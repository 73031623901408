<div class="device-movements-table-component">
  <div class="bg2-grid-wrapper">
    <div class="bg2-grid-container" [ngClass]="{ empty: data_source?.data?.length === 0 }">
      <table
        mat-table
        [dataSource]="data_source"
        matSort
        [matSortActive]="sort__active$$ | async"
        [matSortDirection]="sort__direction$$ | async"
        (matSortChange)="onSortData($event)"
      >
        <!-- #region .headers-and-cells-templates -->
        <ng-container *ngFor="let column of flattened_columns$$ | async; trackBy: track_by_column" [matColumnDef]="column.property">
          <!-- #region .header-definitions -->
          <th mat-header-cell *matHeaderCellDef mat-sort-header [disabled]="!column?.is_sortable">
            <ng-container [ngSwitch]="column.property">
              <!-- Column type: "device.type" -->
              <!-- <ng-container *ngSwitchCase="'device.type'">
                  {{ column.label | translate }}
                </ng-container> -->

              <!-- Column type: "device.owner" -->
              <ng-container *ngSwitchDefault>
                {{ column.label | translate }}
              </ng-container>
            </ng-container>
          </th>
          <!-- #endregion -->

          <!-- #region .content-definitions -->
          <td
            mat-cell
            *matCellDef="let row"
            [attr.data-label]="column?.label | translate"
            [class.selected]="isSelected$$(row) | async"
            (click)="column?.property === 'select' ? selection_model.toggle(row) + $event.stopPropagation() : null"
          >
            <ng-container [ngSwitch]="column.property">
              <ng-container *ngSwitchCase="'point.date'">
                <span>{{ row?.point?.date | formatDate : 'full' }}</span>
              </ng-container>

              <ng-container *ngSwitchCase="'position'">
                <span>{{ row.position?.latitude?.toFixed(5) }}, {{ row.position?.longitude?.toFixed(5) }}</span>
              </ng-container>

              <ng-container *ngSwitchCase="'position_type'">
                <span
                  class="red-text"
                  *ngIf="get(row, column.property) === 'CELLIDS'; else defaultPostionType"
                  [popper]="'VIEWS_WINDOWED.MODALS.DEVICE_MODAL.Expect poor accuracy because of the cellular network' | translate"
                >
                  <span class="mdi mdi-alert-outline"></span>&nbsp;
                  {{ 'VIEWS_WINDOWED.MODALS.ROUTE_TRACER.Approximative position' | translate }}
                </span>
                <ng-template #defaultPostionType>
                  <span>{{ get(row, column.property) }}</span>
                </ng-template>
              </ng-container>

              <ng-container *ngSwitchCase="'accuracy'">
                <span *ngIf="!isNil(row.accuracy)">{{ row.accuracy }} m</span>
              </ng-container>

              <ng-container *ngSwitchCase="'point.gps_speed'">
                <span *ngIf="!isNil(row.point?.gps_speed)">{{ row.point?.gps_speed }} km/h</span>
              </ng-container>

              <ng-container *ngSwitchCase="'point.tracking_delta_last_move'">
                <span *ngIf="!isNil(row.point?.tracking_delta_last_move)">{{ row.point.tracking_delta_last_move | formatDuration }}</span>
              </ng-container>

              <!-- Column other cols -->
              <ng-container *ngSwitchDefault>
                <span>{{ get(row, column.property) }}</span>
              </ng-container>
            </ng-container>
          </td>
          <!-- #endregion -->
        </ng-container>
        <!-- #endregion -->

        <!-- #region .specific-templates -->
        <ng-container matColumnDef="group-header">
          <td mat-cell *matCellDef="let group" [colSpan]="(displayed_columns$$ | async).length">
            <span
              *ngIf="group.first_row.movement; let item"
              class="header-content mdi mdi-chevron-{{ group.expanded ? 'up' : 'down' }} mdi-24px"
              [style.padding-left.px]="(group.level - 1) * 24"
            >
              {{
                'VIEWS_WINDOWED.MODALS.ROUTE_TRACER.Movement of [date], ([points] points, [duration], [distance_str])'
                  | translate
                    : {
                        date: (item.start_date$$ | async | formatDate : 'full'),
                        points: (item.length$$ | async),
                        duration: (item.duration$$ | async),
                        distance_str: (item.distance_str$$ | async)
                      }
              }}
            </span>
          </td>
        </ng-container>
        <!-- #endregion -->

        <!-- #region .row-definitions -->
        <tr mat-header-row *matHeaderRowDef="displayed_columns$$ | async"></tr>
        <tr mat-row *matRowDef="let row; columns: displayed_columns$$ | async" class="final-row"></tr>
        <tr mat-row *matRowDef="let row; columns: ['group-header']; when: isGroup" (click)="groupHeaderClick(row)"></tr>
        <!-- #endregion -->
      </table>
    </div>

    <!-- Empty grid message -->
    <div *ngIf="(has_data$$ | async) === false" class="empty-grid-message">
      <div class="text">
        {{ 'VIEWS_WINDOWED.MODALS.ROUTE_TRACER.No path found for this date range' | translate }}
      </div>
    </div>
  </div>
</div>
