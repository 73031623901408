import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { DialogsService } from 'app/widgets/dialogs-modals';

import {
  RunErrorDialogParams,
  RunErrorDialogComponent,
} from 'app/widgets/widgets-snackbar/components/run-error-dialog/run-error.dialog';

import { BaseRunner } from 'app/core/runners';


import { RunnerType } from 'app/core/runners/models/enumerators/runner-type';
import { RunSnapshot } from 'app/core/api/run/models/RunSnapshot';

@Component({
  selector: 'bg2-snackbar-single',
  templateUrl: './snackbar-single.component.html',
  styleUrls: ['./snackbar-single.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SnackbarSingleComponent {
  @Input()
  public runner: BaseRunner = null;

  // #region -> (component basics)

  public RunnerType = RunnerType;

  constructor(private _dialogs: DialogsService) {}

  // #endregion

  public openErrorDialog(run: BaseRunner): void {
    if (run instanceof RunSnapshot) {
      // TODO manage that in run state itself:
      // if (run.has_error_dialog)
      // this._dialogs.open<RunErrorDialogParams, any>(run.error_dialog_class, { run })
      this._dialogs.open<RunErrorDialogParams, any>(RunErrorDialogComponent, { run }).subscribe(
        () => {},
        error => console.error(error),
        () => {}
      );
    }
  }
}
