import { DefaultMarker, DeviceMarker, LocationMarker } from 'app/typings/mapping';
import { groupMarkersByType } from '../group-markers-by-type/group-markers-by-type';

/**
 * The method is used to group by category a list of markers.
 *
 * @param markers The list of markers to group by.
 */
export const groupMarkersByCat = (markers: L.Marker[]) => {
  const initial_group = groupMarkersByType(markers);

  return {
    locations: [...(initial_group.location || []), ...(initial_group.location_archived || [])] as LocationMarker[],
    devices: [
      ...(initial_group.device_gps || []),
      ...(initial_group.device_gprs || []),
      ...(initial_group.device_wg || []),
      ...(initial_group.device_rg || []),
      ...(initial_group.device_cpt ?? []),
    ] as DeviceMarker[],
    unrepertoried: [...(initial_group.default || [])] as DefaultMarker[],
  };
};
