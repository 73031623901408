<div
  #popperViewRef
  [attr.id]="id"
  [style.opacity]="opacity"
  [style.display]="displayType"
  [ngStyle]="popperOptions.styles"
  [class.ngxp__animation]="!popperOptions.disableAnimation"
  [attr.aria-describedby]="popperOptions.ariaDescribe || null"
  [class.ngxp__container]="!popperOptions.disableDefaultStyling"
  [ngClass]="extractAppliedClassListExpr(popperOptions.applyClass)"
  attr.aria-hidden="{{ ariaHidden }}"
  attr.role="{{ popperOptions.ariaRole }}"
>
  <div *ngIf="text" class="ngxp__inner" [innerHTML]="text">
    <ng-content></ng-content>
  </div>

  <div *ngIf="!text" class="ngxp__inner">
    <ng-content></ng-content>
  </div>

  <div
    class="ngxp__arrow"
    data-popper-arrow
    [class.ngxp__force-arrow]="arrowColor"
    [ngClass]="extractAppliedClassListExpr(popperOptions.applyArrowClass)"
  ></div>
</div>
