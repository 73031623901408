<div class="bg2-pull-down-filters-bottom-sheet-component">
  <div class="content-container">
    <div class="content-container__filters-title">
      <span>{{ 'VIEWS.LOCATION.Filters' | translate }}</span>
      <span class="content-container__filters-title__active-filters"
        >&nbsp;({{ 'ALL.FILTERS.[total] active filters' | translate: { total: (filters?.active_filters__total$$ | async) } }})</span
      >
    </div>

    <div class="content-container__filters-form">
      <bg2-filters-form [filters]="filters"></bg2-filters-form>
    </div>
  </div>

  <mat-divider></mat-divider>

  <div class="footer-container">
    <div class="footer-container__active-filters">
      <ng-container *ngIf="filters.active_filters$$ | async; let active_filters">
        <ng-container *ngIf="active_filters.length > 0">
          <div *ngFor="let active_filter of active_filters" class="active-filter" matRipple (click)="filters.clear(active_filter.name)">
            <span>{{ active_filter.label | translate: { data: active_filter.data } }}</span>
            <span class="mdi mdi-close mdi-18px"></span>
          </div>
        </ng-container>
      </ng-container>
    </div>

    <bg2-button
      [type]="'cancel'"
      [icon]="'mdi-close-box'"
      [message]="'ALL.COMMON.Close' | translate"
      (btn_click)="close_sheet()"
    ></bg2-button>
  </div>
</div>
