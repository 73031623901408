import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';

import { marker as i18n } from '@biesbjerg/ngx-translate-extract-marker';

import { assign, cloneDeep, isArray, isEmpty, isNil, isObject } from 'lodash-es';

import { ArrayLayoutWidget, FormProperty, FormPropertyFactory } from 'ngx-schema-form';

import { filter, Subscription, take } from 'rxjs';

import { ConsoleLoggerService } from 'app/core/console-logger.service';

import { Dictionary } from 'app/typings/core/interfaces/Dictionary.iface';

/** */
interface ArrayOptions extends Dictionary<any> {
  /** */
  auto_add?: boolean;

  /** */
  remove_btn_inline: boolean;
}

/** */
@Component({
  selector: 'bg2-ng-mat-array',
  templateUrl: './ng-mat-array.component.html',
  styleUrls: ['./ng-mat-array.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default, // Do not change to OnPush (imposed by ngx-schema-form)
})
export class NgMatArrayWidgetComponent extends ArrayLayoutWidget implements OnInit, AfterViewInit, OnDestroy {
  // #region -> (component basics)

  /** */
  private readonly LOGGER = new ConsoleLoggerService('NgMatArrayWidgetComponent', true);

  /** */
  constructor(private formPropertyFactory: FormPropertyFactory) {
    super();
  }

  /** */
  ngOnInit(): void {
    this.options = assign({}, this.options, this.schema?.config ?? {});
    this.options = assign(this.options, this.schema?.options ?? {});

    if (this.options?.auto_add) {
      this.newFormProperty();
    }
  }

  /** */
  ngAfterViewInit(): void {
    super.ngAfterViewInit();
  }

  /** */
  ngOnDestroy(): void {
    this.new_property_sub?.unsubscribe();
  }

  // #endregion

  // #region -> (options management)

  /** */
  public options: ArrayOptions = {
    remove_btn_inline: false,
  };

  // #endregion

  // #region -> (array property management)

  public removeItem(item: FormProperty) {
    this.formProperty.removeItem(item);
  }

  // #endregion

  // #region -> (auto-add management)

  /** */
  public new_form_property: FormProperty = null;

  /** */
  private new_property_sub: Subscription = null;

  /** */
  private newFormProperty(): void {
    this.new_form_property = null;
    this.new_property_sub?.unsubscribe();

    setTimeout(() => {
      const schema = cloneDeep(this.schema.items);

      if (this.schema.label_add) {
        schema.label = this.schema.label_add;
      }

      const new_property = this.formPropertyFactory.createProperty(schema, this.formProperty);
      this.new_form_property = new_property;

      this.new_property_sub = this.new_form_property.valueChanges
        .pipe(
          filter(value => !isNil(value)),
          filter(value => !isObject(value) || !isEmpty(value)),
          filter(value => !isArray(value) || !isEmpty(value)),
          take(1)
        )
        .subscribe({
          next: value => {
            this.LOGGER.debug(value);

            this.formProperty.addItem(value);
            this.newFormProperty();
          },
        });
    }, 1);
  }

  // #endregion

  // #region -> (array property labels)

  public get label_remove(): string {
    return this.schema.description_remove || i18n('WIDGETS.EVENT_FORM.ARRAY.Remove');
  }

  // #endregion
}
