import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ResizedDirective } from './resized/resized.directive';
import { LetDirective } from './ng-let-variable/ng-let-variable.directive';
import { NgOptionHighlightDirective } from './ng-option-highlight/ng-option-highlight.directive';
import { TypeSafeMatCellDefDirective } from './type-safe-mat-cell-def/type-safe-mat-cell-def.directive';

@NgModule({
  imports: [CommonModule],
  exports: [NgOptionHighlightDirective, LetDirective, ResizedDirective, TypeSafeMatCellDefDirective],
  declarations: [NgOptionHighlightDirective, LetDirective, ResizedDirective, TypeSafeMatCellDefDirective],
})
export class DirectivesModule {}
