import { formatDate } from '@angular/common';

import { isEmpty, isNil } from 'lodash-es';

import { map, Observable, of, switchMap } from 'rxjs';

import { DRDevice } from 'app/models/devices';
import { IZohoCreateDeskTicket } from 'app/models/zoho/desk';
import { build_template_simple_message } from '../base/template-simple-message';
import { build_template_ticket_comment_header } from '../base/template-ticket-comment-header';
import { build_template_device_list$ } from '../template-device-list';

/** */
export const build_template_support_new_ru$ = (
  ticket_ref: Partial<IZohoCreateDeskTicket>,
  ru_in_date: Date,
  devices: { selected: DRDevice[]; exceptional_imeis: string[] },
  type: { is_partial: boolean; is_already_ru: boolean; is_completing_ru: boolean },
  optional: Partial<{ comment: string }>
): Observable<string> =>
  of([build_template_ticket_comment_header()]).pipe(
    // Add support title to template
    map(template_builder => {
      if (type.is_partial) {
        if (type?.is_completing_ru) {
          template_builder.push('<h1 style="color: green;">Déclaration nouveau "Retour Usine" (complet)</h1>');
          return template_builder;
        }

        template_builder.push('<h1 style="color: orange;">Déclaration nouveau "Retour Usine" (partiel)</h1>');
        return template_builder;
      }

      template_builder.push('<h1 style="color: green;">Déclaration nouveau "Retour Usine" (total)</h1>');
      return template_builder;
    }),
    // Add support properties to template
    map(template_builder => {
      template_builder.push('<h3>Propriétés :</h3>');

      template_builder.push(
        '<ul>',
        `<li><b>Nouveau statut :</b> ${ticket_ref.status}</li>`,
        `<li><b>Date arrivée usine :</b> ${formatDate(ru_in_date, 'd MMMM y, HH:mm:ss zzzz', 'fr-FR')}</li>`,
        '</ul>'
      );

      return template_builder;
    }),
    // Add devices table
    switchMap(template_builder =>
      build_template_device_list$(devices.selected, devices.exceptional_imeis ?? []).pipe(
        map(build_template_device_list => {
          template_builder.push(build_template_device_list);

          return template_builder;
        })
      )
    ),
    // Add user comment to template
    map(template_builder => {
      const comment = optional?.comment;

      if (!isNil(comment) || !isEmpty(comment)) {
        template_builder.push(build_template_simple_message('Commentaire', optional?.comment));
      }

      return template_builder;
    }),
    // Build template
    map(template_builder => template_builder.join(''))
  );
