import { Component, ViewChild, OnInit, OnDestroy, ChangeDetectionStrategy, HostListener, NgZone, AfterViewInit } from '@angular/core';

import { Observable, BehaviorSubject, of, Subscription, concat, combineLatest, take } from 'rxjs';
import { map, switchMap, filter, distinctUntilChanged, debounceTime, tap, catchError } from 'rxjs';

import { assign, isArray, isNil, keys, sortBy, uniq, uniqueId } from 'lodash-es';
import { concat as _concat } from 'lodash-es';
import { MtxSelectComponent } from '@ng-matero/extensions/select';
import { TranslateService } from '@ngx-translate/core';
import { marker as i18n } from '@biesbjerg/ngx-translate-extract-marker';
import { SchemaValidatorFactory, ObjectProperty } from 'ngx-schema-form';

import { AppStateService } from 'app/core/app-state.service';
import { Beeguard2Api } from 'app/core';
import { DeviceApi } from 'app/core';
import { replay, robustCombineLatest } from '@bg2app/tools/rxjs';

import { DRDevice, WhDevicesConfig } from 'app/models';
import { Warehouse } from 'app/models';
import { DeviceConfig } from 'app/models';
import { Hive } from 'app/models';
import { Apiary } from 'app/models';
import { Location } from 'app/models';
import { DRDevice as Device } from 'app/models';

import { parseDate } from 'app/misc/tools';
import { DialogsService } from 'app/widgets/dialogs-modals';
import { distinctUntilRealChanged } from '@bg2app/tools/rxjs';
import { Dictionary } from 'app/typings/core/interfaces';
import { ConsoleLoggerService } from 'app/core/console-logger.service';

import { EfSelectWidgetComponent, EfSelectOptions } from '../select/select.widget';
import { FullscreenSelectHelper } from '@bg2app/tools/misc';
import { DeviceQueryParams } from 'app/core/api/device/device-api-service';

i18n('DEVICE.ALL.ERROR.Not associated devices');
i18n('DEVICE.ALL.ERROR.Already associated devices');

interface EfEntityWidgetOptions extends EfSelectOptions {
  nullable?: boolean;
  hidden?: boolean;
  multiple?: boolean;

  show_all_devices?: boolean; // if false show devices from a given warehouse only
  event_date_path?: string;
  previous_apiary_path?: string;
  previous_hive_path?: string;
  previous_warehouse_path?: string;
}

/**
 * Interface of device options for ng-select
 */
interface DeviceOption {
  name: string; // Name of the device
  imei: number; // IMEI of the device
  device: Device; // The device itself

  wh_associated: boolean; // Used to know if device is associated with a warehouse
  wh_id?: number; // The warehouse identifier
  wh?: Warehouse; // The warehouse itself

  associated: boolean; // Used to know if device is associated with a hive
  hive_or_apiary_id?: number; // The hive identifier
  since?: {
    date: Date;
    event_id: number;
  };

  hive?: Hive; // The hive itself
  apiary?: Apiary; // The apiary itself
  apiary_id?: number; // The associated apiary id
  location?: Location; // The location itself
}

interface GlobalDeviceConfig extends DeviceConfig {
  wh_id?: number;
  wh?: Warehouse;
}

type GlovalDevicesConfig = Dictionary<GlobalDeviceConfig>;

@Component({
  selector: 'bg2-device-widget',
  templateUrl: './device.widget.html',
  styleUrls: ['./device.widget.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EfBg2DeviceWidgetComponent extends EfSelectWidgetComponent implements OnInit, AfterViewInit, OnDestroy {
  private readonly _logger = new ConsoleLoggerService('EfBg2DeviceWidget', false);

  private _event_id: number;

  public options: EfEntityWidgetOptions = {
    img: false,
    img_prefix: 'select/',
    items: {},
    indent: false,
    clearable: true,
    readonly: false,
    hidden: false,
    nullable: false,
    multiple: false,
    reset_btn: false,
    show_all_devices: false,
    event_date_path: null,
    previous_apiary_path: null,
    previous_hive_path: null,
    previous_warehouse_path: null,
  };

  // #region -> (fullscreen management)

  /** */
  public fullscreen_select_helper = new FullscreenSelectHelper(this._ng_zone);

  // #endregion

  // #region -> (component basics)

  private _source_wh_sub: Subscription = null;
  private _pwh_property_sub: Subscription = null;
  private _phive_property_sub: Subscription = null;
  private _papiary_property_sub: Subscription = null;

  public readonly widget_id = uniqueId('widget-event-form-bg2device-');

  constructor(
    protected dialog: DialogsService,
    protected schemaValidatorFactory: SchemaValidatorFactory,
    protected bg2Api: Beeguard2Api,
    protected deviceApi: DeviceApi,
    public appState: AppStateService,
    protected translate: TranslateService,
    protected readonly _ng_zone: NgZone
  ) {
    super(bg2Api, translate, appState, dialog, _ng_zone);
  }

  ngOnInit(): void {
    super.ngOnInit();

    if (!isNil(this.schema.all_devices)) {
      //FIXME: this is for retro compatibikity but this is deprecated and sould be removed
      this.options.show_all_devices = this.schema.all_devices;
    }

    if (!this.options.multiple) {
      if (this.formProperty instanceof ObjectProperty) {
        if (!('imei' in this.formProperty.properties)) {
          this._logger.error('The required property "IMEI" in the device schema does not exist');
          throw "Invalid schema, device selector (not multiple) should have an 'imei' property";
        }
        if (!('dtype' in this.schema.properties)) {
          this._logger.debug('No dtype property');
        }
      } else {
        throw 'Invalid schema, device selector (not multiple) should be of type object';
      }
      this.value$$ = concat(of(this.formProperty.value), this.formProperty.valueChanges).pipe(
        map(value => ('imei' in value ? value.imei : null)),
        tap(values => this._logger.debug('new values$$', values)),
        distinctUntilRealChanged(),
        tap(imei => (!isNil(imei) ? this._imeis$$.next([imei]) : this._imeis$$.next(null))),
        replay()
      );
    } else {
      this.value$$ = concat(of(this.formProperty.value), this.formProperty.valueChanges).pipe(
        tap(values => this._logger.debug('new values$$', values)),
        map(values => values?.filter((val: any) => 'imei' in val).map((val: any) => val.imei)),
        distinctUntilRealChanged(),
        tap(imeis => this._imeis$$.next(imeis)),
        tap(imeis => this._logger.debug('new imeis$$', imeis)),
        replay()
      );
    }

    this.value$$.subscribe();

    this.date$$ = this.getDateObservable();

    // Binding to change previous_hive entity selector
    const phive_path = this.options.previous_hive_path || this.schema.previous_hive_path;
    if (phive_path) {
      this.bindToPreviousHive(phive_path);
    }

    // Binding to change previous_apiary entity selector
    const papiary_path = this.options.previous_apiary_path || this.schema.previous_apiary_path;
    if (papiary_path) {
      this.bindToPreviousApiary(papiary_path);
    }

    // Binding to change previous_warehouse entity selector
    const pwh_path = this.options.previous_warehouse_path || this.schema.previous_warehouse_path;
    // not get previous_warehouse_path from schema for retrocompatibility
    if (pwh_path) {
      this.bindToPreviousWarehouse(pwh_path);
    }

    if (!this.options.show_all_devices) {
      this.bindToSourceWarehouse();
    }
  }

  ngAfterViewInit(): void {
    super.ngAfterViewInit();
  }

  ngOnDestroy(): void {
    this._source_wh_sub?.unsubscribe();
    this._pwh_property_sub?.unsubscribe();
    this._phive_property_sub?.unsubscribe();

    this.fullscreen_select_helper.destroy();

    super.ngOnDestroy();
  }

  // #endregion

  // #region -> (schema properties)

  private date$$: Observable<Date>;

  private getDateObservable(): Observable<Date> {
    //const event_root_property = this.formProperty.parent.parent; // TODO: Make it more robust
    const event_root_property = this.formProperty.root;
    const dpath = this.options.event_date_path || event_root_property.schema?.options?.event_date_path;
    const date_property = !isNil(dpath) ? this.robustSearchProperty(dpath, event_root_property) : null;

    if (isNil(date_property)) {
      this._logger.info(`Date path not found (${dpath})`);
      return of(new Date());
    } else {
      this._event_id = date_property.schema.event_id || null;
      return concat(of(date_property.value), date_property.valueChanges).pipe(
        filter(date => !isNil(date)),
        debounceTime(200),
        distinctUntilRealChanged(),
        map(date => parseDate(date)),
        replay()
      );
    }
  }

  // #endregion

  // #region -> (device filtering)

  public devices_filter$$ = new BehaviorSubject<string>('');

  // #endregion

  // #region -> (device data management)

  /**
   * Get device type from schema.
   */
  private get device_type(): string | null {
    return this.schema.device_type || null;
  }

  protected imei_set_sub: Subscription;

  /**
   * Callback used by ng select when device is selected
   */
  public set imeis(_imeis: number | number[]) {
    this._logger.debug(`Change selected imeis: ${_imeis}`);
    this.imei_set_sub?.unsubscribe();

    if (this.options.multiple) {
      if (isNil(_imeis)) {
        this._logger.info('no imeis ?');
      } else if (isArray(_imeis)) {
        this.imei_set_sub = this.device_idx$$.pipe(take(1)).subscribe({
          next: device_idx => {
            const new_value = _imeis.map(imei => {
              if ('dtype' in this.schema?.items?.properties) {
                const dtype = device_idx[imei].type;
                return { imei, dtype };
              } else {
                return { imei };
              }
            });
            this._logger.debug(`Set new values: ${new_value}`);
            this.formProperty.setValue(new_value, false);
          },
        });
      } else {
        throw 'Invalid imeis format, should be null or array of number';
      }
    } else {
      if (isArray(_imeis)) {
        throw 'Invalid imeis format, should be null or number not an array';
      }
      this.imei_set_sub = this.device_idx$$.pipe(take(1)).subscribe({
        next: device_idx => {
          let new_value: any = null;
          if (!isNil(_imeis)) {
            new_value = { imei: _imeis };
            if ('dtype' in this.schema?.properties) {
              new_value.dtype = device_idx?.[_imeis]?.type ?? null;
            }
          }
          this._logger.debug('Set new value: ', new_value);
          this.formProperty.setValue(new_value, false);
        },
      });
    }
  }

  public _imeis$$ = new BehaviorSubject<number[]>(null);
  public imeis$$ = this._imeis$$.asObservable();

  private _source_wh_id$$ = new BehaviorSubject<number>(null);
  public source_wh_id$$ = this._source_wh_id$$.asObservable().pipe(distinctUntilRealChanged(), replay());

  /**
   * Observable on the device query.
   */
  public query$$ = combineLatest({
    devices_filter: this.devices_filter$$,
    source_wh_id: this.source_wh_id$$,
    imeis: this.imeis$$,
  }).pipe(
    distinctUntilRealChanged(),
    map(({ devices_filter, source_wh_id, imeis }) => {
      const devices_query: Dictionary<any> = {};

      if (devices_filter) {
        const device_names = devices_filter?.split(' ');
        devices_query._additional_filters_or = _concat<any[]>(
          device_names.map(name_filter => ({
            name__contains: name_filter,
          })),
          device_names
            .map(name_filter => Number(name_filter))
            .filter(imei_filter => !isNaN(imei_filter))
            .map(imei_filter => ({
              imei: imei_filter,
            }))
        );
      }

      if (imeis) {
        devices_query.imei__nin = imeis;
      }

      if (!isNil(source_wh_id)) {
        devices_query.warehouse__0__warehouse_id = source_wh_id;
      }

      if (!isNil(this.device_type) && this.device_type.length > 0) {
        devices_query._cls__contains = this.device_type;
      }

      return devices_query;
    }),
    map(query => JSON.stringify(query)),
    tap(query => this._logger.debug('Query', query)),
    replay()
  );

  /**
   * Observable on the max displayed devices.
   */
  public limit$$ = combineLatest({
    source_wh_id: this.source_wh_id$$,
    imeis: this.imeis$$,
  }).pipe(
    map(({ source_wh_id, imeis }) => {
      if (!isNil(source_wh_id)) {
        return -1; // Should display all of the devices
      }
      return Math.max(0, 10 - imeis?.length);
    }),
    distinctUntilRealChanged(),
    tap(limit => this._logger.debug('limit', limit)),
    replay()
  );

  /**
   * Observable on the list of default displayed devices (by IMEI).
   */
  public default_imeis$$: Observable<number[]> = of(null).pipe(
    map(() => this.schema.default || this.schema.properties?.imei?.default),
    tap(imeis => this._logger.debug('default', imeis)),
    map(imeis => (isArray(imeis) || isNil(imeis) ? imeis : [imeis])),
    map(imeis => imeis?.map(imei => Number(imei)).filter(imei => !isNaN(imei))),
    tap(imeis => {
      if (this.options.multiple && !isNil(imeis)) {
        this.imeis = imeis;
      } else if (!isNil(imeis) && imeis.length === 1) {
        this.imeis = imeis[0];
      }
    }),
    distinctUntilRealChanged(),
    replay()
  );

  private device_loading_params$$: Observable<DeviceQueryParams> = of(null).pipe(
    map(() => {
      const params: DeviceQueryParams = {
        last_measurements: ['message', 'gateway_message', 'sensor_message', 'location', 'location_AGG_GPS', 'energy', 'weight', 'env'],
        last_measurements_details: ['sensor_message_AGG_as_gateway'],
        include: ['beeguard_setup'],
      };
      return params;
    }),
    replay()
  );

  private selected_devices_requests$$ = combineLatest({
    imeis: this.imeis$$,
    params: this.device_loading_params$$,
  }).pipe(
    debounceTime(400),
    switchMap(({ imeis, params }) => {
      this._logger.debug(`Load selected devices, imeis: ${imeis}`);
      if (isNil(imeis)) {
        return of({
          devices: [],
          paging: {
            total: 0,
          },
          params,
        });
      }
      const offset = 0;

      return this.deviceApi
        .old_get_devices$(imeis, undefined, 0, -1, params.last_measurements, params.last_measurements_details, params.include, undefined)
        .pipe(
          map((ret: any) => ({
            devices: ret.devices,
            paging: ret.paging,
            params,
          }))
        );
    })
  );

  private _devices_requests$$ = combineLatest({
    query: this.query$$,
    limit: this.limit$$,
    default_imeis: this.default_imeis$$,
    params: this.device_loading_params$$,
  }).pipe(
    // TODO: Make it into a device API method
    debounceTime(400),
    tap(() => (this.loading = true)),
    switchMap(({ query, limit, default_imeis, params }) => {
      if (!isNil(default_imeis)) {
        this._is_searchable$$.next(false);
      }

      this._logger.debug(`query: ${query} imeis: ${default_imeis} limit: ${limit}`);
      const offset = 0;

      return this.deviceApi
        .old_get_devices$(
          default_imeis,
          query,
          offset,
          limit,
          params.last_measurements,
          params.last_measurements_details,
          params.include,
          undefined
        )
        .pipe(
          map((ret: any) => ({
            devices: ret.devices,
            paging: ret.paging,
            params,
          }))
        );
    }),
    replay()
  );

  private devices_requests$$ = combineLatest({
    selected_devices_requests: this.selected_devices_requests$$,
    other_devices_search: this._devices_requests$$,
  }).pipe(
    map(({ selected_devices_requests, other_devices_search }) => {
      let devices = _concat(selected_devices_requests.devices, other_devices_search.devices);
      let paging = {
        total: selected_devices_requests.paging.total + other_devices_search.paging.total,
        limit: selected_devices_requests.paging.total + other_devices_search.paging.limit,
        offset: other_devices_search.paging.offset,
      };
      return {
        devices: devices,
        paging: paging,
        params: other_devices_search.params,
      };
    })
  );

  /**
   * Observable on the total of devices fetched by the request.
   */
  public total$$ = this.devices_requests$$.pipe(
    map(({ paging }) => paging.total),
    distinctUntilRealChanged(),
    replay()
  );

  private devices$$: Observable<DRDevice[]> = this.devices_requests$$.pipe(
    map(({ devices, params }) => this.deviceApi.deserializeList(devices, params)),
    map(devices => this._sortOrFilterDevices(devices)),
    replay()
  );

  public device_idx$$ = this.devices$$.pipe(
    map(devices => {
      const _devices: Dictionary<DRDevice> = {};
      devices.map(device => {
        _devices[device.imei] = device;
      });
      return _devices;
    }),
    replay()
  );

  public devices_options$$: Observable<DeviceOption[]> = this.devices$$.pipe(
    switchMap(devices => this.date$$.pipe(map(date => ({ devices, date })))),
    map(({ devices, date }) => {
      const all_wh_ids = devices.map(device => device.getWarehouseIdAtDate(date, this._event_id)).filter(wh_id => !isNil(wh_id));
      return { devices, wh_ids: uniq(all_wh_ids), date };
    }),
    switchMap(({ devices, wh_ids, date }) =>
      this.bg2Api.getEntitiesObj(wh_ids).pipe(
        map(warehouses => warehouses as Warehouse[]),
        map(warehouses => ({ devices, warehouses, date }))
      )
    ),
    switchMap(({ devices, warehouses, date }) => {
      if (warehouses.length > 0) {
        const config_requests = warehouses
          .filter(warehouse => warehouse instanceof Warehouse)
          .map(warehouse =>
            warehouse.requestDevicesConfigAtDate(date, this._event_id).pipe(
              catchError((error: unknown) => of([])),
              map((conf: WhDevicesConfig): GlovalDevicesConfig => {
                const gconf: Dictionary<any> = {};

                keys(conf).map((imei: string) => {
                  const gdconf: GlobalDeviceConfig = conf[imei];
                  gdconf.wh = warehouse;
                  gdconf.wh_id = warehouse.id;
                  gconf[imei] = gdconf;
                });

                return gconf;
              })
            )
          );
        return combineLatest(config_requests).pipe(
          map((configs: GlovalDevicesConfig[]): GlovalDevicesConfig => {
            const config: GlovalDevicesConfig = {};

            configs.map(conf => {
              // TODO: Assert imei not already present ?
              assign(config, conf);
            });

            return config;
          }),
          map(wh_devices_config => ({ devices, wh_devices_config }))
        );
      } else {
        return of({ devices, wh_devices_config: <GlovalDevicesConfig>{} });
      }
    }),
    // map((devices: Device[], warehouse: Warehouse[]): DeviceOption[] => {
    map(({ devices, wh_devices_config }): DeviceOption[] => {
      const devices_options = devices.map(device => {
        const wh_dconf = wh_devices_config[device.imei];
        // this._logger.debug({wh_dconf})
        const device_opt: DeviceOption = {
          device,
          imei: device.imei,
          name: device.name,
          wh_associated: !isNil(device.warehouse_id),
          associated: false,
        };
        //
        if (!isNil(device.warehouse_id) && !isNil(wh_dconf) && !isNil(wh_dconf.wh)) {
          device_opt.wh = wh_dconf.wh;
          device_opt.associated = !isNil(wh_dconf.associated_to);
          device_opt.hive_or_apiary_id = wh_dconf.associated_to;
        }
        return device_opt;
      });
      return devices_options;
    }),
    tap(() => (this.loading = false)),
    switchMap((devices_options: DeviceOption[]) =>
      concat(
        of(devices_options), // Show devices options as soon as possible
        this.loadDeviceEntities(devices_options).pipe(
          switchMap(_devices_options =>
            concat(
              of(_devices_options), // but then with associated devices
              this.loadAllApiaries(_devices_options) // and then with indirect associated devices (apiary)
            )
          )
        )
      )
    ),
    replay()
  );

  private loadDeviceEntities(devices_options: DeviceOption[]) {
    const _entity_to_devices: Dictionary<DeviceOption[]> = {};

    const hive_or_apiary_ids = devices_options.reduce((previous: number[], device_option) => {
      if (device_option?.associated) {
        const hive_id = device_option.hive_or_apiary_id;
        const _idx = _entity_to_devices[hive_id] || [];

        _idx.push(device_option);
        _entity_to_devices[hive_id] = _idx;

        previous.push(hive_id);
      }

      return previous;
    }, []);

    return this.bg2Api.getEntitiesObj(hive_or_apiary_ids).pipe(
      tap(entities =>
        entities.forEach(hive_or_apiary =>
          _entity_to_devices[hive_or_apiary.id].forEach(_device_opt => {
            if (hive_or_apiary instanceof Hive) {
              _device_opt.hive = hive_or_apiary;
            } else if (hive_or_apiary instanceof Apiary) {
              _device_opt.apiary = hive_or_apiary;
            } else {
              throw Error('Invalid entity type');
            }
          })
        )
      ),
      map(() => devices_options)
    );
  }

  private loadAllApiaries(devices_options: DeviceOption[]) {
    const _entity_to_devices: Dictionary<DeviceOption[]> = {};
    const dopts_with_missing_apiary = devices_options.filter(dopt => !isNil(dopt?.hive) && isNil(dopt?.apiary));
    return this.date$$.pipe(
      switchMap(_date => {
        const missing_apiaries_id$$ = devices_options.map(dopt => {
          if (dopt.hive) {
            return dopt.hive.getAtDate('state.apiary_id', _date, this._event_id).pipe(
              tap(apiary_id => (dopt.apiary_id = apiary_id)),
              map(() => dopt)
            );
          } else {
            return of(dopt);
          }
        });
        return robustCombineLatest(missing_apiaries_id$$);
      })
    );
  }

  public selected_devices_option$$ = combineLatest({
    imeis: this.imeis$$,
    dopt: this.devices_options$$,
  }).pipe(
    map(({ imeis, dopt: dopts }) => {
      if (isNil(dopts)) {
        return null;
      }
      if (isNil(imeis)) {
        return [];
      }
      return dopts.filter(opt => imeis.includes(opt.imei));
    }),
    replay()
  );

  public selected_devices_option_with_entity_loaded$$ = this.selected_devices_option$$.pipe(
    filter(dopts => dopts?.length > 0),
    filter(dopts => dopts.map(dopt => isNil(dopt.hive_or_apiary_id) || !isNil(dopt.hive) || !isNil(dopt.apiary)).every(v => v)),
    map(dopts => {
      if (isNil(this._event_id)) {
        // keep all if event_id is unnknow
        return dopts;
      } else {
        // keep only dopts with associatiated "event_id" different from this one
        return dopts.filter(dopt => dopt?.since?.event_id !== this._event_id);
      }
    }),
    replay()
  );

  /**
   * Filter devices if there is a default type else sort them by type.
   *
   * @param devices The list of devices to sort or filter
   * @returns Returns a sorted or filtered devices list.
   */
  private _sortOrFilterDevices(devices: Device[]): Device[] {
    return isNil(this.device_type)
      ? sortBy(devices, device => device.type)
      : devices.filter(device => device.type.endsWith(this.device_type));
  }

  /**
   * Method used to group devices, for the select component
   */
  public groupDevices(device: DeviceOption): string {
    if (device.associated) {
      return i18n<string>('DEVICE.ALL.ERROR.Already associated devices');
    }

    return i18n<string>('DEVICE.ALL.ERROR.Not associated devices');
  }

  /**
   * Update previous hive field when device change
   */
  private bindToPreviousHive(phive_path: any): void {
    // const phive_property = this.formProperty.parent.searchProperty(phive_path);
    const phive_property = this.robustSearchProperty(phive_path, this.formProperty.parent);
    if (isNil(phive_property)) {
      this._logger.error(`(bindToPreviousHive) Previous hive path not found (${phive_path})`);
    } else {
      this._phive_property_sub?.unsubscribe();
      this._phive_property_sub = this.selected_devices_option_with_entity_loaded$$
        .pipe(
          map(dopts => dopts.map(dopt => dopt.hive?.id).filter(hive_id => !isNil(hive_id))),
          map(hive_ids => new Set(hive_ids)),
          map(hive_ids => Array.from(hive_ids))
        )
        .subscribe((hive_ids: number[]) => {
          this._logger.debug('(bindToPreviousHive) New previous hives_ids', hive_ids);
          const new_hive_value = this.options.multiple ? hive_ids : hive_ids[0] || null;
          if (phive_property.value !== new_hive_value) {
            phive_property.setValue(new_hive_value, false);
          }
        });
    }
  }

  /**
   * Update previous hive field when device change
   */
  private bindToPreviousApiary(papiary_path: any): void {
    // const papiary_property = this.formProperty.parent.searchProperty(papiary_path);
    const papiary_property = this.robustSearchProperty(papiary_path, this.formProperty.parent);
    if (isNil(papiary_property)) {
      this._logger.error(`(bindToPreviousApiary) Previous apiary path not found (${papiary_path})`);
    } else {
      this._papiary_property_sub?.unsubscribe();
      this._papiary_property_sub = this.selected_devices_option_with_entity_loaded$$
        .pipe(
          map(dopts => dopts.map(dopt => dopt.apiary?.id || dopt.apiary_id).filter(apiary_id => !isNil(apiary_id))),
          map(apiary_ids => new Set(apiary_ids)),
          map(apiary_ids => Array.from(apiary_ids))
        )
        .subscribe((apiary_ids: number[]) => {
          this._logger.debug('(bindToPreviousHive) New previous apiary_ids', apiary_ids);
          const new_hive_value = this.options.multiple ? apiary_ids : apiary_ids[0] || null;
          if (papiary_property.value !== new_hive_value) {
            papiary_property.setValue(new_hive_value, false);
          }
        });
    }
  }

  /**
   * Update previous warehouse field when device change
   *
   * @param pwh_path Previous warehouse path
   */
  private bindToPreviousWarehouse(pwh_path: any): void {
    // const pwh_property = this.formProperty.parent.searchProperty(pwh_path);
    const pwh_property = this.robustSearchProperty(pwh_path, this.formProperty.parent);
    if (isNil(pwh_property)) {
      this._logger.error(`(bindToPreviousWarehouse) Previous wh path not found (${pwh_path})`);
    } else {
      this._pwh_property_sub?.unsubscribe();
      this._pwh_property_sub = combineLatest({
        dopts: this.selected_devices_option$$,
        date: this.date$$,
      })
        .pipe(
          filter(({ dopts, date }) => dopts?.length > 0),
          map(({ dopts, date }) => dopts.filter(dopt => dopt.device).map(dopt => dopt.device.getWarehouseIdAtDate(date, this._event_id))),
          map(wh_ids => new Set(wh_ids)),
          map(wh_ids => Array.from(wh_ids))
        )
        .subscribe((wh_ids: number[]) => {
          this._logger.debug(`(previousWarehouse) prev wh:`, wh_ids);
          if (pwh_property.value !== wh_ids) {
            this._logger.debug(`(previousWarehouse) change previous wh_id`);
            if (this.multiple) {
              pwh_property.setValue(wh_ids, false);
            } else {
              pwh_property.setValue(wh_ids[0], false);
            }
          }
        });
    }
  }

  private bindToSourceWarehouse(): void {
    const wpath = this.schema.warehouse_path;
    if (isNil(wpath)) {
      this._logger.info('No warehouse path !');
      return;
    }
    const warehouse_property = this.formProperty.parent.searchProperty(wpath);
    if (isNil(warehouse_property)) {
      this._logger.error(`Warehouse path not found (${wpath})`);
      return;
    }

    this._source_wh_sub?.unsubscribe();
    this._source_wh_sub = concat(of(warehouse_property.value), warehouse_property.valueChanges)
      .pipe(debounceTime(100), distinctUntilChanged())
      .subscribe(warehouse_id => {
        this._logger.debug(`Source warehouse updated: ${warehouse_id}`);
        this._source_wh_id$$.next(warehouse_id);
      });
  }

  // #endregion

  // #region -> (device select management)

  @ViewChild('select', { static: true })
  public select: MtxSelectComponent;

  private _is_loading$$ = new BehaviorSubject<boolean>(false);
  public is_loading$$ = this._is_loading$$.asObservable().pipe(debounceTime(100), distinctUntilChanged(), replay());

  set loading(is_loading: boolean) {
    this._is_loading$$.next(is_loading);
  }

  private _is_searchable$$ = new BehaviorSubject(true);
  public is_searchable$$ = this._is_searchable$$.asObservable().pipe(distinctUntilRealChanged(), replay());

  public scrollToSelect(): void {
    this.select.ngSelect.element.scrollIntoView(true);
  }

  public select_loading_sentence$$ = combineLatest([this.devices_options$$, this.total$$]).pipe(
    switchMap(([device_options, total]) =>
      this.translate.stream(i18n<string>('WIDGETS.EVENT_FORM.BG2_DEVICE.[loaded] out of [total] devices loaded'), {
        loaded: device_options?.length,
        total,
      })
    ),
    distinctUntilRealChanged(),
    replay()
  );

  // #endregion

  public as_device_conf(item: any): DeviceOption {
    return item;
  }
}
