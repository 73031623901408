import { Component, ChangeDetectionStrategy } from '@angular/core';

import { BehaviorSubject, catchError, forkJoin, map, Observable, of, switchMap, take, throwError } from 'rxjs';
import { replay } from '@bg2app/tools/rxjs';

import { DeviceApi } from 'app/core';
import { ConsoleLoggerService } from 'app/core/console-logger.service';

import { AbstractDialogComponent } from 'app/widgets/dialogs-modals/abstract-dialog.component';

import { DRDevice } from 'app/models';
import { DevicesDialogParams } from '../../devices-dialog-params';
import { Dictionary } from 'app/typings/core/interfaces';
import { isEmpty, isNil, mapValues, merge } from 'lodash-es';

@Component({
  selector: 'bg2-device-enable-disable-dialog',
  templateUrl: './device-enable-disable-dialog.component.html',
  styleUrls: ['./device-enable-disable-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DeviceEnableDisableDialogComponent extends AbstractDialogComponent<DevicesDialogParams, any> {
  // #region -> (dialog basics)

  /** */
  private readonly LOGGER = new ConsoleLoggerService('DeviceEnableDisableDialogComponent', false);

  constructor(private readonly _deviceApi: DeviceApi) {
    super();
  }

  /** */
  public close(value: boolean): void {
    this.complete(value);
  }

  // #endregion

  // #region -> (dialog params)

  /**
   * List of devices defined by dialog params.
   */
  public devices$$: Observable<DRDevice[]> = this.input_params$$.pipe(
    map(params => params.devices),
    replay()
  );

  // #endregion

  // #region -> (form model)

  /** */
  private _model$$ = new BehaviorSubject<{ [key: number]: Dictionary<any> }>({});

  /** */
  private model$$ = this._model$$.asObservable();

  /** */
  public set_device_active_state(activation_state: boolean, device: DRDevice): void {
    const current_model = this._model$$.getValue();

    if (isNil(current_model[device?.imei])) {
      current_model[device?.imei] = {};
    }

    current_model[device?.imei] = merge({}, current_model[device?.imei], {
      metadata: {
        unactivated: {
          state: !activation_state,
        },
      },
    });

    this._model$$.next(current_model);
  }

  // #endregion

  // #region -> (form validity)

  /** */
  public is_valid$$ = this.model$$.pipe(
    map(model => !isEmpty(model)),
    replay()
  );

  // #endregion

  // #region -> (form submit)

  /** */
  private _is_updating_devices$$ = new BehaviorSubject(false);

  /** */
  public is_updating_devices$$ = this._is_updating_devices$$.asObservable();

  /** */
  public submit(): void {
    this._is_updating_devices$$.next(true);

    this.model$$
      .pipe(
        take(1),
        switchMap(model => {
          const queries$$ = mapValues(model, (value, key) => {
            const imei = parseInt(key, 10);

            return this._deviceApi.update_device$(imei, { imei, ...value }, ['metadata']).pipe(catchError(() => of(null)));
          });

          return forkJoin(queries$$);
        })
      )
      .subscribe({
        error: () => {
          this._is_updating_devices$$.next(false);
        },
        complete: () => {
          this._is_updating_devices$$.next(true);
          this.close(true);
        },
      });
  }

  // #endregion
}
