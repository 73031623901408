<div class="bg2-button-delete-or-archive-entity-component" [class.as-icon]="is_icon$$ | async">
  <!-- As button -->
  <ng-container *ngIf="(is_icon$$ | async) === false">
    <ng-template #loadingEntityAsButton>
      <bg2-button
        [type]="'action'"
        [disabled]="true"
        [loading]="true"
        [icon]="'mdi-loading'"
        [options]="{ is_thin: thin_button }"
        [message]="('VIEWS.ENTITIES.SHARED.BUTTON_DELETE_OR_ARCHIVE_ENTITY.Loading entity' | translate) + '...'"
      ></bg2-button>
    </ng-template>

    <ng-container *ngIf="entity$$ | async; let entity; else: loadingEntityAsButton">
      <bg2-button
        [type]="'delete'"
        [icon]="iconic$$ | async"
        [message]="buttonMessageDefinition"
        [options]="{ is_thin: thin_button }"
        [disabled]="is_disabled$$ | async"
        [loading]="in_progress$$ | async"
        (btn_click)="deleteOrArchiveEntity()"
      ></bg2-button>

      <ng-template #buttonMessageDefinition>
        <!-- Entity can be only archived -->
        <ng-container *ngIf="(can_archive$$ | async) && cannot_delete$$ | async">
          {{ 'VIEWS.MODALS.FORM.Archive' | translate }}
        </ng-container>

        <!-- Entity can be deleted and archived -->
        <ng-container *ngIf="can_delete$$ | async">{{ 'VIEWS.MODALS.FORM.Delete' | translate }}</ng-container>

        <ng-container *ngIf="(is_loading_delete_check$$ | async) || (is_loading_archive_check$$ | async)">
          {{ ('VIEWS.ENTITIES.SHARED.BUTTON_DELETE_OR_ARCHIVE_ENTITY.Checking entity' | translate) + '...' }}
        </ng-container>
      </ng-template>
    </ng-container>
  </ng-container>

  <!-- As icon -->
  <ng-container *ngIf="is_icon$$ | async">
    <ng-template #loadingEntityAsIcon>
      <span class="mdi mdi-24px mdi-loading mdi-spin"></span>
    </ng-template>

    <ng-container *ngIf="entity$$ | async; let entity; else: loadingEntityAsIcon">
      <div (click)="deleteOrArchiveEntity()">
        <!-- Entity can be only archived -->
        <ng-container *ngIf="(can_archive$$ | async) && (cannot_delete$$ | async)">
          <span *ngIf="is_archiving_in_progress$$ | async" class="mdi mdi-loading mdi-spin"></span>
          <span *ngIf="(is_archiving_in_progress$$ | async) === false" class="mdi mdi-archive"></span>
        </ng-container>

        <!-- Entity can be deleted (also archived) -->
        <ng-container *ngIf="can_delete$$ | async">
          <span *ngIf="is_deletion_in_progress$$ | async" class="mdi mdi-loading mdi-spin"></span>
          <span *ngIf="(is_deletion_in_progress$$ | async) === false" class="mdi mdi-delete"></span>
        </ng-container>

        <!-- Loading ... -->
        <ng-container *ngIf="(is_loading_archive_check$$ | async) || (is_loading_delete_check$$ | async)">
          <span class="mdi mdi-loading mdi-spin"></span>
        </ng-container>
      </div>
    </ng-container>
  </ng-container>
</div>
