<span class="bg2-acl-not-accessible-component" [popper]="popper">
  <!-- <span class="mdi mdi-lock"></span> -->
  <span>🔒</span>
  <span>{{ message$$ | async }}</span>
</span>

<popper-content #popper>
  <bg2-acl-tooltip-content
    *ngIf="popper_config$$ | async; let popper_config; else popperDefault"
    [ace]="popper_config.ace"
    [what]="popper_config.what"
    [entity_type]="popper_config.entity_type"
  ></bg2-acl-tooltip-content>

  <ng-template #popperDefault>DEFAULT</ng-template>
</popper-content>
