import { ChangeDetectionStrategy, Component, NgZone, OnInit } from '@angular/core';

import { Beeguard2Api } from 'app/core';

import { EfSelectWidgetComponent } from '../select/select.widget';
import { TranslateService } from '@ngx-translate/core';
import { AppStateService } from 'app/core/app-state.service';
import { DialogsService } from 'app/widgets/dialogs-modals/dialogs.service';

import { EfSelectOptions, ItemSelectOption  } from '../select/select.widget';

export interface EfBg2UserSelectOptions extends EfSelectOptions {
  img: boolean;
  img_prefix: string;
  items: { [key: string]: ItemSelectOption };
  readonly: boolean;
  clearable?: boolean;
  auto_scroll?: boolean;
  no_impersonate?: boolean;
}

@Component({
  selector: 'bg2-user-select-widget',
  templateUrl: './bg2user-select.widget.html',
  styleUrls: ['./bg2user-select.widget.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class EfBg2UserSelectWidget extends EfSelectWidgetComponent implements OnInit {

  public options: EfBg2UserSelectOptions = {
    img: false,
    img_prefix: 'select/',
    items: {},
    readonly: false,
    indent: false,
    clearable: true,
    reset_btn: false,
    auto_scroll: true,
    no_impersonate: false,
  };

  constructor(
    protected bg2Api: Beeguard2Api,
    protected translate: TranslateService,
    public appState: AppStateService,
    protected dialogs: DialogsService,
    protected readonly _ng_zone: NgZone
  ) {
    super(bg2Api, translate, appState, dialogs, _ng_zone);
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  get label(): string {
    return this.schema.label;
  }
}
