/** */
export enum ZohoCRMDealStage {
  /** */
  PREPARNG_OFFER = "Préparation de l'offre",

  /** */
  FOLLOW_OFFER_NORMAL_DELAY = "Suivre l'offre - Délai Normal",

  /** */
  FOLLOW_OFFER_TO_BE_RELAUNCHED = "Suivre l'offre - A relancer",

  /** */
  FOLLOW_OFFER_WAITING = "Suivre l'offre - En attente",

  /** */
  WIN_OFFER = 'Gagné - New',

  /** */
  LOST_OFFER = 'Perdu - New',
}
