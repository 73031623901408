import { Directive, Input, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';

export class LetContext<T = unknown> {
  ngLet: T = null;
  $implicit: T = null;
}

@Directive({
  selector: '[ngLet]',
})
export class LetDirective<T> implements OnInit {
  @Input()
  public set ngLet(value: T) {
    this._context.$implicit = this._context.ngLet = value;
  }

  private readonly _context = new LetContext<T>();

  constructor(private readonly _viewContainer: ViewContainerRef, private readonly _templateRef: TemplateRef<LetContext<T>>) {}

  ngOnInit(): void {
    this._viewContainer.createEmbeddedView(this._templateRef, this._context);
  }

  static ngTemplateContextGuard<T>(dir: LetDirective<T>, ctx: unknown): ctx is LetContext<T> {
    return true;
  }
}
