<ng-container *ngIf="event_and_entity$$ | async as event_n_entity">
  <div *ngIf="event_n_entity.event" [class.error]="has_error$$ | async" class="event-link-component">
    <span *ngIf="show_date" class="date" [popper]="event_n_entity.event.date | formatDate : 'full'">
      {{ event_n_entity.event.date | formatDate : 'short' }}
    </span>

    <span class="event-link-component__event-container">
      <ng-container *ngIf="event_n_entity?.event?.run && event_n_entity?.event?.run?.state">
        <i class="mdi mdi-alert-circle-outline mdi-18px red-text" *ngIf="event_n_entity.event.run.state === 'error'"></i>

        <i
          *ngIf="event_n_entity?.event?.run?.state === 'running' || event_n_entity?.event?.run?.state === 'new'"
          class="mdi mdi-autorenew mdi-spin mdi-18px"
        ></i>
      </ng-container>

      <bg2-event-span
        [desc]="desc"
        [event]="event_n_entity.event"
        [from]="event_n_entity.entity"
        [popper]="event_tooltip$$ | async"
      ></bg2-event-span>
    </span>
  </div>
</ng-container>
