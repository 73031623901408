<div class="snackbar-list-component" #snacklist>
  <!-- Trigger to resume of split runners -->
  <div class="collapse-trigger"
    *ngIf="multiple_runs$$ | async"
    [class.collapsed]="is_collapsed$$ | async"
    (click)="is_collapsed = !is_collapsed"
  >
    <ng-container *ngIf="is_collapsed$$ | async; else notCollapsedTpl">
      <span
        class="mdi mdi-arrow-collapse-up"
        [popper]="
          ('VIEWS_WINDOWED.RUN_SNACK_BAR.COMPONENTS.RUN_LIST.Show each run individually' | translate) + '...'
        "
      >
        {{ 'ALL.COMMON.Shows' | translate }}
      </span>
    </ng-container>
    <ng-template #notCollapsedTpl>
      <span
        class="mdi mdi-arrow-collapse-down"
        [popper]="('VIEWS_WINDOWED.RUN_SNACK_BAR.COMPONENTS.RUN_LIST.Show summarized runs' | translate) + '...'"
      >
        {{ 'ALL.COMMON.Reduce' | translate }}
      </span>
    </ng-template>
  </div>

  <!-- Snackbar list -->
  <div class="snackbars-content-list">
    <ng-container *ngIf="is_collapsed$$ | async; else uncollapedContent">
      <bg2-snackbar-summary [runners]="runners?.current_runners$$ | async"></bg2-snackbar-summary>
    </ng-container>

    <ng-template #uncollapedContent>
      <ng-container *ngFor="let runner of runners?.current_runners$$ | async">
        <bg2-snackbar-single [runner]="runner"></bg2-snackbar-single>
      </ng-container>
    </ng-template>
  </div>
</div>
