import { Pipe, PipeTransform } from '@angular/core';

import { Dictionary } from 'app/typings/core/interfaces/Dictionary.iface';

@Pipe({ name: 'mapArray' })
export class MapArrayPipe implements PipeTransform {
  public transform(value: Dictionary<any>[], property: string): any[] {
    return value.map(val => val[property]);
  }
}
