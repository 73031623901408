import { ChangeDetectionStrategy, Component } from '@angular/core';

import { merge } from 'lodash-es';

import { Beeguard2Api } from 'app/core';
import { AppStateService } from 'app/core/app-state.service';
import { DialogsService } from 'app/widgets/dialogs-modals/dialogs.service';

import { BgControlWidgetComponent, WidgetOptions } from '../control/control.widget';

/** */
interface NgMatTextareaWidgetOptions extends WidgetOptions {
  /** */
  max_length: number;

  /** */
  autosize: {
    /** */
    min: number;

    /** */
    max: number;
  };
}

@Component({
  selector: 'bg2-ng-mat-textarea',
  templateUrl: './ng-mat-textarea.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NgMatTextareaComponent extends BgControlWidgetComponent {
  // #region -> (component basics)

  constructor(private _bg2Api: Beeguard2Api, private _dialogs: DialogsService, private _appState: AppStateService) {
    super(_bg2Api, _appState, _dialogs);
  }

  // #endregion

  /**
   * Re-define options for this widget component
   */
  public options: NgMatTextareaWidgetOptions = merge(
    {},
    <NgMatTextareaWidgetOptions>{
      max_length: null,
      autosize: {
        min: 5,
        max: 10,
      },
    },
    super.options
  );
}
