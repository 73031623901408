import { assign } from 'lodash-es';
import { ISchema } from 'ngx-schema-form';

export const IFx2ActivationVector: ISchema = {
  type: 'object',
  label: 'Configuration activation vector',
  options: {
    indent: true,
  },
  properties: {
    gprs: {
      description_on: 'gprs',
      type: 'boolean',
      // widget: 'hidden',
    },
    sms: {
      description_on: 'sms',
      type: 'boolean',
      // widget: 'hidden',
    },
    dns: {
      description_on: 'dns',
      type: 'boolean',
      // widget: 'hidden',
    },
    rfx_1: {
      description_on: 'rfx_1',
      type: 'boolean',
    },
    rfx_2: {
      description_on: 'rfx_2',
      type: 'boolean',
      // widget: 'hidden',
    },
    rfx_3: {
      description_on: 'rfx_3',
      type: 'boolean',
      // widget: 'hidden',
    },
    alarms: {
      description_on: 'alarms',
      type: 'boolean',
      // widget: 'hidden',
    },
    tracking: {
      description_on: 'tracking',
      type: 'boolean',
      // widget: 'hidden',
    },
    shock: {
      description_on: 'shock',
      type: 'boolean',
      // widget: 'hidden',
    },
    audio: {
      description_on: 'audio',
      type: 'boolean',
      // widget: 'hidden',
    },
    alarm_event_0: {
      description_on: 'alarm_event_0',
      type: 'boolean',
      // widget: 'hidden',
    },
    alarm_event_1: {
      description_on: 'alarm_event_1',
      type: 'boolean',
      // widget: 'hidden',
    },
    alarm_event_2: {
      description_on: 'alarm_event_2',
      type: 'boolean',
      // widget: 'hidden',
    },
    alarm_event_3: {
      description_on: 'alarm_event_3',
      type: 'boolean',
      // widget: 'hidden',
    },
    alarm_event_4: {
      description_on: 'alarm_event_4',
      type: 'boolean',
      // widget: 'hidden',
    },
    alarm_event_5: {
      description_on: 'alarm_event_5',
      type: 'boolean',
      // widget: 'hidden',
    },
    alarm_event_6: {
      description_on: 'alarm_event_6',
      type: 'boolean',
      // widget: 'hidden',
    },
    alarm_event_7: {
      description_on: 'alarm_event_7',
      type: 'boolean',
      // widget: 'hidden',
    },
    alarm_event_8: {
      description_on: 'alarm_event_8',
      type: 'boolean',
      // widget: 'hidden',
    },
    alarm_event_9: {
      description_on: 'alarm_event_9',
      type: 'boolean',
      // widget: 'hidden',
    },
    log: {
      description_on: 'log',
      type: 'boolean',
      // widget: 'hidden',
    },
    vbat: {
      description_on: 'vbat',
      type: 'boolean',
    },
    button: {
      description_on: 'button',
      type: 'boolean',
    },
    activity: {
      description_on: 'activity',
      type: 'boolean',
      // widget: 'hidden',
    },
    linux_misc: {
      description_on: 'linux-misc',
      type: 'boolean',
      // widget: 'hidden',
    },
    camera: {
      description_on: 'camera',
      type: 'boolean',
      // widget: 'hidden',
    },
    ephemeride: {
      description_on: 'ephemerid',
      type: 'boolean',
    },
  },
};

export const IFx2LinuxEvent: ISchema = {
  type: 'object',
  options: {
    indent: true,
  },
  properties: {
    enable: {
      description_on: 'enable',
      type: 'boolean',
    },
    filter_linux_on: {
      label: 'filter_linux_on',
      description_on: 'Send event only if linux SOM is already on (ie. do not wake up linux SOM)',
      type: 'boolean',
      visibleIf: {
        enable: [true],
      },
    },
    filter_vbat_threshold: {
      label: 'filter_vbat_threshold',
      type: 'integer',
      description:
        'Threshold to use: 0 => No Threshold, 1 => use vbat.threshold_1_mv, 2 => use vbat.threshold_2_mv, 3 => use vbat.threshold_3_mv',
      widget: 'slider',
      minimum: 0,
      maximum: 3,
      multipleOf: 1,
      visibleIf: {
        enable: [true],
      },
    },
  },
};

function IFx2LinuxEvent_copy(overide: any, enable_description_on: string = null) {
  const _schema = assign({}, IFx2LinuxEvent, overide);
  if (enable_description_on && IFx2LinuxEvent.properties) {
    // We need to copy to modify only on field (and not other)
    _schema.properties = assign({}, IFx2LinuxEvent.properties);
    _schema.properties.enable = assign({}, IFx2LinuxEvent.properties.enable, {
      description_on: enable_description_on,
    });
  }
  return _schema;
}

export const IFx2ActivityState: ISchema = {
  type: 'object',
  options: {
    indent: true,
  },
  properties: {
    enable_pir: {
      description_on: 'enable_pir',
      type: 'boolean',
    },
  },
};

export const IFx2Activity: ISchema = {
  type: 'object',
  options: {
    indent: true,
  },
  properties: {
    state: IFx2ActivityState,
    inactive_time_sec: {
      label: 'inactive_time_sec',
      type: 'integer',
    },
    periodic_time_sec: {
      label: 'periodic_time_sec',
      type: 'integer',
    },
    event_activity_start: assign({}, IFx2LinuxEvent, {
      label: 'event_activity_start',
    }),
    event_activity_periodic: assign({}, IFx2LinuxEvent, {
      label: 'event_activity_periodic',
    }),
    event_activity_stop: assign({}, IFx2LinuxEvent, {
      label: 'event_activity_stop',
    }),
  },
};

export const IFx2AlarmEventConfiguration: ISchema = {
  type: 'object',
  label: 'Alarm Event Configuration',
  options: {
    indent: true,
  },
  properties: {
    rising_edge: {
      description_on: 'rising_edge',
      type: 'boolean',
    },
    falling_edge: {
      description_on: 'falling_edge',
      type: 'boolean',
    },
    hysteresis_pos: {
      description_on: 'hysteresis_pos',
      type: 'boolean',
    },
    hysteresis_neg: {
      description_on: 'hysteresis_neg',
      type: 'boolean',
    },
  },
};

export const IFx2AlarmEvent: ISchema = {
  type: 'object',
  options: {
    indent: true,
  },
  properties: {
    measure_id: {
      label: 'measure_id',
      type: 'string',
      widget: 'select',
      enum: [
        'TEMPERATURE',
        'TEMPERATURE_INTERNAL',
        'HUMIDITY',
        'ANEMOMETER_SPEED',
        'ANEMOMETER_HEADING',
        'RAIN_GAUGE',
        'PRESSURE',
        'ACCELEROMETER_ANGLE',
        'PYRANOMETER',
        'VOLTAGES',
      ],
    },
    event_configuration: IFx2AlarmEventConfiguration,
    threshold: {
      label: 'threshold',
      type: 'integer',
    },
    hysteresis: {
      label: 'hysteresis',
      type: 'integer',
    },
    time_on_min: {
      label: 'time_on_min',
      type: 'integer',
    },
    time_pause_min: {
      label: 'time_pause_min',
      type: 'integer',
    },
  },
};

export const IFx2Button: ISchema = {
  type: 'object',
  options: {
    indent: true,
  },
  properties: {
    btn_short_action: {
      label: 'btn_short_action',
      type: 'string',
      widget: 'select',
      enum: ['NO_ACTION', 'TRIGGER_RFX1', 'TOGGLE_WORK', 'SIMULATE_PIR'],
    },
    btn_long_action: {
      label: 'btn_long_action',
      type: 'string',
      widget: 'select',
      enum: ['NO_ACTION', 'TRIGGER_RFX1', 'TOGGLE_WORK', 'SIMULATE_PIR'],
    },
    long_threshold_ms: {
      label: 'long_threshold_ms',
      description: 'Time in miliseconds for "long" btn push',
      type: 'integer',
    },
    event_btn_short: IFx2LinuxEvent_copy(
      {
        label: 'event_btn_short',
      },
      'Send event to linux SOM on **short** btn push'
    ),
    event_btn_long: IFx2LinuxEvent_copy(
      {
        label: 'event_btn_long',
      },
      'Send event to linux SOM on **long** btn push'
    ),
  },
};

export const IFx2OneCamera: ISchema = {
  type: 'object',
  options: {
    indent: true,
  },
  properties: {
    fps: {
      label: 'fps',
      type: 'number',
      minimum: 0.1,
      maximum: 30,
      multipleOf: 0.1,
    },
    automatic_exposure: {
      description_on: 'automatic exposure',
      type: 'boolean',
    },
    exposure_time_ms: {
      label: 'exposure time (ms)',
      type: 'integer',
      minimum: 0,
      maximum: 100000,
      multipleOf: 1,
      visibleIf: {
        automatic_exposure: [false],
      },
    },

    automatic_focus: {
      description_on: 'automatic focus',
      type: 'boolean',
    },
    focus: {
      label: 'focs',
      type: 'integer',
      minimum: 0,
      maximum: 1000,
      multipleOf: 1,
      visibleIf: {
        automatic_focus: [false],
      },
    },

    automatic_gain: {
      description_on: 'automatic gain',
      type: 'boolean',
    },
    gain: {
      label: 'gain',
      type: 'integer',
      minimum: 0,
      maximum: 1000,
      multipleOf: 1,
      visibleIf: {
        automatic_gain: [false],
      },
    },

    automatic_whitebalance: {
      description_on: 'automatic whitebalance',
      type: 'boolean',
    },

    white_balance: {
      type: 'object',
      label: 'white_balance',
      visibleIf: {
        automatic_whitebalance: [false],
      },
      options: {
        indent: true,
      },
      properties: {
        red: {
          label: 'red',
          type: 'integer',
          widget: 'slider',
          minimum: 0,
          maximum: 2 ** 12 - 1,
          multipleOf: 1,
        },
        green: {
          label: 'green',
          type: 'integer',
          widget: 'slider',
          minimum: 0,
          maximum: 2 ** 12 - 1,
          multipleOf: 1,
        },
        blue: {
          label: 'blue',
          type: 'integer',
          widget: 'slider',
          minimum: 0,
          maximum: 2 ** 12 - 1,
          multipleOf: 1,
        },
      },
    },
  },
};

export const IFx2Camera: ISchema = {
  type: 'object',
  options: {
    indent: true,
  },
  properties: {
    day_night_threshold_100mlux: {
      label: 'day_night_threshold_100mlux',
      type: 'integer',
    },
    camera_1: assign({}, IFx2OneCamera, {
      label: 'Camera 1',
    }),
    camera_2: assign({}, IFx2OneCamera, {
      label: 'Camera 2',
    }),
  },
};

export const IFx2Ephemeride: ISchema = {
  type: 'object',
  options: {
    indent: true,
  },
  properties: {
    gps_source: {
      label: 'gps_source',
      type: 'string',
      widget: 'select',
      enum: ['DEFAULT', 'CONF_BOARD', 'FIX_GPS'],
    },
    offset_sunrise_deg: {
      label: 'offset_sunrise_deg',
      type: 'integer',
      description: 'In swv <= 4.2, this code both sunrise and sunset off set',
    },
    offset_sunset_deg: {
      label: 'offset_sunset_deg',
      type: 'integer',
      widget: 'hidden', // This is not implemented in device yet
    },
    offset_sunrise_sec: {
      label: 'offset_sunrise_sec',
      type: 'integer',
      description:
        'In swv <= 4.2, this code both sunrise and sunset off set (900 means wake up 15 min before sunrise, and sleep 25 min after)',
    },
    offset_sunset_sec: {
      label: 'offset_sunset_sec',
      type: 'integer',
      widget: 'hidden', // This is not implemented in device yet
    },
    lat_lgt: {
      type: 'object',
      properties: {
        lat: {
          label: 'latitude',
          type: 'integer',
        },
        lgt: {
          label: 'longitude',
          type: 'integer',
        },
      },
    },
  },
};

export const IFx2EventLinuxMisc: ISchema = {
  type: 'object',
  label: 'Linux Event',
  options: {
    indent: true,
  },
  properties: {
    event_power_on: assign({}, IFx2LinuxEvent, {
      label: 'event_power_on',
    }),
    event_init_mc: assign({}, IFx2LinuxEvent, {
      label: 'event_init_mc',
    }),
    event_ifx_order_1: assign({}, IFx2LinuxEvent, {
      label: 'event_ifx_order_1',
    }),
    event_ifx_order_2: assign({}, IFx2LinuxEvent, {
      label: 'event_ifx_order_2',
    }),
    event_spare_1: assign({}, IFx2LinuxEvent, {
      label: 'event_spare_1',
    }),
    event_spare_2: assign({}, IFx2LinuxEvent, {
      label: 'event_spare_2',
    }),
    event_spare_3: assign({}, IFx2LinuxEvent, {
      label: 'event_spare_3',
    }),
    event_spare_4: assign({}, IFx2LinuxEvent, {
      label: 'event_spare_4',
    }),
    linux_mc_functions: {
      label: 'linux_mc_functions',
      description: 'Configuration of functions between mission control (MC) and linux SOM',
      type: 'object',
      properties: {
        activate_watchdog: {
          description_on: 'Activate linux watchdog',
          type: 'boolean',
        },
      },
    },
  },
};

export const IFx2Gprs: ISchema = {
  type: 'object',
  label: 'GPRS/APN configuration',
  options: {
    indent: true,
  },
  properties: {
    gprs_apn: {
      label: 'gprs_apn',
      type: 'string',
    },
    gprs_login: {
      label: 'gprs_login',
      type: 'string',
    },
    gprs_password: {
      label: 'gprs_password',
      type: 'string',
    },
  },
  visibleIf: {
    'active_configurations/gprs': [true],
  },
};

export const IFx2LogActivationVector: ISchema = {
  type: 'object',
  label: 'Log activation',
  options: {
    indent: true,
  },
  properties: {
    debug: {
      description_on: 'debug',
      type: 'boolean',
    },
    application: {
      description_on: 'application',
      type: 'boolean',
    },
    gsm_tx: {
      description_on: 'gsm_tx',
      type: 'boolean',
    },
    gsm_rx: {
      description_on: 'gsm_rx',
      type: 'boolean',
    },
    frame: {
      description_on: 'frame',
      type: 'boolean',
    },
    gps: {
      description_on: 'gps',
      type: 'boolean',
    },
    error: {
      description_on: 'error',
      type: 'boolean',
    },
    linux: {
      description_on: 'linux',
      type: 'boolean',
    },
  },
};

export const IFx2Log: ISchema = {
  type: 'object',
  options: {
    indent: true,
  },
  properties: {
    presence_log: IFx2LogActivationVector,
    start_ts_h_log: {
      label: 'start_ts_h_log',
      type: 'integer',
    },
    stop_ts_h_log: {
      label: 'stop_ts_h_log',
      type: 'integer',
    },
  },
};

export const IFx2PRFxPresenceVector: ISchema = {
  type: 'object',
  label: 'Presence Vector',
  description: 'Activated measurements',
  options: {
    indent: true,
  },
  properties: {
    pressure: {
      description_on: 'pressure',
      type: 'boolean',
    },
    rain_gauge: {
      description_on: 'rain_gauge',
      type: 'boolean',
      // widget: 'hidden',
    },
    anemometer_heading: {
      description_on: 'anemometer_heading',
      type: 'boolean',
      // widget: 'hidden',
    },
    anemometer_speed: {
      description_on: 'anemometer_speed',
      type: 'boolean',
      // widget: 'hidden',
    },
    humidity: {
      description_on: 'humidity',
      type: 'boolean',
    },
    temperature_internal: {
      description_on: 'temperature_internal',
      type: 'boolean',
    },
    temperature: {
      description_on: 'temperature',
      type: 'boolean',
    },
    tracking: {
      description_on: 'tracking',
      type: 'boolean',
      // widget: 'hidden',
    },
    tracking_state: {
      description_on: 'tracking_state',
      type: 'boolean',
      // widget: 'hidden',
    },
    counters: {
      description_on: 'counters',
      type: 'boolean',
    },
    accelerometer_angle: {
      description_on: 'accelerometer_angle',
      type: 'boolean',
      // widget: 'hidden',
    },
    accelerometer_orientation: {
      description_on: 'accelerometer_orientation',
      type: 'boolean',
      // widget: 'hidden',
    },
    weight_1: {
      description_on: 'weight_1',
      type: 'boolean',
      // widget: 'hidden',
    },
    weight_2: {
      description_on: 'weight_2',
      type: 'boolean',
      // widget: 'hidden',
    },
    weight_3: {
      description_on: 'weight_3',
      type: 'boolean',
      // widget: 'hidden',
    },
    weight_4: {
      description_on: 'weight_4',
      type: 'boolean',
      // widget: 'hidden',
    },
    pyranometer: {
      description_on: 'pyranometer',
      type: 'boolean',
    },
    wifi_1: {
      description_on: 'wifi_1',
      type: 'boolean',
      // widget: 'hidden',
    },
    wifi_2_3: {
      description_on: 'wifi_2_3',
      type: 'boolean',
      // widget: 'hidden',
    },
    io: {
      description_on: 'io',
      type: 'boolean',
      // widget: 'hidden',
    },
    cell_ids_1: {
      description_on: 'cell_ids_1',
      type: 'boolean',
      // widget: 'hidden',
    },
    cell_ids_2_5: {
      description_on: 'cell_ids_2_5',
      type: 'boolean',
      // widget: 'hidden',
    },
    cell_ids_1_5_extended: {
      description_on: 'cell_ids_1_5_extended',
      type: 'boolean',
      // widget: 'hidden',
    },
    gps: {
      description_on: 'gps',
      type: 'boolean',
      // widget: 'hidden',
    },
    geofencing: {
      description_on: 'geofencing',
      type: 'boolean',
      // widget: 'hidden',
    },
    voltages: {
      description_on: 'voltages',
      type: 'boolean',
    },
    magnetometer_axes: {
      description_on: 'magnetometer_axes',
      type: 'boolean',
      // widget: 'hidden',
    },
    counter_shock: {
      description_on: 'counter_shock',
      type: 'boolean',
      // widget: 'hidden',
    },
    anemometer_speed_min_max: {
      description_on: 'anemometer_speed_min_max',
      type: 'boolean',
      // widget: 'hidden',
    },
    cat: {
      description_on: 'cat',
      type: 'boolean',
    },
    accelerometer_axes: {
      description_on: 'accelerometer_axes',
      type: 'boolean',
    },
    spectrum: {
      description_on: 'spectrum',
      type: 'boolean',
      // widget: 'hidden',
    },
    events: {
      description_on: 'events',
      type: 'boolean',
      // widget: 'hidden',
    },
    trackagri: {
      description_on: 'trackagri',
      type: 'boolean',
      // widget: 'hidden',
    },
    jamming_detector: {
      description_on: 'jamming_detector',
      type: 'boolean',
      // widget: 'hidden',
    },
    spectre: {
      description_on: 'spectre',
      type: 'boolean',
      // widget: 'hidden',
    },
  },
};

export const IFx2RFxComConfiguration: ISchema = {
  type: 'object',
  label: 'RFx Com Configuration',
  options: {
    indent: true,
  },
  properties: {
    com_on_ephemeride: {
      label: 'com_on_ephemeride',
      description_on: 'Overide rfx_communication_start_hour and last communication with sunrise/sunset time (see ephemerid conf)',
      type: 'boolean',
    },
  },
};

export const IFx2RFxConfiguration: ISchema = {
  type: 'object',
  label: 'RFx Configuration',
  // widget: 'hidden',
  options: {
    indent: true,
  },
  properties: {
    unactivate_periodic_measure: {
      description_on: 'unactivate_periodic_measure',
      type: 'boolean',
    },
    measure_only_during_com_period: {
      description_on: 'measure_only_during_com_period',
      type: 'boolean',
    },
    measure_time_delta_range: {
      label: 'measure_time_delta_range',
      type: 'string',
      widget: 'select',
      enum: ['SECONDS', 'MINUTES'],
    },
  },
};

export const IFx2RFxFlashConfiguration: ISchema = {
  type: 'object',
  label: 'RFx Flash Configuration',
  // widget: 'hidden',
  options: {
    indent: true,
  },
  properties: {
    gnss_force: {
      description_on: 'gnss_force',
      type: 'boolean',
    },
    flash_persistence: {
      description_on: 'flash_persistence',
      type: 'boolean',
    },
    flash_no_limit: {
      description_on: 'flash_no_limit',
      type: 'boolean',
    },
  },
};

export const IFx2RFx: ISchema = {
  type: 'object',
  description: 'Periodic measurements and communications mission configuration',
  options: {
    indent: true,
  },
  properties: {
    rfx_presence: IFx2PRFxPresenceVector,
    rfx_measure_period_sec: {
      label: 'rfx_measure_period_sec',
      description: 'Period between two measurements (in seconds)',
      type: 'integer',
    },
    rfx_intermediate_measure_sec: {
      label: 'rfx_intermediate_measure_sec',
      description:
        'Period between two intermediate measurements (in seconds). Intermediate measurements are not recorded but can triggers alarms.',
      type: 'integer',
    },
    rfx_communication_period_minute: {
      label: 'rfx_communication_period_minute',
      description: 'Period between two communications (in minutes)',
      type: 'integer',
    },
    rfx_communication_start_hour: {
      label: 'rfx_communication_start_hour',
      description: 'Hour of the first communication (UTC)',
      type: 'integer',
    },
    rfx_communication_number: {
      label: 'rfx_communication_number',
      description: 'Number of communications to do (0 means infinit communication number)',
      type: 'integer',
    },
    rfx_external_sensor_strategy: {
      label: 'rfx_external_sensor_strategy',
      description: 'Weather to activate external local sensor data collecting',
      type: 'string',
      widget: 'select',
      enum: ['UNACTIVATED', 'ACTIVATED', 'ACTIVATED_OLD'],
    },
    rfx_max_measurements: {
      label: 'rfx_max_measurements',
      description: 'Max number of measurements to send on each communication',
      type: 'integer',
    },
    rfx_com_configuration: IFx2RFxComConfiguration,
    rfx_gps_flash_configuration: IFx2RFxFlashConfiguration,
    rfx_configuration: IFx2RFxConfiguration,
    rfx_sensor_mode_auto_start_hour: {
      label: 'rfx_sensor_mode_auto_start_hour',
      type: 'integer',
      // widget: 'hidden',
    },
    rfx_sensor_mode_auto_stop_hour: {
      label: 'rfx_sensor_mode_auto_stop_hour',
      type: 'integer',
      // widget: 'hidden',
    },
    event_rfx_first: IFx2LinuxEvent_copy(
      {
        label: 'event_rfx_first',
      },
      'Send event to linux SOM on **first RFx communication**'
    ),
    event_rfx_all: IFx2LinuxEvent_copy(
      {
        label: 'event_rfx_all',
      },
      'Send event to linux SOM on **each RFx communication**'
    ),
    event_rfx_last: IFx2LinuxEvent_copy(
      {
        label: 'event_rfx_last',
      },
      'Send event to linux SOM on **last RFx communication**'
    ),
    event_rfx_meas: IFx2LinuxEvent_copy(
      {
        label: 'event_rfx_meas',
      },
      'Send event to linux SOM on **each RFx measurement**'
    ),
  },
};

export const IFx2Tracking: ISchema = {
  type: 'object',
  label: 'Tracking',
  options: {
    indent: true,
  },
  properties: {
    tracking_mode: {
      label: 'tracking_mode',
      type: 'string',
      widget: 'select',
      enum: ['UNACTIVATED', 'ACTIVATED', 'ACTIVATED_NO_PERIODIC', 'ACTIVATED_FAST', 'ACTIVATED_HISTORY', 'ACTIVATED_NO_MSG_NO_GPS'],
    },
    tracking_period: {
      label: 'tracking_period',
      type: 'integer',
    },
    tracking_stop_duration: {
      label: 'tracking_stop_duration',
      type: 'integer',
    },
    stop_threshold: {
      label: 'stop_threshold',
      type: 'integer',
    },
    move_threshold: {
      label: 'move_threshold',
      type: 'integer',
    },
    acc_nb: {
      label: 'acc_nb',
      type: 'integer',
    },
    acc_delay_off: {
      label: 'acc_delay_off',
      type: 'integer',
    },
    acc_delay: {
      label: 'acc_delay',
      type: 'integer',
    },
  },
};

export const IFx2Vbat: ISchema = {
  type: 'object',
  options: {
    indent: true,
  },
  properties: {
    threshold_1_mv: {
      label: 'threshold_1_mv',
      description: 'Battery threshold 1 (in milivolt)',
      type: 'integer',
    },
    threshold_2_mv: {
      label: 'threshold_2_mv',
      description: 'Battery threshold 2 (in milivolt)',
      type: 'integer',
    },
    threshold_3_mv: {
      label: 'threshold_3_mv',
      description: 'Battery threshold 3 (in milivolt)',
      type: 'integer',
    },
    event_bat_low_1: IFx2LinuxEvent_copy(
      {
        label: 'event_bat_low_1',
      },
      '⤵ Send event to linux SOM when going **under** the **threshold_1_mv**'
    ),
    event_bat_low_2: IFx2LinuxEvent_copy(
      {
        label: 'event_bat_low_2',
      },
      '⤵ Send event to linux SOM when going **under** the **threshold__mv**'
    ),
    event_bat_low_3: IFx2LinuxEvent_copy(
      {
        label: 'event_bat_low_3',
      },
      '⤵ Send event to linux SOM when going **under** the **threshold_3_mv**'
    ),
    event_bat_high_1: IFx2LinuxEvent_copy(
      {
        label: 'event_bat_high_1',
      },
      '⤴ Send event to linux SOM when going **over** the **threshold_1_mv**'
    ),
    event_bat_high_2: IFx2LinuxEvent_copy(
      {
        label: 'event_bat_high_2',
      },
      '⤴ Send event to linux SOM when going **over** the **threshold_2_mv**'
    ),
    event_bat_high_3: IFx2LinuxEvent_copy(
      {
        label: 'event_bat_high_3',
      },
      '⤴ Send event to linux SOM when going **over** the **threshold_3_mv**'
    ),
  },
};

export const IFx2LinuxSpecific: ISchema = {
  type: 'object',
  options: {
    indent: true,
  },
  properties: {
    broadcast: {
      type: 'object',
      label: 'broadcast',
      options: {
        indent: true,
      },
      properties: {
        communication_period_sec: {
          label: 'Communication period (sec)',
          type: 'integer',
          minimum: 0,
          multipleOf: 1,
        },
        communication_mode: {
          type: 'string',
          widget: 'select',
          enum: ['PERIODIC'],
        },
        keep_modem_activated: {
          description_on: 'Keep modem activated',
          type: 'boolean',
        },
        monitoring_period_sec: {
          label: 'Monitoring period (sec)',
          type: 'integer',
          minimum: 0,
          multipleOf: 1,
        },
      },
    },
  },
};

export const IFx2ConfigurationSchemaEmbedded: ISchema = {
  type: 'object',
  label: 'IFx2 configuration',
  description: '',
  properties: {
    sim: {
      label: 'sim',
      type: 'integer',
      // widget: 'hidden'
    },
    // timestamp: {},
    time_zone: {
      label: 'time_zone',
      type: 'integer',
      // widget: 'hidden'
    },
    gps_timeout: {
      label: 'gps_timeout',
      type: 'integer',
    },
    server_port: {
      label: 'server_port',
      type: 'integer',
      // widget: 'hidden'
    },
    server_ip: {
      label: 'server_ip',
      type: 'string',
      // widget: 'hidden'
    },
    active_configurations: IFx2ActivationVector,
    gprs: IFx2Gprs,
    rfx_1: assign({}, IFx2RFx, {
      label: 'RFX_1',
      visibleIf: {
        'active_configurations/rfx_1': [true],
      },
    }),
    rfx_2: assign({}, IFx2RFx, {
      label: 'RFX_2',
      visibleIf: {
        'active_configurations/rfx_2': [true],
      },
    }),
    rfx_3: assign({}, IFx2RFx, {
      label: 'RFX_3',
      visibleIf: {
        'active_configurations/rfx_3': [true],
      },
    }),
    tracking: assign({}, IFx2Tracking, {
      visibleIf: {
        'active_configurations/tracking': [true],
      },
    }),
    alarm_event_0: assign({}, IFx2AlarmEvent, {
      label: 'Alarm Event 0',
      visibleIf: {
        'active_configurations/alarm_event_0': [true],
      },
    }),
    alarm_event_1: assign({}, IFx2AlarmEvent, {
      label: 'Alarm Event 1',
      visibleIf: {
        'active_configurations/alarm_event_1': [true],
      },
    }),
    alarm_event_2: assign({}, IFx2AlarmEvent, {
      label: 'Alarm Event 2',
      visibleIf: {
        'active_configurations/alarm_event_2': [true],
      },
    }),
    alarm_event_3: assign({}, IFx2AlarmEvent, {
      label: 'Alarm Event 3',
      visibleIf: {
        'active_configurations/alarm_event_3': [true],
      },
    }),
    alarm_event_4: assign({}, IFx2AlarmEvent, {
      label: 'Alarm Event 4',
      visibleIf: {
        'active_configurations/alarm_event_4': [true],
      },
    }),
    alarm_event_5: assign({}, IFx2AlarmEvent, {
      label: 'Alarm Event 5',
      visibleIf: {
        'active_configurations/alarm_event_5': [true],
      },
    }),
    alarm_event_6: assign({}, IFx2AlarmEvent, {
      label: 'Alarm Event 6',
      visibleIf: {
        'active_configurations/alarm_event_6': [true],
      },
    }),
    alarm_event_7: assign({}, IFx2AlarmEvent, {
      label: 'Alarm Event 7',
      visibleIf: {
        'active_configurations/alarm_event_7': [true],
      },
    }),
    alarm_event_8: assign({}, IFx2AlarmEvent, {
      label: 'Alarm Event 8',
      visibleIf: {
        'active_configurations/alarm_event_8': [true],
      },
    }),
    alarm_event_9: assign({}, IFx2AlarmEvent, {
      label: 'Alarm Event 9',
      visibleIf: {
        'active_configurations/alarm_event_9': [true],
      },
    }),
    log: assign({}, IFx2Log, {
      label: 'Log 🪵',
      visibleIf: {
        'active_configurations/log': [true],
      },
    }),
    vbat: assign({}, IFx2Vbat, {
      label: 'VBat',
      visibleIf: {
        'active_configurations/vbat': [true],
      },
    }),
    button: assign({}, IFx2Button, {
      label: 'Button',
      visibleIf: {
        'active_configurations/button': [true],
      },
    }),
    activity: assign({}, IFx2Activity, {
      label: 'Activity/PIR',
      visibleIf: {
        'active_configurations/activity': [true],
      },
    }),
    linux_misc: assign({}, IFx2EventLinuxMisc, {
      label: 'Linux Misc',
      visibleIf: {
        'active_configurations/linux_misc': [true],
      },
    }),
    camera: assign({}, IFx2Camera, {
      label: 'Camera 🎥',
      visibleIf: {
        'active_configurations/camera': [true],
      },
    }),
    ephemeride: assign({}, IFx2Ephemeride, {
      label: 'Ephemeride 🌅',
      visibleIf: {
        'active_configurations/ephemeride': [true],
      },
    }),
    linux_specific: assign({}, IFx2LinuxSpecific, {
      label: 'Linux Specific 🐧',
    }),

    // _cls: {
    //   type: "string",
    // },
    // reset: {
    //   readOnly: true
    // },
    // reset_full: {
    //   $ref: '#/definitions/Reset'
    // },
    // order: {
    //   readOnly: true
    // },
    // order_full: {
    //   $ref: '#/definitions/Order'
    // },
  },
  required: ['active_configurations'],
};

export const IFx2ConfigurationSchema: ISchema = {
  type: 'object',
  title: 'Full configuration (IFx2)',
  properties: {
    embedded: IFx2ConfigurationSchemaEmbedded,
  },
};
