import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output, TemplateRef } from '@angular/core';

import { Bg2ButtonType } from '../button/button.component';

@Component({
  selector: 'bg2-button-condition',
  templateUrl: './button-condition.component.html',
  styleUrls: ['./button-condition.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class Bg2ButtonConditionComponent {
  @Input()
  public colors: [string, string] = [undefined, undefined];

  @Input()
  public loadings: [boolean, boolean] = [false, false];

  @Input()
  public disables: [boolean, boolean] = [false, false];

  @Input()
  public types: [Bg2ButtonType, Bg2ButtonType] = [null, null];

  @Input()
  public icons: [string | undefined, string | undefined] = [undefined, undefined];

  @Input()
  public messages: [TemplateRef<any> | string | undefined, TemplateRef<any> | string | undefined] = [null, null];

  @Input()
  public internal_routing_0: { routerLink: any; queryParamsHandling?: any; queryParams?: any } = null;

  @Input()
  public internal_routing_1: { routerLink: any; queryParamsHandling?: any; queryParams?: any } = null;

  @Output()
  public btn_0_click = new EventEmitter();

  @Output()
  public btn_1_click = new EventEmitter();

  // #region -> (component basics)

  constructor() {}

  // #endregion
}
