import { combineLatest, Observable, of } from 'rxjs';
import { switchMap, map, take } from 'rxjs';

import { marker as i18n } from '@biesbjerg/ngx-translate-extract-marker';

import { Event, I18NParams } from './Event';

import { Entity } from '../entities';
import { ExportHeader, ExportedRow } from '../export';
import { TranslateService } from '@ngx-translate/core';
import { isNil } from 'lodash-es';
import { distinctUntilRealChanged, replay, waitForNotNilValue } from '@bg2app/tools/rxjs';
import { HoneyType } from 'app/views/events/shared/events/superbox_harvest/superbox-harvest-histogram-honey-factory-quantity/superbox-harvest-histogram-honey-factory-quantity.component';

export class SuperBoxAdd extends Event {
  protected getDescKey(role: any, schema: any, i18nData: any): Observable<string> {
    return super.getDescKey(role, schema, i18nData).pipe(
      map(desc_key => {
        if (role === 'apiary') {
          if (this.data.superbox.nbs_diff < 0) {
            if (this.data.superbox.nbs_final === 0) {
              return i18n(
                'EVENT.SUPERBOX_ADD.Remove all [computed->nbs_adiff] supers from the apiary (location [entities->location->link])'
              );
            } else {
              return i18n(
                'EVENT.SUPERBOX_ADD.Remove [computed->nbs_adiff] supers, still [data->superbox->nbs_final] in place (location [entities->location->link])'
              );
            }
          } else if (this.data.superbox.nbs_diff === this.data.superbox.nbs_final) {
            return i18n('EVENT.SUPERBOX_ADD.Add [data->superbox->nbs_diff] supers (location [entities->location->link])');
          }
        } else if (role === 'location') {
          if (this.data.superbox.nbs_diff < 0) {
            if (this.data.superbox.nbs_final === 0) {
              return i18n('EVENT.SUPERBOX_ADD.Remove all [computed->nbs_adiff] supers from the apiary [entities->apiary->name]');
            } else {
              return i18n(
                'EVENT.SUPERBOX_ADD.Remove [computed->nbs_adiff] supers, still [data->superbox->nbs_final] in place (on apiary [entities->apiary->name])'
              );
            }
          } else if (this.data.superbox.nbs_diff === this.data.superbox.nbs_final) {
            return i18n('EVENT.SUPERBOX_ADD.Add [data->superbox->nbs_diff] supers (on apiary [entities->apiary->name])');
          }
        } else if (role === 'warehouse') {
          if (this.data.superbox.nbs_diff < 0) {
            return i18n(
              'EVENT.SUPERBOX_ADD.Remove [computed->nbs_adiff] supers on apiary [entities->apiary->name] (location [entities->location->link])'
            );
          }
        }
        return desc_key;
      })
    );
  }

  protected getI18nParams(translate_service: any, entities: any) {
    return super.getI18nParams(translate_service, entities).pipe(
      map(i18nParams => {
        i18nParams.computed = {
          nbs_adiff: Math.abs(i18nParams.data.superbox.nbs_diff),
        };
        return i18nParams;
      })
    );
  }
}

export interface TotalHarvestedForHoneyType {
  nb_harvested_supers: number;
  honey_type: `HONEY_TYPE.${HoneyType}`;
}

export class SuperBoxHarvest extends Event {
  /**
   * Observble on the comment of the event.
   */
  public comment$$: Observable<string> = this.data$$.pipe(
    map(data => data?.comment || null),
    distinctUntilRealChanged(),
    replay()
  );

  /**
   * Observable on the harvested honey type.
   */
  public honey_type$$: Observable<`HONEY_TYPE.${HoneyType}`> = this.data$$.pipe(
    map(data => data.honey_type?.type),
    distinctUntilRealChanged(),
    replay()
  );

  /**
   * Observes the honey image URL (CSS).
   * 
   * @note Added fix on 05/30/22 to handle unsupported spaces in file name for url CSS property.
   *
   * @public
   */
  public honey_type__image_css$$ = this.honey_type$$.pipe(
    waitForNotNilValue(),
    map(honey_type => {
      if (honey_type.indexOf(' ') >= 0) {
        return `url(assets/img/honey_type/${honey_type.replace(' ', '_')}.jpg)`;
      }

      return `url(assets/img/honey_type/${honey_type}.jpg)`;
    })
  );

  /**
   * Observable on the harvested filling repartition.
   */
  public filling_repartition$$: Observable<{ filling: number; nb_superbox: number }[]> = this.data$$.pipe(
    map(data => data?.filling_repartition),
    distinctUntilRealChanged(),
    replay()
  );

  /**
   * Observable on the superbox repartition after harvesting.
   */
  public superbox$$: Observable<{ stock: number; nbs_diff: number; nbs_final: number; repartition: any[] }> = this.data$$.pipe(
    map(data => data?.superbox),
    distinctUntilRealChanged(),
    replay()
  );

  public nb_harvested_supers$$: Observable<number> = this.superbox$$.pipe(
    map(superbox_holder => Math.abs(superbox_holder?.nbs_diff ?? 0)),
    replay()
  );

  // #region -> (combined data)

  public total_harvested_honey_type$$: Observable<TotalHarvestedForHoneyType> = combineLatest([
    this.nb_harvested_supers$$,
    this.honey_type$$,
  ]).pipe(
    map(([nb_harvested_supers, honey_type]) => ({ nb_harvested_supers, honey_type })),
    distinctUntilRealChanged()
  );

  // #endregion

  get honey_type(): string {
    if (this.data.honey_type.type === 'HONEY_TYPE.OTHER') {
      return this.data.honey_type.type_other;
    } else {
      return this.data.honey_type.type;
    }
  }

  get nb_supers_harvested(): number {
    return -this.data.superbox.nbs_diff;
  }

  get comment(): string {
    return this.data.comment || null;
  }

  protected getI18nParams(translate_service: any, entities: any) {
    return super.getI18nParams(translate_service, entities).pipe(
      map(i18nParams => {
        i18nParams.computed = {
          nbs_harvested: -i18nParams.data.superbox.nbs_diff,
        };
        return i18nParams;
      }),
      switchMap((i18nParams): Observable<I18NParams> => {
        const honey_type = i18nParams.data.honey_type.type;
        let honey_type$;
        if (honey_type === 'HONEY_TYPE.OTHER') {
          honey_type$ = of(i18nParams.data.honey_type.type_other);
        } else {
          honey_type$ = translate_service.stream(honey_type);
        }
        return honey_type$.pipe(
          map(honey_type_val => {
            i18nParams.computed.honey_type = honey_type_val;
            return i18nParams;
          })
        );
      })
    );
  }

  public getExportRowBuilder(headers: ExportHeader[], translateService: TranslateService): Observable<ExportedRow> {
    let row_builder = super.getExportRowBuilder(headers, translateService);
    headers.forEach((header: ExportHeader) => {
      switch (header.name) {
        case 'base_informations': {
          row_builder = row_builder.pipe(
            switchMap((row: ExportedRow) =>
              this.entities$$.pipe(
                take(1),
                map((entities: { [role: string]: Entity | Entity[] }) => {
                  const base_informations = row.base_informations || {};
                  if (!isNil(entities)) {
                    header.sub_headers.forEach((subHeader: ExportHeader) => {
                      switch (subHeader.name) {
                        case 'apiary_name':
                          base_informations.apiary_name = (entities as any).apiary.name;
                          break;

                        case 'location_name':
                          base_informations.location_name = (entities as any).location.name;
                          break;
                      }
                    });
                  }
                  row.base_informations = base_informations;
                  return row;
                })
              )
            )
          );
          break;
        }

        // Get honey type and nb supers harvested
        case 'harvest': {
          row_builder = row_builder.pipe(
            switchMap((row: ExportedRow) => {
              row[header.name] = {};
              header.sub_headers.forEach((subHeader: ExportHeader) => {
                if (subHeader.name === 'honey_type') {
                  row[header.name][subHeader.name] = translateService.instant(this[subHeader.name]);
                } else {
                  // nb_supers_harvested & Comment
                  row[header.name][subHeader.name] = (this as any)[subHeader.name] || '';
                }
              });
              return of(row);
            })
          );
          break;
        }
      }
    });

    return row_builder;
  }
}
