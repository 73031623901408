type DateAsString = `${string}-${string}-${string}` | `${string}-${string}`

export const parse_version = (full_version: string): DateAsString => {
  const pattern = /(release|beta)-bg[0-9]{4}-(01|02|03|04|05|06|07|08|09|10|11|12)(?:-[0-3][0-9])?-(b|r)[0-9]{1,}/g;
  const has_correct_format = pattern.test(full_version);

  if (!has_correct_format) {
    return null;
  }

  // Format current version
  const is_beta_release = full_version.startsWith('beta-bg');

  // Should give `2019-07-r15` or `2019-07-12-r0`
  const [version_header, version_with_fix] = full_version.split(is_beta_release ? 'beta-bg' : 'release-bg');

  // Should give `2019-07` or `2019-07-12`
  const [version, version_fix] = version_with_fix.split(is_beta_release ? '-b' : '-r');

  return version as DateAsString;
};
