// From @node_modules/@angular/*
import { Component, Input, ChangeDetectionStrategy, OnDestroy } from '@angular/core';

// From @node_modules/lodash/*
import { isNil } from 'lodash-es';

// From @src/app/core/*
import { AppStateService } from 'app/core/app-state.service';

// From @src/app/models/*
import { DRDevice } from 'app/models';
import { openGrafanaForSingleDevice } from 'app/models/devices/DRDevice';
import { DeviceApi } from 'app/core';
import { BehaviorSubject, Subscription, switchMap, tap } from 'rxjs';
import { waitForNotNilValue } from '@bg2app/tools/rxjs';
import { DialogsService } from 'app/widgets/dialogs-modals';
import { DevicesBulkBatteryChangeDialogComponent } from '../../dialogs-and-modals/dialogs';

@Component({
  selector: 'bg2-device-inline',
  templateUrl: './device-inline.component.html',
  styleUrls: ['./device-inline.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DeviceInlineComponent implements OnDestroy {
  public readonly openGrafanaForSingleDevice = openGrafanaForSingleDevice;

  @Input()
  public hive_id: number;
  
  @Input()
  public open_in_modal = true;

  @Input()
  public show_status = true;
  
  @Input()
  public show_base = true;

  @Input()
  public show_action = true;

  @Input()
  public show_last_com = true;

  @Input()
  public link_to_device = true;

  /** */
  private _load_device_from_imei_sub: Subscription = null;

  constructor(public appState: AppStateService, private readonly _device_api: DeviceApi, private _dialogs: DialogsService) {
    this._load_device_from_imei_sub = this._device_imei$$
      .asObservable()
      .pipe(
        waitForNotNilValue(),
        switchMap(device_imei => this._device_api.requestDevice(device_imei)),
      )
      .subscribe({
        next: device => this._device$$.next(device),
      });
  }

  ngOnDestroy(): void {
    this._load_device_from_imei_sub?.unsubscribe();
  }

  // #region -> (device)

  /** */
  private _device$$ = new BehaviorSubject<DRDevice>(null);

  /** */
  public device$$ = this._device$$.asObservable();

  @Input()
  public set device(device: DRDevice) {
    this._device$$.next(device);
  }

  public get device(): DRDevice {
    return this._device$$.getValue();
  }

  private _device_imei$$ = new BehaviorSubject<number>(null);

  @Input()
  public set device_imei(device_imei: number) {
    this._device_imei$$.next(device_imei);
  }

  // #endregion

  public replace_battery(device: DRDevice): void {
    this._dialogs.open(DevicesBulkBatteryChangeDialogComponent, { devices: [device] }).subscribe();
  }
}
