<bg2-modal [fullscreen]="fullscreen || false" #modal style="height: 100%">
  <bg2-modal-header>
    <div class="header">
      <span class="title">
        {{ 'VIEWS.DEVICES.DIALOGS_AND_MODALS.MODALS.DEVICE_SUPPORT.DEVICE_SUPPORT_CLOSE_MODAL.End of support' | translate }}
      </span>
    </div>

    <div class="action-buttons">
      <span class="mdi mdi-close-box mdi-36px" (click)="close()"></span>
    </div>
  </bg2-modal-header>

  <bg2-modal-content>
    <bg2-error-helper *ngIf="(is_authenticated_to_zoho$$ | async) === false" [error]="not_authenticated_error"></bg2-error-helper>

    <!-- Selected ticket -->
    <div *ngIf="is_authenticated_to_zoho$$ | async" class="selected-ticket">
      <bg2-zoho-ticket-summary [ticket]="selected_desk_ticket$$ | async"></bg2-zoho-ticket-summary>

      <bg2-loading-skeleton
        *ngIf="(is_authenticated_to_zoho$$ | async) && is_loading_ticket.value$$ | async"
        [visible_message]="true"
        [theme]="{ width: '100%', height: '100%' }"
        [string_model]="
          ('VIEWS.DEVICES.DIALOGS_AND_MODALS.MODALS.DEVICE_SUPPORT.DEVICE_SUPPORT_CLOSE_MODAL.LOADING.Loading DESK ticket' | translate) +
          ' ...'
        "
      ></bg2-loading-skeleton>
    </div>

    <mat-stepper *ngIf="is_authenticated_to_zoho$$ | async" [orientation]="'vertical'" [linear]="true" #stepper>
      <!-- Check and select ticket related devices -->
      <mat-step [editable]="(is_saving.value$$ | async) === false" [completed]="is_step_1_valid$$ | async">
        <ng-template matStepLabel>
          {{
            'VIEWS.DEVICES.DIALOGS_AND_MODALS.MODALS.DEVICE_SUPPORT.DEVICE_SUPPORT_CLOSE_MODAL.STEP_SELECTION.Devices selection' | translate
          }}
        </ng-template>

        <ng-template matStepContent>
          <div class="mat-step-container">
            <bg2-error-helper *ngIf="error$$ | async; let error" [error]="error"></bg2-error-helper>

            <bg2-loading-skeleton
              *ngIf="is_loading_selectable_device.value$$ | async"
              [visible_message]="true"
              [theme]="{ width: '100%', height: '100%' }"
              [string_model]="
                ('VIEWS.DEVICES.DIALOGS_AND_MODALS.MODALS.DEVICE_SUPPORT.DEVICE_SUPPORT_CLOSE_MODAL.LOADING.Loading devices' | translate) +
                ' ...'
              "
            ></bg2-loading-skeleton>

            <div class="devices-list">
              <table>
                <thead>
                  <th>{{ 'DEVICE.ALL.COMMON.Device' | translate }}</th>

                  <th>
                    {{
                      'VIEWS.DEVICES.DIALOGS_AND_MODALS.MODALS.DEVICE_SUPPORT.DEVICE_SUPPORT_CLOSE_MODAL.STEP_SELECTION.Support (in-APP)'
                        | translate
                    }}
                  </th>

                  <th>
                    {{
                      'VIEWS.DEVICES.DIALOGS_AND_MODALS.MODALS.DEVICE_SUPPORT.DEVICE_SUPPORT_CLOSE_MODAL.STEP_SELECTION.Support (in-DESK)'
                        | translate
                    }}
                  </th>

                  <th class="select-all">
                    <div>
                      <span>
                        {{
                          'VIEWS.DEVICES.DIALOGS_AND_MODALS.MODALS.DEVICE_SUPPORT.DEVICE_SUPPORT_CLOSE_MODAL.STEP_SELECTION.Close RU ?'
                            | translate
                        }}
                      </span>

                      <mat-checkbox
                        *ngLet="devices_still_in_ru_after_select$$ | async; let devices_still_in_ru"
                        [color]="'primary'"
                        [checked]="devices_still_in_ru?.length === 0"
                        [indeterminate]="devices_still_in_ru?.length > 0 && (selected_imeis$$ | async)?.length !== 0"
                        (change)="select_all_devices($event.checked)"
                      >
                        {{
                          'VIEWS.DEVICES.DIALOGS_AND_MODALS.MODALS.DEVICE_SUPPORT.DEVICE_SUPPORT_CLOSE_MODAL.STEP_SELECTION.Select all devices'
                            | translate
                        }}
                      </mat-checkbox>
                    </div>
                  </th>
                </thead>

                <tbody>
                  <ng-container *ngFor="let selectable_device of selectable_devices$$ | async">
                    <tr>
                      <!-- Device identity -->
                      <td *ngLet="selectable_device?.device; let device" class="device-identity">
                        <div>
                          <span>{{ device?.name }}</span>
                          <span>({{ device?.imei$$ | async }})</span>
                        </div>
                      </td>

                      <!-- Device support APP -->
                      <td *ngLet="selectable_device?.support; let support">
                        <ng-container *ngIf="support?.is_open">
                          {{
                            'VIEWS.DEVICES.DIALOGS_AND_MODALS.MODALS.DEVICE_SUPPORT.DEVICE_SUPPORT_CLOSE_MODAL.STEP_SELECTION.Support opened'
                              | translate
                          }}
                        </ng-container>

                        <ng-container *ngIf="!support?.is_open">
                          {{
                            'VIEWS.DEVICES.DIALOGS_AND_MODALS.MODALS.DEVICE_SUPPORT.DEVICE_SUPPORT_CLOSE_MODAL.STEP_SELECTION.Support already closed'
                              | translate
                          }}
                        </ng-container>
                      </td>

                      <!-- Device support DESK -->
                      <td *ngLet="selectable_device?.support; let support">
                        {{ support?.issue_id ?? '--' }}
                      </td>

                      <!-- Validation -->
                      <td
                        class="select-case"
                        [ngClass]="{ disabled: selectable_device.disabled }"
                        (click)="selectable_device.disabled ? null : update_selected_imei(selectable_device.device.imei)"
                      >
                        <mat-checkbox
                          [color]="'primary'"
                          [disabled]="selectable_device.disabled"
                          [checked]="is_imei_selected$$(selectable_device.device.imei) | async"
                          (click)="$event.stopImmediatePropagation()"
                          (change)="update_selected_imei(selectable_device.device.imei)"
                        ></mat-checkbox>
                      </td>
                    </tr>
                  </ng-container>
                </tbody>
              </table>
            </div>

            <div class="step-actions">
              <bg2-button
                [type]="'action'"
                [icon]="'mdi-chevron-triple-down'"
                [message]="'ALL.COMMON.Next step' | translate"
                [disabled]="(is_step_1_valid$$ | async) === false"
                (btn_click)="next_step(stepper)"
              ></bg2-button>
            </div>
          </div>
        </ng-template>
      </mat-step>

      <!-- Select RU closure date -->
      <mat-step [editable]="(is_saving.value$$ | async) === false" [completed]="is_step_2_valid$$ | async">
        <ng-template matStepLabel>
          {{ 'VIEWS.DEVICES.DIALOGS_AND_MODALS.MODALS.DEVICE_SUPPORT.DEVICE_SUPPORT_CLOSE_MODAL.Configure end of support' | translate }}
        </ng-template>

        <ng-template matStepContent>
          <div class="mat-step-container">
            <div class="step-actions">
              <bg2-button
                [type]="'action'"
                [icon]="'mdi-chevron-triple-up'"
                [message]="'ALL.COMMON.Previous step' | translate"
                (btn_click)="previous_step(stepper)"
              ></bg2-button>
            </div>

            <bg2-form-overlay
              style="padding: 0px 10px"
              [source_schema]="step_2_schema$$ | async"
              [source_model]="step_2_schema_model.value$$ | async"
              (when_form_data_update)="step_2_schema_model.value = $event"
              (when_form_valid_update)="step_2_schema_valid.value = $event"
            ></bg2-form-overlay>

            <ng-container *ngIf="(can_close_ticket$$ | async) === false">
              <bg2-ui-message
                [type]="'alert'"
                [title]="
                  'VIEWS.DEVICES.DIALOGS_AND_MODALS.MODALS.DEVICE_SUPPORT.DEVICE_SUPPORT_CLOSE_MODAL.STEP_CONFIGURE_END_OF_SUPPORT.ERROR.Unable to close DESK ticket'
                    | translate
                "
              >
                <div message>
                  <span>
                    {{
                      'VIEWS.DEVICES.DIALOGS_AND_MODALS.MODALS.DEVICE_SUPPORT.DEVICE_SUPPORT_CLOSE_MODAL.STEP_CONFIGURE_END_OF_SUPPORT.ERROR.You cannot mark as "closed" a DESK ticket that still has devices with open support'
                        | translate
                    }}
                  </span>
                </div>
              </bg2-ui-message>
            </ng-container>

            <div class="devices-list">
              <table [formGroup]="configure_support_form">
                <thead>
                  <th>{{ 'DEVICE.ALL.COMMON.Device' | translate }}</th>
                  <th>{{ 'DEVICE.SUPPORTS.NEXT_USAGE.Next usage' | translate }}</th>
                </thead>

                <tbody>
                  <ng-container *ngFor="let selectable_device of selected_devices$$ | async; let index = index">
                    <tr [formGroup]="configure_support_form.controls[selectable_device.device.imei.toString()]">
                      <!-- Device identity -->
                      <td *ngLet="selectable_device?.device; let device" class="device-identity">
                        <div>
                          <span>{{ device?.name }}</span>
                          <span>({{ device?.imei$$ | async }})</span>
                        </div>
                      </td>

                      <!-- Next usage -->
                      <td
                        class="device-next-usage"
                        [ngStyle]="{
                          'border-width': '2px',
                          'border-style': 'solid',
                          'border-color': (is_device_next_usage_valid$$(selectable_device.device.imei) | async) ? '#4caf50' : 'red'
                        }"
                      >
                        <div>
                          <mat-form-field [appearance]="'outline'">
                            <mat-label>{{ 'DEVICE.SUPPORTS.NEXT_USAGE.Next usage' | translate }}</mat-label>
                            <mtx-select
                              [required]="true"
                              [searchable]="true"
                              [bindValue]="'value'"
                              [items]="next_usage_options"
                              [formControlName]="'next_usage'"
                            >
                              <!-- Template: selected item -->
                              <ng-template ng-label-tmp let-item="item">
                                <div class="device-support-close-modal-component_select-device-next-usage_selected-option">
                                  <img height="15" width="15" [src]="'assets/' + item.image" />
                                  <span>{{ item.label | translate }}</span>
                                </div>
                              </ng-template>

                              <!-- Template: item in dropdown -->
                              <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
                                <div class="device-support-close-modal-component_select-device-next-usage_dropdown-option">
                                  <img [src]="'assets/' + item.image" alt="" />

                                  <div>
                                    <span>{{ item.label | translate }}</span>
                                  </div>
                                </div>
                              </ng-template>
                            </mtx-select>
                          </mat-form-field>

                          <bg2-form-overlay
                            *ngIf="
                              configure_support_form.controls[selectable_device.device.imei.toString()].controls['next_usage'].value !==
                              'send_back_to_customer'
                            "
                            style="width: 100%"
                            [source_schema]="REPLACE_WITH_DEVICE_SCHEMA"
                            [source_model]="device_next_usage_form_value$$(selectable_device.device.imei) | async"
                            (when_form_data_update)="on_update_device_replace(selectable_device.device.imei, $event)"
                          ></bg2-form-overlay>

                          <mat-form-field appearance="outline" class="ng-default" style="width: 100%">
                            <mat-label>{{ 'ALL.COMMON.Comment' | translate }}</mat-label>
                            <textarea
                              matInput
                              cdkTextareaAutosize
                              #autosize="cdkTextareaAutosize"
                              cdkAutosizeMinRows="5"
                              cdkAutosizeMaxRows="5"
                              [formControlName]="'comment'"
                            ></textarea>
                          </mat-form-field>

                          <ng-container *ngIf="index === 0 && (selected_devices$$ | async)?.length > 1">
                            <bg2-button
                              class="w-100"
                              [type]="'action'"
                              [options]="{ is_thin: true }"
                              [icon]="'mdi-content-duplicate'"
                              [message]="
                                'VIEWS.DEVICES.DIALOGS_AND_MODALS.MODALS.DEVICE_SUPPORT.DEVICE_SUPPORT_CLOSE_MODAL.STEP_CONFIGURE_END_OF_SUPPORT.Apply this setting to all the following devices'
                                  | translate
                              "
                              (btn_click)="duplicate_configuration_to_all_devices(selectable_device.device.imei)"
                            ></bg2-button>
                          </ng-container>
                        </div>
                      </td>
                    </tr>
                  </ng-container>
                </tbody>
              </table>
            </div>

            <div class="step-actions">
              <bg2-button
                [type]="'action'"
                [icon]="'mdi-chevron-triple-down'"
                [message]="'ALL.COMMON.Next step' | translate"
                [disabled]="(is_step_2_valid$$ | async) === false"
                (btn_click)="next_step(stepper)"
              ></bg2-button>
            </div>
          </div>
        </ng-template>
      </mat-step>

      <!-- Summary -->
      <mat-step>
        <ng-template matStepLabel>
          {{
            'VIEWS.DEVICES.DIALOGS_AND_MODALS.MODALS.DEVICE_SUPPORT.DEVICE_SUPPORT_CLOSE_MODAL.STEP_REVIEW.Summary of modifications'
              | translate
          }}
        </ng-template>

        <ng-template matStepContent>
          <div class="mat-step-container --review-and-save">
            <div class="step-actions">
              <bg2-button
                [type]="'action'"
                [icon]="'mdi-chevron-triple-up'"
                [message]="'ALL.COMMON.Previous step' | translate"
                (btn_click)="previous_step(stepper)"
              ></bg2-button>
            </div>

            <div *ngLet="review_summary$$ | async; let review_summary" class="reviews-container">
              <div class="reviews-container__review">
                <span class="reviews-container__review__title">
                  {{
                    'VIEWS.DEVICES.DIALOGS_AND_MODALS.MODALS.DEVICE_SUPPORT.DEVICE_SUPPORT_CLOSE_MODAL.STEP_REVIEW.What will happen to the devices ?'
                      | translate
                  }}
                </span>

                <bg2-ui-message [type]="'info'" [title]="''">
                  <div style="display: flex; flex-flow: column nowrap" message>
                    <span>
                      {{
                        'VIEWS.DEVICES.DIALOGS_AND_MODALS.MODALS.DEVICE_SUPPORT.DEVICE_SUPPORT_CLOSE_MODAL.STEP_REVIEW.The support of every device will be closed'
                          | translate
                      }}
                    </span>
                  </div>
                </bg2-ui-message>

                <div class="devices-list">
                  <table [formGroup]="configure_support_form">
                    <thead>
                      <th>{{ 'DEVICE.ALL.COMMON.Device' | translate }}</th>
                      <th>{{ 'DEVICE.SUPPORTS.NEXT_USAGE.Next usage' | translate }}</th>
                      <th>{{ 'DEVICE.SUPPORTS.NEXT_USAGE.Replace with' | translate }}</th>
                    </thead>

                    <tbody>
                      <ng-container *ngFor="let device_x_support of review_summary?.by_device">
                        <tr>
                          <!-- Device identity -->
                          <td *ngLet="device_x_support?.device; let device" class="device-identity">
                            <div>
                              <span>{{ device?.name }}</span>
                              <span>({{ device?.imei$$ | async }})</span>
                            </div>
                          </td>

                          <!-- Next usage -->
                          <td *ngLet="device_x_support?.next_usage?.next_usage; let next_usage" class="device-review-next-usage">
                            <div *ngLet="device_x_support?.next_usage?.replacement; let replacement">
                              <ng-container *ngIf="replacement?.keep_old_device">
                                {{
                                  'VIEWS.DEVICES.DIALOGS_AND_MODALS.MODALS.DEVICE_SUPPORT.DEVICE_SUPPORT_CLOSE_MODAL.Keep replaced device in client warehouse'
                                    | translate
                                }}
                              </ng-container>

                              <ng-container *ngIf="!replacement?.keep_old_device">
                                <img
                                  *ngIf="next_usage === 'send_back_to_customer'"
                                  src="assets/picons/from_mdi/truck-delivery.svg"
                                  alt=""
                                />
                                <img *ngIf="next_usage === 'defective_stock'" src="assets/picons/stock-defective.svg" alt="" />
                                <img *ngIf="next_usage === 'functional_stock'" src="assets/picons/stock-functional.svg" alt="" />
                                <img *ngIf="next_usage === 'untested_stock'" src="assets/picons/stock-untested.svg" alt="" />

                                <span>{{ 'DEVICE.SUPPORTS.NEXT_USAGE.' + next_usage | translate }}</span>
                              </ng-container>
                            </div>
                          </td>

                          <td *ngLet="device_x_support?.next_usage?.replacement; let replacement" class="device-review-replacement">
                            <div>
                              <span *ngIf="!replacement?.replace_device">X</span>

                              <ng-container *ngIf="replacement?.replace_device">
                                <span>{{ replacement?.new_device?.device?.name }}</span>
                                <span>
                                  ({{
                                    replacement?.reinstall_in_hive_or_apiary
                                      ? 'VIEWS.DEVICES.DIALOGS_AND_MODALS.MODALS.DEVICE_SUPPORT.DEVICE_SUPPORT_CLOSE_MODAL.STEP_REVIEW.reinstalled in hive or apiary'
                                      : 'VIEWS.DEVICES.DIALOGS_AND_MODALS.MODALS.DEVICE_SUPPORT.DEVICE_SUPPORT_CLOSE_MODAL.STEP_REVIEW.not reinstalled in hive or apiary'
                                  }})
                                </span>
                              </ng-container>
                            </div>
                          </td>
                        </tr>
                      </ng-container>
                    </tbody>
                  </table>
                </div>
              </div>

              <div class="reviews-container__review">
                <span class="reviews-container__review__title">
                  {{
                    'VIEWS.DEVICES.DIALOGS_AND_MODALS.MODALS.DEVICE_SUPPORT.DEVICE_SUPPORT_CLOSE_MODAL.STEP_REVIEW.What will happen to the DESK ticket ?'
                      | translate
                  }}
                </span>

                <bg2-ui-message [type]="'info'" [title]="''">
                  <div style="display: flex; flex-flow: column nowrap" message>
                    <span>
                      {{
                        'VIEWS.DEVICES.DIALOGS_AND_MODALS.MODALS.DEVICE_SUPPORT.DEVICE_SUPPORT_CLOSE_MODAL.STEP_REVIEW.The current summary will be added as a comment to the ticket'
                          | translate
                      }}
                    </span>

                    <span>
                      {{
                        'VIEWS.DEVICES.DIALOGS_AND_MODALS.MODALS.DEVICE_SUPPORT.DEVICE_SUPPORT_CLOSE_MODAL.STEP_REVIEW.The ticket status will be set to "[ticket_status]"'
                          | translate : { ticket_status: review_summary.ticket.status }
                      }}
                    </span>
                  </div>
                </bg2-ui-message>
              </div>

              <div *ngIf="(devices_still_in_ru_after_select$$ | async)?.length > 0" class="reviews-container__review">
                <span class="reviews-container__review__title">
                  {{
                    'VIEWS.DEVICES.DIALOGS_AND_MODALS.MODALS.DEVICE_SUPPORT.DEVICE_SUPPORT_CLOSE_MODAL.STEP_REVIEW.What remains to be done ?'
                      | translate
                  }}
                </span>

                <bg2-ui-message [type]="'warning'" [title]="''">
                  <div style="display: flex; flex-flow: column nowrap" message>
                    <span>
                      {{
                        'VIEWS.DEVICES.DIALOGS_AND_MODALS.MODALS.DEVICE_SUPPORT.DEVICE_SUPPORT_CLOSE_MODAL.STEP_REVIEW.The devices "[devices]" still have open support'
                          | translate
                            : {
                                devices: devices_still_in_ru_after_select$$ | async | mapArray : 'name' | join : ', '
                              }
                      }}
                    </span>

                    <span>
                      {{
                        'VIEWS.DEVICES.DIALOGS_AND_MODALS.MODALS.DEVICE_SUPPORT.DEVICE_SUPPORT_CLOSE_MODAL.STEP_REVIEW.We remind you not to close the DESK ticket while some devices are still marked in "RU"'
                          | translate
                      }}
                    </span>
                  </div>
                </bg2-ui-message>
              </div>
            </div>

            <div class="step-actions">
              <bg2-button
                [type]="'action'"
                [icon]="'mdi-chevron-triple-down'"
                [message]="'ALL.COMMON.Next step' | translate"
                (btn_click)="create_requests_for_save(stepper)"
              ></bg2-button>
            </div>
          </div>
        </ng-template>
      </mat-step>

      <!-- Save data -->
      <mat-step>
        <ng-template matStepLabel>
          {{ 'VIEWS.DEVICES.DIALOGS_AND_MODALS.MODALS.DEVICE_SUPPORT.DEVICE_SUPPORT_CLOSE_MODAL.STEP_SAVE.Save modifications' | translate }}
        </ng-template>

        <ng-template matStepContent>
          <div class="mat-step-container --save-step">
            <div *ngLet="_requests?.value$$ | async; let requests_observer" class="requests-container">
              <div *ngFor="let a of requests_observer?.device_requests" class="request-container">
                <span class="entry-name">{{ a.device.name }}</span>
                <div *ngFor="let b of a?.requests" class="request-container__request">
                  <span>|</span>
                  <span>{{ b.label | translate }}</span>
                  <mat-divider></mat-divider>
                  <span *ngLet="b?.status; let status" class="status">
                    <ng-container *ngIf="status === 'not_started'">
                      <span class="mdi mdi-creation" style="color: goldenrod"></span>
                      <span>
                        {{
                          'VIEWS.DEVICES.DIALOGS_AND_MODALS.MODALS.DEVICE_SUPPORT.DEVICE_SUPPORT_CLOSE_MODAL.STEP_SAVE.STATUS.not_started'
                            | translate
                        }}
                      </span>
                    </ng-container>

                    <ng-container *ngIf="status === 'running'">
                      <span class="mdi mdi-reload mdi-spin" style="color: orange"></span>
                      <span>
                        {{
                          'VIEWS.DEVICES.DIALOGS_AND_MODALS.MODALS.DEVICE_SUPPORT.DEVICE_SUPPORT_CLOSE_MODAL.STEP_SAVE.STATUS.running'
                            | translate
                        }}
                      </span>
                    </ng-container>

                    <ng-container *ngIf="status === 'success'">
                      <span class="mdi mdi-check-bold" style="color: green"></span>
                      <span>
                        {{
                          'VIEWS.DEVICES.DIALOGS_AND_MODALS.MODALS.DEVICE_SUPPORT.DEVICE_SUPPORT_CLOSE_MODAL.STEP_SAVE.STATUS.success'
                            | translate
                        }}
                      </span>
                    </ng-container>

                    <ng-container *ngIf="status === 'failed'">
                      <span class="mdi mdi-close-thick" style="color: red"></span>
                      <span>
                        {{
                          'VIEWS.DEVICES.DIALOGS_AND_MODALS.MODALS.DEVICE_SUPPORT.DEVICE_SUPPORT_CLOSE_MODAL.STEP_SAVE.STATUS.failed'
                            | translate
                        }}
                      </span>
                    </ng-container>
                  </span>
                </div>
              </div>

              <div class="request-container">
                <span class="entry-name">DESK ticket</span>
                <div class="request-container__request">
                  <span>|</span>
                  <span>{{ requests_observer?.ticket_request?.label | translate }}</span>
                  <mat-divider></mat-divider>
                  <span *ngLet="requests_observer?.ticket_request?.status; let status" class="status">
                    <ng-container *ngIf="status === 'not_started'">
                      <span class="mdi mdi-creation" style="color: goldenrod"></span>
                      <span>
                        {{
                          'VIEWS.DEVICES.DIALOGS_AND_MODALS.MODALS.DEVICE_SUPPORT.DEVICE_SUPPORT_CLOSE_MODAL.STEP_SAVE.STATUS.not_started'
                            | translate
                        }}
                      </span>
                    </ng-container>

                    <ng-container *ngIf="status === 'running'">
                      <span class="mdi mdi-reload mdi-spin" style="color: orange"></span>
                      <span>
                        {{
                          'VIEWS.DEVICES.DIALOGS_AND_MODALS.MODALS.DEVICE_SUPPORT.DEVICE_SUPPORT_CLOSE_MODAL.STEP_SAVE.STATUS.running'
                            | translate
                        }}
                      </span>
                    </ng-container>

                    <ng-container *ngIf="status === 'success'">
                      <span class="mdi mdi-check-bold" style="color: green"></span>
                      <span>
                        {{
                          'VIEWS.DEVICES.DIALOGS_AND_MODALS.MODALS.DEVICE_SUPPORT.DEVICE_SUPPORT_CLOSE_MODAL.STEP_SAVE.STATUS.success'
                            | translate
                        }}
                      </span>
                    </ng-container>

                    <ng-container *ngIf="status === 'failed'">
                      <span class="mdi mdi-close-thick" style="color: red"></span>
                      <span>
                        {{
                          'VIEWS.DEVICES.DIALOGS_AND_MODALS.MODALS.DEVICE_SUPPORT.DEVICE_SUPPORT_CLOSE_MODAL.STEP_SAVE.STATUS.failed'
                            | translate
                        }}
                      </span>
                    </ng-container>
                  </span>
                </div>
              </div>
            </div>

            <div class="step-actions">
              <bg2-button
                *ngLet="has_failed_requests$$ | async; let has_failed_requests"
                [type]="'action'"
                [icon]="'mdi-content-save'"
                [loading]="is_saving.value$$ | async"
                [disabled]="is_saving.value$$ | async"
                [message]="
                  (has_failed_requests
                    ? 'VIEWS.DEVICES.DIALOGS_AND_MODALS.MODALS.DEVICE_SUPPORT.DEVICE_SUPPORT_CLOSE_MODAL.STEP_SAVE.Retry failed requests'
                    : 'ALL.COMMON.Save'
                  ) | translate
                "
                (btn_click)="has_failed_requests ? retry_failed_requests() : save()"
              ></bg2-button>
            </div>
          </div>
        </ng-template>
      </mat-step>
    </mat-stepper>
  </bg2-modal-content>
</bg2-modal>
