<div class="stackable-cards" #stackableCardGroup>
  <bg2-abstract-carousel-group-card>
    <div role="stackable-card-header" class="card-header">
      <div *ngIf="event_date$$ | async; let date; else: loadingTitle" class="card-title">
        <bg2-adaptive-text-to-container
          [text_to_adapt]="
            'EVENT.SUPERBOX_HARVEST.CAROUSEL.TITLE.Harvest of [date] (there was [distance_to_now])'
              | translate
                : {
                    date: date | formatDate: 'short',
                    distance_to_now: date | distanceToNow
                  }
          "
          [options]="{ color: '#242424', fontMin: 18, fontWeight: 600, textAnchor: 'start' }"
        ></bg2-adaptive-text-to-container>

        <bg2-adaptive-text-to-container
          [text_to_adapt]="
            'VIEWS.APIARY.SHARED.WIDGETS.APIARY_EVALUATION_CAROUSEL.SUMMARY.TITLE.[author]' | translate: { author: event_author$$ | async }
          "
          [options]="{ color: '#242424', fontMin: 14, fontMax: 14, fontWeight: 600, textAnchor: 'end' }"
        ></bg2-adaptive-text-to-container>
      </div>

      <ng-template #loadingTitle>
        <bg2-loading-skeleton
          [theme]="{ width: '100%', height: '18px' }"
          [string_model]="'Superbox harvest of April, 28'"
        ></bg2-loading-skeleton>
      </ng-template>
    </div>

    <div role="stackable-card-content">
      <div
        class="honey-type-img"
        [ngStyle]="{
          backgroundSize: '100%',
          backgroundRepeat: 'no-repeat',
          backgroundImage: harvested_honey_type__image$$ | async
        }"
      >
        <div class="honey-type">{{ harvested_honey_type$$ | async | translate }}</div>
      </div>
      <div class="harvest-info">
        <div *ngLet="harvested_supers__total$$ | async; let harvested_supers__total" class="nb-supers">
          <span class="nb-real">{{ harvested_supers__total }}</span>
          <bg2-picto-hive [supers]="1" [size]="'100px'"> </bg2-picto-hive>
        </div>
        <span> {{ 'VIEWS.EXPLOITATIONS.COMPONENTS.WIDGETS_HONEY_FACTORY.EXPLOITATION_HONEY_FACTORY_SUMMARY.Harvested supers' | translate }} </span>
      </div>
    </div>
  </bg2-abstract-carousel-group-card>
</div>
