import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Beeguard2Api } from 'app/core';

import { TranslateService } from '@ngx-translate/core';

import { BehaviorSubject, Observable } from 'rxjs';
import { map, switchMap, take, tap } from 'rxjs';
import { distinctUntilRealChanged, replay } from '@bg2app/tools/rxjs';

import { every, flatten, values } from 'lodash-es';

import { Entity, Event } from 'app/models';

import { AbstractDialogComponent, AbstractDialogParams, DialogsService } from 'app/widgets/dialogs-modals';

export interface DeleteEventDialogParams extends AbstractDialogParams {
  eventID: number;
}

export enum DeleteEventDialogOutput {
  CANCELED,
  DELETED,
}

@Component({
  selector: 'bg2-delete-event-dialog',
  templateUrl: './delete-event-dialog.component.html',
  styleUrls: ['./delete-event-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DeleteEventDialogComponent
  extends AbstractDialogComponent<DeleteEventDialogParams, DeleteEventDialogOutput>
  implements OnInit
{
  // #region -> (component basics)

  constructor(private _bg2Api: Beeguard2Api, public translate: TranslateService) {
    super();
  }

  ngOnInit(): void {}

  // #endregion

  private _is_loading$$ = new BehaviorSubject(true);
  public is_loading$$ = this._is_loading$$.asObservable().pipe(distinctUntilRealChanged(), replay());

  private _is_deleting_event$$ = new BehaviorSubject(false);
  public is_deleting_event$$ = this._is_deleting_event$$.asObservable().pipe(distinctUntilRealChanged(), replay());

  public event$$: Observable<Event> = this.input_params$$.pipe(
    map(input_params => input_params.eventID),
    tap(() => this._is_loading$$.next(true)),
    switchMap(eventID => this._bg2Api.getEventObj(eventID)),
    tap(() => this._is_loading$$.next(false)),
    replay()
  );

  public setup_entities$$: Observable<Entity[]> = this.event$$.pipe(
    switchMap(event => event.setup_entities$$),
    map(entities => flatten(values(entities))),
    replay()
  );

  public has_setup_entities$$: Observable<boolean> = this.setup_entities$$.pipe(
    map(entities => (entities || []).length > 0),
    replay()
  );

  public can_delete$$: Observable<boolean> = this.setup_entities$$.pipe(
    map(entities => every(entities, entity => entity.canDelete())),
    replay()
  );

  public cancel(): void {
    super.complete(DeleteEventDialogOutput.CANCELED);
  }

  public deleteEvent(): void {
    this._is_deleting_event$$.next(true);
    this.event$$
      .pipe(
        take(1),
        switchMap(event => event.delete())
      )
      .subscribe({
        next: () => {
          this._is_deleting_event$$.next(false);
          super.complete(DeleteEventDialogOutput.DELETED);
        },
        error: (error: unknown) => {
          this._is_deleting_event$$.next(false);

          throw new Error((<any>error).msg || (<Error>error).message || 'Cannot delete event #' + this.input_params.eventID);
        },
      });
  }

  public static open(dialogs_service: DialogsService, params: DeleteEventDialogParams): Observable<DeleteEventDialogOutput> {
    return super.aopen<DeleteEventDialogParams, DeleteEventDialogOutput>(dialogs_service, params);
  }
}
