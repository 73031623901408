import { Component, Input, Output, EventEmitter, ChangeDetectionStrategy } from '@angular/core';
import { MatSelectChange } from '@angular/material/select';

import { find, isEmpty, isEqual, isNil } from 'lodash-es';
import { DatatableColumn } from 'app/models/misc/datatable';
import { BehaviorSubject, Observable, combineLatest } from 'rxjs';
import { distinctUntilRealChanged, waitForNotNilProperties, waitForNotNilValue } from '@bg2app/tools/rxjs';
import { shareReplay, map } from 'rxjs';
import { replay } from '@bg2app/tools/rxjs';
import { Dictionary } from 'app/typings/core/interfaces';
import { DatatableBaseRow } from 'app/typings/datatable/interfaces/DatatableBaseRow.iface';

@Component({
  selector: 'bg2-table-groups-breadcrumb',
  templateUrl: 'table-groups-breadcrumb.component.html',
  styleUrls: ['table-groups-breadcrumb.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TableGroupsBreadcrumbComponent {
  @Input()
  public set all_columns(all_columns: DatatableColumn<DatatableBaseRow>[]) {
    this._all_columns$.next(all_columns);
  }

  private _all_columns$: BehaviorSubject<DatatableColumn<DatatableBaseRow>[]> = new BehaviorSubject([]);
  private all_columns$$: Observable<DatatableColumn<DatatableBaseRow>[]> = this._all_columns$
    .asObservable()
    .pipe(distinctUntilRealChanged<DatatableColumn<DatatableBaseRow>[]>(), replay());

  @Input()
  public set groups(groups: string[]) {
    this._groups$.next(groups);
  }

  private _groups$: BehaviorSubject<string[]> = new BehaviorSubject([]);
  public groups$$: Observable<string[]> = this._groups$.asObservable().pipe(
    waitForNotNilValue(),
    distinctUntilRealChanged<string[]>(),
    replay()
  );

  @Output()
  public groupsChange: EventEmitter<string[]> = new EventEmitter();

  /** */
  private _columns_and_groups$$ = combineLatest({
    groups: this.groups$$,
    all_columns: this.all_columns$$,
  }).pipe(waitForNotNilProperties(), replay());

  public options$$: Observable<DatatableColumn<DatatableBaseRow>[]> = this._columns_and_groups$$.pipe(
    map(({ groups, all_columns }) =>
      all_columns.filter((column: DatatableColumn<DatatableBaseRow>) => column.is_groupable && !groups.includes(column.property))
    )
  );

  public labels_by_group_name$$: Observable<Dictionary<string>> = this._columns_and_groups$$.pipe(
    map(({ all_columns, groups }) => {
      const labels: Dictionary<string> = {};

      groups.forEach(
        (group: string) =>
          (labels[group] = find(all_columns, (column: DatatableColumn<DatatableBaseRow>) => isEqual(column.property, group)).label)
      );

      return labels;
    })
  );

  // #region -> (event handlers)

  public onRemoveGroup(value: string): void {
    this.groupsChange.emit(this._groups$.getValue().filter((group: string) => group !== value));
  }

  public onUpdate(event: MatSelectChange): void {
    if (!isNil(event.value)) {
      this.groupsChange.emit([...this._groups$.getValue(), event.value]);
    }

    event.source.value = undefined;
  }
}
