<div class="bg-dialog" *ngIf="input_params.run as run">
  <!-- Dialog header -->
  <dialog-header>Erreur avec l'intervention du {{ run.error.source.event_date | formatDate:'short' }}</dialog-header>

  <!-- Dialog body -->
  <dialog-body>
    <ng-container *ngIf="(run.event$$ | async) as event; else loading">
      <!-- *** Event description *** -->
      <div class="group">
        <b>{{ 'VIEWS_WINDOWED.DIALOGS.WINDOWS.RUN_ERROR.Error source:' | translate }}</b>
        <bg2-event-span [event]="event" (link_clicked)="close()"></bg2-event-span>
      </div>

      <!-- *** Reason description *** -->
      <div class="group">
        <b>{{ 'VIEWS_WINDOWED.DIALOGS.WINDOWS.RUN_ERROR.Caused by:' | translate }}</b>
        <span>{{ run.error.description }}</span>
      </div>

      <!-- *** Available actions *** -->
      <div class="group">
        <b>{{ 'VIEWS_WINDOWED.DIALOGS.WINDOWS.RUN_ERROR.Resolve error by:' | translate }}</b>

        <div class="actions">
          <bg2-button
            class="w-100"
            [type]="'action'"
            [icon]="'mdi-pencil'"
            [message]="'VIEWS_WINDOWED.DIALOGS.WINDOWS.RUN_ERROR.Updating this intervention' | translate"
            (btn_click)="update_event(event)"
          >
            <span class="button-principal">
              <span class="mdi mdi-pencil"></span>
              <span translate></span>
            </span>
          </bg2-button>
          <bg2-button [type]="'delete'" class="w-100" [icon]="'mdi-delete'" [message]="'VIEWS_WINDOWED.DIALOGS.WINDOWS.RUN_ERROR.Deleting this intervention' | translate"  (btn_click)="deleteEvent(run.error.source.event_id)">
          </bg2-button>
          <bg2-button
            [type]="'action'"
            [icon]="'mdi-email'"
            [message]="'VIEWS_WINDOWED.DIALOGS.WINDOWS.RUN_ERROR.Contacting support' | translate"
            class="w-100"
            (btn_click)="contact_support(run)"
          >
          </bg2-button>
        </div>
      </div>
    </ng-container>
    <ng-template #loading>
      <hex-loading [size]="'medium'"></hex-loading>
    </ng-template>
  </dialog-body>

  <!-- Dialog footer -->
  <div class="dialog-footer">
    <bg2-button (btn_click)="close()" class="w-100" [type]="'cancel'" [message]="'ALL.COMMON.Close' | translate">
    </bg2-button>
  </div>
</div>
