import { isEmpty, isNil } from 'lodash-es';

import { BehaviorSubject, map } from 'rxjs';
import { replay } from '@bg2app/tools/rxjs';

import { ZohoBaseModel } from '../../../../abstract';
import { IZohoDeskAccount } from '../interfaces';

/** */
export class ZohoDeskAccount extends ZohoBaseModel<IZohoDeskAccount> {
  // #region -> (model basics)

  constructor() {
    super();
  }

  // #endregion

  // #region -> (account infos)

  /** */
  protected _account_name$$ = new BehaviorSubject<string>(null);

  /** */
  public account_name$$ = this._account_name$$.asObservable();

  /** */
  public set accountName(account_name: string) {
    this._account_name$$.next(account_name);
  }

  /** */
  public get accountName(): string {
    return this._account_name$$.getValue();
  }

  // #endregion

  // #region -> (related zoho CRM account)

  /** */
  private _zoho_crm_account$$ = new BehaviorSubject<{ id?: string }>(null);

  /** */
  public zoho_crm_account$$ = this._zoho_crm_account$$.asObservable();

  /** */
  public set zohoCRMAccount(zoho_crm_account: { id?: string }) {
    this._zoho_crm_account$$.next(zoho_crm_account);
  }

  /** */
  public get zohoCRMAccount(): { id?: string } {
    return this._zoho_crm_account$$.getValue();
  }

  // #endregion

  // #region -> (account additional infos)

  // #endregion

  // #region -> (links)

  /** */
  public url$$ = this.id$$.pipe(
    map(id => {
      if (isNil(id) || isEmpty(id)) {
        return null;
      }

      return `https://desk.zoho.eu/agent/beeguard/support-beeguard/accounts/details/${id}`;
    }),
    replay()
  );

  /** */
  public url_tickets$$ = this.url$$.pipe(
    map(url => {
      if (isNil(url) || isEmpty(url)) {
        return null;
      }

      return `${url}/tickets`;
    }),
    replay()
  );

  // #endregion
}
