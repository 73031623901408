<div class="reset-password-component">
  <div class="reset-password-component__container mat-elevation-z3">
    <div class="reset-password-component__container__language">
      <bg2-lang-select></bg2-lang-select>
    </div>

    <div class="reset-password-component__container__logo">
      <img [src]="logo" />
    </div>

    <div class="reset-password-component__container__title">{{ 'VIEWS.AUTH.RESET_PASSWORD.Password reset' | translate }}</div>

    <!-- <div class="reset-password-component__container__welcome-message">{{ 'VIEWS.AUTH.LOGIN.Welcome message' | translate }}</div> -->

    <div *ngIf="error$$ | async; let error" class="reset-password-component__container__error">
      <div class="reset-password-password-component__container__icon">
        <span class="mdi mdi-24px mdi-alert-circle"></span>
      </div>

      <div class="reset-password-password-component__container__message">
        <span>{{ error.message | translate }}</span>
      </div>
    </div>

    <ng-container *ngLet="current_state$$ | async; let current_state">
      <!-- Mail address form -->
      <form
        *ngIf="current_state === 'input_mail'"
        class="reset-password-component__container__form-container"
        [formGroup]="reset_password_form"
      >
        <mat-form-field [appearance]="'outline'">
          <mat-label>{{ 'VIEWS.AUTH.LOGIN.Mail address' | translate }}</mat-label>
          <input matInput type="email" autocomplete="email" formControlName="mail" />
        </mat-form-field>

        <bg2-button
          [type]="'action'"
          [btn_type]="'submit'"
          [icon]="'mdi-lock-reset'"
          [disabled]="!reset_password_form.valid || (reset_in_progress$$ | async)"
          [loading]="reset_in_progress$$ | async"
          [message]="'VIEWS.AUTH.RESET_PASSWORD.Ask for password reset' | translate"
          (btn_click)="ask_for_password_reset()"
        ></bg2-button>
      </form>

      <!-- Mail sent -->
      <div *ngIf="current_state === 'mail_sent'" class="reset-password-component__container__mail-sent">
        <span
          >{{
            'VIEWS.AUTH.RESET_PASSWORD.Instructions has been sent to [mail]' | translate: { mail: reset_password_form.value.mail }
          }}.</span
        >
        <span>{{ 'VIEWS.AUTH.RESET_PASSWORD.Go to your mailbox and follow the instructions' | translate }}.</span>
      </div>

      <!-- Change password form -->
      <form
        *ngIf="current_state === 'renew_password'"
        class="reset-password-component__container__form-container"
        [formGroup]="renew_password_form"
      >
        <mat-form-field [appearance]="'outline'" hidden>
          <mat-label>{{ 'VIEWS.AUTH.RESET_PASSWORD.STATES.RENEW_PASSWORD.Email' | translate }}</mat-label>
          <input matInput type="email" autocomplete="email" formControlName="email" />
        </mat-form-field>

        <mat-form-field [appearance]="'outline'">
          <mat-label>{{ 'VIEWS.AUTH.RESET_PASSWORD.STATES.RENEW_PASSWORD.New password' | translate }}</mat-label>
          <input matInput [type]="show_password.new ? 'text' : 'password'" autocomplete="new-password" formControlName="new_password" />
          <mat-icon matSuffix (click)="show_password.new = !show_password.new">
            {{ show_password.new ? 'visibility_off' : 'visibility' }}
          </mat-icon>
        </mat-form-field>

        <mat-form-field [appearance]="'outline'">
          <mat-label>{{ 'VIEWS.AUTH.RESET_PASSWORD.STATES.RENEW_PASSWORD.Confirm new password' | translate }}</mat-label>
          <input matInput [type]="show_password.confirm ? 'text' : 'password'" formControlName="confirm_password" />
          <mat-icon matSuffix (click)="show_password.confirm = !show_password.confirm">
            {{ show_password.confirm ? 'visibility_off' : 'visibility' }}
          </mat-icon>

          <ng-container *ngIf="renew_password_form.get('confirm_password').errors; let errors">
            <mat-error *ngIf="errors?.matchedValue"
              >{{ 'ALL.ERROR.FORM.The password does not match the new password' | translate }}.</mat-error
            >
          </ng-container>
        </mat-form-field>

        <bg2-button
          [type]="'action'"
          [btn_type]="'submit'"
          [icon]="'mdi-lock-reset'"
          [disabled]="!renew_password_form.valid"
          [message]="'VIEWS.AUTH.RESET_PASSWORD.Confirm' | translate"
          (btn_click)="renew_password()"
        ></bg2-button>
      </form>
    </ng-container>

    <mat-divider></mat-divider>

    <div class="reset-password-component__container__have-account-container">
      <span>{{ 'VIEWS.AUTH.RESET_PASSWORD.HAVE_ACCOUNT.Already have an account ?' | translate }}&nbsp;</span>
      <span
        ><a [routerLink]="['login']">{{ 'VIEWS.AUTH.LOGIN.Log in' | translate }}</a></span
      >.
    </div>

    <div class="reset-password-component__container__no-account-container">
      <span>{{ 'VIEWS.AUTH.LOGIN.NO_ACCOUNT.If you do not have an account yet, you can' | translate }}&nbsp;</span>
      <span>
        <a *ngLet="language$$ | async; let language" [href]="'https://beeguard.' + (language === 'en' ? 'net' : language)">
          {{ 'VIEWS.AUTH.LOGIN.NO_ACCOUNT.contact us' | translate }}</a
        >.
      </span>
    </div>
  </div>
</div>
