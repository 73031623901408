<form-element-header [schema]="schema" [showLabel]="false"></form-element-header>
<!-- <pre>{{schema._min | json}}</pre>
<pre>{{all_min$$ | async | json}}</pre>
<pre>{{min$$ | async | json}}</pre> -->
<div class="date-field" style="margin-top: 10px">
  <div class="row">
    <div
      class="col"
      [class.s8]="!options.pickerType || options.pickerType !== 'calendar'"
      [class.s12]="options.pickerType === 'calendar'"
    >
      <label [attr.for]="owl_datatime_picker_id">{{ schema.label | translate }}</label>
      <input
        [class.valid]="valid$$ | async"
        [class.invalid]="(valid$$ | async) === false"
        [ngModel]="(datetime$$ | async)"
        (ngModelChange)="setDate($event)"
        [min]="min_day$$ | async"
        [max]="max"
        [id]="owl_datatime_picker_id"
        [owlDateTime]="owldt"
        [owlDateTimeTrigger]="owldt"
        [disabled]="options.readonly"
      />
      <owl-date-time [disabled]="options.readonly" pickerType="calendar" #owldt></owl-date-time>
    </div>

    <div *ngIf="!options.pickerType || options.pickerType !== 'calendar'" class="col s4" style="position: relative">
      <label [attr.for]="owl_datatime_picker_id">{{ 'WIDGETS.EVENT_FORM.DATE.Time' | translate }}</label>
      <input
        [class.valid]="valid$$ | async"
        [class.invalid]="(valid$$ | async) === false"
        [value]="(datetime$$ | async)"
        [ngModel]="(datetime$$ | async)"
        (ngModelChange)="setTime($event)"
        [min]="min$$ | async"
        id="owlTimePicker"
        [owlDateTimeTrigger]="owldtimer"
        [owlDateTime]="owldtimer"
        [disabled]="options.readonly"
      />
      <owl-date-time [disabled]="options.readonly" pickerType="timer" #owldtimer [showSecondsTimer]="options?.show_seconds ?? false"></owl-date-time>
    </div>
  </div>

  <ng-container *ngIf="!options.readonly">
    <div class="date-widget-informations" style="display: flex; flex-flow: column nowrap;">
      <!-- Informations block -->
      <div class="block-informations" style="display: flex; flex-flow: column nowrap;">
        <ng-container *ngIf="(min_role$$ | async); let min_role">
          <ng-container [ngSwitch]="min_role.role">
            <ng-container *ngSwitchCase="'setupdate_role_apiary'">
              <span>
                <span class="mdi mdi-information" style="color: blue"></span>
                <span>
                  {{ 'WIDGETS.EVENT_FORM.DATE.INFORMATION.The apiary was setup on [date], you must choose an equal or later date' |
                  translate: {date: min_role.date | formatDate:'full'} }}
                </span>
              </span>
            </ng-container>

            <ng-container *ngSwitchCase="'setupdate_role_location'">
              <span>
                <span class="mdi mdi-information" style="color: blue"></span>
                <span>
                  {{ 'WIDGETS.EVENT_FORM.DATE.INFORMATION.The location was setup on [date], you must choose an equal or later date'
                  | translate: {date: min_role.date | formatDate:'full'} }}
                </span>
              </span>
            </ng-container>

            <ng-container *ngSwitchCase="'setupdate_role_hive'">
              <span>
                <span class="mdi mdi-information" style="color: blue"></span>
                <span>
                  {{ 'WIDGETS.EVENT_FORM.DATE.INFORMATION.The hive was setup on [date], you must choose an equal or later date' |
                  translate: {date: min_role.date | formatDate:'full'} }}
                </span>
              </span>
            </ng-container>

            <ng-container *ngSwitchDefault>
              <span>
                <span class="mdi mdi-information" style="color: blue"></span>
                <span>
                  {{ 'WIDGETS.EVENT_FORM.DATE.INFORMATION.The date must be equal or later than [date]' | translate: {date:
                  min_role.date | formatDate:'full'} }}
                </span>
              </span>
            </ng-container>
          </ng-container>
        </ng-container>
      </div>

      <!-- Warnings block -->
      <div class="block-warnings" style="display: flex; flex-flow: column nowrap;">
        <span *ngIf="is_in_futur$$ | async" style="color: red">
          <span class="mdi mdi-alert-decagram" style="color: inherit"></span>
          {{'WIDGETS.EVENT_FORM.DATE.Warning: the chosen date is in the future' | translate }}
        </span>
      </div>
    </div>
  </ng-container>

  <div style="display: flex; flex-flow: column nowrap;">
    <span class="description" *ngIf="schema.description">{{schema.description | translate}}</span>
    <span class="warning" *ngIf="schema.warning">{{schema.warning | translate}}</span>
  </div>
</div>
