import { BehaviorSubject } from 'rxjs';

import { ZohoBaseModel } from '../../../../abstract';
import { IZohoDeskAgent } from '../interfaces';

/** */
export class ZohoDeskAgent extends ZohoBaseModel<IZohoDeskAgent> {
  // #region -> (model basics)

  constructor() {
    super();
  }

  // #endregion

  // #region -> (agent infos)

  /** */
  protected _photo_URL$$ = new BehaviorSubject<string>(null);

  /** */
  public photoURL$$ = this._photo_URL$$.asObservable();

  /** */
  public set photoURL(account_name: string) {
    this._photo_URL$$.next(account_name);
  }

  /** */
  public get photoURL(): string {
    return this._photo_URL$$.getValue();
  }

  // #endregion

  // #region -> (account additional infos)

  // #endregion
}
