<ng-container *ngIf="visible$$ | async">
  <div [id]="widget_id" class="select-widget" [class.with-img]="options?.img ?? false" [class.no-label]="!label">
    <form-element-header [schema]="schema" [showLabel]="false"></form-element-header>

    <div
      *ngIf="fullscreen_select_helper?.is_open_in_fullscreen.value$$| async"
      id="fulscreen-select-blocker"
      (click)="fullscreen_select_helper.close(true)"
    ></div>

    <div *ngIf="options.readonly && value">
      <label>{{schema.label | translate}}:</label>
      <span class="value">{{value}}</span>
    </div>

    <mat-form-field
      *ngIf="!options.readonly"
      [appearance]="'outline'"
      [class.with-img]="options?.img ?? false"
      [class.fullscreen]="fullscreen_select_helper?.is_open_in_fullscreen.value$$ | async"
    >
      <mat-label>{{ label | translate }}</mat-label>

      <mtx-select
        #select
        [class.with-img]="options.img"
        [class.valid]="valid$$ | async"
        [class.invalid]="(valid$$ | async) === false"
        [ngModel]="value$$ | async"
        (ngModelChange)="value = $event;"
        [items]="items$$ | async"
        bindValue="value"
        bindLabel="label"
        [multiple]="multiple"
        [disabled]="schema.readOnly"
        [groupBy]="'group_name'"
        [clearable]="options.clearable"
        dropdownPosition="bottom"
        [searchFn]="$any(ngSelectCustomSearch)"
        [closeOnSelect]="multiple ? false : true"
        [appendTo]="'div#' + widget_id + ' mat-form-field .mat-form-field-wrapper'"
        (open)="fullscreen_select_helper.open();"
        (close)="fullscreen_select_helper.close();"
      >
        <!-- Template for selected option -->
        <ng-template ng-label-tmp let-item="item">
          <div class="selected-item {{ options?.template_name ?? '' }}" [ngStyle]="getItemStyle(item)">
            <ng-container *ngIf="options?.template_name === null">
              <div class="item">
                <span class="label">{{ item.label | translate }}</span>
              </div>
            </ng-container>

            <ng-container *ngIf="options?.template_name === 'zoho-ticket-status'">
              <div class="item">
                <span class="mdi mdi-checkbox-blank-circle --{{ item?.status_type }}"></span>
                <div class="label">{{item.label | translate}}</div>
              </div>
            </ng-container>
          </div>
        </ng-template>

        <!-- Template for option in dropdown -->
        <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
          <div class="option-item {{ options?.template_name ?? '' }}" [ngStyle]="getItemStyle(item)">
            <ng-container *ngIf="options?.template_name === null">
              <div class="item">
                <div class="label" [ngOptionHighlight]="search">{{item.label | translate}}</div>
              </div>
            </ng-container>

            <ng-container *ngIf="options?.template_name === 'zoho-ticket-status'">
              <div class="item">
                <span class="mdi mdi-checkbox-blank-circle --{{ item?.status_type }}"></span>
                <div class="label" [ngOptionHighlight]="search">{{item.label | translate}}</div>
              </div>
            </ng-container>
          </div>
        </ng-template>

        <ng-template ng-notfound-tmp let-searchTerm="searchTerm">
          <div class="ng-option disabled">{{ 'WIDGETS.EVENT_FORM.SELECT.Not found' | translate }}</div>
        </ng-template>
      </mtx-select>
    </mat-form-field>

    <!-- La description est afficher dans form-element-header si on a un title-->
    <!-- TODOs: Should be in mat-hint -->
    <span class="description" *ngIf="schema.description && !schema.title">{{ schema.description }}</span>
  </div>
</ng-container>
