import { latLng } from 'leaflet';

/**
 * Generates number of random geolocation points given a center and a radius.
 * Reference URL: http://goo.gl/KWcPE.
 *
 * @param center A JS object with lat and lng attributes.
 * @param radius Radius in meters.
 * @return The generated random points as JS object with lat and lng attributes.
 */
export const generateRandomPoint = (center: L.LatLng, radius: number): L.LatLng => {
  const x0 = center.lng;
  const y0 = center.lat;
  // Convert Radius from meters to degrees.
  const rd = radius / 111300;

  const u = Math.random();
  const v = Math.random();

  const w = rd; // * Math.sqrt(u);
  const t = 2 * Math.PI * v;
  const x = w * Math.cos(t);
  const y = w * Math.sin(t);

  const xp = x / Math.cos(y0);

  // Resulting point.
  return latLng(y + y0, xp + x0);
};
