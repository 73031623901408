import { Component, AfterViewInit, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { uniqueId, isNil } from 'lodash-es';

import { Options as ng5SliderOptions } from '@angular-slider/ngx-slider';

import { EfNumberWidget } from '../number/number.widget';
import { WidgetOptions } from '..';
import { Observable } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs';
import { replay } from '@bg2app/tools/rxjs';

export interface SliderOptions extends ng5SliderOptions, WidgetOptions { }

@Component({
  selector: 'bg2-ef-slider-widget',
  templateUrl: './slider.widget.html',
  styleUrls: ['./slider.widget.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EfSliderWidgetComponent extends EfNumberWidget implements OnInit, AfterViewInit {
  public id = uniqueId();

  public __isNil = isNil;

  public min_max = false;

  public options: SliderOptions = {
    floor: 0,
    ceil: 100,
    indent: false,
    reset_btn: false,
    draggableRange: true
  };

  // #region ↛ (Filters on min & max values)
  public min$$: Observable<number>;
  public max$$: Observable<number>;

  get min(): number {
    return this.value[0] || 0;
  }

  set min(new_min: number) {
    this.value = [new_min, this.max];
  }

  get max(): number {
    return this.value[1] || 255;
  }

  set max(new_max: number) {
    this.value = [this.min, new_max];
  }

  // //#endregion

  ngOnInit(): void {
    super.ngOnInit();
    this.min_max = this.schema.type === 'array';

    this.options.floor = this.schema.minimum || this.schema.items?.minimum || this.options.floor;
    this.options.ceil = this.schema.maximum || this.schema.items?.maximum || this.options.ceil;

    this.options.step = this.schema.step || this.schema.multipleOf || this.schema.items?.multipleOf;

    this.min$$ = this.value$$.pipe(
      map(value => value[0]),
      distinctUntilChanged(),
      replay()
    );
    this.max$$ = this.value$$.pipe(
      map(value => value[1]),
      distinctUntilChanged(),
      replay()
    );

    this.initValue();
  }

  private initValue(): void {
    let initial_value = this.value;

    if (!initial_value) {
      initial_value = this.schema.default || 0;
    }

    if (initial_value < this.options.floor) {
      initial_value = this.options.floor;
    } else if (this.options.floor > this.options.ceil) {
      initial_value = this.options.ceil;
    }

    if (this.value !== initial_value) {
      this.value = initial_value;
    }
  }
}
