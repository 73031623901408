import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ComponentRef,
  ElementRef,
  OnDestroy,
  OnInit,
  Type,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';

import { clone, isNil, merge } from 'lodash-es';

import { BehaviorSubject, combineLatest, filter, map, Observable, of, switchMap, tap } from 'rxjs';
import { distinctUntilRealChanged, replay } from '@bg2app/tools/rxjs';
import { ConsoleLoggerService } from 'app/core/console-logger.service';
import { ErrorHelperData } from '../../../errors/error-helper/error-helper.component';
import { Dictionary } from 'app/typings/core/interfaces';
import { AbstractCarouselSlideComponent } from '../abstract-carousel-slide/abtract-carousel-slide.component';

/** */
export interface IAbstractCarouselConfig {}

/**
 * @module WidgetsReusableModule
 *
 * @description
 *
 * @template CarouselGroupType
 */
@Component({
  selector: 'bg2-abstract-carousel-container',
  template: '',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export abstract class AbstractCarouselContainerComponent<
  CarouselGroupType extends AbstractCarouselSlideComponent,
  CarouselConfigType extends IAbstractCarouselConfig = any
> implements OnInit, AfterViewInit, OnDestroy
{
  // #region -> (component basics)

  /** */
  @ViewChild('slider')
  public slider: ElementRef<HTMLDivElement> = undefined;

  /** */
  @ViewChild('container', { read: ViewContainerRef })
  public container: ViewContainerRef;

  /** */
  protected type_of_card_group_component: Type<any> = null;

  /** */
  protected _logger = new ConsoleLoggerService('AbstractCarouselContainerComponent', false);

  /** */
  protected readonly CAROUSEL_ITEM_WIDTH = `95%`;

  /** */
  constructor() {}

  /** */
  ngOnInit(): void {}

  /** */
  ngAfterViewInit(): void {}

  /** */
  ngOnDestroy(): void {
    this.carousel_items.forEach(cmp => cmp.destroy());
    this._carousel_items$$.next([]);
  }

  // #endregion

  // #region -> (carousel config management)

  /** */
  private _carousel_config$ = new BehaviorSubject<CarouselConfigType>(null);

  /** */
  protected carousel_config$$ = this._carousel_config$.asObservable();

  /** */
  protected set carousel_config(carousel_config: Partial<CarouselConfigType>) {
    const previous = this._carousel_config$.getValue();
    const next_value = merge({}, previous, carousel_config);

    this._carousel_config$.next(next_value);
  }

  // #endregion

  // #region -> (carousel dynamic data management)

  /** */
  protected _dynamic_data: Dictionary<any>;

  /** */
  protected set dynamic_data(dynamic_data: Dictionary<any>) {
    this._dynamic_data = dynamic_data;

    this.carousel_items.forEach(component_ref => {
      component_ref.instance.dynamic_parameters = dynamic_data;
    });
  }

  /** */
  protected get dynamic_data(): Dictionary<any> {
    return this._dynamic_data;
  }

  // #endregion

  // #region -> (carousel static data management)

  /** */
  private _new_card_group_input_data$$: BehaviorSubject<any> = new BehaviorSubject<any>({});

  /** */
  protected set new_card_group_input_data(new_card_group_input_data: any) {
    this._new_card_group_input_data$$.next(new_card_group_input_data);
  }

  /** */
  protected get new_card_group_input_data(): any {
    return this._new_card_group_input_data$$.getValue();
  }

  // #endregion

  // #region -> (carousel error management)

  /** */
  public abstract error$$: Observable<ErrorHelperData>;

  // #endregion

  // #region -> (carousel slides management)

  /** */
  protected _slider$$ = new BehaviorSubject<ElementRef<HTMLDivElement>>(undefined);

  /** */
  protected slider$$ = this._slider$$.asObservable().pipe(
    filter(slider => !isNil(slider)),
    replay()
  );

  /** */
  protected _carousel_items$$ = new BehaviorSubject<ComponentRef<CarouselGroupType>[]>([]);

  /** */
  protected carousel_items$$ = this._carousel_items$$.asObservable().pipe(replay());

  /** */
  protected get carousel_items(): ComponentRef<CarouselGroupType>[] {
    return this._carousel_items$$.getValue();
  }

  /** */
  protected set carousel_items(carousel_items: ComponentRef<CarouselGroupType>[]) {
    const previous_before_update = this.carousel_items;
    previous_before_update.push(carousel_items[0]);

    this._carousel_items$$.next(previous_before_update);
  }

  /** */
  public carousel_slides_total$$ = this.carousel_items$$.pipe(
    map(slides => slides?.length ?? null),
    distinctUntilRealChanged()
  );

  /** */
  public has_carousel_slides$$ = this.carousel_items$$.pipe(
    map(carousel_items => carousel_items.length),
    map(total_of_groups => total_of_groups > 0),
    distinctUntilRealChanged()
  );

  // #endregion

  // #region -> (carousel groups management)

  /** */
  private _current_index$$ = new BehaviorSubject(0);

  /** */
  private current_index$$ = this._current_index$$.asObservable().pipe(distinctUntilRealChanged());

  /** */
  protected set current_index(current_index: number) {
    this._current_index$$.next(current_index);
  }

  /** */
  protected get current_index(): number {
    return this._current_index$$.getValue();
  }

  /**
   * Observes the previous slide's component reference.
   *
   * This property observes the component reference of the previous slide depending
   * on the current carousel's display index.
   */
  private previous_slide$$: Observable<ComponentRef<CarouselGroupType>> = combineLatest([this.carousel_items$$, this.current_index$$]).pipe(
    map(([slides, current_index]) => slides[Math.abs(current_index) + 1]),
    replay()
  );

  /**
   * Observes if the carousel has a previous slide to current index.
   *
   * This property observes if the carousel has an existant previous slide depending
   * on the current carousel's display index.
   */
  public has_previous_slide$$: Observable<boolean> = this.previous_slide$$.pipe(
    map(previous_slide => !isNil(previous_slide)),
    distinctUntilRealChanged()
  );

  /**
   * Observes the static parameters of the previous slide.
   *
   * This property observes the static parameters of the preivous slide depending on
   * the current carousel's display index.
   */
  public previous_slide_static_data$$: Observable<any> = this.previous_slide$$.pipe(
    switchMap(previous_slide => {
      if (isNil(previous_slide)) {
        return of(null);
      }

      return (previous_slide?.instance as any)?.static_parameters$$;
    })
  );

  /**
   * Observes the current slide's component reference.
   *
   * This property observes the component reference of the current displayed slide of
   * the carousel.
   */
  private current_slide$$: Observable<ComponentRef<CarouselGroupType>> = combineLatest([this.carousel_items$$, this.current_index$$]).pipe(
    map(([slides, current_index]) => slides[Math.abs(current_index)]),
    replay()
  );

  /**
   * Observes the next slide's component reference.
   *
   * This property observes the component reference of the displayed slide after the current
   * one in the carousel.
   */
  private next_slide$$: Observable<ComponentRef<CarouselGroupType>> = combineLatest([this.carousel_items$$, this.current_index$$]).pipe(
    map(([slides, current_index]) => slides[Math.abs(current_index) - 1]),
    replay()
  );

  /**
   * Observes if the carousel has a next slide to current index.
   *
   * This property observes if the carousel has an existant slide after the
   * currently displayed one.
   */
  public has_next_slide$$: Observable<boolean> = this.next_slide$$.pipe(
    map(next_slide => !isNil(next_slide)),
    distinctUntilRealChanged()
  );

  /**
   * Observes the static parameters of the next slide.
   *
   * This property observes the static parameters of the slide after the
   * currently displayed one.
   */
  public next_slide_static_data$$: Observable<any> = this.next_slide$$.pipe(
    switchMap(next_slide => {
      if (isNil(next_slide)) {
        return of(null);
      }

      return (next_slide?.instance as any)?.static_parameters$$;
    })
  );

  // #endregion

  // #region -> (carousel animating management)

  /** */
  protected _transition$$ = new BehaviorSubject(false);

  /** */
  public transition$$ = this._transition$$.asObservable().pipe(distinctUntilRealChanged(), replay());

  /** */
  private _update_carousel_translate_x$$ = new BehaviorSubject<boolean>(true);

  /** */
  public carousel_translate_x$$: Observable<string> = combineLatest([
    this.slider$$.pipe(replay()),
    this._update_carousel_translate_x$$.asObservable().pipe(distinctUntilRealChanged(), replay()),
  ]).pipe(
    filter(([slider, update]) => !isNil(slider) && !isNil(update)),
    map(() => {
      const total_items = clone(this.carousel_items.length);

      const centering_offset = `((100% - ${this.CAROUSEL_ITEM_WIDTH}) / 2)`;
      const slide_offset = `(${this.CAROUSEL_ITEM_WIDTH} * ${this.current_index})`;
      const global_offset = `(-${this.CAROUSEL_ITEM_WIDTH} * (${total_items} - 1))`;

      return `translateX(calc(
        ${centering_offset}
        + ${global_offset}
        - ${slide_offset}
      ))`;
    }),
    distinctUntilRealChanged(),
    replay()
  );

  /** */
  protected update(): void {
    this._update_carousel_translate_x$$.next(!this._update_carousel_translate_x$$.getValue());
  }

  /** */
  public loadNext(): void {
    if (this.current_index !== 0) {
      this.current_index = this.current_index + 1;
      this.update();
    }
  }

  // #endregion
}
