import { ChangeDetectionStrategy, Component, Input, OnDestroy } from '@angular/core';

import { findIndex, flatten, isEmpty, isNil, some, sumBy } from 'lodash-es';

import { addMinutes, isEqual, isSameDay, startOfTomorrow, subDays } from 'date-fns/esm';
import { utcToZonedTime } from 'date-fns-tz';

import { distinctUntilRealChanged, replay, waitForNotNilProperties, waitForNotNilValue } from '@bg2app/tools/rxjs';
import { BehaviorSubject, combineLatest, map, Observable, switchMap, tap, take, Subscription, of } from 'rxjs';

import { WeightDataPoint } from 'app/models/data';
import { Apiary, ApiaryWeightsData } from 'app/models';
import { WeatherData, HiveDiffWeightPoint, HiveWeightData } from 'app/models/data/data-classic';

import { computes_barometric_pressure_from_sea_pressure, compute_pressure_at_sea_level } from 'app/misc/tools';
import { AppStateService } from 'app/core/app-state.service';
import { DATA_VIEW } from 'app/models/config/data-view.enum';
import { group_data_by_day, startOfTomorrowLuxon } from '@bg2app/tools/dates';
import { DateRangeManager } from 'app/misc/tools/dates/date-range-manager';

@Component({
  selector: 'bg2-apiary-charts-for-compact',
  templateUrl: './apiary-charts-for-compact.component.html',
  styleUrls: ['./apiary-charts-for-compact.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ApiaryChartsForCompactComponent implements OnDestroy {
  // #region -> (component basics)

  /** */
  public date_range_manager = new DateRangeManager();

  /** */
  private _user_default_date_range_sub: Subscription = null;

  constructor(private readonly _app_state_service: AppStateService) {
    this.location$$.pipe(switchMap(location => location.location_timezone$$)).subscribe({
      next: timezone => {
        const end_date = startOfTomorrowLuxon(timezone);
        const start = end_date.minus({ days: 7 });

        this.date_range_manager.initialize([start, end_date], timezone);
      },
    });

    // Load initial date range
    this._user_default_date_range_sub = this._app_state_service.user$$
      .pipe(
        waitForNotNilValue(),
        switchMap(user => user.params$$),
        map(params => params?.app_settings?.apiary?.list?.default_date_range ?? 'weekly')
      )
      .subscribe({
        next: default_date_range => {
          if (default_date_range === 'weekly') {
            this.date_range_manager.ranged_to.fixed.weekly();
          } else {
            this.date_range_manager.ranged_to.fixed.monthly();
          }
        },
      });
  }

  ngOnDestroy(): void {
    this._user_default_date_range_sub?.unsubscribe();
  }

  // #endregion

  // #region -> (apiary entity)

  /** */
  private _apiary$$ = new BehaviorSubject<Apiary>(null);

  /** */
  private apiary$$ = this._apiary$$.asObservable().pipe(waitForNotNilValue());

  @Input()
  public set apiary(apiary: Apiary) {
    this._apiary$$.next(apiary);
  }

  // #endregion

  // #region -> (location entity)

  /** */
  private location$$ = this.apiary$$.pipe(
    switchMap(apiary => apiary.location$$),
    waitForNotNilValue(),
    replay()
  );

  /** */
  public location_id$$ = this.location$$.pipe(switchMap(location => location.id$$));

  /** */
  public location_position$$ = this.location$$.pipe(switchMap(location => location?.position_robust$$));

  /** */
  private location_elevation$$ = this.location$$.pipe(switchMap(location => location.elevation$$));

  /** */
  private location_latitude$$ = this.location$$.pipe(
    switchMap(location => location.position$$),
    map(position => position?.latitude)
  );

  // #endregion

  // #region -> (chart type)

  /** */
  private _user_favorite_chart_type$$: Observable<DATA_VIEW> = this._app_state_service.user$$.pipe(
    waitForNotNilValue(),
    switchMap(user => user.params__apiary_list_default_chart_type$$),
    replay()
  );

  /** */
  private _which_chart_type_to_display$$ = new BehaviorSubject<DATA_VIEW>(null);

  /** */
  public which_chart_type_to_display$$ = this._user_favorite_chart_type$$.pipe(
    switchMap(data_view => {
      if (data_view !== DATA_VIEW.AUTOMATIC) {
        return of(data_view);
      }

      return of(data_view).pipe(
        // Check for beelive data view
        switchMap(_data_view =>
          this.has_devices_for_beecounting$$.pipe(
            map(has_devices_for_beecounting => {
              if (!has_devices_for_beecounting) {
                return DATA_VIEW.AUTOMATIC;
              }

              return DATA_VIEW.COUNTER;
            })
          )
        ),
        // Check for weight data view
        switchMap(_data_view => {
          if (_data_view !== DATA_VIEW.AUTOMATIC) {
            return of(_data_view);
          }

          return this.has_devices_for_weight_data$$.pipe(
            map(has_devices_for_weight_data => {
              if (has_devices_for_weight_data) {
                return DATA_VIEW.WEIGHT_LINEAR;
              }

              return DATA_VIEW.TEMPERATURE_INTERNAL_LINEAR;
            })
          );
        })
      );
    }),
    tap(show_diff_weight_chart => (this.which_chart_type_to_display = show_diff_weight_chart)),
    switchMap(() => this._which_chart_type_to_display$$.asObservable()),
    replay()
  );

  /** */
  public set which_chart_type_to_display(which_chart_type_to_display: DATA_VIEW) {
    this._which_chart_type_to_display$$.next(which_chart_type_to_display);
  }

  /** */
  public is_favorite_chart_type_selected$$ = this._which_chart_type_to_display$$.pipe(
    switchMap(which_chart_type_to_display =>
      this._user_favorite_chart_type$$.pipe(map(user_favorite_chart_type => which_chart_type_to_display === user_favorite_chart_type))
    ),
    replay()
  );

  /** */
  public when_user_add_view_to_favorite(add_to_favorite: boolean) {
    console.log({ add_to_favorite });

    combineLatest({ user: this._app_state_service.user$$, chart_type: this.which_chart_type_to_display$$ })
      .pipe(
        waitForNotNilProperties(),
        take(1),
        switchMap(({ user, chart_type }) => {
          user.setPartialParams({ app_settings: { apiary: { list: { default_chart_type: chart_type } } } });
          return user.save(['params']);
        }),
        take(1)
      )
      .subscribe();
  }

  // #endregion

  // #region -> (weather data)

  /**
   * Observes the apiary weather raw data.
   */
  public weather_data$$: Observable<WeatherData> = this.date_range_manager.range$$.pipe(
    tap(() => this.loadings.weather_data$$.next(true)),
    switchMap(range =>
      this.apiary$$.pipe(
        switchMap(apiary => {
          const start = range.start;
          const end = range.end.plus({ minutes: 30 });

          return apiary.stream_weather_data$$(start.toJSDate(), end.toJSDate());
        })
      )
    ),
    switchMap(weather_data =>
      combineLatest({ elevation: this.location_elevation$$, latitude: this.location_latitude$$ }).pipe(
        map(({ elevation, latitude }) => this.apply_data_transformations(weather_data, elevation, latitude))
      )
    ),
    tap(() => this.loadings.weather_data$$.next(false)),
    replay()
  );

  /** */
  private apply_data_transformations = (weather_data: WeatherData, elevation: number, latitude: number): WeatherData => {
    if (isEmpty(weather_data.points)) {
      return weather_data;
    }

    weather_data.points = weather_data?.points.map((point, index, self) => {
      const is_first_index = index === 0;

      // Compute pressure data
      point.pressure.value_sea_level = !isNil(point?.pressure?.value)
        ? compute_pressure_at_sea_level(point.pressure?.value, point.temperature, elevation, latitude)
        : null;
      point.pressure.range_up = computes_barometric_pressure_from_sea_pressure(1015, point.temperature, elevation, latitude);
      point.pressure.range_down = computes_barometric_pressure_from_sea_pressure(1010, point.temperature, elevation, latitude);
      point.pressure.zone = !isNil(point?.pressure?.value)
        ? point?.pressure?.value >= point?.pressure?.range_up
          ? 'high'
          : point?.pressure?.value <= point?.pressure?.range_down
          ? 'low'
          : 'normal'
        : null;

      // Compute cumulative rain
      if (weather_data?.has_rain_data) {
        point.rain_cumul_day = point?.rain ?? null;

        if (!is_first_index) {
          const current_date = point.date;
          const previous_date = self[index - 1].date;

          if (isSameDay(current_date, previous_date)) {
            if (!isNil(point?.rain_cumul_day)) {
              point.rain_cumul_day = self[index - 1].rain_cumul_day + point.rain_cumul_day;
            }
          }
        }
      }

      return point;
    });

    return weather_data;
  };

  // #endregion

  // #region -> (temperature data)

  /** */
  public temperature_data$$ = this.date_range_manager.range$$.pipe(
    tap(() => this.loadings.temperature_data$$.next(true)),
    switchMap(date_range =>
      this.apiary$$.pipe(
        switchMap(apiary => {
          const start_date = date_range.start;
          const end_date = date_range.end.plus({ minutes: 30 });

          return apiary?.stream_temperature_data$$(start_date.toJSDate(), end_date.toJSDate());
        })
      )
    ),
    tap(() => this.loadings.temperature_data$$.next(false)),
    replay()
  );

  // #endregion

  // #region -> (weight data)

  /** */
  private has_devices_for_weight_data$$ = this.apiary$$.pipe(switchMap(apiary => apiary.has_devices_for_weight_measure$$));

  /** */
  public weight_data$$: Observable<ApiaryWeightsData> = this.date_range_manager.range$$.pipe(
    tap(() => this.loadings.weight_data$$.next(true)),
    switchMap(date_range =>
      this.apiary$$.pipe(
        switchMap(apiary => {
          const start_date = date_range.start;
          const end_date = date_range.end.plus({ minutes: 30 });

          return apiary.stream_weight_data$$(start_date.toJSDate(), end_date.toJSDate());
        })
      )
    ),

    tap(() => this.loadings.weight_data$$.next(false)),
    replay()
  );

  /** */
  public weight_diff_data$$: Observable<{ date: Date; values: HiveDiffWeightPoint[] }[]> = this.weight_data$$.pipe(
    map(apiary_weights_data => {
      const hives_weight: HiveWeightData[] = apiary_weights_data?.hives_weight;

      const final = hives_weight?.map(data => {
        const hive_weights_by_day = group_data_by_day(data.values);

        const hive_weights_by_day_summarized = hive_weights_by_day.map((current: { date_of_day: Date; values: WeightDataPoint[] }) => {
          const have_at_least_one_not_nil_value = some(current.values, point => !isNil(point.weight_diff));

          const summary_weight_data_point: HiveDiffWeightPoint = {
            hive_id: data.hive_id,
            hive_num: data.hive_num,
            hive_name: data.hive_name,
            hive_color: data.hive_color,

            nb_meas: current.values?.length,

            weight: current.values.slice(-1)[0]?.weight,
            weight_diff: have_at_least_one_not_nil_value ? sumBy(current.values, d => d.weight_diff) : null,
          };

          return { date: current.date_of_day, point: summary_weight_data_point };
        });

        return hive_weights_by_day_summarized;
      });

      return flatten(final).reduce((build: { date: Date; tz_date: Date; timezone: string; values: HiveDiffWeightPoint[] }[], current) => {
        const index = findIndex(build, value => isEqual(value.date, current.date));

        if (index === -1) {
          build.push({
            date: current?.date,
            timezone: apiary_weights_data.timezone,
            tz_date: utcToZonedTime(current?.date, apiary_weights_data.timezone),
            values: [current?.point],
          });
        } else {
          build[index].values = (build[index].values ?? []).concat(current?.point);
        }

        return build;
      }, []);
    })
  );

  // #endregion

  // #region -> (bee counting data)

  /** */
  public has_devices_for_beecounting$$ = this.apiary$$.pipe(switchMap(apiary => apiary.has_devices_for_beecounting$$));

  /** */
  public counter_data$$ = this.date_range_manager.range$$.pipe(
    tap(() => this.loadings.counting_data$$.next(true)),
    switchMap(date_range =>
      this.apiary$$.pipe(
        switchMap(apiary => {
          const start_date = date_range.start;
          const end_date = date_range.end.plus({ minutes: 30 });

          return apiary.stream_beecounter_data$$(start_date.toJSDate(), end_date.toJSDate());
        })
      )
    ),
    tap(() => this.loadings.counting_data$$.next(false)),
    replay()
  );

  // #endregion

  // #region -> (chart legend management)

  /** */
  public show_total_WG_sensors_changed$$ = this.weight_data$$.pipe(
    map(apiary_weights_data => {
      const losts_and_restablished_sensors = apiary_weights_data?.hives_weight?.reduce((next: boolean[], current) => {
        const hive_weight_data_values = current?.values ?? [];
        const assoc_restablished_lost_sensors = flatten(
          hive_weight_data_values.map(hive_weight_data_value => [
            hive_weight_data_value?.has_lost_sensors ?? false,
            hive_weight_data_value?.has_restablished_sensors ?? false,
          ])
        );

        return next.concat(assoc_restablished_lost_sensors);
      }, []);

      return some(losts_and_restablished_sensors);
    }),
    distinctUntilRealChanged(),
    replay()
  );

  // #endregion

  /** */
  public loadings = {
    /** */
    weather_data$$: new BehaviorSubject(true),

    /** */
    weight_data$$: new BehaviorSubject(true),

    /** */
    counting_data$$: new BehaviorSubject(true),

    /** */
    temperature_data$$: new BehaviorSubject(true),
  };
}
