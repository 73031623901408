/** */
export enum ZohoDeskTicketStatusType {
  /** */
  OPEN = 'Open',

  /** */
  CLOSED = 'Closed',

  /** */
  ON_HOLD = 'On Hold',
}
