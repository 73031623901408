<div *ngLet="device$$ | async; let device" class="bg2-device-modal-data-component">
  <!-- CARD: Last data -->
  <div *ngIf="is_either_view" class="device-card-template">
    <div class="device-card-template__normal-container">
      <div class="device-detail__title-container">
        <div class="title-container__icon --color-brown">
          <span class="mdi mdi-numeric mdi-36px"></span>
        </div>

        <div class="title-container__title">{{ 'VIEWS.DEVICES.DIALOGS_AND_MODALS.DEVICE_MODAL.DETAILS.DATA.Data' | translate }}</div>
      </div>

      <div class="device-modal-card-container__body-container">
        <div class="body-container__properties">
          <!-- Last device temperature -->
          <div *ngIf="(device?.last_temperature$$ | async)?.exists" class="body-container__property-container">
            <div class="device-details-properties__property-container__label-container">
              <span class="mdi mdi-thermometer mdi-24px summary__value-container__icon"></span>
              <b class="hide-on-small">{{ 'ALL.DATA.LABELS_FULL.Temperature' | translate | colon }}</b>
            </div>

            <span
              *ngLet="(device?.last_temperature$$ | async)?.value; let temperature"
              class="device-details-properties__property-container__value"
            >
              <span
                *ngIf="temperature?.minimum >= 0 || temperature?.minimum < 0"
                style="color: cornflowerblue"
                [popper]="'VIEWS_WINDOWED.MODALS.DEVICE_MODAL.Minimal temperature (last 24h)' | translate"
              >
                ({{ temperature.minimum | localeNumber : '0.0-1' }} °C)
              </span>
              <span class="mdi mdi-thermometer-minus" style="color: cornflowerblue"></span>
            </span>

            <span
              *ngLet="(device?.last_temperature$$ | async)?.value; let temperature"
              class="device-details-properties__property-container__value"
            >
              <span
                *ngIf="temperature?.maximum >= 0 || temperature?.maximum < 0"
                style="color: red"
                [popper]="'VIEWS_WINDOWED.MODALS.DEVICE_MODAL.Maximal temperature (last 24h)' | translate"
              >
                ({{ temperature.maximum | localeNumber : '0.0-1' }} °C)
              </span>
              <span class="mdi mdi-thermometer-plus" style="color: red"></span>
            </span>
          </div>

          <!-- Last device weight -->
          <div *ngIf="device?.type === 'WG'" class="body-container__property-container">
            <ng-container *ngIf="device?.last_measurements?.weight?.fields as fields">
              <div class="device-details-properties__property-container__label-container">
                <span class="mdi mdi-weight mdi-24px"></span>
                <b>{{ 'VIEWS_WINDOWED.MODALS.DEVICE_MODAL.Last weight (only scale)' | translate | colon }}</b>
              </div>

              <span class="device-details-properties__property-container__value">
                <span>{{ fields['weight_1'].last + fields['weight_2'].last | localeNumber : '0.0-2' }} kg&nbsp;</span>
                <span
                  [style.color]="'orange'"
                  class="mdi mdi-alert mdi-24px"
                  [popper]="
                    'VIEWS_WINDOWED.MODALS.DEVICE_MODAL.This data is the device\'s raw measurement, which is only half the weight of the hive'
                      | translate
                  "
                ></span>
              </span>
            </ng-container>
          </div>

          <!-- Device data -->
          <div *ngIf="device?.user_acl?.can$$('read_devices_data') | async" class="body-container__property-container">
            <div class="device-details-properties__property-container__label-container">
              <span class="mdi mdi-chart-line mdi-24px"></span>
            </div>

            <span class="device-details-properties__property-container__value">
              <a
                class="summary__value-container__label"
                [routerLink]="[{ outlets: { primary: ['data', 'device'], modal: ['raz'] } }]"
                [queryParams]="{ eids: [{ id: device_imei$$ | async } | json] }"
              >
                {{ 'VIEWS.MODALS.DEVICE.View all of the device\'s data on the "data" page' | translate }}
              </a>
            </span>
          </div>

          <!-- Grafana link -->
          <div *ngIf="is_superadmin$$ | async" class="body-container__property-container --only-superadmin">
            <div class="device-details-properties__property-container__label-container">
              <img class="summary__value-container__icon" src="assets/picons/logo/grafana.svg" alt="" />
            </div>

            <span class="device-details-properties__property-container__value">
              <a class="summary__value-container__label" target="_blank" [href]="device?.grafana_url$$ | async">
                {{ 'VIEWS.MODALS.DEVICE.Show raw data (grafana)' | translate }}
              </a>
            </span>
          </div>
        </div>

        <!-- Device job data chart -->
        <ng-container *ngIf="view === 'summary'">
          <ng-container *ngLet="device_acl$$ | async; let device_acl_error">
            <bg2-error-helper *ngIf="device_acl_error !== null" [error]="device_acl_error"></bg2-error-helper>

            <ng-container *ngIf="device_acl_error === null">
              <div
                *ngLet="device_job_data$$ | async; let device_job_data"
                [ngStyle]="{ height: (device_type$$ | async) === 'RG' ? '315px' : '150px' }"
              >
                <ng-template #loadingJobDataChart>
                  <bg2-loading-skeleton
                    style="width: 100%"
                    [theme]="{ height: (device_type$$ | async) === 'RG' ? '315px' : '150px' }"
                  ></bg2-loading-skeleton>
                </ng-template>

                <ng-container *ngIf="(is_loading_job_data.value$$ | async) === false; else loadingJobDataChart">
                  <!-- GPS -->
                  <bg2-gps-job-data-d3-chart
                    *ngIf="(device_type$$ | async) === 'GPS'"
                    style="width: 100%"
                    [is_loading]="false"
                    [data]="device_job_data"
                    [geoposition]="device_geoposition$$ | async"
                    [date_range]="date_range_manager?.range$$ | async"
                  ></bg2-gps-job-data-d3-chart>

                  <!-- TG -->
                  <bg2-tg-job-data-d3-chart
                    *ngIf="(device_type$$ | async) === 'TG'"
                    style="width: 100%"
                    [is_loading]="false"
                    [data]="device_job_data"
                    [geoposition]="device_geoposition$$ | async"
                    [date_range]="date_range_manager?.range$$ | async"
                  ></bg2-tg-job-data-d3-chart>

                  <!-- RG -->
                  <bg2-rg-job-data-d3-chart
                    *ngIf="(device_type$$ | async) === 'RG'"
                    style="width: 100%"
                    [icons_data]="device_job_data"
                    [weather_data]="device_job_data"
                    [wind_data]="wind_data$$ | async"
                    [geoposition]="device_geoposition$$ | async"
                    [date_range]="date_range_manager?.range$$ | async"
                    [is_loading]="{ icons: false, wind: false, weather: false }"
                  ></bg2-rg-job-data-d3-chart>

                  <!-- WG -->
                  <bg2-wg-job-data-d3-chart
                    *ngIf="(device_type$$ | async) === 'WG'"
                    style="width: 100%"
                    [is_loading]="false"
                    [data]="device_job_data"
                    [geoposition]="device_geoposition$$ | async"
                    [date_range]="date_range_manager?.range$$ | async"
                  ></bg2-wg-job-data-d3-chart>

                  <!-- CPT/BEELIVE -->
                  <bg2-cpt-job-data-d3-chart
                    *ngIf="(device_type$$ | async) === 'CPT' || (device_type$$ | async) === 'BeeLive'"
                    style="width: 100%"
                    [is_loading]="false"
                    [data]="device_job_data"
                    [geoposition]="device_geoposition$$ | async"
                    [date_range]="date_range_manager?.range$$ | async"
                  ></bg2-cpt-job-data-d3-chart>
                </ng-container>
              </div>
            </ng-container>
          </ng-container>
        </ng-container>
      </div>

      <div *ngIf="view === 'summary'" class="device-detail__open-detail-container">
        <bg2-button
          [type]="'navigation'"
          [icon]="'mdi-magnify'"
          [options]="{ is_thin: true }"
          [styles]="{ padding: '2px 5px', margin: '0px' }"
          [message]="'VIEWS.DEVICES.DIALOGS_AND_MODALS.DEVICE_MODAL.DETAILS.MISC.More details' | translate"
          (btn_click)="open_detail.next(true)"
        ></bg2-button>
      </div>
    </div>
  </div>

  <!-- CARD: Data history -->
  <div *ngIf="view === 'details'" class="device-card-template">
    <div class="device-card-template__normal-container">
      <div class="device-detail__title-container">
        <div class="title-container__icon --color-brown">
          <span class="mdi mdi-chart-multiple mdi-36px"></span>
        </div>

        <div class="title-container__title">
          {{ 'VIEWS.DEVICES.DIALOGS_AND_MODALS.DEVICE_MODAL.DETAILS.DATA.Data history' | translate }}
        </div>
      </div>

      <div class="device-modal-card-container__body-container">
        <!-- Job data chart (device dependent) -->
        <div
          *ngLet="device_job_data$$ | async; let device_job_data"
          [ngStyle]="{ height: (device_type$$ | async) === 'RG' ? '315px' : '150px' }"
        >
          <ng-template #loadingJobDataChart>
            <bg2-loading-skeleton
              style="width: 100%"
              [theme]="{ height: (device_type$$ | async) === 'RG' ? '315px' : '150px' }"
            ></bg2-loading-skeleton>
          </ng-template>

          <ng-container *ngIf="(is_loading_job_data.value$$ | async) === false; else loadingJobDataChart">
            <!-- GPS -->
            <bg2-gps-job-data-d3-chart
              *ngIf="(device_type$$ | async) === 'GPS'"
              style="width: 100%"
              [is_loading]="false"
              [data]="device_job_data"
              [geoposition]="device_geoposition$$ | async"
              [date_range]="date_range_manager?.range$$ | async"
            ></bg2-gps-job-data-d3-chart>

            <!-- TG -->
            <bg2-tg-job-data-d3-chart
              *ngIf="(device_type$$ | async) === 'TG'"
              style="width: 100%"
              [is_loading]="false"
              [data]="device_job_data"
              [geoposition]="device_geoposition$$ | async"
              [date_range]="date_range_manager?.range$$ | async"
            ></bg2-tg-job-data-d3-chart>

            <!-- RG -->
            <bg2-rg-job-data-d3-chart
              *ngIf="(device_type$$ | async) === 'RG'"
              style="width: 100%"
              [icons_data]="device_job_data"
              [weather_data]="device_job_data"
              [wind_data]="wind_data$$ | async"
              [geoposition]="device_geoposition$$ | async"
              [date_range]="date_range_manager?.range$$ | async"
              [is_loading]="{ icons: false, wind: false, weather: false }"
            ></bg2-rg-job-data-d3-chart>

            <!-- WG -->
            <bg2-wg-job-data-d3-chart
              *ngIf="(device_type$$ | async) === 'WG'"
              style="width: 100%"
              [is_loading]="false"
              [data]="device_job_data"
              [geoposition]="device_geoposition$$ | async"
              [date_range]="date_range_manager?.range$$ | async"
            ></bg2-wg-job-data-d3-chart>

            <!-- CPT/BEELIVE -->
            <bg2-cpt-job-data-d3-chart
              *ngIf="(device_type$$ | async) === 'CPT' || (device_type$$ | async) === 'BeeLive'"
              style="width: 100%"
              [is_loading]="false"
              [data]="device_job_data"
              [geoposition]="device_geoposition$$ | async"
              [date_range]="date_range_manager?.range$$ | async"
            ></bg2-cpt-job-data-d3-chart>
          </ng-container>
        </div>

        <!-- Beelive photos -->
        <ng-container *ngIf="((device_type$$ | async) === 'CPT' || (device_type$$ | async) === 'BeeLive') && (is_superadmin$$ | async)">
          <bg2-beelive-photos-carousel [device]="$any(device$$ | async)"></bg2-beelive-photos-carousel>
        </ng-container>

        <!-- Secondary data chart -->
        <ng-container *ngLet="secondary_data$$ | async; let secondary_data">
          <ng-template #loadingSecondaryData>
            <bg2-loading-skeleton style="width: 100%" [theme]="{ height: '150px' }"></bg2-loading-skeleton>
          </ng-template>

          <!-- Temperature chart (WG) -->
          <ng-container *ngIf="is_device_is_wg$$ | async">
            <bg2-single-line-chart
              *ngIf="(is_loading_secondary_data.value$$ | async) === false; else loadingSecondaryData"
              style="width: 100%"
              [data]="secondary_data"
              [geoposition]="device_geoposition$$ | async"
              [date_range]="date_range_manager?.range$$ | async"
              [is_loading]="is_loading_secondary_data.value$$ | async"
              [options]="{
                path: 'temperature',
                color: '#ff0000',
                labels: {
                  unit: 'ALL.DATA.UNITS.°C',
                  label_short_with_unit: 'ALL.DATA.LABELS_SHORT_WITH_UNIT.Temperature (°C)',
                  label_full_without_unit: 'ALL.DATA.LABELS_FULL.Temperature'
                }
              }"
            ></bg2-single-line-chart>
          </ng-container>

          <!-- Humidity chart (WG/gen1) -->
          <ng-container *ngIf="(is_device_is_wg$$ | async) && (device_generation$$ | async) === 1">
            <bg2-single-line-chart
              *ngIf="(is_loading_secondary_data.value$$ | async) === false; else loadingSecondaryData"
              style="width: 100%"
              [data]="secondary_data"
              [geoposition]="device_geoposition$$ | async"
              [date_range]="date_range_manager?.range$$ | async"
              [is_loading]="is_loading_secondary_data.value$$ | async"
              [options]="{
                path: 'humidity',
                color: '#008000',
                labels: {
                  unit: 'ALL.DATA.UNITS.%',
                  label_short_with_unit: 'ALL.DATA.LABELS_SHORT_WITH_UNIT.Humidity (%)',
                  label_full_without_unit: 'ALL.DATA.LABELS_FULL.Humidity'
                }
              }"
            ></bg2-single-line-chart>
          </ng-container>

          <!-- Pressure chart (WG/gen1) -->
          <ng-container *ngIf="(is_device_is_wg$$ | async) && (device_generation$$ | async) === 1">
            <bg2-single-line-chart
              *ngIf="(is_loading_secondary_data.value$$ | async) === false; else loadingSecondaryData"
              style="width: 100%"
              [data]="secondary_data"
              [geoposition]="device_geoposition$$ | async"
              [date_range]="date_range_manager?.range$$ | async"
              [is_loading]="is_loading_secondary_data.value$$ | async"
              [options]="{
                path: 'pressure',
                color: '#1e90ff',
                labels: {
                  unit: 'ALL.DATA.UNITS.hPa',
                  label_short_with_unit: 'ALL.DATA.LABELS_SHORT_WITH_UNIT.Atmospheric pressure (hPa)',
                  label_full_without_unit: 'ALL.DATA.LABELS_FULL.Atmospheric pressure'
                }
              }"
            ></bg2-single-line-chart>
          </ng-container>
        </ng-container>
      </div>
    </div>
  </div>
</div>
