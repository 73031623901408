import { isNumber } from 'lodash-es';

/**
 * Calculates the X percentile of a dataset. Also, checkout {@link https://goodcalculators.com/percentile-calculator/} for
 * formula and online calculator.
 *
 * @returns An array with the value at index 0 and the value's index at index 1.
 */
export const percentile = (search_percentile: number, dataset: number[]): [number, number] => {
  if (dataset.length === 0) {
    return [undefined, -1];
  }

  const dataset_with_idx = dataset.map((value, index) => <[number, number]>[value, index]);
  const sorted_dataset = dataset_with_idx.sort(([value_a], [value_b]) => {
    if (!isNumber(value_a)) {
      value_a = Number.NEGATIVE_INFINITY;
    }

    if (!isNumber(value_b)) {
      value_b = Number.NEGATIVE_INFINITY;
    }

    if (value_a > value_b) {
      return 1;
    }

    if (value_a < value_b) {
      return -1;
    }

    return 0;
  });

  let index_of_percentile = (search_percentile * dataset.length) / 100;
  index_of_percentile = Math.ceil(index_of_percentile);

  return [sorted_dataset[index_of_percentile - 1][0], sorted_dataset[index_of_percentile - 1][1]];
};
