import { UntypedFormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';

/** */
export function SameValueValidator(controlName: string, matchingControlName: string): ValidatorFn {
  return (form_group: UntypedFormGroup): ValidationErrors => {
    const control = form_group.controls[controlName];
    const matching_control = form_group.controls[matchingControlName];

    if (matching_control.errors && !matching_control.errors.confirmedValidator) {
      return;
    }

    if (control.value !== matching_control.value) {
      matching_control.setErrors({ matchedValue: true });
    } else {
      matching_control.setErrors(null);
    }
  };
}
