<!-- Null wind speed template -->
<ng-template #nullWindSpeed>
  <b>-</b>
</ng-template>

<!-- Null wind speed template -->
<ng-template #nullWindHeading>
  <span>-</span>
</ng-template>

<div class="picto-wind-direction-component">
  <ng-container *ngIf="is_not_nil_raw_wind_heading$$ | async; else nullWindHeading">
    <div class="wind-heading">
      <svg
        width="16"
        height="16"
        version="1.1"
        viewBox="0 0 4.2333333 4.2333333"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:svg="http://www.w3.org/2000/svg"
      >
        <g [ngStyle]="{ transform: (wind_heading_data$$ | async)?.transform }">
          <g transform="matrix(0.12529095,0,0,0.10659796,-1.0156071,-0.26046786)">
            <g transform="matrix(0.71169519,0,0,0.8364979,7.2076202,3.6460968)">
              <polygon
                *ngIf="(wind_heading_data$$ | async) !== null; else notReliable"
                class="wind-arrow-polygon"
                points="10.9,40.4 25.4,33.2 39.1,40 25,4.2"
              />
              <ng-template #notReliable>
                <path
                  d="M32.3,13.1c1.1,0.7,2.1,1.5,2.9,2.4c5.6,5.6,5.6,14.7,0,20.4c-5.5,5.6-14.5,5.6-20,0.1l-0.1-0.1 c-5.5-5.7-5.5-14.8,0.1-20.4l3.7,6.9l2.1-15.2L4.1,10.8l8,2c-7.2,7.2-7.2,18.8,0,26.1c7.1,7.2,18.5,7.3,25.7,0.3 c0.1-0.1,0.3-0.3,0.3-0.3c7.2-7.3,7.2-18.9,0-26.3c-1.5-1.3-3.1-2.5-4.8-3.3L32.3,13.1z"
                />
              </ng-template>
            </g>
          </g>
        </g>
      </svg>

      <ng-container *ngIf="(wind_heading_data$$ | async)?.name === '?'; else normalDirection">
        <span
          class="explicit-direction mdi mdi-help-circle not-enough-wind"
          [popper]="
            'WIDGETS.WIDGETS_REUSABLES.SVG_PICONS.PICTO_WIND_DIRECTION.There is not wind enough to determinate the wind direction'
              | translate
          "
        >
        </span>
      </ng-container>

      <ng-template #normalDirection>
        <span class="explicit-direction">({{ (wind_heading_data$$ | async)?.name }})</span>
      </ng-template>
    </div>
  </ng-container>

  <span class="wind-speed">
    <ng-container *ngIf="(is_not_nil_raw_wind_speed$$ | async) && raw_wind_speed >= 0; else nullWindSpeed">
      <b>{{ raw_wind_speed$$ | async | fixed : 0 }}</b>
    </ng-container>

    <span>&nbsp;km/h</span>
  </span>
</div>
