import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule, CurrencyPipe } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';


import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime-ex';

import { SchemaFormModule } from 'ngx-schema-form';

import { MiscModule } from 'app/misc/misc.module';
import { MiscWidgetsModule } from 'app/widgets/misc-widgets/misc-widgets.module';

import { SharedModule } from 'app/shared-module/shared.module';
import { EventsSharedModule } from 'app/views/events/events-shared.module';
import { HivesSharedModule } from 'app/views/hives/shared/hives-shared.module';
import { EntitiesSharedModule } from 'app/views/entities/entities-shared.module';
import { ApiarySharedModule } from 'app/views/apiary/apiary-shared.module';
import { DevicesSharedModule } from '../views/devices/shared/devices-shared.module';

import { MaterialSharedModule } from 'app/shared-module/material-shared.module';
import { DialogsModalsModule } from '../widgets/dialogs-modals/dialogs-modals.module';
import { WidgetsReusableModule } from 'app/widgets/widgets-reusables/widgets-reusables.module';

import { NewEventModalComponent } from './new-event/new-event.modal';
import { NewIssueModalComponent } from './new-issue/new-issue.modal';
import { MigratoryModal } from './migratory/migratory.modal';
import { NewEntityModal } from './new-entity/new-entity.modal';
import { WelcomeModalComponent } from './welcome/welcome.modal';
import { UserManualModal } from './user-manual/user-manual.modal';
import { UpdateEventModalComponent } from './update-event/update-event.modal';
import { UpdateEntityModal } from './update-entity/update-entity.modal';
import { ApiaryHiveListModal } from './apiary-hive-list/apiary-hive-list.modal';
import { NewExploitationModal } from './new-exploitation/new-exploitation.modal';
import { UpdateEntityACLModal } from './update-entity-acl/update-entity-acl.modal';
import { ApiaryEvaluationModal } from './apiary-evaluation/apiary-evaluation.modal';
import { VisitModalComponent } from './visit-modal/visit-modal.component';
import { DeviceDialogsAndModalsModule } from 'app/views/devices/dialogs-and-modals/device-dialogs-and-modals.module';
import { LocationsModalsModule } from 'app/views/locations/modals/location-modals.module';
import { UserDialogsAndModalsModule } from 'app/views/user/dialogs-and-modals/user-dialogs-and-modals.module';

import { NavigateIdentifiedHivesComponent } from './visit-modal/widgets/navigation-bars/navigate-identified-hives/navigate-identified-hives.component';
import { NavigateNotIdentifiedHivesComponent } from './visit-modal/widgets/navigation-bars/navigate-not-identified-hives/navigate-not-identified-hives.component';
import { ZohoDialogsAndModalsModule } from 'app/views/zoho/dialogs-and-modals/zoho-dialogs-and-modals.module';
import { ZohoSharedModule } from 'app/views/zoho/shared/zoho-shared.module';
import { NavigateVisitAspectsComponent } from './visit-modal/widgets/navigation-bars/navigate-visit-aspects/navigate-visit-aspects.component';


@NgModule({
  declarations: [
    NewEventModalComponent,
    NewIssueModalComponent,
    NewEntityModal,
    MigratoryModal,
    UserManualModal,
    UpdateEventModalComponent,
    UpdateEntityModal,
    ApiaryHiveListModal,
    NewExploitationModal,
    UpdateEntityACLModal,
    WelcomeModalComponent,
    ApiaryEvaluationModal,
    VisitModalComponent,
    NavigateVisitAspectsComponent,
    NavigateIdentifiedHivesComponent,
    NavigateNotIdentifiedHivesComponent,
  ],

  imports: [
    CommonModule,
    MiscModule,
    FormsModule,
    RouterModule,
    SharedModule,
    OwlDateTimeModule,
    ReactiveFormsModule,
    DialogsModalsModule,
    OwlNativeDateTimeModule,
    SchemaFormModule.forRoot(),
    DevicesSharedModule,
    HivesSharedModule,
    MiscWidgetsModule,
    ApiarySharedModule,
    MaterialSharedModule,
    EventsSharedModule,
    EntitiesSharedModule,
    WidgetsReusableModule,

    // Other modals modules
    LocationsModalsModule,
    UserDialogsAndModalsModule,
    DeviceDialogsAndModalsModule,
    ZohoSharedModule,
    ZohoDialogsAndModalsModule,
  ],

  exports: [],

  providers: [CurrencyPipe],
})
export class ModalsModule {}
