import { ChangeDetectionStrategy, Component } from '@angular/core';

import { map, of, switchMap, take, tap } from 'rxjs';
import { replay, waitForNotNilValue } from '@bg2app/tools/rxjs';

import { isNil } from 'lodash-es';

import { Beeguard2Api, DeviceApi, UsersApiService } from 'app/core';
import { DialogsService } from 'app/widgets/dialogs-modals';
import { AppStateService } from 'app/core/app-state.service';

import { DeviceModalAbstractCategoryComponent } from '../device-modal-abstract-details-or-summary.component';
import {
  DevicesRegisterDialogComponent,
  DevicesRegisterDialogParams,
} from 'app/views/devices/dialogs-and-modals/dialogs/device-register-dialogs/devices-register.dialog';

import { SimpleSetterGetter } from 'app/models';
import { DeviceInterface } from 'app/models/devices/DRDevice';

@Component({
  selector: 'bg2-device-modal-affectation',
  templateUrl: './device-modal-affectation.component.html',
  styleUrls: ['./device-modal-affectation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DeviceModalAffectationComponent extends DeviceModalAbstractCategoryComponent {
  // #region -> (component basics)

  constructor(
    protected readonly _deviceApi: DeviceApi,
    protected readonly _dialogs: DialogsService,
    protected readonly _appStateService: AppStateService,

    private readonly _bg2Api: Beeguard2Api,
    private readonly _usersApi: UsersApiService
  ) {
    super(_appStateService, _dialogs, _deviceApi);
  }

  // #endregion

  // #region -> (loadings)

  /** */
  protected is_loading_affection = new SimpleSetterGetter(true);

  /** */
  protected is_loading_owner = new SimpleSetterGetter(true);

  // #endregion

  // #region -> (device basics)

  /** */
  public device_affectation$$ = this.device$$.pipe(
    tap(() => (this.is_loading_affection.value = true)),
    switchMap(device => {
      if (isNil(device)) {
        return of(null);
      }

      return device.requestAffectation(this._bg2Api);
    }),
    tap(() => (this.is_loading_affection.value = false)),
    replay()
  );

  /**
   * Observes the owner of the device.
   */
  public device_owner$$ = this.device$$.pipe(
    tap(() => (this.is_loading_owner.value = true)),
    waitForNotNilValue(),
    switchMap(device => device.request_owner_user$(this._usersApi)),
    tap(() => (this.is_loading_owner.value = false)),
    replay()
  );

  // #endregion

  // #region -> (exploitation affectation)

  /** */
  public exploitation_affectation_event$$ = this.device_affectation$$.pipe(
    map(affectation => affectation?.exploitation),
    switchMap(affectation => {
      if (isNil(affectation?.since?.event_id)) {
        return of(null);
      }

      return this._bg2Api.fetch_event$(affectation?.since?.event_id).pipe();
    }),
    replay()
  );

  // #endregion

  /** */
  public has_base_affectation$$ = this.device_affectation$$.pipe(
    map(affectation => !isNil(affectation?.exploitation) && !isNil(affectation?.warehouse)),
    replay()
  );

  /** */
  public can_be_affected_to_hive$$ = this.device$$.pipe(
    waitForNotNilValue(),
    switchMap(device => device.type$$),
    map(device_type => {
      if (device_type === DeviceInterface.TypeEnum.RG) {
        return false;
      }

      return true;
    }),
    replay()
  );

  /** */
  public change_owner() {
    this.change_owner_and_exploitation(true);
  }

  public change_owner_and_exploitation(ignore_set_exploitation: boolean = false) {
    this.device$$
      .pipe(
        waitForNotNilValue(),
        take(1),
        switchMap(device =>
          this._dialogs.open<DevicesRegisterDialogParams, any>(DevicesRegisterDialogComponent, {
            ignore_set_exploitation,
            devices: [device],
          })
        )
      )
      .subscribe();
  }
}
