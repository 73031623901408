/**
 * identity-server
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: dev
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { GetGroupsUser } from '../model/getGroupsUser';
import { GetOneUser } from '../model/getOneUser';
import { GetRelationShipsUser } from '../model/getRelationShipsUser';
import { GetSubordinatesUser } from '../model/getSubordinatesUser';
import { GetUsersList } from '../model/getUsersList';
import { IdentitySrvError } from '../model/identitySrvError';
import { InputChangePassword } from '../model/inputChangePassword';
import { InputCreateUser } from '../model/inputCreateUser';
import { InputSetPassword } from '../model/inputSetPassword';
import { InputUpdateUser } from '../model/inputUpdateUser';
import { UserPasswordHashed } from '../model/userPasswordHashed';
import { UserResponse } from '../model/userResponse';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class UsersService {

    protected basePath = '/';
    protected defaultHeaders = new HttpHeaders();
    protected configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Create a new user
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    protected createUser(body: InputCreateUser, observe?: 'body', reportProgress?: boolean): Observable<UserResponse>;
    protected createUser(body: InputCreateUser, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<UserResponse>>;
    protected createUser(body: InputCreateUser, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<UserResponse>>;
    protected createUser(body: InputCreateUser, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling createUser.');
        }

        let headers = this.defaultHeaders;

        // authentication (OAuth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<UserResponse>('post',`${this.basePath}/users/`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Delete an existing user identified by its ID &#x60;user_id&#x60;
     * 
     * @param user_id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    protected deleteUser(user_id: number, observe?: 'body', reportProgress?: boolean): Observable<UserResponse>;
    protected deleteUser(user_id: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<UserResponse>>;
    protected deleteUser(user_id: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<UserResponse>>;
    protected deleteUser(user_id: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (user_id === null || user_id === undefined) {
            throw new Error('Required parameter user_id was null or undefined when calling deleteUser.');
        }

        let headers = this.defaultHeaders;

        // authentication (OAuth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<UserResponse>('delete',`${this.basePath}/users/${encodeURIComponent(String(user_id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get info about an existing user identified by its ID &#x60;user_id&#x60;
     * 
     * @param user_id 
     * @param only Return only the given fields
     * @param exclude Exclude the given fields
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    protected getUser(user_id: number, only?: Array<string>, exclude?: Array<string>, observe?: 'body', reportProgress?: boolean): Observable<GetOneUser>;
    protected getUser(user_id: number, only?: Array<string>, exclude?: Array<string>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GetOneUser>>;
    protected getUser(user_id: number, only?: Array<string>, exclude?: Array<string>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GetOneUser>>;
    protected getUser(user_id: number, only?: Array<string>, exclude?: Array<string>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (user_id === null || user_id === undefined) {
            throw new Error('Required parameter user_id was null or undefined when calling getUser.');
        }



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (only) {
            only.forEach((element) => {
                queryParameters = queryParameters.append('only', <any>element);
            })
        }
        if (exclude) {
            exclude.forEach((element) => {
                queryParameters = queryParameters.append('exclude', <any>element);
            })
        }

        let headers = this.defaultHeaders;

        // authentication (OAuth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<GetOneUser>('get',`${this.basePath}/users/${encodeURIComponent(String(user_id))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get all groups about an existing user identified by its ID &#x60;user_id&#x60;
     * 
     * @param user_id 
     * @param only Return only the given fields
     * @param exclude Exclude the given fields
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    protected getUserGroups(user_id: number, only?: Array<string>, exclude?: Array<string>, observe?: 'body', reportProgress?: boolean): Observable<GetGroupsUser>;
    protected getUserGroups(user_id: number, only?: Array<string>, exclude?: Array<string>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GetGroupsUser>>;
    protected getUserGroups(user_id: number, only?: Array<string>, exclude?: Array<string>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GetGroupsUser>>;
    protected getUserGroups(user_id: number, only?: Array<string>, exclude?: Array<string>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (user_id === null || user_id === undefined) {
            throw new Error('Required parameter user_id was null or undefined when calling getUserGroups.');
        }



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (only) {
            only.forEach((element) => {
                queryParameters = queryParameters.append('only', <any>element);
            })
        }
        if (exclude) {
            exclude.forEach((element) => {
                queryParameters = queryParameters.append('exclude', <any>element);
            })
        }

        let headers = this.defaultHeaders;

        // authentication (OAuth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<GetGroupsUser>('get',`${this.basePath}/users/${encodeURIComponent(String(user_id))}/groups`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get all relationships about an existing user identified by its ID &#x60;user_id&#x60;
     * 
     * @param user_id 
     * @param only Return only the given fields
     * @param exclude Exclude the given fields
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    protected getUserRelationShips(user_id: number, only?: Array<string>, exclude?: Array<string>, observe?: 'body', reportProgress?: boolean): Observable<GetRelationShipsUser>;
    protected getUserRelationShips(user_id: number, only?: Array<string>, exclude?: Array<string>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GetRelationShipsUser>>;
    protected getUserRelationShips(user_id: number, only?: Array<string>, exclude?: Array<string>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GetRelationShipsUser>>;
    protected getUserRelationShips(user_id: number, only?: Array<string>, exclude?: Array<string>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (user_id === null || user_id === undefined) {
            throw new Error('Required parameter user_id was null or undefined when calling getUserRelationShips.');
        }



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (only) {
            only.forEach((element) => {
                queryParameters = queryParameters.append('only', <any>element);
            })
        }
        if (exclude) {
            exclude.forEach((element) => {
                queryParameters = queryParameters.append('exclude', <any>element);
            })
        }

        let headers = this.defaultHeaders;

        // authentication (OAuth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<GetRelationShipsUser>('get',`${this.basePath}/users/${encodeURIComponent(String(user_id))}/relationships`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get all subordinates about an existing user identified by its ID &#x60;user_id&#x60;
     * 
     * @param user_id 
     * @param only Return only the given fields
     * @param exclude Exclude the given fields
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    protected getUserSubordinates(user_id: number, only?: Array<string>, exclude?: Array<string>, observe?: 'body', reportProgress?: boolean): Observable<GetSubordinatesUser>;
    protected getUserSubordinates(user_id: number, only?: Array<string>, exclude?: Array<string>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GetSubordinatesUser>>;
    protected getUserSubordinates(user_id: number, only?: Array<string>, exclude?: Array<string>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GetSubordinatesUser>>;
    protected getUserSubordinates(user_id: number, only?: Array<string>, exclude?: Array<string>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (user_id === null || user_id === undefined) {
            throw new Error('Required parameter user_id was null or undefined when calling getUserSubordinates.');
        }



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (only) {
            only.forEach((element) => {
                queryParameters = queryParameters.append('only', <any>element);
            })
        }
        if (exclude) {
            exclude.forEach((element) => {
                queryParameters = queryParameters.append('exclude', <any>element);
            })
        }

        let headers = this.defaultHeaders;

        // authentication (OAuth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<GetSubordinatesUser>('get',`${this.basePath}/users/${encodeURIComponent(String(user_id))}/subordinates`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Retrieve list of users
     * 
     * @param query 
     * @param only Return only the given fields
     * @param exclude Exclude the given fields
     * @param offset Pagging offset
     * @param limit Max number of user to return
     * @param sort 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    protected listUsers(query?: string, only?: Array<string>, exclude?: Array<string>, offset?: number, limit?: number, sort?: Array<string>, observe?: 'body', reportProgress?: boolean): Observable<GetUsersList>;
    protected listUsers(query?: string, only?: Array<string>, exclude?: Array<string>, offset?: number, limit?: number, sort?: Array<string>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GetUsersList>>;
    protected listUsers(query?: string, only?: Array<string>, exclude?: Array<string>, offset?: number, limit?: number, sort?: Array<string>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GetUsersList>>;
    protected listUsers(query?: string, only?: Array<string>, exclude?: Array<string>, offset?: number, limit?: number, sort?: Array<string>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {







        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (query !== undefined && query !== null) {
            queryParameters = queryParameters.set('query', <any>query);
        }
        if (only) {
            only.forEach((element) => {
                queryParameters = queryParameters.append('only', <any>element);
            })
        }
        if (exclude) {
            exclude.forEach((element) => {
                queryParameters = queryParameters.append('exclude', <any>element);
            })
        }
        if (offset !== undefined && offset !== null) {
            queryParameters = queryParameters.set('offset', <any>offset);
        }
        if (limit !== undefined && limit !== null) {
            queryParameters = queryParameters.set('limit', <any>limit);
        }
        if (sort) {
            sort.forEach((element) => {
                queryParameters = queryParameters.append('sort', <any>element);
            })
        }

        let headers = this.defaultHeaders;

        // authentication (OAuth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<GetUsersList>('get',`${this.basePath}/users/`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update an existing user identified by its ID &#x60;user_id&#x60;
     * 
     * @param body 
     * @param user_id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    protected updateUser(body: InputUpdateUser, user_id: number, observe?: 'body', reportProgress?: boolean): Observable<UserResponse>;
    protected updateUser(body: InputUpdateUser, user_id: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<UserResponse>>;
    protected updateUser(body: InputUpdateUser, user_id: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<UserResponse>>;
    protected updateUser(body: InputUpdateUser, user_id: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling updateUser.');
        }

        if (user_id === null || user_id === undefined) {
            throw new Error('Required parameter user_id was null or undefined when calling updateUser.');
        }

        let headers = this.defaultHeaders;

        // authentication (OAuth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<UserResponse>('put',`${this.basePath}/users/${encodeURIComponent(String(user_id))}`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update password for an existing user identified by its ID &#x60;user_id&#x60;
     * 
     * @param body 
     * @param user_id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    protected updateUserPassword(body: InputChangePassword, user_id: number, observe?: 'body', reportProgress?: boolean): Observable<UserResponse>;
    protected updateUserPassword(body: InputChangePassword, user_id: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<UserResponse>>;
    protected updateUserPassword(body: InputChangePassword, user_id: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<UserResponse>>;
    protected updateUserPassword(body: InputChangePassword, user_id: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling updateUserPassword.');
        }

        if (user_id === null || user_id === undefined) {
            throw new Error('Required parameter user_id was null or undefined when calling updateUserPassword.');
        }

        let headers = this.defaultHeaders;

        // authentication (OAuth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<UserResponse>('put',`${this.basePath}/users/${encodeURIComponent(String(user_id))}/password`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update password of the user + hash the password
     * 
     * @param body 
     * @param user_id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    protected usersUserIdPasswordHashPut(body: InputSetPassword, user_id: number, observe?: 'body', reportProgress?: boolean): Observable<UserPasswordHashed>;
    protected usersUserIdPasswordHashPut(body: InputSetPassword, user_id: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<UserPasswordHashed>>;
    protected usersUserIdPasswordHashPut(body: InputSetPassword, user_id: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<UserPasswordHashed>>;
    protected usersUserIdPasswordHashPut(body: InputSetPassword, user_id: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling usersUserIdPasswordHashPut.');
        }

        if (user_id === null || user_id === undefined) {
            throw new Error('Required parameter user_id was null or undefined when calling usersUserIdPasswordHashPut.');
        }

        let headers = this.defaultHeaders;

        // authentication (OAuth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<UserPasswordHashed>('put',`${this.basePath}/users/${encodeURIComponent(String(user_id))}/password_hash`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
