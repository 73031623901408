import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { marker as i18n } from '@biesbjerg/ngx-translate-extract-marker';

import {
  distinctUntilRealChanged,
  keepSourceIfNoError,
  replay,
  waitForNotNilProperties,
  waitForNotNilValue,
} from '@bg2app/tools/rxjs';
import { BehaviorSubject, catchError, combineLatest, concat, map, Observable, of, Subject, switchMap, tap } from 'rxjs';

import { ConsoleLoggerService } from 'app/core/console-logger.service';

import { Apiary, Entity, Event, Hive, Location } from 'app/models';
import { EventPaging, OneOfEntity } from 'app/core/api/main/beeguard2-api-service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'bg2-entity-events-history',
  templateUrl: './entity-events-history.component.html',
  styleUrls: ['./entity-events-history.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EntityEventsHistoryComponent {
  // #region -> (component basics)

  /** */
  private readonly _logger = new ConsoleLoggerService('EventsHistoryComponent', false);

  /** */
  constructor(private readonly _translate: TranslateService) {}

  /** */
  public track_by_event(index: number, event: Event) {
    return event.id;
  }

  // #endregion

  // #region -> (binded entity)

  private _entity$$ = new BehaviorSubject<OneOfEntity>(null);
  public entity$$ = this._entity$$.asObservable().pipe(waitForNotNilValue());

  @Input()
  public set entity(entity: OneOfEntity) {
    this._entity$$.next(entity);
  }

  /**
   * @description
   *
   * Observes the entity type.
   */
  public entity_type$$ = this.entity$$.pipe(
    switchMap(entity => {
      if (entity instanceof Apiary) {
        return of('location');
      }

      return entity.type$$;
    })
  );

  /**
   * @description
   *
   * Observes the entity id.
   */
  public entity_id$$ = this.entity$$.pipe(
    switchMap(entity => {
      if (entity instanceof Apiary) {
        return entity.location_id$$;
      }

      return entity.id$$;
    })
  );

  /**
   * @description
   *
   * Observes the binded entity's exploitation.
   */
  public exploitation$$ = this.entity$$.pipe(
    switchMap(entity => {
      if (entity instanceof Location) {
        return entity.exploitation$$;
      } else if (entity instanceof Apiary) {
        return entity.exploitation$$;
      } else if (entity instanceof Hive) {
        return entity.apiary$$.pipe(
          waitForNotNilValue(),
          switchMap(apiary => apiary.exploitation$$)
        );
      }
    }),
    waitForNotNilValue(),
    replay()
  );

  // #endregion

  // #region -> (events management)

  /** */
  private _page$$ = new BehaviorSubject<number>(0);

  /** */
  public page$$ = this._page$$.pipe(distinctUntilRealChanged());

  /** */
  public set page(page: number) {
    this._page$$.next(page);
  }

  /** */
  public offset$$ = combineLatest({ limit: of(10), page: this.page$$ }).pipe(
    waitForNotNilProperties(),
    map(({ limit, page }) => limit * page),
    distinctUntilRealChanged(),
    replay()
  );

  /** */
  private events_pagination$$: Observable<EventPaging> = this.entity$$.pipe(
    waitForNotNilValue(),
    switchMap(entity => this.offset$$.pipe(map(offset => ({ entity, offset })))),
    tap(() => this._is_loading_events$$.next(true)),
    switchMap(({ entity, offset }) => entity.stream_events$$(offset, 10)),
    replay()
  );

  /** */
  public events$$: Observable<Event[]> = this.events_pagination$$.pipe(
    map(events_paging => events_paging?.events ?? []),
    tap(() => this._is_loading_events$$.next(false)),
    replay()
  );

  /** */
  public total$$ = this.events_pagination$$.pipe(
    map(event_paging => event_paging?.paging?.total),
    replay()
  );

  /** */
  public has_pagination$$ = this.total$$.pipe(
    map(total => 10 < total),
    replay()
  );

  // #endregion

  // #region -> (error management)

  /** */
  public error$$ = this.entity$$.pipe(
    keepSourceIfNoError(entity =>
      entity.user_acl.check_read_all_events$$({
        what: i18n<string>("ALL.ACE.READ_ALL_EVENTS.WHAT.see the entity's events"),
      })
    ),
    map(error_or_entity => {
      if (error_or_entity instanceof Error) {
        return error_or_entity;
      }

      return null;
    }),
    replay()
  );

  // #endregion

  // #region -> (loadings)

  private _is_loading_events$$: Subject<boolean> = new Subject<boolean>();
  private is_loading_events$$: Observable<boolean> = this._is_loading_events$$.asObservable();

  public loadings = {
    events$$: concat(of(true), this.is_loading_events$$),
  };

  // #endregion
}
