import { ActivatedRoute } from '@angular/router';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';

import { marker as i18n } from '@biesbjerg/ngx-translate-extract-marker';

import { isNil } from 'lodash-es';

import {
  Observable,
  Subscription,
  map,
  switchMap,
  BehaviorSubject,
  concat,
  of,
  tap,
  Subject,
  take,
  combineLatest,
  pairwise,
  startWith,
} from 'rxjs';
import {
  replay,
  waitForNotNilValue,
  distinctUntilRealChanged,
  create_replay_subject_with_first_value,
} from '@bg2app/tools/rxjs';

import { Beeguard2Api } from 'app/core';
import { PageTitleService } from 'app/core/page-title.service';
import { ConsoleLoggerService } from 'app/core/console-logger.service';

import { Location } from 'app/models';

@Component({
  selector: 'bg2-location-details',
  templateUrl: './location-details.component.html',
  styleUrls: ['./location-details.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LocationDetailsComponent implements OnInit, OnDestroy {
  @Input()
  public display_type: 'compact' | 'classic' | 'modal' = 'classic';

  @Output()
  public when_quit = new EventEmitter<null>();

  // #region -> (component basics)

  /** */
  private readonly _logger = new ConsoleLoggerService('LocationDetailsComponent', true);

  /** */
  private _page_title_sub: Subscription = null;

  /** */
  constructor(private _title: PageTitleService, private _activatedRoute: ActivatedRoute, private _bg2Api: Beeguard2Api) {}

  /** */
  ngOnInit(): void {
    this._title.setKey(i18n<string>('VIEWS.PAGE.LOCATION_DETAIL.PAGE_TITLE'));

    const location_id = (this._activatedRoute?.snapshot?.params?.id ?? null) as number;
    if (!isNil(location_id)) {
      this.location_id = location_id;
    }

    this._page_title_sub = this.location_name$$.subscribe({
      next: name => this._title.setKey(i18n<string>('VIEWS.PAGE.LOCATION_DETAIL.PAGE_TITLE [name]'), { name }),
    });
  }

  /** */
  ngOnDestroy(): void {
    this._page_title_sub?.unsubscribe();
  }

  // #endregion

  // #region -> (location entity)

  /** */
  @Input()
  public set location_id(location_id: number) {
    this._location_id$$.next(location_id);
  }

  /** */
  private _location_id$$: BehaviorSubject<number> = new BehaviorSubject<number>(null);

  /** */
  public location_id$$: Observable<number> = this._location_id$$.asObservable().pipe(waitForNotNilValue(), distinctUntilRealChanged());

  /**
   * Observes the location entity.
   */
  public location$$: Observable<Location> = this.location_id$$.pipe(
    switchMap(location_id => concat(of<Location>(null), this._bg2Api.getEntityObj(location_id))),
    waitForNotNilValue(),
    map(entity => {
      if (!(entity instanceof Location)) {
        throw Error(i18n<string>('ALL.ERROR.HTTP.MESSAGE.You are trying to reach information that is other than a location'));
      }

      return entity as Location;
    }),
    replay()
  );

  /**
   * Observes the location's name.
   */
  public location_name$$ = this.location$$.pipe(switchMap(location => location.name$$));

  // #region -> (exploitation entity)

  /**
   * Observes the exploitation itself.
   */
  private exploitation$$ = this.location$$.pipe(
    waitForNotNilValue(),
    switchMap(location => location.exploitation$$)
  );

  /**
   * Observes the exploitation's name.
   */
  public exploitation_name$$ = this.exploitation$$.pipe(switchMap(exploitation => exploitation.name$$));

  /**
   * Observes the exploitation's ID.
   */
  public exploitation_id$$ = this.exploitation$$.pipe(switchMap(exploitation => exploitation.id$$));

  // #endregion

  // #region -> (breadcrumb navigation)

  /** */
  public breadcrumb_data$$ = combineLatest({
    location_name: concat(of(null), this.location_name$$),
    exploitation_name: concat(of(null), this.exploitation_name$$),
  });

  // #endregion

  // #region -> (loadings management)

  /** */
  public is_loading_apiary$$ = create_replay_subject_with_first_value(true);

  // #endregion
}
