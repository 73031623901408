import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { Observable } from 'rxjs';

import { AppStateService } from '../app-state.service';

@Injectable({
  providedIn: 'root',
})
export class BeeguardAdminGuard implements CanActivate {
  constructor(private readonly _appState: AppStateService) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return this._appState.is_superadmin$$;
  }
}
