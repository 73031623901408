<div class="widget form-group bg2superbox-repartition-widget">
  <form-element-header [schema]="schema" [showLabel]="false"></form-element-header>

  <div *ngIf="(is_loading$$ | async) === false" class="superbox-repartition-widget" [class.error]="error$$ | async">
    <div *ngIf="is_updating$$ | async" class="loading">
      <hex-loading [size]="'small'" [message]="'ALL.COMMON.Loading' | translate"></hex-loading>
    </div>

    <ng-container *ngIf="(is_updating$$ | async) === false">
      <div class="base-informations">
        <!-- Select the number of harvested supers -->
        <mat-form-field [appearance]="'outline'">
          <mat-label *ngIf="options.add_supers">
            {{ 'EVENT.SUPERBOX.Number of added supers (on [nbh] hives)' | translate:{nbh: nbh_actual} }}
          </mat-label>

          <mat-label *ngIf="!options.add_supers">
            {{ 'EVENT.SUPERBOX.Number of harvested supers' | translate:{nbh: nbh_actual} }}
          </mat-label>

          <input
            matInput
            type="number"
            [step]="1"
            [(ngModel)]="nbs_adiff"
            [attr.min]="options.add_supers ? (-nbs_initial) : 0"
            [placeholder]="(schema_nbs_diff$$ | async)?.placeholder | translate"
          />
        </mat-form-field>
      </div>

      <p class="rep-summary">
        <b>{{ 'EVENT.SUPERBOX.After this intervention we will have :' | translate }}&nbsp;</b>
        <span>
          <ng-container *ngIf="nbs_final === 0">{{ 'EVENT.SUPERBOX.No supers on this apiary\'s hives' | translate }}</ng-container>
          <ng-container *ngIf="nbs_final === 1">{{ 'EVENT.SUPERBOX.One super setted up' | translate }}</ng-container>
          <ng-container *ngIf="nbs_final > 1">
            {{ 'EVENT.SUPERBOX.[nb_supers] supers setted up' | translate:{ nb_supers: nbs_final } }}
          </ng-container>
          <span *ngIf="nbs_diff !== 0" class="diff"><i *ngIf="nbs_diff > 0">+</i>{{ nbs_diff }}</span>
        </span>
        <span *ngIf="nbh_actual && nbh_actual > 0 && nbs_final > 0">
          <ng-container *ngIf="nbh_actual === 1"> {{ 'EVENT.SUPERBOX.on one hive' | translate:{ nbh_actual: nbh_actual } }}</ng-container>
          <ng-container *ngIf="nbh_actual > 1">
            {{ 'EVENT.SUPERBOX.on [nbh_actual] hives' | translate:{ nbh_actual: nbh_actual } }}
          </ng-container>
          <span *ngIf="nbh && nbh_diff" class="diff"><i *ngIf="nbh_diff > 0">+</i>{{ nbh_diff }}</span>
        </span>
      </p>

      <!-- <div class="hives-superbox-repartition no-rep" *ngIf="((is_rep_is_defined$$ | async) === false) && (options.add_supers || nbs_final !==0)">
        <span class="no-rep" *ngIf="options.add_supers">{{ 'EVENT.SUPERBOX.The distribution of supers on the hives is not defined, you can specify it:' | translate }}</span>
        <span class="no-rep" *ngIf="!options.add_supers">{{ 'EVENT.SUPERBOX.The distribution of the remaining supers on the hives is not defined, you can specify it:' | translate }}</span>
      </div>

      <div class="hives-superbox-repartition" *ngIf="is_rep_is_defined$$ | async">
        <ng-container *ngFor="let idx of [0, 1, 2, 3, 4]">
          <div class="supers-repartition-status" *ngIf="repartition[idx] || (repartition_initial$$ | async)?.[idx]">
            <superbox-display [nb_box]="idx"></superbox-display>
            <div class="desc" *ngIf="!__isNil(repartition[idx])">
              <span class="desc-nb-hives">
                <span class="val">{{ repartition[idx] }} </span>
                <span *ngIf="repartition[idx] > 1" translate>ENTITY.ALL.TYPE.hives</span>
                <span *ngIf="repartition[idx] < 2" translate>ENTITY.ALL.TYPE.hive</span>
                <span *ngIf="(repartition_initial$$ | async) && (repartition_initial$$ | async)?.[idx] - repartition[idx]" class="diff">
                  <i
                    *ngIf="repartition[idx] - (repartition_initial$$ | async)?.[idx] > 0">+</i>{{ repartition[idx] - (repartition_initial$$ | async)?.[idx] }}
                </span>
              </span> <br />
              <span class="desc-nb-super">
                <span *ngIf="idx === 0"> {{'EVENT.SUPERBOX.without super' | translate}}</span>
                <span *ngIf="idx === 1"> {{'EVENT.SUPERBOX.with 1 super' | translate }}</span>
                <span *ngIf="idx > 1"> {{'EVENT.SUPERBOX.with [nb] supers' | translate:{nb: idx} }}</span>
              </span>
            </div>
          </div>
          <div *ngIf="idx === 2 && repartition[0] > 0 && repartition[1] > 0" class="linebreak-small-and-down"></div>
        </ng-container>
      </div> -->

      <!-- Errors row -->
      <div class="errors-displayer" *ngIf="error$$ | async; let error">
        <p class="error">{{ error | translate:{ nbh_adiff:nbh_adiff$$ | async, nbh_actual:nbh_actual, nbh:nbh } }}</p>

        <div>
          <bg2-button
            [type]="'delete'"
            [icon]="nbh_diff < 0 ? 'mdi-arrow-down-bold-hexagon-outline' : 'mdi-arrow-up-bold-hexagon-outline'"
            [message]="'EVENT.SUPERBOX.change total nb_hive' | translate"
            (btn_click)="changeTotalHiveNumber()"
          >
          </bg2-button>

          <bg2-button
            *ngIf="nbh_added > 0"
            [type]="'delete'"
            [icon]="'mdi-layers-plus'"
            [message]="'EVENT.SUPERBOX.add [diff] with zero' | translate:{ diff:nbh_added }"
            (btn_click)="autoComplete()"
          >
          </bg2-button>

          <bg2-button
            [type]="'delete'"
            [icon]="'mdi-undo'"
            [message]="'EVENT.SUPERBOX.Reset to initial state' | translate"
            (btn_click)="resetOldOrInitial()"
          >
          </bg2-button>
        </div>
      </div>

      <!-- Row actions -->
      <!-- <div class="actions-row" >
        <button *ngIf="(is_rep_is_defined$$ | async) || options.add_supers || nbs_final !== 0" mat-flat-button color="breeding-register" (click)="startManualEdition()">
          <span class="button-principal">
            <span class="mdi mdi-pencil mdi-24px"></span>
            <span>{{"EVENT.SUPERBOX.Manually change supers distribution" | translate}}</span>
          </span>
        </button>

        <div *ngIf="(is_rep_is_defined$$ | async) || options.add_supers || nbs_final !== 0" >
          <button *ngIf="!visitHistory.length" mat-flat-button color="breeding-register"
            (click)="visitHistory.length ? startVisit() : continueVisit()">
            <span class="button-principal">
              <span class="mdi mdi-code-greater-than mdi-24px"></span>
              <span *ngIf="visitHistory.length">{{ "EVENT.SUPERBOX.Continue apiary visit" | translate }}</span>
              <span *ngIf="!visitHistory.length">{{"EVENT.SUPERBOX.Change distribution by visiting the apiary" | translate}}</span>
            </span>
          </button>

          <button mat-flat-button color="warn" (click)="razRepartition()" *ngIf="(is_rep_is_defined$$ | async)">
            <span class="button-principal">
              <span class="mdi mdi-close-circle mdi-24px"></span>
              <span>{{"EVENT.SUPERBOX.Do not specify distribution" | translate}}</span>
            </span>
          </button>
        </div>
      </div> -->

      <div class="nb-hives nb-stock">
        <mat-form-field [appearance]="'outline'">
          <mat-label>
            <ng-container *ngIf="nbs_stock <= 1" style="text-transform: capitalize">{{ 'ENTITY.ALL.TYPE.super' | translate }}</ng-container>
            <ng-container *ngIf="nbs_stock > 1" style="text-transform: capitalize">{{ 'ENTITY.ALL.TYPE.supers' | translate }}</ng-container>
            <ng-container> {{ 'EVENT.SUPERBOX.in stock on the apiary' | translate }}</ng-container>
            <span class="diff" *ngIf="(nbs_stock_initial$$ | async) - nbs_stock">
              <i *ngIf="nbs_stock - (nbs_stock_initial$$ | async) > 0">+</i>{{ nbs_stock - (nbs_stock_initial$$ | async) }}
            </span>
          </mat-label>
          <input matInput [(ngModel)]="nbs_stock" type="number" [attr.min]="0" [step]="1" />
        </mat-form-field>
      </div>
    </ng-container>
  </div>
</div>
