import { Component, ChangeDetectionStrategy } from '@angular/core';

import * as Sentry from '@sentry/angular-ivy';

import { assign as _assign, isNil } from 'lodash-es';

import { marker as i18n } from '@biesbjerg/ngx-translate-extract-marker';
import { TranslateService } from '@ngx-translate/core';

import { filter, map, switchMap, tap } from 'rxjs';
import { replay } from '@bg2app/tools/rxjs';

import { AbstractDialogComponent, AbstractDialogParams } from '../abstract-dialog.component';
import { HttpErrorResponse, HttpStatusCode } from '@angular/common/http';
import { Dictionary } from 'app/typings/core/interfaces';
import { get_status_code_i18n } from 'app/models/i18n/http_status_code';
import { ConsoleLoggerService } from 'app/core/console-logger.service';
import { ErrorHelperData } from 'app/widgets/widgets-reusables/errors/error-helper/error-helper.component';

/** */
export interface ErrorDialogParams extends AbstractDialogParams {
  /** */
  error: ErrorHelperData | HttpErrorResponse | Dictionary<any>;
}

@Component({
  selector: 'bg2-dialog-error',
  templateUrl: './error.dialog.html',
  styleUrls: ['./error.dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ErrorDialogComponent extends AbstractDialogComponent<ErrorDialogParams, any> {
  // #region -> (component basics)

  /** */
  private readonly LOGGER = new ConsoleLoggerService('ErrorDialogComponent', false);

  /** */
  constructor(private readonly _translate_service: TranslateService) {
    super();
  }

  public clicked(return_value: any): void {
    this.complete(return_value);
  }

  // #endregion

  // #region -> (error management)

  private logError(error: unknown) {
    Sentry.configureScope(scope => {
      scope.addAttachment({ filename: 'error.json', data: JSON.stringify(error, undefined, 4) });
    });

    if (error instanceof Error) {
      this.LOGGER.error(`[${error?.name}] ${error?.message}`);
    } else if (error instanceof HttpErrorResponse) {
      this.LOGGER.error(`[${error?.status}] ${error?.statusText}`);
    } else {
      this.LOGGER.error(error);
    }

    // Clear attachments
    Sentry.configureScope(scope => {
      scope.clearAttachments();
    });
  }

  /** */
  private _incoming_error = this.input_params$$.pipe(
    map(parameters => parameters.error),
    tap(error => this.logError(error)),
    replay()
  );

  protected is_bg2_error$$ = this._incoming_error.pipe(
    map(error => error instanceof ErrorHelperData),
    replay()
  )

  protected incoming_bg2_error$$ = this._incoming_error.pipe(
    filter(error => error instanceof ErrorHelperData),
    map(error => error as ErrorHelperData),
    replay()
  )

  /** */
  public error_origin$$ = this._incoming_error.pipe(
    map(error_value => {
      if (error_value instanceof HttpErrorResponse) {
        return error_value.status as HttpStatusCode;
      }

      return null;
    }),
    switchMap(http_code => {
      if (isNil(http_code)) {
        return this._translate_service.stream(i18n<string>('WIDGETS.DIALOGS_MODALS.DIALOGS.ERROR.From app'));
      }

      return this._translate_service.stream(get_status_code_i18n(http_code));
    }),
    replay()
  );

  /** */
  public error_message$$ = this._incoming_error.pipe(
    map(error_value => {
      if (error_value instanceof HttpErrorResponse) {
        return error_value.error?.message ?? error_value?.message;
      }

      return error_value?.message;
    }),
    replay()
  );

  // #endregion
}
