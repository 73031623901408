import { Observable } from 'rxjs';

import { assign, cloneDeep, isEqual, isNil } from 'lodash-es';

import { Beeguard2Api } from 'app/core';
import { parseDate } from 'app/misc/tools';
import { Event } from 'app/models/events';

import { BaseRunner } from '../../../runners';
import { RunnerType } from '../../../runners';

export enum RunSnapshotState {
  new = 'new',
  running = 'running',
  error = 'error',
  aborting = 'aborting',
  stopped = 'stopped',
  done = 'done',
}

export interface RunSnapshotInterface {
  id: string;
  snapshot_date: Date;
  state: RunSnapshotState;
  error: any;
  nb_total: number;
  nb_remains: number;
}

export interface RunSnapshotError {
  date: Date;
  description: string;
  type: string;
  source: {
    event_id: number;
    event_type: string;
    event_date: Date;
    error: any;
  };
}

export interface SummarizedRunSnapshot {
  number_of_runs: number;
  nb_total: number;
  nb_remains: number;
  errors: any[];
}

export class RunSnapshot extends BaseRunner implements RunSnapshotInterface {
  public bg2api: Beeguard2Api;

  public static deserializeError(run_error: RunSnapshotError): RunSnapshotError {
    if (isNil(run_error)) {
      return run_error;
    }

    const run_error_copy = cloneDeep(run_error);

    if (!isNil(run_error_copy.date)) {
      run_error_copy.date = parseDate(run_error_copy.date);
    }

    if (!isNil(run_error_copy.source) && !isNil(run_error_copy.source.event_date)) {
      run_error_copy.source.event_date = parseDate(run_error_copy.source.event_date);
    }

    return run_error_copy;
  }

  public static deserialize(run_interface: RunSnapshotInterface, bg2Api: Beeguard2Api): RunSnapshot {
    const run = new RunSnapshot();
    assign(run, run_interface);
    assign(run.error, RunSnapshot.deserializeError(run.error));

    if (run.snapshot_date) {
      run.snapshot_date = parseDate(run.snapshot_date);
    }

    run.setBg2Api(bg2Api);
    run.setup();
    return run;
  }

  private _id: string;
  public set id(id: string) {
    if (isEqual(this._id, id)) {
      return;
    }
    this._id = id;
  }
  public get id(): string {
    return this._id;
  }

  private _snapshot_date: Date;
  public set snapshot_date(snapshot_date: Date) {
    if (isEqual(this._snapshot_date, snapshot_date)) {
      return;
    }
    this._snapshot_date = snapshot_date;
  }
  public get snapshot_date(): Date {
    return this._snapshot_date;
  }

  private _state: RunSnapshotState;
  public set state(state: RunSnapshotState) {
    if (isEqual(this._state, state)) {
      return;
    }
    this._state = state;
  }
  public get state(): RunSnapshotState {
    return this._state;
  }



  public event$$: Observable<Event> = null;

  constructor() {
    super(0, 0);
    this.type = RunnerType.RUNNER_ENTITIES_COMPUTE;
  }

  public setBg2Api(bg2api: Beeguard2Api) {
    this.bg2api = bg2api;
  }

  private setup() {
    if (isNil(this.bg2api)) {
      return;
    }

    if (!isNil(this.error) && !isNil(this.error.source) && !isNil(this.error.source.event_id)) {
      this.event$$ = this.bg2api.getEventObj(this.error.source.event_id);
    }
  }
}
