<ng-container *ngIf="status$$ | async as status">
  <div class="gps-level-component">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlns:svg="http://www.w3.org/2000/svg"
      version="1.1"
      width="24"
      height="24"
      viewBox="0 0 6.3499999 6.3500002"
      [popperTimeout]="100"
      [popper]="tooltipCases"
      [queryParamsHandling]="'merge'"
      [queryParams]="{ inmodal_url: 'diagnostic' }"
      [routerLink]="open_in_modal ? ['', { outlets: { modal: ['device', { imei: device_imei$$ | async }] } }] : null"
      (click)="$event.stopPropagation()"
    >
      <path
        id="path_icon_gps"
        [style.fill]="(is_gps_ok$$ | async) ? 'green' : '#ff0000'"
        d="M 4.2283959,4.5423926 3.6507697,3.9657334 3.7882818,3.8282358 3.9257936,3.6907381 3.883624,3.6494255 C 3.86043,3.6267033 3.842555,3.604814 3.843902,3.6007827 3.845242,3.5967483 3.888106,3.5515987 3.939139,3.5004434 L 4.03193,3.4074338 4.076684,3.4514351 4.121437,3.4954374 4.2611444,3.3562082 4.4008548,3.216978 4.9785525,3.793652 5.55625,4.370326 5.1811361,4.7446895 4.8060224,5.1190525 Z M 2.5486568,3.7679164 2.1828336,3.4026402 3.289461,2.2997809 C 4.3171959,1.2755456 4.4008074,1.1945011 4.4622774,1.1629721 4.6717408,1.0555347 4.912154,1.0913291 5.075368,1.2542541 5.2302068,1.4088185 5.265689,1.6524151 5.1633159,1.8580473 5.1385342,1.9078248 5.0021187,2.0475185 4.0248201,3.023897 L 2.9144795,4.1331924 Z M 1.775282,2.093625 1.2231952,1.5424582 1.5982997,1.1681042 1.973404,0.79375005 2.5277332,1.3471562 3.082063,1.9005618 2.9425661,2.0400441 2.8030693,2.1795265 l 0.053324,0.053792 0.053324,0.053791 -0.097726,0.097222 -0.097728,0.097223 -0.053838,-0.053183 -0.053838,-0.053182 -0.1348002,0.1348014 c -0.074139,0.074141 -0.1369639,0.134802 -0.1396087,0.134802 -0.00264,0 -0.2532478,-0.2480254 -0.5568964,-0.5511679 z"
      />

      <path
        id="path_icon_waves"
        [style.fill]="(is_gps_ok$$ | async) ? 'green' : 'lightgray'"
        d="M 2.7710774,5.55625 C 2.6749628,5.5484861 2.4962309,5.5147108 2.3913553,5.4844915 1.7882723,5.3107172 1.2852266,4.8884112 1.0054603,4.3210356 0.87004013,4.046399 0.80235384,3.7877258 0.78814743,3.4905434 L 0.78237243,3.3696983 H 0.92295038 1.0635281 l 0.00639,0.1070697 c 0.040253,0.6736981 0.4231046,1.2686084 1.0197577,1.5845927 0.1241112,0.065729 0.2903038,0.1287683 0.4331697,0.1643081 0.1135859,0.028256 0.3145465,0.056077 0.4050673,0.056077 h 0.049235 V 5.422338 5.5629295 l -0.081962,-0.00164 C 2.8501099,5.5603817 2.7942586,5.5581228 2.7710754,5.5562431 Z M 2.8694312,4.7975821 C 2.5794255,4.7630789 2.392656,4.6974262 2.1721101,4.5524605 2.0663897,4.48297 1.8658031,4.2826165 1.7949695,4.1757575 1.6905116,4.0181735 1.6111432,3.8313766 1.5779279,3.6649408 1.5626238,3.5882539 1.5442094,3.4416933 1.5440717,3.3954731 l -7.08e-5,-0.025775 H 1.6832147 1.822428 l 0.00628,0.072639 C 1.853161,3.7248734 1.9541266,3.9578173 2.1374238,4.1545932 2.3295527,4.3608504 2.5814204,4.4844419 2.8717721,4.5149374 L 2.97715,4.5260044 v 0.1388643 0.1388642 l -0.039809,-0.00141 c -0.021895,-7.712e-4 -0.052456,-0.00291 -0.06791,-0.00474 z"
      />

      <path
        *ngIf="is_gps_ko$$ | async"
        id="path_icon_no_waves"
        style="fill: #ff0000"
        d="M 1.4542069,3.7965584 1.2703572,3.9804085 1.7208407,4.4308918 1.2703572,4.8811219 1.4542069,5.0652258 1.9046905,4.6147422 2.355175,5.0652258 2.5390246,4.8811219 2.0885415,4.4308918 2.5390246,3.9804084 2.355175,3.7965584 1.9046906,4.2470417 Z"
      />

      <!-- <path
            *ngIf="is_gps_ko$$ | async"
            id="gps_force_ko"
            style="stroke: none; fill: #ff0000; fill-opacity: 1"
            d="M 3.2959892,2.9030667 2.9125664,3.2864901 3.8520594,4.2259827 2.9125664,5.1649474 3.2959892,5.5489001 4.2354824,4.6094066 5.1749777,5.5489001 5.5583998,5.1649474 4.6189074,4.2259827 5.5583998,3.2864901 5.1749777,2.9030667 4.2354824,3.8425595 Z"
          /> -->
    </svg>
  </div>

  <popper-content #tooltipCases>
    <ng-container *ngIf="status?.value; else deviceKO">
      {{ 'DEVICE.ALL.STATUS.STATUS_GPS.GPS_OK' | translate }}
      <ng-container *ngIf="gps_accuracy$$ | async as gps_accuracy"> (+/- {{ gps_accuracy }} m) </ng-container>
    </ng-container>

    <ng-template #deviceKO>
      <span>{{ 'DEVICE.ALL.STATUS.STATUS_GPS.GPS_KO' | translate }}<br /></span>
      <doc-helper page="devices/no_gps_fix" section="">
        {{ 'DEVICE.ALL.ACTION.How to improve situation' | translate }}
      </doc-helper>
    </ng-template>
  </popper-content>
</ng-container>
