/**
 * BeeGuard - DeviceAPI
 * This document is the reference documentation of [BeeGuard](https://www.beeguard.fr) protected API to acces devices data.  # Rate Limit  to ensure a high quality of service for all API consumers, each unauthenticated consumer is limited to **100 requests per minutes**.  # Confidential document  Do not duplicate or distribute without written permission from BeeGuard.   # Other informations 
 *
 * OpenAPI spec version: 20180410r1
 * Contact: e.navarro@beeguard.fr
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { AckConfigurationHistoryReturn } from '../model/ackConfigurationHistoryReturn';
import { CreateDevicesPayload } from '../model/createDevicesPayload';
import { CreateDevicesResponse } from '../model/createDevicesResponse';
import { DeleteoOrUpdateConfigurationReturn } from '../model/deleteoOrUpdateConfigurationReturn';
import { DeviceAffectationHistoryReturn } from '../model/deviceAffectationHistoryReturn';
import { ErrorModel } from '../model/errorModel';
import { Fconf } from '../model/fconf';
import { GetBatInfoResponse } from '../model/getBatInfoResponse';
import { GetBulkOtaResponse } from '../model/getBulkOtaResponse';
import { GetConfigurationReturn } from '../model/getConfigurationReturn';
import { GetDeviceResponse } from '../model/getDeviceResponse';
import { GetDeviceSimplifiedConfigurationResponse } from '../model/getDeviceSimplifiedConfigurationResponse';
import { GetDeviceTimeseriesResponse } from '../model/getDeviceTimeseriesResponse';
import { GetDevicesResponse } from '../model/getDevicesResponse';
import { GetNewDevicesResponse } from '../model/getNewDevicesResponse';
import { GotNotifivationForReturn } from '../model/gotNotifivationForReturn';
import { ListCredentialsResponse } from '../model/listCredentialsResponse';
import { ListSupportResponse } from '../model/listSupportResponse';
import { ListTmpConfigurationReturn } from '../model/listTmpConfigurationReturn';
import { OrderData } from '../model/orderData';
import { OtaData } from '../model/otaData';
import { PostBatInfoResponse } from '../model/postBatInfoResponse';
import { PostBulkOrderResponse } from '../model/postBulkOrderResponse';
import { PostBulkOtaResponse } from '../model/postBulkOtaResponse';
import { PostNewBatCh } from '../model/postNewBatCh';
import { PostTmpConfiguration } from '../model/postTmpConfiguration';
import { PostTmpConfigurationReturn } from '../model/postTmpConfigurationReturn';
import { PutBatCh } from '../model/putBatCh';
import { PutBulkOwnerData } from '../model/putBulkOwnerData';
import { PutBulkOwnerResponse } from '../model/putBulkOwnerResponse';
import { PutTmpConfiguration } from '../model/putTmpConfiguration';
import { Sconf } from '../model/sconf';
import { SupportResponse } from '../model/supportResponse';
import { UpdateDeviceBody } from '../model/updateDeviceBody';
import { UpdateDeviceResponse } from '../model/updateDeviceResponse';
import { UpdateDeviceSupportIssue } from '../model/updateDeviceSupportIssue';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class DeviceService {

    protected basePath = 'https://transapi.beeguard.net/devices';
    protected defaultHeaders = new HttpHeaders();
    protected configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Indicate a battery change
     * Indicate when battery has been changed.
     * @param imei 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    protected addBatCh(imei: number, body?: PostNewBatCh, observe?: 'body', reportProgress?: boolean): Observable<PostBatInfoResponse>;
    protected addBatCh(imei: number, body?: PostNewBatCh, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PostBatInfoResponse>>;
    protected addBatCh(imei: number, body?: PostNewBatCh, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PostBatInfoResponse>>;
    protected addBatCh(imei: number, body?: PostNewBatCh, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (imei === null || imei === undefined) {
            throw new Error('Required parameter imei was null or undefined when calling addBatCh.');
        }


        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<PostBatInfoResponse>(`${this.basePath}/${encodeURIComponent(String(imei))}/bat`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Create a new support ticket.
     * @param imei 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    protected addSupport(imei: number, body?: UpdateDeviceSupportIssue, observe?: 'body', reportProgress?: boolean): Observable<SupportResponse>;
    protected addSupport(imei: number, body?: UpdateDeviceSupportIssue, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SupportResponse>>;
    protected addSupport(imei: number, body?: UpdateDeviceSupportIssue, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SupportResponse>>;
    protected addSupport(imei: number, body?: UpdateDeviceSupportIssue, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (imei === null || imei === undefined) {
            throw new Error('Required parameter imei was null or undefined when calling addSupport.');
        }


        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<SupportResponse>(`${this.basePath}/${encodeURIComponent(String(imei))}/supports`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Create some devices 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    protected createDevices(body?: CreateDevicesPayload, observe?: 'body', reportProgress?: boolean): Observable<CreateDevicesResponse>;
    protected createDevices(body?: CreateDevicesPayload, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CreateDevicesResponse>>;
    protected createDevices(body?: CreateDevicesPayload, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CreateDevicesResponse>>;
    protected createDevices(body?: CreateDevicesPayload, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<CreateDevicesResponse>(`${this.basePath}/`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Indicate a battery change
     * Indicate when battery has been changed.
     * @param imei 
     * @param time 
     * @param force 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    protected deleteBatCh(imei: number, time?: Date, force?: boolean, observe?: 'body', reportProgress?: boolean): Observable<GetBatInfoResponse>;
    protected deleteBatCh(imei: number, time?: Date, force?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GetBatInfoResponse>>;
    protected deleteBatCh(imei: number, time?: Date, force?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GetBatInfoResponse>>;
    protected deleteBatCh(imei: number, time?: Date, force?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (imei === null || imei === undefined) {
            throw new Error('Required parameter imei was null or undefined when calling deleteBatCh.');
        }



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (time !== undefined && time !== null) {
            queryParameters = queryParameters.set('time', <any>time.toISOString());
        }
        if (force !== undefined && force !== null) {
            queryParameters = queryParameters.set('force', <any>force);
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.delete<GetBatInfoResponse>(`${this.basePath}/${encodeURIComponent(String(imei))}/bat`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Delete a device. 
     * @param imei device uniq id (imei)
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    protected deleteDevice(imei: number, observe?: 'body', reportProgress?: boolean): Observable<UpdateDeviceResponse>;
    protected deleteDevice(imei: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<UpdateDeviceResponse>>;
    protected deleteDevice(imei: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<UpdateDeviceResponse>>;
    protected deleteDevice(imei: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (imei === null || imei === undefined) {
            throw new Error('Required parameter imei was null or undefined when calling deleteDevice.');
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.delete<UpdateDeviceResponse>(`${this.basePath}/${encodeURIComponent(String(imei))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Delete an exisiting support ticket.
     * @param imei 
     * @param support_id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    protected deleteSupport(imei: number, support_id: string, observe?: 'body', reportProgress?: boolean): Observable<SupportResponse>;
    protected deleteSupport(imei: number, support_id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SupportResponse>>;
    protected deleteSupport(imei: number, support_id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SupportResponse>>;
    protected deleteSupport(imei: number, support_id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (imei === null || imei === undefined) {
            throw new Error('Required parameter imei was null or undefined when calling deleteSupport.');
        }

        if (support_id === null || support_id === undefined) {
            throw new Error('Required parameter support_id was null or undefined when calling deleteSupport.');
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.delete<SupportResponse>(`${this.basePath}/${encodeURIComponent(String(imei))}/supports/${encodeURIComponent(String(support_id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Delete one temporary configurations
     * 
     * @param imei 
     * @param cid 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    protected deleteTmpConfiguration(imei: number, cid: string, observe?: 'body', reportProgress?: boolean): Observable<DeleteoOrUpdateConfigurationReturn>;
    protected deleteTmpConfiguration(imei: number, cid: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<DeleteoOrUpdateConfigurationReturn>>;
    protected deleteTmpConfiguration(imei: number, cid: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<DeleteoOrUpdateConfigurationReturn>>;
    protected deleteTmpConfiguration(imei: number, cid: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (imei === null || imei === undefined) {
            throw new Error('Required parameter imei was null or undefined when calling deleteTmpConfiguration.');
        }

        if (cid === null || cid === undefined) {
            throw new Error('Required parameter cid was null or undefined when calling deleteTmpConfiguration.');
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.delete<DeleteoOrUpdateConfigurationReturn>(`${this.basePath}/${encodeURIComponent(String(imei))}/configuration/tmp/${encodeURIComponent(String(cid))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get device baterry info
     * Get the last device battery information: size, estimated level, etc.
     * @param imei 
     * @param deleted 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    protected getBatCh(imei: number, deleted?: boolean, observe?: 'body', reportProgress?: boolean): Observable<GetBatInfoResponse>;
    protected getBatCh(imei: number, deleted?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GetBatInfoResponse>>;
    protected getBatCh(imei: number, deleted?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GetBatInfoResponse>>;
    protected getBatCh(imei: number, deleted?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (imei === null || imei === undefined) {
            throw new Error('Required parameter imei was null or undefined when calling getBatCh.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (deleted !== undefined && deleted !== null) {
            queryParameters = queryParameters.set('deleted', <any>deleted);
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<GetBatInfoResponse>(`${this.basePath}/${encodeURIComponent(String(imei))}/bat`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Get device full configuration. 
     * @param imei device uniq id (imei)
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    protected getConfiguration(imei: number, observe?: 'body', reportProgress?: boolean): Observable<Fconf>;
    protected getConfiguration(imei: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Fconf>>;
    protected getConfiguration(imei: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Fconf>>;
    protected getConfiguration(imei: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (imei === null || imei === undefined) {
            throw new Error('Required parameter imei was null or undefined when calling getConfiguration.');
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<Fconf>(`${this.basePath}/${encodeURIComponent(String(imei))}/configuration`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get acked configuration history
     * 
     * @param imei 
     * @param start 
     * @param end 
     * @param query Query to filter devices (stringified from json). Note: fields ending with &#39;_date&#39; will be parsed to date (isoformat).
     * @param offset Paging offset.
     * @param limit Max number of devices to return.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    protected getConfigurationAckHistory(imei: number, start?: Date, end?: Date, query?: string, offset?: number, limit?: number, observe?: 'body', reportProgress?: boolean): Observable<AckConfigurationHistoryReturn>;
    protected getConfigurationAckHistory(imei: number, start?: Date, end?: Date, query?: string, offset?: number, limit?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<AckConfigurationHistoryReturn>>;
    protected getConfigurationAckHistory(imei: number, start?: Date, end?: Date, query?: string, offset?: number, limit?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<AckConfigurationHistoryReturn>>;
    protected getConfigurationAckHistory(imei: number, start?: Date, end?: Date, query?: string, offset?: number, limit?: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (imei === null || imei === undefined) {
            throw new Error('Required parameter imei was null or undefined when calling getConfigurationAckHistory.');
        }






        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (start !== undefined && start !== null) {
            queryParameters = queryParameters.set('start', <any>start.toISOString());
        }
        if (end !== undefined && end !== null) {
            queryParameters = queryParameters.set('end', <any>end.toISOString());
        }
        if (query !== undefined && query !== null) {
            queryParameters = queryParameters.set('query', <any>query);
        }
        if (offset !== undefined && offset !== null) {
            queryParameters = queryParameters.set('offset', <any>offset);
        }
        if (limit !== undefined && limit !== null) {
            queryParameters = queryParameters.set('limit', <any>limit);
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<AckConfigurationHistoryReturn>(`${this.basePath}/${encodeURIComponent(String(imei))}/configuration/ack/history`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Gets a specific device. 
     * @param imei device uniq id (imei)
     * @param last_measurements Returns the last aggregated values for this measurements
     * @param last_measurements_detail Add every last values for this measurements
     * @param include Return additional embedded informations (&#39;*&#39; for all)
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    protected getDevice(imei: number, last_measurements?: Array<string>, last_measurements_detail?: Array<string>, include?: Array<string>, observe?: 'body', reportProgress?: boolean): Observable<GetDeviceResponse>;
    protected getDevice(imei: number, last_measurements?: Array<string>, last_measurements_detail?: Array<string>, include?: Array<string>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GetDeviceResponse>>;
    protected getDevice(imei: number, last_measurements?: Array<string>, last_measurements_detail?: Array<string>, include?: Array<string>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GetDeviceResponse>>;
    protected getDevice(imei: number, last_measurements?: Array<string>, last_measurements_detail?: Array<string>, include?: Array<string>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (imei === null || imei === undefined) {
            throw new Error('Required parameter imei was null or undefined when calling getDevice.');
        }




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (last_measurements) {
            queryParameters = queryParameters.set('last_measurements', last_measurements.join(COLLECTION_FORMATS['csv']));
        }
        if (last_measurements_detail) {
            queryParameters = queryParameters.set('last_measurements_detail', last_measurements_detail.join(COLLECTION_FORMATS['csv']));
        }
        if (include) {
            queryParameters = queryParameters.set('include', include.join(COLLECTION_FORMATS['csv']));
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<GetDeviceResponse>(`${this.basePath}/${encodeURIComponent(String(imei))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Clear cache of entities (deprecated prefer to use DELETE method)
     * @param imei 
     * @param end 
     * @param start 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    protected getDeviceAffectationHistory(imei: number, end?: Date, start?: Date, observe?: 'body', reportProgress?: boolean): Observable<DeviceAffectationHistoryReturn>;
    protected getDeviceAffectationHistory(imei: number, end?: Date, start?: Date, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<DeviceAffectationHistoryReturn>>;
    protected getDeviceAffectationHistory(imei: number, end?: Date, start?: Date, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<DeviceAffectationHistoryReturn>>;
    protected getDeviceAffectationHistory(imei: number, end?: Date, start?: Date, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (imei === null || imei === undefined) {
            throw new Error('Required parameter imei was null or undefined when calling getDeviceAffectationHistory.');
        }



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (end !== undefined && end !== null) {
            queryParameters = queryParameters.set('end', <any>end.toISOString());
        }
        if (start !== undefined && start !== null) {
            queryParameters = queryParameters.set('start', <any>start.toISOString());
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<DeviceAffectationHistoryReturn>(`${this.basePath}/${encodeURIComponent(String(imei))}/affectations`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Get timeseries data for a devices. 
     * @param imei Device imei
     * @param measurements Name of the data to retrieve
     * @param start Get data from this date
     * @param end Get data until this date
     * @param step Resolution step (1h, 2h, 1d, ...)
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    protected getDeviceTimeseries(imei: number, measurements?: Array<string>, start?: Date, end?: Date, step?: string, observe?: 'body', reportProgress?: boolean): Observable<GetDeviceTimeseriesResponse>;
    protected getDeviceTimeseries(imei: number, measurements?: Array<string>, start?: Date, end?: Date, step?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GetDeviceTimeseriesResponse>>;
    protected getDeviceTimeseries(imei: number, measurements?: Array<string>, start?: Date, end?: Date, step?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GetDeviceTimeseriesResponse>>;
    protected getDeviceTimeseries(imei: number, measurements?: Array<string>, start?: Date, end?: Date, step?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (imei === null || imei === undefined) {
            throw new Error('Required parameter imei was null or undefined when calling getDeviceTimeseries.');
        }





        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (measurements) {
            queryParameters = queryParameters.set('measurements', measurements.join(COLLECTION_FORMATS['csv']));
        }
        if (start !== undefined && start !== null) {
            queryParameters = queryParameters.set('start', <any>start.toISOString());
        }
        if (end !== undefined && end !== null) {
            queryParameters = queryParameters.set('end', <any>end.toISOString());
        }
        if (step !== undefined && step !== null) {
            queryParameters = queryParameters.set('step', <any>step);
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<GetDeviceTimeseriesResponse>(`${this.basePath}/${encodeURIComponent(String(imei))}/timeseries`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * List all devices a user has access to. 
     * @param device_imeis Limit listen devices to given device IMEI
     * @param query Raw query to filter returned devices
     * @param offset Pagination offset
     * @param limit Number of device by page
     * @param last_measurements Returns the last aggregated values for this measurements
     * @param last_measurements_detail Add every last values for this measurements
     * @param include Return additional embedded informations (&#39;*&#39; for all)
     * @param sort How to sort returned devices (by name by default)
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    protected getDevices(device_imeis?: Array<number>, query?: string, offset?: number, limit?: number, last_measurements?: Array<string>, last_measurements_detail?: Array<string>, include?: Array<string>, sort?: Array<string>, observe?: 'body', reportProgress?: boolean): Observable<GetDevicesResponse>;
    protected getDevices(device_imeis?: Array<number>, query?: string, offset?: number, limit?: number, last_measurements?: Array<string>, last_measurements_detail?: Array<string>, include?: Array<string>, sort?: Array<string>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GetDevicesResponse>>;
    protected getDevices(device_imeis?: Array<number>, query?: string, offset?: number, limit?: number, last_measurements?: Array<string>, last_measurements_detail?: Array<string>, include?: Array<string>, sort?: Array<string>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GetDevicesResponse>>;
    protected getDevices(device_imeis?: Array<number>, query?: string, offset?: number, limit?: number, last_measurements?: Array<string>, last_measurements_detail?: Array<string>, include?: Array<string>, sort?: Array<string>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {









        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (device_imeis) {
            queryParameters = queryParameters.set('device_imeis', device_imeis.join(COLLECTION_FORMATS['csv']));
        }
        if (query !== undefined && query !== null) {
            queryParameters = queryParameters.set('query', <any>query);
        }
        if (offset !== undefined && offset !== null) {
            queryParameters = queryParameters.set('offset', <any>offset);
        }
        if (limit !== undefined && limit !== null) {
            queryParameters = queryParameters.set('limit', <any>limit);
        }
        if (last_measurements) {
            queryParameters = queryParameters.set('last_measurements', last_measurements.join(COLLECTION_FORMATS['csv']));
        }
        if (last_measurements_detail) {
            queryParameters = queryParameters.set('last_measurements_detail', last_measurements_detail.join(COLLECTION_FORMATS['csv']));
        }
        if (include) {
            queryParameters = queryParameters.set('include', include.join(COLLECTION_FORMATS['csv']));
        }
        if (sort) {
            queryParameters = queryParameters.set('sort', sort.join(COLLECTION_FORMATS['csv']));
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<GetDevicesResponse>(`${this.basePath}/`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * List devices that has communicate recently but doesn&#39;t exist in DB 
     * @param last Look for devices that have communicate within this time range (2h by default)
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    protected getNewDevices(last?: string, observe?: 'body', reportProgress?: boolean): Observable<GetNewDevicesResponse>;
    protected getNewDevices(last?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GetNewDevicesResponse>>;
    protected getNewDevices(last?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GetNewDevicesResponse>>;
    protected getNewDevices(last?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (last !== undefined && last !== null) {
            queryParameters = queryParameters.set('last', <any>last);
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<GetNewDevicesResponse>(`${this.basePath}/new`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get bulk devices OTA information
     * 
     * @param dids 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    protected getOtaBulk(dids: Array<number>, observe?: 'body', reportProgress?: boolean): Observable<GetBulkOtaResponse>;
    protected getOtaBulk(dids: Array<number>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GetBulkOtaResponse>>;
    protected getOtaBulk(dids: Array<number>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GetBulkOtaResponse>>;
    protected getOtaBulk(dids: Array<number>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (dids === null || dids === undefined) {
            throw new Error('Required parameter dids was null or undefined when calling getOtaBulk.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (dids) {
            queryParameters = queryParameters.set('dids', dids.join(COLLECTION_FORMATS['csv']));
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<GetBulkOtaResponse>(`${this.basePath}/ota/`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Get device simplified configuration. 
     * @param imei device uniq id (imei)
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    protected getSimplifiedConfiguration(imei: number, observe?: 'body', reportProgress?: boolean): Observable<GetDeviceSimplifiedConfigurationResponse>;
    protected getSimplifiedConfiguration(imei: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GetDeviceSimplifiedConfigurationResponse>>;
    protected getSimplifiedConfiguration(imei: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GetDeviceSimplifiedConfigurationResponse>>;
    protected getSimplifiedConfiguration(imei: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (imei === null || imei === undefined) {
            throw new Error('Required parameter imei was null or undefined when calling getSimplifiedConfiguration.');
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<GetDeviceSimplifiedConfigurationResponse>(`${this.basePath}/${encodeURIComponent(String(imei))}/sconf`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Get all the detail of a given device support ticket.
     * @param imei 
     * @param support_id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    protected getSupport(imei: number, support_id: string, observe?: 'body', reportProgress?: boolean): Observable<SupportResponse>;
    protected getSupport(imei: number, support_id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SupportResponse>>;
    protected getSupport(imei: number, support_id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SupportResponse>>;
    protected getSupport(imei: number, support_id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (imei === null || imei === undefined) {
            throw new Error('Required parameter imei was null or undefined when calling getSupport.');
        }

        if (support_id === null || support_id === undefined) {
            throw new Error('Required parameter support_id was null or undefined when calling getSupport.');
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<SupportResponse>(`${this.basePath}/${encodeURIComponent(String(imei))}/supports/${encodeURIComponent(String(support_id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get one temporary configuration
     * 
     * @param imei 
     * @param cid 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    protected getTmpConfiguration(imei: number, cid: string, observe?: 'body', reportProgress?: boolean): Observable<GetConfigurationReturn>;
    protected getTmpConfiguration(imei: number, cid: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GetConfigurationReturn>>;
    protected getTmpConfiguration(imei: number, cid: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GetConfigurationReturn>>;
    protected getTmpConfiguration(imei: number, cid: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (imei === null || imei === undefined) {
            throw new Error('Required parameter imei was null or undefined when calling getTmpConfiguration.');
        }

        if (cid === null || cid === undefined) {
            throw new Error('Required parameter cid was null or undefined when calling getTmpConfiguration.');
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<GetConfigurationReturn>(`${this.basePath}/${encodeURIComponent(String(imei))}/configuration/tmp/${encodeURIComponent(String(cid))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * List devices user will be notify for
     * 
     * @param user_id 
     * @param offset Pagination offset
     * @param limit Number of device by page
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    protected gotNotificationFor(user_id: number, offset?: number, limit?: number, observe?: 'body', reportProgress?: boolean): Observable<GotNotifivationForReturn>;
    protected gotNotificationFor(user_id: number, offset?: number, limit?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GotNotifivationForReturn>>;
    protected gotNotificationFor(user_id: number, offset?: number, limit?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GotNotifivationForReturn>>;
    protected gotNotificationFor(user_id: number, offset?: number, limit?: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (user_id === null || user_id === undefined) {
            throw new Error('Required parameter user_id was null or undefined when calling gotNotificationFor.');
        }



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (offset !== undefined && offset !== null) {
            queryParameters = queryParameters.set('offset', <any>offset);
        }
        if (limit !== undefined && limit !== null) {
            queryParameters = queryParameters.set('limit', <any>limit);
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<GotNotifivationForReturn>(`${this.basePath}/got_notification_for/${encodeURIComponent(String(user_id))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * List device credentials
     * List device credentials.
     * @param imei 
     * @param query Json suery to filters the result.          This is based on mongoengine query system (see https://docs.mongoengine.org/guide/querying.html#query-operators).      For exemple:     - Get only actif supports: {\&quot;is_actif\&quot;: true}     - Get supports ended before a date: {\&quot;end_time__lte__date\&quot;: \&quot;2020-01-01\&quot;}     
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    protected listCredentials(imei: number, query?: string, observe?: 'body', reportProgress?: boolean): Observable<ListCredentialsResponse>;
    protected listCredentials(imei: number, query?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ListCredentialsResponse>>;
    protected listCredentials(imei: number, query?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ListCredentialsResponse>>;
    protected listCredentials(imei: number, query?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (imei === null || imei === undefined) {
            throw new Error('Required parameter imei was null or undefined when calling listCredentials.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (query !== undefined && query !== null) {
            queryParameters = queryParameters.set('query', <any>query);
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<ListCredentialsResponse>(`${this.basePath}/${encodeURIComponent(String(imei))}/credentials`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * List support tickets information
     * Get support tickets.
     * @param imei 
     * @param limit Maximum number of reccord in a page
     * @param offset Position of the first reccord
     * @param query Json suery to filters the result.          This is based on mongoengine query system (see https://docs.mongoengine.org/guide/querying.html#query-operators).      For exemple:     - Get only actif supports: {\&quot;is_actif\&quot;: true}     - Get supports ended before a date: {\&quot;end_time__lte__date\&quot;: \&quot;2020-01-01\&quot;}     
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    protected listSupports(imei: number, limit?: number, offset?: number, query?: string, observe?: 'body', reportProgress?: boolean): Observable<ListSupportResponse>;
    protected listSupports(imei: number, limit?: number, offset?: number, query?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ListSupportResponse>>;
    protected listSupports(imei: number, limit?: number, offset?: number, query?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ListSupportResponse>>;
    protected listSupports(imei: number, limit?: number, offset?: number, query?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (imei === null || imei === undefined) {
            throw new Error('Required parameter imei was null or undefined when calling listSupports.');
        }




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (limit !== undefined && limit !== null) {
            queryParameters = queryParameters.set('limit', <any>limit);
        }
        if (offset !== undefined && offset !== null) {
            queryParameters = queryParameters.set('offset', <any>offset);
        }
        if (query !== undefined && query !== null) {
            queryParameters = queryParameters.set('query', <any>query);
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<ListSupportResponse>(`${this.basePath}/${encodeURIComponent(String(imei))}/supports`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * List device temporary configurations
     * 
     * @param imei 
     * @param type Filter tmp conf of a given type
     * @param all If set, also return the past tmp conf
     * @param now If set, returns tmp configuration that are active right now
     * @param at_date returns tmp configuration that are active at the given date
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    protected listTmpConfigurations(imei: number, type?: string, all?: boolean, now?: boolean, at_date?: Date, observe?: 'body', reportProgress?: boolean): Observable<ListTmpConfigurationReturn>;
    protected listTmpConfigurations(imei: number, type?: string, all?: boolean, now?: boolean, at_date?: Date, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ListTmpConfigurationReturn>>;
    protected listTmpConfigurations(imei: number, type?: string, all?: boolean, now?: boolean, at_date?: Date, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ListTmpConfigurationReturn>>;
    protected listTmpConfigurations(imei: number, type?: string, all?: boolean, now?: boolean, at_date?: Date, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (imei === null || imei === undefined) {
            throw new Error('Required parameter imei was null or undefined when calling listTmpConfigurations.');
        }





        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (type !== undefined && type !== null) {
            queryParameters = queryParameters.set('type', <any>type);
        }
        if (all !== undefined && all !== null) {
            queryParameters = queryParameters.set('all', <any>all);
        }
        if (now !== undefined && now !== null) {
            queryParameters = queryParameters.set('now', <any>now);
        }
        if (at_date !== undefined && at_date !== null) {
            queryParameters = queryParameters.set('at_date', <any>at_date.toISOString());
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<ListTmpConfigurationReturn>(`${this.basePath}/${encodeURIComponent(String(imei))}/configuration/tmp`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param order_data 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    protected postBulkOrder(order_data: OrderData, observe?: 'body', reportProgress?: boolean): Observable<PostBulkOrderResponse>;
    protected postBulkOrder(order_data: OrderData, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PostBulkOrderResponse>>;
    protected postBulkOrder(order_data: OrderData, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PostBulkOrderResponse>>;
    protected postBulkOrder(order_data: OrderData, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (order_data === null || order_data === undefined) {
            throw new Error('Required parameter order_data was null or undefined when calling postBulkOrder.');
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<PostBulkOrderResponse>(`${this.basePath}/order/`,
            order_data,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param ota_data 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    protected postOtaBulk(ota_data: OtaData, observe?: 'body', reportProgress?: boolean): Observable<PostBulkOtaResponse>;
    protected postOtaBulk(ota_data: OtaData, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PostBulkOtaResponse>>;
    protected postOtaBulk(ota_data: OtaData, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PostBulkOtaResponse>>;
    protected postOtaBulk(ota_data: OtaData, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (ota_data === null || ota_data === undefined) {
            throw new Error('Required parameter ota_data was null or undefined when calling postOtaBulk.');
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<PostBulkOtaResponse>(`${this.basePath}/ota/`,
            ota_data,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Add a tmp configuration
     * 
     * @param imei 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    protected postTmpConfiguration(imei: number, body: PostTmpConfiguration, observe?: 'body', reportProgress?: boolean): Observable<PostTmpConfigurationReturn>;
    protected postTmpConfiguration(imei: number, body: PostTmpConfiguration, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PostTmpConfigurationReturn>>;
    protected postTmpConfiguration(imei: number, body: PostTmpConfiguration, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PostTmpConfigurationReturn>>;
    protected postTmpConfiguration(imei: number, body: PostTmpConfiguration, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (imei === null || imei === undefined) {
            throw new Error('Required parameter imei was null or undefined when calling postTmpConfiguration.');
        }

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling postTmpConfiguration.');
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<PostTmpConfigurationReturn>(`${this.basePath}/${encodeURIComponent(String(imei))}/configuration/tmp`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param put_bulk_owner_data 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    protected putBulkOwner(put_bulk_owner_data: PutBulkOwnerData, observe?: 'body', reportProgress?: boolean): Observable<PutBulkOwnerResponse>;
    protected putBulkOwner(put_bulk_owner_data: PutBulkOwnerData, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PutBulkOwnerResponse>>;
    protected putBulkOwner(put_bulk_owner_data: PutBulkOwnerData, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PutBulkOwnerResponse>>;
    protected putBulkOwner(put_bulk_owner_data: PutBulkOwnerData, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (put_bulk_owner_data === null || put_bulk_owner_data === undefined) {
            throw new Error('Required parameter put_bulk_owner_data was null or undefined when calling putBulkOwner.');
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<PutBulkOwnerResponse>(`${this.basePath}/owner/`,
            put_bulk_owner_data,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Set device configuration. 
     * @param imei device uniq id (imei)
     * @param configuration the new device configuration
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    protected setConfiguration(imei: number, configuration: Fconf, observe?: 'body', reportProgress?: boolean): Observable<any>;
    protected setConfiguration(imei: number, configuration: Fconf, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    protected setConfiguration(imei: number, configuration: Fconf, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    protected setConfiguration(imei: number, configuration: Fconf, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (imei === null || imei === undefined) {
            throw new Error('Required parameter imei was null or undefined when calling setConfiguration.');
        }

        if (configuration === null || configuration === undefined) {
            throw new Error('Required parameter configuration was null or undefined when calling setConfiguration.');
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<any>(`${this.basePath}/${encodeURIComponent(String(imei))}/configuration`,
            configuration,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Set device simplified configuration. 
     * @param imei device uniq id (imei)
     * @param sconf the new device simplified configuration
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    protected setSimplifiedConfiguration(imei: number, sconf: Sconf, observe?: 'body', reportProgress?: boolean): Observable<any>;
    protected setSimplifiedConfiguration(imei: number, sconf: Sconf, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    protected setSimplifiedConfiguration(imei: number, sconf: Sconf, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    protected setSimplifiedConfiguration(imei: number, sconf: Sconf, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (imei === null || imei === undefined) {
            throw new Error('Required parameter imei was null or undefined when calling setSimplifiedConfiguration.');
        }

        if (sconf === null || sconf === undefined) {
            throw new Error('Required parameter sconf was null or undefined when calling setSimplifiedConfiguration.');
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<any>(`${this.basePath}/${encodeURIComponent(String(imei))}/sconf`,
            sconf,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update a battery change
     * Update a battery change information.                  Note: battery changement time could not be modify, you should delete it and create an other one.         
     * @param imei 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    protected updateBatCh(imei: number, body: PutBatCh, observe?: 'body', reportProgress?: boolean): Observable<PostBatInfoResponse>;
    protected updateBatCh(imei: number, body: PutBatCh, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PostBatInfoResponse>>;
    protected updateBatCh(imei: number, body: PutBatCh, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PostBatInfoResponse>>;
    protected updateBatCh(imei: number, body: PutBatCh, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (imei === null || imei === undefined) {
            throw new Error('Required parameter imei was null or undefined when calling updateBatCh.');
        }

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling updateBatCh.');
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<PostBatInfoResponse>(`${this.basePath}/${encodeURIComponent(String(imei))}/bat`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Update a specific device. 
     * @param imei device uniq id (imei)
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    protected updateDevice(imei: number, body?: UpdateDeviceBody, observe?: 'body', reportProgress?: boolean): Observable<UpdateDeviceResponse>;
    protected updateDevice(imei: number, body?: UpdateDeviceBody, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<UpdateDeviceResponse>>;
    protected updateDevice(imei: number, body?: UpdateDeviceBody, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<UpdateDeviceResponse>>;
    protected updateDevice(imei: number, body?: UpdateDeviceBody, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (imei === null || imei === undefined) {
            throw new Error('Required parameter imei was null or undefined when calling updateDevice.');
        }


        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<UpdateDeviceResponse>(`${this.basePath}/${encodeURIComponent(String(imei))}`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Update an exisiting support ticket.
     * @param imei 
     * @param support_id 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    protected updateSupport(imei: number, support_id: string, body?: UpdateDeviceSupportIssue, observe?: 'body', reportProgress?: boolean): Observable<SupportResponse>;
    protected updateSupport(imei: number, support_id: string, body?: UpdateDeviceSupportIssue, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SupportResponse>>;
    protected updateSupport(imei: number, support_id: string, body?: UpdateDeviceSupportIssue, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SupportResponse>>;
    protected updateSupport(imei: number, support_id: string, body?: UpdateDeviceSupportIssue, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (imei === null || imei === undefined) {
            throw new Error('Required parameter imei was null or undefined when calling updateSupport.');
        }

        if (support_id === null || support_id === undefined) {
            throw new Error('Required parameter support_id was null or undefined when calling updateSupport.');
        }


        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<SupportResponse>(`${this.basePath}/${encodeURIComponent(String(imei))}/supports/${encodeURIComponent(String(support_id))}`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update one temporary configurations
     * 
     * @param imei 
     * @param cid 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    protected updateTmpConfiguration(imei: number, cid: string, body: PutTmpConfiguration, observe?: 'body', reportProgress?: boolean): Observable<DeleteoOrUpdateConfigurationReturn>;
    protected updateTmpConfiguration(imei: number, cid: string, body: PutTmpConfiguration, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<DeleteoOrUpdateConfigurationReturn>>;
    protected updateTmpConfiguration(imei: number, cid: string, body: PutTmpConfiguration, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<DeleteoOrUpdateConfigurationReturn>>;
    protected updateTmpConfiguration(imei: number, cid: string, body: PutTmpConfiguration, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (imei === null || imei === undefined) {
            throw new Error('Required parameter imei was null or undefined when calling updateTmpConfiguration.');
        }

        if (cid === null || cid === undefined) {
            throw new Error('Required parameter cid was null or undefined when calling updateTmpConfiguration.');
        }

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling updateTmpConfiguration.');
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<DeleteoOrUpdateConfigurationReturn>(`${this.basePath}/${encodeURIComponent(String(imei))}/configuration/tmp/${encodeURIComponent(String(cid))}`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
