import {
  Input,
  NgZone,
  OnInit,
  Injector,
  OnDestroy,
  Component,
  ComponentRef,
  HostListener,
  ChangeDetectionStrategy,
  ComponentFactoryResolver,
  Output,
  EventEmitter,
} from '@angular/core';

import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';

import { isNil, isEmpty, cloneDeep, flatten, max } from 'lodash-es';

import { filter, tap, map, take, debounceTime, timer } from 'rxjs';
import { distinctUntilRealChanged, replay } from '@bg2app/tools/rxjs';
import { BehaviorSubject, Observable, combineLatest, Subscription, ReplaySubject } from 'rxjs';

import { TranslateService } from '@ngx-translate/core';

import * as L from 'leaflet';
import 'leaflet.markercluster';
import 'leaflet-polylinedecorator';
import 'leaflet.markercluster.freezable';

L.Marker.prototype.options.icon = L.icon({
  iconRetinaUrl: 'assets/img/views-windowed/modals/route-tracer/marker-icon-2x.png',
  iconUrl: 'assets/img/views-windowed/modals/route-tracer/marker-icon.png',
  shadowUrl: 'assets/img/views-windowed/modals/route-tracer/marker-shadow.png',
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  tooltipAnchor: [16, -28],
  shadowSize: [41, 41],
});

import { ConsoleLoggerService } from 'app/core/console-logger.service';
import { GoogleMapsLoader } from 'app/misc/services/google-maps-loader.service';

import { unifyPolyCircles } from 'app/misc/tools/geomap';
import { RoutePositionMarker } from 'app/typings/mapping/models/marker/RoutePositionMarker.model';
import { AbstractMarker, MapMarkerType } from 'app/typings/mapping';

import { Stack } from 'app/typings/core/Stack';
import { Gradient } from 'app/misc/tools/colors.helpers';
import { Bg2MapPopupMovementMarkerComponent } from 'app/views/map/shared/html-map-popups/map-popup-movement-marker/map-popup-movement-marker.component';
import { Bg2MapPopupMovementLineComponent } from 'app/views/map/shared/html-map-popups/map-popup-movement-line/map-popup-movement-line.component';
import { DeviceMovement, PositionHelper } from '../../route-tracer.modal';

@AutoUnsubscribe()
@Component({
  selector: 'bg2-route-tracer-map',
  templateUrl: './device-movements-map.component.html',
  styleUrls: ['./device-movements-map.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class DeviceMovementsMapComponent implements OnInit, OnDestroy {
  private _colorGradient = new Gradient();
  private _logger = new ConsoleLoggerService('DeviceMovementsMapComponent', false);

  @Input()
  public set device_movements(device_movements: DeviceMovement[]) {
    this._device_movements$$.next(device_movements);
  }

  private _device_movements$$ = new BehaviorSubject<DeviceMovement[]>([]);
  public device_movements$$ = this._device_movements$$.asObservable().pipe(
    tap(movements => {
      const length = movements?.length || 0;

      // Generates a new color palette
      this._colorGradient.setGradientSteps('#48feff', '#f904ff');
      this._colorGradient.setMidpoint(max([2, length]));
      this._schemed_colors = this._colorGradient.getArray();
    }),
    replay()
  );

  // public routes$$;

  // #region -> (component basics)

  private _schemed_colors: string[] = [];

  private _leaflet_marker_popups: {
    route_position_popup: Stack<{
      popup: L.Popup;
      cmp: ComponentRef<Bg2MapPopupMovementMarkerComponent>;
    }>;
    movement_line_popup: Stack<{
      popup: L.Popup;
      cmp: ComponentRef<Bg2MapPopupMovementLineComponent>;
    }>;
  } = {
    route_position_popup: new Stack(),
    movement_line_popup: new Stack(),
  };

  private _clustering_sub: Subscription = null;

  constructor(
    private _ngZone: NgZone,
    private _injector: Injector,
    private _translate: TranslateService,
    private _resolver: ComponentFactoryResolver
  ) {}

  ngOnInit(): void {
    GoogleMapsLoader.load().catch((reason: any) => this._logger.error(reason));

    this._clustering_sub = combineLatest([this.clusters$$, this.is_clustering_enabled$$])
      .pipe(
        filter(([clusters]) => !isEmpty(clusters || [])),
        debounceTime(100)
      )
      .subscribe({
        next: ([clusters, is_clustering_enabled]) => {
          this._logger.debug(cloneDeep({ is_clustering_enabled, clusters }));
          if (is_clustering_enabled) {
            clusters.forEach(cluster => (cluster as any).enableClustering());
          } else {
            clusters.forEach(cluster => (cluster as any).disableClustering());
          }
        },
      });
  }

  ngOnDestroy(): void {
    // Clears route positions marker popups
    while (this._leaflet_marker_popups.route_position_popup.size() > 0) {
      const popup = this._leaflet_marker_popups.route_position_popup.pop();
      popup.cmp.destroy();
    }

    while (this._leaflet_marker_popups.movement_line_popup.size() > 0) {
      const popup = this._leaflet_marker_popups.movement_line_popup.pop();
      popup.cmp.destroy();
    }

    this._clustering_sub?.unsubscribe();
    this._fast_reload_timer_sub?.unsubscribe();
  }

  // #endregion

  // #region -> (fast reload management)

  /** */
  private _fast_reload_timer_sub: Subscription = null;

  /** */
  @Output()
  public reload = new EventEmitter<any>();

  /** */
  private _is_fast_reload_enabled$$ = new BehaviorSubject(false);

  /** */
  public is_fast_reload_enabled$$ = this._is_fast_reload_enabled$$.asObservable();

  /** */
  public set is_fast_reload_enabled(is_fast_reload_enabled: boolean) {
    this._is_fast_reload_enabled$$.next(is_fast_reload_enabled);

    this._fast_reload_timer_sub?.unsubscribe();
    if (is_fast_reload_enabled) {
      this._fast_reload_timer_sub = timer(0, 1000 * 30).subscribe({
        next: () => this.reload.next(true),
      });
    }
  }

  /** */
  public get is_fast_reload_enabled(): boolean {
    return this._is_fast_reload_enabled$$.getValue();
  }

  // #endregion

  private _positions_by_route$$: Observable<PositionHelper[][]> = this.device_movements$$.pipe(
    map(device_movements => {
      if (isEmpty(device_movements || [])) {
        return null;
      }

      return device_movements.map((movement, movement_index) => {
        if (isEmpty(movement?.positions || [])) {
          return;
        }

        const movement_color = this._schemed_colors[movement_index];

        return movement?.positions
          .filter(position_point => {
            const latitude = position_point.gps_lat || position_point.cell_ids_lat || null;
            const longitude = position_point.gps_lng || position_point.cell_ids_lng || null;

            return !isNil(latitude) && !isNil(longitude);
          })
          .map(position_point => {
            // Prepare some variables for each routes
            const latitude = position_point.gps_lat || position_point.cell_ids_lat || null;
            const longitude = position_point.gps_lng || position_point.cell_ids_lng || null;

            // Build position helper
            const current_position: PositionHelper = {
              latitude,
              longitude,
              point: cloneDeep(position_point),
              usable: !isNil(latitude) && !isNil(longitude),
              gps_fix: !isNil(position_point.gps_lng) && !isNil(position_point.gps_lng),
              accuracy: position_point.gps_accuracy || position_point.cell_ids_accuracy || null,
              color: movement_color,
            };

            return current_position;
          });
      });
    }),
    replay()
  );

  public markers_by_route$$ = this._positions_by_route$$.pipe(
    map((positions_by_movements: PositionHelper[][]) => {
      // Clear old popup components
      while (this._leaflet_marker_popups.route_position_popup.size() > 0) {
        const popup = this._leaflet_marker_popups.route_position_popup.pop();
        popup.cmp.destroy();
      }

      if (isNil(positions_by_movements)) {
        return [];
      }

      return positions_by_movements.map(movement =>
        movement.map((position, index, self) => {
          let previous_movement: PositionHelper = null;
          if (index > 0) {
            previous_movement = self[index - 1];
          }

          // Builds a new marker object
          const marker = new RoutePositionMarker(L.latLng(position?.latitude, position?.longitude), {
            tracking_state: this.getMarkerTypeByTrackingState(position?.point?.tracking_state),
            previous_position: previous_movement,
            current_position: position,
            next_position: self[index + 1] || null,
          });

          const component = this._resolver.resolveComponentFactory(Bg2MapPopupMovementMarkerComponent).create(this._injector);
          marker.bindPopup(component.location.nativeElement, { closeButton: true });

          component.instance.marker = marker;
          component.instance.self_popup_ref = marker.getPopup();
          component.changeDetectorRef.detectChanges();

          this._leaflet_marker_popups.route_position_popup.push({ popup: marker.getPopup(), cmp: component });

          return marker;
        })
      );
    }),
    replay()
  );

  // #region -> (component helping methods)

  public getMarkerTypeByTrackingState(tracking_state: string): MapMarkerType {
    switch (tracking_state) {
      case 'MOVEMENT':
        return MapMarkerType.POS_TYPE_MOVEMENT;
      case 'PERIODIC_LOCATION':
        return MapMarkerType.POS_TYPE_PERIODIC_LOCATION;
      case 'STOP':
        return MapMarkerType.POS_TYPE_STOP;
      default:
        return MapMarkerType.DEFAULT;
    }
  }

  // #endregion

  // #region -> (map clusters management)

  @HostListener('window:keyup', ['$event'])
  onKeyUp(event: KeyboardEvent): void {
    if (event.shiftKey && event.code === 'KeyF') {
      this.is_clustering_enabled = !this.is_clustering_enabled;
    }
  }

  private _is_clustering_enabled$$ = new BehaviorSubject(false);
  public is_clustering_enabled$$ = this._is_clustering_enabled$$.asObservable().pipe(distinctUntilRealChanged(), replay());

  public get is_clustering_enabled(): boolean {
    return this._is_clustering_enabled$$.getValue();
  }

  public set is_clustering_enabled(is_clustered: boolean) {
    this._is_clustering_enabled$$.next(is_clustered);
  }

  private clusters$$: Observable<L.MarkerClusterGroup[]> = this.markers_by_route$$.pipe(
    map(markers_by_route => {
      const clusters = markers_by_route.map(markers => {
        const movement_cluster = this.createMovementCluster();
        movement_cluster.addLayer(L.layerGroup(markers));

        return movement_cluster;
      });

      return clusters;
    }),
    tap(clusters => this._fitBounds(clusters)),
    replay()
  );

  // #endregion

  // #region -> (map basics)

  private _leaflet_map$$ = new BehaviorSubject(null);
  private leaflet_map$$ = this._leaflet_map$$.asObservable();

  private set leaflet_map(leaflet_map: L.Map) {
    this._leaflet_map$$.next(leaflet_map);
  }

  private get leaflet_map(): L.Map {
    return this._leaflet_map$$.getValue();
  }

  public onLeafletMapReady(leaflet_map: L.Map): void {
    leaflet_map.addControl(L.control.zoom({ position: 'bottomright' }));
    this.leaflet_map = leaflet_map;
  }

  public onLeafletMapResized(): void {
    this.leaflet_map$$
      .pipe(
        filter(leaflet_map => !isNil(leaflet_map)),
        take(1)
      )
      .subscribe({
        next: leaflet_map => leaflet_map?.invalidateSize(),
      });
  }

  /**
   * Update leaflet map to fit bounds of current loaded objects.
   */
  private _fitBounds(clusters: L.MarkerClusterGroup[]): void {
    this._leaflet_map$$
      .pipe(
        filter(leaflet_map => !isNil(leaflet_map)),
        take(1)
      )
      .subscribe({
        next: leaflet_map => {
          const bounds: L.LatLngBounds = L.latLngBounds([]);

          clusters.forEach(cluster => {
            if (cluster.getLayers().length > 0) {
              bounds.extend(cluster.getBounds());
            }
          });

          if (bounds.isValid()) {
            leaflet_map.fitBounds(bounds, {
              animate: true,
              padding: L.point(25, 25),
              maxZoom: 15,
            });
          }
        },
      });
  }

  public fitBounds(): void {
    this.clusters$$.pipe(take(1)).subscribe(clusters => this._fitBounds(clusters));
  }

  private createMovementCluster(): L.MarkerClusterGroup {
    const movement_cluster = L.markerClusterGroup({
      zoomToBoundsOnClick: false,
      spiderfyOnMaxZoom: true,
      maxClusterRadius: 50,
      iconCreateFunction: (cluster: L.MarkerCluster) => {
        // Prepare some constants
        const markers = cluster.getAllChildMarkers();
        const movement_color = (markers[0] as RoutePositionMarker).line_coloration;

        return L.divIcon({
          className: 'cluster-single-movement',
          iconSize: L.point(32, 32),
          html: `<div class="circle" style="background-color: ${movement_color}">` + markers.length + '</div>',
        });
      },
    });

    // Bind event 'animationend' on cluster
    movement_cluster.on('animationend', () => {
      this._ngZone.run(() => {
        this._recompute_objects$$.next(!this._recompute_objects$$.getValue());
      });
    });

    // Bind event 'clusterclick' on cluster
    movement_cluster.on('clusterclick', (event: L.LeafletEvent) => {
      const cluster = event.sourceTarget as L.MarkerClusterGroup;
      this.leaflet_map?.fitBounds(cluster.getBounds(), { padding: [25, 25] });
    });

    if (!this.is_clustering_enabled) {
      (movement_cluster as any)?.disableClustering();
    }

    return movement_cluster;
  }

  // #endregion

  // #region -> (map layers)

  private _recompute_objects$$: BehaviorSubject<boolean> = new BehaviorSubject(true);
  private recompute_objects$$ = this._recompute_objects$$.asObservable().pipe(distinctUntilRealChanged(), replay());

  private _leaflet_map_lines$$ = combineLatest([this.clusters$$, this.recompute_objects$$]).pipe(
    map(([clusters_by_movement]) => {
      if (isNil(this.leaflet_map)) {
        return [];
      }

      const calculated_lines: L.Polyline[] = [];
      const calculated_decorators: L.PolylineDecorator[] = [];

      // Clear old popup components
      while (this._leaflet_marker_popups.movement_line_popup.size() > 0) {
        const popup = this._leaflet_marker_popups.movement_line_popup.pop();
        popup.cmp.destroy();
      }

      clusters_by_movement?.forEach(cluster =>
        cluster
          ?.getLayers()
          ?.filter(
            (marker: AbstractMarker) =>
              //this.leaflet_map.hasLayer(marker) && // Uncoment to ignore cluster points
              this.leaflet_map.getBounds().contains(marker.getLatLng()) && marker instanceof RoutePositionMarker
          )
          ?.forEach((marker: RoutePositionMarker) => {
            if (isNil(marker.previous_position)) {
              return;
            }

            const previous_point = marker.previous_position as PositionHelper;
            if (marker.tracking_state !== MapMarkerType.POS_TYPE_MOVEMENT) {
              const line: L.Polyline = L.polyline(
                [L.latLng(previous_point.latitude, previous_point.longitude), L.latLng(marker.getLatLng().lat, marker.getLatLng().lng)],
                {
                  color: marker.line_coloration,
                  weight: 5,
                }
              );

              // Builds a popup for the marker
              const component = this._resolver.resolveComponentFactory(Bg2MapPopupMovementLineComponent).create(this._injector);
              line.bindPopup(component.location.nativeElement, { closeButton: true });

              component.instance.self_popup_ref = marker.getPopup();
              component.instance.markers = cluster.getLayers() as RoutePositionMarker[];
              component.changeDetectorRef.detectChanges();

              // Build-up popup itself

              // Keep track of created component for ulterior destroy
              this._leaflet_marker_popups.movement_line_popup.push({ popup: line.getPopup(), cmp: component });

              const decorator: L.PolylineDecorator = L.polylineDecorator(line, {
                patterns: [
                  {
                    offset: 0,
                    repeat: 100,
                    symbol: L.Symbol.arrowHead({
                      pixelSize: 15,
                      polygon: false,
                      pathOptions: {
                        stroke: true,
                        color: marker.line_coloration,
                      },
                    }),
                  },
                ],
              });

              calculated_lines.push(line);
              calculated_decorators.push(decorator);
            }
          })
      );

      return [...calculated_lines, ...calculated_decorators];
    }),
    map(lines_x_decorators => {
      if (lines_x_decorators?.length <= 0) {
        return L.featureGroup([]);
      }
      return L.featureGroup(lines_x_decorators);
    }),
    replay()
  );

  private leaflet_map_circles$$: Observable<L.FeatureGroup<any>[]> = combineLatest([this.clusters$$, this.recompute_objects$$]).pipe(
    map(([movement_clusters]) => {
      const unclustered_markers_by_movement = movement_clusters
        ?.filter(
          cluster =>
            cluster
              ?.getLayers()
              ?.filter(
                (marker: AbstractMarker) =>
                  this.leaflet_map?.hasLayer(marker) &&
                  this.leaflet_map?.getBounds()?.contains(marker?.getLatLng()) &&
                  marker instanceof RoutePositionMarker
              )?.length > 0
        )
        ?.map(cluster => cluster.getLayers());

      if (flatten(unclustered_markers_by_movement).length <= 0) {
        return [];
      }

      return unclustered_markers_by_movement?.map((markers: RoutePositionMarker[]) => {
        const coloration = markers[0].line_coloration;
        const circles = markers.map(marker => marker.circle);

        return L.featureGroup([
          unifyPolyCircles(circles, {
            color: coloration,
            fillColor: coloration,
            interactive: false,
          }),
        ]);
      });
    }),
    replay()
  );

  public layers$$: Observable<L.Layer[]> = combineLatest([this.clusters$$, this._leaflet_map_lines$$, this.leaflet_map_circles$$]).pipe(
    map(([movement_clusters, lines, circles]) => [...movement_clusters, lines, ...circles])
  );

  // #endregion
}
