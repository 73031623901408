<form-element-header [schema]="schema" [showLabel]="false"></form-element-header>
<div class="dates-selection">
  <span class="mdi mdi-24px mdi-calendar-clock" [matMenuTriggerFor]="menuInterval"></span>

  <mat-form-field class="ng-default" appearance="outline">
    <mat-label>{{ schema.label | translate }}</mat-label>
    <mat-date-range-input [formGroup]="date_range_form" [rangePicker]="picker" (click)="picker.open()">
      <input
        matStartDate
        [formControlName]="'start'"
        class="browser-default"
        [placeholder]="'ALL.COMMON.DATE_MANAGEMENT.Start date' | translate"
      />
      <input
        matEndDate
        [formControlName]="'end'"
        class="browser-default"
        [placeholder]="'ALL.COMMON.DATE_MANAGEMENT.End date' | translate"
      />
    </mat-date-range-input>
    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
    <mat-date-range-picker #picker color="primary"></mat-date-range-picker>
  </mat-form-field>
</div>

<mat-menu #menuInterval="matMenu" class="time-interval-menu">
  <span
    mat-menu-item
    [class.active]="(selected_interval$$ | async) === INTERVAL.LAST_DAY"
    (click)="setInterval(INTERVAL.LAST_DAY)"
  >
    <span translate>VIEWS.PAGE.DATA.ALL.COMMON.Last day</span>
  </span>
  <span
    mat-menu-item
    [class.active]="(selected_interval$$ | async) === INTERVAL.LAST_WEEK"
    (click)="setInterval(INTERVAL.LAST_WEEK)"
  >
    <span translate>VIEWS.PAGE.DATA.ALL.COMMON.Last week</span>
  </span>
  <span
    mat-menu-item
    [class.active]="(selected_interval$$ | async) === INTERVAL.LAST_MONTH"
    (click)="setInterval(INTERVAL.LAST_MONTH)"
  >
    <span translate>VIEWS.PAGE.DATA.ALL.COMMON.Last month</span>
  </span>
  <span
    mat-menu-item
    [class.active]="(selected_interval$$ | async) === INTERVAL.LAST_YEAR"
    (click)="setInterval(INTERVAL.LAST_YEAR)"
  >
    <span translate>VIEWS.PAGE.DATA.ALL.COMMON.Last year</span>
  </span>
</mat-menu>
