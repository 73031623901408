import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';

import { BehaviorSubject, filter, forkJoin, map, Observable, switchMap, take } from 'rxjs';
import { replay } from '@bg2app/tools/rxjs';

import { isEmpty } from 'lodash-es';

import { DeviceApi } from 'app/core';

import { ModalParams } from 'app/widgets/dialogs-modals/abstract-modal.component';
import { AbstractModalComponent, DialogsService } from 'app/widgets/dialogs-modals';

import { DRDevice } from 'app/models';
import { Dictionary } from 'app/typings/core/interfaces';

import { DeviceAuthorizedMovementDialogComponent } from '../../dialogs/device-authorized-movement-dialog/device-authorized-movement-dialog.component';
import { parseDate } from 'app/misc/tools';

/** */
interface UserAccountModalParams extends ModalParams {
  /** */
  imeis: string;
  new?: boolean;
}

/** */
export interface MovementAuthorizationsByDevice {
  /** */
  device: DRDevice;

  /** */
  configurations: {
    /** */
    id: string;

    /** */
    device: {
      /** */
      imei: number;
    };

    /** */
    start: Date;

    /** */
    end: Date;

    /** */
    type: string;

    /** */
    embedded: Dictionary<any>;
  }[];
}

type ViewMode = 'table' | 'calendar';

@Component({
  selector: 'bg2-device-movement-authorization-modal',
  templateUrl: 'device-movements-authorization-modal.component.html',
  styleUrls: ['device-movements-authorization-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DeviceMovementAuthorizationModalComponent extends AbstractModalComponent<UserAccountModalParams> implements OnInit {
  // #region -> (modal basics)

  /** */
  constructor(private readonly _bg2_device_api: DeviceApi, private readonly _dialogs: DialogsService) {
    super();
  }

  ngOnInit() {
    this.new$$.pipe(take(1)).subscribe({
      next: _new => (_new ? this.create_authorized_movement() : null),
    });
  }

  /** */
  protected handle_event_before_unload(event: BeforeUnloadEvent): void {
    return null;
  }

  // #endregion

  // #region -> (view mode)

  /** */
  private _view_mode$$ = new BehaviorSubject<ViewMode>('table');

  /** */
  public view_mode$$: Observable<ViewMode> = this._view_mode$$.asObservable();

  /** */
  public set view_mode(mode: ViewMode) {
    this._view_mode$$.next(mode);
  }

  // #endregion

  // #region -> (devices)

  /** */
  public imeis$$ = this.input_params$$.pipe(
    map(parameters => parameters.imeis),
    map(imeis => JSON.parse(imeis) as number[]),
    replay()
  );

  /** */
  public new$$ = this.input_params$$.pipe(
    map(parameters => parameters.new || false),
    replay()
  );

  /** */
  public devices$$ = this.imeis$$.pipe(
    filter(imeis => !isEmpty(imeis ?? [])),
    switchMap(imeis => this._bg2_device_api.requestDevices(imeis)),
    replay()
  );

  // #endregion

  // #region -> (authorized movements)

  /** */
  private _reload$$ = new BehaviorSubject<boolean>(true);

  /** */
  private reload$$ = this._reload$$.asObservable();

  /** */
  public reload(): void {
    this._reload$$.next(true);
  }

  /** */
  public authorized_movements_by_device$$ = this.devices$$.pipe(
    switchMap(devices => this.reload$$.pipe(map(() => devices))),
    switchMap(devices => {
      const move_auth_by_device$$ = devices.map(device =>
        this._bg2_device_api.fetch_device_tmp_confs$(device.imei, 'move_auth', true).pipe(
          map(configurations =>
            configurations.tmp_configurations.map((configuration: any) => {
              configuration.start = parseDate(configuration.start);
              configuration.end = parseDate(configuration.end);

              return configuration;
            })
          ),
          map(configurations => <MovementAuthorizationsByDevice>{ device, configurations: configurations })
        )
      );

      return forkJoin(move_auth_by_device$$);
    }),
    replay()
  );

  /** */
  public create_authorized_movement(): void {
    this.imeis$$
      .pipe(
        take(1),
        switchMap(imeis => this._dialogs.open(DeviceAuthorizedMovementDialogComponent, { imeis }))
      )
      .subscribe({
        next: response => {
          if (response) {
            this.reload();
          }
        },
      });
  }

  // #endregion
}
