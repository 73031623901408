import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { isNil } from 'lodash-es';

import { distinctUntilRealChanged, replay, waitForNotNilValue } from '@bg2app/tools/rxjs';
import { map, BehaviorSubject, switchMap, tap, filter, Observable, take, of, debounceTime, combineLatest } from 'rxjs';

import { ENV } from '../providers/environment.provider';
import { ConsoleLoggerService } from '../console-logger.service';
import { IdentityServerService as SwaggerIdentityServerService } from '../api-swagger/user-v2';

import { IEnvironment } from 'environments/common';

@Injectable({
  providedIn: 'root',
})
export abstract class GenericAuthService extends SwaggerIdentityServerService {
  // #region -> (service basics)

  /** */
  protected readonly LOGGER = new ConsoleLoggerService('GenericAuthService', false);

  /** */
  constructor(@Inject(ENV) public env: IEnvironment, protected httpClient: HttpClient) {
    super(httpClient, env.UserApiUrl, null);

    // Loads impersonate ID and scopes
    const _impersonate_id = localStorage.getItem('_impersonate_id') ?? null;
    const _impersonate_scopes = localStorage.getItem('_impersonate_scopes') ?? '';
    if (!isNil(_impersonate_id)) {
      const impersonate_id = parseInt(_impersonate_id);
      const impersonate_scopes = _impersonate_scopes.split(', ');
      this.LOGGER.debug('Init impersonate');
      this._impersonate$$.next({ user_id: impersonate_id, scopes: impersonate_scopes });
    }
    this.check_bearer();
  }

  // #endregion

  // #region -> (errors management)

  /** */
  handleUnauthorizedError(err: any): void {
    console.error(err);

    // Try to get user id
    // if (this.userid_fetched) {
    //   this.userid_fetched = false;
    //   this.real_user_id = null;
    //   this.fetchUserId();
    // }
  }

  // #endregion

  // #region -> (authorization management)

  /** */
  private _is_authorized$$ = new BehaviorSubject<boolean>(false);

  public is_authorized$$ = this._is_authorized$$.pipe(distinctUntilRealChanged(), replay());

  /** */
  public wait_is_authorized$$ = this.is_authorized$$.pipe(filter(is_authorized => is_authorized));

  /** */
  protected get is_authorized() {
    return this._is_authorized$$.getValue();
  }

  /** */
  protected set is_authorized(_authorized: boolean) {
    this._is_authorized$$.next(_authorized);
  }

  /**
   * True if not authorize and no "userid fetch" in progress
   */
  canLogin(): boolean {
    // return !this._authorized && !this.userid_fetch_inprogress;
    return !this.is_authorized;
  }

  /**
   * True if token has been verified
   */
  isAuthorized(): boolean {
    return this.is_authorized;
  }

  private check_bearer(): void {
    this.access_token$$
      .pipe(
        take(1),
        switchMap(access_token => {
          if (isNil(access_token)) {
            return of(null);
          }
          return this.httpClient.get(this.env.UserApiUrl, {
            headers: new HttpHeaders({
              Accept: 'text/json',
              Authorization: `Bearer ${access_token}`,
            }),
          });
        })
      )
      .subscribe({
        next: (res: { commit_name: string; commit_sha: string; name: string; user_id: number } | null) => {
          if (isNil(res)) {
            return null;
          }

          this._real_user_id$$.next(res.user_id);
          this.is_authorized = true;
        },
      });
  }

  // #endregion

  // #region -> (token management)

  /**
   *
   */
  protected _access_token: string = null;
  private _expires_at_epoch_ms: number = null;

  /** */
  protected _update_token$$ = new BehaviorSubject(true);

  protected getAccessToken() {
    return of(this._access_token);
  }

  protected storeAccessToken(access_token: string, expires_at_epoch_ms: number) {
    this._access_token = access_token;
    this._expires_at_epoch_ms = expires_at_epoch_ms;
    return of(true);
  }

  /**
   * Observes the access token.
   *
   * @public
   */
  public access_token$$: Observable<string> = this._update_token$$.pipe(
    debounceTime(100), // This is important to be sure access token has been saved
    switchMap(() => this.getAccessToken()),
    distinctUntilRealChanged(),
    replay()
  );

  /** */
  protected set_access_token(access_token: string, expires_at_epoch_ms: number) {
    this._access_token = access_token;

    return this.storeAccessToken(access_token, expires_at_epoch_ms).pipe(
      filter(Boolean),
      tap(() => this._update_token$$.next(true)),
      switchMap(() => this.access_token$$),
      waitForNotNilValue()
    );
  }

  /**
   * @deprecated
   */
  public get access_token(): string {
    return this._access_token;
  }

  /** */
  public abstract hasValidAccessToken$(): Observable<{ valid: boolean; reason?: 'expired' | 'empty' }>;

  // #endregion

  // #region -> (real user management)

  /**
   *
   * @protected
   * @readonly
   */
  protected readonly _real_user_id$$ = new BehaviorSubject<number>(null);

  /**
   *
   * @public
   * @readonly
   */
  public readonly real_user_id$$ = this._real_user_id$$.pipe(waitForNotNilValue(), distinctUntilRealChanged(), replay());

  /**
   *
   * @public
   */
  public get real_user_id(): number {
    return this._real_user_id$$.getValue();
  }

  // #endregion

  // #region -> (impersonated user management)

  /** */
  private _impersonate$$ = new BehaviorSubject<{ user_id: number; scopes: string[] }>(null);

  public impersonate$$ = this._impersonate$$.asObservable();
  /** */
  public impersonate_id$$ = this.impersonate$$.pipe(
    map(impersonate => impersonate?.user_id || null),
    distinctUntilRealChanged(),
    replay()
  );

  public is_impersonate_actif$$ = this._impersonate$$.pipe(
    map(impersonate_id => !isNil(impersonate_id)),
    distinctUntilRealChanged(),
    replay()
  );

  public get impersonate(): { user_id: number; scopes: string[] } {
    return this._impersonate$$.getValue();
  }

  /** */
  public set impersonate(_impersonate: { user_id: number; scopes: string[] }) {
    if (!isNil(_impersonate)) {
      localStorage.setItem('_impersonate_id', _impersonate.user_id.toString());
      localStorage.setItem('_impersonate_scopes', _impersonate.scopes?.join(', '));
    } else {
      localStorage.removeItem('_impersonate_id');
      localStorage.removeItem('_impersonate_scopes');
    }
    this.LOGGER.debug('Set impersonate');
    this._impersonate$$.next(_impersonate);
    this.check_bearer();
  }

  /**
   * Observes if impersonate ID is not nil.
   *
   * @public
   * @replay
   */
  public has_impersonate_id$$ = this.impersonate_id$$.pipe(
    map(impersonate_id => !isNil(impersonate_id)),
    distinctUntilRealChanged(),
    replay()
  );

  /**
   * Dictionnary of authentication data.
   *
   * @public
   * @replay
   */
  public abstract authentication_data$$: Observable<{
    expires_at: number;
    access_token: string;
    impersonate: {
      user_id: number;
      scopes: string[];
    };
  }>;

  // #endregion

  public user_id$$ = combineLatest({
    real_user_id: this.real_user_id$$,
    impersonate_id: this.impersonate_id$$,
  }).pipe(
    map(({ real_user_id, impersonate_id }) => {
      if (isNil(impersonate_id)) {
        return real_user_id;
      }
      return impersonate_id;
    }),
    replay()
  );

  /** */
  public abstract login(email: string, password: string): void;

  /** */
  public abstract logout(): void;

  // userid_fetch_inprogress = false;
  // userid_fetched = false;
}
