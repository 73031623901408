<div class="bg2-zoho-check-bind-exploitation-id-component">
  <table>
    <thead>
      <tr>
        <th colspan="4">
          <span>
            {{
              'VIEWS.ZOHO.SHARED.ZOHO_CHECKS.ZOHO_CHECK_ACCOUNT_EXPLOITATION_ID.Checking Zoho CRM binding with exploitation "[exploitation_name]"'
                | translate : { exploitation_name: local_exploitation_name$$ | async }
            }}
          </span>
        </th>
      </tr>

      <tr>
        <th class="banned-cell"></th>
        <th>{{ 'ENTITY.EXPLOITATION.Exploitation "[name]"' | translate : { name: local_exploitation_name$$ | async } }}</th>
        <th>{{ 'MODELS.ZOHO.CRM.CRM account' | translate }}</th>
        <th>{{ 'MODELS.ZOHO.DESK.DESK account' | translate }}</th>
      </tr>
    </thead>

    <tbody>
      <!-- Exploitation ID line -->
      <tr>
        <th>{{ "ENTITY.EXPLOITATION.Exploitation's ID" | translate }}</th>

        <td *ngLet="local_exploitation_id$$ | async; let local_exploitation_id" class="not-editable">
          <div *ngIf="local_exploitation_id; else iconCloseThick" class="cell-content">
            <span>{{ local_exploitation_id }}</span>
            <a
              class="mdi mdi-web"
              [queryParamsHandling]="'preserve'"
              [routerLink]="['', { outlets: { modal: ['update_entity', { eid: local_exploitation_id }] } }]"
            ></a>
          </div>
        </td>

        <td
          *ngLet="zoho_crm_account$$ | async; let zoho_crm_account_or_error"
          [ngClass]="{ error: is_zoho_account_expl_id_invalid$$ | async }"
        >
          <ng-container *ngIf="asType(zoho_crm_account_or_error, 'crm_account'); let zoho_crm_account; else: iconCloseThick">
            <ng-container *ngIf="zoho_crm_account.exploitation_id$$ | async; let exploitation_id; else: iconCloseThick">
              <span>{{ exploitation_id }}</span>
            </ng-container>
          </ng-container>
        </td>

        <td class="banned-cell"></td>
      </tr>

      <!-- Name line -->
      <tr>
        <th>{{ 'ALL.COMMON.Name' | translate }}</th>

        <td *ngLet="local_exploitation_name$$ | async; let local_exploitation_name">
          <ng-container *ngIf="local_exploitation_name">
            {{ local_exploitation_name }}
          </ng-container>

          <ng-container *ngIf="!local_exploitation_name">
            <span class="mdi mdi-close-thick"></span>
          </ng-container>
        </td>

        <td *ngLet="zoho_crm_account$$ | async; let zoho_crm_account_or_error" [ngClass]="{ error: isError(zoho_crm_account_or_error) }">
          <ng-container *ngIf="asType(zoho_crm_account_or_error, 'crm_account'); let zoho_crm_account; else: iconCloseThick">
            <ng-container *ngIf="zoho_crm_account.account_name$$ | async; let account_name; else: iconCloseThick">
              <span>{{ account_name }}</span>
            </ng-container>
          </ng-container>
        </td>

        <td *ngLet="zoho_desk_account$$ | async; let zoho_desk_account_or_error" [ngClass]="{ error: isError(zoho_desk_account_or_error) }">
          <ng-container *ngIf="asType(zoho_desk_account_or_error, 'desk_account'); let zoho_desk_account; else: iconCloseThick">
            <ng-container *ngIf="zoho_desk_account.account_name$$ | async; let account_name; else: iconCloseThick">
              <span>{{ account_name }}</span>
            </ng-container>
          </ng-container>
        </td>
      </tr>

      <!-- CRM ID line -->
      <tr>
        <th>{{ 'MODELS.ZOHO.CRM.CRM identifier' | translate }}</th>

        <td *ngLet="local_exploitation_crm_id$$ | async; let local_exploitation_crm_id" [ngClass]="{ error: !local_exploitation_crm_id }">
          <ng-container *ngIf="local_exploitation_crm_id">
            {{ local_exploitation_crm_id }}
          </ng-container>

          <ng-container *ngIf="!local_exploitation_crm_id">
            <span class="mdi mdi-close-thick"></span>
          </ng-container>
        </td>

        <td
          *ngLet="zoho_crm_account$$ | async; let zoho_crm_account_or_error"
          [ngClass]="{ error: isError(zoho_crm_account_or_error) }"
          class="not-editable"
        >
          <ng-container *ngIf="asType(zoho_crm_account_or_error, 'crm_account'); let zoho_crm_account; else: iconCloseThick">
            <div *ngIf="zoho_crm_account.id$$ | async; let id; else: iconCloseThick" class="cell-content">
              <span>{{ id }}</span>
              <a *ngIf="zoho_crm_account.url$$ | async; let url" [href]="url" target="_blank" class="mdi mdi-web"></a>
            </div>
          </ng-container>
        </td>

        <td *ngLet="zoho_desk_account$$ | async; let zoho_desk_account_or_error" [ngClass]="{ error: isError(zoho_desk_account_or_error) }">
          <ng-container *ngIf="asType(zoho_desk_account_or_error, 'desk_account'); let zoho_desk_account; else: iconCloseThick">
            <ng-container *ngIf="(zoho_desk_account.zoho_crm_account$$ | async)?.id; let zoho_crm_account_id; else: iconCloseThick">
              <span>{{ zoho_crm_account_id }}</span>
            </ng-container>
          </ng-container>
        </td>
      </tr>

      <!-- DESK ID line -->
      <tr>
        <th>{{ 'MODELS.ZOHO.DESK.DESK identifier' | translate }}</th>

        <td colspan="2" class="banned-cell"></td>

        <td
          *ngLet="zoho_desk_account$$ | async; let zoho_desk_account_or_error"
          [ngClass]="{ error: isError(zoho_desk_account_or_error) }"
          class="not-editable"
        >
          <ng-container *ngIf="asType(zoho_desk_account_or_error, 'desk_account'); let zoho_desk_account; else: iconCloseThick">
            <div *ngIf="zoho_desk_account.id$$ | async; let zoho_desk_account_id; else: iconCloseThick" class="cell-content">
              <span>{{ zoho_desk_account_id }}</span>
              <a *ngIf="zoho_desk_account.url$$ | async; let url" [href]="url" target="_blank" class="mdi mdi-web"></a>
            </div>
          </ng-container>
        </td>
      </tr>
    </tbody>

    <!-- Table footer -->
    <tfoot>
      <tr>
        <td
          *ngLet="final_validation$$ | async; let final_validation_status"
          colspan="4"
          [ngClass]="{ error: isError(final_validation_status) }"
        >
          <div>
            <ng-container *ngIf="isError(final_validation_status); let error; else: passed">
              <span>{{ error?.message | translate }}</span>

              <ng-container *ngIf="error?.message === 'MODELS.ZOHO.ERROR.MISSING_CRM_ID_IN_EXPLOITATION'">
                <a
                  [queryParamsHandling]="'preserve'"
                  [routerLink]="['', { outlets: { modal: ['update_entity', { eid: local_exploitation_id$$ | async }] } }]"
                  class="clickable"
                >
                  {{ 'VIEWS.ZOHO.SHARED.ZOHO_CHECKS.CHECK_ACCOUNT_EXPLOITATION_ID.Link a zoho account to the exploitation' | translate }}
                </a>
              </ng-container>

              <ng-container
                *ngIf="
                  error?.message === 'MODELS.ZOHO.ERROR.LOCAL_EXPL_ID_DIFFERENT_FROM_CRM_ACCOUNT_EXPL_ID' ||
                  error?.message === 'MODELS.ZOHO.ERROR.MISSING_EXPL_ID_IN_CRM_ACCOUNT'
                "
              >
                <a href="javascript:void(0)" class="clickable" (click)="update_zoho_exploitation_id()">
                  {{ 'VIEWS.ZOHO.SHARED.ZOHO_CHECKS.CHECK_ACCOUNT_EXPLOITATION_ID.Update exploitation ID on Zoho-Account' | translate }}
                </a>
              </ng-container>

              <ng-container *ngIf="error?.message === 'MODELS.ZOHO.ERROR.UNDEFINED_ZOHO_CRM_ACCOUNT'">
                <a
                  [queryParamsHandling]="'preserve'"
                  [routerLink]="['', { outlets: { modal: ['update_entity', { eid: local_exploitation_id$$ | async }] } }]"
                  class="clickable"
                >
                  {{
                    'VIEWS.ZOHO.SHARED.ZOHO_CHECKS.CHECK_ACCOUNT_EXPLOITATION_ID.Modify linked zoho account in the exploitation' | translate
                  }}
                </a>
              </ng-container>
            </ng-container>

            <ng-template #passed>
              <span>{{ 'VIEWS.ZOHO.SHARED.ZOHO_CHECKS.ALL.Nothing to do' | translate }}</span>
            </ng-template>
          </div>
        </td>
      </tr>
    </tfoot>
  </table>
</div>

<ng-template #validCheck>
  <span class="mdi mdi-check-bold"></span>
</ng-template>

<ng-template #iconCloseThick>
  <span class="mdi mdi-close-thick"></span>
</ng-template>
