<ng-container *ngIf="(date$$ | async) && (value$$ | async)">
  <div class="form-element-previous">
    <span>{{ 'WIDGETS.EVENT_FORM.EVENT_FORM_PREVIOUS.Last visit on [date]:' | translate:{ 'date': (date$$ | async) | formatDate:'short'} }}&nbsp;</span>
    <ng-container *ngIf="!from_template && (canonical_path$$ | async); let canonical_path">
      <ng-container [ngSwitch]="true">

        <!-- 'hive_status' || 'state' case -->
        <ng-container *ngSwitchCase="canonical_path.includes('evaluation_hive.hive_status') || canonical_path.includes('evaluation_hive.state')">
          <span>{{ ('EVENT.VISIT.STATUS.' + (value$$ | async)) | translate }}</span>
        </ng-container>

        <!-- 'brood_box' case -->
        <ng-container *ngSwitchCase="canonical_path.includes('evaluation_hive.brood_box')">
          <ng-container *ngIf="canonical_path.includes('brood_box.brood_frame')">
            <span *ngIf="((current$$ | async) === 'homogeneous' || (current$$ | async) === 'eggs'); else classicValue;">
              {{ ((value$$ | async) ? 'ALL.COMMON.Yes' : 'ALL.COMMON.No') | translate }}
            </span>
          </ng-container>
          <ng-container *ngIf="canonical_path.includes('brood_box.stores_frames')">
            <ng-container [ngTemplateOutlet]="classicValue"></ng-container>
          </ng-container>
          <ng-container *ngIf="canonical_path.includes('brood_box.empty_frames')">
            <ng-container [ngTemplateOutlet]="classicValue"></ng-container>
          </ng-container>
        </ng-container>

        <!-- 'queen' case -->
        <ng-container *ngSwitchCase="canonical_path.includes('evaluation_hive.queen')">
          <span *ngIf="canonical_path.includes('queen.status')">
            {{ ('EVENT.VISIT.QUEEN.' + (value$$ | async)) | translate }}
          </span>
          <span *ngIf="canonical_path.includes('queen.color')">
            <img src="assets/img/queen/{{ value$$ | async }}.png" alt="" style="max-width: 30px;">
          </span>
          <span *ngIf="canonical_path.includes('queen_cell')">
            {{ ('EVENT.VISIT.QUEEN_CELL.' + (value$$ | async)) | translate }}
          </span>
        </ng-container>

        <!-- 'supers' case -->
        <ng-container *ngSwitchCase="canonical_path.includes('evaluation_hive.supers')">
          <span *ngIf="(current$$ | async) === 'nb_supers'">{{ value$$ | async }}&nbsp;{{ ('ENTITY.ALL.TYPE.super'.concat((value$$ | async) > 0 ? 's' : '')) | translate }}</span>
        </ng-container>

        <!-- Default case -->
        <ng-container *ngSwitchDefault>
          <ng-container [ngTemplateOutlet]="classicValue"></ng-container>
        </ng-container>

      </ng-container>
    </ng-container>
    <ng-container *ngIf="from_template">
      <ng-content></ng-content>
    </ng-container>
    <span *ngIf="previous_btn" class="mdi mdi-refresh mdi-flip-h mdi-24px clickable" style="padding: 0 3px" (click)="resetPrevious()"></span>
  </div>
</ng-container>

<ng-template #classicValue>
  <span>{{ value$$ | async}}</span>
</ng-template>