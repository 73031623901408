<div class="bg2-apiary-undefined-component">
  <div class="catch-sentence">
    <span>{{ 'VIEWS.APIARY.SHARED.WIDGETS.APIARY_UNDEFINED.This location does not have an apiary' | translate }}.&nbsp;</span>
    <span>{{ 'VIEWS.APIARY.SHARED.WIDGETS.APIARY_UNDEFINED.Do you want to install one ?' | translate }}</span>
  </div>

  <div class="available-actions">
    <ng-container *ngIf="env.config.events.activate_migratory; else onlyCreateApiary">
      <bg2-button-condition
        [types]="['action', 'action']"
        [icons]="['mdi-plus', 'mdi-truck']"
        [messages]="[
          'VIEWS.APIARY.SHARED.WIDGETS.APIARY_UNDEFINED.Create an apiary' | translate,
          'VIEWS.APIARY.SHARED.WIDGETS.APIARY_UNDEFINED.Migrate an existing apiary' | translate
        ]"
        [internal_routing_0]="{
          routerLink: [
            '',
            { outlets: { modal: ['new_entity', { etype: 'apiary', args: ({ setup: { location: location_id$$ | async } } | json) }] } }
          ],
          queryParamsHandling: 'preserve'
        }"
        [internal_routing_1]="{
          routerLink: ['', { outlets: { modal: ['migratory', { args: ({ location_dest: location_id$$ | async } | json) }] } }],
          queryParamsHandling: 'preserve'
        }"
      ></bg2-button-condition>
    </ng-container>

    <ng-template #onlyCreateApiary>
      <bg2-button
        [type]="'action'"
        [icon]="'mdi-plus'"
        [message]="'VIEWS.APIARY.SHARED.WIDGETS.APIARY_UNDEFINED.Create an apiary' | translate"
        [internal_routing]="{
          routerLink: [
            '',
            { outlets: { modal: ['new_entity', { etype: 'apiary', args: ({ setup: { location: location_id$$ | async } } | json) }] } }
          ],
          queryParamsHandling: 'preserve'
        }"
      ></bg2-button>
    </ng-template>
  </div>

  <div class="previous-apiary">
    <span>{{ 'VIEWS.APIARY.SHARED.WIDGETS.APIARY_UNDEFINED.Previous apiary' | translate | colon }}</span>

    <ng-container *ngIf="(is_loading.previous_apiary$$ | async) === false; else loadingPreviousApiary">
      <ng-container *ngIf="has_previous_apiary$$ | async; else noPreviousApiary">
        <b>{{ 'ENTITY.APIARY.Apiary "[name]"' | translate: { name: previous_apiary_name$$ | async } }}</b>
        <span>,&nbsp;</span>
        <span>
          {{ 'VIEWS.APIARY.Removed on [date]' | translate: { date: apiary_remove_at$$ | async | formatDate: 'short' } | lowercase }}
        </span>
        <span>.</span>
      </ng-container>
    </ng-container>

    <ng-template #loadingPreviousApiary>
      <bg2-loading-skeleton [string_model]="'apiary test_name'"></bg2-loading-skeleton>
      <span>,&nbsp;</span>
      <bg2-loading-skeleton [string_model]="'removed at date_short'"></bg2-loading-skeleton>
      <span>.</span>
    </ng-template>

    <ng-template #noPreviousApiary>
      <span>{{ 'ENTITY.APIARY.ERRORS.No apiary' | translate }}</span>
    </ng-template>
  </div>
</div>
