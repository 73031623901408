import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit } from '@angular/core';

import { isNil } from 'lodash-es';

import { BehaviorSubject, catchError, map, Observable, of, Subscription, switchMap, take, tap } from 'rxjs';
import { anyTrue, distinctUntilRealChanged, replay, waitForNotNilValue } from '@bg2app/tools/rxjs';

import { Location, LocationEntityUserAce } from 'app/models';

@Component({
  selector: 'bg2-location-details-content',
  templateUrl: './location-details-content.component.html',
  styleUrls: ['./location-details-content.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LocationDetailsContentComponent implements OnInit, OnDestroy {
  // #region -> (component basics)

  /** */
  private _has_apiary_sub: Subscription = null;

  constructor() {}

  ngOnInit(): void {
    this._has_apiary_sub = this.location_has_apiary$$.subscribe({
      next: has_apiary => {
        this.selected_tab = has_apiary ? 'apiary' : 'location';
      },
    });
  }

  ngOnDestroy(): void {
    this._has_apiary_sub?.unsubscribe();
  }

  // #endregion

  @Input()
  public display_type: 'classic' | 'compact' | 'modal' = 'classic';

  // #region -> (location entity)

  /** */
  private _location$$ = new BehaviorSubject<Location>(null);

  /**
   * Observes the location entity.
   */
  public location$$: Observable<Location> = this._location$$.asObservable().pipe(
    waitForNotNilValue(),
    tap(() => this.loadings.location$$.next(false))
  );

  /** */
  @Input()
  public set location(location: Location) {
    this._location$$.next(location);
  }

  /**
   * Observes if the location is ghost.
   */
  public is_location_is_ghost$$ = this.location$$.pipe(switchMap(location => location.is_ghost$$));

  /**
   * Observes if the location has an apiary.
   */
  public location_has_apiary$$ = this.location$$.pipe(switchMap(location => location.has_apiary$$));

  /**
   * Observes location's latitude.
   */
  public location_latitute$$ = this.location$$.pipe(switchMap(location => location.location_latitute$$));

  /** */
  public location_longitude$$ = this.location$$.pipe(switchMap(location => location.location_longitude$$));

  /** */
  public location_has_coordinates$$ = this.location$$.pipe(switchMap(location => location.location_has_latlng$$));

  /** */
  public location_elevation$$ = this.location$$.pipe(switchMap(location => location.location_elevation$$));

  /** */
  public location_has_elevation$$ = this.location$$.pipe(switchMap(location => location.location_has_elevation$$));

  /**
   * Observes the location address
   */
  public location_address$$ = this.location$$.pipe(
    switchMap(location => location.address$$)
  );

  /** */
  public location_has_address$$ = this.location_address$$.pipe(map(address => !isNil(address)));

  /**
   * Observes the comment of the location.
   */
  public comment$$ = this.location$$.pipe(switchMap(location => location.comment$$));

  /** */
  public location_has_ace$$ = (scope: string) => this.location$$.pipe(map(location => location.hasACE(scope)));

  // #endregion

  // #region -> (related apiary entity)

  /**
   * Observes the location's apiary.
   */
  public apiary$$ = this.location$$.pipe(
    switchMap(location => location.apiary$$),
    waitForNotNilValue(),
    tap(() => this.loadings.apiary$$.next(false))
  );

  /** */
  public apiary_id$$ = this.apiary$$.pipe(switchMap(apiary => apiary.id$$));

  /** */
  public apiary_name$$ = this.apiary$$.pipe(switchMap(apiary => apiary?.name$$ ?? of(null)));

  /** */
  public apiary_is_ghost$$ = this.apiary$$.pipe(switchMap(apiary => apiary?.is_ghost$$ ?? of(false)));

  /** */
  public apiary_has_devices_for_movement_detection$$ = this.apiary$$.pipe(
    switchMap(apiary => apiary?.has_devices_for_movement_detection$$ ?? of(false))
  );

  /** */
  public user_can_x_apiary$$ = (ace: keyof typeof LocationEntityUserAce) =>
    this.apiary$$.pipe(
      switchMap(apiary => apiary.user_acl.can$$(ace)),
      distinctUntilRealChanged(),
      replay()
    );

  // #endregion

  // #region -> (tab selection)

  /** */
  private _selected_tab$$: BehaviorSubject<'apiary' | 'location'> = new BehaviorSubject<'apiary' | 'location'>('location');

  /** */
  public selected_tab$$ = this._selected_tab$$.asObservable().pipe(distinctUntilRealChanged());

  /** */
  public set selected_tab(selected_tab: 'apiary' | 'location') {
    this.location_has_apiary$$.pipe(take(1)).subscribe({
      next: has_apiary => {
        if (selected_tab === 'location' || (selected_tab === 'apiary' && has_apiary)) {
          this._selected_tab$$.next(selected_tab);
        }
      },
    });
  }

  // #endregion

  // #region -> (active alarms management)

  /** */
  public movement_alarms$$ = this.location$$.pipe(switchMap(location => location.movement_alarms$$));

  // #endregion

  // #region -> (ACE management)

  /**
   * Checks if the current user can read approximate position.
   *
   * @public
   */
  public user_can_read_approximate_position$$ = this.location$$.pipe(
    switchMap(location => location.user_acl.check_read_aprox_position$$({ what: '' })),
    map(() => true),
    catchError(() => of(false))
  );

  /**
   * Checks if the user can read precise position.
   *
   * @public
   */
  public user_can_read_precise_position$$ = this.location$$.pipe(
    switchMap(location => location.user_acl.check_read_precise_position$$({ what: '' })),
    map(() => true),
    catchError(() => of(false))
  );

  /**
   * Checks if the current user can read precise or aproximate position.
   *
   * @public
   */
  public user_can_read_any_position$$ = anyTrue(this.user_can_read_approximate_position$$, this.user_can_read_precise_position$$);

  // #endregion

  /** */
  public loadings = {
    /** */
    location$$: new BehaviorSubject(true),

    /** */
    apiary$$: new BehaviorSubject(true),
  };
}
