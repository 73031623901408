import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { BehaviorSubject } from 'rxjs';

import { ZohoBooksInvoice } from 'app/models/zoho/books';

@Component({
  selector: 'bg2-zoho-books-invoice-summary',
  templateUrl: './zoho-books-invoice-summary.component.html',
  styleUrls: ['./zoho-books-invoice-summary.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ZohoBooksInvoiceSummaryComponent {
  // #region -> (component basics)

  constructor() {}

  // #endregion

  // #region -> (deal object)

  /** */
  private _invoice$$ = new BehaviorSubject<ZohoBooksInvoice>(null);

  /** */
  public invoice$$ = this._invoice$$.asObservable();

  @Input()
  public set invoice(invoice: ZohoBooksInvoice) {
    this._invoice$$.next(invoice);
  }

  public get note(): ZohoBooksInvoice {
    return this._invoice$$.getValue();
  }

  // #endregion
}
