<ng-container *ngIf="flags$$ | async as flags">
  <span
    *ngIf="!loading && !flags?.never"
    class="last-com-widget"
    [class.too-old]="flags?.too_old"
    [class.fromnow]="fromnow && !(hive_fromnow_if_old && flags?.too_old)"
  >
    <ng-container *ngIf="prefix$$ | async as prefix">
      <span *ngIf="prefix.is_nil">{{ 'DEVICE.ALL.COMMUNICATION.last communication' | translate | colon }}</span>
      <span *ngIf="prefix.is_string" [innerHTML]="prefix.prefix"></span>
    </ng-container>

    <ng-container *ngIf="fromnow && !(hive_fromnow_if_old && flags?.too_old)">
      <ng-container *ngLet="last_contact$$ | async; let last_contact">
        <ng-container *ngIf="{ age_days: (DATE_NOW | differenceInDays : last_contact) }; let ctx">
          <span [ngStyle]="{ color: ctx.age_days > 7 ? 'red' : ctx.age_days > 2 ? 'orange' : 'green' }">
            {{ last_contact | distanceToNow : true : true }}
          </span>
        </ng-container>
      </ng-container>
    </ng-container>

    <ng-container *ngIf="fulldate">
      <ng-container *ngLet="last_contact$$ | async; let last_contact">
        <ng-container *ngIf="{ age_days: (DATE_NOW | differenceInDays : last_contact) }; let ctx">
          <span class="fulldate" [ngStyle]="{ color: ctx.age_days > 7 ? 'red' : ctx.age_days > 2 ? 'orange' : 'green' }">
            {{ last_contact | formatDate : 'short' }}
          </span>
        </ng-container>
      </ng-container>
    </ng-container>
  </span>

  <span *ngIf="flags?.never && !loading" translate>DEVICE.ALL.ERROR.Never get data</span>
  <span *ngIf="loading" translate>ALL.COMMON.Loading</span>
</ng-container>
