import {
  of,
  tap,
  map,
  take,
  concat,
  concatMap,
  switchMap,
  catchError,
  Observable,
  Subscription,
  debounceTime,
  combineLatest,
  BehaviorSubject,
  forkJoin,
} from 'rxjs';
import {
  replay,
  waitForNotNilValue,
  robustCombineLatest,
  distinctUntilRealChanged,
  create_replay_subject_with_first_value,
} from '@bg2app/tools/rxjs';

import { max, isNil, clone, sortBy, values, reverse, orderBy, indexOf, isEmpty, isArray, min } from 'lodash-es';

import {
  setSeconds,
  differenceInDays,
  differenceInHours,
  subYears,
  differenceInYears,
  addYears,
  isAfter,
  subHours,
  addHours,
  startOfTomorrow,
} from 'date-fns/esm';

import { marker as i18n } from '@biesbjerg/ngx-translate-extract-marker';

import {
  Fconf,
  Sconf,
  PutBatCh,
  BatChange,
  PostNewBatCh,
  DeviceBatInfo,
  Device as DeviceInterface,
  GetDeviceTimeseriesResponse,
  GetDeviceSimplifiedConfigurationResponse,
  DeviceSupportIssue,
} from 'app/core/api-swagger/device';
import { Beeguard2Api, DeviceApi, UsersApiService } from 'app/core';
import { DeviceLastMeasurement } from 'app/core/api-swagger/device';
import { DeviceQueryParams } from 'app/core/api/device/device-api-service';

import { environment } from 'environments/environment';

import { HiveDeviceConfig } from '../entities/misc';
import { Apiary, Entity, Exploitation, Hive, Location, Warehouse, WhDevicesConfig } from '../entities';

import {
  compute_bat_state,
  compute_868_observation_state,
  compute_bat_observation_state,
  compute_gps_observation_state,
  compute_gprs_observation_state,
} from './_functions';
import { parseDate, strEnum } from 'app/misc/tools';
import { getDistance } from 'app/misc/tools/geomap';

import { Dictionary } from 'app/typings/core/interfaces';
import { DatatableDeviceRow } from 'app/typings/datatable/interfaces/DatatableDeviceRow.iface';

import { User } from '../user/User.model';

import { DeviceUserAce } from './enumerators/device-user-ace.enum';
import { DeviceUserAclManager } from './classes/device-user-acl-manager.class';
import { AnyOfDeviceJobData, AnyOfDeviceSecondaryData, DataPoint, DeviceRSSIData } from '../data';
import { DeviceCommunicationTechnology } from './enumerators/device-communication-technology.enum';
import {
  BatterySparklineData,
  BeeGuardSetup,
  BeeLiveCredentials,
  Device868SparklineData,
  DeviceAffectation,
  DeviceFullConfiguration,
  DeviceGPRSSparklineData,
  DeviceSupportType,
  RawDeviceSupports,
} from './interfaces';
import {
  device_battery_price,
  DEVICE_BATTERY_TYPE,
  DEVICE_SIMPLIFIED_BATTERY_STATE,
  DEVICE_SIMPLIFIED_BATTERY_STATE_REASON,
  DeviceSimplifiedBatteryState,
} from './enumerators';
import { tzLookup } from 'app/misc/tools/misc/suncalc/tzLookup';
import { getTimezoneOffset, utcToZonedTime } from 'date-fns-tz';
import { startOfTomorrowLuxon } from 'app/misc/tools/dates';
import { ZohoApisService } from 'app/core/services/zoho/zoho-apis.service';
import { ZohoDeskTicket } from '../zoho/desk';
import { DateTime } from 'luxon';
import { timezones_data } from '../misc/timezones';
import { AppStateService } from 'app/core/app-state.service';

export { Sconf } from 'app/core/api-swagger/device/model/sconf';
export { Device as DeviceInterface } from 'app/core/api-swagger/device/model/device';

/**
 * An interface to define warehouse configuration.
 */
interface WarehouseConfig {
  timestamp: Date;
  event_id: number;
  warehouse_id: number;
  contract: 'rent' | 'purchase' | 'loan';
}

/**
 * Device location data
 */
export interface DeviceLocation {
  /**
   * Type of position
   */
  type: 'CELLIDS' | 'GPS';

  /**
   * Latitude of the position
   */
  latitude: number;

  /**
   * Longitude of the position
   */
  longitude: number;

  /**
   * Timestamp of the position
   */
  timestamp?: Date;

  /** */
  fix?: boolean;

  /**
   * Accuracy of the position
   */
  accuracy?: number;

  /**
   * Timezone of the device
   */
  timezone: string;
}

export interface ValidDeviceLocation extends DeviceLocation {
  latitude: number;
  longitude: number;
  timestamp?: Date;
  fix?: boolean;
  accuracy?: number;
}

interface DeviceLastMeasurementMovement {
  /** */
  measurement: 'mvmt_status';

  /** */
  nb_aggregated: number;

  /**
   * Time of last received movement message.
   */
  time: Date;

  /** */
  time_older: Date;

  /** */
  tags: {
    /** */
    id: number;

    /** */
    imei: number;

    /** */
    status: string;

    /**
     * Is the movemement authorized.
     */
    move_auth: boolean;

    /** */
    changed: boolean;
  };

  /** */
  fields: {
    /** */
    start_time: {
      /**
       * Timestamp of start of movement.
       */
      last: Date;
    };

    /** */
    mvmt_duration: {
      /**
       * Movement duration in seconds.
       */
      last: number;
    };
  };
}

/** */
export type DeviceLastMeasurements = Dictionary<DeviceLastMeasurement>;

/**
 * Device status (gps, 868, etc...)
 */
export interface DeviceStatus {
  value: any;
  timestamp: Date;
  outdated: boolean;
}

export interface DeviceStatusGPS extends DeviceStatus {
  value: boolean;
  state: DeviceStatusGPSStr;
}

export interface DeviceStatusBat extends DeviceStatus {
  value: number;
  state: DeviceStatusBatStr;
}

export interface DeviceStatus868 extends DeviceStatus {
  value: number;
  state: DeviceStatus868Str;
}

export interface DeviceStatusGPRS extends DeviceStatus {
  value: number;
  state: DeviceStatusGPRSStr;
}

export interface BatteryPrice {
  price: number | null;
  reason: 'undefined_battery_type' | 'undefined_battery_contract';
}

const device_868_status_str = strEnum(['868_excellent', '868_good', '868_low', '868_very_low', '868_ko']);
export type DeviceStatus868Str = keyof typeof device_868_status_str;

const device_gps_status_str = strEnum(['gps_ok', 'gps_ko']);
export type DeviceStatusGPSStr = keyof typeof device_gps_status_str;

const device_gprs_status_str = strEnum(['gprs_excellent', 'gprs_good', 'gprs_low', 'gprs_very_low', 'gprs_ko']);
export type DeviceStatusGPRSStr = keyof typeof device_gprs_status_str;

const device_bat_status_str = strEnum(['bat_full', 'bat_half_full', 'bat_half', 'bat_half_empty', 'bat_empty', 'bat_unknown']);
export type DeviceStatusBatStr = keyof typeof device_bat_status_str;

export const device_global_state_str = strEnum(['ok', 'need_check', 'have_issue']);
export type DeviceGlobalStateStr = keyof typeof device_global_state_str;

type DeviceStatusStrI18nSchema = `DEVICE.ALL.GLOBAL_STATUS.${DeviceGlobalStateStr}`;
export const device_global_status_i18n: { [key in DeviceGlobalStateStr]: DeviceStatusStrI18nSchema } = {
  ok: i18n<DeviceStatusStrI18nSchema>('DEVICE.ALL.GLOBAL_STATUS.ok'),
  need_check: i18n<DeviceStatusStrI18nSchema>('DEVICE.ALL.GLOBAL_STATUS.need_check'),
  have_issue: i18n<DeviceStatusStrI18nSchema>('DEVICE.ALL.GLOBAL_STATUS.have_issue'),
};

/** */
export interface DeviceAlarm {
  /** */
  device: DRDevice;

  /** */
  hive?: Hive;

  /** */
  last_movement_status: DeviceLastAlarmStatus;
}

/** */
export interface DeviceLastAlarmStatus {
  /**
   * Date of the last movememnt.
   */
  time: Date;

  /**
   * Last movement status.
   */
  last_status: string;

  /**
   * Is the movement currently authorized.
   */
  is_authorized: boolean;

  /**
   * Date of start of last movement.
   */
  movement_start_time: Date;
}

/**
 * Represents basic informations of BeeGuard devices.
 */
export abstract class DRDevice implements DeviceInterface {
  // #region -> (model basics)

  /**
   * Default temperature used to adjust calibrated voltage.
   */
  public static DEFAULT_TEMPERATURE_IF_INVALID = 20;

  private _update_sub: Subscription = null;
  private _reload_sub: Subscription = null;
  private _auto_status_sub: Subscription = null;

  constructor(protected deviceApi: DeviceApi, params: DeviceQueryParams = {}) {
    this._update_sub = this.update$$.subscribe(() => {
      this._last_msg = null;
      this._location_gps = null;
      this._location_cellids = null;
    });

    this._auto_status_sub = combineLatest([
      this.last_contact$$,
      this.status_868$$,
      this.status_868$$,
      this.status_gprs$$,
      this.status_bat$$,
    ]).subscribe();

    this._reload_sub = this.reload$$
      .pipe(
        tap(() => (this.loading = true)),
        debounceTime(1000),
        switchMap(() => this.deviceApi.requestDevice(this.imei, params)),
        tap(() => (this.loading = false))
      )
      .subscribe(device => {
        Object.assign(this, device);
        this._update$$.next(this);
      });
  }

  public destroy(): void {
    this._update_sub?.unsubscribe();
    this._reload_sub?.unsubscribe();
    this._auto_status_sub?.unsubscribe();
  }

  // #endregion

  // #region -> (other apis)

  /** */
  private _zoho_apis: ZohoApisService;

  /** */
  public set_zoho_apis(_zohoApis: ZohoApisService) {
    this._zoho_apis = _zohoApis;
  }

  // #endregion

  // #region -> (device basics)

  /** */
  private _imei$$ = new BehaviorSubject<number>(null);

  /** */
  public imei$$ = this._imei$$.asObservable().pipe(distinctUntilRealChanged(), replay());

  /** */
  public set imei(imei: number) {
    this._imei$$.next(imei);
  }

  /** */
  public get imei(): number {
    return this._imei$$.getValue();
  }

  // #endregion

  // #region -> (internal update management)

  // Note/TODO: this should be call from async api when device "change"
  /** */
  private _update$$ = new BehaviorSubject<DRDevice>(null); // Note: we do not want an inital value (only deserialize or update)

  /** */
  public update$$ = this._update$$.asObservable().pipe(waitForNotNilValue());

  /** */
  private _reload$$ = new BehaviorSubject<boolean>(null); // Note: we do not want an inital value (only reload when update)

  /** */
  public reload$$ = this._reload$$.asObservable().pipe(waitForNotNilValue());

  /** */
  public force_reload(): void {
    this._reload$$.next(true);
  }

  /**
   * Subjects the device's loading state.
   */
  private _loading$$ = new BehaviorSubject<boolean>(false);

  /**
   * Observes the device's loading state.
   */
  public loading$$ = this._loading$$.asObservable().pipe(distinctUntilRealChanged());

  /**
   * Mutates the device's loading state.
   */
  private set loading(loading: boolean) {
    this._loading$$.next(loading);
  }

  // #endregion

  // #region -> (device loadings management)

  /**
   * List of possible device's loading states.
   */
  public loadings = {
    battery_change: {
      is_adding$$: create_replay_subject_with_first_value(false),
      is_updating$$: create_replay_subject_with_first_value(false),
      is_deleting$$: create_replay_subject_with_first_value(false),
    },
  };

  // #endregion

  // #region -> (device credentials)

  /** */
  public credentials$$ = this.imei$$.pipe(
    waitForNotNilValue(),
    switchMap(imei => this.deviceApi.fetch_device_credentials$(imei)),
    map(response => response?.credentials ?? null),
    map(credentials => {
      if (isNil(credentials)) {
        return null;
      }

      return <BeeLiveCredentials>{
        ApiKeyCredential: credentials.find(credential => credential.type === 'DeviceCredential.ApiKeyCredential'),
        WireGuardCredential: credentials.find(credential => credential.type === 'DeviceCredential.WireGuardCredential'),
      };
    }),
    replay()
  );

  /** */
  public has_credentials$$ = this.credentials$$.pipe(
    map(credentials => !isNil(credentials)),
    replay()
  );

  // #endregion

  // #region -> (device supports)

  /** */
  private _supports$$ = new BehaviorSubject<RawDeviceSupports>(null);

  /** */
  public supports$$ = this._supports$$.asObservable().pipe(
    map(supports => {
      if (isNil(supports) || isEmpty(supports)) {
        return supports;
      }

      supports.newest_open_support_start = !isNil(supports?.newest_open_support_start)
        ? <any>parseDate(supports.newest_open_support_start)
        : null;
      supports.oldest_open_support_start = !isNil(supports?.oldest_open_support_start)
        ? <any>parseDate(supports.oldest_open_support_start)
        : null;
      supports.last_closed_support_end = !isNil(supports?.last_closed_support_end)
        ? <any>parseDate(supports.last_closed_support_end)
        : null;

      return supports;
    })
  );

  public set supports(supports: RawDeviceSupports) {
    this._supports$$.next(supports);
  }

  public get supports(): RawDeviceSupports {
    return this._supports$$.getValue();
  }

  /** */
  public supports_opened$$: Observable<RawDeviceSupports['open']> = this.supports$$.pipe(
    map(supports => supports?.open ?? []),
    switchMap(opened_supports => {
      const a$$ = opened_supports.map((support: DeviceSupportIssue & { ticket_ref?: ZohoDeskTicket }) => {
        support.start_time = !isNil(support?.start_time) ? <any>parseDate(support.start_time) : null;
        support.update_time = !isNil(support?.update_time) ? <any>parseDate(support.update_time) : null;
        support.ticket_ref = null;

        if (!isNil(this._zoho_apis) && !isNil(support?.issue_id)) {
          return this._zoho_apis.desk_api.search_tickets({ id: support.issue_id }).pipe(
            map(fetched_tickets => {
              const fetched_ticket = fetched_tickets?.data?.[0];
              support.ticket_ref = fetched_ticket;

              return support;
            }),
            catchError(() => of(support))
          );
        }

        return of(support);
      });

      return robustCombineLatest(a$$);
    }),
    replay()
  );

  /** */
  public has_type_of_supports$$ = (support_type: DeviceSupportType) =>
    this.supports_opened$$.pipe(
      map(opened_supports => opened_supports.filter(support => support.type === support_type)),
      map(opened_RU_supports => (opened_RU_supports ?? [])?.length > 0),
      replay()
    );

  /** */
  public supports_history$$ = this.imei$$.pipe(
    waitForNotNilValue(),
    switchMap(device_imei => this.deviceApi.fetch_device_supports$(device_imei, 0, 0)),
    replay()
  );

  /** */
  public fetch_supports_history$(start: Date, end: Date) {
    return this.imei$$.pipe(
      waitForNotNilValue(),
      switchMap(imei =>
        this.deviceApi.fetch_device_supports$(imei, -1, 0, {
          _start_time__lte__date: end.toISOString(),
          _start_time__gte__date: start.toISOString(),
        })
      )
    );
  }

  // #endregion

  // Wether if this kind of device CAN be a gateway
  // **WARNING** it may be true even if the device is not configured to transmit sensors
  public is_gateway = false;

  //#region Device unique ID

  sn?: string;
  id_v1?: number;
  comment?: string;

  //#endregion

  // #region -> (creation date)

  private _creation_date$$ = new BehaviorSubject<Date>(null);

  public creation_date$$ = this._creation_date$$.asObservable();

  public set cdate(cdate: string) {
    if (isNil(cdate)) {
      this._creation_date$$.next(null);
    }

    this._creation_date$$.next(parseDate(cdate));
  }

  // #endregion

  // #region -> (device configuration)

  /**
   * Current wrahouse configuration for this device.
   */
  warehouse: WarehouseConfig[] = [];

  /**
   * Current hive configuration for this device.
   *
   * @note This is setted when device is loaded from hive.devices$$.
   */
  hive_config: HiveDeviceConfig;

  // #endregion

  // #region -> (device metadata)

  /** */
  private _metadata$$ = new BehaviorSubject<any>(null);

  /** */
  public metadata$$ = this._metadata$$.pipe(distinctUntilRealChanged(), replay());

  /** */
  public set metadata(metadata: any) {
    this._metadata$$.next(metadata);
  }

  /** */
  public get metadata(): any {
    return this._metadata$$.getValue();
  }

  /** */
  public metadata__unactivated$$: Observable<{ state: boolean }> = this.metadata$$.pipe(
    map(metadata => metadata?.unactivated),
    replay()
  );

  /** */
  public is_activated$$ = this.metadata__unactivated$$.pipe(
    map(metadata_unactivated => {
      if (isNil(metadata_unactivated)) {
        return true;
      }

      if (isNil(metadata_unactivated?.state)) {
        return true;
      }

      return metadata_unactivated?.state === false;
    }),
    replay()
  );

  // #endregion

  last_data: any;
  configuration: any;

  // #region -> (device user ACL)

  /**
   * List of authorizations of the current user for this entity.
   *
   * @deprecated
   */
  private _user_acl: DeviceUserAce[];

  /**
   * Manager for user ACL.
   */
  public user_acl: DeviceUserAclManager = new DeviceUserAclManager();

  /**
   * Check if the current user have the access for a precise scope on this device.
   *
   * @param scope Search for a precise scope.
   */
  public hasACE(scope: string): boolean {
    return indexOf(this._user_acl, <any>scope) >= 0;
  }

  // #endregion

  /**
   * Represents the device name (**warning**: name should be not unique !).
   */
  name: string;

  /**
   * User ID of the device's owner.
   */
  private _owner = new BehaviorSubject<number>(null);
  public owner$$ = this._owner.pipe(distinctUntilRealChanged(), replay());

  set owner(_owner: number) {
    this._owner.next(_owner);
  }

  get owner(): number {
    return this._owner.getValue();
  }

  public get setup_event_name(): string {
    return `device_${this.type}_install`;
  }

  /** */
  private owner_user$$: Observable<User> = null;

  /** */
  public request_owner_user$(users_api: UsersApiService) {
    if (!isNil(this.owner_user$$)) {
      return this.owner_user$$;
    }

    this.owner_user$$ = this.owner$$.pipe(
      distinctUntilRealChanged(),
      switchMap(owner_id => users_api.fetch_user$(owner_id)),
      replay()
    );

    return this.owner_user$$;
  }

  // #region -> (device type management)

  /**
   * Value of the device's type.
   */
  public type: DeviceInterface.TypeEnum;

  /**
   * Value of the device's raw type.
   */
  public type_raw?: string;

  /**
   * Observes the device's type.
   */
  public type$$: Observable<DeviceInterface.TypeEnum | string> = this.update$$.pipe(
    map(() => this.type ?? this.type_raw),
    distinctUntilRealChanged(),
    replay()
  );

  // #endregion

  // #region -> (device last data management)

  /**
   * Dictionnary of device's last measurements.
   */
  public last_measurements: DeviceLastMeasurements;

  /**
   * Observes the device's last measurements.
   */
  public last_measurements$$: Observable<DeviceLastMeasurements> = this.update$$.pipe(
    map(() => this.last_measurements),
    replay()
  );

  /**
   * Value of the device's last measurement message (eihter sensor_message or gateway_message)
   */
  private _last_msg: DeviceLastMeasurement = null;

  /**
   * Gets the device's last message.
   * @deprecated
   */
  public get last_msg(): DeviceLastMeasurement {
    if (!isNil(this._last_msg)) {
      return this._last_msg;
    }

    const last_measurements = this.last_measurements;

    let msg: DeviceLastMeasurement = null;

    if (!isNil(last_measurements?.message)) {
      msg = last_measurements.message;
    } else if (!isNil(last_measurements?.gateway_message)) {
      msg = last_measurements.gateway_message;
    } else if (!isNil(last_measurements?.sensor_message)) {
      msg = last_measurements.sensor_message;
    }

    if (msg) {
      msg.time = parseDate(msg.time);
    }

    this._last_msg = msg;
    return this._last_msg;
  }

  public last_msg$$: Observable<DeviceLastMeasurement> = this.last_measurements$$.pipe(
    map(last_measurements => {
      let msg: DeviceLastMeasurement = null;

      if (!isNil(last_measurements?.message)) {
        msg = last_measurements.message;
      } else if (!isNil(last_measurements?.gateway_message)) {
        msg = last_measurements.gateway_message;
      } else if (!isNil(last_measurements?.sensor_message)) {
        msg = last_measurements.sensor_message;
      }

      if (msg) {
        msg.time = parseDate(msg.time);
      }

      return msg;
    }),
    tap(msg => (this._last_msg = msg)),
    replay()
  );

  /**
   * Gets the device's last contact date.
   * @see last_contact$$ to observe the device's last contact date.
   *
   * @returns Returns the device's last contact date.
   */
  public get last_contact(): Date {
    const last_msg = this.last_msg;

    if (last_msg) {
      return last_msg.time;
    }

    return null;
  }

  /**
   * Observes the device's last contact date.
   *
   * @returns
   */
  public last_contact$$: Observable<Date> = this.last_msg$$.pipe(
    map(last_msg => last_msg?.time),
    distinctUntilRealChanged(),
    replay()
  );

    /**
   * Observes the device's last origin.
   *
   * @returns Returns the device's last origin.
   */
    public last_origin$$: Observable<string> = this.last_msg$$.pipe(
      map(last_msg => last_msg?.tags?.origin),
      distinctUntilRealChanged(),
      replay(),
    );

    /** */
    public last_network_activity_time$$ = this.last_measurements$$.pipe(
      map(last_measurements => last_measurements?.network_activity?.time),
      map(last_network_activity_time => {
        if (isNil(last_network_activity_time)) {
          return null;
        }
  
        return parseDate(last_network_activity_time);
      }),
      distinctUntilRealChanged(),
      replay(),
    );

  /** */
  public has_last_contact$$ = this.last_contact$$.pipe(
    map(last_contact => !isNil(last_contact)),
    replay()
  );

  /**
   * Gets device's last contact in days from now.
   * @see last_contact_in_days$ to observe the device's last contact in days from now.
   *
   * @returns
   */
  public get last_contact_in_days(): number {
    if (isNil(this.last_contact)) {
      return null;
    }

    return differenceInDays(new Date(), this.last_contact);
  }

  /**
   * Observes the device's last contact in days from now.
   * @todo Make auto-update dependent on the current date.
   *
   * @returns
   */
  public last_contact_in_days$ = this.last_contact$$.pipe(
    map(last_contact => {
      if (isNil(last_contact)) {
        return null;
      }

      return differenceInDays(new Date(), this.last_contact);
    })
  );

  // #endregion

  // #region -> (device versions)

  /**
   * Gets device's hardware version.
   * @see hwv$$ to observe the device's hardware version.
   *
   * @returns
   */
  public get hwv(): number {
    const last_environment = this.last_env;

    if (isNil(last_environment)) {
      return null;
    }

    return last_environment?.tags?.hardware_version || null;
  }

  /**
   * Observes the device's hardware version.
   *
   * @returns
   */
  public hwv$$: Observable<number> = this.last_msg$$.pipe(
    map(msg => msg?.tags?.hardware_version),
    distinctUntilRealChanged(),
    replay()
  );

  /**
   * Gets device's software version.
   * @see swv$$ to observe the device's software version.
   *
   * @returns
   */
  public get swv(): number {
    const last_environment = this.last_env;

    if (isNil(last_environment)) {
      return null;
    }

    return last_environment?.tags?.software_version || null;
  }

  /**
   * Observes the device's software version.
   *
   * @returns
   */
  public swv$$: Observable<number> = this.last_msg$$.pipe(
    map(msg => msg?.tags?.software_version),
    distinctUntilRealChanged(),
    replay()
  );

  /**
   * Observes the device's software major version.
   */
  public swv_minor$$ = this.last_measurements$$.pipe(
    map(last_measurements => last_measurements?.gateway_message ?? null),
    map(gateway_message => gateway_message?.tags?.software_version_minor),
    distinctUntilRealChanged(),
    replay()
  );

  /** */
  public modem_version$$ = this.last_measurements$$.pipe(
    map(last_measurements => last_measurements?.gateway_message),
    map(message => message?.tags?.modem_version),
    distinctUntilRealChanged(),
    replay()
  );

  /**
   * Observes if the device management is made in v1.
   *
   * @returns
   */
  public is_mngt_v1$$ = this.type$$.pipe(
    switchMap(type => {
      if (type === 'GPS') {
        return combineLatest([this.comment$$, this.swv$$, this.hwv$$]).pipe(
          debounceTime(40),
          map(([comment, swv, hwv]) => {
            if (comment?.startsWith('config_v2')) {
              return false;
            } else if (hwv && hwv < 10 && swv && swv >= 40) {
              return false;
            } else if (this.id_v1 && hwv < 10) {
              return true;
            } else {
              return false;
            }
          })
        );
      }

      return of(false);
    }),
    distinctUntilRealChanged(),
    replay()
  );

  // #endregion

  // #region -> (communication tech and infos)

  /**
   * ICCID of the sim on the device.
   */
  public iccid$$ = this.last_measurements$$.pipe(
    map(last_measurements => last_measurements?.iccid),
    map(iccid_measurement => iccid_measurement?.fields?.iccid?.last),
    map((iccid: string) => {
      if (!isNil(iccid) && !isEmpty(iccid) && iccid?.endsWith('0000')) {
        return iccid?.slice(0, -4);
      }

      return iccid;
    }),
    distinctUntilRealChanged(),
    replay()
  );

  /** */
  protected abstract get_com_technology$$(): Observable<DeviceCommunicationTechnology[]>;

  /** */
  public com_technology$$: Observable<DeviceCommunicationTechnology[]> = this.update$$.pipe(
    switchMap(device => device.get_com_technology$$()),
    distinctUntilRealChanged(),
    replay()
  );

  // #endregion

  public warehouse_id$$ = this.update$$.pipe(
    map(() => this.warehouse_id),
    distinctUntilRealChanged(),
    replay()
  );

  public comment$$ = this.update$$.pipe(
    map(() => this.comment),
    distinctUntilRealChanged(),
    replay()
  );

  public gateway_type$$: Observable<string | null> = this.last_msg$$.pipe(
    map(message => message?.tags?.gateway_type || null),
    distinctUntilRealChanged(),
    replay()
  );

  /**
   * Deserialize a generic `Device` into a `DRDevice`.
   *
   * @param input `Device` swagger class object.
   */
  deserialize(input: DeviceInterface, fields?: (keyof typeof DRDevice.prototype)[]): DRDevice {
    if (!isNil(fields) && !isEmpty(fields)) {
      fields.forEach(field => {
        const value: any = (<any>input)[field];
        (<any>this)[field] = value;
      });
    } else {
      Object.keys(input).map(key => {
        const value: any = (<any>input)[key];
        (<any>this)[key] = value;
      });
    }

    this.user_acl.update_acl((input as any)._user_acl, input?.name);

    this._update$$.next(this);

    return this;
  }

  // #region -> (device position)

  protected _location: DeviceLocation = null;

  public get location(): DeviceLocation {
    if (isNil(this._location)) {
      if (this.location_gps) {
        this._location = this.location_gps;
      } else {
        this._location = this.location_cellids;
      }
    }

    return this._location;
  }

  public get distance_gps_cellid(): number {
    if (isNil(this.location_gps) || isNil(this.location_cellids)) {
      return null;
    }

    return getDistance(
      {
        latitude: this.location_gps.latitude,
        longitude: this.location_gps.longitude,
      },
      {
        latitude: this.location_cellids.latitude,
        longitude: this.location_cellids.longitude,
      }
    );
  }

  protected _location_gps: DeviceLocation = null;

  /**
   * Gets device's GPS current location.
   *
   * @returns Returns device's GPS current location.
   */
  public get location_gps(): DeviceLocation {
    // Par default p
    // Si GPS: on prend dernier mouvement
    // si RG/WG on prend le dernier avec FIX
    const location_meas = this.last_measurements?.location_AGG_GPS || {};
    const measurement_fields = location_meas?.fields;

    if (isNil(this._location_gps) && !isNil(measurement_fields) && !isNil(measurement_fields?.gps_lat?.last)) {
      const location: DeviceLocation = {
        type: 'GPS',
        timezone: 'Europe/Paris',
        latitude: measurement_fields.gps_lat?.last,
        timestamp: parseDate(location_meas.time),
        longitude: measurement_fields.gps_lng?.last,
      };

      location.fix = measurement_fields.gps_fix?.last === 'FIX';
      location.accuracy = measurement_fields.gps_accuracy?.last;

      if (!isNil(location?.latitude) && !isNil(location?.longitude)) {
        location.timezone = tzLookup(location?.latitude, location?.longitude);
      }

      this._location_gps = location;
    }

    return this._location_gps;
  }

  protected _location_cellids: DeviceLocation = null;

  /**
   * Gets device last cellid location (None if GPS one is more recent)
   *
   * @returns Returns last know cellid location
   */
  public get location_cellids(): DeviceLocation {
    // si RG/WG on prend le dernier avec FIX
    const location_meas_gps = this.last_measurements?.location_AGG_GPS || {};
    const location_meas_all = this.last_measurements?.location || {};

    if (isNil(this._location_cellids) && (location_meas_all?.fields?.gps_lat || location_meas_all?.fields?.cell_ids_lat)) {
      let has_gps = location_meas_all?.fields?.gps_fix;
      has_gps = has_gps && location_meas_all?.fields?.gps_fix?.last === 'FIX';
      has_gps = has_gps && location_meas_all?.time === location_meas_gps?.time;

      if (!has_gps && location_meas_all.fields?.cell_ids_lat && location_meas_all.fields?.cell_ids_accuracy) {
        const location: DeviceLocation = {
          type: 'CELLIDS',
          timezone: 'Europe/Paris',
          timestamp: parseDate(location_meas_all.time),
          latitude: location_meas_all.fields.cell_ids_lat.last,
          longitude: location_meas_all.fields.cell_ids_lng.last,
        };

        location.accuracy = location_meas_all.fields.cell_ids_accuracy.last;

        if (!isNil(location?.latitude) && !isNil(location?.longitude)) {
          location.timezone = tzLookup(location?.latitude, location?.longitude);
        }

        this._location_cellids = location;
      }
    }

    return this._location_cellids;
  }

  get location_timestamp(): Date {
    return (this.location_gps || {}).timestamp || (this.location_cellids || {}).timestamp;
  }

  /**
   * Device's current position.
   *
   * @remarks
   * Use this observable only to display position related data. To diplay charts data, prefer {@link geoposition_robust$$}.
   */
  public geoposition$$ = this.update$$.pipe(
    map(() => this.location),
    replay()
  );

  /**
   * Device's position with defaults.
   *
   * @remarks
   * Use this observable only to display device data charts. For another use, prefer {@link geoposition$$}.
   */
  public geoposition_robust$$ = this.geoposition$$.pipe(
    map(geoposition => {
      if (isNil(geoposition) || isEmpty(geoposition) || isNil(geoposition?.latitude) || isNil(geoposition?.longitude)) {
        const local_timezone = geoposition?.timezone ?? DateTime.now()?.zoneName;
        const estimated_timezone_data = timezones_data.find(tz_data => tz_data.tz_identifier === local_timezone);

        let robust_position: DeviceLocation = {
          type: 'GPS',
          timezone: local_timezone,
          latitude: geoposition?.latitude ?? estimated_timezone_data?.latitude ?? null,
          longitude: geoposition?.latitude ?? estimated_timezone_data?.longitude ?? null,
        };

        console.warn(
          `device#${this.imei} : using local timezone "${local_timezone}" at position [lat:${robust_position?.latitude}, lng:${robust_position?.longitude}]`
        );

        return robust_position;
      }

      return geoposition;
    }),
    replay()
  );

  /** */
  public timezone$$ = this.geoposition_robust$$.pipe(
    map(geoposition => geoposition?.timezone),
    replay()
  );

  /**
   * Observes if the device have a geoposition.
   *
   * @public
   * @observable
   */
  public has_geoposition$$ = this.geoposition$$.pipe(
    map(geoposition => !isNil(geoposition)),
    replay()
  );

  /** */
  public geoposition_cellids$$ = this.update$$.pipe(
    map(() => this.location_cellids),
    replay()
  );

  /** */
  public has_geoposition_cellids$$ = this.geoposition_cellids$$.pipe(
    map(geoposition => !isNil(geoposition?.latitude)),
    replay()
  );

  /** */
  public geoposition_gps$$ = this.update$$.pipe(
    map(() => this.location_gps),
    replay()
  );

  /** */
  public has_geoposition_gps$$ = this.geoposition_gps$$.pipe(
    map(geoposition => !isNil(geoposition?.latitude)),
    replay()
  );

  /** */
  public geoposition_elevation$$ = this.last_msg$$.pipe(
    map(last_msg => last_msg?.fields?.gateway_altitude?.last ?? null),
    distinctUntilRealChanged(),
    replay()
  );

  /** */
  public geoposition_latitude$$ = this.geoposition$$.pipe(
    map(geoposition => geoposition?.latitude ?? null),
    replay()
  );

  /** */
  public geoposition_quality$$ = this.geoposition$$.pipe(
    map(geoposition => {
      if (isNil(geoposition?.timestamp)) {
        return null;
      }

      const diff = differenceInDays(new Date(), geoposition?.timestamp);

      if (diff >= 23) {
        return false;
      }

      return true;
    }),
    replay()
  );

  // #endregion

  // #region -> (device last environment data)

  protected _last_env: DeviceLastMeasurement = null;

  public get last_env(): DeviceLastMeasurement {
    if (isNil(this._last_env)) {
      const last_measurements = this.last_measurements;

      if (isNil(last_measurements)) {
        this._last_env = null;
        return this._last_env;
      } else {
        const env_measurement = last_measurements.env;

        if (isNil(env_measurement)) {
          this._last_env = null;
        } else {
          env_measurement.time = parseDate(env_measurement.time);
          this._last_env = env_measurement;
        }
      }
    }

    return this._last_env;
  }

  /**
   * Observes the device last environment data.
   */
  public last_env$$ = this.update$$.pipe(
    map(device => device.last_env),
    replay()
  );

  /** */
  public last_temperature$$ = this.last_env$$.pipe(
    map(last_env => {
      const temperature_field: { minimum: number; maximum: number; last: number } = last_env?.fields?.temperature ?? null;
      const exists = !isNil(temperature_field);

      return {
        exists,
        value: temperature_field,
      };
    }),
    replay()
  );

  // #endregion

  /**
   * Gets last power on reset date.
   *
   * @returns Returns date of last power on reset.
   */
  protected _last_reset_power_on: DeviceLastMeasurement = null;
  get last_power_on(): Date {
    if (isNil(this._last_reset_power_on)) {
      const msg: DeviceLastMeasurement = this.last_measurements?.reset_cause_AGG_power_on || null;
      if (msg) {
        msg.time = parseDate(msg.time);
      }
      this._last_reset_power_on = msg;
    }
    return this._last_reset_power_on ? this._last_reset_power_on.time : null;
  }

  public last_power_on$$: Observable<Date> = this.last_measurements$$.pipe(
    map(last_measurements => {
      const msg: DeviceLastMeasurement = last_measurements?.reset_cause_AGG_power_on || null;

      if (isNil(msg?.time)) {
        return null;
      }

      return parseDate(msg.time);
    }),
    distinctUntilRealChanged(),
    replay()
  );

  // #region -> battery

  /**
   * Gets battery type of the device.
   *
   * @returns
   */
  public get battery_type(): DEVICE_BATTERY_TYPE {
    return <any>this.bat?.model;
  }

  public get battery_price(): BatteryPrice {
    const battery_type = this.battery_type;
    const battery_price = { price: null, reason: null } as BatteryPrice;

    if (isNil(battery_type)) {
      battery_price.reason = 'undefined_battery_type';
      return battery_price;
    }

    const contract_type = this.warehouse_conf?.contract || null;

    if (isNil(contract_type)) {
      battery_price.reason = 'undefined_battery_contract';
      return battery_price;
    }

    if (contract_type === 'rent' || contract_type === 'loan') {
      battery_price.price = 0;
      return battery_price;
    }

    battery_price.price = device_battery_price[battery_type];
    return battery_price;
  }

  /**
   * Gets last battery change date.
   *
   * @returns Returns the date when the last battery was changed.
   */
  public get last_battery_change(): Date {
    if (this.battery_changes.length === 0) {
      return null;
    }

    return new Date(this.battery_changes.reduce((prev: BatChange, next: BatChange) => (prev.time > next.time ? prev : next)).time);
  }

  /** */
  private _battery_info$$ = new BehaviorSubject<DeviceBatInfo>(null);

  /** */
  public bat$$ = this._battery_info$$.asObservable().pipe(
    switchMap(battery_informations => {
      if (isNil(battery_informations)) {
        return of(battery_informations);
      }

      // Override battery type
      let battery_type = (battery_informations?.model ?? null) as DEVICE_BATTERY_TYPE;
      if (isNil(battery_type)) {
        return this.compute_battery_type$$().pipe(
          map(local_battery_type => {
            if (!isNil(battery_type) || battery_type !== DEVICE_BATTERY_TYPE.unknown) {
              this.is_battery_computed_in_local = true;
            }

            battery_informations.model = local_battery_type ?? null;
            return battery_informations;
          })
        );
      }

      return of(battery_informations);
    }),
    distinctUntilRealChanged(),
    replay()
  );

  /** */
  public battery_type$$: Observable<DEVICE_BATTERY_TYPE> = this.bat$$.pipe(
    map(device_battery_info => <any>device_battery_info?.model ?? null),
    replay()
  );

  /** */
  protected abstract compute_battery_type$$(): Observable<DEVICE_BATTERY_TYPE>;

  /**
   * Gets the device's current battery information.
   *
   * @returns Returns device's current battery information.
   */
  public get bat(): DeviceBatInfo {
    return this._battery_info$$.getValue();
  }

  /**
   * Mutates the device's current battery information.
   */
  public set bat(val: DeviceBatInfo) {
    if (isNil(val)) {
      this._battery_info$$.next(val);
      return;
    }

    // // Override battery type
    // let battery_type = val?.model as DEVICE_BATTERY_TYPE;
    // if (isNil(battery_type)) {
    //   battery_type = this.compute_battery_type();

    //   if (!isNil(battery_type) || battery_type !== DEVICE_BATTERY_TYPE.unknown) {
    //     this.is_battery_computed_in_local = true;
    //   }
    // }

    // battery_type = battery_type ?? null;
    // val.model = battery_type;

    this._battery_info$$.next(val);
  }

  public is_battery_computed_in_local = false;

  /**
   * Gets the device's battery changes.
   * @see bat_changes$$ to observe the device's battery changes.
   *
   * @returns Returns a list of device's battery changes.
   */
  public get battery_changes(): BatChange[] {
    return this.bat?.changes || [];
  }

  /**
   * Observes the device's battery changes.
   *
   * @returns Returns an observable of the device's battery changes.
   */
  public bat_changes$$: Observable<BatChange[]> = this.bat$$.pipe(
    map(bat => bat?.changes || []),
    map(bat_changes =>
      bat_changes.map(bat_ch => {
        bat_ch.time = parseDate(bat_ch.time);
        return bat_ch;
      })
    ),
    map(bat_changes => sortBy(bat_changes, 'time')),
    map(bat_changes => reverse(bat_changes)),
    distinctUntilRealChanged(),
    replay()
  );

  /** */
  public last_battery_change$$ = this.bat_changes$$.pipe(
    map(changes => {
      if ((changes ?? []).length === 0) {
        return null;
      }

      return changes[0];
    })
  );

  /** */
  protected get_battery_noload_voltage(last_measurements: DeviceLastMeasurements): number | number[] {
    return null;
  }

  /** */
  public battery_noload_voltage$$: Observable<number | number[]> = this.last_measurements$$.pipe(
    map(last_measurements => this.get_battery_noload_voltage(last_measurements)),
    distinctUntilRealChanged()
  );

  /** */
  protected get_battery_com_voltage(last_measurements: DeviceLastMeasurements): number | number[] {
    return null;
  }

  /** */
  public battery_com_voltage$$: Observable<number | number[]> = this.last_measurements$$.pipe(
    map(last_measurements => this.get_battery_com_voltage(last_measurements)),
    distinctUntilRealChanged()
  );

  /**
   * Observes the last battery voltage of the device.
   *
   * @note The priority is the following : com_voltage, noload_voltage
   */
  public last_battery_voltage$$: Observable<number> = combineLatest({
    com_voltage: this.battery_com_voltage$$,
    noload_voltage: this.battery_noload_voltage$$,
  }).pipe(
    map(({ com_voltage, noload_voltage }) => {
      if (!isNil(com_voltage)) {
        if (isArray(com_voltage)) {
          const voltages_above_2_volts = com_voltage.filter(voltage => voltage > 2);
          const lowest_voltage = min(voltages_above_2_volts);

          if (voltages_above_2_volts.length === 0) {
            return min(com_voltage);
          }

          return lowest_voltage;
        }

        return com_voltage;
      }

      if (isArray(noload_voltage)) {
        const voltages_above_2_volts = noload_voltage.filter(voltage => voltage > 2);
        const lowest_voltage = min(voltages_above_2_volts);

        return lowest_voltage;
      }

      return noload_voltage;
    }),
    replay()
  );

  /** */
  protected fix_last_battery_voltage(last_temperature: number): number {
    return last_temperature;
  }

  /** */
  public last_battery_voltage_calibrated$$ = this.last_battery_voltage$$.pipe(
    switchMap(last_battery_voltage => {
      if (isNil(last_battery_voltage)) {
        return of(null);
      }

      return this.last_temperature$$.pipe(
        map(last_temperature => {
          const last_temperature_value = this.fix_last_battery_voltage(last_temperature?.value?.last);
          const formula = last_battery_voltage - 0.01 * ((last_temperature_value ?? DRDevice.DEFAULT_TEMPERATURE_IF_INVALID) - 20);

          return formula;
        })
      );
    }),
    replay()
  );

  /** */
  protected fix_sparkline_timeseries(timeseries: any[]): any[] {
    return timeseries;
  }

  /** */
  public battery_sparkline_last_two_years$$ = this.timezone$$.pipe(
    concatMap(timezone => {
      let end = startOfTomorrowLuxon(timezone);
      let start = end.minus({ years: 2 });

      const first_part = this.requestTimeseries(['vbat', 'temperature'], start.toJSDate(), end.minus({ months: 1 }).toJSDate(), '7d');
      const second_part = this.requestTimeseries(
        ['vbat', 'temperature'],
        end.minus({ months: 1 }).plus({ seconds: 1 }).toJSDate(),
        end.plus({ minutes: 30 }).toJSDate(),
        '1d'
      );

      return forkJoin({
        first_part,
        second_part,
      }).pipe(
        map(response => {
          const timeseries_data = [...(response?.first_part?.timeseries?.data ?? []), ...(response?.second_part?.timeseries?.data ?? [])];
          return timeseries_data;
        }),
        map(timeseries_data => this.fix_sparkline_timeseries(timeseries_data)),
        switchMap(timeseries_data =>
          this.bat_changes$$.pipe(
            map(battery_changes => {
              const start_date = start;
              const end_date = end;

              const battery_changes_to_keep = battery_changes.filter(
                battery_changed =>
                  battery_changed?.time.getTime() >= start_date.toMillis() && battery_changed?.time.getTime() <= end_date.toMillis()
              );

              return <BatterySparklineData>{
                end_date: end,
                start_date: start,
                battery_changes: battery_changes_to_keep,
                battery_voltages: timeseries_data ?? [],
              };
            })
          )
        )
      );
    }),
    replay()
  );

  /**
   * Device typical voltage range
   *
   * This is override for each device type
   */
  public get_battery_std_voltage_range$$(): Observable<[number, number]> {
    return of([1.0, 4]);
  }

  /** */
  public battery_range_vbat$$ = this.update$$.pipe(
    switchMap(device => device.get_battery_std_voltage_range$$()),
    distinctUntilRealChanged(),
    replay()
  );

  /** */
  protected abstract get_battery_critical_vbat$$(): Observable<number>;

  /** */
  public battery_critical_vbat$$ = this.update$$.pipe(
    switchMap(device => device.get_battery_critical_vbat$$()),
    distinctUntilRealChanged(),
    replay()
  );

  // /** */
  // protected _get_default_battery_simplified_state$$: Observable<{
  //   state: DEVICE_SIMPLIFIED_BATTERY_STATE;
  //   reason: DEVICE_SIMPLIFIED_BATTERY_STATE_REASON;
  // }> = this.last_contact$$.pipe(
  //   map(last_contact => {
  //     if (isNil(last_contact)) {
  //       return null;
  //     }

  //     const last_contact_in_days = Math.abs(differenceInDays(new Date(), last_contact));

  //     if (last_contact_in_days >= 7) {
  //       return {
  //         state: DEVICE_SIMPLIFIED_BATTERY_STATE.OUTDATED,
  //         reason: DEVICE_SIMPLIFIED_BATTERY_STATE_REASON.NO_CONTACT_SINCE_7_DAYS,
  //       };
  //     }

  //     return null;
  //   }),
  //   switchMap(status => {
  //     if (!isNil(status)) {
  //       return of(status);
  //     }

  //     return combineLatest({
  //       critical_vbat: this.battery_critical_vbat$$,
  //       last_battery_voltage: this.last_battery_voltage_calibrated$$,
  //     }).pipe(
  //       map(({ last_battery_voltage, critical_vbat }) => {
  //         if (isNil(last_battery_voltage)) {
  //           return {
  //             state: DEVICE_SIMPLIFIED_BATTERY_STATE.UNKNOWN,
  //             reason: DEVICE_SIMPLIFIED_BATTERY_STATE_REASON.UNKNOWN,
  //           };
  //         }

  //         if (last_battery_voltage <= critical_vbat) {
  //           return {
  //             state: DEVICE_SIMPLIFIED_BATTERY_STATE.NOT_OK,
  //             reason: DEVICE_SIMPLIFIED_BATTERY_STATE_REASON.BATTERY_VOLTAGE_UNDER_CRITICAL_LIMIT,
  //           };
  //         }

  //         const limit_value = critical_vbat * 0.03;
  //         const warning_limit = critical_vbat + limit_value;

  //         if (last_battery_voltage <= warning_limit) {
  //           return {
  //             state: DEVICE_SIMPLIFIED_BATTERY_STATE.OK,
  //             reason: null,
  //           };
  //         }

  //         return null;
  //       })
  //     );
  //   })
  // );

  /** */
  protected _get_default_battery_simplified_state$$: Observable<{
    state: DEVICE_SIMPLIFIED_BATTERY_STATE;
    reason: DEVICE_SIMPLIFIED_BATTERY_STATE_REASON;
  }> = combineLatest({
    last_contact: this.last_contact$$,
    critical_vbat: this.battery_critical_vbat$$,
    last_calibrated_vbat: this.last_battery_voltage_calibrated$$,
  }).pipe(
    map(({ last_contact, critical_vbat, last_calibrated_vbat }) => {
      if (isNil(last_calibrated_vbat)) {
        return {
          state: DEVICE_SIMPLIFIED_BATTERY_STATE.UNKNOWN,
          reason: DEVICE_SIMPLIFIED_BATTERY_STATE_REASON.UNKNOWN,
        };
      }

      if (last_calibrated_vbat <= critical_vbat) {
        return {
          state: DEVICE_SIMPLIFIED_BATTERY_STATE.NOT_OK,
          reason: DEVICE_SIMPLIFIED_BATTERY_STATE_REASON.BATTERY_VOLTAGE_UNDER_CRITICAL_LIMIT,
        };
      }

      if (!isNil(last_contact)) {
        const last_contact_in_days = Math.abs(differenceInDays(new Date(), last_contact));

        if (last_contact_in_days >= 7) {
          return {
            state: DEVICE_SIMPLIFIED_BATTERY_STATE.OUTDATED,
            reason: DEVICE_SIMPLIFIED_BATTERY_STATE_REASON.NO_CONTACT_SINCE_7_DAYS,
          };
        }
      }

      const limit_value = critical_vbat * 0.03;
      const warning_limit = critical_vbat + limit_value;

      if (last_calibrated_vbat <= warning_limit) {
        return {
          state: DEVICE_SIMPLIFIED_BATTERY_STATE.OK,
          reason: null,
        };
      }

      return null;
    }),
    replay()
  );

  /** */
  public get_battery_simplified_state$$(): Observable<DeviceSimplifiedBatteryState> {
    return this._get_default_battery_simplified_state$$;
  }

  /** */
  public battery_simplified_state$$ = this.update$$.pipe(
    switchMap(device => device.get_battery_simplified_state$$()),
    distinctUntilRealChanged(),
    replay()
  );

  // #endregion

  get at_this_position_since(): Date {
    // TODO: this may be improved with:
    // - date for last RF2 for GPS
    // - OR better location measureemnt aggregation for all device: aggregate new location if different from previous (more then 200m)
    return this.location_timestamp;
  }

  /**
   * Check if device has precise position.
   *
   * @returns Returns `true` if device has precise position else `false`.
   */
  public hasPrecisePosition(): boolean {
    if (!this.location) {
      return;
    }
    if (!this.location.accuracy) {
      return;
    }
    return this.location.accuracy <= 1000;
  }

  get gateway_type(): string | null {
    return this.last_msg?.tags?.gateway_type || null;
  }

  /**
   * Gets generation of the device.
   *
   * @returns Returns the generation of the device.
   */
  protected getGeneration(swv: number, hwv: number, gateway_type: string): number {
    return null;
  }

  /**
   * Observes the device generation.
   *
   * @public
   */
  public generation$$ = combineLatest({ swv: this.swv$$, hwv: this.hwv$$, gateway_type: this.gateway_type$$ }).pipe(
    map(({ swv, hwv, gateway_type }) => this.getGeneration(swv, hwv, gateway_type)),
    replay()
  );

  /**
   * Gets router link to short configuration modal.
   */
  public config_modal$$ = this.imei$$.pipe(
    map(device_imei => ['device_config', { imei: device_imei }]),
    replay()
  );

  /**
   * Gets router link to full configuration modal.
   */
  public full_config_modal$$ = this.imei$$.pipe(
    map(device_imei => ['device_config', { imei: device_imei, full: true }]),
    replay()
  );

  /**
   * Gets last exploitation affectation date.
   *
   * @returns Returns date of last affectation.
   */
  get last_affectation(): Date {
    const last_affect = this.warehouse[0]?.timestamp;
    if (last_affect) {
      return last_affect;
    }
    return null;
  }

  /**
   * Gets url of device for v1.
   *
   * @returns Returns url of device for v1.
   */
  get url_v1(): string {
    return `${environment.Beeguard1Url}/index.php/device/${this.id_v1}`;
  }

  get gps_accuracy(): number {
    return null;
  }

  get has_outdated_gprs(): { outdated: boolean; since: Date } {
    return {
      outdated: this.status_gprs?.outdated || false,
      since: this.status_gprs?.timestamp || null,
    };
  }

  get has_outdated_gps(): { outdated: boolean; since: Date } {
    return {
      outdated: this.status_gps?.outdated || false,
      since: this.status_gps?.timestamp || null,
    };
  }

  get has_outdated_868(): { outdated: boolean; since: Date } {
    return {
      outdated: this.status_868?.outdated || false,
      since: this.status_868?.timestamp || null,
    };
  }

  get has_outdated_battery_level(): { outdated: boolean; since: Date } {
    return {
      outdated: this.status_bat?.outdated || false,
      since: this.status_bat?.timestamp || null,
    };
  }

  get has_outdated_com(): { outdated: boolean; since: Date } {
    return {
      outdated: this.has_outdated_868.outdated || this.has_outdated_gprs.outdated || this.has_outdated_gps.outdated,
      since: max([this.has_outdated_868.since, this.has_outdated_gprs.since, this.has_outdated_gps.since].filter(date => !isNil(date))),
    };
  }

  /** */
  public has_outdated_com$$: Observable<{ since: Date; outdated: boolean }> = this.update$$.pipe(
    map(device => device.has_outdated_com),
    distinctUntilRealChanged(),
    replay()
  );

  get has_outdated_property(): { outdated: boolean; since: Date } {
    return {
      outdated:
        this.has_outdated_868.outdated ||
        this.has_outdated_battery_level.outdated ||
        this.has_outdated_gprs.outdated ||
        this.has_outdated_gps.outdated,
      since: max(
        [
          this.has_outdated_868.since,
          this.has_outdated_battery_level.since,
          this.has_outdated_gprs.since,
          this.has_outdated_gps.since,
        ].filter(date => !isNil(date))
      ),
    };
  }

  // #region -> (GPRS status)

  public get status_gprs(): DeviceStatusGPRS {
    return null;
  }

  public status_gprs$$: Observable<DeviceStatusGPRS> = this.update$$.pipe(
    map(device => device.status_gprs),
    distinctUntilRealChanged(),
    replay()
  );

  public status_gprs_observation_state$$: Observable<DeviceGlobalStateStr> = this.status_gprs$$.pipe(
    map(status_gprs => compute_gprs_observation_state(status_gprs)),
    distinctUntilRealChanged()
  );

  /** */
  public gprs_sparkline_last_two_years$$ = this.timezone$$.pipe(
    concatMap(timezone => {
      let end = startOfTomorrowLuxon(timezone);
      let start = end.minus({ years: 2 });

      return this.requestTimeseries(['rssi_gprs'], start.toJSDate(), end.plus({ minutes: 30 }).toJSDate(), '2d').pipe(
        map(
          response =>
            <DeviceGPRSSparklineData>{
              end_date: end,
              start_date: start,
              gprs_levels: response?.timeseries?.data ?? [],
            }
        )
      );
    }),
    replay()
  );

  // #endregion

  // #region -> (GPS status)

  public get status_gps(): DeviceStatusGPS {
    return null;
  }

  public status_gps$$: Observable<DeviceStatusGPS> = this.update$$.pipe(
    map(device => device.status_gps),
    distinctUntilRealChanged(),
    replay()
  );

  public status_gps_observation_state$$: Observable<DeviceGlobalStateStr> = this.status_gps$$.pipe(
    map(status_gps => compute_gps_observation_state(status_gps)),
    distinctUntilRealChanged()
  );

  // #endregion

  // #region -> (s868 status)

  public get status_868(): DeviceStatus868 {
    return null;
  }

  public status_868$$: Observable<DeviceStatus868> = this.update$$.pipe(
    map(device => device.status_868),
    distinctUntilRealChanged(),
    replay()
  );

  public status_868_observation_state$$: Observable<DeviceGlobalStateStr> = this.status_868$$.pipe(
    map(status_868 => compute_868_observation_state(status_868)),
    distinctUntilRealChanged()
  );

  /** */
  public s868_sparkline_last_two_years$$ = this.timezone$$.pipe(
    concatMap(timezone => {
      let end = startOfTomorrowLuxon(timezone);
      let start = end.minus({ years: 2 });

      return this.requestTimeseries(['rssi_868'], start.toJSDate(), end.plus({ minutes: 30 }).toJSDate(), '2d').pipe(
        map(
          response =>
            <Device868SparklineData>{
              end_date: end,
              start_date: start,
              values: response?.timeseries?.data ?? [],
            }
        )
      );
    }),
    replay()
  );

  // #endregion

  // #region -> (battery status)

  public get status_bat(): DeviceStatusBat {
    const bat_info = this.bat;

    if (isNil(bat_info)) {
      return null;
    }

    const bat_state = bat_info.state;

    if (isNil(bat_state)) {
      return null;
    }

    const value = bat_state.level;
    let timestamp = parseDate(bat_state.last_update);

    // TODO: Remove this tmp. workaround (bug: wrong "last_update" prop in bat model)
    const last_contact_time = this.last_contact;

    if (!isNil(last_contact_time)) {
      timestamp = isAfter(last_contact_time, timestamp) ? last_contact_time : timestamp;
    }

    const outdated = differenceInDays(new Date(), timestamp) >= 7;

    const prebuilt_status: DeviceStatus = { timestamp, value, outdated };
    return { state: compute_bat_state(prebuilt_status), ...prebuilt_status };
  }

  /**
   * Get the status of the battery for this device (estimated level, outdated?).
   *
   * @returns Returns current battery status.
   */
  public status_bat$$: Observable<DeviceStatusBat> = this.bat$$.pipe(
    map(() => this.status_bat),
    distinctUntilRealChanged(),
    replay()
  );

  public status_bat_observation_state$$: Observable<DeviceGlobalStateStr> = this.status_bat$$.pipe(
    map(status_bat => compute_bat_observation_state(status_bat)),
    distinctUntilRealChanged()
  );

  // #endregion

  // #region -> (observation state)

  /** */
  public observation_state$$: Observable<{ state: DeviceGlobalStateStr; i18n: string; mdi: `mdi-${string}` }> = robustCombineLatest([
    this.status_868_observation_state$$,
    this.status_bat_observation_state$$,
    this.status_gps_observation_state$$,
    this.status_gprs_observation_state$$,
  ]).pipe(
    map(states => {
      const array_of_statuses = values(states);
      const ordered_statuses = orderBy(array_of_statuses, ['have_issue', 'need_check', 'ok', undefined, null] as DeviceGlobalStateStr[]);

      return ordered_statuses?.[0];
    }),
    map(observation_state => {
      const label_i18n = device_global_status_i18n[observation_state];
      let mdi_icon: `mdi-${string}` = null;

      if (observation_state === 'ok') {
        mdi_icon = 'mdi-check-underline-circle';
      }

      if (observation_state === 'need_check') {
        mdi_icon = 'mdi-tools';
      }

      if (observation_state === 'have_issue') {
        mdi_icon = 'mdi-alert';
      }

      return { state: observation_state, i18n: label_i18n, mdi: mdi_icon };
    })
  );

  // #endregion

  // #region -> (movemement alarm)

  /** */
  private last_movement_status$$ = this.last_measurements$$.pipe(
    map(last_measurements => <DeviceLastMeasurementMovement>last_measurements?.mvmt_status ?? null),
    map(device_last_measurement => {
      if (isNil(device_last_measurement)) {
        return null;
      }

      const last_movement_status = <DeviceLastAlarmStatus>{};
      last_movement_status.time = parseDate(device_last_measurement?.time);
      last_movement_status.last_status = device_last_measurement?.tags?.status;
      last_movement_status.is_authorized = device_last_measurement?.tags?.move_auth;
      last_movement_status.movement_start_time = parseDate(device_last_measurement?.fields?.start_time?.last);
      return last_movement_status;
    }),
    replay()
  );

  /** */
  public last_movement$$ = this.last_movement_status$$.pipe(
    map(movement_status => movement_status?.time),
    replay()
  );

  /** */
  public movement_alarm$$ = this.last_movement_status$$.pipe(
    map(last_movement_status => {
      if (isNil(last_movement_status)) {
        return null;
      }

      const is_movement_stop = last_movement_status?.last_status === 'MOVEMENT_STOPPED';
      const hours_since_last_message = differenceInHours(new Date(), last_movement_status?.time);

      if ((is_movement_stop && Math.abs(hours_since_last_message) > 2) || Math.abs(hours_since_last_message) > 4) {
        return null;
      } else {
        return last_movement_status;
      }
    }),
    replay()
  );

  /** */
  public has_currently_alarm$$ = this.movement_alarm$$.pipe(
    map(movement_alarm => !isNil(movement_alarm)),
    replay()
  );

  /** */
  public last_tracking_state$$ = this.last_measurements$$.pipe(
    map(last_measurements => last_measurements?.location),
    map(last_location => {
      const time = parseDate(last_location?.time);
      const tracking_state = last_location?.fields?.tracking_state?.last ?? null;

      return { time, tracking_state };
    }),
    replay()
  );

  //#endregion

  // #region -> (device affectation)

  /** */
  private _beeguard_setup$$ = new BehaviorSubject<BeeGuardSetup>(null);

  /** */
  public beeguard_setup$$ = this._beeguard_setup$$.asObservable();

  /** */
  public set beeguard_setup(beeguard_setup: BeeGuardSetup) {
    this._beeguard_setup$$.next(beeguard_setup);
  }

  /** */
  private _exploitation$$: Observable<Exploitation> = null;

  /** */
  public exploitation$$(bg2Api: Beeguard2Api, options?: { at_date?: Date }): Observable<Exploitation> {
    if (!isNil(this._exploitation$$)) {
      return this._exploitation$$;
    }

    const request$$ = this.beeguard_setup$$.pipe(
      switchMap(beeguard_setup => {
        const exploitation_id = beeguard_setup?.exploitation?.id ?? null;

        if (isNil(exploitation_id)) {
          return of<Exploitation>(null);
        }

        return bg2Api.getEntityObj<Exploitation>(exploitation_id).pipe();
      }),
      replay()
    );

    this._exploitation$$ = request$$;
    return this._exploitation$$;
  }

  /** */
  private _location_entity: Location = null;

  /** */
  public get location_entity(): Location {
    return this._location_entity;
  }

  /** */
  private _location$$: Observable<Location> = null;

  /** */
  public location$$(bg2Api: Beeguard2Api, options?: { at_date?: Date }): Observable<Location> {
    if (!isNil(this._location$$)) {
      return this._location$$;
    }

    const location_request$$ = this.beeguard_setup$$.pipe(
      switchMap(beeguard_setup => {
        const location_id = beeguard_setup?.location?.id ?? null;

        if (isNil(location_id)) {
          return of<Location>(null);
        }

        return bg2Api.getEntityObj<Location>(location_id).pipe(tap(location => (this._location_entity = location)));
      }),
      replay()
    );

    this._location$$ = location_request$$;
    return this._location$$;
  }

  /** */
  private _named_location_entity: Location = null;

  /** */
  public get named_location_entity(): Location {
    return this._named_location_entity;
  }

  /** */
  private _named_location$$: Observable<Location> = null;

  /** */
  public named_location$$(bg2Api: Beeguard2Api) {
    if (!isNil(this._named_location$$)) {
      return this._named_location$$;
    }

    const location_request$$ = this.beeguard_setup$$.pipe(
      switchMap(beeguard_setup => {
        const location_id = beeguard_setup?.location?.id ?? null;

        if (isNil(location_id)) {
          return of<Location>(null);
        }

        return bg2Api.getEntityObj<Location>(location_id).pipe(
          switchMap(location =>
            location?.is_ghost$$.pipe(
              map(is_ghost => {
                if (is_ghost) {
                  return null;
                }

                return location;
              })
            )
          ),
          tap(location => (this._named_location_entity = location))
        );
      }),
      replay()
    );

    this._named_location$$ = location_request$$;
    return this._named_location$$;
  }

  /** */
  public affectation_history$$ = this.imei$$.pipe(
    waitForNotNilValue(),
    switchMap(imei => this.deviceApi.fetch_device_affectation_history$(imei, undefined, undefined)),
    map(response => response?.affectations),
    replay()
  );

  /** */
  public fetch_affectation_history$(start: Date, end: Date) {
    return this.imei$$.pipe(
      waitForNotNilValue(),
      switchMap(imei => this.deviceApi.fetch_device_affectation_history$(imei, end, start)),
      map(response => response?.affectations)
    );
  }

  // #endregion

  //#region Warehouse configuration

  /**
   * Get identifier of warehouse for this device.
   *
   * @returns Returns current warehouse's identifier.
   */
  get warehouse_id(): number {
    if (this.warehouse.length <= 0) {
      return null;
    }
    return this.warehouse[0].warehouse_id;
  }

  public warehouse_config$$: Observable<WarehouseConfig> = this.update$$.pipe(
    map(() => this.warehouse_conf),
    distinctUntilRealChanged()
  );

  /** */
  public warehouse_history$$: Observable<WarehouseConfig[]> = this.update$$.pipe(
    map(() => this.warehouse ?? []),
    distinctUntilRealChanged()
  );

  public affected_to_warehouse_since$$: Observable<Date> = this.warehouse_config$$.pipe(
    map(warehouse_conf => parseDate(warehouse_conf?.timestamp)),
    replay()
  );

  /**
   * Get current warehouse's configuration.
   *
   * @returns Returns current warehouse's configuration as `WarehouseConfig`.
   */
  get warehouse_conf(): WarehouseConfig {
    if (this.warehouse.length <= 0) {
      return null;
    }
    return this.warehouse[0];
  }

  /**
   * Get warehouse ID at a precise date.
   *
   * @param date The date on which we want the warehouse ID.
   * @param before_event_id The event ID (gets warehouse ID for specific `date` before this event).
   */
  public getWarehouseIdAtDate(date: Date, before_event_id?: number): number {
    for (const whconf of this.warehouse) {
      const event_date = setSeconds(parseDate(whconf.timestamp), 0);
      const ndate = setSeconds(date, 0);
      if (ndate > event_date && before_event_id !== whconf.event_id) {
        return whconf.warehouse_id;
      } else if (ndate === event_date && before_event_id < whconf.event_id) {
        return whconf.warehouse_id;
      }
    }
    return null;
  }

  //#endregion

  // #region -> (contract and guaratantee)

  /**
   * Get an observable of this device's contract (rent or).
   */
  public contract$$: Observable<'rent' | 'purchase' | 'loan'> = this.warehouse_config$$.pipe(
    map(warehouse_config => warehouse_config?.contract || null),
    distinctUntilRealChanged(),
    replay()
  );

  /** */
  public guarantee$$: Observable<{ under_guarantee: boolean; reason: string; data: Dictionary<any> }> = this.contract$$.pipe(
    switchMap(contract => {
      if (contract === 'rent') {
        return of({ under_guarantee: true, reason: i18n<string>('DEVICE.ALL.GUARANTEE.The device is rented'), data: {} });
      }

      if (contract === 'loan') {
        return of({ under_guarantee: true, reason: i18n<string>('DEVICE.ALL.GUARANTEE.The device is loaned'), data: {} });
      }

      return this.affected_to_warehouse_since$$.pipe(
        map(date => {
          const affected_before_two_years = differenceInYears(new Date(), date) <= 2;

          if (affected_before_two_years) {
            return {
              under_guarantee: true,
              reason: i18n<string>('DEVICE.ALL.CONTRACT.Device affected to exploitation since [affected_date]'),
              data: {
                affected_date: date,
              },
            };
          }

          return {
            under_guarantee: false,
            reason: i18n<string>(
              'DEVICE.ALL.CONTRACT.Device guarantee expired on [expiration_date] (affected to exploitation since [affected_date])'
            ),
            data: {
              affected_date: date,
              expiration_date: addYears(date, 2),
            },
          };
        })
      );
    })
  );

  // #endregion

  // #region -> (device timeseries)

  /**
   * Request the timeseries of the device.
   *
   * @param measurements List of measurements to query.
   * @param start Fetch data from this date.
   * @param end Fetch data until this date.
   * @param step Fetch data every each specified step.
   *
   * @returns Returns an `Observable` on the device timeseries.
   *
   * @note This method applies parseDate and utcToZonedTime to the date of the point.
   */
  public requestTimeseries(measurements?: Array<string>, start?: Date, end?: Date, step?: string): Observable<GetDeviceTimeseriesResponse> {
    if (isNil(start)) {
      start = undefined;
    }

    if (isNil(end)) {
      end = undefined;
    }

    if (isNil(step)) {
      step = undefined;
    }

    return this.timezone$$.pipe(
      switchMap(timezone =>
        this.deviceApi.fetch_device_timeseries$(this.imei, measurements, start, end, step).pipe(
          map(response => {
            if ((response?.timeseries?.data ?? [])?.length <= 0) {
              return response;
            }

            response.timeseries.data = response?.timeseries?.data.map((datum: DataPoint) => {
              datum.date = parseDate(datum.date);
              datum.tz_date = utcToZonedTime(datum.date, timezone);
              datum.timezone = timezone;

              return datum;
            });

            return response;
          })
        )
      ),
      take(1)
    );
  }

  /**
   * Fetches data related to the job of the device.
   */
  public abstract fetch_job_data$(start?: Date, end?: Date, step?: string): Observable<AnyOfDeviceJobData>;

  /**
   * Fetches data related to the job of the device.
   */
  public abstract fetch_secondary_data$(start?: Date, end?: Date, step?: string): Observable<AnyOfDeviceSecondaryData>;

  /**
   * Fetches data related to the job of the device.
   */
  public abstract fetch_rssi$(start?: Date, end?: Date, step?: string): Observable<DeviceRSSIData>;

  /** */
  public grafana_url$$ = this.update$$.pipe(
    map(device => getDeviceGrafanaUrl(device)),
    distinctUntilRealChanged(),
    replay()
  );

  // #endregion

  /**
   * Ask for the affectation of the device.
   *
   * @param bg2Api Reference to the BeeGuard API.
   * @returns Returns an `Obersable` on the device affection.
   */
  private _affectation_only_named$$: any = null;

  private _affectation$$: any = null;

  public requestAffectation(bg2Api: Beeguard2Api, only_named = false): Observable<DeviceAffectation> {
    const exist = (only_named && !isNil(this._affectation_only_named$$)) || (!only_named && !isNil(this._affectation$$));

    if (!exist) {
      const _affectation$$ = this.beeguard_setup$$.pipe(
        // Prepare device affectation
        map(beeguard_setup => {
          const device_affectation = <DeviceAffectation>{
            hive: null,
            apiary: null,
            location: null,
            warehouse: null,
            exploitation: null,
            device_config: null,
          };

          return { beeguard_setup, device_affectation };
        }),

        // Load affected warehouse
        switchMap(({ beeguard_setup, device_affectation }) => {
          if (isNil(beeguard_setup?.warehouse?.id)) {
            return of({ device_affectation, beeguard_setup });
          }

          return bg2Api.getEntityObj<Warehouse>(beeguard_setup?.warehouse?.id).pipe(
            map(warehouse => {
              device_affectation.warehouse = {
                entity: warehouse,
                since: {
                  event_id: beeguard_setup?.warehouse?.since?.event_id,
                  date: parseDate(beeguard_setup?.warehouse?.since?.date),
                },
              };

              return { device_affectation, beeguard_setup };
            })
          );
        }),

        // Load affected exploitation
        switchMap(({ device_affectation, beeguard_setup }) => {
          if (isNil(beeguard_setup?.exploitation?.id)) {
            return of({ device_affectation, beeguard_setup });
          }

          return bg2Api.getEntityObj<Exploitation>(beeguard_setup?.exploitation?.id).pipe(
            map(exploitation => {
              device_affectation.exploitation = {
                entity: exploitation,
                since: {
                  event_id: beeguard_setup?.exploitation?.since?.event_id,
                  date: parseDate(beeguard_setup?.exploitation?.since?.date),
                },
              };

              return { device_affectation, beeguard_setup };
            })
          );
        }),

        // Load device low-level configuration
        switchMap(({ device_affectation, beeguard_setup }) =>
          (device_affectation?.warehouse?.entity?.devices_config$$ ?? of<WhDevicesConfig>(null)).pipe(
            map((devices_config: WhDevicesConfig) => {
              if (isNil(devices_config?.[this.imei])) {
                return { device_affectation, beeguard_setup };
              }

              device_affectation.device_config = devices_config[this.imei];

              return { device_affectation, beeguard_setup };
            })
          )
        ),

        // Load device low-level affectation
        switchMap(({ device_affectation, beeguard_setup }) => {
          let entity$$: Observable<Hive | Apiary | Entity<any>> = of(null);

          if (device_affectation?.device_config?.associated_to) {
            entity$$ = bg2Api.getEntityObj<Hive | Apiary>(device_affectation.device_config.associated_to);
          }

          return entity$$.pipe(
            map(entity => {
              if (isNil(entity)) {
                return { beeguard_setup, device_affectation };
              }

              device_affectation = clone(device_affectation);

              if (entity instanceof Hive) {
                device_affectation.hive = {
                  entity: entity,
                  since: {
                    event_id: beeguard_setup?.hive?.since?.event_id,
                    date: parseDate(beeguard_setup?.hive?.since?.date),
                  },
                };
              } else if (entity instanceof Apiary) {
                device_affectation.apiary = {
                  entity: entity,
                  since: {
                    event_id: beeguard_setup?.apiary?.since?.event_id,
                    date: parseDate(beeguard_setup?.apiary?.since?.date),
                  },
                };
              } else {
                console.error(
                  `Invalid entity type ! Got entity of type ${entity?.type} instead of Hive or Apiary for device ${device_affectation?.device_config?.imei} in device_config`
                );
              }

              return { beeguard_setup, device_affectation };
            })
          );
        }),

        // Load device apiary (if affected to hive)
        switchMap(({ beeguard_setup, device_affectation }) => {
          let apiary$$: Observable<Apiary> = only_named
            ? device_affectation?.hive?.entity?.named_apiary$$
            : device_affectation?.hive?.entity?.apiary$$;
          apiary$$ = isNil(device_affectation?.apiary) && !isNil(device_affectation?.hive) ? apiary$$ : of<Apiary>(null);

          return apiary$$.pipe(
            map(apiary => {
              if (isNil(apiary)) {
                return { beeguard_setup, device_affectation };
              }

              device_affectation = clone(device_affectation);
              device_affectation.apiary = {
                entity: apiary,
                since: {
                  event_id: beeguard_setup?.apiary?.since?.event_id,
                  date: parseDate(beeguard_setup?.apiary?.since?.date),
                },
              };

              return { beeguard_setup, device_affectation };
            })
          );
        }),

        // Load device location
        switchMap(({ beeguard_setup, device_affectation }) => {
          let location$$: Observable<Location> = of<Location>(null);

          if (!isNil(device_affectation.apiary)) {
            location$$ = only_named ? device_affectation.apiary.entity.named_location$$ : device_affectation.apiary.entity.location$$;
          }

          return location$$.pipe(
            map(location => {
              if (isNil(location)) {
                return device_affectation;
              }

              device_affectation.location = {
                entity: location,
                since: {
                  event_id: beeguard_setup?.location?.since?.event_id,
                  date: parseDate(beeguard_setup?.location?.since?.date),
                },
              };

              return device_affectation;
            })
          );
        }),
        replay()
      );

      if (only_named) {
        this._affectation_only_named$$ = _affectation$$;
      } else {
        this._affectation$$ = _affectation$$;
      }
    }

    return only_named ? this._affectation_only_named$$ : this._affectation$$;
  }

  public requestProgressifAffectation(bg2Api: Beeguard2Api) {
    const tmp_affectation: DeviceAffectation = {
      exploitation: null,
      warehouse: null,
      location: null,
      apiary: null,
      hive: null,
      device_config: null,
    };

    return concat(
      of<DatatableDeviceRow>({
        owner: null,
        device: this,
        affectation: tmp_affectation,
        affectation_loading: true,
      }),
      this.requestAffectation(bg2Api).pipe(
        map<DeviceAffectation, DatatableDeviceRow>(affectation => ({
          owner: null,
          device: this,
          affectation,
          affectation_loading: false,
        })),
        catchError((err: unknown) => {
          console.error(`Error while loading device (${this.imei}) affectation: `, err);
          return of<DatatableDeviceRow>({
            owner: null,
            device: this,
            affectation: tmp_affectation,
            affectation_loading: false,
          });
        })
      )
    );
  }

  /**
   * Find all compatible locations for current device.
   *
   * @param locations$$ Observable of user locations.
   * @return Returns observable on compatible locations.
   */
  public findCompatibleLocations(locations$$: Observable<Location[]>): Observable<Location[]> {
    return locations$$.pipe(map(locations => locations.filter(location => this.isDeviceInLocationRange(location))));
  }

  /**
   * Check if current device location is compatible.
   *
   * @returns Returns an observable on `true` if location is compatible else an observable on `false`.
   */
  private _isLocationCompatible: any = null;
  public isLocationCompatible(bg2Api: Beeguard2Api, only_named = true): Observable<boolean> {
    if (isNil(this._isLocationCompatible)) {
      this._isLocationCompatible = this.named_location$$(bg2Api).pipe(
        map(location => {
          if (isNil(location)) {
            return false;
          }

          return this.isDeviceInLocationRange(location);
        }),
        replay()
      );
    }

    return this._isLocationCompatible;
  }

  /**
   * Check if the device is in the location range.
   *
   * @param from The given location.
   * @param range The given range (in meters).
   */
  public isDeviceInLocationRange(from: Location, range = 500): boolean {
    if (isNil(this.location)) {
      return false;
    }
    const distance = getDistance(
      { latitude: from.position.latitude, longitude: from.position.longitude },
      { latitude: this.location.latitude, longitude: this.location.longitude },
      1
    );
    return distance < range + (this.location.accuracy || 0);
  }

  /**
   * Checks if the device is not in specific latlng range.
   *
   * @param latlng The given initial latlng.
   * @param range The given range to check (in meters).
   * @returns Returns `true` if the device in located around else `false`.
   */
  public isDeviceInLatLngRange(latlng: { lat: number; lng: number }, range = 500): boolean {
    const distance = getDistance(
      { latitude: latlng.lat, longitude: latlng.lng },
      { latitude: this.location.latitude, longitude: this.location.longitude },
      1
    );
    return distance < range + (this.location.accuracy || 0);
  }

  // #region -> (device configuration)

  /** */
  public fetch_configuration_history$(start: Date, end: Date) {
    return this.imei$$.pipe(
      waitForNotNilValue(),
      switchMap(imei => this.deviceApi.fetch_device_ack_history$(imei, start, end, undefined, 0, -1)),
      map(response => response?.ack_configurations)
    );
  }

  /** */
  public ack_configuration_history$$ = this.imei$$.pipe(
    waitForNotNilValue(),
    switchMap(imei => this.deviceApi.fetch_device_ack_history$(imei)),
    map(response => response?.ack_configurations),
    replay()
  );

  // #endregion

  //#region Device configuration

  /**
   * Ask for device configuration schema.
   */
  public fetchConfigurationSchema$(): Observable<any> {
    return of({
      type: 'object',
      properties: {},
    });
  }

  //#region Device full configuration

  /**
   * Ask for full device configuration.
   */
  public requestConfiguration(): Observable<DeviceFullConfiguration> {
    return this.deviceApi.fetch_device_full_conf$(this.imei);
  }

  /**
   * Update full configuration of the device.
   *
   * @param conf The new configuration.
   */
  public setConfiguration(conf: DeviceFullConfiguration): Observable<any> {
    return this.deviceApi.update_device_full_conf$(this.imei, conf);
  }

  //#endregion

  // #region -> (device simplified configuration)

  /** */
  public sconf$$ = this.update$$.pipe(
    debounceTime(400),
    switchMap(() =>
      this.user_acl.can$$('write_devices_configuration').pipe(
        switchMap(can_write_devices_configuration => {
          if (can_write_devices_configuration) {
            return this.requestSimplifiedConfiguration();
          }

          return of(null);
        })
      )
    )
  );

  /** */
  public can_have_sconf$$ = combineLatest([this.type$$, this.is_mngt_v1$$]).pipe(
    map(
      ([type, is_mngt_v1]: [DeviceInterface.TypeEnum, boolean]) =>
        !is_mngt_v1 && [DeviceInterface.TypeEnum.GPS, DeviceInterface.TypeEnum.RG].includes(type)
    ),
    replay()
  );

  /** */
  public is_wguard_data_retrieval_active$$ = this.sconf$$.pipe(
    map(simplified_configuration => {
      if (isNil(simplified_configuration)) {
        return null;
      }

      const ack_mode = simplified_configuration?.ack?.sconf?.mode ?? null;
      if (isNil(ack_mode)) {
        return false;
      }

      return ack_mode === 'tracking_measures_sensors' || ack_mode === 'measures_sensors';
    }),
    replay()
  );

  /**
   * Ask for simplified configuration schema.
   */
  public requestSimplifiedConfigurationSchema(): Observable<any> {
    return of({
      type: 'object',
      properties: {
        sconf: {
          title: i18n('DEVICE.ALL.CONFIG.Mode and communication hours'),
          type: 'object',
          options: {
            showSelectionBar: true,
            showTicks: true,
            tickStep: 1,
          },
          properties: {
            mode: {
              type: 'string',
              label: i18n('DEVICE.ALL.CONFIG.Operating mode'),
              widget: 'select',
              default: 'tracking',
              oneOf: [
                {
                  enum: ['tracking'],
                  label: i18n('DEVICE.ALL.CONFIG.Tracking only'),
                },
                {
                  enum: ['tracking_measures'],
                  label: i18n('DEVICE.ALL.CONFIG.Tracking and internal measurements'),
                },
                {
                  enum: ['tracking_measures_sensors'],
                  label: i18n('DEVICE.ALL.CONFIG.Tracking, internal measurements and WGuard'),
                },
                {
                  enum: ['measures'],
                  label: i18n('DEVICE.ALL.CONFIG.Internal measurements'),
                },
                {
                  enum: ['measures_sensors'],
                  label: i18n('DEVICE.ALL.CONFIG.Internal measurements and WGuard'),
                },
              ],
            },
            com: {
              type: 'object',
              widget: 'bg2device-com',
              options: {
                maxRange: 24,
              },
              properties: {
                conf: {
                  type: 'string',
                  widget: 'select',
                  label: i18n('DEVICE.ALL.CONFIG.Number of communications'),
                  default: 'one_by_day',
                  options: {
                    clearable: false,
                  },
                  oneOf: [
                    {
                      enum: ['every_two_days'],
                      label: i18n('DEVICE.ALL.CONFIG.One communication every two days'),
                    },
                    {
                      enum: ['one_by_day'],
                      label: i18n('DEVICE.ALL.CONFIG.One communication by day'),
                    },
                    {
                      enum: ['two_by_day'],
                      label: i18n('DEVICE.ALL.CONFIG.Two communications by day'),
                    },
                  ],
                },
                hour_utc_one: {
                  type: 'number',
                  widget: 'slider',
                  label: i18n('DEVICE.ALL.CONFIG.First hour of communication'),
                  minimum: 0,
                  maximum: 23,
                  multipleOf: 1,
                  visibleIf: {
                    conf: ['every_two_days', 'one_by_day', 'two_by_day'],
                  },
                },
                hour_utc_two: {
                  type: 'number',
                  widget: 'slider',
                  label: i18n('DEVICE.ALL.CONFIG.Second hour of communication'),
                  minimum: 0,
                  maximum: 23,
                  default: 19,
                  multipleOf: 1,
                  visibleIf: {
                    conf: ['two_by_day'],
                  },
                },
              },
            },
          },
        },
        order: {
          type: 'string',
          title: i18n('DEVICE.ALL.CONFIG.Order'),
          widget: 'checklist',
          oneOf: [
            {
              enum: ['no'],
              hidden: true,
            },
            {
              type: 'number',
              enum: ['scan'],
              label: i18n('DEVICE.ALL.CONFIGURATION.ORDER.scan'),
            },
            {
              type: 'number',
              enum: ['gps'],
              label: i18n('DEVICE.ALL.CONFIGURATION.ORDER.gps'),
            },
          ],
          options: {
            display: 'button',
            null_value: 'no',
          },
        },
      },
    });
  }

  /**
   * Ask for simplified configuration.
   */
  public requestSimplifiedConfiguration(): Observable<GetDeviceSimplifiedConfigurationResponse> {
    return this.deviceApi.fetch_device_simple_conf$(this.imei);
  }

  /**
   * Update simplified configuration of the device.
   *
   * @param sconf The new configuration.
   */
  public setSimplifiedConfiguration(sconf: Sconf): Observable<any> {
    return this.deviceApi.update_device_simple_conf$(this.imei, sconf).pipe(
      // TODO: this is done before to have async update server side
      tap(() => this._update$$.next(this))
    );
  }

  // #endregion

  //#endregion

  //#region Device bat ch

  /**
   * Declare a new battery change for the given device
   */
  public addBatCh(date: Date, comment?: string): Observable<any> {
    this.loadings.battery_change.is_adding$$.next(true);

    return this.deviceApi
      .create_battery_change$(this.imei, {
        comment,
        time: date,
        cause: 'manual',
        origin: 'ng-app',
        confirmed: false,
      } as PostNewBatCh)
      .pipe(
        // TODO: this is done before to have async update server side
        map((ret: any) => {
          if (ret.status === 'done') {
            this.bat = ret.bat;
          }
        }),
        tap(() => this.loadings.battery_change.is_adding$$.next(false))
      );
  }

  public confirmBatCh(date: Date): Observable<any> {
    return this.updateBatCh(date, undefined, undefined, true);
  }

  public unConfirmBatCh(date: Date): Observable<any> {
    return this.updateBatCh(date, undefined, undefined, false);
  }

  /**
   * Update a new battery change for the given device
   */
  public updateBatCh(date: Date, comment?: string, cause?: string, confirmed?: boolean, deleted?: boolean): Observable<any> {
    const data: PutBatCh = {
      time: date,
      cause,
      confirmed,
      deleted,
      comment,
    };
    this.loadings.battery_change.is_updating$$.next(true);
    return this.deviceApi.update_battery_change$(this.imei, data).pipe(
      // TODO: this is done before to have async update server side
      map((ret: any) => {
        if (ret.status === 'done') {
          this.bat = ret.bat;
        }
      }),
      tap(() => this.loadings.battery_change.is_updating$$.next(false))
    );
  }

  public rmBatCh(dtime: Date, force: boolean = false): Observable<any> {
    this.loadings.battery_change.is_deleting$$.next(true);
    return this.deviceApi.delete_battery_change$(this.imei, dtime, force).pipe(
      map((ret: any) => {
        if (ret.status === 'done') {
          this.bat = ret.bat;
        }
      }),
      tap(() => this.loadings.battery_change.is_deleting$$.next(false))
    );
  }

  ////#endregion
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export const getDeviceGrafanaUrl = (device: DRDevice): string => {
  switch (device.type) {
    case 'CPT':
    case 'BeeLive':
    case 'BloomLive': {
      return `${environment?.grafana?.url}/d/kh-eOcp7z/beecounter2?orgId=${environment?.grafana?.orgId}&var-imei=${device.imei}`;
    }

    case 'CPTMC': {
      return `${environment?.grafana?.url}/d/_zKzuI3nz/beelive-gps?orgId=${environment?.grafana?.orgId}&var-IMEI=${device.imei}`;
    }

    case 'GPS':
    case 'RG': {
      return `${environment?.grafana?.url}/d/iCHLAAmik/gps?orgId=${environment?.grafana?.orgId}&var-IMEI=${device.imei}`;
    }

    case 'WG':
    case 'TG': {
      return `${environment?.grafana?.url}/d/tjKDJAimz/sensor?orgId=${environment?.grafana?.orgId}&var-sensor_id=${device.imei}&var-GW_IMEI=All`;
    }

    default: {
      console.error(`Grafana shortcut not take in charge for ${device.type}`);
    }
  }
  return null;
};

// eslint-disable-next-line @typescript-eslint/naming-convention
export const openGrafanaForSingleDevice = (event: MouseEvent, device: DRDevice, appState: AppStateService): void => {
  appState.is_real_user_superadmin$$.pipe(take(1)).subscribe({
    next: is_real_user_superadmin => {
      if (!is_real_user_superadmin) {
        return;
      }

      if ((event.ctrlKey || event.metaKey) && event.shiftKey) {
        event.preventDefault();
        event.stopPropagation();
        event.stopImmediatePropagation();

        const url = getDeviceGrafanaUrl(device);

        if (url) {
          window.open(url);
        }
      }
    },
  });
};

// eslint-disable-next-line @typescript-eslint/naming-convention
export const openGrafanaForMultipleDevices = (devices: DRDevice[]): void => {
  const is_only_gps = !devices.map(device => device.type === 'GPS' || device.type === 'RG').includes(false);
  const is_only_wg = !devices.map(device => device.type === 'WG').includes(false);

  // Only GPS array (GPS / GPSd / RG)
  if (is_only_gps) {
    let request = '';
    devices.forEach(device => (request += `&var-IMEI=${device.imei}`));
    window.open(`https://${environment?.grafana?.url}/d/GxQmGn9mk/multi-gps-batteries?${environment?.grafana?.orgId}${request}&from=now-1y&to=now`);
  }

  // Only WG array (WG)
  if (is_only_wg) {
    let request = '';
    devices.forEach(device => (request += `&var-sensor_id=${device.imei}`));
    window.open(`https://${environment?.grafana?.url}/d/OKVzIn9ik/multi-sensor-batteries?${environment?.grafana?.orgId}${request}&from=now-1y&to=now`);
  }

  // Mixed array (GPS / GPSd / RG / WG)
  if (!is_only_gps && !is_only_wg) {
    // let request = '';
    // devices.forEach(device => request += `&var-sensor_id=${device.imei}`);
    // window.open(`https://${environment?.grafana?.url}/d/OKVzIn9ik/multi-sensor-batteries?${environment?.grafana?.orgId}${request}&from=now-1y&to=now`);
  }
};

/** */
export class TestDRDevice extends DRDevice {
  protected compute_battery_type$$(): Observable<DEVICE_BATTERY_TYPE> {
    throw new Error('Method not implemented.');
  }

  protected get_battery_critical_vbat$$(): Observable<number> {
    throw new Error('Method not implemented.');
  }

  // #region -> (communication technology)

  /** */
  protected get_com_technology$$(): Observable<DeviceCommunicationTechnology[]> {
    throw new Error('Method not implemented.');
  }

  // #endregion

  // #region -> battery

  // #endreguib

  // #region -> (device timeseries)

  /** */
  public fetch_job_data$(start: Date = undefined, end: Date = undefined, step: string = undefined): Observable<AnyOfDeviceJobData> {
    return super
      .requestTimeseries([], start, end, step)
      .pipe(map(response => <AnyOfDeviceJobData>{ points: response?.timeseries?.data ?? [] }));
  }

  /** */
  public fetch_secondary_data$(start?: Date, end?: Date, step?: string): Observable<AnyOfDeviceSecondaryData> {
    return of({
      points: [],
    });
  }

  /** */
  public fetch_rssi$(start?: Date, end?: Date, step?: string): Observable<DeviceRSSIData> {
    return of({
      points: [],
    });
  }

  // #endregion
}
