import { Component, ChangeDetectionStrategy } from '@angular/core';

import { map } from 'rxjs';

import { Apiary } from 'app/models';
import { AppStateService } from 'app/core/app-state.service';

import { AbstractDialogComponent, AbstractDialogParams } from 'app/widgets/dialogs-modals/abstract-dialog.component';

export interface OtherInterventionDialogParams extends AbstractDialogParams {
  apiary: Apiary;
}

@Component({
  selector: 'other-intervention-dialog',
  templateUrl: './other-intervention.dialog.html',
  styleUrls: ['./other-intervention.dialog.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OtherInterventionDialogComponent extends AbstractDialogComponent<OtherInterventionDialogParams, any> {
  public apiary$$ = this.input_params$$.pipe(map(params => params.apiary));

  constructor(public appState: AppStateService) {
    super();
  }

  public clicked(value: boolean): void {
    setTimeout(() => {
      this.complete(value);
    }, 1);
  }
}
