import { replay } from '@bg2app/tools/rxjs';
import { BehaviorSubject } from 'rxjs';

/** */
export class SimpleSetterGetter<Type> {
  /** */
  constructor(default_value: Type = null) {
    this.value = default_value;
  }

  /** */
  private _value$$ = new BehaviorSubject<Type>(null);

  /** */
  public readonly value$$ = this._value$$.asObservable().pipe(replay());

  /** */
  public set value(value: Type) {
    this._value$$.next(value);
  }

  /** */
  public get value(): Type {
    return this._value$$.getValue();
  }
}
