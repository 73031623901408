import { Pipe, PipeTransform } from '@angular/core';
import { AppStateService } from 'app/core/app-state.service';

import { formatDuration, intervalToDuration } from 'date-fns';


@Pipe({
  name: 'formatDuration',
  pure: false
})
export class FormatDurationPipe implements PipeTransform {

  constructor(protected appState: AppStateService) { }

  transform(duration_sec: number): string {

    const duration_str = formatDuration(intervalToDuration({
      start: 0,
      end: duration_sec * 1000
    }), {
      locale: this.appState.dl.dateFns
    });

    return duration_str;
  }
}
