<ng-container *ngIf="solutions$$ | async; let solutions">
  <div *ngFor="let solution_alt of solutions" class="all-solutions">
    <div class="solution">
      <div class="solution-header">
        <div class="solution-title">
          <div class="solution-title-icon">
            <ng-container *ngIf="solution_alt.level === 0">
              <bg2-picon-location-entity [location]="null"></bg2-picon-location-entity>
            </ng-container>
            
            <ng-container *ngIf="solution_alt.level === 1">
              <img class="level-apiary" src="/assets/img/apiary.png" alt="">
            </ng-container>
            
            <ng-container *ngIf="solution_alt.level === 2">
              <bg2-picto-hive [htype]="'hive'" [size]="'30px'"></bg2-picto-hive>
            </ng-container>
          </div>
          <span *ngIf="solution_alt.level === 0">{{ 'GHOST.SOLUTION_VIEW.TITLE.Location declaration' | translate }}</span>
          <span *ngIf="solution_alt.level === 1">{{ 'GHOST.SOLUTION_VIEW.TITLE.Apiary setup' | translate }}</span>
          <span *ngIf="solution_alt.level === 2 && solution_alt.getSourceLocation()">{{
            'GHOST.SOLUTION_VIEW.TITLE.Setup devices previously on [source_location->name]'
              | translate
                : {
                    source_location: solution_alt.getSourceLocation()
                  }
          }}</span>
          <span *ngIf="solution_alt.level === 2 && !solution_alt.getSourceLocation()">{{
            'GHOST.SOLUTION_VIEW.TITLE.Setup devices not installed yet' | translate
          }}</span>
        </div>
        <div class="compatible-devices" *ngIf="solution_alt.level === 2 || (solution_alt.level === 1 && (solutions?.length > 1 || solution_alt.getSubSolutions().length === 0))">
          <!-- Show devices if root or differents solutions for different devices groups -->
          <div class="device-list">
            <ng-container *ngFor="let device of solution_alt.getDevices()">
              <div class="device" [popper]="deviceTooltip" [popperTimeout]="100">
                <span>
                  {{ device.name }}
                </span>
              </div>

              <popper-content #deviceTooltip>
                <div style="display: flex; flex-flow: column nowrap;">
                  <bg2-last-com [device]="device"></bg2-last-com>
                  <span>
                    <span class="mdi mdi-eye"></span>
                    <a [routerLink]="['', { outlets: { modal: ['device', { imei: device.imei }] } }]" [queryParamsHandling]="'preserve'">
                      {{ 'DEVICE.ALL.ACTION.See detail of this device' | translate }}
                    </a>
                  </span>
                </div>
              </popper-content>
            </ng-container>
          </div>
        </div>
      </div>
      <div class="solution-content">
        <div class="actions">
          <div class="alternatives">
            <mat-button-toggle-group
              (change)="solution_alt.setSelectedIdx($event.value)"
              [value]="solution_alt.selected_solution_idx$$ | async"
            >
              <mat-button-toggle *ngFor="let solution of solution_alt.solutions; index as idx" [value]="idx">
                <!-- <i class="mdi mdi-view-module mdi-18px"></i> -->
                <span>{{ solution.description | translate: solution.description_params }}</span>
              </mat-button-toggle>
              <ng-container> </ng-container>
            </mat-button-toggle-group>
          </div>
        </div>
        <!-- <pre>{{solution_alt.desc}}</pre> -->
        <div *ngIf="solution_alt.selected_solution$$ | async; let solution" class="solution-events">
          <ul>
            <li *ngFor="let event of solution.getEvents()">
              <bg2-event-link [event]="event" [from]="location$$ | async"></bg2-event-link>
            </li>
          </ul>
        </div>
        <bg2-button
          *ngIf="!solution_alt.has_parent"
          (click)="validateOne(solution_alt)"
          [icon]="'mdi-save'"
          type="action"
          color="secondary"
          [message]="'GHOST.SOLUTION_VIEW.Validate only this step' | translate"
          class="bg2-button-validate"
        >
        </bg2-button>
      </div>
    </div>
    <!-- Sub-Solutions -->
    <div class="sub-solutions">
      <bg2-ghost-solutions [solutions]="solution_alt.getSubSolutions()" [location]="location$$ | async"></bg2-ghost-solutions>
    </div>
  </div>
</ng-container>
