import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';

import { assign as _assign, findIndex as _findIndex, isEqual as _isEqual } from 'lodash-es';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs';

import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';
import { BgControlWidgetComponent as ControlWidget, WidgetOptions } from '../control/control.widget';
import { replay } from '@bg2app/tools/rxjs';
import { Dictionary } from 'app/typings/core/interfaces';

export class ChecklistItem {
  label: string;
  id: string;
  image: string;
  image_styles: any;
  disabled: boolean;

  private _value$$ = new BehaviorSubject<boolean>(false);
  public value$$ = this._value$$.asObservable().pipe(distinctUntilChanged(), replay());
  set value(val: boolean) {
    this._value$$.next(val);
  }
  get value(): boolean {
    return this._value$$.getValue();
  }
}

export interface ChecklistOptions extends WidgetOptions, Dictionary<any> {
  display: string;
  null_value: any;
  fill: boolean;
}

@AutoUnsubscribe()
@Component({
  selector: 'bg2-ef-checklist-widget',
  templateUrl: './checklist.widget.html',
  styleUrls: ['./checklist.widget.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EfChecklistWidgetComponent extends ControlWidget implements OnInit, OnDestroy {
  public items: ChecklistItem[] = [];
  public filledIn = false;

  public minItems: number;
  public nb_true$$: Observable<number>;

  private one_value = false;
  // ^ if true only one value is possible
  // So we have a radio with possible null state

  private sub_value: Subscription;

  public container_style: Dictionary<any> = {};

  public options: ChecklistOptions = {
    display: 'checkbox',
    null_value: null,
    fill: false,
    indent: false,
    title_style: '',
    reset_btn: false,
    for_each_item: {
      styles: <Dictionary<any>>{},
    },
  };

  ngOnInit(): void {
    super.ngOnInit();
    this.minItems = this.schema.minItems || 0;
    this.one_value = this.schema.type !== 'array';
    this.initItems(this.one_value ? this.schema.oneOf : this.schema.items.oneOf);

    // TODO manage proper widget options
    this.options = _assign(this.options, this.schema.options);
    if (this.options.fill) {
      this.filledIn = this.options.fill;
    }
    if (this.options['background-image']) {
      this.container_style['background-image'] = `url(assets/${this.options['background-image']})`;
      this.container_style.padding = '30px 0';
      this.container_style.margin = '-30px 0';
    }

    // Obs of number of select values
    this.nb_true$$ = this.value$$.pipe(map(values => values?.length || 0));
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  initItems(source_items: any[]): void {
    this.items = source_items
      .filter(item => !item.hidden)
      .filter(item => item.label)
      .filter(item => item.enum && item.enum.length > 0)
      .map(_item => {
        const item = new ChecklistItem();
        item.label = _item.label;
        item.disabled = _item.forced || _item.disabled || false;
        item.value = false || _item.forced;
        item.id = _item.enum[0];
        item.image = _item.image || null;
        item.image_styles = _item.image_styles || {};
        return item;
      });

    this.unsubscribeSubValue();
    this.sub_value = this.value$$
      .pipe(
        // tap(values => console.log('Got new values', this.formProperty.path, values)),
        map(values => (this.one_value ? [values] : values)) // ensure we have a list of values
      )
      .subscribe(values => {
        // console.log('new values', this.formProperty.path, values)
        // uncheck everything
        this.items.map(item => {
          item.value = false;
        });
        // And then check only the one givens
        for (const val of values) {
          const idx = _findIndex(this.items, item => item.id === val);
          if (idx >= 0) {
            this.items[idx].value = true;
          }
        }
      });
  }

  public onChange(item: any, new_val: boolean): void {
    // console.log('change', item, new_val)
    item.value = new_val;
    if (!this.one_value) {
      const new_value = this.items.filter(_item => _item.value).map(_item => _item.id);
      if (!_isEqual(new_value, this.formProperty.value)) {
        this.formProperty.setValue(new_value, false);
      }
    } else {
      const new_value = item.value ? item.id : this.options.null_value || null;
      this.formProperty.setValue(new_value, false);
      // RAZ other
      this.items.filter(_item => item.id !== _item.id).map(_item => (_item.value = false));
    }
  }

  protected unsubscribeSubValue(): void {
    this.sub_value?.unsubscribe();
  }
}
