/**
 * Converts a list of UTC hours to a list of local hours.
 *
 * @param array_of_utc_hours List of UTC hours to convert.
 *
 * @returns Returns a list of local hours converted from UTC hours.
 */
export const convert_utc_hours_to_local = (array_of_utc_hours: number[]): number[] => {
  const date_time = new Date();
  const timezone_offset = date_time.getTimezoneOffset() / 60;

  return array_of_utc_hours.map(hour => hour - timezone_offset).map(hour => ((hour % 24) + 24) % 24);
};
