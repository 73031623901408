import { ChangeDetectionStrategy, Component, OnDestroy, OnInit} from '@angular/core';

import { merge as _merge } from 'lodash-es';

import { Beeguard2Api } from 'app/core';
import { AppStateService } from 'app/core/app-state.service';

import { DialogsService } from 'app/widgets/dialogs-modals/dialogs.service';

import { StringWidget } from 'ngx-schema-form';

@Component({
  selector: 'ng-mat-password',
  templateUrl: './ng-mat-password.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NgMatPasswordComponent extends StringWidget {

  constructor(private _bg2Api: Beeguard2Api, private _dialogs: DialogsService, private _appState: AppStateService) {
    super();
  }

  set value(value: string) {
    this.formProperty.setValue(value, false);
  }

  // #region -> (hide/show password)

  public show_password = false;

  // #endregion
}
