// From @node_modules/@angular/*
import { Component, Input, ChangeDetectionStrategy } from '@angular/core';

import { BehaviorSubject, Observable, of } from 'rxjs';
import { map, switchMap, take, tap } from 'rxjs';

import { marker as i18n } from '@biesbjerg/ngx-translate-extract-marker';

import { endOfDay, startOfDay, subMonths } from 'date-fns';

// From @node_modules/@lodash/*
import { isNil, reverse, sortBy, uniqueId as _uniqueId } from 'lodash-es';

import { replay } from '@bg2app/tools/rxjs';
import { CompareByType, parseDate } from 'app/misc/tools';

import { AppStateService } from 'app/core/app-state.service';

import { DRDevice } from 'app/models';
import { DatatableColumn } from 'app/models/misc/datatable';

// eslint-disable-next-line @typescript-eslint/naming-convention
const GW_MSG_COLS: DatatableColumn<any>[] = [
  {
    property: 'msg.time',
    short_property: 'msg_ts',
    label: i18n('VIEWS.DEVICES.MSG_TABLE.date'),
    displayed_by_default: true,
    not_configurable: false,
    is_sortable: true,
    is_groupable: false,
    compare_by: CompareByType.DATE,
  },
  // {
    //   property: 'msg.measurement',
    //   label: i18n('VIEWS.DEVICES.MSG_TABLE.Measurement'),
    //   displayed_by_default: true,
    //   not_configurable: false,
    //   is_sortable: true,
    //   is_groupable: false,
    //   compare_by: CompareByType.STRING,
    // },
    // {
      //   property: 'msg.tags.id',
      //   label: i18n('VIEWS.DEVICES.MSG_TABLE.Device ID'),
      //   displayed_by_default: true,
      //   not_configurable: false,
      //   is_sortable: true,
      //   is_groupable: false,
      //   compare_by: CompareByType.STRING,
      // },
      {
        property: 'msg.tags.packet_type',
        short_property: 'msg_pck_type',
        label: i18n('VIEWS.DEVICES.MSG_TABLE.Packet type'),
        displayed_by_default: true,
        not_configurable: false,
        is_sortable: true,
        is_groupable: true,
        compare_by: CompareByType.STRING,
      },
      {
        property: 'msg.fields.first_com',
        short_property: 'msg_frst_com',
        label: i18n('VIEWS.DEVICES.MSG_TABLE.Packet number'),
        displayed_by_default: true,
        not_configurable: false,
        is_sortable: true,
        is_groupable: false,
        compare_by: CompareByType.NUMBER,
      },
      // {
        //   property: 'msg.tags.software_version',
        //   label: i18n('VIEWS.DEVICES.MSG_TABLE.Software version'),
        //   displayed_by_default: true,
        //   not_configurable: false,
        //   is_sortable: true,
        //   is_groupable: true,
        //   compare_by: CompareByType.NUMBER,
        // },
        // {
          //   property: 'msg.tags.hardware_version',
          //   label: i18n('VIEWS.DEVICES.MSG_TABLE.Hardware version'),
          //   displayed_by_default: true,
          //   not_configurable: false,
          //   is_sortable: true,
          //   is_groupable: true,
          //   compare_by: CompareByType.NUMBER,
          // },
          {
            property: 'msg.fields.temperature_micro',
            short_property: 'msg_tmp_mic',
            label: i18n('VIEWS.DEVICES.MSG_TABLE.T°C'),
            displayed_by_default: true,
            not_configurable: false,
            is_sortable: true,
            is_groupable: false,
            compare_by: CompareByType.NUMBER,
          },
          {
            property: 'msg.fields.vbat',
            short_property: 'msg_vbat',
            label: i18n('VIEWS.DEVICES.MSG_TABLE.vbat'),
            displayed_by_default: true,
            not_configurable: false,
            is_sortable: true,
            is_groupable: false,
            compare_by: CompareByType.NUMBER,
          },
          {
            property: 'msg.fields.nb_sensors',
            short_property: 'msg_nb_snsrs',
            label: i18n('VIEWS.DEVICES.MSG_TABLE.nb sensors msg'),
            displayed_by_default: true,
            not_configurable: false,
            is_sortable: true,
            is_groupable: false,
            compare_by: CompareByType.NUMBER,
          },
          {
            property: 'msg.fields.time_fix',
            short_property: 'msg_ts_fix',
            label: i18n('VIEWS.DEVICES.MSG_TABLE.GPS_timefix'),
            displayed_by_default: true,
            not_configurable: false,
            is_sortable: true,
            is_groupable: false,
            compare_by: CompareByType.NUMBER,
          },
          {
            property: 'msg.fields.gprs_qos',
            short_property: 'msg_gprs_qos',
            label: i18n('VIEWS.DEVICES.MSG_TABLE.gprs_rssi'),
            displayed_by_default: true,
            not_configurable: false,
            is_sortable: true,
            is_groupable: false,
            compare_by: CompareByType.NUMBER,
          },
          {
            property: 'msg.fields.gprs_connect_time',
            short_property: 'msg_gprs_con_ts',
            label: i18n('VIEWS.DEVICES.MSG_TABLE.gprs_connect_time'),
            displayed_by_default: true,
            not_configurable: false,
            is_sortable: true,
            is_groupable: false,
            compare_by: CompareByType.NUMBER,
          },
          {
            property: 'msg.fields.mcc',
            short_property: 'msg_mcc',
            label: 'MCC',
            displayed_by_default: true,
            not_configurable: false,
            is_sortable: true,
            is_groupable: false,
            compare_by: CompareByType.NUMBER,
          },
          {
            property: 'msg.fields.mnc',
            short_property: 'msg_mnc',
            label: 'MNC',
            displayed_by_default: true,
            not_configurable: false,
            is_sortable: true,
            is_groupable: false,
            compare_by: CompareByType.NUMBER,
          },
          // {
            //   property: 'msg.fields.lac',
            //   label: 'LAC',
            //   displayed_by_default: true,
            //   not_configurable: false,
            //   is_sortable: true,
            //   is_groupable: false,
            //   compare_by: CompareByType.NUMBER,
            // },
            // {
              //   property: 'msg.fields.cell_id',
              //   label: 'Cell Id',
              //   displayed_by_default: true,
              //   not_configurable: false,
  //   is_sortable: true,
  //   is_groupable: false,
  //   compare_by: CompareByType.NUMBER,
  // },
];

const GW_SENSOR_COLS = [
  {
    property: 'msg.time',
    short_property: 'msg_ts',
    label: i18n('VIEWS.DEVICES.MSG_TABLE.date'),
    displayed_by_default: true,
    not_configurable: false,
    is_sortable: true,
    is_groupable: false,
    compare_by: CompareByType.DATE,
  },
  // {
    //   property: 'msg.measurement',
    //   label: i18n('VIEWS.DEVICES.MSG_TABLE.Measurement'),
    //   displayed_by_default: true,
    //   not_configurable: false,
    //   is_sortable: true,
    //   is_groupable: false,
    //   compare_by: CompareByType.STRING,
    // },
    {
      property: 'msg.tags.imei',
      short_property: 'msg_imei',
      label: i18n('VIEWS.DEVICES.MSG_TABLE.Gateway ID'),
      displayed_by_default: true,
      not_configurable: false,
      is_sortable: true,
      is_groupable: false,
      compare_by: CompareByType.STRING,
    },
    {
      property: 'msg.fields.counter',
      short_property: 'msg_counter',
      label: i18n('VIEWS.DEVICES.MSG_TABLE.Msg counter'),
      displayed_by_default: true,
      not_configurable: false,
      is_sortable: true,
      is_groupable: false,
      compare_by: CompareByType.NUMBER,
    },
    {
      property: 'msg.fields.nb_measurements',
      short_property: 'msg_nb_msremt',
      label: i18n('VIEWS.DEVICES.MSG_TABLE.Nb meas'),
      displayed_by_default: true,
      not_configurable: false,
      is_sortable: true,
      is_groupable: false,
      compare_by: CompareByType.NUMBER,
    },
    {
      property: 'msg.fields.rssi',
      short_property: 'msg_rssi',
      label: i18n('VIEWS.DEVICES.MSG_TABLE.RSSI'),
      displayed_by_default: true,
      not_configurable: false,
      is_sortable: true,
      is_groupable: false,
      compare_by: CompareByType.NUMBER,
    },
    {
      property: 'msg.fields.sensor_vbat',
      short_property: 'msg_snsr_vbat',
      label: i18n('VIEWS.DEVICES.MSG_TABLE.vbat'),
      displayed_by_default: true,
      not_configurable: false,
      is_sortable: true,
      is_groupable: false,
      compare_by: CompareByType.NUMBER,
    },
    {
      property: 'msg.fields.autotest',
      short_property: 'msg_autotst',
      label: i18n('VIEWS.DEVICES.MSG_TABLE.Autotest'),
      displayed_by_default: true,
      not_configurable: false,
      is_sortable: true,
      is_groupable: false,
      compare_by: CompareByType.NUMBER,
    },
    {
      property: 'msg.fields.date_ref',
      short_property: 'msg_date_ref',
      label: i18n('VIEWS.DEVICES.MSG_TABLE.date ref'),
      displayed_by_default: true,
      not_configurable: false,
      is_sortable: true,
      is_groupable: false,
      compare_by: CompareByType.DATE,
    },
    {
      property: 'msg.fields.reception_timestamp',
      short_property: 'msg_rcpt_ts',
      label: i18n('VIEWS.DEVICES.MSG_TABLE.Reception date'),
      displayed_by_default: true,
      not_configurable: false,
      is_sortable: true,
      is_groupable: false,
      compare_by: CompareByType.DATE,
    },
  ];
  

@Component({
  selector: 'bg2-device-last-msgs',
  templateUrl: './device-last-msgs.component.html',
  styleUrls: ['./device-last-msgs.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DeviceLastMsgsComponent {
  public uid = _uniqueId();

  private _device$$: BehaviorSubject<DRDevice> = new BehaviorSubject(null);
  public device$$: Observable<DRDevice> = this._device$$.asObservable();

  public last_gw_msgs$$ = this.device$$.pipe(
    map(device => {
      const msgs: {
        device: DRDevice;
        msg: any;
      }[] = [];
      const gw_msgs = device.last_measurements?.gateway_message?.last_measurements;
      if (gw_msgs) {
        gw_msgs.forEach(msg => msgs.push({device, msg}));
      }
      return msgs;
    }),
    tap(msg_rows => msg_rows.forEach(msg_row => msg_row.msg.time = parseDate(msg_row.msg.time))),
    map(msg_rows => sortBy(msg_rows, msg_row => msg_row.msg.time)),
    map(msg_rows => reverse(msg_rows)),
    replay()
  );

  public last_sensor_msgs$$ = this.device$$.pipe(
    map(device => {
      const msgs: {
        device: DRDevice;
        msg: any;
      }[] = [];
      const sensor_msgs = device.last_measurements?.sensor_message?.last_measurements;
      if (sensor_msgs) {
        sensor_msgs.forEach(msg => msgs.push({device, msg}));
      }
      // console.log(sensor_msgs[0]);
      return msgs;
    }),
    tap(msg_rows => msg_rows.forEach(msg_row => msg_row.msg.time = parseDate(msg_row.msg.time))),
    map(msg_rows => sortBy(msg_rows, msg_row => msg_row.msg.time)),
    map(msg_rows => reverse(msg_rows)),
    replay()
  );

  @Input()
  public set device(device: DRDevice) {
    this._device$$.next(device);
  }

  public get device(): DRDevice {
    return this._device$$.getValue();
  }


  public gw_columns = GW_MSG_COLS;
  public sensor_columns = GW_SENSOR_COLS;

  constructor(public appState: AppStateService) { }

}
