import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, HostListener, ViewChild } from '@angular/core';

import { BehaviorSubject, combineLatest } from 'rxjs';
import { map } from 'rxjs';

import { isNil } from 'lodash-es';

import { ConsoleLoggerService } from 'app/core/console-logger.service';
import { RunnersService } from 'app/core/runners/runners.service';

@Component({
  selector: 'bg2-snackbar-list',
  templateUrl: './snackbar-list.component.html',
  styleUrls: ['./snackbar-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SnackbarListComponent implements AfterViewInit {
  private _logger = new ConsoleLoggerService('SnackbarListComponent', false);

  @ViewChild('snacklist')
  public snackList: ElementRef<HTMLDivElement>;

  @HostListener('window:resize')
  public onComponentResize() {
    if (!isNil(this.snackList)) {
      this.runners.used_height = this.snackList.nativeElement.offsetHeight;
    }
  }

  // #region -> (component basics)

  constructor(public runners: RunnersService) {}

  ngAfterViewInit(): void {
    this.runners.used_height = this.snackList?.nativeElement?.offsetHeight;
  }

  // #endregion

  // #region -> (collapsing management)

  public multiple_runs$$ = this.runners.current_runners$$.pipe(map(runners => !isNil(runners) && runners.length > 1));

  private _is_collapsed$$ = new BehaviorSubject<boolean>(true);

  public is_collapsed$$ = combineLatest([this._is_collapsed$$, this.multiple_runs$$]).pipe(
    map(([is_collapsed, multiple_runs]) => (!multiple_runs ? false : is_collapsed))
  );

  public get is_collapsed(): boolean {
    return this._is_collapsed$$.getValue();
  }

  public set is_collapsed(collapsed: boolean) {
    this._is_collapsed$$.next(collapsed);
  }

  // #endregion
}
