import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';

import { merge, isNumber, isNil } from 'lodash-es';

import { BehaviorSubject, Observable } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs';
import { distinctUntilRealChanged, replay } from '@bg2app/tools/rxjs';

import { Beeguard2Api } from 'app/core';
import { AppStateService } from 'app/core/app-state.service';
import { DialogsService } from 'app/widgets/dialogs-modals/dialogs.service';

import { EfNumberWidget } from '../number/number.widget';
import { WidgetOptions } from '../control/control.widget';

export interface INgMatNumberRangeOptions extends WidgetOptions {
  ceil: number;
  step: number;
  floor: number;
  labelOnMin: string;
  suffixOnMin: string;
  suffixOnMax: string;
  labelOnMax: string;
  display_as: 'fields' | 'slider';
}

@Component({
  selector: 'bg2-ng-mat-number-range',
  templateUrl: './ng-mat-number-range.component.html',
  styleUrls: ['./ng-mat-number-range.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NgMatNumberRangeComponent extends EfNumberWidget implements OnInit {
  /**
   * Re-define options for this widget component
   */
  public options: INgMatNumberRangeOptions = merge(
    {},
    {
      step: 1,
      floor: 0,
      ceil: 255,
      suffixOnMin: null,
      suffixOnMax: null,
      display_as: 'fields',
      labelOnMin: 'DEFAULT_MIN_LABEL',
      labelOnMax: 'DEFAULT_MAX_LABEL',
    } as INgMatNumberRangeOptions,
    super.options
  );

  // #region -> (component basics)

  constructor(private _bg2Api: Beeguard2Api, private _dialogs: DialogsService, private _appState: AppStateService) {
    super(_bg2Api, _appState, _dialogs);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this._is_min_max$$.next(this.schema.type === 'array');

    this.options.floor = this.schema.minimum || this.schema.items?.minimum || this.options.floor;
    this.options.ceil = this.schema.maximum || this.schema.items?.maximum || this.options.ceil;

    this.options.step = this.schema.step || this.schema.multipleOf || this.schema.items?.multipleOf;

    this.min$$ = this.value$$.pipe(
      map(value => value[0]),
      distinctUntilChanged(),
      replay()
    );
    this.max$$ = this.value$$.pipe(
      map(value => value[1]),
      distinctUntilChanged(),
      replay()
    );

    this.initializeValue();
  }

  // #endregion

  // #region -> (value management)

  private _is_min_max$$ = new BehaviorSubject(false);
  public is_min_max$$ = this._is_min_max$$.asObservable().pipe(distinctUntilRealChanged(), replay());

  public min$$: Observable<number>;
  public max$$: Observable<number>;

  public get min(): number {
    return this.formProperty.value[0];
  }

  public set min(minimum: number) {
    if (!isNumber(minimum)) {
      minimum = parseFloat(minimum) || null;
    }
    if (!isNil(minimum)) {
      this.formProperty.setValue([minimum, this.max], false);
    }
  }

  public get max(): number {
    return this.formProperty.value[1];
  }

  public set max(maximum: number) {
    if (!isNumber(maximum)) {
      maximum = parseFloat(maximum) || null;
    }
    if (!isNil(maximum)) {
      this.formProperty.setValue([this.min, maximum], false);
    }
  }

  private initializeValue(): void {
    let initial_value = this.value;

    if (!initial_value) {
      initial_value = this.schema.default || 0;
    }

    if (initial_value < this.options.floor) {
      initial_value = this.options.floor;
    } else if (this.options.floor > this.options.ceil) {
      initial_value = this.options.ceil;
    }

    if (this.value !== initial_value) {
      this.value = initial_value;
    }
  }
}
