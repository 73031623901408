import * as d3 from 'd3';

import { isArray, isEmpty, isNil, max, sortBy } from 'lodash-es';

import { setHours } from 'date-fns/esm';

import { marker as i18n } from '@biesbjerg/ngx-translate-extract-marker';

import { AppStateService } from 'app/core/app-state.service';
import { ConsoleLoggerService } from 'app/core/console-logger.service';
import { D3SharedCursorService } from 'app/core/services/global/d3-shared-cursor.service';

import { RGJobData, WeatherDataPoint } from 'app/models/data';

import {
  AbstractUserEvents,
  AbstractD3Grid,
  AbstractD3Axes,
  AbstractD3Scales,
  AbstractDataObjects,
  AbstractD3Labels,
  AbstractEventObjects,
  D3SvgBuilderLuxon,
} from '@bg2app/models/charts';
import { group_data_by_day } from '@bg2app/tools/dates';
import { D3GridDayCycle } from '@bg2app/models/charts';
import { NgZone } from '@angular/core';

// #region -> (interfaces)

/** */
interface Axes extends AbstractD3Axes {
  /**
   * Defines a dictionnary to hold the informations about time axis.
   */
  time: {
    /**
     * Reference to the d3-axis generator for the time values.
     */
    axis: d3.Axis<Date>;

    /**
     * Reference to the container for the time axis.
     */
    container: d3.Selection<SVGGElement, unknown, HTMLElement, any>;
  };

  /**
   * Defines a dictionnary to hold the informations about weather axis.
   */
  temperature: {
    /**
     * Reference to the d3-axis generator for the pressure values.
     */
    axis: d3.Axis<d3.AxisDomain>;

    /**
     * Reference to the container for the pressure axis.
     */
    container: d3.Selection<SVGGElement, unknown, HTMLElement, any>;
  };

  /**
   * Defines a dictionnary to hold the informations about pressure axis.
   */
  pressure: {
    /**
     * Reference to the d3-axis generator for the pressure values.
     */
    axis: d3.Axis<d3.AxisDomain>;

    /**
     * Reference to the container for the pressure axis.
     */
    container: d3.Selection<SVGGElement, unknown, HTMLElement, any>;
  };
}

/** */
interface Scales extends AbstractD3Scales {
  /**
   * Reference to the time scale for the temporal value.
   */
  time: d3.ScaleTime<number, number, never>;

  /**
   * Reference to linear scale for the weight value.
   */
  temperature: d3.ScaleLinear<number, number, never>;

  /**
   * Reference to linear scale for the weight value.
   */
  pressure: d3.ScaleLinear<number, number, never>;

  /** */
  rain: d3.ScaleLinear<number, number, never>;
}

/** */
interface DataObjects extends AbstractDataObjects {
  /** */
  temperature: {
    /** */
    line: {
      /** */
      shape: d3.Line<WeatherDataPoint>;

      /** */
      path: d3.Selection<SVGPathElement, unknown, HTMLElement, any>;
    };

    /** */
    area: {
      /** */
      shape: d3.Area<WeatherDataPoint>;

      /** */
      path: d3.Selection<SVGPathElement, unknown, HTMLElement, any>;
    };

    /** */
    circle: d3.Selection<SVGCircleElement, unknown, HTMLElement, any>;
  };

  /** */
  pressure: {
    /** */
    low: {
      /** */
      shape: d3.Line<WeatherDataPoint>;

      /** */
      path: d3.Selection<SVGPathElement, unknown, HTMLElement, any>;
    };

    /** */
    high: {
      /** */
      shape: d3.Line<WeatherDataPoint>;

      /** */
      path: d3.Selection<SVGPathElement, unknown, HTMLElement, any>;
    };

    /** */
    nominal: {
      /** */
      shape: d3.Line<WeatherDataPoint>;

      /** */
      path: d3.Selection<SVGPathElement, unknown, HTMLElement, any>;
    };

    /** */
    circle: d3.Selection<SVGCircleElement, unknown, HTMLElement, any>;
  };

  /** */
  rain: {
    /** */
    container: d3.Selection<SVGGElement, unknown, HTMLElement, unknown>;

    /** */
    area: {
      /** */
      shape: d3.Area<WeatherDataPoint>;

      /** */
      path: d3.Selection<SVGPathElement, unknown, HTMLElement, any>;
    };
  };
}

/** */
interface Events extends AbstractUserEvents {
  /** */
  weather: {
    /** */
    container: d3.Selection<HTMLDivElement, unknown, HTMLElement, any>;
  };
}

/** */
interface Labels extends AbstractD3Labels {
  /** */
  labels: {
    /** */
    temperature: d3.Selection<SVGTextElement, unknown, HTMLElement, unknown>;

    /** */
    pressure: d3.Selection<SVGTextElement, unknown, HTMLElement, unknown>;
  };
}

// #endregion

// #region -> (classes)

/** */
export class RGJobDataWeatherD3ChartFactory extends D3SvgBuilderLuxon<RGJobData> {
  // #region -> (properties)

  public axes: Axes = {
    container: null,
    time: {
      axis: null,
      container: null,
    },
    temperature: {
      axis: null,
      container: null,
    },
    pressure: {
      axis: null,
      container: null,
    },
  };

  public scales: Scales = {
    time: null,
    rain: null,
    pressure: null,
    temperature: null,
  };

  public data_objects: DataObjects = {
    container: null,
    temperature: {
      line: {
        shape: d3
          .line<WeatherDataPoint>()
          .defined(point => !isNil(point?.temperature))
          .curve(d3.curveLinear),
        path: null,
      },

      area: {
        shape: d3
          .area<WeatherDataPoint>()
          .defined(point => !isNil(point?.temperature))
          .curve(d3.curveLinear),
        path: null,
      },

      circle: null,
    },
    pressure: {
      low: {
        shape: d3.line<WeatherDataPoint>().curve(d3.curveLinear),
        path: null,
      },

      high: {
        shape: d3.line<WeatherDataPoint>().curve(d3.curveLinear),
        path: null,
      },

      nominal: {
        shape: d3.line<WeatherDataPoint>().curve(d3.curveLinear),
        path: null,
      },

      circle: null,
    },

    rain: {
      container: null,

      area: {
        shape: d3
          .area<WeatherDataPoint>()
          .defined(point => !isNil(point?.rain_cumul_day))
          .curve(d3.curveStepAfter),
        path: null,
      },
    },
  };

  public event_objects: AbstractEventObjects = {
    container: null,
  };

  public user_events: Events = {
    container: null,
    event_bounds: null,

    focus_line_x: null,
    focus_line_y: null,

    weather: {
      container: null,
    },
  };

  public grids: AbstractD3Grid = {
    container: null,

    time: {
      axis: null,
      container: null,
    },

    day_cycle: new D3GridDayCycle(),
  };

  public labels: Labels = {
    container: null,

    labels: {
      temperature: null,
      pressure: null,
    },
  };

  //#endregion

  // #region -> (component basics)

  /** */
  protected colors: { [key in 'pressure_def' | 'pressure_low' | 'pressure_high' | 'pressure_nominal' | 'temperature']: `#${string}` } = {
    /** */
    pressure_def: '#32B117',
    pressure_low: '#0F5900',
    pressure_high: '#99E38A',
    pressure_nominal: '#32B117',

    /** */
    temperature: '#E96363',
  };

  /** */
  protected LOGGER: ConsoleLoggerService = new ConsoleLoggerService('RGJobDataWeatherD3ChartFactory', true);

  /** */
  constructor(protected _shared_cursor: D3SharedCursorService, protected _appState: AppStateService, protected _ngZone: NgZone) {
    super(_shared_cursor, _appState, _ngZone);

    this.margins.top = 5;
    this.margins.right = 50;
  }

  /** */
  public destroy(): void {
    super.destroy();
  }

  // #endregion

  // #region -> (incoming data)

  /** */
  public get has_data() {
    const data = this.incoming_data?.points;

    if (isArray(data)) {
      return !isEmpty(data ?? []);
    }

    return !isNil(data);
  }

  // #endregion

  // #region -> (update methods)

  /** */
  protected update_axes(): void {
    super.update_axes();

    this.axes.temperature.axis.scale(this.scales.temperature).ticks(5);
    this.axes.temperature.container
      .transition()
      .duration(500)
      .call(this.axes.temperature.axis)
      .attr('transform', `translate(${this.margins.left}, ${this.margins.top})`);

    this.axes.temperature.container.selectAll('.tick').select('text').attr('fill', this.colors.temperature).attr('font-weight', 600);

    this.axes.pressure.axis.scale(this.scales.pressure).ticks(5);
    this.axes.pressure.container
      .transition()
      .duration(500)
      .call(this.axes.pressure.axis)
      .attr('transform', `translate(${this.box_sizing.width - this.margins.right}, ${this.margins.top})`);

    this.axes.pressure.container.selectAll('.tick').select('text').attr('fill', this.colors.pressure_def).attr('font-weight', 600);
  }

  // #endregion

  // #region -> (non-abstract methods)

  public resize(): void {
    this.axes.pressure.axis.scale(this.scales.pressure);
    this.axes.temperature.axis.scale(this.scales.temperature);

    this.labels.labels.temperature.attr('x', -(this.calc_size_of(this.box_sizing.height, ['-top', '-bottom']) / 2));
    this.labels.labels.pressure
      .attr('x', -(this.calc_size_of(this.box_sizing.height, ['-top', '-bottom']) / 2))
      .attr('y', this.calc_size_of(this.box_sizing.width, ['-left', '-right']) + 45);

    super.resize();
  }

  // #endregion

  // #region -> (abstract methods)

  protected create_chart_defs(): void {
    super.create_chart_defs();

    const temperature_gradient = this.definitions
      .append('linearGradient')
      .attr('id', 'temperatureGradient')
      .attr('x1', '0%')
      .attr('x2', '0%')
      .attr('y1', '0%')
      .attr('y2', '100%');
    temperature_gradient.append('stop').attr('offset', '0%').attr('stop-color', this.colors.temperature).attr('stop-opacity', 0.6);
    temperature_gradient.append('stop').attr('offset', '100%').attr('stop-color', '#FFFFFF').attr('stop-opacity', 0);
  }

  protected create_scales(): void {
    super.create_scales();

    this.scales.temperature = d3.scaleLinear().rangeRound([this.calc_size_of(this.box_sizing?.height, ['-top', '-bottom']), 0]);
    this.scales.pressure = d3.scaleLinear().rangeRound([this.calc_size_of(this.box_sizing?.height, ['-top', '-bottom']), 0]);
    this.scales.rain = d3.scaleLinear().rangeRound([this.calc_size_of((35 / 100) * this.box_sizing?.height, ['-top', '-bottom']), 0]);
  }

  public create_axes(): void {
    super.create_axes();

    this.axes.temperature.axis = d3.axisLeft(this.scales.temperature);
    this.axes.temperature.container = this.axes.container
      .append('g')
      .attr('class', 'd3-Y-axis d3-Y-axis-temperature')
      .attr('transform', `translate(${this.calc_size_of(0, ['+left'])}, ${this.calc_size_of(0, ['+top'])})`)
      .call(this.axes.temperature.axis);

    this.axes.pressure.axis = d3.axisRight(this.scales.pressure);
    this.axes.pressure.container = this.axes.container
      .append('g')
      .attr('class', 'd3-Y-axis d3-Y-axis-pressure')
      .attr('transform', `translate(${this.calc_size_of(0, ['+right'])}, ${this.calc_size_of(0, ['+top'])})`)
      .call(this.axes.pressure.axis);

    this.apply_axes_format();
  }

  protected create_labels(): void {
    super.create_labels();

    this.labels.labels.temperature = this.labels.container
      .append('text')
      .text(this._appState.translate.instant(i18n<string>('ALL.DATA.LABELS_SHORT_WITH_UNIT.Temperature (°C)')))
      .attr('transform', 'rotate(-90)')
      .attr('font-size', '10px')
      .attr('font-weight', 'bold')
      .attr('fill', this.colors.temperature)
      .attr('text-anchor', 'middle')
      .attr('x', -(this.calc_size_of(this.box_sizing.height, ['-top', '-bottom']) / 2))
      .attr('y', -25);

    this.labels.labels.pressure = this.labels.container
      .append('text')
      .text(this._appState.translate.instant(i18n<string>('ALL.DATA.LABELS_SHORT_WITH_UNIT.Atmospheric pressure (hPa)')))
      .attr('transform', 'rotate(-90)')
      .attr('font-size', '10px')
      .attr('font-weight', 'bold')
      .attr('fill', this.colors.pressure_def)
      .attr('text-anchor', 'middle')
      .attr('x', -(this.calc_size_of(this.box_sizing.height, ['-top', '-bottom']) / 2))
      .attr('y', this.calc_size_of(this.box_sizing.width, ['-left', '-right']) + 45);
  }

  public build_event_objects(): void {
    this.user_events.focus_line_y = this.create_focus_line('y', this.user_events.container);

    this.user_events.weather.container = this.create_html_tooltip(this.svg_parent);

    this.data_objects.temperature.line.path = this.data_objects.container
      .append('path')
      .style('fill', 'none')
      .style('stroke', this.colors.temperature)
      .style('stroke-width', '2px');
    this.data_objects.temperature.area.path = this.data_objects.container.append('path').style('fill', 'url(#temperatureGradient)');

    this.data_objects.pressure.low.path = this.data_objects.container
      .append('path')
      .style('fill', 'none')
      .style('stroke', this.colors.pressure_low)
      .style('stroke-width', '2px');
    this.data_objects.pressure.high.path = this.data_objects.container
      .append('path')
      .style('fill', 'none')
      .style('stroke', this.colors.pressure_high)
      .style('stroke-width', '2px');
    this.data_objects.pressure.nominal.path = this.data_objects.container
      .append('path')
      .style('fill', 'none')
      .style('stroke', this.colors.pressure_nominal)
      .style('stroke-width', '2px');

    this.data_objects.rain.container = this.data_objects.container.append('g').attr('class', 'd3-data-objects-container__rain');
    this.data_objects.rain.area.path = this.data_objects.container
      .append('path')
      .style('fill', 'rgba(0, 0, 255, 0.3)')
      .attr('transform', `translate(0, ${this.box_sizing?.height - this.calc_size_of((35 / 100) * this.box_sizing?.height, [])})`);

    this.data_objects.temperature.circle = this.user_events.container
      .append('circle')
      .attr('r', 4)
      .style('display', 'none')
      .attr('pointer-events', 'none')
      .attr('class', 'd3-focus-circle')
      .attr('fill', this.colors.temperature);

    this.data_objects.pressure.circle = this.user_events.container
      .append('circle')
      .attr('r', 4)
      .style('display', 'none')
      .attr('pointer-events', 'none')
      .attr('class', 'd3-focus-circle')
      .attr('fill', this.colors.pressure_def);
  }

  /**
   * @public
   * @method
   * @override
   * @description
   *
   *
   * @param is_from_shared_cursor
   */
  public on_mouse_enter(is_from_shared_cursor = false): void {
    super.on_mouse_enter(is_from_shared_cursor);

    if (!this.has_data) {
      return;
    }

    this.user_events.weather.container.style('display', null);
    this.data_objects.temperature.circle.style('display', null);
  }

  /**
   * @public
   * @method
   * @override
   * @description
   *
   *
   * @param event
   * @param data
   * @param is_from_shared_cursor
   */
  public on_mouse_move(event: MouseEvent | Date, data: RGJobData, is_from_shared_cursor = false): void {
    if (!this.has_data) {
      return null;
    }

    let pointed_position_x: number = null;
    let pointed_position_y: number = null;

    if (is_from_shared_cursor) {
      pointed_position_x = this.scales.time(event as Date);
    } else {
      const source_element = (event as MouseEvent).target as SVGGElement;
      const boundings = source_element.getBoundingClientRect();

      pointed_position_x = (event as MouseEvent).clientX - boundings.left;
      pointed_position_y = (event as MouseEvent).clientY - boundings.top;
    }

    const weather_points = data.points;

    const index = d3.bisectCenter(
      weather_points?.map(v => v.tz_date),
      this.scales.time.invert(pointed_position_x)
    );

    const closest_point = weather_points?.[index] ?? null;

    if (!is_from_shared_cursor) {
      this._shared_cursor.shared_cursor = {
        date: closest_point?.tz_date ?? null,
        from: this.unique_id,
        event_type: 'mousemove',
      };
    }

    let actual_pressure_text: string = null;
    if (!isNil(closest_point?.pressure)) {
      actual_pressure_text =
        closest_point?.pressure?.zone === 'low'
          ? i18n<string>('ALL.DATA.SPEC.PRESSURE.Low-pressure area')
          : closest_point?.pressure?.zone === 'high'
          ? i18n<string>('ALL.DATA.SPEC.PRESSURE.High-pressure area')
          : i18n<string>('ALL.DATA.SPEC.PRESSURE.Normal pressure area');
    }

    let template = this.create_tooltip_header(closest_point?.date);

    template += `<div class="d3-chart-tooltip-list-item">`;

    template += '<div class="d3-chart-tooltip-series-name">';
    template += `<span class="mdi mdi-minus-thick" style="color: ${this.colors.temperature}"></span>`;
    template += `${this._appState.translate.instant(i18n<string>('ALL.DATA.LABELS_FULL.Temperature'))}`;
    template += '</div>';

    template += '<div class="d3-chart-tooltip-value">';
    template += `${closest_point?.temperature?.toFixed(1) ?? '?'} ${this._appState.translate.instant(i18n<string>('ALL.DATA.UNITS.°C'))}`;
    template += '</div>';

    template += '</div>';

    template += `<div class="d3-chart-tooltip-list-item">`;

    template += '<div class="d3-chart-tooltip-series-name">';
    template += `<span class="mdi mdi-minus-thick" style="color: ${this.colors.pressure_def}"></span>`;
    template += `${this._appState.translate.instant(i18n<string>('ALL.DATA.LABELS_FULL.Atmospheric pressure'))}`;
    template += '</div>';

    template += '<div class="d3-chart-tooltip-value">';
    template += `${closest_point?.pressure?.value?.toFixed(1) ?? '?'} ${this._appState.translate.instant(
      i18n<string>('ALL.DATA.UNITS.hPa')
    )}${actual_pressure_text ? ` (${this._appState.translate.instant(actual_pressure_text)})` : ''}`;
    template += '</div>';

    template += '</div>';

    template += `<div class="d3-chart-tooltip-list-item">`;

    template += '<div class="d3-chart-tooltip-series-name">';
    template += `<span class="mdi mdi-minus-thick" style="color: blue"></span>`;
    template += `${this._appState.translate.instant(i18n<string>('ALL.DATA.LABELS_FULL.Rain (fall / cumulated)'))}`;
    template += '</div>';

    template += '<div class="d3-chart-tooltip-value">';
    template += `${closest_point?.rain?.toFixed(1) ?? '?'} / ${
      closest_point?.rain_cumul_day?.toFixed(1) ?? '?'
    } ${this._appState.translate.instant(i18n<string>('ALL.DATA.UNITS.mm'))}`;
    template += '</div>';

    template += '</div>';

    // Update tooltip position
    const is_in_first_half = pointed_position_x < this.user_events.event_bounds.node().width.baseVal.value / 2;
    if (is_in_first_half) {
      this.user_events.weather.container
        .html(template)
        .style('left', `${this.calc_size_of(pointed_position_x + 10, ['+left'])}px`)
        .style('top', `${this.user_events.event_bounds.node().getBBox().height / 2}px`);
    } else {
      this.user_events.weather.container
        .html(template)
        .style('left', `${pointed_position_x + this.margins.left - 1 - this.user_events.weather.container.node().clientWidth - 10}px`)
        .style('top', `${this.user_events.event_bounds.node().getBBox().height / 2}px`);
    }

    // Update the focus_line_x position
    this.user_events.focus_line_y
      .attr('x1', this.scales.time(this.scales.time.invert(pointed_position_x)))
      .attr('x2', this.scales.time(this.scales.time.invert(pointed_position_x)));

    // Update circle positions (depending on date)
    if (isNil(closest_point?.tz_date)) {
      this.data_objects.pressure.circle.style('display', 'none');
      this.data_objects.temperature.circle.style('display', 'none');
    }

    // Update temperature circle
    if (isNil(closest_point?.temperature)) {
      this.data_objects.temperature.circle.style('display', 'none');
    } else {
      this.data_objects.temperature.circle.style('display', null);
      this.data_objects.temperature.circle.attr(
        'transform',
        `translate(${this.scales.time(closest_point.tz_date.getTime())},${this.scales.temperature(closest_point.temperature)})`
      );
    }

    // Update pressure circle
    if (isNil(closest_point?.pressure?.value)) {
      this.data_objects.pressure.circle.style('display', 'none');
    } else {
      this.data_objects.pressure.circle.style('display', null);
      this.data_objects.pressure.circle.attr(
        'transform',
        `translate(${this.scales.time(closest_point.tz_date.getTime())},${this.scales.pressure(closest_point.pressure.value)})`
      );
    }
  }

  public on_mouse_out(is_from_shared_cursor = false): void {
    super.on_mouse_out(is_from_shared_cursor);

    this.user_events.weather.container.style('display', 'none');

    this.data_objects.pressure.circle.style('display', 'none');
    this.data_objects.temperature.circle.style('display', 'none');
  }

  public append_data(is_after_resize: boolean): void {
    this.scales.time.domain([this.date_range.start, this.date_range.end]);

    if (!this.has_data) {
      this.scales.temperature.domain([10, 20]);
      this.scales.pressure.domain([1000, 1028]);

      this.data_objects.pressure.low.path?.attr('d', '');
      this.data_objects.pressure.high.path?.attr('d', '');
      this.data_objects.pressure.nominal.path?.attr('d', '');

      this.data_objects.temperature.line.path.attr('d', '');
      this.data_objects.temperature.area.path.attr('d', '');

      this.data_objects.rain.area.path.attr('d', '');
      this.data_objects.rain.container.selectAll('text').remove();

      super.append_data(is_after_resize);
      return;
    }

    const points: WeatherDataPoint[] = this.incoming_data?.points;

    // Search min value for temperature
    const min_temperature_value = d3.min(
      points.filter(weather_data_point => !isNil(weather_data_point.temperature)),
      (c: WeatherDataPoint) => c.temperature - 5
    );

    // Search max value for temperature
    const max_temperature_value = d3.max(
      points.filter(weather_data_point => !isNil(weather_data_point.temperature)),
      (c: WeatherDataPoint) => c.temperature + 5
    );

    // Search min value for pressure
    const min_pressure_value =
      d3.min(
        points.filter(weather_data_point => !isNil(weather_data_point.pressure.value)),
        (c: WeatherDataPoint) => c.pressure.value
      ) - 5;

    // Search max value for pressure
    const max_pressure_value = d3.max(
      points.filter(weather_data_point => !isNil(weather_data_point.pressure)),
      (c: WeatherDataPoint) => c.pressure.value + 5
    );

    // Update Y-Axes ranges
    this.scales.temperature.domain([min_temperature_value, max_temperature_value]);
    this.scales.pressure.domain([min_pressure_value, max_pressure_value]);

    this.scales.rain.domain([
      0,
      d3.max(
        points.filter(weather_data_point => !isNil(weather_data_point.rain_cumul_day)),
        (c: WeatherDataPoint) => c.rain_cumul_day
      ),
    ]);

    // Update data of path
    this.data_objects.temperature.line.shape
      .x(_point => this.scales.time(_point.tz_date))
      .y(_point => this.scales.temperature(_point.temperature));
    this.data_objects.temperature.line.path.attr('d', this.data_objects.temperature.line.shape(points));

    this.data_objects.temperature.area.shape
      .x(point => this.scales.time(point.tz_date))
      .y1(point => this.scales.temperature(point.temperature))
      .y0(() => this.scales.temperature(min_temperature_value));
    this.data_objects.temperature.area.path.attr('d', this.data_objects.temperature.area.shape(points));

    this._draw_pressure_lines(points);

    this.data_objects.rain.container.selectAll('text').remove();

    /** */
    let data_by_day = group_data_by_day(points);
    data_by_day = sortBy(data_by_day, datum => datum.date_of_day);

    data_by_day.map(datum => {
      const max_cumul = max(datum.values.map(_value => _value.rain_cumul_day));

      if ((max_cumul ?? 0) > 0) {
        this.data_objects.rain.container
          .append('text')
          .attr('font-size', '10px')
          .attr('fill', 'blue')
          .attr('text-anchor', 'middle')
          .attr('x', this.scales.time(setHours(datum.date_of_day, 12)))
          .attr('y', this.calc_size_of(this.box_sizing.height, ['-top', '-bottom']) + 10)
          .text(() => {
            if (this.days_to_display > 7) {
              return `${max_cumul?.toFixed(1)}`;
            }

            return `${max_cumul?.toFixed(1)} mm.`;
          });
      }
    });

    this.data_objects.rain.area.shape
      .x(point => this.scales.time(point.tz_date))
      .y1(point => this.scales.rain(point.rain_cumul_day))
      .y0(() => this.scales.rain(0));
    this.data_objects.rain.area.path.attr('d', this.data_objects.rain.area.shape(points));

    super.append_data(is_after_resize);
  }

  /** */
  private _draw_pressure_lines(points: WeatherDataPoint[]): void {
    this.data_objects.pressure.low.shape
      .x(_point => this.scales.time(_point.tz_date))
      .y(_point => this.scales.pressure(_point.pressure.value))
      .defined((point, index, self) => {
        // Keep low-pressure points if :
        //  -> Pressure value is not nil
        //  -> Pressure value is considered "low"
        //  -> Pressure value is "normal" and the previous was "low"

        if (isNil(point?.pressure?.value)) {
          return false;
        }

        const previous_point = self?.[index - 1] ?? null;

        if (isNil(previous_point)) {
          return point.pressure.zone === 'low';
        }

        if (point.pressure.zone === 'normal' && previous_point?.pressure?.zone === 'low') {
          return true;
        }

        return point.pressure.zone === 'low';
      });
    this.data_objects.pressure.low.path?.attr('d', this.data_objects.pressure.low.shape(points));

    this.data_objects.pressure.high.shape
      .x(_point => this.scales.time(_point.tz_date))
      .y(_point => this.scales.pressure(_point.pressure.value))
      .defined((point, index, self) => {
        // Keep high-pressure points if :
        //  -> Pressure value is not nil
        //  -> Pressure value is considered "high"
        //  -> Pressure value is "normal" and the previous was "high"

        if (isNil(point?.pressure?.value)) {
          return false;
        }

        const previous_point = self?.[index - 1] ?? null;

        if (isNil(previous_point)) {
          return point.pressure.zone === 'high';
        }

        if (point.pressure.zone === 'normal' && previous_point?.pressure?.zone === 'high') {
          return true;
        }

        return point.pressure.zone === 'high';
      });
    this.data_objects.pressure.high.path?.attr('d', this.data_objects.pressure.high.shape(points));

    this.data_objects.pressure.nominal.shape
      .x(_point => this.scales.time(_point.tz_date))
      .y(_point => this.scales.pressure(_point.pressure.value))
      .defined((point, index, self) => {
        // Keep normal-pressure points if :
        //  -> Pressure value is not nil
        //  -> Pressure value is considered "normal"
        //  -> Pressure value is "low" and the previous was "normal"
        //  -> Pressure value is "high" and the previous was "normal"

        if (isNil(point?.pressure?.value)) {
          return false;
        }

        const previous_point = self?.[index - 1] ?? null;

        if (isNil(previous_point)) {
          return point.pressure.zone === 'normal';
        }

        if (
          (point.pressure.zone === 'low' && previous_point?.pressure?.zone === 'normal') ||
          (point.pressure.zone === 'high' && previous_point?.pressure?.zone === 'normal')
        ) {
          return true;
        }

        return point.pressure.zone === 'normal';
      });
    this.data_objects.pressure.nominal.path?.attr('d', this.data_objects.pressure.nominal.shape(points));
  }

  // #endregion

  // #region -> (external chart events)

  protected on_language_updated(): void {
    super.on_language_updated();

    this.labels?.labels?.temperature
      ?.text(this._appState.translate.instant(i18n<string>('ALL.DATA.LABELS_SHORT_WITH_UNIT.Temperature (°C)')))
      ?.attr('x', -(this.calc_size_of(this.box_sizing.height, ['-top', '-bottom']) / 2));

    this.labels?.labels?.pressure
      ?.text(this._appState.translate.instant(i18n<string>('ALL.DATA.LABELS_SHORT_WITH_UNIT.Atmospheric pressure (hPa)')))
      ?.attr('x', -(this.calc_size_of(this.box_sizing.height, ['-top', '-bottom']) / 2));
  }

  // #endregion
}

// #endregion
