import { Pipe, PipeTransform } from '@angular/core';

import { Country } from 'app/widgets/misc-widgets/custom-form-field/intl-phone-input/model/country.model';

@Pipe({
  name: 'search',
})
export class SearchPipe implements PipeTransform {
  transform(country: Country, searchCriteria?: string): boolean {
    if (!searchCriteria || searchCriteria === '') {
      return true;
    }

    return `${country.name}+${country.dialCode}`.toLowerCase().includes(searchCriteria.toLowerCase());
  }
}
