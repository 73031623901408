import { isBefore, isAfter } from 'date-fns';

import { get, intersection, isArray, isEmpty, isEqual, isFunction, isNil, isNumber, isObject, isString } from 'lodash-es';

import { lz_string } from './misc/encoders';
import { parseDate } from './dates';
import { Dictionary } from 'app/typings/core/interfaces';

import { environment } from 'environments/environment';

export const encodeJson = (json: Dictionary<any>): string => {
  const stringified_json = JSON.stringify(json ?? {});
  return lz_string.compress(stringified_json);
};

export const decodeJson = (json: string): string => lz_string.decompress(json);

/**
 * Parse current version
 *
 * @example Release `release-bg2019-07-r15` should become `2019-07`
 * @example Release `release-bg2019-11-r0` should become `2019-11`
 */
export const app_version_parser = (version_to_parse: string): string => {
  // Handle DEV_VERSION
  if (
    version_to_parse === 'DEV_VERSION' ||
    version_to_parse === 'dev' ||
    version_to_parse === 'preprod' ||
    !version_to_parse.includes('release-bg') ||
    isNil(version_to_parse)
  ) {
    // return dfns_format(new Date(), 'yyyy-MM');
    version_to_parse = 'release-bg0000-00-r0';
  }

  // Format current version
  const current_release = version_to_parse; // Should give `release-bg2019-07-r15` or `release-bg2019-07-12-r0`
  const app_version = current_release.split('bg')[1]; // Should give `2019-07-r15` or `2019-07-12-r0`
  const calendar_version = app_version.split(/(-r[0-9]*)/g)[0]; // Should give `2019-07` or `2019-07-12`

  // Should give `2019-07` or `2019-07-12`
  return calendar_version;
};

export const getQueryParam = (name: string) => {
  const url = window.location.href;
  name = name.replace(/[[]]/g, '$&');
  const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)');
  const results = regex.exec(url);
  if (!results) {
    return null;
  }
  if (!results[2]) {
    return '';
  }
  return decodeURIComponent(results[2].replace('/+/g', ' '));
};

/**
 * Helper to extract msg from http error response
 */
export const extractErrorMsg = (err: any) => {
  console.log(`HTTP ERROR: ${err.status}`);
  const server_error = isNil(err.error) ? null : err.error;
  let error = '';
  if (server_error && server_error.error) {
    error = server_error.error;
  } else {
    if (err.status === 500) {
      error = "ERROR.Server error, this shouldn't arrive !";
    } else {
      error = 'ERROR.Unknow error';
    }
  }
  return error;
};

/**
 * Force input value to be an array.
 *
 * forceArray(45) // = [45, ]
 * forceArray([45, 34]) // = [45, 34]
 *
 * @param value input value
 */
export const forceArray = <T>(value: T | T[]): T[] => {
  if (isArray(value)) {
    return value;
  } else if (value && !isNil(value)) {
    return [value];
  } else {
    return [];
  }
};

/**
 * Check if is Float
 */
export const isFloat = (input: any) => +input === input && (!isFinite(input) || Boolean(input % 1));

export enum CompareByType {
  STRING,
  NUMBER,
  DATE,
  NULL,
  MIXED_STRING_NUMBER,
}

export const compareObjectsByType = (compare_by: CompareByType | ((obj_a: any, obj_b: any) => any), value_a: any, value_b: any): any => {
  // Manage null values => devices values ara arrays so check if _.isEmpty, others check with _.isNil
  if ((isNil(value_a) && isNil(value_b)) || (isArray(value_a) && isArray(value_b) && isEmpty(value_a) && isEmpty(value_b))) {
    return 0;
  } else if (isNil(value_a) || (isArray(value_a) && isEmpty(value_a))) {
    return -1;
  } else if (isNil(value_b) || (isArray(value_b) && isEmpty(value_b))) {
    return 1;
  }

  if (isFunction(compare_by)) {
    return compare_by(value_a, value_b);
  } else {
    switch (compare_by) {
      case CompareByType.MIXED_STRING_NUMBER: {
        const is_number = (v: any) => (+v).toString() === v;
        const value_a_part = value_a.toString().match(/\d+|\D+/g);
        const value_b_part = value_b.toString().match(/\d+|\D+/g);

        let i = 0;
        let len = Math.min(value_a_part.length, value_b_part.length);

        while (i < len && value_a_part[i] === value_b_part[i]) {
          i++;
        }

        if (i === len) {
          return value_a_part.length - value_b_part.length;
        }

        if (is_number(value_a_part[i]) && is_number(value_b_part[i])) {
          return value_a_part[i] - value_b_part[i];
        }

        return value_a_part[i].localeCompare(value_b_part[i]);
      }

      case CompareByType.STRING: {
        if (!isString(value_a)) {
          value_a = value_a.toString();
        }
        if (!isString(value_b)) {
          value_b = value_b.toString();
        }
        return (value_a as string).localeCompare(value_b);
      }

      case CompareByType.NUMBER: {
        if (!isNumber(value_a)) {
          value_a = parseInt(value_a, 10);
        }
        if (!isNumber(value_b)) {
          value_b = parseInt(value_b, 10);
        }
        if (value_a < value_b) {
          return -1;
        }
        if (value_a > value_b) {
          return 1;
        }
        return 0;
      }

      case CompareByType.DATE: {
        value_a = parseDate(value_a);
        value_b = parseDate(value_b);

        if (isBefore(value_a, value_b)) {
          return -1;
        }
        if (isAfter(value_a, value_b)) {
          return 1;
        }
        return 0;
      }

      default: {
        throw new Error(`The cast type ${compare_by} is not handled`);
      }
    }
  }
};

/**
 * Extracts all the keys (including deep) from object.
 *
 * @param obj The object to extract all the keys.
 * @returns Returns an array of all usable keys of object.
 * @example
 * const obj = { a: 5, b: [{ c: 3 }] };
 * const r = getDeepKeys(obj); // r = ['a', 'b', 'b.0', 'b.0.c']
 */
export const getDeepKeys = (obj: Dictionary<any>): string[] => {
  let keys: any[] = [];
  Object.keys(obj).forEach(key => {
    keys.push(key);

    if (isObject(obj[key])) {
      const sub_keys = getDeepKeys(obj[key]);
      keys = keys.concat(sub_keys.map(sub_key => `${key}.${sub_key}`));
    }
  });
  return keys;
};

export const extractDiffInKeys = <T>(new_object: T, old_object: T): string[] => {
  const keys = getDeepKeys(new_object);

  return keys.filter((key: string) => {
    const is_same_value = isEqual(get(new_object, key), get(old_object, key));
    const is_key_is_object = isObject(get(new_object, key));

    return !is_same_value && !is_key_is_object;
  });
};

export const containsAll = (search: string[], into: string[]): boolean => intersection(search, into)?.length === search?.length;

export const strEnum = <T extends string>(o: Array<T>): { [K in T]: K } => {
  return o.reduce((res, key) => {
    res[key] = key;
    return res;
  }, Object.create(null));
};

export const string_format = (template: string) => (data: Dictionary<any>) =>
  Object.keys(data).reduce((acc, key) => acc.replace(`\$\{${key}\}`, data[key]), template);

export const find_closest = (goal: number, in_array: number[]) => {
  let curr = in_array[0],
    diff = Math.abs(goal - curr);

  let index = 0;

  for (let val = 0; val < in_array.length; val++) {
    let newdiff = Math.abs(goal - in_array[val]);

    if (newdiff < diff) {
      diff = newdiff;
      curr = in_array[val];
      index = val;
    }
  }

  return index;
};

/**
 * Converts absolute atmospheric pressure to sea level (http://www.wind101.net/sea-level-pressure-advanced/sea-level-pressure-advanced.html).
 *
 * @param atmo_pressure The measured atmospheric pressure on the station in [hPA].
 * @param measured_temp The measured temperature on the station in [°C].
 * @param altitude The altitude of the station in [m].
 * @param latitude The latitude of the station in [deg]
 *
 * @returns Returns the atmospheric pressure at sea level.
 */
export const compute_pressure_at_sea_level = (atmo_pressure: number, measured_temp: number, altitude: number, latitude: number) => {
  const msl_corrections = (h: number, t: number, l: number) => {
    // Conversions
    var phi = (l * Math.PI) / 180; // To convert latitude in grad

    // Constants
    const b = 1013.25; //barometric pressure of the air column [hPa]
    const K = 18400.0; //barometrics constant [m]
    const a = 0.0037; //coefficient of thermal expansion of the air
    const k = 0.0026; //constant depending on the figure of the earth
    const lr = 0.005; //is calculated assuming a temperature gradient of 0.5 degC/100 metres.	[C/m]
    const R = 6367324; //radius of the earth [m]

    // Calculation
    //delta h [m]  ..0m is mean sea level
    var dZ = h - 0;
    //average temperature of air column [C]
    var at = t + (lr * dZ) / 2;
    //vapor pressure h2o [hPa]
    var e = Math.pow(10, (7.5 * at) / (237.3 + at)) * 6.1078;

    //correction for atmpsferic temperature
    var corT = 1 + a * at;
    // correction for humidity
    var corH = 1 / (1 - 0.378 * (e / b));
    //correction for asphericity of earth (latitude)
    var corE = 1 / (1 - k * Math.cos(2 * phi));
    //correction for variation of gravity with height
    var corG = 1 + h / R;

    return dZ / (K * corT * corH * corE * corG);
  };

  return atmo_pressure * Math.pow(10, msl_corrections(altitude, measured_temp, latitude));
};

/**
 * Computes a barometric pressure from sea-level pressure. (http://www.wind101.net/sea-level-pressure-advanced/sea-level-pressure-advanced.html).
 *
 * @param pressure Sea-level pressure in [hPa].
 * @param temperature Measured temperature on the station in [°C].
 * @param altitude Altitude of the weather station in [m].
 * @param latitude Latitude of the station in [deg].
 *
 */
export const computes_barometric_pressure_from_sea_pressure = (
  pressure: number,
  temperature: number,
  altitude: number,
  latitude: number
) => {
  if (isNil(pressure) || isNil(temperature) || isNil(altitude) || isNil(latitude)) {
    return null;
  }

  const msl_corrections = (h: number, t: number, l: number) => {
    // Conversions
    var phi = (l * Math.PI) / 180; // To convert latitude in grad

    // Constants
    const b = 1013.25; //barometric pressure of the air column [hPa]
    const K = 18400.0; //barometrics constant [m]
    const a = 0.0037; //coefficient of thermal expansion of the air
    const k = 0.0026; //constant depending on the figure of the earth
    const lr = 0.005; //is calculated assuming a temperature gradient of 0.5 degC/100 metres.	[C/m]
    const R = 6367324; //radius of the earth [m]

    // Calculation
    //delta h [m]  ..0m is mean sea level
    var dZ = h - 0;
    //average temperature of air column [C]
    var at = t + (lr * dZ) / 2;
    //vapor pressure h2o [hPa]
    var e = Math.pow(10, (7.5 * at) / (237.3 + at)) * 6.1078;

    //correction for atmpsferic temperature
    var corT = 1 + a * at;
    // correction for humidity
    var corH = 1 / (1 - 0.378 * (e / b));
    //correction for asphericity of earth (latitude)
    var corE = 1 / (1 - k * Math.cos(2 * phi));
    //correction for variation of gravity with height
    var corG = 1 + h / R;

    return dZ / (K * corT * corH * corE * corG);
  };

  return pressure / Math.pow(10, msl_corrections(altitude, temperature, latitude));
};

export const compute_weather_icon = (
  total_rain: number,
  pressure: { mean: number; limit_up: number; limit_down: number },
  wind_speed: number
):
  | 'cloudy-windy'
  | 'partly-windy'
  | 'sunny-windy'
  | 'cloudy-rainy-windy'
  | 'partly-rainy-windy'
  | 'cloudy-pouring-windy'
  | 'partly-pouring-windy'
  | 'cloudy'
  | 'partly'
  | 'sunny'
  | 'cloudy-rainy'
  | 'partly-rainy'
  | 'cloudy-pouring'
  | 'partly-pouring'
  | 'unknown' => {
  // Entry data :
  //    rain          (no / a little / a lot)
  //    pressure      (low / normal / high)
  //    wind speed    (no / yes)

  // Possible icons :
  //    (icon_name)           -> (rain)   | (pressure) | (wind speed) | (icon design)
  //    cloudy-windy          -> no       | low        | yes          | cloud + wind
  //    partly-windy          -> no       | normal     | yes          | cloud + small sun + wind-1
  //    sunny-windy           -> no       | high       | yes          | large sun + wind-2
  //    cloudy-rainy-windy    -> a little | low        | yes          | cloud + small rain + wind-1
  //    partly-rainy-windy    -> a little | normal     | yes          | cloud + small rain + small sun + wind
  //                          -> a little | high       | yes          | [not existent] => maybe the pressure is bugged (ignore it)
  //    cloudy-pouring-windy  -> a lot    | low        | yes          | cloud + large rain + wind-1
  //    partly-pouring-windy  -> a lot    | normal     | yes          | cloud + large rain + wind-1
  //                          -> a lot    | high       | yes          | [not existent] => maybe the pressure or the rain is bugged (ignore it)
  //    cloudy                -> no       | low        | no           | cloud
  //    partly                -> no       | normal     | no           | cloud + small sun
  //    sunny                 -> no       | high       | no           | large sun
  //    cloudy-rainy          -> a little | low        | no           | cloud + small rain
  //    partly-rainy          -> a little | normal     | no           | cloud + small rain + small sun
  //                          -> a little | high       | no           | [not existent] => maybe the pressure is bugged (ignore it)
  //    cloudy-pouring        -> a lot    | low        | no           | cloud + large rain
  //    partly-pouring        -> a lot    | normal     | no           | cloud + large rain
  //                          -> a lot    | high       | no           | [not existent] => maybe the pressure or the rain is bugged (ignore it)

  const has_wind = !isNil(wind_speed) && wind_speed > environment.config.data.amemo.min_speed_for_valid_heading;
  const pressure_range = pressure?.mean > pressure?.limit_up ? 'high' : pressure?.mean < pressure?.limit_down ? 'low' : 'normal';
  const rain = total_rain > 4 ? 'a lot' : total_rain > 0 ? 'a little' : 'no';

  let icon_name:
    | 'cloudy-windy'
    | 'partly-windy'
    | 'sunny-windy'
    | 'cloudy-rainy-windy'
    | 'partly-rainy-windy'
    | 'cloudy-pouring-windy'
    | 'partly-pouring-windy'
    | 'cloudy'
    | 'partly'
    | 'sunny'
    | 'cloudy-rainy'
    | 'partly-rainy'
    | 'cloudy-pouring'
    | 'partly-pouring'
    | 'unknown' = null;

  if (has_wind) {
    if (rain === 'no') {
      if (pressure_range === 'low') icon_name = 'cloudy-windy';
      else if (pressure_range === 'normal') icon_name = 'partly-windy';
      else icon_name = 'sunny-windy';
    } else if (rain === 'a little') {
      if (pressure_range === 'low') icon_name = 'cloudy-rainy-windy';
      else if (pressure_range === 'normal') icon_name = 'partly-rainy-windy';
      else icon_name = 'partly-rainy-windy';
    } else {
      if (pressure_range === 'low') icon_name = 'cloudy-pouring-windy';
      else if (pressure_range === 'normal') icon_name = 'partly-pouring-windy';
      else icon_name = 'partly-pouring-windy';
    }
  } else {
    if (rain === 'no') {
      if (pressure_range === 'low') icon_name = 'cloudy';
      else if (pressure_range === 'normal') icon_name = 'partly';
      else icon_name = 'sunny';
    } else if (rain === 'a little') {
      if (pressure_range === 'low') icon_name = 'cloudy-rainy';
      else if (pressure_range === 'normal') icon_name = 'partly-rainy';
      else icon_name = 'partly-rainy';
    } else {
      if (pressure_range === 'low') icon_name = 'cloudy-pouring';
      else if (pressure_range === 'normal') icon_name = 'partly-pouring';
      else icon_name = 'partly-pouring';
    }
  }

  return icon_name;
};

/**
 * Check that all direct properties are nil.
 *
 * @param obj
 *
 * @returns Returns `true` if all the properties are null else `false`
 */
export const is_all_properties_null = (obj: Dictionary<any>) => Object.values(obj).every(v => isNil(v));
