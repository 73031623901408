import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { DeviceApi } from 'app/core';
import { DeviceSupportIssue } from 'app/core/api-swagger/device';
import { AppStateService } from 'app/core/app-state.service';
import { ZohoDeskApiService } from 'app/core/services/zoho/zoho-desk-api.service';
import { catchErrorInDialog, distinctUntilRealChanged, replay } from '@bg2app/tools/rxjs';
import { DeviceSupportType, DRDevice, SimpleSetterGetter } from 'app/models';
import { ZohoDeskTicketComment } from 'app/models/zoho/desk';

import { AbstractModalComponent, DialogsService } from 'app/widgets/dialogs-modals';

import { ModalParams } from 'app/widgets/dialogs-modals/abstract-modal.component';
import { ErrorHelperData } from 'app/widgets/widgets-reusables/errors/error-helper/error-helper.component';
import { flatten, isEmpty, isNil, uniq } from 'lodash-es';
import { catchError, combineLatest, concatMap, forkJoin, map, Observable, of, switchMap, tap } from 'rxjs';

/** */
interface DeviceXSupport {
  /** */
  device: DRDevice;

  /** */
  support: DeviceSupportIssue;
}

/** */
export interface DeviceSupportUpdateDeskTicketModalParams extends ModalParams {
  /** */
  imeis: string;
}

@Component({
  selector: 'bg2-device-support-update-desk-ticket',
  templateUrl: './device-support-update-desk-ticket-comments-modal.component.html',
  styleUrls: ['./device-support-update-desk-ticket-comments-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DeviceSupportUpdateDeskTicketModalComponent
  extends AbstractModalComponent<DeviceSupportUpdateDeskTicketModalParams>
  implements OnInit, OnDestroy
{
  /** */
  protected handle_event_before_unload(event: BeforeUnloadEvent): void {
    return null;
  }

  // #region -> (component basics)

  constructor(
    private readonly _deviceApi: DeviceApi,
    private readonly _dialogs: DialogsService,
    private readonly _zohoDeskApi: ZohoDeskApiService,
    private readonly _appStateService: AppStateService
  ) {
    super();
  }

  ngOnInit(): void {}

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  // #endregion

  // #region -> (loadings)

  /** */
  protected is_loading_device = new SimpleSetterGetter(true);

  // #endregion

  // #region -> (device basis)

  /** */
  public device_imeis$$ = this.input_params$$.pipe(
    map(parameters => {
      const imeis_string = parameters?.imeis;
      const imeis = imeis_string.trim().split(',');

      if (isNil(imeis) || isEmpty(imeis)) {
        throw new Error('Missing or empty device IMEI !');
      }

      return imeis.map(imei => parseInt(imei, 10));
    }),
    replay()
  );

  /** */
  public devices$$: Observable<DRDevice[]> = this.device_imeis$$.pipe(
    tap(() => (this.is_loading_device.value = true)),
    switchMap(imeis => this._deviceApi.requestDevices(imeis).pipe(catchErrorInDialog(this._dialogs))),
    tap(device => {
      if (!isNil(device)) {
        this.is_loading_device.value = false;
        return;
      }

      this.is_loading_device.value = true;
    }),
    replay()
  );

  // #endregion

  // #region -> (device app-supports)

  /** */
  private _fetch_supports_for_devices$(devices: DRDevice[]): Observable<DeviceXSupport[]> {
    const support_x_device$$ = devices.map(device =>
      this._deviceApi
        .fetch_device_supports$(
          device.imei,
          null,
          null,
          {
            is_open: true,
            type: DeviceSupportType.RU_NO_SMS,
          }
        )
        .pipe(
          map(response => (response?.supports ?? [null])[0]),
          map(support => <DeviceXSupport>{ device, support })
        )
    );

    return combineLatest(support_x_device$$);
  }

  private _device_app_supports$$ = this.devices$$.pipe(
    switchMap(devices => {
      if (devices.length === 0) {
        return of<DeviceXSupport[]>([]);
      }

      return this._fetch_supports_for_devices$(devices);
    }),
    replay()
  );

  /**
   * List of devices without created support.
   */
  public devices_without_support$$ = this._device_app_supports$$.pipe(
    map(device_x_support_list =>
      device_x_support_list
        .filter(device_x_support => device_x_support?.support?.is_open === false || isNil(device_x_support?.support))
        .map(device_x_support => device_x_support?.device)
    ),
    replay()
  );

  // #endregion

  // #region -> (device related tickets)

  /** */
  public related_tickets$$ = this._device_app_supports$$.pipe(
    switchMap(device_app_supports => {
      const queries$$ = device_app_supports.map(composed => {
        const support = composed?.support;

        const query$ = this._zohoDeskApi.search_tickets({ id: support.issue_id });
        return query$;
      });

      return forkJoin(queries$$);
    }),
    map(results => {
      const tickets = results.map(result => result.data);
      return flatten(tickets);
    }),
    replay()
  );

  /** */
  public ticket$$ = this.related_tickets$$.pipe(
    map(related_ticket => related_ticket[0]),
    replay()
  );

  /** */
  public is_unique_ticket$$ = this.related_tickets$$.pipe(
    map(related_tickets => {
      console.log(related_tickets);

      const ticket_ids = related_tickets.map(ticket => ticket.id);
      const unique_ticket_id = uniq(ticket_ids);

      return unique_ticket_id.length === 1;
    }),
    distinctUntilRealChanged(),
    replay()
  );

  // #endregion

  // #region -> (unique ticket management)

  /** */
  public comments$$ = this.ticket$$.pipe(
    switchMap(ticket => ticket.id$$),
    switchMap(ticket_id => this._zohoDeskApi.fetch_ticket_comments$(ticket_id)),
    map(response => response.data),
    catchError(() => of(<ZohoDeskTicketComment[]>[])),
    replay()
  );

  /** */
  public selected_comment_to_edit = new SimpleSetterGetter<ZohoDeskTicketComment>(null);

  /** */
  public on_edit_comment(comment: ZohoDeskTicketComment): void {
    this.selected_comment_to_edit.value = comment;
  }

  /** */
  public on_cancel_comment(): void {
    this.selected_comment_to_edit.value = null;
  }

  /** */
  public on_save_comment(): void {
    this.selected_comment_to_edit.value = null;
  }

  /** */
  public is_editing_comment$$ = this.selected_comment_to_edit.value$$.pipe(
    map(selected_comment_to_edit => !isNil(selected_comment_to_edit)),
    distinctUntilRealChanged(),
    replay()
  );

  /** */
  public is_editing_comment_id$$ = (comment_id: string) =>
    this.selected_comment_to_edit.value$$.pipe(
      switchMap(selected_comment_to_edit => selected_comment_to_edit?.id$$ ?? of('-1')),
      map(id => id === comment_id),
      distinctUntilRealChanged(),
      replay()
    );

  // #endregion

  // #region -> (editor management)

  // #endregion

  public error_x: ErrorHelperData = {
    name: 'alpha_1',
    message: 'default',
    description: [
      {
        type: 'span',
        content: 'asanfnjaf',
      },
    ],
  };
}
