import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

import { marker as i18n } from '@biesbjerg/ngx-translate-extract-marker';

import { BehaviorSubject, catchError, combineLatest, concat, filter, map, Observable, of, switchMap, take } from 'rxjs';
import { replay, switchTap, waitForNotNilProperties, waitForNotNilValue } from '@bg2app/tools/rxjs';

import { isAfter, subHours } from 'date-fns/esm';

import { isNil, max } from 'lodash-es';

import { ConsoleLoggerService } from 'app/core/console-logger.service';

import { Apiary, DRDevice } from 'app/models';
import { strEnum } from 'app/misc/tools';

const view_type = strEnum(['last_data', 'data_chart', 'apiary_visits', 'ia', 'devices']);
type ViewType = keyof typeof view_type;

@Component({
  selector: 'bg2-apiary-details-compact',
  templateUrl: 'apiary-details-compact.component.html',
  styleUrls: ['apiary-details-compact.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ApiaryDetailsCompactComponent implements OnInit {
  // #region -> (component basics)

  /** */
  private readonly LOGGER = new ConsoleLoggerService('ApiaryDetailsCompactComponent', true);

  constructor() {
    this.compute_view_to_display$$.subscribe();
  }

  ngOnInit(): void {
    this.compute_view_to_display$$.pipe(take(1)).subscribe({
      next: view_to_display => {
        this._view_to_display$$.next(view_to_display);
        this.loadings.view_to_display$$.next(false);
      },
    });
  }

  /** */
  public track_by_devices(index: number, device: DRDevice) {
    return device.imei;
  }

  // #endregion

  // #region -> (apiary entity)

  /** */
  private _apiary$$ = new BehaviorSubject<Apiary>(null);

  /** */
  public apiary$$ = this._apiary$$.asObservable().pipe(waitForNotNilValue());

  /** */
  @Input()
  public set apiary(apiary: Apiary) {
    this._apiary$$.next(apiary);
  }

  /** */
  public apiary_id$$ = this.apiary$$.pipe(switchMap(apiary => apiary.id$$));

  // #endregion

  // #region -> (location entity)

  /** */
  private location$$ = this.apiary$$.pipe(switchMap(apiary => apiary.location$$));

  /** */
  public location_id$$ = this.location$$.pipe(
    filter(location => !isNil(location)),
    switchMap(location => location.id$$)
  );
  // #endregion

  // #region -> (exploitation entity)

  /** */
  private exploitation$$ = this.location$$.pipe(switchMap(location => location.exploitation$$));

  /** */
  public exploitation__has_devices$$ = this.exploitation$$.pipe(switchMap(exploitation => exploitation.has_devices$$));

  // #endregion

  // #region -> (devices properties)

  public devices$$ = this.apiary$$.pipe(switchMap(apiary => apiary.devices$$));

  /**
   * Observes the oldest device setup date among devices config.
   */
  public apiary_oldest_device_setup_date$$ = this.apiary$$.pipe(switchMap(apiary => apiary.apiary_oldest_device_setup_date$$));

  /**
   * Observes if there is devices on the apiary.
   */
  public has_devices$$ = this.apiary$$.pipe(switchMap(apiary => apiary.has_devices$$));

  /** */
  public has_only_gps$$ = this.apiary$$.pipe(switchMap(apiary => apiary.has_only_gps$$));

  /** */
  public devices_last_com$$ = this.apiary$$.pipe(
    switchMap(apiary => apiary.devices$$),
    switchMap(devices => combineLatest(devices.map(device => device.last_contact$$))),
    map(last_contact_dates => last_contact_dates.filter(last_contact_date => !isNil(last_contact_date))),
    map(last_contact_dates => max(last_contact_dates)),
    replay()
  );

  /**
   * Observes if the most oldest device has been setup since 24h.
   */
  public has_been_setup_today$$ = this.apiary_oldest_device_setup_date$$.pipe(
    map(setup_date => {
      if (isNil(setup_date)) {
        return null;
      }

      return isAfter(setup_date, subHours(new Date(), 24));
    })
  );

  // #endregion

  // #region -> (visit event management)

  /**
   * Observes the last apiary's last visit event.
   */
  private last_visit_event$$ = this.apiary$$.pipe(
    switchMap(apiary => apiary.fetch_last_evaluation_event$$(0)),
    replay()
  );

  /**
   * Observes the date of the apiary's last date.
   */
  public last_visit_date$$ = this.last_visit_event$$.pipe(
    switchMap(event => event?.date$$ ?? of(null)),
    replay()
  );

  /**
   * Observes if there is at least one visit event on the apiary.
   */
  private has_visit_event$$ = this.last_visit_event$$.pipe(map(last_visit_event => !isNil(last_visit_event)));

  // #endregion

  // #region -> (displayed view management)

  /** */
  private _view_to_display$$ = new BehaviorSubject<ViewType>(null);

  /** */
  public view_to_display$$ = this._view_to_display$$.asObservable();

  /** */
  public set view_to_display(view_to_display: ViewType) {
    this._view_to_display$$.next(view_to_display);
  }

  /** */
  private compute_view_to_display$$: Observable<ViewType> = combineLatest({
    has_devices: concat(of(null), this.has_devices$$),
    has_visits: concat(of(null), this.has_visit_event$$),
    available_ace: this.apiary$$.pipe(
      switchMap(apiary =>
        combineLatest({
          can_read_measurements_data: apiary?.user_acl?.can$$('read_measurements_data'),
          can_read_devices: apiary?.user_acl?.can$$('read_devices'),
          can_read_all_events: apiary?.user_acl?.can$$('read_all_events'),
        }).pipe(waitForNotNilProperties())
      )
    ),
  }).pipe(
    waitForNotNilProperties(),
    switchMap(({ has_devices, has_visits, available_ace }) => {
      const { can_read_all_events, can_read_devices, can_read_measurements_data } = available_ace;

      if (!can_read_all_events && !can_read_devices && !can_read_measurements_data) {
        return of(view_type.ia);
      }

      // Show data chart
      if (can_read_measurements_data) {
        if (!can_read_devices) {
          return of(view_type.data_chart);
        }

        if (can_read_devices && has_devices) {
          return combineLatest({ has_been_setup_today: this.has_been_setup_today$$ }).pipe(
            map(({ has_been_setup_today }) => {
              if (has_been_setup_today) {
                return view_type.last_data;
              }

              return view_type.data_chart;
            })
          );
        }
      }

      if (!can_read_measurements_data && can_read_devices) {
        return of(view_type.devices);
      }

      if (has_visits) {
        return of(view_type.apiary_visits);
      }

      return of(view_type.ia);
    }),
    replay()
  );

  // #endregion

  // #region -> (ia view)

  public ia_view_error$$ = this.apiary$$.pipe(
    switchTap(apiary =>
      // Check if the user can "read"
      apiary.user_acl.check_only__read_minimal$$({
        what: i18n<string>('ALL.ACE.READ.WHAT.edit this apiary'),
      })
    ),
    switchTap(apiary =>
      // Check if the user can "read_devices"
      apiary.user_acl.check_read_devices$$({
        what: i18n<string>('ALL.ACE.READ_DEVICES.WHAT.manage hives and devices'),
      })
    ),
    switchTap(apiary =>
      // Check if the user can "read_devices"
      apiary.user_acl.check_write_all_events$$({
        what: i18n<string>('ALL.ACE.WRITE_ALL_EVENTS.WHAT.create a visit event'),
      })
    ),
    map(() => null),
    catchError((error: unknown) => of(error))
  );

  // #endregion

  /** */
  public loadings = {
    /** */
    view_to_display$$: new BehaviorSubject(true),
  };
}
