// Angular modules
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

// External modules (node_modules)
import { TranslateModule, TranslateParser } from '@ngx-translate/core';

// Internal modules (shared)
import { MiscModule } from 'app/misc/misc.module';
import { MaterialSharedModule } from 'app/shared-module/material-shared.module';

import { FormsModule } from '@angular/forms';
import { PictoHiveComponent } from './picto-hive/picto-hive.component';
import { BgTranslateParser } from 'app/shared-module/translate.parser';

@NgModule({
  imports: [
    // Angular modules
    CommonModule,
    RouterModule,
    FormsModule,

    // External modules (node_modules)
    MiscModule,
    MaterialSharedModule,
    TranslateModule.forChild({
      parser: { provide: TranslateParser, useClass: BgTranslateParser },
    }),
  ],
  exports: [PictoHiveComponent],
  declarations: [PictoHiveComponent],
})
export class HivesSharedModule {}
