<!-- Global button content definition -->
<ng-template #buttonContentTemplate>
  <!-- hide-label-on-small-and-down -->
  <ng-content select="[role=button-loader]"></ng-content>
  <span
    class="button-principal"
    [ngClass]="{ 'button-stacked-icon': options?.is_icon_stacked, 'hide-label-on-small-and-down': options?.is_label_hidden_on_small }"
  >
    <hex-loading *ngIf="loading$$ | async" [size]="'tiny'"></hex-loading>
    <ng-container *ngLet="icon; let icon_name">
      <span
        *ngIf="icon_name?.startsWith('mdi-')"
        class="mdi {{ icon_name }} {{ options?.icon_size_class ?? 'mdi-24px' }}"
        [ngStyle]="{ marginRight: iconWithMargin ? '5px' : '0px' }"
      ></span>

      <img
        *ngIf="icon_name?.startsWith('cs-')"
        class="mdi custom-icon"
        [src]="'assets/' + icon_name.split('cs-')[1]"
        [ngStyle]="{ marginRight: iconWithMargin ? '5px' : '0px' }"
      />
    </ng-container>

    <span *ngIf="message" class="button-message">
      <ng-container *ngIf="isTemplate(message); let message_desc; else: notTemplateMessage">
        <ng-container [ngTemplateOutlet]="message_desc"></ng-container>
      </ng-container>

      <ng-template #notTemplateMessage>{{ message }}</ng-template>
    </span>
  </span>

  <span *ngIf="descriptive" class="button-descriptive">
    <ng-container *ngIf="isTemplate(descriptive); let template_desc; else: notTemplateDescriptive">
      <ng-container [ngTemplateOutlet]="template_desc"></ng-container>
    </ng-container>

    <ng-template #notTemplateDescriptive>{{ descriptive }}</ng-template>
  </span>
</ng-template>

<!-- Button type switcher -->
<ng-container [ngSwitch]="true">
  <!-- Flattened button definition -->
  <button
    *ngSwitchCase="isFlatButton"
    mat-flat-button
    [class.w-100]="options?.is_w100"
    [class.mat-button-thin]="options?.is_thin"
    [class.beta]="options?.has_flag_beta"
    [color]="color"
    [type]="btn_type"
    [ngStyle]="styles"
    [disabled]="disabled"
    [routerLink]="internal_routing?.routerLink"
    [queryParams]="internal_routing?.queryParams || {}"
    [queryParamsHandling]="internal_routing?.queryParamsHandling || 'preserve'"
    (click)="btn_click.next($event)"
  >
    <ng-container [ngTemplateOutlet]="buttonContentTemplate"></ng-container>
  </button>

  <!-- Stroked button definition -->
  <button
    *ngSwitchCase="isStrokedButton"
    mat-stroked-button
    [class.w-100]="options?.is_w100"
    [class.mat-button-thin]="options?.is_thin"
    [class.beta]="options?.has_flag_beta"
    [color]="color"
    [type]="btn_type"
    [ngStyle]="styles"
    [disabled]="disabled"
    [routerLink]="internal_routing?.routerLink"
    [queryParams]="internal_routing?.queryParams || {}"
    [queryParamsHandling]="internal_routing?.queryParamsHandling || 'preserve'"
    (click)="btn_click.next($event)"
  >
    <ng-container [ngTemplateOutlet]="buttonContentTemplate"></ng-container>
  </button>

  <ng-container *ngSwitchDefault>
    <span id="null-button-type">DEFAULT</span>
  </ng-container>
</ng-container>
