<div class="bg2-acl-tooltip-content-component">
  <span
    [innerHtml]="
      'ALL.ACE.COMMON.You can\'t **[what]** because you don\'t have the following permission: \'**[ace]**\''
        | translate: { what: what$$ | async | lowercase, ace: ace$$ | async }
        | markdown
    "
  ></span>

  <span [innerHtml]="i18n_solution$$ | async | markdown"></span>
</div>
