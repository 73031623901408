import { KeyValue } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';

import { isNil, max, some, values } from 'lodash-es';

import { map, switchMap, tap } from 'rxjs';
import { BehaviorSubject, concat, Observable, of } from 'rxjs';
import { distinctUntilRealChanged, replay } from '@bg2app/tools/rxjs';

import { Dictionary } from 'app/typings/core/interfaces';
import { ErrorHelperData } from '../../../errors/error-helper/error-helper.component';
import { ConsoleLoggerService } from 'app/core/console-logger.service';

export type SimpleHistogramData = { [key: string]: number };
export type SimpleHistogramConfig = Map<string, { img: string; label: string; key: string | number }>;

@Component({
  selector: 'bg2-simple-histogram-base',
  templateUrl: './simple-histogram-base.component.html',
  styleUrls: ['./simple-histogram-base.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class Bg2SimpleHistogramBaseComponent implements OnInit {
  public Math = Math;

  // #region -> (component basics)

  protected _logger = new ConsoleLoggerService('Bg2SimpleHistogramBaseComponent', true);

  ngOnInit(): void {}

  // #endregion

  // #region -> (histogram type)

  public histogram_type: 'queen-color' | 'hive-status' | 'hive-broodframe' | 'hive-super' = null;

  // #endregion

  // #region -> (error data management)

  private _error$$: BehaviorSubject<ErrorHelperData> = new BehaviorSubject<ErrorHelperData>(null);
  public error$$: Observable<ErrorHelperData> = this._error$$.asObservable().pipe(distinctUntilRealChanged());

  protected set error(error: ErrorHelperData) {
    this._error$$.next(error);
  }

  // #endregion

  // #region -> (histogram config management)

  private _display_zero_values$$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  public display_zero_values$$: Observable<boolean> = this._display_zero_values$$.pipe(distinctUntilRealChanged());

  protected set display_zero_values(display_zero_values: boolean) {
    this._display_zero_values$$.next(display_zero_values);
  }

  private _histogram_configuration$$: BehaviorSubject<SimpleHistogramConfig> = new BehaviorSubject<SimpleHistogramConfig>(null);
  public histogram_configuration$$: Observable<SimpleHistogramConfig> = this._histogram_configuration$$
    .asObservable()
    .pipe(distinctUntilRealChanged());

  public set histogram_configuration(histogram_configuration: SimpleHistogramConfig) {
    this._histogram_configuration$$.next(histogram_configuration);
  }

  public keepOriginalOrder = (a: KeyValue<any, any>, b: KeyValue<any, any>): number => 0;

  public shouldDisplayValue$$(value: number): Observable<boolean> {
    return of(value).pipe(
      switchMap(_value => {
        if ((_value ?? 0) === 0) {
          return this.display_zero_values$$;
        }

        return of(true);
      })
    );
  }

  // #endregion

  // #region -> (data management)

  protected set histogram_data(histogram_data: SimpleHistogramData) {
    this._histogram_data$$.next(histogram_data);
  }

  private _histogram_data$$: BehaviorSubject<SimpleHistogramData> = new BehaviorSubject<SimpleHistogramData>({});
  public histogram_data$$: Observable<SimpleHistogramData> = this._histogram_data$$.asObservable().pipe(distinctUntilRealChanged());

  /**
   * Observable to check if there is existing data.
   */
  public has_data$$: Observable<boolean> = this.histogram_data$$.pipe(
    // tap(data => this._logger.debug(data)),
    map(histogram_data => some(histogram_data, value => value !== 0)),
    distinctUntilRealChanged(),
    replay()
  );

  /**
   * Observable on the maximum possible value.
   */
  public max$$: Observable<number> = this.histogram_data$$.pipe(
    map((data: Dictionary<number>) => max([...values(data), 0])),
    distinctUntilRealChanged(),
    replay()
  );

  // #endregion

  // #region -> (loadings management)

  public is_loading = {
    data$$: concat(of(null), this.histogram_data$$).pipe(
      map(data => isNil(data)),
      distinctUntilRealChanged()
    ),
  };

  // #endregion
}
