<div *ngLet="device$$ | async; let device" class="bg2-device-modal-identification-component">
  <div *ngIf="is_either_view" class="device-card-template">
    <div *ngLet="device_affectation$$ | async; let affectation" class="device-card-template__normal-container">
      <div class="device-detail__title-container">
        <div class="title-container__icon --color-blue">
          <span class="mdi mdi-account mdi-36px"></span>
        </div>

        <ng-container *ngLet="has_base_affectation$$ | async; let is_affected_to_exploitation">
          <div class="title-container__title">
            <ng-container *ngIf="is_affected_to_exploitation">
              <bg2-loading-skeleton *ngIf="is_loading_affection.value" [string_model]="'expl_name'"></bg2-loading-skeleton>
              <ng-container *ngIf="!is_loading_affection.value">{{ affectation?.exploitation?.entity?.name$$ | async }}</ng-container>
            </ng-container>

            <ng-container *ngIf="!is_affected_to_exploitation">{{ 'Not affected' }}</ng-container>
          </div>

          <div class="title-container__sep">|</div>

          <div *ngLet="device_owner$$ | async; let device_owner" class="title-container__important-container">
            <bg2-loading-skeleton *ngIf="is_loading_owner.value" [string_model]="'owner_name'"></bg2-loading-skeleton>
            <ng-container *ngIf="!is_loading_owner.value">
              <a
                [routerLink]="['', { outlets: { modal: ['user_account', { user_id: device_owner?.user_id$$ | async }] } }]"
                [queryParamsHandling]="'preserve'"
              >
                {{ device_owner.name$$ | async }}
              </a>

              <ng-container *ngIf="is_superadmin$$ | async">
                <span
                  class="mdi mdi-account-convert clickable clickable-colorized"
                  [popper]="'VIEWS.MODALS.DEVICE.Change device\'s owner' | translate"
                  (click)="change_owner()"
                ></span>
              </ng-container>
            </ng-container>
          </div>
        </ng-container>
      </div>

      <div class="device-modal-card-container__body-container">
        <div class="body-container__properties">
          <!-- Replace device -->
          <ng-container *ngIf="is_superadmin$$ | async">
            <div class="body-container__property-container --only-superadmin">
              <div class="device-details-properties__property-container__label-container">
                <span class="mdi mdi-autorenew mdi-24px"></span>
              </div>

              <span class="device-details-properties__property-container__value">
                <a
                  [routerLink]="[
                    '',
                    {
                      outlets: {
                        modal: [
                          'new_event',
                          {
                            etype: 'device_replace',
                            args:
                              {
                                warehouse: device_warehouse_id$$ | async,
                                data: { old_device: { imei: device_imei$$ | async } }
                              } | json
                          }
                        ]
                      }
                    }
                  ]"
                  [queryParamsHandling]="'preserve'"
                >
                  {{ 'VIEWS.MODALS.DEVICE.Replace that device' | translate }}
                </a>
              </span>
            </div>
          </ng-container>

          <!-- Change device's exploitation -->
          <ng-container *ngIf="is_superadmin$$ | async">
            <div class="body-container__property-container --only-superadmin">
              <div class="device-details-properties__property-container__label-container">
                <span class="mdi mdi-factory mdi-24px"></span>
              </div>

              <span class="device-details-properties__property-container__value">
                <a (click)="change_owner_and_exploitation()" class="clickable">
                  {{ "VIEWS.MODALS.DEVICE.Change device's exploitation" | translate }}
                </a>
              </span>
            </div>
          </ng-container>
        </div>

        <div class="body-container__affectations">
          <ng-template #loadingAffectationValue>
            <bg2-loading-skeleton [string_model]="'loading value'" [theme]="{ height: '20px' }"></bg2-loading-skeleton>
          </ng-template>

          <!-- Exploitation -->
          <div
            *ngLet="affectation?.exploitation; let affected_exploitation"
            class="affectation-container"
            [ngClass]="{ '--not-affected': affected_exploitation === null }"
          >
            <div class="affectation-container__icon">
              <span class="mdi mdi-factory mdi-24px"></span>
            </div>

            <div class="affectation-container__body">
              <span class="affectation-container__body__title">{{ 'ENTITY.ALL.TYPE.exploitation' | translate }}</span>

              <span *ngIf="affected_exploitation === null" class="affectation-container__not-affected">
                <span class="mdi mdi-close-thick"></span>
                <span class="">{{ 'DEVICE.AFFECTATION.MISC.Not affected' | translate }}</span>
              </span>

              <div *ngIf="affected_exploitation !== null" class="affectation-container__body__properties">
                <div class="affectation-container__body__property">
                  <span class="affectation-container__body__property__label">{{ 'ALL.COMMON.Name' | translate | colon }}</span>
                  <ng-container *ngIf="(is_loading_affection.value$$ | async) === false; else loadingAffectationValue">
                    <span
                      class="affectation-container__body__property__value simili-link"
                      [routerLink]="[
                        '',
                        { outlets: { primary: 'exploitation/' + (affected_exploitation?.entity?.id$$ | async), modal: ['raz'] } }
                      ]"
                      [queryParamsHandling]="''"
                    >
                      {{ affected_exploitation?.entity?.name$$ | async }}
                    </span>
                  </ng-container>
                </div>

                <div class="affectation-container__body__property">
                  <span class="affectation-container__body__property__label">{{ 'ALL.COMMON.Since' | translate | colon }}</span>
                  <ng-container *ngIf="(is_loading_affection.value$$ | async) === false; else loadingAffectationValue">
                    <span class="affectation-container__body__property__value">
                      {{ affected_exploitation?.since?.date | formatDate : 'full' }}
                    </span>

                    <span
                      *ngLet="affected_exploitation?.entity?.user_acl?.can$$('write_all_events'); let can_write_all_events"
                      class="affectation-container__body__property__value"
                    >
                      <a
                        [routerLink]="[
                          '',
                          {
                            outlets: {
                              modal: ['update_event', { eid: affected_exploitation?.since?.event_id, ro: !can_write_all_events ? 1 : 0 }]
                            }
                          }
                        ]"
                        [queryParamsHandling]="'preserve'"
                      >
                        <span class="mdi" [ngClass]="{ 'mdi-pencil': can_write_all_events, 'mdi-eye': !can_write_all_events }"></span>
                      </a>
                    </span>
                  </ng-container>
                </div>

                <div
                  *ngIf="(exploitation_affectation_event$$ | async)?.data?.comment; let comment"
                  class="affectation-container__body__property"
                >
                  <span class="affectation-container__body__property__label" style="white-space: nowrap;">{{ 'ALL.COMMON.Comment' | translate | colon }}</span>
                  <ng-container *ngIf="(is_loading_affection.value$$ | async) === false; else loadingAffectationValue">
                    <span class="affectation-container__body__property__value">
                      {{ comment }}
                    </span>
                  </ng-container>
                </div>
              </div>
            </div>
          </div>

          <!-- Location / Apiary -->
          <div
            *ngLet="affectation?.location; let affected_location"
            class="affectation-container"
            [ngClass]="{ '--not-affected': affected_location === null }"
          >
            <div class="affectation-container__icon">
              <img src="/assets/picons/entities/location-normal.svg" alt="" />
            </div>

            <div class="affectation-container__body">
              <span class="affectation-container__body__title">
                <span>{{ 'ENTITY.ALL.TYPE.location' | translate }}</span>
                <span> & </span>
                <span>{{ 'ENTITY.ALL.TYPE.apiary' | translate }}</span>
              </span>

              <span *ngIf="affected_location === null" class="affectation-container__not-affected">
                <span class="mdi mdi-close-thick"></span>
                <span class="">{{ 'DEVICE.AFFECTATION.MISC.Not affected' | translate }}</span>
              </span>

              <div *ngIf="affected_location !== null" class="affectation-container__body__properties">
                <div class="affectation-container__body__property">
                  <span class="affectation-container__body__property__label">{{ 'ALL.COMMON.Name' | translate | colon }}</span>
                  <ng-container *ngIf="(is_loading_affection.value$$ | async) === false; else loadingAffectationValue">
                    <span
                      class="affectation-container__body__property__value simili-link"
                      [routerLink]="[
                        '',
                        { outlets: { modal: ['location_details', { location_id: affected_location?.entity?.id$$ | async }] } }
                      ]"
                      [queryParamsHandling]="'preserve'"
                    >
                      <span>{{ affected_location?.entity?.name$$ | async }}</span>
                      <span *ngLet="affected_location?.entity?.apiary$$ | async; let apiary">&nbsp;({{ apiary?.name$$ | async }})</span>
                    </span>
                  </ng-container>
                </div>

                <div class="affectation-container__body__property">
                  <span class="affectation-container__body__property__label">{{ 'ALL.COMMON.Since' | translate | colon }}</span>
                  <ng-container *ngIf="(is_loading_affection.value$$ | async) === false; else loadingAffectationValue">
                    <span class="affectation-container__body__property__value">
                      {{ affected_location?.since?.date | formatDate : 'full' }}
                    </span>

                    <span
                      *ngLet="affected_location?.entity?.user_acl?.can$$('write_all_events'); let can_write_all_events"
                      class="affectation-container__body__property__value"
                    >
                      <a
                        [routerLink]="[
                          '',
                          {
                            outlets: {
                              modal: ['update_event', { eid: affected_location?.since?.event_id, ro: !can_write_all_events ? 1 : 0 }]
                            }
                          }
                        ]"
                        [queryParamsHandling]="'preserve'"
                      >
                        <span class="mdi" [ngClass]="{ 'mdi-pencil': can_write_all_events, 'mdi-eye': !can_write_all_events }"></span>
                      </a>
                    </span>
                  </ng-container>
                </div>
              </div>
            </div>
          </div>

          <!-- Hive -->
          <ng-container *ngIf="can_be_affected_to_hive$$ | async">
            <div
              *ngLet="affectation?.hive; let affected_hive"
              class="affectation-container"
              [ngClass]="{ '--not-affected': affected_hive === null }"
            >
              <div class="affectation-container__icon">
                <bg2-picto-hive [size]="'24px'"></bg2-picto-hive>
              </div>

              <div class="affectation-container__body">
                <span class="affectation-container__body__title">{{ 'ENTITY.ALL.TYPE.hive' | translate }}</span>

                <span *ngIf="affected_hive === null" class="affectation-container__not-affected">
                  <span class="mdi mdi-close-thick"></span>
                  <span class="">{{ 'DEVICE.AFFECTATION.MISC.Not affected' | translate }}</span>
                </span>

                <div *ngIf="affected_hive !== null" class="affectation-container__body__properties">
                  <div class="affectation-container__body__property">
                    <span class="affectation-container__body__property__label">{{ 'ALL.COMMON.Name' | translate | colon }}</span>
                    <ng-container *ngIf="(is_loading_affection.value$$ | async) === false; else loadingAffectationValue">
                      <span
                        class="affectation-container__body__property__value simili-link"
                        [routerLink]="['', { outlets: { modal: ['update_entity', { eid: affected_hive?.entity?.id$$ | async }] } }]"
                        [queryParamsHandling]="'preserve'"
                      >
                        {{ affected_hive?.entity?.name$$ | async }}
                      </span>
                    </ng-container>
                  </div>

                  <div class="affectation-container__body__property">
                    <span class="affectation-container__body__property__label">{{ 'ALL.COMMON.Since' | translate | colon }}</span>
                    <ng-container *ngIf="(is_loading_affection.value$$ | async) === false; else loadingAffectationValue">
                      <span class="affectation-container__body__property__value">
                        {{ affected_hive?.since?.date | formatDate : 'full' }}
                      </span>

                      <span
                        *ngLet="affected_hive?.entity?.user_acl?.can$$('write_all_events'); let can_write_all_events"
                        class="affectation-container__body__property__value"
                      >
                        <a
                          [routerLink]="[
                            '',
                            {
                              outlets: {
                                modal: ['update_event', { eid: affected_hive?.since?.event_id, ro: !can_write_all_events ? 1 : 0 }]
                              }
                            }
                          ]"
                          [queryParamsHandling]="'preserve'"
                        >
                          <span class="mdi" [ngClass]="{ 'mdi-pencil': can_write_all_events, 'mdi-eye': !can_write_all_events }"></span>
                        </a>
                      </span>
                    </ng-container>
                  </div>
                </div>
              </div>
            </div>
          </ng-container>
        </div>
      </div>

      <div *ngIf="view === 'summary'" class="device-detail__open-detail-container">
        <bg2-button
          [type]="'navigation'"
          [icon]="'mdi-magnify'"
          [options]="{ is_thin: true }"
          [message]="'VIEWS.DEVICES.DIALOGS_AND_MODALS.DEVICE_MODAL.DETAILS.MISC.More details' | translate"
          (btn_click)="open_detail.next(true)"
        ></bg2-button>
      </div>
    </div>
  </div>

  <!-- Device history -->
  <div *ngIf="view === 'details'" class="device-details-history">
    <bg2-device-history [device]="device" [what_to_display]="['affectations']"></bg2-device-history>
  </div>
</div>
