<div class="device-authorized-movements-modal-calendar-view-component">
  <div class="calendar-manager">
    <bg2-button
      [type]="'action'"
      [icon]="'mdi-skip-previous'"
      [options]="{ is_thin: true, is_label_hidden_on_small: true }"
      [message]="'ALL.COMMON.Previous' | translate"
      mwlCalendarPreviousView
      [view]="'week'"
      [viewDate]="current_date$$ | async"
      [daysInWeek]="days_in_week$$ | async"
      (viewDateChange)="when_day_changed($event)"
    ></bg2-button>

    <div *ngLet="days_in_week$$ | async; let days_in_week">
      <span class="calendar-manager_state_day">
        <ng-container *ngIf="days_in_week <= 1">{{ current_date$$ | async | formatDate: 'short' }}</ng-container>
        <ng-container *ngIf="days_in_week > 1">
          {{ current_date$$ | async | calendarDate: 'weekViewTitle':(language$$ | async):1:[]:days_in_week }}
        </ng-container>
      </span>

      <span
        class="calendar-manager_state_go-to-now"
        mwlCalendarToday
        [viewDate]="current_date$$ | async"
        (viewDateChange)="when_day_changed($event)"
      >
        <span class="mdi mdi-cursor-default-click"></span>
        <span>{{ 'ALL.COMMON.Show today' | translate }}</span>
      </span>
    </div>

    <bg2-button
      [type]="'action'"
      [icon]="'mdi-skip-next'"
      [options]="{ is_thin: true, is_label_hidden_on_small: true }"
      [message]="'ALL.COMMON.Next' | translate"
      mwlCalendarNextView
      [view]="'week'"
      [viewDate]="current_date$$ | async"
      [daysInWeek]="days_in_week$$ | async"
      (viewDateChange)="when_day_changed($event)"
    ></bg2-button>
  </div>

  <!-- <full-calendar #calendar [options]="calendar_options$$ | async"></full-calendar> -->
  <mwl-calendar-week-view
    [weekStartsOn]="1"
    [events]="events$$ | async"
    [locale]="language$$ | async"
    [viewDate]="current_date$$ | async"
    [daysInWeek]="days_in_week$$ | async"
    [eventTemplate]="customEventTemplate"
  ></mwl-calendar-week-view>

  <ng-template
    #customEventTemplate
    let-weekEvent="weekEvent"
    let-tooltipPlacement="tooltipPlacement"
    let-eventClicked="eventClicked"
    let-tooltipTemplate="tooltipTemplate"
    let-tooltipAppendToBody="tooltipAppendToBody"
    let-tooltipDisabled="tooltipDisabled"
    let-tooltipDelay="tooltipDelay"
    let-column="column"
  >
    <div
      class="cal-event"
      [ngStyle]="{
        color: weekEvent.event.color?.secondaryText,
        borderColor: weekEvent.event.color?.primary,
        backgroundColor: weekEvent.event.color?.secondary
      }"
      (click)="weekEvent?.event?.actions ? update_authorized_movement(weekEvent?.event) : null"
    >
      <div class="csm_cal-event_title">
        {{ weekEvent?.event?.title | translate }}
      </div>

      <div class="csm_cal-event_title">({{ weekEvent?.event?.meta?.device?.name }})</div>

      <div class="csm_cal-event_time">
        {{ weekEvent?.event?.start | formatDate: 'hour_short' }} - {{ weekEvent?.event?.end | formatDate: 'hour_short' }}
      </div>
    </div>
  </ng-template>
</div>
