import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { ISchema } from 'ngx-schema-form';
import { isNil, set } from 'lodash-es';

import { TranslateService } from '@ngx-translate/core';
import { marker as i18n } from '@biesbjerg/ngx-translate-extract-marker';

import { BehaviorSubject, map, Observable, of, switchMap, tap } from 'rxjs';
import { distinctUntilRealChanged, replay, waitForNotNilValue } from '@bg2app/tools/rxjs';

import { User, UserParameters } from 'app/models';
import { ConsoleLoggerService } from 'app/core/console-logger.service';

import { Dictionary } from 'app/typings/core/interfaces';
import { DialogsService } from 'app/widgets/dialogs-modals';

export const SETINGS_NOTIFICATION_SCHEMA: ISchema = {
  type: 'object',
  properties: {
    sms_on_gps_move: {
      type: 'boolean',
      label: i18n<string>('ROUTABLE_MODALS.USER_SETTINGS.CATEGORY.NOTIFICATIONS.sms_on_gps_move'),
      widget: 'radio',
      oneOf: [
        {
          enum: [true],
          label: i18n<string>('ALL.COMMON.Yes'),
          // options: {
          //   img: 'img/views/locations/components/page-locations/map-view/filter-antenna-sites-bottom-sheet/symbol-yes.png',
          // },
        },
        {
          enum: [false],
          label: i18n<string>('ALL.COMMON.No'),
          // options: {
          //   img: 'img/views/locations/components/page-locations/map-view/filter-antenna-sites-bottom-sheet/symbol-no.png',
          // },
        },
      ],
    },
    // email_on_gps_move: {
    //   type: 'boolean',
    //   widget: 'boolean',
    //   description_off: i18n<string>('ROUTABLE_MODALS.USER_SETTINGS.CATEGORY.NOTIFICATIONS.email_on_gps_move'),
    //   options: {
    //     type: 'classic',
    //   },
    // },
    sms_forward_actif: {
      type: 'boolean',
      label: i18n<string>('ROUTABLE_MODALS.USER_SETTINGS.CATEGORY.NOTIFICATIONS.sms_forward_actif'),
      widget: 'radio',
      oneOf: [
        {
          enum: [true],
          label: i18n<string>('ALL.COMMON.Yes'),
          // options: {
          //   img: 'img/views/locations/components/page-locations/map-view/filter-antenna-sites-bottom-sheet/symbol-yes.png',
          // },
        },
        {
          enum: [false],
          label: i18n<string>('ALL.COMMON.No'),
          // options: {
          //   img: 'img/views/locations/components/page-locations/map-view/filter-antenna-sites-bottom-sheet/symbol-no.png',
          // },
        },
      ],
    },
    sms_forward_numbers: {
      type: 'array',
      widget: 'array',
      label: i18n<string>('ROUTABLE_MODALS.USER_SETTINGS.CATEGORY.NOTIFICATIONS.sms_forward_numbers'),
      config: {
        remove_btn_inline: true,
      },
      minItems: 1,
      description_add: i18n<string>('ROUTABLE_MODALS.USER_SETTINGS.CATEGORY.NOTIFICATIONS.Add a phone number'),
      visibleIf: { sms_forward_actif: true },
      items: {
        type: 'string',
        default: '',
        widget: 'ng-mat-tel',
        label: i18n<string>('ROUTABLE_MODALS.USER_ACCOUNT.UAM_USER_DETAILS.PROFILE.Mobile phone'),
      },
    },
  },
};

@Component({
  selector: 'bg2-notifications-settings',
  templateUrl: './notifications-settings.component.html',
  styleUrls: ['./notifications-settings.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotificationsSettingsComponent {
  private readonly LOGGER = new ConsoleLoggerService(this.constructor.name, false);

  constructor(private _dialogs: DialogsService, private _translate: TranslateService) {}

  // #region -> (loading)
  private _loading$$ = new BehaviorSubject<boolean>(true);
  public loading$$ = this._loading$$.pipe(distinctUntilRealChanged(), replay());

  private set loading(val: boolean) {
    this._loading$$.next(val);
  }

  // #endregion

  // #region -> (related user)

  private _user$$ = new BehaviorSubject<User>(null);
  public user$$ = this._user$$.asObservable().pipe(waitForNotNilValue());

  public user_id$$ = this.user$$.pipe(
    switchMap(user => (user ? user.user_id$$ : of(null))),
    replay()
  );

  /** */
  @Input()
  public set user(user: User) {
    this._user$$.next(user);
  }

  public get user() {
    return this._user$$.getValue();
  }

  // #endregion

  // #region -> (notifications management)

  public notifications_schema: ISchema = SETINGS_NOTIFICATION_SCHEMA;

  public notifications_model$$: Observable<UserParameters['notifications']> = this.user$$.pipe(
    switchMap(user => user.params$$),
    map(params => params?.notifications ?? <UserParameters['notifications']>{}),
    tap(() => (this.loading = false)),
    replay()
  );

  public when_notifications_update(new_value: UserParameters['notifications']): void {
    if (isNil(this.user)) {
      this.LOGGER.warn('User not set yet');
      return;
    }

    if (new_value) {
      const partialParams = { notifications: new_value };
      // TODO: Remove when notifications settings have UI for global toggle of SMS notifications
      set(partialParams, 'notifications.sms.enabled', new_value?.sms_on_gps_move ? true : false);

      this.user.setPartialParams(partialParams);
    }
  }

  public save_notification_params(): void {
    this.loading = true;
    this.user$$
      .pipe(switchMap(user => user.save(['params'])))
      .pipe(switchMap(() => this._dialogs.alert(this._translate.instant('ROUTABLE_MODALS.USER_SETTINGS.User settings have been saved !'))))
      .subscribe({
        next: () => {
          this.loading = false;
        },
        error: (error: unknown) => {
          console.error(error);
          this.loading = false;
        },
        complete: () => {},
      });
  }

  private _form_is_valid$$ = new BehaviorSubject<boolean>(false);
  public form_is_valid$$ = this._form_is_valid$$.asObservable();

  public update_form_is_valid(valid: boolean) {
    this._form_is_valid$$.next(valid);
  }

  // #endregion
}
