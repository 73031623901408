<div class="bg2-last-created-devices-datatable-component">
  <!-- Grid wrapper -->
  <div class="bg2-grid-wrapper">
    <mat-toolbar class="export-and-configuration-toolbar">
      <mat-toolbar-row
        *ngIf="(has_error$$ | async) || selection_model?.selected?.length >= 1"
        class="error-and-selected-row"
        [class.has-error]="has_error$$ | async"
        [class.has-selected]="selection_model?.selected?.length >= 1"
      >
        <ng-container *ngIf="error$$ | async">
          <span>{{ 'VIEWS.MODALS.NEW_ISSUE.An unknown error occurred, please retry' | translate }}</span>
        </ng-container>

        <div
          class="selected-items-management"
          *ngIf="(has_not_error$$ | async) && selection_model?.selected?.length >= 1 && !TOOLBARS_CONFIG.selection.disabled"
        >
          <span>{{ TOOLBARS_CONFIG.selection.text | translate: { total: selection_model.selected.length } }}</span>

          <div>
            <mat-form-field>
              <mat-label>{{ ('VIEWS.DATATABLE.to do the action' | translate) + '...' }}</mat-label>
              <mtx-select
                #selectActionComponent
                [bindValue]="'type'"
                [appendTo]="null"
                [ngModel]="selected_action$$ | async"
                [items]="
                  (appStateService.is_superadmin$$ | async)
                    ? TOOLBARS_CONFIG.selection.available_actions
                    : (TOOLBARS_CONFIG.selection.available_actions | filter: 'only_admin':false)
                "
                (ngModelChange)="set_selected_action($event)"
              >
                <ng-template ng-label-tmp let-item="item">
                  {{ item.label | translate }}
                </ng-template>

                <ng-template ng-option-tmp let-item="item">
                  {{ item.label | translate }}
                </ng-template>
              </mtx-select>
            </mat-form-field>
          </div>
        </div>
      </mat-toolbar-row>
    </mat-toolbar>

    <div class="bg2-grid-container" [ngClass]="{ empty: data_source?.data?.length === 0 }">
      <ng-container *ngIf="loading$$ | async">
        <mat-progress-bar class="top-loading" [mode]="'indeterminate'"></mat-progress-bar>
      </ng-container>

      <table mat-table [dataSource]="data_source" matSort [matSortActive]="sort__active$$ | async" [matSortDirection]="sort__direction$$ | async" (matSortChange)="onSortData($event)">
        <!-- #region .headers-and-cells-templates -->
        <ng-container *ngFor="let column of flattened_columns$$ | async; trackBy:track_by_column" [matColumnDef]="column.property">
          <!-- #region .header-definitions -->
          <th
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header
            class="{{ column_sort_type(column) }}"
            [disabled]="!column?.is_sortable"
            [@.disabled]="true"
          >
            <ng-container [ngSwitch]="column.property">
              <!-- Column type: "select" -->
              <ng-container *ngSwitchCase="'select'">
                <mat-checkbox
                  [color]="'primary'"
                  [disabled]="true"
                  [ngModel]="all_selected$$ | async"
                  [indeterminate]="(some_selected$$ | async) && (all_selected$$ | async) === false"
                  (change)="$event ? masterToggle() : null"
                ></mat-checkbox>
              </ng-container>

              <!-- Default column header -->
              <ng-container *ngSwitchDefault>
                {{ column.label | translate }}
              </ng-container>
            </ng-container>
          </th>
          <!-- #endregion -->

          <!-- #region .content-definitions -->
          <td
            mat-cell
            *matCellDef="let device_row"
            class="col-{{ column.property }}"
            [attr.data-label]="column?.label | translate"
            [class.selected]="isSelected$$(device_row) | async"
            [ngStyle]="{
              'pointer-events':
                column?.property === 'select' ? (device_row?.affectation?.exploitation?.entity?.name === null ? 'unset' : 'none') : 'unset'
            }"
            (click)="column?.property === 'select' ? selection_model.toggle(device_row) + $event.stopPropagation() : null"
          >
            <ng-container [ngSwitch]="column.property">
              <!-- Column type: "select" -->
              <ng-container *ngSwitchCase="'select'">
                <mat-checkbox
                  [color]="'primary'"
                  [disabled]="(loading$$ | async) || device_row?.affectation?.exploitation?.entity?.name !== null"
                  (click)="$event.stopPropagation()"
                  (change)="$event ? selection_model.toggle(device_row) : null"
                  [checked]="isSelected$$(device_row) | async"
                ></mat-checkbox>
              </ng-container>

              <!-- Column type: "device.type" -->
              <ng-container *ngSwitchCase="'device.type'">
                <ng-container *ngLet="assert_device(device_row?.device); let device">
                  <span>{{ device?.type }}</span>
                </ng-container>
              </ng-container>

              <!-- Column type: "device.imei" -->
              <ng-container *ngSwitchCase="'device.imei'">
                <ng-container *ngLet="assert_device(device_row?.device); let device">
                  <span>{{ device?.imei }}</span>
                </ng-container>
              </ng-container>

              <!-- Column type: "device.created_at" -->
              <ng-container *ngSwitchCase="'device.created_at'">
                <ng-container *ngLet="assert_device(device_row?.device); let device">
                  <span *ngIf="device?.creation_date$$ | async; let date; else: emptyValue">{{ date | formatDate: 'full' }}</span>
                </ng-container>
              </ng-container>

              <!-- Column type: "affectation.exploitation.name" -->
              <ng-container *ngSwitchCase="'affectation.exploitation.name'">
                <ng-container *ngIf="!device_row.affectation_loading; else loading">
                  <span *ngIf="device_row?.affectation?.exploitation?.entity?.name as exploitation_name; else emptyValue">
                    {{ exploitation_name }}
                  </span>
                </ng-container>
              </ng-container>

              <!-- Column other cols -->
              <ng-container *ngSwitchDefault>
                <span>{{ get(device_row, column.property) }}</span>
              </ng-container>
            </ng-container>
          </td>
          <!-- #endregion -->
        </ng-container>
        <!-- #endregion -->

        <!-- #region .specific-templates -->
        <ng-container matColumnDef="group-header">
          <td mat-cell *matCellDef="let group" [colSpan]="(displayed_columns$$ | async).length">
            <span
              class="header-content mdi mdi-chevron-{{ group.expanded ? 'up' : 'down' }} mdi-24px"
              [style.padding-left.px]="(group.level - 1) * 24"
            >
              <ng-container [ngSwitch]="group.last_property">
                <ng-container *ngSwitchDefault>
                  {{ group.label | translate }} : {{ group.last_col_value }} ({{ group.totalCounts }})
                </ng-container>
              </ng-container>
            </span>
          </td>
        </ng-container>
        <!-- #endregion -->

        <!-- #region .row-definitions -->
        <tr mat-header-row *matHeaderRowDef="displayed_columns$$ | async"></tr>

        <tr
          mat-row
          *matRowDef="let device_row; columns: displayed_columns$$ | async"
          class="final-row"
          [ngClass]="{ selected: isSelected$$(device_row) | async }"
        ></tr>

        <tr mat-row *matRowDef="let row; columns: ['group-header']; when: isGroup"></tr>
        <!-- #endregion -->
      </table>
    </div>

    <!-- Empty grid message -->
    <div *ngIf="data_source?.data.length === 0 && (loading$$ | async) === false" class="empty-grid-message">
      <img src="assets/img/large_up_arrow.png" />
      <div class="text">
        {{ 'VIEWS.DEVICES.COMPONENTS.DEVICES_DATATABLE.No matching device' | translate }}
      </div>
    </div>

    <!-- Grid pagination -->
    <mat-paginator
      *ngLet="paging$$ | async; let paging"
      showFirstLastButtons
      [length]="paging?.total"
      [pageSize]="paging?.limit"
      [disabled]="loading$$ | async"
      [pageSizeOptions]="PAGE_SIZE_OPTIONS"
      [pageIndex]="paging?.offset / paging?.limit"
      (page)="onPageChanged($event)"
    ></mat-paginator>
  </div>
</div>

<ng-template #emptyValue>-</ng-template>
<ng-template #lockedValue>
  <span class="mdi mdi-lock mdi-18px"></span>
</ng-template>

<ng-template #loading>
  <hex-loading [size]="'tiny'" [inline]="true"></hex-loading>
</ng-template>
