import {
  of,
  map,
  tap,
  take,
  filter,
  switchMap,
  Observable,
  Subscription,
  debounceTime,
  combineLatest,
  BehaviorSubject,
  distinctUntilChanged,
  concat,
} from 'rxjs';

import {
  assign,
  clone,
  cloneDeep,
  has,
  indexOf,
  isEqual,
  isNil,
  keyBy,
  mean,
  keys,
  maxBy,
  merge,
  min,
  orderBy,
  sum,
  uniqBy,
  flatten,
  values,
  isEmpty,
  some,
  mapValues,
  minBy,
  get,
  difference,
  size,
  uniq,
  uniqueId,
} from 'lodash-es';

import { addHours, max as dateMax, subHours, differenceInHours } from 'date-fns';
import { marker as i18n } from '@biesbjerg/ngx-translate-extract-marker';

import { DeviceApi, Beeguard2Api } from 'app/core';

import { computes_barometric_pressure_from_sea_pressure, parseDate } from 'app/misc/tools';
import {
  allTrue,
  anyTrue,
  distinctUntilRealChanged,
  replay,
  robustCombineLatest,
  waitForNotNilProperties,
  waitForNotNilValue,
} from '@bg2app/tools/rxjs';

import { DeviceHistory, Entity, IEntityStaticState } from '../../00_abstract/classes/Entity';
import { Location } from '../../03_location';
import { Hive, HiveType } from '../../05_hive/classes/Hive';
import { DeviceConfig, EntityStateValue } from '../../misc';

import {
  GhostSolution,
  GhostSolutionAlternatives,
  GhostSolutionLevel,
  DevicesFromSameLocation,
} from 'app/core/ghost/models/ghost-solution';

import { DRDevice, WGDevice, RGDevice, GPSDevice, CPTDevice } from '../../../devices';
import { DataPoint, GetEntityTimeseriesResponse, MeasurementDescription } from 'app/core/api-swagger/beeguard2';
import { ApiaryHiveStatuses, ApiaryHiveSupers, ApiaryHiveQueens, ApiaryHiveBroodframes } from '../../../types/common';
import { Dictionary, MinMax, NumericDictionary } from 'app/typings/core/interfaces';
import { percentile } from 'app/misc/tools/maths';

import { Exploitation } from '../..';

import { HiveSetup } from '../../../events/HiveBasic';
import { DeviceGPSInstall, DeviceRGInstall, DevicesWGInstall } from '../../../events/device_setup_events';
import { Migratory } from '../../../events/Migratory';
import { TranslateService } from '@ngx-translate/core';
import { EvaluationEvent } from '../../../events/Evaluation';
import { LastWeatherData, RawWeatherDataPoint, WeightDataPoint, WeatherDataPoint, TemperatureDataPoint } from '../../../data';
import { DeviceAlarm } from '../../../devices/DRDevice';
import { HiveBeeCountData, HiveTemperatureData, HiveWeightData } from '../../../data/data-classic/interfaces/hive-data.interface';
import { WeatherData } from '../../../data/data-classic';
import { ErrorHelperData } from 'app/widgets/widgets-reusables/errors/error-helper/error-helper.component';
import { getTimezoneOffset, utcToZonedTime } from 'date-fns-tz';
import { ApiaryEntityUserACL } from './apiary-entity-user-acl.class';

export interface ApiaryWeightsData {
  hives_weight: HiveWeightData[];
  has_wgt_data: boolean;
  timezone: string;
}

export interface ApiaryDeviceConfig extends DeviceConfig {
  hives: Hive[];
  positions: string[];
}

interface IApiaryStaticState extends IEntityStaticState {
  hives_order: number[];
}

/** Apairy entity
 *
 * Notes:
 *  - apiary automaticaly updates it's own hives when state change.
 *  - also automaticaly updates it's devices (from hive's devices)
 */
export class Apiary extends Entity<IApiaryStaticState> {
  // #region -> (entity basics)

  constructor(bg2Api: Beeguard2Api, deviceApi: DeviceApi) {
    super(bg2Api, deviceApi);

    this.type = 'apiary';
    this.hive_ids_sub = this.hive_ids$$.subscribe();
  }

  // #endregion

  // #region -> (related location entity)

  /**
   * Gets the ID of the apiary's location at instant X. If possible, prefer usage of {@link location_id$$}.
   *
   * @public
   */
  public get location_id(): number {
    return this.state?.location_id ?? null;
  }

  /**
   * Observes the ID of the apiary's location.
   *
   * @public
   * @replay
   */
  public location_id$$: Observable<number> = this.state$$.pipe(
    map(state => state?.location_id ?? null),
    distinctUntilRealChanged(),
    replay()
  );

  /**
   * Observes the apiary's location entity.
   *
   * @public
   * @replay
   */
  public location$$: Observable<Location> = this.location_id$$.pipe(
    switchMap(location_id => {
      if (isNil(location_id)) {
        return of<Location>(null);
      }

      return this.bg2Api.getEntityObj<Location>(location_id);
    }),
    replay()
  );

  /** */
  public location_timezone$$ = this.location$$.pipe(
    waitForNotNilValue(),
    switchMap(location => location.location_timezone$$)
  );

  // #endregion

  // #region -> (related named location entity)

  /**
   * Observes the ID of the apiary's named location.
   *
   * @public
   * @replay
   */
  public named_location_id$$: Observable<number> = this.named_state$$.pipe(
    map(state => state.location_id),
    distinctUntilRealChanged(),
    replay()
  );

  /**
   * Observes the apiary's named location entity.
   *
   * @public
   * @replay
   */
  public named_location$$: Observable<Location> = this.named_location_id$$.pipe(
    switchMap(location_id => {
      if (isNil(location_id)) {
        return of<Location>(null);
      }

      return this.bg2Api.getEntityObj<Location>(location_id);
    }),
    replay()
  );

  // #endregion

  // #region -> (acl management)

  /**
   * @inheritdoc
   */
  public user_acl = new ApiaryEntityUserACL();

  // #endregion

  // #region -> (related exploitation entity)

  /**
   * Observes the apiary's exploitation entity.
   *
   * @public
   */
  public exploitation$$ = this.location$$.pipe(switchMap(location => (location ? location.exploitation$$ : of<Exploitation>(null))));

  /**
   * Observes if the related exploitation has devices.
   *
   * @public
   */
  public exploitation_has_devices$$ = this.exploitation$$.pipe(switchMap(exploitation => exploitation?.has_devices$$));

  // #endregion

  // #region -> (related hives entities)

  /** */
  public hives: Hive[] = [];

  /** */
  public connected_hives: Hive[] = [];

  /** */
  public hive_ids: any[] = [];

  /** */
  public hive_ids$$: Observable<number[]> = this.state$$.pipe(
    switchMap(state => this.static_state$$.pipe(map(static_state => ({ state, static_state })))),
    map(({ state, static_state }) => {
      let hive_ids: number[] = state?.hive_ids ?? [];
      const hives_order: number[] = static_state?.hives_order ?? [];

      hive_ids = orderBy(hive_ids, hive_id => {
        const idx = indexOf(hives_order, hive_id);
        if (idx < 0) {
          // Hive not in order at the end
          return hives_order.length + hive_id;
        }

        return idx;
      });

      return hive_ids;
    }),
    tap(hive_ids => (this.hive_ids = hive_ids)),
    replay()
  );

  // #endregion

  protected hive_ids_sub: Subscription;

  public named_hive_ids$$: Observable<number[]> = this.named_state$$.pipe(
    map(state => state.hive_ids),
    replay()
  );

  private hive_by_id: { [id: number]: [Hive, number] } = {};

  private _hives_loaded$$ = new BehaviorSubject(false);
  public hives_loaded$$ = this._hives_loaded$$.asObservable().pipe(distinctUntilChanged(), replay());
  set hives_loaded(val: boolean) {
    this._hives_loaded$$.next(val);
  }
  get hives_loaded(): boolean {
    return this._hives_loaded$$.getValue();
  }

  // #region -> (events management)

  // #endregion

  // #region -> (visit event management)

  /**
   * Fetches apiary's last visit event with offset.
   *
   * @param offset
   *
   * @returns
   */
  public fetch_last_evaluation_event$$(offset: number = 0): Observable<EvaluationEvent> {
    return this.user_acl.can$$('read_all_events').pipe(
      switchMap(can_read_all_events => {
        if (!can_read_all_events) {
          return of(null);
        }

        return this.id$$.pipe(
          switchMap(apiary_id => this.bg2Api.getEventsObj([apiary_id], { limit: 1, offset }, ['evaluation'])),
          map(event_paging => (event_paging?.events?.[0] || null) as EvaluationEvent)
        );
      }),
      replay()
    );
  }

  // #endregion

  /**
   * Add _total property on a intial repartition
   */
  private compute_total_resulting(repartition: Dictionary<number>): Dictionary<number> {
    if (isNil(repartition)) {
      return null;
    }

    const cp_repartition = cloneDeep(repartition);
    const total: number = sum(
      keys(repartition)
        .filter(key => !key.startsWith('_'))
        .map(key => repartition[key])
    );
    cp_repartition._total = total;
    return cp_repartition;
  }

  public hive_statuses$$: Observable<ApiaryHiveStatuses> = this.state$$.pipe(
    map((state: EntityStateValue) => state?.hive_statuses) || null,
    map((hive_statuses: ApiaryHiveStatuses) => {
      if (isNil(hive_statuses)) {
        return null;
      }

      const cp_hive_statuses = cloneDeep(hive_statuses);

      if (cp_hive_statuses?.since?.event_id) {
        cp_hive_statuses.event$ = this.bg2Api.getEventObj(cp_hive_statuses.since.event_id);
      } else {
        cp_hive_statuses.event$ = of(null);
      }

      return cp_hive_statuses;
    }),
    map((hive_statuses: ApiaryHiveStatuses) => {
      if (!isNil(hive_statuses)) {
        hive_statuses.repartition = this.compute_total_resulting(hive_statuses?.repartition);
      }

      return hive_statuses;
    })
  );

  public supers$$: Observable<ApiaryHiveSupers> = this.state$$.pipe(
    map<EntityStateValue, ApiaryHiveSupers>(state => state?.superbox || null),
    map((superbox: ApiaryHiveSupers) => {
      if (isNil(superbox)) {
        return null;
      }

      const cp_superbox = cloneDeep(superbox);
      if (cp_superbox?.since?.event_id) {
        cp_superbox.event$$ = this.bg2Api.getEventObj(cp_superbox?.since?.event_id);
      } else {
        cp_superbox.event$$ = of(null);
      }

      return cp_superbox;
    }),
    map((superbox: ApiaryHiveSupers) => {
      if (!isNil(superbox)) {
        // Add nb of supers in stock (if any)
        if (!isNil(superbox?.stock)) {
          if (isNil(superbox?.repartition)) {
            superbox.repartition = {};
          }
          superbox.repartition['-1'] = superbox?.stock;
        }
        superbox.repartition = this.compute_total_resulting(superbox?.repartition);
      }
      return superbox;
    })
  );

  public brood_frames$$: Observable<ApiaryHiveBroodframes> = this.state$$.pipe(
    map<EntityStateValue, ApiaryHiveBroodframes>(state => state?.brood_frames || null),
    map((brood_frames: ApiaryHiveBroodframes) => {
      if (isNil(brood_frames)) {
        return null;
      }

      const cp_broodframes = cloneDeep(brood_frames);
      cp_broodframes.repartition = assign({}, cp_broodframes?.repartition || {});
      cp_broodframes.event$$ = cp_broodframes?.since?.event_id ? this.bg2Api.getEventObj(cp_broodframes?.since?.event_id) : of(null);
      return cp_broodframes;
    }),
    map((brood_frames: ApiaryHiveBroodframes) => {
      if (!isNil(brood_frames)) {
        brood_frames.repartition = this.compute_total_resulting(brood_frames?.repartition);
      }
      return brood_frames;
    })
  );

  public queen_colors$$: Observable<ApiaryHiveQueens> = this.state$$.pipe(
    map((state: EntityStateValue) => state?.queens || null),
    map((queens: ApiaryHiveQueens) => {
      if (isNil(queens)) {
        return null;
      }

      const cp_queens = cloneDeep(queens);
      cp_queens.event$$ = cp_queens?.since?.event_id ? this.bg2Api.getEventObj(cp_queens.since.event_id) : of(null);
      return cp_queens;
    }),
    map(queens => {
      if (!isNil(queens)) {
        queens.colors = this.compute_total_resulting(queens?.colors);
      }
      return queens;
    })
  );

  public sanitary$$ = this.state$$.pipe(map(state => state.sanitary || {}));

  public treatments$$: Observable<{ treatments: any[]; since: { at: Date; visit_id: number } }> = this.sanitary$$.pipe(
    map(sanitary => ({
      since: sanitary.since,
      treatments: sanitary?.sanitary_treatments || [],
    }))
  );

  /**
   * Apiari's hives
   */
  get hives_order(): number[] {
    return this.static_state.hives_order;
  }

  set hives_order(order: Array<number>) {
    this.static_state.hives_order = order;
    this.checkStaticStateChanged();
  }

  public has_ghost_hives$$ = combineLatest({ hive_ids: this.hive_ids$$, named_hive_ids: this.named_hive_ids$$ }).pipe(
    map(({ hive_ids, named_hive_ids }) => difference(hive_ids, named_hive_ids)?.length > 0),
    replay()
  );

  public hives_nucs$$: Observable<Hive[]> = this.hive_ids$$.pipe(
    distinctUntilRealChanged(),
    tap(hive_ids => (this.hives_loaded = false)),
    switchMap(hive_ids => {
      if (hive_ids.length > 0) {
        return this.bg2Api.getEntitiesObj(hive_ids);
      } else {
        return of([]);
      }
    }),
    map(entities => {
      const hives = (entities as Hive[]) || [];
      hives.map((hive, hive_num) => {
        hive.setApiary(this, hive_num);
      });
      return hives;
    }),
    tap(hives => {
      this.hives = hives; // => do update this.hives$
      this.hives_loaded = true;
    }),
    tap(hives => {
      // console.log(this.desc, 'recompute hives index');
      const hive_by_id: Dictionary<any> = {};
      hives.map((hive, hive_nb) => {
        hive_by_id[hive.id] = [hive, hive_nb];
      });
      this.hive_by_id = hive_by_id;
    }),
    replay()
  );

  public ghost_hives$$ = this.hives_nucs$$.pipe(
    map(hives => hives.filter(hive => hive.id < 0)),
    replay()
  );

  public named_hives_nucs$$: Observable<Hive[]> = this.named_hive_ids$$.pipe(
    distinctUntilRealChanged(),
    switchMap(hive_ids => {
      if (hive_ids?.length > 0) {
        return this.bg2Api.getEntitiesObj(hive_ids);
      } else {
        return of([]);
      }
    }),
    map(entities => {
      const hives = (entities as Hive[]) || [];
      hives.map((hive, hive_num) => {
        hive.setApiary(this, hive_num);
      });
      return hives;
    }),
    replay()
  );

  public hives$$: Observable<Hive[]> = this.hives_nucs$$.pipe(
    map(hives => hives.filter(hive => hive.htype === 'hive')),
    replay()
  );

  public nucs$$: Observable<Hive[]> = this.hives_nucs$$.pipe(
    map(hives => hives.filter(hive => hive.htype === 'nuc')),
    replay()
  );

  public connected_hives$$: Observable<Hive[]> = this.hives_nucs$$.pipe(
    switchMap(hives => {
      // connect to all hives connected$$ observable to update list of connected hives
      const connected_hives$$ = hives
        .filter(hive => !isNil(hive))
        .map(hive => hive.connected$$.pipe(map(connected => ({ hive, connected }))));
      return robustCombineLatest(connected_hives$$).pipe(
        map(hives_connected => hives_connected.filter(({ connected }) => connected).map(({ hive }) => hive))
      );
    }),
    debounceTime(30), // This is usefill to group together same time hivelevel changes
    distinctUntilChanged((previous, current) =>
      isEqual(
        previous.map(hive => hive.id),
        current.map(hive => hive.id)
      )
    ),
    tap(connected_hives => {
      // console.log(this.desc, 'Connected hives changed', connected_hives.map(hive => hive.id));
      this.connected_hives = connected_hives;
    }),
    replay()
  );

  public nb_connected_hives$$: Observable<number> = this.connected_hives$$.pipe(
    map(connected_hives => connected_hives.length),
    replay()
  );

  /** */
  public has_connected_hives$$ = this.nb_connected_hives$$.pipe(map(total_connected_hives => (total_connected_hives ?? 0) > 0));

  /**
   * Generates an hive/nuc name.
   *
   * @param prefix The hive type we want a new name.
   * @param translate Reference to the translation service.
   * @param options Facultative options to generate the new hive/nuc name.
   *
   * @returns Returns the translated hive/nuc name.
   */
  public next_hive_name(prefix: HiveType, translate: TranslateService, options?: { offset: number }): Observable<string> {
    return combineLatest([this.hives$$, this.nucs$$]).pipe(
      map(([hives, nucs]) => {
        const total_of_hives = (hives || []).length;
        const total_of_nucs = (nucs || []).length;

        const hive_num = prefix === 'hive' ? total_of_hives : total_of_nucs;
        return translate.instant(`ENTITY.ALL.TYPE.${prefix as string}`) + ' ' + `#${hive_num + 1 + (options?.offset || 0)}`;
      }),
      distinctUntilRealChanged(),
      replay()
    );
  }

  private _conbineHiveDeviceConfig(hives$$: Observable<Hive[]>) {
    return hives$$.pipe(
      // tap(data => console.log(this.desc, 'HIVES', data)),
      switchMap(
        (hives: Hive[]): Observable<{ [imei: string]: ApiaryDeviceConfig }[]> =>
          robustCombineLatest(
            hives.map((hive: Hive) =>
              hive.devices_config$$.pipe(
                map(configs => {
                  const devices_config: { [imei: string]: ApiaryDeviceConfig } = {};
                  if (!isNil(configs)) {
                    Object.keys(configs).forEach((config_key: any) => {
                      devices_config[config_key] = {
                        imei: configs[config_key].imei,
                        type: configs[config_key].type,
                        since: configs[config_key].since,
                        hives: [hive],
                        positions: [configs[config_key].position],
                      };
                    });
                  }
                  return devices_config;
                })
              )
            )
          )
      ),
      map(configs => assign({}, ...configs))
    );
  }

  public named_hive_devices_config$$: Observable<{
    [imei: string]: ApiaryDeviceConfig;
  }> = this._conbineHiveDeviceConfig(this.named_hives_nucs$$).pipe(replay());

  public hive_devices_config$$: Observable<{ [imei: string]: ApiaryDeviceConfig }> = this._conbineHiveDeviceConfig(this.hives_nucs$$).pipe(
    // tap(data => console.log(this.desc, data)),
    debounceTime(50),
    replay()
  );

  public all_rg_devices_config$$: Observable<Dictionary<ApiaryDeviceConfig>> = this.user_acl.can$$('read_devices').pipe(
    switchMap(can_read_devices => {
      if (!can_read_devices) {
        return of({});
      }

      return this.state$$.pipe(map(state => state?.devices ?? {}));
    }),
    distinctUntilRealChanged(),
    debounceTime(50),
    replay()
  );

  public named_rg_devices_config$$ = this.all_rg_devices_config$$.pipe(
    map(device_config => {
      const named_device_config: Dictionary<ApiaryDeviceConfig> = {};
      keys(device_config).map(imei => {
        if (device_config[imei].since?.event_id > 0) {
          named_device_config[imei] = device_config[imei];
        }
      });
      return named_device_config;
    })
  );

  /** Devices config ignoring ghost entities */
  public named_devices_config$$ = combineLatest([this.named_hive_devices_config$$, this.named_rg_devices_config$$]).pipe(
    map(data => assign({}, ...data)),
    // tap(dev => console.log(this.desc, 'new devices', dev)),
    replay()
  );

  /** Devices with ghost entities */

  public devices_config$$ = combineLatest([this.hive_devices_config$$, this.all_rg_devices_config$$]).pipe(
    map(data => assign({}, ...data)),
    // tap(dev => console.log(this.desc, 'new devices', dev)),
    replay()
  );

  public devices_loading = false;
  private _devices: DRDevice[] = null;

  public compatible_devices$$: Observable<DRDevice[]> = this.location$$.pipe(
    // tap(locations => this._logger.debug('got locations', locations)),
    switchMap(location => {
      if (isNil(location)) {
        return of([]);
      }
      return location.compatible_devices$$;
    })
  );

  public compatible_devices_by_previous_location$$: Observable<DevicesFromSameLocation[]> = this.location$$.pipe(
    // tap(location => this._logger.debug('new location', location?.desc)),
    distinctUntilChanged((prev, next) => prev?.id === next?.id),
    switchMap(location => {
      if (isNil(location)) {
        return of([]);
      }
      return location.compatible_devices_by_previous_location$$;
    }),
    // tap(data => this._logger.debug('new compatible devices', data)),
    replay()
  );

  /**
   * Stores device imei with their related hive.
   * @note Built using {@link hives_devices$$}.
   */
  private _device_2_hive: { [imei: number]: Hive } = {};

  /** */
  public hives_devices$$ = this.hives_nucs$$.pipe(
    tap(() => (this.devices_loading = true)),
    switchMap(hives => {
      const devices_by_hive$$ = hives.map(hive =>
        hive.devices$$.pipe(
          tap(devices => {
            devices.forEach(device => {
              this._device_2_hive[device.imei] = hive;
            });
          })
        )
      );

      return robustCombineLatest(devices_by_hive$$);
    }),
    map(devices_array => flatten(devices_array)),
    tap(devices => {
      this.devices_loading = false;
      this.setDevices(devices);
    }),
    replay()
  );

  public devices_wg$$: Observable<WGDevice[]> = this.hives_devices$$.pipe(
    map((devices: DRDevice[]) => devices.filter(device => device.type === 'WG') as WGDevice[]),
    replay()
  );

  /**
   * Observes the number of associated WG to the apiary via the hives.
   */
  public devices_wg__total$$ = this.devices_wg$$.pipe(
    map(devices => devices?.length ?? 0),
    distinctUntilRealChanged(),
    replay()
  );

  /** */
  public devices_gps$$: Observable<GPSDevice[]> = this.hives_devices$$.pipe(
    map((devices: DRDevice[]) => devices.filter(device => device.type === 'GPS') as GPSDevice[]),
    replay()
  );

  /**
   * Observes the number of associated GPS to the apiary via the hives.
   */
  public devices_gps__total$$ = this.devices_gps$$.pipe(
    map(devices => devices?.length ?? 0),
    distinctUntilRealChanged(),
    replay()
  );

  /** */
  public devices_cpt$$: Observable<CPTDevice[]> = this.hives_devices$$.pipe(
    map((devices: DRDevice[]) => devices.filter(device => device.type === 'CPT') as CPTDevice[]),
    replay()
  );

  /** */
  public devices_beelive$$: Observable<CPTDevice[]> = this.hives_devices$$.pipe(
    map((devices: DRDevice[]) => devices.filter(device => device.type === 'BeeLive') as CPTDevice[]),
    replay()
  );

  /**
   * Observes the number of associated CPT/BeeLive to the apiary via the hives.
   */
  public devices_beelive__total$$ = combineLatest({
    cpt: this.devices_cpt$$,
    beelive: this.devices_beelive$$,
  }).pipe(
    map(({ cpt, beelive }) => (cpt?.length ?? 0) + (beelive?.length ?? 0)),
    distinctUntilRealChanged(),
    replay()
  );

  public devices_rg$$: Observable<RGDevice[]> = this.all_rg_devices_config$$.pipe(
    map(rg_devices_configs => ({
      device_imeis: keys(rg_devices_configs).map(val => parseInt(val, 10)),
      rg_devices_configs,
    })),
    distinctUntilChanged((prev, next) => isEqual(prev?.device_imeis, next.device_imeis)),
    // tap(val => console.log(this.desc, 'rg device', val)),
    switchMap(({ device_imeis, rg_devices_configs }) => {
      if (device_imeis.length > 0 && this.hasACE('read_devices')) {
        // this._logger.debug(`loadRGDevices(${device_imeis})`);
        return this.deviceApi.requestDevices(device_imeis).pipe(
          // Add current device config in each device
          map(devices => {
            devices.map((device: any) => {
              // console.log(device,  devices_config[device.imei])
              device.rg_config = rg_devices_configs[device.imei]; //TODO: use proper association setup/config
              device.rg_config.rg_id = this.id;
            });
            return devices;
          })
        );
      } else {
        return of([]);
      }
    }),
    map(devices => devices.filter(device => device.type === 'RG')),
    map((devices: DRDevice[]) => devices.map(rg => rg as RGDevice)),
    tap(() => {
      this.devices_loading = false;
      // console.log('weather_stations : ', devices);
    }),
    replay()
  );

  /**
   * Observes the number of associated RG to the apiary.
   */
  public devices_rg__total$$ = this.devices_rg$$.pipe(
    map(devices => devices?.length ?? 0),
    distinctUntilRealChanged(),
    replay()
  );

  public local_devices_rg$$ = this.devices_rg$$.pipe(
    map(devices => devices.filter((device: any) => device.rg_config.since.event_id < 0)),
    replay()
  );

  public last_weather_date$$: Observable<Date> = combineLatest([this.devices_wg$$, this.devices_rg$$]).pipe(
    map(([wgs, rgs]) => {
      const all: DRDevice[] = flatten([wgs as DRDevice[], rgs as DRDevice[]]).filter(device => !isNil(device.last_env));

      if (all.length > 0) {
        return new Date(maxBy(all, value => value?.last_env?.time).last_env.time);
      }

      return new Date();
    })
  );

  // #region -> (devices management)

  /**
   * Observes apiary's devices.
   *
   * @public
   * @replay
   */
  public devices$$: Observable<DRDevice[]> = combineLatest([this.devices_rg$$, this.hives_devices$$]).pipe(
    map(devices => {
      const all_devices: DRDevice[] = flatten(devices);
      this.devices_loading = false;
      this.setDevices(all_devices);
      return all_devices;
    }),
    replay()
  );

  /**
   * Observes alarms of the apiary's devices.
   *
   * @todo This observable is based on static alarms of device and will not change automatically.
   *
   * @private
   * @replay
   */
  private devices_alarms$$: Observable<DeviceAlarm[]> = this.hives_devices$$.pipe(
    switchMap(devices => {
      const devices_alarms$$ = devices.map(device =>
        device.movement_alarm$$.pipe(
          map(last_movement_status => {
            const hive = this._device_2_hive[device.imei];

            return assign({}, { device, hive, last_movement_status });
          })
        )
      );

      return robustCombineLatest(devices_alarms$$);
    }),
    replay()
  );

  /**
   * Observes only movement alarms of apiary's devices.
   *
   * @public
   * @replay
   */
  public devices_alarms_movement$$ = this.devices_alarms$$.pipe(
    map(alarms => alarms.filter(alarm => !isNil(alarm.last_movement_status))),
    replay()
  );

  /**
   * Observes if the apiary has devices.
   */
  public has_devices$$ = this.devices_config$$.pipe(
    map(devices => size(devices ?? {}) > 0),
    replay()
  );

  /**
   * Observes if the apiary has equipped WG devices for weight measures.
   */
  public has_devices_for_weight_measure$$ = this.devices_wg$$.pipe(map(weight_scales => (weight_scales ?? [])?.length > 0));

  /**
   * Observes if the apiary has equipped GPSs on hives for temperature measures.
   */
  public has_devices_for_temperature_measure$$ = this.devices$$.pipe(
    map(devices => devices?.filter(device => device.type === 'GPS' || device.type === 'TG')),
    map(devices => devices?.length > 0),
    replay()
  );

  /** */
  public has_devices_for_movement_detection$$ = this.devices$$.pipe(
    map(devices => devices.filter(device => device.type === 'GPS')),
    map(devices => devices?.length > 0)
  );

  /**
   * Observes if the apiary has devices for bee counting measurement.
   *
   * @public
   * @replay
   */
  public has_devices_for_beecounting$$ = this.devices$$.pipe(
    map(devices => devices?.filter(device => device.type === 'CPT' || device.type === 'BeeLive')),
    map(devices => devices?.length > 0),
    replay()
  );

  /** */
  public sensor_devices$$: Observable<DRDevice[]> = this.devices$$.pipe(map(devices => devices.filter(device => !device.is_gateway)));

  /** */
  public gateway_devices$$: Observable<DRDevice[]> = this.devices$$.pipe(map(devices => devices.filter(device => device.is_gateway)));

  /** */
  public has_only_gps$$ = this.devices$$.pipe(
    map(devices => devices.map(device => device.type)),
    map(types => difference(types ?? [], ['GPS'])),
    map(non_GPS_types => non_GPS_types?.length === 0),
    replay()
  );

  // #endregion

  // #region -> (devices management for weather data)

  /**
   * Observes the list of devices used to display weather data along what weather data type is observable.
   *
   * @public
   * @replay
   */
  private devices_n_observable_weather_data$$ = combineLatest({ wguards: this.devices_wg$$, weather_stations: this.devices_rg$$ }).pipe(
    map(({ weather_stations, wguards }) => {
      const all_devices: DRDevice[] = [...weather_stations, ...wguards];
      return flatten(all_devices);
    }),
    switchMap(devices => {
      if (isEmpty(devices ?? [])) {
        return of<{ devices_to_keep: DRDevice[]; can_have: { ext_temperature: boolean; pressure: boolean; wind: boolean; rain: boolean } }>(
          { devices_to_keep: [], can_have: { ext_temperature: false, pressure: false, wind: false, rain: false } }
        );
      }

      const devices_with_generation$$ = devices.map(device => device.generation$$.pipe(map(generation => ({ device, generation }))));
      return combineLatest(devices_with_generation$$).pipe(
        map(devices_wt_generation => {
          const weather_stations_only_wt_gen = devices_wt_generation.filter(
            device_wt_generation => device_wt_generation.device.type === 'RG'
          );

          if (weather_stations_only_wt_gen?.length > 0) {
            const has_wind_gen_rg =
              (weather_stations_only_wt_gen.map(rg_wt_gen => rg_wt_gen.generation) ?? []).filter(generation => generation >= 2)?.length > 0;

            return {
              devices_to_keep: weather_stations_only_wt_gen.map(rg_wt_gen => rg_wt_gen.device),
              can_have: {
                rain: true,
                pressure: true,
                ext_temperature: true,
                wind: has_wind_gen_rg,
              },
            };
          }

          return devices_wt_generation
            .filter(device_wt_gen => device_wt_gen.device.type === 'WG')
            .reduce(
              (
                reduced: {
                  devices_to_keep: DRDevice[];
                  can_have: { ext_temperature: boolean; pressure: boolean; wind: boolean; rain: boolean };
                },
                current
              ) => {
                reduced.can_have.ext_temperature = true;

                if (current?.generation === 1) {
                  reduced.can_have.pressure = true;
                }

                reduced.devices_to_keep.push(current.device);
                return reduced;
              },
              { devices_to_keep: [], can_have: { ext_temperature: false, pressure: false, wind: false, rain: false } }
            );
        })
      );
    }),
    replay()
  );

  /**
   * Observes the list of devices used to display weather data.
   *
   * @public
   */
  public devices__for_weather_data$$ = this.devices_n_observable_weather_data$$.pipe(map(value => value?.devices_to_keep ?? []));

  // #endregion

  // #region -> (devices summary properties)

  /**
   * Observes the oldest device setup date among devices config.
   *
   * @public
   * @replay
   */
  public devices_oldest_setup_date = this.devices_config$$.pipe(
    map(devices_config => mapValues(devices_config, dvc_config => (dvc_config?.since?.date ? parseDate(dvc_config.since.date) : null))),
    map(setup_date_by_imei => values(setup_date_by_imei).filter(setup_date => !isNil(setup_date))),
    map(setup_dates => (isEmpty(setup_dates ?? []) ? null : min(setup_dates))),
    replay()
  );

  /**
   * Observes the oldest device setup date among devices config.
   *
   * @public
   * @replay
   */
  public apiary_oldest_device_setup_date$$ = this.devices_config$$.pipe(
    map(devices_config =>
      values(devices_config).reduce((build_up: Date[], device_config) => {
        const since_date = device_config?.since?.date ?? null;

        if (isNil(since_date)) return build_up;

        build_up.push(parseDate(since_date));

        return build_up;
      }, [])
    ),
    map(devices_setup_date => (isEmpty(devices_setup_date ?? []) ? null : min(devices_setup_date))),
    replay()
  );

  // #endregion

  // #region -> (data management)

  // #endregion

  // #region -> (counter data management)

  /** */
  public stream_beecounter_data$$ = (start: Date, end: Date): Observable<HiveBeeCountData[]> =>
    this.location$$.pipe(
      switchMap(location => location.stream_beecounter_data$$(start, end)),
      map(hive_beecount_data => {
        const has_any_value = some(hive_beecount_data.map(a => a.values?.length > 0));
        if (!has_any_value) {
          return [];
        }

        hive_beecount_data = hive_beecount_data.filter(hive_weight_data => hive_weight_data.hive_visible_on_chart);
        return hive_beecount_data;
      }),
      replay()
    );

  // #endregion

  // #region -> (weight data management)

  private _build_hive_weight_timeseries(hive_id: number, location_timezone: string, timeseries_response: GetEntityTimeseriesResponse) {
    const hive_num = this.getHiveNumberById(hive_id);
    return this.bg2Api.getEntityObj<Hive>(hive_id).pipe(
      switchMap(hive => {
        if (isNil(hive)) {
          return of<HiveWeightData>(<HiveWeightData>{ hive_id: null, values: [] });
        }

        return hive.is_visible_on_chart$$.pipe(
          map(is_hive_visible_on_chart => {
            const hive_weight_data: HiveWeightData = {
              hive,
              hive_id,
              hive_num,
              hive_name: hive.name,
              hive_color: hive.color,
              hive_visible_on_chart: is_hive_visible_on_chart,
              hive_unique_id: uniqueId(`hive-${hive_id}-`),
              values: [],
            };

            let has_sensor_lost_restablished = false;
            let previous_sensor_lost: WeightDataPoint = null;
            const _values = (timeseries_response?.timeseries?.data ?? []).map((point: any) => {
              const wgt_point: WeightDataPoint = {
                date: parseDate(point.date),
                timezone: location_timezone,
                tz_date: utcToZonedTime(point.date, location_timezone),

                weight: point[`weight__${hive_id}`] ?? null,
                weight_diff: null,
                weight_nb_sensors: point[`weight__${hive_id}_nb_sensors`] ?? null,

                has_lost_sensors: false,
                has_restablished_sensors: false,
              };
              return wgt_point
            }).map((wgt_point: WeightDataPoint, index, _all_values: WeightDataPoint[]) => {
              // Compute weight difference
              const previous_point = _all_values?.[index - 1];
              if (!isNil(previous_point?.weight) && !isNil(wgt_point?.weight)) {
                wgt_point.weight_diff = (wgt_point?.weight ?? 0) - (previous_point?.weight ?? 0);
              } else {
                wgt_point.weight_diff = null;
              }
              return wgt_point;
            }).map((wgt_point: WeightDataPoint, index, _all_values: WeightDataPoint[]) => {
              // Compote lost/retalished sensors (when a WG data is missing)
              if (isNil(wgt_point?.weight)) {
                return wgt_point;
              }

              const previous_point = _all_values[index - 1] ?? null;
              if (
                !isNil(previous_point?.weight_nb_sensors) &&
                previous_point?.weight_nb_sensors !== 0
              ) {
                // Ignore nb sensor change if no much diff in weight (less than 1.5 kg changes)
                if (wgt_point?.weight && previous_point?.weight
                  && Math.abs(wgt_point?.weight - previous_point?.weight) < 1.5
                ) {
                  return wgt_point;
                }
                if (previous_point?.weight_nb_sensors > wgt_point?.weight_nb_sensors) {
                  previous_sensor_lost = wgt_point;
                  wgt_point.has_lost_sensors = true;
                  has_sensor_lost_restablished = true;
                } else if (previous_point?.weight_nb_sensors < wgt_point?.weight_nb_sensors) {
                  if (previous_sensor_lost?.date && differenceInHours(wgt_point.date, previous_sensor_lost?.date) <= 2) {
                    // Cancel lost sensors if sensor is retablish in less than 2 hours
                    previous_sensor_lost.has_lost_sensors = false;
                  } else {
                    wgt_point.has_restablished_sensors = true;
                    wgt_point.has_lost_sensors = true;
                  }
                }
              }

              return wgt_point;
            });

            hive_weight_data.values = _values;
            return hive_weight_data;
          })
        );
      })
    );
  }

  public stream_weight_data$$(start: Date, end: Date, step: string = 'auto'): Observable<ApiaryWeightsData> {
    return this.location_timezone$$.pipe(
      switchMap(location_timezone =>
        this.streamAvailableTimeseries(start, end).pipe(
          map(available => available.filter(ts => ts.type === 'weight')),
          switchMap(measurement_descriptions => {
            if ((measurement_descriptions ?? [])?.length === 0) {
              return of<ApiaryWeightsData>({ hives_weight: [], has_wgt_data: false } as ApiaryWeightsData);
            }

            const measurement_description_names = measurement_descriptions.map(measurement_description => measurement_description.name);
            const loader = this.requestTimeseries(measurement_description_names, start, end, step).pipe(
              switchMap(timeseries_response => {
                const wgt_hive_builder: Observable<HiveWeightData>[] = measurement_descriptions.map(measurement_description => {
                  const hive_id = measurement_description.description_params.hive_id;
                  return this._build_hive_weight_timeseries(hive_id, location_timezone, timeseries_response);
                });
                return combineLatest(wgt_hive_builder);
              }),
              map(hives_weight => ({ hives_weight, has_wgt_data: true, timezone: location_timezone }))
            );
            return loader;
          }),
          map(data => {
            if (isEmpty(data?.hives_weight ?? [])) {
              return data;
            }

            const has_any_value = some(data.hives_weight.map(a => a.values?.length > 0));
            if (!has_any_value) {
              data.hives_weight = [];
              return data;
            }

            data.hives_weight = data.hives_weight.filter(hive_weight_data => hive_weight_data.hive_visible_on_chart);
            return data;
          }),
          replay()
        )
      ),
      take(1)
    );
  }

  // #endregion

  // #region -> (weather data management)

  /** */
  public last_weather_data$$: Observable<LastWeatherData> = this.last_weather_date$$.pipe(
    waitForNotNilValue(),
    switchMap((last_date: Date) =>
      this.requestTimeseries(
        ['temperature', 'humidity', 'pressure', 'rain', 'anemo_speed', 'anemo_heading'],
        subHours(last_date, 24),
        addHours(last_date, 1),
        'auto'
      )
    ),
    map(timeseries => {
      const raw_data = ((timeseries?.timeseries?.data as any) ?? []) as RawWeatherDataPoint[];
      const last_weather_data = {} as LastWeatherData;

      if (isEmpty(raw_data)) {
        return last_weather_data;
      }

      last_weather_data.end = parseDate(timeseries.timeseries.end);

      // Calculate last data for temperature
      const temperatures = raw_data.map(datum => datum?.temperature).filter(temperature => !isNil(temperature));
      if (!isEmpty(temperatures)) {
        const minimal_temperature = minBy(raw_data, 'temperature');
        const maximal_temperature = maxBy(raw_data, 'temperature');

        last_weather_data.temperature = {
          min: minimal_temperature?.temperature,
          timestamp_for_min: parseDate(minimal_temperature.date),

          max: maximal_temperature?.temperature,
          timestamp_for_max: parseDate(maximal_temperature.date),

          last: temperatures?.[temperatures.length - 1],
        };
      }

      // Calculate last data for humidity
      const humidities = raw_data.map(datum => datum?.humidity).filter(humidity => !isNil(humidity));
      if (!isEmpty(humidities)) {
        last_weather_data.humidity = {
          mean: mean(humidities),
          last: humidities?.[humidities.length - 1],
        };
      }

      // Calculate last data for pressure
      const pressure_values = raw_data.map(datum => datum.pressure).filter(pressure => !isNil(pressure));
      if (!isEmpty(pressure_values)) {
        last_weather_data.pressure = {
          mean: mean(pressure_values),
          last: pressure_values?.[pressure_values.length - 1],
        };
      }

      // Calculate last data for rain
      const rain_values = raw_data.map(datum => datum.rain).filter(rain => !isNil(rain));
      if (!isEmpty(rain_values)) {
        last_weather_data.rain = {
          last: rain_values?.[rain_values.length - 1],
          total: sum(rain_values),
        };
      }

      // Calculate last data for anemo_speed and anemo_heading
      const anemo_speed_values = raw_data.map(datum => datum.anemo_speed);
      const anemo_heading_values = raw_data.map(datum => datum.anemo_heading);
      const [anemo_speed_percentile_90, anemo_speed_percentile_idx] = percentile(90, anemo_speed_values) as [number, number];

      if (!isEmpty(anemo_speed_values)) {
        last_weather_data.anemo_speed = {
          last: anemo_speed_values[anemo_speed_values.length - 1],
          percentile_90: anemo_speed_percentile_90,
        };
      }

      if (!isEmpty(anemo_heading_values)) {
        last_weather_data.anemo_heading = {
          last: anemo_heading_values[anemo_heading_values.length - 1],
          percentile_90: anemo_heading_values[anemo_speed_percentile_idx],
        };
      }

      return last_weather_data;
    }),
    replay()
  );

  /**
   * Observes the weather station most recent measured temperature.
   */
  public last_weather_temperature$$: Observable<{ max: number; min: number; timestamp_for_min: Date; timestamp_for_max: Date }> =
    this.last_weather_data$$.pipe(
      map(weather_data => get(weather_data, 'temperature')),
      map(last_temperature_data => {
        if (isNil(last_temperature_data?.min) && isNil(last_temperature_data?.max)) {
          return null;
        }

        return last_temperature_data;
      })
    );

  /** */
  public last_weather_humidity$$: Observable<{ rain: number; humidity: number }> = this.last_weather_data$$.pipe(
    map(weather_data => ({ rain: weather_data?.rain?.total, humidity: weather_data?.humidity?.mean })),
    map(last_humidity_data => {
      if (isNil(last_humidity_data?.rain) && isNil(last_humidity_data?.humidity)) {
        return null;
      }

      return last_humidity_data;
    })
  );

  /**
   * Observes apiary's last weather wind speed and heading.
   *
   * @public
   * @replay
   */
  public last_weather_wind$$: Observable<{ speed: number; heading: number }> = this.last_weather_data$$.pipe(
    map(weather_data => ({ speed: weather_data?.anemo_speed?.percentile_90, heading: weather_data?.anemo_heading?.percentile_90 })),
    map(last_wind_data => {
      if (isNil(last_wind_data?.heading) && isNil(last_wind_data?.speed)) {
        return null;
      }

      return isEmpty(last_wind_data) ? null : last_wind_data;
    }),
    replay()
  );

  /**
   * Observes apiaries's last weather pressure.
   *
   * @public
   * @replay
   */
  public last_weather_pressure$$: Observable<number> = this.last_weather_data$$.pipe(
    map(last_weather_data => last_weather_data?.pressure?.last),
    replay()
  );

  /**
   * Observes the apiary atmospheric pressure range.
   */
  public last_weather_pressure_range$$: Observable<MinMax> = combineLatest({
    elevation: this.location$$.pipe(switchMap(location => location?.elevation$$ ?? of(null))),
    latitude: this.location$$.pipe(
      switchMap(location => location?.position$$ ?? of(null)),
      map(position => position?.latitude)
    ),
  }).pipe(
    switchMap(({ elevation, latitude }) =>
      this.last_weather_data$$.pipe(
        map(last_weather_data => ({
          latitude,
          elevation,
          temperature: last_weather_data?.temperature?.last,
        }))
      )
    ),
    map(({ temperature, latitude, elevation }) => {
      if (isNil(temperature) || isNil(latitude) || isNil(elevation)) {
        return null;
      }

      return {
        min: computes_barometric_pressure_from_sea_pressure(1010, temperature, elevation, latitude),
        max: computes_barometric_pressure_from_sea_pressure(1015, temperature, elevation, latitude),
      };
    })
  );

  /**
   * Observes if the apiary can have weather wind data.
   */
  private can_have_weather_wind_data$$: Observable<boolean> = this.devices_n_observable_weather_data$$.pipe(
    map(value => value?.can_have?.wind ?? false)
  );

  /**
   * Observes if the apiary can have weather pressure data.
   */
  private can_have_weather_pressure_data$$: Observable<boolean> = this.devices_n_observable_weather_data$$.pipe(
    map(value => value?.can_have?.pressure ?? false)
  );

  /**
   * Observes if the apiary can have weather rain data.
   */
  private can_have_weather_rain_data$$: Observable<boolean> = this.devices_n_observable_weather_data$$.pipe(
    map(value => value?.can_have?.rain ?? false)
  );

  /**
   * Observes if the apiary can have weather temperature data.
   */
  private can_have_weather_temperature_data$$: Observable<boolean> = this.devices_n_observable_weather_data$$.pipe(
    map(value => value?.can_have?.ext_temperature ?? false)
  );

  /**
   * Observes if the apiary can have specific weather data.
   */
  public can_have_weather_data$$ = combineLatest({
    wind: concat(of(null), this.can_have_weather_wind_data$$),
    rain: concat(of(null), this.can_have_weather_rain_data$$),
    pressure: concat(of(null), this.can_have_weather_pressure_data$$),
    temperature: concat(of(null), this.can_have_weather_temperature_data$$),
  }).pipe(waitForNotNilProperties(), replay());

  // #endregion

  public link$$ = combineLatest({
    id: this.id$$,
    name: this.name$$,
    is_dirty: this.dirty$$,
    is_archived: this.is_archived$$,
    location_id: this.location_id$$,
  }).pipe(
    debounceTime(40),
    distinctUntilRealChanged(),
    map(({ id, location_id, name, is_archived, is_dirty }) => {
      if (!is_archived && !isNil(location_id) && !is_dirty) {
        return `<a href="javascript:void(/locations, ${location_id})">${name}</a>`;
      } else {
        return `<a href="javascript:void(modal:update_entity, eid=${id})">${name}</a>`;
      }
    }),
    replay()
  );

  get description(): string {
    return i18n<string>(`ENTITY.APIARY.Apiary "[name]"`);
  }

  public elevation$$: Observable<number | null> = this.location$$.pipe(map(location => location?.elevation || null));

  public getHiveById(id: number): Hive {
    const hive_num = this.hive_by_id[id] || [null, null];
    return hive_num[0] || null;
  }

  public getHiveNumberById(id: number): number {
    const hive_num = this.hive_by_id[id] || [null, null];
    return hive_num[1];
  }

  get nb_declared_hives(): number {
    if (!this.state || !this.state.nb_hives || isNil(this.state.nb_hives)) {
      return null;
    }
    return this.state.nb_hives;
  }

  public nb_named_hives_nucs$$: Observable<number> = this.hive_ids$$.pipe(
    map(hives_id => hives_id.length),
    distinctUntilChanged(),
    replay()
  );

  public nb_named_hives$$ = this.hives$$.pipe(
    map(hives => hives.length),
    distinctUntilChanged(),
    replay()
  );

  public nb_declared_hives$$: Observable<number> = this.state$$.pipe(
    map(state => state?.nb_hives),
    distinctUntilChanged(),
    replay()
  );

  public hardware_stock$$ = this.state$$.pipe(
    map(state => state?.hardware_stock),
    replay()
  );

  /**
   * Get the real number of hives.
   * I.e. the max number between declared and named nb of hives.
   *
   *  [  nb declared hive  ]
   *  [     nb named hive and nuc   ]
   *  [ nb named hives ]
   *  [      nb hives      ]
   */
  public nb_hives$$: Observable<number> = combineLatest([this.nb_declared_hives$$, this.nb_named_hives_nucs$$]).pipe(
    switchMap(([nb_declared, nb_named_total]) => {
      if (isNil(nb_declared)) {
        return this.nb_named_hives$$;
      }

      if (nb_declared > nb_named_total) {
        return of(nb_declared);
      } else {
        return this.nb_named_hives$$.pipe(map(nb_named => Math.max(nb_named, nb_declared)));
      }
    }),
    distinctUntilChanged(),
    replay()
  );

  get nb_declared_nuc(): number {
    if (!this.state || !this.state.nb_nuc || isNil(this.state.nb_nuc)) {
      return null;
    }
    return this.state.nb_nuc;
  }

  public nb_declared_nuc$$: Observable<number> = this.state$$.pipe(
    map(state => state?.nb_nuc),
    distinctUntilChanged()
  );

  public nb_named_nuc$$ = this.nucs$$.pipe(
    map(hives => hives.length),
    distinctUntilChanged(),
    replay()
  );

  public nb_nuc$$ = combineLatest([this.nb_declared_nuc$$, this.nb_named_hives_nucs$$]).pipe(
    switchMap(([nb_declared, nb_named_total]) => {
      if (!isNil(nb_declared) && nb_declared > nb_named_total) {
        return of(nb_declared);
      } else {
        return this.nb_named_nuc$$.pipe(map(nb_named => Math.max(nb_named, nb_declared || 0)));
      }
    }),
    distinctUntilChanged(),
    replay()
  );

  get nb_total(): number {
    if (!this.state || (!this.state.nb_nuc && !this.state.nb_hives && isNil(this.state.nb_nuc) && isNil(this.state.nb_hives))) {
      return null;
    } else if (!this.state.nb_nuc && isNil(this.state.nb_nuc)) {
      return this.state.nb_hives;
    } else if (!this.state.nb_hives && isNil(this.state.nb_hives)) {
      return this.state.nb_nuc;
    } else {
      return this.state.nb_nuc + this.state.nb_hives;
    }
  }

  public nb_total$$ = combineLatest({
    nb_hives: this.nb_hives$$,
    nb_nuc: this.nb_nuc$$,
  }).pipe(map(({ nb_hives, nb_nuc }) => (nb_hives || 0) + (nb_nuc || 0)));

  // #region -> (hives/nucs counting)

  /**
   * Know if this apiary has the same number of declared hives/nucs and named hives/nucs.
   */
  public has_same_nb_of_declared_and_named_hives_and_nucs$$ = combineLatest({
    nb_named_hives: this.nb_named_hives$$,
    nb_named_nuc: this.nb_named_nuc$$,
    nb_declared_hives: this.nb_declared_hives$$,
    nb_declared_nuc: this.nb_declared_nuc$$,
  }).pipe(
    map(
      ({ nb_named_hives, nb_named_nuc, nb_declared_hives, nb_declared_nuc }) =>
        (nb_named_hives || 0) + (nb_named_nuc || 0) === (nb_declared_hives || 0) + (nb_declared_nuc || 0)
    ),
    distinctUntilRealChanged()
  );

  /**
   * Know if this apiary has not the same number of declared hives/nucs and named hives/nucs.
   */
  public has_not_same_nb_of_declared_and_named_hives_and_nucs$$ = this.has_same_nb_of_declared_and_named_hives_and_nucs$$.pipe(
    map(has_same_number_of_declared_and_named_hives_and_nucs => !has_same_number_of_declared_and_named_hives_and_nucs),
    distinctUntilRealChanged()
  );

  // #endregion

  // #region -> (named hives/nucs data)

  /**
   * Know if this apiary have at least 1 named hive.
   */
  public has_named_hives$$ = this.nb_named_hives$$.pipe(
    map(nb_named_hives => (nb_named_hives || 0) > 0),
    distinctUntilRealChanged()
  );

  /**
   * Know if this apiary have at least 1 named nuc.
   */
  public has_named_nucs$$ = this.nb_named_nuc$$.pipe(
    map(nb_named_nucs => (nb_named_nucs || 0) > 0),
    distinctUntilRealChanged()
  );

  /**
   * Know if this apiary have at least 1 named hive and 1 named nuc.
   */
  public has_named_hives_and_nucs$$ = allTrue(this.has_named_hives$$, this.has_named_nucs$$);

  /**
   * Know if this apiary have no named hive and no named nuc.
   */
  public has_no_named_hives_and_nucs$$ = this.has_named_hives_and_nucs$$.pipe(
    map(has_no_named_hives_and_nucs => !has_no_named_hives_and_nucs),
    distinctUntilRealChanged()
  );

  /**
   * Know if this apiary have at least one named hive or one named nuc.
   */
  public has_named_hives_or_named_nucs$$ = anyTrue(this.has_named_hives$$, this.has_named_nucs$$);

  // #endregion

  // #region -> (declared hives/nucs data)

  public has_no_declared_hives_and_nucs$$ = combineLatest({
    nb_declared_hives: this.nb_declared_hives$$,
    nb_declared_nuc: this.nb_declared_nuc$$,
  }).pipe(
    map(({ nb_declared_hives, nb_declared_nuc }) => (nb_declared_hives || 0) + (nb_declared_nuc || 0) === 0),
    distinctUntilRealChanged()
  );

  /**
   * Know if this apiary have at least 1 declared hive.
   */
  public has_declared_hives$$: Observable<boolean> = this.nb_declared_hives$$.pipe(
    map(nb_declared_hives => (nb_declared_hives || 0) > 0),
    distinctUntilRealChanged()
  );

  /**
   * Know if this apiary have at least 1 declared nuc.
   */
  public has_declared_nucs$$: Observable<boolean> = this.nb_declared_nuc$$.pipe(
    map(nb_declared_nucs => (nb_declared_nucs || 0) > 0),
    distinctUntilRealChanged()
  );

  /**
   * Know if this apiary have only declared hives.
   */
  public has_only_declared_hives$$: Observable<boolean> = combineLatest([this.has_declared_hives$$, this.has_declared_nucs$$]).pipe(
    map(([has_declared_hives, has_declared_nucs]) => has_declared_hives === true && has_declared_nucs === false),
    distinctUntilRealChanged()
  );

  /**
   * Know if this apiary have only declared NUCS.
   */
  public has_only_declared_nucs$$: Observable<boolean> = combineLatest([this.has_declared_hives$$, this.has_declared_nucs$$]).pipe(
    map(([has_declared_hives, has_declared_nucs]) => has_declared_hives === false && has_declared_nucs === true),
    distinctUntilRealChanged()
  );

  /**
   * Know if this apiary have at least 1 declared hive and 1 declared nuc.
   */
  public has_declared_hives_and_nucs$$: Observable<boolean> = allTrue(this.has_declared_hives$$, this.has_declared_nucs$$);

  /**
   * Know if this apiary have at least 1 declared hive or 1 declared nuc.
   */
  public has_declared_hives_or_nucs$$: Observable<boolean> = anyTrue(this.has_declared_hives$$, this.has_declared_nucs$$);

  // #endregion

  get nb_pallet(): number {
    if (!this.state || !this.state.nb_pallet || isNil(this.state.nb_pallet)) {
      return null;
    }
    return this.state.nb_pallet;
  }

  public nb_pallet$$ = this.state$$.pipe(
    map(state => state?.nb_pallet),
    distinctUntilChanged()
  );

  get nbs(): number {
    if (!this.state || !this.state.superbox) {
      return 0;
    }
    return this.state.superbox.nbs;
  }

  public nbs$$ = this.state$$.pipe(
    map(state => (state?.superbox?.nbs || 0) as number),
    distinctUntilChanged()
  );

  get superbox(): any {
    if (!this.state || !this.state.superbox) {
      return 0;
    }
    return this.state.superbox;
  }

  get nbs_mean_by_hive(): number {
    if (isNil(this.nb_declared_hives) || this.nb_declared_hives === 0) {
      return null;
    }
    return Math.ceil(this.nbs / this.nb_declared_hives);
  }

  public nbs_mean_by_hive$$ = combineLatest({
    nbs: this.nbs$$,
    nb_total: this.nb_total$$,
  }).pipe(
    map(({ nbs, nb_total }) => {
      if (isNil(nb_total) || nb_total === 0) {
        return null;
      }
      return Math.ceil(nbs / nb_total);
    })
  );

  public in_location_since$$ = this.state$$.pipe(
    map(state => state?.location_id_since?.date),
    map(date => (!isNil(date) ? parseDate(date) : null)),
    replay()
  );

  get devices(): DRDevice[] {
    if (isNil(this._devices)) {
      return [];
    }
    return this._devices;
  }

  private setDevices(devices: DRDevice[]): void {
    this._devices = devices;
  }

  public getAtDate(path: string, date?: Date, event_id?: number, stream = false): Observable<any> {
    if (path === 'state.warehouse_id') {
      return this.bg2Api.fetch_entity_state$(this.id, date, event_id).pipe(
        switchMap((res: any) => {
          const state = res.state?.state;
          if (!isNil(state) && !isNil(state.warehouse_id)) {
            return of(state.warehouse_id);
          } else if (!isNil(state) && !isNil(state.location_id)) {
            // Fail back to get warehouse id from exploiation
            console.warn(this.desc, `warehouse_id not in state, get ot from location ${state.location_id}`);
            return this.bg2Api.getEntityObj(state.location_id).pipe(
              map((entity: Entity) => entity as Location),
              switchMap(location => {
                if (!isNil(location)) {
                  return location.getAtDate('state.warehouse_id', date, event_id);
                } else {
                  return of(null);
                }
              })
            );
          } else {
            return of(null);
          }
        })
      );
    } else {
      return super.getAtDate(path, date, event_id, stream);
    }
  }

  public preDestroy(): void {
    super.preDestroy();
    this.hive_ids_sub?.unsubscribe();
  }

  /**
   * Request apiary hives at a given date (and before an event_id)
   *
   */
  public requestHivesAtDate(date?: Date, event_id?: number, stream = false): Observable<Hive[]> {
    return this.getAtDate('state.hive_ids', date, event_id, stream).pipe(
      switchMap((hive_ids: number[]) => {
        if (isNil(hive_ids)) {
          return of([]);
        } else {
          return this.bg2Api.getEntitiesObj(hive_ids) as Observable<Hive[]>;
        }
      })
    );
  }

  public requestLocationAtDate(date: Date = null, event_id: number = null): Observable<Location> {
    // console.log(`requestLocationAtDate(${date})`);
    return this.getAtDate('state.location_id', date, event_id).pipe(
      switchMap(apiary_id => {
        if (isNil(apiary_id)) {
          return of(null);
        } else {
          return this.location_id$$.pipe(
            take(1),
            switchMap(location_id => this.bg2Api.getEntityObj(location_id) as Observable<Location>)
          );
        }
      })
    );
  }

  public streamGhostAvailableTimeseries(start: Date, end: Date): Observable<MeasurementDescription[]> {
    // get at from ghost hives
    const ghost_hives_at$$ = this.ghost_hives$$.pipe(
      switchMap(hives =>
        robustCombineLatest(
          hives.map(hive => hive.streamGhostAvailableTimeseries(start, end).pipe(map(at => [hive, at] as [Hive, MeasurementDescription[]])))
        )
      ),
      map(ats => {
        // exteral mesurements
        const ext_ts = flatten(ats.map(([hive, at]) => at.filter(oat => ['temperature', 'humidity', 'pressure'].includes(oat.name))));

        // internal measurements
        const int_ts = flatten(
          ats.map(([hive, at]) =>
            at
              .filter(oat => ['internal_temperature', 'weight'].includes(oat.name))
              .map(oat => {
                const al_at = clone(oat);
                al_at.name = `${oat.name}__${hive.id}`;
                if (oat.name === 'weight') {
                  al_at.description = 'ALL.TIMESERIES.Weight of the hive "[hive_name]"';
                  al_at.description_short = 'ALL.TIMESERIES.hive "[hive_name]"';
                }
                if (oat.name === 'internal_temperature') {
                  al_at.description = 'ALL.TIMESERIES.Internal temperature of the hive "[hive_name]"';
                  al_at.description_short = 'ALL.TIMESERIES.Internal "[hive_name]"';
                }
                al_at.description_params = {
                  hive_name: hive.name,
                  hive_id: hive.id,
                };
                return al_at;
              })
          )
        );
        return ext_ts.concat(int_ts);
      })
    );

    let actual_ts$$ = of([]);
    // get available TS from apiary associated devices
    actual_ts$$ = this.local_devices_rg$$.pipe(
      map(devices => {
        const has_rg = devices.length > 0;
        const ats = [];
        if (has_rg) {
          ats.push({
            id: 'et',
            type: 'temperature',
            name: 'temperature',
            title: 'ALL.TIMESERIES.External_temperature',
            description: 'ALL.TIMESERIES.External temperature',
            description_short: 'ALL.TIMESERIES.External',
          });
          ats.push({
            id: 'h',
            type: 'humidity',
            name: 'humidity',
            title: 'ALL.TIMESERIES.Humidity',
            description: 'ALL.TIMESERIES.GPS humidity',
            description_short: 'ALL.TIMESERIES.Humidity',
          });
          ats.push({
            id: 'tc',
            type: 'temperature',
            name: 'temperature_com',
            title: 'ALL.TIMESERIES.Temperature (at com)',
            description: 'ALL.TIMESERIES.GPS temperature at the communication',
            description_short: 'ALL.TIMESERIES.Temperature (at com)',
          });
          ats.push({
            id: 'rg',
            type: 'rain',
            name: 'rain',
            title: 'ALL.TIMESERIES.Rain',
            description: 'ALL.TIMESERIES.Rain',
            description_short: 'ALL.TIMESERIES.Rain',
          });
        }
        return ats;
      })
    );
    return combineLatest([ghost_hives_at$$, actual_ts$$]).pipe(
      map(([gh_ats, a_ats]) => gh_ats.concat(a_ats)),
      map(ats => uniqBy(ats, 'name'))
    );
  }

  /** Request entity timeseries data
   */
  protected _requestTimeseries(
    measurements: Array<string> = [],
    start?: Date,
    end?: Date,
    step?: string
  ): Observable<GetEntityTimeseriesResponse> {
    return combineLatest([
      this.getLocalStateHistory('devices'),
      this.named_hives_nucs$$.pipe(
        switchMap(hives =>
          robustCombineLatest(
            hives.map(hive =>
              hive.getLocalStateHistory('devices').pipe(map(hive_dev_history => [hive.id, hive_dev_history] as [number, DeviceHistory]))
            )
          )
        )
      ),
    ]).pipe(
      take(1),
      switchMap(([apiary_dev_history, hives_dev_history]) => {
        const dev_history: { [eid: number]: DeviceHistory } = {};
        if (apiary_dev_history?.length > 0) {
          dev_history[this.id] = apiary_dev_history;
        }
        hives_dev_history.forEach(([hive_id, hive_dev_history]) => {
          if (hive_dev_history.length > 0) {
            dev_history[hive_id] = hive_dev_history;
          }
        });
        return super._requestTimeseries(measurements, start, end, step, dev_history);
      })
    );
  }

  /** Override request timeseries in case of ghost apiary
   */
  public requestGhostTimeseries(
    measurements?: Array<string>,
    start?: Date,
    end?: Date,
    step?: string
  ): Observable<GetEntityTimeseriesResponse> {
    // get data from ghost hives
    const splited_measurements = measurements.map(meas => meas.split('__'));
    const ghost_hives_meas = splited_measurements.filter(smeas => smeas.length === 2 && smeas[1][0] === '-');
    measurements = splited_measurements.filter(smeas => smeas.length === 1 || smeas[1][0] !== '-').map(smeas => smeas.join('__'));
    const apiary_measurements = splited_measurements.filter(smeas => smeas.length === 1).map(smeas => smeas[0]);

    const meas_by_ghost_hives: NumericDictionary<string[]> = {};
    let ghost_ts$$: Observable<[GetEntityTimeseriesResponse, number][]> = of([]);
    ghost_hives_meas.forEach(([meas, hive_id_str]) => {
      const hive_id = parseInt(hive_id_str, 10);
      meas_by_ghost_hives[hive_id] = [meas];
    });
    ghost_ts$$ = this.ghost_hives$$.pipe(
      switchMap(hives => {
        const requested_hives = hives.filter(hive => meas_by_ghost_hives[hive.id] || apiary_measurements.length > 0);
        const hive_ts$$ = requested_hives.map(hive => {
          const hive_measurements = (meas_by_ghost_hives?.[hive.id] || []).concat(apiary_measurements);
          return hive.streamGhostAvailableTimeseries(start, end).pipe(
            switchMap(ats => {
              const ats_names = ats.map(at => at.name);
              const filterd_hive_measurements = hive_measurements.filter(meas => ats_names.includes(meas));
              if (filterd_hive_measurements.length > 0) {
                return hive.requestTimeseries(filterd_hive_measurements, start, end, step).pipe(
                  map(ret => {
                    ret.available = ats;
                    return ret;
                  })
                );
              } else {
                return of(null);
              }
            }),
            map(gts => [gts, hive.id] as [GetEntityTimeseriesResponse, number])
          );
        });
        return robustCombineLatest(hive_ts$$);
      }),
      map(ghost_ts => ghost_ts.filter(([gts]) => !isNil(gts)))
    );

    const empty_ts: GetEntityTimeseriesResponse = {
      available: [],
      timeseries: {
        data: [],
      },
    };
    let ts$$ = of(empty_ts);
    if (measurements && measurements.length > 0) {
      // this._logger.debug(`Ghost entity local requests timeseries`, measurements);
      ts$$ = this.local_devices_rg$$.pipe(
        switchMap(devices => {
          const all_requests = devices.map(device => device.requestTimeseries(measurements, start, end, step));
          return robustCombineLatest(all_requests);
        }),
        map(all_ts => {
          if (all_ts.length === 0) {
            return empty_ts;
          } else {
            // TODO better aggregation
            return all_ts[0];
          }
        })
      );
    }
    return combineLatest([ghost_ts$$, ts$$]).pipe(
      map(([ghost_ts, ts]) => {
        if (ghost_ts.length > 0) {
          // rename ghost ts
          const ghost_data = ghost_ts.map(([gts, hive_id]) => {
            const fixed_data = gts.timeseries.data.map(dpoint => {
              const _dpoint = dpoint as Dictionary<any>;
              (meas_by_ghost_hives[hive_id] || []).forEach((hive_meas: string) => {
                if (has(_dpoint, hive_meas)) {
                  const meas = `${hive_meas}__${hive_id}`;
                  _dpoint[meas] = _dpoint[hive_meas];
                  delete _dpoint[hive_meas];
                }
              });
              return _dpoint as DataPoint;
            });
            return fixed_data;
          });
          // merge all available ts
          ts.available = ts.available || [];
          // const all_available  = ghost_ts.map(([ats, ]) => _.keyBy(ats.available, 'name'));
          // const merged_available = _.merge(_.keyBy(ts.available, 'name'), ...all_available);
          // ts.available = _.values(merged_available); // sort ?

          // merge all ts
          const all_data = ghost_data.map(data => keyBy(data, 'date'));
          const merged = merge(keyBy(ts.timeseries?.data, 'date'), ...all_data);
          ts.timeseries = ts.timeseries || {};
          ts.timeseries.data = values(merged); // sort ?
        }
        return ts;
      })
    );
  }

  /** */
  public stream_weather_data$$(start: Date, end: Date): Observable<WeatherData> {
    return this.location_timezone$$.pipe(
      switchMap(location_timezone =>
        this.requestTimeseries(['temperature', 'pressure', 'humidity', 'rain', 'anemo_speed', 'anemo_heading'], start, end, 'auto').pipe(
          map(timeseries_response => {
            const ats = timeseries_response.available || [];

            return {
              has_rain_data: ats.filter((md: MeasurementDescription) => md.type === 'rain').length > 0,
              has_anemo_speed_data: ats.filter(md => md.type === 'anemo_speed')?.length > 0,
              has_temperature_data: ats.filter((md: MeasurementDescription) => md.type === 'temperature').length > 0,
              has_anemo_heading_data: ats.filter(md => md.type === 'anemo_heading')?.length > 0,

              pressure_range: null,

              points: timeseries_response?.timeseries?.data?.map((point: WeatherDataPoint) => {
                point.date = parseDate(point.date);
                point.tz_date = utcToZonedTime(point.date, location_timezone);
                point.timezone = location_timezone;
                point.pressure = {
                  value: point.pressure as any,
                  value_sea_level: null,

                  zone: null,
                  range_up: null,
                  range_down: null,
                };
                return point;
              }),
            } as WeatherData;
          }),
          switchMap(weather_data =>
            this.last_weather_pressure_range$$.pipe(
              map(last_weather_pressure_range => {
                weather_data.pressure_range = last_weather_pressure_range;

                return weather_data;
              })
            )
          )
        )
      ),
      take(1)
    );
  }

  private _available_ts_caches$$: Dictionary<Observable<MeasurementDescription[]>> = {};

  public streamAvailableTimeseries(start: Date, end: Date): Observable<MeasurementDescription[]> {
    const key = `${start.toISOString()}__${end.toISOString()}`;
    if (!this._available_ts_caches$$[key]) {
      this._available_ts_caches$$[key] = this.devices_config$$.pipe(
        switchMap(() => this.requestTimeseries([], start, end, 'auto')),
        map(ts => ts.available),
        distinctUntilRealChanged(),
        replay()
      );
    }
    return this._available_ts_caches$$[key];
  }

  /** */
  public stream_temperature_data$$ = (start: Date, end: Date, step: string = 'auto') =>
    this.location_timezone$$.pipe(
      switchMap(timezone =>
        this.streamAvailableTimeseries(start, end).pipe(
          map(available_timeseries =>
            available_timeseries?.filter(
              measurement_desc => measurement_desc.type === 'temperature' && measurement_desc?.id !== 'htt' && !isNil(measurement_desc?.description_params?.hive_id)
            )
          ),
          switchMap(measurement_descriptions => {
            if ((measurement_descriptions ?? [])?.length === 0) {
              return of<HiveTemperatureData[]>([]);
            }

            const measurement_description_names = measurement_descriptions.map(measurement_description => measurement_description.name);
            const loader = this.requestTimeseries(measurement_description_names, start, end, step).pipe(
              switchMap(timeseries_response => {
                const tmp_hive_builder: Observable<HiveTemperatureData>[] = measurement_descriptions.map(measurement_description => {
                  const hive_id = measurement_description.description_params.hive_id;
                  const hive_num = this.getHiveNumberById(hive_id);

                  return this.bg2Api.getEntityObj<Hive>(hive_id).pipe(
                    switchMap(hive =>
                      (isNil(hive) ? of(false) : hive.is_visible_on_chart$$).pipe(
                        map(is_hive_visible_on_chart => {
                          const hive_data: HiveTemperatureData = {
                            hive,
                            hive_id,
                            hive_num,
                            hive_name: hive?.name,
                            hive_color: hive?.color,
                            hive_visible_on_chart: is_hive_visible_on_chart,
                            hive_unique_id: uniqueId(`hive-${hive_id}-`),

                            values: [],
                          };

                          hive_data.values = (timeseries_response?.timeseries?.data ?? []).map((point: any) => {
                            const hive_point: TemperatureDataPoint = {
                              timezone,
                              date: parseDate(point.date),
                              tz_date: utcToZonedTime(point.date, timezone),
                              temperature: undefined,
                            };

                            hive_point.temperature = point?.[`internal_temperature__${hive_id}`] ?? null;

                            return hive_point;
                          });

                          return hive_data;
                        })
                      )
                    )
                  );
                });

                return combineLatest(tmp_hive_builder);
              })
            );
            return loader;
          }),
          map(hive_temperature_data => {
            if (isEmpty(hive_temperature_data ?? [])) {
              return hive_temperature_data;
            }

            const has_any_value = some(hive_temperature_data.map(a => a.values?.length > 0));
            if (!has_any_value) {
              hive_temperature_data = [];
              return hive_temperature_data;
            }

            hive_temperature_data = hive_temperature_data.filter(hive_temperature_datum => hive_temperature_datum.hive_visible_on_chart);
            return hive_temperature_data;
          }),
          replay()
        )
      ),
      take(1)
    );

  // #region -> (ghost state management)

  public ghost_status$$ = combineLatest([this.is_ghost$$, this.has_ghost_hives$$]).pipe(
    map(([is_ghost, has_ghost_hives]) => ({ is_ghost, has_ghost_hives }))
  );

  public badly_associated_devices$$ = this.location$$.pipe(
    switchMap(location => (isNil(location) ? of<DRDevice[]>([]) : location.badly_associated_devices$$))
  );

  // #endregion

  // #region -> (weather data management)

  public is_weather_data_outdated_by_48h$$ = of(new Date()).pipe(
    switchMap(date =>
      this.last_weather_data$$.pipe(
        map(last_weather_data => (last_weather_data ? differenceInHours(date, last_weather_data?.end) > 48 : true))
      )
    ),
    distinctUntilRealChanged(),
    replay()
  );

  public has_weather_last_data$$ = this.last_weather_data$$.pipe(
    map(weather => {
      if (isNil(weather)) {
        return false;
      }

      const has_temp = weather?.temperature?.min && weather?.temperature?.max;
      const has_pressure = weather?.pressure?.mean;
      const has_rain_or_humidity = weather?.rain?.total >= 0 || weather?.humidity?.mean;
      const has_anemo = weather?.anemo_speed?.percentile_90 || weather?.anemo_heading?.percentile_90;

      return has_temp || has_pressure || has_rain_or_humidity || has_anemo;
    }),
    distinctUntilRealChanged(),
    replay()
  );

  // #endregion

  // #region -> (global data management)
  public buildSolutionForDevicesFromSameApiary(
    device_group: DevicesFromSameLocation,
    location: Location,
    exploitation: Exploitation,
    all_groups: DevicesFromSameLocation[]
  ): GhostSolutionAlternatives {
    const alternatives = new GhostSolutionAlternatives(GhostSolutionLevel.LVL_HIVE);
    const new_hive_solution = new GhostSolution('new_hive');

    const devices = device_group.devices;
    alternatives.setDevices(devices);
    const affectations = device_group.affectations;

    // Manage individual device association
    devices.forEach((device, idx) => {
      const affectation = affectations[idx];
      // First solution: new hive
      if (device.type === 'GPS' || device.type === 'WG') {
        const ghive_ref = new_hive_solution.newGhostEntity('hive', { name: 'Ruche' });
        const guest_setup_date = dateMax([device.at_this_position_since, this.location_since_date]);

        const setup_hive = new HiveSetup(this.bg2Api);
        setup_hive.date = guest_setup_date;
        setup_hive.setOperand('apiary', this.id);
        new_hive_solution.addEvent(setup_hive, ['hive', ghive_ref]);

        if (device?.type === 'GPS') {
          const install_device = new DeviceGPSInstall(this.bg2Api);
          install_device.date = guest_setup_date;
          install_device.setOperand('warehouse', exploitation.warehouse_id);
          if (affectation.hive) {
            install_device.setOperand('previous_hive', affectation.hive.entity.id);
          }
          if (affectation.apiary) {
            install_device.setOperand('previous_apiary', affectation.apiary.entity.id);
          }
          install_device.setOperand('apiary', this.id);
          install_device.data = {
            device: {
              type: device.type,
              imei: device.imei,
            },
          };
          new_hive_solution.addEvent(install_device, ['hive', ghive_ref]);
        } else if (device?.type === 'WG') {
          const install_device = new DevicesWGInstall(this.bg2Api);
          install_device.setOperand('warehouse', exploitation.warehouse_id);
          install_device.setOperand('apiary', this.id);
          install_device.date = guest_setup_date;
          if (affectation.hive) {
            install_device.setOperand('previous_hive_wg1', affectation?.hive?.entity?.id);
          }
          if (affectation.apiary) {
            install_device.setOperand('previous_apiary_wg1', affectation?.apiary?.entity?.id);
          }
          install_device.data = {
            wg_configuration: 'FRONT',
            WG1: {
              type: device.type,
              imei: device.imei,
            },
          };
          new_hive_solution.addEvent(install_device, ['hive', ghive_ref]);
        }
      } else if (device.type === 'RG') {
        const install_rg_solution = new GhostSolution('install_RG_device');
        const rg_install = new DeviceRGInstall(this.bg2Api);
        rg_install.setOperand('warehouse', exploitation.warehouse_id);
        rg_install.setOperand('apiary', this.id);
        if (device.hive_config?.hive_id) {
          rg_install.setOperand('previous_apiary', device.hive_config.hive_id);
        }
        rg_install.data = {
          device: {
            imei: device.imei,
            type: device.type,
          },
        };
        rg_install.date = device.status_gps.timestamp;
        install_rg_solution.addEvent(rg_install);
        alternatives.add(install_rg_solution); // default solution
      } else {
        console.error('Device type not yet supported in ghost association');
      }
    });
    new_hive_solution.setDevices(device_group.devices);
    new_hive_solution.setSourceLocation(device_group.location);
    new_hive_solution.setDescription(i18n('GHOST.SOLUTION.New hive instalation'), {});
    alternatives.add(new_hive_solution); // default solution

    // Manage migratory solutions
    if (device_group.location && (all_groups.length > 1 || this.id > 0)) {
      // Do not propose migratory if apiary is ghost, and only one group of devices

      const migratory_solution = new GhostSolution('migratory');
      const migratory = new Migratory(this.bg2Api);
      const migratory_since = min(device_group.devices.map(device => device.at_this_position_since));
      migratory.date = dateMax([migratory_since, this.location_since_date]);
      migratory.data = {
        partial: false,
      };
      migratory.setOperand('apiary', device_group.apiary.id);
      migratory.setOperand('apiary_dest', this.id);
      migratory.setOperand('location_dest', location.id);
      migratory.setOperand('location_source', device_group.location.id);
      migratory.setOperand('warehouse_source', device_group?.affectations?.[0]?.warehouse?.entity?.id);
      migratory.setOperand('warehouse_dest', device_group?.affectations?.[0]?.warehouse?.entity?.id);
      migratory_solution.addEvent(migratory);
      migratory_solution.setDevices(device_group.devices);
      migratory_solution.setSourceLocation(device_group.location);
      migratory_solution.setDescription(i18n('GHOST.SOLUTION.Migratory from [location->name]'), {
        location: device_group.location,
      });
      alternatives.add(migratory_solution);
    }
    alternatives.setDevices(device_group.devices);
    alternatives.setSourceLocation(device_group.location);
    return alternatives;
  }

  public buildSolutionForPossibleDevices(devices_by_previous_location: DevicesFromSameLocation[]): Observable<GhostSolutionAlternatives[]> {
    this._logger.debug('New compatible devices:', devices_by_previous_location);
    if (keys(devices_by_previous_location).length === 0) {
      return of([]);
    } else {
      this._logger.debug('New compatible devices   --:');
      return this._buildSolutionForPossibleDevices(devices_by_previous_location);
    }
  }

  private _buildSolutionForPossibleDevices(
    devices_by_previous_location: DevicesFromSameLocation[]
  ): Observable<GhostSolutionAlternatives[]> {
    return combineLatest([
      this.exploitation$$.pipe(filter(exploitation => !isNil(exploitation))),
      this.location$$.pipe(filter(location => !isNil(location))),
    ]).pipe(
      take(1), // NOTE: this is super important to avoid recompute solution when applying it
      // maybe one can use debounce ? distinctUntilChange
      map(([exploitation, location]) => {
        const all_solutions: GhostSolutionAlternatives[] = [];

        devices_by_previous_location.map(device_group => {
          const alternatives = this.buildSolutionForDevicesFromSameApiary(
            device_group,
            location,
            exploitation,
            devices_by_previous_location
          );
          all_solutions.push(alternatives);
        });

        return all_solutions;
      })
    );
  }
  // #endregion

  // #region -> (error management)

  /**
   * Dictionnary of prebuilt error models.
   *
   * @public
   */
  public prebuilt_error_models = {
    /** */
    get_error_manage_hive_and_devices$$: (message: string): Observable<ErrorHelperData> =>
      this.id$$.pipe(
        map(
          apiary_id =>
            new ErrorHelperData([
              {
                type: 'span',
                content: message,
              },
              {
                type: 'button',
                color: 'device',
                icon: 'mdi-shape-polygon-plus',
                message: i18n<string>('VIEWS.APIARY.SHARED.WIDGETS.APIARY_LAST_DATA.Manage Hives & Devices'),
                navigate: {
                  commands: ['', { outlets: { modal: ['apiary_hive_list', { eid: apiary_id }] } }],
                },
              },
            ])
        )
      ),
  };

  // #endregion
}
