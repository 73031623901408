import { ChangeDetectionStrategy, Component, OnDestroy } from '@angular/core';

import { PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { SelectionModel } from '@angular/cdk/collections';

import { catchError, debounceTime, distinctUntilChanged, filter, map, merge, switchMap, take, tap, timeout } from 'rxjs';
import { BehaviorSubject, combineLatest, concat, forkJoin, isObservable, Observable, of, Subscription } from 'rxjs';

import { Paging, SimpleSetterGetter } from 'app/models';
import { AvailableAction, ColumnRunTimeConfig, ColumnsRunTimeConfig, DatatableColumn, DatatableGroup } from 'app/models/misc/datatable';

import { defaultValue, distinctUntilRealChanged, replay, waitForNotNilValue } from '@bg2app/tools/rxjs';

import {
  isEqual,
  clone,
  uniqBy,
  cloneDeep,
  isUndefined,
  isNil,
  get,
  isArray,
  isPlainObject,
  isEmpty,
  flatten,
  isFunction,
  isObject,
} from 'lodash-es';

import _merge from 'lodash.merge';

import { DatatableBaseRow } from 'app/typings/datatable/interfaces/DatatableBaseRow.iface';
import { CompareByType, compareObjectsByType } from 'app/misc/tools';
import { ExportHeader } from 'app/models/export';
import { TranslateService } from '@ngx-translate/core';
import { Sort } from '@angular/material/sort';
import { UrlParamsService } from 'app/core/url-param.service';
import { Dictionary } from 'app/typings/core/interfaces';

/** */
export interface DatatableSortingModel<RowType> {
  /** */
  sort: Sort;

  /** */
  server_sort_key: string[];

  /** */
  column: DatatableColumn<RowType>;
}

/**
 * @template RowType Generic type for row data type.
 */
@Component({
  selector: 'bg2-datatable',
  template: '',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export abstract class TableBaseComponent<RowType extends DatatableBaseRow> implements OnDestroy {
  /**
   * Public reference to the table data source.
   *
   * @note This is a material object that manage internal observable which notify the table when data or sort change.
   */
  public data_source: MatTableDataSource<RowType | DatatableGroup<RowType>> = new MatTableDataSource([]);

  protected customFilterPredicate(data: RowType | DatatableGroup<RowType>, filter_val: string): boolean {
    return data instanceof DatatableGroup ? data.visible : this.getDataRowVisible(data as any);
  }

  // #region -> (errors management)

  private _error$$: BehaviorSubject<any> = new BehaviorSubject(null);
  public error$$: Observable<any> = this._error$$.asObservable().pipe(distinctUntilRealChanged<any>(), replay());

  public has_error$$ = this.error$$.pipe(map(error => !isNil(error)));

  public has_not_error$$ = this.has_error$$.pipe(map(has_error => !has_error));

  public set error(error: any) {
    this._error$$.next(error);
  }

  // #endregion

  /** */
  private readonly URL_KEY_DATATABLE_PAGING: string = 'dtatbl_pge';

  /** */
  private readonly URL_KEY_DATATABLE_GROUPBY: string = 'dtatbl_gpby';

  protected raw_data_sub: Subscription;

  constructor(private translate: TranslateService, private readonly _url_parameters_service: UrlParamsService) {
    this.raw_data_sub = combineLatest({ rows: this.paged_data_rows$$, grouped_columns: this.grouped_columns$$ })
      .pipe(
        switchMap(({ rows, grouped_columns }) => {
          if ((rows ?? [])?.length === 0) {
            return of([]);
          }

          const root_group = new DatatableGroup<RowType>();
          root_group.expanded = true;

          return this.getSublevel(rows, 0, grouped_columns, root_group);
        })
      )
      .subscribe(rows => {
        this.data_source.data = rows;
        this.data_source.filterPredicate = this.customFilterPredicate.bind(this);
        this.data_source.filter = performance.now().toString();
      });
  }

  ngOnDestroy(): void {
    this.raw_data_sub?.unsubscribe();

    // Remove specific URL parameters
    this._url_parameters_service.del('cols');
    this._url_parameters_service.del('cols_sort');

    if (this.track_pagination_in_url.value) {
      this._url_parameters_service.del(this.URL_KEY_DATATABLE_PAGING);
    }

    if (this.track_groupby_in_url.value) {
      this._url_parameters_service.del(this.URL_KEY_DATATABLE_GROUPBY);
    }
  }

  // #region -> (data management)

  private _filtered_data_rows$$: BehaviorSubject<RowType[]> = new BehaviorSubject([]);
  public filtered_data_rows$$: Observable<RowType[]> = this._filtered_data_rows$$.asObservable().pipe(replay());

  public set filtered_data_rows(filtered_data_rows: RowType[]) {
    this._filtered_data_rows$$.next(filtered_data_rows);
  }

  public get filtered_data_rows(): RowType[] {
    return this._filtered_data_rows$$.getValue();
  }

  private _paged_data_rows$$: BehaviorSubject<RowType[]> = new BehaviorSubject([]);
  public paged_data_rows$$: Observable<RowType[]> = this._paged_data_rows$$.asObservable().pipe(replay());

  public set paged_data_rows(paged_data_rows: RowType[]) {
    this._paged_data_rows$$.next(paged_data_rows);
  }

  public get paged_data_rows(): RowType[] {
    return this._paged_data_rows$$.getValue();
  }

  public nb_raw_rows$$: Observable<number> = this.paged_data_rows$$.pipe(
    map(rows => rows.length),
    replay()
  );

  // True if we have data to display
  public has_data$$: Observable<boolean> = this.nb_raw_rows$$.pipe(map(nb_rows => nb_rows > 0));

  /** */
  private getSublevel(
    rows: RowType[],
    level: number,
    group_by_cols: string[],
    parent: DatatableGroup<RowType>
  ): Observable<(RowType | DatatableGroup<RowType>)[]> {
    if (isEmpty(rows ?? [])) {
      return of([]);
    }

    if (level >= group_by_cols.length) {
      rows.map((row: RowType) => (row.parent = parent));
      return of(rows);
    }

    const datatable_groups$$ = rows.map(row => {
      const candicate_group = new DatatableGroup();
      candicate_group.level = level + 1;
      candicate_group.parent = parent;
      candicate_group.values = {};
      candicate_group.values_to_load$$ = {};

      return of({ _candidate_group: candicate_group, _level: level, _row: row }).pipe(
        map(({ _candidate_group, _level, _row }) => {
          for (let level_index = 0; level_index <= _level; level_index++) {
            const row_value: Observable<any> | any = get(_row, group_by_cols[level_index]) ?? of(null);
            const row_value$$: Observable<any> = isObservable(row_value) ? row_value : of(row_value);

            _candidate_group.property = group_by_cols[level_index];
            _candidate_group.values_to_load$$[group_by_cols[level_index]] = row_value$$.pipe(take(1));
          }

          return _candidate_group;
        }),
        switchMap(_candidate_group => {
          const values_to_load$$ = forkJoin(_candidate_group.values_to_load$$);
          return values_to_load$$.pipe(
            map(values_to_load => {
              _candidate_group.values = values_to_load;
              return _candidate_group;
            })
          );
        })
      );
    });

    return forkJoin(datatable_groups$$).pipe(
      switchMap(datatable_groups => {
        const groups = uniqBy(datatable_groups, (_row: DatatableGroup<RowType>) => JSON.stringify(_row.values));

        const sub_groups: Observable<(RowType | DatatableGroup<RowType>)[]>[] = groups.map((group: DatatableGroup<RowType>) => {
          const rows_for_group$$ = rows.map(_row => {
            const row_value: Observable<any> | any = get(_row, group_by_cols[level]);
            const row_value$$: Observable<any> = isObservable(row_value) ? row_value : of(row_value);

            return row_value$$.pipe(
              take(1),
              map(_row_value => {
                const reference_value = group.values[group_by_cols[level]];

                if (isNil(reference_value) && isNil(_row_value)) {
                  return _row;
                }

                if (!isEqual(reference_value, _row_value)) {
                  return null;
                }

                return _row;
              })
            );
          });

          return forkJoin(rows_for_group$$).pipe(
            map(rows_for_group => rows_for_group.filter(row => !isNil(row))),
            switchMap(rows_for_group => {
              group.totalCounts = rows_for_group.length;

              if (rows_for_group.length > 0 && !isNil(rows_for_group[0])) {
                group.first_row = clone(rows_for_group[0]);
              }

              const last_associated_column = this.flattened_columns.filter(column => isEqual(column.property, group.property))[0];
              group.label = last_associated_column?.label ?? undefined;
              group.value = !isNil(group?.values?.[group?.property]) ? group.values[group.property] : '-';

              return this.getSublevel(rows_for_group, level + 1, group_by_cols, group).pipe(
                map(_sub_group => {
                  _sub_group.unshift(group);
                  return _sub_group;
                })
              );
            })
          );
        });

        return forkJoin(sub_groups).pipe(map(_data => flatten(_data)));
      })
    );
  }

  protected getDataRowVisible(row: RowType): boolean {
    const parent = row.parent;
    return !parent || (parent.visible && parent.expanded);
  }

  // #endregion

  // #region -> (selection management)

  public selection_model: SelectionModel<RowType | DatatableGroup<RowType>> = new SelectionModel(true, []);

  public selection_changed$$: Observable<SelectionModel<RowType | DatatableGroup<RowType>>> = concat(
    of(null),
    this.selection_model.changed
  ).pipe(
    map(() => this.selection_model),
    replay()
  );

  public nb_selected$$: Observable<number> = this.selection_changed$$.pipe(
    map(selection_model => selection_model.selected.length),
    replay()
  );

  public some_selected$$: Observable<boolean> = this.nb_selected$$.pipe(
    map(nb_selected => nb_selected > 0),
    replay()
  );

  public all_selected$$ = combineLatest([this.nb_selected$$, this.nb_raw_rows$$]).pipe(
    map(([nb_selected, nb_rows]) => nb_rows > 0 && nb_selected === nb_rows),
    replay()
  );

  public isSelected$$(row: RowType | DatatableGroup<RowType>): Observable<boolean> {
    return this.selection_changed$$.pipe(
      map(selection_model => selection_model.isSelected(row)),
      distinctUntilChanged(),
      replay()
    );
  }

  public masterToggle(): void {
    if (this.selection_model.selected.length === this.paged_data_rows.length) {
      this.selection_model.clear();
    } else {
      this.selection_model.select(...(this.paged_data_rows as any));
    }
  }

  /** */
  public groupActionByFn = (item: AvailableAction): string => item?.group?.name ?? null;

  /** */
  public groupActionValueFn = (_: string, data: AvailableAction[]): AvailableAction['group'] => data[0]?.group;

  /** */
  public asAvailableAction(item: Dictionary<any>): AvailableAction {
    if (!isObject(item)) {
      return null;
    }

    return <AvailableAction>item;
  }

  /** */
  public asAvailableActionGroup(item: Dictionary<any>): AvailableAction['group'] {
    if (!isObject(item)) {
      return null;
    }

    return <AvailableAction['group']>item;
  }

  // #endregion

  // #region -> (columns management)

  /** */
  public track_by_column(index: number, column: DatatableColumn<RowType>) {
    return column?.property;
  }

  public column_sort_type(column: DatatableColumn<RowType>): 'by-default' | 'by-number' | 'by-abc' | 'by-date' {
    const comparator = column?.compare_by;

    if (isFunction(comparator) || isNil(comparator)) {
      return 'by-default';
    }

    if (comparator === CompareByType.STRING) {
      return 'by-abc';
    }

    if (comparator === CompareByType.NUMBER) {
      return 'by-number';
    }

    if (comparator === CompareByType.DATE) {
      return 'by-date';
    }

    if (comparator === CompareByType.NULL) {
      return 'by-default';
    }

    return 'by-default';
  }

  protected _all_columns$$: BehaviorSubject<DatatableColumn<RowType>[]> = new BehaviorSubject(null);

  /**
   * Ordered list of displayed cols
   */
  public all_columns$$: Observable<DatatableColumn<RowType>[]> = this._all_columns$$.asObservable().pipe(
    filter((all_columns: DatatableColumn<RowType>[]) => !isNil(all_columns)),
    replay()
  );

  public set all_columns(all_columns: DatatableColumn<RowType>[]) {
    this._all_columns$$.next(all_columns);
  }

  public get all_columns(): DatatableColumn<RowType>[] {
    return this._all_columns$$.getValue();
  }

  public flattened_columns$$ = this.all_columns$$.pipe(
    distinctUntilRealChanged(),
    map(columns => columns.map(column => (column?.inner?.length > 0 ? column.inner : column))),
    map(columns => flatten(columns)),
    replay()
  );

  public get flattened_columns(): DatatableColumn<RowType>[] {
    return flatten(this.all_columns.map(column => (column?.inner?.length > 0 ? column.inner : column)));
  }

  private _cols_conf: ColumnsRunTimeConfig;

  public set columns_config(cols_conf: ColumnsRunTimeConfig) {
    this._columns_config$$.next(cloneDeep(cols_conf));
  }

  public get columns_config(): ColumnsRunTimeConfig {
    return cloneDeep(this._cols_conf);
  }

  private _columns_config$$: BehaviorSubject<ColumnsRunTimeConfig> = new BehaviorSubject(null);
  public columns_config$$: Observable<ColumnsRunTimeConfig> = this._columns_config$$.asObservable().pipe(
    // tap(val => console.log('debug n1', val)),
    switchMap(cols_conf => {
      if (isNil(cols_conf)) {
        // Default cols selection
        return this.all_columns$$.pipe(
          map(all_columns =>
            all_columns.map(column => {
              const col_conf: ColumnRunTimeConfig = {
                property: column.property,
                displayed: column?.always_hidden ? false : column?.displayed_by_default || false,
                short_property: column?.short_property,
                required: column?.always_hidden ? false : column?.not_configurable,
                visible_only_for: column.visible_only_for,
                is_available: column?.is_available || (() => true),
                inner:
                  column?.inner?.length < 1
                    ? null
                    : column?.inner?.map(
                        inner_column =>
                          ({
                            property: inner_column.property,
                            short_property: inner_column?.short_property,
                            displayed: inner_column?.displayed_by_default || false,
                            required: inner_column.not_configurable,
                            visible_only_for: inner_column.visible_only_for,
                            is_available: inner_column?.is_available || (() => true),
                          } as ColumnRunTimeConfig)
                      ),
              };
              return col_conf;
            })
          )
        );
      }
      return of(cols_conf);
    }),
    distinctUntilRealChanged(),
    // tap(val => console.log('debug n2', val)),
    tap(cols_conf => (this._cols_conf = cols_conf)),
    replay()
  );

  public toggle_col_display(col_property: string, toggle_val?: boolean) {
    const cols_conf = this.columns_config;
    const col_conf = cols_conf.find(col => col.property === col_property);
    if (!col_conf) {
      return;
    }
    col_conf.displayed = isUndefined(toggle_val) ? !col_conf.displayed : toggle_val;
    this.columns_config = cols_conf;
  }

  private _displayed_columns_ref: string[] = [];
  public displayed_columns$$: Observable<string[]> = this.columns_config$$.pipe(
    map((columns: ColumnsRunTimeConfig) => {
      const flattened_columns = flatten(
        columns.map(column => (column?.inner?.length > 0 ? (column.displayed ? column.inner : []) : column))
      );
      return flattened_columns.filter(colconf => colconf.displayed);
    }),
    map((columns: ColumnsRunTimeConfig) => columns.map(column => column.property)),
    distinctUntilRealChanged(),
    tap((cols: string[]) => (this._displayed_columns_ref = cols)),
    replay()
  );

  public get displayed_columns(): string[] {
    return this._displayed_columns_ref;
  }

  // #endregion

  // #region -> (columns grouping)

  /** */
  private _on_delete_groupby_param$$ = new BehaviorSubject<boolean>(null);

  /** */
  protected track_groupby_in_url = new SimpleSetterGetter(true);

  /** */
  private _local_groupby$$ = new BehaviorSubject<string[]>([]);

  /** */
  private groupby_source$$: Observable<string[]> = this.track_groupby_in_url.value$$.pipe(
    switchMap(track_groupby_in_url => {
      if (track_groupby_in_url) {
        return merge(
          this._on_delete_groupby_param$$.pipe(
            waitForNotNilValue(),
            map(() => [])
          ),
          this._url_parameters_service.on_change(this.URL_KEY_DATATABLE_GROUPBY).pipe(
            defaultValue(<string[]>[], 1000),
            map((groupby: string[]) => {
              if (isNil(groupby)) {
                return [];
              }

              return groupby
                .filter(group_by_name => !isNil(group_by_name))
                .filter(group_by_name => group_by_name !== '~')
                .filter(group_by_name => !isEmpty(group_by_name?.trim()));
            })
          )
        );
      }

      return this._local_groupby$$.asObservable();
    }),
    replay()
  );

  /** */
  public grouped_columns$$ = this.groupby_source$$.pipe(distinctUntilRealChanged(), replay());

  /** */
  public set_grouped_columns(grouped_columns: string[]): void {
    const track_groupby_in_url = this.track_groupby_in_url.value;

    if (track_groupby_in_url) {
      if (grouped_columns?.length === 0) {
        this._on_delete_groupby_param$$.next(true);
        this._url_parameters_service.set(this.URL_KEY_DATATABLE_GROUPBY, ['~']);
      } else {
        this._url_parameters_service.set(this.URL_KEY_DATATABLE_GROUPBY, grouped_columns);
      }
    } else {
      this._local_groupby$$.next(grouped_columns);
    }
  }

  /** */
  public isGroup(index: number, row: RowType | DatatableGroup<RowType>): boolean {
    return row instanceof DatatableGroup;
  }

  public groupHeaderClick(group: DatatableGroup<RowType>): void {
    group.expanded = !group.expanded;
    this.data_source.filter = performance.now().toString();
  }

  // #endregion

  // #region -> (sorting management)

  /** */
  private _on_delete_cols_sort_param$$ = new BehaviorSubject(true);

  /** */
  protected sorting_model$$ = this.flattened_columns$$.pipe(
    filter(flattened_columns => !isNil(flattened_columns) && !isEmpty(flattened_columns)),
    switchMap(flattened_columns =>
      merge(
        this._on_delete_cols_sort_param$$.pipe(
          map(
            () =>
              <DatatableSortingModel<RowType>>{
                sort: undefined,
                column: undefined,
                server_sort_key: [],
              }
          )
        ),
        this._url_parameters_service.on_change('cols_sort').pipe(
          defaultValue(<Sort>{ active: null, direction: null }, 1000),
          map(sort => {
            if (isArray(sort)) {
              return <Sort>{ active: sort?.[0], direction: sort?.[1] };
            }

            return sort;
          }),
          distinctUntilRealChanged(),
          map(sort => {
            let sorting_model = <DatatableSortingModel<RowType>>{
              sort: undefined,
              column: undefined,
              server_sort_key: [],
            };

            if (isNil(sort?.active) || isNil(sort?.direction)) {
              return sorting_model;
            }

            const related_column_to_sort = flattened_columns.find(column => column.property === sort.active);

            if (related_column_to_sort?.is_sortable === false) {
              return sorting_model;
            }

            sorting_model = <{ sort: Sort; server_sort_key: [string]; column: DatatableColumn<RowType> }>{
              sort: sort,
              column: related_column_to_sort,
              server_sort_key: !isNil(related_column_to_sort?.server_sort_key)
                ? [`${sort.direction === 'desc' ? '-' : ''}${related_column_to_sort?.server_sort_key}`]
                : [],
            };

            return sorting_model;
          })
        )
      )
    ),
    replay()
  );

  /** */
  public sort__active$$ = this.sorting_model$$.pipe(
    map(sorting_model => sorting_model?.sort?.active ?? null),
    replay()
  );

  /** */
  public sort__direction$$ = this.sorting_model$$.pipe(
    map(sorting_model => sorting_model?.sort?.direction ?? null),
    replay()
  );

  public onSortData(sort: Sort): void {
    if (isNil(sort?.active) || isNil(sort?.direction)) {
      return;
    }

    // Update URL params
    if (sort.direction === '' || isNil(sort.direction)) {
      this._on_delete_cols_sort_param$$.next(true);
      this._url_parameters_service.del('cols_sort');
    } else {
      this._url_parameters_service.set('cols_sort', [sort.active, sort.direction]);
    }
  }

  /** */
  protected sort_data_locally(data_rows: RowType[], sorting_model: DatatableSortingModel<RowType>): Observable<RowType[]> {
    const has_sort_enabled = !isNil(sorting_model?.sort);
    const is_sort_server_side = !isNil(sorting_model?.server_sort_key?.[0]);

    if (!has_sort_enabled || is_sort_server_side) {
      return of(data_rows);
    }

    return of(data_rows.map(row => [row, get(row, sorting_model?.sort?.active)])).pipe(
      switchMap((_data_rows: [RowType, any | Observable<any>][]) => {
        const values$$: Observable<[RowType, any]>[] = _data_rows.map(row => {
          const is_data_observable = row[1] instanceof Observable;

          if (!is_data_observable) {
            return of(row);
          }

          return row[1].pipe(
            debounceTime(20),
            take(1),
            timeout(100), // Error of no data in 100ms
            catchError((error: unknown) => of(null)),
            map(val => [row[0], val])
          );
        });

        return combineLatest(values$$);
      }),
      map(sortable_data => {
        sortable_data.sort((srow_a, srow_b) => compareObjectsByType(sorting_model?.column?.compare_by, srow_a[1], srow_b[1]));

        if (sorting_model?.sort?.direction === 'desc') {
          sortable_data.reverse();
        }

        return sortable_data.map(srow => srow[0]);
      })
    );
  }

  // #endregion

  // #region -> (paging management)

  public readonly PAGE_SIZE_OPTIONS = [10, 20, 30, 100];
  protected get_default_page_size() {
    return this.PAGE_SIZE_OPTIONS[2];
  }

  /** */
  private _pagination_total$$ = new BehaviorSubject<number>(0);

  /** */
  public pagination_total$$ = this._pagination_total$$.asObservable();

  /** */
  protected track_pagination_in_url = new SimpleSetterGetter(true);

  /** */
  private _local_paging$$ = new BehaviorSubject<Paging>({ offset: 0, limit: this.get_default_page_size(), total: undefined });

  /** */
  private paging_source$$ = this.track_pagination_in_url.value$$.pipe(
    switchMap(track_paging_in_url => {
      if (track_paging_in_url) {
        return this._url_parameters_service.on_change(this.URL_KEY_DATATABLE_PAGING).pipe(
          defaultValue(['0', this.get_default_page_size()?.toString()], 1000),
          map(([offset, limit]: [string, string]) => {
            const parsed_offset = parseInt(offset ?? '0', 10);
            const parsed_limit = parseInt(limit ?? '0', 10);

            return <Paging>{ offset: parsed_offset, limit: parsed_limit };
          })
        );
      }

      return this._local_paging$$.asObservable();
    }),
    replay()
  );

  /** */
  public paging$$: Observable<Paging> = this.paging_source$$.pipe(
    switchMap(partial_pagination =>
      this.pagination_total$$.pipe(map(pagination_total => _merge({}, partial_pagination, { total: pagination_total })))
    ),
    distinctUntilRealChanged(),
    replay()
  );

  public query_paging$$: Observable<Paging> = this.paging$$.pipe(
    map(
      paging =>
        <Paging>{
          offset: paging?.offset,
          limit: paging?.limit,
        }
    ),
    distinctUntilRealChanged(),
    replay()
  );

  public set paging(paging: Paging) {
    this.internal_update_pagination(paging);
  }

  public onPageChanged(page_event: PageEvent): void {
    const paging = <Paging>{
      total: page_event.length,
      limit: page_event.pageSize,
      offset: page_event.pageIndex * page_event.pageSize,
    };

    this.internal_update_pagination(paging);
  }

  /** */
  private internal_update_pagination(paging: Paging): void {
    const paging_binded_to_url = this.track_pagination_in_url.value;

    this._pagination_total$$.next(paging?.total ?? 0);

    if (paging_binded_to_url) {
      this._url_parameters_service.set(this.URL_KEY_DATATABLE_PAGING, [paging?.offset, paging?.limit]);
    } else {
      this._local_paging$$.next(paging);
    }
  }

  // #endregion

  // #region -> (datatable export)

  protected _is_exporting$$ = new BehaviorSubject<boolean>(false);
  public is_exporting$$ = this._is_exporting$$.asObservable().pipe(distinctUntilRealChanged(), replay());

  /**
   * Observable on the column property name with is splitted.
   *
   * @warning This observable is terminated after called.
   */
  public splitted_by$$: Observable<string | null> = this.grouped_columns$$.pipe(
    take(1),
    map(grouped_columns => grouped_columns?.[0] || null)
  );

  /**
   * Observable of export headers list.
   *
   * @warning This observable is terminated after called.
   */
  public config_headers$$ = combineLatest([this.splitted_by$$, this.flattened_columns$$, this.displayed_columns$$]).pipe(
    map(([splitted_by, all_columns, displayed_columns]) =>
      all_columns
        .filter(column => column.property !== 'select' && (displayed_columns.includes(column.property) || column.property === splitted_by))
        .sort((a, b) => displayed_columns.indexOf(a.property) - displayed_columns.indexOf(b.property))
        .map(column => {
          const i18n_column = this.translate.instant(column.label);
          return {
            name: `${column.property}`,
            label: i18n_column,
            sub_headers:
              column?.export?.subdivided_in?.map(
                subdivider =>
                  ({
                    name: subdivider.property,
                    label: `${i18n_column} (${this.translate.instant(subdivider.label)})`,
                    sub_headers: null,
                    export: subdivider.export,
                  } as ExportHeader)
              ) || null,
            export: column.export,
          } as ExportHeader;
        })
    )
  );

  public export_headers$$ = this.config_headers$$.pipe(
    map(headers => headers.map(header => (header?.sub_headers?.length > 0 ? header.sub_headers : header))),
    map(headers => flatten(headers))
  );

  /**
   * Obseervable of export data.
   *
   * @warning This observable is terminated after called.
   */
  public export_data$$ = forkJoin([this.config_headers$$.pipe(take(1)), this.filtered_data_rows$$.pipe(take(1))]).pipe(
    switchMap(([headers, filtered_data_rows]) => {
      // For each line to export
      const data_lines$$ = filtered_data_rows.map((data_line, data_row_index) => {
        // Rebuild an excel line

        const data_for_each_header$$: Observable<any>[] = headers.map(header => {
          const header_name = header.name;
          const value: any | Observable<any> = get(data_line, header_name, of(null));

          let value_source$$ = (isObservable(value) ? value : of(value)).pipe(take(1));
          value_source$$ = value_source$$.pipe(
            map(v => {
              let calculated = isArray(v) || isPlainObject(v) ? (!isEmpty(v) ? v : null) : v;

              if (!isNil(header?.export?.tranform)) {
                calculated = header.export.tranform(calculated, { _translate: this.translate, row: data_line });
              }

              if (header?.sub_headers?.length > 0) {
                return header.sub_headers.map(subheader => {
                  if (!isNil(subheader?.export?.tranform)) {
                    return subheader.export.tranform(get(calculated, subheader.name), {
                      _translate: this.translate,
                      row: data_line,
                    });
                  }

                  return get(calculated, subheader.name);
                });
              }

              return calculated;
            }),
            take(1)
          );

          return value_source$$;
        });

        return forkJoin(data_for_each_header$$).pipe(
          map(data_for_each_header => flatten(Object.values(data_for_each_header))),
          take(1)
        );
      });

      return forkJoin(data_lines$$).pipe(take(1));
    })
  );

  // Used in the template to get default cell value
  public get(row: DatatableBaseRow, path: string) {
    return get(row, path);
  }
  // #endregion

  /** */
  public isRowOrGroup(value: any, type: 'Row'): RowType;
  public isRowOrGroup(value: any, type: 'Group'): DatatableGroup<RowType>;
  public isRowOrGroup(value: any, type: 'Group' | 'Row') {
    if (type === 'Group' && value instanceof DatatableGroup) {
      return value;
    }

    if (type === 'Row' && !(value instanceof DatatableGroup)) {
      return value;
    }

    return null;
  }
}
