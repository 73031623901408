<div class="bg-dialog" *ngIf="input_params?.near_locations as near_locations">
  <!-- Dialog body -->
  <dialog-body>
    <!-- *** One similar location description *** -->
    <div *ngIf="near_locations.length === 1" class="group">
      <ng-container *ngIf="near_locations[0] as nloc">
        <p>{{ 'VIEWS.MODALS.FORM.We have detected one similar location: ' | translate }}</p>

        <b class="mdi mdi-map-marker">{{ nloc.location.name$$ | async }}</b>

        <span *ngIf="nloc.close_position && !nloc.similar_name">
          {{ 'VIEWS.MODALS.FORM.similar position ' | translate }}
        </span>

        <span *ngIf="nloc.similar_name && !nloc.close_position">
          {{ 'VIEWS.MODALS.FORM.similar name' | translate }}
        </span>

        <span *ngIf="nloc.similar_name && nloc.close_position">
          {{ 'VIEWS.MODALS.FORM.similar position and name ' | translate }}
        </span>
        <p>{{ 'VIEWS.MODALS.FORM.Are you sure you want to create this location?' | translate }}</p>
      </ng-container>
    </div>

    <!-- *** Several similar locations description *** -->
    <div *ngIf="near_locations.length > 1" class="group">
      <p>{{ 'VIEWS.MODALS.FORM.We have detected several similar locations: ' | translate }}</p>

      <ul>
        <li class="mdi mdi-map-marker" style="font-weight: bold" *ngFor="let nloc of near_locations">
          {{ nloc.location.name$$ | async }}

          <span *ngIf="nloc.close_position && !nloc.similar_name">
            {{ 'VIEWS.MODALS.FORM.similar position ' | translate }}
          </span>

          <span *ngIf="nloc.similar_name && !nloc.close_position">
            {{ 'VIEWS.MODALS.FORM.similar name ' | translate }}
          </span>

          <span *ngIf="nloc.similar_name && nloc.close_position">
            {{ 'VIEWS.MODALS.FORM.similar position and name ' | translate }}
          </span>
        </li>
      </ul>
      <p>{{ 'VIEWS.MODALS.FORM.Are you sure you want to create this location?' | translate }}</p>
    </div>

    <ng-template #loading>
      <hex-loading [size]="'medium'"></hex-loading>
    </ng-template>
  </dialog-body>

  <!-- Dialog footer -->
  <div class="dialog-footer">
    <bg2-button [type]="'cancel'" [message]="'ALL.COMMON.Cancel' | translate" (btn_click)="clicked(false)"> </bg2-button>

    <bg2-button [type]="'action'" [message]="'ALL.COMMON.Create anyway' | translate" (btn_click)="clicked(true)"> </bg2-button>
  </div>
</div>
