import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TranslateService } from '@ngx-translate/core';

import { MatSortModule } from '@angular/material/sort';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatTabsModule } from '@angular/material/tabs';
import { MatListModule } from '@angular/material/list';
import { MatChipsModule } from '@angular/material/chips';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';
import { MatBadgeModule } from '@angular/material/badge';
import { MatTableModule } from '@angular/material/table';
import { MatRippleModule } from '@angular/material/core';
import { MatButtonModule } from '@angular/material/button';
import { MatSelectModule } from '@angular/material/select';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatDividerModule } from '@angular/material/divider';
import { MatStepperModule } from '@angular/material/stepper';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatPaginatorIntl, MatPaginatorModule } from '@angular/material/paginator';

import { MtxSelectModule } from '@ng-matero/extensions/select';

import { PaginatorI18n } from './i18n-material-paginator.factory';
import { CDK_DRAG_CONFIG, DragDropModule } from '@angular/cdk/drag-drop';

@NgModule({
  declarations: [],
  imports: [CommonModule],
  exports: [
    MatIconModule,
    MatSortModule,
    MatMenuModule,
    MatCardModule,
    MatTabsModule,
    MatListModule,
    MatInputModule,
    MatRadioModule,
    MatTableModule,
    MatBadgeModule,
    MatChipsModule,
    MatButtonModule,
    MatRippleModule,
    MatSelectModule,
    MatDividerModule,
    MatStepperModule,
    MatSidenavModule,
    MatToolbarModule,
    MatSnackBarModule,
    MatCheckboxModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatPaginatorModule,
    MatDatepickerModule,
    MatSlideToggleModule,
    MatProgressBarModule,
    MatBottomSheetModule,
    MatButtonToggleModule,
    MatAutocompleteModule,

    DragDropModule,

    MtxSelectModule,
  ],
  providers: [
    {
      provide: MatPaginatorIntl,
      deps: [TranslateService],
      useFactory: (translateService: TranslateService) => new PaginatorI18n(translateService),
    },
    {
      provide: CDK_DRAG_CONFIG,
      useValue: {
        zIndex: 10501,
        dragStartThreshold: 0,
        pointerDirectionChangeThreshold: 5,
      },
    },
  ],
})
export class MaterialSharedModule {}
