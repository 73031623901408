<div class="bg2-visit-summary-component">
  <ng-container *ngFor="let item of summary_data$$ | async | keyvalue">
    <ng-container *ngIf="force_type(item?.value); let value">
      <div
        class="visit-summary-aspect"
        [class.--is-evaluated]="value?.is_evaluated"
        (click)="value?.is_evaluated ? when_redirect_to_card.emit(item?.key) : null"
      >
        <div class="visit-summary-aspect__container">
          <div
            [ngSwitch]="item?.key"
            class="visit-summary-aspect__container__picon"
            [class.--is-not-evaluated]="!value?.is_evaluated"
            [matBadge]="value?.total"
            [matBadgeSize]="'large'"
          >
            <img *ngSwitchCase="'broodframes'" src="assets/img/eval/broodframe_unknow.png" />
            <bg2-picon-queen-color *ngSwitchCase="'queen_colors'" [size_w]="'75px'" [queen_color]="'all'"></bg2-picon-queen-color>
            <bg2-picto-hive *ngSwitchCase="'supers'" [size]="'75px'" [supers]="(mean_super_by_hive$$ | async) ?? 0"></bg2-picto-hive>
            <bg2-picon-hive-status *ngSwitchCase="'hive_statuses'" [size_w]="'75px'" [hive_status]="'OK'"></bg2-picon-hive-status>
          </div>

          <div *ngIf="value?.is_evaluated" class="visit-summary-aspect__container__legend">
            <ng-container [ngSwitch]="item?.key">
              <ng-container *ngSwitchCase="'supers'">{{ 'EVENT.VISIT.Supers' | translate }}</ng-container>

              <ng-container *ngSwitchCase="'queen_colors'">{{ 'EVENT.VISIT.Queens' | translate }}</ng-container>

              <ng-container *ngSwitchCase="'hive_statuses'">
                <ng-container *ngLet="evaluated_hives_nucs_total$$ | async; let evaluated_hives_nucs_total">
                  {{ 'DEVICE.ALL.GLOBAL_STATUS.ok' | translate }}/{{
                    (evaluated_hives_nucs_total?.hives ?? 0) + (evaluated_hives_nucs_total?.nucs ?? 0)
                  }}
                </ng-container>
              </ng-container>

              <ng-container *ngSwitchCase="'broodframes'">{{ 'EVENT.VISIT.Brood frames' | translate }}</ng-container>
            </ng-container>
          </div>

          <div *ngIf="!value?.is_evaluated" class="visit-summary-aspect__legend non-legend">{{
            'ALL.COMMON.Not evaluted' | translate
          }}</div>
        </div>
      </div>
    </ng-container>
  </ng-container>
</div>
