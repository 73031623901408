import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';

import { ISchema, Validator } from 'ngx-schema-form';

import {
  BehaviorSubject,
  debounce,
  debounceTime,
  delay,
  filter,
  map,
  merge,
  MonoTypeOperatorFunction,
  Observable,
  of,
  Subject,
  Subscription,
  switchMap,
  take,
  tap,
} from 'rxjs';
import { distinctUntilRealChanged, replay } from '@bg2app/tools/rxjs';

import { cloneDeep, isEqual, isNil, uniqueId } from 'lodash-es';

import { ConsoleLoggerService } from 'app/core/console-logger.service';

import { Dictionary } from 'app/typings/core/interfaces/Dictionary.iface';

/** */
export class SchemaFormBinder {
  /** */
  public set is_form_bind(v: boolean) {
    this._is_form_bind$$.next(v);
  }

  /** */
  private _is_form_bind$$ = new BehaviorSubject(false);

  /** */
  public set should_bind_form(v: boolean) {
    this._should_bind_form$$.next(v);
  }

  /** */
  private _should_bind_form$$ = new BehaviorSubject(true);

  /** */
  public should_bind_form$$ = this._should_bind_form$$.asObservable();

  /** */
  public wait_for_form_bind = <T>(): MonoTypeOperatorFunction<T> =>
    switchMap((model: any) => {
      const current_form_bind = this._should_bind_form$$.getValue();

      if (current_form_bind) {
        return of(model);
      }

      return this._is_form_bind$$.pipe(
        filter(is_form_bind => !is_form_bind), // Wait for false
        tap(() => this._should_bind_form$$.next(true)),
        map(() => model)
      );
    });
}

@Component({
  selector: 'bg2-form-overlay',
  template: `
    <ng-container *ngIf="source_schema$$ | async; let schema">
      <sf-form
        [id]="uniq_id"
        [validators]="validators"
        [schema]="source_schema$$ | async"
        [model]="form_data_update$$ | async"
        (isValid)="form_validity_changed($event)"
        (onChange)="when_form_change($event.value)"
        (onErrorChange)="when_form_error_change($event.value)"
      ></sf-form>
    </ng-container>
  `,
  styles: [
    `
      :host {
        width: 100%;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormOverlayComponent implements OnDestroy {
  // #region -> (component basics)

  public uniq_id = uniqueId('bg2-form-overlay-');

  /** */
  private readonly EDIT_DEBOUNCE_TIME: number = 100;

  /** */
  private readonly LOGGER = new ConsoleLoggerService('FormOverlayComponent', false);

  /** */
  private _subscriptions = {
    /** */
    form_data_sub: null as Subscription,

    /** */
    form_model_init_sub: null as Subscription,
  };

  /** */
  constructor() {}

  /** */
  ngOnDestroy(): void {
    Object.values(this._subscriptions).forEach(sub => sub?.unsubscribe());
  }

  // #endregion

  // #region -> (custom validators)

  @Input()
  public validators: { [key: string]: Validator } = {};

  // #endregion

  // #region -> (external bind management)

  /** */
  @Input()
  public set form_bind(value: boolean) {
    value ? this.bind_form() : this.unbind_form();
  }

  /** */
  @Output()
  public is_form_bind = new EventEmitter<boolean>();

  // #endregion

  // #region -> (incoming source schema)

  /** */
  private _source_schema$ = new BehaviorSubject<ISchema>(null);

  /** */
  public source_schema$$ = this._source_schema$.asObservable();

  /** */
  @Input()
  public set source_schema(source_schema: ISchema) {
    this._source_schema$.next(source_schema);

    // Auto-rebind form each time the schema changes
    if (!isNil(source_schema)) {
      this.bind_form();
    }
  }

  // #endregion

  // #region -> (incoming source model)

  /** */
  private _source_model$ = new BehaviorSubject<Dictionary<any>>(null);

  /** */
  private source_model$$ = this._source_model$.asObservable();

  /** */
  @Input()
  public set source_model(source_model: Dictionary<any>) {
    this._source_model$.next(source_model);
  }

  // #endregion

  // #region -> (outgoing model update)

  /** */
  @Output()
  public when_form_data_update = new EventEmitter<any>();

  // #endregion

  // #region -> (form error management)

  /** */
  public when_form_error_change(error: any) {
    if (isNil(error)) {
      return;
    }

    this.LOGGER.log_error(error);
  }

  // #endregion

  // #region -> (form valid management)

  /** */
  private _previous_validity_value: boolean;

  /** */
  @Output()
  public when_form_valid_update = new EventEmitter<boolean>();

  /** */
  public form_validity_changed(is_valid: boolean): void {
    if (isEqual(this._previous_validity_value, is_valid)) {
      return;
    }

    this._previous_validity_value = is_valid;
    this.when_form_valid_update.emit(is_valid);
  }

  // #endregion

  /** */
  private _force_form_update$ = new Subject<boolean>();

  /** */
  public form_data_update$$: Observable<any> = this.source_model$$.pipe(
    distinctUntilRealChanged(),
    debounce(() => this._wait_form_update_possible$$),
    tap(val => this.LOGGER.debug('📸 Update form from model : ', val))
  );

  /** */
  private bind_form(): void {
    this.LOGGER.debug('bind_form()');
    this._force_form_update$.next(true);

    this._subscriptions.form_model_init_sub?.unsubscribe();
    this._subscriptions.form_model_init_sub = this.form_data_update$$
      .pipe(
        take(1),
        delay(this.EDIT_DEBOUNCE_TIME),
        tap(() => this.LOGGER.debug('🐠 Form model initialized, unlock form change binding')),
        switchMap(() => this.form_data$$)
      )
      .subscribe({
        next: form_data => this.when_form_data_update.emit(form_data),
        error: (error: unknown) => {},
        complete: () => {},
      });

    this.is_form_bind.emit(true);
  }

  /** */
  private unbind_form(): void {
    this._subscriptions.form_model_init_sub?.unsubscribe();
    this._form_data$.next(null);

    this.is_form_bind.emit(false);
  }

  /** */
  private _form_data$ = new BehaviorSubject<Dictionary<any>>(null);

  /** */
  private form_data$$ = this._form_data$.pipe(
    distinctUntilRealChanged(),
    tap(form_data => this.LOGGER.debug('✨ Got new data from [form]: ', form_data)),
    replay()
  );

  private _previous_form_data: Dictionary<any> = null;

  /** */
  public when_form_change(data: Dictionary<any>): void {
    if (isNil(data)) {
      return;
    }

    if (isEqual(data, this._previous_form_data)) {
      return;
    }

    this._previous_form_data = data;
    this._form_data$.next(data);
  }

  /** */
  private _wait_form_update_possible$$ = merge(
    this._force_form_update$,
    this.form_data$$.pipe(
      debounceTime(this.EDIT_DEBOUNCE_TIME * 3),
      map(() => true)
    )
  );
}
