import { Router } from '@angular/router';
import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';

import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';

import { clone, concat, isNil, orderBy, toArray } from 'lodash-es';

import { saveAs as fs_saveAs } from 'file-saver';

import { ISchema } from 'ngx-schema-form';

import { TranslateService } from '@ngx-translate/core';
import { marker as i18n } from '@biesbjerg/ngx-translate-extract-marker';

import { endOfDay, startOfDay, formatDistance, isSameDay } from 'date-fns';

import { Observable, combineLatest, Subscription, BehaviorSubject } from 'rxjs';
import { distinctUntilRealChanged, replay } from '@bg2app/tools/rxjs';
import { map, switchMap, filter, tap, distinctUntilChanged, take } from 'rxjs';

import { DeviceApi } from 'app/core';
import { AppStateService } from 'app/core/app-state.service';

import { DRDevice } from 'app/models';
import { parseDate } from 'app/misc/tools';
import { Dictionary } from 'app/typings/core/interfaces';
import { PositionDataPoint } from 'app/models/data';
import { GetDeviceTimeseriesResponse } from 'app/core/api-swagger/device';
import { ModalArgs, ModalParams } from 'app/widgets/dialogs-modals/abstract-modal.component';
import { PositionRow } from 'app/views/devices/dialogs-and-modals/modals/route-tracer/components/device-movements-table/device-movements-table.component';

import { getDistance } from 'app/misc/tools/geomap';
import { KMLBuilderHelper } from 'app/misc/tools/kml.helpers';

import { AbstractModalComponent } from 'app/widgets/dialogs-modals';

export interface PositionHelper {
  point: PositionDataPoint;
  latitude: number;
  longitude: number;
  accuracy: number;
  gps_fix: boolean;
  usable: boolean;
  color: string;
}

// TODO: mv in in a modes/devices/DeviceMovement.ts
export class DeviceMovement {
  private _positions$$ = new BehaviorSubject<PositionDataPoint[]>([]);
  public positions$$ = this._positions$$.asObservable();

  public get positions(): PositionDataPoint[] {
    return this._positions$$.getValue();
  }

  public addPosition(pos: PositionDataPoint): void {
    pos.date = parseDate(pos.date); // Ensure we have a date object
    const new_positions = concat(this.positions, [pos]);
    this._positions$$.next(new_positions);
  }

  public length$$: Observable<number> = this.positions$$.pipe(
    map(positions => positions.length),
    replay()
  );

  public distance$$: Observable<number> = this.positions$$.pipe(
    map(positions => {
      let dist_totale = 0;
      const not_null_positions = positions.filter(pos => !isNil(pos.gps_lat) && !isNil(pos.gps_lng));
      for (let index = 0; index < not_null_positions.length - 1; index++) {
        const dist_segment = getDistance(
          { latitude: not_null_positions[index].gps_lat, longitude: not_null_positions[index].gps_lng },
          { latitude: not_null_positions[index + 1].gps_lat, longitude: not_null_positions[index + 1].gps_lng }
        );
        dist_totale += dist_segment;
      }
      return dist_totale;
    }),
    replay()
  );

  // Pas utile pour le moment

  // public latitudes$$: Observable<number[]> = this.positions$$.pipe(
  //   map(positions => {
  //     let latitudes = [];
  //     const not_null_positions = positions.filter(pos => !isNil(pos.gps_lat) && !isNil(pos.gps_lng));
  //     for (let index = 0; index < not_null_positions.length - 1; index++) {
  //       latitudes.push(not_null_positions[index].gps_lat);
  //     }
  //     return latitudes;
  //   }),
  //   replay()
  // );

  // public longitudes$$: Observable<number[]> = this.positions$$.pipe(
  //   map(positions => {
  //     let longitudes = [];
  //     const not_null_positions = positions.filter(pos => !isNil(pos.gps_lat) && !isNil(pos.gps_lng));
  //     for (let index = 0; index < not_null_positions.length - 1; index++) {
  //       longitudes.push(not_null_positions[index].gps_lng);
  //     }
  //     return longitudes;
  //   }),
  //   replay()
  // );

  public start_date$$: Observable<Date> = this.positions$$.pipe(
    filter(positions => !isNil(positions) && positions.length > 0),
    map(positions => positions[0].date)
  );

  public end_date$$: Observable<Date> = this.positions$$.pipe(
    filter(positions => !isNil(positions) && positions.length > 0),
    map(positions => positions[positions.length - 1].date)
  );

  public start_day$$: Observable<Date> = this.start_date$$.pipe(map(date => startOfDay(date)));

  public color: string = null;

  public duration$$: Observable<string> = combineLatest([this.start_date$$, this.end_date$$]).pipe(
    map(([date_start, date_end]: [Date, Date]) =>
      formatDistance(date_start, date_end, {
        locale: this.appState.dl.dateFns,
      })
    ),
    replay()
  );

  public is_same_day$$: Observable<boolean> = combineLatest([this.start_date$$, this.end_date$$]).pipe(
    map(([start_date, end_date]) => isSameDay(start_date, end_date)),
    replay()
  );

  public distance_str$$: Observable<string> = this.distance$$.pipe(
    switchMap(distance => {
      if (distance < 2) {
        return this.appState.translate.stream(i18n<string>('VIEWS_WINDOWED.MODALS.ROUTE_TRACER.On-site movement'));
      } else if (distance > 2000) {
        const distance_str = (distance / 1000).toFixed(1);
        return this.appState.translate.stream(i18n<string>('VIEWS_WINDOWED.MODALS.ROUTE_TRACER.About [distance] km'), {
          distance: distance_str,
        });
      } else {
        return this.appState.translate.stream(i18n<string>('VIEWS_WINDOWED.MODALS.ROUTE_TRACER.About [distance] m'), {
          distance,
        });
      }
    }),
    replay()
  );

  constructor(public appState: AppStateService) {}
}

export const groupPositionsByMovements = (positions: PositionDataPoint[], appState: AppStateService): DeviceMovement[] => {
  // Prepare some variables
  const final_data: { [key: string]: DeviceMovement } = {};
  let creation_index = -1;

  // Map on each position
  positions.forEach((pos: PositionDataPoint) => {
    if (pos.tracking_state === 'MOVEMENT') {
      creation_index++;
      final_data[creation_index] = new DeviceMovement(appState);
    }
    if (!isNil(final_data[creation_index])) {
      final_data[creation_index].addPosition(pos);
    }
  });

  return toArray(final_data);
};

export interface RouteTracerModalParams extends ModalParams {
  args: ModalArgs;
  device_imei: string;
  start: string | Date;
  end: string | Date;
}

type RouteViewMode = 'tbl' | 'map';

@AutoUnsubscribe()
@Component({
  selector: 'bg2-route-tracer-modal',
  templateUrl: './route-tracer.modal.html',
  styleUrls: ['./route-tracer.modal.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class RouteTracerModal extends AbstractModalComponent<RouteTracerModalParams> implements OnInit, OnDestroy {
  protected device_movements_sub: Subscription;

  /** */
  protected handle_event_before_unload(event: BeforeUnloadEvent): void {
    return null;
  }

  // #region ↛ (model form)

  private _start$ = this.input_params$$.pipe(
    map(params => params?.start),
    filter(val => !isNil(val)),
    distinctUntilChanged(),
    map(start => parseDate(start)),
    replay()
  );

  private _end$ = this.input_params$$.pipe(
    map(params => params?.end),
    filter(val => !isNil(val)),
    distinctUntilChanged(),
    map(end => parseDate(end)),
    replay()
  );

  private start_end$$: Observable<{ start: Date; end: Date }> = combineLatest([this._start$, this._end$]).pipe(
    map(([start, end]) => ({ start, end }))
  );

  set start(val: string | Date) {
    const params = clone(this.input_params);
    params.start = val;
    this.input_params = params;
  }

  set end(val: string | Date) {
    const params = clone(this.input_params);
    params.end = val;
    this.input_params = params;
  }

  public schema: ISchema = {
    type: 'object',
    properties: {
      dates: {
        label: i18n<string>('VIEWS_WINDOWED.MODALS.ROUTE_TRACER.See route tracing from date to date'),
        type: 'array',
        widget: 'date-range',
        options: {
          default_interval: 'LAST_WEEK',
          url_param_binding: 'rt-',
        },
        items: {
          type: 'string',
        },
      },
    },
  };

  public onFormModelChanged(event: Dictionary<any>): void {
    if (!isNil(event.value.dates) && event.value.dates.length === 2) {
      this.start = event.value.dates[0];
      this.end = event.value.dates[1];
    }
  }

  // #endregion

  // #region -> (fast reload mgmt)

  /** */
  private _on_reload$$ = new BehaviorSubject(false);

  /** */
  public on_reload() {
    this._on_reload$$.next(true);
  }

  // #endregion

  public asDeviceMovement(item: any): DeviceMovement {
    if (item instanceof DeviceMovement) {
      return item;
    }

    return null;
  }

  public get device_imei(): string {
    return this.input_params.device_imei;
  }

  public device_imei$$: Observable<number> = this.input_params$$.pipe(
    map(params => params.device_imei),
    map(imei => parseInt(imei, 10)),
    distinctUntilChanged(),
    replay()
  );

  public device$$: Observable<DRDevice> = this.device_imei$$.pipe(
    switchMap(device_imei => this.deviceApi.requestDevice(device_imei)),
    replay()
  );

  // View modes
  private _view_mode$$ = new BehaviorSubject<RouteViewMode>('map');
  public view_mode$$: Observable<RouteViewMode> = this._view_mode$$.asObservable();

  public set view_mode(mode: RouteViewMode) {
    this._view_mode$$.next(mode);
  }

  constructor(
    public router: Router,
    public appState: AppStateService,
    private deviceApi: DeviceApi,
    private translateService: TranslateService
  ) {
    super();
    this.changeFullscreen(true);
  }

  // #region ↛ (device movement)

  public available_movements$$ = combineLatest([this.device$$, this.start_end$$, this._on_reload$$]).pipe(
    filter(([device, dates]) => !isNil(dates.start) && !isNil(dates.end)),
    switchMap(([device, dates]) => device.requestTimeseries(['position'], dates.start, dates.end, 'raw')),
    map((response: GetDeviceTimeseriesResponse) => response.timeseries.data || []),
    map((positions: PositionDataPoint[]) =>
      positions.map(position => {
        position.date = parseDate(position.date);
        return position;
      })
    ),
    map((positions: PositionDataPoint[]) => groupPositionsByMovements(positions, this.appState) || []),
    switchMap(movements => {
      const movement_with_start_date$$ = movements.map(movement =>
        movement.start_date$$.pipe(map(start_date => ({ start_date, movement })))
      );
      return combineLatest(movement_with_start_date$$).pipe(
        map(_data => orderBy(_data, ['start_date'], ['desc'])),
        map(_data => _data.map(datum => datum.movement))
      );
    }),
    tap(available_movements => (this.available_movements = available_movements)),
    tap(available_movements => {
      if (available_movements.length > 0) {
        this.selected_routes = [available_movements[0]];
      }
    }),
    replay()
  );

  /**
   * An array of all available movements of the device at precise date.
   */
  public available_movements: DeviceMovement[] = [];

  private _selected_routes$$ = new BehaviorSubject<DeviceMovement[]>(null);
  public selected_routes$$ = this._selected_routes$$.asObservable().pipe(distinctUntilRealChanged(), replay());

  public datatable_data$$ = this.selected_routes$$.pipe(
    map(selected_routes => {
      const raw_data_rows: PositionRow[] = [];
      (selected_routes || []).forEach((route: DeviceMovement, index: number) => {
        (route.positions || []).forEach((datum: PositionDataPoint) => {
          const row: PositionRow = {
            movement: route,
            movement_num: index + 1,
            point: datum,
            position: {
              latitude: datum.gps_lat || datum.cell_ids_lat || null,
              longitude: datum.gps_lng || datum.cell_ids_lng || null,
            },
            accuracy: datum.gps_accuracy || datum.cell_ids_accuracy || null,
            gps_fix: !isNil(datum.gps_lng) && !isNil(datum.gps_lng),
            position_type: !isNil(datum.gps_lng) && !isNil(datum.gps_lng) ? 'GPS' : 'CELLIDS',
          };
          raw_data_rows.push(row);
        });
      });
      return raw_data_rows;
    }),
    replay()
  );

  public createKML$$: Observable<string> = combineLatest([this.selected_routes$$]).pipe(
    map(([selected_routes]) => selected_routes.filter(route => !isNil(route))),
    switchMap(not_null_selected_routes => combineLatest(not_null_selected_routes.map(route => route.positions$$))),
    map(position_points => {
      let lat_lng: number[][] = [];
      const trajets: (typeof lat_lng)[] = [];
      position_points.forEach(points_values => {
        points_values.forEach(position =>
          isNil(position.gps_lat)
            ? lat_lng.push([position.cell_ids_lat, position.cell_ids_lng])
            : lat_lng.push([position.gps_lat, position.gps_lng])
        );
        trajets.push(lat_lng);
        lat_lng = [];
      });
      return trajets;
    }),
    map(trajets => {
      const kml_file = new KMLBuilderHelper();
      kml_file.startKml();
      kml_file.createTour('' + new Date().getTime(), 'KML_Tour', 'KML_Tour');
      for (let i_index = 0; i_index < trajets.length; i_index++) {
        const lat_lng = trajets[i_index];
        for (let j_index = 0; j_index < lat_lng.length; j_index++) {
          kml_file.addPlacemark(
            'P' + (i_index + 1) + (j_index + 1),
            this.translateService.instant(i18n('VIEWS.MODALS.ROUTE_TRACER.Point number')) + (j_index + 1),
            lat_lng[j_index][1],
            lat_lng[j_index][0],
            1,
            'relativeToGround',
            this.translateService.instant(i18n('VIEWS.MODALS.ROUTE_TRACER.Location number')) + (j_index + 1),
            null,
            1
          );
        }

        for (let j_index = 1; j_index < lat_lng.length; j_index++) {
          kml_file.createLineString(
            'LS' + (i_index + 1) + (j_index + 1),
            this.translateService.instant(i18n('VIEWS.MODALS.ROUTE_TRACER.Movement number')) + (j_index + 1),
            lat_lng[j_index - 1][1] + ',' + lat_lng[j_index - 1][0] + ',1 ' + lat_lng[j_index][1] + ',' + lat_lng[j_index][0] + ',1',
            1
          );
        }
      }

      return kml_file.writeKML();
    }),
    distinctUntilRealChanged(),
    replay()
  );

  public saveAsKML(KML_file: string) {
    fs_saveAs(
      new Blob([KML_file], { type: 'text/xml;charset=UTF-8' }),
      this.translateService.instant(i18n('VIEWS.MODALS.ROUTE_TRACER.KML Export')) + new Date().getTime() + '.kml'
    );
  }

  public get selected_routes(): DeviceMovement[] {
    return this._selected_routes$$.getValue();
  }

  public set selected_routes(routes: DeviceMovement[]) {
    this._selected_routes$$.next(routes);
  }

  public selectAll(): void {
    this.selected_routes = this.available_movements;
  }

  public unselectAll(): void {
    this.selected_routes = [];
  }

  // #endregion

  // #region ↛ (angular lifecycles)

  get windowRef(): Window {
    return window;
  }

  ngOnInit(): void {
    // Check for required modal params
    if (isNil(this.device_imei)) {
      throw new Error('Missing required parameter: "device_imei"');
    }

    this.device_movements_sub = this.available_movements$$.subscribe();
  }

  // #endregion

  // #region -> (export management)

  private _is_exporting$$ = new BehaviorSubject(false);
  public is_exporting$$ = this._is_exporting$$.asObservable().pipe(
    distinctUntilRealChanged(),
    tap(is_exporting => {
      if (!is_exporting) {
        this._export_from_table$$.next(null);
      }
    }),
    replay()
  );

  private _export_from_table$$ = new BehaviorSubject<'xlsx' | 'csv'>(null);
  public export_from_table$$ = this._export_from_table$$.asObservable().pipe(distinctUntilRealChanged(), replay());

  public table_has_been_exported() {
    this._is_exporting$$.next(false);
  }

  public exportData(type: 'kml' | 'xlsx' | 'csv') {
    this._is_exporting$$.next(true);

    if (type === 'kml') {
      return this.createKML$$.pipe(take(1)).subscribe({
        next: (data: string) => {
          this.saveAsKML(data);
          this._is_exporting$$.next(false);
        },
        error: (error: unknown) => console.error(error),
      });
    }

    // Else use export of datatable
    this._export_from_table$$.next(type);
  }

  // #endregion
}
