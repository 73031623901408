import { Directive, ElementRef, EventEmitter, NgZone, OnDestroy, OnInit, Output } from '@angular/core';

import { ResizeObserver } from '@juggle/resize-observer';
import { distinctUntilRealChanged, replay } from '@bg2app/tools/rxjs';
import { isNil } from 'lodash-es';
import { BehaviorSubject, debounceTime, filter, Subject, Subscription, tap } from 'rxjs';

/** */
export interface ResizedEvent {
  /** */
  new_width: number;

  /** */
  new_height: number;
}

/**
 * @ngModule DirectivesModule
 *
 * @description
 *
 * @see https://github.com/vdolek/angular-resize-event
 */
@Directive({
  selector: '[resized]',
})
export class ResizedDirective implements OnInit, OnDestroy {
  private _observer: ResizeObserver = null;
  private _previous_rect?: DOMRectReadOnly = null;

  @Output()
  public resized: EventEmitter<ResizedEvent> = new EventEmitter<ResizedEvent>();

  // #region -> (directive basics)

  constructor(private readonly element: ElementRef, private readonly _zone: NgZone) {
    this._debouncer_sub = this.debouncer$$.pipe(filter(event => event?.new_height > 0 && event?.new_width > 0)).subscribe({
      next: event_to_emit => {
        this.resized.emit(event_to_emit);
      },
    });
  }

  public ngOnInit(): void {
    // TODO: Manage non-existent ResizeObserver for some browsers
    this._observer = new ResizeObserver((entries: ResizeObserverEntry[]) => this._zone.run(() => this.observe(entries)));
    this._observer.observe(this.element.nativeElement);
  }

  public ngOnDestroy(): void {
    this._observer?.disconnect();
    this._debouncer_sub?.unsubscribe();
  }

  // #endregion

  // #region -> (debouncer)

  /** */
  private _debouncer_sub: Subscription = null;

  /** */
  private _debouncer$$: Subject<ResizedEvent> = new Subject<ResizedEvent>();

  /** */
  private debouncer$$ = this._debouncer$$.pipe(distinctUntilRealChanged(), replay());

  // #endregion

  private observe(entries: ResizeObserverEntry[]): void {
    const resize_observer_entry = entries[0];

    const resized_event = <ResizedEvent>{
      new_width: Math.floor(resize_observer_entry.contentRect.width),
      new_height: Math.floor(resize_observer_entry.contentRect.height),
      // newRect: resize_observer_entry.contentRect,
      // oldRect: this._previous_rect,
      // isFirst: isNil(this._previous_rect),
    };

    // this._previous_rect = resize_observer_entry.contentRect;
    this._debouncer$$.next(resized_event);
  }
}
