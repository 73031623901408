import { ChangeDetectionStrategy, Component } from '@angular/core';

import { isNil, isNaN } from 'lodash-es';

import { EfStringWidget as StringWidget } from '../string/string.widget';

@Component({
  selector: 'ef-number-widget',
  templateUrl: './number.widget.html',
  styleUrls: ['./number.widget.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EfNumberWidget extends StringWidget {
  public __isNil = isNil;

  public onChange(val: number): void {
    val = isNaN(val) ? null : val;

    if (!isNil(val)) {
      if (!isNil(this.schema.minimum)) {
        val = Math.max(val, this.schema.minimum);
      }

      if (!isNil(this.schema.maximum)) {
        val = Math.min(val, this.schema.maximum);
      }
    }

    this.value = val;
  }
}
