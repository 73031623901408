<div class="bg2-zoho-task-summary-component">
  <div *ngLet="task$$ | async; let task" class="task-container">
    <div class="task-container__description-container">
      <span class="task-container__description-container__subject" [popper]="task?.subject$$ | async">
        {{ task?.subject$$ | async | truncate : 50 : '...' }}
      </span>

      <div class="task-container__description-container__subinfo-container">
        <span class="label">
          {{ 'VIEWS.ZOHO.SHARED.CRM.TASK_SUMMARY.Due date' | translate | colon }}
        </span>

        <span class="value">
          {{ task?.due_date$$ | async | formatDate : 'short' }}
        </span>
      </div>

      <div class="task-container__description-container__tags-container">
        <div class="tag">
          <span class="label">
            {{ 'VIEWS.ZOHO.SHARED.CRM.TASK_SUMMARY.Status' | translate | colon }}
          </span>

          <span class="value">
            {{ task?.status$$ | async }}
          </span>
        </div>

        <div class="tag">
          <span class="label">
            {{ 'VIEWS.ZOHO.SHARED.CRM.TASK_SUMMARY.Priority' | translate | colon }}
          </span>

          <span class="value">
            {{ task?.priority$$ | async }}
          </span>
        </div>
      </div>

      <div class="link-to-crm">
        <span class="mdi mdi-web"></span>

        <a [href]="task?.url$$ | async" target="_blank" (click)="$event.stopImmediatePropagation()">
          {{ 'VIEWS.ZOHO.SHARED.CRM.Click to view/edit on CRM' | translate }}
        </a>
      </div>
    </div>
  </div>
</div>
