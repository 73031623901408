export enum TooltipPlacement {
  TOP = 'top',
  BOTTOM = 'bottom',
  LEFT = 'left',
  RIGHT = 'right',
  TOPSTART = 'top-start',
  BOTTOMSTART = 'bottom-start',
  LEFTSTART = 'left-start',
  RIGHTSTART = 'right-start',
  TOPEND = 'top-end',
  BOTTOMEND = 'bottom-end',
  LEFTEND = 'left-end',
  RIGHTEND = 'right-end',
  AUTO = 'auto',
  AUTOSTART = 'auto-start',
  AUTOEND = 'auto-end',
}
