import { Component, HostListener, Input } from '@angular/core';

import { replay } from '@bg2app/tools/rxjs';
import { take, BehaviorSubject, Observable, Subject } from 'rxjs';

import { DialogsService } from './dialogs.service';

export interface AbstractDialogParams {
  header?: string;
}

@Component({
  selector: 'bg2-abstract-dialog',
  template: '',
})
export class AbstractDialogComponent<I extends AbstractDialogParams, O> {
  /** */
  @HostListener('document:keydown.escape', ['$event'])
  public onEscapeKeyDown(event: KeyboardEvent) {
    setTimeout(() => {
      event.preventDefault();
      event.stopPropagation();
      event.stopImmediatePropagation();

      return false;
    }, 1);
  }

  // #region -> (incoming parameters)

  private _input_params$$ = new BehaviorSubject<I>(null);
  public input_params$$ = this._input_params$$.asObservable().pipe(replay());

  @Input()
  public set input_params(params: I) {
    this._input_params$$.next(params);
  }

  public get input_params(): I {
    return this._input_params$$.getValue();
  }

  // #endregion

  // #region -> (resultant data)

  protected complete(with_result: O): void {
    this._result.next(with_result);
  }

  private _result = new Subject<O>();
  public readonly results$$ = this._result.pipe(take(1));

  // #endregion

  public fullscreen = false;

  public changeFullscreen(enter_fullscreen: boolean): void {
    if (enter_fullscreen) {
      this.fullscreen = true;
    } else {
      this.fullscreen = false;
    }
  }

  public static aopen<InputType = AbstractDialogParams, OutputType = any>(
    dialogs_service: DialogsService,
    params: InputType
  ): Observable<OutputType> {
    return dialogs_service.open<InputType, OutputType>(this, params);
  }
}
