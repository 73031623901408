<fieldset
    *ngFor="let fieldset of formProperty.schema.fieldsets"
    [class.explicit]="!transparent"
    [class.with-step]="!__isNil(schema.step)">

  <form-element-header [schema]='schema' [showLabel]="false"></form-element-header>

  <div class="row ofields">
    <div class="col s12">
      <sf-form-element [formProperty]="formProperty.getProperty('user_id')"></sf-form-element>
    </div>

    <ul class="col s12 scopes">
      <li *ngFor="let scope of (possible_scopes$$ | async)">
        <mat-checkbox
          [color]="'primary'"
          [id]="id + '__' + scope.name"
          [value]="scope.name"
          [ngModel]="scopes$$[scope.name] | async"
          (ngModelChange)="onScopeChange(scope.name, $event)">
          <i class="mdi {{scopes_icons[scope.name]}} mdi-24px"></i>
          {{ scope.description | translate }}
        </mat-checkbox>

        <ul *ngIf="scopes$$[scope.name] | async" class="subscopes">
          <li *ngFor="let subscope_name of scope.imply">
            <mat-checkbox
              [id]="id + '__' + scope.name + '_' + subscope_name"
              [disabled]="true"
              [indeterminate]="!(scopes$$[subscope_name] | async)"
              [checked]="(scopes$$[subscope_name] | async)"
              [color]="'primary'">
              <i class="mdi {{scopes_icons[subscope_name]}} mdi-18px"></i>
              {{ all_scopes[subscope_name].description | translate }}
            </mat-checkbox>
          </li>
        </ul>

      </li>
    </ul>

  </div>
</fieldset>