import { DateTime } from 'luxon';
import { chunk, flatten } from 'lodash-es';

import { zonedTimeToUtc } from 'date-fns-tz';
import { eachDayOfInterval, endOfDay, isWithinInterval, subMinutes } from 'date-fns/esm';

import { DataPoint } from 'app/models/data';
import { group_data_by_day } from '../group-data-by-day/group-data-by-day';

/** */
export const group_data_every_x_day = <T extends { date: Date }>(
  objects: T[],
  every_x_day: number,
  date_range: { from: Date; to: Date }
) => {
  const each_days = eachDayOfInterval({ start: date_range?.from, end: date_range?.to }, { step: every_x_day });

  return each_days.map((date, index, self) => {
    const next_date = self[index + 1] ?? endOfDay(date);

    const all = objects.filter(obj => isWithinInterval(obj.date, { start: date, end: subMinutes(next_date, 1) }));
    return { date_of_day: date, values: all };
  });
};

/** */
export const group_data_every_x_day_luxon = <T extends DataPoint>(
  objects: T[],
  every_x_day: number
): { date_of_day: DateTime; values: T[] }[] => {
  const data_by_day = group_data_by_day(objects);

  const chunks = chunk(data_by_day, every_x_day).map(chunk_value => {
    const values = chunk_value.map(v => v.values);
    const flatten_values = flatten(values);

    const initial_date = DateTime.fromJSDate(zonedTimeToUtc(chunk_value?.[0]?.date_of_day, flatten_values[0]?.timezone)).setZone(
      flatten_values[0]?.timezone
    );

    return { date_of_day: initial_date, values: flatten_values };
  });

  return chunks;
};
