import { DateTime } from 'luxon';
import { isBefore } from 'date-fns/esm';

/**
 * @description
 *
 *
 * @param dates
 *
 * @returns
 */
export const compute_difference_in_days = (dates: [Date, Date]): number => {
  if (isBefore(dates[1], dates[0])) {
    dates = dates.reverse() as [Date, Date];
  }

  const difference_in_days = (dates[1].getTime() - dates[0].getTime()) / (1000 * 3600 * 24);
  return Math.round(difference_in_days);
};

export const compute_difference_in_days_luxon = (range: { start: DateTime; end: DateTime }): number => {
  if (range.end.toMillis() <= range.start.toMillis()) {
    range = { start: range.end, end: range.start };
  }

  const difference_in_days = (range.end.toMillis() - range.start.toMillis()) / (1000 * 3600 * 24);
  return Math.round(difference_in_days);
};
