import { isNil } from 'lodash-es';

import {
  DeviceStatus868Str,
  DeviceStatusBatStr,
  DeviceStatusGPRSStr,
  DeviceStatusGPSStr,
  DeviceGlobalStateStr,
  DeviceStatus,
  DeviceStatusBat,
  DeviceStatusGPRS,
  DeviceStatusGPS,
  DeviceStatus868,
} from './DRDevice';

// #region -> (device state computation)

/**
 * Computes 868 state from a device status.
 * @note Best is -50, worst is -120.
 *
 * @param status
 *
 * @return Returns the computed state.
 */
export const compute_868_state = (status: DeviceStatus): DeviceStatus868Str => {
  if (isNil(status)) {
    return null;
  }

  if (isNil(status.value) && isNil(status.outdated)) {
    return null;
  }

  if (isNil(status.value)) {
    return '868_ko';
  }

  if (status.value > -80) {
    return '868_excellent';
  }

  if (status.value > -90 && status.value <= -80) {
    return '868_good';
  }

  if (status.value > -100 && status.value <= -90) {
    return '868_low';
  }

  if (status.value <= -100) {
    return '868_very_low';
  }
};

/**
 * Computes battery state from a device status.
 * @param status
 *
 * @return Returns the computed state.
 */
export const compute_bat_state = (status: DeviceStatus): DeviceStatusBatStr => {
  if (isNil(status?.value)) {
    return 'bat_unknown';
  }

  if (status.value >= 87.5) {
    return 'bat_full';
  }

  if (status.value >= 62.5 && status.value < 87.5) {
    return 'bat_half_full';
  }

  if (status.value >= 37.5 && status.value < 62.5) {
    return 'bat_half';
  }

  if (status.value >= 12.5 && status.value < 37.5) {
    return 'bat_half_empty';
  }

  if (status.value < 12.5) {
    return 'bat_empty';
  }
};

/**
 * Computes GPS state from a device status.
 * @param status
 *
 * @return Returns the computed state.
 */
export const compute_gps_state = (status: DeviceStatus): DeviceStatusGPSStr => {
  if (isNil(status)) {
    return null;
  }

  if (isNil(status.value) || status.value === false) {
    return 'gps_ko';
  }

  return 'gps_ok';
};

/**
 * Computes GPRS state from a device status.
 * @param status
 *
 * @return Returns the computed state.
 */
export const compute_gprs_state = (status: DeviceStatus): DeviceStatusGPRSStr => {
  if (isNil(status)) {
    return null;
  }

  if (isNil(status.value) && isNil(status.outdated)) {
    return null;
  }

  if (isNil(status.value)) {
    return 'gprs_ko';
  }

  if (status.value > -70) {
    return 'gprs_excellent';
  }

  if (status.value > -80 && status.value <= -70) {
    return 'gprs_good';
  }

  if (status.value > -90 && status.value <= -80) {
    return 'gprs_low';
  }

  if (status.value <= -90) {
    return 'gprs_very_low';
  }
};

// #endregion

// #region -> (device observation state computation)

/**
 * @description
 *
 * Computes the observation state for the 868 status.
 *
 * @param status
 *
 * @returns
 */
export const compute_868_observation_state = (status: DeviceStatus868): DeviceGlobalStateStr => {
  if (isNil(status)) {
    return null;
  }

  if (status?.outdated) {
    return 'have_issue';
  }

  const state: DeviceStatus868Str = status?.state || null;

  if (isNil(state)) {
    return null;
  }

  switch (true) {
    case state === '868_excellent' || state === '868_good': {
      return 'ok';
    }

    case state === '868_low' || state === '868_very_low': {
      return 'need_check';
    }

    case state === '868_ko': {
      return 'have_issue';
    }
  }
};

/**
 * @description
 *
 * Computes the observation state for the battery status.
 *
 * @param status
 *
 * @returns
 */
export const compute_bat_observation_state = (status: DeviceStatusBat): DeviceGlobalStateStr => {
  if (isNil(status)) {
    return null;
  }

  if (status?.outdated) {
    return 'have_issue';
  }

  const state: DeviceStatusBatStr = status?.state || null;

  if (isNil(state)) {
    return null;
  }

  switch (true) {
    case state === 'bat_full' || state === 'bat_half_full' || state === 'bat_half': {
      return 'ok';
    }

    case state === 'bat_half_empty': {
      return 'need_check';
    }

    case state === 'bat_empty' || state === 'bat_unknown': {
      return 'have_issue';
    }
  }
};

/**
 * @description
 *
 * Computes the observation state for the GPS status.
 *
 * @param status
 *
 * @returns
 */
export const compute_gps_observation_state = (status: DeviceStatusGPS): DeviceGlobalStateStr => {
  if (isNil(status)) {
    return null;
  }

  const state: DeviceStatusGPSStr = status?.state || null;

  if (isNil(state)) {
    return null;
  }

  switch (true) {
    case state === 'gps_ok': {
      return 'ok';
    }

    case state === 'gps_ko': {
      return 'have_issue';
    }
  }
};

/**
 * @description
 *
 * Computes the observation state for the GPRS status.
 *
 * @param status
 *
 * @returns
 */
export const compute_gprs_observation_state = (status: DeviceStatusGPRS): DeviceGlobalStateStr => {
  if (isNil(status)) {
    return null;
  }

  if (status?.outdated) {
    return 'have_issue';
  }

  const state: DeviceStatusGPRSStr = status?.state || null;

  if (isNil(state)) {
    return null;
  }

  switch (true) {
    case state === 'gprs_excellent' || state === 'gprs_good' || state === 'gprs_low': {
      return 'ok';
    }

    case state === 'gprs_very_low': {
      return 'need_check';
    }

    case state === 'gprs_ko': {
      return 'have_issue';
    }
  }
};

// #endregion
