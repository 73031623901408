import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'bg2-map-popup',
  templateUrl: './map-popup.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class Bg2MapPopupComponent {
  /** */
  @Input()
  public self_popup_ref: L.Popup = null;
}
