import { Observable } from 'rxjs';
import { switchMap, map } from 'rxjs';

import { Event, I18NParams } from './Event';


export class Feeding extends Event {

  protected getI18nParams(translate_service: any, entities: any): Observable<I18NParams> {
    // Get translation for 'feeding_type'
    return super.getI18nParams(translate_service, entities).pipe(
      switchMap((i18nParams: I18NParams) => {
        const desc_obs: Observable<I18NParams> = translate_service.stream(i18nParams.data.feeding_type).pipe(
          map(feeding_type => {
            i18nParams.data.feeding_type = feeding_type;
            return i18nParams;
          })
        );
        return desc_obs;
      }
    ));
  }
}
