import { Injectable } from '@angular/core';
import { TranslateParser } from '@ngx-translate/core';

import { isNil } from 'lodash-es';

@Injectable()
export class BgTranslateParser extends TranslateParser {
  templateMatcher = /{{\s?([^{}]*)\s?}}/g;
  conditionalMatcher = /([^{}\s]*)\?([^{}]*)/g;
  // templateMatcher: RegExp = /{{\s?([^{}\s]*)\s?}}/g;

  public interpolate(expr: string | ((v: any) => any), params?: any): string {
    let result: string;

    if (typeof expr === 'string') {
      result = this.interpolateString(expr, params);
    } else if (typeof expr === 'function') {
      result = this.interpolateFunction(expr, params);
    } else {
      // this should not happen, but an unrelated TranslateService test depends on it
      result = expr as string;
    }

    return result;
  }

  getValue(target: any, key: string): any {
    const keys = key.split('.');
    key = '';
    do {
      key += keys.shift();
      if (!isNil(target) && !isNil(target[key]) && (typeof target[key] === 'object' || !keys.length)) {
        target = target[key];
        key = '';
      } else if (!keys.length) {
        target = undefined;
      } else {
        key += '.';
      }
    } while (keys.length);

    return target;
  }

  private interpolateFunction(fn: (v: any) => any, params?: any): any {
    return fn(params);
  }

  private interpolateString(expr: string, params?: any): any {
    if (!params) {
      return expr;
    }

    return expr.replace(this.templateMatcher, (substring: string, path: string) => {
      const condi = this.conditionalMatcher.exec(path);
      if (condi) {
        const new_path = condi[1];
        const patern = condi[2];
        const r = this.getValue(params, new_path);
        // console.log(condi, path, patern, params, r);
        return !isNil(r) ? patern.replace('$VAL', r) : '';
      } else {
        const r = this.getValue(params, path);
        return !isNil(r) ? r : substring;
      }
    });
  }

}
