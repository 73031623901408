<div class="bg2-pull-down-filters-bottom-sheet-component">
  <div class="content-container">
    <div class="content-container__filters-title">
      <span>
        {{
          'VIEWS.LOCATIONS.COMPONENTS.PAGE_LOCATIONS.MAP_VIEW.FILTER_ANTENNA_SITES_SHEET.Display mobile network antenna sites' | translate
        }}
      </span>
    </div>

    <div class="content-container__filters-form">
      <bg2-form-overlay
        [source_schema]="schema"
        [source_model]="source_model$$ | async"
        (when_form_data_update)="save_modified_data($event)"
        (when_form_valid_update)="is_form_is_valid = $event"
      ></bg2-form-overlay>
    </div>
  </div>

  <mat-divider></mat-divider>

  <div class="footer-container">
    <span class="spacer"></span>
    <bg2-button
      [type]="'cancel'"
      [icon]="'mdi-close-box'"
      [message]="'ALL.COMMON.Close' | translate"
      [disabled]="(is_form_is_valid$$ | async) === false"
      (btn_click)="close_sheet()"
    ></bg2-button>
  </div>
</div>
