import { Component, ChangeDetectionStrategy } from '@angular/core';

import { Observable, combineLatest, BehaviorSubject, concat, of, forkJoin } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, map, switchMap, take, tap } from 'rxjs/operators';

import { isNil, cloneDeep } from 'lodash-es';

import { marker as i18n } from '@biesbjerg/ngx-translate-extract-marker';

import { DRDevice } from 'app/models';

import { AbstractDialogComponent } from 'app/widgets/dialogs-modals/abstract-dialog.component';
import { distinctUntilRealChanged, replay } from '@bg2app/tools/rxjs';
import { Beeguard2Api, DeviceApi } from 'app/core';
import { ConsoleLoggerService } from 'app/core/console-logger.service';
import { ISchema } from 'ngx-schema-form';
import { DialogsService } from 'app/widgets/dialogs-modals';
import { BulkDevicesRegister } from 'app/models/events/device_setup_events';
import { DevicesDialogParams } from '../../devices-dialog-params';

export interface DevicesRegisterDialogParams extends DevicesDialogParams {
  ignore_set_exploitation: boolean;
}

@Component({
  selector: 'bg2-devices-register-dialog',
  templateUrl: './devices-register.dialog.html',
  styleUrls: ['./devices-register.dialog.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DevicesRegisterDialogComponent extends AbstractDialogComponent<DevicesRegisterDialogParams, any> {
  DEFAULT_SCHEMA: ISchema = {
    type: 'object',
    required: ["devices"],
    properties: {
      devices: {
        label: i18n('VIEWS.DEVICES.OTA-DIALOG.Selected devices'),
        type: 'array',
        widget: 'bg2device',
        options: {
          readonly: false,
          multiple: true,
          show_all_devices: true,
          event_date_path: 'expl/date',
          previous_hive_path: 'expl/previous_hives',
          previous_apiary_path: 'expl/previous_apiaries',
          previous_warehouse_path: 'expl/previous_warehouses'
        },
        minItems: 1,
        default: [] as number[],
        items: {
          type: 'object',
          properties: {
            imei: {
              type: "integer"
            },
          }
        }
      },
      config: {
        type: "array",
        default: ["owner", "expl"],
        uniqueItems: false,
        widget: "checklist",
        options: {
            "display": "button",
        },
        minItems: 1,
        items: {
            type: "string",
            oneOf: [
                {
                    enum: ["owner"],
                    label: i18n("VIEWS.DEVICES.REGISTER-DIALOG.Change owner"),
                    // image: "img/hive.png",
                    // image_styles: {"max-height": "50px"},
                },
                {
                    enum: ["expl"],
                    label: i18n("VIEWS.DEVICES.REGISTER-DIALOG.Change exploitation"),
                    // "image": "img/nuc_hive.png",
                    // "image_styles": {"max-height": "50px"},
                },
            ],
        },
      },
      owner: {
        label: i18n('VIEWS.MODALS.UPDATE_ENTITY_ACL.User'),
        type: 'number',
        widget: 'bg2user-select',
        visibleIf: {
          oneOf: [
            {
              "config": ['owner']
            },
            {
              "config": [['owner', 'expl']]
            },
            // {
            //   "config":
            //   [
            //     "$EXP$ target.value = ['owner']"
            //   ]
            // }
          ] // TODO XXX FIXME That this is a hack to list all the cases (['owner', 'expl']) the $EXP$ should be enought but did not work
        },
      },
      expl: {
        type: "object",
        options: {
          beta: false,
          event_date_path: "date"
        },
        required: [
          "date",
          "warehouse",
          "contract"
        ],
        visibleIf: {
          oneOf: [
            {
              "config": ['expl']
            },
            {
              "config": [['owner', 'expl']]
            },
            // {
            //   "config":
            //   [
            //     "$EXP$ target.value.includes('expl')"
            //   ]
            // }
          ] // TODO XXX FIXME That this is a hack to list all the cases (['owner', 'expl']) the $EXP$ should be enought but did not work
        },
        properties: {
          date: {
            "label": "EVENT.ALL.COMMON.Date of the intervention",
            "type": "string",
            "widget": {
              "id": "date-time"
            },
            "options": {
              "pickerType": "both"
            },
            "isRequired": true
          },
          previous_warehouses: {
            type: "array",
            items: {
              type: "number"
            },
            "label": "EVENT.DEVICE.Previous devices warehouses",
            "widget": "bg2entity",
            "etype": "warehouse",
            options: {
              "mutiple": true,
              "readonly": true,
              "event_date_path": "date"
            },
            "isRequired": false
          },
          previous_hives: {
            type: "array",
            items: {
              type: "number"
            },
            "label": "EVENT.DEVICE.Previous hives",
            "widget": "bg2entity",
            "etype": "hive",
            options: {
              "mutiple": true,
              "readonly": true,
              "event_date_path": "date"
            },
            "isRequired": false
          },
          previous_apiaries: {
            type: "array",
            items: {
              type: "number"
            },
            "label": "EVENT.DEVICE.Previous apiary",
            "widget": "bg2entity",
            "etype": "apiary",
            options: {
              "mutiple": true,
              "readonly": true,
              "event_date_path": "date"
            },
            "isRequired": false
          },
          warehouse: {
            "type": "number",
            "label": "EVENT.DEVICE.Device warehouse",
            "widget": "bg2entity",
            "etype": "warehouse",
            "options": {
              "event_date_path": "date"
            },
            "isRequired": true
          },
          contract: {
            type: "string",
            label: "EVENT.DEVICE.Contract type",
            default: "purchase",
            widget: "radio",
            oneOf: [
              {
                "enum": [
                  "purchase"
                ],
                "label": "EVENT.DEVICE.purchase"
              },
              {
                "enum": [
                  "rent"
                ],
                "label": "EVENT.DEVICE.rent"
              },
              {
                "enum": [
                  "loan"
                ],
                "label": "EVENT.DEVICE.loan"
              }
            ],
          },
          comment: {
            "label": "ALL.COMMON.Comment",
            "options": {
              "optional": true
            },
            "type": "string",
            "widget": {
              "id": "textarea"
            }
          }
        }
      }
    }
  }

  protected _logger = new ConsoleLoggerService(this.constructor.name, false);

  public devices$$: Observable<DRDevice[]> = this.input_params$$.pipe(
    map(params => params.devices),
    replay()
  );

  public ignore_set_exploitation$$ = this.input_params$$.pipe(
    map(params => params.ignore_set_exploitation || false),
    replay()
  );

  /** */
  private _form_model$$ = new BehaviorSubject<{
    devices: { imei: number }[];
    config: any;
    owner: any;
    expl: {
      contract: string;
      date: string;
      previous_apiaries: any[];
      previous_hives: any[];
      comment: string;
      previous_warehouses: number[];
      warehouse: number;
    };
  }>(<any>{});

  /** */
  public form_model$$ = this._form_model$$.pipe(
    debounceTime(100),
    distinctUntilRealChanged(),
    tap(data => this._logger.debug('new data', data)),
    replay(),
  );

  public schema$$ = combineLatest({
    devices: this.devices$$,
    ignore_set_exploitation: this.ignore_set_exploitation$$
  }).pipe(
    map(({devices, ignore_set_exploitation}) => {
      const schema = cloneDeep(this.DEFAULT_SCHEMA);
      // Devices default values
      schema.properties.devices.default = devices.map(device => device.imei);
      if (devices.length == 1) {
        schema.properties.devices.options.readonly = true
      }

      // Otions
      if (ignore_set_exploitation) {
        schema.properties.config.default = ["owner"]
        schema.properties.config.widget = "hidden"
      }
  
      return schema as ISchema;
    }),
    replay()
  );

  private _form_valid$$ = new BehaviorSubject<boolean>(false);
  public form_valid$$ = this._form_valid$$.pipe(
    distinctUntilChanged(),
    replay()
  );

  private _submit_progress_total$$ = new BehaviorSubject<number>(null);
  public submit_progress_total$$ = this._submit_progress_total$$.pipe(
    distinctUntilChanged(),
    replay()
  );

  private _submit_progress_current$$ = new BehaviorSubject<number>(null);
  public submit_progress_current$$ = this._submit_progress_current$$.pipe(
    distinctUntilChanged(),
    replay()
  );

  public submit_progress_percent$$ = combineLatest([this.submit_progress_total$$, this.submit_progress_current$$]).pipe(
    map(([total, current]) => !total ? 0 : current / total),
    replay()
  );

  public setFormValid(val: any): void {
    this._form_valid$$.next(val);
  }

  constructor(
    private bg2Api: Beeguard2Api,
    private device_api: DeviceApi,
    private _dialogs: DialogsService
  ) {
    super();
  }

  public onFormModelChanged(event: any) {
    this._form_model$$.next(event.value);
  }

  public logErrors(errors: any) {
    if (errors) {
      this._logger.log_error(errors);
    }
  }

  private create_bulk_register_devices(
    date: Date,
    warehouse_id: number,
    previous_warehouses_ids: number[],
    previous_apiaries_ids: number[],
    previous_hives_ids: number[],
    device_imeis: number[],
    contract: string,
    comment: string
  ) {
    return this.devices$$.pipe(
      take(1),
      tap(devices => {
        devices.forEach(device => {
          const wh = device.getWarehouseIdAtDate(date);

          if (wh === warehouse_id) {
            throw Error(`${device.name} already associated with selected exploitation (at date ${date.toISOString()})`);
          }
        });
      }),
      switchMap(devices => {
        const new_event = new BulkDevicesRegister(this.bg2Api)
        new_event.type = 'bulk_devices_register';
        new_event.date = date;
        const selected = new Set(device_imeis);
        const imeis_and_types = devices.filter(dev => selected.has(dev.imei)).map(dev => ({
          imei: dev.imei,
          dtype: dev.type
        }));
        new_event.data = {
          devices: imeis_and_types,
          contract: contract,
          comment: comment
        }
        new_event.setOperand('warehouse', warehouse_id);
        if (previous_warehouses_ids?.length > 0) {
          new_event.setOperand('previous_warehouses', previous_warehouses_ids);
        }
        if (previous_apiaries_ids?.length > 0) {
          new_event.setOperand('previous_apiaries', previous_apiaries_ids);
        }
        if (previous_hives_ids?.length > 0) {
          new_event.setOperand('previous_hives', previous_hives_ids);
        }
        return new_event.save()
      })
    )
  }

  public submit(): void {
    combineLatest({ model: this.form_model$$, devices: this.devices$$ })
      .pipe(
      take(1),
        switchMap(({ model, devices }) => {
        const actions: Observable<any>[] = [];

          console.log({ model, devices });

        const conf = model.config;
          const selected_imeis = model.devices?.map(dev => dev.imei);

        if (conf.includes('owner') && model.owner) {
          const data = {
              dids: selected_imeis,
            owner: model.owner,
            };

            actions.push(this.device_api.putBulkOwner(data));
          }

        if (conf.includes('expl')) {
          const date = model.expl.date;
          const warehouse_id = model.expl.warehouse;
          const previous_warehouses_ids = model.expl.previous_warehouses;
          const previous_apiaries_ids = model.expl.previous_apiaries;
          const previous_hives_ids = model.expl.previous_hives;
          const contract = model.expl.contract;
          const comment = model.expl.comment;
            actions.push(
              this.create_bulk_register_devices(
                new Date(date),
            warehouse_id,
            previous_warehouses_ids,
            previous_apiaries_ids,
            previous_hives_ids,
                selected_imeis,
            contract,
            comment
              )
            );
        }

          if (selected_imeis?.length > 0) {
            const selected_devices = devices.filter(device => {
              const device_imei = device?.imei;

              return selected_imeis.includes(device_imei);
            });

            const gps_devices = selected_devices.filter(device => device.type === 'GPS');
            const rg_devices = selected_devices.filter(device => device.type === 'RG');

            console.log({ gps_devices, rg_devices });

            (gps_devices ?? []).forEach(device => {
              const request$ = device.setSimplifiedConfiguration(<any>{
                sconf: {
                  mode: 'tracking_measures_sensors',
                  com: { conf: 'one_by_day', hour_utc_one: 20 },
                },
              });

              console.log('push config update for ', device.imei);
              actions.push(request$);
            });

            (rg_devices ?? []).forEach(device => {
              const request$ = device.setSimplifiedConfiguration(<any>{
                sconf: {
                  mode: 'measures_sensors',
                  com: { conf: 'one_by_day', hour_utc_one: 12 },
                },
              });

              console.log('push config update for ', device.imei);
              actions.push(request$);
            });
          }

        if (actions.length == 0) {
          throw Error("Nothing to do !")
        }
        return concat(...actions);
      }),
      catchError((err: unknown) => {
        const error_model = <any>err;

        this._logger.error(err, error_model.error);
        return this._dialogs.alert('Error: ' + (error_model.error?.message || error_model.message || err)).pipe(
          map(() => null)
        );
      }),
    ).subscribe({
      next: res => {
        if (!isNil(res)) {
          this.close(true);
        }
      },
      error: () => {},
      complete: () => {},
    })
  }

  public close(value: boolean): void {
    this.complete(value);
  }

}
