<div class="bg2-device-calibration-temperature-chart-component">
  <!-- <b>{{ is_calib ? 'After' : 'Before' }}</b> -->
  <div [id]="unique_name"></div>

  <div class="legend">
    <div>
      <div style="background-color: red"></div>
      <span>
        {{
          'VIEWS.DEVICES.DIALOGS_AND_MODALS.MODALS.DEVICE_CALIBRATION.DEVICE_CALIBRATION_TEMPERATURE_MODAL.DEVICE_CALIBRATION_TEMPERATURE_CHART.Not calibrated'
            | translate
        }}
      </span>
    </div>

    <div>
      <div style="background-color: green"></div>
      <span>
        {{
          'VIEWS.DEVICES.DIALOGS_AND_MODALS.MODALS.DEVICE_CALIBRATION.DEVICE_CALIBRATION_TEMPERATURE_MODAL.DEVICE_CALIBRATION_TEMPERATURE_CHART.Calibrated'
            | translate
        }}
      </span>
    </div>
  </div>
</div>
