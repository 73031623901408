<div class="device-position-component">
  <ng-container *ngIf="device$$ | async as device">
    <!-- <div class="warning" *ngIf="(DATE_NOW | differenceInDays: device.last_contact) > 1">
      <svg style="width: 24px; height: 24px" viewBox="0 0 24 24">
        <path d="M13,14H11V10H13M13,18H11V16H13M1,21H23L12,2L1,21Z" />
      </svg>
      <span>
        {{ 'VIEWS.MODALS.DEVICE.position taken on [date]' | translate: { date: (device.location.timestamp | formatDate: 'short') } }}
      </span>
    </div> -->

    <div class="map-and-last-position-taken">
      <div *ngIf="(user_can_read_device_position$$ | async) === false" class="no-ace-message">
        <bg2-acl-not-accessible
          [message]="'ALL.ERROR.ACE.READ_POSITION.ACCESSIBILITY.Position not displayed' | translate"
          [popper_config]="{
            entity_type: 'location',
            ace: 'read_devices_last_position',
            what: ('ALL.ERROR.ACE.READ_DEVICES_LAST_POSITION.WHAT.the device\'s position' | translate)
          }"
        ></bg2-acl-not-accessible>
      </div>

      <hex-loading *ngIf="is_loading"></hex-loading>

      <bg2-leaflet-map
        [height]="height"
        [layers]="map_layers$$ | async"
        [selectable_base_layer]="false"
        (mapMoveEnd)="onLeafletMapMoveEnd()"
        (mapReady)="onLeafletMapReady($event)"
      >
      </bg2-leaflet-map>
    </div>

    <!-- <p *ngIf="device.type === 'RG'" style="text-align: center">
      {{ 'VIEWS.MODALS.DEVICE.position taken on [date]' | translate: { date: device.location?.timestamp | formatDate: 'full' } }}
    </p> -->
  </ng-container>
</div>
