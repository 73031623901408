<div class="simple-histogram">

  <ng-template #loading_data>
    <hex-loading [size]="'small'"></hex-loading>
  </ng-template>

  <ng-container *ngIf="data$$ | async as histo_data; else loading_data;">
    <div class="datas">
      <ng-container *ngFor="let item of items_config">
        <div class="object" *ngIf="histo_data.data[item.key] > 0">
          <div class="data" [ngStyle]="{'height':histogram_height}">
            <div class="data-legend">
              <img class="data-image" src="assets/{{item.img}}" alt="{{item.label | translate}}">
              <span class="data-value">{{histo_data.data[item.key]}}</span>
            </div>
            <div class="data-percentage" [ngStyle]="{
                'height': ((histo_data.data[item.key] / histo_data.maximum) * 100) + '%',
                'background':histogram_color
              }">
            </div>
          </div>
          <div class="label">{{item.label | translate}}</div>
        </div>

      </ng-container>
    </div>
  </ng-container>

</div>