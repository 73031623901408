<div class="device-movements-map-component">
  <span
    id="fit-bounds"
    class="map-config-button"
    (click)="fitBounds()"
    [popper]="'VIEWS.MAP.COMPONENTS.PAGE_MAP.Automatic zoom' | translate"
  >
    <i class="mdi mdi-fit-to-page-outline mdi-24px"></i>
  </span>

  <span
    id="cluster-markers"
    class="map-config-button"
    (click)="is_clustering_enabled = !is_clustering_enabled"
    [popper]="
      (is_clustering_enabled$$ | async)
        ? ('VIEWS.MAP.COMPONENTS.PAGE_MAP.Unclusterize' | translate)
        : ('VIEWS.MAP.COMPONENTS.PAGE_MAP.Clusterize' | translate)
    "
  >
    <div style="position: relative">
      <span [class.is-mdi-disabled]="(is_clustering_enabled$$ | async) === false"></span>
      <span class="mdi mdi-group mdi-24px"></span>
    </div>
  </span>

  <span
    id="fast-reload"
    class="map-config-button"
    (click)="is_fast_reload_enabled = !is_fast_reload_enabled"
    [popper]="
      (is_fast_reload_enabled$$ | async)
        ? ('VIEWS.MAP.COMPONENTS.PAGE_MAP.Disable fast reload' | translate)
        : ('VIEWS.MAP.COMPONENTS.PAGE_MAP.Enable fast reload' | translate)
    "
  >
    <div style="position: relative">
      <span [class.is-mdi-disabled]="(is_fast_reload_enabled$$ | async) === false"></span>
      <img src="/assets/img/pictos/refresh-30s.svg" />
    </div>
  </span>

  <bg2-leaflet-map (mapReady)="onLeafletMapReady($event)" [layers]="layers$$ | async" [height]="'100%'"></bg2-leaflet-map>
</div>
