export enum DeviceUserAce {
  /**
   * Is the user allowed to access the associated devices ?
   */
  'read_devices',

  /**
   * Is the user allowed to read the last position of the device ?
   */
  'read_devices_last_position',

  /**
   * Is the user allowed to read trips of the device ?
   */
  'read_devices_routes',

  /**
   * Is the user allowed to read raw data of the device ?
   */
  'read_devices_data',

  /**
   * Is the user allowed to create movement authorization for the device ?
   */
  'write_move_authorization',

  /**
   * Is the user allowed to configure notifications of the device ?
   */
  'write_devices_notifications',

  /**
   * Is the user allowed to edit configuration of the device ?
   */
  'write_devices_configuration',
}

/** */
export const device_user_ace_to_i18n: Map<keyof typeof DeviceUserAce, string> = new Map([
  ['read_devices', 'ENTITY.ALL.SCOPE.Access to the associated devices'],
  ['read_devices_last_position', 'ENTITY.ALL.SCOPE.Read last precise GPS position of associated devices'],
  ['read_devices_routes', 'ENTITY.ALL.SCOPE.Access trips of associated devices'],
  ['read_devices_data', 'ENTITY.ALL.SCOPE.Access device raw data'],
  ['write_move_authorization', 'ENTITY.ALL.SCOPE.Configure movement authorizations for associated devices'],
  ['write_devices_notifications', 'ENTITY.ALL.SCOPE.Configure custom notifications on associated devices'],
  ['write_devices_configuration', 'ENTITY.ALL.SCOPE.Configure devices associated to the location'],
]);
