import { Component, ChangeDetectionStrategy, OnDestroy } from '@angular/core';
import { OnInit, Input } from '@angular/core';
import { ComponentFactoryResolver } from '@angular/core';
import { Injector } from '@angular/core';
import { ComponentRef } from '@angular/core';

import { isNil, uniqueId } from 'lodash-es';

import { switchMap, map, tap, distinctUntilChanged, filter, catchError, of, delay, delayWhen } from 'rxjs';
import { BehaviorSubject, Observable, combineLatest } from 'rxjs';

import { TranslateService } from '@ngx-translate/core';

import { GoogleMapsLoader } from 'app/misc/services/google-maps-loader.service';

import { distinctUntilRealChanged, replay, switchTap, waitForNotNilValue } from '@bg2app/tools/rxjs';

import { DRDevice } from 'app/models';
import { Beeguard2Api } from 'app/core';
import { ConsoleLoggerService } from 'app/core/console-logger.service';

// From @node_modules/leaflet/*
import { Map, latLngBounds, control, point, featureGroup, FeatureGroup } from 'leaflet';
import { DeviceMarker, AbstractMarker, CONF_CIRCLE_COLORS, LocationMarker } from 'app/typings/mapping';
import { groupMarkersByType, unifyPolyCircles } from 'app/misc/tools/geomap';
import { Bg2MapPopupDeviceMarkerComponent } from 'app/views/map/shared/html-map-popups/map-popup-device-marker/map-popup-device-marker.component';

@Component({
  selector: 'bg2-device-position',
  templateUrl: './device-position.component.html',
  styleUrls: ['./device-position.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DevicePositionComponent implements OnInit, OnDestroy {
  public readonly DATE_NOW = new Date();
  public readonly UNIQUE_ID = `${uniqueId('location-map-')}`;

  private _device$$ = new BehaviorSubject<DRDevice>(null);
  public device$$ = this._device$$.asObservable();

  @Input()
  public set device(dev: DRDevice) {
    this._device$$.next(dev);
  }

  // #region -> (component basics)

  private readonly _logger: ConsoleLoggerService = new ConsoleLoggerService(this.constructor.name, true);

  constructor(
    private _bg2api: Beeguard2Api,
    private _resolver: ComponentFactoryResolver,
    private _injector: Injector,
    private _translate: TranslateService
  ) {}

  ngOnInit(): void {
    GoogleMapsLoader.load().catch((reason: any) => this._logger.error(reason));
  }

  ngOnDestroy(): void {
    if (this._device_popup_ref?.cmp) {
      this._device_popup_ref.cmp.destroy();
    }
  }

  // #endregion

  /**
   * Height in pixels of the map.
   *
   * @default '250px'
   *
   * @example
   * // Indicate value followed by 'px'
   * let height = '100px';
   */
  @Input()
  public height: `${string}px` | `${string}%` = '250px';

  @Input()
  public delay: number = null;

  // #region -> (ACE management)

  /** */
  public user_can_read_device_position$$ = this.device$$.pipe(
    switchMap(device =>
      device.location$$(this._bg2api).pipe(
        switchMap(location => {
          if (isNil(location)) {
            return device.user_acl.can$$('read_devices_last_position');
          }

          return location.user_acl.can$$('read_devices_last_position');
        })
      )
    ),
    distinctUntilRealChanged(),
    replay()
  );

  // #endregion

  /** */
  public is_loading = true;

  // #region -> (mapping management)

  /** */
  private _prefer_position_type$ = new BehaviorSubject<'GPS' | 'CELLIDS'>(null);

  /** */
  private prefer_position_type$$ = this._prefer_position_type$.asObservable();

  /** */
  @Input()
  public set prefer_position_type(prefer_position_type: 'GPS' | 'CELLIDS') {
    this._prefer_position_type$.next(prefer_position_type);
  }

  private _map_ready$$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public map_ready$$: Observable<boolean> = this._map_ready$$.asObservable().pipe(
    distinctUntilChanged<boolean>(),
    switchMap(value => {
      if (!isNil(this.delay)) {
        return of(value).pipe(
          delay(this.delay),
          tap(() => (this.is_loading = false))
        );
      }

      return of(value);
    }),
    replay()
  );

  private _leaflet_map_ref: Map = null;

  private _loading$$: BehaviorSubject<boolean> = new BehaviorSubject(true);

  private set loading(val: boolean) {
    this._loading$$.next(val);
  }

  private _device_popup_ref: { popup: L.Popup; cmp: ComponentRef<Bg2MapPopupDeviceMarkerComponent> } = {
    popup: null,
    cmp: null,
  };

  private _device_marker$$: Observable<FeatureGroup<DeviceMarker>> = this.map_ready$$
    .pipe(
      filter(is_map_ready => is_map_ready),
      switchMap(() => this.device$$),
      switchMap(device =>
        device.location$$(this._bg2api).pipe(
          switchMap(location => {
            if (isNil(location)) {
              return device.user_acl.throw__if_cannot$$(
                'read_devices_last_position',
                "ALL.ERROR.ACE.READ_DEVICES_LAST_POSITION.WHAT.the device's position"
              );
            }

            return location.user_acl.check_read_precise_position$$({
              what: "ALL.ERROR.ACE.READ_DEVICES_LAST_POSITION.WHAT.the device's position",
            });
          }),
          map(() => device)
        )
      ),
      catchError(() => of(null)),
      tap(() => (this.loading = true)),
      switchMap(device => this.prefer_position_type$$.pipe(map(prefer_position_type => ({ device, prefer_position_type })))),
      map(({ device, prefer_position_type }) => {
        this._device_popup_ref.cmp?.destroy();
        this._device_popup_ref.cmp = null;
        if (isNil(device?.location)) {
          return null;
        }

        // Buil new device marker
        const device_marker = new DeviceMarker(device, prefer_position_type);

        // Build-up popup content component
        const component = this._resolver.resolveComponentFactory(Bg2MapPopupDeviceMarkerComponent).create(this._injector);
        device_marker.bindPopup(component.location.nativeElement, { closeButton: true });

        component.instance.marker = device_marker;
        component.instance.self_popup_ref = device_marker.getPopup();
        component.changeDetectorRef.detectChanges();

        // Build-up popup itself
        this._device_popup_ref = {
          popup: device_marker.getPopup(),
          cmp: component,
        };

        return device_marker;
      })
    )
    .pipe(
      map(device_marker => (!isNil(device_marker) ? [device_marker] : [])),
      map(device_markers => featureGroup(device_markers)),
      tap(device_markers => this.updateMapBounds(device_markers))
    );

  private _location_marker$$: Observable<FeatureGroup<LocationMarker>> = this.map_ready$$.pipe(
    filter(is_map_ready => is_map_ready),
    switchMap(() => this.device$$),
    switchMap(device =>
      device.location$$(this._bg2api).pipe(
        switchTap(location => {
          if (isNil(location)) {
            return of(null);
          }

          return location.user_acl.check_read_precise_position$$({
            what: "ALL.ERROR.ACE.READ_PRECISE_POSITION.WHAT.the location's precise position",
          });
        })
      )
    ),
    catchError(() => of(null)),
    map(location => {
      // TODO : https://gitlab.dev.siconsult.fr:9090/beeguard_v2/beeguard2-ng-app/-/issues/839
      if (!isNil(location) && !isNil(location?.position?.latitude) && !isNil(location?.position?.longitude)) {
        return new LocationMarker(location);
      }

      return null;
    }),
    map(location_marker => (!isNil(location_marker) ? [location_marker] : [])),
    map(location_markers => featureGroup(location_markers))
  );

  private _update_circles$: BehaviorSubject<boolean> = new BehaviorSubject(true);
  private _leaflet_map_circles$$: Observable<L.FeatureGroup<any>[]> = combineLatest({
    update: this._update_circles$.asObservable().pipe(distinctUntilChanged<boolean>()),
    device_marker: this._device_marker$$,
  }).pipe(
    tap(() => (this.loading = true)),
    map(({ update, device_marker }) => {
      const markers: AbstractMarker[] = (device_marker.getLayers() as AbstractMarker[]).filter(
        (marker: AbstractMarker) => marker instanceof DeviceMarker
      );
      const grouped_markers = groupMarkersByType(markers, 'circle');
      return Object.keys(grouped_markers).map((key: string) => {
        if ((grouped_markers as any)[key].getLayers().length > 0) {
          return featureGroup([unifyPolyCircles((grouped_markers as any)[key].getLayers(), (CONF_CIRCLE_COLORS as any)[key])]);
        }
        return null;
      });
    })
  );

  public onLeafletMapReady(leaflet_map: Map): void {
    this._leaflet_map_ref = leaflet_map;
    this._leaflet_map_ref.addControl(control.zoom({ position: 'bottomright' }));
    this._map_ready$$.next(true);
  }

  public onLeafletMapMoveEnd(): void {
    this._update_circles$.next(!this._update_circles$.getValue());
  }

  public map_layers$$: Observable<L.Layer[]> = combineLatest({
    device_marker: this._device_marker$$,
    location_marker: this._location_marker$$,
    circles: this._leaflet_map_circles$$,
  }).pipe(
    map(({ device_marker, location_marker, circles }) => [device_marker, location_marker, ...circles]),
    tap(() => (this.loading = false))
  );

  private updateMapBounds(markers: FeatureGroup): void {
    if (!isNil(this._leaflet_map_ref)) {
      const bounds: L.LatLngBounds = latLngBounds([]);
      bounds.extend(markers.getBounds());
      if (bounds.isValid()) {
        this._leaflet_map_ref.flyToBounds(bounds, {
          animate: true,
          padding: point(25, 25),
          maxZoom: 18,
          duration: 0.25,
        });
      }
    }
  }
}
