import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { HiveStatus } from 'app/views/events/shared/events/evaluation/evaluation-histogram-hive-statuses/evaluation-histogram-hive-statuses.component';

@Component({
  selector: 'bg2-picon-hive-status',
  templateUrl: './picon-hive-status.component.html',
  styles: [':host { display: flex; justify-content: center; }'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PiconHiveStatusComponent {
  @Input()
  public size_w: string = '24px';

  @Input()
  public hive_status: HiveStatus = null;
}
