import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { BehaviorSubject, Observable } from 'rxjs';

import * as L from 'leaflet';

import { Bg2MapPopupComponent } from '../map-popup/map-popup.component';
import { replay } from '@bg2app/tools/rxjs';
import { DefaultMarker, DeviceMarker, LocationMarker } from 'app/typings/mapping';
import { map } from 'rxjs';
import { groupMarkersByCat } from 'app/misc/tools/geomap';

@Component({
  selector: 'bg2-map-popup-all-cluster',
  templateUrl: './map-popup-all-cluster.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class Bg2MapPopupAllClusterComponent extends Bg2MapPopupComponent {
  private _cluster$$: BehaviorSubject<L.MarkerCluster> = new BehaviorSubject(null);
  public cluster$$: Observable<L.MarkerCluster> = this._cluster$$.asObservable().pipe(replay());

  @Input()
  public set cluster(cluster: L.MarkerCluster) {
    this._cluster$$.next(cluster);
  }

  public cluster_info$$: Observable<{
    locations: LocationMarker[];
    devices: DeviceMarker[];
    unrepertoried: DefaultMarker[];
  }> = this.cluster$$.pipe(map((cluster: L.MarkerCluster) => groupMarkersByCat(cluster.getAllChildMarkers())));
}
