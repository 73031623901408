/**
 * Converts a list of local hours to a list of UTC hours.
 *
 * @param hours List of local hours to convert.
 *
 * @returns Returns a list of UTC hours converted from local hours.
 */
export const convert_local_hours_to_utc = (hours: number[]): number[] => {
  const date_time = new Date();
  const timezone_offset = date_time.getTimezoneOffset() / 60;

  return hours.map(hour => hour + timezone_offset).map(hour => ((hour % 24) + 24) % 24);
};
