import {
  Component,
  OnDestroy,
  ViewChild,
  ElementRef,
  OnInit,
  ChangeDetectionStrategy,
  HostListener,
  NgZone,
  AfterViewInit,
} from '@angular/core';
import { first, get, isNil, last, uniqueId } from 'lodash-es';

import { concat, Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs';

import { TranslateService } from '@ngx-translate/core';

import { Beeguard2Api } from 'app/core';
import { AppStateService } from 'app/core/app-state.service';
import { DialogsService } from 'app/widgets/dialogs-modals/dialogs.service';
import { distinctUntilRealChanged } from '@bg2app/tools/rxjs';

import { BgControlWidgetComponent as ControlWidget, WidgetOptions } from '../control/control.widget';
import { replay } from '@bg2app/tools/rxjs';
import { Dictionary } from 'app/typings/core/interfaces';
import { FullscreenSelectHelper } from '@bg2app/tools/misc';
import { MtxSelectComponent } from '@ng-matero/extensions/select';

interface SelectItem {
  label: string;
  value: string;
  ref: string;
}

export interface ItemSelectOption {
  position_y?: string;
}

export interface EfSelectOptions extends WidgetOptions {
  img: boolean;
  img_prefix: string;
  items: { [key: string]: ItemSelectOption };
  readonly: boolean;
  clearable?: boolean;
  auto_scroll?: boolean;
  template_name?: string;
}

@Component({
  selector: 'bg2-ef-select-widget',
  templateUrl: './select.widget.html',
  styleUrls: ['./select.widget.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EfSelectWidgetComponent extends ControlWidget implements OnDestroy, AfterViewInit, OnInit {
  public options: EfSelectOptions = {
    img: false,
    img_prefix: 'select/',
    items: {},
    readonly: false,
    indent: false,
    clearable: true,
    reset_btn: false,
    auto_scroll: true,
    template_name: null,
  };

  public items$$: Observable<SelectItem[]>;
  public len$$: Observable<number>;
  private banned_values$$: Observable<string[]>;

  public multiple = false;

  set value(value: string) {
    if (isNil(value)) {
      // This is needed to RAZ the propety,
      // elsewhere we got an error that null is not a valid type
      value = '';
    }
    this.formProperty.setValue(value, false);
  }

  get value(): string {
    return this.formProperty.value;
  }

  // #region -> (fullscreen widget)

  /** */
  public fullscreen_select_helper = new FullscreenSelectHelper(this._ng_zone);

  // #endregion

  /** */
  public readonly widget_id = uniqueId('widget-event-form-select-');

  /** */
  private _material_selector_ref: MtxSelectComponent;

  /** */
  @ViewChild('select', {})
  protected set select_ref(select: MtxSelectComponent) {
    if (isNil(select)) {
      return;
    }

    this._material_selector_ref = select;
    this.fullscreen_select_helper.select_ref.value = select;
  }

  /** */
  protected get select_ref() {
    return this._material_selector_ref;
  }

  constructor(
    protected bg2Api: Beeguard2Api,
    protected translate: TranslateService,
    public appState: AppStateService,
    protected dialogs: DialogsService,
    protected readonly _ng_zone: NgZone
  ) {
    super(bg2Api, appState, dialogs);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.multiple = this.schema.type === 'array';

    this.banned_values$$ = of([]); // default value
    const parent_set_level = this.schema.parent_set_level || null;
    if (!isNil(parent_set_level)) {
      // Get parent_set config, is the select part of a larger set (array) if values
      let parent_set = this.formProperty.parent;
      for (let i = 1; i < parent_set_level; i++) {
        parent_set = parent_set.parent;
      }
      let parent_set_child_path = this.formProperty.path.substr(parent_set.path.length + 3); // +3 for last '/*/'
      parent_set_child_path = parent_set_child_path.replace('/', '.');
      this.banned_values$$ = concat(of([]), of(parent_set.value), parent_set.valueChanges).pipe(
        map(parent_values => parent_values.map((obj: any) => get(obj, parent_set_child_path))),
        switchMap(banned_values => this.value$$.pipe(map(value => banned_values.filter((_value: any) => _value !== value)))),
        distinctUntilRealChanged(),
        replay()
      );
    }

    this.items$$ = of(this.schema).pipe(
      map(schema => {
        let _items: SelectItem[] = [];
        if (this.multiple) {
          schema = schema.items;
        }
        if (!isNil(schema.oneOf)) {
          _items = schema.oneOf.map((oneof: any) => ({
            ...oneof,
            label: oneof.label || oneof.description,
            value: oneof.enum[0],
            ref: this.getRef(oneof.label || oneof.description),
          }));
        } else if (!isNil(schema.enum)) {
          _items = schema.enum.map((val: any) => ({
            label: val,
            value: val,
            ref: this.getRef(val),
          }));
        }
        return _items;
      }),
      switchMap(items => this.banned_values$$.pipe(map(banned_values => [items, banned_values] as [SelectItem[], string[]]))),
      map(([_items, banned_values]) => {
        const _exclude: Dictionary<any> = {};
        banned_values.map(value => {
          _exclude[value] = true;
        });
        return _items.filter((item: any) => !_exclude[item.value]);
      }),
      replay()
    );

    this.len$$ = this.items$$.pipe(map(ti => ti.length));
  }

  ngAfterViewInit(): void {}

  ngOnDestroy(): void {
    this.fullscreen_select_helper.destroy();
    super.ngOnDestroy();
  }

  get placeholder(): string {
    return this.schema.placeholder || this.schema.title;
  }

  get label(): string {
    return this.schema.label;
  }

  protected _items_style: Dictionary<any> = {};
  public getItemStyle(item: SelectItem): any {
    const key = item.label;
    if (!this._items_style[key]) {
      const item_opt = this.options.items[key] || {};
      const styles: Dictionary<any> = {};
      if (this.options.img && !isNil(key)) {
        styles['background-image'] = `url('assets/img/${this.options.img_prefix}${key}.jpg')`;
        if (item_opt.position_y) {
          styles['background-position-y'] = item_opt.position_y;
        }
      }
      this._items_style[key] = styles;
    }
    return this._items_style[key];
  }

  public getRef(i18n: any): string {
    const last_key = last(('.' + i18n).split('.')) as string;
    const first_word = first(last_key.split(' ')) as string;
    return first_word;
  }

  public scrollToSelect(): void {
    if (this.options.auto_scroll) {
      setTimeout(() => {
        this.select_ref.ngSelect.element.scrollIntoView(true);
      }, 150);
    }
  }

  // Custom research on select: checking the final translation, not the i18n
  // Fat arrow needed to bind 'this' to 'EfSelectWidget'
  public ngSelectCustomSearch = (term: string, item: any) => {
    term = term.toLocaleLowerCase();
    if (!item.label) {
      return false;
    }
    return this.translate.instant(item.label).toLocaleLowerCase().indexOf(term) > -1;
  };
}
