import { AfterViewInit, ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

import { select, scaleLinear, axisBottom, axisLeft, line, min, max, Selection, ScaleLinear } from 'd3';
import * as ss from 'simple-statistics';
import { flatten, isNil, sortBy, uniqueId } from 'lodash-es';
import { SimpleSetterGetter } from 'app/models';
import { filter, switchMap, take } from 'rxjs';

interface ChartData {
  points_noise: { weight_1: number; weight_2: number; temperature: number }[];
  calibrated_points: { weight_1: number; weight_2: number; temperature: number }[];
}

@Component({
  selector: 'bg2-device-calibration-temperature-chart',
  templateUrl: './device-calibration-temperature-chart.component.html',
  styleUrls: ['./device-calibration-temperature-chart.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DeviceCalibrationTemperatureChartComponent implements OnInit, AfterViewInit {
  // #region -> (component basics)

  constructor() {}

  ngOnInit(): void {
    this._is_chart_base_ready.value$$
      .pipe(
        filter(Boolean),
        take(1),
        switchMap(() => this._data.value$$)
      )
      .subscribe({
        next: data => this.draw_data(data),
      });
  }

  ngAfterViewInit(): void {
    this._chart_refs.svg = select('#' + this.unique_name)
      .append('svg')
      .attr('width', this._chart_size.width)
      .attr('height', this._chart_size.height)
      .append('g')
      .attr('transform', `translate(${this._chart_size.margins.left}, ${this._chart_size.margins.top})`);

    // Build X-axis
    this._chart_refs.xAxis = scaleLinear().range([0, this.chart_real_width]);
    this._chart_refs.xAxisContainer = this._chart_refs.svg
      .append('g')
      .attr('transform', `translate(0, ${this.chart_real_height})`)
      .call(axisBottom(this._chart_refs.xAxis));

    // Build Y-axis
    this._chart_refs.yAxis = scaleLinear().range([this.chart_real_height, 0]);
    this._chart_refs.yAxisContainer = this._chart_refs.svg.append('g').call(axisLeft(this._chart_refs.yAxis));

    // Create data containers
    const scatterplot_container = this._chart_refs.svg.append('g').attr('class', 'scatterplot-container');
    this._chart_refs.scatterplot_container.raw = scatterplot_container.append('g').attr('class', 'scatterplot-container__raw');
    this._chart_refs.scatterplot_container.corrected = scatterplot_container.append('g').attr('class', 'scatterplot-container__corrected');

    const trendline_container = this._chart_refs.svg.append('g').attr('class', 'trendline-container');
    this._chart_refs.trendline_container.raw = trendline_container.append('path').attr('class', 'trendline-container__raw');
    this._chart_refs.trendline_container.corrected = trendline_container.append('path').attr('class', 'trendline-container__corrected');

    this._is_chart_base_ready.value = true;
  }

  // #endregion

  // #region -> (chart management)

  /** */
  private readonly _chart_size = {
    margins: { top: 10, right: 10, bottom: 20, left: 40 },
    width: 300,
    height: 200,
  };

  /** */
  private get chart_real_width(): number {
    return this._chart_size.width - this._chart_size.margins.left - this._chart_size.margins.right;
  }

  /** */
  private get chart_real_height(): number {
    return this._chart_size.height - this._chart_size.margins.top - this._chart_size.margins.bottom;
  }

  /** */
  public unique_name = uniqueId('device-calibration-chart-');

  /** */
  private _chart_refs: {
    svg: Selection<SVGGElement, unknown, HTMLElement, any>;

    xAxis: ScaleLinear<number, number, never>;
    xAxisContainer: Selection<SVGGElement, unknown, HTMLElement, any>;

    yAxis: ScaleLinear<number, number, never>;
    yAxisContainer: Selection<SVGGElement, unknown, HTMLElement, any>;

    scatterplot_container: {
      raw: Selection<SVGGElement, unknown, HTMLElement, any>;
      corrected: Selection<SVGGElement, unknown, HTMLElement, any>;
    };

    trendline_container: {
      raw: Selection<SVGGElement, unknown, HTMLElement, any>;
      corrected: Selection<SVGGElement, unknown, HTMLElement, any>;
    };
  } = {
    svg: null,
    xAxis: null,
    xAxisContainer: null,
    yAxis: null,
    yAxisContainer: null,
    scatterplot_container: {
      raw: null,
      corrected: null,
    },
    trendline_container: {
      raw: null,
      corrected: null,
    },
  };

  /** */
  private _is_chart_base_ready = new SimpleSetterGetter(false);

  /** */
  private draw_data(data: { raw: ChartData; calib: ChartData }): void {
    if (isNil(data)) {
      return;
    }

    // Data raw
    const raw_data_noise = data.raw.points_noise;

    const raw_data = data.raw.calibrated_points;
    const raw_data_sorted_by_temp = sortBy(raw_data, d => d.temperature);

    // Data corrected
    const corrected_data_noise = data.calib.points_noise;

    const corrected_data = data.calib.calibrated_points;
    const corrected_data_sorted_by_temp = sortBy(corrected_data, d => d.temperature);

    const values_temperature = [
      ...raw_data_noise.map(p => p?.temperature),
      ...raw_data?.map(p => p?.temperature),
      ...corrected_data_noise?.map(p => p?.temperature),
      ...corrected_data?.map(p => p?.temperature),
    ];

    const values_weight = [
      ...flatten(raw_data_noise.map(p => [p?.weight_1, p?.weight_2])),
      ...flatten(raw_data?.map(p => [p?.weight_1, p?.weight_2])),
      ...flatten(corrected_data_noise?.map(p => [p?.weight_1, p?.weight_2])),
      ...flatten(corrected_data?.map(p => [p?.weight_1, p?.weight_2])),
    ];

    const min_value_x = min(values_temperature);
    const max_value_x = max(values_temperature);

    const min_value_y = min(values_weight);
    const max_value_y = max(values_weight);

    // Update domains
    this._chart_refs.xAxis.domain([min_value_x - 1, max_value_x + 1]);
    this._chart_refs.yAxis.domain([min_value_y - 1, max_value_y + 1]);

    // Update axes
    this._chart_refs.xAxisContainer.call(axisBottom(this._chart_refs.xAxis));
    this._chart_refs.yAxisContainer.call(axisLeft(this._chart_refs.yAxis));

    // Draw scatterplot (raw)
    this._chart_refs.scatterplot_container.raw
      .selectAll('dot')
      .data(raw_data_noise)
      .join('circle')
      .attr('cx', d => this._chart_refs.xAxis(d.temperature))
      .attr('cy', d => this._chart_refs.yAxis(d[this.property]))
      .attr('r', 2)
      .style('fill', 'orange');

    // Draw scatterplot (corrected)
    this._chart_refs.scatterplot_container.corrected
      .selectAll('dot')
      .data(corrected_data_noise)
      .join('circle')
      .attr('cx', d => this._chart_refs.xAxis(d.temperature))
      .attr('cy', d => this._chart_refs.yAxis(d[this.property]))
      .attr('r', 2)
      .style('fill', 'blue');

    // Draw trendline (raw)
    const linear_regression_raw = ss.linearRegression(raw_data.map(d => [d.temperature, d[this.property]]));
    const linear_regression_fn_raw = ss.linearRegressionLine(linear_regression_raw);

    const x_coordinates_raw = [raw_data_sorted_by_temp[0].temperature, raw_data_sorted_by_temp.slice(-1)[0].temperature];
    const alpha_raw = x_coordinates_raw.map(d => [d, linear_regression_fn_raw(d)]);

    this._chart_refs.trendline_container.raw
      .datum(alpha_raw)
      .attr('fill', 'none')
      .attr('stroke', 'red')
      .style('stroke-dasharray', '3, 3')
      .attr('stroke-width', 2)
      .attr(
        'd',
        <any>line()
          .x(d => this._chart_refs.xAxis(d[0]))
          .y(d => this._chart_refs.yAxis(d[1]))
      );

    // Draw trendline (corrected)
    const linear_regression_corrected = ss.linearRegression(corrected_data.map(d => [d.temperature, d[this.property]]));
    const linear_regression_fn_corrected = ss.linearRegressionLine(linear_regression_corrected);
    const x_coordinates_corrected = [corrected_data_sorted_by_temp[0].temperature, corrected_data_sorted_by_temp.slice(-1)[0].temperature];
    const alpha_corrected = x_coordinates_corrected.map(d => [d, linear_regression_fn_corrected(d)]);
    this._chart_refs.trendline_container.corrected
      .datum(alpha_corrected)
      .attr('fill', 'none')
      .attr('stroke', 'green')
      .style('stroke-dasharray', '3, 3')
      .attr('stroke-width', 2)
      .attr(
        'd',
        <any>line()
          .x(d => this._chart_refs.xAxis(d[0]))
          .y(d => this._chart_refs.yAxis(d[1]))
      );
  }

  // #endregion

  @Input()
  public set data(data: { raw: ChartData; calib: ChartData }) {
    this._data.value = data;
  }

  /** */
  private _data = new SimpleSetterGetter<{
    raw: ChartData;
    calib: ChartData;
  }>(null);

  @Input()
  public property: 'weight_1' | 'weight_2';

  @Input()
  public is_calib: boolean = false;
}
