import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { SchemaFormModule, WidgetRegistry } from 'ngx-schema-form';

import { MiscModule } from 'app/misc/misc.module';
import { SharedModule } from 'app/shared-module/shared.module';
import { MaterialSharedModule } from 'app/shared-module/material-shared.module';
import { WidgetsReusableModule } from '../widgets-reusables/widgets-reusables.module';

import { EfWidgetRegistry } from 'app/widgets/event-form/eventforms.widgetregistry';

import { PagingComponent } from './paging/paging.component';
import { HtmlErrorComponent } from './html-error/html-error.component';
import { DocHelperComponent } from './doc-helper/doc-helper.component';
import { LangSelectComponent } from './/lang-select/lang-select.component';
import { Bg2UserSelectComponent } from './user-select/user-select.component';
import { FiltersFormComponent } from './filters-form/filters-form.component';
import { Bg2LeafletMapComponent } from './leaflet-map/leaflet-map.component';
import { Bg2HistogramComponent } from './bg2-histogram/bg2-histogram.component';
import { TableToolbarsComponent } from './table-toolbars/table-toolbars.component';
import { AppUpdateSnackComponent } from './app_update_snack/app-update-snack.component';
import { SimpleHistogramComponent } from './simple-histogram/simple-histogram.component';
import { SuperboxDisplayComponent } from './superbox-display/superbox-display.component';
import { MenuImpersonateComponent } from './menu-impersonate/menu-impersonate.component';
import { PullDownFiltersComponent } from './pull-down-filters/pull-down-filters.component';
import { ExplanationSchemasComponent } from './explanation-schemas/explanation-schemas.component';
import { TableGroupsBreadcrumbComponent } from './table-groups-breadcrumb/table-groups-breadcrumb.component';
import { Bg2AdaptiveTextToContainerComponent } from './adaptive-text-to-container/adaptive-text-to-container.component';
import { PullDownFiltersBottomSheetComponent } from './pull-down-filters-bottom-sheet/pull-down-filters-bottom-sheet.component';
import { SuperadminShortcutComponent } from './superadmin-shortcut/superadmin-shortcut.component';
import { ZohoSharedModule } from 'app/views/zoho/shared/zoho-shared.module';
import { IntlPhoneInputComponent } from './custom-form-field/intl-phone-input/intl-phone-input.component';

const module_components = [
  PagingComponent,
  DocHelperComponent,
  HtmlErrorComponent,
  LangSelectComponent,
  Bg2UserSelectComponent,
  IntlPhoneInputComponent,
  FiltersFormComponent,
  Bg2HistogramComponent,
  TableToolbarsComponent,
  Bg2LeafletMapComponent,
  AppUpdateSnackComponent,
  SuperboxDisplayComponent,
  MenuImpersonateComponent,
  SimpleHistogramComponent,
  PullDownFiltersComponent,
  ExplanationSchemasComponent,
  TableGroupsBreadcrumbComponent,
  Bg2AdaptiveTextToContainerComponent,
  SuperadminShortcutComponent,
];

@NgModule({
  imports: [
    MiscModule,
    FormsModule,
    CommonModule,
    RouterModule,
    SharedModule,
    ZohoSharedModule,
    ReactiveFormsModule,
    MaterialSharedModule,
    WidgetsReusableModule,
    SchemaFormModule.forRoot(),
  ],
  declarations: [...module_components, PullDownFiltersBottomSheetComponent],
  exports: [...module_components],
  providers: [
    {
      provide: WidgetRegistry,
      useClass: EfWidgetRegistry,
    },
  ],
})
export class MiscWidgetsModule {}
