import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';

import { MiscModule } from 'app/misc/misc.module';
import { SharedModule } from 'app/shared-module/shared.module';
import { WidgetsReusableModule } from 'app/widgets/widgets-reusables/widgets-reusables.module';

import { ZohoCRMNoteSummaryComponent } from './crm/note-summary/zoho-crm-note-summary.component';
import { ZohoCRMDealSummaryComponent } from './crm/deal-summary/zoho-crm-deal-summary.component';
import { ZohoCRMTaskSummaryComponent } from './crm/task-summary/zoho-crm-task-summary.component';
import { ZohoTicketSummaryComponent } from './tickets/zoho-ticket-summary/zoho-ticket-summary.component';
import { ZohoManagerContainerComponent } from './zoho-manager-container/zoho-manager-container.component';
import { ZohoBooksInvoiceSummaryComponent } from './books/invoice-summary/zoho-books-invoice-summary.component';
import { ZohoTicketListOpenedComponent } from './tickets/zoho-ticket-list-opened/zoho-ticket-list-opened.component';
import { ZohoCheckContactUserIDComponent } from './zoho-checks/zoho-check-contact-user-id/zoho-check-contact-user-id.component';
import { ZohoCheckBindedExploitationIdComponent } from './zoho-checks/zoho-check-binded-exploitation-id/zoho-check-bind-exploitation-id.component';

const components_to_export = [
  ZohoTicketSummaryComponent,
  ZohoCRMNoteSummaryComponent,
  ZohoCRMTaskSummaryComponent,
  ZohoCRMDealSummaryComponent,

  ZohoBooksInvoiceSummaryComponent,

  ZohoManagerContainerComponent,
  ZohoTicketListOpenedComponent,
  ZohoCheckContactUserIDComponent,
  ZohoCheckBindedExploitationIdComponent,
];

@NgModule({
  declarations: [...components_to_export],
  imports: [
    // Angular modules
    CommonModule,

    // Local modules
    SharedModule,
    WidgetsReusableModule,
    RouterModule,
    // FormsModule,

    // External modules (node_modules)
    MiscModule,
    // MaterialSharedModule,
  ],
  exports: [...components_to_export],
})
export class ZohoSharedModule {}
