import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { BehaviorSubject, combineLatest, map, Observable, of, switchMap, take, tap } from 'rxjs';
import { anyTrue, distinctUntilRealChanged, replay, waitForNotNilProperties } from '@bg2app/tools/rxjs';

import { isEqual, isNil, isString } from 'lodash-es';

import { ZohoCRMApiService } from 'app/core/services/zoho/zoho-crm-api.service';

import { SimpleSetterGetter, User } from 'app/models';
import { ZohoCRMModuleName } from 'app/models/zoho';

/** */
enum ZohoCheckError {
  UNDEFINED_ZOHO_CONTACT = 'UNDEFINED_ZOHO_CONTACT',
  MISSING_CRM_ID_IN_LOCAL_USER = 'MISSING_CRM_ID_IN_LOCAL_USER',
  MISSING_USER_ID_IN_LOCAL_USER = 'MISSING_USER_ID_IN_LOCAL_USER',
  MISSING_CRM_ID_IN_LOCAL_OWNER = 'MISSING_CRM_ID_IN_LOCAL_OWNER',
  MISSING_USER_ID_IN_LOCAL_OWNER = 'MISSING_USER_ID_IN_LOCAL_OWNER',
  MISSING_USER_ID_IN_ZOHO_CONTACT = 'MISSING_USER_ID_IN_ZOHO_CONTACT',

  EQUAL_IDS = 'EQUAL_IDS',
  DIFFERENT_IDS = 'DIFFERENT_IDS',
}

@Component({
  selector: 'bg2-zoho-check-contact-user-id',
  templateUrl: './zoho-check-contact-user-id.component.html',
  styleUrls: ['zoho-check-contact-user-id.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ZohoCheckContactUserIDComponent {
  // #region -> (component basics)

  constructor(private readonly _zohoCrmApiService: ZohoCRMApiService) {}

  // #endregion

  @Input()
  public origin: 'default' | 'exploitation' = 'default';

  // #region -> (user)

  /** */
  @Input()
  public set user(user: User) {
    this._user$$.next(user);
  }

  /** */
  private _user$$ = new BehaviorSubject<User>(null);

  /** */
  public user$$: Observable<User> = this._user$$.asObservable();

  /** */
  public user_id$$ = this.user$$.pipe(switchMap(user => user?.user_id$$ ?? of(null)));
  /** */

  public user_name$$ = this.user$$.pipe(switchMap(user => user?.name$$ ?? of(null)));

  /** */
  public user_crm_id$$ = this.user$$.pipe(switchMap(user => user?.CRM_id$$ ?? of(null)));

  // #endregion

  // #region -> (zoho data)

  @Input()
  public set should_refetch_zoho_data(should_refetch_zoho_data: boolean) {
    this.refetch_zoho_data.value = should_refetch_zoho_data;
  }

  /** */
  private refetch_zoho_data = new SimpleSetterGetter(false);

  /** */
  public zoho_contact_user_id$$: Observable<number> = this.user_crm_id$$.pipe(
    switchMap(user_crm_id => this.refetch_zoho_data.value$$.pipe(map(() => user_crm_id))),
    switchMap(user_crm_id => {
      if (isNil(user_crm_id)) {
        return of(null);
      }

      return this._zohoCrmApiService.fetch_record$(ZohoCRMModuleName.Contacts, user_crm_id, ['user_id']).pipe(
        map(zoho_contact => {
          if (isNil(zoho_contact)) {
            return -1;
          }

          return zoho_contact?.user_id ?? null;
        })
      );
    }),
    map(user_id => {
      if (isNil(user_id)) {
        return null;
      }

      if (isString(user_id)) {
        return parseInt(user_id, 10);
      }

      return user_id;
    }),
    replay()
  );

  // #endregion

  // #region -> (check pipeline)

  /** */
  private _checking_valid_value$$ = new BehaviorSubject<boolean>(false);

  /** */
  public checking_valid_value$$ = this._checking_valid_value$$.asObservable();

  /** */
  public check_reason_result$$ = this.user_crm_id$$.pipe(
    switchMap(user_crm_id => {
      this._checking_valid_value$$.next(true);

      if (isNil(user_crm_id)) {
        return of({
          error: this.origin === 'default' ? ZohoCheckError.MISSING_CRM_ID_IN_LOCAL_USER : ZohoCheckError.MISSING_CRM_ID_IN_LOCAL_OWNER,
          values_to_check: { user_id: <number>null },
        });
      }

      return this.user_id$$.pipe(map(user_id => ({ error: <ZohoCheckError>null, values_to_check: { user_id } })));
    }),
    switchMap(({ error, values_to_check }) => {
      this._checking_valid_value$$.next(true);

      if (!isNil(error) || isNil(values_to_check?.user_id)) {
        return of({
          error:
            error ??
            (this.origin === 'default' ? ZohoCheckError.MISSING_USER_ID_IN_LOCAL_USER : ZohoCheckError.MISSING_USER_ID_IN_LOCAL_OWNER),
          values_to_check: {
            zoho_contact_user_id: <number>null,
            user_id: <number>null,
          },
        });
      }

      return this.zoho_contact_user_id$$.pipe(
        map(zoho_contact_user_id => ({ zoho_contact_user_id, user_id: values_to_check?.user_id })),
        map(({ user_id, zoho_contact_user_id }) => ({
          error: <ZohoCheckError>null,
          values_to_check: { user_id, zoho_contact_user_id },
        }))
      );
    }),
    switchMap(({ error, values_to_check }) => {
      this._checking_valid_value$$.next(true);

      if (!isNil(error)) {
        return of(error);
      }

      if (values_to_check?.zoho_contact_user_id === -1) {
        return of(ZohoCheckError.UNDEFINED_ZOHO_CONTACT);
      }

      if (isNil(values_to_check?.zoho_contact_user_id)) {
        return of(ZohoCheckError.MISSING_USER_ID_IN_ZOHO_CONTACT);
      }

      if (!isEqual(values_to_check?.user_id, values_to_check?.zoho_contact_user_id)) {
        return of(ZohoCheckError.DIFFERENT_IDS);
      }

      return of(ZohoCheckError.EQUAL_IDS);
    }),
    tap(() => this._checking_valid_value$$.next(false)),
    distinctUntilRealChanged(),
    replay()
  );

  // #endregion

  // #region -> (update zoho-Account)

  /** */
  private _is_updating_user_id$$ = new BehaviorSubject(false);

  /** */
  public is_updating_user_id$$ = this._is_updating_user_id$$.asObservable();

  /** */
  public update_zoho_contact_user_id(): void {
    this._is_updating_user_id$$.next(true);

    combineLatest({
      user_id: this.user_id$$,
      zoho_coontact_id: this.user_crm_id$$,
    })
      .pipe(
        waitForNotNilProperties(),
        switchMap(({ zoho_coontact_id, user_id }) =>
          this._zohoCrmApiService.update_contact$(zoho_coontact_id, {
            user_id: user_id?.toString(),
          })
        ),
        take(1)
      )
      .subscribe({
        complete: () => {
          this.refetch_zoho_data.value = true;
          this._is_updating_user_id$$.next(false);
        },
      });
  }

  // #endregion

  /** */
  public has_any_loading$$ = anyTrue(this.checking_valid_value$$, this._is_updating_user_id$$);
}
