export enum CONFIG_EXPORT_FORMAT_TYPE {
  TEXT = '@',

  /**
   * Format a value to a date according to app state dl.lll_xls format
   */
  DATE_FORMAT_LLL = 'DATE_FORMAT_LLL',

  /**
   * Format a value to a number without fractional part.
   *
   * @example formatted -> (-1 || 100 || 45 || 12);
   */
  NUMBER_FORMAT_1 = '0',

  NUMBER_FORMAT_4 = '0.0000',

  CUSTOM_IMEI = '000000000000000',
  CUSTOM_DECIBEL_LEVEL = '0 "dB"',
  CUSTOM_AVG_PERCENT = '"~" 0%',
  CUSTOM_ELEVATION = '0 "m"',
  CUSTOM_KILOGRAM_2 = '0.00 "kg"',
  CUSTOM_LITER_2 = '0.00 "L"',

  UNIT_METERS_2 = '0.00 "m"',

  UNIT_VOLT_2 = '0.00 "V"',
  UNIT_DECIBEL_2 = '0.00 "dB"',
  UNIT_MILLIMETER_2 = '0.00 "mm"',
  UNIT_DEGREE_CELSIUS_2 = '0.00 "°C"',
  UNIT_APPROX_PERCENT_2 = '"~" 0.00%',
  UNIT_KILOGRAM_2 = '0.00 "kg"',
  UNIT_HPA_2 = '0.00 "hPa"',

  UNIT_KILOMETER_PER_HOUR_0 = '0 "km/h"',
  UNIT_KILOMETER_PER_HOUR_1 = '0.0 "km/h"',
  UNIT_KILOMETER_PER_HOUR_2 = '0.00 "km/h"',

  UNIT_DEGREE_0 = '0 "°"',
  UNIT_DEGREE_1 = '0.0 "°"',
  UNIT_DEGREE_2 = '0.00 "°"',

  MONEY_EURO = '_-[$€-x-euro2] * #,##0.00_-;-[$€-x-euro2] * #,##0.00_-;_-[$€-x-euro2] * "-"??_-;_-@_-',
}
