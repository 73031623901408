import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';

import { flatten, isNil, uniq } from 'lodash-es';

import { replay, waitForNotNilValue } from '@bg2app/tools/rxjs';
import { BehaviorSubject, catchError, forkJoin, map, Observable, of, Subscription, switchMap, take, tap } from 'rxjs';

import { User as SwaggerUserInterface } from '../../api-swagger/user-v2/model/user';
import { UsersService as SwaggerUsersApi } from '../../api-swagger/user-v2/api/users.service';

import { BeeguardAuthService } from 'app/core/auth/beeguard-auth.service';
import { ZohoApisService } from 'app/core/services/zoho/zoho-apis.service';
import { InputChangePassword, InputCreateUser, InputUpdateUser } from 'app/core/api-swagger/user-v2';

import { ApiCache } from '../misc/api-cache';
import { PagingQuery, User } from 'app/models';
import { HttpBatch } from 'app/models/http-batch';
import { RequestInQueue } from 'app/models/http-batch/interfaces/request-in-queue';
import { RequestsToBatchByQueryParameters } from 'app/models/http-batch/interfaces/request-to-batch-by-qparams';

import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root',
})
export class UsersApiService extends SwaggerUsersApi implements OnDestroy {
  // #region -> (service basics)

  /** */
  private _impersonate_id_sub: Subscription = null;
  private impersonate_id: string = null;
  private impersonate_scopes: string = null;

  private _headers_token_is_set$ = new BehaviorSubject<boolean>(null);
  private wait_headers_token_is_set$$ = this._headers_token_is_set$.pipe(waitForNotNilValue(), replay());

  constructor(private _authService: BeeguardAuthService, protected http: HttpClient, public zohoApis: ZohoApisService) {
    super(http, environment.UserApiUrl, null);

    this._impersonate_id_sub = this._authService.authentication_data$$.subscribe({
      next: ({ access_token, impersonate }) => {
        let headers = new HttpHeaders({
          'Content-Type': 'application/json',
          Authorization: `Bearer ${access_token}`,
        });

        this.impersonate_id = impersonate?.user_id?.toString() || null;
        this.impersonate_scopes = impersonate?.scopes?.join(' ') || '';
        this.defaultHeaders = headers;
        this.activate_impersonate();
        this._headers_token_is_set$.next(true);
      },
    });
  }

  private activate_impersonate() {
    if (this.impersonate_id) {
      let headers = this.defaultHeaders;
      headers = headers.set('ImpersonateId', this.impersonate_id);
      headers = headers.set('ImpersonateScope', this.impersonate_scopes);
      this.defaultHeaders = headers;
    } else {
      this.unactivate_impersonate();
    }
  }

  private unactivate_impersonate() {
    let headers = this.defaultHeaders;
    headers = headers.delete('ImpersonateId');
    headers = headers.delete('ImpersonateScope');
    this.defaultHeaders = headers;
  }

  ngOnDestroy(): void {
    this._impersonate_id_sub?.unsubscribe();
  }

  // #endregion

  // #region -> (user cache management)

  /** */
  private _cache = new ApiCache<User, number>();

  // #endregion

  // #region -> (reload management)

  /** */
  private _reload$$ = new BehaviorSubject(false);

  /** */
  public reload$$ = this._reload$$.asObservable();

  /** */
  private reload(): void {
    this._reload$$.next(true);
  }

  // #endregion

  // #region -> (create methods)

  /**
   * Create a new user.
   *
   * @public
   */
  public create_user$(user_model: InputCreateUser, no_impersonate = false) {
    return this.wait_headers_token_is_set$$.pipe(
      switchMap(() => {
        if (no_impersonate) {
          this.unactivate_impersonate();
        } else {
          this.activate_impersonate();
        }
        return super.createUser(user_model);
      }),
      tap(() => this.reload())
    );
  }

  // #endregion

  // #region -> (read methods)

  /** */
  private fetch_user_batcher = new HttpBatch<SwaggerUserInterface, { no_impersonate?: boolean; only?: string[] }>({
    logger: {
      name: 'HTTP_BATCH_FETCH_USER',
      active: false,
    },
    batch_requests: requests_by_query_parameters => {
      const queries$$ = requests_by_query_parameters.map(request => {
        const ids_aoa = request.requests.map(req => req.object_ids);
        const ids = flatten(ids_aoa);

        return this.fetch_users$(
          { user_id__in: uniq(ids) },
          { offset: 0, limit: -1 },
          undefined,
          request?.params?.no_impersonate,
          request?.params?.only
        );
      });

      const query_entities$$ = this.wait_headers_token_is_set$$.pipe(
        switchMap(() => forkJoin(queries$$)),
        map(responses => responses.map(response => response.users))
      );

      const dispatch_entity_by_request$$ = query_entities$$.pipe(
        map(entities => {
          const dispatched_response: RequestInQueue<SwaggerUserInterface, { no_impersonate?: boolean; only?: string[] }>[] = [];

          requests_by_query_parameters.forEach(
            (
              current: RequestsToBatchByQueryParameters<SwaggerUserInterface, { no_impersonate?: boolean; only?: string[] }>,
              index: number
            ) => {
              const responses_for_index = entities[index];

              current.requests.forEach(request => {
                let user_response: SwaggerUserInterface =
                  responses_for_index.find(response => response?.user_id === request?.object_ids?.[0]) ?? null;

                if (isNil(user_response)) {
                  user_response = {
                    user_id: request?.object_ids?.[0],
                    username: `user#${request?.object_ids?.[0]}`,
                  };
                }

                dispatched_response.push({
                  ...request,
                  response: user_response,
                });
              });
            }
          );

          return dispatched_response;
        })
      );

      return dispatch_entity_by_request$$;
    },
  });

  /**
   * Fetches a specific user.
   *
   * @param user_id ID of the user to fetch.
   *
   * @returns Returns the deserialized fetched user.
   */
  public fetch_user$(user_id: number, no_impersonate = false, only: string[] = undefined): Observable<User> {
    if (isNil(user_id)) {
      throw new Error('User ID should not be nil !');
    }

    return this.fetch_user_batcher.add_and_wait_request(user_id, { no_impersonate, only }).pipe(
      catchError((error: unknown) => {
        const partial_user: SwaggerUserInterface = { user_id, username: `user#${user_id}` };
        return of(partial_user);
      }),
      map(user_interface => this.deserialize(user_interface))
    );
  }

  /** */
  public fetch_users$(
    query?: { [key: string]: any },
    pagination?: PagingQuery,
    sort?: string[],
    no_impersonate = false,
    only: string[] = undefined
  ) {
    return this.wait_headers_token_is_set$$.pipe(
      take(1),
      switchMap(() => {
        if (no_impersonate) {
          this.unactivate_impersonate();
        } else {
          this.activate_impersonate();
        }

        return super.listUsers(JSON.stringify(query ?? {}), only, undefined, pagination?.offset, pagination?.limit, sort);
      }),
      map(users_response => ({
        users: users_response.users.map(user => this.deserialize(user)),
        paging: users_response.paging,
        params: { query, sort },
      }))
    );
  }

  /**
   * Fetches a list of users.
   *
   * This version is based on reload. For a one-shot, prefer usage of {@link fetch_users$}.
   *
   * @public
   */
  public fetch_users$$(
    query?: { [key: string]: any },
    pagination?: PagingQuery,
    sort?: string[],
    no_impersonate = false,
    only: string[] = undefined
  ) {
    return this.reload$$.pipe(
      switchMap(() => this.fetch_users$(query, pagination, sort, no_impersonate, only)),
      replay()
    );
  }

  // #endregion

  // #region -> (update methods)

  /** */
  public update_user$(user_id: number, user_model: InputUpdateUser) {
    return super.updateUser(user_model ?? {}, user_id).pipe(
      map(user_response => user_response?.user),
      map(user_interface => this.deserialize(user_interface))
    );
  }

  /** */
  public update_password$(user_id: number, passwords: InputChangePassword) {
    return super.updateUserPassword(passwords, user_id).pipe(map(user_response => user_response?.user));
  }

  // #endregion

  // #region -> (delete methods)

  /** */
  public delete_user$(user_id: number) {
    return super.deleteUser(user_id).pipe(tap(() => this.reload()));
  }

  // #endregion

  /** */
  public getUserObj(id: number, handle403: boolean = false): Observable<User> {
    this.activate_impersonate();

    return this.fetch_user$(id);
  }

  /**
   * @deprecated better use fetch_users$
   */
  public getAllUsersObj(): Observable<User[]> {
    this.activate_impersonate();

    return this.fetch_users$({}, { limit: -1, offset: 0 }).pipe(map(users_response => users_response.users));
  }

  /** */
  public handleUnauthorizedError(err: any) {
    this._authService.handleUnauthorizedError(err);
  }

  /** */
  public deserialize(input: SwaggerUserInterface): User {
    if (this._cache.has(input.user_id)) {
      let user_object = this._cache.get(input.user_id);
      return user_object.deserialize(input);
    }

    let new_user = new User(this);
    new_user = new_user.deserialize(input);

    this._cache.add(new_user.user_id, new_user);

    return new_user;
  }

  public getNameFiltersOr(name_filter: string) {
    const name_filtering = [
      {
        first_name__icontains: name_filter,
      },
      {
        last_name__icontains: name_filter,
      },
      {
        username__icontains: name_filter,
      },
    ];
    return name_filtering;
  }
}
