import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule, Provider } from '@angular/core';

import { TooltipDirective } from './tooltip.directive';

import { TooltipContentComponent } from './tooltip-content/tooltip-content.component';

import { TooltipOptions } from './interfaces/tooltip-options.model';
import { TOOLTIP_OPTIONS_TOKEN } from './tokens/tooltip-options-token';

export function provideTooltipOptions(config: TooltipOptions = {}): Provider[] {
  return [{ provide: TOOLTIP_OPTIONS_TOKEN, useValue: config }];
}

@NgModule({
    imports: [CommonModule],
    declarations: [TooltipContentComponent, TooltipDirective],
    exports: [TooltipContentComponent, TooltipDirective],
    providers: [provideTooltipOptions()]
})
export class TooltipModule {
  public static forRoot(popperBaseOptions?: TooltipOptions): ModuleWithProviders<TooltipModule> {
    return {
      ngModule: TooltipModule,
      providers: [provideTooltipOptions(popperBaseOptions)],
    };
  }
}
