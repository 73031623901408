/** */
export enum ExploitationEntityUserAce {
  /** */
  'write_all',

  /** */
  'read_minimal',

  /** */
  'read',

  /** */
  'read_aproximate_position',

  /** */
  'read_precisse_position',

  /** */
  'read_measurements_data',

  /** */
  'read_all_events',

  /** */
  'write_all_events',

  /** */
  'read_devices',

  /** */
  'read_devices_last_position',

  /** */
  'read_devices_routes',

  /** */
  'read_devices_data',

  /** */
  'write_move_authorization',

  /** */
  'write_devices_notifications',

  /** */
  'write_devices_configuration',
}

/** */
export const exploitation_user_ace_to_i18n: Map<keyof typeof ExploitationEntityUserAce, string> = new Map([
  ['read', 'ENTITY.ALL.SCOPE.Access to the exploitation (gives read access to all locations)'],
  ['read_all_events', 'ENTITY.ALL.SCOPE.Access all events'],
  ['read_aproximate_position', 'ENTITY.ALL.SCOPE.Read approximate position of locations'],
  ['read_devices', 'ENTITY.ALL.SCOPE.Access to the warehouse (and associated devices)'],
  ['read_devices_data', 'ENTITY.ALL.SCOPE.Access device raw data'],
  ['read_devices_last_position', 'ENTITY.ALL.SCOPE.Read last precise GPS position of associated devices'],
  ['read_devices_routes', 'ENTITY.ALL.SCOPE.Access trips of associated devices'],
  ['read_measurements_data', 'ENTITY.ALL.SCOPE.Access all measurements data'],
  ['read_minimal', 'ENTITY.ALL.SCOPE.Minimal access to the exploitation (No access to locations)'],
  ['read_precisse_position', 'ENTITY.ALL.SCOPE.Read precisse position of locations'],
  ['write_all', 'ENTITY.ALL.SCOPE.Full read/write acces to the exploitation and associated location, apiary, hives and devices'],
  ['write_all_events', 'ENTITY.ALL.SCOPE.Create and modify all events'],
  ['write_devices_configuration', 'ENTITY.ALL.SCOPE.Configure devices associated to the location'],
  ['write_devices_notifications', 'ENTITY.ALL.SCOPE.Configure custom notifications on associated devices'],
  ['write_move_authorization', 'ENTITY.ALL.SCOPE.Configure movement authorizations for associated devices'],
]);
