<div [id]="widget_id" class="bg2-zoho-search-crm-widget-component" *ngIf="visible$$ | async">
  <form-element-header [schema]="schema" [showLabel]="false" [title_style]="options.title_style"></form-element-header>

  <div
    *ngIf="fullscreen_select_helper?.is_open_in_fullscreen.value$$ | async"
    id="fulscreen-select-blocker"
    (click)="fullscreen_select_helper.close(true)"
  ></div>

  <div>
    <img src="/assets/picons/zoho/zoho-crm-logo.svg" alt="" />

    <mat-form-field [appearance]="'outline'" [class.fullscreen]="fullscreen_select_helper?.is_open_in_fullscreen.value$$ | async">
      <mat-label>{{ schema.label | translate }}</mat-label>

      <input *ngIf="options?.readonly; else editableField" matInput type="text" [readonly]="true" [value]="value$$ | async" />

      <ng-template #editableField>
        <ng-template #offlineField>
          <input matInput type="text" [value]="value$$ | async" [disabled]="true" />
        </ng-template>

        <ng-container *ngIf="is_authenticated$$ | async; else offlineField">
          <mtx-select
            *ngIf="options?.readonly === false"
            #select
            class="bg2-zoho-search-crm-widget-component__select"
            [bindValue]="'id'"
            [searchable]="true"
            [items]="users$$ | async"
            [typeahead]="raw_filter$$"
            [dropdownPosition]="'bottom'"
            [loading]="is_loading$$ | async"
            [ngModel]="selected_value_id$$ | async"
            [placeholder]="placeholder | translate"
            [appendTo]="'div#' + widget_id + ' mat-form-field .mat-form-field-wrapper'"
            (ngModelChange)="value = $event"
            (open)="fullscreen_select_helper.open()"
            (close)="fullscreen_select_helper.close()"
          >
            <!-- Template for header -->
            <ng-template ng-header-tmp>
              <div class="ng-option disabled">
                <span>{{ current_loaded_sentence$$ | async }}</span>
              </div>
            </ng-template>

            <!-- Template for loading items  -->
            <ng-template ng-loadingtext-tmp let-searchTerm="searchTerm">
              <div class="ng-option disabled">
                <span *ngIf="searchTerm">"{{ searchTerm }}"</span>
                <span translate>{{ 'ALL.COMMON.Loading' | translate }}</span>
              </div>
            </ng-template>

            <!-- Template for not found item -->
            <ng-template ng-notfound-tmp let-searchTerm="searchTerm">
              <div class="ng-option disabled">{{ 'WIDGETS.EVENT_FORM.ZOHO_SEARCH.No data found' | translate }}</div>
            </ng-template>

            <!-- Template for label item -->
            <ng-template ng-label-tmp let-object="item">
              <ng-container *ngIf="object">
                <span *ngIf="options?.zoho_search_config?.search_in === 'Accounts'">{{ object?.Account_Name }}</span>
                <span *ngIf="options?.zoho_search_config?.search_in === 'Contacts'">{{ object?.First_Name }} {{ object?.Last_Name }}</span>

                <span>({{ 'CRM ID' | colon }}{{ object?.id }})</span>
              </ng-container>
            </ng-template>

            <!-- Template for group? item inside the dropdown -->
            <!-- <ng-template ng-optgroup-tmp let-user="item" let-index="index">
              {{ user.name$$ | async }}
            </ng-template> -->

            <!-- Template for each item inside the dropdown -->
            <ng-template ng-option-tmp let-object="item" let-index="index" let-search="searchTerm">
              <div *ngIf="object" class="ng-option-template template-for-{{ options?.zoho_search_config?.search_in ?? 'default' }}">
                <ng-container *ngIf="options?.zoho_search_config?.search_in === 'Accounts'">
                  <ng-container *ngLet="asType(object, 'Accounts'); let account">
                    <div class="account-name">
                      <span class="mdi mdi-factory"></span>
                      <span [ngOptionHighlight]="search">{{ account?.Account_Name }}</span>
                      <span>
                        <span>(CRM ID : </span>
                        <a
                          href="https://crm.zoho.eu/crm/org20067795631/tab/Accounts/{{ account?.id }}"
                          target="_blank"
                          (click)="$event.stopPropagation()"
                        >
                          #{{ account?.id }}
                        </a>
                        <span>)</span>
                      </span>
                    </div>
                  </ng-container>
                </ng-container>

                <ng-container *ngIf="options?.zoho_search_config?.search_in === 'Contacts'">
                  <ng-container *ngLet="asType(object, 'Contacts'); let contact">
                    <div class="contact-name">
                      <span class="mdi mdi-account"></span>
                      <span [ngOptionHighlight]="search">{{ contact?.Full_Name }}</span>
                      <span>
                        <span>(CRM ID : </span>
                        <a
                          href="https://crm.zoho.eu/crm/org20067795631/tab/Contacts/{{ contact?.id }}"
                          target="_blank"
                          (click)="$event.stopPropagation()"
                        >
                          #{{ contact?.id }}
                        </a>
                        <span>)</span>
                      </span>
                    </div>

                    <div class="contact-account">
                      <span class="mdi mdi-factory"></span>

                      <ng-container *ngIf="contact?.Account_Name?.name; let account_name; else: errorContactWithoutAccount">
                        <span>{{ account_name }}</span>
                        <span>
                          <span>(CRM ID : </span>
                          <a
                            href="https://crm.zoho.eu/crm/org20067795631/tab/Accounts/{{ contact?.Account_Name?.id }}"
                            target="_blank"
                            (click)="$event.stopPropagation()"
                          >
                            #{{ contact?.Account_Name?.id }}
                          </a>
                          <span>)</span>
                        </span>
                      </ng-container>

                      <ng-template #errorContactWithoutAccount>
                        <span class="error">{{ 'MODELS.ZOHO.ERROR.CRM_CONTACT_WITHOUT_ACCOUNT' | translate }}</span>
                      </ng-template>
                    </div>
                  </ng-container>
                </ng-container>
              </div>
            </ng-template>
          </mtx-select>
        </ng-container>
      </ng-template>
    </mat-form-field>

    <ng-container *ngLet="is_authenticated$$ | async; let is_authenticated">
      <bg2-button
        [type]="'action'"
        [options]="{ is_thin: true }"
        [color]="is_authenticated ? 'green' : 'warn'"
        [icon]="is_authenticated ? 'cs-/picons/api-link-on.svg' : 'cs-/picons/api-link-off.svg'"
        [popper]="(is_authenticated ? 'MODELS.ZOHO.AUTH.Connected' : 'MODELS.ZOHO.AUTH.Not connected') | translate"
        (btn_click)="is_authenticated ? null : login()"
      ></bg2-button>
    </ng-container>
  </div>
</div>
