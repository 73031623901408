import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { SchemaFormModule } from 'ngx-schema-form';

import { MiscModule } from 'app/misc/misc.module';

import { SharedModule } from 'app/shared-module/shared.module';
import { MaterialSharedModule } from 'app/shared-module/material-shared.module';
import { MiscWidgetsModule } from 'app/widgets/misc-widgets/misc-widgets.module';
import { DialogsModalsModule } from 'app/widgets/dialogs-modals/dialogs-modals.module';
import { WidgetsReusableModule } from 'app/widgets/widgets-reusables/widgets-reusables.module';

import { Bg2ButtonDeleteOrArchiveEntityComponent } from './shared/button-delete-or-archive-entity/button-delete-or-archive-entity.component';

@NgModule({
  declarations: [Bg2ButtonDeleteOrArchiveEntityComponent],
  imports: [
    MiscModule,
    CommonModule,
    RouterModule,
    SharedModule,
    SchemaFormModule,
    SchemaFormModule,
    MiscWidgetsModule,
    DialogsModalsModule,
    MaterialSharedModule,
    WidgetsReusableModule,
  ],
  exports: [Bg2ButtonDeleteOrArchiveEntityComponent],
})
export class EntitiesSharedModule {}
