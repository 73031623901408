<bg2-modal class="apiary-evaluation-v2-modal" [fullscreen]="fullscreen || false" #modal>
  <bg2-modal-header>
    <div class="action-buttons" *ngIf="evaluation_manager?.can_go_back$$ | async">
      <!-- Retour à l'étape précédente -->
      <span class="mdi mdi-arrow-left mdi-36px" (click)="evaluation_manager?.visit_state_machine.transition('go_back')"></span>
    </div>

    <div class="header">
      <span [ngSwitch]="evaluation_manager?.visit_state$$ | async" class="title">
        <ng-container *ngSwitchCase="'home'">{{ (apiary$$ | async)?.name$$ | async }}</ng-container>

        <span>{{ evaluation_manager?.visit_state$$ | async }}</span>

        <!-- <ng-container *ngSwitchCase="'hive_visit_config'">Choix de la visite</ng-container>

        <ng-container *ngSwitchCase="'apiary_visit'">Visite du Rucher</ng-container>

        <ng-container *ngSwitchCase="'hive_identified_visit'">Ruche id + nom eval</ng-container>

        <ng-container *ngSwitchCase="'hive_not_identified_visit'">Visite rapide</ng-container>

        <ng-container *ngSwitchCase="'hive_identified_selection'">Sélection des ruches</ng-container>

        <ng-container *ngSwitchCase="'hive_nuc_count_selection'">Sélection des ruches</ng-container>

        <ng-container *ngSwitchCase="'evaluation_selection'">Sélection des évaluations</ng-container>

        <ng-container *ngSwitchDefault>{{ 'VIEWS.MODALS.EVAL.Apiary Visit' | translate }}</ng-container> -->
      </span>
    </div>

    <div class="action-buttons">
      <!-- <doc-helper page="Visit_and_evaluation" iconSize="36px"></doc-helper> -->

      <!-- Retour à l'accueil en faisant une sauvegarde avec le statut "en cours" -->
      <ng-container *ngIf="evaluation_manager?.is_visiting$$ | async">
        <span class="mdi mdi-content-save-move mdi-36px" (click)="evaluation_manager?.visit_state_machine.transition('save_visit')"></span>
      </ng-container>

      <ng-container *ngIf="evaluation_manager?.is_configuring$$ | async"></ng-container>

      <ng-container *ngIf="(evaluation_manager?.visit_state$$ | async) === 'event_config'">
        <span class="mdi mdi-close-box mdi-36px" (click)="close()"></span>
      </ng-container>
    </div>
  </bg2-modal-header>

  <bg2-modal-content>
    <!-- Main Content -->
    <div class="visit-modal__frames-container">
      <ng-container *ngLet="evaluation_manager.visit_state$$ | async; let visit_state">
        <!-- Hive by hive navigation -->
        <div class="visit-modal__frames-container__hive-by-hive-navigation" #hivesNav>
          <bg2-navigate-identified-hives
            (wheel)="onWheel($event)"
            *ngIf="visit_state === 'hive_by_hive_identified_visit'"
            [hives]="hives_to_visit$$ | async"
            (when_hive_id_is_selected)="hive_identified_visit__current_id = $event"
          ></bg2-navigate-identified-hives>

          <bg2-navigate-not-identified-hives
            *ngIf="visit_state === 'hive_by_hive_not_identified_visit'"
            [total_hives]="hive_not_identified_visit__hives__total$$ | async"
            [current_index]="hive_not_identified_visit__current_index$$ | async"
            (current_index_change)="hive_not_identified_visit__current_index = $event"
          ></bg2-navigate-not-identified-hives>
        </div>

        <!-- Aspects navigation -->
        <div
          *ngIf="evaluation_manager?.is_visiting$$ | async"
          class="visit-modal__frames-container__aspects-navigation"
          [style.height]="'calc(100% - ' + hivesNav.clientHeight + 'px)'"
        >
          <bg2-navigate-visit-aspects
            (wheel)="onWheel($event)"
            [aspects]="visit_aspects$$ | async"
            [current_aspect]="evaluation_manager.visit_aspect$$ | async"
            (when_aspect_changed)="select_aspect_to_visit = $event"
          ></bg2-navigate-visit-aspects>
        </div>
      </ng-container>

      <!-- Visit frames -->
      <div
        class="visit-modal__frames-container__visit-frame"
        [ngClass]="{ '--is-visiting': evaluation_manager?.is_visiting$$ | async }"
        [ngSwitch]="evaluation_manager?.visit_state$$ | async"
      >
        <!--  -->
        <div *ngSwitchCase="'event_config'" class="visit-state--event-config">
          <bg2-form-overlay
            #visitConfig
            [source_model]="visit_config__model$$ | async"
            [source_schema]="event_config__schema$$ | async"
            (when_form_data_update)="update_model_for_visit_config($event)"
          ></bg2-form-overlay>

          <div class="visit-summary">
            <div class="visit-summary__assessment">
              <div class="visit-summary__assessment__header">
                <img src="assets/img/eval/apiary.png" alt="" />

                <span class="visit-summary__assessment__header__title">
                  {{ 'ROUTABLE_MODALS.EVALUATION_V2.Overall assessment' | translate }}
                </span>
              </div>

              <div class="visit-summary__assessment__definition">
                <span class="mdi mdi-24px mdi-help-circle"></span>

                <span class="visit-summary__assessment__definition__text">
                  <span>What is the overall assessment ?</span>
                  <span>
                    Lorem ipsum dolor sit, amet consectetur adipisicing elit. Enim iusto recusandae autem, doloremque fugit ab maxime, harum
                    ut mollitia similique modi eos eum corporis accusantium numquam. Esse exercitationem eveniet optio.
                  </span>
                </span>
              </div>

              <div class="visit-summary__assessment__start-assessment">
                <bg2-button
                  [type]="'action'"
                  [icon]="'mdi-ray-start-arrow'"
                  [message]="'ROUTABLE_MODALS.EVALUATION_V2.Start the overall assessment'"
                  (btn_click)="evaluation_manager?.visit_state_machine.transition('configure_overall_visit_aspects')"
                ></bg2-button>
              </div>

              <div class="visit-summary__assessment__summary">
                <pre>event_data$$.overall_visit: {{ (event_data$$ | async)?.visit_data?.overall_visit ?? null | json }}</pre>
              </div>
            </div>

            <mat-divider></mat-divider>

            <div class="visit-summary__assessment">
              <div class="visit-summary__assessment__header">
                <img src="assets/img/hive.png" alt="" />

                <span class="visit-summary__assessment__header__title">
                  {{ 'ROUTABLE_MODALS.EVALUATION_V2.Hive by hive assessment' | translate }}
                </span>
              </div>

              <div class="visit-summary__assessment__definition">
                <span class="mdi mdi-24px mdi-help-circle"></span>

                <span class="visit-summary__assessment__definition__text">
                  <span>What is the hive by hive assessment ?</span>
                  <span>
                    Lorem ipsum dolor sit, amet consectetur adipisicing elit. Enim iusto recusandae autem, doloremque fugit ab maxime, harum
                    ut mollitia similique modi eos eum corporis accusantium numquam. Esse exercitationem eveniet optio.
                  </span>
                </span>
              </div>

              <div class="visit-summary__assessment__start-assessment">
                <bg2-button
                  [type]="'action'"
                  [icon]="'mdi-ray-start-arrow'"
                  [message]="'ROUTABLE_MODALS.EVALUATION_V2.Start the hive by hive assessment'"
                  (btn_click)="evaluation_manager?.visit_state_machine.transition('configure_hive_by_hive_visit')"
                ></bg2-button>
              </div>

              <div class="visit-summary__assessment__summary">
                <pre>hive_not_identified_visit$$: {{ (hive_identified_visit__model__full$$ | async) ?? null | json }}</pre>
                <pre>hive_identified_visit$$: {{ (hive_not_identified_visit__model__full$$ | async) ?? null | json }}</pre>
              </div>
            </div>
          </div>
        </div>

        <!--  -->
        <div *ngSwitchCase="'overall_visit_aspects_config'" class="visit-state--overall-aspects-config">
          <bg2-form-overlay
            #overallAspectsConfig
            [source_model]="aspects_config__model$$ | async"
            [source_schema]="aspects_config__schema$$ | async"
            (when_form_data_update)="update_model_for_aspects_config($event)"
          ></bg2-form-overlay>

          <bg2-button
            [type]="'action'"
            [message]="'start visit'"
            (btn_click)="evaluation_manager?.visit_state_machine?.transition('do_overall_visit')"
          ></bg2-button>
        </div>

        <!--  -->
        <div *ngSwitchCase="'hive_by_hive_config'" class="visit-state--hive-by-hive-config">
          <bg2-form-overlay
            [source_model]="hive_by_hive_config__model$$ | async"
            [source_schema]="hive_by_hive_config__schema$$ | async"
            (is_schema_valid)="is_hive_by_hive_config_valid = $event"
            (when_form_data_update)="update_model_for_hive_by_hive_config($event)"
          ></bg2-form-overlay>

          <bg2-button
            [type]="'action'"
            [disabled]="(is_hive_by_hive_config_valid$$ | async) === false"
            [message]="'ROUTABLE_MODALS.VISIT_MODAL.ACTIONS.Select aspects to visit' | translate"
            (btn_click)="evaluation_manager?.visit_state_machine?.transition('configure_hive_by_hive_visit_aspects')"
          ></bg2-button>
        </div>

        <!--  -->
        <div *ngSwitchCase="'hive_by_hive_aspects_config'" class="visit-state--hive-by-hive-aspects-config">
          <bg2-form-overlay
            [source_model]="aspects_config__model$$ | async"
            [source_schema]="aspects_config__schema$$ | async"
            (is_schema_valid)="is_aspects_config_valid = $event"
            (when_form_data_update)="update_model_for_aspects_config($event)"
          ></bg2-form-overlay>

          <bg2-button
            [type]="'action'"
            [disabled]="(is_aspects_config_valid$$ | async) === false"
            [message]="'ROUTABLE_MODALS.VISIT_MODAL.ACTIONS.Start the visit' | translate"
            (btn_click)="start_hive_by_hive_visit()"
          ></bg2-button>
        </div>

        <!-- Visit management -->
        <ng-container *ngIf="evaluation_manager?.is_visiting$$ | async">
          <div class="apiary-visit-state-visit">
            <!-- Header -->

            <!-- Body -->
            <div class="widget-form" *ngLet="evaluation_manager.visit_state$$ | async; let visit_state">
              <span *ngIf="visit_state === 'overall_visit'">
                <pre>apiary_visit__model$$: {{ apiary_visit__model$$ | async | json }}</pre>
              </span>

              <span *ngIf="visit_state === 'hive_by_hive_identified_visit'">
                <pre>current_hive_model$$: {{ hive_identified_visit__model$$ | async | json }}</pre>
              </span>

              <span *ngIf="visit_state === 'hive_by_hive_not_identified_visit'">
                <pre>current_hive_model$$: {{ hive_not_identified_visit__model__for_current$$ | async | json }}</pre>
              </span>

              <bg2-form-overlay
                *ngIf="visit_state === 'overall_visit'"
                [source_model]="apiary_visit__model$$ | async"
                [source_schema]="reload_schema && overall_visit_schema$$ | async"
                (when_form_data_update)="update_model_for_apiary_visit($event)"
              ></bg2-form-overlay>

              <bg2-form-overlay
                *ngIf="visit_state === 'hive_by_hive_identified_visit'"
                [source_model]="hive_identified_visit__model$$ | async"
                [source_schema]="reload_schema && hive_by_hive_identified_schema$$ | async"
                [form_bind]="hive_identified_visit__form_binder.should_bind_form$$ | async"
                (is_form_bind)="hive_identified_visit__form_binder.is_form_bind = $event"
                (when_form_data_update)="update_model_for_hive_identified_visit($event)"
              ></bg2-form-overlay>

              <bg2-form-overlay
                *ngIf="visit_state === 'hive_by_hive_not_identified_visit'"
                [source_schema]="reload_schema && hive_by_hive_not_identified_schema$$ | async"
                [source_model]="hive_not_identified_visit__model__for_current$$ | async"
                [form_bind]="hive_not_identified_visit__form_binder.should_bind_form$$ | async"
                (is_form_bind)="hive_not_identified_visit__form_binder.is_form_bind = $event"
                (when_form_data_update)="update_model_for_hive_not_identified_visit($event)"
              ></bg2-form-overlay>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </bg2-modal-content>
</bg2-modal>
