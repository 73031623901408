import { isNil, isEmpty } from 'lodash-es';

import { BehaviorSubject, map } from 'rxjs';
import { replay } from '@bg2app/tools/rxjs';


import { IZohoCRMContact } from '../interfaces';
import { ZohoBaseModel } from '../../../../abstract';

/** */
export class ZohoCRMContact extends ZohoBaseModel<IZohoCRMContact> {
  // #region -> (model basics)

  constructor() {
    super();
  }

  // #endregion

  // #region -> (contact additional infos)

  /** */
  protected _user_id$$ = new BehaviorSubject<string>(null);

  /** */
  public user_id$$ = this._user_id$$.asObservable();

  /** */
  public set user_id(user_id: string) {
    this._user_id$$.next(user_id);
  }

  /** */
  public get user_id(): string {
    return this._user_id$$.getValue();
  }

  /** */
  protected _email$$ = new BehaviorSubject<string>(null);

  /** */
  public email$$ = this._email$$.asObservable();

  /** */
  public set Email(Email: string) {
    this._email$$.next(Email);
  }

  /** */
  public get Email(): string {
    return this._email$$.getValue();
  }

  // #endregion

  // #region -> (contact name)

  /** */
  protected _full_name$$ = new BehaviorSubject<string>(null);

  /** */
  public full_name$$ = this._full_name$$.asObservable();

  /** */
  public set Full_Name(full_name: string) {
    this._full_name$$.next(full_name);
  }

  /** */
  public get Full_Name(): string {
    return this._full_name$$.getValue();
  }

  // #endregion

  // #region -> (links)

  /** */
  public url$$ = this.id$$.pipe(
    map(id => {
      if (isNil(id) || isEmpty(id)) {
        return null;
      }

      return `https://crm.zoho.eu/crm/org20067795631/tab/Contacts/${id}`;
    }),
    replay()
  );

  // #endregion
}
