import { ActivatedRoute } from '@angular/router';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';

import { marker as i18n } from '@biesbjerg/ngx-translate-extract-marker';

import { isNil } from 'lodash-es';

import { BehaviorSubject, filter, map, switchMap, take, tap } from 'rxjs';

import { BeeguardAuthService } from 'app/core';
import { AppStateService } from 'app/core/app-state.service';
import { ENV } from 'app/core/providers/environment.provider';

import { SameValueValidator } from 'app/misc/tools/misc/form-validators';

import { IEnvironment } from 'environments/common';

/** */
type ResetPasswordState = 'input_mail' | 'mail_sent' | 'renew_password';

@Component({
  selector: 'bg2-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ResetPasswordComponent implements OnInit {
  // #region -> (component basics)

  /** */
  constructor(
    @Inject(ENV) public readonly env: IEnvironment,
    private readonly _activated_route: ActivatedRoute,
    private readonly _auth_service: BeeguardAuthService,
    private readonly _app_state_service: AppStateService
  ) {}

  /** */
  ngOnInit(): void {
    this._auth_service.logout(false);

    this._activated_route.queryParams
      .pipe(
        map(parameters => <string>parameters.token),
        filter(token => !isNil(token)),
        take(1),
        tap(dd => console.log(dd)),
        switchMap(token => this._auth_service.check_reset_password_token(token))
      )
      .subscribe({
        next: response => {
          this._reset_token$$.next(response.token);
          this.renew_password_form.patchValue({ email: response.email });

          this._current_state$$.next('renew_password');
        },
        error: (error: unknown) => {
          this._error$$.next({ message: (<any>error)?.error?.message ?? i18n<string>('ALL.ERROR.An unknown error occured !') });
        },
      });
  }

  // #endregion

  /** */
  public language$$ = this._app_state_service.lang$$;

  /** */
  public get logo(): string {
    if (this.env.env === 'apismart') {
      return 'assets/logo_apismart.png';
    }

    if (this.env.env === 'prod') {
      return 'assets/logo.svg';
    }

    return `assets/logo_${this.env.env}.svg`;
  }

  /** */
  public show_password = {
    new: false,
    confirm: false,
  };

  // #region -> (error message)

  /** */
  private _error$$ = new BehaviorSubject<{ message: string }>(null);

  /** */
  public error$$ = this._error$$.asObservable();

  // #endregion

  // #region -> (reset password state)

  /** */
  private _current_state$$ = new BehaviorSubject<ResetPasswordState>('input_mail');

  /** */
  public current_state$$ = this._current_state$$.asObservable();

  // #endregion

  // #region -> (reset password form)

  /** */
  public reset_password_form = new UntypedFormGroup({
    mail: new UntypedFormControl(null, [Validators.required]),
  });

  private _reset_in_progress$$ = new BehaviorSubject(false);
  public reset_in_progress$$ = this._reset_in_progress$$.asObservable();

  private set _reset_in_progress(val: boolean) {
    this._reset_in_progress$$.next(val);
  }
  private get _reset_in_progress(): boolean {
    return this._reset_in_progress$$.getValue();
  }

  /** */
  public ask_for_password_reset(): void {
    if (this._reset_in_progress) return;
    this._reset_in_progress = true;
    const { mail } = <{ mail: string }>this.reset_password_form.value;

    this._auth_service.ask_reset_password_token(mail).subscribe({
      next: value => {
        this._error$$.next(null);
        this._current_state$$.next('mail_sent');
        this._reset_in_progress = false;
      },
      error: (error: unknown) => {
        const message = (error as any).error?.message ?? i18n<string>('ALL.ERROR.An unknown error occured !');
        this._error$$.next({ message });
        this._reset_in_progress = false;
      },
    });
  }

  // #endregion

  // #region -> (new password management)

  /** */
  private _reset_token$$ = new BehaviorSubject<string>(null);

  /** */
  public reset_token$$ = this._reset_token$$.asObservable();

  /** */
  public renew_password_form = new UntypedFormGroup(
    {
      email: new UntypedFormControl(null, [Validators.required]),
      new_password: new UntypedFormControl(null, [Validators.required]),
      confirm_password: new UntypedFormControl(null, [Validators.required]),
    },
    { validators: [SameValueValidator('new_password', 'confirm_password')] }
  );

  /** */
  public renew_password(): void {
    const { new_password, confirm_password } = <{ new_password: string; confirm_password: string }>this.renew_password_form.value;
    const token = this._reset_token$$.getValue();

    this._auth_service.update_reset_password(confirm_password, token).subscribe({
      next: response => {
        this._error$$.next(null);
        this._auth_service.login_after_reset_password(response);
      },
      error: (error: unknown) => {
        this._error$$.next({ message: (<any>error)?.error?.message ?? i18n<string>('ALL.ERROR.An unknown error occured !') });
      },
    });
  }

  // #endregion
}
