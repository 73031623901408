export * from './00_abstract';
export * from './01_warehouse';
export * from './02_exploitation';
export * from './03_location';
export * from './04_apiary';
export * from './05_hive';
export * from './06_misc';

export * from './misc';

export { Ace as ACE } from 'app/core/api-swagger/beeguard2/model/ace';

import { Beeguard2Api, DeviceApi } from 'app/core';
import { Entity as EntityInterface } from 'app/core/api-swagger/beeguard2/model/entity';

import { Entity, Warehouse, Exploitation, Location, Apiary, Hive, NAEntity } from '@bg2app/models/entities/index';

/**
 * Factory that build an entity from api data
 */
export const entityDeserialize = (bg2Api: Beeguard2Api, deviceApi: DeviceApi, input: EntityInterface): Entity => {
  let object;
  switch (input.type) {
    case 'exploitation': {
      object = new Exploitation(bg2Api, deviceApi);
      break;
    }
    case 'warehouse': {
      object = new Warehouse(bg2Api, deviceApi);
      break;
    }
    case 'location': {
      object = new Location(bg2Api, deviceApi);
      break;
    }
    case 'apiary': {
      object = new Apiary(bg2Api, deviceApi);
      break;
    }
    case 'hive': {
      object = new Hive(bg2Api, deviceApi);
      break;
    }
    default: {
      // statements;
      object = new NAEntity(bg2Api, deviceApi);
      break;
    }
  }
  object.deserialize(input);
  return object;
};
