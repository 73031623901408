import { ChangeDetectionStrategy, Component, Inject, Input } from '@angular/core';

import { isNil, merge, set } from 'lodash-es';

import { isSameDay, subDays } from 'date-fns/esm';

import { BehaviorSubject, map, of, switchMap, take } from 'rxjs';

import { ENV } from 'app/core/providers/environment.provider';
import { IEnvironment } from 'environments/common';
import { AppStateService } from 'app/core/app-state.service';
import { replay, waitForNotNilValue } from '@bg2app/tools/rxjs';
import { Dictionary } from 'app/typings/core/interfaces';
import { DateTime } from 'luxon';
import { DateRangeManager } from 'app/misc/tools/dates/date-range-manager';

/** */
interface IApiaryChartFooterConfig {
  /** */
  show_more_data_btn?: boolean;

  /** */
  show_save_range_tooltip?: boolean;

  /** */
  show_total_WG_sensors_changed?: boolean;
}

@Component({
  selector: 'bg2-apiary-charts-footer',
  templateUrl: './apiary-charts-footer.component.html',
  styleUrls: ['./apiary-charts-footer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ApiaryChartsFooterComponent {
  constructor(@Inject(ENV) public readonly env: IEnvironment, private readonly _app_state_service: AppStateService) {}

  @Input()
  public location_id: number = null;

  @Input()
  public is_compact: boolean = true;

  /** */
  @Input()
  public date_range_manager: DateRangeManager = null;

  /** */
  public is_same_day$$ = (end_date: DateTime) => {
    if (isNil(end_date)) {
      return of(false);
    }

    const previous_day = end_date.minus({ days: 1 });
    const now = DateTime.now().setZone(this.date_range_manager.manager_timezone);

    const is_same_day = previous_day.startOf('day').equals(now.startOf('day'));

    return of(is_same_day);
  };

  // #region -> (legend config)

  /** */
  private _config$$ = new BehaviorSubject<IApiaryChartFooterConfig>({
    show_more_data_btn: true,
    show_save_range_tooltip: false,
    show_total_WG_sensors_changed: false,
  });

  /** */
  public config$$ = this._config$$.asObservable();

  @Input()
  public set config(config: IApiaryChartFooterConfig) {
    this._config$$.next(merge(this._config$$.getValue(), config));
  }

  // #endregion

  // #region -> (time interval management)

  /** */
  public toggle_time_interval(time_interval: 'weekly' | 'monthly' = null): void {
    if (isNil(time_interval)) {
      this.date_range_manager.range_name$$.pipe(take(1)).subscribe({
        next: interval => {
          if (interval === 'monthly') {
            this.date_range_manager.ranged_to.fixed.weekly();
          } else {
            this.date_range_manager.ranged_to.fixed.monthly();
          }
        },
      });
    } else if (time_interval === 'weekly') {
      this.date_range_manager.ranged_to.fixed.weekly();
    } else {
      this.date_range_manager.ranged_to.fixed.monthly();
    }
  }

  // #endregion

  // #region -> (user settings update)

  private _user_favorite_date_range$$ = this._app_state_service.user$$.pipe(
    waitForNotNilValue(),
    switchMap(user => user.params__apiary_list_default_date_range$$),
    replay()
  );

  /** */
  public is_weekly_favorite$$ = this._user_favorite_date_range$$.pipe(
    map(user_favorite_date_range => user_favorite_date_range === 'weekly'),
    replay()
  );

  /** */
  public is_monthly_favorite$$ = this._user_favorite_date_range$$.pipe(
    map(user_favorite_date_range => user_favorite_date_range === 'monthly'),
    replay()
  );

  /** */
  public user_setting_default_date_range_is_not_null$$ = this._app_state_service.user$$.pipe(
    waitForNotNilValue(),
    switchMap(user => user.params$$),
    map(user_parameters => user_parameters?.app_settings?.apiary?.list?.default_date_range ?? null),
    map(setting => !isNil(setting)),
    replay()
  );

  /** */
  public save_date_range(range_name: 'weekly' | 'monthly') {
    this._app_state_service.user$$
      .pipe(
        waitForNotNilValue(),
        take(1),
        map(user => {
          user.setPartialParams({ app_settings: { apiary: { list: { default_date_range: range_name } } } });
          return user;
        }),
        switchMap(user => user.save(['params'])),
        take(1)
      )
      .subscribe();
  }

  // #endregion
}
