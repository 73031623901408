<div class="bg-dialog">
  <dialog-header>
    <span>
      {{ 'VIEWS.DEVICES.DIALOGS_AND_MODALS.DEVICE_SUPPORT_DIALOGS.TITLE.Add comment for devices in RU' | translate }}
    </span>
  </dialog-header>

  <dialog-body>
    <div *ngIf="(has_devices_with_support$$ | async) === false" class="message message-alert">
      <span class="mdi mdi-24px mdi-alert message-icon"></span>
      <div class="message-content">
        <div class="message-content__title">
          <span> {{ 'VIEWS.DEVICES.DIALOGS_AND_MODALS.DEVICE_SUPPORT_DIALOGS.MESSAGE.ALERT.Devices without open RU' | translate }} </span>
        </div>

        <div class="message-content__text">
          <div *ngLet="support_type$$ | async; let support_type">
            {{
              'VIEWS.DEVICES.DIALOGS_AND_MODALS.DEVICE_SUPPORT_DIALOGS.MESSAGE.ALERT.There is no support ([support_type]) open for the following devices : [device_name_list]'
                | translate
                  : {
                      support_type: support_type,
                      device_name_list: devices_without_support$$ | async | mapArray : 'name' | join : ', '
                    }
            }}
          </div>
        </div>
      </div>
    </div>

    <ng-container *ngIf="has_devices_with_support$$ | async">
      <div *ngIf="(missing_devices_of_ticket$$ | async)?.length > 0" class="message message-info">
        <span class="mdi mdi-24px mdi-information message-icon"></span>
        <div class="message-content">
          <div class="message-content__title">
            <span>
              {{ 'VIEWS.DEVICES.DIALOGS_AND_MODALS.DEVICE_SUPPORT_DIALOGS.MESSAGE.INFO.Several devices has been added' | translate }}
            </span>
          </div>

          <div class="message-content__text">
            <div>
              {{
                'VIEWS.DEVICES.DIALOGS_AND_MODALS.DEVICE_SUPPORT_DIALOGS.MESSAGE.INFO.The related DESK ticket refers to these devices : [device_name_list]'
                  | translate
                    : {
                        device_name_list: missing_devices_of_ticket$$ | async | mapArray : 'name' | join : ', '
                      }
              }}.
            </div>
          </div>
        </div>
      </div>

      <mat-form-field [appearance]="'outline'">
        <mat-label>{{ 'VIEWS.DEVICES.SUPPORTS-DIALOG.Add comment for device(s)' | translate }}</mat-label>

        <mtx-select
          [multiple]="true"
          [appendTo]="null"
          [bindLabel]="'name'"
          [bindValue]="'imei'"
          [ngModel]="selected_devices_to_comment$$ | async"
          [items]="available_devices_for_support$$ | async"
          (ngModelChange)="selected_devices_to_comment = $event"
        >
          <!-- Template for each item inside the dropdown -->
          <ng-template ng-option-tmp let-object="item" let-index="index" let-search="searchTerm">
            <div *ngIf="object" class="ng-option-template">
              <ng-container *ngIf="asType(object?.device, 'device'); let device">
                <span>{{ device?.name }}</span>

                <span *ngIf="object?.disabled" class="already-closed-support">
                  {{ 'DEVICE.SUPPORTS.ERROR.Support already opened' | translate }}
                </span>
              </ng-container>
            </div>
          </ng-template>
        </mtx-select>
      </mat-form-field>

      <bg2-form-overlay
        *ngIf="schema$$ | async; let supports_schema"
        [source_schema]="supports_schema"
        [source_model]="form_model$$ | async"
        (when_form_valid_update)="setFormValid($event)"
        (when_form_data_update)="onFormModelChanged($event)"
      ></bg2-form-overlay>
    </ng-container>
  </dialog-body>

  <div class="dialog-footer">
    <bg2-button [type]="'cancel'" [message]="'VIEWS.MODALS.FORM.Cancel' | translate" (btn_click)="close(false)"> </bg2-button>

    <bg2-button
      [type]="'action'"
      [icon]="'mdi-content-save'"
      [disabled]="(is_valid$$ | async) === false"
      [message]="'VIEWS.MODALS.FORM.Save' | translate"
      (btn_click)="submit()"
    >
    </bg2-button>
  </div>
</div>
