import { NgZone } from '@angular/core';

import { marker as i18n } from '@biesbjerg/ngx-translate-extract-marker';

import { Selection, select, TimeInterval, timeDay, scaleTime, axisBottom } from 'd3';

import { format, isEqual, subDays } from 'date-fns/esm';
import { formatInTimeZone, getTimezoneOffset } from 'date-fns-tz';

import { isArray, isEmpty, isNil, uniqueId } from 'lodash-es';

import { BehaviorSubject, combineLatest, filter, map, Observable, Subscription, switchMap, take } from 'rxjs';

import { AppStateService } from 'app/core/app-state.service';
import { ConsoleLoggerService } from 'app/core/console-logger.service';
import { D3SharedCursor, D3SharedCursorService } from 'app/core/services/global/d3-shared-cursor.service';

import { SimpleSetterGetter } from 'app/models';
import { DateTime, Interval } from 'luxon';
import { SizeModifier } from '../../enumerators';
import { D3GridDayCycle } from '../d3-grid-day-cycle/d3-grid-day-cycle';
import {
  AbstractD3Axes,
  AbstractD3Grid,
  AbstractD3Labels,
  AbstractD3Scales,
  AbstractDataObjects,
  AbstractEventObjects,
  AbstractUserEvents,
  BoxSizing,
  ChartGeoposition,
  D3ChartMargins,
  D3TooltipSize,
} from '../../interfaces';
import { distinctUntilRealChanged, replay, waitForNotNilProperties, waitForNotNilValue } from '@bg2app/tools/rxjs';
import { compute_difference_in_days_luxon } from '@bg2app/tools/dates';

/**
 * @public
 * @abstract
 * @description
 *
 * @template
 */
export abstract class D3SvgBuilderLuxon<DataType> {
  // #region -> (properties)

  /** */
  public margins: D3ChartMargins = {
    top: 5,
    left: 35,
    right: 35,
    bottom: 25,
  };

  /** */
  public show_x_grid: boolean = true;

  /** */
  public show_day_cycle_grid: boolean = true;

  /** */
  protected offset_time_for_events = false;

  /** */
  public readonly tooltip_size: D3TooltipSize = {
    margins: {
      top: 4,
      left: 5,
      right: 5,
      bottom: 5,
    },

    box_sizing: {
      height: 18,
      width: null,
    },
  };

  /** */
  public svg: Selection<SVGSVGElement, unknown, HTMLElement, null> = null;

  /** */
  public get svg_parent(): Selection<HTMLElement, unknown, HTMLElement, null> {
    if (isNil(this.svg)) {
      return null;
    }

    return select(this.svg.node().parentElement);
  }

  /** */
  protected definitions: Selection<SVGDefsElement, unknown, HTMLElement, unknown> = null;

  /** */
  public abstract axes: AbstractD3Axes;

  /** */
  public abstract scales: AbstractD3Scales;

  /** */
  public abstract data_objects: AbstractDataObjects;

  /** */
  public abstract event_objects: AbstractEventObjects;

  /** */
  public abstract user_events: AbstractUserEvents;

  /** */
  public abstract grids: AbstractD3Grid;

  /** */
  public abstract labels: AbstractD3Labels;

  /** */
  protected abstract colors: { [key: string]: `#${string}` };

  // #region -> (chart setup status)

  /** */
  private _is_chart_is_setup$$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  /** */
  public readonly is_chart_is_setup$$: Observable<boolean> = this._is_chart_is_setup$$
    .asObservable()
    .pipe(filter(Boolean), distinctUntilRealChanged());

  /** */
  public set is_chart_is_setup(is_chart_is_setup: boolean) {
    this._is_chart_is_setup$$.next(is_chart_is_setup);
  }

  /** */
  public get is_chart_is_setup(): boolean {
    return this._is_chart_is_setup$$.getValue();
  }

  // #endregion

  // #region -> (chart actual box sizing)

  /** */
  private _box_sizing$$ = new BehaviorSubject<BoxSizing>(null);

  /** */
  public box_sizing$$: Observable<BoxSizing> = this._box_sizing$$.asObservable().pipe(
    filter(box_sizing => !isNil(box_sizing?.width) && !isNil(box_sizing?.height)),
    replay()
  );

  /** */
  public get box_sizing(): BoxSizing {
    return this._box_sizing$$.getValue();
  }

  /** */
  public set box_sizing(box_sizing: BoxSizing) {
    this._box_sizing$$.next(box_sizing);
  }

  // #endregion

  // #region -> (class basics)

  /** */
  protected abstract LOGGER: ConsoleLoggerService;

  /** */
  public unique_id: string = null;

  /** */
  private _data_sub: Subscription = null;

  /** */
  private _loading_sub: Subscription = null;

  /** */
  private _box_sizing_sub: Subscription = null;

  /** */
  private _shared_cursor_sub: Subscription = null;

  /** */
  private _app_language_sub: Subscription = null;

  /** */
  constructor(protected _shared_cursor: D3SharedCursorService, protected _appState: AppStateService, protected _ngZone: NgZone) {
    this.unique_id = uniqueId('d3-js-chart-');

    this._box_sizing_sub = this.box_sizing$$.pipe(switchMap(() => this.is_chart_is_setup$$)).subscribe({
      next: () => this.resize(),
    });

    this._data_sub = this.incoming_data$$.pipe(switchMap(() => this.is_chart_is_setup$$)).subscribe({
      next: () => this.append_data(),
    });

    this._loading_sub = this._is_loading$$
      .asObservable()
      .pipe(switchMap(is_loading => this.is_chart_is_setup$$.pipe(map(() => is_loading))))
      .subscribe({
        next: is_loading => {
          this.loading_objects.container.style('display', is_loading ? 'initial' : 'none');
        },
      });

    this._shared_cursor_sub = this.is_chart_is_setup$$.pipe(switchMap(() => this._shared_cursor.shared_cursor$$)).subscribe({
      next: shared_cursor => this.on_cursor_move(shared_cursor, this.incoming_data),
    });

    this._app_language_sub = this._appState.lang$$.pipe(switchMap(() => this.is_chart_is_setup$$)).subscribe({
      next: () => this.on_language_updated(),
    });
  }

  /** */
  public destroy() {
    this._data_sub?.unsubscribe();
    this._loading_sub?.unsubscribe();
    this._box_sizing_sub?.unsubscribe();
    this._app_language_sub?.unsubscribe();
    this._shared_cursor_sub?.unsubscribe();

    // Cleanup grids
    this?.grids?.day_cycle?.destroy();
    this.grids.day_cycle = null;
  }

  // #endregion

  // #region -> (loadings management)

  /** */
  private _is_loading$$ = new BehaviorSubject(true);

  /** */
  public set is_loading(is_loading: boolean) {
    if (this.is_loading === is_loading) {
      return;
    }

    this._is_loading$$.next(is_loading);
  }

  /** */
  public get is_loading(): boolean {
    return this._is_loading$$.getValue();
  }

  /** */
  private loading_objects = {
    /** */
    container: null as Selection<SVGForeignObjectElement, unknown, HTMLElement, null>,

    /** */
    background: null as Selection<HTMLDivElement, unknown, HTMLElement, null>,
  };

  // #endregion

  // #region -> (incoming data)

  /** */
  protected no_data_objects = {
    /** */
    container: null as Selection<SVGGElement, unknown, HTMLElement, null>,

    /** */
    text_container: null as Selection<SVGRectElement, unknown, HTMLElement, null>,

    /** */
    text: null as Selection<SVGTextElement, unknown, HTMLElement, null>,
  };

  /** */
  protected _incoming_data$$: BehaviorSubject<DataType> = new BehaviorSubject<DataType>(null);

  /** */
  public incoming_data$$: Observable<DataType> = this._incoming_data$$.asObservable().pipe(waitForNotNilValue());

  /** */
  public set incoming_data(incoming_data: DataType) {
    this._incoming_data$$.next(incoming_data || null);
  }

  /** */
  public get incoming_data(): DataType {
    return this._incoming_data$$.getValue();
  }

  /** */
  public get has_data() {
    const data = this.incoming_data;

    if (isArray(data)) {
      return !isEmpty(data ?? []);
    }

    return !isNil(data);
  }

  // #endregion

  // #region -> (time management)

  /** */
  private _each_day_in_range: DateTime[] = null;

  /** */
  protected get each_day_in_range() {
    return this._each_day_in_range;
  }

  /** */
  private _date_range$$ = new BehaviorSubject<{ start: DateTime; end: DateTime }>(null);

  /** */
  protected date_range$$ = this._date_range$$.asObservable();

  /** */
  public set date_range(date_range: { start: DateTime; end: DateTime }) {
    if (isNil(date_range?.start) || isNil(date_range?.end)) {
      return;
    }

    const start = date_range.start.plus({
      hours: date_range.start.offset / 60 + new Date(date_range.start.toString()).getTimezoneOffset() / 60,
    });

    const end = date_range.end.plus({ hours: date_range.end.offset / 60 + new Date(date_range.end.toString()).getTimezoneOffset() / 60 });

    this._date_range$$.next({ start, end });

    if (start.isValid && end.isValid) {
      this.days_to_display = compute_difference_in_days_luxon({ start, end });

      this._each_day_in_range = Interval.fromDateTimes(start, end)
        .splitBy({ days: 1 })
        .map(d => d.start);
    }
  }

  /** */
  public get date_range() {
    return this._date_range$$.getValue();
  }

  /** */
  public geoposition = new SimpleSetterGetter<ChartGeoposition>(null);

  /** */
  private _days_to_display$$ = new BehaviorSubject<number>(null);

  /** */
  protected days_to_display$$ = this._days_to_display$$.asObservable().pipe(distinctUntilRealChanged());

  /** */
  protected set days_to_display(days_to_display: number) {
    this._days_to_display$$.next(days_to_display);
  }

  /** */
  protected get days_to_display() {
    return this._days_to_display$$.getValue();
  }

  /** */
  protected get display_every_x_day(): number {
    if (isNil(this.days_to_display)) {
      return 1;
    }

    if (this.days_to_display <= 7) {
      return 1;
    } else if (this.days_to_display <= 31) {
      return 3;
    } else {
      return 5;
    }
  }

  /** */
  private get_time_interval(): TimeInterval {
    // return timeDay.every(this.display_every_x_day);
    return timeDay.filter(d => timeDay.count(this.date_range.start.toJSDate(), d) % this.display_every_x_day === 0);
  }

  // #endregion

  // #region -> (initial creation methods)

  /**
   * Creates the D3-chart initial objects.
   *
   * The method {@link create_chart} allows the user to create the required objects
   * for the D3-chart once only. It includes the selection of the HTML tag, the creation
   * of the SVG definitions, containers, scales, axes and event objects.
   *
   * @throws Throws an {@link Error} if the chart is already created.
   */
  public create_chart(): void {
    if (this.is_chart_is_setup) {
      throw new Error('The creation of the chart can be done only once.');
    }

    combineLatest({
      box_sizing: this.box_sizing$$,
      days_to_display: this.days_to_display$$,
      date_range: this.date_range$$.pipe(waitForNotNilProperties()),
      geoposition: this.geoposition.value$$,
    })
      .pipe(waitForNotNilProperties(), take(1))
      .subscribe({
        next: ({ geoposition }) => {
          // Select the SVG container
          this.svg = select<SVGSVGElement, unknown>(`svg#${this.unique_id}`).attr('viewbox', [
            0,
            0,
            this.box_sizing?.width,
            this.box_sizing?.height,
          ]);

          this.create_chart_defs();
          this.create_containers();
          this.create_scales();
          this.create_axes();
          this.create_grid(geoposition);
          this.create_labels();

          this.build_event_objects();

          // Bind to the mouse events
          this.user_events.event_bounds
            .on('mouseenter', () => this.on_mouse_enter())
            .on('mousemove', event => this.on_mouse_move(event, this._incoming_data$$.getValue()))
            .on('mouseout', () => this.on_mouse_out());

          // Create no data label
          this.no_data_objects.text_container = this.no_data_objects.container
            .append('rect')
            .attr('x', 5)
            .attr('y', 5)
            .style('transform-box', 'fill-box')
            .style('fill', 'rgba(255, 255, 255, 1)')
            .style('transform', 'translate(-50%, -50%)');

          this.no_data_objects.text = this.no_data_objects.container
            .append('text')
            .attr('x', this.user_events.event_bounds.node().getBoundingClientRect().width / 2)
            .attr('y', this.user_events.event_bounds.node().getBoundingClientRect().height / 2 + 5)
            .attr('fill', 'black')
            .style('font-size', '13px')
            .style('font-weight', 'bold')
            .style('text-anchor', 'middle')
            .text(this._appState.translate.instant(i18n<string>('ALL.DATA.MISC.No data so far !')));

          // Small fix for Firefox differing rendering
          setTimeout(() => {
            this.no_data_objects.text_container
              .attr('width', this.no_data_objects.text.node().getBoundingClientRect().width + 10)
              .attr('height', this.no_data_objects.text.node().getBoundingClientRect().height + 10)
              .attr('x', this.user_events.event_bounds.node().getBoundingClientRect().width / 2)
              .attr('y', this.user_events.event_bounds.node().getBoundingClientRect().height / 2);
          }, 1);

          this.is_chart_is_setup = true;
        },
      });
  }

  /**
   * Creates chart definitions. To build SVG gradients, checkout {@link https://doodad.dev/gradient-generator}.
   *
   * @protected
   */
  protected create_chart_defs(): void {
    this.definitions = this.svg.append('defs');

    // Build day/night transition gradient
    const day_night_transition_gradient = this.definitions
      .append('linearGradient')
      .attr('id', `${this.unique_id}day_night_transition_gradient`)
      .attr('x1', '0')
      .attr('x2', '1')
      .attr('y1', '0.5')
      .attr('y2', '0.5');

    day_night_transition_gradient.append('stop').attr('offset', '0%').attr('stop-color', 'rgba(255, 226, 178, 0)');
    day_night_transition_gradient.append('stop').attr('offset', '5%').attr('stop-color', '#ffe2b2');
    day_night_transition_gradient.append('stop').attr('offset', '95%').attr('stop-color', '#ffe2b2');
    day_night_transition_gradient.append('stop').attr('offset', '100%').attr('stop-color', 'rgba(255, 226, 178, 0)');

    const current_day_pattern = this.definitions
      .append('pattern')
      .attr('id', `${this.unique_id}current-day-pattern`)
      .attr('width', '10')
      .attr('height', '10')
      .attr('patternUnits', 'userSpaceOnUse')
      .attr('patternTransform', 'rotate(45 0 0)');

    current_day_pattern.append('rect').attr('x', 0).attr('y', 0).attr('width', 5).attr('height', '100%').attr('fill', '#ffe2b2');
  }

  /**
   * Creates the chart objects containers.
   *
   * The method {@link create_containers} creates the required containers to tidy up the chart objects
   * like axes, events objects and data objects.
   */
  private create_containers(): void {
    // Setup grids container
    this.grids.container = this.svg
      .append('g')
      .attr('pointer-events', 'none')
      .attr('class', 'd3-grids-container')
      .attr('transform', `translate(${this.margins.left}, ${this.calc_size_of(this.box_sizing.height, ['-bottom'])})`);

    // Create labels container
    this.labels.container = this.svg
      .append('g')
      .attr('pointer-events', 'none')
      .attr('class', 'd3-labels-container')
      .attr('transform', `translate(${this.margins.left},${this.margins.top})`);

    // Create container for axes
    this.axes.container = this.svg.append('g').attr('pointer-events', 'none').attr('class', 'd3-axes-container');

    // Create container for data objects
    this.data_objects.container = this.svg
      .append('g')
      .attr('pointer-events', 'none')
      .attr('class', 'd3-data-objects-container')
      .attr('transform', `translate(${this.margins.left},${this.margins.top})`);

    // Create container for event objects
    this.event_objects.container = this.svg
      .append('g')
      .attr('pointer-events', 'none')
      .attr('class', 'd3-event-objects-container')
      .attr('transform', `translate(${this.margins.left},${this.margins.top})`);

    // Create container for event objects
    this.user_events.container = this.svg
      .append('g')
      .attr('class', 'd3-user-event-objects-container')
      .attr('transform', `translate(${this.margins.left},${this.margins.top})`);

    // Create container for event triggering
    this.user_events.event_bounds = this.svg
      .append('rect')
      .attr('fill', 'transparent')
      .attr('class', 'd3-events-container')
      .attr('width', this.calc_size_of(this.box_sizing.width, ['-right', '-left']))
      .attr('height', this.calc_size_of(this.box_sizing.height, ['-top', '-bottom']))
      .attr('transform', `translate(${this.margins.left},${this.margins.top})`);

    this.no_data_objects.container = this.svg
      .append('g')
      .attr('class', 'd3-nodata-container')
      .style('display', 'none')
      .attr('transform', `translate(${this.margins.left},${this.margins.top})`);

    this.loading_objects.container = this.svg
      .append('foreignObject')
      .attr('class', 'd3-loading-container')
      .attr('transform', `translate(${this.margins.left},${this.margins.top})`)
      .attr('width', this.calc_size_of(this.box_sizing.width, ['-right', '-left']))
      .attr('height', this.calc_size_of(this.box_sizing.height, ['-top', '-bottom']));

    this.loading_objects.background = this.loading_objects.container
      .append('xhtml:div')
      .attr('class', 'd3-loading-container__background') as any;
  }

  /**
   * Creates the scales of the chart.
   *
   * The method {@link create_scales} creates the default scales of the D3-chart. It includes
   * the time-scale.
   */
  protected create_scales(): void {
    this.scales.time = scaleTime().rangeRound([0, this.calc_size_of(this.box_sizing?.width, ['-left', '-right'])]);
  }

  /**
   * Creates the axes of the chart.
   *
   * The method {@link create_axes} creates the initial axes of the D3-chart. It includes the
   * time-axis.
   */
  protected create_axes() {
    this.axes.time.axis = axisBottom(this.scales.time).ticks(this.get_time_interval()).tickSize(8).tickSizeOuter(0);
    this.axes.time.container = this.axes.container
      .append('g')
      .attr('class', 'd3-X-axis')
      .attr('transform', `translate(${this.calc_size_of(0, ['+left'])}, ${this.calc_size_of(this.box_sizing.height, ['-bottom'])})`)
      .call(this.axes.time.axis);
  }

  /** */
  protected create_grid(geoposition: ChartGeoposition): void {
    if (this.show_x_grid) {
      this.grids.time.axis = axisBottom(this.scales.time)
        .tickSize(-this.calc_size_of(this.box_sizing.height, ['-top', '-bottom']))
        .tickFormat(() => '')
        .ticks(this.get_time_interval());

      this.grids.time.container = this.grids.container.append('g').attr('class', 'd3-X-grid').call(this.grids.time.axis);
    }

    if (this.show_day_cycle_grid) {
      this.grids.day_cycle = new D3GridDayCycle({ geoposition });
      this.grids.day_cycle.create_container(this.grids.container);
    }
  }

  /** */
  protected create_labels(): void {}

  // #endregion

  // #region -> (update methods)

  /** */
  protected update_grid(): void {
    if (this.show_x_grid) {
      this.grids.time.axis.scale(this.scales.time);
      this.grids.time.container.call(this.grids.time.axis);

      this.grids.time.container.select('path').remove();
    }

    // if (this.display_every_x_day > 1) {
    //   this.grids.time.container.selectAll('line').each((a: any, i, groups: any[]) => {
    //     const line = groups[i];

    //     if (i % this.display_every_x_day !== 0) {
    //       select(line).style('stroke', '#ebebeb');
    //     }
    //   });
    // }

    if (this.show_day_cycle_grid) {
      this.grids?.day_cycle?.update(
        this.unique_id,
        this.axes.time.axis.scale().domain() as any,
        this.scales.time,
        this.calc_size_of(this.box_sizing.height, ['-top', '-bottom']),
        this.days_to_display
      );
    }
  }

  /** */
  protected update_axes(): void {
    this.axes.time.axis.scale(this.scales.time).ticks(this.get_time_interval());
    this.axes.time.container.transition().duration(500).call(this.axes.time.axis);
  }

  // #endregion

  // #region -> (methods)

  /**
   * @public
   * @description
   *
   *
   */
  public resize(): void {
    // Update the SVG width
    this.svg.attr('width', this.box_sizing?.width);

    // Update X-Axes
    this.scales.time.rangeRound([0, this.calc_size_of(this.box_sizing?.width, ['-left', '-right'])]);

    this.user_events.event_bounds.attr('width', this.calc_size_of(this.box_sizing.width, ['-right', '-left']));

    // Update x/y lines
    if (!isNil(this.user_events?.focus_line_x)) {
      this.user_events.focus_line_x.attr('x2', this.user_events.event_bounds.node().getBBox().width);
    }

    if (!isNil(this.user_events?.focus_line_y)) {
      this.user_events.focus_line_y.attr('y2', this.user_events.event_bounds.node().getBBox().height);
    }

    this.loading_objects.container
      .attr('width', this.calc_size_of(this.box_sizing.width, ['-right', '-left']))
      .attr('height', this.calc_size_of(this.box_sizing.height, ['-top', '-bottom']));

    this.no_data_objects.text.attr('x', this.user_events.event_bounds.node().getBoundingClientRect().width / 2);
    this.no_data_objects.text_container
      .attr('width', this.no_data_objects.text.node().getBoundingClientRect().width + 10)
      .attr('height', this.no_data_objects.text.node().getBoundingClientRect().height + 10)
      .attr('x', this.user_events.event_bounds.node().getBoundingClientRect().width / 2)
      .attr('y', this.user_events.event_bounds.node().getBoundingClientRect().height / 2);

    this.append_data(true);
  }

  // #endregion

  // #region -> (axes management)

  /** */
  public apply_axes_format(): void {
    if (!isNil(this.axes?.time.axis)) {
      this.axes.time.axis.tickFormat((date: Date, index: number) => {
        if (this.days_to_display <= 7) {
          return format(date, this._appState.dl.ll_ny, { locale: this._appState.dl.dateFns });
        }

        const previous_date = subDays(date, this.display_every_x_day);

        const is_first_date = isEqual(date, this.date_range.start.toJSDate());
        const is_last_date = isEqual(date, this.date_range.end.toJSDate());
        const is_new_month = !isNil(previous_date) ? previous_date.getMonth() !== date.getMonth() : false;

        if (is_first_date || is_last_date) {
          return format(date, this._appState.dl.ll_ny, { locale: this._appState.dl.dateFns });
        }

        if (is_new_month) {
          return format(date, this._appState.dl.ll_ny, { locale: this._appState.dl.dateFns });
        }

        return format(date, this._appState.dl.d, { locale: this._appState.dl.dateFns });
      });

      this.axes.time.container.call(this.axes.time.axis);

      if (this.offset_time_for_events) {
        const ticks = this.axes.time.container.selectAll('.tick');
        ticks.select('line').style('transform', 'translate(0px, 20px)');
        ticks.select('text').style('transform', 'translate(0px, 20px)');
      }
    }
  }

  // #endregion

  // #region -> (data management)

  /** */
  protected append_data(is_after_resize = false): void {
    this.update_axes();
    this.update_grid();

    this.apply_axes_format();

    this.no_data_objects.container.style('display', this.has_data ? 'none' : 'initial');
  }

  // #endregion

  // #region -> (abstract methods)

  /**
   * @public
   * @abstract
   * @description
   *
   *
   */
  public abstract build_event_objects(): void;

  /**
   * @public
   * @method
   * @description
   *
   *
   * @param is_from_shared_cursor
   */
  public on_mouse_enter(is_from_shared_cursor = false, emit_cursor = true): void {
    if (!this.has_data) {
      return;
    }

    this.user_events.focus_line_y?.style('display', null);

    if (!is_from_shared_cursor && emit_cursor) {
      this.user_events.focus_line_x?.style('display', null);
      this._shared_cursor.shared_cursor = { date: null, from: this.unique_id, event_type: 'mouseenter' };
    }
  }

  /**
   * @public
   * @abstract
   * @description
   *
   *
   * @param event
   * @param data
   * @param is_from_shared_cursor
   */
  public on_mouse_move(event: MouseEvent | Date, data: DataType, is_from_shared_cursor = false): void {}

  /**
   * @public
   * @method
   * @description
   *
   *
   * @param is_from_shared_cursor
   */
  public on_mouse_out(is_from_shared_cursor = false): void {
    this?.user_events?.focus_line_y?.style('display', 'none');

    if (!is_from_shared_cursor) {
      this?.user_events?.focus_line_x?.style('display', 'none');
      this._shared_cursor.shared_cursor = {
        date: null,
        from: this.unique_id,
        event_type: 'mouseout',
      };
    }
  }

  /**
   * @public
   * @abstract
   * @description
   *
   *
   * @param cursor
   * @param data
   */
  protected on_cursor_move(cursor: D3SharedCursor, data: DataType) {
    if (isNil(cursor)) {
      return;
    }

    if (this.unique_id === cursor?.from) {
      return;
    }

    // Check if cursor is out of date bounds
    if (cursor?.date?.getTime() <= this.date_range.start.toMillis() || cursor?.date?.getTime() >= this.date_range.end.toMillis()) {
      this.on_mouse_out(true);
      return;
    } else {
      this.on_mouse_enter(true);
    }

    if (cursor.event_type === 'mouseenter') {
      this.on_mouse_enter(true);
      return;
    }

    if (cursor.event_type === 'mouseout') {
      this.on_mouse_out(true);
      return;
    }

    if (isEmpty(data || []) || cursor.event_type !== 'mousemove') {
      return;
    }

    this.on_mouse_move(cursor.date, data, true);
  }

  // #endregion

  // #region -> (chart events)

  /** */
  protected on_language_updated(): void {
    this.apply_axes_format();

    this.no_data_objects?.text
      .text(this._appState.translate.instant(i18n<string>('ALL.DATA.MISC.No data so far !')))
      .attr('x', this.user_events.event_bounds.node().getBoundingClientRect().width / 2);
    this.no_data_objects?.text_container
      .attr('width', this.no_data_objects.text.node().getBoundingClientRect().width + 10)
      .attr('height', this.no_data_objects.text.node().getBoundingClientRect().height + 10)
      .attr('x', this.user_events.event_bounds.node().getBoundingClientRect().width / 2)
      .attr('y', this.user_events.event_bounds.node().getBoundingClientRect().height / 2);
  }

  // #endregion

  // #region -> (helpers)

  /** */
  protected create_tooltip_header(date: Date, date_format: string = null): string {
    let template = `<div class="d3-chart-tooltip-time">`;

    // Displays time UTC
    // template += '<span>';
    // template += date.toISOString();
    // template += '</span>';

    // Displays time in timezone of location
    template += '<span class="timezoned-time">';
    template += this.format_date(date, date_format ?? this._appState.dl.lll);
    template += ` (${formatInTimeZone(date, this.geoposition.value.timezone, 'zzz')})`;
    template += '</span>';

    // Displays time in browser time
    const is_not_same_timezone = getTimezoneOffset(this.geoposition.value.timezone, date) / 60000 !== this._appState.timezone_offset;

    if (is_not_same_timezone) {
      template += '<span class="browsered-time">';
      template += '<span class="mdi mdi-map-clock"></span>';
      template += `<span>${this._appState.translate.instant(i18n<string>('ALL.COMMON.Browser:'))} `;
      template += this.format_date_local(date, this._appState.dl.HH_mm);
      template += ' ';
      template += `(${this.format_date_local(date, 'zzz')})`;
      template += '</span>';
      template += '</span>';
    }

    template += '</div>';

    return template;
  }

  /**
   * @public
   * @method
   * @description Calculate chart size according to parameters and configuration
   *
   *
   * @param initial
   * @param modifiers
   *
   * @returns
   */
  public calc_size_of(initial: number, modifiers: SizeModifier[]): number {
    let final_value = initial;

    (modifiers || []).forEach(modifier => {
      const direction = modifier.split(/[+-]/)[1] as 'left' | 'right' | 'top' | 'bottom';

      if (modifier.includes('+')) {
        final_value += this.margins[direction];
      } else {
        final_value -= this.margins[direction];
      }
    });

    return final_value;
  }

  /**
   * @public
   * @method
   * @description
   *
   *
   *
   * @param direction
   * @param container
   *
   * @returns
   */
  public create_focus_line(direction: 'x' | 'y', container: Selection<SVGGElement, unknown, HTMLElement, any>) {
    const x2 = direction === 'x' ? this.user_events.event_bounds.node().getBBox().width : 0;
    const y2 = direction === 'y' ? this.user_events.event_bounds.node().getBBox().height : 0;

    return container
      .append('line')
      .attr('stroke', 'black')
      .style('display', 'none')
      .attr('stroke-width', '1.5px')
      .attr('pointer-events', 'none')
      .attr('stroke-dasharray', '4')
      .attr('class', `d3-focus-line-${direction}`)
      .attr('x1', 0)
      .attr('x2', x2)
      .attr('y1', 0)
      .attr('y2', y2);
  }

  /**
   * @public
   * @method
   *
   * @description
   *
   *
   *
   * @param container
   * @param color
   *
   * @returns
   */
  public create_d3_circle(container: Selection<SVGGElement, unknown, HTMLElement, any>, color: string = 'red') {
    return container
      .append('circle')
      .attr('r', 4)
      .style('display', 'none')
      .attr('pointer-events', 'none')
      .attr('class', 'd3-focus-circle')
      .attr('fill', `${color}`);
  }

  /**
   * @public
   * @method
   * @deprecated
   *
   * @description
   *
   *
   * @returns
   */
  public create_d3_tooltip() {
    const container = this.user_events.container
      .append('g')
      .attr('class', 'd3-tooltip')
      .style('display', 'none')
      .attr('pointer-events', 'none');
    container
      .append('rect')
      .attr('x', -this.tooltip_size.margins.left)
      .attr('y', 0)
      .attr('height', this.tooltip_size.box_sizing.height)
      .attr('fill', 'rgba(193,193,193,0.7)');

    const text = container
      .append('text')
      .attr('y', this.tooltip_size.box_sizing.height - this.tooltip_size.margins.bottom)
      .attr('x', 0)
      .text('DEFAULT')
      .attr('font-size', '12px');

    return { container, text };
  }

  /**
   * @public
   * @method
   *
   * @description
   *
   * @param base_element
   *
   * @returns
   */
  public create_html_tooltip(base_element: Selection<HTMLElement, any, HTMLElement, null>) {
    return base_element
      .append('div')
      .attr('class', 'd3-chart-tooltip')
      .style('display', 'none')
      .style('position', 'absolute')
      .style('pointer-events', 'none');
  }

  /**
   * @public
   *
   * @method
   * @description
   *
   *
   * @param date
   * @param fm
   *
   * @returns
   */
  public format_date(date: number | Date, fm: string): string {
    return formatInTimeZone(date, this.geoposition.value.timezone, fm, {
      locale: this._appState.dl.dateFns,
      timeZone: this.geoposition.value.timezone,
    });
  }

  /** */
  public format_date_local(date: number | Date, fm: string): string {
    return format(date, fm, {
      locale: this._appState.dl.dateFns,
    });
  }

  // #endregion
}
