import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Router } from '@angular/router';

import { flatten, isNil } from 'lodash-es';

import { replay } from '@bg2app/tools/rxjs';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { distinctUntilChanged, filter, map, switchMap, take, tap } from 'rxjs';

import { GhostService } from 'app/core/ghost';
import { DialogsService } from 'app/widgets/dialogs-modals';
import { ConsoleLoggerService } from 'app/core/console-logger.service';

import { Location } from 'app/models';
import { GhostSolutionAlternatives } from 'app/core/ghost/models/ghost-solution';
import { GhostConfirmDialogComponent } from '../ghost-confirm-dialog/ghost-confirm-dialog.component';

@Component({
  selector: 'bg2-locations-ghost-wizard',
  templateUrl: './locations-ghost-wizard.component.html',
  styleUrls: ['./locations-ghost-wizard.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LocationsGhostWizardComponent {
  @Input()
  public set location(location: Location) {
    this._location$$.next(location);
  }

  private _location$$: BehaviorSubject<Location> = new BehaviorSubject<Location>(null);
  public location$$: Observable<Location> = this._location$$.asObservable().pipe(
    filter((location: Location) => !isNil(location)),
    replay()
  );

  // #region -> (component basics)

  private readonly _logger = new ConsoleLoggerService('LocationsGhostWizardComponent', true);

  constructor(public ghostService: GhostService, private readonly _router: Router, private _dialogs: DialogsService) {}

  // #endregion

  // #region -> (ghost action management)

  private _config_has_been_modified$$ = new BehaviorSubject<boolean>(false);
  public config_has_been_modified$$ = this._config_has_been_modified$$.asObservable();

  public modifyInstallation(): void {
    this.location$$
      .pipe(
        switchMap(location => location.id$$),
        take(1)
      )
      .subscribe({
        next: location_id => {
          this._router.navigate(
            [
              '',
              {
                outlets: {
                  modal: ['ghost_config', { location_id }],
                },
              },
            ],
            { queryParamsHandling: 'preserve' }
          );

          this._config_has_been_modified$$.next(true);
        },
      });
  }

  public confirmInstallation(): void {
    this.location$$
      .pipe(
        filter(location => !isNil(location)),
        switchMap(location => location.id$$),
        take(1),
        switchMap(location_id => this._dialogs.open(GhostConfirmDialogComponent, { location_id }))
      )
      .subscribe({
        complete: () => console.log('complete'),
      });
  }

  // #endregion

  public all_solutions$$: Observable<GhostSolutionAlternatives[]> = this.location$$.pipe(
    switchMap(location => this.ghostService.streamSolutionsFromLocation(location.id)),
    // tap(solutions => this._logger.debug(solutions)),
    replay()
  );

  public is_active$$: Observable<boolean> = combineLatest([this.ghostService.load_ghost$$, this.all_solutions$$]).pipe(
    map(([load_ghost, solutions]) => load_ghost && solutions?.length > 0),
    distinctUntilChanged(),
    replay()
  );

  // #region -> (devices management)

  public devices$$ = this.all_solutions$$.pipe(
    map(solutions => solutions.map(sol => sol.getDevices())),
    map(list_of_devices => flatten(list_of_devices)),
    replay()
  );

  public devices_length$$: Observable<number> = this.devices$$.pipe(
    map(devices => (devices || [])?.length),
    replay()
  );

  // #endregion
}
