import { environment } from 'environments/environment';

/** */
export class ConsoleLoggerService {
  /** */
  private readonly CONFIG = {
    ERROR_STYLE: 'background: red; color: #fff; padding: 3px; font-weight: bold',
    INFO_STYLE: 'background: blue; color: #fff; padding: 3px; font-weight: bold',
    WARN_STYLE: 'background: orange; color: #fff; padding: 3px; font-weight: bold',
    GHOST_STYLE: 'background: white; color: black; padding: 3px; font-weight: bold',
    OAUTH_STYLE: 'background: green; color: white; padding: 3px; font-weight: bold',
    DEBUG_STYLE: 'background: #ff00f7; color: #fff; padding: 3px; font-weight: bold',
    RUNNER_STYLE: 'background: purple; color: white; padding: 3px; font-weight: bold',
  };

  // #region -> (service basics)

  /** */
  private _prefix = '';

  /** */
  private active = true;

  /** */
  public constructor(prefix = '', active = true) {
    this.prefix = prefix;
    this.active = active;
  }

  /** */
  public update_prefix = (prefix: string) => (this._prefix = prefix);

  // #endregion

  /** */
  public set prefix(prefix: string) {
    this._prefix = prefix;
  }

  /** */
  public get debug(): any {
    if (!this.active || environment?.production) {
      return () => {};
    }

    return console.log.bind(console, `%c🐛 [DEBUG] ${this._prefix} ->`, this.CONFIG?.DEBUG_STYLE);
  }

  /** */
  public get ghost(): any {
    if (environment?.production) {
      return console.log.bind(console, `👻 ${this._prefix} ->`);
    } else {
      return console.log.bind(console, `%c👻 ${this._prefix} ->`, this.CONFIG?.GHOST_STYLE);
    }
  }

  /** */
  public get runner(): any {
    if (environment?.production) {
      return console.log.bind(console, `🏃 [RUNNERS] ->`);
    } else {
      return console.log.bind(console, `%c🏃[RUNNERS] ->`, this.CONFIG?.RUNNER_STYLE);
    }
  }

  /**
   * Logs an information message which should be always visible.
   */
  public get info(): any {
    if (environment?.production) {
      return console.log.bind(console, `📣 [INFO] ${this._prefix} ->`);
    } else {
      return console.log.bind(console, `%c📣 [INFO] ${this._prefix} ->`, this.CONFIG?.INFO_STYLE);
    }
  }

  /** */
  public get warn(): any {
    if (environment?.production) {
      return console.log.bind(console, `⚠️ [WARN] ${this._prefix} ->`);
    } else {
      return console.log.bind(console, `%c⚠️ [WARN] ${this._prefix} ->`, this.CONFIG?.WARN_STYLE);
    }
  }

  /** */
  public get auth(): any {
    if (environment?.production) {
      return console.log.bind(console, `🔐 [AUTH] ${this._prefix} ->`);
    } else {
      return console.log.bind(console, `%c🔐 [AUTH] ${this._prefix} ->`, this.CONFIG?.OAUTH_STYLE);
    }
  }

  /**
   * Logs an error which occured on the app like typing error of observable error ...
   */
  public get error(): any {
    if (environment?.production) {
      return console.error.bind(console, `🆘 [ERROR] ${this._prefix} ->`);
    } else {
      return console.error.bind(console, `%c🆘 [ERROR] ${this._prefix} ->`, this.CONFIG?.ERROR_STYLE);
    }
  }

  /**
   * Logs an error which has no link to application error like form errors.
   */
  public get log_error(): any {
    if (environment?.production) {
      return console.log.bind(console, `🆘 [LOG_ERROR] ${this._prefix} ->`);
    } else {
      return console.log.bind(console, `%c🆘 [LOG_ERROR] ${this._prefix} ->`, this.CONFIG?.ERROR_STYLE);
    }
  }
}
