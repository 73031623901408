/** */
export enum DEVICE_SIMPLIFIED_BATTERY_STATE {
  /** The device's battery state is OK */
  OK,

  /** The device's battery state is outdated */
  OUTDATED,

  /** The device's battery state is not ok */
  NOT_OK,

  /** The device's battery state is unknown */
  UNKNOWN,
}

/** */
export enum DEVICE_SIMPLIFIED_BATTERY_STATE_REASON {
  /** */
  NO_CONTACT_SINCE_7_DAYS,

  /** */
  BATTERY_VOLTAGE_UNDER_CRITICAL_LIMIT,

  /** */
  LAST_BATTERY_OLDER_THAN_1_YEAR,

  /** */
  LAST_BATTERY_OLDER_THAN_2_YEARS,

  /** */
  LAST_BATTERY_OLDER_THAN_3_YEARS,

  /** */
  UNKNOWN,
}

/** */
export interface DeviceSimplifiedBatteryState {
  /** */
  state: DEVICE_SIMPLIFIED_BATTERY_STATE;

  /** */
  reason: DEVICE_SIMPLIFIED_BATTERY_STATE_REASON;
}
