import { Injectable, ViewContainerRef, Type, ComponentRef } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';

import { of, Observable } from 'rxjs';

import { ToolsSidenavBase } from 'app/views/sidenav/tools-sidenav-base';

import { isNil } from 'lodash-es';
import { DatatableColumn } from 'app/models/misc/datatable';

/**
 * Sidenav service with generic typing.
 *
 * @generic The letter "O" refers to the "Output" data type.
 * @generic The letter "I" refers to the "Input" data type.
 * @generic The letter "C" refers to the "Component" used as sidenav.
 */
@Injectable({ providedIn: 'root' })
export class SidenavService<O = any, I = any, C extends ToolsSidenavBase = any> {
  // #region -> (service basics)

  constructor() {}

  // #endregion

  // #region -> (navigation sidenav)

  private _navigation_sidenav: MatSidenav = null;

  // #endregion

  public close(name: 'navigation' | 'tools' = null): void {
    if (isNil(name)) {
      this._navigation_sidenav.close();
      this._mat_sidenav.close();
    }

    if (name === 'navigation') {
      this._navigation_sidenav.close();
    }

    if (name === 'tools') {
      this._mat_sidenav.close();
    }
  }

  private _mat_sidenav: MatSidenav = null;
  private _current_sidenav_ref: ComponentRef<C> = null;
  private _sidenav_container: ViewContainerRef = null;

  public output_data$$: Observable<O> = new Observable();
  public openChange$$: Observable<boolean> = new Observable();

  public setContainers(navigation_sidenav: MatSidenav, tools_sidenav: MatSidenav, sidenav_vc: ViewContainerRef): void {
    this._navigation_sidenav = navigation_sidenav;

    this._mat_sidenav = tools_sidenav;
    this._sidenav_container = sidenav_vc;
    this.openChange$$ = this._mat_sidenav.openedChange.asObservable();
  }

  public emitDataToSidenav(data: I): void {
    if (this._current_sidenav_ref && this._current_sidenav_ref.instance) {
      this._current_sidenav_ref.instance.emit(data);
    } else {
      console.log('WARN, sidenav not ready yet / imposible to push data');
    }
  }

  public remove(): void {
    if (!isNil(this._current_sidenav_ref)) {
      this._current_sidenav_ref.destroy();
      this._current_sidenav_ref = null;
    }
    if (!isNil(this._sidenav_container)) {
      this._sidenav_container.clear();
    }

    this._mat_sidenav.close();
  }

  public load(type: Type<C>, column_config?: DatatableColumn<any>[]): void {
    if (isNil(this._sidenav_container)) {
      console.log('WARN, sidenav container not ready yet');
      return;
    }
    // Destroy previous loaded sidenav
    this.remove();

    // Create new sidebav
    this._current_sidenav_ref = this._sidenav_container.createComponent(type);

    if (column_config) {
      (this._current_sidenav_ref.instance as any).COLUMN_CONFIG = column_config;
    }

    this.output_data$$ = this._current_sidenav_ref.instance.output_data$$ || of('debug');
  }

  public toggle(): void {
    this._mat_sidenav.toggle();
  }

  public get(): C {
    return this._current_sidenav_ref?.instance;
  }
}
