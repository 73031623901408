import { ChangeDetectionStrategy, Component } from '@angular/core';

import { AppStateService } from 'app/core/app-state.service';

@Component({
  selector: 'bg2-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class USAboutComponent {
  // #region -> (component basics)

  constructor(public readonly appState: AppStateService) {}

  // #endregion
}
