import { union } from 'polygon-clipping';

import { turf_polygon } from '../turf-polygon/turf-polygon';
import { turf_get_geom } from '../turf-get-geom/turf-get-geom';
import { turf_multipolygon } from '../turf-multipolygon/turf-multipolygon';

// From https://github.com/Turfjs/turf/blob/84110709afda447a686ccdf55724af6ca755c1f8/packages/turf-union/index.ts
export const turf_union = <P = any>(poly1: any, poly2: any, options: { properties?: P } = {}): any => {
  const geom1: any = turf_get_geom(poly1);
  const geom2: any = turf_get_geom(poly2);

  const unioned = union(geom1.coordinates as any, geom2.coordinates as any);

  if (unioned.length === 0) {
    return null;
  }

  if (unioned.length === 1) {
    return turf_polygon(unioned[0], options.properties);
  } else {
    return turf_multipolygon(unioned, options.properties);
  }
};
