import { isNil } from 'lodash-es';
import { toXML } from 'jstoxml';

export class KMLBuilderHelper {
  private encoding = 'utf-8';
  private file: string = null;
  private kml = {
    name: 'KML Routes',
    open: '1',
    description: 'Routes History',
    Folder: [] as any[],
  };
  private name: string;

  constructor() {}

  startKml(name = 'kml', visibility = 1) {
    this.name = name;
    this.kml.Folder.push({name: 'KML_Folder'});
  }

  /*****
    Tour Operation
    *****/

  createTour(ID: string, name: string, description: string) {
    this.buildTour(ID, name, description);
  }

  buildTour(ID: string, name: string, description: string) {
    this.kml.Folder.push({
      _name: 'gx:Tour',
      _attrs: {
        id: ID,
      },
      _content: [
        { name: name },
        { description: description },
        {
          _name: 'gx:Playlist',
          _content: [],
        },
      ],
    });
  }

  addIntoTour(id: string, lon: number, lat: number, alt: number, head: number, tilt: number, altMode: string) {
    const index: number = this.getTagById('gx:Tour', id);
    this.kml.Folder[index]._content[2]._content.push(this.generateFlyTo(lon, lat, alt, head, tilt, altMode));
  }

  createOrbit(id: string, name: string, description: string, latitude: number, longitude: number, range: number) {
    this.createTour(id, name, description);
    let heading = 0;
    for (let i = 0; i < 300; i++) {
      this.addIntoTour(id, longitude, latitude, range, heading, 58, 'relativeToGround');
      heading = (heading + 10) % 360;
    }
  }

  generateFlyTo(
    lon: number,
    lat: number,
    range: number,
    head: number,
    tilt: number,
    altMode = 'relativeToGround',
    duration = 2
  ) {
    const data: any = {
      _name: 'gx:FlyTo',
      _content: [
        { _name: 'gx:flyToMode', _content: 'smooth' },
        { _name: 'gx:duration', _content: '1' },
      ],
    };
    data._content.push(this.generateVisionTag('LookAt', lon, lat, range, head, tilt, altMode));
    return data;
  }

  //generate Placemarks
  addPlacemark(
    id: string,
    name: string,
    lon: number,
    lat: number,
    range: number,
    altMode = 'relativeToGround',
    description: string,
    icon: string,
    scale: number
  ) {
    const data = this.generatePlacemark(id, name, lon, lat, range, altMode, description, icon, scale);
    this.kml.Folder.push(data);
  }

  deleteTagById(tag: string, id: string) {
    const index = this.getTagById(tag, id);
    if (!isNil(index)) {
      this.kml.Folder.splice(index, 1);
    }
  }

  editBalloonState(id: string, newValue: string) {
    const index: number = this.getTagById('Placemark', id);
    this.kml.Folder[index]._content[2].text = newValue;
  }

  editCoodPlacemark(id: string, lon: number, lat: number, range: number) {
    const index: number = this.getTagById('Placemark', id);

    // if( index === null ){
    //   console.log("out")
    //   return false
    // }
    // this.kml.Folder[index]._content[5]._content[0].text = lat + ',' + lon
    // this.kml.Folder[index]._content[4]._content[0].text = lon
    this.kml.Folder[index]._content[4]._content[0].text = lat + ',' + lon + ',' + range;
  }

  addGroundOverlay(
    id: string,
    name: string,
    url: string,
    firstCorner: string,
    secondCorner: string,
    thirdCorner: string,
    fourthCorner: string
  ) {
    const corners = firstCorner + ' ' + secondCorner + ' ' + thirdCorner + ' ' + fourthCorner;
    const data = {
      _name: 'GroundOverlay',
      _attrs: { id: id },
      _content: [
        { name: name },
        {
          _name: 'Icon',
          _content: [{ _name: 'href', _content: url }],
        },
        {
          _name: 'gx:LatLonQuad',
          _content: [
            {
              _name: 'coordinates',
              _content: corners,
            },
          ],
        },
      ],
    };
    this.kml.Folder.push(data);
  }

  generatePlacemark(
    id: string,
    name: string,
    lon: number,
    lat: number,
    range: number,
    altMode: string,
    description: string,
    icon: string,
    scale: number
  ) {
    const data: any = {
      _name: 'Placemark',
      _attrs: { id: id },
      _content: [
        { name: name },
        { _name: 'description', _content: description },
        { _name: 'gx:balloonVisibility', _content: 0 },
        {
          _name: 'LookAt',
          _content: [
            { _name: 'longitude', _content: lon },
            { _name: 'latitude', _content: lat },
            { _name: 'range', _content: range },
          ],
        },
      ],
    };
    data._content.push(this.generatePoint(lon, lat, range, altMode));
    if (!isNil(icon)) {
      const data_icon: any = {
        _name: 'Style',
        _attrs: { id: id + 'icon' },
        _content: [
          {
            _name: 'IconStyle',
            _content: [
              { _name: 'scale', _content: scale },
              {
                _name: 'Icon',
                _content: [{ _name: 'href', _content: icon }],
              },
            ],
          },
        ],
      };
      data._content.push(data_icon);
      data._content.push({
        _name: 'styleUrl',
        _content: '#' + id + 'icon',
      });
    }
    return data;
  }

  // network link creation
  networkLink(id: string, link: string, fly: number, visibility = 1) {
    const data = {
      _name: 'NetworkLink',
      _content: [
        {
          _name: 'Link',
          _content: [
            { _name: 'href', _content: link },
            { _name: 'refreshMode', _content: 'onInterval' },
            { _name: 'refreshInterval', _content: '1 ' },
          ],
        },
      ],
    };
    this.kml.Folder.push(data);
  }

  //
  //  Geometry functions
  //

  //build polygon

  createPolygon(id: string, name: string, extrude = 1) {
    const data = {
      _name: 'Placemark',
      _attrs: { id: id },
      _content: [
        { name: name },
        {
          _name: 'Polygon',
          _content: [
            { _name: 'extrude', _content: 1 },
            { _name: 'altitudeMode', _content: 'relativeToGround' },
          ],
        },
      ],
    };
    this.kml.Folder.push(data);
  }

  addOuterBoundary(polygonId: string, coordinates: string) {
    const index: number = this.getTagById('Placemark', polygonId);
    const data = {
      _name: 'outerBoundaryIs',
      _content: [
        {
          _name: 'LinearRing',
          _content: [
            {
              _name: 'coordinates',
              _content: coordinates,
            },
          ],
        },
      ],
    };
    this.kml.Folder[index]._content[1]._content.push(data);
  }

  addInnerBoundary(polygonId: string, coordinates: string) {
    const index: number = this.getTagById('Placemark', 'pentagon');
    const data = {
      _name: 'innerBoundaryIs',
      _content: [
        {
          _name: 'LinearRing',
          _content: [
            {
              _name: 'coordinates',
              _content: coordinates,
            },
          ],
        },
      ],
    };
    this.kml.Folder[index]._content[1]._content.push(data);
  }

  generatePoint(lon: number, lat: number, range: number, altMode: string) {
    return {
      _name: 'Point',
      _content: [
        {
          _name: 'coordinates',
          _content: lon + ',' + lat + ',' + range,
        },
        {
          _name: 'altitudeMode',
          _content: altMode,
        },
      ],
    };
  }

  createLineString(id: string, name: string, coordinates: string, tessellate = 1) {
    const data = {
      _name: 'Placemark',
      _attrs: { id: id },
      _content: [
        { name: name },
        { styleUrl: '#lineStyle' },
        {
          _name: 'LineString',
          _content: [{ altitudeMode: 'relativeToGround' }, { tessellate: tessellate }, { coordinates: coordinates }],
        },
      ],
    };
    this.kml.Folder.push(data);
  }

  //suport functions
  // Maybe rework
  getTagById(name: string, id: string) {
    let index: number;
    this.kml.Folder.forEach((tag: any, i: number) => {
      // est-ce une bonne chose de désactiver cette option pour cette ligne ?
      // eslint-disable-next-line eqeqeq
      if (tag._name == name && tag._attrs.id == id) {
        index = i;
        return;
      }
    });
    if (index !== undefined) {
      return index;
    }
  }

  //Camera, LookAt
  generateVisionTag(
    name: string,
    lon: number,
    lat: number,
    range: number,
    head: number,
    tilt: number,
    altMode: string
  ) {
    return {
      name: name,
      _content: [
        { _name: 'longitude', _content: lon },
        { _name: 'latitude', _content: lat },
        { _name: 'range', _content: range },
        { _name: 'heading', _content: head },
        { _name: 'tilt', _content: tilt },
        { _name: 'altitudeMode', _content: altMode },
      ],
    };
  }

  writeKML() {
    let kml = this.getKML();
    const text =
      '<kml xmlns=' +
      '"http://www.opengis.net/kml/2.2" ' +
      'xmlns:gx=' +
      ' "http://www.google.com/kml/ext/2.2">' +
      '<Document> <Style id = "lineStyle" ><LineStyle><color>ff4ab5f5</color><width>5</width></LineStyle></Style>';

    kml = kml.replace('<kml>', text);
    kml = kml.replace('</kml>', '</Document></kml>');

    return kml;
  }

  getKML() {
    const data = this.kml;
    const doc = { kml: data };
    return toXML(doc);
    // jsontoXML.
  }
}
