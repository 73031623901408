import { BgControlWidgetComponent as ControlWidget } from '../control/control.widget';

import { uniqueId as _uniqueId } from 'lodash-es';

import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Dictionary } from 'app/typings/core/interfaces';

interface ChoiceEntry {
  label: string;
  value: string;
  options: {
    img?: string;
    img_style?: Dictionary<any>;
    line?: number;
    label_style?: any;
  };
}

interface Line {
  choices: ChoiceEntry[];
}

@Component({
  selector: 'ef-bg2checklist-widget',
  templateUrl: './bg2checklist.widget.html',
  styleUrls: ['./bg2checklist.widget.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EfBg2ChecklistWidget extends ControlWidget implements OnInit {
  public id = _uniqueId();
  public label: string;

  // Display options
  public display_inline: boolean;

  // List of differents checkbox
  private choices: ChoiceEntry[] = [];
  public choices_lines: Line[] = [];

  ngOnInit(): void {
    super.ngOnInit();
    // Get title group
    this.label = this.schema.label;

    // Get display options
    const options = this.schema.options || {};
    this.display_inline = options.inline || false;

    // Get choices
    this.schema.items.oneOf.forEach((choice: any) => {
      this.choices.push({
        label: choice.label,
        value: choice.enum[0],
        options: choice.options || {},
      });
    });

    // Sort choices by line number
    this.choices.map(choice => {
      const line = choice.options.line || 0;
      while (this.choices_lines.length <= line) {
        this.choices_lines.push({
          choices: [],
        });
      }
      this.choices_lines[line].choices.push(choice);
    });
  }
}
