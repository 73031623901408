import { Component, Input, Output, OnDestroy, EventEmitter, ChangeDetectionStrategy, OnInit } from '@angular/core';

import { Subscription, BehaviorSubject, Observable, combineLatest, concat, of, Subject } from 'rxjs';
import { tap, filter, switchMap, debounceTime } from 'rxjs';

import { isEqual, isNil } from 'lodash-es';

import { Event } from 'app/models';
import { AppStateService } from 'app/core/app-state.service';
import { ConsoleLoggerService } from 'app/core/console-logger.service';
import { distinctUntilRealChanged } from '@bg2app/tools/rxjs';
import { EventForm } from 'app/widgets/event-form/eventforms.helpers';
import { replay } from '@bg2app/tools/rxjs';

/** Build a form to create/update an event
 *
 * Note sur le parcours des données
 *  -> modification par l'utilisateur depuis le formulaire
 *  -> callback onFormChange()
 *  -> si modif effective callback onFormChanged()
 *  -> this.event_form.updateEvent(fdata.date, fdata.apply_to, fdata.data);
 *      - mise a jour de l'event
 *      - (si changement des entities) => event_form.recomputeDynamicEntities()
 *  -> lorsque fin du calcul des entités dynamiques -> event_form.recomputeFormModel()
 *  -> mise a jour du form_model (par subscribe sur this.event_form.form_model$)
 */
@Component({
  selector: 'bg2-event-form-inline',
  templateUrl: './event-form-inline.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EventFormInlineComponent implements OnInit, OnDestroy {
  private _logger = new ConsoleLoggerService(this.constructor.name, true);

  @Input()
  public disabled = false;

  private _event$: BehaviorSubject<Event> = new BehaviorSubject(null);
  public event$$: Observable<Event> = this._event$.asObservable();

  private _form_data$: Subject<Event> = new Subject();

  @Input()
  public set event(event: Event) {
    this._event$.next(event);
  }
  public get event(): Event {
    return this._event$.getValue();
  }

  private _args$: BehaviorSubject<any> = new BehaviorSubject(null);
  private args$$: Observable<any> = this._args$.asObservable();

  @Input()
  public set args(args: any) {
    this._args$.next(args);
  }

  @Output()
  public isSchema: EventEmitter<boolean> = new EventEmitter();

  @Output()
  public isBeta: EventEmitter<boolean> = new EventEmitter();

  @Output()
  public isValid: EventEmitter<boolean> = new EventEmitter();

  @Output()
  public eventChange: EventEmitter<string> = new EventEmitter();

  // #region -> (form handlers)

  private _last_form_data: any = null;

  public form_model$$: Observable<any> = this.event$$.pipe(
    filter(event => !isNil(event)),
    switchMap(event => concat(of(event.export()), event.update$$)),
    // tap(event => console.log('export', event, this.internal_change)),
    filter(() => {
      const internal_change = this.internal_change;
      this.internal_change = false;
      return !internal_change;
    }),
    // tap(event => console.log('export 2', event)),
    distinctUntilRealChanged(),
    replay(),
  );

  protected form_ready = false;

  private init_from_modal = false;
  private internal_change = false;

  public form_schema$$ = combineLatest([
    this.event$$, this.args$$
  ]).pipe(
    filter(([event, args]) => !isNil(event)),
    switchMap(([event, args]) => EventForm.buildSchema(event, args)),
    tap(schema => {
      this.isSchema.next(schema);
      this.form_ready = true;
    }),
    replay()
  );

  public setFormValid(value: any): void {
    this.isValid.emit(value);
  }

  public onFormChange(event: any): void {
    if (!this.form_ready || isNil(this.event)) {
      return;
    }
    if (isNil(event.value) || isEqual(this._last_form_data, event.value)) {
      return;
    }
    this._last_form_data = event.value;
    this.onFormChanged(event);
    this.eventChange.emit(this._last_form_data);
  }

  protected onFormChanged(fdata: any): void {
    this._form_data$.next(fdata.value);
  }

  public logErrors(errors: any): void {
    if (errors !== null && errors !== undefined) {
      this._logger.log_error(errors);
    }
  }
  // #endregion

  // #region -> (component basics)
  private _event_form_schema_sub: Subscription = null;
  private _form_data_sub: Subscription = null;

  constructor(
    public appState: AppStateService
  ) { }

  ngOnInit(): void {
    this._event_form_schema_sub = this.form_schema$$.subscribe(schema => {
      this.isBeta.emit(schema.options.beta);
    });

    this._form_data_sub = this._form_data$.pipe(
      filter(() => {
        const init_done = this.init_from_modal;
        this.init_from_modal = true;
        return init_done;
      }),
      debounceTime(200),
      // tap(fdata => console.log('Update event with:', fdata)),
    ).subscribe(fdata => {
      this.internal_change = true;
      this.event.update(fdata.date, fdata.apply_to, fdata.data);
    });
  }

  ngOnDestroy(): void {
    this._event_form_schema_sub?.unsubscribe();
    this._form_data_sub?.unsubscribe();
  }

  // #endregion

}
