import { AfterViewInit, ChangeDetectionStrategy, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';

import { assign, isEmpty, isNil, merge } from 'lodash-es';

import { FileInputComponent } from 'ngx-material-file-input';

import { Beeguard2Api } from 'app/core';
import { AppStateService } from 'app/core/app-state.service';
import { DialogsService } from 'app/widgets/dialogs-modals/dialogs.service';

import { BgControlWidgetComponent, WidgetOptions } from '../control/control.widget';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { FileInput, FileValidator } from 'ngx-material-file-input';
import { map, Subscription } from 'rxjs';
import { distinctUntilKeysChanged, distinctUntilRealChanged, replay } from '@bg2app/tools/rxjs';
import { is_all_properties_null } from 'app/misc/tools';
import { ImageCropperDialogComponent } from 'app/widgets/dialogs-modals/image-cropper-dialog/image-cropper-dialog.component';

/** */
export interface NgMatFileWidgetOptions extends WidgetOptions {
  /**
   * List of accepted formats separated by commas.
   *
   * @default 'image/*'
   *
   * @example '.jpg,.jpeg,.png' // Accept "specific image file".
   * @example 'audio/*' // Accept "any audio file".
   * @example 'video/*' // Accept "any video file".
   * @example 'image/*' // Accept "any image file".
   * @example 'image/*,.pdf' // Accept only "any image file and PDF".
   */
  accept: string;

  /**
   * Max size of file in bytes
   */
  max_size?: number;

  /**
   * Options for the cropper.
   */
  cropper_options?: {
    /**
     * The width / height ratio (e.g. 1 / 1 for a square, 4 / 3, 16 / 9 ...).
     */
    aspectRatio?: number;

    /**
     * The cropper cannot be made bigger than this number of pixels in width.
     */
    width?: number;

    /**
     * The cropper cannot be made bigger than this number of pixels in height.
     */
    height?: number;
  };
}

@Component({
  selector: 'bg2-ng-mat-file',
  templateUrl: './ng-mat-file.component.html',
  styleUrls: ['./ng-mat-file.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NgMatFileComponent extends BgControlWidgetComponent implements OnInit, AfterViewInit, OnDestroy {
  // #region -> (component basics)

  @ViewChild('matFileInput')
  public matFileInput: FileInputComponent = null;

  /** */
  private _incoming_value_sub: Subscription = null;

  /** */
  private _outgoing_value_sub: Subscription = null;

  /** */
  private _has_value_sub: Subscription = null;

  /** */
  public options: NgMatFileWidgetOptions = merge(
    <NgMatFileWidgetOptions>{
      max_size: null,
      accept: 'image/*',
      cropper_options: {
        aspectRatio: 1 / 1,
        width: 0,
        height: 0,
      },
    },
    this.options
  );

  /** */
  constructor(private _bg2Api: Beeguard2Api, private _dialogs: DialogsService, private _appState: AppStateService) {
    super(_bg2Api, _appState, _dialogs);

    if (this.schema?.isRequired || this?.schema?.required) {
      this.form_group.controls.file.addValidators(Validators.required);
    }

    if (!isNil(this.options?.max_size)) {
      this.form_group.controls.file.addValidators(FileValidator.maxContentSize(this.options.max_size));
    }
  }

  ngOnInit(): void {
    super.ngOnInit();

    this._has_value_sub = this.has_value$$.subscribe({
      next: has_value => {
        if (has_value) {
          this.form_group.disable();
        } else {
          this.form_group.enable();
        }
      },
    });
  }

  ngAfterViewInit(): void {
    super.ngAfterViewInit();

    this._incoming_value_sub = this.value$$.subscribe({
      next: (value: { lastModified: '2023-03-02T12:04:45+00:00'; name: 'ducklings.jpg'; size: 65576; type: 'image/jpg' }) => {
        if (isNil(value) || isEmpty(value)) {
          return;
        }

        const file_input = new FileInput([
          new File([], value.name, {
            lastModified: new Date(value.lastModified).getTime(),
            type: value.type,
          }),
        ]);

        this.form_group.patchValue({
          name: value.name,
          size: value.size,
          type: value.type,
          file_input: file_input,
          lastModified: value.lastModified,
        });
      },
    });

    this._outgoing_value_sub = this.form_group.valueChanges
      .pipe(distinctUntilKeysChanged('lastModified', 'name', 'size', 'type', 'file'))
      .subscribe({
        next: value => {
          if (isNil(value) || isEmpty(value) || is_all_properties_null(value)) {
            this.formProperty.reset(null, false);
          } else {
            delete value.file_input;
            this.formProperty.setValue(value, false);
          }
        },
      });
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();

    this._has_value_sub?.unsubscribe();
    this._incoming_value_sub?.unsubscribe();
    this._outgoing_value_sub?.unsubscribe();
  }

  // #endregion

  // #region -> (UI help)

  /** */
  public get has_aspect_ratio_limit() {
    return !isNil(this.options?.cropper_options?.aspectRatio);
  }

  /** */
  public get has_width_limit() {
    return !isNil(this.options?.cropper_options?.width);
  }

  /** */
  public get has_height_limit() {
    return !isNil(this.options?.cropper_options?.height);
  }

  /** */
  public get has_cropper_limits() {
    return this.has_aspect_ratio_limit || this.has_width_limit || this.has_height_limit;
  }

  // #endregion

  protected form_group = new FormGroup({
    /**
     * Base64 format of the image
     */
    file: new FormControl<string | ArrayBuffer>(undefined, { validators: [] }),

    /**
     * File input of form
     */
    file_input: new FormControl<FileInput>(undefined, { validators: [] }),

    /**
     * Name of the image with extension.
     */
    name: new FormControl<string>(undefined, { validators: [Validators.required] }),

    /**
     * Timestamp of last modification of the file (milliseconds)
     */
    lastModified: new FormControl<string>(undefined, { validators: [Validators.required] }),

    /**
     * Size of the file in bytes
     */
    size: new FormControl<number>(undefined, { validators: [Validators.required] }),

    /**
     * Type of the file
     */
    type: new FormControl<string>(undefined, { validators: [Validators.required] }),
  });

  /** */
  public has_value$$ = this.form_group.valueChanges.pipe(
    map(value => !isNil(value.file) || !isNil(value.file_input)),
    distinctUntilRealChanged(),
    replay()
  );

  /** */
  public when_image_changed(event: Event): void {
    const source = <HTMLInputElement>(event?.target ?? event?.srcElement);
    const file = source?.files?.[0] ?? null;

    if (isNil(file)) {
      return;
    }

    this._dialogs
      .open(ImageCropperDialogComponent, {
        file: file,
        options: this.options,
      })
      .pipe(
        map(cropped_image => {
          if (isNil(cropped_image)) {
            return null;
          }

          return cropped_image;
        })
      )
      .subscribe({
        next: response => {
          if (isNil(response)) {
            this.formProperty.reset(null, false);
            return;
          }

          this.form_group.patchValue({
            file: response.base_64,
            name: file.name,
            size: response.size,
            type: response.type,
            lastModified: new Date(file.lastModified).toISOString(),
          });
        },
      });
  }

  /** */
  public delete_file(): void {
    this.matFileInput.clear();
    this.form_group.reset({});
  }
}
