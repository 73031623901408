export enum DEVICE_BATTERY_TYPE {
  P1 = 'P1',
  M1 = 'M1',
  G2 = 'G2',
  G3 = 'G3',
  W1 = 'W1',
  '3P1' = '3P1',
  'unknown' = 'unknown',
}

/**
 * Lookup table for device battery prices.
 */
export const device_battery_price: { [key in keyof typeof DEVICE_BATTERY_TYPE]: number } = {
  P1: 9.5,
  M1: 20,
  G2: 8,
  G3: 9.5,
  W1: 9.5,
  '3P1': 28.5,
  unknown: 0,
};
