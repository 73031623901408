import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { MatBottomSheet, MatBottomSheetRef } from '@angular/material/bottom-sheet';

import { BehaviorSubject } from 'rxjs';
import { replay } from '@bg2app/tools/rxjs';

import { AppStateService } from 'app/core/app-state.service';

import { PullDownFiltersBottomSheetComponent } from '../pull-down-filters-bottom-sheet/pull-down-filters-bottom-sheet.component';

import { AbstractFilterMngt } from 'app/models/filters';

@Component({
  selector: 'bg2-pull-down-filters',
  templateUrl: './pull-down-filters.component.html',
  styleUrls: ['./pull-down-filters.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PullDownFiltersComponent {
  private _filters$$ = new BehaviorSubject<AbstractFilterMngt<any>>(null);
  public filters$$ = this._filters$$.asObservable();

  @Input()
  public set filters(val: AbstractFilterMngt<any>) {
    this._filters$$.next(val);
  }

  private _compact$$ = new BehaviorSubject<boolean>(false);
  public compact$$ = this._compact$$.asObservable();

  @Input()
  public set compact(val: boolean) {
    this._compact$$.next(val);
  }

  // #region -> (component basics)

  constructor(public appState: AppStateService, private _bottomSheet: MatBottomSheet) {}

  // #endregion

  // #region -> (filters management)

  public open(): void {
    this._bottomSheet.open(PullDownFiltersBottomSheetComponent, {
      panelClass: 'bg2-bottom-sheet',
      data: {
        filters: this._filters$$.getValue(),
      },
    });
  }

  // #endregion
}
