import { PipeTransform, Pipe } from '@angular/core';
import { AppStateService } from 'app/core/app-state.service';

import { formatDistanceToNow } from 'date-fns';

import { parseDate } from '../tools';

@Pipe({
  name: 'distanceToNow',
  pure: false
})
export class DistanceToNowPipe implements PipeTransform {
  constructor(protected appState: AppStateService) {}

  previousLanguage: Locale = null;
  transform(value: Date | string, includeSeconds: boolean = false, addSuffix: boolean = false, args: any[] = null): string {
    const vdate = parseDate(value);   // ensure this is a Date
    return formatDistanceToNow(vdate, { locale: this.appState.dl.dateFns, includeSeconds, addSuffix });
  }
}

/*
Args options, not handled here:
- includeSeconds : distances less than a minute are more detailed;
- addSuffix : result specifies if now is earlier or later than the passed date;
- Locale : the locale object (handled);
*/
