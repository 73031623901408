import { DOCUMENT } from '@angular/common';
import { OnInit, Inject, Renderer2, OnDestroy, Component, AfterViewInit, ChangeDetectionStrategy, Input } from '@angular/core';

import { endOfYear, startOfYear, subSeconds } from 'date-fns/esm';

import { ConsoleLoggerService } from 'app/core/console-logger.service';

import { LocationYearlyStatsCarouselSlideComponent } from './location-yearly-stats-carousel-slide.component';
import { TimeBasedCarouselContainerComponent } from 'app/widgets/widgets-reusables/carousels/time-based-carousel/time-carousel-container/time-carousel-container.component';
import { Location } from 'app/models';
import { merge } from 'lodash-es';

@Component({
  selector: 'bg2-location-yearly-stats-carousel',
  templateUrl:
    '../../../../../widgets/widgets-reusables/carousels/time-based-carousel/time-carousel-container/time-carousel-container.component.html',
  styleUrls: [
    '../../../../../widgets/widgets-reusables/carousels/time-based-carousel/time-carousel-container/time-carousel-container.component.scss',
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LocationYearlyStatsCarouselComponent extends TimeBasedCarouselContainerComponent implements OnInit, AfterViewInit, OnDestroy {
  // #region -> (carousel inputs)

  @Input()
  public set location(location: Location) {
    this.dynamic_data = merge({}, this.dynamic_data, { location });
  }

  // #endregion

  // #region -> (component basics)

  protected _logger = new ConsoleLoggerService('LocationYearlyStatsCarouselComponent', true);

  constructor(protected _renderer: Renderer2, @Inject(DOCUMENT) protected document: Document) {
    super(_renderer, document);
    this.type_of_card_group_component = LocationYearlyStatsCarouselSlideComponent;
  }

  ngOnInit(): void {
    this.formatters = { date: 'yyyy', label: '${dateStart}' };

    this.date_computing.compute_start_date = (end_date: Date): Date => startOfYear(end_date);
    this.date_computing.compute_previous_range = () => {
      this._end_date = subSeconds(this._start_date, 1);
      this._start_date = this.date_computing.compute_start_date(this._end_date);

      this.new_card_group_input_data = {
        start_date: this._start_date,
        end_date: this._end_date,
      };
    };

    this._end_date = endOfYear(new Date());
    this._start_date = this.date_computing.compute_start_date(this._end_date);

    super.ngOnInit();
  }

  ngAfterViewInit(): void {
    super.ngAfterViewInit();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  // #endregion
}
