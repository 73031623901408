<div class="login-component">
  <div class="login-component__container mat-elevation-z3">
    <div class="login-component__container__language">
      <bg2-lang-select></bg2-lang-select>
    </div>

    <div class="login-component__container__logo">
      <img [src]="logo" />
    </div>

    <div class="login-component__container__title">{{ 'VIEWS.AUTH.LOGIN.Connection to the app' | translate }}</div>

    <div class="login-component__container__welcome-message">
      <span class="login-component__container__welcome-message__main">{{ 'VIEWS.AUTH.LOGIN.Welcome message' | translate }}</span>
      <span class="login-component__container__welcome-message__version"
        ><i
          >{{ 'ALL.COMMON.Version' | translate | colon }}<b>{{ env?.version }}</b></i
        ></span
      >
    </div>

    <div *ngIf="error$$ | async; let error" class="login-component__container__invalid-token">
      <div class="login-component__container__invalid-token__icon">
        <span class="mdi mdi-24px mdi-alert-circle"></span>
      </div>

      <div class="login-component__container__invalid-token__message">
        <ng-container *ngIf="['disabled_account'].includes(error); else defaultErrorTemplate">
          <ng-container *ngIf="error === 'disabled_account'">
            <span>{{ 'VIEWS.AUTH.LOGIN.ERROR.DISABLED_ACCOUNT.Your account has been disabled' | translate }}</span>
            <span>
              {{ 'VIEWS.AUTH.LOGIN.ERROR.DISABLED_ACCOUNT.Please reach the customer support via' | translate }}
            </span>
            <a href="mailto:contact@beeguard.fr">contact&#64;beeguard.fr</a>
            <span>&nbsp;</span>
            <span>{{ 'VIEWS.AUTH.LOGIN.ERROR.DISABLED_ACCOUNT.or by phone at' | translate }}</span>
            <span>&nbsp;</span>
            <a href="tel:+33562883955">05 62 88 39 55</a>
            <span>.</span>
          </ng-container>
        </ng-container>

        <ng-template #defaultErrorTemplate>
          <span>{{ error | translate }}</span>
        </ng-template>
      </div>
    </div>

    <form class="login-component__container__form-container" [formGroup]="login_form">
      <!-- Not used for now -->
      <mat-form-field [appearance]="'outline'">
        <mat-label>{{ 'VIEWS.AUTH.LOGIN.Mail address' | translate }}</mat-label>
        <input matInput type="email" autocomplete="email" formControlName="email" />
      </mat-form-field>

      <mat-form-field [appearance]="'outline'">
        <mat-label>{{ 'VIEWS.AUTH.LOGIN.Password' | translate }}</mat-label>
        <input matInput [type]="show_password ? 'text' : 'password'" autocomplete="current-password" formControlName="password" />
        <mat-icon matSuffix (click)="show_password = !show_password">
          {{ show_password ? 'visibility_off' : 'visibility' }}
        </mat-icon>
      </mat-form-field>

      <bg2-button
        [type]="'action'"
        [icon]="'mdi-login'"
        [btn_type]="'submit'"
        [disabled]="!login_form.valid"
        [message]="'VIEWS.AUTH.LOGIN.Log in' | translate"
        (btn_click)="login()"
      ></bg2-button>
    </form>

    <mat-divider></mat-divider>

    <div class="login-component__container__no-account-container">
      <span>{{ 'VIEWS.AUTH.LOGIN.NO_ACCOUNT.If you do not have an account yet, you can' | translate }}&nbsp;</span>
      <span>
        <a *ngLet="appState.lang$$ | async; let language" [href]="'https://beeguard.' + (language === 'en' ? 'net' : language)">
          {{ 'VIEWS.AUTH.LOGIN.NO_ACCOUNT.contact us' | translate }}
        </a>
      </span>
      <span>.</span>
    </div>

    <div class="login-component__container__no-account-container">
      <span>{{ 'VIEWS.AUTH.LOGIN.NO_ACCOUNT.Forgot your password ?' | translate }}&nbsp;</span>
      <span
        ><a [routerLink]="['/reset-password']">{{ 'VIEWS.AUTH.LOGIN.Reset now' | translate }}</a></span
      >.
    </div>
  </div>
</div>
