import { AfterViewInit, ChangeDetectionStrategy, Component, Input, NgZone, OnDestroy } from '@angular/core';

import { isNil } from 'lodash-es';

import { take } from 'rxjs/operators';
import { waitForNotNilValue } from '@bg2app/tools/rxjs';

import { AppStateService } from 'app/core/app-state.service';
import { ConsoleLoggerService } from 'app/core/console-logger.service';
import { D3SharedCursorService } from 'app/core/services/global/d3-shared-cursor.service';

import { WindDataPoint } from 'app/models/data';
import { ResizedEvent } from 'app/misc/directives/resized/resized.directive';

import { D3SvgApiaryWindFactory } from './_apiary-wind-d3-chart-factory';
import { DateTime } from 'luxon';

/**
 * @ngModule ApiarySharedModule
 *
 * @description
 *
 * Creates a D3.js chart to display the apiary's weather data.
 *
 * The `ApiaryWindD3ChartComponent` component creates a D3.js chart to display
 * apiary's weather data like temperature, humidity, atmospheric pressure and wind
 * data (heading and speed).
 */
@Component({
  selector: 'bg2-apiary-wind-d3-chart',
  templateUrl: './apiary-wind-d3-chart.component.html',
  styleUrls: ['./apiary-wind-d3-chart.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ApiaryWindD3ChartComponent implements AfterViewInit, OnDestroy {
  // #region -> (component basics)

  private readonly _logger = new ConsoleLoggerService('ApiaryWindD3ChartComponent', true);

  constructor(private _d3_shared_cursor: D3SharedCursorService, private _appState: AppStateService, private _ngZone: NgZone) {
    this.chart_factory = new D3SvgApiaryWindFactory(this._d3_shared_cursor, this._appState, this._ngZone);
  }

  ngAfterViewInit(): void {
    this.chart_factory.create_chart();
  }

  ngOnDestroy(): void {
    this.chart_factory.destroy();
  }

  // #endregion

  // #region -> (container size management)

  public onChartContainerResized(resized_event: ResizedEvent): void {
    this.chart_factory.box_sizing = { width: resized_event?.new_width, height: resized_event?.new_height };
  }

  // #endregion

  // #region -> (data management)

  /** */
  @Input()
  public set wind_data(wind_data: WindDataPoint[]) {
    this.chart_factory.incoming_data = wind_data || null;
  }

  /** */
  @Input()
  public set date_range(date_range: { start: DateTime; end: DateTime }) {
    if (isNil(date_range)) {
      return;
    }

    this.chart_factory.date_range = date_range;
  }

  /** */
  @Input()
  public set is_loading(is_loading: boolean) {
    this.chart_factory.is_loading = is_loading;
  }

  /** */
  @Input()
  public set geoposition(geoposition: { timezone: string; latitude: number; longitude: number }) {
    this.chart_factory.geoposition.value = geoposition;
  }

  // #endregion

  // #region -> (chart management)

  /** */
  public chart_factory: D3SvgApiaryWindFactory = null;

  // #endregion
}
