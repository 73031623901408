import { Pipe, PipeTransform } from '@angular/core';
import { isNil } from 'lodash-es';

import { AppStateService } from 'app/core/app-state.service';

@Pipe({
  name: 'localeNumber',
  pure: false
})
export class LocaleNumberPipe implements PipeTransform {

  constructor(protected appState: AppStateService) { }

  /**
   * @param value
   * @param digits Should be "0.5-5"
   * @param locale
   */
  transform(value: number, digits: string = '0.5-5', locale?: string) {
    if (isNil(value)) {return null;}

    // Get locale
    locale = locale || this.appState.lang || 'fr';

    // Get digits
    const splittedDigits = digits.split('.');

    const maxSignificantDigits = splittedDigits[0];
    const minFractionDigits = splittedDigits[1].split('-')[0];
    const maxFractionDigits = splittedDigits[1].split('-')[1];

    // Return formatted number
    return value.toLocaleString(locale, {
      minimumFractionDigits: parseInt(minFractionDigits, 10),
      maximumFractionDigits: parseInt(maxFractionDigits, 10)
    });
  }

}
