/** */
export enum ZohoCRMModuleName {
  /** */
  Notes = 'Notes',

  /** */
  Tasks = 'Tasks',

  /** */
  Accounts = 'Accounts',

  /** */
  Contacts = 'Contacts',

  /** */
  Deals = 'Deals',
}
