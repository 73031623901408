<ng-container *ngIf="visible$$ | async">
  <form-element-header [schema]="schema" [showLabel]="false" [title_style]="options.title_style"></form-element-header>
  <ng-container *ngIf="!options.readonly">
    <mat-form-field appearance="outline" class="ng-default">
      <mat-label>{{ schema.label | translate }}</mat-label>
      <input matInput
        [id]="uid"
        [name]="name"
        [maxlength]="10"
        [ngModel]="value$$ | async"
        [type]="this.getInputType()"
        (ngModelChange)="value = $event"
        [maxlength]="options?.max_length"
        [placeholder]="schema.placeholder | translate"
        [required]="schema?.isRequired">
        <mat-hint *ngIf="options?.max_length !== null" align="end">{{(value$$ | async).length}} / {{options?.max_length}}</mat-hint>
    </mat-form-field>
  </ng-container>
  <!-- WARNING: we use 'style.display' and not ngIf
    this is to ensure to have 'value$$ | async' in the dom
    elsewhere we got sync issue in input's ngModel 
  -->
  <div [style.display]="options.readonly ? 'block' : 'none'">
    <span>{{ schema.label | translate }}&nbsp;: {{ value$$ | async }}</span>
  </div>
</ng-container>