import { isEmpty, isNil } from 'lodash-es';

import { BehaviorSubject, map } from 'rxjs';
import { replay } from '@bg2app/tools/rxjs';

import { IZohoBooksInvoice } from '../interfaces';
import { ZohoBaseModel } from '../../../../abstract';
import { parseDate } from 'app/misc/tools';
import { endOfDay } from 'date-fns/esm';

/** */
export class ZohoBooksInvoice extends ZohoBaseModel<IZohoBooksInvoice> {
  // #region -> (class basics)

  constructor() {
    super();
  }

  // #endregion

  // #region -> (base identification)

  /** */
  protected set invoice_id(invoice_id: string) {
    super.id = invoice_id;
  }

  // #endregion

  // #region -> (customer data)

  /** */
  protected _customer_name$$ = new BehaviorSubject<string>(null);

  /** */
  public customer_name$$ = this._customer_name$$.asObservable();

  /** */
  public set customer_name(customer_name: string) {
    this._customer_name$$.next(customer_name);
  }

  /** */
  public get customer_name(): string {
    return this._customer_name$$.getValue();
  }

  // #endregion

  // #region -> (invoice details)

  /** */
  protected _created_time$$ = new BehaviorSubject<Date>(null);

  /** */
  public created_time$$ = this._created_time$$.asObservable();

  /** */
  public set created_time(created_time: Date) {
    if (isNil(created_time)) {
      this._created_time$$.next(created_time);
      return;
    }

    const date = parseDate(created_time);
    this._created_time$$.next(date);
  }

  /** */
  public get created_time(): Date {
    return this._created_time$$.getValue();
  }

  /** */
  protected _due_date$$ = new BehaviorSubject<Date>(null);

  /** */
  public due_date$$ = this._due_date$$.asObservable();

  /** */
  public set due_date(due_date: Date) {
    if (isNil(due_date)) {
      this._due_date$$.next(due_date);
      return;
    }

    const date = parseDate(due_date);
    this._due_date$$.next(endOfDay(date));
  }

  /** */
  public get due_date(): Date {
    return this._due_date$$.getValue();
  }

  /** */
  protected _invoice_number$$ = new BehaviorSubject<string>(null);

  /** */
  public invoice_number$$ = this._invoice_number$$.asObservable();

  /** */
  public set invoice_number(invoice_number: string) {
    this._invoice_number$$.next(invoice_number);
  }

  /** */
  public get invoice_number(): string {
    return this._invoice_number$$.getValue();
  }

  /** */
  protected _reference_number$$ = new BehaviorSubject<string>(null);

  /** */
  public reference_number$$ = this._reference_number$$.asObservable();

  /** */
  public set reference_number(reference_number: string) {
    this._reference_number$$.next(reference_number);
  }

  /** */
  public get reference_number(): string {
    return this._reference_number$$.getValue();
  }

  // #endregion

  // #region -> (price details)

  /** */
  protected _total$$ = new BehaviorSubject<number>(null);

  /** */
  public total$$ = this._total$$.asObservable();

  /** */
  public set total(total: number) {
    this._total$$.next(total);
  }

  /** */
  public get total(): number {
    return this._total$$.getValue();
  }

  // #endregion

  /**
   * URL to invoice in the web browser
   */
  public url$$ = this.id$$.pipe(
    map(id => {
      if (isNil(id) || isEmpty(id)) {
        return null;
      }

      return `https://books.zoho.eu/app/20067795982#/invoices/${id}`;
    }),
    replay()
  );
}
