<div class="bg2-zoho-note-summary-component">
  <div *ngLet="note$$ | async; let note" class="note-container">
    <div class="note-container__description-container">
      <span class="note-container__description-container__subject">
        {{ 'VIEWS.ZOHO.SHARED.CRM.NOTE_SUMMARY.Note' | translate : { created_time: note?.created_time$$ | async | formatDate : 'short' } }}
      </span>

      <div class="date-and-owner">
        <span class="created-time">{{ note?.created_time$$ | async | formatDate : 'short' }}</span>
        <span class="owner">{{ 'MODELS.USER.MISC.By [user]' | translate : { user: (note?.owner$$ | async)?.name } }}</span>
      </div>

      <span class="note-container__description-container__content">
        {{ note?.note_content$$ | async }}
      </span>
    </div>
  </div>
</div>
