import { Injectable } from '@angular/core';

import { isNil, mapValues, pick } from 'lodash-es';

import { BehaviorSubject, debounceTime, fromEvent, map, Observable, of, Subscription, take, tap } from 'rxjs';
import { distinctUntilRealChanged, replay } from 'app/misc/tools/rxjs';

import { ConsoleLoggerService } from 'app/core/console-logger.service';

import { Dictionary } from 'app/typings/core/interfaces';

@Injectable({
  providedIn: 'root',
})
export class LocalStorageService {
  // #region -> (service basics)

  /** */
  private readonly LOGGER: ConsoleLoggerService = new ConsoleLoggerService('LocalStorageService', false);

  /** */
  private _local_storage_changes_sub: Subscription = null;

  constructor() {
    // Load initial data
    const data = Object.keys(localStorage)
      .map(key => ({ key, value: localStorage.getItem(key) }))
      .reduce((result: Dictionary<any>, current, self) => {
        result[current.key] = current.value;
        return result;
      }, {});

    this._local_storage_data$$.next(data);

    // Subscribe to storage changes
    this._local_storage_changes_sub = fromEvent(window, 'storage')
      .pipe(debounceTime(100))
      .subscribe({
        next: (event: StorageEvent) => {
          this.LOGGER.debug('Catched storage modification : ', { newValue: event.newValue, oldValue: event.oldValue, key: event.key });

          const previous_storage = this._local_storage_data$$.getValue();
          previous_storage[event.key] = event?.newValue;

          this._local_storage_data$$.next(previous_storage);
        },
      });

    // this.local_storage_data$$.subscribe();
  }

  // #endregion

  // #region -> (local storage)

  /** */
  private readonly PARSER_METHOD: Dictionary<(value: any) => any> = {
    zoho_credentials: (value: any) => JSON.parse(value),
    zoho_ignore_auth: (value: any) => JSON.parse(value),
  };

  /** */
  private _local_storage_data$$ = new BehaviorSubject(null);

  /** */
  private local_storage_data$$ = this._local_storage_data$$.asObservable().pipe(replay());

  /** */
  public put(primary_key: string, value: string): Observable<string> {
    return of(value).pipe(
      map(_value => {
        const old_value = window.localStorage.getItem(primary_key);

        const event = new StorageEvent('storage', {
          key: primary_key,
          oldValue: old_value,
          newValue: value,
        });

        window.localStorage.setItem(primary_key, value);
        window.dispatchEvent(event);
        return value;
      }),
      take(1)
    );
  }

  /** */
  public get<T = any>(primary_key: string): T {
    const value = window?.localStorage.getItem(primary_key) ?? null;
    const parser_method = this.PARSER_METHOD[primary_key] ?? null;

    if (isNil(parser_method)) {
      throw new Error('Undefined parser method');
    }

    const parsed_value = parser_method(value);
    return parsed_value;
  }

  // #endregion

  // #region -> (credentials)

  /** */
  public credentials$$ = this.local_storage_data$$.pipe(
    map(data => pick(data, ['zoho_credentials'])),
    map(credentials =>
      mapValues(credentials, (value, key) => {
        const parser_method = this.PARSER_METHOD[key];

        if (isNil(parser_method)) {
          throw new Error('Undefined parser method');
        }

        const parsed_value = parser_method(value);
        return parsed_value;
      })
    ),
    distinctUntilRealChanged(),
    replay()
  );

  // #endregion
}
