import { HttpErrorResponse, HttpStatusCode } from '@angular/common/http';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Component, ChangeDetectionStrategy, OnInit, OnDestroy, Inject } from '@angular/core';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';

import * as queryString from 'query-string';

import { marker as i18n } from '@biesbjerg/ngx-translate-extract-marker';

import { BehaviorSubject, catchError, filter, map, of, Subscription, take, throwError } from 'rxjs';

import { isNil, keys, merge } from 'lodash-es';

import { BeeguardAuthService } from 'app/core';
import { AppStateService } from 'app/core/app-state.service';
import { ConsoleLoggerService } from 'app/core/console-logger.service';

import { Dictionary } from 'app/typings/core/interfaces';
import { RedirectRoute } from 'app/models/misc/redirect-route.interface';

import { environment } from 'environments/environment';
import { ENV } from 'app/core/providers/environment.provider';
import { IEnvironment } from 'environments/common';

@Component({
  selector: 'bg2-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoginComponent implements OnInit, OnDestroy {
  // #region -> (component basics)

  /** */
  private readonly LOGGER = new ConsoleLoggerService('LoginComponent', true);

  /** */
  private _already_authorized_sub: Subscription = null;

  /** */
  private _invalid_token_sub: Subscription = null;

  constructor(
    @Inject(ENV)
    public readonly env: IEnvironment,
    public readonly appState: AppStateService,

    private readonly _router: Router,
    private readonly _auth: BeeguardAuthService,
    private readonly _activatedRoute: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this._already_authorized_sub = this._auth.hasValidAccessToken$().subscribe({
      next: has_valid_access_token => {
        if (has_valid_access_token?.valid) {
          this._router.navigate(['/locations/all', {}], {
            replaceUrl: true,
            queryParams: {},
          });
        }
      },
    });

    this._invalid_token_sub = this.is_token_invalid$$.pipe(filter(Boolean)).subscribe({
      next: () => {
        this._auth.logout(false);
        this._error$$.next(i18n<string>('VIEWS.AUTH.LOGIN.NOT_AUTHORIZED.Your access token has expired, you must re-login'));
      },
    });
  }

  ngOnDestroy(): void {
    this._invalid_token_sub?.unsubscribe();
    this._already_authorized_sub?.unsubscribe();
  }

  // #endregion

  // #region -> (activated route params)

  /** */
  private type$$ = this._activatedRoute.queryParamMap.pipe(map(parameters => parameters?.get('logType')));

  /** */
  private is_token_invalid$$ = this.type$$.pipe(map(type => type === 'invalid-access-token'));

  // #endregion

  public get logo(): string {
    if (this.env.env === 'apismart') {
      return 'assets/logo_apismart.png';
    }

    if (this.env.env === 'prod') {
      return 'assets/logo.svg';
    }

    return `assets/logo_${this.env.env}.svg`;
  }

  // #region -> (hide/show password)

  public show_password = false;

  // #endregion

  // #region -> (login form management)

  /** */
  public login_form = new UntypedFormGroup({
    email: new UntypedFormControl(null, [Validators.required]),
    password: new UntypedFormControl(null, [Validators.required]),
  });

  // #endregion

  // #region -> (error management)

  /** */
  private _error$$ = new BehaviorSubject<string>(null);

  /** */
  public error$$ = this._error$$.asObservable();

  // #endregion

  /** */
  public login(): void {
    this._error$$.next(null);

    const form_value = this.login_form.value;
    this._auth
      .login(form_value.email, form_value.password)
      .pipe(
        catchError((error: unknown) => {
          if (error instanceof HttpErrorResponse) {
            if (error.status === HttpStatusCode.Locked) {
              return throwError(() => 'disabled_account');
            }

            return throwError(() => error?.error?.message ?? error?.message);
          }

          return throwError(() => i18n<string>('ALL.ERROR.An unknown error occured !'));
        })
      )
      .subscribe({
        next: () => {
          const { commands, extras } = this.prepare_redirect_route();

          this._router
            .navigate(commands, merge({}, extras))
            .catch((reason: any) => this.LOGGER.error(reason))
            .finally(() => localStorage.removeItem('redirect'));
        },
        error: (error: unknown) => {
          this._error$$.next(error as string);
        },
      });
  }

  /** */
  private prepare_redirect_route(): { commands: any[]; extras: NavigationExtras } {
    const redirect: RedirectRoute = JSON.parse(localStorage.getItem('redirect') ?? null);

    if (isNil(redirect)) {
      return { commands: ['/'], extras: {} };
    }

    const commands: any[] = [];

    // Check if redirect to modal
    if (redirect.modal) {
      const final_params: Dictionary<any> = {};

      if ((redirect.modal_params ?? '').length > 0) {
        redirect.modal_params.split(';').forEach((current: `${string}=${string}`) => {
          let key = current.split('=')[0];
          let value = current.split('=')[1];

          final_params[key] = value;
        }, {});
      }

      if (keys(redirect.modal_args ?? {}).length > 0) {
        final_params.args = JSON.stringify(redirect.modal_args);
      }

      commands.push({
        outlets: {
          primary: redirect.uri,
          modal: [redirect.modal, final_params],
        },
      });
    } else {
      commands.push(redirect.uri);
    }

    return {
      commands,
      extras: {
        queryParams: queryString.default.parse(redirect.uri_params, {
          arrayFormat: 'bracket-separator',
          arrayFormatSeparator: ',',
        }),
      },
    };
  }
}
