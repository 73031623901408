import { isNil, clone } from 'lodash-es';
import { catchError, map, of, switchMap } from 'rxjs';

import { Beeguard2Api } from 'app/core';

import { Dictionary } from 'app/typings/core/interfaces';

import { Event } from '../Event';
import { Apiary, DeviceConfig, Entity, Hive } from '../..';

import { NopDeviceEvent } from './NopDeviceEvent';

export class DeviceInstall extends NopDeviceEvent {
  protected installDevice(entity: Entity, imei: number, type: string, position: string = null) {
    const state = entity.getPreviousLocalState(this.date, this.id);
    const devices = clone(state.devices || {});
    devices[`${imei}`] = {
      imei,
      type,
      since: {
        date: this.date.toISOString(),
        event_id: this.id || -2,
      },
      position,
    };
    state.devices = devices;
    entity.storeLocalState(this.date, this.id, state);
  }

  protected unInstallDevice(entity: Entity, imei: number, force = false) {
    const state = entity.getPreviousLocalState(this.date, this.id);
    const devices = clone(state.devices || {});
    const imei_str = `${imei}`;
    let device_conf: DeviceConfig = null;
    if (state.devices[imei_str] && (force || state.devices[imei_str].since?.event_id < 0)) {
      device_conf = devices[imei_str];
      delete devices[imei_str];
    }
    state.devices = devices;
    entity.storeLocalState(this.date, this.id, state);
  }
}

export class DevicesWGInstall extends DeviceInstall {
  constructor(protected bg2Api: Beeguard2Api) {
    super(bg2Api);
    this.type = 'devices_WG_install';
    this.resetLogger();
  }

  protected _applyLocally(entities: Dictionary<Entity | Entity[]>): Event {
    // Apiary
    const apiary = entities.apiary as Apiary;
    const apiary_state = apiary.getPreviousLocalState(this.date, this.id);
    apiary.storeLocalState(this.date, this.id, apiary_state);
    // Hive
    const hive = entities.hive as Hive;
    if (!['FRONT', 'BACK', 'LEFT', 'RIGHT'].includes(this.data.wg_configuration)) {
      this.data.wg_configuration = 'FRONT';
    }
    const imei = this.data.WG1.imei;
    this.installDevice(hive, imei, this.data.WG1.type, this.data.wg_configuration);
    // Previous Hive
    const previous_hive = entities.previous_hive_wg1 as Hive;
    if (previous_hive) {
      this.unInstallDevice(previous_hive, imei, true);
    }
    // Previous Apiary
    const previous_apiary = entities.previous_apiary_wg1 as Apiary;
    if (previous_apiary) {
      const prev_apiary_state = previous_apiary.getPreviousLocalState(this.date, this.id);
      previous_apiary.storeLocalState(this.date, this.id, prev_apiary_state);
    }
    return this;
  }

  protected getI18nParams(translate_service: any, entities: any) {
    return super.getI18nParams(translate_service, entities).pipe(
      map(i18nParams => {
        if (!isNil(i18nParams.data.WG1)) {
          i18nParams.data.WG1 = this.extandDeviceParams(i18nParams.data.WG1, 'WG');
        }
        if (!isNil(i18nParams.data.WG2)) {
          i18nParams.data.WG2 = this.extandDeviceParams(i18nParams.data.WG2, 'WG');
        }
        return i18nParams;
      })
    );
  }
}

export class DeviceGPSInstall extends DeviceInstall {
  constructor(protected bg2Api: Beeguard2Api) {
    super(bg2Api);
    this.type = 'device_GPS_install';
    this.resetLogger();
  }

  protected _applyLocally(entities: Dictionary<Entity | Entity[]>): Event {
    this._logger.debug('Apply GPS INSTALL');
    // Apiary
    const apiary = entities.apiary as Apiary;
    const apiary_state = apiary.getPreviousLocalState(this.date, this.id);
    apiary.storeLocalState(this.date, this.id, apiary_state);
    // Hive
    const hive = entities.hive as Hive;
    this.installDevice(hive, this.data.device.imei, this.data.device.type);
    // Previous Hive
    const previous_hive = entities.previous_hive as Apiary;
    if (previous_hive) {
      this.unInstallDevice(previous_hive, this.data.device.imei, true);
    }
    // Previous Apiary
    const previous_apiary = entities.previous_apiary as Apiary;
    if (previous_apiary) {
      const prev_apiary_state = previous_apiary.getPreviousLocalState(this.date, this.id);
      previous_apiary.storeLocalState(this.date, this.id, prev_apiary_state);
    }
    return this;
  }

  protected getI18nParams(translate_service: any, entities: any) {
    return super.getI18nParams(translate_service, entities).pipe(
      map(i18nParams => {
        if (!isNil(i18nParams.data.device)) {
          i18nParams.data.device = this.extandDeviceParams(i18nParams.data.device, 'GPS');
        }
        return i18nParams;
      })
    );
  }
}

export class DeviceCPTInstall extends DeviceInstall {
  constructor(protected bg2Api: Beeguard2Api) {
    super(bg2Api);
    this.type = 'device_CPT_install';
    this.resetLogger();
  }

  protected _applyLocally(entities: Dictionary<Entity | Entity[]>): Event {
    // Apiary
    const apiary = entities.apiary as Apiary;
    const apiary_state = apiary.getPreviousLocalState(this.date, this.id);
    apiary.storeLocalState(this.date, this.id, apiary_state);
    // Hive
    const hive = entities.hive as Hive;
    this.installDevice(hive, this.data.device.imei, this.data.device.type);
    const previous_hive = entities.previous_hive as Apiary;
    // Previous hive
    if (previous_hive) {
      this.unInstallDevice(previous_hive, this.data.device.imei, true);
    }
    // Previous Apiary
    const previous_apiary = entities.previous_apiary as Apiary;
    if (previous_apiary) {
      const prev_apiary_state = previous_apiary.getPreviousLocalState(this.date, this.id);
      previous_apiary.storeLocalState(this.date, this.id, prev_apiary_state);
    }
    return this;
  }

  protected getI18nParams(translate_service: any, entities: any) {
    return super.getI18nParams(translate_service, entities).pipe(
      map(i18nParams => {
        if (!isNil(i18nParams.data.device)) {
          i18nParams.data.device = this.extandDeviceParams(i18nParams.data.device, 'CPT');
        }
        return i18nParams;
      })
    );
  }
}

export class DeviceBeeLiveInstall extends DeviceInstall {
  constructor(protected bg2Api: Beeguard2Api) {
    super(bg2Api);
    this.type = 'device_BeeLive_install';
    this.resetLogger();
  }

  protected _applyLocally(entities: Dictionary<Entity | Entity[]>): Event {
    // Apiary
    const apiary = entities.apiary as Apiary;
    const apiary_state = apiary.getPreviousLocalState(this.date, this.id);
    apiary.storeLocalState(this.date, this.id, apiary_state);
    // Hive
    const hive = entities.hive as Hive;
    this.installDevice(hive, this.data.device.imei, this.data.device.type);
    const previous_hive = entities.previous_hive as Apiary;
    // Previous hive
    if (previous_hive) {
      this.unInstallDevice(previous_hive, this.data.device.imei, true);
    }
    // Previous Apiary
    const previous_apiary = entities.previous_apiary as Apiary;
    if (previous_apiary) {
      const prev_apiary_state = previous_apiary.getPreviousLocalState(this.date, this.id);
      previous_apiary.storeLocalState(this.date, this.id, prev_apiary_state);
    }
    return this;
  }

  protected getI18nParams(translate_service: any, entities: any) {
    return super.getI18nParams(translate_service, entities).pipe(
      map(i18nParams => {
        if (!isNil(i18nParams.data.device)) {
          i18nParams.data.device = this.extandDeviceParams(i18nParams.data.device, 'BeeLive');
        }
        return i18nParams;
      })
    );
  }
}

export class DeviceRGInstall extends DeviceInstall {
  constructor(protected bg2Api: Beeguard2Api) {
    super(bg2Api);
    this.type = 'device_RG_install';
    this.resetLogger();
  }

  protected _applyLocally(entities: Dictionary<Entity | Entity[]>): Event {
    const apiary = entities.apiary as Apiary;
    this.installDevice(apiary, this.data.device.imei, this.data.device.type);
    const previous_apiary = entities.previous_apiary as Apiary;
    if (previous_apiary) {
      this.unInstallDevice(previous_apiary, this.data.device.imei, true);
    }
    return this;
  }

  protected getI18nParams(translate_service: any, entities: any) {
    return super.getI18nParams(translate_service, entities).pipe(
      map(i18nParams => {
        if (!isNil(i18nParams.data.device)) {
          i18nParams.data.device = this.extandDeviceParams(i18nParams.data.device, 'RG');
        }
        return i18nParams;
      })
    );
  }
}

export class DeviceTGInstall extends DeviceInstall {
  constructor(protected bg2Api: Beeguard2Api) {
    super(bg2Api);
    this.type = 'device_TG_install';
    this.resetLogger();
  }

  protected _applyLocally(entities: Dictionary<Entity | Entity[]>): Event {
    this._logger.debug('Apply TG INSTALL');
    // Apiary
    const apiary = entities.apiary as Apiary;
    const apiary_state = apiary.getPreviousLocalState(this.date, this.id);
    apiary.storeLocalState(this.date, this.id, apiary_state);
    // Hive
    const hive = entities.hive as Hive;
    this.installDevice(hive, this.data.device.imei, this.data.device.type);
    // Previous Hive
    const previous_hive = entities.previous_hive as Apiary;
    if (previous_hive) {
      this.unInstallDevice(previous_hive, this.data.device.imei, true);
    }
    // Previous Apiary
    const previous_apiary = entities.previous_apiary as Apiary;
    if (previous_apiary) {
      const prev_apiary_state = previous_apiary.getPreviousLocalState(this.date, this.id);
      previous_apiary.storeLocalState(this.date, this.id, prev_apiary_state);
    }
    return this;
  }

  protected getI18nParams(translate_service: any, entities: any) {
    return super.getI18nParams(translate_service, entities).pipe(
      map(i18nParams => {
        if (!isNil(i18nParams.data.device)) {
          i18nParams.data.device = this.extandDeviceParams(i18nParams.data.device, 'TG');
        }
        return i18nParams;
      })
    );
  }
}
