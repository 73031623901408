import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { SharedModule } from 'app/shared-module/shared.module';
import { MiscModule } from 'app/misc/misc.module';
import { MiscWidgetsModule } from '../misc-widgets/misc-widgets.module';
import { EventsSharedModule } from 'app/views/events/events-shared.module';
import { DialogsModalsModule } from '../dialogs-modals/dialogs-modals.module';
import { MaterialSharedModule } from 'app/shared-module/material-shared.module';

import { RunErrorDialogComponent } from './components/run-error-dialog/run-error.dialog';

import { SnackbarListComponent } from './components/snackbar-list/snackbar-list.component';
import { SnackbarSingleComponent } from './components/snackbar-single/snackbar-single.component';
import { SnackbarSummmaryComponent } from './components/snackbar-summary/snackbar-summary.component';
import { WidgetsReusableModule } from '../widgets-reusables/widgets-reusables.module';

@NgModule({
  declarations: [SnackbarListComponent, SnackbarSingleComponent, SnackbarSummmaryComponent, RunErrorDialogComponent],
  imports: [
    CommonModule,
    MiscModule,
    RouterModule,
    SharedModule,
    MiscWidgetsModule,
    EventsSharedModule,
    DialogsModalsModule,
    MaterialSharedModule,
    WidgetsReusableModule
  ],
})
export class WidgetsSnackbarModule {}
