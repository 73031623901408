import { Injectable, OnDestroy } from '@angular/core';

import { clone, isNil } from 'lodash-es';

import { isEqual } from 'date-fns';

import { replay, robustCombineLatest } from '@bg2app/tools/rxjs';
import { Subject, Observable, of, Subscription } from 'rxjs';
import { filter, takeWhile, map, switchMap, startWith, tap } from 'rxjs';

import { Beeguard2Api } from '../main/beeguard2-api-service';
import { ConsoleLoggerService } from 'app/core/console-logger.service';

import { Entity } from 'app/models';
import { RunSnapshot } from './models/RunSnapshot';
import { RunSnapshotInterface, RunSnapshotState } from './models/RunSnapshot';


@Injectable({
  providedIn: 'root',
})
export class Beeguard2RunService implements OnDestroy {
  private _logger = new ConsoleLoggerService('Beeguard2RunService', false);

  protected _current_runner_ids = new Set<string>();
  protected _current_runner_ids$$ = new Subject<Set<string>>();
  public current_runner_ids$$ = this._current_runner_ids$$.asObservable().pipe(replay());

  public entity_runners$$ = this.current_runner_ids$$.pipe(
    map(set_of_ids => Array.from(set_of_ids.values())),
    switchMap(ids => {
      const runners$$ = ids.map(id => this.getRunner$$(id));
      return robustCombineLatest(runners$$);
    })
  );

  public run_entities: { [run_id: string]: Set<number> } = {};
  public run_start_date: { [run_id: string]: Date } = {};
  public run_last: { [run_id: string]: RunSnapshot } = {};
  public entity_run: { [nid: number]: string } = {};

  private _all_runs$: Subject<RunSnapshot> = new Subject<RunSnapshot>();
  private all_runs$$: Observable<RunSnapshot> = this._all_runs$.asObservable().pipe(replay());

  private _runners$$_holder: { [run_id: string]: Observable<RunSnapshot> } = {};

  // #region -> (service basics)

  private _bg2api: Beeguard2Api;
  private _all_runs_sub: Subscription = null;
  private _current_runs_sub: Subscription = null;

  public setBg2Api(bg2api: Beeguard2Api) {
    this._bg2api = bg2api;
  }

  constructor() {
    this._current_runs_sub = this.current_runner_ids$$.subscribe();
    this._current_runner_ids$$.next(this._current_runner_ids);

    this._all_runs_sub = this.all_runs$$.subscribe(run => {
      if (!this._runners$$_holder[run.id]) {
        this._runners$$_holder[run.id] = this.all_runs$$.pipe(
          filter(_run => _run.id === run.id),
          takeWhile(_run => _run.state !== RunSnapshotState.done),
          startWith(run),
          replay()
        );
      }

      if (!this._current_runner_ids.has(run.id)) {
        this.run_start_date[run.id] = run.snapshot_date;
        this._current_runner_ids.add(run.id);
        this._current_runner_ids$$.next(this._current_runner_ids);
      }

      this.run_last[run.id] = run;
      if (run.state === RunSnapshotState.done) {
        this.deleteRunner(run.id);
      }
    });
  }

  ngOnDestroy(): void {
    this._all_runs_sub?.unsubscribe();
    this._current_runs_sub?.unsubscribe();
  }

  // #endregion

  // #region -> (runner managers)

  public getRunner$$(run_id: string): Observable<RunSnapshot> {
    if (!this._runners$$_holder[run_id]) {
      console.error('missing obs', run_id);
      return of(null);
    }
    return this._runners$$_holder[run_id];
  }

  public updateRunner(runInterface: RunSnapshotInterface, from_entity: Entity, is_last = false) {
    this._logger.debug(`Run updated: (from ${from_entity.desc}) last: ${is_last} =>`, runInterface.id);

    const runner = RunSnapshot.deserialize(runInterface, this._bg2api);
    const old_run_id = this.entity_run[from_entity.id];

    if (runner) {
      const has_error = !isNil(runner.error);
      const is_registered = this._current_runner_ids.has(runner.id);

      const has_related_runner = !isNil(old_run_id);
      const is_related_runner_registered = this._current_runner_ids.has(old_run_id);

      if (is_last && !has_error && !is_registered && (!has_related_runner || !is_related_runner_registered)) {
        this._logger.debug('is last, no error, not register and (no related runner or related runner is not registered)');
        return;
      }
    }

    if (!this.run_entities[runner.id]) {
      this.run_entities[runner.id] = new Set<number>();
    }

    this.run_entities[runner.id].add(from_entity.id);
    this.entity_run[from_entity.id] = runner.id;

    // store run info, only if this is a recent one
    const previous_run = this.run_last[runner.id];
    if (
      isNil(previous_run) ||
      runner.snapshot_date > previous_run.snapshot_date ||
      (
        isEqual(runner.snapshot_date, previous_run.snapshot_date) &&
        runner.nb_remains <= previous_run.nb_remains
      )
    ) {
      this._logger.debug('Add the runner');
      this._all_runs$.next(runner);
    }

    // Invalide a previous run ?
    if (old_run_id !== runner.id) {
      this._logger.debug('Delete old runner');
      this.deleteRunner(old_run_id);
    }

    // Is the run over for us ?
    // i.e. no more entity
    if (is_last && runner.state !== RunSnapshotState.error) {
      this.run_entities[runner.id].delete(from_entity.id);
      if (this.run_entities[runner.id].size === 0) {
        // Run no more exist, push a last snapshot with state done
        const run_done = clone(runner);
        run_done.state = RunSnapshotState.done;
        this._logger.debug('[debug] run over');
        this._all_runs$.next(run_done);
      }
    }
  }

  private deleteRunner(run_id: string) {
    if (this._runners$$_holder[run_id]) {
      delete this._runners$$_holder[run_id];
    }

    if (this._current_runner_ids.has(run_id)) {
      this._current_runner_ids.delete(run_id);
      this._current_runner_ids$$.next(this._current_runner_ids);
    }

    if (this.run_last[run_id]) {
      delete this.run_last[run_id];
    }
  }

  // #endregion
}
