<div
  class="bg2-snackbar-single-component snackbar-runner"
  [ngClass]="{
    'snackbar-runner-error': runner?.error,
    'ghost-runner': runner.type === RunnerType.RUNNER_GHOST,
    'export-runner': runner.type === RunnerType.RUNNER_EXPORT,
    'entities-runner': runner.type === RunnerType.RUNNER_ENTITIES_COMPUTE
  }"
>
  <span class="title">
    <ng-container *ngIf="!runner?.error">
      <ng-container [ngSwitch]="runner.type">
        <ng-container *ngSwitchCase="RunnerType.RUNNER_ENTITIES_COMPUTE">
          {{
            'WIDGETS.WIDGETS_SNACKBAR.COMPONENTS.SNACKBAR_SINGLE.RUNNER_ENTITIES_COMPUTE.Update of entities in progress, please wait'
              | translate
          }}...
        </ng-container>

        <ng-container *ngSwitchCase="RunnerType.RUNNER_GHOST">
          {{
            'WIDGETS.WIDGETS_SNACKBAR.COMPONENTS.SNACKBAR_SINGLE.RUNNER_GHOST.Compute of ghost entities in progress, please wait'
              | translate
          }}...
        </ng-container>

        <ng-container *ngSwitchCase="RunnerType.RUNNER_EXPORT">
          {{
            'WIDGETS.WIDGETS_SNACKBAR.COMPONENTS.SNACKBAR_SINGLE.RUNNER_EXPORT.Export in progress, please wait'
              | translate
          }}...
        </ng-container>

        <ng-container *ngSwitchDefault>
          {{
            'WIDGETS.WIDGETS_SNACKBAR.COMPONENTS.SNACKBAR_SINGLE.DEFAULT.Update in progress, please wait' | translate
          }}...
        </ng-container>
      </ng-container>
    </ng-container>

    <ng-container *ngIf="runner?.error">
      <span>
        <ng-container [ngSwitch]="runner.type">
          <ng-container *ngSwitchCase="RunnerType.RUNNER_ENTITIES_COMPUTE">
            {{
              'WIDGETS.WIDGETS_SNACKBAR.COMPONENTS.SNACKBAR_SINGLE.RUNNER_ENTITIES_COMPUTE.An error occurred while computing entities !'
                | translate
            }}
          </ng-container>

          <ng-container *ngSwitchCase="RunnerType.RUNNER_GHOST">
            {{
              'WIDGETS.WIDGETS_SNACKBAR.COMPONENTS.SNACKBAR_SINGLE.RUNNER_GHOST.An error occured while computing ghost entities !'
                | translate
            }}
          </ng-container>

          <ng-container *ngSwitchCase="RunnerType.RUNNER_EXPORT">
            {{
              'WIDGETS.WIDGETS_SNACKBAR.COMPONENTS.SNACKBAR_SINGLE.RUNNER_EXPORT.An error occured while exporting the data :'
                | translate
            }}
          </ng-container>

          <ng-container *ngSwitchDefault>
            {{
              'WIDGETS.WIDGETS_SNACKBAR.COMPONENTS.SNACKBAR_SINGLE.DEFAULT.An error occured while updating !'
                | translate
            }}...
          </ng-container>
        </ng-container>
      </span>

      <b (click)="openErrorDialog(runner)" class="link">
        <i class="mdi mdi-arrow-right-bold"></i>
        {{ 'WIDGETS.WIDGETS_SNACKBAR.COMPONENTS.SNACKBAR_SINGLE.DEFAULT.See error' | translate }}
      </b>
    </ng-container>
  </span>

  <div class="run-progress" *ngIf="!runner?.error">
    <mat-progress-bar [value]="(100 * (runner.nb_total - runner.nb_remains)) / runner.nb_total"></mat-progress-bar>
    <span class="progress-value" *ngIf="runner.nb_remains !== null; else unknowProgress">
      {{ ((100 * (runner.nb_total - runner.nb_remains)) / runner.nb_total).toFixed(0) }} % ({{
        runner.nb_total - runner.nb_remains
      }}
      / {{ runner.nb_total }})
    </span>
    <ng-template #unknowProgress>
      <span class="progress-value">
        0 (0 / ?)
      </span>
    </ng-template>
  </div>
</div>
