<ul class="bg-pagination" *ngIf="has_pagination$$ | async">
  <li [class.disabled]="(page$$ | async) === 0" class="waves-effect">
    <a (click)="goto_previous()">
      <i class="mdi mdi-chevron-left mdi-24px"></i>
    </a>
  </li>

  <li *ngFor="let page of pages$$ | async" [class.active]="page.num === (page$$ | async)" class="waves-effect">
    <a (click)="goto(page.num)">{{page.label}}</a>
  </li>

  <li [class.disabled]="(page$$ | async) === (nb_pages$$ | async) - 1"
    class="waves-effect">
    <a (click)="goto_next()">
      <i class="mdi mdi-chevron-right mdi-24px"></i>
    </a>
  </li>
</ul>