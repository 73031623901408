import { marker as i18n } from '@biesbjerg/ngx-translate-extract-marker';

/** */
export enum DeviceConfigOrder {
  /** */
  NO = 'no',

  /** */
  GPS = 'gps',

  /** */
  SCAN = 'scan',
}

const _translate_device_config_order: { [key in DeviceConfigOrder]: string } = {
  no: i18n<string>('DEVICE.ALL.CONFIGURATION.ORDER.no'),
  gps: i18n<string>('DEVICE.ALL.CONFIGURATION.ORDER.gps'),
  scan: i18n<string>('DEVICE.ALL.CONFIGURATION.ORDER.scan'),
};
