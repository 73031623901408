import { marker as i18n } from '@biesbjerg/ngx-translate-extract-marker';

/** */
export enum UserTag {
  UNPAID_WARNING = 'unpaid_warning',
}

/** */
export const user_tag_to_i18n: { [key in UserTag]: string } = {
  unpaid_warning: i18n<string>('MODELS.USER.ENUMERATORS.USER_TAG.unpaid_warning'),
};
