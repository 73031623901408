import { NgModule, Type } from '@angular/core';

import { MaxPipe } from './max.pipe';
import { LogPipe } from './log.pipe';
import { JoinPipe } from './join.pipe';
import { SafePipe } from './safe.pipe';
import { KeysPipe } from './keys.pipe';
import { FixedPipe } from './fixed.pipe';
import { ItemsPipe } from './items.pipe';
import { RangePipe } from './range.pipe';
import { ColonPipe } from './colon.pipe';
import { SearchPipe } from './search.pipe';
import { FilterPipe } from './filter.pipe';
import { OrderPipe } from './orderBy.pipe';
import { ValuesPipe } from './values.pipe';
import { IsArrayPipe } from './isArray.pipe';
import { ToArrayPipe } from './toArray.pipe';
import { BytesPipe } from './bytesFormat.pipe';
import { IsNumberPipe } from './isNumber.pipe';
import { SubWeeksPipe } from './subWeeks.pipe';
import { TruncatePipe } from './truncate.pipe';
import { MapArrayPipe } from './mapArray.pipe';
import { MarkdownPipe } from './markdown.pipe';
import { SubMonthsPipe } from './subMonths.pipe';
import { DayParityPipe } from './dayParity.pipe';
import { ParseDatePipe } from './parseDate.pipe';
import { JsonInlinePipe } from './jsonInline.pipe';
import { FormatDatePipe } from './localeDate.pipe';
import { EncodeJsonPipe } from './encodeJson.pipe';
import { LocaleNumberPipe } from './localeNumber.pipe';
import { DistanceToNowPipe } from './distanceToNow.pipe';
import { FormatDurationPipe } from './localeDuration.pipe';
import { TruncateMiddlePipe } from './truncate-middle.pipe';
import { DateToISOStringPipe } from './dateToISOString.pipe';
import { DifferenceInDaysPipe } from './differenceInDays.pipe';
import { AbbreviateNumberPipe } from './abbreviateNumber.pipe';

const pipes: (any[] | Type<any>)[] = [
  LogPipe,
  MaxPipe,
  JoinPipe,
  SafePipe,
  KeysPipe,
  BytesPipe,
  ItemsPipe,
  ColonPipe,
  FixedPipe,
  OrderPipe,
  RangePipe,
  FilterPipe,
  ValuesPipe,
  SearchPipe,
  ToArrayPipe,
  IsArrayPipe,
  IsNumberPipe,
  SubWeeksPipe,
  MarkdownPipe,
  TruncatePipe,
  MapArrayPipe,
  SubMonthsPipe,
  DayParityPipe,
  ParseDatePipe,
  JsonInlinePipe,
  FormatDatePipe,
  EncodeJsonPipe,
  LocaleNumberPipe,
  DistanceToNowPipe,
  FormatDurationPipe,
  TruncateMiddlePipe,
  DateToISOStringPipe,
  AbbreviateNumberPipe,
  DifferenceInDaysPipe,
];

@NgModule({
  exports: [...pipes],
  declarations: [...pipes],
})
export class PipesModule {}
