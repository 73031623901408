<div class="bg2-map-popup-all-cluster-component">
  <ng-container *ngIf="cluster_info$$ | async as cluster_info">
    <div style="display: flex; flex-direction: column">
      <span *ngIf="cluster_info?.devices?.length > 0">
        {{ 'VIEWS.MAP.SHARED.HTML_POPUP_MARKER.[total] devices' | translate: { total: cluster_info.devices.length } }}
      </span>
      <span *ngIf="cluster_info?.locations?.length > 0">
        {{ 'VIEWS.MAP.SHARED.HTML_POPUP_MARKER.[total] locations' | translate: { total: cluster_info.locations.length } }}
      </span>
      <span *ngIf="cluster_info?.unrepertoried?.length > 0">
        {{ 'VIEWS.MAP.SHARED.HTML_POPUP_MARKER.[total] unrepertoried' | translate: { total: cluster_info.unrepertoried.length } }}
      </span>
    </div>
  </ng-container>
</div>
