import { ChangeDetectionStrategy, Component } from '@angular/core';

import { MatSnackBarRef } from '@angular/material/snack-bar';

@Component({
  selector: 'app-update-snack',
  templateUrl: 'app-update-snack.component.html',
  styleUrls: ['app-update-snack.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppUpdateSnackComponent {
  // #region -> (component basics)

  constructor(public snackBarRef: MatSnackBarRef<AppUpdateSnackComponent>) {}

  // #endregion

  public reloadNow(): void {
    location?.reload();
  }

  public reloadLater(): void {
    this.snackBarRef.dismiss();
  }
}
