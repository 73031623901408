import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { BehaviorSubject, Observable } from 'rxjs';
import { distinctUntilRealChanged, replay } from '@bg2app/tools/rxjs';

import { Bg2MapPopupComponent } from '../map-popup/map-popup.component';

import { DeviceMarker } from 'app/typings/mapping';
import { map } from 'rxjs';

@Component({
  selector: 'bg2-map-popup-device-marker',
  templateUrl: './map-popup-device-marker.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class Bg2MapPopupDeviceMarkerComponent extends Bg2MapPopupComponent {
  private _marker$$: BehaviorSubject<DeviceMarker> = new BehaviorSubject(null);
  public marker$$ = this._marker$$.asObservable().pipe(replay());

  @Input()
  public set marker(marker: DeviceMarker) {
    this._marker$$.next(marker);
  }

  private _can_create_location$$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public can_create_location$$: Observable<boolean> = this._can_create_location$$.asObservable().pipe(distinctUntilRealChanged(), replay());

  @Input()
  public set can_create_location(can_create_location: boolean) {
    this._can_create_location$$.next(can_create_location);
  }

  public device$$ = this.marker$$.pipe(
    map(marker => marker.device),
    replay()
  );
}
