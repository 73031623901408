import { ChangeDetectionStrategy, Component } from '@angular/core';

import { isNil } from 'lodash-es';

import { map, of, switchMap } from 'rxjs';
import { replay, waitForNotNilValue } from '@bg2app/tools/rxjs';

import { DeviceApi } from 'app/core';
import { DialogsService } from 'app/widgets/dialogs-modals';
import { AppStateService } from 'app/core/app-state.service';

import { DeviceModalAbstractCategoryComponent } from '../device-modal-abstract-details-or-summary.component';

import { DEVICE_SIMPLIFIED_BATTERY_STATE, DEVICE_SIMPLIFIED_BATTERY_STATE_REASON, SimpleSetterGetter } from 'app/models';
import { DRDevice, DeviceInterface, DeviceLocation } from 'app/models/devices/DRDevice';
import { encodeJson } from 'app/misc/tools';
import { DevicesBulkBatteryChangeDialogComponent } from 'app/views/devices/dialogs-and-modals/dialogs';

@Component({
  selector: 'bg2-device-modal-diagnostic',
  templateUrl: './device-modal-diagnostic.component.html',
  styleUrls: ['./device-modal-diagnostic.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DeviceModalDiagnosticComponent extends DeviceModalAbstractCategoryComponent {
  // #region -> (component basics)

  /** */
  public BATTERY_STATE = DEVICE_SIMPLIFIED_BATTERY_STATE;

  /** */
  public BATTERY_REASON = DEVICE_SIMPLIFIED_BATTERY_STATE_REASON;

  constructor(
    protected readonly _deviceApi: DeviceApi,
    protected readonly _dialogs: DialogsService,
    protected readonly _appStateService: AppStateService
  ) {
    super(_appStateService, _dialogs, _deviceApi);
  }

  // #endregion

  // #region -> (loadings)

  /** */
  public is_loading_rssi_data = new SimpleSetterGetter(true);

  // #endregion

  /** */
  public can_show_gprs_card$$ = this.device_type$$.pipe(
    waitForNotNilValue(),
    map((device_type: DeviceInterface.TypeEnum) => {
      if (
        device_type === 'CPT' ||
        device_type === 'BeeLive' ||
        device_type === 'CPTMC' ||
        device_type === 'GPS' ||
        device_type === 'RG' ||
        device_type === 'BloomLive'
      ) {
        return true;
      }

      return false;
    }),
    replay()
  );

  /** */
  public can_show_868_card$$ = this.device_type$$.pipe(
    waitForNotNilValue(),
    map((device_type: DeviceInterface.TypeEnum) => {
      if (device_type === 'WG') {
        return true;
      }

      return false;
    }),
    replay()
  );

  // #region -> device basics

  /** */
  public is_not_beelive_nor_cpt$$ = this.device_type$$.pipe(
    waitForNotNilValue(),
    map((device_type: DeviceInterface.TypeEnum) => {
      if (device_type === 'CPT' || device_type === 'BeeLive') {
        return false;
      }

      return true;
    }),
    replay()
  );

  // #endregion

  // #region -> (device status)

  /** */
  public device_status_868$$ = this.device$$.pipe(
    switchMap(device => {
      if (isNil(device)) {
        return of(null);
      }

      return device.status_868$$;
    })
  );

  /** */
  public device_status_gps$$ = this.device$$.pipe(
    switchMap(device => {
      if (isNil(device)) {
        return of(null);
      }

      return device.status_gps$$;
    })
  );

  /** */
  public device_status_gprs$$ = this.device$$.pipe(
    switchMap(device => {
      if (isNil(device)) {
        return of(null);
      }

      return device.status_gprs$$;
    })
  );

  /** */
  public device_status_bat$$ = this.device$$.pipe(
    switchMap(device => {
      if (isNil(device)) {
        return of(null);
      }

      return device.status_bat$$;
    })
  );

  // #endregion

  // #region -> (device geoposition)

  /** */
  public device_location_gps$$ = this.device$$.pipe(
    map(device => {
      if (isNil(device)) {
        return <DeviceLocation>null;
      }

      return device.location_gps;
    })
  );

  /** */
  public device_location_cellids$$ = this.device$$.pipe(
    map(device => {
      if (isNil(device)) {
        return <DeviceLocation>null;
      }

      return device.location_cellids;
    })
  );

  // #endregion

  // #region -> (device battery)

  /** */
  public should_show_analysis$$ = this.device_status_bat$$.pipe(
    map(status => status?.outdated || status?.value < 30),
    replay()
  );

  /** */
  public order_battery$$ = this.device$$.pipe(
    waitForNotNilValue(),
    map(device => ({ name: device?.name, bat: device?.bat })),
    map(data => encodeJson([data])),
    replay()
  );

  public replace_battery(device: DRDevice): void {
    this._dialogs.open(DevicesBulkBatteryChangeDialogComponent, { devices: [device] }).subscribe();
  }

  // #endregion
}
