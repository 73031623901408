import { ParsedNumber, parseNumber } from 'libphonenumber-js';
import { isArray, isEmpty, isNil, mergeWith, pick, set } from 'lodash-es';

import { BehaviorSubject, catchError, combineLatest, map, Observable, of, switchMap, take, throwError } from 'rxjs';
import { allTrue, distinctUntilRealChanged, replay } from '@bg2app/tools/rxjs';

import { UsersApiService } from 'app/core/api/user/users-api.service';

import {
  Tag,
  InputUpdateUser,
  UserAnonymousRef,
  Role as SwaggerUserRole,
  Group as SwaggerUserGroup,
  User as SwaggerUserInterface,
  Subgroup as SwaggerUserSubGroup,
} from 'app/core/api-swagger/user-v2';
import { parseDate } from 'app/misc/tools';
import { DATA_VIEW } from '../config/data-view.enum';
import { UserACE } from './enumerators/user-acl.enum';
import { UserScope } from './enumerators/user-scope.enum';
import { UserParameters } from './interfaces/user-parameters.iface';
import { get_i18n_for_zoho_error, ZohoCRMContact, ZohoCRMModuleName, ZohoError } from '../zoho';

export type SubUser = [number, string, any];
export type SubUsers = SubUser[];

export interface SimpleUserRef {
  id: number;
  name: string;
}

export class User implements SwaggerUserInterface {
  // #region -> (model basics)

  /** */
  constructor(private usersApi: UsersApiService) {}

  /** */
  public save(fields_to_save: (keyof InputUpdateUser)[] = null) {
    const user_view = fields_to_save ? pick(this, fields_to_save) : this;

    return this.user_id$$.pipe(
      take(1),
      switchMap(user_id => this.usersApi.update_user$(user_id, user_view))
    );
  }

  /** */
  public save_password(old_password: string, new_password: string) {
    return this.user_id$$.pipe(
      take(1),
      switchMap(user_id => this.usersApi.update_password$(user_id, { old_password, password: new_password })),
      map((user_interface: SwaggerUserInterface) => this.deserialize(user_interface))
    );
  }

  /** */
  public deserialize(input: SwaggerUserInterface): User {
    Object.assign(this, input);
    return this;
  }

  // #endregion

  // #region -> (user ID)

  /**
   * Set user's ID.
   *
   * @param user_id The new id.
   *
   * @public
   */
  public set user_id(user_id: number) {
    this._user_id$$.next(user_id);
  }

  /**
   * Get user's ID.
   *
   * @public
   */
  public get user_id(): number {
    return this._user_id$$.getValue();
  }

  /** */
  private _user_id$$ = new BehaviorSubject<number>(null);

  /**
   * Observes user's ID.
   *
   * @public
   */
  public user_id$$ = this._user_id$$.pipe(distinctUntilRealChanged());

  /**
   * ID of the user.
   *
   * @public
   * @deprecated
   */
  public get id() {
    return this._user_id$$.getValue();
  }

  /**
   * ID of the user.
   *
   * @public
   * @deprecated
   */
  public set id(id: number) {
    this.user_id = id;
  }

  /**
   * ID of the user in V1.
   *
   * @deprecated
   */
  public get id_v1(): number {
    return this.user_id;
  }

  // #endregion

  // #region -> (user name)

  /**
   * Mutates user's username.
   *
   * @param username New username.
   *
   * @private
   */
  public set username(username: string) {
    this._username$$.next(username);
  }

  /** */
  private _username$$ = new BehaviorSubject<string>(null);

  /**
   * Observes user's username.
   *
   * @public
   */
  public username$$ = this._username$$.asObservable().pipe(distinctUntilRealChanged());

  /**
   * Name of the user.
   *
   * @public
   * @deprecated
   */
  public get username() {
    return this._username$$.getValue();
  }

  /**
   * Mutates user's first name.
   *
   * @param first_name First name of the user.
   *
   * @public
   */
  public set first_name(first_name: string) {
    this._first_name$$.next(first_name);
  }

  /**
   * Get user's name.
   *
   * @public
   */
  public get first_name(): string {
    return this._first_name$$.getValue();
  }

  /** */
  private _first_name$$ = new BehaviorSubject<string>(null);

  /** */
  public first_name$$ = this._first_name$$.asObservable();

  /**
   * Mutates user's last name.
   *
   * @param last_name Last name of the user.
   *
   * @public
   */
  public set last_name(last_name: string) {
    this._last_name$$.next(last_name);
  }

  /**
   * Get user's name.
   *
   * @public
   */
  public get last_name(): string {
    return this._last_name$$.getValue();
  }

  /** */
  private _last_name$$ = new BehaviorSubject<string>(null);

  /** */
  public last_name$$ = this._last_name$$.asObservable();

  /**
   * Get user's complete name.
   *
   * @public
   */
  public get name(): string {
    return User._readable_name(
      this._first_name$$.getValue(),
      this._last_name$$.getValue(),
      this._username$$.getValue(),
      this._user_id$$.getValue()
    );
  }

  /**
   * Observes user's full name.
   *
   * @public
   */
  public name$$ = combineLatest({
    first_name: this.first_name$$,
    last_name: this.last_name$$,
    username: this.username$$,
    user_id: this.user_id$$,
  }).pipe(
    map(({ first_name, last_name, username, user_id }) => User._readable_name(first_name, last_name, username, user_id)),
    replay()
  );

  /** */
  public static _readable_name(first_name: string, last_name: string, username: string, user_id: number): string {
    if (isNil(first_name) && isNil(last_name)) {
      if (isNil(username) || username.length == 0) {
        return `user#${user_id}`;
      }
      return username;
    }
    return first_name + ' ' + (last_name ?? '').toUpperCase();
  }

  // #endregion

  // #region -> (user mail)

  /**
   * Set user's email.
   *
   * @param email The new email
   *
   * @public
   */
  public set email(email: string) {
    this._email$$.next(email);
  }

  /**
   * Get the user's mail.
   *
   * @returns Returns the user's mail.
   *
   * @public
   * @deprecated
   */
  public get email(): string {
    return this._email$$.getValue();
  }

  /** */
  private _email$$ = new BehaviorSubject<string>(null);

  /**
   * Observes user's email.
   *
   * @public
   */
  public email$$ = this._email$$.asObservable().pipe(distinctUntilRealChanged());

  // #endregion

  // #region -> (user phone number)

  /**
   * Mutates user's phone number.
   *
   * @param phone_number New phone number.
   *
   * @public
   */
  public set phone_number(phone_number: string) {
    this._phone_number$$.next(phone_number);
  }

  /** */
  public get phone_number(): string {
    return this._phone_number$$.getValue();
  }

  /** */
  private _phone_number$$ = new BehaviorSubject<string>(null);

  /**
   * Observes user's phone number.
   *
   * @public
   */
  public phone_number$$ = this._phone_number$$.asObservable().pipe(distinctUntilRealChanged());

  /** */
  public phone_number_country$$ = this.phone_number$$.pipe(
    map(phone_number => {
      if (isNil(phone_number) || isEmpty(phone_number)) {
        return null;
      }

      const parsed_phone = parseNumber(phone_number, {});
      const country_iso2 = (<ParsedNumber>parsed_phone)?.country;

      return country_iso2 ?? null;
    }),
    replay()
  );

  // #endregion

  // #region -> (user lang)

  /**
   * Mutates user's preferred language.
   *
   * @param language Preferred language of the user.
   *
   * @public
   */
  public set lang(language: string) {
    this._lang$$.next(language);
  }

  /** */
  public get lang(): string {
    return this._lang$$.getValue();
  }

  /** */
  private _lang$$ = new BehaviorSubject<string>(null);

  /**
   * Observes user's preferred language.
   *
   * @public
   */
  public lang$$ = this._lang$$.asObservable().pipe(distinctUntilRealChanged());

  // #endregion

  // #region -> (user timezone)

  /**
   * Mutates user's timezone.
   *
   * @param timezone New timezone.
   *
   * @public
   */
  public set timezone(timezone: string) {
    this._timezone$$.next(timezone);
  }

  /** */
  public get timezone(): string {
    return this._timezone$$.getValue();
  }

  /** */
  private _timezone$$ = new BehaviorSubject<string>(null);

  /**
   * Observes user's timezone.
   *
   * @public
   */
  public timezone$$ = this._timezone$$.asObservable().pipe(distinctUntilRealChanged());

  // #endregion

  // #region -> (disabled)

  /** */
  public set disabled(disabled: boolean) {
    this._disabled$$.next(isNil(disabled) ? false : disabled);
  }

  /** */
  public get disabled(): boolean {
    return this._disabled$$.getValue();
  }

  /** */
  private _disabled$$ = new BehaviorSubject<boolean>(false);

  /** */
  public disabled$$ = this._disabled$$.asObservable();

  // #endregion

  // #region -> (referent)

  /**
   * Mutates who created the user.
   *
   * @param referent New value for created by.
   *
   * @public
   */
  public set referent(referent: UserAnonymousRef) {
    this._referent$$.next(referent);
  }

  /** */
  public get referent(): UserAnonymousRef {
    return this._referent$$.getValue();
  }

  /** */
  private _referent$$ = new BehaviorSubject<UserAnonymousRef>(null);

  /**
   * Observes the ID of the user who created the current user.
   *
   * @public
   */
  public referent$$ = this._referent$$.asObservable().pipe(distinctUntilRealChanged());

  /**
   * Observes who created the user.
   *
   * @public
   * @replay
   */
  public created_by_user$$: Observable<User> = this.referent$$.pipe(
    map(created_by => created_by?.user_id ?? null),
    switchMap(created_by_user_id => {
      if (isNil(created_by_user_id)) {
        return of(null);
      }

      return this.usersApi.fetch_user$(created_by_user_id);
    }),
    replay()
  );

  // #endregion

  // #region -> (user CRM / DESK)

  /**
   * Mutates user's CRM ID.
   *
   * @param crm_id The new last login date.
   *
   * @public
   */
  public set CRM_id(crm_id: string) {
    this._CRM_id$$.next(isEmpty(crm_id) ? null : crm_id);
  }

  /**
   * CRM ID of the user.
   *
   * @public
   * @deprecated
   */
  public get CRM_id() {
    return this._CRM_id$$.getValue();
  }

  /** */
  private _CRM_id$$ = new BehaviorSubject<string>(null);

  /**
   * Observes user's CRM ID.
   *
   * @public
   */
  public CRM_id$$ = this._CRM_id$$.asObservable().pipe(distinctUntilRealChanged());

  /**
   * Observes user's CRM link.
   *
   * @public
   * @replay
   */
  public crm_link$$ = this.CRM_id$$.pipe(
    map(crm_id => {
      if (isNil(crm_id)) {
        return null;
      }

      return `https://crm.zoho.eu/crm/org20067795631/tab/Contacts/${crm_id}`;
    }),
    replay()
  );

  /**
   * Observes the user's related CRM contact.
   */
  public zoho_crm_contact$$: Observable<ZohoCRMContact | Error> = this.CRM_id$$.pipe(
    switchMap(crm_id => {
      if (isNil(crm_id)) {
        return of(new Error(get_i18n_for_zoho_error(ZohoError.MISSING_CRM_ID_IN_APP_USER)));
      }

      return this.usersApi.zohoApis.crm_api.fetch_record$(ZohoCRMModuleName.Contacts, crm_id, []);
    }),
    replay()
  );

  /** */
  public zoho_desk_contact$$ = this.zoho_crm_contact$$.pipe(
    switchMap(crm_contact => {
      if (crm_contact instanceof Error) {
        return of(crm_contact);
      }

      return of(crm_contact).pipe(
        switchMap(_crm_contact => {
          if (isNil(_crm_contact)) {
            return of(null);
          }

          return this.usersApi.zohoApis.desk_api
            .search_in_contacts$({ email: _crm_contact.Email })
            .pipe(map(response => ({ response, crm_contact: _crm_contact })));
        }),
        catchError((error: unknown) => of(<Error>error))
      );
    }),
    map(error_or_response => {
      if (error_or_response instanceof Error) {
        return error_or_response;
      }

      const desk_contact = error_or_response?.response?.data?.filter(possible_desk_contact => {
        const crm_contact_id_from_desk = possible_desk_contact?.zohoCRMContact?.id ?? null;
        return crm_contact_id_from_desk === error_or_response?.crm_contact?.id;
      });

      if (desk_contact?.length === 0) {
        return null;
      }

      return desk_contact?.[0];
    }),
    replay()
  );

  /** */
  public zoho_books_contact$$ = this.zoho_crm_contact$$.pipe(
    switchMap(crm_contact_or_error => {
      if (crm_contact_or_error instanceof Error) {
        return of(crm_contact_or_error);
      }

      return of(crm_contact_or_error).pipe(
        switchMap(_crm_contact => {
          if (isNil(_crm_contact)) {
            return of(null);
          }

          return this.usersApi.zohoApis.books_api.search_books_contact_from_crm_contact$(_crm_contact.id);
        }),
        catchError((error: unknown) => of(<Error>error))
      );
    }),
    map(error_or_books_contact => {
      if (error_or_books_contact instanceof Error) {
        return error_or_books_contact;
      }

      return error_or_books_contact;
    }),
    replay()
  );

  // #endregion

  // #region -> (user parameters)

  /** */
  private _params$$ = new BehaviorSubject<UserParameters>(null);

  /**
   * Mutates user's parameters.
   *
   * @param parameters The new parameters.
   *
   * @public
   */
  public set params(parameters: UserParameters) {
    this._params$$.next(parameters);
  }

  /**
   * Get user's parameters.
   *
   * @public
   */
  public get params(): UserParameters {
    return this._params$$.getValue();
  }

  /**
   * Observes user's parameters.
   *
   * @public
   */
  public params$$: Observable<UserParameters> = this._params$$.asObservable().pipe(
    map(user_parameters => {
      // Fix forward phone numbers
      if (user_parameters?.notifications?.sms_forward_numbers) {
        user_parameters.notifications.sms_forward_numbers = (user_parameters.notifications?.sms_forward_numbers ?? []).filter(
          phone => !isNil(phone)
        );
      }

      // Fix sms notifications enabled status
      if (isNil(user_parameters?.notifications?.sms?.enabled)) {
        set(user_parameters, 'notifications.sms.enabled', user_parameters?.notifications?.sms_on_gps_move ? true : false);
      }

      return user_parameters;
    }),
    distinctUntilRealChanged(),
    replay()
  );

  /**
   * Observes default displayed chart type in locations list view.
   *
   * @returns Configured chart type to display else lineat weight.
   *
   * @public
   * @observable
   */
  public params__apiary_list_default_chart_type$$ = this.params$$.pipe(
    map(params => (params?.app_settings?.apiary?.list?.default_chart_type as DATA_VIEW) ?? DATA_VIEW.AUTOMATIC),
    distinctUntilRealChanged(),
    replay()
  );

  /**
   * Observes default displayed date range in locations list view.
   *
   * @returns Configured date range to display.
   *
   * @public
   * @observable
   */
  public params__apiary_list_default_date_range$$ = this.params$$.pipe(
    map(params => params?.app_settings?.apiary?.list?.default_date_range ?? 'weekly'),
    distinctUntilRealChanged(),
    replay()
  );

  /**
   * @public
   */
  public setPartialParams(new_partial_params: Partial<UserParameters>) {
    this.params$$.pipe(take(1)).subscribe({
      next: current_user_params => {
        this.params = mergeWith(<UserParameters>{}, current_user_params ?? <UserParameters>{}, <UserParameters>new_partial_params, (a, b) =>
          isArray(b) ? b : undefined
        );
      },
    });
  }

  // #endregion

  // #region -> (user time data)

  /**
   * Mutates user's last login date.
   *
   * @param last_login The new last login date.
   *
   * @public
   */
  public set _last_login(last_login: Date) {
    if (isNil(last_login)) {
      return;
    }

    this._last_login$$.next(parseDate(last_login));
  }

  /** */
  public get last_login(): Date {
    return this._last_login$$.getValue();
  }

  /** */
  private _last_login$$ = new BehaviorSubject<Date>(null);

  /**
   * Observes user's last login date.
   *
   * @public
   */
  public last_login$$ = this._last_login$$.asObservable().pipe(distinctUntilRealChanged());

  /** */
  public set _creation_time(creation_time: Date) {
    if (isNil(creation_time)) {
      return;
    }

    this._creation_time$$.next(parseDate(creation_time));
  }

  /** */
  public get creation_time(): Date {
    return this._creation_time$$.getValue();
  }

  /** */
  private _creation_time$$ = new BehaviorSubject<Date>(null);

  /** */
  public creation_time$$ = this._creation_time$$.asObservable().pipe(distinctUntilRealChanged());

  /** */
  public set _update_time(update_time: Date) {
    if (isNil(update_time)) {
      return;
    }

    this._update_time$$.next(parseDate(update_time));
  }

  /** */
  public get update_time(): Date {
    return this._update_time$$.getValue();
  }

  /** */
  private _update_time$$ = new BehaviorSubject<Date>(null);

  /** */
  public update_time$$ = this._update_time$$.asObservable().pipe(distinctUntilRealChanged());

  // #endregion

  // #region -> (user tags)

  /** */
  public set tags(tags: Tag[]) {
    this._tags$$.next(tags);
  }

  /** */
  public get tags(): Tag[] {
    return this._tags$$.getValue() ?? [];
  }

  /** */
  private _tags$$ = new BehaviorSubject<Tag[]>(null);

  /**
   * Observes user's tags.
   *
   * @public
   */
  public tags$$ = this._tags$$.asObservable().pipe(
    map(tags => tags ?? []),
    distinctUntilRealChanged()
  );

  /**
   * Observes unpaid warning state.
   *
   * @public
   * @replay
   */
  public unpaid_warning$$ = this.tags$$.pipe(
    map(tags => tags?.map(tag => tag?.name).includes('unpaid_warning')),
    replay()
  );

  // #endregion

  // #region -> (user scopes)

  /**
   * Mutates user's scopes.
   *
   * @param scopes The new scopes.
   *
   * @public
   */
  public set scopes(scopes: string[]) {
    if (isNil(scopes)) {
      return;
    }

    this._scopes$$.next(scopes);
  }

  /** */
  public get scopes(): string[] {
    return this._scopes$$.getValue();
  }

  /** */
  private _scopes$$ = new BehaviorSubject<string[]>([]);

  /**
   * Observes user's last login date.
   *
   * @public
   */
  public scopes$$ = this._scopes$$.asObservable().pipe(distinctUntilRealChanged());

  /** */
  public is_public_account$$ = this.scopes$$.pipe(
    map(scopes => !scopes.includes(UserScope.WRITE_SELF) && !scopes.includes(UserScope.WRITE_ALL)),
    replay()
  );

  /** */
  public is_not_public_account$$ = this.is_public_account$$.pipe(
    map(is_public_account => !is_public_account),
    replay()
  );

  /** */
  public can_create_subusers$$ = this.scopes$$.pipe(
    map(scopes => scopes.includes(UserScope.CREATE_SUB_USERS) || scopes.includes(UserScope.WRITE_ALL)),
    replay()
  );

  /** */
  public can_create_users$$ = this.scopes$$.pipe(
    map(scopes => scopes.includes(UserScope.WRITE_ALL) || scopes.includes(UserScope.CREATE_SUB_USERS)),
    replay()
  );

  /** */
  public is_superadmin = () => {
    const scopes = this._scopes$$.getValue();
    const user_id = this._user_id$$.getValue();

    const is_superadministrator_user = (user_id ?? null) === 1;

    const have_write_all_scope = (scopes ?? []).includes(UserScope.WRITE_ALL);
    const have_superadmin_scope = (scopes ?? []).includes(UserScope.SUPERADMIN);

    return (have_write_all_scope && have_superadmin_scope) || is_superadministrator_user;
  };

  /** */
  public is_superadmin$$ = combineLatest({ scopes: this.scopes$$, user_id: this.user_id$$ }).pipe(
    map(({ scopes, user_id }) => {
      const is_superadministrator_user = (user_id ?? null) === 1;

      const have_write_all_scope = (scopes ?? []).includes(UserScope.WRITE_ALL);
      const have_superadmin_scope = (scopes ?? []).includes(UserScope.SUPERADMIN);

      return (have_write_all_scope && have_superadmin_scope) || is_superadministrator_user;
    }),
    replay()
  );

  // #endregion

  // #region -> (user ACL)

  public set _user_acl(user_acl: UserACE[]) {
    this._user_acl$$.next(user_acl);
  }

  /** */
  private _user_acl$$ = new BehaviorSubject<UserACE[]>(null);

  /** */
  public user_acl$$ = this._user_acl$$.asObservable().pipe(
    map(user_acl => {
      if (isNil(user_acl)) {
        return [];
      }

      return user_acl;
    }),
    distinctUntilRealChanged()
  );

  /** */
  public acl__has_rights$$ = this.user_acl$$.pipe(
    map(user_acl => user_acl.length),
    map(total_user_acl => total_user_acl !== 0)
  );

  /** */
  public acl__can_write$$ = this.user_acl$$.pipe(
    map(user_acl => user_acl.includes(UserACE.WRITE)),
    replay()
  );

  /** */
  public acl__cannot_write$$ = this.acl__can_write$$.pipe(
    map(acl_can_write => !acl_can_write),
    replay()
  );

  /** */
  public acl__can_read$$ = this.user_acl$$.pipe(
    map(user_acl => user_acl.includes(UserACE.FULL_READ)),
    replay()
  );

  /** */
  public acl__can_read_and_write$$ = allTrue(this.acl__can_read$$, this.acl__can_write$$).pipe(replay());

  // #endregion

  // #region -> (props from SwaggerUserInterface)

  /**
   * Role of the user.
   *
   * @public
   */
  public role?: SwaggerUserRole;

  /**
   * Groups of the user.
   */
  public groups?: SwaggerUserGroup;

  /**
   * Sub-groups of the user.
   */
  public subgroup?: SwaggerUserSubGroup;

  // public disabled?: boolean;
  // public superior_id?: number;
  // public params?: UserParams;

  /**
   * @deprecated
   */
  superior?: number;

  /**
   * @deprecated
   */
  subordinate?: Array<number>;

  /**
   * @deprecated
   */
  subordinate_tree?: Array<SubUsers>;

  /**
   * @deprecated
   */
  unpaid_block = false;

  // #endregion
}
