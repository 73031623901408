// From ~/node_modules/@angular
import { Component, Input, TemplateRef, ChangeDetectionStrategy } from '@angular/core';

import { combineLatest, Observable, BehaviorSubject } from 'rxjs';
import { switchMap, filter, distinctUntilChanged } from 'rxjs';

import { TranslateService } from '@ngx-translate/core';

// From ~/src/app/core
import { AppStateService } from 'app/core/app-state.service';
import { TooltipContentComponent } from 'app/misc/libraries/bg2-tooltip/tooltip-content/tooltip-content.component';

/**
 * Component class for documentation helper.
 *
 * @example Use `<doc-helper [link]="somewhere"></doc-helper> to make the link directly on the icon
 * @example Use `<doc-helper [tooltip]="'hello, click here'"></doc-helper> to make the link to the doc via the popup
 */
@Component({
  selector: 'doc-helper',
  templateUrl: 'doc-helper.component.html',
  styleUrls: ['./doc-helper.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DocHelperComponent {

  @Input()
  public timeout = false;

  @Input()
  public tooltip: string | TooltipContentComponent = null;

  @Input()
  public iconSize = '18px';

  @Input()
  public text: string = null;

  private _page$: BehaviorSubject<string> = new BehaviorSubject(null);
  public page$$: Observable<string> = this._page$.asObservable();

  @Input()
  public set page(page: string) {
    this._page$.next(page);
  }

  private _section$: BehaviorSubject<string> = new BehaviorSubject('');
  public section$$ = this._section$.asObservable();

  @Input()
  public set section(section: string) {
    this._section$.next(section);
  }

  // TODO: add an inut to configure the input
  private _icon$$ = new BehaviorSubject<string>('help-circle');
  public icon$$ = this._icon$$.asObservable();

  public link$$ = combineLatest([this.page$$, this.section$$]).pipe(
    distinctUntilChanged(),
    filter(([page, section]: [string, string]) => page && page.length > 0),
    switchMap(([page, section]: [string, string]) => {
      let page_key = `HELP_LINK.PAGE.${page}`;
      if (section) {
        page_key += `#${section}`;
      }
      return this.translate.stream(page_key);
    }),
    filter((page_val) => page_val && page_val.length > 0)
  );

  // #region -> (component basics)

  constructor(
    public appState: AppStateService,
    protected translate: TranslateService
  ) { }

  // #endregion

}
