import { decodeJson, encodeJson } from 'app/misc/tools';
import { filter, has, isNil, values } from 'lodash-es';
import { from, Observable, of, Subject } from 'rxjs';
import { map } from 'rxjs';

export class ApiCache<ObjectType, KeyType = string> {
  public cache: Record<string, ObjectType> = {};
  private timeouts: Record<string, number> = {};

  private _cache$ = new Subject<Record<string, ObjectType>>();
  public cache$ = this._cache$.asObservable();

  private STORAGE_NAME: string;
  private TIMEOUT: any; // ms
  private keyHash: (key: KeyType) => string;

  constructor(timeout = 0, storage_name: string = null, key_hash: (key: KeyType) => string = null) {
    this.STORAGE_NAME = storage_name;
    this.TIMEOUT = timeout;
    if (key_hash) {
      this.keyHash = key_hash;
    } else {
      this.keyHash = (key: KeyType) => `${key}`;
    }
  }

  public has(key: KeyType): boolean {
    const id = this.keyHash(key);
    return this._hasInCache(id);
  }

  private _hasInCache(id: string): boolean {
    if (this.hasTimeout(id)) {
      delete this.cache[id];
      delete this.timeouts[id];
    }
    return has(this.cache, id);
  }

  public get(key: KeyType): ObjectType {
    const id = this.keyHash(key);
    // do not use has to prevent latency between check and get.
    if (has(this.cache, id)) {
      return this.cache[id];
    }
  }

  public getType(type: number | string): ObjectType[] {
    return filter(this.cache, (obj: any) => obj.type === type);
  }

  public add(key: KeyType, object: ObjectType): void {
    const id = this.keyHash(key);
    this.cache[id] = object;
    this.timeouts[id] = Date.now();
    this._cache$.next(this.cache);
  }

  private hasTimeout(id: string): boolean {
    if (this.TIMEOUT <= 0) {
      return;
    }
    if (!has(this.timeouts, id)) {
      return;
    }

    return Date.now() - this.timeouts[id] >= this.TIMEOUT;
  }

  public remove(key: KeyType): void {
    const id = this.keyHash(key);
    if (this._hasInCache(id)) {
      if (this.cache[id].hasOwnProperty('preDestroy')) {
        (this.cache as any)[id].preDestroy();
      }
      delete this.cache[id];
      this._cache$.next(this.cache);
    }
  }

  public razCache(): void {
    values(this.cache).map((obj: any) => {
      if (obj.hasOwnProperty('preDestroy')) {
        obj.preDestroy();
      }
    });
    this.cache = {};
    this._cache$.next(this.cache);
  }

  public loadFromLocalStorage(): Observable<boolean> {
    if (!this.STORAGE_NAME) {
      return of(false);
    }
    const encoded_json_data = localStorage.getItem(this.STORAGE_NAME);

    if (!isNil(encoded_json_data)) {
      try {
        return of(decodeJson(encoded_json_data)).pipe(
          map((decoded_json: any) => {
            this.cache = decoded_json?.cache || {};
            this.timeouts = decoded_json?.timeouts || {};
            this._cache$.next(this.cache);
          }),
          map(() => true)
        );
      } catch (error) {
        console.error(error);
      }
    }
    // Default if no cache
    this.cache = {};
    this.timeouts = {};
    return of(true);
  }

  /**
   * Saves the current geocoded positions to the local storage.
   */
  public saveToLocalStorage(): void {
    if (!this.STORAGE_NAME) {
      return;
    }

    const encoded_json = encodeJson({ cache: this.cache, timeouts: this.timeouts });
    localStorage.setItem(this.STORAGE_NAME, encoded_json);
  }
}
