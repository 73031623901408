<div class="bg2-apiary-weather-data-component">
  <div *ngLet="weather_data$$ | async; let weather_data" class="weather-wind-chart-container">
    <div class="weather-icons-container">
      <bg2-apiary-weather-icons-d3-chart
        [weather_data]="weather_data"
        [geoposition]="location_geoposition$$ | async"
        [is_loading]="loadings.weather_data$$ | async"
        [date_range]="date_range_manager.range$$ | async"
      ></bg2-apiary-weather-icons-d3-chart>
    </div>

    <div *ngIf="weather_data?.has_anemo_speed_data" class="wind-chart-container">
      <bg2-apiary-wind-d3-chart
        [wind_data]="wind_data$$ | async"
        [geoposition]="location_geoposition$$ | async"
        [is_loading]="loadings.weather_data$$ | async"
        [date_range]="date_range_manager.range$$ | async"
      ></bg2-apiary-wind-d3-chart>
    </div>

    <div class="weather-chart-container">
      <bg2-apiary-weather-d3-chart
        [weather_data]="weather_data"
        [geoposition]="location_geoposition$$ | async"
        [is_loading]="loadings.weather_data$$ | async"
        [date_range]="date_range_manager.range$$ | async"
      ></bg2-apiary-weather-d3-chart>
    </div>
  </div>

  <bg2-apiary-charts-footer
    [is_compact]="false"
    [location_id]="location_id$$ | async"
    [date_range_manager]="date_range_manager"
  ></bg2-apiary-charts-footer>

  <ng-container *ngIf="error$$ | async; let error">
    <bg2-error-helper [error]="error"></bg2-error-helper>
  </ng-container>
</div>
