/**
 * BeeGuard - DeviceAPI
 * This document is the reference documentation of [BeeGuard](https://www.beeguard.fr) public API to acces devices data.  # Rate Limit  to ensure a high quality of service for all API consumers, each unauthenticated consumer is limited to **100 requests per minutes**.  # Confidential document  Do not duplicate or distribute without written permission from BeeGuard.   # Other informations 
 *
 * OpenAPI spec version: 20180410r1
 * Contact: e.navarro@beeguard.fr
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { DeviceConfiguration } from './deviceConfiguration';
import { DeviceEmbedded } from './deviceEmbedded';
import { DeviceLastMeasurement } from './deviceLastMeasurement';


export interface Device { 
    /**
     * Device unique ID
     */
    imei: number;
    /**
     * Standard device name (**warning**: may not be unique) 
     */
    name: string;
    /**
     * Id of the owner of the device
     */
    owner?: number;
    /**
     * Type of Device
     */
    type: Device.TypeEnum;
    /**
     * Creation date of the device
     */
    cdate?: string;
    /**
     * Free comment about the device
     */
    comment?: string;
    configuration?: DeviceConfiguration;
    /**
     * Aggregation of device last measurements
     */
    last_measurements?: { [key: string]: DeviceLastMeasurement; };
    last_data?: DeviceEmbedded;
}
export namespace Device {
    export type TypeEnum = 'GPS' | 'WG' | 'RG' | 'CPT' | 'CPTMC' | 'TG' | 'BCA' | 'Compute' | 'BeeLive' | 'BloomLive';
    export const TypeEnum = {
        GPS: 'GPS' as TypeEnum,
        WG: 'WG' as TypeEnum,
        RG: 'RG' as TypeEnum,
        CPT: 'CPT' as TypeEnum,
        CPTMC: 'CPTMC' as TypeEnum,
        TG: 'TG' as TypeEnum,
        BCA: 'BCA' as TypeEnum,
        Compute: 'Compute' as TypeEnum,
        BeeLive: 'BeeLive' as TypeEnum,
        BloomLive: 'BloomLive' as TypeEnum
    };
}
