import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ZohoCRMNote } from 'app/models/zoho';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'bg2-zoho-crm-note-summary',
  templateUrl: './zoho-crm-note-summary.component.html',
  styleUrls: ['./zoho-crm-note-summary.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ZohoCRMNoteSummaryComponent {
  // #region -> (component basics)

  constructor() {}

  // #endregion

  // #region -> (note)

  /** */
  private _note$$ = new BehaviorSubject<ZohoCRMNote>(null);

  /** */
  public note$$ = this._note$$.asObservable();

  @Input()
  public set note(note: ZohoCRMNote) {
    this._note$$.next(note);
  }

  public get note(): ZohoCRMNote {
    return this._note$$.getValue();
  }

  // #endregion
}
