import { geoJSON } from 'leaflet';
import { turf_union } from '../turf-union/turf-union';

/**
 * Unify two or more polygon circles into unique polygon to avoid color overlaps.
 *
 * @param polyCircles The array of polygon circles to unify.
 * @param styles Additionnal options to apply to the unified circles.
 */
export const unifyPolyCircles = (polyCircles: any[], styles?: L.PathOptions | L.StyleFunction<any>): L.GeoJSON<any> => {
  let tmpUnion: any = null;
  polyCircles.forEach((polyCircle, idx) => {
    if (idx === 0) {
      tmpUnion = polyCircle.toGeoJSON();
    }
    tmpUnion = turf_union(tmpUnion, polyCircle.toGeoJSON());
  });
  return geoJSON(tmpUnion, { style: styles || {} });
};
