import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { HiveQueen } from 'app/views/events/shared/events/evaluation/evaluation-histogram-hive-queens/evaluation-histogram-hive-queens.component';

@Component({
  selector: 'bg2-picon-queen-color',
  templateUrl: './picon-queen-color.component.html',
  styleUrls: ['./picon-queen-color.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PiconQueenColorComponent {
  @Input()
  public size_w: string = '24px';

  @Input()
  public queen_color: HiveQueen = null;

  @Input()
  public show_years: boolean = true;
}
