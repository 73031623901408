<div
  class="stackable-card"
  [ngClass]="{ 'content-hidden': (index$$ | async) >= 2, 'can-be-tap': (index$$ | async) > 0 }"
  (tap)="clickCard()"
  (swipeup)="swipeUp.next(null)"
  (swipedown)="swipeDown.next(null)"
  #stackableCard
>
  <ng-container>
    <ng-content select="div[role=stackable-card-header]"></ng-content>
  </ng-container>

  <ng-container *ngIf="can_load_content$$ | async">
    <ng-content select="div[role=stackable-card-content]"></ng-content>
  </ng-container>

  <ng-content select="div[role=stackable-card-cta]"></ng-content>
</div>
