/**
 * Computes the opposite angle for a specific angle.
 *
 * @param angle Initiate angle.
 *
 * @returns Returns the opposite angle.
 */
export function get_opposite_angle(angle: number): number {
  while (angle < 0) {
    angle += 360.0;
  }

  if (angle > 360) {
    angle = angle % 360;
  }

  const opposite_angle = angle + 180;
  return opposite_angle % 360;
}
