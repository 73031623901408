import { DOCUMENT } from '@angular/common';
import { AfterViewInit, ChangeDetectionStrategy, Component, Inject, Input, OnDestroy, OnInit, Renderer2 } from '@angular/core';

import { isNil } from 'lodash-es';

import { marker as i18n } from '@biesbjerg/ngx-translate-extract-marker';

import { Beeguard2Api } from 'app/core';

import { Apiary } from 'app/models';
import { EvaluationEvent } from 'app/models/events/Evaluation';

import { ApiaryBREvaluationCarouselGroupComponent } from './apiary-br-evaluation-carousel-group.component';
import { EventBasedCarouselContainerComponent } from 'app/widgets/widgets-reusables/carousels/event-based-carousel/event-carousel-container/event-carousel-container.component';

@Component({
  selector: 'bg2-apiary-br-evaluation-carousel',
  templateUrl:
    '../../../../../../widgets/widgets-reusables/carousels/event-based-carousel/event-carousel-container/event-carousel-container.component.html',
  styleUrls: [
    '../../../../../../widgets/widgets-reusables/carousels/event-based-carousel/event-carousel-container/event-carousel-container.component.scss',
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ApiaryBREvaluationCarouselComponent
  extends EventBasedCarouselContainerComponent<EvaluationEvent>
  implements OnInit, AfterViewInit, OnDestroy
{
  @Input()
  public set apiary(apiary: Apiary) {
    if (isNil(apiary)) {
      return;
    }

    this.carousel_config = {
      entity: apiary,

      error: {
        read_what: i18n<string>('ALL.ACE.READ_ALL_EVENTS.WHAT.see the visit events'),
        write_what: i18n<string>('ALL.ACE.WRITE_ALL_EVENTS.WHAT.create a visit event'),
        create: {
          message: i18n<string>("EVENT.VISIT.ERRORS.You can register your apiary's last visit !"),
        },
      },
    };
  }

  // #region -> (component basics)

  constructor(protected _renderer: Renderer2, protected _bg2Api: Beeguard2Api, @Inject(DOCUMENT) protected document: Document) {
    super(_renderer, _bg2Api, document);
    this.type_of_card_group_component = ApiaryBREvaluationCarouselGroupComponent;
  }

  ngOnInit(): void {
    this.carousel_config = {
      event_name: 'evaluation',
      legend: {
        previous: i18n<string>('EVENT.VISIT.CAROUSEL.NAVIGATION.Previous visit'),
        next: i18n<string>('EVENT.VISIT.CAROUSEL.NAVIGATION.Next visit'),
      },
    };
    super.ngOnInit();
  }

  ngAfterViewInit(): void {
    super.ngAfterViewInit();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  // #endregion

  /** */
  protected build_button_to_new_event(entity_id: number) {
    return {
      type: 'button',
      icon: 'mdi-plus-circle-outline',
      action: 'action',
      message: i18n<string>('EVENT.VISIT.Add visit'),
      navigate: {
        extras: {
          queryParamsHandling: 'preserve',
        },
        commands: ['', { outlets: { modal: ['apiary_evaluation', { aid: entity_id }] } }],
      },
    };
  }
}
