import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'bg2-modal-header',
  templateUrl: './bg2-modal-header.component.html',
  styleUrls: ['./bg2-modal-header.component.scss'],
})
export class Bg2ModalHeaderComponent {

  @Input()
  public isBeta = false;

}
