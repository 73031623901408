import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

import { isNil } from 'lodash-es';

import { marker as i18n } from '@biesbjerg/ngx-translate-extract-marker';

import { BehaviorSubject, map, Observable, of, switchMap, take, tap } from 'rxjs';
import { anyTrue, catchErrorInDialog, replay, waitForNotNilValue } from '@bg2app/tools/rxjs';

import { DeviceApi } from 'app/core';
import { DialogsService } from 'app/widgets/dialogs-modals';
import { AppStateService } from 'app/core/app-state.service';

import { DeviceInterface, DRDevice, SimpleSetterGetter } from 'app/models';

@Component({
  selector: 'bg2-device-modal-abstract-details-or-summary',
  template: '',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export abstract class DeviceModalAbstractCategoryComponent {
  // #region -> (component basics)

  constructor(
    protected readonly _appStateService: AppStateService,
    protected readonly _dialogs: DialogsService,
    protected readonly _deviceApi: DeviceApi
  ) {}

  @Output()
  public open_detail = new EventEmitter<boolean>();

  @Output()
  public after_delete = new EventEmitter<boolean>();

  // #endregion

  // #region -> (globals)

  /** */
  public is_superadmin$$ = this._appStateService.is_superadmin$$;

  // #endregion

  // #region -> (view type)

  /** */
  @Input()
  public view: 'summary' | 'details' = null;

  /** */
  public get is_either_view() {
    return this.view === 'summary' || this.view === 'details';
  }

  // #endregion

  // #region -> (loadings)

  /** */
  public is_loading_device = new SimpleSetterGetter(true);

  // #endregion

  // #region -> (device basics)

  /** */
  private _device$$ = new BehaviorSubject<DRDevice>(null);

  /** */
  public device$$ = this._device$$.asObservable().pipe(
    tap(device => {
      if (isNil(device)) {
        this.is_loading_device.value = true;
      } else {
        this.is_loading_device.value = false;
      }
    }),
    waitForNotNilValue()
  );

  /** */
  @Input()
  public set device(device: DRDevice) {
    this._device$$.next(device);
  }

  /**
   * Observes the name of the device.
   *
   * @observable
   */
  public device_name$$ = this.device$$.pipe(
    map(device => device?.name),
    replay()
  );

  /**
   * Observe the imei of the device.
   *
   * @observable
   */
  public device_imei$$ = this.device$$.pipe(
    map(device => device?.imei),
    replay()
  );

  /**
   * Observe the type of the device.
   *
   * @observable
   */
  public device_type$$: Observable<DeviceInterface.TypeEnum | string> = this.device$$.pipe(
    switchMap(device => device?.type$$ ?? of(null)),
    replay()
  );

  /**
   * Observe the type of the device.
   *
   * @observable
   */
  public device_generation$$ = this.device$$.pipe(
    switchMap(device => device?.generation$$ ?? of(null)),
    replay()
  );

  /** */
  public is_device_cpt$$ = this.device_type$$.pipe(
    map(device_type => {
      if (isNil(device_type)) {
        return false;
      }

      return (
        device_type === DeviceInterface.TypeEnum.CPT ||
        device_type === DeviceInterface.TypeEnum.CPTMC ||
        device_type === DeviceInterface.TypeEnum.BCA ||
        device_type === DeviceInterface.TypeEnum.BeeLive ||
        device_type === DeviceInterface.TypeEnum.BloomLive
      );
    })
  );

  /** */
  public is_device_bloomlive$$ = this.device_type$$.pipe(
    map(device_type => {
      if (isNil(device_type)) {
        return false;
      }

      return device_type === DeviceInterface.TypeEnum.BloomLive;
    })
  );

  /** */
  public is_device_is_wg$$ = this.device_type$$.pipe(
    map(device_type => {
      if (isNil(device_type)) {
        return false;
      }

      return device_type === DeviceInterface.TypeEnum.WG;
    })
  );

  /** */
  public is_device_is_tg$$ = this.device_type$$.pipe(
    map(device_type => {
      if (isNil(device_type)) {
        return false;
      }

      return device_type === DeviceInterface.TypeEnum.TG;
    })
  );

  /** */
  public is_device_is_gps$$ = this.device_type$$.pipe(
    map(device_type => {
      if (isNil(device_type)) {
        return false;
      }

      return device_type === DeviceInterface.TypeEnum.GPS;
    })
  );

  /** */
  public is_device_is_rg$$ = this.device_type$$.pipe(
    map(device_type => {
      if (isNil(device_type)) {
        return false;
      }

      return device_type === DeviceInterface.TypeEnum.RG;
    })
  );

  /** */
  public is_device_rg_or_gps$$ = anyTrue(this.is_device_is_rg$$, this.is_device_is_gps$$);

  /** */
  public delete_device() {
    this.device$$
      .pipe(
        switchMap(device =>
          this._dialogs
            .deleteConfirmWithInput(
              i18n('VIEWS.MODALS.DEVICE.Are you sure you want delete the device [dname] ?'),
              i18n('VIEWS.MODALS.DEVICE.Please, re-type device name to confirm deletion :'),
              i18n("VIEWS.MODALS.DEVICE.You didn't type the correct device name"),
              {
                on_true_message: i18n('VIEWS.MODALS.DEVICE.Delete the device'),
                on_false_message: i18n('VIEWS.MODALS.FORM.Abandon'),
                waited_input: device.name,
              },
              {
                dname: device.name,
              }
            )
            .pipe(map(confirm => ({ confirm, device })))
        ),
        switchMap(({ confirm, device }) => {
          if (confirm) {
            return this._deviceApi.delete_device$(device.imei);
          }

          return of(false);
        }),
        take(1),
        catchErrorInDialog(this._dialogs)
      )
      .subscribe({
        next: deleted => {
          if (deleted) {
            this.after_delete.next(true);
          }
        },
      });
  }

  // #endregion

  // #region -> (device affectation)

  /** */
  public device_warehouse_id$$ = this.device$$.pipe(
    switchMap(device => {
      if (isNil(device)) {
        return of(null);
      }

      return device?.warehouse_id$$;
    }),
    replay()
  );

  // #endregion

  // #region -> (device conf)

  /** */
  public device_full_config_modal$$ = this.device$$.pipe(
    switchMap(device => {
      if (isNil(device)) {
        return of(null);
      }

      return device?.full_config_modal$$;
    }),
    replay()
  );

  /** */
  public is_wguard_data_retrieval_active$$ = this.device$$.pipe(
    waitForNotNilValue(),
    switchMap(device => device?.is_wguard_data_retrieval_active$$)
  );

  // #endregion
}
