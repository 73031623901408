import { KeyValue } from '@angular/common';
import { ChangeDetectionStrategy, Component, HostListener, OnDestroy, OnInit } from '@angular/core';

import { isEmpty, isNil } from 'lodash-es';

import { marker as i18n } from '@biesbjerg/ngx-translate-extract-marker';

import { map, switchMap, tap, Observable, BehaviorSubject, of, take, combineLatest } from 'rxjs';
import { catchErrorInDialog, defaultValue, distinctUntilRealChanged, replay } from '@bg2app/tools/rxjs';

import { DeviceApi } from 'app/core';
import { DialogsService } from 'app/widgets/dialogs-modals';
import { AppStateService } from 'app/core/app-state.service';
import { UrlParamsService } from 'app/core/url-param.service';
import { DeviceQueryParams } from 'app/core/api/device/device-api-service';

import { DRDevice, SimpleSetterGetter } from 'app/models';

import { AbstractModalComponent, ModalArgs, ModalParams } from 'app/widgets/dialogs-modals/abstract-modal.component';

/** */
export interface DeviceModalParams extends ModalParams {
  /** */
  args: ModalArgs;

  /** */
  imei: string;
}

enum DeviceDetailName {
  HOME = 'home',
  DATA = 'data',
  GEOLOCATION = 'geoposition',
  AFFECTATION = 'affectation',
  CONFIGURATION = 'configuration',
  IDENTIFICATION = 'identification',
  STATUS_AND_BATTERY = 'diagnostic',
}

@Component({
  selector: 'bg2-device-modal',
  templateUrl: './device.modal.html',
  styleUrls: ['./device.modal.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DeviceModalComponent extends AbstractModalComponent<DeviceModalParams> implements OnInit, OnDestroy {
  // #region -> (component basics)

  /** */
  private readonly MODAL_URL_PARAM_NAME = 'inmodal_url';

  constructor(
    private readonly _deviceApi: DeviceApi,
    private readonly _dialogs: DialogsService,
    private readonly _appStateService: AppStateService,
    private readonly _url_params_service: UrlParamsService
  ) {
    super();
    this._logger.update_prefix('DeviceModalComponent');
    this.override_close_on_escape = true;
  }

  ngOnInit(): void {}

  ngOnDestroy(): void {
    this._url_params_service.del(this.MODAL_URL_PARAM_NAME);
  }

  // #endregion

  // #region -> (closing management)

  /** */
  protected handle_event_before_unload(event: BeforeUnloadEvent): void {
    return null;
  }

  /** */
  @HostListener('keydown.escape', ['$event'])
  public onEscapeKeyDown(event: KeyboardEvent) {
    setTimeout(() => {
      const is_the_last_modal = this.modals_service.isLast(this);

      if (!is_the_last_modal || event?.defaultPrevented || this.is_trying_to_close_modal) {
        this._logger.info(`Ignored "${event?.key}" keyboard event for reason : `, {
          is_not_last_opened_modal: !is_the_last_modal,
          managed_by_another_than_modal: event?.defaultPrevented,
          is_trying_to_close_modal: this.is_trying_to_close_modal,
        });

        return false;
      }

      this._logger.info(`Manage "${event?.key}" keyboard event`);

      event.preventDefault();
      event.stopPropagation();
      event.stopImmediatePropagation();

      const full_url = this._last_full_url;
      const has_detail_url = !isNil(full_url) && (full_url ?? '')?.split('/')?.length > 0;

      if (has_detail_url) {
        this.full_url = null;
      } else {
        this.modals_service.tryCloseLastModal();
      }

      return false;
    }, 5);
  }

  // #endregion

  /** */
  public is_superadmin$$ = this._appStateService.is_superadmin$$;

  // #region -> (loadings)

  /** */
  protected is_loading_device = new SimpleSetterGetter(true);

  // #endregion

  // #region -> (modal inner url navigation)

  /** */
  public readonly NAVIGATION_MENUS: {
    [key in DeviceDetailName]?: {
      /** */
      icon: `mdi-${string}`;

      /** */
      label: string;

      /** */
      url: DeviceDetailName;

      /** */
      sub: {
        [key: string]: {
          /** */
          name: string;
        };
      };
    };
  } = {
    identification: {
      icon: 'mdi-identifier',
      label: i18n<string>('VIEWS.DEVICES.DIALOGS_AND_MODALS.DEVICE_MODAL.INNER_NAVIGATION.Identity'),
      url: DeviceDetailName.IDENTIFICATION,
      sub: null,
    },
    // geoposition: {
    //   icon: 'mdi-map-marker',
    //   label: i18n<string>('VIEWS.DEVICES.DIALOGS_AND_MODALS.DEVICE_MODAL.INNER_NAVIGATION.Geolocation'),
    //   url: DeviceDetailName.GEOLOCATION,
    //   sub: null,
    // },
    diagnostic: {
      icon: 'mdi-list-status',
      label: i18n<string>('VIEWS.DEVICES.DIALOGS_AND_MODALS.DEVICE_MODAL.INNER_NAVIGATION.Diagnostic & Battery'),
      url: DeviceDetailName.STATUS_AND_BATTERY,
      sub: null,
    },
    data: {
      icon: 'mdi-numeric',
      label: i18n<string>('VIEWS.APIARY.SHARED.APIARY_DETAILS_COMPACT.VIEW_SELECT.Data'),
      url: DeviceDetailName.DATA,
      sub: null,
    },
    // configuration: {
    //   icon: 'mdi-cog',
    //   label: i18n<string>('VIEWS.MODALS.V1.Configuration'),
    //   url: DeviceDetailName.CONFIGURATION,
    //   sub: null,
    // },
    affectation: {
      icon: 'mdi-account',
      label: i18n<string>('VIEWS.DEVICES.DIALOGS_AND_MODALS.DEVICE_MODAL.INNER_NAVIGATION.Affectation'),
      url: DeviceDetailName.AFFECTATION,
      sub: null,
    },
  };

  /** */
  private _last_full_url: string;

  /** */
  public full_url$$: Observable<`${DeviceDetailName}`> = this._url_params_service.on_change(this.MODAL_URL_PARAM_NAME).pipe(
    defaultValue(DeviceDetailName.HOME, 100),
    distinctUntilRealChanged(),
    tap(value => {
      // Force the url param to indicate "home" (important: we replace the current url)
      if (value === DeviceDetailName.HOME) {
        this._url_params_service.set(this.MODAL_URL_PARAM_NAME, DeviceDetailName.HOME, true);
      }
    }),
    tap(value => this._last_full_url = value),
    replay()
  );

  /** */
  public set full_url(full_url: `${DeviceDetailName}`) {
    this._url_params_service.set(this.MODAL_URL_PARAM_NAME, full_url, false);
  }

  /** */
  public detail_url$$: Observable<DeviceDetailName[]> = this.full_url$$.pipe(
    map(full_url => {
      if (isNil(full_url) || isEmpty(full_url) || full_url == DeviceDetailName.HOME) {
        return [];
      }

      return <any>full_url.split('/');
    }),
    replay()
  );

  /** */
  public is_summary_view$$ = this.detail_url$$.pipe(
    map(detail_url => detail_url?.length === 0),
    replay()
  );

  /** */
  public originalOrder = (a: KeyValue<string, any>, b: KeyValue<string, any>): number => 0;

  /** */
  public context$$ = combineLatest({ is_summary_view: this.is_summary_view$$, detail_url: this.detail_url$$ }).pipe(
    distinctUntilRealChanged(),
    replay()
  );

  // #endregion

  /** */
  public readonly DEVICE_DETAIL_NAME = DeviceDetailName;

  // #region -> (device basis)

  /** */
  public device_imei$$ = this.input_params$$.pipe(
    map(parameters => {
      const device_imei = parameters?.imei;

      if (isNil(device_imei) || isEmpty(device_imei)) {
        throw new Error('Missing or empty device IMEI !');
      }

      return parseInt(device_imei, 10);
    }),
    replay()
  );

  /** */
  public device$$: Observable<DRDevice> = this.device_imei$$.pipe(
    tap(() => (this.is_loading_device.value = true)),
    switchMap(device_imei => {
      const device_query_params: DeviceQueryParams = {
        last_measurements: [
          'message',
          'gateway_message',
          'sensor_message',
          'location',
          'location_AGG_GPS',
          'energy',
          'weight',
          'env',
          'mvmt_status',
          'reset_cause_AGG_power_on',
        ],
        last_measurements_details: ['gateway_message', 'sensor_message', 'sensor_message_AGG_as_gateway'],
        include: ['gateways', 'sensors'],
      };

      return this._deviceApi.requestDevice(device_imei, device_query_params).pipe(catchErrorInDialog(this._dialogs));
    }),
    tap(device => {
      if (!isNil(device)) {
        this.is_loading_device.value = false;
        return;
      }

      this.is_loading_device.value = true;
    }),
    replay()
  );

  /** */
  public device_name$$ = this.device$$.pipe(
    map(device => device?.name),
    replay()
  );

  /** */
  public device_type$$ = this.device$$.pipe(
    switchMap(device => device?.type$$ ?? of('default')),
    replay()
  );

  // #endregion
}
