import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { isNil } from 'lodash-es';

import { map, of, switchMap, take } from 'rxjs';

import { ConsoleLoggerService } from '../console-logger.service';
import { BeeguardAuthService } from './beeguard-auth.service';

import { RedirectRoute } from 'app/models/misc/redirect-route.interface';

@Injectable()
export class BeeguardAuthGuard implements CanActivate {
  // #region -> (guard basics)

  /** */
  private readonly LOGGER = new ConsoleLoggerService('BeeguardAuthGuard', true);

  /** */
  constructor(private _authenticationService: BeeguardAuthService, private router: Router) {}

  /** */
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const redirect_route = this.build_redirect_route(state);

    return this._authenticationService.hasValidAccessToken$().pipe(
      map(hasValidAccessToken => {
        // Token is valid
        if (hasValidAccessToken?.valid) {
          return true;
        }

        switch (hasValidAccessToken?.reason ?? 'undefined') {
          case 'empty':
          case 'undefined': {
            this.LOGGER.debug('No access token in local storage, redirect to login ...');

            localStorage.setItem('redirect', JSON.stringify(redirect_route));
            this.router.navigate(['', { outlets: { primary: 'login', modal: ['raz'] } }], {
              replaceUrl: true,
              queryParams: {},
            });

            return false;
          }

          case 'expired': {
            localStorage.setItem('redirect', JSON.stringify(redirect_route));
            this.router.navigate(['', { outlets: { primary: 'login', modal: ['raz'] } }], {
              replaceUrl: true,
              queryParams: { logType: 'invalid-access-token' },
            });

            return false;
          }
        }
      })
    );
  }

  // #endregion

  /** */
  private build_redirect_route(state: RouterStateSnapshot): RedirectRoute {
    const redirect_route: RedirectRoute = {
      modal: null,
      modal_params: null,
      modal_args: null,
      uri: state.url.split('?')[0],
      uri_params: decodeURIComponent(state.url.split('?')[1]),
    };

    if (redirect_route.uri.includes('(modal:')) {
      const modal_part = redirect_route.uri.split('(modal:')[1];
      redirect_route.uri = redirect_route.uri.split('(modal:')[0];

      const [modal_name, ...each_modal_params] = modal_part.substring(0, modal_part.length - 1).split(';');
      redirect_route.modal = modal_name;

      // Extract modal parameters
      redirect_route.modal_params = decodeURIComponent(each_modal_params.filter(p => !p.startsWith('args=')).join(';'));

      // Extract modal arguments
      const modal_params_args = each_modal_params.filter(value => value.startsWith('args='))[0];
      if ((modal_params_args ?? '').length > 0) {
        redirect_route.modal_args = JSON.parse(decodeURIComponent(modal_params_args.split('args=')[1]).replace(/[\n\r]/g, ''));
      }
    }

    return redirect_route;
  }
}
