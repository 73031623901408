import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';

import { differenceBy, isEmpty, isNil, uniqBy } from 'lodash-es';

import { ISchema } from 'ngx-schema-form';

import { BehaviorSubject, combineLatest, map, of, switchMap, take } from 'rxjs';
import { catchErrorInDialog, replay, switchTap } from '@bg2app/tools/rxjs';

import { marker as i18n } from '@biesbjerg/ngx-translate-extract-marker';

import { DeviceApi } from 'app/core/api/device/device-api-service';
import { AbstractModalComponent, DialogsService } from 'app/widgets/dialogs-modals';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { ImportDevicesToCreateDialogComponent } from '../../dialogs/import-devices-to-create-dialog/import-devices-to-create-dialog.component';
import { NewDeviceCandidate } from 'app/core/api-swagger/device';

interface CreateDeviceModalParams extends AbstractModalComponent {}

/** */
interface CreateDeviceFormData {
  /** */
  device_type: DeviceType;
}

/** */
interface DraggableDevice extends NewDeviceCandidate {
  /**
   * Type of the device.
   */
  type: 'gateway' | 'sensor';

  /** */
  is_valid?: boolean;
}

type DeviceType =
  | 'Device.Gateway.Dynamic.GPSd'
  | 'Device.Sensor.WG'
  | 'Device.Sensor.TG'
  | 'Device.Gateway.Dynamic.RG'
  | 'Device.CPT'
  | 'Device.LAB'
  | 'Device.Gateway.Dynamic.CPTMC'
  | 'Device.Compute'
  | 'Device.Compute.BloomLive'
  | 'Device.Compute.BeeLive';

@Component({
  selector: 'bg2-create-device-modal',
  templateUrl: './create-device-modal.component.html',
  styleUrls: ['./create-device-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CreateDeviceModalComponent extends AbstractModalComponent<CreateDeviceModalParams> implements OnInit, OnDestroy {
  // #region -> (component basics)

  constructor(private readonly _dialogs_service: DialogsService, private readonly _device_api: DeviceApi) {
    super();
  }

  ngOnInit(): void {
    this._device_api
      .fetch_new_device$()
      .pipe(catchErrorInDialog(this._dialogs_service), take(1))
      .subscribe({
        next: candidates => {
          candidates = candidates.map(c => {
            c.id = parseInt(c.id as any as string, 10);
            return c;
          });

          this._candidate_device$$.next(candidates as Partial<DraggableDevice>[]);
          this._is_loading_candidate_devices$$.next(false);
        },
      });
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }

  /** */
  public close(raz = false) {
    if (this._devices_to_create$$.getValue()?.length > 0) {
      this._dialogs_service
        .confirm(i18n<string>('VIEWS.MODALS.FORM.Abandon the device creation ?'), {
          onTrueMessage: i18n<string>('VIEWS.MODALS.FORM.Abandon'),
          onFalseMessage: i18n<string>('VIEWS.MODALS.FORM.Continue'),
        })
        .subscribe(agreement => {
          if (agreement) {
            super.close(raz);
          }
        });
    } else {
      super.close(raz);
    }
  }

  private force_close() {
    super.close();
  }

  /** */
  protected handle_event_before_unload(event: BeforeUnloadEvent): void {
    return null;
  }

  // #endregion

  // #region -> (candidate devices)

  /** */
  private _is_loading_candidate_devices$$ = new BehaviorSubject<boolean>(true);

  /** */
  public is_loading_candidate_devices$$ = this._is_loading_candidate_devices$$.asObservable();

  // #endregion

  // #region -> (import devices)

  /** */
  public import_more_devices(): void {
    this._dialogs_service.open(ImportDevicesToCreateDialogComponent, {}).subscribe({
      next: imported_devices => {
        if (isEmpty(imported_devices ?? [])) {
          return;
        }

        this.merge_devices_to_import(imported_devices);
      },
    });
  }

  // #endregion

  // #region -> (drag n drop management)

  /** */
  private _candidate_device$$ = new BehaviorSubject<Partial<DraggableDevice>[]>([]);

  /**
   * Observe list of candidate devices.
   */
  public candidate_devices$$ = this._candidate_device$$.asObservable();

  /** */
  private _devices_to_create$$ = new BehaviorSubject<Partial<DraggableDevice>[]>([]);

  /**
   * Observe list of devices to create.
   */
  public devices_to_create$$ = this._devices_to_create$$.asObservable().pipe(
    switchMap(devices_to_create =>
      this.form_model$$.pipe(
        map(form_model => form_model?.device_type),
        map(device_type =>
          devices_to_create.map(device_to_create => {
            if (isNil(device_type)) {
              device_to_create.is_valid = null;
              return device_to_create;
            }

            if (isNil(device_to_create.type)) {
              device_to_create.is_valid = null;
              return device_to_create;
            }

            device_to_create.is_valid = this.is_device_type_matches_platform(device_to_create.type, device_type);
            return device_to_create;
          })
        )
      )
    ),
    replay()
  );

  /** */
  public drop(event: CdkDragDrop<Partial<DraggableDevice>[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);

      // After move
      if (event.container.id === 'cdk-drop-list-candidate-devices') {
        this._candidate_device$$.next(event.container.data);
      } else {
        this._devices_to_create$$.next(event.container.data);
      }
    } else {
      transferArrayItem(event.previousContainer.data, event.container.data, event.previousIndex, event.currentIndex);

      // After transfer
      if (event.previousContainer.id === 'cdk-drop-list-candidate-devices') {
        this._candidate_device$$.next(event.previousContainer.data);
        this._devices_to_create$$.next(event.container.data);
      } else {
        this._candidate_device$$.next(event.container.data);
        this._devices_to_create$$.next(event.previousContainer.data);
      }
    }
  }

  /** */
  public devices_to_create_is_valid$$ = this.devices_to_create$$.pipe(
    map(devices_to_create => devices_to_create.filter(device_to_create => !isNil(device_to_create?.type ?? null))),
    map(devices_to_create => devices_to_create.filter(device_to_create => !device_to_create.is_valid)),
    map(non_valid_devices => non_valid_devices?.length === 0)
  );

  /** */
  private merge_devices_to_import(imported_devices: number[]): void {
    const imported_as_draggable = imported_devices.map(imported_device => <Partial<DraggableDevice>>{ id: imported_device });

    const current_candidates = this._candidate_device$$.getValue();
    const current_to_be_created = this._devices_to_create$$.getValue();

    // List of candidates that are imported
    const imported_candidates = current_candidates.filter(a => imported_devices.includes(a.id));

    // Remove possible devices to import from current candidates
    const new_candidate_devices = differenceBy(current_candidates, imported_as_draggable, 'id');
    this._candidate_device$$.next(new_candidate_devices);

    // Update to be created by removing possible duplicates
    const new_to_be_created_devices = uniqBy([...imported_candidates, ...current_to_be_created, ...imported_as_draggable], 'id');
    this._devices_to_create$$.next(new_to_be_created_devices);
  }

  /** */
  private raz_devices_to_create(): void {
    this._devices_to_create$$.next([]);
  }
  // #endregion

  // #region -> (schema management)

  /** */
  private readonly _default_schema: ISchema = {
    type: 'object',
    required: ['device_type'],
    properties: {
      device_type: {
        type: 'string',
        widget: 'ng-mat-select',
        label: i18n<string>('DEVICE.ALL.COMMON.Device type'),
        default: null,
        options: {
          with_reset: false,
        },
        oneOf: [
          {
            enum: ['Device.Gateway.Dynamic.GPSd'],
            label: 'GPS',
          },
          {
            enum: ['Device.Sensor.WG'],
            label: 'WG (Balance)',
          },
          {
            enum: ['Device.Sensor.TG'],
            label: 'TG (TemperatureGuard - Tag)',
          },
          {
            enum: ['Device.Gateway.Dynamic.RG'],
            label: 'RG (Station meteo)',
          },
          {
            enum: ['Device.CPT'],
            label: 'BeeLive (HWV2/HWV3 - DEPRECATED)',
          },
          {
            enum: ['Device.LAB'],
            label: 'LAB (Labyrinthe)',
          },
          {
            enum: ['Device.Gateway.Dynamic.CPTMC'],
            label: 'CPTMC (BeeLive Mission Ctrl)',
          },
          {
            enum: ['Device.Compute'],
            label: 'Compute (generic hwv4-compatible - only for tests)',
          },
          {
            enum: ['Device.Compute.BeeLive'],
            label: 'BeeLive (HWV4)',
          },
          {
            enum: ['Device.Compute.BloomLive'],
            label: 'BloomLive',
          },
        ],
      },
    },
  };

  /** */
  public form_schema$$ = of(this._default_schema).pipe(replay());

  // #endregion

  // #region -> (form model management)

  private readonly default_form_value: CreateDeviceFormData = {
    device_type: null,
  };

  /** */
  private _form_model$$ = new BehaviorSubject<CreateDeviceFormData>(this.default_form_value);

  /** */
  public form_model$$ = this._form_model$$.asObservable().pipe();

  /** */
  public onFormChanged(fdata: CreateDeviceFormData): void {
    this._form_model$$.next(fdata);
    // Run check of devices to create
  }

  // #endregion

  // #region -> (form validity)

  /** */
  private _form_valid$$ = new BehaviorSubject(false);

  /** */
  public form_valid$$ = this._form_valid$$.asObservable();

  /** */
  public update_form_validity(is_valid: boolean): void {
    this._form_valid$$.next(is_valid);
  }

  // #endregion

  // #region -> (last created devices)

  /** */
  private _reload_last_created_devices$$ = new BehaviorSubject(0);
  public reload_last_created_devices$$ = this._reload_last_created_devices$$.asObservable();

  public reload_last_created_devices() {
    this._reload_last_created_devices$$.next(this._reload_last_created_devices$$.getValue() + 1);
  }

  // #endregion

  /** */
  public submit() {
    combineLatest({ form_data: this.form_model$$, devices_to_create: this.devices_to_create$$ })
      .pipe(
        take(1),
        switchMap(({ form_data, devices_to_create }) => {
          if (isNil(devices_to_create)) {
            return of(null);
          } else {
            const imeis = devices_to_create.map(device_to_create => device_to_create.id);
            this._logger.info('Create', imeis);
            return this._device_api.create_devices$(form_data.device_type, imeis);
          }
        }),
        switchTap(res => {
          let res_that_handle_errors$$ = of(res);
          console.log(res);
          if (res.errors?.length > 0) {
            res.errors.forEach(error => {
              res_that_handle_errors$$ = res_that_handle_errors$$.pipe(
                switchMap(() => this._dialogs_service.error({ error: error.error_data }))
              );
            });
          }
          return res_that_handle_errors$$;
        }),
        catchErrorInDialog(this._dialogs_service),
        switchTap(res => {
          if (res.created.length > 0) {
            return this._dialogs_service.alert(
              i18n<string>('VIEWS.DEVICES.DIALOGS_AND_MODALS.CREATE_DEVICE_MODAL.[nb_created] device(s) has been created'),
              {
                nb_created: res.created.length,
              }
            );
          } else {
            return of(null);
          }
        })
      )
      .subscribe({
        next: result => {
          this.raz_devices_to_create();
          this.reload_last_created_devices();
        },
        error: (error: unknown) => {},
        complete: () => {},
      });
  }

  private is_device_type_matches_platform(platform: 'gateway' | 'sensor', device_type: DeviceType) {
    let is_device_type_matches_platform = null;

    if (device_type === 'Device.Gateway.Dynamic.RG') {
      return true
    }

    switch (device_type) {
      case 'Device.CPT':
      case 'Device.LAB':
      case 'Device.Compute':
      case 'Device.Compute.BeeLive':
      case 'Device.Compute.BloomLive':
      case 'Device.Gateway.Dynamic.GPSd':
      case 'Device.Gateway.Dynamic.CPTMC': {
        is_device_type_matches_platform = platform === 'gateway';
        break;
      }

      case 'Device.Sensor.WG':
      case 'Device.Sensor.TG': {
        is_device_type_matches_platform = platform === 'sensor';
        break;
      }
    }

    return is_device_type_matches_platform;
  }
}