<div
  *ngLet="apiary$$ | async; let apiary"
  class="bg2-apiary-details bg2-apiary-details-compact-component"
  [ngClass]="{ 'bg2-apiary-details--ghost': apiary?.is_ghost$$ | async }"
>
  <div class="bg2-apiary-details__header-summary">
    <div class="bg2-apiary-details__header-summary__numbered-summary">
      <div
        *ngLet="
          { total: apiary?.nb_total$$ | async, total_hives: apiary?.nb_hives$$ | async, total_nucs: apiary?.nb_nuc$$ | async };
          let context
        "
        class="bg2-apiary-details__header-summary__numbered-summary__hives-summary"
        [popper]="popperHivesNucsTotal"
        [popperApplyClass]="'bg2-apiary-details__header-summary__numbered-summary__hives-summary__popper'"
      >
        <div *ngIf="context?.total_hives <= 0 && context?.total_nucs <= 0">
          <bg2-picto-hive [htype]="'hive'" [title]="false" [size]="'50px'"></bg2-picto-hive>
          <span>0</span>
        </div>

        <div *ngIf="context?.total_hives > 0">
          <bg2-picto-hive [htype]="'hive'" [title]="false" [size]="'50px'"></bg2-picto-hive>
          <span>{{ context?.total_hives }}</span>
        </div>

        <!-- <span *ngIf="context?.total_hives > 0 && context?.total_nucs > 0">/</span> -->

        <div *ngIf="context?.total_nucs > 0">
          <bg2-picto-hive [htype]="'nuc'" [title]="false" [size]="'50px'"></bg2-picto-hive>
          <span>{{ context?.total_nucs }}</span>
        </div>

        <popper-content #popperHivesNucsTotal>
          <span>
            {{ 'VIEWS.APIARY.SHARED.APIAR_DETAILS_COMPACT.Total hives : [total]' | translate: { total: apiary?.nb_hives$$ | async } }}
          </span>

          <span>
            {{ 'VIEWS.APIARY.SHARED.APIAR_DETAILS_COMPACT.Total nucs : [total]' | translate: { total: apiary?.nb_nuc$$ | async } }}
          </span>
        </popper-content>
      </div>

      <div
        *ngLet="apiary?.user_acl?.can$$('read_devices') | async; let can_read_devices"
        class="bg2-apiary-details__header-summary__numbered-summary__devices-summary"
        [ngClass]="{ clickable: can_read_devices }"
        (click)="can_read_devices ? (view_to_display = 'devices') : null"
      >
        <span
          *ngLet="apiary?.devices_gps__total$$ | async; let devices_gps__total"
          class="mdi mdi-24px mdi-satellite-variant"
          [ngClass]="{ 'mat-badge-grey': !can_read_devices }"
          [matBadge]="can_read_devices ? devices_gps__total : '🔒'"
          [popper]="
            can_read_devices
              ? ('VIEWS.APIARY.SHARED.APIARY_DETAILS_COMPACT.[x] GPS on the apiary' | translate: { x: devices_gps__total })
              : ('ALL.ERROR.ACE.READ_DEVICES.You do not have the right to see the total of devices' | translate)
          "
        ></span>

        <span
          *ngLet="apiary?.devices_wg__total$$ | async; let devices_wg__total"
          class="mdi mdi-24px mdi-weight-kilogram"
          [ngClass]="{ 'mat-badge-grey': !can_read_devices }"
          [matBadge]="can_read_devices ? devices_wg__total : '🔒'"
          [popper]="
            can_read_devices
              ? ('VIEWS.APIARY.SHARED.APIARY_DETAILS_COMPACT.[x] WG on the apiary' | translate: { x: devices_wg__total })
              : ('ALL.ERROR.ACE.READ_DEVICES.You do not have the right to see the total of devices' | translate)
          "
        ></span>

        <ng-container *ngLet="apiary?.devices_rg__total$$ | async; let apiary_total_rg">
          <span
            *ngIf="apiary_total_rg > 0"
            class="mdi mdi-24px mdi-water"
            [ngClass]="{ 'mat-badge-grey': !can_read_devices }"
            [matBadge]="can_read_devices ? apiary_total_rg : '🔒'"
            [popper]="
              can_read_devices
                ? ('VIEWS.APIARY.SHARED.APIARY_DETAILS_COMPACT.[x] RG on the apiary' | translate: { x: apiary_total_rg })
                : ('ALL.ERROR.ACE.READ_DEVICES.You do not have the right to see the total of devices' | translate)
            "
          ></span>
        </ng-container>

        <!-- <ng-container *ngLet="apiary?.devices_beelive__total$$ | async; let apiary_total_beelive">
          <span
            *ngIf="apiary_total_beelive > 0"
            class="mdi mdi-24px mdi-counter"
            [ngClass]="{ 'mat-badge-grey': !can_read_devices }"
            [matBadge]="can_read_devices ? apiary_total_beelive : '🔒'"
            [popper]="
              can_read_devices
                ? ('VIEWS.APIARY.SHARED.APIARY_DETAILS_COMPACT.[x] BeeLive on the apiary' | translate: { x: apiary_total_beelive })
                : ('ALL.ERROR.ACE.READ_DEVICES.You do not have the right to see the total of devices' | translate)
            "
          ></span>
        </ng-container> -->
      </div>
    </div>

    <div class="bg2-apiary-details__header-summary__dated-summary">
      <div class="bg2-apiary-details__header-summary__dated-summary__last-visit">
        <span class="label">{{ 'VIEWS.APIARY.SHARED.WIDGETS.APIARY_LAST_DATA.APIARY_LAST_DATA_CARD.Last visit' | translate | colon }}</span>

        <span
          *ngLet="apiary?.user_acl.can$$('read_all_events') | async; let can_read_all_events"
          class="value"
          [popper]="popperLastVisitDate"
          [popperDisabled]="can_read_all_events"
        >
          <ng-container *ngIf="can_read_all_events">
            <span *ngIf="last_visit_date$$ | async; let last_visit_date; else: hasNoVisit">
              {{ last_visit_date | formatDate: 'short' }}
            </span>
            <ng-template #hasNoVisit>{{ 'VIEWS.MODALS.VISIT.No visits reported' | translate }}</ng-template>
          </ng-container>

          <ng-container *ngIf="!can_read_all_events">
            <span class="mdi mdi-lock"></span>
            <span>{{ 'ALL.COMMON.Unknown' | translate }}</span>
          </ng-container>
        </span>

        <popper-content #popperLastVisitDate>
          <bg2-acl-tooltip-content
            [ace]="'read_all_events'"
            [entity_type]="'location'"
            [what]="'ALL.ERROR.ACE.READ_ALL_EVENTS.WHAT.the visits' | translate | lowercase"
          ></bg2-acl-tooltip-content>
        </popper-content>
      </div>

      <div *ngIf="devices_last_com$$ | async; let devices_last_com" class="bg2-apiary-details__header-summary__dated-summary__last-com">
        <span class="label">{{ 'VIEWS.APIARY.SHARED.WIDGETS.APIARY_LAST_DATA.APIARY_LAST_DATA_CARD.Last com' | translate | colon }}</span>
        <span class="value">{{ devices_last_com | formatDate: 'short' }}</span>
      </div>
    </div>
  </div>

  <div class="bg2-apiary-details__view-navigator">
    <ng-container *ngLet="apiary?.user_acl?.can$$('read_all_events') | async; let can_read_all_events">
      <span
        class="bg2-apiary-details__view-navigator__link"
        [popper]="popperNavToVisits"
        [popperDisabled]="can_read_all_events"
        [ngClass]="{ active: (view_to_display$$ | async) === 'apiary_visits', 'locked-due-to-rights': !can_read_all_events }"
        (click)="can_read_all_events ? (view_to_display = 'apiary_visits') : null"
      >
        <span class="mdi" [ngClass]="{ 'mdi-calendar-multiple-check': can_read_all_events, 'mdi-lock': !can_read_all_events }"></span>
        {{ 'VIEWS.APIARY.SHARED.APIARY_DETAILS_COMPACT.VIEW_SELECT.Visits' | translate }}
      </span>

      <popper-content #popperNavToVisits>
        <bg2-acl-tooltip-content
          [ace]="'read_all_events'"
          [entity_type]="'location'"
          [what]="'ALL.ERROR.ACE.READ_ALL_EVENTS.WHAT.the visits' | translate | lowercase"
        ></bg2-acl-tooltip-content>
      </popper-content>
    </ng-container>

    <span>|</span>

    <ng-container *ngLet="apiary?.user_acl?.can$$('read_devices') | async; let can_read_devices">
      <span
        class="bg2-apiary-details__view-navigator__link"
        [popper]="popperNavToDevices"
        [popperDisabled]="can_read_devices"
        [ngClass]="{ active: (view_to_display$$ | async) === 'devices', 'locked-due-to-rights': !can_read_devices }"
        (click)="can_read_devices ? (view_to_display = 'devices') : null"
      >
        <span class="mdi" [ngClass]="{ 'mdi-shape-plus': can_read_devices, 'mdi-lock': !can_read_devices }"></span>

        {{ 'VIEWS.APIARY.SHARED.APIARY_DETAILS_COMPACT.VIEW_SELECT.Devices' | translate }}
      </span>

      <popper-content #popperNavToDevices>
        <bg2-acl-tooltip-content
          [ace]="'read_devices'"
          [entity_type]="'location'"
          [what]="'ALL.ERROR.ACE.READ_ALL_EVENTS.WHAT.the devices' | translate | lowercase"
        ></bg2-acl-tooltip-content>
      </popper-content>
    </ng-container>

    <span>➔</span>

    <ng-container *ngLet="apiary?.user_acl?.can$$('read_devices') | async; let can_read_devices">
      <span
        class="bg2-apiary-details__view-navigator__link"
        [popper]="popperNavToEqHives"
        [popperDisabled]="can_read_devices"
        [ngClass]="{ active: (view_to_display$$ | async) === 'last_data', 'locked-due-to-rights': !can_read_devices }"
        (click)="can_read_devices ? (view_to_display = 'last_data') : null"
      >
        <span *ngIf="!can_read_devices" class="mdi mdi-lock"></span>
        <bg2-picto-hive *ngIf="can_read_devices" [size]="'20px'"></bg2-picto-hive>

        {{ 'VIEWS.APIARY.SHARED.APIARY_DETAILS_COMPACT.VIEW_SELECT.Eq. hives' | translate }}
      </span>

      <popper-content #popperNavToEqHives>
        <bg2-acl-tooltip-content
          [ace]="'read_devices'"
          [entity_type]="'location'"
          [what]="'ALL.ERROR.ACE.READ_ALL_EVENTS.WHAT.the equipped hives' | translate | lowercase"
        ></bg2-acl-tooltip-content>
      </popper-content>
    </ng-container>

    <span>➔</span>

    <ng-container *ngLet="apiary?.user_acl?.can$$('read_measurements_data') | async; let can_read_measurements_data">
      <span
        class="bg2-apiary-details__view-navigator__link"
        [popper]="popperNavToData"
        [popperDisabled]="can_read_measurements_data"
        [ngClass]="{ active: (view_to_display$$ | async) === 'data_chart', 'locked-due-to-rights': !can_read_measurements_data }"
        (click)="can_read_measurements_data ? (view_to_display = 'data_chart') : null"
      >
        <span class="mdi" [ngClass]="{ 'mdi-chart-multiple': can_read_measurements_data, 'mdi-lock': !can_read_measurements_data }"></span>

        {{ 'VIEWS.APIARY.SHARED.APIARY_DETAILS_COMPACT.VIEW_SELECT.Data' | translate }}
      </span>

      <popper-content #popperNavToData>
        <bg2-acl-tooltip-content
          [ace]="'read_measurements_data'"
          [entity_type]="'location'"
          [what]="'ALL.ERROR.ACE.READ_ALL_EVENTS.WHAT.the data' | translate | lowercase"
        ></bg2-acl-tooltip-content>
      </popper-content>
    </ng-container>
  </div>

  <div class="bg2-apiary-details__adaptive-body">
    <ng-container *ngIf="loadings.view_to_display$$ | async; else bodyTemplate">
      <bg2-loading-skeleton [theme]="{ height: '100%' }"></bg2-loading-skeleton>
    </ng-container>

    <ng-template #bodyTemplate>
      <ng-container [ngSwitch]="view_to_display$$ | async">
        <ng-container *ngSwitchCase="'last_data'">
          <bg2-apiary-last-data [apiary]="apiary$$ | async" [show_navigation]="false"></bg2-apiary-last-data>
        </ng-container>

        <ng-container *ngSwitchCase="'data_chart'">
          <bg2-apiary-charts-for-compact [apiary]="apiary$$ | async"></bg2-apiary-charts-for-compact>
        </ng-container>

        <ng-container *ngSwitchCase="'apiary_visits'">
          <bg2-apiary-br-evaluation-carousel [apiary]="apiary$$ | async"></bg2-apiary-br-evaluation-carousel>
        </ng-container>

        <ng-container *ngSwitchCase="'devices'">
          <ng-container *ngLet="devices$$ | async; let devices">
            <div *ngIf="devices?.length > 0" class="bg2-apiary-details__adaptive-body__devices-list">
              <bg2-device-inline
                *ngFor="let device of devices; trackBy: track_by_devices"
                [device]="device"
                [show_action]="true"
                [show_last_com]="true"
                [hive_id]="device.hive_config?.hive_id || null"
              >
              </bg2-device-inline>
            </div>

            <ng-container *ngIf="(devices$$ | async)?.length === 0">
              <bg2-ia-single-dialog>
                <ng-container *ngIf="(exploitation__has_devices$$ | async) === false; else noAssociatedDevices">
                  <span
                    [innerHtml]="'VIEWS.APIARY.SHARED.APIARY_DETAILS_COMPACT.DEVICES.No devices on exploitation' | translate | markdown"
                  ></span>

                  <bg2-button
                    [type]="'action'"
                    [internal_routing]="{ routerLink: ['', { outlets: { modal: ['new_issue'] } }] }"
                    [message]="'ROUTABLE_MODALS.NEW_ISSUE.TYPES.ASK_QUOTE.Ask for a quote' | translate"
                  ></bg2-button>
                </ng-container>

                <ng-template #noAssociatedDevices>
                  <span [innerHtml]="'VIEWS.APIARY.SHARED.APIARY_DETAILS_COMPACT.DEVICES.Can affect devices' | translate | markdown"></span>

                  <bg2-button
                    [type]="'action'"
                    [color]="'device'"
                    [icon]="'mdi-shape-polygon-plus'"
                    [message]="'VIEWS.APIARY.SHARED.WIDGETS.APIARY_LAST_DATA.Manage Hives & Devices' | translate"
                    [internal_routing]="{ routerLink: ['', { outlets: { modal: ['apiary_hive_list', { eid: apiary_id$$ | async }] } }] }"
                  ></bg2-button>
                </ng-template>
              </bg2-ia-single-dialog>
            </ng-container>
          </ng-container>
        </ng-container>

        <ng-container *ngSwitchCase="'ia'">
          <bg2-ia-single-dialog *ngLet="apiary?.user_acl.only$$('read') | async; let has_only_minimal_access">
            <bg2-error-helper *ngIf="ia_view_error$$ | async; let error" [error]="error"></bg2-error-helper>

            <ng-container *ngIf="(ia_view_error$$ | async) === null">
              <span [innerHtml]="'VIEWS.APIARY.SHARED.APIARY_DETAILS_COMPACT.NOTHING_ON_APIARY.Description' | translate | markdown"></span>

              <bg2-button-condition
                [types]="['action', 'action']"
                [colors]="['device', 'breeding-register']"
                [icons]="['mdi-shape-polygon-plus', 'mdi-plus-circle-outline']"
                [messages]="[
                  'VIEWS.APIARY.SHARED.WIDGETS.APIARY_LAST_DATA.Manage Hives & Devices' | translate,
                  'EVENT.VISIT.Add visit' | translate
                ]"
                [internal_routing_0]="{
                  routerLink: ['', { outlets: { modal: ['apiary_hive_list', { eid: (apiary_id$$ | async) }] } }],
                  queryParamsHandling: 'preserve'
                }"
                [internal_routing_1]="{
                  routerLink: ['', { outlets: { modal: ['apiary_evaluation', { aid: (apiary_id$$ | async) }] } }],
                  queryParamsHandling: 'preserve'
                }"
              ></bg2-button-condition>
            </ng-container>
          </bg2-ia-single-dialog>
        </ng-container>
      </ng-container>
    </ng-template>
  </div>

  <div class="bg2-apiary-details__footer">
    <bg2-button
      [type]="'navigation'"
      [routerLink]="['', { outlets: { modal: ['location_details', { location_id: location_id$$ | async }] } }]"
      [queryParamsHandling]="'preserve'"
      [message]="'VIEWS.APIARY.SHARED.APIARY_DETAILS_COMPACT.View apiary details' | translate"
    >
    </bg2-button>
  </div>
</div>
