import { Component, Input, ChangeDetectionStrategy } from '@angular/core';


@Component({
  selector: 'dialog-header',
  templateUrl: 'dialog-header.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DialogHeaderComponent {

  @Input()
  public header: string = null;

}
