<ng-container *ngIf="visible$$ | async">
  <form-element-header [schema]="schema" [title_style]="options.title_style"></form-element-header>
  <ng-container *ngIf="choices_lines && choices_lines.length > 0">
    <div class="radiogroup">
      <div class="radiogroup-line" *ngFor="let line of choices_lines">
        <!-- For each line -->
        <div class="radiogroup-options" *ngFor="let choice of line.choices" [class.checked]="choice.value === value">
          <div class="set-position">
            <!-- For each option of the line -->
            <input
              type="radio"
              color="primary"
              [ngModel]="value$$ | async"
              (ngModelChange)="value = $event"
              [id]="'radio_' + id + '_' + choice.value"
              [name]="id + '_radiogroup'"
              [value]="choice.value"
              [checked]="choice.value === value"
            >
            <label for="'radio_' + id + '_' + choice.value" *ngIf="choice.label || !choice.options.img">
              <span> {{choice.label ? (choice.label | translate) : '&nbsp;'}} </span>
              <img
                *ngIf="choice.options.img"
                src="/assets/{{choice.options.img}}"
                [alt]="choice.label | translate"
                class="radio-image"
              />
            </label>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="previous$$ | async; let previous">
    <form-element-previous [previous]="previous"></form-element-previous>
  </ng-container>
</ng-container>
