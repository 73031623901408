<div class="ng-mat-tel-container">
  <div class="form-fields">
    <mat-form-field [appearance]="'outline'">
      <mat-label>{{ schema?.label | translate }}</mat-label>

      <bg2-intl-phone-input
        [enableSearch]="true"
        [formControl]="control"
        [enablePlaceholder]="true"
        [preferredCountries]="['fr']"
      ></bg2-intl-phone-input>

      <mat-error *ngIf="control?.errors?.validatePhoneNumber">Invalid Number</mat-error>
    </mat-form-field>
  </div>
</div>
