import { ChangeDetectionStrategy, Component } from '@angular/core';

import { marker as i18n } from '@biesbjerg/ngx-translate-extract-marker';

import { concat, isArray, isEmpty, isNil, some, uniqBy } from 'lodash-es';

import { replay } from '@bg2app/tools/rxjs';
import { catchError, combineLatest, map, Observable, of, switchMap, throwError } from 'rxjs';

import { AppStateService } from 'app/core/app-state.service';
import { ZohoAuthService } from 'app/core/services/zoho/zoho-auth.service';
import { ZohoDeskApiService } from 'app/core/services/zoho/zoho-desk-api.service';

import { Exploitation } from 'app/models';
import {
  build_zoho_search_criteria,
  get_i18n_for_zoho_error,
  ZohoCRMAccount,
  ZohoCRMContact,
  ZohoCRMModuleName,
  ZohoCRMNote,
  ZohoCRMTask,
  ZohoError,
} from 'app/models/zoho';
import { IZohoDeskTicket, ZohoDeskAccount, ZohoDeskContact, ZohoDeskTicket } from 'app/models/zoho/desk';
import { UsersApiService } from 'app/core';
import { ZohoBooksContact } from 'app/models/zoho/books';
import { ZohoCRMApiService } from 'app/core/services/zoho/zoho-crm-api.service';
import { ZohoCRMDeal } from 'app/models/zoho/crm/modules';
import { ZohoCRMDealStage } from 'app/models/zoho/crm/modules/deals/enumerators';
import { ZohoBooksApiService } from 'app/core/services/zoho/zoho-books-api.service';

@Component({
  selector: 'bg2-superadmin-shortcut',
  templateUrl: './superadmin-shortcut.component.html',
  styleUrls: ['./superadmin-shortcut.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SuperadminShortcutComponent {
  // #region -> (component basics)

  constructor(
    private readonly _usersApiService: UsersApiService,
    private readonly _zohoAuthService: ZohoAuthService,
    private readonly _appStateService: AppStateService,
    private readonly _zohoCRMApiService: ZohoCRMApiService,
    private readonly _zohoDeskApiService: ZohoDeskApiService,
    private readonly _zohoBooksApiService: ZohoBooksApiService
  ) {}

  // #endregion

  // #region -> (zoho auth)

  /** */
  public is_zoho_authenticated$$ = this._zohoAuthService.is_authenticated$$;

  /** */
  public login(): void {
    this._zohoAuthService.login();
  }

  /** */
  public logout(): void {
    this._zohoAuthService.logout();
  }

  // #endregion

  /** */
  public has_selected_exploitations$$ = this._appStateService.selected_exploitations$$.pipe(
    map(selected_exploitations => selected_exploitations?.length > 0)
  );

  // #region -> (zoho checks)

  /** */
  public zoho_checks_menu_error_key$$ = this.is_zoho_authenticated$$.pipe(
    switchMap(is_zoho_authenticated =>
      of(is_zoho_authenticated).pipe(
        switchMap(_is_zoho_authenticated => {
          if (_is_zoho_authenticated === false) {
            return throwError(() => new Error(i18n<string>('VIEWS.AUTH.LOGIN.Not connected')));
          }

          return this._appStateService.selected_exploitations$$;
        }),
        catchError((error: unknown) => of(<Error>error))
      )
    ),
    map(selected_exploitations => {
      const response = { value: <Exploitation[]>null, error: <Error>null };

      if (selected_exploitations instanceof Error) {
        response.error = selected_exploitations;
        return response;
      }

      if (isEmpty(selected_exploitations ?? [])) {
        response.error = new Error(i18n<string>('ENTITY.EXPLOITATION.ERRORS.No exploitation selected'));
      }

      response.value = selected_exploitations;
      return response;
    }),
    replay()
  );

  // #endregion

  // #region -> (base observables)

  /** */
  private _unique_selected_exploitation$$ = this.is_zoho_authenticated$$.pipe(
    switchMap(is_zoho_authenticated =>
      of(is_zoho_authenticated).pipe(
        switchMap(_is_zoho_authenticated => {
          if (_is_zoho_authenticated === false) {
            return throwError(() => new Error(i18n<string>('VIEWS.AUTH.LOGIN.Not connected')));
          }

          return this._appStateService.selected_exploitations$$;
        }),
        catchError((error: unknown) => of(<Error>error))
      )
    ),
    switchMap(selected_exploitations => {
      if (selected_exploitations instanceof Error) {
        return of(selected_exploitations);
      }

      return of(selected_exploitations).pipe(
        switchMap(_selected_exploitations => {
          if (isEmpty(_selected_exploitations ?? [])) {
            return throwError(() => new Error(i18n<string>('ENTITY.EXPLOITATION.ERRORS.No exploitation selected')));
          }

          if ((_selected_exploitations ?? [])?.length > 1) {
            return throwError(() => new Error(i18n<string>('ENTITY.EXPLOITATION.ERRORS.Too many exploitations selected')));
          }

          return of(_selected_exploitations[0]);
        }),
        catchError((error: unknown) => of(<Error>error))
      );
    }),
    replay()
  );

  /** */
  private _unique_selected_exploitation_user$$ = this._unique_selected_exploitation$$.pipe(
    switchMap(exploitation_or_error => {
      if (exploitation_or_error instanceof Error) {
        return of(exploitation_or_error);
      }

      return exploitation_or_error?.user_id$$.pipe(switchMap(user_id => this._usersApiService.fetch_user$(user_id)));
    }),
    replay()
  );

  /** */
  private _unique_zoho_crm_account$$ = this._unique_selected_exploitation$$.pipe(
    switchMap(exploitation_or_error => {
      if (exploitation_or_error instanceof Error) {
        return of(exploitation_or_error);
      }

      const zoho_crm_account = exploitation_or_error?.zoho_crm_account$$;

      return zoho_crm_account;
    }),
    replay()
  );

  /** */
  private _unique_zoho_crm_contact$$ = this._unique_selected_exploitation_user$$.pipe(
    switchMap(user_or_error => {
      if (user_or_error instanceof Error) {
        return of(user_or_error);
      }

      return user_or_error?.zoho_crm_contact$$;
    }),
    replay()
  );

  /** */
  private _unique_zoho_books_client_from_acccount$$ = this._unique_selected_exploitation$$.pipe(
    switchMap(exploitation_or_error => {
      if (exploitation_or_error instanceof Error) {
        return of(exploitation_or_error);
      }

      return exploitation_or_error?.zoho_books_contact$$;
    }),
    replay()
  );

  /** */
  private _unique_zoho_books_client_from_contact$$ = this._unique_selected_exploitation$$.pipe(
    switchMap(exploitation_or_error => {
      if (exploitation_or_error instanceof Error) {
        return of(exploitation_or_error);
      }

      return exploitation_or_error?.zoho_books_contact$$;
    }),
    replay()
  );

  /** */
  private _unique_zoho_books_client$$ = combineLatest({
    client_from_account: this._unique_zoho_books_client_from_acccount$$,
    client_from_contact: this._unique_zoho_books_client_from_contact$$,
  }).pipe(
    map(({ client_from_account, client_from_contact }) => {
      const is_books_client_from_account_error = client_from_account instanceof Error;
      const is_books_client_from_contact_error = client_from_contact instanceof Error;

      if (is_books_client_from_account_error && is_books_client_from_contact_error) {
        return client_from_account;
      }

      if (!is_books_client_from_account_error && !is_books_client_from_contact_error) {
        const is_same_books_client = client_from_contact.id === client_from_account.id;

        if (is_same_books_client) {
          return client_from_contact;
        }

        // Not same books contact
        return client_from_contact;
      }

      return client_from_account ?? client_from_account ?? new Error(get_i18n_for_zoho_error(ZohoError.UNDEFINED_ZOHO_BOOKS_OBJECT));
    }),
    replay()
  );

  // #endregion

  // #region -> (links to CRM)

  /** */
  private link_to_crm_account$$ = this._unique_zoho_crm_account$$.pipe(
    map(crm_account => {
      const response = { crm_account: <ZohoCRMAccount>null, error: <Error>null };

      if (crm_account instanceof Error) {
        response.error = crm_account;
        return response;
      }

      response.crm_account = crm_account;
      return response;
    }),
    replay()
  );

  /** */
  private link_to_crm_contact$$ = this._unique_zoho_crm_contact$$.pipe(
    map(crm_contact_or_error => {
      const response = { crm_contact: <ZohoCRMContact>null, error: <Error>null };

      if (crm_contact_or_error instanceof Error) {
        response.error = crm_contact_or_error;
        return response;
      }

      response.crm_contact = crm_contact_or_error;
      return response;
    }),
    replay()
  );

  /** */
  public links_to_crm$$ = combineLatest({ crm_account: this.link_to_crm_account$$, crm_contact: this.link_to_crm_contact$$ }).pipe(
    map(({ crm_account, crm_contact }) => {
      const response = {
        errors: <Error[]>null,
        links: {
          account: crm_account,
          contact: crm_contact,
        },
      };

      const is_error_crm_account = !isNil(crm_account?.error);
      const is_error_crm_contact = !isNil(crm_contact?.error);

      if (is_error_crm_account && is_error_crm_contact) {
        response.errors = [crm_account?.error, crm_contact?.error];
        return response;
      }

      return response;
    }),
    replay()
  );

  // #endregion

  // #region -> (links to desk)

  /** */
  private link_to_desk_account$$ = this._unique_selected_exploitation$$.pipe(
    switchMap(exploitation_or_error => {
      if (exploitation_or_error instanceof Error) {
        return of(exploitation_or_error);
      }

      return exploitation_or_error?.zoho_desk_account$$;
    }),
    map(account_or_error => {
      const response = { desk_account: <ZohoDeskAccount>null, error: <Error>null };

      if (account_or_error instanceof Error) {
        response.error = account_or_error;
        return response;
      }

      response.desk_account = account_or_error;
      return response;
    }),
    replay()
  );

  /** */
  private link_to_desk_contact$$ = this._unique_selected_exploitation_user$$.pipe(
    switchMap(user_or_error => {
      if (user_or_error instanceof Error) {
        return of(user_or_error);
      }

      return user_or_error?.zoho_desk_contact$$;
    }),
    map(contact_or_error => {
      const response = { desk_contact: <ZohoDeskContact>null, error: <Error>null };

      if (contact_or_error instanceof Error) {
        response.error = contact_or_error;
        return response;
      }

      response.desk_contact = contact_or_error;
      return response;
    }),
    replay()
  );

  /** */
  public links_to_desk$$ = combineLatest({ desk_account: this.link_to_desk_account$$, desk_contact: this.link_to_desk_contact$$ }).pipe(
    map(({ desk_account, desk_contact }) => {
      const response = {
        errors: <Error[]>null,
        links: {
          account: desk_account,
          contact: desk_contact,
        },
      };

      const is_error_crm_account = !isNil(desk_account?.error);
      const is_error_crm_contact = !isNil(desk_contact?.error);

      if (is_error_crm_account && is_error_crm_contact) {
        response.errors = [desk_account?.error, desk_contact?.error];
        return response;
      }

      return response;
    }),
    replay()
  );

  // #endregion

  // #region -> (basic links)

  /** */
  private link_to_books_contact_from_exploitation$$ = this._unique_selected_exploitation$$.pipe(
    switchMap(exploitation_or_error => {
      if (exploitation_or_error instanceof Error) {
        return of(exploitation_or_error);
      }

      return exploitation_or_error?.zoho_books_contact$$;
    }),
    map(books_contact => {
      const response = { books_contact: <ZohoBooksContact>null, error: <Error>null };

      if (books_contact instanceof Error) {
        response.error = books_contact;
        return response;
      }

      response.books_contact = books_contact;
      return response;
    }),
    replay()
  );

  /** */
  private link_to_books_contact_from_user$$ = this._unique_selected_exploitation_user$$.pipe(
    switchMap(user_or_error => {
      if (user_or_error instanceof Error) {
        return of(user_or_error);
      }

      return user_or_error?.zoho_books_contact$$;
    }),
    map(books_contact => {
      const response = { books_contact: <ZohoBooksContact>null, error: <Error>null };

      if (books_contact instanceof Error) {
        response.error = books_contact;
        return response;
      }

      response.books_contact = books_contact;
      return response;
    }),
    replay()
  );

  /** */
  public links_to_books$$ = combineLatest({
    client_of_crm_contact: this.link_to_books_contact_from_user$$,
    client_of_crm_account: this.link_to_books_contact_from_exploitation$$,
  }).pipe(
    map(({ client_of_crm_contact, client_of_crm_account }) => {
      const response = {
        errors: <Error[]>null,
        links: {
          client_of_crm_contact,
          client_of_crm_account,
        },
        unique: client_of_crm_account ?? client_of_crm_contact,
      };

      const is_error_crm_account = !isNil(client_of_crm_account?.error);
      const is_error_crm_contact = !isNil(client_of_crm_contact?.error);

      if (is_error_crm_account && is_error_crm_contact) {
        response.errors = [client_of_crm_account?.error, client_of_crm_contact?.error];
        return response;
      }

      return response;
    }),
    replay()
  );

  /** */
  public is_same_client$$ = combineLatest({
    client_of_crm_contact: this.link_to_books_contact_from_user$$,
    client_of_crm_account: this.link_to_books_contact_from_exploitation$$,
  }).pipe(
    map(({ client_of_crm_contact, client_of_crm_account }) => {
      if (isNil(client_of_crm_contact?.error) && isNil(client_of_crm_account?.error)) {
        if (!isNil(client_of_crm_account?.books_contact) && !isNil(client_of_crm_contact?.books_contact)) {
          return client_of_crm_account?.books_contact?.id === client_of_crm_contact?.books_contact?.id;
        }
      }

      return false;
    }),
    replay()
  );

  // #endregion

  // #region -> (selected exploitation notes)

  /** */
  private notes_from_account$$ = this._unique_zoho_crm_account$$.pipe(
    switchMap(crm_account_or_error => {
      if (crm_account_or_error instanceof Error) {
        return of(crm_account_or_error);
      }

      return this._zohoCRMApiService.fetch_notes_of_module$(ZohoCRMModuleName.Accounts, crm_account_or_error.id);
    }),
    map(crm_note_or_error => {
      const response = { notes: <ZohoCRMNote[]>null, error: <Error>null };

      if (crm_note_or_error instanceof Error) {
        response.error = crm_note_or_error;
        return response;
      }

      response.notes = crm_note_or_error;
      return response;
    }),
    replay()
  );

  /** */
  private notes_from_contact$$ = this._unique_zoho_crm_contact$$.pipe(
    switchMap(crm_contact_or_error => {
      if (crm_contact_or_error instanceof Error) {
        return of(crm_contact_or_error);
      }

      return this._zohoCRMApiService.fetch_notes_of_module$(ZohoCRMModuleName.Contacts, crm_contact_or_error.id);
    }),
    map(crm_note_or_error => {
      const response = { notes: <ZohoCRMNote[]>null, error: <Error>null };

      if (crm_note_or_error instanceof Error) {
        response.error = crm_note_or_error;
        return response;
      }

      response.notes = crm_note_or_error;
      return response;
    }),
    replay()
  );

  /** */
  public notes$$ = combineLatest({ notes_from_account: this.notes_from_account$$, notes_from_contact: this.notes_from_contact$$ }).pipe(
    map(({ notes_from_account, notes_from_contact }) => {
      const notes_from_account_error = notes_from_account?.error;
      const notes_from_contact_error = notes_from_contact?.error;

      if (!isNil(notes_from_account_error) && !isNil(notes_from_contact_error)) {
        return notes_from_account_error;
      }

      let notes: ZohoCRMNote[] = concat(...(notes_from_account?.notes ?? []), ...(notes_from_contact?.notes ?? []));
      notes = uniqBy(notes, note => note.id);

      return notes;
    }),
    map(crm_note_or_error => {
      const response = { notes: <ZohoCRMNote[]>null, error: <Error>null };

      if (crm_note_or_error instanceof Error) {
        response.error = crm_note_or_error;
        return response;
      }

      response.notes = crm_note_or_error;
      return response;
    }),
    replay()
  );

  /** */
  public notes_total$$ = this.notes$$.pipe(
    map(response => {
      if (response instanceof Error || isNil(response)) {
        return null;
      }

      return response?.notes?.length;
    }),
    replay()
  );

  // #endregion

  // #region -> (selected exploitation tasks)

  /** */
  public tasks$$ = this._unique_zoho_crm_contact$$.pipe(
    switchMap(crm_contact_or_error => {
      if (crm_contact_or_error instanceof Error) {
        return of(crm_contact_or_error);
      }

      const criteria = build_zoho_search_criteria(
        [
          { prop: 'Who_Id', condition: 'equals', replace_value: 'ACCOUNT_ID' },
          'and',
          { prop: 'Status', condition: 'not_equal', replace_value: 'STATUS' },
        ],
        {
          ACCOUNT_ID: crm_contact_or_error.id,
          STATUS: 'Terminé',
        }
      );

      return this._zohoCRMApiService.search_in_records(ZohoCRMModuleName.Tasks, criteria);
    }),
    map(tasks_or_error => {
      const response = { tasks: <ZohoCRMTask[]>null, error: <Error>null };

      if (tasks_or_error instanceof Error) {
        response.error = tasks_or_error;
        return response;
      }

      response.tasks = tasks_or_error?.data;
      return response;
    }),
    replay()
  );

  /** */
  public tasks_total$$ = this.tasks$$.pipe(
    map(tasks => {
      if (tasks instanceof Error || isNil(tasks)) {
        return null;
      }

      return tasks?.tasks?.length;
    }),
    replay()
  );

  // #endregion

  // #region -> (selected exploitation deals)

  /** */
  private _deals_from_crm_account$$ = this._unique_zoho_crm_account$$.pipe(
    switchMap(crm_account_or_error => {
      if (crm_account_or_error instanceof Error) {
        return of(crm_account_or_error);
      }

      const criteria = build_zoho_search_criteria(
        [
          { prop: 'Account_Name', condition: 'equals', replace_value: 'ACCOUNT_ID' },
          'and',
          [
            { prop: 'Stage', condition: 'not_equal', replace_value: 'STAGE_WIN' },
            'and',
            { prop: 'Stage', condition: 'not_equal', replace_value: 'STAGE_LOST' },
          ],
        ],
        {
          ACCOUNT_ID: crm_account_or_error.id,
          STAGE_WIN: ZohoCRMDealStage.WIN_OFFER,
          STAGE_LOST: ZohoCRMDealStage.LOST_OFFER,
        }
      );

      return this._zohoCRMApiService.search_in_records(ZohoCRMModuleName.Deals, criteria);
    }),
    map(deals_or_error => {
      const response = { deals: <ZohoCRMDeal[]>null, error: <Error>null };

      if (deals_or_error instanceof Error) {
        response.error = deals_or_error;
        return response;
      }

      response.deals = deals_or_error?.data;
      return response;
    }),
    replay()
  );

  /** */
  private _deals_from_crm_contact$$ = this._unique_zoho_crm_contact$$.pipe(
    switchMap(crm_contact_or_error => {
      if (crm_contact_or_error instanceof Error) {
        return of(crm_contact_or_error);
      }

      const criteria = build_zoho_search_criteria(
        [
          { prop: 'Contact_Name', condition: 'equals', replace_value: 'CONTACT_ID' },
          'and',
          [
            { prop: 'Stage', condition: 'not_equal', replace_value: 'STAGE_WIN' },
            'and',
            { prop: 'Stage', condition: 'not_equal', replace_value: 'STAGE_LOST' },
          ],
        ],
        {
          CONTACT_ID: crm_contact_or_error.id,
          STAGE_WIN: ZohoCRMDealStage.WIN_OFFER,
          STAGE_LOST: ZohoCRMDealStage.LOST_OFFER,
        }
      );

      return this._zohoCRMApiService.search_in_records(ZohoCRMModuleName.Deals, criteria);
    }),
    map(deals_or_error => {
      const response = { deals: <ZohoCRMDeal[]>null, error: <Error>null };

      if (deals_or_error instanceof Error) {
        response.error = deals_or_error;
        return response;
      }

      response.deals = deals_or_error?.data;
      return response;
    }),
    replay()
  );

  /** */
  public deals$$ = combineLatest({
    deals_from_contact: this._deals_from_crm_contact$$,
    deals_from_account: this._deals_from_crm_account$$,
  }).pipe(
    map(({ deals_from_contact, deals_from_account }) => {
      const is_contact_error = !isNil(deals_from_contact?.error);
      const is_account_error = !isNil(deals_from_account?.error);

      const response = { deals: <ZohoCRMDeal[]>null, error: <Error>null };

      if (is_account_error && is_contact_error) {
        response.error = deals_from_account.error;
        return response;
      }

      const all_deals: ZohoCRMDeal[] = [];

      if (!is_account_error) {
        all_deals.push(...deals_from_account?.deals);
      }

      if (!is_contact_error) {
        all_deals.push(...deals_from_contact?.deals);
      }

      response.deals = uniqBy(all_deals, deal => deal.id);
      return response;
    }),
    replay()
  );

  /** */
  public deals_total$$ = this.deals$$.pipe(
    map(deals_response => {
      if (deals_response instanceof Error || isNil(deals_response)) {
        return null;
      }

      return deals_response?.deals?.length;
    }),
    replay()
  );

  // #endregion

  // #region -> (selected exploitation invoices)

  /** */
  public invoices$$ = this._unique_zoho_books_client$$.pipe(
    switchMap(books_customer_or_error => {
      if (books_customer_or_error instanceof Error) {
        return of(books_customer_or_error);
      }

      return this._zohoBooksApiService.fetch_unpaid_invoices$(books_customer_or_error?.id);
    }),
    map(invoices_or_error => {
      const response = { invoices: <any[]>null, error: <Error>null };

      if (invoices_or_error instanceof Error) {
        response.error = invoices_or_error;
        return response;
      }

      response.invoices = invoices_or_error;
      return response;
    }),
    replay()
  );

  /** */
  public invoices_total$$ = this.invoices$$.pipe(
    map(response => {
      if (response instanceof Error || isNil(response)) {
        return null;
      }

      return response?.invoices?.length;
    }),
    replay()
  );

  // #endregion

  // #region -> (selected exploitation tickets)

  /** */
  public tickets$$ = this._unique_selected_exploitation$$.pipe(
    switchMap(exploitation_or_error => {
      if (exploitation_or_error instanceof Error) {
        return of(exploitation_or_error);
      }

      return exploitation_or_error?.zoho_desk_account$$;
    }),
    switchMap(zoho_desk_account => {
      if (zoho_desk_account instanceof Error) {
        return of(zoho_desk_account);
      }

      return of(zoho_desk_account).pipe(
        switchMap(_zoho_desk_account => {
          if (isNil(_zoho_desk_account)) {
            return throwError(() => new Error(get_i18n_for_zoho_error(ZohoError.UNDEFINED_ZOHO_DESK_ACCOUNT)));
          }

          return this._zohoDeskApiService
            .search_tickets({ accountId: zoho_desk_account.id }, { statusType: ['Open', 'On Hold'] })
            .pipe(map(result => result?.data ?? []));
        }),
        catchError((error: unknown) => of(<Error>error))
      );
    }),
    map(tickets => {
      const response = { value: <ZohoDeskTicket[]>null, error: <Error>null };

      if (tickets instanceof Error) {
        response.error = tickets;
        return response;
      }

      response.value = tickets;
      return response;
    }),
    replay()
  );

  /** */
  public tickets_total$$ = this.tickets$$.pipe(
    map(response => {
      if (response instanceof Error || isNil(response)) {
        return null;
      }

      return response?.value?.length;
    }),
    replay()
  );

  // #endregion

  /** */
  public total_of_objects$$ = combineLatest({
    tickets: this.tickets_total$$,
    notes: this.notes_total$$,
    tasks: this.tasks_total$$,
    deals: this.deals_total$$,
    invoices: this.invoices_total$$,
  }).pipe(
    map(({ tickets, notes, tasks, deals, invoices }) => (tickets ?? 0) + (notes ?? 0) + (tasks ?? 0) + (deals ?? 0) + (invoices ?? 0)),
    replay()
  );

  /** */
  public isError(value: any | Error): Error {
    if (value instanceof Error) {
      return value;
    }

    return null;
  }

  /** */
  public isErrors(values: any | Error[]): Error[] {
    if (some(values, value => value instanceof Error)) {
      return values;
    }

    return [];
  }

  /** */
  public check_type(values: any, type: 'error'): Error;
  public check_type(values: any, type: 'ZohoCRMAccount'): ZohoCRMAccount;
  public check_type(values: any | Error[], type: 'crm_links'): { account: Error | ZohoCRMAccount; contact: Error | ZohoCRMContact };
  public check_type(values: any | Error[], type: 'ZohoCRMAccount' | 'crm_links' | 'error'): any {
    if (type === 'ZohoCRMAccount' && values instanceof ZohoCRMAccount) {
      return values;
    }

    if (type === 'error' && values instanceof Error) {
      return values;
    }

    if (type === 'crm_links' && some(values, value => !(value instanceof Error))) {
      return values;
    }

    return null;
  }
}
