import * as d3 from 'd3';

import { isNil, merge } from 'lodash-es';
import { getTimezoneOffset, utcToZonedTime } from 'date-fns-tz';
import { eachDayOfInterval, endOfDay, isSameDay, isValid } from 'date-fns/esm';

import { getTimes } from 'app/misc/tools/misc/suncalc';

import { GridDayCycleConfig } from '../../interfaces';

/** */
export class D3GridDayCycle {
  // #region -> (class basics)

  private _config: GridDayCycleConfig = {
    geoposition: {
      latitude: 43.54589,
      longitude: 1.50392,
      timezone: 'Europe/Paris',
    },
    only_curent_day: false,
    ignore_start_end_of_day: false,
  };

  /** */
  constructor(config?: GridDayCycleConfig) {
    this._config = merge({}, this._config, config ?? {});
  }

  /** */
  private get timezone_offset() {
    return getTimezoneOffset(this._config.geoposition.timezone);
  }

  // #endregion

  // #region -> (flags)

  /** */
  private _has_been_created = false;

  // #endregion

  // #region -> (objects)

  /** */
  private _container: d3.Selection<SVGGElement, unknown, HTMLElement, unknown> = null;

  // #endregion

  /** */
  public create_container(container_ref: d3.Selection<SVGGElement, unknown, HTMLElement, unknown>): void {
    if (this._has_been_created) {
      return;
    }

    this._container = container_ref.append('g').attr('class', 'd3-grid-day-cycle').attr('pointer-events', 'none');
    this._has_been_created = true;
  }

  /** */
  public update(
    builder_unique_id: string,
    date_interval: Date[],
    time_scale: d3.ScaleTime<any, any, unknown>,
    height: number,
    max_days: number = null
  ): void {
    this.clear();

    const [start_date, end_date] = date_interval;

    if (!isValid(start_date) || !isValid(end_date)) {
      return;
    }

    eachDayOfInterval({ start: start_date, end: end_date }).forEach((day, index) => {
      if (!isNil(max_days) && index >= max_days) {
        return;
      }

      const compund_day = day.getTime() + day.getTimezoneOffset() * -1 * 60000;
      const is_same_day = isSameDay(compund_day, utcToZonedTime(new Date(), this._config.geoposition.timezone));

      if (this._config.only_curent_day && !is_same_day) {
        return;
      }

      if (this._config.ignore_start_end_of_day) {
        return;
      }

      let { sunrise, sunset } = getTimes(
        this.timezone_offset < 0 ? endOfDay(new Date(compund_day)) : new Date(compund_day),
        this._config?.geoposition?.latitude,
        this._config?.geoposition?.longitude,
        (<any>this._config.geoposition).elevation ?? 0
      );

      const fill_url = is_same_day ? `#${builder_unique_id}current-day-pattern` : `#${builder_unique_id}day_night_transition_gradient`;

      this._container
        .append('rect')
        .attr('x', time_scale(sunrise))
        .attr('y', -height)
        .attr('fill', `url('${fill_url}')`)
        .attr('height', height)
        .attr('width', time_scale(sunset) - time_scale(sunrise));
    });
  }

  /** */
  private clear(): void {
    this._container.selectAll('rect').remove();
  }

  /** */
  public destroy(): void {
    this._container?.remove();
  }
}
