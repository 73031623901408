import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MatSnackBarRef, MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';

import { LocalStorageService } from 'app/core/services/storage/local-storage.service';

import { ZohoAuthService } from 'app/core/services/zoho/zoho-auth.service';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'bg2-zoho-auth-snackbar',
  templateUrl: 'zoho-auth-snackbar.component.html',
  styleUrls: ['zoho-auth-snackbar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ZohoAuthSnackbarComponent {
  // #region -> (component basics)

  constructor(
    @Inject(MAT_SNACK_BAR_DATA) public data: {},
    private snackBarRef: MatSnackBarRef<ZohoAuthSnackbarComponent>,
    private readonly _zohoAuthService: ZohoAuthService,
    private readonly _localStorage: LocalStorageService
  ) {}

  // #endregion

  // #region -> (reminder management)

  /** */
  private _do_not_remind_me$$ = new BehaviorSubject(false);

  /** */
  public do_not_remind_me$$ = this._do_not_remind_me$$.asObservable();

  /** */
  public set do_not_remind_me(do_not_remind_me: boolean) {
    this._do_not_remind_me$$.next(do_not_remind_me);
  }

  /** */
  public get do_not_remind_me(): boolean {
    return this._do_not_remind_me$$.getValue();
  }

  // #endregion

  public login(): void {
    this._zohoAuthService.login();
    this.snackBarRef.dismiss();

    this._localStorage.put('zoho_ignore_auth', this.do_not_remind_me.toString()).subscribe({
      next: () => {
        this.snackBarRef.dismiss();
      },
    });
  }

  public ignore(): void {
    this._localStorage.put('zoho_ignore_auth', this.do_not_remind_me.toString()).subscribe({
      next: () => {
        this.snackBarRef.dismiss();
      },
    });
  }
}
