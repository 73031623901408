<div class="bg2-apiary-charts-footer-component" *ngLet="config$$ | async; let config">
  <div class="chart-legends">
    <div class="legend legend-day-night-cycle">
      <span class="legend__coloration"></span>

      <span class="legend__text">
        {{ 'VIEWS.APIARY.SHARED.WIDGETS.APIARY_CHARTS.APIARY_CHART_FOOTER.Day / night cycle' | translate }}
      </span>
    </div>

    <div
      *ngIf="config?.show_total_WG_sensors_changed && (date_range_manager?.days_to_display$$ | async) <= 7"
      class="legend legend-total-sensors-changed"
    >
      <span class="legend__coloration">
        <span class="mdi mdi-exclamation-thick"></span>
      </span>

      <span class="legend__text">
        {{ 'VIEWS.APIARY.SHARED.WIDGETS.APIARY_CHARTS.APIARY_CHART_FOOTER.WG sensors changed' | translate }}
      </span>
    </div>
  </div>

  <div class="links-container">
    <bg2-button
      *ngIf="config.show_more_data_btn && !env?.config?.disable_navigation"
      call-to-action
      icon="mdi-chart-line"
      type="navigation"
      [routerLink]="['', { outlets: { primary: 'data/location', modal: ['raz'] } }]"
      [queryParams]="{
        eids: [{ id: location_id } | jsonInline],
        start: (date_range_manager.start_date$$ | async)?.toJSDate()?.toISOString(),
        end: (date_range_manager.end_date$$ | async)?.toJSDate()?.toISOString()
      }"
      [options]="{ is_thin: true, is_label_hidden_on_small: false }"
      [message]="'VIEWS.APIARY.Show more data' | translate"
    ></bg2-button>
  </div>

  <div class="navigation-container">
    <span class="navigate-to-previous" (click)="date_range_manager.move_to.previous.day()">
      <span class="mdi mdi-arrow-left"></span>
      <span>{{ 'ALL.COMMON.Previous' | translate }}</span>
    </span>

    <div class="days-to-show">
      <div class="days-to-show__range-select">
        <ng-container *ngIf="is_compact">
          <bg2-favorite
            *ngIf="(date_range_manager.range_name$$ | async) === 'weekly'"
            [is_removable]="false"
            [is_favorite]="is_weekly_favorite$$ | async"
            [labels]="{
              add: 'WIDGETS.WIDGETS_REUSABLES.UI_COMPONENTS.FAVORITE.CUSTOM.Make it my preffered view' | translate,
              cannot_add: 'WIDGETS.WIDGETS_REUSABLES.UI_COMPONENTS.FAVORITE.CUSTOM.Already the preffered view' | translate,
              cannot_remove: 'WIDGETS.WIDGETS_REUSABLES.UI_COMPONENTS.FAVORITE.CUSTOM.Already the preffered view' | translate
            }"
            (favorite_changed)="save_date_range('weekly')"
          ></bg2-favorite>
        </ng-container>

        <span [class.active]="(date_range_manager.range_name$$ | async) === 'weekly'" (click)="toggle_time_interval('weekly')">
          <span class="days-to-show__weekly__long">{{ 'ALL.DATE.RANGES.VALUE.LONG.[x] days' | translate : { x: 7 } }}</span>
          <span class="days-to-show__weekly__short">{{ 'ALL.DATE.RANGES.VALUE.SHORT.[x] days' | translate : { x: 7 } }}</span>
        </span>

        <mat-slide-toggle
          [color]="'primary'"
          [checked]="(date_range_manager.range_name$$ | async) === 'monthly'"
          (change)="toggle_time_interval()"
        ></mat-slide-toggle>

        <span [class.active]="(date_range_manager.range_name$$ | async) === 'monthly'" (click)="toggle_time_interval('monthly')">
          <span class="days-to-show__monthly__long">{{ 'ALL.DATE.RANGES.VALUE.LONG.[x] days' | translate : { x: 30 } }}</span>
          <span class="days-to-show__monthly__short">{{ 'ALL.DATE.RANGES.VALUE.SHORT.[x] days' | translate : { x: 30 } }}</span>
        </span>

        <ng-container *ngIf="is_compact">
          <bg2-favorite
            *ngIf="(date_range_manager.range_name$$ | async) === 'monthly'"
            [is_removable]="false"
            [is_favorite]="is_monthly_favorite$$ | async"
            [labels]="{
              add: 'WIDGETS.WIDGETS_REUSABLES.UI_COMPONENTS.FAVORITE.CUSTOM.Make it my preffered view' | translate,
              cannot_add: 'WIDGETS.WIDGETS_REUSABLES.UI_COMPONENTS.FAVORITE.CUSTOM.Already the preffered view' | translate,
              cannot_remove: 'WIDGETS.WIDGETS_REUSABLES.UI_COMPONENTS.FAVORITE.CUSTOM.Already the preffered view' | translate
            }"
            (favorite_changed)="save_date_range('monthly')"
          ></bg2-favorite>
        </ng-container>
      </div>

      <div *ngIf="is_compact && false" class="days-to-show__apply-to-all">
        <span class="mdi mdi-24px mdi-multicast"></span>
        <span class="simili-link">apply to all</span>
      </div>
    </div>
    <span
      class="navigate-to-next"
      [class.disabled]="is_same_day$$(date_range_manager.end_date$$ | async) | async"
      (click)="date_range_manager.move_to.next.day()"
    >
      <span>{{ 'ALL.COMMON.Next' | translate }}</span>
      <span class="mdi mdi-arrow-right"></span>
    </span>
  </div>
</div>
