import { Component, Input, ChangeDetectionStrategy } from '@angular/core';

import { AppStateService } from '../../../../../core/app-state.service';

import { DeviceAlarm } from 'app/models/devices/DRDevice';

@Component({
  selector: 'alarm-mvnt-row',
  templateUrl: './alarm-mvnt-row.component.html',
  styleUrls: ['./alarm-mvnt-row.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AlarmMvntRowComponent {
  @Input()
  public alarm: DeviceAlarm = null;

  @Input()
  public show_authorize_button: boolean;

  constructor(public appState: AppStateService) {}
}
