import { AfterViewInit, ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { assign, isEqual, isNil } from 'lodash-es';

import { BehaviorSubject, concat, Observable, of, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map, switchMap, tap } from 'rxjs';

import { ArrayWidget, FormProperty } from 'ngx-schema-form';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';

import { marker as i18n } from '@biesbjerg/ngx-translate-extract-marker';
import { Beeguard2Api } from 'app/core';

import { EventDateAndId, robustSearchProperty, trackEventDateAndId } from '../eventforms.helpers';
import { trackValue, TrackOption } from '../eventforms.helpers';
import { distinctUntilRealChanged, replay } from '@bg2app/tools/rxjs';

export interface ArrayOptions {
  remove_btn_inline: boolean;
  show_add: boolean;
  show_remove: boolean;
}

@AutoUnsubscribe()
@Component({
  selector: 'bg2-ef-array-widget',
  templateUrl: './array.widget.html',
  styleUrls: ['./array.widget.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class EfArrayWidget extends ArrayWidget implements OnInit, OnDestroy, AfterViewInit {
  // #region -> (component basics)

  protected default_value_sub: Subscription = null;

  public options: ArrayOptions = {
    remove_btn_inline: false,
    show_add: true,
    show_remove: true,
  };

  constructor(protected bg2Api: Beeguard2Api) {
    super();
  }

  ngOnInit(): void {
    this.options = assign(this.options, this.schema.config || {});
    this.options = assign(this.options, this.schema.options);
  }

  ngAfterViewInit(): void {
    super.ngAfterViewInit();

    // We try to load default value if initial value isNil
    this.default_to = setTimeout(() => {
      if (isNil(this.value) || this.value.length === 0) {
        this.computeDefault();
      } else {
        this.is_default_value = false;
      }
    }, 100);
  }

  ngOnDestroy(): void {
    this.default_value_sub?.unsubscribe();

    if (this.default_to) {
      clearTimeout(this.default_to);
    }
  }

  // #endregion

  // #region -> (value management)

  /** */
  public set value(value: any) {
    this.formProperty.setValue(value, false);
  }

  /** */
  public get value(): any {
    return this.formProperty.value;
  }

  // #endregion

  // #region -> (default value management)

  /**
   * Previous value for default "reset"
   */
  private previous_default_value: any;

  /**
   * set default timeout ref
   */
  protected default_to: NodeJS.Timeout = null;

  /** */
  private _is_default_value$$ = new BehaviorSubject<boolean>(true);

  /** */
  public is_default_value$$ = this._is_default_value$$.asObservable().pipe(distinctUntilChanged());

  /** */
  public set is_default_value(val) {
    this._is_default_value$$.next(val);
  }

  /** */
  public get is_default_value(): boolean {
    return this._is_default_value$$.getValue();
  }

  /** */
  protected computeDefault(): void {
    if (isNil(this.schema._default)) {
      return;
    }

    const default_conf = this.schema._default;
    this.default_value_sub?.unsubscribe();

    this.default_value_sub = this.trackDefault(this.bg2Api, default_conf)
      ?.pipe(tap(value => console.log(this.formProperty.path, 'default val ?', value)))
      .subscribe(value => {
        if (!this.previous_default_value || isEqual(this.value, this.previous_default_value)) {
          // current value is the previous default, so we update the value
          this.value = value;
          this.previous_default_value = value;
        }
      });
  }

  /**
   * Get an observable on default value
   *
   * @param default_conf default configuration schema (`this.schema._default`)
   * @param dpath path to date property
   */
  protected trackDefault(bg2Api: Beeguard2Api, default_conf: TrackOption, dpath?: string): Observable<any> | null {
    dpath = dpath ? dpath : '/date';
    const event_date_and_id = this.trackEventDateAndId(dpath);
    return trackValue(this.formProperty, bg2Api, default_conf, event_date_and_id);
  }

  /**
   * Observable over current event date and id
   */
  protected trackEventDateAndId(dpath: string): Observable<EventDateAndId> {
    const date_property = this.robustSearchProperty(dpath);
    return trackEventDateAndId(date_property);
  }

  // #endregion

  // #region -> (labels)

  public get label_remove(): string {
    return this.schema.description_remove || i18n('WIDGETS.EVENT_FORM.ARRAY.Remove');
  }

  public get label_add_first(): string {
    return this.schema.description_add_first || this.schema.description_add || i18n('WIDGETS.EVENT_FORM.ARRAY.Add');
  }

  public get label_add(): string {
    return this.schema.description_add || i18n('WIDGETS.EVENT_FORM.ARRAY.Add');
  }

  // #endregion

  // #region -> (fields management)

  /** */
  public get is_empty() {
    return (this.formProperty?.properties ?? [])?.length === 0;
  }

  /** */
  public get can_add() {
    if (this.schema.hasOwnProperty('maxItems') && Array.isArray(this.formProperty.properties)) {
      if (this.formProperty.properties.length >= this.schema.maxItems) {
        return false;
      }
    }

    return true;
  }

  /** */
  public get can_remove() {
    if (this.schema.hasOwnProperty('minItems') && Array.isArray(this.formProperty.properties)) {
      if (this.formProperty.properties.length <= this.schema.minItems) {
        return false;
      }
    }

    return true;
  }

  public addItem(value: any = null) {
    this.formProperty.addItem(value);
    this.updateButtonDisabledState();

    // const new_item = this.formProperty.addItem(value);
    // this.newItemCreated(new_item);
  }

  // private forceRedraw() {
  //   // sub form elements do not always match with the correct properties after a delete
  //   this.redraw = true;
  //   setTimeout(() => {
  //     this.redraw = false;
  //   }, 100);
  // }

  removeItem(item: FormProperty) {
    // item.setValue(null, false);
    // super.removeItem(item);
    this.formProperty.removeItem(item);

    this.updateButtonDisabledState();
  }

  // #endregion

  protected robustSearchProperty(path: string): FormProperty {
    return robustSearchProperty(this.formProperty, path);
  }

  buttonDisabledAdd: boolean;
  buttonDisabledRemove: boolean;

  trackByIndex(index: number, item: any) {
    return index;
  }

  updateButtonDisabledState() {
    this.buttonDisabledAdd = this.isAddButtonDisabled();
    this.buttonDisabledRemove = this.isRemoveButtonDisabled();
  }
  isAddButtonDisabled() {
    if (this.schema.hasOwnProperty('maxItems') && Array.isArray(this.formProperty.properties)) {
      if (this.formProperty.properties.length >= this.schema.maxItems) {
        return true;
      }
    }

    return false;
  }

  isRemoveButtonDisabled() {
    if (this.schema.hasOwnProperty('minItems') && Array.isArray(this.formProperty.properties)) {
      if (this.formProperty.properties.length <= this.schema.minItems) {
        return true;
      }
    }

    return false;
  }
}
