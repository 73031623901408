import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { MatStepper } from '@angular/material/stepper';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

import { BehaviorSubject, catchError, combineLatest, filter, forkJoin, map, Observable, of, switchMap, take, tap, throwError } from 'rxjs';
import { allTrue, catchErrorInDialog, distinctUntilRealChanged, replay } from '@bg2app/tools/rxjs';

import { ISchema } from 'ngx-schema-form';

import { TranslateService } from '@ngx-translate/core';
import { marker as i18n } from '@biesbjerg/ngx-translate-extract-marker';
import { clone, cloneDeep, difference, every, flatten, isEmpty, isNil, mapValues, uniq } from 'lodash-es';

import { Beeguard2Api, DeviceApi } from 'app/core';
import { AppStateService } from 'app/core/app-state.service';
import { DeviceSupportIssue } from 'app/core/api-swagger/device';
import { ZohoDeskApiService } from 'app/core/services/zoho/zoho-desk-api.service';

import { ModalParams } from 'app/widgets/dialogs-modals/abstract-modal.component';
import { AbstractModalComponent, DialogsService } from 'app/widgets/dialogs-modals';
import { ErrorHelperData } from 'app/widgets/widgets-reusables/errors/error-helper/error-helper.component';

import { parseDate } from 'app/misc/tools';
import {
  Event,
  DRDevice,
  DeviceInterface,
  eventDeserialize,
  DeviceSupportType,
  SimpleSetterGetter,
  DeviceSupportNextUsage,
  __i18n_device_support_next_usage,
} from 'app/models';
import { ZohoDeskTicket, ZohoDeskTicketStatus, zoho_desk_ticket_status_oneof } from 'app/models/zoho/desk';
import { ZohoAuthService } from 'app/core/services/zoho/zoho-auth.service';
import { max } from 'date-fns/esm';

/** */
interface SelectDeviceToCloseRU {
  /** */
  name: string;

  /** */
  imei: number;

  /** */
  device: DRDevice;

  /** */
  disabled: boolean;

  /** */
  support: DeviceSupportIssue;
}

/** */
interface DeviceXSupportXDeskTicket {
  /** */
  device: DRDevice;

  /** */
  support: DeviceSupportIssue;

  /** */
  ticket: ZohoDeskTicket;
}

/** */
interface EndOfSupportRequest<ResultType> {
  /** */
  name: string;

  /** */
  label: string;

  /** */
  status: 'not_started' | 'running' | 'failed' | 'success';

  /** */
  request$$: Observable<ResultType>;
}

/** */
export interface DeviceSupportCloseModalParams extends ModalParams {
  /** */
  imeis: string;
}

@Component({
  selector: 'bg2-device-support-close-modal',
  templateUrl: './device-support-close-modal.component.html',
  styleUrls: ['./device-support-close-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DeviceSupportCloseModalComponent extends AbstractModalComponent<DeviceSupportCloseModalParams> implements OnInit, OnDestroy {
  // #region -> (component basics)

  constructor(
    private readonly _deviceApi: DeviceApi,
    private readonly _bg2Api: Beeguard2Api,
    private readonly _dialogs: DialogsService,
    private readonly _formBuilder: FormBuilder,
    private readonly _translate: TranslateService,
    private readonly _zohoDeskApi: ZohoDeskApiService,
    private readonly _zohoAuthService: ZohoAuthService,
    private readonly _appStateService: AppStateService
  ) {
    super();
  }

  ngOnInit(): void {
    this.configure_support_form.valueChanges.subscribe({
      next: (value: any) => (this.devices_next_usage_value.value = value),
    });

    this.selected_devices$$.subscribe({
      next: selected_devices => {
        const control_names = Object.keys(this.configure_support_form.controls);
        const selected_imeis = selected_devices.map(sd => sd.device.imei.toString());

        // Add form groups
        selected_imeis.forEach(imei => {
          if (control_names.includes(imei)) {
            return;
          }

          const group = this._formBuilder.group({
            comment: this._formBuilder.control(null, []),
            next_usage: this._formBuilder.control(DeviceSupportNextUsage.SEND_BACK_TO_CUSTOMER, [Validators.required]),
            replacement: this._formBuilder.group({
              keep_old_device: this._formBuilder.control(null),
              reinstall_in_hive_or_apiary: this._formBuilder.control(null),
              replace_device: this._formBuilder.control(null),
              new_device: this._formBuilder.group({
                imei: this._formBuilder.control(null),
                dtype: this._formBuilder.control(null),
              }),
            }),
          });

          this.configure_support_form.addControl(imei, group);
        });

        // Remove form groups
        difference(control_names, selected_imeis).forEach(imei => {
          this.configure_support_form.removeControl(imei);
          this.configure_support_form.updateValueAndValidity();
        });
      },
    });
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  /** */
  protected handle_event_before_unload(event: BeforeUnloadEvent): void {
    return null;
  }

  /** */
  public support_type$$: Observable<string> = of(DeviceSupportType.RU_NO_SMS);

  // #endregion

  // #region -> (zoho basics)

  /** */
  protected is_authenticated_to_zoho$$ = this._zohoAuthService.is_authenticated$$;

  /** */
  public not_authenticated_error = <ErrorHelperData>{
    name: '',
    message: '',
    description: [
      {
        type: 'image_svg',
        url: '/assets/picons/api-link-off.svg',
        styles: {
          width: '40px',
        },
      },
      {
        type: 'span',
        content: i18n<string>(
          'VIEWS.DEVICES.DIALOGS_AND_MODALS.MODALS.DEVICE_SUPPORT.DEVICE_SUPPORT_CLOSE_MODAL.ERROR.You are not connected to Zoho'
        ),
      },
      {
        type: 'button',
        action: 'action',
        icon: 'mdi-login',
        handler_name: 'login_to_zoho',
        message: i18n<string>('VIEWS.AUTH.LOGIN.Log in'),
      },
    ],
  };

  // #endregion

  // #region -> (loadings)

  /** */
  protected is_loading_device = new SimpleSetterGetter(true);

  /** */
  protected is_loading_selectable_device = new SimpleSetterGetter(true);

  /** */
  protected is_loading_ticket = new SimpleSetterGetter(true);

  // #endregion

  // #region -> (stepper management)

  /** */
  public previous_step(stepper: MatStepper): void {
    stepper.previous();
  }

  /** */
  public next_step(stepper: MatStepper): void {
    stepper.next();
  }

  // #endregion

  // #region -> (modal incoming devices)

  /** */
  private modal_device_imeis$$ = this.input_params$$.pipe(
    map(parameters => {
      const imeis_string = parameters?.imeis;
      const imeis = imeis_string.trim().split(',');

      if (isNil(imeis) || isEmpty(imeis)) {
        throw new Error('Missing or empty device IMEI !');
      }

      return imeis.map(imei => parseInt(imei, 10));
    }),
    replay()
  );

  /** */
  private modal_devices$$: Observable<DRDevice[]> = this.modal_device_imeis$$.pipe(
    tap(() => (this.is_loading_device.value = true)),
    switchMap(imeis => this._deviceApi.requestDevices(imeis).pipe(catchErrorInDialog(this._dialogs))),
    tap(device => {
      if (!isNil(device)) {
        this.is_loading_device.value = false;
        return;
      }

      this.is_loading_device.value = true;
    }),
    replay()
  );

  /** */
  private modal_devices_x_support_x_ticket$$ = this.is_authenticated_to_zoho$$.pipe(
    filter(Boolean),
    take(1),
    switchMap(() => this.modal_devices$$),
    switchMap(devices => {
      if (devices.length === 0) {
        return of<DeviceXSupportXDeskTicket[]>([]);
      }

      return this._fetch_supports_with_ticket_of_devices$(devices);
    }),
    replay()
  );

  // #endregion

  // #region -> (step 1 / check requirements and select devices)

  /** */
  public is_only_one_desk_ticket$$ = this.modal_devices_x_support_x_ticket$$.pipe(
    map(composed => {
      const ticket_ids = composed.filter(c => !isNil(c.ticket)).map(c => c?.ticket?.id);
      const unique_ticket_ids = uniq(ticket_ids);

      return unique_ticket_ids.length === 1;
    }),
    distinctUntilRealChanged(),
    replay()
  );

  /** */
  public have_at_least_one_opened_support$$ = this.modal_devices_x_support_x_ticket$$.pipe(
    map(composed => {
      const opened_supports = composed.filter(c => !isNil(c.support));

      return opened_supports?.length > 0;
    }),
    distinctUntilRealChanged(),
    replay()
  );

  /** */
  public selected_desk_ticket$$ = this.modal_devices_x_support_x_ticket$$.pipe(
    switchMap(composed => {
      const ticket_ids = composed.filter(c => !isNil(c.ticket)).map(c => c?.ticket?.id);
      const unique_ticket_ids = uniq(ticket_ids);

      if (unique_ticket_ids?.length !== 1 || isNil(unique_ticket_ids?.[0])) {
        this.is_loading_ticket.value = true;
        return of(null);
      }

      this.is_loading_ticket.value = false;
      return this._zohoDeskApi.fetch_module$('tickets', unique_ticket_ids[0]);
    }),
    replay()
  );

  /** */
  private missing_devices_of_ticket$$ = this.selected_desk_ticket$$.pipe(
    map(ticket => ticket?.cf_imeis?.trim() ?? null),
    map(related_device_imeis => {
      if (isNil(related_device_imeis) || isEmpty(related_device_imeis)) {
        return [];
      }

      const imeis = related_device_imeis.split(' ');
      return imeis.map(imei => parseInt(imei, 10));
    }),
    switchMap(related_device_imeis =>
      this.modal_devices$$.pipe(
        map(devices => devices.map(device => device.imei)),
        map(selected_device_imeis => difference(related_device_imeis, selected_device_imeis)),
        take(1)
      )
    ),
    switchMap(missing_imeis => this._deviceApi.requestDevices(missing_imeis)),
    replay()
  );

  /**
   * Observes all devices (selected + ticket).
   */
  private _all_devices$$ = combineLatest({
    initial_devices: this.modal_devices$$,
    missing_devices: this.missing_devices_of_ticket$$,
  }).pipe(
    map(({ initial_devices, missing_devices }) => [...(initial_devices ?? []), ...(missing_devices ?? [])]),
    switchMap(devices => this._fetch_supports_with_ticket_of_devices$(devices)),
    replay()
  );

  /** */
  public selectable_devices$$: Observable<SelectDeviceToCloseRU[]> = this._all_devices$$.pipe(
    map(devices => this._create_selectable_devices(devices)),
    tap(() => (this.is_loading_selectable_device.value = false)),
    replay()
  );

  /** */
  public set selected_imeis(selected_imeis: number[]) {
    this._selected_imeis$$.next(selected_imeis);
  }

  /** */
  private _selected_imeis$$ = new BehaviorSubject<number[]>(null);

  /** */
  public selected_imeis$$ = this.modal_devices_x_support_x_ticket$$.pipe(
    map(devices =>
      this._create_selectable_devices(devices)
        .filter(selectable_device => !selectable_device.disabled && !isNil(selectable_device?.device))
        .map(selectable_device => selectable_device.imei)
    ),
    take(1),
    tap(selected_imeis => (this.selected_imeis = selected_imeis)),
    switchMap(() => this._selected_imeis$$),
    distinctUntilRealChanged(),
    replay()
  );

  /** */
  public is_imei_selected$$ = (imei: number) => this.selected_imeis$$.pipe(map(selected_imeis => selected_imeis.includes(imei)));

  /** */
  public select_all_devices(completed: boolean): void {
    if (completed) {
      this.selectable_devices$$
        .pipe(
          map(selectable_devices => selectable_devices?.map(device => device?.imei)),
          take(1)
        )
        .subscribe({
          next: imeis => this._selected_imeis$$.next(imeis),
        });
    } else {
      this._selected_imeis$$.next([]);
    }
  }

  /** */
  public update_selected_imei(device_imei: number): void {
    const actual_application = clone(this._selected_imeis$$.getValue());
    const should_add = !actual_application.includes(device_imei);

    if (should_add) {
      actual_application.push(device_imei);
      this._selected_imeis$$.next(uniq(actual_application));

      return;
    }

    if (!should_add) {
      const updated = actual_application.filter(imei => imei !== device_imei);
      this._selected_imeis$$.next(updated);

      return;
    }
  }

  public selected_devices$$ = this._all_devices$$.pipe(
    switchMap(composed => this.selected_imeis$$.pipe(map(selected_imeis => composed.filter(c => selected_imeis.includes(c.device.imei))))),
    replay()
  );

  /** */
  public devices_still_in_ru_after_select$$ = combineLatest({
    selected_devices: this.selected_devices$$,
    selectable_devices: this.selectable_devices$$,
  }).pipe(
    map(({ selectable_devices, selected_devices }) => {
      const all_selectable_imeis = selectable_devices?.filter(c => !c?.disabled)?.map(c => c?.device?.imei);
      const all_selected_imeis = selected_devices?.map(c => c?.device?.imei);

      const not_selected_devices_imeis = difference(all_selectable_imeis, all_selected_imeis);

      if ((not_selected_devices_imeis ?? [])?.length === 0) {
        return [];
      }

      return selectable_devices.filter(c => not_selected_devices_imeis?.includes(c?.device?.imei));
    }),
    replay()
  );

  /** */
  public is_step_1_valid$$ = allTrue(
    this.is_loading_selectable_device.value$$.pipe(map(is_loading => !is_loading)),
    this.selected_imeis$$.pipe(map(selected_imeis => (selected_imeis ?? [])?.length > 0)),
    this.selected_desk_ticket$$.pipe(map(selected_ticket => !isNil(selected_ticket)))
  );

  // #endregion

  // #region -> (step 2 / configure end of support)

  /** */
  private readonly STEP_2_SCHEMA: ISchema = {
    type: 'object',
    properties: {
      start_time: {
        type: 'string',
        widget: 'hidden',
        label: i18n<string>('ALL.COMMON.DATE_MANAGEMENT.Start date'),
      },
      end_time: {
        label: i18n('VIEWS.DEVICES.SUPPORTS-DIALOG.Support end time'),
        type: 'string',
        widget: 'date-time',
        default: new Date().toISOString(),
        _min: {
          value: 'from_property',
          path: 'start_time',
        },
        options: {
          pickerType: 'both',
          show_seconds: false,
        },
      },
      ticket_status: {
        type: 'string',
        widget: 'select',
        label: i18n<string>('VIEWS.ZOHO.DIALOGS_AND_MODALS.ZOHO_CREATE_ISSUE_MODAL.FORM.Status'),
        oneOf: zoho_desk_ticket_status_oneof([]),
        options: {
          template_name: 'zoho-ticket-status',
        },
      },
      comment: {
        label: 'ALL.COMMON.Comment',
        type: 'string',
        widget: 'ng-mat-textarea',
        options: {
          autosize: {
            min: 3,
            max: 3,
          },
        },
      },
    },
    required: ['ticket_status', 'start_time', 'end_time'],
  };

  /** */
  public readonly step_2_schema$$ = of(this.STEP_2_SCHEMA).pipe(
    switchMap(schema =>
      combineLatest({
        minimal_date: this.selected_devices$$.pipe(
          map(devices => devices.map(device => parseDate(device?.support?.start_time))),
          map(start_times => max(start_times))
        ),
        ticket: this.selected_desk_ticket$$,
      }).pipe(
        map(({ minimal_date, ticket }) => {
          let modified_schema = cloneDeep(schema);

          modified_schema.properties.ticket_status.default = ticket.status;
          modified_schema.properties.start_time.default = minimal_date.toISOString() ?? null;

          return modified_schema;
        })
      )
    ),
    replay()
  );

  /** */
  public step_2_schema_model = new SimpleSetterGetter({ end_time: null, ticket_status: null, comment: null });

  /** */
  public step_2_schema_valid = new SimpleSetterGetter<boolean>(false);

  /** */
  public next_usage_options = [
    {
      value: DeviceSupportNextUsage.SEND_BACK_TO_CUSTOMER,
      label: __i18n_device_support_next_usage[DeviceSupportNextUsage.SEND_BACK_TO_CUSTOMER],
      image: 'picons/from_mdi/truck-delivery.svg',
    },
    {
      value: DeviceSupportNextUsage.FUNCTIONAL_STOCK,
      label: __i18n_device_support_next_usage[DeviceSupportNextUsage.FUNCTIONAL_STOCK],
      image: 'picons/stock-functional.svg',
    },
    {
      value: DeviceSupportNextUsage.DEFECTIVE_STOCK,
      label: __i18n_device_support_next_usage[DeviceSupportNextUsage.DEFECTIVE_STOCK],
      image: 'picons/stock-defective.svg',
    },
    {
      value: DeviceSupportNextUsage.UNTESTED_STOCK,
      label: __i18n_device_support_next_usage[DeviceSupportNextUsage.UNTESTED_STOCK],
      image: 'picons/stock-untested.svg',
    },
  ];

  // #endregion

  // #region -> (step 2 / per device config)

  /** */
  public configure_support_form: FormGroup<{
    [key: string]: FormGroup<{
      comment: FormControl<string>;
      next_usage: FormControl<DeviceSupportNextUsage>;
      replacement: FormGroup<{
        keep_old_device: FormControl<boolean>;
        reinstall_in_hive_or_apiary: FormControl<boolean>;
        replace_device: FormControl<boolean>;
        new_device: FormGroup<{
          imei: FormControl<number>;
          dtype: FormControl<string>;
        }>;
      }>;
    }>;
  }> = new FormGroup({});

  /** */
  public devices_next_usage_value = new SimpleSetterGetter<{
    [imei: string]: {
      comment: string;
      next_usage: DeviceSupportNextUsage;
      replacement: {
        new_device: {
          imei: number;
          dtype: string;
          device?: DRDevice;
        };
        replace_device: boolean;
        keep_old_device: boolean;
        reinstall_in_hive_or_apiary: boolean;
      };
    };
  }>({});

  /** */
  public readonly REPLACE_WITH_DEVICE_SCHEMA: ISchema = {
    type: 'object',
    properties: {
      replace_device: {
        type: 'boolean',
        widget: 'boolean',
        default: true,
        description_on: 'EVENT.DEVICE_REPLACE.Replace by another device',
      },

      new_device: {
        label: 'EVENT.DEVICE.by this device',
        type: 'object',
        widget: 'bg2device',
        visibleIf: {
          replace_device: true,
        },
        options: {
          show_all_devices: true,
          // previous_warehouse_path: 'apply_to/previous_warehouse',
          // previous_apiary_path: 'apply_to/previous_apiary',
          // previous_hive_path: 'apply_to/previous_hive',
        },
        properties: {
          imei: {
            type: 'integer',
            label: 'EVENT.DEVICE.Device ID',
          },
          dtype: {
            type: 'string',
            label: 'EVENT.DEVICE.Type of device',
          },
        },
        required: ['imei', 'dtype'],
      },

      reinstall_in_hive_or_apiary: {
        type: 'boolean',
        default: false,
        description_on: 'EVENT.DEVICE_REPLACE.Reinstall new device in hive or apiary',
        visibleIf: {
          replace_device: true,
        },
      },

      keep_old_device: {
        type: 'boolean',
        default: false,
        description_on: 'EVENT.DEVICE_REPLACE.Keep old device in the warehouse (for now)',
        visibleIf: {
          replace_device: true,
        },
      },
    },
  };

  /** */
  public on_update_device_replace(imei: number, value: any): void {
    this.configure_support_form.controls[imei.toString()].controls['replacement'].patchValue({
      new_device: value?.new_device,
      keep_old_device: value?.keep_old_device,
      replace_device: value?.replace_device,
      reinstall_in_hive_or_apiary: value?.reinstall_in_hive_or_apiary,
    });
  }

  /** */
  protected duplicate_configuration_to_all_devices(initial_imei: number): void {
    const current_form_value = this.configure_support_form.controls[initial_imei.toString()].value;

    this.selected_devices$$.pipe(take(1)).subscribe({
      next: selected_devices => {
        selected_devices
          ?.map(device => device?.device?.imei)
          .filter(imei => imei !== initial_imei)
          .forEach(imei => {
            this.configure_support_form?.controls[imei.toString()].patchValue(current_form_value);
          });
      },
    });
  }

  public device_next_usage_form_value$$ = (imei: number) =>
    this.devices_next_usage_value.value$$.pipe(
      map(form_value => form_value?.[imei.toString()]),
      distinctUntilRealChanged(),
      map(control_value => {
        const form_value = {
          new_device: {
            imei: control_value.replacement.new_device.imei,
            dtype: control_value.replacement.new_device.dtype,
          },
          replace_device: control_value.replacement.replace_device,
          keep_old_device: control_value.replacement.keep_old_device,
          reinstall_in_hive_or_apiary: control_value.replacement.reinstall_in_hive_or_apiary,
        };

        return form_value;
      })
    );

  public is_device_next_usage_valid$$ = (imei: number) =>
    this.devices_next_usage_value.value$$.pipe(
      map(form_value => form_value?.[imei.toString()]),
      distinctUntilRealChanged(),
      map(value => {
        if (isNil(value)) {
          return false;
        }

        if (value.next_usage === DeviceSupportNextUsage.SEND_BACK_TO_CUSTOMER) {
          return true;
        }

        const replacement = value.replacement;

        if (!replacement.replace_device) {
          return true;
        }

        if (replacement.replace_device) {
          if (isNil(replacement?.new_device?.imei)) {
            return false;
          }
        }

        return true;
      })
    );

  public is_valid$$ = this.devices_next_usage_value.value$$.pipe(
    map(devices_next_usage_value => {
      const control_validity = mapValues(devices_next_usage_value, (value, imei) => {
        if (isNil(value)) {
          return false;
        }

        if (value.next_usage === DeviceSupportNextUsage.SEND_BACK_TO_CUSTOMER) {
          return true;
        }

        const replacement = value.replacement;

        if (!replacement.replace_device) {
          return true;
        }

        if (replacement.replace_device) {
          if (isNil(replacement?.new_device?.imei)) {
            return false;
          }
        }

        return true;
      });

      return every(Object.values(control_validity), Boolean);
    }),
    distinctUntilRealChanged(),
    replay()
  );

  /** */
  public can_close_ticket$$ = combineLatest({
    model: this.step_2_schema_model.value$$,
    devices_still_in_ru: this.devices_still_in_ru_after_select$$,
  }).pipe(
    map(({ model, devices_still_in_ru }) => {
      const ticket_status = <ZohoDeskTicketStatus>model.ticket_status;

      if (devices_still_in_ru?.length > 0 && ticket_status === ZohoDeskTicketStatus.CLOTURE) {
        return false;
      }

      return true;
    }),
    replay()
  );

  /** */
  public is_step_2_valid$$ = allTrue(this.step_2_schema_valid.value$$, this.is_valid$$, this.can_close_ticket$$);

  // #endregion

  // #region -> (step 3 / review)

  /** */
  public data_for_step_3$$ = combineLatest({ a: this.step_2_schema_model.value$$, b: this.devices_next_usage_value.value$$ }).pipe(
    map(({ a, b }) => {
      const data = {
        comment: a.comment,
        date: a.end_time,
        ticket_status: a.ticket_status,
        devices: b,
      };

      return data;
    }),
    switchMap(data => {
      const replaced_device_imeis = Object.values(data.devices)
        .map(a => a?.replacement?.new_device?.imei ?? null)
        .filter(imei => !isNil(imei));

      if (replaced_device_imeis?.length > 0) {
        const replaced_devices$ = this._deviceApi.requestDevices(replaced_device_imeis);
        return replaced_devices$.pipe(
          map(replaced_devices => {
            data.devices = mapValues(data.devices, value => {
              if (value?.replacement?.replace_device) {
                value.replacement.new_device.device = replaced_devices.find(device => device.imei === value.replacement.new_device.imei);
              }

              return value;
            });

            return data;
          })
        );
      }

      return of(data);
    }),
    distinctUntilRealChanged(),
    replay()
  );

  /** */
  public review_summary$$ = combineLatest({
    form_model: this.data_for_step_3$$,
    all_devices: this._all_devices$$,
  }).pipe(
    map(({ form_model, all_devices }) => {
      // All devices data
      const all_device_imeis: number[] = all_devices.map(device_x_support => device_x_support.device.imei);
      const selected_imeis = Object.keys(form_model.devices).map(imei => parseInt(imei, 10));

      // Selected devices data
      const selected_device_x_support_list: DeviceXSupportXDeskTicket[] = all_devices.filter(device_x_support =>
        selected_imeis.includes(device_x_support.device.imei)
      );

      // Remaining devices data
      const remaining_devices_with_opened_support: DeviceXSupportXDeskTicket[] = all_devices.filter(
        device_x_support => device_x_support.support?.is_open === true
      );
      const remaining_imeis = remaining_devices_with_opened_support.map(device_x_support => device_x_support.device.imei);

      // Compute some variables
      const is_partial = difference(all_device_imeis, selected_imeis).length > 0;
      const is_completing = difference(remaining_imeis, selected_imeis).length === 0;

      return {
        ticket: {
          is_partial,
          is_completing,
          comment: form_model.comment,
          status: form_model.ticket_status,
          id: all_devices.filter(c => !isNil(c?.ticket?.id)).map(c => c?.ticket?.id)?.[0],
        },
        date: form_model.date,
        by_device: selected_device_x_support_list.map(d => ({
          ...d,
          next_usage: form_model.devices[d.device.imei],
        })),
      };
    }),
    replay()
  );

  // #endregion

  // #region -> (step 3 / save)

  /** */
  private _create_change_exploitation_event(
    date: Date,
    data: { device: { imei: number; dtype: DeviceInterface.TypeEnum } },
    apply_to: {
      warehouse: {
        entity_id: number;
      };
      warehouse_source: {
        entity_id: number;
      };
    }
  ): Event {
    const new_event = eventDeserialize(this._bg2Api, this._deviceApi, {
      type: 'device_register',
      date: date,
      data: {
        device: data.device,
      },
      apply_to: apply_to,
    });

    return new_event;
  }

  /** */
  private _create_replacement_event(
    date: Date,
    data: {
      keep_old_device: boolean;
      new_device: { imei: number; dtype: DeviceInterface.TypeEnum };
      old_device: { imei: number; dtype: DeviceInterface.TypeEnum };
      reinstall_in_hive_or_apiary: boolean;
    },
    apply_to: {
      /** New warehouse of the replaced device */
      sav_warehouse: {
        /** ID of the entity */
        entity_id: number;
      };

      /** Warehouse of the device to replace with */
      previous_warehouse: {
        /** ID of the entity */
        entity_id: number;
      };

      /** Warehouse of the device to replace */
      warehouse: {
        /** ID of the entity */
        entity_id: number;
      };
    }
  ): Event {
    const new_event = eventDeserialize(this._bg2Api, this._deviceApi, {
      type: 'device_replace',
      date,
      data,
      apply_to,
    });

    return new_event;
  }

  /** */
  public is_saving = new SimpleSetterGetter(false);

  /** */
  public create_requests_for_save(stepper: MatStepper): void {
    this.review_summary$$
      .pipe(
        take(1),
        map(review => {
          // Build requests for each modified device
          const devices_requests$ = review.by_device.map(composed => {
            const replacement_data = composed?.next_usage?.replacement;
            const is_replacement = replacement_data?.replace_device;

            let stock_warehouse_id: number = null;
            if (composed?.next_usage?.next_usage === DeviceSupportNextUsage.DEFECTIVE_STOCK) {
              stock_warehouse_id = 26200;
            }

            if (composed?.next_usage?.next_usage === DeviceSupportNextUsage.FUNCTIONAL_STOCK) {
              stock_warehouse_id = 26198;
            }

            if (composed?.next_usage?.next_usage === DeviceSupportNextUsage.UNTESTED_STOCK) {
              stock_warehouse_id = 1689;
            }

            const request_observer: EndOfSupportRequest<any>[] = [];

            switch (composed?.next_usage?.next_usage) {
              case DeviceSupportNextUsage.SEND_BACK_TO_CUSTOMER: {
                break;
              }

              case DeviceSupportNextUsage.DEFECTIVE_STOCK:
              case DeviceSupportNextUsage.FUNCTIONAL_STOCK:
              case DeviceSupportNextUsage.UNTESTED_STOCK: {
                const previous_device: DRDevice = composed?.device;
                const new_device = replacement_data?.new_device;

                if (is_replacement) {
                  request_observer.push(
                    {
                      name: 'change_owner_of_old_device',
                      status: 'not_started',
                      label: i18n<string>(
                        'VIEWS.DEVICES.DIALOGS_AND_MODALS.MODALS.DEVICE_SUPPORT.DEVICE_SUPPORT_CLOSE_MODAL.STEP_SAVE.Change device owner'
                      ),
                      request$$: this._deviceApi.putBulkOwner({ dids: [previous_device?.imei], owner: 210 }),
                    },
                    {
                      name: 'change_owner_of_new_device',
                      status: 'not_started',
                      label: i18n<string>(
                        'VIEWS.DEVICES.DIALOGS_AND_MODALS.MODALS.DEVICE_SUPPORT.DEVICE_SUPPORT_CLOSE_MODAL.STEP_SAVE.Change device owner'
                      ),
                      request$$: this._deviceApi.putBulkOwner({
                        dids: [new_device?.imei],
                        owner: previous_device.owner,
                      }),
                    },
                    {
                      name: 'replacement_event',
                      status: 'not_started',
                      label: i18n<string>(
                        'VIEWS.DEVICES.DIALOGS_AND_MODALS.MODALS.DEVICE_SUPPORT.DEVICE_SUPPORT_CLOSE_MODAL.STEP_SAVE.Replace device (event)'
                      ),
                      request$$: this._bg2Api.create_event$(
                        this._create_replacement_event(
                          parseDate(review.date),
                          {
                            keep_old_device: replacement_data?.keep_old_device ?? false,
                            reinstall_in_hive_or_apiary: replacement_data?.reinstall_in_hive_or_apiary ?? false,
                            old_device: {
                              imei: previous_device?.imei,
                              dtype: previous_device?.type,
                            },
                            new_device: {
                              imei: new_device?.imei,
                              dtype: <any>new_device?.dtype,
                            },
                          },
                          {
                            previous_warehouse: {
                              entity_id: new_device?.device?.warehouse_id,
                            },
                            warehouse: {
                              entity_id: previous_device?.warehouse_id,
                            },
                            sav_warehouse: {
                              entity_id: stock_warehouse_id,
                            },
                          }
                        )
                      ),
                    }
                  );
                } else {
                  // Create event to change exploitation
                  request_observer.push(
                    {
                      name: 'change_exploitation',
                      status: 'not_started',
                      label: i18n<string>(
                        'VIEWS.DEVICES.DIALOGS_AND_MODALS.MODALS.DEVICE_SUPPORT.DEVICE_SUPPORT_CLOSE_MODAL.STEP_SAVE.Change device exploitation (event)'
                      ),
                      request$$: this._bg2Api.create_event$(
                        this._create_change_exploitation_event(
                          parseDate(review.date),
                          {
                            device: {
                              imei: previous_device?.imei,
                              dtype: previous_device?.type,
                            },
                          },
                          {
                            warehouse: {
                              entity_id: stock_warehouse_id,
                            },
                            warehouse_source: {
                              entity_id: previous_device?.warehouse_id,
                            },
                          }
                        )
                      ),
                    },
                    {
                      name: 'change_owner',
                      status: 'not_started',
                      label: i18n<string>(
                        'VIEWS.DEVICES.DIALOGS_AND_MODALS.MODALS.DEVICE_SUPPORT.DEVICE_SUPPORT_CLOSE_MODAL.STEP_SAVE.Change device owner'
                      ),
                      request$$: this._deviceApi.putBulkOwner({ dids: [previous_device?.imei], owner: 210 }),
                    }
                  );
                }

                break;
              }
            }

            request_observer.push({
              name: 'close_ru_support',
              label: i18n<string>(
                'VIEWS.DEVICES.DIALOGS_AND_MODALS.MODALS.DEVICE_SUPPORT.DEVICE_SUPPORT_CLOSE_MODAL.STEP_SAVE.Close device RU'
              ),
              status: 'not_started',
              request$$: this._deviceApi.update_device_support$(composed?.device?.imei, composed?.support?.id, {
                end_time: review?.date,
                is_open: false,
              }),
            });

            return { device: composed?.device, requests: request_observer };
          });

          return {
            device_requests: devices_requests$,
            ticket_request: <
              {
                name: string;
                label: string;
                status: 'not_started' | 'running' | 'failed' | 'success';
                request$$: Observable<ZohoDeskTicket>;
              }
            >{
              label: i18n<string>(
                'VIEWS.DEVICES.DIALOGS_AND_MODALS.MODALS.DEVICE_SUPPORT.DEVICE_SUPPORT_CLOSE_MODAL.STEP_SAVE.Update desk ticket'
              ),
              status: 'not_started',
              name: 'update_desk_ticket',
              request$$: this._zohoDeskApi.update_ticket_with_support$(review?.ticket.id, {
                type: 'close',
                review: review,
                comment: review.ticket.comment,
                selected_devices: review?.by_device?.map(c => c?.device),
              }),
            },
          };
        })
      )
      .subscribe({
        next: response => {
          if (isNil(response)) {
            return;
          }

          this._requests.value = response;
          this.next_step(stepper);
        },
        complete: () => {},
      });
  }

  /** */
  protected _requests = new SimpleSetterGetter<{
    /** */
    device_requests: {
      /** */
      device: DRDevice;

      /** */
      requests: {
        /** */
        name: string;

        /** */
        label: string;

        /** */
        status: 'not_started' | 'running' | 'failed' | 'success';

        /** */
        request$$: Observable<any>;
      }[];
    }[];

    /** */
    ticket_request: {
      /** */
      name: string;

      /** */
      label: string;

      /** */
      status: 'not_started' | 'running' | 'failed' | 'success';

      /** */
      request$$: Observable<ZohoDeskTicket>;
    };
  }>();

  /** */
  public has_failed_requests$$ = this._requests.value$$.pipe(
    map(requests => {
      if (isNil(requests)) {
        return false;
      }

      const all_statuses = [
        requests?.ticket_request?.status ?? null,
        ...requests?.device_requests?.map(d => d?.requests?.map(k => k?.status ?? null)),
      ];
      const uniq_status = uniq(flatten(all_statuses).filter(status => !isNil(status)));

      if (uniq_status.length === 1) {
        const status = uniq_status?.[0];

        if (status === 'not_started' || status === 'running' || status === 'success') {
          return false;
        }
      }

      return true;
    }),
    replay()
  );

  // #endregion

  // #region -> (step 4 / apply save)

  /** */
  public save(): void {
    this.is_saving.value = true;

    const cloned_requests = clone(this._requests.value);

    const b$$ = cloned_requests.device_requests.map((device_requests, index_of_device_requests) => {
      const a$$ = device_requests.requests.map((r, index_of_device_request) => {
        cloned_requests.device_requests[index_of_device_requests].requests[index_of_device_request].status = 'running';

        return r.request$$.pipe(
          map(() => {
            r.status = 'success';
            return r;
          }),
          catchError(() => {
            r.status = 'failed';
            return of(r);
          })
        );
      });

      return forkJoin(a$$).pipe(
        tap(responses => {
          cloned_requests.device_requests[index_of_device_requests].requests = responses;
          this._requests.value = cloned_requests;
        })
      );
    });

    cloned_requests.ticket_request.status = 'running';

    const c$$ = cloned_requests.ticket_request.request$$.pipe(
      map(request => {
        cloned_requests.ticket_request.status = 'success';
        return cloned_requests.ticket_request;
      }),
      catchError(() => {
        cloned_requests.ticket_request.status = 'failed';
        return of(cloned_requests.ticket_request);
      }),
      tap(() => (this._requests.value = cloned_requests))
    );

    forkJoin(b$$)
      .pipe(switchMap(b => c$$.pipe(map(c => ({ b, c })))))
      .subscribe({
        next: response => {
          if (!isNil(response)) {
            const all_statuses = [response.c.status, ...response.b.map(d => d.map(k => k.status))];
            const uniq_status = uniq(flatten(all_statuses));

            if (uniq_status.length === 1 && uniq_status?.[0] === 'success') {
              this.close();
            }
          }
        },
        complete: () => {
          this.is_saving.value = false;
        },
      });
  }

  /** */
  public retry_failed_requests(): void {
    this.is_saving.value = true;

    const cloned_requests = clone(this._requests.value);

    const b$$ = cloned_requests.device_requests.map((device_requests, index_of_device_requests) => {
      const a$$ = device_requests.requests
        .filter(request => request.status !== 'success')
        .map((r, index_of_device_request) => {
          cloned_requests.device_requests[index_of_device_requests].requests[index_of_device_request].status = 'running';

          return r.request$$.pipe(
            map(() => {
              r.status = 'success';
              return r;
            }),
            catchError(() => {
              r.status = 'failed';
              return of(r);
            })
          );
        });

      return forkJoin(a$$).pipe(
        tap(responses => {
          cloned_requests.device_requests[index_of_device_requests].requests = responses;
          this._requests.value = cloned_requests;
        })
      );
    });

    const ticket_request_observer$$ =
      cloned_requests?.ticket_request?.status !== 'success'
        ? cloned_requests.ticket_request.request$$.pipe(
            map(() => {
              cloned_requests.ticket_request.status = 'success';
              return cloned_requests.ticket_request;
            }),
            catchError(() => {
              cloned_requests.ticket_request.status = 'failed';
              return of(cloned_requests.ticket_request);
            }),
            tap(() => (this._requests.value = cloned_requests))
          )
        : of(null).pipe(take(1));

    forkJoin(b$$)
      .pipe(
        tap(data => console.log(data)),
        switchMap(b => ticket_request_observer$$.pipe(map(c => ({ b, c }))))
      )
      .subscribe({
        next: response => {
          this.is_saving.value = false;

          if (!isNil(response)) {
            const all_statuses = [response.c.status, ...response.b.map(d => d.map(k => k.status))];

            const uniq_status = uniq(all_statuses);

            if (uniq_status.length === 0 && uniq_status?.[0] === 'success') {
              this.close();
            }
          }
        },
        complete: () => {
          this.is_saving.value = false;
        },
      });
  }

  // #endregion

  // #region -> (errors)

  /** */
  public readonly error_type = {
    different_desk_tickets: <ErrorHelperData>{
      name: '',
      message: '',
      description: [
        {
          type: 'image_svg',
          url: '/assets/img/pictos/lock.svg',
          styles: {
            width: '40px',
          },
        },
        {
          type: 'span',
          content: i18n<string>(
            'VIEWS.DEVICES.DIALOGS_AND_MODALS.MODALS.DEVICE_SUPPORT.DEVICE_SUPPORT_CLOSE_MODAL.ERROR.Multiple DESK ticket reference detected'
          ),
        },
        {
          type: 'span',
          content: i18n<string>(
            'VIEWS.DEVICES.DIALOGS_AND_MODALS.MODALS.DEVICE_SUPPORT.DEVICE_SUPPORT_CLOSE_MODAL.ERROR.You cannot declare an end of support with multiple DESK ticket references'
          ),
        },
      ],
    },
    no_opened_support: <ErrorHelperData>{
      name: '',
      message: '',
      description: [
        {
          type: 'image_svg',
          url: '/assets/img/pictos/lock.svg',
          styles: {
            width: '40px',
          },
        },
        {
          type: 'span',
          content: i18n<string>(
            'VIEWS.DEVICES.DIALOGS_AND_MODALS.MODALS.DEVICE_SUPPORT.DEVICE_SUPPORT_CLOSE_MODAL.ERROR.No device with opened support selected'
          ),
        },
        {
          type: 'span',
          content: i18n<string>(
            'VIEWS.DEVICES.DIALOGS_AND_MODALS.MODALS.DEVICE_SUPPORT.DEVICE_SUPPORT_CLOSE_MODAL.ERROR.You cannot declare an end of support for a device without an opened support'
          ),
        },
      ],
    },
  };

  /** */
  public error$$ = combineLatest({
    is_only_one_desk_ticket: this.is_only_one_desk_ticket$$,
    have_at_least_one_opened_support: this.have_at_least_one_opened_support$$,
  }).pipe(
    map(errors => {
      if (!errors.have_at_least_one_opened_support) {
        return this.error_type.no_opened_support;
      }

      if (!errors.is_only_one_desk_ticket) {
        return this.error_type.different_desk_tickets;
      }

      return null;
    }),
    replay()
  );

  // #endregion

  // #region -> (helpers)

  /** */
  private _fetch_supports_with_ticket_of_devices$(devices: DRDevice[]): Observable<DeviceXSupportXDeskTicket[]> {
    const support_x_device$$ = devices.map(device => {
      const query_device_supports$ = this._deviceApi.fetch_device_supports$(device.imei, null, null, {
        is_open: true,
        type: DeviceSupportType.RU_NO_SMS,
      });

      return query_device_supports$.pipe(
        map(response => (response?.supports ?? [null])[0]),
        switchMap(support => {
          if (isNil(support) || isNil(support?.issue_id)) {
            return of<DeviceXSupportXDeskTicket>({ device, support, ticket: null });
          }

          return this._zohoDeskApi.fetch_module$('tickets', support.issue_id).pipe(
            map(ticket => <DeviceXSupportXDeskTicket>{ device, support, ticket }),
            catchError(() => of<DeviceXSupportXDeskTicket>({ device, support, ticket: null }))
          );
        })
      );
    });

    return combineLatest(support_x_device$$);
  }

  /** */
  private _create_selectable_devices(composed: DeviceXSupportXDeskTicket[]): SelectDeviceToCloseRU[] {
    return composed.map(c => {
      const device = c.device;
      const support = c.support;

      const item: SelectDeviceToCloseRU = {
        device,
        support,
        name: device.name,
        imei: device.imei,
        disabled: (device?.supports?.open ?? [])?.filter(_support => _support.type === DeviceSupportType.RU_NO_SMS)?.length === 0,
      };

      return item;
    });
  }

  // #endregion

  /** */
  // public can_submit$$ = allTrue(
  //   this.form_valid$$,
  //   this.selected_imeis$$.pipe(map(imeis => imeis?.length > 0)),
  //   this.is_submitted$$.pipe(map(is_submitted => is_submitted === false))
  // );
}
