import { marker as i18n } from '@biesbjerg/ngx-translate-extract-marker';
import { DeviceSupportIssue } from 'app/core/api-swagger/device';

export type DeviceSupportCreationType = 'new' | 'update' | 'close';

/** */
export interface RawDeviceSupports {
  /** */
  last_closed_support_end: string;

  /** */
  newest_open_support_start: string;

  /** */
  oldest_open_support_start: string;

  /** */
  open: DeviceSupportIssue[];

  /** */
  open_tags: ('no_sms' | 'ru')[];

  /** */
  open_types: DeviceSupportType[];
}

/** */
export enum DeviceSupportType {
  RU_NO_SMS = 'ru_no_sms',
  UNKNOWN = 'unknown',
}

/** */
const device_support_type_i18n: { [key in DeviceSupportType]: string } = {
  /** */
  ru_no_sms: i18n<string>('DEVICE.SUPPORTS.TYPE.ru_no_sms'),

  /** */
  unknown: i18n<string>('DEVICE.SUPPORTS.TYPE.unknown'),
};

// #region -> (device support next usage)

/** */
export enum DeviceSupportNextUsage {
  /** */
  SEND_BACK_TO_CUSTOMER = 'send_back_to_customer',

  /** */
  DEFECTIVE_STOCK = 'defective_stock',

  /** */
  FUNCTIONAL_STOCK = 'functional_stock',

  /** */
  UNTESTED_STOCK = 'untested_stock',
}

/** */
export const __i18n_device_support_next_usage: { [key in DeviceSupportNextUsage]: string } = {
  /** */
  send_back_to_customer: i18n<string>('DEVICE.SUPPORTS.NEXT_USAGE.send_back_to_customer'),

  /** */
  defective_stock: i18n<string>('DEVICE.SUPPORTS.NEXT_USAGE.defective_stock'),

  /** */
  functional_stock: i18n<string>('DEVICE.SUPPORTS.NEXT_USAGE.functional_stock'),

  /** */
  untested_stock: i18n<string>('DEVICE.SUPPORTS.NEXT_USAGE.untested_stock'),
};

// #endregion
