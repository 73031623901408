<bg2-modal [fullscreen]="fullscreen || false" #modal>
  <bg2-modal-header>
    <div class="header">
      <span class="title">{{'VIEWS.MODALS.NEW_ISSUE.Contact us' | translate}}</span>
    </div>

    <div class="action-buttons">
      <span class="mdi mdi-close-box mdi-36px" (click)="close()"></span>
    </div>
  </bg2-modal-header>

  <bg2-modal-content>
    <bg2-form-overlay
      [source_model]="form_model$$ | async"
      [source_schema]="form_schema$$ | async"
      (when_form_valid_update)="is_valid = $event"
      (when_form_data_update)="onFormChange({ value: $event })"
    ></bg2-form-overlay>

    <div>
      <bg2-button [type]="'cancel'" [message]="'VIEWS.MODALS.FORM.Cancel' | translate" (btn_click)="close()"></bg2-button>

      <bg2-button
        [type]="'action'"
        [loading]="loading$$ | async"
        [disabled]="(loading$$ | async) || (is_valid$$ | async) === false"
        [message]="'VIEWS.MODALS.FORM.Submit' | translate"
        (btn_click)="submit()"
      ></bg2-button>
    </div>

    <div class="info">
      <p *ngIf="(language$$ | async) === 'fr'">
        <doc-helper page="Summary"> {{ 'VIEWS_WINDOWED.MODALS.NEW_ISSUE.Did you check the user manual' | translate }} </doc-helper>
      </p>
      <p>
        <span>{{ 'VIEWS.MODALS.NEW_ISSUE.You can also contact us by phone at' | translate }}&nbsp;</span>
        <a [href]="'tel:' + ('ALL.TEL.compact' | translate)">{{"ALL.TEL.readable" | translate}}</a>
      </p>
    </div>
  </bg2-modal-content>
</bg2-modal>
