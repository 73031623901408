import { AfterViewInit, ChangeDetectionStrategy, Component, Input, OnDestroy } from '@angular/core';

import {
  of,
  map,
  tap,
  filter,
  forkJoin,
  switchMap,
  Observable,
  catchError,
  Subscription,
  debounceTime,
  combineLatest,
  BehaviorSubject,
  take,
  first,
  Subject,
  takeUntil,
} from 'rxjs';
import {
  replay,
  anyTrue,
  waitForNotNilValue,
  robustCombineLatest,
  waitForNotNilProperties,
  distinctUntilRealChanged,
} from '@bg2app/tools/rxjs';

import { TranslateService } from '@ngx-translate/core';
import { marker as i18n } from '@biesbjerg/ngx-translate-extract-marker';

import { DateTime } from 'luxon';
import { isEqual } from 'date-fns/esm';
import { flatten, isNil, last, range } from 'lodash-es';

import { Beeguard2Api } from 'app/core';
import { AppStateService } from 'app/core/app-state.service';
import { ZohoAuthService } from 'app/core/services/zoho/zoho-auth.service';
import { ZohoDeskApiService } from 'app/core/services/zoho/zoho-desk-api.service';
import { AckConfiguration, DeviceAffectationTimeSegment, DeviceSupportIssue } from 'app/core/api-swagger/device';

import { ZohoDeskTicket } from 'app/models/zoho/desk';
import { Dictionary } from 'app/typings/core/interfaces';
import { DRDevice, Event, SimpleSetterGetter } from 'app/models';
import { BulkDevicesRegister, DeviceRegister } from 'app/models/events/device_setup_events';

import { parseDate } from 'app/misc/tools';
import { DialogsService } from 'app/widgets/dialogs-modals';
import { ConsoleLoggerService } from 'app/core/console-logger.service';

/** */
abstract class DeviceLocalEvent {
  // #region -> (model basics)

  /** */
  public abstract use_event_span: boolean;

  /** */
  protected destroyed$ = new Subject<boolean>();

  constructor(date: Date) {
    this.date = parseDate(date);
  }

  /** */
  public destroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  // #endregion

  // #region -> (event filtering)

  /** */
  public abstract apply_filter$$(filters: DeviceHistoryFilters): Observable<boolean>;

  // #endregion

  // #region -> (event basics)

  /** */
  private _date$$ = new BehaviorSubject<Date>(null);

  /** */
  public date$$ = this._date$$.asObservable();

  /** */
  public set date(date: Date) {
    this._date$$.next(date);
  }

  /** */
  public get date(): Date {
    return this._date$$.getValue();
  }

  /** */
  public abstract get_event_label$$: Observable<string>;

  /** */
  public abstract get_event_label_params$$: Observable<Dictionary<any>>;

  // #endregion
}

/** */
class DeviceLocalEventAffectation extends DeviceLocalEvent {
  // #region -> (auto-subs)

  /** */
  private _real_event_sub: Subscription = null;

  // #endregion

  // #region -> (model basics)

  /** */
  public use_event_span: boolean = true;

  /** */
  private _translate_service: TranslateService = null;

  /** */
  private _beeguard2_api: Beeguard2Api = null;

  constructor(affectation: DeviceAffectationTimeSegment, _bg2Api: Beeguard2Api, _translateService: TranslateService) {
    super(affectation.start);

    this._beeguard2_api = _bg2Api;
    this._translate_service = _translateService;

    this._affectation$$.next(affectation);

    this._real_event_sub = this.event$$.pipe(takeUntil(this.destroyed$)).subscribe();
  }

  // #endregion

  // #region -> (event filtering)

  /** */
  public apply_filter$$(filters: DeviceHistoryFilters): Observable<boolean> {
    return this.event$$.pipe(
      map(event => {
        if (filters.show_only_affectated_exploitations) {
          const is_device_register = event instanceof DeviceRegister;
          const is_devices_bulk_register = event instanceof BulkDevicesRegister;

          return is_device_register || is_devices_bulk_register;
        }

        return true;
      })
    );
  }

  // #endregion

  // #region -> (event related data)

  /** */
  private _affectation$$ = new BehaviorSubject<DeviceAffectationTimeSegment>(null);

  /** */
  public affectation$$ = this._affectation$$.asObservable();

  /** */
  public event$$: Observable<Event> = this.affectation$$.pipe(
    map(affectation => affectation?.event_id),
    switchMap(event_id => this._beeguard2_api.getEventObj(event_id)),
    replay()
  );

  // #endregion

  // #region -> (event basics)

  /** */
  public get_event_label$$: Observable<string> = this.event$$.pipe(
    waitForNotNilValue(),
    switchMap(event => event?.getDesc(this._translate_service, null, null, true)),
    replay()
  );

  /** */
  public get_event_label_params$$: Observable<Dictionary<any>> = this.affectation$$.pipe(
    map(affectation => ({})),
    replay()
  );

  // #endregion
}

/** */
class DeviceLocalEventDeskTicket extends DeviceLocalEvent {
  // #region -> (model basics)

  /** */
  public use_event_span: boolean = false;

  constructor(ticket: ZohoDeskTicket, event_date: Date) {
    super(event_date);

    this._ticket$$.next(ticket);
  }

  // #endregion

  // #region -> (event filtering)

  /** */
  public apply_filter$$(filters: DeviceHistoryFilters): Observable<boolean> {
    return this.ticket$$.pipe(
      map(ticket => {
        if (!filters.show_off_topic_tickets && ticket.maybe_off_topic) {
          return false;
        }

        return true;
      })
    );
  }

  // #endregion

  // #region -> (event related data)

  /** */
  private _ticket$$ = new BehaviorSubject<ZohoDeskTicket>(null);

  /** */
  public ticket$$ = this._ticket$$.asObservable();

  /** */
  public get ticket() {
    return this._ticket$$.getValue();
  }

  // #endregion

  // #region -> (event basics)

  /** */
  public get_event_label$$: Observable<string> = this.ticket$$.pipe(
    switchMap(ticket =>
      this.date$$.pipe(
        map(event_date => {
          const is_open_date_same_as_event = isEqual(event_date, parseDate(ticket.createdTime));

          return is_open_date_same_as_event
            ? i18n<string>(`MODELS.ZOHO.DESK.TICKET.New opened DESK ticket "[subject]" ([link])`)
            : i18n<string>(`MODELS.ZOHO.DESK.TICKET.Closure of the DESK ticket ([link])`);
        })
      )
    ),
    replay()
  );

  /** */
  public get_event_label_params$$: Observable<Dictionary<any>> = this.ticket$$.pipe(
    switchMap(ticket => combineLatest({ url_href: ticket.url_href$$, subject: ticket?.subject$$ })),
    map(({ url_href, subject }) => ({ link: url_href, subject })),
    replay()
  );

  // #endregion
}

/** */
class DeviceLocalEventAckConf extends DeviceLocalEvent {
  // #region -> (model basics)

  /** */
  public use_event_span: boolean = false;

  constructor(ack_config: AckConfiguration) {
    super(parseDate((<any>ack_config)?.time));
    this._ack_config$$.next(ack_config);
  }

  // #endregion

  // #region -> (event filtering)

  /** */
  public apply_filter$$(filters: DeviceHistoryFilters): Observable<boolean> {
    return of(true);
  }

  // #endregion

  // #region -> (event related data)

  /** */
  private _ack_config$$ = new BehaviorSubject<AckConfiguration>(null);

  /** */
  public ack_config$$ = this._ack_config$$.asObservable();

  // #endregion

  // #region -> (event basics)

  /** */
  public get_event_label$$: Observable<string> = this.ack_config$$.pipe(
    switchMap(ack_config => of(i18n<string>('DEVICE.ALL.CONFIGURATION.STATUS.New configuration acknowledged'))),
    replay()
  );

  /** */
  public get_event_label_params$$: Observable<Dictionary<any>> = this.ack_config$$.pipe(
    map(ack_config => ({})),
    replay()
  );

  // #endregion
}

/** */
class DeviceLocalEventSupport extends DeviceLocalEvent {
  // #region -> (model basics)

  /** */
  public use_event_span: boolean = false;

  constructor(support: DeviceSupportIssue, event_date: Date) {
    super(event_date);

    this._support$$.next(support);
  }

  // #endregion

  // #region -> (event filtering)

  /** */
  public apply_filter$$(filters: DeviceHistoryFilters): Observable<boolean> {
    return of(true);
  }

  // #endregion

  // #region -> (event related data)

  /** */
  private _support$$ = new BehaviorSubject<DeviceSupportIssue>(null);

  /** */
  public support$$ = this._support$$.asObservable();

  // #endregion

  // #region -> (event basics)

  /** */
  public get_event_label$$: Observable<string> = this.support$$.pipe(
    switchMap(support =>
      this.date$$.pipe(
        map(event_date => {
          const is_open_date_same_as_event = isEqual(event_date, parseDate(support.start_time));

          if (is_open_date_same_as_event) {
            const key_basic = i18n<string>(`DEVICE.SUPPORTS.EVENT.New support "[type]" opened`);

            return key_basic;
          }

          return i18n<string>(`DEVICE.SUPPORTS.EVENT.End of support`);
        })
      )
    ),
    replay()
  );

  /** */
  public get_event_label_params$$: Observable<Dictionary<any>> = this.support$$.pipe(
    map(support => ({ type: support?.type })),
    replay()
  );

  // #endregion
}

/** */
interface DeviceHistoryFilters {
  /** */
  show_off_topic_tickets: boolean;

  /** */
  show_only_affectated_exploitations: boolean;
}

/** */
type DisplayedEvents = ('*' | 'affectations' | 'tickets' | 'supports' | 'ack_configs')[];

@Component({
  selector: 'bg2-device-history',
  templateUrl: './device-history.component.html',
  styleUrls: ['./device-history.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DeviceHistoryComponent implements AfterViewInit, OnDestroy {
  // #region -> (component basics)

  /** */
  private readonly LOGGER = new ConsoleLoggerService('DeviceHistoryComponent', false);

  /** */
  private _device_affectations_sub: Subscription = null;

  constructor(
    public readonly appState: AppStateService,
    private readonly _bg2Api: Beeguard2Api,
    private readonly _dialogs: DialogsService,
    private readonly _zohoAuthService: ZohoAuthService,
    private readonly _translateService: TranslateService,
    private readonly _zohoDeskApiService: ZohoDeskApiService
  ) {
    this._device_affectations_sub = this.device$$
      .pipe(
        waitForNotNilValue(),
        switchMap(device => device?.affectation_history$$)
      )
      .subscribe({
        next: affectations => (this.affectations.value = affectations),
      });
  }

  ngAfterViewInit(): void {
    this.load_new_event_range(DateTime.now().endOf('day'));
  }

  ngOnDestroy(): void {
    this._device_affectations_sub?.unsubscribe();

    const events$$ = this.ranged_events.value.map(ranged_event => ranged_event.events$$);
    combineLatest(events$$)
      .pipe(
        take(1),
        map(events => flatten(events))
      )
      .subscribe({
        next: events => {
          events.map(event => event?.destroy());
          this.ranged_events.value.forEach(ranged_event => ranged_event?.events_sub?.unsubscribe());
        },
      });
  }

  // #endregion

  // #region -> (configuration)

  /** */
  protected which_event_is_displayed = new SimpleSetterGetter<DisplayedEvents>(['*']);

  @Input()
  public set what_to_display(what_to_display: DisplayedEvents) {
    this.which_event_is_displayed.value = what_to_display;
  }

  // #endregion

  // #region -> (internal configuration)

  /** */
  private fetch_every_x_month = 6;

  /** */
  private max_fetchable_months_if_nothing = 12;

  // #endregion

  /** */
  public is_connected_to_zoho$$ = this._zohoAuthService.is_authenticated$$;

  // #region -> (loading)

  /** */
  private is_loading_device = new SimpleSetterGetter(true);

  /** */
  private is_loading_affectations = new SimpleSetterGetter(true);

  /** */
  private is_loading_ack_configs_history = new SimpleSetterGetter(true);

  /** */
  private is_loading_tickets = new SimpleSetterGetter(true);

  /** */
  private is_loading_supports = new SimpleSetterGetter(true);

  /** */
  public is_loading$$ = anyTrue(
    this.is_loading_device.value$$,
    this.is_loading_affectations.value$$,
    this.is_loading_tickets.value$$,
    this.is_loading_supports.value$$,
    this.is_loading_ack_configs_history.value$$
  );

  // #endregion

  // #region -> (device)

  /** */
  @Input()
  public set device(device: DRDevice) {
    this._device$$.next(device);
  }

  /** */
  private _device$$ = new BehaviorSubject<DRDevice>(null);

  /** */
  public device$$ = this._device$$.asObservable().pipe(
    tap(device => (this.is_loading_device.value = isNil(device))),
    replay()
  );

  // #endregion

  // #region -> (tickets history)

  /** */
  private _show_off_topics_tickets$$ = new BehaviorSubject(false);

  /** */
  public show_off_topics_tickets$$ = this._show_off_topics_tickets$$.asObservable().pipe(distinctUntilRealChanged(), replay());

  /** */
  public set show_off_topics_tickets(show_off_topics_tickets: boolean) {
    this._show_off_topics_tickets$$.next(show_off_topics_tickets);
  }

  /** */
  private fetch_tickets_history$(device: DRDevice, start: DateTime, end: DateTime): Observable<DeviceLocalEvent[]> {
    return this.appState.is_superadmin$$.pipe(
      take(1),
      switchMap(is_superadmin => {
        if (!is_superadmin) {
          return of([]);
        }

        return this._zohoAuthService.is_authenticated$$.pipe(
          take(1),
          tap(() => (this.is_loading_tickets.value = true)),
          switchMap(is_authenticated => {
            if (isNil(device)) {
              return of([]);
            }

            if (!is_authenticated) {
              return of([]);
            }

            const request_for_v1$$ = this._zohoDeskApiService
              .search_tickets(
                { _all: `${device.name}`, modifiedTimeRange: `${start.toJSDate().toISOString()},${end.toJSDate().toISOString()}` },
                { statusType: ['Open', 'On Hold', 'Closed'], include_with_undefined_status_type: true }
              )
              .pipe(map(response => response?.data ?? []));

            const request_for_v2$$ = this._zohoDeskApiService
              .search_tickets(
                {
                  customField1: `cf_imeis:${device.imei}`,
                  modifiedTimeRange: `${start.toJSDate().toISOString()},${end.toJSDate().toISOString()}`,
                },
                { statusType: ['Open', 'On Hold', 'Closed'], include_with_undefined_status_type: true }
              )
              .pipe(map(response => response?.data ?? []));

            return forkJoin([request_for_v1$$, request_for_v2$$]).pipe(
              map(([tickets_v1, tickets_v2]) => {
                const ticket_ids_v2 = tickets_v2.map(ticket => ticket.ticketNumber);
                const tickets_v1_modified = (tickets_v1 ?? [])
                  ?.filter(ticket => !ticket_ids_v2.includes(ticket.ticketNumber))
                  ?.map(ticket => {
                    ticket.maybe_off_topic = true;
                    return ticket;
                  });

                return [...tickets_v1_modified, tickets_v2];
              })
            );
          }),
          map((tickets: ZohoDeskTicket[][]) => flatten(tickets)),
          map(tickets =>
            tickets.map(ticket => {
              let opened_ticket = new DeviceLocalEventDeskTicket(ticket, parseDate(ticket.createdTime));

              if (ticket.statusType === 'Closed' || !isNil(ticket?.closedTime)) {
                const closure_ticket = new DeviceLocalEventDeskTicket(ticket, parseDate(ticket.closedTime));
                return [opened_ticket, closure_ticket];
              }

              return [opened_ticket];
            })
          ),
          map(tickets => flatten(tickets)),
          catchError(() => of([])),
          replay()
        );
      })
    );
  }

  // #endregion

  // #region -> (ack configs history)

  /** */
  private fetch_ack_configs_history$(device: DRDevice, start: DateTime, end: DateTime): Observable<DeviceLocalEvent[]> {
    if (isNil(device)) {
      return of([]);
    }

    return this.appState.is_superadmin$$.pipe(
      take(1),
      switchMap(is_superadmin => {
        if (!is_superadmin) {
          return of([]);
        }

        return device?.fetch_configuration_history$(start.toJSDate(), end.toJSDate()).pipe(
          tap(() => (this.is_loading_ack_configs_history.value = true)),
          switchMap(ack_configs => {
            if (ack_configs.length === 0) {
              return of([]);
            }

            return of(ack_configs);
          }),
          map(history => history.map(ack_conf => new DeviceLocalEventAckConf(ack_conf))),
          replay()
        );
      })
    );
  }

  // #endregion

  // #region -> (affectations history)

  /** */
  private affectations = new SimpleSetterGetter<DeviceAffectationTimeSegment[]>(null);

  /** */
  protected show_only_affectated_exploitations = new SimpleSetterGetter(false);

  /** */
  private _already_fetch_affectation_event_ids: number[] = [];

  /** */
  private fetch_affectations_history$(device: DRDevice, range__start: DateTime, range__end: DateTime): Observable<DeviceLocalEvent[]> {
    if (isNil(device)) {
      return of([]);
    }

    this.LOGGER.debug('Searching affectations in range: ', { start: range__start.toString(), end: range__end.toString() });

    return this.affectations.value$$.pipe(
      waitForNotNilValue(),
      take(1),
      tap(() => (this.is_loading_affectations.value = true)),
      map(affectations => {
        if ((affectations?.length ?? []) === 0) {
          return [];
        }

        return affectations.filter(affectation => !isNil(affectation?.event_id) && !isNil(affectation?.start));
      }),
      tap(affectations => this.LOGGER.debug('searchable affectations : ', affectations)),
      map(affectations =>
        affectations.filter(affectation => {
          const affectation_start = parseDate(affectation?.start);
          const affectation_end = parseDate(affectation?.end);

          const start_in_range =
            affectation_start.getTime() >= range__start?.toJSDate()?.getTime() &&
            affectation_start.getTime() <= range__end?.toJSDate()?.getTime();

          // This is the current affectation
          if (isNil(affectation_end)) {
            return start_in_range;
          }

          const end_in_range =
            affectation_end.getTime() >= range__start?.toJSDate()?.getTime() &&
            affectation_end.getTime() <= range__end?.toJSDate()?.getTime();

          return start_in_range || end_in_range;
        })
      ),
      map(affectation_history => {
        if (affectation_history.length === 0) {
          return [];
        }

        return affectation_history
          .filter(affectation => !this._already_fetch_affectation_event_ids.includes(affectation.event_id))
          .map(affectation => {
            this._already_fetch_affectation_event_ids.push(affectation?.event_id);
            return new DeviceLocalEventAffectation(affectation, this._bg2Api, this._translateService);
          });
      }),
      replay()
    );
  }

  // #endregion

  // #region -> (supports history)

  /** */
  private fetch_supports_history$(device: DRDevice, start: DateTime, end: DateTime): Observable<DeviceLocalEvent[]> {
    this.is_loading_supports.value = true;

    if (isNil(device)) {
      return of([]);
    }

    return device?.fetch_supports_history$(start.toJSDate(), end.toJSDate()).pipe(
      map(supports_history => supports_history?.supports),
      map(supports =>
        supports.map(support => {
          let opened_support = new DeviceLocalEventSupport(support, parseDate(support.start_time));

          if (!support.is_open) {
            const closure_ticket = new DeviceLocalEventSupport(support, parseDate(support.end_time));
            return [opened_support, closure_ticket];
          }

          return [opened_support];
        })
      ),
      map(supports => flatten(supports)),
      catchError(() => of([])),
      replay()
    );
  }

  // #endregion

  // #region -> (device events)

  /** */

  /** */
  // public has_events$$ = this.events$$.pipe(
  //   map(events => events?.length > 0),
  //   replay()
  // );

  /** */
  private months_without_events = 0;

  /** */
  protected ranged_events = new SimpleSetterGetter<
    { start: DateTime; end: DateTime; events_sub: Subscription; events$$: Observable<DeviceLocalEvent[]> }[]
  >([]);

  /** */
  public loaded_events_until_date$$ = this.ranged_events.value$$.pipe(
    map(value => last(value)),
    map(composed => composed?.start),
    map(date => date?.toJSDate()),
    replay()
  );

  /** */
  private _apply_filters_to_local_events$$(events: DeviceLocalEvent[]) {
    const filters$$ = {
      show_off_topic_tickets: this.show_off_topics_tickets$$,
      show_only_affectated_exploitations: this.show_only_affectated_exploitations.value$$,
    };

    return combineLatest(filters$$).pipe(
      waitForNotNilProperties(),
      switchMap((filters: DeviceHistoryFilters) => {
        const filtered_events$$ = events.map(event =>
          event.apply_filter$$(filters).pipe(map(should_keep_event => (should_keep_event ? event : null)))
        );

        return robustCombineLatest(filtered_events$$);
      }),
      map(kept_events => kept_events?.filter(kept_event => !isNil(kept_event)))
    );
  }

  /** */
  private merged_ranged_events$$ = this.ranged_events.value$$.pipe(
    filter(value => value.length !== 0),
    debounceTime(100),
    switchMap(ranged_events => {
      const all_events$$ = ranged_events.map(rng => rng.events$$);

      return robustCombineLatest(all_events$$).pipe(
        map(events_by_range => {
          const last_events = last(events_by_range);
          this.LOGGER.debug(`Found ${last_events.length} events, currently ${this.months_without_events} months`);

          if ((last_events ?? []).length === 0) {
            if (this.months_without_events < this.max_fetchable_months_if_nothing / this.fetch_every_x_month) {
              this.load_new_event_range();
              this.months_without_events = this.months_without_events + 1;

              return null;
            }
          }

          this.months_without_events = 0;
          return events_by_range;
        })
      );
    }),
    waitForNotNilValue(),
    map(events_by_range => flatten(events_by_range)),
    switchMap(events => this._apply_filters_to_local_events$$(events)),
    replay()
  );

  // #endregion

  /** */
  public has_events$$ = this.merged_ranged_events$$.pipe(
    map(events => events?.length > 0),
    replay()
  );

  /** */
  private grouped_events_by_year_and_month$$: Observable<ReadonlyMap<string, ReadonlyMap<string, DeviceLocalEvent[]>>> =
    this.merged_ranged_events$$.pipe(
      map(events => events.sort((a, b) => b.date.getTime() - a.date.getTime())),
      map(events =>
        events.reduce((final, current, index, array) => {
          const year = current.date.getFullYear();
          const month = current.date.getMonth() + 1;

          const previous_year = array?.[index - 1]?.date?.getFullYear() ?? null;

          if (final.has(year.toString())) {
            const events_by_month_for_year = final.get(year.toString());

            if (events_by_month_for_year.has(month.toString())) {
              const _v = events_by_month_for_year.get(month.toString());
              _v.push(current);
              events_by_month_for_year.set(month.toString(), _v);
            } else {
              events_by_month_for_year.set(month.toString(), [current]);
            }

            final.set(year.toString(), events_by_month_for_year);
          } else {
            if (!isNil(previous_year) && previous_year !== year) {
              const diff = Math.abs(year - previous_year) - 1;

              if (diff > 0) {
                range(1, diff + 1).forEach(value => {
                  final.set((previous_year - value).toString(), new Map<string, DeviceLocalEvent[]>());
                });
              }
            }

            const new_month = new Map<string, DeviceLocalEvent[]>();
            new_month.set(month.toString(), [current]);
            final.set(year.toString(), new_month);
          }

          return final;
        }, new Map<string, Map<string, DeviceLocalEvent[]>>())
      ),
      tap(() => {
        this.is_loading_tickets.value = false;
        this.is_loading_supports.value = false;
        this.is_loading_affectations.value = false;
        this.is_loading_ack_configs_history.value = false;
      }),
      replay()
    );

  /** */
  public device_history$$ = this.grouped_events_by_year_and_month$$.pipe(
    map(merged_events_by_date => {
      const flattened: ({ type: 'year' | 'month'; value: string } | { type: 'event'; value: DeviceLocalEvent })[] = [];

      merged_events_by_date.forEach((value, year) => {
        flattened.push({ type: 'year', value: year });

        value.forEach((events, month) => {
          flattened.push({ type: 'month', value: month });
          events.map(event => flattened.push({ type: 'event', value: event }));
        });
      });

      return flattened;
    }),
    replay()
  );

  /** */
  private _watch_events_for_range$(start: DateTime, end: DateTime): Observable<DeviceLocalEvent[]> {
    const watch_event_for_range$$ = combineLatest({
      device: this.device$$,
      which_event_is_displayed: this.which_event_is_displayed.value$$,
    }).pipe(
      waitForNotNilProperties(),
      switchMap(({ device, which_event_is_displayed }) => {
        const should_include_all = which_event_is_displayed.includes('*');

        return combineLatest({
          // Desk tickets history
          tickets_history: (should_include_all || which_event_is_displayed.includes('tickets')
            ? this.fetch_tickets_history$(device, start, end)
            : of([])
          )
            .pipe
            // take(1)
            // tap(tickets_history => console.log({ tickets_history }))
            (),

          // Ack config history
          ack_configs_history: (should_include_all || which_event_is_displayed.includes('ack_configs')
            ? this.fetch_ack_configs_history$(device, start, end)
            : of([])
          )
            .pipe
            // take(1)
            // tap(ack_configs_history => console.log({ ack_configs_history }))
            (),

          // Affectaation history
          affectation_history: (should_include_all || which_event_is_displayed.includes('affectations')
            ? this.fetch_affectations_history$(device, start, end)
            : of([])
          )
            .pipe
            // take(1)
            // tap(affectation_history => console.log({ affectation_history }))
            (),

          // Supports history
          supports_history: (should_include_all || which_event_is_displayed.includes('supports')
            ? this.fetch_supports_history$(device, start, end)
            : of([])
          )
            .pipe
            // take(1)
            // tap(supports_history => console.log({ supports_history }))
            (),
        });
      }),
      map(({ ack_configs_history, affectation_history, supports_history, tickets_history }) => [
        ...tickets_history,
        ...supports_history,
        ...ack_configs_history,
        ...affectation_history,
      ]),
      map(events => flatten(events)),
      replay()
    );

    return watch_event_for_range$$;
  }

  /** */
  public load_new_event_range(initial_date: DateTime = undefined): void {
    let end = initial_date;

    const previous_ranged_events = this.ranged_events.value;
    const last_range_start = last(previous_ranged_events)?.start ?? null;
    if (isNil(end)) {
      end = last_range_start;
    }

    const start = end.minus({ months: this.fetch_every_x_month }).startOf('month');

    const local_events$$ = this._watch_events_for_range$(start, end);
    previous_ranged_events.push({ start, end, events_sub: local_events$$.subscribe(), events$$: local_events$$ });

    this.ranged_events.value = previous_ranged_events;
  }

  // #region -> (event helpers)

  /** */
  public show_ack_conf_in_dialog(ack_config: any): void {
    this._dialogs
      .customizable({
        body: {
          styles: {
            textAlign: 'left',
          },
          type: 'json-data',
          content: ack_config,
        },
        footer: {
          styles: {},
          buttons: {
            styles: {},
            items: [
              {
                type: 'button',
                color: 'transparent',
                content: i18n<string>('ALL.COMMON.Close'),
                result: true,
                styles: {},
              },
            ],
          },
        },
      })
      .subscribe();
  }

  public as_type(event: any, type: 'abstract'): DeviceLocalEvent;
  public as_type(event: any, type: 'ack-conf'): DeviceLocalEventAckConf;
  public as_type(event: any, type: 'ticket'): DeviceLocalEventDeskTicket;
  public as_type(event: any, type: 'abstract' | 'ticket' | 'ack-conf'): DeviceLocalEvent {
    if (type === 'ticket' && event instanceof DeviceLocalEventDeskTicket) {
      return event;
    }

    if (type === 'ack-conf' && event instanceof DeviceLocalEventAckConf) {
      return event;
    }

    return event;
  }

  // #endregion
}
