import { DivIcon, Icon, IconOptions, divIcon, Util, PolylineOptions, Polygon, latLng, LatLngExpression } from 'leaflet';

import { isNil } from 'lodash-es';

import { Location } from 'app/models/entities';

import { drawPolygonCircle } from 'app/misc/tools/geomap';

import { AbstractMarker } from './AbstractMarker.model';
import { MapMarkerType } from '../../enumerators/MapMarkerType.enum';

export const locationMarker = (location: Location, is_approximative = false): LocationMarker =>
  new LocationMarker(location, is_approximative);

interface BulkLocationMarkerConfig {
  has_apiary: boolean;
  is_archived: boolean;
  is_approximative: boolean;
}

export class LocationMarker extends AbstractMarker {
  public location: Location = null;
  protected is_approximative = false;

  constructor(location: Location, is_approximative = false) {
    super(latLng(location?.position?.latitude, location?.position?.longitude, location?.position?.elevation));

    // Updates marker properties
    this.location = location;
    this.is_approximative = is_approximative;
    this.options.icon = this.buildMarker();

    if (!isNil(this.location)) {
      this.type = this.location.isArchived() ? MapMarkerType.LOCATION_ARCHIVED : MapMarkerType.LOCATION;
      this.circle = this.buildCircle(3000, {});
    } else {
      this.type = MapMarkerType.LOCATION;
    }
  }

  protected buildMarker(): DivIcon | Icon<IconOptions> {
    if (this.is_approximative) {
      return divIcon({
        className: 'marker-location-approximative',
        html: Util.template('<img src="assets/img/views/common/maps/location_approx_marker.svg" />', {}),
        iconSize: [38.17, 38.17], // size of the icon
        iconAnchor: [38.17 / 2, 38.17 / 2], // point of the icon which will correspond to marker's location
      });
    }

    return divIcon({
      className: 'marker-location',
      html: Util.template(
        `<img src="assets/img/views/common/maps/location_${
          this.location && (this.location.isArchived() || !this.location.hasApiary()) ? 'archived_' : ''
        }marker.svg" />`,
        {}
      ),
      iconSize: [29.16, 41.64], // size of the icon
      // shadowSize: [50, 64], // size of the shadow
      iconAnchor: [29.16 / 2, 41.64], // point of the icon which will correspond to marker's location
      // shadowAnchor: [4, 62],  // the same for the shadow
      popupAnchor: [0, -36], // point from which the popup should open relative to the iconAnchor
    });
  }

  protected buildCircle(radius: number, options: PolylineOptions): Polygon<any> {
    return drawPolygonCircle(this.getLatLng(), radius, options);
  }

  public setLatLng(latlng: LatLngExpression): this {
    return super.setLatLng(latlng);
  }
}

export class BulkLocationMarker extends LocationMarker {
  private _bulk_config: BulkLocationMarkerConfig = null;

  constructor(location: Location, config: BulkLocationMarkerConfig) {
    super(location, config.is_approximative);
    this._bulk_config = config;

    // Updates marker properties
    console.log(this._bulk_config)
    this.is_approximative = this._bulk_config.is_approximative;
    this.options.icon = this.buildMarker();

    this.type = this._bulk_config.is_archived ? MapMarkerType.LOCATION_ARCHIVED : MapMarkerType.LOCATION;
    this.circle = this.buildCircle(3000, {});
  }

  protected buildMarker(): DivIcon | Icon<IconOptions> {
    if (this.is_approximative) {
      return divIcon({
        className: 'marker-location-approximative',
        html: Util.template('<img src="assets/img/views/common/maps/location_approx_marker.svg" />', {}),
        iconSize: [38.17, 38.17], // size of the icon
        iconAnchor: [38.17 / 2, 38.17 / 2], // point of the icon which will correspond to marker's location
      });
    }

    return divIcon({
      className: 'marker-location',
      html: Util.template(
        `<img src="assets/img/views/common/maps/location_${
          this._bulk_config?.is_archived || !this._bulk_config?.has_apiary ? 'archived_' : ''
        }marker.svg" />`,
        {}
      ),
      iconSize: [29.16, 41.64], // size of the icon
      // shadowSize: [50, 64], // size of the shadow
      iconAnchor: [29.16 / 2, 41.64], // point of the icon which will correspond to marker's location
      // shadowAnchor: [4, 62],  // the same for the shadow
      popupAnchor: [0, -36], // point from which the popup should open relative to the iconAnchor
    });
  }

  protected buildCircle(radius: number, options: PolylineOptions): Polygon<any> {
    return drawPolygonCircle(this.getLatLng(), radius, options);
  }

  public setLatLng(latlng: LatLngExpression): this {
    return super.setLatLng(latlng);
  }
}
