import { NgZone } from '@angular/core';

import { isNil } from 'lodash-es';
import { MtxSelectComponent } from '@ng-matero/extensions/select';

import { filter, fromEvent, merge, Subject, withLatestFrom } from 'rxjs';

import { SimpleSetterGetter } from 'app/models';
import { distinctUntilRealChanged } from '../../rxjs';

/** */
export class FullscreenSelectHelper {
  // #region -> (model basics)

  /** */
  constructor(private _zone: NgZone) {
    this._zone?.runOutsideAngular(() => {
      merge(
        fromEvent(document, 'mousedown', { capture: true }),
        fromEvent(document, 'touchstart', { capture: true }),
        fromEvent(document, 'keydown').pipe(filter((keydown: KeyboardEvent) => keydown.key === 'Escape'))
      )
        .pipe(
          withLatestFrom(this.is_open_in_fullscreen.value$$.pipe(distinctUntilRealChanged())),
          filter(([event, is_open_in_fullscreen]) => is_open_in_fullscreen)
        )
        .subscribe({
          next: ([event, is_open_in_fullscreen]) => {
            if (is_open_in_fullscreen) {
              event?.stopPropagation();
              event?.stopImmediatePropagation();

              if (event instanceof KeyboardEvent) {
                event?.preventDefault();
                this.is_open_in_fullscreen.value = false;

                if (isNil(this.select_ref)) {
                  throw new Error('Missing reference to the selector !');
                } else {
                  this.select_ref.value.close();
                }
              }
            }
          },
        });
    });
  }

  /** */
  public destroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }

  // #endregion

  /** */
  public open(): void {
    this.is_open_in_fullscreen.value = true;
  }

  /** */
  public close(with_select = false): void {
    this.is_open_in_fullscreen.value = false;

    if (with_select) {
      this.select_ref.value.close();
    }
  }

  // #region -> (click management)

  private readonly _destroy$ = new Subject<void>();

  // #endregion

  public select_ref = new SimpleSetterGetter<MtxSelectComponent>(null);

  /** */
  public is_open_in_fullscreen = new SimpleSetterGetter(false);
}
