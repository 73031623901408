import { NgZone } from '@angular/core';

import { BehaviorSubject } from 'rxjs';
import { distinctUntilRealChanged, waitForNotNilValue } from '@bg2app/tools/rxjs';

import * as d3 from 'd3';
import { isSameDay } from 'date-fns/esm';
import { isEmpty, isNil, max, maxBy, min, uniqueId } from 'lodash-es';
import { marker as i18n } from '@biesbjerg/ngx-translate-extract-marker';

import { AppStateService } from 'app/core/app-state.service';
import { ConsoleLoggerService } from 'app/core/console-logger.service';
import { D3SharedCursorService } from 'app/core/services/global/d3-shared-cursor.service';

import {
  AbstractD3Axes,
  AbstractD3Grid,
  AbstractD3Labels,
  AbstractD3Scales,
  D3SvgBuilderLuxon,
  AbstractUserEvents,
  AbstractDataObjects,
  AbstractEventObjects,
} from '@bg2app/models/charts';

import { BatChange } from 'app/core/api-swagger/device';
import { BatteryVoltageDataPoint } from 'app/models/data';
import { BatterySparklineData } from 'app/models/devices/interfaces/battery-sparkline-data.interface';

/** */
interface Axes extends AbstractD3Axes {
  /**
   * Defines a dictionnary to hold the informations about baattery voltage.
   */
  vbat: {
    /**
     * Reference to the d3-axis generator for the pressure values.
     */
    axis: d3.Axis<d3.AxisDomain>;

    /**
     * Reference to the container for the pressure axis.
     */
    container: d3.Selection<SVGGElement, unknown, HTMLElement, any>;
  };
}

/** */
interface Scales extends AbstractD3Scales {
  /**
   * Reference to linear scale for the battery voltage value.
   */
  vbat: d3.ScaleLinear<number, number, never>;
}

/** */
interface DataObjects extends AbstractDataObjects {
  /** */
  // vbat: {
  //   /** */
  //   line_before_change_not_nil: {
  //     /** */
  //     shape: d3.Line<BatteryVoltageDataPoint>;

  //     /** */
  //     path: d3.Selection<SVGPathElement, unknown, HTMLElement, any>;
  //   };

  //   /** */
  //   area_before_change_not_nil: {
  //     /** */
  //     shape: d3.Area<BatteryVoltageDataPoint>;

  //     /** */
  //     path: d3.Selection<SVGPathElement, unknown, HTMLElement, any>;
  //   };

  //   /** */
  //   line_before_change_nil: {
  //     /** */
  //     shape: d3.Line<BatteryVoltageDataPoint>;

  //     /** */
  //     path: d3.Selection<SVGPathElement, unknown, HTMLElement, any>;
  //   };

  //   /** */
  //   area_before_change_nil: {
  //     /** */
  //     shape: d3.Area<BatteryVoltageDataPoint>;

  //     /** */
  //     path: d3.Selection<SVGPathElement, unknown, HTMLElement, any>;
  //   };

  //   /** */
  //   line_after_change_not_nil: {
  //     /** */
  //     shape: d3.Line<BatteryVoltageDataPoint>;

  //     /** */
  //     path: d3.Selection<SVGPathElement, unknown, HTMLElement, any>;
  //   };

  //   /** */
  //   area_after_change_not_nil: {
  //     /** */
  //     shape: d3.Area<BatteryVoltageDataPoint>;

  //     /** */
  //     path: d3.Selection<SVGPathElement, unknown, HTMLElement, any>;
  //   };

  //   line_after_change_nil: {
  //     /** */
  //     shape: d3.Line<BatteryVoltageDataPoint>;

  //     /** */
  //     path: d3.Selection<SVGPathElement, unknown, HTMLElement, any>;
  //   };

  //   area_after_change_nil: {
  //     /** */
  //     shape: d3.Area<BatteryVoltageDataPoint>;

  //     /** */
  //     path: d3.Selection<SVGPathElement, unknown, HTMLElement, any>;
  //   };

  //   /** */
  //   circle: d3.Selection<SVGCircleElement, unknown, HTMLElement, any>;
  // };

  vbat_calib: {
    /** */
    line_before_change_not_nil: {
      /** */
      shape: d3.Line<BatteryVoltageDataPoint>;

      /** */
      path: d3.Selection<SVGPathElement, unknown, HTMLElement, any>;
    };

    /** */
    area_before_change_not_nil: {
      /** */
      shape: d3.Area<BatteryVoltageDataPoint>;

      /** */
      path: d3.Selection<SVGPathElement, unknown, HTMLElement, any>;
    };

    /** */
    line_before_change_nil: {
      /** */
      shape: d3.Line<BatteryVoltageDataPoint>;

      /** */
      path: d3.Selection<SVGPathElement, unknown, HTMLElement, any>;
    };

    /** */
    area_before_change_nil: {
      /** */
      shape: d3.Area<BatteryVoltageDataPoint>;

      /** */
      path: d3.Selection<SVGPathElement, unknown, HTMLElement, any>;
    };

    /** */
    line_after_change_not_nil: {
      /** */
      shape: d3.Line<BatteryVoltageDataPoint>;

      /** */
      path: d3.Selection<SVGPathElement, unknown, HTMLElement, any>;
    };

    /** */
    area_after_change_not_nil: {
      /** */
      shape: d3.Area<BatteryVoltageDataPoint>;

      /** */
      path: d3.Selection<SVGPathElement, unknown, HTMLElement, any>;
    };

    line_after_change_nil: {
      /** */
      shape: d3.Line<BatteryVoltageDataPoint>;

      /** */
      path: d3.Selection<SVGPathElement, unknown, HTMLElement, any>;
    };

    area_after_change_nil: {
      /** */
      shape: d3.Area<BatteryVoltageDataPoint>;

      /** */
      path: d3.Selection<SVGPathElement, unknown, HTMLElement, any>;
    };

    /** */
    circle: d3.Selection<SVGCircleElement, unknown, HTMLElement, any>;
  };
}

/** */
interface Events extends AbstractUserEvents {
  /** */
  vbat: {
    /** */
    text: d3.Selection<SVGGElement, unknown, HTMLElement, any>;

    /** */
    container: d3.Selection<HTMLDivElement, unknown, HTMLElement, any>;
  };
}

/** */
interface EventObjects extends AbstractEventObjects {
  /** */
  vbat: {
    /** */
    critical: d3.Selection<SVGLineElement, unknown, HTMLElement, any>;
  };
}

interface Labels extends AbstractD3Labels {
  /** */
  labels: {};
}

/** */
export class DeviceBatterySparklineFactory extends D3SvgBuilderLuxon<BatterySparklineData> {
  // #region -> (properties)

  public axes: Axes = {
    container: null,
    time: {
      axis: null,
      container: null,
    },
    vbat: {
      axis: null,
      container: null,
    },
  };

  public scales: Scales = {
    time: null,
    vbat: null,
  };

  public data_objects: DataObjects = {
    container: null,
    // vbat: {
    //   line_before_change_not_nil: {
    //     shape: d3
    //       .line<BatteryVoltageDataPoint>()
    //       .defined(point => !isNil(point?.vbat))
    //       .curve(d3.curveLinear),
    //     path: null,
    //   },
    //   area_before_change_not_nil: {
    //     shape: d3
    //       .area<BatteryVoltageDataPoint>()
    //       .defined(point => !isNil(point?.vbat))
    //       .curve(d3.curveLinear),
    //     path: null,
    //   },

    //   line_before_change_nil: {
    //     shape: d3
    //       .line<BatteryVoltageDataPoint>()
    //       .defined(point => !isNil(point?.vbat))
    //       .curve(d3.curveLinear),
    //     path: null,
    //   },
    //   area_before_change_nil: {
    //     shape: d3
    //       .area<BatteryVoltageDataPoint>()
    //       .defined(point => !isNil(point?.vbat))
    //       .curve(d3.curveLinear),
    //     path: null,
    //   },

    //   line_after_change_not_nil: {
    //     shape: d3
    //       .line<BatteryVoltageDataPoint>()
    //       .defined(point => !isNil(point?.vbat))
    //       .curve(d3.curveLinear),
    //     path: null,
    //   },
    //   area_after_change_not_nil: {
    //     shape: d3
    //       .area<BatteryVoltageDataPoint>()
    //       .defined(point => !isNil(point?.vbat))
    //       .curve(d3.curveLinear),
    //     path: null,
    //   },

    //   line_after_change_nil: {
    //     shape: d3
    //       .line<BatteryVoltageDataPoint>()
    //       .defined(point => !isNil(point?.vbat))
    //       .curve(d3.curveLinear),
    //     path: null,
    //   },
    //   area_after_change_nil: {
    //     shape: d3
    //       .area<BatteryVoltageDataPoint>()
    //       .defined(point => !isNil(point?.vbat))
    //       .curve(d3.curveLinear),
    //     path: null,
    //   },

    //   circle: null,
    // },

    vbat_calib: {
      line_before_change_not_nil: {
        shape: d3
          .line<BatteryVoltageDataPoint>()
          .defined(point => !isNil(point?.vbat_calib))
          .curve(d3.curveLinear),
        path: null,
      },
      area_before_change_not_nil: {
        shape: d3
          .area<BatteryVoltageDataPoint>()
          .defined(point => !isNil(point?.vbat_calib))
          .curve(d3.curveLinear),
        path: null,
      },

      line_before_change_nil: {
        shape: d3
          .line<BatteryVoltageDataPoint>()
          .defined(point => !isNil(point?.vbat_calib))
          .curve(d3.curveLinear),
        path: null,
      },
      area_before_change_nil: {
        shape: d3
          .area<BatteryVoltageDataPoint>()
          .defined(point => !isNil(point?.vbat_calib))
          .curve(d3.curveLinear),
        path: null,
      },

      line_after_change_not_nil: {
        shape: d3
          .line<BatteryVoltageDataPoint>()
          .defined(point => !isNil(point?.vbat_calib))
          .curve(d3.curveLinear),
        path: null,
      },
      area_after_change_not_nil: {
        shape: d3
          .area<BatteryVoltageDataPoint>()
          .defined(point => !isNil(point?.vbat_calib))
          .curve(d3.curveLinear),
        path: null,
      },

      line_after_change_nil: {
        shape: d3
          .line<BatteryVoltageDataPoint>()
          .defined(point => !isNil(point?.vbat_calib))
          .curve(d3.curveLinear),
        path: null,
      },
      area_after_change_nil: {
        shape: d3
          .area<BatteryVoltageDataPoint>()
          .defined(point => !isNil(point?.vbat_calib))
          .curve(d3.curveLinear),
        path: null,
      },

      circle: null,
    },
  };

  public event_objects: EventObjects = {
    container: null,
    vbat: {
      critical: null,
    },
  };

  public user_events: Events = {
    container: null,
    event_bounds: null,

    focus_line_x: null,
    focus_line_y: null,

    vbat: {
      text: null,
      container: null,
    },
  };

  public grids: AbstractD3Grid = {
    container: null,

    time: {
      axis: null,
      container: null,
    },
  };

  public labels: Labels = {
    container: null,
    labels: {
      vbat: null,
    },
  };

  // #endregion

  // #region -> (custom range mgmt)

  /** */
  private _vbat_range$$ = new BehaviorSubject<[number, number]>([0, 5]);

  /** */
  protected vbat_range$$ = this._vbat_range$$.pipe(waitForNotNilValue(), distinctUntilRealChanged());

  /** */
  public set vbat_range(range: [number, number]) {
    this._vbat_range$$.next(range);
  }

  /** */
  public get vbat_range(): [number, number] {
    return this._vbat_range$$.getValue();
  }

  // #endregion

  // #region -> (custom minimal working tension)

  /** */
  private _vbat_critical$$ = new BehaviorSubject<number>(0);

  /** */
  protected vbat_critical$$ = this._vbat_critical$$.pipe(waitForNotNilValue(), distinctUntilRealChanged());

  /** */
  public set vbat_critical(vbat_critical: number) {
    this._vbat_critical$$.next(vbat_critical);
  }

  /** */
  public get vbat_critical(): number {
    return this._vbat_critical$$.getValue();
  }

  // #endregion

  // #region -> (component basics)

  /** */
  protected colors: { [key in 'vbat' | 'vbat_before' | 'vbat_calib_before' | 'vbat_calib']: `#${string}` } = {
    /** */
    vbat: `#817ed1`,

    /** */
    vbat_before: `#c0c0c0`,

    /** */
    vbat_calib: `#817ed1`,

    /** */
    vbat_calib_before: `#c0c0c0`,
  };

  /** */
  protected LOGGER: ConsoleLoggerService = new ConsoleLoggerService('DeviceBatterySparklineFactory', true);

  /** */
  constructor(protected _shared_cursor: D3SharedCursorService, protected _appState: AppStateService, protected _ngZone: NgZone) {
    super(_shared_cursor, _appState, _ngZone);

    this.unique_id = uniqueId('d3-js-chart-device-battery-sparkline-');

    this.margins.top = 5;
    this.margins.left = 25;
    this.margins.right = 5;
    this.margins.bottom = 5;

    this.show_x_grid = false;
    this.show_day_cycle_grid = false;
  }

  /** */
  public destroy(): void {
    super.destroy();
  }

  /** */
  public get has_data() {
    const voltages = this.incoming_data?.battery_voltages ?? [];

    return !isEmpty(voltages ?? []);
  }

  // #endregion

  // #region -> (update methods)

  /** */
  protected update_axes(): void {
    super.update_axes();

    this.axes.vbat.axis.scale(this.scales.vbat).ticks(3);
    this.axes.vbat.container
      .transition()
      .duration(500)
      .call(this.axes.vbat.axis)
      .attr('transform', `translate(${this.margins.left}, ${this.margins.top})`);

    this.axes.vbat.container.selectAll('.tick').select('text').attr('fill', this.colors.vbat).attr('font-weight', 300);
  }

  // #endregion

  // #region -> (non-abstract methods)

  public resize(): void {
    // Update speed axis
    this.axes.vbat.axis.scale(this.scales.vbat);
    this.axes.vbat.container.call(this.axes.vbat.axis).attr('transform', `translate(${this.margins.left}, ${this.margins.top})`);

    super.resize();
  }

  // #endregion

  // #region -> (creation methods)

  public create_axes(): void {
    super.create_axes();

    this.axes.time.container.style('display', 'none');

    // Create voltage X axis
    this.axes.vbat.axis = d3.axisLeft(this.scales.vbat);
    this.axes.vbat.container = this.axes.container
      .append('g')
      .attr('class', 'd3-Y-axis')
      .attr('transform', `translate(${this.calc_size_of(0, ['+left'])}, ${this.calc_size_of(0, ['+top'])})`)
      .call(this.axes.vbat.axis);

    // Call at least one time the format
    this.apply_axes_format();
  }

  protected create_scales(): void {
    super.create_scales();

    this.scales.vbat = d3.scaleLinear().rangeRound([this.calc_size_of(this.box_sizing?.height, ['-top', '-bottom']), 0]);
  }

  protected create_labels(): void {
    super.create_labels();
  }

  // #endregion

  // #region -> (abstract methods)

  public build_event_objects(): void {
    // Build Y-focus line
    this.user_events.focus_line_y = this.create_focus_line('y', this.user_events.container);

    // Build tooltips
    this.user_events.vbat.container = this.create_html_tooltip(this.svg_parent);

    // Add line for minimal vbat
    this.event_objects.vbat.critical = this.event_objects.container.append('line').attr('stroke', 'red').attr('stroke-width', '2px');

    // this.data_objects.vbat.line_before_change_not_nil.path = this.data_objects.container
    //   .append('path')
    //   .attr('id', 'vbat-before-bat-change-data__line')
    //   .style('fill', 'none')
    //   .style('stroke', this.colors.vbat_before)
    //   .style('stroke-width', '2px');
    // this.data_objects.vbat.area_before_change_not_nil.path = this.data_objects.container
    //   .append('path')
    //   .attr('id', 'vbat-before-bat-change-data__area')
    //   .style('fill', this.colors.vbat_before);

    // this.data_objects.vbat.line_before_change_nil.path = this.data_objects.container
    //   .append('path')
    //   .attr('id', 'vbat-null-before-bat-change-data__line')
    //   .style('fill', 'none')
    //   .style('stroke', this.colors.vbat_before + '50')
    //   .style('stroke-width', '2px');
    // this.data_objects.vbat.area_before_change_nil.path = this.data_objects.container
    //   .append('path')
    //   .attr('id', 'vbat-null-before-bat-change-data__area')
    //   .style('fill', this.colors.vbat_before + '50');

    // this.data_objects.vbat.line_after_change_not_nil.path = this.data_objects.container
    //   .append('path')
    //   .attr('id', 'vbat-after-bat-change-data__line')
    //   .style('fill', 'none')
    //   .style('stroke', this.colors.vbat)
    //   .style('stroke-width', '2px');
    // this.data_objects.vbat.area_after_change_not_nil.path = this.data_objects.container
    //   .append('path')
    //   .attr('id', 'vbat-after-bat-change-data__area')
    //   .style('fill', this.colors.vbat);

    // this.data_objects.vbat.line_after_change_nil.path = this.data_objects.container
    //   .append('path')
    //   .attr('id', 'vbat-null-after-bat-change-data__line')
    //   .style('fill', 'none')
    //   .style('stroke', this.colors.vbat + '26')
    //   .style('stroke-width', '2px');
    // this.data_objects.vbat.area_after_change_nil.path = this.data_objects.container
    //   .append('path')
    //   .attr('id', 'vbat-null-after-bat-change-data__area')
    //   .style('fill', this.colors.vbat + '26');

    this.data_objects.vbat_calib.line_before_change_not_nil.path = this.data_objects.container
      .append('path')
      .attr('id', 'vbat_calib-before-bat-change-data__line')
      .style('fill', 'none')
      .style('stroke', this.colors.vbat_calib_before)
      .style('stroke-width', '2px');
    this.data_objects.vbat_calib.area_before_change_not_nil.path = this.data_objects.container
      .append('path')
      .attr('id', 'vbat_calib-before-bat-change-data__area')
      .style('fill', this.colors.vbat_calib_before);

    this.data_objects.vbat_calib.line_before_change_nil.path = this.data_objects.container
      .append('path')
      .attr('id', 'vbat_calib-null-before-bat-change-data__line')
      .style('fill', 'none')
      .style('stroke', this.colors.vbat_calib_before + '50')
      .style('stroke-width', '2px');
    this.data_objects.vbat_calib.area_before_change_nil.path = this.data_objects.container
      .append('path')
      .attr('id', 'vbat_calib-null-before-bat-change-data__area')
      .style('fill', this.colors.vbat_calib_before + '50');

    this.data_objects.vbat_calib.line_after_change_not_nil.path = this.data_objects.container
      .append('path')
      .attr('id', 'vbat_calib-after-bat-change-data__line')
      .style('fill', 'none')
      .style('stroke', this.colors.vbat_calib)
      .style('stroke-width', '2px');
    this.data_objects.vbat_calib.area_after_change_not_nil.path = this.data_objects.container
      .append('path')
      .attr('id', 'vbat_calib-after-bat-change-data__area')
      .style('fill', this.colors.vbat_calib + '80');

    this.data_objects.vbat_calib.line_after_change_nil.path = this.data_objects.container
      .append('path')
      .attr('id', 'vbat_calib-null-after-bat-change-data__line')
      .style('fill', 'none')
      .style('stroke', this.colors.vbat_calib + '26')
      .style('stroke-width', '2px');
    this.data_objects.vbat_calib.area_after_change_nil.path = this.data_objects.container
      .append('path')
      .attr('id', 'vbat_calib-null-after-bat-change-data__area')
      .style('fill', this.colors.vbat_calib + '26');

    // Create event circles
    // this.data_objects.vbat.circle = this.user_events.container
    //   .append('circle')
    //   .attr('r', 4)
    //   .style('display', 'none')
    //   .attr('pointer-events', 'none')
    //   .attr('class', 'd3-focus-circle')
    //   .attr('fill', '#7100cb');

    // Create event circles
    this.data_objects.vbat_calib.circle = this.user_events.container
      .append('circle')
      .attr('r', 4)
      .style('display', 'none')
      .attr('pointer-events', 'none')
      .attr('class', 'd3-focus-circle')
      .attr('fill', 'black');
  }

  /** */
  public on_mouse_enter(is_from_shared_cursor = false): void {
    super.on_mouse_enter(is_from_shared_cursor, false);

    if (!this.has_data) {
      return;
    }

    this.user_events.vbat.container.style('display', null);
    // this.data_objects.vbat.circle.style('display', null);
    this.data_objects.vbat_calib.circle.style('display', null);
  }

  /** */
  public on_mouse_move(event: MouseEvent | Date, data: BatterySparklineData, is_from_shared_cursor = false): void {
    if (!this.has_data) {
      return null;
    }

    let pointed_position_x: number = null;
    let pointed_position_y: number = null;

    if (is_from_shared_cursor) {
      pointed_position_x = this.scales.time(event as Date);
    } else {
      const source_element = (event as MouseEvent).target as SVGGElement;
      const boundings = source_element.getBoundingClientRect();

      pointed_position_x = (event as MouseEvent).clientX - boundings.left;
      pointed_position_y = (event as MouseEvent).clientY - boundings.top;
    }

    const points = data?.battery_voltages;
    const battery_changes = data?.battery_changes;

    const index = d3.bisectCenter(
      points?.map(v => v.tz_date),
      this.scales.time.invert(pointed_position_x)
    );

    const closest_point = points?.[index] ?? null;

    let possible_bat_changes_at_point: BatChange[] = [];
    if (!isNil(closest_point) && battery_changes?.length > 0) {
      possible_bat_changes_at_point = battery_changes.filter(bat_change => isSameDay(bat_change.time, closest_point.tz_date));
    }

    // if (!is_from_shared_cursor) {
    //   this._shared_cursor.shared_cursor = {
    //     date: closest_point?.date ?? null,
    //     from: this.unique_id,
    //     event_type: 'mousemove',
    //   };
    // }

    let template = this.create_tooltip_header(closest_point.tz_date);

    // Create list item (voltage)
    // template += `<div class="d3-chart-tooltip-list-item">`;

    // template += '<div class="d3-chart-tooltip-series-name">';
    // template += `<span class="mdi mdi-minus-thick" style="color:${this.colors.vbat}"></span>`;
    // template += `${this._appState.translate.instant(i18n<string>('ALL.DATA.LABELS_FULL.Voltage'))}`;
    // template += '</div>';

    // template += '<div class="d3-chart-tooltip-value">';
    // template += `${closest_point?.vbat?.toFixed(1) ?? '?'} ${this._appState.translate.instant(i18n<string>('ALL.DATA.UNITS.V'))}`;
    // template += '</div>';

    // template += '</div>';

    template += `<div class="d3-chart-tooltip-list-item">`;

    template += '<div class="d3-chart-tooltip-series-name">';
    template += `<span class="mdi mdi-minus-thick" style="color:${this.colors.vbat_calib}"></span>`;
    template += `${this._appState.translate.instant(i18n<string>('ALL.DATA.LABELS_FULL.Voltage (calibrated)'))}`;
    template += '</div>';

    template += '<div class="d3-chart-tooltip-value">';
    template += `${closest_point?.vbat_calib?.toFixed(2) ?? '?'} ${this._appState.translate.instant(i18n<string>('ALL.DATA.UNITS.V'))}`;
    template += '</div>';

    template += '</div>';

    template += '</div>';

    // Create list item (battery change)
    if (possible_bat_changes_at_point.length > 0) {
      template += `<div class="d3-chart-tooltip-list-item">`;

      template += '<div class="d3-chart-tooltip-series-name">';
      template += `<span class="mdi mdi-minus-thick" style="color:green"></span>`;
      template += `${this._appState.translate.instant(
        i18n<string>('VIEWS.DEVICES.SHARED.CHARTS.ALL.DEVICE_BATTERY_SPARKLINE.LEGEND.Battery change')
      )}`;
      template += '</div>';

      template += '<div class="d3-chart-tooltip-value">';
      // template += `detected`;
      template += '</div>';

      template += '</div>';
    }

    template += '</div>';

    // Update tooltip position
    const is_in_first_half = pointed_position_x < this.user_events.event_bounds.node().width.baseVal.value / 2;
    if (is_in_first_half) {
      this.user_events.vbat.container
        .html(template)
        .style('left', `${this.calc_size_of(pointed_position_x + 10, ['+left'])}px`)
        .style('top', `${this.user_events.event_bounds.node().getBBox().height / 2}px`);
    } else {
      this.user_events.vbat.container
        .html(template)
        .style('left', `${pointed_position_x + this.margins.left - 1 - this.user_events.vbat.container.node().clientWidth - 10}px`)
        .style('top', `${this.user_events.event_bounds.node().getBBox().height / 2}px`);
    }

    // Update the focus_line_x position
    this.user_events.focus_line_y
      .attr('x1', this.scales.time(this.scales.time.invert(pointed_position_x)))
      .attr('x2', this.scales.time(this.scales.time.invert(pointed_position_x)));

    // Update circle positions (depending on date)
    if (isNil(closest_point?.tz_date)) {
      // this.data_objects.vbat.circle.style('display', 'none');
      this.data_objects.vbat_calib.circle.style('display', 'none');
    }

    // Update vbat circle
    if (isNil(closest_point?.vbat_calib)) {
      // this.data_objects.vbat.circle.style('display', 'none');
      this.data_objects.vbat_calib.circle.style('display', 'none');
    } else {
      // this.data_objects.vbat.circle.style('display', null);
      this.data_objects.vbat_calib.circle.style('display', null);
      // this.data_objects.vbat.circle.attr(
      //   'transform',
      //   `translate(${this.scales.time(closest_point.tz_date.getTime())},${this.scales.vbat(closest_point.vbat)})`
      // );
      this.data_objects.vbat_calib.circle.attr(
        'transform',
        `translate(${this.scales.time(closest_point.tz_date.getTime())},${this.scales.vbat(closest_point.vbat_calib)})`
      );
    }
  }

  /** */
  public on_mouse_out(is_from_shared_cursor = false): void {
    super.on_mouse_out(is_from_shared_cursor);

    this.user_events.vbat.container.style('display', 'none');

    // this.data_objects.vbat.circle.style('display', 'none');
    this.data_objects.vbat_calib.circle.style('display', 'none');
  }

  /** */
  public append_data(is_after_resize: boolean): void {
    this.scales.time.domain([this.date_range.start, this.date_range.end]);

    // this.data_objects.vbat.line_before_change_not_nil.path.attr('d', '');
    this.data_objects.vbat_calib.line_before_change_not_nil.path.attr('d', '');
    // this.data_objects.vbat.area_before_change_not_nil.path.attr('d', '');
    this.data_objects.vbat_calib.area_before_change_not_nil.path.attr('d', '');

    // this.data_objects.vbat.line_before_change_nil.path.attr('d', '');
    this.data_objects.vbat_calib.line_before_change_nil.path.attr('d', '');
    // this.data_objects.vbat.area_before_change_nil.path.attr('d', '');
    this.data_objects.vbat_calib.area_before_change_nil.path.attr('d', '');

    // this.data_objects.vbat.line_after_change_not_nil.path.attr('d', '');
    this.data_objects.vbat_calib.line_after_change_not_nil.path.attr('d', '');
    // this.data_objects.vbat.area_after_change_not_nil.path.attr('d', '');
    this.data_objects.vbat_calib.area_after_change_not_nil.path.attr('d', '');

    // this.data_objects.vbat.line_after_change_nil.path.attr('d', '');
    this.data_objects.vbat_calib.line_after_change_nil.path.attr('d', '');
    // this.data_objects.vbat.area_after_change_nil.path.attr('d', '');
    this.data_objects.vbat_calib.area_after_change_nil.path.attr('d', '');

    this.event_objects.container.selectAll('g.d3-battery-changed-events-container').remove();
    this.event_objects.vbat.critical.style('visibility', 'hidden');

    if (!this.has_data) {
      this.scales.vbat.domain(this.vbat_range);
      super.append_data(is_after_resize);
      return;
    }

    const minimal_vbat = min([...this.vbat_range, this.vbat_critical]);
    const maximal_vbat = max([...this.vbat_range, this.vbat_critical]);

    this.scales.vbat.domain([minimal_vbat - 0.5, maximal_vbat + 0.5]);

    let points: BatteryVoltageDataPoint[] = this.incoming_data?.battery_voltages.filter(
      point => isNil(point?.vbat) || (point?.vbat >= this.scales.vbat.domain()[0] && point?.vbat <= this.scales.vbat.domain()[1])
    );

    points = points.map(point => {
      if (!isNil(point.vbat) && !isNil(point.temperature)) {
        point.vbat_calib = point.vbat - 0.01 * (point.temperature - 20);
      }

      return point;
    });

    const last_battery_change = maxBy(this.incoming_data?.battery_changes, 'time');

    const points_from_last_battery_change = isNil(last_battery_change)
      ? points
      : points.filter(point => point?.tz_date.getTime() >= last_battery_change?.time.getTime());
    const points_before_last_battery_change = isNil(last_battery_change)
      ? points
      : points.filter(point => point?.tz_date < last_battery_change?.time);

    // Update line (for points before last battery change)
    // this.data_objects.vbat.line_before_change_not_nil.shape
    //   .x(_point => this.scales.time(_point.tz_date))
    //   .y(_point => this.scales.vbat(_point.vbat));
    this.data_objects.vbat_calib.line_before_change_not_nil.shape
      .x(_point => this.scales.time(_point.tz_date))
      .y(_point => this.scales.vbat(_point.vbat_calib));
    // this.data_objects.vbat.line_before_change_not_nil.path.attr(
    //   'd',
    //   this.data_objects.vbat.line_before_change_not_nil.shape(points_before_last_battery_change)
    // );
    this.data_objects.vbat_calib.line_before_change_not_nil.path.attr(
      'd',
      this.data_objects.vbat_calib.line_before_change_not_nil.shape(points_before_last_battery_change)
    );

    // Update area (for points before last battery change)
    // this.data_objects.vbat.area_before_change_not_nil.shape
    //   .x(point => this.scales.time(point.tz_date))
    //   .y1(point => this.scales.vbat(point.vbat))
    //   .y0(() => this.scales.vbat(this.scales.vbat.domain()[0]));
    this.data_objects.vbat_calib.area_before_change_not_nil.shape
      .x(point => this.scales.time(point.tz_date))
      .y1(point => this.scales.vbat(point.vbat_calib))
      .y0(() => this.scales.vbat(this.scales.vbat.domain()[0]));
    // this.data_objects.vbat.area_before_change_not_nil.path.attr(
    //   'd',
    //   this.data_objects.vbat.area_before_change_not_nil.shape(points_before_last_battery_change)
    // );
    this.data_objects.vbat_calib.area_before_change_not_nil.path.attr(
      'd',
      this.data_objects.vbat_calib.area_before_change_not_nil.shape(points_before_last_battery_change)
    );

    // Update line (for points before last battery change)
    // this.data_objects.vbat.line_before_change_nil.shape
    //   .x(_point => this.scales.time(_point.tz_date))
    //   .y(_point => this.scales.vbat(_point.vbat));
    this.data_objects.vbat_calib.line_before_change_nil.shape
      .x(_point => this.scales.time(_point.tz_date))
      .y(_point => this.scales.vbat(_point.vbat_calib));
    // this.data_objects.vbat.line_before_change_nil.path.attr(
    //   'd',
    //   this.data_objects.vbat.line_before_change_nil.shape(points_before_last_battery_change.filter(p => !isNil(p?.vbat)))
    // );
    this.data_objects.vbat_calib.line_before_change_nil.path.attr(
      'd',
      this.data_objects.vbat_calib.line_before_change_nil.shape(points_before_last_battery_change.filter(p => !isNil(p?.vbat_calib)))
    );

    // Update area (for points before last battery change)
    // this.data_objects.vbat.area_before_change_nil.shape
    //   .x(point => this.scales.time(point.tz_date))
    //   .y1(point => this.scales.vbat(point.vbat))
    //   .y0(() => this.scales.vbat(this.scales.vbat.domain()[0]));
    this.data_objects.vbat_calib.area_before_change_nil.shape
      .x(point => this.scales.time(point.tz_date))
      .y1(point => this.scales.vbat(point.vbat_calib))
      .y0(() => this.scales.vbat(this.scales.vbat.domain()[0]));
    // this.data_objects.vbat.area_before_change_nil.path.attr(
    //   'd',
    //   this.data_objects.vbat.area_before_change_nil.shape(points_before_last_battery_change.filter(p => !isNil(p?.vbat)))
    // );
    this.data_objects.vbat_calib.area_before_change_nil.path.attr(
      'd',
      this.data_objects.vbat_calib.area_before_change_nil.shape(points_before_last_battery_change.filter(p => !isNil(p?.vbat_calib)))
    );

    // Update line (for points from last battery change)
    // this.data_objects.vbat.line_after_change_not_nil.shape
    //   .x(_point => this.scales.time(_point.tz_date))
    //   .y(_point => this.scales.vbat(_point.vbat));
    this.data_objects.vbat_calib.line_after_change_not_nil.shape
      .x(_point => this.scales.time(_point.tz_date))
      .y(_point => this.scales.vbat(_point.vbat_calib));
    // this.data_objects.vbat.line_after_change_not_nil.path.attr(
    //   'd',
    //   this.data_objects.vbat.line_after_change_not_nil.shape(points_from_last_battery_change)
    // );
    this.data_objects.vbat_calib.line_after_change_not_nil.path.attr(
      'd',
      this.data_objects.vbat_calib.line_after_change_not_nil.shape(points_from_last_battery_change)
    );

    // Update area (for points from last battery change)
    // this.data_objects.vbat.area_after_change_not_nil.shape
    //   .x(point => this.scales.time(point.tz_date))
    //   .y1(point => this.scales.vbat(point.vbat))
    //   .y0(() => this.scales.vbat(this.scales.vbat.domain()[0]));
    this.data_objects.vbat_calib.area_after_change_not_nil.shape
      .x(point => this.scales.time(point.tz_date))
      .y1(point => this.scales.vbat(point.vbat_calib))
      .y0(() => this.scales.vbat(this.scales.vbat.domain()[0]));
    // this.data_objects.vbat.area_after_change_not_nil.path.attr(
    //   'd',
    //   this.data_objects.vbat.area_after_change_not_nil.shape(points_from_last_battery_change)
    // );
    this.data_objects.vbat_calib.area_after_change_not_nil.path.attr(
      'd',
      this.data_objects.vbat_calib.area_after_change_not_nil.shape(points_from_last_battery_change)
    );

    // Update line (no data after last battery change)
    // this.data_objects.vbat.line_after_change_nil.shape
    //   .x(_point => this.scales.time(_point.tz_date))
    //   .y(_point => this.scales.vbat(_point.vbat));
    this.data_objects.vbat_calib.line_after_change_nil.shape
      .x(_point => this.scales.time(_point.tz_date))
      .y(_point => this.scales.vbat(_point.vbat_calib));
    // this.data_objects.vbat.line_after_change_nil.path.attr(
    //   'd',
    //   this.data_objects.vbat.line_after_change_nil.shape(points_from_last_battery_change.filter(point => !isNil(point?.vbat)))
    // );
    this.data_objects.vbat_calib.line_after_change_nil.path.attr(
      'd',
      this.data_objects.vbat_calib.line_after_change_nil.shape(points_from_last_battery_change.filter(point => !isNil(point?.vbat_calib)))
    );

    // Update area (no data after last battery change)
    // this.data_objects.vbat.area_after_change_nil.shape
    //   .x(point => this.scales.time(point.tz_date))
    //   .y1(point => this.scales.vbat(point.vbat))
    //   .y0(() => this.scales.vbat(this.scales.vbat.domain()[0]));
    this.data_objects.vbat_calib.area_after_change_nil.shape
      .x(point => this.scales.time(point.tz_date))
      .y1(point => this.scales.vbat(point.vbat_calib))
      .y0(() => this.scales.vbat(this.scales.vbat.domain()[0]));
    // this.data_objects.vbat.area_after_change_nil.path.attr(
    //   'd',
    //   this.data_objects.vbat.area_after_change_nil.shape(points_from_last_battery_change.filter(p => !isNil(p?.vbat)))
    // );
    this.data_objects.vbat_calib.area_after_change_nil.path.attr(
      'd',
      this.data_objects.vbat_calib.area_after_change_nil.shape(points_from_last_battery_change.filter(p => !isNil(p?.vbat_calib)))
    );

    // Displays battery changes
    this.incoming_data?.battery_changes
      .filter(point => !isNil(point?.time))
      .forEach(battery_change => {
        const battery_changed_container = this.event_objects.container.append('g').attr('class', 'd3-battery-changed-events-container');

        battery_changed_container
          .append('line')
          .attr('x1', this.scales.time(battery_change?.time))
          .attr('y1', this.scales.vbat.range()[1])
          .attr('x2', this.scales.time(battery_change?.time))
          .attr('y2', this.scales.vbat.range()[0])
          .attr('stroke', 'green')
          .attr('stroke-width', '2px');
      });

    // Displays minimal voltage
    if (
      !isNil(this.vbat_critical) &&
      this.vbat_critical >= this.scales.vbat.domain()[0] &&
      this.vbat_critical <= this.scales.vbat.domain()[1]
    ) {
      this.event_objects.vbat.critical
        .style('visibility', 'initial')
        .attr('x1', this.scales.time.range()[0])
        .attr('y1', this.scales.vbat(this.vbat_critical))
        .attr('x2', this.scales.time.range()[1])
        .attr('y2', this.scales.vbat(this.vbat_critical));
    }

    super.append_data(is_after_resize);
  }

  // #endregion

  // #region -> (external chart events)

  protected on_language_updated(): void {
    super.on_language_updated();
  }

  // #endregion
}
