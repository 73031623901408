import { Component, ChangeDetectionStrategy } from '@angular/core';

import { Observable, combineLatest, BehaviorSubject } from 'rxjs';
import { concat, debounceTime, distinctUntilChanged, filter, map, switchMap, take, tap } from 'rxjs';

import { isNil, cloneDeep, forIn } from 'lodash-es';

import { TranslateService } from '@ngx-translate/core';
import { marker as i18n } from '@biesbjerg/ngx-translate-extract-marker';

import { DRDevice } from 'app/models';

import { DialogsService } from 'app/widgets/dialogs-modals';
import { AbstractDialogComponent } from 'app/widgets/dialogs-modals/abstract-dialog.component';
import { distinctUntilRealChanged, replay } from '@bg2app/tools/rxjs';
import { DeviceApi } from 'app/core';
import { ConsoleLoggerService } from 'app/core/console-logger.service';
import { ISchema } from 'ngx-schema-form';

import { DevicesDialogParams } from '../../devices-dialog-params';

@Component({
  selector: 'bg2-devices-bulk-config-dialog',
  templateUrl: './devices-bulk-config.dialog.html',
  styleUrls: ['./devices-bulk-config.dialog.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DevicesBulkConfigDialogComponent extends AbstractDialogComponent<DevicesDialogParams, any> {
  // #region -> (dialog basics)

  /** */
  protected _logger = new ConsoleLoggerService('DevicesBulkConfigDialogComponent', true);

  /** */
  constructor(private device_api: DeviceApi, private _translate: TranslateService, private _dialogs: DialogsService) {
    super();
  }

  // #endregion

  DEFAULT_SCHEMA = {
    type: 'object',
    required: ['devices', 'configuration'],
    properties: {
      devices: {
        label: i18n('VIEWS.DEVICES.OTA-DIALOG.Selected devices'),
        type: 'array',
        widget: 'ng-mat-select',
        options: {
          multiple: true,
        },
        minItems: 1,
        default: [] as any[],
        items: {
          type: 'number',
          oneOf: [] as any[],
        },
      },
      fields: {
        label: i18n('VIEWS.DEVICES.CONF-DIALOG.Fields to set'),
        type: 'array',
        options: {
          multiple: true,
        },
        widget: 'select',
        items: {
          type: 'string',
          oneOf: [] as any[],
        },
      },
      configuration: {
        label: i18n('VIEWS.DEVICES.CONF-DIALOG.Configuration'),
        type: 'object',
        properties: {},
      },
    },
  };

  public devices$$: Observable<DRDevice[]> = this.input_params$$.pipe(
    map(params => params.devices),
    replay()
  );

  private _form_model$$ = new BehaviorSubject<any>({});
  public form_model$$ = this._form_model$$.pipe(debounceTime(100), distinctUntilRealChanged(), replay());

  public schema$$ = this.devices$$.pipe(
    switchMap(devices => {
      // check one device at least,
      if (devices.length === 0) {
        return this._dialogs.alert(this._translate.instant('VIEWS.DEVICES.CONF-DIALOG.You should have selected one device at least')).pipe(
          tap(() => this.close(false)),
          map(() => ({ conf_schema: null, devices }))
        );
      }
      // check same device type
      const dtype = new Set(devices.map(dev => dev.type_raw || dev.type));
      if (dtype.size > 1) {
        return this._dialogs
          .alert(this._translate.instant('VIEWS.DEVICES.CONF-DIALOG.Selected devices have different configurations'))
          .pipe(
            tap(() => this.close(false)),
            map(() => ({ conf_schema: null, devices }))
          );
      }
      // load
      return devices[0].fetchConfigurationSchema$().pipe(map(conf_schema => ({ conf_schema, devices })));
    }),
    filter(({ conf_schema, devices }) => !isNil(conf_schema)),
    map(({ conf_schema, devices }) => {
      const schema = cloneDeep(this.DEFAULT_SCHEMA);

      // Device schema
      schema.properties.devices.items.oneOf = devices.map(device => ({
        enum: [device.imei],
        label: `${device.imei} (${device.name})`,
      }));
      schema.properties.devices.default = devices.map(device => device.imei);

      // Conf
      schema.properties.configuration = conf_schema;
      schema.properties.fields.items.oneOf = [];
      forIn(conf_schema.properties, (prop, name) => {
        if (prop.type === 'object') {
          forIn(prop.properties, (sub_prop, sub_name) => {
            schema.properties.fields.items.oneOf.push({
              enum: [`${name}.${sub_name}`],
              label: sub_prop.label || sub_prop.title || sub_name,
            });
            sub_prop.visibleIf = {
              '/fields/*': `${name}.${sub_name}`,
            };
          });
        } else {
          schema.properties.fields.items.oneOf.push({
            enum: [name],
            label: prop.label || prop.title || name,
          });
          prop.visibleIf = {
            '/fields/*': `${name}`,
          };
        }
      });
      return schema as ISchema;
    }),
    replay()
  );

  private _form_valid$$ = new BehaviorSubject<boolean>(false);
  public form_valid$$ = this._form_valid$$.pipe(distinctUntilChanged(), replay());

  private _submit_progress_total$$ = new BehaviorSubject<number>(null);
  public submit_progress_total$$ = this._submit_progress_total$$.pipe(distinctUntilChanged(), replay());

  private _submit_progress_current$$ = new BehaviorSubject<number>(null);
  public submit_progress_current$$ = this._submit_progress_current$$.pipe(distinctUntilChanged(), replay());

  private _submit_progress_current_inc() {
    this._submit_progress_current$$.next(this._submit_progress_current$$.getValue() + 1);
  }

  public submit_progress_percent$$ = combineLatest([this.submit_progress_total$$, this.submit_progress_current$$]).pipe(
    map(([total, current]) => (!total ? 0 : current / total)),
    replay()
  );

  public setFormValid(val: any): void {
    this._form_valid$$.next(val);
  }

  public onFormModelChanged(event: any) {
    this._logger.debug(event);
    this._form_model$$.next(event.value);
  }

  public logErrors(errors: any) {
    if (errors) {
      this._logger.log_error(errors);
    }
  }

  public submit(): void {
    this.form_model$$
      .pipe(
        take(1),
        switchMap(model => {
          const imeis: number[] = model.devices;

          this._submit_progress_total$$.next(imeis.length);
          this._submit_progress_current$$.next(0);

          const conf = model.configuration;

          const set_configs$$ = imeis.map(imei =>
            this.device_api.update_device_full_conf$(imei, conf).pipe(tap(() => this._submit_progress_current_inc()))
          );

          return concat(...set_configs$$);
        })
      )
      .subscribe({
        next: () => {},
        error: () => {},
        complete: () => {
          this.close(true);
        },
      });
  }

  public close(value: boolean): void {
    this.complete(value);
  }
}
