<div class="us-debugging-settings-component">
  <mat-card>
    <mat-card-header>
      <mat-card-title> {{ 'ROUTABLE_MODALS.USER_SETTINGS.CATEGORY.DEBUG.SUBGROUP_1.Title' | translate }} </mat-card-title>
    </mat-card-header>
  </mat-card>

  <mat-card>
    <mat-card-header>
      <mat-card-title> {{ 'ROUTABLE_MODALS.USER_SETTINGS.CATEGORY.DEBUG.SUBGROUP_2.Title' | translate }} </mat-card-title>
    </mat-card-header>

    <mat-card-content>
      <div class="config">
        <span class="label">
          {{ 'ROUTABLE_MODALS.USER_SETTINGS.CATEGORY.DEBUG.SUBGROUP_2.Config_1' | translate }}
          <span
            class="mdi mdi-alert-outline"
            [popper]="'ROUTABLE_MODALS.USER_SETTINGS.WARNING.This config has effect only on your current browser' | translate"
          ></span>
        </span>

        <mat-slide-toggle
          [color]="'primary'"
          (change)="debug_analytics_google = !debug_analytics_google"
          [ngModel]="apiary_data_default_view_type$$ | async"
        ></mat-slide-toggle>
      </div>
    </mat-card-content>
  </mat-card>
</div>
