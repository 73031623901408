import { ChangeDetectionStrategy, Component, OnDestroy, OnInit, Input, ViewChild } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';
import { marker as i18n } from '@biesbjerg/ngx-translate-extract-marker';

import {
  BehaviorSubject,
  catchError,
  combineLatest,
  debounce,
  debounceTime,
  forkJoin,
  interval,
  map,
  Observable,
  of,
  Subscription,
  switchMap,
  take,
  tap,
  timeout,
} from 'rxjs';
import { anyTrue, catchErrorInDialog, distinctUntilRealChanged, replay } from '@bg2app/tools/rxjs';

import { Beeguard2Api, DeviceApi } from 'app/core';

import { TableBaseComponent } from 'app/widgets/misc-widgets/table-base/table-base.component';

import { DRDevice, Paging } from 'app/models';
import { Dictionary } from 'app/typings/core/interfaces';
import { DatatableColumn, DatatableGroup } from 'app/models/misc/datatable';
import { DatatableBaseRow } from 'app/typings/datatable/interfaces/DatatableBaseRow.iface';
import { DialogsService } from 'app/widgets/dialogs-modals';
import { CompareByType } from 'app/misc/tools';
import { TableToolbarsConfig } from 'app/widgets/misc-widgets/table-toolbars/table-toolbars.component';
import { AvailableAction } from 'app/models/misc/datatable';
import { find, isNil } from 'lodash-es';
import { MtxSelectComponent } from '@ng-matero/extensions/select';
import { AppStateService } from 'app/core/app-state.service';
import { UrlParamsService } from 'app/core/url-param.service';

/** */
interface CreatedDeviceRow extends DatatableBaseRow {
  /** */
  device: DRDevice;
}

@Component({
  selector: 'bg2-last-created-devices-datatable',
  templateUrl: 'last-created-devices-datatabe.component.html',
  styleUrls: ['last-created-devices-datatable.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LastCreatedDevicesDatatableComponent extends TableBaseComponent<CreatedDeviceRow> implements OnInit, OnDestroy {
  protected get_default_page_size() {
    return this.PAGE_SIZE_OPTIONS[0];
  }

  /** */
  public readonly COLUMS_DEFINITION = <DatatableColumn<CreatedDeviceRow>[]>[
    {
      property: 'select',
      short_property: 'slct',
      label: i18n<string>('VIEWS.DEVICES.COMPONENTS.DEVICES_DATATABLE.Select'),
      displayed_by_default: true,
      not_configurable: true,
      is_sortable: false,
      is_groupable: false,
      compare_by: CompareByType.NULL,
    },
    {
      property: 'device.type',
      short_property: 'dvc_type',
      label: i18n<string>('DEVICE.ALL.COMMON.Device type'),
      displayed_by_default: true,
      not_configurable: false,
      is_sortable: false,
      is_groupable: false,
    },
    {
      property: 'device.imei',
      short_property: 'dvc_imei',
      label: i18n<string>('VIEWS.MODALS.DEVICE.IMEI'),
      displayed_by_default: true,
      not_configurable: false,
      is_sortable: false,
      is_groupable: false,
    },
    {
      property: 'device.created_at',
      short_property: 'dvc_imei',
      label: i18n<string>('ALL.COMMON.Creation date'),
      displayed_by_default: true,
      not_configurable: false,
      is_sortable: false,
      is_groupable: false,
    },
    {
      property: 'affectation.exploitation.name',
      short_property: 'dvc_aff_expl',
      label: i18n<string>('ENTITY.ALL.TYPE.exploitation'),
      displayed_by_default: true,
      not_configurable: false,
      is_sortable: false,
      is_groupable: false,
      compare_by: CompareByType.STRING,
    },
  ];

  // #region -> component basics

  /** */
  private _related_devices_sub: Subscription = null;

  constructor(
    public readonly appStateService: AppStateService,
    private _translate: TranslateService,
    private readonly _url_params: UrlParamsService,
    private readonly _device_api: DeviceApi,
    private readonly _beeguard2Api: Beeguard2Api,
    private readonly _dialogs_service: DialogsService
  ) {
    super(_translate, _url_params);
  }

  private _reload$$ = new BehaviorSubject(0);
  private reload$$ = this._reload$$.pipe(debounceTime(300));

  @Input()
  set reload(val: number) {
    this.reloadDevices();
  }

  private reloadDevices() {
    this._reload$$.next(this._reload$$.getValue() + 1);
  }

  /** */
  ngOnInit(): void {
    // Loads datatable columns
    this.all_columns = this.COLUMS_DEFINITION;

    // Subscribes to related devices data
    this._related_devices_sub = combineLatest({
      reload: this.reload$$,
      sort: of(['-cdate']),
      query: of({}),
      paging: this.query_paging$$,
    })
      .pipe(
        tap(() => (this.loading = true)),
        debounceTime(500), // Avoid to troger many requests when rapid changes occors
        tap(data => console.log('Reload devices', data)),
        switchMap(({ sort, query, paging }) => this.load_device_rows(sort, query, paging))
      )
      .subscribe({
        next: (devices: CreatedDeviceRow[]) => {
          this.error = null;

          const old_seletion = new Set(
            this.selection_model.selected.filter(row => !(row instanceof DatatableGroup)).map((row: CreatedDeviceRow) => row.device?.imei)
          );

          const new_selection = devices.filter(row => row.device?.imei && old_seletion.has(row.device.imei));

          this.selection_model.clear();
          this.selection_model.select(...new_selection);

          this.paged_data_rows = devices;

          // Update loading
          this.loading = false;
        },
        error: (error: unknown) => {
          this.error = error;
          this.loading = false;
        },
      });
  }

  ngOnDestroy(): void {
    this._related_devices_sub?.unsubscribe();
  }

  // #endregion

  // #region -> (selection management)

  @ViewChild('selectActionComponent')
  public selectActionComponent: MtxSelectComponent;

  public readonly AVAILABLE_ACTIONS: AvailableAction[] = [
    {
      type: 'delete',
      label: i18n<string>('ALL.ACTIONS.Delete'),
      handler: (params: { selected_rows: CreatedDeviceRow[] }) => {
        const imeis_to_delete = params?.selected_rows?.map(row => row.device).map(device => device.imei);
        const delete_requests$ = imeis_to_delete.map(device_imei =>
          this._device_api.delete_device$(device_imei).pipe(take(1), catchErrorInDialog(this._dialogs_service))
        );

        return this._dialogs_service
          .confirm(
            i18n<string>(
              'VIEWS.DEVICES.DIALOGS_AND_MODALS.CREATE_DEVICE_MODAL.DELETE.Are you sure you want to delete the selected devices ?'
            ),
            {
              onFalseMessage: i18n<string>('ALL.COMMON.No'),
              onTrueMessage: i18n<string>('ALL.COMMON.Yes'),
            }
          )
          .pipe(
            switchMap(has_accepted => {
              if (!has_accepted) {
                return of(null);
              }

              return forkJoin(delete_requests$);
            }),

            take(1),
            catchErrorInDialog(this._dialogs_service),
            tap(() => this.reloadDevices())
          );
      },
      only_admin: false,
    },
  ];

  public readonly TOOLBARS_CONFIG: TableToolbarsConfig = {
    selection: {
      disabled: false,
      available_actions: this.AVAILABLE_ACTIONS,
      text: i18n<string>('VIEWS.DEVICES.COMPONENTS.DEVICES_DATATABLE.I selected [total] devices and I want to'),
    },
  };

  /** */
  private _selected_action$: BehaviorSubject<string> = new BehaviorSubject(null);

  /** */
  public selected_action$$: Observable<string> = this._selected_action$.asObservable().pipe(
    tap(action => {
      if (!isNil(action)) {
        find(this.AVAILABLE_ACTIONS, (av_action: AvailableAction) => av_action.type === action)
          .handler({
            selected_rows: this.selection_model.selected,
          })
          .subscribe({
            complete: () => {
              this.selectActionComponent.ngSelect.clearModel();
              this.selectActionComponent.ngSelect.blur();
            },
          });
      }
    })
  );

  /** */
  public set_selected_action(selected_action: string): void {
    this._selected_action$.next(selected_action);
  }

  // #endregion

  // #region -> (related devices management)

  /** */
  public assert_device(device: any): DRDevice {
    return <DRDevice>device;
  }

  /** */
  private query_devices$(query: Dictionary<any>, pagination: Paging, sort: string[]) {
    return this._device_api.requestAllDevices(query, pagination, {}, sort);
  }

  /** */
  private load_device_rows(sort: string[], query: Dictionary<any>, _paging: Paging): Observable<CreatedDeviceRow[]> {
    return this.query_devices$(query, _paging, sort).pipe(
      tap(({ paging }) => {
        if (paging?.offset >= paging?.total) {
          paging.offset = 0;
        }

        this.paging = <Paging>paging; //TODO: modify api to indicate all paging fields are always present
      }),
      map(response => response?.devices),
      map((devices: DRDevice[]) => devices.map(device => <CreatedDeviceRow>{ device })),
      replay()
    );
  }

  // #endregion

  // #region -> (component loadings)

  /** */
  private _loading$$: BehaviorSubject<boolean> = new BehaviorSubject(true);

  /** */
  private set loading(val: boolean) {
    this._loading$$.next(val);
  }

  /** */
  public loading$$ = anyTrue(this._loading$$.pipe(distinctUntilRealChanged())).pipe(replay());

  // #endregion
}
