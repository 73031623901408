import { Component, OnInit, AfterViewChecked, ChangeDetectorRef, HostListener, ChangeDetectionStrategy, OnDestroy } from '@angular/core';

import { isNil } from 'lodash-es';
import { TranslateService } from '@ngx-translate/core';

import { environment } from 'environments/environment';

import { AppStateService } from 'app/core/app-state.service';
import { Beeguard2Api } from 'app/core/api/main/beeguard2-api-service';
import { marker as i18n } from '@biesbjerg/ngx-translate-extract-marker';

import { Event as ModelEvent, eventDeserialize } from 'app/models';

import { DialogsService } from 'app/widgets/dialogs-modals/dialogs.service';
import { parseDate } from 'app/misc/tools';

import { AbstractFormModalComponent } from 'app/widgets/dialogs-modals/abstract-form-modal.component';
import { ModalParams, ModalArgs } from 'app/widgets/dialogs-modals/abstract-modal.component';
import { BehaviorSubject, combineLatest, Observable, of } from 'rxjs';
import { filter, map, switchMap, take } from 'rxjs';
import { distinctUntilRealChanged, replay } from '@bg2app/tools/rxjs';
import { endOfDay, isAfter } from 'date-fns/esm';
import { ISchema } from 'ngx-schema-form';

export interface NewEventModalParams extends ModalParams {
  etype: string;
  date?: string;
  args: ModalArgs;
}

@Component({
  selector: 'bg2-new-event-modal',
  templateUrl: './new-event.modal.html',
  styleUrls: ['./new-event.modal.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NewEventModalComponent<I extends NewEventModalParams = NewEventModalParams, EventType extends ModelEvent = ModelEvent>
  extends AbstractFormModalComponent<I, EventType>
  implements OnInit, OnDestroy, AfterViewChecked
{
  // #region -> (incoming parameters)

  public input_event_type$$: Observable<string> = this.input_params$$.pipe(
    map(input_params => input_params?.etype),
    distinctUntilRealChanged(),
    replay()
  );

  public get input_event_type(): string {
    return this.input_params?.etype;
  }

  public input_event_date$$: Observable<Date | null> = this.input_params$$.pipe(
    map(input_params => {
      const input_date = input_params?.date || null;
      return !isNil(input_date) ? parseDate(input_date) : null;
    }),
    distinctUntilRealChanged(),
    replay()
  );

  // #endregion

  // #region -> (component basics)

  constructor(
    protected _bg2Api: Beeguard2Api,
    protected _dialogs: DialogsService,
    protected _cdRef: ChangeDetectorRef,
    protected _appState: AppStateService,
    protected _translate: TranslateService
  ) {
    super();
    this._logger.update_prefix('NewEventModal');
  }

  ngOnInit(): void {
    // Create a new event from modal incoming data
    combineLatest({
      type: this.input_event_type$$,
      date: this.input_event_date$$,
    })
      .pipe(take(1))
      .subscribe({
        next: ({ type, date }) => {
          const new_event = eventDeserialize(this._bg2Api, undefined, {
            type,
            date,
            data: undefined,
            apply_to: undefined,
          }) as EventType;
          this._event$$.next(new_event);
        },
      });
  }

  ngAfterViewChecked(): void {
    this._cdRef.detectChanges();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this.unsubscribe();
  }

  protected unsubscribe(): void {
    const event = this._event$$.getValue();

    if (event) {
      event.unsubscribe();
      this._event$$.next(null);
    }
  }

  // #endregion

  // #region -> (flags management)

  private _is_beta$$ = new BehaviorSubject(false);
  public is_beta$$ = this._is_beta$$.asObservable().pipe(distinctUntilRealChanged(), replay());

  public set is_beta(is_beta: boolean) {
    this._is_beta$$.next(is_beta);
  }

  // #endregion

  // #region -> (help page management)

  /**
   * Observable on the page and section of user manual.
   */
  private help_page_section$$: Observable<{ page: string; section: string }> = this.input_event_type$$.pipe(
    map(etype => {
      switch (etype) {
        case 'device_GPS_install': {
          return {
            page: 'Gps_association',
            section: '',
          };
        }

        case 'devices_WG_install': {
          return {
            page: 'Wg_association',
            section: '',
          };
        }

        case 'device_RG_install': {
          return {
            page: 'Rg_association',
            section: '',
          };
        }
      }
    }),
    distinctUntilRealChanged(),
    replay()
  );

  /**
   * Observable on the page of the user manual.
   */
  public help_page$$: Observable<string> = this.help_page_section$$.pipe(
    map(page_and_section => page_and_section?.page),
    distinctUntilRealChanged(),
    replay()
  );

  /**
   * Observable on the section of the user manual.
   */
  public help_link_section$$: Observable<string> = this.help_page_section$$.pipe(
    map(page_and_section => page_and_section?.section),
    distinctUntilRealChanged(),
    replay()
  );

  // #endregion

  // #region -> (event management)

  private _event$$ = new BehaviorSubject<EventType>(null);
  public event$$ = this._event$$.asObservable().pipe(replay());

  public get event(): EventType {
    return this._event$$.getValue();
  }

  public set event(event: EventType) {
    this._event$$.next(event);
  }

  public event_data$$: EventType['data$$'] = this.event$$.pipe(
    switchMap(event => event.data$$),
    replay()
  );

  /** */
  public event_date$$: Observable<Date> = this.event$$.pipe(
    switchMap(event => event.date$$),
    replay()
  );

  // #endregion

  // #region -> (form management)

  public setSchema(schema: ISchema): void {
    if (this.input_event_type === 'device_GPS_install' || this.input_event_type === 'device_BeeLive_install') {
      schema.properties.date.title = this._translate.instant(i18n<string>('ALL.COMMON.Date'));
      schema.properties.apply_to.properties.hive.title = this._translate.instant(i18n<string>('ENTITY.HIVE.Choice of hive'));
      schema.properties.data.properties.device.title = this._translate.instant(i18n<string>('DEVICE.ALL.COMMON.Choice of device'));
    }

    this.form_schema = schema;
  }

  protected onFormChanged(event: any): any {
    return event;
  }

  public submit(): void {
    if (this.loading) {
      return;
    }

    this.is_submitting = true;

    let agreement_pipe: Observable<boolean> = of(true);
    if (isAfter(new Date(this._event$$.getValue().date), endOfDay(new Date()))) {
      agreement_pipe = agreement_pipe.pipe(
        switchMap(last_agreement =>
          last_agreement
            ? this._dialogs.confirm(
                i18n<string>(`VIEWS.MODALS.FORM.The date you've picked is in the future. Are you sure this is the date you want to use ?`),
                {
                  onFalseMessage: i18n<string>('VIEWS.MODALS.FORM.Cancel'),
                  onTrueMessage: i18n<string>('VIEWS.MODALS.FORM.Confirm'),
                }
              )
            : of(false)
        )
      );
    }

    agreement_pipe.pipe(take(1)).subscribe({
      next: has_agreed => {
        if (has_agreed) {
          this.error = null;
          this.loading = true;
          this._submit();

          this.is_submitting = false;
        }
      },
    });
  }

  public _submit(): void {
    this.event$$
      .pipe(
        take(1),
        switchMap(event => event.save())
      )
      .subscribe({
        next: () => {
          this.loading = false;
          this.initial_loading = false;
          this.eventCreated();
        },
        error: (err: unknown) => {
          this.loading = false;
          this.initial_loading = false;
          this.handleHttpError(err);
        },
      });
  }

  protected eventCreated() {
    if (this.input_event_type === 'apiary_archive') {
      this.force_close(true);
    } else {
      this.force_close();
    }
  }

  // #endregion

  // #region -> (modal management)

  /** */
  protected handle_event_before_unload(event: BeforeUnloadEvent): void {
    if (this._event$$?.getValue()?.has_changed && this.form_ready) {
      event.returnValue = true;
    }
  }

  public get activateDailyRAZWarning(): boolean {
    return environment?.activateDailyRAZWarning;
  }

  public force_close(raz = false): void {
    super.close(this._event$$.getValue(), raz);
  }

  public close(results: EventType = null, raz = false): void {
    if (this.is_submitting || this.is_trying_to_close_modal) {
      return;
    }

    const event = this._event$$.getValue();

    if (event?.has_changed && this.form_ready) {
      this.is_trying_to_close_modal = true;
      this._dialogs
        .confirm(i18n<string>('VIEWS.MODALS.FORM.Abandon the event creation ?'), {
          onFalseMessage: i18n<string>('VIEWS.MODALS.FORM.Come back'),
          onTrueMessage: i18n<string>('VIEWS.MODALS.FORM.Close without saving'),
        })
        .subscribe(agreement => {
          this.is_trying_to_close_modal = false;

          if (agreement) {
            super.close(null, raz);
          }
        });
    } else {
      super.close(null, raz);
    }
  }

  // #endregion
}
