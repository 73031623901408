import { ChangeDetectionStrategy, Component, Input, OnDestroy } from '@angular/core';

import { BehaviorSubject, combineLatest, Subscription } from 'rxjs';
import { filter, map, switchMap, tap } from 'rxjs';

import { ConsoleLoggerService } from 'app/core/console-logger.service';
import { AbstractFilterMngt } from 'app/models/filters/filter-mngt';
import { distinctUntilRealChanged, replay } from '@bg2app/tools/rxjs';
import { isEqual } from 'lodash-es';

@Component({
  selector: 'bg2-filters-form',
  templateUrl: './filters-form.component.html',
  styleUrls: ['./filters-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FiltersFormComponent implements OnDestroy {
  private _logger = new ConsoleLoggerService();

  private form_sub: Subscription;

  private _filters$$ = new BehaviorSubject<AbstractFilterMngt<any>>(null);
  public filters$$ = this._filters$$.asObservable();

  @Input()
  public set filters(filters: AbstractFilterMngt<any>) {
    this._filters$$.next(filters);
  }

  public get filters() {
    return this._filters$$.getValue();
  }

  private _form_values$$ = new BehaviorSubject<any>({});
  public form_values$$ = this._form_values$$.pipe(distinctUntilRealChanged());

  public schema$$ = this.filters$$.pipe(
    switchMap(filters => {
      const filters_schema$$ = filters.get_schema_for(filters.only_for);
      return filters_schema$$;
    }),
    replay()
  );

  public model_values$$ = this.filters$$.pipe(switchMap(filters => filters.get_values_for(filters.only_for)));

  private last_form_value: any = null;

  public values$$ = combineLatest([this.model_values$$, this.form_values$$]).pipe(
    map(([model_values, form_values]) => ({ model_values, form_values })),
    filter(({ model_values, form_values }, index) => {
      const new_form_values = !isEqual(form_values, this.last_form_value);
      // console.log('new form value ?', new_form_values, index, this.last_form_value);
      if (new_form_values) {
        this.last_form_value = form_values;
        if (index > 0) {
          // We ignore model value here, except if this is first init
          return false;
        }
      }
      const new_model_value = !isEqual(model_values, this.last_form_value);
      // console.log('new model value ?', new_model_value);
      return new_model_value;
    }),
    map(({ model_values }) => model_values),
    // tap(val => console.log('new values from model', val)),
    replay()
  );

  public onFormChanged(event: any): void {
    this._form_values$$.next(event);
  }

  constructor() {
    this.form_sub = this.form_values$$.subscribe(values => {
      this.filters?.update(values, this.filters?.only_for);
    });
  }

  ngOnDestroy() {
    this.form_sub?.unsubscribe();
  }
}
