import { Injectable, ComponentRef, Type } from '@angular/core';
import { CanDeactivate, Params } from '@angular/router';

import { DomInjectionService } from 'app/core/dom-injection.service';
import { ConsoleLoggerService } from '../../core/console-logger.service';
import { BehaviorSubject } from 'rxjs';

import { isNil, clone } from 'lodash-es';
import { Stack } from 'app/typings/core/Stack';

import { AbstractModalComponent } from './abstract-modal.component';


export interface ModalSetup {
  type: string;
  params: Params;
  replace?: boolean;
}

@Injectable()
export class ModalsService implements CanDeactivate<any> {
  private readonly _logger = new ConsoleLoggerService(this.constructor.name, true);

  private _modal_stack_types: Stack<string> = new Stack();
  private _modal_stack_params: Stack<Params> = new Stack();
  private _opened_modal_stack: Stack<ComponentRef<AbstractModalComponent>> = new Stack();

  private _current_modal$$ = new BehaviorSubject<ModalSetup>(null);
  public current_modal$$ = this._current_modal$$.asObservable();
  // ^ foreground modal

  // pointers to each actualy open modals

  private set current_modal(type_params: ModalSetup) {
    this._current_modal$$.next(type_params);
  }

  private _has_sub_modals = false;
  private back_clicked = false;

  get current_type(): string {
    return this._current_modal$$.getValue()?.type;
  }

  get current_params(): Params {
    return this._current_modal$$.getValue()?.params;
  }

  get history_length(): number {
    return this._modal_stack_params.size();
  }

  get nb_opened_modals(): number {
    return this._opened_modal_stack.size();
  }

  get has_sub_modals(): boolean {
    return this._has_sub_modals;
  }

  constructor(private injectionService: DomInjectionService) {}

  public setBackClicked(): void {
    this.back_clicked = true;
  }

  public changeCurrentParams(params: Params): void {
    // this._current_params = _.cloneDeep(params);
    this.current_modal = {
      type: this.current_modal?.type,
      params,
      replace: true,
    };
  }

  public canDeactivate(component: any): boolean {
    // will prevent user from going back
    if (this.back_clicked) {
      this.back_clicked = false;
      // push current state again to prevent further attempts.
      history.pushState(null, null, location.href);
      return false;
    }
    return true;
  }

  private _lastComponentRef(): ComponentRef<AbstractModalComponent> {
    if (this.nb_opened_modals <= 0) {
      return null;
    }
    return this._opened_modal_stack.peek();
  }

  public last(): AbstractModalComponent {
    return this._lastComponentRef()?.instance;
  }

  public isLast(modal: AbstractModalComponent): boolean {
    return modal === this.last();
  }

  public setHasSubModals(value: boolean): void {
    this._has_sub_modals = value;
  }

  public tryCloseLastModal(raz?: boolean): void {
    this.last().close(raz);
  }

  public canDestroyLastModal(): boolean {
    const last_modal = this.last();
    return !isNil(last_modal) && last_modal.canBeClose() && !this.has_sub_modals;
  }

  public tryDestroyLastModal(raz?: boolean): void {
    if (this.canDestroyLastModal()) {
      // this._logger.debug('tryDestroyLastModal()', raz);
      this._forceCloseLast();
      if (raz) {
        this._raz();
      }
    }
  }

  public newModal(modal_class: Type<AbstractModalComponent>, modal_type: string, params: any): void {
    this.injectionService.addOverlay('modal-overlay');
    const new_modal = this.injectionService.appendComponent(modal_class, {input_params: params});
    new_modal.instance.setModalsService(this);
    this._stackNew(new_modal, modal_type, params);
  }

  private _raz(): void {
    while (this.nb_opened_modals > 0) {
      this._forceCloseLast();
    }
  }

  private _stackNew(new_modal: ComponentRef<AbstractModalComponent>, type: string, params: Params): void {
    if (this.nb_opened_modals > 0) {
      this._modal_stack_params.push(this.current_params);
      this._modal_stack_types.push(this.current_type);
    }
    this._opened_modal_stack.push(new_modal);
    this.current_modal = {
      type,
      params: clone(params),
    };
  }

  private _forceCloseLast(): void {
    this._destroyLast();
    this._popLast();
  }

  private _destroyLast(): void {
    // Clear current open modal
    const last_modal = this.last();
    if (!last_modal) {
      return;
    }
    // this._logger.debug('_destroyLast()', last_modal);
    const last_modal_ref = this._lastComponentRef();
    this.injectionService.destroyComponent(last_modal_ref);
    this.injectionService.removeOverlay('modal-overlay');
  }

  private _popLast(): void {
    if (this.nb_opened_modals > 0) {
      if (this.nb_opened_modals > 1) {
        this.current_modal = {
          type: this._modal_stack_types.pop(),
          params: this._modal_stack_params.pop(),
        };
      } else {
        this.current_modal = null;
      }
      this._opened_modal_stack.pop();
    }
  }
}
