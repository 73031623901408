<bg2-modal [fullscreen]="fullscreen || false" #modal>
  <bg2-modal-header>
    <div class="header">
      <span class="mdi mdi-shape-plus mdi-36px"></span>
      <span class="title">{{ 'VIEWS.MODALS.V1.Create device' | translate }}</span>
    </div>

    <div class="action-buttons">
      <span class="mdi mdi-close-box mdi-36px" (click)="close()"></span>
    </div>
  </bg2-modal-header>

  <bg2-modal-content>
    <div cdkDropListGroup class="move-devices-to-create">
      <div class="move-devices-to-create__container">
        <div class="move-devices-to-create__title">
          {{ 'VIEWS.DEVICES.DIALOGS_AND_MODALS.CREATE_DEVICES_MODAL.Candidate devices' | translate }}
        </div>
        <div
          class="move-devices-to-create__list"
          cdkDropList
          #candidateList="cdkDropList"
          [id]="'cdk-drop-list-candidate-devices'"
          [cdkDropListData]="candidate_devices$$ | async"
          [cdkDropListConnectedTo]="[createList]"
          (cdkDropListDropped)="drop($event)"
        >
          <div *ngIf="is_loading_candidate_devices$$ | async" class="move-devices-to-create__list__loading">
            <hex-loading></hex-loading>
          </div>

          <div class="move-devices-to-create__list__box" *ngFor="let device of candidate_devices$$ | async" cdkDrag>
            <span class="move-devices-to-create__list__box__name">
              <b>{{ 'VIEWS.MODALS.DEVICE.Device #[imei]' | translate: { imei: device?.id } }}</b>
              <span> ({{ device?.type ?? 'ALL.COMMON.Unknown' | translate }})</span>
            </span>

            <span class="move-devices-to-create__list__box__versions">
              <b>{{ 'HW' | colon }}</b>
              <span>{{ device?.hardware_version ?? '--' }}, </span>
              <b>{{ 'SW' | colon }}</b>
              <span>{{ device?.software_version ?? '--' }}</span>
            </span>

            <span class="move-devices-to-create__list__box__last_com">
              <b>{{ 'DEVICE.ALL.COMMUNICATION.last communication' | translate | colon }}</b>
              <span *ngIf="device?.time; let time; else: emptyValue">{{ time | formatDate: 'full' }}</span>
            </span>
          </div>
        </div>
      </div>

      <span class="mdi mdi-24px mdi-swap-horizontal"></span>

      <div class="move-devices-to-create__container">
        <div class="move-devices-to-create__title">
          {{ 'VIEWS.DEVICES.DIALOGS_AND_MODALS.CREATE_DEVICES_MODAL.Devices to create' | translate }}
        </div>

        <div
          class="move-devices-to-create__list"
          cdkDropList
          #createList="cdkDropList"
          [id]="'cdk-drop-list-devices-to-create'"
          [cdkDropListData]="devices_to_create$$ | async"
          [cdkDropListConnectedTo]="[candidateList]"
          (cdkDropListDropped)="drop($event)"
        >
          <div class="move-devices-to-create__list__box" *ngFor="let device of devices_to_create$$ | async" cdkDrag>
            <span class="move-devices-to-create__list__box__name">
              <b>{{ 'VIEWS.MODALS.DEVICE.Device #[imei]' | translate: { imei: device?.id } }}</b>
              <span> ({{ device?.type ?? 'ALL.COMMON.Unknown' | translate }})</span>
            </span>

            <span class="move-devices-to-create__list__box__versions">
              <b>{{ 'HW' | colon }}</b>
              <span>{{ device?.hardware_version ?? '--' }}, </span>
              <b>{{ 'SW' | colon }}</b>
              <span>{{ device?.software_version ?? '--' }}</span>
            </span>

            <span class="move-devices-to-create__list__box__last_com">
              <b>{{ 'DEVICE.ALL.COMMUNICATION.last communication' | translate | colon }}</b>
              <span *ngIf="device?.time; let time; else: emptyValue">{{ time | formatDate: 'full' }}</span>
            </span>

            <span *ngLet="device?.is_valid; let is_valid" class="move-devices-to-create__list__box__valid">
              <ng-container *ngIf="is_valid === null || is_valid === undefined">
                <ng-container *ngIf="device?.type">
                  <span class="mdi mdi-close-thick"></span>
                  <span class="move-devices-to-create__list__box__valid__message">
                    {{ 'VIEWS.DEVICES.DIALOGS_AND_MODALS.CREATE_DEVICES_MODAL.VALIDATION.Type of device to create is not selected' | translate }}
                  </span>
                </ng-container>

                <ng-container *ngIf="!device?.type">
                  <span class="mdi mdi-alert"></span>
                  <span class="move-devices-to-create__list__box__valid__message">
                    {{ 'VIEWS.DEVICES.DIALOGS_AND_MODALS.CREATE_DEVICES_MODAL.VALIDATION.Validation depends on you (imported)' | translate }}
                  </span>
                </ng-container>
              </ng-container>

              <ng-container *ngIf="is_valid === true">
                <span class="mdi mdi-check-bold"></span>
                <span class="move-devices-to-create__list__box__valid__message">
                  {{ 'VIEWS.DEVICES.DIALOGS_AND_MODALS.CREATE_DEVICES_MODAL.VALIDATION.Ready for creation' | translate }}
                </span>
              </ng-container>

              <ng-container *ngIf="is_valid === false">
                <span class="mdi mdi-close-thick"></span>
                <span class="move-devices-to-create__list__box__valid__message">
                  <ng-container *ngIf="device?.type === 'sensor'">
                    {{ 'VIEWS.DEVICES.DIALOGS_AND_MODALS.CREATE_DEVICES_MODAL.VALIDATION.Not a gateway' | translate }}
                  </ng-container>
                  
                  <ng-container *ngIf="device?.type === 'gateway'">
                    {{ 'VIEWS.DEVICES.DIALOGS_AND_MODALS.CREATE_DEVICES_MODAL.VALIDATION.Not a sensor' | translate }}
                  </ng-container>
                </span>
              </ng-container>
            </span>
          </div>
        </div>

        <bg2-button
          style="flex: 1 1 0%"
          [type]="'action'"
          [options]="{ is_thin: true }"
          [icon]="'mdi-plus-circle-outline'"
          [disabled]="is_loading_candidate_devices$$ | async"
          [message]="'VIEWS.DEVICES.DIALOGS_AND_MODALS.CREATE_DEVICES_MODAL.Import devices' | translate"
          (btn_click)="import_more_devices()"
        ></bg2-button>
      </div>
    </div>

    <bg2-form-overlay
      [source_model]="form_model$$ | async"
      [source_schema]="form_schema$$ | async"
      (when_form_data_update)="onFormChanged($event)"
      (when_form_valid_update)="update_form_validity($event)"
    ></bg2-form-overlay>

    <bg2-button
      style="flex: 1 1 0%"
      [type]="'action'"
      [icon]="'mdi-content-save'"
      [options]="{ is_thin: true }"
      [message]="'ALL.ACTIONS.Create' | translate"
      [disabled]="(form_valid$$ | async) === false || (devices_to_create_is_valid$$ | async) === false"
      (btn_click)="submit()"
    ></bg2-button>

    <mat-divider></mat-divider>

    <div class="last-created-devices">
      <div class="last-created-devices__title">
        {{ 'VIEWS.DEVICES.DIALOGS_AND_MODALS.CREATE_DEVICES_MODAL.Last created devices' | translate }}

        <span class="clickable mdi mdi-reload mdi-24px" (click)="reload_last_created_devices()"></span>
      </div>

      <bg2-last-created-devices-datatable [reload]="reload_last_created_devices$$ | async"></bg2-last-created-devices-datatable>
    </div>
  </bg2-modal-content>
</bg2-modal>

<ng-template #emptyValue>--</ng-template>
