import { Component, Input, ChangeDetectionStrategy } from '@angular/core';

import { replay, waitForNotNilValue } from '@bg2app/tools/rxjs';
import { map, switchMap, BehaviorSubject, combineLatest, Observable, concat, of, tap } from 'rxjs';

import { DeviceInterface, DRDevice, SimpleSetterGetter } from 'app/models';
import { convert_utc_hours_to_local } from '@bg2app/tools/dates';
import { isNil } from 'lodash-es';
import { AppStateService } from 'app/core/app-state.service';

@Component({
  selector: 'bg2-device-ack-next-conf',
  templateUrl: './device-ack-next-conf.component.html',
  styleUrls: ['./device-ack-next-conf.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DeviceAckNextConfComponent {
  // #region -> (component basics)

  constructor(private readonly _appStateService: AppStateService) {}

  /** */
  public is_superadmin$$ = this._appStateService.is_superadmin$$;

  // #endregion

  // #region -> (display type)

  /** */
  @Input()
  public display_type: 'device_modal_summary' | 'normal' = 'normal';

  // #endregion

  // #region -> (device)

  /** */
  private _device$$ = new BehaviorSubject<DRDevice>(null);

  /** */
  public device$$: Observable<DRDevice> = this._device$$.asObservable();

  /** */
  @Input()
  public set device(device: DRDevice) {
    this._device$$.next(device);
  }

  // #endregion

  // #region -> (device configuration)

  /** */
  protected is_loading_sconf = new SimpleSetterGetter(true);

  /** */
  public device_simplified_configuration$$ = this.device$$.pipe(
    tap(() => (this.is_loading_sconf.value = true)),
    waitForNotNilValue(),
    switchMap(device => device.sconf$$),
    tap(() => (this.is_loading_sconf.value = false)),
    replay()
  );

  /**
   * Observes if the current can have simplified configuration.
   */
  public can_have_sconf$$ = this.device$$.pipe(switchMap(device => device?.can_have_sconf$$));

  // #endregion

  // #region -> (ack conf)

  /** */
  public ack_sconf$$ = this.device_simplified_configuration$$.pipe(
    map(sconf => sconf?.ack?.sconf ?? null),
    replay()
  );

  /** */
  private ack_sconf__com$$ = this.ack_sconf$$.pipe(
    map(sconf => sconf?.com ?? null),
    replay()
  );

  /** */
  private _ack_sconf__com__hour_utc_one$$ = this.ack_sconf__com$$.pipe(
    waitForNotNilValue(),
    map(sconf_com => {
      const hour_utc_one = sconf_com?.hour_utc_one ?? null;

      if (isNil(hour_utc_one)) {
        return null;
      }

      return convert_utc_hours_to_local([hour_utc_one])[0];
    })
  );

  /** */
  private _ack_sconf__com__hour_utc_two$$ = this.ack_sconf__com$$.pipe(
    waitForNotNilValue(),
    map(sconf_com => {
      const hour_utc_two = sconf_com?.hour_utc_two ?? null;

      if (isNil(hour_utc_two)) {
        return null;
      }

      return convert_utc_hours_to_local([hour_utc_two])[0];
    })
  );

  /** */
  private _ack_sconf__com__hours$$ = combineLatest([
    concat(of(null), this._ack_sconf__com__hour_utc_one$$),
    concat(of(null), this._ack_sconf__com__hour_utc_two$$),
  ]).pipe(
    map(([hour_one, hour_two]) => {
      if (isNil(hour_two)) {
        return [hour_one, hour_two];
      }

      if (isNil(hour_one)) {
        return [hour_two, hour_one];
      }

      return hour_one < hour_two ? [hour_one, hour_two] : [hour_two, hour_one];
    })
  );

  /** */
  public ack_sconf__com__hour_utc_one$$ = this._ack_sconf__com__hours$$.pipe(
    map(hours => hours[0]),
    replay()
  );

  /** */
  public ack_sconf__com__hour_utc_two$$ = this._ack_sconf__com__hours$$.pipe(
    map(hours => hours[1]),
    replay()
  );

  // #endregion

  // #region -> (next configuration)

  /** */
  public next_sconf$$ = this.device_simplified_configuration$$.pipe(
    map(sconf => sconf?.next?.sconf ?? null),
    replay()
  );

  /** */
  private _next_sconf__com$$ = this.next_sconf$$.pipe(
    map(sconf => sconf?.com ?? null),
    replay()
  );

  /** */
  private _next_sconf__com__hour_utc_one$$ = this._next_sconf__com$$.pipe(
    waitForNotNilValue(),
    map(sconf_com => {
      const hour_utc_one = sconf_com?.hour_utc_one ?? null;

      if (isNil(hour_utc_one)) {
        return null;
      }

      return convert_utc_hours_to_local([hour_utc_one])[0];
    })
  );

  /** */
  private _next_sconf__com__hour_utc_two$$ = this._next_sconf__com$$.pipe(
    waitForNotNilValue(),
    map(sconf_com => {
      const hour_utc_two = sconf_com?.hour_utc_two ?? null;

      if (isNil(hour_utc_two)) {
        return null;
      }

      return convert_utc_hours_to_local([hour_utc_two])[0];
    })
  );

  /** */
  private _next_sconf__com__hours$$ = combineLatest([concat(of(null), this._next_sconf__com__hour_utc_one$$), concat(of(null), this._next_sconf__com__hour_utc_two$$)]).pipe(
    map(([hour_one, hour_two]) => {
      if (isNil(hour_two)) {
        return [hour_one, hour_two];
      }

      if (isNil(hour_one)) {
        return [hour_two, hour_one];
      }

      return hour_one < hour_two ? [hour_one, hour_two] : [hour_two, hour_one];
    })
  );

  /** */
  public next_sconf__com__hour_utc_one$$ = this._next_sconf__com__hours$$.pipe(
    map(hours => hours[0]),
    replay()
  );

  /** */
  public next_sconf__com__hour_utc_two$$ = this._next_sconf__com__hours$$.pipe(
    map(hours => hours[1]),
    replay()
  );

  // #endregion
}
