import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

import { marker as i18n } from '@biesbjerg/ngx-translate-extract-marker';

import { strEnum } from 'app/misc/tools';

import {
  Bg2SimpleHistogramBaseComponent,
  SimpleHistogramConfig,
} from 'app/widgets/widgets-reusables/charts/histograms/simple-histogram-base/simple-histogram-base.component';
import { ErrorHelperData } from 'app/widgets/widgets-reusables/errors/error-helper/error-helper.component';

const honey_type = strEnum([
  'Acacia',
  'Alfalfa',
  'All Flower',
  'Arbutus',
  'Bramble',
  'Buckthorn',
  'Chestnut',
  'Fir',
  'Heather',
  'High mountain',
  'Lavender',
  'Linden',
  'Metcalfa',
  'Mountain',
  'OTHER',
  'Oak',
  'Rapeseed',
  'Rhododendron',
  'Rosemary',
  'Scrubland',
  'Spring',
  'Sunflower',
  'Thyme',
]);
export type HoneyType = keyof typeof honey_type;

export type HoneyHarvestByType = { [key in HoneyType]: number };

@Component({
  selector: 'bg2-superbox-harvest-histogram-honey-factory-quantity',
  templateUrl: '../../../../../../widgets/widgets-reusables/charts/histograms/simple-histogram-base/simple-histogram-base.component.html',
  styleUrls: ['../../../../../../widgets/widgets-reusables/charts/histograms/simple-histogram-base/simple-histogram-base.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class Bg2SuperboxHarvestHistogramHoneyFactoryQuantityComponent extends Bg2SimpleHistogramBaseComponent implements OnInit {
  @Input()
  public set quantity(quantity: HoneyHarvestByType) {
    this.histogram_data = quantity;
  }

  // #region -> (component basics)

  constructor() {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();

    this.display_zero_values = false;
    this.histogram_configuration = new Map([
      ['Acacia', { key: 'Acacia', label: 'HONEY_TYPE.Acacia', img: null }],
      ['Alfalfa', { key: 'Alfalfa', label: 'HONEY_TYPE.Alfalfa', img: null }],
      ['All Flower', { key: 'All Flower', label: 'HONEY_TYPE.All Flower', img: null }],
      ['Arbutus', { key: 'Arbutus', label: 'HONEY_TYPE.Arbutus', img: null }],
      ['Bramble', { key: 'Bramble', label: 'HONEY_TYPE.Bramble', img: null }],
      ['Buckthorn', { key: 'Buckthorn', label: 'HONEY_TYPE.Buckthorn', img: null }],
      ['Chestnut', { key: 'Chestnut', label: 'HONEY_TYPE.Chestnut', img: null }],
      ['Fir', { key: 'Fir', label: 'HONEY_TYPE.Fir', img: null }],
      ['Heather', { key: 'Heather', label: 'HONEY_TYPE.Heather', img: null }],
      ['High mountain', { key: 'High mountain', label: 'HONEY_TYPE.High mountain', img: null }],
      ['Lavender', { key: 'Lavender', label: 'HONEY_TYPE.Lavender', img: null }],
      ['Linden', { key: 'Linden', label: 'HONEY_TYPE.Linden', img: null }],
      ['Metcalfa', { key: 'Metcalfa', label: 'HONEY_TYPE.Metcalfa', img: null }],
      ['Mountain', { key: 'Mountain', label: 'HONEY_TYPE.Mountain', img: null }],
      ['OTHER', { key: 'OTHER', label: 'HONEY_TYPE.OTHER', img: null }],
      ['Oak', { key: 'Oak', label: 'HONEY_TYPE.Oak', img: null }],
      ['Rapeseed', { key: 'Rapeseed', label: 'HONEY_TYPE.Rapeseed', img: null }],
      ['Rhododendron', { key: 'Rhododendron', label: 'HONEY_TYPE.Rhododendron', img: null }],
      ['Rosemary', { key: 'Rosemary', label: 'HONEY_TYPE.Rosemary', img: null }],
      ['Scrubland', { key: 'Scrubland', label: 'HONEY_TYPE.Scrubland', img: null }],
      ['Spring', { key: 'Spring', label: 'HONEY_TYPE.Spring', img: null }],
      ['Sunflower', { key: 'Sunflower', label: 'HONEY_TYPE.Sunflower', img: null }],
      ['Thyme', { key: 'Thyme', label: 'HONEY_TYPE.Thyme', img: null }],
      ['Thyme', { key: 'Thyme', label: 'HONEY_TYPE.Thyme', img: null }],
      ['Pink_berries', { key: 'Pink_berries', label: 'HONEY_TYPE.Pink_berries', img: null }],
      ['Lychee', { key: 'Lychee', label: 'HONEY_TYPE.Lychee', img: null }],
    ]) as SimpleHistogramConfig;

    this.has_data$$.subscribe({
      next: has_data => {
        if (!has_data) {
          this.error = new ErrorHelperData([
            {
              type: 'span',
              content: i18n<string>('ALL.DATA.MISC.No data'),
            },
          ]);
        } else {
          this.error = null;
        }
      },
    });
  }

  // #endregion
}
