import { Injectable } from '@angular/core';
import { DefaultWidgetRegistry } from 'ngx-schema-form';

import { EfStringWidget } from './string/string.widget';
import { EfNumberWidget } from './number/number.widget';
import { EfNumberLightWidget } from './number-light/number-light.widget';
import { EfTextAreaWidget } from './textarea/textarea.widget';
import { EfSelectWidgetComponent } from './select/select.widget';
import { EfSliderWidgetComponent } from './slider/slider.widget';

import { EfGeoposWidgetComponent } from './geopos/geopos.widget';
import { EfDateWidgetComponent } from './date/date.widget';
import { EfBooleanWidget } from './boolean/boolean.widget';
import { EfCheckboxWidget } from './checkbox/checkbox.widget';
import { EfChecklistWidgetComponent } from './checklist/checklist.widget';
import { EfRadioboxWidget } from './radiobox/radiobox.widget';
import { EfColorHSLSelectorWidget } from './color-hsl-selector/color-hsl-selector.widget';

import { EfObjectWidgetComponent } from './object/object.widget';
import { EfArrayWidget } from './array/array.widget';

import { EfBg2EntityWidget } from './bg2entity/entity.widget';
import { EfBg2DeviceWidgetComponent } from './bg2device/device.widget';
import { EfBg2DeviceComWidgetComponent } from './bg2device-com/device-com.widget';
import { EfBg2EntityACLWidgetComponent } from './bg2entity-acl/entity-acl.widget';
import { EfBg2SuperboxRepartitionWidget } from './bg2superbox-repartition/bg2superbox-repartition.widget';
import { EfBg2SuperboxFillingWidgetComponent } from './bg2superbox-filling/bg2superbox-filling.widget';
import { EfBg2SuperboxHarvestSelectorWidgetComponent } from './bg2superbox-harvest-selector/bg2superbox-harvest-selector.widget';
import { EfArrayAddOnlyWidgetComponent } from './array-add-only/array-add-only.widget';
import { EfBg2FastvisitWidgetComponent } from './bg2fastvisit/bg2fastvisit.widget';
import { EfBg2VisitConfigWidgetComponent } from './bg2visit-config/bg2visit-config.widget';
import { EfBg2UserWidget } from './bg2user/user.widget';
import { EfBg2UserSelectWidget } from './bg2user-select/bg2user-select.widget';
import { Bg2RatingWidget } from './bg2-rating/bg2-rating.widget';
import { EfDateRangeWidgetComponent } from './date-range/date-range.widget';

import { NgMatTextComponent } from './ng-mat-text/ng-mat-text.component';
import { NgMatArrayWidgetComponent } from './ng-mat-array/ng-mat-array.component';
import { NgMatSelectFormFieldComponent } from './ng-mat-select/ng-mat-select.component';
import { NgMatNumberRangeComponent } from './ng-mat-number-range/ng-mat-number-range.component';
import { EfBg2RadioboxWidget } from './bg2radiobox/bg2radiobox.widget';
import { EfBg2ChecklistWidget } from './bg2checklist/bg2checklist.widget';

import { NgMatTelComponent } from './ng-mat-tel/ng-mat-tel.component';
import { NgMatPasswordComponent } from './ng-mat-password/ng-mat-password.component';
import { NgMatTextareaComponent } from './ng-mat-textarea/ng-mat-textarea.component';

import { ZohoSearchCRMWidgetComponent } from './zoho-search-crm/zoho-search-crm-widget.component';
import { ZohoSearchDeskWidgetComponent } from './zoho-search-desk/zoho-search-desk-widget.component';
import { NgMatFileComponent } from './ng-mat-file/ng-mat-file.component';
import { Bg2EntityExploitationFormWidgetComponent } from './bg2-entity-exploitation/bg2-entity-exploitation.component';
import { TimezoneSelectFormWidgetComponent } from './bg2-timezone-select/bg2-timezone-select.component';

@Injectable()
export class EfWidgetRegistry extends DefaultWidgetRegistry {
  constructor() {
    super();

    // Registers angular material versions of fields
    this.register('ng-mat-text', NgMatTextComponent);
    this.register('ng-mat-array', NgMatArrayWidgetComponent);
    this.register('ng-mat-select', NgMatSelectFormFieldComponent);
    this.register('ng-mat-number-range', NgMatNumberRangeComponent);

    this.register('number', EfNumberWidget);
    this.register('rating', Bg2RatingWidget);
    this.register('number-light', EfNumberLightWidget);
    this.register('slider', EfSliderWidgetComponent);
    this.register('integer', EfNumberWidget);
    this.register('string', EfStringWidget);
    this.register('textarea', EfTextAreaWidget);
    this.register('select', EfSelectWidgetComponent);
    this.register('boolean', EfBooleanWidget);
    this.register('checkbox', EfCheckboxWidget);
    this.register('checklist', EfChecklistWidgetComponent);
    this.register('radio', EfRadioboxWidget);
    this.register('color-hsl', EfColorHSLSelectorWidget);

    this.register('object', EfObjectWidgetComponent);
    this.register('array', EfArrayWidget);
    this.register('array-add-only', EfArrayAddOnlyWidgetComponent);

    this.register('date', EfDateWidgetComponent);
    this.register('geoloc', EfGeoposWidgetComponent);
    this.register('date-time', EfDateWidgetComponent);
    this.register('date-range', EfDateRangeWidgetComponent);

    // Beeguard object widgets
    this.register('bg2entity', EfBg2EntityWidget);
    this.register('bg2-entity-exploitation', Bg2EntityExploitationFormWidgetComponent);
    this.register('bg2entity-acl', EfBg2EntityACLWidgetComponent);
    this.register('bg2device', EfBg2DeviceWidgetComponent);
    this.register('bg2device-com', EfBg2DeviceComWidgetComponent);
    this.register('bg2user', EfBg2UserWidget);
    this.register('bg2user-select', EfBg2UserSelectWidget);
    this.register('timezone-select', TimezoneSelectFormWidgetComponent);

    this.register('bg2superbox-repartion', EfBg2SuperboxRepartitionWidget);
    this.register('bg2superbox-filling', EfBg2SuperboxFillingWidgetComponent);
    this.register('bg2superbox-harvest-selector', EfBg2SuperboxHarvestSelectorWidgetComponent);

    this.register('bg2visit-config', EfBg2VisitConfigWidgetComponent);
    this.register('bg2fastvisit', EfBg2FastvisitWidgetComponent);

    // Angular classic fields
    this.register('ng-mat-tel', NgMatTelComponent);
    this.register('ng-mat-file', NgMatFileComponent);
    this.register('ng-mat-textarea', NgMatTextareaComponent);
    this.register('ng-mat-password', NgMatPasswordComponent);

    // Zoho fields
    this.register('zoho-search-crm', ZohoSearchCRMWidgetComponent);
    this.register('zoho-search-desk', ZohoSearchDeskWidgetComponent);

    this.register('bg2radiobox', EfBg2RadioboxWidget);
    this.register('bg2checklist', EfBg2ChecklistWidget);
  }
}
