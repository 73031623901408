import { Observable, of, merge, combineLatest, OperatorFunction, concat, take } from 'rxjs';
import { map, filter, mapTo, switchMap, withLatestFrom, tap, distinctUntilChanged } from 'rxjs';

import { Event, ApplyToEntity, ApplyTo, IEventData, IEventGlobalSchema, I18NParams } from './Event';
import { Beeguard2Api } from 'app/core';
import { Apiary, Warehouse, Location, Hive, Entity } from '../entities';
import { marker as i18n } from '@biesbjerg/ngx-translate-extract-marker';
import { clone, countBy, isEmpty, isNil, isUndefined, keys, mapKeys, mapValues, union } from 'lodash-es';

import { Dictionary } from 'app/typings/core/interfaces';
import { replay, robustCombineLatest } from '@bg2app/tools/rxjs';
import { ExportHeader, ExportedRow } from '../export';
import { TranslateService } from '@ngx-translate/core';

const emptyDictToNull: OperatorFunction<Dictionary<any>, Dictionary<any>> = map(rep => (keys(rep).length > 0 ? rep : null));

const emptyArrayToNull: OperatorFunction<any[], any[]> = map(rep => (isNil(rep) ? null : rep.length > 0 ? rep : null));

const switchIfTrue = <OutputType>(
  obs_true: Observable<OutputType>,
  obs_false?: Observable<OutputType>
): OperatorFunction<boolean, OutputType> => {
  if (isUndefined(obs_false)) {
    obs_false = of(null);
  }
  return switchMap(enable => {
    if (enable) {
      return obs_true;
    } else {
      return obs_false;
    }
  });
};

const includes = <ValueType>(value: ValueType): OperatorFunction<ValueType[], boolean> =>
  map(array => (isNil(array) ? false : array.includes(value)));

export interface ApiaryObservation extends Dictionary<any> {
  label?: string;
  other?: string;
  comment?: string;
  nb_hives?: string;
  observation?: string;
}

export interface SanitaryTest extends Dictionary<any> {
  comment?: string;
  label?: string;
  type?: 'VP100' | 'foulbrood';
  VP100?: {
    label?: string;
    number_varroas?: number;
    result?: number;
    sample_weight?: number;
    variant?: string;
  };
  foulbrood?: {
    result?: boolean;
  };
}

export interface SanitaryTreatment extends Dictionary<string> {
  cat?: 'bio' | 'conventionel';
  comment?: string;
  label?: string;
  test_type_b?: string;
  test_type_c?: string;
}

export class GlobalFeeding {
  public comment = '';
  public feeding_type = {
    value: '',
    label: '',
  };

  public quantity_kg: {
    value: number;
    label: string;
  };

  public quantity_L: {
    value: number;
    label: string;
  };

  public nb_feeded_hive: {
    value: number;
    label: string;
  };

  public nb_feeded_nuc: {
    value: number;
    label: string;
  };

  // public quantity_kg: number;
  // public quantity_L: number;
}

interface IEvaluationApplyTo extends ApplyTo {
  apiary: ApplyToEntity;
  location: ApplyToEntity;
  hives?: ApplyToEntity[];
  warehouse: ApplyToEntity;
}

interface IEvaluationData extends IEventData {
  config: any;
  evaluation_hives: Dictionary<any>[];
  evaluation_apiary: any;
}

export class EvaluationEvent extends Event<IEvaluationApplyTo, IEvaluationData> {
  protected default_data: IEvaluationData = {
    config: {},
    evaluation_hives: [],
    evaluation_apiary: {},
  };

  public vconf = {
    update_modal: 'apiary_evaluation',
  };

  constructor(protected bg2Api: Beeguard2Api) {
    super(bg2Api);
    this.type = 'evaluation';
  }

  //#region Entities

  public apiary$$: Observable<Apiary> = this.entities$$.pipe(map(entities => entities.apiary as Apiary));
  public location$$: Observable<Location> = this.entities$$.pipe(map(entities => entities.location as Location));
  public warehouse$$: Observable<Warehouse> = this.entities$$.pipe(map(entities => entities.warehouse as Warehouse));

  public apiary_state$$ = this.apiary$$.pipe(switchMap(apiary => apiary.getStateAtDate(this.date, this.id)));

  // Check if apply_to is fully load
  public is_valid$$: Observable<boolean> = concat(
    of(false),
    this.apply_to$$.pipe(
      map(apply_to => apply_to.hasOwnProperty('apiary') && apply_to.hasOwnProperty('location') && apply_to.hasOwnProperty('warehouse'))
    )
  ).pipe(
    tap(is_valid => (this.is_valid = is_valid)),
    distinctUntilChanged(),
    replay()
  );

  public is_valid = false;

  //#endregion

  //#region sub-Schema

  // Get full schema of observations
  public sanitary_observations_schema$$ = this.data_schema$$.pipe(
    map(schema => schema.properties.evaluation_apiary),
    map(evaluation_apiary_schema => evaluation_apiary_schema.properties.sanitary),
    map(sanitary_schema => sanitary_schema.properties.observations),
    map(obs_schema => obs_schema.items)
  );

  // Get i18n labels of observations
  public sanitary_observations_labels$$: Observable<Dictionary<any>> = this.sanitary_observations_schema$$.pipe(
    map(schema => {
      const oneof = schema.properties.observation.oneOf;
      const obs_desc: Dictionary<any> = {};
      oneof.forEach((obs: any) => {
        obs_desc[obs.enum[0]] = {
          label: obs.label,
        };
      });
      return obs_desc;
    })
  );

  // Get full schema of sanitary tests
  public sanitary_tests_schema$$ = this.data_schema$$.pipe(
    map(schema => schema.properties.evaluation_apiary),
    map(evaluation_apiary_schema => evaluation_apiary_schema.properties.sanitary),
    map(sanitary_schema => sanitary_schema.properties.sanitary_tests),
    map(sanitary_tests_schema => sanitary_tests_schema.items)
  );

  // Get i18n labels of sanitary tests
  public sanitary_tests_labels$$: Observable<Dictionary<any>> = this.sanitary_tests_schema$$.pipe(
    map(schema => {
      const one_of = schema.properties.type.oneOf;
      const san_desc: Dictionary<any> = {};
      one_of.forEach((san: any) => {
        san_desc[san.enum[0]] = {
          label: san.label,
        };
      });

      for (const key in san_desc) {
        if (schema.properties[key]) {
          if (schema.properties[key].properties.variant) {
            schema.properties[key].properties.variant.oneOf.forEach((trad: any) => {
              san_desc[key][trad.enum[0]] = {
                label: trad.label,
              };
            });
          }
        }
      }
      return san_desc;
    })
  );

  // Get full schema of sanitary treatments
  public sanitary_treatments_schema$$ = this.data_schema$$.pipe(
    map(schema => schema.properties.evaluation_apiary),
    map(evaluation_apiary_schema => evaluation_apiary_schema.properties.sanitary),
    map(sanitary_schema => sanitary_schema.properties.sanitary_treatments),
    map(sanitary_tests_schema => sanitary_tests_schema.items)
  );

  // Get i18n labels of sanitary treatments
  public sanitary_treatments_labels$$: Observable<Dictionary<any>> = this.sanitary_treatments_schema$$.pipe(
    map(schema => {
      const one_of = schema.properties.cat.oneOf;
      const san_treat_desc: Dictionary<any> = {};

      one_of.forEach((san: any) => {
        san_treat_desc[san.enum[0]] = {
          label: san.label,
        };
      });

      return san_treat_desc;
    }),
    emptyDictToNull
  );

  // Get full schema of feeding
  public feeding_schema$$ = this.data_schema$$.pipe(
    map(schema => schema.properties.evaluation_apiary),
    map(evaluation_apiary_schema => evaluation_apiary_schema.properties.feeding),
    map(feeding_schema => feeding_schema)
  );

  // Get i18n labels of feeding
  public feeding_labels$$: Observable<Dictionary<any>> = this.feeding_schema$$.pipe(
    map(schema => {
      const i18n_labels: Dictionary<any> = {
        feeding_type: {},
        quantity_kg: null,
        quantity_L: null,
        nb_feeded_hive: null,
        nb_feeded_nuc: null,
      };
      const feeding_type = schema.properties.feeding_type;
      const quantity_kg = schema.properties.quantity_kg;
      const quantity_l = schema.properties.quantity_L;
      const nb_feeded_hive = schema.properties.nb_feeded_hive;
      const nb_feeded_nuc = schema.properties.nb_feeded_nuc;

      feeding_type.oneOf.forEach((type: any, index: any) => {
        type = type.enum[0];
        i18n_labels.feeding_type[type] = {
          label: type.label,
        };
      });

      i18n_labels.quantity_kg = {
        label: quantity_kg.label,
      };

      i18n_labels.quantity_L = {
        label: quantity_l.label,
      };

      i18n_labels.nb_feeded_hive = {
        label: nb_feeded_hive.label,
      };

      i18n_labels.nb_feeded_nuc = {
        label: nb_feeded_nuc.label,
      };

      return i18n_labels;
    })
  );

  // Get full schema of hardware stocks
  public hardware_stocks_schema$$ = this.data_schema$$.pipe(
    map(schema => schema.properties.evaluation_apiary),
    map(evaluation_apiary_schema => evaluation_apiary_schema.properties.hardware_stock),
    map(hardware_stock_schema => hardware_stock_schema.properties)
  );

  // Get i18n labels of hardware stocks
  public hardware_stocks_labels$$: Observable<Dictionary<any>> = this.hardware_stocks_schema$$.pipe(
    map(schema => {
      const i18n_labels: Dictionary<any> = {};

      Object.keys(schema).forEach(schema_key => {
        i18n_labels[schema_key] = {
          label: schema[schema_key].label,
        };
      });

      return i18n_labels;
    })
  );

  // Get full schema of next actions
  public next_actions_schema$$ = this.data_schema$$.pipe(
    map(schema => schema.properties.evaluation_apiary),
    map(evaluation_apiary_schema => evaluation_apiary_schema.properties.next_actions),
    map(next_actions_schema => next_actions_schema.items)
  );

  // Get i18n labels of next actions
  public next_actions_labels$$: Observable<Dictionary<any>> = this.next_actions_schema$$.pipe(
    map(schema => {
      const one_of = schema.properties.value.oneOf;
      const i18n_labels: Dictionary<any> = {};

      one_of.forEach((obj: any) => {
        i18n_labels[obj.enum[0]] = {
          label: obj.label,
        };
      });

      return i18n_labels;
    })
  );

  //#endregion

  //#region Config

  public config$$: Observable<Dictionary<any>> = this.data$$.pipe(map(data => data?.config ?? {}));

  public config_apiary$$: Observable<string[]> = this.config$$.pipe(map(config => config?.apiary ?? []));

  public config_hf$$: Observable<Dictionary<any>> = this.config$$.pipe(map(config => config?.hives_fast ?? []));

  public config_hf_prod$$: Observable<string[]> = this.config_hf$$.pipe(
    map(config_hf => {
      if (!config_hf) {
        return [];
      }

      return config_hf?.hives_status_config ?? [];
    })
  );

  public config_apiary_or_hf_prod$$: Observable<string[]> = combineLatest(
    this.config_apiary$$,
    this.config_hf_prod$$,
    (conf_apiary, conf_hf_prod) => union(conf_apiary, conf_hf_prod)
  );

  public config_hp$$: Observable<string[]> = this.config$$.pipe(map(config => config.hives_precise || []));

  public config_hp_prod$$: Observable<string[]> = this.config_hp$$.pipe(
    map(config_hp => {
      if (!config_hp) {
        return [];
      } else {
        return config_hp || [];
      }
    })
  );

  public config_apiary_or_hp_prod$$: Observable<string[]> = combineLatest(
    [this.config_apiary$$, this.config_hp_prod$$],
    (conf_apiary, conf_hp_prod) => union(conf_apiary, conf_hp_prod)
  ).pipe(replay());

  public visit_type$$ = this.config$$.pipe(
    map(config => {
      const visit_type_i18n = [];

      // If visit on apiary
      if (config.apiary.length > 0) {
        visit_type_i18n.push(i18n('WIDGETS.EVENT_FORM.VISIT_CONFIG.Global on the apiary'));
      }

      // If visit by hives
      if (config.by_hives !== 'no') {
        visit_type_i18n.push(config.by_hives === 'fast' ? i18n('EVENT.VISIT.BY_HIVE.fast') : i18n('EVENT.VISIT.BY_HIVE.advanced'));
      }
      return visit_type_i18n;
    })
  );

  //#endregion

  //#region Global visit

  // Get global visit results
  public global_visit_results$$: Observable<any> = this.data$$.pipe(
    // filter(data => data.evaluation_apiary), // Using filter -> block if condition not exists
    map(data => {
      if (!isNil(data.evaluation_apiary)) {
        return data.evaluation_apiary;
      }
      return null;
    })
  );

  // Number of hives from event data (of apiary entity if not defined in the event)
  public nb_hives$$: Observable<number> = combineLatest(this.global_visit_results$$, this.apiary_state$$).pipe(
    map(res => {
      const event_res = res[0];
      if (!isNil(event_res) && event_res.hardware_stock && event_res.hardware_stock.nb_hives) {
        return event_res.hardware_stock.nb_hives;
      }
      const apiary_state = res[1];
      if (apiary_state && apiary_state.state && apiary_state.state.nb_hives) {
        return apiary_state.state.nb_hives;
      }
      return null;
    })
  );

  public nb_nuc$$: Observable<number> = combineLatest(this.global_visit_results$$, this.apiary_state$$).pipe(
    map(res => {
      const event_res = res[0];
      if (!isNil(event_res) && event_res.hardware_stock && event_res.hardware_stock.nb_nuc) {
        return event_res.hardware_stock.nb_nuc;
      }
      const apiary_state = res[1];
      if (apiary_state && apiary_state.state && apiary_state.state.nb_nuc) {
        return apiary_state.state.nb_nuc;
      }
      return null;
    })
  );

  // Get global observations
  public sanitary_observations$$: Observable<ApiaryObservation[]> = this.config_apiary$$.pipe(
    includes('sanitary'),
    switchIfTrue(
      this.global_visit_results$$.pipe(
        map(data => {
          if (!isNil(data) && !isNil(data.sanitary) && !isNil(data.sanitary.observations)) {
            return data.sanitary.observations;
          }
          return null;
        }),
        switchMap(observations =>
          this.sanitary_observations_labels$$.pipe(
            map(descriptions => {
              if (!isNil(observations)) {
                observations.map((observation: any) => (observation.label = descriptions[observation.observation].label));
                return observations;
              }
              return null;
            })
          )
        ),
        emptyArrayToNull
      )
    )
  );

  // Get global sanitary tests
  public sanitary_tests$$: Observable<any[]> = this.config_apiary$$.pipe(
    includes('sanitary'),
    switchIfTrue(
      this.global_visit_results$$.pipe(
        map(data => {
          if (!isNil(data?.sanitary?.sanitary_tests)) {
            return data.sanitary.sanitary_tests;
          }
          return null;
        }),
        switchMap(sanitary_tests =>
          this.sanitary_tests_labels$$.pipe(
            map(descriptions => {
              if (!isNil(sanitary_tests)) {
                sanitary_tests.map((sanitary_test: any) => {
                  sanitary_test.label = descriptions[sanitary_test.type].label;
                  if (sanitary_test[sanitary_test.type].variant) {
                    sanitary_test[sanitary_test.type].label =
                      descriptions[sanitary_test.type][sanitary_test[sanitary_test.type].variant].label;
                  }
                });
              }
              return sanitary_tests;
            })
          )
        ),
        emptyArrayToNull
      )
    )
  );

  // Get global sanitary treatments
  public sanitary_treatments$$: Observable<any> = this.config_apiary$$.pipe(
    includes('sanitary'),
    switchIfTrue(
      this.global_visit_results$$.pipe(
        // tap(data => console.log('dt1: ', data)),
        map(data => {
          if (!isNil(data) && !isNil(data.sanitary) && !isNil(data.sanitary.sanitary_treatments)) {
            return data.sanitary.sanitary_treatments;
          }
          return null;
        }),
        // tap(data => console.log('dt2: ', data)),
        switchMap(sanitary_treatments =>
          this.sanitary_treatments_labels$$.pipe(
            map(descriptions => {
              if (!isNil(sanitary_treatments)) {
                sanitary_treatments.map(
                  (sanitary_treatment: any) => (sanitary_treatment.label = descriptions[sanitary_treatment.cat].label)
                );
                return sanitary_treatments;
              }
              return null;
            })
          )
        ),
        emptyArrayToNull
      )
    )
  );

  // Get global feeding
  public feeding$$: Observable<GlobalFeeding> = this.config_apiary$$.pipe(
    includes('feeding'),
    switchIfTrue(
      this.global_visit_results$$.pipe(
        map(data => data?.feeding || null),
        withLatestFrom(this.feeding_labels$$),
        map(results => {
          const feeding = results[0];
          const descriptions = results[1];
          if (feeding) {
            const apiary_feeding: GlobalFeeding = new GlobalFeeding();

            // Assign comment
            apiary_feeding.comment = feeding.comment;

            Object.keys(feeding)
              .filter(key => descriptions.hasOwnProperty(key))
              .forEach(key => {
                // Use switch case because location for feeding_label is different of location for quantities labels
                switch (key) {
                  case 'feeding_type': {
                    const feed_type = feeding.feeding_type_other ? feeding.feeding_type_other : feeding.feeding_type;
                    apiary_feeding.feeding_type = {
                      value: feed_type,
                      label: descriptions.feeding_type[feeding.feeding_type].label,
                    };
                    break;
                  }

                  case 'quantity_kg':
                  case 'nb_feeded_hive':
                  case 'nb_feeded_nuc':
                  case 'quantity_L': {
                    apiary_feeding[key] = {
                      value: feeding[key],
                      label: descriptions[key].label,
                    };
                    break;
                  }
                }
              });
            return apiary_feeding;
          } else {
            return null;
          }
        })
      )
    )
  );

  // Get global hardware stocks
  public hardware_stocks$$: Observable<{ [key: string]: { value: any; label: string } }> = this.config_apiary$$.pipe(
    includes('hardware_stock'),
    switchIfTrue(
      this.global_visit_results$$.pipe(
        map(data => {
          if (!isNil(data) && !isNil(data.hardware_stock)) {
            return data.hardware_stock;
          }
          return null;
        }),
        switchMap(hardware_stocks =>
          this.hardware_stocks_labels$$.pipe(
            map(descriptions => {
              const hw_stocks_clone: Dictionary<any> = {};
              if (!isNil(hardware_stocks) && !isNil(descriptions)) {
                Object.keys(hardware_stocks).forEach(hw_stock_key => {
                  hw_stocks_clone[hw_stock_key] = {
                    value: hardware_stocks[hw_stock_key],
                    label: descriptions[hw_stock_key].label,
                  };
                });
              }
              return hw_stocks_clone;
            })
          )
        ),
        emptyDictToNull
      )
    )
  );

  // Get global next actions
  public next_actions$$: Observable<any[]> = this.config_apiary$$.pipe(
    includes('next_actions'),
    switchIfTrue(
      this.global_visit_results$$.pipe(
        map(data => data.next_actions || []),
        switchMap(next_actions =>
          this.next_actions_labels$$.pipe(
            map(descriptions => {
              next_actions.forEach((next_action: any) => {
                next_action.label = descriptions[next_action.value].label;
              });
              return next_actions;
            })
          )
        ),
        emptyArrayToNull
      )
    )
  );

  //#endregion

  //#region Hive fast visit

  // Get hive fast visit results
  public hf_visit_results$$: Observable<any> = this.data$$.pipe(
    map(data => {
      if (
        !isNil(data) &&
        !isNil(data.evaluation_apiary) &&
        !isNil(data.evaluation_apiary.hf_prod_visit) &&
        !isNil(data.evaluation_apiary.hf_prod_visit.result)
      ) {
        return data.evaluation_apiary.hf_prod_visit.result;
      }
      return null;
    })
  );

  // Get hive fast visit statuses
  public hf_hive_statuses$$: Observable<{ [key: string]: number }> = this.config_apiary_or_hf_prod$$.pipe(
    includes('hive_status'),
    switchIfTrue(
      this.hf_visit_results$$.pipe(
        map(result => {
          if (!isNil(result) && !isNil(result.hive_status)) {
            return result.hive_status;
          }
          return null;
        })
        // map(/** add img */)
      )
    )
  );

  // Get hive fast visit brood_frames
  public hf_broodframes$$: Observable<{ [key: string]: number }> = this.config_apiary_or_hf_prod$$.pipe(
    includes('brood_frame'),
    switchIfTrue(
      this.hf_visit_results$$.pipe(
        map(result => {
          if (!isNil(result) && !isNil(result.brood_frame)) {
            return result.brood_frame;
          }
          return null;
        })
        // map(/** add img */)
      )
    )
  );

  // Get hive fast visit queen colors
  public hf_queen_colors$$: Observable<{ [key: string]: number }> = this.config_apiary_or_hf_prod$$.pipe(
    includes('queen'),
    switchIfTrue(
      this.hf_visit_results$$.pipe(
        map(result => {
          if (!isNil(result) && !isNil(result.queen)) {
            return result.queen;
          }
          return null;
        })
        // map(/** add img */)
      )
    )
  );

  // Get hive fast visit supers
  public hf_supers$$: Observable<{ [key: string]: number }> = this.config_apiary_or_hf_prod$$.pipe(
    includes('supers'),
    switchIfTrue(
      this.hf_visit_results$$.pipe(
        map(result => {
          if (!isNil(result) && !isNil(result.supers)) {
            return result.supers;
          }
          return null;
        })
        // map(/** add img */)
      )
    )
  );

  //#endregion

  //#region Hive precise visit

  //#region Hive precise schema (i18n labels)

  // Get hive precise visit schema
  public hp_schema$$ = this.data_schema$$.pipe(
    map(data => data.properties.evaluation_hives.items.properties),
    replay()
  );

  // Get hive precise labels for hive_status
  public hp_hive_status_labels$$: Observable<{ [key: string]: { label: string } }> = this.hp_schema$$.pipe(
    map(hp_schema => hp_schema.hive_status.oneOf),
    map((oneOf: any[]) => {
      const i18n_labels: { [key: string]: { label: string } } = {};

      oneOf.forEach((obj: any) => {
        i18n_labels[obj.enum[0]] = {
          label: obj.label,
        };
      });

      return i18n_labels;
    }),
    replay()
  );

  // Get hive precise labels for state
  public hp_state_labels$$: Observable<{ [key: string]: { label: string } }> = this.hp_schema$$.pipe(
    map(hp_schema => hp_schema.state.oneOf),
    map((oneOf: any[]) => {
      const i18n_labels: { [key: string]: { label: string } } = {};

      oneOf.forEach((obj: any) => {
        i18n_labels[obj.enum[0]] = {
          label: obj.label,
        };
      });

      return i18n_labels;
    }),
    replay()
  );

  // Get hive precise labels for queen colors
  public hp_queen_colors_labels$$ = this.hp_schema$$.pipe(
    map(hp_schema => hp_schema.queen.properties),
    map(properties => {
      const i18n_labels: Dictionary<any> = {
        color: {
          b: { label: 'EVENT.VISIT.QUEEN.blue' },
          w: { label: 'EVENT.VISIT.QUEEN.white' },
          y: { label: 'EVENT.VISIT.QUEEN.yellow' },
          r: { label: 'EVENT.VISIT.QUEEN.red' },
          g: { label: 'EVENT.VISIT.QUEEN.green' },
          unknow: { label: 'EVENT.VISIT.QUEEN.Unknow' },
          not_found: { label: 'EVENT.VISIT.QUEEN.Not found' },
        },
        queen_cell: { action: {}, status: {} },
        status: {},
      };

      // Get labels for queen cell action
      properties.queen_cell.properties.action.oneOf.forEach((obj: any) => {
        i18n_labels.queen_cell.action[obj.enum[0]] = {
          label: obj.label,
        };
      });

      // Get labels for queen cell status
      properties.queen_cell.properties.status.oneOf.forEach((obj: any) => {
        i18n_labels.queen_cell.status[obj.enum[0]] = {
          label: obj.label,
        };
      });

      // Get labels for queen status
      properties.status.oneOf.forEach((obj: any) => {
        i18n_labels.status[obj.enum[0]] = {
          label: obj.label,
        };
      });

      return i18n_labels;
    }),
    replay()
  );

  public hp_mortality_labels$$ = this.hp_schema$$.pipe(
    map(hp_schema => hp_schema.mortality.properties),
    map(properties => {
      const i18n_labels: Dictionary<any> = {
        cause_supposed: {},
        next_action: {},
        observation: {},
      };

      // Get labels for supposed cause
      properties.cause_supposed.oneOf.forEach((obj: any) => {
        i18n_labels.cause_supposed[obj.enum[0]] = {
          label: obj.label,
        };
      });

      // Get labels for next actions
      properties.next_action.oneOf.forEach((obj: any) => {
        i18n_labels.next_action[obj.enum[0]] = {
          label: obj.label,
        };
      });

      // Get labels for observations
      properties.observation.oneOf.forEach((obj: any) => {
        i18n_labels.observation[obj.enum[0]] = {
          label: obj.label,
        };
      });

      return i18n_labels;
    }),
    replay()
  );

  //#endregion

  /** */
  private _all_labels$$ = robustCombineLatest([
    this.hp_state_labels$$,
    this.hp_queen_colors_labels$$,
    this.hp_hive_status_labels$$,
    this.hp_mortality_labels$$,
  ]).pipe(
    map(([hp_state_labels, hp_queen_colors_labels, hp_hive_status_labels, hp_mortality_labels]) => ({
      hp_state_labels,
      hp_mortality_labels,
      hp_hive_status_labels,
      hp_queen_colors_labels,
    })),
    replay(),
  );

  // Get hive precise visit results
  public hp_visit_results$$: Observable<any[]> = combineLatest({
    labels: this._all_labels$$,
    evaluated_hives: this.data$$.pipe(map(data => data?.evaluation_hives ?? [])),
  }).pipe(
    map(({ evaluated_hives, labels }) =>
      evaluated_hives.map((evaluated_hive: Dictionary<any>) => {
        // State
        evaluated_hive.state_label = labels?.hp_state_labels[evaluated_hive.state].label;

        // Queen
        if (evaluated_hive.queen) {
          if (evaluated_hive.queen.color) {
            evaluated_hive.queen.color_label = labels?.hp_queen_colors_labels.color[evaluated_hive.queen.color].label;
          }
          evaluated_hive.queen.status_label = labels?.hp_queen_colors_labels.status[evaluated_hive.queen.status].label;
          evaluated_hive.queen.queen_cell.status_label =
            labels?.hp_queen_colors_labels.queen_cell.status[evaluated_hive.queen.queen_cell.status].label;

          if (evaluated_hive.queen.queen_cell.action) {
            evaluated_hive.queen.queen_cell.action_label =
              labels?.hp_queen_colors_labels.queen_cell.action[evaluated_hive.queen.queen_cell.action].label;
          }
        }

        // Status
        if (evaluated_hive.hive_status) {
          evaluated_hive.hive_status_label = labels?.hp_hive_status_labels[evaluated_hive.hive_status].label;
        }

        // mortality
        if (evaluated_hive.mortality) {
          ['cause_supposed', 'next_action', 'observation'].forEach(property => {
            evaluated_hive.mortality[`${property}_label`] = labels?.hp_mortality_labels[property][evaluated_hive.mortality[property]].label;
          });
        }

        return evaluated_hive;
      }),
    ),
    replay(),
  );

  public hives$$ = this.hp_visit_results$$.pipe(
    map(results => results.map((result: any) => result.hive_id)),
    switchMap((hive_ids: number[]) => this.bg2Api.getEntitiesObj(hive_ids))
  );

  public hp_visit_results_with_hive$$ = combineLatest([this.hp_visit_results$$, this.hives$$]).pipe(
    map(([results, hives]) =>
      results.map(_result => {
        const result = clone(_result); // NOTE: this is important to not modify the event data
        result.hive = hives.filter((hive: Hive) => hive.id === result.hive_id)[0];
        return result;
      })
    )
  );

  //#region Hives summaries

  public hive_status_by_hive$$: Observable<{ [key: number]: { histogram: { [key: string]: number }; label: string } }> =
    this.config_apiary_or_hp_prod$$.pipe(
      includes('hive_status'),
      switchIfTrue(
        this.hp_visit_results$$.pipe(
          map(data => {
            const status_by_hive: any = {};
            mapValues(data, o => {
              const holder: any = {
                histogram: {},
                value: o.state,
                label: o.state_label,
              };

              holder.histogram[o.state] = 1;
              status_by_hive[o.hive_id] = holder;
            });

            return status_by_hive;
          }),
          emptyDictToNull
        )
      )
    );

  public hive_broodframe_by_hive$$: Observable<{ [key: number]: { histogram: Dictionary<number> } }> = this.config_apiary_or_hp_prod$$.pipe(
    includes('brood_frame'),
    switchIfTrue(
      this.hp_visit_results$$.pipe(
        map(data => {
          const bf_by_hive: any = {};
          mapValues(data, o => {
            const value = o.brood_box.brood_frame.size;
            const holder: any = {
              histogram: {},
              value,
            };

            holder.histogram[`C${value}${value > 7 ? '+' : ''}`] = 1;
            bf_by_hive[o.hive_id] = holder;
          });

          return bf_by_hive;
        }),
        emptyDictToNull
      )
    ),
    replay()
  );

  public hp_queen_colors_by_hive$$: Observable<{ [key: number]: { histogram: { [key: string]: number }; label: string } }> =
    this.config_apiary_or_hp_prod$$.pipe(
      includes('queen'),
      switchIfTrue(
        this.hp_visit_results$$.pipe(
          map(data => {
            const queen_colors_by_hive: any = {};

            mapValues(data, o => {
              const value = o?.queen?.color || null;
              const holder: any = {
                histogram: {},
                label: o?.queen?.color_label,
                value,
              };

              if (o?.queen?.status === 'present' || o?.queen?.status === 'introduced') {
                holder.histogram[value] = 1;
                queen_colors_by_hive[o.hive_id] = holder;
              }

              return queen_colors_by_hive;
            });

            return queen_colors_by_hive;
          }),
          emptyDictToNull
        )
      ),
      replay()
    );

  public hp_hive_super_by_hive$$: Observable<{ [key: number]: { histogram: { [key: string]: number }; value: number } }> =
    this.config_apiary_or_hp_prod$$.pipe(
      includes('supers'),
      switchIfTrue(
        this.hp_visit_results$$.pipe(
          map(data => {
            const supers_by_hive: any = {};

            mapValues(data, o => {
              const value = o?.supers?.nb_supers ?? null;

              const holder: any = {
                histogram: {},
                value,
              };

              holder.histogram[`${value}${value > 4 ? '+' : ''}`] = 1;
              supers_by_hive[o.hive_id] = holder;

              return supers_by_hive;
            });

            return supers_by_hive;
          }),
          emptyDictToNull
        )
      ),
      replay()
    );

  public hp_hive_feeding_by_hive$$: Observable<{ [key: number]: { feeding_type: string; quantity: number; unit: string } }> =
    this.hp_visit_results$$.pipe(
      map(data => {
        const feeding_by_hive: { [key: number]: { feeding_type: string; quantity: number; unit: string } } = {};

        mapValues(data, o => {
          const feeding_type = o?.feeding?.feeding_type ?? null;
          const quantity = o?.feeding?.quantity_L ?? o?.feeding?.quantity_kg ?? null;
          const is_liter_unit = o?.feeding?.hasOwnProperty('quantity_L');

          const holder = {
            feeding_type,
            quantity,
            unit: is_liter_unit ? 'L' : 'kg',
            comment: o?.feeding?.comment,
          };

          feeding_by_hive[o.hive_id] = holder;
        });

        return feeding_by_hive;
      }),
      emptyDictToNull,
      replay()
    );

  // Get hive precise visit statuses
  public hp_hive_statuses$$: Observable<{ [key: string]: number }> = this.config_apiary_or_hp_prod$$.pipe(
    includes('hive_status'),
    switchIfTrue(
      this.hp_visit_results$$.pipe(
        map(data => countBy(data.map(hive_eval => hive_eval.state))),
        map(rep => (keys(rep).length > 0 ? rep : null)),
        emptyDictToNull
      )
    )
  );

  // Get hive precise visit broodframes
  public hp_broodframes$$: Observable<{ [key: string]: number }> = this.config_apiary_or_hp_prod$$.pipe(
    includes('brood_frame'),
    switchIfTrue(
      this.hp_visit_results$$.pipe(
        map(data => {
          const broodframes_data = data
            .filter(hive_eval => hive_eval.state === 'OK')
            .filter(hive_eval => hive_eval.hasOwnProperty('brood_box'))
            .filter(hive_eval => hive_eval.brood_box.hasOwnProperty('brood_frame'))
            .filter(hive_eval => hive_eval.brood_box.brood_frame.hasOwnProperty('size'))
            .map(hive_eval => Math.round(hive_eval.brood_box.brood_frame.size));

          const broodframes = countBy(broodframes_data);
          return mapKeys(broodframes, (value, key) => 'C' + key);
        }),
        emptyDictToNull
      )
    )
  );

  // Get hive fast visit queen colors
  public hp_queen_colors$$: Observable<{ [key: string]: number }> = this.config_apiary_or_hp_prod$$.pipe(
    includes('queen'),
    switchIfTrue(
      this.hp_visit_results$$.pipe(
        map((data: any[]) => {
          const queen_colors = data
            .filter(hive_eval => hive_eval.state === 'OK')
            .filter(hive_eval => hive_eval.hasOwnProperty('queen'))
            .filter(hive_eval => hive_eval.queen.status === 'present' || hive_eval.queen.status === 'introduced')
            .map(hive_eval => hive_eval.queen.color);
          return countBy(queen_colors);
        }),
        emptyDictToNull
      )
    )
  );

  // Get hive fast visit supers
  public hp_supers$$: Observable<{ [key: string]: number }> = this.config_apiary_or_hp_prod$$.pipe(
    includes('supers'),
    switchIfTrue(
      this.hp_visit_results$$.pipe(
        map(data => {
          const supers = data
            .filter(hive_eval => hive_eval.state === 'OK')
            .filter(hive_eval => hive_eval.hasOwnProperty('supers'))
            .map(hive_eval => Math.round(hive_eval.supers.nb_supers));
          return countBy(supers);
        }),
        emptyDictToNull
      )
    )
  );

  //#endregion

  //#endregion

  //#region Combined hive data

  // Datas of hive statuses
  public hive_statuses$$: Observable<{ [key: string]: number }> = robustCombineLatest([
    this.hf_hive_statuses$$,
    this.hp_hive_statuses$$,
  ]).pipe(
    map(values => {
      if (!isNil(values[1])) {
        return values[1];
      }
      return values[0];
    })
  );

  // Datas of valid hive statuses
  public nb_hives_ok$$: Observable<number> = this.hive_statuses$$.pipe(map(hives_by_status => hives_by_status.OK || 0));

  // Datas of broodframes
  public broodframes$$: Observable<{ [key: string]: number }> = combineLatest(this.hf_broodframes$$, this.hp_broodframes$$).pipe(
    map(values => {
      if (!isNil(values[1])) {
        return values[1];
      }
      return values[0];
    }),
    map(data => {
      if (!isNil(data)) {
        Object.keys(data).forEach(key => {
          if (parseInt(key.substring(1), 10) > 7) {
            if (!data.hasOwnProperty('C7+')) {
              data['C7+'] = 0;
            }
            data['C7+'] += data[key];
          }
        });

        if (Object.keys(data).length === 1 && Object.keys(data).includes('C0')) {
          return null;
        } else {
          return data;
        }
      }

      return null;
    })
  );

  // Datas of queen colors
  public queen_colors$$: Observable<{ [key: string]: number }> = combineLatest(this.hf_queen_colors$$, this.hp_queen_colors$$).pipe(
    map(values => {
      if (!isNil(values[1])) {
        return values[1];
      }
      return values[0];
    })
  );

  // Datas of supers
  protected _supers$$: Observable<{ [key: string]: number }> = robustCombineLatest([this.hf_supers$$, this.hp_supers$$]).pipe(
    map(([hf_supers, hp_supers]) => (!isNil(hf_supers) ? hf_supers : hp_supers ?? {})),
  );

  public nb_supers$$ = this._supers$$.pipe(
    map(supers => {
      if (isNil(supers) || Object.keys(supers).length == 0) {
        return null;
      } else {
        let total = 0;
        Object.keys(supers).forEach(key => {
          let key_int: number;
          if (key == '4+') {
            key_int = 5;
          } else {
            key_int = parseInt(key, 10);
          }
          total += key_int * supers[key];
        });
        return total
      }
    })
  )

  public supers$$: Observable<{ [key: string]: number }> = this._supers$$.pipe(
    map(data => {
      if (!isNil(data)) {
        const _supers = {};
        Object.keys(data).forEach(key => {
          if (parseInt(key, 10) > 4) {
            if (!data.hasOwnProperty('4+')) {
              data['4+'] = 0;
            }
            data['4+'] += data[key];
            delete data[key];
          }
        });

        return data;
      } else {
        return null;
      }
    })
  );

  // Extras supers (add stocks)
  public supers_and_stocks$$: Observable<{ [key: string]: number }> = this.hardware_stocks$$.pipe(
    // tap(val => console.log(val)),
    switchMap(stocks =>
      this.supers$$.pipe(
        map(supers => {
          // on recopie ds la repart des hausses le nb des hausses en stock
          if (
            !isNil(stocks) &&
            !isNil(stocks.nb_supers_stock) &&
            !isNil(stocks.nb_supers_stock.value) &&
            stocks.nb_supers_stock.value > 0 &&
            !isNil(supers)
          ) {
            supers['-1'] = stocks.nb_supers_stock.value;
          }
          return supers;
        })
      )
    ),
    emptyDictToNull
  );

  public has_hive_data$$: Observable<boolean> = merge(
    concat(of(true), this.data$$).pipe(
      filter((value: any) => value?.config?.by_hives === 'by_hives' || value?.config?.by_hives === 'advanced' || value?.apiary?.length > 0),
      mapTo(false)
    ),
    merge(this.hive_statuses$$, this.broodframes$$, this.queen_colors$$, this.supers$$).pipe(
      filter(value => !isNil(value) && !isEmpty(value)),
      mapTo(true)
    )
  );

  //#endregion

  //#region Export

  public getExportRowBuilder(headers: ExportHeader[], translateService: TranslateService): Observable<Dictionary<any>> {
    let row_builder = super.getExportRowBuilder(headers, translateService);
    headers.forEach((header: ExportHeader) => {
      switch (header.name) {
        case 'base_informations': {
          row_builder = row_builder.pipe(
            switchMap((row: ExportedRow) =>
              this.entities$$.pipe(
                take(1),
                map((entities: { [role: string]: Entity | Entity[] }) => {
                  const base_informations = row.base_informations || {};
                  if (!isNil(entities)) {
                    header.sub_headers.forEach((subHeader: ExportHeader) => {
                      switch (subHeader.name) {
                        case 'apiary_name':
                          base_informations.apiary_name = (entities as any).apiary.name;
                          break;

                        case 'location_name':
                          base_informations.location_name = (entities as any).location.name;
                          break;
                      }
                    });
                  }
                  row.base_informations = base_informations;
                  return row;
                })
              )
            )
          );
          break;
        }

        // Get stocks
        case 'nb_hives_stocks': {
          row_builder = row_builder.pipe(
            switchMap((row: ExportedRow) =>
              this.hardware_stocks$$.pipe(
                take(1),
                map((hardware_stocks: { [key: string]: { value: number; label: string } }) => {
                  row[header.name] = {};
                  header.sub_headers.forEach((subHeader: ExportHeader) => {
                    if (!isNil(hardware_stocks) && hardware_stocks.hasOwnProperty(subHeader.name)) {
                      row[header.name][subHeader.name] = hardware_stocks[subHeader.name].value || null;
                    }
                  });
                  return row;
                })
              )
            )
          );
          break;
        }

        // Get hive statuses repartition
        case 'hive_status': {
          row_builder = row_builder.pipe(
            switchMap((row: ExportedRow) =>
              this.hive_statuses$$.pipe(
                take(1),
                map((hive_statuses: { [key: string]: number }) => {
                  row[header.name] = {};
                  if (!isNil(hive_statuses)) {
                    header.sub_headers.forEach((subHeader: ExportHeader) => {
                      row[header.name][subHeader.name] = hive_statuses[subHeader.name] || null;
                    });
                  }
                  return row;
                })
              )
            )
          );
          break;
        }

        // Get brood frames repartition
        case 'brood_frame': {
          row_builder = row_builder.pipe(
            switchMap((row: ExportedRow) =>
              this.broodframes$$.pipe(
                take(1),
                map((broodframes: { [key: string]: number }) => {
                  row[header.name] = {};
                  if (!isNil(broodframes)) {
                    header.sub_headers.forEach((subHeader: ExportHeader) => {
                      row[header.name][subHeader.name] = broodframes[subHeader.name] || null;
                    });
                  }
                  return row;
                })
              )
            )
          );
          break;
        }

        // Get queen colors repartition
        case 'queen_colors': {
          row_builder = row_builder.pipe(
            switchMap((row: ExportedRow) =>
              this.queen_colors$$.pipe(
                take(1),
                map((queen_colors: { [key: string]: number }) => {
                  row[header.name] = {};
                  if (!isNil(queen_colors)) {
                    header.sub_headers.forEach((subHeader: ExportHeader) => {
                      row[header.name][subHeader.name] = queen_colors[subHeader.name] || null;
                    });
                  }
                  return row;
                })
              )
            )
          );
          break;
        }

        // Get supers repartition
        case 'supers': {
          row_builder = row_builder.pipe(
            switchMap((row: ExportedRow) =>
              this.supers$$.pipe(
                take(1),
                map((supers: { [key: string]: number }) => {
                  row[header.name] = {};
                  if (!isNil(supers)) {
                    header.sub_headers.forEach((subHeader: ExportHeader) => {
                      row[header.name][subHeader.name] = supers[subHeader.name] || null;
                    });
                  }
                  return row;
                })
              )
            )
          );
          break;
        }

        // Get Feeding informations
        case 'feeding': {
          row_builder = row_builder.pipe(
            switchMap((row: ExportedRow) =>
              this.feeding$$.pipe(
                take(1),
                map((feeding: Dictionary<any>) => {
                  row.feeding = {};
                  if (!isNil(feeding)) {
                    header.sub_headers.forEach((subHeader: ExportHeader) => {
                      switch (subHeader.name) {
                        case 'feeding_type': {
                          // Note: feeding type other ? TODO
                          const feeding_type = feeding.feeding_type.value;
                          row.feeding.feeding_type = translateService.instant(feeding_type);
                          break;
                        }

                        case 'quantity_kg':
                        case 'quantity_L': {
                          if (feeding[subHeader.name]) {
                            row.feeding[subHeader.name] = parseFloat(feeding[subHeader.name].value) || null;
                          }
                          break;
                        }

                        default: {
                          row.feeding[subHeader.name] = feeding[subHeader.name] || null;
                          break;
                        }
                      }
                    });
                  }
                  return row;
                })
              )
            )
          );
          break;
        }

        // Get sanitary observations
        case 'sanitary_observations': {
          row_builder = row_builder.pipe(
            switchMap((row: ExportedRow) =>
              this.sanitary_observations$$.pipe(
                take(1),
                map((sanitary_observations: ApiaryObservation[]) => {
                  row[header.name] = {};
                  if (!isNil(sanitary_observations)) {
                    header.sub_headers.forEach((subHeader: ExportHeader) => {
                      const values = sanitary_observations.map((observation: ApiaryObservation) => {
                        if (subHeader.name === 'label') {
                          if (!isNil(observation.other)) {
                            return observation.other;
                          } else {
                            return translateService.instant(observation.label);
                          }
                        }
                        return observation[subHeader.name] || '';
                      });
                      row[header.name][subHeader.name] = values.join('\r\n') || null;
                    });
                  }
                  return row;
                })
              )
            )
          );
          break;
        }

        // Get sanitary tests
        case 'sanitary_tests': {
          row_builder = row_builder.pipe(
            switchMap((row: ExportedRow) =>
              this.sanitary_tests$$.pipe(
                take(1),
                map((sanitary_tests: SanitaryTest[]) => {
                  row[header.name] = {};
                  if (!isNil(sanitary_tests)) {
                    header.sub_headers.forEach((subHeader: ExportHeader) => {
                      const values = sanitary_tests.map(test => {
                        const block: Dictionary<string> = { label: null, result: null, comment: null };

                        block.label =
                          test.type === 'VP100'
                            ? `${translateService.instant(test?.label)}, ${translateService.instant(
                                test?.VP100?.label
                              )}, ${translateService.instant(i18n<string>('EVENT.VISIT.VP100.Sample weight (g)'))} : ${
                                test?.VP100?.sample_weight
                              }`
                            : translateService.instant('EVENT.VISIT.Foulbrood test');

                        block.result =
                          test.type === 'VP100'
                            ? `${test?.VP100?.result}, ${translateService.instant(
                                i18n<string>('EVENT.VISIT.VP100.Counted number of varroas')
                              )} : ${test?.VP100?.number_varroas}`
                            : `${
                                test?.foulbrood?.result
                                  ? translateService.instant('VIEWS.MODALS.EVAL.Positif test result')
                                  : translateService.instant('VIEWS.MODALS.EVAL.Negatif test result')
                              }`;

                        block.comment = test.comment;

                        return block;
                      });
                      row[header.name][subHeader.name] = values.map(val => val[subHeader.name]).join('\r\n');
                    });
                  }
                  return row;
                })
              )
            )
          );
          break;
        }

        // Get sanitary treatements
        case 'sanitary_treatments': {
          row_builder = row_builder.pipe(
            switchMap((row: ExportedRow) =>
              this.sanitary_treatments$$.pipe(
                take(1),
                map((sanitary_treatments: SanitaryTreatment[]) => {
                  row[header.name] = {};
                  if (!isNil(sanitary_treatments)) {
                    header.sub_headers.forEach((subHeader: ExportHeader) => {
                      const values = sanitary_treatments.map(treatment => {
                        const block: Dictionary<string> = { label: null, comment: null };
                        block.label = `${translateService.instant(treatment.label)} : ${treatment?.test_type_b || treatment?.test_type_c}`;
                        block.comment = treatment.comment;
                        return block;
                      });
                      row[header.name][subHeader.name] = values.map(val => val[subHeader.name]).join('\r\n');
                    });
                  }
                  return row;
                })
              )
            )
          );
          break;
        }

        // // Get feeding
        // case 'feeding':
        //   if (event.type === 'evaluation') {
        //     row_builder = row_builder.pipe(
        //       switchMap(row => {
        //         return this.feeding$$.pipe(
        //           take(1),
        //           map((feeding: { [key: string]: any }) => {
        //             headers['subHeaders']
        //               .filter((subHeader: SubHeader) => subHeader.parent === header.original)
        //               .map((subHeader: SubHeader) => {
        //                 if (!_.isNil(feeding)) {
        //                   switch (subHeader.name) {
        //                     case 'feeding_type':
        //                       row.push(this.translateService.instant(feeding[subHeader.name]));
        //                       break;

        //                     case 'comment':
        //                       row.push(feeding[subHeader.name] || '');
        //                       break;

        //                     case 'quantity': {
        //                       let final_quantity = '';
        //                       if (feeding.hasOwnProperty('quantity_L')) {
        //                         final_quantity = this.translateService.instant(feeding['quantity_L']['label'])
        //                           + ' : '
        //                           + feeding['quantity_L']['value'];
        //                       } else if (feeding.hasOwnProperty('quantity_kg')) {
        //                         final_quantity = this.translateService.instant(feeding['quantity_kg']['label'])
        //                           + ' : '
        //                           + feeding['quantity_kg']['value'];
        //                       }
        //                       row.push(final_quantity);
        //                       break;
        //                     }
        //                   }
        //                 }
        //               })
        //             return row;
        //           })
        //         )
        //       })
        //     )
        //   } else {
        //     row_builder = row_builder.pipe(
        //       map(row => {
        //         headers['subHeaders']
        //           .filter((subHeader: SubHeader) => subHeader.parent === header.original)
        //           .forEach(() => row.push(''))
        //         return row;
        //       })
        //     )
        //   }
        //   break;
      }
    });

    return row_builder;
  }

  //#endregion

  // #region -> (comment)

  /** */
  public hf_comment$$ = this.data$$.pipe(
    map(data => data?.evaluation_apiary?.hf_prod_visit?.comment ?? null),
    replay()
  );

  // #endregion

  protected getDescKey(role: string, schema: IEventGlobalSchema, i18nData: I18NParams): Observable<string> {
    return super.getDescKey(role, schema, i18nData).pipe(
      map(() => {
        if (role === 'warehouse') {
          return i18n("EVENT.VISIT.Evaluation visit on apiary '[entities->apiary->name]' ([data->config_join])");
        }
        return i18n('EVENT.VISIT.Evaluation visit ([data->config_join])');
      })
    );
  }

  protected getI18nParams(translate_service: any, entities: any): Observable<{ entities: any; data: any; computed: any }> {
    return super.getI18nParams(translate_service, entities).pipe(
      switchMap((i18nParams: I18NParams) => {
        const apiary_config = i18nParams.data.config?.apiary || [];
        const hp_config = i18nParams.data.config?.hives_precise || [];
        const fast_config = i18nParams.data.config?.hives_fast?.hives_status_config || [];

        const full_config = [...new Set([...apiary_config, ...fast_config, ...hp_config])];
        if (full_config.length === 0) {
          full_config.push('no_config');
        }

        const config_keys = full_config.map((conf: string) => this.VISIT_CONFIG_I18N[conf] || 'DEFAULT');

        const desc_obs: Observable<I18NParams> = translate_service.stream(config_keys).pipe(
          map((translations: { [key: string]: string }) => {
            i18nParams.data.config_join = Object.values(translations).join(', ');
            return i18nParams;
          })
        );
        return desc_obs;
      })
    );
  }

  private readonly VISIT_CONFIG_I18N: { [key: string]: string } = {
    feeding: 'EVENT.VISIT.Feeding',
    sanitary: 'EVENT.VISIT.Sanitary',
    supers: 'ENTITY.ALL.TYPE.supers',
    queen: 'EVENT.VISIT.Queen colors',
    hive_status: 'EVENT.VISIT.Hives status',
    brood_frame: 'EVENT.VISIT.Brood frames',
    observations: 'EVENT.VISIT.Observations',
    hardware_stock: 'EVENT.VISIT.Hardware stock',
    no_config: i18n<string>('EVENT.VISIT.Not specified'),
  };
}
