import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { BehaviorSubject } from 'rxjs';
import { map, withLatestFrom } from 'rxjs';
import { replay } from '@bg2app/tools/rxjs';

import { isNil } from 'lodash-es';


import { formatDistance, isSameDay, max, min } from 'date-fns';

import { AppStateService } from 'app/core/app-state.service';

import { Bg2MapPopupComponent } from '../map-popup/map-popup.component';

import { getDistance } from 'app/misc/tools/geomap';
import { RoutePositionMarker } from 'app/typings/mapping/models/marker/RoutePositionMarker.model';

@Component({
  selector: 'bg2-map-popup-movement-line',
  templateUrl: './map-popup-movement-line.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class Bg2MapPopupMovementLineComponent extends Bg2MapPopupComponent {
  private _markers$$: BehaviorSubject<RoutePositionMarker[]> = new BehaviorSubject(null);
  public markers$$ = this._markers$$.asObservable().pipe(replay());

  @Input()
  public set markers(markers: RoutePositionMarker[]) {
    this._markers$$.next(markers);
  }

  // #region -> (component basics)

  constructor(private _appState: AppStateService) {
    super();
  }

  // #endregion

  public summarizedRouteInformations$$ = this.markers$$?.pipe(
    map(route_markers => route_markers.map(marker => marker.current_position)),
    withLatestFrom(this._appState.lang$$),
    map(([positions]) => {
      let initial_distance = 0;
      const dates: Date[] = [];

      // Gathering of required informations
      positions.forEach((position, index, self) => {
        // Check required properties to update movement distance
        if (!isNil(position.point?.gps_lat) || !isNil(position.point.gps_lng)) {
          const next_position = self?.[index + 1];

          if (!isNil(next_position?.point?.gps_lat) && !isNil(next_position?.point?.gps_lng)) {
            const dist_segment = getDistance(
              { latitude: position?.point?.gps_lat, longitude: position?.point?.gps_lng },
              { latitude: next_position?.point?.gps_lat, longitude: next_position?.point?.gps_lng }
            );

            initial_distance += dist_segment;
          }
        }

        dates?.push(position?.point?.date);
      });

      const minimal_date = min(dates);
      const maximale_date = max(dates);
      const resultant = {
        start_date: minimal_date,
        end_date: maximale_date,
        coloration: positions[0]?.color,
        is_same_day: isSameDay(minimal_date, maximale_date),
        duration: formatDistance(minimal_date, maximale_date, { locale: this._appState.dl.dateFns }),
        distance: initial_distance,
        total_points: positions?.length,
      };

      return resultant;
    }),
    replay()
  );
}
