import { AfterViewInit, ChangeDetectionStrategy, Component, OnDestroy, Renderer2 } from '@angular/core';

import { map, Observable, switchMap } from 'rxjs';
import { replay, waitForNotNilValue } from '@bg2app/tools/rxjs';

import { Beeguard2Api } from 'app/core';
import { ConsoleLoggerService } from 'app/core/console-logger.service';

import { Event } from 'app/models';

import { AbstractCarouselSlideComponent } from '../../abstract-carousel/abstract-carousel-slide/abtract-carousel-slide.component';

@Component({
  selector: 'bg2-event-carousel-slide',
  template: '',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EventBasedCarouselSlideComponent<EventType extends Event>
  extends AbstractCarouselSlideComponent
  implements AfterViewInit, OnDestroy
{
  // #region -> (component basics)

  protected _logger = new ConsoleLoggerService('EventBasedCarouselSlideComponent', true);

  constructor(protected _renderer: Renderer2, protected _bg2Api: Beeguard2Api) {
    super(_renderer);
  }

  ngAfterViewInit(): void {
    super.ngAfterViewInit();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  // #endregion

  // #region -> (related event management)

  /**
   * Observes the related event model.
   *
   * @public
   * @replay
   */
  public event$$: Observable<EventType> = this.static_parameters$$.pipe(
    map(static_parameters => static_parameters?.event_id),
    waitForNotNilValue(),
    switchMap(event_id => this._bg2Api.getEventObj(event_id) as Observable<EventType>),
    replay()
  );

  /**
   * Observes the event's ID.
   *
   * @public
   */
  public event_id$$ = this.event$$.pipe(switchMap(event => event.id$$));

  /**
   * Observes the event's date.
   *
   * @public
   */
  public event_date$$ = this.event$$.pipe(switchMap(event => event.date$$));

  /**
   * Observes the event's author name.
   */
  public event_author$$: Observable<string> = this.event$$.pipe(
    switchMap(event => event.author$$),
    map(user => user?.username)
  );

  // #endregion
}
