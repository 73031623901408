import { map, Observable } from 'rxjs';

import { marker as i18n } from '@biesbjerg/ngx-translate-extract-marker';

import { ErrorHelperData } from 'app/widgets/widgets-reusables/errors/error-helper/error-helper.component';

import { AbstractEntityUserACL, EntityUserAclContext } from '../../00_abstract';
import { ExploitationEntityUserAce, exploitation_user_ace_to_i18n } from '../enums';

/** */
export class ExploitationEntityUserACL extends AbstractEntityUserACL<keyof typeof ExploitationEntityUserAce> {
  // #region -> (ace checks)

  /**
   * Check if the current user can read devices.
   *
   * @returns An observable with the error model else null.
   *
   * @public
   * @observable
   */
  public check_can_read_devices$$ = (context: EntityUserAclContext): Observable<ErrorHelperData | null> =>
    this.can$$('read_devices').pipe(
      map(can_read_devices => {
        if (!can_read_devices) {
          return new ErrorHelperData([
            {
              type: 'image_svg',
              url: '/assets/img/pictos/lock.svg',
              styles: {
                width: '40px',
              },
            },
            {
              type: 'ace',
              content: i18n<string>("ALL.ACE.COMMON.You can't **[what]**"),
              translateParams: {
                what: context.what,
              },
            },
            {
              type: 'ace',
              content: i18n<string>(
                'ALL.ACE.COMMON.Ask **the exploitation\'s manager** to grant you the following permission: "**[ace]**"'
              ),
              translateParams: {
                ace: exploitation_user_ace_to_i18n.get('read_devices'),
              },
            },
          ]);
        }

        return null;
      })
    );

  /**
   * Check if the current user can write all.
   *
   * @returns An observable with the error model else null.
   *
   * @public
   * @observable
   */
  public check_can_write_all$$ = (context: EntityUserAclContext): Observable<ErrorHelperData | null> =>
    this.can$$('write_all').pipe(
      map(can_write_all => {
        if (!can_write_all) {
          return new ErrorHelperData([
            {
              type: 'image_svg',
              url: '/assets/img/pictos/lock.svg',
              styles: {
                width: '40px',
              },
            },
            {
              type: 'ace',
              content: i18n<string>("ALL.ACE.COMMON.You can't **[what]**"),
              translateParams: {
                what: context.what,
              },
            },
            {
              type: 'ace',
              content: i18n<string>(
                'ALL.ACE.COMMON.Ask **the exploitation\'s manager** to grant you the following permission: "**[ace]**"'
              ),
              translateParams: {
                ace: exploitation_user_ace_to_i18n.get('write_all'),
              },
            },
          ]);
        }

        return null;
      })
    );

  /**
   * Check if the current user can read events.
   *
   * @returns An observable with the error model else null.
   *
   * @public
   * @observable
   */
  public check_read_all_events$$ = (context: EntityUserAclContext): Observable<ErrorHelperData | null> =>
    this.can$$('read_all_events').pipe(
      map(can_read_devices => {
        if (!can_read_devices) {
          return new ErrorHelperData([
            {
              type: 'image_svg',
              url: '/assets/img/pictos/lock.svg',
              styles: {
                width: '40px',
              },
            },
            {
              type: 'ace',
              content: i18n<string>("ALL.ACE.COMMON.You can't **[what]**"),
              translateParams: {
                what: context.what,
              },
            },
            {
              type: 'ace',
              content: i18n<string>(
                'ALL.ACE.COMMON.Ask **the exploitation\'s manager** to grant you the following permission: "**[ace]**"'
              ),
              translateParams: {
                ace: exploitation_user_ace_to_i18n.get('read_all_events'),
              },
            },
          ]);
        }

        return null;
      })
    );

  /**
   * Check if the current user can write events.
   *
   * @returns An observable with the error model else null.
   *
   * @public
   * @observable
   */
  public check_write_all_events$$ = (context: EntityUserAclContext): Observable<ErrorHelperData | null> =>
    this.can$$('write_all_events').pipe(
      map(can_read_devices => {
        if (!can_read_devices) {
          return new ErrorHelperData([
            {
              type: 'image_svg',
              url: '/assets/img/pictos/lock.svg',
              styles: {
                width: '40px',
              },
            },
            {
              type: 'ace',
              content: i18n<string>("ALL.ACE.COMMON.You can't **[what]**"),
              translateParams: {
                what: context.what,
              },
            },
            {
              type: 'ace',
              content: i18n<string>(
                'ALL.ACE.COMMON.Ask **the exploitation\'s manager** to grant you the following permission: "**[ace]**"'
              ),
              translateParams: {
                ace: exploitation_user_ace_to_i18n.get('write_all_events'),
              },
            },
          ]);
        }

        return null;
      })
    );

  // #endregion

  // #region -> (error-scoped ACE check)

  /**
   * Check if the current user cannot do specific ace.
   *
   * @returns An observable with the error model else null.
   *
   * @public
   * @observable
   */
  public throw__if_cannot$$ = (ace: keyof typeof ExploitationEntityUserAce, context: string): Observable<ErrorHelperData | null> =>
    this.can$$(ace).pipe(
      map(can_read_devices => {
        if (!can_read_devices) {
          return new ErrorHelperData([
            {
              type: 'image_svg',
              url: '/assets/img/pictos/lock.svg',
              styles: {
                width: '40px',
              },
            },
            {
              type: 'ace',
              content: i18n<string>("ALL.ACE.COMMON.You can't **[what]**"),
              translateParams: {
                what: context,
              },
            },
            {
              type: 'ace',
              content: i18n<string>(
                'ALL.ACE.COMMON.Ask **the exploitation\'s manager** to grant you the following permission: "**[ace]**"'
              ),
              translateParams: {
                ace: exploitation_user_ace_to_i18n.get(ace),
              },
            },
          ]);
        }

        return null;
      })
    );

  // #endregion
}
