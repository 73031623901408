import { Observable, of, combineLatest, iif, throwError, catchError, forkJoin } from 'rxjs';
import { map, switchMap, tap, filter, startWith } from 'rxjs';

import { concat, isEmpty, isEqual, isNil, sortBy, sum, union, values } from 'lodash-es';
import { marker as i18n } from '@biesbjerg/ngx-translate-extract-marker';
import { differenceInDays, startOfYear } from 'date-fns';

import { environment } from 'environments/environment';

import { Beeguard2Api, DeviceApi, GeocodingService } from 'app/core';
import { distinctUntilRealChanged, replay, robustCombineLatest } from '@bg2app/tools/rxjs';
import { GeocodingReverseOptions } from 'app/misc/services/geocoding.service';

import { Entity, IEntityStaticState } from '../../00_abstract/classes/Entity';
import { Warehouse } from '../../01_warehouse';
import { Location } from '../../03_location';
import { DeviceLocation, DRDevice, ValidDeviceLocation } from '../../../devices/DRDevice';
import { SetupLocation } from '../../../events/LocationBasic';

import { getDistance } from 'app/misc/tools/geomap';
import { DevicesAtOneLocation, GhostSolution, GhostSolutionAlternatives, GhostSolutionLevel } from 'app/core/ghost/models/ghost-solution';
import { mergeDeviceByPreviousLocation } from 'app/misc/tools/devices.helpers';
import { User } from '../../../user';
import { ExploitationEntityUserACL } from './exploitation-entity-user-acl.class';
import { ErrorHelperData } from 'app/widgets/widgets-reusables/errors/error-helper/error-helper.component';
import { get_i18n_for_zoho_error, ZohoCRMModuleName, ZohoError } from '../../../zoho';

export interface PossibleLocation {
  devices: DRDevice[];
  position: ValidDeviceLocation;
  address?: string;
}

interface IExploitationStaticState extends IEntityStaticState {
  crm_id?: string;
  id_v1: number | null;
  warehouse_id: number | null;
  position: {
    address?: string;
    latitude?: number;
    longitude?: number;
    elevation?: number;
  };
}

/**
 * Do load warehouse
 */
export class Exploitation extends Entity<IExploitationStaticState> {
  public warehouse_id: number;
  public warehouse_id$$: Observable<number> = this.static_state$$.pipe(
    filter(static_state => !isNil(static_state) && !isNil(static_state.warehouse_id)),
    map(static_state => static_state.warehouse_id),
    tap(warehouse_id => (this.warehouse_id = warehouse_id))
  );

  public warehouse: Warehouse;
  public warehouse$$: Observable<Warehouse> = this.warehouse_id$$.pipe(
    switchMap(warehouse_id => (warehouse_id ? this.bg2Api.getEntityObj(warehouse_id) : of(null))),
    map(warehouse => warehouse as Warehouse),
    tap(warehouse => (this.warehouse = warehouse))
  );

  // #region -> (model basics)

  /** */
  constructor(protected bg2Api: Beeguard2Api, protected deviceApi: DeviceApi) {
    super(bg2Api, deviceApi);
    this.type = 'exploitation';
  }

  public crm_id$$ = this.static_state$$.pipe(
    map(static_state => static_state.crm_id as string),
    distinctUntilRealChanged(),
    replay()
  );

  // #endregion

  // #region -> (exploitation owner)

  /**
   * Observes exploitation's owner ID.
   */
  public owner_id$$ = this.updates$.pipe(
    map(self => self.user_id),
    distinctUntilRealChanged()
  );

  /**
   * Observes exploitation's owner object.
   */
  public owner$$: Observable<User> = this.owner_id$$.pipe(
    filter(owner_id => !isNil(owner_id)),
    switchMap(owner_id => this.bg2Api.userApi.getUserObj(owner_id)),
    replay()
  );

  /**
   * Observes exploitation's owner name.
   */
  public owner_name$$ = this.owner$$.pipe(
    map(owner => owner?.name ?? owner?.username ?? null),
    distinctUntilRealChanged()
  );

  // #endregion

  // #region -> (acl management)

  /**
   * @inheritdoc
   */
  public user_acl = new ExploitationEntityUserACL();

  // #endregion

  // #region -> (devices management)

  /**
   * Observe's the exploitation's devices config.
   */
  public devices_config$$ = this.warehouse$$.pipe(switchMap((warehouse: Warehouse) => warehouse.devices_config$$));

  /**
   * Observe's the exploitation's devices.
   */
  public devices$$: Observable<DRDevice[]> = this.warehouse$$.pipe(switchMap((warehouse: Warehouse) => warehouse.devices$$));

  /**
   * Observe's if the exploitation has devices.
   */
  public has_devices$$ = this.devices_config$$.pipe(
    map(devices_config => {
      if (isNil(devices_config)) {
        return false;
      }

      if (isEmpty(devices_config)) {
        return false;
      }

      return true;
    })
  );

  // #endregion

  /** Monitor exploitation/warehouse devices that are not associated, or associated to an incompatible locations
   *
   * This is the "root" method to build all ghost
   */
  public badly_associated_devices$$: Observable<DRDevice[]> = this.devices$$.pipe(
    // tap(devices => this._logger.ghost('Devices:', devices.map(dev => dev.name))),
    //
    // Filter devices with no locations (ie gps/gprs position)
    map(devices => devices.filter(device => device.location && device.location.longitude && device.location.latitude)),
    //
    // Filter devices with no com since too long
    map(devices =>
      devices.filter((device: DRDevice) => {
        const last_com_date: Date = device.last_contact || new Date(2000, 1, 1);
        //this._logger.debug('DEBUG bad device ?', device.imei, device.last_contact, differenceInDays(new Date(), last_com_date));
        return differenceInDays(new Date(), last_com_date) < environment.config.ghost.last_com_max_days_threshold;
      })
    ),
    // tap(devices => this._logger.ghost('Devices with recent location:', devices.map(dev => dev.name))),
    //
    // Filter devices that are at @beeguard office
    map(devices =>
      devices.filter(
        (device: DRDevice) =>
          !device.isDeviceInLatLngRange(
            { lat: 43.5453575, lng: 1.5016686 }, // TODO move it in a ghost config
            environment.config.ghost.default_merge_range_meter
          )
      )
    ),
    // tap(devices => this._logger.ghost('Devices not at BeeGaurd office:', devices.map(dev => dev.name))),
    switchMap(filtered_devices => {
      const compatible_locations = filtered_devices.map(device =>
        device
          .isLocationCompatible(this.bg2Api, true)
          .pipe
          // tap(comp => this._logger.ghost(device.name, comp))
          ()
      );
      return robustCombineLatest(compatible_locations).pipe(
        map(location_compatible => filtered_devices.filter((device, index) => !location_compatible[index]))
      );
    }),
    // tap(devices => this._logger.ghost('Devices badly associated:', devices.map(dev => dev.name))),
    distinctUntilRealChanged(),
    replay()
  );

  public devices_nbr$$ = this.warehouse$$.pipe(
    switchMap(devices_nbr => devices_nbr.devices_nbr$$),
    distinctUntilRealChanged(),
    replay()
  );

  public devices_wg_nbr$$ = this.warehouse$$.pipe(
    switchMap(wg_nbr => wg_nbr.devices_wg_nbr$$),
    distinctUntilRealChanged(),
    replay()
  );

  public devices_gps_nbr$$ = this.warehouse$$.pipe(
    switchMap(gps_nbr => gps_nbr.devices_gps_nbr$$),
    distinctUntilRealChanged(),
    replay()
  );

  public devices_rg_nbr$$ = this.warehouse$$.pipe(
    switchMap(rg_nbr => rg_nbr.devices_rg_nbr$$),
    distinctUntilRealChanged(),
    replay()
  );

  public address$$: Observable<string> = this.static_state$$.pipe(
    map(static_state => static_state?.position?.address),
    distinctUntilRealChanged(),
    replay()
  );

  public archived_locations_ids$$: Observable<number[]> = this.state$$.pipe(
    map(state => state.archived_location_ids || []),
    startWith([]),
    distinctUntilRealChanged(),
    replay()
  );

  public locations_ids$$: Observable<number[]> = this.state$$.pipe(
    map(state => state?.location_ids || null),
    distinctUntilRealChanged(),
    replay()
  );

  public named_locations_ids$$: Observable<number[]> = this.named_state$$.pipe(
    map(state => state.location_ids || []),
    startWith([]),
    distinctUntilRealChanged(),
    replay()
  );

  public locations_nbr$$: Observable<number> = this.locations_ids$$.pipe(
    map(location_ids => location_ids?.length || 0),
    distinctUntilRealChanged(),
    replay()
  );

  public locations$$: Observable<Location[]> = this.locations_ids$$.pipe(
    switchMap(locations_ids => this.bg2Api.getEntitiesObj(locations_ids, 'location', false)),
    map(active_locations => active_locations as Location[]),
    replay()
  );

  public named_locations$$: Observable<Location[]> = this.named_locations_ids$$.pipe(
    switchMap(locations_ids => this.bg2Api.getEntitiesObj(locations_ids, 'location', false)),
    map(active_locations => active_locations as Location[]),
    replay()
  );

  public locations_archived$$: Observable<Location[]> = this.archived_locations_ids$$.pipe(
    switchMap(locations_ids => this.bg2Api.getEntitiesObj(locations_ids, 'location', false)),
    map(active_locations => active_locations as Location[]),
    replay()
  );

  public locations_archived_or_not$$: Observable<Location[]> = combineLatest([this.locations$$, this.locations_archived$$]).pipe(
    map(([locations, archived_locations]) => locations.concat(archived_locations)),
    replay()
  );

  public named_locations_archived_or_not$$: Observable<Location[]> = combineLatest([
    this.named_locations$$,
    this.locations_archived$$,
  ]).pipe(
    map(([locations, archived_locations]) => locations.concat(archived_locations)),
    replay()
  );

  // Cf. app-state.service.ts L.1007
  public not_empty_locations$$ = this.locations$$.pipe(
    switchMap(locations => {
      const locations_has_apiary$$ = locations.map(location => location.has_apiary$$.pipe(map(has_apiary => [location, has_apiary])));
      return robustCombineLatest(locations_has_apiary$$);
    }),
    map(locations_has_apiary => {
      locations_has_apiary = locations_has_apiary.filter(([location, has_apiary]) => has_apiary);
      return locations_has_apiary.map(([location]: [Location, boolean]) => location);
    }),
    distinctUntilRealChanged(),
    replay()
  );

  public apiary_nbr$$: Observable<number> = this.not_empty_locations$$.pipe(
    map(apiaries => apiaries.length),
    distinctUntilRealChanged(),
    replay()
  );

  public hives_nbr$$: Observable<number> = this.not_empty_locations$$.pipe(
    switchMap(locations => robustCombineLatest(locations.map(location => location.hives_nbr$$))),
    map(hives_nbr_for_locs => sum(hives_nbr_for_locs)),
    distinctUntilRealChanged(),
    replay()
  );

  // #region -> (ghost locations management)
  public badly_associated_devices_by_location$$: Observable<{ [location_id: number]: DevicesAtOneLocation }> = combineLatest([
    this.badly_associated_devices$$,
    this.named_locations$$,
    this.warehouse$$.pipe(switchMap(wh => wh.blacklisted_ghost_locations$$)),
  ]).pipe(
    map(([badly_associated_devices, locations, blacklisted_locations]) => {
      const dev_by_location: { [lid: number]: DevicesAtOneLocation } = {};
      badly_associated_devices.forEach(device => {
        const compatible_locations = locations.filter(location =>
          device.isDeviceInLocationRange(location, environment.config.ghost.default_merge_range_meter)
        );

        let selected_loc: Location = null;
        let alt_locations: Location[] = [];
        if (compatible_locations.length === 1) {
          // if one compatible location,  simple
          selected_loc = compatible_locations[0];
        } else if (compatible_locations.length > 1) {
          // if more than one, take the closest, add alt
          const sorted_compatible_locations = sortBy(
            compatible_locations,
            location =>
              -getDistance(
                { latitude: location.position.latitude, longitude: location.position.longitude },
                { latitude: device.location.latitude, longitude: device.location.longitude },
                0
              )
          );
          selected_loc = sorted_compatible_locations[0];
          alt_locations = sorted_compatible_locations.slice(1);
        }

        const selected_loc_id = selected_loc?.id || -1;
        if (!dev_by_location[selected_loc_id]) {
          dev_by_location[selected_loc_id] = {
            devices: [],
            alt_locations: [],
            location: selected_loc,
          };
        }
        dev_by_location[selected_loc_id].devices.push(device);
        dev_by_location[selected_loc_id].alt_locations = union(dev_by_location[selected_loc_id].alt_locations, alt_locations);
      });
      return dev_by_location;
    }),
    replay()
  );

  public possible_new_locations$$: Observable<PossibleLocation[]> = combineLatest([
    this.badly_associated_devices_by_location$$,
    this.named_locations$$, // We do not whant to check archived locations
    this.warehouse$$.pipe(switchMap(wh => wh.blacklisted_ghost_locations$$)),
  ]).pipe(
    map(([badly_associated_devices_by_location, locations, blacklisted_locations]) => {
      const possible_new_locations: PossibleLocation[] = [];
      const badly_associated_devices = badly_associated_devices_by_location[-1]?.devices || [];
      badly_associated_devices.forEach(device => {
        const compatible_locations = locations.filter(location =>
          device.isDeviceInLocationRange(location, environment.config.ghost.default_merge_range_meter)
        );
        if (compatible_locations.length === 0) {
          possible_new_locations.push({
            devices: [device],
            position: device.location as ValidDeviceLocation,
          });
        }
      });
      return possible_new_locations.filter(possible_location => {
        const in_range = blacklisted_locations
          .map(
            pos =>
              getDistance(
                { latitude: possible_location.position.latitude, longitude: possible_location.position.longitude },
                { latitude: pos.lat, longitude: pos.lng },
                5
              ) < 50
          )
          .filter(Boolean);
        return in_range.length === 0;
      });
    }),
    map((possible_new_locations: PossibleLocation[]) => {
      const filtered_possible_new_locations: PossibleLocation[] = [];
      possible_new_locations.map((newloc: PossibleLocation, idx: number) => {
        const similar = possible_new_locations.slice(0, idx).filter((newloc_other: PossibleLocation) => {
          const distance = getDistance(newloc.position, newloc_other.position, 1);
          return distance < environment.config.ghost.default_merge_range_meter;
        });

        if (similar.length === 0) {
          filtered_possible_new_locations.push(newloc);
        } else {
          similar.map((newloc_other: PossibleLocation) => {
            newloc_other.devices = concat(newloc_other.devices, newloc.devices);
          });
        }
      });
      return filtered_possible_new_locations;
    })
  );

  get name(): string {
    return this.static_state.name;
  }

  get description(): string {
    return i18n<string>(`ENTITY.EXPLOITATION.Exploitation "[name]"`);
  }

  get nameLower(): string {
    // Used to sort exploitation list
    return this.name.toLowerCase();
  }

  get id_v1(): number {
    return this.static_state.id_v1;
  }

  get url_v1(): string {
    if (this.id_v1) {
      return 'https://beeguard.fr/index.php/exploitations/' + this.id_v1;
    }
    return null;
  }

  public link$$ = this.name$$.pipe(
    distinctUntilRealChanged(),
    map(name => `<a href="javascript:void(/exploitations, ${this.id})">${name}</a>`),
    replay()
  );

  public static compare(exploitation_A: Exploitation, exploitation_B: Exploitation, by: string = 'id'): boolean {
    return isEqual(exploitation_A.get(by), exploitation_B.get(by));
  }

  /**
   * Build all the ghost locations
   *
   * @returns observable over the ALL the exploitation's locations
   */
  public buildSolutionForNewPossibleLocations$$(lang: string, geocoding: GeocodingService): Observable<GhostSolutionAlternatives[]> {
    return this.possible_new_locations$$.pipe(
      switchMap(possible_locations => {
        this._logger.ghost('New possible locations are : ', possible_locations);
        const possible_locations$$ = possible_locations.map(location =>
          this.buildSolutionsForNewPossibleLocation(location.position, location.devices, lang, geocoding)
        );
        return robustCombineLatest(possible_locations$$);
      })
    );
  }

  public exploitation_summary$$ = this.warehouse$$.pipe(
    switchMap(warehouse => warehouse.state$$),
    switchMap(wh_state => this.locations$$.pipe(map(locations => ({ locations, wh_state })))),
    switchMap(({ locations, wh_state }) => {
      if (isEmpty(locations || [])) {
        return of({ wh_state, locations: [], apiaries: [] });
      }

      const apiaries$$ = locations.map(location => location.apiary$$);
      return combineLatest(apiaries$$).pipe(map(apiaries => ({ wh_state, locations, apiaries })));
    }),
    map(({ wh_state, locations, apiaries }) => {
      const summary = {
        location: {
          nb_archived: 0,
          nb_total: 0,
        },
        apiary: {
          nb_total: 0,
        },
        hives: {
          nb_total: 0,
        },
        superbox: {
          nb_in_stock_on_apiaries: 0,
          nb_in_stock_on_warehouse: 0,
          nb_on_hives: 0,
          where_stock: [] as any[],
        },
      };

      if (apiaries?.length > 0) {
        apiaries?.forEach((apiary, index) => {
          const apiary_location = locations[index];
          summary.location.nb_total += 1;

          if (!isNil(apiary)) {
            summary.hives.nb_total += apiary.nb_declared_hives || 0;
            summary.apiary.nb_total += 1;

            if (apiary?.state?.superbox?.stock) {
              summary.superbox.nb_in_stock_on_apiaries += apiary.state.superbox.stock || 0;
              if (apiary.state.superbox.stock > 0) {
                summary.superbox.where_stock.push({
                  location_id: apiary_location.id,
                  location_name: apiary_location.name,
                  nb_in_stock: apiary.state.superbox.stock,
                });
              }
            }
          }
        });
      }

      if (wh_state?.inventory?.superbox) {
        const supers_inventory = wh_state?.inventory?.superbox;
        summary.superbox.nb_in_stock_on_warehouse = supers_inventory?.length;
        summary.superbox.nb_on_hives = sum(values(supers_inventory?.used_on_apiary));
      }

      return summary;
    })
  );

  public archivable$$ = of(false);

  public buildSolutionsForNewPossibleLocation(
    position: DeviceLocation,
    devices: DRDevice[],
    lang: string,
    geocoding: GeocodingService
  ): Observable<GhostSolutionAlternatives> {
    const lnglat = {
      lat: position.latitude,
      lng: position.longitude,
    };
    const options: GeocodingReverseOptions = {
      lang,
      level: ['street_address', 'route', 'locality'],
    };

    return combineLatest([
      mergeDeviceByPreviousLocation(devices, this.bg2Api),
      geocoding.reverse([lnglat], options),
      geocoding.elevation(lnglat, options),
    ]).pipe(
      map(([devices_by_previous_location, addresses, elevation]) => {
        const address = addresses[0].values?.locality || addresses[0]?.values?.route; // TODO manage if not available ?
        const loc_name = address.address_components[0].short_name;
        const loc_address = address.formatted_address;

        const new_location_solution = new GhostSolution('create_location');
        new_location_solution.setDevices(devices);
        const ghost_location_ref = new_location_solution.newGhostEntity('location', {
          name: loc_name,
          position: {
            address: loc_address,
            latitude: position.latitude,
            longitude: position.longitude,
            elevation,
          },
          _apiary: null,
        });
        const setup_location = new SetupLocation(this.bg2Api);
        setup_location.date = startOfYear(new Date());
        setup_location.setOperand('exploitation', this.id);
        new_location_solution.addEvent(setup_location, ['location', ghost_location_ref]);
        new_location_solution.setRemainingDevices(devices_by_previous_location);
        new_location_solution.setDescription(i18n('GHOST.SOLUTION.Declare a new location'), {});

        const alternatives = new GhostSolutionAlternatives(GhostSolutionLevel.LVL_LOCATION);
        alternatives.setDevices(devices);
        alternatives.add(new_location_solution);
        return alternatives;
      })
    );
  }

  // #region -> (error management)

  /**
   * Dictionnary of prebuilt error models.
   *
   * @public
   */
  public prebuilt_error_models = {
    /** */
    get_error_ask_quote$$: (): Observable<ErrorHelperData> =>
      of(null).pipe(
        map(
          () =>
            new ErrorHelperData([
              {
                type: 'span',
                content: i18n<string>('ENTITY.EXPLOITATION.ERRORS.No equipped devices on this exploitation'),
              },
              {
                type: 'button',
                message: i18n<string>('ROUTABLE_MODALS.NEW_ISSUE.TYPES.ASK_QUOTE.Ask for a quote'),
                navigate: {
                  commands: ['', { outlets: { modal: ['new_issue'] } }],
                },
              },
            ])
        )
      ),
  };

  // #endregion

  // #region -> (zoho CRM management)

  /** */
  public crm_id_or_error$$ = this.crm_id$$.pipe(
    switchMap(crm_id =>
      of(crm_id).pipe(
        switchMap(_crm_id => {
          if (isNil(_crm_id)) {
            return throwError(() => new Error(get_i18n_for_zoho_error(ZohoError.MISSING_CRM_ID_IN_EXPLOITATION)));
          }

          return of(crm_id);
        }),
        catchError((error: unknown) => of(<Error>error))
      )
    )
  );

  /** */
  public zoho_crm_account$$ = this.crm_id_or_error$$.pipe(
    switchMap(crm_id_or_error => {
      if (crm_id_or_error instanceof Error) {
        return of(crm_id_or_error);
      }

      return this.bg2Api.zohoApis.crm_api.fetch_record$(ZohoCRMModuleName.Accounts, crm_id_or_error, []);
    }),
    replay()
  );

  /** */
  public zoho_books_contact$$ = this.zoho_crm_account$$.pipe(
    switchMap(crm_account_or_error => {
      if (crm_account_or_error instanceof Error) {
        return of(crm_account_or_error);
      }

      return of(crm_account_or_error).pipe(
        switchMap(_crm_contact => {
          if (isNil(_crm_contact)) {
            return of(null);
          }

          return this.bg2Api.zohoApis.books_api.search_books_contact_from_crm_account$(_crm_contact.id);
        }),
        catchError((error: unknown) => of(<Error>error))
      );
    }),
    map(error_or_books_contact => {
      if (error_or_books_contact instanceof Error) {
        return error_or_books_contact;
      }

      return error_or_books_contact;
    }),
    replay()
  );

  // #endregion

  // #region -> (zoho desk management)

  /** */
  public zoho_desk_account$$ = this.zoho_crm_account$$.pipe(
    switchMap(zoho_crm_account => {
      if (zoho_crm_account instanceof Error) {
        return of(zoho_crm_account);
      }

      return of(zoho_crm_account).pipe(
        switchMap(_zoho_crm_account => {
          if (isNil(_zoho_crm_account)) {
            return throwError(() => new Error(i18n<string>(get_i18n_for_zoho_error(ZohoError.UNDEFINED_ZOHO_CRM_ACCOUNT))));
          }

          return this.bg2Api.zohoApis.desk_api
            .search_accounts$({ accountName: _zoho_crm_account?.Account_Name })
            .pipe(map(response => response?.data));
        }),
        catchError((error: unknown) => of(<Error>error))
      );
    }),
    switchMap(zoho_desk_accounts_or_error => {
      if (zoho_desk_accounts_or_error instanceof Error) {
        return of(zoho_desk_accounts_or_error);
      }

      return of(zoho_desk_accounts_or_error).pipe(
        switchMap(_zoho_desk_accounts_or_error => {
          if (isEmpty(_zoho_desk_accounts_or_error ?? [])) {
            return throwError(() => new Error(i18n<string>(get_i18n_for_zoho_error(ZohoError.UNDEFINED_ZOHO_DESK_ACCOUNT))));
          }

          const zoho_desk_account_ids$$ = _zoho_desk_accounts_or_error
            .map(zoho_desk_account => zoho_desk_account.id)
            .map(zoho_desk_account_id => this.bg2Api.zohoApis.desk_api.fetch_module$('accounts', zoho_desk_account_id));

          return forkJoin(zoho_desk_account_ids$$);
        }),
        catchError((error: unknown) => of(<Error>error))
      );
    }),
    switchMap(zoho_desk_accounts_or_error => {
      if (zoho_desk_accounts_or_error instanceof Error) {
        return of(zoho_desk_accounts_or_error);
      }

      return this.crm_id$$.pipe(
        switchMap(crm_id =>
          of({ _crm_id: crm_id, _zoho_desk_accounts_or_error: zoho_desk_accounts_or_error }).pipe(
            switchMap(({ _crm_id, _zoho_desk_accounts_or_error }) => {
              const related_zoho_desk_account = _zoho_desk_accounts_or_error.find(zoho_desk_account => {
                const crm_id_to_test = zoho_desk_account?.zohoCRMAccount?.id;
                return crm_id_to_test === _crm_id;
              });

              if (isNil(related_zoho_desk_account)) {
                return throwError(() => new Error(i18n<string>(get_i18n_for_zoho_error(ZohoError.UNDEFINED_ZOHO_DESK_ACCOUNT))));
              }

              return of(related_zoho_desk_account);
            }),
            catchError((error: unknown) => of(<Error>error))
          )
        )
      );
    }),
    replay()
  );

  /** */
  public desk_account_have_desk_contact$$ = this.zoho_desk_account$$.pipe(
    switchMap(zoho_desk_account => {
      if (zoho_desk_account instanceof Error) {
        return of(zoho_desk_account);
      }

      return this.bg2Api.zohoApis.desk_api.fetch_account_contacts$(zoho_desk_account?.id).pipe(map(response => response?.data));
    }),
    switchMap(assoc_zoho_desk_contacts => {
      if (assoc_zoho_desk_contacts instanceof Error) {
        return of(assoc_zoho_desk_contacts);
      }

      if (isEmpty(assoc_zoho_desk_contacts)) {
        return of(false);
      }

      return this.owner$$.pipe(
        switchMap(owner => owner?.zoho_desk_contact$$),
        map(owner_desk_contact => {
          if (owner_desk_contact instanceof Error) {
            return owner_desk_contact;
          }

          return owner_desk_contact?.id;
        }),
        map(zoho_desk_contact_id => {
          if (zoho_desk_contact_id instanceof Error) {
            return zoho_desk_contact_id;
          }

          const found_zoho_desk_contact = assoc_zoho_desk_contacts.find(zoho_desk_contact => zoho_desk_contact.id === zoho_desk_contact_id);

          if (isNil(found_zoho_desk_contact)) {
            return false;
          }

          return true;
        })
      );
    }),
    replay()
  );

  // public zoho_desk_account$$ = this.owner$$.pipe(
  //   switchMap(owner =>
  //     of(owner).pipe(
  //       switchMap(_owner => {
  //         if (isNil(_owner)) {
  //           return throwError(() => new Error(i18n<string>("ENTITY.EXPLOITATION.ERRORS.No exploitation's owner")));
  //         }

  //         return owner.zoho_desk_contact$$;
  //       }),
  //       catchError((error: unknown) => of(<Error>error))
  //     )
  //   ),
  //   switchMap(zoho_desk_contact => {
  //     if (zoho_desk_contact instanceof Error) {
  //       return of(zoho_desk_contact);
  //     }

  //     return of(zoho_desk_contact).pipe(
  //       switchMap(_zoho_desk_contact => {
  //         if (isNil(_zoho_desk_contact)) {
  //           return throwError(() => new Error(get_i18n_for_zoho_error(ZohoError.UNDEFINED_ZOHO_DESK_CONTACT)));
  //         }

  //         if (isNil(_zoho_desk_contact?.accountId)) {
  //           return throwError(() => new Error(get_i18n_for_zoho_error(ZohoError.NO_ZOHO_DESK_ACCOUNT_LINKED_TO_DESK_CONTACT)));
  //         }

  //         return this.crm_id$$.pipe(map(crm_account_id => ({ crm_account_id, _zoho_desk_contact: zoho_desk_contact })));
  //       }),
  //       catchError((error: unknown) => of(<Error>error))
  //     );
  //   }),
  //   switchMap(composite => {
  //     if (composite instanceof Error) {
  //       return of(composite);
  //     }

  //     return of(composite).pipe(
  //       switchMap(_composite => {
  //         if (isNil(_composite.crm_account_id)) {
  //           return throwError(() => new Error(get_i18n_for_zoho_error(ZohoError.MISSING_CRM_ID_IN_EXPLOITATION)));
  //         }

  //         return this.bg2Api.zohoApis.desk_api
  //           .fetch_module$('accounts', _composite?._zoho_desk_contact?.accountId)
  //           .pipe(map(zoho_desk_account => ({ zoho_desk_account, crm_account_id: composite.crm_account_id })));
  //       }),
  //       catchError((error: unknown) => of(<Error>error))
  //     );
  //   }),
  //   switchMap(composite_value => {
  //     if (composite_value instanceof Error) {
  //       return of(composite_value);
  //     }

  //     return of(composite_value).pipe(
  //       switchMap(_composite_value => {
  //         const zoho_crm_account_id_from_desk = composite_value?.zoho_desk_account?.zohoCRMAccount?.id ?? null;

  //         if (isNil(zoho_crm_account_id_from_desk)) {
  //           return throwError(() => new Error('no crm account linked to desk account'));
  //         }

  //         if (_composite_value.crm_account_id !== zoho_crm_account_id_from_desk) {
  //           return throwError(
  //             () =>
  //               new Error(
  //                 `Related CRM account ID of fetched desk account (#${zoho_crm_account_id_from_desk}) is different of exploitation CRM account ID (#${_composite_value.crm_account_id})`
  //               )
  //           );
  //         }

  //         return of(_composite_value?.zoho_desk_account);
  //       }),
  //       catchError((error: unknown) => of(<Error>error))
  //     );
  //   }),
  //   replay()
  // );

  // #endregion
}
