import { isNil } from 'lodash-es';

import { BehaviorSubject } from 'rxjs';

import { ZohoBaseModel } from '../../../../abstract';
import { parseDate } from 'app/misc/tools';

import { IZohoDeskTicketComment } from '../interfaces';

/** */
export class ZohoDeskTicketComment extends ZohoBaseModel<IZohoDeskTicketComment> {
  // #region -> (model basics)

  constructor() {
    super();
  }

  // #endregion

  // #region -> (comment infos)

  /** */
  private _modifiedTime$$ = new BehaviorSubject<Date>(null);

  /** */
  public modifiedTime$$ = this._modifiedTime$$.asObservable();

  /** */
  public set modifiedTime(modifiedTime: Date) {
    if (isNil(modifiedTime)) {
      this._modifiedTime$$.next(modifiedTime);
      return;
    }

    const date = parseDate(modifiedTime);
    this._modifiedTime$$.next(date);
  }

  /** */
  public get modifiedTime(): Date {
    return this._modifiedTime$$.getValue();
  }

  /** */
  private _commentedTime$$ = new BehaviorSubject<Date>(null);

  /** */
  public commentedTime$$ = this._commentedTime$$.asObservable();

  /** */
  public set commentedTime(commentedTime: Date) {
    if (isNil(commentedTime)) {
      this._commentedTime$$.next(commentedTime);
      return;
    }

    const date = parseDate(commentedTime);
    this._commentedTime$$.next(date);
  }

  /** */
  public get commentedTime(): Date {
    return this._commentedTime$$.getValue();
  }

  /** */
  private _commenter$$ = new BehaviorSubject<IZohoDeskTicketComment['commenter']>(null);

  /** */
  public commenter$$ = this._commenter$$.asObservable();

  /** */
  public set commenter(commenter: IZohoDeskTicketComment['commenter']) {
    this._commenter$$.next(commenter);
  }

  /** */
  public get commenter(): IZohoDeskTicketComment['commenter'] {
    return this._commenter$$.getValue();
  }

  // #endregion

  // #region -> (comment content)

  /** */
  private _content$$ = new BehaviorSubject<string>(null);

  /** */
  public content$$ = this._content$$.asObservable();

  /** */
  public set content(content: string) {
    this._content$$.next(content);
  }

  /** */
  public get content(): string {
    return this._content$$.getValue();
  }

  // #endregion
}
