<div
  style="display: flex; flex-flow: row wrap"
  class="ng-mat-number-range-form-field"
  [class.as-fields]="options.display_as === 'fields'"
  [class.as-slider]="options.display_as === 'slider'"
>
  <ng-container *ngIf="options.display_as === 'fields'">
    <div style="flex: 1 1 50%">
      <mat-form-field class="ng-default" appearance="outline">
        <mat-label>{{ options?.labelOnMin | translate }}</mat-label>
        <input
          matInput
          type="number"
          class="browser-default"
          [max]="max$$ | async"
          [min]="options?.floor"
          [step]="options?.step"
          [ngModel]="min$$ | async"
          [placeholder]="options?.labelOnMin | translate"
          (ngModelChange)="min = $event"
        />
        <span *ngIf="options?.suffixOnMin" class="mdi {{ options.suffixOnMin }}" matSuffix></span>
      </mat-form-field>
    </div>

    <div style="flex: 1 1 50%">
      <mat-form-field class="ng-default" appearance="outline">
        <mat-label>{{ options?.labelOnMax | translate }}</mat-label>
        <input
          matInput
          type="number"
          class="browser-default"
          [max]="options?.ceil"
          [min]="min$$ | async"
          [step]="options?.step"
          [ngModel]="max$$ | async"
          [placeholder]="options?.labelOnMax | translate"
          (ngModelChange)="max = $event"
        />
        <span *ngIf="options?.suffixOnMax" class="mdi {{ options.suffixOnMax }}" matSuffix></span>
      </mat-form-field>
    </div>
  </ng-container>

  <ng-container *ngIf="options?.display_as === 'slider'">
    <ngx-slider
      style="flex: 1 1 100%"
      *ngIf="is_min_max$$ | async"
      [options]="options"
      [value]="min$$ | async"
      [highValue]="max$$ | async"
      (valueChange)="min = $event"
      (highValueChange)="max = $event"
    >
    </ngx-slider>
    <span style="flex: 1 1 100%" class="description" *ngIf="schema.label">{{ schema.label | translate }}</span>
  </ng-container>
</div>
