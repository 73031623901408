import { Entity } from 'app/models';

import { BaseRunner } from '../../runners';
import { RunnerType } from '../../runners';
import { GhostSolutionAlternatives } from './ghost-solution';

export class GhostRunState extends BaseRunner {
  public readonly type = RunnerType.RUNNER_GHOST;

  public solutions: GhostSolutionAlternatives[];

  constructor(total: number, remains: number, solutions: GhostSolutionAlternatives[]) {
    super(total, remains);
    this.solutions = solutions;
  };
}
