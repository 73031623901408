import { Observable, of } from 'rxjs';
import { switchMap, map } from 'rxjs';

import { Event, I18NParams } from './Event';


export class HoneyHarvest extends Event {

  protected getI18nParams(translate_service: any, entities: any) {
    // Get translation for 'honey_type'
    return super.getI18nParams(translate_service, entities).pipe(switchMap(i18nParams => {
      const honney_type = i18nParams.data.honey_type;
      let honney_type_i18n = of(i18nParams.data.honey_type_other);
      if (honney_type !== 'HONEY_TYPE.OTHER') {
        honney_type_i18n = translate_service.stream(i18nParams.data.honey_type);
      }
      return honney_type_i18n.pipe(
        map(honey_type => {
          i18nParams.data.honey_type = honey_type;
          return i18nParams;
        })
      );
    }));
  }
}


export class HoneyExtraction extends Event {

  get honey_type(): string {
    return this.data.honey_type.type;
  }

  protected getI18nParams(translate_service: any, entities: any) {
    return super.getI18nParams(translate_service, entities).pipe(
      switchMap((i18nParams): Observable<I18NParams> => {
        const honey_type = i18nParams.data.honey_type.type;
        let honey_type$;
        if (honey_type === 'HONEY_TYPE.OTHER') {
          honey_type$ = of(i18nParams.data.honey_type.type_other);
        } else {
          honey_type$ = translate_service.stream(honey_type);
        }
        return honey_type$.pipe(
          map(honey_type_val => {
            i18nParams.computed.honey_type = honey_type_val;
            return i18nParams;
          })
        );
      }),
    );
  }
}
