import { isEmpty, isNil } from 'lodash-es';

import { BehaviorSubject, map } from 'rxjs';
import { replay } from '@bg2app/tools/rxjs';

import { parseDate } from 'app/misc/tools';

import { IZohoCRMDeal } from '../interfaces';
import { ZohoBaseModel } from '../../../../abstract';

export class ZohoCRMDeal extends ZohoBaseModel<IZohoCRMDeal> {
  // #region -> (model basics)

  constructor() {
    super();
  }

  // #endregion

  // #region -> (deal basic infos)

  /** */
  protected _deal_name$$ = new BehaviorSubject<string>(null);

  /** */
  public deal_name$$ = this._deal_name$$.asObservable();

  /** */
  public set Deal_Name(Deal_Name: string) {
    this._deal_name$$.next(Deal_Name);
  }

  /** */
  public get Deal_Name(): string {
    return this._deal_name$$.getValue();
  }

  /** */
  protected _closing_date$$ = new BehaviorSubject<Date>(null);

  /** */
  public closing_date$$ = this._closing_date$$.asObservable();

  /** */
  public set Closing_Date(Closing_Date: Date) {
    if (isNil(Closing_Date)) {
      this._closing_date$$.next(Closing_Date);
      return;
    }

    const date = parseDate(Closing_Date);
    this._closing_date$$.next(date);
  }

  /** */
  public get Closing_Date(): Date {
    return this._closing_date$$.getValue();
  }

  /** */
  public url$$ = this.id$$.pipe(
    map(id => {
      if (isNil(id) || isEmpty(id)) {
        return null;
      }

      return `https://crm.zoho.eu/crm/org20067795631/tab/Potentials/${id}`;
    }),
    replay()
  );

  // #endregion

  // #region -> (deal prices)

  /** */
  protected _amount$$ = new BehaviorSubject<number>(null);

  /** */
  public amount$$ = this._amount$$.asObservable();

  /** */
  public set Amount(Amount: number) {
    this._amount$$.next(Amount);
  }

  /** */
  public get Amount(): number {
    return this._amount$$.getValue();
  }

  // #endregion
}
