import { ChangeDetectionStrategy, Component, OnDestroy } from '@angular/core';

import { marker as i18n } from '@biesbjerg/ngx-translate-extract-marker';

import { cloneDeep, isNil, isString, matches } from 'lodash-es';

import { map, switchMap, take, of, tap, delay } from 'rxjs';
import { Subscription, combineLatest, BehaviorSubject } from 'rxjs';

import { anyTrue, distinctUntilRealChanged, replay } from '@bg2app/tools/rxjs';

import { DeviceApi } from 'app/core';
import { DialogsService } from 'app/widgets/dialogs-modals/dialogs.service';

import { AbstractModalComponent, ModalArgs, ModalParams } from 'app/widgets/dialogs-modals/abstract-modal.component';
import { Dictionary } from 'app/typings/core/interfaces';
import { ConsoleLoggerService } from 'app/core/console-logger.service';
import { SimpleSetterGetter } from 'app/models';

export interface DeviceConfigParams extends ModalParams {
  imei: number;
  full: boolean;
  args: ModalArgs;
}

@Component({
  selector: 'bg2-device-config',
  templateUrl: './device-config.modal.html',
  styleUrls: ['./device-config.modal.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DeviceConfigModalComponent extends AbstractModalComponent<DeviceConfigParams> implements OnDestroy {
  // #region -> (component basics)

  /** */
  private _device_config_sub: Subscription = null;

  /** */
  protected readonly _logger = new ConsoleLoggerService('DeviceConfigModalComponent', false);

  /** */
  constructor(private _deviceApi: DeviceApi, private _dialogs: DialogsService) {
    super();
    this.loading = true;
  }

  /** */
  ngOnDestroy(): void {
    super.ngOnDestroy();

    this._device_config_sub?.unsubscribe();
  }

  /** */
  protected handle_event_before_unload(event: BeforeUnloadEvent): void {
    return null;
  }

  // #endregion

  // #region -> (loadings)

  /** */
  protected is_loading_device = new SimpleSetterGetter(true);

  /** */
  protected is_loading_form_schema = new SimpleSetterGetter(true);

  /** */
  protected is_loading_form_data = new SimpleSetterGetter(true);

  /** */
  public is_loading$$ = anyTrue(this.is_loading_device.value$$, this.is_loading_form_schema.value$$, this.is_loading_form_data.value$$);

  // #endregion

  // #region -> (device basics)

  /** */
  private device_imei$$ = this.input_params$$.pipe(
    tap(() => (this.is_loading_device.value = true)),
    map(params => +params.imei),
    distinctUntilRealChanged(),
    replay()
  );

  /** */
  public device$$ = this.device_imei$$.pipe(
    switchMap(device_imei => this._deviceApi.requestDevice(device_imei)),
    tap(() => (this.is_loading_device.value = false)),
    replay()
  );

  // #endregion

  // #region -> (device config)

  /** */
  private ask_full_config$$ = this.input_params$$.pipe(
    map(params => params.full),
    map((ask_full_config: boolean | string | null) => {
      if (isNil(ask_full_config)) {
        return false;
      }

      if (isString(ask_full_config)) {
        return JSON.parse(ask_full_config);
      }

      return ask_full_config;
    }),
    distinctUntilRealChanged(),
    replay()
  );

  /** */
  public device_config$$ = this.ask_full_config$$.pipe(
    tap(() => (this.is_loading_form_data.value = true)),
    switchMap(ask_full_config =>
      this.device$$.pipe(
        tap(() => (this.loading = true)),
        switchMap(device => {
          if (ask_full_config) {
            return device.requestConfiguration();
          }

          return device.requestSimplifiedConfiguration().pipe(map(conf => conf.next));
        })
      )
    ),
    tap(config => this._logger.debug('device_config$$', config)),
    tap(() => (this.is_loading_form_data.value = false)),
    replay()
  );

  // #endregion

  // #region -> (form management)

  /** */
  public configuration_schema$$ = this.ask_full_config$$.pipe(
    tap(() => (this.is_loading_form_schema.value = true)),
    switchMap(ask_full_config =>
      this.device$$.pipe(
        switchMap(device => (ask_full_config ? device.fetchConfigurationSchema$() : device.requestSimplifiedConfigurationSchema()))
      )
    ),
    tap(schema => this._logger.debug({ schema })),
    tap(() => (this.is_loading_form_schema.value = false)),
    replay()
  );

  /** */
  public config_form_context$$ = combineLatest({
    schema: this.configuration_schema$$,
    model: this.device_config$$
  }).pipe(replay());

  /** */
  private _configuration_model$$ = new BehaviorSubject(null);

  /** */
  public configuration_model$$ = this._configuration_model$$.pipe(
    distinctUntilRealChanged(),
    tap(config => this._logger.debug('New config', config)),
    replay()
  );

  /** */
  public config_has_changed$$ = combineLatest({
    conf_from_model: this.configuration_model$$,
    initial_device_config: this.device_config$$,
  }).pipe(
    map(({ conf_from_model, initial_device_config }) => {
      const check = matches(conf_from_model);
      return !check(initial_device_config);
    }),
    replay()
  );

  /** */
  public update_model_for_device_config(incoming_form_data: Dictionary<any>) {
    this._logger.debug('update_model_for_device_config()', incoming_form_data);
    this._configuration_model$$.next(incoming_form_data);
  }

  /** */
  public saveNewConfig() {
    this.loading = true;

    combineLatest({
      device: this.device$$,
      ask_full_config: this.ask_full_config$$,
      model: this.configuration_model$$,
    })
      .pipe(
        take(1),
        switchMap(({ device, ask_full_config, model }) =>
          ask_full_config ? device.setConfiguration(model) : device.setSimplifiedConfiguration(model)
        )
      )
      .subscribe({
        next: () => {
          this.loading = false;
          this._force_close();
        },
        error: (error: unknown) => {
          this._logger.error(error);
          this.error = (error as any).msg || 'Unknow error';

          this._configuration_model$$.next(cloneDeep(this._configuration_model$$.getValue()));

          this.loading = false;
        },
      });
  }

  // #endregion

  // #region -> (modal management)

  /** */
  public close() {
    if (this.is_trying_to_close_modal) {
      return;
    }
    this.is_trying_to_close_modal = true;
    this.config_has_changed$$
      .pipe(
        switchMap(_config_has_changed => {
          if (_config_has_changed) {
            return this._dialogs.confirm(
              i18n<string>('VIEWS.MODALS.FORM.One or more changes are not saved. Do you want to close by abandoning these changes ?'),
              {
                onFalseMessage: i18n<string>('VIEWS.MODALS.FORM.Come back'),
                onTrueMessage: i18n<string>('VIEWS.MODALS.FORM.Close without saving'),
              }
            );
          }
          return of(true);
        })
      )
      .subscribe(agreement => {
        this.is_trying_to_close_modal = false;
        if (agreement) {
          this._force_close();
        }
      });
  }

  /** */
  private _force_close() {
    this.unsubscribe();
    super.close();
  }

  // #endregion
}
