<bg2-modal [fullscreen]="fullscreen || false" #modal>
  <bg2-modal-header>
    <div class="header">
      <span class="mdi mdi-account-key mdi-36px"></span>
      <span class="title">
        <span>{{ 'VIEWS.MODALS.UPDATE_ENTITY_ACL.Update authorization' | translate }}</span>
        <ng-container *ngIf="entity$$ | async; let entity">
          <span>&nbsp;-&nbsp;</span>
          <span>{{ entity?.type_i18n | translate }}</span>
          <span>&nbsp;</span>
          <span>{{ entity?.name$$ | async }}</span>
        </ng-container>
      </span>
    </div>

    <div class="action-buttons">
      <doc-helper page="Data_sharing" section="configurer-les-droits-au-niveau-exploitation" iconSize="36px"></doc-helper>
      <span class="mdi mdi-close-box mdi-36px" (click)="close()"></span>
    </div>
  </bg2-modal-header>

  <bg2-modal-content>
    <div class="update-entity-owner-container">
      <ng-template #loadingOwnerSchema>
        <hex-loading [message]="('ROUTABLE_MODALS.UPDATE_ENTITY_ACL.Loading entity owner' | translate)"></hex-loading>
      </ng-template>

      <ng-container *ngIf="owner_schema$$ | async; let owner_schema; else loadingOwnerSchema">
        <bg2-form-overlay
          [source_schema]="owner_schema"
          [source_model]="owner_model$$ | async"
          (when_form_data_update)="onOwnerFormChanged($event)"
          (when_form_valid_update)="is_owner_schema_valid = $event"
        ></bg2-form-overlay>
      </ng-container>
    </div>

    <div class="legend">
      <div>{{ 'VIEWS.MODALS.UPDATE_ENTITY_ACL.Other authorizations' | translate }}</div>
    </div>

    <bg2-ui-message [hidden]="(is_mobile$$ | async) && (selected_user_id$$ | async) !== null" [type]="'info'" [title]="''">
      <div message>
        <span>{{ add_authorized_info$$ | async }}</span>
      </div>
    </bg2-ui-message>

    <mat-form-field
      [appearance]="'outline'"
      [hidden]="(is_mobile$$ | async) && (selected_user_id$$ | async) !== null"
      *ngIf="can_add_more_authorized_users$$ | async"
    >
      <mat-label>{{ 'VIEWS.MODALS.UPDATE_ENTITY_ACL.Add authorization for another user' | translate }}</mat-label>
      <bg2-user-select
        [value]="selected_user_id$$ | async"
        (valueChange)="onSelectNewAuthorizedUser($event)"
        [options]="{
          exclude_ids: (acl_user_ids$$ | async)
        }"
      >
      </bg2-user-select>
    </mat-form-field>

    <ng-template #noUsersWithAcl>
      <div *ngIf="loading_users_with_acl$$ | async">
        <hex-loading [message]="'ROUTABLE_MODALS.UPDATE_ENTITY_ACL.Loading associated users ACLs' | translate"></hex-loading>
      </div>
    </ng-template>

    <div *ngIf="(has_users_with_acl$$ | async); else noUsersWithAcl" class="update-entity-acl-container">
      <bg2-button
        [type]="'navigation'"
        [icon]="'mdi-arrow-left'"
        [message]="'ROUTABLE_MODALS.UPDATE_ENTITY_ACL.Back to user selection' | translate"
        [options]="{ is_thin: true }"
        [hidden]="(is_mobile$$ | async) === false || ((is_mobile$$ | async) && (selected_user_id$$ | async)
        === null)"
        (btn_click)="selected_user_id = null"
      >
      </bg2-button>

      <div>
        <div class="navigation-container" [hidden]="(is_mobile$$ | async) && (selected_user_id$$ | async) !== null">
          <ng-template #loadingUsersNavigation>
            <hex-loading [size]="'small'"></hex-loading>
          </ng-template>

          <ng-container *ngIf="acl_users$$ | async; let acl_users; else loadingUsersNavigation">
            <mat-button-toggle-group vertical [value]="selected_user_id$$ | async" (valueChange)="selected_user_id = $event">
              <ng-container *ngFor="let user of acl_users">
                <mat-button-toggle [value]="user.id">
                  <div>
                    <span class="mdi mdi-24px mdi-account"></span>
                    <span>{{ user?.name$$ | async }}</span>
                  </div>

                  <span class="mdi mdi-24px mdi-chevron-right"></span>
                </mat-button-toggle>
              </ng-container>
            </mat-button-toggle-group>
          </ng-container>
        </div>

        <div
          class="configuration-container"
          [ngClass]="{ 'no-selected-user': (selected_user_id$$ | async) === null }"
          [hidden]="(is_mobile$$ | async) && (selected_user_id$$ | async) === null"
        >
          <ng-template #noSelectedUserToConfigure>
            <span>{{ 'ROUTABLE_MODALS.UPDATE_ENTITY_ACL.Please select a user to modify his rights' | translate }}...</span>
          </ng-template>

          <ng-container *ngIf="(selected_user_id$$ | async) !== null; else noSelectedUserToConfigure">
            <ng-container *ngIf="is_checking_minimum_rights$$ | async">
              <hex-loading
                [size]="'medium'"
                [message]="'WIDGETS.EVENT_FORM.BG2_USER_ACL.Checking in progress to see if the user has the minimal access rights to the exploitation' | translate"
              ></hex-loading>
            </ng-container>

            <div class="warning" *ngIf="(no_minimal_access$$ | async)">
              <span>
                {{ 'ENTITY.ALL.Warning! This user does not have any rights on the exploitation level, he will not be able to see this
                location' | translate }}
              </span>

              <bg2-button
                [type]="'action'"
                [icon]="'mdi-account-plus'"
                [message]="'ENTITY.ALL.Add the minimum exploitation access right for this user' | translate"
                [disabled]="is_updating_entity_acl$$ | async"
                [loading]="is_updating_entity_acl$$ | async"
                (btn_click)="giveMinimalAccessForCurrentUser()"
              >
              </bg2-button>
            </div>
            <sf-form [schema]="acl_schema$$ | async" [model]="getModelForUser$$ | async" (onChange)="onAclFormChanged($event)"></sf-form>
            <bg2-button
              [type]="'delete'"
              [icon]="'mdi-delete'"
              [options]="{ is_thin: true }"
              [disabled]="is_saving$$ | async"
              [message]="'VIEWS.MODALS.UPDATE_ENTITY_ACL.Remove all authorizations for this user' | translate"
              (btn_click)="onDeleteUserAcl($event)"
            >
            </bg2-button>
          </ng-container>
        </div>
      </div>
    </div>
  </bg2-modal-content>

  <bg2-modal-footer>
    <div>
      <bg2-button
        [type]="'cancel'"
        [disabled]="is_saving$$ | async"
        [message]="'VIEWS.MODALS.FORM.Cancel' | translate"
        (btn_click)="close()"
      ></bg2-button>

      <bg2-button
        [type]="'action'"
        [disabled]="(is_saving$$ | async) || (is_owner_schema_valid$$ | async) === false"
        [loading]="is_saving$$ | async"
        [message]="(is_saving$$ | async) ? ('VIEWS.MODALS.FORM.Saving' | translate) + '...' : ('VIEWS.MODALS.FORM.Save' | translate)"
        icon="mdi-content-save"
        (btn_click)="submit()"
      >
      </bg2-button>
    </div>
  </bg2-modal-footer>
</bg2-modal>
