import { forIn } from 'lodash-es';

export { DRDevice } from './DRDevice';
export { DeviceInterface } from './DRDevice';
export { GPSDevice } from './GPSDevice';
export { WGDevice } from './WGDevice';
export { RGDevice } from './RGDevice';
export { CPTDevice } from './CPTDevice';
export { CPTMCDevice } from './CPTMCDevice';
export { ComputeDevice } from './ComputeDevice';
export { BloomLiveDevice } from './BloomLiveDevice';
export { BeeLiveDevice } from './BeeLiveDevice';

export { TGDevice } from './classes/TGDevice/TGDevice';

export * from './interfaces';
export * from './enumerators';

export const DEVICE_ORDERS = {
  SCAN: 0x03,
  NEW_SERVER: 0x04,
  FORCE_MEASURE_AND_GPS: 0x06,
  NEW_SIM: 0x07,
  MOVE_TO_SRV_V2: 0xff12,
  OTA: 242,
  // ---
  FORCE_SEARCH_NETWORK: 0x08,
  SCAN_NETWORK: 0x09,
  RESET_FAIL_GPS: 0x0a,
  RESET_BOARD: 0x10, //Second method to reset board
  GET_LOG: 0x11,
  AUTO_TESTS: 0x80,
  IFx_ORDER_1: 0xe0,
  IFx_ORDER_2: 0xe1,
  RESET_FLASH: 0xf5,
};

export const DEVICE_ORDER_STR: { [order: number]: string } = {};
forIn(DEVICE_ORDERS, (value, key) => (DEVICE_ORDER_STR[value] = key));
export type DEVICE_ORDER_STATES = keyof typeof DEVICE_ORDERS;
