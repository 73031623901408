import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  QueryList,
  Renderer2,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { ConsoleLoggerService } from 'app/core/console-logger.service';
import { distinctUntilRealChanged, replay } from '@bg2app/tools/rxjs';
import { Dictionary } from 'app/typings/core/interfaces';
import { isNil, some } from 'lodash-es';
import { BehaviorSubject, map, Observable, Subscription } from 'rxjs';

import { AbstractCarouselGroupCardComponent } from '../abtract-carousel-group-card/abstract-carousel-group-card.component';

@Component({
  selector: 'bg2-abstract-carousel-slide',
  template: '',
  styleUrls: ['./abstract-carousel-slide.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AbstractCarouselSlideComponent implements AfterViewInit, OnDestroy {
  // #region -> (component basics)

  private _group_cards_swipe_subs: Subscription[] = [];

  protected _logger = new ConsoleLoggerService('AbstractCarouselSlideComponent', true);

  constructor(protected _renderer: Renderer2) {}

  ngAfterViewInit(): void {
    this._renderer.setStyle(
      this.slide.nativeElement,
      'marginBottom',
      `${this.MAX_VISIBLE_CARDS === 1 ? 0 : this.MAX_VISIBLE_CARDS * 25}px`
    );

    if (this.MAX_VISIBLE_CARDS === 1) {
      this._renderer.setStyle(this.slide.nativeElement, 'marginTop', '10px');
    }
  }

  ngOnDestroy(): void {
    this._group_cards_swipe_subs.forEach(subscription => subscription?.unsubscribe());
    this._group_cards_swipe_subs = null;
  }

  // #endregion

  // #region -> (slide management)

  @ViewChild('stackableCardGroup')
  public slide: ElementRef<HTMLDivElement> = undefined;

  // #endregion

  // #region -> (static parameters)

  @Input()
  public set static_parameters(static_parameters: any) {
    this._static_parameters$$.next(static_parameters);
  }

  public get static_parameters(): any {
    return this._static_parameters$$.getValue();
  }

  private _static_parameters$$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public static_parameters$$: Observable<any> = this._static_parameters$$.asObservable();

  // #endregion

  // #region -> (dynamic parameters)

  @Input()
  public set dynamic_parameters(dynamic_parameters: any) {
    this._dynamic_parameters$$.next(dynamic_parameters);
  }

  private _dynamic_parameters$$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public dynamic_parameters$$: Observable<any> = this._dynamic_parameters$$.asObservable();

  // #endregion

  // #region -> (inner cards management)

  protected MAX_VISIBLE_CARDS = 3;

  private readonly COEF_SCALING = 0.1;
  private readonly MARGE_BETWEEN_CARDS = 25;

  /** */
  private _cards: QueryList<AbstractCarouselGroupCardComponent> = undefined;

  /** */
  @ViewChildren(AbstractCarouselGroupCardComponent)
  private set cards(cards: QueryList<AbstractCarouselGroupCardComponent>) {
    if (cards?.length === 0 || isNil(cards)) {
      return;
    }

    this._cards = cards;
    this._current_position = 0;

    if (this._group_cards_swipe_subs?.length > 0) {
      this._group_cards_swipe_subs.forEach(s => s?.unsubscribe());
      this._group_cards_swipe_subs = [];
    }

    this._cards.forEach(card => {
      this._group_cards_swipe_subs.push(
        card.swipeDown.subscribe({
          next: () => {
            this._current_position++;
            this.updateCardsState();
          },
        })
      );

      this._group_cards_swipe_subs.push(
        card.swipeUp.subscribe({
          next: () => {
            this._current_position--;
            this.updateCardsState();
          },
        })
      );
    });

    this.updateCardsState();
  }

  private _current_position = 0;

  public shift<T>(arr: T[], direction: 'left' | 'right', n: number): T[] {
    var times = n > arr.length ? n % arr.length : n;
    return arr.concat(arr.splice(0, direction === 'right' ? arr.length - times : times));
  }

  private updateCardsState(from_position: number = null): void {
    const nb_cards = this._cards.length;

    this._current_position =
      Math.abs(
        from_position ?? (this._current_position < 0 ? (nb_cards - Math.abs(this._current_position)) % nb_cards : this._current_position)
      ) % nb_cards;

    let el_trans = 0;
    let el_trans_top = this.MAX_VISIBLE_CARDS;

    let el_zindex = 5;
    let el_scaling = 1;

    // Size: 0 -> 6 = 7
    const shifted_cards = this.shift(this._cards.toArray(), 'left', this._current_position);

    // CSS for the visible cards
    // for i = 0; i < 3; i++
    const nb_visible_cards = Math.min(this.MAX_VISIBLE_CARDS, nb_cards);
    for (let i = 0; i < nb_visible_cards; i++) {
      shifted_cards[i].index = i;

      el_trans = this.MARGE_BETWEEN_CARDS * el_trans_top;
      el_trans_top--;

      // activate all but the last visible
      if (i <= this.MAX_VISIBLE_CARDS - 1) {
        shifted_cards[i].active = true;
      } else {
        shifted_cards[i].active = false;
      }

      this._renderer.setStyle(
        shifted_cards[i].card.nativeElement,
        'transform',
        `scale(${el_scaling}) translateX(0) translateY(${el_trans - this.MARGE_BETWEEN_CARDS + (i >= 2 ? 10 : 0)}px) translateZ(0)`
      );

      this._renderer.setStyle(shifted_cards[i].card.nativeElement, 'opacity', 1);
      this._renderer.setStyle(shifted_cards[i].card.nativeElement, 'zIndex', el_zindex);
      this._renderer.setStyle(shifted_cards[i].card.nativeElement, 'animation', 'none');

      el_scaling = el_scaling - this.COEF_SCALING;
      el_zindex--;
    }

    // CSS for the unvisible cards
    // for i = 3; i < 7; i++
    for (let i = nb_visible_cards; i < nb_cards; i++) {
      shifted_cards[i].active = false;

      this._renderer.setStyle(shifted_cards[i].card.nativeElement, 'zIndex', 0);
      this._renderer.setStyle(shifted_cards[i].card.nativeElement, 'opacity', 0);
      this._renderer.setStyle(
        shifted_cards[i].card.nativeElement,
        'transform',
        `scale(${1 - this.MAX_VISIBLE_CARDS * this.COEF_SCALING}) translateX(0) translateY(${
          this.MARGE_BETWEEN_CARDS * (this.MAX_VISIBLE_CARDS - 1)
        }px) translateZ(0)`
      );
    }

    // Add temporary partial opacity on previous first card
    const previous_position = (this._current_position + nb_cards - 1) % nb_cards;
    const pshifted_cards = this.shift(this._cards.toArray(), 'left', previous_position);
    this._renderer.setStyle(pshifted_cards[0].card.nativeElement, 'animation', 'transparent-during-transition 1s');
  }

  // #endregion

  // #region -> (cards manual navigation)

  /** */
  protected slide_card_mapping: Map<any, number> = null;

  /** */
  public navigate_to_card(source: any): void {
    if (isNil(this.slide_card_mapping)) {
      throw new Error('Usage of `navigate_to_card()` requires non-null `slide_card_mapping` !');
    }

    if (isNil(source)) {
      throw new Error('Usage of `navigate_to_card()` requires non-null `source` input !');
    }

    this.updateCardsState(this.slide_card_mapping.get(source));
  }

  // #endregion

  // #region -> (helpers)

  public has_data$$(source$$: Observable<Dictionary<number>>) {
    return source$$.pipe(
      map(data => some(data, value => value >= 1)),
      distinctUntilRealChanged(),
      replay()
    );
  }

  // #endregion
}
