import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { BehaviorSubject } from 'rxjs';

import { ZohoCRMDeal } from 'app/models/zoho/crm/modules/deals';

@Component({
  selector: 'bg2-zoho-crm-deal-summary',
  templateUrl: './zoho-crm-deal-summary.component.html',
  styleUrls: ['./zoho-crm-deal-summary.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ZohoCRMDealSummaryComponent {
  // #region -> (component basics)

  constructor() {}

  // #endregion

  // #region -> (deal object)

  /** */
  private _deal$$ = new BehaviorSubject<ZohoCRMDeal>(null);

  /** */
  public deal$$ = this._deal$$.asObservable();

  @Input()
  public set deal(deal: ZohoCRMDeal) {
    this._deal$$.next(deal);
  }

  public get note(): any {
    return this._deal$$.getValue();
  }

  // #endregion
}
