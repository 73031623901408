import { isNil } from 'lodash-es';

import { BehaviorSubject, combineLatest, map } from 'rxjs';
import { replay } from '@bg2app/tools/rxjs';

import { parseDate } from 'app/misc/tools';

import { ZohoBaseModel } from '../../../../abstract';
import { IZohoDeskTicket, TicketCustomFields } from '../interfaces';

/** */
export class ZohoDeskTicket extends ZohoBaseModel<IZohoDeskTicket> {
  // #region -> (model basics)

  constructor() {
    super();
  }

  // #endregion

  // #region -> (ticket infos)

  /** */
  private _subject$$ = new BehaviorSubject<string>(null);

  /** */
  public subject$$ = this._subject$$.asObservable();

  /** */
  public set subject(subject: string) {
    this._subject$$.next(subject);
  }

  /** */
  public get subject(): string {
    return this._subject$$.getValue();
  }

  /** */
  private _ticket_number$$ = new BehaviorSubject<string>(null);

  /** */
  public ticket_number$$ = this._ticket_number$$.asObservable();

  /** */
  public set ticketNumber(ticket_number: string) {
    this._ticket_number$$.next(ticket_number);
  }

  /** */
  public get ticketNumber(): string {
    return this._ticket_number$$.getValue();
  }

  /** */
  private _statusType$$ = new BehaviorSubject<'On Hold' | 'Closed' | 'Open'>(null);

  /** */
  public statusType$$ = this._statusType$$.asObservable();

  /** */
  public set statusType(statusType: 'On Hold' | 'Closed' | 'Open') {
    this._statusType$$.next(statusType);
  }

  /** */
  public get statusType(): 'On Hold' | 'Closed' | 'Open' {
    return this._statusType$$.getValue();
  }

  /** */
  private _status$$ = new BehaviorSubject<string>(null);

  /** */
  public status$$ = this._status$$.asObservable();

  /** */
  public set status(status: string) {
    this._status$$.next(status);
  }

  /** */
  public get status(): string {
    return this._status$$.getValue();
  }

  /** */
  private _maybe_off_topic$$ = new BehaviorSubject<boolean>(false);

  /** */
  public maybe_off_topic$$ = this._maybe_off_topic$$.asObservable();

  /** */
  public set maybe_off_topic(maybe_off_topic: boolean) {
    this._maybe_off_topic$$.next(maybe_off_topic);
  }

  /** */
  public get maybe_off_topic(): boolean {
    return this._maybe_off_topic$$.getValue();
  }

  // #endregion

  // #region -> (ticket time)

  /** */
  private _createdTime$$ = new BehaviorSubject<Date>(null);

  /** */
  public createdTime$$ = this._createdTime$$.asObservable();

  /** */
  public set createdTime(createdTime: Date) {
    if (isNil(createdTime)) {
      this._createdTime$$.next(createdTime);
      return;
    }

    const date = parseDate(createdTime);
    this._createdTime$$.next(date);
  }

  /** */
  public get createdTime(): Date {
    return this._createdTime$$.getValue();
  }

  /** */
  private _closedTime$$ = new BehaviorSubject<Date>(null);

  /** */
  public closedTime$$ = this._closedTime$$.asObservable();

  /** */
  public set closedTime(closedTime: Date) {
    if (isNil(closedTime)) {
      this._closedTime$$.next(closedTime);
      return;
    }

    const date = parseDate(closedTime);
    this._closedTime$$.next(date);
  }

  /** */
  public get closedTime(): Date {
    return this._closedTime$$.getValue();
  }

  // #endregion

  // #region -> (ticket URL)

  /** */
  private _web_url$$ = new BehaviorSubject<string>(null);

  /** */
  public web_url$$ = this._web_url$$.asObservable();

  /** */
  public set webUrl(web_url: string) {
    this._web_url$$.next(web_url);
  }

  /** */
  public get webUrl(): string {
    return this._web_url$$.getValue();
  }

  /** */
  public url_href$$ = combineLatest({ web_url: this.web_url$$, ticket_number: this.ticket_number$$ }).pipe(
    map(({ web_url, ticket_number }) => `<a href="${web_url}" target="_blank">#${ticket_number}</a>`),
    replay()
  );

  // #endregion

  // #region -> (custom fields)

  /** */
  private _cf$$ = new BehaviorSubject<Partial<TicketCustomFields>>(null);

  /** */
  public cf$$ = this._cf$$.asObservable();

  /** */
  public set cf(cf: Partial<TicketCustomFields>) {
    this._cf$$.next(cf);
  }

  /** */
  public get cf(): Partial<TicketCustomFields> {
    return this._cf$$.getValue();
  }

  /**
   * @deprecated
   */
  public get cf_imeis(): TicketCustomFields['cf_imeis'] {
    return (this?.cf?.cf_imeis ?? '').trim();
  }

  /**
   * @deprecated
   */
  public get cf_imeis_recu_en_ru(): TicketCustomFields['cf_imeis_recu_en_ru'] {
    return (this?.cf?.cf_imeis_recu_en_ru ?? '').trim();
  }

  // #endregion
}
