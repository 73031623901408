<div class="bg-dialog">
  <!-- Dialog body -->
  <dialog-body>
    <bg2-form-overlay
      [validators]="form_validators"
      [source_model]="form_model$$ | async"
      [source_schema]="form_schema$$ | async"
      (when_form_data_update)="onFormChanged($event)"
      (when_form_valid_update)="is_form_valid = $event"
    ></bg2-form-overlay>
  </dialog-body>

  <!-- Dialog footer -->
  <div class="dialog-footer">
    <!-- Cancel button -->
    <bg2-button [type]="'cancel'" [message]="'ALL.ACTIONS.Cancel' | translate" (btn_click)="cancel()"></bg2-button>

    <!-- Create button -->
    <bg2-button
      [type]="'action'"
      [icon]="'mdi-content-save'"
      [disabled]="(is_form_valid$$ | async) === false"
      [message]="'VIEWS.DEVICES.DIALOGS_AND_MODALS.CREATE_DEVICES_MODAL.Import devices' | translate"
      (btn_click)="import_devices()"
    >
    </bg2-button>
  </div>
</div>
