import { HttpClient } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';

import { isEmpty, isNil } from 'lodash-es';

import { catchError, map, Observable, of, switchMap, take } from 'rxjs';
import { replay, waitForNotNilValue } from '@bg2app/tools/rxjs';

import { ZohoAuthService } from 'app/core/services/zoho/zoho-auth.service';

import { ApiCache } from 'app/core/api/misc/api-cache';

import { get_i18n_for_zoho_error, ZohoBaseModel, ZohoError } from '@bg2app/models/zoho';
import { IZohoBooksContact, IZohoBooksInvoice, ZohoBooksContact, ZohoBooksInvoice } from '@bg2app/models/zoho/books';

/** */
enum ZohoBooksModuleName {
  /** */
  MODULE_CONTACTS = 'contacts',

  /** */
  MODULE_INVOICES = 'invoices',
}

/**
 * Zoho BOOKS API service.
 *
 * Use this API to manage bills, invoices, etc.
 *
 * @url https://www.zoho.com/books/api/v3
 */
@Injectable({
  providedIn: 'root',
})
export class ZohoBooksApiService implements OnDestroy {
  // #region -> (service basics)

  /** */
  private static readonly API_BASE = 'https://www.zohoapis.eu/books/v3';

  /** */
  private _cache = new ApiCache<ZohoBaseModel<IZohoBooksContact | IZohoBooksInvoice>, string>();

  /** */
  constructor(private readonly _httpClient: HttpClient, private readonly _auth: ZohoAuthService) {}

  /** */
  ngOnDestroy(): void {}

  // #endregion

  // #region -> (auth token)

  /** */
  private _access_token$$: Observable<string> = this._auth.is_authenticated$$.pipe(
    switchMap(is_authenticated => {
      if (!is_authenticated) {
        return of(null);
      }

      return this._auth.access_token$$;
    }),
    replay()
  );

  /** */
  private _wait_for_access_token$$ = this._access_token$$.pipe(waitForNotNilValue(), take(1));

  // #endregion

  // #region -> (read)

  /**
   * Fetch all unpaid invoices for a specific customer.
   */
  public fetch_unpaid_invoices$(customer_id: string) {
    return this._wait_for_access_token$$.pipe(
      switchMap(access_token =>
        this._httpClient.get(`${ZohoBooksApiService.API_BASE}/invoices?customer_id=${customer_id}&status=unpaid`, {
          headers: {
            Authorization: `Zoho-oauthtoken ${access_token}`,
          },
        })
      ),
      map((response: { invoices: IZohoBooksInvoice[] }) => {
        const records = response?.invoices;

        if (isNil(records)) {
          return null;
        }

        return this.deserialize_multiple(records, ZohoBooksModuleName.MODULE_INVOICES);
      }),
      catchError(() => of(new Error(get_i18n_for_zoho_error(ZohoError.UNDEFINED_ZOHO_CRM_OBJECT))))
    );
  }

  // #endregion

  // #region -> (search)

  /** */
  public search_books_contact_from_crm_contact$(zoho_crm_contact_id: string) {
    return this._wait_for_access_token$$.pipe(
      switchMap(access_token =>
        this._httpClient.get(`${ZohoBooksApiService.API_BASE}/contacts?zcrm_contact_id=${zoho_crm_contact_id}`, {
          headers: {
            Authorization: `Zoho-oauthtoken ${access_token}`,
          },
        })
      ),
      map((response: { contacts: IZohoBooksContact[] }) => {
        const record = response?.contacts?.[0];

        if (isNil(record)) {
          return new Error(get_i18n_for_zoho_error(ZohoError.BOOKS__NO_CLIENT_FOR_CRM_CONTACT));
        }

        return this.deserialize(record, ZohoBooksModuleName.MODULE_CONTACTS);
      }),
      catchError(() => of(new Error(get_i18n_for_zoho_error(ZohoError.UNDEFINED_ZOHO_CRM_OBJECT))))
    );
  }

  /** */
  public search_books_contact_from_crm_account$(zoho_crm_account_id: string) {
    return this._wait_for_access_token$$.pipe(
      switchMap(access_token =>
        this._httpClient.get(`${ZohoBooksApiService.API_BASE}/contacts?zcrm_account_id=${zoho_crm_account_id}`, {
          headers: {
            Authorization: `Zoho-oauthtoken ${access_token}`,
          },
        })
      ),
      map((response: { contacts: IZohoBooksContact[] }) => {
        const record = response?.contacts?.[0];

        if (isNil(record)) {
          return null;
        }

        return this.deserialize(record, ZohoBooksModuleName.MODULE_CONTACTS);
      }),
      catchError(() => of(new Error(get_i18n_for_zoho_error(ZohoError.UNDEFINED_ZOHO_CRM_OBJECT))))
    );
  }

  // #endregion

  /** */
  public deserialize(raw_model: IZohoBooksInvoice, type: ZohoBooksModuleName.MODULE_INVOICES): ZohoBooksInvoice;
  public deserialize(raw_model: IZohoBooksContact, type: ZohoBooksModuleName.MODULE_CONTACTS): ZohoBooksContact;
  public deserialize(
    raw_model: IZohoBooksContact | IZohoBooksInvoice,
    type: ZohoBooksModuleName
  ): ZohoBaseModel<IZohoBooksContact | IZohoBooksInvoice> {
    let object: ZohoBaseModel<IZohoBooksContact | IZohoBooksInvoice> = null;
    let object_id = (<IZohoBooksContact>raw_model)?.contact_id ?? (<IZohoBooksInvoice>raw_model)?.invoice_id ?? null;

    if (isNil(object_id)) {
      throw new Error('Object ID of Zoho BOOKS should not be nil');
    }

    if (this._cache.has(object_id)) {
      object = this._cache.get(object_id);
      object.deserialize(raw_model);

      return object;
    }

    switch (type) {
      case ZohoBooksModuleName.MODULE_CONTACTS: {
        object = new ZohoBooksContact();
        break;
      }

      case ZohoBooksModuleName.MODULE_INVOICES: {
        object = new ZohoBooksInvoice();
        break;
      }
    }

    object.deserialize(<any>raw_model);
    this._cache.add(object_id, object);

    return object;
  }

  /** */
  public deserialize_multiple(raw_models: IZohoBooksContact[], type: ZohoBooksModuleName.MODULE_CONTACTS): ZohoBooksContact[];
  public deserialize_multiple(raw_models: IZohoBooksInvoice[], type: ZohoBooksModuleName.MODULE_INVOICES): ZohoBooksInvoice[];
  public deserialize_multiple(raw_models: any[], type: ZohoBooksModuleName): ZohoBaseModel<IZohoBooksContact | IZohoBooksInvoice>[] {
    return raw_models.map(raw_model => this.deserialize(raw_model, <any>type));
  }

  // #region -> (create methods)

  // #endregion

  // #region -> (read methods)

  /** */
  public fetch_contacts$(): Observable<ZohoBooksContact[]> {
    return this._wait_for_access_token$$.pipe(
      switchMap(access_token =>
        this._httpClient.get(`${ZohoBooksApiService.API_BASE}/contacts`, {
          headers: {
            Authorization: `Zoho-oauthtoken ${access_token}`,
          },
        })
      ),
      map((response: { contacts: any[] }) => {
        const contacts = response?.contacts;

        if (isNil(contacts) || isEmpty(contacts)) {
          return [];
        }

        return contacts.map(contact => this.deserialize(contact, ZohoBooksModuleName.MODULE_CONTACTS));
      }),
      catchError(() => of(<ZohoBooksContact[]>null))
    );
  }

  // #endregion

  // #region -> (update methods)

  // #endregion

  // #region -> (delete methods)

  // #endregion

  // #region -> (search methods)

  // #endregion
}
