/**
 * Calculates the distance between two words. Also, checkout {@link https://planetcalc.com/1721/} for formula and online calculation.
 *
 * @param word_a The first word.
 * @param word_b The second word.
 *
 * @returns The levenshtein distance between the word A and the word B.
 */
export const levenshtein_distance = (word_a: string, word_b: string): number => {
  const word_a_length = (word_a ?? '').length;
  const word_b_length = (word_b ?? '').length;

  if (word_a_length === 0) {
    return word_b_length;
  }

  if (word_b_length === 0) {
    return word_a_length;
  }

  const matrix = new Array<number[]>(word_b_length + 1);

  for (let i = 0; i <= word_b_length; ++i) {
    let row = (matrix[i] = new Array<number>(word_a_length + 1));
    row[0] = i;
  }

  const first_row = matrix[0];

  for (let j = 1; j <= word_a_length; ++j) {
    first_row[j] = j;
  }

  for (let i = 1; i <= word_b_length; ++i) {
    for (let j = 1; j <= word_a_length; ++j) {
      if (word_b.charAt(i - 1) === word_a.charAt(j - 1)) {
        matrix[i][j] = matrix[i - 1][j - 1];
      } else {
        matrix[i][j] =
          Math.min(
            matrix[i - 1][j - 1], // substitution
            matrix[i][j - 1], // insertion
            matrix[i - 1][j] // deletion
          ) + 1;
      }
    }
  }

  return matrix[word_b_length][word_a_length];
};
