import { subHours, addHours, subMinutes, addMinutes } from 'date-fns';
import { differenceInHours, differenceInMonths, endOfYesterday, isSameDay, startOfDay, subDays } from 'date-fns/esm';

import { TranslateService } from '@ngx-translate/core';
import { marker as i18n } from '@biesbjerg/ngx-translate-extract-marker';

import { Observable, of, Subscription, concat, combineLatest, BehaviorSubject, throwError } from 'rxjs';
import { map, filter, switchMap, distinctUntilChanged, tap, debounceTime, catchError } from 'rxjs/operators';
import { allTrue, distinctUntilRealChanged, replay, robustCombineLatest, waitForNotNilValue } from '@bg2app/tools/rxjs';

import { has, last, keys, clone, isNil, isEqual, includes, max, maxBy, mean, min, minBy, range, sortBy, values, orderBy } from 'lodash-es';

import { parseDate } from 'app/misc/tools';

import { DeviceApi, Beeguard2Api } from 'app/core';

import { Entity, Apiary, Location } from '@bg2app/models/entities';

import { HiveDeviceConfig } from '../../misc';

import { DRDevice, DeviceInterface, WGDevice, GPSDevice, CPTDevice, BeeLiveDevice, TGDevice } from '../../../devices';
import { Event } from '../../../events';
import { DataPoint, GetEntityACLResponse, GetEntityTimeseriesResponse, MeasurementDescription } from 'app/core/api-swagger/beeguard2';
import { Dictionary } from 'app/typings/core/interfaces';
import { find_worst_device_status_in, find_worst_device_status_simplified_in } from '../../../devices/tools/device-status.tools';
import { DeviceGlobalStateStr, DeviceStatus868, DeviceStatusBat, DeviceStatusGPRS, DeviceStatusGPS } from '../../../devices/DRDevice';
import { coloration_helper } from 'app/misc/tools/colors.helpers';
import { WeightDataPoint, TemperatureDataPoint, BeeCountDataPoint } from '../../../data';
import { HiveEntityUserACL } from './hive-entity-user-acl.class';
import { ErrorHelperData } from 'app/widgets/widgets-reusables/errors/error-helper/error-helper.component';

export type HiveType = 'hive' | 'nuc';

const DEFAULT_COLORS = [
  '1f77b4', // 205° 71% 41%
  'ff7f0e', // 28° 100% 53%
  '2ca02c', // 120° 57% 40%
  'd62728', // 360° 69% 50%
  '9467bd', // 271° 39% 57%
  '8c564b', // 10° 30% 42%
  'e377c2', // 318° 66% 68%
  '7f7f7f', //  0° 0% 50%
  'bcbd22', // 60° 70% 44%
  '17becf', // 186° 80% 45%
];
export const DEFAULT_SATURATION = 58.2;
export const DEFAULT_LUMINOSITY = 48.8;

export const HiveQueenColorToI18n = {
  unknow: i18n<string>('EVENT.VISIT.QUEEN.Unknow'),
  not_found: i18n<string>('EVENT.VISIT.QUEEN.Not found'),
  b: i18n<string>('EVENT.VISIT.QUEEN.blue'),
  w: i18n<string>('EVENT.VISIT.QUEEN.white'),
  y: i18n<string>('EVENT.VISIT.QUEEN.yellow'),
  r: i18n<string>('EVENT.VISIT.QUEEN.red'),
  g: i18n<string>('EVENT.VISIT.QUEEN.green'),
};

export interface HiveEditingFormModel {
  date: string;
  name: string;
  htype: 'hive' | 'nuc';
  color: { h: number; s: number; l: number };
}

export class Hive extends Entity {
  // #region -> (entity basics)

  /** */
  private devices_config_sub: Subscription;

  /** */
  constructor(bg2Api: Beeguard2Api, deviceApi: DeviceApi) {
    super(bg2Api, deviceApi);

    this.type = 'hive';
    this.devices_config_sub = this.devices_config$$.subscribe();
  }

  // #endregion

  // #region -> (related apiary entity)

  /** */
  public apiary_id$$ = this.state$$.pipe(
    map(state => state?.apiary_id),
    waitForNotNilValue(),
    distinctUntilRealChanged(),
    replay()
  );

  /** */
  public apiary$$: Observable<Apiary> = this.apiary_id$$.pipe(
    switchMap(apiary_id => this.bg2Api.getEntityObj<Apiary>(apiary_id)),
    tap(apiary => {
      if (apiary && apiary.type !== 'apiary') {
        throw Error(`Invalid apiary for hive ${this.desc}`);
      }
    }),
    replay()
  );

  /** */
  public named_apiary_id$$ = this.named_state$$.pipe(
    map(state => state.apiary_id),
    waitForNotNilValue(),
    distinctUntilRealChanged(),
    replay()
  );

  /** */
  public named_apiary$$: Observable<Apiary> = this.named_apiary_id$$.pipe(
    switchMap(apiary_id => this.bg2Api.getEntityObj<Apiary>(apiary_id)),
    tap(apiary => {
      if (apiary && apiary.type !== 'apiary') {
        throw Error(`Invalid apiary for hive ${this.desc}`);
      }
    }),
    replay()
  );

  // #endregion

  // #region -> (acl management)

  /**
   * @inheritdoc
   */
  public user_acl = new HiveEntityUserACL();

  // #endregion

  // #region -> (hive selection state for charts)

  /** */
  private _is_visible_on_chart$ = new BehaviorSubject<boolean>(true);

  /** */
  public is_visible_on_chart$$ = this._is_visible_on_chart$.asObservable();

  /** */
  public set is_visible_on_chart(is_visible_on_chart: boolean) {
    this._is_visible_on_chart$.next(is_visible_on_chart);
  }

  // #endregion

  protected weight_data_cache: Dictionary<any> = {};

  private _apiary: Apiary = null;
  public hive_nb = -1; // Number of the hive in the apiary
  public connected = false;
  public will_be_created_after_visit = false;

  public has_apiary$$: Observable<boolean> = this.state$$.pipe(map(state => has(state, 'apiary_id')));

  public location$$: Observable<Location> = this.apiary$$.pipe(switchMap(apiary => apiary.location$$));

  private _devices_conf: any = null; // cache of devices config array

  public devices_config$$: Observable<Dictionary<HiveDeviceConfig>> = this.user_acl.can$$('read_devices').pipe(
    switchMap(can_read_devices => {
      if (!can_read_devices) {
        return of({});
      }

      return this.state$$.pipe(map(state => state?.devices ?? {}));
    }),
    distinctUntilRealChanged(),
    tap(() => this.clearCache()),
    replay()
  );

  public devices_loading = false;

  get description(): string {
    return i18n(`ENTITY.HIVE.Hive "[name]"`);
  }

  /* Return list of devices config
   */
  // ToDo Rename device_config
  // TODO still use in picto hive, but should be removed for observables
  get devices(): HiveDeviceConfig[] {
    if (isNil(this._devices_conf)) {
      this._devices_conf = sortBy(values(this.state.devices) || [], ['type', 'imei']);
    }
    return this._devices_conf;
  }

  /**
   * Warning: hive's devices are loaded on subscribe on devices$$
   */
  public devices$$: Observable<DRDevice[]> = this.devices_config$$.pipe(
    debounceTime(100),
    map(devices_config => ({
      device_imeis: keys(devices_config).map(val => parseInt(val, 10)),
      devices_config,
    })),
    distinctUntilChanged((prev, next) => isEqual(prev?.device_imeis, next.device_imeis)),
    tap(() => (this.devices_loading = true)),
    // tap(({device_imeis}) => console.log(this.desc, 'Load devices from device_imeis:', device_imeis)),
    switchMap(({ device_imeis, devices_config }) => {
      if (device_imeis.length > 0) {
        // this._logger.debug(`loadDevices(${device_imeis})`);
        return this.deviceApi.requestDevices(device_imeis).pipe(
          // Add current device config in each device
          map(devices => {
            devices.map(device => {
              // console.log(device,  devices_config[device.imei])
              device.hive_config = devices_config[device.imei];
              device.hive_config.hive_id = this.id;
              // Note: this ^ hive_config data may also be updated by device warehouse
            });
            return devices;
          })
        );
      } else {
        return of([]);
      }
    }),
    tap(() => (this.devices_loading = false)),
    replay()
    // tap(devices => console.log(this.desc, 'devices$$', devices))
  );

  private _gps$$ = this.devices$$.pipe(
    map(devices => devices.filter(dev => dev.type === 'GPS')),
    replay()
  );

  /** */
  public has_device_GPS$$ = this._gps$$.pipe(
    map(devices => (devices ?? [])?.length >= 1),
    replay()
  );

  private _wg_with_generation$$ = this.devices$$.pipe(
    map(devices => devices.filter(dev => dev.type === 'WG')),
    switchMap(devices => robustCombineLatest(devices.map(device => device.generation$$.pipe(map(generation => ({ device, generation })))))),
    replay()
  );

  private _wg_gen1$$ = this._wg_with_generation$$.pipe(
    map(devices_with_gen => devices_with_gen.filter(({ device, generation }) => generation == 1)),
    replay()
  );

  private _wg_gen2$$ = this._wg_with_generation$$.pipe(
    map(devices_with_gen => devices_with_gen.filter(({ device, generation }) => generation == 2)),
    replay()
  );

  private _cpt$$ = this.devices$$.pipe(
    map(devices => <CPTDevice[]>devices.filter(device => device.type === 'CPT')),
    replay()
  );

  /** */
  public has_device_CPT$$ = this._cpt$$.pipe(
    map(devices => (devices ?? [])?.length >= 1),
    replay()
  );

  private _beelive$$ = this.devices$$.pipe(
    map(devices => <BeeLiveDevice[]>devices.filter(device => device.type === 'BeeLive')),
    replay()
  );

  /** */
  public has_device_beelive$$ = this._beelive$$.pipe(
    map(devices => (devices ?? [])?.length >= 1),
    replay()
  );

  /** */
  private _tg$$ = this.devices$$.pipe(
    map(devices => <TGDevice[]>devices.filter(device => device.type === 'TG')),
    replay()
  );

  /** */
  public has_device_tg$$ = this._tg$$.pipe(
    map(devices => (devices ?? [])?.length >= 1),
    replay()
  );

  /** Wether the hive host some devices or not
   */
  public connected$$: Observable<boolean> = this.devices_config$$.pipe(
    // tap(devices_config => console.log(this.desc, devices_config)),
    map(devices_config => keys(devices_config).length > 0),
    replay()
  );

  public nb_supers$$: Observable<number> = this.state$$.pipe(
    map(state => state.nb_supers),
    distinctUntilChanged(),
    replay()
  );

  public queen_color$$: Observable<string> = this.state$$.pipe(
    map(state => (!isNil(state.queen) ? state.queen.color : 'not_found')),
    distinctUntilChanged(),
    replay()
  );

  public nb_broodframes$$: Observable<number> = this.state$$.pipe(
    map(state => {
      if (!isNil(state.brood_box) && !isNil(state.brood_box.brood_frame)) {
        return state.brood_box.brood_frame.size || 0;
      }
      return 0;
    }),
    distinctUntilChanged(),
    replay()
  );

  // #region -> (hive coloration)

  /** */
  private initialize_color() {
    // delete this.static_state.color; ?
    const hive_nb = this.hive_nb >= 0 ? this.hive_nb : 0;
    const idx = hive_nb % DEFAULT_COLORS.length;
    const color_hsl = coloration_helper.converters.from_hex.to_hsl(DEFAULT_COLORS[idx]);
    // store this inital color in _static_state to avoid false "has_changed"
    const color = { h: color_hsl.h, s: DEFAULT_SATURATION, l: DEFAULT_LUMINOSITY };
    this._static_state.color = clone(color);
    // store initial color in current tmp static_state
    this.static_state.color = color;

    return color;
  }

  /** */
  public get color_hsl(): { h: number; s: number; l: number } {
    if (!this.static_state.color) {
      this.initialize_color();
    }
    return this.static_state.color;
  }

  /** */
  public set color_hsl(color: { h: number; s: number; l: number }) {
    this.static_state.color = color;
    this.checkStaticStateChanged();
  }

  /** */
  public get color(): string {
    return coloration_helper.converters.from_hsl.to_hex(this.color_hsl);
  }

  /** */
  public hsl_color$$: Observable<{ h: number; s: number; l: number }> = this.static_state$$.pipe(
    map(static_state => static_state?.color ?? this.initialize_color()),
    distinctUntilRealChanged()
  );

  /** */
  public color$$: Observable<string> = this.hsl_color$$.pipe(
    map(hsl_color => coloration_helper.converters.from_hsl.to_hex(hsl_color)),
    distinctUntilRealChanged(),
    replay()
  );

  /** */
  public coloration$$ = this.hsl_color$$.pipe(
    map(color => {
      const tonal = coloration_helper.modifiers.change_saturation_and_luminosity({ h: color?.h, s: 67, l: 87 });
      const tonal_max = coloration_helper.modifiers.change_saturation_and_luminosity({ h: color?.h, s: 67, l: 95 });

      return {
        tonal: coloration_helper.formatters.format_hsl_number_to_string(tonal),
        tonal_max: coloration_helper.formatters.format_hsl_number_to_string(tonal_max),
      };
    })
  );

  // #endregion

  get global_state(): Dictionary<any> {
    if (!isNil(this.state.global_state)) {
      return this.state.global_state;
    }
    return null;
  }

  get dynamism(): number {
    if (!isNil(this.global_state) && has(this.global_state, 'dynamism')) {
      return this.global_state.dynamism;
    }
    return null;
  }

  get eggs(): boolean {
    return this.state.brood_box ? this.state.brood_box.brood_frame.eggs : null;
  }

  get homogeneous_brood(): boolean {
    return this.state.brood_box ? this.state.brood_box.brood_frame.homogeneous : null;
  }

  get stores_frames(): number {
    return this.state.brood_box ? this.state.brood_box.stores_frames.size : null;
  }

  //#region Hive name

  initialize_name(name: string): void {
    this._static_state.name = clone(name);
    // store initial name in current tmp static_state
    this.static_state.name = name;
    this.checkStaticStateChanged();
  }

  //#endregion

  // #region -> (hive type management)

  private _tmp_htype$$ = new BehaviorSubject<HiveType>('hive');
  public tmp_htype$$ = this._tmp_htype$$.asObservable();

  public get htype(): HiveType {
    return this.state.htype || this._tmp_htype$$.getValue();
  }

  public set_tmp_htype(htype: HiveType) {
    this._tmp_htype$$.next(htype);
  }

  private _htype$$ = this.state$$.pipe(map(state => state?.htype));

  public htype$$: Observable<HiveType> = combineLatest([this._htype$$, this.tmp_htype$$]).pipe(
    map(([htype, tmp_htype]) => htype || tmp_htype),
    distinctUntilRealChanged()
  );

  // #endregion

  // #region -> (hive name management)

  /**
   * Computes the default name of a hive/nuc.
   *
   * @param apiary The apiary to use as basis for hive/nuc naming.
   * @param translate Reference to the translation service.
   * @param options Facultative options to compute the hive/nuc name.
   *
   * @returns Returns the computed default name of the hive/nuc.
   */
  public compute_default_name$$(apiary: Apiary, translate: TranslateService, options?: { offset: number }): Observable<string> {
    const default_name$$ = this.htype$$.pipe(
      switchMap(prefix => apiary.next_hive_name(prefix, translate, options)),
      distinctUntilRealChanged()
    );

    let previous_default_name: string = null;
    return combineLatest([default_name$$, concat(of(null), this.name$$)]).pipe(
      filter(([default_name, name]) => {
        const use_default_name = isNil(name) || previous_default_name === name;
        previous_default_name = default_name;
        return use_default_name;
      }),
      map(([default_name]) => default_name)
    );
  }

  // #endregion

  // TODO: all that getter should be replaced by observables !

  get nb_supers(): number {
    return this.state.nb_supers || 0;
  }

  get nb_brood_frames(): number {
    return this.state.brood_box ? this.state.brood_box.brood_frame.size : null;
  }

  get queen_color(): number {
    if (this.state.queen && this.state.queen.color) {
      return this.state.queen.color;
    } else {
      return null;
    }
  }

  get last_comment(): string {
    if (this.state.last_visit) {
      return this.state.last_visit.comment;
    } else {
      return null;
    }
  }

  get last_visit(): { date: Date; event_id: number } {
    if (this.state.last_visit) {
      return this.state.last_visit.from;
    } else {
      return null;
    }
  }

  get last_visit_date(): Date {
    if (this.last_visit) {
      return this.last_visit.date;
    } else {
      return null;
    }
  }

  //#region Setup hive date

  get setup_date(): Date {
    if (!isNil(this.state.apiary_id_since)) {
      return parseDate(this.state.apiary_id_since.date);
    } else {
      return this.initial_setup_date;
    }
  }

  public apiary_id_since$$ = this.state$$.pipe(
    map(state => {
      if (!isNil(state.apiary_id_since)) {
        return parseDate(state.apiary_id_since.date);
      }
      return null;
    })
  );

  public setup_date$$: Observable<Date> = combineLatest([this.initial_setup_date$$, this.apiary_id_since$$]).pipe(
    map(([initial_setup_date, apiary_id_since]) => apiary_id_since || initial_setup_date),
    replay()
  );

  get setup_event_id(): number {
    if (!isNil(this.state.apiary_id_since)) {
      return this.state.apiary_id_since.event_id;
    }
    return this.initial_setup_event_id;
  }

  public setup_event$$ = this.state$$.pipe(
    map(state => {
      if (!isNil(state.apiary_id_since)) {
        return state.apiary_id_since.event_id;
      }
      return null;
    }),
    switchMap(event_id => {
      if (event_id) {
        return this.bg2Api.getEventObj(event_id);
      } else {
        return of(null);
      }
    })
  );

  get has_no_migratory(): boolean {
    if (isNil(this.state.apiary_id_since)) {
      return false;
    }
    if (isNil(this.state.setup)) {
      return false;
    }
    return this.state.apiary_id_since.event_id === this.state.setup.event_id;
  }

  //#endregion

  //#region Hive methods

  /**
   * Setup the current hive to a specific apiary
   *
   * @param apiary_id Identifier of the apiary in which to create the hive.
   */
  public hiveSaveCreateUpdateSetup(apiary: Apiary, setup_date: Date): Observable<Event> {
    const new_setup_date = !isNil(setup_date) ? parseDate(setup_date) : null; // Ensure we have a Date obj
    const is_new_hive = isNil(this.id) || this.id < 0;
    const has_setup_event = !isNil(this.setup_event_id);

    const need_to_create_new_setup_event = is_new_hive && !has_setup_event;

    // Check data coherence
    if (is_new_hive && has_setup_event) {
      throw Error(`Hive ${this.id} is new but has already a setup event`);
    }
    if (!is_new_hive && !has_setup_event) {
      throw Error(`Hive ${this.id} exist already, but has no setup event`);
    }

    // Prepare some variables
    let setup_request: Observable<any> = this.save();
    if (!need_to_create_new_setup_event) {
      const need_to_mv_setup_date = !isNil(new_setup_date) && !isEqual(new_setup_date, this.setup_date);
      // if setup date changed
      if (need_to_mv_setup_date) {
        if (!this.has_no_migratory) {
          throw Error(`Hive ${this.id} has migratory, imposible to move setup event`);
        }
        this._logger.info(`Change setup date from ${this.setup_date} to ${new_setup_date}`);
        setup_request = setup_request.pipe(
          switchMap(() =>
            combineLatest({
              setup_event: this.bg2Api.getEventObj(this.setup_event_id),
              event_after_setup: apiary.event_after_setup$$,
            })
          ),
          switchMap(({ setup_event, event_after_setup }) => {
            console.log(event_after_setup, event_after_setup.date);
            if (event_after_setup?.date && event_after_setup.date <= setup_date) {
              const msg = i18n(
                'ENTITY.HIVE.ERRORS.An event apply on this hive on [event_after_setup_date] so hive could not be setup after that date'
              );
              const params = {
                event_after_setup_date: event_after_setup.date,
              };
              throw new ErrorHelperData([{ type: 'markdown', content: msg, translateParams: params }]);
            }
            setup_event.date = setup_date;
            return setup_event.save();
          })
        );
      }
    } else {
      // Create a new setup event
      if (isNil(apiary.id)) {
        throw new Error('Cannot create an hive setup without an apiary id');
      }
      if (isNil(new_setup_date)) {
        throw new Error('Cannot create an hive setup without a date');
      }
      // Should create hive
      const setup_event = new Event(this.bg2Api);
      setup_event.type = 'hive_setup';
      setup_event.date = new_setup_date; // TODO: Check if OK from bg2entity
      setup_event.setOperand('apiary', apiary.id);
      setup_event.data = { htype: this.htype }; // TODO: Check if OK from bg2entity

      setup_request = setup_request.pipe(
        // WHATE THE USE ??? should be done in this.save() ?
        // map(update_entity_response => {
        //   Object.assign(this, update_entity_response.entity);
        //   return this;
        // }),
        switchMap(() =>
          // Note this is more robust than simple apiary.state.warehouse_id
          // => get the warehouse id at the given date
          // => and if warehouse id is not in the state get it from location
          apiary.getAtDate('state.warehouse_id', new_setup_date, undefined, false).pipe(
            tap(warehouse_id => {
              setup_event.setOperand('warehouse', warehouse_id);
            })
          )
        ),
        switchMap(() => {
          setup_event.setOperand('hive', this.id);
          return setup_event.save();
        }),
        catchError((err: unknown) => {
          this._logger.info('Error while creating hive setup, need to delete the hive');
          return this.delete(true).pipe(switchMap(() => throwError(err)));
        })
      );
    }

    return setup_request;
  }

  /**
   * Creates a new hive/nuc from data.
   *
   * @param creation_model
   * @param apis Reference to the required APIs to create the hive.
   * @param services Reference to the required services to create the hive.
   * @param options Facultative options to create the hive/nuc.
   *
   * @returns Returns an observable on a new hive/nuc.
   */
  //TODO: this should return a Hive with a HiveSetupEvent that may be applyed localy
  public static create(
    creation_model: { htype: HiveType; apiary?: Apiary },
    apis: { bg2Api: Beeguard2Api; deviceApi: DeviceApi },
    services: { translate: TranslateService },
    options?: { offset: number }
  ): Observable<Hive> {
    const new_hive = apis.bg2Api.createGhostEntity({
      type: 'hive',
      static_state: {},
      last_state: {
        state: {
          htype: creation_model?.htype,
          apiary_id: creation_model?.apiary?.id,
        },
        error: null,
        event_id: -1,
        event_date: new Date(),
        dirty: false,
        is_last: true,
        previous_event_id: null,
        next_event_id: null,
      },
    }) as Hive;

    console.log(new_hive);

    // Compute default name
    return new_hive.compute_default_name$$(creation_model?.apiary, services.translate, options).pipe(
      map(new_hive_name => {
        new_hive.name = new_hive_name;
        return new_hive;
      })
    );
  }

  //#endregion

  public getAtDate(path: string, date?: Date, event_id?: number): Observable<any> {
    if (path === 'state.warehouse_id') {
      return this.bg2Api.fetch_entity_state$(this.id, date, event_id).pipe(
        switchMap((res: any) => {
          const state = res.state.state;
          if (!isNil(state) && !isNil(state.warehouse_id)) {
            return of(state.warehouse_id);
          } else if (!isNil(state) && !isNil(state.apiary_id)) {
            // Fail back to get warehouse id from exploiation
            console.warn(this.desc, 'warehouse_id not in state, get ot from apiary');
            return this.bg2Api.getEntityObj(state.apiary_id).pipe(
              map((entity: Entity) => entity as Apiary),
              switchMap(apiary => {
                if (!isNil(apiary)) {
                  return apiary.getAtDate(path, date, event_id);
                } else {
                  return of(null);
                }
              })
            );
          } else {
            return of(null);
          }
        })
      );
    } else {
      return super.getAtDate(path, date, event_id);
    }
  }

  public preDestroy(): void {
    super.preDestroy();
    this.devices_config_sub?.unsubscribe();
  }

  public setApiary(apiary: Apiary, hive_nb: number): void {
    this.hive_nb = hive_nb;
    this._apiary = apiary;
  }

  private clearCache(): void {
    // console.log(`Hive#${this.id} clearCache()`);
    this.weight_data_cache = {};
  }

  public hive_meas2dev_meas(meas: string): string {
    switch (meas) {
      case 'weight':
        return 'weight_total';
      case 'internal_temperature':
        return 'temperature';
      default:
        return meas;
    }
  }

  public dev_meas2hive_meas(meas: string): string {
    switch (meas) {
      case 'weight_total':
        return 'weight';
      case 'weight_total_nb_sensors':
        return 'weight_nb_sensors';
      case 'temperature':
        return 'temperature'; // NOTE: internal_temperature stay as 'temperature'
      default:
        return meas;
    }
  }

  public dev2hive_value(meas: string, value: number): number {
    switch (meas) {
      case 'weight':
        return value ? value * 2 : value; // This is needed to avoir to convert null to 0
      default:
        return value;
    }
  }

  /** Override request timeseries in case of ghost apiary
   */
  public requestGhostTimeseries(
    measurements?: Array<string>,
    start?: Date,
    end?: Date,
    step?: string
  ): Observable<GetEntityTimeseriesResponse> {
    if (this.id < 0 && measurements && measurements.length > 0) {
      // this._logger.debug(`Ghost hive local requests timeseries`, measurements);
      const dev_measurements = measurements.map(meas => this.hive_meas2dev_meas(meas));
      return this.devices$$.pipe(
        switchMap(devices => {
          const all_requests = devices.map(device => device.requestTimeseries(dev_measurements, start, end, step));
          return combineLatest(all_requests);
        }),
        map(all_ts => {
          // As we are in a ghost hive have only one device
          // TODO aggregation nb_sensors
          const ts = all_ts[0];
          if (!isNil(ts.timeseries)) {
            ts.timeseries.data = ts.timeseries.data.map(dpoint => {
              const _dpoint = dpoint as Dictionary<any>;
              keys(dpoint).forEach((dev_meas: string) => {
                if (has(_dpoint, dev_meas)) {
                  const meas = this.dev_meas2hive_meas(dev_meas);
                  if (meas !== dev_meas) {
                    _dpoint[meas] = _dpoint[dev_meas];
                    delete _dpoint[dev_meas];
                  }
                  _dpoint[meas] = this.dev2hive_value(meas, _dpoint[meas]);
                }
              });
              return _dpoint as DataPoint;
            });
          } else {
            ts.timeseries = { data: [] };
          }
          return ts;
        })
      );
    } else {
      return super.requestGhostTimeseries(measurements, start, end, step);
    }
  }

  public streamGhostAvailableTimeseries(start: Date, end: Date): Observable<MeasurementDescription[]> {
    if (this.id < 0) {
      return combineLatest({
        wg_gen_gen1: this._wg_gen1$$,
        wg_gen_gen2: this._wg_gen2$$,
        gps: this._gps$$,
      }).pipe(
        debounceTime(20),
        map(({ gps, wg_gen_gen1, wg_gen_gen2 }) => {
          const has_wg_gen1 = wg_gen_gen1.length > 0;
          const has_wg_gen2 = wg_gen_gen2.length > 0;
          const has_gps = gps.length > 0;
          const ats = [];
          if (has_gps) {
            ats.push({
              id: 'hit',
              type: 'temperature',
              name: 'internal_temperature',
              title: 'ALL.TIMESERIES.Hive_internal_temperature',
              description: 'ALL.TIMESERIES.Internal temperature of the hive',
            });
          }
          if (has_wg_gen1 || has_wg_gen2) {
            ats.push({
              id: 'wt',
              type: 'weight',
              name: 'weight',
              title: 'ALL.TIMESERIES.Total weight',
              description: 'ALL.TIMESERIES.Total weight',
              description_short: 'ALL.TIMESERIES.Total weight',
            });
          }
          // Add patmo
          if (has_wg_gen1 || has_wg_gen2) {
            ats.push({
              id: 'et',
              type: 'temperature',
              name: 'temperature',
              title: 'ALL.TIMESERIES.External_temperature',
              description: 'ALL.TIMESERIES.External temperature',
              description_short: 'ALL.TIMESERIES.External',
            });
          }
          if (has_wg_gen1) {
            ats.push({
              id: 'h',
              type: 'humidity',
              name: 'humidity',
              title: 'ALL.TIMESERIES.Humidity',
              description: 'ALL.TIMESERIES.GPS humidity',
              description_short: 'ALL.TIMESERIES.Humidity',
            });
          }
          return ats;
        })
      );
    } else {
      return of([]);
    }
  }

  /** Load hive weight (form WGuard weight data)
   *
   * data are grouped by hour
   */
  public requestWeightData(start?: Date, end?: Date): Observable<WeightDataPoint[]> {
    let request;
    if (this.weight_data_cache[start.toString() + end.toString()]) {
      // console.log(`Get wguards/weight data from cache`);
      request = of(this.weight_data_cache[start.toString() + end.toString()]);
    } else {
      // console.log(`Request wguards/weight data ${start.toString()} -> ${end.toString()}`);
      request = this.requestTimeseries(['weight'], start, end).pipe(
        map(res => {
          const ret: WeightDataPoint[] = res.timeseries.data.map(point => {
            point.date = parseDate(point.date);
            return point as WeightDataPoint;
          });
          return ret;
        })
      );
      request = request.pipe(
        map(data => {
          // Add to cache
          this.weight_data_cache[start.toString() + end.toString()] = data;
          return data;
        })
      );
    }
    return request;
  }

  public requestApiaryAtDate(date: Date = null, event_id: number = null): Observable<Apiary> {
    console.log(`requestApiaryAtDate(${date})`);
    return this.getAtDate('state.apiary_id', date, event_id).pipe(
      switchMap(apiary_id => {
        if (isNil(apiary_id)) {
          return of(null);
        } else {
          return this.bg2Api.getEntityObj(apiary_id) as Observable<Apiary>;
        }
      })
    );
  }

  public requestLocationAtDate(date: Date = null, event_id: number = null): Observable<Location> {
    return this.requestApiaryAtDate(date, event_id).pipe(switchMap(apiary => apiary.requestLocationAtDate(date, event_id)));
  }

  public asDict(): Dictionary<any> {
    const entity_dict = super.asDict();
    entity_dict.static_state.color = this.color;
    entity_dict.static_state.hive_nb = this.hive_nb;
    return entity_dict;
  }

  public badly_associated_devices$$: Observable<DRDevice[]> = this.has_apiary$$.pipe(
    switchMap(has_apiary => {
      if (!has_apiary) {
        return of({ bad_devices: [], imeis: [] });
      }

      return this.apiary$$.pipe(
        switchMap(apiary => apiary.badly_associated_devices$$),
        switchMap(bad_devices => this.devices$$.pipe(map(hive_devices => ({ bad_devices, imeis: hive_devices.map(d => d.imei) }))))
      );
    }),
    map(data => data.bad_devices.filter(device => data.imeis.includes(device.imei)))
  );

  // #region -> (hive filters)

  public name_contains(fname: string = null): boolean {
    return fname && fname !== '' ? this?.name?.toLowerCase().includes(fname?.toLowerCase()) : true;
  }

  public has_type(ftype: string = null): boolean {
    return ftype && ftype !== '' ? this?.htype === ftype : true;
  }

  public has_compatible_dynamism_value(fdynamism: { min: number; max: number } = null): boolean {
    if (!isNil(this.dynamism) && fdynamism?.min && fdynamism?.max) {
      return includes(range(fdynamism.min, fdynamism.max + 1), this.dynamism);
    } else {
      return true;
    }
  }

  public has_compatible_brood_frames_number(fbrood_frames: { min: number; max: number } = null): boolean {
    if (!isNil(this.nb_brood_frames) && fbrood_frames?.min && fbrood_frames?.max) {
      return this?.nb_brood_frames >= fbrood_frames?.min && this?.nb_brood_frames <= fbrood_frames?.max;
    } else {
      return true;
    }
  }

  public has_compatible_supers_number(fsupers: { min: number; max: number } = null): boolean {
    if (!isNil(this.nb_supers) && fsupers?.min && fsupers?.max) {
      return includes(range(fsupers.min, fsupers.max + 1), this.nb_supers);
    } else {
      return true;
    }
  }

  // #endregion

  // #region -> (last weight data management)

  /** */
  private _weight$: Observable<{ timestamp: Date; value: number }> = this.devices$$.pipe(
    map(devices => devices.filter(device => device.type === DeviceInterface.TypeEnum.WG).map(wguard => wguard as WGDevice)),
    map((wguards: WGDevice[]) => wguards.filter(wguard => wguard.last_weight && wguard.last_weight.time)),
    filter((wguards: WGDevice[]) => wguards.length > 0),
    map((wguards: WGDevice[]) => {
      // console.log(wguards.map(wguard => wguard.last_weight.time))
      const last_ts = max(wguards.map(wguard => wguard.last_weight.time));
      const last_date = new Date(last_ts);
      return last_date;
    }),
    filter(date => !isNil(date)),
    switchMap((date: Date) =>
      // Note: demande de timeseries pour eviter de devoir aggrégé ici les données des devices
      // TODO: faire ca coté server
      this.requestTimeseries(['weight'], subMinutes(date, 30), addMinutes(date, 30), '1h')
    ),
    map(timeseries => {
      const data = timeseries.timeseries.data as WeightDataPoint[];
      return data.filter(point => !isNil(point.weight));
    }),
    map(data => (data.length > 0 ? data : null)),
    map(wgs => {
      let res = null;
      if (!isNil(wgs)) {
        res = {
          timestamp: new Date(last(wgs).date),
          value: last(wgs).weight,
        };
        // Note: Dans une précédente version on demandais les données brutes
        // mais cela possait pb voir https://gitlab.dev.siconsult.fr:9090/beeguard_v2/beeguard2-ng-app/-/issues/857
      }
      return res;
    })
  );

  /**
   * Observes the last measured hive's weight.
   */
  public last_weight$$: Observable<{ timestamp: Date; value: number }> = concat(of(null), this._weight$).pipe(
    debounceTime(300), // may avoid initial null if real value available
    replay()
  );

  /** */
  public is_weight_outdated_by_48h$$ = of(new Date()).pipe(
    switchMap(current_date =>
      this.last_weight$$.pipe(
        map(weight => {
          if (isNil(weight)) {
            return false;
          }

          return differenceInHours(current_date, parseDate(weight?.timestamp)) > 48;
        })
      )
    ),
    distinctUntilRealChanged(),
    replay()
  );

  // #endregion

  // #region -> (last beecount data)

  /** */
  private _beecount$: Observable<{ day_m1: BeeCountDataPoint; day_m2: BeeCountDataPoint }> = this.devices$$.pipe(
    map(devices => devices.filter(device => device.type === 'CPT' || device.type === 'BeeLive')),
    filter((devices: (CPTDevice | BeeLiveDevice)[]) => devices.length > 0),
    switchMap(() => this.requestTimeseries(['count_in', 'count_out'], undefined, undefined, '1d')),
    map(response => {
      const current_date = endOfYesterday();
      const data = (response?.timeseries?.data ?? [])?.map(datum => {
        datum.date = parseDate(datum.date);
        return <BeeCountDataPoint>datum;
      });

      const day_m1 = data?.find(datum => isSameDay(datum.date, current_date));
      const day_m2 = data?.find(datum => isSameDay(datum.date, subDays(current_date, 1)));

      return { day_m2, day_m1 };
    })
  );

  /**
   * Observes the last measured hive's beecount.
   */
  public last_beecount$$: Observable<{ day_m1: BeeCountDataPoint; day_m2: BeeCountDataPoint }> = concat(of(null), this._beecount$).pipe(
    debounceTime(300),
    replay()
  );

  // #endregion

  // #region -> (last internal temperature data management)

  /** */
  public tempin: any = { min: null, max: null, value: null, timestamp: null };

  /** */
  public tempin_device: GPSDevice | TGDevice;

  /** */
  private _tempin$: Observable<any> = this.devices$$.pipe(
    map(
      devices =>
        devices.filter(device => device.type === DeviceInterface.TypeEnum.GPS || device.type === DeviceInterface.TypeEnum.TG) as (
          | GPSDevice
          | TGDevice
        )[]
    ),
    map(devices => devices.filter(_gps => !isNil(_gps.last_env))),
    filter(devices => devices.length > 0),
    map((devices: (GPSDevice | TGDevice)[]) => {
      this.tempin_device = maxBy(devices, value => value?.last_env?.time);
      this.tempin.timestamp = min([new Date(), new Date(this.tempin_device.last_env.time)]);

      return this.tempin.timestamp;
    }),
    switchMap((date: Date) => this.requestTimeseries(['internal_temperature'], subHours(date, 24), addHours(date, 1), 'raw')),
    map(timeseries => {
      const data = (timeseries.timeseries.data || []) as TemperatureDataPoint[];

      return data.filter(_data => !isNil(_data.temperature));
    }),
    map(data => {
      if (!(data.length > 0)) {
        const last_env = this.tempin_device.last_env;

        if (last_env) {
          return [{ temperature: last_env.fields.temperature.last }];
        }
      }

      return data;
    }),
    filter(data => data.length > 0),
    map(data => {
      if (data.length === 1) {
        this.tempin.value = data[0].temperature;

        return this.tempin;
      }

      const minimal_internal_temp_data = minBy(data, 'temperature') as TemperatureDataPoint;
      const maximal_internal_temp_data = maxBy(data, 'temperature') as TemperatureDataPoint;

      this.tempin.min = minimal_internal_temp_data.temperature;
      this.tempin.timestamp_for_min = parseDate(minimal_internal_temp_data.date);

      this.tempin.max = maximal_internal_temp_data.temperature;
      this.tempin.timestamp_for_max = parseDate(maximal_internal_temp_data.date);

      return this.tempin;
    })
  );

  /**
   * Observes the last measured hive's internal temperature.
   */
  public last_internal_temperature$$: Observable<{
    min: number;
    max: number;
    value: number;
    timestamp: Date;
    timestamp_for_min: Date;
    timestamp_for_max: Date;
  }> = concat(of(null), this._tempin$).pipe(
    debounceTime(300), // may avoid initial null if real value available
    replay()
  );

  /** */
  public is_internal_temperature_outdated_by_48h$$ = of(new Date()).pipe(
    switchMap(current_date =>
      this.last_internal_temperature$$.pipe(
        map(tempin => {
          if (isNil(tempin)) {
            return false;
          }

          return differenceInHours(current_date, parseDate(tempin?.timestamp)) > 48;
        })
      )
    ),
    distinctUntilRealChanged(),
    replay()
  );

  // #endregion

  // #region -> (last internal humidity data management)

  /** */
  public internal_humidity: any = { min: null, max: null, value: null, timestamp: null };

  /** */
  public internal_humidity_device: GPSDevice;

  /** */
  private _internal_humidity$$: Observable<any> = this.devices$$.pipe(
    map(devices => devices.filter(device => device.type === DeviceInterface.TypeEnum.GPS).map(gps => gps as GPSDevice)),
    filter(gps => gps.length > 0),
    map(gps => gps.filter(_gps => !isNil(_gps.last_env))),
    filter(gps => gps.length > 0),
    map((gps: GPSDevice[]) => {
      this.internal_humidity_device = maxBy(gps, value => value?.last_env?.time);
      this.internal_humidity.timestamp = min([new Date(), new Date(this.internal_humidity_device.last_env.time)]);
      return this.internal_humidity.timestamp;
    }),
    switchMap((date: Date) => this.requestTimeseries(['internal_humidity'], subHours(date, 24), addHours(date, 1), 'raw')),
    map(timeseries => {
      const data = (timeseries.timeseries.data || []) as any[];
      return data.filter(_data => !isNil(_data.humidity));
    }),
    map(data => {
      if (data.length > 0) {
        return data;
      }

      const last_env = this.internal_humidity_device?.last_env;
      if (last_env?.fields?.humidity?.last) {
        return [{ humidity: last_env.fields.humidity.last }];
      }
    }),
    filter(data => data?.length > 0),
    map(data => {
      if (data.length === 1) {
        this.internal_humidity.value = data[0].humidity;

        return this.internal_humidity;
      }

      const minimal_internal_hum_data = minBy(data, 'humidity') as any;
      const maximal_internal_hum_data = maxBy(data, 'humidity') as any;

      this.internal_humidity.min = minimal_internal_hum_data.humidity;
      this.internal_humidity.timestamp_for_min = parseDate(minimal_internal_hum_data.date);

      this.internal_humidity.max = maximal_internal_hum_data.humidity;
      this.internal_humidity.timestamp_for_max = parseDate(maximal_internal_hum_data.date);

      return this.internal_humidity;
    })
  );

  /**
   * Observes the last measured hive's internal humidity.
   */
  public last_internal_humidity$$: Observable<{
    min: number;
    max: number;
    value: number;
    timestamp: Date;
    timestamp_for_min: Date;
    timestamp_for_max: Date;
  }> = concat(of(null), this._internal_humidity$$).pipe(debounceTime(300), replay());

  /** */
  public is_internal_humidity_outdated_by_48h$$ = of(new Date()).pipe(
    switchMap(current_date =>
      this.last_internal_humidity$$.pipe(
        map(internal_humidity => {
          if (isNil(internal_humidity)) {
            return false;
          }

          return differenceInHours(current_date, parseDate(internal_humidity?.timestamp)) > 48;
        })
      )
    ),
    distinctUntilRealChanged(),
    replay()
  );

  // #endregion

  // #region -> (last data management)

  /**
   * Observable on the last data of the hive. It includes the **weight**, the **internal temperature** and the **internal humidity**.
   */
  public last_data$$ = combineLatest([this.last_weight$$, this.last_internal_temperature$$, this.last_internal_humidity$$]).pipe(
    map(([weight, tempin, internal_humidity]) => ({ weight, tempin, internal_humidity })),
    distinctUntilRealChanged(),
    replay()
  );

  /**
   * Observes if the hive has at least one available last data.
   */
  public has_last_data$$ = this.last_data$$.pipe(
    map(last_data => {
      const weight = last_data.weight;
      const tempin = last_data.tempin;
      const humidity = last_data.internal_humidity;

      return !isNil(weight?.value) || !isNil(tempin?.min) || !isNil(humidity?.min);
    }),
    distinctUntilRealChanged(),
    replay()
  );

  /**
   * Observes if the hive's last data are all outdated.
   */
  public is_last_data_outdated_by_48h$$ = allTrue(
    this.is_weight_outdated_by_48h$$,
    this.is_internal_humidity_outdated_by_48h$$,
    this.is_internal_temperature_outdated_by_48h$$
  ).pipe(distinctUntilRealChanged(), replay());

  // #endregion

  // #region -> (devices management)

  /**
   * Observes the list of hive's WG devices.
   */
  public devices_wg$$: Observable<WGDevice[]> = this.devices$$.pipe(
    map(devices => devices.filter(device => device.type === 'WG') as WGDevice[]),
    replay()
  );

  /**
   * Observes the list of hives's GPS devices.
   */
  public devices_gps$$: Observable<GPSDevice[]> = this.devices$$.pipe(
    map(devices => devices.filter(device => device.type === 'GPS') as GPSDevice[]),
    replay()
  );

  /**
   * Observes the worst battery state of hive's devices (GPS and WG).
   */
  public devices_worst_state_battery$$: Observable<DeviceStatusBat> = this.devices$$.pipe(
    map(devices => devices.filter(device => device.type === 'GPS' || device.type === 'WG')),
    switchMap(devices => {
      const device_battery_states$$ = devices.map(device => device.status_bat$$);
      return robustCombineLatest(device_battery_states$$);
    }),
    map(statuses => find_worst_device_status_in(statuses)),
    distinctUntilRealChanged()
  );

  /** */
  public devices_worst_state_battery_simplified$$ = this.devices$$.pipe(
    switchMap(devices => {
      const device_battery_states$$ = devices.map(device => device.battery_simplified_state$$);
      return robustCombineLatest(device_battery_states$$);
    }),
    map(statuses => find_worst_device_status_simplified_in(statuses)),
    distinctUntilRealChanged()
  );

  /**
   * Observes the worst GPRS state of hive's GPSs.
   */
  public devices_worst_state_gprs$$: Observable<DeviceStatusGPRS> = this.devices_gps$$.pipe(
    switchMap(gps_list => {
      const gps_gprs_state$$ = gps_list.map(gps => gps.status_gprs$$);
      return robustCombineLatest(gps_gprs_state$$);
    }),
    map(statuses => find_worst_device_status_in(statuses)),
    distinctUntilRealChanged()
  );

  /**
   * Observes the worst GPS state of hive's GPSs.
   */
  public devices_worst_state_gps$$: Observable<DeviceStatusGPS> = this.devices_gps$$.pipe(
    switchMap(gps_list => {
      const gps_gps_state$$ = gps_list.map(gps => gps.status_gps$$);
      return robustCombineLatest(gps_gps_state$$);
    }),
    map(statuses => find_worst_device_status_in(statuses, 'gps')),
    distinctUntilRealChanged()
  );

  /**
   * Observes the worst 868 state of hive's WGs.
   */
  public devices_worst_state_868$$: Observable<DeviceStatus868> = this.devices_wg$$.pipe(
    switchMap(wg_list => {
      const wg_868_state$$ = wg_list.map(wg => wg.status_868$$);
      return robustCombineLatest(wg_868_state$$);
    }),
    map(statuses => find_worst_device_status_in(statuses)),
    distinctUntilRealChanged()
  );

  /**
   * Observes the worst observation state of hive's devices.
   */
  public devices_worst_observation_state$$ = this.devices$$.pipe(
    switchMap(devices => {
      const device_obs_states$$ = devices.map(device => device.observation_state$$);
      return robustCombineLatest(device_obs_states$$);
    }),
    map(observation_states =>
      orderBy(observation_states, obs_state => ['have_issue', 'need_check', 'ok', null, undefined].indexOf(obs_state.state))
    ),

    map(ordered_states => ordered_states?.[0]),
    distinctUntilRealChanged()
  );

  /** */
  public devices_last_com$$ = this.devices$$.pipe(
    switchMap(devices => {
      const last_coms$$ = devices.map(device => device.last_contact$$);
      return combineLatest(last_coms$$).pipe(map(dates => max(dates)));
    })
  );

  // #endregion

  // #region -> (editing form model)

  public get_editing_form_model$$ = this.static_state$$.pipe(
    map(() => {
      const form_model: HiveEditingFormModel = {
        name: this.name,
        htype: this.htype,
        color: this.color_hsl,
        date: this.setup_date.toISOString(),
      };

      if (!this?.id && differenceInMonths(new Date(), this.setup_date) >= 2) {
        form_model.date = startOfDay(new Date()).toISOString();
      }

      return form_model;
    })
  );

  // #endregion
}
