import { Component, ChangeDetectionStrategy } from '@angular/core';
import { Router } from '@angular/router';

import { AppStateService } from 'app/core/app-state.service';
import { RunSnapshot } from 'app/core/api/run';
import { Event } from 'app/models';

import { DeleteEventDialogComponent } from 'app/views/events/shared/delete-event-dialog/delete-event-dialog.component';
import { DialogsService } from 'app/widgets/dialogs-modals';

import { AbstractDialogComponent, AbstractDialogParams } from 'app/widgets/dialogs-modals/abstract-dialog.component';
import { encodeJson } from 'app/misc/tools';

export interface RunErrorDialogParams extends AbstractDialogParams {
  run: RunSnapshot;
}

@Component({
  selector: 'bg2-dialog-error',
  templateUrl: './run-error.dialog.html',
  styleUrls: ['./run-error.dialog.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RunErrorDialogComponent extends AbstractDialogComponent<RunErrorDialogParams, any> {
  constructor(public appState: AppStateService, private router: Router, private _dialogs: DialogsService) {
    super();
  }

  public update_event(event: Event): void {
    this.complete(true);
    this.router.navigate(['', { outlets: { modal: [event.vconf.update_modal, { eid: event.id }] } }], {
      queryParamsHandling: 'preserve',
    });
  }

  public contact_support(run: RunSnapshot) {
    this.complete(true);
    const data = encodeJson(run.error);
    this.router.navigate(['', { outlets: { modal: ['new_issue', { type: 'contact_support', data: data }] } }], {
      queryParamsHandling: 'preserve',
    });
  }

  public close(): void {
    this.complete(true);
  }

  public deleteEvent(eventID: number) {
    this.complete(true);
    DeleteEventDialogComponent.open(this._dialogs, { eventID }).subscribe();
  }
}
