import { isNil } from 'lodash-es';

import { map } from 'rxjs';
import { Observable } from 'rxjs';
import { marker as i18n } from '@biesbjerg/ngx-translate-extract-marker';

import { Beeguard2Api } from 'app/core';

import { Dictionary } from 'app/typings/core/interfaces';
import { Event } from './Event';
import { Entity, Apiary, Location } from '..';
import { replay } from 'app/misc/tools';

export class SetupApiary extends Event {
  constructor(protected bg2Api: Beeguard2Api) {
    super(bg2Api);
    this.type = 'apiary_setup';
    this.resetLogger();
  }

  protected _applyLocally(entities: Dictionary<Entity | Entity[]>): Event {
    const location = entities.location as Location;
    const apiary = entities.apiary as Apiary;
    // Update location
    const location_state = location.getPreviousLocalState(this.date, this.id);
    location_state.apiary_id = apiary.id;
    location.storeLocalState(this.date, this.id, location_state);
    // Update location
    const state = apiary.getPreviousLocalState(this.date, this.id);
    state.location_id = location.id;
    state.location_id_since = this.since();
    apiary.storeLocalState(this.date, this.id, state);
    return this;
  }

  protected getI18nParams(translate_service: any, entities: any) {
    return super.getI18nParams(translate_service, entities).pipe(
      map(i18nParams => {
        if (isNil(i18nParams?.data)) {
          i18nParams.data = {
            nb_hives: '0',
          };
        }

        return i18nParams;
      })
    );
  }
}

export class OtherIntervention extends Event {
  /** */
  public intervention_name$$: Observable<string | null> = this.data$$.pipe(
    map(data => data?.intervention_name ?? null),
    replay()
  );

  /** */
  public comment$$: Observable<string | null> = this.data$$.pipe(
    map(data => data?.comment ?? null),
    replay()
  );

  protected getDescKey(role: any, schema: any, i18nData: any): Observable<string> {
    return super.getDescKey(role, schema, i18nData).pipe(
      map(desc_key => {
        if (this.data.comment) {
          return desc_key;
        } else {
          if (role === 'apiary') {
            return i18n('EVENT.OTHER_INTERVENTION.[data->intervention_name]');
          } else {
            return i18n("EVENT.OTHER_INTERVENTION.[intervention_name] on apiary '[entities->apiary->name]'");
          }
        }
      })
    );
  }

  protected getI18nParams(translate_service: any, entities: any) {
    return super.getI18nParams(translate_service, entities).pipe(
      map(i18nParams => {
        const nb_max = 30;
        const comment = i18nParams.data.comment || '';
        i18nParams.data.comment_TRUNCATE = comment.length > nb_max ? comment.slice(0, nb_max) + '...' : comment;
        return i18nParams;
      })
    );
  }
}
