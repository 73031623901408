import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { BehaviorSubject, map, Observable, of, switchMap } from 'rxjs';
import { replay, waitForNotNilValue } from '@bg2app/tools/rxjs';

import { isNil } from 'lodash-es';

import { ZohoAuthService } from 'app/core/services/zoho/zoho-auth.service';
import { ZohoDeskApiService } from 'app/core/services/zoho/zoho-desk-api.service';

import { DRDevice, SimpleSetterGetter } from 'app/models';
import { encodeJson } from 'app/misc/tools';
import { ZohoDeskTicket } from 'app/models/zoho/desk';

/** */
type ZohoDeskTicketSource = DRDevice | any;

@Component({
  selector: 'bg2-zoho-ticket-list-opened',
  templateUrl: './zoho-ticket-list-opened.component.html',
  styleUrls: ['zoho-ticket-list-opened.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ZohoTicketListOpenedComponent {
  // #region -> (component basics)

  constructor(private readonly _zohoAuthService: ZohoAuthService, private readonly _zohoDeskApiService: ZohoDeskApiService) {}

  // #endregion

  // #region -> (zoho data)

  @Input()
  public set should_refetch_zoho_data(should_refetch_zoho_data: boolean) {
    this.refetch_zoho_data.value = should_refetch_zoho_data;
  }

  /** */
  private refetch_zoho_data = new SimpleSetterGetter(false);

  // #endregion

  // #region -> (source)

  /** */
  @Input()
  public set source(source: ZohoDeskTicketSource) {
    this._source$$.next(source);
  }

  /** */
  private _source$$ = new BehaviorSubject<ZohoDeskTicketSource>(null);

  /** */
  public source$$: Observable<ZohoDeskTicketSource> = this._source$$.asObservable();

  /** */
  public ask_device_support_data$$ = this.source$$.pipe(
    waitForNotNilValue(),
    map(device => ({ name: device?.name })),
    map(data => encodeJson([data])),
    replay()
  );

  // #endregion

  // #region -> (tickets)

  /** */
  private _tickets$$: Observable<ZohoDeskTicket[]> = this.refetch_zoho_data.value$$.pipe(
    switchMap(() => this.source$$),
    switchMap(source => {
      if (isNil(source)) {
        return of([]);
      }

      if (source instanceof DRDevice) {
        return this._zohoDeskApiService
          .search_tickets({ customField1: `cf_imeis:${source.imei}` }, { statusType: ['Open', 'On Hold'] })
          .pipe(map(response => response?.data ?? []));
      }

      return of([]);
    })
  );

  /** */
  public tickets$$: Observable<ZohoDeskTicket[]> = this._zohoAuthService.is_authenticated$$.pipe(
    switchMap(is_authenticated => {
      if (!is_authenticated) {
        return of([]);
      }

      return this._tickets$$;
    }),
    replay()
  );

  /** */
  public has_tickets$$ = this.tickets$$.pipe(
    map(tickets => tickets?.length > 0),
    replay()
  );

  // #endregion
}
