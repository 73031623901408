import { Injectable } from '@angular/core';
import { distinctUntilRealChanged, waitForNotNilValue } from '@bg2app/tools/rxjs';
import { BehaviorSubject, debounceTime } from 'rxjs';

/**
 * @interface
 * @description
 *
 *
 */
export interface D3SharedCursor {
  /**
   * @description
   *
   *
   */
  date: Date;

  /**
   * @description
   *
   *
   */
  from: string;

  /**
   * @description
   *
   *
   */
  event_type: 'mouseenter' | 'mousemove' | 'mouseout';
}

/**
 * @class
 * @description
 *
 *
 */
@Injectable({
  providedIn: 'root',
})
export class D3SharedCursorService {
  // #region -> (service basics)

  constructor() {}

  // #endregion

  // #region -> (shared cursor management)

  /** */
  private _shared_cursor$$ = new BehaviorSubject<D3SharedCursor>(null);

  /** */
  public shared_cursor$$ = this._shared_cursor$$.asObservable().pipe(waitForNotNilValue());

  /** */
  public set shared_cursor(shared_cursor: D3SharedCursor) {
    this._shared_cursor$$.next(shared_cursor);
  }

  /** */
  public get shared_cursor(): D3SharedCursor {
    return this._shared_cursor$$.getValue();
  }

  // #endregion
}
