import { catchError, map, of, switchMap } from 'rxjs';

import { NopDeviceEvent } from './NopDeviceEvent';


export class DeviceTakeout extends NopDeviceEvent {
  protected getI18nParams(translate_service: any, entities: any) {
    return super.getI18nParams(translate_service, entities).pipe(
      switchMap(i18nParams => {
        const imei = i18nParams.data.device.imei;

        return this.bg2Api.deviceApi.requestDevice(imei).pipe(
          map(device => {
            i18nParams.data.device = device;
            i18nParams.data.device = this.extandDeviceParams(i18nParams.data.device, null);
            return i18nParams;
          }),
          catchError((error: unknown) => {
            i18nParams.data.device = { imei, type: 'device', link: `device#${imei}` };
            return of(i18nParams);
          })
        );
      })
    );
  }
}

export class DeviceRGTakeout extends DeviceTakeout {}
