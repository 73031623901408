import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

import { marker as i18n } from '@biesbjerg/ngx-translate-extract-marker';

import { CalendarEvent } from 'angular-calendar';

import { BehaviorSubject, map } from 'rxjs';
import { distinctUntilRealChanged, replay } from '@bg2app/tools/rxjs';

import { isBefore } from 'date-fns';

import { flatten, isNil } from 'lodash-es';

import { DialogsService } from 'app/widgets/dialogs-modals';
import { AppStateService } from 'app/core/app-state.service';

import { MovementAuthorizationsByDevice } from '../device-movements-authorization-modal.component';
import { DeviceAuthorizedMovementDialogComponent } from '../../../dialogs/device-authorized-movement-dialog/device-authorized-movement-dialog.component';

@Component({
  selector: 'bg2-device-authorized-movements-modal-calendar-view',
  templateUrl: './calendar-view.component.html',
  styleUrls: ['./calendar-view.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DeviceAuthorizedMovementsModalCalendarViewComponent {
  // #region -> (modal basics)

  /** */
  constructor(
    private readonly _dialogs: DialogsService,
    private readonly _app_state_service: AppStateService,
    private readonly _breakpointObserver: BreakpointObserver
  ) {}

  // #endregion

  // #region -> (authorized movement management)

  /** */
  private _authorized_movements_by_device$$ = new BehaviorSubject<MovementAuthorizationsByDevice[]>(<any>[]);

  /** */
  private authorized_movements_by_device$$ = this._authorized_movements_by_device$$.asObservable();

  /** */
  @Input()
  public set authorized_movements_by_device(value: MovementAuthorizationsByDevice[]) {
    this._authorized_movements_by_device$$.next(value);
  }

  @Output()
  public reload_data = new EventEmitter<boolean>();

  // #endregion

  // #region -> (calendar config)

  private readonly CALENDAR_RESPONSIVE = {
    xsmall: {
      breakpoint: '(max-width: 599.98px)',
      daysInWeek: 1,
    },
    small: {
      breakpoint: '(max-width: 959.98px)',
      daysInWeek: 3,
    },
    medium: {
      breakpoint: '(max-width: 1279.98px)',
      daysInWeek: 5,
    },
  };

  /** */
  public language$$ = this._app_state_service.lang$$;

  /** */
  public days_in_week$$ = this._breakpointObserver
    .observe(Object.values(this.CALENDAR_RESPONSIVE).map(({ breakpoint }) => breakpoint))
    .pipe(
      map((state: BreakpointState) => {
        const found_breakpoint = Object.values(this.CALENDAR_RESPONSIVE).find(({ breakpoint }) => !!state.breakpoints[breakpoint]);

        if (found_breakpoint) {
          return found_breakpoint.daysInWeek;
        }

        return 7;
      }),
      distinctUntilRealChanged(),
      replay()
    );

  /** */
  private _current_date$$ = new BehaviorSubject(new Date());

  /** */
  public current_date$$ = this._current_date$$.asObservable();

  /** */
  public get current_date(): Date {
    return this._current_date$$.getValue();
  }

  /** */
  public set current_date(current_date: Date) {
    this._current_date$$.next(current_date);
  }

  /** */
  public when_day_changed(date: Date) {
    this.current_date = date;
  }

  // #endregion

  // #region -> (calendar events)

  public events$$ = this.authorized_movements_by_device$$.pipe(
    map(movement_authorizations_by_device => {
      if (isNil(movement_authorizations_by_device)) {
        return;
      }

      // Build events
      const build_events = movement_authorizations_by_device.map(movement_authorizations_for_device => {
        const events_by_device = movement_authorizations_for_device.configurations.map(movement_authorization => {
          const calendar_event = <CalendarEvent>{};

          // Event basic properties
          calendar_event.id = movement_authorization.id;
          calendar_event.end = movement_authorization.end;
          calendar_event.start = movement_authorization.start;
          calendar_event.title = i18n<string>('ROUTABLE_MODALS.DEVICE_AUTHORIZED_MOVEMENT_MODAL.CALENDAR.EVENT.Movement authorized');

          // Event additionnal properties
          calendar_event.meta = {
            movement_authorization: movement_authorization,
            device: movement_authorizations_for_device.device,
          };

          // Make event editable or not
          if (!isBefore(calendar_event.end, new Date())) {
            calendar_event.actions = [
              {
                label: '',
                onClick: ({ event }): void => {},
              },
            ];
          }

          return calendar_event;
        });

        return events_by_device;
      });

      return build_events;
    }),
    map(events => flatten(events))
  );

  // #endregion

  // #region -> (authorized move)

  /** */
  public update_authorized_movement(event: CalendarEvent): void {
    if (isBefore(event.end, new Date())) {
      return;
    }

    this._dialogs
      .open(DeviceAuthorizedMovementDialogComponent, {
        imeis: [],
        authorized_movement: {
          id: event.meta.movement_authorization.id,
          imei: event.meta.movement_authorization.device.imei,
        },
      })
      .subscribe({
        next: (response: boolean) => {
          if (response) {
            this.reload_data.emit(true);
          }
        },
      });
  }

  // #endregion
}
