import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { BehaviorSubject } from 'rxjs';

import { ZohoCRMTask } from 'app/models/zoho';

@Component({
  selector: 'bg2-zoho-crm-task-summary',
  templateUrl: './zoho-crm-task-summary.component.html',
  styleUrls: ['./zoho-crm-task-summary.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ZohoCRMTaskSummaryComponent {
  // #region -> (component basics)

  constructor() {}

  // #endregion

  // #region -> (task object)

  /** */
  private _task$$ = new BehaviorSubject<ZohoCRMTask>(null);

  /** */
  public task$$ = this._task$$.asObservable();

  @Input()
  public set task(task: ZohoCRMTask) {
    this._task$$.next(task);
  }

  public get note(): any {
    return this._task$$.getValue();
  }

  // #endregion
}
