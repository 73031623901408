import { Observable } from 'rxjs';

/**
 * Converts a file to a Base64 string.
 */
export const convertFileToBase64Obs = (myFile: File) =>
  new Observable<string | ArrayBuffer>(subscriber => {
    const reader = new FileReader();

    reader.readAsDataURL(myFile);
    reader.onload = () => {
      subscriber.next(reader.result);
      subscriber.complete();
    };
    reader.onerror = () => subscriber.error(reader.error);

    return () => reader.abort();
  });
