import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { BeeguardAuthGuard } from './core/auth/beeguard-auth.guard';

import { ModalsService } from './widgets/dialogs-modals/modals.service';

import { LoginComponent } from './views/auth/login/login.component';
import { LogoutComponent } from './views/auth/logout/logout.component';
import { ModalsManagerComponent } from './app.modals-manager.component';
import { ResetPasswordComponent } from './views/auth/reset-password/reset-password.component';
import { BeeguardAdminGuard } from './core/auth/beeguard-admin.guard';

export const routes: Routes = [
  {
    path: 'login',
    component: LoginComponent,
  },
  {
    path: 'logout',
    component: LogoutComponent,
  },
  {
    path: 'reset-password',
    component: ResetPasswordComponent,
  },
  {
    path: 'locations',
    canActivate: [BeeguardAuthGuard],
    loadChildren: () => import('./views/locations/locations.module').then(m => m.LocationsModule),
  },
  {
    path: 'exploitations',
    canActivate: [BeeguardAuthGuard],
    loadChildren: () => import('./views/exploitations/exploitations.module').then(m => m.ExploitationsModule),
  },
  {
    path: 'exploitation/:id',
    redirectTo: 'exploitations/:id',
  },
  {
    path: 'hives',
    canActivate: [BeeguardAuthGuard],
    loadChildren: () => import('./views/hives/hives.module').then(m => m.HivesModule),
  },
  {
    path: 'data',
    canActivate: [BeeguardAuthGuard],
    loadChildren: () => import('./views/data/data.module').then(m => m.DataModule),
  },
  {
    path: 'devices',
    canActivate: [BeeguardAuthGuard],
    loadChildren: () => import('./views/devices/devices.module').then(m => m.DevicesModule),
  },
  {
    path: 'events',
    canActivate: [BeeguardAuthGuard],
    loadChildren: () => import('./views/events/events.module').then(m => m.EventsModule),
  },
  {
    path: 'users',
    canActivate: [BeeguardAuthGuard],
    loadChildren: () => import('./views/user/user.module').then(m => m.UserModule),
  },
  {
    path: 'zoho',
    canActivate: [BeeguardAuthGuard, BeeguardAdminGuard],
    loadChildren: () => import('./views/zoho/zoho.module').then(m => m.ZohoModule),
  },
  {
    path: 'backoffice',
    canActivate: [BeeguardAuthGuard, BeeguardAdminGuard],
    loadChildren: () => import('./views-backoffice/backoffice.module').then(m => m.BackofficeModule),
  },
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'locations',
  },
  {
    path: '**',
    redirectTo: 'locations',
  },
  {
    outlet: 'modal',
    path: ':modalType',
    component: ModalsManagerComponent,
    canDeactivate: [ModalsService],
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      enableTracing: false, // Activate logging of all router event/config
      scrollPositionRestoration: 'disabled',
      // ^ Disable scroll on top of the page on page change.
      // This is needed (for now) to preserve scroll on modal opening
      // Not that we add a custom code in app.component.ts top scroll up
      // when route change on main outlet.
      useHash: false,
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
