import { Component, ChangeDetectionStrategy, Input } from '@angular/core';

import { isNil } from 'lodash-es';

import { map, switchMap } from 'rxjs';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { distinctUntilRealChanged, replay } from '@bg2app/tools/rxjs';

import { DeviceStatusGPRSStr, DeviceStatus, DeviceStatusGPRS, DRDevice } from 'app/models/devices/DRDevice';

@Component({
  selector: 'bg2-gprs-level',
  templateUrl: 'gprs-level.component.html',
  styleUrls: ['gprs-level.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GPRSLevelComponent {
  /** */
  @Input()
  public open_in_modal = true;

  // #region -> (device management)

  @Input()
  public set device(device: DRDevice) {
    this._device$$.next(device);
  }

  private _device$$: BehaviorSubject<DRDevice> = new BehaviorSubject(null);
  public device$$: Observable<DRDevice> = this._device$$.asObservable();

  /** */
  public device_imei$$: Observable<number> = this.device$$.pipe(
    switchMap(device => {
      if (isNil(device)) {
        return of(null);
      }

      return device?.imei$$;
    }),
    replay()
  );

  // #endregion

  // #region -> (status management)

  @Input()
  public set status(status: DeviceStatus) {
    this._status$$.next(status);
  }

  private _status$$: BehaviorSubject<DeviceStatus> = new BehaviorSubject<DeviceStatus>(null);
  public status$$: Observable<DeviceStatus> = this._status$$.asObservable().pipe(
    distinctUntilRealChanged<DeviceStatus>(),
    switchMap((status: DeviceStatus) => {
      if (isNil(status)) {
        return this.device$$.pipe(switchMap(device => (device ? device.status_gprs$$ : of(null))));
      } else {
        return of<DeviceStatus>(status);
      }
    })
  );

  // #endregion

  constructor() {}

  // #region -> (state management)

  private state$$: Observable<DeviceStatusGPRSStr> = this.status$$.pipe(
    map((status: DeviceStatusGPRS) => status?.state),
    distinctUntilRealChanged()
  );

  public is_gprs_is_excellent$$: Observable<boolean> = this.state$$.pipe(
    map(state => state === 'gprs_excellent'),
    distinctUntilRealChanged(),
    replay()
  );

  public is_gprs_is_good$$: Observable<boolean> = this.state$$.pipe(
    map(state => state === 'gprs_good'),
    distinctUntilRealChanged(),
    replay()
  );

  public is_gprs_is_low$$: Observable<boolean> = this.state$$.pipe(
    map(state => state === 'gprs_low'),
    distinctUntilRealChanged(),
    replay()
  );

  public is_gprs_is_very_low$$: Observable<boolean> = this.state$$.pipe(
    map(state => state === 'gprs_very_low'),
    distinctUntilRealChanged(),
    replay()
  );

  public is_gprs_is_ko$$: Observable<boolean> = this.state$$.pipe(
    map(state => state === 'gprs_ko'),
    distinctUntilRealChanged(),
    replay()
  );

  // #endregion

  // #region -> (coloration management)

  public getColor$$(com_state: DeviceStatusGPRSStr): Observable<string> {
    return this.status$$.pipe(
      map(status => status?.outdated || false),
      map(is_outdated => {
        if (is_outdated && com_state !== 'gprs_ko') {
          return 'black';
        }

        if (com_state === 'gprs_excellent') {
          return 'green';
        }

        if (com_state === 'gprs_good') {
          return 'green';
        }

        if (com_state === 'gprs_low') {
          return 'darkorange';
        }

        if (com_state === 'gprs_very_low') {
          return 'red';
        }

        if (com_state === 'gprs_ko') {
          return 'lightgray';
        }
      })
    );
  }

  // #endregion
}
