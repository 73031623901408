import { isEqual, isNil } from 'lodash-es';

import { Subject, of } from 'rxjs';
import { map, switchMap } from 'rxjs';

import { parseDate, strEnum } from 'app/misc/tools';

import { Event } from '..';
import { Beeguard2Api } from 'app/core';
import { EntityState as EntityStateInterface } from 'app/core/api-swagger/beeguard2/model/entityState';
import { replay } from '@bg2app/tools/rxjs';
import { Dictionary } from 'app/typings/core/interfaces';

/** */
const entity_type = strEnum(['warehouse', 'exploitation', 'location', 'apiary', 'hive']);
export type EntityType = keyof typeof entity_type;

export type EntityTypeI18n = `ENTITY.ALL.TYPE.${EntityType}`;

export interface DeviceConfig {
  imei: number;
  type: string;
  associated_to?: number;
  position?: string;
  since?: {
    date: string;
    event_id: number;
  };
}

export interface HiveDeviceConfig extends DeviceConfig {
  hive_id: number;
}

export interface EntityStateValue {
  [key: string]: any;
}

export class EntityState implements EntityStateInterface {
  dirty = false;
  is_last: boolean;
  is_local?: boolean;
  state: EntityStateValue;
  error?: {
    type: string;
    description: string;
    kwargs?: {
      [key: string]: any; // Error may have other argument
    };
  };
  entity_id?: number;
  event_id: number;
  event_date: Date;
  event_type?: string;
  event_rev?: number;
  previous_event_id: number | null;
  next_event_id: number | null;

  olderThan(other: EntityState): boolean {
    const other_date = parseDate(other.event_date);
    const other_event_id = other.event_id;
    return this.olderThanDate(other_date, other_event_id);
  }

  olderThanDate(date: Date, event_id: number): boolean {
    const mydate = parseDate(this.event_date);
    return mydate < date || (mydate === date && this.event_id <= event_id);
  }

  public static Deserialize(state_dict: EntityStateInterface): EntityState {
    const state = new EntityState();
    Object.assign(state, state_dict);
    state.event_date = parseDate(state_dict.event_date);
    return state;
  }
}

export class EntityUpdateError {
  public type: string; // error type
  public description: string; // error msg (i18n)
  public kwargs?: {
    [key: string]: any; // Error may have other argument
  };
  public event_type: string;
  public event_date: Date;

  protected _event_id: any;
  protected event_id_subject = new Subject<number>();
  public event_id$ = this.event_id_subject.asObservable();
  public event_id$$ = this.event_id$.pipe(replay());

  set event_id(eid: number) {
    this._event_id = eid;
    this.event_id_subject.next(this._event_id);
  }
  get event_id(): number {
    return this._event_id;
  }

  public event?: Event; // On may have the full event
  public event$ = of(true).pipe(
    switchMap(() => {
      this.event_loading = true;
      return this.bg2Api.getEventObj(this.event_id);
    }),
    map(event => {
      this.event = event;
      this.event_loading = false;
      return this.event;
    })
  );
  public event$$ = this.event$.pipe(replay());

  public event_loading = false;

  public static FromEntityState(state: EntityState, bg2Api: Beeguard2Api): EntityUpdateError {
    let error = null;
    if (!isNil(state.error)) {
      error = new EntityUpdateError(bg2Api);
      error.type = state.error.type;
      error.description = state.error.description;
      error.kwargs = state.error.kwargs;
      error.event_id = state.event_id;
      error.event_type = state.event_type;
      error.event_date = state.event_date;
      // error.event_date = parseDate(state.event_date);
    }
    return error;
  }

  public static FromRunError(run_error: any, bg2Api: Beeguard2Api): EntityUpdateError {
    let error = null;
    if (!isNil(run_error.source)) {
      error = new EntityUpdateError(bg2Api);
      error.type = run_error.source.error.type;
      error.description = run_error.source.error.description;
      error.kwargs = run_error.source.error.kwargs;
      error.event_id = run_error.source.event_id;
      error.event_type = run_error.source.event_type;
      error.event_date = parseDate(run_error.source.event_date);
    }
    return error;
  }

  constructor(protected bg2Api: Beeguard2Api) {}

  public isEqual(other: EntityUpdateError): boolean {
    if (isNil(other)) {
      return false;
    }
    return isEqual(this.toJSON(), other.toJSON());
  }

  public toJSON(): Dictionary<any> {
    const obj: Dictionary<any> = {};
    obj.type = this.type;
    obj.event_id = this.event_id;
    obj.description = this.description;
    obj.event_date = this.event_date;
    return obj;
  }

  protected unsubscribe(): void {}
}
