<div class="bg-dialog">
  <dialog-header>
    <span translate>VIEWS.DEVICES.REGISTER-DIALOG.Bulk set devices owner and exploitation</span>
  </dialog-header>

  <dialog-body>
    <div class="content">
      <div *ngIf="schema$$ | async; let supports_schema">
        <sf-form
        [schema]="supports_schema"
        (isValid)="setFormValid($event)"
        [model]="form_model$$ | async"
        (onErrorChange)="logErrors($event.value)" 
        (onChange)="onFormModelChanged($event)">
        </sf-form>
      </div>
    </div>
  </dialog-body>

  <div class="dialog-footer">
    <bg2-button
      [type]="'cancel'"
      [message]="'VIEWS.MODALS.FORM.Cancel' | translate"
      (btn_click)="close(false)"
    >
    </bg2-button>
    <bg2-button
      [type]="'action'"
      [icon]="'mdi-content-save'"
      [message]="'VIEWS.MODALS.FORM.Save' | translate"
      [disabled]="((form_valid$$ | async) === false) || $any(submit_progress_total$$ | async)"
      (btn_click)="submit()"
    >
    </bg2-button>
    <mat-progress-bar [value]="submit_progress_percent$$ | async" *ngIf="submit_progress_total$$ | async"></mat-progress-bar>
  </div>
</div>
