<div class="bg2-navigate-identified-hives-component">
  <ng-container *ngFor="let hive of hives$$ | async">
    <div class="hive" [class.selected-hive]="is_hive_currently_selected$$(hive) | async" (click)="select_hive(hive?.id)">
      <span *ngIf="hive?.is_ghost$$ | async" class="mdi mdi-auto-fix"></span>
      <bg2-picto-hive [hive]="hive" [size]="'30px'"></bg2-picto-hive>
      <span class="hive-name">{{ hive?.name$$ | async }}</span>
    </div>
  </ng-container>

  <div class="hive hive-add">
    <span class="mdi mdi-24px mdi-plus-circle-outline"></span>
    <span class="hive-name">add hive or nuc</span>
  </div>
</div>
