<bg2-modal [fullscreen]="fullscreen || false" #modal>
  <bg2-modal-header>
    <div class="header">
      <span class="title">
        <ng-template #defaultModalTitle>{{ 'DEVICE.ALL.CONFIG.Device configuration' | translate }}</ng-template>
        <ng-container *ngIf="device$$ | async; let device; else defaultModalTitle">
          {{ 'DEVICE.ALL.CONFIG.Device [dname] configuration' | translate:{ dname: device.name } }}
        </ng-container>
      </span>
    </div>
    <div class="action-buttons">
      <doc-helper page="Devices_configuration" iconSize="36px"></doc-helper>
      <span class="mdi mdi-close-box mdi-36px" (click)="close()"></span>
    </div>
  </bg2-modal-header>

  <bg2-modal-content>
    <div class="error" *ngIf="error">{{ error | translate }}</div>

    <ng-container *ngLet="config_form_context$$ | async; let config_form_context">
      <hex-loading [size]="'medium'" *ngIf="is_loading$$ | async"></hex-loading>

      <div *ngIf="(is_loading$$ | async) === false">
        <bg2-form-overlay
          [source_model]="config_form_context?.model"
          [source_schema]="config_form_context?.schema"
          (when_form_data_update)="update_model_for_device_config($event)"
        ></bg2-form-overlay>
      </div>
    </ng-container>

    <!-- <div *ngIf="configuration_schema$$ | async; let configuration_schema">
      <pre>{{configuration_schema | json}}</pre>
    </div> -->

    <!-- *** Device configuration *** -->
    <bg2-device-ack-next-conf [device]="device$$ | async"></bg2-device-ack-next-conf>
  </bg2-modal-content>

  <bg2-modal-footer>
    <bg2-button [type]="'cancel'" [message]="'VIEWS.MODALS.FORM.Cancel' | translate" (btn_click)="close()"></bg2-button>

    <bg2-button
      *ngIf="config_has_changed$$ | async"
      [type]="'action'"
      [icon]="'mdi-content-save'"
      [disabled]="is_loading$$ | async"
      [message]="'VIEWS.MODALS.FORM.Save' | translate"
      (btn_click)="saveNewConfig()"
    ></bg2-button>
  </bg2-modal-footer>
</bg2-modal>
