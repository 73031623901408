import { OnDestroy, Injectable } from '@angular/core';
import { MatPaginatorIntl } from '@angular/material/paginator';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs';

import { TranslateService } from '@ngx-translate/core';

import { marker as i18n } from '@biesbjerg/ngx-translate-extract-marker';

@Injectable()
export class PaginatorI18n extends MatPaginatorIntl implements OnDestroy {
  private onDestroy$: Subject<boolean> = new Subject();

  constructor(private readonly translate: TranslateService) {
    super();

    this.translate
      .stream([
        i18n<string>('SHARED_MODULE.I18N_MATERIAL_PAGINATOR_FACTORY.Number of items per page'),
        i18n<string>('SHARED_MODULE.I18N_MATERIAL_PAGINATOR_FACTORY.Next page'),
        i18n<string>('SHARED_MODULE.I18N_MATERIAL_PAGINATOR_FACTORY.Previous page'),
        i18n<string>('SHARED_MODULE.I18N_MATERIAL_PAGINATOR_FACTORY.First page'),
        i18n<string>('SHARED_MODULE.I18N_MATERIAL_PAGINATOR_FACTORY.Last page'),
      ])
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(translations => {
        this.itemsPerPageLabel = translations['SHARED_MODULE.I18N_MATERIAL_PAGINATOR_FACTORY.Number of items per page'];
        this.nextPageLabel = translations['SHARED_MODULE.I18N_MATERIAL_PAGINATOR_FACTORY.Next page'];
        this.previousPageLabel = translations['SHARED_MODULE.I18N_MATERIAL_PAGINATOR_FACTORY.Previous page'];
        this.firstPageLabel = translations['SHARED_MODULE.I18N_MATERIAL_PAGINATOR_FACTORY.First page'];
        this.lastPageLabel = translations['SHARED_MODULE.I18N_MATERIAL_PAGINATOR_FACTORY.Last page'];
        this.getRangeLabel = this.getRangeLabel.bind(this);

        this.changes.next();
      });
  }

  ngOnDestroy(): void {
    this.onDestroy$.next(true);
    this.onDestroy$.complete();
  }

  public getRangeLabel = (page: number, pageSize: number, length: number): string => {
    length = Math.max(length, 0);
    return this.translate.instant(
      'SHARED_MODULE.I18N_MATERIAL_PAGINATOR_FACTORY.Page [currentPage] of [totalPages] ([totalItems] results)',
      {
        currentPage: page + 1,
        totalPages: Math.ceil(length / pageSize) || 1,
        totalItems: length,
      }
    );
  };
}
