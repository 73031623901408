import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';

import { MiscModule } from 'app/misc/misc.module';
import { SharedModule } from 'app/shared-module/shared.module';
import { MiscWidgetsModule } from 'app/widgets/misc-widgets/misc-widgets.module';
import { MaterialSharedModule } from 'app/shared-module/material-shared.module';

// Dialogs parts.
import { DialogDivComponent } from './dialog-parts/dialog-div/dialog-div.component';
import { DialogBodyComponent } from './dialog-parts/dialog-body/dialog-body.component';
import { DialogHeaderComponent } from './dialog-parts/dialog-header/dialog-header.component';

// Modals parts.
import { Bg2ModalComponent } from './modals-parts/bg2-modal/bg2-modal.component';
import { Bg2ModalHeaderComponent } from './modals-parts/bg2-modal-header/bg2-modal-header.component';
import { Bg2ModalBodyComponent } from './modals-parts/bg2-modal-body/bg2-modal-body.component';
import { Bg2ModalFooterComponent } from './modals-parts/bg2-modal-footer/bg2-modal-footer.component';

// Dialogs windows.
import { ErrorDialogComponent } from './dialog-error/error.dialog';
import { CustomazibleDialogComponent } from './customizable/customazible.dialog';
import { LocationNearPosWarnComponent } from './location-near-pos-warn/location-near-pos-warn.component';
import { WidgetsReusableModule } from '../widgets-reusables/widgets-reusables.module';
import { ImageCropperDialogComponent } from './image-cropper-dialog/image-cropper-dialog.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

// import 'cropperjs';

@NgModule({
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  imports: [
    MiscModule,
    SharedModule,
    FormsModule,
    CommonModule,
    RouterModule,
    MiscWidgetsModule,
    ReactiveFormsModule,
    MaterialSharedModule,
    WidgetsReusableModule,
  ],
  declarations: [
    // Dialogs parts.
    DialogDivComponent,
    DialogBodyComponent,
    DialogHeaderComponent,

    // modals parts
    Bg2ModalComponent,
    Bg2ModalHeaderComponent,
    Bg2ModalBodyComponent,
    Bg2ModalFooterComponent,

    // Dialogs windows.
    ErrorDialogComponent,
    ImageCropperDialogComponent,
    CustomazibleDialogComponent,
    LocationNearPosWarnComponent,
  ],
  exports: [
    // Dialogs parts.
    DialogBodyComponent,
    DialogHeaderComponent,

    // modals parts
    Bg2ModalComponent,
    Bg2ModalHeaderComponent,
    Bg2ModalBodyComponent,
    Bg2ModalFooterComponent,
  ],
})
export class DialogsModalsModule {}
