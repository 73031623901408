import { parseISO } from 'date-fns/esm';
import { isNil, isNumber } from 'lodash-es';

import { parseUTCTimestamp } from '../parse-utc-timestamp/parse-utc-timestamp';

export function parseDate(val: Date | string | number | null | undefined): Date {
  if (isNil(val)) {
    return null;
  }

  if (val instanceof Date && typeof val.getMonth === 'function') {
    return val;
  } else if (isNumber(val)) {
    return parseUTCTimestamp(val);
  } else {
    return parseISO(val as unknown as string);
  }
}

export function parseDateForXls(val: Date | string | number): Date {
  if (isNil(val)) {
    return null;
  }

  const date = parseDate(val);
  const ONE_MINUTE = 60 * 1000;
  const ticks = date.getTime() - date.getTimezoneOffset() * ONE_MINUTE;
  return new Date(ticks);
}
