import { IZohoBooksContact } from '../interfaces';
import { ZohoBaseModel } from '../../../../abstract';
import { BehaviorSubject, map } from 'rxjs';
import { isEmpty, isNil } from 'lodash-es';
import { replay } from '@bg2app/tools/rxjs';

/** */
export class ZohoBooksContact extends ZohoBaseModel<IZohoBooksContact> {
  // #region -> (class basics)

  constructor() {
    super();
  }

  // #endregion

  // #region -> (base identification)

  /** */
  protected set contact_id(contact_id: string) {
    super.id = contact_id;
  }

  // #endregion

  // #region -> (name)

  // #region -> (contact name)

  /** */
  protected _contact_name$$ = new BehaviorSubject<string>(null);

  /** */
  public contact_name$$ = this._contact_name$$.asObservable();

  /** */
  public set contact_name(contact_name: string) {
    this._contact_name$$.next(contact_name);
  }

  /** */
  public get contact_name(): string {
    return this._contact_name$$.getValue();
  }

  // #endregion

  // #endregion

  // #region -> (links)

  /**
   * URL to contact in the web browser
   */
  public url$$ = this.id$$.pipe(
    map(id => {
      if (isNil(id) || isEmpty(id)) {
        return null;
      }

      return `https://books.zoho.eu/app/20067795982#/contacts/${id}`;
    }),
    replay()
  );

  /** */
  public url_sales$$ = this.url$$.pipe(
    map(url => {
      if (isNil(url) || isEmpty(url)) {
        return null;
      }

      return `${url}/sales`;
    }),
    replay()
  );

  // #endregion
}
