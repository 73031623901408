import { IOneOf, IProperties, ISchema } from 'ngx-schema-form';

import { of } from 'rxjs';

import { marker as i18n } from '@biesbjerg/ngx-translate-extract-marker';

import { Beeguard2Api } from 'app/core';

import { strEnum } from 'app/misc/tools';

import { Event, IEventGlobalSchema } from '.';
import { APIARY_VISIT_STATE, apiary_visit_state } from 'app/routable-modals/visit-modal/_visit-manager';
import { Dictionary } from 'app/typings/core/interfaces';

// #region -> (event schema management)

enum VISIT_ASPECT_COMMON {
  queen = 'queen',
  super = 'super',
  status = 'status',
  brood_box = 'brood_box',
}

enum VISIT_ASPECT_OVERALL {
  pollen = 'pollen',
  comment = 'comment',
  feeding = 'feeding',
  propolis = 'propolis',
  sanitary = 'sanitary',
  royal_jelly = 'royal_jelly',
  hardware_stock = 'hardware_stock',
}

enum VISIT_ASPECT_HIVE_IDENTIFIED {
  dynamism = 'dynamism',
  feeding = 'feeding',
  global_state = 'global_state',
  pollen = 'pollen',
  propolis = 'propolis',
  royal_jelly = 'royal_jelly',
  hive_id = 'hive_id',
  hive_usage = 'hive_usage',
  htype = 'htype',
  mortality = 'mortality',
  next_actions = 'next_actions',
  comment = 'comment',
}

enum VISIT_ASPECT_HIVE_NOT_IDENTIFIED {
  visit_id = 'visit_id',
  dynamism = 'dynamism',
}

// /** [apiary_visit /                       /                          ] */ 'hardware_stock',
// /** [apiary_visit / hive_identified_visit / hive_not_identified_visit] */ 'status',
// /** [             / hive_identified_visit /                          ] */ 'dynamism',
// /** [apiary_visit / hive_identified_visit / hive_not_identified_visit] */ 'brood_box',
// /** [apiary_visit / hive_identified_visit / hive_not_identified_visit] */ 'queen',
// /** [apiary_visit / hive_identified_visit / hive_not_identified_visit] */ 'super',
// /** [apiary_visit / hive_identified_visit /                          ] */ 'feeding',
// /** [apiary_visit /                       /                          ] */ 'sanitary',
// /** [             / hive_identified_visit /                          ] */ 'global_state',
// /** [apiary_visit / hive_identified_visit /                          ] */ 'pollen',
// /** [apiary_visit / hive_identified_visit /                          ] */ 'propolis',
// /** [apiary_visit / hive_identified_visit /                          ] */ 'royal_jelly',
// /** [             / hive_identified_visit /                          ] */ 'hive_id',
// /** [             / hive_identified_visit /                          ] */ 'hive_usage',
// /** [             / hive_identified_visit /                          ] */ 'htype',
// /** [             / hive_identified_visit /                          ] */ 'mortality',
// /** [             / hive_identified_visit /                          ] */ 'next_actions',
// /** [             /                       / hive_not_identified_visit] */ 'visit_id',
// /** [apiary_visit / hive_identified_visit /                          ] */ 'comment',

/** */
export type VISIT_ASPECT = VISIT_ASPECT_COMMON | VISIT_ASPECT_OVERALL | VISIT_ASPECT_HIVE_IDENTIFIED | VISIT_ASPECT_HIVE_NOT_IDENTIFIED;

/** */
export interface IVisitApiaryAspectSchema extends ISchema {
  /** */
  properties: {
    /** */
    [key in VISIT_ASPECT_COMMON | VISIT_ASPECT_OVERALL]?: ISchema;
  };
}

/** */
export interface IVisitHiveIdentifiedAspectSchema extends ISchema {
  /** */
  items: {
    /** */
    type: 'object';

    /** */
    properties: {
      /** */
      [key in VISIT_ASPECT_COMMON | VISIT_ASPECT_HIVE_IDENTIFIED]?: ISchema;
    };
  };
}

/** */
export interface IVisitHiveNotIdentifiedAspectSchema extends ISchema {
  /** */
  items: {
    /** */
    type: 'object';

    /** */
    properties: {
      /** */
      [key in VISIT_ASPECT_COMMON | VISIT_ASPECT_HIVE_NOT_IDENTIFIED]?: ISchema;
    };
  };
}

/** */
export interface VisitConfigSchema extends ISchema {
  /** */
  properties: {
    /**
     * Configuration of the overall visit.
     */
    apiary_visit: ISchema;

    /**
     * Configuration of the hive by hive visit.
     */
    hive_by_hive: ISchema;

    /** */
    aspects_to_visit: ISchema & {
      /** */
      properties: ISchema['properties'] & {
        /** */
        overall_visit: ISchema & {
          items: {
            oneOf: (IOneOf & { enum: (VISIT_ASPECT_COMMON | VISIT_ASPECT_OVERALL)[] })[];
          };
        };

        /** */
        hive_by_hive_identified_visit: ISchema & {
          items: {
            oneOf: (IOneOf & { enum: (VISIT_ASPECT_COMMON | VISIT_ASPECT_HIVE_IDENTIFIED)[] })[];
          };
        };

        /** */
        hive_by_hive_not_identified_visit: ISchema & {
          items: {
            oneOf: (IOneOf & { enum: (VISIT_ASPECT_COMMON | VISIT_ASPECT_HIVE_NOT_IDENTIFIED)[] })[];
          };
        };
      };
    };
  };
}

/** */
export interface VisitDataSchema extends ISchema {
  /** */
  properties: IProperties & { [key in APIARY_VISIT_STATE]?: ISchema } & {
    /** */
    overall_visit: IVisitApiaryAspectSchema;

    /** */
    hive_by_hive_identified_visit: IVisitHiveIdentifiedAspectSchema;

    /** */
    hive_by_hive_not_identified_visit: IVisitHiveNotIdentifiedAspectSchema;
  };
}

/** */
interface DraftVisitEventSchema extends IEventGlobalSchema {
  /** */
  data_schema: ISchema & {
    /** */
    properties: ISchema['properties'] & {
      /** */
      visit_config: VisitConfigSchema;

      /** */
      visit_data: VisitDataSchema;
    };
  };
}

export interface VisitEventSchema extends ISchema {
  properties: ISchema['properties'] & {
    data: ISchema & {
      properties: ISchema['properties'] & {
        /** */
        visit_config: VisitConfigSchema;

        /** */
        visit_data: VisitDataSchema;
      };
    };
  };
}

// #endregion

/** */
export interface VisitHiveNotIdentifiedData {
  /** */
  [key: string]: any;

  /** */
  visit_id: number;
}

/** */
export interface VisitEventData {
  /** */
  config: {
    /** */
    author: number;
  };

  /** */
  visit_config: {
    /** */
    aspects_to_visit: {
      [key in APIARY_VISIT_STATE]: string[];
    };

    /** */
    hive_by_hive: {
      /** */
      evaluated_hive_type: ('hive' | 'nuc')[];

      /** */
      visit_type: 'not_identified' | 'identified';
    };
  };

  /** */
  visit_data: {
    /** */
    overall_visit: Dictionary<any>;

    /** */
    hive_by_hive_identified_visit: { [key: string]: any; hive_id: number }[];

    /** */
    hive_by_hive_not_identified_visit: VisitHiveNotIdentifiedData[];
  };
}

/** */
export class VisitEvent extends Event<any, VisitEventData> {
  // #region -> (class basics)

  /** */
  public static readonly draft_schema: DraftVisitEventSchema = {
    beta: false,
    type: 'evaluation',
    doc: 'Evaluation visit on an apiary',
    event_description: "EVENT.VISIT.Evaluation visit on apiary '[entities->apiary->name]'",
    needed_entities: {
      warehouse: {
        type: 'warehouse',
        dynamic: {
          from: 'apiary',
          path: 'state.warehouse_id',
        },
        readonly: true,
        event_description: "EVENT.VISIT.Evaluation visit on apiary '[entities->apiary->name]'",
        dependencies: ['apiary', 'hives'],
      },
      location: {
        type: 'location',
        dynamic: {
          from: 'apiary',
          path: 'state.location_id',
        },
        readonly: true,
        event_description: "EVENT.VISIT.Evaluation visit on apiary '[entities->apiary->name]'",
      },
      apiary: {
        type: 'apiary',
        event_description: 'EVENT.VISIT.Evaluation visit',
        dependencies: ['hives'],
      },
      hives: {
        type: 'hive',
        label: 'EVENT.VISIT.Evaluated hives',
        event_description: 'EVENT.VISIT.Evaluation of the hive',
        multiple: true,
        nullable: true,
        with_in: {
          from: 'apiary',
          path: 'state.hive_ids',
        },
      },
    },
    date_schema: {
      title: 'EVENT.VISIT.Evaluation date',
      options: {
        pickerType: 'calendar',
      },
    },
    data_schema: {
      type: 'object',
      properties: {
        visit_config: {
          type: 'object',
          properties: {
            apiary_visit: {
              type: 'object',
              widget: 'hidden',
              properties: {
                is_apiary_visit: {
                  type: 'boolean',
                  widget: 'hidden',
                  default: false,
                },
              },
            },

            hive_by_hive: {
              type: 'object',
              options: {
                event_date_path: '/metadata/date',
              },
              properties: {
                metadata: {
                  type: 'object',
                  widget: 'hidden',
                },
                visit_type: {
                  type: 'string',
                  widget: 'radio',
                  default: 'not_identified',
                  oneOf: [
                    {
                      enum: ['not_identified'],
                      label: 'Not identified hives',
                      options: {
                        img: 'img/eval/hive_fast.png',
                      },
                    },
                    {
                      enum: ['identified'],
                      label: 'Identified hives',
                      options: {
                        img: 'img/eval/hive_step.png',
                      },
                    },
                  ],
                },
                evaluated_hive_type: {
                  type: 'array',
                  widget: 'checklist',
                  default: 'hive',
                  items: {
                    type: 'string',
                    oneOf: [
                      {
                        enum: ['hive'],
                        label: 'ENTITY.ALL.TYPE.hive',
                        options: {
                          img: 'img/hive.png',
                        },
                      },
                      {
                        enum: ['nuc'],
                        label: 'ENTITY.ALL.TYPE.nuc',
                        options: {
                          img: 'img/nuc_hive.png',
                        },
                      },
                    ],
                  },
                },
                hives_to_visit: {
                  type: 'array',
                  widget: 'bg2entity',
                  label: 'some label',
                  etype: 'hive',
                  minItems: 1,
                  items: {
                    type: 'number',
                    widget: 'number',
                  },
                  options: {
                    event_date_path: '/metadata/date',
                    multiple: true,
                    nullable: false,
                    _default: '_all',
                    with_in: {
                      from: '/metadata/apply_to/apiary',
                      path: 'state.hive_ids',
                    },
                  },
                  isRequired: true,
                  visibleIf: {
                    visit_type: ['identified'],
                  },
                },
              },
            },

            aspects_to_visit: {
              type: 'object',
              properties: {
                overall_visit: {
                  type: 'array',
                  widget: 'checklist',
                  options: {
                    display: 'button',
                  },
                  minItems: 1,
                  items: {
                    type: 'string',
                    oneOf: [
                      {
                        enum: [VISIT_ASPECT_COMMON.status],
                        label: 'status',
                        image: '',
                        image_styles: {
                          'max-width': '40px',
                        },
                      },
                      {
                        enum: [VISIT_ASPECT_COMMON.brood_box],
                        label: 'brood_box',
                        image: '',
                        image_styles: {
                          'max-width': '40px',
                        },
                      },
                      {
                        enum: [VISIT_ASPECT_COMMON.queen],
                        label: 'queen',
                        image: '',
                        image_styles: {
                          'max-width': '40px',
                        },
                      },
                      {
                        enum: [VISIT_ASPECT_COMMON.super],
                        label: 'super',
                        image: '',
                        image_styles: {
                          'max-width': '40px',
                        },
                      },
                      {
                        enum: [VISIT_ASPECT_OVERALL.comment],
                        label: 'comment',
                        image: '',
                        image_styles: {
                          'max-width': '40px',
                        },
                      },
                      {
                        enum: [VISIT_ASPECT_OVERALL.feeding],
                        label: 'feeding',
                        image: '',
                        image_styles: {
                          'max-width': '40px',
                        },
                      },
                      {
                        enum: [VISIT_ASPECT_OVERALL.hardware_stock],
                        label: 'hardware_stock',
                        image: '',
                        image_styles: {
                          'max-width': '40px',
                        },
                      },
                      {
                        enum: [VISIT_ASPECT_OVERALL.pollen],
                        label: 'pollen',
                        image: '',
                        image_styles: {
                          'max-width': '40px',
                        },
                      },
                      {
                        enum: [VISIT_ASPECT_OVERALL.propolis],
                        label: 'propolis',
                        image: '',
                        image_styles: {
                          'max-width': '40px',
                        },
                      },
                      {
                        enum: [VISIT_ASPECT_OVERALL.royal_jelly],
                        label: 'royal_jelly',
                        image: '',
                        image_styles: {
                          'max-width': '40px',
                        },
                      },
                      {
                        enum: [VISIT_ASPECT_OVERALL.sanitary],
                        label: 'sanitary',
                        image: '',
                        image_styles: {
                          'max-width': '40px',
                        },
                      },
                    ],
                  },
                  visibleIf: {
                    '/metadata/visit_type': 'overall',
                  },
                },

                hive_by_hive_identified_visit: {
                  type: 'array',
                  widget: 'checklist',
                  items: {
                    type: 'string',
                    oneOf: [
                      {
                        enum: [VISIT_ASPECT_COMMON.status],
                        label: 'status',
                        image: '',
                        image_styles: {
                          'max-width': '40px',
                        },
                      },
                      {
                        enum: [VISIT_ASPECT_COMMON.brood_box],
                        label: 'brood_box',
                        image: '',
                        image_styles: {
                          'max-width': '40px',
                        },
                      },
                      {
                        enum: [VISIT_ASPECT_COMMON.queen],
                        label: 'queen',
                        image: '',
                        image_styles: {
                          'max-width': '40px',
                        },
                      },
                      {
                        enum: [VISIT_ASPECT_COMMON.super],
                        label: 'super',
                        image: '',
                        image_styles: {
                          'max-width': '40px',
                        },
                      },
                      {
                        enum: [VISIT_ASPECT_HIVE_IDENTIFIED.comment],
                        label: 'comment',
                        image: '',
                        image_styles: {
                          'max-width': '40px',
                        },
                      },
                      {
                        enum: [VISIT_ASPECT_HIVE_IDENTIFIED.dynamism],
                        label: 'dynamism',
                        image: '',
                        image_styles: {
                          'max-width': '40px',
                        },
                      },
                      {
                        enum: [VISIT_ASPECT_HIVE_IDENTIFIED.feeding],
                        label: 'feeding',
                        image: '',
                        image_styles: {
                          'max-width': '40px',
                        },
                      },
                      {
                        enum: [VISIT_ASPECT_HIVE_IDENTIFIED.global_state],
                        label: 'global_state',
                        image: '',
                        image_styles: {
                          'max-width': '40px',
                        },
                      },
                      // {
                      //   enum: [VISIT_ASPECT_HIVE_IDENTIFIED.hive_id],
                      //   label: 'hive_id',
                      //   image: '',
                      //   image_styles: {
                      //     'max-width': '40px',
                      //   },
                      // },
                      {
                        enum: [VISIT_ASPECT_HIVE_IDENTIFIED.hive_usage],
                        label: 'hive_usage',
                        image: '',
                        image_styles: {
                          'max-width': '40px',
                        },
                      },
                      {
                        enum: [VISIT_ASPECT_HIVE_IDENTIFIED.htype],
                        label: 'htype',
                        image: '',
                        image_styles: {
                          'max-width': '40px',
                        },
                      },
                      {
                        enum: [VISIT_ASPECT_HIVE_IDENTIFIED.mortality],
                        label: 'mortality',
                        image: '',
                        image_styles: {
                          'max-width': '40px',
                        },
                      },
                      {
                        enum: [VISIT_ASPECT_HIVE_IDENTIFIED.next_actions],
                        label: 'next_actions',
                        image: '',
                        image_styles: {
                          'max-width': '40px',
                        },
                      },
                      {
                        enum: [VISIT_ASPECT_HIVE_IDENTIFIED.pollen],
                        label: 'pollen',
                        image: '',
                        image_styles: {
                          'max-width': '40px',
                        },
                      },
                      {
                        enum: [VISIT_ASPECT_HIVE_IDENTIFIED.propolis],
                        label: 'propolis',
                        image: '',
                        image_styles: {
                          'max-width': '40px',
                        },
                      },
                      {
                        enum: [VISIT_ASPECT_HIVE_IDENTIFIED.royal_jelly],
                        label: 'royal_jelly',
                        image: '',
                        image_styles: {
                          'max-width': '40px',
                        },
                      },
                    ],
                  },
                  visibleIf: {
                    '/metadata/visit_type': 'identified',
                  },
                },

                hive_by_hive_not_identified_visit: {
                  type: 'array',
                  widget: 'checklist',
                  default: ['visit_id'],
                  items: {
                    type: 'string',
                    oneOf: [
                      {
                        enum: [VISIT_ASPECT_COMMON.status],
                        label: 'status',
                        image: '',
                        image_styles: {
                          'max-width': '40px',
                        },
                      },
                      {
                        enum: [VISIT_ASPECT_COMMON.brood_box],
                        label: 'brood_box',
                        image: '',
                        image_styles: {
                          'max-width': '40px',
                        },
                      },
                      {
                        enum: [VISIT_ASPECT_COMMON.queen],
                        label: 'queen',
                        image: '',
                        image_styles: {
                          'max-width': '40px',
                        },
                      },
                      {
                        enum: [VISIT_ASPECT_COMMON.super],
                        label: 'super',
                        image: '',
                        image_styles: {
                          'max-width': '40px',
                        },
                      },
                      // {
                      //   enum: [VISIT_ASPECT_HIVE_NOT_IDENTIFIED.visit_id],
                      //   label: 'visit_id',
                      //   image: '',
                      //   image_styles: {
                      //     'max-width': '40px',
                      //   },
                      // },
                    ],
                  },
                  visibleIf: {
                    '/metadata/visit_type': 'not_identified',
                  },
                },
              },
            },
          },
        },

        visit_data: {
          type: 'object',
          properties: {
            overall_visit: {
              type: 'object',
              properties: {
                hardware_stock: {
                  type: 'object',
                  title: 'EVENT.VISIT.Hardware stock',
                  options: {
                    title_style: 'large',
                  },
                  properties: {
                    nb_hives: {
                      label: 'EVENT.VISIT.Number of hives',
                      type: 'number',
                      minimum: 0,
                      step: 1,
                      _default: {
                        value: 'from_entity',
                        entity_path: 'apply_to/apiary',
                        path: 'state.nb_hives',
                        date_path: '/metadata/date',
                      },
                      options: {
                        img: 'img/hive.png',
                      },
                    },
                    nb_nuc: {
                      label: 'EVENT.VISIT.Number of nuc hives',
                      type: 'number',
                      minimum: 0,
                      step: 1,
                      _default: {
                        value: 'from_entity',
                        entity_path: 'apply_to/apiary',
                        path: 'state.nb_nuc',
                        date_path: '/metadata/date',
                      },
                      options: {
                        img: 'img/nuc_hive.png',
                      },
                    },
                    nb_pallet: {
                      label: 'EVENT.VISIT.Number of pallet on the apiary',
                      type: 'number',
                      minimum: 0,
                      step: 1,
                      default: 0,
                    },
                    nb_supers_stock: {
                      label: 'EVENT.VISIT.Number of supers in stock on the apiary',
                      type: 'number',
                      minimum: 0,
                      step: 1,
                      default: 0,
                      options: {
                        img: 'img/super/super_stock.svg',
                      },
                    },
                    nb_hives_stock: {
                      label: 'EVENT.VISIT.Number of empty hives in stock on the apiary',
                      type: 'number',
                      minimum: 0,
                      step: 1,
                      default: 0,
                    },
                    nb_nuc_stock: {
                      label: 'EVENT.VISIT.Number of empty nuc hives in stock on the apiary',
                      type: 'number',
                      minimum: 0,
                      step: 1,
                      default: 0,
                    },
                    comment: {
                      title: 'ALL.COMMON.Comment',
                      options: {
                        optional: true,
                      },
                      type: 'string',
                      widget: 'textarea',
                    },
                  },
                },

                status: {
                  type: 'object',
                  title: 'EVENT.VISIT.Hives status',
                  options: {
                    title_style: 'large',
                    // variants: 'flexbox',
                    // nb_cols: 1,
                    // nb_cols_m: 1,
                    // nb_cols_s: 1,
                    // color: '#F6C674',
                  },
                  properties: {
                    OK: {
                      type: 'number',
                      widget: 'number-light',
                      label: 'EVENT.VISIT.STATUS.OK',
                      options: {
                        img: 'img/hive_status/ok.png',
                      },
                      step: 1,
                      minimum: 0,
                    },
                    RHS: {
                      type: 'number',
                      widget: 'number-light',
                      label: 'EVENT.VISIT.STATUS.Dead queen',
                      options: {
                        img: 'img/hive_status/rhs.png',
                      },
                      step: 1,
                      minimum: 0,
                    },
                    E: {
                      type: 'number',
                      widget: 'number-light',
                      label: 'EVENT.VISIT.STATUS.Swarming',
                      options: {
                        img: 'img/hive_status/e.png',
                      },
                      step: 1,
                      minimum: 0,
                    },
                    NV: {
                      type: 'number',
                      widget: 'number-light',
                      label: 'EVENT.VISIT.STATUS.NV',
                      options: {
                        img: 'img/hive_status/nv.png',
                      },
                      step: 1,
                      minimum: 0,
                    },
                    B: {
                      type: 'number',
                      widget: 'number-light',
                      label: 'EVENT.VISIT.STATUS.Drone laying queen',
                      options: {
                        img: 'img/hive_status/b.png',
                      },
                      step: 1,
                      minimum: 0,
                    },
                    S: {
                      type: 'number',
                      widget: 'number-light',
                      label: 'EVENT.VISIT.STATUS.Sanitary suspicion',
                      options: {
                        img: 'img/hive_status/s.png',
                      },
                      step: 1,
                      minimum: 0,
                    },
                    D: {
                      type: 'number',
                      widget: 'number-light',
                      label: 'EVENT.VISIT.STATUS.Dead',
                      options: {
                        img: 'img/hive_status/d.png',
                      },
                      step: 1,
                      minimum: 0,
                    },
                    T: {
                      type: 'number',
                      widget: 'number-light',
                      label: 'EVENT.VISIT.STATUS.Stolen',
                      options: {
                        img: 'img/hive_status/t.png',
                      },
                      step: 1,
                      minimum: 0,
                    },
                  },
                },

                brood_box: {
                  type: 'object',
                  options: {
                    title_style: 'large',
                    // variants: 'flexbox',
                    // nb_cols: 3,
                    // nb_cols_s: 1,
                    // color: '#55AA55',
                  },
                  title: 'EVENT.VISIT.Brood frames',
                  properties: {
                    C0: {
                      type: 'number',
                      widget: 'number-light',
                      label: 'EVENT.VISIT.BF.No BF',
                      options: {
                        img: 'img/hive_frame/c0.png',
                      },
                      step: 1,
                      minimum: 0,
                    },
                    C2: {
                      type: 'number',
                      widget: 'number-light',
                      label: 'EVENT.VISIT.BF.2 BF',
                      options: {
                        img: 'img/hive_frame/c2.png',
                      },
                      step: 1,
                      minimum: 0,
                    },
                    C3: {
                      type: 'number',
                      widget: 'number-light',
                      label: 'EVENT.VISIT.BF.3 BF',
                      options: {
                        img: 'img/hive_frame/c3.png',
                      },
                      step: 1,
                      minimum: 0,
                    },
                    C4: {
                      type: 'number',
                      widget: 'number-light',
                      label: 'EVENT.VISIT.BF.4 BF',
                      options: {
                        img: 'img/hive_frame/c4.png',
                      },
                      step: 1,
                      minimum: 0,
                    },
                    C5: {
                      type: 'number',
                      widget: 'number-light',
                      label: 'EVENT.VISIT.BF.5 BF',
                      options: {
                        img: 'img/hive_frame/c5.png',
                      },
                      step: 1,
                      minimum: 0,
                    },
                    C6: {
                      type: 'number',
                      widget: 'number-light',
                      label: 'EVENT.VISIT.BF.6 BF',
                      options: {
                        img: 'img/hive_frame/c6.png',
                      },
                      step: 1,
                      minimum: 0,
                    },
                    'C7+': {
                      type: 'number',
                      widget: 'number-light',
                      label: 'EVENT.VISIT.BF.+ 7 BF',
                      options: {
                        img: 'img/hive_frame/c7+.png',
                      },
                      step: 1,
                      minimum: 0,
                    },
                  },
                },

                queen: {
                  type: 'object',
                  title: 'EVENT.VISIT.Queen color',
                  options: {
                    // variants: 'flexbox',
                    // nb_cols: 5,
                    // nb_cols_m: 2,
                    // color: '#cdcdcd',
                    title_style: 'large',
                  },
                  properties: {
                    b: {
                      type: 'number',
                      widget: 'number-light',
                      label: 'EVENT.VISIT.QUEEN.blue',
                      options: {
                        img: 'img/queen/b.png',
                      },
                      step: 1,
                      minimum: 0,
                    },
                    w: {
                      type: 'number',
                      widget: 'number-light',
                      label: 'EVENT.VISIT.QUEEN.white',
                      options: {
                        img: 'img/queen/w.png',
                      },
                      step: 1,
                      minimum: 0,
                    },
                    y: {
                      type: 'number',
                      widget: 'number-light',
                      label: 'EVENT.VISIT.QUEEN.yellow',
                      options: {
                        img: 'img/queen/y.png',
                      },
                      step: 1,
                      minimum: 0,
                    },
                    r: {
                      type: 'number',
                      widget: 'number-light',
                      label: 'EVENT.VISIT.QUEEN.red',
                      options: {
                        img: 'img/queen/r.png',
                      },
                      step: 1,
                      minimum: 0,
                    },
                    g: {
                      type: 'number',
                      widget: 'number-light',
                      label: 'EVENT.VISIT.QUEEN.green',
                      options: {
                        img: 'img/queen/g.png',
                      },
                      step: 1,
                      minimum: 0,
                    },
                    unknow: {
                      type: 'number',
                      widget: 'number-light',
                      label: 'EVENT.VISIT.QUEEN.Unknow',
                      options: {
                        img: 'img/queen/unknow.png',
                      },
                      step: 1,
                      minimum: 0,
                    },
                    not_found: {
                      type: 'number',
                      widget: 'number-light',
                      label: 'EVENT.VISIT.QUEEN.Not found',
                      options: {
                        img: 'img/queen/not_found.png',
                      },
                      step: 1,
                      minimum: 0,
                    },
                  },
                },

                super: {
                  type: 'object',
                  title: 'EVENT.VISIT.Supers repartition',
                  options: {
                    title_style: 'large',
                    // variants: 'flexbox',
                    // nb_cols: 3,
                    // nb_cols_s: 1,
                    // color: '#8BB1D0',
                  },
                  properties: {
                    '0': {
                      type: 'number',
                      widget: 'number-light',
                      label: 'EVENT.VISIT.SUPERS.No supers',
                      options: {
                        img: 'img/super/super_0.svg',
                      },
                      step: 1,
                      minimum: 0,
                    },
                    '1': {
                      type: 'number',
                      widget: 'number-light',
                      label: 'EVENT.VISIT.SUPERS.1 super',
                      options: {
                        img: 'img/super/super_1.svg',
                      },
                      step: 1,
                      minimum: 0,
                    },
                    '2': {
                      type: 'number',
                      widget: 'number-light',
                      label: 'EVENT.VISIT.SUPERS.2 supers',
                      options: {
                        img: 'img/super/super_2.svg',
                      },
                      step: 1,
                      minimum: 0,
                    },
                    '3': {
                      type: 'number',
                      widget: 'number-light',
                      label: 'EVENT.VISIT.SUPERS.3 supers',
                      options: {
                        img: 'img/super/super_3.svg',
                      },
                      step: 1,
                      minimum: 0,
                    },
                    '4': {
                      type: 'number',
                      widget: 'number-light',
                      label: 'EVENT.VISIT.SUPERS.4 supers',
                      options: {
                        img: 'img/super/super_4.svg',
                      },
                      step: 1,
                      minimum: 0,
                    },
                    '4+': {
                      type: 'number',
                      widget: 'number-light',
                      label: 'EVENT.VISIT.SUPERS.4+ supers',
                      options: {
                        img: 'img/super/super_4+.svg',
                      },
                      step: 1,
                      minimum: 0,
                    },
                  },
                },

                feeding: {
                  type: 'object',
                  title: 'EVENT.VISIT.Feeding',
                  options: {
                    title_style: 'large',
                  },
                  // order: ['feeding_type', 'feeding_type_other', 'quantity_kg', 'quantity_L', 'nb_feeded_hive', 'nb_feeded_nuc', 'comment'],
                  properties: {
                    feeding_type: {
                      type: 'string',
                      widget: 'radio',
                      label: 'EVENT.FEEDING.Type of feeding',
                      default: 'FEEDING_TYPE.None',
                      oneOf: [
                        {
                          enum: ['FEEDING_TYPE.None'],
                          label: 'FEEDING_TYPE.None',
                        },
                        {
                          enum: ['FEEDING_TYPE.Candy'],
                          label: 'FEEDING_TYPE.Candy',
                        },
                        {
                          enum: ['FEEDING_TYPE.Sirup'],
                          label: 'FEEDING_TYPE.Sirup',
                        },
                        {
                          enum: ['FEEDING_TYPE.Honey'],
                          label: 'FEEDING_TYPE.Honey',
                        },
                        {
                          enum: ['FEEDING_TYPE.Pollen'],
                          label: 'FEEDING_TYPE.Pollen',
                        },
                        {
                          enum: ['FEEDING_TYPE.OTHER'],
                          label: 'FEEDING_TYPE.OTHER',
                        },
                      ],
                    },

                    feeding_type_other: {
                      type: 'string',
                      label: 'EVENT.FEEDING.Ohter',
                      visibleIf: {
                        feeding_type: ['FEEDING_TYPE.OTHER'],
                      },
                    },

                    quantity_kg: {
                      label: 'EVENT.FEEDING.Quantity per hive (in kg)',
                      type: 'number',
                      minimum: 0,
                      step: 0.1,
                      visibleIf: {
                        feeding_type: ['FEEDING_TYPE.Candy', 'FEEDING_TYPE.Honey', 'FEEDING_TYPE.Pollen', 'FEEDING_TYPE.OTHER'],
                      },
                    },

                    quantity_L: {
                      label: 'EVENT.FEEDING.Quantity per hive (in L)',
                      type: 'number',
                      minimum: 0,
                      step: 0.1,
                      visibleIf: {
                        feeding_type: ['FEEDING_TYPE.Sirup'],
                      },
                    },

                    nb_feeded_hive: {
                      label: 'EVENT.VISIT.Number of feeded hives',
                      type: 'number',
                      widget: 'number-light',
                      minimum: 0,
                      step: 1,
                      _default: {
                        value: 'from_entity',
                        entity_path: 'apply_to/apiary',
                        path: 'state.nb_hives',
                      },
                      visibleIf: {
                        feeding_type: "$EXP$ target.value != 'FEEDING_TYPE.None'",
                      },
                      options: {
                        img: 'img/views-windowed/modals/evaluation/hive_feeding.png',
                      },
                    },

                    nb_feeded_nuc: {
                      label: 'EVENT.VISIT.Number of feeded nuc',
                      type: 'number',
                      widget: 'number-light',
                      minimum: 0,
                      step: 1,
                      _default: {
                        value: 'from_entity',
                        entity_path: 'apply_to/apiary',
                        path: 'state.nb_nuc',
                      },
                      visibleIf: {
                        feeding_type: "$EXP$ target.value != 'FEEDING_TYPE.None'",
                      },
                      options: {
                        img: 'img/views-windowed/modals/evaluation/nuc_feeding.png',
                      },
                    },

                    comment: {
                      type: 'string',
                      label: 'ALL.COMMON.Comment',
                      widget: 'textarea',
                      options: {
                        optional: true,
                      },
                      visibleIf: {
                        feeding_type: ['$ANY$'],
                      },
                    },
                  },
                },

                sanitary: {
                  type: 'object',
                  title: 'EVENT.VISIT.Sanitary',
                  options: {
                    title_style: 'large',
                  },
                  properties: {
                    tests: {
                      type: 'array',
                      title: 'EVENT.VISIT.Sanitary tests',
                      label_add: 'EVENT.VISIT.Add a test',
                      description_remove: 'EVENT.VISIT.Remove this test',
                      widget: 'ng-mat-array',
                      options: {
                        remove_btn_inline: false,
                        auto_add: true,
                      },
                      items: {
                        type: 'object',
                        properties: {
                          type: {
                            type: 'string',
                            widget: 'radio',
                            oneOf: [
                              {
                                enum: ['VP100'],
                                label: 'EVENT.VISIT.VP/100AB test',
                              },
                              {
                                enum: ['foulbrood'],
                                label: 'EVENT.VISIT.Foulbrood test',
                              },
                            ],
                          },

                          VP100: {
                            type: 'object',
                            properties: {
                              variant: {
                                label: 'EVENT.VISIT.VP100.Variant',
                                type: 'string',
                                widget: 'radio',
                                oneOf: [
                                  {
                                    enum: ['CO2'],
                                    label: 'EVENT.VISIT.VP100.variant.CO2',
                                  },
                                  {
                                    enum: ['icing_sugar'],
                                    label: 'EVENT.VISIT.VP100.variant.icing sugar',
                                  },
                                  {
                                    enum: ['detergent'],
                                    label: 'EVENT.VISIT.VP100.variant.detergent',
                                  },
                                ],
                              },
                              sample_weight: {
                                label: 'EVENT.VISIT.VP100.Sample weight (g)',
                                type: 'number',
                                minimum: 0,
                                step: '0.1',
                                default: 42,
                              },
                              number_varroas: {
                                label: 'EVENT.VISIT.VP100.Counted number of varroas',
                                type: 'number',
                                minimum: 0,
                                step: 1,
                              },
                              result: {
                                label: 'EVENT.VISIT.VP100.Results',
                                type: 'number',
                                minimum: 0,
                                step: '0.1',
                              },
                            },
                            visibleIf: {
                              type: ['VP100'],
                            },
                          },

                          foulbrood: {
                            type: 'object',
                            properties: {
                              result: {
                                label: 'EVENT.VISIT.Test result',
                                type: 'boolean',
                                widget: 'radio',
                                default: false,
                                oneOf: [
                                  {
                                    enum: [true],
                                    label: 'EVENT.VISIT.Positif test result',
                                  },
                                  {
                                    enum: [false],
                                    label: 'EVENT.VISIT.Negative test result',
                                  },
                                ],
                              },
                            },
                            visibleIf: {
                              type: ['foulbrood'],
                            },
                          },

                          comment: {
                            label: 'ALL.COMMON.Comment',
                            type: 'string',
                            widget: 'textarea',
                            options: {
                              optional: true,
                            },
                            visibleIf: {
                              type: ['$ANY$'],
                            },
                          },
                        },
                      },
                    },

                    treatments: {
                      type: 'array',
                      title: 'EVENT.VISIT.Sanitary treatments',
                      label_add: 'EVENT.VISIT.Add a sanitary treatment',
                      description_remove: 'EVENT.VISIT.Remove this treatment',
                      widget: 'ng-mat-array',
                      options: {
                        remove_btn_inline: false,
                        auto_add: true,
                      },
                      items: {
                        type: 'object',
                        properties: {
                          cat: {
                            type: 'string',
                            widget: 'radio',
                            oneOf: [
                              {
                                enum: ['bio'],
                                label: 'EVENT.VISIT.Bio',
                              },
                              {
                                enum: ['conventionel'],
                                label: 'EVENT.VISIT.Conventionel',
                              },
                            ],
                          },
                          test_type_b: {
                            type: 'string',
                            label: 'EVENT.VISIT.Name of the treatment',
                            visibleIf: {
                              cat: ['bio'],
                            },
                          },
                          test_type_c: {
                            type: 'string',
                            label: 'EVENT.VISIT.Name of the treatment',
                            visibleIf: {
                              cat: ['conventionel'],
                            },
                          },
                          comment: {
                            label: 'ALL.COMMON.Comment',
                            type: 'string',
                            widget: 'textarea',
                            options: {
                              optional: true,
                            },
                            visibleIf: {
                              cat: ['$ANY$'],
                            },
                          },
                        },
                      },
                    },

                    observations: {
                      type: 'array',
                      title: 'EVENT.VISIT.Sanitary observations',
                      label_add: 'EVENT.VISIT.Add an observation',
                      description_remove: 'EVENT.VISIT.Remove this observation',
                      widget: 'ng-mat-array',
                      options: {
                        remove_btn_inline: false,
                        auto_add: true,
                      },
                      items: {
                        type: 'object',
                        option: {
                          transparent: 'false',
                        },
                        properties: {
                          observation: {
                            type: 'string',
                            widget: 'radio',
                            oneOf: [
                              {
                                enum: ['deformed_wings'],
                                label: 'EVENT.VISIT.OBS.Deformed wings',
                              },
                              {
                                enum: ['bee_trembling'],
                                label: 'EVENT.VISIT.OBS.Bee trembling',
                              },
                              {
                                enum: ['hairless_bee'],
                                label: 'EVENT.VISIT.OBS.hairless bee',
                              },
                              {
                                enum: ['bee_cant_fly'],
                                label: "EVENT.VISIT.OBS.Bees can't fly",
                              },
                              {
                                enum: ['heap_dead_bees'],
                                label: 'EVENT.VISIT.OBS.Heap of dead bees',
                              },
                              {
                                enum: ['other'],
                                label: 'EVENT.VISIT.NEXT.Other sanitary observation',
                              },
                            ],
                          },
                          other: {
                            type: 'string',
                            label: 'EVENT.VISIT.Other observation detail',
                            visibleIf: {
                              observation: ['other'],
                            },
                          },
                          nb_hives: {
                            type: 'number',
                            label: 'EVENT.VISIT.Number of affected hives',
                            minimum: 0,
                            step: 1,
                            default: 1,
                            options: {
                              img: 'img/hive.png',
                            },
                            visibleIf: {
                              observation: ['$ANY$'],
                            },
                          },
                          comment: {
                            label: 'ALL.COMMON.Comment',
                            options: {
                              optional: true,
                            },
                            visibleIf: {
                              observation: ['$ANY$'],
                            },
                            type: 'string',
                            widget: 'textarea',
                          },
                        },
                      },
                    },
                  },
                },

                comment: {
                  type: 'string',
                  widget: 'textarea',
                  title: 'ALL.COMMON.Comment',
                  options: {
                    title_style: 'large',
                  },
                },
              },
            },

            hive_by_hive_identified_visit: {
              type: 'array',
              items: {
                type: 'object',
                properties: {
                  hive_id: {
                    label: 'EVENT.VISIT.Evaluated hive',
                    type: 'number',
                    widget: 'hidden',
                  },

                  // hive_usage: {
                  //   title: 'EVENT.VISIT.Hive status',
                  //   widget: 'radio',
                  //   type: 'string',
                  //   oneOf: [
                  //     {
                  //       enum: ['breeding'],
                  //       label: 'EVENT.VISIT.STATUS.breeding',
                  //     },
                  //     {
                  //       enum: ['production_hive'],
                  //       label: 'EVENT.VISIT.STATUS.production_hive',
                  //     },
                  //     {
                  //       enum: ['NV'],
                  //       label: 'EVENT.VISIT.STATUS.NV',
                  //     },
                  //   ],
                  //   _default: {
                  //     value: 'from_previous',
                  //   },
                  //   options: {
                  //     title_style: 'large',
                  //     previous: {
                  //       value: {
                  //         value: 'from_entity',
                  //         entity_path: '/data/evaluation_hive/hive_id',
                  //         path: 'state.status',
                  //       },
                  //       from: {
                  //         value: 'from_entity',
                  //         entity_path: '/data/evaluation_hive/hive_id',
                  //         path: 'state.last_visit.from',
                  //       },
                  //     },
                  //   },
                  // },

                  // global_state: {
                  //   type: 'object',
                  //   properties: {
                  //     dynamism: {
                  //       title: 'EVENT.VISIT.Hive dynamic scoring',
                  //       type: 'number',
                  //       widget: 'rating',
                  //       minimum: 0,
                  //       maximum: 5,
                  //       step: 1,
                  //       options: {
                  //         title_style: 'large',
                  //         reset_btn: true,
                  //         image: 'assets/img/widgets/event-form/bg2-rating/bee.png',
                  //         previous: {
                  //           value: {
                  //             value: 'from_entity',
                  //             entity_path: '/data/evaluation_hive/hive_id',
                  //             path: 'state.global_state.dynamism',
                  //           },
                  //           from: {
                  //             value: 'from_entity',
                  //             entity_path: '/data/evaluation_hive/hive_id',
                  //             path: 'state.last_visit.from',
                  //           },
                  //         },
                  //       },
                  //       _default: {
                  //         value: 'from_previous',
                  //       },
                  //     },
                  //   },
                  // },

                  status: {
                    type: 'string',
                    widget: 'radio',
                    title: 'EVENT.VISIT.General state',
                    default: 'not_evaluated',
                    options: {
                      inline: true,
                      title_style: 'large',
                    },
                    oneOf: [
                      {
                        enum: ['not_evaluated'],
                        label: 'EVENT.VISIT.STATUS.not_evaluated',
                        options: {
                          line: 0,
                        },
                      },
                      {
                        enum: ['OK'],
                        label: 'EVENT.VISIT.STATUS.OK',
                        options: {
                          line: 1,
                          img: 'img/hive_status/ok.png',
                        },
                      },
                      {
                        enum: ['RHS'],
                        label: 'EVENT.VISIT.STATUS.Dead queen',
                        options: {
                          line: 1,
                          img: 'img/hive_status/rhs.png',
                        },
                      },
                      {
                        enum: ['E'],
                        label: 'EVENT.VISIT.STATUS.Swarming',
                        options: {
                          line: 1,
                          img: 'img/hive_status/e.png',
                        },
                      },
                      {
                        enum: ['NV'],
                        label: 'EVENT.VISIT.STATUS.NV',
                        options: {
                          line: 1,
                          img: 'img/hive_status/nv.png',
                        },
                      },
                      {
                        enum: ['B'],
                        label: 'EVENT.VISIT.STATUS.Drone laying queen',
                        options: {
                          line: 1,
                          img: 'img/hive_status/b.png',
                        },
                      },
                      {
                        enum: ['S'],
                        label: 'EVENT.VISIT.STATUS.Sanitary suspicion',
                        options: {
                          line: 1,
                          img: 'img/hive_status/s.png',
                        },
                      },
                      {
                        enum: ['D'],
                        label: 'EVENT.VISIT.STATUS.dead',
                        options: {
                          line: 1,
                          img: 'img/hive_status/d.png',
                        },
                      },
                      {
                        enum: ['T'],
                        label: 'EVENT.VISIT.STATUS.stolen',
                        options: {
                          img: 'img/hive_status/t.png',
                          line: 1,
                        },
                      },
                    ],
                  },

                  brood_box: {
                    title: 'EVENT.VISIT.Brood box',
                    type: 'object',
                    options: {
                      title_style: 'large',
                    },
                    // visibleIf: {
                    //   status: ['OK'],
                    // },
                    properties: {
                      brood_frame: {
                        title: 'EVENT.VISIT.Brood frames',
                        type: 'object',
                        properties: {
                          size: {
                            type: 'number',
                            minimum: 0,
                            maximum: 12,
                            multipleOf: 0.25,
                            widget: 'slider',
                            _default: {
                              value: 'from_previous',
                            },
                            options: {
                              reset_btn: true,
                              showSelectionBar: true,
                              showTicks: true,
                              tickStep: 1,
                              previous: {
                                value: {
                                  value: 'from_entity',
                                  entity_path: '/hive_id',
                                  path: 'state.brood_box.brood_frame.size',
                                  date_path: '/metadata/date',
                                },
                                from: {
                                  value: 'from_entity',
                                  entity_path: '/hive_id',
                                  path: 'state.last_visit.from',
                                  date_path: '/metadata/date',
                                },
                              },
                            },
                          },
                          added: {
                            label: 'EVENT.VISIT.Number of added or removed brood frames',
                            type: 'number',
                            widget: 'number-light',
                            minimum: -10,
                            maximum: 10,
                            multipleOf: 1,
                            default: 0,
                            _default: {
                              value: 'from_previous',
                            },
                            options: {
                              reset_btn: true,
                              previous: {
                                value: {
                                  value: 'from_entity',
                                  entity_path: '/hive_id',
                                  path: 'state.brood_box.brood_frame.added',
                                  date_path: '/metadata/date',
                                },
                                from: {
                                  value: 'from_entity',
                                  entity_path: '/hive_id',
                                  path: 'state.last_visit.from',
                                  date_path: '/metadata/date',
                                },
                              },
                            },
                          },
                          homogeneous: {
                            label: 'EVENT.VISIT.Homogeneous brood',
                            type: 'boolean',
                            default: true,
                            widget: 'checkbox',
                            _default: {
                              value: 'from_previous',
                            },
                            options: {
                              previous: {
                                value: {
                                  value: 'from_entity',
                                  entity_path: '/hive_id',
                                  path: 'state.brood_box.brood_frame.homogeneous',
                                  date_path: '/metadata/date',
                                },
                                from: {
                                  value: 'from_entity',
                                  entity_path: '/hive_id',
                                  path: 'state.last_visit.from',
                                  date_path: '/metadata/date',
                                },
                              },
                            },
                          },
                          eggs: {
                            label: 'EVENT.VISIT.Presence of eggs',
                            type: 'boolean',
                            default: false,
                            widget: 'checkbox',
                            _default: {
                              value: 'from_previous',
                            },
                            options: {
                              previous: {
                                value: {
                                  value: 'from_entity',
                                  entity_path: '/hive_id',
                                  path: 'state.brood_box.brood_frame.eggs',
                                  date_path: '/metadata/date',
                                },
                                from: {
                                  value: 'from_entity',
                                  entity_path: '/hive_id',
                                  path: 'state.last_visit.from',
                                  date_path: '/metadata/date',
                                },
                              },
                            },
                          },
                        },
                      },
                      stores_frames: {
                        title: 'EVENT.VISIT.Store frames',
                        type: 'object',
                        properties: {
                          size: {
                            type: 'number',
                            minimum: 0,
                            maximum: 12,
                            multipleOf: 0.25,
                            widget: 'slider',
                            _default: {
                              value: 'from_previous',
                            },
                            options: {
                              reset_btn: true,
                              showSelectionBar: true,
                              showTicks: true,
                              tickStep: 1,
                              previous: {
                                value: {
                                  value: 'from_entity',
                                  entity_path: '/hive_id',
                                  path: 'state.brood_box.stores_frames.size',
                                  date_path: '/metadata/date',
                                },
                                from: {
                                  value: 'from_entity',
                                  entity_path: '/hive_id',
                                  path: 'state.last_visit.from',
                                  date_path: '/metadata/date',
                                },
                              },
                            },
                          },
                          added: {
                            label: 'EVENT.VISIT.Number of added or removed store frames',
                            type: 'number',
                            widget: 'number-light',
                            minimum: -10,
                            maximum: 10,
                            multipleOf: 1,
                            default: 0,
                            _default: {
                              value: 'from_previous',
                            },
                            options: {
                              reset_btn: true,
                              showSelectionBar: true,
                              showTicks: true,
                              tickStep: 1,
                              previous: {
                                value: {
                                  value: 'from_entity',
                                  entity_path: '/hive_id',
                                  path: 'state.brood_box.stores_frames.added',
                                  date_path: '/metadata/date',
                                },
                                from: {
                                  value: 'from_entity',
                                  entity_path: '/hive_id',
                                  path: 'state.last_visit.from',
                                  date_path: '/metadata/date',
                                },
                              },
                            },
                          },
                        },
                      },
                      empty_frames: {
                        title: 'EVENT.VISIT.New empty frames',
                        type: 'object',
                        properties: {
                          added: {
                            type: 'number',
                            widget: 'slider',
                            minimum: 0,
                            maximum: 10,
                            multipleOf: 1,
                            default: 0,
                            _default: {
                              value: 'from_previous',
                            },
                            options: {
                              reset_btn: true,
                              showSelectionBar: true,
                              showTicks: true,
                              tickStep: 1,
                              previous: {
                                value: {
                                  value: 'from_entity',
                                  entity_path: '/hive_id',
                                  path: 'state.brood_box.stores_frames.added',
                                  date_path: '/metadata/date',
                                },
                                from: {
                                  value: 'from_entity',
                                  entity_path: '/hive_id',
                                  path: 'state.last_visit.from',
                                  date_path: '/metadata/date',
                                },
                              },
                            },
                          },
                        },
                      },
                      comment: {
                        title: 'ALL.COMMON.Comment',
                        options: {
                          optional: true,
                        },
                        type: 'string',
                        widget: 'textarea',
                      },
                    },
                  },

                  queen: {
                    title: 'EVENT.VISIT.Queen state',
                    type: 'object',
                    options: {
                      title_style: 'large',
                    },
                    // visibleIf: {
                    //   status: ['OK'],
                    // },
                    properties: {
                      status: {
                        type: 'string',
                        widget: 'radio',
                        default: 'not_evaluated',
                        oneOf: [
                          {
                            enum: ['not_evaluated'],
                            label: 'EVENT.VISIT.QUEEN.not_evaluated',
                            options: {
                              line: 0,
                            },
                          },
                          {
                            enum: ['present'],
                            label: 'EVENT.VISIT.QUEEN.present',
                            options: {
                              line: 0,
                            },
                          },
                          {
                            enum: ['missing'],
                            label: 'EVENT.VISIT.QUEEN.missing',
                            options: {
                              line: 0,
                            },
                          },
                          {
                            enum: ['introduced'],
                            label: 'EVENT.VISIT.QUEEN.introduced',
                            options: {
                              line: 0,
                            },
                          },
                        ],
                        _default: {
                          value: 'from_previous',
                        },
                        options: {
                          inline: true,
                          previous: {
                            value: {
                              value: 'from_entity',
                              entity_path: '/hive_id',
                              path: 'state.queen.status',
                              date_path: '/metadata/date',
                            },
                            from: {
                              value: 'from_entity',
                              entity_path: '/hive_id',
                              path: 'state.last_visit.from',
                              date_path: '/metadata/date',
                            },
                          },
                        },
                      },
                      color: {
                        label: 'EVENT.VISIT.Queen color',
                        type: 'string',
                        widget: 'radio',
                        default: 'unknow',
                        oneOf: [
                          {
                            enum: ['unknow'],
                            label: 'EVENT.VISIT.QUEEN.Unknow',
                            options: {
                              line: 0,
                              img: 'img/queen/unknow.png',
                            },
                          },
                          {
                            enum: ['not_found'],
                            label: 'EVENT.VISIT.QUEEN.Not found',
                            options: {
                              line: 0,
                              img: 'img/queen/not_found.png',
                            },
                          },
                          {
                            enum: ['b'],
                            label: 'EVENT.VISIT.QUEEN.blue',
                            options: {
                              line: 0,
                              class: 'color',
                              img: 'img/queen/b.png',
                              label_style: {
                                'background-color': 'cornflowerblue',
                              },
                            },
                          },
                          {
                            enum: ['w'],
                            label: 'EVENT.VISIT.QUEEN.white',
                            options: {
                              line: 0,
                              class: 'color',
                              img: 'img/queen/w.png',
                              label_style: {
                                'background-color': 'whitesmoke',
                              },
                            },
                          },
                          {
                            enum: ['y'],
                            label: 'EVENT.VISIT.QUEEN.yellow',
                            options: {
                              line: 0,
                              class: 'color',
                              img: 'img/queen/y.png',
                              label_style: {
                                'background-color': 'yellow',
                              },
                            },
                          },
                          {
                            enum: ['r'],
                            label: 'EVENT.VISIT.QUEEN.red',
                            options: {
                              line: 0,
                              class: 'color',
                              img: 'img/queen/r.png',
                              label_style: {
                                'background-color': 'indianred',
                              },
                            },
                          },
                          {
                            enum: ['g'],
                            label: 'EVENT.VISIT.QUEEN.green',
                            options: {
                              line: 0,
                              class: 'color',
                              img: 'img/queen/g.png',
                              label_style: {
                                'background-color': 'seagreen',
                              },
                            },
                          },
                        ],
                        visibleIf: {
                          status: ['present', 'introduced'],
                        },
                        _default: {
                          value: 'from_previous',
                        },
                        options: {
                          inline: true,
                          previous: {
                            value: {
                              value: 'from_entity',
                              entity_path: '/hive_id',
                              path: 'state.queen.color',
                              date_path: '/metadata/date',
                            },
                            from: {
                              value: 'from_entity',
                              entity_path: '/hive_id',
                              path: 'state.last_visit.from',
                              date_path: '/metadata/date',
                            },
                          },
                        },
                      },
                      queen_cell: {
                        type: 'object',
                        label: 'EVENT.VISIT.Queen cells',
                        properties: {
                          status: {
                            type: 'string',
                            widget: 'radio',
                            default: 'not_evaluated',
                            oneOf: [
                              {
                                enum: ['not_evaluated'],
                                label: 'EVENT.VISIT.QUEEN.not_evaluated',
                                options: {
                                  line: 0,
                                },
                              },
                              {
                                enum: ['absent'],
                                label: 'EVENT.VISIT.QUEEN_CELL.absent',
                                options: {
                                  line: 0,
                                },
                              },
                              {
                                enum: ['observed'],
                                label: 'EVENT.VISIT.QUEEN_CELL.observed',
                                options: {
                                  line: 0,
                                },
                              },
                              {
                                enum: ['introduced'],
                                label: 'EVENT.VISIT.QUEEN_CELL.introduced',
                                options: {
                                  line: 0,
                                },
                              },
                            ],
                            _default: {
                              value: 'from_previous',
                            },
                            options: {
                              inline: true,
                              previous: {
                                value: {
                                  value: 'from_entity',
                                  entity_path: '/hive_id',
                                  path: 'state.queen.queen_cell.status',
                                  date_path: '/metadata/date',
                                },
                                from: {
                                  value: 'from_entity',
                                  entity_path: '/hive_id',
                                  path: 'state.last_visit.from',
                                  date_path: '/metadata/date',
                                },
                              },
                            },
                          },
                          action: {
                            label: 'EVENT.VISIT.Action',
                            type: 'string',
                            widget: 'radio',
                            default: 'nothing',
                            oneOf: [
                              {
                                enum: ['nothing'],
                                label: 'EVENT.VISIT.QUEEN_CELL.not_evaluated',
                                options: {
                                  line: 0,
                                },
                              },
                              {
                                enum: ['destroyed'],
                                label: 'EVENT.VISIT.QUEEN_CELL.destroyed',
                                options: {
                                  line: 0,
                                },
                              },
                              {
                                enum: ['collected'],
                                label: 'EVENT.VISIT.QUEEN_CELL.collected',
                                options: {
                                  line: 0,
                                },
                              },
                            ],
                            _default: {
                              value: 'from_previous',
                            },
                            options: {
                              inline: true,
                              previous: {
                                value: {
                                  value: 'from_entity',
                                  entity_path: '/hive_id',
                                  path: 'state.queen.queen_cell.action',
                                  date_path: '/metadata/date',
                                },
                                from: {
                                  value: 'from_entity',
                                  entity_path: '/hive_id',
                                  path: 'state.last_visit.from',
                                  date_path: '/metadata/date',
                                },
                              },
                            },
                            visibleIf: {
                              status: ['observed'],
                            },
                          },
                        },
                      },
                    },
                  },

                  super: {
                    type: 'object',
                    title: 'EVENT.VISIT.Supers',
                    options: {
                      title_style: 'large',
                    },
                    // visibleIf: {
                    //   status: ['OK'],
                    // },
                    properties: {
                      nb_supers: {
                        label: 'EVENT.VISIT.Number of supers on the hive',
                        type: 'number',
                        minimum: 0,
                        maximum: 8,
                        multipleOf: 1,
                        widget: 'number-light',
                        _default: {
                          value: 'from_previous',
                        },
                        options: {
                          img: 'img/super/super_[value].svg',
                          previous: {
                            value: {
                              value: 'from_entity',
                              entity_path: '/hive_id',
                              path: 'state.nb_supers',
                              date_path: '/metadata/date',
                            },
                            from: {
                              value: 'from_entity',
                              entity_path: '/hive_id',
                              path: 'state.last_visit.from',
                              date_path: '/metadata/date',
                            },
                          },
                        },
                      },
                    },
                  },

                  feeding: {
                    type: 'object',
                    required: ['feeding_type'],
                    additionalProperties: false,
                    title: 'EVENT.VISIT.Feeding',
                    options: {
                      title_style: 'large',
                    },
                    properties: {
                      feeding_type: {
                        type: 'string',
                        widget: 'radio',
                        label: 'EVENT.FEEDING.Type of feeding',
                        default: 'FEEDING_TYPE.None',
                        oneOf: [
                          {
                            enum: ['FEEDING_TYPE.None'],
                            label: 'FEEDING_TYPE.None',
                          },
                          {
                            enum: ['FEEDING_TYPE.Candy'],
                            label: 'FEEDING_TYPE.Candy',
                          },
                          {
                            enum: ['FEEDING_TYPE.Sirup'],
                            label: 'FEEDING_TYPE.Sirup',
                          },
                          {
                            enum: ['FEEDING_TYPE.Honey'],
                            label: 'FEEDING_TYPE.Honey',
                          },
                          {
                            enum: ['FEEDING_TYPE.Pollen'],
                            label: 'FEEDING_TYPE.Pollen',
                          },
                          {
                            enum: ['FEEDING_TYPE.OTHER'],
                            label: 'FEEDING_TYPE.OTHER',
                          },
                        ],
                      },
                      feeding_type_other: {
                        type: 'string',
                        label: 'EVENT.FEEDING.Ohter',
                        visibleIf: {
                          feeding_type: ['FEEDING_TYPE.OTHER'],
                        },
                      },
                      quantity_kg: {
                        label: 'EVENT.FEEDING.Quantity per hive (in kg)',
                        type: 'number',
                        minimum: 0,
                        step: 0.1,
                        visibleIf: {
                          feeding_type: ['FEEDING_TYPE.Candy', 'FEEDING_TYPE.Honey', 'FEEDING_TYPE.Pollen', 'FEEDING_TYPE.OTHER'],
                        },
                      },
                      quantity_L: {
                        label: 'EVENT.FEEDING.Quantity per hive (in L)',
                        type: 'number',
                        minimum: 0,
                        step: 0.1,
                        visibleIf: {
                          feeding_type: ['FEEDING_TYPE.Sirup'],
                        },
                      },
                      comment: {
                        type: 'string',
                        label: 'ALL.COMMON.Comment',
                        widget: 'textarea',
                        options: {
                          optional: true,
                        },
                        visibleIf: {
                          feeding_type: ['$ANY$'],
                        },
                      },
                    },
                    // visibleIf: {
                    //   status: ['OK'],
                    // },
                  },

                  htype: {
                    widget: 'hidden',
                    type: 'string',
                    oneOf: [
                      {
                        enum: ['hive'],
                        label: 'ENTITY.ALL.TYPE.hive',
                        options: {
                          img: 'img/hive.png',
                        },
                      },
                      {
                        enum: ['nuc'],
                        label: 'ENTITY.ALL.TYPE.nuc',
                        options: {
                          img: 'img/nuc_hive.png',
                        },
                      },
                    ],
                  },

                  mortality: {
                    title: 'EVENT.VISIT.Hive mortality',
                    options: {
                      title_style: 'large',
                    },
                    // visibleIf: {
                    //   status: ['D'],
                    // },
                    type: 'object',
                    properties: {
                      observation: {
                        label: 'EVENT.VISIT.Observations',
                        type: 'string',
                        widget: 'select',
                        default: 'nothing',
                        oneOf: [
                          {
                            enum: ['nothing'],
                            label: 'EVENT.VISIT.OBS.Nothing',
                          },
                          {
                            enum: ['depopulation'],
                            label: 'EVENT.VISIT.OBS.Depopulation',
                          },
                          {
                            enum: ['heap_dead_bees'],
                            label: 'EVENT.VISIT.OBS.Heap deed bees',
                          },
                        ],
                      },
                      cause_supposed: {
                        label: 'EVENT.VISIT.DEAD.Supposed cause',
                        type: 'string',
                        widget: 'select',
                        default: 'do_not_know',
                        oneOf: [
                          {
                            enum: ['do_not_know'],
                            label: 'EVENT.VISIT.DEAD.CAUSE.I do not know',
                          },
                          {
                            enum: ['famine'],
                            label: 'EVENT.VISIT.DEAD.CAUSE.Famine',
                          },
                          {
                            enum: ['poisoning'],
                            label: 'EVENT.VISIT.DEAD.CAUSE.Poisoning',
                          },
                          {
                            enum: ['pathology'],
                            label: 'EVENT.VISIT.DEAD.CAUSE.Pathology',
                          },
                          {
                            enum: ['other'],
                            label: 'EVENT.VISIT.Other',
                          },
                        ],
                      },
                      next_action: {
                        label: 'EVENT.VISIT.DEAD.Next action',
                        type: 'string',
                        widget: 'select',
                        default: 'nothing',
                        oneOf: [
                          {
                            enum: ['nothing'],
                            label: 'EVENT.VISIT.DEAD.NEXT.Nothing',
                          },
                          {
                            enum: ['veterinary_expertise'],
                            label: 'EVENT.VISIT.DEAD.NEXT.Veterinary expertise',
                          },
                          {
                            enum: ['repopulation'],
                            label: 'EVENT.VISIT.DEAD.NEXT.Repopulation',
                          },
                          {
                            enum: ['destruction'],
                            label: 'EVENT.VISIT.DEAD.NEXT.Destruction',
                          },
                          {
                            enum: ['back to stock'],
                            label: 'EVENT.VISIT.DEAD.NEXT.Back to stock',
                          },
                          {
                            enum: ['other'],
                            label: 'EVENT.VISIT.Other',
                          },
                        ],
                      },
                      comment: {
                        label: 'ALL.COMMON.Comment',
                        options: {
                          optional: true,
                        },
                        type: 'string',
                        widget: 'textarea',
                      },
                    },
                  },

                  next_actions: {
                    title: 'EVENT.VISIT.Next actions',
                    type: 'array',
                    options: {
                      title_style: 'large',
                    },
                    // visibleIf: {
                    //   status: ['OK', 'D'],
                    // },
                    items: {
                      type: 'object',
                      properties: {
                        value: {
                          type: 'string',
                          widget: 'select',
                          oneOf: [
                            {
                              enum: ['hive_to_divide'],
                              label: 'EVENT.VISIT.STATUS.hive_to_divide',
                            },
                            {
                              enum: ['feeding'],
                              label: 'EVENT.VISIT.NEXT.Feeding',
                            },
                            {
                              enum: ['harvest'],
                              label: 'EVENT.VISIT.NEXT.Harvest',
                            },
                            {
                              enum: ['other'],
                              label: 'EVENT.VISIT.NEXT.Other',
                            },
                          ],
                        },
                        other: {
                          type: 'string',
                          visibleIf: {
                            value: ['other'],
                          },
                        },
                      },
                    },
                  },

                  comment: {
                    type: 'string',
                    widget: 'textarea',
                    title: 'ALL.COMMON.Comment',
                    options: {
                      title_style: 'large',
                    },
                  },
                },
              },
            },

            hive_by_hive_not_identified_visit: {
              type: 'array',
              items: {
                type: 'object',
                properties: {
                  visit_id: {
                    type: 'number',
                    widget: 'hidden',
                  },

                  dynamism: {
                    title: 'EVENT.VISIT.Hive dynamic scoring',
                    type: 'number',
                    widget: 'rating',
                    minimum: 0,
                    maximum: 5,
                    step: 1,
                    options: {
                      title_style: 'large',
                      reset_btn: true,
                      image: 'assets/img/widgets/event-form/bg2-rating/bee.png',
                    },
                  },

                  status: {
                    type: 'string',
                    widget: 'radio',
                    title: 'EVENT.VISIT.Hive status',
                    default: 'not_evaluated',
                    options: {
                      title_style: 'large',
                    },
                    oneOf: [
                      {
                        enum: ['not_evaluated'],
                        label: 'EVENT.VISIT.STATUS.not_evaluated',
                        options: {
                          line: 0,
                        },
                      },
                      {
                        enum: ['OK'],
                        label: 'EVENT.VISIT.STATUS.OK',
                        options: {
                          line: 1,
                          img: 'img/hive_status/ok.png',
                        },
                      },
                      {
                        enum: ['RHS'],
                        label: 'EVENT.VISIT.STATUS.Dead queen',
                        options: {
                          line: 1,
                          img: 'img/hive_status/rhs.png',
                        },
                      },
                      {
                        enum: ['E'],
                        label: 'EVENT.VISIT.STATUS.Swarming',
                        options: {
                          line: 2,
                          img: 'img/hive_status/e.png',
                        },
                      },
                      {
                        enum: ['NV'],
                        label: 'EVENT.VISIT.STATUS.NV',
                        options: {
                          line: 2,
                          img: 'img/hive_status/nv.png',
                        },
                      },
                      {
                        enum: ['B'],
                        label: 'EVENT.VISIT.STATUS.Drone laying queen',
                        options: {
                          line: 3,
                          img: 'img/hive_status/b.png',
                        },
                      },
                      {
                        enum: ['S'],
                        label: 'EVENT.VISIT.STATUS.Sanitary suspicion',
                        options: {
                          line: 3,
                          img: 'img/hive_status/s.png',
                        },
                      },
                      {
                        enum: ['D'],
                        label: 'EVENT.VISIT.STATUS.dead',
                        options: {
                          line: 4,
                          img: 'img/hive_status/d.png',
                        },
                      },
                      {
                        enum: ['T'],
                        label: 'EVENT.VISIT.STATUS.stolen',
                        options: {
                          img: 'img/hive_status/t.png',
                          line: 4,
                        },
                      },
                    ],
                  },

                  brood_box: {
                    type: 'string',
                    widget: 'radio',
                    title: 'EVENT.VISIT.Brood frame',
                    options: {
                      title_style: 'large',
                    },
                    oneOf: [
                      {
                        enum: ['C0'],
                        label: 'EVENT.VISIT.BF.No BF',
                        options: {
                          img: 'img/hive_frame/c0.png',
                          line: 0,
                        },
                      },
                      {
                        enum: ['C2'],
                        label: 'EVENT.VISIT.BF.2 BF',
                        options: {
                          img: 'img/hive_frame/c2.png',
                          line: 0,
                        },
                      },
                      {
                        enum: ['C3'],
                        label: 'EVENT.VISIT.BF.3 BF',
                        options: {
                          img: 'img/hive_frame/c3.png',
                          line: 1,
                        },
                      },
                      {
                        enum: ['C4'],
                        label: 'EVENT.VISIT.BF.4 BF',
                        options: {
                          img: 'img/hive_frame/c4.png',
                          line: 1,
                        },
                      },
                      {
                        enum: ['C5'],
                        label: 'EVENT.VISIT.BF.5 BF',
                        options: {
                          img: 'img/hive_frame/c5.png',
                          line: 2,
                        },
                      },
                      {
                        enum: ['C6'],
                        label: 'EVENT.VISIT.BF.6 BF',
                        options: {
                          img: 'img/hive_frame/c6.png',
                          line: 2,
                        },
                      },
                      {
                        enum: ['C7+'],
                        label: 'EVENT.VISIT.BF.+ 7 BF',
                        options: {
                          img: 'img/hive_frame/c7+.png',
                          line: 3,
                        },
                      },
                    ],
                  },

                  queen: {
                    type: 'string',
                    widget: 'radio',
                    title: 'EVENT.VISIT.Queen color',
                    options: {
                      title_style: 'large',
                    },
                    oneOf: [
                      {
                        enum: ['b'],
                        label: 'EVENT.VISIT.QUEEN.blue',
                        options: {
                          img: 'img/queen/b.png',
                          line: 0,
                        },
                      },
                      {
                        enum: ['w'],
                        label: 'EVENT.VISIT.QUEEN.white',
                        options: {
                          img: 'img/queen/w.png',
                          line: 0,
                        },
                      },
                      {
                        enum: ['y'],
                        label: 'EVENT.VISIT.QUEEN.yellow',
                        options: {
                          img: 'img/queen/y.png',
                          line: 1,
                        },
                      },
                      {
                        enum: ['r'],
                        label: 'EVENT.VISIT.QUEEN.red',
                        options: {
                          img: 'img/queen/r.png',
                          line: 1,
                        },
                      },
                      {
                        enum: ['g'],
                        label: 'EVENT.VISIT.QUEEN.green',
                        options: {
                          img: 'img/queen/g.png',
                          line: 2,
                        },
                      },
                      {
                        enum: ['unknow'],
                        label: 'EVENT.VISIT.QUEEN.Unknow',
                        options: {
                          img: 'img/queen/unknow.png',
                          line: 2,
                        },
                      },
                      {
                        enum: ['not_found'],
                        label: 'EVENT.VISIT.QUEEN.Not found',
                        options: {
                          img: 'img/queen/not_found.png',
                          line: 3,
                        },
                      },
                    ],
                  },

                  super: {
                    type: 'string',
                    widget: 'radio',
                    title: 'EVENT.VISIT.Supers repartition',
                    options: {
                      title_style: 'large',
                    },
                    oneOf: [
                      {
                        enum: ['0'],
                        label: 'EVENT.VISIT.SUPERS.No supers',
                        options: {
                          img: 'img/super/super_0.svg',
                          line: 0,
                        },
                      },
                      {
                        enum: ['1'],
                        label: 'EVENT.VISIT.SUPERS.1 super',
                        options: {
                          img: 'img/super/super_1.svg',
                          line: 0,
                        },
                      },
                      {
                        enum: ['3'],
                        label: 'EVENT.VISIT.SUPERS.3 supers',
                        options: {
                          img: 'img/super/super_3.svg',
                          line: 1,
                        },
                      },
                      {
                        enum: ['4'],
                        label: 'EVENT.VISIT.SUPERS.4 supers',
                        options: {
                          img: 'img/super/super_4.svg',
                          line: 1,
                        },
                      },
                      {
                        enum: ['4+'],
                        label: 'EVENT.VISIT.SUPERS.4+ supers',
                        options: {
                          img: 'img/super/super_4+.svg',
                          line: 2,
                        },
                      },
                    ],
                  },
                },
              },
            },
          },
        },
      },
    },
  };

  constructor(protected bg2Api: Beeguard2Api) {
    super(bg2Api);

    this.type = 'evaluation';
    this.vconf.update_modal = 'visit';

    this.schema$$ = of(VisitEvent.draft_schema);
  }

  // #endregion

  // #region -> (schema management)

  // #endregion

  // #region -> (model management)

  // #endregion
}
