import { DOCUMENT } from '@angular/common';
import {
  Type,
  Inject,
  OnInit,
  Renderer2,
  Component,
  OnDestroy,
  TemplateRef,
  ComponentRef,
  AfterViewInit,
  ChangeDetectionStrategy,
} from '@angular/core';

import { clone, isNil } from 'lodash-es';

import { filter, map, BehaviorSubject, combineLatest, Observable, of } from 'rxjs';
import { distinctUntilRealChanged, replay } from '@bg2app/tools/rxjs';

import { ConsoleLoggerService } from 'app/core/console-logger.service';

import { Dictionary } from 'app/typings/core/interfaces';

import { string_format } from 'app/misc/tools';
import { AbstractCarouselContainerComponent } from '../../abstract-carousel/abstract-carousel-container/abstract-carousel-container.component';
import { TimeBasedCarouselSlideComponent } from '../time-carousel-slide/time-carousel-slide.component';
import { ErrorHelperData } from 'app/widgets/widgets-reusables/errors/error-helper/error-helper.component';

export type Content<T> = string | TemplateRef<T> | Type<T>;

/**
 * @module
 *
 * @description
 *
 *
 */
@Component({
  selector: 'bg2-time-carousel-container',
  templateUrl: './time-carousel-container.component.html',
  styleUrls: ['./time-carousel-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TimeBasedCarouselContainerComponent
  extends AbstractCarouselContainerComponent<any>
  implements OnInit, AfterViewInit, OnDestroy
{
  // #region -> (component basics)

  protected _logger = new ConsoleLoggerService('TimeBasedCarouselContainerComponent', true);

  constructor(protected _renderer: Renderer2, @Inject(DOCUMENT) protected document: Document) {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  ngAfterViewInit(): void {
    this.new_card_group_input_data = {
      start_date: this._start_date,
      end_date: this._end_date,
    };

    this.addSlide();

    this.date_computing.compute_previous_range();
    this.addSlide();

    this._slider$$.next(this.slider);

    super.ngAfterViewInit();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  // #endregion

  // #region -> (per slide management)

  public previous_slide_dates$$: Observable<{ start_date: Date; end_date: Date }> = this.previous_slide_static_data$$.pipe(
    map(static_data => {
      if (isNil(static_data)) {
        return null;
      }

      return { start_date: static_data.start_date, end_date: static_data.end_date };
    }),
    distinctUntilRealChanged()
  );

  public next_slide_dates$$: Observable<{ start_date: Date; end_date: Date }> = this.next_slide_static_data$$.pipe(
    map(static_data => {
      if (isNil(static_data)) {
        return null;
      }

      return { start_date: static_data.start_date, end_date: static_data.end_date };
    }),
    distinctUntilRealChanged()
  );

  // #endregion

  // #region -> (carousel dating management)

  protected _start_date: Date = null;
  protected _end_date: Date = null;

  protected date_computing = {
    compute_start_date: (end_date: Date): Date => {
      throw new Error('Not implemented exception !');
    },
    compute_previous_range: (): void => {
      throw new Error('Not implemented exception !');
    },
  };

  // #endregion

  // #region -> (carousel dating navigation)

  private _formatters$$: BehaviorSubject<{ date: string; label: string }> = new BehaviorSubject<{ date: string; label: string }>({
    date: 'LLLL yyyy',
    label: '${dateStart} - ${dateEnd}',
  });

  public formatters$$: Observable<{ date: string; label: string }> = this._formatters$$.asObservable().pipe(distinctUntilRealChanged());

  protected set formatters(formatters: { date?: string; label?: string }) {
    const previous = this._formatters$$.getValue();
    this._formatters$$.next({ ...previous, ...formatters });
  }

  public formatLabel$$(dateStart: string, dateEnd: string): Observable<string> {
    return this.formatters$$.pipe(
      map(formatters => formatters.label),
      map(label_formatter => string_format(label_formatter)({ dateStart, dateEnd })),
      distinctUntilRealChanged(),
      replay()
    );
  }

  // #endregion

  /** */
  public error$$: Observable<ErrorHelperData> = of(null);

  public loadPrevious(): void {
    // Add a new slide
    if (clone(this.carousel_items?.length) <= Math.abs(this.current_index) + 2) {
      this._transition$$.next(false);
      this.date_computing.compute_previous_range();
      this.addSlide();
      this.update();
    }

    // Move to the new slide
    setTimeout(() => {
      this._transition$$.next(true);
      this.current_index = this.current_index - 1;
      this.update();
    }, 1);
  }

  // #region -> (component creation)

  protected addSlide(): void {
    const carousel_item_ref: ComponentRef<TimeBasedCarouselSlideComponent> = this.container.createComponent(
      this.type_of_card_group_component,
      { index: 0 }
    );

    carousel_item_ref.instance.dynamic_parameters = this._dynamic_data;
    carousel_item_ref.instance.static_parameters = this.new_card_group_input_data;
    carousel_item_ref.hostView.detectChanges();

    this.carousel_items = [carousel_item_ref];
  }

  // #endregion
}
