import { DeviceApi } from 'app/core';
import { DeviceQueryParams } from 'app/core/api/device/device-api-service';

import { isNil } from 'lodash-es';
import { differenceInHours, differenceInYears } from 'date-fns';

import { Observable, combineLatest, map, of, switchMap } from 'rxjs';

import { parseDate } from 'app/misc/tools';

import { DeviceRSSIData, TGJobData, TGSecondaryData } from 'app/models/data';

import { compute_868_state } from '../../_functions';
import {
  DEVICE_BATTERY_TYPE,
  DEVICE_SIMPLIFIED_BATTERY_STATE,
  DEVICE_SIMPLIFIED_BATTERY_STATE_REASON,
  DeviceCommunicationTechnology,
  DeviceSimplifiedBatteryState,
} from '../../enumerators';
import { DRDevice, DeviceInterface, DeviceLastMeasurements, DeviceStatus, DeviceStatus868 } from '../../DRDevice';

export class TGDevice extends DRDevice {
  // #region -> (model basics)

  constructor(protected deviceApi: DeviceApi, params?: DeviceQueryParams) {
    super(deviceApi, params);

    this.is_gateway = false;
    this.type = DeviceInterface.TypeEnum.TG;
  }

  // #endregion

  /** */
  private get last_sensor_message() {
    const last_sensor_message = this.last_measurements?.sensor_message;

    if (last_sensor_message) {
      last_sensor_message.time = parseDate(last_sensor_message.time);
    }

    return last_sensor_message;
  }

  // #region -> battery

  /** */
  protected compute_battery_type$$(): Observable<DEVICE_BATTERY_TYPE> {
    return of(DEVICE_BATTERY_TYPE.G3);
  }

  /** */
  // protected get_battery_noload_voltage(last_measurements: DeviceLastMeasurements): number | number[] {
  //   return last_measurements?.sensor_message?.fields?.valim?.last ?? null;
  // }

  /** */
  protected get_battery_com_voltage(last_measurements: DeviceLastMeasurements): number | number[] {
    return last_measurements?.sensor_message?.fields?.sensor_vbat?.last ?? null;
  }

  public get_battery_std_voltage_range$$(): Observable<[number, number]> {
    return of([2.3, 3.2]);
  }

  protected get_battery_critical_vbat$$(): Observable<number> {
    return of(2.7);
  }

  /** */
  public get_battery_simplified_state$$(): Observable<DeviceSimplifiedBatteryState> {
    return this._get_default_battery_simplified_state$$.pipe(
      switchMap(state => {
        if (!isNil(state)) {
          return of(state);
        }

        return combineLatest({
          last_power_on: this.last_power_on$$,
          last_battery_change: this.last_battery_change$$,
        }).pipe(
          map(({ last_battery_change, last_power_on }) => {
            const last_battery_change_years = Math.abs(differenceInYears(last_battery_change?.time ?? last_power_on, new Date()));

            if (last_battery_change_years >= 1) {
              return {
                state: DEVICE_SIMPLIFIED_BATTERY_STATE.NOT_OK,
                reason: DEVICE_SIMPLIFIED_BATTERY_STATE_REASON.LAST_BATTERY_OLDER_THAN_1_YEAR,
              };
            }

            return {
              state: DEVICE_SIMPLIFIED_BATTERY_STATE.OK,
              reason: null,
            };
          })
        );
      })
    );
  }

  // #endregion

  // #region -> (s868 status)

  /** */
  public get status_868(): DeviceStatus868 {
    const message = this.last_sensor_message;

    if (isNil(message)) {
      return null;
    }

    let outdated = false;
    const timestamp = message.time;
    const value = message.fields.rssi.last;

    if (differenceInHours(new Date(), timestamp) > 49) {
      outdated = true;
    }

    const prebuilt_status: DeviceStatus = { timestamp, value, outdated };
    return { state: compute_868_state(prebuilt_status), ...prebuilt_status };
  }

  // #endregion

  // #region -> (device timeseries)

  public fetch_job_data$(start?: Date, end?: Date, step?: string): Observable<TGJobData> {
    return super
      .requestTimeseries(['temperature'], start, end, step)
      .pipe(map(response => <TGJobData>{ points: response?.timeseries?.data ?? [] }));
  }

  public fetch_secondary_data$(start?: Date, end?: Date, step?: string): Observable<TGSecondaryData> {
    return of(<TGSecondaryData>{
      points: [],
    });
  }

  public fetch_rssi$(start?: Date, end?: Date, step?: string): Observable<DeviceRSSIData> {
    return super.requestTimeseries(['rssi_868'], start, end, step).pipe(
      map(
        response =>
          <DeviceRSSIData>{
            points: response?.timeseries?.data ?? [],
          }
      )
    );
  }

  // #endregion

  // #region -> (communication technology)

  protected get_com_technology$$(): Observable<DeviceCommunicationTechnology[]> {
    return of(null);
  }

  // #endregion

  // public deserialize(input: DeviceInterface, fields?: (keyof DRDevice)[]): DRDevice {
  //   console.log({ input, fields });
  //   return super.deserialize(input, fields);
  // }
}
