import { HttpClient } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';

import { isEmpty, isNil, merge } from 'lodash-es';

import { replay, waitForNotNilValue } from '@bg2app/tools/rxjs';
import { catchError, map, Observable, of, pipe, switchMap, take } from 'rxjs';

import { ZohoAuthService } from 'app/core/services/zoho/zoho-auth.service';
import {
  get_i18n_for_zoho_error,
  IZohoCRMAccount,
  IZohoCRMContact,
  IZohoCRMNote,
  IZohoCRMTask,
  ZohoBaseModel,
  ZohoCRMAccount,
  ZohoCRMContact,
  ZohoCRMModuleName,
  ZohoCRMNote,
  ZohoCRMTask,
  ZohoError,
} from 'app/models/zoho';
import { ApiCache } from 'app/core/api/misc/api-cache';
import { PickEnum } from 'app/models';
import { IZohoCRMDeal, ZohoCRMDeal } from 'app/models/zoho/crm/modules';

/**
 * Zoho CRM API service.
 *
 * Use this API to manage modules, organization, roles, profiles, territories, records, etc.
 *
 * @url https://www.zoho.com/crm/developer/docs/api/v3/
 */
@Injectable({
  providedIn: 'root',
})
export class ZohoCRMApiService implements OnDestroy {
  // #region -> (service basics)

  /** */
  public static readonly API_BASE = 'https://www.zohoapis.eu/crm/v3';

  /** */
  private _cache = new ApiCache<ZohoBaseModel<IZohoCRMAccount | IZohoCRMContact | IZohoCRMNote | IZohoCRMTask>, string>();

  /** */
  constructor(private readonly _httpClient: HttpClient, private readonly _auth: ZohoAuthService) {}

  /** */
  ngOnDestroy(): void {}

  // #endregion

  // #region -> (auth token)

  /** */
  private _access_token$$: Observable<string> = this._auth.is_authenticated$$.pipe(
    switchMap(is_authenticated => {
      if (!is_authenticated) {
        return of(null);
      }

      return this._auth.access_token$$;
    }),
    replay()
  );

  /** */
  private _wait_for_access_token$$ = this._access_token$$.pipe(waitForNotNilValue(), take(1));

  // #endregion

  /** */
  public deserialize(raw_model: IZohoCRMDeal, type: ZohoCRMModuleName.Deals): ZohoCRMDeal;
  public deserialize(raw_model: IZohoCRMNote, type: ZohoCRMModuleName.Notes): ZohoCRMNote;
  public deserialize(raw_model: IZohoCRMContact, type: ZohoCRMModuleName.Tasks): ZohoCRMTask;
  public deserialize(raw_model: IZohoCRMContact, type: ZohoCRMModuleName.Contacts): ZohoCRMContact;
  public deserialize(raw_model: IZohoCRMAccount, type: ZohoCRMModuleName.Accounts): ZohoCRMAccount;
  public deserialize(
    raw_model: IZohoCRMAccount | IZohoCRMContact | IZohoCRMNote | IZohoCRMTask | IZohoCRMDeal,
    type: ZohoCRMModuleName
  ): ZohoBaseModel<IZohoCRMAccount | IZohoCRMContact | IZohoCRMNote | IZohoCRMTask | IZohoCRMDeal> {
    let object: ZohoBaseModel<IZohoCRMAccount | IZohoCRMContact | IZohoCRMNote | IZohoCRMTask | IZohoCRMDeal> = null;

    if (this._cache.has(raw_model?.id)) {
      object = this._cache.get(raw_model?.id);
      object.deserialize(raw_model);

      return object;
    }

    switch (type) {
      case ZohoCRMModuleName.Accounts: {
        object = new ZohoCRMAccount();
        break;
      }

      case ZohoCRMModuleName.Deals: {
        object = new ZohoCRMDeal();
        break;
      }

      case ZohoCRMModuleName.Contacts: {
        object = new ZohoCRMContact();
        break;
      }

      case ZohoCRMModuleName.Notes: {
        object = new ZohoCRMNote();
        break;
      }

      case ZohoCRMModuleName.Tasks: {
        object = new ZohoCRMTask();
        break;
      }
    }

    object.deserialize(raw_model);
    this._cache.add(object.id, object);

    return object;
  }

  // #region -> (create methods)

  // #endregion

  // #region -> (read methods)

  /**
   * Fetch the current logged user.
   *
   * @returns Returns an observable on the current logged user.
   */
  public fetch_current_logged_user$(): Observable<Object> {
    return this._wait_for_access_token$$.pipe(
      switchMap(access_token =>
        this._httpClient.get(`${ZohoCRMApiService.API_BASE}/users?type=CurrentUser`, {
          headers: {
            Authorization: `Zoho-oauthtoken ${access_token}`,
          },
        })
      )
    );
  }

  /** */
  public fetch_record$(record_type: ZohoCRMModuleName.Accounts, record_id: string, fields: string[]): Observable<ZohoCRMAccount>;
  public fetch_record$(record_type: ZohoCRMModuleName.Contacts, record_id: string, fields: string[]): Observable<ZohoCRMContact>;
  public fetch_record$(
    record_type: PickEnum<ZohoCRMModuleName, ZohoCRMModuleName.Accounts | ZohoCRMModuleName.Contacts>,
    record_id: string,
    fields: string[]
  ): Observable<any> {
    return this._wait_for_access_token$$.pipe(
      switchMap(access_token =>
        this._httpClient.get(`${ZohoCRMApiService.API_BASE}/${record_type}/${record_id}`, {
          headers: {
            Authorization: `Zoho-oauthtoken ${access_token}`,
          },
        })
      ),
      map((results: { data: any[] }) => {
        const record = results?.data?.[0];

        if (isNil(record)) {
          return null;
        }

        return this.deserialize(results?.data?.[0], <any>record_type);
      }),
      catchError(() => {
        if (record_type === ZohoCRMModuleName.Accounts) {
          return of(new Error(get_i18n_for_zoho_error(ZohoError.UNDEFINED_ZOHO_CRM_ACCOUNT)));
        }

        if (record_type === ZohoCRMModuleName.Contacts) {
          return of(new Error(get_i18n_for_zoho_error(ZohoError.UNDEFINED_ZOHO_CRM_CONTACT)));
        }

        return of(new Error(get_i18n_for_zoho_error(ZohoError.UNDEFINED_ZOHO_CRM_OBJECT)));
      })
    );
  }

  /** */
  public fetch_notes_of_module$(record_type: ZohoCRMModuleName.Contacts, record_id: string): Observable<ZohoCRMNote[]>;
  public fetch_notes_of_module$(record_type: ZohoCRMModuleName.Accounts, record_id: string): Observable<ZohoCRMNote[]>;
  public fetch_notes_of_module$(
    record_type: PickEnum<ZohoCRMModuleName, ZohoCRMModuleName.Accounts | ZohoCRMModuleName.Contacts>,
    record_id: string
  ) {
    return this._wait_for_access_token$$.pipe(
      switchMap(access_token => {
        const query_fields = ['Created_Time', 'Owner', 'Note_Title', 'Note_Content', 'Parent_Id', 'se_module'];

        return this._httpClient.get(
          `${ZohoCRMApiService.API_BASE}/${record_type}/${record_id}/${ZohoCRMModuleName.Notes}?fields=${query_fields.join(',')}`,
          {
            headers: {
              Authorization: `Zoho-oauthtoken ${access_token}`,
            },
          }
        );
      }),
      map((results: { data: any[] }) => {
        const records = results?.data;

        if (isNil(records) || isEmpty(records)) {
          return null;
        }

        return records?.map(record => this.deserialize(record, ZohoCRMModuleName.Notes));
      }),
      catchError(() => {
        if (record_type === ZohoCRMModuleName.Accounts) {
          return of(new Error(get_i18n_for_zoho_error(ZohoError.UNDEFINED_ZOHO_CRM_ACCOUNT)));
        }

        if (record_type === ZohoCRMModuleName.Contacts) {
          return of(new Error(get_i18n_for_zoho_error(ZohoError.UNDEFINED_ZOHO_CRM_CONTACT)));
        }

        return of(new Error(get_i18n_for_zoho_error(ZohoError.UNDEFINED_ZOHO_CRM_OBJECT)));
      })
    );
  }

  // #endregion

  // #region -> (update methods)

  /** */
  public update_account$(account_id: string, new_data: { exploitation_id?: string }) {
    return this._wait_for_access_token$$.pipe(
      switchMap(access_token =>
        this._httpClient.put(
          `${ZohoCRMApiService.API_BASE}/${ZohoCRMModuleName.Accounts}`,
          {
            data: [
              {
                id: account_id,
                ...new_data,
              },
            ],
          },
          {
            headers: {
              Authorization: `Zoho-oauthtoken ${access_token}`,
            },
          }
        )
      )
    );
  }

  /** */
  public update_contact$(contact_id: string, new_data: { user_id?: string }) {
    return this._wait_for_access_token$$.pipe(
      switchMap(access_token =>
        this._httpClient.put(
          `${ZohoCRMApiService.API_BASE}/${ZohoCRMModuleName.Contacts}`,
          {
            data: [
              {
                id: contact_id,
                ...new_data,
              },
            ],
          },
          {
            headers: {
              Authorization: `Zoho-oauthtoken ${access_token}`,
            },
          }
        )
      )
    );
  }

  // #endregion

  // #region -> (delete methods)

  // #endregion

  // #region -> (search methods)

  /** */
  private readonly DEFAULT_SEARCH_OPTIONAL_PARAMS = { per_page: 10, fields: <string[]>[] };

  /** */
  public search_in_records(
    module_name: ZohoCRMModuleName.Tasks,
    criteria: string,
    optional_params?: { per_page?: number }
  ): Observable<{ data: ZohoCRMTask[]; info: { per_page: number; count: number; page: number; more_records: boolean } }>;
  public search_in_records(
    module_name: ZohoCRMModuleName.Contacts,
    criteria: string,
    optional_params?: { per_page?: number }
  ): Observable<{ data: ZohoCRMContact[]; info: { per_page: number; count: number; page: number; more_records: boolean } }>;
  public search_in_records(
    module_name: ZohoCRMModuleName.Deals,
    criteria: string,
    optional_params?: { per_page?: number }
  ): Observable<{ data: ZohoCRMDeal[]; info: { per_page: number; count: number; page: number; more_records: boolean } }>;
  public search_in_records(
    module_name: ZohoCRMModuleName.Accounts,
    criteria: string,
    optional_params?: { per_page?: number }
  ): Observable<{ data: ZohoCRMAccount[]; info: { per_page: number; count: number; page: number; more_records: boolean } }>;
  public search_in_records(
    module_name: PickEnum<
      ZohoCRMModuleName,
      ZohoCRMModuleName.Contacts | ZohoCRMModuleName.Accounts | ZohoCRMModuleName.Tasks | ZohoCRMModuleName.Deals
    >,
    criteria: string,
    optional_params?: { per_page?: number }
  ): Observable<{
    data: ZohoBaseModel<IZohoCRMAccount | IZohoCRMContact | IZohoCRMTask | IZohoCRMDeal>[];
    info: { per_page: number; count: number; page: number; more_records: boolean };
  }> {
    return <any>this._wait_for_access_token$$.pipe(
      switchMap(access_token => {
        if (isNil(criteria) || isEmpty(criteria)) {
          return of([]);
        }

        const merged_optional_params = merge({}, this.DEFAULT_SEARCH_OPTIONAL_PARAMS, optional_params);

        const optional_parameters_string = ''.concat(
          merged_optional_params?.per_page ? '&per_page=' + merged_optional_params?.per_page : ''
        );

        return this._httpClient
          .get<{
            data: (IZohoCRMAccount | IZohoCRMContact | IZohoCRMTask)[];
            info: { per_page: number; count: number; page: number; more_records: boolean };
          }>(`${ZohoCRMApiService.API_BASE}/${module_name}/search?criteria=${criteria}${optional_parameters_string}`, {
            headers: {
              Authorization: `Zoho-oauthtoken ${access_token}`,
            },
          })
          .pipe(
            map(response => {
              if (isNil(response)) {
                return { data: [] };
              }

              const deserialized_data = response?.data?.map(datum => this.deserialize(<any>datum, <any>module_name));

              return {
                data: deserialized_data,
                info: response?.info,
              };
            })
          );
      })
    );
  }

  // #endregion
}
