import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { BehaviorSubject, map, Observable, ReplaySubject, switchMap } from 'rxjs';
import {
  create_replay_subject_with_first_value,
  distinctUntilRealChanged,
  replay,
  waitForNotNilValue,
} from '@bg2app/tools/rxjs';

import { AppStateService } from 'app/core/app-state.service';

import { DRDevice, Hive, LocationEntityUserAce } from 'app/models';

@Component({
  selector: 'bg2-apiary-last-data-card-hive',
  templateUrl: './apiary-last-data-card-hive.component.html',
  styleUrls: ['./apiary-last-data-card-hive.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ApiaryLastDataCardHiveComponent {
  // #region -> (component basics)

  /**
   * Observes the application's current language.
   */
  public language$$ = this._appState.lang$$;

  /** */
  constructor(private _appState: AppStateService) {}

  /** */
  public track_by_device(index: number, device: DRDevice) {
    return device.imei;
  }

  // #endregion

  // #region -> (hive entity)

  /** */
  private _hive$$: ReplaySubject<Hive> = create_replay_subject_with_first_value<Hive>(null);

  /** */
  public hive$$: Observable<Hive> = this._hive$$.asObservable().pipe(waitForNotNilValue());

  /** */
  @Input()
  public set hive(hive: Hive) {
    this._hive$$.next(hive);
  }

  /** */
  public is_hive_is_ghost$$ = this.hive$$.pipe(switchMap(hive => hive.is_ghost$$));

  /** */
  public user_can_x_hive$$ = (ace: keyof typeof LocationEntityUserAce) =>
    this.hive$$.pipe(
      switchMap(hive => hive.user_acl.can$$(ace)),
      distinctUntilRealChanged(),
      replay()
    );

  // #endregion

  // #region -> (inner carousel management)

  /** */
  private _current_index$$ = new BehaviorSubject<number>(0);

  /** */
  public translate_x$$ = this._current_index$$.pipe(
    distinctUntilRealChanged(),
    map(current_index => `translateX(${current_index * 100 * -1}%)`)
  );

  /** */
  public get current_index() {
    return this._current_index$$.getValue();
  }

  /** */
  public set current_index(current_index) {
    this._current_index$$.next(current_index);
  }

  // #endregion
}
