<bg2-modal [fullscreen]="fullscreen ?? false">
  <bg2-modal-header>
    <div class="header">
      <span class="mdi mdi-cursor-move mdi-36px"></span>
      <span class="title">{{ 'ROUTABLE_MODALS.DEVICE_MOVE_AUTH.Device move auth' | translate }}</span>
    </div>

    <div class="action-buttons">
      <span class="mdi mdi-close-box mdi-36px" (click)="close()"></span>
    </div>
  </bg2-modal-header>

  <bg2-modal-content>
    <div>
      <bg2-button
        [type]="'action'"
        icon="mdi-plus"
        [message]="'ROUTABLE_MODALS.DEVICE_MOVE_AUTH.Add move auth' | translate"
        (btn_click)="create_authorized_movement()"
      ></bg2-button>

      <div class="select-view-type">
        <mat-button-toggle-group (change)="view_mode = $event.value" [value]="view_mode$$ | async">
          <mat-button-toggle value="table">
            <i class="mdi mdi-table mdi-18px"></i>
            <span class="hide-on-small-and-down">{{ 'ROUTABLE_MODALS.DEVICE_MOVE_AUTH.VIEW_TYPE.Table' | translate }}</span>
          </mat-button-toggle>

          <mat-button-toggle value="calendar">
            <i class="mdi mdi-calendar mdi-18px"></i>
            <span class="hide-on-small-and-down">{{ 'ROUTABLE_MODALS.DEVICE_MOVE_AUTH.VIEW_TYPE.Calendar' | translate }}</span>
          </mat-button-toggle>
        </mat-button-toggle-group>
      </div>
    </div>

    <ng-container [ngSwitch]="view_mode$$ | async">
      <bg2-device-authorized-movements-modal-calendar-view
        *ngSwitchCase="'calendar'"
        [authorized_movements_by_device]="authorized_movements_by_device$$ | async"
        (reload_data)="reload()"
        ></bg2-device-authorized-movements-modal-calendar-view>
        
        <bg2-device-authorized-movements-modal-table-view
        *ngSwitchCase="'table'"
        [activate_groups]="(imeis$$ | async).length > 1"
        [authorized_movements_by_device]="authorized_movements_by_device$$ | async"
        (reload_data)="reload()"
      ></bg2-device-authorized-movements-modal-table-view>
    </ng-container>
  </bg2-modal-content>
</bg2-modal>
