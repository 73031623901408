// From @node_modules/rxjs/*
import { Observable, of } from 'rxjs';
import { switchMap, map } from 'rxjs';

// From @node_modules/@ngx-translate/*
import { TranslateService } from '@ngx-translate/core';

// From @app/models/*
import { Event, I18NParams } from './Event';

// From @app/core/*
import { Beeguard2Api } from 'app/core';

export class LocationPayment extends Event {

  constructor(protected bg2Api: Beeguard2Api) {
    super(bg2Api);
    this.type = 'location_payment';
  }

  protected getI18nParams(translate_service: TranslateService, entities: any): Observable<I18NParams> {
    // Get translation for 'feeding_type'
    return super.getI18nParams(translate_service, entities).pipe(
      switchMap((i18nParams: I18NParams) => {
        const desc_obs: Observable<I18NParams> = translate_service.stream(i18nParams.data['nature']).pipe(
          map((nature: string) => {
            i18nParams.data['nature'] = translate_service.instant(`EVENT.LOCATION_PAYMENT.PAYMENT_TYPE.${nature.toLowerCase()}`);
            return i18nParams;
          })
        );
        return desc_obs;
      }
    ));
  }

}
