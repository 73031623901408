<div class="device-bat-changes-component">
  <div class="device-bat-changes-header">
    <span style="font-size: 1.1em; font-weight: 700">
      {{ 'VIEWS_WINDOWED.MODALS.DEVICE_MODAL.Device battery changes' | translate }}
    </span>

    <div *ngIf="can_add" class="create-new-bat-change-container">
      <bg2-button
        [type]="'action'"
        [icon]="'mdi-plus'"
        [options]="{ is_thin: true }"
        [message]="'VIEWS_WINDOWED.MODALS.DEVICE_MODAL.Add battery change' | translate"
        (btn_click)="add()"
      ></bg2-button>
    </div>
  </div>

  <div class="bg2-grid-wrapper">
    <div class="bg2-grid-container" [ngClass]="{ empty: data_source?.data?.length === 0 }">
      <ng-container *ngIf="loading$$ | async">
        <mat-progress-bar class="top-loading" [mode]="'indeterminate'"></mat-progress-bar>
      </ng-container>

      <table
        mat-table
        [dataSource]="data_source"
        matSort
        [matSortActive]="sort__active$$ | async"
        [matSortDirection]="sort__direction$$ | async"
        (matSortChange)="onSortData($event)"
      >
        <!-- #region .headers-and-cells-templates -->
        <ng-container *ngFor="let column of flattened_columns$$ | async; trackBy: track_by_column" [matColumnDef]="column.property">
          <!-- #region .header-definitions -->
          <th
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header
            class="{{ column_sort_type(column) }}"
            [disabled]="!column?.is_sortable"
            [@.disabled]="true"
          >
            <ng-container [ngSwitch]="column.property">
              <!-- Column type: "select" -->
              <ng-container *ngSwitchCase="'select'">
                <mat-checkbox
                  [color]="'primary'"
                  (change)="$event ? masterToggle() : null"
                  [disabled]="loading$$ | async"
                  [ngModel]="all_selected$$ | async"
                  [indeterminate]="(some_selected$$ | async) && (all_selected$$ | async) === false"
                ></mat-checkbox>
              </ng-container>

              <!-- Default column header -->
              <ng-container *ngSwitchDefault>
                {{ column.label | translate }}
              </ng-container>
            </ng-container>
          </th>
          <!-- #endregion -->

          <!-- #region .content-definitions -->
          <td
            mat-cell
            *matCellDef="let row_or_group; dataSource: data_source"
            class="col-{{ column.property }}"
            [attr.data-label]="column?.label | translate"
            [class.selected]="isSelected$$(row_or_group) | async"
            (click)="column?.property === 'select' ? selection_model.toggle(row_or_group) + $event.stopPropagation() : null"
          >
            <ng-container [ngSwitch]="column.property">
              <!-- Column type: "select" -->
              <ng-container *ngSwitchCase="'select'">
                <mat-checkbox
                  [color]="'primary'"
                  [disabled]="loading$$ | async"
                  (click)="$event.stopPropagation()"
                  (change)="$event ? selection_model.toggle(row_or_group) : null"
                  [checked]="isSelected$$(row_or_group) | async"
                ></mat-checkbox>
              </ng-container>

              <ng-container *ngIf="is_row(row_or_group); let row">
                <!-- Column type: "bat_change.type" -->
                <ng-container *ngSwitchCase="'bat_change.type'">
                  <div *ngLet="row?.bat_change; let bat_change" class="bat-change-type">
                    <ng-container *ngIf="bat_change?.tags?.origin === 'device_consumption_base'">
                      <span
                        class="mdi mdi-24px"
                        [ngClass]="{
                          '--confirmed': bat_change?.fields?.confirmed,
                          'mdi-cloud-check': bat_change?.fields?.confirmed,
                          'mdi-cloud-question': !bat_change?.fields?.confirmed
                        }"
                      ></span>

                      <div class="bat-change-type__label">
                        <span>{{ 'VIEWS.DEVICES.SHARED.DEVICE_BAT_CHANGES.Added automatically' | translate }}</span>

                        <span *ngIf="!bat_change?.fields?.confirmed">
                          ({{ 'VIEWS.DEVICES.SHARED.DEVICE_BAT_CHANGES.Not manually confirmed yet' | translate }})
                        </span>

                        <span *ngIf="bat_change?.fields?.confirmed">
                          ({{ 'VIEWS.DEVICES.SHARED.DEVICE_BAT_CHANGES.Manually confirmed' | translate }})
                        </span>
                      </div>
                    </ng-container>

                    <ng-container *ngIf="bat_change?.tags?.origin !== 'device_consumption_base'">
                      <span
                        class="mdi mdi-24px"
                        [ngClass]="{
                          '--confirmed': bat_change?.fields?.confirmed,
                          'mdi-account-check': bat_change?.fields?.confirmed,
                          'mdi-account-question': !bat_change?.fields?.confirmed
                        }"
                      ></span>

                      <div class="bat-change-type__label">
                        <span>{{ 'VIEWS.DEVICES.SHARED.DEVICE_BAT_CHANGES.Added manually' | translate }}</span>

                        <span *ngIf="!bat_change?.fields?.confirmed">
                          ({{ 'VIEWS.DEVICES.SHARED.DEVICE_BAT_CHANGES.Not confirmed yet' | translate }})
                        </span>

                        <span *ngIf="bat_change?.fields?.confirmed">
                          ({{ 'VIEWS.DEVICES.SHARED.DEVICE_BAT_CHANGES.Confirmed' | translate }})
                        </span>
                      </div>
                    </ng-container>
                  </div>
                </ng-container>

                <!-- Column type: "bat_change.date" -->
                <ng-container *ngSwitchCase="'bat_change.date'">
                  <span *ngLet="row?.bat_change; let bat_change">{{ bat_change.time | formatDate : 'full' }}</span>
                </ng-container>

                <!-- Column type: "bat_change.date" -->
                <ng-container *ngSwitchCase="'bat_change.actions'">
                  <div *ngLet="row?.bat_change; let bat_change" class="bat-change-type__actions">
                    <bg2-button
                      *ngIf="bat_change.fields.confirmed"
                      [type]="'cancel'"
                      [options]="{ is_thin: true }"
                      [popper]="'VIEWS_WINDOWED.MODALS.DEVICE_MODAL.Not confirmed' | translate"
                      [icon]="bat_change?.tags?.origin === 'device_consumption_base' ? 'mdi-cloud-question' : 'mdi-account-question'"
                      (btn_click)="unConfirm(bat_change)"
                    ></bg2-button>

                    <bg2-button
                      *ngIf="!bat_change.fields.confirmed"
                      [type]="'action'"
                      [icon]="'mdi-check'"
                      [options]="{ is_thin: true }"
                      [popper]="'VIEWS_WINDOWED.MODALS.DEVICE_MODAL.Confirm' | translate"
                      (btn_click)="confirm(bat_change)"
                    ></bg2-button>

                    <bg2-button
                      [type]="'delete'"
                      [icon]="'mdi-delete'"
                      [options]="{ is_thin: true }"
                      [popper]="'VIEWS.MODALS.FORM.Delete' | translate"
                      (btn_click)="delete(bat_change)"
                    ></bg2-button>
                  </div>
                </ng-container>

                <!-- Column other cols -->
                <ng-container *ngSwitchDefault>
                  <span>{{ get(row_or_group, column.property) }}</span>
                </ng-container>
              </ng-container>
            </ng-container>
          </td>
          <!-- #endregion -->
        </ng-container>
        <!-- #endregion -->

        <!-- #region .specific-templates -->
        <ng-container matColumnDef="group-header">
          <td mat-cell *matCellDef="let row_or_group; dataSource: data_source" [colSpan]="(displayed_columns$$ | async).length">
            <ng-container *ngIf="is_group(row_or_group); let group">
              <span
                class="header-content mdi mdi-chevron-{{ group.expanded ? 'up' : 'down' }} mdi-24px"
                [style.padding-left.px]="(group.level - 1) * 24"
              >
                <ng-container [ngSwitch]="group.last_property">
                  <ng-container *ngSwitchDefault>
                    {{ group.label | translate }} : {{ group.last_col_value }} ({{ group.totalCounts }})
                  </ng-container>
                </ng-container>
              </span>
            </ng-container>
          </td>
        </ng-container>
        <!-- #endregion -->

        <!-- #region .row-definitions -->
        <tr mat-header-row *matHeaderRowDef="displayed_columns$$ | async"></tr>
        <tr
          mat-row
          *matRowDef="let device_row; columns: displayed_columns$$ | async"
          class="final-row"
          [class.selected]="isSelected$$(device_row) | async"
        ></tr>

        <tr mat-row *matRowDef="let row; columns: ['group-header']; when: isGroup" (click)="groupHeaderClick(row)"></tr>
        <!-- #endregion -->
      </table>
    </div>

    <!-- Empty grid message -->
    <div *ngIf="data_source?.data.length === 0 && (loading$$ | async) === false" class="empty-grid-message">
      <div class="text">
        {{ 'VIEWS.DEVICES.SHARED.DEVICE_BAT_CHANGES.No battery change detected on this device' | translate }}
      </div>
    </div>

    <!-- Grid pagination -->
    <mat-paginator
      *ngLet="paging$$ | async; let paging"
      showFirstLastButtons
      [pageSize]="5"
      [pageSizeOptions]="[5]"
      [length]="paging?.total"
      [disabled]="loading$$ | async"
      [pageIndex]="paging?.offset / 5"
      (page)="onPageChanged($event)"
    ></mat-paginator>
  </div>
</div>
