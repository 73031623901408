import {
  Component,
  OnInit,
  OnDestroy,
  AfterViewInit,
  ChangeDetectionStrategy,
  NgZone,
  ComponentFactoryResolver,
  Injector,
  ComponentRef,
} from '@angular/core';

import { FormProperty } from 'ngx-schema-form';

import {
  of,
  Subscription,
  Observable,
  BehaviorSubject,
  combineLatest,
  filter,
  map,
  tap,
  distinctUntilChanged,
  switchMap,
  take,
  debounceTime,
  catchError,
  concat,
  Subject,
} from 'rxjs';
import {
  create_replay_subject_with_first_value,
  distinctUntilKeysChanged,
  distinctUntilRealChanged,
  replay,
  waitForNotNilValue,
} from '@bg2app/tools/rxjs';

import * as L from 'leaflet';

import { assign, cloneDeep, has, isEmpty, isNil, merge, pick } from 'lodash-es';

import { Beeguard2Api } from 'app/core';
import { ConsoleLoggerService } from 'app/core/console-logger.service';

import { DRDevice, Exploitation, Warehouse, Location, Entity, SimpleSetterGetter } from 'app/models';
import { generateRandomPoint, groupMarkersByType, unifyPolyCircles } from 'app/misc/tools/geomap';
import { AbstractMarker, CONF_CIRCLE_COLORS, DeviceMarker, LocationMarker, locationMarker } from 'app/typings/mapping';

import { EfObjectWidgetComponent } from '../object/object.widget';
import { Bg2MapPopupAllClusterComponent } from 'app/views/map/shared/html-map-popups/map-popup-all-cluster/map-popup-all-cluster.component';
import { Bg2MapPopupDeviceMarkerComponent } from 'app/views/map/shared/html-map-popups/map-popup-device-marker/map-popup-device-marker.component';
import { tzLookup } from 'app/misc/tools/misc/suncalc/tzLookup';

/** */
interface IGeoPositionData {
  /** */
  latitude: number;

  /** */
  longitude: number;

  /** */
  address?: string;

  /** */
  elevation?: number;

  /** */
  timezone?: string;

  /** */
  approximate?: {
    /** */
    latitude: number;

    /** */
    longitude: number;
  };
}

interface GeoposPopups {
  cluster_info: {
    popup: L.Popup;
    cmp: ComponentRef<Bg2MapPopupAllClusterComponent>;
  };
  device_marker_info: {
    popup: L.Popup;
    cmp: ComponentRef<Bg2MapPopupDeviceMarkerComponent>;
  }[];
}

@Component({
  selector: 'bg2-ef-geopos-widget',
  templateUrl: './geopos.widget.html',
  styleUrls: ['./geopos.widget.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EfGeoposWidgetComponent extends EfObjectWidgetComponent implements OnInit, OnDestroy, AfterViewInit {
  // #region -> (component basics)

  /**
   * The position of BeeGuard.
   */
  private readonly BEEGUARD_COORD = L.latLng(43.54589, 1.50392, 146);

  /**
   * Reference to the logger for the component.
   */
  private readonly LOGGER = new ConsoleLoggerService('EfGeoposWidget', true);

  /**
   * Reference to the google geocoding service.
   */
  private readonly _google_geocoder = new google.maps.Geocoder();

  /**
   * Reference to the google elevation service.
   */
  private readonly _google_elevation = new google.maps.ElevationService();

  /** */
  public eid: any = null;

  /** */
  protected is_default_value = true;

  /** */
  private _location_marker_pos_sub: Subscription = null;
  private _altitude_tracked_changed_sub: Subscription = null;
  private _timezone_tracked_changed_sub: Subscription = null;
  private _location_approx_marker_pos_sub: Subscription = null;
  private _initial_approximate_activated_sub: Subscription = null;

  constructor(
    private readonly _ngZone: NgZone,
    private readonly _injector: Injector,
    private readonly _bg2Api: Beeguard2Api,
    private readonly _resolver: ComponentFactoryResolver
  ) {
    super(_bg2Api);
  }

  /** */
  ngOnInit(): void {
    this._initial_approximate_activated_sub = this.formProperty.valueChanges.pipe(debounceTime(100), distinctUntilRealChanged()).subscribe({
      next: (data: IGeoPositionData) => {
        const approximate_value = data.approximate;

        if (!isNil(approximate_value)) {
          this.is_approximative_activated = true;
        }
      },
    });

    // Devices
    let devices$: Observable<DRDevice[]> = null;

    // TODO: make the path to the exploitation configurable in schema.options
    const exploitation_property = this.robustSearchProperty('/event/apply_to/exploitation');
    if (this.formProperty.root.schema.args) {
      this.eid = this.formProperty.root.schema.args.eid;
    }

    let expl$: Observable<Exploitation>;

    if (!isNil(exploitation_property) && isNil(this.eid)) {
      expl$ = exploitation_property.valueChanges.pipe(
        distinctUntilChanged(),
        filter((exploitationId: number) => !isNil(exploitationId)),
        switchMap((exploitationId: number) => this._bg2Api.getEntityObj(exploitationId)),
        map((entity: Entity) => entity as Exploitation)
      );
    } else if (!isNil(this.eid)) {
      expl$ = this._bg2Api
        .getEntityObj(this.eid)
        .pipe(switchMap((entity: Location | Exploitation) => (entity instanceof Location ? entity.exploitation$$ : of(entity))));
    }

    if (expl$) {
      devices$ = expl$.pipe(
        tap(() => (this.device_loading = true)),
        switchMap((exploitation: Exploitation) => exploitation.warehouse$$),
        switchMap((warehouse: Warehouse) => warehouse.devices$$),
        map((devices: DRDevice[]) =>
          devices.filter(
            (device: DRDevice) =>
              !isNil(device.location) && !isNil(device.location.latitude) && (device.type === 'GPS' || device.type === 'RG')
          )
        )
      );
    }

    // Subscribe on devices data stream
    if (devices$) {
      this._sub_devices = devices$.subscribe({
        next: (devices: DRDevice[]) => {
          this.device_loading = false;
          this.devices = devices;
        },
        error: (error: unknown) => {
          this.device_loading = false;
          console.error(error);
        },
      });
    }

    this.has_address = has(this.formProperty.schema.properties, 'address');
    this.has_timezone = has(this.formProperty.schema.properties, 'timezone');
    this.has_elevation = has(this.formProperty.schema.properties, 'elevation');

    this.is_altitude_tracked_getset.value = this.has_elevation && isNil(this.formProperty.value.elevation);
    this.is_timezone_tracked.value = this.has_timezone && isNil(this.formProperty?.value?.timezone);

    if (this.has_timezone) {
      this.watch_timezone_tracked();
    }

    // Track elevation change
    if (this.has_elevation) {
      this.watch_altitude_tracked();

      // this._elevation_sub = this.formProperty
      //   .getProperty('elevation')
      //   .valueChanges.pipe(distinctUntilChanged())
      //   .subscribe((elevation: number) => {
      //     console.log(elevation);
      //     if (!this.elevation_internal_change && !isNil(elevation)) {
      //       this.is_altitude_tracked_getset.value = false;
      //     } else if (isNil(elevation)) {
      //       this.is_altitude_tracked_getset.value = true;
      //     }
      //     this.elevation_internal_change = false;
      //   });
    }

    this._current_position_sub = this.track_map_position_changed().subscribe({
      next: data => {
        if (!isNil(data.elevation)) {
          this.set_elevation(data.elevation);
        }

        if (!isNil(data.address) && this.address_tracked) {
          if (isNil(this?.formProperty?.value?.address)) {
            this.set_form_address(data.address);
          } else {
            this.tmp_form_address = data.address;
          }
        }

        if (this.is_timezone_tracked.value) {
          if (!isNil(data?.new_position?.latitude) && !isNil(data?.new_position?.longitude)) {
            this.set_timezone(data.timezone);
          }
        }

        this.set_form_position(L.latLng(data?.new_position?.latitude, data?.new_position?.longitude));
      },
    });
  }

  /** */
  ngAfterViewInit(): void {
    super.ngAfterViewInit();

    const initial_value = cloneDeep(this.formProperty.value);

    setTimeout(() => {
      if (isNil(initial_value.latitude) && isNil(initial_value.address)) {
        this.computeDefault();
      } else {
        this.is_default_value = false;
      }
    }, 100);
  }

  /** */
  ngOnDestroy(): void {
    this.valid_sub?.unsubscribe();
    this._address_sub?.unsubscribe();
    this._sub_devices?.unsubscribe();
    this._elevation_sub?.unsubscribe();
    this._default_value_sub?.unsubscribe();
    this._current_position_sub?.unsubscribe();
    this._altitude_tracked_changed_sub?.unsubscribe();
    this._timezone_tracked_changed_sub?.unsubscribe();

    // Destory cluster info popup
    this.leaflet_popup_cmps.cluster_info.cmp?.destroy();
    this.leaflet_popup_cmps.cluster_info.cmp = null;

    // Destroy devices popups
    this.leaflet_popup_cmps.device_marker_info.forEach((ct: any, idx: number) => {
      if (this.leaflet_popup_cmps.device_marker_info?.[idx]?.cmp) {
        this.leaflet_popup_cmps.device_marker_info[idx].cmp.destroy();
        this.leaflet_popup_cmps.device_marker_info[idx].cmp = null;
      }
    });

    this._location_marker_pos_sub?.unsubscribe();
    this._location_approx_marker_pos_sub?.unsubscribe();
    this._initial_approximate_activated_sub?.unsubscribe();
  }

  // #endregion

  // #region -> (form management)

  /** */
  private get form_props__approximate(): FormProperty {
    return this.formProperty?.getProperty('approximate') ?? null;
  }

  /** */
  private get form_props__address(): FormProperty {
    return this.formProperty?.getProperty('address') ?? null;
  }

  /** */
  public get has_form_approximate_prop(): boolean {
    return !isNil(this.form_props__approximate);
  }

  // #endregion

  // #region -> (track methods)

  /** */
  private _wait_for_initialization$$ = new BehaviorSubject<IGeoPositionData>(null);

  /** */
  private wait_for_initialization$$ = this._wait_for_initialization$$.asObservable().pipe(waitForNotNilValue());

  /** */
  private _current_position_changed$$: Observable<{
    address: string;
    elevation: number;
    timezone: string;
    new_position: { latitude: number; longitude: number };
  }> = null;

  /** */
  private track_map_position_changed(): Observable<{
    address: string;
    elevation: number;
    timezone: string;
    new_position: {
      latitude: number;
      longitude: number;
    };
  }> {
    if (!isNil(this._current_position_changed$$)) {
      return this._current_position_changed$$;
    }

    this._current_position_changed$$ = this.formProperty.valueChanges.pipe(
      switchMap((position_data: IGeoPositionData) => {
        if (
          this.has_address &&
          !isNil(position_data?.address) &&
          isNil(position_data?.latitude) &&
          isNil(position_data?.longitude) &&
          this.address_tracked
        ) {
          return this.fetch_position_from_address(position_data?.address).pipe(
            map(new_position => assign(cloneDeep(position_data), new_position))
          );
        }

        return of(position_data);
      }),
      tap(data => this._wait_for_initialization$$.next(data)),
      map<IGeoPositionData, { latitude: number; longitude: number }>(value => pick(value, ['latitude', 'longitude'])),
      filter(value => !isNil(value?.latitude) && !isNil(value?.longitude)),
      debounceTime(400),
      switchMap(new_position =>
        combineLatest([of(new_position), this.is_altitude_tracked_getset.value$$, this.is_timezone_tracked.value$$])
      ),
      distinctUntilRealChanged(),
      switchMap(([new_position, is_elevation_tracked, is_timezone_tracked]) => {
        const updates = {
          new_position: of(new_position),
          timezone: of(null) as Observable<string>,
          elevation: of(null) as Observable<number>,
          address: this.leaflet_map$$.pipe(switchMap(() => this.fetch_address_from_position(new_position))),
        };

        if (this.has_elevation && is_elevation_tracked) {
          updates.elevation = this.leaflet_map$$.pipe(switchMap(() => this.fetch_elevation_from_position(new_position)));
        }

        if (this.has_timezone && is_timezone_tracked) {
          updates.timezone = of(tzLookup(new_position.latitude, new_position.longitude));
        }

        return combineLatest(updates);
      })
    );

    return this._current_position_changed$$;
  }

  // #endregion

  // #region -> (fields management)

  // #endregion

  // #region -> (fields management / exclusive to address)

  /** */
  public has_address = false;

  /** */
  public address_tracked = true;

  /** */
  protected _address_sub: Subscription;

  /** */
  private _is_searching_geopos_from_address$ = create_replay_subject_with_first_value(false);

  /** */
  public is_searching_geopos_from_address$$ = this._is_searching_geopos_from_address$.asObservable();

  /** */
  public search_position_from_address(): void {
    this._is_searching_geopos_from_address$.next(true);
    const address_value = this.formProperty.value.address as string;

    of(address_value)
      .pipe(
        switchMap(address => this.fetch_position_from_address(address)),
        catchError((error: unknown) => {
          this.LOGGER.error(error);
          return of(null);
        }),
        filter(val => !isNil(val)),
        take(1)
      )
      .subscribe({
        next: position => {
          this.set_form_position(L.latLng(position?.latitude, position?.longitude));
        },
        complete: () => {
          this._is_searching_geopos_from_address$.next(false);
        },
      });
  }

  /** */
  private fetch_address_from_position(position: IGeoPositionData): Observable<string> {
    if (isEmpty(position) || isNil(position)) {
      return of<string>(null);
    }

    return new Observable<string>(observer => {
      this._google_geocoder.geocode(
        {
          location: new google.maps.LatLng(position.latitude, position.longitude),
        },
        (results, status) => {
          if (status === google.maps.GeocoderStatus.OK) {
            if (results[1]) {
              observer.next(results[1].formatted_address);
              observer.complete();
            } else {
              observer.error('No address found');
            }
          } else {
            observer.error('Address/geocode service failed due to: ' + status);
          }
        }
      );
    });
  }

  /** */
  private set_form_address(new_address: string): void {
    if (isNil(this.form_props__address)) {
      return;
    }

    this.form_props__address.setValue(new_address, false);
  }

  /** */
  private _tmp_form_address$ = create_replay_subject_with_first_value(null);

  /** */
  public tmp_form_address$$ = this._tmp_form_address$.asObservable().pipe(distinctUntilRealChanged());

  /** */
  private set tmp_form_address(address: string) {
    const actual_address = this.form_props__address.value;

    if (actual_address === address) {
      this._tmp_form_address$.next(null);
    } else {
      this._tmp_form_address$.next(address);
    }
  }

  /** */
  public apply_tmp_for_address(): void {
    this.tmp_form_address$$.pipe(take(1)).subscribe({
      next: address => {
        this.set_form_address(address);
        this.tmp_form_address = null;
      },
    });
  }

  // #endregion

  // #region -> (fields management / exclusive to map)

  /** */
  private fetch_position_from_address(address: string): Observable<IGeoPositionData> {
    return new Observable<IGeoPositionData>(observer => {
      this._google_geocoder.geocode({ address }, (results, status) => {
        if (status === google.maps.GeocoderStatus.OK) {
          const loc = results[0].geometry.location;

          observer.next({ latitude: loc.lat(), longitude: loc.lng() });
          observer.complete();
        } else {
          const latlng_re =
            /(^[-+]?(?:[1-8]?\d(?:\.\d+)?|90(?:\.0+)?)),\s*([-+]?(?:180(?:\.0+)?|(?:(?:1[0-7]\d)|(?:[1-9]?\d))(?:\.\d+)?))$/;
          const latlng_match = address.match(latlng_re);
          if (latlng_match) {
            this.LOGGER.info('GPS possition matching use it direcly');
            observer.next({ latitude: +latlng_match[1], longitude: +latlng_match[2] });
            observer.complete();
          } else {
            observer.error('Address/geocode service failed due to: ' + status);
          }
        }
      });
    });
  }

  /** */
  private set_form_position(latlng: L.LatLng): void {
    if (this.leaflet_map && !isNil(latlng)) {
      this.leaflet_map.panTo(latlng);
      this._location_marker.setLatLng(latlng);
    }

    this.formProperty.setValue(
      { latitude: latlng?.lat ?? this.BEEGUARD_COORD.lat, longitude: latlng?.lng ?? this.BEEGUARD_COORD.lng } as Partial<IGeoPositionData>,
      false
    );
  }

  /** */
  public onLeafletMapReady(leaflet_map: L.Map): void {
    this.leaflet_map = leaflet_map;
    this.leaflet_map.addControl(L.control.zoom({ position: 'bottomright' }));

    this.wait_for_initialization$$.pipe(take(1)).subscribe({
      next: initial_position => {
        // Setup markers
        this.setupLocationMarker(initial_position);
        this.setupApproximativeMarker();

        // Setup map events
        this.leaflet_map.on('click', (event: L.LeafletMouseEvent) => this.set_form_position(event.latlng.wrap()));

        if (this.form_props__approximate && this.form_props__approximate.value.latitude) {
          this.is_approximative_activated = true;
        }

        // Setup base cluster
        this.setupBaseCluster();
        this.updateMapView();
      },
    });
  }

  // #endregion

  // #region -> (fields management / exclusive to elevation)

  /** */
  public has_elevation = false;

  /** */
  protected _elevation_sub: Subscription;

  /** */
  private elevation_internal_change = false; // flag True when internal change

  /** */
  protected is_altitude_tracked_getset = new SimpleSetterGetter(true);

  /**
   * Updates the form property elevation field.
   *
   * @param altitude The new value to set.
   */
  private set_elevation(altitude: number): void {
    this.elevation_internal_change = true;
    this.formProperty.setValue(merge({}, this.formProperty.value, { elevation: altitude }), false);
  }

  /**
   * Fetches the elevation for a specific position.
   *
   * @param position Position to use for elevation.
   * @returns One-shot observable on the elevation.
   */
  private fetch_elevation_from_position(position: IGeoPositionData): Observable<number> {
    return new Observable<number>(observer => {
      this._google_elevation.getElevationForLocations(
        {
          locations: [new google.maps.LatLng(position.latitude, position.longitude)],
        },
        (results, status) => {
          if (status === google.maps.ElevationStatus.OK) {
            if (results[0]) {
              observer.next(Math.round(results[0].elevation));
              observer.complete();
            } else {
              observer.error('No elevation found');
            }
          } else {
            observer.error('Elevation service failed due to: ' + status);
          }
        }
      );
    });
  }

  /** */
  private watch_altitude_tracked(): void {
    this._altitude_tracked_changed_sub = this.is_altitude_tracked_getset.value$$.pipe(distinctUntilRealChanged()).subscribe({
      next: is_altitude_tracked => {
        this.schema.properties.elevation.readOnly = is_altitude_tracked;
      },
    });
  }

  // #endregion

  // #region -> (fields management / exclusive to timezone)

  /** */
  public has_timezone = false;

  /** */
  protected is_timezone_tracked = new SimpleSetterGetter(false);

  /** */
  private set_timezone(timezone: string): void {
    this.formProperty.setValue(merge({}, this.formProperty.value, { timezone }), false);
  }

  /** */
  private watch_timezone_tracked(): void {
    this._timezone_tracked_changed_sub = this.is_timezone_tracked.value$$.pipe(distinctUntilRealChanged()).subscribe({
      next: is_timezone_tracked => {
        this.schema.properties.timezone.readOnly = is_timezone_tracked;
      },
    });
  }

  // #endregion

  // #region ->  (devices management)

  private leaflet_popup_cmps: GeoposPopups = {
    cluster_info: {
      popup: L.popup({ closeButton: true }),
      cmp: null,
    },
    device_marker_info: [],
  };

  public device_loading = false;
  private _sub_devices: Subscription;

  private _show_all_devices$$ = new BehaviorSubject(false);
  public show_all_devices$$ = this._show_all_devices$$.asObservable().pipe(distinctUntilChanged(), replay());

  public set show_all_devices(show_all_devices: boolean) {
    this._show_all_devices$$.next(show_all_devices);
  }

  public get show_all_devices(): boolean {
    return this._show_all_devices$$.getValue();
  }

  private _devices$$: BehaviorSubject<DRDevice[]> = new BehaviorSubject([]);
  public devices$$: Observable<DRDevice[]> = this._devices$$.asObservable();

  private set devices(devices: DRDevice[]) {
    this._devices$$.next(devices);
  }

  private get devices(): DRDevice[] {
    return this._devices$$.getValue();
  }

  private _devices_markers$$: Observable<L.LayerGroup<DeviceMarker>> = combineLatest([this.devices$$, this.show_all_devices$$]).pipe(
    map(([devices, show_devices]) => {
      // Prepare required conditions
      const has_devices = (devices || []).length > 0;

      // Clear old components
      this.leaflet_popup_cmps.device_marker_info.forEach((val: any, idx: any) => {
        if (!isNil(this.leaflet_popup_cmps.device_marker_info[idx].cmp)) {
          val.cmp.destroy();
          this.leaflet_popup_cmps.device_marker_info[idx].cmp = null;
        }
      });

      if (!has_devices || !show_devices) {
        return L.layerGroup([]);
      }

      // Builds up markers for each valid device
      const markers = devices
        .filter((device: DRDevice) => !isNil(device.location))
        .map((device: DRDevice) => {
          const device_marker = new DeviceMarker(device);

          // Build-up popup content component
          const component = this._resolver.resolveComponentFactory(Bg2MapPopupDeviceMarkerComponent).create(this._injector);
          device_marker.bindPopup(component.location.nativeElement, { closeButton: true });

          component.instance.self_popup_ref = device_marker.getPopup();
          component.instance.marker = device_marker;
          component.changeDetectorRef.detectChanges();

          this.leaflet_popup_cmps.device_marker_info.push({ popup: device_marker.getPopup(), cmp: component });

          return device_marker;
        });

      return L.layerGroup(markers);
    }),
    replay()
  );

  // #endregion

  // #region -> (map field management)

  private _location_marker: LocationMarker;
  private _approximative_marker: LocationMarker;

  private _leaflet_map$$ = new BehaviorSubject<L.Map>(null);
  private leaflet_map$$ = this._leaflet_map$$.asObservable().pipe(waitForNotNilValue(), take(1), replay());

  private get leaflet_map(): L.Map {
    return this._leaflet_map$$.getValue();
  }

  private set leaflet_map(lmap: L.Map) {
    this._leaflet_map$$.next(lmap);
  }

  private _leaflet_map_cluster$: BehaviorSubject<L.MarkerClusterGroup> = new BehaviorSubject(null);
  private _leaflet_map_cluster$$: Observable<L.MarkerClusterGroup> = combineLatest([
    this._leaflet_map_cluster$.asObservable(),
    this._devices_markers$$,
  ]).pipe(
    filter(([cluster]) => !isNil(cluster)),
    map(([cluster, device_markers]) => {
      cluster.clearLayers();
      cluster.addLayer(device_markers);
      return cluster;
    }),
    tap(() => this.updateMapView()),
    replay()
  );

  private _update_circles$: BehaviorSubject<boolean> = new BehaviorSubject(true);
  private _leaflet_map_circles$$: Observable<L.FeatureGroup<any>[]> = combineLatest([
    this._leaflet_map_cluster$$,
    this._update_circles$.asObservable().pipe(distinctUntilRealChanged<boolean>()),
  ]).pipe(
    map(([cluster]) => {
      const cluster_layers: AbstractMarker[] = cluster.getLayers() as AbstractMarker[];
      const unclustered_markers = cluster_layers.filter(
        (marker: AbstractMarker) =>
          this.leaflet_map.hasLayer(marker) &&
          this.leaflet_map.getBounds().contains(marker.getLatLng()) &&
          !(marker instanceof LocationMarker)
      ) as AbstractMarker[];

      const grouped_markers = groupMarkersByType(unclustered_markers, 'circle');
      return Object.keys(grouped_markers).map((key: string) => {
        if ((grouped_markers as any)[key].getLayers().length > 0) {
          return L.featureGroup([unifyPolyCircles((grouped_markers as any)[key].getLayers(), (CONF_CIRCLE_COLORS as any)[key])]);
        }
        return null;
      });
    })
  );

  public layers$$: Observable<L.Layer[]> = combineLatest([this._leaflet_map_cluster$$, this._leaflet_map_circles$$]).pipe(
    map(([cluster, circles]) => [cluster, ...circles])
  );

  private updateMapView(): void {
    if (isNil(this.leaflet_map) || isNil(this._leaflet_map_cluster$.getValue())) {
      return;
    }

    this._update_circles$.next(!this._update_circles$.getValue());
    this.updateBounds();
  }

  private updateBounds(): void {
    const bounds: L.LatLngBounds = L.latLngBounds([]);
    bounds.extend(this._location_marker.getLatLng());

    if (this.is_approximative_activated) {
      bounds.extend(this._approximative_marker.getLatLng());
    }

    if (this._leaflet_map_cluster$.getValue().getLayers().length > 0) {
      bounds.extend(this._leaflet_map_cluster$.getValue().getBounds());
    }

    if (bounds.isValid() && !isNil(this.leaflet_map)) {
      this.leaflet_map.fitBounds(bounds, {
        animate: true,
        padding: L.point(25, 25),
        maxZoom: 15,
      });
    }
  }

  /**
   * Builds the location marker
   */
  private setupLocationMarker(initial_position?: IGeoPositionData): void {
    // Build-up location marker
    this._location_marker = locationMarker(null);
    this._location_marker.options.draggable = true;

    this._location_marker.setLatLng({
      lat: initial_position?.latitude ?? this.BEEGUARD_COORD.lat,
      lng: initial_position?.longitude ?? this.BEEGUARD_COORD.lng,
    });

    // Setup location marker events
    this._location_marker.on('dragend', event => {
      this._ngZone.run(() => this.set_form_position((event.target as LocationMarker).getLatLng().wrap()));
    });

    // Add the marker to the map
    this.leaflet_map.addLayer(this._location_marker);
  }

  /**
   * Build the approximative marker
   */
  private setupApproximativeMarker(): void {
    // Build-up approximative marker
    this._approximative_marker = locationMarker(null, true);
    this._approximative_marker.options.draggable = true;

    // Bind the marker position to the form data
    this._location_approx_marker_pos_sub = this.is_approximative_activated$$
      .pipe(
        filter(Boolean),
        switchMap(() => this.formProperty.valueChanges),
        map((value: IGeoPositionData) => value.approximate),
        waitForNotNilValue(),
        distinctUntilKeysChanged('latitude', 'longitude')
      )
      .pipe()
      .subscribe({
        next: updated_latlng => {
          this.setApproxPosition(L.latLng(updated_latlng.latitude, updated_latlng.longitude));
        },
      });

    // Setup location marker events
    this._approximative_marker.on('dragend', event => {
      const marker = event.target as LocationMarker;
      this.setApproxPosition(marker.getLatLng().wrap());
    });
  }

  /**
   * Build the map base cluster
   */
  private setupBaseCluster(): void {
    const leaflet_map_cluster = L.markerClusterGroup({
      zoomToBoundsOnClick: false,
      spiderfyOnMaxZoom: true,
      maxClusterRadius: 50,
    });

    // Bind event 'animationend' on cluster
    leaflet_map_cluster.on('animationend', () => {
      this._ngZone.run(() => {
        this._update_circles$.next(!this._update_circles$.getValue());
      });
    });

    // Bind event 'clustermouseover' on cluster
    leaflet_map_cluster.on('clustermouseover', (event: L.LeafletEvent) => {
      this._ngZone.run(() => {
        // Build-up the component
        const factory = this._resolver.resolveComponentFactory(Bg2MapPopupAllClusterComponent);
        const component = factory.create(this._injector);
        component.instance.cluster = event.propagatedFrom as L.MarkerCluster;
        component.changeDetectorRef.detectChanges();

        // Updates the popup content / position
        this.leaflet_popup_cmps.cluster_info.popup
          .setLatLng((event as any).latlng)
          .setContent(component.location.nativeElement)
          .addTo(this.leaflet_map);

        // Keep track of created component for ulterior destroy
        this.leaflet_popup_cmps.cluster_info.cmp = component;
      });
    });

    // Bind event 'cloustermouseout' on cluster
    leaflet_map_cluster.on('clustermouseout', () => {
      this._ngZone.run(() => {
        this.leaflet_popup_cmps.cluster_info.popup.removeFrom(this.leaflet_map);
        if (!isNil(this.leaflet_popup_cmps.cluster_info.cmp)) {
          this.leaflet_popup_cmps.cluster_info.cmp.destroy();
          this.leaflet_popup_cmps.cluster_info.cmp = null;
        }
      });
    });

    // Bind event 'clusterclick' on cluster
    leaflet_map_cluster.on('clusterclick', (event: L.LeafletEvent) => {
      const cluster = event.sourceTarget as L.MarkerClusterGroup;
      this.leaflet_map.fitBounds(cluster.getBounds(), { padding: [25, 25] });
    });

    this._leaflet_map_cluster$.next(leaflet_map_cluster);
  }

  // #endregion

  // #region -> (approximative position management)

  private _is_approximative_activated$$ = new BehaviorSubject(false);
  public is_approximative_activated$$ = this._is_approximative_activated$$.asObservable().pipe(distinctUntilRealChanged(), replay());

  public set is_approximative_activated(is_activated: boolean) {
    const is_currently_activated = this._is_approximative_activated$$.getValue();

    if (is_currently_activated === is_activated) {
      return;
    }

    this._is_approximative_activated$$.next(is_activated);

    const current_value: IGeoPositionData = cloneDeep(this.formProperty.value);

    if (is_activated) {
      if (current_value?.approximate?.latitude) {
        this.setApproxPosition(L.latLng(current_value?.approximate?.latitude, current_value?.approximate?.longitude));
      } else {
        const random_pt = generateRandomPoint(L.latLng(current_value.latitude, current_value.longitude), 500);
        this.setApproxPosition(L.latLng(random_pt.lat, random_pt.lng));
      }

      this._approximative_marker.addTo(this.leaflet_map);
    } else {
      this._approximative_marker.removeFrom(this.leaflet_map);
      this.setApproxPosition(L.latLng(null, null));
    }

    this.updateMapView();
  }

  private setApproxPosition(latlng: L.LatLng): void {
    if (latlng?.lat && latlng?.lng) {
      this._approximative_marker.setLatLng(latlng);
    }

    this.form_props__approximate.setValue({ latitude: latlng?.lat || null, longitude: latlng?.lng || null }, false);
  }

  // #endregion

  // #region -> (global form management)

  private _current_position_sub: Subscription;
  private _default_value_sub: Subscription;

  public browser_location_available = true;

  protected computeDefault() {
    // DEFAULT:
    // si on a _default on part de la.
    // sinon on cherhe la pos du browser
    // sinon labège...
    let default_obs: Observable<IGeoPositionData> = of({ latitude: this.BEEGUARD_COORD.lat, longitude: this.BEEGUARD_COORD.lng });

    if (this.schema._default) {
      let expl_pos = this.trackValue(this.bg2Api, cloneDeep(this.schema._default), '/event/date');

      if (isNil(expl_pos)) {
        return;
      }

      default_obs = concat(
        default_obs,
        expl_pos.pipe(
          distinctUntilRealChanged(),
          switchMap(pos => {
            if (!isNil(pos)) {
              return of(pos);
            }

            return this.getBrowserLocation();
          }),
          filter(pos => !isNil(pos))
        )
      );
    } else if (window.navigator && window.navigator.geolocation) {
      default_obs = concat(default_obs, this.getBrowserLocation());
    }

    this._default_value_sub?.unsubscribe();

    this._default_value_sub = default_obs.subscribe(value => {
      // console.log("get default", value);
      if (this.is_default_value) {
        const has_elevation = !isNil(value.elevation);
        const has_addr = !isNil(value.address);
        const has_pos = !isNil(value.latitude) && !isNil(value.longitude);
        // Unactivate all tracking, reactive then when needed
        this.address_tracked = false;
        // this.pos_tracked = false;
        this.is_altitude_tracked_getset.value = true;

        if (has_elevation) {
          this.set_elevation(value.elevation);
        } else {
          this.is_altitude_tracked_getset.value = true;
        }

        if (!has_pos) {
          // this.pos_tracked = true;
        }

        if (has_addr) {
          this.set_form_address(value.address);
        } else {
          this.address_tracked = true;
        }

        if (has_pos) {
          this.set_form_position(L.latLng({ lat: value?.latitude, lng: value.longitude }));
        }

        // Activate all tracking for future changes
        this.address_tracked = true;
        // this.pos_tracked = true;
        this.is_altitude_tracked_getset.value = true;

        // console.log('Default setted, reset is_default');
        this.is_default_value = true;
      }
    });
  }

  private getBrowserLocation(): Observable<any> {
    if (!window.navigator.geolocation) {
      return of(null);
    }
    return new Observable(observer => {
      window.navigator.geolocation.getCurrentPosition(
        position => {
          this.browser_location_available = true;
          observer.next({
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
          });
          observer.complete();
        },
        error => {
          this.browser_location_available = false;
          console.warn(error);
        }
      );
    });
  }

  // #endregion

  public useDevicePosition(): void {
    this.getBrowserLocation().subscribe(position => {
      if (position && position.latitude && position.longitude) {
        this.set_form_position(L.latLng(position.latitude, position.longitude));
      }
    });
  }
}
