import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

import { BehaviorSubject, Observable } from 'rxjs';
import { replay } from '@bg2app/tools/rxjs';

import { Bg2MapPopupComponent } from '../map-popup/map-popup.component';

import { RoutePositionMarker } from 'app/typings/mapping/models/marker/RoutePositionMarker.model';

@Component({
  selector: 'bg2-map-popup-movement-marker',
  templateUrl: './map-popup-movement-marker.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class Bg2MapPopupMovementMarkerComponent extends Bg2MapPopupComponent {
  private _marker$$: BehaviorSubject<RoutePositionMarker> = new BehaviorSubject<RoutePositionMarker>(null);
  public marker$$: Observable<RoutePositionMarker> = this._marker$$.asObservable().pipe(replay());

  @Input()
  public set marker(marker: RoutePositionMarker) {
    this._marker$$.next(marker);
  }
}
