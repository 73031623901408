import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { isNil } from 'lodash-es';

import { map } from 'rxjs';
import { BehaviorSubject } from 'rxjs';
import { replay } from '@bg2app/tools/rxjs';

import { BaseRunner } from 'app/core/runners';

@Component({
  selector: 'bg2-snackbar-summary',
  templateUrl: './snackbar-summary.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SnackbarSummmaryComponent {
  private _runners$$ = new BehaviorSubject<BaseRunner[]>(null);
  public runners$$ = this._runners$$.asObservable().pipe(replay());

  @Input()
  public set runners(runners: BaseRunner[]) {
    this._runners$$.next(runners);
  }

  public summarized$$ = this.runners$$.pipe(
    map(runners => {
      let total = 0;
      let remains = 0;
      const errors: any[] = [];

      runners.forEach(runner => {
        total += runner?.nb_total;
        remains += runner?.nb_remains;

        if (!isNil(runner.error)) {
          errors.push(runner.error);
        }
      });

      return { total, remains, errors, total_runners: runners?.length || 0 };
    })
  );
}
