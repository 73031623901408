<div class="bg-dialog" *ngLet="input_params$$ | async; let input_params">
  <!-- Dialog body -->
  <dialog-body>
    <div class="error-message-container">
      <div class="error-message-container__title">
        <span class="error-message-container__title__name">{{ 'WIDGETS.DIALOGS_MODALS.DIALOGS.ERROR.Error !' | translate }}</span>
        <span class="error-message-container__title__message">
          &nbsp;{{ 'WIDGETS.DIALOGS_MODALS.DIALOGS.ERROR.A problem has occured !' | translate }}
        </span>
      </div>

      <mat-divider></mat-divider>

      <ng-container *ngIf="is_bg2_error$$ | async else genericError">
        <bg2-error-helper [error]="incoming_bg2_error$$ | async"></bg2-error-helper>
      </ng-container>
    
      <ng-template #genericError>
        <div class="error-message-container__description-container">
          <span>{{ 'WIDGETS.DIALOGS_MODALS.DIALOGS.ERROR.Description' | translate | colon }}</span>
  
          <div class="error-message-container__description-container__infos">
            <span class="error-message-container__description-container__infos__single">
              <span>{{ 'WIDGETS.DIALOGS_MODALS.DIALOGS.ERROR.Type' | translate | colon }}</span>
              <span>{{ error_origin$$ | async }}</span>
            </span>
  
            <span *ngIf="error_message$$ | async; let error_message" class="error-message-container__description-container__infos__single">
              <span>{{ 'WIDGETS.DIALOGS_MODALS.DIALOGS.ERROR.Message' | translate | colon }}</span>
              <span>{{ error_message | translate }}</span>
            </span>
          </div>
        </div>
      </ng-template>
    </div>
  </dialog-body>

  <!-- Dialog footer -->
  <div class="dialog-footer">
    <bg2-button style="flex: 1 1 100%;" [type]="'action'" [message]="'ALL.COMMON.OK' | translate" (btn_click)="clicked(true)"> </bg2-button>
  </div>
</div>
