<form-element-header [schema]='schema'></form-element-header>
<div class="config">

  <div class="col col-apiary">
    <div class="header" [class.actif]="global_actif$$ | async">
      <h5 translate>WIDGETS.EVENT_FORM.VISIT_CONFIG.Global on the apiary</h5>
      <img src="assets/img/eval/apiary.png"/>
    </div>
    <sf-form-element *ngIf="$any(formProperty.properties)['apiary']"
        [formProperty]="$any(formProperty.properties)['apiary']">
    </sf-form-element>
  </div>

  <div class="col col-hives">
    <div class="header">
      <h5 translate>WIDGETS.EVENT_FORM.VISIT_CONFIG.By hive</h5>
      <sf-form-element *ngIf="$any(formProperty.properties)['by_hives']"
          [formProperty]="$any(formProperty.properties)['by_hives']">
      </sf-form-element>
    </div>
    <div class="by-hive-conf">
      <sf-form-element *ngIf="$any(formProperty.properties)['hives_fast']"
          [formProperty]="$any(formProperty.properties)['hives_fast']">
      </sf-form-element>
      <sf-form-element *ngIf="$any(formProperty.properties)['hives_precise']"
          [formProperty]="$any(formProperty.properties)['hives_precise']">
      </sf-form-element>
    </div>
  </div>

</div>