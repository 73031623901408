import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Router } from '@angular/router';

import { flatten, isNil, some } from 'lodash-es';

import { combineLatest, Observable, of } from 'rxjs';
import { filter, map, switchMap, take } from 'rxjs';
import { distinctUntilRealChanged, replay } from '@bg2app/tools/rxjs';

import { GhostService } from 'app/core/ghost';

import { AbstractDialogComponent, AbstractDialogParams } from 'app/widgets/dialogs-modals';
import { GhostSolution, GhostSolutionAlternatives } from 'app/core/ghost/models/ghost-solution';

interface GhostConfirmDialogInput extends AbstractDialogParams {
  location_id: number;
}

type GhostConfirmDialogOutput = 'change_config' | 'confirm_installation' | 'cancel_installation';

@Component({
  selector: 'bg2-ghost-confirm-dialog',
  templateUrl: './ghost-confirm-dialog.component.html',
  styleUrls: ['./ghost-confirm-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GhostConfirmDialogComponent extends AbstractDialogComponent<GhostConfirmDialogInput, GhostConfirmDialogOutput> {
  // #region -> (incoming parameters)

  public location_id$$: Observable<number> = this.input_params$$.pipe(
    filter(parameters => !isNil(parameters?.location_id)),
    map(parameters => parameters.location_id),
    distinctUntilRealChanged(),
    replay()
  );

  // #endregion

  // #region -> (component basics)

  constructor(private _ghostService: GhostService, private router: Router) {
    super();
  }

  // #endregion

  // #region -> (solutions management)

  private all_solutions$$ = this.location_id$$.pipe(
    filter(location_id => !isNil(location_id)),
    switchMap(location_id => this._ghostService.streamSolutionsFromLocation(location_id)),
    replay()
  );

  public events$$ = this.all_solutions$$.pipe(
    switchMap(all_solutions => {
      const selected_solutions$$ = all_solutions.map(solution => solution.streamAllEvents());
      return combineLatest(selected_solutions$$);
    }),
    map(events_by_selected_solution => flatten(events_by_selected_solution)),
    // TODO: add time/id sorting
    replay()
  );

  // #endregion

  // #region -> (resultant management)

  public modifyConfiguration(): void {
    this.location_id$$.pipe(
      take(1)
    ).subscribe(location_id => {
      this.complete('change_config');
      this.router.navigate(
        ['', { outlets: { modal: ['ghost_config', { location_id } ] } }],
        {
          queryParamsHandling: "preserve"
        }
      )
    })
  }

  public cancelInstallation(): void {
    this.complete('cancel_installation');
  }

  public confirmInstallation(): void {
    this.all_solutions$$.pipe(
      take(1),
      switchMap(solutions => this._ghostService.validate(solutions))
    ).subscribe(() => {
      this.complete('confirm_installation');
    })
  }

  // #endregion
}
