import { Component, Input, Output, EventEmitter, ChangeDetectionStrategy } from '@angular/core';

import { BehaviorSubject, map } from 'rxjs';
import { distinctUntilRealChanged, replay } from '@bg2app/tools/rxjs';

import { assign, isArray, isNil } from 'lodash-es';

import { Dictionary } from 'app/typings/core/interfaces/Dictionary.iface';
import { ISchema } from 'ngx-schema-form';

export interface AbstractDialogElement<ReturnType> {
  styles?: { [key: string]: string };
  class?: string[];
  translateParams?: Dictionary<any>;
}

export interface DialogSpanElement<ReturnType> extends AbstractDialogElement<ReturnType> {
  type: 'span';
  content: string;
}

export interface DialogJSONDataElement<ReturnType> extends AbstractDialogElement<ReturnType> {
  type: 'json-data';
  content: string;
}

export interface DialogLogicalSeparator<ReturnType> extends AbstractDialogElement<ReturnType> {
  type: 'logical-sep';
  messageType: 'OR' | 'AND';
}

export interface DialogButton<ReturnType> extends AbstractDialogElement<ReturnType> {
  type: 'button';

  /**
   * The background color of the button. Do not precise to use the default white color.
   */
  color?: 'warn' | 'primary' | 'device' | 'breeding-register' | 'transparent';

  /**
   * The icon name of the button.
   */
  icon?: string;

  /**
   * The main content of the button.
   */
  content: string;

  /**
   * The descriptive of the button. This should be only a span, never a link nor something else.
   */
  descriptive?: DialogSpanElement<ReturnType>[];

  /**
   * Returned value when the user clicks on the button.
   */
  result?: ReturnType;
}

export interface DialogLink<ReturnType> extends AbstractDialogElement<ReturnType> {
  type: 'link';
  content: string;
  result?: ReturnType;
  action?: () => void;
}

export interface DialogSchema<ReturnType> extends AbstractDialogElement<ReturnType> {
  type: 'schema';
  name: string;
  data: { [key: string]: any };
}

export interface DialogDiv<ReturnType> extends AbstractDialogElement<ReturnType> {
  type: 'div';
  elements: DialogElement<ReturnType> | DialogElement<ReturnType>[];
  name?: string;
}

export interface DialogTextarea<ReturnType> extends AbstractDialogElement<ReturnType> {
  type: 'textarea';
  content: string;
}

export interface DialogForm<ReturnType> extends AbstractDialogElement<ReturnType> {
  type: 'form';
  schema: ISchema;
  name: string;
}

//model$$ = new BehaviorSubject({});

export type DialogElement<ReturnType> =
  | DialogSpanElement<ReturnType>
  | DialogJSONDataElement<ReturnType>
  | DialogLogicalSeparator<ReturnType>
  | DialogButton<ReturnType>
  | DialogLink<ReturnType>
  | DialogSchema<ReturnType>
  | DialogDiv<ReturnType>
  | DialogTextarea<ReturnType>
  | DialogForm<ReturnType>;

@Component({
  selector: 'bg2-dialog-div',
  templateUrl: 'dialog-div.component.html',
  styleUrls: ['dialog-div.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DialogDivComponent<ReturnType = any> {
  public _assigner = assign;
  public _isNil = isNil;

  private _forms_data$$ = new BehaviorSubject<{ [form_name: string]: any }>({});

  private _content$$ = new BehaviorSubject<DialogDiv<ReturnType>>(null);
  public content$$ = this._content$$.asObservable();

  public elements$$ = this.content$$.pipe(
    map(content => content?.elements),
    map(elements => (isArray(elements) ? elements : [elements])),
    replay(),
  );

  @Input()
  public set content(_content: DialogDiv<ReturnType>) {
    this._content$$.next(_content);
  }

  @Output()
  public action = new EventEmitter<ReturnType>();

  @Output()
  public form_values = new EventEmitter<any>();

  public get_form_model$$(form_element_path: string) {
    return this._forms_data$$.pipe(
      map(_forms_data => _forms_data[form_element_path] || null),
      distinctUntilRealChanged(),
      replay(),
    );
  }

  public update_form_element(form_element_path: string, $event: any) {
    let data = this._forms_data$$.getValue();
    if (isNil(form_element_path)) {
      data = assign({}, data, $event);
    } else {
      data[form_element_path] = $event;
    }
    this._forms_data$$.next(data);
    this.form_values.emit(data);
  }

  public onClickButton(return_value: ReturnType): void {
    this.action.next(return_value);
  }

  public isSpan(element: DialogElement<ReturnType>) {
    return this.checkType(element, 'span');
  }

  public isJSONDate(element: DialogElement<ReturnType>) {
    return this.checkType(element, 'json-data');
  }

  public isLogicalSep(element: DialogElement<ReturnType>) {
    return this.checkType(element, 'logical-sep');
  }

  public isButton(element: DialogElement<ReturnType>) {
    return this.checkType(element, 'button');
  }

  public isLink(element: DialogElement<ReturnType>) {
    return this.checkType(element, 'link');
  }

  public isTextarea(element: DialogElement<ReturnType>) {
    return this.checkType(element, 'textarea');
  }

  public isSchema(element: DialogElement<ReturnType>) {
    return this.checkType(element, 'schema');
  }

  public isDiv(element: DialogElement<ReturnType>) {
    return this.checkType(element, 'div');
  }

  public isForm(element: DialogElement<ReturnType>) {
    return this.checkType(element, 'form');
  }

  public checkType(element: DialogElement<ReturnType>, type: 'json-data'): DialogJSONDataElement<ReturnType>;
  public checkType(element: DialogElement<ReturnType>, type: 'span'): DialogSpanElement<ReturnType>;
  public checkType(element: DialogElement<ReturnType>, type: 'textarea'): DialogTextarea<ReturnType>;
  public checkType(element: DialogElement<ReturnType>, type: 'logical-sep'): DialogLogicalSeparator<ReturnType>;
  public checkType(element: DialogElement<ReturnType>, type: 'button'): DialogButton<ReturnType>;
  public checkType(element: DialogElement<ReturnType>, type: 'link'): DialogLink<ReturnType>;
  public checkType(element: DialogElement<ReturnType>, type: 'schema'): DialogSchema<ReturnType>;
  public checkType(element: DialogElement<ReturnType>, type: 'div'): DialogDiv<ReturnType>;
  public checkType(element: DialogElement<ReturnType>, type: 'form'): DialogForm<ReturnType>;
  public checkType(element: DialogElement<ReturnType>, type: string): DialogElement<ReturnType> {
    return element.type === type ? element : null;
  }
}
