import { Component, ChangeDetectionStrategy } from '@angular/core';
import { OnInit, OnDestroy, Input, NgZone, ComponentRef, ComponentFactoryResolver, Injector } from '@angular/core';

import { isNil } from 'lodash-es';

import { BehaviorSubject, Observable, of, combineLatest, catchError, filter, switchMap, map, tap, distinctUntilChanged, take } from 'rxjs';
import { replay, waitForNotNilValue, anyTrue, switchTap } from '@bg2app/tools/rxjs';

import {
  Map,
  layerGroup,
  MarkerClusterGroup,
  markerClusterGroup,
  LayerGroup,
  latLngBounds,
  point,
  featureGroup,
  LeafletEvent,
  control,
} from 'leaflet';

import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';

import { AppStateService } from 'app/core/app-state.service';
import { ConsoleLoggerService } from 'app/core/console-logger.service';
import { GoogleMapsLoader } from 'app/misc/services/google-maps-loader.service';

import { Location, Apiary, DRDevice } from 'app/models';
import { groupMarkersByType, unifyPolyCircles } from 'app/misc/tools/geomap';
import { locationMarker, LocationMarker, DeviceMarker, AbstractMarker, CONF_CIRCLE_COLORS } from 'app/typings/mapping';

import { Bg2MapPopupDeviceMarkerComponent } from 'app/views/map/shared/html-map-popups/map-popup-device-marker/map-popup-device-marker.component';

@AutoUnsubscribe()
@Component({
  selector: 'bg2-location-map',
  templateUrl: './location-map.component.html',
  styleUrls: ['./location-map.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LocationMapComponent implements OnInit, OnDestroy {
  private _logger = new ConsoleLoggerService('LocationMapComponent', false);

  @Input()
  public set location(location: Location) {
    this._logger.debug(location);
    this._location$.next(location);
  }

  private _location$: BehaviorSubject<Location> = new BehaviorSubject(null);
  public location$$: Observable<Location> = this._location$.asObservable().pipe(waitForNotNilValue(), replay());

  // #region -> (component loadings)

  private _loading$$: BehaviorSubject<boolean> = new BehaviorSubject(true);
  public loading$$: Observable<boolean> = combineLatest([
    this._loading$$.asObservable().pipe(distinctUntilChanged()),
    this._appState.locations_loading$$,
    this._appState.exploitations_loading$$,
  ]).pipe(map(([local_loading, locations_loading, expl_loading]) => local_loading || locations_loading || expl_loading));

  // #endregion

  // #region -> (component basics)

  constructor(
    private _ngZone: NgZone,
    private _injector: Injector,
    private _appState: AppStateService,
    private _resolver: ComponentFactoryResolver
  ) {}

  ngOnInit(): void {
    GoogleMapsLoader.load().catch((reason: any) => this._logger.error(reason));
  }

  ngOnDestroy(): void {
    // Destroy devices popups
    this._leaflet_popups_ref.device_popups.forEach((ctx, idx: number) => {
      ctx?.cmp?.destroy();
      if (this._leaflet_popups_ref.device_popups?.[idx]?.cmp) {
        this._leaflet_popups_ref.device_popups[idx].cmp = null;
      }
    });
  }

  // #endregion

  // #region -> (ACE management)

  /** */
  public user_can_read_approximate_position$$ = this.location$$.pipe(
    switchMap(location => location.user_acl.can$$('read_aproximate_position'))
  );

  /** */
  public user_can_read_precise_position$$ = this.location$$.pipe(switchMap(location => location.user_acl.can$$('read_precisse_position')));

  /** */
  public user_can_read_position$$ = anyTrue(this.user_can_read_approximate_position$$, this.user_can_read_precise_position$$);

  // #endregion

  // #region -> (markers builders)

  private _location_marker$$: Observable<LayerGroup<LocationMarker>> = this.location$$.pipe(
    switchTap(location =>
      location.user_acl.check_read_precise_position$$({ what: "ALL.ERROR.ACE.READ_PRECISE_POSITION.WHAT.the location's precise position" })
    ),
    catchError(() => of(null)),
    switchMap(location => {
      if (isNil(location)) {
        return of<Location>(null);
      }

      return combineLatest([location.has_apiary$$, location.is_archived$$]).pipe(map(() => location));
    }),
    tap(() => this._loading$$.next(true)),
    map((location: Location) => {
      if (isNil(location) || !Location.hasLatitudeLongitude(location)) {
        return layerGroup([]);
      }

      return layerGroup([locationMarker(location)]);
    })
  );

  private _device_markers$$: Observable<LayerGroup<DeviceMarker>> = this.location$$.pipe(
    tap(() => this._loading$$.next(true)),
    switchMap((location: Location) => location.apiary$$),
    switchMap((apiary: Apiary) => (isNil(apiary) ? of<DRDevice[]>([]) : apiary.devices$$)),
    map((devices: DRDevice[]) => {
      // Clear old components
      this._leaflet_popups_ref.device_popups.forEach((val, idx) => {
        if (!isNil(this._leaflet_popups_ref.device_popups[idx].cmp)) {
          val.cmp.destroy();
          this._leaflet_popups_ref.device_popups[idx].cmp = null;
        }
      });

      if ((devices || []).length === 0) {
        return layerGroup([]);
      }

      const device_markers: DeviceMarker[] = devices
        .filter((device: DRDevice) => !isNil(device.location))
        .map((device: DRDevice) => {
          const device_marker = new DeviceMarker(device);

          // Build-up popup content component
          const component = this._resolver.resolveComponentFactory(Bg2MapPopupDeviceMarkerComponent).create(this._injector);
          device_marker.bindPopup(component.location.nativeElement, { closeButton: true });

          component.instance.self_popup_ref = device_marker.getPopup();
          component.instance.marker = device_marker;
          component.changeDetectorRef.detectChanges();

          // Build-up popup itself
          this._leaflet_popups_ref.device_popups.push({ popup: device_marker.getPopup(), cmp: component });

          return device_marker;
        });

      return layerGroup(device_markers);
    })
  );

  // #endregion

  // #region -> (map things)

  private _leaflet_map_ref: Map = null;
  private _cluster_ref: MarkerClusterGroup = null;

  private _leaflet_popups_ref: {
    device_popups: { popup: L.Popup; cmp: ComponentRef<Bg2MapPopupDeviceMarkerComponent> }[];
  } = { device_popups: [] };

  private _leaflet_map_cluster$: BehaviorSubject<MarkerClusterGroup> = new BehaviorSubject(null);
  private _leaflet_map_cluster$$: Observable<MarkerClusterGroup> = combineLatest([
    this._leaflet_map_cluster$.asObservable(),
    this._device_markers$$,
  ]).pipe(
    filter(([cluster]) => !isNil(cluster)),
    map(([cluster, dev_markers]) => {
      cluster.clearLayers();
      cluster.addLayers([dev_markers]);
      return cluster;
    }),
    tap((cluster: MarkerClusterGroup) => (this._cluster_ref = cluster)),
    tap(() => this.updateMapBounds()),
    replay()
  );

  private _update_circles$: BehaviorSubject<boolean> = new BehaviorSubject(true);
  private _leaflet_map_circles$$: Observable<L.FeatureGroup<any>[]> = combineLatest([
    this._update_circles$.asObservable().pipe(distinctUntilChanged<boolean>()),
    this._leaflet_map_cluster$$,
  ]).pipe(
    tap(() => this._loading$$.next(true)),
    map(([update, cluster]) => {
      const cluster_layers: AbstractMarker[] = cluster.getLayers() as AbstractMarker[];
      const unclustered_markers = cluster_layers.filter(
        (marker: AbstractMarker) =>
          this._leaflet_map_ref.hasLayer(marker) &&
          this._leaflet_map_ref.getBounds().contains(marker.getLatLng()) &&
          !(marker instanceof LocationMarker)
      ) as AbstractMarker[];
      const grouped_markers = groupMarkersByType(unclustered_markers, 'circle');
      return Object.keys(grouped_markers).map((key: string) => {
        if ((grouped_markers as any)[key].getLayers().length > 0) {
          return featureGroup([unifyPolyCircles((grouped_markers as any)[key].getLayers(), (CONF_CIRCLE_COLORS as any)[key])]);
        }
        return null;
      });
    })
  );

  public map_layers$$: Observable<L.Layer[]> = combineLatest([
    this._leaflet_map_cluster$$,
    this._leaflet_map_circles$$,
    this._location_marker$$,
  ]).pipe(
    map(([cluster, circles, loc]) => {
      const layers: L.Layer[] = [cluster, ...circles];

      if (!isNil(loc)) {
        layers.push(loc);
      }

      return layers;
    }),
    tap(() => this._loading$$.next(false))
  );

  public onLeafletMapReady(leaflet_map: Map): void {
    this._leaflet_map_ref = leaflet_map;
    this._leaflet_map_ref.addControl(control.zoom({ position: 'bottomright' }));
    this.buildMapCluster();
  }

  public onLeafletMapMoveEnd(): void {
    this._update_circles$.next(!this._update_circles$.getValue());
  }

  private updateMapBounds(): void {
    if (!isNil(this._cluster_ref) && !isNil(this._leaflet_map_ref)) {
      if (this._cluster_ref.getLayers().length > 0) {
        this._location_marker$$.pipe(take(1)).subscribe({
          next: location_marker_layer => {
            const bounds: L.LatLngBounds = latLngBounds([]);
            bounds.extend(this._cluster_ref.getBounds());

            if (!isNil(location_marker_layer.getLayers()[0])) {
              bounds.extend((location_marker_layer.getLayers()[0] as LocationMarker).getLatLng());
            }

            if (bounds.isValid()) {
              this._leaflet_map_ref.flyToBounds(bounds, {
                animate: true,
                padding: point(25, 25),
                maxZoom: 13,
                duration: 0.25,
              });
            }
          },
        });
      }
    }
  }

  private buildMapCluster(): void {
    const leaflet_map_cluster = markerClusterGroup({
      zoomToBoundsOnClick: false,
      spiderfyOnMaxZoom: true,
      maxClusterRadius: 50,
    });

    // Bind event 'animationend' on cluster
    leaflet_map_cluster.on('animationend', () => {
      this._ngZone.run(() => {
        this._update_circles$.next(!this._update_circles$.getValue());
      });
    });

    // Bind event 'clusterclick' on cluster
    leaflet_map_cluster.on('clusterclick', (event: LeafletEvent) => {
      const cluster = event.sourceTarget as MarkerClusterGroup;
      this._leaflet_map_ref.fitBounds(cluster.getBounds(), { padding: [25, 25] });
    });

    this._leaflet_map_cluster$.next(leaflet_map_cluster);
  }

  // #endregion
}
