import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { BrowserTracing } from '@sentry/browser';
import { CaptureConsole } from '@sentry/integrations';
import { InboundFilters, init, routingInstrumentation, Replay } from '@sentry/angular-ivy';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

if (environment?.sentry?.url) {
  init({
    dsn: environment.sentry.url,
    release: `ngapp@${environment.sentry.release}`,
    environment: environment.sentry.environment,
    sendClientReports: false,
    autoSessionTracking: false,
    attachStacktrace: true,

    integrations: [
      new CaptureConsole({
        levels: ['error'],
      }),
      new InboundFilters({
        ignoreErrors: ['Non-Error exception captured', 'ChunkLoadError', 'FORBIDDEN', 'Unauthorized', 'Unauthorized - invalid_token'],
        denyUrls: [/clarity\.ms/i, /google\.com/i, /google-analytics\.com/i, /extensions\//i, /^chrome:\/\//i, /^chrome-extension:\/\//i],
      }),
      // Registers and configures the Tracing integration,
      // which automatically instruments your application to monitor its
      // performance, including custom Angular routing instrumentation
      new BrowserTracing({
        tracingOrigins: ['localhost', /^\//],
        routingInstrumentation: routingInstrumentation,
      }),
      new Replay({
        maskAllText: false,
      }),
    ],

    tracesSampleRate: environment.sentry.traces_sample_rate,
    replaysOnErrorSampleRate: 1.0,
  });
}

platformBrowserDynamic().bootstrapModule(AppModule);
