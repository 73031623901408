import { concatMap, forkJoin, map, Observable, of, switchMap } from 'rxjs';

import { assign, cloneDeep, isEmpty, isNil } from 'lodash-es';
import { differenceInHours } from 'date-fns';

import { DeviceApi } from 'app/core';
import { DeviceQueryParams } from 'app/core/api/device/device-api-service';
import { DeviceCommunicationTechnology } from './enumerators/device-communication-technology.enum';
import { BeeLivePhotosData, CPTJobData, CPTSecondaryData, DeviceRSSIData } from '../data';
import { parseDate, replay, startOfTomorrowLuxon } from 'app/misc/tools';
import { DataPoint, DeviceLastMeasurement } from 'app/core/api-swagger/device';

import { DRDevice, DeviceLocation, DeviceStatusGPS, DeviceStatus, DeviceStatusGPRS, DeviceLastMeasurements } from './DRDevice';
import { CPTMC_IFX_SCHEMA } from './CPTMCDevice';
import { compute_gprs_state, compute_gps_state } from './_functions';
import { tzLookup } from 'app/misc/tools/misc/suncalc/tzLookup';
import {
  DEVICE_BATTERY_TYPE,
  DEVICE_SIMPLIFIED_BATTERY_STATE,
  DEVICE_SIMPLIFIED_BATTERY_STATE_REASON,
  DeviceSimplifiedBatteryState,
} from './enumerators';
import { BatterySparklineData } from './interfaces';

export const CPT_CONF_SCHEMA = {
  type: 'object',
  title: 'CPT configuration',
  properties: {
    embedded: {
      label: 'Embedded configuration',
      type: 'object',
      properties: {
        operating_mode: {
          label: 'operating_mode',
          widget: 'ng-mat-select',
          type: 'string',
          enum: ['ECO', 'MAX', 'DEBUG'],
        },
        communication_period_sec: {
          type: 'integer',
          label: 'communication_period_sec',
        },
        log_period_sec: {
          type: 'integer',
          label: 'log_period_sec',
        },
        counter_bin_duration_sec: {
          type: 'integer',
          label: 'counter_bin_duration_sec',
          minimum: 1,
          maximum: 3600,
        },
        recording_mode: {
          label: 'recording_mode',
          widget: 'ng-mat-select',
          type: 'string',
          enum: ['OFF', 'THRESHOLD', 'PERIODIC', 'BOTH'],
        },
        recording_deadtime_sec: {
          label: 'recording_deadtime_sec',
          type: 'integer',
          minimum: 60,
          maximum: 86400,
        },
        recording_threshold_count: {
          label: 'recording_threshold_count',
          type: 'integer',
          minimum: 1,
          maximum: 99999999,
        },
        recording_duration_sec: {
          label: 'recording_duration_sec',
          type: 'integer',
          minimum: 10,
          maximum: 600,
        },
        recording_periodic_cron: {
          label: 'recording_periodic_cron',
          type: 'string',
        },
        timelaps_mode: {
          label: 'timelaps_mode',
          type: 'string',
          widget: 'ng-mat-select',
          enum: ['ON', 'OFF'],
        },
        timelaps_period_sec: {
          label: 'timelaps_period_sec',
          type: 'integer',
          minimum: 60,
          maximum: 86400,
        },
        timelaps_annotation: {
          label: 'timelaps_annotation',
          widget: 'ng-mat-select',
          type: 'string',
          enum: ['ON', 'OFF'],
        },
        local_wifi_SSID: {
          label: 'local_wifi_SSID',
          type: 'string',
        },
        local_wifi_password: {
          label: 'local_wifi_password',
          type: 'string',
        },
        lightning_mode: {
          label: 'lightning_mode',
          widget: 'ng-mat-select',
          type: 'string',
          enum: ['ON', 'OFF', 'FIX_PERIOD'],
        },
        lightning_period_start_hour_utc: {
          label: 'lightning_period_start_hour_utc',
          type: 'number',
          minimum: 0,
          maximum: 24,
        },
        lightning_period_stop_hour_utc: {
          label: 'lightning_period_stop_hour_utc',
          type: 'number',
          minimum: 0,
          maximum: 24,
        },
      },
    },
  },
};

export class CPTDevice extends DRDevice {
  // #region -> (model basics)

  constructor(protected deviceApi: DeviceApi, params?: DeviceQueryParams) {
    super(deviceApi, params);

    this.is_gateway = true;
    this.type = 'CPT';
  }

  public fetchConfigurationSchema$(): Observable<any> {
    const full_schema = cloneDeep(CPT_CONF_SCHEMA);
    assign(full_schema.properties.embedded.properties, CPTMC_IFX_SCHEMA.properties.embedded.properties);
    return of(full_schema);
  }

  // #endregion

  // #region -> (device msg)

  /** */
  private _last_gw_msg: DeviceLastMeasurement = null;

  /**
   * Gets device's last gateway message.
   *
   * @returns Returns device's last gateway message.
   */
  get last_gw_msg(): DeviceLastMeasurement {
    if (isNil(this._last_gw_msg)) {
      const last_measurements = this.last_measurements;

      if (isNil(last_measurements) || isEmpty(last_measurements)) {
        return null;
      }

      const gateway_message = last_measurements.gateway_message;

      if (isNil(gateway_message) || isEmpty(gateway_message)) {
        return null;
      } else {
        gateway_message.time = parseDate(gateway_message.time);
      }

      this._last_gw_msg = gateway_message;
    }

    return this._last_gw_msg;
  }

  // #endregion

  // #region -> (GPRS status)

  /** */
  public get status_gprs(): DeviceStatusGPRS {
    const message = this.last_gw_msg;

    if (isNil(message)) {
      return null;
    }

    let outdated = false;
    const timestamp = message.time;

    if (differenceInHours(new Date(), timestamp) > 49) {
      outdated = true;
    }

    const prebuilt_status: DeviceStatus = { timestamp, value: message?.fields?.gprs_qos?.last ?? null, outdated };
    return { state: compute_gprs_state(prebuilt_status), ...prebuilt_status };
  }

  // #endregion

  // #region -> (GPS status)

  /** */
  public get status_gps(): DeviceStatusGPS {
    const location = this.location;

    if (isNil(location)) {
      return null;
    }

    const outdated = false;
    const value = location.fix;
    const timestamp = location.timestamp;

    const prebuilt_status: DeviceStatus = { timestamp, value, outdated };
    return { state: compute_gps_state(prebuilt_status), ...prebuilt_status };
  }

  // #endregion

  // #region -> (geoposition)

  /** */
  public get location_gps(): DeviceLocation {
    if (!isNil(this.last_measurements?.location_AGG_GPS)) {
      return super.location_gps;
    }

    const location_measurement = this?.last_measurements?.location ?? {};

    if (isNil(location_measurement?.fields)) {
      return this._location_gps;
    }

    const device_location: DeviceLocation = {
      type: 'GPS',
      timestamp: parseDate(location_measurement?.time),
      latitude: location_measurement?.fields?.gps_lat?.last ?? null,
      longitude: location_measurement?.fields?.gps_lng?.last ?? null,
      fix:
        (location_measurement?.fields?.gps_fix?.last ?? null) === 'FIX' || (location_measurement?.fields?.gps_fix?.last ?? null) === 'True',
      accuracy: location_measurement?.fields?.gps_accuracy?.last ?? null,
      timezone: 'Europe/Paris',
    };

    if (!isNil(device_location?.latitude) && !isNil(device_location?.longitude)) {
      device_location.timezone = tzLookup(device_location?.latitude, device_location?.longitude);
    }

    this._location_gps = device_location;
    return this._location_gps;
  }

  // #endregion

  // #region -> battery state

  /** */
  public battery_sparkline_last_two_years$$ = this.timezone$$.pipe(
    concatMap(timezone => {
      let end = startOfTomorrowLuxon(timezone);
      let start = end.minus({ years: 2 });

      const first_part = this.requestTimeseries(
        ['vbat', 'temperature', 'temperature_com'],
        start.toJSDate(),
        end.minus({ months: 1 }).toJSDate(),
        '7d'
      );
      const second_part = this.requestTimeseries(
        ['vbat', 'temperature', 'temperature_com'],
        end.minus({ months: 1 }).plus({ seconds: 1 }).toJSDate(),
        end.plus({ minutes: 30 }).toJSDate(),
        '1d'
      );

      return forkJoin({
        first_part,
        second_part,
      }).pipe(
        map(response => {
          // console.log({ response });

          const final_timeseries: DataPoint[] = [];

          final_timeseries.push(...(response?.first_part?.timeseries?.data ?? []));
          final_timeseries.push(...(response?.second_part?.timeseries?.data ?? []));

          return final_timeseries;
        }),
        map(timeseries_data => {
          return timeseries_data.map((d: any) => {
            d.vbat = d.vbat / 1000;
            d.temperature = d.temperature_com;
            return d;
          });
        }),
        map((timeseries_data: DataPoint[]) => {
          return <BatterySparklineData>{
            end_date: end,
            start_date: start,
            battery_changes: [],
            battery_voltages: timeseries_data ?? [],
          };
        })
      );
    }),
    // switchMap(data =>
    //   this.is_wguard_data_retrieval_active$$.pipe(
    //     map(is_wguard_data_retrieval_active => {
    //       if (is_wguard_data_retrieval_active) {
    //         return data;
    //       }

    //       data.battery_voltages = data.battery_voltages.map(datum => {
    //         datum.temperature = datum.temperature_com;
    //         return datum;
    //       });

    //       return data;
    //     })
    //   )
    // ),
    replay()
  );

  /** */
  protected compute_battery_type$$(): Observable<DEVICE_BATTERY_TYPE> {
    return of(null);
  }

  /** */
  protected get_battery_noload_voltage(last_measurements: DeviceLastMeasurements): number | number[] {
    return null;
  }

  /** */
  protected get_battery_com_voltage(last_measurements: DeviceLastMeasurements): number | number[] {
    const last_vbat = last_measurements?.env?.fields?.pyranometer?.last;

    if (!isNil(last_vbat)) {
      return last_vbat / 1000;
    }

    return last_measurements?.gateway_message?.fields?.vbat?.last ?? null;
  }

  /**
   * Device typical voltage range
   */
  public get_battery_std_voltage_range$$(): Observable<[number, number]> {
    return of([8, 16]);
  }

  protected get_battery_critical_vbat$$(): Observable<number> {
    return of(11.5);
  }

  /** */
  public get_battery_simplified_state$$(): Observable<DeviceSimplifiedBatteryState> {
    return this._get_default_battery_simplified_state$$.pipe(
      map(state => {
        if (!isNil(state)) {
          return state;
        }

        return {
          state: DEVICE_SIMPLIFIED_BATTERY_STATE.OK,
          reason: null,
        };
      })
    );
  }

  // #endregion

  // #endregion

  // #region -> (communication technology)

  /** */
  protected get_com_technology$$(): Observable<DeviceCommunicationTechnology[]> {
    return of(null);
  }

  // #endregion

  // #region -> (device timeseries)

  /** */
  public fetch_job_data$(start: Date = undefined, end: Date = undefined, step: string = undefined) {
    return super
      .requestTimeseries(['count_in', 'count_out'], start, end, step)
      .pipe(map(response => <CPTJobData>{ points: response?.timeseries?.data ?? [] }));
  }

  /** */
  public fetch_secondary_data$(start?: Date, end?: Date, step?: string): Observable<CPTSecondaryData> {
    return of(<CPTSecondaryData>{
      points: [],
    });
  }

  /** */
  public fetch_rssi$(start?: Date, end?: Date, step?: string): Observable<DeviceRSSIData> {
    return super.requestTimeseries(['rssi_gprs'], start, end, step).pipe(
      map(
        response =>
          <DeviceRSSIData>{
            points: response?.timeseries?.data ?? [],
          }
      )
    );
  }

  /** */
  public fetch_timelapses$(start: Date, end: Date): Observable<BeeLivePhotosData> {
    return super.requestTimeseries(['timelapse_cam0', 'timelapse_cam1'], start, end, '5m').pipe(
      map(
        response =>
          <BeeLivePhotosData>{
            points: response?.timeseries?.data ?? [],
          }
      )
    );
  }

  // #endregion
}
