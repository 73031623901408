import { HttpClient } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';

import { find, flatten, has, isEmpty, isNil, union, uniq } from 'lodash-es';

import { catchError, map, switchMap, tap } from 'rxjs';
import { Observable, of, forkJoin, Subscription } from 'rxjs';

import {
  AckConfigurationHistoryReturn,
  Device as DeviceInterface,
  Fconf,
  GetDevicesResponse,
  ListCredentialsResponse,
  OrderData,
  OtaData,
  PostNewBatCh,
  PostTmpConfiguration,
  PutBatCh,
  PutBulkOwnerData,
  Sconf,
  UpdateDeviceSupportIssue,
} from '../../api-swagger/device';
import { DeviceService as DeviceApiSwagger } from '../../api-swagger/device';

import { ApiCache } from '../misc/api-cache';
import { BeeguardAuthService } from 'app/core/auth/beeguard-auth.service';

import { PagingQuery, Paging } from 'app/models';
import { DRDevice, GPSDevice, WGDevice, RGDevice, RawDeviceSupports, DeviceFullConfiguration, TGDevice } from 'app/models/devices';
import { CPTDevice, CPTMCDevice } from 'app/models/devices';
import { BloomLiveDevice } from 'app/models/devices';
import { BeeLiveDevice } from 'app/models/devices';
import { ComputeDevice } from 'app/models/devices';

import { Dictionary } from 'app/typings/core/interfaces';

import { environment } from 'environments/environment';
import { NADevice } from 'app/models/devices/NADevice';
import { ConsoleLoggerService } from 'app/core/console-logger.service';
import { parseDate } from 'app/misc/tools';
import { HttpBatch } from 'app/models/http-batch';
import { RequestsToBatchByQueryParameters } from 'app/models/http-batch/interfaces/request-to-batch-by-qparams';
import { RequestInQueue } from 'app/models/http-batch/interfaces/request-in-queue';
import { ZohoApisService } from 'app/core/services/zoho/zoho-apis.service';

/**
 * Type of the device query available parameters.
 */
export interface DeviceQueryParams {
  include?: ('beeguard_setup' | 'gateways' | 'sensors' | 'configuration')[];
  last_measurements?: string[];
  last_measurements_details?: ('gateway_message' | 'sensor_message' | 'sensor_message_AGG_as_gateway')[];
}

@Injectable({
  providedIn: 'root',
})
export class DeviceApi extends DeviceApiSwagger implements OnDestroy {
  public default_include: DeviceQueryParams['include'] = ['beeguard_setup', 'gateways', 'sensors'];

  public default_last_measurements = [
    'measures',
    'message',
    'gateway_message',
    'sensor_message',
    'location',
    'location_AGG_GPS',
    'weight',
    'env',
    'iccid',
    'mvmt_status',
    'network_activity',
  ];

  public default_last_measurements_details: DeviceQueryParams['last_measurements_details'] = [];

  protected basePath = environment.DeviceApiUrl;

  // #region -> (service basics)

  private _impersonate_id_sub: Subscription = null;

  private _cache = new ApiCache<DRDevice, number>(60000);

  private _logger = new ConsoleLoggerService('DeviceApi', false);

  constructor(private _zohoApis: ZohoApisService, private oAuthService: BeeguardAuthService, protected http: HttpClient) {
    super(http, null, null);

    this._impersonate_id_sub = this.oAuthService.authentication_data$$.subscribe({
      next: ({ access_token, impersonate }) => {
        this.defaultHeaders = this.defaultHeaders.set('Authorization', `Bearer ${access_token}`);

        if (impersonate) {
          this.defaultHeaders = this.defaultHeaders.set('ImpersonateId', impersonate.user_id.toString());
          this.defaultHeaders = this.defaultHeaders.set('ImpersonateScope', impersonate.scopes.join(' '));
        } else {
          this.defaultHeaders = this.defaultHeaders.delete('ImpersonateId');
          this.defaultHeaders = this.defaultHeaders.delete('ImpersonateScope');
        }
      },
    });
  }

  ngOnDestroy(): void {
    this._impersonate_id_sub?.unsubscribe();
  }

  // #endregion

  // #region -> (errors management)

  public handleUnauthorizedError(err: any) {
    this.oAuthService.handleUnauthorizedError(err);
  }

  // #endregion

  // #region -> (create methods)

  /**
   * Create a unique device.
   *
   * @param device_type Type of device to create.
   * @param imei IMEI of the new device.
   */
  public create_device$(device_type: string, imei: string) {
    return of(null);
  }

  /**
   * Create a serie of devices.
   *
   * @param device_type Type of the devices to create.
   * @param imeis List of IMEI to create.
   */
  public create_devices$(device_type: string, imeis: number[]) {
    return super.createDevices({
      dtype: device_type,
      dids: imeis,
    });
  }

  /** */
  public create_battery_change$(imei: number, body?: PostNewBatCh) {
    return super.addBatCh(imei, body);
  }

  /** */
  public create_device_tmp_conf$(imei: number, new_configuration: PostTmpConfiguration) {
    return super.postTmpConfiguration(imei, new_configuration);
  }

  /** */
  public create_bulk_order$(order_data: OrderData) {
    return super.postBulkOrder(order_data).pipe(
      tap(response => {
        const device_imeis = response?.dids;
        device_imeis?.map(imei => this._cache.get(imei)).forEach(device => device.force_reload());
      })
    );
  }

  /** */
  public create_OTA_bulk$(ota_data: OtaData) {
    return super.postOtaBulk(ota_data);
  }

  /** */
  public create_device_supports$(imei: number, body?: UpdateDeviceSupportIssue) {
    return super.addSupport(imei, body).pipe(
      switchMap(response =>
        this.requestDevice(imei).pipe(
          tap(device => {
            const current_supports = device?.supports ?? <RawDeviceSupports>{};

            if (isNil(current_supports?.open)) {
              current_supports.open = [];
            }

            current_supports.open.push(response.support);
            device.supports = current_supports;
          }),
          map(() => response)
        )
      )
    );
  }

  // #endregion

  // #region -> (request methods)

  /** */
  public fetch_new_device$() {
    return super.getNewDevices().pipe(
      map(response => response.new_devices),
      map(devices =>
        devices.map(device => {
          if (!isNil(device?.time)) {
            device.time = parseDate(device.time);
          }

          return device;
        })
      )
    );
  }

  /** */
  public fetch_device_tmp_conf$(imei: number, config_id: string) {
    return super.getTmpConfiguration(imei, config_id);
  }

  /** */
  public fetch_device_tmp_confs$(imei: number, type?: string, all?: boolean, now?: boolean, at_date?: Date) {
    return super.listTmpConfigurations(imei, type, all, now, at_date);
  }

  /** */
  public fetch_device_simple_conf$(imei: number) {
    return super.getSimplifiedConfiguration(imei);
  }

  /** */
  public fetch_device_full_conf$(imei: number) {
    return super.getConfiguration(imei).pipe(map(conf => (<any>conf).configuration)) as Observable<DeviceFullConfiguration>;
  }

  /** */
  public fetch_OTA_bulk$(device_ids: number[]) {
    return super.getOtaBulk(device_ids);
  }

  /** */
  public fetch_device_supports$(imei: number, limit?: number, offset?: number, query?: Dictionary<any>) {
    const query_string = isNil(query) || isEmpty(query) ? undefined : JSON.stringify(query);

    return super.listSupports(imei, limit, offset, query_string);
  }

  /** */
  public fetch_notifications_for$(user_id: number, pagination?: Paging) {
    return super.gotNotificationFor(user_id, pagination?.offset ?? undefined, pagination?.limit ?? undefined);
  }

  /** */
  public fetch_device_timeseries$(imei: number, measurements: string[], start: Date, end: Date, step: string) {
    return super.getDeviceTimeseries(imei, measurements, start, end, step);
  }

  /**
   * Fetch complete device affectation history.
   */
  public fetch_device_affectation_history$(imei: number, end?: Date, start?: Date) {
    return this.getDeviceAffectationHistory(imei, end, start);
  }

  /** */
  public fetch_device_ack_history$(imei: number, start?: Date, end?: Date, query?: string, offset?: number, limit?: number) {
    return super
      .getConfigurationAckHistory(imei, start, end, query, offset, limit)
      .pipe(catchError(() => of(<AckConfigurationHistoryReturn>{ start, end, paging: null, query, ack_configurations: [] })));
  }

  /**
   * Fetch device credentials.
   *
   * @param imei IMEI of the device to get the credentials.
   *
   * @returns
   */
  public fetch_device_credentials$(imei: number): Observable<ListCredentialsResponse> {
    return super.listCredentials(imei).pipe(catchError(() => of(null)));
  }

  public old_get_devices$(
    device_imeis?: number[],
    query?: string,
    offset?: number,
    limit?: number,
    last_measurements?: string[],
    last_measurements_detail?: string[],
    include?: string[],
    sort?: string[]
  ) {
    const use_include = union(this.default_include, include ?? []);
    const use_last_measurements = union(this.default_last_measurements, last_measurements ?? []);
    const use_last_measurements_details = union(this.default_last_measurements_details, last_measurements_detail ?? []);

    if (device_imeis?.length === 0) {
      return of({
        devices: [],
        paging: {
          total: 0,
          limit: 0,
          offset: 0,
        },
      } as GetDevicesResponse);
    }
    return this.getDevices(device_imeis, query, offset, limit, use_last_measurements, use_last_measurements_details, use_include, sort);
  }

  /**
   * Requests a specific device.
   *
   * @param device_imei The IMEI of the device to request.
   * @param include
   *
   * @returns Returns an observable on the requested device.
   */
  public requestDevice(device_imei: number, query_params?: DeviceQueryParams): Observable<DRDevice> {
    const use_include = union(this.default_include, query_params?.include ?? []);
    const use_last_measurements = union(this.default_last_measurements, query_params?.last_measurements ?? []);
    const use_last_measurements_details = union(this.default_last_measurements_details, query_params?.last_measurements_details ?? []);

    const params: DeviceQueryParams = {
      include: use_include,
      last_measurements: use_last_measurements,
      last_measurements_details: use_last_measurements_details,
    };

    if (this._cache.has(device_imei)) {
      return of<DRDevice>(this._cache.get(device_imei));
    }

    return this.getDevice(device_imei, params.last_measurements, params.last_measurements_details, params?.include).pipe(
      map((ret: any) => this.deserialize(ret.device, params))
    );
  }

  /**
   * Requests all devices without IMEI distinction.
   *
   * @param query
   * @param pagination
   * @param config
   * @param sort
   *
   * @returns
   */
  public requestAllDevices(
    query: { [key: string]: any },
    pagination: PagingQuery,
    config: DeviceQueryParams,
    sort: string[]
  ): Observable<GetDevicesResponse> {
    const use_include = union(this.default_include, config?.include ?? []);
    const use_last_measurements = union(this.default_last_measurements, config?.last_measurements ?? []);
    const use_last_measurements_details = union(this.default_last_measurements_details, config?.last_measurements_details ?? []);

    return this.getDevices(
      undefined,
      JSON.stringify(query),
      pagination.offset,
      pagination.limit,
      use_last_measurements,
      use_last_measurements_details,
      use_include,
      sort
    ).pipe(
      map((response: Dictionary<any>) => {
        const resultant = (response?.devices || []).reduce(
          (result: { in_cache: number[]; not_in_cache: DeviceInterface[] }, device_interface: DeviceInterface) => {
            if (this._cache.has(device_interface.imei)) {
              result.in_cache.push(device_interface.imei);
            } else {
              result.not_in_cache.push(device_interface);
            }

            return result;
          },
          { in_cache: [], not_in_cache: [] }
        );

        const in_cache_devices = resultant.in_cache.map((imei: number) => {
          let cached_device = this._cache.get(imei);
          cached_device.deserialize(find(response.devices, (iface: DeviceInterface) => iface.imei === imei));
          return cached_device;
        });

        const not_in_cache_devices = this.deserializeList(resultant.not_in_cache, <DeviceQueryParams>{
          include: use_include,
          last_measurements: use_last_measurements,
          last_measurements_details: use_last_measurements_details,
        });

        const final_devices = [...in_cache_devices, ...not_in_cache_devices];

        return {
          devices: final_devices,
          paging: response.paging,
        } as GetDevicesResponse;
      })
    );
  }

  private readonly request_devices_batcher = new HttpBatch<DRDevice[], DeviceQueryParams>({
    logger: {
      name: 'HTTP_BATCH_REQUEST_DEVICES',
      active: false,
    },
    batch_requests: requests_by_query_parameters => {
      const queries$$ = requests_by_query_parameters.map(request => {
        const ids_aoa = request.requests.map(req => req.object_ids);
        const ids = uniq(flatten(ids_aoa));

        // Do not send a request to get no devices
        if (ids.length == 0) {
          return of({
            devices: [],
          });
        }

        return super.getDevices(
          ids,
          undefined,
          0,
          -1,
          request.params.last_measurements,
          request?.params?.last_measurements_details,
          request?.params?.include,
          undefined
        );
      });

      // Send query to server
      const query_responses$$ = forkJoin(queries$$);

      // Dispatch response to requests
      const dispatch_entity_by_request$$ = query_responses$$.pipe(
        map(query_responses => {
          const dispatched_response: RequestInQueue<DRDevice[], DeviceQueryParams>[] = [];

          requests_by_query_parameters.forEach(
            (current: RequestsToBatchByQueryParameters<DRDevice[], DeviceQueryParams>, index: number) => {
              const responses_for_index = query_responses[index];

              current.requests.forEach(request => {
                const devices = responses_for_index.devices.filter(device => request.object_ids.includes(device.imei));
                const response = this.deserializeList(devices, request.request_parameters);

                dispatched_response.push({
                  ...request,
                  response: response,
                });
              });
            }
          );

          return dispatched_response;
        })
      );

      return dispatch_entity_by_request$$;
    },
  });

  /**
   * Resquests a list of devices.
   *
   * @param device_imeis The devices' IMEI to request.
   *
   * @returns Returns an observable on the requested devices.
   */
  public requestDevices(device_imeis: number[]): Observable<DRDevice[]> {
    const known_device_ids: Dictionary<any> = {};
    const known_devices = device_imeis
      .filter(imei => this._cache.has(imei))
      .map(imei => this._cache.get(imei))
      .map(device => {
        known_device_ids[device.imei] = device;
        return device;
      });

    const new_device_ids = device_imeis.filter(imei => !this._cache.has(imei));

    if (known_devices.length === device_imeis.length) {
      return of(known_devices);
    }

    const params: DeviceQueryParams = {
      include: this.default_include,
      last_measurements: this.default_last_measurements,
      last_measurements_details: this.default_last_measurements_details,
    };

    return this.request_devices_batcher.add_and_wait_request(new_device_ids, params).pipe(
      map(devices_aoa => flatten(devices_aoa)),
      map(devices => {
        if ((known_devices?.length ?? []) === 0) {
          return devices;
        }

        return devices.concat(known_devices);
      })

      // return forkJoin(requests).pipe(
      //   map(rets => flatten(rets.map(ret => ret.devices))),
      //   map(raw_devices => {
      //     this.deserializeList(raw_devices, params);
      //     const devices = device_imeis
      //       .map((imei: any) => {
      //         if (this._cache.has(imei)) {
      //           // Note: all devices should be in cache
      //           return this._cache.get(imei);
      //         } else if (has(known_device_ids, imei)) {
      //           // except if cache expire since last check...
      //           return known_device_ids[imei];
      //         } else {
      //           return null;
      //         }
      //       })
      //       .filter((device: any) => !isNil(device));
      //     return devices;
      //   })
      // );
    );
  }

  /**
   * Requests all unassociated devices.
   *
   * @param include
   *
   *  @returns
   */
  public getUnassociatedDevices(include: any): Observable<DRDevice[]> {
    const params: DeviceQueryParams = { include };
    const query = JSON.stringify({ warehouse: null });

    return this.getDevices(undefined, query, undefined, undefined, include, undefined).pipe(
      map((ret: any) => this.deserializeList(ret.devices, params))
    );
  }

  public putBulkOwner(put_bulk_owner_data: PutBulkOwnerData, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
    return super.putBulkOwner(put_bulk_owner_data, observe, reportProgress).pipe(
      tap(() => {
        //NOTE: manual update device owner on local device models
        const new_owner = put_bulk_owner_data.owner;
        put_bulk_owner_data.dids
          .filter(did => this._cache.has(did))
          .map(did => this._cache.get(did))
          .map(device => (device.owner = new_owner));
      })
    );
  }

  // #endregion

  // #region -> (update methods)

  /** */
  public update_device$(imei: number, device_body: Dictionary<any>, fields?: (keyof typeof DRDevice.prototype)[]) {
    return super.updateDevice(imei, <any>device_body).pipe(
      map(response => {
        const device = this.deserialize(<any>response, {}, fields);
        return device;
      })
    );
  }

  /** */
  public update_device_tmp_conf$(imei: number, configuration_id: string, new_configuration: PostTmpConfiguration) {
    return super.updateTmpConfiguration(imei, configuration_id, new_configuration);
  }

  /** */
  public update_device_simple_conf$(imei: number, configuration: Sconf) {
    return super.setSimplifiedConfiguration(imei, configuration);
  }

  /** */
  public update_device_full_conf$(imei: number, configuration: Fconf) {
    return super.setConfiguration(imei, configuration);
  }

  /** */
  public update_device_support$(imei: number, support_id: string, body?: UpdateDeviceSupportIssue) {
    return super.updateSupport(imei, support_id, body).pipe(
      switchMap(response =>
        this.requestDevice(imei).pipe(
          tap(device => {
            const current_supports = device?.supports ?? <RawDeviceSupports>{};
            const opened_supports = current_supports.open;

            if (body.is_open === false) {
              const index = opened_supports.findIndex(support => support.id === support_id);

              if (index < 0) {
                return;
              }

              opened_supports.splice(index, 1);
              current_supports.open = opened_supports;
              device.supports = current_supports;
            }
          }),
          map(() => response)
        )
      )
    );
  }

  /** */
  public update_battery_change$(imei: number, body?: PutBatCh) {
    return super.updateBatCh(imei, body);
  }

  // #endregion

  // #region -> (deletion methods)

  /** */
  public delete_device$(imei: number) {
    return super.deleteDevice(imei);
  }

  /** */
  public delete_device_tmp_conf$(imei: number, conf_id: string) {
    return super.deleteTmpConfiguration(imei, conf_id);
  }

  /** */
  public delete_battery_change$(imei: number, time?: Date, force?: boolean) {
    return super.deleteBatCh(imei, time, force);
  }

  // #endregion

  // #region -> (serialization / deserialization)

  /**
   * Deserializes a device interface to a device.
   *
   * @param input
   * @param params
   *
   * @returns
   */
  public deserialize(input: DeviceInterface, params: DeviceQueryParams, fields?: (keyof typeof DRDevice.prototype)[]): DRDevice {
    const imei = input.imei;
    let device: DRDevice = null;

    if (this._cache.has(imei)) {
      device = this._cache.get(imei);
      device.deserialize(input, fields);

      return device;
    }

    switch (input.type) {
      case 'GPS': {
        device = new GPSDevice(this, params);
        break;
      }

      case 'TG': {
        device = new TGDevice(this, params);
        break;
      }

      case 'WG': {
        device = new WGDevice(this, params);
        break;
      }

      case 'RG': {
        device = new RGDevice(this, params);
        break;
      }

      case 'CPT' as any: {
        device = new CPTDevice(this, params);
        break;
      }

      case 'CPTMC' as any: {
        device = new CPTMCDevice(this, params);
        break;
      }

      case 'Compute': {
        device = new ComputeDevice(this, params);
        break;
      }

      case 'BloomLive': {
        device = new BloomLiveDevice(this, params);
        break;
      }

      case 'BeeLive': {
        device = new BeeLiveDevice(this, params);
        break;
      }

      default: {
        device = new NADevice(this, params);
        this._logger.warn(
          `Device/IMEI:"${imei ?? '???'}" of type "${input.type}" has been deserialized as NADevice (not application device) `
        );
        break;
      }
    }

    device.deserialize(input, fields);
    device.set_zoho_apis(this._zohoApis);
    this._cache.add(imei, device);

    return device;
  }

  /**
   * Deserializes a list of devices' interface to a list of devices.
   *
   * @param device_interfaces
   * @param params
   *
   * @returns
   */
  public deserializeList(device_interfaces: DeviceInterface[], params: DeviceQueryParams): DRDevice[] {
    return device_interfaces.map(device_interface => this.deserialize(device_interface, params));
  }

  // #endregion
}
