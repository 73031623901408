<div class="bg2-navigate-visit-aspects-component">
  <ng-container *ngFor="let aspect of aspects$$ | async">
    <div class="aspect" [class.is-current]="(current_aspect$$ | async) === aspect" (click)="when_aspect_changed.emit(aspect)">
      <ng-container [ngSwitch]="aspect">
        <bg2-picon-queen-color *ngSwitchCase="'queen'" [size_w]="'24px'" [queen_color]="'all'" [show_years]="false"></bg2-picon-queen-color>

        <span *ngSwitchDefault class="mdi mdi-24px mdi-account-hard-hat"></span>
      </ng-container>
      <span>{{aspect}}</span>
    </div>
  </ng-container>
</div>
