import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

import { marker as i18n } from '@biesbjerg/ngx-translate-extract-marker';

import { strEnum } from 'app/misc/tools';

import {
  Bg2SimpleHistogramBaseComponent,
  SimpleHistogramConfig,
} from 'app/widgets/widgets-reusables/charts/histograms/simple-histogram-base/simple-histogram-base.component';
import { ErrorHelperData } from 'app/widgets/widgets-reusables/errors/error-helper/error-helper.component';

const hive_super = strEnum(['0', '1', '2', '3', '4', '4+', '-1']);
export type HiveSuper = keyof typeof hive_super;

export type HiveSupersData = { [key in HiveSuper]: number };

@Component({
  selector: 'bg2-evaluation-histogram-hive-supers',
  templateUrl: '../../../../../../widgets/widgets-reusables/charts/histograms/simple-histogram-base/simple-histogram-base.component.html',
  styleUrls: ['../../../../../../widgets/widgets-reusables/charts/histograms/simple-histogram-base/simple-histogram-base.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class Bg2EvaluationHistogramHiveSupersComponent extends Bg2SimpleHistogramBaseComponent implements OnInit {
  @Input()
  public set supers(supers: HiveSupersData) {
    this.histogram_data = supers;
  }

  // #region -> (component basics)

  constructor() {
    super();
    this.histogram_type = 'hive-super';
  }

  ngOnInit(): void {
    super.ngOnInit();

    this.histogram_configuration = new Map([
      ['0', { key: '0', label: 'EVENT.VISIT.SUPERS.No supers', img: 'assets/img/super/super_0.svg' }],
      ['1', { key: '1', label: 'EVENT.VISIT.SUPERS.1 super', img: 'assets/img/super/super_1.svg' }],
      ['2', { key: '2', label: 'EVENT.VISIT.SUPERS.2 supers', img: 'assets/img/super/super_2.svg' }],
      ['3', { key: '3', label: 'EVENT.VISIT.SUPERS.3 supers', img: 'assets/img/super/super_3.svg' }],
      ['4', { key: '4', label: 'EVENT.VISIT.SUPERS.4 supers', img: 'assets/img/super/super_4.svg' }],
      ['4+', { key: '4+', label: 'EVENT.VISIT.SUPERS.4+ supers', img: 'assets/img/super/super_4+.svg' }],
      ['-1', { key: '-1', label: 'EVENT.TYPE.superbox_stock', img: 'assets/img/super/super_stock.svg' }],
    ]) as SimpleHistogramConfig;

    this.has_data$$.subscribe({
      next: has_data => {
        if (!has_data) {
          this.error = new ErrorHelperData([{ type: 'span', content: i18n<string>('ALL.DATA.MISC.No data') }]);
        } else {
          this.error = null;
        }
      },
    });
  }

  // #endregion
}
