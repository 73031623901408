import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit } from '@angular/core';

import { isNil, uniqueId } from 'lodash-es';

import { ResizedEvent } from 'app/misc/directives/resized/resized.directive';

import { debounceTime, filter, map, switchMap, tap, withLatestFrom } from 'rxjs';
import { BehaviorSubject, combineLatest, Subscription } from 'rxjs';
import { distinctUntilRealChanged, replay } from '@bg2app/tools/rxjs';

import { ConsoleLoggerService } from 'app/core/console-logger.service';

const d3 = require('d3plus-text');

interface Sizing {
  width: number;
  height: number;
}

interface AdaptiveTextOptions {
  color?: string;

  fontMax?: number;
  fontMin?: number;
  fontWeight?: number;

  textAnchor?: 'start' | 'middle' | 'end';
}

@Component({
  selector: 'bg2-adaptive-text-to-container',
  templateUrl: './adaptive-text-to-container.component.html',
  styleUrls: ['./adaptive-text-to-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class Bg2AdaptiveTextToContainerComponent implements OnInit, OnDestroy {
  @Input()
  public set text_to_adapt(text_to_adapt: string) {
    this._text_to_adapt$$.next(text_to_adapt);
  }

  private _text_to_adapt$$ = new BehaviorSubject<string>(null);
  public text_to_adapt$$ = this._text_to_adapt$$.asObservable().pipe(
    filter(text_to_adapt => !isNil(text_to_adapt)),
    distinctUntilRealChanged()
  );

  @Input()
  public set options(options: AdaptiveTextOptions) {
    this._options$$.next(options);
  }

  private _options$$ = new BehaviorSubject<AdaptiveTextOptions>({});
  public options$$ = this._options$$.asObservable().pipe(distinctUntilRealChanged());

  // #region -> (component basics)

  private readonly _logger = new ConsoleLoggerService('Bg2AdaptiveTextToContainerComponent', false);
  public readonly adaptive_text_id = uniqueId('adaptive-text-svg-');

  private _sizing_x_text_sub: Subscription = null;

  constructor() {}

  ngOnInit(): void {
    this._sizing_x_text_sub = combineLatest([this.text_to_adapt$$, this.sizing$$, this.options$$])
      .pipe(tap(() => this._is_loading$$.next(true)))
      .subscribe({
        next: ([text, sizing, options]) => {
          new d3.TextBox()
            .select('#' + this.adaptive_text_id)
            .data([{ text, resize: true }])
            .fontResize((d: any) => d.resize)
            .width(sizing.width)
            .height(sizing.height)
            .fontMin(options?.fontMin || 15)
            .fontMax(options?.fontMax || 35)
            .fontColor(options?.color || 'white')
            .textAnchor(options?.textAnchor ?? 'start')
            .fontWeight(options?.fontWeight ?? 'normal')
            .fontFamily(['Source Sans Pro', 'sans-serif'])
            .verticalAlign('middle')
            .render();
          this._is_loading$$.next(false);
        },
        error: (error: unknown) => this._logger.error(error),
      });
  }

  ngOnDestroy(): void {
    this._sizing_x_text_sub?.unsubscribe();
  }

  // #endregion

  // #region -> (loading management)

  private _is_loading$$ = new BehaviorSubject(true);
  public is_loading$$ = this._is_loading$$.asObservable().pipe(distinctUntilRealChanged());

  // #endregion

  // #region -> (sizing management)

  private _sizing$$ = new BehaviorSubject<ResizedEvent>(null);
  public sizing$$ = this._sizing$$.asObservable().pipe(
    debounceTime(100),
    map(event => {
      if (isNil(event)) {
        return null;
      }

      return { width: event?.new_width, height: event?.new_height } as Sizing;
    }),
    filter(sizing => !isNil(sizing)),
    distinctUntilRealChanged(),
    replay()
  );

  public onContainerResized(event: ResizedEvent): void {
    this._sizing$$.next(event);
  }

  // #endregion
}
