import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit } from '@angular/core';

import { marker as i18n } from '@biesbjerg/ngx-translate-extract-marker';

import { BehaviorSubject, combineLatest, debounceTime, forkJoin, map, Observable, of, Subscription, switchMap, take, tap, filter } from 'rxjs';

import { find, isEmpty, isNil } from 'lodash-es';
import { TranslateService } from '@ngx-translate/core';

import { anyTrue, distinctUntilRealChanged, replay } from '@bg2app/tools/rxjs';

import { TableBaseComponent } from 'app/widgets/misc-widgets/table-base/table-base.component';

import { DRDevice, Paging, User } from 'app/models';
import { ColumnsRunTimeConfig, DatatableColumn } from 'app/models/misc/datatable';

import { SidenavService } from 'app/core/sidenav.service';
import { ExcelExportService } from 'app/core/export/excel-export.service';
import { AppStateService } from 'app/core/app-state.service';

import { Dictionary } from 'app/typings/core/interfaces';

import { DatatableBaseRow } from 'app/typings/datatable/interfaces/DatatableBaseRow.iface';
import { ExportFileExtension } from 'app/models/export';
import { NotifiedDevicesDatatableFilters } from './notified-devices-datatable.filters';
import { ConsoleLoggerService } from 'app/core/console-logger.service';
import { DeviceApi } from 'app/core';
import { GotNotifivationForDevice } from 'app/core/api-swagger/device';
import { UrlParamsService } from 'app/core/url-param.service';


// We add here all notifications "why" to ensure translation
i18n("DEVICE.NOTIFICATION.WHY.device_owner");
i18n("DEVICE.NOTIFICATION.WHY.device_subscription");
i18n("DEVICE.NOTIFICATION.WHY.exploitaion_ace");
i18n("DEVICE.NOTIFICATION.WHY.location_ace");


interface DeviceRow extends DatatableBaseRow {
  device: DRDevice;
  notified_device: GotNotifivationForDevice;
}

@Component({
  selector: 'bg2-notified-devices-datatable',
  templateUrl: 'notified-devices-datatable.component.html',
  styleUrls: ['notified-devices-datatable.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotifiedDevicesDatatableComponent extends TableBaseComponent<DeviceRow> implements OnInit, OnDestroy {
  /** */
  private readonly LOGGER = new ConsoleLoggerService('NotifiedDevicesDatatableComponent', false);

  /** */
  public readonly COLUMS_DEFINITION = <DatatableColumn<DeviceRow>[]>[
    {
      property: 'device.name',
      server_sort_key: 'name',
      short_property: 'dvc_nm',
      label: i18n<string>('DEVICE.ALL.COMMON.Device name'),
      displayed_by_default: true,
      not_configurable: false,
      is_sortable: false,
      is_groupable: false,
    },
    {
      property: 'notified_device.why',
      short_property: 'not_dvc_why',
      label: i18n<string>('DEVICE.NOTIFICATION.Reason'),
      displayed_by_default: true,
      not_configurable: false,
      is_sortable: false,
      is_groupable: false,
    },
    {
      property: 'device.last_com',
      short_property: 'dvc_ls_cm',
      label: i18n<string>('DEVICE.ALL.COMMUNICATION.last communication'),
      displayed_by_default: true,
      not_configurable: false,
      is_sortable: false,
      is_groupable: false,
    },
  ];

  // #region -> component basics

  /** */
  private _devices_data_sub: Subscription = null;

  constructor(
    public appState: AppStateService,
    public sidenav: SidenavService<ColumnsRunTimeConfig, ColumnsRunTimeConfig>,
    private _translate: TranslateService,
    private readonly _devices_api: DeviceApi,
    private readonly _export: ExcelExportService,
    private readonly _url_params: UrlParamsService
  ) {
    super(_translate, _url_params);
  }

  /** */
  ngOnInit(): void {
    // Loads datatable columns
    this.all_columns = this.COLUMS_DEFINITION;

    // Subscribes to devices data
    this._devices_data_sub = combineLatest({
      sort: of([]),
      query: of({}),
      paging: this.query_paging$$,
    })
      .pipe(
        distinctUntilRealChanged(),
        tap(() => (this.loading = true)),
        debounceTime(100), // Avoid to troger many requests when rapid changes occors
        switchMap(({ sort, query, paging }) => this.load_device_rows(sort, query, paging))
      )
      .subscribe({
        next: (data_rows: DeviceRow[]) => {
          this.error = null;

          this.selection_model.clear();
          this.paged_data_rows = data_rows;

          // Update loading
          this.loading = false;
        },
        error: (error: unknown) => {
          this.error = error;
        },
      });
  }

  ngOnDestroy(): void {
    this._devices_data_sub?.unsubscribe();
  }

  // #endregion

  // #region -> (user)

  private _user$$ = new BehaviorSubject<User>(null);
  public user$$ = this._user$$.pipe(
    filter(user => !isNil(user)),
    replay()
  );

  public user_id$$ = this.user$$.pipe(
    map(user => user?.user_id),
    replay()
  )

  @Input()
  public set user(user: User) {
    this._user$$.next(user);
  }

  // #endregion

  // #region -> (datatable filtering)

  /** */
  public filters = new NotifiedDevicesDatatableFilters();

  /** */
  public user_query$$ = this.filters.apply(<any>{}).pipe(replay());

  // #endregion

  // #region -> (related users management)

  /** */
  public assert_device(device: any): DRDevice {
    if (isNil(device)) {
      return null;
    }

    return device instanceof DRDevice ? device : null;
  }

  /** */
  public assert_notified_device(notified_device: any): GotNotifivationForDevice {
    return notified_device;
  }

  /** */
  private query_notified_devices$(query: Dictionary<any>, pagination: Paging, sort: string[]) {
    return this.user_id$$.pipe(switchMap(user_id => this._devices_api.fetch_notifications_for$(user_id, pagination)));
  }

  /** */
  private load_device_rows(sort: string[], query: Dictionary<any>, _paging: Paging): Observable<DeviceRow[]> {
    return this.query_notified_devices$(query, _paging, sort).pipe(
      tap(({ paging }) => {
        if (paging?.offset >= paging?.total) {
          paging.offset = 0;
        }
        this.paging = <Paging>paging; //TODO: modify api to indicate all paging fields are always present
      }),
      map(got_notification_for_devices_response => got_notification_for_devices_response?.devices),
      switchMap(notified_devices => {
        const imeis = notified_devices.map(notified_device => notified_device.imei);

        return this._devices_api
          .old_get_devices$(
            imeis,
            undefined,
            0,
            _paging.limit,
            ['message', 'gateway_message', 'sensor_message', 'location', 'location_AGG_GPS', 'energy', 'weight', 'env'],
            ['sensor_message_AGG_as_gateway'],
            ['beeguard_setup']
          )
          .pipe(
            map(devices_response => devices_response.devices),
            map(devices => this._devices_api.deserializeList(devices, {})),
            map(devices => {
              const notified_devices_rows = notified_devices.map(notified_device => {
                const device = find(devices, { imei: notified_device.imei });
                return { notified_device, device };
              });

              return notified_devices_rows;
            })
          );
      }),
      replay()
    );
  }

  // #endregion

  // #region -> (export users)

  public exportData(type: ExportFileExtension): void {
    this._is_exporting$$.next(true);
    this._export.setExportStep('export_triggered');

    forkJoin([this.sorting_model$$.pipe(take(1)), this.user_query$$.pipe(take(1))])
      .pipe(
        switchMap(([sort, query]) => {
          const data_to_fetch$$ = this.load_device_rows(sort?.server_sort_key, query, { offset: 0, limit: -1, total: undefined }).pipe(take(1));

          this._export.setExportStep('ask_authorization');
          this._export.setExportStep('querying_data');

          return data_to_fetch$$.pipe(
            tap(() => this._export.setExportStep('building_data')),
            tap(users => (this.filtered_data_rows = users)),
            tap(() => this._export.setExportStep('preparing_export')),
            switchMap(() => combineLatest([this.export_headers$$, this.export_data$$, this.splitted_by$$]).pipe(take(1)))
          );
        })
      )
      .subscribe({
        next: ([headers, data, split]) => {
          if (!isEmpty(data) || !isNil(data)) {
            this._export.setHeaders(headers);
            this._export.setData(data, split);
            this._export.generateExcel({ title: i18n<string>('CORE.SERVICES.EXPORT.Export of users') }, type);
          } else {
            this._export.setExportStep('abort');
          }
          this._is_exporting$$.next(false);
          this.error = null;
        },
        error: (error: unknown) => {
          console.log(error);
          this._is_exporting$$.next(false);
          this.error = error;
          this._export.setExportStep('abort');
        },
      });
  }

  // #endregion

  // #region -> (component loadings)

  /** */
  private _loading$$: BehaviorSubject<boolean> = new BehaviorSubject(true);

  /** */
  private set loading(val: boolean) {
    this._loading$$.next(val);
  }

  /** */
  public loading$$ = anyTrue(this._loading$$.pipe(distinctUntilRealChanged())).pipe(replay());

  // #endregion
}
