import { Injectable } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';
import { marker as i18n } from '@biesbjerg/ngx-translate-extract-marker';

import { Cell, Column, Row, TableColumnProperties, Workbook, Worksheet } from 'exceljs';

import { saveAs } from 'file-saver';

import { clone, flatten, groupBy, isNil } from 'lodash-es';

import { AppStateService } from 'app/core/app-state.service';
import { ConsoleLoggerService } from 'app/core/console-logger.service';

import { ExportFileExtension, ExportHeader } from 'app/models/export';

import { CONFIG_EXPORT_FORMAT_TYPE } from 'app/typings/export';
import { Dictionary } from 'app/typings/core/interfaces';
import { EXPORT_LOGO } from 'app/typings/misc/export-logo';
import { parseDateForXls, strEnum } from 'app/misc/tools';
import { BehaviorSubject, Observable } from 'rxjs';
import { replay } from '@bg2app/tools/rxjs';
import { map, tap } from 'rxjs';

import { ExportRunState } from './models/export-runner';
import { RunnersService } from '../runners';

export type ExcelDataLine = any[];
export type ExcelDataLines = ExcelDataLine[];
export type ExcelHeaderLine = ExportHeader[];

export type ExcelDataPage = { split_by: string; data: ExcelDataLines };
export type ExcelDataPages = ExcelDataPage[];

export const ExcelExportStep = strEnum([
  'export_triggered',
  'ask_authorization',
  'querying_data',
  'building_data',
  'preparing_export',
  'building_doc',
  'done',
  'abort',
]);
export type ExcelExportStep = keyof typeof ExcelExportStep;

@Injectable({ providedIn: 'root' })
export class ExcelExportService {
  // #region -> (service basics)

  private readonly _logger = new ConsoleLoggerService(this.constructor.name, true);

  constructor(private _appState: AppStateService, private _translate: TranslateService, _runnersService: RunnersService) {
    _runnersService.registerRunner('export', this.export_runners$$);
  }

  // #endregion

  // #region -> (runners)

  private get run_state(): ExportRunState {
    return this._export_runner$$.getValue();
  }

  private set run_state(runner: ExportRunState) {
    this._export_runner$$.next(runner);
  }

  private _export_runner$$ = new BehaviorSubject<ExportRunState>(null);
  public export_runners$$: Observable<ExportRunState[]> = this._export_runner$$.asObservable().pipe(
    map(run_state => (run_state?.nb_remains === 0 ? null : run_state)),
    map(run_state => (!isNil(run_state) ? [run_state] : [])),
    replay()
  );

  public setExportStep(step: ExcelExportStep): void {
    switch (step) {
      case 'export_triggered': {
        this.run_state = new ExportRunState(7, 7);
        break;
      }

      case 'ask_authorization': {
        const runner = clone(this.run_state);
        runner.nb_remains--;
        this.run_state = runner;
        break;
      }

      case 'querying_data': {
        const runner = clone(this.run_state);
        runner.nb_remains--;
        this.run_state = runner;
        break;
      }

      case 'building_data': {
        const runner = clone(this.run_state);
        runner.nb_remains--;
        this.run_state = runner;
        break;
      }

      case 'preparing_export': {
        const runner = clone(this.run_state);
        runner.nb_remains--;
        this.run_state = runner;
        break;
      }

      case 'building_doc': {
        const runner = clone(this.run_state);
        runner.nb_remains--;
        this.run_state = runner;
        break;
      }

      case 'done': {
        this._headers = null;
        this._data_pages = null;
        this._company_logo_id = null;

        this.run_state = new ExportRunState(0, 0);
        break;
      }

      case 'abort': {
        this._headers = null;
        this._data_pages = null;
        this._company_logo_id = null;

        this.run_state = new ExportRunState(0, 0);
        break;
      }
    }
  }

  // #endregion

  // #region -> (excel basics)

  private readonly EXCEL_CONFIG = {
    sheet: {
      table_initial_line: 7,
    },
  };

  /**
   * Creates a new workbook.
   *
   * @returns Returns the created workbook.
   */
  private _createWorkbook(): Workbook {
    const workbook = new Workbook();

    // Workbook user settings
    workbook.creator = this._appState.user.username;
    workbook.lastModifiedBy = this._appState.user.username;

    // Workbook data settings
    workbook.created = new Date();
    workbook.modified = new Date();

    // Workbook other properties
    workbook.calcProperties.fullCalcOnLoad = true;

    // Loads company logo
    this._company_logo_id = workbook.addImage({ base64: EXPORT_LOGO, extension: 'png' });

    return workbook;
  }

  /**
   * Autofit worksheet columns by width
   *
   * @param worksheet The worksheet to autofit.
   * @param minimalWidth The initial minimal width to use. It's the number of characters that can
   * fit without being cut at the display.
   *
   * @note The first column size is fixed to 31 because of the image width.
   */
  private _autofitSheetColumns(worksheet: Worksheet, minimalWidth = 20): void {
    const calc_col_max_length = (column: Partial<Column>, minimal: number): number => {
      let max_column_length = 0;
      column.eachCell({ includeEmpty: true }, cell => {
        const max_cell_length = cell.numFmt === '@' ? cell?.value?.toString().length ?? 0 : cell?.numFmt?.length ?? 0;
        return (max_column_length = Math.max(max_column_length, minimal, max_cell_length));
      });
      return max_column_length;
    };

    worksheet.columns.forEach((column, index) => {
      if (index === 0) {
        column.width = calc_col_max_length(column, 31);
      } else {
        column.width = calc_col_max_length(column, minimalWidth);
      }
    });
  }

  /**
   * Truncates a phrase by it's middle.
   *
   * @param phrase The phrase to truncate.
   * @param max The max of characters to keep at final. By default, it's 26 (the size of excel sheet name length)
   * @param separator The separator to use as replacement.
   */
  private _middleTruncate(phrase: string, max: number = 26, separator?: string): string {
    if (phrase.length <= max) {
      return phrase;
    }

    separator = separator || '...';

    const sep_length = separator.length;
    const chars_to_show = max - sep_length;
    const front_chars = Math.ceil(chars_to_show / 2);
    const back_chars = Math.floor(chars_to_show / 2);

    return phrase.substr(0, front_chars) + separator + phrase.substr(phrase.length - back_chars);
  }

  /**
   * Builds a file header to add to a specific sheet.
   *
   * @param sheet The sheet which needs the header file.
   * @param title The title of the sheet.
   */
  private _buildSheetHeader(sheet: Worksheet, title: string): void {
    // Add image to the sheet
    sheet.addImage(this._company_logo_id, {
      tl: { col: 0, row: 0 },
      ext: { width: 200, height: 56.57 },
      hyperlinks: {
        tooltip: 'BeeGuard',
        hyperlink: 'https://www.beeguard.fr',
      },
    });

    sheet.getRows(1, 3).forEach((row, index) => {
      const cell = row.getCell(2);

      switch (index + 1) {
        case 1: {
          cell.value = title;
          cell.style = {
            font: {
              bold: true,
              size: 14,
            },
            alignment: {
              horizontal: 'left',
              vertical: 'middle',
            },
          };
          cell.numFmt = '@';
          break;
        }

        case 2: {
          cell.value = parseDateForXls(new Date());
          cell.style = {
            alignment: {
              horizontal: 'left',
              vertical: 'middle',
            },
          };
          cell.numFmt = `"${this._translate.instant(i18n<string>('ALL.COMMON.On'))}" ${this._appState.dl.lll_xls}`;
          break;
        }

        case 3: {
          cell.value = this._translate.instant(i18n<string>('CORE.SERVICES.EXPORT.Exported by [username]'), {
            username: this._appState.user.username,
          });
          cell.numFmt = '@';
          break;
        }
      }

      row.commit();
    });
  }

  // #endregion

  /**
   * Reference to the export headers.
   */
  private _headers: ExcelHeaderLine = null;

  /**
   * Reference to the data lines.
   */
  private _data_pages: ExcelDataPages = null;

  /**
   * The identifier of the company logo.
   */
  private _company_logo_id: number = null;

  /**
   * Checks if data lines has been set.
   */
  private _hasDataPages() {
    return !isNil(this._data_pages);
  }

  /**
   * Checks if headers has been set.
   */
  private _hasHeaders() {
    return !isNil(this._headers);
  }

  /**
   * Sets the headers of the export.
   *
   * @param headers The headers to use for the export.
   */
  public setHeaders(headers: ExcelHeaderLine): void {
    this._headers = headers;
  }

  /**
   * Sets the data to export.
   *
   * @param data_page The data to export.
   * @param split_by The grouped property name. If null, then all the results are displayed on one page.
   */
  public setData(data_page: ExcelDataLines, split_by: string = null): void {
    if (!this._hasHeaders) {
      throw new Error('Cannot set data without setting headers');
    }

    if (isNil(split_by)) {
      this._data_pages = [{ split_by: null, data: data_page }];
      return;
    }

    const index_of_split = this._headers.findIndex(header => header.name === split_by);
    if (index_of_split < 0) {
      throw new Error(`Headers does not contains specified header : ${split_by}`);
    }

    const grouped_data: Dictionary<ExcelDataLines> = groupBy(data_page, value => value[index_of_split]);

    this._data_pages = Object.keys(grouped_data).map((key: string) => {
      const data: ExcelDataLines = grouped_data[key];
      const page_name = (data[0][index_of_split] || '-').toString();
      data.forEach(datum => datum.splice(index_of_split, 1));
      return { split_by: page_name, data } as ExcelDataPage;
    });

    this.setHeaders(this._headers.filter(header => header.name !== split_by));
  }

  /**
   * Generates a new excel or CSV export.
   *
   * @param config The file configuration with `title` for the header and it's i18n params
   * @param extension The extension we want to export.
   *
   * @note Before using this method, you should set the headers and the data.
   */
  public generateExcel(
    config: { title: string; params?: Dictionary<any> },
    extension: ExportFileExtension = ExportFileExtension.XLSX
  ): void {
    if (!this._hasDataPages || !this._hasHeaders) {
      this.setExportStep('abort');
      throw new Error('Missing headers or data for export config.');
    }

    this.setExportStep('building_doc');

    switch (extension) {
      case ExportFileExtension.XLSX: {
        const workbook = this._createWorkbook();

        const i18n_title: string = this._translate.instant(config.title, config?.params || {});
        const i18n_filename = i18n_title.replace(' ', '_').toLowerCase();

        const table_initial_cell = `A${this.EXCEL_CONFIG.sheet.table_initial_line}`;
        const sheet_names: string[] = [];

        this._data_pages.forEach((data_line: ExcelDataPage, index) => {
          let sheet_name: string = '';

          if (data_line.split_by) {
            sheet_names.push(data_line.split_by);
            const same_sheet_name_count = sheet_names.filter(name => name === data_line.split_by).length;

            if (same_sheet_name_count <= 1) {
              sheet_name = `🐝 (${this._middleTruncate(data_line.split_by)})`;
            } else {
              sheet_name = `🐝 (${this._middleTruncate(same_sheet_name_count.toString() + '-' + data_line.split_by)})`;
            }
          } else {
            sheet_name = this._translate.instant(i18n<string>('CORE.SERVICES.EXPORT.All data'))
          }

          const sheet = workbook.addWorksheet(sheet_name.replace(/[\/\\\*\?\[\]]/g, '_'), {
              properties: {},
              views: [
                {
                  state: 'frozen',
                  showGridLines: false,
                  activeCell: table_initial_cell,
                  ySplit: this.EXCEL_CONFIG.sheet.table_initial_line,
                  xSplit: 1,
                },
              ],
            }
          );

          // Adds sheet header
          this._buildSheetHeader(sheet, i18n_title);

          // Display the data
          sheet.addTable({
            name: `datatable_${index}`,
            ref: table_initial_cell,
            headerRow: true,
            totalsRow: false,
            style: {
              theme: 'TableStyleMedium8',
              showRowStripes: true,
            },
            columns: this._headers.map(h => ({ name: h.label, filterButton: true })) as TableColumnProperties[],
            rows: data_line.data || [],
          });

          sheet
            .findRows(this.EXCEL_CONFIG.sheet.table_initial_line, this.EXCEL_CONFIG.sheet.table_initial_line + data_line.data.length)
            .forEach((row: Row, row_index: number) => {
              row.eachCell((cell: Cell) => {
                if (row_index === 0) {
                  cell.style = { alignment: { vertical: 'middle', horizontal: 'center' } };
                  cell.numFmt = '@';
                  return;
                }

                const cfg_column = this._headers[parseInt(cell.col, 10) - 1];
                const format = cfg_column?.export?.format ?? '@';

                if (format === '@') {
                  cell.style = { alignment: { vertical: 'middle', horizontal: 'center' } };
                }

                if (cfg_column?.export?.conditional_fill) {
                  cell.fill = cfg_column.export.conditional_fill(cell.value);
                }

                if (format === CONFIG_EXPORT_FORMAT_TYPE.DATE_FORMAT_LLL) {
                  cell.numFmt = this._appState.dl.lll_xls;
                } else {
                  cell.numFmt = format;
                }
              });

              row.commit();
            });

          // Autofit columns
          this._autofitSheetColumns(sheet);
        });

        workbook.xlsx.writeBuffer().then(buffer => {
          saveAs(
            new Blob([buffer], {
              type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8',
            }),
            `${i18n_filename}_at_${new Date().getTime()}.xlsx`
          );
        });

        break;
      }

      case ExportFileExtension.CSV: {
        const workbook = this._createWorkbook();
        const i18n_title: string = this._translate.instant(config.title, config?.params || {});
        const i18n_filename = i18n_title.replace(' ', '_').toLowerCase();

        const flattened_data = flatten(this._data_pages.map(page => page.data));

        const sheet = workbook.addWorksheet('0');
        sheet.addRows(flattened_data);

        workbook.csv
          .writeBuffer({
            includeEmptyRows: true,
            dateUTC: false,
            encoding: 'UTF-8',
            formatterOptions: {
              delimiter: ';',
              headers: this._headers.map(h => h.label),
            },
          })
          .then(buffer => {
            saveAs(
              new Blob([buffer], {
                type: 'text/csv;charset=UTF-8',
              }),
              `${i18n_filename}_at_${new Date().getTime()}.csv`
            );
          });

        break;
      }
    }

    this.setExportStep('done');
  }
}
