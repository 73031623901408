<div class="widget form-group">
  <!-- <pre>{{this.formProperty.value | json}}</pre> -->
  <div class="array">
    <form-element-header [schema]="schema"></form-element-header>

    <ng-container *ngIf="(redraw$$ | async) === false">
      <div
        *ngFor="let itemProperty of (properties$$ | async); let i=index; trackBy:trackByIndex"
        class="subproperty"
        [class.inline]="options.remove_btn_inline"
      >
        <sf-form-element [formProperty]="itemProperty"></sf-form-element>

        <bg2-button
          *ngIf="options.remove_btn_inline && (canRemove$$ | async)"
          class="array-remove-button inline"
          [type]="'delete'"
          [icon]="'mdi-minus-box'"
          [message]="label_remove | translate"
          (btn_click)="removeItem(itemProperty)"
        >
        </bg2-button>
        <div *ngIf="!options.remove_btn_inline" class="subproperty-footer">
          <a
            *ngIf="canRemove$$ | async"
            href="javascript:void(0)"
            (click)="removeItem(itemProperty)"
            class="array-remove-button"
            [class.inline]="options.remove_btn_inline"
          >
            <i class="mdi mdi-minus-box mdi-24px"></i>
            <span>{{label_remove | translate}}</span>
          </a>
        </div>
      </div>
    </ng-container>

    <div *ngIf="new_property$$ | async; let new_property" class="new-item">
      <sf-form-element [formProperty]="new_property"></sf-form-element>
      <bg2-button
        *ngIf="!options.auto_add && (canAdd$$ | async)"
        class="array-add-button"
        [type]="'action'"
        [icon]="'mdi-plus-box'"
        [message]="((empty$$ | async) ? label_add_first : label_add) | translate"
        (btn_click)="addItemProperty(new_property)"
      >
      </bg2-button>
    </div>
  </div>
</div>
