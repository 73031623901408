<ng-template #loadingApiary>
  <hex-loading [size]="'medium'" [message]="'ROUTABLE_MODALS.APIARY_HIVE_LIST.LOADING.Loading apiary' | translate"></hex-loading>
</ng-template>

<ng-template #hiveDevicesLoading>
  <li>
    <span class="mdi mdi-autorenew mdi-spin mdi-24px"></span>
  </li>
</ng-template>

<bg2-modal [fullscreen]="fullscreen || false" #modal>
  <bg2-modal-header>
    <div class="header">
      <span class="title">{{ 'VIEWS.MODALS.APIARY_HIVE_LIST.Hives and devices management' | translate }}</span>
    </div>

    <div class="action-buttons">
      <doc-helper page="Get_started" section="create_hive_nuc" iconSize="36px"></doc-helper>
      <span class="mdi mdi-close-box mdi-36px" (click)="close()"></span>
    </div>
  </bg2-modal-header>

  <bg2-modal-content>
    <ng-container *ngIf="apiary$$ | async; let apiary; else loadingApiary">
      <section id="current-apiary-status">
        <ng-container *ngIf="apiary?.has_no_declared_hives_and_nucs$$ | async">
          {{ 'VIEWS.MODALS.APIARY_HIVE_LIST.There are no hive or nuc declared on the apiary' | translate}}
        </ng-container>

        <span *ngIf="(apiary?.has_no_named_hives_and_nucs$$ | async) && (apiary?.has_declared_hives_or_nucs$$ | async)">
          <ng-container *ngIf="apiary?.has_declared_hives_and_nucs$$ | async">
            {{ 'VIEWS.MODALS.APIARY_HIVE_LIST.There are [nb_hives] hives and [nb_nuc] nucs (unnamed) declared on the apiary' | translate:{
            nb_hives: apiary?.nb_declared_hives$$ | async, nb_nuc: apiary?.nb_declared_nuc$$ | async } }}
          </ng-container>

          <ng-container *ngIf="apiary?.has_only_declared_hives$$ | async">
            {{'VIEWS.MODALS.APIARY_HIVE_LIST.There are [nb_hives] hives (unnamed) declared on the apiary' | translate: { nb_hives:
            apiary?.nb_declared_hives$$ | async } }}
          </ng-container>

          <ng-container *ngIf="apiary?.has_only_declared_nucs$$ | async">
            {{ 'VIEWS.MODALS.APIARY_HIVE_LIST.There are [nb_nuc] nucs (unnamed) declared on the apiary' | translate :{ nb_nuc:
            apiary?.nb_declared_nuc$$ | async } }}
          </ng-container>

          <br />
          <ng-container>{{ 'VIEWS.MODALS.APIARY_HIVE_LIST.You can create them all or part of them here' | translate }}</ng-container>
        </span>

        <ng-container *ngIf="(apiary?.has_not_same_nb_of_declared_and_named_hives_and_nucs$$ | async)">
          <ng-container *ngIf="(apiary?.has_only_declared_hives$$ | async) && (apiary?.has_named_hives$$ | async)">
            {{ 'VIEWS.MODALS.APIARY_HIVE_LIST.There are [nb_hives] hives declared on the apiary where [nb_hives_named] are named :' |
            translate :{ nb_hives: (apiary?.nb_declared_hives$$ | async), nb_hives_named : apiary?.nb_named_hives$$ | async } }}
          </ng-container>

          <ng-container *ngIf="(apiary?.has_only_declared_nucs$$ | async) && (apiary?.has_named_nucs$$ | async)">
            {{ 'VIEWS.MODALS.APIARY_HIVE_LIST.There are [nb_nuc] nuc declared on the apiary where [nb_nuc_named] are named :' | translate:{
            nb_nuc: (apiary?.nb_declared_nuc$$ | async), nb_nuc_named: (apiary?.nb_named_nuc$$ | async) } }}
          </ng-container>

          <ng-container *ngIf="(apiary?.has_declared_hives_and_nucs$$ | async) && (apiary?.has_named_hives_or_named_nucs$$ | async)">
            {{ 'VIEWS.MODALS.APIARY_HIVE_LIST.There are [nb_hives] hives and [nb_nuc] nuc declared on the apiary, including [nb_hives_named]
            hives and [nb_nuc_named] nuc named:' | translate:{ nb_hives: (apiary?.nb_declared_hives$$ | async), nb_nuc:
            (apiary?.nb_declared_nuc$$ | async), nb_hives_named: (apiary?.nb_named_hives$$ | async), nb_nuc_named: (apiary?.nb_named_nuc$$ |
            async) } }}
          </ng-container>
        </ng-container>

        <span *ngIf="apiary?.has_same_nb_of_declared_and_named_hives_and_nucs$$ | async">
          <ng-container *ngIf="apiary?.has_only_declared_nucs$$ | async">
            {{ 'VIEWS.MODALS.APIARY_HIVE_LIST.There are [total] nucs' | translate:{total:(apiary?.nb_declared_nuc$$ | async) } }}
          </ng-container>

          <ng-container *ngIf="apiary?.has_only_declared_hives$$ | async">
            {{ 'VIEWS.MODALS.APIARY_HIVE_LIST.There are [total] hives' | translate:{total: (apiary?.nb_declared_hives$$ | async) } }}
          </ng-container>

          <ng-container *ngIf="apiary?.has_declared_hives_and_nucs$$ | async">
            {{ 'VIEWS.MODALS.APIARY_HIVE_LIST.There are [total_hives] hives and [total_nucs]' | translate:{total_hives:
            (apiary?.nb_named_hives$$ | async), total_nucs: (apiary?.nb_named_nuc$$ | async) } }}
          </ng-container>
        </span>
      </section>

      <div class="loading" *ngIf="(is_hives_loading$$ | async) || (apiary?.dirty$$ | async)">
        <hex-loading [size]="'small'" [message]="'ROUTABLE_MODALS.APIARY_HIVE_LIST.LOADING.Loading hives' | translate"></hex-loading>
      </div>

      <div *ngIf="displayed_hives$$ | async; let displayed_hives;" id="apiary-hives-list">
        <!-- Existing hives entities -->
        <ng-container *ngFor="let hive of displayed_hives; let index = index; trackBy: trackByHive">
          <div class="hive-row" [ngClass]="{ ghost: hive.is_ghost$$ | async, 'hive-edit': is_hive_is_edited$$(hive.id$$) | async }">
            <div class="hive-detail">
              <!-- Hive ordering -->
              <div *ngIf="(is_hive_is_edited$$(hive.id$$) | async) === false" class="selection-and-order">
                <bg2-button
                  [type]="'action'"
                  [color]="'transparent'"
                  [icon]="'mdi-chevron-up'"
                  [disabled]="index - 1 < 0"
                  [options]="{ is_thin: true }"
                  (btn_click)="moveHive(index, -1)"
                ></bg2-button>

                <mat-checkbox [id]="'checkbox-' + index"></mat-checkbox>

                <bg2-button
                  [type]="'action'"
                  [color]="'transparent'"
                  [icon]="'mdi-chevron-down'"
                  [options]="{ is_thin: true }"
                  [disabled]="index + 1 === displayed_hives.length"
                  (btn_click)="moveHive(index, +1)"
                ></bg2-button>
              </div>

              <!-- Hive picon -->
              <bg2-picto-hive size="80px" [hive]="hive"></bg2-picto-hive>

              <div *ngIf="(is_hive_is_edited$$(hive.id$$) | async) === false" class="hive-info-card">
                <header>
                  <h5>
                    {{ hive?.name$$ | async }}

                    <span
                      *ngIf="apiary?.hasACE('write_all_events') && ((edited_hive$$ | async) === null) && (hive?.is_ghost$$ |async) === false"
                      class="simili-link mdi mdi-pencil"
                      [popper]="('VIEWS.MODALS.APIARY_HIVE_LIST.Edit the hive' | translate)"
                      (click)="edit_hive(index)"
                    >
                    </span>

                    <span
                      *ngIf="apiary?.hasACE('read_all_events') && (hive?.is_ghost$$ |async) === false"
                      class="simili-link mdi mdi-format-list-bulleted"
                      [queryParamsHandling]="'preserve'"
                      [popper]="('VIEWS.MODALS.APIARY_HIVE_LIST.See hive history' | translate)"
                      [routerLink]="['', { outlets: {'modal': ['update_entity', {'eid': hive?.id, 'oh': true}]} }]"
                    >
                    </span>
                  </h5>

                  <span *ngIf="(hive?.setup_date$$ | async); let hive_setup_date">
                    <i class="mdi mdi-autorenew mdi-18px" *ngIf="hive.dirty$$ | async"></i>
                    <span>
                      {{ 'VIEWS.MODALS.APIARY_HIVE_LIST.Hive installed on [time]' | translate:{ time: (hive_setup_date | formatDate:'full')
                      } }}
                    </span>

                    <span
                      *ngIf="apiary?.hasACE('read_all_events') && (hive?.is_ghost$$ | async) === false"
                      class="mdi mdi-eye hive-setup-detail"
                      [popper]="eventSpan"
                      [popperTimeout]="100"
                    ></span>

                    <popper-content #eventSpan>
                      <div *ngIf="(hive?.setup_event$$ | async) as setup_event;" style="display: flex; flex-flow: row nowrap; gap: 5px">
                        <bg2-event-span [event]="setup_event" [from]="hive"></bg2-event-span>
                        <a
                          *ngIf="apiary?.hasACE('write_all_events')"
                          [queryParamsHandling]="'preserve'"
                          [routerLink]="['', { outlets: { 'modal': [setup_event.vconf.update_modal, { 'eid': setup_event.id }] } }]"
                        >
                          <i class="mdi mdi-pencil"></i>
                        </a>
                      </div>
                    </popper-content>
                  </span>
                </header>

                <section class="hive-devices">
                  <ul class="device-list">
                    <ng-container *ngIf="(hive?.devices$$ | async) as hive_devices; else hiveDevicesLoading;">
                      <li *ngFor="let hive_device of hive_devices" class="device-row">
                        <bg2-device-inline [show_action]="true" [device]="hive_device"></bg2-device-inline>

                        <div class="device-association-detail">
                          <span *ngIf="hive_device?.hive_config?.since?.date; let in_hive_since_date" class="in-hive-since">
                            {{ 'VIEWS.MODALS.APIARY_HIVE_LIST.installed on [time]' | translate:{time: (in_hive_since_date |
                            formatDate:'short' )} }}
                            <a
                              *ngIf="apiary?.hasACE('write_all_events') && (hive?.is_ghost$$ |async) === false"
                              class="cancel-device-association"
                              [queryParamsHandling]="'preserve'"
                              [routerLink]="['', {
                                    outlets: { 'modal': [ 'update_event',
                                      {'eid': hive_device.hive_config.since.event_id, 'ro': apiary?.hasACE('write_all_events')}
                                    ]}
                                  }]"
                            >
                              <i class="mdi mdi-pencil mdi-18px"></i>
                            </a>
                          </span>

                          <a
                            *ngIf="apiary?.hasACE('write_all_events') && (hive?.is_ghost$$ |async) === false"
                            class="device-takeout-link"
                            (click)="takeoutDevice(hive_device)"
                          >
                            <i class="mdi mdi-arrow-down-box"></i>
                            <span translate>VIEWS.MODALS.APIARY_HIVE_LIST.Remove from this hive</span>
                          </a>
                        </div>
                      </li>

                      <!-- Associate GPS -->
                      <li
                        *ngIf="apiary?.hasACE('write_all_events') && ((hive?.is_ghost$$ |async) === false) && (hive?.has_device_GPS$$ | async) === false"
                      >
                        <a
                          [queryParamsHandling]="'preserve'"
                          [routerLink]="['', {
                              outlets: {'modal': ['new_event',
                                {
                                  'etype': 'device_GPS_install',
                                  'args': ({
                                    'hive': hive.id
                                  } | json)
                                }
                              ]}
                            }]"
                        >
                          <i class="mdi mdi-plus-circle-outline"></i>
                          {{'VIEWS.MODALS.APIARY_HIVE_LIST.Associate a GPS device' | translate}}
                        </a>
                      </li>

                      <!-- Associate WG -->
                      <li *ngIf="apiary.hasACE('write_all_events') && (hive.is_ghost$$ |async) === false">
                        <a
                          [routerLink]="['', {
                              outlets: {'modal': ['new_event',
                                {
                                  'etype': 'devices_WG_install',
                                  'args': ({
                                    'hive': hive.id
                                  } | json)
                                }
                              ]}
                            }]"
                          [queryParamsHandling]="'preserve'"
                        >
                          <i class="mdi mdi-plus-circle-outline"></i>
                          {{ 'VIEWS.MODALS.APIARY_HIVE_LIST.Associate one or more WGuard devices' | translate }}
                        </a>
                      </li>

                      <!-- Associate CPT -->
                      <li
                        *ngIf="apiary.hasACE('write_all_events') && ((hive.is_ghost$$ |async) === false) && (has_cpt_devices$$ | async) && (((hive?.has_device_CPT$$ | async) === false) && ((hive?.has_device_beelive$$ | async) === false))"
                      >
                        <a
                          [routerLink]="['', {
                              outlets: {'modal': ['new_event',
                                {
                                  'etype': 'device_CPT_install',
                                  'args': ({
                                    'hive': hive.id
                                  } | json)
                                }
                              ]}
                            }]"
                          [queryParamsHandling]="'preserve'"
                        >
                          <i class="mdi mdi-plus-circle-outline"></i>
                          {{ 'VIEWS.MODALS.APIARY_HIVE_LIST.Associate a BeeLive (CPT)' | translate }}
                        </a>
                      </li>

                      <!-- Associate BeeLive -->
                      <li
                        *ngIf="apiary.hasACE('write_all_events') && ((hive.is_ghost$$ |async) === false) && (has_beelive_devices$$ | async) && (((hive?.has_device_CPT$$ | async) === false) && ((hive?.has_device_beelive$$ | async) === false))"
                      >
                        <a
                          [routerLink]="['', {
                                outlets: {'modal': ['new_event',
                                  {
                                    'etype': 'device_BeeLive_install',
                                    'args': ({
                                      'hive': hive.id
                                    } | json)
                                  }
                                ]}
                              }]"
                          [queryParamsHandling]="'preserve'"
                        >
                          <i class="mdi mdi-plus-circle-outline"></i>
                          {{ 'VIEWS.MODALS.APIARY_HIVE_LIST.Associate a BeeLive (BeeLive)' | translate }}
                        </a>
                      </li>

                      <!-- Associate TG -->
                      <li
                        *ngIf="apiary.hasACE('write_all_events') && ((hive.is_ghost$$ |async) === false) && (has_tg_devices$$ | async) && (hive?.has_device_tg$$ | async) === false"
                      >
                        <a
                          [routerLink]="['', {
                                outlets: {'modal': ['new_event',
                                  {
                                    'etype': 'device_TG_install',
                                    'args': ({
                                      'hive': hive.id
                                    } | json)
                                  }
                                ]}
                              }]"
                          [queryParamsHandling]="'preserve'"
                        >
                          <i class="mdi mdi-plus-circle-outline"></i>
                          {{ 'VIEWS.MODALS.APIARY_HIVE_LIST.Associate a TG (temperature tag)' | translate }}
                        </a>
                      </li>
                    </ng-container>
                  </ul>
                </section>
              </div>

              <ng-container *ngIf="edited_hive$$ | async; let edited_hive">
                <div *ngIf="is_hive_is_edited$$(hive.id$$) | async" class="hive-info-card">
                  <bg2-form-overlay
                    [source_model]="edited_hive__model$$ | async"
                    [source_schema]="form_schema_for_edited_hive$$ | async"
                    [form_bind]="edited_hive__form_binder.should_bind_form$$ | async"
                    (when_form_data_update)="update_model_for_edited_hive($event)"
                    (is_form_bind)="edited_hive__form_binder.is_form_bind = $event"
                  ></bg2-form-overlay>
                </div>
              </ng-container>
            </div>

            <div *ngIf="is_hive_is_edited$$(hive.id$$) | async" class="hive-color-and-form-valid">
              <bg2-button
                [type]="'action'"
                [icon]="'mdi-content-save'"
                [options]="{ is_thin: true }"
                [message]="'VIEWS.MODALS.FORM.Save' | translate"
                [disabled]="(is_hives_dirty$$ | async) || (editing_hive_has_changed$$ | async) === false"
                (btn_click)="save_edited_hive()"
              ></bg2-button>

              <bg2-button
                [type]="'cancel'"
                [options]="{ is_thin: true }"
                [disabled]="(is_hives_dirty$$ | async)"
                [message]="'VIEWS.MODALS.FORM.Cancel' | translate"
                (btn_click)="cancel_edited_hive()"
              ></bg2-button>

              <span class="spacer"></span>

              <bg2-button-delete-or-archive
                [entity]="hive"
                [thin_button]="true"
                [external_disabled]="(is_hives_dirty$$ | async)"
                (taken_action)="delete_or_archive_edited_hive($event)"
              ></bg2-button-delete-or-archive>
            </div>
          </div>
        </ng-container>

        <!-- New hive entity block -->
        <div *ngIf="new_hive$$ | async; let hive" class="hive-row hive-edit">
          <div class="hive-detail">
            <!-- Hive picon -->
            <bg2-picto-hive size="80px" [hive]="hive" [htype]="hive.tmp_htype$$ | async" [color]="hive.color$$ | async"></bg2-picto-hive>

            <!-- Hive creation form -->
            <div class="hive-info-card">
              <bg2-form-overlay
                [source_model]="new_hive__model$$ | async"
                [source_schema]="form_schema_for_new_hive$$ | async"
                [form_bind]="new_hive__form_binder.should_bind_form$$ | async"
                (when_form_data_update)="update_model_for_new_hive($event)"
                (is_form_bind)="new_hive__form_binder.is_form_bind = $event"
              ></bg2-form-overlay>
            </div>
          </div>

          <div class="hive-color-and-form-valid">
            <bg2-button
              [type]="'action'"
              [icon]="'mdi-content-save'"
              [options]="{ is_thin: true }"
              [message]="'VIEWS.MODALS.FORM.Save' | translate"
              [disabled]="(is_hives_dirty$$ | async) || (editing_hive_has_changed$$ | async) === false"
              (btn_click)="save_new_hive()"
            ></bg2-button>

            <bg2-button
              [type]="'cancel'"
              [options]="{ is_thin: true }"
              [disabled]="(is_hives_dirty$$ | async)"
              [message]="'VIEWS.MODALS.FORM.Cancel' | translate"
              (btn_click)="cancel_new_hive()"
            ></bg2-button>

            <span class="spacer"></span>
          </div>
        </div>
      </div>

      <bg2-button
        [type]="'action'"
        [icon]="'mdi-plus-circle-outline'"
        [message]="'VIEWS.MODALS.APIARY_HIVE_LIST.Setup a new Hive' | translate"
        [disabled]="(new_hive$$ | async) !== null || (is_hives_dirty$$ | async) || !apiary?.hasACE('write_all_events') || (apiary?.dirty$$ | async)"
        (btn_click)="create_new_hive()"
      ></bg2-button>
    </ng-container>

    <hr />

    <ng-container *ngIf="apiary$$ | async; let apiary; else loadingApiary">
      <section>
        <ng-container *ngIf="apiary.devices_rg$$ | async; let weather_stations">
          <span *ngIf="weather_stations.length === 0" translate>VIEWS.MODALS.APIARY_HIVE_LIST.No weather station</span>

          <span *ngIf="weather_stations.length === 1">{{ 'VIEWS.MODALS.APIARY_HIVE_LIST.1 weather station: ' | translate }}</span>

          <span *ngIf="weather_stations.length > 1" [translateParams]="{ number: weather_stations.length }" translate
            >VIEWS.MODALS.APIARY_HIVE_LIST.[number] weather stations:
          </span>

          <ul class="weather-stations-list">
            <li *ngFor="let weather_station of weather_stations" class="weather-station-object">
              <img src="/assets/img/svg_models/weather_station.png" alt="" class="weather-station-icon" />
              <div class="weather-station-informations">
                <div class="ws-name">
                  <span>
                    <a
                      [routerLink]="['', { outlets: { 'modal': [ 'device', { 'imei': weather_station['imei'] } ]}}]"
                      [queryParamsHandling]="'preserve'"
                      >{{weather_station['name']}}
                    </a>
                  </span>
                </div>

                <div *ngIf="$any(weather_station).rg_config.since.date" class="since">
                  <span [translateParams]="{time: ($any(weather_station).rg_config.since.date | formatDate:'short')}" translate
                    >VIEWS.MODALS.APIARY_HIVE_LIST.installed on [time]</span
                  >
                  <a
                    class="mdi mdi-pencil"
                    [routerLink]="['', { outlets: { 'modal': ['update_event', { 'eid': $any(weather_station).rg_config.since.event_id }]}}]"
                    [queryParamsHandling]="'preserve'"
                  >
                  </a>
                </div>

                <div class="remove-from-apiary takeout-rg" (click)="takeoutDevice(weather_station)">
                  <span>(</span>
                  <span class="mdi mdi-arrow-down-box mdi-18px red-text"></span>
                  <span>{{ 'VIEWS.MODALS.APIARY_HIVE_LIST.Remove from this apiary' | translate }}</span>
                  <span>)</span>
                </div>
              </div>
            </li>
          </ul>

          <bg2-button
            [type]="'action'"
            [icon]="'mdi-plus-circle-outline'"
            [message]="'VIEWS.MODALS.APIARY_HIVE_LIST.Add a weather station' | translate"
            [disabled]="weather_stations.length >= 1 || !apiary.hasACE('write_all_events')"
            [routerLink]="['', { outlets: {'modal': ['new_event',{ 'etype': 'device_RG_install', 'args': ({ 'apiary': apiary.id } | json) }]}}]"
            [queryParamsHandling]="'preserve'"
          ></bg2-button>
        </ng-container>
      </section>
    </ng-container>
  </bg2-modal-content>

  <bg2-modal-footer>
    <ng-container *ngIf="apiary$$ | async; let apiary">
      <div *ngIf="(apiary?.dirty$$ | async)" class="loading-footer">
        <i class="mdi mdi-autorenew mdi-spin mdi-24px"></i>
        {{ 'ALL.COMMON.Loading' | translate }}
      </div>
    </ng-container>

    <bg2-button
      class="w-100"
      [type]="'cancel'"
      [icon]="'mdi-close-box'"
      [message]="'VIEWS.MODALS.FORM.close' | translate"
      (btn_click)="close()"
    ></bg2-button>
  </bg2-modal-footer>
</bg2-modal>
