<div class="stackable-cards" #stackableCardGroup>
  <bg2-abstract-carousel-group-card class="abstract-carousel-group-card-visit-summary">
    <div role="stackable-card-header" class="card-header">
      <div *ngIf="event_date$$ | async; let date; else: loadingTitle" class="card-title">
        <bg2-adaptive-text-to-container
          [text_to_adapt]="
            'VIEWS.APIARY.SHARED.WIDGETS.APIARY_EVALUATION_CAROUSEL.SUMMARY.TITLE.Visit of [date] (there was [distance_to_now])'
              | translate
                : {
                    date: date | formatDate: 'short',
                    distance_to_now: date | distanceToNow
                  }
          "
          [options]="{ color: '#242424', fontMin: 18, fontWeight: 600, textAnchor: 'start' }"
        ></bg2-adaptive-text-to-container>

        <bg2-adaptive-text-to-container
          [text_to_adapt]="
            'VIEWS.APIARY.SHARED.WIDGETS.APIARY_EVALUATION_CAROUSEL.SUMMARY.TITLE.by [author]'
              | translate: { author: event_author$$ | async }
          "
          [options]="{ color: '#242424', fontMin: 14, fontMax: 14, fontWeight: 600, textAnchor: 'end' }"
        ></bg2-adaptive-text-to-container>
      </div>

      <ng-template #loadingTitle>
        <bg2-loading-skeleton
          [theme]="{ width: '100%', height: '18px' }"
          [string_model]="'Visit of [date] by [user]'"
        ></bg2-loading-skeleton>
      </ng-template>
    </div>

    <div role="stackable-card-content">
      <bg2-evaluation-resume
        [summary_data]="summary_data$$ | async"
        (when_redirect_to_card)="navigate_to_card($event)"
      ></bg2-evaluation-resume>
    </div>

    <div role="stackable-card-cta">
      <bg2-button
        [type]="'navigation'"
        [message]="'EVENT.VISIT.Visit details' | translate"
        [internal_routing]="{
          routerLink: ['', { outlets: { modal: ['apiary_evaluation', { eid: event_id$$ | async }] } }]
        }"
      ></bg2-button>
    </div>
  </bg2-abstract-carousel-group-card>

  <bg2-abstract-carousel-group-card>
    <div role="stackable-card-header">
      <div class="card-title">{{ 'ALL.COMMON.Statuses' | translate }}</div>
      <div *ngIf="event_date$$ | async; let date" class="card-date">
        {{
          'VIEWS.APIARY.SHARED.WIDGETS.APIARY_EVALUATION_CAROUSEL.SUMMARY.TITLE.Visit of [date] (there was [distance_to_now])'
            | translate
              : {
                  date: date | formatDate: 'short',
                  distance_to_now: date | distanceToNow
                }
        }}
      </div>
    </div>

    <div role="stackable-card-content">
      <bg2-evaluation-histogram-hive-statuses [statuses]="hive_statuses_data$$ | async"></bg2-evaluation-histogram-hive-statuses>
    </div>
  </bg2-abstract-carousel-group-card>

  <bg2-abstract-carousel-group-card>
    <div role="stackable-card-header">
      <div class="card-title">{{ 'EVENT.VISIT.Queens' | translate }}</div>
      <div *ngIf="event_date$$ | async; let date" class="card-date">
        {{
          'VIEWS.APIARY.SHARED.WIDGETS.APIARY_EVALUATION_CAROUSEL.SUMMARY.TITLE.Visit of [date] (there was [distance_to_now])'
            | translate
              : {
                  date: date | formatDate: 'short',
                  distance_to_now: date | distanceToNow
                }
        }}
      </div>
    </div>

    <div role="stackable-card-content">
      <bg2-evaluation-histogram-hive-queens [queens]="queen_colors_data$$ | async"></bg2-evaluation-histogram-hive-queens>
    </div>
  </bg2-abstract-carousel-group-card>

  <bg2-abstract-carousel-group-card>
    <div role="stackable-card-header">
      <div class="card-title">{{ 'EVENT.VISIT.Supers' | translate }}</div>
      <div *ngIf="event_date$$ | async; let date" class="card-date">
        {{
          'VIEWS.APIARY.SHARED.WIDGETS.APIARY_EVALUATION_CAROUSEL.SUMMARY.TITLE.Visit of [date] (there was [distance_to_now])'
            | translate
              : {
                  date: date | formatDate: 'short',
                  distance_to_now: date | distanceToNow
                }
        }}
      </div>
    </div>

    <div role="stackable-card-content">
      <bg2-evaluation-histogram-hive-supers [supers]="supers_data$$ | async"></bg2-evaluation-histogram-hive-supers>
    </div>
  </bg2-abstract-carousel-group-card>

  <bg2-abstract-carousel-group-card>
    <div role="stackable-card-header">
      <div class="card-title">{{ 'EVENT.VISIT.BROODFRAMES.Broodframes' | translate }}</div>
      <div *ngIf="event_date$$ | async; let date" class="card-date">
        {{
          'VIEWS.APIARY.SHARED.WIDGETS.APIARY_EVALUATION_CAROUSEL.SUMMARY.TITLE.Visit of [date] (there was [distance_to_now])'
            | translate
              : {
                  date: date | formatDate: 'short',
                  distance_to_now: date | distanceToNow
                }
        }}
      </div>
    </div>

    <div role="stackable-card-content">
      <bg2-evaluation-histogram-hive-broodframes [broodframes]="broodframes_data$$ | async"></bg2-evaluation-histogram-hive-broodframes>
    </div>
  </bg2-abstract-carousel-group-card>
</div>
