import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'bg2-coming-soon',
  templateUrl: './coming-soon.component.html',
  styleUrls: ['./coming-soon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class Bg2ComingSoonComponent {
  // #region -> (component basics)

  constructor() {}

  // #endregion
}
