import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';

import { BehaviorSubject, combineLatest, Observable, map } from 'rxjs';
import { distinctUntilRealChanged } from '@bg2app/tools/rxjs';

/**
 * @module WidgetsReusableModule
 *
 * @description
 */
@Component({
  selector: 'bg2-abstract-carousel-group-card',
  templateUrl: './abstract-carousel-group-card.component.html',
  styleUrls: ['./abstract-carousel-group-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AbstractCarouselGroupCardComponent {
  @ViewChild('stackableCard')
  public card: ElementRef<HTMLDivElement> = undefined;

  private _active$$ = new BehaviorSubject(false);
  public active$$ = this._active$$.asObservable();

  @Input()
  public set active(active: boolean) {
    this._active$$.next(active);
    this._cdRef.detectChanges();
  }

  private _index$$: BehaviorSubject<number> = new BehaviorSubject<number>(null);
  public index$$: Observable<number> = this._index$$.asObservable().pipe(distinctUntilRealChanged());

  @Input()
  public set index(index: number) {
    this._index$$.next(index);
    this._cdRef.detectChanges();
  }

  @Output()
  public swipeUp = new EventEmitter();

  @Output()
  public swipeDown = new EventEmitter();

  // #region -> (component basics)

  constructor(private _cdRef: ChangeDetectorRef) {}

  // #endregion

  // #region -> (events management)

  public clickCard(): void {
    const card_index = this._index$$.getValue();

    if (card_index > 0) {
      this.swipeDown.next(null);
    }
  }

  // #endregion

  // #region -> (content loading management)

  private _has_been_loaded$$ = new BehaviorSubject(false);
  private has_been_loaded$$ = this._has_been_loaded$$.asObservable().pipe(distinctUntilRealChanged());

  public can_load_content$$ = combineLatest([this.active$$, this.has_been_loaded$$]).pipe(
    map(([active, has_been_loaded]) => {
      if (has_been_loaded) {
        return true;
      }

      if (active) {
        this._has_been_loaded$$.next(true);
        return true;
      }

      return false;
    }),
    distinctUntilRealChanged()
  );

  // #endregion
}
