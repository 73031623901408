import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MiscModule } from 'app/misc/misc.module';
import { SharedModule } from 'app/shared-module/shared.module';
import { ZohoSharedModule } from '../shared/zoho-shared.module';
import { DialogsModalsModule } from 'app/widgets/dialogs-modals/dialogs-modals.module';
import { WidgetsReusableModule } from 'app/widgets/widgets-reusables/widgets-reusables.module';

import { ZohoCheckModalComponent } from './zoho-check-modal/zoho-check-modal.component';
import { ZohoCreateIssueModalComponent } from './zoho-create-issue-modal/zoho-create-issue-modal.component';

@NgModule({
  declarations: [ZohoCheckModalComponent, ZohoCreateIssueModalComponent],
  imports: [MiscModule, SharedModule, CommonModule, DialogsModalsModule, ZohoSharedModule, WidgetsReusableModule],
})
export class ZohoDialogsAndModalsModule {}
