<ng-container *ngIf="(device$$ | async) as device">
  <ng-container *ngIf="last_gw_msgs$$ | async as last_gw_msgs">
    <details class="default device-modal-last-msgs" *ngIf="last_gw_msgs.length > 0">
      <summary>{{'VIEWS.DEVICES.MSG_TABLE.See the last [nb] gateway messages' | translate: {nb: last_gw_msgs.length} }}</summary>
      <div class="data">
        <bg2-device-msgs-table [msgs]="last_gw_msgs" [all_columns]="gw_columns">
        </bg2-device-msgs-table>
      </div>
    </details>
  </ng-container>

  <ng-container *ngIf="last_sensor_msgs$$ | async as last_sensor_msgs">
    <details class="default device-modal-last-msgs" *ngIf="last_sensor_msgs.length > 0">
      <summary>{{'VIEWS.DEVICES.MSG_TABLE.See the last [nb] meassurement messages' | translate: {nb: last_sensor_msgs.length} }}</summary>
      <div class="data">
        <bg2-device-msgs-table [msgs]="last_sensor_msgs" [all_columns]="sensor_columns">
        </bg2-device-msgs-table>
        <!-- <pre>{{last_sensor_msgs[0] | json }}</pre> -->
      </div>
    </details>

  </ng-container>

</ng-container>