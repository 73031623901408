import { Component, Input, Output, EventEmitter, ChangeDetectionStrategy } from '@angular/core';

import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs';

import { DialogDiv, DialogElement } from '../dialog-div/dialog-div.component';

@Component({
  selector: 'dialog-body',
  templateUrl: 'dialog-body.component.html',
  styleUrls: ['dialog-body.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DialogBodyComponent<ReturnType = any> {
  private _content$$ = new BehaviorSubject(null);
  public content$$ = this._content$$.asObservable();

  public body_div$$: Observable<DialogDiv<ReturnType>> = this.content$$.pipe(
    map(content => ({
      type: 'div',
      elements: content,
    }))
  );

  @Input()
  public set content(content: DialogElement<ReturnType>) {
    this._content$$.next(content);
  }

  @Input()
  public show_hr = true;

  @Output()
  public action = new EventEmitter<ReturnType>();

  @Output()
  public form_values = new EventEmitter<any>();

  public onClickButton(return_value: ReturnType): void {
    this.action.next(return_value);
  }

  public onFormValues($event: any) {
    this.form_values.emit($event);
  }
}
