import { ChangeDetectionStrategy, Component, OnDestroy } from '@angular/core';

import { isEqual, isNil } from 'lodash-es';

import { combineLatest, map, of, switchMap } from 'rxjs';
import { distinctUntilRealChanged, replay, waitForNotNilProperties, waitForNotNilValue } from '@bg2app/tools/rxjs';

import { AppStateService } from 'app/core/app-state.service';
import { UsersApiService } from 'app/core/api/user/users-api.service';

import { AbstractModalComponent } from 'app/widgets/dialogs-modals';
import { ModalParams } from 'app/widgets/dialogs-modals/abstract-modal.component';
import { BeeguardAuthService } from 'app/core';

interface UserAccountModalParams extends ModalParams {
  user_id: string;
}

@Component({
  selector: 'bg2-user-account-modal',
  templateUrl: 'user-account-modal.component.html',
  styleUrls: ['user-account-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserAccountModalComponent extends AbstractModalComponent<UserAccountModalParams> implements OnDestroy {
  // #region -> (component basics)

  constructor(
    public readonly oauthService: BeeguardAuthService,
    private readonly _appState: AppStateService,
    private readonly _usersApi: UsersApiService
  ) {
    super();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  // #endregion

  /** */
  protected handle_event_before_unload(event: BeforeUnloadEvent): void {
    return null;
  }

  // #region -> (current user)

  /** */
  public current_user$$ = this._appState.user$$;

  /** */
  public is_current_user_superadmin$$ = this.current_user$$.pipe(
    waitForNotNilValue(),
    switchMap(user => user.is_superadmin$$)
  );

  /** */
  public user_id$$ = this.input_params$$.pipe(
    map(input_params => input_params.user_id),
    map(user_id => parseInt(user_id)),
    distinctUntilRealChanged()
  );

  /** */
  public user$$ = this.user_id$$.pipe(
    waitForNotNilValue(),
    switchMap(user_id => this._usersApi.fetch_user$(user_id)),
    replay()
  );

  /** */
  public is_modal_user_superadmin$$ = this.user$$.pipe(
    switchMap(user => {
      if (isNil(user)) {
        return of(false);
      }

      return user?.is_superadmin$$;
    }),
    replay()
  );

  /** */
  public user_name$$ = this.user$$.pipe(switchMap(user => user.name$$));

  /** */
  public is_modal_user_is_current_user$$ = combineLatest({
    current_user_id: this._appState.user$$.pipe(
      waitForNotNilValue(),
      switchMap(current_user => current_user.user_id$$)
    ),
    modal_user_id: this.user_id$$,
  }).pipe(
    waitForNotNilProperties(),
    map(({ current_user_id, modal_user_id }) => isEqual(current_user_id, modal_user_id)),
    distinctUntilRealChanged()
  );

  // #endregion
}
