import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';
import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { BgControlWidgetComponent as ControlWidget, WidgetOptions } from '../control/control.widget';

export interface StringWidgetOptions extends WidgetOptions {
  indent: boolean;
  img?: string;
  readonly?: boolean;
  max_length?: number;
}

@AutoUnsubscribe()
@Component({
  selector: 'ef-string-widget',
  templateUrl: './string.widget.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class EfStringWidget extends ControlWidget implements OnInit {

  public options: StringWidgetOptions = {
    indent: false,
    title_style: '',
    img: null,
    readonly: false,
    reset_btn: false,
    max_length: null,
  };

  getInputType(): string {
    if (!this.schema.widget.id || this.schema.widget.id === 'string') {
      return 'text';
    } else {
      return this.schema.widget.id;
    }
  }
}
