<div class="battery-level-simplified-component" *ngLet="battery_simplified_state$$ | async; let status">
  <span
    class="mdi mdi-24px"
    [popperTimeout]="100"
    [popper]="tooltipCases"
    [popperPreventOverflow]="true"
    [popperPlacement]="$any('auto')"
    [popperDisabled]="is_popper_disabled"
    [queryParamsHandling]="'merge'"
    [queryParams]="{ inmodal_url: 'diagnostic' }"
    [popperApplyClass]="'device-battery-level-popper'"
    [routerLink]="open_in_modal ? ['', { outlets: { modal: ['device', { imei: device_imei$$ | async }] } }] : null"
    (click)="$event.stopPropagation()"
    [ngClass]="{
      'mdi-battery-check': (status?.state ?? simple_state) === BATTERY_STATE.OK,
      'mdi-battery-remove': (status?.state ?? simple_state) === BATTERY_STATE.NOT_OK,
      'mdi-battery-clock': (status?.state ?? simple_state) === BATTERY_STATE.OUTDATED,
      'mdi-battery-unknown': (status?.state ?? simple_state) === BATTERY_STATE.UNKNOWN
    }"
    [ngStyle]="{
      color:
        (status?.state ?? simple_state) === BATTERY_STATE.OK
          ? 'green'
          : (status?.state ?? simple_state) === BATTERY_STATE.NOT_OK
          ? 'red'
          : (status?.state ?? simple_state) === BATTERY_STATE.OUTDATED
          ? 'grey'
          : 'black'
    }"
  ></span>
</div>

<popper-content #tooltipCases>
  <ng-container *ngIf="device$$ | async; let device">
    <ng-container *ngIf="(has_last_contact$$ | async) === false">
      <span>{{ 'DEVICE.ALL.ERROR.This device has never communicated' | translate }}</span>
    </ng-container>

    <ng-container *ngIf="has_last_contact$$ | async">
      <ng-container *ngIf="last_contact$$ | async; let timestamp">
        <span style="text-align: center">
          {{ 'DEVICE.ALL.BATTERY_LEVEL.Updated on [x]' | translate : { x: timestamp | formatDate : 'full' } }}
        </span>
      </ng-container>

      <div class="battery-informations">
        <span *ngLet="device?.battery_type$$ | async; let battery_type">
          <span>{{ 'WIDGETS.DIALOGS_MODALS.DIALOGS.ERROR.Type' | translate | colon }}</span>
          <ng-container *ngIf="battery_type; else unknownBattery">
            <span *ngIf="device?.is_battery_computed_in_local">({{ battery_type }})</span>
            <span *ngIf="!device?.is_battery_computed_in_local">{{ battery_type }}</span>
          </ng-container>

          <ng-template #unknownBattery>N/A</ng-template>
        </span>

        <span *ngLet="last_voltage$$ | async; let last_voltage">
          <span>{{ 'DEVICE.ALL.BATTERY.Last voltage' | translate | colon }}</span>
          <ng-container *ngIf="last_voltage; else unknownLastVoltage">
            <span>{{ last_voltage | fixed : 2 }} V</span>
          </ng-container>
          <ng-template #unknownLastVoltage>N/A</ng-template>
        </span>

        <ng-container *ngIf="is_not_beelive_nor_cpt$$ | async">
          <span *ngLet="last_battery_change$$ | async; let last_battery_change">
            <span>{{ 'DEVICE.ALL.BATTERY_LEVEL.Last changed' | translate | colon }}</span>
            <ng-container *ngIf="last_battery_change?.time; let timestamp; else: unknownLastBatteryChange">
              <span>{{ timestamp | formatDate : 'full' }}</span>
            </ng-container>
            <ng-template #unknownLastBatteryChange>N/A</ng-template>
          </span>
        </ng-container>

        <ng-container *ngIf="(has_last_battery_change$$ | async) === false || (is_not_beelive_nor_cpt$$ | async)">
          <span *ngLet="last_power_on$$ | async; let last_power_on">
            <span>{{ 'DEVICE.ALL.BATTERY_LEVEL.Last power on' | translate | colon }}</span>
            <ng-container *ngIf="last_power_on; let timestamp; else: unknownLastPowerOn">
              <span>{{ timestamp | formatDate : 'full' }}</span>
            </ng-container>
            <ng-template #unknownLastPowerOn>N/A</ng-template>
          </span>
        </ng-container>
      </div>

      <bg2-ui-message [type]="'info'" [title]="''" [invert_text_color]="true" [fill_background]="false" [show_borders]="true">
        <div message>
          <span>
            {{
              'VIEWS.DEVICES.SHARED.DEVICE_LEVEL.BATTERY_LEVEL_SIMPLIFIED.You can view more informations on clicking the battery icon'
                | translate
            }}
          </span>
        </div>
      </bg2-ui-message>

      <ng-container *ngLet="battery_simplified_state$$ | async; let status">
        <bg2-ui-message
          *ngIf="status?.reason !== null"
          [type]="'warning'"
          [title]="''"
          [invert_text_color]="true"
          [fill_background]="false"
          [show_borders]="true"
        >
          <div message>
            <ng-container [ngSwitch]="status.reason">
              <span *ngSwitchCase="BATTERY_REASON.BATTERY_VOLTAGE_UNDER_CRITICAL_LIMIT">
                {{ 'VIEWS.DEVICES.SHARED.DEVICE_LEVEL.BATTERY_LEVEL_SIMPLIFIED.BATTERY_VOLTAGE_UNDER_CRITICAL_LIMIT' | translate }}
              </span>

              <span *ngSwitchCase="BATTERY_REASON.LAST_BATTERY_OLDER_THAN_1_YEAR">
                {{ 'VIEWS.DEVICES.SHARED.DEVICE_LEVEL.BATTERY_LEVEL_SIMPLIFIED.LAST_BATTERY_OLDER_THAN_1_YEAR' | translate }}
              </span>

              <span *ngSwitchCase="BATTERY_REASON.LAST_BATTERY_OLDER_THAN_2_YEARS">
                {{ 'VIEWS.DEVICES.SHARED.DEVICE_LEVEL.BATTERY_LEVEL_SIMPLIFIED.LAST_BATTERY_OLDER_THAN_2_YEARS' | translate }}
              </span>

              <span *ngSwitchCase="BATTERY_REASON.LAST_BATTERY_OLDER_THAN_3_YEARS">
                {{ 'VIEWS.DEVICES.SHARED.DEVICE_LEVEL.BATTERY_LEVEL_SIMPLIFIED.LAST_BATTERY_OLDER_THAN_3_YEARS' | translate }}
              </span>

              <span *ngSwitchCase="BATTERY_REASON.NO_CONTACT_SINCE_7_DAYS">
                {{ 'VIEWS.DEVICES.SHARED.DEVICE_LEVEL.BATTERY_LEVEL_SIMPLIFIED.NO_CONTACT_SINCE_7_DAYS' | translate }}
              </span>

              <span *ngSwitchCase="BATTERY_REASON.UNKNOWN">
                {{ 'VIEWS.DEVICES.SHARED.DEVICE_LEVEL.BATTERY_LEVEL_SIMPLIFIED.UNKNOWN' | translate }}
              </span>
            </ng-container>
          </div>
        </bg2-ui-message>
      </ng-container>

      <mat-divider></mat-divider>

      <div class="device-battery-sparkline">
        <bg2-device-battery-sparkline
          *ngIf="device$$ | async; let device"
          [show_title]="true"
          [device]="device"
        ></bg2-device-battery-sparkline>
      </div>
    </ng-container>
  </ng-container>
</popper-content>

<!-- <pre style="text-align: left">{{ battery_info$$ | async | json }}</pre> -->
