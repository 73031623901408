<div *ngLet="device$$ | async; let device" class="bg2-device-modal-identification-component">
  <!-- CARD: Device identity -->
  <div *ngIf="is_either_view" class="device-card-template">
    <div class="device-card-template__normal-container">
      <div class="device-detail__title-container">
        <div class="title-container__icon --color-brown">
          <span class="mdi mdi-identifier mdi-36px"></span>
        </div>

        <div class="title-container__title">
          <ng-container *ngIf="(is_loading_device?.value$$ | async) === false; else loadingDeviceIdentificationName">
            {{ device_name$$ | async }}
          </ng-container>

          <ng-template #loadingDeviceIdentificationName>
            <bg2-loading-skeleton [string_model]="'dvc_name'" [theme]="{ height: '24px' }"></bg2-loading-skeleton>
          </ng-template>
        </div>

        <div class="title-container__sep">|</div>

        <div class="title-container__important-container">
          <ng-container *ngIf="(is_device_rg_or_gps$$ | async) || (is_device_cpt$$ | async) || (is_device_bloomlive$$ | async)">
            <bg2-gps-level [open_in_modal]="false" [device]="device"></bg2-gps-level>
            <bg2-gprs-level [open_in_modal]="false" [device]="device"></bg2-gprs-level>
          </ng-container>

          <bg2-s868-level
            *ngIf="(is_device_is_wg$$ | async) || (is_device_is_tg$$ | async)"
            [open_in_modal]="false"
            [device]="device"
          ></bg2-s868-level>
          <!-- <bg2-battery-level [open_in_modal]="false" [device]="device"></bg2-battery-level> -->
          <bg2-battery-level-simplified [open_in_modal]="false" [device]="device"></bg2-battery-level-simplified>
        </div>

        <ng-container *ngIf="view === 'summary'">
          <span class="span title-container__spacer"></span>

          <div
            *ngLet="device_opened_tickets$$ | async; let tickets"
            class="title-container__important-container title-container__important-container--wider-gap"
          >
            <span
              *ngIf="is_superadmin$$ | async"
              class="clickable"
              [matMenuTriggerFor]="deviceDeskTicketsMenu"
              [matBadge]="tickets?.error === null ? tickets?.value?.length ?? 0 : '?'"
            >
              <img
                [src]="
                  '/assets/picons/zoho/zoho-desk-ticket-' +
                  (tickets?.value?.length > 0 ? 'red' : tickets?.error !== null ? 'orange' : 'green') +
                  '.svg'
                "
                alt=""
                style="width: 36px; display: flex"
              />
            </span>

            <!-- Menu for device opened tickets -->
            <mat-menu #deviceDeskTicketsMenu="matMenu" class="device-modal__device-desk-tickets__menu">
              <button mat-menu-item (click)="search_tickets_on_web()">
                <div class="mat-menu-item__container">
                  <span class="mdi mdi-search-web mdi-24px"></span>

                  <div class="mat-menu-item__container__content">
                    <span>{{ 'VIEWS.MODALS.DEVICE.Search CRM ticket' | translate }}</span>
                  </div>
                </div>
              </button>

              <button mat-menu-item (click)="create_desk_ticket()" [disabled]="(is_logged_to_zoho$$ | async) === false">
                <div class="mat-menu-item__container">
                  <span class="mdi mdi-ticket mdi-24px"></span>

                  <div class="mat-menu-item__container__content">
                    <span>{{ 'VIEWS.MODALS.DEVICE.Create DESK ticket' | translate }}</span>
                  </div>
                </div>
              </button>

              <ng-container *ngIf="(tickets?.value?.length ?? 0) <= 0">
                <button *ngLet="tickets?.error; let error" mat-menu-item [disabled]="true">
                  <div class="mat-menu-item__container">
                    <span class="mdi mdi-arrow-down-bold mdi-24px"></span>

                    <div class="mat-menu-item__container__content">
                      <span>Tickets DESK</span>

                      <span
                        class="mat-menu-item__content__status"
                        [ngClass]="{ '--valid': error === null, '--error': error !== null || tickets?.value?.length === 0 }"
                      >
                        <ng-container *ngIf="error || tickets?.value?.length === 0; else ready">
                          <ng-container *ngIf="error">{{ error?.message | translate }}</ng-container>
                          <ng-container *ngIf="tickets?.value?.length === 0">{{
                            'MODELS.ZOHO.ERROR.EMPTY_DESK_TICKETS' | translate
                          }}</ng-container>
                        </ng-container>

                        <ng-template #ready>{{ 'VIEWS.AUTH.LOGIN.Connected' | translate }}</ng-template>
                      </span>
                    </div>
                  </div>
                </button>
              </ng-container>

              <a mat-menu-item *ngFor="let ticket of tickets?.value" [href]="ticket?.webUrl" target="_blank">
                <bg2-zoho-ticket-summary [ticket]="ticket"></bg2-zoho-ticket-summary>
              </a>
            </mat-menu>

            <!-- Supports menu -->
            <span
              *ngLet="(device_opened_supports$$ | async)?.length; let total_opened_supports"
              class="mdi mdi-tools mdi-36px clickable"
              [matBadge]="total_opened_supports"
              [matMenuTriggerFor]="deviceSupportsMainMenu"
              [matBadgeHidden]="(is_superadmin$$ | async) === false"
              [ngStyle]="{
                color:
                  (is_superadmin$$ | async)
                    ? total_opened_supports > 0
                      ? 'red'
                      : 'green'
                    : total_opened_supports > 0
                    ? 'red'
                    : 'lightgray'
              }"
            ></span>

            <!-- Menu for device support mgmt -->
            <mat-menu #deviceSupportsMainMenu="matMenu" class="device-modal-supports-base-menu">
              <!-- Ask support for this device -->
              <button
                mat-menu-item
                [queryParamsHandling]="'preserve'"
                [routerLink]="[
                  '',
                  { outlets: { modal: ['new_issue', { type: 'device_support', data: (ask_device_support_data$$ | async) }] } }
                ]"
              >
                <div class="mat-menu-item__container">
                  <span class="mdi mdi-email mdi-24px"></span>

                  <div class="mat-menu-item__container__content">
                    <span>{{ 'DEVICE.SUPPORTS.Ask new support' | translate }}</span>

                    <span
                      *ngLet="(device_opened_supports$$ | async)?.length; let total_opened_supports"
                      class="mat-menu-item__content__status"
                      [ngClass]="{ '--error': total_opened_supports > 0, '--valid': total_opened_supports === 0 }"
                    >
                      <ng-container *ngIf="total_opened_supports === 0">
                        {{ 'DEVICE.SUPPORTS.MISC.No after-sales service in progress' | translate }}
                      </ng-container>

                      <ng-container *ngIf="total_opened_supports === 1">
                        {{ 'DEVICE.SUPPORTS.MISC.1 after-sales service in progress' | translate }}
                      </ng-container>

                      <ng-container *ngIf="total_opened_supports > 1">
                        {{ 'DEVICE.SUPPORTS.MISC.[total] after-sales service in progress' | translate : { total: total_opened_supports } }}
                      </ng-container>
                    </span>
                  </div>
                </div>
              </button>

              <ng-container *ngIf="is_superadmin$$ | async">
                <!-- Create new RU -->
                <ng-container *ngIf="(device_opened_supports$$ | async)?.length <= 0">
                  <button
                    *ngLet="(can_manage_ru_support$$ | async)?.error; let error"
                    mat-menu-item
                    [disabled]="error !== null || (device_opened_supports$$ | async)?.length > 0"
                    (click)="create_new_support()"
                  >
                    <div class="mat-menu-item__container">
                      <span class="mdi mdi-tag-plus mdi-24px"></span>

                      <div class="mat-menu-item__container__content">
                        <span>Create new RU</span>

                        <span class="mat-menu-item__content__status" [ngClass]="{ '--valid': error === null, '--error': error !== null }">
                          <ng-container *ngIf="error; else ready"> {{ error?.message | translate }} </ng-container>
                          <ng-template #ready> {{ 'VIEWS.AUTH.LOGIN.Connected' | translate }} </ng-template>
                        </span>
                      </div>
                    </div>
                  </button>
                </ng-container>

                <!-- List of opened supports -->
                <ng-container *ngFor="let support of device_opened_supports$$ | async">
                  <button mat-menu-item [matMenuTriggerFor]="deviceUpdateSupportMenu">
                    <div class="mat-menu-item__container">
                      <div class="mat-menu-item__container__content">
                        <span>
                          {{
                            'DEVICE.SUPPORTS.TYPE.Support "[type]"'
                              | translate : { type: 'DEVICE.SUPPORTS.TYPE.' + support?.type ?? 'unknown' | translate }
                          }}
                        </span>

                        <span class="mat-menu-item__content__status">
                          {{ 'ALL.DATE.STATE.Created at : [date]' | translate : { date: support?.start_time | formatDate : 'short' } }}
                        </span>
                      </div>
                    </div>
                  </button>
                </ng-container>
              </ng-container>
            </mat-menu>

            <!-- Support menu (non-admin version) -->
          </div>

          <!-- Menu for device active supports -->
          <mat-menu #deviceActiveSupportsMenu class="device-modal__device-opened-supports__list__menu">
            <ng-container *ngFor="let support of device_opened_supports$$ | async">
              <button mat-menu-item [matMenuTriggerFor]="deviceUpdateSupportMenu">
                <div class="mat-menu-item__container">
                  <span class="mdi mdi-tag mdi-24px"></span>

                  <div class="mat-menu-item__container__content">
                    <span>
                      {{
                        'DEVICE.SUPPORTS.TYPE.Support "[type]"'
                          | translate : { type: 'DEVICE.SUPPORTS.TYPE.' + support?.type ?? 'unknown' | translate }
                      }}
                    </span>

                    <span class="mat-menu-item__content__status">
                      {{ 'ALL.DATE.STATE.Created at : [date]' | translate : { date: support?.start_time | formatDate : 'short' } }}
                    </span>
                  </div>
                </div>
              </button>
            </ng-container>
          </mat-menu>

          <!-- Menu for device selected support -->
          <mat-menu #deviceUpdateSupportMenu class="device-modal__device-opened-supports__list__update__menu">
            <button
              *ngLet="(can_manage_ru_support$$ | async)?.error; let error"
              mat-menu-item
              [disabled]="error !== null"
              (click)="update_support()"
            >
              <div class="mat-menu-item__container">
                <span class="mdi mdi-tag-search mdi-24px"></span>

                <div class="mat-menu-item__container__content">
                  <span>{{ 'VIEWS.DEVICES.COMPONENTS.DEVICES_DATATABLES.SELECTION.Modify RU' | translate }}</span>

                  <span class="mat-menu-item__content__status" [ngClass]="{ '--valid': error === null, '--error': error !== null }">
                    <ng-container *ngIf="error; else ready"> {{ error?.message | translate }} </ng-container>
                    <ng-template #ready> {{ 'VIEWS.AUTH.LOGIN.Connected' | translate }} </ng-template>
                  </span>
                </div>
              </div>
            </button>

            <button
              *ngLet="(can_manage_ru_support$$ | async)?.error; let error"
              mat-menu-item
              [disabled]="error !== null"
              [routerLink]="['', { outlets: { modal: ['device_support_end_support', { imeis: [device_imei$$ | async] }] } }]"
              [queryParamsHandling]="'preserve'"
            >
              <div class="mat-menu-item__container">
                <span class="mdi mdi-tag-check mdi-24px"></span>

                <div class="mat-menu-item__container__content">
                  <span>{{ 'VIEWS.DEVICES.COMPONENTS.DEVICES_DATATABLES.SELECTION.Declare leave RU' | translate }}</span>

                  <span class="mat-menu-item__content__status" [ngClass]="{ '--valid': error === null, '--error': error !== null }">
                    <ng-container *ngIf="error; else ready"> {{ error?.message | translate }} </ng-container>
                    <ng-template #ready> {{ 'VIEWS.AUTH.LOGIN.Connected' | translate }} </ng-template>
                  </span>
                </div>
              </div>
            </button>
          </mat-menu>
        </ng-container>
      </div>

      <div class="device-modal-card-container__body-container">
        <!-- WGuard track -->
        <ng-container *ngIf="is_device_rg_or_gps$$ | async">
          <ng-container *ngLet="is_wguard_data_retrieval_active$$ | async; let is_wguard_data_retrieval_active">
            <bg2-ui-message
              *ngIf="is_wguard_data_retrieval_active !== null"
              [type]="is_wguard_data_retrieval_active === false ? 'warning' : 'success'"
              [title]="
                is_wguard_data_retrieval_active === false
                  ? ('DEVICE.ALL.CONFIGURATION.STATUS.WGuard data retrieval is not active' | translate)
                  : ('DEVICE.ALL.CONFIGURATION.STATUS.WGuard data collecting status' | translate)
              "
            >
              <div message>
                <span *ngIf="is_wguard_data_retrieval_active === false">
                  {{ 'DEVICE.ALL.CONFIGURATION.STATUS.This device is not configured to collect data from nearby WGuards' | translate }}
                </span>

                <span *ngIf="is_wguard_data_retrieval_active === true">
                  {{ 'DEVICE.ALL.CONFIGURATION.STATUS.This device is configured to collect data from nearby WGuards' | translate }}
                </span>
              </div>

              <div buttons>
                <ng-container *ngIf="is_wguard_data_retrieval_active === false">
                  <bg2-button
                    *ngLet="device?.user_acl?.can$$('write_devices_configuration') | async; let can_write_devices_configuration"
                    [type]="'action'"
                    [color]="'device'"
                    [disabled]="!can_write_devices_configuration"
                    [options]="{ is_thin: true }"
                    [message]="'VIEWS.MODALS.DEVICE.Configuration' | translate"
                    [icon]="can_write_devices_configuration ? 'mdi-cog' : 'cs-/img/pictos/lock.svg'"
                    [internal_routing]="{
                      queryParamsHandling: 'preserve',
                      routerLink: ['', { outlets: { modal: device?.config_modal$$ | async } }]
                    }"
                  ></bg2-button>
                </ng-container>
              </div>
            </bg2-ui-message>
          </ng-container>
        </ng-container>

        <div class="body-container__properties">
          <!-- Device IMEI -->
          <div class="body-container__property-container">
            <div class="device-details-properties__property-container__label-container">
              <span class="mdi mdi-barcode mdi-24px"></span>
            </div>

            <span class="device-details-properties__property-container__value">{{ device_imei$$ | async }}</span>
          </div>

          <!-- Is device activated -->
          <ng-container *ngIf="is_superadmin$$ | async">
            <div *ngLet="device?.is_activated$$ | async; let is_activated" class="body-container__property-container --only-superadmin">
              <div class="device-details-properties__property-container__label-container">
                <span class="mdi mdi-information mdi-24px"></span>
                <b>{{ 'DEVICE.ALL.METADATA.UNACTIVATED.Is active ?' | translate }}</b>
              </div>

              <span class="device-details-properties__property-container__value">
                {{ (is_activated ? 'ALL.COMMON.Yes' : 'ALL.COMMON.No') | translate }}
              </span>
            </div>
          </ng-container>

          <!-- Device owner -->
          <div *ngLet="device_owner$$ | async; let device_owner" class="body-container__property-container">
            <div class="device-details-properties__property-container__label-container">
              <span
                *ngLet="device_owner?.is_superadmin$$ | async; let is_superadmin"
                class="mdi mdi-24px"
                [ngClass]="{ 'mdi-account': !is_superadmin, 'mdi-crown': is_superadmin }"
                [ngStyle]="{ color: is_superadmin ? 'goldenrod' : 'inherit' }"
              ></span>

              <b>{{ 'VIEWS.MODALS.DEVICE.Owner' | translate | colon }}</b>
            </div>

            <bg2-loading-skeleton *ngIf="is_loading_owner.value" [string_model]="'owner_name'"></bg2-loading-skeleton>
            <ng-container *ngIf="!is_loading_owner.value">
              <a
                [routerLink]="['', { outlets: { modal: ['user_account', { user_id: device_owner?.user_id$$ | async }] } }]"
                [queryParamsHandling]="'preserve'"
              >
                {{ device_owner.name$$ | async }}
              </a>

              <ng-container *ngIf="is_superadmin$$ | async">
                <span
                  class="mdi mdi-account-convert clickable clickable-colorized"
                  [popper]="'VIEWS.MODALS.DEVICE.Change device\'s owner' | translate"
                  (click)="change_owner()"
                ></span>
              </ng-container>
            </ng-container>
          </div>

          <!-- Contract and guarantee -->
          <div *ngLet="device?.contract$$ | async; let contract" class="body-container__property-container">
            <div class="device-details-properties__property-container__label-container">
              <span class="mdi mdi-file-certificate mdi-24px"></span>
              <b>{{ 'VIEWS.DEVICES.DIALOGS_AND_MODALS.DEVICE_MODAL.DETAILS.IDENTIFICATION.Contract & Guarantee' | translate | colon }}</b>
            </div>

            <span class="device-details-properties__property-container__value">
              <span *ngIf="contract">{{ 'EVENT.DEVICE.'.concat(contract) | translate }}</span>
              <span *ngIf="!contract">{{ 'DEVICE.ALL.CONTRACT.Contract not defined' | translate }}</span>
            </span>

            <ng-container *ngIf="contract">
              <span
                *ngLet="device?.guarantee$$ | async; let guarantee"
                class="device-details-properties__property-container__value"
                [popper]="deviceGuaranteePopper"
                [popperApplyClass]="'device-guarantee__popper'"
              >
                <img *ngIf="guarantee?.under_guarantee" src="assets/picons/from_mdi/guarantee-on-gold.svg" alt="" />
                <img *ngIf="!guarantee?.under_guarantee" src="assets/picons/from_mdi/guarantee-off-gold.svg" alt="" />
              </span>

              <popper-content #deviceGuaranteePopper>
                <ng-container *ngLet="device?.guarantee$$ | async; let guarantee">
                  <span>
                    {{ 'DEVICE.ALL.GUARANTEE.Is under guarantee ?' | translate }}
                    {{ (guarantee?.under_guarantee ? 'ALL.COMMON.Yes' : 'ALL.COMMON.No') | translate }}
                  </span>
                  <span>
                    <span>{{ 'DEVICE.ALL.GUARANTEE.Reason' | translate | colon }}</span>
                    <span>
                      {{
                        guarantee?.reason
                          | translate
                            : {
                                affected_date: guarantee?.data?.affected_date | formatDate : 'short',
                                expiration_date: guarantee?.data?.expiration_date | formatDate : 'short'
                              }
                      }}
                    </span>
                  </span>
                </ng-container>
              </popper-content>
            </ng-container>
          </div>

          <!-- Communication technology -->
          <div *ngIf="device?.com_technology$$ | async; let com_technologies" class="body-container__property-container">
            <div class="device-details-properties__property-container__label-container">
              <span class="mdi mdi-satellite-uplink mdi-24px"></span>
              <b>{{ 'VIEWS.MODALS.EXPORT_DEVICE.Communication technology' | translate | colon }}</b>
            </div>

            <span class="device-details-properties__property-container__value">
              <span>{{ com_technologies | join : ', ' }}</span>
            </span>

            <span class="device-details-properties__property-container__value">
              <doc-helper [page]="'appareils/technologie_communication'" section=""></doc-helper>
            </span>
          </div>

          <!-- Device version -->
          <ng-container *ngIf="device?.swv$$ | async; let swv">
            <div *ngLet="device?.hwv$$ | async; let hwv" class="body-container__property-container">
              <div class="device-details-properties__property-container__label-container">
                <span class="mdi mdi-information-variant mdi-24px"></span>
                <b>{{ 'DEVICE.ALL.VERSION.Versions' | translate | colon }}</b>
              </div>

              <span
                *ngLet="device?.swv_minor$$ | async; let minor_software_version"
                class="device-details-properties__property-container__value"
              >
                <span>
                  HW_{{ hwv }} | SW_{{ swv
                  }}{{ minor_software_version !== null && minor_software_version !== undefined ? '.' + minor_software_version : '' }}
                </span>
              </span>

              <span *ngIf="is_superadmin$$ | async" class="device-details-properties__property-container__value --only-superadmin">
                <span>(Gen. {{ device?.generation$$ | async }})</span>
              </span>
            </div>
          </ng-container>

          <!-- Device modem version -->
          <ng-container *ngIf="is_superadmin$$ | async">
            <div *ngIf="device?.modem_version$$ | async; let modem_version" class="body-container__property-container --only-superadmin">
              <div class="device-details-properties__property-container__label-container">
                <span class="mdi mdi-information-variant mdi-24px"></span>
                <b>{{ 'DEVICE.ALL.VERSION.Modem version' | translate | colon }}</b>
              </div>

              <span class="device-details-properties__property-container__value">
                <span>{{ modem_version }}</span>
              </span>
            </div>
          </ng-container>

          <!-- ICCID -->
          <ng-container *ngIf="(is_superadmin$$ | async) && view === 'details'">
            <div *ngIf="device?.iccid$$ | async; let iccid" class="body-container__property-container --only-superadmin">
              <div class="device-details-properties__property-container__label-container">
                <span class="mdi mdi-sim mdi-24px"></span>
                <b>{{ 'DEVICE.ALL.COMMON.ICCID' | translate | colon }}</b>
              </div>

              <span class="device-details-properties__property-container__value">
                <span>{{ iccid }}</span>
              </span>

              <span
                *ngIf="iccid"
                class="device-details-properties__property-container__value clickable"
                [popperTrigger]="$any('click')"
                [popperTimeoutAfterShow]="1000"
                [popper]="'ALL.ACTIONS.Copied !' | translate"
                (click)="clipboard.copy(iccid)"
              >
                <span class="mdi mdi-24px mdi-content-copy"></span>
              </span>
            </div>
          </ng-container>

          <!-- BeeLive exclusive data (superadmin) -->
          <ng-container *ngIf="is_device_cpt$$ | async">
            <!-- Beelive SN -->
            <div class="body-container__property-container">
              <div class="device-details-properties__property-container__label-container">
                <span class="mdi mdi-identifier mdi-24px"></span>
                <b>{{ 'DEVICE.TYPE.CPT.IDENTITY.Serial number' | translate | colon }}</b>
              </div>

              <span class="device-details-properties__property-container__value">
                <span>{{ device?.sn ?? '...' }}</span>
              </span>
            </div>

            <ng-container *ngIf="view === 'details'">
              <!-- Beelive hostname -->
              <div class="body-container__property-container">
                <div class="device-details-properties__property-container__label-container">
                  <span class="mdi mdi-web mdi-24px"></span>
                  <b>{{ 'DEVICE.TYPE.CPT.IDENTITY.Hostname' | translate | colon }}</b>
                </div>

                <span class="device-details-properties__property-container__value">
                  <span>{{ $any(device)?.hostname ?? '...' }}</span>
                </span>
              </div>

              <!-- Beelive VPN IP -->
              <div class="body-container__property-container">
                <div class="device-details-properties__property-container__label-container">
                  <span class="mdi mdi-lan mdi-24px"></span>
                  <b>{{ 'DEVICE.TYPE.CPT.IDENTITY.VPN IP' | translate | colon }}</b>
                </div>

                <span class="device-details-properties__property-container__value">
                  <span>{{ $any(device)?.metadata?.vpn_ip ?? '...' }}</span>
                </span>
              </div>
            </ng-container>

            <!-- Beelive mission control -->
            <div class="body-container__property-container">
              <div class="device-details-properties__property-container__label-container">
                <span class="mdi mdi-sim mdi-24px"></span>
                <b>{{ 'DEVICE.TYPE.CPT.IDENTITY.Mission control' | translate | colon }}</b>
              </div>

              <span class="device-details-properties__property-container__value">
                <a
                  *ngIf="device.metadata?.blive_mc_imei; let blive_mc_imei; else: undefinedBeeliveMissionControl"
                  [queryParamsHandling]="'merge'"
                  [queryParams]="{ inmodal_url: '' }"
                  [routerLink]="['', { outlets: { modal: ['device', { imei: blive_mc_imei }] } }]"
                >
                  {{ blive_mc_imei }}
                </a>

                <ng-template #undefinedBeeliveMissionControl>
                  <span>...</span>
                </ng-template>
              </span>
            </div>
          </ng-container>

          <!-- Delete device -->
          <ng-container *ngIf="is_superadmin$$ | async">
            <div class="body-container__property-container --only-superadmin">
              <div class="device-details-properties__property-container__label-container">
                <span class="mdi mdi-delete mdi-24px" [style]="{ color: 'red' }"></span>
              </div>

              <span class="device-details-properties__property-container__value">
                <a class="clickable" (click)="delete_device()" [style]="{ color: 'red' }">{{ 'ALL.ACTIONS.Delete' | translate }}</a>
              </span>
            </div>
          </ng-container>
        </div>
      </div>

      <div *ngIf="view === 'summary'" class="device-detail__open-detail-container">
        <bg2-button
          [type]="'navigation'"
          [icon]="'mdi-magnify'"
          [options]="{ is_thin: true }"
          [message]="'VIEWS.DEVICES.DIALOGS_AND_MODALS.DEVICE_MODAL.DETAILS.MISC.More details' | translate"
          (btn_click)="open_detail.next(true)"
        ></bg2-button>
      </div>
    </div>
  </div>

  <!-- CARD: Device credentials -->
  <ng-container *ngIf="(is_device_cpt$$ | async) && device?.has_credentials$$ | async">
    <div *ngIf="view === 'details' && (is_superadmin$$ | async)" class="device-card-template --only-superadmin">
      <div *ngLet="device?.credentials$$ | async; let credentials" class="device-card-template__normal-container">
        <div class="device-detail__title-container">
          <div class="title-container__icon --color-purple">
            <span class="mdi mdi-key-chain mdi-36px"></span>
          </div>

          <div class="title-container__title">
            {{ 'VIEWS.DEVICES.DIALOGS_AND_MODALS.MODALS.DEVICE_MODAL.CATEGORIES.IDENTIFICATION.Credentials' | translate }}
          </div>
        </div>

        <div class="device-modal-card-container__body-container">
          <ng-container *ngIf="credentials?.ApiKeyCredential; let api_key_credentials">
            <span style="font-size: 1.1em; font-weight: 700">{{ 'DEVICE.TYPE.CPT.CREDENTIALS.API credentials' | translate }}</span>

            <div class="body-container__properties">
              <!-- ID -->
              <div *ngLet="api_key_credentials?.id; let id" class="body-container__property-container">
                <div class="device-details-properties__property-container__label-container">
                  <span class="mdi mdi-identifier mdi-24px"></span>
                </div>

                <span class="device-details-properties__property-container__value">
                  {{ id ?? '--' }}
                </span>

                <span
                  *ngIf="id"
                  class="device-details-properties__property-container__value clickable"
                  [popperTrigger]="$any('click')"
                  [popperTimeoutAfterShow]="1000"
                  [popper]="'ALL.ACTIONS.Copied !' | translate"
                  (click)="clipboard.copy(id)"
                >
                  <span class="mdi mdi-24px mdi-content-copy"></span>
                </span>
              </div>

              <!-- CDate -->
              <div *ngLet="api_key_credentials?.cdate; let cdate" class="body-container__property-container">
                <div class="device-details-properties__property-container__label-container">
                  <span class="mdi mdi-clock mdi-24px"></span>
                  <b>{{ 'DEVICE.TYPE.CPT.CREDENTIALS.cdate' | translate | colon }}</b>
                </div>

                <span class="device-details-properties__property-container__value">
                  {{ cdate | formatDate : 'full' ?? '--' }}
                </span>
              </div>

              <!-- User name -->
              <div *ngLet="api_key_credentials?.user_name; let user_name" class="body-container__property-container">
                <div class="device-details-properties__property-container__label-container">
                  <span class="mdi mdi-account mdi-24px"></span>
                  <b>{{ 'DEVICE.TYPE.CPT.CREDENTIALS.User name' | translate | colon }}</b>
                </div>

                <span class="device-details-properties__property-container__value">
                  {{ user_name ?? '--' }}
                </span>

                <span
                  *ngIf="user_name"
                  class="device-details-properties__property-container__value clickable"
                  [popperTrigger]="$any('click')"
                  [popperTimeoutAfterShow]="1000"
                  [popper]="'ALL.ACTIONS.Copied !' | translate"
                  (click)="clipboard.copy(user_name)"
                >
                  <span class="mdi mdi-24px mdi-content-copy"></span>
                </span>
              </div>

              <!-- API key -->
              <div *ngLet="api_key_credentials?.api_key; let api_key" class="body-container__property-container">
                <div class="device-details-properties__property-container__label-container">
                  <span class="mdi mdi-key mdi-24px"></span>
                  <b>{{ 'DEVICE.TYPE.CPT.CREDENTIALS.API key' | translate | colon }}</b>
                </div>

                <span class="device-details-properties__property-container__value">
                  {{ api_key ?? '...' }}
                </span>

                <span
                  *ngIf="api_key"
                  class="device-details-properties__property-container__value clickable"
                  [popperTrigger]="$any('click')"
                  [popperTimeoutAfterShow]="1000"
                  [popper]="'ALL.ACTIONS.Copied !' | translate"
                  (click)="clipboard.copy(api_key)"
                >
                  <span class="mdi mdi-24px mdi-content-copy"></span>
                </span>
              </div>
            </div>
          </ng-container>

          <ng-container *ngIf="credentials?.WireGuardCredential; let wireguard_credentials">
            <span style="font-size: 1.1em; font-weight: 700">{{ 'DEVICE.TYPE.CPT.CREDENTIALS.WireGuard credentials' | translate }}</span>

            <div class="body-container__properties">
              <!-- ID -->
              <div *ngLet="wireguard_credentials?.id; let id" class="body-container__property-container">
                <div class="device-details-properties__property-container__label-container">
                  <span class="mdi mdi-identifier mdi-24px"></span>
                </div>

                <span class="device-details-properties__property-container__value">
                  {{ id ?? '...' }}
                </span>

                <span
                  *ngIf="id"
                  class="device-details-properties__property-container__value clickable"
                  [popperTrigger]="$any('click')"
                  [popperTimeoutAfterShow]="1000"
                  [popper]="'ALL.ACTIONS.Copied !' | translate"
                  (click)="clipboard.copy(id)"
                >
                  <span class="mdi mdi-24px mdi-content-copy"></span>
                </span>
              </div>

              <!-- CDate -->
              <div *ngLet="wireguard_credentials?.cdate; let cdate" class="body-container__property-container">
                <div class="device-details-properties__property-container__label-container">
                  <span class="mdi mdi-clock mdi-24px"></span>
                  <b>{{ 'DEVICE.TYPE.CPT.CREDENTIALS.cdate' | translate | colon }}</b>
                </div>

                <span class="device-details-properties__property-container__value">
                  {{ cdate | formatDate : 'full' ?? '--' }}
                </span>
              </div>

              <!-- IP address -->
              <div *ngLet="wireguard_credentials?.ip_address; let ip_address" class="body-container__property-container">
                <div class="device-details-properties__property-container__label-container">
                  <span class="mdi mdi-ip mdi-24px"></span>
                  <b>{{ 'DEVICE.TYPE.CPT.CREDENTIALS.IP address' | translate | colon }}</b>
                </div>

                <span class="device-details-properties__property-container__value">
                  {{ ip_address ?? '...' }}
                </span>

                <span
                  *ngIf="ip_address"
                  class="device-details-properties__property-container__value clickable"
                  [popperTrigger]="$any('click')"
                  [popperTimeoutAfterShow]="1000"
                  [popper]="'ALL.ACTIONS.Copied !' | translate"
                  (click)="clipboard.copy(ip_address)"
                >
                  <span class="mdi mdi-24px mdi-content-copy"></span>
                </span>

                <span *ngIf="ip_address" class="device-details-properties__property-container__value clickable">
                  <a
                    class="mdi mdi-24px mdi-web"
                    target="_blank"
                    rel="nofollow noopener noreferrer"
                    [href]="'http://' + ip_address.split('/')[0]"
                  ></a>
                </span>
              </div>

              <!-- Public key -->
              <div *ngLet="wireguard_credentials?.public_key; let public_key" class="body-container__property-container">
                <div class="device-details-properties__property-container__label-container">
                  <span class="mdi mdi-key mdi-24px"></span>
                  <b>{{ 'DEVICE.TYPE.CPT.CREDENTIALS.Public key' | translate | colon }}</b>
                </div>

                <span class="device-details-properties__property-container__value">
                  {{ public_key ?? '...' }}
                </span>

                <span
                  *ngIf="public_key"
                  class="device-details-properties__property-container__value clickable"
                  [popperTrigger]="$any('click')"
                  [popperTimeoutAfterShow]="1000"
                  [popper]="'ALL.ACTIONS.Copied !' | translate"
                  (click)="clipboard.copy(public_key)"
                >
                  <span class="mdi mdi-24px mdi-content-copy"></span>
                </span>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </ng-container>

  <!-- CARD: Active supports -->
  <div *ngIf="view === 'details'" class="device-card-template">
    <div class="device-card-template__normal-container">
      <div class="device-detail__title-container">
        <div class="title-container__icon --color-brown">
          <span class="mdi mdi-face-agent mdi-36px"></span>
        </div>

        <div class="title-container__title">Support</div>
      </div>

      <div class="device-modal-card-container__body-container">
        <ng-container *ngLet="device_opened_supports$$ | async; let device_opened_supports">
          <ng-container *ngIf="device_opened_supports?.length === 0; else showOpenedSupports">
            <bg2-ui-message [type]="'success'" [title]="'DEVICE.SUPPORTS.ERROR.No support opened' | translate">
              <div message>
                <span>
                  {{ 'DEVICE.SUPPORTS.MISC.There is no opened support for this device' | translate }}
                </span>

                <span>
                  {{ 'DEVICE.SUPPORTS.MISC.If you have a problem, you can click on the button just below' | translate }}
                </span>
              </div>
            </bg2-ui-message>
          </ng-container>

          <ng-template #showOpenedSupports>
            <ng-container *ngFor="let support of device_opened_supports">
              <bg2-ui-message [type]="'warning'" [title]="'DEVICE.SUPPORTS.MISC.Support [type]' | translate : { type: support.type }">
                <div message>
                  <span>
                    {{
                      'DEVICE.SUPPORTS.MISC.Support opened since [date] ([distance_to_now])'
                        | translate
                          : { date: support.start_time | formatDate : 'full', distance_to_now: support.start_time | distanceToNow }
                    }}
                  </span>
                </div>
              </bg2-ui-message>
            </ng-container>
          </ng-template>

          <bg2-button
            [type]="'action'"
            [icon]="'mdi-email'"
            [message]="'DEVICE.SUPPORTS.Ask new support' | translate"
            [internal_routing]="{
              queryParamsHandling: 'preserve',
              routerLink: ['', { outlets: { modal: ['new_issue', { type: 'device_support', data: (ask_device_support_data$$ | async) }] } }]
            }"
          ></bg2-button>
        </ng-container>

        <bg2-zoho-manager-container
          *ngIf="is_superadmin$$ | async"
          [title]="'MODELS.ZOHO.DESK.TICKET.List of opened DESK tickets' | translate"
          (refetch_zoho_data)="refetch_zoho_data.value = $event"
        >
          <bg2-zoho-ticket-list-opened
            [source]="device"
            [should_refetch_zoho_data]="refetch_zoho_data.value$$ | async"
          ></bg2-zoho-ticket-list-opened>
        </bg2-zoho-manager-container>
      </div>
    </div>
  </div>

  <!-- Device history -->
  <div *ngIf="view === 'details'" class="device-details-history">
    <bg2-device-history [device]="device"></bg2-device-history>
  </div>
</div>
