<div class="device-authorized-movements-modal-table-view-component">
  <!-- Grid container -->
  <div class="bg2-grid-wrapper">
    <!-- Grid configuration/export toolbar -->
    <mat-toolbar class="export-and-configuration-toolbar">
      <mat-toolbar-row *ngIf="activate_groups" class="group-and-export-row">
        <bg2-table-groups-breadcrumb
          [groups]="grouped_columns$$ | async"
          [all_columns]="flattened_columns$$ | async"
          (groupsChange)="set_grouped_columns($event)"
        ></bg2-table-groups-breadcrumb>
      </mat-toolbar-row>

      <!-- <mat-toolbar-row
        *ngIf="(has_error$$ | async) || selection_model?.selected?.length >= 1"
        class="error-and-selected-row"
        [class.has-error]="has_error$$ | async"
        [class.has-selected]="selection_model?.selected?.length >= 1"
      >
        <ng-container *ngIf="error$$ | async">
          <span>{{ 'VIEWS.MODALS.NEW_ISSUE.An unknown error occurred, please retry' | translate }}</span>
        </ng-container>

        <div
          class="selected-items-management"
          *ngIf="(has_not_error$$ | async) && selection_model?.selected?.length >= 1 && !TOOLBARS_CONFIG.selection.disabled"
        >
          <span>{{ TOOLBARS_CONFIG.selection.text | translate: { total: selection_model.selected.length } }}</span>

          <div>
            <mat-form-field>
              <mat-label>{{ ('VIEWS.DATATABLE.to do the action' | translate) + '...' }}</mat-label>
              <mtx-select
                #selectActionComponent
                [bindValue]="'type'"
                [ngModel]="selected_action$$ | async"
                [items]="
                  (appState.is_superadmin$$ | async)
                    ? TOOLBARS_CONFIG.selection.available_actions
                    : (TOOLBARS_CONFIG.selection.available_actions | filter: 'only_admin':false)
                "
                (ngModelChange)="set_selected_action($event)"
              >
                <ng-template ng-label-tmp let-item="item">
                  {{ item.label | translate }}
                </ng-template>

                <ng-template ng-option-tmp let-item="item">
                  {{ item.label | translate }}
                </ng-template>
              </mtx-select>
            </mat-form-field>
          </div>
        </div>
      </mat-toolbar-row> -->
    </mat-toolbar>

    <div class="bg2-grid-container" [ngClass]="{ empty: data_source?.data?.length === 0 }">
      <ng-container *ngIf="loading$$ | async">
        <mat-progress-bar class="top-loading" [mode]="'indeterminate'"></mat-progress-bar>
      </ng-container>

      <table mat-table [dataSource]="data_source" matSort [matSortActive]="sort__active$$ | async" [matSortDirection]="sort__direction$$ | async" (matSortChange)="onSortData($event)">
        <!-- #region .headers-and-cells-templates -->
        <ng-container *ngFor="let column of flattened_columns$$ | async; trackBy:track_by_column" [matColumnDef]="column.property">
          <!-- #region .header-definitions -->
          <th
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header
            class="{{ column_sort_type(column) }}"
            [disabled]="!column?.is_sortable"
            [@.disabled]="true"
          >
            <ng-container [ngSwitch]="column.property">
              <!-- Column type: "select" -->
              <!-- <ng-container *ngSwitchCase="'select'">
                <mat-checkbox
                  [color]="'primary'"
                  (change)="$event ? masterToggle() : null"
                  [disabled]="loading$$ | async"
                  [ngModel]="all_selected$$ | async"
                  [indeterminate]="(some_selected$$ | async) && !(all_selected$$ | async)"
                ></mat-checkbox>
              </ng-container> -->

              <!-- Default column header -->
              <ng-container *ngSwitchDefault>
                {{ column.label | translate }}
              </ng-container>
            </ng-container>
          </th>
          <!-- #endregion -->

          <!-- #region .content-definitions -->
          <td
            mat-cell
            *matCellDef="let table_row"
            [attr.data-label]="column?.label | translate"
            class="col-{{ column.property }}"
            [class.selected]="isSelected$$(table_row) | async"
            (click)="column?.property === 'select' ? selection_model.toggle(table_row) + $event.stopPropagation() : null"
          >
            <ng-container [ngSwitch]="column.property">
              <!-- Column type: "select" -->
              <!-- <ng-container *ngSwitchCase="'select'">
                <mat-checkbox
                  [color]="'primary'"
                  [disabled]="loading$$ | async"
                  (click)="$event.stopPropagation()"
                  (change)="$event ? selection_model.toggle(device_row) : null"
                  [checked]="isSelected$$(device_row) | async"
                ></mat-checkbox>
              </ng-container> -->

              <!-- Column type: "config.id" -->
              <ng-container *ngSwitchCase="'config.id'">
                <ng-container *ngIf="assertRowModel(table_row) as row">
                  {{ row.config.id }}
                </ng-container>
              </ng-container>

              <!-- Column type: "config.id" -->
              <ng-container *ngSwitchCase="'config.device.imei'">
                <ng-container *ngIf="assertRowModel(table_row) as row">
                  <a
                    [queryParamsHandling]="'preserve'"
                    [routerLink]="['', { outlets: { modal: ['device', { imei: row.device.imei }] } }]"
                    (click)="$event.stopPropagation()"
                  >
                    {{ row.device.name }}
                  </a>
                </ng-container>
              </ng-container>

              <!-- Column type: "config.id" -->
              <ng-container *ngSwitchCase="'config.start'">
                <ng-container *ngIf="assertRowModel(table_row) as row">
                  {{ row.config.start | formatDate: 'full' }}
                </ng-container>
              </ng-container>

              <!-- Column type: "config.id" -->
              <ng-container *ngSwitchCase="'config.end'">
                <ng-container *ngIf="assertRowModel(table_row) as row">
                  {{ row.config.end | formatDate: 'full' }}
                </ng-container>
              </ng-container>

              <!-- Column other cols -->
              <ng-container *ngSwitchDefault>
                <span>{{ get(table_row, column.property) }}</span>
              </ng-container>
            </ng-container>
          </td>
          <!-- #endregion -->
        </ng-container>
        <!-- #endregion -->

        <!-- #region .specific-templates -->
        <ng-container matColumnDef="group-header">
          <td mat-cell *matCellDef="let group" [colSpan]="(displayed_columns$$ | async).length">
            <span
              class="header-content mdi mdi-chevron-{{ group.expanded ? 'up' : 'down' }} mdi-24px"
              [style.padding-left.px]="(group.level - 1) * 24"
            >
              <ng-container [ngSwitch]="group.last_property">
                <!-- Location Group Display -->
                <!-- <ng-container *ngSwitchCase="'affectation.location.name'">
                  {{ group.last_col_value }} ({{ group.totalCounts }})
                  <a
                    *ngIf="group.first_row.affectation.location"
                    [routerLink]="[
                      '',
                      { outlets: { modal: ['location_details', { location_id: group.first_row.affectation.location?.id }] } }
                    ]"
                    [queryParamsHandling]="'preserve'"
                  >
                    <i class="mdi mdi-magnify mdi-24px"></i>
                  </a>
                  <div *ngIf="group.first_row.affectation.apiary" class="on-apiary" (click)="$event.stopPropagation()">
                    {{ 'ENTITY.ALL.TYPE.apiary' | translate }}
                    <a
                      [routerLink]="['', { outlets: { modal: ['update_entity', { eid: group.first_row.affectation.apiary?.id }] } }]"
                      [queryParamsHandling]="'preserve'"
                    >
                      {{ group.first_row.affectation.apiary?.name }}
                    </a>
                  </div>
                </ng-container> -->

                <ng-container *ngSwitchDefault>
                  {{ group.label | translate }} : {{ group.last_col_value }} ({{ group.totalCounts }})
                </ng-container>
              </ng-container>
            </span>
          </td>
        </ng-container>
        <!-- #endregion -->

        <!-- #region .row-definitions -->
        <tr mat-header-row *matHeaderRowDef="displayed_columns$$ | async"></tr>
        <tr
          mat-row
          *matRowDef="let table_row; columns: displayed_columns$$ | async"
          class="data-row"
          [ngClass]="{ 'not-interactable': !table_row.editable }"
          [class.selected]="isSelected$$(table_row) | async"
          (click)="update_authorized_movement(table_row)"
        ></tr>

        <tr mat-row *matRowDef="let row; columns: ['group-header']; when: isGroup" (click)="groupHeaderClick(row)"></tr>
        <!-- #endregion -->
      </table>
    </div>

    <!-- Empty grid message -->
    <div *ngIf="data_source?.data.length === 0 && (loading$$ | async) === false" class="empty-grid-message">
      <img src="assets/img/large_up_arrow.png" />
      <div class="text">
        {{ 'VIEWS.DEVICES.DIALOGS_AND_MODALS.DEVICE_MOVEMENTS_AUTHORIZATION_MODAL.TABLE_VIEW.No matching configurations' | translate }}
      </div>
    </div>
  </div>
</div>
