<div class="alarm alarm-mvnt" [class.authorized]="alarm?.last_movement_status?.is_authorized">
  <div class="left-part">
    <div class="message">
      <img [src]="'assets/img/pictos/GPS_mvnt_w.png'" class="icon" />
      <div class="title">
        <span translate>DEVICE.ALL.ALARM.Movement alarm since</span>
        <span> {{ alarm?.last_movement_status?.movement_start_time | distanceToNow:true }}</span>
        <span *ngIf="alarm?.last_movement_status?.is_authorized" class="authorized" translate>DEVICE.ALL.ALARM.Authorized</span>
      </div>
    </div>

    <div class="detail">
      <div class="links">
        <span class="device" *ngIf="alarm?.device">
          <span translate>DEVICE.ALL.ALARM.Device:</span>
          <a
            [routerLink]="[
              '',
              {
                outlets: {
                  modal: [
                    'device',
                    {
                      imei: alarm.device.imei,
                      id_v1: alarm.device.id_v1
                    }
                  ]
                }
              }
            ]"
            [queryParamsHandling]="'preserve'"
          >
            {{ alarm.device.name }}
          </a>
          <a
            [routerLink]="['', { outlets: { modal: ['route_tracer', { device_imei: alarm.device.imei }] } }]"
            [queryParamsHandling]="'preserve'"
          >
            <i class="mdi mdi-map-marker-path mdi-24px"></i>
          </a>
        </span>

        <span class="hive-name" *ngIf="alarm?.hive"> ({{ alarm.hive.name | truncate: 15:'...' }}) </span>

        <!-- <span class="last_msg" *ngIf="alarm.last_rf2">
          <span translate>DEVICE.ALL.ALARM.Last msg:</span>
          <span class="value">
            <span>"{{ alarm.last_rf2.tracking }}"</span>
            <span> ({{ alarm.last_rf2.timestamp_embeded | formatDate: 'full' }})</span>
          </span>
        </span> -->
      </div>
    </div>
  </div>

  <div class="actions">
    <bg2-button
      [type]="'navigation'"
      [icon]="'mdi-map-marker-path'"
      [options]="{ is_thin: true, is_label_hidden_on_small: true }"
      [message]="'VIEWS.MODALS.DEVICE.See the move' | translate"
      [internal_routing]="{ routerLink: ['', { outlets: { modal: ['route_tracer', { device_imei: alarm.device.imei }] } }] }"
    ></bg2-button>
    <bg2-button
      *ngIf="show_authorize_button && !alarm?.last_movement_status?.is_authorized"
      [type]="'action'"
      [icon]="'mdi-cursor-move'"
      [options]="{ is_thin: true, is_label_hidden_on_small: true }"
      [message]="'VIEWS.MODALS.DEVICE.Add move authorization' | translate"
      [internal_routing]="{ routerLink: ['', { outlets: { modal: ['device_move_auth', { 'imeis': ([alarm.device.imei] | json), 'new': true }] } }] }"
    ></bg2-button>
  </div>
</div>
