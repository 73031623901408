import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { SchemaFormModule } from 'ngx-schema-form';

import { SharedModule } from 'app/shared-module/shared.module';
import { MiscModule } from 'app/misc/misc.module';
import { MaterialSharedModule } from 'app/shared-module/material-shared.module';
import { MiscWidgetsModule } from 'app/widgets/misc-widgets/misc-widgets.module';
import { DialogsModalsModule } from 'app/widgets/dialogs-modals/dialogs-modals.module';
import { WidgetsReusableModule } from 'app/widgets/widgets-reusables/widgets-reusables.module';

import { EventLinkComponent } from './shared/event-link/event-link.component';
import { EventSpanComponent } from './shared/event-span/event-span.component';
import { EntityHistoryComponent } from './shared/entity-history/event-history.component';
import { EventFormInlineComponent } from './shared/event-form-inline/event-form-inline.component';
import { DeleteEventDialogComponent } from './shared/delete-event-dialog/delete-event-dialog.component';
import { Bg2EvaluationHistogramHiveStatusesComponent } from './shared/events/evaluation/evaluation-histogram-hive-statuses/evaluation-histogram-hive-statuses.component';
import { Bg2EvaluationHistogramHiveBroodframesComponent } from './shared/events/evaluation/evaluation-histogram-hive-broodframes/evaluation-histogram-hive-broodframes.component';
import { Bg2EvaluationHistogramHiveQueensComponent } from './shared/events/evaluation/evaluation-histogram-hive-queens/evaluation-histogram-hive-queens.component';
import { Bg2EvaluationHistogramHiveSupersComponent } from './shared/events/evaluation/evaluation-histogram-hive-supers/evaluation-histogram-hive-supers.component';

import { Bg2SuperboxHarvestHistogramHoneyFactoryQuantityComponent } from './shared/events/superbox_harvest/superbox-harvest-histogram-honey-factory-quantity/superbox-harvest-histogram-honey-factory-quantity.component';
import { EntityEventsHistoryComponent } from './shared/entity-events-history/entity-events-history.component';
import { Bg2EvaluationSummaryComponent } from './shared/events/evaluation/evaluation-resume/evaluation-resume.component';
import { HivesSharedModule } from '../hives/shared/hives-shared.module';

@NgModule({
  declarations: [
    EventLinkComponent,
    EventSpanComponent,
    EntityHistoryComponent,
    EntityEventsHistoryComponent,
    EventFormInlineComponent,
    DeleteEventDialogComponent,

    Bg2EvaluationHistogramHiveQueensComponent,
    Bg2EvaluationHistogramHiveSupersComponent,
    Bg2EvaluationHistogramHiveStatusesComponent,
    Bg2EvaluationHistogramHiveBroodframesComponent,
    Bg2EvaluationSummaryComponent,

    Bg2SuperboxHarvestHistogramHoneyFactoryQuantityComponent,
  ],
  imports: [
    MiscModule,
    CommonModule,
    RouterModule,
    SharedModule,
    SchemaFormModule,
    SchemaFormModule,
    HivesSharedModule,
    MiscWidgetsModule,
    DialogsModalsModule,
    MaterialSharedModule,
    WidgetsReusableModule,
  ],
  exports: [
    EntityEventsHistoryComponent,
    DeleteEventDialogComponent,
    EventLinkComponent,
    EventSpanComponent,
    EventFormInlineComponent,
    EntityHistoryComponent,
    
    Bg2EvaluationHistogramHiveQueensComponent,
    Bg2EvaluationHistogramHiveSupersComponent,
    Bg2EvaluationHistogramHiveStatusesComponent,
    Bg2EvaluationHistogramHiveBroodframesComponent,
    Bg2EvaluationSummaryComponent,
    
    Bg2SuperboxHarvestHistogramHoneyFactoryQuantityComponent,
  ],
  providers: [],
})
export class EventsSharedModule {}
