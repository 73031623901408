import { Component, Input } from '@angular/core';

import { BehaviorSubject, Observable, map, of, switchMap } from 'rxjs';

import {
  DEVICE_BATTERY_TYPE,
  DEVICE_SIMPLIFIED_BATTERY_STATE,
  DEVICE_SIMPLIFIED_BATTERY_STATE_REASON,
  DRDevice,
  DeviceInterface,
} from 'app/models';
import { replay, waitForNotNilValue } from 'app/misc/tools';
import { BatChange } from 'app/core/api-swagger/device';
import { isArray, isNil } from 'lodash-es';
import { differenceInDays, differenceInYears } from 'date-fns';

interface BatteryInformations {
  hwv: number;
  device_type: DeviceInterface.TypeEnum | string;
  battery_com_voltage: number | number[];
  battery_noload_voltage: number | number[];
  battery_range_vbat: [number, number];
  battery_critical_vbat: number;
  last_contact: Date;
  bat_info: DEVICE_BATTERY_TYPE;
  last_power_on: Date;
  last_battery_change: BatChange;
  last_temperature: number;
}

@Component({
  selector: 'bg2-battery-level-simplified',
  templateUrl: './battery-level-simplified.component.html',
  styleUrls: ['./battery-level-simplified.component.scss'],
})
export class BatteryLevelSimplifiedComponent {
  // #region -> component basics

  /** */
  public BATTERY_STATE = DEVICE_SIMPLIFIED_BATTERY_STATE;

  /** */
  public BATTERY_REASON = DEVICE_SIMPLIFIED_BATTERY_STATE_REASON;

  /** */
  constructor() {}

  // #endregion

  /** */
  @Input()
  public open_in_modal = true;

  @Input()
  public simple_state: DEVICE_SIMPLIFIED_BATTERY_STATE;

  /** */
  public is_popper_disabled = true;

  // #region -> device

  /** */
  @Input()
  public set device(device: DRDevice) {
    this._device$$.next(device);
    this.is_popper_disabled = false;
  }

  /** */
  private _device$$: BehaviorSubject<DRDevice> = new BehaviorSubject(null);

  /** */
  public device$$: Observable<DRDevice> = this._device$$.asObservable().pipe(waitForNotNilValue(), replay());

  /** */
  public device_imei$$: Observable<number> = this.device$$.pipe(
    switchMap(device => {
      if (isNil(device)) {
        return of(null);
      }

      return device?.imei$$;
    }),
    replay()
  );

  /**
   * Observe the type of the device.
   *
   * @observable
   */
  public device_type$$: Observable<DeviceInterface.TypeEnum | string> = this.device$$.pipe(
    switchMap(device => device?.type$$ ?? of(null)),
    replay()
  );

  /** */
  public is_not_beelive_nor_cpt$$ = this.device_type$$.pipe(
    waitForNotNilValue(),
    map((device_type: DeviceInterface.TypeEnum) => {
      if (device_type === 'CPT' || device_type === 'BeeLive') {
        return false;
      }

      return true;
    }),
    replay()
  );

  // #endregion

  // #region -> last voltage

  /** */
  public last_voltage$$ = this.device$$.pipe(
    switchMap(device => {
      if (isNil(device)) {
        return of(null);
      }

      return device?.last_battery_voltage_calibrated$$;
    }),
    replay()
  );

  // #endregion

  // #region -> last contact

  /** */
  public last_contact$$ = this.device$$.pipe(switchMap(device => device?.last_contact$$));

  /** */
  public has_last_contact$$ = this.last_contact$$.pipe(
    map(last_contact => !isNil(last_contact)),
    replay()
  );

  // #endregion

  // #region -> last power on

  /** */
  public last_power_on$$ = this.device$$.pipe(switchMap(device => device?.last_power_on$$));

  // #endregion

  // #region -> battery state

  /** */
  public battery_simplified_state$$ = this.device$$.pipe(
    switchMap(device => device.battery_simplified_state$$),
    replay()
  );

  /** */
  public last_battery_change$$ = this.device$$.pipe(switchMap(device => device?.last_battery_change$$));

  /** */
  public has_last_battery_change$$ = this.last_battery_change$$.pipe(map(last_battery_change => !isNil(last_battery_change)));

  // #endregion
}
