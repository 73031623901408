import { marker as i18n } from '@biesbjerg/ngx-translate-extract-marker';

export enum BlacklistReason {
  THIEFTED = 'thiefed',
  NOT_A_LOCATION = 'not_a_locaton',
  DO_NOT_PRECISE = 'do_not_precise',
  BACK_TO_WAREHOUSE = 'back_to_warehouse',
}

export const BlacklistReasonI18N = new Map<BlacklistReason, string>([
  [BlacklistReason.THIEFTED, i18n<string>('ENTITY.WAREHOUSE.BLACKLIST_REASON.thiefed')],
  [BlacklistReason.NOT_A_LOCATION, i18n<string>('ENTITY.WAREHOUSE.BLACKLIST_REASON.not_a_locaton')],
  [BlacklistReason.DO_NOT_PRECISE, i18n<string>('ENTITY.WAREHOUSE.BLACKLIST_REASON.do_not_precise')],
  [BlacklistReason.BACK_TO_WAREHOUSE, i18n<string>('ENTITY.WAREHOUSE.BLACKLIST_REASON.back_to_warehouse')],
]);
