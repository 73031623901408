import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime-ex';

import { SharedModule } from 'app/shared-module/shared.module';
import { MiscModule } from 'app/misc/misc.module';

import { MiscWidgetsModule } from 'app/widgets/misc-widgets/misc-widgets.module';
import { DevicesSharedModule } from 'app/views/devices/shared/devices-shared.module';
import { HivesSharedModule } from 'app/views/hives/shared/hives-shared.module';
import { DialogsModalsModule } from 'app/widgets/dialogs-modals/dialogs-modals.module';

import { MaterialSharedModule } from 'app/shared-module/material-shared.module';
import { EventsSharedModule } from 'app/views/events/events-shared.module';
import { WidgetsReusableModule } from 'app/widgets/widgets-reusables/widgets-reusables.module';
import { OtherInterventionDialogComponent } from './shared/other-intervention-dialog/other-intervention.dialog';
import { ApiaryDetailsComponent } from './shared/apiary-details/apiary-details.component';
import { ApiaryLastDataCardHiveComponent } from './shared/widgets/apiary-last-data/apiary-last-data-card/apiary-last-data-card-hive/apiary-last-data-card-hive.component';
import { ApiaryLastDataCardWeatherStationComponent } from './shared/widgets/apiary-last-data/apiary-last-data-card/apiary-last-data-card-weather/apiary-last-data-card-weather.component';
import { ApiaryLastDataComponent } from './shared/widgets/apiary-last-data/apiary-last-data.component';
import { ApiaryBREvaluationCarouselGroupComponent } from './shared/widgets/breeding-register/apiary-br-evaluation-carousel/apiary-br-evaluation-carousel-group.component';
import { ApiaryBREvaluationCarouselComponent } from './shared/widgets/breeding-register/apiary-br-evaluation-carousel/apiary-br-evaluation-carousel.component';
import { ApiaryCarouselGroupsuperboxHarvestComponent } from './shared/widgets/breeding-register/apiary-carousel-superbox-harvest/apiary-carousel-group-sh.component';
import { ApiaryCarouselSuperboxHarvestComponent } from './shared/widgets/breeding-register/apiary-carousel-superbox-harvest/apiary-carousel-sh.component';
import { ApiaryHivesNucsSummaryComponent } from './shared/widgets/apiary-hives-nucs-summary/apiary-hives-nucs-summary.component';
import { ApiaryUndefinedComponent } from './shared/widgets/apiary-undefined/apiary-undefined.component';
import { ApiaryChartWeatherContainerComponent } from './shared/widgets/apiary-charts/apiary-weather-data-container/apiary-weather-data-container.component';
import { ApiaryWeatherD3ChartComponent } from './shared/widgets/apiary-charts/apiary-weather-data-container/apiary-weather-d3-chart/apiary-weather-d3-chart.component';
import { ApiarySensorDataContainerComponent } from './shared/widgets/apiary-charts/apiary-sensor-data-container/apiary-sensor-data-container.component';
import { ApiaryWeightD3ChartComponent } from './shared/widgets/apiary-charts/apiary-sensor-data-container/apiary-hives-weight-d3-chart/apiary-weight-d3-chart.component';
import { ApiaryWindD3ChartComponent } from './shared/widgets/apiary-charts/apiary-weather-data-container/apiary-wind-d3-chart/apiary-wind-d3-chart.component';
import { Bg2ApiaryHivesWeightDiffD3ChartComponent } from './shared/widgets/apiary-charts/apiary-sensor-data-container/apiary-hives-weight-diff-d3-chart/apiary-hives-weight-diff-d3-chart.component';
import { ApiaryChartsFooterComponent } from './shared/widgets/apiary-charts/apiary-charts-footer/apiary-charts-footer.component';
import { ApiaryWeatherIconsD3ChartComponent } from './shared/widgets/apiary-charts/apiary-weather-data-container/apiary-weather-icons-d3-chart/apiary-weather-icons-d3-chart.component';
import { ApiaryHivesBeeCountD3ChartComponent } from './shared/widgets/apiary-charts/apiary-sensor-data-container/apiary-hives-beecount-d3-chart/apiary-hives-beecount-d3-chart.component';
import { ApiaryHivesTemperatureD3ChartComponent } from './shared/widgets/apiary-charts/apiary-sensor-data-container/apiary-hives-temperature-d3-chart/apiary-hives-temperature-d3-chart.component';
import { ApiaryDetailsCompactComponent } from './shared/apiary-details-compact/apiary-details-compact.component';
import { ApiaryChartsForCompactComponent } from './shared/widgets/apiary-charts/apiary-charts-for-compact/apiary-charts-for-compact.component';
import { ApiaryHivesWeightD3ChartWrapperComponent } from './shared/widgets/apiary-chart-wrappers/apiary-hives-weight-d3-chart-wrapper/apiary-hives-weight-d3-chart-wrapper.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    SharedModule,
    MiscModule,
    DevicesSharedModule,
    HivesSharedModule,
    MiscWidgetsModule,
    DialogsModalsModule,
    MaterialSharedModule,
    EventsSharedModule,
    WidgetsReusableModule,
  ],
  declarations: [
    OtherInterventionDialogComponent,

    // new components (views)
    ApiaryDetailsComponent,
    ApiaryDetailsCompactComponent,

    // new components (widgets)
    ApiaryLastDataComponent,
    ApiaryLastDataCardHiveComponent,
    ApiaryLastDataCardWeatherStationComponent,

    ApiaryHivesNucsSummaryComponent,

    ApiaryBREvaluationCarouselComponent,
    ApiaryBREvaluationCarouselGroupComponent,

    ApiaryCarouselSuperboxHarvestComponent,
    ApiaryCarouselGroupsuperboxHarvestComponent,

    // Widgets (...device data)
    ApiarySensorDataContainerComponent,

    // Widgets (...charts)
    ApiaryWindD3ChartComponent,
    ApiaryWeightD3ChartComponent,
    ApiaryWeatherD3ChartComponent,
    ApiaryChartsForCompactComponent,
    ApiaryWeatherIconsD3ChartComponent,
    ApiaryHivesBeeCountD3ChartComponent,
    ApiaryHivesTemperatureD3ChartComponent,
    Bg2ApiaryHivesWeightDiffD3ChartComponent,

    // Widgets (misc.)
    ApiaryChartWeatherContainerComponent,
    ApiaryUndefinedComponent,
    ApiaryChartsFooterComponent,

    ApiaryHivesWeightD3ChartWrapperComponent,
  ],
  providers: [],
  exports: [
    // new components
    ApiaryDetailsComponent,
    ApiaryDetailsCompactComponent,

    // Widgets (misc.)
    ApiaryUndefinedComponent,
    ApiaryHivesWeightD3ChartWrapperComponent,
  ],
})
export class ApiarySharedModule {}
