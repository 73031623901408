import { ChangeDetectionStrategy, Component } from '@angular/core';

import { FormProperty, ISchema, PropertyGroup, Validator } from 'ngx-schema-form';

import { BehaviorSubject, map, of, take } from 'rxjs';
import { replay } from '@bg2app/tools/rxjs';

import { marker as i18n } from '@biesbjerg/ngx-translate-extract-marker';

import { AbstractDialogComponent, AbstractDialogParams } from 'app/widgets/dialogs-modals';
import { every, isEmpty, isNil, some } from 'lodash-es';
import { ConsoleLoggerService } from 'app/core/console-logger.service';

/** */
export interface ImportDevicesToCreateDialogParams extends AbstractDialogParams {}

/** */
interface ImportDevicesFormData {
  /** */
  imeis: string;
}

@Component({
  selector: 'bg2-import-devices-to-create',
  templateUrl: './import-devices-to-create-dialog.component.html',
  styleUrls: ['./import-devices-to-create-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ImportDevicesToCreateDialogComponent extends AbstractDialogComponent<ImportDevicesToCreateDialogParams, number[] | null> {
  // #region -> (component basics)

  /** */
  private readonly LOGGER = new ConsoleLoggerService('ImportDevicesToCreateDialogComponent', false);

  constructor() {
    super();
  }

  // #endregion

  // #region -> (dialog final action)

  /** */
  public cancel(): void {
    this.complete(null);
  }

  /** */
  public import_devices(): void {
    this.form_model$$
      .pipe(
        map(form_model => form_model.imeis),
        map(imeis_as_string => {
          let imeis = (imeis_as_string ?? '').split('\n');
          imeis = imeis.filter(imei => !isNil(imei) && !isEmpty(imei));

          return imeis;
        }),
        map(imeis => imeis.map(imei => parseInt(imei, 10))),
        take(1)
      )
      .subscribe({
        next: imeis => this.complete(imeis),
      });
  }

  // #endregion

  // #region -> (schema management)

  /** */
  private readonly _default_schema: ISchema = {
    type: 'object',
    required: ['imeis'],
    properties: {
      imeis: {
        type: 'string',
        widget: 'textarea',
        label: i18n<string>('VIEWS.DEVICES.DIALOGS_AND_MODALS.CREATE_DEVICE_MODAL.IMEIs (one per line)'),
        options: {
          focus: true
        }
      },
    },
  };

  /** */
  public form_schema$$ = of(this._default_schema).pipe(replay());

  // #endregion

  // #region -> (custom validators)

  /** */
  public form_validators: { [key: string]: Validator } = {
    '/imeis': (value: string, form_property: FormProperty, form: PropertyGroup) => {
      let errors: [{ [key: string]: any }] = null;

      if (isNil(value) || isEmpty(value)) {
        return null;
      }

      const parsed_imeis = value.split('\n');

      // Check if no spaces in IMEIS
      const has_space = some(parsed_imeis.map(imei => imei.indexOf(' ') > 0));
      if (has_space) {
        errors = [
          {
            code: 'PATTERN',
            path: `#${form_property.findRoot().getProperty('imeis')}`,
            message: 'ALL.ERROR.IMEI.IMEI must not have spaces',
            params: [value],
          },
        ];

        return errors;
      }

      // Check if only numbers
      const has_only_numbers = every(parsed_imeis.map(imei => /^[0-9]+$/.test(imei)));
      if (!has_only_numbers) {
        errors = [
          {
            code: 'PATTERN',
            path: `#${form_property.findRoot().getProperty('imeis')}`,
            message: 'ALL.ERROR.IMEI.IMEI must only have numbers',
            params: [value],
          },
        ];

        return errors;
      }

      return errors;
    },
  };

  // #endregion

  // #region -> (form model management)

  private readonly default_form_value: ImportDevicesFormData = {
    imeis: '',
  };

  /** */
  private _form_model$$ = new BehaviorSubject<ImportDevicesFormData>(this.default_form_value);

  /** */
  public form_model$$ = this._form_model$$.asObservable().pipe();

  /** */
  public onFormChanged(fdata: ImportDevicesFormData): void {
    this._form_model$$.next(fdata);
  }

  // #endregion

  // #region -> (form validity)

  /** */
  private _is_form_valid$$ = new BehaviorSubject(false);

  /** */
  public is_form_valid$$ = this._is_form_valid$$.asObservable();

  /** */
  public set is_form_valid(is_form_valid: boolean) {
    this._is_form_valid$$.next(is_form_valid);
  }

  // #endregion
}
