import { HttpClient } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';

import RxSingletonLock from 'rx-singleton-lock';

import { assign, concat, isNil, isEmpty, min, flatten, uniq, find } from 'lodash-es';

import {
  BehaviorSubject,
  combineLatest,
  Observable,
  of,
  race,
  Subscription,
  map,
  tap,
  delay,
  filter,
  switchMap,
  take,
  forkJoin,
} from 'rxjs';

import { ApiCache } from '../misc/api-cache';
import { UsersApiService } from '../user/users-api.service';
import { DeviceApi } from '../device/device-api-service';
import { Beeguard2Stream } from './beeguard2-stream-service';
import { Beeguard2RunService } from '../run/beeguard2-run-service';
import { ConsoleLoggerService } from 'app/core/console-logger.service';
import { BeeguardAuthService } from '../../auth/beeguard-auth.service';

import {
  Hive,
  Event,
  Entity,
  Apiary,
  Paging,
  Location,
  Warehouse,
  PagingQuery,
  Exploitation,
  eventDeserialize,
  entityDeserialize,
} from 'app/models';

import { EventSchema } from '../../api-swagger/beeguard2/model/eventSchema';
import { Event as EventInterface } from '../../api-swagger/beeguard2/model/event';
import { Entity as EntityInterface } from '../../api-swagger/beeguard2/model/entity';
import {
  Beeguard2Service as Beeguard2ApiSwagger,
  GetEntitiesResponse,
  GetEntityEventsResponse,
  UpdateEventParams,
} from '../../api-swagger/beeguard2';

import { parseDate } from 'app/misc/tools';

import { environment } from 'environments/environment';
import { replay, waitForNotNilValue } from '@bg2app/tools/rxjs';
import { HttpBatch } from 'app/models/http-batch';
import { RequestInQueue } from 'app/models/http-batch/interfaces/request-in-queue';
import { RequestsToBatchByQueryParameters } from 'app/models/http-batch/interfaces/request-to-batch-by-qparams';
import { AclAndOwner } from 'app/core/api-swagger/beeguard2/model/aclAndOwner';
import { ZohoApisService } from 'app/core/services/zoho/zoho-apis.service';

/** */
export interface EventPaging {
  /** */
  paging: Paging;

  /** */
  events: Event[];
}

/** */
export interface EventRequestConfig {
  /** */
  end?: Date;

  /** */
  start?: Date;

  /** */
  asc?: boolean;

  /** */
  limit?: number;

  /** */
  offset?: number;

  /** */
  types?: string[];

  /** */
  run_info?: boolean;
}

/** */
export type OneOfEntity = Warehouse | Exploitation | Location | Apiary | Hive;

/** */
@Injectable({
  providedIn: 'root',
})
export class Beeguard2Api extends Beeguard2ApiSwagger implements OnDestroy {
  /** */
  protected basePath = environment.Beeguard2ApiUrl;

  /** */
  protected LOGGER = new ConsoleLoggerService('Beeguard2Api', false);

  /** */
  private _ghost_update$$ = new BehaviorSubject<number>(0);

  /** */
  public ghost_update$$ = this._ghost_update$$.asObservable();

  /** */
  private entity_cache = new ApiCache<Entity, number>();

  /** */
  private event_schema_cache = new ApiCache<EventSchema, string>();

  // #region -> (api basics)

  /** */
  private _bg2Stream_sub: Subscription = null;

  /** */
  private _impersonate_id_sub: Subscription = null;

  private _headers_token_is_set$ = new BehaviorSubject<boolean>(null);
  private wait_headers_token_is_set$$ = this._headers_token_is_set$.pipe(waitForNotNilValue(), replay());

  /** */
  constructor(
    public userApi: UsersApiService,
    public zohoApis: ZohoApisService,
    public deviceApi: DeviceApi,
    public bg2Stream: Beeguard2Stream,
    public runs: Beeguard2RunService,
    protected http: HttpClient,
    private _translate: TranslateService,
    private oAuthService: BeeguardAuthService
  ) {
    super(http, null, null);
    runs.setBg2Api(this);

    this._bg2Stream_sub = this.bg2Stream.reconnect$.subscribe(() => {
      // If socketio is disconnect we need to update entities state when it reconnect;
      this.resetCachedEntities();
    });

    this._impersonate_id_sub = this.oAuthService.authentication_data$$.subscribe({
      next: ({ access_token, impersonate }) => {
        this.defaultHeaders = this.defaultHeaders.set('Content-Type', 'application/json');
        // this.defaultHeaders = this.defaultHeaders.set('Authorization', `Bearer ${access_token}`);

        if (impersonate) {
          this.defaultHeaders = this.defaultHeaders.set('ImpersonateId', impersonate.user_id.toString());
          this.defaultHeaders = this.defaultHeaders.set('ImpersonateScope', impersonate.scopes.join(' '));
        } else {
          this.defaultHeaders = this.defaultHeaders.delete('ImpersonateId');
          this.defaultHeaders = this.defaultHeaders.delete('ImpersonateScope');
        }

        this._headers_token_is_set$.next(true);
      },
    });
  }

  /** */
  ngOnDestroy(): void {
    this._bg2Stream_sub?.unsubscribe();
    this._impersonate_id_sub?.unsubscribe();
  }

  // #endregion

  // #region -> (misc methods)

  /** */
  public resetCachedEntities(): void {
    // TODO: It is also needed to reload all entities (that are "in use")
    this.entity_cache.razCache();
  }

  /** */
  public razEntitiesCache(): void {
    this.entity_cache.razCache();
  }

  /** */
  public handleUnauthorizedError(err: any): void {
    this.oAuthService.handleUnauthorizedError(err);
  }

  // #endregion

  // #region -> (entities management)

  // #region -> (create methods)

  /** */
  public create_entity$(body: EntityInterface) {
    return this.wait_headers_token_is_set$$.pipe(
      take(1),
      switchMap(() => super.createEntity(body))
    );
  }

  // #endregion

  // #region -> (read methods)

  /** */
  public fetch_entity__raw$(id: number, history?: string[], history_from?: Date, state_before?: Date, with_states?: boolean) {
    return this.wait_headers_token_is_set$$.pipe(
      take(1),
      switchMap(() => super.getEntity(id, history, history_from, state_before, with_states))
    );
  }

  /** */
  public fetch_entity_state$(id: number, date?: Date, before_event_id?: number) {
    return this.wait_headers_token_is_set$$.pipe(
      take(1),
      switchMap(() => super.getEntityState(id, date, before_event_id))
    );
  }

  /** */
  public fetch_entities_states$(id: number[], date?: Date, before_event_id?: number) {
    return this.wait_headers_token_is_set$$.pipe(
      take(1),
      switchMap(() => super.getEntitiesStates(id, date, before_event_id))
    );
  }

  /** */
  public fetch_entity_acl$(id: number) {
    return this.wait_headers_token_is_set$$.pipe(
      take(1),
      switchMap(() => super.getEntityACL(id))
    );
  }

  /** */
  public fetch_entity_timeseries$(id: number, measurements?: string[], start?: Date, end?: Date, step?: string) {
    return this.wait_headers_token_is_set$$.pipe(
      take(1),
      switchMap(() => super.getEntityTimeseries(id, measurements, start, end, step))
    );
  }

  /** */
  public fetch_entity_schema$(type: string) {
    return this.wait_headers_token_is_set$$.pipe(
      take(1),
      switchMap(() => super.getEntitySchema(type))
    );
  }

  // #endregion

  // #region -> (update methods)

  /** */
  public update_entity$(id: number, body: EntityInterface) {
    return this.wait_headers_token_is_set$$.pipe(
      take(1),
      switchMap(() => super.updateEntity(body, id))
    );
  }

  /** */
  public update_entity_acl$(body: AclAndOwner, id: number) {
    return this.wait_headers_token_is_set$$.pipe(
      take(1),
      switchMap(() => super.updateEntityACL(body, id))
    );
  }

  // #endregion

  // #region -> (delete methods)

  // #endregion

  // #endregion

  // #region -> (events management)

  // #region -> (create methods)

  /** */
  public create_event$(body: EventInterface) {
    return this.wait_headers_token_is_set$$.pipe(
      take(1),
      switchMap(() => super.createEvent(body))
    );
  }

  // #endregion

  // #region -> (read methods)

  /** */
  public fetch_event$(id: number, include?: string, run_info?: boolean) {
    return this.wait_headers_token_is_set$$.pipe(
      take(1),
      switchMap(() => super.getEvent(id, include, run_info))
    );
  }

  // #endregion

  // #region -> (update methods)

  /** */
  public update_event$(event_id: number, body: UpdateEventParams) {
    return this.wait_headers_token_is_set$$.pipe(
      take(1),
      switchMap(() => super.updateEvent(body, event_id))
    );
  }

  // #endregion

  // #region -> (delete methods)

  /** */
  public delete_event$(id: number) {
    return this.wait_headers_token_is_set$$.pipe(
      take(1),
      switchMap(() => super.deleteEvent(id))
    );
  }

  // #endregion

  // #endregion

  // #region -> (event schemas management)

  /** */
  public getEventSchema(event_type: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
    if (this.event_schema_cache.has(event_type)) {
      return of(this.event_schema_cache.get(event_type));
    }

    // Not all know
    return this.wait_headers_token_is_set$$.pipe(
      take(1),
      switchMap(() => super.getEventSchema(event_type, observe, reportProgress)),
      tap((ret: any) => this.event_schema_cache.add(event_type, ret))
    );
  }

  // #endregion

  // #region -> (events management)

  /** */
  public getEventObj(event_id: any): Observable<Event> {
    if (event_id < 0) {
      return of(this._ghost_events.find(event => event.id === event_id));
    }

    return this.wait_headers_token_is_set$$.pipe(
      take(1),
      switchMap(() => this.fetch_event$(event_id)),
      map((event_dict: EventInterface) => this.eventDeserialize(event_dict))
    );
  }

  /** */
  public getEventsObj(
    entity_ids: number[],
    pagination: PagingQuery,
    event_types?: string[],
    range?: [Date, Date]
  ): Observable<EventPaging> {
    let local_events: Event[];
    let local_eids: number[], eids: number[];

    if (isNil(entity_ids) || isEmpty(entity_ids)) {
      return of({ paging: { limit: pagination.limit, offset: pagination.offset, total: 0 }, events: [] });
    } else {
      eids = entity_ids.filter(eid => eid >= 0);
      local_eids = entity_ids.filter(eid => eid < 0);
    }

    if (local_eids?.length > 0) {
      local_events = this._ghost_events.filter(event => {
        const is_event_in_local_eids = local_eids.includes(event.id);

        let final_condition = is_event_in_local_eids;

        if (!isEmpty(event_types ?? [])) {
          final_condition = final_condition && (event_types ?? []).includes(event.type);
        }

        return final_condition;
      });
    }

    let query$$: Observable<GetEntityEventsResponse> = of({
      events: [],
      paging: {
        total: 0,
        limit: pagination.limit || 10,
        offset: 0,
      },
    });

    if (eids?.length > 0) {
      query$$ = this.wait_headers_token_is_set$$.pipe(
        take(1),
        switchMap(() =>
          this.getEvents(
            eids,
            event_types || undefined,
            isNil(range?.[0]) ? undefined : parseDate(range?.[0]),
            isNil(range?.[1]) ? undefined : parseDate(range?.[1]),
            pagination.offset,
            pagination.limit
          )
        )
      );
    }
    return query$$.pipe(
      map(
        (result: GetEntityEventsResponse) =>
        ({
          paging: result.paging,
          events: isEmpty(result.events) ? [] : result.events.map(event => this.eventDeserialize(event)),
        } as EventPaging)
      ),
      map(result => {
        if (local_events?.length > 0) {
          result.events = concat(local_events, result.events);
        }
        return result;
      })
    );
  }

  /** */
  public getEntityEventsObj(entity_id: number, config: EventRequestConfig): Observable<EventPaging> {
    // Put default value
    if (entity_id < 0) {
      // Ghost entity
      return of({
        events: [],
        paging: {
          total: 0,
          limit: config.limit || 10,
          offset: 0,
        },
      });
    }
    const dconfig = assign(
      {
        limit: 10,
        offset: 0,
        run_info: true,
      },
      config
    );
    return this.wait_headers_token_is_set$$.pipe(
      take(1),
      switchMap(() =>
        this.getEntityEvents(
          entity_id,
          dconfig.types,
          dconfig.start,
          dconfig.end,
          dconfig.offset,
          dconfig.limit,
          dconfig.asc,
          dconfig.run_info
        )
      ),
      map((res: { events: EventInterface[]; paging: Paging }) => {
        const nres = {
          events: res.events.map(evt => this.eventDeserialize(evt)),
          paging: res.paging,
        };
        return nres;
      })
    );
  }

  /** */
  public eventDeserialize(event_dict: EventInterface): Event {
    return eventDeserialize(this, this.deviceApi, event_dict);
  }

  // #endregion

  // #region -> (entities management)

  private get_entity_obj_batch = new HttpBatch<OneOfEntity, { show_archived: boolean }>({
    logger: {
      name: 'HTTP_BATCH_GET_ENTITY',
      active: false,
    },
    batch_requests: requests_by_query_parameters => {
      const queries$$ = requests_by_query_parameters.map(request => {
        const object_ids = request.requests.map(req => req.object_ids);
        const flattened_object_ids = flatten(object_ids);
        const entity_ids = uniq(flattened_object_ids);

        //return super.getEntities(entity_ids, undefined, undefined, undefined, undefined, undefined, request?.params?.show_archived ?? false);
        return super.getEntities(entity_ids, undefined, undefined, undefined, undefined, request?.params?.show_archived ?? false);
      });

      const query_entities$$ = this.wait_headers_token_is_set$$.pipe(
        switchMap(() => forkJoin(queries$$)),
        map(responses => responses.map(r => this.entityDeserializeList(r.entities) as OneOfEntity[]))
      );

      const dispatch_entity_by_request$$ = query_entities$$.pipe(
        map(entities => {
          const dispatched_response: RequestInQueue<OneOfEntity, { show_archived: boolean }>[] = [];

          requests_by_query_parameters.forEach(
            (current: RequestsToBatchByQueryParameters<OneOfEntity, { show_archived: boolean }>, index: number) => {
              const responses_for_index = entities[index];

              current.requests.forEach(request => {
                dispatched_response.push({
                  ...request,
                  response: find(responses_for_index, entity => entity.id === request.object_ids[0]) ?? null,
                });
              });
            }
          );

          return dispatched_response;
        })
      );

      return dispatch_entity_by_request$$;
    },
  });

  /**
   * Fetches a specific entity object from it's ID.
   * @template T Type of the entity to fetch, default is {@link Entity}
   *
   * @param entity_id ID of the entity to fetch.
   * @param show_archived Should get archived entity.
   *
   * @returns Returns a one-shot observable on the fetched entity.
   */
  public getEntityObj<T extends OneOfEntity = any>(entity_id: number, show_archived = false): Observable<T> {
    if (isNil(entity_id) || isNaN(entity_id)) {
      return of(null);
    }

    if (this.entity_cache.has(entity_id)) {
      return of(this.entity_cache.get(entity_id) as T);
    }

    if (entity_id < 0) {
      // Ghost entity (not present in the cache)
      return race(
        of(null).pipe(delay(50000)),
        this.ghost_update$$.pipe(
          filter(() => this.entity_cache.has(entity_id)),
          map(() => this.entity_cache.get(entity_id) as T)
        )
      ).pipe(
        map(val => {
          if (!val) {
            throw new Error(`UnknowGhost entity #${entity_id}`);
          }
          return val;
        })
      );
    } else {
      return <Observable<T>>this.get_entity_obj_batch.add_and_wait_request(entity_id, { show_archived });
    }
  }

  /** */
  public getEntitiesObjByType(
    entity_type: string,
    id?: Array<number>,
    history?: Array<string>,
    history_from?: Date,
    with_states?: boolean,
    state_before?: Date,
    show_archived?: boolean,
    offset?: number,
    limit?: number
  ): Observable<Entity[]> {
    return this.wait_headers_token_is_set$$.pipe(
      take(1),
      switchMap(() =>
        this.getEntitiesByType(entity_type, id, history, history_from, with_states, state_before, show_archived, offset, limit)
      ),
      map((ret: any) => {
        // XXX TODO: should improve this when API get better
        const know_entities = this.entity_cache.getType(entity_type);
        const know_ids = new Set(know_entities.map(entity => entity.id));
        const new_raw_entities = ret.entities.filter((rent: any) => !know_ids.has(rent.id));
        const new_entities = this.entityDeserializeList(new_raw_entities, /*async_bind = */ with_states);
        return concat(know_entities, new_entities);
      })
    );
  }

  /** */
  public getEntitiesObj(entity_ids: Array<number>, type?: string, show_archived?: boolean): Observable<Entity[]> {
    // TODO; gerer les pointeurs pour pouvoir "détruire/déconnecter" des entity non utilisées
    // TODO: Regrouper les requettes dans un "pull" ?
    // (requette avec un delay de 100ms pour laisser le tps de faire un regroupement)
    //
    //  les clients: [(observable deja retourné,  les params), ...]
    //  la requette (future) <= merge de tout les params
    //   le timeout
    //   le nom/id du pull (type d'ident)
    if (isNil(entity_ids) || entity_ids.length === 0) {
      return of([]);
    }
    const know_entity_ids = entity_ids.filter(eid => this.entity_cache.has(eid));
    const missing_ghost_ids = entity_ids.filter(eid => !this.entity_cache.has(eid) && eid < 0);
    const new_entity_ids = entity_ids.filter(eid => !this.entity_cache.has(eid) && eid > 0);
    if (know_entity_ids.length === entity_ids.length) {
      const know_entities = know_entity_ids.map(eid => this.entity_cache.get(eid));
      return of(know_entities);
    }
    // Not all know
    let source: Observable<GetEntitiesResponse>;
    if (isNil(type) && new_entity_ids?.length) {
      //source = this.getEntities(new_entity_ids, undefined, undefined, undefined, undefined, undefined, show_archived);
      source = this.getEntities(new_entity_ids, undefined, undefined, undefined, undefined, show_archived);
    } else if (!isNil(type)) {
      //source = this.getEntitiesByType(type, new_entity_ids, undefined, undefined, undefined, undefined, undefined, show_archived);
      source = this.getEntitiesByType(type, new_entity_ids, undefined, undefined, undefined, undefined, show_archived);
    } else {
      source = of({
        type: '',
        entities: [],
      });
    }
    if (missing_ghost_ids.length > 0) {
      source = source.pipe(
        switchMap(res => combineLatest(missing_ghost_ids.map(ghost_id => this.getEntityObj(ghost_id))).pipe(map(() => res)))
      );
    }
    return this.wait_headers_token_is_set$$.pipe(
      take(1),
      switchMap(() => source),
      map(ret => {
        // note: desiriaze entities add it to local cache
        this.entityDeserializeList(ret.entities);
        // then get all entities from the cache
        const entities = entity_ids.filter(eid => this.entity_cache.has(eid)).map(eid => this.entity_cache.get(eid));
        return entities;
      })
    );
  }

  /** */
  public deleteEntity(id: number, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
    return this.wait_headers_token_is_set$$.pipe(
      take(1),
      switchMap(() => super.deleteEntity(id, observe, reportProgress)),
      tap(() => this.entity_cache.remove(id))
    );
  }

  /**
   * Build Entity object from api returned entity
   * Note: Also store in local cache the entity object
   *
   * @param entity_dict Input entity to transform
   */
  public deserialize_entity<T extends Entity>(entity_dict: EntityInterface, async_bind = true): T {
    const id = entity_dict.id;

    if (!this.entity_cache.has(id)) {
      const entity = entityDeserialize(this, this.deviceApi, entity_dict);

      if (async_bind) {
        entity.bind('API');
      }
      // ^ this is important => all entities in the cache are "binded"
      // ie. they are connected to socket-io stream

      this.entity_cache.add(id, entity);
    }

    return this.entity_cache.get(id) as T;
  }

  /**
   * Build Entity objects from api returned entity
   * Note: Also store in local cache the entities objects
   *
   * @param inputs List of original entity to transform to object
   */
  public entityDeserializeList(inputs: EntityInterface[], async_bind = true): Entity[] {
    const objects = inputs.map(entity => this.deserialize_entity(entity, async_bind));
    return objects;
  }

  // #endregion

  // #region -> (ghosts management)

  /**
   * Local events are store more recent one in first
   */
  private _ghost_events: Event[] = [];

  /** */
  private _ghost_events_lock = new RxSingletonLock({
    traceErr: console.error.bind(console),
    traceLog: console.log.bind(console),
  });

  /** */
  private _next_ghost_id = -1;

  /**
   * List events from more recent to oldest
   */
  private _ghost_event_next_id = -1;

  /** */
  public createGhostEntity(entity_dict: any): Entity {
    if (entity_dict.type !== 'location') {
      entity_dict.static_state.name = `${this._translate.instant(`ENTITY.ALL.TYPE.${entity_dict.type}`)} #${Math.abs(this._next_ghost_id)}`;
    }
    entity_dict.id = this._next_ghost_id--;
    this._ghost_update$$.next(this._next_ghost_id);
    // this._logger.debug(`Create ghost entity ${entity_dict.id} (${entity_dict.type})`)
    return this.deserialize_entity(entity_dict);
  }

  /** */
  public addGhostEvent(_event: Event): Observable<Event> {
    // this._logger.debug('request addGhostEvent', _event.type, _event.date, _event.id);
    return this._ghost_events_lock.sync(
      of(_event).pipe(
        switchMap(event => {
          event.id = this._ghost_event_next_id;
          event.resetLogger();
          this._ghost_event_next_id--;

          const idx = this._getNewGhostEventIdx(event);
          // this._logger.debug('addGhostEvent', idx, [event.type, event.date, event.id], this._ghost_events.map(_evt => [_evt.type, _evt.date, _evt.id]));
          this._ghost_events.splice(idx, 0, event);
          return this._applyGhostEventsFrom(idx);
        })
      )
    );
  }

  /** */
  public changeEntityIdInGhostEvents(prev_entity_id: number, new_entity_id: number) {
    this._ghost_events.forEach(event => {
      const role_by_id = event.apply_to_role_by_id;
      const role = role_by_id[prev_entity_id];
      if (role) {
        event.setOperand(role, new_entity_id);
      }
    });
  }

  /** */
  public deleteGhostEvent(_event: Event): Observable<Event> {
    // this._logger.debug('request deleteGhostEvent', _event.type, _event.date, _event.id);
    return this._ghost_events_lock.sync(
      of(_event).pipe(
        switchMap(event => {
          const idx = this._getExistingGhostEventIdx(event);
          // this._logger.debug('deleteGhostEvent', idx, [event.type, event.date, event.id], this._ghost_events.map(_evt => [_evt.type, _evt.date, _evt.id]));
          const [_del_event] = this._ghost_events.splice(idx, 1);
          // this._logger.debug(this._ghost_events.map(__event => __event.id));
          const delete_from = idx - 1;
          // apply
          return _del_event.unApplyLocally().pipe(
            tap(() => {
              event.id = null;
              event.resetLogger();
            }),
            switchMap(() => this._applyGhostEventsFrom(delete_from))
          );
        })
      )
    );
  }

  /** */
  public deleteGhostEntity(eid: number): boolean {
    const has_entity = this.entity_cache.has(eid);
    if (has_entity) {
      this.entity_cache.remove(eid);
    }
    return has_entity;
  }

  /** */
  private _getNewGhostEventIdx(event: Event): number {
    let idx = 0;
    for (; idx < this._ghost_events.length; idx++) {
      const _event = this._ghost_events[idx];
      if (!_event.is_older_than(event)) {
        break;
      }
    }
    return idx;
  }

  /** */
  private _getExistingGhostEventIdx(event: Event): number {
    let idx = 0;
    for (; idx < this._ghost_events.length; idx++) {
      const _event = this._ghost_events[idx];
      if (_event.id == event.id) {
        break;
      }
    }
    if (idx === this._ghost_events.length) {
      throw Error('Ghost event do not exist !');
    }
    return idx;
  }

  /** */
  private _applyGhostEventsFrom(idx: number): Observable<Event> {
    let apply_pipe$$: Observable<any> = of(null);
    for (let _idx = min([idx, this._ghost_events.length - 1]); _idx >= 0; _idx--) {
      const _event = this._ghost_events[_idx];
      if (isNil(_event)) {
        throw Error('Event missing');
      }
      apply_pipe$$ = apply_pipe$$.pipe(
        // tap(() => this._logger.ghost('re-Apply event', _idx, _event.id, _event.type)),
        switchMap(() => _event.applyLocally())
      );
    }
    return apply_pipe$$;
  }

  // #endregion
}
