import { Component, ChangeDetectionStrategy } from '@angular/core';

import { BeeguardAuthService } from 'app/core';

@Component({
  selector: 'menu-impersonate',
  templateUrl: './menu-impersonate.component.html',
  styleUrls: ['./menu-impersonate.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MenuImpersonateComponent {
  // #region -> (component basics)

  /** */
  constructor(public oAuthService: BeeguardAuthService) {}

}
