import { ChangeDetectionStrategy, Component } from '@angular/core';

import { marker as i18n } from '@biesbjerg/ngx-translate-extract-marker';

import { catchError, map, Observable, of, switchMap, take, tap } from 'rxjs';
import { keepSourceIfNoError, replay, switchTap, waitForNotNilValue } from '@bg2app/tools/rxjs';

import { isNil } from 'lodash-es';

import { DeviceApi } from 'app/core';
import { AppStateService } from 'app/core/app-state.service';
import { DialogsService } from 'app/widgets/dialogs-modals';

import { ErrorHelperData } from 'app/widgets/widgets-reusables/errors/error-helper/error-helper.component';
import { DeviceModalAbstractCategoryComponent } from '../device-modal-abstract-details-or-summary.component';
import { DevicesOtaDialogComponent, DevicesOrderDialogComponent } from 'app/views/devices/dialogs-and-modals/dialogs/';

import { SimpleSetterGetter } from 'app/models';
import { compute_868_state } from 'app/models/devices/_functions';
import { DeviceStatus, DeviceStatus868 } from 'app/models/devices/DRDevice';
import { DevicesDialogParams } from 'app/views/devices/dialogs-and-modals/devices-dialog-params';

import { parseDate } from 'app/misc/tools';

@Component({
  selector: 'bg2-device-modal-configuration',
  templateUrl: './device-modal-configuration.component.html',
  styleUrls: ['./device-modal-configuration.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DeviceModalConfigurationComponent extends DeviceModalAbstractCategoryComponent {
  // #region -> (component basics)

  constructor(
    protected readonly _deviceApi: DeviceApi,
    protected readonly _dialogs: DialogsService,
    protected readonly _appStateService: AppStateService
  ) {
    super(_appStateService, _dialogs, _deviceApi);
  }

  // #endregion

  // #region -> (loadings)

  /** */
  protected is_loading_sconf = new SimpleSetterGetter(true);

  // #endregion

  // #region -> (device basics)

  /**
   * Observes the device active simplified configuration.
   */
  public device_simplified_conf$$ = this.device$$.pipe(
    tap(() => (this.is_loading_sconf.value = true)),
    switchMap(device => {
      if (isNil(device)) {
        return of(null);
      }

      return device.sconf$$;
    }),
    tap(() => (this.is_loading_sconf.value = false)),
    replay()
  );

  /** */
  public device_acl_error$$: Observable<ErrorHelperData | null> = this.device$$.pipe(
    waitForNotNilValue(),

    // Check if the user can "read_devices"
    keepSourceIfNoError(device =>
      device.user_acl.throw__if_cannot$$('read_devices', i18n<string>('ALL.ACE.READ_DEVICES.WHAT.view the device'), 'exploitation')
    ),

    // Check if the user can "write_devices_configuration"
    keepSourceIfNoError(device_or_error => {
      if (device_or_error instanceof ErrorHelperData) {
        return of(device_or_error);
      }

      return device_or_error.user_acl.throw__if_cannot$$(
        'write_devices_configuration',
        i18n<string>('ALL.ACE.WRITE_DEVICES_CONFIGURATION.WHAT.view the configuration of the device'),
        'exploitation'
      );
    }),
    map(device_or_error => {
      if (device_or_error instanceof ErrorHelperData) {
        return device_or_error;
      }

      return null;
    })
  );

  // #endregion

  // #region -> (device next conf)

  /** */
  public device_next_conf_is_pending$$ = this.device_simplified_conf$$.pipe(
    map(sconf => sconf?.next?.pending ?? false),
    replay()
  );

  // #endregion

  /** */
  public compute_gateway_state(gateway: {
    imei: number;
    name: string;
    is_last: boolean;
    read_ace: boolean;
    rssi_last: number;
    timestamp_last: string;
  }): DeviceStatus868 | null {
    if (isNil(gateway?.rssi_last)) {
      return null;
    }

    const current_status: DeviceStatus = { outdated: false, value: gateway.rssi_last, timestamp: parseDate(gateway.timestamp_last) };
    const computed_state = compute_868_state({ outdated: false, value: gateway.rssi_last, timestamp: parseDate(gateway.timestamp_last) });

    return { ...current_status, state: computed_state } as DeviceStatus868;
  }

  /** */
  public compute_sensor_state(sensor: {
    imei: number;
    name: string;
    is_last: boolean;
    read_ace: boolean;
    rssi_last: number;
    timestamp_last: string;
  }): DeviceStatus868 | null {
    if (isNil(sensor?.rssi_last)) {
      return null;
    }

    const current_status: DeviceStatus = { outdated: false, value: sensor.rssi_last, timestamp: parseDate(sensor.timestamp_last) };
    const computed_state = compute_868_state({ outdated: false, value: sensor.rssi_last, timestamp: parseDate(sensor.timestamp_last) });

    return { ...current_status, state: computed_state } as DeviceStatus868;
  }

  /** */
  public program_ota() {
    this.device$$
      .pipe(
        waitForNotNilValue(),
        take(1),
        switchMap(device =>
          this._dialogs.open<DevicesDialogParams, any>(DevicesOtaDialogComponent, {
            devices: [device],
          })
        )
      )
      .subscribe();
  }

  /** */
  public send_order() {
    this.device$$
      .pipe(
        waitForNotNilValue(),
        take(1),
        switchMap(device =>
          this._dialogs.open<DevicesDialogParams, any>(DevicesOrderDialogComponent, {
            devices: [device],
          })
        )
      )
      .subscribe();
  }
}
