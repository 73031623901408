import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { get, isNil } from 'lodash-es';

import { BehaviorSubject, Observable, combineLatest, map, of, switchMap, take } from 'rxjs';
import { replay } from 'app/misc/tools/rxjs';

import { DialogsService } from 'app/widgets/dialogs-modals';
import { AppStateService } from 'app/core/app-state.service';
import { AppBackofficeService } from 'app/core/services/global/app-backoffice.service';

import { AppBackofficeConfig } from 'app/models';

@Component({
  selector: 'bg2-top-layer-announcement',
  templateUrl: './top-layer-announcement.component.html',
  styleUrls: ['./top-layer-announcement.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TopLayerAnnouncementComponent {
  // #region - component basics

  constructor(
    public readonly appBackofficeService: AppBackofficeService,
    private readonly _appState: AppStateService,
    private readonly _dialogsService: DialogsService
  ) {}

  // #endregion

  // #region - top layer announcement component config

  /** */
  @Input()
  public mode: 'live' | 'preview' = 'live';

  // #endregion

  /** */
  private _top_layer_announcement_config = new BehaviorSubject<AppBackofficeConfig['top_layer_announcement']>(null);

  /** */
  public top_layer_announcement_config$$ = this._top_layer_announcement_config.asObservable();

  /** */
  @Input() public set top_layer_announcement_config(config: AppBackofficeConfig['top_layer_announcement']) {
    this._top_layer_announcement_config.next(config);
  }

  /** */
  public get top_layer_announcement_config(): AppBackofficeConfig['top_layer_announcement'] {
    return this._top_layer_announcement_config.getValue();
  }

  /** */
  public top_layer_announcement_message$$: Observable<string> = combineLatest({
    language: this._appState.lang$$,
    config: this.top_layer_announcement_config$$,
  }).pipe(
    map(({ language, config }) => {
      if (!config.enabled || isNil(language)) {
        return null;
      }

      return get(config, `message.${language}`) ?? config?.message['en'];
    }),
    replay()
  );

  /** */
  public find_out_more_top_layer_announcement() {
    this.top_layer_announcement_config$$
      .pipe(
        take(1),
        switchMap(config => {
          console.log(config);

          if (!config?.read_more?.enabled) {
            return of(false);
          }

          return this._dialogsService.customizable({
            header: (config?.read_more?.dialog_config?.title as any)[this._appState.lang],
            body: {
              type: 'div',
              styles: {
                'text-align': 'left',
              },
              elements: {
                type: 'textarea',
                content: (config?.read_more?.dialog_config?.body?.content as any)[this._appState.lang],
              },
            },
            footer: {
              styles: { 'flex-direction': 'column' },
              buttons: {
                styles: { margin: '8px 0px 0px 0px' },
                items: [
                  {
                    type: 'button',
                    color: config?.read_more?.dialog_config?.footer?.button?.color,
                    content: (config?.read_more?.dialog_config?.footer?.button?.message as any)[this._appState.lang],
                    result: true,
                  },
                ],
              },
            },
          });
        })
      )
      .subscribe();
  }
}
