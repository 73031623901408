/** */
export enum ZohoDeskTicketSupportType {
  /** */
  INCONNU = 'Inconnu',

  /** */
  ASK_QUOTE = 'Demande devis',

  /** */
  RENTAL_RETURN = 'Retour location',

  /** */
  ORDER_BATTERIES = 'Commande piles',

  /** */
  PROBLEM_WITH_APP = 'Pb application',

  /** */
  PROBLEM_WITH_DEVICES = 'Pb appareils',

  /** */
  REPLACEMENT_OR_LOAN_RETURN = 'Retour remplacement/prêt',
}
