import { BehaviorSubject, catchError, map, Observable, of } from 'rxjs';
import { distinctUntilRealChanged, replay, waitForNotNilValue } from '@bg2app/tools/rxjs';

import { marker as i18n } from '@biesbjerg/ngx-translate-extract-marker';

import { difference, some } from 'lodash-es';

import { ConsoleLoggerService } from 'app/core/console-logger.service';

import { DeviceUserAce, device_user_ace_to_i18n } from '../enumerators/device-user-ace.enum';
import { ErrorHelperData } from 'app/widgets/widgets-reusables/errors/error-helper/error-helper.component';

export class DeviceUserAclManager {
  // #region -> (class basic)

  protected readonly LOGGER = new ConsoleLoggerService('DeviceUserAclManager', false);

  // #endregion

  // #region -> (ace list management)

  /** */
  private _acl$$: BehaviorSubject<(keyof typeof DeviceUserAce)[]> = new BehaviorSubject<(keyof typeof DeviceUserAce)[]>([]);

  /** */
  public acl$$: Observable<(keyof typeof DeviceUserAce)[]> = this._acl$$.asObservable().pipe(waitForNotNilValue());

  /** */
  public missing_acl$$ = this.acl$$.pipe(
    map(actual_acl => {
      const missing = difference(new Array(...device_user_ace_to_i18n.keys()), actual_acl);
      return missing;
    }),
    replay()
  );

  /** */
  public update_acl(acl: (keyof typeof DeviceUserAce)[], device_name: string) {
    if (device_name) {
      this.LOGGER.update_prefix(`DeviceUserAclManager|${device_name}`);
    }

    this.LOGGER.debug(acl ?? []);
    this._acl$$.next(acl ?? []);
  }

  // #endregion

  // #region -> (generic check methods)

  /** */
  public can$$ = (ace: keyof typeof DeviceUserAce) =>
    this.acl$$.pipe(
      map(acl => acl.includes(ace)),
      distinctUntilRealChanged()
    );

  /** */
  public cannot$$ = (ace: keyof typeof DeviceUserAce) =>
    this.can$$(ace).pipe(
      map(can_ace => !can_ace),
      distinctUntilRealChanged()
    );

  /** */
  public cannot_or$$ = (...aces: (keyof typeof DeviceUserAce)[]) =>
    this.acl$$.pipe(
      map(acl => aces.map(ace => !acl.includes(ace))),
      map(are_aces_in_acl => some(are_aces_in_acl)),
      distinctUntilRealChanged()
    );

  /** */
  public only$$ = (ace: keyof typeof DeviceUserAce) =>
    this.acl$$.pipe(
      map(acl => acl.length === 1 && acl.includes(ace)),
      distinctUntilRealChanged()
    );

  // #endregion

  // #region -> (error-scoped ACE check)

  /**
   * Check if the current user cannot do specific ace.
   *
   * @returns An observable with the error model else null.
   *
   * @public
   * @observable
   */
  public throw__if_cannot$$ = (
    ace: keyof typeof DeviceUserAce,
    context: string,
    source: 'exploitation' | 'location' = 'location'
  ): Observable<ErrorHelperData | null> =>
    this.can$$(ace).pipe(
      map(can_read_devices => {
        let who_to_ask = i18n<string>('ALL.ACE.COMMON.Ask **the location\'s manager** to grant you the following permission: "**[ace]**"');

        if (source === 'exploitation') {
          who_to_ask = i18n<string>('ALL.ACE.COMMON.Ask **the exploitation\'s owner** to grant you the following permission: "**[ace]**"');
        }

        if (!can_read_devices) {
          throw new ErrorHelperData([
            {
              type: 'image_svg',
              url: '/assets/img/pictos/lock.svg',
              styles: {
                width: '40px',
              },
            },
            {
              type: 'ace',
              content: i18n<string>("ALL.ACE.COMMON.You can't **[what]**"),
              translateParams: {
                what: context,
              },
            },
            {
              type: 'ace',
              content: who_to_ask,
              translateParams: {
                ace: device_user_ace_to_i18n.get(ace),
              },
            },
          ]);
        }

        return null;
      }),
      catchError((error: unknown) => of(error))
    );

  // #endregion
}
