import { ChangeDetectionStrategy, Component, EventEmitter, Inject, Input, Output } from '@angular/core';
import { FormControl, FormGroup, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';

import { isEqual, values } from 'lodash-es';

import { marker as i18n } from '@biesbjerg/ngx-translate-extract-marker';

import { allTrue, anyTrue, catchErrorInDialog, replay, waitForNotNilProperties, waitForNotNilValue } from '@bg2app/tools/rxjs';
import { BehaviorSubject, catchError, map, of, switchMap, take, combineLatest, Observable, withLatestFrom } from 'rxjs';

import { AppStateService } from 'app/core/app-state.service';
import { ConsoleLoggerService } from 'app/core/console-logger.service';

import { User, UserScope } from 'app/models';
import { timezone } from 'app/models/misc/timezones';
import { SameValueValidator } from 'app/misc/tools/misc/form-validators';
import { UserTag, user_tag_to_i18n } from 'app/models/user/enumerators/user-tag.enum';
import { InputUpdateUser, User as SwaggerUserInterface } from 'app/core/api-swagger/user-v2';
import { DialogsService } from 'app/widgets/dialogs-modals';
import { UsersApiService } from 'app/core/api/user/users-api.service';
import { Dictionary } from 'app/typings/core/interfaces';
import { ISchema } from 'ngx-schema-form';
import { ZohoSearchSelectOptions } from 'app/widgets/event-form/zoho-search-crm/zoho-search-crm-widget.component';
import { IEnvironment } from 'environments/common';
import { ENV } from 'app/core/providers/environment.provider';

class Klass {
  // #region -> (class basics)

  /** */
  public form: UntypedFormGroup = null;

  /** */
  public initial_value$$: Observable<any> = null;

  /** */
  constructor(config: { form: UntypedFormGroup; initial_value$$: Observable<any> }) {
    this.form = config?.form;
    this.initial_value$$ = config?.initial_value$$;
  }

  // #endregion

  /** */
  private _is_editing$$ = new BehaviorSubject<boolean>(false);

  /** */
  public is_editing$$ = this._is_editing$$.asObservable();

  /** */
  public cancel(): void {
    this.form.reset();
    this._is_editing$$.next(false);
  }

  /** */
  public edit(): void {
    this.initial_value$$.pipe(take(1)).subscribe({
      next: initial_data => {
        this.form.patchValue(initial_data);
        this._is_editing$$.next(true);
      },
    });
  }

  /** */
  public saved(): void {
    this._is_editing$$.next(false);
  }
}

@Component({
  selector: 'bg2-uam-user-details',
  templateUrl: 'uam-user-details.component.html',
  styleUrls: ['uam-user-details.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UAMUserDetailsComponent {
  // #region -> (component basics)

  /** */
  private readonly LOGGER = new ConsoleLoggerService('UAMUserDetailsComponent', false);

  /** */
  constructor(
    @Inject(ENV) public env: IEnvironment,

    private readonly _dialogs: DialogsService,
    private readonly _appState: AppStateService,
    private readonly _users_api: UsersApiService
  ) {}

  // #endregion

  @Output()
  public close_modal = new EventEmitter();

  // #region -> (user management)

  /** */
  @Input()
  public set user(user: User) {
    this._modal_user$$.next(user);
  }

  /** */
  private _modal_user$$ = new BehaviorSubject<User>(null);

  /** */
  public modal_user$$ = this._modal_user$$.asObservable().pipe(waitForNotNilValue(), replay());

  /** */
  private _modal_user__id$$ = this.modal_user$$.pipe(switchMap(user => user.user_id$$));

  /** */
  private _modal_user__referent_id$$ = this.modal_user$$.pipe(
    switchMap(user => user.referent$$),
    map(referent => referent?.user_id),
    replay()
  );

  // #region - viewer user (auth.)

  /**
   * The viewer user (the user who is currently logged in).
   *
   * @public
   */
  public viewer_user$$ = this._appState.user$$.pipe(waitForNotNilValue(), replay());

  /** */
  private _viewer_user__id$$ = this.viewer_user$$.pipe(switchMap(user => user.user_id$$));

  /**
   * True if the viewer user is a superadmin.
   *
   * @public
   */

  public is_viewer_superadmin$$ = this.viewer_user$$.pipe(
    waitForNotNilValue(),
    switchMap(user => user.is_superadmin$$)
  );

  /**
   * True if the viewer user is the referent of the modal user.
   *
   * @public
   */
  private _is_viewer_referent_of_modal_user$$ = combineLatest({
    modal_user__referent_id: this._modal_user__referent_id$$,
    viewer_user__id: this._viewer_user__id$$,
  }).pipe(
    map(({ viewer_user__id, modal_user__referent_id }) => modal_user__referent_id === viewer_user__id),
    replay()
  );

  /**
   * True if the viewer user is the modal user.
   *
   * @public
   */
  private _is_viewer_same_as_modal_user$$ = combineLatest({
    modal_user_id: this._modal_user__id$$,
    viewer_user_id: this._viewer_user__id$$,
  }).pipe(
    map(({ modal_user_id, viewer_user_id }) => modal_user_id === viewer_user_id),
    replay()
  );

  // #endregion

  // #region -> (profile form data)

  /** */
  public profile_form_data$$ = this.modal_user$$.pipe(
    switchMap(user =>
      combineLatest({
        first_name: user.first_name$$,
        last_name: user.last_name$$,
        email: user.email$$,
        timezone: user.timezone$$,
        referent_id: user.referent$$.pipe(map(referent => referent?.user_id)),
        phone_number: user.phone_number$$,
      })
    )
  );

  // #endregion

  // #region - read profile

  /**
   * Check if the profile is readable.
   * The profile is readable if:
   * - the viewer user is a superadmin
   * - the viewer user is the referent of the modal user
   * - the viewer user is the modal user (must have ACL read rights)
   *
   * @public
   */
  public is_profile_readable$$ = anyTrue(
    this.is_viewer_superadmin$$,
    this._is_viewer_referent_of_modal_user$$,
    this._is_viewer_same_as_modal_user$$.pipe(
      switchMap(is_viewer_same_as_modal_user =>
        this.viewer_user$$.pipe(
          switchMap(viewer => {
            if (!is_viewer_same_as_modal_user) {
              return of(false);
            }

            return viewer.acl__can_read$$;
          })
        )
      )
    )
  );

  // #endregion

  // #region -> (profile edition management)

  /** True if show/edit profile of authentified user */
  public is_auth_user$$ = combineLatest({
    modal_user: this.modal_user$$,
    authenticated_user: this._appState.user$$,
  }).pipe(
    waitForNotNilProperties(),
    map(({ modal_user, authenticated_user }) => isEqual(modal_user.user_id, authenticated_user.user_id)),
    replay()
  );

  /**
   * Check if the refeent field can be edited.
   * The refent field can be edited if:
   * - the viewer user is a superadmin
   * - the viewer user is the referent of the modal user
   */
  public is_referent_editable$$ = anyTrue(this.is_viewer_superadmin$$, this._is_viewer_referent_of_modal_user$$);

  /**
   * Check if the profile is editable.
   * The profile is editable if:
   * - the viewer user is a superadmin
   * - the viewer user is the referent of the modal user
   * - the viewer user is the modal user (must not be a public account, and can write)
   *
   * @public
   */
  public is_profile_editable$$ = anyTrue(
    this.is_viewer_superadmin$$,
    this._is_viewer_referent_of_modal_user$$,
    this._is_viewer_same_as_modal_user$$.pipe(
      switchMap(is_viewer_same_as_modal_user =>
        this.viewer_user$$.pipe(
          switchMap(viewer => {
            if (!is_viewer_same_as_modal_user) {
              return of(false);
            }

            return allTrue(viewer.acl__can_write$$, viewer.is_not_public_account$$);
          })
        )
      )
    )
  );

  /** */
  private _is_editing_profile$$ = new BehaviorSubject(false);

  /** */
  public is_editing_profile$$ = this._is_editing_profile$$.asObservable();

  /** */
  public timezones$$ = of(timezone);

  /** */
  public assert_user(model: any): User {
    return model instanceof User ? model : null;
  }

  /** */
  public profile_form = new UntypedFormGroup({
    first_name: new UntypedFormControl('', []),
    last_name: new UntypedFormControl('', []),
    email: new UntypedFormControl('', [Validators.required]),
    timezone: new UntypedFormControl('', [Validators.required]),
    phone_number: new UntypedFormControl('', []),
    referent_id: new UntypedFormControl(null, []),
  });

  /** */
  public edit_user_profile(): void {
    this.profile_form_data$$.pipe(take(1)).subscribe({
      next: user_profile_data => {
        this.profile_form.patchValue(user_profile_data);
        this._is_editing_profile$$.next(true);
      },
    });
  }

  /** */
  public delete_user_profile(): void {
    this._dialogs
      .confirm(i18n<string>('MODELS.USER.ACTIONS.DELETE.Are you sure to want to delete this user ?'), {
        onTrueMessage: 'ALL.COMMON.Yes',
        onFalseMessage: 'ALL.COMMON.No',
      })
      .pipe(
        switchMap((resultant: boolean) => {
          if (resultant) {
            return this.modal_user$$.pipe(
              waitForNotNilValue(),
              take(1),
              switchMap(user => this._users_api.delete_user$(user.user_id)),
              map(user => true)
            );
          }

          return of(false);
        })
      )
      .subscribe({
        next: is_deleted => {
          if (is_deleted) {
            this.close_modal.emit();
          }
        },
      });
  }

  /** */
  public cancel_user_profile(): void {
    this.profile_form.reset();
    this._is_editing_profile$$.next(false);
  }

  /** */
  public save_user_profile(): void {
    const user_profile_value = this.profile_form.value;

    if (user_profile_value.referent_id) {
      user_profile_value.referent = { user_id: user_profile_value.referent_id };
    } else {
      user_profile_value.referent = { user_id: null };
    }

    delete user_profile_value.referent_id;

    this.modal_user$$
      .pipe(
        withLatestFrom(this.is_referent_editable$$.pipe(take(1))),
        switchMap(([user, can_edit_referent]) => {
          let properties_to_save: (keyof InputUpdateUser)[] = ['first_name', 'last_name', 'email', 'timezone', 'phone_number', 'referent'];

          if (!can_edit_referent) {
            delete user_profile_value.referent;
            properties_to_save = properties_to_save.filter(property => property !== 'referent');
          }

          const update_user = user.deserialize(user_profile_value);
          return update_user.save(properties_to_save);
        }),
        take(1),
        catchErrorInDialog(this._dialogs)
      )
      .subscribe({
        next: () => {},
        complete: () => this._is_editing_profile$$.next(false),
      });
  }

  // #endregion

  // #region -> (security management)

  /** */
  public user_tags_list = values(UserTag).map(user_tag => ({
    value: user_tag,
    label: user_tag_to_i18n[user_tag],
  }));

  /**
   * Check if the disabled account field can be edited.
   * The disabled account field can be edited if:
   * - the viewer user is a superadmin
   * - the viewer user is the referent of the modal user
   */
  public is_disabled_account_editable$$ = anyTrue(this.is_viewer_superadmin$$);

  /** */
  public get_i18n_for_tag(tag: string): string {
    return (user_tag_to_i18n as any)[tag];
  }

  /** */
  public security_manager = new Klass({
    form: new FormGroup({
      tags: new FormControl(),
      disabled: new FormControl(),
      can_create_users: new FormControl(),
      is_public_account: new FormControl(),
      is_administrator_account: new FormControl(),
    }),

    initial_value$$: this.modal_user$$.pipe(
      waitForNotNilValue(),
      switchMap(user =>
        combineLatest({
          disabled: user.disabled$$,
          is_public_account: user.is_public_account$$,
          can_create_users: user.can_create_subusers$$,
          is_administrator_account: user.is_superadmin$$,
          tags: user.tags$$.pipe(map(tags => (tags ?? []).map(tag => tag.name))),
        })
      )
    ),
  });

  /** */
  public save_security_data(): void {
    const form_value = this.security_manager.form.value;
    const scopes_to_save: UserScope[] = [];

    if (form_value.is_administrator_account) {
      scopes_to_save.push(UserScope.READ_ALL, UserScope.WRITE_ALL, UserScope.SUPERADMIN);
    } else {
      if (form_value.can_create_users) {
        scopes_to_save.push(UserScope.CREATE_SUB_USERS);
      }

      if (!form_value.is_public_account) {
        scopes_to_save.push(UserScope.WRITE_SELF);
      }
    }

    combineLatest({
      user: this.modal_user$$,
      is_superadmin: this.is_viewer_superadmin$$,
    })
      .pipe(
        switchMap(({ user, is_superadmin }) => {
          const fields: (keyof InputUpdateUser)[] = ['scopes'];
          const data: SwaggerUserInterface = {
            scopes: scopes_to_save,
          };

          if (is_superadmin) {
            data.disabled = form_value?.disabled;
            fields.push('disabled');
            data.tags = form_value?.tags?.map((tag: string) => ({ name: tag }));
            fields.push('tags');
          }

          return user.deserialize(data).save(fields);
        }),
        take(1),
        catchErrorInDialog(this._dialogs)
      )
      .subscribe({
        next: () => {},
        error: (error: unknown) => {},
        complete: () => this.security_manager.saved(),
      });
  }

  // #endregion

  // #region -> (scopes management)

  // user can write
  // viewer !== modal user

  /**
   * Check if the security can be edited.
   * The security can be edited if:
   * - the viewer user is a superadmin
   * - the viewer user is the referent of the modal user
   */
  public is_security_editable$$ = anyTrue(this.is_viewer_superadmin$$, this._is_viewer_referent_of_modal_user$$);

  // #endregion

  // #region -> (password management)

  /** */
  private _is_editing_password$$ = new BehaviorSubject(false);

  /** */
  public is_editing_password$$ = this._is_editing_password$$.asObservable();

  /**
   * Checks if the password can be edited.
   * The password can be edited if:
   * - the viewer user is a superadmin
   * - the viewer user is the referent of the modal user
   * - the viewer user is the modal user (must have ACL write rights)
   */
  public is_password_editable$$ = anyTrue(
    this.is_viewer_superadmin$$,
    this._is_viewer_referent_of_modal_user$$,
    this._is_viewer_same_as_modal_user$$.pipe(
      switchMap(is_viewer_same_as_modal_user =>
        this.viewer_user$$.pipe(
          switchMap(viewer => {
            if (!is_viewer_same_as_modal_user) {
              return of(false);
            }

            return viewer.acl__can_write$$;
          })
        )
      )
    )
  );

  /** */
  public password_form = new UntypedFormGroup(
    {
      email: new UntypedFormControl(null, [Validators.required]),
      current_password: new UntypedFormControl('', [Validators.required]),
      new_password: new UntypedFormControl('', [Validators.required]),
      confirm_password: new UntypedFormControl('', [Validators.required]),
    },
    {
      validators: [SameValueValidator('new_password', 'confirm_password')],
    }
  );

  /** */
  public edit_user_password(): void {
    this.modal_user$$
      .pipe(
        switchMap(user => user.email$$),
        withLatestFrom(this.is_viewer_superadmin$$),
        take(1)
      )
      .subscribe({
        next: ([email, is_superadmin]) => {
          const form_value: Dictionary<any> = { email };

          if (is_superadmin) {
            form_value['current_password'] = 'RESERVED_DEFAULT_SA';
          }

          this.password_form.patchValue(form_value);
          this._is_editing_password$$.next(true);
        },
      });
  }

  /** */
  public cancel_user_password(): void {
    this.password_form.reset();
    this._is_editing_password$$.next(false);
  }

  /** */
  public save_user_password(): void {
    const { current_password, confirm_password } = this.password_form.value;

    this.modal_user$$
      .pipe(
        switchMap(user => user.save_password(current_password === 'RESERVED_DEFAULT_SA' ? undefined : current_password, confirm_password)),
        take(1),
        catchErrorInDialog(this._dialogs)
      )
      .subscribe({
        complete: () => this._is_editing_password$$.next(false),
      });
  }

  /** */
  public show_password = {
    current: false,
    new: false,
    confirm: false,
  };

  // #endregion

  // #region -> (CRM management)

  /** */
  private _is_editing_crm$$ = new BehaviorSubject(false);

  /** */
  public is_editing_crm$$ = this._is_editing_crm$$.asObservable();

  /** */
  public crm_form_schema: ISchema = {
    type: 'object',
    properties: {
      CRM_id: {
        type: 'string',
        widget: 'zoho-search-crm',
        label: i18n('WIDGETS.EVENT_FORM.ZOHO_SEARCH.Zoho-Contact CRM ID'),
        options: <ZohoSearchSelectOptions>{
          visible_only_for: 'superadmin',
          zoho_search_config: {
            search_in: 'Contacts',
            criteria_template: [
              { prop: 'id', condition: 'equals', replace_value: 'SEARCH_TERM' },
              'or',
              [
                { prop: 'First_Name', condition: 'in', replace_value: 'SEARCH_TERM' },
                'or',
                { prop: 'First_Name', condition: 'starts_with', replace_value: 'SEARCH_TERM' },
              ],
              'or',
              [
                { prop: 'Last_Name', condition: 'in', replace_value: 'SEARCH_TERM' },
                'or',
                { prop: 'Last_Name', condition: 'starts_with', replace_value: 'SEARCH_TERM' },
              ],
            ],
          },
        },
      },
    },
  };

  /** */
  private _crm_form_model$$ = new BehaviorSubject<{ CRM_id: string }>(null);

  /** */
  public crm_form_model$$ = this._crm_form_model$$.asObservable();

  /** */
  public when_crm_form_data_update(model: { CRM_id: string }): void {
    this._crm_form_model$$.next(model);
  }

  /** */
  private _crm_form_valid$$ = new BehaviorSubject<boolean>(false);

  /** */
  public crm_form_valid$$ = this._crm_form_valid$$.asObservable();

  /** */
  public set is_crm_form_valid(is_crm_form_valid: boolean) {
    this._crm_form_valid$$.next(is_crm_form_valid);
  }

  /** */
  public edit_crm_profile(): void {
    this.modal_user$$
      .pipe(
        switchMap(user => user.CRM_id$$),
        take(1)
      )
      .subscribe({
        next: CRM_id => {
          this._crm_form_model$$.next({ CRM_id });
          this._is_editing_crm$$.next(true);
        },
      });
  }

  /** */
  public cancel_crm_profile(): void {
    this._crm_form_model$$.next(null);
    this._is_editing_crm$$.next(false);
  }

  /** */
  public save_crm_profile(): void {
    const user_crm_value = <{ CRM_id: string }>this._crm_form_model$$.getValue();

    this.modal_user$$
      .pipe(
        switchMap(user => {
          user.CRM_id = user_crm_value?.CRM_id?.toString() ?? null;
          return user.save(['CRM_id']);
        }),
        take(1),
        catchErrorInDialog(this._dialogs)
      )
      .subscribe({
        next: () => this._is_editing_crm$$.next(false),
      });
  }

  // #endregion
}
