<bg2-modal [fullscreen]="fullscreen || false" #modal>
  <bg2-modal-header>
    <div class="header">
      <span class="mdi mdi-map-marker-path mdi-36px"></span>
      <span class="title" *ngIf="device$$ | async as device;">
        {{ 'VIEWS_WINDOWED.MODALS.ROUTE_TRACER.Route [device]' | translate:{ device: (device.name) } }}
      </span>
    </div>
    <div class="action-buttons">
      <ng-container *ngIf="windowRef.innerWidth > 1200">
        <span class="mdi mdi-fullscreen-exit mdi-36px" *ngIf="fullscreen" (click)="changeFullscreen(false)"></span>
        <span class="mdi mdi-fullscreen mdi-36px" *ngIf="!fullscreen" (click)="changeFullscreen(true)"></span>
      </ng-container>
      <span class="mdi mdi-close-box mdi-36px" (click)="close()"></span>
    </div>
  </bg2-modal-header>

  <bg2-modal-content class="mode-{{view_mode$$ | async}}">
    <div class="router-modal__content">
      <div class="router-modal__content__date-and-view">
        <sf-form [schema]="schema" (onChange)="onFormModelChanged($event)"></sf-form>

        <div class="view-switch-map-tbl">
          <mat-button-toggle-group (change)="view_mode = $event.value" [value]="view_mode$$ | async">
            <mat-button-toggle value="map">
              <i class="mdi mdi-map mdi-18px"></i>
              <span class="hide-on-small-and-down">{{ 'ALL.MENU.Map' | translate}}</span>
            </mat-button-toggle>
            <mat-button-toggle value="tbl">
              <i class="mdi mdi-table-eye mdi-18px"></i>
              <span class="hide-on-small-and-down">{{ 'VIEWS.LOCATION.Table' | translate}}</span>
            </mat-button-toggle>
          </mat-button-toggle-group>
        </div>
      </div>

      <div class="router-modal__content__route-select">
        <div>
          <mat-form-field appearance="outline">
            <mat-label>{{ 'VIEWS_WINDOWED.MODALS.ROUTE_TRACER.Select movements' | translate }}</mat-label>
            <mtx-select [appendTo]="null" [items]="available_movements" [(ngModel)]="selected_routes" [multiple]="true">
              <!-- #region .override-select-header -->
              <ng-template ng-header-tmp>
                <div>
                  <bg2-button
                    [type]="'cancel'"
                    [options]="{ is_thin: true }"
                    [message]="'ALL.ACTIONS.Select all' | translate"
                    (btn_click)="selectAll()"
                  ></bg2-button>

                  <bg2-button
                    [type]="'cancel'"
                    [options]="{ is_thin: true }"
                    [message]="'ALL.ACTIONS.Unselect all' | translate"
                    (btn_click)="unselectAll()"
                  ></bg2-button>
                </div>
              </ng-template>
              <!-- #endregion -->

              <!-- #region .override-multi-option-template -->
              <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                <div class="ng-value" *ngFor="let item of items | slice:0:1">
                  <span class="ng-value-label">
                    {{'VIEWS_WINDOWED.MODALS.ROUTE_TRACER.Movement of [date], ([points] points, [duration], [distance_str])' | translate:{
                    date: ($any(item).start_date$$ | async | formatDate:'full'), points: ($any(item).length$$ | async), duration:
                    ($any(item).duration$$ | async), distance_str: ($any(item).distance_str$$ | async) } }}
                  </span>
                  <span class="ng-value-icon left" (click)="clear(item)" aria-hidden="true">×</span>
                </div>

                <div class="ng-value" *ngIf="items.length > 1">
                  <span class="ng-value-label">
                    {{ 'VIEWS_WINDOWED.MODALS.ROUTE_TRACER.[total] more' | translate:{ 'total': items.length - 1 } }}
                  </span>
                </div>
              </ng-template>
              <!-- #endregion -->

              <!-- #region .override-option-template -->
              <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
                <div *ngLet="asDeviceMovement(item); let movement;">
                  <span>
                    <b>
                      <ng-container *ngIf="movement.is_same_day$$ | async; else notSameDay">
                        {{ 'VIEWS_WINDOWED.MODALS.ROUTE_TRACER.Movement of [start_date] from [start_hour] to [end_hour]' | translate: {
                        start_date: (movement.start_date$$ | async | formatDate: 'short'), start_hour: (movement.start_date$$ | async |
                        formatDate: 'hour_short'), end_hour: (item.end_date$$ | async | formatDate: 'hour_short') } }}
                      </ng-container>

                      <ng-template #notSameDay>
                        {{ 'VIEWS_WINDOWED.MODALS.ROUTE_TRACER.Movement from [start_date] to [end_date]' | translate: { start_date:
                        (movement.start_date$$ | async | formatDate: 'full'), end_date: (item.end_date$$ | async | formatDate: 'full') } }}
                      </ng-template>
                    </b>
                  </span>

                  <span><b>{{ 'VIEWS_WINDOWED.MODALS.ROUTE_TRACER.Points' | translate | colon }}</b>{{ movement?.length$$ | async }}</span>
                  <span
                    ><b>{{ 'VIEWS_WINDOWED.MODALS.ROUTE_TRACER.Duration' | translate | colon }}</b>{{ movement?.duration$$ | async }}</span
                  >
                  <span
                    ><b>{{ 'VIEWS_WINDOWED.MODALS.ROUTE_TRACER.Distance' | translate | colon }}</b>{{ movement?.distance_str$$ | async
                    }}</span
                  >
                  <span>
                    <b>{{ 'VIEWS_WINDOWED.MODALS.ROUTE_TRACER.On multiple days' | translate | colon }}</b>
                    {{ ((movement?.is_same_day$$ | async) ? 'ALL.COMMON.No' : 'ALL.COMMON.Yes') | translate }}
                  </span>
                </div>
              </ng-template>

              <!-- #endregion -->
            </mtx-select>
          </mat-form-field>
        </div>

        <div style="padding-bottom: 0.4em">
          <bg2-button
            [type]="'action'"
            [matMenuTriggerFor]="exportMenu"
            [message]="'VIEWS.MODALS.ROUTE_TRACER.Export route' | translate"
            [icon]="'mdi-download'"
            [disabled]="selected_routes === null || selected_routes.length < 1 || (is_exporting$$ | async)"
            [loading]="is_exporting$$ | async"
          ></bg2-button>

          <mat-menu #exportMenu="matMenu">
            <ng-container *ngIf="(view_mode$$ | async); let view_mode">
              <button mat-menu-item (click)="exportData('kml')" *ngIf="view_mode === 'map'">
                {{ 'CORE.SERVICES.EXPORT.Export as KML' | translate }}
              </button>

              <ng-container *ngIf="view_mode === 'tbl'">
                <button mat-menu-item (click)="exportData('csv')"> {{ 'CORE.SERVICES.EXPORT.Export as CSV' | translate }} </button>
                <button mat-menu-item (click)="exportData('xlsx')"> {{ 'CORE.SERVICES.EXPORT.Export as XLSX' | translate }} </button>
              </ng-container>
            </ng-container>
          </mat-menu>
        </div>
      </div>

      <div class="router-modal__content__map-or-table">
        <bg2-route-tracer-map
          *ngIf="(view_mode$$ | async) === 'map'"
          [device_movements]="selected_routes$$ | async"
          (reload)="on_reload()"
        ></bg2-route-tracer-map>

        <bg2-device-movements-table
          *ngIf="(view_mode$$ | async) === 'tbl'"
          [routes]="datatable_data$$ | async"
          [export_as]="export_from_table$$ | async"
          (exported)="table_has_been_exported()"
        ></bg2-device-movements-table>
      </div>
    </div>
  </bg2-modal-content>
</bg2-modal>
