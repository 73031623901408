<ng-container *ngIf="visible$$ | async">
  <div class="checkbox-container">
    <form-element-header [schema]="schema" [showLabel]="false"></form-element-header>
    <p>
      <mat-checkbox id="checkbox-{{id}}" [ngModel]="value$$ | async" (ngModelChange)="value = $event" color="primary" [disabled]="schema?.readOnly">
        {{ schema.label | translate }}
      </mat-checkbox>
    </p>
  </div>
  <form-element-previous [previous]="previous$$ | async"></form-element-previous>
</ng-container>
