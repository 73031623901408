import { Router } from '@angular/router';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';
import { marker as i18n } from '@biesbjerg/ngx-translate-extract-marker';

import { assign, clone, cloneDeep, find, get, groupBy, isEqual, isNaN, isNil, mapValues, merge, pick, set, unset } from 'lodash-es';

import { IProperties, ISchema } from 'ngx-schema-form';

import {
  of,
  map,
  tap,
  take,
  switchMap,
  throwError,
  Observable,
  Subscription,
  combineLatest,
  BehaviorSubject,
  range,
  filter,
  debounceTime,
} from 'rxjs';
import {
  replay,
  waitForNotNilValue,
  robustCombineLatest,
  waitForNotNilProperties,
  distinctUntilRealChanged,
} from '@bg2app/tools/rxjs';

import { DialogsService } from 'app/widgets/dialogs-modals';
import { AppStateService } from 'app/core/app-state.service';
import { ConsoleLoggerService } from 'app/core/console-logger.service';
import { Beeguard2Api, BeeguardAuthService, DeviceApi } from 'app/core';

import { Apiary, Hive } from 'app/models';
import { Dictionary } from 'app/typings/core/interfaces';
import {
  IVisitApiaryAspectSchema,
  IVisitHiveIdentifiedAspectSchema,
  IVisitHiveNotIdentifiedAspectSchema,
  VisitConfigSchema,
  VisitDataSchema,
  VisitEvent,
  VisitEventData,
  VisitEventSchema,
  VisitHiveNotIdentifiedData,
  VISIT_ASPECT,
} from 'app/models/events/VisitEvent';

import { NewEventModalComponent, NewEventModalParams } from '../new-event/new-event.modal';

import { parseDate } from 'app/misc/tools';

import { EventForm } from 'app/widgets/event-form';
import { SchemaFormBinder } from 'app/widgets/widgets-reusables/forms/bg2-form-overlay.component';

import { ApiaryEvaluationManager } from './_visit-manager';

/** */
interface ApiaryEvaluationV2ModalParams extends NewEventModalParams {
  /** */
  eid?: number;

  /** */
  aid?: number;
}

/** */
@Component({
  selector: 'bg2-visit-modal',
  templateUrl: './visit-modal.component.html',
  styleUrls: ['./visit-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VisitModalComponent extends NewEventModalComponent<ApiaryEvaluationV2ModalParams, VisitEvent> implements OnInit, OnDestroy {
  // #region -> (component basics)

  /** */
  protected _logger = new ConsoleLoggerService('VisitModalComponent', true);

  /** */
  private _event_object_sub: Subscription = null;

  /** */
  constructor(
    public appState: AppStateService,
    protected router: Router,
    protected authApi: BeeguardAuthService,
    protected deviceApi: DeviceApi,
    protected _bg2Api: Beeguard2Api,
    protected dialogs: DialogsService,
    protected _cdRef: ChangeDetectorRef,
    protected translate: TranslateService
  ) {
    super(_bg2Api, dialogs, _cdRef, appState, translate);
  }

  /** */
  ngOnInit(): void {
    super.ngOnInit();

    // Load the evaluation event
    this._event_object_sub = this.input_params$$
      .pipe(
        map(parameters => ({ apiary_id: parameters?.aid, event_id: parameters?.eid })),
        distinctUntilRealChanged(),
        switchMap(({ apiary_id, event_id }) => {
          if (isNil(event_id)) {
            if (isNil(apiary_id) || isNaN(apiary_id)) {
              return throwError(() => new Error('Misdefinition of apiary ID !'));
            }

            return combineLatest({
              event: of<VisitEvent>(new VisitEvent(this._bg2Api)),
              apiary_id: of<number>(apiary_id),
            });
          }

          return this._bg2Api.getEventObj(+event_id).pipe(
            switchMap(event => {
              if (event.type !== 'evaluation') {
                return throwError(() => new Error('Loaded event should be of type EvaluationEvent'));
              }

              this.loading = true;
              return event.date$$.pipe(map(() => ({ event, apiary_id: event.apply_to.apiary.entity_id })));
            })
          );
        })
      )
      .subscribe({
        next: ({ event, apiary_id }: { event: VisitEvent; apiary_id: number }) => {
          this.event = event;
          this._apiary_id$$.next(apiary_id);
        },
      });

    //
    this.apiary$$.subscribe({
      next: () => {
        this.build_and_bind_config_schema_form();
      },
    });
  }

  /** */
  ngOnDestroy(): void {
    super.ngOnDestroy();

    this._event_object_sub?.unsubscribe();
  }

  // #endregion

  // #region -> (apiary entity management)

  /** */
  private _apiary_id$$ = new BehaviorSubject<number>(null);

  /**
   * Observes the apiary entity
   */
  public apiary$$ = this._apiary_id$$.asObservable().pipe(
    waitForNotNilValue(),
    tap(apiary_id => {
      // Update modal parameters
      const params = clone(this.input_params);
      params.aid = +apiary_id;
      this.input_params = params;

      // NOTE: It is required to generate the schema with default value
      this.args.apiary = +apiary_id;
    }),
    switchMap(apiary_id => this._bg2Api.getEntityObj(apiary_id) as Observable<Apiary>),
    replay()
  );

  /**
   * Observes the apiary's state at the date of the event.
   */
  private apiary__state__at_date$$ = combineLatest({ apiary: this.apiary$$, event: this.event$$ }).pipe(
    waitForNotNilProperties(),
    switchMap(({ apiary, event }) => apiary.getStateAtDate(event?.date, event?.id, true)),
    map(entity_state_value => entity_state_value?.state ?? null),
    replay()
  );

  // #endregion

  // #region -> (evaluation manager)

  /** */
  public evaluation_manager = new ApiaryEvaluationManager();

  // #endregion

  /** */
  public reload_schema = true;

  /**
   * Reloads schema form to force change.
   */
  private reload_form_schema() {
    this.reload_schema = false;
    setTimeout(() => {
      this.reload_schema = true;
    }, 0);
  }

  // #region -> (event schemas)

  /** */
  private _event_full_schema$$ = new BehaviorSubject<VisitEventSchema>(null);

  /** */
  private event_full_schema$$ = this._event_full_schema$$.asObservable().pipe(waitForNotNilValue(), replay());

  /**
   * Observes the schema for the aplied entities.
   *
   * @private
   */
  private event_visit_applyto__schema$$: Observable<ISchema> = this.event_full_schema$$.pipe(
    waitForNotNilValue(),
    switchMap(event_full_schema => {
      const apply_to_schema = cloneDeep(get(event_full_schema, 'properties.apply_to', null));

      if (isNil(apply_to_schema)) {
        return throwError(() => new Error('Could not find visit applyto schema'));
      }

      return of(apply_to_schema);
    }),
    distinctUntilRealChanged()
  );

  /**
   * Observes the schema for the date.
   *
   * @private
   */
  private event_visit_date__schema$$: Observable<ISchema> = this.event_full_schema$$.pipe(
    waitForNotNilValue(),
    switchMap(event_full_schema => {
      const date_schema = cloneDeep(get(event_full_schema, 'properties.date', null));

      if (isNil(date_schema)) {
        return throwError(() => new Error('Could not find visit date schema'));
      }

      return of<ISchema>(date_schema);
    }),
    switchMap(date_schema =>
      this.event_date$$.pipe(
        map(event_date => {
          date_schema.default = event_date.toISOString();
          return date_schema;
        })
      )
    ),
    distinctUntilRealChanged()
  );

  /**
   * Observes the schema for the data.
   *
   * @private
   */
  private event_visit_data__schema$$: Observable<VisitDataSchema> = this.event_full_schema$$.pipe(
    waitForNotNilValue(),
    switchMap(event_full_schema => {
      const visit_data_schema = get(event_full_schema, 'properties.data.properties.visit_data', null);

      if (isNil(visit_data_schema)) {
        return throwError(() => new Error('Could not find visit data schema'));
      }

      return of(visit_data_schema);
    }),
    distinctUntilRealChanged()
  );

  /**
   * Observes the configuration's visit schema.
   *
   * @private
   */
  private event_visit__config__schema$$: Observable<VisitConfigSchema> = this.event_full_schema$$.pipe(
    waitForNotNilValue(),
    switchMap(event_full_schema => {
      const visit_config_schema = get(event_full_schema, 'properties.data.properties.visit_config', null);

      if (isNil(visit_config_schema)) {
        return throwError(() => new Error('Could not find visit config schema'));
      }

      return of(visit_config_schema);
    }),
    distinctUntilRealChanged()
  );

  // #endregion

  // #region -> (config schema forms management)

  /** */
  private build_and_bind_config_schema_form(): void {
    if (isNil(this.event)) {
      throw new Error('Cannot build and bind config form since event is nil');
    }

    EventForm.buildSchema(this.event, this.args)
      .pipe(
        switchMap((event_schema: VisitEventSchema) => this.authApi.real_user_id$$.pipe(map(user_id => ({ event_schema, user_id })))),
        take(1)
      )
      .subscribe({
        next: ({ event_schema, user_id }) => {
          this._event_full_schema$$.next(event_schema);
        },
      });
  }

  /** */
  private _last_visit_config_model: Dictionary<any> = null;

  /** */
  public update_model_for_visit_config(
    incoming_model: Partial<{ date: string; apply_to: Dictionary<any>; config: Dictionary<any>; author: number }>
  ): void {
    if (isEqual(incoming_model, this._last_visit_config_model)) {
      return;
    }

    this.event.update(
      !isNil(incoming_model?.date) ? parseDate(incoming_model?.date) : undefined,
      incoming_model?.apply_to ?? {},
      assign({}, this.event.data, { config: assign({}, incoming_model?.config, { author: incoming_model?.author }) })
    );

    this._last_visit_config_model = incoming_model;
  }

  // #endregion

  // #region -> (event config)

  /** */
  public event_config__schema$$ = this.authApi.real_user_id$$.pipe(
    waitForNotNilValue(),
    switchMap(user_id =>
      this.event_full_schema$$.pipe(
        map(event_full_schema => {
          const event_config_schema = cloneDeep(event_full_schema);

          event_config_schema.order = ['date', 'apply_to', 'author', 'data'];
          event_config_schema.properties.apply_to = merge(event_config_schema.properties.apply_to, <ISchema>{
            title: i18n<string>('EVENT.VISIT.Informations'),
            properties: {
              hives: {
                widget: 'hidden',
              },
            },
          });
          event_config_schema.properties.author = {
            type: 'number',
            widget: 'bg2user',
            default: user_id,
            label: 'VIEWS.MODALS.EVAL.Visit done by',
            options: {
              readonly: true,
            },
          };

          unset(event_config_schema, 'properties.data.properties.visit_config');
          unset(event_config_schema, 'properties.data.properties.visit_data');

          return event_config_schema;
        })
      )
    )
  );

  /**
   * Observes the visit configuration model.
   */
  public visit_config__model$$ = combineLatest({
    data: this.event_data$$,
    date: this.event$$.pipe(switchMap(event => event.date$$)),
    apply_to: this.event$$.pipe(switchMap(event => event.apply_to$$)),
  }).pipe(
    map(({ data, date, apply_to }) => {
      const config_data = data?.visit_config ?? null;
      const rebuild_data: Dictionary<any> = {};

      set(rebuild_data, 'author', data?.config?.author);
      unset(config_data, 'author');

      set(rebuild_data, 'visit_config', config_data);
      set(
        rebuild_data,
        'apply_to',
        mapValues(apply_to, (value: { entity_id: number }, key) => value?.entity_id)
      );
      set(rebuild_data, 'date', date.toISOString());

      return rebuild_data;
    })
  );

  // #endregion

  // #region -> (aspects navigation)

  /**
   * Observes the aspects to visit.
   */
  public visit_aspects$$: Observable<VISIT_ASPECT[]> = this.evaluation_manager.visit_state$$.pipe(
    switchMap(visit_state => this.aspects_config__model$$.pipe(map(config_model => get(config_model, visit_state) as any)))
  );

  /** */
  public set select_aspect_to_visit(aspect: VISIT_ASPECT) {
    this.evaluation_manager.visit_aspect = aspect;
    this.reload_form_schema();
  }

  // #endregion

  // #region -> (aspects config)

  /** */
  private _is_aspects_config_valid$$ = new BehaviorSubject(false);

  /** */
  public is_aspects_config_valid$$ = this._is_aspects_config_valid$$.asObservable();

  /** */
  public set is_aspects_config_valid(is_aspects_config_valid: boolean) {
    this._is_aspects_config_valid$$.next(is_aspects_config_valid);
  }

  /** */
  public aspects_config__schema$$ = this.evaluation_manager.visit_state$$.pipe(
    filter(visit_state => visit_state === 'hive_by_hive_aspects_config' || visit_state === 'overall_visit_aspects_config'),
    switchMap(visit_state =>
      this.visit_config__model$$.pipe(
        switchMap(config_model => {
          const visit_config_model = config_model?.visit_config;
          const visit_type =
            visit_state === 'overall_visit_aspects_config'
              ? 'overall'
              : visit_config_model?.hive_by_hive?.visit_type === 'identified'
              ? 'identified'
              : 'not_identified';

          return this.event_visit__config__schema$$.pipe(
            map(schema => <ISchema>get(schema, 'properties.aspects_to_visit')),
            map(aspects_config => {
              // Add metadata
              aspects_config.properties['metadata'] = <ISchema>{
                type: 'object',
                properties: {
                  visit_type: {
                    type: 'string',
                    widget: 'hidden',
                    default: visit_type,
                    oneOf: [
                      {
                        enum: ['overall'],
                        label: 'Apiary',
                        options: {
                          img: '',
                        },
                      },
                      {
                        enum: ['not_identified'],
                        label: 'Not identified hives',
                        options: {
                          img: 'img/eval/hive_fast.png',
                        },
                      },
                      {
                        enum: ['identified'],
                        label: 'Identified hives',
                        options: {
                          img: 'img/eval/hive_step.png',
                        },
                      },
                    ],
                  },
                },
              };

              return aspects_config;
            })
          );
        })
      )
    )
  );

  /** */
  public aspects_config__model$$ = this.event_data$$.pipe(map(event_data => get(event_data, 'visit_config.aspects_to_visit')));

  /** */
  public update_model_for_aspects_config(data: Dictionary<any>): void {
    delete data.metadata;

    let full_event_model = cloneDeep(this.event.data || {});

    const initial_aspects_config = get(full_event_model, `visit_config.aspects_to_visit`, {});
    set(full_event_model, `visit_config.aspects_to_visit`, data);

    this.event.update(undefined, {}, full_event_model);
  }

  // #endregion

  // #region -> (hive by hive visit config)

  /** */
  private _is_hive_by_hive_config_valid$$ = new BehaviorSubject(false);

  /** */
  public is_hive_by_hive_config_valid$$ = this._is_hive_by_hive_config_valid$$.asObservable();

  /** */
  public set is_hive_by_hive_config_valid(is_hive_by_hive_config_valid: boolean) {
    this._is_hive_by_hive_config_valid$$.next(is_hive_by_hive_config_valid);
  }

  /** */
  public hive_by_hive_config__schema$$ = this.event_visit__config__schema$$.pipe(
    map(schema => schema.properties.hive_by_hive),
    switchMap(config_schema =>
      this.event_visit_date__schema$$.pipe(
        map(date_schema => {
          config_schema = set(config_schema, 'properties.metadata.properties.date', date_schema);

          return config_schema;
        })
      )
    ),
    switchMap(config_schema =>
      this.event_visit_applyto__schema$$.pipe(
        map(applyto_schema => {
          const schema = cloneDeep(applyto_schema);

          schema.widget = 'hidden';
          schema.properties['hives'].options = {
            ...schema.properties['hives'].options,
            event_date_path: '../metadata/date',
          };
          schema.properties['apiary'].options = {
            ...schema.properties['apiary'].options,
            event_date_path: '../metadata/date',
          };
          schema.properties['location'].options = {
            ...schema.properties['location'].options,
            event_date_path: '../metadata/date',
          };
          schema.properties['warehouse'].options = {
            ...schema.properties['warehouse'].options,
            event_date_path: '../metadata/date',
          };

          config_schema.properties.metadata.properties.apply_to = schema;
          return config_schema;
        })
      )
    ),
    replay()
  );

  /** */
  public hive_by_hive_config__model$$ = this.event_data$$.pipe(map(event_data => event_data?.visit_config?.hive_by_hive));

  /** */
  public update_model_for_hive_by_hive_config(data: Dictionary<any>): void {
    delete data.metadata;

    let full_event_model = cloneDeep(this.event.data);

    const initial_aspects_config = get(full_event_model, `visit_config.hive_by_hive`, {});
    set(full_event_model, `visit_config.hive_by_hive`, data);

    this.event.update(undefined, {}, full_event_model);
  }

  /**
   * Starts the hive by hive visit.
   *
   * @public
   */
  public start_hive_by_hive_visit(): void {
    this.hive_by_hive_config__model$$
      .pipe(
        take(1),
        map(model => <'identified' | 'not_identified'>model?.visit_type)
      )
      .subscribe({
        next: visit_type => {
          if (visit_type === 'not_identified') {
            this.evaluation_manager.visit_state_machine.transition('do_hive_by_hive_not_identified_visit');
          } else {
            this.evaluation_manager.visit_state_machine.transition('do_hive_by_hive_identified_visit');
          }
        },
      });
  }

  // #endregion

  // #region -> (overall visit)

  /** */
  public overall_visit_schema$$: Observable<IVisitApiaryAspectSchema> = this.event_visit_data__schema$$.pipe(
    map(data_schema => <IVisitApiaryAspectSchema>data_schema.properties.overall_visit),
    switchMap(schema => {
      let modified_schema = cloneDeep(schema);

      return this.evaluation_manager.visit_aspect$$.pipe(
        waitForNotNilValue(),
        map(current_visit_aspect => {
          const properties_to_display = pick(modified_schema.properties, ['metadata', current_visit_aspect]);
          modified_schema.properties = properties_to_display;

          return modified_schema;
        })
      );
    }),
    switchMap(schema =>
      this.event_visit_date__schema$$.pipe(
        map(date_schema => {
          let schema_properties: IProperties = schema.properties;

          if (isNil(schema_properties.metadata)) {
            schema_properties.metadata = {
              type: 'object',
              properties: {
                date: date_schema,
              },
            };
          }

          schema.properties = schema_properties;
          return schema;
        })
      )
    ),
    switchMap(schema =>
      this.event_visit_applyto__schema$$.pipe(
        map(applyto_schema => {
          applyto_schema.properties = mapValues(applyto_schema.properties, (value, key) => {
            value.options.event_date_path = '/metadata/date';
            return value;
          });

          (<IProperties>schema.properties)['metadata']['properties']['apply_to'] = applyto_schema;
          (<IProperties>schema.properties)['metadata'].widget = 'hidden';

          return schema;
        })
      )
    )
  );

  /**
   * Observes the complete overall visit model.
   */
  private apiary_visit__model__full$$ = this.event_data$$.pipe(
    map(event_data => event_data?.visit_data?.overall_visit ?? {}),
    replay()
  );

  /** */
  public apiary_visit__model$$ = this.apiary_visit__model__full$$.pipe(replay());

  /** */
  public update_model_for_apiary_visit(incoming_data: Dictionary<any>) {
    unset(incoming_data, 'metadata');
    console.log(incoming_data);

    this.evaluation_manager.visit_aspect$$.pipe(take(1)).subscribe({
      next: current_visit_aspect => {
        let full_event_model = cloneDeep(this.event?.data);

        if (isNil(full_event_model.visit_data)) {
          full_event_model.visit_data = <any>{};
        }

        if (isNil(full_event_model.visit_data.overall_visit)) {
          full_event_model.visit_data.overall_visit = <any>{};
        }

        full_event_model.visit_data.overall_visit[current_visit_aspect] = incoming_data[current_visit_aspect];
        this.event.update(undefined, {}, full_event_model);
      },
    });
  }

  // #endregion

  // #region -> (hive by hive identified visit)

  /** */
  public hive_identified_visit__form_binder = new SchemaFormBinder();

  /** */
  private _hive_identified_visit__current_id$$ = new BehaviorSubject<number>(null);

  /** */
  public set hive_identified_visit__current_id(id: number) {
    this.hive_identified_visit__form_binder.should_bind_form = false;
    this._hive_identified_visit__current_id$$.next(id);
  }

  /** */
  public hive_by_hive_identified_schema$$: Observable<IVisitHiveIdentifiedAspectSchema['items']> = this.event_visit_data__schema$$.pipe(
    map(data_schema => <IVisitHiveIdentifiedAspectSchema>data_schema.properties.hive_by_hive_identified_visit),
    map(schema => schema.items),
    switchMap(schema => {
      let modified_schema = cloneDeep(schema);

      return this.evaluation_manager.visit_aspect$$.pipe(
        waitForNotNilValue(),
        map(current_visit_aspect => {
          const properties_to_display = pick(modified_schema.properties, ['metadata', 'hive_id', current_visit_aspect]);
          modified_schema.properties = properties_to_display;

          return modified_schema;
        })
      );
    }),
    switchMap(schema =>
      this.event_visit_date__schema$$.pipe(
        map(date_schema => {
          let schema_properties: IProperties = schema.properties;

          if (isNil(schema_properties.metadata)) {
            schema_properties.metadata = {
              type: 'object',
              properties: {
                date: date_schema,
              },
            };
          }

          schema_properties.metadata.properties.date.widget = 'hidden';
          schema.properties = schema_properties;
          return schema;
        })
      )
    ),
    replay(),
    distinctUntilRealChanged(),
    tap(data => this._logger.debug(data))
  );

  /**
   * Observes the model of all the hives (identified mode).
   *
   * @private
   * @replay
   */
  public hive_identified_visit__model__full$$ = this.event_data$$.pipe(
    map(event_data => event_data?.visit_data?.hive_by_hive_identified_visit ?? null),
    replay()
  );

  /**
   * Observes the model of the current selected hive (identified mode).
   *
   * @public
   * @replay
   */
  public hive_identified_visit__model$$ = this.hive_identified_visit__model__full$$.pipe(
    switchMap(models =>
      this._hive_identified_visit__current_id$$.pipe(
        map(current_id => {
          const found_model = find(models, model => model.hive_id === current_id) ?? null;

          if (isNil(found_model)) {
            return { hive_id: current_id };
          }

          return found_model;
        })
      )
    ),
    this.hive_identified_visit__form_binder.wait_for_form_bind(),
    replay()
  );

  /**
   * Updates event model for the current identified hive.
   *
   * @public
   */
  public update_model_for_hive_identified_visit(incoming_data: Dictionary<any> | any) {
    unset(incoming_data, 'metadata');

    this._hive_identified_visit__current_id$$.pipe(waitForNotNilValue(), take(1)).subscribe({
      next: current_hive_id => {
        let full_event_model = cloneDeep(this.event?.data);
        const related_index = (full_event_model?.visit_data?.hive_by_hive_identified_visit ?? []).findIndex(
          model => model?.hive_id === current_hive_id
        );

        if (related_index < 0) {
          const new_index = (full_event_model?.visit_data?.hive_by_hive_identified_visit ?? []).length;

          full_event_model = set(full_event_model, `visit_data.hive_by_hive_identified_visit[${new_index}]`, incoming_data);
          full_event_model = set(full_event_model, `visit_data.hive_by_hive_identified_visit[${new_index}].hive_id`, current_hive_id);
        } else {
          const updated_model = merge(
            {},
            get(full_event_model, `visit_data.hive_by_hive_identified_visit[${related_index}]`, {}),
            incoming_data
          );
          full_event_model = set(full_event_model, `visit_data.hive_by_hive_identified_visit[${related_index}]`, updated_model);
        }

        this.event.update(undefined, {}, full_event_model);
      },
    });
  }

  /**
   * Observes the hives to visit (identified mode).
   *
   * @public
   */
  public hives_to_visit$$ = this.apiary__state__at_date$$.pipe(
    map(apiary_state => ({
      nb_nuc: apiary_state?.nb_nuc ?? 0,
      nb_hives: apiary_state?.nb_hives ?? 0,
      hive_ids__length: apiary_state?.hive_ids?.length ?? 0,
    })),
    switchMap(({ nb_nuc, nb_hives, hive_ids__length }) =>
      this.hive_by_hive_config__model$$.pipe(
        map(
          model =>
            <{ hives_to_visit: number[]; evaluated_hive_type: ('hive' | 'nuc')[] }>pick(model, ['hives_to_visit', 'evaluated_hive_type'])
        ),
        switchMap(({ hives_to_visit, evaluated_hive_type }) => {
          if (hives_to_visit.length > hive_ids__length) {
            return throwError(
              () => new Error('Total of hives to visit should not be superior than total of existing hives in apiary at visit event')
            );
          }

          const hives_to_visit$$ = this._bg2Api.getEntitiesObj(hives_to_visit, 'hive').pipe(replay()) as Observable<Hive[]>;

          if (hives_to_visit?.length < hive_ids__length) {
            return hives_to_visit$$;
          }

          return this.apiary$$.pipe(
            take(1),
            switchMap(apiary =>
              hives_to_visit$$.pipe(
                switchMap(hives => {
                  const apiary_existing_hives_nucs: { [key in 'nuc' | 'hive']: Hive[] } = groupBy(hives, hive => hive.type) as any;
                  const hives$$: Observable<Hive>[] = [];

                  // Add missing "ghost" hives
                  if (evaluated_hive_type.includes('hive')) {
                    const not_named_hives_total = nb_hives - (apiary_existing_hives_nucs?.hive ?? []).length;
                    apiary_existing_hives_nucs?.hive?.forEach(hive => hives$$.push(of(hive)));

                    if (not_named_hives_total > 0) {
                      // Build missing hives
                      range(not_named_hives_total).forEach(hive_num => {
                        hives$$.push(
                          Hive.create(
                            {
                              htype: 'hive',
                              apiary: apiary,
                            },
                            { bg2Api: this._bg2Api, deviceApi: this.deviceApi },
                            { translate: this.translate },
                            { offset: 1 + hive_num }
                          ).pipe(
                            map(hive => {
                              hive.will_be_created_after_visit = true;
                              return hive;
                            })
                          )
                        );
                      });
                    }
                  }

                  // Add missing "ghost" nuc
                  if (evaluated_hive_type.includes('nuc')) {
                    const not_named_nucs_total = nb_nuc - (apiary_existing_hives_nucs?.nuc ?? []).length;
                    apiary_existing_hives_nucs?.nuc?.forEach(hive => hives$$.push(of(hive)));

                    if (not_named_nucs_total > 0) {
                      range(not_named_nucs_total).forEach(hive_num => {
                        hives$$.push(
                          Hive.create(
                            {
                              htype: 'nuc',
                              apiary: apiary,
                            },
                            { bg2Api: this._bg2Api, deviceApi: this.deviceApi },
                            { translate: this.translate },
                            { offset: 1 + nb_hives + hive_num }
                          ).pipe(
                            map(hive => {
                              hive.will_be_created_after_visit = true;
                              return hive;
                            })
                          )
                        );
                      });
                    }
                  }

                  return robustCombineLatest(hives$$);
                })
              )
            )
          );
        })
      )
    )
  );

  // #endregion

  // #region -> (hive by hive not identified visit management)

  /** */
  public hive_not_identified_visit__form_binder = new SchemaFormBinder();

  /** */
  private _hive_not_identified_visit__current_index$ = new BehaviorSubject<number>(0);

  /** */
  public hive_not_identified_visit__current_index$$ = this._hive_not_identified_visit__current_index$.asObservable();

  /** */
  public set hive_not_identified_visit__current_index(value: number) {
    this.hive_not_identified_visit__form_binder.should_bind_form = false;
    this._hive_not_identified_visit__current_index$.next(value);
  }

  /**
   * Observes the schema for hive by hive not identified visit.
   *
   * @public
   */
  public hive_by_hive_not_identified_schema$$: Observable<IVisitHiveNotIdentifiedAspectSchema['items']> =
    this.event_visit_data__schema$$.pipe(
      map(data_schema => <IVisitHiveNotIdentifiedAspectSchema>data_schema.properties.hive_by_hive_not_identified_visit),
      map(schema => schema.items),
      switchMap(schema => {
        let modified_schema = cloneDeep(schema);

        return this.evaluation_manager.visit_aspect$$.pipe(
          waitForNotNilValue(),
          map(current_visit_aspect => {
            const properties_to_display = pick(modified_schema.properties, ['metadata', 'visit_id', current_visit_aspect]);
            modified_schema.properties = properties_to_display;

            return modified_schema;
          })
        );
      }),

      switchMap(schema =>
        this.event_visit_date__schema$$.pipe(
          map(date_schema => {
            let schema_properties: IProperties = schema.properties;

            if (isNil(schema_properties.metadata)) {
              schema_properties.metadata = {
                type: 'object',
                properties: {
                  date: date_schema,
                },
              };
            }

            schema.properties = schema_properties;
            return schema;
          })
        )
      )
    );

  /**
   * Observes the model of all the hives (not identified mode).
   *
   * @private
   * @replay
   */
  public hive_not_identified_visit__model__full$$ = this.event_data$$.pipe(
    map(event_data => event_data?.visit_data?.hive_by_hive_not_identified_visit ?? null),
    replay()
  );

  /**
   * Observes the model of the current selected hive (not identified mode).
   *
   * @public
   * @replay
   */
  public hive_not_identified_visit__model__for_current$$ = this.hive_not_identified_visit__model__full$$.pipe(
    switchMap(models =>
      this.hive_not_identified_visit__current_index$$.pipe(
        map(current_index => {
          if (current_index < 0) {
            return null;
          }

          const found_model = models?.[current_index] ?? null;

          if (isNil(found_model)) {
            return { visit_id: current_index };
          }

          return found_model;
        })
      )
    ),
    this.hive_not_identified_visit__form_binder.wait_for_form_bind(),
    replay()
  );

  /**
   * Observes the total of hives to visit (not identified mode).
   *
   * @public
   * @replay
   */
  public hive_not_identified_visit__hives__total$$ = this.event_data$$.pipe(
    map(event_data => event_data?.visit_config?.hive_by_hive?.evaluated_hive_type),
    waitForNotNilValue(),
    switchMap(evaluated_hive_type =>
      this.apiary__state__at_date$$.pipe(
        map(state => {
          let total_of_hives = 0;

          if (evaluated_hive_type?.includes('hive')) {
            total_of_hives += state?.nb_hives ?? 0;
          }

          if (evaluated_hive_type?.includes('nuc')) {
            total_of_hives += state?.nb_nuc ?? 0;
          }

          return total_of_hives;
        })
      )
    ),
    replay()
  );

  /**
   * Updates event model for the not identified hive.
   *
   * @public
   */
  public update_model_for_hive_not_identified_visit(incoming_data: VisitHiveNotIdentifiedData) {
    this.hive_not_identified_visit__current_index$$.pipe(waitForNotNilValue(), take(1)).subscribe({
      next: current_index => {
        let full_event_model = cloneDeep(this.event.data);

        const related_model = full_event_model?.visit_data?.hive_by_hive_not_identified_visit?.[current_index] ?? {
          visit_id: current_index,
        };
        const new_value = merge({}, related_model, incoming_data);
        delete new_value.metadata;

        if (isNil(full_event_model?.visit_data)) {
          full_event_model.visit_data = <any>{};
        }

        if (isNil(full_event_model?.visit_data?.hive_by_hive_not_identified_visit)) {
          full_event_model.visit_data.hive_by_hive_not_identified_visit = [];
        }

        full_event_model = set(full_event_model, `visit_data.hive_by_hive_not_identified_visit.[${current_index}]`, new_value);
        this.event.update(undefined, {}, full_event_model);
      },
    });
  }

  // #endregion

  public onWheel(event: WheelEvent): void {
    (<Element>event.target).parentElement.scrollLeft += event.deltaY;
    event.preventDefault();
  }
}
