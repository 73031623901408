import { Injectable } from '@angular/core';

import { filter, map, take } from 'rxjs';
import { replay } from 'app/misc/tools';

import { AppStateService } from 'app/core/app-state.service';
import { Beeguard2Api, BeeguardAuthService } from 'app/core';

import { AppBackofficeConfig, SimpleSetterGetter } from 'app/models';

@Injectable({
  providedIn: 'root',
})
export class AppBackofficeService {
  // #region - service basics

  constructor(
    private readonly _appState: AppStateService,
    private readonly _beeguard2Api: Beeguard2Api,
    private readonly _authService: BeeguardAuthService
  ) {
    this.onInit();
  }

  /** */
  public onInit(): void {
    this._authService.is_authorized$$
      .pipe(
        filter(is_authorized => is_authorized),
        take(1)
      )
      .subscribe(() => this._load_configuration());
  }

  // #endregion

  // #region - backoffice configuration

  /** */
  private _full_configuration = new SimpleSetterGetter<AppBackofficeConfig>(null);

  /** */
  public top_layer_announcement_config$$ = this._full_configuration.value$$.pipe(
    map(config => config?.top_layer_announcement),
    replay()
  );

  /** */
  private _load_configuration(): void {
    this._full_configuration.value = <AppBackofficeConfig>{
      top_layer_announcement: {
        enabled: true,
        styles: {
          background_color: 'orange',
          font_color: 'inherit',
        },
        message: {
          en: 'Telephone reception unavailable from 17/02 to 21/02 - Please prefer e-mail',
          es: 'Servicio telefónico no disponible del 17/02 al 21/02 - Prefiera el correo electrónico',
          fr: "Accueil téléphonique indisponible du 17/02 au 21/02 - Privilégiez le mail",
          it: 'Servizio telefonico non disponibile dal 17/02 al 21/02 - Preferire l\'e-mail',
          ja: '17/02から21/02まで電話は不通。',
          pt: 'Serviço telefónico indisponível de 17/02 a 21/02 - Prefira o correio eletrónico',
        },
        read_more: {
          enabled: false,
          dialog_config: {
            title: {
              en: 'Summer holidays from 29/07 to 23/08',
              es: 'Vacaciones de verano del 29/07 al 23/08',
              fr: "Congés d'été du 29/07 au 23/08",
              it: 'Vacanze estive dal 29/07 al 23/08',
              ja: '夏休みは7月29日から8月23日まで。',
              pt: 'Férias de verão de 29/07 a 23/08',
            },
            body: {
              content: {
                en: "It's summer holiday time for the BeeGuard team.\n\nThe switchboard will stop at 5pm on 26 July and will be back on 26 August at 9am.\n\nThe last shipments will be processed between 29 July and 02 August.\n\nYou can always write to us at contact@beeguard.fr or use the contact form if you need to.\n\nMessages will be consulted regularly, including during the holiday period. We would be grateful if you could specify the level of urgency so that we know whether we can reply to you after our holidays or whether we need to send the fire brigade.\n\nThe server and application services will remain under surveillance 7/7 and 24/24 with a team on call in case of emergency.\n\nWe look forward to seeing you again after this rest period.\n\nBeeGuard team\n\nTranslated with DeepL.com (free version)",
                es: 'Llegan las vacaciones de verano para el equipo de BeeGuard.\n\nLa centralita dejará de funcionar a las 17.00 horas del 26 de julio y volverá el 26 de agosto a las 9.00 horas.\n\nLos últimos envíos se procesarán entre el 29 de julio y el 2 de agosto.\n\nSiempre puede escribirnos a contact@beeguard.fr o utilizar el formulario de contacto si lo necesita.\n\nLos mensajes se consultarán con regularidad, incluso durante el periodo de vacaciones. Le agradeceríamos que nos especificara el nivel de urgencia para saber si podemos responderle después de las vacaciones o si tenemos que enviar a los bomberos.\n\nLos servicios de servidores y aplicaciones permanecerán bajo vigilancia 7/7 y 24/24 con un equipo de guardia en caso de emergencia.\n\nEsperamos volver a verle después de este periodo de descanso.\n\nEquipo BeeGuard\n\nTraducción realizada con la versión gratuita del traductor DeepL.com',
                fr: "C'est le moment des congés d'été pour l'équipe BeeGuard.\n\nLe standard téléphonique s'arrête le 26 juillet à 17h pour vous retrouver le 26 aout à 9h.\n\nLes dernières expéditions seront traitées entre le 29 juillet et le 02 aout.\n\nVous pourrez toujours nous écrire sur la boite email contact@beeguard.fr ou utiliser le formulaires de contact en cas de besoin.\n\nLes messages seront consultés régulièrement y compris pendant la période de fermeture, nous vous remercions par avance de bien préciser le niveau d'urgence pour savoir si on peut vous répondre après nos vacances ou s'il faut dépêcher les pompiers.\n\nLe serveur et les services de l'application restent sous surveillance 7/7 et 24/24 avec une équipe d'astreinte en cas de coups de chaud.\n\nAu plaisir de vous retrouver après cette période de repos.\n\nEquipe BeeGuard",
                it: "È tempo di vacanze estive per il team di BeeGuard.\n\nIl centralino si fermerà alle 17.00 del 26 luglio e tornerà alle 9.00 del 26 agosto.\n\nLe ultime spedizioni saranno elaborate tra il 29 luglio e il 2 agosto.\n\nIn caso di necessità, potete sempre scriverci all'indirizzo contact@beeguard.fr o utilizzare il modulo di contatto.\n\nI messaggi saranno consultati regolarmente, anche durante il periodo di ferie. Vi saremmo grati se poteste specificare il livello di urgenza, in modo da sapere se possiamo rispondervi dopo le ferie o se dobbiamo inviare i vigili del fuoco.\n\nIl server e i servizi applicativi rimarranno sorvegliati 7/7 e 24/24 con un team a disposizione in caso di emergenza.\n\nCi auguriamo di rivedervi dopo questo periodo di riposo.\n\nIl team BeeGuard",
                ja: 'ビーガード・チームは夏休みです。\n\n7月26日午後5時に電話受付を停止し、8月26日午前9時に再開します。\n\n最後の出荷は7月29日から8月2日の間に処理されます。\n\ncontact@beeguard.fr、またはお問い合わせフォームをご利用ください。\n\nお休み期間中も含め、定期的にご連絡を差し上げますので、お休み明けにお返事が可能か、消防隊を派遣する必要があるかなど、緊急度をご指定いただければ幸いです。\n\nなお、サーバーおよびアプリケーション・サービスについては、年中無休・24時間体制で監視を継続し、緊急時に備えて待機しております。\n\nこの休暇の後、またお会いできることを楽しみにしております。\n\nBeeGuardチーム',
                pt: 'É tempo de férias de verão para a equipa BeeGuard.\n\nA central telefónica pára às 17h00 do dia 26 de julho e regressa no dia 26 de agosto às 9h00.\n\nOs últimos envios serão processados entre 29 de julho e 2 de agosto.\n\nSe necessário, pode sempre escrever-nos para contact@beeguard.fr ou utilizar o formulário de contacto.\n\nAs mensagens serão consultadas regularmente, incluindo durante o período de férias. Agradecemos que especifique o nível de urgência para sabermos se podemos responder-lhe após as férias ou se temos de enviar os bombeiros.\n\nO servidor e os serviços de aplicação permanecerão sob vigilância 7/7 e 24/24 com uma equipa de plantão em caso de emergência.\n\nEsperamos vê-lo novamente após este período de descanso.\n\nEquipa BeeGuard',
              },
            },
            footer: {
              button: {
                color: 'transparent',
                message: {
                  en: 'Close',
                  es: 'Cerrar',
                  fr: 'Fermer',
                  it: 'Chiudere',
                  ja: '閉じる',
                  pt: 'Fechar',
                },
              },
            },
          },
        },
      },
    };
  }

  // #endregion

  // #region - top layer announcement

  /** */
  public show_top_layer_announcement = sessionStorage.getItem('top_layer_announcement');

  /** */
  public hide_top_layer_announcement = () => {
    sessionStorage.setItem('top_layer_announcement', 'no');
    this.show_top_layer_announcement = 'no';
  };

  // #endregion
}
