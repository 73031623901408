<ng-container *ngIf="visible$$ | async">
  <form-element-header [schema]="schema" [showLabel]="false"></form-element-header>
  <div class="input-content">
    <div class="picto" *ngIf="schema.options?.img">
      <img [src]="'assets/' + schema.options?.img" width="90px" />
    </div>

    <mat-form-field class="ng-default" appearance="outline">
      <mat-label>{{ schema.label | translate }}</mat-label>
      <input
        matInput
        type="number"
        class="browser-default"
        [id]="uid"
        [name]="name"
        [ngModel]="(value$$ | async)"
        [disabled]="schema?.readOnly"
        (ngModelChange)="onChange($event)"
        [placeholder]="schema.placeholder | translate"
        [attr.min]="__isNil(schema.minimum) ? null : schema.minimum"
        [attr.max]="__isNil(schema.maximum) ? null : schema.maximum"
        [step]="schema.type==='integer' ? 1 : (schema.multipleOf || schema.step || 0.01)"
      />
    </mat-form-field>

    <!-- La description est afficher dans form-element-header si on a un title-->
    <!-- TODOs: Should be in mat-hint -->
    <span class="description" *ngIf="schema.description && !schema.title">{{ schema.description }}</span>
  </div>

  <ng-container *ngIf="previous$$ | async; let previous">
    <form-element-previous [previous]="previous" [previous_btn]="formProperty?.value!==previous?.value" (reset_previous)="resetPrevious()">
    </form-element-previous>
  </ng-container>
</ng-container>
