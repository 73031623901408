import { BreakpointObserver } from '@angular/cdk/layout';
import { AfterViewInit, Component, ElementRef, Input, OnDestroy, Renderer2, ViewChild } from '@angular/core';
import { ConsoleLoggerService } from 'app/core/console-logger.service';
import { RunnersService } from 'app/core/runners';
import { isNil } from 'lodash-es';
import { BehaviorSubject, combineLatest, Subscription } from 'rxjs';
import { filter, map, switchMap } from 'rxjs';

@Component({
  selector: 'bg2-modal',
  templateUrl: './bg2-modal.component.html',
  styleUrls: ['./bg2-modal.component.scss'],
})
export class Bg2ModalComponent implements AfterViewInit, OnDestroy {
  @Input()
  public fullscreen = false;

  @ViewChild('modal')
  public modal: ElementRef<HTMLDivElement>;

  // #region -> (component basics)

  private readonly _logger = new ConsoleLoggerService('Bg2ModalComponent', false);

  private _modal_height_sub: Subscription = null;

  constructor(private _runnersService: RunnersService, private _renderer: Renderer2, private _breakpoints: BreakpointObserver) {
    this._modal_height_sub = this.modal_ref$$
      .pipe(
        switchMap(modal =>
          combineLatest([this._runnersService.used_height$$, this._breakpoints.observe(['(max-width: 992px)'])]).pipe(
            map(([used_height, is_matched]) => ({ modal, used_height, is_matched }))
          )
        )
      )
      .subscribe({
        next: ({ modal, used_height, is_matched }) => {
          if (!isNil(used_height)) {
            this._renderer.setStyle(
              modal.nativeElement,
              'height',
              `calc(100% - ${is_matched.matches ? '0px' : '25px'} - ${used_height}px - 10px)`
            );
          } else {
            this._renderer.setStyle(modal.nativeElement, 'height', `calc(100% - ${is_matched.matches ? '0px' : '25px'})`);
          }
        },
      });
  }

  ngAfterViewInit(): void {
    this._modal_ref$$.next(this.modal);
  }

  ngOnDestroy(): void {
    this._modal_height_sub?.unsubscribe();
  }

  // #endregion

  private _modal_ref$$ = new BehaviorSubject<ElementRef<HTMLDivElement>>(null);
  private modal_ref$$ = this._modal_ref$$.asObservable().pipe(filter(modal => !isNil(modal)));
}
