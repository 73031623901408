<fieldset
    *ngFor="let fieldset of formProperty.schema.fieldsets"
    class="title-{{options.title_style}}"
    [class.explicit]="!transparent"
    [class.indent]="indent"
    [class.with-step]="!__isNil(schema.step)">

  <form-element-header [schema]="schema" [title_style]="options.title_style" [hide_description]="true"></form-element-header>
  <div *ngIf="fieldset.description">{{fieldset.description | translate}}</div>
  <div class="fields {{options.variants}}">
    <div *ngFor="let fieldId of fieldset.fields" class="ofields {{ofields_class.join(' ')}}" >
      <sf-form-element
        *ngIf="{val: (formProperty.valueChanges | async)}"
        [formProperty]="formProperty.getProperty(fieldId)">
      </sf-form-element>
      <!-- NOTE: trick to force update on visibility change with the onPush
      cf: https://gitlab.dev.siconsult.fr:9090/beeguard_v2/beeguard2-ng-app/-/issues/435#note_7843 -->
    </div>
  </div>

</fieldset>
