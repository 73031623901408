<div class="ng-mat-select-form-field" *ngIf="visible$$ | async">
  <form-element-header [schema]="schema" [showLabel]="false"></form-element-header>

  <mat-form-field appearance="outline">
    <mat-label>{{ schema?.label | translate }}</mat-label>
    <mat-select
      #select
      *ngIf="!options.readonly"
      [value]="value$$ | async"
      (selectionChange)="value = $event.value"
      [multiple]="options?.multiple"
      [required]="schema?.isRequired"
    >
      <mat-option *ngIf="options?.with_reset">{{ options?.reset_label | translate }}</mat-option>
      <mat-option *ngFor="let select_option of select_options$$ | async" [value]="select_option.value">
        {{ select_option.label | translate }}
      </mat-option>
    </mat-select>

    <input matInput *ngIf="options?.readonly" type="text" [readonly]="true" [value]="value$$ | async" />
  </mat-form-field>
</div>
