import { Component, OnInit, OnDestroy, AfterViewInit, ChangeDetectionStrategy } from '@angular/core';
import { Observable, Subscription, combineLatest, EMPTY, BehaviorSubject, concat, of } from 'rxjs';
import { tap, map, distinctUntilChanged, switchMap, filter } from 'rxjs';

import {
  assign,
  assign as _assign,
  cloneDeep as _cloneDeep,
  isNil,
  isNil as _isNil,
  isUndefined as _isUndefined,
  uniqueId as _uniqueId,
} from 'lodash-es';

import { ControlWidget, FormProperty } from 'ngx-schema-form';

import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';
import { marker as i18n } from '@biesbjerg/ngx-translate-extract-marker';

import { Beeguard2Api } from 'app/core';
import { AppStateService } from 'app/core/app-state.service';
import { DialogsService } from 'app/widgets/dialogs-modals/dialogs.service';
import { parseDate } from 'app/misc/tools';
import { allTrue, distinctUntilRealChanged, replay } from '@bg2app/tools/rxjs';

import { EventDateAndId, robustSearchProperty, trackEventDateAndId } from '../eventforms.helpers';
import { trackValue, TrackOption } from '../eventforms.helpers';

export interface WidgetOptions {
  indent: boolean;
  title_style?: '' | 'large';
  previous?: {
    value: TrackOption;
    from: TrackOption;
  };
  visible_only_for?: 'superadmin'; // TODO: Add owner / visitor.
  reset_btn: boolean;
}

export interface PreviousValue {
  property: {
    path: string;
    current: string;
  };
  value: any;
  from: {
    event_id: number;
    date: Date;
  };
}

@AutoUnsubscribe()
@Component({
  template: '',
  selector: 'bg2-ef-ctrl-widget',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BgControlWidgetComponent extends ControlWidget implements OnInit, OnDestroy, AfterViewInit {
  // #region -> (form validity management)

  /** */
  private valid_sub: Subscription = null;

  /** */
  public valid$$: Observable<boolean> = null;

  /** */
  private _self_valid$$: Observable<boolean> = null;

  /** */
  private _valid_from_root$$: Observable<boolean> = null;

  /** */
  private _valid_from_parent$$: Observable<boolean> = null;

  // #endregion

  public readonly uid = _uniqueId('bg2-control-widget-');

  public options: WidgetOptions = {
    indent: false,
    title_style: '',
    visible_only_for: null,
    reset_btn: false,
  };

  protected default_to: any; // set default timeout ref

  public visible$$: Observable<boolean>;

  private _is_default_value$$ = new BehaviorSubject<boolean>(true);
  public is_default_value$$ = this._is_default_value$$.asObservable().pipe(distinctUntilChanged());
  public set is_default_value(val) {
    this._is_default_value$$.next(val);
  }
  public get is_default_value(): boolean {
    return this._is_default_value$$.getValue();
  }

  // ^ if true, the value "follow" the default value (if dep from ext. entity)
  protected default_value_sub: Subscription;

  // \/ TODO: better handle default/previous value
  // private default_value: any;    // Default value for default "reset"
  private previous_value: any; // Previous value for default "reset"

  public previous$$: Observable<PreviousValue>;

  public value$$: Observable<any>;
  public not_nil_value$$: Observable<boolean>;

  public set value(value: any) {
    this.is_default_value = false;
    this.formProperty.setValue(value, false);
  }

  public get value(): any {
    return this.formProperty.value;
  }

  constructor(protected bg2Api: Beeguard2Api, public appState: AppStateService, protected dialogs: DialogsService) {
    super();
  }

  public resetPrevious(): void {
    this.formProperty.reset(this.previous_value || 0, true);
  }

  public reset(): void {
    this.dialogs
      .confirm(i18n<string>("WIDGETS.EVENT_FORM.CONTROL.Are you sure you don't want to specify?"), {
        onFalseMessage: i18n<string>('ALL.COMMON.No'),
        onTrueMessage: i18n<string>('ALL.COMMON.Yes'),
      })
      .subscribe(agreement => {
        if (agreement) {
          this.formProperty.setValue(null, false);
        }
      });
  }

  ngOnInit(): void {
    this.options = assign(this.options, this.schema.options);

    this.value$$ = concat(of(this.formProperty.value), this.formProperty.valueChanges).pipe(
      map(val => _cloneDeep(val)), // Note: this is needed to ensure distinctUntilRealChanged is not flood
      // but this may cause a perf issue... control widget should handle only terminal types (not complex ones)
      distinctUntilRealChanged(),
      replay()
    );

    this.not_nil_value$$ = this.value$$.pipe(
      map(val => !isNil(val)),
      replay()
    );

    this.visible$$ = of(null).pipe(
      switchMap(() => {
        if (this.options.visible_only_for === 'superadmin') {
          return concat(of(false), this.appState.user$$.pipe(switchMap(user => user?.is_superadmin$$ ?? of(false))));
        } else {
          return of(true);
        }
      }),
      distinctUntilChanged(),
      replay()
    );

    // Set previous value observable
    this.previous$$ = this.trackPrevious().pipe(
      tap(previous => {
        if (!_isNil(previous)) {
          // Set as default value
          this.formProperty.setValue(previous.value, false);
          // Set as reset value
          this.previous_value = previous.value;
        }
      }),
      replay()
    );
    this._initValidObs();
  }

  private _initValidObs(): void {
    const path = this.formProperty.path;
    const sub_path_idx = path.lastIndexOf('/');
    const property_id = sub_path_idx !== -1 ? path.substr(sub_path_idx + 1) : path;

    this._self_valid$$ = concat(of([]), this.formProperty?.errorsChanges ?? of([])).pipe(
      map(errors => (errors || []).length === 0),
      distinctUntilChanged(),
      replay()
    );

    this._valid_from_parent$$ = concat(of([]), this.formProperty?.parent?.errorsChanges ?? of([])).pipe(
      map(errors => {
        // console.log(this.formProperty.path, 'parent', errors);
        // Get parents errors for this
        const myerrors = (errors || []).filter((error: any) => error.path.endsWith(`/${property_id}`));
        // Catch "any of" error
        const any_of_errors = (errors || []).filter((error: any) => error.code === 'ANY_OF_MISSING');
        const any_of_internals = any_of_errors
          .map((error: any) => error.inner)
          .map((inners: any) => inners.filter((inner: any) => inner.code === 'OBJECT_MISSING_REQUIRED_PROPERTY'))
          .map((inners: any) => inners.filter((inner: any) => inner.params.includes(property_id)))
          .filter((inners: any) => inners.length > 0);
        // console.log(this.formProperty.path, any_of_internals);
        // Update validity
        return myerrors.length === 0 && any_of_internals.length === 0;
      }),
      distinctUntilChanged(),
      replay()
    );

    this._valid_from_root$$ = concat(of([]), this.formProperty?.findRoot()?.errorsChanges ?? of([])).pipe(
      map(errors => {
        // console.log(this.formProperty.path, 'root', errors)
        const myerrors = (errors || []).filter((error: any) => error.path === this.formProperty.path);
        return myerrors.length === 0;
      }),
      distinctUntilChanged(),
      replay()
    );

    this.valid$$ = allTrue(this._self_valid$$, this._valid_from_parent$$, this._valid_from_root$$).pipe(distinctUntilChanged(), replay());

    // Auto subscribe valid$$ to ensure shareReplay
    this.unsubscribeValid();
    this.valid_sub = this.valid$$.subscribe();
  }

  ngAfterViewInit(): void {
    super.ngAfterViewInit();
    // We try to load default value if initial value isNil
    this.default_to = setTimeout(() => {
      if (isNil(this.value)) {
        this.computeDefault();
      } else {
        this.is_default_value = false;
      }
    }, 100);
  }

  ngOnDestroy(): void {
    this.unsubscribe();
    if (this.default_to) {
      clearTimeout(this.default_to);
    }
  }

  protected unsubscribe(): void {
    this.unsubscribeValid();
    this.defaultValueUnsubscribe();
  }

  protected unsubscribeValid(): void {
    this.valid_sub?.unsubscribe();
  }

  protected defaultValueUnsubscribe(): void {
    this.default_value_sub?.unsubscribe();
  }

  protected computeDefault(): void {
    if (isNil(this.schema._default)) {
      return;
    }
    const default_conf = this.schema._default;
    this.defaultValueUnsubscribe();
    this.default_value_sub = this.trackValue(this.bg2Api, default_conf, default_conf?.date_path ?? null)
      ?.pipe(
        // tap(value => console.log(this.formProperty.path, 'default val ?', value)),
        switchMap(value => this.is_default_value$$.pipe(map(is_default_value => [value, is_default_value] as [any, boolean]))),
        filter(([value, is_default_value]) => is_default_value && !_isUndefined(value)),
        map(([value, is_default_value]) => value)
      )
      .subscribe(value => {
        this.value = value;
        this.previous_value = value; // TODO: use different attr for previous or default value
      });
  }

  protected trackPrevious(): Observable<PreviousValue> {
    if (isNil(this.options.previous)) {
      return EMPTY;
    }

    // Checks if the required values exists
    if (_isNil(this.options.previous.value)) {
      throw new Error("Cannot track previous value since property'value' is null");
    }
    if (_isNil(this.options.previous.from)) {
      throw new Error("Cannot track previous value since property'from' is null");
    }

    const pvalue = this.trackValue(this.bg2Api, this.options.previous.value, this.options.previous?.value?.date_path ?? null);
    const pfrom = this.trackValue(this.bg2Api, this.options.previous.from, this.options.previous?.from?.date_path ?? null);

    if (isNil(pvalue)) {
      console.error('Impossible to track previous value !');
    }

    if (isNil(pfrom)) {
      console.error('Impossible to track previous from !');
    }

    return combineLatest([pvalue, pfrom]).pipe(
      map((previous: [any, any]) => {
        if (isNil(previous[0]) || isNil(previous[1])) {
          return null;
        }

        const from = {
          event_id: previous[1].event_id as number,
          date: parseDate(previous[1].date),
        };

        const canonical_path = this.formProperty.canonicalPathNotation.split('.');
        const property = {
          path: this.formProperty.canonicalPathNotation,
          current: canonical_path[canonical_path.length - 1],
        };

        return { value: previous[0], from, property };
      })
    );
  }

  protected robustSearchProperty(path: string, possible_root?: FormProperty): FormProperty {
    return robustSearchProperty(this.formProperty, path, possible_root);
  }

  /**
   * Observable over current event date and id
   */
  protected trackEventDateAndId(dpath: string): Observable<EventDateAndId> {
    const date_property = this.robustSearchProperty(dpath);
    if (isNil(date_property)) {
      console.log(this.formProperty.path, `Unable to get date property (path: ${dpath})`);
      return null;
    }
    return trackEventDateAndId(date_property);
  }

  /**
   * Get an observable on a value
   *
   * @param track_confs track configuration schema
   * @param dpath path to date property
   */
  protected trackValue(bg2Api: Beeguard2Api, track_confs: TrackOption, dpath?: string): Observable<any> | null {
    dpath = dpath ? dpath : '/date';
    const event_date_and_id = this.trackEventDateAndId(dpath);
    return trackValue(this.formProperty, bg2Api, track_confs, event_date_and_id);
  }
}
