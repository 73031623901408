import { Observable, combineLatest, map } from 'rxjs';
import { distinctUntilRealChanged } from '../distinct-until-real-changed/distinct-until-real-changed';
import { replay } from '../replay/replay';

/**
 * Combine two or more boolean observables to check if there are all true.
 *
 * @param observables The list of boolean observables to use for checks.
 */
export const allTrue = (...observables: Observable<boolean>[]): Observable<boolean> =>
  combineLatest(observables).pipe(
    map(values => values.every(v => v === true)),
    distinctUntilRealChanged(),
    replay()
  );
