<div class="bg-dialog">
  <dialog-header>
    <span>{{ 'VIEWS.DEVICES.DIALOGS_AND_MODALS.DIALOGS.DEVICE_BULK_BATTERY_CHANGE.Declare new battery change' | translate }}</span>
  </dialog-header>

  <dialog-body>
    <bg2-form-overlay
      [source_schema]="schema"
      [source_model]="form_model?.value$$ | async"
      (when_form_data_update)="form_model.value = $event"
      (when_form_valid_update)="is_valid.value = $event"
    ></bg2-form-overlay>
  </dialog-body>

  <div class="dialog-footer">
    <bg2-button [type]="'cancel'" [message]="'VIEWS.MODALS.FORM.Cancel' | translate" (btn_click)="close(false)"> </bg2-button>

    <bg2-button
      [type]="'action'"
      [icon]="'mdi-content-save'"
      [loading]="is_saving?.value$$ | async"
      [message]="'VIEWS.MODALS.FORM.Save' | translate"
      [disabled]="(is_valid?.value$$ | async) === false || (is_saving?.value$$ | async)"
      (btn_click)="submit()"
    >
    </bg2-button>
  </div>
</div>
