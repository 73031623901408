<div
  *ngIf="summarized$$ | async as summarized"
  class="bg2-snackbar-summary-component snackbar-runner"
  [class.snackbar-runner-error]="summarized?.errors?.length > 0"
>
  <span class="title">
    {{
      ('WIDGETS.WIDGETS_SNACKBAR.COMPONENTS.SNACKBAR_SUMMARY.Update in progress of [number] runs'
        | translate: { number: summarized.total_runners }) + '...'
    }}
  </span>

  <div class="run-progress">
    <mat-progress-bar [value]="(100 * (summarized.total - summarized.remains)) / summarized.total"></mat-progress-bar>
    <span class="progress-value">
      {{ ((100 * (summarized.total - summarized.remains)) / summarized.total).toFixed(0) }} % ({{
        summarized.total - summarized.remains
      }}
      / {{ summarized.total }})
    </span>
  </div>

  <span class="description">
    <ng-container *ngIf="summarized?.errors?.length >= 1">
      {{
        'WIDGETS.WIDGETS_SNACKBAR.COMPONENTS.SNACKBAR_SUMMARY.An error occured during the execution of a runner, please open the detail'
          | translate
      }}...
    </ng-container>
  </span>
</div>
