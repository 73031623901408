<div class="widget form-group bg2superbox-filling-widget">
  <div class="filling-repartition">
    <label class="label-large" *ngIf="schema.label && ((is_loading$$ | async) || (nbs_total$$ | async))">
      {{ schema.label | translate }}
    </label>

    <div *ngIf="is_loading$$ | async" class="loading">
      <hex-loading [textContent]="'ALL.COMMON.Loading' | translate"></hex-loading>
    </div>

    <div class="filling-lines" *ngIf="(nbs_total$$ | async) && (is_loading$$ | async) === false">
      <!-- Initial required line -->
      <div class="filling-line">
        <!-- Supers number -->
        <mat-form-field [appearance]="'outline'">
          <input
            matInput
            type="number"
            [step]="schema.items.properties.nb_superbox.step"
            [value]="formProperty.value[0].nb_superbox"
            [attr.max]="formProperty.value[0].nb_superbox + (formProperty.value[0] ? formProperty.value[0].nb_superbox : 0)"
            [attr.min]="schema.items.properties.nb_superbox.minimum"
            disabled
          />
        </mat-form-field>

        <!-- Word linker -->
        <span class="word-linker">
          {{ 'EVENT.SUPERBOX_HARVEST.supers filled at' | translate:{ super: formProperty.value[0].nb_superbox } }}
        </span>

        <!-- Filling percentage + deletion -->
        <div class="filling-percentage-and-delete">
          <mat-form-field [appearance]="'outline'">
            <mtx-select
              [appendTo]="null"
              [disabled]="true"
              [clearable]="false"
              [items]="(enumerator$$ | async)?.[0]"
              [(ngModel)]="formProperty.value[0].filling"
            >
              <ng-template ng-label-tmp let-item="item">{{item}} %</ng-template>
            </mtx-select>
          </mat-form-field>

          <bg2-button
            class="deletion"
            [type]="'delete'"
            [options]="{ is_thin: true }"
            [icon]="'mdi-close'"
            style="visibility: hidden"
          >
          </bg2-button>
        </div>
      </div>

      <mat-divider style="margin: 5px 0"></mat-divider>

      <!-- Filling lines added by the user -->
      <ng-container *ngFor="let idx of filling_range$$ | async">
        <div
          class="filling-line"

        >
          <!-- Supers number -->
          <mat-form-field [appearance]="'outline'">
            <input
              matInput
              type="number"
              [value]="formProperty.value[idx].nb_superbox"
              (input)="setSupersForFilling(idx, +$event.target.value)"
              [step]="schema.items.properties.nb_superbox.step"
              [attr.min]="schema.items.properties.nb_superbox.minimum"
              [attr.max]="formProperty.value[0].nb_superbox + (formProperty.value[idx] ? formProperty.value[idx].nb_superbox : 0)"
            />
          </mat-form-field>

          <!-- Word linker -->
          <span class="word-linker">
            {{ 'EVENT.SUPERBOX_HARVEST.supers filled at' | translate:{ super: formProperty.value[0].nb_superbox } }}
          </span>

          <!-- Filling percentage + deletion -->
          <div class="filling-percentage-and-delete">
            <mat-form-field [appearance]="'outline'">
              <mtx-select
                [appendTo]="null"
                [items]="(enumerator$$ | async)?.[idx]"
                [clearable]="false"
                (change)="setFilling(idx, +$event)"
                [(ngModel)]="formProperty.value[idx].filling"
              >
                <ng-template ng-label-tmp let-item="item">{{item}} %</ng-template>
              </mtx-select>
            </mat-form-field>

            <bg2-button
              class="deletion"
              [type]="'delete'"
              [icon]="'mdi-close'"
              [options]="{ is_thin: true }"
              (btn_click)="removeFillingRepartition(idx)"
            >
            </bg2-button>
          </div>
        </div>

        <mat-divider *ngIf="idx !== formProperty.value.length - 1" style="margin: 5px 0"></mat-divider>
      </ng-container>
    </div>

    <div class="actions" *ngIf="(nbs_total$$ | async) && (is_loading$$ | async) === false">
      <bg2-button
        class="w-100"
        [type]="'action'"
        [icon]="'mdi-percent'"
        [message]="'EVENT.SUPERBOX_HARVEST.Precise superbox filling' | translate"
        [disabled]="!(formProperty.value[0].nb_superbox >= schema.items.properties.nb_superbox.default && getAvailable().length > 0)"
        (btn_click)="addFillingRepartition()"
      >
      </bg2-button>
    </div>
  </div>
</div>
