import { Component, ChangeDetectionStrategy } from '@angular/core';

import { Observable, combineLatest, BehaviorSubject, merge, of, concat } from 'rxjs';
import { debounceTime, distinctUntilChanged, finalize, map, switchMap, take, tap, withLatestFrom } from 'rxjs/operators';

import { isNil, cloneDeep, forIn } from 'lodash-es';

import { marker as i18n } from '@biesbjerg/ngx-translate-extract-marker';

import { DRDevice } from 'app/models';

import { AbstractDialogComponent, AbstractDialogParams } from 'app/widgets/dialogs-modals/abstract-dialog.component';
import { distinctUntilRealChanged, replay } from '@bg2app/tools/rxjs';
import { DeviceApi } from 'app/core';
import { ConsoleLoggerService } from 'app/core/console-logger.service';
import { ISchema } from 'ngx-schema-form';

import { DEVICE_ORDERS } from 'app/models/devices';

import { DevicesDialogParams } from '../../devices-dialog-params';


@Component({
  selector: 'bg2-devices-order-dialog',
  templateUrl: './devices-order.dialog.html',
  styleUrls: ['./devices-order.dialog.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DevicesOrderDialogComponent extends AbstractDialogComponent<DevicesDialogParams, any> {

  DEFAULT_SCHEMA = {
    type: 'object',
    required: ["devices"],
    properties: {
      devices: {
        label: i18n('VIEWS.DEVICES.OTA-DIALOG.Selected devices'),
        type: 'array',
        widget: 'ng-mat-select',
        options: {
          multiple: true
        },
        minItems: 1,
        default: [] as any[],
        items: {
          type: 'number',
          oneOf: [
            // {
            //   enum: ['WG'],
            //   label: 'WG'
            // },
          ] as any[]
        }
      },
      order: {
        label: i18n('VIEWS.DEVICES.ORDER-DIALOG.Order'),
        type: 'number',
        widget: 'ng-mat-select',
        options: {
          multiple: false
        },
        oneOf: [
          // {
          //   enum: ['WG'],
          //   label: 'WG'
          // },
        ] as any[]
      },
    }
  };

  protected _logger = new ConsoleLoggerService(this.constructor.name, true);

  public devices$$: Observable<DRDevice[]> = this.input_params$$.pipe(
    map(params => params.devices),
    replay()
  );

  private _form_model$$ = new BehaviorSubject<any>({});
  public form_model$$ = this._form_model$$.pipe(
    debounceTime(100),
    distinctUntilRealChanged(),
    replay(),
  );

  public schema$$ = this.devices$$.pipe(
    map(devices => {
      const schema = cloneDeep(this.DEFAULT_SCHEMA);
      schema.properties.devices.items.oneOf = devices.map(device => ({
        enum: [device.imei],
        label: `${device.imei} (${device.name})`
      }));
      schema.properties.devices.default = devices.map(device => device.imei);

      forIn(DEVICE_ORDERS, (value, key) => {
        let order_val_str = value.toString(16).toUpperCase();
        const padding = 2;
        while (order_val_str.length < padding) {
          order_val_str = "0" + order_val_str;
        }
        schema.properties.order.oneOf.push({
          enum: [value],
          label: `${key} (0x${order_val_str})`
        })
      });
      schema.properties.order.oneOf.push({
        enum: [0],
        label: 'Raz order'
      })
      return schema as ISchema;
    }),
    replay()
  );

  private _form_valid$$ = new BehaviorSubject<boolean>(false);
  public form_valid$$ = this._form_valid$$.pipe(
    distinctUntilChanged(),
    replay()
  );

  private _submit_progress_total$$ = new BehaviorSubject<number>(null);
  public submit_progress_total$$ = this._submit_progress_total$$.pipe(
    distinctUntilChanged(),
    replay()
  );

  private _submit_progress_current$$ = new BehaviorSubject<number>(null);
  public submit_progress_current$$ = this._submit_progress_current$$.pipe(
    distinctUntilChanged(),
    replay()
  );

  public submit_progress_percent$$ = combineLatest([this.submit_progress_total$$, this.submit_progress_current$$]).pipe(
    map(([total, current]) => !total ? 0 : current / total),
    replay()
  );

  public setFormValid(val: any): void {
    this._form_valid$$.next(val);
  }

  constructor(private device_api: DeviceApi) {
    super();
  }

  public onFormModelChanged(event: any) {
    this._logger.debug(event);
    this._form_model$$.next(event.value);
  }

  public logErrors(errors: any) {
    if (errors) {
      this._logger.log_error(errors);
    }
  }


  public submit(): void {
    this.form_model$$.pipe(
      take(1),
      switchMap(model => {
        const order_data = {
          dids: model.devices,
          order: model.order || 0,
        }
        return this.device_api.create_bulk_order$(order_data)
      })
    ).subscribe(
      () => {},
      () => {},
      () => {
        this.close(true);
      },
    )
  }

  public close(value: boolean): void {
    this.complete(value);
  }

}
