import {
  ApplicationRef,
  ComponentFactoryResolver,
  ComponentRef,
  EmbeddedViewRef,
  Injectable,
  Injector,
  Type,
} from '@angular/core';
import { Dictionary } from 'app/typings/core/interfaces';

import { isNil, keys } from 'lodash-es';

@Injectable({
  providedIn: 'root'
})
export class DomInjectionService {

  constructor(
    private applicationRef: ApplicationRef,
    private componentFactoryResolver: ComponentFactoryResolver,
    private injector: Injector
  ) {}

  /**
   * Appends a component to an adjacent location.
   */
  appendComponent<T>(
    componentClass: Type<T>,
    options: any = {}
  ): ComponentRef<T> {
    const parent = this.getContainerElement();
    // instantiate component to load
    const component_factory = this.componentFactoryResolver.resolveComponentFactory(componentClass);
    const component_ref = component_factory.create(this.injector);

    // project the options passed to the component instance
    this.projectComponentInputs(component_ref, options);

    // attach view for dirty checking
    this.applicationRef.attachView(component_ref.hostView);

    // append component to location in the DOM where we want it to be rendered
    const component_root_node = this.getComponentRootNode(component_ref);
    parent.appendChild(component_root_node);

    return component_ref;
  }

  public destroyComponent<T>(component_ref: ComponentRef<T>): void {
    const component_root_node = this.getComponentRootNode(component_ref);
    const parent = this.getContainerElement();

    setTimeout(() => {
      if (parent.contains(component_root_node)) {
        parent.removeChild(component_root_node);
      }
  
      this.applicationRef.detachView(component_ref.hostView);
      component_ref.destroy();
    }, 1);
  }

  /**
   * Gets the html element for a component ref.
   */
  private getComponentRootNode(componentRef: ComponentRef<any>): Element {
    return (componentRef.hostView as EmbeddedViewRef<any>).rootNodes[0] as Element;
  }

  /**
   * Gets the container element.
   */
  private getContainerElement(): Element {
    return document.body;
  }

  /**
   * Projects the inputs onto the component.
   */
  private projectComponentInputs<T>(component: ComponentRef<T>, options: any): ComponentRef<T> {
    keys(options).forEach(key => {
      const value: any = options[key];
      (component.instance as any)[key] = value;
    })

    return component;
  }


  private _overlay: Dictionary<HTMLDivElement> = {};
  private _overlay_opened: Dictionary<number> = {};

  public addOverlay(class_name: string) {
    this._overlay_opened[class_name] = (this._overlay_opened[class_name] || 0) + 1;

    if (isNil(this._overlay[class_name])) {
      this._overlay[class_name] = document.createElement('div');
      this._overlay[class_name].classList.add(...['bg2-overlay', class_name]);
      document.body.appendChild(this._overlay[class_name]);
    }

  }

  public removeOverlay(class_name: string) {
    this._overlay_opened[class_name] = this._overlay_opened[class_name] - 1;

    if (this._overlay_opened[class_name] === 0) {
      document.body.removeChild(this._overlay[class_name]);
      document.body.classList.remove('no-scroll');
      this._overlay[class_name] = null;
    }
  }

  public preventBodyScroll() {
    // Update body with scroll disabling.
    document.body.classList.add('no-scroll');
  }

  public releaseBodyScroll() {
    document.body.classList.remove('no-scroll');
  }

}
