import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { BehaviorSubject, catchError, combineLatest, debounceTime, map, Observable, of, switchMap, take, tap, throwError } from 'rxjs';
import { anyTrue, replay, waitForNotNilProperties, waitForNotNilValue } from '@bg2app/tools/rxjs';

import { isNil } from 'lodash-es';

import { ZohoCRMApiService } from 'app/core/services/zoho/zoho-crm-api.service';
import { Exploitation, SimpleSetterGetter } from 'app/models';
import { get_i18n_for_zoho_error, ZohoCRMAccount, ZohoCRMModuleName, ZohoError } from 'app/models/zoho';
import { ZohoDeskAccount } from 'app/models/zoho/desk';

@Component({
  selector: 'bg2-zoho-check-bind-exploitation-id',
  templateUrl: './zoho-check-bind-exploitation-id.component.html',
  styleUrls: ['zoho-check-bind-exploitation-id.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ZohoCheckBindedExploitationIdComponent {
  // #region -> (component basics)

  constructor(private readonly _zohoCrmApiService: ZohoCRMApiService) {}

  // #endregion

  // #region -> (exploitation)

  @Input()
  public set exploitation(exploitation: Exploitation) {
    this._exploitation$$.next(exploitation);
  }

  /** */
  private _exploitation$$ = new BehaviorSubject<Exploitation>(null);

  /** */
  private exploitation$$ = this._exploitation$$.asObservable();

  /** */
  public zoho_account_id$$ = this.exploitation$$.pipe(
    switchMap(exploitation => exploitation?.crm_id$$ ?? of(null)),
    debounceTime(200)
  );

  /** */
  public local_exploitation_id$$: Observable<number> = this.exploitation$$.pipe(switchMap(exploitation => exploitation?.id$$ ?? of(null)));

  /** */
  public local_exploitation_name$$ = this.exploitation$$.pipe(switchMap(exploitation => exploitation?.name$$ ?? of(null)));

  /** */
  public local_exploitation_crm_id$$ = this.exploitation$$.pipe(switchMap(exploitation => exploitation.crm_id$$));

  // #endregion

  @Input()
  public set should_refetch_zoho_data(should_refetch_zoho_data: boolean) {
    this.refetch_zoho_data.value = should_refetch_zoho_data;
  }

  /** */
  private refetch_zoho_data = new SimpleSetterGetter(false);

  // #region -> (zoho CRM account)

  /** */
  public zoho_crm_account$$ = this.exploitation$$.pipe(
    switchMap(exploitation => exploitation.crm_id_or_error$$),
    switchMap(zoho_crm_id_or_error => {
      if (zoho_crm_id_or_error instanceof Error) {
        return of(zoho_crm_id_or_error);
      }

      return this._zohoCrmApiService.fetch_record$(ZohoCRMModuleName.Accounts, zoho_crm_id_or_error, []);
    })
  );

  // #endregion

  // #region -> (zoho DESK account)

  /** */
  public zoho_desk_account$$ = this.exploitation$$.pipe(switchMap(exploitation => exploitation.zoho_desk_account$$));

  // #endregion

  // #region -> (validation pipeline)

  /** */
  private _checking_valid_value$$ = new BehaviorSubject<boolean>(false);

  /** */
  public checking_valid_value$$ = this._checking_valid_value$$.asObservable();

  /** */
  public is_zoho_account_expl_id_invalid$$ = this.zoho_crm_account$$.pipe(
    switchMap(zoho_crm_account_or_error => {
      if (zoho_crm_account_or_error) {
        if (zoho_crm_account_or_error instanceof Error) {
          return of(zoho_crm_account_or_error);
        }

        return this.local_exploitation_id$$.pipe(
          switchMap(local_exploitation_id => {
            if (isNil(zoho_crm_account_or_error?.exploitation_id)) {
              return throwError(() => new Error(get_i18n_for_zoho_error(ZohoError.MISSING_EXPL_ID_IN_CRM_ACCOUNT)));
            }

            if (local_exploitation_id?.toString() !== zoho_crm_account_or_error?.exploitation_id) {
              return throwError(() => new Error(get_i18n_for_zoho_error(ZohoError.LOCAL_EXPL_ID_DIFFERENT_FROM_CRM_ACCOUNT_EXPL_ID)));
            }

            return of(null);
          }),
          catchError((error: unknown) => of(<Error>error))
        );
      }
    })
  );

  /** */
  public final_validation$$ = this.refetch_zoho_data.value$$.pipe(
    switchMap(() => this.is_zoho_account_expl_id_invalid$$),
    replay()
  );

  // #endregion

  // #region -> (update zoho-Account)

  /** */
  private _is_updating_exploitation_id$$ = new BehaviorSubject(false);

  /** */
  public is_updating_exploitation_id$$ = this._is_updating_exploitation_id$$.asObservable();

  /** */
  public update_zoho_exploitation_id(): void {
    this._is_updating_exploitation_id$$.next(true);

    combineLatest({
      zoho_account_id: this.zoho_account_id$$,
      local_exploitation_id: this.local_exploitation_id$$,
    })
      .pipe(
        waitForNotNilProperties(),
        switchMap(({ zoho_account_id, local_exploitation_id }) =>
          this._zohoCrmApiService.update_account$(zoho_account_id, {
            exploitation_id: local_exploitation_id?.toString(),
          })
        ),
        take(1)
      )
      .subscribe({
        complete: () => {
          this.refetch_zoho_data.value = true;
          this._is_updating_exploitation_id$$.next(false);
        },
      });
  }

  // #endregion

  /** */
  public has_any_loading$$ = anyTrue(this.checking_valid_value$$, this._is_updating_exploitation_id$$);

  public asType(value: any, type: 'desk_account'): ZohoDeskAccount;
  public asType(value: any, type: 'crm_account'): ZohoCRMAccount;
  public asType(value: any, type: 'crm_account' | 'desk_account'): any {
    if (value instanceof ZohoCRMAccount) {
      return value;
    }

    if (value instanceof ZohoDeskAccount) {
      return value;
    }

    return null;
  }

  /** */
  public isError(value: any): Error {
    return value instanceof Error ? value : null;
  }
}
