import { map, Observable, switchMap, take } from 'rxjs';

import { marker as i18n } from '@biesbjerg/ngx-translate-extract-marker';

import { TranslateService } from '@ngx-translate/core';
import { isEmpty, isNil } from 'lodash-es';

import { Beeguard2Api } from 'app/core';
import { Dictionary } from 'app/typings/core/interfaces';
import { distinctUntilRealChanged, replay, robustCombineLatest } from '@bg2app/tools/rxjs';

import { Event, I18NParams, ApplyToEntity, ApplyTo, IEventGlobalSchema } from './Event';
import { Entity, EntityStateValue, Hive, Location } from '../entities';
import { Apiary } from '../entities/04_apiary/classes/Apiary';

import { ExportHeader, ExportedRow } from '../export';

interface IMigratoryApplyTo extends ApplyTo {
  apiary: ApplyToEntity;
  apiary_dest?: ApplyToEntity;
  location_dest: ApplyToEntity;
  warehouse_dest: ApplyToEntity;
  location_source: ApplyToEntity;
  warehouse_source: ApplyToEntity;
  migrated_hives?: ApplyToEntity[];
}

export class Migratory extends Event<IMigratoryApplyTo> {
  public vconf = {
    update_modal: 'migratory',
  };

  get partial(): boolean {
    return this.data.partial || false;
  }

  constructor(protected bg2Api: Beeguard2Api) {
    super(bg2Api);
    this.type = 'migratory';
  }

  public migrated_apiary$$: Observable<Apiary> = this.entities$$.pipe(
    map(entities => entities.apiary as Apiary),
    replay()
  );

  public location_source$$: Observable<Location> = this.entities$$.pipe(
    map(entities => entities.location_source as Location),
    replay()
  );

  public apiary_dest$$: Observable<Apiary> = this.entities$$.pipe(
    map(entities => entities.apiary_dest as Apiary),
    replay()
  );

  public location_dest$$: Observable<Location> = this.entities$$.pipe(
    map(entities => entities.location_dest as Location),
    replay()
  );

  public migrated_hives_and_nucs$$: Observable<Hive[]> = this.entities$$.pipe(
    map(entities => entities.migrated_hives as Hive[]),
    replay()
  );

  public migrated_hives$$ = this.migrated_hives_and_nucs$$.pipe(
    map(hives_and_nucs => (hives_and_nucs || []).filter(hive_or_nuc => hive_or_nuc.htype === 'hive')),
    map(migrated_hives => (isEmpty(migrated_hives) ? null : migrated_hives ?? null)),
    replay()
  );

  public migrated_hives_names$$: Observable<{ name: string; id: number }[]> = this.migrated_hives$$.pipe(
    switchMap(migrated_hives => {
      const array_of_named_id$$ = (migrated_hives || []).map(hive =>
        robustCombineLatest([hive.name$$, hive.id$$]).pipe(map(([name, id]: [string, number]) => ({ name, id })))
      );

      return robustCombineLatest(array_of_named_id$$);
    }),
    map(names_with_id => (names_with_id || [])?.filter(name_with_id => !isNil(name_with_id?.name))),
    map(names_with_id => (isEmpty(names_with_id) ? null : names_with_id ?? null)),
    distinctUntilRealChanged()
  );

  public migrated_nucs$$ = this.migrated_hives_and_nucs$$.pipe(
    map(hives_and_nucs => (hives_and_nucs || []).filter(hive_or_nuc => hive_or_nuc.htype === 'nuc')),
    map(migrated_nucs => (isEmpty(migrated_nucs) ? null : migrated_nucs ?? null)),
    replay()
  );

  public migrated_nucs_names$$: Observable<{ name: string; id: number }[]> = this.migrated_nucs$$.pipe(
    switchMap(migrated_nucs => {
      const array_of_named_id$$ = (migrated_nucs || []).map(nuc =>
        robustCombineLatest([nuc.name$$, nuc.id$$]).pipe(map(([name, id]: [string, number]) => ({ name, id })))
      );

      return robustCombineLatest(array_of_named_id$$);
    }),
    map(names_with_id => (names_with_id || [])?.filter(name_with_id => !isNil(name_with_id?.name))),
    map(names_with_id => (isEmpty(names_with_id) ? null : names_with_id ?? null)),
    distinctUntilRealChanged()
  );

  public nb_hives$$: Observable<number> = this.migrated_hives$$.pipe(
    map(migrated_hives => migrated_hives?.length || 0),
    distinctUntilRealChanged()
  );

  public nb_nuc$$: Observable<number> = this.migrated_nucs$$.pipe(
    map(migrated_nucs => migrated_nucs?.length || 0),
    distinctUntilRealChanged()
  );

  private _hive_set_apiary(hive_state: EntityStateValue, apiary: Apiary): EntityStateValue {
    hive_state.apiary_id = apiary?.id;
    hive_state.apiary_id_since = this.since();
    return hive_state;
  }

  private _apiary_set_location(apiary_state: EntityStateValue, location: Location): EntityStateValue {
    apiary_state.location_id = location?.id;
    apiary_state.location_id_since = this.since();
    return apiary_state;
  }

  private _location_set_apiary(location_state: EntityStateValue, apiary: Apiary): EntityStateValue {
    location_state.apiary_id = apiary?.id;
    location_state.apiary_id_since = this.since();
    if (isNil(apiary?.id)) {
      location_state.empty_since = this.since();
    } else {
      location_state.empty_since = null;
    }
    return location_state;
  }

  protected _merge_apiaries(source: EntityStateValue, dest: EntityStateValue, hives: EntityStateValue[]) {
    dest.nb_hives += source.nb_hives || 0;
    dest.nb_nuc += source.nb_nuc || 0;
    dest.hive_ids = dest.hive_ids || [];
    (source.hive_ids || []).forEach((hid: number) => {
      dest.hive_ids.push(hid);
    });
  }

  protected _applyLocally(entities: Dictionary<Entity | Entity[]>): Event {
    this._logger.ghost('Apply migratory');
    const apiary = entities.apiary as Apiary;
    const apiary_state = apiary.getPreviousLocalState(this.date, this.id);

    const location_source = entities.location_source as Location;
    const location_source_state = location_source.getPreviousLocalState(this.date, this.id);

    const apiary_dest = entities.apiary_dest as Apiary;

    const location_dest = entities.location_dest as Location;
    const location_dest_state = location_dest.getPreviousLocalState(this.date, this.id);

    const migrated_hives = (entities.migrated_hives || []) as Hive[];
    const partial = this.data.partial || false;

    if (partial) {
      throw Error('Not implemented yet');
    } else if (apiary_dest) {
      const apiary_dest_state = apiary_dest.getPreviousLocalState(this.date, this.id);
      migrated_hives.forEach(hive => {
        const hive_state = hive.getPreviousLocalState(this.date, this.id);
        // reset hive apiary to ensure the date is updated
        this._hive_set_apiary(hive_state, apiary_dest);
        hive.storeLocalState(this.date, this.id, hive_state);
      });
      this._merge_apiaries(apiary_state, apiary_dest_state, []);
      // this._location_set_apiary(location_dest_state, apiary_dest);
      this._location_set_apiary(location_source_state, null);
      apiary_dest.storeLocalState(this.date, this.id, apiary_dest_state);
      apiary.storeLocalState(this.date, this.id, apiary_state);
      location_source.storeLocalState(this.date, this.id, location_source_state);
      location_dest.storeLocalState(this.date, this.id, location_dest_state);
    } else {
      migrated_hives.forEach(hive => {
        const hive_state = hive.getPreviousLocalState(this.date, this.id);
        // reset hive apiary to ensure the date is updated
        this._hive_set_apiary(hive_state, apiary);
        hive.storeLocalState(this.date, this.id, hive_state);
      });
      this._apiary_set_location(apiary_state, location_dest);
      this._location_set_apiary(location_dest_state, apiary);
      this._location_set_apiary(location_source_state, null);
      apiary.storeLocalState(this.date, this.id, apiary_state);
      location_source.storeLocalState(this.date, this.id, location_source_state);
      location_dest.storeLocalState(this.date, this.id, location_dest_state);
    }
    return this;
  }

  protected getDescKey(role: string, schema: IEventGlobalSchema, i18nData: I18NParams): Observable<string> {
    return super.getDescKey(role, schema, i18nData).pipe(
      map(desc_key => {
        const apiary_dest = i18nData.entities.apiary_dest as Apiary;
        if (role === 'migrated_hives') {
          if (apiary_dest) {
            return i18n(
              "EVENT.MIGRATORY.Migration from [entities->location_source->link] (apiary '[entities->apiary->name]') to '[entities->location_dest->link]' (to apiary '[entities->apiary_dest->name]')"
            );
          } else {
            return i18n(
              "EVENT.MIGRATORY.Migration from [entities->location_source->link] to [entities->location_dest->link] (Full migration of apiary '[entities->apiary->name]')"
            );
          }
        }
        if (!this.partial) {
          // TODO case of apiary in case of merge
          // if (role === 'apiary') {
          // }

          // if (role === 'apiary_dest') {
          // }

          // if (role === 'location_source') {
          // }

          // if (role === 'location_dest') {
          // }

          // if (role === 'warehouse_source') {
          // }
        } else {
          // partial migratory
          const nb_migrated_nucs = i18nData?.data?.nb_nuc ?? 0;
          const nb_migrated_hives = i18nData?.data?.nb_hives ?? 0;
          const move_all_devices = i18nData?.data?.move_all_devices ?? false;

          const has_only_migrated_nucs = nb_migrated_nucs > 0 && nb_migrated_hives <= 0;
          const has_only_migrated_hives = nb_migrated_hives > 0 && nb_migrated_nucs <= 0;
          const has_migrated_hives_and_nucs = nb_migrated_hives > 0 && nb_migrated_nucs > 0;

          const new_apiary_dest = apiary_dest.initial_setup_event_id === this.id;

          if (role === 'apiary') {
            if (new_apiary_dest) {
              if (move_all_devices) {
                if (has_only_migrated_hives) {
                  return i18n(
                    "EVENT.MIGRATORY.Partial migration to [entities->location_dest->link] (moved [data->nb_hives] hives and weather station to new apiary '[entities->apiary_dest->name]')"
                  );
                }

                if (has_only_migrated_nucs) {
                  return i18n(
                    "EVENT.MIGRATORY.Partial migration to [entities->location_dest->link] (moved [data->nb_nuc] nuc and weather station to new apiary '[entities->apiary_dest->name]')"
                  );
                }

                if (has_migrated_hives_and_nucs) {
                  return i18n(
                    "EVENT.MIGRATORY.Partial migration to [entities->location_dest->link] (moved [data->nb_hives] hives, [data->nb_nuc] nuc and weather station to new apiary '[entities->apiary_dest->name]')"
                  );
                }
              }

              if (!move_all_devices) {
                if (has_only_migrated_hives) {
                  return i18n(
                    "EVENT.MIGRATORY.Partial migration to [entities->location_dest->link] (moved [data->nb_hives] hives to new apiary '[entities->apiary_dest->name]')"
                  );
                }

                if (has_only_migrated_nucs) {
                  return i18n(
                    "EVENT.MIGRATORY.Partial migration to [entities->location_dest->link] (moved [data->nb_nuc] nuc to new apiary '[entities->apiary_dest->name]')"
                  );
                }

                if (has_migrated_hives_and_nucs) {
                  return i18n(
                    "EVENT.MIGRATORY.Partial migration to [entities->location_dest->link] (moved [data->nb_hives] hives and [data->nb_nuc] nuc to new apiary '[entities->apiary_dest->name]')"
                  );
                }
              }
            } else {
              if (move_all_devices) {
                if (has_only_migrated_hives) {
                  return i18n(
                    "EVENT.MIGRATORY.Partial migration to [entities->location_dest->link] (moved [data->nb_hives] hives and weather station to apiary '[entities->apiary_dest->name]')"
                  );
                }

                if (has_only_migrated_nucs) {
                  return i18n(
                    "EVENT.MIGRATORY.Partial migration to [entities->location_dest->link] (moved [data->nb_nuc] nuc and weather station to apiary '[entities->apiary_dest->name]')"
                  );
                }

                if (has_migrated_hives_and_nucs) {
                  return i18n(
                    "EVENT.MIGRATORY.Partial migration to [entities->location_dest->link] (moved [data->nb_hives] hives, [data->nb_nuc] nuc and weather station to apiary '[entities->apiary_dest->name]')"
                  );
                }
              }

              if (!move_all_devices) {
                if (has_only_migrated_hives) {
                  return i18n(
                    "EVENT.MIGRATORY.Partial migration to [entities->location_dest->link] (moved [data->nb_hives] hives to apiary '[entities->apiary_dest->name]')"
                  );
                }

                if (has_only_migrated_nucs) {
                  return i18n(
                    "EVENT.MIGRATORY.Partial migration to [entities->location_dest->link] (moved [data->nb_nuc] nuc to apiary '[entities->apiary_dest->name]')"
                  );
                }

                if (has_migrated_hives_and_nucs) {
                  return i18n(
                    "EVENT.MIGRATORY.Partial migration to [entities->location_dest->link] (moved [data->nb_hives] hives and [data->nb_nuc] nuc to apiary '[entities->apiary_dest->name]')"
                  );
                }
              }
            }
          }

          if (role === 'apiary_dest') {
            if (new_apiary_dest) {
              if (move_all_devices) {
                if (has_only_migrated_hives) {
                  return i18n(
                    "EVENT.MIGRATORY.Setup apiary, Partial migration from [entities->location_source->link] (moved [data->nb_hives] hives and weather station from apiary '[entities->apiary->link]')"
                  );
                }

                if (has_only_migrated_nucs) {
                  return i18n(
                    "EVENT.MIGRATORY.Setup apiary, Partial migration from [entities->location_source->link] (moved [data->nb_nuc] nuc and weather station from apiary '[entities->apiary->link]')"
                  );
                }

                if (has_migrated_hives_and_nucs) {
                  return i18n(
                    "EVENT.MIGRATORY.Setup apiary, Partial migration from [entities->location_source->link] (moved [data->nb_hives] hives, [data->nb_nuc] nuc and weather station from apiary '[entities->apiary->link]')"
                  );
                }
              }

              if (!move_all_devices) {
                if (has_only_migrated_hives) {
                  return i18n(
                    "EVENT.MIGRATORY.Setup apiary, Partial migration from [entities->location_source->link] (moved [data->nb_hives] hives from apiary '[entities->apiary->link]')"
                  );
                }

                if (has_only_migrated_nucs) {
                  return i18n(
                    "EVENT.MIGRATORY.Setup apiary, Partial migration from [entities->location_source->link] (moved [data->nb_nuc] nuc from apiary '[entities->apiary->link]')"
                  );
                }

                if (has_migrated_hives_and_nucs) {
                  return i18n(
                    "EVENT.MIGRATORY.Setup apiary, Partial migration from [entities->location_source->link] (moved [data->nb_hives] hives and [data->nb_nuc] nuc from apiary '[entities->apiary->link]')"
                  );
                }
              }
            } else {
              if (move_all_devices) {
                if (has_only_migrated_hives) {
                  return i18n(
                    "EVENT.MIGRATORY.Partial migration from [entities->location_source->link] (moved [data->nb_hives] hives and weather station from apiary '[entities->apiary->name]')"
                  );
                }

                if (has_only_migrated_nucs) {
                  return i18n(
                    "EVENT.MIGRATORY.Partial migration from [entities->location_source->link] (moved [data->nb_nuc] nuc and weather station from apiary '[entities->apiary->name]')"
                  );
                }

                if (has_migrated_hives_and_nucs) {
                  return i18n(
                    "EVENT.MIGRATORY.Partial migration from [entities->location_source->link] (moved [data->nb_hives] hives, [data->nb_nuc] nuc and weather station from apiary '[entities->apiary->name]')"
                  );
                }
              }

              if (!move_all_devices) {
                if (has_only_migrated_hives) {
                  return i18n(
                    "EVENT.MIGRATORY.Partial migration from [entities->location_source->link] (moved [data->nb_hives] hives from apiary '[entities->apiary->name]')"
                  );
                }

                if (has_only_migrated_nucs) {
                  return i18n(
                    "EVENT.MIGRATORY.Partial migration from [entities->location_source->link] (moved [data->nb_nuc] nuc from apiary '[entities->apiary->name]')"
                  );
                }

                if (has_migrated_hives_and_nucs) {
                  return i18n(
                    "EVENT.MIGRATORY.Partial migration from [entities->location_source->link] (moved [data->nb_hives] hives and [data->nb_nuc] nuc from apiary '[entities->apiary->name]')"
                  );
                }
              }
            }
          }

          if (role === 'location_source') {
            if (new_apiary_dest) {
              return i18n(
                "EVENT.MIGRATORY.Partial migration to [entities->location_dest->link] (creation of apiary '[entities->apiary_dest->name]')"
              );
            } else {
              return i18n(
                "EVENT.MIGRATORY.Partial migration to [entities->location_dest->link] (merge with apiary '[entities->apiary_dest->name]')"
              );
            }
          }

          if (role === 'location_dest') {
            if (new_apiary_dest) {
              if (move_all_devices) {
                if (has_only_migrated_hives) {
                  return i18n(
                    "EVENT.MIGRATORY.Setup of apiary '[entities->apiary_dest->name]', partial migration from [entities->location_source->link] (moved [data->nb_hives] hives and weather station from apiary '[entities->apiary_dest->name]')"
                  );
                }

                if (has_only_migrated_nucs) {
                  return i18n(
                    "EVENT.MIGRATORY.Setup of apiary '[entities->apiary_dest->name]', partial migration from [entities->location_source->link] (moved [data->nb_nuc] nuc and weather station from apiary '[entities->apiary_dest->name]')"
                  );
                }

                if (has_migrated_hives_and_nucs) {
                  return i18n(
                    "EVENT.MIGRATORY.Setup of apiary '[entities->apiary_dest->name]', partial migration from [entities->location_source->link] (moved [data->nb_hives] hives, [data->nb_nuc] nuc and weather station from apiary '[entities->apiary_dest->name]')"
                  );
                }
              }

              if (!move_all_devices) {
                if (has_only_migrated_hives) {
                  return i18n(
                    "EVENT.MIGRATORY.Setup of apiary '[entities->apiary_dest->name]', partial migration from [entities->location_source->link] (moved [data->nb_hives] hives from apiary '[entities->apiary_dest->name]')"
                  );
                }

                if (has_only_migrated_nucs) {
                  return i18n(
                    "EVENT.MIGRATORY.Setup of apiary '[entities->apiary_dest->name]', partial migration from [entities->location_source->link] (moved [data->nb_nuc] nuc from apiary '[entities->apiary_dest->name]')"
                  );
                }

                if (has_migrated_hives_and_nucs) {
                  return i18n(
                    "EVENT.MIGRATORY.Setup of apiary '[entities->apiary_dest->name]', partial migration from [entities->location_source->link] (moved [data->nb_hives] hives and [data->nb_nuc] nuc from apiary '[entities->apiary_dest->name]')"
                  );
                }
              }
            } else {
              if (move_all_devices) {
                if (has_only_migrated_hives) {
                  return i18n(
                    "EVENT.MIGRATORY.Partial migration from [entities->location_source->link] (moved [data->nb_hives] hives and weather station from apiary '[entities->apiary_dest->name]' to apiary '[entities->apiary_dest->name]')"
                  );
                }

                if (has_only_migrated_nucs) {
                  return i18n(
                    "EVENT.MIGRATORY.Partial migration from [entities->location_source->link] (moved [data->nb_nuc] nuc and weather station from apiary '[entities->apiary_dest->name]' to apiary '[entities->apiary_dest->name]')"
                  );
                }

                if (has_migrated_hives_and_nucs) {
                  return i18n(
                    "EVENT.MIGRATORY.Partial migration from [entities->location_source->link] (moved [data->nb_hives] hives, [data->nb_nuc] nuc and weather station from apiary '[entities->apiary_dest->name]' to apiary '[entities->apiary_dest->name]')"
                  );
                }
              }

              if (!move_all_devices) {
                if (has_only_migrated_hives) {
                  return i18n(
                    "EVENT.MIGRATORY.Partial migration from [entities->location_source->link] (moved [data->nb_hives] hives from apiary '[entities->apiary_dest->name]' to apiary '[entities->apiary_dest->name]')"
                  );
                }

                if (has_only_migrated_nucs) {
                  return i18n(
                    "EVENT.MIGRATORY.Partial migration from [entities->location_source->link] (moved [data->nb_nuc] nuc from apiary '[entities->apiary_dest->name]' to apiary '[entities->apiary_dest->name]')"
                  );
                }

                if (has_migrated_hives_and_nucs) {
                  return i18n(
                    "EVENT.MIGRATORY.Partial migration from [entities->location_source->link] (moved [data->nb_hives] hives and [data->nb_nuc] nuc from apiary '[entities->apiary_dest->name]' to apiary '[entities->apiary_dest->name]')"
                  );
                }
              }
            }
          }

          if (role === 'warehouse_source') {
            if (new_apiary_dest) {
              if (move_all_devices) {
                if (has_only_migrated_hives) {
                  return i18n(
                    "EVENT.MIGRATORY.Setup of apiary '[entities->apiary_dest->name]' at location [entities->location_source->link], partial migration from [entities->location_source->link] (moved [data->nb_hives] hives and weather station from apiary '[entities->apiary_dest->name]')"
                  );
                }

                if (has_only_migrated_nucs) {
                  return i18n(
                    "EVENT.MIGRATORY.Setup of apiary '[entities->apiary_dest->name]' at location [entities->location_source->link], partial migration from [entities->location_source->link] (moved [data->nb_nuc] nuc and weather station from apiary '[entities->apiary_dest->name]')"
                  );
                }

                if (has_migrated_hives_and_nucs) {
                  return i18n(
                    "EVENT.MIGRATORY.Setup of apiary '[entities->apiary_dest->name]' at location [entities->location_source->link], partial migration from [entities->location_source->link] (moved [data->nb_hives] hives, [data->nb_nuc] nuc and weather station from apiary '[entities->apiary_dest->name]')"
                  );
                }
              }

              if (!move_all_devices) {
                if (has_only_migrated_hives) {
                  return i18n(
                    "EVENT.MIGRATORY.Setup of apiary '[entities->apiary_dest->name]' at location [entities->location_source->link], partial migration from [entities->location_source->link] (moved [data->nb_hives] hives from apiary '[entities->apiary_dest->name]')"
                  );
                }

                if (has_only_migrated_nucs) {
                  return i18n(
                    "EVENT.MIGRATORY.Setup of apiary '[entities->apiary_dest->name]' at location [entities->location_source->link], partial migration from [entities->location_source->link] (moved [data->nb_nuc] nuc from apiary '[entities->apiary_dest->name]')"
                  );
                }

                if (has_migrated_hives_and_nucs) {
                  return i18n(
                    "EVENT.MIGRATORY.Setup of apiary '[entities->apiary_dest->name]' at location [entities->location_source->link], partial migration from [entities->location_source->link] (moved [data->nb_hives] hives and [data->nb_nuc] nuc from apiary '[entities->apiary_dest->name]')"
                  );
                }
              }
            } else {
              if (move_all_devices) {
                if (has_only_migrated_hives) {
                  return i18n(
                    "EVENT.MIGRATORY.Partial migration from [entities->location_source->link] to [entities->location_dest->link] (moved [data->nb_hives] hives and weather station from apiary '[entities->apiary_dest->name]' to apiary '[entities->apiary_dest->name]')"
                  );
                }

                if (has_only_migrated_nucs) {
                  return i18n(
                    "EVENT.MIGRATORY.Partial migration from [entities->location_source->link] to [entities->location_dest->link] (moved [data->nb_nuc] nuc and weather station from apiary '[entities->apiary_dest->name]' to apiary '[entities->apiary_dest->name]')"
                  );
                }

                if (has_migrated_hives_and_nucs) {
                  return i18n(
                    "EVENT.MIGRATORY.Partial migration from [entities->location_source->link] to [entities->location_dest->link] (moved [data->nb_hives] hives, [data->nb_nuc] nuc and weather station from apiary '[entities->apiary_dest->name]' to apiary '[entities->apiary_dest->name]')"
                  );
                }
              }

              if (!move_all_devices) {
                if (has_only_migrated_hives) {
                  return i18n(
                    "EVENT.MIGRATORY.Partial migration from [entities->location_source->link] to [entities->location_dest->link] (moved [data->nb_hives] hives from apiary '[entities->apiary_dest->name]' to apiary '[entities->apiary_dest->name]')"
                  );
                }

                if (has_only_migrated_nucs) {
                  return i18n(
                    "EVENT.MIGRATORY.Partial migration from [entities->location_source->link] to [entities->location_dest->link] (moved [data->nb_nuc] nuc from apiary '[entities->apiary_dest->name]' to apiary '[entities->apiary_dest->name]')"
                  );
                }

                if (has_migrated_hives_and_nucs) {
                  return i18n(
                    "EVENT.MIGRATORY.Partial migration from [entities->location_source->link] to [entities->location_dest->link] (moved [data->nb_hives] hives and [data->nb_nuc] nuc from apiary '[entities->apiary_dest->name]' to apiary '[entities->apiary_dest->name]')"
                  );
                }
              }
            }
          }
        }

        return desc_key;
      })
    );
  }

  public getExportRowBuilder(headers: ExportHeader[], translateService: TranslateService): Observable<Dictionary<any>> {
    let row_builder = super.getExportRowBuilder(headers, translateService);
    headers.forEach((header: ExportHeader) => {
      switch (header.name) {
        case 'base_informations': {
          row_builder = row_builder.pipe(
            switchMap((row: ExportedRow) =>
              this.entities$$.pipe(
                take(1),
                map((entities: { [role: string]: Entity | Entity[] }) => {
                  const base_informations = row.base_informations || {};
                  if (!isNil(entities)) {
                    header.sub_headers.forEach((subHeader: ExportHeader) => {
                      switch (subHeader.name) {
                        case 'apiary_name':
                          base_informations.apiary_name = (entities.apiary as any).name || '';
                          break;

                        case 'location_name':
                          base_informations.location_name = (entities.location_dest as any).name || '';
                          break;
                      }
                    });
                  }
                  row.base_informations = base_informations;
                  return row;
                })
              )
            )
          );
          break;
        }

        // Get migratory informations (source location, destination location, source warehouse, etc.)
        case 'migratory': {
          row_builder = row_builder.pipe(
            switchMap((row: ExportedRow) =>
              this.entities$$.pipe(
                take(1),
                map((entities: { [role: string]: Entity | Entity[] }) => {
                  row.migratory = {};
                  if (!isNil(entities)) {
                    header.sub_headers.forEach((subHeader: ExportHeader) => {
                      if (entities.hasOwnProperty(subHeader.name)) {
                        row.migratory[subHeader.name] = (entities as any)[subHeader.name].name || '';
                      } else {
                        if (subHeader.name === 'apiary_dest') {
                          row.migratory.apiary_dest = row.migratory.apiary;
                        }

                        if (subHeader.name === 'nb_hives') {
                          row.migratory.nb_hives = this.data?.nb_hives?.toString() || '';
                        }

                        if (subHeader.name === 'nb_nuc') {
                          row.migratory.nb_nuc = this.data?.nb_nuc?.toString() || '';
                        }
                      }
                    });
                  }
                  // row['base_informations']['apiary_name'] = row['migratory']['location_dest'];
                  row.base_informations.location_name = row.migratory.location_dest;
                  return row;
                })
              )
            )
          );
          break;
        }
      }
    });
    return row_builder;
  }
}
