import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, OnInit, ViewChild } from '@angular/core';

import { Beeguard2Api } from 'app/core';
import { DialogsService } from 'app/widgets/dialogs-modals';
import { AppStateService } from 'app/core/app-state.service';

import { BgControlWidgetComponent as ControlWidget, WidgetOptions } from '../control/control.widget';

export interface TextAreaOptions extends WidgetOptions {
  indent: boolean;
  focus?: boolean;
  optional?: boolean;
}

@Component({
  selector: 'ef-textarea-widget',
  templateUrl: './textarea.widget.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EfTextAreaWidget extends ControlWidget implements OnInit, AfterViewInit {
  public hidden = false;

  public options: TextAreaOptions = {
    focus: false,
    indent: false,
    optional: false,
    reset_btn: false,
  };

  // #region -> (widget basics)

  @ViewChild('textareaWidget')
  public textarea: ElementRef<HTMLTextAreaElement>;

  constructor(protected bg2Api: Beeguard2Api, public appState: AppStateService, protected dialogs: DialogsService) {
    super(bg2Api, appState, dialogs);
  }

  ngOnInit(): void {
    super.ngOnInit();

    if (this.options.optional) {
      this.hidden = true;
    }
  }

  ngAfterViewInit() {
    if (this.textarea && this.options.focus) {
      this.textarea.nativeElement.focus();
    }
  }

  // #endregion

  public when_value_changed(new_value: any) {
    this.formProperty.setValue(new_value, false);
  }
}
