import { Event, EventSchema } from './Event';


export class DataSharing extends Event {

  protected _fixSchema(schema: EventSchema): EventSchema {
    schema.data_schema.properties.tiers.widget = "select";  
    if (!schema.data_schema.properties.tiers.options) {
      schema.data_schema.properties.tiers.options = {};
    }
    schema.data_schema.properties.tiers.options.visible_only_for = "superadmin";
    return schema;
  }
}
