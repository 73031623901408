<div *ngIf="apiary$$ | async; let apiary" class="bg2-apiary-last-data-card-weather-component">
  <div class="last-data-card-header">
    <div class="last-data-card-header__info">
      <div class="last-data-card-header__info__name">
        <span class="mdi mdi-sun-wireless mdi-24px"></span>
        <span class="last-data-card-header__info__name__text">
          {{ 'VIEWS.APIARY.SHARED.WIDGETS.APIARY_LAST_DATA.APIARY_LAST_DATA_CARD.Ambient weather' | translate }}
        </span>
      </div>
    </div>
  </div>

  <div class="last-data-card-body">
    <div class="last-data-card-body__inner" [ngStyle]="{ transform: translate_x$$ | async }">
      <div class="last-data-card-body__lastdata-container">
        <div class="last-data-info last-temperature">
          <span
            class="icon mdi mdi-24px mdi-thermometer"
            [popper]="
              'VIEWS.APIARY.SHARED.APIARY_LAST_DATA.APIARY_LAST_DATA_CARD.APIARY_LAST_DATA_CARD_WEATHER_STATION.Ambient temperature'
                | translate
            "
          ></span>

          <span
            *ngLet="last_temperature_data$$ | async; let last_temperature"
            class="value"
            [ngClass]="{ 'outdated-value': is_last_weather_data_outdated$$ | async }"
          >
            <ng-container *ngIf="(last_temperature ?? null) === null; else tmpLastTemperatureExists">
              <span>--</span>
            </ng-container>

            <ng-template #tmpLastTemperatureExists>
              <span class="minimal-value num-value" [popper]="lastTempMin">
                {{ last_temperature?.min | fixed: 1 }}
              </span>
              <span>/</span>
              <span class="maximal-value num-value" [popper]="lastTempMax">
                {{ last_temperature?.max | fixed: 1 }}
              </span>
              <span>&nbsp;°C</span>

              <popper-content #lastTempMin>
                <span>
                  {{ 'ALL.DATA.SPEC.TEMPERATURE.Minimale temperature over 24 hours' | translate }}
                  ({{ 'ALL.COMMON.Taken on [date]' | translate: { date: last_temperature?.timestamp_for_min | formatDate: 'full' } }})
                </span>
              </popper-content>

              <popper-content #lastTempMax>
                <span>
                  {{ 'ALL.DATA.SPEC.TEMPERATURE.Maximale temperature over 24 hours' | translate }}
                  ({{ 'ALL.COMMON.Taken on [date]' | translate: { date: last_temperature?.timestamp_for_max | formatDate: 'full' } }})
                </span>
              </popper-content>

              <span
                *ngIf="is_last_weather_data_outdated$$ | async"
                class="mdi mdi-clock value-outdated-icon"
                [popper]="'VIEWS.APIARY.SHARED.APIARY_LAST_DATA.Value has not been updated since 48 hours' | translate"
              ></span>
            </ng-template>
          </span>
        </div>

        <div class="last-data-info last-humidity">
          <span
            class="icon mdi mdi-24px mdi-water-percent"
            [popper]="
              'VIEWS.APIARY.SHARED.APIARY_LAST_DATA.APIARY_LAST_DATA_CARD.APIARY_LAST_DATA_CARD_WEATHER_STATION.Ambient humidity'
                | translate
            "
          ></span>

          <span
            *ngLet="last_humidity_data$$ | async; let last_humidity"
            class="value"
            [ngClass]="{ 'outdated-value': is_last_weather_data_outdated$$ | async }"
          >
            <ng-container *ngIf="(last_humidity ?? null) === null; else tmpLastHumidityExists">
              <span>--</span>
            </ng-container>

            <ng-template #tmpLastHumidityExists>
              <span>
                <ng-container *ngIf="apiary_can_have_rain_data$$ | async">
                  <span class="num-value" [popper]="'ALL.DATA.SPEC.RAIN.Cumulated rain since 24 hours' | translate">
                    {{ last_humidity?.rain | fixed: 1 }}
                  </span>
                  <span>&nbsp;mm</span>

                  <span>&nbsp;/&nbsp;</span>
                </ng-container>

                <span class="num-value" [popper]="'ALL.DATA.SPEC.HUMIDITY.Mean humidity over 24 hours' | translate">
                  {{ last_humidity?.humidity | fixed: 1 }}
                </span>
                <span>&nbsp;%</span>
              </span>

              <span
                *ngIf="is_last_weather_data_outdated$$ | async"
                class="mdi mdi-clock value-outdated-icon"
                [popper]="'VIEWS.APIARY.SHARED.APIARY_LAST_DATA.Value has not been updated since 48 hours' | translate"
              ></span>
            </ng-template>
          </span>
        </div>

        <div class="last-data-info last-pressure">
          <span
            class="icon mdi mdi-24px mdi-gauge"
            [popper]="
              'VIEWS.APIARY.SHARED.APIARY_LAST_DATA.APIARY_LAST_DATA_CARD.APIARY_LAST_DATA_CARD_WEATHER_STATION.Ambient pressure'
                | translate
            "
          ></span>

          <span
            *ngLet="last_pressure_data$$ | async; let last_pressure"
            class="value"
            [ngClass]="{ 'outdated-value': is_last_weather_data_outdated$$ | async }"
          >
            <ng-container *ngIf="(last_pressure ?? null) === null; else tmpLastPressureExists">
              <span>--</span>
            </ng-container>

            <ng-template #tmpLastPressureExists>
              <span class="num-value" [popper]="'ALL.DATA.SPEC.HUMIDITY.Mean pressure over 24 hours' | translate">
                {{ last_pressure | fixed: 0 }}
              </span>
              <span>&nbsp;hPa</span>

              <span
                *ngIf="is_last_weather_data_outdated$$ | async"
                class="mdi mdi-clock value-outdated-icon"
                [popper]="'VIEWS.APIARY.SHARED.APIARY_LAST_DATA.Value has not been updated since 48 hours' | translate"
              ></span>
            </ng-template>
          </span>
        </div>

        <div class="last-data-info last-wind">
          <span
            class="icon mdi mdi-24px mdi-weather-windy"
            [popper]="
              'VIEWS.APIARY.SHARED.APIARY_LAST_DATA.APIARY_LAST_DATA_CARD.APIARY_LAST_DATA_CARD_WEATHER_STATION.Ambient wind' | translate
            "
          ></span>

          <span
            *ngLet="last_wind_data$$ | async; let last_wind"
            class="value"
            [ngClass]="{ 'outdated-value': is_last_weather_data_outdated$$ | async }"
          >
            <ng-container *ngIf="(last_wind ?? null) === null; else tmpLastWindExists">
              <span>--</span>
            </ng-container>

            <ng-template #tmpLastWindExists>
              <bg2-picon-wind-direction
                [raw_wind_speed]="last_wind?.speed"
                [raw_wind_heading]="last_wind?.heading"
              ></bg2-picon-wind-direction>

              <span
                *ngIf="is_last_weather_data_outdated$$ | async"
                class="mdi mdi-clock value-outdated-icon"
                [popper]="'VIEWS.APIARY.SHARED.APIARY_LAST_DATA.Value has not been updated since 48 hours' | translate"
              ></span>
            </ng-template>
          </span>
        </div>
      </div>

      <div *ngLet="weather_devices$$ | async; let devices" class="last-data-card-body__devices-container">
        <ng-container *ngFor="let device of devices; let index = index; trackBy: track_by_device">
          <div class="device-details">
            <bg2-device-inline [device]="device" [show_status]="true" [show_action]="true" [show_last_com]="false"></bg2-device-inline>
          </div>

          <mat-divider *ngIf="index + 1 < devices?.length"></mat-divider>
        </ng-container>
      </div>
    </div>
  </div>

  <div class="last-data-card-footer">
    <div *ngLet="weather_devices_most_recent_date$$ | async; let weather_devices_most_recent_date" class="last-data-card-footer__lastcom">
      <span [popper]="weatherDevicesLastCom">
        <span>{{ 'VIEWS.APIARY.SHARED.WIDGETS.APIARY_LAST_DATA.APIARY_LAST_DATA_CARD.Last com' | translate | colon }}</span>
        <span *ngIf="weather_devices_most_recent_date; else loadingWeatherDevicesLastCom">{{
          weather_devices_most_recent_date | distanceToNow
        }}</span>
      </span>

      <ng-template #loadingWeatherDevicesLastCom>
        <bg2-loading-skeleton [string_model]="'today'" [theme]="{ height: '18px', width: '100%' }"></bg2-loading-skeleton>
      </ng-template>

      <popper-content #weatherDevicesLastCom>
        <span>{{ 'VIEWS.APIARY.SHARED.WIDGETS.APIARY_LAST_DATA.APIARY_LAST_DATA_CARD.Last com' | translate | colon }}</span>
        <span *ngIf="weather_devices_most_recent_date">{{ weather_devices_most_recent_date | formatDate: 'full' }}</span>
      </popper-content>
    </div>

    <div class="navigation_and_states">
      <div class="switch-lastdata-devices">
        <span *ngIf="current_index === 0" (click)="current_index = -1">
          <ng-container *ngLet="(weather_devices$$ | async)?.length; let total_weather_devices">
            {{
              (total_weather_devices > 1
                ? 'VIEWS.APIARY.SHARED.WIDGETS.APIARY_LAST_DATA.APIARY_LAST_DATA_CARD.See [x] device(s)'
                : 'VIEWS.APIARY.SHARED.WIDGETS.APIARY_LAST_DATA.APIARY_LAST_DATA_CARD.See the device'
              ) | translate: { x: total_weather_devices }
            }}
          </ng-container>
        </span>

        <span *ngIf="current_index === -1" (click)="current_index = 0">
          {{ 'VIEWS.APIARY.SHARED.WIDGETS.APIARY_LAST_DATA.APIARY_LAST_DATA_CARD.Last data' | translate }}
        </span>
      </div>

      <div class="worst-states">
        <!-- Battery level -->
        <ng-container>
          <!-- <bg2-battery-level [status]="worst_weather_devices_statuses.worst_status_bat$$ | async"></bg2-battery-level> -->
          <bg2-battery-level-simplified
            [open_in_modal]="false"
            [simple_state]="worst_weather_devices_statuses.worst_status_bat_simplified$$ | async"
          ></bg2-battery-level-simplified>
        </ng-container>

        <!-- GPS level -->
        <ng-container>
          <bg2-gps-level [open_in_modal]="false" [status]="worst_weather_devices_statuses.worst_status_gps$$ | async"></bg2-gps-level>
        </ng-container>

        <!-- GPRS level -->
        <ng-container>
          <bg2-gprs-level [open_in_modal]="false" [status]="worst_weather_devices_statuses.worst_status_gprs$$ | async"></bg2-gprs-level>
        </ng-container>
      </div>
    </div>
  </div>
</div>
