import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

import { isNil, omit, orderBy, pick } from 'lodash-es';

import { BehaviorSubject, combineLatest, map, Observable, of, tap } from 'rxjs';

import { ConsoleLoggerService } from 'app/core/console-logger.service';

import { strEnum } from 'app/misc/tools';
import { waitForNotNilValue } from '@bg2app/tools/rxjs';

/** */
const visit_summary_carousel_aspect = strEnum(['hive_statuses', 'queen_colors', 'supers', 'broodframes']);

/** */
export type VisitSummaryCarouselApsects = keyof typeof visit_summary_carousel_aspect;

/** */
export interface VisitSummaryData {
  /** */
  total_hives: number;

  /** */
  total_nucs: number;

  /** */
  supers: {
    /**
     * The total of supers setted on the visit.
     */
    total: number;

    /** */
    is_evaluated: boolean;
  };

  /** */
  broodframes: {
    /**
     * The total of identified broodframes on the visit.
     */
    total: number;

    /** */
    is_evaluated: boolean;
  };

  /** */
  queen_colors: {
    /**
     * The total of colored queens identified on the visit.
     */
    total: number;

    /** */
    is_evaluated: boolean;
  };

  /** */
  hive_statuses: {
    /**
     * The total of OK hives identified on the visit.
     */
    total: number;

    /** */
    is_evaluated: boolean;
  };
}

/** */
@Component({
  selector: 'bg2-evaluation-resume',
  templateUrl: './evaluation-resume.component.html',
  styleUrls: ['./evaluation-resume.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class Bg2EvaluationSummaryComponent {
  // #region -> (component basics)

  /** */
  private readonly LOGGER = new ConsoleLoggerService('Bg2EvaluationSummaryComponent', true);

  /** */
  constructor() {}

  // #endregion

  /** */
  @Output()
  public when_redirect_to_card = new EventEmitter<string>();

  /** */
  private _summary_data$$ = new BehaviorSubject<VisitSummaryData>(null);

  /** */
  public summary_data$$ = this._summary_data$$.asObservable().pipe(
    waitForNotNilValue(),
    map(data => omit(data, ['total_hives', 'total_nucs']))
  );

  /** */
  @Input()
  public set summary_data(summary_data: VisitSummaryData) {
    this._summary_data$$.next(summary_data);
    this._evaluated_hives_nucs_total$$.next({ hives: summary_data?.total_hives, nucs: summary_data?.total_nucs });
  }

  /** */
  public force_type(model: any): { total: number; is_evaluated: boolean } {
    return model;
  }

  // #region -> (hives and nucs management)

  /** */
  private _evaluated_hives_nucs_total$$ = new BehaviorSubject<{ hives: number; nucs: number }>(null);

  /** */
  public evaluated_hives_nucs_total$$ = this._evaluated_hives_nucs_total$$.asObservable();

  // #endregion

  // #region -> (specific data)

  /** */
  public mean_super_by_hive$$ = combineLatest({
    supers: this.summary_data$$.pipe(map(summary => pick(summary, ['supers']).supers)),
    total_hives_nucs: this._evaluated_hives_nucs_total$$,
  }).pipe(
    map(({ supers, total_hives_nucs }) => {
      const total_hives_nucs_sum = (total_hives_nucs?.hives ?? 0) + (total_hives_nucs?.nucs ?? 0);

      if (!supers?.is_evaluated || total_hives_nucs_sum === 0) {
        return 0;
      }

      return Math.round(supers?.total / total_hives_nucs_sum);
    })
  );

  // #endregion
}
