import { formatDate } from '@angular/common';

import { TranslateService } from '@ngx-translate/core';

import { forkJoin, map, Observable, of, switchMap, take } from 'rxjs';

import { DeviceInterface, DRDevice } from 'app/models/devices';

/** */
export const build_template_devices_guarantee_list$ = (
  devices: DRDevice[],
  { _translate }: { _translate: TranslateService }
): Observable<string> => {
  const non_excluded_devices = devices.filter(device => device?.type !== 'CPT' && device?.type !== 'BeeLive' && device?.type !== 'CPTMC');

  // Styles
  const table_th_td_styles = 'border: 1px solid gray;text-align: center;padding: 5px;';
  const table_th_styles = 'background-color: black; color: white;';

  return of(['<h3>Garantie des appareils</h3>']).pipe(
    switchMap(template_builder => {
      if (non_excluded_devices?.length === 0) {
        template_builder.push('<div><b>Aucun appareil lié au ticket</b></div>');
        return of(template_builder);
      }

      return of(template_builder).pipe(
        map(_template_builder => {
          _template_builder.push(
            '<table style="border-collapse: collapse;width: 100%;table-layout:fixed;">',

            '<thead><tr>',
            `<th style="${table_th_td_styles}${table_th_styles}">Appareils</th>`,
            `<th style="${table_th_td_styles}${table_th_styles}">Contrat</th>`,
            `<th style="${table_th_td_styles}${table_th_styles}">Date affectation a l'exploitation</th>`,
            `<th style="${table_th_td_styles}${table_th_styles}">Sous garantie ?</th>`,
            '</tr></thead>',

            '<tbody>'
          );

          return _template_builder;
        }),
        switchMap(_template_builder => {
          const build_device_guarantee_row$ = (device: DRDevice): Observable<string> =>
            forkJoin({
              contract: device?.contract$$.pipe(take(1)),
              under_guarantee: device?.guarantee$$.pipe(
                take(1),
                map(guarantee => guarantee.under_guarantee)
              ),
              affected_to_warehouse_since: device?.affected_to_warehouse_since$$.pipe(take(1)),
            }).pipe(
              map(({ contract, under_guarantee, affected_to_warehouse_since }) => {
                let device_row_template = '';

                device_row_template += '<tr>';

                device_row_template += `<th style="${table_th_td_styles}${table_th_styles}">${device?.name}</th>`;

                device_row_template += `<td style="${table_th_td_styles}">`;
                device_row_template += `${_translate.instant('EVENT.DEVICE.'.concat(contract))}`;
                device_row_template += '</td>';

                device_row_template += `<td style="${table_th_td_styles}">`;
                device_row_template += '<div style="display: flex; flex-flow: column nowrap;">';
                device_row_template += `<span>${formatDate(affected_to_warehouse_since, 'd MMMM yyyy', 'fr')}</span>`;
                device_row_template += `<span>${formatDate(affected_to_warehouse_since, 'HH:mm:ss zzzz', 'fr')}</span>`;
                device_row_template += '</div>';
                device_row_template += '</td>';

                device_row_template += `<td style="${table_th_td_styles}background-color: ${under_guarantee ? 'green' : 'red'};color: ${
                  under_guarantee ? 'white' : 'black'
                };">`;
                device_row_template += '<div style="display: flex; flex-flow: column nowrap;">';
                device_row_template += `<span style="font-variant: small-caps;font-weight: bold;">${
                  under_guarantee ? 'Oui' : 'Non'
                }</span>`;
                // device_row_template += `<span>Reason : ${device?.is_under_guarantee$$}</span>`;
                device_row_template += '</div>';
                device_row_template += '</td>';

                device_row_template += '</tr>';

                return device_row_template;
              }),
              take(1)
            );

          return forkJoin(non_excluded_devices.map(device => build_device_guarantee_row$(device))).pipe(
            map(templates => {
              _template_builder.push(...templates);
              return _template_builder;
            })
          );
        }),
        map(_template_builder => {
          _template_builder.push('</tbody>', '</table>');
          return _template_builder;
        })
      );
    }),
    map(template_builder => template_builder.join('')),
    take(1)
  );
};
