import { Injectable } from '@angular/core';
import { Socket } from 'ngx-socket-io';

import { environment } from 'environments/environment';
import { BeeguardAuthService } from './auth/beeguard-auth.service';

@Injectable()
export class Bg2Socket extends Socket {
  constructor(private oAuthService: BeeguardAuthService) {
    super({
      url: environment.Beeguard2StreamUrl,
      options: {
        query: {
          access_token: oAuthService.access_token,
        },
        transports: ['websocket', 'polling'], // Test websocket first, and then polling
      },
    });
  }
}
