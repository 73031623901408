import * as d3 from 'd3';

import { isEmpty, isNil } from 'lodash-es';

import { isSameDay, setHours } from 'date-fns/esm';

import { marker as i18n } from '@biesbjerg/ngx-translate-extract-marker';

import { AppStateService } from 'app/core/app-state.service';
import { ConsoleLoggerService } from 'app/core/console-logger.service';
import { D3SharedCursorService } from 'app/core/services/global/d3-shared-cursor.service';

import { WindDataPoint } from 'app/models/data';

import {
  AbstractUserEvents,
  AbstractD3Axes,
  AbstractD3Scales,
  AbstractDataObjects,
  AbstractD3Grid,
  AbstractD3Labels,
  AbstractEventObjects,
  D3SvgBuilderLuxon,
} from '@bg2app/models/charts';
import { percentile, compute_wind_heading } from 'app/misc/tools/maths';
import { group_data_by_day } from '@bg2app/tools/dates';
import { NgZone } from '@angular/core';

// #region -> (interfaces)

/**
 * @interface
 * @description
 *
 *
 */
interface Axes extends AbstractD3Axes {
  /**
   * @description
   *
   * Defines a dictionnary to hold the informations about time axis.
   */
  time: {
    /**
     * @description
     *
     * Reference to the d3-axis generator for the time values.
     */
    axis: d3.Axis<d3.AxisDomain>;

    /**
     * @description
     *
     * Reference to the container for the time axis.
     */
    container: d3.Selection<SVGGElement, unknown, HTMLElement, any>;
  };

  /**
   * @description
   *
   * Defines a dictionnary to hold the informations about speed axis.
   */
  speed: {
    /**
     * @description
     *
     * Reference to the d3-axis generator for the pressure values.
     */
    axis: d3.Axis<d3.AxisDomain>;

    /**
     * @description
     *
     * Reference to the container for the pressure axis.
     */
    container: d3.Selection<SVGGElement, unknown, HTMLElement, any>;
  };
}

/**
 * @interface
 * @description
 *
 *
 */
interface Scales extends AbstractD3Scales {
  /**
   * @description
   *
   * Reference to the time scale for the temporal value.
   */
  time: d3.ScaleTime<number, number, never>;

  /**
   * @description
   *
   * Reference to linear scale for the speed value.
   */
  speed: d3.ScaleLinear<number, number, never>;
}

/**
 * @interface
 * @description
 *
 *
 */
interface DataObjects extends AbstractDataObjects {
  /**
   * @description
   *
   *
   */
  speed: {
    /**
     * @description
     */
    line: {
      /**
       * @description
       *
       *
       */
      shape: d3.Line<WindDataPoint>;

      /**
       * @description
       *
       *
       */
      path: d3.Selection<SVGPathElement, unknown, HTMLElement, any>;
    };

    /**
     * @property
     * @description
     *
     *
     */
    circle: d3.Selection<SVGCircleElement, unknown, HTMLElement, any>;
  };
}

/**
 * @interface
 * @description
 *
 *
 */
interface Events extends AbstractUserEvents {
  /**
   * @description
   *
   *
   */
  speed: {
    text: d3.Selection<SVGGElement, unknown, HTMLElement, any>;
    container: d3.Selection<HTMLDivElement, unknown, HTMLElement, any>;
  };
}

interface Labels extends AbstractD3Labels {
  /** */
  labels: {
    /** */
    wind: d3.Selection<SVGTextElement, unknown, HTMLElement, unknown>;
  };
}

// #endregion

// #region -> (classes)

export class RGJobDataWindD3ChartFactory extends D3SvgBuilderLuxon<WindDataPoint[]> {
  // #region -> (properties)

  public axes: Axes = {
    container: null,
    time: {
      axis: null,
      container: null,
    },
    speed: {
      axis: null,
      container: null,
    },
  };

  public scales: Scales = {
    time: null,
    speed: null,
  };

  public data_objects: DataObjects = {
    container: null,
    speed: {
      line: {
        shape: d3
          .line<WindDataPoint>()
          .defined(point => !isNil(point?.anemo_speed))
          .curve(d3.curveLinear),
        path: null,
      },

      circle: null,
    },
  };

  public event_objects: AbstractEventObjects = {
    container: null,
  };

  public user_events: Events = {
    container: null,
    event_bounds: null,

    focus_line_x: null,
    focus_line_y: null,

    speed: {
      text: null,
      container: null,
    },
  };

  public grids: AbstractD3Grid = {
    container: null,

    time: {
      axis: null,
      container: null,
    },
  };

  public labels: Labels = {
    container: null,
    labels: {
      wind: null,
    },
  };

  // #endregion

  // #region -> (component basics)

  /** */
  protected colors: { [key in 'wind']: `#${string}` } = {
    /** */
    wind: `#439AEB`,
  };

  /** */
  protected LOGGER: ConsoleLoggerService = new ConsoleLoggerService('RGJobDataWindD3ChartFactory', true);

  /** */
  constructor(protected _shared_cursor: D3SharedCursorService, protected _appState: AppStateService, protected _ngZone: NgZone) {
    super(_shared_cursor, _appState, _ngZone);

    this.margins.bottom = 5;
    this.margins.right = 50;
  }

  /** */
  public destroy(): void {
    super.destroy();
  }

  // #endregion

  // #region -> (update methods)

  /** */
  protected update_axes(): void {
    super.update_axes();

    this.axes.speed.axis.scale(this.scales.speed);
    this.axes.speed.container
      .transition()
      .duration(500)
      .call(this.axes.speed.axis)
      .attr('transform', `translate(${this.margins.left}, ${this.margins.top})`);

    this.axes.speed.container.selectAll('.tick').select('text').attr('fill', this.colors.wind).attr('font-weight', 600);
  }

  // #endregion

  // #region -> (non-abstract methods)

  public resize(): void {
    // Update speed axis
    this.axes.speed.axis.scale(this.scales.speed);
    this.axes.speed.container.call(this.axes.speed.axis).attr('transform', `translate(${this.margins.left}, ${this.margins.top})`);

    // Update labels
    this.labels.labels.wind
      .text(this._appState.translate.instant(i18n<string>('ALL.DATA.LABELS_SHORT_WITH_UNIT.Wind (km/h)')))
      .attr('x', -(this.calc_size_of(this.box_sizing.height, ['-top', '-bottom']) / 2));

    super.resize();
  }

  // #endregion

  // #region -> (creation methods)

  public create_axes(): void {
    super.create_axes();

    this.axes.time.container.style('display', 'none');

    // Create weight axis
    this.axes.speed.axis = d3.axisLeft(this.scales.speed).ticks(3);
    this.axes.speed.container = this.axes.container
      .append('g')
      .attr('class', 'd3-Y-axis')
      .attr('transform', `translate(${this.calc_size_of(0, ['+left'])}, ${this.calc_size_of(0, ['+top'])})`)
      .call(this.axes.speed.axis);

    // Call at least one time the format
    this.apply_axes_format();
  }

  protected create_scales(): void {
    super.create_scales();

    this.scales.speed = d3.scaleLinear().rangeRound([this.calc_size_of(this.box_sizing?.height, ['-top', '-bottom']), 0]);
  }

  protected create_labels(): void {
    super.create_labels();

    this.labels.labels.wind = this.labels.container
      .append('text')
      .text(this._appState.translate.instant(i18n<string>('ALL.DATA.LABELS_SHORT_WITH_UNIT.Wind (km/h)')))
      .attr('font-size', '10px')
      .attr('transform', 'rotate(-90)')
      .attr('font-weight', 'bold')
      .attr('fill', this.colors.wind)
      .attr('text-anchor', 'middle')
      .attr('x', -(this.calc_size_of(this.box_sizing.height, ['-top', '-bottom']) / 2))
      .attr('y', -25);
  }

  // #endregion

  // #region -> (abstract methods)

  public build_event_objects(): void {
    // Build Y-focus line
    this.user_events.focus_line_y = this.create_focus_line('y', this.user_events.container);

    // Build tooltips
    this.user_events.speed.container = this.create_html_tooltip(this.svg_parent);

    this.data_objects.speed.line.path = this.data_objects.container
      .append('path')
      .style('fill', 'none')
      .style('stroke', this.colors.wind)
      .style('stroke-width', '2px');

    // Create event circles for each hive
    this.data_objects.speed.circle = this.user_events.container
      .append('circle')
      .attr('r', 4)
      .style('display', 'none')
      .attr('pointer-events', 'none')
      .attr('class', 'd3-focus-circle')
      .attr('fill', this.colors.wind);
  }

  public on_mouse_enter(is_from_shared_cursor = false): void {
    super.on_mouse_enter(is_from_shared_cursor);

    if (!this.has_data) {
      return;
    }

    this.user_events.speed.container.style('display', null);
    this.data_objects.speed.circle.style('display', null);
  }

  public on_mouse_move(event: MouseEvent | Date, data: WindDataPoint[], is_from_shared_cursor = false): void {
    if (isEmpty(data || [])) {
      return null;
    }

    let pointed_position_x: number = null;
    let pointed_position_y: number = null;

    if (is_from_shared_cursor) {
      pointed_position_x = this.scales.time(event as Date);
    } else {
      const source_element = (event as MouseEvent).target as SVGGElement;
      const boundings = source_element.getBoundingClientRect();

      pointed_position_x = (event as MouseEvent).clientX - boundings.left;
      pointed_position_y = (event as MouseEvent).clientY - boundings.top;
    }

    // Retrieve consistent data for each hive
    const wind_points = data;

    const index = d3.bisectCenter(
      wind_points?.map(v => v.tz_date),
      this.scales.time.invert(pointed_position_x)
    );

    const closest_point = data?.[index] ?? null;

    if (!is_from_shared_cursor) {
      this._shared_cursor.shared_cursor = {
        date: closest_point?.tz_date ?? null,
        from: this.unique_id,
        event_type: 'mousemove',
      };
    }

    let template = this.create_tooltip_header(closest_point?.date);

    template += `<div class="d3-chart-tooltip-list-item">`;

    template += '<div class="d3-chart-tooltip-series-name">';
    template += `<span class="mdi mdi-minus-thick" style="color: ${this.colors.wind}"></span>`;
    template += `Vitesse du vent`;
    template += '</div>';

    template += '<div class="d3-chart-tooltip-value">';
    template += `${closest_point?.anemo_speed?.toFixed(1) ?? '?'} Km/H`;
    template += '</div>';

    template += '</div>';

    // Update tooltip position
    const is_in_first_half = pointed_position_x < this.user_events.event_bounds.node().width.baseVal.value / 2;
    if (is_in_first_half) {
      this.user_events.speed.container
        .html(template)
        .style('left', `${this.calc_size_of(pointed_position_x + 10, ['+left'])}px`)
        .style('top', `${this.user_events.event_bounds.node().getBBox().height / 2}px`);
    } else {
      this.user_events.speed.container
        .html(template)
        .style('left', `${pointed_position_x + this.margins.left - 1 - this.user_events.speed.container.node().clientWidth - 10}px`)
        .style('top', `${this.user_events.event_bounds.node().getBBox().height / 2}px`);
    }

    // Update the focus_line_x position
    this.user_events.focus_line_y
      .attr('x1', this.scales.time(this.scales.time.invert(pointed_position_x)))
      .attr('x2', this.scales.time(this.scales.time.invert(pointed_position_x)));

    // Update circle positions

    if (isNil(closest_point?.tz_date) || isNil(closest_point?.anemo_speed)) {
      this.data_objects.speed.circle.style('display', 'none');
      return;
    } else {
      this.data_objects.speed.circle.style('display', null);
      this.data_objects.speed.circle.attr(
        'transform',
        `translate(${this.scales.time(closest_point.tz_date.getTime())},${this.scales.speed(closest_point.anemo_speed)})`
      );
    }
  }

  public on_mouse_out(is_from_shared_cursor = false): void {
    super.on_mouse_out(is_from_shared_cursor);

    this.user_events.speed.container.style('display', 'none');
    this.data_objects.speed.circle.style('display', 'none');
  }

  public append_data(is_after_resize: boolean): void {
    this.scales.time.domain([this.date_range.start, this.date_range.end]);

    if (!this.has_data) {
      this.scales.speed.domain([0, 20]);
      this.data_objects.speed.line.path.attr('d', '');

      super.append_data(is_after_resize);
      return;
    }

    const points: WindDataPoint[] = this.incoming_data;

    // Search max value for wind speed
    const max_wind_speed_value = d3.max(
      this.incoming_data.filter(wind_data_point => !isNil(wind_data_point.anemo_speed)),
      (c: WindDataPoint) => c.anemo_speed + 2
    );

    // Update Y-Axes ranges
    this.scales.speed.domain([0, max_wind_speed_value]);

    // Update data of path
    this.data_objects.speed.line.shape.x(_point => this.scales.time(_point.tz_date)).y(_point => this.scales.speed(_point.anemo_speed));
    this.data_objects.speed.line.path.attr('d', this.data_objects.speed.line.shape(points));

    // Add wind headings
    this.data_objects.container.selectAll('.wind-heading').remove();

    group_data_by_day<WindDataPoint>(this.incoming_data).map((datum, index) => {
      if (isSameDay(datum.date_of_day, this.date_range.end.toJSDate())) {
        return;
      }

      const anemo_speeds = datum.values.map(v => v.anemo_speed);

      const percentile_index = percentile(90, anemo_speeds)?.[1] as number;

      if (percentile_index >= 0) {
        const date = setHours(datum.values?.[percentile_index]?.tz_date, 12);
        const anemo_speed = datum.values?.[percentile_index]?.anemo_speed;
        const anemo_heading = compute_wind_heading(anemo_speed, datum.values?.[percentile_index]?.anemo_heading);

        if (!isNil(anemo_heading?.rotation)) {
          this.data_objects.container
            .append('path')
            .attr('class', 'wind-heading')
            .attr('d', 'M12,2L4.5,20.29L5.21,21L12,18L18.79,21L19.5,20.29L12,2Z')
            .attr('stroke', 'black')
            .attr('fill', this.colors.wind)
            .attr('transform-origin', '50% 50%')
            .attr('style', 'transform-box: fill-box')
            .attr('transform', `translate(${this.scales.time(date) - 11.5}, 0) rotate(${anemo_heading?.rotation})`);
        }
      }
    });

    super.append_data(is_after_resize);
  }

  // #endregion

  // #region -> (external chart events)

  protected on_language_updated(): void {
    super.on_language_updated();

    this.labels?.labels?.wind
      .text(this._appState.translate.instant(i18n<string>('ALL.DATA.LABELS_SHORT_WITH_UNIT.Wind (km/h)')))
      .attr('x', -(this.calc_size_of(this.box_sizing.height, ['-top', '-bottom']) / 2));
  }

  // #endregion
}

// #endregion
