import { AfterViewInit, ChangeDetectionStrategy, Component, Input, NgZone, OnDestroy } from '@angular/core';

import { AppStateService } from 'app/core/app-state.service';
import { ConsoleLoggerService } from 'app/core/console-logger.service';
import { D3SharedCursorService } from 'app/core/services/global/d3-shared-cursor.service';

import { ApiaryWeightsData } from 'app/models';

import { ResizedEvent } from 'app/misc/directives/resized/resized.directive';
import { D3SvgApiaryHivesTemperatureFactory } from './apiary-hives-temperature-d3-chart.factory';
import { HiveTemperatureData } from 'app/models/data/data-classic/interfaces/hive-data.interface';
import { ChartGeoposition, D3ChartMargins } from '@bg2app/models/charts';
import { merge } from 'lodash-es';
import { DateTime } from 'luxon';

/**
 * @ngModule DevicesSharedModule
 *
 * @description
 *
 * Creates a D3.js chart to display the weight of the hives with WGuard devices.
 *
 * The `ApiaryHivesTemperatureD3ChartComponent` component creates a D3.js chart to display
 * the temeprature of each hive which have GPS devices installed on it.
 */
@Component({
  selector: 'bg2-apiary-hives-temperature-d3-chart-component',
  template: `<div class="bg2-apiary-weight-d3-chart-component" (resized)="onChartContainerResized($event)">
    <svg [id]="chart_factory.unique_id"></svg>
    <div class="loading"></div>
  </div>`,
  styleUrls: ['./apiary-hives-temperature-d3-chart.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ApiaryHivesTemperatureD3ChartComponent implements AfterViewInit, OnDestroy {
  // #region -> (component basics)

  private readonly _logger = new ConsoleLoggerService('ApiaryHivesTemperatureD3ChartComponent', true);

  /** */
  public chart_factory: D3SvgApiaryHivesTemperatureFactory = null;

  constructor(private _d3_shared_cursor: D3SharedCursorService, private _appState: AppStateService, private _ngZone: NgZone) {
    this.chart_factory = new D3SvgApiaryHivesTemperatureFactory(this._d3_shared_cursor, this._appState, this._ngZone);
  }

  ngAfterViewInit(): void {
    this.chart_factory.create_chart();
  }

  ngOnDestroy(): void {
    this.chart_factory.destroy();
  }

  // #endregion

  // #region -> (container size management)

  public onChartContainerResized(resized_event: ResizedEvent): void {
    this.chart_factory.box_sizing = { width: resized_event?.new_width, height: resized_event?.new_height };
  }

  // #endregion

  // #region -> (data management)

  /** */
  @Input()
  public set temperature_data(temperature_data: HiveTemperatureData[]) {
    this.chart_factory.incoming_data = temperature_data ?? null;
  }

  @Input()
  public set margins(margins: Partial<D3ChartMargins>) {
    this.chart_factory.margins = merge(this.chart_factory.margins, margins);
  }

  /** */
  @Input()
  public set date_range(date_range: { start: DateTime; end: DateTime }) {
    this.chart_factory.date_range = date_range;
  }

  /** */
  @Input()
  public set is_loading(is_loading: boolean) {
    this.chart_factory.is_loading = is_loading;
  }

  /** */
  @Input()
  public set geoposition(geoposition: ChartGeoposition) {
    this.chart_factory.geoposition.value = geoposition;
  }

  // #endregion
}
