<div class="bg2-title-seperator-component">
  <span *ngIf="icon" class="icon-title mdi {{ icon }}"></span>
  <span class="title">{{ title }}</span>

  <ng-container *ngIf="goto_link?.routerLink">
    <span
      class="icon-link mdi mdi-link"
      [routerLink]="goto_link?.routerLink ?? null"
      [queryParamsHandling]="goto_link?.queryParamsHandling ?? 'preserve'"
      [popper]="'WIDGETS.WIDGETS_REUSABLES.TITLES.TITLE_SEPARATOR.TOOLTIPS.View details' | translate"
    ></span>
  </ng-container>
</div>
