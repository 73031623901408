import { ChangeDetectionStrategy, Component, HostListener, OnDestroy, OnInit } from '@angular/core';

import { Clipboard } from '@angular/cdk/clipboard';

import { catchError, map, of, switchMap, take, tap } from 'rxjs';
import { combineLatest, Subscription } from 'rxjs';

import { marker as i18n } from '@biesbjerg/ngx-translate-extract-marker';

import { isEmpty, isNil } from 'lodash-es';

import { AppStateService } from 'app/core/app-state.service';
import { Beeguard2Api } from 'app/core/api/main/beeguard2-api-service';
import { DeviceApi } from 'app/core/api/device/device-api-service';

import { Exploitation, Warehouse } from 'app/models';
import { EntitySchema } from 'app/core/api-swagger/beeguard2';

import { AbstractFormModalComponent, DialogsService } from 'app/widgets/dialogs-modals';
import { ISchema } from 'ngx-schema-form';
import { replay, switchTap } from '@bg2app/tools/rxjs';
import { openExploitationCRMUpdateModal } from 'app/views/exploitations/exploitations-helpers';
import { ModalParams } from 'app/widgets/dialogs-modals/abstract-modal.component';
import { ZohoAuthService } from 'app/core/services/zoho/zoho-auth.service';
import { ZohoCRMApiService } from 'app/core/services/zoho/zoho-crm-api.service';

interface FormModelData {
  owner: any;
  exploitation: {
    id: number;
    type: 'exploitation';
    user_id: number;
    static_state: {
      name: string;
      position: {
        address: string;
      };
      crm_id: string;
    };
  };
  warehouse: {
    id: number;
    type: 'warehouse';
    user_id: number;
    static_state: {
      exploitation_id: number;
      name: string;
    };
  };
}

@Component({
  selector: 'bg2-new-exploitation-modal',
  templateUrl: './new-exploitation.modal.html',
  styleUrls: ['./new-exploitation.modal.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class NewExploitationModal extends AbstractFormModalComponent<ModalParams, any, FormModelData> implements OnInit, OnDestroy {
  // #region -> (component basics)

  private data_schema_sub: Subscription = null;

  constructor(
    private _bg2Api: Beeguard2Api,
    private _deviceApi: DeviceApi,
    private _appState: AppStateService,
    private _dialogsService: DialogsService,
    private _clipboard: Clipboard,
    private readonly _zohoAuthService: ZohoAuthService,
    private readonly _zohoCRMApiService: ZohoCRMApiService
  ) {
    super();
    this._logger.update_prefix('NewExploitationModal');
  }

  ngOnInit(): void {
    this.exploitation = new Exploitation(this._bg2Api, this._deviceApi);
    this.warehouse = new Warehouse(this._bg2Api, this._deviceApi);
    this.warehouse.exploitation_id = -1;

    this.loading = true;

    // Load available users and schemas
    const warehouse_schema$$ = this.warehouse.schema$$;
    const exploitation_schema$$ = this.exploitation.schema$$;

    this.data_schema_sub = combineLatest([exploitation_schema$$, warehouse_schema$$]).subscribe({
      next: ([exploitation_schema, warehouse_schema]) => {
        this.warehouse_schema = warehouse_schema;
        this.exploitation_schema = exploitation_schema;

        this.computeFormSchema();

        this.loading = false;
        this.initial_loading = false;
      },
      error: (error: unknown) => {
        // TODO: Manage the error
        this._logger.error(error);

        this.loading = false;
        this.initial_loading = false;

        this.warehouse_schema = null;
        this.exploitation_schema = null;
      },
    });
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();

    this.data_schema_sub?.unsubscribe();

    if (!this.creation_done) {
      if (this.exploitation) {
        this.exploitation.preDestroy();
        delete this.exploitation;
      }

      if (this.warehouse) {
        this.warehouse.preDestroy();
        delete this.warehouse;
      }
    }
  }

  /** */
  public close(): void {
    if (this.is_submitting || this.is_trying_to_close_modal) {
      return;
    }

    if (this?.exploitation?.has_changed && this.form_ready) {
      this.is_trying_to_close_modal = true;

      this._dialogsService
        .confirm(i18n<string>('VIEWS.MODALS.FORM.Abandon the event creation ?'), {
          onFalseMessage: i18n<string>('VIEWS.MODALS.FORM.Come back'),
          onTrueMessage: i18n<string>('VIEWS.MODALS.FORM.Close without saving'),
        })
        .subscribe(agreement => {
          this.is_trying_to_close_modal = false;

          if (agreement) {
            super.close(null);
          }
        });
    } else {
      super.close(null);
    }
  }

  // #endregion

  // #region -> (modal basics)

  /** */
  protected handle_event_before_unload(event: BeforeUnloadEvent): void {
    if (this.exploitation?.has_changed) {
      event.returnValue = true;
    }
  }

  // #endregion

  // #region -> (entities management)

  private creation_done = false;

  private warehouse: Warehouse = null;
  private exploitation: Exploitation = null;

  private warehouse_schema: EntitySchema = null;
  private exploitation_schema: EntitySchema = null;

  public updateAppStateWithNewEntity() {
    this._appState.addExploitations(this.exploitation);
    this._appState.selectExploitations([this.exploitation]);
    this._appState.loadExploitations();
  }

  // #endregion

  // #region -> (zoho management)

  /** */
  public is_authenticated_to_zoho$$ = this._zohoAuthService.is_authenticated$$;

  // #endregion

  // #region -> (form management)

  /** */
  public zoho_crm_id$$ = this.form_model$$.pipe(
    map(form_model => form_model?.exploitation?.static_state?.crm_id ?? null),
    replay()
  );

  // #endregion

  // #region -> (schema form management)

  private computeFormSchema(): any {
    if (isNil(this.exploitation) || isNil(this.warehouse)) {
      return null;
    }

    const schema: ISchema = {
      type: 'object',
      properties: {
        owner: {
          label: i18n('VIEWS.MODALS.NEW_ENTITY.Owner'),
          type: 'number',
          widget: 'bg2user-select',
        },
        exploitation: {
          type: 'object',
          properties: {
            static_state: this.exploitation_schema.static_state_schema,
          },
          required: ['static_state'],
        },
        warehouse: {
          type: 'object',
          properties: {
            static_state: this.warehouse_schema.static_state_schema,
          },
          required: ['static_state'],
        },
      },
      required: ['owner', 'exploitation', 'warehouse'],
    };

    this.form_schema = null;

    setTimeout(() => {
      this.form_schema = schema;
      this.computeFormModel();
    }, 2);
  }

  private computeFormModel(): void {
    if (isNil(this.exploitation) || isNil(this.warehouse)) {
      return;
    }

    if (!isNil(this.args)) {
      this.exploitation.static_state.name = this.args.expl_name;
      this.exploitation.static_state.position = this.args.position;
      this.exploitation.static_state.crm_id = this.args.crm_id || null;
      this.warehouse.static_state.name = this.args.expl_name;
    }

    const data: FormModelData = {
      exploitation: <any>this.exploitation.toJSON(),
      warehouse: <any>this.warehouse.toJSON(),
      owner: this.args?.bg_user_id,
    };

    this.form_model = data;
  }

  protected onFormChanged(fdata: any): any {
    if (!this.form_ready) {
      return;
    }

    if (isNil(this.exploitation) || isNil(this.warehouse)) {
      return;
    }

    fdata = fdata.value;

    if (fdata.owner) {
      this.exploitation.user_id = fdata.owner;
      this.warehouse.user_id = fdata.owner;
    }

    if (fdata.exploitation) {
      this.exploitation.static_state = fdata.exploitation.static_state;
      this.warehouse.name = `${this.exploitation.name}`;
    }

    if (fdata.warehouse) {
      this.warehouse.static_state = fdata.warehouse.static_state;
    }
  }

  public submit() {
    this.error = null;
    this.loading = true;
    this.is_submitting = true;

    this.exploitation
      .save()
      .pipe(
        take(1),
        catchError((error: unknown) => {
          this._logger.error(error);
          this.error = i18n('VIEWS.MODALS.NEW_ENTITY.Impossible to create the entity');
          throw error;
        }),
        map(ret => {
          this.exploitation.deserialize(ret.entity);
          return this.exploitation;
        }),
        switchMap(exploitation => {
          this.warehouse.name = exploitation.name;
          this.warehouse.exploitation_id = exploitation.id;
          return this.warehouse.save().pipe(
            take(1),
            catchError((error: unknown) => {
              this._logger.error(error);
              this.error = i18n('VIEWS.MODALS.NEW_ENTITY.Impossible to setup the entity');
              return exploitation.delete().pipe(
                take(1),
                map(res => {
                  throw error;
                })
              );
            }),
            map(ret => {
              this.warehouse.deserialize(ret.entity);
              exploitation.warehouse_id = this.warehouse.id;
              return this.warehouse;
            }),
            map(warehouse => ({ warehouse, exploitation }))
          );
        }),
        switchMap(({ exploitation, warehouse }) => {
          if (isNil(exploitation?.static_state?.crm_id) || isEmpty(exploitation?.static_state?.crm_id)) {
            return of({ exploitation, warehouse });
          }

          return this.is_authenticated_to_zoho$$.pipe(
            take(1),
            switchMap(is_connected_to_zoho => {
              if (!is_connected_to_zoho) {
                return openExploitationCRMUpdateModal(
                  this._dialogsService,
                  this._clipboard,
                  exploitation,
                  i18n('ROUTABLE_MODALS.NEW_EXPLOITATION.CREATED.Exploitation has been created')
                );
              }

              return this._zohoCRMApiService.update_account$(exploitation.static_state?.crm_id, {
                exploitation_id: exploitation?.id.toString(),
              });
            })
          );
        })
      )
      .subscribe({
        next: () => {
          console.log('next');

          this.loading = false;
          this.creation_done = true;
          this.is_submitting = false;
          this.initial_loading = false;
          this.updateAppStateWithNewEntity();

          super.close();
        },
        error: (error: unknown) => {
          this.loading = false;
          this.initial_loading = false;
        },
      });
  }

  // http://app.beeguard.test:4200/locations/all(modal:new_expl;args=%7B"expl_name":"MASSARD%20Jean-Daniel","crm_id":"219345000018188003","position":%7B"address":"7%20Rue%20Du%20Pic%20MAOU,%2031600%20MURET,%20France"%7D%7D)?offset=0&se%5B%5D=641,1935

  // #endregion
}
