<div class="pull-down-filters-component" [class.isCompact]="compact$$ | async">
  <div class="main-base-filters" *ngIf="(compact$$ | async) === false">
    <ng-content select="[main]"></ng-content>
  </div>

  <div class="main-compact-filters" *ngIf="compact$$ | async">
    <ng-content select="[main-compact]"></ng-content>
  </div>

  <div class="active-filters">
    <div class="active-filters-inner">
      <bg2-button
        class="handle-filter"
        [type]="'navigation'"
        [matBadgeColor]="'primary'"
        [icon]="'mdi-filter-plus'"
        [options]="{ is_thin: true }"
        [message]="'VIEWS.LOCATION.Filters' | translate"
        [matBadge]="((filters$$ | async)?.active_filters__total$$ | async) ?? null"
        (btn_click)="open()"
      >
      </bg2-button>

      <ng-container *ngLet="filters$$ | async; let filters">
        <ng-container *ngIf="filters.active_filters$$ | async; let active_filters">
          <ng-container *ngIf="active_filters.length > 0">
            <div *ngFor="let active_filter of active_filters" class="active-filter" matRipple (click)="filters.clear(active_filter.name)">
              <span>{{ active_filter.label | translate: { data: active_filter.data } }}</span>
              <span class="mdi mdi-close mdi-18px"></span>
            </div>
          </ng-container>
        </ng-container>
      </ng-container>
    </div>
  </div>
</div>
