<div class="table-groups-breadcrumb">
  <span>{{ 'VIEWS.HIVES.COMPONENTS.HIVES_GROUPS_BREADCRUMB.Group hives by (recursive) :' | translate }}</span>
  <ul>
    <ng-container *ngIf="labels_by_group_name$$ | async as labels">
      <ng-container *ngFor="let group of (groups$$ | async)">
        <li class="breadcrumb-item">
          <div (click)="onRemoveGroup(group)">
            <span class="mdi mdi-close mdi-18px">{{ labels[group] | translate }}</span>
          </div>
        </li>
      </ng-container>
    </ng-container>
    <ng-container *ngIf="options$$ | async as options">
      <li *ngIf="options.length >= 1">
        <mat-form-field>
          <mat-label translate>VIEWS.DATATABLE.Column name</mat-label>
          <mat-select (selectionChange)="onUpdate($event)">
            <mat-option *ngFor="let option of options" [value]="option.property">
              {{ option.label | translate }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </li>
    </ng-container>
  </ul>
</div>