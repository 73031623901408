<bg2-modal [fullscreen]="fullscreen || false" #modal>
  <bg2-modal-header>
    <div class="header">
      <span class="mdi mdi-account-plus mdi-36px"></span>
      <span class="title">{{ 'ROUTABLE_MODALS.NEW_USER_MODAL.Modal title' | translate }}</span>
    </div>

    <div class="action-buttons">
      <span class="mdi mdi-close-box mdi-36px" (click)="close()"></span>
    </div>
  </bg2-modal-header>

  <bg2-modal-content>
    <bg2-form-overlay
      [source_model]="form_model$$ | async"
      [source_schema]="form_schema$$ | async"
      (when_form_data_update)="onFormChanged($event)"
      (when_form_valid_update)="update_form_validity($event)"
    ></bg2-form-overlay>
  </bg2-modal-content>

  <bg2-modal-footer>
    <bg2-button style="flex: 1 1 0%" [type]="'cancel'" [message]="'ALL.ACTIONS.Cancel' | translate" (btn_click)="close()"></bg2-button>

    <bg2-button
      style="flex: 1 1 0%"
      [type]="'action'"
      [icon]="'mdi-content-save'"
      [message]="'ALL.ACTIONS.Create' | translate"
      [disabled]="(form_valid$$ | async) === false"
      (btn_click)="submit()"
    ></bg2-button>
  </bg2-modal-footer>
</bg2-modal>
