import { marker as i18n } from '@biesbjerg/ngx-translate-extract-marker';

import { map, Observable } from 'rxjs';

import { LocationEntityUserAce, location_user_ace_to_i18n } from '../enums/location-user-acl.enum';

import { ErrorHelperData } from 'app/widgets/widgets-reusables/errors/error-helper/error-helper.component';
import { AbstractEntityUserACL, EntityUserAclContext } from '../../00_abstract';

/** */
export class LocationEntityUserACL extends AbstractEntityUserACL<keyof typeof LocationEntityUserAce> {
  // #region -> (specific ACL checks)

  /**
   * Check if the current user can read events.
   *
   * @returns An observable with the error model else null.
   *
   * @public
   * @observable
   */
  public check_read_all_events$$ = (context: EntityUserAclContext): Observable<ErrorHelperData | null> =>
    this.can$$('read_all_events').pipe(
      map(can_read_devices => {
        if (!can_read_devices) {
          return new ErrorHelperData([
            {
              type: 'image_svg',
              url: '/assets/img/pictos/lock.svg',
              styles: {
                width: '40px',
              },
            },
            {
              type: 'ace',
              content: i18n<string>("ALL.ACE.COMMON.You can't **[what]**"),
              translateParams: {
                what: context.what,
              },
            },
            {
              type: 'ace',
              content: i18n<string>('ALL.ACE.COMMON.Ask **the location\'s manager** to grant you the following permission: "**[ace]**"'),
              translateParams: {
                ace: location_user_ace_to_i18n.get('read_all_events'),
              },
            },
          ]);
        }

        return null;
      })
    );

  /**
   * Check if the current user can write events
   *
   * @returns An observable with the error model else null.
   *
   * @public
   * @observable
   */
  public check_write_all_events$$ = (context: EntityUserAclContext): Observable<ErrorHelperData | null> =>
    this.can$$('write_all_events').pipe(
      map(can_read_devices => {
        if (!can_read_devices) {
          return new ErrorHelperData([
            {
              type: 'image_svg',
              url: '/assets/img/pictos/lock.svg',
              styles: {
                width: '40px',
              },
            },
            {
              type: 'ace',
              content: i18n<string>("ALL.ACE.COMMON.You can't **[what]**"),
              translateParams: {
                what: context.what,
              },
            },
            {
              type: 'ace',
              content: i18n<string>('ALL.ACE.COMMON.Ask **the location\'s manager** to grant you the following permission: "**[ace]**"'),
              translateParams: {
                ace: location_user_ace_to_i18n.get('write_all_events'),
              },
            },
          ]);
        }

        return null;
      })
    );

  /**
   * Check if the current user can read precise position.
   *
   * @returns An observable with the error model else null.
   *
   * @public
   * @observable
   */
  public check_read_precise_position$$ = (context: EntityUserAclContext): Observable<ErrorHelperData | null> =>
    this.can$$('read_precisse_position').pipe(
      map(can_read_precise_position => {
        if (!can_read_precise_position) {
          return new ErrorHelperData([
            {
              type: 'image_svg',
              url: '/assets/img/pictos/lock.svg',
              styles: {
                width: '40px',
              },
            },
            {
              type: 'ace',
              content: i18n<string>("ALL.ACE.COMMON.You can't **[what]**"),
              translateParams: {
                what: context.what,
              },
            },
            {
              type: 'ace',
              content: i18n<string>('ALL.ACE.COMMON.Ask **the location\'s manager** to grant you the following permission: "**[ace]**"'),
              translateParams: {
                ace: location_user_ace_to_i18n.get('read_precisse_position'),
              },
            },
          ]);
        }

        return null;
      })
    );

  /**
   * Check if the current user can read last_position.
   *
   * @returns An observable with the error model else null.
   *
   * @public
   * @observable
   */
  public check_read_read_devices_last_position$$ = (context: EntityUserAclContext): Observable<ErrorHelperData | null> =>
    this.can$$('read_devices_last_position').pipe(
      map(can_read_devices_last_position => {
        if (!can_read_devices_last_position) {
          return new ErrorHelperData([
            {
              type: 'image_svg',
              url: '/assets/img/pictos/lock.svg',
              styles: {
                width: '40px',
              },
            },
            {
              type: 'ace',
              content: i18n<string>("ALL.ACE.COMMON.You can't **[what]**"),
              translateParams: {
                what: context.what,
              },
            },
            {
              type: 'ace',
              content: i18n<string>('ALL.ACE.COMMON.Ask **the location\'s manager** to grant you the following permission: "**[ace]**"'),
              translateParams: {
                ace: location_user_ace_to_i18n.get('read_devices_last_position'),
              },
            },
          ]);
        }

        return null;
      })
    );

  /**
   * Check if the current user can read approximate position.
   *
   * @returns An observable with the error model else null.
   *
   * @public
   * @observable
   */
  public check_read_aprox_position$$ = (context: EntityUserAclContext): Observable<ErrorHelperData | null> =>
    this.can$$('read_aproximate_position').pipe(
      map(can_read_aproximate_position => {
        if (!can_read_aproximate_position) {
          return new ErrorHelperData([
            {
              type: 'image_svg',
              url: '/assets/img/pictos/lock.svg',
              styles: {
                width: '40px',
              },
            },
            {
              type: 'ace',
              content: i18n<string>("ALL.ACE.COMMON.You can't **[what]**"),
              translateParams: {
                what: context.what,
              },
            },
            {
              type: 'ace',
              content: i18n<string>('ALL.ACE.COMMON.Ask **the location\'s manager** to grant you the following permission: "**[ace]**"'),
              translateParams: {
                ace: location_user_ace_to_i18n.get('read_aproximate_position'),
              },
            },
          ]);
        }

        return null;
      })
    );

  // #endregion

  // #region -> (error-scoped ACE check)

  /**
   * Check if the current user cannot do specific ace.
   *
   * @returns An observable with the error model else null.
   *
   * @public
   * @observable
   */
  public throw__if_cannot$$ = (ace: keyof typeof LocationEntityUserAce, context: string): Observable<ErrorHelperData | null> =>
    this.can$$(ace).pipe(
      map(can_do_ace => {
        if (!can_do_ace) {
          return new ErrorHelperData([
            {
              type: 'image_svg',
              url: '/assets/img/pictos/lock.svg',
              styles: {
                width: '40px',
              },
            },
            {
              type: 'ace',
              content: i18n<string>("ALL.ACE.COMMON.You can't **[what]**"),
              translateParams: {
                what: context,
              },
            },
            {
              type: 'ace',
              content: i18n<string>('ALL.ACE.COMMON.Ask **the location\'s manager** to grant you the following permission: "**[ace]**"'),
              translateParams: {
                ace: location_user_ace_to_i18n.get(ace),
              },
            },
          ]);
        }

        return null;
      })
    );

  // #endregion
}
