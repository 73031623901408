import { isNil, isEqual, cloneDeep, merge as mergeDict, clone, isUndefined, max, min, isArray, isDate, sortBy } from 'lodash-es';

import { marker as i18n } from '@biesbjerg/ngx-translate-extract-marker';

import { Observable, BehaviorSubject, combineLatest, of, timer } from 'rxjs';
import { map, switchMap, tap, filter, debounceTime, skipUntil } from 'rxjs';

import { ISchema } from 'ngx-schema-form';

import { distinctUntilRealChanged, replay } from '@bg2app/tools/rxjs';
import { UrlParamsService } from 'app/core/url-param.service';
import { Dictionary } from 'app/typings/core/interfaces';
import { AppStateService } from 'app/core/app-state.service';
import { format } from 'date-fns';
import { parseDate } from 'app/misc/tools';
import { ConsoleLoggerService } from 'app/core/console-logger.service';

export interface ActiveFilter {
  label: string;
  data: Dictionary<any>;
}

export interface ActiveFilterView extends ActiveFilter {
  name: string;
}

export interface GenericFilterOptions<FilterType, ApplyOnType> {
  url_param_name?: string;
  chip_i18n?: string;
  disable_chip?: boolean;
  reset_value?: FilterType;
  only_url_param_if_active?: boolean;
  is_active?: (filter: GenericFilter<FilterType, ApplyOnType>) => boolean;
  compute_chip?: (filter: GenericFilter<FilterType, ApplyOnType>) => Observable<ActiveFilter>;
  apply?: (filter: GenericFilter<FilterType, ApplyOnType>, input: ApplyOnType) => Observable<ApplyOnType>;
  transform_value?: (value: any) => FilterType;
  parse_url_value?: (value: any) => any;
  parse_value_to_url?: (value: any) => any;
}

// #region -> (base filter)

export class GenericFilter<FilterType, ApplyOnType> {
  protected _logger: ConsoleLoggerService;

  protected _name: string;
  protected _url_param_name: string;
  protected _options: GenericFilterOptions<FilterType, ApplyOnType> = {};

  protected _schema$$ = new BehaviorSubject<ISchema>({});
  public schema$$ = this._schema$$.asObservable();

  public default$$: Observable<FilterType> = this.schema$$.pipe(map(schema => schema?.default ?? null));

  public reset_value$$ = this.default$$.pipe(
    map(() => this.reset_value) // NOTE: this is a shortcut to get a obs version of reset value
  );

  protected _value: FilterType;
  protected _value$$ = new BehaviorSubject<FilterType>(null);
  public value$$: Observable<FilterType> = this._value$$.asObservable().pipe(
    // tap(val => this._logger.debug('raw val', val)),
    switchMap(value => {
      if (isNil(value)) {
        return this.default$$;
      } else {
        return of(value);
      }
    }),
    distinctUntilRealChanged(),
    tap(val => this._logger.debug('new value:', val)),
    tap(value => (this._value = value)),
    replay()
  );

  public actif$$ = combineLatest([this.value$$, this.reset_value$$]).pipe(
    map(([_value, reset_value]) => this.is_active(_value, reset_value)),
    distinctUntilRealChanged(),
    replay()
  );

  public chip$$: Observable<ActiveFilterView> = combineLatest([this.value$$, this.actif$$]).pipe(
    switchMap(([value, actif]) => (actif && this.is_chip_enable() ? this.compute_chip(value) : of<ActiveFilter>(null))),
    map(chip => {
      if (isNil(chip)) {
        return null;
      }
      (chip as ActiveFilterView).name = this.name;
      return chip as ActiveFilterView;
    }),
    replay()
  );

  constructor(name: string, schema: ISchema, options: GenericFilterOptions<FilterType, ApplyOnType> = null) {
    this._name = name;
    this._logger = new ConsoleLoggerService(`filter: ${this.name}`, false);
    this._options = mergeDict(this._options, options);
    this._url_param_name = this._options.url_param_name;
    this._schema$$.next(schema);
  }

  public get name(): string {
    return this._name;
  }

  protected transform_value(_value: any): FilterType {
    if (this._options.transform_value) {
      _value = this._options.transform_value(_value);
    }
    return _value;
  }

  public get value(): FilterType {
    return this._value;
  }

  public set value(_value: FilterType) {
    const _set_value = this.transform_value(_value);
    this._value$$.next(clone(_set_value));
  }

  public set(_value: any) {
    this.value = _value;
  }

  public get url_param_name(): string {
    return this._url_param_name || this.name;
  }

  public set value_from_url(_value: any) {
    this._value$$.next(clone(_value));
  }

  public get default() {
    return this._schema$$?.getValue()?.default ?? null;
  }

  public get reset_value(): FilterType {
    if (!isUndefined(this._options.reset_value)) {
      return this._options.reset_value;
    } else {
      return this.default;
    }
  }

  public is_chip_enable() {
    return !(this._options.disable_chip || false);
  }

  protected compute_chip(value: FilterType): Observable<ActiveFilter> {
    if (this._options?.compute_chip) {
      return this._options.compute_chip(this);
    }
    return of(
      mergeDict(
        {
          label: this._options?.chip_i18n || `${value}`,
          data: { value },
        },
        { name: this.name }
      )
    );
  }

  protected is_active(value: FilterType, reset_value: FilterType) {
    if (this._options?.is_active) {
      return this._options.is_active(this);
    } else {
      return !isEqual(value, reset_value);
    }
  }

  public clear() {
    this.value = cloneDeep(this.reset_value);
  }

  public parse_url_value(value: any): FilterType {
    if (this._options.parse_url_value) {
      return this._options.parse_url_value(value);
    } else {
      return value as FilterType;
    }
  }

  private ingnore_next_url_param_update = false;

  public sync_url_params(url_params: UrlParamsService): Observable<number> {
    const on_url_param_changed$$ = url_params.on_change(this.url_param_name).pipe(
      distinctUntilRealChanged(),
      map(value => this.parse_url_value(value)),
      tap(value => {
        if (this.value !== value && !this.ingnore_next_url_param_update) {
          this.value = value as FilterType;
          this._logger.debug('update value from url param: ', value);
        } else {
          this._logger.debug('Ignore value from url param: ', this.value !== value, !this.ingnore_next_url_param_update);
        }
      }),
      tap(() => (this.ingnore_next_url_param_update = false))
    );

    const on_value_changed$$ = combineLatest([this.actif$$, this.value$$]).pipe(
      skipUntil(timer(500)), // ignore default value ?
      debounceTime(200),
      map(([actif, value]) => ({ actif, value })),
      distinctUntilRealChanged(),
      tap(() => (this.ingnore_next_url_param_update = true)),
      tap(({ actif, value }) => {
        this._logger.debug('update url param from value: ', { name: this._name, value });
        if (actif || !this._options?.only_url_param_if_active) {
          const modified_value = this._options?.parse_value_to_url ? this._options.parse_value_to_url(value) : value;
          url_params.set(this.url_param_name, modified_value);
        } else {
          url_params.set(this.url_param_name, this.reset_value ?? null);
        }
      })
    );

    return combineLatest([on_value_changed$$, on_url_param_changed$$]).pipe(map(() => 0));
  }

  public apply(input: ApplyOnType): Observable<ApplyOnType> {
    input = clone(input);
    if (this._options?.apply) {
      return this.value$$.pipe(switchMap(() => this._options.apply(this, input)));
    }
    return this.value$$.pipe(map(() => input));
  }
}

// #endregion

// #region -> (refined filter)

export class StringFilter<ApplyOnType> extends GenericFilter<string, ApplyOnType> {
  constructor(name: string, schema: ISchema, options: GenericFilterOptions<string, ApplyOnType>) {
    schema.type = 'string';
    super(name, schema, options);
  }
}

export class BooleanFilter<ApplyOnType> extends GenericFilter<boolean, ApplyOnType> {
  constructor(name: string, schema: ISchema, options: GenericFilterOptions<boolean, ApplyOnType>) {
    schema.type = 'boolean';
    super(name, schema, options);
  }
}

export class NumberFilter<ApplyOnType> extends GenericFilter<number, ApplyOnType> {
  constructor(name: string, schema: ISchema, options: GenericFilterOptions<number, ApplyOnType>) {
    schema.type = 'number';
    super(name, schema, options);
  }

  public sync_url_params(url_params: UrlParamsService): Observable<number> {
    url_params.castAsInt(this.url_param_name);
    return super.sync_url_params(url_params);
  }
}

export interface MinMaxFilterOptions<ApplyOnType> extends GenericFilterOptions<[number, number], ApplyOnType> {
  is_active?: (filter: MinMaxFilter<ApplyOnType>) => boolean;
  compute_chip?: (filter: MinMaxFilter<ApplyOnType>) => Observable<ActiveFilter>;
  apply?: (filter: MinMaxFilter<ApplyOnType>, input: ApplyOnType) => Observable<ApplyOnType>;
  one_value_chip_i18n: string;
  two_values_chip_i18n: string;
}

export class StringArrayFilter<ApplyOnType> extends GenericFilter<string[], ApplyOnType> {
  private static default_schema = {
    default: [] as string[],
  };

  private static needed_schema = {
    type: 'array',
    items: {
      type: 'string',
    },
  };

  constructor(name: string, schema: ISchema, options: GenericFilterOptions<string[], ApplyOnType>) {
    super(name, mergeDict(cloneDeep(StringArrayFilter.default_schema), schema, StringArrayFilter.needed_schema), options);
  }

  public sync_url_params(url_params: UrlParamsService): Observable<number> {
    url_params.castAsArray(this.url_param_name);
    return super.sync_url_params(url_params);
  }
}

export class NumberArrayFilter<ApplyOnType> extends GenericFilter<number[], ApplyOnType> {
  private static default_schema = {
    default: [] as number[],
  };

  private static needed_schema = {
    type: 'array',
    items: {
      type: 'number',
    },
  };

  constructor(name: string, schema: ISchema, options: GenericFilterOptions<number[], ApplyOnType>) {
    super(name, mergeDict(cloneDeep(NumberArrayFilter.default_schema), schema, NumberArrayFilter.needed_schema), options);
  }

  public sync_url_params(url_params: UrlParamsService): Observable<number> {
    url_params.castAsIntArray(this.url_param_name);
    return super.sync_url_params(url_params);
  }
}

export class MinMaxFilter<ApplyOnType> extends GenericFilter<[number, number], ApplyOnType> {
  private static default_schema = {
    widget: 'ng-mat-number-range',
    items: {
      type: 'number',
      minimum: 0,
      maximum: 100,
      multipleOf: 1,
    },
    default: [0, 100],
  };

  private static needed_schema = {
    type: 'array',
    items: {
      type: 'number',
    },
    minItems: 2,
    maxItems: 2,
    additionalItems: false,
  };

  protected _options: MinMaxFilterOptions<ApplyOnType> = {
    one_value_chip_i18n: '{{value}}',
    two_values_chip_i18n: '{{data.min}} - {{max}}',
  };

  constructor(name: string, schema: ISchema, options: MinMaxFilterOptions<ApplyOnType>) {
    super(name, mergeDict(cloneDeep(MinMaxFilter.default_schema), schema, MinMaxFilter.needed_schema), options);
    this._options = mergeDict(this._options, options);
  }

  protected transform_value(_value: any): [number, number] {
    if (isNil(_value) || (_value as number[]).length === 0) {
      return;
    }
    if (_value.length > 2) {
      _value = _value.slice(0, 2) as [number, number];
    } else if (_value.length === 1) {
      _value = [_value[0], _value[0]];
    }
    _value = [min(_value), max(_value)];
    _value = super.transform_value(_value);
    return _value;
  }

  get dict_value(): { min: number; max: number } {
    const value = this.value;
    return { min: value[0], max: value[1] };
  }

  public sync_url_params(url_params: UrlParamsService): Observable<number> {
    url_params.castAsIntArray(this.url_param_name);
    return super.sync_url_params(url_params);
  }

  protected is_active(value: [number, number], reset_value: [number, number]): boolean {
    return value[0] > reset_value[0] || value[1] < reset_value[1];
  }

  protected compute_chip(value: [number, number]): Observable<ActiveFilter> {
    const active_filter: ActiveFilter = {
      label: value[0] === value[1] ? this._options.one_value_chip_i18n : this._options.two_values_chip_i18n,
      data: {
        min: value[0],
        max: value[1],
        value: value[0],
      },
    };
    return of(active_filter);
  }
}

export class DateRangeFilter<ApplyOnType> extends GenericFilter<[Date, Date], ApplyOnType> {
  protected _app_state$$ = new BehaviorSubject<AppStateService>(null);

  protected _lang_dl$$ = this._app_state$$.pipe(
    filter(appState => !isNil(appState)),
    switchMap(appState => appState.lang$$.pipe(map(() => appState.dl))),
    replay()
  );

  constructor(name: string, schema: ISchema, options: GenericFilterOptions<[Date, Date], ApplyOnType>) {
    super(name, schema, options);
  }

  protected transform_value(value: any): [Date, Date] {
    if (!isArray(value)) {
      return [isDate(value) ? value : parseDate(value), null];
    }

    const first = isDate(value[0]) ? value[0] : parseDate(value[0]);
    const last = isDate(value[1]) ? value[1] : parseDate(value[1]);

    return sortBy([first, last]) as [Date, Date];
  }

  protected compute_chip(value: [Date, Date]): Observable<ActiveFilter> {
    return this._lang_dl$$.pipe(
      map(dl => {
        const [start, end] = value || [null, null];
        const active_filter: ActiveFilter = {
          label: 'DEFAULT',
          data: {
            start: !isNil(start) ? format(parseDate(start), dl.lll, { locale: dl.dateFns }) : null,
            end: !isNil(end) ? format(parseDate(end), dl.lll, { locale: dl.dateFns }) : null,
          },
        };

        if (!isNil(start) && isNil(end)) {
          active_filter.label = i18n<string>('VIEWS.EVENTS.CONFIGURATIONS.Since [data->start]');
        }

        if (!isNil(start) && !isNil(end)) {
          active_filter.label = i18n<string>('VIEWS.EVENTS.CONFIGURATIONS.Since [data->start] to [date->end]');
        }
        return active_filter;
      })
    );
  }

  public set_app_state(appState: AppStateService) {
    this._app_state$$.next(appState);
  }
}

// #endregion

// #region -> (specific filter)

export class SelectedExploitationsFilter<ApplyOnType> extends GenericFilter<number[], ApplyOnType> {
  constructor() {
    super(
      'selected_exploitation_ids',
      {
        type: 'array',
        widget: 'bg2-entity-exploitation',
        label: i18n<string>('ENTITY.EXPLOITATION.Exploitations'),
        etype: 'exploitation',
        options: {},
        items: {
          type: 'number',
        },
      },
      {
        url_param_name: 'se',
        apply: (_filter, query) => of(query),
        is_active: _filter => {
          const exploitation_ids = _filter?.value ?? [];
          return exploitation_ids.length > 0;
        },
        reset_value: [-1],
        disable_chip: true,
      }
    );
  }

  /** */
  public set_app_state(appstate: AppStateService) {
    // Bind selected exploitation ids to filter
    appstate.selected_exploitations_ids$$.pipe().subscribe({
      next: exploitation_ids => (this.value = exploitation_ids),
    });
  }
}

// #endregion
