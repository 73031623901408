
<div class="zone-datatable">

  <!-- #region .table-itself -->
  <div class="table-container" [class.empty]="data_source.data.length === 0">
    <table mat-table [dataSource]="data_source" matSort [matSortActive]="sort__active$$ | async" [matSortDirection]="sort__direction$$ | async" (matSortChange)="onSortData($event)">
  
      <!-- #region .headers-and-cells-templates -->
      <ng-container *ngFor="let column of flattened_columns$$ | async; trackBy:track_by_column" [matColumnDef]="column.property">
  
        <!-- #region .header-definitions -->
        <th mat-header-cell *matHeaderCellDef mat-sort-header [disabled]="!column?.is_sortable">
          <ng-container [ngSwitch]="column.property">
  
            <!-- Column type: "device.type" -->
            <ng-container *ngSwitchCase="'device.type'">
              {{ column.label | translate }}
            </ng-container>
  
            <!-- Column type: "device.owner" -->
            <ng-container *ngSwitchDefault>
              {{ column.label | translate }}
            </ng-container>
  
          </ng-container>
        </th>
        <!-- #endregion -->
  
        <!-- #region .content-definitions -->
        <td mat-cell *matCellDef="let msg_row" [attr.data-label]="column?.label | translate"
          [class.selected]="(isSelected$$(msg_row) | async)"
          (click)="column?.property === 'select' ? (selection_model.toggle(msg_row) + $event.stopPropagation()) : null">
          <ng-container [ngSwitch]="column.property">
  
            <ng-container *ngSwitchCase="'msg.tags.imei'">
              <a [routerLink]="['', { outlets: { 'modal': [
                  'device', { 'imei': msg_row?.msg?.tags?.imei }
                  ]} }]" [queryParamsHandling]="'preserve'">
                {{ msg_row?.msg?.tags?.imei }}
              </a>
            </ng-container>
  
            <!-- <ng-container *ngSwitchCase="'msg.fields.rssi'">
              <div *ngIf="msg_row?.msg?.fields?.rssi as rssi_level">
                <bg2-s868-level [open_in_modal]="false" [status]="{ timestamp: null, value: rssi_level, outdated: false }"></bg2-s868-level>
                {{rssi_level | localeNumber:'0.0-0'}} dB
              </div>
            </ng-container> -->
        
            <!-- Column type: "Date" -->
            <ng-container *ngSwitchCase="'msg.time'">
              <span>{{ msg_row?.msg?.time | formatDate:'full' }}</span>
            </ng-container>
  
            <ng-container *ngSwitchCase="'msg.fields.reception_timestamp'">
              <span *ngIf="msg_row?.msg?.fields?.reception_timestamp as date">
                {{ date | parseDate | formatDate:'full' }}
              </span>
            </ng-container>
        
            <ng-container *ngSwitchCase="'msg.fields.date_ref'">
              <span *ngIf="msg_row?.msg?.fields?.date_ref as date">
                {{ date | parseDate | formatDate:'full' }}
              </span>
            </ng-container>
  
            <!-- Column type: "device.name" -->
            <ng-container *ngSwitchDefault>
              <span>{{ get(msg_row, column.property) }}</span>
            </ng-container>
  
          </ng-container>
        </td>
        <!-- #endregion -->
  
      </ng-container>
      <!-- #endregion -->
  
      <!-- #region .row-definitions -->
        <tr mat-header-row *matHeaderRowDef="displayed_columns$$ | async"></tr>
        <tr mat-row *matRowDef="let msg_row; columns: displayed_columns$$ | async;" class="final-row">
        </tr>
      <!-- #endregion -->
  
    </table>
  </div>
  <!-- #endregion -->

  <!-- #region .table-no-data -->
  <div *ngIf="!(has_data$$ | async)" class="no-data">
    <div class="text">
      {{'VIEWS.DEVICES.COMPONENTS.DEVICES_DATATABLE.No matching device' | translate}}
    </div>
  </div>
  <!-- #endregion -->

  <!-- #region .table-pagination -->
  <!-- <ng-container *ngIf="paging$$ | async as paging;">
    <mat-paginator (page)="onPageChanged($event)" [disabled]="false" [length]="paging.total"
      [pageIndex]="paging.offset/paging.limit" [pageSize]="paging.limit" [pageSizeOptions]="PAGE_OPTIONS"
      showFirstLastButtons>
    </mat-paginator>
  </ng-container> -->
  <!-- #endregion -->

</div>
<!-- #endregion -->