import { Observable, map, switchMap } from 'rxjs';

export const keepSourceIfNoError = <X, E extends Error>(fct: (x: X) => Observable<E>) =>
  switchMap((source: X) =>
    fct(source).pipe(
      map(source_or_error => {
        if (source_or_error instanceof Error) {
          return <E>source_or_error;
        }

        return <X>source;
      })
    )
  );
