import { replay } from '@bg2app/tools/rxjs';
import { isEmpty, isNil } from 'lodash-es';
import { BehaviorSubject, combineLatest, map } from 'rxjs';

import { ZohoBaseModel } from '../../../../abstract';
import { IZohoDeskContact } from '../interfaces';

/** */
export class ZohoDeskContact extends ZohoBaseModel<IZohoDeskContact> {
  // #region -> (model basics)

  constructor() {
    super();
  }

  // #endregion

  // #region -> (account infos)

  /** */
  private _first_name$$ = new BehaviorSubject<string>(null);

  /** */
  public first_name$$ = this._first_name$$.asObservable();

  /** */
  public set firstName(first_name: string) {
    this._first_name$$.next(first_name);
  }

  /** */
  public get firstName(): string {
    return this._first_name$$.getValue();
  }

  /** */
  private _last_name$$ = new BehaviorSubject<string>(null);

  /** */
  public last_name$$ = this._last_name$$.asObservable();

  /** */
  public set lastName(last_name: string) {
    this._last_name$$.next(last_name);
  }

  /** */
  public get lastName(): string {
    return this._last_name$$.getValue();
  }

  /** */
  public full_name$$ = combineLatest([this.first_name$$, this.last_name$$]).pipe(
    map(([first_name, last_name]) => [first_name ?? '', (last_name ?? '').toUpperCase()].join(' ')),
    replay()
  );

  /** */
  private _email$$ = new BehaviorSubject<string>(null);

  /** */
  public email$$ = this._email$$.asObservable();

  /** */
  public set email(email: string) {
    this._email$$.next(email);
  }

  /** */
  public get email(): string {
    return this._email$$.getValue();
  }

  // #endregion

  // #region -> (link)

  /** */
  public url$$ = this.id$$.pipe(
    map(id => {
      if (isNil(id) || isEmpty(id)) {
        return null;
      }

      return `https://desk.zoho.eu/agent/beeguard/support-beeguard/contacts/details/${id}`;
    }),
    replay()
  );

  // #endregion

  // #region -> (related desk account)

  /** */
  private _account$$ = new BehaviorSubject<IZohoDeskContact['account']>(null);

  /** */
  public account$$ = this._account$$.asObservable();

  /** */
  public set account(account: IZohoDeskContact['account']) {
    this._account$$.next(account);
  }

  /** */
  public get account(): IZohoDeskContact['account'] {
    return this._account$$.getValue();
  }

  // #endregion

  // #region -> (related zoho CRM contact)

  /** */
  private _zoho_crm_contact$$ = new BehaviorSubject<{ id?: string }>(null);

  /** */
  public zoho_crm_contact$$ = this._zoho_crm_contact$$.asObservable();

  /** */
  public set zohoCRMContact(zoho_crm_contact: { id?: string }) {
    this._zoho_crm_contact$$.next(zoho_crm_contact);
  }

  /** */
  public get zohoCRMContact(): { id?: string } {
    return this._zoho_crm_contact$$.getValue();
  }

  /** */
  public has_related_zoho_crm_contact$$ = this.zoho_crm_contact$$.pipe(
    map(zoho_crm_contact => !isNil(zoho_crm_contact?.id)),
    replay()
  );

  // #endregion

  // #region -> (account additional infos)

  // #endregion
}
