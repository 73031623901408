import { debounceTime, map } from 'rxjs/operators';

import { values } from 'lodash-es';

import { Beeguard2Api } from 'app/core';
import { DRDevice } from 'app/models';
import { DevicesFromSameLocation } from 'app/core/ghost/models/ghost-solution';
import { parseDate } from './dates';
import { robustCombineLatest } from '.';

// eslint-disable-next-line @typescript-eslint/naming-convention
export const mergeDeviceByPreviousLocation = (devices: DRDevice[], bg2Api: Beeguard2Api) => {
  const device_x_affectations$$ = devices.map(device =>
    device.requestAffectation(bg2Api, true).pipe(map(affectation => ({ device, affectation })))
  );

  return robustCombineLatest(device_x_affectations$$).pipe(
    debounceTime(10),
    map(devices_affectations => {
      const dev_by_previous: { [location_id: number]: DevicesFromSameLocation } = {};

      devices_affectations.map(({ device, affectation }) => {
        const previous_location_id = affectation?.location?.entity?.id ?? 0;

        if (!dev_by_previous[previous_location_id]) {
          dev_by_previous[previous_location_id] = {
            apiary: affectation?.apiary?.entity ?? null,
            location: affectation?.location?.entity ?? null,
            exploitation: affectation?.exploitation?.entity ?? null,
            total: 0,
            devices_names: [],
            devices: [],
            affectations: [],
            setup_dates: [],
          };
        }

        dev_by_previous[previous_location_id].devices.push(device);
        dev_by_previous[previous_location_id].affectations.push(affectation);
        dev_by_previous[previous_location_id].devices_names.push(device.name);
        dev_by_previous[previous_location_id].total += 1;
        dev_by_previous[previous_location_id].setup_dates.push(parseDate(affectation.device_config.since?.date));
      });

      return values(dev_by_previous);
    })
  );
};
