import { NgModule, Type } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { SchemaFormModule } from 'ngx-schema-form';

import { MiscModule } from 'app/misc/misc.module';
import { SharedModule } from 'app/shared-module/shared.module';
import { MaterialSharedModule } from 'app/shared-module/material-shared.module';

import { Bg2ButtonComponent } from './buttons/button/button.component';
import { Bg2ButtonConditionComponent } from './buttons/button-condition/button-condition.component';

import { TimeBasedCarouselSlideComponent } from './carousels/time-based-carousel/time-carousel-slide/time-carousel-slide.component';

import { Bg2SimpleHistogramBaseComponent } from './charts/histograms/simple-histogram-base/simple-histogram-base.component';

import { Bg2SummaryWithCTAComponent } from './summaries/summary-with-cta/summary-with-cta.component';

import { Bg2ComingSoonComponent } from './errors/coming-soon/coming-soon.component';
import { Bg2ErrorHelperComponent } from './errors/error-helper/error-helper.component';
import { SkeletonLoadingComponent } from './loadings/loading-skeleton/loading-skeleton.component';

import { IASingleDialogComponent } from './beeguard-ia/ia-single-dialog/ia-single-dialog.component';

import { TitleSeparatorComponent } from './titles/title-separator/title-separator.component';

import { PiconQueenColorComponent } from './svg-picons/picon-queen-color/picon-queen-color.component';
import { PiconHiveStatusComponent } from './svg-picons/picon-hive-status/picon-hive-status.component';
import { PiconWindDirectionComponent } from './svg-picons/picon-wind-direction/picon-wind-direction.component';
import { PiconLocationEntityComponent } from './svg-picons/picon-location-entity/picon-location-entity.component';

import { EventBasedCarouselSlideComponent } from './carousels/event-based-carousel/event-carousel-slide/event-carousel-slide.component';
import { TimeBasedCarouselContainerComponent } from './carousels/time-based-carousel/time-carousel-container/time-carousel-container.component';
import { EventBasedCarouselContainerComponent } from './carousels/event-based-carousel/event-carousel-container/event-carousel-container.component';
import { AbstractCarouselGroupCardComponent } from './carousels/abstract-carousel/abtract-carousel-group-card/abstract-carousel-group-card.component';
import { NewBetaVersionSnackbarComponent } from './snackbars/new-beta-version-snackbar.component';
import { FormOverlayComponent } from './forms/bg2-form-overlay.component';
import { AclTooltipContentComponent } from './errors/acl/acl-tooltip-content/acl-tooltip-content.component';
import { ACLNotAccessibleComponent } from './errors/acl/acl-not-accessible/acl-not-accessible.component';

import { HexLoadingComponent } from './hex-loading/hex-loading.component';
import { ZohoAuthSnackbarComponent } from './snackbars/zoho-auth/zoho-auth-snackbar.component';
import { FilterAntennatSitesBottomSheetComponent } from './bottom-sheets/filter-antenna-sites-bottom-sheet/filter-antenna-sites-bottom-sheet.component';

// UI components
import { UIMessageComponent } from './ui-components/message/message.component';
import { SingleLineD3ChartComponent } from './charts/line/single-line-chart/single-line-chart.component';
import { FavoriteComponent } from './ui-components/favorite/favorite.component';
import { TopLayerAnnouncementComponent } from './ui-components/top-layer-announcement/top-layer-announcement.component';


const components_to_declare_and_export: (any[] | Type<any>)[] = [
  HexLoadingComponent,

  // Button components
  Bg2ButtonComponent,
  Bg2ButtonConditionComponent,

  // Carousel components
  TimeBasedCarouselSlideComponent,
  EventBasedCarouselSlideComponent,
  AbstractCarouselGroupCardComponent,

  // UI component
  FavoriteComponent,
  UIMessageComponent,

  // Titles
  TitleSeparatorComponent,


  // Charts
  SingleLineD3ChartComponent,
  Bg2SimpleHistogramBaseComponent,

  Bg2ErrorHelperComponent,

  Bg2SummaryWithCTAComponent,

  SkeletonLoadingComponent,
  Bg2ComingSoonComponent,
  AclTooltipContentComponent,
  ACLNotAccessibleComponent,

  IASingleDialogComponent,

  // Picons
  PiconHiveStatusComponent,
  PiconQueenColorComponent,
  PiconWindDirectionComponent,
  PiconLocationEntityComponent,

  ZohoAuthSnackbarComponent,
  NewBetaVersionSnackbarComponent,
  FormOverlayComponent,

  // bottom-sheets
  FilterAntennatSitesBottomSheetComponent,

  // ui-components
  TopLayerAnnouncementComponent,
];

@NgModule({
  declarations: [
    // Components to declare && to export
    ...components_to_declare_and_export,

    // Carousel components
    TimeBasedCarouselContainerComponent,
    EventBasedCarouselContainerComponent,
  ],
  imports: [
    SchemaFormModule,
    CommonModule,
    SharedModule,
    RouterModule,
    MaterialSharedModule,
    MiscModule,
  ],
  exports: [...components_to_declare_and_export],
})
export class WidgetsReusableModule {}
