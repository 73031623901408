import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { QueryParamsHandling } from '@angular/router';

@Component({
  selector: 'bg2-title-separator',
  templateUrl: './title-separator.component.html',
  styleUrls: ['./title-separator.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TitleSeparatorComponent {
  @Input()
  public title: string = 'UNDEFINED';

  @Input()
  public icon: `mdi-${string}` = undefined;

  @Input()
  public goto_link: {
    routerLink: any[] | string;
    queryParamsHandling?: QueryParamsHandling;
  } = null;
}
