<div class="bg2-apiary-charts-for-compact">
  <div *ngLet="which_chart_type_to_display$$ | async; let show_diff_weight_chart_value" class="charts-container">
    <bg2-apiary-weather-icons-d3-chart
      [margins]="{ right: 15, left: 45 }"
      [weather_data]="weather_data$$ | async"
      [geoposition]="location_position$$ | async"
      [is_loading]="loadings.weather_data$$ | async"
      [date_range]="date_range_manager.range$$ | async"
    ></bg2-apiary-weather-icons-d3-chart>

    <bg2-apiary-hives-temperature-d3-chart-component
      *ngIf="show_diff_weight_chart_value === 'temp_internal_linear'"
      [margins]="{ top: 5, right: 15 }"
      [geoposition]="location_position$$ | async"
      [temperature_data]="temperature_data$$ | async"
      [date_range]="date_range_manager.range$$ | async"
      [is_loading]="loadings?.temperature_data$$ | async"
    ></bg2-apiary-hives-temperature-d3-chart-component>

    <bg2-apiary-weight-d3-chart-component
      *ngIf="show_diff_weight_chart_value === 'weight_linear'"
      [margins]="{ top: 5, right: 15 }"
      [weight_data]="weight_data$$ | async"
      [geoposition]="location_position$$ | async"
      [is_loading]="loadings.weight_data$$ | async"
      [date_range]="date_range_manager.range$$ | async"
    ></bg2-apiary-weight-d3-chart-component>

    <bg2-apiary-hives-weight-diff-d3-chart
      *ngIf="show_diff_weight_chart_value === 'weight_diff'"
      [margins]="{ top: 5, right: 15 }"
      [geoposition]="location_position$$ | async"
      [is_loading]="loadings.weight_data$$ | async"
      [weight_diff_data]="weight_diff_data$$ | async"
      [date_range]="date_range_manager.range$$ | async"
    ></bg2-apiary-hives-weight-diff-d3-chart>

    <bg2-apiary-hives-beecount-d3-chart-component
      *ngIf="show_diff_weight_chart_value === 'counter'"
      [margins]="{ right: 15 }"
      [data]="counter_data$$ | async"
      [geoposition]="location_position$$ | async"
      [is_loading]="loadings.counting_data$$ | async"
      [date_range]="date_range_manager.range$$ | async"
    ></bg2-apiary-hives-beecount-d3-chart-component>

    <span class="charts-container__change-chart-type">
      <bg2-favorite
        [is_removable]="false"
        [is_favorite]="is_favorite_chart_type_selected$$ | async"
        [labels]="{
          add: 'WIDGETS.WIDGETS_REUSABLES.UI_COMPONENTS.FAVORITE.CUSTOM.Make it my preffered view' | translate,
          cannot_add: 'WIDGETS.WIDGETS_REUSABLES.UI_COMPONENTS.FAVORITE.CUSTOM.Already the preffered view' | translate,
          cannot_remove: 'WIDGETS.WIDGETS_REUSABLES.UI_COMPONENTS.FAVORITE.CUSTOM.Already the preffered view' | translate
        }"
        (favorite_changed)="when_user_add_view_to_favorite($event)"
      ></bg2-favorite>

      <span
        class="mdi"
        [ngClass]="{
          'mdi-counter': show_diff_weight_chart_value === 'counter',
          'mdi-chart-bar': show_diff_weight_chart_value === 'weight_diff',
          'mdi-chart-line': show_diff_weight_chart_value === 'weight_linear' || show_diff_weight_chart_value === 'temp_internal_linear'
        }"
      ></span>

      <mat-select [ngModel]="show_diff_weight_chart_value" (ngModelChange)="which_chart_type_to_display = $event">
        <mat-option [value]="'weight_diff'">
          {{ 'ALL.DATA.LABELS_FULL.Weight (differential)' | translate }}
        </mat-option>

        <mat-option [value]="'weight_linear'">
          {{ 'ALL.DATA.LABELS_FULL.Weight (linear)' | translate }}
        </mat-option>

        <mat-option [value]="'temp_internal_linear'">
          {{ 'ALL.DATA.LABELS_FULL.Internal temperature' | translate }}
        </mat-option>

        <mat-option *ngIf="has_devices_for_beecounting$$ | async" [value]="'counter'">
          {{ 'ALL.DATA.LABELS_FULL.BeeLive data' | translate }}
        </mat-option>
      </mat-select>
    </span>
  </div>

  <div class="footer-container">
    <bg2-apiary-charts-footer
      [location_id]="location_id$$ | async"
      [date_range_manager]="date_range_manager"
      [config]="{
        show_more_data_btn: false,
        show_save_range_tooltip: true,
        show_total_WG_sensors_changed: show_total_WG_sensors_changed$$ | async
      }"
    ></bg2-apiary-charts-footer>
  </div>
</div>
