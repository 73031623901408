<ng-container *ngIf="visible$$ | async">
  <div [id]="uid" class="select-widget">
    <form-element-header [schema]="schema" [showLabel]="false"></form-element-header>

    <div
      *ngIf="fullscreen_select_helper?.is_open_in_fullscreen.value$$ | async"
      id="fulscreen-select-blocker"
      (click)="fullscreen_select_helper.close(true)"
    ></div>

    <mat-form-field [appearance]="'outline'" [class.fullscreen]="fullscreen_select_helper?.is_open_in_fullscreen.value$$ | async">
      <mat-label>{{ schema?.label | translate }}</mat-label>

      <mtx-select
        #select
        [multiple]="false"
        [clearable]="true"
        [bindLabel]="'label'"
        [closeOnSelect]="true"
        [groupBy]="'group_name'"
        [items]="items$$ | async"
        [ngModel]="value$$ | async"
        [disabled]="schema.readOnly"
        [bindValue]="'tz_identifier'"
        [dropdownPosition]="'bottom'"
        [searchFn]="ngSelectCustomSearch"
        [appendTo]="'div#' + uid + ' mat-form-field .mat-form-field-wrapper'"
        (ngModelChange)="value = $event"
        (open)="fullscreen_select_helper.open()"
        (close)="fullscreen_select_helper.close()"
      >
        <!-- Template for selected option -->
        <ng-template ng-label-tmp let-item="item">
          <div class="selected-item">
            <div class="item">
              <img
                *ngIf="item?.country_code"
                [alt]="item.country_name"
                [src]="'https://flagcdn.com/' + (item.country_code | lowercase) + '.svg'"
              />
              <div class="label">{{ item.tz_identifier }}</div>
            </div>
          </div>
        </ng-template>

        <!-- Template for option in dropdown -->
        <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
          <div class="option-item">
            <div class="item">
              <img [src]="'https://flagcdn.com/' + (item.country_code | lowercase) + '.svg'" [alt]="item.country_name" />

              <div class="timezone-data">
                <div class="timezone-identifier">
                  <b [ngOptionHighlight]="search">{{ item.tz_identifier }}</b>
                  <span [ngOptionHighlight]="search">({{ item.country_name }})</span>
                </div>

                <div class="gmt-offset">
                  <span>{{ 'WIDGETS.EVENT_FORM.BG2_TIMEZONE_SELECT.TZ offset' | translate | colon }}</span>

                  <span [ngOptionHighlight]="search">{{ item.tz_offset }}</span>
                  <span [ngOptionHighlight]="search">({{ item.tz_abbr }})</span>
                </div>

                <div *ngIf="item?.summer_time; let summer_time" class="summer-time">
                  <span>{{ 'WIDGETS.EVENT_FORM.BG2_TIMEZONE_SELECT.Summer time' | translate | colon }}</span>

                  <span [ngOptionHighlight]="search">{{ summer_time.tz_offset }}</span>
                  <span [ngOptionHighlight]="search">({{ summer_time.tz_abbr }})</span>
                </div>
              </div>
            </div>
          </div>
        </ng-template>

        <ng-template ng-notfound-tmp let-searchTerm="searchTerm">
          <div class="ng-option disabled">{{ 'WIDGETS.EVENT_FORM.SELECT.Not found' | translate }}</div>
        </ng-template>
      </mtx-select>
    </mat-form-field>
  </div>
</ng-container>
