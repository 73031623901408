// From @node_modules/lodash/*
import { merge as _merge, clone as _clone } from 'lodash-es';

// From @local/*
import { MapMarkerType } from '../enumerators';

export const CONF_CIRCLE_DEFAULT_COLOR: L.PolylineOptions = {
  color: 'red',
  fillColor: 'red',
  fillOpacity: 0.25,
  interactive: false,
  stroke: true,
};

export const CONF_CIRCLE_COLORS: { [key in MapMarkerType]: L.PolylineOptions } = {
  /**
   * Default circle colors.
   */
  default: _clone(CONF_CIRCLE_DEFAULT_COLOR),

  /**
   * Circle colors for location entities.
   */
  location: _merge(_clone(CONF_CIRCLE_DEFAULT_COLOR), {
    color: '#bb4801',
    fillColor: '#bb4801',
    stroke: false,
  }),
  location_archived: _merge(_clone(CONF_CIRCLE_DEFAULT_COLOR), {
    color: '#fdb78c',
    fillColor: '#fdb78c',
    stroke: false,
  }),

  /**
   * Circle colors for device entities.
   */
  device_gprs: _merge(_clone(CONF_CIRCLE_DEFAULT_COLOR), {
    color: '#007f0d',
    fillColor: '#00ff1a',
  }),
  device_cpt: _merge(_clone(CONF_CIRCLE_DEFAULT_COLOR), {
    color: '#ff7f29',
    fillColor: '#FFD1B3',
  }),
  device_gps: _merge(_clone(CONF_CIRCLE_DEFAULT_COLOR), {
    color: '#007f0d',
    fillColor: '#00ff1a',
  }),
  device_rg: _merge(_clone(CONF_CIRCLE_DEFAULT_COLOR), {
    color: '#000099',
    fillColor: '#32dfe2',
  }),
  device_wg: _merge(_clone(CONF_CIRCLE_DEFAULT_COLOR), {
    color: '#31124a',
    fillColor: '#9d56d6',
  }),
  device_tg: _merge(_clone(CONF_CIRCLE_DEFAULT_COLOR), {
    color: '#ff3a2c',
    fillColor: '#d55f57',
  }),

  /**
   * Circle colors for position objects
   */
  pos_type_movement: _merge(_clone(CONF_CIRCLE_DEFAULT_COLOR), {
    color: 'red',
    fillColor: 'red',
  }),
  pos_type_periodic_location: _merge(_clone(CONF_CIRCLE_DEFAULT_COLOR), {
    color: 'orange',
    fillColor: 'orange',
  }),
  pos_type_stop: _merge(_clone(CONF_CIRCLE_DEFAULT_COLOR), {
    color: 'green',
    fillColor: 'green',
  }),

  mobile_nw_antenna_site: _merge(_clone(CONF_CIRCLE_DEFAULT_COLOR)),
};
