<div [id]="widget_id" class="device-widget">
  <form-element-header [schema]="schema" [showLabel]="false"></form-element-header>

  <div
    *ngIf="fullscreen_select_helper?.is_open_in_fullscreen?.value$$ | async"
    id="fulscreen-select-blocker"
    (click)="fullscreen_select_helper.close(true)"
  ></div>

  <ng-container *ngIf="devices_options$$ | async; let devices_options; else: device_loading">
    <mat-form-field [appearance]="'outline'" [class.fullscreen]="fullscreen_select_helper?.is_open_in_fullscreen?.value$$ | async">
      <mat-label>{{ this.schema.label | translate }}</mat-label>

      <mtx-select
        #select
        [bindValue]="'imei'"
        [bindLabel]="'name'"
        [groupBy]="$any(groupDevices)"
        [items]="devices_options"
        [ngModel]="value$$ | async"
        (ngModelChange)="imeis = $event"
        [typeahead]="devices_filter$$"
        [loading]="is_loading$$ | async"
        [searchable]="is_searchable$$ | async"
        [multiple]="options.multiple"
        [clearable]="options.nullable"
        [closeOnSelect]="!options.multiple"
        [disabled]="schema.readOnly"
        [class.valid]="(valid$$ | async)"
        [class.invalid]="(valid$$ | async) === false"
        [dropdownPosition]="'bottom'"
        [appendTo]="'div#' + widget_id + ' mat-form-field .mat-form-field-wrapper'"
        (open)="fullscreen_select_helper.open()"
        (close)="fullscreen_select_helper.close()"
      >
        <!-- Template for header -->
        <ng-template ng-header-tmp>
          <div>
            <span>{{ select_loading_sentence$$ | async }}</span>
          </div>
        </ng-template>

        <!-- Template for loading items  -->
        <ng-template ng-loadingtext-tmp let-searchTerm="searchTerm">
          <div class="ng-option disabled">
            <span *ngIf="searchTerm">"{{searchTerm}}" </span>
            <span translate>ALL.COMMON.Loading</span>
          </div>
        </ng-template>

        <!-- Template for not found item -->
        <ng-template ng-notfound-tmp let-searchTerm="searchTerm">
          <div class="ng-option disabled">{{ 'DEVICE.ALL.ERROR.No device found (at this date)' | translate }}</div>
        </ng-template>

        <!-- Template for label item -->
        <ng-template ng-label-tmp let-dopt="item">
          {{dopt.name || dopt.imei}}

          <span class="last-com" *ngIf="!options.multiple"
            >(<bg2-last-com
              [prefix]="'VIEWS.APIARY.SHARED.WIDGETS.APIARY_LAST_DATA.APIARY_LAST_DATA_CARD.Last com' | translate"
              [device]="dopt.device"
              [fulldate]="false"
            ></bg2-last-com
            >)</span
          >
        </ng-template>

        <!-- Template for item inside the dropdown -->
        <ng-template ng-optgroup-tmp let-item="item" let-index="index">
          <span class="mdi mdi-shape"></span>
          <span>&nbsp;{{ item.name | translate }}</span>
        </ng-template>

        <!-- Template for each item inside the dropdown -->
        <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
          <div *ngLet="as_device_conf(item); let dconf" class="item">
            <div class="device-container">
              <div class="device-container__base-infos">
                <span [ngOptionHighlight]="search">{{ dconf?.name ?? dconf?.imei }}</span>
                <span class="last-com">
                  <bg2-last-com
                    [fulldate]="false"
                    [device]="dconf.device"
                    [prefix]="'VIEWS.APIARY.SHARED.WIDGETS.APIARY_LAST_DATA.APIARY_LAST_DATA_CARD.Last com' | translate"
                  ></bg2-last-com>
                </span>
              </div>

              <div class="device-container__imei">
                <span class="mdi mdi-barcode"></span>
                <span [ngOptionHighlight]="search">{{ dconf?.imei ?? '--' }}</span>
              </div>
            </div>

            <div class="device-aff-container">
              <span *ngIf="dconf.associated" class="hive-in">
                <span *ngIf="!dconf.hive" translate>ALL.COMMON.Loading</span>
                <span *ngIf="dconf.hive; let hive">
                  <bg2-picto-hive *ngIf="dconf?.device?.type !== 'RG'" [hive]="hive"></bg2-picto-hive>
                  <span>{{ hive.name }}</span>
                  <span *ngIf="hive.location$$ | async as location"> - {{ $any(location).name }}</span>
                  <span *ngIf="hive.apiary$$ | async as apiary"> ({{ $any(apiary).name }})</span>
                </span>
              </span>

              <span *ngIf="dconf.wh_associated" class="wh-in">
                <span *ngIf="!dconf.wh" translate>ALL.COMMON.Loading</span>
                <span *ngIf="dconf.wh"> {{ dconf.wh?.name }}</span>
              </span>
            </div>
          </div>
        </ng-template>
      </mtx-select>
    </mat-form-field>
  </ng-container>

  <ng-template #device_loading>
    <hex-loading [inline]="true" [size]="'small'"></hex-loading>
  </ng-template>
</div>
