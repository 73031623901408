import { AsyncPipe } from '@angular/common';
import { ChangeDetectorRef, OnDestroy, Pipe, PipeTransform } from '@angular/core';

import { map } from 'rxjs';
import { distinctUntilRealChanged } from '@bg2app/tools/rxjs';

import { AppStateService } from 'app/core/app-state.service';

@Pipe({
  name: 'colon',
  pure: false,
})
export class ColonPipe implements PipeTransform, OnDestroy {
  // #region -> (pipe basics)

  private _async: AsyncPipe = null;

  constructor(private _appState: AppStateService, private _cdRef: ChangeDetectorRef) {
    this._async = new AsyncPipe(this._cdRef);
  }

  ngOnDestroy(): void {
    this._async.ngOnDestroy();
  }

  // #endregion

  public transform(sentence: string): string {
    return this._async.transform(
      this._appState.lang$$.pipe(
        map(language => (language === 'fr' ? `${sentence} : ` : `${sentence}: `)),
        distinctUntilRealChanged()
      )
    );
  }
}
