import { CompareByType } from 'app/misc/tools';

import { Dictionary } from 'app/typings/core/interfaces';
import { ConfigExportDatatableColumn } from 'app/typings/export';
import { DatatableBaseRow } from 'app/typings/datatable/interfaces/DatatableBaseRow.iface';
import { Observable } from 'rxjs';
import { SimpleSetterGetter } from '..';

type ApplicableTo = 'gl' | 'hv' | 'lc' | 'ay';

/** */
export interface AvailableAction<RowType extends DatatableBaseRow = DatatableBaseRow> {
  /** */
  type: string;

  /** */
  label: string;

  /** */
  icon?: `mdi-${string}` | `cs-${string}`;

  /**
   * Is the action is only for superadmin ?
   *
   * If the value is true, the action is removed from the list else not.
   */
  only_admin: boolean;

  /**
   * Is the action is disabled ?
   *
   * @note If no value is precised, disabled state will be computed from `compute_disabled$$()`
   */
  disabled?: boolean;

  /** */
  disabled_reason?: string;

  /**
   * Compute the disabled state.
   *
   * If no value is precised and disabled state is null, then `false` is used.
   */
  compute_disabled$$?: (selected_rows: (RowType | DatatableGroup<RowType>)[]) => Observable<boolean | Error>;

  /** */
  handler: (params: Dictionary<any>) => Observable<any>;

  /** */
  group?: {
    /** */
    name: string;

    /** */
    label: string;

    /** */
    icon?: `mdi-${string}`;
  };
}

/**
 * Datatable column data interface.
 */
export interface DatatableColumn<T extends DatatableBaseRow> {
  /**
   * Column property name. Also used as path to fetch the data.
   *
   * @note Can be precised with dots like `hive.name` or another.
   */
  property: string;

  /**
   * Short column property name used for url parameters.
   */
  short_property: `${string}`;
  // short_property: `${ApplicableTo}_${string}`;

  /**
   * The label of the column.
   *
   * @note It needs to be translated else it displays the translation key.
   */
  label: string;

  /**
   * Column initial displayed state at loading. If `true` then the column will be displayed by default.
   */
  displayed_by_default: boolean;

  /**
   * Limit the cols only for some special users
   */
  visible_only_for?: 'superadmin' | null;

  /**
   * Column configurabke state. If `true` then the column will not be sortable nor hidable.
   */
  not_configurable: boolean;

  /**
   * Column sortable state. If `true`, the column will be sortable except if `not_configurable` is also at `true`.
   */
  is_sortable: boolean;

  /**
   * The key for server-side sorting. Precise only if it's possible.
   */
  server_sort_key?: string;

  /**
   * The key for row grouping. If `true` the data can be grouped by this column.
   */
  is_groupable: boolean;

  /**
   * Check is the column is available depending on the data.
   */
  is_available?: (data: { rows: T[]; filters: Dictionary<any> }) => boolean;

  /**
   * Comparaison method used only if `is_sotable` is `true`.
   */
  compare_by?: CompareByType | ((obj_a: any, obj_b: any) => any);

  /**
   * Never shows the column.
   */
  always_hidden?: boolean;

  /**
   * Exportation configuration of the column.
   */
  export?: ConfigExportDatatableColumn;

  /**
   * Add definition for inner columns.
   *
   * @warning This should be only sub-columns, inner should not contains another inner.
   */
  inner?: DatatableColumn<T>[];
}

export interface ColumnRunTimeConfig {
  property: string; // Column name
  short_property: string; // Short column name for URL parameters
  displayed: boolean; // The col is active ?
  required: boolean;
  visible_only_for: string;
  is_available: (data: { rows: any[]; filters: Dictionary<any> }) => boolean;
  inner: ColumnRunTimeConfig[];
}

export type ColumnsRunTimeConfig = ColumnRunTimeConfig[];

/**
 * Table object to handle a group of rows
 */
export class DatatableGroup<RowType extends DatatableBaseRow> {
  level = 0;
  parent: DatatableGroup<RowType>;
  expanded = true;
  totalCounts = 0;
  first_row?: RowType = null;

  label: string;

  // #region -> (datatable column related property)

  /** */
  private _property = new SimpleSetterGetter<string>(null);

  /**
   * @deprecated Use `property` instead.
   */
  public get last_property() {
    return this._property.value;
  }
  /**
   * @deprecated Use `property` instead.
   */
  public set last_property(last_property) {
    this._property.value = last_property;
  }

  /** */
  public get property() {
    return this._property.value;
  }

  /** */
  public set property(property) {
    this._property.value = property;
  }

  // #endregion

  // #region -> (group value)

  /** */
  private _value = new SimpleSetterGetter<any>(null);

  /**
   * @deprecated Use `value` instead.
   */
  public get last_col_value() {
    return this._value.value;
  }
  /**
   * @deprecated Use `value` instead.
   */
  public set last_col_value(last_col_value) {
    this._value.value = last_col_value;
  }

  /** */
  public get value() {
    return this._value.value;
  }

  /** */
  public set value(value) {
    this._value.value = value;
  }

  // #endregion

  /**
   * Common values of the group. Automatically filled from `values_to_load$$`
   */
  values: Dictionary<string>;

  /** */
  values_to_load$$: Dictionary<Observable<string>>;

  get visible(): boolean {
    return !this.parent || (this.parent.visible && this.parent.expanded);
  }
}
