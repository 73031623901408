import { Observable, of, combineLatest } from 'rxjs';
import { map, switchMap, take, tap } from 'rxjs';
import { distinctUntilRealChanged, replay } from '@bg2app/tools/rxjs';

import { assign, isArray, isEqual, keys, values } from 'lodash-es';

import { marker as i18n } from '@biesbjerg/ngx-translate-extract-marker';

import { environment } from 'environments/environment';

import { Beeguard2Api, DeviceApi } from 'app/core';

import { DRDevice, TGDevice } from '../../..';
import { Exploitation } from '../../02_exploitation';
import { Location } from '../../03_location';
import { Entity, IEntityStaticState } from '../../00_abstract';
import { DeviceConfig, EntityStateValue } from '../../misc';

import { BlacklistedGhostLocation, BlacklistedDevicesByLocation, BlacklistReason } from 'app/typings/entities/warehouse';
import { BlacklistReasonI18N } from 'app/typings/entities/warehouse/blacklist-reason.enumerator';
import { addDays } from 'date-fns';
import { parseDate } from 'app/misc/tools';
import { CPTDevice } from '../../../devices/CPTDevice';
import { BeeLiveDevice } from '../../../devices/BeeLiveDevice';
import { WarehouseEntityUserACL } from './warehouse-entity-user-acl.class';

export interface WhDevicesConfig {
  [imei: string]: DeviceConfig;
}

interface IWarehouseStaticState extends IEntityStaticState {
  exploitation_id: number;

  /**
   * List of blacklisted ghost locations (CAD -> never create a ghost location at this position)
   */
  blacklisted_ghost_locations: { data: BlacklistedGhostLocation[] };

  /**
   * List of blacklisted devices (CAD -> never create ghost with this devices at this position)
   */
  blacklisted_devices_by_location: { data: BlacklistedDevicesByLocation[] };
}

export class Warehouse extends Entity<IWarehouseStaticState> {
  // #region -> (model basics)

  // #endregion

  // #region -> (related exploitation entity)

  /** */
  public exploitation_id$$ = this.static_state$$.pipe(
    map(static_state => static_state.exploitation_id),
    distinctUntilRealChanged(),
    replay()
  );

  /** */
  public exploitation$$: Observable<Exploitation> = this.exploitation_id$$.pipe(
    switchMap(exploitation_id => this.bg2Api.getEntityObj(exploitation_id) as Observable<Exploitation>),
    replay()
  );

  // #endregion

  // #region -> (acl management)

  /**
   * @inheritdoc
   */
  public user_acl = new WarehouseEntityUserACL();

  // #endregion

  exploitation: any = null;

  public devices_loading = false;

  get devices_config(): WhDevicesConfig {
    if (!this.state.devices) {
      return {};
    }
    return this.state.devices;
  }

  public devices_config$$: Observable<WhDevicesConfig> = this.state$$.pipe(
    map((state: EntityStateValue) => state.devices || {}),
    distinctUntilRealChanged(),
    replay()
  );

  /**
   * @description Observable on all devices of warehouse
   */
  public devices$$: Observable<DRDevice[]> = this.devices_config$$.pipe(
    tap(() => (this.devices_loading = true)),
    switchMap(devices_config => this._getDevices$(devices_config)), // API should only return devices we have the ACL
    tap(() => (this.devices_loading = false)),
    replay()
  );

  public devices_nbr$$ = this.devices$$.pipe(
    map(devices_nbr => devices_nbr.length),
    replay()
  );

  public moved_devices$$ = this.devices$$.pipe(
    // Filter devices that last posistion do not match to current location position
    switchMap(devices => {
      const are_compatible = devices.map(device => device.isLocationCompatible(this.bg2Api));
      return combineLatest(are_compatible).pipe(map(is_compatible => devices.filter((device, idx) => !is_compatible[idx])));
    })
  );

  public sensor_devices$$: Observable<DRDevice[]> = this.devices$$.pipe(map(devices => devices.filter(device => !device.is_gateway)));

  public gateway_devices$$: Observable<DRDevice[]> = this.devices$$.pipe(map(devices => devices.filter(device => device.is_gateway)));

  /**
   * @description Observable on all gps devices of warehouse
   */
  public devices_gps$$: Observable<DRDevice[]> = this.devices$$.pipe(
    map((devices: DRDevice[]) => devices.filter((device: DRDevice) => device.type === 'GPS')),
    replay()
  );

  public devices_gps_nbr$$ = this.devices_config$$.pipe(
    map(device_type => values(device_type)),
    map(devices_confs => devices_confs.filter(dconf => dconf.type === 'GPS')),
    map(gps_nbr => gps_nbr.length),
    replay()
  );

  /**
   * @description Observable on all wg devices of warehouse
   */
  public devices_wg$$: Observable<DRDevice[]> = this.devices$$.pipe(
    map((devices: DRDevice[]) => devices.filter((device: DRDevice) => device.type === 'WG')),
    replay()
  );

  public devices_wg_nbr$$ = this.devices_config$$.pipe(
    map(device_type => values(device_type)),
    map(devices_confs => devices_confs.filter(dconf => dconf.type === 'WG')),
    map(wg_nbr => wg_nbr.length),
    replay()
  );

  /**
   * @description Observable on all rg devices of warehouse
   */
  public devices_rg$$: Observable<DRDevice[]> = this.devices$$.pipe(
    map((devices: DRDevice[]) => devices.filter((device: DRDevice) => device.type === 'RG')),
    replay()
  );

  public devices_rg_nbr$$ = this.devices_config$$.pipe(
    map(device_type => values(device_type)),
    map(devices_confs => devices_confs.filter(dconf => dconf.type === 'RG')),
    map(rg_nbr => rg_nbr.length),
    replay()
  );

  public devices_cpt$$: Observable<CPTDevice[]> = this.devices$$.pipe(
    map(devices => devices.filter(device => device.type === 'CPT' || device.type === 'BeeLive') as CPTDevice[]),
    replay()
  );

  public devices_cpt_nbr$$ = this.devices_config$$.pipe(
    tap(data => console.log(data)),
    map(device_type => values(device_type)),
    map(devices_confs => devices_confs.filter(conf => conf.type === 'CPT' || conf.type === 'BeeLive')),
    map(configurations => configurations?.length ?? 0),
    replay()
  );

  /**
   * Observes list of beelive devices associated to the warehouse.
   */
  public devices_beelive$$: Observable<BeeLiveDevice[]> = this.devices$$.pipe(
    map(devices => devices.filter(device => device.type === 'BeeLive') as BeeLiveDevice[]),
    replay()
  );

  /**
   * Observes list of TG devices associated to the warehouse.
   */
  public devices_tg$$: Observable<TGDevice[]> = this.devices$$.pipe(
    map(devices => (devices ?? []).filter(device => device.type === 'TG') as TGDevice[]),
    replay()
  );

  /**
   * Observes list of TG devices associated to the warehouse.
   */
  public has_devices_tg$$: Observable<boolean> = this.devices_tg$$.pipe(
    map(devices => devices?.length > 0),
    replay()
  );

  protected _getDevices$(devices_config: any): Observable<DRDevice[]> {
    const devices_imei = keys(devices_config).map(val => parseInt(val, 10));
    if (devices_imei.length > 0) {
      // this._logger.debug(`loadDevices(${devices_imei})`);
      return this.deviceApi.requestDevices(devices_imei).pipe(
        map(devices => {
          devices.map(device => {
            const config = devices_config[device.imei];
            if (config && config.associated_to) {
              const hc = {
                imei: device.imei,
                type: device.type,
                since: config.since,
                position: config.position,
                hive_id: config.associated_to,
              };
              // Note: only update hive_config as more data may still be valid and setted by hive model
              device.hive_config = assign(device.hive_config, hc);
            }
          });
          return devices;
        })
      );
    } else {
      return of([]);
    }
  }

  get description(): string {
    return i18n(`ENTITY.WAREHOUSE.Warehouse "[name]"`);
  }

  get exploitation_id(): number {
    return this.static_state.exploitation_id;
  }

  set exploitation_id(val: number) {
    this.static_state.exploitation_id = val;
    this.checkStaticStateChanged();
  }

  public link$$ = this.exploitation$$.pipe(
    switchMap(explotation => (explotation ? combineLatest([explotation.name$$, of(explotation.id)]) : of([null, null]))),
    distinctUntilRealChanged(),
    map(([name, exploitation_id]) => {
      if (exploitation_id) {
        return `<a href="javascript:void(/exploitations, ${exploitation_id})">${name}</a>`;
      } else {
        return this.name;
      }
    }),
    replay()
  );

  constructor(protected bg2Api: Beeguard2Api, protected deviceApi: DeviceApi) {
    super(bg2Api, deviceApi);
    this.type = 'warehouse';
  }

  public requestExploitation(): Observable<Exploitation> {
    return this.bg2Api.getEntityObj(this.exploitation_id).pipe(
      map(exploitation => {
        this.exploitation = exploitation as Exploitation;
        this.exploitation.warehouse = this;
        return this.exploitation;
      })
    );
  }

  public requestDevicesConfigAtDate(date: Date = null, event_id: number = null): Observable<WhDevicesConfig> {
    return this.getAtDate('state.devices', date, event_id);
  }

  public getDevicesImeis(): number[] {
    const device_imeis = keys(this.devices_config).map(val => parseInt(val, 10));
    return device_imeis;
  }

  public static devices$$ByWarehouses(warehouses: Warehouse[]): Observable<DRDevice[]>[] {
    return warehouses.map((warehouse: Warehouse) => warehouse.devices$$);
  }

  // #region -> (ghosts blacklist management)

  public blacklist_ghost_location(position: { latitude: number; longitude: number }, reason: BlacklistReason) {
    return this.static_state$$.pipe(
      take(1),
      switchMap(static_state => {
        if (!static_state?.blacklisted_ghost_locations) {
          static_state.blacklisted_ghost_locations = { data: [] };
        }
        static_state.blacklisted_ghost_locations.data.push({
          lat: parseFloat(position.latitude.toFixed(5)),
          lng: parseFloat(position.longitude.toFixed(5)),
          reason,
          reason_key: BlacklistReasonI18N.get(reason),
        });
        this.static_state = static_state;
        return this.save();
      })
    );
  }

  public rmBlacklistGhostLocation(item: BlacklistedGhostLocation) {
    return this.static_state$$.pipe(
      take(1),
      switchMap(ss => {
        if (!(ss?.blacklisted_ghost_locations?.data?.length > 0)) {
          return of(true);
        }

        ss.blacklisted_ghost_locations.data = ss.blacklisted_ghost_locations.data.filter(val => !isEqual(val, item));
        this.static_state = ss;

        return this.save();
      })
    );
  }

  public blacklisted_devices_by_location$$: Observable<{ data: BlacklistedDevicesByLocation[] }> = this.static_state$$.pipe(
    map(static_state => static_state?.blacklisted_devices_by_location || { data: [] }),
    map(bdls => {
      // Manage timeout
      // NOTE: as blacklist update is build from this filterd list, outdated entries are deleted on any update
      const current_date = new Date();
      const bdls_to_keep = bdls.data.filter(bdl => current_date <= parseDate(bdl.timeout));
      return { data: bdls_to_keep };
    })
  );

  public addBlackListedDevices(location: Location, imeis: number[]) {
    return this.blacklisted_devices_by_location$$.pipe(
      take(1),
      switchMap(blacklisted_devices_by_location => {
        // Handle existing location
        const unique_location_bl_idx = blacklisted_devices_by_location.data.findIndex(datum =>
          location.isLocationPositionWithinRange(datum, environment.config.ghost.default_merge_range_meter)
        );
        if (unique_location_bl_idx !== -1) {
          blacklisted_devices_by_location.data[unique_location_bl_idx].imeis.push(...imeis);
          blacklisted_devices_by_location.data[unique_location_bl_idx].imeis = [
            ...new Set(blacklisted_devices_by_location.data[unique_location_bl_idx].imeis),
          ];
        } else {
          blacklisted_devices_by_location.data.push({
            lat: parseFloat(location.position.latitude.toFixed(5)),
            lng: parseFloat(location.position.longitude.toFixed(5)),
            imeis,
            timeout: addDays(new Date(), 3),
          });
        }
        this.static_state.blacklisted_devices_by_location = blacklisted_devices_by_location;
        return this.save();
      })
    );
  }

  public rmBlackListedDevices(item: BlacklistedDevicesByLocation, imeis: number | number[]) {
    return this.blacklisted_devices_by_location$$.pipe(
      take(1),
      switchMap(blacklisted_devices_by_location => {
        if (!(blacklisted_devices_by_location?.data?.length > 0)) {
          return of(true);
        }

        const index_of_item = blacklisted_devices_by_location.data.findIndex(value => value.lat === item.lat && value.lng === item.lng);

        if (index_of_item < 0) {
          return of(true);
        }

        if (!isArray(imeis)) {
          imeis = [imeis as number];
        }

        blacklisted_devices_by_location.data[index_of_item].imeis = blacklisted_devices_by_location.data[index_of_item].imeis.filter(
          imei => !(imeis as number[]).includes(imei)
        );

        if (blacklisted_devices_by_location.data[index_of_item].imeis.length === 0) {
          blacklisted_devices_by_location.data = blacklisted_devices_by_location.data.filter(
            value => value.lat !== item.lat && value.lng !== item.lng
          );
        }

        this.static_state.blacklisted_devices_by_location = blacklisted_devices_by_location;
        return this.save();
      })
    );
  }

  public blacklisted_ghost_locations$$: Observable<BlacklistedGhostLocation[]> = this.static_state$$.pipe(
    // tap(ss => console.log('WH statig', this.desc, ss)),
    map(static_state => static_state?.blacklisted_ghost_locations?.data || [])
  );

  // #endregion

  public archivable$$ = of(false);
}
