<div class="bg-dialog" *ngIf="apiary$$ | async; let apiary">
  <!-- .dialog-header -->
  <dialog-header>{{ 'EVENT.OTHER_INTERVENTION.Other intervention' | translate }}</dialog-header>
  <!-- .dialog-header -->

  <!-- .dialog-body -->
  <dialog-body>
    <div class="actions">
      <!-- .classic-other-intervention -->
      <div class="action">
        <bg2-button
          [type]="'action'"
          [icon]="'mdi-pencil-box'"
          [queryParamsHandling]="'preserve'"
          [options]="{ has_flag_beta: true }"
          [message]="'EVENT.OTHER_INTERVENTION.Other intervention' | translate"
          [routerLink]="['', { outlets: { 'modal': ['new_event', { 'etype': 'other_intervention', 'args': ({ 'apiary': apiary?.id } | json) }] } }]"
          (btn_click)="clicked(true)"
        ></bg2-button>
      </div>
      <!-- .classic-other-intervention -->

      <!-- .payment -->
      <div class="action">
        <bg2-button
          [type]="'action'"
          [icon]="'mdi-account-cash'"
          [queryParamsHandling]="'preserve'"
          [options]="{ has_flag_beta: true }"
          [message]="'EVENT.LOCATION_PAYMENT.Annotate payment for the location' | translate"
          [routerLink]="['', { outlets: { 'modal': ['new_event', { 'etype': 'location_payment', 'args': ({ 'location': apiary?.location_id$$ | async } | json) }] } }]"
          (btn_click)="clicked(true)"
        ></bg2-button>
      </div>
      <!-- .payment -->

      <!-- .share -->
      <div class="action" *ngIf="appState.is_superadmin$$ | async">
        <bg2-button
          [type]="'action'"
          [icon]="'mdi-share-variant'"
          [queryParamsHandling]="'preserve'"
          [options]="{ has_flag_beta: true }"
          [message]="'EVENT.SHARE.Share data' | translate"
          [routerLink]="['', { outlets: { 'modal': ['new_event', { 'etype': 'data_sharing', 'args': ({ 'apiary': apiary?.id } | json) }] } }]"
          (btn_click)="clicked(true)"
        ></bg2-button>
      </div>
      <!-- .share -->
    </div>
  </dialog-body>
  <!-- .dialog-body -->
  
  <!-- .dialog-footer -->
  <div class="dialog-footer">
    <bg2-button
      [type]="'cancel'"
      [message]="'ALL.COMMON.Close' | translate"
      (btn_click)="clicked(true)"
    ></bg2-button>
  </div>
  <!-- .dialog-footer -->
</div>
