<div class="bg2-ng-mat-array-widget-component">
  <div class="properties-array">
    <form-element-header [schema]="schema"></form-element-header>

    <span *ngIf="schema?.description; let description" class="formHelp">{{ description }}</span>

    <ng-container *ngFor="let itemProperty of $any(formProperty?.properties); let index=index">
      <div class="properties-array__property">
        <sf-form-element [formProperty]="itemProperty"></sf-form-element>

        <bg2-button
          [type]="'delete'"
          [icon]="'mdi-minus-box'"
          [options]="{ is_thin: true }"
          [message]="label_remove | translate"
          (btn_click)="removeItem(itemProperty)"
        ></bg2-button>
      </div>

      <mat-divider></mat-divider>
    </ng-container>
  </div>

  <div *ngIf="new_form_property" class="new-item">
    <sf-form-element [formProperty]="new_form_property"></sf-form-element>
  </div>
</div>
