import { Component, ViewChild, ElementRef, HostListener, ChangeDetectionStrategy } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

import { map, tap } from 'rxjs';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { distinctUntilRealChanged, replay } from '@bg2app/tools/rxjs';

import { environment } from 'environments/environment';

import { AppStateService } from 'app/core/app-state.service';

import { ModalParams } from 'app/widgets/dialogs-modals/abstract-modal.component';
import { AbstractModalComponent } from 'app/widgets/dialogs-modals/abstract-modal.component';

import { Stack } from 'app/typings/core/Stack';

export interface UserManualModalParams extends ModalParams {
  help: string;
}

@Component({
  selector: 'bg2-user-manual',
  templateUrl: './user-manual.modal.html',
  styleUrls: ['./user-manual.modal.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class UserManualModal extends AbstractModalComponent<UserManualModalParams> {
  public help$$ = this.input_params$$.pipe(
    map(params => params.help || 'README.html'),
    distinctUntilRealChanged(),
    replay()
  );

  // #region -> (component basics)

  constructor(private _appState: AppStateService, private _sanitizer: DomSanitizer) {
    super();
  }

  // #endregion

  // #region -> (inheritance overrides)

  public close() {
    if (this.url_stack.size() >= 2) {
      this.url_stack.pop();
      const previous = this.url_stack.pop();
      this._force_url$$.next(previous);
    } else {
      super.close();
    }
  }

  /** */
  protected handle_event_before_unload(event: BeforeUnloadEvent): void {
    return null;
  }

  // #endregion

  // #region -> (URL management)

  private _url_stack = new Stack<string>();
  public get url_stack(): Stack<string> {
    return this._url_stack;
  }

  private _force_url$$ = new BehaviorSubject<string>(null);
  public url$$ = combineLatest([this._appState.lang$$, this.help$$, this._force_url$$]).pipe(
    tap(() => {
      this._init_done = true;
      this._iframe_loading = true;
    }),
    map(([lang, help, force_url]) => {
      if (force_url) {
        return force_url;
      } else {
        return `${environment.userManualUrl}/${lang}/${help}`;
      }
    }),
    map(url => this._sanitizer.bypassSecurityTrustResourceUrl(url)),
    replay()
  );

  // #endregion

  // #region -> (iframe management)

  @ViewChild('userManualFrame')
  public iframe: ElementRef<HTMLIFrameElement>;

  private _nb_loaded = 0;
  private _init_done = false;

  private _iframe_ajax = false;
  private _iframe_loading = false;

  private _title$$ = new BehaviorSubject<string>(null);
  public title$$ = this._title$$.asObservable().pipe(distinctUntilRealChanged(), replay());

  @HostListener('window:message', ['$event'])
  protected onMessage(event: MessageEvent) {
    if (event.data === 'iframe_unload') {
      this._iframe_loading = true;
    } else if (event.data === 'iframe_ajaxStart') {
      this._iframe_ajax = true;
    } else if (event.data === 'iframe_ajaxStop') {
      this._iframe_ajax = false;
    } else if (event.data === 'iframe_back') {
      // Do not stack URL, just "back" after form validation
      if (this.url_stack.size() >= 2) {
        this.url_stack.pop();
      }
    } else if (event.data && event.data.startsWith) {
      const data: string = event.data;

      if (data.startsWith('url:')) {
        const url: string = data.slice(4);

        // User see application timeline, then mark it.
        if (url.includes('app_timeline')) {
          localStorage.setItem('latest_viewed_version', environment.version);
        }

        // Send in iframe info to the doc
        this.iframe.nativeElement.contentWindow.postMessage('in_iframe', '*');
        this._iframe_loading = false;
        this.initial_loading = false;
        this.url_stack.push(url);
      } else if (data.startsWith('title:')) {
        this._title$$.next(data.slice('title:'.length).trim());
      }
    }
  }

  public onIframeLoaded(): void {
    this._nb_loaded += 1;

    if (!this._init_done || this._nb_loaded === 1) {
      return;
    }

    setTimeout(() => {
      this._iframe_loading = false;
      this.initial_loading = false;
    }, 10);
  }

  // #endregion
}
