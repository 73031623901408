<div class="bg2-notified-devices-datatable-component">
  <!-- Grid wrapper -->
  <div class="bg2-grid-wrapper">
    <div class="bg2-grid-container" [ngClass]="{ empty: data_source?.data?.length === 0 }">
      <ng-container *ngIf="loading$$ | async">
        <mat-progress-bar class="top-loading" [mode]="'indeterminate'"></mat-progress-bar>
      </ng-container>

      <table mat-table [dataSource]="data_source" matSort [matSortActive]="sort__active$$ | async" [matSortDirection]="sort__direction$$ | async" (matSortChange)="onSortData($event)">
        <!-- #region .headers-and-cells-templates -->
        <ng-container *ngFor="let column of flattened_columns$$ | async; trackBy:track_by_column" [matColumnDef]="column.property">
          <!-- #region .header-definitions -->
          <th
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header
            class="{{ column_sort_type(column) }}"
            [disabled]="!column?.is_sortable"
            [@.disabled]="true"
          >
            <ng-container [ngSwitch]="column.property">
              <!-- Default column header -->
              <ng-container *ngSwitchDefault>
                {{ column.label | translate }}
              </ng-container>
            </ng-container>
          </th>
          <!-- #endregion -->

          <!-- #region .content-definitions -->
          <td
            mat-cell
            *matCellDef="let user_row"
            class="col-{{ column.property }}"
            [attr.data-label]="column?.label | translate"
            [class.selected]="isSelected$$(user_row) | async"
            (click)="column?.property === 'select' ? selection_model.toggle(user_row) + $event.stopPropagation() : null"
          >
            <ng-container [ngSwitch]="column.property">
              <!-- Column type: "user.user_id" -->
              <!-- <ng-container *ngSwitchCase="'user.user_id'">
                <ng-container *ngLet="assert_user(user_row?.user); let user">
                  <span>{{ user?.user_id$$ | async }}</span>
                </ng-container>
              </ng-container> -->

              <!-- Column type: "device.name" -->
              <ng-container *ngSwitchCase="'device.name'">
                <ng-container *ngLet="assert_device(user_row?.device); let device">
                  <span>{{ device?.name }}</span>
                </ng-container>
              </ng-container>

              <!-- Column type: "notified_device.why" -->
              <ng-container *ngSwitchCase="'notified_device.why'">
                <ul *ngLet="assert_notified_device(user_row?.notified_device); let notified_device" class="device-notify-why-list">
                  <li *ngFor="let why of notified_device?.why">{{ 'DEVICE.NOTIFICATION.WHY.' + why | translate }}</li>
                </ul>
              </ng-container>

              <!-- Column type: "device.last_coms" -->
              <ng-container *ngSwitchCase="'device.last_com'">
                <ng-container *ngLet="assert_device(user_row?.device); let device">
                  <span>{{ device?.last_contact$$ | async | formatDate:'short' }}</span>
                </ng-container>
              </ng-container>

              <!-- Column other cols -->
              <ng-container *ngSwitchDefault>
                <span>{{ get(user_row, column.property) }}</span>
              </ng-container>
            </ng-container>
          </td>
          <!-- #endregion -->
        </ng-container>
        <!-- #endregion -->

        <!-- #region .specific-templates -->
        <ng-container matColumnDef="group-header">
          <td mat-cell *matCellDef="let group" [colSpan]="(displayed_columns$$ | async).length">
            <span
              class="header-content mdi mdi-chevron-{{ group.expanded ? 'up' : 'down' }} mdi-24px"
              [style.padding-left.px]="(group.level - 1) * 24"
            >
              <ng-container [ngSwitch]="group.last_property">
                <ng-container *ngSwitchDefault>
                  {{ group.label | translate }} : {{ group.last_col_value }} ({{ group.totalCounts }})
                </ng-container>
              </ng-container>
            </span>
          </td>
        </ng-container>
        <!-- #endregion -->

        <!-- #region .row-definitions -->
        <tr mat-header-row *matHeaderRowDef="displayed_columns$$ | async"></tr>

        <tr
          mat-row
          *matRowDef="let row; columns: displayed_columns$$ | async"
          class="final-row"
          [ngClass]="{ selected: isSelected$$(row) | async }"
          [routerLink]="['', { outlets: { modal: ['device', { imei: row?.device?.imei }] } }]"
        ></tr>

        <tr mat-row *matRowDef="let row; columns: ['group-header']; when: isGroup" (click)="groupHeaderClick(row)"></tr>
        <!-- #endregion -->
      </table>
    </div>

    <!-- Empty grid message -->
    <div *ngIf="data_source?.data.length === 0 && (loading$$ | async) === false" class="empty-grid-message">
      <img src="assets/img/large_up_arrow.png" />
      <div class="text">
        <!-- {{ 'VIEWS.DEVICES.COMPONENTS.DEVICES_DATATABLE.No matching device' | translate }} -->
      </div>
    </div>

    <!-- Grid pagination -->
    <mat-paginator
      *ngLet="paging$$ | async; let paging"
      showFirstLastButtons
      [length]="paging?.total"
      [pageSize]="paging?.limit"
      [disabled]="loading$$ | async"
      [pageSizeOptions]="PAGE_SIZE_OPTIONS"
      [pageIndex]="paging?.offset / paging?.limit"
      (page)="onPageChanged($event)"
    ></mat-paginator>
  </div>
</div>

<ng-template #emptyValue>--</ng-template>
<ng-template #lockedValue>
  <span class="mdi mdi-lock mdi-18px"></span>
</ng-template>
