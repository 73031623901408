import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { TranslateModule, TranslateParser } from '@ngx-translate/core';

import { BgTranslateParser } from 'app/shared-module/translate.parser';
import { MaterialSharedModule } from 'app/shared-module/material-shared.module';

import { MiscModule } from 'app/misc/misc.module';
import { DirectivesModule } from 'app/misc/directives/directives.module';

import { EventsSharedModule } from 'app/views/events/events-shared.module';
import { ApiarySharedModule } from 'app/views/apiary/apiary-shared.module';
import { GhostSharedModule } from 'app/views/ghost/shared/ghost-shared.module';
import { EntitiesSharedModule } from 'app/views/entities/entities-shared.module';
import { DevicesSharedModule } from 'app/views/devices/shared/devices-shared.module';

import { MiscWidgetsModule } from 'app/widgets/misc-widgets/misc-widgets.module';
import { DialogsModalsModule } from 'app/widgets/dialogs-modals/dialogs-modals.module';
import { WidgetsReusableModule } from 'app/widgets/widgets-reusables/widgets-reusables.module';

import { LocationMapComponent } from './location-map/location-map.component';
import { DevicesListDialogComponent } from './devices-list-dialog/devices-list.dialog';
import { LocationDetailsComponent } from './location-details/location-details.component';
import { FilterEmptyLocationsComponent } from './filter-empty-locations/filter-empty-locations.component';
import { LocationDetailsHeaderComponent } from './location-details/location-details-header/location-details-header.component';
import { LocationYearlyStatsCarouselComponent } from './widgets/location-yearly-stats/location-yearly-stats-carousel.component';
import { LocationDetailsContentComponent } from './location-details/location-details-content/location-details-content.component';
import { LocationYearlyStatsCarouselSlideComponent } from './widgets/location-yearly-stats/location-yearly-stats-carousel-slide.component';

const module_components = [
  LocationDetailsComponent,
  LocationMapComponent,
  FilterEmptyLocationsComponent,

  // Location details parts
  LocationDetailsHeaderComponent,
  LocationDetailsContentComponent,
];

@NgModule({
  declarations: [
    ...module_components,
    DevicesListDialogComponent,

    LocationYearlyStatsCarouselComponent,
    LocationYearlyStatsCarouselSlideComponent,
  ],
  imports: [
    // Angular modules
    CommonModule,
    RouterModule,
    FormsModule,

    // External modules (node_modules)
    MiscModule,
    TranslateModule.forChild({
      parser: { provide: TranslateParser, useClass: BgTranslateParser },
    }),

    // Internal modules (shared)
    DialogsModalsModule,
    DirectivesModule,
    MiscWidgetsModule,
    ApiarySharedModule,
    DevicesSharedModule,
    MaterialSharedModule,
    EventsSharedModule,
    EntitiesSharedModule,
    WidgetsReusableModule,
    GhostSharedModule,
  ],
  exports: [...module_components],
})
export class LocationsSharedModule {}
