<ng-container *ngIf="visible$$ | async">
  <form-element-header [schema]="schema"></form-element-header>

  <div class="switch" [ngClass]="{ '--classic': options?.type === 'classic' }">
    <span class="switch__left-label" *ngIf="schema.description_off">{{ schema.description_off | translate }}</span>

    <mat-slide-toggle
      [id]="uid"
      [ngModel]="value$$ | async"
      (ngModelChange)="value = $event"
      [color]="'primary'"
    ></mat-slide-toggle>

    <span class="switch__right-label" *ngIf="options?.type === 'on-off' && schema.description_on">
      {{ schema.description_on | translate }}
    </span>
  </div>
</ng-container>
