import { isNil, isEmpty } from 'lodash-es';

export const build_template_simple_message = (header: string = null, message: string): string => {
  if (isNil(message) || isEmpty(message)) {
    return '';
  }

  const template_builder: string[] = [];

  if (!isNil(header)) {
    template_builder.push(`<h3>${header} : </h3>`);
  }

  template_builder.push('<div style="color: #194281; font-family: Roboto; font-style: italic;">');
  const comments = message.split('\n');
  comments.forEach(_message => {
    const is_empty_line = isNil(_message) || isEmpty(_message);

    if (is_empty_line) {
      template_builder.push(is_empty_line ? '<br>' : '');
    } else {
      template_builder.push('<div>', `<span>${_message}</span>`, '</div>');
    }
  });
  template_builder.push('<br>', '<span>---------- FIN DU MESSAGE ----------</span>', '</div>');

  return template_builder.join('');
};
