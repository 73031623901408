import { Component, Input, OnDestroy, ChangeDetectionStrategy } from '@angular/core';

import { distinctUntilRealChanged, replay } from '@bg2app/tools/rxjs';
import { Subscription, BehaviorSubject, Observable, concat, of, filter, map, switchMap } from 'rxjs';

import { isEmpty, isNil, values } from 'lodash-es';

import { Beeguard2Api } from 'app/core';
import { Hive, HiveDeviceConfig, Location, Apiary, HiveType } from 'app/models';

@Component({
  selector: 'bg2-picto-hive',
  templateUrl: './picto-hive.component.html',
  styleUrls: ['./picto-hive.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PictoHiveComponent implements OnDestroy {
  public __isNil = isNil;

  // Subscriptions
  private hive_appiary_location_sub: Subscription = null;
  private hive_dconf_sub: Subscription = null;

  @Input()
  public title = false;

  @Input()
  public size = '25px';

  @Input()
  public status: 'OK' | 'D' | 'T' = null;

  // #region -> (hive color)

  private _color = 'black';

  @Input()
  public set color(color: string) {
    this._color = color;
  }

  public get color(): string {
    if (this.title) {
      return 'black';
    }
    if (this.hive) {
      return this.hive.color;
    }
    return this._color;
  }

  // #endregion

  // #region -> (hive type)

  private _htype: HiveType = null;

  @Input()
  public set htype(val: HiveType) {
    this._htype = val;
  }

  public get htype(): HiveType {
    if (!isNil(this._htype)) {
      return this._htype;
    }

    if (this.hive) {
      return this.hive.htype;
    }

    return 'hive';
  }

  // #endregion

  // #region -> (hive GPS device)

  private _hive_GPS = false;

  @Input()
  public set GPS(device: boolean) {
    this._hive_GPS = device;
  }

  public get GPS(): boolean {
    return this._hive_GPS;
  }

  //#endregion

  // #region -> (hive WG device)

  private _hive_WG: string[] = ['none'];

  @Input()
  public set WGuard(device: string[]) {
    this._hive_WG = device || [this.hive.devices[0].position];
  }

  public get WGuard(): string[] {
    return this._hive_WG;
  }

  // #endregion

  // #region -> (hive itself)

  private _hive: Hive = null;

  @Input()
  public set hive(hive: Hive) {
    if (isNil(hive)) {
      return;
    }

    this._hive = hive;
    this.loadHiveData();
  }

  public get hive(): Hive {
    return this._hive;
  }

  // #endregion

  // #region -> (hive identifier)

  private hive_sub: Subscription;

  private unsubscribeHive(): void {
    if (this.hive_sub) {
      this.hive_sub.unsubscribe();
    }
  }

  private _hive_id: number = null;

  @Input()
  public set hive_id(id: number) {
    this._hive_id = id;
    this.unsubscribeHive();
    this.hive_sub = this.bg2Api
      .getEntityObj(id)
      .pipe(
        filter(entity => entity.type === 'hive'),
        map(hive => hive as Hive)
      )
      .subscribe(hive => {
        // TODO: Checks if it works with OnPush strategy (since we sets the value)
        this.hive = hive;
      });
  }

  public get hive_id(): number {
    return this._hive_id;
  }

  // #endregion

  //#region -> (hive supers)

  private hive_supers_sub: Subscription = null;

  private unsubscribeHiveSupers(): void {
    if (this.hive_supers_sub) {
      this.hive_supers_sub.unsubscribe();
    }
  }

  private _supers = 0;

  @Input()
  public set supers(supers: number) {
    this._supers = supers || 0;
  }

  public get supers(): number {
    return this._supers;
  }

  // #endregion

  // #region -> (component basics)

  constructor(private bg2Api: Beeguard2Api) {}

  ngOnDestroy(): void {
    this.hive_sub?.unsubscribe();
    this.hive_dconf_sub?.unsubscribe();
    this.hive_supers_sub?.unsubscribe();
    this.hive_appiary_location_sub?.unsubscribe();
  }

  // #endregion

  // #region -> (hive apiary data)

  private _apiary$: BehaviorSubject<Apiary> = new BehaviorSubject(null);

  private get apiary(): Apiary {
    return this._apiary$.getValue();
  }

  private set apiary(apiary: Apiary) {
    this._apiary$.next(apiary);
  }

  // #endregion

  // #region -> (hive location data)

  private _location$: BehaviorSubject<Location> = new BehaviorSubject(null);
  private location$$: Observable<Location> = this._location$.asObservable();

  private get location(): Location {
    return this._location$.getValue();
  }

  private set location(location: Location) {
    this._location$.next(location);
  }

  // #endregion

  private unsubscribeHiveApiaryLocation(): void {
    if (this.hive_appiary_location_sub) {
      this.hive_appiary_location_sub.unsubscribe();
      this.hive_appiary_location_sub = null;
    }
  }

  // #region -> (hive device configuration)

  private unsubscribeDeviceConfiguration(): void {
    if (this.hive_dconf_sub) {
      this.hive_dconf_sub.unsubscribe();
    }
  }

  // #endregion

  /**
   * Called once if the hive is directly an input.
   */
  private loadHiveData(): void {
    this._hive_id = this.hive.id;

    // Susbcribe to hive apiary & location
    this.unsubscribeHiveApiaryLocation();
    this.hive_appiary_location_sub = this.hive.apiary$$
      .pipe(
        switchMap((apiary: Apiary) => (apiary ? apiary.location$$ : of(null)).pipe(map((location: Location) => ({ apiary, location }))))
      )
      .subscribe((data: { apiary: Apiary; location: Location }) => {
        this.apiary = data.apiary;
        this.location = data.location;
      });

    // Subscribe to hive device configuration
    this.unsubscribeDeviceConfiguration();
    this.hive_dconf_sub = this.hive.devices_config$$.subscribe(devices_config => {
      const devices_config_values = values(devices_config);
      // TODO: Checks if it works with OnPush strategy (since we sets the value)
      this.WGuard = this.getCorrectedWGuardPosition(devices_config_values);
      this.GPS = devices_config_values.filter(dconf => dconf.type === 'GPS').length > 0;
    });

    // Susbcribe to hive supers
    this.unsubscribeHiveSupers();
    this.hive_supers_sub = this.hive.nb_supers$$.subscribe((supers: number) => {
      // TODO: Checks if it works with OnPush strategy (since we sets the value)
      this.supers = supers;
    });
  }

  private getCorrectedWGuardPosition(devices_config_values: HiveDeviceConfig[]): string[] {
    const wguard_configs = devices_config_values.filter((dconf: HiveDeviceConfig) => dconf.type === 'WG');

    const sort_by_date_fn = (a: HiveDeviceConfig, b: HiveDeviceConfig) => {
      if (new Date(a.since.date) >= new Date(b.since.date)) {
        return -1;
      }
      if (new Date(a.since.date) < new Date(b.since.date)) {
        return 1;
      }
    };

    if (wguard_configs.length === 2) {
      const is_front_back =
        wguard_configs.sort(sort_by_date_fn)[0].position === 'BACK' || wguard_configs.sort(sort_by_date_fn)[0].position === 'FRONT';
      return is_front_back ? ['FRONT', 'BACK'] : ['RIGHT', 'LEFT'];
    }

    return !isNil(wguard_configs[0]) ? [wguard_configs[0].position] : [''];
  }

  public tooltip$$ = concat(of(null), this.location$$).pipe(
    switchMap(location => {
      if (isNil(location)) {
        return of(null);
      }

      return location.name$$;
    }),
    map((location_name: string) => {
      if (this.hive) {
        if (!isNil(location_name)) {
          return `${this.hive.name} - ${location_name}`;
        }

        return `${this.hive.name} - ...`;
      }

      return '';
    }),
    replay()
  );

  public should_not_display_tooltip$$ = this.tooltip$$.pipe(
    map(tooltip_content => isNil(tooltip_content) || isEmpty(tooltip_content)),
    distinctUntilRealChanged()
  );
}
