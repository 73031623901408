import { Component, ChangeDetectionStrategy } from '@angular/core';
import { catchErrorInDialog } from '@bg2app/tools/rxjs';
import { DialogsService } from 'app/widgets/dialogs-modals';
import { isNil } from 'lodash-es';
import { of, switchMap } from 'rxjs';
import { AppStateService } from '../../../core/app-state.service';

@Component({
  selector: 'bg2-lang-select',
  templateUrl: 'lang-select.component.html',
  styleUrls: ['./lang-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LangSelectComponent {
  public readonly LANGUAGES = [
    { code: 'en', name: 'English' },
    { code: 'es', name: 'Español' },
    { code: 'fr', name: 'Français' },
    { code: 'it', name: 'Italiano' },
    { code: 'pt', name: 'Português' },
    { code: 'ja', name: '日本語' },
  ];

  // #region -> (component basics)

  constructor(public appState: AppStateService, private readonly _dialogs_service: DialogsService) {}

  // #endregion

  // #region -> (current user)

  public current_user$$ = this.appState.user$$;

  // #endregion

  // #region -> (language update)

  /** */
  public on_update_language(new_language: string) {
    this.current_user$$
      .pipe(
        switchMap(user => {
          if (isNil(user)) {
            return of(null);
          }

          user.lang = new_language;
          return user.save(['lang']);
        }),
        catchErrorInDialog(this._dialogs_service)
      )
      .subscribe({
        next: user => {
          this.appState.set_language(user?.lang ?? new_language);
        },
      });
  }

  // #endregion
}
