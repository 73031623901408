import { NgZone } from '@angular/core';

import * as d3 from 'd3';
import { endOfDay, isSameDay, startOfDay } from 'date-fns/esm';
import { flatten, isEqual, isNil, max, min, range } from 'lodash-es';

import { AppStateService } from 'app/core/app-state.service';
import { ConsoleLoggerService } from 'app/core/console-logger.service';
import { D3SharedCursorService } from 'app/core/services/global/d3-shared-cursor.service';

import { HiveDiffWeightPoint } from 'app/models/data/data-classic/interfaces/hive-data.interface';

import {
  AbstractD3Axes,
  AbstractD3Grid,
  AbstractD3Labels,
  AbstractD3Scales,
  AbstractUserEvents,
  AbstractDataObjects,
  AbstractEventObjects,
  D3SvgBuilderLuxon,
} from '@bg2app/models/charts';

// #region -> (redefined interfaces)

/** */
interface Axes extends AbstractD3Axes {
  /** */
  middle: {
    /** */
    container: d3.Selection<SVGGElement, unknown, HTMLElement, any>;

    /** */
    line: d3.Selection<SVGLineElement, unknown, HTMLElement, any>;
  };

  /** */
  range: {
    /** */
    container: d3.Selection<SVGGElement, unknown, HTMLElement, any>;

    /** */
    line: d3.Selection<SVGLineElement, unknown, HTMLElement, any>;

    /** */
    text: d3.Selection<SVGTextElement, unknown, HTMLElement, any>;
  };
}

/** */
interface Scales extends AbstractD3Scales {
  /** */
  y: d3.ScaleLinear<any, any, never>;

  /** */
  per_day: d3.ScaleBand<number>[];
}

interface Events extends AbstractUserEvents {
  weight: {
    container: d3.Selection<HTMLDivElement, unknown, HTMLElement, any>;
  };
}

/** */
interface DataObjects extends AbstractDataObjects {
  /** */
  current_day: d3.Selection<SVGRectElement, unknown, HTMLElement, any>;
}

// #endregion

// #region -> (factory class)

export class D3SvgApiaryWeightDiffFactory extends D3SvgBuilderLuxon<{ date: Date; values: HiveDiffWeightPoint[] }[]> {
  // #region -> (abstract properties)

  public axes: Axes = {
    container: null,
    time: {
      axis: null,
      container: null,
    },

    middle: {
      container: null,
      line: null,
    },

    range: {
      container: null,
      line: null,
      text: null,
    },
  };

  public grids: AbstractD3Grid = {
    container: null,
    time: {
      axis: null,
      container: null,
    },
    day_cycle: null,
  };

  public event_objects: AbstractEventObjects = {
    container: null,
  };

  public user_events: Events = {
    container: null,
    event_bounds: null,

    focus_line_x: null,
    focus_line_y: null,

    weight: {
      container: null,
    },
  };

  public scales: Scales = {
    time: null,
    y: null,

    per_day: [],
  };

  public labels: AbstractD3Labels = {
    container: null,
    labels: {},
  };

  public data_objects: DataObjects = {
    container: null,
    current_day: null,
  };

  protected colors: { [key in 'range']: `#${string}` } = {
    range: '#555',
  };

  // #endregion

  // #region -> (class basics)

  /** */
  protected LOGGER: ConsoleLoggerService = new ConsoleLoggerService('D3SvgApiaryWeightDiffFactory', true);

  /** */
  constructor(protected _shared_cursor: D3SharedCursorService, _appState: AppStateService, protected _ngZone: NgZone) {
    super(_shared_cursor, _appState, _ngZone);

    this.margins.left = 45;
    this.margins.right = 20;

    this.show_day_cycle_grid = false;
  }

  /** */
  public destroy(): void {
    super.destroy();
  }

  // #endregion

  /** */
  public get has_data() {
    const data = this.incoming_data;

    if (isNil(data)) {
      return false;
    }

    const keepable_data = data.filter(datum => {
      const total_with_data = datum?.values.filter(value => !isNil(value?.weight_diff))?.length;

      return total_with_data > 0;
    });

    if (keepable_data?.length > 0) {
      return true;
    }

    return false;
  }

  // #region -> (creation methods)

  /** */
  protected create_chart_defs(): void {
    super.create_chart_defs();

    this.definitions
      .append('svg:marker')
      .attr('id', `triangle-start`)
      .attr('refX', 0)
      .attr('refY', 5)
      .attr('markerWidth', 7)
      .attr('markerHeight', 7)
      .attr('markerUnits', 'strokeWidth')
      .attr('viewBox', '0 0 10 10')
      .attr('orient', 'auto')
      .append('path')
      .attr('d', 'M 10,10 L 0,5 L 10,0')
      .style('fill', 'none')
      .style('stroke', this.colors.range);

    this.definitions
      .append('svg:marker')
      .attr('id', `triangle-end`)
      .attr('refX', 10)
      .attr('refY', 5)
      .attr('markerWidth', 7)
      .attr('markerHeight', 7)
      .attr('markerUnits', 'strokeWidth')
      .attr('viewBox', '0 0 10 10')
      .attr('orient', 'auto')
      .append('path')
      .attr('d', 'M 0,0 L 10,5 L 0,10')
      .style('fill', 'none')
      .style('stroke', this.colors.range);

    const gradient_out_of_range_weight_diff = this.definitions
      .append('linearGradient')
      .attr('id', 'maskOutOfRangeWeightDiff')
      .attr('x1', '0.5')
      .attr('x2', '0.5')
      .attr('y1', '100%')
      .attr('y2', '0%');
    gradient_out_of_range_weight_diff.append('stop').attr('offset', '0%').attr('stop-color', 'transparent');
    gradient_out_of_range_weight_diff.append('stop').attr('offset', '79%').attr('stop-color', 'transparent');
    gradient_out_of_range_weight_diff.append('stop').attr('offset', '80%').attr('stop-color', 'white');
    gradient_out_of_range_weight_diff.append('stop').attr('offset', '82%').attr('stop-color', 'white');
    gradient_out_of_range_weight_diff.append('stop').attr('offset', '83%').attr('stop-color', 'transparent');
    gradient_out_of_range_weight_diff.append('stop').attr('offset', '85%').attr('stop-color', 'transparent');
    gradient_out_of_range_weight_diff.append('stop').attr('offset', '86%').attr('stop-color', 'white');
    gradient_out_of_range_weight_diff.append('stop').attr('offset', '88%').attr('stop-color', 'white');
    gradient_out_of_range_weight_diff.append('stop').attr('offset', '89%').attr('stop-color', 'transparent');
    gradient_out_of_range_weight_diff.append('stop').attr('offset', '91%').attr('stop-color', 'transparent');
    gradient_out_of_range_weight_diff.append('stop').attr('offset', '92%').attr('stop-color', 'white');
    gradient_out_of_range_weight_diff.append('stop').attr('offset', '94%').attr('stop-color', 'white');
    gradient_out_of_range_weight_diff.append('stop').attr('offset', '95%').attr('stop-color', 'transparent');
    gradient_out_of_range_weight_diff.append('stop').attr('offset', '100%').attr('stop-color', 'transparent');

    const gradient_out_of_range_weight_diff_reversed = this.definitions
      .append('linearGradient')
      .attr('id', 'maskOutOfRangeWeightDiffReversed')
      .attr('x1', '0.5')
      .attr('x2', '0.5')
      .attr('y1', '0%')
      .attr('y2', '100%');
    gradient_out_of_range_weight_diff_reversed.append('stop').attr('offset', '0%').attr('stop-color', 'transparent');
    gradient_out_of_range_weight_diff_reversed.append('stop').attr('offset', '79%').attr('stop-color', 'transparent');
    gradient_out_of_range_weight_diff_reversed.append('stop').attr('offset', '80%').attr('stop-color', 'white');
    gradient_out_of_range_weight_diff_reversed.append('stop').attr('offset', '82%').attr('stop-color', 'white');
    gradient_out_of_range_weight_diff_reversed.append('stop').attr('offset', '83%').attr('stop-color', 'transparent');
    gradient_out_of_range_weight_diff_reversed.append('stop').attr('offset', '85%').attr('stop-color', 'transparent');
    gradient_out_of_range_weight_diff_reversed.append('stop').attr('offset', '86%').attr('stop-color', 'white');
    gradient_out_of_range_weight_diff_reversed.append('stop').attr('offset', '88%').attr('stop-color', 'white');
    gradient_out_of_range_weight_diff_reversed.append('stop').attr('offset', '89%').attr('stop-color', 'transparent');
    gradient_out_of_range_weight_diff_reversed.append('stop').attr('offset', '91%').attr('stop-color', 'transparent');
    gradient_out_of_range_weight_diff_reversed.append('stop').attr('offset', '92%').attr('stop-color', 'white');
    gradient_out_of_range_weight_diff_reversed.append('stop').attr('offset', '94%').attr('stop-color', 'white');
    gradient_out_of_range_weight_diff_reversed.append('stop').attr('offset', '95%').attr('stop-color', 'transparent');
    gradient_out_of_range_weight_diff_reversed.append('stop').attr('offset', '100%').attr('stop-color', 'transparent');

    const weight_diff_current_day = this.definitions
      .append('pattern')
      .attr('id', `${this.unique_id}-weight-diff-current-day-pattern`)
      .attr('width', '15')
      .attr('height', '15')
      .attr('patternUnits', 'userSpaceOnUse')
      .attr('patternTransform', 'rotate(45 0 0)');

    weight_diff_current_day.append('rect').attr('x', 0).attr('y', 0).attr('width', 5).attr('height', '100%').attr('fill', '#ffd78d');
  }

  /** */
  protected create_scales(): void {
    super.create_scales();

    this.scales.y = d3
      .scaleLinear()
      .domain([-2.5, 2.5])
      .rangeRound([this.calc_size_of(this.box_sizing.height, ['-top', '-bottom']), 0]);
  }

  /** */
  protected create_axes(): void {
    super.create_axes();

    this.axes.middle.container = this.axes.container
      .append('g')
      .attr('class', 'd3-middle-axis')
      .attr('transform', `translate(${this.calc_size_of(0, ['+left'])}, ${this.calc_size_of(0, ['+top'])})`);
    this.axes.middle.line = this.axes.middle.container
      .append('line')
      .style('shape-rendering', 'crispEdges')
      .style('stroke-width', '1px')
      .style('stroke', '#d0d0d0');

    this.axes.range.container = this.axes.container
      .append('g')
      .attr('class', 'd3-range-axis')
      .attr('transform', `translate(${this.calc_size_of(0, ['+left'])}, ${this.calc_size_of(0, ['+top'])})`);
    this.axes.range.line = this.axes.range.container
      .append('line')
      .attr('stroke', this.colors.range)
      .attr('stroke-width', 1)
      .attr('stroke-dasharray', '10 2.5')
      .attr('marker-start', 'url(#triangle-start)')
      .attr('marker-end', 'url(#triangle-end)');
    this.axes.range.text = this.axes.range.container
      .append('text')
      .attr('x', 0)
      .attr('y', 0)
      .attr('text-anchor', 'middle')
      .attr('font-size', '12px')
      .attr('transform', `rotate(-90)`)
      .attr('font-weight', '600')
      .attr('fill', this.colors.range)
      .text('+/- 2.5 kg');

    this.apply_axes_format();
  }

  /** */
  public build_event_objects(): void {
    this.user_events.weight.container = this.create_html_tooltip(this.svg_parent);

    this.data_objects.current_day = this.data_objects.container
      .append('rect')
      .attr('class', 'weight-diff-current-day')
      .attr('x', 0)
      .attr('y', 0)
      .attr('width', 0)
      .style('display', 'initial')
      .attr('height', this.box_sizing.height - this.margins.top - this.margins.bottom)
      .attr('fill', `url(#${this.unique_id}-weight-diff-current-day-pattern)`);
  }

  // #endregion

  // #region -> (updating methods)

  /** */
  public resize(): void {
    this.scales.y.rangeRound([this.calc_size_of(this.box_sizing.height, ['-top', '-bottom']), 0]);

    this.axes.middle.line
      .attr('x1', 0)
      .attr('y1', this.scales.y(0))
      .attr('x2', this.calc_size_of(this.box_sizing.width, ['-left', '-right']))
      .attr('y2', this.scales.y(0));

    this.axes.range.line
      .attr('x1', 0)
      .attr('y1', 0)
      .attr('x2', 0)
      .attr('y2', this.calc_size_of(this.box_sizing.height, ['-top', '-bottom']));

    this.axes.range.text.attr('x', -this.scales.y(0)).attr('y', -10);

    const size_of_last_day = this.scales.per_day[this.scales.per_day.length - 1]?.range()?.[1] ?? null;
    if (!isNil(size_of_last_day)) {
      this.data_objects.current_day
        .attr('width', size_of_last_day)
        .attr('x', this.calc_size_of(this.box_sizing.width, ['-left', '-right']) - size_of_last_day)
        .attr('height', this.box_sizing.height - this.margins.top - this.margins.bottom);
    }

    super.resize();
  }

  // #endregion

  /** */
  public append_data(is_after_resize: boolean): void {
    this.scales.time.domain([this.date_range.start, this.date_range.end]);

    if (!this.has_data) {
      this.data_objects.container.select('.days').remove();

      super.append_data(is_after_resize);
      return;
    }

    // Dyanmically create scales
    if (this.scales.per_day.length < this.days_to_display) {
      const total_to_add = this.days_to_display - this.scales.per_day.length;
      range(total_to_add).forEach(() => this.scales.per_day.push(d3.scaleBand<number>().padding(1)));
    }

    // Calculation of the range
    const hive_weight_diffs = flatten(
      this.incoming_data.map(datum => datum.values.filter(point => !isNil(point?.weight_diff))).filter(points => points?.length > 0)
    ).map(point => Math.abs(point.weight_diff));

    let selected_range = 2;

    // IF (total_of_weight_diff_greater_than_sel_range) GREATER THAN (1/4 of weights_diff)
    while (hive_weight_diffs.filter(weight_diff => weight_diff > selected_range).length > Math.round((1 / 4) * hive_weight_diffs.length)) {
      selected_range = selected_range + 0.1;
    }

    selected_range = Math.ceil(selected_range * 10) / 10;

    this.axes.range.text.text(`+/- ${selected_range.toFixed(1)} kg`);

    // Update scales
    this.scales.y.domain([-selected_range, selected_range]);

    this.data_objects.container.select('.days').remove();
    const days = this.data_objects.container.append('g').attr('class', 'days');

    this.incoming_data.forEach((datum, which_day, self) => {
      if (isSameDay(datum.date, this.date_range.end.toJSDate())) {
        return;
      }

      // Update custom scales
      const next = self?.[which_day + 1]?.date ?? this.date_range.end.toJSDate();
      const scale_of_day = this.scales.per_day[which_day];

      if (isNil(scale_of_day)) {
        return;
      }

      if (!isNil(next)) {
        const start = this.scales.time(datum.date);
        const end = this.scales.time(next);

        this.scales.per_day[which_day]
          .domain(range(datum.values.length))
          .rangeRound([0, end - start])
          .paddingOuter(0.1)
          .paddingInner(0.2);
      }

      const size_of_last_day = this.scales.per_day[which_day].range()[1];
      this.data_objects.current_day
        .attr('width', size_of_last_day)
        .attr('x', this.calc_size_of(this.box_sizing.width, ['-left', '-right']) - size_of_last_day);

      const day = days
        .append('g')
        .datum(datum)
        .attr('class', 'day')
        .attr('transform', `translate(${this.scales.time(datum.date)}, 0)`);

      const should_draw_rectangle = this.days_to_display <= 7 && datum.values.length <= 3;

      datum.values.forEach((didd_point, index_of_didd_point) => {
        if (which_day === self.length - 2 && isSameDay(datum.date, new Date())) {
          this.data_objects.current_day.style('display', 'initial');
        } else {
          this.data_objects.current_day.style('display', 'none');
        }

        this.draw_single_didd_point(
          should_draw_rectangle ? 'rectangle' : 'line',
          { diff_weight_point: didd_point, index: index_of_didd_point },
          { container: day, which_day: which_day },
          selected_range
        );

        if (this.days_to_display <= 7) {
          const data_text = day
            .append('text')
            .attr('x', this.scales.per_day[which_day](index_of_didd_point) + this.scales.per_day[which_day].bandwidth() / 2)
            .style('font-size', '11px')
            .style('font-weight', '600')
            .attr('text-anchor', 'middle')
            .text(() => {
              let template_text = '';

              if (isNil(didd_point?.weight_diff)) {
                return 'nc.';
              }

              if (didd_point?.weight_diff > 0) {
                template_text += `+ `;
              } else if (didd_point?.weight_diff === 0) {
                template_text += '';
              } else {
                template_text += '- ';
              }

              template_text += Math.abs(didd_point?.weight_diff).toFixed(2);

              return template_text;
            })
            .style('transform-origin', 'center center')
            .style('transform-box', 'fill-box');

          const width = data_text.node().getBoundingClientRect().width;
          const value_label_can_fit_over_data = should_draw_rectangle ? width <= this.scales.per_day[which_day].bandwidth() : false;

          if (value_label_can_fit_over_data) {
            const height = data_text.node().getBoundingClientRect().height / 2;
            data_text.attr('y', this.scales.y(0) + ((didd_point?.weight_diff ?? 0) > 0 ? height + 7 : -height));
          } else {
            data_text.style('transform', 'rotate(-90deg)');
            const height = data_text.node().getBoundingClientRect().height / 2;
            data_text.attr('y', this.scales.y(0) + ((didd_point?.weight_diff ?? 0) > 0 ? height + 10 : -height - 5));
          }
        }
      });
    });

    super.append_data(is_after_resize);
  }

  /** */
  private draw_single_didd_point(
    type: 'rectangle' | 'line',
    point: { diff_weight_point: HiveDiffWeightPoint; index: number },
    day: { container: d3.Selection<SVGGElement, { date: Date; values: HiveDiffWeightPoint[] }, HTMLElement, any>; which_day: number },
    selected_range: number
  ) {
    if (isNil(point?.diff_weight_point?.weight_diff)) {
      return;
    }

    const is_out_of_range = Math.abs(point?.diff_weight_point?.weight_diff ?? 0) > selected_range;

    const weight_diff_ranged =
      (point?.diff_weight_point?.weight_diff ?? 0) >= 0
        ? min([point?.diff_weight_point?.weight_diff ?? 0, selected_range])
        : max([point?.diff_weight_point?.weight_diff ?? 0, -selected_range]);

    if (type === 'rectangle') {
      const height_of_rectangle =
        (weight_diff_ranged ?? 0) >= 0
          ? this.scales.y(0) - this.scales.y(weight_diff_ranged)
          : this.scales.y(weight_diff_ranged) - this.scales.y(0);

      const pos_y = (weight_diff_ranged ?? 0) >= 0 ? this.scales.y(0) - height_of_rectangle : this.scales.y(0);

      const data_rectangle = day?.container
        .append('rect')
        .attr('x', () => this.scales.per_day[day.which_day](point?.index))
        .attr('y', pos_y)
        .attr('width', this.scales.per_day[day.which_day].bandwidth())
        .attr('height', height_of_rectangle)
        .attr('fill', point?.diff_weight_point.hive_color);

      if (is_out_of_range) {
        if (point?.diff_weight_point?.weight_diff > 0) {
          const data_rectangle_mask = day?.container
            .append('rect')
            .attr('x', () => this.scales.per_day[day.which_day](point?.index))
            .attr('y', pos_y)
            .attr('width', this.scales.per_day[day.which_day].bandwidth())
            .attr('height', height_of_rectangle)
            .attr('fill', 'url(#maskOutOfRangeWeightDiff)');
        } else {
          const data_rectangle_mask_reversed = day?.container
            .append('rect')
            .attr('x', () => this.scales.per_day[day.which_day](point?.index))
            .attr('y', pos_y)
            .attr('width', this.scales.per_day[day.which_day].bandwidth())
            .attr('height', height_of_rectangle)
            .attr('fill', 'url(#maskOutOfRangeWeightDiffReversed)');
        }
      }
    } else {
      const data_line = day?.container
        .append('line')
        .attr('x1', this.scales.per_day[day.which_day](point?.index) + this.scales.per_day[day.which_day].bandwidth() / 2)
        .attr('x2', this.scales.per_day[day.which_day](point?.index) + this.scales.per_day[day.which_day].bandwidth() / 2)
        .attr('y1', this.scales.y(0))
        .attr('y2', this.scales.y(weight_diff_ranged))
        .attr('stroke', point?.diff_weight_point?.hive_color)
        .attr('stroke-width', 3);

      if (is_out_of_range) {
        data_line.attr('stroke-dasharray', 5);
      }

      if (this.days_to_display <= 7) {
        const data_circle = day?.container
          .append('circle')
          .attr('cx', this.scales.per_day[day.which_day](point?.index) + this.scales.per_day[day.which_day].bandwidth() / 2)
          .attr('cy', this.scales.y(weight_diff_ranged))
          .attr('r', '3')
          .attr('fill', point?.diff_weight_point.hive_color);
      }
    }
  }

  // #region -> (user event management)

  /** */
  private _previous_displayed_day: Date = null;

  /** */
  private _previous_displayed_template: string = null;

  /** */
  public on_mouse_enter(is_from_shared_cursor?: boolean): void {
    super.on_mouse_enter(is_from_shared_cursor, false);

    if (!this.has_data) {
      return;
    }
  }

  /** */
  public on_mouse_move(
    event: Date | MouseEvent,
    data: { date: Date; tz_date: Date; timezone: string; values: HiveDiffWeightPoint[] }[],
    is_from_shared_cursor?: boolean
  ): void {
    if (!this.has_data) {
      return null;
    }

    let pointed_position_x: number = null;
    let pointed_position_y: number = null;

    let start_of_day: Date = null;

    if (is_from_shared_cursor) {
      start_of_day = startOfDay(event as Date);
      pointed_position_x = this.scales.time(start_of_day);
    } else {
      const source_element = (event as MouseEvent).target as SVGGElement;
      const boundings = source_element.getBoundingClientRect();

      pointed_position_x = (event as MouseEvent).clientX - boundings.left;
      pointed_position_y = (event as MouseEvent).clientY - boundings.top;

      start_of_day = startOfDay(this.scales.time.invert(pointed_position_x));
    }

    if (!isEqual(start_of_day, this._previous_displayed_day)) {
      this._previous_displayed_day = start_of_day;
      const data_of_day = data.find(datum => isEqual(datum.date, start_of_day));

      if (!isNil(data_of_day)) {
        this._previous_displayed_template = this.create_tooltip_header(data_of_day.tz_date, this._appState.dl.ll);

        data_of_day.values.forEach(didd_point => {
          this._previous_displayed_template += `<div class="d3-chart-tooltip-list-item">`;

          this._previous_displayed_template += '<div class="d3-chart-tooltip-series-name">';
          this._previous_displayed_template += `<span class="mdi mdi-minus-thick" style="color: ${didd_point.hive_color}"></span>`;
          this._previous_displayed_template += `${didd_point?.hive_name}`;
          this._previous_displayed_template += '</div>';

          this._previous_displayed_template += '<div class="d3-chart-tooltip-value">';
          this._previous_displayed_template += `${didd_point?.weight_diff?.toFixed(2) ?? '?'} kg`;
          this._previous_displayed_template += '</div>';

          this._previous_displayed_template += '</div>';
        });

        if (pointed_position_x < this.user_events.event_bounds.node().getBoundingClientRect().width / 2) {
          const left_placement = this.margins.left + (this.scales.time(endOfDay(start_of_day)) + 10);
          this.user_events.weight.container
            .html(this._previous_displayed_template)
            .style('right', null)
            .style('left', `${left_placement}px`)
            .style('top', `${this.user_events.event_bounds.node().getBoundingClientRect().height / 2}px`);
        } else {
          const right_placement =
            this.margins.right + (this.user_events.event_bounds.node().getBoundingClientRect().width - this.scales.time(start_of_day)) + 10;
          this.user_events.weight.container
            .html(this._previous_displayed_template)
            .style('left', null)
            .style('right', `${right_placement}px`)
            .style('top', `${this.user_events.event_bounds.node().getBoundingClientRect().height / 2}px`);
        }

        this.user_events.weight.container.style('display', null);
        this.data_objects.container
          .selectChild('.days')
          .selectAll('.day')
          .each((datum: { date: Date; values: HiveDiffWeightPoint[] }, index, groups: any[]) => {
            if (!isEqual(start_of_day, datum.date)) {
              d3.select(groups[index]).style('filter', 'grayscale(1) opacity(0.3)');
            } else {
              d3.select(groups[index]).style('filter', 'none');
            }
          });
      } else {
        this.user_events.weight.container.style('display', 'none');
        this.data_objects.container
          .selectChild('.days')
          .selectAll('.day')
          .each((a: { date: Date; values: HiveDiffWeightPoint[] }, i, groups: any[]) =>
            groups.forEach(g => d3.select(g).style('filter', 'none'))
          );
      }
    }
  }

  /** */
  public on_mouse_out(is_from_shared_cursor?: boolean): void {
    super.on_mouse_out(is_from_shared_cursor);

    this.user_events.weight.container.style('display', 'none');

    this.data_objects.container
      .selectChild('.days')
      .selectAll('.day')
      .each((a: { date: Date; values: HiveDiffWeightPoint[] }, i, groups: any[]) =>
        groups.forEach(g => d3.select(g).style('filter', 'none'))
      );

    this._previous_displayed_day = null;
  }

  // #endregion
}

// #endregion
