import { ChangeDetectionStrategy, Component } from '@angular/core';
import { BgControlWidgetComponent as ControlWidget } from '../control/control.widget';

@Component({
  selector: 'ef-checkbox-widget',
  templateUrl: './checkbox.widget.html',
  styleUrls: ['./checkbox.widget.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EfCheckboxWidget extends ControlWidget {
}
