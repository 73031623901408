<div class="device-com-conf">
  <sf-form-element [formProperty]="$any(formProperty.properties)?.conf"></sf-form-element>

  <label>{{'DEVICE.ALL.CONFIG.Communication hours' | translate}}</label>

  <ng-container *ngIf="(com_config$$ | async) === 'one_by_day' || (com_config$$ | async) === 'every_two_days'">
    <ngx-slider [value]="local_hour_one$$ | async" [options]="options" (valueChange)="local_hour_one = $event"></ngx-slider>
  </ng-container>

  <ng-container *ngIf="(com_config$$ | async) === 'two_by_day'">
    <ngx-slider
      [options]="options"
      [value]="local_hour_one$$ | async"
      [highValue]="local_hour_two$$ | async"
      (valueChange)="local_hour_one = $event"
      (highValueChange)="local_hour_two = $event"
    ></ngx-slider>
  </ng-container>
</div>
