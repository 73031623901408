import merge from 'lodash.merge';
import { environment as env } from './common';

import { IEnvironment, IEnvironmentConfig } from './common';

const config: Partial<IEnvironmentConfig> = {
  beta_version: {
    is_beta_env: false,
    should_check_for_beta: false,
    should_check_for_classy: false,
  },
};

export const environment: IEnvironment = {
  env: 'preprod',
  production: true,
  version: env.version,
  package_version: env.package_version,
  wa_secret_key: 'thbz3wNIjPwkHksycrHupEXeyX',

  config: merge(env.config, config),

  activateAsyncStream: true,

  activateDailyRAZWarning: false,

  Beeguard1Url: 'https://v1.beeguard.net',

  Beeguard2ApiUrl: 'https://api2.beeguard.net',
  Beeguard2StreamUrl: 'https://api2stream.beeguard.net',

  DeviceApiUrl: 'https://api2.beeguard.net/devices',

  UserApiUrl: 'https://users.beeguard.net',

  grafana: {
    url: 'https://grafana.beeguard.direct',
    orgId: '2',
  },

  sentry: {
    url: 'https://b40257a4d3123cad23e3f38fb215a175@sentry.siconsult.cloud/3',
    release: env.sentry.release,
    environment: 'prod-preprod',
    traces_sample_rate: 1,
  },

  show_welcome: false,

  userManualUrl: 'https://doc.beeguard.net',

  anfrlUrl: 'https://anfr.beeguard.net',

  //salesiq_code: 'c28fc5e582968949ef57e75707b80b53978c14b221c38109a172315481ad602f',
  salesiq_code: null,
  google_api_key: 'AIzaSyAfrkMvaUlC7LdDlEKHx26RmnDlO_S2gKc',

  analytics: {
    clarity: 'bmjy38pe50',
    google_analytics: 'G-BCQZ5091TJ',
  },
};
