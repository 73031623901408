<ng-container *ngIf="visible$$ | async">
  <form-element-header [schema]="schema" [showLabel]="false" [title_style]="options.title_style"></form-element-header>

  <mat-form-field appearance="outline">
    <mat-label>{{schema.label | translate}}</mat-label>

    <bg2-user-select
      [placeholder]="schema.label"
      [required]="schema?.isRequired"
      [value]="formProperty.valueChanges | async"
      [options]="{
        no_impersonate: options.no_impersonate,
        readonly: options.readonly
      }"
      (selector_ref)="select_ref = $event"
      (valueChange)="formProperty.setValue($event, false)"
    >
    </bg2-user-select>
  </mat-form-field>
</ng-container>
