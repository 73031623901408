import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, OnDestroy } from '@angular/core';

import { UAParser } from 'ua-parser-js';

import { combineLatest, Subscription } from 'rxjs';

import { isNil } from 'lodash-es';

import { ConsoleLoggerService } from 'app/core/console-logger.service';
import { BeeguardAuthService } from 'app/core/auth/beeguard-auth.service';

import { Gtag } from 'app/models/google-analytics/gtag';

import { Dictionary } from 'app/typings/core/interfaces';

import { environment } from 'environments/environment';
import { AppStateService } from 'app/core/app-state.service';
import { filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class GoogleAnalyticsService implements OnDestroy {
  // #region -> (service basics)

  private readonly LOGGER = new ConsoleLoggerService('GoogleAnalyticsService', true);

  /** */
  private _is_authorized_sub: Subscription = null;

  constructor(@Inject(DOCUMENT) private _document: Document, private _oauth: BeeguardAuthService, private _appState: AppStateService) {}

  public initialize(): void {
    this._is_authorized_sub = combineLatest([this._appState.lang$$, this._oauth.wait_is_authorized$$])
      .pipe(
        filter(([language, is_authorized]) => is_authorized === true),
        filter(([language, is_authorized]) => !isNil(language))
      )
      .subscribe({
        next: ([language, is_authorized]) => {
          if (isNil(environment?.analytics?.google_analytics)) {
            return;
          }

          const current_browser = new UAParser(navigator.userAgent).getBrowser();
          const gtag_config_cmd_options: Dictionary<any> = {};

          if (!isNil(localStorage.getItem('config.debug.analytics.google'))) {
            gtag_config_cmd_options.debug_mode = (JSON.parse(localStorage.getItem('config.debug.analytics.google')) as boolean) || false;
          }

          const ga4_tag_manager: HTMLScriptElement = document.createElement('script');
          ga4_tag_manager.async = true;
          ga4_tag_manager.src = `https://www.googletagmanager.com/gtag/js?id=${environment?.analytics?.google_analytics}`;

          const ga4_tag_script = document.createElement('script');
          ga4_tag_script.innerHTML = 'window.dataLayer = window.dataLayer || [];';
          ga4_tag_script.innerHTML += 'function gtag(){dataLayer.push(arguments);}';
          ga4_tag_script.innerHTML += `gtag('js', new Date());`;
          ga4_tag_script.innerHTML += `gtag('set', 'user_properties', { 
            'app_user_id': ${this._oauth.real_user_id}, 
            'app_user_lang':'${language}',
            'app_user_browser_version':'${current_browser?.version}',
          });`;

          const initial_config: Dictionary<any> = {};

          if (JSON.parse(localStorage.getItem('config.debug.analytics.google') ?? 'false')) {
            initial_config.debug_mode = true;
          }

          ga4_tag_script.innerHTML += `gtag('config', '${environment?.analytics?.google_analytics}', ${JSON.stringify(initial_config)});`;

          this._document.body.appendChild(ga4_tag_manager);
          this._document.body.appendChild(ga4_tag_script);
        },
      });
  }

  ngOnDestroy(): void {
    this._is_authorized_sub?.unsubscribe();
  }

  // #endregion

  // #region -> (script management)

  private is_available(): boolean {
    return !isNil(this.gtag);
  }

  private get gtag(): Gtag {
    return ((window as any) || {})?.gtag || null;
  }

  // #endregion

  // #region -> (specific event senders)

  // #endregion
}
