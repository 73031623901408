<div class="bg-dialog">
  <!-- Dialog header -->
  <dialog-header>
    <span>{{ 'WIDGETS.DIALOGS_MODALS.IMAGE_CROPPER_DIALOG.Modify image (crop & compress)' | translate }}</span>
  </dialog-header>

  <!-- Dialog body -->
  <dialog-body *ngLet="input_file_base64$$ | async; let input_file_base64" [show_hr]="false">
    <ng-container *ngIf="is_loading_image.value$$ | async">
      <bg2-loading-skeleton [theme]="{ height: '100%', width: '100%' }"></bg2-loading-skeleton>
    </ng-container>

    <div *ngIf="input_file_base64">
      <img #cropperImage [src]="input_file_base64" />
    </div>
  </dialog-body>

  <!-- Dialog footer -->
  <div class="dialog-footer">
    <bg2-button [type]="'cancel'" [message]="'ALL.COMMON.Cancel' | translate" (btn_click)="cancel()"></bg2-button>

    <bg2-button
      [type]="'action'"
      [message]="'ALL.COMMON.Save' | translate"
      [disabled]="(has_cropped_file$$ | async) === false"
      (btn_click)="save()"
    ></bg2-button>
  </div>
</div>
