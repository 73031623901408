import { Component, ChangeDetectionStrategy } from '@angular/core';

import { ISchema } from 'ngx-schema-form';
import { marker as i18n } from '@biesbjerg/ngx-translate-extract-marker';

import { replay } from '@bg2app/tools/rxjs';
import { Observable, forkJoin, map, switchMap, take } from 'rxjs';

import { ConsoleLoggerService } from 'app/core/console-logger.service';

import { AbstractDialogComponent } from 'app/widgets/dialogs-modals/abstract-dialog.component';

import { DRDevice, SimpleSetterGetter } from 'app/models';
import { DevicesDialogParams } from '../../devices-dialog-params';

interface DeviceBulkBatteryChangeDialogParams extends DevicesDialogParams {}

@Component({
  selector: 'bg2-device-bulk-battery-change-dialog',
  templateUrl: './device-bulk-battery-change-dialog.component.html',
  styleUrls: ['./device-bulk-battery-change-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DevicesBulkBatteryChangeDialogComponent extends AbstractDialogComponent<DeviceBulkBatteryChangeDialogParams, any> {
  // #region -> (dialog basics)

  /** */
  private LOGGER = new ConsoleLoggerService('DevicesBulkBatteryChangeDialogComponent', false);

  /** */
  constructor() {
    super();
  }

  public close(value: boolean): void {
    this.complete(value);
  }

  // #endregion

  // #region -> (loadings)

  /** */
  public is_saving = new SimpleSetterGetter(false);

  // #endregion

  public devices$$: Observable<DRDevice[]> = this.input_params$$.pipe(
    map(params => params.devices),
    replay()
  );

  // #region -> (schema management)

  /** */
  public readonly schema: ISchema = {
    type: 'object',
    required: ['date'],
    properties: {
      date: {
        type: 'string',
        widget: 'date-time',
        default: new Date().toISOString(),
        label: i18n<string>('VIEWS.DEVICES.DIALOGS_AND_MODALS.DIALOGS.DEVICE_BULK_BATTERY_CHANGE.Date of battery change'),
        options: {
          pickerType: 'both',
        },
      },
      comment: {
        type: 'string',
        widget: 'ng-mat-textarea',
        label: i18n<string>('ALL.COMMON.Comment'),
      },
    },
  };

  /** */
  public form_model = new SimpleSetterGetter({ date: null, comment: null });

  /** */
  public is_valid = new SimpleSetterGetter(false);

  public submit(): void {
    this.is_saving.value = true;

    this.form_model.value$$
      .pipe(
        take(1),
        switchMap(form_model =>
          this.devices$$.pipe(
            take(1),
            switchMap(devices => {
              const create_battery_change_for_devices$$ = devices?.map(device =>
                device.addBatCh(new Date(form_model.date), form_model?.comment ?? undefined)
              );

              return forkJoin(create_battery_change_for_devices$$);
            })
          )
        )
      )
      .subscribe({
        next: () => {},
        complete: () => {
          this.is_saving.value = false;
          this.close(true);
        },
      });
  }

  // #endregion
}
