import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { isNil } from 'lodash-es';

import { marked } from 'marked';

/**
 * Transforms markdown string to html string with tags.
 *
 * @example <div [innerHtml]="'**example strong text**' | markdown"></div>
 */
@Pipe({ name: 'markdown', pure: true })
export class MarkdownPipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {}

  transform(sentence: string): SafeHtml {
    if (isNil(sentence) || sentence.length === 0) {
      return '';
    } else {
      const parsed_sentence = marked.parse(sentence);
      const sanitized_sentence = this.sanitizer.bypassSecurityTrustHtml(parsed_sentence);
      return sanitized_sentence;
    }

  }
}
