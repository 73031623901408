import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { BehaviorSubject, combineLatest, map, tap } from 'rxjs';
import { waitForNotNilProperties } from '@bg2app/tools/rxjs';

import { get, isNil } from 'lodash-es';

import { ConsoleLoggerService } from 'app/core/console-logger.service';

import { Hive } from 'app/models';

@Component({
  selector: 'bg2-navigate-identified-hives',
  templateUrl: './navigate-identified-hives.component.html',
  styleUrls: ['./navigate-identified-hives.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavigateIdentifiedHivesComponent {
  // #region -> (component basics)

  /** */
  private readonly LOGGER = new ConsoleLoggerService('NavigateIdentifiedHivesComponent', true);

  // #endregion

  /** */
  private _hives$$ = new BehaviorSubject<Hive[]>([]);

  /** */
  public hives$$ = this._hives$$.asObservable();

  /** */
  @Input()
  public set hives(hives: Hive[]) {
    this._hives$$.next(hives);

    if (isNil(this._current_selected_hive_id$$.getValue())) {
      this.select_hive(get(hives, '[0].id', null));
    }
  }

  // #region -> (hive selector management)

  /** */
  private _current_selected_hive_id$$ = new BehaviorSubject(null);

  /** */
  public current_selected_hive_id$$ = this._current_selected_hive_id$$.asObservable();

  /** */
  @Output()
  public when_hive_id_is_selected = new EventEmitter<number>();

  /** */
  public select_hive(id: number): void {
    this._current_selected_hive_id$$.next(id);
    this.when_hive_id_is_selected.emit(id);
  }

  /** */
  public is_hive_currently_selected$$ = (hive: Hive) =>
    combineLatest({ current_selected_id: this.current_selected_hive_id$$, hive_id: hive.id$$ }).pipe(
      waitForNotNilProperties(),
      map(({ current_selected_id, hive_id }) => current_selected_id === hive_id)
    );

  // #endregion
}
