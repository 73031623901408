import { Component, Input, ChangeDetectionStrategy } from '@angular/core';

import { filter, map, switchMap } from 'rxjs';
import { format } from 'date-fns';

import { TranslateService } from '@ngx-translate/core';

import { parseDate } from 'app/misc/tools';
import { DRDevice } from '../../../../../models';
import { AppStateService } from '../../../../../core/app-state.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { replay } from '@bg2app/tools/rxjs';
import { isNil } from 'lodash-es';

@Component({
  selector: 'bg2-device-alarms-status',
  templateUrl: './alarms-status.component.html',
  styleUrls: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DeviceAlarmsStatusComponent {
  // #region -> (component basics)

  constructor(private _appState: AppStateService, private _translate: TranslateService) {}

  // #endregion

  // #region -> (device management)

  private _device$: BehaviorSubject<DRDevice> = new BehaviorSubject(null);
  public device$$: Observable<DRDevice> = this._device$.asObservable().pipe(replay());

  @Input()
  public set device(device: DRDevice) {
    this._device$.next(device);
  }

  // #endregion

  // #region -> (device alarm management)

  /** */
  public has_alarm$$ = this.device$$.pipe(switchMap(device => device.has_currently_alarm$$));

  /** */
  public tooltip$$ = this.device$$.pipe(
    switchMap((device: DRDevice) => device?.movement_alarm$$),
    map(movement_alarm => movement_alarm?.movement_start_time),
    filter(movement_start_time => !isNil(movement_start_time)),
    switchMap(movement_start_time =>
      this._translate.stream('DEVICE.ALL.ALARM.Movement alarm since').pipe(
        map((text: string) => {
          const from: string = format(parseDate(movement_start_time), this._appState.dl.lll, {
            locale: this._appState.dl.dateFns,
          });

          return `${text} ${from}`;
        })
      )
    )
  );

  // #endregion
}
