import { Beeguard2Api, DeviceApi } from 'app/core';

import { Entity, AbstractEntityUserACL } from '../../00_abstract';

/** */
export class NAEntity extends Entity<any> {
  // #region -> (class basics)

  /** */
  constructor(protected bg2Api: Beeguard2Api, protected deviceApi: DeviceApi) {
    super(bg2Api, deviceApi);

    this.type = 'NA' as any;
  }

  // #endregion

  // #region -> (acl management)

  /**
   * @inheritdoc
   */
  protected _user_acl_manager: AbstractEntityUserACL = null;

  /**
   * @inheritdoc
   */
  public get user_acl(): AbstractEntityUserACL {
    return this._user_acl_manager;
  }

  // #endregion
}
