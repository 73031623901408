import { Pipe, PipeTransform } from '@angular/core';
import { isNil } from 'lodash-es';

@Pipe({
  name: 'fixed',
  pure: true,
})
export class FixedPipe implements PipeTransform {
  public transform(value: number, fix: number = 0): string {
    if (isNil(value)) {
      return null;
    }

    return value.toFixed(fix);
  }
}
