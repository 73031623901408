<ng-container *ngIf="status$$ | async as status">
  <div *ngIf="status?.value" class="gprs-level-component">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlns:svg="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 6.3499999 6.3500002"
      version="1.1"
      [queryParamsHandling]="'merge'"
      [popperTimeout]="100"
      [popper]="tooltipCases"
      [queryParams]="{ inmodal_url: 'diagnostic' }"
      [routerLink]="open_in_modal ? ['', { outlets: { modal: ['device', { imei: device_imei$$ | async }] } }] : null"
      (click)="$event.stopPropagation()"
    >
      <!-- #region GPRS signal levels -->

      <g *ngIf="is_gprs_is_excellent$$ | async" [attr.fill]="getColor$$('gprs_excellent') | async">
        <path id="gprs_force_excellent" d="M 4.7625,5.55625 H 5.5562501 V 0.79375005 H 4.7625 Z" />
        <path id="gprs_force_good" d="m 3.4395835,5.55625 h 0.7937499 v -3.175 H 3.4395835" />
        <path id="gprs_force_low" d="m 2.1166667,5.55625 h 0.79375 V 3.7041667 h -0.79375" />
        <path id="gprs_force_very_low" d="M 0.79375,5.55625 H 1.5875 V 4.7625 H 0.79375" />
      </g>

      <g *ngIf="is_gprs_is_good$$ | async" [attr.fill]="getColor$$('gprs_good') | async">
        <path id="gprs_force_excellent" d="M 4.7625,5.55625 H 5.5562501 V 0.79375005 H 4.7625 Z" fill="lightgray" />
        <path id="gprs_force_good" d="m 3.4395835,5.55625 h 0.7937499 v -3.175 H 3.4395835" />
        <path id="gprs_force_low" d="m 2.1166667,5.55625 h 0.79375 V 3.7041667 h -0.79375" />
        <path id="gprs_force_very_low" d="M 0.79375,5.55625 H 1.5875 V 4.7625 H 0.79375" />
      </g>

      <g *ngIf="is_gprs_is_low$$ | async" [attr.fill]="getColor$$('gprs_low') | async">
        <path id="gprs_force_excellent" d="M 4.7625,5.55625 H 5.5562501 V 0.79375005 H 4.7625 Z" fill="lightgray" />
        <path id="gprs_force_good" d="m 3.4395835,5.55625 h 0.7937499 v -3.175 H 3.4395835" fill="lightgray" />
        <path id="gprs_force_low" d="m 2.1166667,5.55625 h 0.79375 V 3.7041667 h -0.79375" />
        <path id="gprs_force_very_low" d="M 0.79375,5.55625 H 1.5875 V 4.7625 H 0.79375" />
      </g>

      <g *ngIf="is_gprs_is_very_low$$ | async" [attr.fill]="getColor$$('gprs_very_low') | async">
        <path id="gprs_force_excellent" d="M 4.7625,5.55625 H 5.5562501 V 0.79375005 H 4.7625 Z" fill="lightgray" />
        <path id="gprs_force_good" d="m 3.4395835,5.55625 h 0.7937499 v -3.175 H 3.4395835" fill="lightgray" />
        <path id="gprs_force_low" d="m 2.1166667,5.55625 h 0.79375 V 3.7041667 h -0.79375" fill="lightgray" />
        <path id="gprs_force_very_low" d="M 0.79375,5.55625 H 1.5875 V 4.7625 H 0.79375" />
      </g>

      <g *ngIf="is_gprs_is_ko$$ | async" [attr.fill]="getColor$$('gprs_ko') | async">
        <path id="gprs_force_excellent" fill="#00000" d="M 4.7625,5.55625 H 5.5562501 V 0.79375005 H 4.7625 Z" />
        <path id="gprs_force_good" d="m 3.4395835,5.55625 h 0.7937499 v -3.175 H 3.4395835" />
        <path id="gprs_force_low" d="m 2.1166667,5.55625 h 0.79375 V 3.7041667 h -0.79375" />
        <path id="gprs_force_very_low" d="M 0.79375,5.55625 H 1.5875 V 4.7625 H 0.79375" />

        <path
          id="gprs_force_ko"
          style="
            fill: #ff0000;
            fill-opacity: 1;
            stroke: none;
            stroke-width: 0.0620683;
            stroke-linecap: round;
            stroke-linejoin: round;
            stroke-miterlimit: 4;
            stroke-dasharray: none;
            stroke-opacity: 1;
          "
          d="M 3.4395835,3.073524 3.0797976,3.4333106 3.9613736,4.3148862 3.0797976,5.1959668 3.4395835,5.55625 4.3211596,4.6746734 5.2027377,5.55625 5.5625239,5.1959668 4.6809473,4.3148862 5.5625239,3.4333106 5.2027377,3.073524 4.3211596,3.9551 Z"
        />
      </g>

      <!-- #endregion -->

      <!-- #region outdated icon -->
      <g *ngIf="(status$$ | async)?.outdated" id="outdated_state">
        <circle fill="white" id="path_icon_outdated_background" cx="1.8785424" cy="1.8857162" r="2" />

        <path
          id="path_icon_outdated"
          fill="red"
          d="M 1.7050321,3.6517988 C 1.2782849,3.6076991 0.91246894,3.4343689 0.61109124,3.1334818 0.45768604,2.980326 0.36145374,2.8466024 0.27009024,2.6596283 0.18414894,2.4837429 0.13477114,2.3273341 0.10518284,2.1372272 c -0.021739,-0.1396466 -0.021739,-0.3827547 0,-0.5224012 0.060395,-0.3880483 0.2283705,-0.7188877 0.5059084,-0.9964264 0.1535157,-0.1535148 0.2873132,-0.2497659 0.47402446,-0.3410008 0.1758854,-0.085945 0.3322941,-0.1353193 0.5224012,-0.1649077 0.1398058,-0.021761 0.3828447,-0.021736 0.5224011,5.22e-5 0.1930347,0.03013 0.3468183,0.078632 0.522402,0.1647638 0.1876838,0.092067 0.3207185,0.1877974 0.4740232,0.3411022 0.1533049,0.1533047 0.2490352,0.2863395 0.3411022,0.4740243 0.085288,0.1738615 0.1351698,0.3319796 0.1648072,0.5224012 0.021736,0.1396466 0.021736,0.3827547 0,0.5224012 C 3.5720096,2.5243017 3.4031787,2.8568267 3.1263432,3.1336621 2.9730385,3.2869669 2.8400038,3.3826964 2.65232,3.4747642 2.4788496,3.5598582 2.3168132,3.6109657 2.1320674,3.6388536 2.0247267,3.6550526 1.8018826,3.6618136 1.7050321,3.6518086 Z M 2.6881387,2.819619 C 2.7434417,2.787211 2.7709627,2.7153734 2.7534617,2.6491077 2.7459817,2.6207397 2.7113087,2.5838747 2.3800978,2.252006 L 2.0149942,1.8861753 V 1.4155182 0.9448601 l -0.016207,-0.030702 c -0.00891,-0.016884 -0.029558,-0.0401 -0.045879,-0.051592 -0.026846,-0.018911 -0.034845,-0.020899 -0.084191,-0.020899 -0.049348,0 -0.057339,0.00201 -0.084191,0.020899 -0.016312,0.011487 -0.036962,0.034709 -0.045871,0.051592 l -0.016207,0.030702 v 0.5215838 c 0,0.501995 5.013e-4,0.5225541 0.013463,0.5474257 0.00741,0.014211 0.1901399,0.2022983 0.406078,0.4179667 0.3403625,0.3399385 0.3967387,0.3934578 0.4236119,0.4021443 0.04019,0.012994 0.084785,0.00773 0.1225397,-0.014365 z"
        />
      </g>

      <!-- #endregion -->
    </svg>
  </div>

  <popper-content #tooltipCases>
    <ng-container *ngIf="status?.outdated">
      <span>{{ 'DEVICE.ALL.LEVEL.No com since [since_days]' | translate : { since_days: (status.timestamp | distanceToNow) } }}</span
      ><br />
    </ng-container>

    <ng-container [ngSwitch]="true">
      <span *ngSwitchCase="status.value < -67 && status.value >= -82">
        {{ 'DEVICE.ALL.LEVEL.Last signal was good [value]' | translate : { value: status.value } }}
      </span>
      <span *ngSwitchCase="status.value < -82 && status.value >= -90">
        {{ 'DEVICE.ALL.LEVEL.Last signal was medium [value]' | translate : { value: status.value } }}
      </span>
      <span *ngSwitchCase="status.value < -90">
        {{ 'DEVICE.ALL.LEVEL.Last signal was weak [value] : unstable network' | translate : { value: status.value } }}
      </span>
      <span *ngSwitchCase="status.value < -82"
        ><br />
        <doc-helper page="devices/improve_network_link" section="">
          {{ 'DEVICE.ALL.ACTION.How to improve situation' | translate }}
        </doc-helper>
      </span>
      <span *ngSwitchDefault>{{ 'DEVICE.ALL.LEVEL.Last signal was very good [value]' | translate : { value: status.value } }}</span>
    </ng-container>

    <ng-container *ngIf="!status?.value">
      <span>n.c</span>
    </ng-container>
  </popper-content>
</ng-container>
