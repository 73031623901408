import { Injectable } from '@angular/core';

const gmaps_url = 'https://maps.googleapis.com/maps/api/js';
// TODO: mv this key in environement
const key = 'AIzaSyAfrkMvaUlC7LdDlEKHx26RmnDlO_S2gKc';

@Injectable({
  providedIn: 'root',
})
export class GoogleMapsLoader {
  private static promise: any;

  public static load(): Promise<any> {
    // First time 'load' is called?
    if (!GoogleMapsLoader.promise) {
      // Make promise to load
      GoogleMapsLoader.promise = new Promise(resolve => {
        // Set callback for when google maps is loaded.
        (window as any)['__onGoogleLoaded'] = (ev: any) => {
          resolve('google maps api loaded');
        };

        const node = document.createElement('script');
        // TODO : Change maps language by adding &language=<lang> (fr | en | de | ...)
        const url = `${gmaps_url}?key=${key}&callback=__onGoogleLoaded`;
        node.src = url;
        node.type = 'text/javascript';
        document.getElementsByTagName('head')[0].appendChild(node);
      });
    }

    // Always return promise. When 'load' is called many times, the promise is already resolved.
    return GoogleMapsLoader.promise;
  }
}
