import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit } from '@angular/core';

import get from 'lodash-es/get';

import { marker as i18n } from '@biesbjerg/ngx-translate-extract-marker';

import { ConsoleLoggerService } from 'app/core/console-logger.service';
import { TableBaseComponent } from 'app/widgets/misc-widgets/table-base/table-base.component';

import { AppStateService } from 'app/core/app-state.service';
import { DRDevice } from 'app/models';

import { DatatableBaseRow } from 'app/typings/datatable/interfaces/DatatableBaseRow.iface';
import { DatatableColumn } from 'app/models/misc/datatable';
import { CompareByType } from 'app/misc/tools';
import { TranslateService } from '@ngx-translate/core';
import { UrlParamsService } from 'app/core/url-param.service';


export interface MsgRow extends DatatableBaseRow {
  msg: any;
  device: DRDevice;
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export const DEFALUT_MSG_COLS: DatatableColumn<MsgRow>[] = [
  {
    property: 'msg.time',
    short_property: 'msg_ts',
    label: i18n('VIEWS.DEVICES.MSG_TABLE.date'),
    displayed_by_default: true,
    not_configurable: false,
    is_sortable: true,
    is_groupable: false,
    compare_by: CompareByType.DATE,
  },
  {
    property: 'msg.measurement',
    short_property: 'msg_msrmt',
    label: i18n('VIEWS.DEVICES.MSG_TABLE.Measurement'),
    displayed_by_default: true,
    not_configurable: false,
    is_sortable: true,
    is_groupable: false,
    compare_by: CompareByType.STRING,
  },
  {
    property: 'msg.tags.id',
    short_property: 'msg_id',
    label: i18n('VIEWS.DEVICES.MSG_TABLE.Device ID'),
    displayed_by_default: true,
    not_configurable: false,
    is_sortable: true,
    is_groupable: false,
    compare_by: CompareByType.STRING,
  },
];

@Component({
  selector: 'bg2-device-msgs-table',
  templateUrl: 'device-msgs-table.component.html',
  styleUrls: ['device-msgs-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DeviceMsgsTableComponent extends TableBaseComponent<MsgRow> {
  private _logger = new ConsoleLoggerService(this.constructor.name, true);

  @Input()
  set msgs(msgs: MsgRow[]) {
    this.paged_data_rows = msgs;
  }

  @Input()
  public set all_columns(all_columns: DatatableColumn<MsgRow>[]) {
    this._all_columns$$.next(all_columns);
  }

  public get all_columns(): DatatableColumn<MsgRow>[] {
    return this._all_columns$$.getValue();
  }

  constructor(public appState: AppStateService, private _translate: TranslateService, private readonly _url_parameters: UrlParamsService) {
    super(_translate, _url_parameters);

    // Datatable configuration
    this.track_groupby_in_url.value = false;
    this.track_pagination_in_url.value = false;
    this.all_columns = DEFALUT_MSG_COLS;
  }

  public get(row: DatatableBaseRow, path: string) {
    return get(row, path);
  }

}
