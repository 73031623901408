const toRad = (value: number) => (value * Math.PI) / 180;

const robustAcos = (value: number): number => {
  if (value > 1) {
    return 1;
  }
  if (value < -1) {
    return -1;
  }

  return value;
};

const earthRadius = 6378137;

/** */
export function getDistance(
  from: { latitude: number; longitude: number },
  to: { latitude: number; longitude: number },
  accuracy: number = 1
): number {
  accuracy = typeof accuracy !== 'undefined' && !isNaN(accuracy) ? accuracy : 1;

  const from_latitude = from.latitude;
  const from_longitude = from.longitude;
  const to_latitude = to.latitude;
  const to_longitude = to.longitude;

  const distance =
    Math.acos(
      robustAcos(
        Math.sin(toRad(to_latitude)) * Math.sin(toRad(from_latitude)) +
          Math.cos(toRad(to_latitude)) * Math.cos(toRad(from_latitude)) * Math.cos(toRad(from_longitude) - toRad(to_longitude))
      )
    ) * earthRadius;

  return Math.round(distance / accuracy) * accuracy;
}
