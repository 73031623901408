<ng-container *ngIf="visible$$ | async">
  <form-element-header [schema]="schema" [title_style]="options.title_style"></form-element-header>
  <ng-container *ngIf="choices_lines && choices_lines.length > 0">
    <mat-radio-group [ngModel]="value$$ | async" (ngModelChange)="value = $event">
      <div class="radiogroup-line" *ngFor="let line of choices_lines">
        <!-- For each line -->
        <div class="radiogroup-options" *ngFor="let choice of line.choices" [class.checked]="choice.value === value">
          <!-- For each option of the line -->
          <mat-radio-button
            [disableRipple]="true"
            color="primary"
            [id]="'radio_' + id + '_' + choice.value"
            [name]="id + '_radiogroup'"
            [value]="choice.value"
            [checked]="choice.value === value"
          >
            <div>
              <img
                *ngIf="choice.options.img"
                src="/assets/{{choice.options.img}}"
                [ngStyle]="choice.options.img_style || {}"
                [alt]="choice.label | translate"
              />
              <span *ngIf="choice.label || !choice.options.img" [ngStyle]="choice.options.label_style">
                {{choice.label ? (choice.label | translate) : '&nbsp;'}}
              </span>
            </div>
          </mat-radio-button>
        </div>
      </div>
    </mat-radio-group>
  </ng-container>
  <ng-container *ngIf="previous$$ | async; let previous">
    <form-element-previous [previous]="previous"></form-element-previous>
  </ng-container>
</ng-container>
