import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, OnDestroy } from '@angular/core';
import { MatSnackBar, MatSnackBarRef } from '@angular/material/snack-bar';

import { isEqual, isNil } from 'lodash-es';

import { Observable, Subscription, timer, switchMap, take, debounceTime, catchError, of } from 'rxjs';

import { AppStateService } from 'app/core/app-state.service';
import { ENV } from 'app/core/providers/environment.provider';
import { ConsoleLoggerService } from 'app/core/console-logger.service';
import { BeeguardAuthService } from 'app/core/auth/beeguard-auth.service';

import { AppUpdateSnackComponent } from 'app/widgets/misc-widgets/app_update_snack/app-update-snack.component';

import { IEnvironment } from 'environments/common';

import { ApplicationVersionFile } from 'app/models/misc';
import { parse_version } from '@bg2app/tools/misc';

@Injectable({
  providedIn: 'root',
})
export class ApplicationVersionService implements OnDestroy {
  // #region -> (service basics)

  /** */
  private readonly LOGGER = new ConsoleLoggerService('ApplicationVersionService', true);

  /** */
  private readonly CONSTANTS = {
    local_storage: {
      last_viewed_version: 'latest_viewed_version',
    },
  };

  /** */
  private readonly LOCAL_HASH_BUILD: string = '{{POST_BUILD_ENTERS_HASH_HERE}}';

  constructor(
    @Inject(ENV) public env: IEnvironment,

    private readonly _router: Router,
    private readonly _httpClient: HttpClient,
    private readonly _matSnackbar: MatSnackBar,
    private readonly _appStateService: AppStateService,
    private readonly _beeguardAuthService: BeeguardAuthService
  ) {}

  ngOnDestroy(): void {
    this._app_version_snackbar_ref?.dismiss();
    this._app_version_watcher_sub?.unsubscribe();
  }

  /** */
  public initialize(): void {
    this.watch_application_version();
    this.check_first_login_or_new_update();
  }

  // #endregion

  // #region -> (watch application version)

  /** */
  private _app_version_watcher_sub: Subscription = null;

  /** */
  private _app_version_snackbar_ref: MatSnackBarRef<any> = null;

  /**
   * Watch a possible new application's version.
   *
   * @private
   */
  private watch_application_version(): void {
    const is_production = this.env?.production;
    const is_watching = !isNil(this._app_version_watcher_sub);
    const is_dev_env = ['dev'].includes(this.env?.env);

    if (!is_production || is_dev_env || is_watching) {
      return;
    }

    this._app_version_watcher_sub = timer(0, 1000 * 60 * 10)
      .pipe(
        switchMap(() => this.fetch_current_app_version()),
        catchError(() => of(null))
      )
      .subscribe({
        next: distant_version => {
          if (isNil(distant_version)) {
            return;
          }

          const local_version = parse_version(this.env?.version);

          this.LOGGER.info('Application version checked :');
          if (console.table) {
            console.table({
              hash: {
                local: this.LOCAL_HASH_BUILD,
                server: distant_version?.hash,
              },
              full_version: {
                local: this.env?.version,
                server: distant_version?.version,
              },
              parsed_version_for_check: {
                local: local_version,
                server: parse_version(distant_version?.version),
              },
            });
          } else {
            this.LOGGER.info(`-- SERVER -- HASH:${distant_version?.hash} / VERSION:${parse_version(distant_version?.version)}`);
            this.LOGGER.info(`-- LOCAL  -- HASH:${this.LOCAL_HASH_BUILD} / VERSION:${local_version}`);
          }

          const has_same_version = isEqual(parse_version(distant_version.version), local_version);
          const has_same_hash = isEqual(distant_version.hash, this.LOCAL_HASH_BUILD);

          if (!has_same_version || !has_same_hash) {
            this.alert_new_version();
          }
        },
        error: (error: unknown) => this.LOGGER.error(error),
      });
  }

  /** */
  private fetch_current_app_version(): Observable<ApplicationVersionFile> {
    return this._httpClient.get<ApplicationVersionFile>(`${location?.origin}/version.json?t=${new Date().getTime()}`);
  }

  /** */
  private alert_new_version(): void {
    if (!isNil(this._app_version_snackbar_ref)) {
      return;
    }

    // Displays the beta update snackbar
    this._app_version_snackbar_ref = this._matSnackbar.openFromComponent(AppUpdateSnackComponent, {
      duration: 0,
      panelClass: 'app-version-snackbar',
    });

    // Handle after user action
    this._app_version_snackbar_ref.afterDismissed().subscribe({
      next: () => {
        this._app_version_snackbar_ref = null;
      },
    });
  }

  // #endregion

  // #region -> (check first login or new update)

  /** */
  private check_first_login_or_new_update(): void {
    const is_production = this.env?.production;
    const is_dev_env = ['dev'].includes(this.env?.env);

    if (!is_production || is_dev_env) {
      return;
    }

    this._beeguardAuthService.wait_is_authorized$$.pipe(debounceTime(500), take(1)).subscribe({
      next: () => {
        const is_first_login: boolean = JSON.parse(localStorage?.getItem('welcome_dismiss_v2')?.toLowerCase() ?? 'false') === false;
        const local_version = parse_version(this.env?.version);

        if (this.env.show_welcome && is_first_login) {
          this._router.navigate([{ outlets: { modal: 'welcome' } }], { queryParamsHandling: 'preserve' });
          localStorage.setItem(this.CONSTANTS.local_storage.last_viewed_version, local_version);

          return;
        }

        const is_current_lang_compatible = ['fr', 'it'].includes(this._appStateService.lang);
        const has_local_version = !isNil(local_version);

        if (!is_current_lang_compatible || !has_local_version) {
          return;
        }

        const last_viewed_version = localStorage.getItem(this.CONSTANTS.local_storage.last_viewed_version) ?? null;

        if (isEqual(local_version, last_viewed_version)) {
          return;
        }

        this._router.navigate(['', { outlets: { modal: ['user_manual', { help: `release/${local_version}.html` }] } }]).then(() => {
          localStorage.setItem(this.CONSTANTS.local_storage.last_viewed_version, local_version);
        });
      },
    });
  }

  // #endregion

  /** */
  public onChunkFailed(): void {
    this.alert_new_version();
  }
}
