import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';

import { TranslateModule, TranslateParser } from '@ngx-translate/core';

import { BgTranslateParser } from 'app/shared-module/translate.parser';

import { MiscModule } from 'app/misc/misc.module';
import { EventsSharedModule } from 'app/views/events/events-shared.module';
import { HivesSharedModule } from 'app/views/hives/shared/hives-shared.module';
import { MaterialSharedModule } from 'app/shared-module/material-shared.module';
import { MiscWidgetsModule } from 'app/widgets/misc-widgets/misc-widgets.module';
import { DevicesSharedModule } from 'app/views/devices/shared/devices-shared.module';
import { WidgetsReusableModule } from 'app/widgets/widgets-reusables/widgets-reusables.module';

import { SelectExploitationComponent } from './select-exploitation/select-exploitation.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    TranslateModule.forChild({
      parser: { provide: TranslateParser, useClass: BgTranslateParser },
    }),
    MiscModule,
    HivesSharedModule,
    MiscWidgetsModule,
    EventsSharedModule,
    DevicesSharedModule,
    MaterialSharedModule,
    WidgetsReusableModule,
  ],
  declarations: [SelectExploitationComponent],
  exports: [SelectExploitationComponent],
})
export class ExploitationsSharedModule {}
