import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  OnDestroy,
  Renderer2,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';

import { ArrowModifier } from '@popperjs/core/lib/modifiers/arrow';
import { OffsetModifier } from '@popperjs/core/lib/modifiers/offset';
import { createPopper as Popper, Options, Instance } from '@popperjs/core';
import { PreventOverflowModifier } from '@popperjs/core/lib/modifiers/preventOverflow';

import { TooltipOptions } from '../interfaces/tooltip-options.model';
import { TooltipTriggerMethod } from '../enums/tooltip-trigger-method';
import { TooltipPlacement } from '../enums/tooltip-placement';

@Component({
  selector: 'popper-content',
  templateUrl: './tooltip-content.component.html',
  styleUrls: ['./tooltip-content.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TooltipContentComponent implements OnDestroy {
  static nextId: number = 0;

  ariaHidden: string = 'true';
  arrowColor: string | null = null;
  displayType: string = 'none';
  id: string = `ngx_poppperjs_${++TooltipContentComponent.nextId}`;
  isMouseOver: boolean = false;
  onHidden = new EventEmitter();
  onUpdate: () => any;
  opacity: number = 0;
  popperInstance: Instance;
  popperOptions: TooltipOptions = {
    disableAnimation: false,
    disableDefaultStyling: false,
    placement: TooltipPlacement.AUTO,
    boundariesElement: '',
    trigger: TooltipTriggerMethod.hover,
    positionFixed: false,
    appendToBody: false,
    popperModifiers: [],
  } as TooltipOptions;
  @ViewChild('popperViewRef', { static: !0 })
  popperViewRef: ElementRef;
  referenceObject: HTMLElement;
  state: boolean = true;
  text: string;

  protected _globalResize: any;
  protected _styleId = `${this.id}_style`;

  // #region -> (component basics)

  constructor(
    public elRef: ElementRef,
    protected _renderer: Renderer2,
    protected _viewRef: ViewContainerRef,
    protected _changeDetectorRef: ChangeDetectorRef
  ) {}

  // #endregion

  clean() {
    this.toggleVisibility(false);
    if (!this.popperInstance) {
      return;
    }
    this.popperInstance.destroy();
  }

  extractAppliedClassListExpr(classList: string | string[] = []): object {
    const klass_list = Array.isArray(classList) ? classList : typeof classList === typeof '' ? classList.replace(/ /, '').split(',') : [];

    return klass_list.reduce((acc, klass) => {
      (acc as any)[klass] = !0;

      return acc;
    }, {});
  }

  hide(): void {
    if (this.popperInstance) {
      this.popperInstance.destroy();
    }
    this.toggleVisibility(false);
    this.onHidden.emit();
  }

  ngOnDestroy() {
    this.clean();
    if (this.popperOptions.appendTo && this.elRef && this.elRef.nativeElement && this.elRef.nativeElement.parentNode) {
      this._viewRef.detach();
      this.elRef.nativeElement.parentNode.removeChild(this.elRef.nativeElement);
    }
  }

  onDocumentResize() {
    this.update();
  }

  @HostListener('mouseover')
  onMouseOver() {
    this.isMouseOver = true;
  }

  show(): void {
    if (!this.referenceObject) {
      return;
    }

    const append_to_parent = this.popperOptions.appendTo && document.querySelector(this.popperOptions.appendTo);
    if (append_to_parent && this.elRef.nativeElement.parentNode !== append_to_parent) {
      this.elRef.nativeElement.parentNode && this.elRef.nativeElement.parentNode.removeChild(this.elRef.nativeElement);
      append_to_parent.appendChild(this.elRef.nativeElement);
    }

    const popper_options: Options = {
      strategy: this.popperOptions.positionFixed ? 'fixed' : 'absolute',
      placement: this.popperOptions.placement,
      modifiers: [
        {
          name: 'offset',
          enabled: !0,
          options: {
            offset: [0, 8],
          },
        } as OffsetModifier,
        {
          name: 'arrow',
          enabled: !0,
          options: {
            element: '.ngxp__arrow',
            padding: 3,
          },
          requires: ['arrow'],
        } as ArrowModifier,
      ],
    } as Options;

    if (this.onUpdate) {
      popper_options.onFirstUpdate = this.onUpdate as any;
    }

    const boundaries_element = this.popperOptions.boundariesElement && document.querySelector(this.popperOptions.boundariesElement);

    if (popper_options.modifiers && boundaries_element) {
      popper_options.modifiers.push({
        name: 'preventOverflow',
        enabled: this.popperOptions.preventOverflow,
        options: {
          boundary: boundaries_element,
        },
      } as PreventOverflowModifier);
    }

    if (popper_options.modifiers) {
      const prevent_overflow_modifier = popper_options.modifiers.find(v => v.name === 'preventOverflow');
      if (prevent_overflow_modifier && !prevent_overflow_modifier.enabled) {
        const hide_modifier = popper_options.modifiers.find(v => v.name === 'preventOverflow');
        hide_modifier && (hide_modifier.enabled = !1);
      }
    }

    this._determineArrowColor();
    popper_options.modifiers = popper_options.modifiers.concat(this.popperOptions.popperModifiers);

    this.popperInstance = Popper(this.referenceObject, this.popperViewRef.nativeElement, popper_options);

    this.toggleVisibility(true);
    this._globalResize = this._renderer.listen('document', 'resize', this.onDocumentResize.bind(this));
  }

  @HostListener('mouseleave')
  showOnLeave() {
    this.isMouseOver = false;
    if (this.popperOptions.trigger !== TooltipTriggerMethod.hover && !this.popperOptions.hideOnMouseLeave) {
      return;
    }
    this.hide();
  }

  private toggleVisibility(state: boolean) {
    if (!state) {
      this._renderer.setStyle(this.elRef.nativeElement, 'display', 'none');

      this.opacity = 0;
      this.displayType = 'none';
      this.ariaHidden = 'true';
      this.state = false;
    } else {
      this._renderer.setStyle(this.elRef.nativeElement, 'display', 'block');

      this.opacity = 1;
      this.displayType = 'block';
      this.ariaHidden = 'false';
      this.state = true;
    }

    if (!(this._changeDetectorRef as any)['destroyed']) {
      this._changeDetectorRef.detectChanges();
    }
  }

  update(): void {
    this.popperInstance && (this.popperInstance as any).update();
  }

  protected _createArrowSelector(): string {
    return `div#${this.id}.ngxp__container > .ngxp__arrow.ngxp__force-arrow`;
  }

  protected _determineArrowColor() {
    if (!this.popperOptions.styles || this.arrowColor) {
      return !1;
    }

    const rule_value = this.popperOptions.styles['background-color'] || this.popperOptions.styles.backgroundColor;
    if (this.arrowColor === rule_value) {
      return !1;
    }

    this.arrowColor = rule_value;
    let $style = document.querySelector(`#${this._styleId}`) as HTMLStyleElement;
    const style_content = this.arrowColor ? `${this._createArrowSelector()}:before { background-color: ${this.arrowColor}; }` : '';

    if (!$style) {
      $style = document.createElement('style') as HTMLStyleElement;
      $style.id = this._styleId;
      $style.setAttribute('type', 'text/css');
      document.head.appendChild($style);
    }

    if (($style as any)['styleSheet']) {
      ($style as any)['styleSheet'].cssText = style_content;
    } else {
      $style.innerHTML = style_content;
    }
  }
}
