<div class="bg2fastvisit-widget">
  <form-element-header
    [schema]="schema"
    [hide_description]="true"
    [additionnal_text]=" 'VIEWS.MODALS.VISIT.[nbh_seen] hives inspected' | translate:{ nbh_seen:(nbh_after_visit$$ | async) }"
  ></form-element-header>

  <ng-container *ngIf="state$$ | async as state;">
    <div *ngIf="state === VISIT_STATE.READY" class="visit-desc">
      <img *ngIf="options.img" class="picto" [src]="'assets/' + options.img" />
      <p>
        <span>{{ "VIEWS.MODALS.VISIT.You will start a quick visit to evaluate" | translate }}</span>
        <ng-container
          *ngIf="{
            eval_type: evaluation_type$$ | async,
            nbhn: nb_elements_hive_or_nuc$$ | async
          } as ctx_desc"
        >
          <ng-container *ngIf="ctx_desc.nbhn">
            <span *ngIf="ctx_desc.eval_type.hive && !ctx_desc.eval_type.nuc">
              {{ "VIEWS.MODALS.VISIT.the [nbhn] apairy's hives" | translate:{ nbhn:ctx_desc.nbhn } }}
            </span>
            <span *ngIf="!ctx_desc.eval_type.hive && ctx_desc.eval_type.nuc">
              {{ "VIEWS.MODALS.VISIT.the [nbhn] apairy's nuc hives" | translate:{ nbhn:ctx_desc.nbhn } }}
            </span>
            <span *ngIf="ctx_desc.eval_type.hive && ctx_desc.eval_type.nuc">
              {{ "VIEWS.MODALS.VISIT.the [nbhn] apairy's hives and nuc hives" | translate:{ nbhn:ctx_desc.nbhn } }}
            </span>
          </ng-container>
          <ng-container *ngIf="!ctx_desc.nbhn">
            <span *ngIf="ctx_desc.eval_type.hive && !ctx_desc.eval_type.nuc"> {{ "VIEWS.MODALS.VISIT.apairy's hives" | translate }} </span>
            <span *ngIf="!ctx_desc.eval_type.hive && ctx_desc.eval_type.nuc">
              {{ "VIEWS.MODALS.VISIT.apairy's nuc hives" | translate }}
            </span>
            <span *ngIf="ctx_desc.eval_type.hive && ctx_desc.eval_type.nuc">
              {{ "VIEWS.MODALS.VISIT.apairy's hives and nuc hives" | translate }}
            </span>
          </ng-container>
        </ng-container>
      </p>
      <span>{{ 'VIEWS.MODALS.VISIT.For each you have to indicate :' | translate }}</span>
      <ul class="fields-list">
        <li *ngFor="let field of fields_order">
          <img *ngIf="fields_config[field]?.img" [src]="'assets/' + fields_config[field]?.img" />
          <span>{{ fields_config[field]?.label | translate }}</span>
        </li>
      </ul>
    </div>

    <div *ngIf="state === VISIT_STATE.DONE" class="visit-summary">
      <h5>{{'VIEWS.MODALS.VISIT.Visit summary' | translate}}</h5>

      <ng-container *ngIf="visit_results$$ | async as result;">
        <ng-container *ngFor="let field of fields_order">
          <div class="visit-summary-row" *ngIf="result[field]">
            <p class="field">{{ fields_config[field].label | translate }}</p>
            <ul class="visit-summary-list">
              <ng-container *ngFor="let fconf of fields_config[field].values">
                <li *ngIf="result[field][fconf.value]">
                  <div class="icon">
                    <img *ngIf="fconf.img" [src]="'assets/' + fconf.img" />
                    <span>{{ fconf.label | translate }}</span>
                  </div>
                  <span class="value">{{ result[field][fconf.value] }}</span>
                </li>
              </ng-container>
            </ul>
          </div>
        </ng-container>
      </ng-container>
    </div>

    <ng-container
      *ngIf="{
        nbh_after_visit: nbh_after_visit$$ | async,
        nbhn: nb_elements_hive_or_nuc$$ | async
      } as alert_ctx;"
    >
      <div class="alert" *ngIf="alert_ctx.nbhn >= 0 && alert_ctx.nbh_after_visit && alert_ctx.nbh_after_visit !== alert_ctx.nbhn">
        <div class="msg">
          <i class="mdi mdi-alert-outline mdi-48px"></i>
          <span *ngIf="alert_ctx.nbh_after_visit < alert_ctx.nbhn">
            {{ "VIEWS.MODALS.VISIT.You have count less hives ([nbh_after_visit]) than indicated in the apiary ([nbh])" | translate:{
            nbh:alert_ctx.nbhn, nbh_after_visit:alert_ctx.nbh_after_visit } }}
          </span>
          <span *ngIf="alert_ctx.nbh_after_visit > alert_ctx.nbhn">
            {{ "VIEWS.MODALS.VISIT.You have more hives ([nbh_after_visit]) than indicated in the apiary ([nbh])" | translate:{
            nbh:alert_ctx.nbhn, nbh_after_visit:alert_ctx.nbh_after_visit } }}
          </span>
        </div>

        <bg2-button
          [type]="'delete'"
          [icon]="'mdi-code-greater-than'"
          [message]="'VIEWS.MODALS.VISIT.Modify apiary hive number' | translate"
          (btn_click)="changeHiveNumber(alert_ctx.nbh_after_visit)"
        >
        </bg2-button>
      </div>
    </ng-container>

    <ng-container *ngIf="warning$$ | async as warning">
      <div class="warning-msg" *ngIf="warning.show">
        <i class="mdi mdi-alert-outline mdi-48px" style="color: orange"></i>
        <span>{{ warning.label | translate }}</span>
      </div>
    </ng-container>

    <div class="actions">
      <bg2-button
        *ngIf="state === VISIT_STATE.READY"
        [type]="'action'"
        [color]="'breeding-register'"
        [disabled]="loading$$ | async"
        [icon]="'mdi-code-greater-than'"
        [message]="'VIEWS.MODALS.VISIT.Start fast hive visit' | translate"
        (btn_click)="startVisit()"
      >
      </bg2-button>

      <bg2-button
        *ngIf="state === VISIT_STATE.DONE"
        [type]="'delete'"
        [icon]="'mdi-delete-forever'"
        [message]="'VIEWS.MODALS.VISIT.RAZ visit result' | translate"
        (btn_click)="clearVisit()"
      >
      </bg2-button>

      <bg2-button
        *ngIf="state === VISIT_STATE.DONE && (visit_history$$ | async).length"
        [type]="'action'"
        [color]="'breeding-register'"
        [disabled]="loading$$ | async"
        [icon]="'mdi-code-greater-than'"
        [message]="'VIEWS.MODALS.VISIT.Continue fast hive visit' | translate"
        (btn_click)="startVisit()"
      >
      </bg2-button>
    </div>

    <sf-form-element [formProperty]="formProperty.getProperty('comment')"></sf-form-element>
  </ng-container>
</div>
