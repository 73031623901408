import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { ZohoAuthService } from 'app/core/services/zoho/zoho-auth.service';
import { SimpleSetterGetter } from 'app/models';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'bg2-zoho-manager-container',
  templateUrl: './zoho-manager-container.component.html',
  styleUrls: ['./zoho-manager-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ZohoManagerContainerComponent {
  // #region -> (component basics)

  constructor(private readonly _zohoAuthService: ZohoAuthService) {}

  // #endregion

  @Input()
  public require_attention_for = false;

  // #region -> (authorization)

  /** */
  public is_authenticated$$ = this._zohoAuthService.is_authenticated$$;

  /** */
  public login(): void {
    this._zohoAuthService.login();
  }

  // #endregion

  // #region -> (zoho block title)

  @Input()
  public title: string = null;

  // #endregion

  // #region -> (manual check reload)

  /** */
  private _last_zoho_fetch_state = false;
  /** */

  protected run_zoho_fetch() {
    this.refetch_zoho_data.emit(!this._last_zoho_fetch_state);
    this._last_zoho_fetch_state = !this._last_zoho_fetch_state;
  }

  @Output()
  public refetch_zoho_data = new EventEmitter<boolean>();

  // #endregion
}
