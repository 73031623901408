import { Location, PopStateEvent } from '@angular/common';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { Component, OnInit, OnDestroy, HostListener, Type } from '@angular/core';

import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';

import { Subscription, SubscriptionLike } from 'rxjs';

import { clone, cloneDeep, isEqual, mapValues, omit, isNil, toString, has, forOwn } from 'lodash-es';

import { DialogsService } from './widgets/dialogs-modals';
import { AbstractModalComponent } from './widgets/dialogs-modals';
import { ModalsService } from './widgets/dialogs-modals/modals.service';
import { ConsoleLoggerService } from './core/console-logger.service';

import {
  ApiaryHiveListModal,
  ApiaryEvaluationModal,
  MigratoryModal,
  NewEntityModal,
  NewExploitationModal,
  NewEventModal,
  NewIssueModal,
  UpdateEntityModal,
  UpdateEntityACLModal,
  UpdateEventModal,
  WelcomeModalComponent,
  UserManualModal,
} from './routable-modals';

import {
  DeviceModalComponent,
  DeviceConfigModalComponent,
  RouteTracerModal,
  CreateDeviceModalComponent,
  DeviceMovementAuthorizationModalComponent,
  DeviceCalibrationTemperatureModalComponent,
  DeviceSupportUpdateDeskTicketModalComponent,
  DeviceSupportCloseModalComponent,
} from './views/devices/dialogs-and-modals/modals';

import { NewUserModalComponent } from './views/user/dialogs-and-modals/new-user/new-user.modal';
import { UserSettingsModal } from './views/user/dialogs-and-modals/user-settings/user-settings.modal';
import { Bg2GhostConfigModalComponent } from './views/ghost/shared/ghost-config-modal/ghost-config-modal.component';
import { UserAccountModalComponent } from './views/user/dialogs-and-modals/user-account/user-account-modal.component';
import { ZohoCheckModalComponent } from './views/zoho/dialogs-and-modals/zoho-check-modal/zoho-check-modal.component';
import { LocationDetailsModalComponent } from './views/locations/modals/location-details-modal/location-details-modal.component';
import { VisitModalComponent } from './routable-modals/visit-modal/visit-modal.component';
import { ZohoCreateIssueModalComponent } from './views/zoho/dialogs-and-modals/zoho-create-issue-modal/zoho-create-issue-modal.component';

/**
 *  Registry of all available modals
 */
const MODALS_REGITSRY: { [key: string]: Type<AbstractModalComponent> } = {
  new_event: NewEventModal,
  new_issue: NewIssueModal,
  migratory: MigratoryModal,
  new_entity: NewEntityModal,
  user_manual: UserManualModal,
  new_expl: NewExploitationModal,
  update_event: UpdateEventModal,
  welcome: WelcomeModalComponent,
  route_tracer: RouteTracerModal,
  update_entity: UpdateEntityModal,
  user_settings: UserSettingsModal,
  user_account: UserAccountModalComponent,
  apiary_hive_list: ApiaryHiveListModal,
  update_entity_acl: UpdateEntityACLModal,
  apiary_evaluation: ApiaryEvaluationModal,
  ghost_config: Bg2GhostConfigModalComponent,
  location_details: LocationDetailsModalComponent,
  evaluation: ApiaryEvaluationModal,
  visit: VisitModalComponent,
  new_user: NewUserModalComponent,

  // Device modals
  device: DeviceModalComponent,
  create_device: CreateDeviceModalComponent,
  device_config: DeviceConfigModalComponent,
  device_move_auth: DeviceMovementAuthorizationModalComponent,
  device_calib_temp: DeviceCalibrationTemperatureModalComponent,
  device_support_udtc: DeviceSupportUpdateDeskTicketModalComponent,
  device_support_end_support: DeviceSupportCloseModalComponent,

  // Zoho modals
  zoho_check: ZohoCheckModalComponent,
  zoho_create_issue: ZohoCreateIssueModalComponent,
};

@AutoUnsubscribe()
@Component({
  selector: 'app-modals-manager',
  template: '',
  styleUrls: ['./app.modals-manager.component.scss'],
})
export class ModalsManagerComponent implements OnInit, OnDestroy {
  private readonly _logger = new ConsoleLoggerService(this.constructor.name, false);

  protected param_sub: Subscription;
  protected location_sub: SubscriptionLike;
  protected current_modal_sub: Subscription;

  private internal_change = false; // Indicate that next route change is due to internal "navigate"
  private history_back = true; // Indicate that next route change is due to "back" from browser
  // ^ true for first as first current modal is always null

  private history_back_already_close = false; // Indicate that the location changes cames from internal

  constructor(
    private dialog: DialogsService,
    private route: ActivatedRoute,
    private router: Router,
    private location: Location,
    private mms: ModalsService
  ) {}

  ngOnInit(): void {
    this.current_modal_sub = this.mms.current_modal$$.subscribe(current_modal => {
      this._logger.debug('New current : ', current_modal, this.history_back);
      if (!this.history_back) {
        // If this back do NOT came from browser history
        //// Commented section: url change by a history "back"
        //// ==> this do not work with iframe as history can contains many "steps" whitin same modal
        // this.history_back_already_close = true;
        // ^ indicate that modal is already close
        // console.log('<= history go back');
        // this.location.back();

        const back_modal_type = clone(current_modal?.type);
        const replace = current_modal?.replace || false;
        const back_params = cloneDeep(current_modal?.params);
        this._logger.debug('=> Change url for: ', current_modal);
        this.changeUrl(back_modal_type, back_params, replace);
        // ^ do back navigate in browser history
      } else {
        // BACK from browser history, so we do not change browser history
      }
      this.history_back = false;
    });

    this.location_sub = this.location.subscribe((event: PopStateEvent) => {
      if (!event.pop) {
        return;
      }

      if (this.history_back_already_close) {
        // console.log('[back from internal, modal already close]');
        this.history_back_already_close = false;
        return;
      }

      this._logger.debug('<= browser BACK, close modal', event);
      let modal_params = '';
      forOwn(this.mms.current_params, (value, key) => modal_params += `${key}=${value}`);
      if (modal_params) {
        modal_params = `;${modal_params}`
      }
      const modal_url = `(modal:${this.mms.current_type}${modal_params})`
      this._logger.debug("current modal_url", modal_url);
      if (event.url.includes(modal_url)) {
        // Note: this is important on "internal" navigation within a modal
        // this is used in device modal, when click browser back from one "detail" page
        this._logger.warn('Same modal open, internal navigation')
      } else if (this.mms.canDestroyLastModal()) {
        this.history_back = true;
        // this.internal_change = true; /// XXX a voir ?
        this.mms.tryCloseLastModal(false);
      } else {
        this.mms.setBackClicked();
      }
    });

    this.param_sub = this.route.params.subscribe((params: Params) => {
      const modal_params = omit(params, ['modalType']);
      const modal_type = params.modalType;
      this._logger.debug('[route changed] internal:', this.internal_change);
      if (this.internal_change) {
        // If "internal change" but the required page do not fit the actual modal => load it!
        // Note: this arrive when going back/forward on a newly relaoded page
        // historic is not present here
        // this._logger.debug(this.mms.current_type !== modal_type, this.mms.current_type, modal_type);
        // this._logger.debug(this.mms.current_params !== modal_params, this.mms.current_params, modal_params);
        if (!isEqual(this.mms.current_params, modal_params) || !isEqual(this.mms.current_type, modal_type)) {
          this.internal_change = false;
        }
      }
      if (!this.internal_change) {
        // this._logger.debug('so open', modal_type, modal_params);
        setTimeout(() => this.open(modal_type, modal_params), 10);
      }
      this.internal_change = false;
    });
  }

  ngOnDestroy(): void {
    this.param_sub?.unsubscribe();
    this.location_sub?.unsubscribe();
  }

  // @HostListener('document:keydown', ['$event'])
  // keypress(event: KeyboardEvent): boolean {
  //   const ESCAPE_KEYCODE = 27;
  //   if (event.code === 'Escape' && !this.dialog.dialog_opened) {
  //     event.stopPropagation();
  //     event.preventDefault();
  //     event.stopImmediatePropagation();
  //     // this._logger.debug('<= ESC pressed, close modal');
  //     this.mms.tryCloseLastModal();
  //     return false;
  //   }
  // }

  public changeParams(params: Params): void {
    // console.log('changeParams', params);
    params = mapValues(params, val => toString(val));
    this.mms.changeCurrentParams(params);
    // this.changeUrl(this.mms.current_type, this.mms.current_params, true);
  }

  private changeUrl(mtype: any, params: any, replace = false): void {
    const modal = isNil(mtype) ? null : [mtype, params];
    this._logger.debug('Change url : ', modal);
    this.internal_change = true;
    this.router.navigate(
      [
        '',
        {
          outlets: { modal },
        },
      ],
      {
        queryParamsHandling: 'preserve',
        replaceUrl: replace,
      }
    );
  }

  // Open new model
  protected open(modal_type: any, params: any): void {
    this._logger.debug(`Opening modal ${modal_type} with params: `, params);

    // Type transforms of some specfic params
    if (has(params, 'rmoc') && params.rmoc === 'false') {
      params.rmoc = false;
    }
    if (has(params, 'oh') && params.rmoc === 'false') {
      params.oh = false;
    }

    if (modal_type === 'raz') {
      if (this.mms.nb_opened_modals === 0) {
        setTimeout(() => this.changeUrl(null, null, true), 10);
      } else {
        this.mms.tryDestroyLastModal(true); // TODO call raz
      }
    } else {
      if (!(modal_type in MODALS_REGITSRY)) {
        const error_exclusion_list = ['error_unauthorized'];

        if (!error_exclusion_list.includes(modal_type)) {
          this._logger.error(`Unknow event form type: ${modal_type}`);
        }
      } else {
        const modal_class = MODALS_REGITSRY[modal_type];
        this.mms.newModal(modal_class, modal_type, params);
      }
    }
  }
}
