<mat-sidenav-container
  #matSidenavContainer
  class="bgapp-sidenav-container"
  [hasBackdrop]="screen_size < 1200"
  fullscreen
  [attr.data-version]="version"
>
  <!-- #region (navigation sidenav) -->
  <mat-sidenav class="bgapp-sidenav" [mode]="'over'" [position]="'start'" [class.fix-runs]="is_runs_displayed" #navigationSidenav>
    <div id="navigation-sidenav" class="bg-sidenav">
      <span class="mdi mdi-close" (click)="navigationSidenav.close()"></span>

      <!-- #region (sidenav header) -->
      <div class="bg-sidenav-header">
        <img [src]="logo" alt="BeeGuard" class="env-{{ appState.env.env }}" />
      </div>
      <!-- #endregion (sidenav header) -->

      <!-- #region (sidenav content) -->
      <div class="bg-sidenav-content">
        <ng-container *ngIf="oAuthService.canLogin()">
          <div class="item" [class.active]="isLinkActive('/login')">
            <a [routerLink]="['/login']">Login</a>
          </div>
        </ng-container>

        <ng-container *ngIf="!((appState.user$$ | async) || oAuthService.canLogin())">
          <div class="item">
            <span translate>ALL.COMMON.Loading</span>
          </div>
        </ng-container>

        <ng-container *ngIf="appState?.user$$ | async as user">
          <div class="item" [class.active]="isLinkActive('/exploitations')">
            <a [routerLink]="['/exploitations']" *ngIf="appState?.more_than_one_expl$$ | async" translate>ALL.MENU.My exploitations</a>
            <a
              [routerLink]="['/exploitation', appState?.all_exploitations[0].id]"
              *ngIf="(appState?.more_than_one_expl$$ | async) === false && appState?.all_exploitations[0]"
            >
              {{ 'ALL.MENU.My exploitation' | translate }}
            </a>
          </div>
          <div class="item" [class.active]="isLinkActive('/locations')">
            <a [routerLink]="['/locations']" translate>ALL.MENU.Apiaries</a>
          </div>
          <div class="item" [class.active]="isLinkActive('/hives')">
            <a [routerLink]="['/hives']" translate>ALL.MENU.Hives</a>
          </div>
          <div class="item" [class.active]="isLinkActive('/devices')">
            <a [routerLink]="['/devices']" translate>ALL.MENU.devices</a>
          </div>
          <div class="item" [class.active]="isLinkActive('/data/location')">
            <a [routerLink]="['/data/location']" translate>ALL.MENU.Data</a>
          </div>
          <div class="item" [class.active]="isLinkActive('/users')">
            <a [routerLink]="['users']">
              {{ 'ALL.MENU.My users' | translate }}
            </a>
          </div>
        </ng-container>

        <mat-divider></mat-divider>

        <mat-accordion>
          <mat-expansion-panel>
            <!-- .panel header -->
            <mat-expansion-panel-header>
              <mat-panel-title>
                <div class="header" *ngIf="appState?.user$$ | async as user" [class.impersonate]="appState.is_impersonate_actif$$ | async">
                  <i
                    *ngLet="user?.is_superadmin$$ | async; let is_superadmin"
                    class="mdi mdi-24px"
                    [ngClass]="{ 'mdi-account': !is_superadmin, 'mdi-crown': is_superadmin }"
                    [ngStyle]="{ color: is_superadmin ? 'goldenrod' : 'inherit' }"
                  ></i>

                  {{ user?.username }}
                </div>
              </mat-panel-title>
            </mat-expansion-panel-header>
            <!-- .panel-header -->

            <!-- .panel-content -->
            <div class="content">
              <div *ngIf="appState.is_real_user_superadmin$$ | async" class="item only-admin">
                <a href="javascript:void(0)">
                  <i class="mdi mdi-incognito mdi-24px"></i>
                  <menu-impersonate></menu-impersonate>
                </a>
              </div>
              <div class="item" *ngIf="appState.is_impersonate_actif$$ | async">
                <a (click)="oAuthService.impersonate = null">
                  <i class="mdi mdi-account-switch mdi-24px"></i>
                  {{ 'ALL.MENU.Back superadmin' | translate }}
                </a>
              </div>

              <div class="item" *ngIf="appState?.user$$ | async as user">
                <a
                  [routerLink]="['', { outlets: { modal: ['user_account', { user_id: user?.user_id$$ | async }] } }]"
                  [queryParamsHandling]="'preserve'"
                >
                  <i
                    *ngLet="user?.is_superadmin$$ | async; let is_superadmin"
                    class="mdi mdi-24px"
                    [ngClass]="{ 'mdi-account': !is_superadmin, 'mdi-crown': is_superadmin }"
                    [ngStyle]="{ color: is_superadmin ? 'goldenrod' : 'inherit' }"
                  ></i>
                  {{ 'ALL.MENU.My account' | translate }}
                </a>
              </div>

              <div class="item" *ngIf="appState?.user$$ | async as user">
                <a
                  [routerLink]="['', { outlets: { modal: ['user_settings', { user_id: user?.user_id$$ | async }] } }]"
                  [queryParamsHandling]="'preserve'"
                >
                  <i class="mdi mdi-cog mdi-24px"></i>
                  {{ 'ALL.MENU.User settings' | translate }}
                </a>
              </div>

              <div class="item">
                <a (click)="oAuthService.logout()">
                  <i class="mdi mdi-logout mdi-24px"></i>
                  {{ 'ALL.MENU.Logout' | translate }}
                </a>
              </div>
            </div>
            <!-- .panel-content -->
          </mat-expansion-panel>
        </mat-accordion>

        <mat-divider></mat-divider>

        <div class="item beta" [class.active]="isLinkActive('/events')">
          <a [routerLink]="['/events']">
            <i class="mdi mdi-calendar-month mdi-24px"></i>
            {{ 'ALL.MENU.Events' | translate }}
          </a>
        </div>

        <div class="item">
          <a [routerLink]="['', { outlets: { modal: ['welcome'] } }]" [queryParamsHandling]="'preserve'">
            <i class="mdi mdi-presentation mdi-24px"></i>
            {{ 'ALL.MENU.Presentation' | translate }}
          </a>
        </div>

        <div class="item" *ngIf="(appState.lang$$ | async) === 'fr'">
          <a [routerLink]="['', { outlets: { modal: ['user_manual', { help: 'app_timeline.html' }] } }]" [queryParamsHandling]="'preserve'">
            <i class="mdi mdi-new-box mdi-24px"></i>
            {{ 'ALL.MENU.Latest news' | translate }}
          </a>
        </div>

        <div class="item" *ngIf="(appState.lang$$ | async) === 'fr'">
          <a [routerLink]="['', { outlets: { modal: ['user_manual', { help: '' }] } }]" [queryParamsHandling]="'preserve'">
            <i class="mdi mdi-help-circle mdi-24px"></i>
            {{ 'ALL.MENU.User manual' | translate }}
          </a>
        </div>

        <div *ngIf="betaAppService.is_current_build_is_beta$$ | async" class="item">
          <a (click)="betaAppService.quit_beta_application()">
            <i class="mdi mdi-flask mdi-24px"></i>
            {{ 'ALL.MENU.Quit BETA version' | translate }}
          </a>
        </div>

        <div *ngIf="(betaAppService.is_current_build_is_beta$$ | async) === false" class="item">
          <a (click)="betaAppService.use_beta_application()">
            <i class="mdi mdi-flask mdi-24px"></i>
            {{ 'ALL.MENU.Try BETA version' | translate }}
          </a>
        </div>

        <div class="item only-admin" *ngIf="appState.is_superadmin$$ | async">
          <a target="blank" href="https://infra-grafana.beeguard.direct/d/pS6ZuGV7z/prometheus-blackbox-exporter?orgId=1">
            <i class="mdi mdi-server-network mdi-24px"></i>
            {{ 'ALL.MENU.Server status' | translate }}
          </a>
        </div>

        <div class="item" *ngIf="appState.config.contact_form.in_menu">
          <a [routerLink]="['', { outlets: { modal: ['new_issue'] } }]" [queryParamsHandling]="'preserve'">
            <i class="mdi mdi-email mdi-24px"></i>
            {{ 'ALL.MENU.Contact' | translate }}
          </a>
        </div>
      </div>
      <!-- #endregion (sidenav content) -->
    </div>
  </mat-sidenav>
  <!-- #endregion (navigation sidenav) -->

  <!-- #region (tools sidenav) -->
  <mat-sidenav
    class="bgapp-sidenav"
    [mode]="screen_size >= 1200 ? 'side' : 'over'"
    [position]="'end'"
    [class.fix-runs]="is_runs_displayed"
    #toolsSidenav
  >
    <div id="tools-sidenav" class="bg-sidenav">
      <div class="close-handle" (click)="tools_sidenav.close()">
        <span class="mdi mdi-chevron-triple-right mdi-18px"></span>
      </div>
      <div class="internal-nav">
        <ng-container #toolsSidenavVC></ng-container>
      </div>
    </div>
  </mat-sidenav>
  <!-- #endregion (tools sidenav) -->

  <!-- #region (website content) -->
  <mat-sidenav-content class="bgapp-sidenav-content" id="app-content-view">
    <!-- #region (website unpaid bill) -->
    <ng-container *ngIf="appState.user$$ | async; let user">
      <div id="app-unpaid-bill" class="alert-unpaid-bill" *ngIf="user?.unpaid_warning$$ | async" #unpaidBanner>
        <div class="description">
          <span>{{ 'ALL.UNPAID.Attention, votre compte présente des impayés' | translate }}</span> <br />
          <span>&nbsp;</span>
          <span>
            <a class="link" [routerLink]="['', { outlets: { modal: ['new_issue'] } }]">{{
              'VIEWS.MODALS.NEW_ISSUE.Contact us' | translate
            }}</a>
            <span> {{ "ALL.UNPAID.de toute urgence pour éviter l'arrêt du service" | translate }}</span>
          </span>
        </div>
      </div>
    </ng-container>
    <!-- #endregion (website unpaid bill) -->

    <ng-container *ngIf="appBackofficeService.show_top_layer_announcement !== 'no'">
      <ng-container *ngIf="top_layer_announcement_config$$ | async as top_layer_announcement_config">
        <ng-container *ngIf="top_layer_announcement_config.enabled ?? false">
          <bg2-top-layer-announcement [top_layer_announcement_config]="top_layer_announcement_config"></bg2-top-layer-announcement>
        </ng-container>
      </ng-container>
    </ng-container>

    <!-- #region (website top navigation) -->
    <div *ngIf="oAuthService.is_authorized$$ | async" id="top-navigation">
      <div id="top-navigation-content" class="app-container" [class.with-sidenav]="toolsSidenav.opened">
        <!-- .brand-logo -->
        <a id="top-navigation-logo" [routerLink]="['/']">
          <img [src]="logo" alt="BeeGuard" class="env-{{ appState.env.env }}" />
        </a>
        <!-- .brand-logo -->

        <!-- .website-navbar -->
        <ul id="website-navbar" class="hide-on-med-and-down">
          <!-- .not-loaded-user -->
          <ng-container *ngIf="{ user: appState.user$$ | async } as context">
            <ng-container *ngIf="!(context.user || oAuthService.canLogin())">
              <li>
                <a>{{ 'ALL.COMMON.Loading' | translate }}</a>
              </li>
            </ng-container>
          </ng-container>
          <!-- .not-loaded-user -->

          <!-- .ready-to-use -->
          <ng-container *ngIf="appState?.user$$ | async as user">
            <!-- .exploitations-page -->
            <li [class.active]="isLinkActive('/exploitations')">
              <a *ngIf="appState?.more_than_one_expl$$ | async" [routerLink]="['/exploitations']" translate>ALL.MENU.My exploitations</a>
              <a
                *ngIf="(appState?.more_than_one_expl$$ | async) === false && appState?.all_exploitations[0]"
                [routerLink]="['/exploitations', appState?.all_exploitations[0].id]"
                translate
                >ALL.MENU.My exploitation</a
              >
            </li>
            <!-- .exploitations-page -->

            <!-- .locations-page -->
            <li [class.active]="isLinkActive('/locations')">
              <a [routerLink]="['/locations']" translate>ALL.MENU.Apiaries</a>
            </li>
            <!-- .locations-page -->

            <!-- .hives-page -->
            <li [class.active]="isLinkActive('/hives')">
              <a [routerLink]="['/hives']" translate>ALL.MENU.Hives</a>
            </li>
            <!-- .hives-page -->

            <!-- .devices-page -->
            <li [class.active]="isLinkActive('/devices')">
              <a [routerLink]="['/devices']" translate>ALL.MENU.devices</a>
            </li>
            <!-- .devices-page -->

            <!-- .data-page -->
            <li [class.active]="isLinkActive('/data')">
              <a [routerLink]="['/data/location']" translate>ALL.MENU.Data</a>
            </li>
            <!-- .data-page -->

            <!-- #region .user-menu -->
            <li class="user-menu" [matMenuTriggerFor]="menu">
              <a href="javascript:void(0)" [class.impersonate]="appState.is_impersonate_actif$$ | async">
                <span
                  *ngLet="user?.is_superadmin$$ | async; let is_superadmin"
                  class="mdi mdi-24px"
                  [ngClass]="{ 'mdi-account': !is_superadmin, 'mdi-crown': is_superadmin }"
                  [ngStyle]="{ color: is_superadmin ? 'goldenrod' : 'inherit' }"
                ></span>
                {{ user?.name$$ | async }}
              </a>

              <mat-menu #menu="matMenu" xPosition="before">
                <div class="navigation-menu">
                  <div mat-menu-item>
                    <span
                      [queryParamsHandling]="'preserve'"
                      [routerLink]="['', { outlets: { modal: ['user_account', { user_id: user?.user_id$$ | async }] } }]"
                    >
                      <span
                        *ngLet="user?.is_superadmin$$ | async; let is_superadmin"
                        class="mdi mdi-24px"
                        [ngClass]="{ 'mdi-account': !is_superadmin, 'mdi-crown': is_superadmin }"
                        [ngStyle]="{ color: is_superadmin ? 'goldenrod' : 'inherit' }"
                      ></span>
                      <span>{{ 'ALL.MENU.My account' | translate }}</span>
                    </span>
                  </div>

                  <div mat-menu-item>
                    <span [routerLink]="['users']">
                      <span class="mdi mdi-account-multiple mdi-24px"></span>
                      <span>{{ 'ALL.MENU.My users' | translate }}</span>
                    </span>
                  </div>

                  <ng-container *ngIf="appState.is_real_user_superadmin$$ | async">
                    <div mat-menu-item class="only-admin">
                      <span [routerLink]="['zoho']">
                        <span class="mdi mdi-account-cash mdi-24px"></span>
                        <span>{{ 'ALL.MENU.Access to Zoho' | translate }}</span>
                      </span>
                    </div>

                    <div mat-menu-item [disableRipple]="true" class="only-admin no-overflow">
                      <span (click)="$event.stopPropagation()">
                        <span class="mdi mdi-incognito mdi-24px"></span>
                        <menu-impersonate></menu-impersonate>
                      </span>
                    </div>

                    <div mat-menu-item *ngIf="appState.is_impersonate_actif$$ | async">
                      <span (click)="oAuthService.impersonate = null">
                        <span class="mdi mdi-account-switch mdi-24px"></span>
                        <span>{{ 'ALL.MENU.Back superadmin' | translate }}</span>
                      </span>
                    </div>
                  </ng-container>

                  <div mat-menu-item>
                    <span
                      [routerLink]="['', { outlets: { modal: ['user_settings', { user_id: user?.user_id$$ | async }] } }]"
                      [queryParamsHandling]="'preserve'"
                    >
                      <span class="mdi mdi-cog mdi-24px"></span>
                      <span>{{ 'ALL.MENU.User settings' | translate }}</span>
                    </span>
                  </div>

                  <div mat-menu-item>
                    <span (click)="oAuthService.logout()">
                      <span class="mdi mdi-logout mdi-24px"></span>
                      <span>{{ 'ALL.MENU.Logout' | translate }}</span>
                    </span>
                  </div>

                  <mat-divider></mat-divider>

                  <div mat-menu-item class="beta">
                    <span [routerLink]="['/events']">
                      <span class="mdi mdi-calendar-month mdi-24px"></span>
                      <span>{{ 'ALL.MENU.Events' | translate }}</span>
                    </span>
                  </div>

                  <div mat-menu-item *ngIf="(appState.lang$$ | async) === 'fr'">
                    <span
                      [routerLink]="['', { outlets: { modal: ['user_manual', { help: 'app_timeline.html' }] } }]"
                      [queryParamsHandling]="'preserve'"
                    >
                      <span class="mdi mdi-new-box mdi-24px"></span>
                      <span>{{ 'ALL.MENU.Latest news' | translate }}</span>
                    </span>
                  </div>

                  <div mat-menu-item *ngIf="(appState.lang$$ | async) === 'fr'">
                    <span [routerLink]="['', { outlets: { modal: ['user_manual', { help: '' }] } }]" [queryParamsHandling]="'preserve'">
                      <span class="mdi mdi-help-circle mdi-24px"></span>
                      <span>{{ 'ALL.MENU.User manual' | translate }}</span>
                    </span>
                  </div>

                  <div mat-menu-item *ngIf="appState.is_superadmin$$ | async" class="only-admin">
                    <a target="blank" href="https://infra-grafana.beeguard.direct/d/pS6ZuGV7z/prometheus-blackbox-exporter?orgId=1">
                      <span class="mdi mdi-server-network mdi-24px"></span>
                      <span>{{ 'ALL.MENU.Server status' | translate }}</span>
                    </a>
                  </div>

                  <div
                    *ngIf="appState.is_superadmin$$ | async"
                    mat-menu-item
                    class="only-admin"
                    [routerLink]="['backoffice']"
                    [queryParamsHandling]="'preserve'"
                  >
                    <span>
                      <span class="mdi mdi-application-cog mdi-24px"></span>
                      <span>{{ 'ALL.MENU.Back office' | translate }}</span>
                    </span>
                  </div>

                  <mat-divider></mat-divider>

                  <div mat-menu-item>
                    <span [routerLink]="['', { outlets: { modal: ['welcome'] } }]" [queryParamsHandling]="'preserve'">
                      <span class="mdi mdi-presentation mdi-24px"></span>
                      <span>{{ 'ALL.MENU.Presentation' | translate }}</span>
                    </span>
                  </div>

                  <div *ngIf="betaAppService.is_current_build_is_beta$$ | async" mat-menu-item>
                    <span (click)="betaAppService.quit_beta_application()">
                      <span class="mdi mdi-flask mdi-24px"></span>
                      <span>{{ 'ALL.MENU.Quit BETA version' | translate }}</span>
                    </span>
                  </div>

                  <ng-container *ngIf="false">
                    <div *ngIf="(betaAppService.is_current_build_is_beta$$ | async) === false" mat-menu-item>
                      <span (click)="betaAppService.use_beta_application()">
                        <span class="mdi mdi-flask mdi-24px"></span>
                        <span>{{ 'ALL.MENU.Try BETA version' | translate }}</span>
                      </span>
                    </div>
                  </ng-container>

                  <div mat-menu-item *ngIf="appState.config.contact_form.in_menu">
                    <span [routerLink]="['', { outlets: { modal: ['new_issue'] } }]" [queryParamsHandling]="'preserve'">
                      <span class="mdi mdi-email mdi-24px"></span>
                      <span>{{ 'ALL.MENU.Contact' | translate }}</span>
                    </span>
                  </div>
                </div>
              </mat-menu>
            </li>
            <!-- endregion .user-menu -->
          </ng-container>
          <!-- .ready-to-use -->
        </ul>
        <!-- .website-navbar -->

        <!-- .website-navbar-on-mobile -->
        <a href="javascript:void(0)" class="button-collapse right" (click)="navigationSidenav.toggle()">
          <i class="mdi mdi-menu mdi-24px" style="color: black"></i>
        </a>
        <!-- .website-navbar-on-mobile -->
      </div>
    </div>
    <!-- #endregion (website top navigation) -->

    <!-- #region (website container) -->
    <div
      class="app-container"
      [ngClass]="{
        'with-sidenav': toolsSidenav?.opened,
        'full-width-app-container': isLinkActive('/devices/map') || isLinkActive('/locations/all/map')
      }"
    >
      <div *ngIf="error">{{ error }}</div>

      <router-outlet (activate)="activateMainOutlet()" *ngIf="!appState.isAccountIsDeactivated(); else deactivatedAccount"></router-outlet>

      <ng-container *ngIf="(oAuthService.is_authorized$$ | async) && (appState.is_superadmin$$ | async)">
        <bg2-superadmin-shortcut
          [ngClass]="{ '--in-front-of-map': isLinkActive('/devices/map') || isLinkActive('/locations/all/map') }"
        ></bg2-superadmin-shortcut>
      </ng-container>
    </div>
    <!-- #endregion (website container) -->

    <router-outlet name="modal"></router-outlet>
  </mat-sidenav-content>
  <!-- #endregion (website content) -->
</mat-sidenav-container>

<ng-template #deactivatedAccount>
  <div id="deactivated-account">
    <h4>{{ 'VIEWS.BASE.APP_COMPONENT.DEACTIVATED_ACCOUNT.Deactivated account' | translate }}</h4>
    <span *ngIf="(appState?.user?.unpaid_warning$$ | async) || true">{{
      'VIEWS.BASE.APP_COMPONENT.DEACTIVATED_ACCOUNT.Reason : One
      or more invoices are still awaiting payment' | translate
    }}</span>
    <div>
      <a [routerLink]="['', { outlets: { modal: ['new_issue'] } }]" [queryParamsHandling]="'preserve'">{{
        'VIEWS.BASE.APP_COMPONENT.DEACTIVATED_ACCOUNT.Contact us' | translate
      }}</a>
      <span
        >&nbsp;{{
          'VIEWS.BASE.APP_COMPONENT.DEACTIVATED_ACCOUNT.to regularise the situation and reactivate your
        account (no data has been deleted)' | translate
        }}</span
      >
    </div>
  </div>
</ng-template>
