<div class="bg-dialog delete-event-dialog">
  <dialog-header>
    <ng-container *ngIf="event$$ | async as event; else defaultTitle">
      <span>
        {{ 'VIEWS.EVENTS.SHARED.DELETE_EVENT_DIALOG.Delete event' | translate }}
      </span>
      <span>:&nbsp;</span>
      <span>
        {{ event.type_i18n | translate }}
      </span>
    </ng-container>
    <ng-template #defaultTitle>
      {{ 'VIEWS.EVENTS.SHARED.DELETE_EVENT_DIALOG.Delete event' | translate }}
    </ng-template>
  </dialog-header>

  <dialog-body>
    <ng-container *ngIf="(is_loading$$ | async) === false; else loadingTemplate">
      <ng-container *ngIf="event$$ | async as event; else loadingTemplate">
        <!-- Event description -->
        <ng-container>
          <span>
            {{ 'VIEWS.EVENTS.SHARED.DELETE_EVENT_DIALOG.You are trying to delete this event:' | translate }}
          </span>
          <br />
          <div class="event-desc">
            <span class="date" [popper]="event.date | formatDate: 'full'">
              {{ event.date | formatDate: 'short' }}
            </span>
            <bg2-event-span [event]="event"></bg2-event-span>
          </div>
        </ng-container>

        <!-- Only if cannot delete the event -->
        <ng-container *ngIf="(can_delete$$ | async) === false">
          <span>
            {{ 'VIEWS.EVENTS.SHARED.DELETE_EVENT_DIALOG.You can not delete this event because it implies to also delete:' | translate }}
          </span>
          <ul>
            <li *ngFor="let entity of setup_entities$$ | async" class="entity-desc">
              {{ entity.getDesc(translate) | async | translate }}
            </li>
          </ul>
        </ng-container>

        <!-- Only if can delete the event -->
        <ng-container *ngIf="can_delete$$ | async">
          <span>
            {{ 'VIEWS.EVENTS.SHARED.DELETE_EVENT_DIALOG.Are you sure to want to delete this event ?' | translate }}
          </span>

          <ng-container *ngIf="has_setup_entities$$ | async">
            <span>
              {{ 'VIEWS.EVENTS.SHARED.DELETE_EVENT_DIALOG.The deletion of this event will also delete the following:' | translate }}
            </span>
            <ul>
              <li *ngFor="let entity of setup_entities$$ | async" class="entity-desc">
                {{ entity.getDesc(translate) | async | translate }}
              </li>
            </ul>
          </ng-container>
        </ng-container>
      </ng-container>
    </ng-container>
  </dialog-body>

  <div class="dialog-footer">
    <bg2-button
      [type]="'cancel'"
      [message]="'ALL.COMMON.Cancel' | translate"
      [disabled]="is_deleting_event$$ | async"
      (btn_click)="cancel()"
    ></bg2-button>

    <bg2-button
      [type]="'delete'"
      [disabled]="(can_delete$$ | async) === false || (is_loading$$ | async) || (is_deleting_event$$ | async)"
      [loading]="is_deleting_event$$ | async"
      [message]="'VIEWS.MODALS.FORM.Confirm delete' | translate"
      [icon]="'mdi-delete'"
      (btn_click)="deleteEvent()"
    ></bg2-button>
  </div>
</div>

<!-- Loading template -->
<ng-template #loadingTemplate>
  <hex-loading [size]="'medium'"></hex-loading>
</ng-template>
