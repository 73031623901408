<div class="bg2-map-popup-movement-line-component">
  <div *ngIf="summarizedRouteInformations$$ | async; let summarized" style="display: flex; flex-flow: column nowrap;">
    <ng-container *ngIf="summarized?.is_same_day; else multipleDayMovement">
      <span>
        <b>
          {{ 'VIEWS.MAP.SHARED.HTML_POPUP_MARKER.Movement of [date]' | translate: { date: summarized?.start_date | formatDate: 'full' } }}
        </b>
      </span>
    </ng-container>

    <ng-template #multipleDayMovement>
      <span>
        <b>
          {{
            'VIEWS.MAP.SHARED.HTML_POPUP_MARKER.Movement from [start_date] to [end_date]'
              | translate
                : {
                    start_date: summarized?.start_date | formatDate: 'full',
                    end_date: summarized?.end_date | formatDate: 'full'
                  }
          }}
        </b>
      </span>
    </ng-template>

    <div style="display: flex; flex-flow: row nowrap;">
      <b>{{ 'VIEWS.MAP.SHARED.HTML_POPUP_MARKER.Coloration on the map:' | translate }}&nbsp;</b>
      <div class="color-viewer" [ngStyle]="{ 'background-color': summarized?.coloration }"></div>
    </div>

    <span>
      <b>{{ 'VIEWS.MAP.SHARED.HTML_POPUP_MARKER.Number of points:' | translate }}</b>
      <span>&nbsp;{{ summarized?.total_points || 0 }}</span>
    </span>

    <span>
      <b>{{ 'VIEWS.MAP.SHARED.HTML_POPUP_MARKER.Movement total time:' | translate }}</b>
      <span>&nbsp;{{ summarized?.duration }}</span>
    </span>

    <span>
      <b>{{ 'VIEWS.MAP.SHARED.HTML_POPUP_MARKER.Movement total distance:' | translate }}</b>
      <span *ngIf="summarized?.distance > 2000">&nbsp;~{{ summarized?.distance / 1000 | fixed: 0 }} km</span>
      <span *ngIf="summarized?.distance <= 2000">&nbsp;~{{ summarized?.distance }} m</span>
    </span>
  </div>
</div>
