import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';
import { marker as i18n } from '@biesbjerg/ngx-translate-extract-marker';

import { BehaviorSubject, combineLatest, map, Observable, switchMap } from 'rxjs';
import { distinctUntilRealChanged, replay, waitForNotNilProperties, waitForNotNilValue } from '@bg2app/tools/rxjs';

import { EntityType, exploitation_user_ace_to_i18n, location_user_ace_to_i18n } from 'app/models';

@Component({
  selector: 'bg2-acl-tooltip-content',
  templateUrl: 'acl-tooltip-content.component.html',
  styleUrls: ['acl-tooltip-content.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AclTooltipContentComponent {
  // #region -> (component basics)

  /** */
  constructor(private readonly _translate: TranslateService) {}

  // #endregion

  /** */
  private _what$$ = new BehaviorSubject<string>(null);

  /** */
  public what$$ = this._what$$.asObservable();

  /** */
  @Input()
  public set what(what: string) {
    this._what$$.next(what);
  }

  // #region -> (entity type management)

  /** */
  private _entity_type$$ = new BehaviorSubject<EntityType>(null);

  /** */
  public entity_type$$: Observable<EntityType> = this._entity_type$$.asObservable().pipe(distinctUntilRealChanged());

  /** */
  @Input()
  public set entity_type(entity_type: EntityType) {
    this._entity_type$$.next(entity_type);
  }

  /** */
  private get_i18n_ace$$ = this.entity_type$$.pipe(
    map(entity_type => {
      switch (entity_type) {
        case 'warehouse':
        case 'exploitation':
          return exploitation_user_ace_to_i18n;

        case 'location':
        case 'apiary':
        case 'hive':
          return location_user_ace_to_i18n;
      }
    }),
    replay()
  );

  // #endregion

  // #region -> (ACE management)

  /** */
  private _ace$$ = new BehaviorSubject<string>(null);

  /** */
  public ace$$: Observable<string> = this._ace$$.asObservable().pipe(
    waitForNotNilValue(),
    switchMap(ace => this.get_i18n_ace$$.pipe(switchMap(i18n_model => this._translate.stream(i18n_model.get(ace as any))))),
    distinctUntilRealChanged()
  );

  /** */
  @Input()
  public set ace(ace: string) {
    this._ace$$.next(ace);
  }

  // #endregion

  // #region -> (i18n management)

  /** */
  public i18n_solution$$ = combineLatest({ what: this.what$$, entity_type: this.entity_type$$ }).pipe(
    waitForNotNilProperties(),
    switchMap(({ what, entity_type }) => {
      let key_to_use = '';

      switch (entity_type) {
        case 'warehouse': {
          key_to_use = i18n<string>("ALL.ACE.COMMON.To view it, **request permission** from the warehouse's owner");
          break;
        }

        case 'exploitation': {
          key_to_use = i18n<string>("ALL.ACE.COMMON.To view it, **request permission** from the exploitaion's owner");
          break;
        }

        case 'location': {
          key_to_use = i18n<string>("ALL.ACE.COMMON.To view it, **request permission** from the location's owner");
          break;
        }

        case 'apiary': {
          key_to_use = i18n<string>("ALL.ACE.COMMON.To view it, **request permission** from the apiary's owner");
          break;
        }

        case 'hive': {
          key_to_use = i18n<string>("ALL.ACE.COMMON.To view it, **request permission** from the hive's owner");
          break;
        }

        default:
          throw new Error(`Switch case not handled for entity of type "${entity_type}" !`);
      }

      return this._translate.stream(key_to_use);
    }),
    distinctUntilRealChanged()
  );

  // #endregion
}
