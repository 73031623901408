<div *ngLet="location$$ | async; let location" class="bg2-location-details-content">
  <ng-container *ngLet="movement_alarms$$ | async; let alarms">
    <div *ngIf="location?.is_alarms_displayed$$ | async" class="location-alarms mat-elevation-z3">
      <ng-container *ngFor="let alarm of alarms">
        <alarm-mvnt-row [alarm]="alarm" [show_authorize_button]="true"></alarm-mvnt-row>
      </ng-container>

      <ng-container *ngIf="(alarms ?? [])?.length === 0">
        <ng-container *ngIf="apiary_has_devices_for_movement_detection$$ | async">
          <div class="location-alarms__status-container">
            <span>{{ 'VIEWS.LOCATIONS.SHARED.LOCATION_DETAILS.MOVEMENT_ALARMS.DESC.Protected apiary' | translate }}</span>
          </div>
        </ng-container>

        <ng-container *ngIf="(apiary_has_devices_for_movement_detection$$ | async) === false">
          <div class="location-alarms__status-container">
            <span>
              {{
                'VIEWS.LOCATIONS.SHARED.LOCATION_DETAILS.MOVEMENT_ALARMS.NOT_PROTECTED_APIARY.This apiary does not seem to be protected by a GPS'
                  | translate
              }}
            </span>

            <span>
              {{
                'VIEWS.LOCATIONS.SHARED.LOCATION_DETAILS.MOVEMENT_ALARMS.NOT_PROTECTED_APIARY.If there is already a GPS on this apiary, you can associate it via'
                  | translate
              }}
              <a
                [routerLink]="['', { outlets: { modal: ['apiary_hive_list', { eid: (apiary_id$$ | async) }] } }]"
                [queryParamsHandling]="'preserve'"
              >
                {{
                  'VIEWS.LOCATIONS.SHARED.LOCATION_DETAILS.MOVEMENT_ALARMS.NOT_PROTECTED_APIARY.the management of hives and devices'
                    | translate
                }}
              </a>
              <span>.</span>
            </span>

            <span>
              {{
                "VIEWS.LOCATIONS.SHARED.LOCATION_DETAILS.MOVEMENT_ALARMS.NOT_PROTECTED_APIARY.If you don't have any GPS, you can ask us to buy one by"
                  | translate
              }}
              <a [routerLink]="['', { outlets: { modal: ['new_issue'] } }]" [queryParamsHandling]="'preserve'">{{
                'VIEWS.LOCATIONS.SHARED.LOCATION_DETAILS.MOVEMENT_ALARMS.NOT_PROTECTED_APIARY.contacting us' | translate
              }}</a>
              .
            </span>
          </div>
        </ng-container>
      </ng-container>

      <div class="location-alarms__actions">
        <bg2-button
          [type]="'navigation'"
          [icon]="'mdi-arrow-up-bold'"
          [options]="{ is_thin: true }"
          [message]="'ALL.COMMON.Close' | translate"
          (btn_click)="location?.update_is_alarms_displayed(null)"
        ></bg2-button>
      </div>
    </div>
  </ng-container>

  <div class="location-ghost-manager">
    <bg2-locations-ghost-wizard [location]="location"></bg2-locations-ghost-wizard>
  </div>

  <div class="apiary-location-selector">
    <div
      class="select-tab location-tab"
      (click)="selected_tab = 'location'"
      [class.is-tab-selected]="(selected_tab$$ | async) === 'location'"
    >
      <bg2-picon-location-entity [location]="location"></bg2-picon-location-entity>

      <div
        *ngLet="user_can_read_any_position$$ | async; let user_can_read_any_position"
        class="address"
        [popper]="popperLocationAddressIsBlocked"
        [popperDisabled]="user_can_read_any_position"
      >
        <span *ngIf="!user_can_read_any_position" class="mdi mdi-lock"></span>

        <bg2-adaptive-text-to-container
          [options]="{ color: '#494752', fontMax: 16 }"
          [text_to_adapt]="
            user_can_read_any_position
              ? (location_address$$ | async) ?? ('ENTITY.LOCATION.ADRESS.No address' | translate)
              : ('ALL.ERROR.ACE.ACCESSIBILITY.Address not accessible' | translate)
          "
        ></bg2-adaptive-text-to-container>
      </div>
    </div>

    <ng-container>
      <div
        *ngIf="location_has_apiary$$ | async"
        class="select-tab apiary-tab"
        [ngClass]="{
          'is-tab-selected': (selected_tab$$ | async) === 'apiary',
          'no-apiary': (location_has_apiary$$ | async) === false,
          ghost: apiary_is_ghost$$ | async
        }"
        (click)="selected_tab = 'apiary'"
      >
        <ng-container *ngIf="location_has_apiary$$ | async">
          <img src="assets/img/apiary_simple.png" alt="" />

          <bg2-adaptive-text-to-container
            [options]="{ color: '#494752' }"
            [text_to_adapt]="apiary_name$$ | async"
          ></bg2-adaptive-text-to-container>

          <ng-container *ngIf="(user_can_x_apiary$$('write_all') | async) && display_type !== 'compact'">
            <span
              class="mdi mdi-pencil mdi-36px"
              [queryParamsHandling]="'preserve'"
              [popper]="'VIEWS.LOCATIONS.COMPONENTS.VIEWS.LOCATION_DETAILS.Edit apiary' | translate"
              [routerLink]="['', { outlets: { modal: ['update_entity', { eid: apiary_id$$ | async }] } }]"
            ></span>
          </ng-container>
        </ng-container>
      </div>
    </ng-container>

    <ng-template #loadingTab>
      <div class="select-tab loading-tab">
        <bg2-loading-skeleton [theme]="{ height: '100%', width: '100%' }"></bg2-loading-skeleton>
      </div>
    </ng-template>
  </div>

  <ng-container [ngSwitch]="selected_tab$$ | async">
    <ng-container *ngSwitchCase="'apiary'">
      <bg2-apiary-details *ngIf="display_type !== 'compact'" [apiary]="apiary$$ | async"></bg2-apiary-details>
      <bg2-apiary-details-compact *ngIf="display_type === 'compact'" [apiary]="apiary$$ | async"></bg2-apiary-details-compact>
    </ng-container>

    <ng-container *ngSwitchCase="'location'">
      <div class="location-details" [class.without-apiary]="(location_has_apiary$$ | async) === false">
        <div *ngIf="(location_has_apiary$$ | async) === false" class="no-apiary-alert">
          <bg2-apiary-undefined [location]="location"></bg2-apiary-undefined>
        </div>

        <bg2-location-map [location]="location"></bg2-location-map>

        <div *ngLet="user_can_read_any_position$$ | async; let can_read_any_position" class="location-geolocation">
          <span class="location-geolocation__category">
            <span class="key">
              <span class="mdi mdi-google-maps"></span>
              <span>{{ 'VIEWS.LOCATION_CARD.Location position' | translate | colon }}</span>
            </span>

            <span class="value">
              <ng-container *ngIf="can_read_any_position; else cannotReadLocationPosition">
                <ng-container *ngIf="(location_latitute$$ | async) && (location_longitude$$ | async); else noAvailablePosition">
                  <a
                    href="https://www.google.fr/maps?q=loc:{{ location_latitute$$ | async }},{{ location_longitude$$ | async }}"
                    target="_blank"
                    [popper]="'VIEWS.PAGE.MAP.Open google maps' | translate"
                  >
                    {{ location_latitute$$ | async | localeNumber: '0.5-5' }};
                    {{ location_longitude$$ | async | localeNumber: '0.5-5' }}
                  </a>

                  <a
                    href="https://www.google.com/maps/dir/?api=1&destination={{ location_latitute$$ | async }},{{
                      location_longitude$$ | async
                    }}"
                    target="_blank"
                    [popper]="'VIEWS.PAGE.MAP.Open direction' | translate | translate"
                  >
                    <i class="mdi mdi-directions mdi-24px"></i>
                  </a>

                  <a
                    href="https://www.cartoradio.fr/index.html#/cartographie/lonlat/{{ location_longitude$$ | async }}/{{
                      location_latitute$$ | async
                    }}"
                    target="_blank"
                  >
                    <i class="mdi mdi-radio-tower mdi-24px"></i>
                  </a>
                </ng-container>

                <ng-template #noAvailablePosition>
                  {{ 'ENTITY.LOCATION.WARN.No geo-position' | translate }}
                </ng-template>
              </ng-container>

              <ng-template #cannotReadLocationPosition>
                <bg2-acl-not-accessible
                  [message]="'ALL.ERROR.ACE.ACCESSIBILITY.Position not accessible' | translate"
                  [popper_config]="{
                    entity_type: 'location',
                    ace: 'read_precisse_position',
                    what: 'ALL.ERROR.ACE.READ_PRECISE_POSITION.WHAT.the location\'s address' | translate | lowercase
                  }"
                ></bg2-acl-not-accessible>
              </ng-template>
            </span>
          </span>

          <span class="location-geolocation__category">
            <span class="key">
              <span class="mdi mdi-terrain"></span>
              <span>{{ 'VIEWS.LOCATION_CARD.Elevation' | translate | colon }}</span>
            </span>

            <span class="value">
              <ng-container *ngIf="can_read_any_position; else tmpLocationElevationIsBlocked">
                <span *ngIf="location_elevation$$ | async; let location_elevation">{{ location_elevation | localeNumber: '1.0-0' }} m</span>
              </ng-container>

              <ng-template #tmpLocationElevationIsBlocked>
                <bg2-acl-not-accessible
                  [message]="'ALL.ERROR.ACE.ACCESSIBILITY.Elevation not accessible' | translate"
                  [popper_config]="{
                    entity_type: 'location',
                    ace: 'read_precisse_position',
                    what: 'ALL.ERROR.ACE.READ_PRECISE_POSITION.WHAT.the location\'s elevation' | translate | lowercase
                  }"
                ></bg2-acl-not-accessible>
              </ng-template>
            </span>
          </span>
        </div>

        <bg2-title-separator
          [title]="'VIEWS.LOCATIONS.COMPONENTS.VIEWS.LOCATION_DETAILS.Season stats' | translate"
          [icon]="'mdi-numeric'"
        ></bg2-title-separator>

        <div class="location-yearly-stats">
          <bg2-location-yearly-stats-carousel [location]="location"></bg2-location-yearly-stats-carousel>
        </div>

        <ng-container *ngIf="display_type !== 'compact'">
          <div *ngIf="comment$$ | async; let notes" class="location-comment-container">
            <bg2-title-separator
              [icon]="'mdi-comment'"
              [title]="'VIEWS.LOCATIONS.COMPONENTS.VIEWS.LOCATION_DETAILS.Notes' | translate"
            ></bg2-title-separator>

            <div class="location-comment-container__inner">
              <div>{{ notes }}</div>
            </div>
          </div>
        </ng-container>

        <bg2-title-separator
          *ngIf="display_type !== 'compact'"
          [icon]="'mdi-calendar-month'"
          [title]="'VIEWS.EVENTS.SHARED.ENTITY_EVENTS_HISTORY.History of location' | translate"
        ></bg2-title-separator>

        <div class="location-history" *ngIf="display_type !== 'compact'">
          <bg2-entity-events-history [entity]="location"></bg2-entity-events-history>
        </div>
      </div>
    </ng-container>
  </ng-container>
</div>

<popper-content #popperLocationAddressIsBlocked>
  <bg2-acl-tooltip-content
    [entity_type]="'location'"
    [ace]="(user_can_read_precise_position$$ | async) ? 'read_precisse_position' : 'read_aproximate_position'"
    [what]="'ALL.ERROR.ACE.READ_PRECISE_POSITION.WHAT.the location\'s address' | translate | lowercase"
  ></bg2-acl-tooltip-content>
</popper-content>
