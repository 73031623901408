import { Component, OnInit, ChangeDetectionStrategy, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { isNil } from 'lodash-es';

import { AppStateService } from 'app/core/app-state.service';

import { BehaviorSubject, Observable } from 'rxjs';
import { map, tap } from 'rxjs';
import { distinctUntilRealChanged } from '@bg2app/tools/rxjs';
import { replay } from '@bg2app/tools/rxjs';

import { AbstractModalComponent } from 'app/widgets/dialogs-modals';
import { IEnvironment } from 'environments/common';
import { ENV } from 'app/core/providers/environment.provider';

declare var twttr: any;

@Component({
  selector: 'bg2-welcome-modal',
  templateUrl: './welcome.modal.html',
  styleUrls: ['./welcome.modal.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WelcomeModalComponent extends AbstractModalComponent implements OnInit {
  // #region -> (component basics)

  constructor(@Inject(ENV) public readonly env: IEnvironment, protected router: Router, public appState: AppStateService) {
    super();
  }

  ngOnInit(): void {
    if (!isNil(JSON.parse(localStorage.getItem('welcome_dismiss_v2')))) {
      this._no_more$.next(JSON.parse(localStorage.getItem('welcome_dismiss_v2')));
    }

    const twitter = (d: Document, s: string, id: string): void => {
      let js: any;
      const fjs = d.getElementsByTagName(s)[0];
      const p = 'https';
      if (!d.getElementById(id)) {
        js = d.createElement(s);
        js.id = id;
        js.src = p + '://platform.twitter.com/widgets.js';
        fjs.parentNode.insertBefore(js, fjs);
      }
    };
    twitter(document, 'script', 'twitter-wjs');
  }

  public close(): void {
    localStorage.setItem('welcome_dismiss_v2', '' + this._no_more$.getValue());
    super.close();
  }

  /** */
  protected handle_event_before_unload(event: BeforeUnloadEvent): void {
    return null;
  }

  // #endregion

  /** */
  public get logo(): string {
    if (this.env.env === 'apismart') {
      return 'assets/logo_apismart.png';
    }

    if (this.env.env === 'prod') {
      return 'assets/logo.svg';
    }

    return `assets/logo_${this.env.env}.svg`;
  }

  // #region -> (paging)

  public readonly PAGES: number[] = [0, 1, 2, 3, 4, 5];

  private _page$: BehaviorSubject<number> = new BehaviorSubject(0);
  public page$$: Observable<number> = this._page$.asObservable().pipe(
    map((page: number) => Math.max(0, page, Math.min(this.PAGES.length - 1, page))),
    tap((page: number) => (page === 5 ? this.openTwitter() : null)),
    replay()
  );

  public next_page(): void {
    this._page$.next(this._page$.getValue() + 1);
  }

  public previous_page(): void {
    this._page$.next(this._page$.getValue() - 1);
  }

  public set_page_number(page_number: number): void {
    this._page$.next(page_number);
  }

  // #endregion

  // #region -> (social networks)

  private _is_twitter_opened$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public is_twitter_opened$$: Observable<boolean> = this._is_twitter_opened$.asObservable().pipe(distinctUntilRealChanged<boolean>());

  private openTwitter(): void {
    if (!this._is_twitter_opened$.getValue()) {
      setTimeout(() => {
        try {
          this._is_twitter_opened$.next(true);
          twttr.widgets.createFollowButton('mybeeguard', document.getElementById('twitter-follow-btn'), { size: 'large' });
        } catch (e) {
          this._is_twitter_opened$.next(false);
          if (e instanceof ReferenceError) {
            console.log('twttr no loaded yet');
          } else {
            throw e;
          }
        }
      }, 200);
    }
  }

  // #endregion

  // #region -> (no more display)

  private _no_more$: BehaviorSubject<boolean> = new BehaviorSubject(true);
  public no_more$$: Observable<boolean> = this._no_more$.asObservable().pipe(distinctUntilRealChanged<boolean>());

  public set_no_more(no_more: boolean): void {
    this._no_more$.next(no_more);
  }

  // #endregion
}
