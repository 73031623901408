import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, TemplateRef } from '@angular/core';

import { isNil, isString } from 'lodash-es';

import { strEnum } from 'app/misc/tools';
import { BehaviorSubject } from 'rxjs';

const Bg2ButtonType = strEnum(['delete', 'cancel', 'action', 'navigation']);
export type Bg2ButtonType = keyof typeof Bg2ButtonType;

interface Bg2ButtonOptions {
  has_flag_beta?: boolean;

  is_thin?: boolean;
  is_w100?: boolean;  

  is_icon_stacked?: boolean;
  icon_size_class?: 'mdi-18px' | string;

  is_label_hidden_on_small?: boolean;
}

@Component({
  selector: 'bg2-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class Bg2ButtonComponent {
  @Input()
  public type: Bg2ButtonType = null;

  @Input()
  public btn_type: 'button' | 'menu' | 'reset' | 'submit' = 'button';

  @Input()
  public icon: string | undefined = undefined;

  @Input()
  public message: TemplateRef<any> | string | undefined;

  @Input()
  public descriptive: TemplateRef<any> | string | undefined = undefined;

  private _loading$$ = new BehaviorSubject<boolean>(false);
  public loading$$ = this._loading$$.asObservable();

  @Input()
  public set loading(_loading: boolean) {
    this._loading$$.next(_loading);
  }

  public get loading(): boolean {
    return this._loading$$.getValue();
  }

  @Input()
  public disabled: boolean = false;

  @Input()
  public styles: { [klass: string]: any } = {};

  @Input()
  public internal_routing: { routerLink: any; queryParamsHandling?: any; queryParams?: any } = undefined;

  // #region -> (advanced inputs)

  private readonly _default_options: Bg2ButtonOptions = {
    is_label_hidden_on_small: false,
    is_w100: true,
  };
  private _options: Bg2ButtonOptions = { ...this._default_options};

  @Input()
  public set options(options: Bg2ButtonOptions) {
    this._options = { ...this._default_options, ...options };
  }

  public get options(): Bg2ButtonOptions {
    return this._options;
  }

  private _color: string | undefined = undefined;

  @Input()
  public set color(color: string | undefined) {
    this._color = color;
  }

  public get color(): string | undefined {
    if (!isNil(this._color)) {
      return this._color;
    }

    switch (this.type) {
      case 'navigation':
      case 'action': {
        return 'primary';
      }

      case 'cancel': {
        return undefined;
      }

      case 'delete': {
        return 'warn';
      }
    }
  }

  // #endregion

  @Output()
  public btn_click = new EventEmitter();

  // #region -> (component basics)

  constructor() {}

  // #endregion

  public isTemplate(content: TemplateRef<any> | string): TemplateRef<any> {
    return !isString(content) ? content : null;
  }

  public get isFlatButton(): boolean {
    return (['action', 'cancel', 'delete'] as Bg2ButtonType[]).includes(this.type);
  }

  public get isStrokedButton(): boolean {
    return (['navigation'] as Bg2ButtonType[]).includes(this.type);
  }

  public get iconWithMargin(): boolean {
    if (this.options?.is_icon_stacked || false) {
      return false;
    }

    if (isNil(this.message)) {
      return false;
    }

    return true;
  }
}
