import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Output, EventEmitter } from '@angular/core';

import { PreviousValue } from '../control/control.widget';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs';

@Component({
  selector: 'form-element-previous',
  templateUrl: './element-previous.component.html',
  styleUrls: ['./element-previous.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FormElementPreviousComponent {

  private _previous$$ = new BehaviorSubject<PreviousValue>(null);
  public previous$$ = this._previous$$.asObservable();

  @Input() set previous(pv: PreviousValue) {
    this._previous$$.next(pv);
  }

  @Input() from_template = false;
  @Input() previous_btn = false;
  @Output() reset_previous = new EventEmitter<boolean>();

  public value$$ = this.previous$$.pipe(
    map(previous => previous?.value)
  );

  public date$$ = this.previous$$.pipe(
    map(previous => previous?.from.date)
  );

  /**
   * Get full canonical path of current property schema.
   */
  public canonical_path$$ = this.previous$$.pipe(
    map(previous => previous?.property?.path)
  );

  /**
   * Get current property schema.
   */
  public current$$ = this.previous$$.pipe(
    map(previous => previous?.property?.current)
  );

  constructor() { }

  public resetPrevious(): void {
    this.reset_previous.emit(true);
  }

}
