import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';

import { combineLatest, map, of, switchMap } from 'rxjs';
import { distinctUntilRealChanged, replay } from '@bg2app/tools/rxjs';

import { AppStateService } from 'app/core/app-state.service';
import { ConsoleLoggerService } from 'app/core/console-logger.service';

import { AbstractModalComponent } from 'app/widgets/dialogs-modals';
import { ModalParams } from 'app/widgets/dialogs-modals/abstract-modal.component';
import { SimpleSetterGetter } from 'app/models';

type ZohoCheckName = 'zoho_account_exploitation_id' | 'zoho_contact_user_id';
type ZohoCheckWithValuesFrom = 'appstate_selected_exploitations' | 'data';

type WhatToCheck = `${ZohoCheckName}:${ZohoCheckWithValuesFrom}`;

/** */
interface ZohoCheckModalParams extends ModalParams {
  /** */
  check: WhatToCheck;
}

@Component({
  selector: 'bg2-zoho-check-modal',
  templateUrl: './zoho-check-modal.component.html',
  styleUrls: ['./zoho-check-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ZohoCheckModalComponent extends AbstractModalComponent<ZohoCheckModalParams> implements OnInit, OnDestroy {
  // #region -> (modal basics)

  /** */
  private readonly LOGGER = new ConsoleLoggerService('ZohoCheckModalComponent', true);

  /** */
  constructor(private readonly _appStateService: AppStateService) {
    super();
  }
  
  /** */
  ngOnInit(): void {}
  
  /** */
  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  // #endregion

  /** */
  protected handle_event_before_unload(event: BeforeUnloadEvent): void {
    return null;
  }

  // #region -> (zoho check config)

  /** */
  public what_to_check$$ = this.input_params$$.pipe(
    map(parameters => parameters.check),
    distinctUntilRealChanged()
  );

  /** */
  public check_name$$ = this.what_to_check$$.pipe(
    map(what_to_check => <ZohoCheckName>what_to_check.split(':')[0]),
    distinctUntilRealChanged()
  );

  /** */
  public check_from_values$$ = this.what_to_check$$.pipe(
    map(what_to_check => <ZohoCheckWithValuesFrom>what_to_check.split(':')[1]),
    distinctUntilRealChanged()
  );

  /** */
  public refetch_zoho_data = new SimpleSetterGetter(false);

  // #endregion

  // #region -> (case "zoho_account_exploitation_id")

  /** */
  public data__check_zoho_account_exploitation_id$$ = this.check_from_values$$.pipe(
    switchMap(check_from_values => {
      if (check_from_values === 'appstate_selected_exploitations') {
        return this._appStateService.selected_exploitations$$;
      }

      return of(null);
    }),
    replay()
  );
  // #endregion

  // #region -> (case "zoho_contact_user_id")

  /** */
  public data__check_zoho_contact_user_id$$ = this.check_from_values$$.pipe(
    switchMap(check_from_values => {
      if (check_from_values === 'appstate_selected_exploitations') {
        return this._appStateService.selected_exploitations$$.pipe(
          switchMap(exploitations => combineLatest(exploitations.map(exploitation => exploitation?.owner$$ ?? of(null))))
        );
      }

      return of(null);
    }),
    replay()
  );

  // #endregion
}
