<div class="us-about-component">
  <mat-card>
    <mat-card-header>
      <mat-card-title>{{ 'ROUTABLE_MODALS.USER_SETTINGS.CATEGORY.ABOUT.SUBGROUP_1.Title' | translate }}</mat-card-title>
    </mat-card-header>

    <mat-card-content>
      <div class="config">
        <span class="label">{{ 'ROUTABLE_MODALS.USER_SETTINGS.CATEGORY.ABOUT.SUBGROUP_1.Angular version' | translate }}</span>

        <ng-container *ngLet="appState.running_app_info.angular_version$$ | async; let angular_version">
          <span>{{ angular_version }}</span>
        </ng-container>
      </div>

      <div class="config">
        <span class="label">{{ 'ROUTABLE_MODALS.USER_SETTINGS.CATEGORY.ABOUT.SUBGROUP_1.Application version' | translate }}</span>

        <ng-container *ngLet="appState.running_app_info.app_version$$ | async; let app_version">
          <span>{{ app_version }}</span>
        </ng-container>
      </div>

      <div class="config">
        <span class="label">{{ 'ROUTABLE_MODALS.USER_SETTINGS.CATEGORY.ABOUT.SUBGROUP_1.Browser' | translate }}</span>

        <ng-container *ngLet="appState.running_app_info.parsed_user_agent$$ | async; let parsed_user_agent">
          <span>{{ parsed_user_agent?.browser?.name }} ({{ parsed_user_agent?.browser?.version }})</span>
        </ng-container>
      </div>

      <div class="config">
        <span class="label">{{ 'ROUTABLE_MODALS.USER_SETTINGS.CATEGORY.ABOUT.SUBGROUP_1.Operating System' | translate }}</span>

        <ng-container *ngLet="appState.running_app_info.parsed_user_agent$$ | async; let parsed_user_agent">
          <span>{{ parsed_user_agent?.os?.name }} ({{ parsed_user_agent?.os?.version }})</span>
        </ng-container>
      </div>
    </mat-card-content>
  </mat-card>
</div>
