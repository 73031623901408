<div class="bg-dialog">
  <!-- Dialog body -->
  <dialog-body>
    <bg2-form-overlay
      #form
      [source_model]="form_value$$ | async"
      [source_schema]="form_schema$$ | async"
      (when_form_data_update)="update_value($event)"
    ></bg2-form-overlay>
  </dialog-body>

  <!-- Dialog footer -->
  <div class="dialog-footer" >
    
    <!-- Delete button -->
    <bg2-button
      *ngIf="(is_create_form$$ | async) === false"
      [type]="'delete'"
      [icon]="'mdi-delete'"
      [message]="'ALL.ACTIONS.Delete' | translate"
      [disabled]="(is_form_value_valid$$ | async) === false"
      (btn_click)="delete_movement()"
    >
    </bg2-button>
    <!-- Cancel button -->
  
    <bg2-button
      [type]="'cancel'"
      [message]="'ALL.ACTIONS.Cancel' | translate"
      (btn_click)="clicked(false)"
    >
    </bg2-button>

    <!-- Create button -->
    <bg2-button
      *ngIf="is_create_form$$ | async"
      [type]="'action'"
      [icon]="'mdi-content-save'"
      [message]="'ALL.ACTIONS.Create' | translate"
      [disabled]="(is_form_value_valid$$ | async) === false"
      (btn_click)="create_movement()"
    >
    </bg2-button>

    <!-- Update button -->
    <bg2-button
      *ngIf="(is_create_form$$ | async) === false"
      [type]="'action'"
      [icon]="'mdi-content-save'"
      [message]="'ALL.ACTIONS.Update' | translate"
      [disabled]="(is_form_value_valid$$ | async) === false"
      (btn_click)="update_movement()"
    >
    </bg2-button>

  </div>
</div>
