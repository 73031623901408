import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { isNil } from 'lodash-es';

import { BehaviorSubject, combineLatest, filter, withLatestFrom, map } from 'rxjs';
import { distinctUntilRealChanged, replay } from '@bg2app/tools/rxjs';

import { ConsoleLoggerService } from 'app/core/console-logger.service';

import { environment } from 'environments/environment';
import { compute_wind_heading } from 'app/misc/tools/maths';

@Component({
  selector: 'bg2-picon-wind-direction',
  templateUrl: './picon-wind-direction.component.html',
  styleUrls: ['./picon-wind-direction.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PiconWindDirectionComponent {
  private readonly _logger = new ConsoleLoggerService('Bg2PictoWindDirectionComponent', true);

  // #region -> (wind heading management)

  private _raw_wind_heading$$ = new BehaviorSubject<number>(null);
  public raw_wind_heading$$ = this._raw_wind_heading$$.asObservable().pipe(distinctUntilRealChanged(), replay());

  @Input()
  public set raw_wind_heading(raw_wind_heading: number) {
    this._raw_wind_heading$$.next(raw_wind_heading);
  }

  public get raw_wind_heading(): number {
    return this._raw_wind_heading$$.getValue();
  }

  public is_not_nil_raw_wind_heading$$ = this.raw_wind_heading$$.pipe(
    map(raw_wind_heading => !isNil(raw_wind_heading)),
    replay()
  );

  // #endregion

  // #region -> (wind speed management)

  private _raw_wind_speed$$ = new BehaviorSubject<number>(null);
  public raw_wind_speed$$ = this._raw_wind_speed$$.asObservable().pipe(distinctUntilRealChanged(), replay());

  @Input()
  public set raw_wind_speed(raw_wind_speed: number) {
    this._raw_wind_speed$$.next(raw_wind_speed);
  }

  public get raw_wind_speed(): number {
    return this._raw_wind_speed$$.getValue();
  }

  public is_not_nil_raw_wind_speed$$ = this.raw_wind_speed$$.pipe(
    map(raw_wind_speed => !isNil(raw_wind_speed)),
    replay()
  );

  // #endregion

  /** */
  public wind_heading_data$$ = combineLatest([this.raw_wind_speed$$, this.raw_wind_heading$$]).pipe(
    map(([speed, heading]) => compute_wind_heading(speed, heading)),
    replay()
  );
}
