import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';

import { Observable } from 'rxjs';
import { map } from 'rxjs';

import { EfObjectWidgetComponent } from '../object/object.widget';

@Component({
  selector: 'bg2-ef-bg2visit-config-widget',
  templateUrl: './bg2visit-config.widget.html',
  styleUrls: ['./bg2visit-config.widget.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EfBg2VisitConfigWidgetComponent extends EfObjectWidgetComponent implements OnInit {

  public global_actif$$: Observable<boolean>;

  ngOnInit(): void {
    super.ngOnInit();
    this.global_actif$$ = this.value$$.pipe(
      map(value => value?.apiary?.length)
    );
  }

}
