<bg2-modal [fullscreen]="fullscreen || false" #modal *ngIf="{entity: (entity$$ | async)} as ctx">
  <bg2-modal-header>
    <div class="header">
      <span class="mdi mdi-pencil mdi-36px"></span>
      <span class="title">
        <ng-container *ngIf="!ctx.entity">{{'VIEWS.MODALS.FORM.Update' | translate}}&nbsp;#{{eid}}</ng-container>
        <ng-container *ngIf="ctx.entity">{{entity.type_i18n | translate}}&nbsp;{{ctx.entity.name$$ | async}}</ng-container>
      </span>
    </div>
    <div class="action-buttons">
      <span class="mdi mdi-close-box mdi-36px" (click)="close()"></span>
    </div>
  </bg2-modal-header>

  <bg2-modal-content>
    <ng-container *ngIf="eid > 0; else: isGhostEntity">
      <div *ngIf="(oh$$ | async) === false">
        <div class="error" *ngIf="error">{{error | translate}}</div>

        <bg2-form-overlay
          [source_schema]="form_schema$$ | async"
          [source_model]="entity_form_model$$ | async"
          (when_form_valid_update)="setFormValid($event)"
          (when_form_data_update)="update_entity_static_state($event)"
        ></bg2-form-overlay>
      </div>
    </ng-container>

    <ng-template #isGhostEntity>
      <div class="warning">
        {{ 'ROUTABLE_MODALS.UPDATE_ENTITY.This entity has been created automatically, you must create it explicitly to modify it' |
        translate }}
      </div>
    </ng-template>

    <div>
      <bg2-entity-history [entity]="ctx.entity"></bg2-entity-history>
    </div>
  </bg2-modal-content>

  <bg2-modal-footer>
    <ng-container
      *ngIf="((oh$$ | async) === false) && (ctx.entity?.id > 0) && ctx.entity?.hasACE('write_all_events') && (ctx.entity?.archivable$$ | async)"
    >
      <bg2-button-delete-or-archive [entity]="ctx.entity" (taken_action)="onDeleteOrArchiveEntity($event)"></bg2-button-delete-or-archive>
    </ng-container>

    <bg2-button
      *ngIf="((oh$$ | async) === false) && (ctx.entity?.has_changed$$ | async)"
      [type]="'cancel'"
      [message]="'VIEWS.MODALS.FORM.Cancel' | translate"
      (btn_click)="close()"
    ></bg2-button>

    <bg2-button
      *ngIf="(oh$$ | async) || (ctx.entity?.has_changed$$ | async) === false"
      [type]="'cancel'"
      [message]="'VIEWS.MODALS.FORM.Close' | translate"
      (btn_click)="close()"
    ></bg2-button>

    <bg2-button
      *ngIf="(oh$$ | async) === false"
      [type]="'action'"
      [icon]="'mdi-content-save'"
      [message]="'VIEWS.MODALS.FORM.Save' | translate"
      [disabled]="(loading$$ | async) || (ctx.entity?.has_changed$$ | async) === false"
      [loading]="loading$$ | async"
      (btn_click)="submit()"
    ></bg2-button>
  </bg2-modal-footer>
</bg2-modal>
