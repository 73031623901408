// From @node_modules/leaflet/*
import { DivIcon, Icon, IconOptions, divIcon, Util, PolylineOptions, Polygon, latLng } from 'leaflet';

import { ConsoleLoggerService } from 'app/core/console-logger.service';

// From @src/app/models/entities/*
import { DRDevice } from 'app/models';

// From @src/app/misc/tools/*
import { drawPolygonCircle } from 'app/misc/tools/geomap';

// From @local/*
import { AbstractMarker } from '../marker/AbstractMarker.model';
import { CONF_CIRCLE_COLORS } from '../../constants/circles.conf';
import { MapMarkerType } from '../../enumerators/MapMarkerType.enum';
import { isNil } from 'lodash-es';

export class DeviceMarker extends AbstractMarker {
  /** */
  private _gps_preferred_position_type: 'GPS' | 'CELLIDS' = null;

  /** */
  public device: DRDevice = null;

  /** */
  private readonly LOGGER = new ConsoleLoggerService(this.constructor.name, false);

  /** */
  constructor(device: DRDevice, prefer_position_type: 'GPS' | 'CELLIDS' = null) {
    const usable_location = isNil(prefer_position_type)
      ? device.location
      : prefer_position_type === 'GPS'
      ? device.location_gps
      : device.location_cellids;

    super(latLng(usable_location.latitude, usable_location.longitude));

    // Updates non-related map properties
    this.device = device;
    this._gps_preferred_position_type = prefer_position_type;

    // Updates map marker properties
    this.type = this.computeMarkerType();
    this.options.icon = this.buildMarker();
    this.circle = this.buildCircle(usable_location.accuracy, {});
  }

  private computeMarkerType(): MapMarkerType {
    if (this.device.type === 'GPS') {
      if (isNil(this._gps_preferred_position_type)) {
        if (this.device.hasPrecisePosition()) {
          return MapMarkerType.DEVICE_GPS;
        }

        return MapMarkerType.DEVICE_GPRS;
      } else {
        if (this._gps_preferred_position_type === 'GPS') {
          return MapMarkerType.DEVICE_GPS;
        }

        return MapMarkerType.DEVICE_GPRS;
      }
    } else if (this.device.type === 'RG') {
      return MapMarkerType.DEVICE_RG;
    } else if (this.device.type === 'WG') {
      return MapMarkerType.DEVICE_WG;
    } else if (this.device.type === 'CPT' || this.device.type === 'CPTMC' || this.device.type === 'BeeLive') {
      return MapMarkerType.DEVICE_CPT;
    } else if (this.device.type === 'TG') {
      return MapMarkerType.DEVICE_TG;
    } else {
      this.LOGGER.warn(`Unknow device type: ${this.device.type}`);
      return MapMarkerType.DEVICE_GPS;
    }
  }

  protected buildMarker(): DivIcon | Icon<IconOptions> {
    let string_template = `<img src="assets/img/views/common/maps/${this.type}_marker.svg" />`;

    if (this.device.has_outdated_com.outdated) {
      string_template += '<img class="outdated-marker-state" src="assets/img/views/common/maps/clock-alert.svg" />';
    }

    if (this.device.type === 'GPS') {
      if (this.device.last_measurements?.location?.fields?.tracking_state?.last === 'PERIODIC_LOCATION') {
        string_template +=
          '<img class="movement-periodic-location-marker-state animate-rotate" src="assets/img/views/common/maps/periodic_location.svg" />';
      }

      if (this.device.last_measurements?.location?.fields?.tracking_state?.last === 'MOVEMENT') {
        string_template += '<img class="movement-movement-marker-state animate-shake" src="assets/img/views/common/maps/movement.svg" />';
      }
    }

    return divIcon({
      className: 'leaflet-device-' + this.device.type + '-marker',
      html: Util.template(string_template, {
        i_color: CONF_CIRCLE_COLORS[this.type].color,
      }),
      // size of the icon
      iconSize: [29.16, 41.64],
      // size of the shadow
      // shadowSize: [50, 64],
      // point of the icon which will correspond to marker's location
      iconAnchor: this.type === MapMarkerType.DEVICE_GPRS ? [38.17 / 2, 38.16 / 2] : [29.16 / 2, 41.64],
      // the same for the shadow
      // shadowAnchor: [4, 62],
      // point from which the popup should open relative to the iconAnchor
      popupAnchor: this.type === MapMarkerType.DEVICE_GPRS ? [0, -11] : [0, -36],
    });
  }

  protected buildCircle(radius: number, options: PolylineOptions): Polygon<any> {
    return drawPolygonCircle(this.getLatLng(), radius, options);
  }
}
