<div class="zoho-auth-snackbar-component">
  <div class="desc">
    {{ "WIDGETS.WIDGETS_REUSABLES.SNACKBARS.ZOHO_AUTH.It looks like you're not connected to Zoho" | translate }}
  </div>

  <div class="actions">
    <bg2-button
      [type]="'cancel'"
      [message]="'WIDGETS.WIDGETS_REUSABLES.SNACKBARS.ZOHO_AUTH.Ignore' | translate"
      (btn_click)="ignore()"
    ></bg2-button>

    <bg2-button [type]="'action'" [message]="'VIEWS.AUTH.LOGIN.Log in' | translate" (btn_click)="login()"></bg2-button>
  </div>

  <div class="reminder">
    <mat-checkbox [color]="'primary'" [checked]="do_not_remind_me$$ | async" (change)="do_not_remind_me = $event.checked">
      {{
        'WIDGETS.WIDGETS_REUSABLES.SNACKBARS.ZOHO_AUTH.Never show me that again (This popup will never appear again, even if you are not connected to Zoho)'
          | translate
      }}
    </mat-checkbox>
  </div>
</div>
