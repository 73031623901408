import { marker as i18n } from '@biesbjerg/ngx-translate-extract-marker';

/** */
export enum ZohoError {
  /** */
  NOT_AUTHENTICATED = 'NOT_AUTHENTICATED',

  /** */
  MISSING_CRM_ID_IN_APP_USER = 'MISSING_CRM_ID_IN_APP_USER',

  /** */
  MISSING_CRM_ID_IN_EXPLOITATION = 'MISSING_CRM_ID_IN_EXPLOITATION',

  /** */
  MISSING_EXPL_ID_IN_CRM_ACCOUNT = 'MISSING_EXPL_ID_IN_CRM_ACCOUNT',

  /** */
  UNDEFINED_ZOHO_CRM_OBJECT = 'UNDEFINED_ZOHO_CRM_OBJECT',

  /** */
  UNDEFINED_ZOHO_BOOKS_OBJECT = 'UNDEFINED_ZOHO_BOOKS_OBJECT',

  /** */
  UNDEFINED_ZOHO_CRM_ACCOUNT = 'UNDEFINED_ZOHO_CRM_ACCOUNT',

  /** */
  UNDEFINED_ZOHO_CRM_CONTACT = 'UNDEFINED_ZOHO_CRM_CONTACT',

  /** */
  UNDEFINED_ZOHO_DESK_ACCOUNT = 'UNDEFINED_ZOHO_DESK_ACCOUNT',

  /** */
  UNDEFINED_ZOHO_DESK_CONTACT = 'UNDEFINED_ZOHO_DESK_CONTACT',

  /** */
  EMPTY_DESK_TICKETS = 'EMPTY_DESK_TICKETS',

  /** */
  NO_ZOHO_DESK_ACCOUNT_LINKED_TO_DESK_CONTACT = 'NO_ZOHO_DESK_ACCOUNT_LINKED_TO_DESK_CONTACT',

  /** */
  LOCAL_EXPL_ID_DIFFERENT_FROM_CRM_ACCOUNT_EXPL_ID = 'LOCAL_EXPL_ID_DIFFERENT_FROM_CRM_ACCOUNT_EXPL_ID',

  /** */
  BOOKS__NO_CLIENT_FOR_CRM_CONTACT = 'BOOKS__NO_CLIENT_FOR_CRM_CONTACT',
}

/** */
const zoho_error_keys: { [key in ZohoError]: string } = {
  /** */
  BOOKS__NO_CLIENT_FOR_CRM_CONTACT: i18n<string>(`MODELS.ZOHO.ERROR.BOOKS__NO_CLIENT_FOR_CRM_CONTACT`),

  /** */
  NOT_AUTHENTICATED: i18n<string>(`MODELS.ZOHO.ERROR.NOT_AUTHENTICATED`),

  /** */
  MISSING_CRM_ID_IN_APP_USER: i18n<string>(`MODELS.ZOHO.ERROR.MISSING_CRM_ID_IN_APP_USER`),

  /** */
  MISSING_CRM_ID_IN_EXPLOITATION: i18n<string>(`MODELS.ZOHO.ERROR.MISSING_CRM_ID_IN_EXPLOITATION`),

  /** */
  MISSING_EXPL_ID_IN_CRM_ACCOUNT: i18n<string>(`MODELS.ZOHO.ERROR.MISSING_EXPL_ID_IN_CRM_ACCOUNT`),

  /** */
  UNDEFINED_ZOHO_DESK_ACCOUNT: i18n<string>(`MODELS.ZOHO.ERROR.UNDEFINED_ZOHO_DESK_ACCOUNT`),

  /** */
  UNDEFINED_ZOHO_BOOKS_OBJECT: i18n<string>(`MODELS.ZOHO.ERROR.UNDEFINED_ZOHO_BOOKS_OBJECT`),

  /** */
  UNDEFINED_ZOHO_CRM_OBJECT: i18n<string>(`MODELS.ZOHO.ERROR.UNDEFINED_ZOHO_CRM_OBJECT`),

  /** */
  UNDEFINED_ZOHO_CRM_ACCOUNT: i18n<string>(`MODELS.ZOHO.ERROR.UNDEFINED_ZOHO_CRM_ACCOUNT`),

  /** */
  UNDEFINED_ZOHO_CRM_CONTACT: i18n<string>(`MODELS.ZOHO.ERROR.UNDEFINED_ZOHO_CRM_CONTACT`),

  /** */
  UNDEFINED_ZOHO_DESK_CONTACT: i18n<string>(`MODELS.ZOHO.ERROR.UNDEFINED_ZOHO_DESK_CONTACT`),

  /** */
  EMPTY_DESK_TICKETS: i18n<string>(`MODELS.ZOHO.ERROR.EMPTY_DESK_TICKETS`),

  /** */
  NO_ZOHO_DESK_ACCOUNT_LINKED_TO_DESK_CONTACT: i18n<string>(`MODELS.ZOHO.ERROR.NO_ZOHO_DESK_ACCOUNT_LINKED_TO_DESK_CONTACT`),

  /** */
  LOCAL_EXPL_ID_DIFFERENT_FROM_CRM_ACCOUNT_EXPL_ID: i18n<string>(`MODELS.ZOHO.ERROR.LOCAL_EXPL_ID_DIFFERENT_FROM_CRM_ACCOUNT_EXPL_ID`),
};

/** */
export const get_i18n_for_zoho_error = (error: ZohoError) => zoho_error_keys[error];
