import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { isNil, max, orderBy } from 'lodash-es';

import { differenceInHours } from 'date-fns/esm';

import { Observable, of, ReplaySubject, map, switchMap, combineLatest, BehaviorSubject } from 'rxjs';
import {
  replay,
  waitForNotNilValue,
  distinctUntilRealChanged,
  create_replay_subject_with_first_value,
} from '@bg2app/tools/rxjs';

import { Apiary, DRDevice } from 'app/models';
import { LastWeatherData } from 'app/models/data';
import { find_worst_device_status_in, find_worst_device_status_simplified_in } from 'app/models/devices/tools/device-status.tools';

import { parseDate } from 'app/misc/tools';

@Component({
  selector: 'bg2-apiary-last-data-card-weather',
  templateUrl: './apiary-last-data-card-weather.component.html',
  styleUrls: ['./apiary-last-data-card-weather.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ApiaryLastDataCardWeatherStationComponent {
  // #region -> (component basics)

  /** */
  public track_by_device(index: number, device: DRDevice) {
    return device.imei;
  }

  // #endregion

  // #region -> (apiary entity)

  /** */
  private _apiary$$: ReplaySubject<Apiary> = create_replay_subject_with_first_value<Apiary>(null);

  /** */
  public apiary$$: Observable<Apiary> = this._apiary$$.asObservable().pipe(waitForNotNilValue());

  /** */
  @Input()
  public set apiary(apiary: Apiary) {
    this._apiary$$.next(apiary);
  }

  /** */
  public apiary_can_have_rain_data$$ = this.apiary$$.pipe(
    switchMap(apiary => apiary.can_have_weather_data$$),
    map(can_have_weather_data => can_have_weather_data.rain)
  );

  // #endregion

  // #region -> (inner carousel management)

  /** */
  private _current_index$$ = new BehaviorSubject<number>(0);

  /** */
  public translate_x$$ = this._current_index$$.pipe(
    distinctUntilRealChanged(),
    map(current_index => `translateX(${current_index * 100 * -1}%)`)
  );

  /** */
  public get current_index() {
    return this._current_index$$.getValue();
  }

  /** */
  public set current_index(current_index) {
    this._current_index$$.next(current_index);
  }

  // #endregion

  /** */
  public weather_devices$$ = this.apiary$$.pipe(switchMap(apiary => apiary?.devices__for_weather_data$$ ?? of<DRDevice[]>([])));

  /**
   * Observe the most recent communication date of the weather devices.
   *
   * @public
   * @replay
   */
  public weather_devices_most_recent_date$$ = this.weather_devices$$.pipe(
    switchMap((devices: DRDevice[]) => combineLatest(devices.map(device => device.last_contact$$))),
    map(last_contact_dates => last_contact_dates.filter(last_contact_date => !isNil(last_contact_date))),
    map(last_contact_dates => max(last_contact_dates)),
    replay()
  );

  /** */
  public worst_weather_devices_observation_state$$ = this.weather_devices$$.pipe(
    switchMap(devices => combineLatest(devices.map(device => device.observation_state$$))),
    map(observation_states =>
      orderBy(observation_states, obs_state => ['have_issue', 'need_check', 'ok', null, undefined].indexOf(obs_state.state as any))
    ),
    map(observation_states => observation_states?.[0]),
    replay()
  );

  /** */
  public worst_weather_devices_statuses = {
    /** */
    worst_status_bat$$: this.weather_devices$$.pipe(
      switchMap(devices => combineLatest(devices.map(device => device.status_bat$$))),
      map(battery_statuses => find_worst_device_status_in(battery_statuses)),
      replay()
    ),
    /** */
    worst_status_bat_simplified$$: this.weather_devices$$.pipe(
      switchMap(devices => combineLatest(devices.map(device => device.battery_simplified_state$$))),
      map(battery_statuses => find_worst_device_status_simplified_in(battery_statuses)),
      replay()
    ),
    /** */
    worst_status_868$$: this.weather_devices$$.pipe(
      switchMap(devices => combineLatest(devices.map(device => device.status_868$$))),
      map(battery_statuses => find_worst_device_status_in(battery_statuses)),
      replay()
    ),
    /** */
    worst_status_gprs$$: this.weather_devices$$.pipe(
      switchMap(devices => combineLatest(devices.map(device => device.status_gprs$$))),
      map(battery_statuses => find_worst_device_status_in(battery_statuses)),
      replay()
    ),
    /** */
    worst_status_gps$$: this.weather_devices$$.pipe(
      switchMap(devices => combineLatest(devices.map(device => device.status_gps$$))),
      map(battery_statuses => find_worst_device_status_in(battery_statuses, 'gps')),
      replay()
    ),
  };

  // #region -> (data management)

  /**
   * Observes the apiary's last weather data.
   */
  private last_weather_data$$: Observable<LastWeatherData> = this.apiary$$.pipe(switchMap((apiary: Apiary) => apiary.last_weather_data$$));

  /**
   * Observes if the last weather data of apiary is outdated.
   */
  public is_last_weather_data_outdated$$: Observable<boolean> = of(new Date()).pipe(
    switchMap(current_date =>
      this.last_weather_data$$.pipe(
        map(last_weather_data => {
          if (isNil(last_weather_data)) {
            return false;
          }

          return differenceInHours(current_date, parseDate(last_weather_data?.end)) > 48;
        })
      )
    ),
    distinctUntilRealChanged(),
    replay()
  );

  /**
   * Observes the weather station most recent measured temperature.
   */
  public last_temperature_data$$ = this.apiary$$.pipe(switchMap(apiary => apiary.last_weather_temperature$$));

  /**
   * Observes the apiary's last humidity data.
   */
  public last_humidity_data$$ = this.apiary$$.pipe(switchMap(apiary => apiary.last_weather_humidity$$));

  /**
   * Observes the apiary's last pressure data.
   */
  public last_pressure_data$$: Observable<number> = this.apiary$$.pipe(switchMap(apiary => apiary.last_weather_pressure$$));

  /**
   * Observes the apiary's last wind data.
   */
  public last_wind_data$$ = this.apiary$$.pipe(switchMap(apiary => apiary.last_weather_wind$$));

  // #endregion
}
