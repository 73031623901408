export class Stack<T> {
  private _items: T[] = [];

  /**
   * Add a new item to the stack
   *
   * @param item The item to add to the stack
   */
  public push(item: T): void {
    this._items.push(item);
  }

  /**
   * Returns and removes the last added element to the stack
   *
   * @note To not delete the element see peek()
   */
  public pop(): T {
    if (this._items.length === 0) {
      return null;
    }

    return this._items.pop();
  }

  /**
   * Returns the last added element to the stack
   *
   * @note To delete the element see pop()
   */
  public peek(): T {
    return this._items[this._items.length - 1];
  }

  /**
   * Check if the stack is empty
   */
  public isEmpty(): boolean {
    return this.size() === 0;
  }

  public size(): number {
    return this._items.length;
  }
}
