import { AfterViewInit, ChangeDetectionStrategy, Component, Input, NgZone, OnDestroy } from '@angular/core';

import { AppStateService } from 'app/core/app-state.service';
import { ConsoleLoggerService } from 'app/core/console-logger.service';
import { D3SharedCursorService } from 'app/core/services/global/d3-shared-cursor.service';

import { ResizedEvent } from 'app/misc/directives/resized/resized.directive';
import { ChartGeoposition } from '@bg2app/models/charts';

import { AnyOfDeviceJobData, CPTJobData } from 'app/models/data/data-classic';
import { DateTime } from 'luxon';
import { CPTJobDataD3ChartFactory } from './cpt-job-data-d3-chart-factory';

@Component({
  selector: 'bg2-cpt-job-data-d3-chart',
  templateUrl: './cpt-job-data-d3-chart.component.html',
  styleUrls: ['./cpt-job-data-d3-chart.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CPTJobDataD3ChartComponent implements AfterViewInit, OnDestroy {
  // #region -> (component basics)

  private readonly _logger = new ConsoleLoggerService('CPTJobDataD3ChartComponent', true);

  constructor(private _d3_shared_cursor: D3SharedCursorService, private _appState: AppStateService, private _ngZone: NgZone) {
    this.chart_factory = new CPTJobDataD3ChartFactory(this._d3_shared_cursor, this._appState, this._ngZone);
  }

  ngAfterViewInit(): void {
    this.chart_factory.create_chart();
  }

  ngOnDestroy(): void {
    this.chart_factory.destroy();
  }

  // #endregion

  // #region -> (container size management)

  public onChartContainerResized(resized_event: ResizedEvent): void {
    this.chart_factory.box_sizing = { width: resized_event?.new_width, height: resized_event?.new_height };
  }

  // #endregion

  // #region -> (data management)

  /** */
  @Input()
  public set data(data: AnyOfDeviceJobData) {
    this.chart_factory.incoming_data = <CPTJobData>data;
  }

  /** */
  @Input()
  public set date_range(date_range: { start: DateTime; end: DateTime }) {
    this.chart_factory.date_range = date_range;
  }

  /** */
  @Input()
  public set is_loading(is_loading: boolean) {
    this.chart_factory.is_loading = is_loading;
  }

    /** */
    @Input()
    public set geoposition(geoposition: ChartGeoposition) {
      this.chart_factory.geoposition.value = geoposition;
    }

  // #endregion

  // #region -> (chart management)

  /** */
  public chart_factory: CPTJobDataD3ChartFactory = null;

  // #endregion
}
