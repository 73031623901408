import { AfterViewInit, ChangeDetectionStrategy, Component, HostListener, NgZone, OnDestroy, OnInit } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';
import { marker as i18n } from '@biesbjerg/ngx-translate-extract-marker';

import { has, isEmpty, isNil, merge, uniqBy } from 'lodash-es';

import { BehaviorSubject, combineLatest, concat, debounceTime, filter, map, Observable, of, Subscription, switchMap, tap } from 'rxjs';
import { anyTrue, distinctUntilRealChanged, replay, waitForNotNilValue } from '@bg2app/tools/rxjs';

import { Beeguard2Api } from 'app/core';
import { DialogsService } from 'app/widgets/dialogs-modals';
import { AppStateService } from 'app/core/app-state.service';
import { ZohoAuthService } from 'app/core/services/zoho/zoho-auth.service';
import { ZohoDeskApiService } from 'app/core/services/zoho/zoho-desk-api.service';

import { parseDate } from 'app/misc/tools';
import { FullscreenSelectHelper } from '@bg2app/tools/misc';
import { SearchContactQueryParams, SearchTicketQueryParams, ZohoDeskAgent, ZohoDeskContact, ZohoDeskTicket } from 'app/models/zoho/desk';

import { EfSelectOptions, EfSelectWidgetComponent } from '../select/select.widget';
import { Dictionary } from 'app/typings/core/interfaces';

export interface ZohoSearchDeskSelectOptions extends EfSelectOptions {
  /** */
  zoho_search_config: {
    /** */
    search_in: 'tickets' | 'contacts' | 'agents' | null;

    /** */
    tickets_config?: {
      accountId?: string;
    };

    /**
     * Configuration of contacts search.
     */
    contacts_config?: {
      /**
       * Limit contacts search to DESK account name.
       */
      limit_to_account_name?: string;
    };
  };
}

@Component({
  selector: 'bg2-zoho-search-desk-widget',
  templateUrl: './zoho-search-desk-widget.component.html',
  styleUrls: ['./zoho-search-desk-widget.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ZohoSearchDeskWidgetComponent extends EfSelectWidgetComponent implements OnInit, AfterViewInit, OnDestroy {
  // #region -> (component basics)

  public options: ZohoSearchDeskSelectOptions = {
    img: false,
    img_prefix: 'select/',
    items: {},
    readonly: false,
    indent: false,
    clearable: true,
    reset_btn: false,
    auto_scroll: true,

    zoho_search_config: {
      search_in: null,
    },
  };

  /** */
  private _widget_value_sub: Subscription = null;

  constructor(
    public appState: AppStateService,

    protected bg2Api: Beeguard2Api,
    protected translate: TranslateService,
    protected dialogs: DialogsService,
    protected readonly _ng_zone: NgZone,

    private readonly _zohoAuthService: ZohoAuthService,
    private readonly _zohoDeskApiService: ZohoDeskApiService
  ) {
    super(bg2Api, translate, appState, dialogs, _ng_zone);
  }

  ngOnInit(): void {
    super.ngOnInit();

    // Loads search contacts config
    this._search_contact_config$$.next({
      limit_to_account_name: !isNil(this.options?.zoho_search_config?.contacts_config?.limit_to_account_name),
    });

    // Loads search tickets config
    this._search_ticket_config$$.next({
      limit_to_account_id: !isNil(this.options?.zoho_search_config?.tickets_config?.accountId),
    });

    /** */
    this._widget_value_sub = concat(of(this.formProperty?.value), this.formProperty.valueChanges)
      .pipe(distinctUntilRealChanged())
      .subscribe({
        next: current_value => {
          this._selected_value$$.next(current_value);
        },
      });
  }

  ngAfterViewInit(): void {
    super.ngAfterViewInit();
    this._options$$.next(this.options);
  }

  ngOnDestroy(): void {
    this._widget_value_sub?.unsubscribe();
  }

  // #endregion

  // #region -> (zoho auth state)

  /** */
  public is_authenticated$$ = this._zohoAuthService.is_authenticated$$;

  /** */
  public login(): void {
    this._zohoAuthService.login();
  }

  // #endregion

  // #region -> (fullscreen management)

  /** */
  public fullscreen_select_helper = new FullscreenSelectHelper(this._ng_zone);

  // #endregion

  // #region -> (loading)

  /** */
  private _is_loading$$ = new BehaviorSubject<boolean>(false);

  /** */
  public is_loading$$ = this._is_loading$$.asObservable().pipe(distinctUntilRealChanged(), replay());

  /** */
  private set is_loading(is_loading: boolean) {
    this._is_loading$$.next(is_loading);
  }

  // #endregion

  // #region -> (widget options)

  /** */
  private _options$$ = new BehaviorSubject<ZohoSearchDeskSelectOptions>(null);

  /** */
  public options$$ = this._options$$.asObservable().pipe(waitForNotNilValue());

  /** */
  public zoho_config_options$$ = this.options$$.pipe(
    map(options => options?.zoho_search_config),
    waitForNotNilValue(),
    replay()
  );

  // #endregion

  // #region -> (search contact config)

  /** */
  private _search_contact_config$$ = new BehaviorSubject<{
    limit_to_account_name: boolean;
  }>(null);

  /** */
  public search_contact_config$$ = this._search_contact_config$$.asObservable();

  /** */
  public update_search_contact_config(property_name: string) {
    const previous_value = this._search_contact_config$$.getValue();

    const new_value: Dictionary<any> = {};
    new_value[property_name] = !(<any>previous_value)[property_name];

    this._search_contact_config$$.next(merge({}, previous_value, new_value));
  }

  // #endregion

  // #region -> (search ticket config)

  /** */
  private _search_ticket_config$$ = new BehaviorSubject<{
    limit_to_account_id: boolean;
  }>(null);

  /** */
  public search_ticket_config$$ = this._search_ticket_config$$.asObservable();

  /** */
  public update_search_ticket_config(property_name: string) {
    const previous_value = this._search_ticket_config$$.getValue();

    const new_value: Dictionary<any> = {};
    new_value[property_name] = !(<any>previous_value)[property_name];

    this._search_ticket_config$$.next(merge({}, previous_value, new_value));
  }

  // #endregion

  // #region -> (search config)

  /** */
  public has_search_contact_config$$ = this.zoho_config_options$$.pipe(
    map(zoho_config_options => {
      const contacts_search = zoho_config_options?.contacts_config;

      return !isNil(contacts_search?.limit_to_account_name);
    })
  );

  /** */
  public has_search_ticket_config$$ = this.zoho_config_options$$.pipe(
    map(zoho_config_options => {
      const tickets_search = zoho_config_options?.tickets_config;

      return !isNil(tickets_search?.accountId);
    })
  );

  /** */
  public has_search_config$$ = anyTrue(this.has_search_contact_config$$, this.has_search_ticket_config$$);

  /** */
  public search_config$$ = combineLatest({
    search_ticket_config: this.search_ticket_config$$,
    search_contact_config: this.search_contact_config$$,
  });

  // #endregion

  // #region -> (search)

  /** */
  public raw_filter$$ = new BehaviorSubject<string>('');

  /** */
  public criteria$$: Observable<string> = this.raw_filter$$.pipe(
    distinctUntilRealChanged(),
    debounceTime(400),
    map(raw_filter => {
      if (isNil(raw_filter)) {
        return '';
      }

      return raw_filter;
    }),
    filter(criteria => !isNil(criteria) && ((criteria ?? '')?.length >= 3 || (criteria ?? '')?.length === 0)),
    distinctUntilRealChanged(),
    replay()
  );

  // #endregion

  // #region -> (requesting result)

  /** */
  private _selected_value$$ = new BehaviorSubject<string>(null);

  /** */
  public selected_value_id$$ = this._selected_value$$.asObservable().pipe(
    map(selected_value => {
      if (isNil(selected_value) || isEmpty(selected_value)) {
        return null;
      }

      return selected_value;
    }),
    distinctUntilRealChanged(),
    replay()
  );

  /** */
  public selected_value_object$$: Observable<any> = this.selected_value_id$$.pipe(
    switchMap(selected_value_id => {
      if (isNil(selected_value_id) || isEmpty(selected_value_id)) {
        return of(null);
      }

      return this._zohoDeskApiService.fetch_module$(<any>this?.options?.zoho_search_config?.search_in, selected_value_id);
    }),
    replay()
  );

  /** */
  private request_users$$ = combineLatest({
    criteria: this.criteria$$,
    config: this.search_config$$,
  }).pipe(
    distinctUntilRealChanged(),
    tap(() => (this.is_loading = true)),
    switchMap(({ criteria, config }) => {
      if (this.options?.zoho_search_config?.search_in === 'tickets') {
        const query_params: SearchTicketQueryParams = {
          _all: criteria,
        };

        if (config?.search_ticket_config?.limit_to_account_id) {
          const refine_by_account_id = this.options?.zoho_search_config?.tickets_config?.accountId;

          if (!isNil(refine_by_account_id)) {
            query_params.accountId = refine_by_account_id;
          }
        }

        return this._zohoDeskApiService.search_tickets(query_params, { statusType: ['Open', 'On Hold'] });
      }

      if (this.options?.zoho_search_config?.search_in === 'contacts') {
        const query_params: SearchContactQueryParams = {
          _all: criteria,
        };

        if (config?.search_contact_config?.limit_to_account_name) {
          const refine_by_account_name = this.options?.zoho_search_config?.contacts_config?.limit_to_account_name;

          if (!isNil(refine_by_account_name)) {
            query_params.accountName = refine_by_account_name;
          }
        }

        return this._zohoDeskApiService.search_in_contacts$(query_params);
      }

      return this._zohoDeskApiService.search_objects_globally(<any>this?.options?.zoho_search_config?.search_in, criteria);
    }),
    switchMap(search_value => this.selected_value_object$$.pipe(map(selected_value => ({ search_value, selected_value })))),
    map(({ search_value, selected_value }) => {
      this._total$$.next((<any>search_value)?.count ?? search_value?.data?.length ?? 0);

      const search_data = search_value?.data ?? [];
      const response = uniqBy(
        [selected_value, ...search_data].filter(value => !isNil(value)),
        'id'
      );

      return response.map(result => {
        if (isNil(result)) {
          return result;
        }

        if (has(result, 'modifiedTime')) {
          (<any>result).modifiedTime = parseDate((<any>result).modifiedTime);
        }

        if (this.options?.zoho_search_config?.search_in === 'tickets') {
          (<any>result).onholdTime = parseDate((<any>result).onholdTime);
        }

        return result;
      });
    }),
    tap(() => (this.is_loading = false)),
    replay()
  );

  /** */
  public users$$: Observable<any[]> = this.request_users$$.pipe(replay());

  // #endregion

  // #region -> (total loaded items)

  /** */
  private _total$$ = new BehaviorSubject(0);

  /** */
  public current_loaded_sentence$$ = combineLatest([this.users$$, this._total$$.asObservable()]).pipe(
    switchMap(([users, total]) => {
      let key = i18n<string>('WIDGETS.EVENT_FORM.ZOHO_SEARCH.[loaded] out of [total] items loaded');

      if (this.options.zoho_search_config.search_in === 'agents') {
        key = i18n<string>('WIDGETS.EVENT_FORM.ZOHO_SEARCH.[loaded] out of [total] agents found');
      }

      if (this.options.zoho_search_config.search_in === 'tickets') {
        key = i18n<string>('WIDGETS.EVENT_FORM.ZOHO_SEARCH.[loaded] out of [total] tickets found');
      }

      if (this.options.zoho_search_config.search_in === 'contacts') {
        key = i18n<string>('WIDGETS.EVENT_FORM.ZOHO_SEARCH.[loaded] out of [total] contacts found');
      }

      return this.translate.stream(key, {
        total,
        loaded: users?.length,
      });
    }),
    distinctUntilRealChanged(),
    replay()
  );

  // #endregion

  // #region -> (search configuration)

  // #endregion

  public asType(value: any, type: 'agents'): ZohoDeskAgent;
  public asType(value: any, type: 'tickets'): ZohoDeskTicket;
  public asType(value: any, type: 'contacts'): ZohoDeskContact;
  public asType(value: any, type: 'agents' | 'tickets' | 'contacts'): any {
    if (value instanceof ZohoDeskAgent) {
      return value;
    }

    if (value instanceof ZohoDeskContact) {
      return value;
    }

    if (value instanceof ZohoDeskTicket) {
      return value;
    }

    return null;
  }
}
