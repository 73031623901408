import { NgZone } from '@angular/core';

import * as d3 from 'd3';
import { isEmpty, isNil, max } from 'lodash-es';
import { marker as i18n } from '@biesbjerg/ngx-translate-extract-marker';

import { AppStateService } from 'app/core/app-state.service';
import { ConsoleLoggerService } from 'app/core/console-logger.service';
import { D3SharedCursorService } from 'app/core/services/global/d3-shared-cursor.service';

import { CPTJobDataPoint, CPTJobData } from 'app/models/data';

import {
  AbstractD3Axes,
  AbstractD3Grid,
  AbstractD3Labels,
  AbstractD3Scales,
  AbstractUserEvents,
  AbstractDataObjects,
  AbstractEventObjects,
  D3SvgBuilderLuxon,
} from '@bg2app/models/charts';

/** */
interface Axes extends AbstractD3Axes {
  /** */
  count: {
    /** */
    axis: d3.Axis<d3.AxisDomain>;

    /** */
    container: d3.Selection<SVGGElement, unknown, HTMLElement, any>;
  };

  /** */
  middle: {
    /** */
    container: d3.Selection<SVGGElement, unknown, HTMLElement, any>;

    /** */
    line: d3.Selection<SVGLineElement, unknown, HTMLElement, any>;
  };
}

/** */
interface Scales extends AbstractD3Scales {
  /** */
  count: d3.ScaleLinear<number, number, never>;
}

/** */
interface Events extends AbstractUserEvents {
  /** */
  count: {
    /** */
    text: d3.Selection<SVGGElement, unknown, HTMLElement, any>;

    /** */
    container: d3.Selection<HTMLDivElement, unknown, HTMLElement, any>;
  };

  /** */
  per_hive: {
    /** */
    [key: number]: {
      /** */
      circle: d3.Selection<SVGCircleElement, unknown, HTMLElement, any>;
    };
  };
}

/** */
interface DataObjects extends AbstractDataObjects {
  /** */
  count_in: {
    line: {
      /** */
      shape: d3.Line<CPTJobDataPoint>;

      /** */
      path: d3.Selection<SVGPathElement, unknown, HTMLElement, any>;
    };

    /** */
    circle: d3.Selection<SVGCircleElement, unknown, HTMLElement, any>;
  };

  /** */
  count_out: {
    line: {
      /** */
      shape: d3.Line<CPTJobDataPoint>;

      /** */
      path: d3.Selection<SVGPathElement, unknown, HTMLElement, any>;
    };

    /** */
    circle: d3.Selection<SVGCircleElement, unknown, HTMLElement, any>;
  };
}

/** */
interface Labels extends AbstractD3Labels {
  /** */
  labels: {
    /** */
    count: d3.Selection<SVGTextElement, unknown, HTMLElement, unknown>;

    /** */
    count_in: d3.Selection<SVGTextElement, unknown, HTMLElement, unknown>;

    /** */
    count_out: d3.Selection<SVGTextElement, unknown, HTMLElement, unknown>;
  };
}

/** */
export class CPTJobDataD3ChartFactory extends D3SvgBuilderLuxon<CPTJobData> {
  // #region -> (properties)

  public axes: Axes = {
    container: null,
    time: {
      axis: null,
      container: null,
    },
    count: {
      axis: null,
      container: null,
    },
    middle: {
      line: null,
      container: null,
    },
  };

  public scales: Scales = {
    time: null,
    count: null,
  };

  public data_objects: DataObjects = {
    container: null,

    count_in: {
      line: {
        shape: d3
          .line<CPTJobDataPoint>()
          .defined(point => !isNil(point?.count_in))
          .curve(d3.curveLinear),
        path: null,
      },
      circle: null,
    },

    count_out: {
      line: {
        shape: d3
          .line<CPTJobDataPoint>()
          .defined(point => !isNil(point?.count_out))
          .curve(d3.curveLinear),
        path: null,
      },
      circle: null,
    },
  };

  public event_objects: AbstractEventObjects = {
    container: null,
  };

  public user_events: Events = {
    container: null,
    event_bounds: null,

    focus_line_x: null,
    focus_line_y: null,

    count: {
      text: null,
      container: null,
    },

    per_hive: {},
  };

  public grids: AbstractD3Grid = {
    container: null,

    time: {
      axis: null,
      container: null,
    },

    day_cycle: null,
  };

  public labels: Labels = {
    container: null,
    labels: {
      count: null,
      count_in: null,
      count_out: null,
    },
  };

  // #endregion

  // #region -> (component basics)

  /** */
  protected LOGGER: ConsoleLoggerService = new ConsoleLoggerService('CPTJobDataD3ChartFactory', true);

  /** */
  constructor(protected _shared_cursor: D3SharedCursorService, protected _appState: AppStateService, protected _ngZone: NgZone) {
    super(_shared_cursor, _appState, _ngZone);

    this.margins.left = 45;
    this.margins.right = 20;
  }

  /** */
  public destroy(): void {
    super.destroy();
  }

  // #endregion

  // #region -> (basic overrides)

  /** */
  protected colors: { [key in 'count_in' | 'count_out']: `#${string}` } = {
    /** */
    count_in: '#008300',
    /** */
    count_out: '#d90000',
  };

  /** */
  public get has_data() {
    const data = this.incoming_data?.points;

    return !isEmpty(data ?? []);
  }

  // #endregion

  // #region -> (creation methods)

  protected create_labels(): void {
    super.create_labels();

    this.labels.labels.count = this.labels.container
      .append('text')
      .text(this._appState.translate.instant(i18n<string>('ALL.DATA.LABELS_SHORT_WITH_UNIT.Bees (total passes)')))
      .attr('transform', 'rotate(-90)')
      .attr('font-size', '10px')
      .attr('font-weight', 'bold')
      .attr('fill', 'black')
      .attr('text-anchor', 'middle')
      .attr('x', -(this.calc_size_of(this.box_sizing.height, ['-top', '-bottom']) / 2))
      .attr('y', -35);

    this.labels.labels.count_in = this.labels.container
      .append('text')
      .text(this._appState.translate.instant(i18n<string>('ALL.TIMESERIES.Count in')))
      .attr('transform', 'rotate(-90)')
      .attr('font-size', '10px')
      .attr('font-weight', 'bold')
      .attr('fill', 'black')
      .attr('text-anchor', 'right')
      .attr('y', 10);
    this.labels.labels.count_in.attr('x', () => {
      const item_width = this.labels.labels.count_in.node().getBBox().width;
      return -item_width - 5;
    });

    this.labels.labels.count_out = this.labels.container
      .append('text')
      .text(this._appState.translate.instant(i18n<string>('ALL.TIMESERIES.Count out')))
      .attr('transform', 'rotate(-90)')
      .attr('font-size', '10px')
      .attr('font-weight', 'bold')
      .attr('fill', 'black')
      .attr('text-anchor', 'left')
      .attr('x', -this.box_sizing.height + this.margins.bottom + this.margins.top + 5)
      .attr('y', 10);
  }

  /** */
  protected create_scales(): void {
    super.create_scales();

    this.scales.count = d3
      .scaleLinear()
      .domain([0, 150])
      .rangeRound([this.calc_size_of(this.box_sizing?.height, ['-top', '-bottom']), 0]);
  }

  /** */
  protected create_axes(): void {
    super.create_axes();

    this.axes.middle.container = this.axes.container
      .append('g')
      .attr('class', 'd3-middle-axis')
      .attr('transform', `translate(${this.calc_size_of(0, ['+left'])}, ${this.calc_size_of(0, ['+top'])})`);
    this.axes.middle.line = this.axes.middle.container
      .append('line')
      .style('shape-rendering', 'crispEdges')
      .style('stroke-width', '1px')
      .style('stroke', '#d0d0d0');

    this.axes.count.axis = d3.axisLeft(this.scales.count);
    this.axes.count.container = this.axes.container
      .append('g')
      .attr('class', 'd3-Y-axis')
      .attr('transform', `translate(${this.calc_size_of(0, ['+left'])}, ${this.calc_size_of(0, ['+top'])})`)
      .call(this.axes.count.axis);

    this.apply_axes_format();
  }

  /** */
  public build_event_objects(): void {
    this.user_events.focus_line_y = this.create_focus_line('y', this.user_events.container);

    this.user_events.count.container = this.create_html_tooltip(this.svg_parent);

    // Build count_in line path
    this.data_objects.count_in.line.path = this.data_objects.container
      .append('path')
      .style('fill', 'none')
      .style('stroke', this.colors.count_in)
      .style('stroke-width', '2px');

    // Build count_out circle
    this.data_objects.count_in.circle = this.user_events.container
      .append('circle')
      .attr('r', 4)
      .style('display', 'none')
      .attr('pointer-events', 'none')
      .attr('class', 'd3-focus-circle')
      .attr('fill', this.colors.count_in);

    // Build count_out line path
    this.data_objects.count_out.line.path = this.data_objects.container
      .append('path')
      .style('fill', 'none')
      .style('stroke', this.colors.count_out)
      .style('stroke-width', '2px');

    // Build count_out circle
    this.data_objects.count_out.circle = this.user_events.container
      .append('circle')
      .attr('r', 4)
      .style('display', 'none')
      .attr('pointer-events', 'none')
      .attr('class', 'd3-focus-circle')
      .attr('fill', this.colors.count_out);
  }

  // #endregion

  // #region -> (updating methods)

  /** */
  protected update_axes(): void {
    super.update_axes();

    this.axes.count.axis.scale(this.scales.count).ticks(5);
    this.axes.count.container
      .transition()
      .duration(500)
      .call(this.axes.count.axis)
      .attr('transform', `translate(${this.margins.left}, ${this.margins.top})`);

    this.axes.middle.line.attr('y1', this.scales.count(0)).attr('y2', this.scales.count(0));
  }

  /** */
  public resize(): void {
    this.scales.count.rangeRound([this.calc_size_of(this.box_sizing.height, ['-top', '-bottom']), 0]);

    this.axes.middle.line.attr('x1', 0).attr('x2', this.calc_size_of(this.box_sizing.width, ['-left', '-right']));

    this.labels.labels.count.attr('x', -(this.calc_size_of(this.box_sizing.height, ['-top', '-bottom']) / 2));

    super.resize();
  }

  /** */
  public append_data(is_after_resize: boolean): void {
    this.scales.time.domain([this.date_range.start, this.date_range.end]);

    this.data_objects.count_in?.line?.path.attr('d', '');
    this.data_objects.count_out?.line?.path.attr('d', '');

    if (!this.has_data) {
      this.scales.count.domain([-150, 150]);

      super.append_data(is_after_resize);
      return;
    }

    const points: CPTJobDataPoint[] = this.incoming_data?.points;

    // Search max count value
    const max_count_value = d3.max(
      points.filter(beecount_data_point => !isNil(beecount_data_point.count_in) || !isNil(beecount_data_point?.count_out)),
      _point => max([_point?.count_in ?? 0, _point?.count_out ?? 0])
    );

    this.scales.count.domain([-max_count_value, max_count_value]);

    // Update data of path (count_in)
    this.data_objects.count_in.line.shape.x(point => this.scales.time(point.tz_date)).y(point => this.scales.count(point.count_in));
    this.data_objects.count_in.line.path.attr('d', this.data_objects.count_in.line.shape(points));

    // Update data of path (count_out)
    this.data_objects.count_out.line.shape.x(_point => this.scales.time(_point.tz_date)).y(_point => this.scales.count(-_point.count_out));
    this.data_objects.count_out.line.path.attr('d', this.data_objects.count_out.line.shape(points));

    super.append_data(is_after_resize);
  }

  // #endregion

  // #region -> (user-events management)

  /** */
  public on_mouse_enter(is_from_shared_cursor = false): void {
    super.on_mouse_enter(is_from_shared_cursor);

    if (!this.has_data) {
      return null;
    }

    this.user_events.count.container.style('display', null);

    this.data_objects?.count_in?.circle.style('display', null);
    this.data_objects?.count_out?.circle.style('display', null);
  }

  /** */
  public on_mouse_move(event: Date | MouseEvent, data: CPTJobData, is_from_shared_cursor?: boolean): void {
    if (!this.has_data) {
      return null;
    }

    let pointed_position_x: number = null;
    let pointed_position_y: number = null;

    if (is_from_shared_cursor) {
      pointed_position_x = this.scales.time(event as Date);
    } else {
      const source_element = (event as MouseEvent).target as SVGGElement;
      const boundings = source_element.getBoundingClientRect();

      pointed_position_x = (event as MouseEvent).clientX - boundings.left;
      pointed_position_y = (event as MouseEvent).clientY - boundings.top;
    }

    const pointed_date = this.scales.time.invert(pointed_position_x);

    // Calculate closest point
    const index = d3.bisectCenter(
      data?.points?.map(v => v.tz_date),
      this.scales.time.invert(pointed_position_x)
    );

    const closest_point = data?.points?.[index] ?? null;

    if (!is_from_shared_cursor) {
      this._shared_cursor.shared_cursor = {
        date: closest_point?.tz_date,
        from: this.unique_id,
        event_type: 'mousemove',
      };
    }

    let template = this.create_tooltip_header(closest_point?.date);

    template += `<div class="d3-chart-tooltip-list-item">`;

    template += '<div class="d3-chart-tooltip-series-name">';
    template += `<span class="mdi mdi-minus-thick" style="color: ${this.colors.count_in}"></span>`;
    template += `${this._appState.translate.instant(i18n<string>('ALL.DATA.LABELS_FULL.Bees'))}`;
    template += '</div>';

    template += '<div class="d3-chart-tooltip-value">';
    template += `${closest_point?.count_in?.toFixed(0) ?? '?'} ${this._appState.translate.instant(
      i18n<string>('VIEWS.DEVICES.SHARED.CHARTS.CPT_JOB_DATA_D3_CHART.TOOLTIP.entered')
    )}`;
    template += '</div>';

    template += '</div>';

    template += `<div class="d3-chart-tooltip-list-item">`;

    template += '<div class="d3-chart-tooltip-series-name">';
    template += `<span class="mdi mdi-minus-thick" style="color: ${this.colors.count_out}"></span>`;
    template += `${this._appState.translate.instant(i18n<string>('ALL.DATA.LABELS_FULL.Bees'))}`;
    template += '</div>';

    template += '<div class="d3-chart-tooltip-value">';
    template += `${closest_point?.count_out?.toFixed(0) ?? '?'} ${this._appState.translate.instant(
      i18n<string>('VIEWS.DEVICES.SHARED.CHARTS.CPT_JOB_DATA_D3_CHART.TOOLTIP.went out')
    )}`;
    template += '</div>';

    template += '</div>';

    // Update tooltip position
    const is_in_first_half = pointed_position_x < this.user_events.event_bounds.node().width.baseVal.value / 2;
    if (is_in_first_half) {
      this.user_events.count.container
        .html(template)
        .style('left', `${this.calc_size_of(pointed_position_x + 10, ['+left'])}px`)
        .style('top', `${this.user_events.event_bounds.node().getBBox().height / 2}px`);
    } else {
      this.user_events.count.container
        .html(template)
        .style('left', `${pointed_position_x + this.margins.left - 1 - this.user_events.count.container.node().clientWidth - 10}px`)
        .style('top', `${this.user_events.event_bounds.node().getBBox().height / 2}px`);
    }

    // Update the focus_line_x position
    this.user_events.focus_line_y
      .attr('x1', this.scales.time(this.scales.time.invert(pointed_position_x)))
      .attr('x2', this.scales.time(this.scales.time.invert(pointed_position_x)));

    // Update circle positions (depending on date)
    if (isNil(closest_point?.tz_date)) {
      this.data_objects.count_in.circle.style('display', 'none');
      this.data_objects.count_out.circle.style('display', 'none');
    }

    // Update circle (count_in)
    if (isNil(closest_point?.count_in)) {
      this.data_objects.count_in.circle.style('display', 'none');
    } else {
      this.data_objects.count_in.circle.style('display', null);
      this.data_objects.count_in.circle.attr(
        'transform',
        `translate(${this.scales.time(closest_point.tz_date.getTime())},${this.scales.count(closest_point.count_in)})`
      );
    }

    // Update circle (count_out)
    if (isNil(closest_point?.count_out)) {
      this.data_objects.count_out.circle.style('display', 'none');
    } else {
      this.data_objects.count_out.circle.style('display', null);
      this.data_objects.count_out.circle.attr(
        'transform',
        `translate(${this.scales.time(closest_point.tz_date.getTime())},${this.scales.count(-closest_point.count_out)})`
      );
    }
  }

  /** */
  public on_mouse_out(is_from_shared_cursor = false): void {
    super.on_mouse_out(is_from_shared_cursor);

    this.user_events.count.container.style('display', 'none');

    this.data_objects?.count_in?.circle.style('display', 'none');
    this.data_objects?.count_out?.circle.style('display', 'none');
  }

  // #endregion
}
