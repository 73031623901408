<div class="app-update-snack-content">
  <span class="desc">
    {{ 'VIEWS_WINDOWED.SHARED.COMPONENTS.APP_UPDATE_SNACK.New update is available, please reload the app' | translate }}
  </span>

  <div class="actions">
    <span class="button reload-now" (click)="reloadNow()">
      {{ 'VIEWS_WINDOWED.SHARED.COMPONENTS.APP_UPDATE_SNACK.Reload now' | translate }}
    </span>

    <span class="button reload-later" (click)="reloadLater()">
      {{ 'VIEWS_WINDOWED.SHARED.COMPONENTS.APP_UPDATE_SNACK.I will reload later' | translate }}
    </span>
  </div>
</div>
