import { ChangeDetectionStrategy, Component, EventEmitter, Inject, Input, Output } from '@angular/core';
import { ENV } from 'app/core/providers/environment.provider';
import { create_replay_subject_with_first_value, waitForNotNilValue } from '@bg2app/tools/rxjs';
import { Location } from 'app/models';
import { IEnvironment } from 'environments/common';
import { isNil } from 'lodash-es';
import { BehaviorSubject, concat, filter, map, Observable, of, switchMap, tap } from 'rxjs';

@Component({
  selector: 'bg2-location-details-header',
  templateUrl: './location-details-header.component.html',
  styleUrls: ['./location-details-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LocationDetailsHeaderComponent {
  // #region -> (component basics)

  constructor(@Inject(ENV) public readonly env: IEnvironment) {}

  // #endregion

  @Input()
  public location_header_type: 'classic' | 'compact' | 'modal' = 'classic';

  @Output()
  public when_quit = new EventEmitter<null>();

  // #region -> (location entity)

  /** */
  private _location$$ = new BehaviorSubject<Location>(null);

  /**
   * Observes the location entity.
   */
  private location$$: Observable<Location> = this._location$$.asObservable().pipe(
    waitForNotNilValue(),
    tap(() => this.loadings.location$$.next(false))
  );

  /** */
  @Input()
  public set location(location: Location) {
    this._location$$.next(location);
  }

  /** */
  public get location(): Location {
    return this._location$$.getValue();
  }

  /**
   * Observes the location's ID.
   */
  public location_id$$ = this.location$$.pipe(
    filter(location => !isNil(location)),
    switchMap(location => location.id$$)
  );

  /**
   * Observes the location's name.
   */
  public location_name$$ = this.location$$.pipe(switchMap(location => location.name$$));

  /** */
  public location_banner_image$$ = this.location$$.pipe(switchMap(location => location.banner_image$$));

  /**
   * Observes if the location is ghost.
   */
  public is_location_is_ghost$$ = this.location$$.pipe(switchMap(location => location.is_ghost$$));

  /**
   * Observes if the location has an apiary.
   */
  public location_has_apiary$$ = this.location$$.pipe(switchMap(location => location.has_apiary$$));

  /** */
  public location_has_ace$$ = (scope: string) => this.location$$.pipe(map(location => location.hasACE(scope)));

  // #endregion

  // #region -> (related apiary entity)

  /**
   * Observes the location's apiary.
   */
  private apiary$$ = this.location$$.pipe(
    switchMap(location => location.apiary$$),
    waitForNotNilValue(),
    tap(() => this.loadings.apiary$$.next(false))
  );

  /** */
  public apiary_has_devices_for_movement_detection$$ = this.apiary$$.pipe(
    switchMap(apiary => apiary?.has_devices_for_movement_detection$$ ?? of(false))
  );

  // #endregion

  // #region -> (related exploitation entity)

  /** */
  private exploitation$$ = this.location$$.pipe(switchMap(location => location.exploitation$$));

  /** */
  public exploitation_name$$ = this.exploitation$$.pipe(switchMap(exploitation => exploitation.name$$));

  /** */
  public exploitation_id$$ = this.exploitation$$.pipe(switchMap(exploitation => exploitation.id$$));

  // #endregion

  // #region -> (active alarms management)

  /** */
  public movement_alarms$$ = this.location$$.pipe(switchMap(location => location.movement_alarms$$));

  // #endregion

  /** */
  public loadings = {
    /** */
    location$$: new BehaviorSubject(true),

    /** */
    apiary$$: new BehaviorSubject(true),
  };
}
