<div
  class="bg2-ui-message-component --type-{{ type }} --show-borders-{{ show_borders }}"
  [ngStyle]="{ height: should_take_full_height ? '100%' : 'initial' }"
  [ngClass]="{ 'fill-background': fill_background, 'invert-text-color': invert_text_color }"
>
  <ng-container [ngSwitch]="type">
    <span *ngSwitchCase="'warning'" class="mdi mdi-alert"></span>
    <span *ngSwitchCase="'info'" class="mdi mdi-information"></span>
    <span *ngSwitchCase="'alert'" class="mdi mdi-alert-circle"></span>
    <span *ngSwitchCase="'success'" class="mdi mdi-check-bold"></span>
    <span *ngSwitchCase="'solution'" class="mdi mdi-lightbulb-on"></span>
  </ng-container>

  <div class="component__full-message-container">
    <div class="full-message-container__title">{{ title }}</div>

    <div class="full-message-container__message">
      <ng-content select="[message]"></ng-content>
      <ng-content select="[buttons]"></ng-content>
      <!-- {{ messages | join : ' ' }} -->
    </div>
  </div>
</div>
