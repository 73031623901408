<div class="bg2-rg-job-data-d3-chart-component">
  <div class="rg-job-data__icons-chart" (resized)="onChartContainerResized($event, 'icons')">
    <svg [id]="icons_chart_factory.unique_id"></svg>
  </div>

  <div class="rg-job-data__wind-chart" (resized)="onChartContainerResized($event, 'wind')">
    <svg [id]="wind_chart_factory.unique_id"></svg>
  </div>

  <div class="rg-job-data__weather-chart" (resized)="onChartContainerResized($event, 'weather')">
    <svg [id]="weather_chart_factory.unique_id"></svg>
  </div>
</div>
