<ng-container *ngIf="status$$ | async as status">
  <div *ngIf="status" class="s868-level-component">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlns:svg="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 6.3499999 6.3500002"
      version="1.1"
      [popperTimeout]="100"
      [popper]="tooltipCases"
      [queryParamsHandling]="'merge'"
      [queryParams]="{ inmodal_url: 'diagnostic' }"
      [routerLink]="open_in_modal ? ['', { outlets: { modal: ['device', { imei: device_imei$$ | async }] } }] : null"
      (click)="$event.stopPropagation()"
    >
      <!-- #region -> (s868 signal) -->

      <g *ngIf="is_s868_excellent$$ | async" [attr.fill]="getColor$$('868_excellent') | async">
        <path
          id="s868_src_force_excellent"
          d="M 3.6122772,5.5014331 C 3.5941386,5.4924505 3.5833202,5.481527 3.5744655,5.4632263 3.5629977,5.4395351 3.5620532,5.4264158 3.5620278,5.2897696 3.561936,4.8717698 3.4863486,4.5287932 3.3172431,4.1791388 3.2604907,4.0617927 3.2187867,3.9885275 3.1489876,3.8835461 2.8058383,3.3674333 2.2755868,2.9947209 1.6774915,2.8492345 1.4810537,2.8014518 1.28422,2.7795026 1.0517162,2.7794546 0.91485225,2.7794271 0.90198003,2.7785018 0.87814293,2.7669602 c -0.0186948,-0.00904 -0.0291596,-0.019517 -0.0382077,-0.038211 -0.0111226,-0.022968 -0.012463,-0.03709 -0.012463,-0.130826 0,-0.093737 0.001346,-0.1078585 0.0124634,-0.1308258 0.009044,-0.018692 0.0195132,-0.029161 0.0382078,-0.03821 0.0235434,-0.011399 0.0368165,-0.012461 0.15542407,-0.012436 0.4905255,1.075e-4 0.88344,0.086136 1.288246,0.2820663 0.4623623,0.223787 0.8538786,0.5668254 1.1368847,0.9961153 0.1850075,0.2806355 0.3196285,0.5939742 0.3931767,0.9151432 0.051733,0.2258944 0.073093,0.4297925 0.073152,0.6981407 2.29e-5,0.1186066 -0.001,0.131875 -0.012439,0.1554234 -0.00904,0.018693 -0.019521,0.029161 -0.038208,0.03821 -0.022978,0.011125 -0.037078,0.012463 -0.1312233,0.01246 -0.094618,-3.9e-6 -0.1080922,-0.00131 -0.1308819,-0.01258 z"
        />

        <path
          id="s868_dst_force_excellent"
          d="m 2.7279376,0.84875761 c 0.018141,0.008986 0.028953,0.0199064 0.037812,0.0382068 0.011469,0.0236916 0.012409,0.0368098 0.012438,0.17345669 9.14e-5,0.4180001 0.075678,0.760977 0.2447839,1.1106318 0.056754,0.1173462 0.098457,0.1906115 0.1682553,0.2955929 0.3431488,0.5161134 0.8733993,0.8888258 1.4714941,1.0343124 0.1964371,0.047783 0.3932704,0.069732 0.6257736,0.06978 0.1368638,2.75e-5 0.149736,9.532e-4 0.1735729,0.012495 0.018699,0.00904 0.02916,0.019517 0.038208,0.03821 0.011121,0.022967 0.012468,0.03709 0.012468,0.1308258 0,0.093737 -0.00134,0.1078587 -0.012468,0.130826 -0.00904,0.018693 -0.019518,0.029161 -0.038208,0.03821 -0.023547,0.0114 -0.036815,0.012462 -0.1554229,0.012436 C 4.81612,3.9336328 4.4232061,3.8476037 4.0184,3.6516738 3.5560383,3.4278865 3.1645228,3.0848479 2.8815169,2.6555577 2.6965101,2.3749218 2.561889,2.0615827 2.4883411,1.7404137 2.436611,1.5145191 2.4152457,1.3106209 2.4151874,1.0422725 c -2.27e-5,-0.11860693 0.00103,-0.13187533 0.012436,-0.15542376 0.00904,-0.0186927 0.019518,-0.029161 0.03821,-0.0382107 0.022979,-0.0111249 0.037079,-0.0124634 0.131223,-0.0124585 0.094618,4e-6 0.108092,0.001297 0.1308818,0.0125795 z"
        />

        <path
          id="s868_src_force_good"
          d="M 2.8862811,5.5014353 C 2.8401553,5.4786009 2.8376423,5.4668551 2.8339404,5.2568944 2.8301459,5.0398568 2.8166707,4.9359614 2.7720711,4.7789459 2.6058815,4.1938463 2.1476353,3.7355992 1.5625369,3.5694097 1.4055212,3.5248117 1.3016266,3.5113627 1.0845886,3.5075419 c -0.16400474,-0.00289 -0.18394261,-0.00434 -0.20684791,-0.01512 -0.0184839,-0.00869 -0.0287209,-0.018895 -0.0378107,-0.037672 -0.011123,-0.022968 -0.0124634,-0.03709 -0.0124634,-0.1308259 0,-0.093736 0.001346,-0.1078585 0.0124625,-0.1308258 0.009288,-0.019175 0.0194614,-0.029136 0.0399024,-0.039028 0.0269249,-0.013036 0.0308293,-0.013218 0.20987281,-0.00983 0.1343316,0.00254 0.2069501,0.00676 0.2754076,0.016003 0.3911068,0.052798 0.7378273,0.2017212 1.0516626,0.4517088 0.076349,0.060812 0.2519423,0.2364107 0.3127551,0.3127555 0.2499876,0.3138358 0.3989101,0.6605571 0.4517086,1.051664 0.00924,0.068456 0.013458,0.1410764 0.016006,0.2754077 0.00336,0.1790426 0.0032,0.1829454 -0.00983,0.2098731 -0.00989,0.020434 -0.019858,0.03062 -0.039026,0.039901 -0.022978,0.011124 -0.037078,0.012463 -0.1312234,0.012459 -0.094618,-4.1e-6 -0.108092,-0.00131 -0.1308817,-0.01258 z"
        />

        <path
          id="s868_dst_force_good"
          d="m 3.4539331,0.84875556 c 0.046127,0.0228342 0.048641,0.0345805 0.052337,0.24454124 0.00382,0.2170377 0.01727,0.3209332 0.061867,0.4779487 0.1661891,0.5851002 0.6244349,1.0433476 1.2095324,1.2095373 0.1570151,0.044598 0.2609102,0.058047 0.4779469,0.061868 0.1640048,0.0029 0.1839426,0.00434 0.2068476,0.01512 0.018489,0.00869 0.028725,0.018895 0.037815,0.037672 0.011122,0.022967 0.012468,0.03709 0.012468,0.130826 0,0.093736 -0.00134,0.1078587 -0.012468,0.1308259 -0.00928,0.019175 -0.019467,0.029136 -0.039905,0.039029 -0.026927,0.013036 -0.03083,0.013218 -0.2098725,0.00983 C 5.1161704,3.203416 5.0435519,3.1991913 4.9750945,3.189951 4.5839882,3.1371524 4.2372681,2.9882296 3.9234332,2.7382418 3.8470888,2.6774289 3.6714912,2.5018309 3.6106786,2.4254859 3.3606917,2.11165 3.2117692,1.7649284 3.158971,1.3738211 3.1497282,1.3053641 3.1455098,1.2327447 3.1429681,1.0984133 c -0.00338,-0.17904295 -0.0032,-0.1829456 0.00983,-0.20987341 0.00989,-0.0204341 0.019854,-0.0306195 0.039029,-0.0399015 0.022979,-0.0111239 0.037079,-0.0124634 0.1312231,-0.0124585 0.094618,4.1e-6 0.1080919,0.001297 0.1308817,0.0125795 z"
        />

        <path
          id="s868_src_force_low"
          d="M 2.1612943,5.5019234 C 2.1155821,5.4792991 2.1135834,5.4712752 2.1093501,5.293189 2.1073295,5.2066742 2.1023479,5.115471 2.0983547,5.0905148 2.0746839,4.9425458 2.008196,4.7823235 1.9177742,4.6553291 1.8657457,4.5822596 1.759227,4.4757393 1.6861577,4.4237125 1.5591632,4.3332902 1.3989412,4.2668056 1.2509724,4.243132 1.2260124,4.239142 1.1348127,4.234192 1.0482984,4.232133 0.86993298,4.227893 0.86176955,4.225843 0.83944534,4.179728 0.82892114,4.157997 0.82747114,4.142258 0.82747114,4.0499187 c 0,-0.093736 0.001346,-0.1078587 0.0124634,-0.1308257 0.009278,-0.019154 0.0194712,-0.029137 0.0398264,-0.038994 0.0266634,-0.01291 0.0315454,-0.013163 0.19172266,-0.00996 0.1768864,0.00354 0.2406169,0.010739 0.3583905,0.040485 0.4880621,0.1232616 0.8777191,0.5129195 1.0009805,1.0009817 0.029741,0.117774 0.036942,0.1815046 0.040484,0.3583907 0.0032,0.160178 0.00296,0.1650536 -0.00995,0.1917229 -0.00985,0.020359 -0.019842,0.030557 -0.038992,0.039831 -0.022978,0.011124 -0.037078,0.012463 -0.1312232,0.012459 -0.093222,-4.3e-6 -0.1083115,-0.00142 -0.1298743,-0.012081 z"
        />

        <path
          id="s868_dst_force_low"
          d="m 4.1789189,0.84826746 c 0.045712,0.0226234 0.047709,0.0306477 0.051942,0.20873474 0.00206,0.086515 0.007,0.177718 0.010999,0.2026743 0.023673,0.1479692 0.090158,0.3081915 0.1805796,0.435186 0.052027,0.073069 0.1585467,0.1795899 0.2316158,0.2316167 0.1269942,0.090423 0.2872164,0.156907 0.4351847,0.1805806 0.024957,0.00399 0.1161592,0.00894 0.2026737,0.010999 0.1783649,0.00424 0.1865282,0.00629 0.2088523,0.052405 0.01052,0.021732 0.011975,0.037471 0.011975,0.1298093 0,0.093736 -0.00134,0.1078588 -0.012468,0.1308259 C 5.490993,2.450254 5.480806,2.460236 5.460443,2.470093 5.433777,2.483002 5.428898,2.483255 5.2687208,2.480053 5.0918384,2.4765086 5.0281079,2.4693105 4.9103343,2.4395656 4.4222733,2.3163039 4.0326167,1.9266456 3.9093554,1.4385831 3.8796113,1.3208089 3.8724131,1.2570783 3.8688708,1.0801921 c -0.0032,-0.16017824 -0.00296,-0.16505386 0.00995,-0.19172314 0.00985,-0.02036 0.019838,-0.0305561 0.038993,-0.0398292 0.022979,-0.0111249 0.037081,-0.0124634 0.131223,-0.0124585 0.093221,4.19e-6 0.1083115,0.001414 0.1298737,0.012081 z"
        />

        <path
          id="s868_src_force_very_low"
          d="M 1.111819,5.5045759 C 0.97879514,5.4759028 0.86472086,5.3607143 0.83657147,5.2266404 0.80599515,5.0810077 0.86743445,4.9292549 0.99087057,4.8455277 1.1002521,4.7713336 1.231817,4.7629424 1.3530017,4.8224312 c 0.064163,0.031496 0.1345559,0.1018913 0.1660514,0.1660515 0.059492,0.1211848 0.0511,0.2527497 -0.023097,0.362131 C 1.4119181,5.474513 1.2581887,5.5361265 1.111819,5.5045759 Z"
        />

        <path
          id="s868_dst_force_very_low"
          d="M 5.2283924,0.84561488 C 5.361416,0.87428803 5.47549,0.98947675 5.5036393,1.1235507 5.534212,1.2691837 5.4727739,1.4209364 5.3493405,1.5046638 5.2399591,1.5788579 5.1083948,1.5872491 4.9872099,1.5277604 4.9230497,1.496265 4.8526543,1.4258691 4.821159,1.3617088 4.7616709,1.2405238 4.7700617,1.1089588 4.8442559,0.99957745 4.9282979,0.87567821 5.082023,0.81406461 5.2283924,0.84561488 Z"
        />
      </g>

      <g *ngIf="is_s868_is_good$$ | async" [attr.fill]="getColor$$('868_good') | async">
        <path
          id="s868_src_force_excellent"
          fill="lightgray"
          d="M 3.6122772,5.5014331 C 3.5941386,5.4924505 3.5833202,5.481527 3.5744655,5.4632263 3.5629977,5.4395351 3.5620532,5.4264158 3.5620278,5.2897696 3.561936,4.8717698 3.4863486,4.5287932 3.3172431,4.1791388 3.2604907,4.0617927 3.2187867,3.9885275 3.1489876,3.8835461 2.8058383,3.3674333 2.2755868,2.9947209 1.6774915,2.8492345 1.4810537,2.8014518 1.28422,2.7795026 1.0517162,2.7794546 0.91485225,2.7794271 0.90198003,2.7785018 0.87814293,2.7669602 c -0.0186948,-0.00904 -0.0291596,-0.019517 -0.0382077,-0.038211 -0.0111226,-0.022968 -0.012463,-0.03709 -0.012463,-0.130826 0,-0.093737 0.001346,-0.1078585 0.0124634,-0.1308258 0.009044,-0.018692 0.0195132,-0.029161 0.0382078,-0.03821 0.0235434,-0.011399 0.0368165,-0.012461 0.15542407,-0.012436 0.4905255,1.075e-4 0.88344,0.086136 1.288246,0.2820663 0.4623623,0.223787 0.8538786,0.5668254 1.1368847,0.9961153 0.1850075,0.2806355 0.3196285,0.5939742 0.3931767,0.9151432 0.051733,0.2258944 0.073093,0.4297925 0.073152,0.6981407 2.29e-5,0.1186066 -0.001,0.131875 -0.012439,0.1554234 -0.00904,0.018693 -0.019521,0.029161 -0.038208,0.03821 -0.022978,0.011125 -0.037078,0.012463 -0.1312233,0.01246 -0.094618,-3.9e-6 -0.1080922,-0.00131 -0.1308819,-0.01258 z"
        />

        <path
          id="s868_dst_force_excellent"
          fill="lightgray"
          d="m 2.7279376,0.84875761 c 0.018141,0.008986 0.028953,0.0199064 0.037812,0.0382068 0.011469,0.0236916 0.012409,0.0368098 0.012438,0.17345669 9.14e-5,0.4180001 0.075678,0.760977 0.2447839,1.1106318 0.056754,0.1173462 0.098457,0.1906115 0.1682553,0.2955929 0.3431488,0.5161134 0.8733993,0.8888258 1.4714941,1.0343124 0.1964371,0.047783 0.3932704,0.069732 0.6257736,0.06978 0.1368638,2.75e-5 0.149736,9.532e-4 0.1735729,0.012495 0.018699,0.00904 0.02916,0.019517 0.038208,0.03821 0.011121,0.022967 0.012468,0.03709 0.012468,0.1308258 0,0.093737 -0.00134,0.1078587 -0.012468,0.130826 -0.00904,0.018693 -0.019518,0.029161 -0.038208,0.03821 -0.023547,0.0114 -0.036815,0.012462 -0.1554229,0.012436 C 4.81612,3.9336328 4.4232061,3.8476037 4.0184,3.6516738 3.5560383,3.4278865 3.1645228,3.0848479 2.8815169,2.6555577 2.6965101,2.3749218 2.561889,2.0615827 2.4883411,1.7404137 2.436611,1.5145191 2.4152457,1.3106209 2.4151874,1.0422725 c -2.27e-5,-0.11860693 0.00103,-0.13187533 0.012436,-0.15542376 0.00904,-0.0186927 0.019518,-0.029161 0.03821,-0.0382107 0.022979,-0.0111249 0.037079,-0.0124634 0.131223,-0.0124585 0.094618,4e-6 0.108092,0.001297 0.1308818,0.0125795 z"
        />

        <path
          id="s868_src_force_good"
          d="M 2.8862811,5.5014353 C 2.8401553,5.4786009 2.8376423,5.4668551 2.8339404,5.2568944 2.8301459,5.0398568 2.8166707,4.9359614 2.7720711,4.7789459 2.6058815,4.1938463 2.1476353,3.7355992 1.5625369,3.5694097 1.4055212,3.5248117 1.3016266,3.5113627 1.0845886,3.5075419 c -0.16400474,-0.00289 -0.18394261,-0.00434 -0.20684791,-0.01512 -0.0184839,-0.00869 -0.0287209,-0.018895 -0.0378107,-0.037672 -0.011123,-0.022968 -0.0124634,-0.03709 -0.0124634,-0.1308259 0,-0.093736 0.001346,-0.1078585 0.0124625,-0.1308258 0.009288,-0.019175 0.0194614,-0.029136 0.0399024,-0.039028 0.0269249,-0.013036 0.0308293,-0.013218 0.20987281,-0.00983 0.1343316,0.00254 0.2069501,0.00676 0.2754076,0.016003 0.3911068,0.052798 0.7378273,0.2017212 1.0516626,0.4517088 0.076349,0.060812 0.2519423,0.2364107 0.3127551,0.3127555 0.2499876,0.3138358 0.3989101,0.6605571 0.4517086,1.051664 0.00924,0.068456 0.013458,0.1410764 0.016006,0.2754077 0.00336,0.1790426 0.0032,0.1829454 -0.00983,0.2098731 -0.00989,0.020434 -0.019858,0.03062 -0.039026,0.039901 -0.022978,0.011124 -0.037078,0.012463 -0.1312234,0.012459 -0.094618,-4.1e-6 -0.108092,-0.00131 -0.1308817,-0.01258 z"
        />

        <path
          id="s868_dst_force_good"
          d="m 3.4539331,0.84875556 c 0.046127,0.0228342 0.048641,0.0345805 0.052337,0.24454124 0.00382,0.2170377 0.01727,0.3209332 0.061867,0.4779487 0.1661891,0.5851002 0.6244349,1.0433476 1.2095324,1.2095373 0.1570151,0.044598 0.2609102,0.058047 0.4779469,0.061868 0.1640048,0.0029 0.1839426,0.00434 0.2068476,0.01512 0.018489,0.00869 0.028725,0.018895 0.037815,0.037672 0.011122,0.022967 0.012468,0.03709 0.012468,0.130826 0,0.093736 -0.00134,0.1078587 -0.012468,0.1308259 -0.00928,0.019175 -0.019467,0.029136 -0.039905,0.039029 -0.026927,0.013036 -0.03083,0.013218 -0.2098725,0.00983 C 5.1161704,3.203416 5.0435519,3.1991913 4.9750945,3.189951 4.5839882,3.1371524 4.2372681,2.9882296 3.9234332,2.7382418 3.8470888,2.6774289 3.6714912,2.5018309 3.6106786,2.4254859 3.3606917,2.11165 3.2117692,1.7649284 3.158971,1.3738211 3.1497282,1.3053641 3.1455098,1.2327447 3.1429681,1.0984133 c -0.00338,-0.17904295 -0.0032,-0.1829456 0.00983,-0.20987341 0.00989,-0.0204341 0.019854,-0.0306195 0.039029,-0.0399015 0.022979,-0.0111239 0.037079,-0.0124634 0.1312231,-0.0124585 0.094618,4.1e-6 0.1080919,0.001297 0.1308817,0.0125795 z"
        />

        <path
          id="s868_src_force_low"
          d="M 2.1612943,5.5019234 C 2.1155821,5.4792991 2.1135834,5.4712752 2.1093501,5.293189 2.1073295,5.2066742 2.1023479,5.115471 2.0983547,5.0905148 2.0746839,4.9425458 2.008196,4.7823235 1.9177742,4.6553291 1.8657457,4.5822596 1.759227,4.4757393 1.6861577,4.4237125 1.5591632,4.3332902 1.3989412,4.2668056 1.2509724,4.243132 1.2260124,4.239142 1.1348127,4.234192 1.0482984,4.232133 0.86993298,4.227893 0.86176955,4.225843 0.83944534,4.179728 0.82892114,4.157997 0.82747114,4.142258 0.82747114,4.0499187 c 0,-0.093736 0.001346,-0.1078587 0.0124634,-0.1308257 0.009278,-0.019154 0.0194712,-0.029137 0.0398264,-0.038994 0.0266634,-0.01291 0.0315454,-0.013163 0.19172266,-0.00996 0.1768864,0.00354 0.2406169,0.010739 0.3583905,0.040485 0.4880621,0.1232616 0.8777191,0.5129195 1.0009805,1.0009817 0.029741,0.117774 0.036942,0.1815046 0.040484,0.3583907 0.0032,0.160178 0.00296,0.1650536 -0.00995,0.1917229 -0.00985,0.020359 -0.019842,0.030557 -0.038992,0.039831 -0.022978,0.011124 -0.037078,0.012463 -0.1312232,0.012459 -0.093222,-4.3e-6 -0.1083115,-0.00142 -0.1298743,-0.012081 z"
        />

        <path
          id="s868_dst_force_low"
          d="m 4.1789189,0.84826746 c 0.045712,0.0226234 0.047709,0.0306477 0.051942,0.20873474 0.00206,0.086515 0.007,0.177718 0.010999,0.2026743 0.023673,0.1479692 0.090158,0.3081915 0.1805796,0.435186 0.052027,0.073069 0.1585467,0.1795899 0.2316158,0.2316167 0.1269942,0.090423 0.2872164,0.156907 0.4351847,0.1805806 0.024957,0.00399 0.1161592,0.00894 0.2026737,0.010999 0.1783649,0.00424 0.1865282,0.00629 0.2088523,0.052405 0.01052,0.021732 0.011975,0.037471 0.011975,0.1298093 0,0.093736 -0.00134,0.1078588 -0.012468,0.1308259 C 5.490993,2.450254 5.480806,2.460236 5.460443,2.470093 5.433777,2.483002 5.428898,2.483255 5.2687208,2.480053 5.0918384,2.4765086 5.0281079,2.4693105 4.9103343,2.4395656 4.4222733,2.3163039 4.0326167,1.9266456 3.9093554,1.4385831 3.8796113,1.3208089 3.8724131,1.2570783 3.8688708,1.0801921 c -0.0032,-0.16017824 -0.00296,-0.16505386 0.00995,-0.19172314 0.00985,-0.02036 0.019838,-0.0305561 0.038993,-0.0398292 0.022979,-0.0111249 0.037081,-0.0124634 0.131223,-0.0124585 0.093221,4.19e-6 0.1083115,0.001414 0.1298737,0.012081 z"
        />

        <path
          id="s868_src_force_very_low"
          d="M 1.111819,5.5045759 C 0.97879514,5.4759028 0.86472086,5.3607143 0.83657147,5.2266404 0.80599515,5.0810077 0.86743445,4.9292549 0.99087057,4.8455277 1.1002521,4.7713336 1.231817,4.7629424 1.3530017,4.8224312 c 0.064163,0.031496 0.1345559,0.1018913 0.1660514,0.1660515 0.059492,0.1211848 0.0511,0.2527497 -0.023097,0.362131 C 1.4119181,5.474513 1.2581887,5.5361265 1.111819,5.5045759 Z"
        />

        <path
          id="s868_dst_force_very_low"
          d="M 5.2283924,0.84561488 C 5.361416,0.87428803 5.47549,0.98947675 5.5036393,1.1235507 5.534212,1.2691837 5.4727739,1.4209364 5.3493405,1.5046638 5.2399591,1.5788579 5.1083948,1.5872491 4.9872099,1.5277604 4.9230497,1.496265 4.8526543,1.4258691 4.821159,1.3617088 4.7616709,1.2405238 4.7700617,1.1089588 4.8442559,0.99957745 4.9282979,0.87567821 5.082023,0.81406461 5.2283924,0.84561488 Z"
        />
      </g>

      <g *ngIf="is_s868_is_low$$ | async" [attr.fill]="getColor$$('868_low') | async">
        <path
          id="s868_src_force_excellent"
          fill="lightgray"
          d="M 3.6122772,5.5014331 C 3.5941386,5.4924505 3.5833202,5.481527 3.5744655,5.4632263 3.5629977,5.4395351 3.5620532,5.4264158 3.5620278,5.2897696 3.561936,4.8717698 3.4863486,4.5287932 3.3172431,4.1791388 3.2604907,4.0617927 3.2187867,3.9885275 3.1489876,3.8835461 2.8058383,3.3674333 2.2755868,2.9947209 1.6774915,2.8492345 1.4810537,2.8014518 1.28422,2.7795026 1.0517162,2.7794546 0.91485225,2.7794271 0.90198003,2.7785018 0.87814293,2.7669602 c -0.0186948,-0.00904 -0.0291596,-0.019517 -0.0382077,-0.038211 -0.0111226,-0.022968 -0.012463,-0.03709 -0.012463,-0.130826 0,-0.093737 0.001346,-0.1078585 0.0124634,-0.1308258 0.009044,-0.018692 0.0195132,-0.029161 0.0382078,-0.03821 0.0235434,-0.011399 0.0368165,-0.012461 0.15542407,-0.012436 0.4905255,1.075e-4 0.88344,0.086136 1.288246,0.2820663 0.4623623,0.223787 0.8538786,0.5668254 1.1368847,0.9961153 0.1850075,0.2806355 0.3196285,0.5939742 0.3931767,0.9151432 0.051733,0.2258944 0.073093,0.4297925 0.073152,0.6981407 2.29e-5,0.1186066 -0.001,0.131875 -0.012439,0.1554234 -0.00904,0.018693 -0.019521,0.029161 -0.038208,0.03821 -0.022978,0.011125 -0.037078,0.012463 -0.1312233,0.01246 -0.094618,-3.9e-6 -0.1080922,-0.00131 -0.1308819,-0.01258 z"
        />

        <path
          id="s868_dst_force_excellent"
          fill="lightgray"
          d="m 2.7279376,0.84875761 c 0.018141,0.008986 0.028953,0.0199064 0.037812,0.0382068 0.011469,0.0236916 0.012409,0.0368098 0.012438,0.17345669 9.14e-5,0.4180001 0.075678,0.760977 0.2447839,1.1106318 0.056754,0.1173462 0.098457,0.1906115 0.1682553,0.2955929 0.3431488,0.5161134 0.8733993,0.8888258 1.4714941,1.0343124 0.1964371,0.047783 0.3932704,0.069732 0.6257736,0.06978 0.1368638,2.75e-5 0.149736,9.532e-4 0.1735729,0.012495 0.018699,0.00904 0.02916,0.019517 0.038208,0.03821 0.011121,0.022967 0.012468,0.03709 0.012468,0.1308258 0,0.093737 -0.00134,0.1078587 -0.012468,0.130826 -0.00904,0.018693 -0.019518,0.029161 -0.038208,0.03821 -0.023547,0.0114 -0.036815,0.012462 -0.1554229,0.012436 C 4.81612,3.9336328 4.4232061,3.8476037 4.0184,3.6516738 3.5560383,3.4278865 3.1645228,3.0848479 2.8815169,2.6555577 2.6965101,2.3749218 2.561889,2.0615827 2.4883411,1.7404137 2.436611,1.5145191 2.4152457,1.3106209 2.4151874,1.0422725 c -2.27e-5,-0.11860693 0.00103,-0.13187533 0.012436,-0.15542376 0.00904,-0.0186927 0.019518,-0.029161 0.03821,-0.0382107 0.022979,-0.0111249 0.037079,-0.0124634 0.131223,-0.0124585 0.094618,4e-6 0.108092,0.001297 0.1308818,0.0125795 z"
        />

        <path
          id="s868_src_force_good"
          fill="lightgray"
          d="M 2.8862811,5.5014353 C 2.8401553,5.4786009 2.8376423,5.4668551 2.8339404,5.2568944 2.8301459,5.0398568 2.8166707,4.9359614 2.7720711,4.7789459 2.6058815,4.1938463 2.1476353,3.7355992 1.5625369,3.5694097 1.4055212,3.5248117 1.3016266,3.5113627 1.0845886,3.5075419 c -0.16400474,-0.00289 -0.18394261,-0.00434 -0.20684791,-0.01512 -0.0184839,-0.00869 -0.0287209,-0.018895 -0.0378107,-0.037672 -0.011123,-0.022968 -0.0124634,-0.03709 -0.0124634,-0.1308259 0,-0.093736 0.001346,-0.1078585 0.0124625,-0.1308258 0.009288,-0.019175 0.0194614,-0.029136 0.0399024,-0.039028 0.0269249,-0.013036 0.0308293,-0.013218 0.20987281,-0.00983 0.1343316,0.00254 0.2069501,0.00676 0.2754076,0.016003 0.3911068,0.052798 0.7378273,0.2017212 1.0516626,0.4517088 0.076349,0.060812 0.2519423,0.2364107 0.3127551,0.3127555 0.2499876,0.3138358 0.3989101,0.6605571 0.4517086,1.051664 0.00924,0.068456 0.013458,0.1410764 0.016006,0.2754077 0.00336,0.1790426 0.0032,0.1829454 -0.00983,0.2098731 -0.00989,0.020434 -0.019858,0.03062 -0.039026,0.039901 -0.022978,0.011124 -0.037078,0.012463 -0.1312234,0.012459 -0.094618,-4.1e-6 -0.108092,-0.00131 -0.1308817,-0.01258 z"
        />

        <path
          id="s868_dst_force_good"
          fill="lightgray"
          d="m 3.4539331,0.84875556 c 0.046127,0.0228342 0.048641,0.0345805 0.052337,0.24454124 0.00382,0.2170377 0.01727,0.3209332 0.061867,0.4779487 0.1661891,0.5851002 0.6244349,1.0433476 1.2095324,1.2095373 0.1570151,0.044598 0.2609102,0.058047 0.4779469,0.061868 0.1640048,0.0029 0.1839426,0.00434 0.2068476,0.01512 0.018489,0.00869 0.028725,0.018895 0.037815,0.037672 0.011122,0.022967 0.012468,0.03709 0.012468,0.130826 0,0.093736 -0.00134,0.1078587 -0.012468,0.1308259 -0.00928,0.019175 -0.019467,0.029136 -0.039905,0.039029 -0.026927,0.013036 -0.03083,0.013218 -0.2098725,0.00983 C 5.1161704,3.203416 5.0435519,3.1991913 4.9750945,3.189951 4.5839882,3.1371524 4.2372681,2.9882296 3.9234332,2.7382418 3.8470888,2.6774289 3.6714912,2.5018309 3.6106786,2.4254859 3.3606917,2.11165 3.2117692,1.7649284 3.158971,1.3738211 3.1497282,1.3053641 3.1455098,1.2327447 3.1429681,1.0984133 c -0.00338,-0.17904295 -0.0032,-0.1829456 0.00983,-0.20987341 0.00989,-0.0204341 0.019854,-0.0306195 0.039029,-0.0399015 0.022979,-0.0111239 0.037079,-0.0124634 0.1312231,-0.0124585 0.094618,4.1e-6 0.1080919,0.001297 0.1308817,0.0125795 z"
        />

        <path
          id="s868_src_force_low"
          d="M 2.1612943,5.5019234 C 2.1155821,5.4792991 2.1135834,5.4712752 2.1093501,5.293189 2.1073295,5.2066742 2.1023479,5.115471 2.0983547,5.0905148 2.0746839,4.9425458 2.008196,4.7823235 1.9177742,4.6553291 1.8657457,4.5822596 1.759227,4.4757393 1.6861577,4.4237125 1.5591632,4.3332902 1.3989412,4.2668056 1.2509724,4.243132 1.2260124,4.239142 1.1348127,4.234192 1.0482984,4.232133 0.86993298,4.227893 0.86176955,4.225843 0.83944534,4.179728 0.82892114,4.157997 0.82747114,4.142258 0.82747114,4.0499187 c 0,-0.093736 0.001346,-0.1078587 0.0124634,-0.1308257 0.009278,-0.019154 0.0194712,-0.029137 0.0398264,-0.038994 0.0266634,-0.01291 0.0315454,-0.013163 0.19172266,-0.00996 0.1768864,0.00354 0.2406169,0.010739 0.3583905,0.040485 0.4880621,0.1232616 0.8777191,0.5129195 1.0009805,1.0009817 0.029741,0.117774 0.036942,0.1815046 0.040484,0.3583907 0.0032,0.160178 0.00296,0.1650536 -0.00995,0.1917229 -0.00985,0.020359 -0.019842,0.030557 -0.038992,0.039831 -0.022978,0.011124 -0.037078,0.012463 -0.1312232,0.012459 -0.093222,-4.3e-6 -0.1083115,-0.00142 -0.1298743,-0.012081 z"
        />

        <path
          id="s868_dst_force_low"
          d="m 4.1789189,0.84826746 c 0.045712,0.0226234 0.047709,0.0306477 0.051942,0.20873474 0.00206,0.086515 0.007,0.177718 0.010999,0.2026743 0.023673,0.1479692 0.090158,0.3081915 0.1805796,0.435186 0.052027,0.073069 0.1585467,0.1795899 0.2316158,0.2316167 0.1269942,0.090423 0.2872164,0.156907 0.4351847,0.1805806 0.024957,0.00399 0.1161592,0.00894 0.2026737,0.010999 0.1783649,0.00424 0.1865282,0.00629 0.2088523,0.052405 0.01052,0.021732 0.011975,0.037471 0.011975,0.1298093 0,0.093736 -0.00134,0.1078588 -0.012468,0.1308259 C 5.490993,2.450254 5.480806,2.460236 5.460443,2.470093 5.433777,2.483002 5.428898,2.483255 5.2687208,2.480053 5.0918384,2.4765086 5.0281079,2.4693105 4.9103343,2.4395656 4.4222733,2.3163039 4.0326167,1.9266456 3.9093554,1.4385831 3.8796113,1.3208089 3.8724131,1.2570783 3.8688708,1.0801921 c -0.0032,-0.16017824 -0.00296,-0.16505386 0.00995,-0.19172314 0.00985,-0.02036 0.019838,-0.0305561 0.038993,-0.0398292 0.022979,-0.0111249 0.037081,-0.0124634 0.131223,-0.0124585 0.093221,4.19e-6 0.1083115,0.001414 0.1298737,0.012081 z"
        />

        <path
          id="s868_src_force_very_low"
          d="M 1.111819,5.5045759 C 0.97879514,5.4759028 0.86472086,5.3607143 0.83657147,5.2266404 0.80599515,5.0810077 0.86743445,4.9292549 0.99087057,4.8455277 1.1002521,4.7713336 1.231817,4.7629424 1.3530017,4.8224312 c 0.064163,0.031496 0.1345559,0.1018913 0.1660514,0.1660515 0.059492,0.1211848 0.0511,0.2527497 -0.023097,0.362131 C 1.4119181,5.474513 1.2581887,5.5361265 1.111819,5.5045759 Z"
        />

        <path
          id="s868_dst_force_very_low"
          d="M 5.2283924,0.84561488 C 5.361416,0.87428803 5.47549,0.98947675 5.5036393,1.1235507 5.534212,1.2691837 5.4727739,1.4209364 5.3493405,1.5046638 5.2399591,1.5788579 5.1083948,1.5872491 4.9872099,1.5277604 4.9230497,1.496265 4.8526543,1.4258691 4.821159,1.3617088 4.7616709,1.2405238 4.7700617,1.1089588 4.8442559,0.99957745 4.9282979,0.87567821 5.082023,0.81406461 5.2283924,0.84561488 Z"
        />
      </g>

      <g *ngIf="is_s868_is_very_low$$ | async" [attr.fill]="getColor$$('868_very_low') | async">
        <path
          id="s868_src_force_excellent"
          fill="lightgray"
          d="M 3.6122772,5.5014331 C 3.5941386,5.4924505 3.5833202,5.481527 3.5744655,5.4632263 3.5629977,5.4395351 3.5620532,5.4264158 3.5620278,5.2897696 3.561936,4.8717698 3.4863486,4.5287932 3.3172431,4.1791388 3.2604907,4.0617927 3.2187867,3.9885275 3.1489876,3.8835461 2.8058383,3.3674333 2.2755868,2.9947209 1.6774915,2.8492345 1.4810537,2.8014518 1.28422,2.7795026 1.0517162,2.7794546 0.91485225,2.7794271 0.90198003,2.7785018 0.87814293,2.7669602 c -0.0186948,-0.00904 -0.0291596,-0.019517 -0.0382077,-0.038211 -0.0111226,-0.022968 -0.012463,-0.03709 -0.012463,-0.130826 0,-0.093737 0.001346,-0.1078585 0.0124634,-0.1308258 0.009044,-0.018692 0.0195132,-0.029161 0.0382078,-0.03821 0.0235434,-0.011399 0.0368165,-0.012461 0.15542407,-0.012436 0.4905255,1.075e-4 0.88344,0.086136 1.288246,0.2820663 0.4623623,0.223787 0.8538786,0.5668254 1.1368847,0.9961153 0.1850075,0.2806355 0.3196285,0.5939742 0.3931767,0.9151432 0.051733,0.2258944 0.073093,0.4297925 0.073152,0.6981407 2.29e-5,0.1186066 -0.001,0.131875 -0.012439,0.1554234 -0.00904,0.018693 -0.019521,0.029161 -0.038208,0.03821 -0.022978,0.011125 -0.037078,0.012463 -0.1312233,0.01246 -0.094618,-3.9e-6 -0.1080922,-0.00131 -0.1308819,-0.01258 z"
        />

        <path
          id="s868_dst_force_excellent"
          fill="lightgray"
          d="m 2.7279376,0.84875761 c 0.018141,0.008986 0.028953,0.0199064 0.037812,0.0382068 0.011469,0.0236916 0.012409,0.0368098 0.012438,0.17345669 9.14e-5,0.4180001 0.075678,0.760977 0.2447839,1.1106318 0.056754,0.1173462 0.098457,0.1906115 0.1682553,0.2955929 0.3431488,0.5161134 0.8733993,0.8888258 1.4714941,1.0343124 0.1964371,0.047783 0.3932704,0.069732 0.6257736,0.06978 0.1368638,2.75e-5 0.149736,9.532e-4 0.1735729,0.012495 0.018699,0.00904 0.02916,0.019517 0.038208,0.03821 0.011121,0.022967 0.012468,0.03709 0.012468,0.1308258 0,0.093737 -0.00134,0.1078587 -0.012468,0.130826 -0.00904,0.018693 -0.019518,0.029161 -0.038208,0.03821 -0.023547,0.0114 -0.036815,0.012462 -0.1554229,0.012436 C 4.81612,3.9336328 4.4232061,3.8476037 4.0184,3.6516738 3.5560383,3.4278865 3.1645228,3.0848479 2.8815169,2.6555577 2.6965101,2.3749218 2.561889,2.0615827 2.4883411,1.7404137 2.436611,1.5145191 2.4152457,1.3106209 2.4151874,1.0422725 c -2.27e-5,-0.11860693 0.00103,-0.13187533 0.012436,-0.15542376 0.00904,-0.0186927 0.019518,-0.029161 0.03821,-0.0382107 0.022979,-0.0111249 0.037079,-0.0124634 0.131223,-0.0124585 0.094618,4e-6 0.108092,0.001297 0.1308818,0.0125795 z"
        />

        <path
          id="s868_src_force_good"
          fill="lightgray"
          d="M 2.8862811,5.5014353 C 2.8401553,5.4786009 2.8376423,5.4668551 2.8339404,5.2568944 2.8301459,5.0398568 2.8166707,4.9359614 2.7720711,4.7789459 2.6058815,4.1938463 2.1476353,3.7355992 1.5625369,3.5694097 1.4055212,3.5248117 1.3016266,3.5113627 1.0845886,3.5075419 c -0.16400474,-0.00289 -0.18394261,-0.00434 -0.20684791,-0.01512 -0.0184839,-0.00869 -0.0287209,-0.018895 -0.0378107,-0.037672 -0.011123,-0.022968 -0.0124634,-0.03709 -0.0124634,-0.1308259 0,-0.093736 0.001346,-0.1078585 0.0124625,-0.1308258 0.009288,-0.019175 0.0194614,-0.029136 0.0399024,-0.039028 0.0269249,-0.013036 0.0308293,-0.013218 0.20987281,-0.00983 0.1343316,0.00254 0.2069501,0.00676 0.2754076,0.016003 0.3911068,0.052798 0.7378273,0.2017212 1.0516626,0.4517088 0.076349,0.060812 0.2519423,0.2364107 0.3127551,0.3127555 0.2499876,0.3138358 0.3989101,0.6605571 0.4517086,1.051664 0.00924,0.068456 0.013458,0.1410764 0.016006,0.2754077 0.00336,0.1790426 0.0032,0.1829454 -0.00983,0.2098731 -0.00989,0.020434 -0.019858,0.03062 -0.039026,0.039901 -0.022978,0.011124 -0.037078,0.012463 -0.1312234,0.012459 -0.094618,-4.1e-6 -0.108092,-0.00131 -0.1308817,-0.01258 z"
        />

        <path
          id="s868_dst_force_good"
          fill="lightgray"
          d="m 3.4539331,0.84875556 c 0.046127,0.0228342 0.048641,0.0345805 0.052337,0.24454124 0.00382,0.2170377 0.01727,0.3209332 0.061867,0.4779487 0.1661891,0.5851002 0.6244349,1.0433476 1.2095324,1.2095373 0.1570151,0.044598 0.2609102,0.058047 0.4779469,0.061868 0.1640048,0.0029 0.1839426,0.00434 0.2068476,0.01512 0.018489,0.00869 0.028725,0.018895 0.037815,0.037672 0.011122,0.022967 0.012468,0.03709 0.012468,0.130826 0,0.093736 -0.00134,0.1078587 -0.012468,0.1308259 -0.00928,0.019175 -0.019467,0.029136 -0.039905,0.039029 -0.026927,0.013036 -0.03083,0.013218 -0.2098725,0.00983 C 5.1161704,3.203416 5.0435519,3.1991913 4.9750945,3.189951 4.5839882,3.1371524 4.2372681,2.9882296 3.9234332,2.7382418 3.8470888,2.6774289 3.6714912,2.5018309 3.6106786,2.4254859 3.3606917,2.11165 3.2117692,1.7649284 3.158971,1.3738211 3.1497282,1.3053641 3.1455098,1.2327447 3.1429681,1.0984133 c -0.00338,-0.17904295 -0.0032,-0.1829456 0.00983,-0.20987341 0.00989,-0.0204341 0.019854,-0.0306195 0.039029,-0.0399015 0.022979,-0.0111239 0.037079,-0.0124634 0.1312231,-0.0124585 0.094618,4.1e-6 0.1080919,0.001297 0.1308817,0.0125795 z"
        />

        <path
          id="s868_src_force_low"
          fill="lightgray"
          d="M 2.1612943,5.5019234 C 2.1155821,5.4792991 2.1135834,5.4712752 2.1093501,5.293189 2.1073295,5.2066742 2.1023479,5.115471 2.0983547,5.0905148 2.0746839,4.9425458 2.008196,4.7823235 1.9177742,4.6553291 1.8657457,4.5822596 1.759227,4.4757393 1.6861577,4.4237125 1.5591632,4.3332902 1.3989412,4.2668056 1.2509724,4.243132 1.2260124,4.239142 1.1348127,4.234192 1.0482984,4.232133 0.86993298,4.227893 0.86176955,4.225843 0.83944534,4.179728 0.82892114,4.157997 0.82747114,4.142258 0.82747114,4.0499187 c 0,-0.093736 0.001346,-0.1078587 0.0124634,-0.1308257 0.009278,-0.019154 0.0194712,-0.029137 0.0398264,-0.038994 0.0266634,-0.01291 0.0315454,-0.013163 0.19172266,-0.00996 0.1768864,0.00354 0.2406169,0.010739 0.3583905,0.040485 0.4880621,0.1232616 0.8777191,0.5129195 1.0009805,1.0009817 0.029741,0.117774 0.036942,0.1815046 0.040484,0.3583907 0.0032,0.160178 0.00296,0.1650536 -0.00995,0.1917229 -0.00985,0.020359 -0.019842,0.030557 -0.038992,0.039831 -0.022978,0.011124 -0.037078,0.012463 -0.1312232,0.012459 -0.093222,-4.3e-6 -0.1083115,-0.00142 -0.1298743,-0.012081 z"
        />

        <path
          id="s868_dst_force_low"
          fill="lightgray"
          d="m 4.1789189,0.84826746 c 0.045712,0.0226234 0.047709,0.0306477 0.051942,0.20873474 0.00206,0.086515 0.007,0.177718 0.010999,0.2026743 0.023673,0.1479692 0.090158,0.3081915 0.1805796,0.435186 0.052027,0.073069 0.1585467,0.1795899 0.2316158,0.2316167 0.1269942,0.090423 0.2872164,0.156907 0.4351847,0.1805806 0.024957,0.00399 0.1161592,0.00894 0.2026737,0.010999 0.1783649,0.00424 0.1865282,0.00629 0.2088523,0.052405 0.01052,0.021732 0.011975,0.037471 0.011975,0.1298093 0,0.093736 -0.00134,0.1078588 -0.012468,0.1308259 C 5.490993,2.450254 5.480806,2.460236 5.460443,2.470093 5.433777,2.483002 5.428898,2.483255 5.2687208,2.480053 5.0918384,2.4765086 5.0281079,2.4693105 4.9103343,2.4395656 4.4222733,2.3163039 4.0326167,1.9266456 3.9093554,1.4385831 3.8796113,1.3208089 3.8724131,1.2570783 3.8688708,1.0801921 c -0.0032,-0.16017824 -0.00296,-0.16505386 0.00995,-0.19172314 0.00985,-0.02036 0.019838,-0.0305561 0.038993,-0.0398292 0.022979,-0.0111249 0.037081,-0.0124634 0.131223,-0.0124585 0.093221,4.19e-6 0.1083115,0.001414 0.1298737,0.012081 z"
        />

        <path
          id="s868_src_force_very_low"
          d="M 1.111819,5.5045759 C 0.97879514,5.4759028 0.86472086,5.3607143 0.83657147,5.2266404 0.80599515,5.0810077 0.86743445,4.9292549 0.99087057,4.8455277 1.1002521,4.7713336 1.231817,4.7629424 1.3530017,4.8224312 c 0.064163,0.031496 0.1345559,0.1018913 0.1660514,0.1660515 0.059492,0.1211848 0.0511,0.2527497 -0.023097,0.362131 C 1.4119181,5.474513 1.2581887,5.5361265 1.111819,5.5045759 Z"
        />

        <path
          id="s868_dst_force_very_low"
          d="M 5.2283924,0.84561488 C 5.361416,0.87428803 5.47549,0.98947675 5.5036393,1.1235507 5.534212,1.2691837 5.4727739,1.4209364 5.3493405,1.5046638 5.2399591,1.5788579 5.1083948,1.5872491 4.9872099,1.5277604 4.9230497,1.496265 4.8526543,1.4258691 4.821159,1.3617088 4.7616709,1.2405238 4.7700617,1.1089588 4.8442559,0.99957745 4.9282979,0.87567821 5.082023,0.81406461 5.2283924,0.84561488 Z"
        />
      </g>

      <g *ngIf="is_s868_is_ko$$ | async" [attr.fill]="getColor$$('868_ko') | async">
        <path
          id="s868_src_force_excellent"
          d="M 3.6122772,5.5014331 C 3.5941386,5.4924505 3.5833202,5.481527 3.5744655,5.4632263 3.5629977,5.4395351 3.5620532,5.4264158 3.5620278,5.2897696 3.561936,4.8717698 3.4863486,4.5287932 3.3172431,4.1791388 3.2604907,4.0617927 3.2187867,3.9885275 3.1489876,3.8835461 2.8058383,3.3674333 2.2755868,2.9947209 1.6774915,2.8492345 1.4810537,2.8014518 1.28422,2.7795026 1.0517162,2.7794546 0.91485225,2.7794271 0.90198003,2.7785018 0.87814293,2.7669602 c -0.0186948,-0.00904 -0.0291596,-0.019517 -0.0382077,-0.038211 -0.0111226,-0.022968 -0.012463,-0.03709 -0.012463,-0.130826 0,-0.093737 0.001346,-0.1078585 0.0124634,-0.1308258 0.009044,-0.018692 0.0195132,-0.029161 0.0382078,-0.03821 0.0235434,-0.011399 0.0368165,-0.012461 0.15542407,-0.012436 0.4905255,1.075e-4 0.88344,0.086136 1.288246,0.2820663 0.4623623,0.223787 0.8538786,0.5668254 1.1368847,0.9961153 0.1850075,0.2806355 0.3196285,0.5939742 0.3931767,0.9151432 0.051733,0.2258944 0.073093,0.4297925 0.073152,0.6981407 2.29e-5,0.1186066 -0.001,0.131875 -0.012439,0.1554234 -0.00904,0.018693 -0.019521,0.029161 -0.038208,0.03821 -0.022978,0.011125 -0.037078,0.012463 -0.1312233,0.01246 -0.094618,-3.9e-6 -0.1080922,-0.00131 -0.1308819,-0.01258 z"
        />

        <path
          id="s868_dst_force_excellent"
          d="m 2.7279376,0.84875761 c 0.018141,0.008986 0.028953,0.0199064 0.037812,0.0382068 0.011469,0.0236916 0.012409,0.0368098 0.012438,0.17345669 9.14e-5,0.4180001 0.075678,0.760977 0.2447839,1.1106318 0.056754,0.1173462 0.098457,0.1906115 0.1682553,0.2955929 0.3431488,0.5161134 0.8733993,0.8888258 1.4714941,1.0343124 0.1964371,0.047783 0.3932704,0.069732 0.6257736,0.06978 0.1368638,2.75e-5 0.149736,9.532e-4 0.1735729,0.012495 0.018699,0.00904 0.02916,0.019517 0.038208,0.03821 0.011121,0.022967 0.012468,0.03709 0.012468,0.1308258 0,0.093737 -0.00134,0.1078587 -0.012468,0.130826 -0.00904,0.018693 -0.019518,0.029161 -0.038208,0.03821 -0.023547,0.0114 -0.036815,0.012462 -0.1554229,0.012436 C 4.81612,3.9336328 4.4232061,3.8476037 4.0184,3.6516738 3.5560383,3.4278865 3.1645228,3.0848479 2.8815169,2.6555577 2.6965101,2.3749218 2.561889,2.0615827 2.4883411,1.7404137 2.436611,1.5145191 2.4152457,1.3106209 2.4151874,1.0422725 c -2.27e-5,-0.11860693 0.00103,-0.13187533 0.012436,-0.15542376 0.00904,-0.0186927 0.019518,-0.029161 0.03821,-0.0382107 0.022979,-0.0111249 0.037079,-0.0124634 0.131223,-0.0124585 0.094618,4e-6 0.108092,0.001297 0.1308818,0.0125795 z"
        />

        <path
          id="s868_src_force_good"
          d="M 2.8862811,5.5014353 C 2.8401553,5.4786009 2.8376423,5.4668551 2.8339404,5.2568944 2.8301459,5.0398568 2.8166707,4.9359614 2.7720711,4.7789459 2.6058815,4.1938463 2.1476353,3.7355992 1.5625369,3.5694097 1.4055212,3.5248117 1.3016266,3.5113627 1.0845886,3.5075419 c -0.16400474,-0.00289 -0.18394261,-0.00434 -0.20684791,-0.01512 -0.0184839,-0.00869 -0.0287209,-0.018895 -0.0378107,-0.037672 -0.011123,-0.022968 -0.0124634,-0.03709 -0.0124634,-0.1308259 0,-0.093736 0.001346,-0.1078585 0.0124625,-0.1308258 0.009288,-0.019175 0.0194614,-0.029136 0.0399024,-0.039028 0.0269249,-0.013036 0.0308293,-0.013218 0.20987281,-0.00983 0.1343316,0.00254 0.2069501,0.00676 0.2754076,0.016003 0.3911068,0.052798 0.7378273,0.2017212 1.0516626,0.4517088 0.076349,0.060812 0.2519423,0.2364107 0.3127551,0.3127555 0.2499876,0.3138358 0.3989101,0.6605571 0.4517086,1.051664 0.00924,0.068456 0.013458,0.1410764 0.016006,0.2754077 0.00336,0.1790426 0.0032,0.1829454 -0.00983,0.2098731 -0.00989,0.020434 -0.019858,0.03062 -0.039026,0.039901 -0.022978,0.011124 -0.037078,0.012463 -0.1312234,0.012459 -0.094618,-4.1e-6 -0.108092,-0.00131 -0.1308817,-0.01258 z"
        />

        <path
          id="s868_dst_force_good"
          d="m 3.4539331,0.84875556 c 0.046127,0.0228342 0.048641,0.0345805 0.052337,0.24454124 0.00382,0.2170377 0.01727,0.3209332 0.061867,0.4779487 0.1661891,0.5851002 0.6244349,1.0433476 1.2095324,1.2095373 0.1570151,0.044598 0.2609102,0.058047 0.4779469,0.061868 0.1640048,0.0029 0.1839426,0.00434 0.2068476,0.01512 0.018489,0.00869 0.028725,0.018895 0.037815,0.037672 0.011122,0.022967 0.012468,0.03709 0.012468,0.130826 0,0.093736 -0.00134,0.1078587 -0.012468,0.1308259 -0.00928,0.019175 -0.019467,0.029136 -0.039905,0.039029 -0.026927,0.013036 -0.03083,0.013218 -0.2098725,0.00983 C 5.1161704,3.203416 5.0435519,3.1991913 4.9750945,3.189951 4.5839882,3.1371524 4.2372681,2.9882296 3.9234332,2.7382418 3.8470888,2.6774289 3.6714912,2.5018309 3.6106786,2.4254859 3.3606917,2.11165 3.2117692,1.7649284 3.158971,1.3738211 3.1497282,1.3053641 3.1455098,1.2327447 3.1429681,1.0984133 c -0.00338,-0.17904295 -0.0032,-0.1829456 0.00983,-0.20987341 0.00989,-0.0204341 0.019854,-0.0306195 0.039029,-0.0399015 0.022979,-0.0111239 0.037079,-0.0124634 0.1312231,-0.0124585 0.094618,4.1e-6 0.1080919,0.001297 0.1308817,0.0125795 z"
        />

        <path
          id="s868_src_force_low"
          d="M 2.1612943,5.5019234 C 2.1155821,5.4792991 2.1135834,5.4712752 2.1093501,5.293189 2.1073295,5.2066742 2.1023479,5.115471 2.0983547,5.0905148 2.0746839,4.9425458 2.008196,4.7823235 1.9177742,4.6553291 1.8657457,4.5822596 1.759227,4.4757393 1.6861577,4.4237125 1.5591632,4.3332902 1.3989412,4.2668056 1.2509724,4.243132 1.2260124,4.239142 1.1348127,4.234192 1.0482984,4.232133 0.86993298,4.227893 0.86176955,4.225843 0.83944534,4.179728 0.82892114,4.157997 0.82747114,4.142258 0.82747114,4.0499187 c 0,-0.093736 0.001346,-0.1078587 0.0124634,-0.1308257 0.009278,-0.019154 0.0194712,-0.029137 0.0398264,-0.038994 0.0266634,-0.01291 0.0315454,-0.013163 0.19172266,-0.00996 0.1768864,0.00354 0.2406169,0.010739 0.3583905,0.040485 0.4880621,0.1232616 0.8777191,0.5129195 1.0009805,1.0009817 0.029741,0.117774 0.036942,0.1815046 0.040484,0.3583907 0.0032,0.160178 0.00296,0.1650536 -0.00995,0.1917229 -0.00985,0.020359 -0.019842,0.030557 -0.038992,0.039831 -0.022978,0.011124 -0.037078,0.012463 -0.1312232,0.012459 -0.093222,-4.3e-6 -0.1083115,-0.00142 -0.1298743,-0.012081 z"
        />

        <path
          id="s868_dst_force_low"
          d="m 4.1789189,0.84826746 c 0.045712,0.0226234 0.047709,0.0306477 0.051942,0.20873474 0.00206,0.086515 0.007,0.177718 0.010999,0.2026743 0.023673,0.1479692 0.090158,0.3081915 0.1805796,0.435186 0.052027,0.073069 0.1585467,0.1795899 0.2316158,0.2316167 0.1269942,0.090423 0.2872164,0.156907 0.4351847,0.1805806 0.024957,0.00399 0.1161592,0.00894 0.2026737,0.010999 0.1783649,0.00424 0.1865282,0.00629 0.2088523,0.052405 0.01052,0.021732 0.011975,0.037471 0.011975,0.1298093 0,0.093736 -0.00134,0.1078588 -0.012468,0.1308259 C 5.490993,2.450254 5.480806,2.460236 5.460443,2.470093 5.433777,2.483002 5.428898,2.483255 5.2687208,2.480053 5.0918384,2.4765086 5.0281079,2.4693105 4.9103343,2.4395656 4.4222733,2.3163039 4.0326167,1.9266456 3.9093554,1.4385831 3.8796113,1.3208089 3.8724131,1.2570783 3.8688708,1.0801921 c -0.0032,-0.16017824 -0.00296,-0.16505386 0.00995,-0.19172314 0.00985,-0.02036 0.019838,-0.0305561 0.038993,-0.0398292 0.022979,-0.0111249 0.037081,-0.0124634 0.131223,-0.0124585 0.093221,4.19e-6 0.1083115,0.001414 0.1298737,0.012081 z"
        />

        <path
          id="s868_src_force_very_low"
          d="M 1.111819,5.5045759 C 0.97879514,5.4759028 0.86472086,5.3607143 0.83657147,5.2266404 0.80599515,5.0810077 0.86743445,4.9292549 0.99087057,4.8455277 1.1002521,4.7713336 1.231817,4.7629424 1.3530017,4.8224312 c 0.064163,0.031496 0.1345559,0.1018913 0.1660514,0.1660515 0.059492,0.1211848 0.0511,0.2527497 -0.023097,0.362131 C 1.4119181,5.474513 1.2581887,5.5361265 1.111819,5.5045759 Z"
        />

        <path
          id="s868_dst_force_very_low"
          d="M 5.2283924,0.84561488 C 5.361416,0.87428803 5.47549,0.98947675 5.5036393,1.1235507 5.534212,1.2691837 5.4727739,1.4209364 5.3493405,1.5046638 5.2399591,1.5788579 5.1083948,1.5872491 4.9872099,1.5277604 4.9230497,1.496265 4.8526543,1.4258691 4.821159,1.3617088 4.7616709,1.2405238 4.7700617,1.1089588 4.8442559,0.99957745 4.9282979,0.87567821 5.082023,0.81406461 5.2283924,0.84561488 Z"
        />

        <path
          id="s868_force_ko"
          fill="red"
          d="M 3.5478955,3.3208094 3.2992224,3.5694829 3.9085406,4.1788015 3.2992224,4.7877787 3.5478955,5.0367958 4.157214,4.4274759 4.766535,5.0367958 5.0152087,4.7877787 4.405889,4.1788015 5.0152087,3.5694829 4.766535,3.3208094 4.157214,3.9301278 Z"
        />
      </g>

      <!-- #endregion -->

      <!-- #region -> (outdated icon) -->

      <g *ngIf="(status$$ | async)?.outdated" id="outdated_state">
        <circle fill="white" id="path_icon_outdated_background" cx="1.8785424" cy="1.8857162" r="2" />

        <path
          id="path_icon_outdated"
          fill="red"
          d="M 1.7050321,3.6517988 C 1.2782849,3.6076991 0.91246894,3.4343689 0.61109124,3.1334818 0.45768604,2.980326 0.36145374,2.8466024 0.27009024,2.6596283 0.18414894,2.4837429 0.13477114,2.3273341 0.10518284,2.1372272 c -0.021739,-0.1396466 -0.021739,-0.3827547 0,-0.5224012 0.060395,-0.3880483 0.2283705,-0.7188877 0.5059084,-0.9964264 0.1535157,-0.1535148 0.2873132,-0.2497659 0.47402446,-0.3410008 0.1758854,-0.085945 0.3322941,-0.1353193 0.5224012,-0.1649077 0.1398058,-0.021761 0.3828447,-0.021736 0.5224011,5.22e-5 0.1930347,0.03013 0.3468183,0.078632 0.522402,0.1647638 0.1876838,0.092067 0.3207185,0.1877974 0.4740232,0.3411022 0.1533049,0.1533047 0.2490352,0.2863395 0.3411022,0.4740243 0.085288,0.1738615 0.1351698,0.3319796 0.1648072,0.5224012 0.021736,0.1396466 0.021736,0.3827547 0,0.5224012 C 3.5720096,2.5243017 3.4031787,2.8568267 3.1263432,3.1336621 2.9730385,3.2869669 2.8400038,3.3826964 2.65232,3.4747642 2.4788496,3.5598582 2.3168132,3.6109657 2.1320674,3.6388536 2.0247267,3.6550526 1.8018826,3.6618136 1.7050321,3.6518086 Z M 2.6881387,2.819619 C 2.7434417,2.787211 2.7709627,2.7153734 2.7534617,2.6491077 2.7459817,2.6207397 2.7113087,2.5838747 2.3800978,2.252006 L 2.0149942,1.8861753 V 1.4155182 0.9448601 l -0.016207,-0.030702 c -0.00891,-0.016884 -0.029558,-0.0401 -0.045879,-0.051592 -0.026846,-0.018911 -0.034845,-0.020899 -0.084191,-0.020899 -0.049348,0 -0.057339,0.00201 -0.084191,0.020899 -0.016312,0.011487 -0.036962,0.034709 -0.045871,0.051592 l -0.016207,0.030702 v 0.5215838 c 0,0.501995 5.013e-4,0.5225541 0.013463,0.5474257 0.00741,0.014211 0.1901399,0.2022983 0.406078,0.4179667 0.3403625,0.3399385 0.3967387,0.3934578 0.4236119,0.4021443 0.04019,0.012994 0.084785,0.00773 0.1225397,-0.014365 z"
        />
      </g>
    </svg>
  </div>

  <popper-content #tooltipCases>
    <ng-container *ngIf="status?.outdated">
      <span>{{ 'DEVICE.ALL.LEVEL.No com since [since_days]' | translate : { since_days: status.timestamp | distanceToNow } }}</span>
      <br />
    </ng-container>

    <ng-container *ngIf="!__isNil(status?.outdated) && !__isNil(status?.value)">
      <span *ngIf="status?.value > -75">
        {{ 'DEVICE.ALL.LEVEL.Last local com : good [value]' | translate : { value: status.value.toFixed(2) } }}
      </span>
      <span *ngIf="status.value <= -75 && status.value >= -90">
        {{ 'DEVICE.ALL.LEVEL.Last local com : medium [value]' | translate : { value: status.value.toFixed(2) } }}
      </span>
      <span *ngIf="status.value < -90">
        {{ 'DEVICE.ALL.LEVEL.Last local com : weak [value]' | translate : { value: status.value.toFixed(2) } }}
      </span>
    </ng-container>

    <span *ngIf="status.value < -90"
      ><br />
      <doc-helper page="devices/improve_local_com" section="">
        {{ 'DEVICE.ALL.ACTION.How to improve situation' | translate }}
      </doc-helper>
    </span>

    <span *ngIf="__isNil(status?.value)">n.c</span>
  </popper-content>
</ng-container>
