import { Component, OnInit, ViewChild, ElementRef, HostListener, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { assign, cloneDeep, clone, indexOf, keys, pull, range, isNil, isNaN, isEqual } from 'lodash-es';

import { tap, map, switchMap, filter, take, withLatestFrom } from 'rxjs';
import { Subscription, of, Observable, forkJoin, throwError, combineLatest } from 'rxjs';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';

import { Beeguard2Api, DeviceApi } from 'app/core';
import { Apiary, Hive, Event, EntityState, EntityStateValue, HiveType } from 'app/models';
import { EvaluationEvent } from 'app/models/events/Evaluation';

import { marker as i18n } from '@biesbjerg/ngx-translate-extract-marker';
import { AppStateService } from 'app/core/app-state.service';
import { EventForm } from 'app/widgets/event-form';
import { HistogramItemConfig } from 'app/widgets/misc-widgets/simple-histogram/simple-histogram.component';

import { TranslateService } from '@ngx-translate/core';
import { subMinutes } from 'date-fns';
import { DialogsService } from 'app/widgets/dialogs-modals/dialogs.service';
import { CustomazibleDialogParams } from 'app/widgets/dialogs-modals/customizable/customazible.dialog';
import { UpdateEntityResponse } from 'app/core/api-swagger/beeguard2/model/updateEntityResponse';
import { NewEventModalComponent, NewEventModalParams } from '../new-event/new-event.modal';
import { ModalArgs } from 'app/widgets/dialogs-modals/abstract-modal.component';
import { Dictionary } from 'app/typings/core/interfaces';
import {
  DeleteEventDialogComponent,
  DeleteEventDialogOutput,
} from 'app/views/events/shared/delete-event-dialog/delete-event-dialog.component';
import { HtmlError } from 'app/widgets/misc-widgets/html-error/html-error.component';
import { distinctUntilRealChanged, robustCombineLatest } from '@bg2app/tools/rxjs';
import { endOfDay, isAfter } from 'date-fns/esm';
import { ISchema } from 'ngx-schema-form';
import { HttpErrorResponse } from '@angular/common/http';

enum ActiveView {
  CONFIG = 0, // initial configuration view
  FORM_A = 10, // gobal apiary form
  FORM_HF = 20, // hive fast input
  FORM_HP = 30, // hive precise input
  SUMMARY = 40, // Summary
}

// Redefine some type for clarification
type FormSchema = any;

export interface ApiaryEvaluationModalParams extends NewEventModalParams {
  eid?: number;
  aid?: number;
}

@AutoUnsubscribe()
@Component({
  selector: 'bg2-apiary-evaluation-modal',
  templateUrl: './apiary-evaluation.modal.html',
  styleUrls: ['./apiary-evaluation.modal.scss'],
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class ApiaryEvaluationModal extends NewEventModalComponent<ApiaryEvaluationModalParams> implements OnInit, OnDestroy {
  public __isNil = isNil;

  private get aid(): number {
    // apiary id
    return +this.input_params.aid;
  }

  private set aid(aid: number) {
    // event id (in case of update)
    const params = clone(this.input_params);
    params.aid = aid;
    this.input_params = params;
  }

  private get eid(): number {
    // event id (in case of update)
    return this.input_params.eid;
  }

  private set eid(eid: number) {
    const params = clone(this.input_params);
    params.eid = eid;
    this.input_params = params;
  }

  // TODO: Add feeding as exclusive field (causing bogue at the moment)
  public EXLUSIF_FIELDS = ['hive_status', 'queen', 'brood_frame', 'supers'];
  public HF_PROD_VISIT_FIELD = this.EXLUSIF_FIELDS;

  // Change
  protected MAX_INNTER_WIDTH = 1200;
  protected MAX_INNTER_HEIGHT = 900;

  // Models
  public get event(): EvaluationEvent {
    return super.event as EvaluationEvent;
  }

  public set event(event: EvaluationEvent) {
    super.event = event;
  }
  private saved_event_date: Date = null;

  public all_hives: Hive[] = []; // Contains all hives, even some that may not be created yet
  public all_hives_idx: { [hive_id: number]: Hive } = {}; // hives indexed id to quick access
  public existing_hives: Hive[] = [];

  // State
  public ActiveView = ActiveView;
  public state: ActiveView = ActiveView.CONFIG;
  public next_state: ActiveView = ActiveView.SUMMARY;
  public previous_state: ActiveView = ActiveView.CONFIG;

  public selected_hive_changed = false;
  private selected_hive_changed_timer: any = null;
  private _selected_hive_num = 0; // First hive selected by default
  public progress = 0; // value of the hive progressbar

  public eval_apiary = false;
  public eval_hives_fast = false;
  public eval_hives_precise = false;
  public hive_states: any[] = [];

  // Event and form management
  private full_event_schema: FormSchema;

  // Config form
  private config_form_schema_sub: Subscription;
  public config_schema: FormSchema = null;

  // Apiary form
  public initial_apiary_evaluation_schema: FormSchema = null;
  public apiary_evaluation_schema: FormSchema = null;

  // Hive forms fast
  public hf_form_schema: FormSchema = null; // this.hive specific evaluation form schema

  // Hive forms precise
  private ignore_default_hp_form: boolean[] = []; // used to avoid default model set
  private ignore_default_hp_form_to: any[] = [];
  public hp_form_schema: FormSchema[] = []; // this.hive specific evaluation form schema
  public hp_form_model: any[] = [];
  private last_hp_form_model: any[] = [];

  // Summarty diplay
  public summary_histo_config: { [field: string]: HistogramItemConfig[] } = {};

  get selected_hive_num() {
    return this._selected_hive_num;
  }

  set selected_hive_num(hive_num) {
    // console.log(`selected_hive_num <= ${hive_num}`);
    this.selected_hive_changed = true;
    this._selected_hive_num = hive_num;
    this.preventHivePreciseFormDefaultOveride(hive_num);
    if (this.selected_hive_changed_timer) {
      clearTimeout(this.selected_hive_changed_timer);
    }
    this.selected_hive_changed_timer = setTimeout(() => {
      this.selected_hive_changed = false;
      this.scrollHive();
    }, 1);
  }

  get selected_hive(): Hive {
    if (this.selected_hive_num < 0 || this.selected_hive_num >= this.all_hives.length) {
      return null;
    }
    return this.all_hives[this.selected_hive_num];
  }

  get selected_hive_id(): number {
    const shive = this.selected_hive;
    return shive ? shive.id : null;
  }

  private _initial_data_pipe_sub: Subscription = null;

  constructor(
    protected bg2Api: Beeguard2Api,
    protected translate: TranslateService,
    protected deviceApi: DeviceApi,
    public appState: AppStateService,
    protected router: Router,
    protected cdRef: ChangeDetectorRef,
    protected dialogs: DialogsService
  ) {
    super(bg2Api, dialogs, cdRef, appState, translate);
    // Build the event form event
  }

  ngOnDestroy(): void {
    this.unsubscribeConfigFormSchema();
    this._initial_data_pipe_sub?.unsubscribe();
  }

  public isFormReady() {
    switch (this.state) {
      case ActiveView.CONFIG:
        return !isNil(this.config_schema);

      case ActiveView.FORM_A:
        return !isNil(this.apiary_evaluation_schema);

      case ActiveView.FORM_HF:
        return !isNil(this.hf_form_schema);

      case ActiveView.FORM_HP:
        return !isNil(this.hp_form_schema);

      case ActiveView.SUMMARY:
        return true;
    }

    return true;
  }

  public isConfigFormReady() {
    return !isNil(this.config_schema);
  }

  public isEventFormReady() {
    return !isNil(this.hp_form_schema);
  }

  public isEventValid() {
    // TODO check if event is valid (data respect the schema)
    return true;
  }

  public apiary: Apiary;
  private apiary_state_at_date: any;

  ngOnInit(): void {
    super.ngOnInit();
    this.onResize();
    this.loading = true;

    this._initial_data_pipe_sub = this.input_params$$
      .pipe(
        map(input_params => ({ apiary_id: input_params.aid, event_id: input_params.eid })),
        distinctUntilRealChanged(),
        switchMap(({ apiary_id, event_id }) => {
          // this._logger.debug(apiary_id, event_id);

          if (isNil(event_id)) {
            if (isNil(apiary_id) || isNaN(apiary_id)) {
              return throwError('Misdefinition of apiary ID !');
            }

            const event = new EvaluationEvent(this.bg2Api);
            this.event = event;
            return of(apiary_id);
          }

          return this.bg2Api.getEventObj(+event_id).pipe(
            tap(event => {
              if (event.type !== 'evaluation') {
                throw new Error('Loaded event should be of type EvaluationEvent');
              }
              this.loading = true;
              this.event = event as EvaluationEvent;
              this.saved_event_date = this.event.date;
              this.changeState(ActiveView.SUMMARY, false);
            }),
            switchMap((event: EvaluationEvent) => event.date$$.pipe(map(() => event.apply_to.apiary.entity_id)))
          );
        }),
        tap(apiary_id => {
          this.aid = +apiary_id;
          // NOTE: \/ this is needed to generate the schema with default value
          this.args.apiary = this.aid;
        }),
        switchMap(aid => this.bg2Api.getEntityObj(aid)),
        tap(() => (this.loading = true)),
        filter(apiary => apiary instanceof Apiary),
        map(apiary => apiary as Apiary),
        switchMap((apiary: Apiary) => {
          this.apiary = apiary;
          return apiary.getStateAtDate(this.event.date, this.event.id, true);
        }),
        map((state: EntityState) => {
          this.apiary_state_at_date = state;
          return state?.state; // state may be null if the apiary is not setup yet
        }),
        switchMap((state: EntityStateValue) => this.bg2Api.getEntitiesObj(state?.hive_ids || [], 'hive') as Observable<Hive[]>),
        map((hives: Hive[]) => {
          this.existing_hives = hives;
          // console.log('existing hives loaded');
          this.buildAndBindConfigForm();
          return hives.length;
        })
      )
      .subscribe(() => {
        this.checkDoApiaryHivesEval();
        // console.log('all needed entities loaded');
        this.loading = false;
        this.initial_loading = false;
      });
  }

  // #region ↛ (modal views manager)

  /**
   * Move the user to the next evaluation step.
   */
  public goNextState() {
    this.changeState(this.next_state);
  }

  /**
   * Updates next / previous states depending on the current state.
   */
  private updateNextPreviousStates() {
    switch (this.state) {
      case ActiveView.CONFIG: {
        this.previous_state = ActiveView.CONFIG;

        if (this.eval_apiary) {
          this.next_state = ActiveView.FORM_A;
        } else if (this.eval_hives_fast) {
          this.next_state = ActiveView.FORM_HF;
        } else if (this.eval_hives_precise) {
          this.next_state = ActiveView.FORM_HP;
        } else {
          this.next_state = ActiveView.SUMMARY;
        }

        break;
      }

      case ActiveView.FORM_A: {
        this.previous_state = ActiveView.CONFIG;

        if (this.eval_hives_fast) {
          this.next_state = ActiveView.FORM_HF;
        } else if (this.eval_hives_precise) {
          this.next_state = ActiveView.FORM_HP;
        } else {
          this.next_state = ActiveView.SUMMARY;
        }

        break;
      }

      case ActiveView.FORM_HF: {
        if (this.eval_apiary) {
          this.previous_state = ActiveView.FORM_A;
        } else {
          this.previous_state = ActiveView.CONFIG;
        }

        this.next_state = ActiveView.SUMMARY;
        break;
      }

      case ActiveView.FORM_HP: {
        if (this.eval_apiary) {
          this.previous_state = ActiveView.FORM_A;
        } else {
          this.previous_state = ActiveView.CONFIG;
        }

        this.next_state = ActiveView.SUMMARY;
        break;
      }

      case ActiveView.SUMMARY: {
        if (this.eval_hives_precise) {
          this.previous_state = ActiveView.FORM_HP;
        } else if (this.eval_hives_fast) {
          this.previous_state = ActiveView.FORM_HF;
        } else if (this.eval_apiary) {
          this.previous_state = ActiveView.FORM_A;
        } else {
          this.previous_state = ActiveView.CONFIG;
        }

        this.next_state = ActiveView.SUMMARY;
        break;
      }

      default: {
        throw new Error(`State ${this.state} doesn't exist !`);
      }
    }
  }

  /**
   * Handle actions when the user has changed evaluation step.
   */
  public changeState(new_view: ActiveView, submit = true) {
    let change_valid = true;

    if (new_view === ActiveView.FORM_A && !this.eval_apiary) {
      change_valid = false;
    } else if (new_view === ActiveView.FORM_HF && !this.eval_hives_fast) {
      change_valid = false;
    } else if (new_view === ActiveView.FORM_HP && !this.eval_hives_precise) {
      change_valid = false;
    }

    if (!change_valid) {
      return;
    }

    const do_next = () => {
      // Change view
      this.state = new_view;
      this.updateNextPreviousStates();

      // Post view change
      const force_scroll = true;

      switch (new_view) {
        case ActiveView.CONFIG:
          break;

        case ActiveView.FORM_A:
          break;

        case ActiveView.FORM_HF:
          break;

        case ActiveView.FORM_HP:
          setTimeout(() => {
            this.scrollHive();
          }, 10);
          break;

        default:
          break;
      }
    };

    // Create agreement pipe
    let agreement_pipe: Observable<boolean> = of<boolean>(true);

    if (this.state === ActiveView.CONFIG && new_view !== ActiveView.CONFIG) {
      // Add future date confirmation
      const is_event_date_in_future = isAfter(new Date(this.event.date), endOfDay(new Date()));
      const ask_for_creation = is_event_date_in_future && isNil(this.saved_event_date);
      const ask_for_update =
        !isNil(this.saved_event_date) && !isEqual(this.saved_event_date, new Date(this.event.date)) && is_event_date_in_future;

      if (ask_for_creation || ask_for_update) {
        agreement_pipe = agreement_pipe.pipe(
          switchMap(last_agreement =>
            last_agreement
              ? this.dialogs.confirm(
                  i18n<string>(
                    `VIEWS.MODALS.FORM.The date you've picked is in the future. Are you sure this is the date you want to use ?`
                  ),
                  {
                    onFalseMessage: i18n<string>('VIEWS.MODALS.FORM.Cancel'),
                    onTrueMessage: i18n<string>('VIEWS.MODALS.FORM.Confirm'),
                  }
                )
              : of(false)
          )
        );
      }
    }

    // Add confirmation of multiple creation
    if (new_view === ActiveView.SUMMARY) {
      const new_hives_ids = (this.event.apply_to.hives || []).filter(element => element.entity_id < 0).map(el => el.entity_id);
      const created_hives = this.all_hives.filter(hive => (hive.has_changed === true && hive.id < 0) || new_hives_ids.includes(hive.id));

      if (this.eval_hives_precise && created_hives.length > 0) {
        agreement_pipe = agreement_pipe.pipe(
          switchMap(last_agreement =>
            last_agreement
              ? this.dialogs.confirm(i18n<string>('VIEWS.MODALS.EVAL.Confirm hive creation (this may take some time)'), {
                  onTrueMessage: i18n<string>('VIEWS.MODALS.FORM.Confirm'),
                  onFalseMessage: i18n<string>('VIEWS.MODALS.FORM.Cancel'),
                })
              : of(false)
          )
        );
      }
    }

    agreement_pipe.subscribe({
      next: has_agreed => {
        if (!has_agreed) {
          return;
        }

        switch (new_view) {
          case ActiveView.CONFIG:
            this.preventFormDefaultOveride();
            do_next();
            break;

          case ActiveView.FORM_A:
            this.rebuildApiaryEvaluationForm();
            this.preventFormDefaultOveride();
            do_next();
            break;

          case ActiveView.FORM_HF:
            this.reBuildHivesFastEvaluationForm();
            this.preventFormDefaultOveride();
            do_next();
            break;

          case ActiveView.FORM_HP:
            this.reBuildHivesPreciseEvaluationForms();
            this.preventHivePreciseFormDefaultOveride(this.selected_hive_num);
            do_next();
            break;

          case ActiveView.SUMMARY: {
            this.submit();
            do_next();
            break;
          }

          default:
            break;
        }
      },
    });
  }

  // #endregion

  // #region ↛ (form building)

  private buildAndBindConfigForm() {
    this.loading = true;
    if (isNil(this.event)) {
      return;
    }

    this.unsubscribeConfigFormSchema();
    // Trigger the build of the full event schema
    this.config_form_schema_sub = EventForm.buildSchema(this.event, this.args).subscribe(full_event_schema => {
      // console.log(full_event_schema, this.config_schema);
      if (isEqual(this.config_schema, full_event_schema)) {
        return;
      }
      // console.log('New event form schema :');
      // console.log(full_event_schema);

      // Build summary view "schema"`
      this.HF_PROD_VISIT_FIELD.map(field => {
        const apiary_eval_schema = full_event_schema.properties.data.properties.evaluation_apiary;
        const hf_prod_visit_res = apiary_eval_schema.properties.hf_prod_visit.properties.result;
        const field_schema = hf_prod_visit_res.properties[field];
        if (field_schema) {
          this.summary_histo_config[field] = keys(field_schema.properties).map(value => {
            const vschema = field_schema.properties[value];
            const hist_conf: HistogramItemConfig = {
              key: value,
              label: vschema.label || value,
            };
            if (vschema.options && vschema.options.img) {
              hist_conf.img = vschema.options.img;
            }
            return hist_conf;
          });
        }
      });
      //
      this.full_event_schema = full_event_schema;
      // Build config schema from event schema
      const config_schema = cloneDeep(full_event_schema);
      config_schema.order = ['data', 'date', 'apply_to', 'author'];
      config_schema.properties.apply_to.title = i18n('EVENT.VISIT.Informations');
      delete config_schema.properties.data.properties.evaluation_apiary;
      delete config_schema.properties.data.properties.evaluation_hives;
      config_schema.properties.apply_to.properties.hives.widget = 'hidden';

      // Add user selector
      config_schema.properties.author = {
        type: 'number',
        widget: 'bg2user',
        default: this.appState.user.id,
        label: 'VIEWS.MODALS.EVAL.Visit done by',
        options: {
          readonly: true,
        },
      };

      // console.log('New config schema :');
      // console.log(config_schema);

      // Set the form schema
      this.last_form_model = this.getFullModelFromEvent();
      this.form_model = cloneDeep(this.last_form_model);
      if (this.eid) {
        // We are editing an existing event
        this.preventFormDefaultOveride(); // Force "last" model to be loaded not the default values
      }
      // console.warn('Inital form model');
      // console.log(_.cloneDeep(this.form_model));
      this.config_schema = config_schema;
      this.configChanged();

      this.loading = false;
      this.initial_loading = false;

      // apiary and hives schema will be build when view changed
    });
  }

  get eval_config() {
    if (!this.form_model || !this.form_model.data) {
      return {};
    }
    return this.form_model.data.config || {};
  }

  private unsubscribeConfigFormSchema(): void {
    this.config_form_schema_sub?.unsubscribe();
  }

  private getFullModelFromEvent() {
    const model = this.event.export();
    // Copy hf_prod_visit ==> global_hf_prod_result
    const apiary_eval_model = model.data?.evaluation_apiary || {};
    if (apiary_eval_model.hf_prod_visit) {
      apiary_eval_model.global_hf_prod_result = cloneDeep(apiary_eval_model.hf_prod_visit.result);
    }
    return model;
  }

  private rebuidlInitialApiaryEvaluationForm() {
    if (isNil(this.eval_config)) {
      // TODO error
      return;
    }
    // Build apiary eval form schema
    const initial_apiary_evaluation_schema: ISchema = cloneDeep(this.full_event_schema);
    initial_apiary_evaluation_schema.properties.date.widget = 'hidden';
    initial_apiary_evaluation_schema.properties.apply_to.widget = 'hidden';
    initial_apiary_evaluation_schema.properties.data.properties.config.widget = 'hidden';
    delete initial_apiary_evaluation_schema.properties.data.properties.evaluation_hives;

    // Add global_hf_prod_result
    const sub_apiary_evaluation_schema = initial_apiary_evaluation_schema.properties.data.properties.evaluation_apiary;
    const global_hf_prod_result = cloneDeep(sub_apiary_evaluation_schema.properties.hf_prod_visit.properties.result);
    sub_apiary_evaluation_schema.properties.global_hf_prod_result = global_hf_prod_result;

    if (initial_apiary_evaluation_schema?.properties?.data?.properties?.evaluation_apiary?.properties?.hf_prod_visit?.properties) {
      initial_apiary_evaluation_schema.properties.data.properties.evaluation_apiary.properties.hf_prod_visit.properties.comment = {
        type: 'string',
        widget: 'ng-mat-textarea',
        label: i18n<string>('ALL.COMMON.Comment'),
      };
    }

    // Save the schema as attr
    return initial_apiary_evaluation_schema;
  }

  private rebuildApiaryEvaluationForm() {
    if (isNil(this.eval_config)) {
      // TODO error
      return;
    }
    if (!this.eval_apiary) {
      return;
    }
    // Build apiary eval form schema
    const apiary_evaluation_schema = this.rebuidlInitialApiaryEvaluationForm();
    const sub_apiary_evaluation_schema = apiary_evaluation_schema.properties.data.properties.evaluation_apiary;

    // Hide hf_*
    sub_apiary_evaluation_schema.properties.hf_prod_visit.widget = 'hidden';
    // if (true) {
    //   // TODO do not delete it in some case, but add an option to have a simplified version of it
    //   sub_apiary_evaluation_schema.properties['hf_rearing_visit'].widget = 'hidden';
    // }

    // Configure apiary evaluation part (from config)
    const config_apiary = this.eval_config.apiary || [];

    // console.log('[debug] -> ', this.HF_PROD_VISIT_FIELD, sub_apiary_evaluation_schema.properties['global_hf_prod_result'].properties);

    this.HF_PROD_VISIT_FIELD.map(field => {
      if (!config_apiary.includes(field)) {
        sub_apiary_evaluation_schema.properties.global_hf_prod_result.properties[field].widget = 'hidden';
      }
    });

    if (indexOf(config_apiary, 'hardware_stock') < 0) {
      sub_apiary_evaluation_schema.properties.hardware_stock.widget = 'hidden';
    }
    if (indexOf(config_apiary, 'sanitary') < 0) {
      sub_apiary_evaluation_schema.properties.sanitary.widget = 'hidden';
    }
    if (indexOf(config_apiary, 'feeding') < 0) {
      sub_apiary_evaluation_schema.properties.feeding.widget = 'hidden';
    }
    if (indexOf(config_apiary, 'next_actions') < 0) {
      sub_apiary_evaluation_schema.properties.next_actions.widget = 'hidden';
    }

    // Build the "initial" form model
    this.last_form_model = this.getFullModelFromEvent();

    // We are editing an existing event
    this.form_model = cloneDeep(this.last_form_model);
    this.preventFormDefaultOveride(); // Force "last" model to be loaded not the default values
    // console.warn('Inital (apiary) forl model');
    // console.log(_.cloneDeep(this.form_model));

    // Set the form schema
    this.apiary_evaluation_schema = apiary_evaluation_schema;
  }

  private reBuildHivesFastEvaluationForm() {
    if (isNil(this.event)) {
      return;
    }
    if (isNil(this.full_event_schema)) {
      return;
    }

    if (!this.eval_hives_fast) {
      return;
    }
    // Buidl appiary eval form schema
    const config = this.eval_config;
    // console.log('config', config);
    const config_hfast = config.hives_fast || {};

    // Build apiary eval form schema
    const hf_evaluation_schema = this.rebuidlInitialApiaryEvaluationForm();

    // Fix apiary evaluation part (from config)
    const sub_hf_evaluation_schema = hf_evaluation_schema.properties.data.properties.evaluation_apiary;
    keys(sub_hf_evaluation_schema.properties).map(prop => {
      if (!prop.startsWith('hf_')) {
        sub_hf_evaluation_schema.properties[prop].widget = 'hidden';
      }
    });
    if (config_hfast.hives_status_config.length > 0) {
      // TODO copy config to widget options
      const options = assign(sub_hf_evaluation_schema.properties.hf_prod_visit.options, {
        fields: config_hfast.hives_status_config,
        hive_types: config_hfast.hive_types,
      });
      sub_hf_evaluation_schema.properties.hf_prod_visit.options = options;
    } else {
      delete sub_hf_evaluation_schema.properties.hf_prod_visit;
    }

    if (config_hfast.rearing_status) {
      // TODO copy config to widget options
    } else {
      delete sub_hf_evaluation_schema.properties.hf_rearing_visit;
    }

    // Build the "initial" form model
    this.last_form_model = this.getFullModelFromEvent();
    this.form_model = cloneDeep(this.last_form_model);
    this.preventFormDefaultOveride(); // Force "last" model to be loaded not the default values
    // console.warn('Inital (hive fast) form model');
    // console.log(_.cloneDeep(this.form_model));

    // Set the form schema
    this.hf_form_schema = hf_evaluation_schema;
  }

  private reBuildHivesPreciseEvaluationForms() {
    if (isNil(this.event)) {
      return;
    }
    if (isNil(this.full_event_schema)) {
      return;
    }

    if (!this.eval_hives_precise) {
      return;
    }

    this.loading = true;

    this.all_hives = [];
    this.all_hives_idx = {};
    this.hp_form_schema = [];

    // Get total number of hives and nucs of apiary
    let nb_hives: number = this.apiary_state_at_date?.state?.nb_hives || 0;
    let nb_nuc: number = this.apiary_state_at_date?.state?.nb_nuc || 0;

    // TODO build hive on config exit (and rebuild on modifications)
    this.existing_hives.map(hive => {
      if (hive.htype === 'nuc') {
        nb_nuc -= 1;
      } else {
        nb_hives -= 1;
      }
      this.all_hives.push(hive);
    });

    // Avoid bug on fast visit with unknow or 0 hives
    if (this.existing_hives.length === 0 && nb_hives === 0 && nb_nuc === 0) {
      nb_hives++;
    }

    of({ _nb_hives: nb_hives, _nb_nuc: nb_nuc })
      .pipe(
        switchMap(({ _nb_hives, _nb_nuc }) => {
          const hives$$: Observable<Hive>[] = [];

          // Add missing "ghost" hives
          range(_nb_hives).forEach(hive_num => {
            hives$$.push(
              Hive.create(
                {
                  htype: 'hive',
                  apiary: this.apiary,
                },
                { bg2Api: this.bg2Api, deviceApi: this.deviceApi },
                { translate: this.translate },
                { offset: hive_num }
              ).pipe(
                map(hive => {
                  hive.will_be_created_after_visit = true;
                  return hive;
                })
              )
            );
          });

          // Add missing "ghost" nuc
          range(_nb_nuc).forEach(hive_num => {
            hives$$.push(
              Hive.create(
                {
                  htype: 'nuc',
                  apiary: this.apiary,
                },
                { bg2Api: this.bg2Api, deviceApi: this.deviceApi },
                { translate: this.translate },
                { offset: hive_num }
              ).pipe(
                map(nuc => {
                  nuc.will_be_created_after_visit = true;
                  return nuc;
                })
              )
            );
          });

          return robustCombineLatest(hives$$);
        }),
        take(1)
      )
      .subscribe({
        next: hives => {
          this.all_hives.push(...hives);
          this.all_hives.map((hive, hive_num) => {
            this.all_hives_idx[hive.id] = hive;
            this.rebuildHivePreciseEvaluationForm(hive_num);
          });

          this.loading = false;
        },
      });
  }

  /**
   * Rebuild the form schema of a specific hive.
   *
   * @param hive_num The index number of the hvie.
   */
  private rebuildHivePreciseEvaluationForm(hive_num: number) {
    if (isNil(this.eval_config)) {
      // TODO error ?
      return;
    }
    const hive = this.all_hives[hive_num];

    const hp_evaluation_schema = this.rebuidlInitialApiaryEvaluationForm();
    hp_evaluation_schema.properties.data.properties.evaluation_apiary.widget = 'hidden';

    // Hive eval widget
    const sub_hp_evaluation_schema = cloneDeep(this.full_event_schema.properties.data.properties.evaluation_hives.items);
    hp_evaluation_schema.properties.data.properties.evaluation_hive = sub_hp_evaluation_schema;

    const config = this.eval_config.hives_precise || [];
    if (indexOf(config, 'brood_frame') < 0) {
      // Note waring brood_frame vs brood_box
      sub_hp_evaluation_schema.properties.brood_box.widget = 'hidden';
    }
    if (indexOf(config, 'queen') < 0) {
      sub_hp_evaluation_schema.properties.queen.widget = 'hidden';
    }
    if (indexOf(config, 'feeding') < 0) {
      sub_hp_evaluation_schema.properties.feeding.widget = 'hidden';
    }
    if (indexOf(config, 'supers') < 0) {
      sub_hp_evaluation_schema.properties.supers.widget = 'hidden';
    }
    if (indexOf(config, 'next_actions') < 0) {
      sub_hp_evaluation_schema.properties.next_actions.widget = 'hidden';
    }

    // Add (some) hive static properties
    sub_hp_evaluation_schema.order = ['hive'].concat(keys(sub_hp_evaluation_schema.properties));
    sub_hp_evaluation_schema.properties.hive = {
      type: 'object',
      title: i18n('ENTITY.ALL.TYPE.hive'),
      options: {
        title_style: 'large',
      },
      required: ['name', 'htype'],
      properties: {
        // TODO get it from hive static state schema
        name: {
          type: 'string',
          label: 'ENTITY.HIVE.Name of the hive',
        },
        htype: {
          label: 'EVENT.HIVE.Hive type',
          type: 'string',
          widget: 'radio',
          oneOf: [
            {
              enum: ['hive'],
              label: 'ENTITY.ALL.TYPE.hive',
              options: {
                img: 'img/hive.png',
              },
            },
            {
              enum: ['nuc'],
              label: 'ENTITY.ALL.TYPE.nuc',
              options: {
                img: 'img/nuc_hive.png',
              },
            },
          ],
        },
      },
    };

    // TODO: this part of code is nerver used while making a visit, needs some tests before prod
    // if (hive.id > 0) {
    //   hp_evaluation_schema.entity_ids = {  // Add the hive in the schema to get it in form widgets
    //     hive: hive.id
    //   };
    // }

    // Build the "initial" form model
    this.last_hp_form_model[hive_num] = this.getFullModelFromEvent();
    // console.log('[debug] -> last_hp_form_model: ', this.last_hp_form_model);

    // Get form data with same hive_id (since hive_num may change...)
    const data_by_hive_id: Dictionary<any> = {};
    (this.event.data.evaluation_hives || []).map((hive_data: any) => {
      data_by_hive_id[hive_data.hive_id] = hive_data;
    });

    // create specific hive model
    const model_from_event = data_by_hive_id[hive.id] || {
      hive_id: hive.id,
    };
    model_from_event.hive = cloneDeep(hive.static_state);
    model_from_event.hive.htype = hive.htype;
    this.last_hp_form_model[hive_num].data.evaluation_hive = cloneDeep(model_from_event);

    this.hp_form_model[hive_num] = cloneDeep(this.last_hp_form_model[hive_num]);
    this.ignore_default_hp_form[hive_num] = true;

    // console.log('hive model', this.hp_form_model[hive_num], hive.static_state);
    // Set the schema
    // console.log('hive schema', hp_evaluation_schema);
    this.hp_form_schema[hive_num] = hp_evaluation_schema;
  }

  /** Dirty fixes to get last data when form reload.
   * Else the double model binding load default values...
   *
   */
  private preventFormDefaultOveride() {
    this.ignore_default_form = true;
  }

  private preventHivePreciseFormDefaultOveride(hive_num: number) {
    this.ignore_default_hp_form[hive_num] = true;
  }

  public addHiveIntoHivePreciseEval() {
    const props: CustomazibleDialogParams<'cancel' | HiveType> = {
      body: {
        type: 'div',
        styles: { 'font-weight': 'bold' },
        class: ['col-centered'],
        elements: [
          {
            type: 'span',
            styles: { 'font-size': '20px' },
            content: i18n<string>('VIEWS.MODALS.EVAL.What type of hive do you want to create?'),
          },
          {
            type: 'button',
            styles: { 'font-size': '17px' },
            icon: null,
            result: 'hive',
            color: 'primary',
            content: i18n<string>('VIEWS.MODALS.EVAL.Add an hive'),
          },
          {
            type: 'logical-sep',
            messageType: 'OR',
          },
          {
            styles: { 'font-size': '17px' },
            type: 'button',
            icon: null,
            result: 'nuc',
            color: 'primary',
            content: i18n<string>('VIEWS.MODALS.EVAL.Add a nuc'),
          },
        ],
      },
      footer: {
        styles: { 'flex-direction': 'column' },
        buttons: {
          styles: { margin: '8px 0px 0px 0px' },
          items: [
            {
              type: 'button',
              content: i18n<string>('VIEWS.MODALS.FORM.Cancel'),
              result: 'cancel',
            },
          ],
        },
      },
    };

    this.dialogs
      .customizable(props)
      .pipe(
        map(res => res.return_value),
        switchMap((dialog_result: 'cancel' | HiveType) => {
          if (dialog_result === 'cancel') {
            return of<Hive>(null);
          }

          const nb_hives = this.all_hives.filter(h => h.htype === 'hive' && !(h.id > 0))?.length || 0;
          const nb_nucs = this.all_hives.filter(h => h.htype === 'nuc' && !(h.id > 0))?.length || 0;

          return Hive.create(
            { htype: dialog_result, apiary: this.apiary },
            { bg2Api: this.bg2Api, deviceApi: this.deviceApi },
            { translate: this.translate },
            { offset: dialog_result === 'hive' ? nb_hives : nb_nucs }
          );
        }),
        take(1)
      )
      .subscribe({
        next: hive => {
          if (!isNil(hive)) {
            hive.forceHasChanged();

            // Uppdate hives
            this.all_hives.push(hive);
            this.all_hives_idx[hive.id] = hive;

            // Rebuild hives form
            this.rebuildHivePreciseEvaluationForm(this.all_hives.length - 1);
            this.selectHive(this.all_hives.length - 1);
          }
        },
      });
  }

  // #endregion

  /***************************************************************************/
  /**   Forms modification callbacks (config, apiary, hf, hp)
   */

  /** Does we evaluate hive ? precise fast ? */
  private checkDoApiaryHivesEval() {
    // console.log('checkDoApiaryHivesEval()');
    const config = this.eval_config;

    const config_apiary = config.apiary || [];
    const config_hfast = config.hives_fast || {};

    // Check eval apiary
    this.eval_apiary = config_apiary.length > 0;

    // hive fast input
    this.eval_hives_fast = false;
    if (config.by_hives === 'fast') {
      this.eval_hives_fast = config_hfast.hives_status_config.length > 0;
    }

    // hive precise input
    if (!this.eval_hives_fast) {
      const config_hives = config.hives_precise || [];
      this.eval_hives_precise = config_hives.length > 0;
    } else {
      this.eval_hives_precise = false;
    }

    this.updateNextPreviousStates();
  }

  public configChanged() {
    let model_changed = false;
    const conf = this.eval_config || {};
    const conf_apiary = conf.apiary || [];
    const conf_hf_prod = conf.hives_fast?.hives_status_config || [];
    const conf_hp = conf.hives_precise || [];

    const last_conf = this.last_form_model?.data?.config || {};
    const last_conf_hf_prod = last_conf.hives_fast?.hives_status_config || [];
    const last_conf_hp = last_conf.hives_precise || [];

    // Filter exclusif conf :
    this.EXLUSIF_FIELDS.map(field => {
      if (conf_apiary.includes(field)) {
        const hf = conf_hf_prod.includes(field);
        const last_hf = last_conf_hf_prod.includes(field);
        const hp = conf_hp.includes(field);
        // Hive status is needed in precise mode
        const last_hp = last_conf_hp.includes(field) && field !== 'hive_status';
        if ((hf && !last_hf) || (hp && !last_hp)) {
          // New in HF ok HP
          pull(conf_apiary, field);
          model_changed = true;
        } else {
          if (hf) {
            pull(conf_hf_prod, field);
            model_changed = true;
          }
          if (hp) {
            pull(conf_hp, field);
            model_changed = true;
          }
        }
      }
    });

    if (model_changed) {
      setTimeout(() => {
        // Retriger onFormChange
        this.form_model = cloneDeep(this.form_model);
      }, 10);
    }
    this.checkDoApiaryHivesEval();
  }

  public last_form_model: any;
  public ignore_default_form = false; // It true FromChanged are ignored
  private reset_in_progress = 0; // number of form reset to apply to avoid default
  private ignore_default_form_to: any;

  private resetFormModel() {
    // console.warn('--- RESET LAST ---', this.reset_in_progress);
    this.form_model = cloneDeep(this.last_form_model);
    this.ignore_default_form = false;
    this.reset_in_progress--;
  }

  public onFormChange(event: any) {
    // console.log('### onFormChange(fdata)');
    if (!this.isEventFormReady()) {
      return;
    }
    if (isNil(this.event)) {
      return;
    }
    if (isNil(event.value)) {
      return;
    }
    // return if same as previous...
    if (isEqual(this.last_form_model, event.value)) {
      return;
    }
    // console.warn('### onFormChange(fdata)', this.ignore_default_form, this.reset_in_progress);
    // console.log(_.cloneDeep(event.value));
    // console.log(_.cloneDeep(this.form_model));
    // console.log(_.cloneDeep(this.last_form_model));

    if (this.ignore_default_form || this.reset_in_progress > 0) {
      // Data reset in progress
      if (this.ignore_default_form && this.reset_in_progress <= 0) {
        // First "RESET", whe need at least two other reset (to avoid issue with default value on embeded formpropery)
        this.reset_in_progress = 2;
      }
      if (isNil(this.ignore_default_form_to) || this.ignore_default_form) {
        if (this.ignore_default_form_to) {
          clearTimeout(this.ignore_default_form_to);
        }
        this.ignore_default_form_to = setTimeout(() => {
          clearTimeout(this.ignore_default_form_to); // clear itself to be able to know if this is over or not
          this.ignore_default_form_to = null;
          this.resetFormModel();
        }, 40);
      }
    } else {
      if (!isEqual(event.value.data?.config, this.last_form_model.data?.config)) {
        this.configChanged();
      }
      this.last_form_model = cloneDeep(event.value);
      this.updateEventFromModels(true, false);
    }
  }

  public hivePreciseEvaluationFormChange(hive_num: any, event: any) {
    if (!this.isEventFormReady()) {
      return;
    }
    if (isNil(this.event)) {
      return;
    }
    if (isNil(event.value)) {
      return;
    }
    if (isEqual(this.last_hp_form_model[hive_num], event.value)) {
      return;
    }

    // console.log('[debug] -> hivePreciseEvaluationFormChange: ', hive_num, event);

    // console.log('### hiveEvaluationFormChange(fdata)', hive_num, this.ignore_default_hp_form[hive_num]);
    // console.log(_.cloneDeep(event.value));
    // console.log(_.cloneDeep(this.hp_form_model[hive_num]));
    // console.log(_.cloneDeep(this.last_hp_form_model[hive_num]));
    if (this.ignore_default_hp_form[hive_num]) {
      if (this.ignore_default_hp_form_to[hive_num]) {
        clearTimeout(this.ignore_default_hp_form_to[hive_num]);
      }
      this.ignore_default_hp_form_to[hive_num] = setTimeout(() => {
        this.hp_form_model[hive_num] = cloneDeep(this.last_hp_form_model[hive_num]);
        this.ignore_default_hp_form[hive_num] = false;
      }, 30);
    } else {
      this.last_hp_form_model[hive_num] = cloneDeep(event.value);
      this.updateEventFromModels(false, true);
      this.updateHiveState(hive_num);
    }
  }

  /***************************************************************************/
  /** Change the event from current forms models
   */
  protected updateEventFromModels(apiary = false, hives = false) {
    const date: Date = this.last_form_model.date;
    const apply_to = cloneDeep(this.last_form_model.apply_to);
    // ^ TODO preserve hives apply_to ?
    const data: any = cloneDeep(this.event.data);
    // Get config data
    if (!isNil(data)) {
      data.config = cloneDeep(this.eval_config);
    }
    // Get apiary evalutation data
    if (apiary) {
      // initial apiary data
      if (this.last_form_model.data && this.last_form_model.data.evaluation_apiary) {
        const new_evaluation_apiary = cloneDeep(this.last_form_model.data.evaluation_apiary);
        if (data.evaluation_apiary) {
          // Copy previous hf_*
          new_evaluation_apiary.hf_prod_visit = data.evaluation_apiary.hf_prod_visit;
          new_evaluation_apiary.hf_rearing_visit = data.evaluation_apiary.hf_rearing_visit;
        }
        data.evaluation_apiary = new_evaluation_apiary;
      } // else, we keep previous data.evaluation_apiary

      // Add hf model if any
      if (this.eval_hives_fast && this.last_form_model && this.last_form_model.data && this.last_form_model.data.evaluation_apiary) {
        apply_to.hives = [];
        // Note: this is done before all apiary data managment to prevent overide
        if (!data.evaluation_apiary) {
          data.evaluation_apiary = {};
        }
        const hf_evaluation_apiary = this.last_form_model.data.evaluation_apiary;
        if (hf_evaluation_apiary.hf_prod_visit) {
          data.evaluation_apiary.hf_prod_visit = cloneDeep(hf_evaluation_apiary.hf_prod_visit);
        }
        // if (hf_evaluation_apiary.hf_rearing_visit) {
        //   data.evaluation_apiary['hf_rearing_visit'] = _.cloneDeep(hf_evaluation_apiary.hf_rearing_visit);
        // }
      }

      const global_hf_prod_result = (this.last_form_model.data.evaluation_apiary || {}).global_hf_prod_result;
      if (global_hf_prod_result && keys(global_hf_prod_result).length) {
        // get back "visit" data
        // if we have "global" values, and the config indicate that we should have it globaly
        // Then we override visit result
        this.HF_PROD_VISIT_FIELD.map(field => {
          if (this.eval_config.apiary && this.eval_config.apiary.includes(field) && global_hf_prod_result[field]) {
            if (!data.evaluation_apiary.hf_prod_visit) {
              data.evaluation_apiary.hf_prod_visit = {};
            }
            if (!data.evaluation_apiary.hf_prod_visit.result) {
              data.evaluation_apiary.hf_prod_visit.result = {};
            }
            data.evaluation_apiary.hf_prod_visit.result[field] = cloneDeep(global_hf_prod_result[field]);
          }
        });
        // console.log('Manage visit data', _.cloneDeep(data.evaluation_apiary));
        delete data.evaluation_apiary.global_hf_prod_result;
      }
    }

    if (hives && this.last_hp_form_model) {
      const hives_models = this.last_hp_form_model.map(fmodel => fmodel.data.evaluation_hive);
      // Extract precise evaluated hives data
      const evaluated_hives = hives_models
        .map((hive_model, index) => {
          const model = cloneDeep(hive_model);
          model.htype = (model.hive || {}).htype;

          const hive = this.all_hives[index];
          if (hive) {
            hive.name = (model.hive || {}).name;
          }

          delete model.hive;
          return model;
        })
        .filter(model => model && model.state && model.state !== 'not_evaluated');

      data.evaluation_hives = evaluated_hives;

      // Get correct apply_to for hives
      apply_to.hives = evaluated_hives.map(model => model.hive_id);
    }
    this.event.update(date, apply_to, data);
  }

  public submit() {
    if (this.loading) {
      return;
    }

    this.error = null;
    this.loading = true;

    let hive_save_pipe: Observable<{ res: UpdateEntityResponse; initial_hive_id: number }[]> = of([]);

    if (this.eval_hives_precise) {
      const evaluated_hives_ids = (this.event.apply_to.hives || []).map(apt => apt.entity_id);

      const hives_to_save = this.all_hives.filter(hive => hive.has_changed || evaluated_hives_ids.includes(hive.id));

      // FIXME: ici on ne sauvegarde que les rucher "EVALUER" hors il est possible de juste modifier une ruche (son nom), sans l'évaluer.
      // save = evalued U modifier
      // [ALL_HIVES]
      //  [evaluated] // event.apply_to.hives
      //  [modified]  // changed static state
      //  [new]       // modified or evaluated ET non existant avant => hives_setup.apply_to.hives
      //  [saved]

      if (hives_to_save.length > 0) {
        const hives_to_save$$: Observable<{ res: UpdateEntityResponse; initial_hive_id: number }>[] = hives_to_save.map(hive => {
          const initial_hive_id = clone(hive.id);
          return hive.save().pipe(map(res => ({ res, initial_hive_id })));
        });

        hive_save_pipe = forkJoin(hives_to_save$$);
      }
    }

    // Update visit event
    const submit_pipe = hive_save_pipe.pipe(
      map(hive_save_res_wh_initial_id => {
        if ((hive_save_res_wh_initial_id ?? []).length > 0) {
          const initial_hive_id_to_new_hive_id: Dictionary<number> = {};
          hive_save_res_wh_initial_id.map((ret: { res: UpdateEntityResponse; initial_hive_id: number }) => {
            initial_hive_id_to_new_hive_id[ret.initial_hive_id] = ret.res.entity.id;
          });

          // Update event data (with new hive ids)
          this.event.data.evaluation_hives.map(
            (ev_hive: Dictionary<any>) => (ev_hive.hive_id = initial_hive_id_to_new_hive_id[ev_hive.hive_id])
          );

          // Update event "apply_to"
          this.event.apply_to.hives = (this.event.apply_to.hives || []).map(apt => {
            apt.entity_id = initial_hive_id_to_new_hive_id[apt.entity_id];
            return apt;
          });
        }

        return hive_save_res_wh_initial_id.map(ret => ret.res);
      }),
      switchMap(hives_ret => {
        // new hives ? then save them with setup event !
        if ((hives_ret ?? []).length === 0) {
          return of(hives_ret);
        }

        // Get hive ids
        const new_hive_ids = hives_ret
          .filter(ret => (ret as any).isNew)
          .filter(ret => (ret as any)._entity.htype === 'hive')
          .map(ret => ret.entity.id);

        if (new_hive_ids.length === 0) {
          return of(hives_ret);
        }

        const setup_new_hives = new Event(this.bg2Api);
        setup_new_hives.date = subMinutes(this.event.date, 1); // Same date than evaluation event to be sure it is done before
        setup_new_hives.type = 'hives_setup';
        setup_new_hives.setOperand('apiary', this.event.apply_to.apiary.entity_id);
        setup_new_hives.setOperand('warehouse', this.event.apply_to.warehouse.entity_id);
        setup_new_hives.setOperand('hives', new_hive_ids);
        setup_new_hives.data = {}
        setup_new_hives.data.htype = 'hive';
        return setup_new_hives.save().pipe(map(() => hives_ret));
      }),
      switchMap(hives_ret => {
        // this._logger.debug(hives_ret);
        // new nuc ? then save them with setup event ?
        if (isNil(hives_ret) || hives_ret.length === 0) {
          return of(hives_ret);
        }

        // Get nuc ids
        const new_nuc_ids = hives_ret
          .filter(ret => (ret as any).isNew)
          .filter(ret => (ret as any)._entity.htype === 'nuc')
          .map(ret => ret.entity.id);

        if (new_nuc_ids.length === 0) {
          return of(hives_ret);
        }

        const setup_new_nucs = new Event(this.bg2Api);
        setup_new_nucs.date = subMinutes(this.event.date, 1); // Same date than evaluation event to be sure it is done before
        setup_new_nucs.type = 'hives_setup';
        setup_new_nucs.setOperand('apiary', this.event.apply_to.apiary.entity_id);
        setup_new_nucs.setOperand('warehouse', this.event.apply_to.warehouse.entity_id);
        setup_new_nucs.setOperand('hives', new_nuc_ids);
        setup_new_nucs.data.htype = 'nuc';
        return setup_new_nucs.save();
      }),
      switchMap(() => this.event.save())
    );

    // Run update event
    submit_pipe.subscribe(
      () => {
        this.loading = false;
        this.initial_loading = false;

        // this._logger.debug(this.eid);
        if (!this.eid) {
          this.eid = this.event.id;
          this.modals_service.changeCurrentParams({ eid: this.eid });
        }
      },
      (err: unknown) => {
        if (err instanceof Error) {
          this._logger.error(`[${err?.name}] ${err?.message}`);
        } else if (err instanceof HttpErrorResponse) {
          this._logger.error(`[${err?.status}] ${err?.statusText}`);
        } else {
          this._logger.error(err);
        }

        this.HTTPError.code = (<any>err).status;
        this.HTTPError.display = true;
        this.loading = false;
        this.initial_loading = false;
        this.handleHttpError(err);
      }
    );
  }

  public HTTPError: HtmlError & { display: boolean } = { code: 0, display: false, msg: null };

  public logErrors(errors: any) {
    if (errors !== null && errors !== undefined) {
      this._logger.log_error(errors);
    }
  }

  /***************************************************************************/
  /* Hive by hive (hp) visit management
   */

  public goNextHive() {
    this.selected_hive_num += 1;
  }

  public selectHive(hive_num: any) {
    if (isEqual(this.selected_hive_num, hive_num)) {
      return;
    }

    this.selected_hive_num = hive_num;
  }

  @HostListener('window:resize')
  public onResize() {
    this.scrollHive();
  }

  @ViewChild('hivesList', { read: ElementRef }) hivesList: ElementRef;
  public scrollHive() {
    if (isNil(this.hivesList)) {
      return;
    }

    const scroll = this.hivesList.nativeElement;
    scroll.scrollLeft = 110 / 2 + 80 * this.selected_hive_num - scroll.offsetWidth / 2;
  }

  private updateHiveState(hive_num: number) {
    this.hive_states[hive_num] = this.hp_form_model[hive_num].data.evaluation_hive.state;
    this.progress = this.hive_states.filter(state => !isNil(state)).filter(state => state !== 'not_evaluated').length;
  }

  public deleteApiaryEvaluation(eventID: number): void {
    DeleteEventDialogComponent.open(this.dialogs, { eventID }).subscribe({
      next: dialog_result => {
        if (dialog_result === DeleteEventDialogOutput.DELETED) {
          this.force_close();
        }
      },
    });
  }
}
