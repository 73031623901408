<div
  class="bg2-superadmin-shortcut-component"
  [matMenuTriggerFor]="mainMenu"
  [matBadge]="total_of_objects$$ | async"
  [matBadgeHidden]="(is_zoho_authenticated$$ | async) === false"
  [ngClass]="{ '--is-not-authenticated': (is_zoho_authenticated$$ | async) === false }"
>
  <img src="assets/img/pictos/super-bee.svg" alt="" />
</div>

<!--  -->
<mat-menu #mainMenu="matMenu" class="superadmin-shotcut-menu">
  <ng-container *ngIf="is_zoho_authenticated$$ | async">
    <!-- Check link between app and zoho -->
    <button
      *ngLet="(zoho_checks_menu_error_key$$ | async)?.error; let error"
      mat-menu-item
      [matMenuTriggerFor]="zohoMainMenu"
      [disabled]="error !== null"
    >
      <div class="mat-menu-item__container">
        <span class="mdi mdi-link mdi-24px"></span>

        <div class="mat-menu-item__container__content">
          <span>Verif. liaison Zoho</span>

          <span class="mat-menu-item__content__status" [ngClass]="{ '--valid': error === null, '--error': error !== null }">
            <ng-container *ngIf="error">
              {{ error?.message | translate }}
            </ng-container>
          </span>
        </div>
      </div>
    </button>

    <!-- See in Books -->
    <button
      *ngLet="links_to_crm$$ | async; let links_or_errors"
      mat-menu-item
      [matMenuTriggerFor]="menuGoToBooksContact"
      [disabled]="links_or_errors?.errors?.length > 0"
    >
      <div class="mat-menu-item__container">
        <img src="assets/picons/zoho/zoho-books-logo.svg" alt="" />

        <div class="mat-menu-item__container__content">
          <span>Voir dans BOOKS</span>

          <span *ngLet="links_or_errors?.errors?.length >= 0" class="mat-menu-item__content__status --error">
            <ng-container *ngIf="links_or_errors?.errors?.length > 0">
              <span>{{ links_or_errors?.errors?.[0]?.message | translate }}</span>
              <span>{{ links_or_errors?.errors?.[1]?.message | translate }}</span>
            </ng-container>
          </span>
        </div>
      </div>
    </button>

    <!-- See in CRM -->
    <button
      *ngLet="links_to_crm$$ | async; let links_or_errors"
      mat-menu-item
      [matMenuTriggerFor]="menuGoToCRMAccountOrContact"
      [disabled]="links_or_errors?.errors?.length > 0"
    >
      <div class="mat-menu-item__container">
        <img src="assets/picons/zoho/zoho-crm-logo.svg" alt="" />

        <div class="mat-menu-item__container__content">
          <span>Voir dans CRM</span>

          <span *ngLet="links_or_errors?.errors?.length >= 0" class="mat-menu-item__content__status --error">
            <ng-container *ngIf="links_or_errors?.errors?.length > 0">
              <span>{{ links_or_errors?.errors?.[0]?.message | translate }}</span>
              <span>{{ links_or_errors?.errors?.[1]?.message | translate }}</span>
            </ng-container>
          </span>
        </div>
      </div>
    </button>

    <!-- See in DESK -->
    <button
      *ngLet="links_to_desk$$ | async; let links_or_errors"
      mat-menu-item
      [matMenuTriggerFor]="menuGoToDESKAccountOrContact"
      [disabled]="links_or_errors?.errors?.length > 0"
    >
      <div class="mat-menu-item__container">
        <img src="assets/picons/zoho/zoho-desk-logo.svg" alt="" />

        <div class="mat-menu-item__container__content">
          <span>Voir dans DESK</span>

          <span *ngLet="links_or_errors?.errors?.length >= 0" class="mat-menu-item__content__status --error">
            <ng-container *ngIf="links_or_errors?.errors?.length > 0">
              <span>{{ links_or_errors?.errors?.[0]?.message | translate }}</span>
              <span>{{ links_or_errors?.errors?.[1]?.message | translate }}</span>
            </ng-container>
          </span>
        </div>
      </div>
    </button>

    <mat-divider></mat-divider>

    <!-- Menu link : list of tickets (for selected exploitation) -->
    <button
      *ngLet="(tickets$$ | async)?.error; let error"
      mat-menu-item
      [matMenuTriggerFor]="menuListOfTickets"
      [disabled]="error !== null"
    >
      <div class="mat-menu-item__container">
        <span class="mdi mdi-ticket-confirmation mdi-24px"></span>

        <div class="mat-menu-item__container__content">
          <span>Tickets DESK</span>

          <span class="mat-menu-item__content__status" [ngClass]="{ '--valid': error === null, '--error': error !== null }">
            <ng-container *ngIf="error">
              {{ error?.message | translate }}
            </ng-container>
          </span>
        </div>

        <div *ngIf="(tickets_total$$ | async) >= 0" class="mat-menu-item__badge">
          {{ tickets_total$$ | async }}
        </div>
      </div>
    </button>

    <!-- Menu link : list of notes (for selected exploitation) -->
    <button *ngLet="(notes$$ | async)?.error; let error" mat-menu-item [matMenuTriggerFor]="menuListOfNotes" [disabled]="error !== null">
      <div class="mat-menu-item__container">
        <span class="mdi mdi-note-multiple mdi-24px"></span>

        <div class="mat-menu-item__container__content">
          <span>Notes CRM</span>

          <span class="mat-menu-item__content__status" [ngClass]="{ '--valid': error === null, '--error': error !== null }">
            <ng-container *ngIf="error">
              {{ error?.message | translate }}
            </ng-container>
          </span>
        </div>

        <div *ngIf="(notes_total$$ | async) >= 0" class="mat-menu-item__badge">
          {{ notes_total$$ | async }}
        </div>
      </div>
    </button>

    <!-- Menu link : list of tasks (for selected exploitation) -->
    <button *ngLet="(tasks$$ | async)?.error; let error" mat-menu-item [matMenuTriggerFor]="menuListOfTasks" [disabled]="error !== null">
      <div class="mat-menu-item__container">
        <span class="mdi mdi-list-status mdi-24px"></span>

        <div class="mat-menu-item__container__content">
          <span>Tâches CRM</span>

          <span class="mat-menu-item__content__status" [ngClass]="{ '--valid': error === null, '--error': error !== null }">
            <ng-container *ngIf="error">
              {{ error?.message | translate }}
            </ng-container>
          </span>
        </div>

        <div *ngIf="(tasks_total$$ | async) >= 0" class="mat-menu-item__badge">
          {{ tasks_total$$ | async }}
        </div>
      </div>
    </button>

    <!-- Menu link : list of deals (for selected exploitation) -->
    <button *ngLet="(deals$$ | async)?.error; let error" mat-menu-item [matMenuTriggerFor]="menuListOfDeals" [disabled]="error !== null">
      <div class="mat-menu-item__container">
        <span class="mdi mdi-handshake mdi-24px"></span>

        <div class="mat-menu-item__container__content">
          <span>Opportunites en cours</span>

          <span class="mat-menu-item__content__status" [ngClass]="{ '--valid': error === null, '--error': error !== null }">
            <ng-container *ngIf="error">
              {{ error?.message | translate }}
            </ng-container>
          </span>
        </div>

        <div *ngIf="(deals_total$$ | async) >= 0" class="mat-menu-item__badge">
          {{ deals_total$$ | async }}
        </div>
      </div>
    </button>

    <!-- Menu link : list of invoices (for selected exploitation) -->
    <button *ngLet="(deals$$ | async)?.error; let error" mat-menu-item [matMenuTriggerFor]="menuListOfInvoices" [disabled]="error !== null">
      <div class="mat-menu-item__container">
        <span class="mdi mdi-cash-multiple mdi-24px"></span>

        <div class="mat-menu-item__container__content">
          <span>Factures en cours</span>

          <span class="mat-menu-item__content__status" [ngClass]="{ '--valid': error === null, '--error': error !== null }">
            <ng-container *ngIf="error">
              {{ error?.message | translate }}
            </ng-container>
          </span>
        </div>

        <div *ngIf="(invoices_total$$ | async) >= 0" class="mat-menu-item__badge">
          {{ invoices_total$$ | async }}
        </div>
      </div>
    </button>

    <mat-divider></mat-divider>
  </ng-container>

  <!-- Login/logout to Zoho -->
  <button
    *ngLet="is_zoho_authenticated$$ | async; let is_zoho_authenticated"
    mat-menu-item
    (click)="is_zoho_authenticated ? logout() : login()"
  >
    <div class="mat-menu-item__container">
      <div class="mat-menu-item__container__content">
        <span class="mat-menu-item__content__auth-status">
          <span *ngIf="is_zoho_authenticated" class="mdi mdi-logout mdi-24px"></span>
          <span *ngIf="!is_zoho_authenticated" class="mdi mdi-login mdi-24px"></span>

          <span *ngIf="is_zoho_authenticated">Se deconnecter de Zoho</span>
          <span *ngIf="!is_zoho_authenticated">Se connecter a Zoho</span>
        </span>
      </div>
    </div>
  </button>
</mat-menu>

<!-- Menu: go to BOOKS account/contact -->
<mat-menu #menuGoToBooksContact class="superadmin-shortcut-base-menu">
  <ng-container *ngIf="is_same_client$$ | async; else differentBooksClient">
    <a
      *ngLet="(links_to_books$$ | async)?.unique; let client_or_error"
      mat-menu-item
      target="_blank"
      [disabled]="client_or_error?.error !== null"
      [href]="client_or_error?.books_contact?.url$$ | async"
    >
      <div class="mat-menu-item__container">
        <span class="mdi mdi-account mdi-24px"></span>

        <div class="mat-menu-item__container__content">
          <span *ngIf="!client_or_error?.error">
            {{ client_or_error?.books_contact?.contact_name$$ | async }}
          </span>

          <span *ngIf="client_or_error?.error">Contact BOOKS</span>

          <span class="mat-menu-item__content__status" [ngClass]="{ '--error': client_or_error?.error !== null }">
            <ng-container *ngIf="!client_or_error?.error">Contact BOOKS</ng-container>

            <ng-container *ngIf="client_or_error?.error">
              {{ client_or_error?.error?.message | translate }}
            </ng-container>
          </span>
        </div>
      </div>
    </a>
  </ng-container>

  <ng-template #differentBooksClient>
    <!-- Go to BOOKS contact (from CRM account)  -->
    <a
      *ngLet="(links_to_books$$ | async)?.links?.client_of_crm_account; let client_or_error"
      mat-menu-item
      target="_blank"
      [disabled]="client_or_error?.error !== null"
      [href]="client_or_error?.books_contact?.url$$ | async"
    >
      <div class="mat-menu-item__container">
        <span class="mdi mdi-account mdi-24px"></span>

        <div class="mat-menu-item__container__content">
          <span *ngIf="!client_or_error?.error">
            {{ client_or_error?.books_contact?.contact_name$$ | async }}
          </span>

          <span *ngIf="client_or_error?.error">Contact BOOKS</span>

          <span class="mat-menu-item__content__status" [ngClass]="{ '--error': client_or_error?.error !== null }">
            <ng-container *ngIf="!client_or_error?.error">Contact BOOKS (depuis compte CRM)</ng-container>

            <ng-container *ngIf="client_or_error?.error">
              {{ client_or_error?.error?.message | translate }}
            </ng-container>
          </span>
        </div>
      </div>
    </a>

    <!-- Go to BOOKS contact (from CRM contact)  -->
    <a
      *ngLet="(links_to_books$$ | async)?.links?.client_of_crm_contact; let client_or_error"
      mat-menu-item
      target="_blank"
      [disabled]="client_or_error?.error !== null"
      [href]="client_or_error?.books_contact?.url$$ | async"
    >
      <div class="mat-menu-item__container">
        <span class="mdi mdi-account mdi-24px"></span>

        <div class="mat-menu-item__container__content">
          <span *ngIf="!client_or_error?.error">
            {{ client_or_error?.books_contact?.contact_name$$ | async }}
          </span>

          <span *ngIf="client_or_error?.error">Contact BOOKS</span>

          <span class="mat-menu-item__content__status" [ngClass]="{ '--error': client_or_error?.error !== null }">
            <ng-container *ngIf="!client_or_error?.error">Contact BOOKS (depuis contact CRM)</ng-container>

            <ng-container *ngIf="client_or_error?.error">
              {{ client_or_error?.error?.message | translate }}
            </ng-container>
          </span>
        </div>
      </div>
    </a>
  </ng-template>
</mat-menu>

<!-- Menu: go to DESK account/contact -->
<mat-menu #menuGoToDESKAccountOrContact class="superadmin-shortcut-base-menu">
  <!-- Go to DESK account  -->
  <a
    *ngLet="(links_to_desk$$ | async)?.links?.account; let account_or_error"
    mat-menu-item
    target="_blank"
    [disabled]="account_or_error?.error !== null"
    [href]="account_or_error?.desk_account?.url$$ | async"
  >
    <div class="mat-menu-item__container">
      <span class="mdi mdi-factory mdi-24px"></span>

      <div class="mat-menu-item__container__content">
        <span *ngIf="!account_or_error?.error">
          {{ account_or_error?.desk_account?.account_name$$ | async }}
        </span>

        <span *ngIf="account_or_error?.error">Compte DESK</span>

        <span class="mat-menu-item__content__status" [ngClass]="{ '--error': account_or_error?.error !== null }">
          <ng-container *ngIf="!account_or_error?.error">Compte DESK de l'exploitation</ng-container>

          <ng-container *ngIf="account_or_error?.error">
            {{ account_or_error?.error?.message | translate }}
          </ng-container>
        </span>
      </div>
    </div>
  </a>

  <!-- Go to DESK contact -->
  <a
    *ngLet="(links_to_desk$$ | async)?.links?.contact; let contact_or_error"
    mat-menu-item
    target="_blank"
    [disabled]="contact_or_error?.error !== null"
    [href]="contact_or_error?.desk_contact?.url$$ | async"
  >
    <div class="mat-menu-item__container">
      <span class="mdi mdi-account mdi-24px"></span>

      <div class="mat-menu-item__container__content">
        <span *ngIf="!contact_or_error?.error">
          {{ contact_or_error?.desk_contact?.full_name$$ | async }}
        </span>

        <span *ngIf="contact_or_error?.error">Contact DESK</span>

        <span class="mat-menu-item__content__status" [ngClass]="{ '--error': contact_or_error?.error !== null }">
          <ng-container *ngIf="!contact_or_error?.error">Contact DESK de l'exploitation</ng-container>

          <ng-container *ngIf="contact_or_error?.error">
            {{ contact_or_error?.error?.message | translate }}
          </ng-container>
        </span>
      </div>
    </div>
  </a>
</mat-menu>

<!-- Menu: go to CRM account/contact -->
<mat-menu class="superadmin-shortcut-base-menu" #menuGoToCRMAccountOrContact>
  <!-- Go to CRM account  -->
  <a
    *ngLet="(links_to_crm$$ | async)?.links?.account; let account_or_error"
    mat-menu-item
    target="_blank"
    [disabled]="account_or_error?.error !== null"
    [href]="account_or_error?.crm_account?.url$$ | async"
  >
    <div class="mat-menu-item__container">
      <span class="mdi mdi-factory mdi-24px"></span>

      <div class="mat-menu-item__container__content">
        <span *ngIf="!account_or_error?.error">
          {{ account_or_error?.crm_account?.account_name$$ | async }}
        </span>

        <span *ngIf="account_or_error?.error">Compte CRM</span>

        <span class="mat-menu-item__content__status" [ngClass]="{ '--error': account_or_error?.error !== null }">
          <ng-container *ngIf="!account_or_error?.error">Compte CRM de l'exploitation</ng-container>

          <ng-container *ngIf="account_or_error?.error">
            {{ account_or_error?.error?.message | translate }}
          </ng-container>
        </span>
      </div>
    </div>
  </a>

  <!-- Go to CRM contact -->
  <a
    *ngLet="(links_to_crm$$ | async)?.links?.contact; let contact_or_error"
    mat-menu-item
    target="_blank"
    [disabled]="contact_or_error?.error !== null"
    [href]="contact_or_error?.crm_contact?.url$$ | async"
  >
    <div class="mat-menu-item__container">
      <span class="mdi mdi-account mdi-24px"></span>

      <div class="mat-menu-item__container__content">
        <span *ngIf="!contact_or_error?.error">
          {{ contact_or_error?.crm_contact?.full_name$$ | async }}
        </span>

        <span *ngIf="contact_or_error?.error">Contact CRM</span>

        <span class="mat-menu-item__content__status" [ngClass]="{ '--error': contact_or_error?.error !== null }">
          <ng-container *ngIf="!contact_or_error?.error">Contact CRM de l'exploitation</ng-container>

          <ng-container *ngIf="contact_or_error?.error">
            {{ contact_or_error?.error?.message | translate }}
          </ng-container>
        </span>
      </div>
    </div>
  </a>
</mat-menu>

<!-- Zoho main menu  -->
<mat-menu #zohoMainMenu="matMenu" class="superadmin-shortcut-base-menu">
  <button
    mat-menu-item
    [queryParamsHandling]="'preserve'"
    [disabled]="(has_selected_exploitations$$ | async) === false"
    [routerLink]="['', { outlets: { modal: ['zoho_check', { check: 'zoho_account_exploitation_id:appstate_selected_exploitations' }] } }]"
  >
    <div class="mat-menu-item__container">
      <div class="mat-menu-item__container__content --type-horizontal">
        <span>Expl. sélectionnée(s)</span>
        <span class="mdi mdi-compare-horizontal mdi-24px"></span>
        <span>Compte (CRM & DESK)</span>
      </div>
    </div>
  </button>

  <button
    mat-menu-item
    [queryParamsHandling]="'preserve'"
    [disabled]="(has_selected_exploitations$$ | async) === false"
    [routerLink]="['', { outlets: { modal: ['zoho_check', { check: 'zoho_contact_user_id:appstate_selected_exploitations' }] } }]"
  >
    <div class="mat-menu-item__container">
      <div class="mat-menu-item__container__content --type-horizontal">
        <span>Expl. sélectionnée(s)</span>
        <span class="mdi mdi-compare-horizontal mdi-24px"></span>
        <span>Contact (CRM & DESK)</span>
      </div>
    </div>
  </button>
</mat-menu>

<!-- Menu : list of tickets  -->
<mat-menu #menuListOfTickets="matMenu" class="superadmin-shortcut-base-menu --unlimited-height" [yPosition]="'above'">
  <ng-container *ngLet="(tickets$$ | async)?.value; let tickets">
    <!-- No tickets button -->
    <ng-container *ngIf="tickets?.length === 0">
      <button mat-menu-item class="mat-menu-item__empty-content default-line-height">
        <div class="mat-menu-item__container">
          <div class="mat-menu-item__container__content">
            <span>{{ 'WIDGETS.MISC_WIDGETS.SUPERADMIN_SHORTCUT.No active tickets' | translate }}</span>
          </div>
        </div>
      </button>
    </ng-container>

    <!-- Tickets list -->
    <ng-container *ngFor="let ticket of tickets; let index = index">
      <a mat-menu-item [href]="ticket?.webUrl" target="_blank">
        <bg2-zoho-ticket-summary [ticket]="ticket"></bg2-zoho-ticket-summary>
      </a>

      <mat-divider *ngIf="index !== tickets?.length - 1"></mat-divider>
    </ng-container>

    <mat-divider class="actions-separator"></mat-divider>

    <!-- Create ticket button -->
    <button
      mat-menu-item
      class="default-line-height"
      [queryParamsHandling]="'preserve'"
      [routerLink]="['', { outlets: { modal: ['zoho_create_issue', { issue_type: 'generic' }] } }]"
    >
      <div class="mat-menu-item__container">
        <div class="mat-menu-item__container__content">
          <span class="mat-menu-item__content__auth-status">
            <span class="mdi mdi-ticket mdi-24px"></span>
            <span>{{ 'WIDGETS.MISC_WIDGETS.SUPERADMIN_SHORTCUT.Open a new ticket' | translate }}</span>
          </span>
        </div>
      </div>
    </button>

    <!-- View all tickets -->
    <a
      *ngLet="(links_to_desk$$ | async)?.links?.account; let account_or_error"
      mat-menu-item
      target="_blank"
      class="default-line-height"
      [disabled]="account_or_error?.error !== null"
      [href]="account_or_error?.desk_account?.url_tickets$$ | async"
    >
      <div class="mat-menu-item__container">
        <span class="mdi mdi-web mdi-24px"></span>

        <div class="mat-menu-item__container__content">
          <span>{{ 'WIDGETS.MISC_WIDGETS.SUPERADMIN_SHORTCUT.View all tickets' | translate }}</span>

          <span *ngIf="account_or_error?.error; let error" class="mat-menu-item__content__status" [ngClass]="{ '--error': error !== null }">
            {{ error?.message | translate }}
          </span>
        </div>
      </div>
    </a>
  </ng-container>
</mat-menu>

<!-- Menu: list of notes -->
<mat-menu #menuListOfNotes="matMenu" class="superadmin-shortcut-base-menu --unlimited-height" [yPosition]="'above'">
  <ng-container *ngLet="(notes$$ | async)?.notes; let notes">
    <!-- No notes -->
    <ng-container *ngIf="notes?.length === 0">
      <button mat-menu-item class="mat-menu-item__empty-content default-line-height">
        <div class="mat-menu-item__container">
          <div class="mat-menu-item__container__content">
            <span>{{ 'WIDGETS.MISC_WIDGETS.SUPERADMIN_SHORTCUT.No notes' | translate }}</span>
          </div>
        </div>
      </button>
    </ng-container>

    <!-- List of notes -->
    <ng-container *ngFor="let note of notes; let index = index">
      <a mat-menu-item target="_blank" [disableRipple]="true" [href]="note?.url$$ | async">
        <bg2-zoho-crm-note-summary [note]="note"></bg2-zoho-crm-note-summary>
      </a>

      <mat-divider *ngIf="index !== notes?.length - 1"></mat-divider>
    </ng-container>

    <mat-divider></mat-divider>

    <!-- Description of the account -->
    <a
      *ngLet="(links_to_crm$$ | async)?.links?.account; let account_or_error"
      mat-menu-item
      target="_blank"
      class="mat-menu-item__manual-disable"
      [disabled]="account_or_error?.error !== null"
      [href]="account_or_error?.crm_account?.url$$ | async"
    >
      <div class="mat-menu-item__container">
        <span class="mdi mdi-text-short mdi-24px"></span>

        <div *ngLet="account_or_error?.crm_account?.description$$ | async; let description" class="mat-menu-item__container__content">
          <span *ngIf="description">{{ description }}</span>
          <span *ngIf="!description">{{ 'no description' }}</span>

          <span *ngIf="account_or_error?.error; let error" class="mat-menu-item__content__status" [ngClass]="{ '--error': error !== null }">
            {{ error?.message | translate }}
          </span>
        </div>
      </div>
    </a>

    <mat-divider class="actions-separator"></mat-divider>

    <!-- View all notes -->
    <a
      *ngLet="(links_to_crm$$ | async)?.links?.account; let account_or_error"
      mat-menu-item
      target="_blank"
      class="default-line-height"
      [disabled]="account_or_error?.error !== null"
      [href]="account_or_error?.crm_account?.url_notes$$ | async"
    >
      <div class="mat-menu-item__container">
        <span class="mdi mdi-web mdi-24px"></span>

        <div class="mat-menu-item__container__content">
          <span>{{ 'WIDGETS.MISC_WIDGETS.SUPERADMIN_SHORTCUT.View all notes' | translate }}</span>

          <span *ngIf="account_or_error?.error; let error" class="mat-menu-item__content__status" [ngClass]="{ '--error': error !== null }">
            {{ error?.message | translate }}
          </span>
        </div>
      </div>
    </a>
  </ng-container>
</mat-menu>

<!-- Menu: list of tasks -->
<mat-menu #menuListOfTasks="matMenu" class="superadmin-shortcut-base-menu --unlimited-height" [yPosition]="'above'">
  <ng-container *ngLet="(tasks$$ | async)?.tasks; let tasks">
    <!-- No tasks -->
    <ng-container *ngIf="tasks?.length === 0">
      <button mat-menu-item class="mat-menu-item__empty-content default-line-height">
        <div class="mat-menu-item__container">
          <div class="mat-menu-item__container__content">
            <span>{{ 'WIDGETS.MISC_WIDGETS.SUPERADMIN_SHORTCUT.No tasks' | translate }}</span>
          </div>
        </div>
      </button>
    </ng-container>

    <!-- List of tasks -->
    <ng-container *ngFor="let task of tasks; let index = index">
      <a mat-menu-item target="_blank" [disableRipple]="true" [href]="task?.url$$ | async">
        <bg2-zoho-crm-task-summary [task]="task"></bg2-zoho-crm-task-summary>
      </a>

      <mat-divider *ngIf="index !== tasks?.length - 1"></mat-divider>
    </ng-container>

    <mat-divider class="actions-separator"></mat-divider>

    <!-- View all tasks -->
    <a
      *ngLet="(links_to_crm$$ | async)?.links?.account; let account_or_error"
      mat-menu-item
      target="_blank"
      class="default-line-height"
      [disabled]="account_or_error?.error !== null"
      [href]="account_or_error?.crm_account?.url$$ | async"
    >
      <div class="mat-menu-item__container">
        <span class="mdi mdi-web mdi-24px"></span>

        <div class="mat-menu-item__container__content">
          <span>{{ 'WIDGETS.MISC_WIDGETS.SUPERADMIN_SHORTCUT.View all tasks' | translate }}</span>

          <span *ngIf="account_or_error?.error; let error" class="mat-menu-item__content__status" [ngClass]="{ '--error': error !== null }">
            {{ error?.message | translate }}
          </span>
        </div>
      </div>
    </a>
  </ng-container>
</mat-menu>

<!-- Menu: list of deals -->
<mat-menu #menuListOfDeals="matMenu" class="superadmin-shortcut-base-menu --unlimited-height" [yPosition]="'above'">
  <ng-container *ngLet="(deals$$ | async)?.deals; let deals">
    <!-- No deals -->
    <ng-container *ngIf="deals?.length === 0">
      <button mat-menu-item class="mat-menu-item__empty-content default-line-height">
        <div class="mat-menu-item__container">
          <div class="mat-menu-item__container__content">
            <span>{{ 'WIDGETS.MISC_WIDGETS.SUPERADMIN_SHORTCUT.No deals' | translate }}</span>
          </div>
        </div>
      </button>
    </ng-container>

    <!-- List of deals -->
    <ng-container *ngFor="let deal of deals; let index = index">
      <a mat-menu-item target="_blank" [disableRipple]="true" [href]="deal?.url$$ | async">
        <bg2-zoho-crm-deal-summary [deal]="deal"></bg2-zoho-crm-deal-summary>
      </a>

      <mat-divider *ngIf="index !== deals?.length - 1"></mat-divider>
    </ng-container>

    <mat-divider class="actions-separator"></mat-divider>

    <!-- View all deals -->
    <a
      *ngLet="(links_to_crm$$ | async)?.links?.account; let account_or_error"
      mat-menu-item
      target="_blank"
      class="default-line-height"
      [disabled]="account_or_error?.error !== null"
      [href]="account_or_error?.crm_account?.url_potentials$$ | async"
    >
      <div class="mat-menu-item__container">
        <span class="mdi mdi-web mdi-24px"></span>

        <div class="mat-menu-item__container__content">
          <span>{{ 'WIDGETS.MISC_WIDGETS.SUPERADMIN_SHORTCUT.View all deals' | translate }}</span>

          <span *ngIf="account_or_error?.error; let error" class="mat-menu-item__content__status" [ngClass]="{ '--error': error !== null }">
            {{ error?.message | translate }}
          </span>
        </div>
      </div>
    </a>
  </ng-container>
</mat-menu>

<!-- Menu: list of invoices -->
<mat-menu #menuListOfInvoices="matMenu" class="superadmin-shortcut-base-menu --unlimited-height" [yPosition]="'above'">
  <ng-container *ngLet="(invoices$$ | async)?.invoices; let invoices">
    <!-- No invoices -->
    <ng-container *ngIf="invoices?.length === 0">
      <button mat-menu-item class="mat-menu-item__empty-content default-line-height">
        <div class="mat-menu-item__container">
          <div class="mat-menu-item__container__content">
            <span>{{ 'WIDGETS.MISC_WIDGETS.SUPERADMIN_SHORTCUT.No invoices' | translate }}</span>
          </div>
        </div>
      </button>
    </ng-container>

    <!-- List of invoices -->
    <ng-container *ngFor="let invoice of invoices; let index = index">
      <a mat-menu-item target="_blank" [disableRipple]="true" [href]="invoice?.url$$ | async">
        <bg2-zoho-books-invoice-summary [invoice]="invoice"></bg2-zoho-books-invoice-summary>
      </a>

      <mat-divider *ngIf="index !== invoices?.length - 1"></mat-divider>
    </ng-container>

    <mat-divider class="actions-separator"></mat-divider>

    <!-- View all invoices -->
    <a
      *ngLet="(links_to_books$$ | async)?.unique; let account_or_error"
      mat-menu-item
      target="_blank"
      class="default-line-height"
      [disabled]="account_or_error?.error !== null"
      [href]="account_or_error?.books_contact?.url_sales$$ | async"
    >
      <div class="mat-menu-item__container">
        <span class="mdi mdi-web mdi-24px"></span>

        <div class="mat-menu-item__container__content">
          <span>{{ 'WIDGETS.MISC_WIDGETS.SUPERADMIN_SHORTCUT.View all transactions' | translate }}</span>

          <span *ngIf="account_or_error?.error; let error" class="mat-menu-item__content__status" [ngClass]="{ '--error': error !== null }">
            {{ error?.message | translate }}
          </span>
        </div>
      </div>
    </a>
  </ng-container>
</mat-menu>
