<div *ngLet="(data$$ | async)?.points; let photo_points" class="stackable-cards" #stackableCardGroup>
  <ng-container *ngIf="photo_points?.length === 0">
    <bg2-abstract-carousel-group-card>
      <div role="stackable-card-header">
        <div class="card-title"> </div>
      </div>

      <div role="stackable-card-content"> nothing </div>
    </bg2-abstract-carousel-group-card>
  </ng-container>

  <ng-container *ngIf="photo_points?.length > 0">
    <bg2-abstract-carousel-group-card *ngFor="let photo_point of photo_points; let index = index">
      <div role="stackable-card-header">
        <div class="card-title">
          {{
            'VIEWS.DEVICES.SHARED.CAROUSEL.BEELIVE_PHOTOS_CAROUSEL.Photo group n°[index] out of [total]'
              | translate : { index: index + 1, total: photo_points?.length }
          }}
        </div>
      </div>

      <div role="stackable-card-content">
        <div>
          <ng-container *ngIf="photo_point?.url_cam1">
            <img [src]="photo_point?.url_cam1" alt="" draggable="false" />

            <span *ngLet="photo_point?.ui?.cam1; let parsed">
              {{
                'VIEWS.DEVICES.SHARED.CAROUSEL.BEELIVE_PHOTOS_CAROUSEL.Photo of "[name]" taken on [date]'
                  | translate : { name: parsed?.name ?? '--', date: parsed?.date ?? photo_point?.date | formatDate : appState.dl.lll }
              }}
            </span>
          </ng-container>
        </div>

        <div>
          <ng-container *ngIf="photo_point?.url_cam0">
            <img [src]="photo_point?.url_cam0" alt="" draggable="false" />

            <span *ngLet="photo_point?.ui?.cam0; let parsed">
              {{
                'VIEWS.DEVICES.SHARED.CAROUSEL.BEELIVE_PHOTOS_CAROUSEL.Photo of "[name]" taken on [date]'
                  | translate : { name: parsed?.name ?? '--', date: parsed?.date ?? photo_point?.date | formatDate : appState.dl.lll }
              }}
            </span>
          </ng-container>
        </div>
      </div>
    </bg2-abstract-carousel-group-card>
  </ng-container>
</div>
