import { Pipe, PipeTransform } from '@angular/core';

import { Dictionary } from 'app/typings/core/interfaces';

@Pipe({
  name: 'keys',
  pure: true,
})
export class KeysPipe implements PipeTransform {
  public transform(value: Dictionary<any>): string[] {
    return Object.keys(value);
  }
}
