<div class="bg2-zoho-check-contact-user-id-component">
  <span class="title">
    <span>{{ 'ALL.COMMON.Comparison' | translate | colon }}</span>

    <span *ngIf="origin === 'default'">({{ 'MODELS.USER.User "[name]"\'s ID' | translate : { name: user_name$$ | async } }})</span>

    <span *ngIf="origin === 'exploitation'">({{ 'MODELS.USER.Owner "[name]"\'s ID' | translate : { name: user_name$$ | async } }})</span>

    <span>&nbsp;</span>
    <span class="mdi mdi-compare-horizontal"></span>
    <span>&nbsp;</span>
    <span>({{ "VIEWS.ZOHO.SHARED.ZOHO_CHECKS.ZOHO_CHECK_ACCOUNT_EXPLOITATION_ID.Linked zoho contact user's ID" | translate }})</span>
  </span>

  <div class="checks">
    <div *ngLet="user_id$$ | async; let user_id" class="check">
      <ng-container *ngIf="user_id === null; else validCheck">
        <span class="mdi mdi-close-thick"></span>
      </ng-container>

      <span>
        <ng-container *ngIf="origin === 'default'">
          {{ "MODELS.USER.User's ID" | translate | colon }}
        </ng-container>

        <ng-container *ngIf="origin === 'exploitation'">
          {{ "MODELS.USER.Owner's ID" | translate | colon }}
        </ng-container>

        {{ user_id }}
      </span>
    </div>

    <div class="check">
      <ng-container
        *ngIf="
          (user_crm_id$$ | async) === null || (zoho_contact_user_id$$ | async) === null || (zoho_contact_user_id$$ | async) === -1;
          else validCheck
        "
      >
        <span class="mdi mdi-close-thick"></span>
      </ng-container>

      <span>
        {{ "VIEWS.ZOHO.SHARED.ZOHO_CHECKS.CHECK_CONTACT_USER_ID.Fetched user's ID" | translate | colon }}
        {{ zoho_contact_user_id$$ | async }}
      </span>
    </div>
  </div>

  <div class="result">
    <span class="mdi mdi-arrow-right-bottom-bold"></span>

    <ng-container *ngIf="(check_reason_result$$ | async) === 'EQUAL_IDS'; else errorMessage">
      <div class="valid">
        <span>
          {{ 'VIEWS.ZOHO.SHARED.ZOHO_CHECKS.ALL.CORRECT.Identical' | translate }}
        </span>
      </div>
    </ng-container>

    <ng-template #errorMessage>
      <ng-container *ngIf="(has_any_loading$$ | async) === false">
        <div class="invalid">
          <ng-container *ngLet="check_reason_result$$ | async; let invalid_check_reason">
            <span *ngIf="invalid_check_reason === 'MISSING_CRM_ID_IN_LOCAL_USER'">
              <span>{{ 'VIEWS.ZOHO.SHARED.ZOHO_CHECKS.ALL.ERRORS.MISSING_CRM_ID_IN_LOCAL_USER' | translate }}</span>

              <a
                [queryParamsHandling]="'preserve'"
                [routerLink]="['', { outlets: { modal: ['user_account', { user_id: user_id$$ | async }] } }]"
                class="clickable"
              >
                {{ 'VIEWS.ZOHO.SHARED.ZOHO_CHECKS.CHECK_CONTACT_USER_ID.Link a zoho contact to the user' | translate }}
              </a>
            </span>

            <span *ngIf="invalid_check_reason === 'MISSING_CRM_ID_IN_LOCAL_OWNER'">
              {{ 'VIEWS.ZOHO.SHARED.ZOHO_CHECKS.ALL.ERRORS.MISSING_CRM_ID_IN_LOCAL_OWNER' | translate }}

              <a
                [queryParamsHandling]="'preserve'"
                [routerLink]="['', { outlets: { modal: ['user_account', { user_id: user_id$$ | async }] } }]"
                class="clickable"
              >
                {{ 'VIEWS.ZOHO.SHARED.ZOHO_CHECKS.CHECK_CONTACT_USER_ID.Link a zoho contact to the owner' | translate }}
              </a>
            </span>

            <span *ngIf="invalid_check_reason === 'MISSING_USER_ID_IN_LOCAL_USER'">
              {{ 'VIEWS.ZOHO.SHARED.ZOHO_CHECKS.ALL.ERRORS.MISSING_USER_ID_IN_LOCAL_USER' | translate }}
            </span>

            <span *ngIf="invalid_check_reason === 'MISSING_USER_ID_IN_LOCAL_OWNER'">
              {{ 'VIEWS.ZOHO.SHARED.ZOHO_CHECKS.ALL.ERRORS.MISSING_USER_ID_IN_LOCAL_OWNER' | translate }}
            </span>

            <span *ngIf="invalid_check_reason === 'UNDEFINED_ZOHO_CONTACT'">
              {{ 'VIEWS.ZOHO.SHARED.ZOHO_CHECKS.ALL.ERRORS.UNDEFINED_ZOHO_CONTACT' | translate }}

              <a
                [queryParamsHandling]="'preserve'"
                [routerLink]="['', { outlets: { modal: ['user_account', { user_id: user_id$$ | async }] } }]"
                class="clickable"
              >
                {{ 'VIEWS.ZOHO.SHARED.ZOHO_CHECKS.CHECK_CONTACT_USER_ID.Modify linked zoho contact of the user' | translate }}
              </a>
            </span>

            <span *ngIf="invalid_check_reason === 'MISSING_USER_ID_IN_ZOHO_CONTACT'">
              <span>{{ 'VIEWS.ZOHO.SHARED.ZOHO_CHECKS.ALL.ERRORS.MISSING_USER_ID_IN_ZOHO_CONTACT' | translate }}</span>

              <a href="javascript:void(0)" class="clickable" (click)="update_zoho_contact_user_id()">
                {{ 'VIEWS.ZOHO.SHARED.ZOHO_CHECKS.CHECK_CONTACT_USER_ID.Update user ID on Zoho-Contact' | translate }}
              </a>
            </span>

            <span *ngIf="invalid_check_reason === 'DIFFERENT_IDS'">
              <span>{{ 'VIEWS.ZOHO.SHARED.ZOHO_CHECKS.ALL.ERRORS.DIFFERENT_IDS' | translate }}</span>

              <a href="javascript:void(0)" class="clickable" (click)="update_zoho_contact_user_id()">
                {{ 'VIEWS.ZOHO.SHARED.ZOHO_CHECKS.CHECK_CONTACT_USER_ID.Update user ID on Zoho-Contact' | translate }}
              </a>
            </span>
          </ng-container>
        </div>
      </ng-container>
    </ng-template>

    <div *ngIf="has_any_loading$$ | async" class="loading-state">
      <span class="mdi mdi-autorenew mdi-spin mdi-24px"></span>

      <span *ngIf="checking_valid_value$$ | async">{{ 'VIEWS.ZOHO.SHARED.ZOHO_CHECKS.ALL.LOADING.Comparing values' | translate }} ...</span>

      <span *ngIf="is_updating_user_id$$ | async">
        {{ 'VIEWS.ZOHO.SHARED.ZOHO_CHECKS.CHECK_CONTACT_USER_ID.LOADING.Updating exploitation ID in Zoho-Contact' | translate }}
        ...
      </span>
    </div>
  </div>
</div>

<ng-template #validCheck>
  <span class="mdi mdi-check-bold"></span>
</ng-template>
