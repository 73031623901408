<ng-container *ngIf="entity$$ | async; let entity">
  <h5 *ngIf="show_title">{{title || ('VIEWS.MODALS.UPDATE_ENTITY.History' | translate)}}</h5>

  <div class="loading" *ngIf="events_loading$$ | async">
    <hex-loading [size]="'small'"></hex-loading>
  </div>

  <ul *ngIf="(events_loading$$ | async) === false">
    <li *ngFor="let event of (events$$ | async)">
      <bg2-event-link [event]="event" [from]="entity"></bg2-event-link>
    </li>
  </ul>
  
  <bg2-paging
    [total]="total$$ | async"
    [offset]="offset$$ | async"
    [limit]="limit$$ | async"
    *ngIf="has_pagging$$ | async"
    (pageChanged)="this.page = $event"
  ></bg2-paging>
</ng-container>