import { PipeTransform, Pipe } from '@angular/core';

import { differenceInDays } from 'date-fns';

import { parseDate } from '../tools';

@Pipe({
  name: 'differenceInDays',
  pure: true,
})
export class DifferenceInDaysPipe implements PipeTransform {
  public transform(future_date: Date | string, previous_date: Date | string): number {
    const later_date = parseDate(future_date); // ensure this is a Date
    const earlier_date = parseDate(previous_date); // ensure this is a Date

    return Math.abs(differenceInDays(later_date, earlier_date));
  }
}
