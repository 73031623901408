<fieldset class="geopos-widget-component">
  <div *ngIf="has_address" class="address-field">
    <div class="form-element-container">
      <sf-form-element [formProperty]="formProperty.getProperty('address')"></sf-form-element>

      <a href="javascript:void(0)" *ngIf="browser_location_available" (click)="useDevicePosition()" class="relocate-btn">
        <span class="mdi mdi-crosshairs-gps mdi-18px" [popper]="('WIDGETS.EVENT_FORM.GEOPOS.Get my position' | translate)"></span>
      </a>
    </div>

    <bg2-button
      [type]="'action'"
      [icon]="'mdi-magnify'"
      [loading]="is_searching_geopos_from_address$$ | async"
      [disabled]="is_searching_geopos_from_address$$ | async"
      [options]="{ is_label_hidden_on_small: true, is_thin: true }"
      [message]="'WIDGETS.EVENT_FORM.GEOPOS.Update postion from address' | translate"
      (btn_click)="search_position_from_address()"
    ></bg2-button>
  </div>

  <div *ngIf="tmp_form_address$$ | async; let addr" class="quoted-address">
    <div class="message">
      <span class="mdi mdi-information mdi-24px"></span>

      <div>
        <span>{{ 'WIDGETS.EVENT_FORM.GEOPOS.The marker on the map corresponds to a different address' | translate | colon }}</span>
        <span>
          <i><b>{{ addr }}</b></i>
        </span>
        <span>.</span><br />
        <span>{{ 'WIDGETS.EVENT_FORM.GEOPOS.You can ignore this message or apply this address' | translate }}.</span>
      </div>
    </div>

    <bg2-button
      [type]="'action'"
      [icon]="'mdi-arrow-up-bold'"
      [message]="'WIDGETS.EVENT_FORM.GEOPOS.Apply' | translate"
      [options]="{ is_thin: true }"
      (btn_click)="apply_tmp_for_address()"
    ></bg2-button>
  </div>

  <div class="map-field">
    <div class="leaflet-map-container">
      <div class="loading" *ngIf="device_loading">
        <hex-loading [size]="'large'"></hex-loading>
      </div>

      <bg2-leaflet-map (mapReady)="onLeafletMapReady($event)" [height]="'470px'" [layers]="layers$$ | async"></bg2-leaflet-map>
    </div>

    <div class="map-options">
      <ng-container *ngIf="has_form_approximate_prop">
        <mat-checkbox
          color="primary"
          [ngModel]="is_approximative_activated$$ | async"
          (ngModelChange)="is_approximative_activated = $event"
        >
          {{ 'WIDGETS.EVENT_FORM.GEOPOS.Set approximate position' | translate }}
        </mat-checkbox>
      </ng-container>

      <mat-checkbox
        color="primary"
        [ngModel]="show_all_devices$$ | async"
        [disabled]="(devices$$ | async).length <= 0 ? true : null"
        (ngModelChange)="show_all_devices = $event"
      >
        <span>
          {{ 'WIDGETS.EVENT_FORM.GEOPOS.Display all devices' | translate }}
          <hex-loading [size]="'tiny'" *ngIf="device_loading"></hex-loading>
        </span>
      </mat-checkbox>
    </div>
  </div>

  <div class="elevation-field" *ngIf="has_elevation">
    <ng-container *ngLet="is_altitude_tracked_getset.value$$ | async; let is_altitude_tracked_value">
      <!-- Ugly workaround because readOnly property not updating in number widget -->
      <sf-form-element *ngIf="is_altitude_tracked_value" [formProperty]="formProperty.getProperty('elevation')"></sf-form-element>
      <sf-form-element *ngIf="!is_altitude_tracked_value" [formProperty]="formProperty.getProperty('elevation')"></sf-form-element>

      <bg2-button
        *ngLet="is_altitude_tracked_getset.value$$ | async; let is_altitude_tracked_value"
        [type]="'action'"
        [options]="{ is_thin: true }"
        [color]="is_altitude_tracked_value ? 'green' : null"
        [icon]="is_altitude_tracked_value ? 'mdi-sync-off' : 'mdi-sync'"
        [message]="is_altitude_tracked_value ? ('WIDGETS.EVENT_FORM.GEOPOS.Disable auto-refresh' | translate) : ('WIDGETS.EVENT_FORM.GEOPOS.Enable auto-refresh' | translate)"
        [descriptive]="('WIDGETS.EVENT_FORM.GEOPOS.Current:' | translate) + ' ' + (is_altitude_tracked_value ? ('WIDGETS.EVENT_FORM.GEOPOS.Enabled' | translate) : ('WIDGETS.EVENT_FORM.GEOPOS.Disabled' | translate))"
        (btn_click)="is_altitude_tracked_value ? (is_altitude_tracked_getset.value = false) : (is_altitude_tracked_getset.value = true)"
      ></bg2-button>
    </ng-container>
  </div>

  <div *ngIf="has_timezone" class="timezone-field">
    <sf-form-element [formProperty]="formProperty.getProperty('timezone')"></sf-form-element>

    <bg2-button
      *ngLet="is_timezone_tracked.value$$ | async; let is_timezone_tracked__value"
      [type]="'action'"
      [options]="{ is_thin: true }"
      [color]="is_timezone_tracked__value ? 'green' : null"
      [icon]="is_timezone_tracked__value ? 'mdi-sync-off' : 'mdi-sync'"
      [message]="is_timezone_tracked__value ? ('WIDGETS.EVENT_FORM.GEOPOS.Disable auto-refresh' | translate) : ('WIDGETS.EVENT_FORM.GEOPOS.Enable auto-refresh' | translate)"
      [descriptive]="('WIDGETS.EVENT_FORM.GEOPOS.Current:' | translate) + ' ' + (is_timezone_tracked__value ? ('WIDGETS.EVENT_FORM.GEOPOS.Enabled' | translate) : ('WIDGETS.EVENT_FORM.GEOPOS.Disabled' | translate))"
      (btn_click)="is_timezone_tracked__value ? (is_timezone_tracked.value = false) :(is_timezone_tracked.value = true)"
    ></bg2-button>
  </div>
</fieldset>
