import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { isNil } from 'lodash-es';

import { marker as i18n } from '@biesbjerg/ngx-translate-extract-marker';

import { BehaviorSubject, combineLatest, forkJoin, Observable, of } from 'rxjs';
import { filter, map, switchMap, take, tap } from 'rxjs';
import { anyTrue, distinctUntilRealChanged, replay } from '@bg2app/tools/rxjs';

import { DialogsService } from 'app/widgets/dialogs-modals';
import { ConsoleLoggerService } from 'app/core/console-logger.service';

import { Dictionary } from 'app/typings/core/interfaces';
import { Entity } from 'app/models';
import { CustomazibleDialogParams } from 'app/widgets/dialogs-modals/customizable/customazible.dialog';
import { Router } from '@angular/router';
import { Beeguard2Api } from 'app/core';
import { AppStateService } from 'app/core/app-state.service';

export enum DeleteOrArchiveEntityAction {
  CANCEL = 'cancel',
  DELETE = 'delete',
  ARCHIVE = 'archive',
}

@Component({
  selector: 'bg2-button-delete-or-archive',
  templateUrl: './button-delete-or-archive-entity.component.html',
  styleUrls: ['./button-delete-or-archive-entity.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class Bg2ButtonDeleteOrArchiveEntityComponent {
  private _entity_id$$ = new BehaviorSubject(null);

  @Input()
  public set entity_id(entity_id: number) {
    this._entity_id$$.next(entity_id);
  }

  private _entity$$ = new BehaviorSubject<Entity>(null);
  public entity$$: Observable<Entity> = combineLatest([this._entity$$.asObservable(), this._entity_id$$.asObservable()]).pipe(
    switchMap(([entity, entity_id]) => {
      if (isNil(entity)) {
        if (isNil(entity_id)) {
          throw new Error('Cannot use undefined entity_id with an undefined entity');
        }

        return this._bg2Api.getEntityObj(entity_id);
      }

      return of(entity);
    }),
    filter(entity => !isNil(entity)),
    replay()
  );

  @Input()
  public set entity(entity: Entity) {
    this._entity$$.next(entity);
  }

  private _external_disabled$$ = new BehaviorSubject<boolean>(false);
  public external_disabled$$ = this._external_disabled$$.asObservable().pipe(distinctUntilRealChanged(), replay());

  @Input()
  public set external_disabled(external_disabled: boolean) {
    this._external_disabled$$.next(external_disabled);
  }

  @Output()
  public taken_action = new EventEmitter<DeleteOrArchiveEntityAction>();

  @Input()
  public thin_button = false;

  @Input()
  public set is_icon(is_icon: boolean) {
    this._is_icon$$.next(is_icon);
  }

  private _is_icon$$ = new BehaviorSubject(false);
  public is_icon$$ = this._is_icon$$.asObservable().pipe(distinctUntilRealChanged(), replay());

  // #region -> (component basics)

  private readonly _logger = new ConsoleLoggerService('Bg2ButtonDeleteOrArchiveEntityComponent', false);

  constructor(
    private _router: Router,
    private _bg2Api: Beeguard2Api,
    private _dialogs: DialogsService,
    private _appState: AppStateService
  ) {}

  // #endregion

  // #region -> (archive checkings)

  private _is_loading_archive_check$$ = new BehaviorSubject(true);
  public is_loading_archive_check$$: Observable<boolean> = this._is_loading_archive_check$$
    .asObservable()
    .pipe(distinctUntilRealChanged(), replay());

  private set is_loading_archive_check(value: boolean) {
    this._is_loading_archive_check$$.next(value);
  }

  private _is_archiving_in_progress$$ = new BehaviorSubject(false);
  public is_archiving_in_progress$$ = this._is_archiving_in_progress$$.asObservable().pipe(distinctUntilRealChanged(), replay());

  private set is_archiving_in_progress(value: boolean) {
    this._is_archiving_in_progress$$.next(value);
  }

  /**
   * Observable if the entity can be archived or not.
   *
   * @todo Move checks to each entity for reusability.
   */
  public can_archive$$ = this.entity$$.pipe(
    tap(() => (this.is_loading_archive_check = true)),
    switchMap(entity => entity.archivable$$),
    tap(() => (this.is_loading_archive_check = false)),
    distinctUntilRealChanged(),
    replay()
  );

  /**
   * Observable if the entity cannot be archived or not.
   *
   * @todo Move the condition inverser to the specific entity for reusability.
   */
  public cannot_archive$$ = this.can_archive$$.pipe(
    map(can_archive => !can_archive),
    replay()
  );

  // #endregion

  // #region -> (delete checkings)

  private _is_loading_delete_check$$ = new BehaviorSubject(true);
  public is_loading_delete_check$$: Observable<boolean> = this._is_loading_delete_check$$
    .asObservable()
    .pipe(distinctUntilRealChanged(), replay());

  private set is_loading_delete_check(value: boolean) {
    this._is_loading_delete_check$$.next(value);
  }

  private _is_deletion_in_progress$$ = new BehaviorSubject(false);
  public is_deletion_in_progress$$ = this._is_deletion_in_progress$$.asObservable().pipe(distinctUntilRealChanged(), replay());

  private set is_deletion_in_progress(value: boolean) {
    this._is_deletion_in_progress$$.next(value);
  }

  /**
   * Observable to know if the entity can be deleted or not.
   */
  public can_delete$$ = this.entity$$.pipe(
    tap(() => (this.is_loading_delete_check = true)),
    switchMap(entity => entity.can_be_deleted$$),
    tap(() => (this.is_loading_delete_check = false)),
    distinctUntilRealChanged(),
    replay()
  );

  /**
   * Observable to know if the entity cannot be deleted or not.
   */
  public cannot_delete$$ = this.can_delete$$.pipe(
    map(can_delete => !can_delete),
    replay()
  );

  // #endregion

  // #region -> (button management)

  public is_disabled$$ = anyTrue(
    this.external_disabled$$,
    this.is_loading_delete_check$$,
    this.is_deletion_in_progress$$,
    this.is_loading_archive_check$$,
    this.is_archiving_in_progress$$
  );

  public deleteOrArchiveEntity(): void {
    forkJoin([this.entity$$.pipe(take(1)), this.can_delete$$.pipe(take(1))])
      .pipe(
        switchMap(([entity, can_be_deleted]) => {
          if (can_be_deleted) {
            this.is_deletion_in_progress = true;
            return of(this._buildDeleteDialogParams(entity)).pipe(
              switchMap(custom_dialog_params => this._dialogs.customizable(custom_dialog_params)),
              map(res => res.return_value),
              switchMap((result: DeleteOrArchiveEntityAction) => {
                if (result !== DeleteOrArchiveEntityAction.DELETE) {
                  return of({ result, entity });
                }
                return entity.initial_setup_event_id$$.pipe(
                  take(1),
                  switchMap((setup_event_id: number) => {
                    if (!isNil(setup_event_id)) {
                      return this._bg2Api.delete_event$(setup_event_id);
                    } else {
                      return of(null);
                    }
                  }),
                  switchMap(() => entity.delete()),
                  map(() => ({ result: DeleteOrArchiveEntityAction.DELETE, entity }))
                );
              })
            );
          }

          this.is_archiving_in_progress = true;
          return of({ result: DeleteOrArchiveEntityAction.ARCHIVE, entity });
        }),
        take(1)
      )
      .subscribe({
        next: ({ result, entity }) => {
          switch (true) {
            case result === DeleteOrArchiveEntityAction.ARCHIVE: {
              const archive_modal_params: Dictionary<any> = {
                args: {},
              };
              archive_modal_params.etype = `${entity.type}_archive`;
              archive_modal_params.args[entity.type] = entity.id;
              archive_modal_params.args = JSON.stringify(archive_modal_params.args);

              this._router.navigate(
                [
                  {
                    outlets: {
                      modal: ['new_event', archive_modal_params],
                    },
                  },
                ],
                {
                  replaceUrl: true,
                  queryParamsHandling: 'preserve',
                }
              );

              break;
            }

            case result === DeleteOrArchiveEntityAction.DELETE: {
              if (entity.type === 'location' && this._router.url.startsWith('/locations/')) {
                setTimeout(() => {
                  this._router.navigate(['/locations'], { queryParamsHandling: 'preserve' });
                }, 40);
              } else if (entity.type === 'exploitation') {
                if (this._router.url.startsWith('/exploitation/')) {
                  setTimeout(() => {
                    this._router.navigate(['/locations'], { queryParamsHandling: 'preserve' });
                  }, 40);
                }
                this._appState.loadExploitations();
              }

              break;
            }

            case isNil(result) || result === DeleteOrArchiveEntityAction.CANCEL:
            default: {
            }
          }

          this.taken_action.next(result || DeleteOrArchiveEntityAction.CANCEL);
          this.is_deletion_in_progress = false;
          this.is_archiving_in_progress = false;
        },
        error: (error: unknown) => this._logger.error(error),
      });
  }

  // #endregion

  // #region -> (dialogs management)

  private _i18n_delete_entity_question(entity: Entity): string {
    switch (entity.type) {
      case 'warehouse': {
        return i18n(
          'VIEWS.MODALS.DELETE.The warehouse [entity->name] does not have history, so you can delete it completely. Are you sure to delete this warehouse ?'
        );
      }

      case 'exploitation': {
        return i18n(
          'VIEWS.MODALS.DELETE.The exploitation [entity->name] does not have history, so you can delete it completely. Are you sure to delete this exploitation ?'
        );
      }

      case 'location': {
        return i18n(
          'VIEWS.MODALS.DELETE.The location [entity->name] does not have history, so you can delete it completely. Are you sure to delete this location ?'
        );
      }

      case 'apiary': {
        return i18n(
          'VIEWS.MODALS.DELETE.The apiary [entity->name] does not have history, so you can delete it completely. Are you sure to delete this apiary ?'
        );
      }

      case 'hive': {
        return i18n(
          'VIEWS.MODALS.DELETE.The hive [entity->name] does not have history, so you can delete it completely. Are you sure to delete this hive ?'
        );
      }
    }
  }

  private _i18n_archive_entity_type(entity: Entity): string {
    switch (entity.type) {
      case 'warehouse': {
        return i18n('VIEWS.MODALS.DELETE.archive this warehouse');
      }

      case 'exploitation': {
        return i18n('VIEWS.MODALS.DELETE.archive this exploitation');
      }

      case 'location': {
        return i18n('VIEWS.MODALS.DELETE.archive this location');
      }

      case 'apiary': {
        return i18n('VIEWS.MODALS.DELETE.archive this apiary');
      }

      case 'hive': {
        return i18n('VIEWS.MODALS.DELETE.archive this hive');
      }
    }
  }

  private _i18n_confirm_delete_entity_type(entity: Entity): string {
    switch (entity.type) {
      case 'warehouse': {
        return i18n('VIEWS.MODALS.DELETE.Delete the warehouse');
      }

      case 'exploitation': {
        return i18n('VIEWS.MODALS.DELETE.Delete the exploitation');
      }

      case 'location': {
        return i18n('VIEWS.MODALS.DELETE.Delete the location');
      }

      case 'apiary': {
        return i18n('VIEWS.MODALS.DELETE.Delete the apiary');
      }

      case 'hive': {
        return i18n('VIEWS.MODALS.DELETE.Delete the hive');
      }
    }
  }

  private _buildDeleteDialogParams(entity: Entity): CustomazibleDialogParams<DeleteOrArchiveEntityAction> {
    return {
      body: {
        type: 'div',
        elements: [
          {
            type: 'span',
            content: this._i18n_delete_entity_question(entity),
            translateParams: { entity },
          },
          {
            type: 'div',
            styles: {
              'display': 'block',
              'margin-top': '15px;',
              'font-size': '0.86em',
            },
            elements: [
              {
                type: 'span',
                content: i18n('VIEWS.MODALS.DELETE.You can also'),
              },
              {
                type: 'link',
                content: this._i18n_archive_entity_type(entity),
                translateParams: { entity },
                result: DeleteOrArchiveEntityAction.ARCHIVE,
              },
              {
                type: 'span',
                content: i18n('VIEWS.MODALS.DELETE.to keep the history'),
              },
            ],
          },
        ],
      },
      footer: {
        buttons: {
          items: [
            {
              type: 'button',
              color: 'transparent',
              result: DeleteOrArchiveEntityAction.CANCEL,
              content: i18n('VIEWS.MODALS.No, cancel the operation'),
            },
            {
              type: 'button',
              icon: 'mdi-delete',
              result: DeleteOrArchiveEntityAction.DELETE,
              color: 'warn',
              content: this._i18n_confirm_delete_entity_type(entity),
              translateParams: { entity },
            },
          ],
        },
      },
    };
  }

  // #endregion

  public in_progress$$ = combineLatest({
    archiving_in_progress: this.is_archiving_in_progress$$,
    deletion_in_progress: this.is_deletion_in_progress$$,
  }).pipe(
    map(({archiving_in_progress, deletion_in_progress}) => archiving_in_progress || deletion_in_progress),
    replay()
  );

  public iconic$$ = combineLatest([
    this.can_archive$$,
    this.can_delete$$,
  ]).pipe(
    map(([can_archive, can_delete]) => {
      if (can_archive && !can_delete) {
        return 'mdi-archive';
      } else if (can_delete) {
        return 'mdi-delete';
      }
      return 'mdi-loading';
    }),
    distinctUntilRealChanged(),
    replay()
  );
}
