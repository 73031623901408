<bg2-modal [fullscreen]="fullscreen || false" #modal>
  <bg2-modal-header [isBeta]="is_beta$$ | async">
    <div class="header">
      <span class="mdi mdi-truck-delivery mdi-36px"></span>
      <span class="title">
        <ng-container *ngIf="!event">{{'VIEWS.MODALS.FORM.New' | translate}}&nbsp;{{ '#' + (input_event_type$$ | async) }}</ng-container>
        <ng-container *ngIf="event">{{event.type_i18n | translate}}</ng-container>
      </span>
    </div>
    <div class="action-buttons">
      <doc-helper page="Migratory" iconSize="36px"></doc-helper>
      <span class="mdi mdi-close-box mdi-36px" (click)="close()"></span>
    </div>
  </bg2-modal-header>

  <bg2-modal-content>
    <ng-container *ngIf="schema$$ | async; let schema">
      <div class="error" *ngIf="error">{{error | translate}}</div>
      <sf-form
        [schema]="schema"
        [model]="form_model"
        (onChange)="onFormChange($event)"
        (onErrorChange)="logErrors($event.value)"
        (isValid)="setFormValid($event)"
        #migratoryForm
      >
      </sf-form>
    </ng-container>
  </bg2-modal-content>

  <bg2-modal-footer>
    <bg2-button
      *ngIf="(is_update_of_event$$ | async) && (event$$ | async)?.id"
      [type]="'delete'"
      [icon]="'mdi-delete'"
      [message]="'VIEWS.MODALS.FORM.Delete' | translate"
      (btn_click)="deleteEvent(event.id)"
    ></bg2-button>

    <bg2-button
      *ngIf="((event?.has_changed$$ | async) === false) && ((new_apiary_has_changed$$ | async) === false)"
      [type]="'cancel'"
      [message]="'VIEWS.MODALS.FORM.Close' | translate"
      (btn_click)="close()"
    ></bg2-button>

    <bg2-button
      *ngIf="(event?.has_changed$$ | async) || (new_apiary_has_changed$$ | async)"
      [type]="'cancel'"
      [message]="'VIEWS.MODALS.FORM.Cancel' | translate"
      (btn_click)="close()"
    ></bg2-button>

    <bg2-button
      *ngIf="(event?.has_changed$$ | async) || (new_apiary_has_changed$$ | async)"
      [type]="'action'"
      [message]="'VIEWS.MODALS.FORM.Save' | translate"
      [disabled]="(loading$$ | async) || (form_valid$$ | async) === false"
      [loading]="loading$$ | async"
      [icon]="'mdi-content-save'"
      (btn_click)="submit()"
    ></bg2-button>
  </bg2-modal-footer>
</bg2-modal>
