import { AfterViewInit, ChangeDetectionStrategy, Component, OnDestroy, Renderer2 } from '@angular/core';

import { Observable, of, map, switchMap } from 'rxjs';
import { replay } from '@bg2app/tools/rxjs';

import { CPTDevice } from 'app/models';

import { TimeBasedCarouselSlideComponent } from 'app/widgets/widgets-reusables/carousels/time-based-carousel/time-carousel-slide/time-carousel-slide.component';
import { Beeguard2Api } from 'app/core';
import { isNil } from 'lodash-es';
import { HoneyType } from 'app/views/events/shared/events/superbox_harvest/superbox-harvest-histogram-honey-factory-quantity/superbox-harvest-histogram-honey-factory-quantity.component';
import { AppStateService } from 'app/core/app-state.service';

export type NbHarvestedByHoneyType = [number, `HONEY_TYPE.${HoneyType}`][];

@Component({
  selector: 'bg2-beelive-photos-carousel-group',
  templateUrl: './beelive-photos-carousel-group.component.html',
  styleUrls: ['./beelive-photos-carousel-group.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BeelivePhotosCarouselGroupComponent extends TimeBasedCarouselSlideComponent implements AfterViewInit, OnDestroy {
  // #region -> (dynamic parameters)

  /** */
  public device$$: Observable<CPTDevice> = this.dynamic_parameters$$.pipe(
    map(dynamic_params => dynamic_params?.device || null),
    replay()
  );

  // #endregion

  // #region -> (component basics)

  constructor(protected _renderer: Renderer2, private _bg2Api: Beeguard2Api, public readonly appState: AppStateService) {
    super(_renderer);
  }

  ngAfterViewInit(): void {
    super.ngAfterViewInit();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  // #endregion

  /** */
  public data$$ = this.device$$.pipe(
    switchMap(device => this.date_range$$.pipe(map(date_range => ({ device, date_range })))),
    switchMap(({ device, date_range }) => {
      if (isNil(device)) {
        return of(null);
      }

      return device.fetch_timelapses$(date_range?.[0], date_range?.[1]).pipe(
        map(data => {
          const updated_points = data.points.map(timelapse => {
            switch (device.type) {
              case 'CPT': {
                // ex : timelapse_2024-04-17T090247.887310p0000_cam0.jpg
                timelapse.ui = {};
                timelapse.ui.cam0 = this.parse_photo_point_filename_of_cpt(timelapse.filename_cam0);
                timelapse.ui.cam1 = this.parse_photo_point_filename_of_cpt(timelapse.filename_cam1);

                return timelapse;
              }

              case 'BeeLive': {
                // ex : timelapse_beelive359232710005279_2024-04-18T064738.695321p0000_cam0.jpg
                timelapse.ui = {};
                timelapse.ui.cam0 = this.parse_photo_point_filename_of_beelive(timelapse.filename_cam0);
                timelapse.ui.cam1 = this.parse_photo_point_filename_of_beelive(timelapse.filename_cam1);

                return timelapse;
              }

              default:
                return timelapse;
            }
          });

          data.points = updated_points;
          return data;
        })
      );
    }),
    replay()
  );

  /** */
  private parse_photo_point_filename_of_cpt(filename: string): { date: Date; name: string } {
    // ex : timelapse_2024-04-17T090247.887310p0000_cam0.jpg
    if (isNil(filename)) {
      return { date: new Date(), name: '' };
    }

    const [measurement, date_str, camera_ext] = filename.split('_');

    // Parse date
    const [date_and_time, ms_and_tz] = date_str.split('.');
    const [date, time] = date_and_time.split('T');
    const parsed_time = time.match(/.{1,2}/g).join(':');

    const parsed_date = new Date(date + 'T' + parsed_time + 'Z');
    const [name, extension] = camera_ext.split('.');

    return { date: parsed_date, name };
  }

  /** */
  private parse_photo_point_filename_of_beelive(filename: string): { date: Date; name: string } {
    // ex : timelapse_beelive359232710005279_2024-04-18T064738.695321p0000_cam0.jpg
    if (isNil(filename)) {
      return { date: new Date(), name: '' };
    }

    const [measurement, device, date_str, camera_ext] = filename.split('_');

    // Parse date
    const [date_and_time, ms_and_tz] = date_str.split('.');
    const [date, time] = date_and_time.split('T');
    const parsed_time = time.match(/.{1,2}/g).join(':');

    const parsed_date = new Date(date + 'T' + parsed_time + 'Z');
    const [name, extension] = camera_ext.split('.');

    return { date: parsed_date, name };
  }

  /** */
  // public parse_photo_point_filename(filename: string) {
  //   console.log(filename);

  //   if (isNil(filename)) {
  //     return { parsed_date: new Date(), name: '' };
  //   }

  //   timelapse_beelive359232710005279_2024-04-18T064738.695321p0000_cam0.jpg

  //   const [measurement, date_str, camera_ext] = filename.split('_');

  //   // Parse date
  //   // const [date_and_time, ms_and_tz] = date_str.split('.');
  //   // const [date, time] = date_and_time.split('T');
  //   // const parsed_time = time.match(/.{1,2}/g).join(':');

  //   // const parsed_date = new Date(date + 'T' + parsed_time + 'Z');
  //   // const [name, extension] = camera_ext.split('.');

  //   return { date: new Date(), name: 'test' };
  // }
}
