<div *ngLet="device_simplified_configuration$$ | async; let sconf" class="device-ack-next-conf-component">
  <ng-container *ngIf="(is_loading_sconf.value$$ | async) === false; else loadingSconf">
    <ng-container *ngIf="can_have_sconf$$ | async">
      <!-- Configuration header -->
      <ng-container *ngIf="display_type === 'normal'">
        <div class="device-config-title-container">
          <span
            class="mdi mdi-cog mdi-24px"
            [ngClass]="{ 'mdi-spin': sconf?.next?.pending }"
            [ngStyle]="{ color: sconf?.next?.pending || sconf?.next?.order !== 'no' ? 'red' : 'black' }"
          ></span>

          <span>
            {{ 'DEVICE.ALL.CONFIG.Device configuration' | translate }}
          </span>

          <span *ngIf="!sconf?.next?.estimated_ack_time && sconf?.ack">
            ({{ 'DEVICE.ALL.CONFIG.Acknoledged on [date]' | translate : { date: sconf?.ack.date | formatDate : 'short' } }})
          </span>

          <span *ngIf="sconf?.next?.estimated_ack_time">
            ({{
              'DEVICE.ALL.CONFIG.Will be taken over on [date]'
                | translate : { date: sconf?.next.estimated_ack_time | formatDate : 'short' }
            }})
          </span>
        </div>
      </ng-container>

      <!-- Current and next config -->
      <div class="device-config-container" [ngStyle]="{ 'padding-left': display_type === 'normal' ? '10px' : '0px' }">
        <!-- *** Device operating mode (ACK) *** -->
        <div *ngLet="sconf?.ack?.sconf; let acknoledged_conf" class="device-config-container__config-state">
          <span class="mdi mdi-check mdi-24px"></span>

          <span [ngSwitch]="acknoledged_conf?.mode">
            <span *ngSwitchCase="'tracking'">{{ 'DEVICE.ALL.CONFIG.Tracking only' | translate }}</span>

            <span *ngSwitchCase="'measures'">{{ 'DEVICE.ALL.CONFIG.Internal measurements' | translate }}</span>

            <span *ngSwitchCase="'measures_sensors'">{{ 'DEVICE.ALL.CONFIG.Internal measurements and WGuard' | translate }}</span>

            <span *ngSwitchCase="'tracking_measures'">{{ 'DEVICE.ALL.CONFIG.Tracking and internal measurements' | translate }}</span>

            <span *ngSwitchCase="'tracking_measures_sensors'">
              {{ 'DEVICE.ALL.CONFIG.Tracking, internal measurements and WGuard' | translate }}
            </span>

            <ng-container *ngSwitchDefault>
              <ng-container *ngTemplateOutlet="unknowOperationMode"></ng-container>
            </ng-container>
          </span>
        </div>

        <!-- *** Device operating mode (NEXT) *** -->
        <ng-container *ngIf="sconf?.next?.pending && sconf?.ack?.sconf?.mode !== sconf?.next?.sconf?.mode">
          <div *ngLet="sconf?.next?.sconf; let next_conf" class="device-config-container__config-state">
            <span class="mdi mdi-keyboard-return mdi-24px mdi-flip-h"></span>

            <span [ngSwitch]="next_conf?.mode">
              <span *ngSwitchCase="'tracking'">{{ 'DEVICE.ALL.CONFIG.Tracking only' | translate }}</span>

              <span *ngSwitchCase="'measures'">{{ 'DEVICE.ALL.CONFIG.Internal measurements' | translate }}</span>

              <span *ngSwitchCase="'measures_sensors'">{{ 'DEVICE.ALL.CONFIG.Internal measurements and WGuard' | translate }}</span>

              <span *ngSwitchCase="'tracking_measures'">{{ 'DEVICE.ALL.CONFIG.Tracking and internal measurements' | translate }}</span>

              <span *ngSwitchCase="'tracking_measures_sensors'">
                {{ 'DEVICE.ALL.CONFIG.Tracking, internal measurements and WGuard' | translate }}
              </span>

              <ng-container *ngSwitchDefault>
                <ng-container *ngTemplateOutlet="unknowOperationMode"></ng-container>
              </ng-container>
            </span>
          </div>
        </ng-container>

        <!-- *** Device communication hours (ACK) -->
        <div *ngLet="sconf?.ack?.sconf; let acknoledged_conf" class="device-config-container__config-state">
          <span class="mdi mdi-check mdi-24px"></span>

          <ng-container *ngIf="acknoledged_conf?.com?.conf; else unknowComHour" [ngSwitch]="acknoledged_conf?.com?.conf">
            <span *ngSwitchCase="'every_two_days'">
              {{
                'DEVICE.ALL.CONFIG.One communication every two days at [hour]h00 (local time)'
                  | translate : { hour: (ack_sconf__com__hour_utc_one$$ | async) }
              }}
            </span>

            <span *ngSwitchCase="'one_by_day'">
              {{
                'DEVICE.ALL.CONFIG.One communication by day at [hour]h00 (local time)'
                  | translate : { hour: (ack_sconf__com__hour_utc_one$$ | async) }
              }}
            </span>

            <span *ngSwitchCase="'two_by_day'">
              {{
                'DEVICE.ALL.CONFIG.Two communications by day at [hour_one]h00 and [hour_two]h00 (local time)'
                  | translate
                    : {
                        hour_one: (ack_sconf__com__hour_utc_one$$ | async),
                        hour_two: (ack_sconf__com__hour_utc_two$$ | async)
                      }
              }}
            </span>

            <ng-container *ngSwitchDefault>
              <ng-container *ngTemplateOutlet="unknowComHour"></ng-container>
            </ng-container>
          </ng-container>
        </div>

        <!-- *** Device communication hours (NEXT) *** -->
        <ng-container
          *ngIf="
            sconf?.next?.pending &&
            (sconf?.ack?.sconf?.com?.conf !== sconf?.next?.sconf?.com?.conf ||
              sconf?.ack?.sconf?.com?.hour_utc_one !== sconf?.next?.sconf?.com?.hour_utc_one ||
              sconf?.ack?.sconf?.com?.hour_utc_two !== sconf?.next?.sconf?.com?.hour_utc_two)
          "
        >
          <div *ngLet="sconf?.next?.sconf; let next_conf" class="device-config-container__config-state">
            <span class="mdi mdi-keyboard-return mdi-24px mdi-flip-h"></span>

            <ng-container *ngIf="next_conf?.com?.conf; else unknowComHour" [ngSwitch]="next_conf?.com?.conf">
              <span *ngSwitchCase="'every_two_days'">
                {{
                  'DEVICE.ALL.CONFIG.One communication every two days at [hour]h00 (local time)'
                    | translate : { hour: (next_sconf__com__hour_utc_one$$ | async) }
                }}
              </span>

              <span *ngSwitchCase="'one_by_day'">
                {{
                  'DEVICE.ALL.CONFIG.One communication by day at [hour]h00 (local time)'
                    | translate : { hour: (next_sconf__com__hour_utc_one$$ | async) }
                }}
              </span>

              <span *ngSwitchCase="'two_by_day'">
                {{
                  'DEVICE.ALL.CONFIG.Two communications by day at [hour_one]h00 and [hour_two]h00 (local time)'
                    | translate
                      : {
                          hour_one: (next_sconf__com__hour_utc_one$$ | async),
                          hour_two: (next_sconf__com__hour_utc_two$$ | async)
                        }
                }}
              </span>

              <ng-container *ngSwitchDefault>
                <ng-container *ngTemplateOutlet="unknowComHour"></ng-container>
              </ng-container>
            </ng-container>
          </div>
        </ng-container>

        <!-- *** Device order *** -->
        <div *ngIf="sconf?.next?.order && sconf?.next?.order !== 'no'" class="device-config-container__order-state">
          <span class="mdi mdi-arrow-right mdi-24px"></span>
          <span>{{ 'DEVICE.ALL.CONFIGURATION.ORDER.' + sconf?.next?.order | translate }}</span>
        </div>
      </div>
    </ng-container>
  </ng-container>
</div>

<ng-template #loadingSconf>
  <bg2-loading-skeleton [theme]="{ height: '100%', width: '100%' }"></bg2-loading-skeleton>
</ng-template>

<ng-template #unknowOperationMode>
  <b>
    <i>{{ 'DEVICE.ALL.CONFIG.No operation mode' | translate }}</i>
  </b>
</ng-template>

<ng-template #unknowComHour>
  <b>
    <i>{{ 'DEVICE.ALL.CONFIG.No communication time' | translate }}</i>
  </b>
</ng-template>
