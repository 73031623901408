import {
  keys,
  pick,
  clone,
  keyBy,
  isNil,
  omitBy,
  values,
  isEqual,
  indexOf,
  mergeWith,
  cloneDeep,
  difference,
  get as _get,
  merge as _merge,
  concat as _concat,
  isString,
} from 'lodash-es';

import {
  of,
  map,
  tap,
  take,
  timer,
  merge,
  filter,
  concat,
  Subject,
  switchMap,
  throwError,
  Observable,
  catchError,
  debounceTime,
  Subscription,
  combineLatest,
  BehaviorSubject,
  distinctUntilChanged,
  pairwise,
  startWith,
} from 'rxjs';

import { differenceInSeconds } from 'date-fns';

import { TranslateService } from '@ngx-translate/core';
import { marker as i18n } from '@biesbjerg/ngx-translate-extract-marker';
import { ISchema } from 'ngx-schema-form';

import { parseDate } from 'app/misc/tools';
import { EntityState, EntityStateValue } from '../../misc';
import { EntityUpdateError } from '../../misc';
import { distinctUntilRealChanged, replay, waitForNotNilValue } from '@bg2app/tools/rxjs';

import { Beeguard2Api } from 'app/core';
import { DeviceApi } from 'app/core';
import { Entity as EntityInterface } from 'app/core/api-swagger/beeguard2/model/entity';
import { EventRequestConfig, EventPaging } from 'app/core/api/main/beeguard2-api-service';
import { GetEntityACLResponse, GetEntityTimeseriesResponse, MeasurementDescription } from 'app/core/api-swagger/beeguard2';
import { EntitySchema as SwaggerEntitySchema } from 'app/core/api-swagger/beeguard2';
import { ConsoleLoggerService } from 'app/core/console-logger.service';
import { Dictionary } from 'app/typings/core/interfaces';

import { Event, isOlder, areEquals as eventsAreEquals } from '../../../events';
import { StreamNotification } from 'app/core/api/main/beeguard2-stream-service';
import { AclAndOwner } from 'app/core/api-swagger/beeguard2/model/aclAndOwner';
import { Paging } from 'app/models/Paging';
import { AbstractEntityUserACL } from './abstract-entity-user-acl.class';

export interface EntityEventRequestConfig extends EventRequestConfig {
  update?: boolean;
}

const is_same_error = (err1: EntityUpdateError | null, err2: EntityUpdateError | null): boolean => {
  if (isNil(err1) && isNil(err2)) {
    return true;
  } else if (!isNil(err1)) {
    return err1.isEqual(err2);
  } else {
    // previous is null, but not current
    return false;
  }
};

export interface EntitySchema extends SwaggerEntitySchema {
  static_state_schema?: ISchema;
}

export interface EntityDict extends EntityInterface {
  state: EntityStateValue; // The "displayed" state of the entity
  last_state: EntityState; // More recent state we know (may be dirty)
  last_state_clean: EntityState; // More recent clean state we know (if any)
  first_state_dirty: EntityState; // id dirty, this is the first state dirty (it may contains an error...)
}

export interface IEntityStaticState extends Dictionary<any> {
  name: string;
  comment: string;
}

interface DeviceHistoryPoint {
  event_id: number;
  start: Date;
  value: any;
}

export type DeviceHistory = DeviceHistoryPoint[];

export class EntityNotificationManager {
  // #endregion -> (class basics)

  constructor() {}

  // #endregion

  // #region -> (deletion notification)

  /** */
  private _has_been_deleted$$ = new BehaviorSubject(false);

  /** */
  public has_been_deleted$$ = this._has_been_deleted$$.asObservable();

  /** */
  public set has_been_deleted(has_been_deleted: boolean) {
    this._has_been_deleted$$.next(has_been_deleted);
  }

  // #endregion
}

/** */

/**
 * @template TStaticState Generic type for static state.
 */
export abstract class Entity<TStaticState extends IEntityStaticState = any> implements EntityDict {
  // #region -> (model basics)

  /** */
  protected _logger: ConsoleLoggerService;

  /** */
  private update_acl_sub: Subscription;

  /** */
  constructor(protected bg2Api: Beeguard2Api, protected deviceApi: DeviceApi) {
    this.resetLogger();
    this.type_sub = this.type$$.subscribe();
    this._is_dirty_sub = this.dirty$$.subscribe();

    this.state_sub = this.state$$.subscribe(state => {
      this.stateChanged(state);
    });

    this.initial_empty_named_state$$.subscribe();

    this.static_state_sub = this.static_state$$.subscribe(static_state => {
      this.staticStateChanged(static_state);
    });

    this.update_acl_sub = this.reload_acl$$.subscribe(entity_acl => {});
    this._reload_acl$.next(true);

    this.error_sub = this.error$$.subscribe(err => {
      if (!isNil(err)) {
        this._logger.error(`New state error:`, err?.description);
      }
    });
  }

  // #endregion

  // #region -> (entity ID)

  /** */
  private _id$$ = new BehaviorSubject<number>(null);

  /** */
  public id$$ = this._id$$.pipe(
    map(entity_id => {
      if (isNil(entity_id)) {
        return null;
      }

      if (isString(entity_id)) {
        return parseInt(entity_id, 10);
      }

      return entity_id;
    }),
    distinctUntilRealChanged(),
    replay()
  );

  /** */
  public set id(_id: number) {
    this._id$$.next(_id);
    this.resetLogger();
  }

  /** */
  public get id(): number {
    return this._id$$.getValue();
  }

  // #endregion

  // #region -> (ACL management (owner))

  /** */
  private _reload_acl$ = new Subject<any>();

  /** */
  private reload_acl$$ = this._reload_acl$.asObservable().pipe(replay());

  /**
   * Observes entity ACL gived to other users by the owner.
   */
  public entity_acl$$ = this.id$$.pipe(
    waitForNotNilValue(),
    switchMap(id => this.reload_acl$$.pipe(map(() => id))),
    switchMap(id => {
      if (id < 0) {
        this._logger.error('Ghost entity, we can requests ACL');

        return of<GetEntityACLResponse>({
          acl: [],
          paging: { limit: 0, offset: 0, total: 0 },
        });
      }

      return this.bg2Api.fetch_entity_acl$(id);
    }),
    replay()
  );

  /**
   * Updates entity's ACL.
   */
  public updateACL(acl_and_owner: AclAndOwner) {
    return this.bg2Api.update_entity_acl$(acl_and_owner, this.id).pipe(tap(() => this._reload_acl$.next(true)));
  }

  // #endregion

  // #region -> (ACL checks (other users))

  /**
   * List of authorizations of the current user for this entity.
   *
   * @deprecated
   */
  public _user_acl: string[];

  /**
   * Manager for user ACL.
   */
  public abstract user_acl: AbstractEntityUserACL;

  /**
   * Checks at instant X if the entity has specific ACE.
   *
   * @deprecated
   */
  public hasACE(scope: any): boolean {
    return indexOf(this._user_acl, scope) >= 0;
  }

  // #endregion

  public static NOT_LAST_UPDATE_DELAY = 13000;
  // ^ delay to wait before to apply a state that is not the last one (in ms)

  public static RUN_EVENT_CHECK_PERIOD = 60000; // every minutes
  // ^ period of check of last event run evenutal error (when dirty)

  protected _type$ = new BehaviorSubject<string>(null);
  public type$$ = this._type$.asObservable().pipe(
    filter(type => !isNil(type)),
    distinctUntilChanged(),
    replay()
  );
  private type_sub: Subscription;

  public get type(): string {
    return this._type$.getValue();
  }

  public set type(type) {
    this._type$.next(type);
  }

  protected _user_id$$ = new BehaviorSubject<number>(null);
  public user_id$$ = this._user_id$$.asObservable();

  public set user_id(_user_id: number) {
    this._user_id$$.next(_user_id);
  }

  public get user_id(): number {
    return this._user_id$$.getValue();
  }

  private _stream_sub: Subscription = null;
  protected last_notification: any; // last recieved notification

  private _is_dirty$$ = new BehaviorSubject<boolean>(true);
  private _is_dirty_sub: Subscription;
  public dirty$$ = this._is_dirty$$.pipe(distinctUntilRealChanged(), replay());

  public is_last = false;

  private _has_changed$$ = new BehaviorSubject<boolean>(false);
  public has_changed$$ = this._has_changed$$.asObservable().pipe(distinctUntilChanged());
  get has_changed() {
    // whether local version has changed from server one (seve needed)
    return this._has_changed$$.getValue();
  }
  public forceHasChanged() {
    this._has_changed$$.next(true);
  }

  protected _static_state: TStaticState = {} as TStaticState; // can only be modify by "server"
  protected tmp_static_state: TStaticState; // local static state before saved

  public get static_state(): TStaticState {
    if (!this.tmp_static_state) {
      this.tmp_static_state = cloneDeep(this._static_state);
    }
    return this.tmp_static_state;
  }

  public set static_state(static_state: TStaticState) {
    if (!isEqual(this.static_state, static_state)) {
      // console.log(this.desc, this._static_state, static_state);
      this.tmp_static_state = static_state;
      this.checkStaticStateChanged();
    }
  }

  public checkStaticStateChanged(): void {
    this._has_changed$$.next(!Entity.isSameStaticState(this._static_state, this.static_state));
    this._static_state$.next(this.static_state);
  }

  private static isSameStaticState(sstate_left: any, sstate_right: any): boolean {
    const sl = omitBy(sstate_left, (val, key) => key.startsWith('_'));
    const sr = omitBy(sstate_right, (val, key) => key.startsWith('_'));
    return isEqual(sl, sr);
  }

  /**
   * Remove all local modification of entity static state
   */
  public reset_static_state(): void {
    this.tmp_static_state = cloneDeep(this._static_state);
    this.checkStaticStateChanged();
  }

  /**
   * Gestion des erreurs
   * -------------------
   *
   * Une entité est en erreur par trois sources :
   * 1) à la deserialisation initiale, on a une erreur sur le first_dirty_state (erreur conserne directement l'entity)
   * 2) en stream, un state dirty -avec une erreur- est recu  (erreur conserne directement l'entity)
   * 3) l'entité est dirty, on va chercher une erreur sur le run du last_state (Erreur sur dépendance)
   *
   * - Les erreurs provenant de 1) et 2) doivent etre prioritaire
   * - La recherche d'erreur avec 3) n'est faite que si besoin (composant d'affichage d'erreur branché)
   *
   * La RAS d'une erreur peu venir de :
   * 1) Surveillance du run, il n'est plus en erreur (au moins pour l'instant)
   * 2) en stream on recoit un nouveau clean_state:
   *    - Si c'est le "last" => RAZ, on est plus dirty plus d'erreur
   *    - Si ce n'est pas le "last" => on est encore dirty,
   *        Si pas d'erreur => déclanchement surveillance dirty as usual
   *        Si deja une erreur => est-ce que l'erreur connue (si )
   *
   *
   */
  private _error: EntityUpdateError = null;
  private _error$: BehaviorSubject<EntityUpdateError> = new BehaviorSubject(null);
  public error$ = this._error$.asObservable();
  public has_error = false;

  public error$$ = this.error$.pipe(
    distinctUntilChanged((previous, current) => is_same_error(previous, current)),
    map(error => {
      if (!isNil(error)) {
        console.log(this.desc, 'Got entity error from entity state');
      }
      return error;
    }),
    replay()
  );
  private error_sub: Subscription;

  public has_run_error = false;
  public run_error$ = this.dirty$$.pipe(
    filter(() => !isNil(this.last_state)), // Ignore if we have no last_state (entity not loaded yet)
    filter(
      dirty =>
        // On ne sruveille que si :
        //   - dirty et on n'a pas de d'erreur de "state"
        //   - !dirty (pour eventuel RAZ)
        // On cherche a mettre a jour run error seulement si on n'est plus dirty,
        // ou si on a pas deja une erreur !
        // console.log(this.desc, `[run_error_pipeline] dirty:${dirty} this.has_error:${this.has_error} this.has_run_error:${this.has_run_error}`);
        (dirty && !this.has_error) || !dirty
    ),
    switchMap(dirty => {
      console.log(this.desc, `[run_error_pipeline] check run error (dirty: ${dirty})`);
      // If state dirty start to check last event !
      if (dirty) {
        return timer(
          // Use random to avoid sync of all check of all entities
          Math.floor(Math.random() * Math.floor(Entity.RUN_EVENT_CHECK_PERIOD / 10)),
          Entity.RUN_EVENT_CHECK_PERIOD
        );
      } else {
        return of(null);
      }
    }),
    switchMap(_timer => {
      if (!isNil(_timer) && this.last_state.event_id) {
        console.log(this.desc, `[run_error_pipeline] entity still dirty ${_timer}... check last event`);
        // TODO manage 404 !
        // if 404 => last_state is false... so one may reload the full entity ?
        console.log(this.desc, '[run_error_pipeline]', this.last_state);
        return this.bg2Api.fetch_event$(this.last_state.event_id, undefined, /*run_info =*/ true);
      }
      return of(null);
    }),
    map((event: any) => {
      if (isNil(event) || isNil(event.run) || isNil(event.run.error) || isNil(event.run.error.source)) {
        return null;
      }
      console.log(this.desc, '[run_error_pipeline] Load new run error...');
      const new_error = EntityUpdateError.FromRunError(event.run.error, this.bg2Api);
      return new_error;
    }),
    distinctUntilChanged((previous, current) => is_same_error(previous, current)),
    map(error => {
      this.has_run_error = !isNil(error);
      if (this.has_run_error) {
        console.log(this.desc, '[run_error_pipeline] Got entity error from run');
      } else {
        console.log(this.desc, '[run_error_pipeline] no error from run');
      }
      return error;
    })
  );

  public run_error$$ = this.run_error$.pipe(replay());

  public all_error$$ = merge(this.run_error$$, this.error$$).pipe(replay());

  public last_state: EntityState; // More recent state we know (may be dirty)
  public last_state_clean: EntityState; // More recent clean state we know (if any)
  public first_state_dirty: EntityState; // NOTE: only used initialy on deserialiaze

  private _state: EntityStateValue;
  private _named_state: EntityStateValue;
  private state_sub: Subscription;

  private _full_state$ = new BehaviorSubject<EntityState>(null);
  private _full_state$$ = this._full_state$.pipe(filter(state => !isNil(state)));

  public state$$ = this._full_state$$.pipe(
    map(full_state => full_state.state),
    tap(state => (this._state = state)),
    distinctUntilRealChanged(),
    replay()
  );

  public named_state$$ = this._full_state$$.pipe(
    filter(full_state => !full_state.is_local),
    map(full_state => full_state.state),
    tap(state => (this._named_state = state)),
    distinctUntilRealChanged(),
    replay()
  );
  public initial_empty_named_state$$: Observable<EntityStateValue> = concat(of({} as EntityStateValue), this.named_state$$).pipe(replay());

  private static_state_sub: Subscription;
  private _static_state$ = new Subject<TStaticState>();
  public static_state$: Observable<TStaticState> = this._static_state$.asObservable();
  public static_state$$: Observable<TStaticState> = this.static_state$.pipe(replay());

  private _updates$ = new BehaviorSubject<Entity<TStaticState>>(null);
  public updates$: Observable<Entity<TStaticState>> = this._updates$.asObservable();
  // ^ updated on each async update of the entity

  private _history: any;
  private _history_subject = new Subject();
  public history$ = this._history_subject.asObservable();
  // TODO:  sur la gestion de l'historique
  // Garder la liste des champs/date dont on trace l'historique
  // XXX: update de l'hitorique depuis le stream

  protected _fix_schema(schema: EntitySchema): EntitySchema {
    return schema;
  }

  public schema$ = this.type$$.pipe(
    filter(type => !isNil(type)),
    tap(type => console.log(this.desc, 'schema', type)),
    switchMap(type => this.bg2Api.fetch_entity_schema$(type)),
    map(schema => this._fix_schema(schema))
  );
  public schema$$: Observable<any> = this.schema$.pipe(replay());

  // #region -> (notifications)

  /** */
  public notifications = new EntityNotificationManager();

  // #endregion

  /* ***** Public methods ***** */

  public name$$ = this.static_state$$.pipe(
    map(static_state => static_state?.name?.trim()),
    distinctUntilRealChanged(),
    replay()
  );

  get name(): string {
    return this.static_state?.name?.trim();
  }

  set name(name: string) {
    if (name && name.trim() !== this.static_state.name) {
      this.static_state.name = name.trim();
      this.checkStaticStateChanged();
    }
  }

  get comment(): string {
    return this.static_state.comment || '';
  }

  set comment(comment: string) {
    if (comment !== this.static_state.comment) {
      this.static_state.comment = comment;
      this.checkStaticStateChanged();
    }
  }

  public comment$$ = this.static_state$$.pipe(
    map(static_state => static_state?.comment || ''),
    distinctUntilChanged()
  );

  get type_i18n(): string {
    return `ENTITY.ALL.TYPE.${this.type}`;
  }

  get description(): string {
    return i18n<string>(`ENTITY.ALL.COMMON.Entity #[id]`);
  }

  get desc(): string {
    return `${this.type}#${this.id ? this.id : '?'}`;
  }

  get error() {
    return this._error;
  }

  set error(new_error) {
    if (!isEqual(this._error, new_error)) {
      this._error = new_error;
      this._error$.next(this._error);
    }
  }

  // TODO: make it protected
  get state(): EntityStateValue {
    return this._state || {};
  }

  get dirty(): boolean {
    return this._is_dirty$$.getValue();
  }

  set dirty(val: boolean) {
    // Note this is important to have a trigger when dirty changed,
    // even if dirty stay dirty
    // Indeed: this is uselfull for errur_run pipeline to trigger again
    this._is_dirty$$.next(val);
  }

  get consistent(): boolean {
    return !this.dirty;
  }

  get history() {
    return this._history || {};
  }

  set history(new_history) {
    if (!new_history) {
      return;
    }
    keys(new_history).map(key => {
      keys(new_history[key]).map(idx => {
        // TODO use parseDate ?
        const start = new Date(new_history[key][idx].start);
        new_history[key][idx].start = start;
      });
    });
    this._history = new_history;
    this._history_subject.next(this._history);
  }

  get location_since_date(): Date {
    if (this.state.location_id_since && this.state.location_id_since.date) {
      return parseDate(this.state.location_id_since.date);
    }
    return null;
  }

  get initial_setup_date(): Date {
    if (this.state?.setup?.date) {
      return parseDate(this.state.setup.date);
    }
    return null;
  }

  public initial_setup_date$$: Observable<Date> = this.state$$.pipe(
    map(state => {
      if (state?.setup?.date) {
        return parseDate(state.setup.date);
      }
      return null;
    })
  );

  get initial_setup_event_id(): number {
    if (this.state.setup && this.state.setup.event_id) {
      return this.state.setup.event_id;
    }
    return null;
  }

  /**
   * Observable on the entity setup event id.
   */
  public initial_setup_event_id$$: Observable<number> = this.state$$.pipe(
    map(state => {
      if (state?.setup?.event_id >= 0) {
        return state.setup.event_id;
      }
      return null;
    }),
    replay()
  );

  private _desc_sub: Observable<string> = null;
  /**
   * Description of the entity
   *
   * @param translate i18n translation service
   */
  public getDesc(translate: TranslateService): Observable<string> {
    // Generic description
    if (isNil(this._desc_sub)) {
      this._desc_sub = translate.get(this.description, this);
    }
    return this._desc_sub;
  }

  /* Getter fct && API request helpers */
  public save(): Observable<any> {
    let save_pipeline: Observable<any>;
    if (this.id && this.id >= 0) {
      // Todo check if static state has changed ?
      save_pipeline = this.bg2Api.update_entity$(this.id, this).pipe(
        tap((res: any) => {
          res.isNew = false;
          res._entity = this;
          return res;
        })
      );
    } else {
      const previous_id = this.id;
      if (this.id < 0) {
        this.id = null;
      }
      save_pipeline = this.bg2Api.create_entity$(this).pipe(
        catchError((err: unknown) => {
          this.id = previous_id;
          return throwError(err);
        }),
        tap((res: any) => {
          // Note this is not called if createEntity fails
          this.id = res.entity.id;
          res.isNew = true;
          res._entity = this;
          return res;
        })
      );
    }
    return save_pipeline;
  }

  public canDelete(): boolean {
    return this.last_state.event_id === this.initial_setup_event_id;
  }

  public delete(only_entity: boolean = true): Observable<any> {
    // TODO manage other unsubscribe & delete ? or other check ?
    // TODO manage deletion of setup entities
    //   TODO: add getter to setup entities ids
    // TODO manage recursif deletion of "next" event
    if (!only_entity) {
      throw Error('Not implemented yet');
    }
    return this.bg2Api.deleteEntity(this.id).pipe(
      tap(() => {
        this.notifications.has_been_deleted = true;

        this.preDestroy();
        this.id = -1;
      })
    );
  }
  private _local_states$$ = new BehaviorSubject<EntityState[]>([]);
  private get _local_states(): EntityState[] {
    return this._local_states$$.getValue();
  }

  private _local_states_splice(start: number, delete_count: number, new_state?: EntityState) {
    const states = clone(this._local_states);
    if (new_state) {
      states.splice(start, delete_count, new_state);
    } else {
      states.splice(start, delete_count);
    }
    this._local_states$$.next(states);
  }

  private _getLocalState(event_date: Date, event_id: number, previous = false): [EntityState, number] {
    let idx = 0;
    let previous_state = null;
    for (; idx < this._local_states.length; idx++) {
      previous_state = this._local_states[idx]; // older first
      if (!isOlder(previous_state.event_date, previous_state.event_id, event_date, event_id)) {
        break;
      } else if (idx == this._local_states.length - 1) {
        previous_state = null;
      }
    }
    if (previous && previous_state && eventsAreEquals(previous_state.event_date, previous_state.event_id, event_date, event_id)) {
      idx++;
      previous_state = this._local_states[idx];
    }
    return [previous_state, idx];
  }

  public getLocalState(event_date: Date, event_id: number): EntityStateValue {
    const [state] = this._getLocalState(event_date, event_id);
    if (state?.state) {
      return cloneDeep(state?.state);
    } else {
      return cloneDeep(this._named_state || {});
    }
  }

  public getPreviousLocalState(event_date: Date, event_id: number): EntityStateValue {
    const [state] = this._getLocalState(event_date, event_id, true);
    if (state?.state) {
      return cloneDeep(state?.state);
    } else {
      return cloneDeep(this._named_state || {});
    }
  }

  public getLocalStateHistory(field: string): Observable<DeviceHistory> {
    const history: DeviceHistory = [];
    let pvalue: any = null;
    this._local_states.forEach(state => {
      const nval = state.state[field];
      if (!isEqual(pvalue, nval)) {
        pvalue = nval;
        history.push({
          event_id: state.event_id,
          start: state.event_date,
          value: nval,
        });
      }
    });
    return of(history);
  }

  public storeLocalState(event_date: Date, event_id: number, state: EntityStateValue) {
    const [older_state, idx] = this._getLocalState(event_date, event_id);
    const already_exist = idx >= 0 && this._local_states[idx]?.event_id === event_id;
    const meta_state = new EntityState();
    meta_state.event_date = event_date;
    meta_state.event_id = event_id;
    meta_state.dirty = false;
    meta_state.is_last = true;
    meta_state.is_local = true;
    meta_state.state = state;
    this._local_states_splice(idx, already_exist ? 1 : 0, meta_state);
    if (idx === 0 || (already_exist && idx == 1)) {
      this.overideStateLocally(meta_state);
    }
  }

  public rmLocalState(event_id: number) {
    if (isNil(event_id)) {
      throw Error('Imposible to rm local state for null event_id');
    }
    let idx = 0;
    for (; idx < this._local_states.length; idx++) {
      const _state = this._local_states[idx];
      if (_state.event_id === event_id) {
        break;
      }
    }
    this._local_states_splice(idx, 1);
    if (idx === 0) {
      if (this._local_states.length === 0) {
        this.useLastKnowState();
      } else {
        const state = this._local_states[0];
        this.overideStateLocally(state);
      }
    }
  }

  private overideStateLocally(full_state: EntityState) {
    this._full_state$.next(full_state);
  }

  //TODO make without that
  public forceLocalState(state: EntityStateValue) {
    const meta_state = new EntityState();
    meta_state.dirty = false;
    meta_state.is_last = true;
    meta_state.is_local = true;
    meta_state.state = state;
    this.overideStateLocally(meta_state);
  }

  public get(path: string): any {
    const entity_dict = this.asDict();
    return _get(entity_dict, path);
  }

  public getStateAtDate(date?: Date, event_id?: number, stream = false): Observable<EntityState> {
    if (!stream) {
      return this.bg2Api.fetch_entity_state$(this.id, date, event_id).pipe(map((res: any) => res.state as EntityState));
    } else {
      return this.state$$.pipe(
        switchMap(() => this.bg2Api.fetch_entity_state$(this.id, date, event_id)),
        map((res: any) => res.state as EntityState)
      );
    }
  }

  /** Get entity value at a given date
   *
   * Note: if no date given, take the current state of the device
   *
   * @param path path to the entity value (`state/nb_hives`)
   * @param date date at which state
   * @param event_id get the value for event before this one (if multiple event at same date)
   */
  public getAtDate(path: string, date?: Date, event_id?: number, stream = false): Observable<any> {
    if (isNil(date)) {
      date = undefined;
    }
    if (isNil(event_id)) {
      event_id = undefined;
    }

    if (path.startsWith('state.')) {
      const state_path = path.slice('state.'.length);
      let _state_at_date$;
      if (date) {
        _state_at_date$ = this.getStateAtDate(date, event_id, stream).pipe(
          map(state => {
            if (state && state.dirty) {
              this._logger.warn(`Dirty state for entity ${this.desc} at date ${date} (event: ${event_id})`);
              // throw Error(`Dirty state for entity ${this.desc} at date ${date} (event: ${event_id})`);
            }
            if (state && state.state) {
              return state.state;
            } else {
              return {};
            }
          })
        );
      } else {
        _state_at_date$ = of(this.state);
      }
      return _state_at_date$.pipe(map(state => _get(state, state_path)));
    } else {
      return of(this.get(path));
    }
  }

  public requestHistory(history_fields: string[]) {
    // TODO history date
    return this.bg2Api.fetch_entity__raw$(this.id, history_fields).pipe(
      map((entity_dict: any) => {
        this.history = entity_dict.history;
        return entity_dict.history;
      })
    );
  }

  public local_events$$ = this._local_states$$.pipe(
    map(states => states.map(state => state.event_id)),
    switchMap(eids => this.bg2Api.getEventsObj(eids, { limit: -1, offset: 0 })),
    replay()
  );

  public streamGhostAvailableTimeseries(start: Date, end: Date): Observable<MeasurementDescription[]> {
    return of([]);
  }

  /** Override request timeseries in case of ghost apiary
   */
  public requestGhostTimeseries(
    measurements?: Array<string>,
    start?: Date,
    end?: Date,
    step?: string
  ): Observable<GetEntityTimeseriesResponse> {
    const empty_ts: GetEntityTimeseriesResponse = {
      available: [],
      timeseries: {
        data: [],
      },
    };
    return of(empty_ts);
  }

  /** Request entity timeseries data
   */
  protected _requestTimeseries(
    measurements?: Array<string>,
    start?: Date,
    end?: Date,
    step?: string,
    dev_history?: { [eid: number]: DeviceHistory }
  ): Observable<GetEntityTimeseriesResponse> {
    if (this.id < 0) {
      this._logger.error(`Ghost entity, we can requests timeseries`, measurements);
      return of({
        available: [],
        timeseries: {},
      });
    }

    if (isNil(start)) {
      start = undefined;
    }

    if (isNil(end)) {
      end = undefined;
    }

    if (isNil(step)) {
      step = undefined;
    }

    return this.bg2Api.fetch_entity_timeseries$(this.id, measurements, start, end, step);
  }

  /** Override request timeseries in case of ghost apiary
   */
  public requestTimeseries(
    measurements: Array<string> = [],
    start?: Date,
    end?: Date,
    step?: string
  ): Observable<GetEntityTimeseriesResponse> {
    const empty_ts: GetEntityTimeseriesResponse = {
      available: [],
      timeseries: {
        data: [],
      },
    };

    return this.user_acl.cannot$$('read_measurements_data').pipe(
      switchMap(cannot_read_measurements_data => {
        if (cannot_read_measurements_data) {
          return of<GetEntityTimeseriesResponse>(empty_ts);
        }

        return this.streamGhostAvailableTimeseries(start, end).pipe(
          // get ghost available ts
          switchMap(ghost_ats => {
            let ghost_request$ = of(empty_ts);
            if (ghost_ats.length > 0 && measurements && measurements.length > 0) {
              // if ghost available, request ghost
              ghost_request$ = this.requestGhostTimeseries(measurements, start, end, step);
            }
            let ts_request$ = of(empty_ts);
            if (this.id >= 0) {
              // anyway if not ghost it self => nomal request
              ts_request$ = this._requestTimeseries(measurements, start, end, step);
            }
            return combineLatest([ghost_request$, ts_request$, of(ghost_ats)]);
          }),
          // tap(data => this._logger.debug('requestTimeseries =>', data)),
          map(([ghost_ts, ts, ghost_ats]) => {
            // merge all available ts
            const merged_available = _merge(keyBy(ts.available || [], 'name'), keyBy(ghost_ats || [], 'name'));
            ts.available = values(merged_available); // sort ?

            // merge all ts
            const ghost_data = ghost_ts?.timeseries?.data || [];
            const ts_data = ts?.timeseries?.data || [];
            const merged = mergeWith(keyBy(ts_data, 'date'), keyBy(ghost_data, 'date'), (named_point: any, ghost_point: any) =>
              mergeWith(named_point || {}, ghost_point || {}, (named_value: any, ghost_value: any, key: string) => {
                const no_ghost_val = isNaN(ghost_value) || isNil(ghost_value);
                const no_named_val = isNaN(named_value) || isNil(named_value);
                if (no_named_val) {
                  return ghost_value;
                } else if (no_ghost_val) {
                  return named_value;
                } else {
                  const nb_sensor_named = named_point[key + '_nb_sensors'] || 1;
                  const nb_sensor_ghost = ghost_point[key + '_nb_sensors'] || 1;
                  return named_value * nb_sensor_named + (ghost_value * nb_sensor_ghost) / (nb_sensor_named + nb_sensor_ghost);
                }
              })
            );
            ts.timeseries = ts.timeseries || {};
            ts.timeseries.data = values(merged); // sort ?
            ts.timeseries.data.sort((a, b) => differenceInSeconds(parseDate(a.date), parseDate(b.date)));
            return ts;
          })
          // tap(data => this._logger.debug('requestTimeseries =>', data))
        );
      })
    );
  }

  /* ***** Internal methods ***** */

  protected whenLoaded(): void {
    // console.log(this.desc, 'loaded');
  }

  /***************************************************************************/
  /* Async stream notification handler
   */
  private streamUpdate(notification: StreamNotification): void {
    // console.log(this.desc, `Got stream notification: ${notification.action}`);
    // console.log(this.desc, 'notification:', notification);
    this.last_notification = notification;

    if (notification.id !== this.id) {
      return;
    }

    switch (notification.action) {
      case 'update': // ie. static_state_update
        const updated_entity = notification.entity;
        if (!isEqual(this._static_state, updated_entity.static_state)) {
          // console.log(this.desc, 'Static state changed => update');
          if (!this.has_changed || Entity.isSameStaticState(this.static_state, updated_entity.static_state)) {
            this.tmp_static_state = null;
          } else {
            // Get a new static state different than local "temporary one..."
            this._logger.info(
              this.desc,
              'Got new static state different from local one...',
              this.static_state,
              updated_entity.static_state
            );
            // console.error(this.desc, 'Got new static state different from local one...', this.static_state, updated_entity.static_state);
          }
          this._static_state = cloneDeep(updated_entity.static_state); // update "server" version of static state
          this.staticStateChanged(this.static_state);
        }
        this.checkStaticStateChanged();
        break;

      case 'invalidate':
        // console.log(this.desc, 'invalidate');
        const inconsistent_from_date = parseDate(notification.inconsistent_from_date);
        const inconsistent_from_event = notification.inconsistent_from_event;
        const no_state = notification.no_state || false;
        // const inconsistent_from_event_rev = notification['inconsistent_from_event_rev'];

        // Update last_state
        if (no_state) {
          const empty_state = new EntityState();
          empty_state.dirty = false;
          empty_state.is_last = true;
          empty_state.event_id = -1;
          empty_state.event_date = new Date(1970, 1, 1);
          empty_state.state = {};
          this.updateCleanState(empty_state);
        } else {
          this.dirty = true; // This also to trigger event_run$ check pipeline
          if (this.last_state) {
            this.last_state.dirty = true;
          }

          // Update last_state_clean
          if (this.last_state_clean?.olderThanDate(inconsistent_from_date, inconsistent_from_event)) {
            // Last state clean is now unknow...
            this.last_state_clean = null;
          }

          // RAZ error
          this.error = null;
          // Note that "run_error" will be check if someone subscribe to this.all_error$$

          if (notification.run) {
            this.bg2Api.runs.updateRunner(notification.run, this, false);
          }
        }
        break;

      case 'state_update':
        const updated_entity_state_tmp = notification.entity_state;
        // console.log(this.desc, `Is last ? ${updated_entity_state_tmp.is_last}`);
        if (!updated_entity_state_tmp.dirty) {
          // New clean state, update last clean state (we do not use it yet)
          if (
            !this.last_state ||
            this.last_state.dirty ||
            this.last_state.event_id !== updated_entity_state_tmp.event_id ||
            !isEqual(this.last_state.state, updated_entity_state_tmp.state)
          ) {
            const updated_entity_state = EntityState.Deserialize(updated_entity_state_tmp);
            // console.log(this.desc, 'State changed => will update');
            // console.log(this.desc, updated_entity_state, this.last_state);
            this.updateCleanState(updated_entity_state);
          } else {
            // console.log(this.desc, 'Got already know state');
          }
        } else {
          // console.log(this.desc, 'Recieved drity state...');
          const updated_entity_state = EntityState.Deserialize(updated_entity_state_tmp);
          this.updateDirtyState(updated_entity_state);
        }

        if (notification.run) {
          this.bg2Api.runs.updateRunner(notification.run, this, updated_entity_state_tmp.is_last);
        }
        break;

      default:
        console.log(this.desc, 'Unknow action');
        break;
    }

    // Indicate update of this own en
    this._updates$.next(this);
  }

  /** Unsubscribe all entity's internal subscription
   *
   * NOTE: should be call only if entity is destroy
   */
  public preDestroy(): void {
    // console.log(this.desc, 'preDestroy()');
    this.unsubscribeError();
    this.unsubscribeType();
    this.unsubscribeDirty();
    this.unsubscribeStaticState();
    this.unsubscribeState();
    this.unsubscribeStaticState();
    this.unsubscribeStream();
  }

  private binded_from = new Set();

  /* Register a "user" of the entity
   *
   * The entity stream will be subscribe/unsubscribe if there is at least one "user"
   */
  public bind(from: string): void {
    if (this.binded_from.size === 0) {
      // console.log(this.desc, 'BIND', from);
      this.subscribeStream();
    }
    this.binded_from.add(from);
  }

  /* UnRegister a "user" of the entity
   */
  public unbind(from: string): void {
    if (this.binded_from.size === 0) {
      return;
    }
    this.binded_from.delete(from);
    if (this.binded_from.size === 0) {
      // console.log(this.desc, 'UNBIND', from);
      this.unsubscribeStream();
    }
  }

  /* Stream subscribtion
   */
  protected subscribeStream(): void {
    this.unsubscribeStream();
    // TODO: does it need a re-sync of current state ? (if discontected for a while...)
    // Register to stream notification/updates
    // console.log(this.desc, 'subscribe entity stream');
    this._stream_sub = this.bg2Api.bg2Stream.subscribeToEntity(this).subscribe(notification => {
      this.streamUpdate(notification);
    });
  }

  protected unsubscribeType(): void {
    if (this.type_sub) {
      this.type_sub.unsubscribe();
    }
  }

  protected unsubscribeError(): void {
    if (this.error_sub) {
      this.error_sub.unsubscribe();
    }
  }

  protected unsubscribeState(): void {
    if (this.state_sub) {
      this.state_sub.unsubscribe();
    }
  }

  protected unsubscribeStaticState(): void {
    if (this.static_state_sub) {
      this.static_state_sub.unsubscribe();
    }
  }

  protected unsuscribeEntityAcl(): void {
    if (this.update_acl_sub) {
      this.update_acl_sub.unsubscribe();
    }
  }

  protected unsubscribeDirty(): void {
    if (this._is_dirty_sub) {
      this._is_dirty_sub.unsubscribe();
    }
  }

  protected unsubscribeStream(): void {
    if (this._stream_sub) {
      // console.log(this.desc, 'unsubscribeStream()');
      this._stream_sub.unsubscribe();
    }
  }

  /**** Helpers to set the displayed state ****/

  private _useState(state: EntityState): void {
    this.is_last = state.is_last;
    this._full_state$.next(state);
  }

  private useLastKnowState(): void {
    if (this.last_state?.state) {
      this._useState(this.last_state);
      this.dirty = this.last_state.dirty;
    } else if (this.last_state_clean?.state) {
      this._useState(this.last_state_clean);
      this.dirty = true;
    } else {
      this._logger.warn(this.desc, `Last state is not know`);
    }
  }

  private updateCleanState(new_state: EntityState): void {
    // Update last_state_clean
    this.last_state_clean = new_state;
    // Raz error
    this.error = null;

    // RAZ first_state_dirty, car si nouveau state clear,
    // le dernier qui etait dirty ne l'est plus ou n'existe plus
    this.first_state_dirty = null;

    // Update displayed state
    if (new_state.is_last) {
      // console.log(this.desc, 'UCS, Fully consistent & last one => update');
      this.last_state = new_state;
      this.useLastKnowState();
      // console.log(this.desc, this.last_state);
      // ^ This will update this.dirty and this.is_last
    }
  }

  /** Recieved a dirty state, so it should have an error msg
   */
  private updateDirtyState(new_state: EntityState): void {
    this.first_state_dirty = new_state;
    this.updateErrorFromState(new_state);
  }

  private updateErrorFromState(dirty_state: any): void {
    // console.log(this.desc, 'updateErrorFromState', dirty_state);
    if (dirty_state.error) {
      this.error = EntityUpdateError.FromEntityState(dirty_state, this.bg2Api);
    }
  }

  /* ***** Abstract methods ***** */

  /** Abstract method called when state has changed.
   *
   * One may also subscribe to `entity.state$` observable
   */
  protected stateChanged(state: any): void {}

  protected staticStateChanged(state: any): void {}

  /* ***** Class/Static/Generic methods ***** */

  /* Load last entity (dynamic) state
   */
  // TODO: change to loadAndBind()
  public loadState(): Observable<Entity<TStaticState>> {
    // console.log(this.desc, this.last_state);
    if (!this.last_state) {
      return this.bg2Api.fetch_entity__raw$(this.id).pipe(
        tap((entity_dict: any) => {
          this.updateStates(entity_dict);
        }),
        map(() => this)
      );
    } else {
      return of(this);
    }
  }

  protected updateStates(input: EntityInterface): void {
    if (input.last_state) {
      this.last_state = EntityState.Deserialize(input.last_state);
    }
    if (input.last_state_clean) {
      this.last_state_clean = EntityState.Deserialize(input.last_state_clean);
    }
    if (input.first_state_dirty) {
      this.first_state_dirty = EntityState.Deserialize(input.first_state_dirty);
    }
    if (this.last_state) {
      this.useLastKnowState();
      // console.log(this.desc, 'deserialize', this.dirty, input.first_state_dirty.error);
      if (this.dirty) {
        // console.log(this.desc, this.first_state_dirty);
        if (!isNil(this.first_state_dirty.error)) {
          this.updateErrorFromState(this.first_state_dirty);
        } else if (!isNil(this.last_state.error)) {
          this.updateErrorFromState(this.last_state);
        }
      }
    }
  }

  public resetLogger() {
    this._logger = new ConsoleLoggerService(this.desc, true);
  }

  public deserialize(input: EntityInterface): Entity<TStaticState> {
    Object.assign(this, input);
    this.resetLogger();
    this._static_state = cloneDeep(input.static_state);
    this.checkStaticStateChanged();
    this.updateStates(input);
    this.whenLoaded();

    // Load ACL
    this.user_acl.update_acl((input as any)._user_acl);

    // Trigger updates
    this._updates$.next(this);

    return this;
  }

  public link$$ = combineLatest({ name: this.name$$, id: this.id$$ }).pipe(
    distinctUntilRealChanged(),
    map(({ name, id }) => `<a href="javascript:void(modal:update_entity, eid=${id})">${name}</a>`),
    replay()
  );

  public asDict$$() {
    const as_dict$$ = combineLatest([
      this.state$$.pipe(distinctUntilRealChanged()),
      this.static_state$$.pipe(distinctUntilRealChanged()),
      this.link$$,
      this.name$$,
    ]).pipe(
      debounceTime(10),
      map(([state, static_state, link, name]) => {
        const entity_dict = this.asDict();
        entity_dict.link = link;
        entity_dict.name = name;
        return entity_dict;
      }),
      distinctUntilRealChanged(),
      replay()
    );
    return as_dict$$;
  }

  public asDict() {
    const entity_dict = this.toJSON();
    entity_dict.state = cloneDeep(this.state);
    return entity_dict;
  }

  public toJSON() {
    const obj: Dictionary<any> = {};
    obj.id = this.id;
    obj.type = this.type;
    obj.user_id = this.user_id;
    obj.static_state = cloneDeep(this.static_state);
    return obj;
  }

  public is_ghost$$: Observable<boolean> = this.id$$.pipe(
    map(id => id < 0),
    distinctUntilChanged(),
    replay()
  );

  // #region -> (events management)

  /**
   * @description
   *
   * Request an array of events from specific request config.
   *
   * @param config
   *
   * @returns
   */
  public requestEvents(config: EntityEventRequestConfig): Observable<EventPaging> {
    const should_update = config.update || false;

    return combineLatest({
      api_events: this._requestEvents(config),
      local_events: should_update ? this.local_events$$ : this.local_events$$.pipe(take(1)),
    }).pipe(
      map(({ api_events, local_events }) => {
        const res = clone(api_events);
        res.events = _concat(local_events.events, res.events);

        // TODO: Fix pagination (because of local events). May return 11 events instead of 10.

        if (config?.types) {
          res.events = res.events.filter(event => config?.types.includes(event.type));
        }

        return res;
      })
    );
  }

  /**
   * @description
   *
   *
   * @param config
   *
   * @returns
   */
  private _requestEvents(config: EntityEventRequestConfig): Observable<EventPaging> {
    const should_update = config.update || false;
    delete config.update;

    if (!should_update) {
      return this.bg2Api.getEntityEventsObj(this.id, config);
    } else {
      let idx = 0;

      return concat(of(idx), this.updates$.pipe(map(() => idx++))).pipe(
        debounceTime(1000),
        switchMap(_idx => this.bg2Api.getEntityEventsObj(this.id, config)),
        replay()
      );
    }
  }

  // #endregion

  // #region -> (last events management)

  /**
   * @description
   *
   * Observes the identifier of the last event.
   *
   * This property observes the identifier of the last event of
   * the current entity.
   */
  public last_event_id$$: Observable<number> = this.state$$.pipe(
    map(() => this.last_state.event_id),
    distinctUntilRealChanged()
  );

  /**
   * @description
   *
   * Observes the last event object.
   *
   * This property observes the last event object of the
   * current entity.
   */
  public last_event$$: Observable<Event> = this.last_event_id$$.pipe(
    switchMap(() => this.stream_events$$(0, 1)),
    map(({ events }) => events?.[0] || null),
    replay()
  );

  /**
   * @description
   *
   *
   * @param type
   * @param offset
   * @param total
   *
   * @returns
   */
  public fetch_last_event$$<EventType extends Event>(type: string, offset: number = 0, total: number = 1): Observable<EventType[]> {
    return this.user_acl.can$$('read_all_events').pipe(
      switchMap(can_read_all_events => {
        if (!can_read_all_events) {
          return of<EventType[]>([]);
        }

        return this.id$$.pipe(
          switchMap(apiary_id => this.bg2Api.getEventsObj([apiary_id], { limit: total, offset }, [type])),
          map(event_paging => (event_paging?.events || []) as EventType[]),
          replay()
        );
      })
    );
  }

  /**
   * @description
   *
   *
   * @param offset
   * @param limit
   * @param types
   *
   * @returns
   */
  public stream_last_events$$(offset: number = 0, limit?: number, types?: string[]): Observable<Event[]> {
    return this.stream_events$$(offset, limit, types).pipe(
      map(events_paging => events_paging?.events),
      replay()
    );
  }

  /**
   * @description
   *
   *
   * @param type
   * @param offset
   *
   * @returns
   */
  public stream_last_event$$<EventType extends Event>(type: string, offset: number = 0): Observable<EventType> {
    return this.stream_events$$(offset, 1, [type]).pipe(
      map(event_paging => event_paging?.events || []),
      map(events => (events?.[0] || null) as EventType),
      replay()
    );
  }

  /**
   * @description
   *
   *
   * @param offset
   * @param limit
   * @param types
   *
   * @returns
   */
  public stream_last_added_event$$(types: string[]): Observable<Event> {
    return this.stream_events$$(0, -1, types).pipe(
      map(paged_events => paged_events?.events),
      startWith(null),
      pairwise(),
      map(([previous, next]) => {
        if (isNil(previous)) {
          return [];
        }

        const previous_ids = previous.map(event => event.id);
        const next_ids = next.map(event => event.id);

        const differenced_ids = difference(next_ids, previous_ids);
        return _concat(previous, next).filter(event => differenced_ids.includes(event.id));
      }),
      map(events => events?.[0] || null),
      filter(value => !isNil(value))
    );
  }

  /**
   * @description
   *
   *
   * @param types
   *
   * @returns
   */
  public stream_last_removed_event$$(types?: string[]) {
    return this.stream_events$$(0, -1, types).pipe(
      map(paged_events => paged_events.events),
      startWith(null),
      pairwise(),
      map(([previous, next]) => {
        if (isNil(previous)) {
          return [];
        }

        const previous_ids = previous.map(event => event.id);
        const next_ids = next.map(event => event.id);

        const differenced_ids = difference(previous_ids, next_ids);
        return _concat(previous, next).filter(event => differenced_ids.includes(event.id));
      }),
      map(events => events?.[0] || null),
      filter(value => !isNil(value))
    );
  }

  /**
   * Watches the last X events of the current entity.
   *
   * @param offset
   * @param limit Total number of events to watch. Use `-1` to watch all the events.
   * @param types
   *
   * @returns
   */
  public stream_events$$(offset?: number, limit?: number, types?: string[]): Observable<{ paging: Paging; events: Event[] }> {
    return this.user_acl.can$$('read_all_events').pipe(
      switchMap(can_read_all_events => {
        if (!can_read_all_events) {
          this._logger.warn("No ACL to get entity");
          return of<EventPaging>({ paging: { total: 0, limit: 10, offset: 0 }, events: [] });
        }

        return this.requestEvents({ update: true, run_info: true, offset, limit, types });
      })
    );
  }

  /**
   * Fetch the reccorded event after entity setup
   */
  public event_after_setup$$ = this.initial_setup_date$$.pipe(
    switchMap(setup_date => {
      if (!isNil(setup_date)) {
        return this.bg2Api.fetch_entity_state$(this.id, setup_date)
      } else {
        return of(null)
      }
    }),
    map(res => res?.state?.next_event_id),
    switchMap(event_id => !isNil(event_id) ? this.bg2Api.getEventObj(event_id) : of(null))
  )

  // #endregion

  // #region -> (archiving management)

  public archived$$ = this.state$$.pipe(
    map(state => pick(state, ['archived', 'archived_since'])),
    replay()
  );

  public is_archived$$: Observable<boolean> = this.archived$$.pipe(
    map(({ archived }) => archived || false),
    distinctUntilChanged(),
    replay()
  );

  public is_not_archived$$: Observable<boolean> = this.is_archived$$.pipe(map(is_archived => !is_archived));

  public archived_since$$ = this.archived$$.pipe(
    map(({ archived_since }) => archived_since),
    distinctUntilRealChanged(),
    replay()
  );

  public archivable$$ = this.is_archived$$.pipe(
    map(is_archived => !is_archived),
    replay()
  );

  // #endregion

  // #region -> (deletion management)

  /**
   * Observable to know if the entity can be deleted or not.
   */
  public can_be_deleted$$ = combineLatest([this.last_event_id$$, this.initial_setup_event_id$$]).pipe(
    map(([last_event_id, setup_event_id]) => {
      if (isNil(last_event_id)) {
        return true;
      }

      return last_event_id === setup_event_id;
    }),
    replay()
  );

  /**
   * Observable to know of the entity cannot be deleted or not.
   */
  public cannot_be_deleted$$ = this.can_be_deleted$$.pipe(
    map(can_be_deleted => !can_be_deleted),
    replay()
  );

  // #endregion
}

/** */
export class TestEntity extends Entity<any> {
  // #region -> (acl management)

  /**
   * @inheritdoc
   */
  protected _user_acl_manager: AbstractEntityUserACL = null;

  /**
   * @inheritdoc
   */
  public get user_acl(): AbstractEntityUserACL {
    return this._user_acl_manager;
  }

  // #endregion
}
