import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { SchemaFormModule } from 'ngx-schema-form';

import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';

import { MiscModule } from 'app/misc/misc.module';
import { SharedModule } from 'app/shared-module/shared.module';
import { DevicesSharedModule } from '../shared/devices-shared.module';
import { ZohoSharedModule } from 'app/views/zoho/shared/zoho-shared.module';
import { HivesSharedModule } from 'app/views/hives/shared/hives-shared.module';
import { MaterialSharedModule } from 'app/shared-module/material-shared.module';
import { MiscWidgetsModule } from 'app/widgets/misc-widgets/misc-widgets.module';
import { DialogsModalsModule } from 'app/widgets/dialogs-modals/dialogs-modals.module';
import { WidgetsReusableModule } from 'app/widgets/widgets-reusables/widgets-reusables.module';

import { device_modal_components } from './modals/device-modal';
import { router_tracer_modal_components } from './modals/route-tracer';
import { create_device_modal_components } from './modals/create-device-modal';
import { device_authorized_movements_modal_components } from './modals/device-movements-authorization-modal';

import {
  RouteTracerModal,
  DeviceModalComponent,
  DeviceConfigModalComponent,
  CreateDeviceModalComponent,
  DeviceMovementAuthorizationModalComponent,
  DeviceCalibrationTemperatureModalComponent,
  DeviceSupportUpdateDeskTicketModalComponent,
  DeviceSupportCloseModalComponent,
} from './modals';

import {
  DevicesOtaDialogComponent,
  DevicesOrderDialogComponent,
  DevicesRegisterDialogComponent,
  DevicesBulkConfigDialogComponent,
  DeviceEnableDisableDialogComponent,
  ImportDevicesToCreateDialogComponent,
  DevicesSupportsUpdateDialogComponent,
  DevicesSupportsCreateDialogComponent,
  DeviceBatteryChangeTypeDialogComponent,
  DeviceAuthorizedMovementDialogComponent,
  DevicesBulkBatteryChangeDialogComponent,
} from './dialogs';
import { DeviceCalibrationTemperatureChartComponent } from './modals/device-calibration/device-calibration-temperature-modal/device-calibration-temperature-chart/device-calibration-temperature-chart.component';
import { DeviceCalibrationTemperatureSelectedChartComponent } from './modals/device-calibration/device-calibration-temperature-modal/device-calibration-temperature-selected-chart/device-calibration-temperature-selected-chart.component';
import { QuillModule } from 'ngx-quill';

@NgModule({
  declarations: [
    // Device modals
    RouteTracerModal,
    DeviceModalComponent,
    DeviceConfigModalComponent,
    CreateDeviceModalComponent,
    DeviceSupportCloseModalComponent,
    DeviceMovementAuthorizationModalComponent,
    DeviceCalibrationTemperatureModalComponent,
    DeviceSupportUpdateDeskTicketModalComponent,

    // Device dialogs
    DevicesOtaDialogComponent,
    DevicesOrderDialogComponent,
    DevicesRegisterDialogComponent,
    DevicesBulkConfigDialogComponent,
    DeviceEnableDisableDialogComponent,
    DevicesSupportsCreateDialogComponent,
    DevicesSupportsUpdateDialogComponent,
    ImportDevicesToCreateDialogComponent,
    DeviceBatteryChangeTypeDialogComponent,
    DevicesBulkBatteryChangeDialogComponent,
    DeviceAuthorizedMovementDialogComponent,

    // Internal components
    ...device_modal_components,
    ...router_tracer_modal_components,
    ...create_device_modal_components,
    ...device_authorized_movements_modal_components,
    DeviceCalibrationTemperatureChartComponent,
    DeviceCalibrationTemperatureSelectedChartComponent,
  ],
  imports: [
    // Base modules
    FormsModule,
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
    QuillModule.forRoot(),

    // External modules
    SchemaFormModule,
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory,
    }),

    // Component modules
    ZohoSharedModule,
    HivesSharedModule,
    DevicesSharedModule,

    // Other modules
    MiscModule,
    SharedModule,
    MiscWidgetsModule,
    DialogsModalsModule,
    MaterialSharedModule,
    WidgetsReusableModule,
  ],
})
export class DeviceDialogsAndModalsModule {}
