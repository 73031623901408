import { Pipe, PipeTransform } from '@angular/core';

import { Dictionary } from 'app/typings/core/interfaces';

@Pipe({
  name: 'jsonInline',
  pure: true,
})
export class JsonInlinePipe implements PipeTransform {
  public transform(value: Dictionary<any>): string {
    return JSON.stringify(value);
  }
}
