import { DateTime } from 'luxon';
import { isNil } from 'lodash-es';

import { BehaviorSubject, filter, map, Observable, take } from 'rxjs';
import { distinctUntilRealChanged, replay } from '@bg2app/tools/rxjs';

import { SimpleSetterGetter } from 'app/models';

import { compute_difference_in_days_luxon } from '@bg2app/tools/dates';

/**
 * Class representing a date range manager.
 */
export class DateRangeManager {
  // #region -> (class basics)

  /** */
  constructor() {}

  /** */
  public initialize(initial: [DateTime, DateTime], timezone?: string): void {
    this.timezone.value = timezone;
    this.range.value = { start: initial[0], end: initial[1] };
  }

  // #endregion

  // #region -> (date properties)

  /** */
  protected timezone = new SimpleSetterGetter<string>(null);

  /** */
  public get manager_timezone() {
    return this.timezone.value;
  }

  // #endregion

  // #region -> (range properties)

  /** */
  private range = new SimpleSetterGetter<{ start: DateTime; end: DateTime }>({ start: null, end: null });

  /** */
  public range$$: Observable<{ start: DateTime; end: DateTime }> = this.range.value$$.pipe(
    filter(range => !isNil(range?.start) && !isNil(range?.end)),
    map(({ start, end }) => {
      if (end.toMillis() <= start.toMillis()) {
        return { start: end, end: start };
      }

      return { start, end };
    }),
    replay()
  );

  /** */
  public start_date$$ = this.range$$.pipe(
    map(range => range?.start),
    replay()
  );

  /** */
  public end_date$$ = this.range$$.pipe(
    map(range => range?.end),
    replay()
  );

  /** */
  public days_to_display$$ = this.range$$.pipe(
    map(range => compute_difference_in_days_luxon(range)),
    distinctUntilRealChanged()
  );

  // #endregion

  // #region -> (management methods)

  /** */
  private _range_name$$ = new BehaviorSubject<'weekly' | 'monthly' | 'custom'>('custom');

  /** */
  public range_name$$ = this._range_name$$.asObservable().pipe(distinctUntilRealChanged());

  /** */
  public ranged_to = {
    /** */
    fixed: {
      /** */
      weekly: () => {
        this.range$$.pipe(take(1)).subscribe({
          next: range => {
            const end = range.end;
            const start = end.minus({ days: 7 });

            this.range.value = { start, end };
            this._range_name$$.next('weekly');
          },
        });
      },

      /** */
      monthly: () => {
        this.range$$.pipe(take(1)).subscribe({
          next: range => {
            const end = range.end;
            const start = end.minus({ days: 30 });

            this.range.value = { start, end };
            this._range_name$$.next('monthly');
          },
        });
      },
    },
  };

  /** */
  public move_to = {
    /** */
    previous: {
      /** */
      day: () => {
        const current_range = this.range.value;

        const new_end_date = current_range.end.minus({ days: 1 });
        const new_start_date = current_range.start.minus({ days: 1 });

        this.range.value = { start: new_start_date, end: new_end_date };
      },
    },

    /** */
    next: {
      /** */
      day: () => {
        const current_range = this.range.value;

        const new_end_date = current_range.end.plus({ days: 1 });
        const new_start_date = current_range.start.plus({ days: 1 });

        this.range.value = { start: new_start_date, end: new_end_date };
      },
    },
  };

  // #endregion
}
