import { captureException, captureMessage, TraceService, createErrorHandler } from '@sentry/angular-ivy';

// Angular libs
import { NgModule, Injectable, APP_INITIALIZER, LOCALE_ID } from '@angular/core';
import { ErrorHandler } from '@angular/core';
import { BrowserModule, HammerGestureConfig, HammerModule, HAMMER_GESTURE_CONFIG, Title } from '@angular/platform-browser';
import { Router, RouterModule } from '@angular/router';
import { APP_BASE_HREF, registerLocaleData } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS, HttpClient, HttpErrorResponse } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { SocketIoModule } from 'ngx-socket-io';

import { TranslateModule, TranslateLoader, TranslateParser } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { BgTranslateParser } from './shared-module/translate.parser';

import { SchemaValidatorFactory, ZSchemaValidatorFactory, WidgetRegistry } from 'ngx-schema-form';

// UI libs
import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime-ex';
import { NgxChartsModule } from '@swimlane/ngx-charts';

// Internal modules
import { SharedModule } from './shared-module/shared.module';
import { MiscModule } from './misc/misc.module';
import { ModalsModule } from './routable-modals/routable-modal.module';
import { MiscWidgetsModule } from 'app/widgets/misc-widgets/misc-widgets.module';
import { ApiarySharedModule } from './views/apiary/apiary-shared.module';
import { DevicesSharedModule } from './views/devices/shared/devices-shared.module';
import { DialogsModalsModule } from './widgets/dialogs-modals/dialogs-modals.module';
import { EfWidgetModule, EfWidgetRegistry } from './widgets/event-form';

// App services
import { AppStateService } from './core/app-state.service';
import { GhostService } from './core/ghost';
import { AuthInterceptor } from './core/http-interceptor.service';
import { UsersApiService, DeviceApi } from './core';
import { Bg2Socket } from './core/bg2socket.service';
import { Beeguard2Stream } from './core';
import { BeeguardAuthService } from './core';
import { BeeguardAuthGuard } from './core/auth/beeguard-auth.guard';
import { DialogsService } from './widgets/dialogs-modals/dialogs.service';
import { SidenavService } from './core/sidenav.service';

// Routing
import { AppRoutingModule } from './app-routing.module';
import { ModalsManagerComponent } from './app.modals-manager.component';
import { ModalsService } from './widgets/dialogs-modals/modals.service';

// Pages
import { AppComponent } from './app.component';
import { LoginComponent } from './views/auth/login/login.component';
import { LogoutComponent } from './views/auth/logout/logout.component';

// Components
import { environment } from '../environments/environment';
import { MaterialSharedModule } from './shared-module/material-shared.module';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { BeeguardAdminGuard } from './core/auth/beeguard-admin.guard';
import { WidgetsSnackbarModule } from './widgets/widgets-snackbar/widgets-snackbar.module';

import * as Hammer from 'hammerjs';
import { DateFnsAdapter, MAT_DATE_FNS_FORMATS } from '@angular/material-date-fns-adapter';
import { WidgetsReusableModule } from './widgets/widgets-reusables/widgets-reusables.module';
import { ApplicationVersionService } from './core/services/global/app-version.service';
import { ENV, getEnv } from './core/providers/environment.provider';
import { ResetPasswordComponent } from './views/auth/reset-password/reset-password.component';
import { AppInitService, initialize_app } from './core/services/global/app-init.service';

import localeFR from '@angular/common/locales/fr';
import localeES from '@angular/common/locales/es';
import localeIT from '@angular/common/locales/it';
import localePT from '@angular/common/locales/pt';
import localeEN from '@angular/common/locales/en';
import localeJA from '@angular/common/locales/ja';

registerLocaleData(localeFR, 'fr');
registerLocaleData(localeES, 'es');
registerLocaleData(localeIT, 'it');
registerLocaleData(localePT, 'pt');
registerLocaleData(localeEN, 'en');
registerLocaleData(localeJA, 'ja');

@Injectable()
export class SentryErrorHandler implements ErrorHandler {
  private readonly sentryErrorHandler: ErrorHandler;

  constructor(private _appVersion: ApplicationVersionService) {
    this.sentryErrorHandler = createErrorHandler({ showDialog: false });
  }

  handleError(error: any): void {
    const chunk_failed_message = /Loading chunk [\d]+ failed/;
    if (chunk_failed_message.exec(error?.message) !== null) {
      this._appVersion.onChunkFailed();
    } else {
      // Fallback to the default Sentry error handler
      this.sentryErrorHandler.handleError(error);
    }
  }
}


@Injectable()
export class MyHammerConfig extends HammerGestureConfig {
  overrides = <any>{
    swipe: { enable: true, direction: Hammer.DIRECTION_ALL },
  };
}

export const create_translate_loader = (http: HttpClient) =>
  new TranslateHttpLoader(http, './assets/i18n/', `.json?version=${environment.version}`);

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    RouterModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    HammerModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: create_translate_loader,
        deps: [HttpClient],
      },
      parser: { provide: TranslateParser, useClass: BgTranslateParser },
    }),
    SocketIoModule,

    // Views cpnts
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    NgxChartsModule,

    // Internal modules (classic)
    AppRoutingModule,
    MiscWidgetsModule,
    ApiarySharedModule,
    DevicesSharedModule,

    // Internal modules (shared)
    MiscModule,
    SharedModule,
    ModalsModule,
    DialogsModalsModule,
    DevicesSharedModule,
    MaterialSharedModule,
    EfWidgetModule,
    WidgetsSnackbarModule,
    WidgetsReusableModule,
  ],
  declarations: [AppComponent, ModalsManagerComponent, LoginComponent, LogoutComponent, ResetPasswordComponent],
  providers: [
    {
      provide: TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initialize_app,
      deps: [AppInitService, TraceService],
      multi: true,
    },

    { provide: LOCALE_ID, useValue: 'fr' },
    { provide: DateAdapter, useClass: DateFnsAdapter },
    { provide: MAT_DATE_FORMATS, useValue: MAT_DATE_FNS_FORMATS },

    AppStateService,
    GhostService,
    { provide: ENV, useFactory: getEnv },
    { provide: APP_BASE_HREF, useValue: '/' },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: ErrorHandler, useClass: SentryErrorHandler },
    {
      provide: WidgetRegistry,
      useClass: EfWidgetRegistry,
    },
    {
      provide: SchemaValidatorFactory,
      useClass: ZSchemaValidatorFactory,
    },
    { provide: HAMMER_GESTURE_CONFIG, useClass: MyHammerConfig },
    Bg2Socket,
    ModalsService,
    BeeguardAuthGuard,
    BeeguardAdminGuard,
    BeeguardAuthService,
    Title,
    DialogsService,
    SidenavService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
