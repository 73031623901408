import { AfterViewInit, ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { assign, cloneDeep, isArray, isEmpty, isEqual, isNil, isObject } from 'lodash-es';

import { BehaviorSubject, concat, debounceTime, distinctUntilChanged, map, Observable, of, Subscription, switchMap, tap } from 'rxjs';
import { filter } from 'rxjs';

import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';

import { marker as i18n } from '@biesbjerg/ngx-translate-extract-marker';

import { FormPropertyFactory, FormProperty, ArrayWidget } from 'ngx-schema-form';

import { Beeguard2Api } from 'app/core';
import { EfArrayWidget, ArrayOptions } from '../array/array.widget';
import { distinctUntilRealChanged, replay } from '@bg2app/tools/rxjs';
import { EventDateAndId, robustSearchProperty, trackEventDateAndId, TrackOption, trackValue } from '../eventforms.helpers';

export interface ArrayAddOnlyOptions extends ArrayOptions {
  auto_add: boolean;
}

@AutoUnsubscribe()
@Component({
  selector: 'bg2-ef-array-add-only-widget',
  templateUrl: './array-add-only.widget.html',
  styleUrls: ['./array-add-only.widget.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EfArrayAddOnlyWidgetComponent extends ArrayWidget implements OnInit, AfterViewInit, OnDestroy {
  private _redraw$$ = new BehaviorSubject<boolean>(false);
  public redraw$$ = this._redraw$$.asObservable();

  private set redraw(val: boolean) {
    this._redraw$$.next(val);
  }

  public options: ArrayAddOnlyOptions = {
    remove_btn_inline: true,
    auto_add: false,
    show_add: true,
    show_remove: true
  };

  protected valuechange_sub: Subscription;
  public _properties$$ = new BehaviorSubject<FormProperty[]>([]);
  public properties$$ = this._properties$$.asObservable();

  protected default_to: any; // set default timeout ref

  private _is_default_value$$ = new BehaviorSubject<boolean>(true);
  public is_default_value$$ = this._is_default_value$$.asObservable().pipe(distinctUntilChanged());
  public set is_default_value(val) {
    this._is_default_value$$.next(val);
  }
  public get is_default_value(): boolean {
    return this._is_default_value$$.getValue();
  }
  protected default_value_sub: Subscription;

  public set value(value: any) {
    this.formProperty.setValue(value, false);
  }

  public get value(): any {
    return this.formProperty.value;
  }

  private previous_default_value: any; // Previous value for default "reset"

  public size$$ = this.properties$$.pipe(
    map(properties => properties.length),
    distinctUntilChanged(),
    replay()
  );

  public empty$$ = this.size$$.pipe(map(size => size === 0));

  public canAdd$$ = this.size$$.pipe(
    map(size => {
      const max = this.schema.maxItems;
      return isNil(max) || size < max;
    }),
    replay()
  );

  public canRemove$$ = this.size$$.pipe(
    map(size => {
      const min = this.schema.minItems;
      return isNil(min) || size > min;
    }),
    replay()
  );

  get label_remove(): string {
    return this.schema.description_remove || i18n('WIDGETS.EVENT_FORM.ARRAY.Remove');
  }

  get label_add_first(): string {
    return this.schema.description_add_first || this.schema.description_add || i18n('WIDGETS.EVENT_FORM.ARRAY.Add');
  }

  get label_add(): string {
    return this.schema.description_add || i18n('WIDGETS.EVENT_FORM.ARRAY.Add');
  }

  constructor(protected bg2Api: Beeguard2Api, private formPropertyFactory: FormPropertyFactory) {
    super();
  }

  ngOnInit(): void {
    this.options = assign(this.options, this.schema.config || {}); // for retro compat.
    this.options = assign(this.options, this.schema.options);

    this.valuechange_sub = concat(of(this.formProperty.value), this.formProperty.valueChanges)
      .pipe(
        debounceTime(100),
        distinctUntilRealChanged(),
        map(() => this.formProperty.properties as FormProperty[]),
        // tap(properties => console.log('debug NEW prop', properties)),
        switchMap(properties =>
          this.redraw$$.pipe(
            // handle "redraw": force empty property array during redraw
            map(_redraw => (_redraw ? [] : properties))
          )
        )
        // tap(properties => console.log('debug NEW porp redraw', properties)),
      )
      .subscribe(properties => {
        this._properties$$.next(properties);
      });

    this.newFormProperty();
  }

  ngAfterViewInit(): void {
    super.ngAfterViewInit();
    // We try to load default value if initial value isNil
    console.log('compute default ?', this.value);
    this.default_to = setTimeout(() => {
      console.log('compute default ?', this.value);
      if (isNil(this.value) || this.value.length === 0) {
        this.computeDefault();
      } else {
        this.is_default_value = false;
      }
    }, 100);
  }

  ngOnDestroy(): void {
    this.defaultValueUnsubscribe();
    if (this.default_to) {
      clearTimeout(this.default_to);
    }
  }

  protected defaultValueUnsubscribe(): void {
    this.default_value_sub?.unsubscribe();
  }

  protected computeDefault(): void {
    if (isNil(this.schema._default)) {
      return;
    }
    const default_conf = this.schema._default;
    this.defaultValueUnsubscribe();
    console.log('default_conf', default_conf);
    this.default_value_sub = this.trackDefault(this.bg2Api, default_conf)
      ?.pipe(tap(value => console.log(this.formProperty.path, 'default val ?', value)))
      .subscribe(value => {
        if (!this.previous_default_value || isEqual(this.value, this.previous_default_value)) {
          // current value is the previous default, so we update the value
          this.value = value;
          this.previous_default_value = value;
        }
      });
  }

  protected robustSearchProperty(path: string): FormProperty {
    return robustSearchProperty(this.formProperty, path);
  }

  /**
   * Observable over current event date and id
   */
  protected trackEventDateAndId(dpath: string): Observable<EventDateAndId> {
    const date_property = this.robustSearchProperty(dpath);
    return trackEventDateAndId(date_property);
  }

  /**
   * Get an observable on default value
   *
   * @param default_conf default configuration schema (`this.schema._default`)
   * @param dpath path to date property
   */
  protected trackDefault(bg2Api: Beeguard2Api, default_conf: TrackOption, dpath?: string): Observable<any> | null {
    dpath = dpath ? dpath : '/date';
    const event_date_and_id = this.trackEventDateAndId(dpath);
    return trackValue(this.formProperty, bg2Api, default_conf, event_date_and_id);
  }

  protected newItemCreated(new_item: FormProperty): void {}

  addItem(value: any = null): void {
    const new_item = this.formProperty.addItem(value);
    this.newItemCreated(new_item);
  }

  private forceRedraw() {
    // sub form elements do not always match with the correct properties after a delete
    this.redraw = true;
    setTimeout(() => {
      this.redraw = false;
    }, 100);
  }

  removeItem(property: FormProperty): void {
    super.removeItem(property);
    // Note: this "redraw" is needed to fix issue:
    this.forceRedraw();
  }

  private _new_property$$ = new BehaviorSubject<FormProperty>(null);

  public new_property$$ = this._new_property$$.asObservable();

  public set new_property(np: FormProperty) {
    this._new_property$$.next(np);
  }

  private new_property_sub: Subscription;

  // ngOnDestroy(): void { }

  public addItemProperty(np: FormProperty): void {
    this.addItem(np.value);
    this.newFormProperty();
  }

  private newFormProperty(): void {
    this.new_property = null;
    if (this.new_property_sub) {
      this.new_property_sub.unsubscribe();
    }
    setTimeout(() => {
      const schema = cloneDeep(this.schema.items);
      if (this.schema.label_add) {
        schema.label = this.schema.label_add;
      }
      const new_property = this.formPropertyFactory.createProperty(schema, this.formProperty);
      this.new_property = new_property;
      if (this.options.auto_add) {
        // If auto_add when new property changed, it is added to the array
        this.new_property_sub = new_property.valueChanges
          .pipe(
            filter(value => !isNil(value)),
            filter(value => !isObject(value) || !isEmpty(value)),
            filter(value => !isArray(value) || !isEmpty(value))
          )
          .subscribe(value => {
            this.addItemProperty(new_property);
          });
      }
    }, 1);
  }
}
