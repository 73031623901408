import { map, Observable, of } from 'rxjs';

import { assign, cloneDeep, isNil } from 'lodash-es';

import { DeviceApi } from 'app/core';
import { DeviceQueryParams } from 'app/core/api/device/device-api-service';

import { DeviceRSSIData, GPSJobData, GPSSecondaryData } from '../data';
import { DRDevice } from './DRDevice';

import { IFx2ConfigurationSchema } from './configuration/IFx2';
import {
  DEVICE_BATTERY_TYPE,
  DEVICE_SIMPLIFIED_BATTERY_STATE,
  DEVICE_SIMPLIFIED_BATTERY_STATE_REASON,
  DeviceCommunicationTechnology,
  DeviceSimplifiedBatteryState,
} from './enumerators';

export class ComputeDevice extends DRDevice {
  // #region -> (model basics)

  constructor(protected deviceApi: DeviceApi, params?: DeviceQueryParams) {
    super(deviceApi, params);

    this.is_gateway = true;
    this.type = 'Compute';
  }

  public fetchConfigurationSchema$(): Observable<any> {
    const full_schema = cloneDeep(IFx2ConfigurationSchema);
    return of(full_schema);
  }

  // #endregion

  public fetch_job_data$(start?: Date, end?: Date, step?: string): Observable<GPSJobData> {
    return of(null);
  }

  public fetch_secondary_data$(start?: Date, end?: Date, step?: string): Observable<GPSSecondaryData> {
    return of(null);
  }

  /** */
  protected get_battery_critical_vbat$$(): Observable<number> {
    return of(null);
  }

  // #endregion

  // #region -> (communication technology)

  /** */
  protected get_com_technology$$(): Observable<DeviceCommunicationTechnology[]> {
    return of(null);
  }

  // #endregion

  // #region -> battery

  /** */
  protected compute_battery_type$$(): Observable<DEVICE_BATTERY_TYPE> {
    return of(null);
  }

  /** */
  public get_battery_simplified_state$$(): Observable<DeviceSimplifiedBatteryState> {
    return this._get_default_battery_simplified_state$$.pipe(
      map(state => {
        if (!isNil(state)) {
          return state;
        }

        return {
          state: DEVICE_SIMPLIFIED_BATTERY_STATE.UNKNOWN,
          reason: DEVICE_SIMPLIFIED_BATTERY_STATE_REASON.UNKNOWN,
        };
      })
    );
  }

  // #endregion

  // #region -> (device timeseries)

  public fetch_rssi$(start?: Date, end?: Date, step?: string): Observable<DeviceRSSIData> {
    return super.requestTimeseries(['rssi_gprs'], start, end, step).pipe(
      map(
        response =>
          <DeviceRSSIData>{
            points: response?.timeseries?.data ?? [],
          }
      )
    );
  }

  // #endregion
}
