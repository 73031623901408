import * as d3 from 'd3';

import { find, isNil, merge, orderBy } from 'lodash-es';

import { marker as i18n } from '@biesbjerg/ngx-translate-extract-marker';

import { AppStateService } from 'app/core/app-state.service';
import { ConsoleLoggerService } from 'app/core/console-logger.service';
import { D3SharedCursorService } from 'app/core/services/global/d3-shared-cursor.service';

import { TemperatureDataPoint, HiveTemperatureData } from 'app/models/data';

import { find_closest } from 'app/misc/tools';
import {
  AbstractD3Axes,
  AbstractD3Grid,
  AbstractD3Labels,
  AbstractD3Scales,
  AbstractUserEvents,
  AbstractDataObjects,
  AbstractEventObjects,
  D3SvgBuilderLuxon,
} from '@bg2app/models/charts';
import { D3GridDayCycle } from '@bg2app/models/charts';
import { NgZone } from '@angular/core';

interface HiveClosestTemperature extends HiveTemperatureData {
  closest_point: TemperatureDataPoint;
}

/** */
interface Axes extends AbstractD3Axes {
  /** */
  temperature: {
    /** */
    axis: d3.Axis<d3.AxisDomain>;

    /** */
    container: d3.Selection<SVGGElement, unknown, HTMLElement, any>;
  };
}

/** */
interface Scales extends AbstractD3Scales {
  /** */
  temperature: d3.ScaleLinear<number, number, never>;
}

/** */
interface DataObjects extends AbstractDataObjects {
  /** */
  per_hive: {
    /** */
    [key: string]: {
      /** */
      shape: d3.Line<TemperatureDataPoint>;

      /** */
      defined_data_path: d3.Selection<SVGPathElement, unknown, HTMLElement, any>;

      /** */
      not_defined_data_path: d3.Selection<SVGPathElement, unknown, HTMLElement, any>;
    };
  };
}

/** */
interface UserEvents extends AbstractUserEvents {
  /** */
  time: {
    /** */
    text: d3.Selection<SVGTextElement, unknown, HTMLElement, any>;

    /** */
    container: d3.Selection<SVGGElement, unknown, HTMLElement, any>;
  };

  /** */
  temperature: {
    /** */
    text: d3.Selection<SVGGElement, unknown, HTMLElement, any>;

    /** */
    container: d3.Selection<HTMLDivElement, unknown, HTMLElement, any>;
  };

  /** */
  per_hive: {
    /** */
    [key: string]: {
      /** */
      circle: d3.Selection<SVGCircleElement, unknown, HTMLElement, any>;
    };
  };
}

/** */
interface Labels extends AbstractD3Labels {
  /** */
  labels: {
    /** */
    temperature: d3.Selection<SVGTextElement, unknown, HTMLElement, unknown>;
  };
}

export class D3SvgApiaryHivesTemperatureFactory extends D3SvgBuilderLuxon<HiveTemperatureData[]> {
  // #region -> (properties)

  public axes: Axes = {
    container: null,
    time: {
      axis: null,
      container: null,
    },
    temperature: {
      axis: null,
      container: null,
    },
  };

  public scales: Scales = {
    time: null,
    temperature: null,
  };

  public data_objects: DataObjects = {
    container: null,
    per_hive: {},
  };

  public event_objects: AbstractEventObjects = {
    container: null,
  };

  public user_events: UserEvents = {
    container: null,
    event_bounds: null,

    focus_line_x: null,
    focus_line_y: null,

    time: {
      text: null,
      container: null,
    },

    temperature: {
      text: null,
      container: null,
    },

    per_hive: {},
  };

  public grids: AbstractD3Grid = {
    container: null,

    time: {
      axis: null,
      container: null,
    },

    day_cycle: new D3GridDayCycle(),
  };

  public labels: Labels = {
    container: null,
    labels: {
      temperature: null,
    },
  };

  // #endregion

  // #region -> (factory basics)

  /** */
  protected colors = {};

  /** */
  protected LOGGER: ConsoleLoggerService = new ConsoleLoggerService('D3SvgApiaryHivesTemperatureFactory', true);

  /** */
  constructor(protected _shared_cursor: D3SharedCursorService, protected _appState: AppStateService, protected _ngZone: NgZone) {
    super(_shared_cursor, _appState, _ngZone);

    this.margins.left = 45;
    this.margins.right = 20;
    this.margins.top = this.margins.top + 15;
  }

  /** */
  public destroy(): void {
    super.destroy();
  }

  // #endregion

  // #region -> (creation methods)

  protected create_scales(): void {
    super.create_scales();

    this.scales.temperature = d3.scaleLinear().rangeRound([this.calc_size_of(this.box_sizing?.height, ['-top', '-bottom']), 0]);
  }

  public create_axes(): void {
    super.create_axes();

    this.axes.temperature.axis = d3.axisLeft(this.scales.temperature);
    this.axes.temperature.container = this.axes.container
      .append('g')
      .attr('class', 'd3-Y-axis')
      .attr('transform', `translate(${this.calc_size_of(0, ['+left'])}, ${this.calc_size_of(0, ['+top'])})`)
      .call(this.axes.temperature.axis);

    // Call at least one time the format
    this.apply_axes_format();
  }

  protected create_labels(): void {
    super.create_labels();

    this.labels.labels.temperature = this.labels.container
      .append('text')
      .text(this._appState.translate.instant(i18n<string>('ALL.DATA.LABELS_SHORT_WITH_UNIT.Internal temperature (°C)')))
      .attr('transform', 'rotate(-90)')
      .attr('font-size', '10px')
      .attr('font-weight', 'bold')
      .attr('fill', 'black')
      .attr('text-anchor', 'middle')
      .attr('x', -(this.calc_size_of(this.box_sizing.height, ['-top', '-bottom']) / 2))
      .attr('y', -35);
  }

  public build_event_objects(): void {
    // Build Y-focus line
    this.user_events.focus_line_y = this.create_focus_line('y', this.user_events.container);
    this.user_events.focus_line_x = this.create_focus_line('x', this.user_events.container);

    // Build tooltips
    this.user_events.temperature.container = this.create_html_tooltip(this.svg_parent);
  }

  // #endregion

  // #region -> (update methods)

  public resize(): void {
    this.axes.temperature.axis.scale(this.scales.temperature);

    this.labels.labels.temperature.attr('x', -(this.calc_size_of(this.box_sizing.height, ['-top', '-bottom']) / 2));

    super.resize();
  }

  protected update_axes(): void {
    super.update_axes();

    this.axes.temperature.axis.scale(this.scales.temperature).ticks(5);
    this.axes.temperature.container
      .transition()
      .duration(500)
      .call(this.axes.temperature.axis)
      .attr('transform', `translate(${this.margins.left}, ${this.margins.top})`);
  }

  // #endregion

  /** */
  public cleanup_data(): void {
    // Cleanup hives data path
    Object.values(this.data_objects.per_hive).forEach(per_hive_objects => {
      per_hive_objects.not_defined_data_path.transition().duration(500).attr('d', '');
      per_hive_objects.defined_data_path.transition().duration(500).attr('d', '');
    });
  }

  /** */
  public append_data(is_after_resize: boolean): void {
    this.scales.time.domain([this.date_range.start, this.date_range.end]);
    this.cleanup_data();

    if (!this.has_data) {
      this.scales.temperature.domain([0, 20]);
      super.append_data(is_after_resize);
      return;
    }

    this.scales.temperature.domain([
      d3.min(this.incoming_data, (c: HiveTemperatureData) =>
        d3.min(
          c.values.filter((d: TemperatureDataPoint) => !isNil(d.temperature)),
          (d: TemperatureDataPoint) => d.temperature - 1
        )
      ),
      d3.max(this.incoming_data, (c: HiveTemperatureData) =>
        d3.max(
          c.values.filter((d: TemperatureDataPoint) => !isNil(d.temperature)),
          (d: TemperatureDataPoint) => d.temperature + 1
        )
      ),
    ]);

    this.incoming_data?.map(temperature_data_for_hive => {
      const hive_closest_identity = temperature_data_for_hive.hive_unique_id;
      const points: TemperatureDataPoint[] = temperature_data_for_hive.values;

      // Check existence of data object for current hive.
      if (isNil(this.data_objects.per_hive[hive_closest_identity])) {
        this.data_objects.per_hive[hive_closest_identity] = {
          shape: d3
            .line<TemperatureDataPoint>()
            .x(point => this.scales.time(point.tz_date))
            .y(point => this.scales.temperature(point.temperature))
            .defined((point: TemperatureDataPoint, index, self) => {
              if (isNil(point?.temperature)) {
                return false;
              }

              return true;
            })
            .curve(d3.curveLinear),

          defined_data_path: this.data_objects.container
            .append('path')
            .attr('fill', 'none')
            .attr('stroke-width', 2)
            .attr('stroke-linecap', 'round')
            .attr('stroke-linejoin', 'round')
            .attr('stroke', `${temperature_data_for_hive.hive_color}`),

          not_defined_data_path: this.data_objects.container
            .append('path')
            .attr('fill', 'none')
            .attr('stroke-width', 2)
            .attr('stroke-linecap', 'round')
            .attr('stroke-dasharray', '3,4')
            .attr('stroke-linejoin', 'round')
            .attr('stroke', `${temperature_data_for_hive.hive_color}`),
        };
      }

      // Create event circles for each hive
      if (isNil(this.user_events?.per_hive?.[hive_closest_identity]?.circle)) {
        this.user_events.per_hive[hive_closest_identity] = merge({}, this.user_events.per_hive[hive_closest_identity], {
          circle: this.create_d3_circle(this.user_events.container, temperature_data_for_hive.hive_color),
        });
      }

      // Update data of path
      this.data_objects.per_hive[hive_closest_identity].defined_data_path
        .transition()
        .duration(500)
        .attr('d', this.data_objects.per_hive[hive_closest_identity].shape(points));
      const filtered_points = points.filter(this.data_objects.per_hive[hive_closest_identity].shape.defined());
      this.data_objects.per_hive[hive_closest_identity].not_defined_data_path
        .transition()
        .duration(500)
        .attr('d', this.data_objects.per_hive[hive_closest_identity].shape(filtered_points));
    });

    super.append_data(is_after_resize);
  }

  // #region -> (user-events updates)

  public on_mouse_enter(is_from_shared_cursor = false): void {
    super.on_mouse_enter(is_from_shared_cursor);

    if (!this.has_data) {
      return null;
    }

    Object.entries(this.user_events.per_hive).forEach(([key, value]) => {
      value.circle.style('display', null);
    });

    this.user_events.temperature.container.style('display', null);
  }

  public on_mouse_move(event: MouseEvent | Date, data: HiveTemperatureData[], is_from_shared_cursor = false): void {
    if (!this.has_data) {
      return null;
    }

    let pointed_position_x: number = null;
    let pointed_position_y: number = null;

    if (is_from_shared_cursor) {
      pointed_position_x = this.scales.time(event as Date);
    } else {
      const source_element = (event as MouseEvent).target as SVGGElement;
      const boundings = source_element.getBoundingClientRect();

      pointed_position_x = (event as MouseEvent).clientX - boundings.left;
      pointed_position_y = (event as MouseEvent).clientY - boundings.top;
    }

    // Retrieve consistent data for each hive
    const closest_point_by_hive = data.reduce((final: HiveClosestTemperature[], hive_temperature_data) => {
      const temperature_points = hive_temperature_data.values;

      const index = d3.bisectCenter(
        temperature_points?.map(v => v.tz_date),
        this.scales.time.invert(pointed_position_x)
      );

      final.push({ closest_point: temperature_points?.[index] ?? null, ...hive_temperature_data });
      return final;
    }, []);

    if (!is_from_shared_cursor) {
      this._shared_cursor.shared_cursor = {
        date: closest_point_by_hive?.[0]?.closest_point?.tz_date ?? null,
        from: this.unique_id,
        event_type: 'mousemove',
      };
    }

    // Search for closest value on Y-axis
    const closest_index_on_y = !is_from_shared_cursor
      ? find_closest(
          this.scales.temperature.invert(pointed_position_y),
          closest_point_by_hive.map(c => c.closest_point.temperature)
        )
      : null;

    const closest_hive_point_on_y = !is_from_shared_cursor ? closest_point_by_hive?.[closest_index_on_y] : null;

    let template = this.create_tooltip_header(closest_point_by_hive?.[0]?.closest_point?.date);
    let closest_hive_point: HiveClosestTemperature = null;

    orderBy(closest_point_by_hive, v => v?.closest_point?.temperature ?? 0, ['desc']).forEach(hive_closest_point => {
      const is_closest = (closest_hive_point_on_y?.hive_unique_id ?? null) === hive_closest_point?.hive_unique_id ?? false;
      if (is_closest) {
        closest_hive_point = hive_closest_point;
      }

      template += `<div class="d3-chart-tooltip-list-item${is_closest ? ' closest-value' : ''}">`;

      template += '<div class="d3-chart-tooltip-series-name">';
      template += `<span class="mdi mdi-minus-thick" style="color: ${hive_closest_point.hive_color}"></span>`;
      template += `${hive_closest_point?.hive_name}`;
      template += '</div>';

      template += '<div class="d3-chart-tooltip-value">';
      template += `${hive_closest_point?.closest_point?.temperature?.toFixed(1) ?? '?'} °C`;
      template += '</div>';

      template += '</div>';
    });

    // Update tooltip position
    const is_in_first_half = pointed_position_x < this.user_events.event_bounds.node().width.baseVal.value / 2;
    if (is_in_first_half) {
      this.user_events.temperature.container
        .html(template)
        .style('left', `${this.calc_size_of(pointed_position_x + 10, ['+left'])}px`)
        .style('top', `${this.user_events.event_bounds.node().getBBox().height / 2}px`);
    } else {
      this.user_events.temperature.container
        .html(template)
        .style('left', `${pointed_position_x + this.margins.left - 1 - this.user_events.temperature.container.node().clientWidth - 10}px`)
        .style('top', `${this.user_events.event_bounds.node().getBBox().height / 2}px`);
    }

    // Update the focus_line_x position
    this.user_events.focus_line_y
      .attr('x1', this.scales.time(this.scales.time.invert(pointed_position_x)))
      .attr('x2', this.scales.time(this.scales.time.invert(pointed_position_x)));

    if (pointed_position_y) {
      this.user_events.focus_line_x
        .attr('y1', this.scales.temperature(this.scales.temperature.invert(pointed_position_y)))
        .attr('y2', this.scales.temperature(this.scales.temperature.invert(pointed_position_y)));
    }

    // Update circle positions
    Object.entries(this.user_events.per_hive).forEach(([key, value]) => {
      const closest_point = closest_hive_point?.closest_point;

      if (key !== closest_hive_point?.hive_unique_id) {
        value.circle.style('display', 'none');
        return;
      }

      if (isNil(closest_point?.tz_date) || isNil(closest_point?.temperature)) {
        value.circle.style('display', 'none');
        return;
      }

      value.circle.style('display', null);
      value.circle.attr(
        'transform',
        `translate(${this.scales.time(closest_point.tz_date.getTime())},${this.scales.temperature(closest_point.temperature)})`
      );
    });
  }

  public on_mouse_out(is_from_shared_cursor = false): void {
    super.on_mouse_out(is_from_shared_cursor);

    Object.entries(this.user_events.per_hive).forEach(([key, value]) => {
      value.circle.style('display', 'none');
    });

    this.user_events.temperature.container.style('display', 'none');
  }

  // #endregion

  // #region -> (external updates)

  protected on_language_updated(): void {
    super.on_language_updated();

    this.labels?.labels?.temperature
      ?.text(this._appState.translate.instant(i18n<string>('ALL.DATA.LABELS_SHORT_WITH_UNIT.Internal temperature (°C)')))
      ?.attr('x', -(this.calc_size_of(this.box_sizing.height, ['-top', '-bottom']) / 2));
  }

  // #endregion
}
