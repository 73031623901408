<div class="bg2-zoho-ticket-list-opened-component">
  <ng-container *ngIf="(has_tickets$$ | async) === false; else showOpenedTickets">
    <bg2-ui-message [type]="'success'" [title]="'MODELS.ZOHO.DESK.TICKET.Neither opnened nor on hold DESK tickets' | translate">
      <div message>
        <span>{{ 'MODELS.ZOHO.DESK.TICKET.There are currently no open tickets for this device' | translate }}</span>
      </div>
    </bg2-ui-message>
  </ng-container>

  <ng-template #showOpenedTickets>
    <ng-container *ngFor="let ticket of tickets$$ | async">
      <bg2-zoho-ticket-summary [ticket]="ticket"></bg2-zoho-ticket-summary>
    </ng-container>
  </ng-template>
</div>
