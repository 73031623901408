import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MiscModule } from 'app/misc/misc.module';
import { SharedModule } from 'app/shared-module/shared.module';
import { LocationsSharedModule } from '../shared/locations-shared.module';
import { DialogsModalsModule } from 'app/widgets/dialogs-modals/dialogs-modals.module';

import { LocationDetailsModalComponent } from './location-details-modal/location-details-modal.component';
import { MiscWidgetsModule } from 'app/widgets/misc-widgets/misc-widgets.module';

@NgModule({
  imports: [
    MiscModule,
    CommonModule,
    SharedModule,
    DialogsModalsModule,
    // RouterModule,
    // OwlDateTimeModule,
    // ReactiveFormsModule,
    // OwlNativeDateTimeModule,
    // SchemaFormModule.forRoot(),
    // HivesSharedModule,
    // MiscWidgetsModule,
    // ApiarySharedModule,
    // MaterialSharedModule,
    // EventsSharedModule,
    // EntitiesSharedModule,
    // WidgetsReusableModule,
    LocationsSharedModule,
  ],
  declarations: [LocationDetailsModalComponent],
})
export class LocationsModalsModule {}
