import { BehaviorSubject, map } from 'rxjs';
import { replay } from '@bg2app/tools/rxjs';

import { isEmpty, isNil } from 'lodash-es';

import { IZohoCRMTask } from '../interfaces';
import { ZohoBaseModel } from '../../../../abstract';

import { parseDate } from 'app/misc/tools';

/** */
export class ZohoCRMTask extends ZohoBaseModel<IZohoCRMTask> {
  // #region -> (model basics)

  constructor() {
    super();
  }

  // #endregion

  // #region -> (subject)

  /** */
  protected _subject$$ = new BehaviorSubject<string>(null);

  /** */
  public subject$$ = this._subject$$.asObservable();

  /** */
  public set Subject(Subject: string) {
    this._subject$$.next(Subject);
  }

  /** */
  public get Subject(): string {
    return this._subject$$.getValue();
  }

  // #endregion

  // #region -> (task state)

  /** */
  protected _status$$ = new BehaviorSubject<IZohoCRMTask['Status']>(null);

  /** */
  public status$$ = this._status$$.asObservable();

  /** */
  public set Status(Status: IZohoCRMTask['Status']) {
    this._status$$.next(Status);
  }

  /** */
  public get Status(): IZohoCRMTask['Status'] {
    return this._status$$.getValue();
  }

  /** */
  protected _priority$$ = new BehaviorSubject<IZohoCRMTask['Priority']>(null);

  /** */
  public priority$$ = this._priority$$.asObservable();

  /** */
  public set Priority(Priority: IZohoCRMTask['Priority']) {
    this._priority$$.next(Priority);
  }

  /** */
  public get Priority(): IZohoCRMTask['Priority'] {
    return this._priority$$.getValue();
  }

  // #endregion

  // #region -> (task time)

  /** */
  private _dueDate$$ = new BehaviorSubject<Date>(null);

  /** */
  public due_date$$ = this._dueDate$$.asObservable();

  /** */
  public set Due_Date(Due_Date: Date) {
    if (isNil(Due_Date)) {
      this._dueDate$$.next(Due_Date);
      return;
    }

    const date = parseDate(Due_Date);
    this._dueDate$$.next(date);
  }

  /** */
  public get Due_Date(): Date {
    return this._dueDate$$.getValue();
  }

  // #endregion

  /** */
  public url$$ = this.id$$.pipe(
    map(id => {
      if (isNil(id) || isEmpty(id)) {
        return null;
      }

      return `https://crm.zoho.eu/crm/org20067795631/tab/Tasks/${id}`;
    }),
    replay()
  );
}
