<div class="bg2-navigate-not-identified-hives-component">
  <div class="progress-status-container">
    <div class="progress-value" [style.width.%]="((current_index + 1) / total_hives) * 100"></div>
  </div>
  <div class="hive-navigator">

    <div class="navigate-icons">
      <ng-container *ngIf="current_index > 0">
        <span class="hive-previous mdi mdi-24px mdi-skip-previous-circle" (click)="previous_visit()"></span>
      </ng-container>
    </div>

    <div class="hive-counter">
      <span>{{ current_index + 1 }}</span>
      <span>/</span>
      <span>{{ total_hives }}</span>
    </div>

    <div class="navigate-icons">
      <ng-container *ngIf="current_index + 1 < total_hives">
        <span class="hive-next mdi mdi-24px mdi-skip-next-circle" (click)="next_visit()"></span>
      </ng-container>
    </div>
  </div>

</div>
