<div class="us-global-settings-component">
  <mat-card>
    <mat-card-header>
      <mat-card-title>{{ 'ROUTABLE_MODALS.USER_SETTINGS.CATEGORY.NOTIFICATIONS.Title' | translate }}</mat-card-title>
    </mat-card-header>

    <mat-card-content>
      <bg2-form-overlay
        [source_schema]="notifications_schema"
        [source_model]="notifications_model$$ | async"
        (when_form_data_update)="when_notifications_update($event)"
        (when_form_valid_update)="update_form_is_valid($event)"
      ></bg2-form-overlay>

      <mat-divider></mat-divider>
    </mat-card-content>

    <mat-card-footer>
      <bg2-button [type]="'cancel'" [message]="'ALL.ACTIONS.Cancel' | translate" [icon]="'mdi-cancel'"></bg2-button>

      <bg2-button
        [type]="'action'"
        [icon]="'mdi-content-save'"
        [message]="'ALL.ACTIONS.Save' | translate"
        [loading]="loading$$ | async"
        [disabled]="(form_is_valid$$ | async) === false || (loading$$ | async)"
        (btn_click)="save_notification_params()"
      ></bg2-button>
    </mat-card-footer>
  </mat-card>

  <mat-card>
    <mat-card-header>
      <mat-card-title>{{ 'ROUTABLE_MODALS.USER_SETTINGS.CATEGORY.NOTIFICATIONS.NOTIFIED_DEVICES.Title' | translate }}</mat-card-title>
    </mat-card-header>

    <mat-card-content>
      <bg2-notified-devices-datatable [user]="user$$ | async"></bg2-notified-devices-datatable>
    </mat-card-content>
  </mat-card>
</div>
