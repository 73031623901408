import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';

import { cloneDeep, findIndex, indexOf, isNil, keys } from 'lodash-es';

import { ArrayProperty } from 'ngx-schema-form/lib/model/arrayproperty';

import { BehaviorSubject, Observable, of, Subject, concat, combineLatest } from 'rxjs';
import { distinctUntilRealChanged, replay } from '@bg2app/tools/rxjs';
import { map, filter, switchMap, tap } from 'rxjs';

import { startOfYear } from 'date-fns';

import { Beeguard2Api } from 'app/core';
import { ConsoleLoggerService } from 'app/core/console-logger.service';

import { EfArrayWidget } from '../array/array.widget';

import { Event, Entity } from 'app/models';
import { Dictionary } from 'app/typings/core/interfaces';
import { FormProperty } from 'ngx-schema-form';

class EventItem {
  formProperty: ArrayProperty;
  event: Event;
  disabled = false; // shown only of false

  protected _selected?: boolean = false;
  get selected() {
    return this._selected;
  }

  set selected(value) {
    this._selected = value;
    this.update();
  }

  constructor() {}

  update() {
    let val = this.formProperty.value;
    const old_val = cloneDeep(val);
    const idx = indexOf(val, this.event.id);

    if (this.selected && !(idx > -1)) {
      val = [...val, this.event.id];
    }
    if (!this.selected && idx > -1) {
      val.splice(idx, 1);
    }
    if (old_val !== val) {
      this.formProperty.setValue(val, false);
    }
  }
}

class EventsGroup {
  events: EventItem[];

  protected _entity_id$ = new Subject<number>();
  public entity_id$ = this._entity_id$.asObservable();
  public entity_id$$ = this.entity_id$.pipe(replay());

  protected _entity_id: number;
  get entity_id() {
    return this._entity_id;
  }
  set entity_id(value) {
    console.log(value);
    this._entity_id = value;
    this._entity_id$.next(value);
  }

  entity$$ = this.entity_id$$.pipe(
    filter(entity_id => !isNil(entity_id)),
    switchMap(entity_id => this.bg2Api.getEntityObj(entity_id)),
    replay()
  );

  constructor(private bg2Api: Beeguard2Api) {
    this.entity_id$$.subscribe();
  }
}

@Component({
  selector: 'bg2-bg2superbox-harvest-selector',
  templateUrl: './bg2superbox-harvest-selector.widget.html',
  styleUrls: ['./bg2superbox-harvest-selector.widget.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EfBg2SuperboxHarvestSelectorWidgetComponent extends EfArrayWidget implements OnInit {
  public options: any;

  private event_items: any[] = [];
  private _initial_value: any[] = [];

  // #region -> (component basics)

  private readonly DATE_NOW = new Date();
  private readonly _logger = new ConsoleLoggerService('EfBg2SuperboxHarvestSelectorWidget', true);

  constructor(private _bg2Api: Beeguard2Api) {
    super(_bg2Api);
  }

  ngOnInit(): void {
    this._initial_value = this.formProperty.value;
    this.options = this.schema.options;

    this._options_source_entity$$.next(this.options?.source_entity);
    this._options_event_filters$$.next(this.options?.event_filters);
  }

  // #endregion

  // #region -> (data management)

  private _options_source_entity$$ = new BehaviorSubject<{ path: string; field: string }>(null);
  private options_source_entity$$ = this._options_source_entity$$.asObservable().pipe(distinctUntilRealChanged(), replay());

  private source_entity_property$$ = this.options_source_entity$$.pipe(
    filter(options_source_entity => !isNil(options_source_entity?.path)),
    map(options_source_entity => this.robustSearchProperty(options_source_entity?.path)),
    replay()
  );

  private _options_event_filters$$ = new BehaviorSubject<{ path: string; field: string }[]>(null);
  private options_event_filters$$ = this._options_event_filters$$.asObservable().pipe(distinctUntilRealChanged(), replay());

  private event_filters_properties$$ = this.options_event_filters$$.pipe(
    map(options_event_filters =>
      options_event_filters.reduce((previous: { path: string; field: string; prop: FormProperty }[], options_event_filter) => {
        if (!isNil(options_event_filter?.path)) {
          previous.push({
            path: options_event_filter.path,
            field: options_event_filter.field,
            prop: this.robustSearchProperty(options_event_filter.path),
          });
        }

        return previous;
      }, [])
    ),
    replay()
  );

  private filters_to_apply$$ = this.event_filters_properties$$.pipe(
    switchMap(filter_props =>
      combineLatest(
        filter_props.map(filter_prop =>
          concat(of(filter_prop.prop.value), filter_prop.prop.valueChanges).pipe(
            map(value => {
              const filters: Dictionary<any> = {};
              filters[filter_prop.field] = value || null;
              return filters;
            })
          )
        )
      )
    ),
    distinctUntilRealChanged(),
    replay()
  );

  /**
   * Observable on the source entity ID.
   */
  private source_entity_id$$: Observable<number> = this.source_entity_property$$.pipe(
    filter(source_entity_property => !isNil(source_entity_property)),
    switchMap(source_entity_property => concat(of(source_entity_property?.value), source_entity_property?.valueChanges)),
    distinctUntilRealChanged(),
    replay()
  );

  /**
   * Observable on the source entity.
   */
  public source_entity$$: Observable<Entity> = this.source_entity_id$$.pipe(
    filter(source_entity_id => !isNil(source_entity_id)),
    switchMap(source_entity_id => this._bg2Api.getEntityObj(source_entity_id)),
    replay()
  );

  /**
   * Observable on the source entity events.
   */
  private source_entity_events$$ = this.source_entity$$.pipe(
    switchMap(source_entity => {
      const request_config: Dictionary<any> = {
        types: [this.options.event_type],
        limit: -1,
      };

      if (this.options?.source_entity?.from_date === 'start_of_year') {
        request_config.start = startOfYear(this.DATE_NOW);
      }

      return source_entity.requestEvents(request_config);
    }),
    map(events => events.events),
    map(events =>
      events.map((event: Event) => {
        const event_item = new EventItem();

        event_item.event = event;
        event_item.formProperty = this.formProperty;

        return event_item;
      })
    ),
    map(events =>
      events.map(event_item => {
        if (indexOf(this._initial_value, event_item.event.id) > -1) {
          event_item.selected = true;
        }

        return event_item;
      })
    ),
    switchMap(events =>
      this.filters_to_apply$$.pipe(
        map(filters_to_apply => {
          let filtered_events = events;

          filters_to_apply.forEach(fta => (filtered_events = this._filterEvents(fta, filtered_events)));

          return filtered_events;
        })
      )
    ),
    replay()
  );

  /**
   * Observable of harvest events grouped by location.
   */
  public events_grouped_by_location$$ = this.source_entity_events$$.pipe(
    map(source_entity_events => {
      const event_groups: EventsGroup[] = [];

      source_entity_events.forEach(src_entity_event => {
        const element = src_entity_event.event.get('apply_to.location');
        let idx = findIndex(event_groups, { entity_id: element });

        if (!(idx > -1)) {
          const egroup = new EventsGroup(this._bg2Api);
          egroup.entity_id = element;
          egroup.events = [];
          event_groups.push(egroup);
          idx = event_groups.length - 1;
        }

        if (!(indexOf(event_groups[idx].events, src_entity_event) > -1)) {
          event_groups[idx].events.push(src_entity_event);
        }
      });

      return event_groups;
    }),
    replay()
  );

  private _filterEvents(filters: Dictionary<any>, events: EventItem[]): EventItem[] {
    return events.map(event_item => {
      let is_disabled = false;

      keys(filters).map(field => {
        const filter_value = filters[field];
        const event_value = event_item.event.get(field);
        let should_keep_event = true;

        if (!isNil(filter_value)) {
          should_keep_event = event_item.event.get(field) === filter_value;
        }

        is_disabled = is_disabled || !should_keep_event;
      });

      event_item.disabled = is_disabled;

      if (event_item.selected && is_disabled) {
        event_item.selected = false;
      }

      return event_item;
    });
  }

  // #endregion
}
