<div *ngLet="location$$ | async; let location" class="stackable-cards" #stackableCardGroup>
  <bg2-abstract-carousel-group-card>
    <div role="stackable-card-header">
      <div class="card-title">
        {{ 'ALL.BREEDING_REGISTER.Season [year]' | translate: { year: current_year$$ | async } }}
      </div>
      <div class="card-date">
        [{{ start_date$$ | async | formatDate: 'MMMM yyyy' }} &#8594; {{ end_date$$ | async | formatDate: 'MMMM yyyy' }}]
      </div>
    </div>

    <div role="stackable-card-content">
      <div *ngLet="location?.user_acl?.can$$('read_all_events') | async; let can_read_all_events" class="stats-container">
        <div class="stat-container">
          <span class="label">{{ 'VIEWS.LOCATIONS.SHARED.WIDGETS.LOCATION_YEARLY_STATS.Nb occupied days' | translate }}</span>

          <div class="stat-container__value">
            <div class="stat-container__value__actual">
              <span
                class="mdi {{ calc_trend_icon$$(total_of_occupied_days_current_year$$, total_of_occupied_days_previous_year$$) | async }}"
              ></span>

              <span>{{ total_of_occupied_days_current_year$$ | async }} {{ 'ALL.DATE.LABELS_SHORT.Days' | translate }}</span>
            </div>

            <div class="stat-container__value__previous">
              <span>{{ (previous_year$$ | async)?.toString() | colon }}</span>
              <span>{{ total_of_occupied_days_previous_year$$ | async }} {{ 'ALL.DATE.LABELS_SHORT.Days' | translate }}</span>
            </div>
          </div>
        </div>

        <div class="stat-container">
          <span class="label">{{ 'VIEWS.LOCATIONS.SHARED.WIDGETS.LOCATION_YEARLY_STATS.Nb harvests' | translate }}</span>

          <div class="stat-container__value">
            <ng-container *ngIf="can_read_all_events">
              <div class="stat-container__value__actual">
                <span
                  class="mdi {{
                    calc_trend_icon$$(superbox_harvests?.for_current_year?.total$$, superbox_harvests?.for_previous_year?.total$$) | async
                  }}"
                ></span>

                <span>{{ superbox_harvests?.for_current_year?.total$$ | async }} {{ 'EVENT.VISIT.Supers' | translate }}</span>
              </div>

              <div class="stat-container__value__previous">
                <span>{{ (previous_year$$ | async)?.toString() | colon }}</span>
                <span>{{ superbox_harvests?.for_previous_year?.total$$ | async }} {{ 'EVENT.VISIT.Supers' | translate }}</span>
              </div>
            </ng-container>

            <bg2-acl-not-accessible
              *ngIf="!can_read_all_events"
              [popper_config]="{
                ace: 'read_all_events',
                entity_type: 'location',
                what: 'ALL.ACE.READ_ALL_EVENTS.WHAT.the total of superbox harvests this year' | translate
              }"
            ></bg2-acl-not-accessible>
          </div>
        </div>

        <div class="stat-container">
          <span class="label">{{ 'VIEWS.LOCATIONS.SHARED.WIDGETS.LOCATION_YEARLY_STATS.Mean of supers by harvest' | translate }}</span>

          <div class="stat-container__value">
            <ng-container *ngIf="can_read_all_events">
              <div class="stat-container__value__actual">
                <span
                  class="mdi {{
                    calc_trend_icon$$(
                      superbox_harvests?.for_current_year?.mean_super_harvest$$,
                      superbox_harvests?.for_previous_year?.mean_super_harvest$$
                    ) | async
                  }}"
                ></span>

                <span>{{ superbox_harvests?.for_current_year?.mean_super_harvest$$ | async }} {{ 'EVENT.VISIT.Supers' | translate }}</span>
              </div>

              <div class="stat-container__value__previous">
                <span>{{ (previous_year$$ | async)?.toString() | colon }}</span>
                <span>{{ superbox_harvests?.for_previous_year?.mean_super_harvest$$ | async }} {{ 'EVENT.VISIT.Supers' | translate }}</span>
              </div>
            </ng-container>

            <bg2-acl-not-accessible
              *ngIf="!can_read_all_events"
              [popper_config]="{
                ace: 'read_all_events',
                entity_type: 'location',
                what: 'ALL.ACE.READ_ALL_EVENTS.WHAT.the average of superbox per harvest this year' | translate
              }"
            ></bg2-acl-not-accessible>
          </div>
        </div>
      </div>
    </div>
  </bg2-abstract-carousel-group-card>
</div>
