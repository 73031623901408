import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpStatusCode } from '@angular/common/http';

import { captureMessage } from '@sentry/angular-ivy';

import { Observable, catchError, retry, throwError, timer } from 'rxjs';
import { ZohoAuthService } from './services/zoho/zoho-auth.service';
import { ZohoDeskApiService } from './services/zoho/zoho-desk-api.service';
import { ZohoCRMApiService } from './services/zoho/zoho-crm-api.service';
import { isNil } from 'lodash-es';
import { get_i18n_for_zoho_error, ZohoError } from 'app/models/zoho';
import { environment } from 'environments/environment';
import { BeeguardAuthService } from '.';
import { ConsoleLoggerService } from './console-logger.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  // #region - interceptor basics

  private readonly LOGGER = new ConsoleLoggerService('HttpInterceptor', false);

  constructor(
    private readonly _router: Router,
    private readonly _zohoAuthService: ZohoAuthService,
    private readonly _beeguardAuthService: BeeguardAuthService,
  ) {
    this._zohoAuthService.access_token$$.subscribe({
      next: accessToken => {
        this.LOGGER.debug(`Zoho Access Token is available with value: ${accessToken}`);
        this._zohoAuthToken = accessToken;
      },
    });

    this._beeguardAuthService.access_token$$.subscribe({
      next: accessToken => {
        this.LOGGER.debug(`BeeGuard Access Token is available with value: ${accessToken}`);
        this._bg2ApiAuthToken = accessToken;
      },
    });
  }

  // #endregion

  // #region - tokens

  /** */
  private _zohoAuthToken: string = null;

  /** */
  private _bg2ApiAuthToken: string = null;

  // #endregion

  intercept(httpRequest: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const requestURL = httpRequest.url;

    if (requestURL.indexOf(ZohoDeskApiService.API_BASE) >= 0 || requestURL.indexOf(ZohoCRMApiService.API_BASE) >= 0) {
      // Special check for Zoho calls
      if (isNil(this._zohoAuthToken)) {
        return throwError(() => new Error(get_i18n_for_zoho_error(ZohoError.NOT_AUTHENTICATED)));
      }

      httpRequest = httpRequest.clone({
        headers: httpRequest.headers.set('Authorization', `Zoho-oauthtoken ${this._zohoAuthToken}`),
      });
    } else if (requestURL.indexOf(environment.Beeguard2ApiUrl) >= 0) {
      // Add token to bg2-api calls
      httpRequest = httpRequest.clone({
        headers: httpRequest.headers.set('Authorization', `Bearer ${this._bg2ApiAuthToken}`),
      });
    }

    return next.handle(httpRequest).pipe(
      // retry({
      //   count: 3,
      //   delay: (err, retryCount) => {
      //     switch (err.status) {
      //       case 0: // connection unstable / cors
      //       case HttpStatusCode.BadGateway:
      //       case HttpStatusCode.RequestTimeout:
      //       case HttpStatusCode.GatewayTimeout:
      //       case HttpStatusCode.ServiceUnavailable: {
      //         this.LOGGER.info(`Retry #${retryCount} of request ${httpRequest?.urlWithParams}`);
      //         return timer(1000 * (retryCount * retryCount));
      //       }

      //       default: {
      //         this.LOGGER.info(`Unhandled retry of request ${httpRequest?.urlWithParams}`);
      //         return throwError(() => err);
      //       }
      //     }
      //   },
      // }),
      catchError((error_response: unknown) => {
        if (error_response instanceof HttpErrorResponse) {
          if (error_response.status === HttpStatusCode.Unauthorized && error_response?.error?.error === 'invalid_token') {
            this._router.navigate(['', { outlets: { primary: 'login', modal: ['raz'] } }], {
              replaceUrl: true,
              queryParams: { logType: 'invalid-access-token' },
            });
          }

          captureMessage(`[${error_response?.status}/${error_response?.statusText}] ${error_response.message}`, 'error');
        }

        return throwError(() => error_response);
      }),
    );
  }
}
