<div class="menu-impersonate">
  <mat-form-field [appearance]="'outline'">
    <mat-label>{{ "ALL.MENU.Impersonate" | translate }}</mat-label>
    <bg2-user-select
      [value]="this.oAuthService.impersonate_id$$ | async"
      (valueChange)="this.oAuthService.set_impersonated_user_id($event)"
      [options]="{
        no_impersonate: true
      }"
    >
    </bg2-user-select>
  </mat-form-field>
</div>
