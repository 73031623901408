import { ActivatedRoute, Router } from '@angular/router';
import {
  OnInit,
  Component,
  OnDestroy,
  ViewChild,
  HostListener,
  AfterViewChecked,
  ViewContainerRef,
  ChangeDetectionStrategy,
} from '@angular/core';
import { MatSidenav, MatSidenavContainer } from '@angular/material/sidenav';

import { Observable, Subscription, combineLatest, debounceTime, filter, map, switchMap, take } from 'rxjs';

import { TranslateService } from '@ngx-translate/core';


import { RunnersService } from './core/runners';
import { SidenavService } from './core/sidenav.service';
import { AppStateService } from './core/app-state.service';
import { BeeguardAuthService } from './core';
import { ConsoleLoggerService } from './core/console-logger.service';
import { BetaAppService } from './core/services/global/beta-app.service';

import { environment } from '../environments/environment';
import { AppBackofficeService } from './core/services/global/app-backoffice.service';
import { get, isNil } from 'lodash-es';
import { replay } from './misc/tools';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements OnInit, AfterViewChecked, OnDestroy {
  private readonly LOGGER = new ConsoleLoggerService(this.constructor.name, false);

  @ViewChild('toolsSidenav') tools_sidenav: MatSidenav;
  @ViewChild('navigationSidenav') navigation_sidenav: MatSidenav;
  @ViewChild('toolsSidenavVC', { static: true, read: ViewContainerRef }) tools_sidenav_vc: any;

  @ViewChild('matSidenavContainer', { static: true })
  public mat_sidenav_container: MatSidenavContainer;

  public screen_size = window.innerWidth;
  public version = environment.version;
  public error = '';

  public get logo(): string {
    if (environment.env === 'apismart') {
      return 'assets/logo_apismart.png';
    }

    if (environment.env === 'prod') {
      return 'assets/logo.svg';
    }

    return `assets/logo_${environment.env}.svg`;
  }

  @HostListener('window:resize')
  onResize(): void {
    this.screen_size = window.innerWidth;
  }

  private _current_user_sub: Subscription = null;
  private _router_events_sub: Subscription = null;
  private _oauth_authorize_sub: Subscription = null;

  constructor(
    public betaAppService: BetaAppService,
    public appState: AppStateService,
    public runnersService: RunnersService,
    public sidenav: SidenavService<any, any>,
    public oAuthService: BeeguardAuthService,
    public translateService: TranslateService,
    public appBackofficeService: AppBackofficeService,

    protected route: ActivatedRoute,

    private router: Router
  ) {}

  ngOnInit(): void {
    this._oauth_authorize_sub = this.oAuthService.user_id$$.pipe(debounceTime(100)).subscribe({
      next: () => {
        this.betaAppService.initialize();
        this.appState.loadExploitations();
      },
    });
  }

  ngAfterViewChecked(): void {
    // Configure sidenav container
    this.sidenav.setContainers(this.navigation_sidenav, this.tools_sidenav, this.tools_sidenav_vc);
  }

  ngOnDestroy(): void {
    this._current_user_sub?.unsubscribe();
    this._router_events_sub?.unsubscribe();
    this._oauth_authorize_sub?.unsubscribe();
  }

  public isLinkActive(url: string): boolean {
    const query_params_index = this.router.url.indexOf('?');
    const base_url = query_params_index === -1 ? this.router.url : this.router.url.slice(0, query_params_index);

    if (url.length > 1) {
      // if url != '/'
      return base_url.startsWith(url);
    }

    return base_url === url;
  }

  // TODO: Checks if it works with OnPush

  /**
   * Scrolls on the top of the page when new page is open,
   * we handle this ourself for now in order to NOT scroll
   * up when a modal is open.
   */
  public activateMainOutlet(): void {
    this.mat_sidenav_container.scrollable.scrollTo({ top: 0 });
  }

  public get is_runs_displayed(): boolean {
    return document.body.contains(document.getElementById('run-snackbar'));
  }

  // #region - top layer announcement

  /** */
  public top_layer_announcement_config$$ = this.appBackofficeService.top_layer_announcement_config$$;

  /** */
  public top_layer_announcement_message$$: Observable<String> = combineLatest({
    language: this.appState.lang$$,
    config: this.top_layer_announcement_config$$,
  }).pipe(
    map(({ language, config }) => {
      if (!config.enabled || isNil(language)) {
        return null;
      }

      return get(config, `message.${language}`) ?? config?.message['en'];
    }),
    replay()
  );

  // #endregion
}
