<ng-container *ngIf="{ has_error: has_error$$ | async, schema: schema$$ | async } as ctx">
  <ng-container *ngIf="!ctx?.has_error; else error">
    <svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 105.83 39.688">
      <!-- #region SVG definitions -->
      <defs xmlns="http://www.w3.org/2000/svg">
        <marker
          id="Arrow2Mend"
          overflow="visible"
          markerHeight="5.1962256"
          markerWidth="6.9304585"
          orient="auto"
          preserveAspectRatio="xMidYMid"
          viewBox="0 0 6.9304588 5.1962256"
        >
          <path
            transform="scale(-.6)"
            d="m8.7186 4.0337-10.926-4.0177 10.926-4.0177c-1.7455 2.3721-1.7354 5.6175-6e-7 8.0354z"
            fill-rule="evenodd"
            stroke="#000"
            stroke-linejoin="round"
            stroke-width=".625"
          />
        </marker>
        <marker id="marker3227" overflow="visible" orient="auto">
          <path
            transform="scale(.6)"
            d="m8.7186 4.0337-10.926-4.0177 10.926-4.0177c-1.7455 2.3721-1.7354 5.6175-6e-7 8.0354z"
            fill-rule="evenodd"
            stroke="#000"
            stroke-linejoin="round"
            stroke-width=".625"
          />
        </marker>
        <marker id="Arrow2Mstart" overflow="visible" orient="auto">
          <path
            transform="scale(.6)"
            d="m8.7186 4.0337-10.926-4.0177 10.926-4.0177c-1.7455 2.3721-1.7354 5.6175-6e-7 8.0354z"
            fill-rule="evenodd"
            stroke="#000"
            stroke-linejoin="round"
            stroke-width=".625"
          />
        </marker>
        <marker id="marker5490" overflow="visible" orient="auto">
          <path
            transform="matrix(-1.1,0,0,-1.1,-1.1,0)"
            d="m8.7186 4.0337-10.926-4.0177 10.926-4.0177c-1.7455 2.3721-1.7354 5.6175-6e-7 8.0354z"
            fill-rule="evenodd"
            stroke="#000"
            stroke-linejoin="round"
            stroke-width=".625"
          />
        </marker>
        <marker id="Arrow2Lend" overflow="visible" orient="auto">
          <path
            transform="matrix(-1.1,0,0,-1.1,-1.1,0)"
            d="m8.7186 4.0337-10.926-4.0177 10.926-4.0177c-1.7455 2.3721-1.7354 5.6175-6e-7 8.0354z"
            fill-rule="evenodd"
            stroke="#000"
            stroke-linejoin="round"
            stroke-width=".625"
          />
        </marker>
        <clipPath id="bQ-6" clip-path="url(#bP)">
          <path
            width="100%"
            height="100%"
            d="m817.28 49.743c-2.668 1.805-3.367 5.432-1.562 8.098 1.805 2.669 5.432 3.367 8.1 1.562s3.367-5.432 1.561-8.099c-1.127-1.666-2.965-2.564-4.834-2.563-1.126 0-2.263 0.324-3.265 1.002"
          />
        </clipPath>
        <clipPath id="bR-8"><path width="100%" height="100%" d="m814.8 48.623h11.52v11.76h-11.52z" /></clipPath>
        <clipPath id="bT-3" clip-path="url(#bS)"><path width="100%" height="100%" d="m816.68 50.781 1.922-1.301-4.952-5.302z" /></clipPath>
        <clipPath id="bU-3"><path width="100%" height="100%" d="m813.6 44.062h5.041v6.72h-5.041z" /></clipPath>
        <clipPath id="bW-1" clip-path="url(#bV)"><path width="100%" height="100%" d="m822.67 59.64 4.949 5.301-3.029-6.601z" /></clipPath>
        <clipPath id="bX-3"><path width="100%" height="100%" d="m822.48 58.222h5.279v6.72h-5.279z" /></clipPath>
        <clipPath id="bZ-0" clip-path="url(#bY)"><path width="100%" height="100%" d="m810.25 61.547 6.6-3.03-1.299-1.919z" /></clipPath>
        <clipPath id="b0-4"><path width="100%" height="100%" d="m810.24 56.542h6.721v5.04h-6.721z" /></clipPath>
        <clipPath id="b2-4" clip-path="url(#b1)"><path width="100%" height="100%" d="m824.41 50.602 1.301 1.92 5.301-4.948z" /></clipPath>
        <clipPath id="b3-5"><path width="100%" height="100%" d="m824.4 47.422h6.721v5.28h-6.721z" /></clipPath>
        <clipPath id="b5-8" clip-path="url(#b4)"><path width="100%" height="100%" d="m820.51 49.09 2.275 0.438 0.25-7.249z" /></clipPath>
        <clipPath id="b6-9"><path width="100%" height="100%" d="m820.32 42.142h2.879v7.44h-2.879z" /></clipPath>
        <clipPath id="b8-4" clip-path="url(#b7)"><path width="100%" height="100%" d="m818.23 66.841 2.526-6.81-2.276-0.439z" /></clipPath>
        <clipPath id="b9-7"><path width="100%" height="100%" d="m818.16 59.422h2.641v7.44h-2.641z" /></clipPath>
        <clipPath id="cb-1" clip-path="url(#ca)"><path width="100%" height="100%" d="m815.16 54.686 0.44-2.277-7.248-0.249z" /></clipPath>
        <clipPath id="cc-6"><path width="100%" height="100%" d="m808.32 51.982h7.439v2.88h-7.439z" /></clipPath>
        <clipPath id="ce-2" clip-path="url(#cd)"><path width="100%" height="100%" d="m825.67 56.713 7.248 0.247-6.81-2.526z" /></clipPath>
        <clipPath id="cf-2"><path width="100%" height="100%" d="m825.6 54.382h7.441v2.64h-7.441z" /></clipPath>
        <clipPath id="ch-3" clip-path="url(#cg)"><path width="100%" height="100%" d="m818.4 49.567 2.267-0.48-2.584-6.778z" /></clipPath>
        <clipPath id="ci-8"><path width="100%" height="100%" d="m817.92 42.142h2.881v7.44h-2.881z" /></clipPath>
        <clipPath id="ck-7" clip-path="url(#cj)"><path width="100%" height="100%" d="m820.6 60.033 2.584 6.778-0.316-7.258z" /></clipPath>
        <clipPath id="cl-9"><path width="100%" height="100%" d="m820.56 59.422h2.639v7.44h-2.639z" /></clipPath>
        <clipPath id="cn-7" clip-path="url(#cm)"><path width="100%" height="100%" d="m808.38 57.113 7.256-0.312-0.481-2.269z" /></clipPath>
        <clipPath id="co-2"><path width="100%" height="100%" d="m808.32 54.382h7.439v2.88h-7.439z" /></clipPath>
        <clipPath id="cq-4" clip-path="url(#cp)"><path width="100%" height="100%" d="m825.63 52.323 0.478 2.267 6.778-2.586z" /></clipPath>
        <clipPath id="cr-0"><path width="100%" height="100%" d="m825.6 51.982h7.441v2.64h-7.441z" /></clipPath>
        <clipPath id="ct-8" clip-path="url(#cs)"><path width="100%" height="100%" d="m822.58 49.445 1.943 1.266 2.965-6.621z" /></clipPath>
        <clipPath id="cu-3"><path width="100%" height="100%" d="m822.48 44.062h5.039v6.72h-5.039z" /></clipPath>
        <clipPath id="cw-0" clip-path="url(#cv)"><path width="100%" height="100%" d="m813.78 65.03 4.911-5.356-1.944-1.265z" /></clipPath>
        <clipPath id="cx-7"><path width="100%" height="100%" d="m813.6 58.222h5.281v6.96h-5.281z" /></clipPath>
        <clipPath id="cz-5" clip-path="url(#cy)"><path width="100%" height="100%" d="m815.52 52.613 1.265-1.944-6.621-2.963z" /></clipPath>
        <clipPath id="cA-3"><path width="100%" height="100%" d="m810 47.662h6.961v5.04h-6.961z" /></clipPath>
        <clipPath id="cC-7" clip-path="url(#cB)"><path width="100%" height="100%" d="m824.48 58.452 6.62 2.964-5.354-4.909z" /></clipPath>
        <clipPath id="cD-8"><path width="100%" height="100%" d="m824.4 56.302h6.721v5.28h-6.721z" /></clipPath>
        <filter id="ap-1" x="-.024571" y="-.023012" width="1.0491" height="1.046" color-interpolation-filters="sRGB">
          <feBlend in2="BackgroundImage" mode="lighten" />
        </filter>
        <clipPath id="bQ-6-0" clip-path="url(#bP)">
          <path
            width="100%"
            height="100%"
            d="m817.28 49.743c-2.668 1.805-3.367 5.432-1.562 8.098 1.805 2.669 5.432 3.367 8.1 1.562s3.367-5.432 1.561-8.099c-1.127-1.666-2.965-2.564-4.834-2.563-1.126 0-2.263 0.324-3.265 1.002"
          />
        </clipPath>
        <clipPath id="bR-8-3"><path width="100%" height="100%" d="m814.8 48.623h11.52v11.76h-11.52z" /></clipPath>
        <clipPath id="bT-3-8" clip-path="url(#bS)">
          <path width="100%" height="100%" d="m816.68 50.781 1.922-1.301-4.952-5.302z" />
        </clipPath>
        <clipPath id="bU-3-4"><path width="100%" height="100%" d="m813.6 44.062h5.041v6.72h-5.041z" /></clipPath>
        <clipPath id="bW-1-8" clip-path="url(#bV)"><path width="100%" height="100%" d="m822.67 59.64 4.949 5.301-3.029-6.601z" /></clipPath>
        <clipPath id="bX-3-8"><path width="100%" height="100%" d="m822.48 58.222h5.279v6.72h-5.279z" /></clipPath>
        <clipPath id="bZ-0-3" clip-path="url(#bY)"><path width="100%" height="100%" d="m810.25 61.547 6.6-3.03-1.299-1.919z" /></clipPath>
        <clipPath id="b0-4-7"><path width="100%" height="100%" d="m810.24 56.542h6.721v5.04h-6.721z" /></clipPath>
        <clipPath id="b2-4-8" clip-path="url(#b1)"><path width="100%" height="100%" d="m824.41 50.602 1.301 1.92 5.301-4.948z" /></clipPath>
        <clipPath id="b3-5-3"><path width="100%" height="100%" d="m824.4 47.422h6.721v5.28h-6.721z" /></clipPath>
        <clipPath id="b5-8-6" clip-path="url(#b4)"><path width="100%" height="100%" d="m820.51 49.09 2.275 0.438 0.25-7.249z" /></clipPath>
        <clipPath id="b6-9-2"><path width="100%" height="100%" d="m820.32 42.142h2.879v7.44h-2.879z" /></clipPath>
        <clipPath id="b8-4-8" clip-path="url(#b7)"><path width="100%" height="100%" d="m818.23 66.841 2.526-6.81-2.276-0.439z" /></clipPath>
        <clipPath id="b9-7-7"><path width="100%" height="100%" d="m818.16 59.422h2.641v7.44h-2.641z" /></clipPath>
        <clipPath id="cb-1-3" clip-path="url(#ca)"><path width="100%" height="100%" d="m815.16 54.686 0.44-2.277-7.248-0.249z" /></clipPath>
        <clipPath id="cc-6-9"><path width="100%" height="100%" d="m808.32 51.982h7.439v2.88h-7.439z" /></clipPath>
        <clipPath id="ce-2-0" clip-path="url(#cd)"><path width="100%" height="100%" d="m825.67 56.713 7.248 0.247-6.81-2.526z" /></clipPath>
        <clipPath id="cf-2-2"><path width="100%" height="100%" d="m825.6 54.382h7.441v2.64h-7.441z" /></clipPath>
        <clipPath id="ch-3-5" clip-path="url(#cg)"><path width="100%" height="100%" d="m818.4 49.567 2.267-0.48-2.584-6.778z" /></clipPath>
        <clipPath id="ci-8-7"><path width="100%" height="100%" d="m817.92 42.142h2.881v7.44h-2.881z" /></clipPath>
        <clipPath id="ck-7-3" clip-path="url(#cj)"><path width="100%" height="100%" d="m820.6 60.033 2.584 6.778-0.316-7.258z" /></clipPath>
        <clipPath id="cl-9-4"><path width="100%" height="100%" d="m820.56 59.422h2.639v7.44h-2.639z" /></clipPath>
        <clipPath id="cn-7-3" clip-path="url(#cm)">
          <path width="100%" height="100%" d="m808.38 57.113 7.256-0.312-0.481-2.269z" />
        </clipPath>
        <clipPath id="co-2-0"><path width="100%" height="100%" d="m808.32 54.382h7.439v2.88h-7.439z" /></clipPath>
        <clipPath id="cq-4-3" clip-path="url(#cp)">
          <path width="100%" height="100%" d="m825.63 52.323 0.478 2.267 6.778-2.586z" />
        </clipPath>
        <clipPath id="cr-0-7"><path width="100%" height="100%" d="m825.6 51.982h7.441v2.64h-7.441z" /></clipPath>
        <clipPath id="ct-8-0" clip-path="url(#cs)">
          <path width="100%" height="100%" d="m822.58 49.445 1.943 1.266 2.965-6.621z" />
        </clipPath>
        <clipPath id="cu-3-7"><path width="100%" height="100%" d="m822.48 44.062h5.039v6.72h-5.039z" /></clipPath>
        <clipPath id="cw-0-4" clip-path="url(#cv)"><path width="100%" height="100%" d="m813.78 65.03 4.911-5.356-1.944-1.265z" /></clipPath>
        <clipPath id="cx-7-7"><path width="100%" height="100%" d="m813.6 58.222h5.281v6.96h-5.281z" /></clipPath>
        <clipPath id="cz-5-5" clip-path="url(#cy)">
          <path width="100%" height="100%" d="m815.52 52.613 1.265-1.944-6.621-2.963z" />
        </clipPath>
        <clipPath id="cA-3-9"><path width="100%" height="100%" d="m810 47.662h6.961v5.04h-6.961z" /></clipPath>
        <clipPath id="cC-7-4" clip-path="url(#cB)"><path width="100%" height="100%" d="m824.48 58.452 6.62 2.964-5.354-4.909z" /></clipPath>
        <clipPath id="cD-8-8"><path width="100%" height="100%" d="m824.4 56.302h6.721v5.28h-6.721z" /></clipPath>
        <filter id="ap-1-0" x="-.024571" y="-.023012" width="1.0491" height="1.046" color-interpolation-filters="sRGB">
          <feBlend in2="BackgroundImage" mode="lighten" />
        </filter>
        <clipPath id="bQ-6-0-2" clip-path="url(#bP)">
          <path
            width="100%"
            height="100%"
            d="m817.28 49.743c-2.668 1.805-3.367 5.432-1.562 8.098 1.805 2.669 5.432 3.367 8.1 1.562s3.367-5.432 1.561-8.099c-1.127-1.666-2.965-2.564-4.834-2.563-1.126 0-2.263 0.324-3.265 1.002"
          />
        </clipPath>
        <clipPath id="bR-8-3-2"><path width="100%" height="100%" d="m814.8 48.623h11.52v11.76h-11.52z" /></clipPath>
        <clipPath id="bT-3-8-8" clip-path="url(#bS)">
          <path width="100%" height="100%" d="m816.68 50.781 1.922-1.301-4.952-5.302z" />
        </clipPath>
        <clipPath id="bU-3-4-6"><path width="100%" height="100%" d="m813.6 44.062h5.041v6.72h-5.041z" /></clipPath>
        <clipPath id="bW-1-8-8" clip-path="url(#bV)">
          <path width="100%" height="100%" d="m822.67 59.64 4.949 5.301-3.029-6.601z" />
        </clipPath>
        <clipPath id="bX-3-8-1"><path width="100%" height="100%" d="m822.48 58.222h5.279v6.72h-5.279z" /></clipPath>
        <clipPath id="bZ-0-3-3" clip-path="url(#bY)"><path width="100%" height="100%" d="m810.25 61.547 6.6-3.03-1.299-1.919z" /></clipPath>
        <clipPath id="b0-4-7-2"><path width="100%" height="100%" d="m810.24 56.542h6.721v5.04h-6.721z" /></clipPath>
        <clipPath id="b2-4-8-5" clip-path="url(#b1)">
          <path width="100%" height="100%" d="m824.41 50.602 1.301 1.92 5.301-4.948z" />
        </clipPath>
        <clipPath id="b3-5-3-7"><path width="100%" height="100%" d="m824.4 47.422h6.721v5.28h-6.721z" /></clipPath>
        <clipPath id="b5-8-6-3" clip-path="url(#b4)">
          <path width="100%" height="100%" d="m820.51 49.09 2.275 0.438 0.25-7.249z" />
        </clipPath>
        <clipPath id="b6-9-2-2"><path width="100%" height="100%" d="m820.32 42.142h2.879v7.44h-2.879z" /></clipPath>
        <clipPath id="b8-4-8-4" clip-path="url(#b7)">
          <path width="100%" height="100%" d="m818.23 66.841 2.526-6.81-2.276-0.439z" />
        </clipPath>
        <clipPath id="b9-7-7-8"><path width="100%" height="100%" d="m818.16 59.422h2.641v7.44h-2.641z" /></clipPath>
        <clipPath id="cb-1-3-3" clip-path="url(#ca)">
          <path width="100%" height="100%" d="m815.16 54.686 0.44-2.277-7.248-0.249z" />
        </clipPath>
        <clipPath id="cc-6-9-2"><path width="100%" height="100%" d="m808.32 51.982h7.439v2.88h-7.439z" /></clipPath>
        <clipPath id="ce-2-0-7" clip-path="url(#cd)">
          <path width="100%" height="100%" d="m825.67 56.713 7.248 0.247-6.81-2.526z" />
        </clipPath>
        <clipPath id="cf-2-2-4"><path width="100%" height="100%" d="m825.6 54.382h7.441v2.64h-7.441z" /></clipPath>
        <clipPath id="ch-3-5-2" clip-path="url(#cg)">
          <path width="100%" height="100%" d="m818.4 49.567 2.267-0.48-2.584-6.778z" />
        </clipPath>
        <clipPath id="ci-8-7-6"><path width="100%" height="100%" d="m817.92 42.142h2.881v7.44h-2.881z" /></clipPath>
        <clipPath id="ck-7-3-3" clip-path="url(#cj)">
          <path width="100%" height="100%" d="m820.6 60.033 2.584 6.778-0.316-7.258z" />
        </clipPath>
        <clipPath id="cl-9-4-6"><path width="100%" height="100%" d="m820.56 59.422h2.639v7.44h-2.639z" /></clipPath>
        <clipPath id="cn-7-3-5" clip-path="url(#cm)">
          <path width="100%" height="100%" d="m808.38 57.113 7.256-0.312-0.481-2.269z" />
        </clipPath>
        <clipPath id="co-2-0-5"><path width="100%" height="100%" d="m808.32 54.382h7.439v2.88h-7.439z" /></clipPath>
        <clipPath id="cq-4-3-0" clip-path="url(#cp)">
          <path width="100%" height="100%" d="m825.63 52.323 0.478 2.267 6.778-2.586z" />
        </clipPath>
        <clipPath id="cr-0-7-8"><path width="100%" height="100%" d="m825.6 51.982h7.441v2.64h-7.441z" /></clipPath>
        <clipPath id="ct-8-0-8" clip-path="url(#cs)">
          <path width="100%" height="100%" d="m822.58 49.445 1.943 1.266 2.965-6.621z" />
        </clipPath>
        <clipPath id="cu-3-7-5"><path width="100%" height="100%" d="m822.48 44.062h5.039v6.72h-5.039z" /></clipPath>
        <clipPath id="cw-0-4-4" clip-path="url(#cv)">
          <path width="100%" height="100%" d="m813.78 65.03 4.911-5.356-1.944-1.265z" />
        </clipPath>
        <clipPath id="cx-7-7-3"><path width="100%" height="100%" d="m813.6 58.222h5.281v6.96h-5.281z" /></clipPath>
        <clipPath id="cz-5-5-1" clip-path="url(#cy)">
          <path width="100%" height="100%" d="m815.52 52.613 1.265-1.944-6.621-2.963z" />
        </clipPath>
        <clipPath id="cA-3-9-7"><path width="100%" height="100%" d="m810 47.662h6.961v5.04h-6.961z" /></clipPath>
        <clipPath id="cC-7-4-3" clip-path="url(#cB)">
          <path width="100%" height="100%" d="m824.48 58.452 6.62 2.964-5.354-4.909z" />
        </clipPath>
        <clipPath id="cD-8-8-3"><path width="100%" height="100%" d="m824.4 56.302h6.721v5.28h-6.721z" /></clipPath>
        <filter id="ap-1-0-6" x="-.024571" y="-.023012" width="1.0491" height="1.046" color-interpolation-filters="sRGB">
          <feBlend in2="BackgroundImage" mode="lighten" />
        </filter>
        <marker id="marker3227-7" overflow="visible" orient="auto">
          <path
            transform="scale(.6)"
            d="m8.7186 4.0337-10.926-4.0177 10.926-4.0177c-1.7455 2.3721-1.7354 5.6175-6e-7 8.0354z"
            fill-rule="evenodd"
            stroke="#000"
            stroke-linejoin="round"
            stroke-width=".625"
          />
        </marker>
        <marker
          id="Arrow2Mend-1"
          overflow="visible"
          markerHeight="5.1962256"
          markerWidth="6.9304585"
          orient="auto"
          preserveAspectRatio="xMidYMid"
          viewBox="0 0 6.9304588 5.1962256"
        >
          <path
            transform="scale(-.6)"
            d="m8.7186 4.0337-10.926-4.0177 10.926-4.0177c-1.7455 2.3721-1.7354 5.6175-6e-7 8.0354z"
            fill-rule="evenodd"
            stroke="#000"
            stroke-linejoin="round"
            stroke-width=".625"
          />
        </marker>
        <marker
          id="Arrow2Mend-1-5"
          overflow="visible"
          markerHeight="5.1962256"
          markerWidth="6.9304585"
          orient="auto"
          preserveAspectRatio="xMidYMid"
          viewBox="0 0 6.9304588 5.1962256"
        >
          <path
            transform="scale(-.6)"
            d="m8.7186 4.0337-10.926-4.0177 10.926-4.0177c-1.7455 2.3721-1.7354 5.6175-6e-7 8.0354z"
            fill-rule="evenodd"
            stroke="#000"
            stroke-linejoin="round"
            stroke-width=".625"
          />
        </marker>
        <marker id="Arrow2Lend-9" overflow="visible" orient="auto">
          <path
            transform="matrix(-1.1,0,0,-1.1,-1.1,0)"
            d="m8.7186 4.0337-10.926-4.0177 10.926-4.0177c-1.7455 2.3721-1.7354 5.6175-6e-7 8.0354z"
            fill-rule="evenodd"
            stroke="#000"
            stroke-linejoin="round"
            stroke-width=".625"
          />
        </marker>
        <marker
          id="Arrow2Mend-1-5-0"
          overflow="visible"
          markerHeight="5.1962256"
          markerWidth="6.9304585"
          orient="auto"
          preserveAspectRatio="xMidYMid"
          viewBox="0 0 6.9304588 5.1962256"
        >
          <path
            transform="scale(-.6)"
            d="m8.7186 4.0337-10.926-4.0177 10.926-4.0177c-1.7455 2.3721-1.7354 5.6175-6e-7 8.0354z"
            fill-rule="evenodd"
            stroke="#000"
            stroke-linejoin="round"
            stroke-width=".625"
          />
        </marker>
        <marker
          id="Arrow2Mend-1-8"
          overflow="visible"
          markerHeight="5.1962256"
          markerWidth="6.9304585"
          orient="auto"
          preserveAspectRatio="xMidYMid"
          viewBox="0 0 6.9304588 5.1962256"
        >
          <path
            transform="scale(-.6)"
            d="m8.7186 4.0337-10.926-4.0177 10.926-4.0177c-1.7455 2.3721-1.7354 5.6175-6e-7 8.0354z"
            fill-rule="evenodd"
            stroke="#000"
            stroke-linejoin="round"
            stroke-width=".625"
          />
        </marker>
        <marker id="marker3227-5" overflow="visible" orient="auto">
          <path
            transform="scale(.6)"
            d="m8.7186 4.0337-10.926-4.0177 10.926-4.0177c-1.7455 2.3721-1.7354 5.6175-6e-7 8.0354z"
            fill-rule="evenodd"
            stroke="#000"
            stroke-linejoin="round"
            stroke-width=".625"
          />
        </marker>
        <marker id="marker5490-6" overflow="visible" orient="auto">
          <path
            transform="matrix(-1.1,0,0,-1.1,-1.1,0)"
            d="m8.7186 4.0337-10.926-4.0177 10.926-4.0177c-1.7455 2.3721-1.7354 5.6175-6e-7 8.0354z"
            fill-rule="evenodd"
            stroke="#000"
            stroke-linejoin="round"
            stroke-width=".625"
          />
        </marker>
        <marker id="marker3227-5-4" overflow="visible" orient="auto">
          <path
            transform="scale(.6)"
            d="m8.7186 4.0337-10.926-4.0177 10.926-4.0177c-1.7455 2.3721-1.7354 5.6175-6e-7 8.0354z"
            fill-rule="evenodd"
            stroke="#000"
            stroke-linejoin="round"
            stroke-width=".625"
          />
        </marker>
      </defs>
      <!-- #endregion -->

      <!-- #region Schema 1 -->
      <g *ngIf="ctx?.schema[0] === 'takeout_device_keep_data'" id="Remove GPS | WG" transform="translate(0 -39.688)" display="">
        <g transform="translate(0 -3.7042)" stroke="#000">
          <g fill="none">
            <path transform="translate(0 -217.62)" d="m1.2095 290.45h103.23" marker-end="url(#Arrow2Lend)" stroke-width=".31553px" />
            <path transform="translate(0 -217.62)" d="m12.441 288.3v4.4901" stroke-width=".316" />
            <path d="m71.179 70.675v4.4901" stroke-width=".316" />
          </g>
          <g transform="matrix(-.19464 -.0017787 -.006825 .20106 53.637 14.232)" display="inline">
            <g transform="translate(-1.7073 18.31)" display="inline">
              <g transform="rotate(6.6687 231.39 285.26)">
                <g transform="translate(.077267 4.7053)" display="inline">
                  <g transform="translate(-.54776 .18259)">
                    <path
                      d="m140.9 242.91 65.831 10.486s0.51724 1.2994 0.50363 1.8793c-0.0136 0.57987-0.58533 1.6-0.58533 1.6l-65.639-11.138s-0.31061-0.93192-0.32726-1.4833c-0.0155-0.51177 0.21704-1.3443 0.21704-1.3443"
                      fill="#a5a7a8"
                      stroke="#000"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width=".47571"
                    />
                    <g transform="translate(125.48 89.916)" fill="#161315">
                      <path
                        d="m81.259 163.61 9.6383-2.177s0.9708-0.0824 1.0448 1.2098c0.074 1.2922-0.52839 1.7072-0.52839 1.7072l-10.077 2.4669s-0.76492-0.10403-0.85182-1.5276c-0.08689-1.4236 0.77454-1.6792 0.77454-1.6792z"
                        fill="#161315"
                        stroke="#000"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width=".75618"
                      />
                    </g>
                  </g>
                  <path
                    d="m140.5 243.11s-2.2874 0.545 9.7289-3.4888c13.689 2.4522 65.962 11.834 65.962 11.834l-10.453 2.5208z"
                    fill="#a5a7a8"
                    stroke="#000"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width=".47571"
                  />
                </g>
                <g transform="translate(9.3202 -23.929)" display="inline" fill="#161315">
                  <g stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width=".28852">
                    <path d="m188.53 280.28v-2.1626" />
                    <path d="m188.53 280.28 1.5411 0.21234" />
                    <path d="m190.07 280.49v-2.1626" display="inline" />
                    <path d="m188.53 278.12 1.5411 0.21234" display="inline" />
                  </g>
                  <path d="m188.66 278.28 1.2836 0.17752-4e-3 1.8778-1.2683-0.17133z" stroke="none" />
                </g>
                <g transform="translate(-39.808 -32.232)" display="inline" fill="#161315">
                  <g stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width=".28852">
                    <path d="m188.53 280.28v-2.1626" />
                    <path d="m188.53 280.28 1.5411 0.21234" />
                    <path d="m190.07 280.49v-2.1626" display="inline" />
                    <path d="m188.53 278.12 1.5411 0.21234" display="inline" />
                  </g>
                  <path d="m188.66 278.28 1.2836 0.17752-4e-3 1.8778-1.2683-0.17133z" stroke="none" />
                </g>
                <g transform="translate(-.54776 .18259)">
                  <path
                    d="m140.9 242.91 65.831 10.486s0.51724 1.2994 0.50363 1.8793c-0.0136 0.57987-0.58533 1.6-0.58533 1.6l-65.639-11.138s-0.31061-0.93192-0.32726-1.4833c-0.0155-0.51177 0.21704-1.3443 0.21704-1.3443"
                    fill="#a5a7a8"
                    stroke="#000"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width=".47571"
                  />
                  <g transform="translate(125.48 89.916)" fill="#161315">
                    <path
                      d="m81.259 163.61 9.6383-2.177s0.9708-0.0824 1.0448 1.2098c0.074 1.2922-0.52839 1.7072-0.52839 1.7072l-10.077 2.4669s-0.76492-0.10403-0.85182-1.5276c-0.08689-1.4236 0.77454-1.6792 0.77454-1.6792z"
                      fill="#161315"
                      stroke="#000"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width=".75618"
                    />
                  </g>
                </g>
                <path
                  d="m140.5 243.11s-2.2874 0.545 9.7289-3.4888c13.689 2.4522 65.962 11.834 65.962 11.834l-10.453 2.5208z"
                  fill="#a5a7a8"
                  stroke="#000"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width=".47571"
                />
              </g>
            </g>
          </g>
          <g
            transform="matrix(.17245 0 0 .15836 -10.787 40.144)"
            display="inline"
            fill="#fff"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="1.0583"
          >
            <path d="m71.267 161.05 12.818 2.0301v12.404l-12.818-2.0301z" />
            <path d="m147.7 147.2-63.613 15.881v12.404l63.474-16.695z" />
          </g>
          <g fill="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width=".228">
            <path d="m2.2628 65.008 1.6599-0.41483 8.216 1.3013-0.93201 0.23238 0.0081 0.29902z" display="inline" />
            <path d="m1.5348 64.591 1.688-0.42286 0.70004 0.12403-0.01506 0.28834-1.6379 0.43104-0.0047-0.30174z" display="inline" />
            <path d="m3.9228 64.292 0.0078 0.29911 8.2082 1.3032 0.69141-0.1964z" display="inline" />
          </g>
          <path
            d="m2.2547 64.713 1.6681-0.42114"
            display="inline"
            fill="none"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width=".228"
          />
          <g stroke-linecap="round" stroke-linejoin="round">
            <path
              d="m11.895 66.257v1.4942l-10.355-1.6751v-1.4942l0.7336 0.11868-1e-6 0.30211 8.8876 1.4377v-0.30211z"
              display="inline"
              fill="#fff"
              stroke-width=".22892"
            />
            <g stroke-width=".228">
              <path
                d="m11.952 66.288-0.01977 1.4438 10.908-3.1327 0.03954-11.651-9.2791 2.3757 0.09885 10.477z"
                display="inline"
                fill="#fffffc"
              />
              <path d="m13.722 65.828-10.562-1.7044 0.05058-10.521 10.427 1.7335z" display="inline" fill="#fff" />
              <path
                d="m16.96 58.927s-0.14868 0.11983-0.21697 0.2722c-0.05278 0.11776-0.02922 0.2606-0.02922 0.34728 0 0.08817 0.01365 0.22287 0.07315 0.32889 0.06348 0.11312 0.17304 0.19488 0.17304 0.19488l3.4301-0.87557s0.15527-0.13786 0.23459-0.29548c0.0695-0.1381 0.06435-0.2909 0.06435-0.38565 0-0.0966-0.03508-0.23376-0.11553-0.33043-0.06796-0.08166-0.18341-0.1317-0.18341-0.1317z"
                display="inline"
                fill="#fff"
              />
            </g>
          </g>
          <g
            transform="matrix(.17235 -.0013215 0 .17235 -4.7805 45.859)"
            display="inline"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="1.0001"
          >
            <path d="m43.886 34.616 62.464 8.969 55.731-13.205-58.761-11.394z" display="inline" />
            <path d="m162.11 45.619v-13.237l-55.569 12.882-62.789-8.9715v15.941l62.789 9.9447 55.569-13.855z" display="inline" />
          </g>
        </g>
        <g transform="matrix(-.19464 -.0017787 -.006825 .20106 122.96 9.999)" display="inline" stroke="#000">
          <g transform="translate(-36.752 33.43)" display="inline">
            <g transform="rotate(6.6687 231.39 285.26)">
              <g transform="translate(.077267 4.7053)" display="inline">
                <g transform="translate(-.54776 .18259)">
                  <path
                    d="m140.9 242.91 65.831 10.486s0.51724 1.2994 0.50363 1.8793c-0.0136 0.57987-0.58533 1.6-0.58533 1.6l-65.639-11.138s-0.31061-0.93192-0.32726-1.4833c-0.0155-0.51177 0.21704-1.3443 0.21704-1.3443"
                    fill="#a5a7a8"
                    stroke="#000"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width=".47571"
                  />
                  <g transform="translate(125.48 89.916)" fill="#161315">
                    <path
                      d="m81.259 163.61 9.6383-2.177s0.9708-0.0824 1.0448 1.2098c0.074 1.2922-0.52839 1.7072-0.52839 1.7072l-10.077 2.4669s-0.76492-0.10403-0.85182-1.5276c-0.08689-1.4236 0.77454-1.6792 0.77454-1.6792z"
                      fill="#161315"
                      stroke="#000"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width=".75618"
                    />
                  </g>
                </g>
                <path
                  d="m140.5 243.11s-2.2874 0.545 9.7289-3.4888c13.689 2.4522 65.962 11.834 65.962 11.834l-10.453 2.5208z"
                  fill="#a5a7a8"
                  stroke="#000"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width=".47571"
                />
              </g>
              <g transform="translate(9.3202 -23.929)" display="inline" fill="#161315">
                <g stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width=".28852">
                  <path d="m188.53 280.28v-2.1626" />
                  <path d="m188.53 280.28 1.5411 0.21234" />
                  <path d="m190.07 280.49v-2.1626" display="inline" />
                  <path d="m188.53 278.12 1.5411 0.21234" display="inline" />
                </g>
                <path d="m188.66 278.28 1.2836 0.17752-4e-3 1.8778-1.2683-0.17133z" stroke="none" />
              </g>
              <g transform="translate(-39.808 -32.232)" display="inline" fill="#161315">
                <g stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width=".28852">
                  <path d="m188.53 280.28v-2.1626" />
                  <path d="m188.53 280.28 1.5411 0.21234" />
                  <path d="m190.07 280.49v-2.1626" display="inline" />
                  <path d="m188.53 278.12 1.5411 0.21234" display="inline" />
                </g>
                <path d="m188.66 278.28 1.2836 0.17752-4e-3 1.8778-1.2683-0.17133z" stroke="none" />
              </g>
              <g transform="translate(-.54776 .18259)">
                <path
                  d="m140.9 242.91 65.831 10.486s0.51724 1.2994 0.50363 1.8793c-0.0136 0.57987-0.58533 1.6-0.58533 1.6l-65.639-11.138s-0.31061-0.93192-0.32726-1.4833c-0.0155-0.51177 0.21704-1.3443 0.21704-1.3443"
                  fill="#a5a7a8"
                  stroke="#000"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width=".47571"
                />
                <g transform="translate(125.48 89.916)" fill="#161315">
                  <path
                    d="m81.259 163.61 9.6383-2.177s0.9708-0.0824 1.0448 1.2098c0.074 1.2922-0.52839 1.7072-0.52839 1.7072l-10.077 2.4669s-0.76492-0.10403-0.85182-1.5276c-0.08689-1.4236 0.77454-1.6792 0.77454-1.6792z"
                    fill="#161315"
                    stroke="#000"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width=".75618"
                  />
                </g>
              </g>
              <path
                d="m140.5 243.11s-2.2874 0.545 9.7289-3.4888c13.689 2.4522 65.962 11.834 65.962 11.834l-10.453 2.5208z"
                fill="#a5a7a8"
                stroke="#000"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width=".47571"
              />
            </g>
          </g>
        </g>
        <g transform="translate(0 -3.7042)">
          <g fill="#fff" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width=".228">
            <path d="m61 65.537 1.6599-0.41483 8.216 1.3013-0.93201 0.23238 0.0081 0.29902z" display="inline" />
            <path d="m60.272 65.12 1.688-0.42286 0.70004 0.12403-0.01506 0.28834-1.6379 0.43104-0.0047-0.30174z" display="inline" />
            <path d="m62.66 64.821 0.0078 0.29911 8.2082 1.3032 0.69141-0.1964z" display="inline" />
          </g>
          <path
            d="m60.992 65.242 1.6681-0.42114"
            display="inline"
            fill="none"
            stroke="#000"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width=".228"
          />
          <g stroke="#000" stroke-linecap="round" stroke-linejoin="round">
            <path
              d="m70.633 66.786v1.4942l-10.355-1.6751v-1.4942l0.7336 0.11868-1e-6 0.30211 8.8876 1.4377v-0.30211z"
              display="inline"
              fill="#fff"
              stroke-width=".22892"
            />
            <g stroke-width=".228">
              <path
                d="m70.689 66.817-0.01977 1.4438 10.908-3.1327 0.03954-11.651-9.2791 2.3757 0.09885 10.477z"
                display="inline"
                fill="#fffffc"
              />
              <path d="m72.46 66.357-10.562-1.7044 0.05058-10.521 10.427 1.7335z" display="inline" fill="#fff" />
              <path
                d="m75.698 59.456s-0.14868 0.11983-0.21697 0.2722c-0.05278 0.11776-0.02922 0.2606-0.02922 0.34728 0 0.08817 0.01365 0.22287 0.07315 0.32889 0.06348 0.11312 0.17304 0.19488 0.17304 0.19488l3.4301-0.87557s0.15527-0.13786 0.23459-0.29548c0.0695-0.1381 0.06435-0.2909 0.06435-0.38564 0-0.0966-0.03508-0.23376-0.11553-0.33043-0.06796-0.08166-0.18341-0.1317-0.18341-0.1317z"
                display="inline"
                fill="#fff"
              />
            </g>
          </g>
          <g
            transform="matrix(.17235 -.0013215 0 .17235 53.957 46.388)"
            display="inline"
            stroke="#000"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="1.0001"
          >
            <path d="m43.886 34.616 62.464 8.969 55.731-13.205-58.761-11.394z" display="inline" />
            <path d="m162.11 45.619v-13.237l-55.569 12.882-62.789-8.9715v15.941l62.789 9.9447 55.569-13.855z" display="inline" />
          </g>
          <path
            d="m75.811 67.345s2.3669 3.2545 3.8821 3.8821 4.3498 0 4.3498 0"
            fill="none"
            marker-end="url(#Arrow2Mend)"
            stroke="#000"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width=".265"
          />
          <g transform="matrix(.012402 0 0 .012402 79.564 63.21)">
            <circle cx="256" cy="256" r="256" fill="#e04f5f" />
            <rect x="119.68" y="240" width="272" height="32" fill="#fff" />
          </g>
          <path
            d="m20.152 67.95s2.1354 3.6679 3.8821 3.8821 4.3498 0 4.3498 0"
            fill="none"
            marker-start="url(#marker3227)"
            stroke="#000"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width=".26458px"
          />
          <circle cx="27.268" cy="68.078" r="3.175" fill="#43b05c" stroke-width=".127" />
          <line
            x1="27.268"
            x2="27.268"
            y1="66.554"
            y2="69.729"
            fill="none"
            stroke="#fff"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-miterlimit="10"
            stroke-width=".254"
          />
          <line
            x1="28.856"
            x2="25.681"
            y1="68.078"
            y2="68.078"
            fill="none"
            stroke="#fff"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-miterlimit="10"
            stroke-width=".254"
          />
        </g>
        <text
          x="5.7147679"
          y="74.714325"
          display="inline"
          fill="#000000"
          font-family="sans-serif"
          font-size="10.583px"
          letter-spacing="0px"
          stroke-width=".26458"
          word-spacing="0px"
          style="line-height: 1.25"
          xml:space="preserve"
        >
          <tspan x="5.7147679" y="74.714325" font-size="3.5278px" stroke-width=".26458">{{ ctx?.schema[1]['since_date'] }}</tspan>
        </text>
        <text
          x="61.654278"
          y="75.211388"
          display="inline"
          fill="#000000"
          font-family="sans-serif"
          font-size="10.583px"
          letter-spacing="0px"
          stroke-width=".26458"
          word-spacing="0px"
          style="line-height: 1.25"
          xml:space="preserve"
        >
          <tspan x="61.654278" y="75.211388" font-size="3.5278px" stroke-width=".26458">{{ ctx?.schema[1]['takeout_date'] }}</tspan>
        </text>
      </g>
      <!-- #endregion -->

      <!-- #region Schema 2 -->
      <g *ngIf="ctx?.schema[0] === 'takeout_device_drop_data'" id="Delete GPS | WG" transform="translate(0 -39.688)" display="">
        <g transform="translate(0 42.333)">
          <g fill="none" stroke="#000">
            <path transform="translate(0 -217.62)" d="m1.2095 245.47h103.23" marker-end="url(#marker5490)" stroke-width=".316" />
            <path transform="translate(0 -217.62)" d="m12.441 243.32v4.4901" stroke-width=".316" />
            <path
              d="m19.343 22.591s2.3669 3.2545 3.8821 3.8821 4.3498 0 4.3498 0"
              marker-start="url(#Arrow2Mstart)"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width=".265"
            />
          </g>
          <g transform="matrix(.127 0 0 .127 24.342 19.315)">
            <circle cx="25" cy="25" r="25" fill="#43b05c" />
            <line
              x1="25"
              x2="25"
              y1="13"
              y2="38"
              fill="none"
              stroke="#fff"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-miterlimit="10"
              stroke-width="2"
            />
            <line
              x1="37.5"
              x2="12.5"
              y1="25"
              y2="25"
              fill="none"
              stroke="#fff"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-miterlimit="10"
              stroke-width="2"
            />
          </g>
          <g transform="matrix(-.19464 -.0017787 -.006825 .20106 53.6 -31.639)" display="inline" stroke="#000">
            <g transform="translate(-1.7073 18.31)" display="inline">
              <g transform="rotate(6.6687 231.39 285.26)">
                <g transform="translate(.077267 4.7053)" display="inline">
                  <g transform="translate(-.54776 .18259)">
                    <path
                      d="m140.9 242.91 65.831 10.486s0.51724 1.2994 0.50363 1.8793c-0.0136 0.57987-0.58533 1.6-0.58533 1.6l-65.639-11.138s-0.31061-0.93192-0.32726-1.4833c-0.0155-0.51177 0.21704-1.3443 0.21704-1.3443"
                      fill="#a5a7a8"
                      stroke="#000"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width=".47571"
                    />
                    <g transform="translate(125.48 89.916)" fill="#161315">
                      <path
                        d="m81.259 163.61 9.6383-2.177s0.9708-0.0824 1.0448 1.2098c0.074 1.2922-0.52839 1.7072-0.52839 1.7072l-10.077 2.4669s-0.76492-0.10403-0.85182-1.5276c-0.08689-1.4236 0.77454-1.6792 0.77454-1.6792z"
                        fill="#161315"
                        stroke="#000"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width=".75618"
                      />
                    </g>
                  </g>
                  <path
                    d="m140.5 243.11s-2.2874 0.545 9.7289-3.4888c13.689 2.4522 65.962 11.834 65.962 11.834l-10.453 2.5208z"
                    fill="#a5a7a8"
                    stroke="#000"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width=".47571"
                  />
                </g>
                <g transform="translate(9.3202 -23.929)" display="inline" fill="#161315">
                  <g stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width=".28852">
                    <path d="m188.53 280.28v-2.1626" />
                    <path d="m188.53 280.28 1.5411 0.21234" />
                    <path d="m190.07 280.49v-2.1626" display="inline" />
                    <path d="m188.53 278.12 1.5411 0.21234" display="inline" />
                  </g>
                  <path d="m188.66 278.28 1.2836 0.17752-4e-3 1.8778-1.2683-0.17133z" stroke="none" />
                </g>
                <g transform="translate(-39.808 -32.232)" display="inline" fill="#161315">
                  <g stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width=".28852">
                    <path d="m188.53 280.28v-2.1626" />
                    <path d="m188.53 280.28 1.5411 0.21234" />
                    <path d="m190.07 280.49v-2.1626" display="inline" />
                    <path d="m188.53 278.12 1.5411 0.21234" display="inline" />
                  </g>
                  <path d="m188.66 278.28 1.2836 0.17752-4e-3 1.8778-1.2683-0.17133z" stroke="none" />
                </g>
                <g transform="translate(-.54776 .18259)">
                  <path
                    d="m140.9 242.91 65.831 10.486s0.51724 1.2994 0.50363 1.8793c-0.0136 0.57987-0.58533 1.6-0.58533 1.6l-65.639-11.138s-0.31061-0.93192-0.32726-1.4833c-0.0155-0.51177 0.21704-1.3443 0.21704-1.3443"
                    fill="#a5a7a8"
                    stroke="#000"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width=".47571"
                  />
                  <g transform="translate(125.48 89.916)" fill="#161315">
                    <path
                      d="m81.259 163.61 9.6383-2.177s0.9708-0.0824 1.0448 1.2098c0.074 1.2922-0.52839 1.7072-0.52839 1.7072l-10.077 2.4669s-0.76492-0.10403-0.85182-1.5276c-0.08689-1.4236 0.77454-1.6792 0.77454-1.6792z"
                      fill="#161315"
                      stroke="#000"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width=".75618"
                    />
                  </g>
                </g>
                <path
                  d="m140.5 243.11s-2.2874 0.545 9.7289-3.4888c13.689 2.4522 65.962 11.834 65.962 11.834l-10.453 2.5208z"
                  fill="#a5a7a8"
                  stroke="#000"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width=".47571"
                />
              </g>
            </g>
          </g>
          <g
            transform="matrix(.17245 0 0 .15836 -10.825 -5.7269)"
            display="inline"
            fill="#fff"
            stroke="#000"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="1.0583"
          >
            <path d="m71.267 161.05 12.818 2.0301v12.404l-12.818-2.0301z" />
            <path d="m147.7 147.2-63.613 15.881v12.404l63.474-16.695z" />
          </g>
          <g fill="#fff" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width=".228">
            <path d="m2.2257 19.137 1.6599-0.41483 8.216 1.3013-0.93201 0.23238 0.0081 0.29902z" display="inline" />
            <path d="m1.4977 18.72 1.688-0.42286 0.70004 0.12403-0.01506 0.28834-1.6379 0.43104-0.0047-0.30174z" display="inline" />
            <path d="m3.8856 18.421 0.0078 0.29911 8.2081 1.3032 0.69141-0.1964z" display="inline" />
          </g>
          <path
            d="m2.2176 18.842 1.6681-0.42114"
            display="inline"
            fill="none"
            stroke="#000"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width=".228"
          />
          <g stroke="#000" stroke-linecap="round" stroke-linejoin="round">
            <path
              d="m11.858 20.386v1.4942l-10.355-1.6751v-1.4942l0.7336 0.11868-1e-6 0.30211 8.8876 1.4377v-0.30211z"
              display="inline"
              fill="#fff"
              stroke-width=".22892"
            />
            <g stroke-width=".228">
              <path
                d="m11.914 20.417-0.01977 1.4438 10.908-3.1327 0.03954-11.651-9.2791 2.3757 0.09885 10.477z"
                display="inline"
                fill="#fffffc"
              />
              <path d="m13.685 19.957-10.562-1.7044 0.05058-10.521 10.427 1.7335z" display="inline" fill="#fff" />
              <path
                d="m16.923 13.056s-0.14868 0.11983-0.21697 0.2722c-0.05278 0.11776-0.02922 0.2606-0.02922 0.34728 0 0.08817 0.01365 0.22287 0.07315 0.32889 0.06348 0.11313 0.17304 0.19488 0.17304 0.19488l3.4301-0.87557s0.15527-0.13786 0.23459-0.29548c0.0695-0.1381 0.06435-0.2909 0.06435-0.38564 0-0.0966-0.03508-0.23376-0.11553-0.33043-0.06796-0.08166-0.18341-0.1317-0.18341-0.1317z"
                display="inline"
                fill="#fff"
              />
            </g>
          </g>
          <g
            transform="matrix(.17235 -.0013215 0 .17235 -4.8177 -.012188)"
            display="inline"
            stroke="#000"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="1.0001"
          >
            <path d="m43.886 34.616 62.464 8.969 55.731-13.205-58.761-11.394z" display="inline" />
            <path d="m162.11 45.619v-13.237l-55.569 12.882-62.789-8.9715v15.941l62.789 9.9447 55.569-13.855z" display="inline" />
          </g>
        </g>
        <g
          transform="translate(-42.091 -188.24)"
          fill="none"
          stroke="#f00"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width=".965"
        >
          <path d="m43.779 233.3 23.76 23.76" />
          <path d="m67.539 233.3-23.76 23.76" />
        </g>
        <text
          x="6.7672915"
          y="75.810165"
          display="inline"
          fill="#000000"
          font-family="sans-serif"
          font-size="10.583px"
          letter-spacing="0px"
          stroke-width=".26458"
          word-spacing="0px"
          style="line-height: 1.25"
          xml:space="preserve"
        >
          <tspan x="6.7672915" y="75.810165" font-size="3.5278px" stroke-width=".26458">{{ ctx?.schema[1]['since_date'] }}</tspan>
        </text>
      </g>
      <!-- #endregion -->

      <!-- #region Schema 3 -->
      <g *ngIf="ctx?.schema[0] === 'takeout_rg_drop_data'" id="Delete RG" transform="translate(0 -39.688)" display="">
        <g transform="translate(0 42.333)" fill="none" stroke="#000" stroke-width=".316">
          <path transform="translate(0 -217.62)" d="m1.2095 245.47h103.23" marker-end="url(#marker5490)" />
          <path transform="translate(0 -217.62)" d="m12.441 243.32v4.4901" />
        </g>
        <g transform="matrix(.14986 0 0 .14986 -1.747 42.656)" display="inline" stroke="#000">
          <g stroke="#000">
            <g transform="matrix(1.0337 0 0 -1.0337 -610.4 318.17)" display="inline">
              <circle transform="scale(1,-1)" cx="729.88" cy="-221.54" r="24.025" fill="#fff" stroke="#000" stroke-width="1.125" />
              <g transform="translate(-77.909 -152.79)">
                <g transform="matrix(.9661 0 0 -.9661 22.942 433.74)">
                  <g clip-path="url(#bQ-6)">
                    <g clip-path="url(#bR-8)">
                      <image
                        transform="matrix(.48 0 0 -.48 813.57 61.76)"
                        width="32"
                        height="29"
                        overflow="visible"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        xlink:href="data:image/jpeg;base64,/9j/4AAQSkZJRgABAgEAlgCWAAD/7AARRHVja3kAAQAEAAAAHgAA/+4AIUFkb2JlAGTAAAAAAQMA EAMCAwYAAAHHAAAB/AAAAnb/2wCEABALCwsMCxAMDBAXDw0PFxsUEBAUGx8XFxcXFx8eFxoaGhoX Hh4jJSclIx4vLzMzLy9AQEBAQEBAQEBAQEBAQEABEQ8PERMRFRISFRQRFBEUGhQWFhQaJhoaHBoa JjAjHh4eHiMwKy4nJycuKzU1MDA1NUBAP0BAQEBAQEBAQEBAQP/CABEIAB8AIQMBIgACEQEDEQH/ xACMAAEBAQEBAQAAAAAAAAAAAAAEAAMFAQYBAAEFAAAAAAAAAAAAAAAAAAQAAQIFBhAAAgMAAgMB AAAAAAAAAAAAAgQAAQMTBRIyMxURAAIABAQHAQAAAAAAAAAAAAECABARMiFxkcExQWESQnKSAxIB AAICAgIDAAAAAAAAAAAAAQIDABEQITFREiIy/9oADAMBAAIRAxEAAAD7fHw+fKTvz1STqr4Xn4KN ni5GCZMyq/F//9oACAECAAEFAGm26aUabtuM4s04pkzbk//aAAgBAwABBQAMw8NACggEHHoQcc// 2gAIAQEAAQUAZaLE/wBA5+gcWZLe52H1ALKGFjOu9p2H1zPxmh+U672judnpwXOC4jnYXP/aAAgB AgIGPwCyqqxAloNGV1WzUd7EPTxZbXVOR8ukj14yu22ucT7bWPX5eP/aAAgBAwIGPwAUxlE4BTGM U4//2gAIAQEBBj8AChQaiuMWDWLBrDAqB204dZL67zfISX13n+mQkp7lGHkQOcXp9CL0+hD1ZWrS 0gy//9k="
                      />
                    </g>
                  </g>
                  <g clip-path="url(#bT-3)">
                    <g clip-path="url(#bU-3)">
                      <image
                        transform="matrix(.48 0 0 -.48 812.22 51.838)"
                        width="16"
                        height="18"
                        overflow="visible"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        xlink:href="data:image/jpeg;base64,/9j/4AAQSkZJRgABAgEAlgCWAAD/7AARRHVja3kAAQAEAAAAHgAA/+4AIUFkb2JlAGTAAAAAAQMA EAMCAwYAAAGkAAABvwAAAgr/2wCEABALCwsMCxAMDBAXDw0PFxsUEBAUGx8XFxcXFx8eFxoaGhoX Hh4jJSclIx4vLzMzLy9AQEBAQEBAQEBAQEBAQEABEQ8PERMRFRISFRQRFBEUGhQWFhQaJhoaHBoa JjAjHh4eHiMwKy4nJycuKzU1MDA1NUBAP0BAQEBAQEBAQEBAQP/CABEIABUAEQMBIgACEQEDEQH/ xACDAAEAAgMAAAAAAAAAAAAAAAAAAwQCBQYBAQEAAAAAAAAAAAAAAAAAAAMFEAABBAIDAQAAAAAA AAAAAAAAAQIDBBETEjIUNBEAAQMDBQAAAAAAAAAAAAAAEQAQEgFxAiExgSIDEgABBAIBBQAAAAAA AAAAAAABEDECEhEhAzJCgjMU/9oADAMBAAIRAxEAAADvIMqc5tqKIwVyet8UC//aAAgBAgABBQC9 ZkjmLWN5/9oACAEDAAEFAI2IrRnU/9oACAEBAAEFAJJeCsuq+Usrgr/SWNRD5Nx//9oACAECAgY/ AOGEJVvONtDYJTjz8zx9ubv24T//2gAIAQMCBj8AkSGGkPX4sn//2gAIAQEBBj8A2Kp5wBqCWrZY XbueFjCcjoQG/9k="
                      />
                    </g>
                  </g>
                  <g clip-path="url(#bW-1)">
                    <g clip-path="url(#bX-3)">
                      <image
                        transform="matrix(.48 0 0 -.48 821.22 65.921)"
                        width="16"
                        height="18"
                        overflow="visible"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        xlink:href="data:image/jpeg;base64,/9j/4AAQSkZJRgABAgEAlgCWAAD/7AARRHVja3kAAQAEAAAAHgAA/+4AIUFkb2JlAGTAAAAAAQMA EAMCAwYAAAGuAAAByAAAAhn/2wCEABALCwsMCxAMDBAXDw0PFxsUEBAUGx8XFxcXFx8eFxoaGhoX Hh4jJSclIx4vLzMzLy9AQEBAQEBAQEBAQEBAQEABEQ8PERMRFRISFRQRFBEUGhQWFhQaJhoaHBoa JjAjHh4eHiMwKy4nJycuKzU1MDA1NUBAP0BAQEBAQEBAQEBAQP/CABEIABUAEQMBIgACEQEDEQH/ xACMAAEBAAMBAAAAAAAAAAAAAAAABAECAwYBAQEBAAAAAAAAAAAAAAAAAAMEBRAAAQMDBQEAAAAA AAAAAAAABAACAwETFBARIRIzNBEAAgEEAgMAAAAAAAAAAAAAERIBABAhgUHBUXETEgABAwICCwAA AAAAAAAAAAABEBECMQMhQVFhgRIiMoITIzM0/9oADAMBAAIRAxEAAAD2+ZemU9w1Qj3IFpF5f//a AAgBAgABBQCAkh5yh2yl/9oACAEDAAEFAHMZSJO81//aAAgBAQABBQCcyzJCVdqjvoEpyi8XvBZ0 /9oACAECAgY/ALloz8cJS4d0UGtnS58zufW/c6sn0p//2gAIAQMCBj8AEmxIQc+2if/aAAgBAQEG PwBEbBJHVBBs9Wn1FRaPoz8r43ioRti3/9k="
                      />
                    </g>
                  </g>
                  <g clip-path="url(#bZ-0)">
                    <g clip-path="url(#b0-4)">
                      <image
                        transform="matrix(.48 0 0 -.48 808.99 62.691)"
                        width="20"
                        height="14"
                        overflow="visible"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        xlink:href="data:image/jpeg;base64,/9j/4AAQSkZJRgABAgEAlgCWAAD/7AARRHVja3kAAQAEAAAAHgAA/+4AIUFkb2JlAGTAAAAAAQMA EAMCAwYAAAGkAAABwAAAAg//2wCEABALCwsMCxAMDBAXDw0PFxsUEBAUGx8XFxcXFx8eFxoaGhoX Hh4jJSclIx4vLzMzLy9AQEBAQEBAQEBAQEBAQEABEQ8PERMRFRISFRQRFBEUGhQWFhQaJhoaHBoa JjAjHh4eHiMwKy4nJycuKzU1MDA1NUBAP0BAQEBAQEBAQEBAQP/CABEIABEAFwMBIgACEQEDEQH/ xACDAAEAAgMAAAAAAAAAAAAAAAAAAwQCBQYBAAIDAAAAAAAAAAAAAAAAAAADAgQFEAABBAMBAAAA AAAAAAAAAAADAAECBBASExQRAAEDAwUAAAAAAAAAAAAAAAEAEQJBgRIgUaEyAxIAAgIBBAMAAAAA AAAAAAAAAQIREgAQMUEDcSIT/9oADAMBAAIRAxEAAADu8Y62c7YoFpdoPiAf/9oACAECAAEFAD3T CN3fz4//2gAIAQMAAQUAiOMo6tvj/9oACAEBAAEFAClYaayzqJNoXRkmuFlAgVq2f//aAAgBAgIG PwBkHS/YixDKp5E59qNNbUj28Rr/AP/aAAgBAwIGPwAEsAcrPO+v/9oACAEBAQY/AA9X4VEZbKGE SWd2suhXpGQImcmFtH//2Q=="
                      />
                    </g>
                  </g>
                  <g clip-path="url(#b2-4)">
                    <g clip-path="url(#b3-5)">
                      <image
                        transform="matrix(.48 0 0 -.48 823.08 53.691)"
                        width="20"
                        height="15"
                        overflow="visible"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        xlink:href="data:image/jpeg;base64,/9j/4AAQSkZJRgABAgEAlgCWAAD/7AARRHVja3kAAQAEAAAAHgAA/+4AIUFkb2JlAGTAAAAAAQMA EAMCAwYAAAGoAAABwwAAAgn/2wCEABALCwsMCxAMDBAXDw0PFxsUEBAUGx8XFxcXFx8eFxoaGhoX Hh4jJSclIx4vLzMzLy9AQEBAQEBAQEBAQEBAQEABEQ8PERMRFRISFRQRFBEUGhQWFhQaJhoaHBoa JjAjHh4eHiMwKy4nJycuKzU1MDA1NUBAP0BAQEBAQEBAQEBAQP/CABEIABEAFQMBIgACEQEDEQH/ xACKAAEBAAMAAAAAAAAAAAAAAAAAAwEEBgEAAwEBAAAAAAAAAAAAAAAAAAMEAgUQAAEBBwUAAAAA AAAAAAAAAAMBABACEhMEFBEiIwUVEQABBAEFAQAAAAAAAAAAAAABABEhEgIgMUGRMhMSAAEBBgcA AAAAAAAAAAAAAAEhABARQQIDMaESIoKjRP/aAAwDAQACEQMRAAAA7WdYcl+4wtXYU4AP/9oACAEC AAEFADj7Gpzv/9oACAEDAAEFAIVDptf/AP/aAAgBAQABBQA95EInoRtVXHuATkW1VpFxn//aAAgB AgIGPwCs27mw1HSEQSbHz9j/AP/aAAgBAwIGPwARCzbnk/8A/9oACAEBAQY/ADgMQQGleB2vq01s yOTbqFTmraP/2Q=="
                      />
                    </g>
                  </g>
                  <g clip-path="url(#b5-8)">
                    <g clip-path="url(#b6-9)">
                      <image
                        transform="matrix(.48 0 0 -.48 819 50.547)"
                        width="12"
                        height="19"
                        overflow="visible"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        xlink:href="data:image/jpeg;base64,/9j/4AAQSkZJRgABAgEAlgCWAAD/7AARRHVja3kAAQAEAAAAHgAA/+4AIUFkb2JlAGTAAAAAAQMA EAMCAwYAAAGSAAABrAAAAfX/2wCEABALCwsMCxAMDBAXDw0PFxsUEBAUGx8XFxcXFx8eFxoaGhoX Hh4jJSclIx4vLzMzLy9AQEBAQEBAQEBAQEBAQEABEQ8PERMRFRISFRQRFBEUGhQWFhQaJhoaHBoa JjAjHh4eHiMwKy4nJycuKzU1MDA1NUBAP0BAQEBAQEBAQEBAQP/CABEIABUADwMBIgACEQEDEQH/ xAB9AAEBAQEAAAAAAAAAAAAAAAAAAgMGAQEBAAAAAAAAAAAAAAAAAAADBBAAAgIDAQAAAAAAAAAA AAAAAgMBBAAREhMRAAICAAQHAAAAAAAAAAAAAAERAAIhURIDYXGBkbEiExIAAgICAwAAAAAAAAAA AAAAAQIRIhAxURID/9oADAMBAAIRAxEAAADuJuJE2FZ//9oACAECAAEFAJBsXM//2gAIAQMAAQUA 2Pnn/9oACAEBAAEFALbWLFDmHWsDBQgNV284HPH/2gAIAQICBj8AV+7HyZDWaggcY//aAAgBAwIG PwCIsDvH/9oACAEBAQY/AK/PBtnlNzcsfaupFZBwA8ZuVzNvAg1PpLJrF9p//9k="
                      />
                    </g>
                  </g>
                  <g clip-path="url(#b8-4)">
                    <g clip-path="url(#b9-7)">
                      <image
                        transform="matrix(.48 0 0 -.48 816.85 68.173)"
                        width="12"
                        height="20"
                        overflow="visible"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        xlink:href="data:image/jpeg;base64,/9j/4AAQSkZJRgABAgEAlgCWAAD/7AARRHVja3kAAQAEAAAAHgAA/+4AIUFkb2JlAGTAAAAAAQMA EAMCAwYAAAGdAAABtwAAAgP/2wCEABALCwsMCxAMDBAXDw0PFxsUEBAUGx8XFxcXFx8eFxoaGhoX Hh4jJSclIx4vLzMzLy9AQEBAQEBAQEBAQEBAQEABEQ8PERMRFRISFRQRFBEUGhQWFhQaJhoaHBoa JjAjHh4eHiMwKy4nJycuKzU1MDA1NUBAP0BAQEBAQEBAQEBAQP/CABEIABUADwMBIgACEQEDEQH/ xACEAAACAwEAAAAAAAAAAAAAAAAAAgEDBAYBAQEBAAAAAAAAAAAAAAAAAAIDBBAAAgIDAQAAAAAA AAAAAAAAAgMBERITBBQRAAEEAgEFAAAAAAAAAAAAAAEAEQISMUFRcbHBAxMSAAAEBAcAAAAAAAAA AAAAAAABEQMxAhJCQVFxMsITM//aAAwDAQACEQMRAAAA7uc1udowRX//2gAIAQIAAQUAX0QT14+7 /9oACAEDAAEFAJChm9f/2gAIAQEAAQUAayQISmQ7ixNR3zdum1avL//aAAgBAgIGPwBxm5tE0MiD ngtOFXbAo2j/2gAIAQMCBj8AlmzBbuI//9oACAEBAQY/AIgbRkch16z1UpcA9lD6W21W8qVbUaVn zhf/2Q=="
                      />
                    </g>
                  </g>
                  <g clip-path="url(#cb-1)">
                    <g clip-path="url(#cc-6)">
                      <image
                        transform="matrix(.48 0 0 -.48 807.22 55.918)"
                        width="20"
                        height="10"
                        overflow="visible"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        xlink:href="data:image/jpeg;base64,/9j/4AAQSkZJRgABAgEAlgCWAAD/7AARRHVja3kAAQAEAAAAHgAA/+4AIUFkb2JlAGTAAAAAAQMA EAMCAwYAAAGbAAABtAAAAfT/2wCEABALCwsMCxAMDBAXDw0PFxsUEBAUGx8XFxcXFx8eFxoaGhoX Hh4jJSclIx4vLzMzLy9AQEBAQEBAQEBAQEBAQEABEQ8PERMRFRISFRQRFBEUGhQWFhQaJhoaHBoa JjAjHh4eHiMwKy4nJycuKzU1MDA1NUBAP0BAQEBAQEBAQEBAQP/CABEIAA0AFQMBIgACEQEDEQH/ xACEAAADAQEAAAAAAAAAAAAAAAAAAwQBBgEBAQAAAAAAAAAAAAAAAAAAAgMQAAEDBAMAAAAAAAAA AAAAAAIBERIAAwQUEyMkEQACAQIHAQAAAAAAAAAAAAAAERIiEzFBYYHBQoKiEgABAwEJAAAAAAAA AAAAAAACAAERMVFhsSKCogMzNP/aAAwDAQACEQMRAAAA7xeyQVQokv/aAAgBAgABBQDIW/Fsxv/a AAgBAwABBQAIP11//9oACAEBAAEFADuRU8sQraTgvQc9V/Pqf//aAAgBAgIGPwCOARcrSdVHz3d0 4Qv/2gAIAQMCBj8AzOte1f/aAAgBAQEGPwBJmD3L0c0mVS8rkqu/J3ty0kz/2Q=="
                      />
                    </g>
                  </g>
                  <g clip-path="url(#ce-2)">
                    <g clip-path="url(#cf-2)">
                      <image
                        transform="matrix(.48 0 0 -.48 824.37 58.064)"
                        width="24"
                        height="9"
                        overflow="visible"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        xlink:href="data:image/jpeg;base64,/9j/4AAQSkZJRgABAgEAlgCWAAD/7AARRHVja3kAAQAEAAAAHgAA/+4AIUFkb2JlAGTAAAAAAQMA EAMCAwYAAAGZAAABsQAAAfL/2wCEABALCwsMCxAMDBAXDw0PFxsUEBAUGx8XFxcXFx8eFxoaGhoX Hh4jJSclIx4vLzMzLy9AQEBAQEBAQEBAQEBAQEABEQ8PERMRFRISFRQRFBEUGhQWFhQaJhoaHBoa JjAjHh4eHiMwKy4nJycuKzU1MDA1NUBAP0BAQEBAQEBAQEBAQP/CABEIAAoAGQMBIgACEQEDEQH/ xACEAAABBQAAAAAAAAAAAAAAAAABAAIDBAYBAQEAAAAAAAAAAAAAAAAAAAMFEAABBAEFAAAAAAAA AAAAAAADAAECBBAhMUESFBEAAQMEAwAAAAAAAAAAAAAAAQAQESFBAhIxcYESAAECBAcBAAAAAAAA AAAAAAERAgAhgRIQMUFRwQMTBP/aAAwDAQACEQMRAAAA2gtqY7ZAaQf/2gAIAQIAAQUAZienj//a AAgBAwABBQDTov/aAAgBAQABBQC1YMMozWZIUpSZS3ZNj//aAAgBAgIGPwB9zvrsukA0+dCDlSNe Y//aAAgBAwIGPwAIOtU3nh//2gAIAQEBBj8A1wMCAeFXKnQR2Mw1vVZ//9k="
                      />
                    </g>
                  </g>
                  <g clip-path="url(#ch-3)">
                    <g clip-path="url(#ci-8)">
                      <image
                        transform="matrix(.48 0 0 -.48 816.79 50.547)"
                        width="12"
                        height="19"
                        overflow="visible"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        xlink:href="data:image/jpeg;base64,/9j/4AAQSkZJRgABAgEAlgCWAAD/7AARRHVja3kAAQAEAAAAHgAA/+4AIUFkb2JlAGTAAAAAAQMA EAMCAwYAAAGeAAABuQAAAgj/2wCEABALCwsMCxAMDBAXDw0PFxsUEBAUGx8XFxcXFx8eFxoaGhoX Hh4jJSclIx4vLzMzLy9AQEBAQEBAQEBAQEBAQEABEQ8PERMRFRISFRQRFBEUGhQWFhQaJhoaHBoa JjAjHh4eHiMwKy4nJycuKzU1MDA1NUBAP0BAQEBAQEBAQEBAQP/CABEIABUADwMBIgACEQEDEQH/ xACHAAACAwAAAAAAAAAAAAAAAAAAAgEDBgEAAwEAAAAAAAAAAAAAAAAAAgMEBRAAAQMEAwEAAAAA AAAAAAAAAwERBAISExQAQjQFEQABBAEDBQAAAAAAAAAAAAARAAECEiIhMQNhcYGxghIAAQMDBQEA AAAAAAAAAAAAEQABAjGBEkFRcSIDM//aAAwDAQACEQMRAAAA25C57bkCgf/aAAgBAgABBQBKiJ9B W2f/2gAIAQMAAQUAZMXT/9oACAEBAAEFAJZiC4ExK401Hpj+ORZaHFrf/9oACAECAgY/AMXlLCXm 7tE9Two/M4PvnbQL/9oACAEDAgY/ACGJqnrWy//aAAgBAQEGPwCNHBJ0U+ST5RsH7Mm8rk+vSzI6 KdbUyJB2X//Z"
                      />
                    </g>
                  </g>
                  <g clip-path="url(#ck-7)">
                    <g clip-path="url(#cl-9)">
                      <image
                        transform="matrix(.48 0 0 -.48 819.54 68.173)"
                        width="12"
                        height="20"
                        overflow="visible"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        xlink:href="data:image/jpeg;base64,/9j/4AAQSkZJRgABAgEAlgCWAAD/7AARRHVja3kAAQAEAAAAHgAA/+4AIUFkb2JlAGTAAAAAAQMA EAMCAwYAAAGiAAABuwAAAg//2wCEABALCwsMCxAMDBAXDw0PFxsUEBAUGx8XFxcXFx8eFxoaGhoX Hh4jJSclIx4vLzMzLy9AQEBAQEBAQEBAQEBAQEABEQ8PERMRFRISFRQRFBEUGhQWFhQaJhoaHBoa JjAjHh4eHiMwKy4nJycuKzU1MDA1NUBAP0BAQEBAQEBAQEBAQP/CABEIABUADwMBIgACEQEDEQH/ xACGAAEBAQEAAAAAAAAAAAAAAAAEAAMGAQADAQAAAAAAAAAAAAAAAAAAAQMEEAABBAMBAQAAAAAA AAAAAAACAREDBBITFCIkEQABBAEDBQAAAAAAAAAAAAARAAECEkFRgaGxwWJyExIAAQMDAwUAAAAA AAAAAAAAAQARAiFBEjGBkTKCAxME/9oADAMBAAIRAxEAAADttxLxVOihf//aAAgBAgABBQCrKRWK +PT/AP/aAAgBAwABBQAxRAJ8P//aAAgBAQABBQCxPJHNkuu8rTv81zRt88//2gAIAQICBj8A+iEi SITo9gvO3pej4ZZ9704X/9oACAEDAgY/AIEXCj1b6bL/2gAIAQEBBj8AjCLiLxPLq2anhR9W6uj4 dk30tarCoAL6rNabgL//2Q=="
                      />
                    </g>
                  </g>
                  <g clip-path="url(#cn-7)">
                    <g clip-path="url(#co-2)">
                      <image
                        transform="matrix(.48 0 0 -.48 807.22 58.606)"
                        width="20"
                        height="10"
                        overflow="visible"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        xlink:href="data:image/jpeg;base64,/9j/4AAQSkZJRgABAgEAlgCWAAD/7AARRHVja3kAAQAEAAAAHgAA/+4AIUFkb2JlAGTAAAAAAQMA EAMCAwYAAAGdAAABtwAAAfv/2wCEABALCwsMCxAMDBAXDw0PFxsUEBAUGx8XFxcXFx8eFxoaGhoX Hh4jJSclIx4vLzMzLy9AQEBAQEBAQEBAQEBAQEABEQ8PERMRFRISFRQRFBEUGhQWFhQaJhoaHBoa JjAjHh4eHiMwKy4nJycuKzU1MDA1NUBAP0BAQEBAQEBAQEBAQP/CABEIAA0AFQMBIgACEQEDEQH/ xACFAAACAwAAAAAAAAAAAAAAAAAAAwECBgEAAwEAAAAAAAAAAAAAAAAAAAEDBBAAAAYCAwAAAAAA AAAAAAAAAAERAxMEAhQSJCURAAIABQUAAAAAAAAAAAAAAAARATFBEhMhYaHBIxIAAQIFBQEAAAAA AAAAAAAAAQAxEQISMhNBsSKiM4P/2gAMAwEAAhEDEQAAAN0mysdHEDP/2gAIAQIAAQUAeK6Z8Laf /9oACAEDAAEFAMYkVsf/2gAIAQEAAQUAdfJvI7yDaOCxqyeeOrr/AP/aAAgBAgIGPwA4sMsukSY7 K6Twp+0XZl//2gAIAQMCBj8A5VEpjd1X/9oACAEBAQY/ALTSEDKoTSPV3Kj6K8lcT3mf/9k="
                      />
                    </g>
                  </g>
                  <g clip-path="url(#cq-4)">
                    <g clip-path="url(#cr-0)">
                      <image
                        transform="matrix(.48 0 0 -.48 824.37 55.856)"
                        width="24"
                        height="10"
                        overflow="visible"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        xlink:href="data:image/jpeg;base64,/9j/4AAQSkZJRgABAgEAlgCWAAD/7AARRHVja3kAAQAEAAAAHgAA/+4AIUFkb2JlAGTAAAAAAQMA EAMCAwYAAAGfAAABugAAAgX/2wCEABALCwsMCxAMDBAXDw0PFxsUEBAUGx8XFxcXFx8eFxoaGhoX Hh4jJSclIx4vLzMzLy9AQEBAQEBAQEBAQEBAQEABEQ8PERMRFRISFRQRFBEUGhQWFhQaJhoaHBoa JjAjHh4eHiMwKy4nJycuKzU1MDA1NUBAP0BAQEBAQEBAQEBAQP/CABEIAA0AGQMBIgACEQEDEQH/ xACFAAEBAQAAAAAAAAAAAAAAAAADAgYBAAMBAAAAAAAAAAAAAAAAAAIDBAUQAAEDBQEAAAAAAAAA AAAAAAMAAgQBERITFBURAAEDAwQDAAAAAAAAAAAAAAEAERIQIQIxUYGhEyMzEgABAwIGAwAAAAAA AAAAAAABABECIgMhQVGRsRITIzP/2gAMAwEAAhEDEQAAANsdzntdiWoP/9oACAECAAEFACCnuLgb T//aAAgBAwABBQCjhY3bl//aAAgBAQABBQCRLqEnoOUc9TNUnm2t5EHXZf/aAAgBAgIGPwCRhejC 2ZUhgcNl08ntb6MOF//aAAgBAwIGPwAPFzmnanRf/9oACAEBAQY/AIDF7O7r59ok4xIp7ZSYabKz o+Pmn//Z"
                      />
                    </g>
                  </g>
                  <g clip-path="url(#ct-8)">
                    <g clip-path="url(#cu-3)">
                      <image
                        transform="matrix(.48 0 0 -.48 821.16 51.838)"
                        width="16"
                        height="18"
                        overflow="visible"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        xlink:href="data:image/jpeg;base64,/9j/4AAQSkZJRgABAgEAlgCWAAD/7AARRHVja3kAAQAEAAAAHgAA/+4AIUFkb2JlAGTAAAAAAQMA EAMCAwYAAAGXAAABtAAAAfX/2wCEABALCwsMCxAMDBAXDw0PFxsUEBAUGx8XFxcXFx8eFxoaGhoX Hh4jJSclIx4vLzMzLy9AQEBAQEBAQEBAQEBAQEABEQ8PERMRFRISFRQRFBEUGhQWFhQaJhoaHBoa JjAjHh4eHiMwKy4nJycuKzU1MDA1NUBAP0BAQEBAQEBAQEBAQP/CABEIABUAEQMBIgACEQEDEQH/ xAB8AAEBAQEBAAAAAAAAAAAAAAAAAwEFBgEAAwEAAAAAAAAAAAAAAAAAAAEDBRAAAgMAAwAAAAAA AAAAAAAAAQMAAgQQEhMRAAEEAgMAAAAAAAAAAAAAABEAAUECEBJRwSISAAIBBAMAAAAAAAAAAAAA AAECIRAgMQMR0SL/2gAMAwEAAhEDEQAAAPbYjlX6I1YSwRYM/9oACAECAAEFAHHdTRDx/9oACAED AAEFAKhRpz//2gAIAQEAAQUAfsKmJ2ljJoUL3Qo00S/n2r5dp//aAAgBAgIGPwBeHJ0vtEBRCk4x Tun/2gAIAQMCBj8AMegtn//aAAgBAQEGPwB6aFmkqtNAZOHeVTg9Y9EpgTGP/9k="
                      />
                    </g>
                  </g>
                  <g clip-path="url(#cw-0)">
                    <g clip-path="url(#cx-7)">
                      <image
                        transform="matrix(.48 0 0 -.48 812.29 66.464)"
                        width="16"
                        height="19"
                        overflow="visible"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        xlink:href="data:image/jpeg;base64,/9j/4AAQSkZJRgABAgEAlgCWAAD/7AARRHVja3kAAQAEAAAAHgAA/+4AIUFkb2JlAGTAAAAAAQMA EAMCAwYAAAGnAAABxAAAAhP/2wCEABALCwsMCxAMDBAXDw0PFxsUEBAUGx8XFxcXFx8eFxoaGhoX Hh4jJSclIx4vLzMzLy9AQEBAQEBAQEBAQEBAQEABEQ8PERMRFRISFRQRFBEUGhQWFhQaJhoaHBoa JjAjHh4eHiMwKy4nJycuKzU1MDA1NUBAP0BAQEBAQEBAQEBAQP/CABEIABUAEQMBIgACEQEDEQH/ xACGAAEAAgMAAAAAAAAAAAAAAAAAAgQBBQYBAQEBAAAAAAAAAAAAAAAAAAQDBRAAAQQDAQEAAAAA AAAAAAAAAgABEgMRBBQTBREAAQMDBQEAAAAAAAAAAAAAEQEhEgAQAkFRYcETIxIAAAQEBwAAAAAA AAAAAAAAEAERAjFREhNhkSIyA0MU/9oADAMBAAIRAxEAAADus05ArfD5UoGMjYDZP//aAAgBAgAB BQAPoA96sh1L/9oACAEDAAEFAHqeKbMF/9oACAEBAAEFADuiY2k5LcJx2Na7Ni2+b0o5fZf/2gAI AQICBj8AfwP0m15sacwNfEt0o13I5VB//9oACAEDAgY/ACcUlDs24IH/2gAIAQEBBj8AggPNIjPZ RslY4rqtvpOYeI7rGHpIsQLf/9k="
                      />
                    </g>
                  </g>
                  <g clip-path="url(#cz-5)">
                    <g clip-path="url(#cA-3)">
                      <image
                        transform="matrix(.48 0 0 -.48 808.93 53.753)"
                        width="20"
                        height="14"
                        overflow="visible"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        xlink:href="data:image/jpeg;base64,/9j/4AAQSkZJRgABAgEAlgCWAAD/7AARRHVja3kAAQAEAAAAHgAA/+4AIUFkb2JlAGTAAAAAAQMA EAMCAwYAAAGgAAABvQAAAgf/2wCEABALCwsMCxAMDBAXDw0PFxsUEBAUGx8XFxcXFx8eFxoaGhoX Hh4jJSclIx4vLzMzLy9AQEBAQEBAQEBAQEBAQEABEQ8PERMRFRISFRQRFBEUGhQWFhQaJhoaHBoa JjAjHh4eHiMwKy4nJycuKzU1MDA1NUBAP0BAQEBAQEBAQEBAQP/CABEIABEAFwMBIgACEQEDEQH/ xACAAAEBAQEBAAAAAAAAAAAAAAAABAMCBgEAAwEAAAAAAAAAAAAAAAAAAAMEAhAAAgIDAQEAAAAA AAAAAAAAAQMCBAAQExEUEQACAgEFAQAAAAAAAAAAAAABEQACISAxcRIDMhIAAQIGAwAAAAAAAAAA AAAAEQECABAxEiITcUIj/9oADAMBAAIRAxEAAAD3uOkUjKnDBWLlgH//2gAIAQIAAQUAuvmpXR/w 6//aAAgBAwABBQBcQT5Hrr//2gAIAQEAAQUAZMwxlowCrBYhwkcamxI11MjV3//aAAgBAgIGPwDz a5z3UtQiL8t2s0yPE//aAAgBAwIGPwDJURIHUz//2gAIAQEBBj8AGG5sHL+pABo8cBwINOfBnpS1 SLHshyNH/9k="
                      />
                    </g>
                  </g>
                  <g clip-path="url(#cC-7)">
                    <g clip-path="url(#cD-8)">
                      <image
                        transform="matrix(.48 0 0 -.48 823.08 62.628)"
                        width="20"
                        height="15"
                        overflow="visible"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        xlink:href="data:image/jpeg;base64,/9j/4AAQSkZJRgABAgEAlgCWAAD/7AARRHVja3kAAQAEAAAAHgAA/+4AIUFkb2JlAGTAAAAAAQMA EAMCAwYAAAGUAAABsAAAAe//2wCEABALCwsMCxAMDBAXDw0PFxsUEBAUGx8XFxcXFx8eFxoaGhoX Hh4jJSclIx4vLzMzLy9AQEBAQEBAQEBAQEBAQEABEQ8PERMRFRISFRQRFBEUGhQWFhQaJhoaHBoa JjAjHh4eHiMwKy4nJycuKzU1MDA1NUBAP0BAQEBAQEBAQEBAQP/CABEIABEAFQMBIgACEQEDEQH/ xAB5AAEAAgMAAAAAAAAAAAAAAAAAAQMCBAYBAQEBAAAAAAAAAAAAAAAAAAAEBRAAAgMBAQEAAAAA AAAAAAAAAQMQAgQSERMRAQACAgMBAAAAAAAAAAAAAAEAEUECIDESoRIAAgIDAQAAAAAAAAAAAAAA AQIgAxEhQRL/2gAMAwEAAhEDEQAAAO0iuzIo2hrz5gA//9oACAECAAEFAFW1s2T/AP/aAAgBAwAB BQAhYXP/2gAIAQEAAQUA0bLqavY256PGtTLPSq1R4flP/9oACAECAgY/ALF9utSWNzmdQ//aAAgB AwIGPwAHAJIh/9oACAEBAQY/AHQ1EK7leT7PWauKaqIUhLRuVmuH/9k="
                      />
                    </g>
                  </g>
                </g>
                <path
                  d="m814.01 379.51c-1.625 3.0818-4.8566 5.185-8.5857 5.185-5.0324 0-9.1683-3.8277-9.659-8.7297-0.25312 0.0471-0.51687 0.0749-0.78351 0.0749-2.3225 0-4.2045-1.881-4.2045-4.2016 0-2.3206 1.881-4.2035 4.2045-4.2035 1.1419 0 21.722-9.6e-4 21.722-9.6e-4 2.1534 0 3.4683 3.078 3.4683 5.1222 9.6e-4 3.5359-2.7051 6.439-6.1618 6.754"
                  fill="#bdd4df"
                />
                <g fill="#7acdef">
                  <path transform="matrix(.9661 0 0 -.9661 22.942 433.74)" d="m806.16 69.527-0.504 0.863-1.728-1.009 0.506-0.862" />
                  <path transform="matrix(.50412 .86363 .86363 -.50412 0 0)" d="m715.93 505.68h1.9234v1.9321h-1.9234z" />
                  <path transform="matrix(.9661 0 0 -.9661 22.942 433.74)" d="m802.64 75.55-0.504 0.864-1.727-1.009 0.504-0.863" />
                  <path transform="matrix(.9661 0 0 -.9661 22.942 433.74)" d="m811.66 69.527-0.504 0.864-1.726-1.008 0.504-0.864" />
                  <path transform="matrix(.504 .8637 .8637 -.504 0 0)" d="m718.56 510.36h1.9245v1.9332h-1.9245z" />
                  <path transform="matrix(.9661 0 0 -.9661 22.942 433.74)" d="m808.14 75.549-0.504 0.865-1.727-1.008 0.504-0.864" />
                  <path transform="matrix(.9661 0 0 -.9661 22.942 433.74)" d="m817.59 69.527-0.504 0.864-1.729-1.008 0.504-0.864" />
                  <path transform="matrix(.504 .8637 .8637 -.504 0 0)" d="m721.45 515.31h1.9245v1.9312h-1.9245z" />
                  <path transform="matrix(.9661 0 0 -.9661 22.942 433.74)" d="m814.07 75.549-0.504 0.865-1.728-1.008 0.504-0.864" />
                </g>
              </g>
            </g>
            <path
              transform="matrix(-2.3751 -1.0035 -1.0035 2.3751 7005.3 1332.9)"
              d="m2675 576.83c0.212 9e-3 0.4214 0.0369 0.6263 0.0831 1.1219 0.25233 2.0997 1.047 2.534 2.2032 0.1707 0.45401 0.2428 0.92451 0.2237 1.383l-0.3448-0.0141c0.017-0.41312-0.048-0.83692-0.2018-1.2475-0.3917-1.0436-1.2735-1.7596-2.2869-1.9883-0.1845-0.0413-0.3739-0.0666-0.5649-0.0748l0.015-0.34453zm0.4483-1.8961c0.4002-0.0144 0.7968 0.0236 1.181 0.10986 1.4673 0.33012 2.7475 1.3685 3.3161 2.8804 0.3257 0.86943 0.3763 1.774 0.1863 2.6171l-0.017 0.0733-0.3357-0.0809 0.015-0.0681c0.1752-0.77931 0.1295-1.614-0.1728-2.4203-0.5258-1.3986-1.7092-2.3586-3.0688-2.6649-0.355-0.0797-0.7222-0.11511-1.0926-0.10181l-0.012-0.34472zm0.4777-3.1861c0.4751 0.0188 0.9447 0.0812 1.4035 0.18418 2.4824 0.55878 4.6448 2.3168 5.6062 4.8746 0.4941 1.3126 0.6111 2.6753 0.4045 3.9654l-0.3406-0.0545c0.1971-1.2328 0.086-2.5334-0.387-3.789-0.9193-2.4455-2.9849-4.125-5.3592-4.6598-0.4381-0.0985-0.8871-0.15802-1.3411-0.17648l0.014-0.34437"
              display="inline"
              fill="#231f20"
              filter="url(#ap-1)"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width=".41534"
            />
            <g transform="matrix(.040356 0 0 .097953 -92.568 -301.26)" display="inline" enable-background="new" fill="#fff">
              <g stroke-width="14.592">
                <ellipse cx="3989.4" cy="3589.7" rx="104.91" ry="34.018" fill-opacity=".99216" stroke-opacity=".74902" />
                <path
                  d="m4092.4 4834.7a104.91 34.018 0 0 1-52.457 29.46 104.91 34.018 0 0 1-104.91 0 104.91 34.018 0 0 1-52.457-29.46"
                  fill-opacity=".99216"
                  stroke-opacity=".74902"
                />
                <path d="m3880.3 4830.4 4.716-1249.8" />
                <path d="m4087.8 4834.2 4.716-1249.8" />
              </g>
              <path
                transform="matrix(.9375 0 0 .9375 0 -.00282)"
                d="m4213.4 5187.7c-32.902-5.9673-55.59-14.11-63.42-22.762-8.0371-8.8809-8.4033-51.384-5.6987-661.34 1.5898-358.52 3.8621-652.82 5.0497-654.01s10.996 1.7963 21.796 6.6309c28.247 12.644 141.82 12.644 170.06 0 10.8-4.8346 19.99-8.1945 20.422-7.4665s-0.1697 297.54-1.3368 659.59l-2.1219 658.26-21.586 9.5487c-21.433 9.4809-96.016 16.476-123.17 11.552z"
                fill-opacity=".99216"
                stroke-opacity=".74902"
                stroke-width="15.565"
              />
            </g>
            <path d="m80.228 13.805h43.706" display="inline" fill="none" stroke-width=".52026" />
            <path d="m123.57 13.83 8.0558 60.458m-58.46-4.2646 6.6974-56.193" display="inline" stroke-width=".59714" />
            <ellipse
              cx="101.82"
              cy="13.019"
              rx="22.129"
              ry="7.1751"
              display="inline"
              fill="#fff"
              fill-opacity=".99216"
              stroke-opacity=".74902"
              stroke-width=".49277"
            />
          </g>
          <g fill="none">
            <path
              d="m123.95 17.207a22.129 7.1751 0 0 1-11.296 6.2565 22.129 7.1751 0 0 1-22.355-0.13077 22.129 7.1751 0 0 1-10.592-6.3846"
              display="inline"
              stroke="#000"
              stroke-opacity=".74902"
              stroke-width=".49277"
            />
            <path
              d="m85.093 21.681 14.317 20.532 2.8016 0.38004 2.748-0.26157 13.217-20.414"
              display="inline"
              stroke="#4d4d4d"
              stroke-dasharray="1.04053, 3.12158"
              stroke-width=".52026"
            />
            <path
              d="m64.162 61.852-0.0147 8.4455m8.8176-0.17036 1.8016-16.735-3.1156-0.07128"
              display="inline"
              stroke="#000"
              stroke-width=".51516"
            />
            <path
              d="m74.55 56.536c0.0055-0.43194 0.06378-0.57677 0.13037-0.32238 0.0664 0.25476 0.0617 0.60806-0.01105 0.78524-0.07176 0.17822-0.12591-0.03144-0.12056-0.46286z"
              display="inline"
              stroke="#000"
              stroke-width="1.8492"
            />
          </g>
          <g fill-opacity=".99216" stroke="#000">
            <path
              d="m72.759 53.572 0.90827 0.06081c0.67136 0.04193 1.2349 0.03145 1.2491 0.04193m-10.476 15.985c-0.45793-1.1627 0.10227-0.85904 0.17178-2.4646l0.0542-4.4896 0.2948 0.26524c0.63622 0.57242 1.3886 0.9848 2.2423 1.2296 0.67027 0.1908 1.8401 0.23903 2.515 0.10431 1.3955-0.28306 2.4052-1.0064 2.9524-2.11 1.6752-2.8777 0.84138 5.7734 0.48545 7.8287"
              display="inline"
              fill="#e6e6e6"
              stroke-width="1.8492"
            />
            <path
              d="m96.917 24.012c-2.4633-0.19091-4.3066-0.42758-6.5429-0.84324-1.8022-0.3347-4.9062-1.165-5.3188-1.4223-0.12168-0.07638-0.3617-0.16035-0.53312-0.18583-0.5896-0.09175-2.9276-1.4096-3.6879-2.079-0.41878-0.36856-0.86157-0.8677-0.98414-1.1095-0.31105-0.61317-0.46563-1.841-0.3348-2.6593l0.1104-1.8435 0.80064 1.2144c2.4753 2.2253 8.381 3.9289 15.655 4.5965 2.4675 0.2265 9.8544 0.55588 12.322 0.32961 7.0766-1.1438 11.81-2.0349 14.207-4.1452l0.94212-1.6733-0.0493 2.2195c0.0932 0.99418 0.0597 1.3477-0.17325 1.8392-1.1534 2.4307-6.8789 4.6192-14.413 5.509-2.6997 0.31892-9.3361 0.45914-12 0.25455z"
              display="inline"
              stroke-opacity=".74902"
              stroke-width=".16666"
            />
            <ellipse
              cx="101.99"
              cy="12.937"
              rx="20.991"
              ry="6.1285"
              display="inline"
              fill="#fff"
              stroke-opacity=".74902"
              stroke-width=".44355"
            />
          </g>
          <g fill="none" stroke="#000">
            <path
              d="m131.64 74.32a33.681 6.0693 0 0 1-16.84 5.2561 33.681 6.0693 0 0 1-33.681 0 33.681 6.0693 0 0 1-16.84-5.2561"
              display="inline"
              stroke-opacity=".74902"
              stroke-width=".55912"
            />
            <path
              d="m130.17 66.286a32.979 6.0733 0 0 1-32.8 6.0733 32.979 6.0733 0 0 1-33.155-6.0076"
              display="inline"
              stroke-opacity=".74902"
              stroke-width=".55345"
            />
            <path d="m73.514 70.13v8.2391" display="inline" stroke-width=".32198" />
          </g>
          <path
            d="m110.82 55.347v1.095c-0.0731 0.20339-0.011 0.48435-0.0994 0.69712-0.41149 3.5843-3.4781 6.8402-7.036 7.4674-0.36169 0.14258-0.78987 0.13996-1.1615 0.19867h-1.3607c-0.37176-0.06081-0.79989-0.05608-1.1616-0.19867-3.8366-0.66048-6.9613-4.3575-7.1355-8.1976v-1.1283c0.12166-0.75006 0.2762-1.53 0.57248-2.2469 0.30005-0.57729 0.60902-1.1515 0.96246-1.6926l1.1284-1.5267c2.524-3.0002 4.7343-6.2227 6.3971-9.7342l0.45803 0.97075c1.374 3.3471 3.5412 6.3108 5.9076 9.0953 0.25553 0.37532 0.531 0.74016 0.8297 1.0952 0.44473 0.68365 0.87949 1.374 1.228 2.1241 0.23903 0.63401 0.38833 1.3177 0.4713 1.9815z"
            display="inline"
            stroke="#000"
            stroke-width=".033188"
          />
          <g fill="#e7e7e7" fill-opacity=".99216" stroke="#000">
            <path
              d="m72.741 64.567c-0.02046-1.6962 0.25019-3.2129 0.48676-3.8719-0.24359-0.98527 0.09528-0.21649-0.19752-1.1515-0.22933-0.73177-0.31556-1.6867-0.31514-3.4875v-2.4801h0.95594c0.52581 0 0.956 0.04718 0.956 0.1017 0 0.19343-0.98762 12.591-1.0555 12.884-0.03774 0.16302-0.81611-0.75038-0.83044-1.9934z"
              display="inline"
              stroke-width="1.8492"
            />
            <path
              d="m70.274 69.488c-2.6109-0.69764-4.3048-1.3816-5.11-2.0635l-0.4779-0.4052v-4.443l0.4779 0.46234c0.26288 0.25476 0.87496 0.64753 1.3603 0.8743 0.78195 0.36536 1.0495 0.41254 2.3528 0.41254 1.3731 0 1.5265-0.03145 2.316-0.46391l0.84554-0.46391v3.221c0 3.7138 0.19699 3.3931-1.7646 2.8691z"
              display="inline"
              stroke-width="1.8492"
            />
            <path
              d="m64.096 74.902v-7.6465c3.1861 1.8135 4.9017 2.3037 9.1171 3.235l0.29407 7.7936c-3.8723-0.53908-6.7153-1.2255-9.4112-3.3821z"
              display="inline"
              stroke-width=".32198"
            />
          </g>
          <g fill-opacity=".99216">
            <path
              d="m89.023 79.839c-4.7879-0.26104-9.7846-0.75976-13.274-1.3248-0.99253-0.1604-1.9621-0.2925-2.0008-0.2925-0.03883 0-0.02833-1.6211-0.02833-3.6026 0-3.3333-0.3379-3.7288-0.09987-3.7286 0.14148 2.11e-4 1.2513 0.22383 1.9269 0.3423 1.7285 0.30403 5.909 0.77606 9.2856 1.0481 3.9161 0.31556 16.089 0.44294 20.606 0.21648 9.6628-0.48592 17.537-1.6919 21.691-3.3226 1.3602-0.53399 2.5932-1.2637 2.9174-1.7267 0.20538-0.29302 0.20921-0.2925 0.29045 0.05609 0.0451 0.19342 0.14311 0.88143 0.21739 1.5284 0.0743 0.64701 0.27201 2.1584 0.43954 3.3585 0.25428 1.8211 0.27267 2.2279 0.1117 2.4577-0.80414 1.1482-3.919 2.3437-8.3737 3.2142-3.6766 0.71825-7.4672 1.1825-13.416 1.6427-2.5701 0.19867-17.319 0.2967-20.293 0.13472z"
              display="inline"
              fill="#a5a7a8"
              stroke="#a5a7a8"
              stroke-opacity=".99216"
              stroke-width=".23528"
            />
            <path
              transform="matrix(.9579 -.28711 .4355 .90019 0 0)"
              d="m40.172 78.651a4.3739 4.0836 0 0 1-4.3116 3.786 4.3739 4.0836 0 0 1-4.4044-3.6917"
              display="inline"
              fill="#fff"
              stroke="#000"
              stroke-opacity=".74902"
              stroke-width=".53049"
            />
            <path
              transform="matrix(.94706 -.32106 .39327 .91942 0 0)"
              d="m43.418 79.4a3.9284 4.0156 0 0 1-4.3778 3.2238 3.9284 4.0156 0 0 1-3.3973-4.2847"
              display="inline"
              fill="#fff"
              stroke="#000"
              stroke-opacity=".74902"
              stroke-width=".32246"
            />
          </g>
        </g>
        <text
          x="6.7672915"
          y="75.810165"
          display="inline"
          fill="#000000"
          font-family="sans-serif"
          font-size="10.583px"
          letter-spacing="0px"
          stroke-width=".26458"
          word-spacing="0px"
          style="line-height: 1.25"
          xml:space="preserve"
        >
          <tspan x="6.7672915" y="75.810165" font-size="3.5278px" stroke-width=".26458">{{ ctx?.schema[1]['since_date'] }}</tspan>
        </text>
        <g transform="translate(-9.9702 -3.6585)" display="inline">
          <path
            d="m20.152 67.95s2.1354 3.6679 3.8821 3.8821 4.3498 0 4.3498 0"
            fill="none"
            marker-start="url(#marker3227-7)"
            stroke="#000"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width=".26458px"
          />
          <circle cx="27.268" cy="68.078" r="3.175" fill="#43b05c" stroke-width=".127" />
          <line
            x1="27.268"
            x2="27.268"
            y1="66.554"
            y2="69.729"
            fill="none"
            stroke="#fff"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-miterlimit="10"
            stroke-width=".254"
          />
          <line
            x1="28.856"
            x2="25.681"
            y1="68.078"
            y2="68.078"
            fill="none"
            stroke="#fff"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-miterlimit="10"
            stroke-width=".254"
          />
        </g>
        <g
          transform="translate(-42.091 -188.24)"
          fill="none"
          stroke="#f00"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width=".965"
        >
          <path d="m43.779 233.3 23.76 23.76" />
          <path d="m67.539 233.3-23.76 23.76" />
        </g>
      </g>
      <!-- #endregion -->

      <!-- #region Schema 4 -->
      <g *ngIf="ctx?.schema[0] === 'takeout_rg_keep_data'" id="Remove RG" transform="translate(0 -39.688)" display="">
        <g transform="translate(0 -3.7042)" fill="none" stroke="#000">
          <path transform="translate(0 -217.62)" d="m1.2095 290.45h103.23" marker-end="url(#Arrow2Lend)" stroke-width=".31553px" />
          <path transform="translate(0 -217.62)" d="m12.441 288.3v4.4901" stroke-width=".316" />
          <path d="m71.179 70.675v4.4901" stroke-width=".316" />
        </g>
        <g transform="translate(-5.5562 -4.3656)">
          <path
            d="m75.811 67.345s2.3669 3.2545 3.8821 3.8821 4.3498 0 4.3498 0"
            fill="none"
            marker-end="url(#Arrow2Mend)"
            stroke="#000"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width=".265"
          />
          <g transform="matrix(.012402 0 0 .012402 79.564 63.21)">
            <circle cx="256" cy="256" r="256" fill="#e04f5f" />
            <rect x="119.68" y="240" width="272" height="32" fill="#fff" />
          </g>
        </g>
        <g transform="translate(-8.9958 -5.2917)">
          <path
            d="m20.152 67.95s2.1354 3.6679 3.8821 3.8821 4.3498 0 4.3498 0"
            fill="none"
            marker-start="url(#marker3227)"
            stroke="#000"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width=".26458px"
          />
          <circle cx="27.268" cy="68.078" r="3.175" fill="#43b05c" stroke-width=".127" />
          <line
            x1="27.268"
            x2="27.268"
            y1="66.554"
            y2="69.729"
            fill="none"
            stroke="#fff"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-miterlimit="10"
            stroke-width=".254"
          />
          <line
            x1="28.856"
            x2="25.681"
            y1="68.078"
            y2="68.078"
            fill="none"
            stroke="#fff"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-miterlimit="10"
            stroke-width=".254"
          />
        </g>
        <text
          x="5.7147679"
          y="74.714325"
          display="inline"
          fill="#000000"
          font-family="sans-serif"
          font-size="10.583px"
          letter-spacing="0px"
          stroke-width=".26458"
          word-spacing="0px"
          style="line-height: 1.25"
          xml:space="preserve"
        >
          <tspan x="5.7147679" y="74.714325" font-size="3.5278px" stroke-width=".26458">{{ ctx?.schema[1]['since_date'] }}</tspan>
        </text>
        <text
          x="61.654278"
          y="75.211388"
          display="inline"
          fill="#000000"
          font-family="sans-serif"
          font-size="10.583px"
          letter-spacing="0px"
          stroke-width=".26458"
          word-spacing="0px"
          style="line-height: 1.25"
          xml:space="preserve"
        >
          <tspan x="61.654278" y="75.211388" font-size="3.5278px" stroke-width=".26458">{{ ctx?.schema[1]['takeout_date'] }}</tspan>
        </text>
        <g transform="matrix(.14986 0 0 .14986 -.95547 41.783)" display="inline" stroke="#000">
          <g stroke="#000">
            <g transform="matrix(1.0337 0 0 -1.0337 -610.4 318.17)" display="inline">
              <circle transform="scale(1,-1)" cx="729.88" cy="-221.54" r="24.025" fill="#fff" stroke="#000" stroke-width="1.125" />
              <g transform="translate(-77.909 -152.79)">
                <g transform="matrix(.9661 0 0 -.9661 22.942 433.74)">
                  <g clip-path="url(#bQ-6-0)">
                    <g clip-path="url(#bR-8-3)">
                      <image
                        transform="matrix(.48 0 0 -.48 813.57 61.76)"
                        width="32"
                        height="29"
                        overflow="visible"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        xlink:href="data:image/jpeg;base64,/9j/4AAQSkZJRgABAgEAlgCWAAD/7AARRHVja3kAAQAEAAAAHgAA/+4AIUFkb2JlAGTAAAAAAQMA EAMCAwYAAAHHAAAB/AAAAnb/2wCEABALCwsMCxAMDBAXDw0PFxsUEBAUGx8XFxcXFx8eFxoaGhoX Hh4jJSclIx4vLzMzLy9AQEBAQEBAQEBAQEBAQEABEQ8PERMRFRISFRQRFBEUGhQWFhQaJhoaHBoa JjAjHh4eHiMwKy4nJycuKzU1MDA1NUBAP0BAQEBAQEBAQEBAQP/CABEIAB8AIQMBIgACEQEDEQH/ xACMAAEBAQEBAQAAAAAAAAAAAAAEAAMFAQYBAAEFAAAAAAAAAAAAAAAAAAQAAQIFBhAAAgMAAgMB AAAAAAAAAAAAAgQAAQMTBRIyMxURAAIABAQHAQAAAAAAAAAAAAECABARMiFxkcExQWESQnKSAxIB AAICAgIDAAAAAAAAAAAAAQIDABEQITFREiIy/9oADAMBAAIRAxEAAAD7fHw+fKTvz1STqr4Xn4KN ni5GCZMyq/F//9oACAECAAEFAGm26aUabtuM4s04pkzbk//aAAgBAwABBQAMw8NACggEHHoQcc// 2gAIAQEAAQUAZaLE/wBA5+gcWZLe52H1ALKGFjOu9p2H1zPxmh+U672judnpwXOC4jnYXP/aAAgB AgIGPwCyqqxAloNGV1WzUd7EPTxZbXVOR8ukj14yu22ucT7bWPX5eP/aAAgBAwIGPwAUxlE4BTGM U4//2gAIAQEBBj8AChQaiuMWDWLBrDAqB204dZL67zfISX13n+mQkp7lGHkQOcXp9CL0+hD1ZWrS 0gy//9k="
                      />
                    </g>
                  </g>
                  <g clip-path="url(#bT-3-8)">
                    <g clip-path="url(#bU-3-4)">
                      <image
                        transform="matrix(.48 0 0 -.48 812.22 51.838)"
                        width="16"
                        height="18"
                        overflow="visible"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        xlink:href="data:image/jpeg;base64,/9j/4AAQSkZJRgABAgEAlgCWAAD/7AARRHVja3kAAQAEAAAAHgAA/+4AIUFkb2JlAGTAAAAAAQMA EAMCAwYAAAGkAAABvwAAAgr/2wCEABALCwsMCxAMDBAXDw0PFxsUEBAUGx8XFxcXFx8eFxoaGhoX Hh4jJSclIx4vLzMzLy9AQEBAQEBAQEBAQEBAQEABEQ8PERMRFRISFRQRFBEUGhQWFhQaJhoaHBoa JjAjHh4eHiMwKy4nJycuKzU1MDA1NUBAP0BAQEBAQEBAQEBAQP/CABEIABUAEQMBIgACEQEDEQH/ xACDAAEAAgMAAAAAAAAAAAAAAAAAAwQCBQYBAQEAAAAAAAAAAAAAAAAAAAMFEAABBAIDAQAAAAAA AAAAAAAAAQIDBBETEjIUNBEAAQMDBQAAAAAAAAAAAAAAEQAQEgFxAiExgSIDEgABBAIBBQAAAAAA AAAAAAABEDECEhEhAzJCgjMU/9oADAMBAAIRAxEAAADvIMqc5tqKIwVyet8UC//aAAgBAgABBQC9 ZkjmLWN5/9oACAEDAAEFAI2IrRnU/9oACAEBAAEFAJJeCsuq+Usrgr/SWNRD5Nx//9oACAECAgY/ AOGEJVvONtDYJTjz8zx9ubv24T//2gAIAQMCBj8AkSGGkPX4sn//2gAIAQEBBj8A2Kp5wBqCWrZY XbueFjCcjoQG/9k="
                      />
                    </g>
                  </g>
                  <g clip-path="url(#bW-1-8)">
                    <g clip-path="url(#bX-3-8)">
                      <image
                        transform="matrix(.48 0 0 -.48 821.22 65.921)"
                        width="16"
                        height="18"
                        overflow="visible"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        xlink:href="data:image/jpeg;base64,/9j/4AAQSkZJRgABAgEAlgCWAAD/7AARRHVja3kAAQAEAAAAHgAA/+4AIUFkb2JlAGTAAAAAAQMA EAMCAwYAAAGuAAAByAAAAhn/2wCEABALCwsMCxAMDBAXDw0PFxsUEBAUGx8XFxcXFx8eFxoaGhoX Hh4jJSclIx4vLzMzLy9AQEBAQEBAQEBAQEBAQEABEQ8PERMRFRISFRQRFBEUGhQWFhQaJhoaHBoa JjAjHh4eHiMwKy4nJycuKzU1MDA1NUBAP0BAQEBAQEBAQEBAQP/CABEIABUAEQMBIgACEQEDEQH/ xACMAAEBAAMBAAAAAAAAAAAAAAAABAECAwYBAQEBAAAAAAAAAAAAAAAAAAMEBRAAAQMDBQEAAAAA AAAAAAAABAACAwETFBARIRIzNBEAAgEEAgMAAAAAAAAAAAAAERIBABAhgUHBUXETEgABAwICCwAA AAAAAAAAAAABEBECMQMhQVFhgRIiMoITIzM0/9oADAMBAAIRAxEAAAD2+ZemU9w1Qj3IFpF5f//a AAgBAgABBQCAkh5yh2yl/9oACAEDAAEFAHMZSJO81//aAAgBAQABBQCcyzJCVdqjvoEpyi8XvBZ0 /9oACAECAgY/ALloz8cJS4d0UGtnS58zufW/c6sn0p//2gAIAQMCBj8AEmxIQc+2if/aAAgBAQEG PwBEbBJHVBBs9Wn1FRaPoz8r43ioRti3/9k="
                      />
                    </g>
                  </g>
                  <g clip-path="url(#bZ-0-3)">
                    <g clip-path="url(#b0-4-7)">
                      <image
                        transform="matrix(.48 0 0 -.48 808.99 62.691)"
                        width="20"
                        height="14"
                        overflow="visible"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        xlink:href="data:image/jpeg;base64,/9j/4AAQSkZJRgABAgEAlgCWAAD/7AARRHVja3kAAQAEAAAAHgAA/+4AIUFkb2JlAGTAAAAAAQMA EAMCAwYAAAGkAAABwAAAAg//2wCEABALCwsMCxAMDBAXDw0PFxsUEBAUGx8XFxcXFx8eFxoaGhoX Hh4jJSclIx4vLzMzLy9AQEBAQEBAQEBAQEBAQEABEQ8PERMRFRISFRQRFBEUGhQWFhQaJhoaHBoa JjAjHh4eHiMwKy4nJycuKzU1MDA1NUBAP0BAQEBAQEBAQEBAQP/CABEIABEAFwMBIgACEQEDEQH/ xACDAAEAAgMAAAAAAAAAAAAAAAAAAwQCBQYBAAIDAAAAAAAAAAAAAAAAAAADAgQFEAABBAMBAAAA AAAAAAAAAAADAAECBBASExQRAAEDAwUAAAAAAAAAAAAAAAEAEQJBgRIgUaEyAxIAAgIBBAMAAAAA AAAAAAAAAQIREgAQMUEDcSIT/9oADAMBAAIRAxEAAADu8Y62c7YoFpdoPiAf/9oACAECAAEFAD3T CN3fz4//2gAIAQMAAQUAiOMo6tvj/9oACAEBAAEFAClYaayzqJNoXRkmuFlAgVq2f//aAAgBAgIG PwBkHS/YixDKp5E59qNNbUj28Rr/AP/aAAgBAwIGPwAEsAcrPO+v/9oACAEBAQY/AA9X4VEZbKGE SWd2suhXpGQImcmFtH//2Q=="
                      />
                    </g>
                  </g>
                  <g clip-path="url(#b2-4-8)">
                    <g clip-path="url(#b3-5-3)">
                      <image
                        transform="matrix(.48 0 0 -.48 823.08 53.691)"
                        width="20"
                        height="15"
                        overflow="visible"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        xlink:href="data:image/jpeg;base64,/9j/4AAQSkZJRgABAgEAlgCWAAD/7AARRHVja3kAAQAEAAAAHgAA/+4AIUFkb2JlAGTAAAAAAQMA EAMCAwYAAAGoAAABwwAAAgn/2wCEABALCwsMCxAMDBAXDw0PFxsUEBAUGx8XFxcXFx8eFxoaGhoX Hh4jJSclIx4vLzMzLy9AQEBAQEBAQEBAQEBAQEABEQ8PERMRFRISFRQRFBEUGhQWFhQaJhoaHBoa JjAjHh4eHiMwKy4nJycuKzU1MDA1NUBAP0BAQEBAQEBAQEBAQP/CABEIABEAFQMBIgACEQEDEQH/ xACKAAEBAAMAAAAAAAAAAAAAAAAAAwEEBgEAAwEBAAAAAAAAAAAAAAAAAAMEAgUQAAEBBwUAAAAA AAAAAAAAAAMBABACEhMEFBEiIwUVEQABBAEFAQAAAAAAAAAAAAABABEhEgIgMUGRMhMSAAEBBgcA AAAAAAAAAAAAAAEhABARQQIDMaESIoKjRP/aAAwDAQACEQMRAAAA7WdYcl+4wtXYU4AP/9oACAEC AAEFADj7Gpzv/9oACAEDAAEFAIVDptf/AP/aAAgBAQABBQA95EInoRtVXHuATkW1VpFxn//aAAgB AgIGPwCs27mw1HSEQSbHz9j/AP/aAAgBAwIGPwARCzbnk/8A/9oACAEBAQY/ADgMQQGleB2vq01s yOTbqFTmraP/2Q=="
                      />
                    </g>
                  </g>
                  <g clip-path="url(#b5-8-6)">
                    <g clip-path="url(#b6-9-2)">
                      <image
                        transform="matrix(.48 0 0 -.48 819 50.547)"
                        width="12"
                        height="19"
                        overflow="visible"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        xlink:href="data:image/jpeg;base64,/9j/4AAQSkZJRgABAgEAlgCWAAD/7AARRHVja3kAAQAEAAAAHgAA/+4AIUFkb2JlAGTAAAAAAQMA EAMCAwYAAAGSAAABrAAAAfX/2wCEABALCwsMCxAMDBAXDw0PFxsUEBAUGx8XFxcXFx8eFxoaGhoX Hh4jJSclIx4vLzMzLy9AQEBAQEBAQEBAQEBAQEABEQ8PERMRFRISFRQRFBEUGhQWFhQaJhoaHBoa JjAjHh4eHiMwKy4nJycuKzU1MDA1NUBAP0BAQEBAQEBAQEBAQP/CABEIABUADwMBIgACEQEDEQH/ xAB9AAEBAQEAAAAAAAAAAAAAAAAAAgMGAQEBAAAAAAAAAAAAAAAAAAADBBAAAgIDAQAAAAAAAAAA AAAAAgMBBAAREhMRAAICAAQHAAAAAAAAAAAAAAERAAIhURIDYXGBkbEiExIAAgICAwAAAAAAAAAA AAAAAQIRIhAxURID/9oADAMBAAIRAxEAAADuJuJE2FZ//9oACAECAAEFAJBsXM//2gAIAQMAAQUA 2Pnn/9oACAEBAAEFALbWLFDmHWsDBQgNV284HPH/2gAIAQICBj8AV+7HyZDWaggcY//aAAgBAwIG PwCIsDvH/9oACAEBAQY/AK/PBtnlNzcsfaupFZBwA8ZuVzNvAg1PpLJrF9p//9k="
                      />
                    </g>
                  </g>
                  <g clip-path="url(#b8-4-8)">
                    <g clip-path="url(#b9-7-7)">
                      <image
                        transform="matrix(.48 0 0 -.48 816.85 68.173)"
                        width="12"
                        height="20"
                        overflow="visible"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        xlink:href="data:image/jpeg;base64,/9j/4AAQSkZJRgABAgEAlgCWAAD/7AARRHVja3kAAQAEAAAAHgAA/+4AIUFkb2JlAGTAAAAAAQMA EAMCAwYAAAGdAAABtwAAAgP/2wCEABALCwsMCxAMDBAXDw0PFxsUEBAUGx8XFxcXFx8eFxoaGhoX Hh4jJSclIx4vLzMzLy9AQEBAQEBAQEBAQEBAQEABEQ8PERMRFRISFRQRFBEUGhQWFhQaJhoaHBoa JjAjHh4eHiMwKy4nJycuKzU1MDA1NUBAP0BAQEBAQEBAQEBAQP/CABEIABUADwMBIgACEQEDEQH/ xACEAAACAwEAAAAAAAAAAAAAAAAAAgEDBAYBAQEBAAAAAAAAAAAAAAAAAAIDBBAAAgIDAQAAAAAA AAAAAAAAAgMBERITBBQRAAEEAgEFAAAAAAAAAAAAAAEAEQISMUFRcbHBAxMSAAAEBAcAAAAAAAAA AAAAAAABEQMxAhJCQVFxMsITM//aAAwDAQACEQMRAAAA7uc1udowRX//2gAIAQIAAQUAX0QT14+7 /9oACAEDAAEFAJChm9f/2gAIAQEAAQUAayQISmQ7ixNR3zdum1avL//aAAgBAgIGPwBxm5tE0MiD ngtOFXbAo2j/2gAIAQMCBj8AlmzBbuI//9oACAEBAQY/AIgbRkch16z1UpcA9lD6W21W8qVbUaVn zhf/2Q=="
                      />
                    </g>
                  </g>
                  <g clip-path="url(#cb-1-3)">
                    <g clip-path="url(#cc-6-9)">
                      <image
                        transform="matrix(.48 0 0 -.48 807.22 55.918)"
                        width="20"
                        height="10"
                        overflow="visible"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        xlink:href="data:image/jpeg;base64,/9j/4AAQSkZJRgABAgEAlgCWAAD/7AARRHVja3kAAQAEAAAAHgAA/+4AIUFkb2JlAGTAAAAAAQMA EAMCAwYAAAGbAAABtAAAAfT/2wCEABALCwsMCxAMDBAXDw0PFxsUEBAUGx8XFxcXFx8eFxoaGhoX Hh4jJSclIx4vLzMzLy9AQEBAQEBAQEBAQEBAQEABEQ8PERMRFRISFRQRFBEUGhQWFhQaJhoaHBoa JjAjHh4eHiMwKy4nJycuKzU1MDA1NUBAP0BAQEBAQEBAQEBAQP/CABEIAA0AFQMBIgACEQEDEQH/ xACEAAADAQEAAAAAAAAAAAAAAAAAAwQBBgEBAQAAAAAAAAAAAAAAAAAAAgMQAAEDBAMAAAAAAAAA AAAAAAIBERIAAwQUEyMkEQACAQIHAQAAAAAAAAAAAAAAERIiEzFBYYHBQoKiEgABAwEJAAAAAAAA AAAAAAACAAERMVFhsSKCogMzNP/aAAwDAQACEQMRAAAA7xeyQVQokv/aAAgBAgABBQDIW/Fsxv/a AAgBAwABBQAIP11//9oACAEBAAEFADuRU8sQraTgvQc9V/Pqf//aAAgBAgIGPwCOARcrSdVHz3d0 4Qv/2gAIAQMCBj8AzOte1f/aAAgBAQEGPwBJmD3L0c0mVS8rkqu/J3ty0kz/2Q=="
                      />
                    </g>
                  </g>
                  <g clip-path="url(#ce-2-0)">
                    <g clip-path="url(#cf-2-2)">
                      <image
                        transform="matrix(.48 0 0 -.48 824.37 58.064)"
                        width="24"
                        height="9"
                        overflow="visible"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        xlink:href="data:image/jpeg;base64,/9j/4AAQSkZJRgABAgEAlgCWAAD/7AARRHVja3kAAQAEAAAAHgAA/+4AIUFkb2JlAGTAAAAAAQMA EAMCAwYAAAGZAAABsQAAAfL/2wCEABALCwsMCxAMDBAXDw0PFxsUEBAUGx8XFxcXFx8eFxoaGhoX Hh4jJSclIx4vLzMzLy9AQEBAQEBAQEBAQEBAQEABEQ8PERMRFRISFRQRFBEUGhQWFhQaJhoaHBoa JjAjHh4eHiMwKy4nJycuKzU1MDA1NUBAP0BAQEBAQEBAQEBAQP/CABEIAAoAGQMBIgACEQEDEQH/ xACEAAABBQAAAAAAAAAAAAAAAAABAAIDBAYBAQEAAAAAAAAAAAAAAAAAAAMFEAABBAEFAAAAAAAA AAAAAAADAAECBBAhMUESFBEAAQMEAwAAAAAAAAAAAAAAAQAQESFBAhIxcYESAAECBAcBAAAAAAAA AAAAAAERAgAhgRIQMUFRwQMTBP/aAAwDAQACEQMRAAAA2gtqY7ZAaQf/2gAIAQIAAQUAZienj//a AAgBAwABBQDTov/aAAgBAQABBQC1YMMozWZIUpSZS3ZNj//aAAgBAgIGPwB9zvrsukA0+dCDlSNe Y//aAAgBAwIGPwAIOtU3nh//2gAIAQEBBj8A1wMCAeFXKnQR2Mw1vVZ//9k="
                      />
                    </g>
                  </g>
                  <g clip-path="url(#ch-3-5)">
                    <g clip-path="url(#ci-8-7)">
                      <image
                        transform="matrix(.48 0 0 -.48 816.79 50.547)"
                        width="12"
                        height="19"
                        overflow="visible"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        xlink:href="data:image/jpeg;base64,/9j/4AAQSkZJRgABAgEAlgCWAAD/7AARRHVja3kAAQAEAAAAHgAA/+4AIUFkb2JlAGTAAAAAAQMA EAMCAwYAAAGeAAABuQAAAgj/2wCEABALCwsMCxAMDBAXDw0PFxsUEBAUGx8XFxcXFx8eFxoaGhoX Hh4jJSclIx4vLzMzLy9AQEBAQEBAQEBAQEBAQEABEQ8PERMRFRISFRQRFBEUGhQWFhQaJhoaHBoa JjAjHh4eHiMwKy4nJycuKzU1MDA1NUBAP0BAQEBAQEBAQEBAQP/CABEIABUADwMBIgACEQEDEQH/ xACHAAACAwAAAAAAAAAAAAAAAAAAAgEDBgEAAwEAAAAAAAAAAAAAAAAAAgMEBRAAAQMEAwEAAAAA AAAAAAAAAwERBAISExQAQjQFEQABBAEDBQAAAAAAAAAAAAARAAECEiIhMQNhcYGxghIAAQMDBQEA AAAAAAAAAAAAEQABAjGBEkFRcSIDM//aAAwDAQACEQMRAAAA25C57bkCgf/aAAgBAgABBQBKiJ9B W2f/2gAIAQMAAQUAZMXT/9oACAEBAAEFAJZiC4ExK401Hpj+ORZaHFrf/9oACAECAgY/AMXlLCXm 7tE9Two/M4PvnbQL/9oACAEDAgY/ACGJqnrWy//aAAgBAQEGPwCNHBJ0U+ST5RsH7Mm8rk+vSzI6 KdbUyJB2X//Z"
                      />
                    </g>
                  </g>
                  <g clip-path="url(#ck-7-3)">
                    <g clip-path="url(#cl-9-4)">
                      <image
                        transform="matrix(.48 0 0 -.48 819.54 68.173)"
                        width="12"
                        height="20"
                        overflow="visible"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        xlink:href="data:image/jpeg;base64,/9j/4AAQSkZJRgABAgEAlgCWAAD/7AARRHVja3kAAQAEAAAAHgAA/+4AIUFkb2JlAGTAAAAAAQMA EAMCAwYAAAGiAAABuwAAAg//2wCEABALCwsMCxAMDBAXDw0PFxsUEBAUGx8XFxcXFx8eFxoaGhoX Hh4jJSclIx4vLzMzLy9AQEBAQEBAQEBAQEBAQEABEQ8PERMRFRISFRQRFBEUGhQWFhQaJhoaHBoa JjAjHh4eHiMwKy4nJycuKzU1MDA1NUBAP0BAQEBAQEBAQEBAQP/CABEIABUADwMBIgACEQEDEQH/ xACGAAEBAQEAAAAAAAAAAAAAAAAEAAMGAQADAQAAAAAAAAAAAAAAAAAAAQMEEAABBAMBAQAAAAAA AAAAAAACAREDBBITFCIkEQABBAEDBQAAAAAAAAAAAAARAAECEkFRgaGxwWJyExIAAQMDAwUAAAAA AAAAAAAAAQARAiFBEjGBkTKCAxME/9oADAMBAAIRAxEAAADttxLxVOihf//aAAgBAgABBQCrKRWK +PT/AP/aAAgBAwABBQAxRAJ8P//aAAgBAQABBQCxPJHNkuu8rTv81zRt88//2gAIAQICBj8A+iEi SITo9gvO3pej4ZZ9704X/9oACAEDAgY/AIEXCj1b6bL/2gAIAQEBBj8AjCLiLxPLq2anhR9W6uj4 dk30tarCoAL6rNabgL//2Q=="
                      />
                    </g>
                  </g>
                  <g clip-path="url(#cn-7-3)">
                    <g clip-path="url(#co-2-0)">
                      <image
                        transform="matrix(.48 0 0 -.48 807.22 58.606)"
                        width="20"
                        height="10"
                        overflow="visible"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        xlink:href="data:image/jpeg;base64,/9j/4AAQSkZJRgABAgEAlgCWAAD/7AARRHVja3kAAQAEAAAAHgAA/+4AIUFkb2JlAGTAAAAAAQMA EAMCAwYAAAGdAAABtwAAAfv/2wCEABALCwsMCxAMDBAXDw0PFxsUEBAUGx8XFxcXFx8eFxoaGhoX Hh4jJSclIx4vLzMzLy9AQEBAQEBAQEBAQEBAQEABEQ8PERMRFRISFRQRFBEUGhQWFhQaJhoaHBoa JjAjHh4eHiMwKy4nJycuKzU1MDA1NUBAP0BAQEBAQEBAQEBAQP/CABEIAA0AFQMBIgACEQEDEQH/ xACFAAACAwAAAAAAAAAAAAAAAAAAAwECBgEAAwEAAAAAAAAAAAAAAAAAAAEDBBAAAAYCAwAAAAAA AAAAAAAAAAERAxMEAhQSJCURAAIABQUAAAAAAAAAAAAAAAARATFBEhMhYaHBIxIAAQIFBQEAAAAA AAAAAAAAAQAxEQISMhNBsSKiM4P/2gAMAwEAAhEDEQAAAN0mysdHEDP/2gAIAQIAAQUAeK6Z8Laf /9oACAEDAAEFAMYkVsf/2gAIAQEAAQUAdfJvI7yDaOCxqyeeOrr/AP/aAAgBAgIGPwA4sMsukSY7 K6Twp+0XZl//2gAIAQMCBj8A5VEpjd1X/9oACAEBAQY/ALTSEDKoTSPV3Kj6K8lcT3mf/9k="
                      />
                    </g>
                  </g>
                  <g clip-path="url(#cq-4-3)">
                    <g clip-path="url(#cr-0-7)">
                      <image
                        transform="matrix(.48 0 0 -.48 824.37 55.856)"
                        width="24"
                        height="10"
                        overflow="visible"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        xlink:href="data:image/jpeg;base64,/9j/4AAQSkZJRgABAgEAlgCWAAD/7AARRHVja3kAAQAEAAAAHgAA/+4AIUFkb2JlAGTAAAAAAQMA EAMCAwYAAAGfAAABugAAAgX/2wCEABALCwsMCxAMDBAXDw0PFxsUEBAUGx8XFxcXFx8eFxoaGhoX Hh4jJSclIx4vLzMzLy9AQEBAQEBAQEBAQEBAQEABEQ8PERMRFRISFRQRFBEUGhQWFhQaJhoaHBoa JjAjHh4eHiMwKy4nJycuKzU1MDA1NUBAP0BAQEBAQEBAQEBAQP/CABEIAA0AGQMBIgACEQEDEQH/ xACFAAEBAQAAAAAAAAAAAAAAAAADAgYBAAMBAAAAAAAAAAAAAAAAAAIDBAUQAAEDBQEAAAAAAAAA AAAAAAMAAgQBERITFBURAAEDAwQDAAAAAAAAAAAAAAEAERIQIQIxUYGhEyMzEgABAwIGAwAAAAAA AAAAAAABABECIgMhQVGRsRITIzP/2gAMAwEAAhEDEQAAANsdzntdiWoP/9oACAECAAEFACCnuLgb T//aAAgBAwABBQCjhY3bl//aAAgBAQABBQCRLqEnoOUc9TNUnm2t5EHXZf/aAAgBAgIGPwCRhejC 2ZUhgcNl08ntb6MOF//aAAgBAwIGPwAPFzmnanRf/9oACAEBAQY/AIDF7O7r59ok4xIp7ZSYabKz o+Pmn//Z"
                      />
                    </g>
                  </g>
                  <g clip-path="url(#ct-8-0)">
                    <g clip-path="url(#cu-3-7)">
                      <image
                        transform="matrix(.48 0 0 -.48 821.16 51.838)"
                        width="16"
                        height="18"
                        overflow="visible"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        xlink:href="data:image/jpeg;base64,/9j/4AAQSkZJRgABAgEAlgCWAAD/7AARRHVja3kAAQAEAAAAHgAA/+4AIUFkb2JlAGTAAAAAAQMA EAMCAwYAAAGXAAABtAAAAfX/2wCEABALCwsMCxAMDBAXDw0PFxsUEBAUGx8XFxcXFx8eFxoaGhoX Hh4jJSclIx4vLzMzLy9AQEBAQEBAQEBAQEBAQEABEQ8PERMRFRISFRQRFBEUGhQWFhQaJhoaHBoa JjAjHh4eHiMwKy4nJycuKzU1MDA1NUBAP0BAQEBAQEBAQEBAQP/CABEIABUAEQMBIgACEQEDEQH/ xAB8AAEBAQEBAAAAAAAAAAAAAAAAAwEFBgEAAwEAAAAAAAAAAAAAAAAAAAEDBRAAAgMAAwAAAAAA AAAAAAAAAQMAAgQQEhMRAAEEAgMAAAAAAAAAAAAAABEAAUECEBJRwSISAAIBBAMAAAAAAAAAAAAA AAECIRAgMQMR0SL/2gAMAwEAAhEDEQAAAPbYjlX6I1YSwRYM/9oACAECAAEFAHHdTRDx/9oACAED AAEFAKhRpz//2gAIAQEAAQUAfsKmJ2ljJoUL3Qo00S/n2r5dp//aAAgBAgIGPwBeHJ0vtEBRCk4x Tun/2gAIAQMCBj8AMegtn//aAAgBAQEGPwB6aFmkqtNAZOHeVTg9Y9EpgTGP/9k="
                      />
                    </g>
                  </g>
                  <g clip-path="url(#cw-0-4)">
                    <g clip-path="url(#cx-7-7)">
                      <image
                        transform="matrix(.48 0 0 -.48 812.29 66.464)"
                        width="16"
                        height="19"
                        overflow="visible"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        xlink:href="data:image/jpeg;base64,/9j/4AAQSkZJRgABAgEAlgCWAAD/7AARRHVja3kAAQAEAAAAHgAA/+4AIUFkb2JlAGTAAAAAAQMA EAMCAwYAAAGnAAABxAAAAhP/2wCEABALCwsMCxAMDBAXDw0PFxsUEBAUGx8XFxcXFx8eFxoaGhoX Hh4jJSclIx4vLzMzLy9AQEBAQEBAQEBAQEBAQEABEQ8PERMRFRISFRQRFBEUGhQWFhQaJhoaHBoa JjAjHh4eHiMwKy4nJycuKzU1MDA1NUBAP0BAQEBAQEBAQEBAQP/CABEIABUAEQMBIgACEQEDEQH/ xACGAAEAAgMAAAAAAAAAAAAAAAAAAgQBBQYBAQEBAAAAAAAAAAAAAAAAAAQDBRAAAQQDAQEAAAAA AAAAAAAAAgABEgMRBBQTBREAAQMDBQEAAAAAAAAAAAAAEQEhEgAQAkFRYcETIxIAAAQEBwAAAAAA AAAAAAAAEAERAjFREhNhkSIyA0MU/9oADAMBAAIRAxEAAADus05ArfD5UoGMjYDZP//aAAgBAgAB BQAPoA96sh1L/9oACAEDAAEFAHqeKbMF/9oACAEBAAEFADuiY2k5LcJx2Na7Ni2+b0o5fZf/2gAI AQICBj8AfwP0m15sacwNfEt0o13I5VB//9oACAEDAgY/ACcUlDs24IH/2gAIAQEBBj8AggPNIjPZ RslY4rqtvpOYeI7rGHpIsQLf/9k="
                      />
                    </g>
                  </g>
                  <g clip-path="url(#cz-5-5)">
                    <g clip-path="url(#cA-3-9)">
                      <image
                        transform="matrix(.48 0 0 -.48 808.93 53.753)"
                        width="20"
                        height="14"
                        overflow="visible"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        xlink:href="data:image/jpeg;base64,/9j/4AAQSkZJRgABAgEAlgCWAAD/7AARRHVja3kAAQAEAAAAHgAA/+4AIUFkb2JlAGTAAAAAAQMA EAMCAwYAAAGgAAABvQAAAgf/2wCEABALCwsMCxAMDBAXDw0PFxsUEBAUGx8XFxcXFx8eFxoaGhoX Hh4jJSclIx4vLzMzLy9AQEBAQEBAQEBAQEBAQEABEQ8PERMRFRISFRQRFBEUGhQWFhQaJhoaHBoa JjAjHh4eHiMwKy4nJycuKzU1MDA1NUBAP0BAQEBAQEBAQEBAQP/CABEIABEAFwMBIgACEQEDEQH/ xACAAAEBAQEBAAAAAAAAAAAAAAAABAMCBgEAAwEAAAAAAAAAAAAAAAAAAAMEAhAAAgIDAQEAAAAA AAAAAAAAAQMCBAAQExEUEQACAgEFAQAAAAAAAAAAAAABEQACISAxcRIDMhIAAQIGAwAAAAAAAAAA AAAAEQECABAxEiITcUIj/9oADAMBAAIRAxEAAAD3uOkUjKnDBWLlgH//2gAIAQIAAQUAuvmpXR/w 6//aAAgBAwABBQBcQT5Hrr//2gAIAQEAAQUAZMwxlowCrBYhwkcamxI11MjV3//aAAgBAgIGPwDz a5z3UtQiL8t2s0yPE//aAAgBAwIGPwDJURIHUz//2gAIAQEBBj8AGG5sHL+pABo8cBwINOfBnpS1 SLHshyNH/9k="
                      />
                    </g>
                  </g>
                  <g clip-path="url(#cC-7-4)">
                    <g clip-path="url(#cD-8-8)">
                      <image
                        transform="matrix(.48 0 0 -.48 823.08 62.628)"
                        width="20"
                        height="15"
                        overflow="visible"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        xlink:href="data:image/jpeg;base64,/9j/4AAQSkZJRgABAgEAlgCWAAD/7AARRHVja3kAAQAEAAAAHgAA/+4AIUFkb2JlAGTAAAAAAQMA EAMCAwYAAAGUAAABsAAAAe//2wCEABALCwsMCxAMDBAXDw0PFxsUEBAUGx8XFxcXFx8eFxoaGhoX Hh4jJSclIx4vLzMzLy9AQEBAQEBAQEBAQEBAQEABEQ8PERMRFRISFRQRFBEUGhQWFhQaJhoaHBoa JjAjHh4eHiMwKy4nJycuKzU1MDA1NUBAP0BAQEBAQEBAQEBAQP/CABEIABEAFQMBIgACEQEDEQH/ xAB5AAEAAgMAAAAAAAAAAAAAAAAAAQMCBAYBAQEBAAAAAAAAAAAAAAAAAAAEBRAAAgMBAQEAAAAA AAAAAAAAAQMQAgQSERMRAQACAgMBAAAAAAAAAAAAAAEAEUECIDESoRIAAgIDAQAAAAAAAAAAAAAA AQIgAxEhQRL/2gAMAwEAAhEDEQAAAO0iuzIo2hrz5gA//9oACAECAAEFAFW1s2T/AP/aAAgBAwAB BQAhYXP/2gAIAQEAAQUA0bLqavY256PGtTLPSq1R4flP/9oACAECAgY/ALF9utSWNzmdQ//aAAgB AwIGPwAHAJIh/9oACAEBAQY/AHQ1EK7leT7PWauKaqIUhLRuVmuH/9k="
                      />
                    </g>
                  </g>
                </g>
                <path
                  d="m814.01 379.51c-1.625 3.0818-4.8566 5.185-8.5857 5.185-5.0324 0-9.1683-3.8277-9.659-8.7297-0.25312 0.0471-0.51687 0.0749-0.78351 0.0749-2.3225 0-4.2045-1.881-4.2045-4.2016 0-2.3206 1.881-4.2035 4.2045-4.2035 1.1419 0 21.722-9.6e-4 21.722-9.6e-4 2.1534 0 3.4683 3.078 3.4683 5.1222 9.6e-4 3.5359-2.7051 6.439-6.1618 6.754"
                  fill="#bdd4df"
                />
                <g fill="#7acdef">
                  <path transform="matrix(.9661 0 0 -.9661 22.942 433.74)" d="m806.16 69.527-0.504 0.863-1.728-1.009 0.506-0.862" />
                  <path transform="matrix(.50412 .86363 .86363 -.50412 0 0)" d="m715.93 505.68h1.9234v1.9321h-1.9234z" />
                  <path transform="matrix(.9661 0 0 -.9661 22.942 433.74)" d="m802.64 75.55-0.504 0.864-1.727-1.009 0.504-0.863" />
                  <path transform="matrix(.9661 0 0 -.9661 22.942 433.74)" d="m811.66 69.527-0.504 0.864-1.726-1.008 0.504-0.864" />
                  <path transform="matrix(.504 .8637 .8637 -.504 0 0)" d="m718.56 510.36h1.9245v1.9332h-1.9245z" />
                  <path transform="matrix(.9661 0 0 -.9661 22.942 433.74)" d="m808.14 75.549-0.504 0.865-1.727-1.008 0.504-0.864" />
                  <path transform="matrix(.9661 0 0 -.9661 22.942 433.74)" d="m817.59 69.527-0.504 0.864-1.729-1.008 0.504-0.864" />
                  <path transform="matrix(.504 .8637 .8637 -.504 0 0)" d="m721.45 515.31h1.9245v1.9312h-1.9245z" />
                  <path transform="matrix(.9661 0 0 -.9661 22.942 433.74)" d="m814.07 75.549-0.504 0.865-1.728-1.008 0.504-0.864" />
                </g>
              </g>
            </g>
            <path
              transform="matrix(-2.3751 -1.0035 -1.0035 2.3751 7005.3 1332.9)"
              d="m2675 576.83c0.212 9e-3 0.4214 0.0369 0.6263 0.0831 1.1219 0.25233 2.0997 1.047 2.534 2.2032 0.1707 0.45401 0.2428 0.92451 0.2237 1.383l-0.3448-0.0141c0.017-0.41312-0.048-0.83692-0.2018-1.2475-0.3917-1.0436-1.2735-1.7596-2.2869-1.9883-0.1845-0.0413-0.3739-0.0666-0.5649-0.0748l0.015-0.34453zm0.4483-1.8961c0.4002-0.0144 0.7968 0.0236 1.181 0.10986 1.4673 0.33012 2.7475 1.3685 3.3161 2.8804 0.3257 0.86943 0.3763 1.774 0.1863 2.6171l-0.017 0.0733-0.3357-0.0809 0.015-0.0681c0.1752-0.77931 0.1295-1.614-0.1728-2.4203-0.5258-1.3986-1.7092-2.3586-3.0688-2.6649-0.355-0.0797-0.7222-0.11511-1.0926-0.10181l-0.012-0.34472zm0.4777-3.1861c0.4751 0.0188 0.9447 0.0812 1.4035 0.18418 2.4824 0.55878 4.6448 2.3168 5.6062 4.8746 0.4941 1.3126 0.6111 2.6753 0.4045 3.9654l-0.3406-0.0545c0.1971-1.2328 0.086-2.5334-0.387-3.789-0.9193-2.4455-2.9849-4.125-5.3592-4.6598-0.4381-0.0985-0.8871-0.15802-1.3411-0.17648l0.014-0.34437"
              display="inline"
              fill="#231f20"
              filter="url(#ap-1-0)"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width=".41534"
            />
            <g transform="matrix(.040356 0 0 .097953 -92.568 -301.26)" display="inline" enable-background="new" fill="#fff">
              <g stroke-width="14.592">
                <ellipse cx="3989.4" cy="3589.7" rx="104.91" ry="34.018" fill-opacity=".99216" stroke-opacity=".74902" />
                <path
                  d="m4092.4 4834.7a104.91 34.018 0 0 1-52.457 29.46 104.91 34.018 0 0 1-104.91 0 104.91 34.018 0 0 1-52.457-29.46"
                  fill-opacity=".99216"
                  stroke-opacity=".74902"
                />
                <path d="m3880.3 4830.4 4.716-1249.8" />
                <path d="m4087.8 4834.2 4.716-1249.8" />
              </g>
              <path
                transform="matrix(.9375 0 0 .9375 0 -.00282)"
                d="m4213.4 5187.7c-32.902-5.9673-55.59-14.11-63.42-22.762-8.0371-8.8809-8.4033-51.384-5.6987-661.34 1.5898-358.52 3.8621-652.82 5.0497-654.01s10.996 1.7963 21.796 6.6309c28.247 12.644 141.82 12.644 170.06 0 10.8-4.8346 19.99-8.1945 20.422-7.4665s-0.1697 297.54-1.3368 659.59l-2.1219 658.26-21.586 9.5487c-21.433 9.4809-96.016 16.476-123.17 11.552z"
                fill-opacity=".99216"
                stroke-opacity=".74902"
                stroke-width="15.565"
              />
            </g>
            <path d="m80.228 13.805h43.706" display="inline" fill="none" stroke-width=".52026" />
            <path d="m123.57 13.83 8.0558 60.458m-58.46-4.2646 6.6974-56.193" display="inline" stroke-width=".59714" />
            <ellipse
              cx="101.82"
              cy="13.019"
              rx="22.129"
              ry="7.1751"
              display="inline"
              fill="#fff"
              fill-opacity=".99216"
              stroke-opacity=".74902"
              stroke-width=".49277"
            />
          </g>
          <g fill="none">
            <path
              d="m123.95 17.207a22.129 7.1751 0 0 1-11.296 6.2565 22.129 7.1751 0 0 1-22.355-0.13077 22.129 7.1751 0 0 1-10.592-6.3846"
              display="inline"
              stroke="#000"
              stroke-opacity=".74902"
              stroke-width=".49277"
            />
            <path
              d="m85.093 21.681 14.317 20.532 2.8016 0.38004 2.748-0.26157 13.217-20.414"
              display="inline"
              stroke="#4d4d4d"
              stroke-dasharray="1.04053, 3.12158"
              stroke-width=".52026"
            />
            <path
              d="m64.162 61.852-0.0147 8.4455m8.8176-0.17036 1.8016-16.735-3.1156-0.07128"
              display="inline"
              stroke="#000"
              stroke-width=".51516"
            />
            <path
              d="m74.55 56.536c0.0055-0.43194 0.06378-0.57677 0.13037-0.32238 0.0664 0.25476 0.0617 0.60806-0.01105 0.78524-0.07176 0.17822-0.12591-0.03144-0.12056-0.46286z"
              display="inline"
              stroke="#000"
              stroke-width="1.8492"
            />
          </g>
          <g fill-opacity=".99216" stroke="#000">
            <path
              d="m72.759 53.572 0.90827 0.06081c0.67136 0.04193 1.2349 0.03145 1.2491 0.04193m-10.476 15.985c-0.45793-1.1627 0.10227-0.85904 0.17178-2.4646l0.0542-4.4896 0.2948 0.26524c0.63622 0.57242 1.3886 0.9848 2.2423 1.2296 0.67027 0.1908 1.8401 0.23903 2.515 0.10431 1.3955-0.28306 2.4052-1.0064 2.9524-2.11 1.6752-2.8777 0.84138 5.7734 0.48545 7.8287"
              display="inline"
              fill="#e6e6e6"
              stroke-width="1.8492"
            />
            <path
              d="m96.917 24.012c-2.4633-0.19091-4.3066-0.42758-6.5429-0.84324-1.8022-0.3347-4.9062-1.165-5.3188-1.4223-0.12168-0.07638-0.3617-0.16035-0.53312-0.18583-0.5896-0.09175-2.9276-1.4096-3.6879-2.079-0.41878-0.36856-0.86157-0.8677-0.98414-1.1095-0.31105-0.61317-0.46563-1.841-0.3348-2.6593l0.1104-1.8435 0.80064 1.2144c2.4753 2.2253 8.381 3.9289 15.655 4.5965 2.4675 0.2265 9.8544 0.55588 12.322 0.32961 7.0766-1.1438 11.81-2.0349 14.207-4.1452l0.94212-1.6733-0.0493 2.2195c0.0932 0.99418 0.0597 1.3477-0.17325 1.8392-1.1534 2.4307-6.8789 4.6192-14.413 5.509-2.6997 0.31892-9.3361 0.45914-12 0.25455z"
              display="inline"
              stroke-opacity=".74902"
              stroke-width=".16666"
            />
            <ellipse
              cx="101.99"
              cy="12.937"
              rx="20.991"
              ry="6.1285"
              display="inline"
              fill="#fff"
              stroke-opacity=".74902"
              stroke-width=".44355"
            />
          </g>
          <g fill="none" stroke="#000">
            <path
              d="m131.64 74.32a33.681 6.0693 0 0 1-16.84 5.2561 33.681 6.0693 0 0 1-33.681 0 33.681 6.0693 0 0 1-16.84-5.2561"
              display="inline"
              stroke-opacity=".74902"
              stroke-width=".55912"
            />
            <path
              d="m130.17 66.286a32.979 6.0733 0 0 1-32.8 6.0733 32.979 6.0733 0 0 1-33.155-6.0076"
              display="inline"
              stroke-opacity=".74902"
              stroke-width=".55345"
            />
            <path d="m73.514 70.13v8.2391" display="inline" stroke-width=".32198" />
          </g>
          <path
            d="m110.82 55.347v1.095c-0.0731 0.20339-0.011 0.48435-0.0994 0.69712-0.41149 3.5843-3.4781 6.8402-7.036 7.4674-0.36169 0.14258-0.78987 0.13996-1.1615 0.19867h-1.3607c-0.37176-0.06081-0.79989-0.05608-1.1616-0.19867-3.8366-0.66048-6.9613-4.3575-7.1355-8.1976v-1.1283c0.12166-0.75006 0.2762-1.53 0.57248-2.2469 0.30005-0.57729 0.60902-1.1515 0.96246-1.6926l1.1284-1.5267c2.524-3.0002 4.7343-6.2227 6.3971-9.7342l0.45803 0.97075c1.374 3.3471 3.5412 6.3108 5.9076 9.0953 0.25553 0.37532 0.531 0.74016 0.8297 1.0952 0.44473 0.68365 0.87949 1.374 1.228 2.1241 0.23903 0.63401 0.38833 1.3177 0.4713 1.9815z"
            display="inline"
            stroke="#000"
            stroke-width=".033188"
          />
          <g fill="#e7e7e7" fill-opacity=".99216" stroke="#000">
            <path
              d="m72.741 64.567c-0.02046-1.6962 0.25019-3.2129 0.48676-3.8719-0.24359-0.98527 0.09528-0.21649-0.19752-1.1515-0.22933-0.73177-0.31556-1.6867-0.31514-3.4875v-2.4801h0.95594c0.52581 0 0.956 0.04718 0.956 0.1017 0 0.19343-0.98762 12.591-1.0555 12.884-0.03774 0.16302-0.81611-0.75038-0.83044-1.9934z"
              display="inline"
              stroke-width="1.8492"
            />
            <path
              d="m70.274 69.488c-2.6109-0.69764-4.3048-1.3816-5.11-2.0635l-0.4779-0.4052v-4.443l0.4779 0.46234c0.26288 0.25476 0.87496 0.64753 1.3603 0.8743 0.78195 0.36536 1.0495 0.41254 2.3528 0.41254 1.3731 0 1.5265-0.03145 2.316-0.46391l0.84554-0.46391v3.221c0 3.7138 0.19699 3.3931-1.7646 2.8691z"
              display="inline"
              stroke-width="1.8492"
            />
            <path
              d="m64.096 74.902v-7.6465c3.1861 1.8135 4.9017 2.3037 9.1171 3.235l0.29407 7.7936c-3.8723-0.53908-6.7153-1.2255-9.4112-3.3821z"
              display="inline"
              stroke-width=".32198"
            />
          </g>
          <g fill-opacity=".99216">
            <path
              d="m89.023 79.839c-4.7879-0.26104-9.7846-0.75976-13.274-1.3248-0.99253-0.1604-1.9621-0.2925-2.0008-0.2925-0.03883 0-0.02833-1.6211-0.02833-3.6026 0-3.3333-0.3379-3.7288-0.09987-3.7286 0.14148 2.11e-4 1.2513 0.22383 1.9269 0.3423 1.7285 0.30403 5.909 0.77606 9.2856 1.0481 3.9161 0.31556 16.089 0.44294 20.606 0.21648 9.6628-0.48592 17.537-1.6919 21.691-3.3226 1.3602-0.53399 2.5932-1.2637 2.9174-1.7267 0.20538-0.29302 0.20921-0.2925 0.29045 0.05609 0.0451 0.19342 0.14311 0.88143 0.21739 1.5284 0.0743 0.64701 0.27201 2.1584 0.43954 3.3585 0.25428 1.8211 0.27267 2.2279 0.1117 2.4577-0.80414 1.1482-3.919 2.3437-8.3737 3.2142-3.6766 0.71825-7.4672 1.1825-13.416 1.6427-2.5701 0.19867-17.319 0.2967-20.293 0.13472z"
              display="inline"
              fill="#a5a7a8"
              stroke="#a5a7a8"
              stroke-opacity=".99216"
              stroke-width=".23528"
            />
            <path
              transform="matrix(.9579 -.28711 .4355 .90019 0 0)"
              d="m40.172 78.651a4.3739 4.0836 0 0 1-4.3116 3.786 4.3739 4.0836 0 0 1-4.4044-3.6917"
              display="inline"
              fill="#fff"
              stroke="#000"
              stroke-opacity=".74902"
              stroke-width=".53049"
            />
            <path
              transform="matrix(.94706 -.32106 .39327 .91942 0 0)"
              d="m43.418 79.4a3.9284 4.0156 0 0 1-4.3778 3.2238 3.9284 4.0156 0 0 1-3.3973-4.2847"
              display="inline"
              fill="#fff"
              stroke="#000"
              stroke-opacity=".74902"
              stroke-width=".32246"
            />
          </g>
        </g>
        <g transform="matrix(.14986 0 0 .14986 58.311 41.651)" display="inline" stroke="#000">
          <g stroke="#000">
            <g transform="matrix(1.0337 0 0 -1.0337 -610.4 318.17)" display="inline">
              <circle transform="scale(1,-1)" cx="729.88" cy="-221.54" r="24.025" fill="#fff" stroke="#000" stroke-width="1.125" />
              <g transform="translate(-77.909 -152.79)">
                <g transform="matrix(.9661 0 0 -.9661 22.942 433.74)">
                  <g clip-path="url(#bQ-6-0-2)">
                    <g clip-path="url(#bR-8-3-2)">
                      <image
                        transform="matrix(.48 0 0 -.48 813.57 61.76)"
                        width="32"
                        height="29"
                        overflow="visible"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        xlink:href="data:image/jpeg;base64,/9j/4AAQSkZJRgABAgEAlgCWAAD/7AARRHVja3kAAQAEAAAAHgAA/+4AIUFkb2JlAGTAAAAAAQMA EAMCAwYAAAHHAAAB/AAAAnb/2wCEABALCwsMCxAMDBAXDw0PFxsUEBAUGx8XFxcXFx8eFxoaGhoX Hh4jJSclIx4vLzMzLy9AQEBAQEBAQEBAQEBAQEABEQ8PERMRFRISFRQRFBEUGhQWFhQaJhoaHBoa JjAjHh4eHiMwKy4nJycuKzU1MDA1NUBAP0BAQEBAQEBAQEBAQP/CABEIAB8AIQMBIgACEQEDEQH/ xACMAAEBAQEBAQAAAAAAAAAAAAAEAAMFAQYBAAEFAAAAAAAAAAAAAAAAAAQAAQIFBhAAAgMAAgMB AAAAAAAAAAAAAgQAAQMTBRIyMxURAAIABAQHAQAAAAAAAAAAAAECABARMiFxkcExQWESQnKSAxIB AAICAgIDAAAAAAAAAAAAAQIDABEQITFREiIy/9oADAMBAAIRAxEAAAD7fHw+fKTvz1STqr4Xn4KN ni5GCZMyq/F//9oACAECAAEFAGm26aUabtuM4s04pkzbk//aAAgBAwABBQAMw8NACggEHHoQcc// 2gAIAQEAAQUAZaLE/wBA5+gcWZLe52H1ALKGFjOu9p2H1zPxmh+U672judnpwXOC4jnYXP/aAAgB AgIGPwCyqqxAloNGV1WzUd7EPTxZbXVOR8ukj14yu22ucT7bWPX5eP/aAAgBAwIGPwAUxlE4BTGM U4//2gAIAQEBBj8AChQaiuMWDWLBrDAqB204dZL67zfISX13n+mQkp7lGHkQOcXp9CL0+hD1ZWrS 0gy//9k="
                      />
                    </g>
                  </g>
                  <g clip-path="url(#bT-3-8-8)">
                    <g clip-path="url(#bU-3-4-6)">
                      <image
                        transform="matrix(.48 0 0 -.48 812.22 51.838)"
                        width="16"
                        height="18"
                        overflow="visible"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        xlink:href="data:image/jpeg;base64,/9j/4AAQSkZJRgABAgEAlgCWAAD/7AARRHVja3kAAQAEAAAAHgAA/+4AIUFkb2JlAGTAAAAAAQMA EAMCAwYAAAGkAAABvwAAAgr/2wCEABALCwsMCxAMDBAXDw0PFxsUEBAUGx8XFxcXFx8eFxoaGhoX Hh4jJSclIx4vLzMzLy9AQEBAQEBAQEBAQEBAQEABEQ8PERMRFRISFRQRFBEUGhQWFhQaJhoaHBoa JjAjHh4eHiMwKy4nJycuKzU1MDA1NUBAP0BAQEBAQEBAQEBAQP/CABEIABUAEQMBIgACEQEDEQH/ xACDAAEAAgMAAAAAAAAAAAAAAAAAAwQCBQYBAQEAAAAAAAAAAAAAAAAAAAMFEAABBAIDAQAAAAAA AAAAAAAAAQIDBBETEjIUNBEAAQMDBQAAAAAAAAAAAAAAEQAQEgFxAiExgSIDEgABBAIBBQAAAAAA AAAAAAABEDECEhEhAzJCgjMU/9oADAMBAAIRAxEAAADvIMqc5tqKIwVyet8UC//aAAgBAgABBQC9 ZkjmLWN5/9oACAEDAAEFAI2IrRnU/9oACAEBAAEFAJJeCsuq+Usrgr/SWNRD5Nx//9oACAECAgY/ AOGEJVvONtDYJTjz8zx9ubv24T//2gAIAQMCBj8AkSGGkPX4sn//2gAIAQEBBj8A2Kp5wBqCWrZY XbueFjCcjoQG/9k="
                      />
                    </g>
                  </g>
                  <g clip-path="url(#bW-1-8-8)">
                    <g clip-path="url(#bX-3-8-1)">
                      <image
                        transform="matrix(.48 0 0 -.48 821.22 65.921)"
                        width="16"
                        height="18"
                        overflow="visible"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        xlink:href="data:image/jpeg;base64,/9j/4AAQSkZJRgABAgEAlgCWAAD/7AARRHVja3kAAQAEAAAAHgAA/+4AIUFkb2JlAGTAAAAAAQMA EAMCAwYAAAGuAAAByAAAAhn/2wCEABALCwsMCxAMDBAXDw0PFxsUEBAUGx8XFxcXFx8eFxoaGhoX Hh4jJSclIx4vLzMzLy9AQEBAQEBAQEBAQEBAQEABEQ8PERMRFRISFRQRFBEUGhQWFhQaJhoaHBoa JjAjHh4eHiMwKy4nJycuKzU1MDA1NUBAP0BAQEBAQEBAQEBAQP/CABEIABUAEQMBIgACEQEDEQH/ xACMAAEBAAMBAAAAAAAAAAAAAAAABAECAwYBAQEBAAAAAAAAAAAAAAAAAAMEBRAAAQMDBQEAAAAA AAAAAAAABAACAwETFBARIRIzNBEAAgEEAgMAAAAAAAAAAAAAERIBABAhgUHBUXETEgABAwICCwAA AAAAAAAAAAABEBECMQMhQVFhgRIiMoITIzM0/9oADAMBAAIRAxEAAAD2+ZemU9w1Qj3IFpF5f//a AAgBAgABBQCAkh5yh2yl/9oACAEDAAEFAHMZSJO81//aAAgBAQABBQCcyzJCVdqjvoEpyi8XvBZ0 /9oACAECAgY/ALloz8cJS4d0UGtnS58zufW/c6sn0p//2gAIAQMCBj8AEmxIQc+2if/aAAgBAQEG PwBEbBJHVBBs9Wn1FRaPoz8r43ioRti3/9k="
                      />
                    </g>
                  </g>
                  <g clip-path="url(#bZ-0-3-3)">
                    <g clip-path="url(#b0-4-7-2)">
                      <image
                        transform="matrix(.48 0 0 -.48 808.99 62.691)"
                        width="20"
                        height="14"
                        overflow="visible"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        xlink:href="data:image/jpeg;base64,/9j/4AAQSkZJRgABAgEAlgCWAAD/7AARRHVja3kAAQAEAAAAHgAA/+4AIUFkb2JlAGTAAAAAAQMA EAMCAwYAAAGkAAABwAAAAg//2wCEABALCwsMCxAMDBAXDw0PFxsUEBAUGx8XFxcXFx8eFxoaGhoX Hh4jJSclIx4vLzMzLy9AQEBAQEBAQEBAQEBAQEABEQ8PERMRFRISFRQRFBEUGhQWFhQaJhoaHBoa JjAjHh4eHiMwKy4nJycuKzU1MDA1NUBAP0BAQEBAQEBAQEBAQP/CABEIABEAFwMBIgACEQEDEQH/ xACDAAEAAgMAAAAAAAAAAAAAAAAAAwQCBQYBAAIDAAAAAAAAAAAAAAAAAAADAgQFEAABBAMBAAAA AAAAAAAAAAADAAECBBASExQRAAEDAwUAAAAAAAAAAAAAAAEAEQJBgRIgUaEyAxIAAgIBBAMAAAAA AAAAAAAAAQIREgAQMUEDcSIT/9oADAMBAAIRAxEAAADu8Y62c7YoFpdoPiAf/9oACAECAAEFAD3T CN3fz4//2gAIAQMAAQUAiOMo6tvj/9oACAEBAAEFAClYaayzqJNoXRkmuFlAgVq2f//aAAgBAgIG PwBkHS/YixDKp5E59qNNbUj28Rr/AP/aAAgBAwIGPwAEsAcrPO+v/9oACAEBAQY/AA9X4VEZbKGE SWd2suhXpGQImcmFtH//2Q=="
                      />
                    </g>
                  </g>
                  <g clip-path="url(#b2-4-8-5)">
                    <g clip-path="url(#b3-5-3-7)">
                      <image
                        transform="matrix(.48 0 0 -.48 823.08 53.691)"
                        width="20"
                        height="15"
                        overflow="visible"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        xlink:href="data:image/jpeg;base64,/9j/4AAQSkZJRgABAgEAlgCWAAD/7AARRHVja3kAAQAEAAAAHgAA/+4AIUFkb2JlAGTAAAAAAQMA EAMCAwYAAAGoAAABwwAAAgn/2wCEABALCwsMCxAMDBAXDw0PFxsUEBAUGx8XFxcXFx8eFxoaGhoX Hh4jJSclIx4vLzMzLy9AQEBAQEBAQEBAQEBAQEABEQ8PERMRFRISFRQRFBEUGhQWFhQaJhoaHBoa JjAjHh4eHiMwKy4nJycuKzU1MDA1NUBAP0BAQEBAQEBAQEBAQP/CABEIABEAFQMBIgACEQEDEQH/ xACKAAEBAAMAAAAAAAAAAAAAAAAAAwEEBgEAAwEBAAAAAAAAAAAAAAAAAAMEAgUQAAEBBwUAAAAA AAAAAAAAAAMBABACEhMEFBEiIwUVEQABBAEFAQAAAAAAAAAAAAABABEhEgIgMUGRMhMSAAEBBgcA AAAAAAAAAAAAAAEhABARQQIDMaESIoKjRP/aAAwDAQACEQMRAAAA7WdYcl+4wtXYU4AP/9oACAEC AAEFADj7Gpzv/9oACAEDAAEFAIVDptf/AP/aAAgBAQABBQA95EInoRtVXHuATkW1VpFxn//aAAgB AgIGPwCs27mw1HSEQSbHz9j/AP/aAAgBAwIGPwARCzbnk/8A/9oACAEBAQY/ADgMQQGleB2vq01s yOTbqFTmraP/2Q=="
                      />
                    </g>
                  </g>
                  <g clip-path="url(#b5-8-6-3)">
                    <g clip-path="url(#b6-9-2-2)">
                      <image
                        transform="matrix(.48 0 0 -.48 819 50.547)"
                        width="12"
                        height="19"
                        overflow="visible"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        xlink:href="data:image/jpeg;base64,/9j/4AAQSkZJRgABAgEAlgCWAAD/7AARRHVja3kAAQAEAAAAHgAA/+4AIUFkb2JlAGTAAAAAAQMA EAMCAwYAAAGSAAABrAAAAfX/2wCEABALCwsMCxAMDBAXDw0PFxsUEBAUGx8XFxcXFx8eFxoaGhoX Hh4jJSclIx4vLzMzLy9AQEBAQEBAQEBAQEBAQEABEQ8PERMRFRISFRQRFBEUGhQWFhQaJhoaHBoa JjAjHh4eHiMwKy4nJycuKzU1MDA1NUBAP0BAQEBAQEBAQEBAQP/CABEIABUADwMBIgACEQEDEQH/ xAB9AAEBAQEAAAAAAAAAAAAAAAAAAgMGAQEBAAAAAAAAAAAAAAAAAAADBBAAAgIDAQAAAAAAAAAA AAAAAgMBBAAREhMRAAICAAQHAAAAAAAAAAAAAAERAAIhURIDYXGBkbEiExIAAgICAwAAAAAAAAAA AAAAAQIRIhAxURID/9oADAMBAAIRAxEAAADuJuJE2FZ//9oACAECAAEFAJBsXM//2gAIAQMAAQUA 2Pnn/9oACAEBAAEFALbWLFDmHWsDBQgNV284HPH/2gAIAQICBj8AV+7HyZDWaggcY//aAAgBAwIG PwCIsDvH/9oACAEBAQY/AK/PBtnlNzcsfaupFZBwA8ZuVzNvAg1PpLJrF9p//9k="
                      />
                    </g>
                  </g>
                  <g clip-path="url(#b8-4-8-4)">
                    <g clip-path="url(#b9-7-7-8)">
                      <image
                        transform="matrix(.48 0 0 -.48 816.85 68.173)"
                        width="12"
                        height="20"
                        overflow="visible"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        xlink:href="data:image/jpeg;base64,/9j/4AAQSkZJRgABAgEAlgCWAAD/7AARRHVja3kAAQAEAAAAHgAA/+4AIUFkb2JlAGTAAAAAAQMA EAMCAwYAAAGdAAABtwAAAgP/2wCEABALCwsMCxAMDBAXDw0PFxsUEBAUGx8XFxcXFx8eFxoaGhoX Hh4jJSclIx4vLzMzLy9AQEBAQEBAQEBAQEBAQEABEQ8PERMRFRISFRQRFBEUGhQWFhQaJhoaHBoa JjAjHh4eHiMwKy4nJycuKzU1MDA1NUBAP0BAQEBAQEBAQEBAQP/CABEIABUADwMBIgACEQEDEQH/ xACEAAACAwEAAAAAAAAAAAAAAAAAAgEDBAYBAQEBAAAAAAAAAAAAAAAAAAIDBBAAAgIDAQAAAAAA AAAAAAAAAgMBERITBBQRAAEEAgEFAAAAAAAAAAAAAAEAEQISMUFRcbHBAxMSAAAEBAcAAAAAAAAA AAAAAAABEQMxAhJCQVFxMsITM//aAAwDAQACEQMRAAAA7uc1udowRX//2gAIAQIAAQUAX0QT14+7 /9oACAEDAAEFAJChm9f/2gAIAQEAAQUAayQISmQ7ixNR3zdum1avL//aAAgBAgIGPwBxm5tE0MiD ngtOFXbAo2j/2gAIAQMCBj8AlmzBbuI//9oACAEBAQY/AIgbRkch16z1UpcA9lD6W21W8qVbUaVn zhf/2Q=="
                      />
                    </g>
                  </g>
                  <g clip-path="url(#cb-1-3-3)">
                    <g clip-path="url(#cc-6-9-2)">
                      <image
                        transform="matrix(.48 0 0 -.48 807.22 55.918)"
                        width="20"
                        height="10"
                        overflow="visible"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        xlink:href="data:image/jpeg;base64,/9j/4AAQSkZJRgABAgEAlgCWAAD/7AARRHVja3kAAQAEAAAAHgAA/+4AIUFkb2JlAGTAAAAAAQMA EAMCAwYAAAGbAAABtAAAAfT/2wCEABALCwsMCxAMDBAXDw0PFxsUEBAUGx8XFxcXFx8eFxoaGhoX Hh4jJSclIx4vLzMzLy9AQEBAQEBAQEBAQEBAQEABEQ8PERMRFRISFRQRFBEUGhQWFhQaJhoaHBoa JjAjHh4eHiMwKy4nJycuKzU1MDA1NUBAP0BAQEBAQEBAQEBAQP/CABEIAA0AFQMBIgACEQEDEQH/ xACEAAADAQEAAAAAAAAAAAAAAAAAAwQBBgEBAQAAAAAAAAAAAAAAAAAAAgMQAAEDBAMAAAAAAAAA AAAAAAIBERIAAwQUEyMkEQACAQIHAQAAAAAAAAAAAAAAERIiEzFBYYHBQoKiEgABAwEJAAAAAAAA AAAAAAACAAERMVFhsSKCogMzNP/aAAwDAQACEQMRAAAA7xeyQVQokv/aAAgBAgABBQDIW/Fsxv/a AAgBAwABBQAIP11//9oACAEBAAEFADuRU8sQraTgvQc9V/Pqf//aAAgBAgIGPwCOARcrSdVHz3d0 4Qv/2gAIAQMCBj8AzOte1f/aAAgBAQEGPwBJmD3L0c0mVS8rkqu/J3ty0kz/2Q=="
                      />
                    </g>
                  </g>
                  <g clip-path="url(#ce-2-0-7)">
                    <g clip-path="url(#cf-2-2-4)">
                      <image
                        transform="matrix(.48 0 0 -.48 824.37 58.064)"
                        width="24"
                        height="9"
                        overflow="visible"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        xlink:href="data:image/jpeg;base64,/9j/4AAQSkZJRgABAgEAlgCWAAD/7AARRHVja3kAAQAEAAAAHgAA/+4AIUFkb2JlAGTAAAAAAQMA EAMCAwYAAAGZAAABsQAAAfL/2wCEABALCwsMCxAMDBAXDw0PFxsUEBAUGx8XFxcXFx8eFxoaGhoX Hh4jJSclIx4vLzMzLy9AQEBAQEBAQEBAQEBAQEABEQ8PERMRFRISFRQRFBEUGhQWFhQaJhoaHBoa JjAjHh4eHiMwKy4nJycuKzU1MDA1NUBAP0BAQEBAQEBAQEBAQP/CABEIAAoAGQMBIgACEQEDEQH/ xACEAAABBQAAAAAAAAAAAAAAAAABAAIDBAYBAQEAAAAAAAAAAAAAAAAAAAMFEAABBAEFAAAAAAAA AAAAAAADAAECBBAhMUESFBEAAQMEAwAAAAAAAAAAAAAAAQAQESFBAhIxcYESAAECBAcBAAAAAAAA AAAAAAERAgAhgRIQMUFRwQMTBP/aAAwDAQACEQMRAAAA2gtqY7ZAaQf/2gAIAQIAAQUAZienj//a AAgBAwABBQDTov/aAAgBAQABBQC1YMMozWZIUpSZS3ZNj//aAAgBAgIGPwB9zvrsukA0+dCDlSNe Y//aAAgBAwIGPwAIOtU3nh//2gAIAQEBBj8A1wMCAeFXKnQR2Mw1vVZ//9k="
                      />
                    </g>
                  </g>
                  <g clip-path="url(#ch-3-5-2)">
                    <g clip-path="url(#ci-8-7-6)">
                      <image
                        transform="matrix(.48 0 0 -.48 816.79 50.547)"
                        width="12"
                        height="19"
                        overflow="visible"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        xlink:href="data:image/jpeg;base64,/9j/4AAQSkZJRgABAgEAlgCWAAD/7AARRHVja3kAAQAEAAAAHgAA/+4AIUFkb2JlAGTAAAAAAQMA EAMCAwYAAAGeAAABuQAAAgj/2wCEABALCwsMCxAMDBAXDw0PFxsUEBAUGx8XFxcXFx8eFxoaGhoX Hh4jJSclIx4vLzMzLy9AQEBAQEBAQEBAQEBAQEABEQ8PERMRFRISFRQRFBEUGhQWFhQaJhoaHBoa JjAjHh4eHiMwKy4nJycuKzU1MDA1NUBAP0BAQEBAQEBAQEBAQP/CABEIABUADwMBIgACEQEDEQH/ xACHAAACAwAAAAAAAAAAAAAAAAAAAgEDBgEAAwEAAAAAAAAAAAAAAAAAAgMEBRAAAQMEAwEAAAAA AAAAAAAAAwERBAISExQAQjQFEQABBAEDBQAAAAAAAAAAAAARAAECEiIhMQNhcYGxghIAAQMDBQEA AAAAAAAAAAAAEQABAjGBEkFRcSIDM//aAAwDAQACEQMRAAAA25C57bkCgf/aAAgBAgABBQBKiJ9B W2f/2gAIAQMAAQUAZMXT/9oACAEBAAEFAJZiC4ExK401Hpj+ORZaHFrf/9oACAECAgY/AMXlLCXm 7tE9Two/M4PvnbQL/9oACAEDAgY/ACGJqnrWy//aAAgBAQEGPwCNHBJ0U+ST5RsH7Mm8rk+vSzI6 KdbUyJB2X//Z"
                      />
                    </g>
                  </g>
                  <g clip-path="url(#ck-7-3-3)">
                    <g clip-path="url(#cl-9-4-6)">
                      <image
                        transform="matrix(.48 0 0 -.48 819.54 68.173)"
                        width="12"
                        height="20"
                        overflow="visible"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        xlink:href="data:image/jpeg;base64,/9j/4AAQSkZJRgABAgEAlgCWAAD/7AARRHVja3kAAQAEAAAAHgAA/+4AIUFkb2JlAGTAAAAAAQMA EAMCAwYAAAGiAAABuwAAAg//2wCEABALCwsMCxAMDBAXDw0PFxsUEBAUGx8XFxcXFx8eFxoaGhoX Hh4jJSclIx4vLzMzLy9AQEBAQEBAQEBAQEBAQEABEQ8PERMRFRISFRQRFBEUGhQWFhQaJhoaHBoa JjAjHh4eHiMwKy4nJycuKzU1MDA1NUBAP0BAQEBAQEBAQEBAQP/CABEIABUADwMBIgACEQEDEQH/ xACGAAEBAQEAAAAAAAAAAAAAAAAEAAMGAQADAQAAAAAAAAAAAAAAAAAAAQMEEAABBAMBAQAAAAAA AAAAAAACAREDBBITFCIkEQABBAEDBQAAAAAAAAAAAAARAAECEkFRgaGxwWJyExIAAQMDAwUAAAAA AAAAAAAAAQARAiFBEjGBkTKCAxME/9oADAMBAAIRAxEAAADttxLxVOihf//aAAgBAgABBQCrKRWK +PT/AP/aAAgBAwABBQAxRAJ8P//aAAgBAQABBQCxPJHNkuu8rTv81zRt88//2gAIAQICBj8A+iEi SITo9gvO3pej4ZZ9704X/9oACAEDAgY/AIEXCj1b6bL/2gAIAQEBBj8AjCLiLxPLq2anhR9W6uj4 dk30tarCoAL6rNabgL//2Q=="
                      />
                    </g>
                  </g>
                  <g clip-path="url(#cn-7-3-5)">
                    <g clip-path="url(#co-2-0-5)">
                      <image
                        transform="matrix(.48 0 0 -.48 807.22 58.606)"
                        width="20"
                        height="10"
                        overflow="visible"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        xlink:href="data:image/jpeg;base64,/9j/4AAQSkZJRgABAgEAlgCWAAD/7AARRHVja3kAAQAEAAAAHgAA/+4AIUFkb2JlAGTAAAAAAQMA EAMCAwYAAAGdAAABtwAAAfv/2wCEABALCwsMCxAMDBAXDw0PFxsUEBAUGx8XFxcXFx8eFxoaGhoX Hh4jJSclIx4vLzMzLy9AQEBAQEBAQEBAQEBAQEABEQ8PERMRFRISFRQRFBEUGhQWFhQaJhoaHBoa JjAjHh4eHiMwKy4nJycuKzU1MDA1NUBAP0BAQEBAQEBAQEBAQP/CABEIAA0AFQMBIgACEQEDEQH/ xACFAAACAwAAAAAAAAAAAAAAAAAAAwECBgEAAwEAAAAAAAAAAAAAAAAAAAEDBBAAAAYCAwAAAAAA AAAAAAAAAAERAxMEAhQSJCURAAIABQUAAAAAAAAAAAAAAAARATFBEhMhYaHBIxIAAQIFBQEAAAAA AAAAAAAAAQAxEQISMhNBsSKiM4P/2gAMAwEAAhEDEQAAAN0mysdHEDP/2gAIAQIAAQUAeK6Z8Laf /9oACAEDAAEFAMYkVsf/2gAIAQEAAQUAdfJvI7yDaOCxqyeeOrr/AP/aAAgBAgIGPwA4sMsukSY7 K6Twp+0XZl//2gAIAQMCBj8A5VEpjd1X/9oACAEBAQY/ALTSEDKoTSPV3Kj6K8lcT3mf/9k="
                      />
                    </g>
                  </g>
                  <g clip-path="url(#cq-4-3-0)">
                    <g clip-path="url(#cr-0-7-8)">
                      <image
                        transform="matrix(.48 0 0 -.48 824.37 55.856)"
                        width="24"
                        height="10"
                        overflow="visible"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        xlink:href="data:image/jpeg;base64,/9j/4AAQSkZJRgABAgEAlgCWAAD/7AARRHVja3kAAQAEAAAAHgAA/+4AIUFkb2JlAGTAAAAAAQMA EAMCAwYAAAGfAAABugAAAgX/2wCEABALCwsMCxAMDBAXDw0PFxsUEBAUGx8XFxcXFx8eFxoaGhoX Hh4jJSclIx4vLzMzLy9AQEBAQEBAQEBAQEBAQEABEQ8PERMRFRISFRQRFBEUGhQWFhQaJhoaHBoa JjAjHh4eHiMwKy4nJycuKzU1MDA1NUBAP0BAQEBAQEBAQEBAQP/CABEIAA0AGQMBIgACEQEDEQH/ xACFAAEBAQAAAAAAAAAAAAAAAAADAgYBAAMBAAAAAAAAAAAAAAAAAAIDBAUQAAEDBQEAAAAAAAAA AAAAAAMAAgQBERITFBURAAEDAwQDAAAAAAAAAAAAAAEAERIQIQIxUYGhEyMzEgABAwIGAwAAAAAA AAAAAAABABECIgMhQVGRsRITIzP/2gAMAwEAAhEDEQAAANsdzntdiWoP/9oACAECAAEFACCnuLgb T//aAAgBAwABBQCjhY3bl//aAAgBAQABBQCRLqEnoOUc9TNUnm2t5EHXZf/aAAgBAgIGPwCRhejC 2ZUhgcNl08ntb6MOF//aAAgBAwIGPwAPFzmnanRf/9oACAEBAQY/AIDF7O7r59ok4xIp7ZSYabKz o+Pmn//Z"
                      />
                    </g>
                  </g>
                  <g clip-path="url(#ct-8-0-8)">
                    <g clip-path="url(#cu-3-7-5)">
                      <image
                        transform="matrix(.48 0 0 -.48 821.16 51.838)"
                        width="16"
                        height="18"
                        overflow="visible"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        xlink:href="data:image/jpeg;base64,/9j/4AAQSkZJRgABAgEAlgCWAAD/7AARRHVja3kAAQAEAAAAHgAA/+4AIUFkb2JlAGTAAAAAAQMA EAMCAwYAAAGXAAABtAAAAfX/2wCEABALCwsMCxAMDBAXDw0PFxsUEBAUGx8XFxcXFx8eFxoaGhoX Hh4jJSclIx4vLzMzLy9AQEBAQEBAQEBAQEBAQEABEQ8PERMRFRISFRQRFBEUGhQWFhQaJhoaHBoa JjAjHh4eHiMwKy4nJycuKzU1MDA1NUBAP0BAQEBAQEBAQEBAQP/CABEIABUAEQMBIgACEQEDEQH/ xAB8AAEBAQEBAAAAAAAAAAAAAAAAAwEFBgEAAwEAAAAAAAAAAAAAAAAAAAEDBRAAAgMAAwAAAAAA AAAAAAAAAQMAAgQQEhMRAAEEAgMAAAAAAAAAAAAAABEAAUECEBJRwSISAAIBBAMAAAAAAAAAAAAA AAECIRAgMQMR0SL/2gAMAwEAAhEDEQAAAPbYjlX6I1YSwRYM/9oACAECAAEFAHHdTRDx/9oACAED AAEFAKhRpz//2gAIAQEAAQUAfsKmJ2ljJoUL3Qo00S/n2r5dp//aAAgBAgIGPwBeHJ0vtEBRCk4x Tun/2gAIAQMCBj8AMegtn//aAAgBAQEGPwB6aFmkqtNAZOHeVTg9Y9EpgTGP/9k="
                      />
                    </g>
                  </g>
                  <g clip-path="url(#cw-0-4-4)">
                    <g clip-path="url(#cx-7-7-3)">
                      <image
                        transform="matrix(.48 0 0 -.48 812.29 66.464)"
                        width="16"
                        height="19"
                        overflow="visible"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        xlink:href="data:image/jpeg;base64,/9j/4AAQSkZJRgABAgEAlgCWAAD/7AARRHVja3kAAQAEAAAAHgAA/+4AIUFkb2JlAGTAAAAAAQMA EAMCAwYAAAGnAAABxAAAAhP/2wCEABALCwsMCxAMDBAXDw0PFxsUEBAUGx8XFxcXFx8eFxoaGhoX Hh4jJSclIx4vLzMzLy9AQEBAQEBAQEBAQEBAQEABEQ8PERMRFRISFRQRFBEUGhQWFhQaJhoaHBoa JjAjHh4eHiMwKy4nJycuKzU1MDA1NUBAP0BAQEBAQEBAQEBAQP/CABEIABUAEQMBIgACEQEDEQH/ xACGAAEAAgMAAAAAAAAAAAAAAAAAAgQBBQYBAQEBAAAAAAAAAAAAAAAAAAQDBRAAAQQDAQEAAAAA AAAAAAAAAgABEgMRBBQTBREAAQMDBQEAAAAAAAAAAAAAEQEhEgAQAkFRYcETIxIAAAQEBwAAAAAA AAAAAAAAEAERAjFREhNhkSIyA0MU/9oADAMBAAIRAxEAAADus05ArfD5UoGMjYDZP//aAAgBAgAB BQAPoA96sh1L/9oACAEDAAEFAHqeKbMF/9oACAEBAAEFADuiY2k5LcJx2Na7Ni2+b0o5fZf/2gAI AQICBj8AfwP0m15sacwNfEt0o13I5VB//9oACAEDAgY/ACcUlDs24IH/2gAIAQEBBj8AggPNIjPZ RslY4rqtvpOYeI7rGHpIsQLf/9k="
                      />
                    </g>
                  </g>
                  <g clip-path="url(#cz-5-5-1)">
                    <g clip-path="url(#cA-3-9-7)">
                      <image
                        transform="matrix(.48 0 0 -.48 808.93 53.753)"
                        width="20"
                        height="14"
                        overflow="visible"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        xlink:href="data:image/jpeg;base64,/9j/4AAQSkZJRgABAgEAlgCWAAD/7AARRHVja3kAAQAEAAAAHgAA/+4AIUFkb2JlAGTAAAAAAQMA EAMCAwYAAAGgAAABvQAAAgf/2wCEABALCwsMCxAMDBAXDw0PFxsUEBAUGx8XFxcXFx8eFxoaGhoX Hh4jJSclIx4vLzMzLy9AQEBAQEBAQEBAQEBAQEABEQ8PERMRFRISFRQRFBEUGhQWFhQaJhoaHBoa JjAjHh4eHiMwKy4nJycuKzU1MDA1NUBAP0BAQEBAQEBAQEBAQP/CABEIABEAFwMBIgACEQEDEQH/ xACAAAEBAQEBAAAAAAAAAAAAAAAABAMCBgEAAwEAAAAAAAAAAAAAAAAAAAMEAhAAAgIDAQEAAAAA AAAAAAAAAQMCBAAQExEUEQACAgEFAQAAAAAAAAAAAAABEQACISAxcRIDMhIAAQIGAwAAAAAAAAAA AAAAEQECABAxEiITcUIj/9oADAMBAAIRAxEAAAD3uOkUjKnDBWLlgH//2gAIAQIAAQUAuvmpXR/w 6//aAAgBAwABBQBcQT5Hrr//2gAIAQEAAQUAZMwxlowCrBYhwkcamxI11MjV3//aAAgBAgIGPwDz a5z3UtQiL8t2s0yPE//aAAgBAwIGPwDJURIHUz//2gAIAQEBBj8AGG5sHL+pABo8cBwINOfBnpS1 SLHshyNH/9k="
                      />
                    </g>
                  </g>
                  <g clip-path="url(#cC-7-4-3)">
                    <g clip-path="url(#cD-8-8-3)">
                      <image
                        transform="matrix(.48 0 0 -.48 823.08 62.628)"
                        width="20"
                        height="15"
                        overflow="visible"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        xlink:href="data:image/jpeg;base64,/9j/4AAQSkZJRgABAgEAlgCWAAD/7AARRHVja3kAAQAEAAAAHgAA/+4AIUFkb2JlAGTAAAAAAQMA EAMCAwYAAAGUAAABsAAAAe//2wCEABALCwsMCxAMDBAXDw0PFxsUEBAUGx8XFxcXFx8eFxoaGhoX Hh4jJSclIx4vLzMzLy9AQEBAQEBAQEBAQEBAQEABEQ8PERMRFRISFRQRFBEUGhQWFhQaJhoaHBoa JjAjHh4eHiMwKy4nJycuKzU1MDA1NUBAP0BAQEBAQEBAQEBAQP/CABEIABEAFQMBIgACEQEDEQH/ xAB5AAEAAgMAAAAAAAAAAAAAAAAAAQMCBAYBAQEBAAAAAAAAAAAAAAAAAAAEBRAAAgMBAQEAAAAA AAAAAAAAAQMQAgQSERMRAQACAgMBAAAAAAAAAAAAAAEAEUECIDESoRIAAgIDAQAAAAAAAAAAAAAA AQIgAxEhQRL/2gAMAwEAAhEDEQAAAO0iuzIo2hrz5gA//9oACAECAAEFAFW1s2T/AP/aAAgBAwAB BQAhYXP/2gAIAQEAAQUA0bLqavY256PGtTLPSq1R4flP/9oACAECAgY/ALF9utSWNzmdQ//aAAgB AwIGPwAHAJIh/9oACAEBAQY/AHQ1EK7leT7PWauKaqIUhLRuVmuH/9k="
                      />
                    </g>
                  </g>
                </g>
                <path
                  d="m814.01 379.51c-1.625 3.0818-4.8566 5.185-8.5857 5.185-5.0324 0-9.1683-3.8277-9.659-8.7297-0.25312 0.0471-0.51687 0.0749-0.78351 0.0749-2.3225 0-4.2045-1.881-4.2045-4.2016 0-2.3206 1.881-4.2035 4.2045-4.2035 1.1419 0 21.722-9.6e-4 21.722-9.6e-4 2.1534 0 3.4683 3.078 3.4683 5.1222 9.6e-4 3.5359-2.7051 6.439-6.1618 6.754"
                  fill="#bdd4df"
                />
                <g fill="#7acdef">
                  <path transform="matrix(.9661 0 0 -.9661 22.942 433.74)" d="m806.16 69.527-0.504 0.863-1.728-1.009 0.506-0.862" />
                  <path transform="matrix(.50412 .86363 .86363 -.50412 0 0)" d="m715.93 505.68h1.9234v1.9321h-1.9234z" />
                  <path transform="matrix(.9661 0 0 -.9661 22.942 433.74)" d="m802.64 75.55-0.504 0.864-1.727-1.009 0.504-0.863" />
                  <path transform="matrix(.9661 0 0 -.9661 22.942 433.74)" d="m811.66 69.527-0.504 0.864-1.726-1.008 0.504-0.864" />
                  <path transform="matrix(.504 .8637 .8637 -.504 0 0)" d="m718.56 510.36h1.9245v1.9332h-1.9245z" />
                  <path transform="matrix(.9661 0 0 -.9661 22.942 433.74)" d="m808.14 75.549-0.504 0.865-1.727-1.008 0.504-0.864" />
                  <path transform="matrix(.9661 0 0 -.9661 22.942 433.74)" d="m817.59 69.527-0.504 0.864-1.729-1.008 0.504-0.864" />
                  <path transform="matrix(.504 .8637 .8637 -.504 0 0)" d="m721.45 515.31h1.9245v1.9312h-1.9245z" />
                  <path transform="matrix(.9661 0 0 -.9661 22.942 433.74)" d="m814.07 75.549-0.504 0.865-1.728-1.008 0.504-0.864" />
                </g>
              </g>
            </g>
            <path
              transform="matrix(-2.3751 -1.0035 -1.0035 2.3751 7005.3 1332.9)"
              d="m2675 576.83c0.212 9e-3 0.4214 0.0369 0.6263 0.0831 1.1219 0.25233 2.0997 1.047 2.534 2.2032 0.1707 0.45401 0.2428 0.92451 0.2237 1.383l-0.3448-0.0141c0.017-0.41312-0.048-0.83692-0.2018-1.2475-0.3917-1.0436-1.2735-1.7596-2.2869-1.9883-0.1845-0.0413-0.3739-0.0666-0.5649-0.0748l0.015-0.34453zm0.4483-1.8961c0.4002-0.0144 0.7968 0.0236 1.181 0.10986 1.4673 0.33012 2.7475 1.3685 3.3161 2.8804 0.3257 0.86943 0.3763 1.774 0.1863 2.6171l-0.017 0.0733-0.3357-0.0809 0.015-0.0681c0.1752-0.77931 0.1295-1.614-0.1728-2.4203-0.5258-1.3986-1.7092-2.3586-3.0688-2.6649-0.355-0.0797-0.7222-0.11511-1.0926-0.10181l-0.012-0.34472zm0.4777-3.1861c0.4751 0.0188 0.9447 0.0812 1.4035 0.18418 2.4824 0.55878 4.6448 2.3168 5.6062 4.8746 0.4941 1.3126 0.6111 2.6753 0.4045 3.9654l-0.3406-0.0545c0.1971-1.2328 0.086-2.5334-0.387-3.789-0.9193-2.4455-2.9849-4.125-5.3592-4.6598-0.4381-0.0985-0.8871-0.15802-1.3411-0.17648l0.014-0.34437"
              display="inline"
              fill="#231f20"
              filter="url(#ap-1-0-6)"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width=".41534"
            />
            <g transform="matrix(.040356 0 0 .097953 -92.568 -301.26)" display="inline" enable-background="new" fill="#fff">
              <g stroke-width="14.592">
                <ellipse cx="3989.4" cy="3589.7" rx="104.91" ry="34.018" fill-opacity=".99216" stroke-opacity=".74902" />
                <path
                  d="m4092.4 4834.7a104.91 34.018 0 0 1-52.457 29.46 104.91 34.018 0 0 1-104.91 0 104.91 34.018 0 0 1-52.457-29.46"
                  fill-opacity=".99216"
                  stroke-opacity=".74902"
                />
                <path d="m3880.3 4830.4 4.716-1249.8" />
                <path d="m4087.8 4834.2 4.716-1249.8" />
              </g>
              <path
                transform="matrix(.9375 0 0 .9375 0 -.00282)"
                d="m4213.4 5187.7c-32.902-5.9673-55.59-14.11-63.42-22.762-8.0371-8.8809-8.4033-51.384-5.6987-661.34 1.5898-358.52 3.8621-652.82 5.0497-654.01s10.996 1.7963 21.796 6.6309c28.247 12.644 141.82 12.644 170.06 0 10.8-4.8346 19.99-8.1945 20.422-7.4665s-0.1697 297.54-1.3368 659.59l-2.1219 658.26-21.586 9.5487c-21.433 9.4809-96.016 16.476-123.17 11.552z"
                fill-opacity=".99216"
                stroke-opacity=".74902"
                stroke-width="15.565"
              />
            </g>
            <path d="m80.228 13.805h43.706" display="inline" fill="none" stroke-width=".52026" />
            <path d="m123.57 13.83 8.0558 60.458m-58.46-4.2646 6.6974-56.193" display="inline" stroke-width=".59714" />
            <ellipse
              cx="101.82"
              cy="13.019"
              rx="22.129"
              ry="7.1751"
              display="inline"
              fill="#fff"
              fill-opacity=".99216"
              stroke-opacity=".74902"
              stroke-width=".49277"
            />
          </g>
          <g fill="none">
            <path
              d="m123.95 17.207a22.129 7.1751 0 0 1-11.296 6.2565 22.129 7.1751 0 0 1-22.355-0.13077 22.129 7.1751 0 0 1-10.592-6.3846"
              display="inline"
              stroke="#000"
              stroke-opacity=".74902"
              stroke-width=".49277"
            />
            <path
              d="m85.093 21.681 14.317 20.532 2.8016 0.38004 2.748-0.26157 13.217-20.414"
              display="inline"
              stroke="#4d4d4d"
              stroke-dasharray="1.04053, 3.12158"
              stroke-width=".52026"
            />
            <path
              d="m64.162 61.852-0.0147 8.4455m8.8176-0.17036 1.8016-16.735-3.1156-0.07128"
              display="inline"
              stroke="#000"
              stroke-width=".51516"
            />
            <path
              d="m74.55 56.536c0.0055-0.43194 0.06378-0.57677 0.13037-0.32238 0.0664 0.25476 0.0617 0.60806-0.01105 0.78524-0.07176 0.17822-0.12591-0.03144-0.12056-0.46286z"
              display="inline"
              stroke="#000"
              stroke-width="1.8492"
            />
          </g>
          <g fill-opacity=".99216" stroke="#000">
            <path
              d="m72.759 53.572 0.90827 0.06081c0.67136 0.04193 1.2349 0.03145 1.2491 0.04193m-10.476 15.985c-0.45793-1.1627 0.10227-0.85904 0.17178-2.4646l0.0542-4.4896 0.2948 0.26524c0.63622 0.57242 1.3886 0.9848 2.2423 1.2296 0.67027 0.1908 1.8401 0.23903 2.515 0.10431 1.3955-0.28306 2.4052-1.0064 2.9524-2.11 1.6752-2.8777 0.84138 5.7734 0.48545 7.8287"
              display="inline"
              fill="#e6e6e6"
              stroke-width="1.8492"
            />
            <path
              d="m96.917 24.012c-2.4633-0.19091-4.3066-0.42758-6.5429-0.84324-1.8022-0.3347-4.9062-1.165-5.3188-1.4223-0.12168-0.07638-0.3617-0.16035-0.53312-0.18583-0.5896-0.09175-2.9276-1.4096-3.6879-2.079-0.41878-0.36856-0.86157-0.8677-0.98414-1.1095-0.31105-0.61317-0.46563-1.841-0.3348-2.6593l0.1104-1.8435 0.80064 1.2144c2.4753 2.2253 8.381 3.9289 15.655 4.5965 2.4675 0.2265 9.8544 0.55588 12.322 0.32961 7.0766-1.1438 11.81-2.0349 14.207-4.1452l0.94212-1.6733-0.0493 2.2195c0.0932 0.99418 0.0597 1.3477-0.17325 1.8392-1.1534 2.4307-6.8789 4.6192-14.413 5.509-2.6997 0.31892-9.3361 0.45914-12 0.25455z"
              display="inline"
              stroke-opacity=".74902"
              stroke-width=".16666"
            />
            <ellipse
              cx="101.99"
              cy="12.937"
              rx="20.991"
              ry="6.1285"
              display="inline"
              fill="#fff"
              stroke-opacity=".74902"
              stroke-width=".44355"
            />
          </g>
          <g fill="none" stroke="#000">
            <path
              d="m131.64 74.32a33.681 6.0693 0 0 1-16.84 5.2561 33.681 6.0693 0 0 1-33.681 0 33.681 6.0693 0 0 1-16.84-5.2561"
              display="inline"
              stroke-opacity=".74902"
              stroke-width=".55912"
            />
            <path
              d="m130.17 66.286a32.979 6.0733 0 0 1-32.8 6.0733 32.979 6.0733 0 0 1-33.155-6.0076"
              display="inline"
              stroke-opacity=".74902"
              stroke-width=".55345"
            />
            <path d="m73.514 70.13v8.2391" display="inline" stroke-width=".32198" />
          </g>
          <path
            d="m110.82 55.347v1.095c-0.0731 0.20339-0.011 0.48435-0.0994 0.69712-0.41149 3.5843-3.4781 6.8402-7.036 7.4674-0.36169 0.14258-0.78987 0.13996-1.1615 0.19867h-1.3607c-0.37176-0.06081-0.79989-0.05608-1.1616-0.19867-3.8366-0.66048-6.9613-4.3575-7.1355-8.1976v-1.1283c0.12166-0.75006 0.2762-1.53 0.57248-2.2469 0.30005-0.57729 0.60902-1.1515 0.96246-1.6926l1.1284-1.5267c2.524-3.0002 4.7343-6.2227 6.3971-9.7342l0.45803 0.97075c1.374 3.3471 3.5412 6.3108 5.9076 9.0953 0.25553 0.37532 0.531 0.74016 0.8297 1.0952 0.44473 0.68365 0.87949 1.374 1.228 2.1241 0.23903 0.63401 0.38833 1.3177 0.4713 1.9815z"
            display="inline"
            stroke="#000"
            stroke-width=".033188"
          />
          <g fill="#e7e7e7" fill-opacity=".99216" stroke="#000">
            <path
              d="m72.741 64.567c-0.02046-1.6962 0.25019-3.2129 0.48676-3.8719-0.24359-0.98527 0.09528-0.21649-0.19752-1.1515-0.22933-0.73177-0.31556-1.6867-0.31514-3.4875v-2.4801h0.95594c0.52581 0 0.956 0.04718 0.956 0.1017 0 0.19343-0.98762 12.591-1.0555 12.884-0.03774 0.16302-0.81611-0.75038-0.83044-1.9934z"
              display="inline"
              stroke-width="1.8492"
            />
            <path
              d="m70.274 69.488c-2.6109-0.69764-4.3048-1.3816-5.11-2.0635l-0.4779-0.4052v-4.443l0.4779 0.46234c0.26288 0.25476 0.87496 0.64753 1.3603 0.8743 0.78195 0.36536 1.0495 0.41254 2.3528 0.41254 1.3731 0 1.5265-0.03145 2.316-0.46391l0.84554-0.46391v3.221c0 3.7138 0.19699 3.3931-1.7646 2.8691z"
              display="inline"
              stroke-width="1.8492"
            />
            <path
              d="m64.096 74.902v-7.6465c3.1861 1.8135 4.9017 2.3037 9.1171 3.235l0.29407 7.7936c-3.8723-0.53908-6.7153-1.2255-9.4112-3.3821z"
              display="inline"
              stroke-width=".32198"
            />
          </g>
          <g fill-opacity=".99216">
            <path
              d="m89.023 79.839c-4.7879-0.26104-9.7846-0.75976-13.274-1.3248-0.99253-0.1604-1.9621-0.2925-2.0008-0.2925-0.03883 0-0.02833-1.6211-0.02833-3.6026 0-3.3333-0.3379-3.7288-0.09987-3.7286 0.14148 2.11e-4 1.2513 0.22383 1.9269 0.3423 1.7285 0.30403 5.909 0.77606 9.2856 1.0481 3.9161 0.31556 16.089 0.44294 20.606 0.21648 9.6628-0.48592 17.537-1.6919 21.691-3.3226 1.3602-0.53399 2.5932-1.2637 2.9174-1.7267 0.20538-0.29302 0.20921-0.2925 0.29045 0.05609 0.0451 0.19342 0.14311 0.88143 0.21739 1.5284 0.0743 0.64701 0.27201 2.1584 0.43954 3.3585 0.25428 1.8211 0.27267 2.2279 0.1117 2.4577-0.80414 1.1482-3.919 2.3437-8.3737 3.2142-3.6766 0.71825-7.4672 1.1825-13.416 1.6427-2.5701 0.19867-17.319 0.2967-20.293 0.13472z"
              display="inline"
              fill="#a5a7a8"
              stroke="#a5a7a8"
              stroke-opacity=".99216"
              stroke-width=".23528"
            />
            <path
              transform="matrix(.9579 -.28711 .4355 .90019 0 0)"
              d="m40.172 78.651a4.3739 4.0836 0 0 1-4.3116 3.786 4.3739 4.0836 0 0 1-4.4044-3.6917"
              display="inline"
              fill="#fff"
              stroke="#000"
              stroke-opacity=".74902"
              stroke-width=".53049"
            />
            <path
              transform="matrix(.94706 -.32106 .39327 .91942 0 0)"
              d="m43.418 79.4a3.9284 4.0156 0 0 1-4.3778 3.2238 3.9284 4.0156 0 0 1-3.3973-4.2847"
              display="inline"
              fill="#fff"
              stroke="#000"
              stroke-opacity=".74902"
              stroke-width=".32246"
            />
          </g>
        </g>
      </g>
      <!-- #endregion -->

      <!-- #region Schema 5 -->
      <g *ngIf="ctx?.schema[0] === 'move_device_from_hive_to_hive'" id="Move GPS | WG" transform="translate(0 -39.688)">
        <rect
          x=".92143"
          y="44.351"
          width="61.253"
          height="23.508"
          fill="none"
          stroke="#f00"
          stroke-dasharray="0.473116, 0.236558"
          stroke-width=".23656"
        />
        <g transform="translate(0 -3.7042)" fill="none" stroke="#000">
          <path transform="translate(0 -217.62)" d="m1.2095 290.45h103.23" marker-end="url(#Arrow2Lend)" stroke-width=".31553px" />
          <g>
            <path transform="translate(0 -217.62)" d="m12.441 288.3v4.4901" stroke-width=".316" />
            <path d="m41.739 70.926v2.3386" stroke-width=".22805" />
            <path d="m84.517 70.879v2.3854" stroke-width=".23032" />
          </g>
        </g>
        <g transform="translate(14.956 -4.3146)">
          <g transform="matrix(-.19464 -.0017787 -.006825 .20106 105.58 11.576)" stroke="#000">
            <g stroke="#000" stroke-linecap="round" stroke-linejoin="round">
              <path
                d="m108.64 270.91 64.168 18.06s0.36284 1.3507 0.28198 1.925c-0.0808 0.57437-0.76716 1.5212-0.76716 1.5212l-63.902-18.685s-0.20029-0.96168-0.1528-1.5112c0.044-0.5101 0.37168-1.31 0.37168-1.31"
                fill="#a5a7a8"
                stroke-width=".47571"
              />
              <path
                d="m172.81 289.11 9.8259-1.043s0.97381 0.0309 0.89725 1.323c-0.0766 1.2921-0.72306 1.6342-0.72306 1.6342l-10.296 1.28s-0.74767-0.19216-0.66865-1.6162c0.079-1.4241 0.96432-1.5779 0.96432-1.5779z"
                fill="#161315"
                stroke-width=".75618"
              />
              <path
                d="m108.79 270.95s-2.3352 0.27568 10.068-2.3354c13.312 4.0253 64.141 19.414 64.141 19.414l-10.675 1.2898z"
                fill="#a5a7a8"
                stroke-width=".47571"
              />
            </g>
            <g fill="#161315">
              <g stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width=".28852">
                <path d="m164.68 286.08 0.25113-2.1479" />
                <path d="m164.68 286.08 1.506 0.38987" />
                <path d="m166.19 286.47 0.25113-2.1479" />
                <path d="m164.94 283.94 1.506 0.38986" />
              </g>
              <path d="m165.04 284.11 1.2543 0.32537-0.22203 1.8646-1.2399-0.31746z" stroke="none" />
              <g stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width=".28852">
                <path d="m116.85 272.13 0.25114-2.1479" />
                <path d="m116.85 272.13 1.506 0.38986" />
                <path d="m118.36 272.52 0.25113-2.1479" />
                <path d="m117.1 269.98 1.506 0.38986" />
              </g>
              <path d="m117.21 270.16 1.2543 0.32537-0.22203 1.8646-1.2399-0.31746z" stroke="none" />
            </g>
            <g stroke="#000" stroke-linecap="round" stroke-linejoin="round">
              <path
                d="m109.11 266.23 64.168 18.06s0.36285 1.3507 0.28199 1.9251c-0.0808 0.57436-0.76717 1.5212-0.76717 1.5212l-63.902-18.685s-0.20028-0.96169-0.15279-1.5112c0.044-0.51011 0.37168-1.31 0.37168-1.31"
                fill="#a5a7a8"
                stroke-width=".47571"
              />
              <path
                d="m173.28 284.43 9.8259-1.043s0.97381 0.0309 0.89725 1.323c-0.0766 1.2921-0.72306 1.6342-0.72306 1.6342l-10.296 1.28s-0.74767-0.19216-0.66865-1.6162c0.079-1.4241 0.96432-1.5779 0.96432-1.5779z"
                fill="#161315"
                stroke-width=".75618"
              />
              <path
                d="m109.26 266.27s-2.3353 0.27568 10.068-2.3354c13.312 4.0252 64.141 19.414 64.141 19.414l-10.675 1.2898z"
                fill="#a5a7a8"
                stroke-width=".47571"
              />
            </g>
          </g>
          <g
            transform="matrix(.17245 0 0 .15836 47.968 40.857)"
            fill="#fff"
            stroke="#000"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="1.0583"
          >
            <path d="m71.267 161.05 12.818 2.0301v12.404l-12.818-2.0301z" />
            <path d="m147.7 147.2-63.613 15.881v12.404l63.474-16.695z" />
          </g>
          <g stroke="#000" stroke-linecap="round" stroke-linejoin="round">
            <g fill="#fff" stroke-width=".228">
              <path d="m61 65.537 1.6599-0.41483 8.216 1.3013-0.93201 0.23238 0.0081 0.29902z" />
              <path d="m60.272 65.12 1.688-0.42286 0.70004 0.12403-0.01506 0.28834-1.6379 0.43104-0.0047-0.30174z" />
              <path d="m62.66 64.821 0.0078 0.29911 8.2082 1.3032 0.69141-0.1964z" />
            </g>
            <path d="m60.992 65.242 1.6681-0.42114" fill="none" stroke-width=".228" />
            <g>
              <path
                d="m70.633 66.786v1.4942l-10.355-1.6751v-1.4942l0.7336 0.11868-1e-6 0.30211 8.8876 1.4377v-0.30211z"
                fill="#fff"
                stroke-width=".22892"
              />
              <g stroke-width=".228">
                <path d="m70.689 66.817-0.01977 1.4438 10.908-3.1327 0.03954-11.651-9.2791 2.3757 0.09885 10.477z" fill="#fffffc" />
                <path d="m72.46 66.357-10.562-1.7044 0.05058-10.521 10.427 1.7335z" fill="#fff" />
                <path
                  d="m75.698 59.456s-0.14868 0.11983-0.21697 0.2722c-0.05278 0.11776-0.02922 0.2606-0.02922 0.34728 0 0.08817 0.01365 0.22287 0.07315 0.32889 0.06348 0.11312 0.17304 0.19488 0.17304 0.19488l3.4301-0.87557s0.15527-0.13786 0.23459-0.29548c0.0695-0.1381 0.06435-0.2909 0.06435-0.38564 0-0.0966-0.03508-0.23376-0.11553-0.33043-0.06796-0.08166-0.18341-0.1317-0.18341-0.1317z"
                  fill="#fff"
                />
              </g>
              <path
                transform="matrix(.17235 -.0013215 0 .17235 53.957 46.388)"
                d="m43.886 34.616 62.464 8.969 55.731-13.205-58.761-11.394z"
                stroke-width="1.0001"
              />
              <path
                transform="matrix(.17235 -.0013215 0 .17235 53.957 46.388)"
                d="m162.11 45.619v-13.237l-55.569 12.882-62.789-8.9715v15.941l62.789 9.9447 55.569-13.855z"
                stroke-width="1.0001"
              />
            </g>
          </g>
          <g transform="translate(-18.932 -.94325)">
            <path
              d="m70.432 70.198c0.79659 1.6835 3.5182 1.961 3.5573-4.3462-0.40317-4.386 6.031-4.4937 6.031-4.4937"
              fill="none"
              marker-end="url(#Arrow2Mend-1-5)"
              stroke="#000"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width=".265"
            />
            <g transform="translate(49.79 .55313)">
              <path
                d="m29.981 66.394h-1.2568v1.2568h-0.62839v-1.2568h-1.2568v-0.62839h1.2568v-1.2568h0.62839v1.2568h1.2568m-1.571-2.8277a3.1419 3.1419 0 0 0-3.1419 3.1419 3.1419 3.1419 0 0 0 3.1419 3.1419 3.1419 3.1419 0 0 0 3.1419-3.1419 3.1419 3.1419 0 0 0-3.1419-3.1419z"
                fill="#43b05c"
                stroke-width=".31419"
              />
              <path
                d="m28.104 67.022v-0.62839h-1.2568v-0.62838h1.2568v-1.2568h0.61185v1.2568h1.2568v0.62838h-1.2568v1.2568h-0.61185z"
                fill="#fff"
                stroke-width=".0039357"
              />
            </g>
          </g>
          <text
            transform="skewY(10)"
            x="66.567535"
            y="50.885651"
            fill="#000000"
            font-size="5.1693px"
            stroke-width=".10252"
            text-align="center"
            text-anchor="middle"
            xml:space="preserve"
          >
            <tspan x="66.567535" y="50.885651" fill="#000000" stroke-width=".10252">B</tspan>
          </text>
        </g>
        <g transform="translate(-30.771 -4.2183)">
          <g transform="matrix(-.19464 -.0017787 -.006825 .20106 122.49 13.048)" stroke="#000">
            <g stroke="#000" stroke-linecap="round" stroke-linejoin="round">
              <path
                d="m108.64 270.91 64.168 18.06s0.36284 1.3507 0.28198 1.925c-0.0808 0.57437-0.76716 1.5212-0.76716 1.5212l-63.902-18.685s-0.20029-0.96168-0.1528-1.5112c0.044-0.5101 0.37168-1.31 0.37168-1.31"
                fill="#a5a7a8"
                stroke-width=".47571"
              />
              <path
                d="m172.81 289.11 9.8259-1.043s0.97381 0.0309 0.89725 1.323c-0.0766 1.2921-0.72306 1.6342-0.72306 1.6342l-10.296 1.28s-0.74767-0.19216-0.66865-1.6162c0.079-1.4241 0.96432-1.5779 0.96432-1.5779z"
                fill="#161315"
                stroke-width=".75618"
              />
              <path
                d="m108.79 270.95s-2.3352 0.27568 10.068-2.3354c13.312 4.0253 64.141 19.414 64.141 19.414l-10.675 1.2898z"
                fill="#a5a7a8"
                stroke-width=".47571"
              />
            </g>
            <g fill="#161315">
              <g stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width=".28852">
                <path d="m164.68 286.08 0.25113-2.1479" />
                <path d="m164.68 286.08 1.506 0.38987" />
                <path d="m166.19 286.47 0.25113-2.1479" />
                <path d="m164.94 283.94 1.506 0.38986" />
              </g>
              <path d="m165.04 284.11 1.2543 0.32537-0.22203 1.8646-1.2399-0.31746z" stroke="none" />
              <g stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width=".28852">
                <path d="m116.85 272.13 0.25114-2.1479" />
                <path d="m116.85 272.13 1.506 0.38986" />
                <path d="m118.36 272.52 0.25113-2.1479" />
                <path d="m117.1 269.98 1.506 0.38986" />
              </g>
              <path d="m117.21 270.16 1.2543 0.32537-0.22203 1.8646-1.2399-0.31746z" stroke="none" />
            </g>
            <g stroke="#000" stroke-linecap="round" stroke-linejoin="round">
              <path
                d="m109.11 266.23 64.168 18.06s0.36285 1.3507 0.28199 1.9251c-0.0808 0.57436-0.76717 1.5212-0.76717 1.5212l-63.902-18.685s-0.20028-0.96169-0.15279-1.5112c0.044-0.51011 0.37168-1.31 0.37168-1.31"
                fill="#a5a7a8"
                stroke-width=".47571"
              />
              <path
                d="m173.28 284.43 9.8259-1.043s0.97381 0.0309 0.89725 1.323c-0.0766 1.2921-0.72306 1.6342-0.72306 1.6342l-10.296 1.28s-0.74767-0.19216-0.66865-1.6162c0.079-1.4241 0.96432-1.5779 0.96432-1.5779z"
                fill="#161315"
                stroke-width=".75618"
              />
              <path
                d="m109.26 266.27s-2.3353 0.27568 10.068-2.3354c13.312 4.0252 64.141 19.414 64.141 19.414l-10.675 1.2898z"
                fill="#a5a7a8"
                stroke-width=".47571"
              />
            </g>
          </g>
          <g stroke="#000" stroke-linecap="round" stroke-linejoin="round">
            <g fill="#fff" stroke-width=".228">
              <path d="m61 65.537 1.6599-0.41483 8.216 1.3013-0.93201 0.23238 0.0081 0.29902z" />
              <path d="m60.272 65.12 1.688-0.42286 0.70004 0.12403-0.01506 0.28834-1.6379 0.43104-0.0047-0.30174z" />
              <path d="m62.66 64.821 0.0078 0.29911 8.2082 1.3032 0.69141-0.1964z" />
            </g>
            <path d="m60.992 65.242 1.6681-0.42114" fill="none" stroke-width=".228" />
            <g>
              <path
                d="m70.633 66.786v1.4942l-10.355-1.6751v-1.4942l0.7336 0.11868-1e-6 0.30211 8.8876 1.4377v-0.30211z"
                fill="#fff"
                stroke-width=".22892"
              />
              <g stroke-width=".228">
                <path d="m70.689 66.817-0.01977 1.4438 10.908-3.1327 0.03954-11.651-9.2791 2.3757 0.09885 10.477z" fill="#fffffc" />
                <path d="m72.46 66.357-10.562-1.7044 0.05058-10.521 10.427 1.7335z" fill="#fff" />
                <path
                  d="m75.698 59.456s-0.14868 0.11983-0.21697 0.2722c-0.05278 0.11776-0.02922 0.2606-0.02922 0.34728 0 0.08817 0.01365 0.22287 0.07315 0.32889 0.06348 0.11312 0.17304 0.19488 0.17304 0.19488l3.4301-0.87557s0.15527-0.13786 0.23459-0.29548c0.0695-0.1381 0.06435-0.2909 0.06435-0.38564 0-0.0966-0.03508-0.23376-0.11553-0.33043-0.06796-0.08166-0.18341-0.1317-0.18341-0.1317z"
                  fill="#fff"
                />
              </g>
              <path
                transform="matrix(.17235 -.0013215 0 .17235 53.957 46.388)"
                d="m43.886 34.616 62.464 8.969 55.731-13.205-58.761-11.394z"
                stroke-width="1.0001"
              />
              <path
                transform="matrix(.17235 -.0013215 0 .17235 53.957 46.388)"
                d="m162.11 45.619v-13.237l-55.569 12.882-62.789-8.9715v15.941l62.789 9.9447 55.569-13.855z"
                stroke-width="1.0001"
              />
            </g>
          </g>
          <g transform="translate(2.6981 -.52917)">
            <path
              d="m79.74 61.311s4.7523-0.62759 5.8933 0.37418c1.141 1.0018 3.087 5.7997 3.087 5.7997"
              fill="none"
              marker-end="url(#Arrow2Mend-1)"
              stroke="#000"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width=".265"
            />
            <g transform="translate(.42095 -1.3096)">
              <path
                d="m79.67 67.547h-3.142v-0.6284h3.142m-1.571-2.8278a3.142 3.142 0 0 0-3.142 3.142 3.142 3.142 0 0 0 3.142 3.142 3.142 3.142 0 0 0 3.142-3.142 3.142 3.142 0 0 0-3.142-3.142z"
                fill="#e04f5f"
                stroke-width=".3142"
              />
              <path d="m76.553 67.222v-0.30402h3.087v0.60804h-3.087z" fill="#fff" stroke-width=".011132" />
            </g>
          </g>
          <text
            transform="skewY(10)"
            x="66.567535"
            y="50.885651"
            fill="#000000"
            font-size="5.1693px"
            stroke-width=".10252"
            text-align="center"
            text-anchor="middle"
            xml:space="preserve"
          >
            <tspan x="66.567535" y="50.885651" fill="#000000" stroke-width=".10252">A</tspan>
          </text>
        </g>
        <g transform="translate(0 -3.7042)">
          <g transform="matrix(-.19464 -.0017787 -.006825 .20106 53.637 14.232)" stroke="#000">
            <g stroke="#000" stroke-linecap="round" stroke-linejoin="round">
              <path
                d="m143.69 255.79 64.168 18.06s0.36284 1.3507 0.28198 1.9251c-0.0808 0.57436-0.76717 1.5211-0.76717 1.5211l-63.902-18.685s-0.20028-0.96169-0.15279-1.5112c0.044-0.51011 0.37168-1.31 0.37168-1.31"
                fill="#a5a7a8"
                stroke-width=".47571"
              />
              <path
                d="m207.85 273.99 9.8259-1.043s0.9738 0.0309 0.89724 1.323c-0.0766 1.2921-0.72305 1.6342-0.72305 1.6342l-10.296 1.28s-0.74766-0.19215-0.66864-1.6162c0.079-1.4241 0.96431-1.5779 0.96431-1.5779z"
                fill="#161315"
                stroke-width=".75618"
              />
              <path
                d="m143.83 255.83s-2.3352 0.27567 10.068-2.3354c13.312 4.0253 64.141 19.414 64.141 19.414l-10.675 1.2898z"
                fill="#a5a7a8"
                stroke-width=".47571"
              />
            </g>
            <g fill="#161315">
              <g stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width=".28852">
                <path d="m199.73 270.96 0.25113-2.1479" />
                <path d="m199.73 270.96 1.506 0.38987" />
                <path d="m201.24 271.35 0.25114-2.1479" />
                <path d="m199.98 268.82 1.506 0.38987" />
              </g>
              <path d="m200.08 268.99 1.2543 0.32538-0.22203 1.8646-1.2399-0.31746z" stroke="none" />
              <g stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width=".28852">
                <path d="m151.9 257.01 0.25113-2.1479" />
                <path d="m151.9 257.01 1.506 0.38987" />
                <path d="m153.4 257.4 0.25113-2.1479" />
                <path d="m152.15 254.86 1.506 0.38986" />
              </g>
              <path d="m152.25 255.04 1.2543 0.32536-0.22203 1.8646-1.2399-0.31746z" stroke="none" />
            </g>
            <g stroke="#000" stroke-linecap="round" stroke-linejoin="round">
              <path
                d="m144.16 251.11 64.168 18.06s0.36284 1.3507 0.28198 1.925c-0.0808 0.57437-0.76717 1.5212-0.76717 1.5212l-63.902-18.685s-0.20029-0.96169-0.1528-1.5112c0.044-0.51011 0.37168-1.31 0.37168-1.31"
                fill="#a5a7a8"
                stroke-width=".47571"
              />
              <path
                d="m208.32 269.31 9.8259-1.043s0.9738 0.0309 0.89724 1.323c-0.0766 1.2921-0.72305 1.6342-0.72305 1.6342l-10.296 1.28s-0.74766-0.19214-0.66864-1.6162c0.079-1.4241 0.96431-1.578 0.96431-1.578z"
                fill="#161315"
                stroke-width=".75618"
              />
              <path
                transform="rotate(6.6687 73.401 279.76)"
                d="m140.5 243.11s-2.2874 0.545 9.7289-3.4888c13.689 2.4522 65.962 11.834 65.962 11.834l-10.453 2.5208z"
                fill="#a5a7a8"
                stroke-width=".47571"
              />
            </g>
          </g>
          <g
            transform="matrix(.17245 0 0 .15836 -10.787 40.144)"
            fill="#fff"
            stroke="#000"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="1.0583"
          >
            <path d="m71.267 161.05 12.818 2.0301v12.404l-12.818-2.0301z" />
            <path d="m147.7 147.2-63.613 15.881v12.404l63.474-16.695z" />
          </g>
          <g stroke="#000" stroke-linecap="round" stroke-linejoin="round">
            <g fill="#fff" stroke-width=".228">
              <path d="m2.2628 65.008 1.6599-0.41483 8.216 1.3013-0.93201 0.23238 0.0081 0.29902z" />
              <path d="m1.5348 64.591 1.688-0.42286 0.70004 0.12403-0.01506 0.28834-1.6379 0.43104-0.0047-0.30174z" />
              <path d="m3.9228 64.292 0.0078 0.29911 8.2082 1.3032 0.69141-0.1964z" />
            </g>
            <path d="m2.2547 64.713 1.6681-0.42114" fill="none" stroke-width=".228" />
            <g>
              <path
                d="m11.895 66.257v1.4942l-10.355-1.6751v-1.4942l0.7336 0.11868-1e-6 0.30211 8.8876 1.4377v-0.30211z"
                fill="#fff"
                stroke-width=".22892"
              />
              <path
                d="m11.952 66.288-0.01977 1.4438 10.908-3.1327 0.03954-11.651-9.2791 2.3757 0.09885 10.477z"
                fill="#fffffc"
                stroke-width=".228"
              />
              <path d="m13.722 65.828-10.562-1.7044 0.05058-10.521 10.427 1.7335z" fill="#fff" stroke-width=".228" />
              <path
                transform="matrix(.17235 -.0013215 0 .17235 -4.7805 45.859)"
                d="m43.886 34.616 62.464 8.969 55.731-13.205-58.761-11.394z"
                stroke-width="1.0001"
              />
              <path
                transform="matrix(.17235 -.0013215 0 .17235 -4.7805 45.859)"
                d="m162.11 45.619v-13.237l-55.569 12.882-62.789-8.9715v15.941l62.789 9.9447 55.569-13.855z"
                stroke-width="1.0001"
              />
              <path
                d="m16.96 58.927s-0.14868 0.11983-0.21697 0.2722c-0.05278 0.11776-0.02922 0.2606-0.02922 0.34728 0 0.08817 0.01365 0.22287 0.07315 0.32889 0.06348 0.11312 0.17304 0.19488 0.17304 0.19488l3.4301-0.87557s0.15527-0.13786 0.23459-0.29548c0.0695-0.1381 0.06435-0.2909 0.06435-0.38565 0-0.0966-0.03508-0.23376-0.11553-0.33043-0.06796-0.08166-0.18341-0.1317-0.18341-0.1317z"
                fill="#fff"
                stroke-width=".228"
              />
            </g>
          </g>
          <g transform="translate(-.14032 -.8419)">
            <path
              d="m20.386 68.464s1.6209 3.0599 3.3676 3.2741 4.3498 0 4.3498 0"
              fill="none"
              marker-start="url(#marker3227)"
              stroke="#000"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width=".26458px"
            />
            <g transform="translate(-3.1987 1.6861)">
              <path
                d="m29.981 66.394h-1.2568v1.2568h-0.62839v-1.2568h-1.2568v-0.62839h1.2568v-1.2568h0.62839v1.2568h1.2568m-1.571-2.8277a3.1419 3.1419 0 0 0-3.1419 3.1419 3.1419 3.1419 0 0 0 3.1419 3.1419 3.1419 3.1419 0 0 0 3.1419-3.1419 3.1419 3.1419 0 0 0-3.1419-3.1419z"
                fill="#43b05c"
                stroke-width=".31419"
              />
              <path
                d="m28.104 67.022v-0.62839h-1.2568v-0.62838h1.2568v-1.2568h0.61185v1.2568h1.2568v0.62838h-1.2568v1.2568h-0.61185z"
                fill="#fff"
                stroke-width=".0039357"
              />
            </g>
          </g>
          <text
            transform="skewY(10)"
            x="7.952776"
            y="60.885326"
            fill="#000000"
            font-size="5.1693px"
            stroke-width=".10252"
            text-align="center"
            text-anchor="middle"
            xml:space="preserve"
          >
            <tspan x="7.952776" y="60.885326" fill="#000000" stroke-width=".10252">A</tspan>
          </text>
        </g>
        <text
          x="17.43795"
          y="75.211388"
          fill="#000000"
          font-family="sans-serif"
          font-size="10.583px"
          letter-spacing="0px"
          stroke-width=".26458"
          word-spacing="0px"
          style="line-height: 1.25"
          xml:space="preserve"
        >
          <tspan x="17.43795" y="75.211388" font-size="3.5278px" stroke-width=".26458" text-align="left" text-anchor="middle">
            {{ ctx?.schema[1]['since_date'] | formatDate: 'short' }}
          </tspan>
        </text>
        <text
          x="62.856972"
          y="75.89817"
          fill="#000000"
          font-family="sans-serif"
          font-size="10.583px"
          letter-spacing="0px"
          stroke-width=".26458"
          word-spacing="0px"
          style="line-height: 1.25"
          xml:space="preserve"
        >
          <tspan x="62.856972" y="75.89817" font-size="3.5278px" stroke-width=".26458" text-align="center" text-anchor="middle">
            {{ today | formatDate: 'short' }}
          </tspan>
        </text>
        <path
          d="m41.724 68.985c-0.27037 4.7084 16.318 0.05888 21.092 3.4324 6.4103-3.3811 21.5 0.20906 21.723-3.4279"
          fill="none"
          stroke="#000"
          stroke-width=".30804"
        />
      </g>
      <!-- #endregion -->

      <!-- #region Schema 6 -->
      <g *ngIf="ctx?.schema[0] === 'move_device_from_wh_to_wh'" transform="translate(.029418 -39.69)">
        <rect
          x=".92143"
          y="44.351"
          width="61.253"
          height="23.508"
          fill="none"
          stroke="#f00"
          stroke-dasharray="0.473116, 0.236558"
          stroke-width=".23656"
        />
        <g transform="translate(0 -3.7042)" fill="none" stroke="#000">
          <path transform="translate(0 -217.62)" d="m1.2095 290.45h103.23" marker-end="url(#Arrow2Lend-9)" stroke-width=".31553px" />
          <g>
            <path transform="translate(0 -217.62)" d="m12.441 288.3v4.4901" stroke-width=".316" />
            <path d="m41.739 70.926v2.3386" stroke-width=".22805" />
            <path d="m84.517 70.879v2.3854" stroke-width=".23032" />
          </g>
        </g>
        <g transform="translate(14.163 -4.8438)">
          <g transform="translate(-1.9384 1.0184)" stroke="#000">
            <path
              d="m62.822 58.283v7.8226l8.3488-2.21 0.01169-4.1861z"
              fill="#d4d4d4"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width=".18468"
            />
            <path
              d="m62.892 66.07 8.2794-2.1746v3.6049l-8.3028-1.3489z"
              fill="#d4d4d4"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width=".18468"
            />
            <g transform="matrix(-.069994 -.00063964 -.0024544 .072303 84.011 46.965)">
              <g stroke="#000" stroke-linecap="round" stroke-linejoin="round">
                <path
                  d="m143.69 255.79 64.168 18.06s0.36284 1.3507 0.28198 1.9251c-0.0808 0.57436-0.76717 1.5211-0.76717 1.5211l-63.902-18.685s-0.20028-0.96169-0.15279-1.5112c0.044-0.51011 0.37168-1.31 0.37168-1.31"
                  fill="#a5a7a8"
                  stroke-width=".47571"
                />
                <path
                  d="m207.85 273.99 9.8259-1.043s0.9738 0.0309 0.89724 1.323c-0.0766 1.2921-0.72305 1.6342-0.72305 1.6342l-10.296 1.28s-0.74766-0.19215-0.66864-1.6162c0.079-1.4241 0.96431-1.5779 0.96431-1.5779z"
                  fill="#161315"
                  stroke-width=".75618"
                />
                <path
                  d="m143.83 255.83s-2.3352 0.27567 10.068-2.3354c13.312 4.0253 64.141 19.414 64.141 19.414l-10.675 1.2898z"
                  fill="#a5a7a8"
                  stroke-width=".47571"
                />
              </g>
              <g fill="#161315">
                <g stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width=".28852">
                  <path d="m199.73 270.96 0.25113-2.1479" />
                  <path d="m199.73 270.96 1.506 0.38987" />
                  <path d="m201.24 271.35 0.25114-2.1479" />
                  <path d="m199.98 268.82 1.506 0.38987" />
                </g>
                <path d="m200.08 268.99 1.2543 0.32538-0.22203 1.8646-1.2399-0.31746z" stroke="none" />
                <g stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width=".28852">
                  <path d="m151.9 257.01 0.25113-2.1479" />
                  <path d="m151.9 257.01 1.506 0.38987" />
                  <path d="m153.4 257.4 0.25113-2.1479" />
                  <path d="m152.15 254.86 1.506 0.38986" />
                </g>
                <path d="m152.25 255.04 1.2543 0.32536-0.22203 1.8646-1.2399-0.31746z" stroke="none" />
              </g>
              <g stroke="#000" stroke-linecap="round" stroke-linejoin="round">
                <path
                  d="m144.16 251.11 64.168 18.06s0.36284 1.3507 0.28198 1.925c-0.0808 0.57437-0.76717 1.5212-0.76717 1.5212l-63.902-18.685s-0.20029-0.96169-0.1528-1.5112c0.044-0.51011 0.37168-1.31 0.37168-1.31"
                  fill="#a5a7a8"
                  stroke-width=".47571"
                />
                <path
                  d="m208.32 269.31 9.8259-1.043s0.9738 0.0309 0.89724 1.323c-0.0766 1.2921-0.72305 1.6342-0.72305 1.6342l-10.296 1.28s-0.74766-0.19214-0.66864-1.6162c0.079-1.4241 0.96431-1.578 0.96431-1.578z"
                  fill="#161315"
                  stroke-width=".75618"
                />
                <path
                  transform="rotate(6.6687 73.401 279.76)"
                  d="m140.5 243.11s-2.2874 0.545 9.7289-3.4888c13.689 2.4522 65.962 11.834 65.962 11.834l-10.453 2.5208z"
                  fill="#a5a7a8"
                  stroke-width=".47571"
                />
              </g>
            </g>
            <path
              d="m72.441 67.706-1.2924-0.22705-4.35e-4 -7.7521-8.2774-1.4002-0.01599 7.7997-0.97261-0.17592 0.05058-11.869 10.427 1.7831z"
              fill="#fff"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width=".228"
            />
            <path
              d="m81.577 65.128 0.03954-11.651-9.2791 2.3757 0.09885 11.868c3.0518-0.8463 6.0947-1.7261 9.1407-2.5933z"
              fill="#fffffc"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width=".228"
            />
            <g fill="#00d4d9" stroke-linecap="round" stroke-linejoin="round">
              <path d="m62.6 55.056v1.3915l2.5725 0.40926v-1.3798z" stroke-width=".265" />
              <path d="m69.113 56.108v1.3915l2.5725 0.40926v-1.3798z" stroke-width=".26458" />
              <path d="m65.843 55.597v1.3915l2.5725 0.40926v-1.3798z" stroke-width=".26458" />
            </g>
            <path
              d="m61.981 54.085 4.7941-1.3096 5.5893 3.087z"
              fill="#404040"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width=".18468"
            />
            <path
              d="m81.625 53.43-6.3376-2.8999-8.5096 2.2451 5.6097 3.1103z"
              fill="#414141"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width=".18468"
            />
          </g>
          <g transform="translate(-18.932 -.94325)">
            <path
              d="m70.432 70.198c0.79659 1.6835 3.5182 1.961 3.5573-4.3462-0.40317-4.386 4.1601-4.3534 4.1601-4.3534"
              fill="none"
              marker-end="url(#Arrow2Mend-1-5-0)"
              stroke="#000"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width=".265"
            />
            <g transform="translate(49.79 .55313)">
              <path
                d="m29.981 66.394h-1.2568v1.2568h-0.62839v-1.2568h-1.2568v-0.62839h1.2568v-1.2568h0.62839v1.2568h1.2568m-1.571-2.8277a3.1419 3.1419 0 0 0-3.1419 3.1419 3.1419 3.1419 0 0 0 3.1419 3.1419 3.1419 3.1419 0 0 0 3.1419-3.1419 3.1419 3.1419 0 0 0-3.1419-3.1419z"
                fill="#43b05c"
                stroke-width=".31419"
              />
              <path
                d="m28.104 67.022v-0.62839h-1.2568v-0.62838h1.2568v-1.2568h0.61185v1.2568h1.2568v0.62838h-1.2568v1.2568h-0.61185z"
                fill="#fff"
                stroke-width=".0039357"
              />
            </g>
          </g>
          <text
            transform="skewY(-10.155)"
            x="75.10006"
            y="77.219299"
            fill="#000000"
            font-size="5.1693px"
            stroke-width=".10252"
            text-align="center"
            text-anchor="middle"
            xml:space="preserve"
          >
            <tspan x="75.10006" y="77.219299" fill="#000000" stroke-width=".10252">B</tspan>
          </text>
        </g>
        <g transform="translate(-30.771 -4.2183)">
          <g transform="matrix(-.19464 -.0017787 -.006825 .20106 121.83 12.189)" stroke="#000">
            <g stroke="#000" stroke-linecap="round" stroke-linejoin="round">
              <path
                d="m108.64 270.91 64.168 18.06s0.36284 1.3507 0.28198 1.925c-0.0808 0.57437-0.76716 1.5212-0.76716 1.5212l-63.902-18.685s-0.20029-0.96168-0.1528-1.5112c0.044-0.5101 0.37168-1.31 0.37168-1.31"
                fill="#a5a7a8"
                stroke-width=".47571"
              />
              <path
                d="m172.81 289.11 9.8259-1.043s0.97381 0.0309 0.89725 1.323c-0.0766 1.2921-0.72306 1.6342-0.72306 1.6342l-10.296 1.28s-0.74767-0.19216-0.66865-1.6162c0.079-1.4241 0.96432-1.5779 0.96432-1.5779z"
                fill="#161315"
                stroke-width=".75618"
              />
              <path
                d="m108.79 270.95s-2.3352 0.27568 10.068-2.3354c13.312 4.0253 64.141 19.414 64.141 19.414l-10.675 1.2898z"
                fill="#a5a7a8"
                stroke-width=".47571"
              />
            </g>
            <g fill="#161315">
              <g stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width=".28852">
                <path d="m164.68 286.08 0.25113-2.1479" />
                <path d="m164.68 286.08 1.506 0.38987" />
                <path d="m166.19 286.47 0.25113-2.1479" />
                <path d="m164.94 283.94 1.506 0.38986" />
              </g>
              <path d="m165.04 284.11 1.2543 0.32537-0.22203 1.8646-1.2399-0.31746z" stroke="none" />
              <g stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width=".28852">
                <path d="m116.85 272.13 0.25114-2.1479" />
                <path d="m116.85 272.13 1.506 0.38986" />
                <path d="m118.36 272.52 0.25113-2.1479" />
                <path d="m117.1 269.98 1.506 0.38986" />
              </g>
              <path d="m117.21 270.16 1.2543 0.32537-0.22203 1.8646-1.2399-0.31746z" stroke="none" />
            </g>
            <g stroke="#000" stroke-linecap="round" stroke-linejoin="round">
              <path
                d="m109.11 266.23 64.168 18.06s0.36285 1.3507 0.28199 1.9251c-0.0808 0.57436-0.76717 1.5212-0.76717 1.5212l-63.902-18.685s-0.20028-0.96169-0.15279-1.5112c0.044-0.51011 0.37168-1.31 0.37168-1.31"
                fill="#a5a7a8"
                stroke-width=".47571"
              />
              <path
                d="m173.28 284.43 9.8259-1.043s0.97381 0.0309 0.89725 1.323c-0.0766 1.2921-0.72306 1.6342-0.72306 1.6342l-10.296 1.28s-0.74767-0.19216-0.66865-1.6162c0.079-1.4241 0.96432-1.5779 0.96432-1.5779z"
                fill="#161315"
                stroke-width=".75618"
              />
              <path
                d="m109.26 266.27s-2.3353 0.27568 10.068-2.3354c13.312 4.0252 64.141 19.414 64.141 19.414l-10.675 1.2898z"
                fill="#a5a7a8"
                stroke-width=".47571"
              />
            </g>
          </g>
          <g transform="translate(-.12356 .39624)" stroke="#000" stroke-linecap="round" stroke-linejoin="round">
            <g>
              <path d="m62.822 58.283v7.8226l8.3488-2.21 0.01169-4.1861z" fill="#d4d4d4" stroke-width=".18468" />
              <path d="m62.892 66.07 8.2794-2.1746v3.6049l-8.3028-1.3489z" fill="#d4d4d4" stroke-width=".18468" />
              <path
                d="m72.441 67.706-1.2924-0.22705-4.35e-4 -7.7521-8.2774-1.4002-0.01599 7.7997-0.97261-0.17592 0.05058-11.869 10.427 1.7831z"
                fill="#fff"
                stroke-width=".228"
              />
              <path
                d="m81.577 65.128 0.03954-11.651-9.2791 2.3757 0.09885 11.868c3.0518-0.8463 6.0947-1.7261 9.1407-2.5933z"
                fill="#fffffc"
                stroke-width=".228"
              />
            </g>
            <g fill="#00d4d9">
              <path d="m62.6 55.056v1.3915l2.5725 0.40926v-1.3798z" stroke-width=".265" />
              <path d="m69.113 56.108v1.3915l2.5725 0.40926v-1.3798z" stroke-width=".26458" />
              <path d="m65.843 55.597v1.3915l2.5725 0.40926v-1.3798z" stroke-width=".26458" />
            </g>
            <path d="m61.981 54.085 4.7941-1.3096 5.5893 3.087z" fill="#404040" stroke-width=".18468" />
            <path d="m81.625 53.43-6.3376-2.8999-8.5096 2.2451 5.6097 3.1103z" fill="#414141" stroke-width=".18468" />
          </g>
          <g transform="translate(2.6981 -.52917)">
            <path
              d="m79.343 61.047s5.5791-0.56145 6.7201 0.44032c1.141 1.0018 2.1609 5.1052 2.1609 5.1052"
              fill="none"
              marker-end="url(#Arrow2Mend-1-8)"
              stroke="#000"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width=".265"
            />
            <g transform="translate(.42095 -1.3096)">
              <path
                d="m79.67 67.547h-3.142v-0.6284h3.142m-1.571-2.8278a3.142 3.142 0 0 0-3.142 3.142 3.142 3.142 0 0 0 3.142 3.142 3.142 3.142 0 0 0 3.142-3.142 3.142 3.142 0 0 0-3.142-3.142z"
                fill="#e04f5f"
                stroke-width=".3142"
              />
              <path d="m76.553 67.222v-0.30402h3.087v0.60804h-3.087z" fill="#fff" stroke-width=".011132" />
            </g>
          </g>
          <text
            transform="matrix(-1 .17633 0 1 0 0)"
            x="-77.4207"
            y="76.697411"
            fill="#000000"
            font-size="5.1693px"
            stroke-width=".10252"
            text-align="center"
            text-anchor="middle"
            xml:space="preserve"
          >
            <tspan x="-77.4207" y="76.697411" fill="#000000" stroke-width=".10252">A</tspan>
          </text>
        </g>
        <g transform="translate(0 -3.7042)">
          <g transform="translate(-58.549 -.00045391)" stroke="#000">
            <path
              d="m62.822 58.283v7.8226l8.3488-2.21 0.01169-4.1861z"
              fill="#d4d4d4"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width=".18468"
            />
            <path
              d="m62.892 66.07 8.2794-2.1746v3.6049l-8.3028-1.3489z"
              fill="#d4d4d4"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width=".18468"
            />
            <g transform="matrix(-.069994 -.00063964 -.0024544 .072303 84.011 46.965)">
              <g stroke="#000" stroke-linecap="round" stroke-linejoin="round">
                <path
                  d="m143.69 255.79 64.168 18.06s0.36284 1.3507 0.28198 1.9251c-0.0808 0.57436-0.76717 1.5211-0.76717 1.5211l-63.902-18.685s-0.20028-0.96169-0.15279-1.5112c0.044-0.51011 0.37168-1.31 0.37168-1.31"
                  fill="#a5a7a8"
                  stroke-width=".47571"
                />
                <path
                  d="m207.85 273.99 9.8259-1.043s0.9738 0.0309 0.89724 1.323c-0.0766 1.2921-0.72305 1.6342-0.72305 1.6342l-10.296 1.28s-0.74766-0.19215-0.66864-1.6162c0.079-1.4241 0.96431-1.5779 0.96431-1.5779z"
                  fill="#161315"
                  stroke-width=".75618"
                />
                <path
                  d="m143.83 255.83s-2.3352 0.27567 10.068-2.3354c13.312 4.0253 64.141 19.414 64.141 19.414l-10.675 1.2898z"
                  fill="#a5a7a8"
                  stroke-width=".47571"
                />
              </g>
              <g fill="#161315">
                <g stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width=".28852">
                  <path d="m199.73 270.96 0.25113-2.1479" />
                  <path d="m199.73 270.96 1.506 0.38987" />
                  <path d="m201.24 271.35 0.25114-2.1479" />
                  <path d="m199.98 268.82 1.506 0.38987" />
                </g>
                <path d="m200.08 268.99 1.2543 0.32538-0.22203 1.8646-1.2399-0.31746z" stroke="none" />
                <g stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width=".28852">
                  <path d="m151.9 257.01 0.25113-2.1479" />
                  <path d="m151.9 257.01 1.506 0.38987" />
                  <path d="m153.4 257.4 0.25113-2.1479" />
                  <path d="m152.15 254.86 1.506 0.38986" />
                </g>
                <path d="m152.25 255.04 1.2543 0.32536-0.22203 1.8646-1.2399-0.31746z" stroke="none" />
              </g>
              <g stroke="#000" stroke-linecap="round" stroke-linejoin="round">
                <path
                  d="m144.16 251.11 64.168 18.06s0.36284 1.3507 0.28198 1.925c-0.0808 0.57437-0.76717 1.5212-0.76717 1.5212l-63.902-18.685s-0.20029-0.96169-0.1528-1.5112c0.044-0.51011 0.37168-1.31 0.37168-1.31"
                  fill="#a5a7a8"
                  stroke-width=".47571"
                />
                <path
                  d="m208.32 269.31 9.8259-1.043s0.9738 0.0309 0.89724 1.323c-0.0766 1.2921-0.72305 1.6342-0.72305 1.6342l-10.296 1.28s-0.74766-0.19214-0.66864-1.6162c0.079-1.4241 0.96431-1.578 0.96431-1.578z"
                  fill="#161315"
                  stroke-width=".75618"
                />
                <path
                  transform="rotate(6.6687 73.401 279.76)"
                  d="m140.5 243.11s-2.2874 0.545 9.7289-3.4888c13.689 2.4522 65.962 11.834 65.962 11.834l-10.453 2.5208z"
                  fill="#a5a7a8"
                  stroke-width=".47571"
                />
              </g>
            </g>
            <path
              d="m72.441 67.706-1.2924-0.22705-4.35e-4 -7.7521-8.2774-1.4002-0.01599 7.7997-0.97261-0.17592 0.05058-11.869 10.427 1.7831z"
              fill="#fff"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width=".228"
            />
            <path
              d="m81.577 65.128 0.03954-11.651-9.2791 2.3757 0.09885 11.868c3.0518-0.8463 6.0947-1.7261 9.1407-2.5933z"
              fill="#fffffc"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width=".228"
            />
            <g fill="#00d4d9" stroke-linecap="round" stroke-linejoin="round">
              <path d="m62.6 55.056v1.3915l2.5725 0.40926v-1.3798z" stroke-width=".265" />
              <path d="m69.113 56.108v1.3915l2.5725 0.40926v-1.3798z" stroke-width=".26458" />
              <path d="m65.843 55.597v1.3915l2.5725 0.40926v-1.3798z" stroke-width=".26458" />
            </g>
            <path
              d="m61.981 54.085 4.7941-1.3096 5.5893 3.087z"
              fill="#404040"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width=".18468"
            />
            <path
              d="m81.625 53.43-6.3376-2.8999-8.5096 2.2451 5.6097 3.1103z"
              fill="#414141"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width=".18468"
            />
          </g>
          <g transform="translate(-.8419 -1.3096)">
            <path
              d="m20.386 68.464s1.6209 3.0599 3.3676 3.2741 4.3498 0 4.3498 0"
              fill="none"
              marker-start="url(#marker3227-5)"
              stroke="#000"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width=".26458px"
            />
            <g transform="translate(-3.1987 1.6861)">
              <path
                d="m29.981 66.394h-1.2568v1.2568h-0.62839v-1.2568h-1.2568v-0.62839h1.2568v-1.2568h0.62839v1.2568h1.2568m-1.571-2.8277a3.1419 3.1419 0 0 0-3.1419 3.1419 3.1419 3.1419 0 0 0 3.1419 3.1419 3.1419 3.1419 0 0 0 3.1419-3.1419 3.1419 3.1419 0 0 0-3.1419-3.1419z"
                fill="#43b05c"
                stroke-width=".31419"
              />
              <path
                d="m28.104 67.022v-0.62839h-1.2568v-0.62838h1.2568v-1.2568h0.61185v1.2568h1.2568v0.62838h-1.2568v1.2568h-0.61185z"
                fill="#fff"
                stroke-width=".0039357"
              />
            </g>
          </g>
          <text
            transform="matrix(-1 .17633 0 1 0 0)"
            x="-18.789343"
            y="66.008911"
            fill="#000000"
            font-size="5.1693px"
            stroke-width=".10252"
            text-align="center"
            text-anchor="middle"
            xml:space="preserve"
          >
            <tspan x="-18.789343" y="66.008911" fill="#000000" stroke-width=".10252">A</tspan>
          </text>
        </g>
        <text
          x="17.43795"
          y="75.211388"
          fill="#000000"
          font-family="sans-serif"
          font-size="10.583px"
          letter-spacing="0px"
          stroke-width=".26458"
          word-spacing="0px"
          style="line-height: 1.25"
          xml:space="preserve"
        >
          <tspan x="17.43795" y="75.211388" font-size="3.5278px" stroke-width=".26458" text-align="left" text-anchor="middle">
            {{ ctx?.schema[1]['since_date'] | formatDate: 'short' }}
          </tspan>
        </text>
        <text
          x="62.856972"
          y="75.89817"
          fill="#000000"
          font-family="sans-serif"
          font-size="10.583px"
          letter-spacing="0px"
          stroke-width=".26458"
          word-spacing="0px"
          style="line-height: 1.25"
          xml:space="preserve"
        >
          <tspan x="62.856972" y="75.89817" font-size="3.5278px" stroke-width=".26458" text-align="center" text-anchor="middle">
            {{ today | formatDate: 'short' }}
          </tspan>
        </text>
        <path
          d="m41.724 68.985c-0.27037 4.7084 16.318 0.05888 21.092 3.4324 6.4103-3.3811 21.5 0.20906 21.723-3.4279"
          fill="none"
          stroke="#000"
          stroke-width=".30804"
        />
      </g>
      <!-- #endregion -->

      <!-- #region Schema 6 -->
      <g *ngIf="ctx?.schema[0] === 'takeout_device__from_wh__drop_data'" transform="translate(-.039707 -39.653)">
        <g transform="translate(0 42.333)">
          <path
            transform="translate(0 -217.62)"
            d="m1.2095 245.47h103.23"
            fill="none"
            marker-end="url(#marker5490-6)"
            stroke="#000"
            stroke-width=".316"
          />
          <path transform="translate(0 -217.62)" d="m12.441 243.32v4.4901" fill="none" stroke="#000" stroke-width=".316" />
          <text
            transform="translate(0 -42.333)"
            x="0.81416613"
            y="75.810165"
            fill="#000000"
            font-family="sans-serif"
            font-size="10.583px"
            letter-spacing="0px"
            stroke-width=".26458"
            word-spacing="0px"
            style="line-height: 1.25"
            xml:space="preserve"
          >
            <tspan x="0.81416613" y="75.810165" font-size="3.5278px" stroke-width=".26458">
              {{ ctx?.schema[1]['since_date'] | formatDate: 'short' }}
            </tspan>
          </text>
        </g>
        <g transform="matrix(1.2623 0 0 1.2623 -76.283 -19.156)" stroke="#000">
          <path
            d="m62.822 58.283v7.8226l8.3488-2.21 0.01169-4.1861z"
            fill="#d4d4d4"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width=".18468"
          />
          <path
            d="m62.892 66.07 8.2794-2.1746v3.6049l-8.3028-1.3489z"
            fill="#d4d4d4"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width=".18468"
          />
          <g transform="matrix(-.069994 -.00063964 -.0024544 .072303 84.011 46.965)">
            <g stroke="#000" stroke-linecap="round" stroke-linejoin="round">
              <path
                d="m143.69 255.79 64.168 18.06s0.36284 1.3507 0.28198 1.9251c-0.0808 0.57436-0.76717 1.5211-0.76717 1.5211l-63.902-18.685s-0.20028-0.96169-0.15279-1.5112c0.044-0.51011 0.37168-1.31 0.37168-1.31"
                fill="#a5a7a8"
                stroke-width=".47571"
              />
              <path
                d="m207.85 273.99 9.8259-1.043s0.9738 0.0309 0.89724 1.323c-0.0766 1.2921-0.72305 1.6342-0.72305 1.6342l-10.296 1.28s-0.74766-0.19215-0.66864-1.6162c0.079-1.4241 0.96431-1.5779 0.96431-1.5779z"
                fill="#161315"
                stroke-width=".75618"
              />
              <path
                d="m143.83 255.83s-2.3352 0.27567 10.068-2.3354c13.312 4.0253 64.141 19.414 64.141 19.414l-10.675 1.2898z"
                fill="#a5a7a8"
                stroke-width=".47571"
              />
            </g>
            <g fill="#161315">
              <g stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width=".28852">
                <path d="m199.73 270.96 0.25113-2.1479" />
                <path d="m199.73 270.96 1.506 0.38987" />
                <path d="m201.24 271.35 0.25114-2.1479" />
                <path d="m199.98 268.82 1.506 0.38987" />
              </g>
              <path d="m200.08 268.99 1.2543 0.32538-0.22203 1.8646-1.2399-0.31746z" stroke="none" />
              <g stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width=".28852">
                <path d="m151.9 257.01 0.25113-2.1479" />
                <path d="m151.9 257.01 1.506 0.38987" />
                <path d="m153.4 257.4 0.25113-2.1479" />
                <path d="m152.15 254.86 1.506 0.38986" />
              </g>
              <path d="m152.25 255.04 1.2543 0.32536-0.22203 1.8646-1.2399-0.31746z" stroke="none" />
            </g>
            <g stroke="#000" stroke-linecap="round" stroke-linejoin="round">
              <path
                d="m144.16 251.11 64.168 18.06s0.36284 1.3507 0.28198 1.925c-0.0808 0.57437-0.76717 1.5212-0.76717 1.5212l-63.902-18.685s-0.20029-0.96169-0.1528-1.5112c0.044-0.51011 0.37168-1.31 0.37168-1.31"
                fill="#a5a7a8"
                stroke-width=".47571"
              />
              <path
                d="m208.32 269.31 9.8259-1.043s0.9738 0.0309 0.89724 1.323c-0.0766 1.2921-0.72305 1.6342-0.72305 1.6342l-10.296 1.28s-0.74766-0.19214-0.66864-1.6162c0.079-1.4241 0.96431-1.578 0.96431-1.578z"
                fill="#161315"
                stroke-width=".75618"
              />
              <path
                transform="rotate(6.6687 73.401 279.76)"
                d="m140.5 243.11s-2.2874 0.545 9.7289-3.4888c13.689 2.4522 65.962 11.834 65.962 11.834l-10.453 2.5208z"
                fill="#a5a7a8"
                stroke-width=".47571"
              />
            </g>
          </g>
          <path
            d="m72.441 67.706-1.2924-0.22705-4.35e-4 -7.7521-8.2774-1.4002-0.01599 7.7997-0.97261-0.17592 0.05058-11.869 10.427 1.7831z"
            fill="#fff"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width=".228"
          />
          <path
            d="m81.577 65.128 0.03954-11.651-9.2791 2.3757 0.09885 11.868c3.0518-0.8463 6.0947-1.7261 9.1407-2.5933z"
            fill="#fffffc"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width=".228"
          />
          <g fill="#00d4d9" stroke-linecap="round" stroke-linejoin="round">
            <path d="m62.6 55.056v1.3915l2.5725 0.40926v-1.3798z" stroke-width=".265" />
            <path d="m69.113 56.108v1.3915l2.5725 0.40926v-1.3798z" stroke-width=".26458" />
            <path d="m65.843 55.597v1.3915l2.5725 0.40926v-1.3798z" stroke-width=".26458" />
          </g>
          <path
            d="m61.981 54.085 4.7941-1.3096 5.5893 3.087z"
            fill="#404040"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width=".18468"
          />
          <path
            d="m81.625 53.43-6.3376-2.8999-8.5096 2.2451 5.6097 3.1103z"
            fill="#414141"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width=".18468"
          />
        </g>
        <g transform="matrix(1.3694 0 0 1.3694 -1.4432 -29.426)">
          <path
            d="m20.386 68.464s1.6209 3.0599 3.3676 3.2741 4.3498 0 4.3498 0"
            fill="none"
            marker-start="url(#marker3227-5-4)"
            stroke="#000"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width=".26458px"
          />
          <g transform="translate(-3.1987 1.6861)">
            <path
              d="m29.981 66.394h-1.2568v1.2568h-0.62839v-1.2568h-1.2568v-0.62839h1.2568v-1.2568h0.62839v1.2568h1.2568m-1.571-2.8277a3.1419 3.1419 0 0 0-3.1419 3.1419 3.1419 3.1419 0 0 0 3.1419 3.1419 3.1419 3.1419 0 0 0 3.1419-3.1419 3.1419 3.1419 0 0 0-3.1419-3.1419z"
              fill="#43b05c"
              stroke-width=".31419"
            />
            <path
              d="m28.104 67.022v-0.62839h-1.2568v-0.62838h1.2568v-1.2568h0.61185v1.2568h1.2568v0.62838h-1.2568v1.2568h-0.61185z"
              fill="#fff"
              stroke-width=".0039357"
            />
          </g>
        </g>
        <g
          transform="matrix(1.4421 0 0 1.0304 -61.34 -196.16)"
          fill="none"
          stroke="#f00"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width=".965"
        >
          <path d="m43.779 233.3 23.76 23.76" />
          <path d="m67.539 233.3-23.76 23.76" />
        </g>
      </g>
      <!-- #endregion -->
    </svg>
  </ng-container>

  <ng-template #error>
    <span class="error">Missing data for schema {{ ctx?.schema[0] }}</span>
  </ng-template>
</ng-container>
