import { isEmpty, isNil } from 'lodash-es';

import { BehaviorSubject, combineLatest, map } from 'rxjs';
import { replay } from '@bg2app/tools/rxjs';

import { parseDate } from 'app/misc/tools';

import { IZohoCRMNote } from '../interfaces';
import { ZohoBaseModel } from '../../../../abstract';

/** */
export class ZohoCRMNote extends ZohoBaseModel<IZohoCRMNote> {
  // #region -> (model basics)

  constructor() {
    super();
  }

  // #endregion

  /** */
  protected _owner$$ = new BehaviorSubject<IZohoCRMNote['Owner']>(null);

  /** */
  public owner$$ = this._owner$$.asObservable();

  /** */
  public set Owner(Owner: IZohoCRMNote['Owner']) {
    this._owner$$.next(Owner);
  }

  /** */
  public get Owner(): IZohoCRMNote['Owner'] {
    return this._owner$$.getValue();
  }

  /** */
  protected _parent_id$$ = new BehaviorSubject<IZohoCRMNote['Parent_Id']>(null);

  /** */
  public parent_id$$ = this._parent_id$$.asObservable();

  /** */
  public set Parent_Id(Parent_Id: IZohoCRMNote['Parent_Id']) {
    this._parent_id$$.next(Parent_Id);
  }

  /** */
  public get Parent_Id(): IZohoCRMNote['Parent_Id'] {
    return this._parent_id$$.getValue();
  }

  // #region -> (related user)

  /** */
  protected _note_content$$ = new BehaviorSubject<string>(null);

  /** */
  public note_content$$ = this._note_content$$.asObservable();

  /** */
  public set Note_Content(Note_Content: string) {
    this._note_content$$.next(Note_Content);
  }

  /** */
  public get Note_Content(): string {
    return this._note_content$$.getValue();
  }

  // #endregion

  // #region -> (note time)

  /** */
  private _createdTime$$ = new BehaviorSubject<Date>(null);

  /** */
  public created_time$$ = this._createdTime$$.asObservable();

  /** */
  public set Created_Time(Created_Time: Date) {
    if (isNil(Created_Time)) {
      this._createdTime$$.next(Created_Time);
      return;
    }

    const date = parseDate(Created_Time);
    this._createdTime$$.next(date);
  }

  /** */
  public get Created_Time(): Date {
    return this._createdTime$$.getValue();
  }

  // #endregion

  // #region -> (links)

  /** */
  public url$$ = combineLatest({ id: this.id$$, parent_id: this.parent_id$$ }).pipe(
    map(({ id, parent_id }) => {
      if (isNil(id) || isEmpty(id)) {
        return null;
      }

      if (isNil(parent_id?.id) || isEmpty(parent_id?.id)) {
        return null;
      }

      return `https://crm.zoho.eu/crm/org20067795631/tab/Accounts/${parent_id?.id}#noteDetails`;
    }),
    replay()
  );

  // #endregion
}
