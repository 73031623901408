import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'bg2-zoho-ticket-summary',
  templateUrl: './zoho-ticket-summary.component.html',
  styleUrls: ['./zoho-ticket-summary.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ZohoTicketSummaryComponent {
  // #region -> (component basics)

  constructor() {}

  // #endregion

  // #region -> (ticket)

  /** */
  private _ticket$$ = new BehaviorSubject<any>(null);

  /** */
  public ticket$$ = this._ticket$$.asObservable();

  @Input()
  public set ticket(ticket: any) {
    this._ticket$$.next(ticket);
  }

  public get ticket(): any {
    return this._ticket$$.getValue();
  }

  // #endregion
}
