<div class="bg-dialog">
  <dialog-body>
    <span>{{ 'VIEWS.GHOST.SHARE.GHOST_CONFIRM_DIALOG.The following events will be applied' | translate | colon }}</span>

    <br /><br />

    <div class="events-to-be-applied">
      <ng-container *ngFor="let event of events$$ | async">
        <bg2-event-link [event]="event"></bg2-event-link>
      </ng-container>
    </div>

    <br />

    <span>
      {{ 'VIEWS.GHOST.SHARE.GHOST_CONFIRM_DIALOG.The list above can be modified' | translate }}
      <a href="javascript:void(0)" (click)="modifyConfiguration()">
        {{ 'VIEWS.GHOST.SHARE.GHOST_CONFIRM_DIALOG.here' | translate }}.
      </a>
    </span>

    <br />
    <br />

    <span>
      <b>
        {{
          'VIEWS.GHOST.SHARE.GHOST_CONFIRM_DIALOG.Are you sure you want to apply all the above events to the current location ?' | translate
        }}
      </b>
    </span>
  </dialog-body>

  <div class="dialog-footer" >
    <bg2-button
      [type]="'cancel'"
      [message]="'ALL.COMMON.Cancel' | translate"
      [icon]="'mdi-close-thick'"
      (btn_click)="cancelInstallation()"
    ></bg2-button>
    <bg2-button
      [type]="'action'"
      [message]="'ALL.COMMON.Apply' | translate"
      [icon]="'mdi-check-bold'"
      (btn_click)="confirmInstallation()"
    ></bg2-button>
  </div>
</div>
