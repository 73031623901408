import { Inject, OnInit, OnDestroy, Renderer2, Component, ComponentRef, AfterViewInit, ChangeDetectionStrategy } from '@angular/core';
import { DOCUMENT } from '@angular/common';

import { clone, isNil, orderBy } from 'lodash-es';

import { marker as i18n } from '@biesbjerg/ngx-translate-extract-marker';

import {
  replay,
  waitForNotNilValue,
  distinctUntilRealChanged,
  create_replay_subject_with_first_value,
  keepSourceIfNoError,
} from '@bg2app/tools/rxjs';
import { map, take, switchMap, Observable, withLatestFrom, BehaviorSubject, Subscription, of, concat } from 'rxjs';

import { Beeguard2Api } from 'app/core';
import { OneOfEntity } from 'app/core/api/main/beeguard2-api-service';
import { ConsoleLoggerService } from 'app/core/console-logger.service';

import { Event, Entity } from 'app/models';

import { EventBasedCarouselSlideComponent } from '../event-carousel-slide/event-carousel-slide.component';
import {
  IAbstractCarouselConfig,
  AbstractCarouselContainerComponent,
} from '../../abstract-carousel/abstract-carousel-container/abstract-carousel-container.component';
import { ErrorHelperData } from 'app/widgets/widgets-reusables/errors/error-helper/error-helper.component';

/** */
interface EventBasedCarouselConfig extends IAbstractCarouselConfig {
  event_name: string;
  entity: OneOfEntity;

  legend: {
    /** */
    next?: string;

    /** */
    previous?: string;
  };

  /** */
  error: {
    /** */
    read_what: string;

    /** */
    write_what: string;

    /** */
    create: {
      /** */
      message: string;
    };
  };
}

/**
 * @module WidgetsReusableModule
 *
 * @description
 *
 * Creates a new event-based carousel component.
 *
 * The `EventCarouselContainerComponent` creates an event-based carousel component depending on
 * the event name. This component subcribes to the BeeGuard API to fetch the specific events to
 * display them in a live event-based carousel.
 *
 * @template EventType This is the type of the event. It is used for improved types inside and outside the component.
 */
@Component({
  selector: 'bg2-event-carousel-container',
  templateUrl: './event-carousel-container.component.html',
  styleUrls: ['./event-carousel-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EventBasedCarouselContainerComponent<EventType extends Event>
  extends AbstractCarouselContainerComponent<EventBasedCarouselSlideComponent<any>, EventBasedCarouselConfig>
  implements OnInit, AfterViewInit, OnDestroy
{
  // #region -> (component basics)

  /** */
  protected _logger = new ConsoleLoggerService('EventCarouselContainerComponent', false);

  /** */
  private _factice_slide_sub: Subscription = null;

  /** */
  private _entity_last_events_sub: Subscription = null;

  /** */
  private _entity_last_added_event_sub: Subscription = null;

  /** */
  private _entity_last_removed_event_sub: Subscription = null;

  /** */
  constructor(protected _renderer: Renderer2, protected _bg2Api: Beeguard2Api, @Inject(DOCUMENT) protected document: Document) {
    super();
  }

  /** */
  ngOnInit(): void {
    super.ngOnInit();
  }

  /** */
  ngAfterViewInit(): void {
    this.loadings.events$$.next(true);

    // Watch changes on total slides to add a factice one
    this._factice_slide_sub = this.carousel_slides_total$$.pipe(waitForNotNilValue(), distinctUntilRealChanged()).subscribe({
      next: total => {
        if (total === 0) {
          this.addSlide(0);
          this._has_factice_slide$.next(true);
        }
        if (total > 1 && this._has_factice_slide$.getValue()) {
          this.removeSlide(0);
          this._has_factice_slide$.next(false);
        }
      },
    });

    // Initialize the carousel with the two last evaluation events
    this._entity_last_events_sub = this.entity$$
      .pipe(
        waitForNotNilValue(),
        switchMap(apiary => this.event_name$$.pipe(switchMap(event_name => apiary?.fetch_last_event$$<EventType>(event_name, 0, 2)))),
        take(1)
      )
      .subscribe({
        next: events => {
          events.forEach(event => {
            this.new_card_group_input_data = {
              event_id: event.id,
              date: event.date,
            };

            this.addSlide();
          });

          this._slider$$.next(this.slider);
          this.loadings.events$$.next(false);
        },
      });

    // Watch added events
    this._entity_last_added_event_sub = this.entity$$
      .pipe(
        withLatestFrom(this.event_name$$),
        switchMap(([apiary, event_name]) => apiary.stream_last_added_event$$([event_name]))
      )
      .subscribe({
        next: event => {
          if (!isNil(event)) {
            let copy_of_carousel_items: { event_id: number; date: Date }[] = this.carousel_items.map(i => i.instance.static_parameters);

            copy_of_carousel_items.push({ event_id: event.id, date: event.date });
            copy_of_carousel_items = orderBy(copy_of_carousel_items, o => o.date, ['asc']);

            // Find the index where the event should be added
            const place_index = copy_of_carousel_items.findIndex(o => o.event_id === event.id);

            this.new_card_group_input_data = {
              date: event.date,
              event_id: event.id,
            };

            this.addSlide(place_index);
            this._slider$$.next(this.slider);
          }
        },
      });

    // Watch removed events
    this._entity_last_removed_event_sub = this.entity$$
      .pipe(
        withLatestFrom(this.event_name$$),
        switchMap(([apiary, event_name]) => apiary.stream_last_removed_event$$([event_name]))
      )
      .subscribe({
        next: event => {
          if (!isNil(event)) {
            const index_of_item_to_remove = this.carousel_items.findIndex(item => item.instance.static_parameters.event_id === event.id);

            if (index_of_item_to_remove >= 0) {
              const removed_refs = this.carousel_items.splice(index_of_item_to_remove, 1);
              const carousel_item_ref = removed_refs[0];

              // Desctroy component itself and from view container
              const index_in_container = this.container.indexOf(carousel_item_ref.hostView);
              this.container.remove(index_in_container);
              carousel_item_ref.destroy();

              if (this.current_index < 0) {
                this.current_index = this.current_index + 1;
              }

              if (this.carousel_items.length === 1) {
                this.loadPrevious(false);
              }

              this._carousel_items$$.next(this.carousel_items);
              this._slider$$.next(this.slider);
            }
          }
        },
      });

    super.ngAfterViewInit();
  }

  ngOnDestroy(): void {
    this._factice_slide_sub?.unsubscribe();
    this._entity_last_events_sub?.unsubscribe();
    this._entity_last_added_event_sub?.unsubscribe();
    this._entity_last_removed_event_sub?.unsubscribe();

    super.ngOnDestroy();
  }

  // #endregion

  // #region -> (loadings)

  public loadings = {
    events$$: create_replay_subject_with_first_value(true),
  };

  // #endregion

  // #region -> (event-based carousel linked entity)

  /**
   * Observes the linked entity to the carousel.
   */
  public entity$$: Observable<OneOfEntity> = this.carousel_config$$.pipe(
    map(carousel_config => carousel_config?.entity),
    waitForNotNilValue(),
    replay()
  );

  /** */
  public entity_id$$ = this.entity$$.pipe(switchMap(entity => entity.id$$));

  // #endregion

  // #region -> (event-based carousel config)

  /** */
  public label_next$$ = this.carousel_config$$.pipe(
    map(config => config?.legend?.next ?? i18n<string>('WIDGETS.WIDGETS_REUSABLES.CAROUSELS.EVENT_BASED_CAROUSEL.Next event'))
  );

  /** */
  public label_previous$$ = this.carousel_config$$.pipe(
    map(config => config?.legend?.previous ?? i18n<string>('WIDGETS.WIDGETS_REUSABLES.CAROUSELS.EVENT_BASED_CAROUSEL.Previous event'))
  );

  /** */
  public event_name$$ = this.carousel_config$$.pipe(
    map(config => config?.event_name),
    waitForNotNilValue()
  );

  /** */
  protected build_button_to_new_event(entity_id: number): any {
    throw new Error("'build_button_to_new_event()' not redefined !");
  }

  // #endregion

  // #region -> (event-based empty data)

  /** */
  private _has_factice_slide$ = new BehaviorSubject(false);

  /** */
  public has_factice_slide$$ = this._has_factice_slide$.asObservable();

  /** */
  public has_data$$ = this.has_factice_slide$$.pipe(map(has_factice_slide => !has_factice_slide));

  // #endregion

  /** */
  public error$$: Observable<ErrorHelperData> = this.carousel_config$$.pipe(
    map(config => config?.error),
    waitForNotNilValue(),
    switchMap(error_config =>
      this.entity$$.pipe(
        keepSourceIfNoError(entity => entity.user_acl.check_read_all_events$$({ what: error_config.read_what })),
        switchMap(entity => {
          if (entity instanceof Error) {
            return of(entity);
          }

          return this.has_data$$.pipe(
            switchMap(has_data => {
              if (has_data) {
                return of<ErrorHelperData>(null);
              }

              return entity.user_acl.check_write_all_events$$({ what: error_config.write_what }).pipe(
                map(() => {
                  const error = new ErrorHelperData([
                    {
                      type: 'span',
                      content: error_config.create.message,
                    },
                    this.build_button_to_new_event(entity.id),
                  ]);

                  return error;
                })
              );
            })
          );
        })
      )
    )
  );

  /**
   * @description
   *
   * Load the previous carousel's slide.
   */
  public loadPrevious(scroll = true): void {
    const total_of_loaded_events = clone(this.carousel_items.length);

    const timeout_move = () => {
      if (scroll) {
        // Move to the new slide
        setTimeout(() => {
          this._transition$$.next(true);
          this.current_index = this.current_index - 1;
          this.update();
        }, 1);
      }
    };

    // Check if has enough slides
    if (Math.abs(this.current_index) + 2 < total_of_loaded_events) {
      timeout_move();
      return;
    }

    // Check if new slide is needed (then move to new slide) ?
    this.entity$$
      .pipe(
        switchMap(apiary =>
          this.event_name$$.pipe(switchMap(event_name => apiary?.fetch_last_event$$<EventType>(event_name, total_of_loaded_events, 1)))
        ),
        take(1)
      )
      .subscribe({
        next: events => {
          if (events?.length > 0) {
            this._transition$$.next(false);
            this.new_card_group_input_data = {
              date: events[0].date,
              event_id: events[0].id,
            };

            this.addSlide();
            this.update();

            timeout_move();
          } else if (Math.abs(this.current_index) + 1 < total_of_loaded_events) {
            timeout_move();
          }
        },
      });
  }

  // #region -> (component creation)

  protected addSlide(at_position = 0): void {
    const carousel_item_ref: ComponentRef<EventBasedCarouselSlideComponent<any>> = this.container.createComponent(
      this.type_of_card_group_component,
      { index: at_position }
    );

    carousel_item_ref.instance.dynamic_parameters = this._dynamic_data;
    carousel_item_ref.instance.static_parameters = this.new_card_group_input_data;
    carousel_item_ref.hostView.detectChanges();

    this.carousel_items = [carousel_item_ref];
  }

  protected removeSlide(id: number): void {
    if (id >= 0) {
      const removed_refs = this.carousel_items.splice(id, 1);
      const carousel_item_ref = removed_refs[0];

      // Desctroy component itself and from view container
      const index_in_container = this.container.indexOf(carousel_item_ref.hostView);
      this.container.remove(index_in_container);
      carousel_item_ref.destroy();

      if (this.current_index < 0) {
        this.current_index = this.current_index + 1;
      }

      this._carousel_items$$.next(this.carousel_items);
      this._slider$$.next(this.slider);
    }
  }

  // #endregion

  // #region -> (ACE management)

  /** */
  public can_create_event$$ = concat(of(false), this.entity$$.pipe(switchMap(entity => entity?.user_acl?.can$$('write_all_events')))).pipe(
    distinctUntilRealChanged(),
    replay()
  );

  // #endregion
}
