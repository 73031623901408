import { Component, ChangeDetectionStrategy, OnInit, OnDestroy } from '@angular/core';

import {
  of,
  map,
  tap,
  take,
  merge,
  finalize,
  switchMap,
  catchError,
  Observable,
  combineLatest,
  withLatestFrom,
  BehaviorSubject,
  distinctUntilChanged,
  Subscription,
} from 'rxjs';
import { allTrue, catchErrorInDialog, distinctUntilRealChanged, replay, robustCombineLatest } from '@bg2app/tools/rxjs';

import { ISchema } from 'ngx-schema-form';
import { isNil, cloneDeep, difference, isEmpty } from 'lodash-es';
import { marker as i18n } from '@biesbjerg/ngx-translate-extract-marker';

import { Beeguard2Api, DeviceApi } from 'app/core';
import { DialogsService } from 'app/widgets/dialogs-modals';
import { ConsoleLoggerService } from 'app/core/console-logger.service';
import { ZohoDeskApiService } from 'app/core/services/zoho/zoho-desk-api.service';

import { DRDevice } from 'app/models';
import { DeviceSupportIssue, ListSupportResponse } from 'app/core/api-swagger/device';

import { AbstractDialogComponent, AbstractDialogParams } from 'app/widgets/dialogs-modals/abstract-dialog.component';
import { ZohoSearchDeskSelectOptions } from 'app/widgets/event-form/zoho-search-desk/zoho-search-desk-widget.component';
import { DeviceSupportType } from 'app/models/devices/interfaces/device-supports.iface';
import { build_template_simple_message, build_template_ticket_comment_header } from 'app/models/zoho/tools/template-builder';

/** */
interface DeviceXSupport {
  /** */
  device: DRDevice;

  /** */
  support: DeviceSupportIssue;
}

/** */
interface SelectableDevice {
  /** */
  name: string;

  /** */
  imei: number;

  /** */
  device: DRDevice;

  /** */
  disabled: boolean;
}

/** */
export interface DevicesSupportUpdateDialogParams extends AbstractDialogParams {
  /** */
  devices: DRDevice[];

  /** */
  support_type: DeviceSupportType;
}

@Component({
  selector: 'bg2-device-support-update-dialog',
  templateUrl: './device-support-update-dialog.component.html',
  styleUrls: ['device-support-update-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DevicesSupportsUpdateDialogComponent
  extends AbstractDialogComponent<DevicesSupportUpdateDialogParams, any>
  implements OnInit, OnDestroy
{
  // #region -> (dialog basics)

  /** */
  protected _logger = new ConsoleLoggerService('DevicesSupportsUpdateDialogComponent', false);

  /** */
  private _initial_data_sub: Subscription = null;

  constructor(
    private device_api: DeviceApi,
    private _bg2Api: Beeguard2Api,
    private readonly _dialogs: DialogsService,
    private readonly _desk_api: ZohoDeskApiService
  ) {
    super();
  }

  ngOnInit(): void {
    this._initial_data_sub = this.devices_supports$$.subscribe({
      next: devices_supports => {
        let form_model: any = {};

        if (devices_supports?.length > 0) {
          form_model = devices_supports[0]?.support;

          if (!isNil(form_model?.comment)) {
            form_model.comment = null;
          }
        }

        this._form_model$$.next(<any>form_model);
      },
    });
  }

  ngOnDestroy(): void {
    this._initial_data_sub?.unsubscribe();
  }

  /** */
  public support_type$$: Observable<string> = this.input_params$$.pipe(
    map(params => params.support_type),
    distinctUntilRealChanged(),
    replay()
  );

  public close(value: boolean): void {
    this.complete(value);
  }

  // #endregion

  // #region -> (devices management)

  /**
   * List of devices defined by dialog params.
   *
   * @note Do not use for operations.
   */
  public devices$$: Observable<DRDevice[]> = this.input_params$$.pipe(
    map(params => params.devices),
    replay()
  );

  /**
   * List of devices (with support) defined by dialog params.
   *
   * @note Do not use for operations.
   */
  public devices_supports$$ = this.devices$$.pipe(
    switchMap(devices => {
      if (devices.length === 0) {
        return of<DeviceXSupport[]>([]);
      }

      return this._fetch_supports_for_devices$(devices);
    }),
    replay()
  );

  // #endregion

  // #region -> (supports management)

  /** */
  private _fetch_supports_for_devices$(devices: DRDevice[]): Observable<DeviceXSupport[]> {
    const support_x_device$$ = devices.map(device =>
      this.device_api
        .fetch_device_supports$(device.imei, null, null, {
          is_open: true,
          type: this.input_params.support_type,
        })
        .pipe(
          map(response => (response?.supports ?? [null])[0]),
          map(support => <DeviceXSupport>{ device, support })
        )
    );

    return combineLatest(support_x_device$$);
  }

  /**
   * List of devices without created support.
   */
  public devices_without_support$$ = this.devices_supports$$.pipe(
    map(device_x_support_list =>
      device_x_support_list
        .filter(device_x_support => device_x_support?.support?.is_open === false || isNil(device_x_support?.support))
        .map(device_x_support => device_x_support?.device)
    ),
    replay()
  );

  /** */
  public has_devices_with_support$$ = this.devices_without_support$$.pipe(
    map(devices_without_support => devices_without_support.length === 0),
    replay()
  );

  // #endregion

  // #region -> (form schema management)

  /** */
  private readonly DEFAULT_SCHEMA: ISchema = {
    type: 'object',
    properties: {
      issue_id: {
        type: 'string',
        readOnly: true,
        widget: 'zoho-search-desk',
        label: i18n('VIEWS.DEVICES.SUPPORTS-DIALOG.Desk ticket ID'),
        options: <ZohoSearchDeskSelectOptions>{
          visible_only_for: 'superadmin',
          zoho_search_config: {
            search_in: 'tickets',
          },
        },
      },
      comment: {
        label: i18n('ALL.COMMON.Comment (visible on Zoho)'),
        type: 'string',
        widget: 'textarea',
      },
    },
    required: ['issue_id', 'comment'],
  };

  /** */
  public schema$$ = this.devices$$.pipe(
    tap(() => this._logger.debug('schema$$ (0) : ', this.DEFAULT_SCHEMA)),
    switchMap(devices => {
      const schema = cloneDeep(this.DEFAULT_SCHEMA);

      const devices_with_exploitation$$ = devices.map(device =>
        device.exploitation$$(this._bg2Api).pipe(map(exploitation => ({ device, exploitation })))
      );

      return combineLatest(devices_with_exploitation$$).pipe(
        switchMap(devices_with_exploitation => {
          const values$$ = devices_with_exploitation.map(_composite =>
            _composite.exploitation.zoho_desk_account$$.pipe(
              map(zoho_desk_account => {
                if (zoho_desk_account instanceof Error) {
                  return { device: _composite.device, exploitation: _composite.exploitation, desk_account_id: <string>null };
                }

                return { device: _composite.device, exploitation: _composite.exploitation, desk_account_id: zoho_desk_account?.id };
              })
            )
          );

          return combineLatest(values$$);
        }),
        map(devices_with_exploitation => {
          // Update issue_id schema
          (<ZohoSearchDeskSelectOptions>schema.properties.issue_id.options).zoho_search_config.tickets_config = {
            accountId: devices_with_exploitation[0].desk_account_id,
          };

          return schema;
        })
      );
    }),
    tap(schema => this._logger.debug('Final schema : ', schema)),
    replay()
  );

  // #endregion

  // #region -> (form management)

  /** */
  private _form_valid$$ = new BehaviorSubject<boolean>(false);

  /** */
  public form_valid$$ = this._form_valid$$.pipe(distinctUntilChanged(), replay());

  /** */
  public setFormValid(val: any): void {
    this._form_valid$$.next(val);
  }

  /** */
  private _form_model$$ = new BehaviorSubject<any>({});

  /** */
  public form_model$$ = this._form_model$$.asObservable();

  /** */
  public onFormModelChanged(event: any) {
    this._logger.debug(event);
    this._form_model$$.next(event);
  }

  // #endregion

  // #region -> (ticket management)

  /** */
  private selected_ticket$$ = this.form_model$$.pipe(
    map(form_model => form_model?.issue_id ?? null),
    distinctUntilRealChanged(),
    switchMap((issue_id: string) => {
      if (isNil(issue_id)) {
        return of(null);
      }

      return this._desk_api.fetch_module$('tickets', issue_id).pipe(catchError(() => of(null)));
    }),
    replay()
  );

  /** */
  public missing_devices_of_ticket$$ = this.selected_ticket$$.pipe(
    map(ticket => ticket?.cf_imeis ?? null),
    map(related_device_imeis => {
      if (isNil(related_device_imeis) || isEmpty(related_device_imeis)) {
        return [];
      }

      const imeis = related_device_imeis.split(' ');
      return imeis.map(imei => parseInt(imei, 10));
    }),
    switchMap(related_device_imeis =>
      this.devices$$.pipe(
        map(devices => devices.map(device => device.imei)),
        map(selected_device_imeis => difference(related_device_imeis, selected_device_imeis)),
        take(1)
      )
    ),
    switchMap(missing_imeis => this.device_api.requestDevices(missing_imeis)),
    switchMap(devices => {
      const devices_with_opened_support$$ = devices.map(device =>
        device
          .has_type_of_supports$$(this.input_params.support_type)
          .pipe(map(has_type_of_supports => (has_type_of_supports ? device : null)))
      );

      return robustCombineLatest(devices_with_opened_support$$).pipe(
        map(devices_with_opened_support => devices_with_opened_support.filter(device => !isNil(device)))
      );
    }),
    replay()
  );

  // #endregion

  /**
   * Observes all devices (selected + ticket).
   */
  private _all_devices$$ = combineLatest({
    initial_devices: this.devices$$,
    missing_devices: this.missing_devices_of_ticket$$,
  }).pipe(
    map(({ initial_devices, missing_devices }) => [...(initial_devices ?? []), ...(missing_devices ?? [])]),
    replay()
  );

  // #region -> (comment devices)

  /** */
  private _create_selectable_devices(devices: DRDevice[]): SelectableDevice[] {
    return devices.map(device => {
      const item: SelectableDevice = {
        device,
        name: device.name,
        imei: device.imei,
        disabled: false,
      };

      return item;
    });
  }

  /** */
  public readonly available_devices_for_support$$ = this._all_devices$$.pipe(
    map(devices => this._create_selectable_devices(devices)),
    tap(device => console.log(device)),
    replay()
  );

  /** */
  private _selected_devices_to_comment$$ = new BehaviorSubject<number[]>([]);

  /**
   * Observes list of selected devies for the new support.
   */
  public selected_devices_to_comment$$ = this.devices$$.pipe(
    map(devices => {
      const items = this._create_selectable_devices(devices);
      return items.filter(item => !item.disabled).map(item => item.imei);
    }),
    take(1),
    tap(selected_imeis => (this.selected_devices_to_comment = selected_imeis)),
    switchMap(() => this._selected_devices_to_comment$$),
    distinctUntilRealChanged(),
    replay()
  );

  /** */
  public set selected_devices_to_comment(selected_devices_to_comment: number[]) {
    this._selected_devices_to_comment$$.next(selected_devices_to_comment);
  }

  // #endregion

  // #region -> (form submit management)

  /** */
  public is_valid$$ = allTrue(
    this.form_valid$$,
    this.selected_devices_to_comment$$.pipe(map(selected_devices => selected_devices?.length >= 1))
  );

  /** */
  public submit(): void {
    combineLatest([this.form_model$$.pipe(take(1)), this.selected_devices_to_comment$$.pipe(take(1))])
      .pipe(
        take(1),
        map(([form_model, selected_imeis]) => {
          if (selected_imeis?.length === 0) {
            throw new Error('No devices selected');
          }

          form_model.devices = selected_imeis;

          return form_model;
        }),
        switchMap(form_model =>
          this._all_devices$$.pipe(
            take(1),
            map(all_devices => all_devices.filter(device => (<number[]>form_model.devices).includes(device.imei))),
            switchMap(devices => {
              const device_names = devices.map(device => device.name);
              const device_names_joined = device_names.join(', ');

              const template_builder = [];
              template_builder.push(build_template_ticket_comment_header(`Commentaire "Retour Usine" pour ${device_names_joined}`));

              const comment = form_model?.comment;

              if (!isNil(comment) || !isEmpty(comment)) {
                template_builder.push(build_template_simple_message(null, comment));
              }

              return this._bg2Api.zohoApis.desk_api.create_ticket_comment$(form_model.issue_id, template_builder.join(''));
            })
          )
        )
      )
      .subscribe({
        next: response => this._logger.debug(response),
        error: () => {},
        complete: () => {
          this.close(true);
        },
      });
  }

  // #endregion

  public asType(value: any, type: 'device'): DRDevice;
  public asType(value: any, type: 'device'): any {
    if (value instanceof DRDevice) {
      return value;
    }

    return null;
  }
}
