<bg2-modal [fullscreen]="false" #modal>
  <bg2-modal-header>
    <div class="header">
      <span class="mdi mdi-auto-fix mdi-36px"></span>
      <span class="title hide-on-medium-and-up">{{ 'GHOST.CONFIG_MODAL.Customize' | translate }}</span>
      <span class="title hide-on-small-and-down">{{ 'GHOST.CONFIG_MODAL.Customize device installation' | translate }}</span>
    </div>
  </bg2-modal-header>

  <bg2-modal-content>
    <bg2-ghost-solutions 
      [solutions]="all_solutions$$ | async"
      [location]="location$$ | async"
      (validate)="close()">
    </bg2-ghost-solutions>
  </bg2-modal-content>

  <bg2-modal-footer>
    <div style="display: flex; flex-flow: row nowrap; flex: 1 1 0%">
      <bg2-button style="flex: 1 1 0%"
        [type]="'action'"
        [message]="'GHOST.CONFIG_MODAL.Preview before validation' | translate"
        [icon]="'mdi-eye'" (btn_click)="close()">
    </bg2-button>
    </div>
  </bg2-modal-footer>
</bg2-modal>
