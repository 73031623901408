import { isEqual } from 'lodash-es';

import { RunnerType } from './enumerators/runner-type';

export abstract class BaseRunner {
  public type = RunnerType.RUNNER_ABSTRACT;

  // #region -> (runner status)

  /**
   * Local reference to the total of updates.
   */
  private _nb_total: number;

  /**
   * Mutates the number of total updates.
   */
  public set nb_total(nb_total: number) {
    if (!isEqual(this._nb_total, nb_total)) {
      this._nb_total = nb_total;
    }
  }

  /**
   * Acesses the number of total updates.
   */
  public get nb_total(): number {
    return this._nb_total;
  }

  /**
   * Local reference to the number of remaining updates.
   */
  private _nb_remains: number;

  /**
   * Mutates the number of remaning updates.
   */
  public set nb_remains(nb_remains: number) {
    if (!isEqual(this._nb_remains, nb_remains)) {
      this._nb_remains = nb_remains;
    }
  }

  /**
   * Acesses the number of total updates.
   */
  public get nb_remains(): number {
    return this._nb_remains;
  }

  // #endregion

  // #region -> (runner error management)

  /**
   * Local reference to the error.
   */
  private _error: any;

  /**
   * Mutates the runner error.
   */
  public set error(error: any) {
    if (!isEqual(this._error, error)) {
      this._error = error;
    }
  }

  /**
   * Acesses the runner error.
   */
  public get error(): any {
    return this._error;
  }

  // #endregion


  constructor(total: number, remains: number) {
    this.nb_total = total;
    this.nb_remains = remains;
  };
}
