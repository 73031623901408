import { ChangeDetectionStrategy, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatBottomSheetRef, MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';

import { marker as i18n } from '@biesbjerg/ngx-translate-extract-marker';
import { MobileNetworkGeneration, MobileOperatorName } from 'app/models/anfr';
import { Dictionary } from 'app/typings/core/interfaces';

import { ISchema } from 'ngx-schema-form';

import { BehaviorSubject, Subscription } from 'rxjs';

@Component({
  selector: 'bg2-filter-antenna-sites-bottom-sheet',
  templateUrl: './filter-antenna-sites-bottom-sheet.component.html',
  styleUrls: ['./filter-antenna-sites-bottom-sheet.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FilterAntennatSitesBottomSheetComponent implements OnInit, OnDestroy {
  // #region -> (component basics)

  /** */
  private _sheet_backdrop_click_sub: Subscription = null;

  constructor(
    @Inject(MAT_BOTTOM_SHEET_DATA) private data: { mobile_network_antenna_sites: Dictionary<any> },
    private readonly _selfSheetRef: MatBottomSheetRef<FilterAntennatSitesBottomSheetComponent>
  ) {}

  ngOnInit(): void {
    this._source_model$$.next(this.data.mobile_network_antenna_sites);
    this._sheet_backdrop_click_sub = this._selfSheetRef.backdropClick().subscribe({
      next: () => {
        if (this._is_form_is_valid$$.getValue()) {
          this.close_sheet();
        }
      },
    });
  }

  ngOnDestroy(): void {
    this._sheet_backdrop_click_sub?.unsubscribe();
  }

  /** */
  public close_sheet(): void {
    this._selfSheetRef.dismiss(this._last_form_model);
  }

  // #endregion

  // #region -> (form management)

  /** */
  public readonly schema: ISchema = {
    type: 'object',
    properties: {
      display: {
        label: i18n<string>('VIEWS.LOCATIONS.COMPONENTS.PAGE_LOCATIONS.MAP_VIEW.FILTER_ANTENNA_SITES_SHEET.DISPLAY.Label'),
        type: 'boolean',
        widget: 'radio',
        oneOf: [
          {
            enum: [true],
            label: i18n<string>('ALL.COMMON.Yes'),
            options: {
              img: 'img/views/locations/components/page-locations/map-view/filter-antenna-sites-bottom-sheet/symbol-yes.png',
            },
          },
          {
            enum: [false],
            label: i18n<string>('ALL.COMMON.No'),
            options: {
              img: 'img/views/locations/components/page-locations/map-view/filter-antenna-sites-bottom-sheet/symbol-no.png',
            },
          },
        ],
      },
      adm_lb_nom: {
        type: 'array',
        widget: 'checklist',
        label: i18n<string>('VIEWS.LOCATIONS.COMPONENTS.PAGE_LOCATIONS.MAP_VIEW.FILTER_ANTENNA_SITES_SHEET.OPERATOR.Label'),
        minItems: 1,
        default: [MobileOperatorName.BOUYGUES],
        options: {
          display: 'button',
        },
        items: {
          type: 'string',
          oneOf: [
            {
              enum: [MobileOperatorName.BOUYGUES],
              label: i18n<string>(
                'VIEWS.LOCATIONS.COMPONENTS.PAGE_LOCATIONS.MAP_VIEW.FILTER_ANTENNA_SITES_SHEET.OPERATOR.Bouygues Telecom'
              ),

              image: 'img/views/locations/components/page-locations/map-view/filter-antenna-sites-bottom-sheet/logo-bouygues-telecom.svg',
              image_styles: {
                'max-width': '40px',
              },
            },
            {
              enum: [MobileOperatorName.SFR],
              label: i18n<string>('VIEWS.LOCATIONS.COMPONENTS.PAGE_LOCATIONS.MAP_VIEW.FILTER_ANTENNA_SITES_SHEET.OPERATOR.SFR'),

              image: 'img/views/locations/components/page-locations/map-view/filter-antenna-sites-bottom-sheet/logo-sfr.png',
              image_styles: {
                'max-width': '40px',
              },
            },
            {
              enum: [MobileOperatorName.ORANGE],
              label: i18n<string>('VIEWS.LOCATIONS.COMPONENTS.PAGE_LOCATIONS.MAP_VIEW.FILTER_ANTENNA_SITES_SHEET.OPERATOR.Orange'),

              image: 'img/views/locations/components/page-locations/map-view/filter-antenna-sites-bottom-sheet/logo-orange.png',
              image_styles: {
                'max-width': '40px',
              },
            },
          ],
        },
      },
      generation: {
        type: 'array',
        widget: 'checklist',
        label: i18n<string>('VIEWS.LOCATIONS.COMPONENTS.PAGE_LOCATIONS.MAP_VIEW.FILTER_ANTENNA_SITES_SHEET.GENERATION.Label'),
        minItems: 1,
        default: [MobileNetworkGeneration['2G']],
        options: {
          display: 'button',
        },
        items: {
          type: 'string',
          oneOf: [
            {
              enum: [MobileNetworkGeneration['2G']],
              label: i18n<string>('VIEWS.LOCATIONS.COMPONENTS.PAGE_LOCATIONS.MAP_VIEW.FILTER_ANTENNA_SITES_SHEET.GENERATION.2G'),

              image: 'img/views/locations/components/page-locations/map-view/filter-antenna-sites-bottom-sheet/generation-2G.svg',
              image_styles: {
                'max-width': '40px',
              },
            },
            {
              enum: [MobileNetworkGeneration['4G']],
              label: i18n<string>('VIEWS.LOCATIONS.COMPONENTS.PAGE_LOCATIONS.MAP_VIEW.FILTER_ANTENNA_SITES_SHEET.GENERATION.4G'),

              image: 'img/views/locations/components/page-locations/map-view/filter-antenna-sites-bottom-sheet/generation-4G.svg',
              image_styles: {
                'max-width': '40px',
              },
            },
          ],
        },
      },
    },
    required: ['display', 'adm_lb_nom', 'generation'],
  };

  /** */
  private _source_model$$ = new BehaviorSubject(null);

  /** */
  public source_model$$ = this._source_model$$.asObservable();

  private _last_form_model: Dictionary<any> = null;

  /** */
  public save_modified_data(updated_form_model: Dictionary<any>): void {
    this._last_form_model = updated_form_model;
  }

  /** */
  private _is_form_is_valid$$ = new BehaviorSubject(false);

  /** */
  public is_form_is_valid$$ = this._is_form_is_valid$$.asObservable();

  /** */
  public set is_form_is_valid(is_form_is_valid: boolean) {
    this._is_form_is_valid$$.next(is_form_is_valid);
  }

  // #endregion
}
