import { InjectionToken } from '@angular/core';

import { IEnvironment } from 'environments/common';
import { environment } from 'environments/environment';

/** */
export const ENV = new InjectionToken<IEnvironment>('env');

/** */
export function getEnv(): IEnvironment {
  return environment;
}
