import { ChangeDetectionStrategy, Component, ElementRef, ViewChild } from '@angular/core';

import { isNil } from 'lodash-es';
import { marker as i18n } from '@biesbjerg/ngx-translate-extract-marker';

import { BehaviorSubject, catchError, map, of, switchMap, take, tap } from 'rxjs';
import { replay, switchTap, waitForNotNilValue } from '@bg2app/tools/rxjs';

import { DeviceApi } from 'app/core';
import { DialogsService } from 'app/widgets/dialogs-modals';
import { AppStateService } from 'app/core/app-state.service';

import { DeviceModalAbstractCategoryComponent } from '../device-modal-abstract-details-or-summary.component';

import { SimpleSetterGetter } from 'app/models';
import { differenceInHours } from 'date-fns/esm';
import { ResizedEvent } from 'app/misc/directives/resized/resized.directive';

@Component({
  selector: 'bg2-device-modal-geoposition',
  templateUrl: './device-modal-geoposition.component.html',
  styleUrls: ['./device-modal-geoposition.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DeviceModalGeopositionComponent extends DeviceModalAbstractCategoryComponent {
  // #region -> (component basics)

  constructor(
    protected readonly _deviceApi: DeviceApi,
    protected readonly _dialogs: DialogsService,
    protected readonly _appStateService: AppStateService
  ) {
    super(_appStateService, _dialogs, _deviceApi);
  }

  // #endregion

  // #region -> (loadings)

  /** */
  public is_loading_owner = new SimpleSetterGetter(true);

  // #endregion

  // #region -> (ACL management)

  /** */
  public acl_error$$ = this.device$$.pipe(
    waitForNotNilValue(),
    switchTap(device =>
      // Check if the user can "read_devices"
      device.user_acl.throw__if_cannot$$('read_devices', i18n<string>('ALL.ACE.READ_DEVICES.WHAT.view the device'), 'exploitation')
    ),
    switchTap(device =>
      // Check if the user can "read_devices_last_position"
      device.user_acl.throw__if_cannot$$(
        'read_devices_last_position',
        i18n<string>("ALL.ACE.READ_DEVICES_LAST_POSITION.WHAT.view the device's last position"),
        'exploitation'
      )
    ),
    map(() => null),
    catchError((error: unknown) => of(error))
  );

  // #endregion

  // #region -> (device basics)

  /** */
  public device_has_oudated_com$$ = this.device$$.pipe(
    switchMap(device => {
      if (isNil(device)) {
        return of(null);
      }

      return device?.has_outdated_com$$;
    }),
    replay()
  );

  // #endregion

  // #region -> (device movement)

  /** */
  protected device_movement_div_size = new SimpleSetterGetter<ResizedEvent>(null);

  /** */
  private last_tracking_movement$$ = this.device$$.pipe(
    switchMap(device => {
      if (isNil(device)) {
        return of(null);
      }

      return device?.last_tracking_state$$;
    }),
    replay()
  );

  /** */
  public has_movement$$ = this.last_tracking_movement$$.pipe(
    map(
      last_tracking_movement =>
        (!isNil(last_tracking_movement) && last_tracking_movement?.tracking_state === 'MOVEMENT') ||
        last_tracking_movement?.tracking_state === 'PERIODIC_LOCATION'
    ),
    replay()
  );

  /** */
  public is_recent_movement$$ = this.last_tracking_movement$$.pipe(
    map(last_tracking_movement => {
      if (isNil(last_tracking_movement)) {
        return false;
      }

      return differenceInHours(new Date(), last_tracking_movement?.time) <= 3;
    }),
    replay()
  );

  // #endregion

  // #region -> (device geoposition)

  /** */
  private _device_location$$ = this.device$$.pipe(
    waitForNotNilValue(),
    switchMap(device => device?.geoposition$$),
    replay()
  );

  /** */
  private _device_location_type$$ = this._device_location$$.pipe(
    map(device_location => device_location?.type),
    replay()
  );

  /** */
  public has_geoposition_gps$$ = this.device$$.pipe(
    waitForNotNilValue(),
    switchMap(device => device?.has_geoposition_gps$$),
    replay()
  );

  /** */
  public has_geoposition_cellids$$ = this.device$$.pipe(
    waitForNotNilValue(),
    switchMap(device => device?.has_geoposition_cellids$$),
    replay()
  );

  // #endregion

  // #region -> (device position switcher)

  /** */
  private _selected_view_type$$ = new BehaviorSubject<'CELLIDS' | 'GPS'>(null);

  /** */
  public selected_view_type$$ = this._device_location_type$$.pipe(
    take(1),
    tap(device_location_type => (this.selected_view_type = device_location_type)),
    switchMap(() => this._selected_view_type$$.asObservable()),
    replay()
  );

  /** */
  public set selected_view_type(selected_view_type: 'CELLIDS' | 'GPS') {
    this._selected_view_type$$.next(selected_view_type);
  }

  /** */
  public device_position_from_type$$ = this.device$$.pipe(
    waitForNotNilValue(),
    switchMap(device =>
      this.selected_view_type$$.pipe(
        switchMap(selected_view_type => {
          if (selected_view_type === 'GPS') {
            return device?.geoposition_gps$$;
          }

          return device?.geoposition_cellids$$;
        })
      )
    ),
    replay()
  );

  // private _device_geoposition_time_from_type$$ = this.device$$.pipe(
  //   waitForNotNilValue(),
  //   switchMap(device =>
  //     this.selected_view_type$$.pipe(
  //       switchMap(selected_view_type => {
  //         if (selected_view_type === 'GPS') {
  //           return device?.geoposition_gps$$;
  //         }

  //         return device?.geoposition_cellids$$;
  //       })
  //     )
  //   )
  // );

  /** */
  // public is_device_geoposition_outdated$$ = this._device_geoposition_time_from_type$$.pipe(
  //   map(device_geoposition => {
  //     const timestamp = device_geoposition.timestamp;

  //     this.device.has_outdated_com

  //     const diff_in_days = differenceInDays(timestamp, new Date());
  //     const diff_in_days_abs = Math.abs(diff_in_days);

  //     if (diff_in_days_abs >= 4) {
  //       return true;
  //     }

  //     return false;
  //   }),
  //   replay()
  // );

  // #endregion
}
