import { formatDate } from '@angular/common';

import { isEmpty, isNil } from 'lodash-es';

import { map, Observable, of, switchMap, take } from 'rxjs';

import { TranslateService } from '@ngx-translate/core';

import { DeviceInterface, DeviceSupportNextUsage, DRDevice, __i18n_device_support_next_usage } from 'app/models/devices';
import { CloseTicketRUSupport, IZohoCreateDeskTicket } from 'app/models/zoho/desk';

import { build_template_simple_message } from '../base/template-simple-message';
import { build_template_ticket_comment_header } from '../base/template-ticket-comment-header';

/** */
export const build_template_support_close_ru$ = (
  ticket_ref: Partial<IZohoCreateDeskTicket>,
  support: CloseTicketRUSupport,
  translate_service: TranslateService
): Observable<string> =>
  of([build_template_ticket_comment_header()]).pipe(
    // Add support title to template
    map(template_builder => {
      if (support.review.ticket.is_partial) {
        if (support?.review?.ticket?.is_completing) {
          template_builder.push('<h1>Déclaration fin "Retour Usine" (complet)</h1>');
          return template_builder;
        }

        template_builder.push('<h1>Déclaration fin "Retour Usine" (partiel)</h1>');
        return template_builder;
      }

      template_builder.push('<h1>Déclaration fin "Retour Usine" (total)</h1>');
      return template_builder;
    }),

    // Add support properties to template
    switchMap(template_builder => {
      const translated_next_usage = '';

      // template_builder.push('<h3>Propriétés :</h3>');

      // template_builder.push(
      //   '<ul>',

      //   // `<li><b>Nouveau statut :</b> ${ticket_ref.status}</li>`,

      //   '</ul>'
      // );

      return get_device_summary_template$(support?.review?.date, support?.review?.by_device, { translate: translate_service }).pipe(
        map(summary => {
          template_builder.push(summary);
          return template_builder;
        })
      );
    }),

    // Add user comment to template
    map(template_builder => {
      const comment = support?.comment;

      if (!isNil(comment) || !isEmpty(comment)) {
        template_builder.push(build_template_simple_message('Commentaire', comment));
      }

      return template_builder;
    }),

    // Build template
    map(template_builder => template_builder.join(''))
  );

/** */
const get_device_summary_template$ = (
  end_date: string,
  review_by_device: CloseTicketRUSupport['review']['by_device'],
  services: {
    translate: TranslateService;
  }
): Observable<string> => {
  const non_excluded_devices: CloseTicketRUSupport['review']['by_device'] = review_by_device.filter(
    review => review?.device?.type !== 'CPT' && review?.device?.type !== 'BeeLive' && review?.device?.type !== 'CPTMC'
  );

  // Styles
  const table_th_td_styles = 'border: 1px solid gray;text-align: center;padding: 5px;word-wrap:break-word;';
  const table_th_styles = 'background-color: black; color: white;';

  return of(['<h3>Résumé</h3>']).pipe(
    switchMap(template_builder => {
      if (non_excluded_devices?.length === 0) {
        template_builder.push('<div><b>Aucun appareil</b></div>');
        return of(template_builder);
      }

      return of(template_builder).pipe(
        map(_template_builder => {
          _template_builder.push(
            '<table style="border-collapse: collapse;width: 100%;table-layout:fixed;">',

            `<thead><tr>`,
            `<th style="${table_th_td_styles}${table_th_styles}">Date fin RU</th>`,
            `<th style="${table_th_td_styles}${table_th_styles}">Appareil</th>`,
            `<th style="${table_th_td_styles}${table_th_styles}">Prochain usage</th>`,
            `<th style="${table_th_td_styles}${table_th_styles}">Remplacer par</th>`,
            `<th style="${table_th_td_styles}${table_th_styles}">Commentaire</th>`,
            `</tr></thead>`,

            '<tbody>'
          );

          return _template_builder;
        }),
        map(_template_builder => {
          const biuld_review_row = (review: CloseTicketRUSupport['review']['by_device'][0], is_first: boolean): string => {
            let device_row_template = '';

            device_row_template += '<tr>';

            if (is_first) {
              device_row_template += `<td style="${table_th_td_styles}" rowspan="${non_excluded_devices.length}">`;
              device_row_template += '<div style="display: flex; flex-flow: column nowrap;">';
              device_row_template += `<span>${formatDate(end_date, 'd MMMM y', 'fr-FR')}</span>`;
              device_row_template += `<span>${formatDate(end_date, 'HH:mm:ss zzzz', 'fr-FR')}</span>`;
              device_row_template += '</div>';
              device_row_template += '</td>';
            }

            device_row_template += `<td style="${table_th_td_styles}">`;
            device_row_template += '<div style="display: flex; flex-flow: column nowrap;">';
            device_row_template += `<span>${review.device.name}</span>`;
            device_row_template += '</div>';
            device_row_template += '</td>';

            // Next usage
            if (review?.next_usage?.replacement?.replace_device && review.next_usage?.replacement?.keep_old_device) {
              device_row_template += `<td style="${table_th_td_styles}">Conserver l'appareil remplacé dans l'entrepôt du client</td>`;
            } else {
              device_row_template += `<td style="${table_th_td_styles}">${services.translate.instant(
                __i18n_device_support_next_usage[review.next_usage.next_usage]
              )}</td>`;
            }

            // Replaced by device
            if (review?.next_usage?.replacement?.replace_device) {
              const replacement_data = review.next_usage.replacement;

              device_row_template += `<td style="${table_th_td_styles}">
                <div style="display: flex; flex-flow: column nowrap;">
                  <span><b>Nouvel appareil :</b> ${replacement_data.new_device.device.name} (${replacement_data.new_device.imei})</span>
                  ${
                    replacement_data?.reinstall_in_hive_or_apiary
                      ? '<span><b>Réinstaller le nouvel appareil dans la ruche ou le rucher</b></span>'
                      : ''
                  }
                </div>
              </td>`;
            } else {
              device_row_template += `<td style="${table_th_td_styles}">-</td>`;
            }

            // Comment
            if (review?.next_usage?.comment) {
              device_row_template += `<td style="${table_th_td_styles}">${review?.next_usage?.comment}</td>`;
            } else {
              device_row_template += `<td style="${table_th_td_styles}">-</td>`;
            }

            device_row_template += '</tr>';

            return device_row_template;
          };

          non_excluded_devices.forEach((review, index) => {
            _template_builder.push(biuld_review_row(review, index === 0));
          });

          return _template_builder;
        }),
        map(_template_builder => {
          _template_builder.push('</table>');
          return _template_builder;
        })
      );
    }),
    map(_template_builder => _template_builder.join('')),
    take(1)
  );
};
