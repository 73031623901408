<form-element-header [schema]="schema"></form-element-header>
<div class="widget-slider" [class.null-value]="__isNil(formProperty.value)">
  <div class="picto" *ngIf="schema.options?.img">
    <img [src]="'assets/' + schema.options?.img" width="90px" />
  </div>
  <ngx-slider
    *ngIf="!min_max"
    [ngModel]="value$$ | async"
    (ngModelChange)="value = $event"
    [options]="options"
  ></ngx-slider>
  <ngx-slider
    *ngIf="min_max"
    [value]="min$$ | async"
    (valueChange)="min = $event"
    [highValue]="max$$ | async"
    (highValueChange)="max = $event"
    [options]="options"
  ></ngx-slider>
  <bg2-button
  *ngIf="options.reset_btn && (not_nil_value$$ | async)"
  style="margin: 0 0 0px auto"
    [type]="'action'"
    [message]="'WIDGETS.EVENT_FORM.CONTROL.Don\'t specify' | translate"
    (btn_click)="reset()"
  >
  </bg2-button>
  <!-- TODOs: Should be in mat-hint -->
  <span class="description" *ngIf="schema.description">{{schema.description}}</span>
</div>
<form-element-previous
  [previous]="previous$$ | async "
  [previous_btn]="(value$$ | async)!==(previous$$ | async)?.value"
  (reset_previous)="resetPrevious()"
></form-element-previous>
