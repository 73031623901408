import { Component, Input, ChangeDetectionStrategy } from '@angular/core';

import { isEqual, isNil } from 'lodash-es';

import { TranslateService } from '@ngx-translate/core';

import { switchMap, shareReplay, tap, filter, map, debounceTime } from 'rxjs';
import { BehaviorSubject, Observable, combineLatest, of } from 'rxjs';
import { distinctUntilRealChanged } from '@bg2app/tools/rxjs';
import { replay } from '@bg2app/tools/rxjs';
import { Entity, Event } from 'app/models';

interface EntityAndEvent {
  event: Event;
  entity: Entity;
}

@Component({
  selector: 'bg2-event-link',
  templateUrl: './event-link.component.html',
  styleUrls: ['./event-link.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EventLinkComponent {

  @Input()
  public show_date = true;

  @Input()
  public set event(event: Event) {
    this._event$.next(event);
  }

  private _event$: BehaviorSubject<Event> = new BehaviorSubject(null);
  public event$$ = this._event$.asObservable().pipe(
    filter((event: Event) => !isNil(event)),
    tap((event: Event) => {
      this._has_error$.next(event.run && isEqual(event.run.state, 'error'));
    }),
    replay()
  );

  @Input()
  public set from(from: Entity) {
    this._from$.next(from);
  }

  private _from$: BehaviorSubject<Entity> = new BehaviorSubject(null);
  public from$$: Observable<Entity> = this._from$.asObservable().pipe(
    replay()
  );

  @Input()
  public desc: string;

  private _has_error$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public has_error$$: Observable<boolean> = this._has_error$.asObservable().pipe(
    distinctUntilRealChanged<boolean>(),
    replay()
  );

  public event_and_entity$$: Observable<EntityAndEvent> = combineLatest([
    this.from$$,
    this.event$$,
  ]).pipe(
    map(([entity, event]) => ({ entity, event }))
  );

  public event_tooltip$$: Observable<string> = combineLatest([
    this.event$$,
    this.has_error$$,
  ]).pipe(
    switchMap(([event, has_error]) => {
      let tooltip$: Observable<string>;
      if (has_error) {
        if (event.run?.error?.source?.event_id === event.id) {
          let key: string = event.type_i18n;
          if (event.run.error.source.error.description) {
            key = event.run.error.source.error.description;
          } else if (event.run.error.description) {
            key = event.run.error.description;
          }
          tooltip$ = this._translate.stream(key).pipe(
            switchMap(
              (error: any) => this._translate.stream('EVENT.ALL.ERROR.Error: [error]', { error })
            )
          );
        } else {
          tooltip$ = this._translate.stream('EVENT.ALL.ERROR.Error: in previous event');
        }
      } else {
        if (event.type === 'evaluation') {
          tooltip$ = this.event_and_entity$$.pipe(
            switchMap(event_n_entity => event_n_entity.event.getDesc(this._translate, event_n_entity?.entity?.id, this.desc, false))
          );
        } else {
          tooltip$ = this._translate.stream(event.type_i18n);
        }
      }
      return tooltip$;
    }),
    replay()
  );

  // #region -> (component basics)

  constructor(
    private _translate: TranslateService,
  ) { }

  // #endregion

}
