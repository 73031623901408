import { Component, ChangeDetectionStrategy } from '@angular/core';
import { replay } from '@bg2app/tools/rxjs';

import { assign as _assign } from 'lodash-es';
import { map } from 'rxjs';

import { AbstractDialogComponent, AbstractDialogParams } from '../abstract-dialog.component';
import { DialogButton, DialogElement } from '../dialog-parts/dialog-div/dialog-div.component';

/**
 * Interface of dialog footer.
 */
export interface DialogFooter<ReturnType = any> {
  styles?: { [key: string]: string };
  buttons?: {
    styles?: { [key: string]: string };
    items: DialogButton<ReturnType>[];
  };
}

/**
 * Interface for dialog properties.
 */
export interface CustomazibleDialogParams<ReturnType = any> extends AbstractDialogParams {
  body: DialogElement<ReturnType>;
  footer?: DialogFooter<ReturnType>;
}

export interface CustomazibleReturn<ReturnType = any> {
  return_value: ReturnType;
  form_values: any;
}

/**
 * Customazible dialog component.
 *
 * @notes This dialog returns `true` if user chooses yes else `false`.
 */
@Component({
  selector: 'bg2-dialog-customazible',
  templateUrl: './customazible.dialog.html',
  styleUrls: ['./customazible.dialog.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomazibleDialogComponent extends AbstractDialogComponent<CustomazibleDialogParams, any> {
  public _assigner = _assign;
  private _form_values: { [name: string]: any };

  constructor() {
    super();
  }

  public clicked(return_value: any): void {
    this.complete({ return_value, form_values: this._form_values });
  }

  public onFormValues(form_values: any): void {
    this._form_values = form_values;
  }

  public style_flex$$ = this.input_params$$.pipe(
    map(parameters => parameters?.footer?.buttons?.items?.length),
    map(length => `1 1 calc(100% / ${length ?? 0} )`),
    replay()
  );
}
