import { NavigationExtras, Router } from '@angular/router';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { Bg2ButtonType } from '../../buttons/button/button.component';

import { Dictionary } from 'app/typings/core/interfaces';
import { isNil } from 'lodash-es';
import { ZohoAuthService } from 'app/core/services/zoho/zoho-auth.service';

/** */
interface AbstractErrorElement {
  /** */
  translateParams?: Dictionary<any>;
}

/** */
interface ErrorSpanElement extends AbstractErrorElement {
  /** */
  type: 'span';

  /** */
  content: string;
  translateParams?: Dictionary<any>;
}

/** */
interface ErrorMarkdownElement extends AbstractErrorElement {
  /** */
  type: 'markdown';

  /** */
  content: string;
  translateParams?: Dictionary<any>;
}

/** */
interface ErrorAceElement extends AbstractErrorElement {
  /** */
  type: 'ace';

  /** */
  content: string;

  /** */
  translateParams: Dictionary<any> & { ace?: string; what?: string };
}

/** */
interface ErrorImageSVGElement extends AbstractErrorElement {
  /** */
  type: 'image_svg';

  /** */
  url: string;

  /** */
  styles?: Dictionary<any>;
}

/** */
export interface ErrorButtonElement extends AbstractErrorElement {
  /** */
  type: 'button';

  /** */
  message?: string;

  /** */
  action?: Bg2ButtonType;

  /** */
  icon?: `mdi-${string}`;

  /** */
  color?: string;

  /** */
  navigate?: { commands: any[]; extras?: NavigationExtras };

  /** */
  handler_name?: string;
}

/** */
type ErrorElement = ErrorSpanElement | ErrorMarkdownElement | ErrorAceElement | ErrorImageSVGElement | ErrorButtonElement;

/** */
export class ErrorHelperData extends Error {
  // #region -> (class basics)

  constructor(elements: ErrorElement[], short_message?: string) {
    super(short_message);
    this.description = elements;
  }

  // #endregion

  /** */
  description?: ErrorElement[];
}

@Component({
  selector: 'bg2-error-helper',
  templateUrl: './error-helper.component.html',
  styleUrls: ['./error-helper.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class Bg2ErrorHelperComponent {
  @Input()
  public error: ErrorHelperData = null;

  @Input()
  public with_background = true;

  // #region -> (component basics)

  constructor(private _router: Router, private readonly _zohoAuthService: ZohoAuthService) {}

  // #endregion

  public onClickButton(index: number): void {
    const error = this.error;
    const button = error.description[index] as ErrorButtonElement;

    if (!isNil(button?.handler_name)) {
      const handler_name = button?.handler_name;

      if (handler_name === 'login_to_zoho') {
        this._zohoAuthService.login();
      } else {
        throw new Error('Not existing handler name for error button');
      }

      return;
    }

    if (button?.navigate?.commands) {
      this._router.navigate(button?.navigate?.commands, button?.navigate?.extras || {});
      return;
    }
  }
}
