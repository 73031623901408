import { Observable, combineLatest, map } from 'rxjs';
import { distinctUntilRealChanged } from '../distinct-until-real-changed/distinct-until-real-changed';
import { replay } from '../replay/replay';
import { isNil } from 'lodash-es';

/**
 * Combine two or more boolean observables to check if there is at least one true.
 *
 * @param observables The list of boolean observables to use for checks.
 */
export const anyTrue = (...observables: Observable<boolean>[]): Observable<boolean> =>
  combineLatest(observables).pipe(
    map(values => !isNil(values.find(v => v === true))),
    distinctUntilRealChanged(),
    replay()
  );
