import { AfterViewInit, ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';

import { clone, cloneDeep, isNil, reverse, sortBy, sum, isEmpty, range } from 'lodash-es';

import { map } from 'rxjs';
import { BehaviorSubject, concat, Observable, of, Subscription } from 'rxjs';
import { distinctUntilRealChanged, replay } from '@bg2app/tools/rxjs';

import { Beeguard2Api } from '../../../core';

import { EfArrayWidget } from '../array/array.widget';

@Component({
  selector: 'bg2-bg2superbox-filling',
  templateUrl: './bg2superbox-filling.widget.html',
  styleUrls: ['./bg2superbox-filling.widget.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EfBg2SuperboxFillingWidgetComponent extends EfArrayWidget implements OnInit, AfterViewInit, OnDestroy {
  public options = {
    remove_btn_inline: false,
    supers_path: '../supers',
    show_add: true,
    show_remove: true
  };

  // #region -> (component basics)

  private _total_value_sub: Subscription = null;

  constructor(private _bg2Api: Beeguard2Api) {
    super(_bg2Api);
  }

  ngOnInit(): void {
    super.ngOnInit();

    this.filling_range$$ = this.formProperty.valueChanges.pipe(
      map(value => range(1, value?.length)),
      distinctUntilRealChanged(),
      replay()
    );
  }

  ngAfterViewInit(): void {
    super.ngAfterViewInit();

    setTimeout(() => {
      this.computeInitial();
    }, 100);
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  private unsubscribeTotalValue(): void {
    this._total_value_sub?.unsubscribe();
    this._total_value_sub = null;
  }

  // #endregion

  // #region -> (loading management)

  private _is_loading$$ = new BehaviorSubject<boolean>(true);
  public is_loading$$ = this._is_loading$$.asObservable().pipe(distinctUntilRealChanged(), replay());

  // #endregion

  // #region -> (filling management)

  public filling_range$$: Observable<any> = of(null);

  /**
   * Add a new filling repartition field.
   */
  public addFillingRepartition(): void {
    const value = this.formProperty.value;
    const avaiable = this.getAvailable();

    value.push({ filling: avaiable[0], nb_superbox: this.schema.items.properties.nb_superbox.default });
    value[0].nb_superbox -= this.schema.items.properties.nb_superbox.default;

    this.formProperty.setValue(value, false);
    this.recomputeSelectEnums();
  }

  /**
   * Remove a filling repartition field.
   * @param idx The ID of the filling repartition field to delete.
   */
  public removeFillingRepartition(idx: any): void {
    const value = clone(this.formProperty.value);

    value[0].nb_superbox += value[idx].nb_superbox;
    value.splice(idx, 1);

    this.formProperty.setValue(value, false);
    this.recomputeSelectEnums();
  }

  // #endregion

  // #region -> (data manipulation)

  private _nbs_total$$ = new BehaviorSubject<number>(null);
  public nbs_total$$ = this._nbs_total$$.asObservable().pipe(distinctUntilRealChanged(), replay());

  private _enumerator$$ = new BehaviorSubject<any[]>([]);
  public enumerator$$ = this._enumerator$$.asObservable().pipe(distinctUntilRealChanged(), replay());

  protected computeInitial(): void {
    this._is_loading$$.next(true);

    const supers_property = this.robustSearchProperty(this.options.supers_path);

    if (isNil(supers_property)) {
      throw Error(`supers_property not found ! (path: ${this.options.supers_path})`);
    }

    this.unsubscribeTotalValue();
    this._total_value_sub = concat(of(supers_property.value), supers_property?.valueChanges)
      .pipe(
        map(supers => -1 * supers.nbs_diff || 0),
        distinctUntilRealChanged()
      )
      .subscribe({
        next: nbs_total => {
          this._is_loading$$.next(false);

          const old_total = this._nbs_total$$.getValue() || sum(this.formProperty.value.map((pt: any) => pt.nb_superbox));
          this._nbs_total$$.next(nbs_total);

          let new_value;
          if (isEmpty(this.formProperty.value || []) || isNil(old_total)) {
            new_value = [{ filling: 100, nb_superbox: nbs_total }];
          } else {
            new_value = cloneDeep(this.formProperty.value);
            const diff = nbs_total - old_total;
            const nb_full = new_value[0] ? new_value[0].nb_superbox : 0;

            // console.log(`nb_full:${nb_full} nbs_total:${nbs_total} old_total:${old_total} diff${diff}:==> ${nb_full + diff}`);
            // console.log(new_value);

            if (diff > 0) {
              new_value[0].nb_superbox = nb_full + diff;
            } else {
              // remove or add all we can from 100%
              let nb_to_remove = -diff;
              let idx = 0;
              while (nb_to_remove > 0 && idx < new_value.length) {
                const nb_now = new_value[idx] ? new_value[idx].nb_superbox : 0;
                const nb_removed = Math.min(nb_now, nb_to_remove);

                // console.log(`idx:${idx} nb_now:${nb_now} nb_removed:${nb_removed} nb_to_remove:${nb_to_remove} ==> ${nb_now - nb_removed}`);
                new_value[idx].nb_superbox = nb_now - nb_removed;
                nb_to_remove -= nb_removed;
                idx++;
              }
            }
          }
          // console.log(new_value);

          this.formProperty.setValue(new_value, false);
          this.recomputeSelectEnums();
        },
      });
  }

  public setFilling(idx: number, new_value: number): void {
    const value = this.formProperty.value;
    value[idx].filling = new_value;

    this.formProperty.setValue(value, false);
    this.recomputeSelectEnums();
  }

  /**
   * Updates the number of supers for a specific filling value.
   *
   * @param idx The ID of the filling repartition field to set for.
   * @param new_value The number of supers to set.
   */
  public setSupersForFilling(idx: number, new_value: number): void {
    const value = this.formProperty.value;

    if (value[idx].nb_superbox !== new_value) {
      const last_value = value[idx].nb_superbox;
      const diff = new_value - last_value;
      const nb_full = value[0].nb_superbox;
      const new_diff = Math.min(nb_full, diff);

      value[0].nb_superbox -= new_diff;
      value[idx].nb_superbox += new_diff;

      this.formProperty.setValue(value, false);
    }
  }

  public getAvailable() {
    return (this.schema?.items?.properties?.filling?.enum || []).filter((value: any) => {
      for (const entry of this.formProperty.value) {
        if (value === entry.filling) {
          return false;
        }
      }

      return true;
    });
  }

  public recomputeSelectEnums() {
    const avaiable = this.getAvailable();

    const rebuilt = this.formProperty.value.map((value: any) => {
      const tmp = clone(avaiable);
      tmp.push(value.filling);
      return reverse(sortBy(tmp));
    });

    this._enumerator$$.next(rebuilt);
  }

  // #endregion
}
