<div class="bg2-zoho-deal-summary-component">
  <div *ngLet="deal$$ | async; let deal" class="deal-container">
    <div class="deal-container__header-container">
      <span class="deal-container__description-container__subject" [popper]="deal?.deal_name$$ | async">
        {{ deal?.deal_name$$ | async | truncate : 50 : '...' }}
      </span>

      <div class="deal-amount">
        <span class="value">{{ (deal?.amount$$ | async) ?? '-' }} €</span>
      </div>
    </div>

    <div class="deal-container__description-container">
      <div class="closing-date">
        <span class="label">
          {{ 'VIEWS.ZOHO.SHARED.CRM.DEAL_SUMMARY.Closing date' | translate | colon }}
        </span>

        <span class="value">{{ deal?.closing_date$$ | async | formatDate : 'short' }}</span>
      </div>

      <div class="link-to-crm">
        <span class="mdi mdi-web"></span>

        <a [href]="deal?.url$$ | async" target="_blank" (click)="$event.stopImmediatePropagation()">
          {{ 'VIEWS.ZOHO.SHARED.CRM.Click to view/edit on CRM' | translate }}
        </a>
      </div>
    </div>
  </div>
</div>
