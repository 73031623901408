import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';

import { BeeguardAuthService } from '../../../core/auth/beeguard-auth.service';

@Component({
  selector: 'app-home',
  template: '',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LogoutComponent implements OnInit {

  constructor(
    private oAuthService: BeeguardAuthService,
  ) { }

  ngOnInit(): void {
    this.oAuthService.logout();
  }
}
