import { Dictionary } from 'app/typings/core/interfaces';
import { isNil } from 'lodash-es';
import { MonoTypeOperatorFunction, map } from 'rxjs';

/**
 * Transforms a null or undefined value to an empty dictionnary.
 *
 * This RxJS operator transforms an initial value which is null or undefined to
 * an empty dictionary, else it keeps the initial value.
 *
 * @returns
 */
export const nullValueToEmptyDict = <T extends Dictionary<any>>(): MonoTypeOperatorFunction<T> => map((x: T) => (isNil(x) ? ({} as T) : x));
