import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';

import { marker as i18n } from '@biesbjerg/ngx-translate-extract-marker';

import { ISchema } from 'ngx-schema-form';
import { TranslateService } from '@ngx-translate/core';
import { cloneDeep, isEmpty, isNil, merge, truncate } from 'lodash-es';

import { BehaviorSubject, forkJoin, map, of, Subscription, switchMap, take } from 'rxjs';
import { allTrue, catchErrorInDialog, distinctUntilRealChanged, replay } from '@bg2app/tools/rxjs';

import { DeviceApi } from 'app/core';
import { ConsoleLoggerService } from 'app/core/console-logger.service';
import { ZohoDeskApiService } from 'app/core/services/zoho/zoho-desk-api.service';

import { ModalParams } from 'app/widgets/dialogs-modals/abstract-modal.component';
import { AbstractModalComponent, DialogsService } from 'app/widgets/dialogs-modals';
import { ZohoSearchDeskSelectOptions } from 'app/widgets/event-form/zoho-search-desk/zoho-search-desk-widget.component';

import {
  TicketCustomFields,
  ZohoDeskTicketStatus,
  IZohoCreateDeskTicket,
  ZohoDeskTicketSupportType,
  zoho_desk_ticket_status_oneof,
} from 'app/models/zoho/desk';
import { DRDevice } from 'app/models';
import { decodeJson } from 'app/misc/tools';
import { CustomazibleDialogParams } from 'app/widgets/dialogs-modals/customizable/customazible.dialog';

import {
  build_template_devices_guarantee_list$,
  build_template_device_list$,
  build_template_simple_message,
  build_template_ticket_comment_header,
} from 'app/models/zoho/tools/template-builder';

/** */
interface ZohoCreateIssueModalParams extends ModalParams {
  /** */
  issue_type: 'device_issue' | 'generic';

  /**
   * Encoded list of IMEIs.
   */
  device_imeis?: string;

  /** */
  zoho_desk_contact_id?: string;

  /** */
  zoho_desk_account_name?: string;
}

@Component({
  selector: 'bg2-zoho-create-issue-modal',
  templateUrl: './zoho-create-issue-modal.component.html',
  styleUrls: ['./zoho-create-issue-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ZohoCreateIssueModalComponent extends AbstractModalComponent<ZohoCreateIssueModalParams> implements OnInit, OnDestroy {
  // #region -> (modal basics)

  /** */
  private LOGGER = new ConsoleLoggerService('ZohoCreateIssueModalComponent', true);

  constructor(
    private readonly _deviceApi: DeviceApi,
    private readonly _translate: TranslateService,
    private readonly _dialogsService: DialogsService,
    private readonly _zohoDeskApi: ZohoDeskApiService
  ) {
    super();
  }

  ngOnInit(): void {
    // Prepare ticket creation for global
    if (this.input_params.issue_type === 'generic') {
      this._loading_form_data$$.next(true);

      forkJoin({ connected_agent: this._zohoDeskApi.connected_agent$$ }).subscribe({
        next: ({ connected_agent }) => {
          let form_data = <IZohoCreateDeskTicket>{
            departmentId: '45530000000007061',
            assigneeId: connected_agent?.id,
            status: ZohoDeskTicketStatus.OUVERT,
            cf: {
              cf_imeis: null,
              cf_appareils_sous_garantie: null,
              cf_type_de_support: ZohoDeskTicketSupportType.INCONNU,
            },
            subject: null,
            description: null,
          };

          if (!isNil(this.input_params.zoho_desk_contact_id)) {
            form_data.contactId = this.input_params.zoho_desk_contact_id;
          }

          this._form_model$$.next(<any>{ creation_type: this.input_params.issue_type, ...form_data });

          this._loading_form_data$$.next(false);
          this.watch_ticket_properties();
        },
      });

      this._loading_form_data$$.next(false);
    }

    // Prepare ticket creation for device problem
    if (this.input_params.issue_type === 'device_issue') {
      this._loading_form_data$$.next(true);

      forkJoin({
        connected_agent: this._zohoDeskApi.connected_agent$$,
        devices: this.devices$$.pipe(take(1)),
        is_under_guarantee: this.devices$$.pipe(
          switchMap(devices => {
            const devices_under_guarantee$$ = devices.map(device => device.guarantee$$.pipe(map(guarantee => guarantee.under_guarantee)));

            return allTrue(...devices_under_guarantee$$);
          }),
          take(1)
        ),
      }).subscribe({
        next: ({ connected_agent, devices, is_under_guarantee }) => {
          const device_names = devices.map(device => device.name).join(', ');
          const device_imeis = devices.map(device => device.imei).join(' ');

          let form_data = <IZohoCreateDeskTicket>{
            departmentId: '45530000000007061',
            assigneeId: connected_agent?.id,
            status: ZohoDeskTicketStatus.OUVERT,
            cf: {
              cf_imeis: device_imeis,
              cf_appareils_sous_garantie: is_under_guarantee ? 'Oui' : 'Non',
              cf_type_de_support: ZohoDeskTicketSupportType.PROBLEM_WITH_DEVICES,
            },
            subject: this.fix_subject_length(ZohoDeskTicketSupportType.PROBLEM_WITH_DEVICES + ' pour ' + device_names),
            description: null,
          };

          if (!isNil(this.input_params.zoho_desk_contact_id)) {
            form_data.contactId = this.input_params.zoho_desk_contact_id;
          }

          this._form_model$$.next(<any>{ creation_type: this.input_params.issue_type, ...form_data });

          this._loading_form_data$$.next(false);
          this.watch_ticket_properties();
        },
      });
    }
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();

    this._watch_ticket_issue_type_sub?.unsubscribe();
  }

  // #endregion

  /** */
  protected handle_event_before_unload(event: BeforeUnloadEvent): void {
    return null;
  }

  /** */
  public issue_type$$ = this.input_params$$.pipe(
    map(parameters => parameters?.issue_type),
    replay()
  );

  /** */
  public modal_title$$ = this.issue_type$$.pipe(
    map(issue_type => {
      let i18n_key: string = null;

      if (issue_type === 'generic') {
        i18n_key = i18n<string>('VIEWS.ZOHO.DIALOGS_AND_MODALS.ZOHO_CREATE_ISSUE_MODAL.TITLE.generic');
      }

      if (issue_type === 'device_issue') {
        i18n_key = i18n<string>('VIEWS.ZOHO.DIALOGS_AND_MODALS.ZOHO_CREATE_ISSUE_MODAL.TITLE.device_issue');
      }

      return i18n_key;
    }),
    replay()
  );

  // #region -> (specific to device ticket)

  /** */
  private device_imeis$$ = this.input_params$$.pipe(
    map(params => params.device_imeis),
    map(encoded_device_imeis => {
      if (isNil(encoded_device_imeis) || isEmpty(encoded_device_imeis)) {
        return [];
      }

      const decoded_device_imeis = decodeJson(encoded_device_imeis);
      const parsed_devices_imeis: string[] = JSON.parse(decoded_device_imeis);

      if (isNil(parsed_devices_imeis) || isEmpty(parsed_devices_imeis)) {
        return [];
      }

      return parsed_devices_imeis.map(imei => parseInt(imei, 10));
    }),
    replay()
  );

  /** */
  public devices$$ = this.device_imeis$$.pipe(
    switchMap(device_imeis => {
      if (isNil(device_imeis) || isEmpty(device_imeis)) {
        return of<DRDevice[]>([]);
      }

      return this._deviceApi.requestDevices(device_imeis);
    }),
    replay()
  );

  // #endregion

  // #region -> (form management)

  /** */
  private readonly schema: ISchema = {
    type: 'object',
    properties: {
      creation_type: {
        type: 'string',
        label: 'Appareils sous garantie ?',
        widget: 'hidden',
        minItems: 1,
        oneOf: [
          {
            enum: ['generic'],
            label: 'generic',
          },
          {
            enum: ['device_issue'],
            label: 'device_issue',
          },
        ],
      },
      departmentId: {
        type: 'string',
        widget: 'checklist',
        options: {
          display: 'button',
        },
        minItems: 1,
        oneOf: [
          {
            enum: ['45530000000007061'],
            label: 'Support BeeGuard',
            image: 'picons/tools.svg',
            image_styles: {
              'max-width': '40px',
            },
          },
          {
            enum: ['45530000000277361'],
            label: 'Service Commercial',
            image: 'img/views/common/filters/euro.svg',
            image_styles: {
              'max-width': '40px',
            },
          },
        ],
      },
      contactId: {
        type: 'string',
        widget: 'zoho-search-desk',
        label: i18n<string>('VIEWS.ZOHO.DIALOGS_AND_MODALS.ZOHO_CREATE_ISSUE_MODAL.FORM.Client'),
        options: <ZohoSearchDeskSelectOptions>{
          zoho_search_config: {
            search_in: 'contacts',
          },
        },
      },
      status: {
        type: 'string',
        widget: 'select',
        label: i18n<string>('VIEWS.ZOHO.DIALOGS_AND_MODALS.ZOHO_CREATE_ISSUE_MODAL.FORM.Status'),
        oneOf: zoho_desk_ticket_status_oneof([ZohoDeskTicketStatus.RETOUR_USINE_RECU]),
        options: {
          template_name: 'zoho-ticket-status',
        },
      },
      assigneeId: {
        type: 'string',
        widget: 'zoho-search-desk',
        label: i18n<string>('VIEWS.ZOHO.DIALOGS_AND_MODALS.ZOHO_CREATE_ISSUE_MODAL.FORM.Agent'),
        options: <ZohoSearchDeskSelectOptions>{
          zoho_search_config: {
            search_in: 'agents',
          },
        },
      },
      cf: {
        type: 'object',
        properties: {
          cf_type_de_support: {
            type: 'string',
            widget: 'select',
            label: i18n<string>('VIEWS.ZOHO.DIALOGS_AND_MODALS.ZOHO_CREATE_ISSUE_MODAL.FORM.Support type'),
            oneOf: Object.values(ZohoDeskTicketSupportType).map(value => ({ enum: [value], label: value })),
          },
          cf_imeis: {
            type: 'string',
            widget: 'hidden',
            label: i18n<string>('VIEWS.ZOHO.DIALOGS_AND_MODALS.ZOHO_CREATE_ISSUE_MODAL.FORM.Selected devices'),
          },
          cf_appareils_sous_garantie: {
            type: 'string',
            label: 'Appareils sous garantie ?',
            widget: 'checklist',
            visibleIf: {
              creation_type: 'device_issue',
            },
            options: {
              display: 'button',
            },
            minItems: 1,
            oneOf: [
              {
                enum: <[TicketCustomFields['cf_appareils_sous_garantie']]>['Oui'],
                label: 'Oui',
                image: 'picons/from_mdi/check-bold-green.svg',
                image_styles: {
                  'max-width': '40px',
                },
              },
              {
                enum: <[TicketCustomFields['cf_appareils_sous_garantie']]>['Non'],
                label: 'Non',
                image: 'picons/from_mdi/close-thick-red.svg',
                image_styles: {
                  'max-width': '40px',
                },
              },
              {
                enum: <[TicketCustomFields['cf_appareils_sous_garantie']]>['- Non spécifié -'],
                label: '- Non spécifié -',
                image: 'picons/from_mdi/help.svg',
                image_styles: {
                  'max-width': '40px',
                },
              },
            ],
          },
        },
        required: ['cf_type_de_support'],
      },
      subject: {
        type: 'string',
        label: i18n<string>('VIEWS.ZOHO.DIALOGS_AND_MODALS.ZOHO_CREATE_ISSUE_MODAL.FORM.Subject'),
        options: {
          max_length: 255,
        },
      },
      description: {
        type: 'string',
        widget: 'textarea',
        label: i18n<string>('VIEWS.ZOHO.DIALOGS_AND_MODALS.ZOHO_CREATE_ISSUE_MODAL.FORM.Description'),
      },
    },
    required: ['departmentId', 'assigneeId', 'contactId', 'status', 'cf', 'subject'],
  };

  /** */
  public schema$$ = this.input_params$$.pipe(
    map(input_params => {
      const schema = cloneDeep(this.schema);

      const zoho_desk_contact_id = input_params.zoho_desk_contact_id;
      const zoho_desk_account_name = input_params.zoho_desk_account_name;

      if (!isNil(zoho_desk_account_name)) {
        const initial_options = schema.properties.contactId.options;

        schema.properties.contactId.options = merge({}, initial_options, <ZohoSearchDeskSelectOptions>{
          zoho_search_config: {
            contacts_config: {
              limit_to_account_name: zoho_desk_account_name,
            },
          },
        });
      }

      return schema;
    })
  );

  /** */
  private _form_model$$ = new BehaviorSubject<IZohoCreateDeskTicket>(null);

  /** */
  public form_model$$ = this._form_model$$.asObservable();

  /** */
  public set form_model(form_model: any) {
    this._form_model$$.next(form_model);
  }

  /** */
  private _is_form_valid$$ = new BehaviorSubject<boolean>(false);

  /** */
  public is_form_valid$$ = this._is_form_valid$$.asObservable();

  /** */
  public set is_form_valid(is_form_valid: boolean) {
    this._is_form_valid$$.next(is_form_valid);
  }

  /** */
  public submit(): void {
    this.devices$$
      .pipe(
        take(1),
        switchMap(devices =>
          forkJoin({
            form_model: this.form_model$$.pipe(take(1)),
            template_devices_list_table: build_template_device_list$(devices).pipe(take(1)),
            template_devices_guarantee_table: build_template_devices_guarantee_list$(devices, { _translate: this._translate }).pipe(
              take(1)
            ),
          }).pipe(
            map(({ form_model, template_devices_list_table, template_devices_guarantee_table }) => {
              const creation_type = cloneDeep(<any>form_model)?.creation_type;
              delete (<any>form_model).creation_type;

              // Apply custom modifications to ticket
              if (form_model.status === ZohoDeskTicketStatus.ATTENTE_RETOUR_USINE) {
                form_model.cf.cf_retour_usine = 'true';
              }

              // form_model.cf.cf_nombre_de_wg_ru = (devices.filter(device => device.type === 'WG') ?? []).length.toString();
              // form_model.cf.cf_nombre_de_rg_ru = (devices.filter(device => device.type === 'RG') ?? []).length.toString();
              // form_model.cf.cf_nombre_d_appareils = (devices.filter(device => device.type === 'GPS') ?? []).length.toString();

              const template_builder = [''];

              // Ticket header
              template_builder.push(
                build_template_ticket_comment_header(`Création du ticket pour "${form_model?.cf?.cf_type_de_support}"`)
              );

              if (creation_type !== 'device_issue') {
                template_builder.push(template_devices_list_table);
                template_builder.push(template_devices_guarantee_table);
              }

              // Add user message
              const user_message = form_model?.description;

              if (!isNil(user_message) || !isEmpty(user_message)) {
                template_builder.push(build_template_simple_message("Message de l'utilisateur", user_message));
              }

              form_model.description = template_builder.join('');
              return form_model;
            })
          )
        ),
        take(1),
        switchMap(form_model => this._zohoDeskApi.create_ticket$(form_model)),
        catchErrorInDialog(this._dialogsService),
        switchMap(zoho_desk_ticket =>
          this._dialogsService.customizable(<CustomazibleDialogParams<'ok'>>{
            body: {
              type: 'div',
              styles: {},
              elements: [
                {
                  type: 'span',
                  content: i18n<string>(
                    'VIEWS.ZOHO.DIALOGS_AND_MODALS.ZOHO_CREATE_ISSUE_MODAL.DIALOG_AFTER_CREATE.Ticket has been created with number: #[ticketNumber]'
                  ),
                  translateParams: {
                    ticketNumber: zoho_desk_ticket?.ticketNumber,
                  },
                },
                {
                  type: 'link',
                  content: i18n<string>('VIEWS.ZOHO.DIALOGS_AND_MODALS.ZOHO_CREATE_ISSUE_MODAL.DIALOG_AFTER_CREATE.Viewthe ticket on zoho'),
                  result: 'ok',
                  action: () => {
                    window.open(zoho_desk_ticket?.webUrl, '_blank');
                  },
                },
              ],
            },
            footer: {
              styles: {},
              buttons: {
                items: [
                  {
                    type: 'button',
                    color: 'primary',
                    content: i18n<string>('ALL.COMMON.OK'),
                    result: 'ok',
                  },
                ],
              },
            },
          })
        )
      )
      .subscribe({
        next: dialog_result => {
          if (dialog_result?.return_value === 'ok') {
            this.close();
          }
        },
      });
  }

  // #endregion

  // #region -> (form data)

  /** */
  private _loading_form_data$$ = new BehaviorSubject(true);

  /** */
  public loading_form_data$$ = this._loading_form_data$$.asObservable();

  /** */
  private _watch_ticket_issue_type_sub: Subscription = null;

  /** */
  private watch_ticket_properties(): void {
    if (isNil(this._watch_ticket_issue_type_sub)) {
      this._watch_ticket_issue_type_sub = this.devices$$
        .pipe(
          switchMap(devices =>
            this.form_model$$.pipe(
              map(model => model?.cf?.cf_type_de_support),
              distinctUntilRealChanged(),
              map(support_type => ({ support_type, devices }))
            )
          )
        )
        .subscribe({
          next: ({ support_type, devices }) => {
            const device_names = devices.map(device => device?.name);

            const current_model = this._form_model$$.getValue();
            current_model.subject = this.fix_subject_length(support_type + ' pour ' + device_names.join(', '));

            this._form_model$$.next(current_model);
          },
        });
    }
  }

  // #endregion

  /** */
  private fix_subject_length(subject: string): string {
    return truncate(subject, { length: 255 });
  }
}
