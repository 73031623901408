export const timezone = [
  'Africa/Abidjan',
  'Africa/Accra',
  'Africa/Addis_Ababa',
  'Africa/Algiers',
  'Africa/Asmara',
  'Africa/Bamako',
  'Africa/Bangui',
  'Africa/Banjul',
  'Africa/Bissau',
  'Africa/Blantyre',
  'Africa/Brazzaville',
  'Africa/Bujumbura',
  'Africa/Cairo',
  'Africa/Casablanca',
  'Africa/Ceuta',
  'Africa/Conakry',
  'Africa/Dakar',
  'Africa/Dar_es_Salaam',
  'Africa/Djibouti',
  'Africa/Douala',
  'Africa/El_Aaiun',
  'Africa/Freetown',
  'Africa/Gaborone',
  'Africa/Harare',
  'Africa/Johannesburg',
  'Africa/Juba',
  'Africa/Kampala',
  'Africa/Khartoum',
  'Africa/Kigali',
  'Africa/Kinshasa',
  'Africa/Lagos',
  'Africa/Libreville',
  'Africa/Lome',
  'Africa/Luanda',
  'Africa/Lubumbashi',
  'Africa/Lusaka',
  'Africa/Malabo',
  'Africa/Maputo',
  'Africa/Maseru',
  'Africa/Mbabane',
  'Africa/Mogadishu',
  'Africa/Monrovia',
  'Africa/Nairobi',
  'Africa/Ndjamena',
  'Africa/Niamey',
  'Africa/Nouakchott',
  'Africa/Ouagadougou',
  'Africa/Porto-Novo',
  'Africa/Sao_Tome',
  'Africa/Tripoli',
  'Africa/Tunis',
  'Africa/Windhoek',
  'America/Adak',
  'America/Anchorage',
  'America/Anguilla',
  'America/Antigua',
  'America/Araguaina',
  'America/Argentina/Buenos_Aires',
  'America/Argentina/Catamarca',
  'America/Argentina/Cordoba',
  'America/Argentina/Jujuy',
  'America/Argentina/La_Rioja',
  'America/Argentina/Mendoza',
  'America/Argentina/Rio_Gallegos',
  'America/Argentina/Salta',
  'America/Argentina/San_Juan',
  'America/Argentina/San_Luis',
  'America/Argentina/Tucuman',
  'America/Argentina/Ushuaia',
  'America/Aruba',
  'America/Asuncion',
  'America/Atikokan',
  'America/Bahia',
  'America/Bahia_Banderas',
  'America/Barbados',
  'America/Belem',
  'America/Belize',
  'America/Blanc-Sablon',
  'America/Boa_Vista',
  'America/Bogota',
  'America/Boise',
  'America/Cambridge_Bay',
  'America/Campo_Grande',
  'America/Cancun',
  'America/Caracas',
  'America/Cayenne',
  'America/Cayman',
  'America/Chicago',
  'America/Chihuahua',
  'America/Costa_Rica',
  'America/Creston',
  'America/Cuiaba',
  'America/Curacao',
  'America/Danmarkshavn',
  'America/Dawson',
  'America/Dawson_Creek',
  'America/Denver',
  'America/Detroit',
  'America/Dominica',
  'America/Edmonton',
  'America/Eirunepe',
  'America/El_Salvador',
  'America/Fort_Nelson',
  'America/Fortaleza',
  'America/Glace_Bay',
  'America/Goose_Bay',
  'America/Grand_Turk',
  'America/Grenada',
  'America/Guadeloupe',
  'America/Guatemala',
  'America/Guayaquil',
  'America/Guyana',
  'America/Halifax',
  'America/Havana',
  'America/Hermosillo',
  'America/Indiana/Indianapolis',
  'America/Indiana/Knox',
  'America/Indiana/Marengo',
  'America/Indiana/Petersburg',
  'America/Indiana/Tell_City',
  'America/Indiana/Vevay',
  'America/Indiana/Vincennes',
  'America/Indiana/Winamac',
  'America/Inuvik',
  'America/Iqaluit',
  'America/Jamaica',
  'America/Juneau',
  'America/Kentucky/Louisville',
  'America/Kentucky/Monticello',
  'America/Kralendijk',
  'America/La_Paz',
  'America/Lima',
  'America/Los_Angeles',
  'America/Lower_Princes',
  'America/Maceio',
  'America/Managua',
  'America/Manaus',
  'America/Marigot',
  'America/Martinique',
  'America/Matamoros',
  'America/Mazatlan',
  'America/Menominee',
  'America/Merida',
  'America/Metlakatla',
  'America/Mexico_City',
  'America/Miquelon',
  'America/Moncton',
  'America/Monterrey',
  'America/Montevideo',
  'America/Montserrat',
  'America/Nassau',
  'America/New_York',
  'America/Nipigon',
  'America/Nome',
  'America/Noronha',
  'America/North_Dakota/Beulah',
  'America/North_Dakota/Center',
  'America/North_Dakota/New_Salem',
  'America/Nuuk',
  'America/Ojinaga',
  'America/Panama',
  'America/Pangnirtung',
  'America/Paramaribo',
  'America/Phoenix',
  'America/Port-au-Prince',
  'America/Port_of_Spain',
  'America/Porto_Velho',
  'America/Puerto_Rico',
  'America/Punta_Arenas',
  'America/Rainy_River',
  'America/Rankin_Inlet',
  'America/Recife',
  'America/Regina',
  'America/Resolute',
  'America/Rio_Branco',
  'America/Santarem',
  'America/Santiago',
  'America/Santo_Domingo',
  'America/Sao_Paulo',
  'America/Scoresbysund',
  'America/Sitka',
  'America/St_Barthelemy',
  'America/St_Johns',
  'America/St_Kitts',
  'America/St_Lucia',
  'America/St_Thomas',
  'America/St_Vincent',
  'America/Swift_Current',
  'America/Tegucigalpa',
  'America/Thule',
  'America/Thunder_Bay',
  'America/Tijuana',
  'America/Toronto',
  'America/Tortola',
  'America/Vancouver',
  'America/Whitehorse',
  'America/Winnipeg',
  'America/Yakutat',
  'America/Yellowknife',
  'Antarctica/Casey',
  'Antarctica/Davis',
  'Antarctica/DumontDUrville',
  'Antarctica/Macquarie',
  'Antarctica/Mawson',
  'Antarctica/McMurdo',
  'Antarctica/Palmer',
  'Antarctica/Rothera',
  'Antarctica/Syowa',
  'Antarctica/Troll',
  'Antarctica/Vostok',
  'Arctic/Longyearbyen',
  'Asia/Aden',
  'Asia/Almaty',
  'Asia/Amman',
  'Asia/Anadyr',
  'Asia/Aqtau',
  'Asia/Aqtobe',
  'Asia/Ashgabat',
  'Asia/Atyrau',
  'Asia/Baghdad',
  'Asia/Bahrain',
  'Asia/Baku',
  'Asia/Bangkok',
  'Asia/Barnaul',
  'Asia/Beirut',
  'Asia/Bishkek',
  'Asia/Brunei',
  'Asia/Chita',
  'Asia/Choibalsan',
  'Asia/Colombo',
  'Asia/Damascus',
  'Asia/Dhaka',
  'Asia/Dili',
  'Asia/Dubai',
  'Asia/Dushanbe',
  'Asia/Famagusta',
  'Asia/Gaza',
  'Asia/Hebron',
  'Asia/Ho_Chi_Minh',
  'Asia/Hong_Kong',
  'Asia/Hovd',
  'Asia/Irkutsk',
  'Asia/Jakarta',
  'Asia/Jayapura',
  'Asia/Jerusalem',
  'Asia/Kabul',
  'Asia/Kamchatka',
  'Asia/Karachi',
  'Asia/Kathmandu',
  'Asia/Khandyga',
  'Asia/Kolkata',
  'Asia/Krasnoyarsk',
  'Asia/Kuala_Lumpur',
  'Asia/Kuching',
  'Asia/Kuwait',
  'Asia/Macau',
  'Asia/Magadan',
  'Asia/Makassar',
  'Asia/Manila',
  'Asia/Muscat',
  'Asia/Nicosia',
  'Asia/Novokuznetsk',
  'Asia/Novosibirsk',
  'Asia/Omsk',
  'Asia/Oral',
  'Asia/Phnom_Penh',
  'Asia/Pontianak',
  'Asia/Pyongyang',
  'Asia/Qatar',
  'Asia/Qostanay',
  'Asia/Qyzylorda',
  'Asia/Riyadh',
  'Asia/Sakhalin',
  'Asia/Samarkand',
  'Asia/Seoul',
  'Asia/Shanghai',
  'Asia/Singapore',
  'Asia/Srednekolymsk',
  'Asia/Taipei',
  'Asia/Tashkent',
  'Asia/Tbilisi',
  'Asia/Tehran',
  'Asia/Thimphu',
  'Asia/Tokyo',
  'Asia/Tomsk',
  'Asia/Ulaanbaatar',
  'Asia/Urumqi',
  'Asia/Ust-Nera',
  'Asia/Vientiane',
  'Asia/Vladivostok',
  'Asia/Yakutsk',
  'Asia/Yangon',
  'Asia/Yekaterinburg',
  'Asia/Yerevan',
  'Atlantic/Azores',
  'Atlantic/Bermuda',
  'Atlantic/Canary',
  'Atlantic/Cape_Verde',
  'Atlantic/Faroe',
  'Atlantic/Madeira',
  'Atlantic/Reykjavik',
  'Atlantic/South_Georgia',
  'Atlantic/St_Helena',
  'Atlantic/Stanley',
  'Australia/Adelaide',
  'Australia/Brisbane',
  'Australia/Broken_Hill',
  'Australia/Darwin',
  'Australia/Eucla',
  'Australia/Hobart',
  'Australia/Lindeman',
  'Australia/Lord_Howe',
  'Australia/Melbourne',
  'Australia/Perth',
  'Australia/Sydney',
  'Europe/Amsterdam',
  'Europe/Andorra',
  'Europe/Astrakhan',
  'Europe/Athens',
  'Europe/Belgrade',
  'Europe/Berlin',
  'Europe/Bratislava',
  'Europe/Brussels',
  'Europe/Bucharest',
  'Europe/Budapest',
  'Europe/Busingen',
  'Europe/Chisinau',
  'Europe/Copenhagen',
  'Europe/Dublin',
  'Europe/Gibraltar',
  'Europe/Guernsey',
  'Europe/Helsinki',
  'Europe/Isle_of_Man',
  'Europe/Istanbul',
  'Europe/Jersey',
  'Europe/Kaliningrad',
  'Europe/Kiev',
  'Europe/Kirov',
  'Europe/Lisbon',
  'Europe/Ljubljana',
  'Europe/London',
  'Europe/Luxembourg',
  'Europe/Madrid',
  'Europe/Malta',
  'Europe/Mariehamn',
  'Europe/Minsk',
  'Europe/Monaco',
  'Europe/Moscow',
  'Europe/Oslo',
  'Europe/Paris',
  'Europe/Podgorica',
  'Europe/Prague',
  'Europe/Riga',
  'Europe/Rome',
  'Europe/Samara',
  'Europe/San_Marino',
  'Europe/Sarajevo',
  'Europe/Saratov',
  'Europe/Simferopol',
  'Europe/Skopje',
  'Europe/Sofia',
  'Europe/Stockholm',
  'Europe/Tallinn',
  'Europe/Tirane',
  'Europe/Ulyanovsk',
  'Europe/Uzhgorod',
  'Europe/Vaduz',
  'Europe/Vatican',
  'Europe/Vienna',
  'Europe/Vilnius',
  'Europe/Volgograd',
  'Europe/Warsaw',
  'Europe/Zagreb',
  'Europe/Zaporozhye',
  'Europe/Zurich',
  'Indian/Antananarivo',
  'Indian/Chagos',
  'Indian/Christmas',
  'Indian/Cocos',
  'Indian/Comoro',
  'Indian/Kerguelen',
  'Indian/Mahe',
  'Indian/Maldives',
  'Indian/Mauritius',
  'Indian/Mayotte',
  'Indian/Reunion',
  'Pacific/Apia',
  'Pacific/Auckland',
  'Pacific/Bougainville',
  'Pacific/Chatham',
  'Pacific/Chuuk',
  'Pacific/Easter',
  'Pacific/Efate',
  'Pacific/Fakaofo',
  'Pacific/Fiji',
  'Pacific/Funafuti',
  'Pacific/Galapagos',
  'Pacific/Gambier',
  'Pacific/Guadalcanal',
  'Pacific/Guam',
  'Pacific/Honolulu',
  'Pacific/Kanton',
  'Pacific/Kiritimati',
  'Pacific/Kosrae',
  'Pacific/Kwajalein',
  'Pacific/Majuro',
  'Pacific/Marquesas',
  'Pacific/Midway',
  'Pacific/Nauru',
  'Pacific/Niue',
  'Pacific/Norfolk',
  'Pacific/Noumea',
  'Pacific/Pago_Pago',
  'Pacific/Palau',
  'Pacific/Pitcairn',
  'Pacific/Pohnpei',
  'Pacific/Port_Moresby',
  'Pacific/Rarotonga',
  'Pacific/Saipan',
  'Pacific/Tahiti',
  'Pacific/Tarawa',
  'Pacific/Tongatapu',
  'Pacific/Wake',
  'Pacific/Wallis',
];

/** */
export interface TimezoneObject {
  /** */
  tz_identifier: string;

  /** */
  latitude: number;

  /** */
  longitude: number;

  /** */
  tz_abbr: string;

  /** */
  tz_offset: `${'+' | '-'}${number}:${number}`;

  /** */
  country_code: string;

  /** */
  country_name: string;

  /** */
  summer_time?: {
    /** */
    tz_abbr: string;

    /** */
    tz_offset: `${'+' | '-'}${number}:${number}`;
  };
}

export const timezones_data: TimezoneObject[] = [
  {
    country_code: 'AD',
    country_name: 'Andorra',
    tz_identifier: 'Europe/Andorra',
    tz_offset: '+01:00',
    tz_abbr: 'CET',
    summer_time: {
      tz_abbr: 'CEST',
      tz_offset: '+02:00',
    },
    latitude: 42.506317,
    longitude: 1.521835,
  },
  {
    country_code: 'AE',
    country_name: 'United Arab Emirates',
    tz_identifier: 'Asia/Dubai',
    tz_offset: '+04:00',
    tz_abbr: '+04',
    latitude: 25.1707,
    longitude: 55.4036,
  },
  {
    country_code: 'AF',
    country_name: 'Afghanistan',
    tz_identifier: 'Asia/Kabul',
    tz_offset: '+04:30',
    tz_abbr: '+0430',
    latitude: 34.5285,
    longitude: 69.1717,
  },
  {
    country_code: 'AG',
    country_name: 'Antigua and Barbuda',
    tz_identifier: 'America/Antigua',
    tz_offset: '-04:00',
    tz_abbr: 'AST',
    latitude: 17.0747,
    longitude: -61.8175,
  },
  {
    country_code: 'AI',
    country_name: 'Anguilla',
    tz_identifier: 'America/Anguilla',
    tz_offset: '-04:00',
    tz_abbr: 'AST',
    latitude: 18.2206,
    longitude: -63.0686,
  },
  {
    country_code: 'AL',
    country_name: 'Albania',
    tz_identifier: 'Europe/Tirane',
    tz_offset: '+01:00',
    tz_abbr: 'CET',
    summer_time: {
      tz_abbr: 'CEST',
      tz_offset: '+02:00',
    },
    latitude: 41.3317,
    longitude: 19.8318,
  },
  {
    country_code: 'AM',
    country_name: 'Armenia',
    tz_identifier: 'Asia/Yerevan',
    tz_offset: '+04:00',
    tz_abbr: '+04',
    latitude: 40.7247,
    longitude: 44.7139,
  },
  {
    country_code: 'AO',
    country_name: 'Angola',
    tz_identifier: 'Africa/Luanda',
    tz_offset: '+01:00',
    tz_abbr: 'WAT',
    latitude: -8.839988,
    longitude: 13.289437,
  },
  {
    country_code: 'AQ',
    country_name: 'Antarctica',
    tz_identifier: 'Antarctica/Casey',
    tz_offset: '+11:00',
    tz_abbr: '+11',
    latitude: -66.281896,
    longitude: 110.5220318,
  },
  {
    country_code: 'AQ',
    country_name: 'Antarctica',
    tz_identifier: 'Antarctica/Davis',
    tz_offset: '+07:00',
    tz_abbr: '+07',
    latitude: -68.5835187,
    longitude: 77.8726563,
  },
  {
    country_code: 'AQ',
    country_name: 'Antarctica',
    tz_identifier: 'Antarctica/DumontDUrville',
    tz_offset: '+10:00',
    tz_abbr: '+10',
    latitude: -66.6251798,
    longitude: 139.8993002,
  },
  {
    country_code: 'AQ',
    country_name: 'Antarctica',
    tz_identifier: 'Antarctica/Mawson',
    tz_offset: '+05:00',
    tz_abbr: '+05',
    latitude: -67.0087979,
    longitude: 142.6584849,
  },
  {
    country_code: 'AQ',
    country_name: 'Antarctica',
    tz_identifier: 'Antarctica/McMurdo',
    tz_offset: '+12:00',
    tz_abbr: 'NZST',
    summer_time: {
      tz_abbr: 'NZDT',
      tz_offset: '+13:00',
    },
    latitude: -77.840117,
    longitude: 166.6058841,
  },
  {
    country_code: 'AQ',
    country_name: 'Antarctica',
    tz_identifier: 'Antarctica/Palmer',
    tz_offset: '-03:00',
    tz_abbr: '-03',
    latitude: -64.7742513,
    longitude: -64.0564102,
  },
  {
    country_code: 'AQ',
    country_name: 'Antarctica',
    tz_identifier: 'Antarctica/Rothera',
    tz_offset: '-03:00',
    tz_abbr: '-03',
    latitude: -67.5655272,
    longitude: -68.1431485,
  },
  {
    country_code: 'AQ',
    country_name: 'Antarctica',
    tz_identifier: 'Antarctica/Syowa',
    tz_offset: '+03:00',
    tz_abbr: '+03',
    latitude: -66.8309918,
    longitude: -65.9377433,
  },
  {
    country_code: 'AQ',
    country_name: 'Antarctica',
    tz_identifier: 'Antarctica/Troll',
    tz_offset: '+00:00',
    tz_abbr: '+00',
    summer_time: {
      tz_abbr: '+02',
      tz_offset: '+02:00',
    },
    latitude: -72.0124876,
    longitude: 2.5296397,
  },
  {
    country_code: 'AQ',
    country_name: 'Antarctica',
    tz_identifier: 'Antarctica/Vostok',
    tz_offset: '+06:00',
    tz_abbr: '+06',
    latitude: -78.4644829,
    longitude: 106.8313397,
  },
  {
    country_code: 'AR',
    country_name: 'Argentina',
    tz_identifier: 'America/Argentina/Buenos_Aires',
    tz_offset: '-03:00',
    tz_abbr: '-03',
    latitude: -34.589967,
    longitude: -58.6507255,
  },
  {
    country_code: 'AR',
    country_name: 'Argentina',
    tz_identifier: 'America/Argentina/Catamarca',
    tz_offset: '-03:00',
    tz_abbr: '-03',
    latitude: -28.7098009,
    longitude: -66.371113,
  },
  {
    country_code: 'AR',
    country_name: 'Argentina',
    tz_identifier: 'America/Argentina/Cordoba',
    tz_offset: '-03:00',
    tz_abbr: '-03',
    latitude: -31.3994267,
    longitude: -64.2767834,
  },
  {
    country_code: 'AR',
    country_name: 'Argentina',
    tz_identifier: 'America/Argentina/Jujuy',
    tz_offset: '-03:00',
    tz_abbr: '-03',
    latitude: -24.2053341,
    longitude: -65.3468404,
  },
  {
    country_code: 'AR',
    country_name: 'Argentina',
    tz_identifier: 'America/Argentina/La_Rioja',
    tz_offset: '-03:00',
    tz_abbr: '-03',
    latitude: -29.4142906,
    longitude: -66.8970622,
  },
  {
    country_code: 'AR',
    country_name: 'Argentina',
    tz_identifier: 'America/Argentina/Mendoza',
    tz_offset: '-03:00',
    tz_abbr: '-03',
    latitude: -34.7801754,
    longitude: -69.8527257,
  },
  {
    country_code: 'AR',
    country_name: 'Argentina',
    tz_identifier: 'America/Argentina/Rio_Gallegos',
    tz_offset: '-03:00',
    tz_abbr: '-03',
    latitude: -51.5989517,
    longitude: -69.6164456,
  },
  {
    country_code: 'AR',
    country_name: 'Argentina',
    tz_identifier: 'America/Argentina/Salta',
    tz_offset: '-03:00',
    tz_abbr: '-03',
    latitude: -24.2020731,
    longitude: -66.7765874,
  },
  {
    country_code: 'AR',
    country_name: 'Argentina',
    tz_identifier: 'America/Argentina/San_Juan',
    tz_offset: '-03:00',
    tz_abbr: '-03',
    latitude: -31.535107,
    longitude: -68.538594,
  },
  {
    country_code: 'AR',
    country_name: 'Argentina',
    tz_identifier: 'America/Argentina/San_Luis',
    tz_offset: '-03:00',
    tz_abbr: '-03',
    latitude: -38.416097,
    longitude: -63.616672,
  },
  {
    country_code: 'AR',
    country_name: 'Argentina',
    tz_identifier: 'America/Argentina/Tucuman',
    tz_offset: '-03:00',
    tz_abbr: '-03',
    latitude: -26.822113,
    longitude: -65.219292,
  },
  {
    country_code: 'AR',
    country_name: 'Argentina',
    tz_identifier: 'America/Argentina/Ushuaia',
    tz_offset: '-03:00',
    tz_abbr: '-03',
    latitude: -54.8019,
    longitude: -68.3031,
  },
  {
    country_code: 'AS',
    country_name: 'American Samoa',
    tz_identifier: 'Pacific/Pago_Pago',
    tz_offset: '-11:00',
    tz_abbr: 'AST',
    latitude: -14.274,
    longitude: -170.7046,
  },
  {
    country_code: 'AT',
    country_name: 'Austria',
    tz_identifier: 'Europe/Vienna',
    tz_offset: '+01:00',
    tz_abbr: 'CET',
    summer_time: {
      tz_abbr: 'CEST',
      tz_offset: '+02:00',
    },
    latitude: 38.8996,
    longitude: -77.2597,
  },
  {
    country_code: 'AU',
    country_name: 'Australia',
    tz_identifier: 'Antarctica/Macquarie',
    tz_offset: '+10:00',
    tz_abbr: 'AEST',
    summer_time: {
      tz_abbr: 'AEDT',
      tz_offset: '+11:00',
    },
    latitude: -31.4333,
    longitude: 152.9,
  },
  {
    country_code: 'AU',
    country_name: 'Australia',
    tz_identifier: 'Australia/Adelaide',
    tz_offset: '+09:30',
    tz_abbr: 'ACST',
    summer_time: {
      tz_abbr: 'ACDT',
      tz_offset: '+10:30',
    },
    latitude: -27.4678,
    longitude: 153.0281,
  },
  {
    country_code: 'AU',
    country_name: 'Australia',
    tz_identifier: 'Australia/Brisbane',
    tz_offset: '+10:00',
    tz_abbr: 'AEST',
    latitude: -27.4678,
    longitude: 153.0281,
  },
  {
    country_code: 'AU',
    country_name: 'Australia',
    tz_identifier: 'Australia/Broken_Hill',
    tz_offset: '+09:30',
    tz_abbr: 'ACST',
    summer_time: {
      tz_abbr: 'ACDT',
      tz_offset: '+10:30',
    },
    latitude: -31.95,
    longitude: 141.4667,
  },
  {
    country_code: 'AU',
    country_name: 'Australia',
    tz_identifier: 'Australia/Darwin',
    tz_offset: '+09:30',
    tz_abbr: 'ACST',
    latitude: -12.4381,
    longitude: 130.8411,
  },
  {
    country_code: 'AU',
    country_name: 'Australia',
    tz_identifier: 'Australia/Eucla',
    tz_offset: '+08:45',
    tz_abbr: '+0845',
    latitude: -31.6429079,
    longitude: 128.1374432,
  },
  {
    country_code: 'AU',
    country_name: 'Australia',
    tz_identifier: 'Australia/Hobart',
    tz_offset: '+10:00',
    tz_abbr: 'AEST',
    summer_time: {
      tz_abbr: 'AEDT',
      tz_offset: '+11:00',
    },
    latitude: -42.8806,
    longitude: 147.325,
  },
  {
    country_code: 'AU',
    country_name: 'Australia',
    tz_identifier: 'Australia/Lindeman',
    tz_offset: '+10:00',
    tz_abbr: 'AEST',
    latitude: -20.4452378,
    longitude: 149.0349127,
  },
  {
    country_code: 'AU',
    country_name: 'Australia',
    tz_identifier: 'Australia/Lord_Howe',
    tz_offset: '+10:30',
    tz_abbr: '+1030',
    summer_time: {
      tz_abbr: '+11',
      tz_offset: '+11:00',
    },
    latitude: -31.5410184,
    longitude: 159.0301227,
  },
  {
    country_code: 'AU',
    country_name: 'Australia',
    tz_identifier: 'Australia/Melbourne',
    tz_offset: '+10:00',
    tz_abbr: 'AEST',
    summer_time: {
      tz_abbr: 'AEDT',
      tz_offset: '+11:00',
    },
    latitude: -37.8142,
    longitude: 144.9631,
  },
  {
    country_code: 'AU',
    country_name: 'Australia',
    tz_identifier: 'Australia/Perth',
    tz_offset: '+08:00',
    tz_abbr: 'AWST',
    latitude: -32.0399569,
    longitude: 115.7963896,
  },
  {
    country_code: 'AU',
    country_name: 'Australia',
    tz_identifier: 'Australia/Sydney',
    tz_offset: '+10:00',
    tz_abbr: 'AEST',
    summer_time: {
      tz_abbr: 'AEDT',
      tz_offset: '+11:00',
    },
    latitude: -33.8481339,
    longitude: 150.7668289,
  },
  {
    country_code: 'AW',
    country_name: 'Aruba',
    tz_identifier: 'America/Aruba',
    tz_offset: '-04:00',
    tz_abbr: 'AST',
    latitude: 12.5183523,
    longitude: -70.008574,
  },
  {
    country_code: 'AX',
    country_name: 'Åland Islands',
    tz_identifier: 'Europe/Mariehamn',
    tz_offset: '+02:00',
    tz_abbr: 'EET',
    summer_time: {
      tz_abbr: 'EEST',
      tz_offset: '+03:00',
    },
    latitude: 60.2488925,
    longitude: 19.6168566,
  },
  {
    country_code: 'AZ',
    country_name: 'Azerbaijan',
    tz_identifier: 'Asia/Baku',
    tz_offset: '+04:00',
    tz_abbr: '+04',
    latitude: 40.1683455,
    longitude: 46.4330034,
  },
  {
    country_code: 'BA',
    country_name: 'Bosnia and Herzegovina',
    tz_identifier: 'Europe/Sarajevo',
    tz_offset: '+01:00',
    tz_abbr: 'CET',
    summer_time: {
      tz_abbr: 'CEST',
      tz_offset: '+02:00',
    },
    latitude: 43.9083692,
    longitude: 16.3547388,
  },
  {
    country_code: 'BB',
    country_name: 'Barbados',
    tz_identifier: 'America/Barbados',
    tz_offset: '-04:00',
    tz_abbr: 'AST',
    latitude: 13.1881634,
    longitude: -59.6178268,
  },
  {
    country_code: 'BD',
    country_name: 'Bangladesh',
    tz_identifier: 'Asia/Dhaka',
    tz_offset: '+06:00',
    tz_abbr: '+06',
    latitude: 23.5010812,
    longitude: 89.0226575,
  },
  {
    country_code: 'BE',
    country_name: 'Belgium',
    tz_identifier: 'Europe/Brussels',
    tz_offset: '+01:00',
    tz_abbr: 'CET',
    summer_time: {
      tz_abbr: 'CEST',
      tz_offset: '+02:00',
    },
    latitude: 50.4935778,
    longitude: 3.1447238,
  },
  {
    country_code: 'BF',
    country_name: 'Burkina Faso',
    tz_identifier: 'Africa/Ouagadougou',
    tz_offset: '+00:00',
    tz_abbr: 'GMT',
    latitude: 12.3584796,
    longitude: -1.6194147,
  },
  {
    country_code: 'BG',
    country_name: 'Bulgaria',
    tz_identifier: 'Europe/Sofia',
    tz_offset: '+02:00',
    tz_abbr: 'EET',
    summer_time: {
      tz_abbr: 'EEST',
      tz_offset: '+03:00',
    },
    latitude: 42.7177799,
    longitude: 24.2217786,
  },
  {
    country_code: 'BH',
    country_name: 'Bahrain',
    tz_identifier: 'Asia/Bahrain',
    tz_offset: '+03:00',
    tz_abbr: '+03',
    latitude: 25.9408564,
    longitude: 50.4224296,
  },
  {
    country_code: 'BI',
    country_name: 'Burundi',
    tz_identifier: 'Africa/Bujumbura',
    tz_offset: '+02:00',
    tz_abbr: 'CAT',
    latitude: -3.3752998,
    longitude: 29.313094,
  },
  {
    country_code: 'BJ',
    country_name: 'Benin',
    tz_identifier: 'Africa/Porto-Novo',
    tz_offset: '+01:00',
    tz_abbr: 'WAT',
    latitude: 6.4959455,
    longitude: 2.5444469,
  },
  {
    country_code: 'BL',
    country_name: 'Saint Barthélemy',
    tz_identifier: 'America/St_Barthelemy',
    tz_offset: '-04:00',
    tz_abbr: 'AST',
    latitude: 17.9036835,
    longitude: -62.8523015,
  },
  {
    country_code: 'BM',
    country_name: 'Bermuda',
    tz_identifier: 'Atlantic/Bermuda',
    tz_offset: '-04:00',
    tz_abbr: 'AST',
    summer_time: {
      tz_abbr: 'ADT',
      tz_offset: '-03:00',
    },
    latitude: 32.3192719,
    longitude: -64.848971,
  },
  {
    country_code: 'BN',
    country_name: 'Brunei Darussalam',
    tz_identifier: 'Asia/Brunei',
    tz_offset: '+08:00',
    tz_abbr: '+08',
    latitude: 4.5501467,
    longitude: 114.389621,
  },
  {
    country_code: 'BO',
    country_name: 'Bolivia, Plurinational State of',
    tz_identifier: 'America/La_Paz',
    tz_offset: '-04:00',
    tz_abbr: '-04',
    latitude: -16.2670842,
    longitude: -66.1945648,
  },
  {
    country_code: 'BQ',
    country_name: 'Bonaire, Sint Eustatius and Saba',
    tz_identifier: 'America/Kralendijk',
    tz_offset: '-04:00',
    tz_abbr: 'AST',
    latitude: 12.167782,
    longitude: -68.3902114,
  },
  {
    country_code: 'BR',
    country_name: 'Brazil',
    tz_identifier: 'America/Araguaina',
    tz_offset: '-03:00',
    tz_abbr: '-03',
    latitude: -7.2109449,
    longitude: -48.2586512,
  },
  {
    country_code: 'BR',
    country_name: 'Brazil',
    tz_identifier: 'America/Bahia',
    tz_offset: '-03:00',
    tz_abbr: '-03',
    latitude: -13.4268869,
    longitude: -44.6045086,
  },
  {
    country_code: 'BR',
    country_name: 'Brazil',
    tz_identifier: 'America/Belem',
    tz_offset: '-03:00',
    tz_abbr: '-03',
    latitude: -1.3413506,
    longitude: -48.5294353,
  },
  {
    country_code: 'BR',
    country_name: 'Brazil',
    tz_identifier: 'America/Boa_Vista',
    tz_offset: '-04:00',
    tz_abbr: '-04',
    latitude: 2.8071983,
    longitude: -60.7378466,
  },
  {
    country_code: 'BR',
    country_name: 'Brazil',
    tz_identifier: 'America/Campo_Grande',
    tz_offset: '-04:00',
    tz_abbr: '-04',
    latitude: -20.4810803,
    longitude: -54.7181053,
  },
  {
    country_code: 'BR',
    country_name: 'Brazil',
    tz_identifier: 'America/Cuiaba',
    tz_offset: '-04:00',
    tz_abbr: '-04',
    latitude: -15.6144035,
    longitude: -56.0830921,
  },
  {
    country_code: 'BR',
    country_name: 'Brazil',
    tz_identifier: 'America/Eirunepe',
    tz_offset: '-05:00',
    tz_abbr: '-05',
    latitude: -6.6623698,
    longitude: -69.8868925,
  },
  {
    country_code: 'BR',
    country_name: 'Brazil',
    tz_identifier: 'America/Fortaleza',
    tz_offset: '-03:00',
    tz_abbr: '-03',
    latitude: -3.7933105,
    longitude: -38.6021867,
  },
  {
    country_code: 'BR',
    country_name: 'Brazil',
    tz_identifier: 'America/Maceio',
    tz_offset: '-03:00',
    tz_abbr: '-03',
    latitude: -9.594565,
    longitude: -35.76925,
  },
  {
    country_code: 'BR',
    country_name: 'Brazil',
    tz_identifier: 'America/Manaus',
    tz_offset: '-04:00',
    tz_abbr: '-04',
    latitude: -3.0446589,
    longitude: -60.0496753,
  },
  {
    country_code: 'BR',
    country_name: 'Brazil',
    tz_identifier: 'America/Noronha',
    tz_offset: '-02:00',
    tz_abbr: '-02',
    latitude: -3.853808,
    longitude: -32.423786,
  },
  {
    country_code: 'BR',
    country_name: 'Brazil',
    tz_identifier: 'America/Porto_Velho',
    tz_offset: '-04:00',
    tz_abbr: '-04',
    latitude: -8.76666,
    longitude: -63.9,
  },
  {
    country_code: 'BR',
    country_name: 'Brazil',
    tz_identifier: 'America/Recife',
    tz_offset: '-03:00',
    tz_abbr: '-03',
    latitude: -8.05,
    longitude: -34.900002,
  },
  {
    country_code: 'BR',
    country_name: 'Brazil',
    tz_identifier: 'America/Rio_Branco',
    tz_offset: '-05:00',
    tz_abbr: '-05',
    latitude: -9.96666,
    longitude: -67.8,
  },
  {
    country_code: 'BR',
    country_name: 'Brazil',
    tz_identifier: 'America/Santarem',
    tz_offset: '-03:00',
    tz_abbr: '-03',
    latitude: -2.44306,
    longitude: -54.70833,
  },
  {
    country_code: 'BR',
    country_name: 'Brazil',
    tz_identifier: 'America/Sao_Paulo',
    tz_offset: '-03:00',
    tz_abbr: '-03',
    latitude: -23.53333,
    longitude: -46.61666,
  },
  {
    country_code: 'BS',
    country_name: 'Bahamas',
    tz_identifier: 'America/Nassau',
    tz_offset: '-05:00',
    tz_abbr: 'EST',
    summer_time: {
      tz_abbr: 'EDT',
      tz_offset: '-04:00',
    },
    latitude: 25.03428,
    longitude: -77.39628,
  },
  {
    country_code: 'BT',
    country_name: 'Bhutan',
    tz_identifier: 'Asia/Thimphu',
    tz_offset: '+06:00',
    tz_abbr: '+06',
    latitude: 27.514162,
    longitude: 90.433601,
  },
  {
    country_code: 'BW',
    country_name: 'Botswana',
    tz_identifier: 'Africa/Gaborone',
    tz_offset: '+02:00',
    tz_abbr: 'CAT',
    latitude: -24.64631346,
    longitude: 25.91194779,
  },
  {
    country_code: 'BY',
    country_name: 'Belarus',
    tz_identifier: 'Europe/Minsk',
    tz_offset: '+03:00',
    tz_abbr: '+03',
    latitude: 53.89997744,
    longitude: 27.56662716,
  },
  {
    country_code: 'BZ',
    country_name: 'Belize',
    tz_identifier: 'America/Belize',
    tz_offset: '-06:00',
    tz_abbr: 'CST',
    latitude: 17.189877,
    longitude: -88.49765,
  },
  {
    country_code: 'CA',
    country_name: 'Canada',
    tz_identifier: 'America/Atikokan',
    tz_offset: '-05:00',
    tz_abbr: 'EST',
    summer_time: {
      tz_abbr: 'EDT',
      tz_offset: '-04:00',
    },
    latitude: 48.75039512,
    longitude: -91.61658899,
  },
  {
    country_code: 'CA',
    country_name: 'Canada',
    tz_identifier: 'America/Blanc-Sablon',
    tz_offset: '-04:00',
    tz_abbr: 'AST',
    latitude: 51.4153475,
    longitude: -57.4030388,
  },
  {
    country_code: 'CA',
    country_name: 'Canada',
    tz_identifier: 'America/Cambridge_Bay',
    tz_offset: '-07:00',
    tz_abbr: 'MST',
    summer_time: {
      tz_abbr: 'MDT',
      tz_offset: '-06:00',
    },
    latitude: 45.41666667,
    longitude: -75.7,
  },
  {
    country_code: 'CA',
    country_name: 'Canada',
    tz_identifier: 'America/Creston',
    tz_offset: '-07:00',
    tz_abbr: 'MST',
    latitude: 49.09996035,
    longitude: -116.516697,
  },
  {
    country_code: 'CA',
    country_name: 'Canada',
    tz_identifier: 'America/Dawson',
    tz_offset: '-07:00',
    tz_abbr: 'MST',
    latitude: 56.130366,
    longitude: -106.346771,
  },
  {
    country_code: 'CA',
    country_name: 'Canada',
    tz_identifier: 'America/Dawson_Creek',
    tz_offset: '-07:00',
    tz_abbr: 'MST',
    summer_time: {
      tz_abbr: 'MDT',
      tz_offset: '-06:00',
    },
    latitude: 56.130366,
    longitude: -106.346771,
  },
  {
    country_code: 'CA',
    country_name: 'Canada',
    tz_identifier: 'America/Edmonton',
    tz_offset: '-07:00',
    tz_abbr: 'MST',
    summer_time: {
      tz_abbr: 'MDT',
      tz_offset: '-06:00',
    },
    latitude: 53.55002464,
    longitude: -113.4999819,
  },
  {
    country_code: 'CA',
    country_name: 'Canada',
    tz_identifier: 'America/Fort_Nelson',
    tz_offset: '-07:00',
    tz_abbr: 'MST',
    latitude: 56.130366,
    longitude: -106.346771,
  },
  {
    country_code: 'CA',
    country_name: 'Canada',
    tz_identifier: 'America/Glace_Bay',
    tz_offset: '-04:00',
    tz_abbr: 'AST',
    summer_time: {
      tz_abbr: 'ADT',
      tz_offset: '-03:00',
    },
    latitude: 46.1913107,
    longitude: -59.9907204,
  },
  {
    country_code: 'CA',
    country_name: 'Canada',
    tz_identifier: 'America/Goose_Bay',
    tz_offset: '-04:00',
    tz_abbr: 'AST',
    summer_time: {
      tz_abbr: 'ADT',
      tz_offset: '-03:00',
    },
    latitude: 53.3457633,
    longitude: -60.4765397,
  },
  {
    country_code: 'CA',
    country_name: 'Canada',
    tz_identifier: 'America/Halifax',
    tz_offset: '-04:00',
    tz_abbr: 'AST',
    summer_time: {
      tz_abbr: 'ADT',
      tz_offset: '-03:00',
    },
    latitude: 44.6461816,
    longitude: -63.6741824,
  },
  {
    country_code: 'CA',
    country_name: 'Canada',
    tz_identifier: 'America/Inuvik',
    tz_offset: '-07:00',
    tz_abbr: 'MST',
    summer_time: {
      tz_abbr: 'MDT',
      tz_offset: '-06:00',
    },
    latitude: 68.346191,
    longitude: -133.6642519,
  },
  {
    country_code: 'CA',
    country_name: 'Canada',
    tz_identifier: 'America/Iqaluit',
    tz_offset: '-05:00',
    tz_abbr: 'EST',
    summer_time: {
      tz_abbr: 'EDT',
      tz_offset: '-04:00',
    },
    latitude: 63.7387726,
    longitude: -68.5585127,
  },
  {
    country_code: 'CA',
    country_name: 'Canada',
    tz_identifier: 'America/Moncton',
    tz_offset: '-04:00',
    tz_abbr: 'AST',
    summer_time: {
      tz_abbr: 'ADT',
      tz_offset: '-03:00',
    },
    latitude: 46.1132252,
    longitude: -64.8831712,
  },
  {
    country_code: 'CA',
    country_name: 'Canada',
    tz_identifier: 'America/Rankin_Inlet',
    tz_offset: '-06:00',
    tz_abbr: 'CST',
    summer_time: {
      tz_abbr: 'CDT',
      tz_offset: '-05:00',
    },
    latitude: 62.8313369,
    longitude: -92.1600324,
  },
  {
    country_code: 'CA',
    country_name: 'Canada',
    tz_identifier: 'America/Regina',
    tz_offset: '-06:00',
    tz_abbr: 'CST',
    latitude: 50.458565,
    longitude: -104.7179797,
  },
  {
    country_code: 'CA',
    country_name: 'Canada',
    tz_identifier: 'America/Resolute',
    tz_offset: '-06:00',
    tz_abbr: 'CST',
    summer_time: {
      tz_abbr: 'CDT',
      tz_offset: '-05:00',
    },
    latitude: 74.6934329,
    longitude: -95.0735041,
  },
  {
    country_code: 'CA',
    country_name: 'Canada',
    tz_identifier: 'America/St_Johns',
    tz_offset: '-03:30',
    tz_abbr: 'NST',
    summer_time: {
      tz_abbr: 'NDT',
      tz_offset: '-02:30',
    },
    latitude: 47.4826016,
    longitude: -52.9122109,
  },
  {
    country_code: 'CA',
    country_name: 'Canada',
    tz_identifier: 'America/Swift_Current',
    tz_offset: '-06:00',
    tz_abbr: 'CST',
    latitude: 50.2876982,
    longitude: -107.8130214,
  },
  {
    country_code: 'CA',
    country_name: 'Canada',
    tz_identifier: 'America/Toronto',
    tz_offset: '-05:00',
    tz_abbr: 'EST',
    summer_time: {
      tz_abbr: 'EDT',
      tz_offset: '-04:00',
    },
    latitude: 43.7181223,
    longitude: -79.5432028,
  },
  {
    country_code: 'CA',
    country_name: 'Canada',
    tz_identifier: 'America/Vancouver',
    tz_offset: '-08:00',
    tz_abbr: 'PST',
    summer_time: {
      tz_abbr: 'PDT',
      tz_offset: '-07:00',
    },
    latitude: 49.2577281,
    longitude: -123.1651889,
  },
  {
    country_code: 'CA',
    country_name: 'Canada',
    tz_identifier: 'America/Whitehorse',
    tz_offset: '-07:00',
    tz_abbr: 'MST',
    latitude: 60.7033516,
    longitude: -135.2433625,
  },
  {
    country_code: 'CA',
    country_name: 'Canada',
    tz_identifier: 'America/Winnipeg',
    tz_offset: '-06:00',
    tz_abbr: 'CST',
    summer_time: {
      tz_abbr: 'CDT',
      tz_offset: '-05:00',
    },
    latitude: 49.8537513,
    longitude: -97.2354104,
  },
  {
    country_code: 'CC',
    country_name: 'Cocos (Keeling) Islands',
    tz_identifier: 'Indian/Cocos',
    tz_offset: '+06:30',
    tz_abbr: '+0630',
    latitude: -12.0159,
    longitude: 96.7904927,
  },
  {
    country_code: 'CD',
    country_name: 'Congo, the Democratic Republic of the',
    tz_identifier: 'Africa/Kinshasa',
    tz_offset: '+01:00',
    tz_abbr: 'WAT',
    latitude: -4.4012992,
    longitude: 15.2401732,
  },
  {
    country_code: 'CD',
    country_name: 'Congo, the Democratic Republic of the',
    tz_identifier: 'Africa/Lubumbashi',
    tz_offset: '+02:00',
    tz_abbr: 'CAT',
    latitude: -11.6753392,
    longitude: 27.4609065,
  },
  {
    country_code: 'CF',
    country_name: 'Central African Republic',
    tz_identifier: 'Africa/Bangui',
    tz_offset: '+01:00',
    tz_abbr: 'WAT',
    latitude: 4.3782212,
    longitude: 18.535914,
  },
  {
    country_code: 'CG',
    country_name: 'Congo',
    tz_identifier: 'Africa/Brazzaville',
    tz_offset: '+01:00',
    tz_abbr: 'WAT',
    latitude: -4.2471907,
    longitude: 15.144651,
  },
  {
    country_code: 'CH',
    country_name: 'Switzerland',
    tz_identifier: 'Europe/Zurich',
    tz_offset: '+01:00',
    tz_abbr: 'CET',
    summer_time: {
      tz_abbr: 'CEST',
      tz_offset: '+02:00',
    },
    latitude: 46.8055826,
    longitude: 6.9027709,
  },
  {
    country_code: 'CI',
    country_name: "Côte d'Ivoire",
    tz_identifier: 'Africa/Abidjan',
    tz_offset: '+00:00',
    tz_abbr: 'GMT',
    latitude: 5.3484446,
    longitude: -4.0622364,
  },
  {
    country_code: 'CK',
    country_name: 'Cook Islands',
    tz_identifier: 'Pacific/Rarotonga',
    tz_offset: '-10:00',
    tz_abbr: '-10',
    latitude: -21.235742,
    longitude: -159.7983684,
  },
  {
    country_code: 'CL',
    country_name: 'Chile',
    tz_identifier: 'America/Punta_Arenas',
    tz_offset: '-03:00',
    tz_abbr: '-03',
    latitude: -53.1417602,
    longitude: -70.9475521,
  },
  {
    country_code: 'CL',
    country_name: 'Chile',
    tz_identifier: 'America/Santiago',
    tz_offset: '-04:00',
    tz_abbr: '-03',
    summer_time: {
      tz_abbr: '-03',
      tz_offset: '-03:00',
    },
    latitude: -33.4727606,
    longitude: -70.7124023,
  },
  {
    country_code: 'CL',
    country_name: 'Chile',
    tz_identifier: 'Pacific/Easter',
    tz_offset: '-06:00',
    tz_abbr: '-06',
    summer_time: {
      tz_abbr: '-05',
      tz_offset: '-05:00',
    },
    latitude: -27.1259739,
    longitude: -109.3800998,
  },
  {
    country_code: 'CM',
    country_name: 'Cameroon',
    tz_identifier: 'Africa/Douala',
    tz_offset: '+01:00',
    tz_abbr: 'WAT',
    latitude: 4.0359027,
    longitude: 9.7005195,
  },
  {
    country_code: 'CN',
    country_name: 'China',
    tz_identifier: 'Asia/Shanghai',
    tz_offset: '+08:00',
    tz_abbr: 'CST',
    latitude: 31.2239269,
    longitude: 121.1464445,
  },
  {
    country_code: 'CN',
    country_name: 'China',
    tz_identifier: 'Asia/Urumqi',
    tz_offset: '+06:00',
    tz_abbr: '+06',
    latitude: 43.821683,
    longitude: 87.480181,
  },
  {
    country_code: 'CO',
    country_name: 'Colombia',
    tz_identifier: 'America/Bogota',
    tz_offset: '-05:00',
    tz_abbr: '-05',
    latitude: 4.6482927,
    longitude: -74.1903784,
  },
  {
    country_code: 'CR',
    country_name: 'Costa Rica',
    tz_identifier: 'America/Costa_Rica',
    tz_offset: '-06:00',
    tz_abbr: 'CST',
    latitude: 8.3556979,
    longitude: -86.1304484,
  },
  {
    country_code: 'CU',
    country_name: 'Cuba',
    tz_identifier: 'America/Havana',
    tz_offset: '-05:00',
    tz_abbr: 'CST',
    summer_time: {
      tz_abbr: 'CDT',
      tz_offset: '-04:00',
    },
    latitude: 23.0506009,
    longitude: -82.4981489,
  },
  {
    country_code: 'CV',
    country_name: 'Cape Verde',
    tz_identifier: 'Atlantic/Cape_Verde',
    tz_offset: '-01:00',
    tz_abbr: '-01',
    latitude: 16.0221142,
    longitude: -24.6493531,
  },
  {
    country_code: 'CW',
    country_name: 'Curaçao',
    tz_identifier: 'America/Curacao',
    tz_offset: '-04:00',
    tz_abbr: 'AST',
    latitude: 12.2082508,
    longitude: -69.0981475,
  },
  {
    country_code: 'CX',
    country_name: 'Christmas Island',
    tz_identifier: 'Indian/Christmas',
    tz_offset: '+07:00',
    tz_abbr: '+07',
    latitude: -10.4913543,
    longitude: 105.581842,
  },
  {
    country_code: 'CY',
    country_name: 'Cyprus',
    tz_identifier: 'Asia/Famagusta',
    tz_offset: '+02:00',
    tz_abbr: 'EET',
    summer_time: {
      tz_abbr: 'EEST',
      tz_offset: '+03:00',
    },
    latitude: 35.3277978,
    longitude: 33.7399009,
  },
  {
    country_code: 'CY',
    country_name: 'Cyprus',
    tz_identifier: 'Asia/Nicosia',
    tz_offset: '+02:00',
    tz_abbr: 'EET',
    summer_time: {
      tz_abbr: 'EEST',
      tz_offset: '+03:00',
    },
    latitude: 35.1922455,
    longitude: 33.3561172,
  },
  {
    country_code: 'CZ',
    country_name: 'Czech Republic',
    tz_identifier: 'Europe/Prague',
    tz_offset: '+01:00',
    tz_abbr: 'CET',
    summer_time: {
      tz_abbr: 'CEST',
      tz_offset: '+02:00',
    },
    latitude: 50.0595525,
    longitude: 14.3004799,
  },
  {
    country_code: 'DE',
    country_name: 'Germany',
    tz_identifier: 'Europe/Berlin',
    tz_offset: '+01:00',
    tz_abbr: 'CET',
    summer_time: {
      tz_abbr: 'CEST',
      tz_offset: '+02:00',
    },
    latitude: 52.5067292,
    longitude: 13.2595881,
  },
  {
    country_code: 'DE',
    country_name: 'Germany',
    tz_identifier: 'Europe/Busingen',
    tz_offset: '+01:00',
    tz_abbr: 'CET',
    summer_time: {
      tz_abbr: 'CEST',
      tz_offset: '+02:00',
    },
    latitude: 47.7000152,
    longitude: 8.6675975,
  },
  {
    country_code: 'DJ',
    country_name: 'Djibouti',
    tz_identifier: 'Africa/Djibouti',
    tz_offset: '+03:00',
    tz_abbr: 'EAT',
    latitude: 11.8131343,
    longitude: 42.2970589,
  },
  {
    country_code: 'DK',
    country_name: 'Denmark',
    tz_identifier: 'Europe/Copenhagen',
    tz_offset: '+01:00',
    tz_abbr: 'CET',
    summer_time: {
      tz_abbr: 'CEST',
      tz_offset: '+02:00',
    },
    latitude: 55.6711688,
    longitude: 12.4381185,
  },
  {
    country_code: 'DM',
    country_name: 'Dominica',
    tz_identifier: 'America/Dominica',
    tz_offset: '-04:00',
    tz_abbr: 'AST',
    latitude: 15.426358,
    longitude: -61.4400763,
  },
  {
    country_code: 'DO',
    country_name: 'Dominican Republic',
    tz_identifier: 'America/Santo_Domingo',
    tz_offset: '-04:00',
    tz_abbr: 'AST',
    latitude: 18.4800379,
    longitude: -69.9881651,
  },
  {
    country_code: 'DZ',
    country_name: 'Algeria',
    tz_identifier: 'Africa/Algiers',
    tz_offset: '+01:00',
    tz_abbr: 'CET',
    latitude: 36.6965509,
    longitude: 2.9270748,
  },
  {
    country_code: 'EC',
    country_name: 'Ecuador',
    tz_identifier: 'America/Guayaquil',
    tz_offset: '-05:00',
    tz_abbr: '-05',
    latitude: -2.152499,
    longitude: -80.0626722,
  },
  {
    country_code: 'EC',
    country_name: 'Ecuador',
    tz_identifier: 'Pacific/Galapagos',
    tz_offset: '-06:00',
    tz_abbr: '-06',
    latitude: -0.2612669,
    longitude: -90.7918977,
  },
  {
    country_code: 'EE',
    country_name: 'Estonia',
    tz_identifier: 'Europe/Tallinn',
    tz_offset: '+02:00',
    tz_abbr: 'EET',
    summer_time: {
      tz_abbr: 'EEST',
      tz_offset: '+03:00',
    },
    latitude: 59.471667,
    longitude: 24.6556712,
  },
  {
    country_code: 'EG',
    country_name: 'Egypt',
    tz_identifier: 'Africa/Cairo',
    tz_offset: '+02:00',
    tz_abbr: 'EET',
    summer_time: {
      tz_abbr: 'EEST',
      tz_offset: '+03:00',
    },
    latitude: 30.0594868,
    longitude: 31.2378219,
  },
  {
    country_code: 'ER',
    country_name: 'Eritrea',
    tz_identifier: 'Africa/Asmara',
    tz_offset: '+03:00',
    tz_abbr: 'EAT',
    latitude: 15.3258133,
    longitude: 38.8754434,
  },
  {
    country_code: 'ES',
    country_name: 'Spain',
    tz_identifier: 'Africa/Ceuta',
    tz_offset: '+01:00',
    tz_abbr: 'CET',
    summer_time: {
      tz_abbr: 'CEST',
      tz_offset: '+02:00',
    },
    latitude: 35.8890546,
    longitude: -5.3391682,
  },
  {
    country_code: 'ES',
    country_name: 'Spain',
    tz_identifier: 'Atlantic/Canary',
    tz_offset: '+00:00',
    tz_abbr: 'WET',
    summer_time: {
      tz_abbr: 'WEST',
      tz_offset: '+01:00',
    },
    latitude: 28.4380798,
    longitude: -17.1749326,
  },
  {
    country_code: 'ES',
    country_name: 'Spain',
    tz_identifier: 'Europe/Madrid',
    tz_offset: '+01:00',
    tz_abbr: 'CET',
    summer_time: {
      tz_abbr: 'CEST',
      tz_offset: '+02:00',
    },
    latitude: 40.4379249,
    longitude: -3.7621075,
  },
  {
    country_code: 'ET',
    country_name: 'Ethiopia',
    tz_identifier: 'Africa/Addis_Ababa',
    tz_offset: '+03:00',
    tz_abbr: 'EAT',
    latitude: 8.9631677,
    longitude: 38.6955734,
  },
  {
    country_code: 'FI',
    country_name: 'Finland',
    tz_identifier: 'Europe/Helsinki',
    tz_offset: '+02:00',
    tz_abbr: 'EET',
    summer_time: {
      tz_abbr: 'EEST',
      tz_offset: '+03:00',
    },
    latitude: 60.110061,
    longitude: 24.8535378,
  },
  {
    country_code: 'FJ',
    country_name: 'Fiji',
    tz_identifier: 'Pacific/Fiji',
    tz_offset: '+12:00',
    tz_abbr: '+12',
    latitude: -16.5375087,
    longitude: 176.8164867,
  },
  {
    country_code: 'FK',
    country_name: 'Falkland Islands (Malvinas)',
    tz_identifier: 'Atlantic/Stanley',
    tz_offset: '-03:00',
    tz_abbr: '-03',
    latitude: -51.6965666,
    longitude: -57.8652425,
  },
  {
    country_code: 'FM',
    country_name: 'Micronesia, Federated States of',
    tz_identifier: 'Pacific/Chuuk',
    tz_offset: '+10:00',
    tz_abbr: '+10',
    latitude: 7.1344504,
    longitude: 150.1742602,
  },
  {
    country_code: 'FM',
    country_name: 'Micronesia, Federated States of',
    tz_identifier: 'Pacific/Kosrae',
    tz_offset: '+11:00',
    tz_abbr: '+11',
    latitude: 5.314397,
    longitude: 162.9256568,
  },
  {
    country_code: 'FM',
    country_name: 'Micronesia, Federated States of',
    tz_identifier: 'Pacific/Pohnpei',
    tz_offset: '+11:00',
    tz_abbr: '+11',
    latitude: 4.0551613,
    longitude: 156.3906448,
  },
  {
    country_code: 'FO',
    country_name: 'Faroe Islands',
    tz_identifier: 'Atlantic/Faroe',
    tz_offset: '+00:00',
    tz_abbr: 'WET',
    summer_time: {
      tz_abbr: 'WEST',
      tz_offset: '+01:00',
    },
    latitude: 61.8978435,
    longitude: -7.6149485,
  },
  {
    country_code: 'FR',
    country_name: 'France',
    tz_identifier: 'Europe/Paris',
    tz_offset: '+01:00',
    tz_abbr: 'CET',
    summer_time: {
      tz_abbr: 'CEST',
      tz_offset: '+02:00',
    },
    latitude: 48.8588254,
    longitude: 2.2644644,
  },
  {
    country_code: 'GA',
    country_name: 'Gabon',
    tz_identifier: 'Africa/Libreville',
    tz_offset: '+01:00',
    tz_abbr: 'WAT',
    latitude: 0.4112099,
    longitude: 9.3520582,
  },
  {
    country_code: 'GB',
    country_name: 'United Kingdom',
    tz_identifier: 'Europe/London',
    tz_offset: '+00:00',
    tz_abbr: 'GMT',
    summer_time: {
      tz_abbr: 'BST',
      tz_offset: '+01:00',
    },
    latitude: 51.5285257,
    longitude: -0.2667424,
  },
  {
    country_code: 'GD',
    country_name: 'Grenada',
    tz_identifier: 'America/Grenada',
    tz_offset: '-04:00',
    tz_abbr: 'AST',
    latitude: 12.2597532,
    longitude: -61.7554474,
  },
  {
    country_code: 'GE',
    country_name: 'Georgia',
    tz_identifier: 'Asia/Tbilisi',
    tz_offset: '+04:00',
    tz_abbr: '+04',
    latitude: 41.7323053,
    longitude: 44.7243423,
  },
  {
    country_code: 'GF',
    country_name: 'French Guiana',
    tz_identifier: 'America/Cayenne',
    tz_offset: '-03:00',
    tz_abbr: '-03',
    latitude: 5.0920803,
    longitude: -52.4611954,
  },
  {
    country_code: 'GG',
    country_name: 'Guernsey',
    tz_identifier: 'Europe/Guernsey',
    tz_offset: '+00:00',
    tz_abbr: 'GMT',
    summer_time: {
      tz_abbr: 'BST',
      tz_offset: '+01:00',
    },
    latitude: 49.5796674,
    longitude: -2.8658454,
  },
  {
    country_code: 'GH',
    country_name: 'Ghana',
    tz_identifier: 'Africa/Accra',
    tz_offset: '+00:00',
    tz_abbr: 'GMT',
    latitude: 5.5912029,
    longitude: -0.2623009,
  },
  {
    country_code: 'GI',
    country_name: 'Gibraltar',
    tz_identifier: 'Europe/Gibraltar',
    tz_offset: '+01:00',
    tz_abbr: 'CET',
    summer_time: {
      tz_abbr: 'CEST',
      tz_offset: '+02:00',
    },
    latitude: 36.1295075,
    longitude: -5.3739424,
  },
  {
    country_code: 'GL',
    country_name: 'Greenland',
    tz_identifier: 'America/Danmarkshavn',
    tz_offset: '+00:00',
    tz_abbr: 'GMT',
    latitude: 76.772154,
    longitude: -18.6890564,
  },
  {
    country_code: 'GL',
    country_name: 'Greenland',
    tz_identifier: 'America/Nuuk',
    tz_offset: '-02:00',
    tz_abbr: '-02',
    latitude: 64.1791052,
    longitude: -51.7274523,
  },
  {
    country_code: 'GL',
    country_name: 'Greenland',
    tz_identifier: 'America/Scoresbysund',
    tz_offset: '+00:00',
    tz_abbr: 'GMT',
    latitude: 70.486343,
    longitude: -21.9695877,
  },
  {
    country_code: 'GL',
    country_name: 'Greenland',
    tz_identifier: 'America/Thule',
    tz_offset: '-04:00',
    tz_abbr: 'AST',
    summer_time: {
      tz_abbr: 'ADT',
      tz_offset: '+03:00',
    },
    latitude: 77.4674015,
    longitude: -69.2380928,
  },
  {
    country_code: 'GM',
    country_name: 'Gambia',
    tz_identifier: 'Africa/Banjul',
    tz_offset: '+00:00',
    tz_abbr: 'GMT',
    latitude: 13.4594119,
    longitude: -16.6196003,
  },
  {
    country_code: 'GN',
    country_name: 'Guinea',
    tz_identifier: 'Africa/Conakry',
    tz_offset: '+00:00',
    tz_abbr: 'GMT',
    latitude: 9.6342154,
    longitude: -13.6619025,
  },
  {
    country_code: 'GP',
    country_name: 'Guadeloupe',
    tz_identifier: 'America/Guadeloupe',
    tz_offset: '-04:00',
    tz_abbr: 'AST',
    latitude: 16.1493753,
    longitude: -61.7273632,
  },
  {
    country_code: 'GQ',
    country_name: 'Equatorial Guinea',
    tz_identifier: 'Africa/Malabo',
    tz_offset: '+01:00',
    tz_abbr: 'WAT',
    latitude: 3.755446,
    longitude: 8.7003925,
  },
  {
    country_code: 'GR',
    country_name: 'Greece',
    tz_identifier: 'Europe/Athens',
    tz_offset: '+02:00',
    tz_abbr: 'EET',
    summer_time: {
      tz_abbr: 'EEST',
      tz_offset: '+03:00',
    },
    latitude: 37.9908354,
    longitude: 23.717697,
  },
  {
    country_code: 'GS',
    country_name: 'South Georgia and the South Sandwich Islands',
    tz_identifier: 'Atlantic/South_Georgia',
    tz_offset: '-02:00',
    tz_abbr: '-02',
    latitude: -56.8538512,
    longitude: -34.5907063,
  },
  {
    country_code: 'GT',
    country_name: 'Guatemala',
    tz_identifier: 'America/Guatemala',
    tz_offset: '-06:00',
    tz_abbr: 'CST',
    latitude: 15.7188718,
    longitude: -91.5565642,
  },
  {
    country_code: 'GU',
    country_name: 'Guam',
    tz_identifier: 'Pacific/Guam',
    tz_offset: '+10:00',
    tz_abbr: 'ChST',
    latitude: 13.4507585,
    longitude: 144.623671,
  },
  {
    country_code: 'GW',
    country_name: 'Guinea-Bissau',
    tz_identifier: 'Africa/Bissau',
    tz_offset: '+00:00',
    tz_abbr: 'GMT',
    latitude: 11.8695462,
    longitude: -15.6329658,
  },
  {
    country_code: 'GY',
    country_name: 'Guyana',
    tz_identifier: 'America/Guyana',
    tz_offset: '-04:00',
    tz_abbr: '-04',
    latitude: 4.9444929,
    longitude: -60.2627415,
  },
  {
    country_code: 'HK',
    country_name: 'Hong Kong',
    tz_identifier: 'Asia/Hong_Kong',
    tz_offset: '+08:00',
    tz_abbr: 'HKT',
    latitude: 22.3526397,
    longitude: 113.9625531,
  },
  {
    country_code: 'HN',
    country_name: 'Honduras',
    tz_identifier: 'America/Tegucigalpa',
    tz_offset: '-06:00',
    tz_abbr: 'CST',
    latitude: 14.0839119,
    longitude: -87.2462578,
  },
  {
    country_code: 'HR',
    country_name: 'Croatia',
    tz_identifier: 'Europe/Zagreb',
    tz_offset: '+01:00',
    tz_abbr: 'CET',
    summer_time: {
      tz_abbr: 'CEST',
      tz_offset: '+02:00',
    },
    latitude: 45.8401662,
    longitude: 15.8817608,
  },
  {
    country_code: 'HT',
    country_name: 'Haiti',
    tz_identifier: 'America/Port-au-Prince',
    tz_offset: '-05:00',
    tz_abbr: 'EST',
    summer_time: {
      tz_abbr: 'EDT',
      tz_offset: '-04:00',
    },
    latitude: 18.5790326,
    longitude: -72.3257452,
  },
  {
    country_code: 'HU',
    country_name: 'Hungary',
    tz_identifier: 'Europe/Budapest',
    tz_offset: '+01:00',
    tz_abbr: 'CET',
    summer_time: {
      tz_abbr: 'CEST',
      tz_offset: '+02:00',
    },
    latitude: 47.4811838,
    longitude: 19.0477323,
  },
  {
    country_code: 'ID',
    country_name: 'Indonesia',
    tz_identifier: 'Asia/Jakarta',
    tz_offset: '+07:00',
    tz_abbr: 'WIB',
    latitude: -6.2297401,
    longitude: 106.7469466,
  },
  {
    country_code: 'ID',
    country_name: 'Indonesia',
    tz_identifier: 'Asia/Jayapura',
    tz_offset: '+09:00',
    tz_abbr: 'WIT',
    latitude: -2.5651374,
    longitude: 140.6397426,
  },
  {
    country_code: 'ID',
    country_name: 'Indonesia',
    tz_identifier: 'Asia/Makassar',
    tz_offset: '+08:00',
    tz_abbr: 'WITA',
    latitude: -5.1195902,
    longitude: 119.3200561,
  },
  {
    country_code: 'ID',
    country_name: 'Indonesia',
    tz_identifier: 'Asia/Pontianak',
    tz_offset: '+07:00',
    tz_abbr: 'WIB',
    latitude: -0.0353948,
    longitude: 109.2902654,
  },
  {
    country_code: 'IE',
    country_name: 'Ireland',
    tz_identifier: 'Europe/Dublin',
    tz_offset: '+01:00',
    tz_abbr: 'IST',
    summer_time: {
      tz_abbr: 'GMT',
      tz_offset: '+00:00',
    },
    latitude: 53.3242916,
    longitude: -6.3282746,
  },
  {
    country_code: 'IL',
    country_name: 'Israel',
    tz_identifier: 'Asia/Jerusalem',
    tz_offset: '+02:00',
    tz_abbr: 'IST',
    summer_time: {
      tz_abbr: 'IDT',
      tz_offset: '+03:00',
    },
    latitude: 31.7963119,
    longitude: 35.1340739,
  },
  {
    country_code: 'IM',
    country_name: 'Isle of Man',
    tz_identifier: 'Europe/Isle_of_Man',
    tz_offset: '+00:00',
    tz_abbr: 'GMT',
    summer_time: {
      tz_abbr: 'BST',
      tz_offset: '+01:00',
    },
    latitude: 54.2276935,
    longitude: -4.7372829,
  },
  {
    country_code: 'IN',
    country_name: 'India',
    tz_identifier: 'Asia/Kolkata',
    tz_offset: '+05:30',
    tz_abbr: 'IST',
    latitude: 22.5354063,
    longitude: 88.2647815,
  },
  {
    country_code: 'IO',
    country_name: 'British Indian Ocean Territory',
    tz_identifier: 'Indian/Chagos',
    tz_offset: '+06:00',
    tz_abbr: '+06',
    latitude: -6.1201463,
    longitude: 70.6895541,
  },
  {
    country_code: 'IQ',
    country_name: 'Iraq',
    tz_identifier: 'Asia/Baghdad',
    tz_offset: '+03:00',
    tz_abbr: '+03',
    latitude: 33.3116587,
    longitude: 44.273334,
  },
  {
    country_code: 'IR',
    country_name: 'Iran, Islamic Republic of',
    tz_identifier: 'Asia/Tehran',
    tz_offset: '+03:30',
    tz_abbr: '+0330',
    latitude: 35.707461,
    longitude: 51.1825095,
  },
  {
    country_code: 'IS',
    country_name: 'Iceland',
    tz_identifier: 'Atlantic/Reykjavik',
    tz_offset: '+00:00',
    tz_abbr: 'GMT',
    latitude: 64.133467,
    longitude: -21.9350124,
  },
  {
    country_code: 'IT',
    country_name: 'Italy',
    tz_identifier: 'Europe/Rome',
    tz_offset: '+01:00',
    tz_abbr: 'CET',
    summer_time: {
      tz_abbr: 'CEST',
      tz_offset: '+02:00',
    },
    latitude: 41.8513329,
    longitude: 12.1846065,
  },
  {
    country_code: 'JE',
    country_name: 'Jersey',
    tz_identifier: 'Europe/Jersey',
    tz_offset: '+00:00',
    tz_abbr: 'GMT',
    summer_time: {
      tz_abbr: 'BST',
      tz_offset: '+01:00',
    },
    latitude: 49.1652904,
    longitude: -2.3627705,
  },
  {
    country_code: 'JM',
    country_name: 'Jamaica',
    tz_identifier: 'America/Jamaica',
    tz_offset: '-05:00',
    tz_abbr: 'EST',
    latitude: 18.1182078,
    longitude: -77.9368186,
  },
  {
    country_code: 'JO',
    country_name: 'Jordan',
    tz_identifier: 'Asia/Amman',
    tz_offset: '+03:00',
    tz_abbr: '+03',
    latitude: 31.8356537,
    longitude: 35.7824847,
  },
  {
    country_code: 'JP',
    country_name: 'Japan',
    tz_identifier: 'Asia/Tokyo',
    tz_offset: '+09:00',
    tz_abbr: 'JST',
    latitude: 35.5074395,
    longitude: 139.1091395,
  },
  {
    country_code: 'KE',
    country_name: 'Kenya',
    tz_identifier: 'Africa/Nairobi',
    tz_offset: '+03:00',
    tz_abbr: 'EAT',
    latitude: -1.3032076,
    longitude: 36.7648254,
  },
  {
    country_code: 'KG',
    country_name: 'Kyrgyzstan',
    tz_identifier: 'Asia/Bishkek',
    tz_offset: '+06:00',
    tz_abbr: '+06',
    latitude: 42.8768454,
    longitude: 74.5092902,
  },
  {
    country_code: 'KH',
    country_name: 'Cambodia',
    tz_identifier: 'Asia/Phnom_Penh',
    tz_offset: '+07:00',
    tz_abbr: '+07',
    latitude: 11.5793526,
    longitude: 104.8076154,
  },
  {
    country_code: 'KI',
    country_name: 'Kiribati',
    tz_identifier: 'Pacific/Kanton',
    tz_offset: '+13:00',
    tz_abbr: '+13',
    latitude: -2.8175076,
    longitude: -171.7105768,
  },
  {
    country_code: 'KI',
    country_name: 'Kiribati',
    tz_identifier: 'Pacific/Kiritimati',
    tz_offset: '+14:00',
    tz_abbr: '+14',
    latitude: 1.8704803,
    longitude: -157.4465617,
  },
  {
    country_code: 'KI',
    country_name: 'Kiribati',
    tz_identifier: 'Pacific/Tarawa',
    tz_offset: '+12:00',
    tz_abbr: '+12',
    latitude: 1.4832082,
    longitude: 172.948707,
  },
  {
    country_code: 'KM',
    country_name: 'Comoros',
    tz_identifier: 'Indian/Comoro',
    tz_offset: '+03:00',
    tz_abbr: 'EAT',
    latitude: -11.6517697,
    longitude: 43.2081004,
  },
  {
    country_code: 'KN',
    country_name: 'Saint Kitts and Nevis',
    tz_identifier: 'America/St_Kitts',
    tz_offset: '-04:00',
    tz_abbr: 'AST',
    latitude: 17.3154043,
    longitude: -62.8253664,
  },
  {
    country_code: 'KP',
    country_name: "Korea, Democratic People's Republic of",
    tz_identifier: 'Asia/Pyongyang',
    tz_offset: '+09:00',
    tz_abbr: 'KST',
    latitude: 39.0291091,
    longitude: 125.6595409,
  },
  {
    country_code: 'KR',
    country_name: 'Korea, Republic of',
    tz_identifier: 'Asia/Seoul',
    tz_offset: '+09:00',
    tz_abbr: 'KST',
    latitude: 37.5648473,
    longitude: 126.8915684,
  },
  {
    country_code: 'KW',
    country_name: 'Kuwait',
    tz_identifier: 'Asia/Kuwait',
    tz_offset: '+03:00',
    tz_abbr: '+03',
    latitude: 29.3124466,
    longitude: 46.8750346,
  },
  {
    country_code: 'KY',
    country_name: 'Cayman Islands',
    tz_identifier: 'America/Cayman',
    tz_offset: '-05:00',
    tz_abbr: 'EST',
    latitude: 19.5074503,
    longitude: -80.9045624,
  },
  {
    country_code: 'KZ',
    country_name: 'Kazakhstan',
    tz_identifier: 'Asia/Almaty',
    tz_offset: '+06:00',
    tz_abbr: '+06',
    latitude: 43.2175832,
    longitude: 76.7790203,
  },
  {
    country_code: 'KZ',
    country_name: 'Kazakhstan',
    tz_identifier: 'Asia/Aqtau',
    tz_offset: '+05:00',
    tz_abbr: '+05',
    latitude: 43.6594554,
    longitude: 51.1632933,
  },
  {
    country_code: 'KZ',
    country_name: 'Kazakhstan',
    tz_identifier: 'Asia/Aqtobe',
    tz_offset: '+05:00',
    tz_abbr: '+05',
    latitude: 50.2736545,
    longitude: 57.1112182,
  },
  {
    country_code: 'KZ',
    country_name: 'Kazakhstan',
    tz_identifier: 'Asia/Atyrau',
    tz_offset: '+05:00',
    tz_abbr: '+05',
    latitude: 47.097112,
    longitude: 51.8537315,
  },
  {
    country_code: 'KZ',
    country_name: 'Kazakhstan',
    tz_identifier: 'Asia/Oral',
    tz_offset: '+05:00',
    tz_abbr: '+05',
    latitude: 51.2180891,
    longitude: 51.3035595,
  },
  {
    country_code: 'KZ',
    country_name: 'Kazakhstan',
    tz_identifier: 'Asia/Qostanay',
    tz_offset: '+06:00',
    tz_abbr: '+06',
    latitude: 53.2054223,
    longitude: 63.5392558,
  },
  {
    country_code: 'KZ',
    country_name: 'Kazakhstan',
    tz_identifier: 'Asia/Qyzylorda',
    tz_offset: '+05:00',
    tz_abbr: '+05',
    latitude: 44.8280739,
    longitude: 65.4637657,
  },
  {
    country_code: 'LA',
    country_name: "Lao People's Democratic Republic",
    tz_identifier: 'Asia/Vientiane',
    tz_offset: '+07:00',
    tz_abbr: '+07',
    latitude: 17.9604352,
    longitude: 102.5644805,
  },
  {
    country_code: 'LB',
    country_name: 'Lebanon',
    tz_identifier: 'Asia/Beirut',
    tz_offset: '+02:00',
    tz_abbr: 'EET',
    summer_time: {
      tz_abbr: 'EEST',
      tz_offset: '+03:00',
    },
    latitude: 33.8892166,
    longitude: 35.4836399,
  },
  {
    country_code: 'LC',
    country_name: 'Saint Lucia',
    tz_identifier: 'America/St_Lucia',
    tz_offset: '-04:00',
    tz_abbr: 'AST',
    latitude: 13.9090371,
    longitude: -61.0590902,
  },
  {
    country_code: 'LI',
    country_name: 'Liechtenstein',
    tz_identifier: 'Europe/Vaduz',
    tz_offset: '+01:00',
    tz_abbr: 'CET',
    summer_time: {
      tz_abbr: 'CEST',
      tz_offset: '+02:00',
    },
    latitude: 47.1427232,
    longitude: 9.5055576,
  },
  {
    country_code: 'LK',
    country_name: 'Sri Lanka',
    tz_identifier: 'Asia/Colombo',
    tz_offset: '+05:30',
    tz_abbr: '+0530',
    latitude: 6.9218382,
    longitude: 79.8355631,
  },
  {
    country_code: 'LR',
    country_name: 'Liberia',
    tz_identifier: 'Africa/Monrovia',
    tz_offset: '+00:00',
    tz_abbr: 'GMT',
    latitude: 6.2954515,
    longitude: -10.7903204,
  },
  {
    country_code: 'LS',
    country_name: 'Lesotho',
    tz_identifier: 'Africa/Maseru',
    tz_offset: '+02:00',
    tz_abbr: 'SAST',
    latitude: -29.3368097,
    longitude: 27.4757862,
  },
  {
    country_code: 'LT',
    country_name: 'Lithuania',
    tz_identifier: 'Europe/Vilnius',
    tz_offset: '+02:00',
    tz_abbr: 'EET',
    summer_time: {
      tz_abbr: 'EEST',
      tz_offset: '+03:00',
    },
    latitude: 54.8208805,
    longitude: 24.9124017,
  },
  {
    country_code: 'LU',
    country_name: 'Luxembourg',
    tz_identifier: 'Europe/Luxembourg',
    tz_offset: '+01:00',
    tz_abbr: 'CET',
    summer_time: {
      tz_abbr: 'CEST',
      tz_offset: '+02:00',
    },
    latitude: 49.8148302,
    longitude: 5.8030212,
  },
  {
    country_code: 'LV',
    country_name: 'Latvia',
    tz_identifier: 'Europe/Riga',
    tz_offset: '+02:00',
    tz_abbr: 'EET',
    summer_time: {
      tz_abbr: 'EEST',
      tz_offset: '+03:00',
    },
    latitude: 56.9717303,
    longitude: 24.0465031,
  },
  {
    country_code: 'LY',
    country_name: 'Libya',
    tz_identifier: 'Africa/Tripoli',
    tz_offset: '+02:00',
    tz_abbr: 'EET',
    latitude: 32.8829352,
    longitude: 13.1676934,
  },
  {
    country_code: 'MA',
    country_name: 'Morocco',
    tz_identifier: 'Africa/Casablanca',
    tz_offset: '+01:00',
    tz_abbr: '+01',
    summer_time: {
      tz_abbr: '+00',
      tz_offset: '+00:00',
    },
    latitude: 33.5722601,
    longitude: -7.6695635,
  },
  {
    country_code: 'MA',
    country_name: 'Morocco',
    tz_identifier: 'Africa/El_Aaiun',
    tz_offset: '+01:00',
    tz_abbr: '+01',
    summer_time: {
      tz_abbr: '+00',
      tz_offset: '+00:00',
    },
    latitude: 35.170986,
    longitude: -5.2916228,
  },
  {
    country_code: 'MC',
    country_name: 'Monaco',
    tz_identifier: 'Europe/Monaco',
    tz_offset: '+01:00',
    tz_abbr: 'CET',
    summer_time: {
      tz_abbr: 'CEST',
      tz_offset: '+02:00',
    },
    latitude: 43.7378509,
    longitude: 7.4155313,
  },
  {
    country_code: 'MD',
    country_name: 'Moldova, Republic of',
    tz_identifier: 'Europe/Chisinau',
    tz_offset: '+02:00',
    tz_abbr: 'EET',
    summer_time: {
      tz_abbr: 'EEST',
      tz_offset: '+03:00',
    },
    latitude: 46.9998394,
    longitude: 28.7756059,
  },
  {
    country_code: 'ME',
    country_name: 'Montenegro',
    tz_identifier: 'Europe/Podgorica',
    tz_offset: '+01:00',
    tz_abbr: 'CET',
    summer_time: {
      tz_abbr: 'CEST',
      tz_offset: '+02:00',
    },
    latitude: 42.4319933,
    longitude: 19.2145939,
  },
  {
    country_code: 'MF',
    country_name: 'Saint Martin (French part)',
    tz_identifier: 'America/Marigot',
    tz_offset: '-04:00',
    tz_abbr: 'AST',
    latitude: 18.0691255,
    longitude: -63.0880344,
  },
  {
    country_code: 'MG',
    country_name: 'Madagascar',
    tz_identifier: 'Indian/Antananarivo',
    tz_offset: '+03:00',
    tz_abbr: 'EAT',
    latitude: -18.8876603,
    longitude: 47.4299426,
  },
  {
    country_code: 'MH',
    country_name: 'Marshall Islands',
    tz_identifier: 'Pacific/Kwajalein',
    tz_offset: '+12:00',
    tz_abbr: '+12',
    latitude: 8.7306191,
    longitude: 167.7177679,
  },
  {
    country_code: 'MH',
    country_name: 'Marshall Islands',
    tz_identifier: 'Pacific/Majuro',
    tz_offset: '+12:00',
    tz_abbr: '+12',
    latitude: 7.0666667,
    longitude: 171.2563458,
  },
  {
    country_code: 'MK',
    country_name: 'Macedonia, the Former Yugoslav Republic of',
    tz_identifier: 'Europe/Skopje',
    tz_offset: '+01:00',
    tz_abbr: 'CET',
    summer_time: {
      tz_abbr: 'CEST',
      tz_offset: '+02:00',
    },
    latitude: 41.9990607,
    longitude: 21.3423194,
  },
  {
    country_code: 'ML',
    country_name: 'Mali',
    tz_identifier: 'Africa/Bamako',
    tz_offset: '+00:00',
    tz_abbr: 'GMT',
    latitude: 12.6125785,
    longitude: -8.0780844,
  },
  {
    country_code: 'MM',
    country_name: 'Myanmar',
    tz_identifier: 'Asia/Yangon',
    tz_offset: '+06:30',
    tz_abbr: '+0630',
    latitude: 16.8390765,
    longitude: 96.0164253,
  },
  {
    country_code: 'MN',
    country_name: 'Mongolia',
    tz_identifier: 'Asia/Choibalsan',
    tz_offset: '+08:00',
    tz_abbr: '+08',
    latitude: 48.0841359,
    longitude: 114.4917009,
  },
  {
    country_code: 'MN',
    country_name: 'Mongolia',
    tz_identifier: 'Asia/Hovd',
    tz_offset: '+07:00',
    tz_abbr: '+07',
    latitude: 47.97873,
    longitude: 91.6199254,
  },
  {
    country_code: 'MN',
    country_name: 'Mongolia',
    tz_identifier: 'Asia/Ulaanbaatar',
    tz_offset: '+08:00',
    tz_abbr: '+08',
    latitude: 47.8916205,
    longitude: 106.8193007,
  },
  {
    country_code: 'MO',
    country_name: 'Macao',
    tz_identifier: 'Asia/Macau',
    tz_offset: '+08:00',
    tz_abbr: 'CST',
    latitude: 22.1997215,
    longitude: 113.5360677,
  },
  {
    country_code: 'MP',
    country_name: 'Northern Mariana Islands',
    tz_identifier: 'ChST',
    tz_offset: '+10:00',
    tz_abbr: 'ChST',
    latitude: 17.3106037,
    longitude: 144.164696,
  },
  {
    country_code: 'MQ',
    country_name: 'Martinique',
    tz_identifier: 'America/Martinique',
    tz_offset: '-04:00',
    tz_abbr: 'AST',
    latitude: 14.6340314,
    longitude: -61.1789221,
  },
  {
    country_code: 'MR',
    country_name: 'Mauritania',
    tz_identifier: 'Africa/Nouakchott',
    tz_offset: '+00:00',
    tz_abbr: 'GMT',
    latitude: 18.0671662,
    longitude: -15.994846,
  },
  {
    country_code: 'MS',
    country_name: 'Montserrat',
    tz_identifier: 'America/Montserrat',
    tz_offset: '-04:00',
    tz_abbr: 'AST',
    latitude: 16.748533,
    longitude: -62.2338872,
  },
  {
    country_code: 'MT',
    country_name: 'Malta',
    tz_identifier: 'Europe/Malta',
    tz_offset: '+01:00',
    tz_abbr: 'CET',
    summer_time: {
      tz_abbr: 'CEST',
      tz_offset: '+02:00',
    },
    latitude: 35.9424217,
    longitude: 14.295779,
  },
  {
    country_code: 'MU',
    country_name: 'Mauritius',
    tz_identifier: 'Indian/Mauritius',
    tz_offset: '+04:00',
    tz_abbr: '+04',
    latitude: -20.2017083,
    longitude: 56.3537662,
  },
  {
    country_code: 'MV',
    country_name: 'Maldives',
    tz_identifier: 'Indian/Maldives',
    tz_offset: '+05:00',
    tz_abbr: '+05',
    latitude: 3.108788,
    longitude: 70.594858,
  },
  {
    country_code: 'MW',
    country_name: 'Malawi',
    tz_identifier: 'Africa/Blantyre',
    tz_offset: '+02:00',
    tz_abbr: 'CAT',
    latitude: -15.7762046,
    longitude: 34.9894804,
  },
  {
    country_code: 'MX',
    country_name: 'Mexico',
    tz_identifier: 'America/Bahia_Banderas',
    tz_offset: '-06:00',
    tz_abbr: 'CST',
    latitude: 20.829089,
    longitude: -105.4135308,
  },
  {
    country_code: 'MX',
    country_name: 'Mexico',
    tz_identifier: 'America/Cancun',
    tz_offset: '-05:00',
    tz_abbr: 'EST',
    latitude: 21.1213379,
    longitude: -86.8905184,
  },
  {
    country_code: 'MX',
    country_name: 'Mexico',
    tz_identifier: 'America/Chihuahua',
    tz_offset: '-06:00',
    tz_abbr: 'CST',
    latitude: 31.6538678,
    longitude: -106.5315066,
  },
  {
    country_code: 'MX',
    country_name: 'Mexico',
    tz_identifier: 'America/Ciudad_Juarez',
    tz_offset: '-07:00',
    tz_abbr: 'MST',
    summer_time: {
      tz_abbr: 'MDT',
      tz_offset: '-06:00',
    },
    latitude: 31.6538678,
    longitude: -106.5315066,
  },
  {
    country_code: 'MX',
    country_name: 'Mexico',
    tz_identifier: 'America/Hermosillo',
    tz_offset: '-07:00',
    tz_abbr: 'MST',
    latitude: 29.0821488,
    longitude: -111.0302718,
  },
  {
    country_code: 'MX',
    country_name: 'Mexico',
    tz_identifier: 'America/Matamoros',
    tz_offset: '-06:00',
    tz_abbr: 'CST',
    summer_time: {
      tz_abbr: 'CDT',
      tz_offset: '-05:00',
    },
    latitude: 25.869028,
    longitude: -97.502739,
  },
  {
    country_code: 'MX',
    country_name: 'Mexico',
    tz_identifier: 'America/Mazatlan',
    tz_offset: '-07:00',
    tz_abbr: 'MST',
    latitude: 23.249414,
    longitude: -106.41114,
  },
  {
    country_code: 'MX',
    country_name: 'Mexico',
    tz_identifier: 'America/Merida',
    tz_offset: '-06:00',
    tz_abbr: 'CST',
    latitude: 20.9800606,
    longitude: -89.6742034,
  },
  {
    country_code: 'MX',
    country_name: 'Mexico',
    tz_identifier: 'America/Mexico_City',
    tz_offset: '-06:00',
    tz_abbr: 'CST',
    latitude: 19.390715,
    longitude: -99.226184,
  },
  {
    country_code: 'MX',
    country_name: 'Mexico',
    tz_identifier: 'America/Monterrey',
    tz_offset: '-06:00',
    tz_abbr: 'CST',
    latitude: 25.6487717,
    longitude: -100.3856674,
  },
  {
    country_code: 'MX',
    country_name: 'Mexico',
    tz_identifier: 'America/Ojinaga',
    tz_offset: '-06:00',
    tz_abbr: 'CST',
    summer_time: {
      tz_abbr: 'CDT',
      tz_offset: '-05:00',
    },
    latitude: 29.56689,
    longitude: -104.54487,
  },
  {
    country_code: 'MX',
    country_name: 'Mexico',
    tz_identifier: 'America/Tijuana',
    tz_offset: '-08:00',
    tz_abbr: 'PST',
    summer_time: {
      tz_abbr: 'PDT',
      tz_offset: '-07:00',
    },
    latitude: 32.5027,
    longitude: -117.00371,
  },
  {
    country_code: 'MY',
    country_name: 'Malaysia',
    tz_identifier: 'Asia/Kuala_Lumpur',
    tz_offset: '+08:00',
    tz_abbr: '+08',
    latitude: 3.1385051,
    longitude: 101.6457044,
  },
  {
    country_code: 'MY',
    country_name: 'Malaysia',
    tz_identifier: 'Asia/Kuching',
    tz_offset: '+08:00',
    tz_abbr: '+08',
    latitude: 1.6181792,
    longitude: 110.2434969,
  },
  {
    country_code: 'MZ',
    country_name: 'Mozambique',
    tz_identifier: 'Africa/Maputo',
    tz_offset: '+02:00',
    tz_abbr: 'CAT',
    latitude: -25.8962528,
    longitude: 32.5693985,
  },
  {
    country_code: 'NA',
    country_name: 'Namibia',
    tz_identifier: 'Africa/Windhoek',
    tz_offset: '+02:00',
    tz_abbr: 'CAT',
    latitude: -22.563751,
    longitude: 17.0209409,
  },
  {
    country_code: 'NC',
    country_name: 'New Caledonia',
    tz_identifier: 'Pacific/Noumea',
    tz_offset: '+11:00',
    tz_abbr: '+11',
    latitude: -22.2643521,
    longitude: 166.4035817,
  },
  {
    country_code: 'NE',
    country_name: 'Niger',
    tz_identifier: 'Africa/Niamey',
    tz_offset: '+01:00',
    tz_abbr: 'WAT',
    latitude: 13.5127655,
    longitude: 2.0777475,
  },
  {
    country_code: 'NF',
    country_name: 'Norfolk Island',
    tz_identifier: 'Pacific/Norfolk',
    tz_offset: '+11:00',
    tz_abbr: '+11',
    summer_time: {
      tz_abbr: '+12',
      tz_offset: '+12:00',
    },
    latitude: -29.0651974,
    longitude: 167.9146952,
  },
  {
    country_code: 'NG',
    country_name: 'Nigeria',
    tz_identifier: 'Africa/Lagos',
    tz_offset: '+01:00',
    tz_abbr: 'WAT',
    latitude: 6.5480484,
    longitude: 3.2013881,
  },
  {
    country_code: 'NI',
    country_name: 'Nicaragua',
    tz_identifier: 'America/Managua',
    tz_offset: '-06:00',
    tz_abbr: 'CST',
    latitude: 12.0976468,
    longitude: -86.341032,
  },
  {
    country_code: 'NL',
    country_name: 'Netherlands',
    tz_identifier: 'Europe/Amsterdam',
    tz_offset: '+01:00',
    tz_abbr: 'CET',
    summer_time: {
      tz_abbr: 'CEST',
      tz_offset: '+02:00',
    },
    latitude: 52.3545399,
    longitude: 4.835819,
  },
  {
    country_code: 'NO',
    country_name: 'Norway',
    tz_identifier: 'Europe/Oslo',
    tz_offset: '+02:00',
    tz_abbr: 'CET',
    summer_time: {
      tz_abbr: 'CEST',
      tz_offset: '+02:00',
    },
    latitude: 59.8938291,
    longitude: 10.7025463,
  },
  {
    country_code: 'NP',
    country_name: 'Nepal',
    tz_identifier: 'Asia/Kathmandu',
    tz_offset: '+05:45',
    tz_abbr: '+0545',
    latitude: 27.7089588,
    longitude: 85.3054904,
  },
  {
    country_code: 'NR',
    country_name: 'Nauru',
    tz_identifier: 'Pacific/Nauru',
    tz_offset: '+12:00',
    tz_abbr: '+12',
    latitude: -0.5279799,
    longitude: 166.9145106,
  },
  {
    country_code: 'NU',
    country_name: 'Niue',
    tz_identifier: 'Pacific/Niue',
    tz_offset: '-11:00',
    tz_abbr: '-11',
    latitude: -19.0540913,
    longitude: -169.9034898,
  },
  {
    country_code: 'NZ',
    country_name: 'New Zealand',
    tz_identifier: 'Pacific/Auckland',
    tz_offset: '+12:00',
    tz_abbr: 'NZST',
    summer_time: {
      tz_abbr: 'NZDT',
      tz_offset: '+13:00',
    },
    latitude: -36.8596966,
    longitude: 174.5409755,
  },
  {
    country_code: 'NZ',
    country_name: 'New Zealand',
    tz_identifier: 'Pacific/Chatham',
    tz_offset: '+12:45',
    tz_abbr: '+1245',
    summer_time: {
      tz_abbr: '+1345',
      tz_offset: '+13:45',
    },
    latitude: -44.0038062,
    longitude: -176.7140225,
  },
  {
    country_code: 'OM',
    country_name: 'Oman',
    tz_identifier: 'Asia/Muscat',
    tz_offset: '+04:00',
    tz_abbr: '+04',
    latitude: 23.5828029,
    longitude: 58.341945,
  },
  {
    country_code: 'PA',
    country_name: 'Panama',
    tz_identifier: 'America/Panama',
    tz_offset: '-05:00',
    tz_abbr: 'EST',
    latitude: 8.3755024,
    longitude: -81.4273329,
  },
  {
    country_code: 'PE',
    country_name: 'Peru',
    tz_identifier: 'America/Lima',
    tz_offset: '-05:00',
    tz_abbr: '-05',
    latitude: -12.0266262,
    longitude: -77.0703505,
  },
  {
    country_code: 'PF',
    country_name: 'French Polynesia',
    tz_identifier: 'Pacific/Gambier',
    tz_offset: '-09:00',
    tz_abbr: '-09',
    latitude: -23.1045529,
    longitude: -134.9920007,
  },
  {
    country_code: 'PF',
    country_name: 'French Polynesia',
    tz_identifier: 'Pacific/Marquesas',
    tz_offset: '-09:30',
    tz_abbr: '-0930',
    latitude: -23.1045529,
    longitude: -134.9920007,
  },
  {
    country_code: 'PF',
    country_name: 'French Polynesia',
    tz_identifier: 'Pacific/Tahiti',
    tz_offset: '-10:00',
    tz_abbr: '-10',
    latitude: -17.6872008,
    longitude: -149.5379516,
  },
  {
    country_code: 'PG',
    country_name: 'Papua New Guinea',
    tz_identifier: 'Pacific/Bougainville',
    tz_offset: '+11:00',
    tz_abbr: '+11',
    latitude: -6.1438943,
    longitude: 154.9890384,
  },
  {
    country_code: 'PG',
    country_name: 'Papua New Guinea',
    tz_identifier: 'Pacific/Port_Moresby',
    tz_offset: '+10:00',
    tz_abbr: '+10',
    latitude: -9.4375201,
    longitude: 147.1489743,
  },
  {
    country_code: 'PH',
    country_name: 'Philippines',
    tz_identifier: 'Asia/Manila',
    tz_offset: '+08:00',
    tz_abbr: 'PHT',
    latitude: 14.5677816,
    longitude: 120.9380721,
  },
  {
    country_code: 'PK',
    country_name: 'Pakistan',
    tz_identifier: 'Asia/Karachi',
    tz_offset: '+05:00',
    tz_abbr: 'PKT',
    latitude: 25.1928357,
    longitude: 66.8251514,
  },
  {
    country_code: 'PL',
    country_name: 'Poland',
    tz_identifier: 'Europe/Warsaw',
    tz_offset: '+01:00',
    tz_abbr: 'CET',
    summer_time: {
      tz_abbr: 'CEST',
      tz_offset: '+02:00',
    },
    latitude: 52.2329091,
    longitude: 20.9786236,
  },
  {
    country_code: 'PM',
    country_name: 'Saint Pierre and Miquelon',
    tz_identifier: 'America/Miquelon',
    tz_offset: '-03:00',
    tz_abbr: '-03',
    summer_time: {
      tz_abbr: '-02',
      tz_offset: '-02:00',
    },
    latitude: 47.0620708,
    longitude: -56.3668925,
  },
  {
    country_code: 'PN',
    country_name: 'Pitcairn',
    tz_identifier: 'Pacific/Pitcairn',
    tz_offset: '-08:00',
    tz_abbr: '-08',
    latitude: -24.4029578,
    longitude: -132.7337628,
  },
  {
    country_code: 'PR',
    country_name: 'Puerto Rico',
    tz_identifier: 'America/Puerto_Rico',
    tz_offset: '-04:00',
    tz_abbr: 'AST',
    latitude: 18.2073528,
    longitude: -67.246581,
  },
  {
    country_code: 'PS',
    country_name: 'Palestine, State of',
    tz_identifier: 'Asia/Gaza',
    tz_offset: '+02:00',
    tz_abbr: 'EET',
    summer_time: {
      tz_abbr: 'EEST',
      tz_offset: '+03:00',
    },
    latitude: 31.4102194,
    longitude: 34.3061217,
  },
  {
    country_code: 'PS',
    country_name: 'Palestine, State of',
    tz_identifier: 'Asia/Hebron',
    tz_offset: '+02:00',
    tz_abbr: 'EET',
    summer_time: {
      tz_abbr: 'EEST',
      tz_offset: '+03:00',
    },
    latitude: 31.5325681,
    longitude: 35.0895048,
  },
  {
    country_code: 'PT',
    country_name: 'Portugal',
    tz_identifier: 'Atlantic/Azores',
    tz_offset: '+00:00',
    tz_abbr: 'GMT',
    latitude: 38.3194919,
    longitude: -29.4625454,
  },
  {
    country_code: 'PT',
    country_name: 'Portugal',
    tz_identifier: 'Atlantic/Madeira',
    tz_offset: '+00:00',
    tz_abbr: 'WET',
    summer_time: {
      tz_abbr: 'WEST',
      tz_offset: '+01:00',
    },
    latitude: 31.5767718,
    longitude: -17.2204853,
  },
  {
    country_code: 'PT',
    country_name: 'Portugal',
    tz_identifier: 'Europe/Lisbon',
    tz_offset: '+00:00',
    tz_abbr: 'WET',
    summer_time: {
      tz_abbr: 'WEST',
      tz_offset: '+01:00',
    },
    latitude: 38.7436193,
    longitude: -9.2014883,
  },
  {
    country_code: 'PW',
    country_name: 'Palau',
    tz_identifier: 'Pacific/Palau',
    tz_offset: '+09:00',
    tz_abbr: '+09',
    latitude: 5.4375984,
    longitude: 131.7223938,
  },
  {
    country_code: 'PY',
    country_name: 'Paraguay',
    tz_identifier: 'America/Asuncion',
    tz_offset: '-04:00',
    tz_abbr: '-04',
    summer_time: {
      tz_abbr: '-03',
      tz_offset: '-03:00',
    },
    latitude: -25.2968469,
    longitude: -57.6393742,
  },
  {
    country_code: 'QA',
    country_name: 'Qatar',
    tz_identifier: 'Asia/Qatar',
    tz_offset: '+03:00',
    tz_abbr: '+03',
    latitude: 25.3437407,
    longitude: 50.8874396,
  },
  {
    country_code: 'RE',
    country_name: 'Réunion',
    tz_identifier: 'Indian/Reunion',
    tz_offset: '+04:00',
    tz_abbr: '+04',
    latitude: -21.1352625,
    longitude: 55.3621586,
  },
  {
    country_code: 'RO',
    country_name: 'Romania',
    tz_identifier: 'Europe/Bucharest',
    tz_offset: '+02:00',
    tz_abbr: 'EET',
    summer_time: {
      tz_abbr: 'EEST',
      tz_offset: '+03:00',
    },
    latitude: 44.437796,
    longitude: 26.0120668,
  },
  {
    country_code: 'RS',
    country_name: 'Serbia',
    tz_identifier: 'Europe/Belgrade',
    tz_offset: '+01:00',
    tz_abbr: 'CET',
    summer_time: {
      tz_abbr: 'CEST',
      tz_offset: '+02:00',
    },
    latitude: 44.8152156,
    longitude: 20.3400263,
  },
  {
    country_code: 'RU',
    country_name: 'Russian Federation',
    tz_identifier: 'Asia/Anadyr',
    tz_offset: '+12:00',
    tz_abbr: '+12',
    latitude: 64.7279379,
    longitude: 177.4798347,
  },
  {
    country_code: 'RU',
    country_name: 'Russian Federation',
    tz_identifier: 'Asia/Barnaul',
    tz_offset: '+07:00',
    tz_abbr: '+07',
    latitude: 53.3342925,
    longitude: 83.5507964,
  },
  {
    country_code: 'RU',
    country_name: 'Russian Federation',
    tz_identifier: 'Asia/Chita',
    tz_offset: '+09:00',
    tz_abbr: '+09',
    latitude: 52.047348,
    longitude: 113.3928236,
  },
  {
    country_code: 'RU',
    country_name: 'Russian Federation',
    tz_identifier: 'Asia/Irkutsk',
    tz_offset: '+08:00',
    tz_abbr: '+08',
    latitude: 52.2983067,
    longitude: 104.1845875,
  },
  {
    country_code: 'RU',
    country_name: 'Russian Federation',
    tz_identifier: 'Asia/Kamchatka',
    tz_offset: '+12:00',
    tz_abbr: '+12',
    latitude: 57.7915181,
    longitude: 159.7515527,
  },
  {
    country_code: 'RU',
    country_name: 'Russian Federation',
    tz_identifier: 'Asia/Khandyga',
    tz_offset: '+09:00',
    tz_abbr: '+09',
    latitude: 62.6529119,
    longitude: 135.5354327,
  },
  {
    country_code: 'RU',
    country_name: 'Russian Federation',
    tz_identifier: 'Asia/Krasnoyarsk',
    tz_offset: '+07:00',
    tz_abbr: '+07',
    latitude: 56.0266192,
    longitude: 92.7005908,
  },
  {
    country_code: 'RU',
    country_name: 'Russian Federation',
    tz_identifier: 'Asia/Magadan',
    tz_offset: '+11:00',
    tz_abbr: '+11',
    latitude: 59.5644185,
    longitude: 150.7998543,
  },
  {
    country_code: 'RU',
    country_name: 'Russian Federation',
    tz_identifier: 'Asia/Novokuznetsk',
    tz_offset: '+07:00',
    tz_abbr: '+07',
    latitude: 53.7966291,
    longitude: 87.0067655,
  },
  {
    country_code: 'RU',
    country_name: 'Russian Federation',
    tz_identifier: 'Asia/Novosibirsk',
    tz_offset: '+07:00',
    tz_abbr: '+07',
    latitude: 54.9698651,
    longitude: 82.7842617,
  },
  {
    country_code: 'RU',
    country_name: 'Russian Federation',
    tz_identifier: 'Asia/Omsk',
    tz_offset: '+06:00',
    tz_abbr: '+06',
    latitude: 54.9857641,
    longitude: 73.1909957,
  },
  {
    country_code: 'RU',
    country_name: 'Russian Federation',
    tz_identifier: 'Asia/Sakhalin',
    tz_offset: '+11:00',
    tz_abbr: '+11',
    latitude: 50.129204,
    longitude: 140.5440963,
  },
  {
    country_code: 'RU',
    country_name: 'Russian Federation',
    tz_identifier: 'Asia/Srednekolymsk',
    tz_offset: '+11:00',
    tz_abbr: '+11',
    latitude: 67.4373063,
    longitude: 153.7183528,
  },
  {
    country_code: 'RU',
    country_name: 'Russian Federation',
    tz_identifier: 'Asia/Tomsk',
    tz_offset: '+07:00',
    tz_abbr: '+07',
    latitude: 56.4895056,
    longitude: 84.9260606,
  },
  {
    country_code: 'RU',
    country_name: 'Russian Federation',
    tz_identifier: 'Asia/Ust-Nera',
    tz_offset: '+10:00',
    tz_abbr: '+10',
    latitude: 64.5609607,
    longitude: 143.1727826,
  },
  {
    country_code: 'RU',
    country_name: 'Russian Federation',
    tz_identifier: 'Asia/Vladivostok',
    tz_offset: '+10:00',
    tz_abbr: '+10',
    latitude: 43.1665573,
    longitude: 131.8708872,
  },
  {
    country_code: 'RU',
    country_name: 'Russian Federation',
    tz_identifier: 'Asia/Yakutsk',
    tz_offset: '+09:00',
    tz_abbr: '+09',
    latitude: 62.0313089,
    longitude: 129.6192157,
  },
  {
    country_code: 'RU',
    country_name: 'Russian Federation',
    tz_identifier: 'Asia/Yekaterinburg',
    tz_offset: '+05:00',
    tz_abbr: '+05',
    latitude: 56.811773,
    longitude: 160.4522376,
  },
  {
    country_code: 'RU',
    country_name: 'Russian Federation',
    tz_identifier: 'Europe/Astrakhan',
    tz_offset: '+04:00',
    tz_abbr: '+04',
    latitude: 46.3654013,
    longitude: 47.9492163,
  },
  {
    country_code: 'RU',
    country_name: 'Russian Federation',
    tz_identifier: 'Europe/Kaliningrad',
    tz_offset: '+02:00',
    tz_abbr: 'EET',
    latitude: 54.7115814,
    longitude: 20.3819596,
  },
  {
    country_code: 'RU',
    country_name: 'Russian Federation',
    tz_identifier: 'Europe/Kirov',
    tz_offset: '+03:00',
    tz_abbr: 'MSK',
    latitude: 58.5852591,
    longitude: 49.4069327,
  },
  {
    country_code: 'RU',
    country_name: 'Russian Federation',
    tz_identifier: 'Europe/Moscow',
    tz_offset: '+03:00',
    tz_abbr: 'MSK',
    summer_time: {
      tz_abbr: 'CEST',
      tz_offset: '+02:00',
    },
    latitude: 55.581582,
    longitude: 37.0552281,
  },
  {
    country_code: 'RU',
    country_name: 'Russian Federation',
    tz_identifier: 'Europe/Samara',
    tz_offset: '+04:00',
    tz_abbr: '+04',
    latitude: 53.2607939,
    longitude: 50.0329335,
  },
  {
    country_code: 'RU',
    country_name: 'Russian Federation',
    tz_identifier: 'Europe/Saratov',
    tz_offset: '+04:00',
    tz_abbr: '+04',
    latitude: 51.5315505,
    longitude: 45.9143329,
  },
  {
    country_code: 'RU',
    country_name: 'Russian Federation',
    tz_identifier: 'Europe/Ulyanovsk',
    tz_offset: '+04:00',
    tz_abbr: '+04',
    latitude: 54.2796737,
    longitude: 48.2201771,
  },
  {
    country_code: 'RU',
    country_name: 'Russian Federation',
    tz_identifier: 'Europe/Volgograd',
    tz_offset: '+03:00',
    tz_abbr: 'MSK',
    latitude: 48.6703014,
    longitude: 44.3415633,
  },
  {
    country_code: 'RW',
    country_name: 'Rwanda',
    tz_identifier: 'Africa/Kigali',
    tz_offset: '+02:00',
    tz_abbr: 'CAT',
    latitude: -1.9297686,
    longitude: 30.044673,
  },
  {
    country_code: 'SA',
    country_name: 'Saudi Arabia',
    tz_identifier: 'Asia/Riyadh',
    tz_offset: '+03:00',
    tz_abbr: '+03',
    latitude: 24.7248301,
    longitude: 46.492218,
  },
  {
    country_code: 'SB',
    country_name: 'Solomon Islands',
    tz_identifier: 'Pacific/Guadalcanal',
    tz_offset: '+11:00',
    tz_abbr: '+11',
    latitude: -9.5982538,
    longitude: 159.8848781,
  },
  {
    country_code: 'SC',
    country_name: 'Seychelles',
    tz_identifier: 'Indian/Mahe',
    tz_offset: '+04:00',
    tz_abbr: '+04',
    latitude: -7.162844,
    longitude: 46.2353967,
  },
  {
    country_code: 'SD',
    country_name: 'Sudan',
    tz_identifier: 'Africa/Khartoum',
    tz_offset: '+02:00',
    tz_abbr: 'CAT',
    latitude: 15.5015298,
    longitude: 32.4900251,
  },
  {
    country_code: 'SE',
    country_name: 'Sweden',
    tz_identifier: 'Europe/Stockholm',
    tz_offset: '+01:00',
    tz_abbr: 'CET',
    summer_time: {
      tz_abbr: 'CEST',
      tz_offset: '+02:00',
    },
    latitude: 59.3261158,
    longitude: 17.8994822,
  },
  {
    country_code: 'SG',
    country_name: 'Singapore',
    tz_identifier: 'Asia/Singapore',
    tz_offset: '+08:00',
    tz_abbr: '+08',
    latitude: 1.3139946,
    longitude: 103.6791024,
  },
  {
    country_code: 'SH',
    country_name: 'Saint Helena, Ascension and Tristan da Cunha',
    tz_identifier: 'Atlantic/St_Helena',
    tz_offset: '+00:00',
    tz_abbr: 'GMT',
    latitude: -23.7015376,
    longitude: -20.8958093,
  },
  {
    country_code: 'SI',
    country_name: 'Slovenia',
    tz_identifier: 'Europe/Ljubljana',
    tz_offset: '+01:00',
    tz_abbr: 'CET',
    summer_time: {
      tz_abbr: 'CEST',
      tz_offset: '+02:00',
    },
    latitude: 46.0660876,
    longitude: 14.4495284,
  },
  {
    country_code: 'SJ',
    country_name: 'Svalbard and Jan Mayen',
    tz_identifier: 'Arctic/Longyearbyen',
    tz_offset: '+01:00',
    tz_abbr: 'CET',
    summer_time: {
      tz_abbr: 'CEST',
      tz_offset: '+02:00',
    },
    latitude: 78.2253554,
    longitude: 15.4753588,
  },
  {
    country_code: 'SK',
    country_name: 'Slovakia',
    tz_identifier: 'Europe/Bratislava',
    tz_offset: '+01:00',
    tz_abbr: 'CET',
    summer_time: {
      tz_abbr: 'CEST',
      tz_offset: '+02:00',
    },
    latitude: 48.1357508,
    longitude: 17.0333464,
  },
  {
    country_code: 'SL',
    country_name: 'Sierra Leone',
    tz_identifier: 'Africa/Freetown',
    tz_offset: '+00:00',
    tz_abbr: 'GMT',
    latitude: 8.4553563,
    longitude: -13.2655674,
  },
  {
    country_code: 'SM',
    country_name: 'San Marino',
    tz_identifier: 'Europe/San_Marino',
    tz_offset: '+01:00',
    tz_abbr: 'CET',
    summer_time: {
      tz_abbr: 'CEST',
      tz_offset: '+02:00',
    },
    latitude: 43.9428705,
    longitude: 12.4188083,
  },
  {
    country_code: 'SN',
    country_name: 'Senegal',
    tz_identifier: 'Africa/Dakar',
    tz_offset: '+00:00',
    tz_abbr: 'GMT',
    latitude: 14.7110208,
    longitude: -17.5071101,
  },
  {
    country_code: 'SO',
    country_name: 'Somalia',
    tz_identifier: 'Africa/Mogadishu',
    tz_offset: '+03:00',
    tz_abbr: 'EAT',
    latitude: 2.0592003,
    longitude: 45.2653798,
  },
  {
    country_code: 'SR',
    country_name: 'Suriname',
    tz_identifier: 'America/Paramaribo',
    tz_offset: '-03:00',
    tz_abbr: '-03',
    latitude: 5.8483233,
    longitude: -55.2191319,
  },
  {
    country_code: 'SS',
    country_name: 'South Sudan',
    tz_identifier: 'Africa/Juba',
    tz_offset: '+02:00',
    tz_abbr: 'CAT',
    latitude: 4.8606901,
    longitude: 31.5359041,
  },
  {
    country_code: 'ST',
    country_name: 'Sao Tome and Principe',
    tz_identifier: 'Africa/Sao_Tome',
    tz_offset: '+00:00',
    tz_abbr: 'GMT',
    latitude: 0.8985862,
    longitude: 6.3327697,
  },
  {
    country_code: 'SV',
    country_name: 'El Salvador',
    tz_identifier: 'America/El_Salvador',
    tz_offset: '-06:00',
    tz_abbr: 'CST',
    latitude: 13.7480989,
    longitude: -89.5909581,
  },
  {
    country_code: 'SX',
    country_name: 'Sint Maarten (Dutch part)',
    tz_identifier: 'America/Lower_Princes',
    tz_offset: '-04:00',
    tz_abbr: 'AST',
    latitude: 18.0323587,
    longitude: -63.1123319,
  },
  {
    country_code: 'SY',
    country_name: 'Syrian Arab Republic',
    tz_identifier: 'Asia/Damascus',
    tz_offset: '+03:00',
    tz_abbr: '+03',
    latitude: 33.5074687,
    longitude: 36.2416104,
  },
  {
    country_code: 'SZ',
    country_name: 'Swaziland',
    tz_identifier: 'Africa/Mbabane',
    tz_offset: '+02:00',
    tz_abbr: 'SAST',
    latitude: -26.3153594,
    longitude: 31.1165427,
  },
  {
    country_code: 'TC',
    country_name: 'Turks and Caicos Islands',
    tz_identifier: 'America/Grand_Turk',
    tz_offset: '-05:00',
    tz_abbr: 'EST',
    summer_time: {
      tz_abbr: 'EDT',
      tz_offset: '-04:00',
    },
    latitude: 21.4623538,
    longitude: -71.1522177,
  },
  {
    country_code: 'TD',
    country_name: 'Chad',
    tz_identifier: 'Africa/Ndjamena',
    tz_offset: '+01:00',
    tz_abbr: 'WAT',
    latitude: 12.1202152,
    longitude: 15.0161738,
  },
  {
    country_code: 'TF',
    country_name: 'French Southern Territories',
    tz_identifier: 'Indian/Kerguelen',
    tz_offset: '+05:00',
    tz_abbr: '+05',
    latitude: -49.1951319,
    longitude: 68.9828046,
  },
  {
    country_code: 'TG',
    country_name: 'Togo',
    tz_identifier: 'Africa/Lome',
    tz_offset: '+00:00',
    tz_abbr: 'GMT',
    latitude: 6.1823153,
    longitude: 1.1641193,
  },
  {
    country_code: 'TH',
    country_name: 'Thailand',
    tz_identifier: 'Asia/Bangkok',
    tz_offset: '+07:00',
    tz_abbr: '+07',
    latitude: 13.7245447,
    longitude: 100.4679634,
  },
  {
    country_code: 'TJ',
    country_name: 'Tajikistan',
    tz_identifier: 'Asia/Dushanbe',
    tz_offset: '+05:00',
    tz_abbr: '+05',
    latitude: 38.5612949,
    longitude: 68.69901,
  },
  {
    country_code: 'TK',
    country_name: 'Tokelau',
    tz_identifier: 'Pacific/Fakaofo',
    tz_offset: '+13:00',
    tz_abbr: '+13',
    latitude: -9.3838071,
    longitude: -171.2677365,
  },
  {
    country_code: 'TL',
    country_name: 'Timor-Leste',
    tz_identifier: 'Asia/Dili',
    tz_offset: '+09:00',
    tz_abbr: '+09',
    latitude: -8.5564142,
    longitude: 125.5286416,
  },
  {
    country_code: 'TM',
    country_name: 'Turkmenistan',
    tz_identifier: 'Asia/Ashgabat',
    tz_offset: '+05:00',
    tz_abbr: '+05',
    latitude: 37.9630921,
    longitude: 58.0851386,
  },
  {
    country_code: 'TN',
    country_name: 'Tunisia',
    tz_identifier: 'Africa/Tunis',
    tz_offset: '+01:00',
    tz_abbr: 'CET',
    latitude: 36.7948544,
    longitude: 10.0607066,
  },
  {
    country_code: 'TO',
    country_name: 'Tonga',
    tz_identifier: 'Pacific/Tongatapu',
    tz_offset: '+13:00',
    tz_abbr: '+13',
    latitude: -21.1699145,
    longitude: -175.2775132,
  },
  {
    country_code: 'TR',
    country_name: 'Turkey',
    tz_identifier: 'Europe/Istanbul',
    tz_offset: '+03:00',
    tz_abbr: '+03',
    latitude: 41.0052037,
    longitude: 28.8470343,
  },
  {
    country_code: 'TT',
    country_name: 'Trinidad and Tobago',
    tz_identifier: 'America/Port_of_Spain',
    tz_offset: '-04:00',
    tz_abbr: 'AST',
    latitude: 10.6685089,
    longitude: -61.5345467,
  },
  {
    country_code: 'TV',
    country_name: 'Tuvalu',
    tz_identifier: 'Pacific/Funafuti',
    tz_offset: '+12:00',
    tz_abbr: '+12',
    latitude: -8.5361659,
    longitude: 179.0702793,
  },
  {
    country_code: 'TW',
    country_name: 'Taiwan, Province of China',
    tz_identifier: 'Asia/Taipei',
    tz_offset: '+08:00',
    tz_abbr: 'CST',
    latitude: 25.0853833,
    longitude: 121.47893,
  },
  {
    country_code: 'TZ',
    country_name: 'Tanzania, United Republic of',
    tz_identifier: 'Africa/Dar_es_Salaam',
    tz_offset: '+03:00',
    tz_abbr: 'EAT',
    latitude: -6.7695633,
    longitude: 39.0891434,
  },
  {
    country_code: 'UA',
    country_name: 'Ukraine',
    tz_identifier: 'Europe/Kyiv',
    tz_offset: '+02:00',
    tz_abbr: 'EET',
    summer_time: {
      tz_abbr: 'EEST',
      tz_offset: '+03:00',
    },
    latitude: 50.4019186,
    longitude: 30.3675466,
  },
  {
    country_code: 'UA',
    country_name: 'Ukraine',
    tz_identifier: 'Europe/Simferopol',
    tz_offset: '+03:00',
    tz_abbr: 'MSK',
    latitude: 44.9628221,
    longitude: 34.0502885,
  },
  {
    country_code: 'UG',
    country_name: 'Uganda',
    tz_identifier: 'Africa/Kampala',
    tz_offset: '+03:00',
    tz_abbr: 'EAT',
    latitude: 0.3130293,
    longitude: 32.5578403,
  },
  {
    country_code: 'UM',
    country_name: 'United States Minor Outlying Islands',
    tz_identifier: 'Pacific/Midway',
    tz_offset: '-11:00',
    tz_abbr: 'SST',
    latitude: 28.2359138,
    longitude: -177.4119848,
  },
  {
    country_code: 'UM',
    country_name: 'United States Minor Outlying Islands',
    tz_identifier: 'Pacific/Wake',
    tz_offset: '+12:00',
    tz_abbr: '+12',
    latitude: 28.2359138,
    longitude: -177.4119848,
  },
  {
    country_code: 'US',
    country_name: 'United States',
    tz_identifier: 'America/Adak',
    tz_offset: '-10:00',
    tz_abbr: 'HST',
    summer_time: {
      tz_abbr: 'HDT',
      tz_offset: '-09:00',
    },
    latitude: 51.8791852,
    longitude: -176.6724875,
  },
  {
    country_code: 'US',
    country_name: 'United States',
    tz_identifier: 'America/Anchorage',
    tz_offset: '-09:00',
    tz_abbr: 'AKST',
    summer_time: {
      tz_abbr: 'AKDT',
      tz_offset: '-08:00',
    },
    latitude: 61.1072534,
    longitude: -150.1009159,
  },
  {
    country_code: 'US',
    country_name: 'United States',
    tz_identifier: 'America/Boise',
    tz_offset: '-07:00',
    tz_abbr: 'MST',
    summer_time: {
      tz_abbr: 'MDT',
      tz_offset: '-06:00',
    },
    latitude: 43.6007763,
    longitude: -116.3164688,
  },
  {
    country_code: 'US',
    country_name: 'United States',
    tz_identifier: 'America/Chicago',
    tz_offset: '-06:00',
    tz_abbr: 'CST',
    summer_time: {
      tz_abbr: 'CDT',
      tz_offset: '-05:00',
    },
    latitude: 41.8336147,
    longitude: -87.897109,
  },
  {
    country_code: 'US',
    country_name: 'United States',
    tz_identifier: 'America/Denver',
    tz_offset: '-07:00',
    tz_abbr: 'MST',
    summer_time: {
      tz_abbr: 'MDT',
      tz_offset: '-06:00',
    },
    latitude: 37.09024,
    longitude: -95.712891,
  },
  {
    country_code: 'US',
    country_name: 'United States',
    tz_identifier: 'America/Detroit',
    tz_offset: '-05:00',
    tz_abbr: 'EST',
    summer_time: {
      tz_abbr: 'EDT',
      tz_offset: '-04:00',
    },
    latitude: 37.09024,
    longitude: -95.712891,
  },
  {
    country_code: 'US',
    country_name: 'United States',
    tz_identifier: 'America/Indiana/Indianapolis',
    tz_offset: '-05:00',
    tz_abbr: 'EST',
    summer_time: {
      tz_abbr: 'EDT',
      tz_offset: '-04:00',
    },
    latitude: 39.7797552,
    longitude: -86.2153209,
  },
  {
    country_code: 'US',
    country_name: 'United States',
    tz_identifier: 'America/Indiana/Knox',
    tz_offset: '-06:00',
    tz_abbr: 'CST',
    summer_time: {
      tz_abbr: 'CDT',
      tz_offset: '-05:00',
    },
    latitude: 41.2912601,
    longitude: -86.6395047,
  },
  {
    country_code: 'US',
    country_name: 'United States',
    tz_identifier: 'America/Indiana/Marengo',
    tz_offset: '-05:00',
    tz_abbr: 'EST',
    summer_time: {
      tz_abbr: 'EDT',
      tz_offset: '-04:00',
    },
    latitude: 38.3704179,
    longitude: -86.3480095,
  },
  {
    country_code: 'US',
    country_name: 'United States',
    tz_identifier: 'America/Indiana/Petersburg',
    tz_offset: '-05:00',
    tz_abbr: 'EST',
    summer_time: {
      tz_abbr: 'EDT',
      tz_offset: '-04:00',
    },
    latitude: 38.492436,
    longitude: -87.2931142,
  },
  {
    country_code: 'US',
    country_name: 'United States',
    tz_identifier: 'America/Indiana/Tell_City',
    tz_offset: '-06:00',
    tz_abbr: 'CST',
    summer_time: {
      tz_abbr: 'CDT',
      tz_offset: '-05:00',
    },
    latitude: 38.492436,
    longitude: -87.2931142,
  },
  {
    country_code: 'US',
    country_name: 'United States',
    tz_identifier: 'America/Indiana/Vevay',
    tz_offset: '-05:00',
    tz_abbr: 'EST',
    summer_time: {
      tz_abbr: 'EDT',
      tz_offset: '-04:00',
    },
    latitude: 38.742325,
    longitude: -85.0866567,
  },
  {
    country_code: 'US',
    country_name: 'United States',
    tz_identifier: 'America/Indiana/Vincennes',
    tz_offset: '-05:00',
    tz_abbr: 'EST',
    summer_time: {
      tz_abbr: 'EDT',
      tz_offset: '-04:00',
    },
    latitude: 38.6855527,
    longitude: -87.5295943,
  },
  {
    country_code: 'US',
    country_name: 'United States',
    tz_identifier: 'America/Indiana/Winamac',
    tz_offset: '-05:00',
    tz_abbr: 'EST',
    summer_time: {
      tz_abbr: 'EDT',
      tz_offset: '-04:00',
    },
    latitude: 41.0552525,
    longitude: -86.6124961,
  },
  {
    country_code: 'US',
    country_name: 'United States',
    tz_identifier: 'America/Juneau',
    tz_offset: '-09:00',
    tz_abbr: 'AKST',
    summer_time: {
      tz_abbr: 'AKDT',
      tz_offset: '-08:00',
    },
    latitude: 58.3833233,
    longitude: -134.8583989,
  },
  {
    country_code: 'US',
    country_name: 'United States',
    tz_identifier: 'America/Kentucky/Louisville',
    tz_offset: '-05:00',
    tz_abbr: 'EST',
    summer_time: {
      tz_abbr: 'EDT',
      tz_offset: '-04:00',
    },
    latitude: 38.1886893,
    longitude: -85.8419159,
  },
  {
    country_code: 'US',
    country_name: 'United States',
    tz_identifier: 'America/Kentucky/Monticello',
    tz_offset: '-05:00',
    tz_abbr: 'EST',
    summer_time: {
      tz_abbr: 'EDT',
      tz_offset: '-04:00',
    },
    latitude: 36.8429467,
    longitude: -84.8741194,
  },
  {
    country_code: 'US',
    country_name: 'United States',
    tz_identifier: 'America/Los_Angeles',
    tz_offset: '-08:00',
    tz_abbr: 'PST',
    summer_time: {
      tz_abbr: 'PDT',
      tz_offset: '-07:00',
    },
    latitude: 34.0203686,
    longitude: -118.5768784,
  },
  {
    country_code: 'US',
    country_name: 'United States',
    tz_identifier: 'America/Menominee',
    tz_offset: '-06:00',
    tz_abbr: 'CST',
    summer_time: {
      tz_abbr: 'CDT',
      tz_offset: '-05:00',
    },
    latitude: 45.1246406,
    longitude: -87.6456249,
  },
  {
    country_code: 'US',
    country_name: 'United States',
    tz_identifier: 'America/Metlakatla',
    tz_offset: '-09:00',
    tz_abbr: 'AKST',
    summer_time: {
      tz_abbr: 'AKDT',
      tz_offset: '-08:00',
    },
    latitude: 55.1194827,
    longitude: -131.5861889,
  },
  {
    country_code: 'US',
    country_name: 'United States',
    tz_identifier: 'America/New_York',
    tz_offset: '-05:00',
    tz_abbr: 'EST',
    summer_time: {
      tz_abbr: 'EDT',
      tz_offset: '-04:00',
    },
    latitude: 40.6973704,
    longitude: -74.1448262,
  },
  {
    country_code: 'US',
    country_name: 'United States',
    tz_identifier: 'America/Nome',
    tz_offset: '-09:00',
    tz_abbr: 'AKST',
    summer_time: {
      tz_abbr: 'AKDT',
      tz_offset: '-08:00',
    },
    latitude: 40.6973704,
    longitude: -74.1448262,
  },
  {
    country_code: 'US',
    country_name: 'United States',
    tz_identifier: 'America/North_Dakota/Beulah',
    tz_offset: '-06:00',
    tz_abbr: 'CST',
    summer_time: {
      tz_abbr: 'CDT',
      tz_offset: '-05:00',
    },
    latitude: 47.2629726,
    longitude: -101.7863198,
  },
  {
    country_code: 'US',
    country_name: 'United States',
    tz_identifier: 'America/North_Dakota/Center',
    tz_offset: '-06:00',
    tz_abbr: 'CST',
    summer_time: {
      tz_abbr: 'CDT',
      tz_offset: '-05:00',
    },
    latitude: 47.1152318,
    longitude: -101.305854,
  },
  {
    country_code: 'US',
    country_name: 'United States',
    tz_identifier: 'America/North_Dakota/New_Salem',
    tz_offset: '-06:00',
    tz_abbr: 'CST',
    summer_time: {
      tz_abbr: 'CDT',
      tz_offset: '-05:00',
    },
    latitude: 46.8466235,
    longitude: -101.4257207,
  },
  {
    country_code: 'US',
    country_name: 'United States',
    tz_identifier: 'America/Phoenix',
    tz_offset: '-07:00',
    tz_abbr: 'MST',
    latitude: 33.6053052,
    longitude: -112.2901969,
  },
  {
    country_code: 'US',
    country_name: 'United States',
    tz_identifier: 'America/Sitka',
    tz_offset: '-09:00',
    tz_abbr: 'AKST',
    summer_time: {
      tz_abbr: 'AKDT',
      tz_offset: '-08:00',
    },
    latitude: 57.0714663,
    longitude: -136.1061266,
  },
  {
    country_code: 'US',
    country_name: 'United States',
    tz_identifier: 'America/Yakutat',
    tz_offset: '-09:00',
    tz_abbr: 'AKST',
    summer_time: {
      tz_abbr: 'AKDT',
      tz_offset: '-08:00',
    },
    latitude: 59.5748996,
    longitude: -139.7960122,
  },
  {
    country_code: 'US',
    country_name: 'United States',
    tz_identifier: 'Pacific/Honolulu',
    tz_offset: '-10:00',
    tz_abbr: 'HST',
    latitude: 21.3280136,
    longitude: -157.8816443,
  },
  {
    country_code: 'UY',
    country_name: 'Uruguay',
    tz_identifier: 'America/Montevideo',
    tz_offset: '-03:00',
    tz_abbr: '-03',
    latitude: -34.8199341,
    longitude: -56.3101524,
  },
  {
    country_code: 'UZ',
    country_name: 'Uzbekistan',
    tz_identifier: 'Asia/Samarkand',
    tz_offset: '+05:00',
    tz_abbr: '+05',
    latitude: 39.6406591,
    longitude: 66.8853162,
  },
  {
    country_code: 'UZ',
    country_name: 'Uzbekistan',
    tz_identifier: 'Asia/Tashkent',
    tz_offset: '+05:00',
    tz_abbr: '+05',
    summer_time: {
      tz_abbr: 'AKDT',
      tz_offset: '-08:00',
    },
    latitude: 41.282568,
    longitude: 69.1967959,
  },
  {
    country_code: 'VA',
    country_name: 'Holy See (Vatican City State)',
    tz_identifier: 'Europe/Vatican',
    tz_offset: '+01:00',
    tz_abbr: 'CET',
    summer_time: {
      tz_abbr: 'CEST',
      tz_offset: '+02:00',
    },
    latitude: 41.9038163,
    longitude: 12.4494809,
  },
  {
    country_code: 'VC',
    country_name: 'Saint Vincent and the Grenadines',
    tz_identifier: 'America/St_Vincent',
    tz_offset: '-04:00',
    tz_abbr: 'AST',
    latitude: 12.9715307,
    longitude: -61.4485474,
  },
  {
    country_code: 'VE',
    country_name: 'Venezuela, Bolivarian Republic of',
    tz_identifier: 'America/Caracas',
    tz_offset: '-04:00',
    tz_abbr: '-04',
    latitude: 10.4683811,
    longitude: -66.9729373,
  },
  {
    country_code: 'VG',
    country_name: 'Virgin Islands, British',
    tz_identifier: 'America/Tortola',
    tz_offset: '-04:00',
    tz_abbr: 'AST',
    summer_time: {
      tz_abbr: 'CEST',
      tz_offset: '+02:00',
    },
    latitude: 18.4210592,
    longitude: -64.6766024,
  },
  {
    country_code: 'VI',
    country_name: 'Virgin Islands, U.S.',
    tz_identifier: 'America/St_Thomas',
    tz_offset: '-04:00',
    tz_abbr: 'AST',
    latitude: 18.3344313,
    longitude: -65.0225012,
  },
  {
    country_code: 'VN',
    country_name: 'Viet Nam',
    tz_identifier: 'Asia/Ho_Chi_Minh',
    tz_offset: '+07:00',
    tz_abbr: '+07',
    latitude: 10.7547484,
    longitude: 106.5300802,
  },
  {
    country_code: 'VU',
    country_name: 'Vanuatu',
    tz_identifier: 'Pacific/Efate',
    tz_offset: '+11:00',
    tz_abbr: '+11',
    latitude: -17.6738996,
    longitude: 168.2935301,
  },
  {
    country_code: 'WF',
    country_name: 'Wallis and Futuna',
    tz_identifier: 'Pacific/Wallis',
    tz_offset: '+12:00',
    tz_abbr: '+12',
    latitude: -13.7736313,
    longitude: -177.8234887,
  },
  {
    country_code: 'WS',
    country_name: 'Samoa',
    tz_identifier: 'Pacific/Apia',
    tz_offset: '+13:00',
    tz_abbr: '+13',
    latitude: -13.8599114,
    longitude: -171.7887973,
  },
  {
    country_code: 'YE',
    country_name: 'Yemen',
    tz_identifier: 'Asia/Aden',
    tz_offset: '+03:00',
    tz_abbr: '+03',
    latitude: 12.8102905,
    longitude: 44.9949247,
  },
  {
    country_code: 'YT',
    country_name: 'Mayotte',
    tz_identifier: 'Indian/Mayotte',
    tz_offset: '+03:00',
    tz_abbr: 'EAT',
    latitude: -12.806537,
    longitude: 45.0732113,
  },
  {
    country_code: 'ZA',
    country_name: 'South Africa',
    tz_identifier: 'Africa/Johannesburg',
    tz_offset: '+02:00',
    tz_abbr: 'SAST',
    latitude: -26.1709457,
    longitude: 27.9900839,
  },
  {
    country_code: 'ZM',
    country_name: 'Zambia',
    tz_identifier: 'Africa/Lusaka',
    tz_offset: '+02:00',
    tz_abbr: 'CAT',
    latitude: -15.4168146,
    longitude: 28.2611817,
  },
  {
    country_code: 'ZW',
    country_name: 'Zimbabwe',
    tz_identifier: 'Africa/Harare',
    tz_offset: '+02:00',
    tz_abbr: 'CAT',
    latitude: -17.8166203,
    longitude: 30.9742869,
  },
];
