import { ChangeDetectionStrategy, Component, HostListener, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';

import { Clipboard } from '@angular/cdk/clipboard';

import { cloneDeep, isNil } from 'lodash-es';
import { Observable, of, take } from 'rxjs';
import { filter, map, switchMap, tap } from 'rxjs';

import { TranslateService } from '@ngx-translate/core';
import { ISchema, SchemaValidatorFactory } from 'ngx-schema-form';

import { AppStateService } from 'app/core/app-state.service';
import { Beeguard2Api } from 'app/core/api/main/beeguard2-api-service';
import { marker as i18n } from '@biesbjerg/ngx-translate-extract-marker';

import { Entity, Exploitation } from 'app/models';

import { DialogsService } from 'app/widgets/dialogs-modals/dialogs.service';

import { AbstractFormModalComponent } from 'app/widgets/dialogs-modals';
import { ModalArgs, ModalParams } from 'app/widgets/dialogs-modals/abstract-modal.component';
import { replay } from '@bg2app/tools/rxjs';
import { DeleteOrArchiveEntityAction } from 'app/views/entities/shared/button-delete-or-archive-entity/button-delete-or-archive-entity.component';

export interface UpdateEntitytModalArgs extends ModalArgs {
  [key: string]: any;
}

export interface UpdateEntityModalParams extends ModalParams {
  eid: number;
  oh: boolean; // oh: Only History, this is used in url so we use a "stupid" achronyme
  args: UpdateEntitytModalArgs;
}

@Component({
  selector: 'bg2-update-entity-modal',
  templateUrl: './update-entity.modal.html',
  styleUrls: ['./update-entity.modal.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class UpdateEntityModal<I extends UpdateEntityModalParams = UpdateEntityModalParams>
  extends AbstractFormModalComponent<I>
  implements OnDestroy
{
  public entity: Entity = null; // Entity object

  get eid(): number {
    return this.input_params?.eid;
  }

  // #region -> (component basics)

  constructor(
    protected router: Router,
    protected schemaValidatorFactory: SchemaValidatorFactory,
    protected bg2Api: Beeguard2Api,
    public appState: AppStateService,
    protected translate: TranslateService,
    private dialogs: DialogsService,
    private _clipboard: Clipboard
  ) {
    super();
  }

  ngOnDestroy(): void {
    this.entity?.reset_static_state();

    super.ngOnDestroy();
  }

  protected unsubscribe(): void {
    super.unsubscribe();

    if (this.entity) {
      // unbind to the entity, do unsubscribe it if no other binders
      this.entity.unbind('UPDATE_ENTITY');
      delete this.entity;
    }
  }

  // #endregion

  // #region -> (closing management)

  /** */
  protected handle_event_before_unload(event: BeforeUnloadEvent): void {
    if (this.entity?.has_changed) {
      event.returnValue = true;
    }
  }

  public close(): void {
    if (this.is_submitting || this.is_trying_to_close_modal) {
      return;
    }

    if (this.entity && this.entity.has_changed) {
      this.is_trying_to_close_modal = true;

      this.dialogs
        .confirm(i18n('VIEWS.MODALS.FORM.One or more changes are not saved. Do you want to close by abandoning these changes ?'), {
          onFalseMessage: i18n('VIEWS.MODALS.FORM.Come back'),
          onTrueMessage: i18n('VIEWS.MODALS.FORM.Close without saving'),
        })
        .subscribe(agreement => {
          this.is_trying_to_close_modal = false;

          if (agreement) {
            this.entity.reset_static_state();
            super.close();
          }
        });
    } else {
      super.close();
    }
  }

  protected forceClose(): void {
    super.close();
  }

  // #endregion

  // #region -> (modal params)

  public oh$$: Observable<boolean> = this.input_params$$.pipe(
    map(input_params => input_params?.oh || false),
    replay()
  );

  public entity$$: Observable<Entity> = this.input_params$$.pipe(
    map<any, string>(input_params => input_params?.eid || '-1'),
    map(eid_str => parseInt(eid_str, 10)),
    tap(() => (this.loading = true)),
    tap(() => (this.initial_loading = true)),
    switchMap(entity_id => (!isNil(entity_id) ? this.bg2Api.getEntityObj(entity_id, true) : of(null))),
    tap(entity => {
      if (!isNil(entity)) {
        this.entity = entity;
        this.entity.bind('UPDATE_ENTITY');
      }
    }),
    replay()
  );

  public form_schema$$ = this.entity$$.pipe(
    filter(entity => !isNil(entity)),
    switchMap(entity => entity.schema$$),
    map(entity_schema => entity_schema.static_state_schema),
    tap(() => (this.loading = false)),
    tap(() => (this.initial_loading = false)),
    map((schema: ISchema) => {
      if (isNil(schema)) {
        return null;
      }
      // Add ref to args and eid in schema, this may be usefull on some widget to get form context
      schema.args = {
        eid: this.eid,
      };

      return schema;
    })
  );

  public entity_form_model$$ = this.entity$$.pipe(
    switchMap(entity => (!isNil(entity) ? entity.static_state$$ : of(null))),
    map(static_state => cloneDeep(static_state)),
    replay()
  );
  // #endregion

  protected onFormChanged(event: any) {} // Not used here

  public update_entity_static_state(new_static_state: any): void {
    if (isNil(this.entity)) {
      return;
    }
    const new_static_state_cpy = cloneDeep(new_static_state);
    if (!isNil(new_static_state_cpy.other_data)) {
      delete new_static_state_cpy.other_data;
    }
    this.entity.static_state = new_static_state_cpy;
  }

  public submit(): void {
    if (this.loading) {
      return;
    }

    this.loading = true;
    this.is_submitting = true;

    this.entity.save().subscribe({
      next: ret => {
        this.loading = false;
        this.initial_loading = false;

        this.is_submitting = false;

        this.forceClose();
      },
      error: (err: unknown) => {
        this.loading = false;
        this.initial_loading = false;

        this.is_submitting = false;

        this.handleHttpError(err);
      },
    });
  }

  public onDeleteOrArchiveEntity(action: DeleteOrArchiveEntityAction): void {
    if (action === DeleteOrArchiveEntityAction.DELETE) {
      this.forceClose();
    }
  }

  /** */
  public as_exploitation(entity: Entity<any>): Exploitation | null {
    if (entity instanceof Exploitation) {
      return entity;
    }

    return null;
  }
}
