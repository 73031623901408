import { Component, Input, ChangeDetectionStrategy, OnInit, OnDestroy } from '@angular/core';

import { chunk, clone, isNil, uniqueId } from 'lodash-es';
import { marker as i18n } from '@biesbjerg/ngx-translate-extract-marker';

import { combineLatest, map, Subscription, switchMap, take, tap } from 'rxjs';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { anyTrue, distinctUntilRealChanged, replay } from '@bg2app/tools/rxjs';

import { AppStateService } from 'app/core/app-state.service';
import { DialogsService } from 'app/widgets/dialogs-modals/dialogs.service';

import { TableBaseComponent } from 'app/widgets/misc-widgets/table-base/table-base.component';

import { DRDevice, Paging, SimpleSetterGetter } from 'app/models';
import { parseDate } from 'app/misc/tools';
import { BatChange } from 'app/core/api-swagger/device';
import { DatatableBaseRow } from 'app/typings/datatable/interfaces/DatatableBaseRow.iface';
import { TranslateService } from '@ngx-translate/core';
import { UrlParamsService } from 'app/core/url-param.service';
import { DatatableGroup } from 'app/models/misc/datatable';
import { get_device_bat_changes_columns$$ } from './device-bat-changes.columns';
import { ConsoleLoggerService } from 'app/core/console-logger.service';
import { DevicesBulkBatteryChangeDialogComponent } from '../../dialogs-and-modals/dialogs';

/** */
interface DeviceBatChangeRow extends DatatableBaseRow {
  /** */
  bat_change: BatChange;
}

@Component({
  selector: 'bg2-device-bat-changes',
  templateUrl: './device-bat-changes.component.html',
  styleUrls: ['./device-bat-changes.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DeviceBatChangesComponent extends TableBaseComponent<DeviceBatChangeRow> implements OnInit, OnDestroy {
  // #region -> (component basics)

  /** */
  private readonly LOGGER = new ConsoleLoggerService('DeviceBatChangesComponent', false);

  /** */
  private _data_sub: Subscription = null;

  /** */
  public readonly PAGE_SIZE_OPTIONS = [5];

  /** */
  protected get_default_page_size() {
    return this.PAGE_SIZE_OPTIONS[0];
  }

  constructor(
    public appState: AppStateService,

    private _translate: TranslateService,
    private readonly dialogs: DialogsService,
    private readonly _urlParams: UrlParamsService
  ) {
    super(_translate, _urlParams);

    this.track_groupby_in_url.value = false;
    this.track_pagination_in_url.value = false;
    this.paging = { limit: 5, offset: 0, total: undefined };
  }

  ngOnInit(): void {
    this.appState.is_superadmin$$
      .pipe(
        take(1),
        switchMap(is_superadmin => get_device_bat_changes_columns$$(is_superadmin))
      )
      .subscribe({
        next: columns => {
          this.all_columns = columns;
        },
        complete: () => this.once_columns_ready(),
      });
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();

    this?._data_sub?.unsubscribe();
  }

  /** */
  private once_columns_ready(): void {
    // Subscribe on devices data
    this._data_sub = this._bat_change_rows$$
      .pipe(
        tap(() => (this.loading.value = true)),
        switchMap(loaded_device_rows =>
          this.sorting_model$$.pipe(switchMap(sorting_model => super.sort_data_locally(loaded_device_rows, sorting_model)))
        )
      )
      .subscribe({
        next: (rows: DeviceBatChangeRow[]) => {
          this.error = null;

          this.selection_model.clear();

          this.paged_data_rows = rows;

          this.loading.value = false;
        },
        error: (error: unknown) => {
          if (error instanceof Error) {
            this.LOGGER.error(error?.message);
          } else {
            this.LOGGER.error(error);
          }
          this.error = error;
        },
      });
  }

  // #endregion

  // #region -> (loadings)

  private loading = new SimpleSetterGetter(true);

  /** */
  public loading$$ = anyTrue(this.loading.value$$.pipe(distinctUntilRealChanged())).pipe(replay());

  // #endregion

  // #region -> (component configuration)

  /** */
  @Input()
  public can_add: boolean = false;

  // #endregion

  // #region -> (related device)

  /** */
  private _device$$: BehaviorSubject<DRDevice> = new BehaviorSubject(null);

  /** */
  public device$$: Observable<DRDevice> = this._device$$.asObservable();

  /** */
  @Input()
  public set device(device: DRDevice) {
    this._device$$.next(device);
  }

  // #endregion

  // #region -> (battery change)

  /** */
  private _device_bat_changes_data$$ = this.device$$.pipe(
    switchMap(device => {
      if (isNil(device)) {
        return of<BatChange[]>([]);
      }

      return device?.bat_changes$$;
    }),
    replay()
  );

  /** */
  private _bat_change_rows$$ = combineLatest({ data: this._device_bat_changes_data$$, paging: this.paging$$ }).pipe(
    map(({ data, paging }) => {
      const copy_paging = clone(paging);
      copy_paging.total = (data ?? [])?.length;

      if ((data ?? [])?.length === 0) {
        copy_paging.total = 0;

        return { data: <DeviceBatChangeRow[]>[], paging: copy_paging };
      }

      const data_chunks = chunk(data, copy_paging?.limit);
      const data_to_return = data_chunks[(copy_paging?.offset ?? 0) / 5]?.map(bat_change => <DeviceBatChangeRow>{ bat_change });

      return { data: data_to_return, paging: copy_paging };
    }),
    map(response => {
      const cp_paging: Paging = response?.paging;

      if (cp_paging?.offset >= cp_paging?.total) {
        cp_paging.offset = 0;
      }

      this.paging = cp_paging;
      return response?.data;
    }),
    replay()
  );

  /** */
  public unConfirm(bat_ch: BatChange): void {
    this.device$$
      .pipe(
        take(1),
        switchMap(device => device.unConfirmBatCh(bat_ch.time))
      )
      .subscribe();
  }

  /** */
  public confirm(bat_ch: BatChange): void {
    this.device$$
      .pipe(
        take(1),
        switchMap(device => device.confirmBatCh(bat_ch.time))
      )
      .subscribe();
  }

  /** */
  public delete(bat_ch: BatChange): void {
    const force = bat_ch.tags.origin !== 'device_consumption_base';

    this.device$$
      .pipe(
        take(1),
        switchMap(device =>
          this.dialogs
            .confirm(i18n('VIEWS_WINDOWED.MODALS.DEVICE_MODAL.Are you sure to remove this bat change ?'), {
              onTrueMessage: i18n("VIEWS.PAGE.DATA.Let's go !"),
              onFalseMessage: i18n('VIEWS.MODALS.FORM.Cancel'),
            })
            .pipe(
              switchMap(agreement => {
                if (agreement) {
                  return device.rmBatCh(bat_ch.time, force);
                }

                return of(null);
              })
            )
        ),
        take(1)
      )
      .subscribe();
  }

  /** */
  public add(): void {
    this.device$$
      .pipe(
        take(1),
        switchMap(device => this.dialogs.open(DevicesBulkBatteryChangeDialogComponent, { devices: [device] })),
        take(1)
      )
      .subscribe();
  }

  // #endregion

  // #region -> (helpers)

  /** */
  public is_row(row_or_group: DeviceBatChangeRow | DatatableGroup<DeviceBatChangeRow>): DeviceBatChangeRow {
    if (row_or_group instanceof DatatableGroup) {
      return null;
    }

    return row_or_group;
  }

  /** */
  public is_group(row_or_group: DeviceBatChangeRow | DatatableGroup<DeviceBatChangeRow>): DatatableGroup<DeviceBatChangeRow> {
    if (row_or_group instanceof DatatableGroup) {
      return row_or_group;
    }

    return null;
  }

  // #endregion
}
