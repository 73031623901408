<div class="bg2-locations-details-component">
  <ng-container *ngIf="display_type === 'classic'">
    <div *ngLet="breadcrumb_data$$ | async; let breadcrumb_data" class="breadcrumb">
      <ng-container *ngIf="breadcrumb_data?.exploitation_name; let exploitation_name; else: loadingExploitation">
        <a [routerLink]="['/exploitation', exploitation_id$$ | async]" [queryParamsHandling]="'preserve'">{{ exploitation_name }}</a>
      </ng-container>

      <ng-template #loadingExploitation>
        <bg2-loading-skeleton [string_model]="'warehouse_name'" [theme]="{ height: '16px' }"></bg2-loading-skeleton>
      </ng-template>

      &gt;

      <a [routerLink]="['/locations/all']" [queryParamsHandling]="'preserve'">
        {{ 'VIEWS.LOCATION_CARD.All my locations' | translate }}
      </a>

      &gt;

      <span *ngIf="breadcrumb_data?.location_name; let location_name; else: loadingLocationName">{{ location_name }}</span>

      <ng-template #loadingLocationName>
        <bg2-loading-skeleton [string_model]="'location_name'" [theme]="{ height: '16px' }"></bg2-loading-skeleton>
      </ng-template>
    </div>
  </ng-container>

  <bg2-location-details-header
    [location]="location$$ | async"
    [location_header_type]="display_type"
    (when_quit)="when_quit.emit()"
  ></bg2-location-details-header>

  <bg2-location-details-content [location]="location$$ | async" [display_type]="display_type"></bg2-location-details-content>
</div>
