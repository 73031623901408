// From ~node_modules/@angular/*
import { Component, Input, ChangeDetectionStrategy } from '@angular/core';

// From ~node_modules/lodash/*
import { includes, isNil } from 'lodash-es';

// From ~node_modules/rxjs/*
import { map } from 'rxjs';
import { BehaviorSubject, Observable, combineLatest } from 'rxjs';
import { Dictionary } from 'app/typings/core/interfaces';

@Component({
  selector: 'explanation-schemas',
  templateUrl: 'explanation-schemas.component.html',
  styleUrls: ['explanation-schemas.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ExplanationSchemasComponent {
  public today = new Date();

  private readonly SCHEMA_NAMES = [
    'takeout_rg_keep_data',
    'takeout_rg_drop_data',
    'takeout_device_drop_data',
    'takeout_device_keep_data',
    'move_device_from_hive_to_hive',
    'move_device_from_wh_to_wh',
    'takeout_device__from_wh__drop_data',
  ];

  private _schema_name$: BehaviorSubject<string> = new BehaviorSubject(null);
  private schema_name$$: Observable<string> = this._schema_name$.asObservable();

  @Input()
  public set schema_name(schema_name: string) {
    this._schema_name$.next(schema_name);
  }

  private _data$: BehaviorSubject<Dictionary<any>> = new BehaviorSubject(null);
  private data$$: Observable<Dictionary<any>> = this._data$.asObservable();

  @Input()
  public set data(data: Dictionary<any>) {
    this._data$.next(data);
  }

  public schema$$: Observable<[string, Dictionary<any>]> = combineLatest([this.schema_name$$, this.data$$]);

  public has_error$$ = this.schema$$.pipe(
    map(([schema_name, data]: [string, Dictionary<any>]) => {
      // Check if there are all needed informations
      if (isNil(schema_name) || isNil(data)) {
        return true;
      }

      // Check if schema exists
      if (!includes(this.SCHEMA_NAMES, schema_name)) {
        return true;
      }

      return false;
    })
  );
}
