import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { marker as i18n } from '@biesbjerg/ngx-translate-extract-marker';

import { isNil } from 'lodash-es';

import { catchError, combineLatest, map, Observable, of, switchMap, take, tap, throwError } from 'rxjs';
import { catchErrorInDialog, replay, waitForNotNilValue } from '@bg2app/tools/rxjs';

import { DialogsService } from 'app/widgets/dialogs-modals';
import { AppStateService } from 'app/core/app-state.service';
import { Beeguard2Api, DeviceApi, UsersApiService } from 'app/core';
import { ZohoAuthService } from 'app/core/services/zoho/zoho-auth.service';
import { ZohoApisService } from 'app/core/services/zoho/zoho-apis.service';

import {
  DevicesSupportCreateDialogParams,
  DevicesSupportsCreateDialogComponent,
} from '../../../../dialogs/device-support-create-dialog/device-support-create-dialog.component';
import {
  DevicesSupportsUpdateDialogComponent,
  DevicesSupportUpdateDialogParams,
} from '../../../../dialogs/device-support-update-dialog/device-support-update-dialog.component';
import { CustomazibleDialogParams } from 'app/widgets/dialogs-modals/customizable/customazible.dialog';
import { DeviceModalAbstractCategoryComponent } from '../device-modal-abstract-details-or-summary.component';

import { ZohoDeskTicket } from 'app/models/zoho/desk';
import { get_i18n_for_zoho_error, ZohoError } from 'app/models/zoho';
import { DeviceSupportType, RawDeviceSupports, SimpleSetterGetter } from 'app/models';

import { encodeJson } from 'app/misc/tools';
import {
  DevicesRegisterDialogComponent,
  DevicesRegisterDialogParams,
} from '../../../../dialogs/device-register-dialogs/devices-register.dialog';
import { Clipboard } from '@angular/cdk/clipboard';
import { Router } from '@angular/router';
import { Dictionary } from 'lodash';

@Component({
  selector: 'bg2-device-modal-identification',
  templateUrl: './device-modal-identification.component.html',
  styleUrls: ['./device-modal-identification.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DeviceModalIdentificationComponent extends DeviceModalAbstractCategoryComponent {
  // #region -> (component basics)

  constructor(
    public readonly clipboard: Clipboard,

    protected readonly _deviceApi: DeviceApi,
    protected readonly _dialogs: DialogsService,
    protected readonly _appStateService: AppStateService,

    private readonly _router: Router,
    private readonly _bg2Api: Beeguard2Api,
    private readonly _zohoApis: ZohoApisService,
    private readonly _usersApi: UsersApiService,
    private readonly _zohoAuthService: ZohoAuthService
  ) {
    super(_appStateService, _dialogs, _deviceApi);
  }

  // #endregion

  // #region -> (loadings)

  /** */
  public is_loading_owner = new SimpleSetterGetter(true);

  // #endregion

  // #region -> (device basics)

  // #endregion

  // #region -> (device config)

  public is_wguard_data_recovery_active$$ = this.device$$.pipe(waitForNotNilValue());

  // #endregion

  // #region -> (device ZOHO tickets and supports)

  /** */
  public is_logged_to_zoho$$ = this._zohoAuthService.is_authenticated$$;

  /** */
  public refetch_zoho_data = new SimpleSetterGetter(false);

  /** */
  public device_opened_tickets$$ = this._zohoAuthService.is_authenticated$$.pipe(
    switchMap(is_zoho_authenticated =>
      of(is_zoho_authenticated).pipe(
        switchMap(_is_zoho_authenticated => {
          if (_is_zoho_authenticated === false) {
            return throwError(() => new Error(i18n<string>('VIEWS.AUTH.LOGIN.Not connected')));
          }

          return this.device$$;
        }),
        catchError((error: unknown) => of(<Error>error))
      )
    ),
    switchMap(device_or_error => {
      if (device_or_error instanceof Error) {
        return of(device_or_error);
      }

      return of(device_or_error).pipe(
        switchMap(_device => {
          if (isNil(_device)) {
            return throwError(() => new Error(get_i18n_for_zoho_error(ZohoError.UNDEFINED_ZOHO_DESK_ACCOUNT)));
          }

          return this._zohoApis.desk_api
            .search_tickets(
              { customField1: `cf_imeis:${_device.imei}` },
              { statusType: ['Open', 'On Hold'], include_with_undefined_status_type: true }
            )
            .pipe(map(response => response?.data ?? []));
        }),
        catchError((error: unknown) => of(<Error>error))
      );
    }),
    map(tickets => {
      const response = { value: <ZohoDeskTicket[]>null, error: <Error>null };

      if (tickets instanceof Error) {
        response.error = tickets;
        return response;
      }

      response.value = tickets;
      return response;
    }),
    replay()
  );

  /** */
  public ask_device_support_data$$ = this.device$$.pipe(
    waitForNotNilValue(),
    map(device => ({ name: device?.name })),
    map(data => encodeJson(data)),
    replay()
  );

  /** */
  public search_tickets_on_web(): void {
    this.device_imei$$.pipe(take(1)).subscribe({
      next: device_imei => {
        const parsed_imei = device_imei?.toString().slice(-4);
        window.open(`https://desk.zoho.eu/support/beeguard/ShowHomePage.do#Cases/search/CurDep/${parsed_imei}`, '_blank');
      },
    });
  }

  protected create_desk_ticket(): void {
    this.device$$
      .pipe(
        waitForNotNilValue(),
        switchMap(device =>
          device?.exploitation$$(this._bg2Api).pipe(
            switchMap(exploitation => {
              const exploitation_owner$$ = exploitation?.owner$$;

              return exploitation_owner$$.pipe(
                switchMap(exploitation_owner => {
                  if (isNil(exploitation_owner)) {
                    return of(null);
                  }

                  return combineLatest({
                    zoho_desk_account: exploitation?.zoho_desk_account$$,
                    zoho_desk_contact: exploitation_owner.zoho_desk_contact$$,
                  });
                })
              );
            }),
            map(({ zoho_desk_account, zoho_desk_contact }) => ({ device, zoho_desk_account, zoho_desk_contact }))
          )
        ),
        map(data => {
          if (data?.zoho_desk_account instanceof Error) {
            throw data?.zoho_desk_account;
          }

          if (data?.zoho_desk_contact instanceof Error) {
            throw data?.zoho_desk_contact;
          }

          return data;
        }),
        catchErrorInDialog(this._dialogs),
        take(1)
      )
      .subscribe({
        next: ({ device, zoho_desk_account, zoho_desk_contact }) => {
          if (zoho_desk_account instanceof Error || zoho_desk_contact instanceof Error) {
            return null;
          }

          const modal_params: Dictionary<any> = {
            issue_type: 'device_issue',
            device_imeis: encodeJson([device.imei]),
          };

          if (!isNil(zoho_desk_contact?.id)) {
            modal_params['zoho_desk_contact_id'] = zoho_desk_contact?.id;
          }

          if (!isNil(zoho_desk_account?.accountName)) {
            modal_params['zoho_desk_account_name'] = zoho_desk_account?.accountName;
          }

          this._router.navigate(
            [
              '',
              {
                outlets: {
                  modal: ['zoho_create_issue', modal_params],
                },
              },
            ],
            {
              queryParamsHandling: 'preserve',
            }
          );
        },
        complete: () => {},
      });
  }

  // #endregion

  // #region -> (device supports)

  /** */
  public device_opened_supports$$: Observable<RawDeviceSupports['open']> = this.device$$.pipe(
    switchMap(device => {
      if (isNil(device)) {
        return of([]);
      }

      return device.supports_opened$$;
    }),
    replay()
  );

  /** */
  public can_manage_ru_support$$ = this._zohoAuthService.is_authenticated$$.pipe(
    // Check if user is logged to Zoho
    switchMap(is_authenticated_or_error =>
      of(is_authenticated_or_error).pipe(
        switchMap(_is_authenticated => {
          if (!_is_authenticated) {
            return throwError(() => new Error(get_i18n_for_zoho_error(ZohoError.NOT_AUTHENTICATED)));
          }

          return this.device$$.pipe(switchMap(device => device?.exploitation$$(this._bg2Api)));
        }),
        catchError((error: unknown) => of(<Error>error))
      )
    ),
    // Check if the device is affected
    switchMap(exploitation_or_error => {
      if (exploitation_or_error instanceof Error) {
        return of(exploitation_or_error);
      }

      return of(exploitation_or_error).pipe(
        switchMap(exploitation => {
          if (isNil(exploitation)) {
            return throwError(() => new Error(i18n<string>('DEVICE.ALL.ERROR.One of the selected devices is not affected')));
          }

          return of(false);
        }),
        catchError((error: unknown) => of(<Error>error))
      );
    }),
    map(can_create_ru_or_error => {
      const response = { value: <boolean>null, error: <Error>null };

      if (can_create_ru_or_error instanceof Error) {
        response.error = can_create_ru_or_error;
        return response;
      }

      response.value = can_create_ru_or_error;
      return response;
    })
  );

  /** */
  public create_new_support(): void {
    this.device$$
      .pipe(
        waitForNotNilValue(),
        switchMap(device =>
          this._dialogs.open<DevicesSupportCreateDialogParams, any>(DevicesSupportsCreateDialogComponent, {
            devices: [device],
            support_type: 'ru_no_sms',
          })
        ),
        take(1)
      )
      .subscribe();
  }

  /** */
  public update_support(): void {
    this.device$$
      .pipe(
        waitForNotNilValue(),
        switchMap(device =>
          this._dialogs.open<DevicesSupportUpdateDialogParams, any>(DevicesSupportsUpdateDialogComponent, {
            devices: [device],
            support_type: DeviceSupportType.RU_NO_SMS,
          })
        ),
        take(1)
      )
      .subscribe();
  }

  // #endregion

  // #region -> (device affectation)

  public device_owner$$ = this.device$$.pipe(
    tap(() => (this.is_loading_owner.value = true)),
    waitForNotNilValue(),
    switchMap(device => device.request_owner_user$(this._usersApi)),
    tap(() => (this.is_loading_owner.value = false)),
    replay()
  );

  public change_owner() {
    this.device$$
      .pipe(
        waitForNotNilValue(),
        take(1),
        switchMap(device =>
          this._dialogs.open<DevicesRegisterDialogParams, any>(DevicesRegisterDialogComponent, {
            ignore_set_exploitation: true,
            devices: [device],
          })
        )
      )
      .subscribe();
  }

  // #endregion
}
