import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { isEmpty, isNil } from 'lodash-es';

import { BehaviorSubject, map, of, switchMap } from 'rxjs';
import { distinctUntilRealChanged } from '@bg2app/tools/rxjs';

import { TranslateService } from '@ngx-translate/core';
import { marker as i18n } from '@biesbjerg/ngx-translate-extract-marker';

import { EntityType } from 'app/models';

/** */
interface ACLPopperConfig {
  /** */
  entity_type: EntityType;

  /** */
  what: string;

  /** */
  ace: string;
}

@Component({
  selector: 'bg2-acl-not-accessible',
  templateUrl: './acl-not-accessible.component.html',
  styleUrls: ['./acl-not-accessible.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ACLNotAccessibleComponent {
  // #region -> (component basics)

  constructor(private readonly _translate: TranslateService) {}

  // #endregion

  // #region -> (message management)

  /** */
  private _message$$ = new BehaviorSubject<string>(null);

  /** */
  public message$$ = this._message$$.asObservable().pipe(
    switchMap(message => {
      if (isNil(message)) {
        return this._translate.stream(i18n<string>('ALL.ACE.COMMON.Not accessible'));
      }

      return of<string>(message);
    }),
    distinctUntilRealChanged()
  );

  /** */
  @Input()
  public set message(message: string) {
    this._message$$.next(message);
  }

  // #endregion

  // #region -> (popper config)

  /** */
  private _popper_config$$ = new BehaviorSubject<ACLPopperConfig>(null);

  /** */
  public popper_config$$ = this._popper_config$$.asObservable();

  /** */
  @Input()
  public set popper_config(popper_config: ACLPopperConfig) {
    if (isNil(popper_config.entity_type)) {
      throw new Error('Property "entity_type" is required !');
    }

    if (isNil(popper_config.ace)) {
      throw new Error('Property "ace" is required !');
    }

    if (isNil(popper_config.what)) {
      throw new Error('Property "what" is required !');
    }

    this._popper_config$$.next(popper_config);
  }

  // #endregion
}
