import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Input,
  NgZone,
  OnDestroy,
  ViewChild,
  ViewContainerRef,
  ViewRef,
} from '@angular/core';

import { take } from 'rxjs';
import { waitForNotNilValue } from '@bg2app/tools/rxjs';

import { AppStateService } from 'app/core/app-state.service';
import { ConsoleLoggerService } from 'app/core/console-logger.service';
import { D3SharedCursorService } from 'app/core/services/global/d3-shared-cursor.service';
import { ResizedEvent } from 'app/misc/directives/resized/resized.directive';

import { D3SvgApiaryWeatherIconsFactory } from './apiary-weather-icons-d3-chart-factory';

import { ChartGeoposition, D3ChartMargins } from '@bg2app/models/charts';
import { isNil, merge } from 'lodash-es';
import { WeatherData } from 'app/models/data/data-classic';
import { DateTime } from 'luxon';

@Component({
  selector: 'bg2-apiary-weather-icons-d3-chart',
  templateUrl: './apiary-weather-icons-d3-chart.component.html',
  styleUrls: ['./apiary-weather-icons-d3-chart.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ApiaryWeatherIconsD3ChartComponent implements AfterViewInit, OnDestroy {
  // #region -> (component basics)

  private readonly _logger = new ConsoleLoggerService('ApiaryWeatherIconsD3ChartComponent', true);

  constructor(private _d3_shared_cursor: D3SharedCursorService, private _appState: AppStateService, private _ngZone: NgZone) {
    this.chart_factory = new D3SvgApiaryWeatherIconsFactory(this._d3_shared_cursor, this._appState, this._ngZone);
  }

  ngAfterViewInit(): void {
    this.chart_factory.box_sizing$$.pipe(waitForNotNilValue(), take(1)).subscribe({
      next: () => {
        this.chart_factory.create_chart();
      },
    });
  }

  ngOnDestroy(): void {
    this.chart_factory.destroy();
  }

  // #endregion

  public chart_factory: D3SvgApiaryWeatherIconsFactory = null;

  /** */
  public onChartContainerResized(resized_event: ResizedEvent): void {
    this.chart_factory.box_sizing = { width: resized_event?.new_width, height: resized_event?.new_height };
  }

  // #region -> (data management)

  @Input()
  public set weather_data(weather_data: WeatherData) {
    this.chart_factory.incoming_data = weather_data;
  }

  @Input()
  public set margins(margins: Partial<D3ChartMargins>) {
    this.chart_factory.margins = merge(this.chart_factory.margins, margins);
  }

  /** */
  @Input()
  public set date_range(date_range: { start: DateTime; end: DateTime }) {
    if (isNil(date_range)) {
      return;
    }

    this.chart_factory.date_range = date_range;
  }

  /** */
  @Input()
  public set is_loading(is_loading: boolean) {
    this.chart_factory.is_loading = is_loading;
  }

  /** */
  @Input()
  public set geoposition(geoposition: ChartGeoposition) {
    this.chart_factory.geoposition.value = geoposition;
  }

  // #endregion
}
