import { AbstractFilterMngt } from 'app/models/filters';

import { Dictionary } from 'app/typings/core/interfaces';

export type DeviceQuery = Dictionary<any>;

export class NotifiedDevicesDatatableFilters extends AbstractFilterMngt<DeviceQuery> {
  /** */
  constructor() {
    super([]);
  }
}
