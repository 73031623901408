import { Component, OnInit, OnDestroy, ChangeDetectionStrategy } from '@angular/core';

import { isNil, isNaN } from 'lodash-es';

import { FormProperty, NumberProperty } from 'ngx-schema-form';

import { Subscription, of, concat, BehaviorSubject, combineLatest, debounceTime, map, tap } from 'rxjs';
import { distinctUntilRealChanged, replay } from '@bg2app/tools/rxjs';

import { Options as Ng5SliderOptions } from '@angular-slider/ngx-slider';

import { ConsoleLoggerService } from 'app/core/console-logger.service';

import { EfObjectWidgetComponent, ObjectWidgetOptions } from '../object/object.widget';
import { convert_local_hours_to_utc, convert_utc_hours_to_local } from '@bg2app/tools/dates';

export interface ComOptions extends Ng5SliderOptions, ObjectWidgetOptions {}

@Component({
  selector: 'bg2-device-com',
  templateUrl: './device-com.widget.html',
  styleUrls: ['./device-com.widget.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EfBg2DeviceComWidgetComponent extends EfObjectWidgetComponent implements OnInit, OnDestroy {
  // #region -> (widget properties)

  public readonly options: ComOptions = {
    transparent: true,
    floor: 0,
    ceil: 23,
    showTicks: true,
    tickStep: 1,
  };

  // #endregion

  // #region -> (component basics)

  private readonly _logger = new ConsoleLoggerService('EfBg2DeviceComWidget', false);

  private _outgoing_values_sub: Subscription = null;
  private _incoming_values_sub: Subscription = null;

  ngOnInit(): void {
    super.ngOnInit();

    // Susbcribe to incoming hour values
    this._incoming_values_sub = concat(of(this.formProperty.value), this.formProperty.valueChanges)
      .pipe(
        map(form_value => ({ com_type: form_value?.conf, utc_hour_one: form_value?.hour_utc_one, utc_hour_two: form_value?.hour_utc_two })),
        distinctUntilRealChanged()
      )
      .subscribe({
        next: ({ com_type, utc_hour_one, utc_hour_two }) => {
          if (isNil(utc_hour_one) && isNil(utc_hour_two)) {
            return;
          }

          this._logger.debug({ com_type, utc_hour_one, utc_hour_two });

          let local_hour_left = convert_utc_hours_to_local([utc_hour_one])[0];
          let local_hour_right = convert_utc_hours_to_local([utc_hour_two])[0];

          // Check if inverse is needed
          // if (!isNil(local_hour_left) && !isNil(local_hour_right) && local_hour_left > local_hour_right) {
          //   const _temp_right = local_hour_right;
          //   local_hour_right = local_hour_left;
          //   local_hour_left = _temp_right;
          // }

          this._com_config$$.next(com_type);
          this._local_hour_one$$.next(local_hour_left);
          this._local_hour_two$$.next(local_hour_right);
        },
      });

    // Subscribe to outgoing values
    this._outgoing_values_sub = combineLatest({
      local_hour_one: this.local_hour_one$$,
      local_hour_two: this.local_hour_two$$,
      com_config: this.com_config$$,
    })
      .pipe(debounceTime(100))
      .subscribe(({ com_config, local_hour_one, local_hour_two }) => {
        this._logger.debug({ com_config, local_hour_one, local_hour_two });

        let utc_hour_one = null;
        let utc_hour_two = null;

        if (!isNaN(local_hour_one)) {
          utc_hour_one = convert_local_hours_to_utc([local_hour_one])[0];
        }

        if (!isNaN(local_hour_two)) {
          utc_hour_two = convert_local_hours_to_utc([local_hour_two])[0];
        }

        // if (!isNil(utc_hour_one) && !isNil(local_hour_two) && utc_hour_one > local_hour_two) {
        //   const _temp_right = local_hour_two;
        //   local_hour_two = utc_hour_one;
        //   utc_hour_one = _temp_right;
        // }

        if (!isNil(utc_hour_one)) {
          const property: NumberProperty = this.formProperty.getProperty('hour_utc_one');

          if (isNil(property) || !(property instanceof NumberProperty)) {
            throw new Error("Bad declared property for 'hour_utc_one'");
          }

          property.setValue(utc_hour_one, false);
        }

        if (!isNil(utc_hour_two)) {
          const property: NumberProperty = this.formProperty.getProperty('hour_utc_two');

          if (isNil(property) || !(property instanceof NumberProperty)) {
            throw new Error("Bad declared property for 'hour_utc_two'");
          }

          property.setValue(utc_hour_two, false);
        }
      });
  }

  ngOnDestroy(): void {
    this._outgoing_values_sub?.unsubscribe();
    this._incoming_values_sub?.unsubscribe();
  }

  // #endregion

  // #region -> (data management)

  /** */
  private _com_config$$ = new BehaviorSubject<'one_by_day' | 'two_by_day' | 'every_two_days'>(null);

  /** */
  public com_config$$ = this._com_config$$.asObservable().pipe(distinctUntilRealChanged(), replay());

  /** */
  private _local_hour_one$$ = new BehaviorSubject<number>(null);

  /** */
  public local_hour_one$$ = this._local_hour_one$$.asObservable().pipe(distinctUntilRealChanged(), replay());

  /** */
  public set local_hour_one(local_hour_one: number) {
    if (isNaN(local_hour_one)) {
      return;
    }

    this._logger.debug('set: ', { local_hour_one });
    this._local_hour_one$$.next(local_hour_one);
  }

  /** */
  private _local_hour_two$$ = new BehaviorSubject<number>(null);

  /** */
  public local_hour_two$$ = this._local_hour_two$$.asObservable().pipe(distinctUntilRealChanged(), replay());

  /** */
  public set local_hour_two(local_hour_two: number) {
    if (isNaN(local_hour_two)) {
      return;
    }

    this._logger.debug('set: ', { local_hour_two });
    this._local_hour_two$$.next(local_hour_two);
  }

  // #endregion
}
