import { Component, OnInit, ChangeDetectorRef, OnDestroy, ChangeDetectionStrategy } from '@angular/core';
import { Router } from '@angular/router';
import { assign, clone, cloneDeep, extend, isEqual, isNil } from 'lodash-es';

import { tap, map, switchMap, catchError, take, filter } from 'rxjs';
import { Subscription, of, Observable, throwError, forkJoin, BehaviorSubject } from 'rxjs';

import { TranslateService } from '@ngx-translate/core';
import { marker as i18n } from '@biesbjerg/ngx-translate-extract-marker';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';

import { Beeguard2Api, DeviceApi } from 'app/core';
import { Apiary, Entity } from 'app/models';
import { Migratory } from 'app/models/events/Migratory';

import { AppStateService } from 'app/core/app-state.service';
import { EventForm } from 'app/widgets/event-form';
import { DialogsService } from 'app/widgets/dialogs-modals/dialogs.service';

import { NewEventModalComponent, NewEventModalParams } from '../new-event/new-event.modal';
import { ModalArgs } from 'app/widgets/dialogs-modals/abstract-modal.component';
import { parseDate } from 'app/misc/tools';
import {
  DeleteEventDialogComponent,
  DeleteEventDialogOutput,
} from 'app/views/events/shared/delete-event-dialog/delete-event-dialog.component';
import { endOfDay, isAfter } from 'date-fns/esm';
import { catchErrorInDialog, distinctUntilRealChanged, replay } from '@bg2app/tools/rxjs';
import { ISchema } from 'ngx-schema-form';

// Redefine some type for clarification
type FormSchema = any;

export interface MigratoryModalArgs extends ModalArgs {
  apiary?: number;
  location_dest?: number;
}

export interface MigratoryModalParams extends NewEventModalParams {
  date?: string;
  eid: number;
  args: MigratoryModalArgs;
}

/**
 * Migration event modal (create/update)
 *
 */
@AutoUnsubscribe()
@Component({
  selector: 'bg2-migratory-modal',
  templateUrl: './migratory.modal.html',
  styleUrls: ['./migratory.modal.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class MigratoryModal extends NewEventModalComponent<MigratoryModalParams> implements OnInit, OnDestroy {
  // #region -> (incoming parameters)

  public input_event_type$$: Observable<string> = of('migratory');

  public event_id$$ = this.input_params$$.pipe(
    map(params => +params.eid),
    distinctUntilRealChanged(),
    replay()
  );

  private get event_id(): number {
    return +this.input_params?.eid;
  }

  // #endregion

  // #region -> (component basics)

  private form_schema_sub: Subscription;

  constructor(
    protected _bg2Api: Beeguard2Api,
    protected _deviceApi: DeviceApi,
    protected _dialogs: DialogsService,
    protected _cdRef: ChangeDetectorRef,
    protected _appState: AppStateService,
    protected _translate: TranslateService
  ) {
    super(_bg2Api, _dialogs, _cdRef, _appState, _translate);
    this._logger.update_prefix('MigratoryModal');
  }

  ngOnInit(): void {
    this.loading = true;
    let init_pipe: Observable<any>;

    if (this.event_id) {
      this._is_update_of_event$$.next(true);
      init_pipe = this.event_id$$.pipe(
        take(1),
        switchMap(event_id => this._bg2Api.getEventObj(event_id) as Observable<Migratory>),
        tap(() => (this.loading = true)),
        tap((event: Migratory) => (this.event = event)),
        switchMap(event =>
          event?.partial
            ? event.getEntities(['apiary_dest'])
            : of<{
                [role: string]: Entity<any> | Entity<any>[];
              }>({})
        ),
        map(entities => {
          const new_apiary = entities.apiary_dest as Apiary;

          if (!isNil(new_apiary) && new_apiary.initial_setup_event_id === this.event.id) {
            this.new_apiary = new_apiary;
            // console.log(_.cloneDeep(this.new_apiary.has_changed));
          } else {
            this.new_apiary = new Apiary(this._bg2Api, this._deviceApi);
            this.update_new_apiary = false;
          }

          return this.new_apiary;
        })
      );
      // TODO what if no eid ? update ?
    } else {
      this.event = new Migratory(this._bg2Api);

      if (this.input_params.date) {
        this.event.date = parseDate(this.input_params.date);
      }

      this.new_apiary = new Apiary(this._bg2Api, this._deviceApi);
      init_pipe = of(false);
    }

    if (init_pipe) {
      init_pipe.subscribe(() => {
        this.buildAndBindForm();

        this.loading = false;
        this.initial_loading = false;
      });
    }
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this.form_schema_sub?.unsubscribe();
  }

  // #endregion

  // #region -> (event management)

  private _is_update_of_event$$ = new BehaviorSubject(false);
  public is_update_of_event$$ = this._is_update_of_event$$.asObservable().pipe(distinctUntilRealChanged(), replay());

  public get event(): Migratory {
    return super.event as Migratory;
  }

  public set event(event: Migratory) {
    super.event = event;
  }

  public deleteEvent(eventID: number) {
    DeleteEventDialogComponent.open(this._dialogs, { eventID }).subscribe({
      next: (dialog_result: DeleteEventDialogOutput) => {
        if (dialog_result === DeleteEventDialogOutput.DELETED) {
          super.force_close();
        }
      },
    });
  }

  // #endregion

  // #region -> (entities management)

  private last_form_model: any;
  private update_new_apiary = false; // True if we update a partial migration that implies an apiary creation
  private ignore_default_form = false;

  private _new_apiary$$ = new BehaviorSubject<Apiary>(null);
  public new_apiary$$ = this._new_apiary$$.asObservable().pipe(replay());

  public new_apiary_has_changed$$ = this.new_apiary$$.pipe(
    filter(new_apiary => !isNil(new_apiary)),
    switchMap(new_apiary => new_apiary.has_changed$$),
    distinctUntilRealChanged(),
    replay()
  );

  private set new_apiary(new_apiary: Apiary) {
    this._new_apiary$$.next(new_apiary);
  }

  private get new_apiary(): Apiary {
    return this._new_apiary$$.getValue();
  }

  // #endregion

  // #region -> (form management)

  private full_event_schema: FormSchema = null;
  private ignore_default_form_to: any;

  private _schema$$ = new BehaviorSubject<FormSchema>(null);
  public schema$$ = this._schema$$.asObservable().pipe(replay());

  private buildAndBindForm(): void {
    if (isNil(this.event)) {
      return;
    }

    this.loading = true;

    this.form_schema_sub?.unsubscribe();
    this.form_schema_sub = null;

    this.form_schema_sub = EventForm.buildSchema(this.event, this.args, false).subscribe(full_event_schema => {
      this.full_event_schema = full_event_schema;

      const oschema = cloneDeep(this.full_event_schema);
      const schema: ISchema = {
        type: 'object',
        options: {
          event_date_path: 'date',
        },
        required: ['date', 'source', 'migrate', 'dest'],
        anyOf: [
          {
            properties: {
              migrate: {
                properties: {
                  data: {
                    properties: {
                      partial: {
                        enum: [true],
                      },
                    },
                  },
                },
              },
              dest: {
                properties: {
                  create_new: { enum: [true] },
                },
                required: ['location_dest', 'warehouse_dest', 'new_apiary'],
              },
            },
          },
          {
            properties: {
              migrate: {
                properties: {
                  data: {
                    properties: {
                      partial: {
                        enum: [true],
                      },
                    },
                  },
                },
              },
              dest: {
                properties: {
                  create_new: { enum: [false] },
                },
                required: ['location_dest', 'warehouse_dest', 'apiary_dest'],
              },
            },
          },
          {
            properties: {
              migrate: {
                properties: {
                  data: {
                    properties: {
                      partial: {
                        enum: [false],
                      },
                    },
                  },
                },
              },
              dest: {
                properties: {
                  create_new: { enum: [false] },
                },
                required: ['location_dest', 'warehouse_dest'],
              },
            },
          },
        ],
        properties: {
          date: oschema.properties.date,
          source: {
            type: 'object',
            title: i18n('VIEWS.MODALS.MIGRATORY.Moved apiary'),
            properties: {
              apiary: oschema.properties.apply_to.properties.apiary,
              location_source: oschema.properties.apply_to.properties.location_source,
              warehouse_source: oschema.properties.apply_to.properties.warehouse_source,
            },
            required: ['location_source', 'apiary', 'warehouse_source'],
          },
          migrate: {
            title: i18n('VIEWS.MODALS.MIGRATORY.What have moved ?'),
            type: 'object',
            properties: {
              data: {
                type: 'object',
                properties: {
                  partial: {
                    type: 'boolean',
                    options: {},
                    default: false,
                    widget: 'checkbox',
                    label: 'EVENT.MIGRATORY.Partial migration',
                  },
                  nb_hives: extend(oschema.properties.data.properties.nb_hives, {
                    _default: {
                      value: 'from_entity',
                      entity_path: '/source/apiary',
                      path: 'state.nb_hives',
                    },
                  }),
                  nb_nuc: extend(oschema.properties.data.properties.nb_nuc, {
                    _default: {
                      value: 'from_entity',
                      entity_path: '/source/apiary',
                      path: 'state.nb_nuc',
                    },
                  }),
                  move_all_devices: oschema.properties.data.properties.move_all_devices,
                },
                anyOf: [
                  {
                    properties: {
                      partial: {
                        enum: [true],
                      },
                    },
                    required: ['partial', 'nb_hives'],
                  },
                  {
                    properties: {
                      partial: {
                        enum: [false],
                      },
                    },
                    required: ['partial'],
                  },
                ],
              },
              migrated_hives: assign(oschema.properties.apply_to.properties.migrated_hives, {
                options: {
                  multiple: true,
                  nullable: true,
                  readonly_if: {
                    path: '/migrate/data/partial',
                    value: [false],
                  },
                  _default: '_all',
                  with_in: {
                    from: '/source/apiary',
                    path: 'state.hive_ids',
                  },
                },
              }),
            },
            visibleIf: {
              oneOf: [
                {
                  '/source': ['$EXP$ target.value.apiary != null'],
                },
              ],
            },
            required: ['migrated_hives', 'data'],
          },
          dest: {
            type: 'object',
            title: i18n('VIEWS.MODALS.MIGRATORY.Destination'),
            required: ['location_dest', 'warehouse_dest', 'create_new'],
            properties: {
              location_dest: oschema.properties.apply_to.properties.location_dest,
              warehouse_dest: oschema.properties.apply_to.properties.warehouse_dest,
              create_new: {
                type: 'boolean',
                widget: 'hidden',
                default: false,
              },
              apiary_dest: oschema.properties.apply_to.properties.apiary_dest,
              new_apiary: {
                type: 'object',
                visibleIf: {
                  create_new: [true],
                },
                properties: {
                  name: {
                    label: i18n('VIEWS.MODALS.MIGRATORY.New apiary name'),
                    type: 'string',
                  },
                },
                required: ['name'],
              },
            },
          },
        },
      };

      if (this._is_update_of_event$$.getValue()) {
        if (!schema.properties.date.options) {
          schema.properties.date.options = {};
        }
        schema.properties.date.options.readonly = true;
        schema.properties.source.properties.apiary.options.readonly = true;
        schema.properties.dest.properties.location_dest.options.readonly = true;
      }

      this._schema$$.next(schema);

      if (this._is_update_of_event$$.getValue()) {
        this.ignore_default_form = true;
      }

      this.last_form_model = this.getFullModelFromEvent();
      this.form_model = cloneDeep(this.last_form_model);

      this.loading = false;
      this.initial_loading = false;
      this.form_schema = schema;
    });
  }

  private getFullModelFromEvent(): any {
    const event_model = this.event.export() as any;
    const model = {
      date: event_model.date,
      source: {
        apiary: event_model.apply_to.apiary,
        location_source: event_model.apply_to.location_source,
        warehouse_source: event_model.apply_to.warehouse_source,
      },
      migrate: {
        data: event_model.data,
        migrated_hives: event_model.apply_to.migrated_hives,
      },
      dest: {
        location_dest: event_model.apply_to.location_dest,
        apiary_dest: this.update_new_apiary ? null : event_model.apply_to.apiary_dest,
        warehouse_dest: event_model.apply_to.warehouse_dest,
        create_new: this.update_new_apiary,
        new_apiary: {
          name: clone(this._new_apiary$$.getValue().name),
        },
      },
    };

    return cloneDeep(model);
  }

  public onFormChange(event: any): void {
    const new_model = event.value;
    if (isNil(this.event)) {
      return;
    }

    if (isNil(new_model)) {
      return;
    }

    if (isEqual(this.last_form_model, new_model)) {
      return;
    }

    if (this.ignore_default_form) {
      if (this.ignore_default_form_to) {
        clearTimeout(this.ignore_default_form_to);
      }

      this.ignore_default_form_to = setTimeout(() => {
        this.form_model = cloneDeep(this.last_form_model);
        this.ignore_default_form = false;
      }, 3);
    } else {
      if (!isNil(new_model.migrate)) {
        const should_create_new = new_model.migrate.data.partial && new_model.dest.location_dest && isNil(new_model.dest.apiary_dest);

        // Update event (and new apiary if needed)
        this.last_form_model = new_model;
        this.updateEventFromModels(new_model);

        if (!isNil(should_create_new) && should_create_new !== new_model.dest.create_new) {
          // Incohérence dans les dones recu, soit:
          // - should_create_new & !create_new ==> cad on affiche le nouvel apiary (create_new) mais on ne doit pas en avoir
          // - !should_create_new & create_new ==> cad n'on affiche pas le nouvel apiary (!create_new) mais on devrais
          // const fmodel = _.cloneDeep(this.last_form_model);
          const fmodel = this.getFullModelFromEvent();
          fmodel.dest.create_new = should_create_new;
          fmodel.migrate.data.partial = new_model.migrate.data.partial;

          if (!should_create_new && !isNil(new_model.dest.apiary_dest)) {
            // Si pas de new apiary, et que l'on a un apiary dest connu alors on le recupere bien
            fmodel.dest.apiary_dest = new_model.dest.apiary_dest;
          }

          this.form_model = fmodel;
        }
      }
    }
  }

  protected updateEventFromModels(model: any): void {
    if (!model.date || !model.source || !model.source.apiary) {
      return;
    }

    const date: Date = model.date;
    const data = model.migrate.data;
    const has_new_apiary = this.last_form_model.dest.create_new;
    const apply_to = {
      apiary: model.source.apiary,
      location_source: model.source.location_source,
      warehouse_source: model.source.warehouse_source,
      // apiary_dest: (has_new_apiary) ? this.new_apiary.id : model.dest.apiary_dest,
      apiary_dest: model.dest.apiary_dest,
      location_dest: model.dest.location_dest,
      warehouse_dest: model.dest.warehouse_dest,
      migrated_hives: model.migrate.migrated_hives,
    };

    // Update event
    this.event.update(date, apply_to, data);

    // Update new apiary
    if (has_new_apiary && model.dest.new_apiary) {
      this.new_apiary.name = model.dest.new_apiary.name;
    }
  }

  public submit(): void {
    if (this.loading) {
      return;
    }

    this.error = null;
    this.loading = true;

    // Build the agreement pipe
    let agreement_pipe: Observable<boolean> = of<boolean>(true);

    // Add future date confirmation
    if (isAfter(new Date(this.event.date), endOfDay(new Date()))) {
      agreement_pipe = agreement_pipe.pipe(
        switchMap(last_agreement =>
          last_agreement
            ? this._dialogs.confirm(
                i18n<string>(`VIEWS.MODALS.FORM.The date you've picked is in the future. Are you sure this is the date you want to use ?`),
                {
                  onFalseMessage: i18n<string>('VIEWS.MODALS.FORM.Cancel'),
                  onTrueMessage: i18n<string>('VIEWS.MODALS.FORM.Confirm'),
                }
              )
            : of(false)
        )
      );
    }

    // Different cases:
    //  - !update:
    //    - create new event, no new apiary
    //    - create new event, with new apiary (has_new_apiary, cancel if fail)
    //  - update:
    //    - !update_new_apiary:
    //      - update event, no new apiary
    //      - update event, new apiary (has_new_apiary, cancel if fail)
    //    - update_new_apiary
    //      - update event, delete new apiary
    //      - update event, update new apiary (has_new_apiary)

    const has_new_apiary = this.last_form_model.dest.create_new;

    let submission_pipe: Observable<any> = of(-1);

    // Manage apiary creation or update if needed ?
    if (has_new_apiary) {
      submission_pipe = this.new_apiary.save().pipe(map(res => res.entity.id));
    }

    // Save the event
    submission_pipe = submission_pipe.pipe(
      tap(new_apiary_id => {
        if (new_apiary_id > 0) {
          this.event.setOperand('apiary_dest', new_apiary_id);
        }
      }),
      switchMap(() => this.event.save())
    );

    // Delete ne more use "old" new apiary
    if (!has_new_apiary && this._is_update_of_event$$.getValue() && this.update_new_apiary) {
      submission_pipe = submission_pipe.pipe(
        switchMap(() => this.new_apiary.delete()),
        map(() => -1)
      );
    }

    if (has_new_apiary && !this.update_new_apiary) {
      submission_pipe = submission_pipe.pipe(
        catchError((val: unknown) => {
          if (!this._is_update_of_event$$.getValue() && this.new_apiary.id >= 0) {
            return this.new_apiary.delete().pipe(switchMap(() => throwError(val)));
          }

          return throwError(val);
        })
      );
    }

    // Run update event
    agreement_pipe
      .pipe(
        switchMap(has_agreed => {
          if (has_agreed) {
            return submission_pipe.pipe(map(() => true));
          }

          return of(false);
        }),
        catchErrorInDialog(this._dialogs)
      )
      .subscribe({
        next: agreement => {
          // Update loading flags
          this.loading = false;
          this.initial_loading = false;

          // Change the url if needed
          if (agreement) {
            if (!this._is_update_of_event$$.getValue()) {
              const params = clone(this.input_params);
              params.eid = this.event.id;
              this.input_params = params;

              this.modals_service.changeCurrentParams({ eid: this.event_id });
            }

            this.close();
          }
        },
        error: (err: unknown) => {
          this.loading = false;
          this.initial_loading = false;
          this.handleHttpError(err);
        },
      });
  }

  public logErrors(errors: any): void {
    if (errors !== null && errors !== undefined && !this.ignore_default_form) {
      this._logger.log_error('ERRORS', errors);
    }
  }

  // #endregion
}
