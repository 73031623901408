<div class="dialog-body">
  <ng-container *ngIf="content$$ | async; else noContent">
    <bg2-dialog-div *ngIf="body_div$$ | async; let body" [content]="body" (action)="onClickButton($event)" (form_values)="onFormValues($event)">
    </bg2-dialog-div>
  </ng-container>
  <ng-template #noContent>
    <ng-content></ng-content>
  </ng-template>
</div>

<!-- Separate body from footer -->
<hr *ngIf="show_hr" style="border-color: transparent transparent rgba(0, 0, 0, 0.1) transparent" />
