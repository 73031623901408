import { Component, OnInit, OnDestroy, ChangeDetectionStrategy } from '@angular/core';

import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';
import { distinctUntilChanged, map, startWith, tap } from 'rxjs';
import { distinctUntilRealChanged, replay } from '@bg2app/tools/rxjs';
import { of, Subscription, combineLatest, BehaviorSubject, Observable, concat as rxjs_concat } from 'rxjs';

import { concat, cloneDeep, every, isNil, keys, pick } from 'lodash-es';

import { marker as i18n } from '@biesbjerg/ngx-translate-extract-marker';

import { Beeguard2Api } from 'app/core';
import { ConsoleLoggerService } from 'app/core/console-logger.service';
import { ModalsService } from 'app/widgets/dialogs-modals/modals.service';
import { DialogsService } from 'app/widgets/dialogs-modals/dialogs.service';

import { TrackOptions } from '../eventforms.helpers';
import { Dictionary } from 'app/typings/core/interfaces';
import { FastVisitOutput, FieldConfig, FieldValueConfig, VisitValues } from './models';

import { EfObjectWidgetComponent, ObjectWidgetOptions } from '../object/object.widget';
import { FastVisitDialogComponent, FastVisitDialogParams, isFieldActif } from './fastvisit-dialog/fastvisit-dialog.component';

export interface FastVisitOptions extends ObjectWidgetOptions {
  fields: string[];
  hive_types: ('nuc' | 'hive')[];
  nb_hives_from: TrackOptions;
  change_nb_hives: {
    config: {
      // The evaluation config to change
      path: string; // config property to change
      add: string; // value to add in this config property value
    };
    value_path: string; // value of the nb hives field
  };
  nb_nuc_from: TrackOptions;
  change_nb_nuc: {
    config: {
      // The evaluation config to change
      path: string; // config property to change
      add: string; // value to add in this config property value
    };
    value_path: string; // value of the nb nuc field
  };
}

enum VisitState {
  READY = 'READY',
  VISIT = 'VISIT',
  DONE = 'DONE',
}

interface EvaluationType extends Dictionary<boolean> {
  nuc: boolean;
  hive: boolean;
}

@AutoUnsubscribe()
@Component({
  selector: 'bg2-bg2fastvisit',
  templateUrl: './bg2fastvisit.widget.html',
  styleUrls: ['./bg2fastvisit.widget.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EfBg2FastvisitWidgetComponent extends EfObjectWidgetComponent implements OnInit, OnDestroy {
  private _logger = new ConsoleLoggerService();

  // /**
  //  * See https://gitlab.dev.siconsult.fr:9090/beeguard_v2/beeguard2-ng-app/issues/25
  //  *
  //  * TODO
  //  * - DONE avoir le nombre de ruche
  //  * - avoir etat courant (avant la visite)
  //  * - DONE gestion so status !OK pas besoin de autres valeurs (pas si simple, a voir...)
  //  * - DONE gestion maj avec d'autres fields,
  //  *         si en moins => on les vire direct
  //  *         si en plus => on doit redémarer visite depuis debut... mais compté aussi les null !
  //  * - DONE changer gestion valide => juste si dernier == default
  //  *
  //  * Etats possible:
  //  * - loading, wait to get apiary data (nbh)
  //  * - empty (new event before visit)
  //  * - visit_on
  //  * - with_data
  //  */

  public fullscreen = window.innerWidth <= 1200 || window.innerHeight <= 800;

  public options: FastVisitOptions = {
    transparent: true,
    img: null,
    fields: [],
    hive_types: ['hive'],
    nb_hives_from: null,
    change_nb_hives: null,
    nb_nuc_from: null,
    change_nb_nuc: null,
    title_style: '',
  };

  // #region -> (visit state)

  public readonly VISIT_STATE = VisitState;

  private _state$: BehaviorSubject<VisitState> = new BehaviorSubject(VisitState.READY);
  public state$$: Observable<VisitState> = this._state$.asObservable().pipe(distinctUntilChanged(), replay());
  private set state(val: VisitState) {
    this._state$.next(val);
  }
  private get state(): VisitState {
    return this._state$.getValue();
  }

  // #endregion

  // #region -> (evaluation type)

  private _evaluation_type$: BehaviorSubject<EvaluationType> = new BehaviorSubject({
    nuc: false,
    hive: false,
  });
  public evaluation_type$$: Observable<EvaluationType> = this._evaluation_type$.asObservable().pipe(distinctUntilChanged(), replay());

  // #endregion

  // #region -> (component basics)

  protected _value_sub: Subscription = null;
  protected _nbh_value_sub: Subscription = null;

  private _nb_hive$: BehaviorSubject<number> = new BehaviorSubject(0);
  public nb_hive$$: Observable<number> = this._nb_hive$.asObservable().pipe(distinctUntilChanged(), replay());

  private _nb_nuc$: BehaviorSubject<number> = new BehaviorSubject(0);
  public nb_nuc$$: Observable<number> = this._nb_nuc$.asObservable().pipe(distinctUntilChanged(), replay());

  private _loading$: BehaviorSubject<boolean> = new BehaviorSubject(true);
  public loading$$: Observable<boolean> = this._loading$.asObservable().pipe(distinctUntilChanged(), replay());

  constructor(protected _bg2Api: Beeguard2Api, private _dialogs: DialogsService, private modalsMngt: ModalsService) {
    super(_bg2Api);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.buildFieldsConfig();

    this._loading$.next(true);
    this._evaluation_type$.next({
      hive: this.options.hive_types.includes('hive'),
      nuc: this.options.hive_types.includes('nuc'),
    });

    this._nbh_value_sub = combineLatest([
      this.trackValue(this.bg2Api, this.options.nb_hives_from).pipe(startWith(0)),
      this.trackValue(this.bg2Api, this.options.nb_nuc_from).pipe(startWith(0)),
    ]).subscribe((val: any) => {
      this._nb_hive$.next(val[0] || 0);
      this._nb_nuc$.next(val[1] || 0);
      this._loading$.next(false);
    });

    // Reset visit state from intial value
    this._value_sub = rxjs_concat(of(this.formProperty.value), this.formProperty.valueChanges)
      .pipe(
        tap(value => {
          // Note: important to do that before distinctUntilRealChanged
          // elsewhere may have issue when back from ialog and nothing changed
          if (value.result && value.visit_history?.length > 0) {
            this.state = VisitState.DONE;
          } else {
            this.state = VisitState.READY;
          }
        }),
        distinctUntilRealChanged(),
        map((value: FastVisitOutput) => {
          const nvalue = cloneDeep(value);
          if (nvalue.result) {
            nvalue.result = pick(nvalue.result, this.fields_order);
          }
          if (nvalue.visit_history) {
            nvalue.visit_history = nvalue.visit_history.map((visit: any) => pick(visit, this.fields_order));
          }
          return nvalue;
        })
      )
      .subscribe(
        (value: FastVisitOutput) => {
          this._visit_results$$.next(value?.result || {});
          this._visit_history$$.next(value?.visit_history || []);
          this._visit_idx$$.next(value?.visit_idx || 0);
        },
        (error: unknown) => this._logger.error(error)
      );
  }

  ngOnDestroy(): void {}

  // #endregion

  // #region -> (visit things)

  private _nb_elements_hive_or_nuc_ref = 0;
  public nb_elements_hive_or_nuc$$ = combineLatest([this.nb_hive$$, this.nb_nuc$$, this.evaluation_type$$]).pipe(
    map(([nbh, nbn, evaluation_type]) => {
      let nb_elements = 0;
      if (evaluation_type.hive) {
        nb_elements += nbh || 0;
      }
      if (evaluation_type.nuc) {
        nb_elements += nbn || 0;
      }
      return nb_elements;
    }),
    tap(nbhn => (this._nb_elements_hive_or_nuc_ref = nbhn))
  );

  private _visit_idx$$ = new BehaviorSubject<number>(0);
  public visit_idx$$ = this._visit_idx$$.pipe(distinctUntilChanged(), replay());

  private _visit_history$$ = new BehaviorSubject<any[]>([]);
  public visit_history$$ = this._visit_history$$.asObservable().pipe(replay());

  private _visit_results$$ = new BehaviorSubject<any>({});
  public visit_results$$ = this._visit_results$$.asObservable().pipe(replay());

  public nbh_after_visit$$: Observable<number> = combineLatest([this.visit_history$$, this.visit_idx$$]).pipe(
    map(([history, max_seen_visit_idx]) => {
      const nb_valid_stored_visit = history.filter(visit => this._isValid(visit)).length;
      return Math.min(nb_valid_stored_visit, max_seen_visit_idx);
    })
  );

  public changeHiveNumber(new_nbh: number): void {
    const ihive = this.options.hive_types.includes('hive');
    const inuc = this.options.hive_types.includes('nuc');

    if (isNil(new_nbh) || new_nbh === this._nb_elements_hive_or_nuc_ref) {
      return;
    }

    let _new_nbh;
    let _new_nbn;

    if (inuc && !ihive) {
      _new_nbn = new_nbh;
    } else if (!inuc && ihive) {
      _new_nbh = new_nbh;
    } else {
      _new_nbh = new_nbh - this._nb_nuc$.getValue();
      if (_new_nbh < 0) {
        _new_nbn = new_nbh;
        _new_nbh = 0;
      }
    }

    // Update nb hive if needed
    if (!isNil(_new_nbh)) {
      const conf = this.options.change_nb_hives;
      const option_prop = this.robustSearchProperty(conf.config.path);
      if (option_prop && !option_prop.value.includes(conf.config.add)) {
        option_prop.setValue(concat(option_prop.value, [conf.config.add]), false);
      }

      const nb_hives_prop = this.robustSearchProperty(conf.value_path);
      if (nb_hives_prop) {
        nb_hives_prop.setValue(_new_nbh, false);
      }
    }

    // Update nb nuc if needed
    if (!isNil(_new_nbn)) {
      const conf = this.options.change_nb_nuc;
      const option_prop = this.robustSearchProperty(conf.config.path);
      if (option_prop && !option_prop.value.includes(conf.config.add)) {
        option_prop.setValue(concat(option_prop.value, [conf.config.add]), false);
      }

      const nb_hives_prop = this.robustSearchProperty(conf.value_path);
      if (nb_hives_prop) {
        nb_hives_prop.setValue(_new_nbn, false);
      }
    }
  }

  public startVisit(): void {
    this.state = VisitState.VISIT;
    this.modalsMngt.setHasSubModals(true);
    this._dialogs
      .open<FastVisitDialogParams, FastVisitOutput>(
        FastVisitDialogComponent,
        {
          initial_value: this.formProperty?.value,
          fields: {
            config: this.fields_config,
            order: this.fields_order,
          },
          status: {
            nb_elements: this._nb_elements_hive_or_nuc_ref || 0,
          },
        },
        'modal-overlay'
      )
      .subscribe(visit_dialog_result => {
        // this._logger.debug('visit_dialog_result', visit_dialog_result);
        this.formProperty.setValue(visit_dialog_result, false);
        this.modalsMngt.setHasSubModals(false);
      });
  }

  // #endregion

  // #region -> (checkers)

  private _isValid(visit: VisitValues): boolean {
    if (isNil(visit)) {
      return false;
    }
    return every(this.fields_order.map(field_name => !isFieldActif(this.fields_config[field_name], visit) || !isNil(visit[field_name])));
  }

  // #endregion

  // #region -> (schema handlers)
  public fields_config: Dictionary<FieldConfig>;

  public fields_order: string[];

  private buildFieldsConfig(): void {
    const result_schema = this.formProperty.schema.properties.result;
    const visit_schema = this.formProperty.schema.properties.visit_history;

    this.fields_order = this.options.fields || visit_schema.items.order || keys(visit_schema.items.properties);

    const fields_config: Dictionary<FieldConfig> = {};

    this.fields_order.map((field_name: string) => {
      const field_schema = visit_schema.items.properties[field_name] || {};
      const field_result_schemas = result_schema.properties[field_name] || { properties: {} };

      if (field_result_schemas.properties) {
        const field_options = field_schema.options || {};
        const field_res_options = field_result_schemas.options || {};
        const fconf: FieldConfig = {
          name: field_name,
          size: 0,
          values: [],
          default: field_schema.default,
          label: field_schema.label || field_name,
          color: field_res_options.color || 'red',
          actif_if: field_options.actif_if || {},
        };

        if (field_options.img) {
          fconf.img = field_options.img;
        }

        const values_keys = field_options.values || keys(field_result_schemas.properties);
        const values = values_keys.map((value: any) => {
          const value_schema = field_result_schemas.properties[value];
          const value_conf: FieldValueConfig = {
            value,
            label: value_schema.label || value,
          };

          if (value_schema.options && value_schema.options.img) {
            value_conf.img = value_schema.options.img;
          }

          return value_conf;
        });

        fconf.values = values;
        fconf.size = values.length;
        fields_config[field_name] = fconf;
      }
    });
    this.fields_config = fields_config;
  }

  // #endregion

  // #region -> (modal management)

  public clearVisit(): void {
    this._dialogs
      .confirm(i18n<string>('VIEWS.MODALS.VISIT.Are you sure you want to reset previous visit counts ?'), {
        onTrueMessage: i18n<string>('VIEWS.MODALS.FORM.Reset'),
        onFalseMessage: i18n<string>('VIEWS.MODALS.FORM.Cancel'),
      })
      .subscribe(isAgreement => {
        if (isAgreement) {
          this.formProperty.reset({ result: {}, visit_history: [], visit_idx: 0 }, false);
        }
      });
  }

  // #endregion

  public warning$$ = combineLatest([this.evaluation_type$$, this.nb_hive$$, this.nb_nuc$$]).pipe(
    map(([type, nb_hives, nb_nuc]: [EvaluationType, number, number]) => {
      const warning = { show: false, label: 'DEFAULT' };
      const i18n_missing_nuc = i18n<string>(
        'VIEWS.MODALS.APIARY_EVALUATION.You will start a quick visit without nucs, the final number of nucs will be automatically updated'
      );
      const i18n_missing_hive = i18n<string>(
        'VIEWS.MODALS.APIARY_EVALUATION.You will start a quick visit without hives, the final number of hives will be automatically updated'
      );
      const i18n_missing_both = i18n<string>(
        'VIEWS.MODALS.APIARY_EVALUATION.You will start a quick visit without hives nor nucs, the final number of hives and nucs will be automatically updated'
      );

      nb_hives = nb_hives || 0;
      nb_nuc = nb_nuc || 0;

      if (type.hive && type.nuc) {
        if (!(nb_hives > 1 && nb_nuc > 1)) {
          warning.show = true;
          if (nb_hives === 0 && nb_nuc === 0) {
            warning.label = i18n_missing_both;
          } else if (nb_hives === 0 && nb_nuc > 0) {
            warning.label = i18n_missing_hive;
          } else {
            warning.label = i18n_missing_nuc;
          }
        }
      } else if (type.hive && !type.nuc) {
        if (nb_hives === 0) {
          warning.show = true;
          warning.label = i18n_missing_hive;
        }
      } else if (!type.hive && type.nuc) {
        if (nb_nuc === 0) {
          warning.show = true;
          warning.label = i18n_missing_nuc;
        }
      } else {
        // Evaluates nothing, should not happening
      }

      return warning;
    })
  );
}
