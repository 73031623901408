<bg2-modal [fullscreen]="fullscreen || false" #modal>
  <bg2-modal-header>
    <div class="header">
      <span class="mdi mdi-lifebuoy mdi-36px"></span>
      <span class="title">{{ (title$$ | async) || ('VIEWS.MODALS.USER_MANUAL.User manual' | translate)}}</span>
    </div>

    <div class="action-buttons">
      <span class="mdi mdi-{{ (url_stack.size() < 2 ? 'close-box' : 'arrow-left') }} mdi-36px" (click)="close()"></span>
    </div>
  </bg2-modal-header>

  <bg2-modal-content>
    <div class="loading" [class.initial]="initial_loading$$ | async" *ngIf="loading$$ | async">
      <hex-loading [size]="(initial_loading$$ | async) ? 'medium' : 'small'"></hex-loading>
    </div>

    <div *ngIf="url$$ | async; let url" class="iframe-holder">
      <iframe #userManualFrame [src]="url" (load)="onIframeLoaded()"></iframe>
    </div>
  </bg2-modal-content>
</bg2-modal>
