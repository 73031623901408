import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { MatBottomSheetRef, MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';

import { AbstractFilterMngt } from 'app/models/filters';

@Component({
  selector: 'bg2-pull-down-filters-bottom-sheet',
  templateUrl: './pull-down-filters-bottom-sheet.component.html',
  styleUrls: ['./pull-down-filters-bottom-sheet.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PullDownFiltersBottomSheetComponent {
  // #region -> (component basics)

  constructor(
    @Inject(MAT_BOTTOM_SHEET_DATA) private data: { filters: AbstractFilterMngt<unknown> },
    private readonly _selfSheetRef: MatBottomSheetRef<PullDownFiltersBottomSheetComponent>
  ) {}

  /** */
  public close_sheet(): void {
    this._selfSheetRef.dismiss();
  }

  // #endregion

  public get filters(): AbstractFilterMngt<unknown> {
    return this.data.filters;
  }
}
