<bg2-modal class="welcome-modal" [fullscreen]="fullscreen || false" (close)="close()" #modal>
  <bg2-modal-header>
    <div class="header">
      <span class="title">
        <ng-container *ngIf="{ n: page$$ | async } as page">
          <ng-container *ngIf="page.n === 0">{{ 'VIEWS.MODALS.WELCOME.Welcome to BeeGuard application' | translate }}</ng-container>
          <ng-container *ngIf="page.n === 1">{{ 'VIEWS.MODALS.WELCOME.Manage easily your locations' | translate }}</ng-container>
          <ng-container *ngIf="page.n === 2">{{ 'VIEWS.MODALS.WELCOME.All the informations in one page' | translate }}</ng-container>
          <ng-container *ngIf="page.n === 3">{{ 'VIEWS.MODALS.WELCOME.A map page to see locations and devices' | translate }}</ng-container>
          <ng-container *ngIf="page.n === 4">{{ 'VIEWS.MODALS.WELCOME.Explore and compare every data' | translate }}</ng-container>
          <ng-container *ngIf="page.n === 5">{{ 'VIEWS.MODALS.WELCOME.It\'s only the start, ask us for a demo' | translate }}</ng-container>
        </ng-container>
      </span>
    </div>
    <div class="action-buttons">
      <span class="mdi mdi-close-box mdi-36px" (click)="close()"></span>
    </div>
  </bg2-modal-header>

  <bg2-modal-content>
    <ng-container *ngIf="{ n: page$$ | async } as page">
      <!-- Links to change current page -->
      <div *ngIf="page.n < PAGES.length - 1" class="link next" [ngStyle]="" (click)="next_page()">
        <i class="mdi mdi-chevron-right mdi-36px"></i>
      </div>

      <div *ngIf="page.n > 0" class="link previous" [ngStyle]="" (click)="previous_page()">
        <i class="mdi mdi-chevron-left mdi-36px"></i>
      </div>

      <!-- ############# PAGE 0 ############# -->
      <div class="page" *ngIf="page.n === 0">
        <img [src]="logo" alt="logo" class="img-small" style="background-color: initial" />
        <p class="title"> {{ 'VIEWS.MODALS.WELCOME.Before starting, see main features with this short presentation' | translate }} </p>
        <div>
          <img class="starter-image" src="assets/img/arrow_2.png" alt="" (click)="next_page()" />
        </div>
      </div>

      <!-- ############# PAGE 1 ############# -->
      <div class="page" *ngIf="page.n === 1">
        <div class="row">
          <div class="col s12 m6">
            <img src="assets/help/Emplacement.gif" alt="" />
          </div>

          <div class="col s12 m6">
            <p style="font-weight: bold" translate>VIEWS.MODALS.WELCOME.Create locations to:</p>
            <ul>
              <li translate>VIEWS.MODALS.WELCOME.Manage easily apiary and hives by location</li>
              <li translate>VIEWS.MODALS.WELCOME.Migrate an apiary from a location to another location</li>
            </ul>

            <p style="font-weight: bold" translate>VIEWS.MODALS.WELCOME.No worry, you can even create locations on: </p>
            <ul>
              <li translate>VIEWS.MODALS.WELCOME.Those that don't currently have hives</li>
              <li translate>VIEWS.MODALS.WELCOME.Those that are not equipped with sensors</li>
            </ul>
          </div>
        </div>
      </div>

      <!-- ############# PAGE 2 ############# -->
      <div class="page" *ngIf="page.n === 2">
        <div class="row">
          <div class="col s12 m6">
            <img src="assets/help/tableau_bord_{{appState.lang}}.gif" alt="" />
          </div>

          <div class="col s12 m6">
            <p style="font-weight: bold" translate>VIEWS.MODALS.WELCOME.In one page, see directly:</p>
            <ul>
              <li translate>VIEWS.MODALS.WELCOME.Informations about the apiary</li>
              <li translate>VIEWS.MODALS.WELCOME.Last received data (30 days and 7 days)</li>
              <li translate>VIEWS.MODALS.WELCOME.Movements alarms with mapping</li>
              <li translate>VIEWS.MODALS.WELCOME.A list of equipped sensors with their statuses</li>
            </ul>
          </div>
        </div>
      </div>

      <!-- ############# PAGE 3 ############# -->
      <div class="page" *ngIf="page.n === 3">
        <div class="row">
          <div class="col s12 m6">
            <img src="assets/help/carte.gif" alt="" />
          </div>

          <div class="col s12 m6">
            <p style="font-weight: bold" translate>VIEWS.MODALS.WELCOME.Use the map to:</p>
            <ul>
              <li translate>VIEWS.MODALS.WELCOME.Create instantly a new location</li>
              <li translate>VIEWS.MODALS.WELCOME.See your apiary at a precise location</li>
              <li translate>VIEWS.MODALS.WELCOME.See at the same time your locations and devices</li>
            </ul>
          </div>
        </div>
      </div>

      <!-- ############# PAGE 4 ############# -->
      <div class="page" *ngIf="page.n === 4">
        <div class="row">
          <div class="col s12 m6">
            <img src="assets/help/donnees.gif" alt="" />
          </div>

          <div class="col s12 m6">
            <p style="font-weight: bold" translate>VIEWS.MODALS.WELCOME.A page only for the data to:</p>
            <ul>
              <li translate>VIEWS.MODALS.WELCOME.Watch data for each sensor, location or apiary</li>
              <li translate>VIEWS.MODALS.WELCOME.Compare data between multiple sensors, locations or apiaries</li>
              <li translate>VIEWS.MODALS.WELCOME.See old data by customizing displayed data</li>
              <li translate>VIEWS.MODALS.WELCOME.Export configured data in one CSV or EXCEL file</li>
            </ul>
          </div>
        </div>
      </div>

      <!-- ############# PAGE 5 ############# -->
      <div class="page" *ngIf="page.n === 5">
        <p>
          <span>
            {{'VIEWS.MODALS.WELCOME.New features are currently being developped with the help of beekeepers and beta-testers' | translate
            }}. {{'VIEWS.MODALS.WELCOME.Thanks to them' | translate}}.
          </span>
        </p>

        <p class="title compact" translate>VIEWS.MODALS.WELCOME.You want a online demo, please contact-us</p>

        <img
          class="img_centre"
          src="assets/help/webcast.png"
          alt="Webcast, formation en ligne, démonstration en ligne"
          style="background-color: initial; width: initial; max-height: 200px"
        />

        <div>
          <bg2-button
            [type]="'action'"
            [icon]="'mdi-email'"
            [message]="'ALL.MENU.Contact' | translate"
            [routerLink]="['', { outlets: { 'modal': ['new_issue'] } }]"
            [queryParamsHandling]="'preserve'"
          ></bg2-button>
        </div>

        <div class="row socnet">
          <div class="col s12"> <span translate>VIEWS.MODALS.WELCOME.Suivez nous sur les réseaux sociaux:</span><br /> </div>

          <div class="col s6">
            <span id="twitter-follow-btn"></span>
            <div *ngIf="!(is_twitter_opened$$ | async)" class="btn-rs btn-tw" contextmenu="menu" data-scribe="component:button">
              <a
                id="follow-button"
                class="btnx"
                title="Follow BeeGuard (@mybeeguard) on Twitter"
                target="_blank"
                href="https://twitter.com/intent/follow?original_referer=http%3A%2F%2Fapp.beeguard.test%3A4200%2Flocations%2F11(modal%3Awelcome)&amp;ref_src=twsrc%5Etfw&amp;region=follow_link&amp;screen_name=mybeeguard&amp;tw_p=followbutton"
              >
                <i class="mdi mdi-twitter mdi-18px"></i>
                <span class="label" id="l">Follow <b>@mybeeguard</b></span>
              </a>
              <script async src="https://platform.twitter.com/widgets.js" charset="utf-8"></script>
            </div>
          </div>

          <!-- <div class="col s6">
            <iframe
              src="https://www.facebook.com/plugins/like.php?href=https%3A%2F%2Fwww.facebook.com%2FBeeGuard%2F&width=194&layout=button_count&action=like&size=large&show_faces=false&share=true&height=46&appId"
              width="194"
              height="46"
              style="border: none; overflow: hidden"
              scrolling="no"
              frameborder="0"
              allowTransparency="true"
              allow="encrypted-media"
            ></iframe>
          </div> -->
        </div>
      </div>
    </ng-container>

    <mat-checkbox [ngModel]="no_more$$ | async" (ngModelChange)="set_no_more($event)" [color]="'primary'">
      {{ 'VIEWS.MODALS.WELCOME.No more show it' | translate }}
    </mat-checkbox>
  </bg2-modal-content>

  <bg2-modal-footer>
    <div class="progress-status">
      <span *ngFor="let index of PAGES">
        <a (click)="set_page_number(index)" href="javascript: void(0);">
          <ng-container *ngIf="{ n: page$$ | async } as page">
            <i *ngIf="index!==page.n" class="mdi mdi-radiobox-blank" style="color: #f5b54a"></i>
            <i *ngIf="index===page.n" class="mdi mdi-radiobox-marked" style="color: #f5b54a"></i>
          </ng-container>
        </a>
      </span>
    </div>

    <bg2-button [type]="'cancel'" [message]="'VIEWS.MODALS.WELCOME.Close' | translate" (btn_click)="close()"></bg2-button>
  </bg2-modal-footer>
</bg2-modal>
