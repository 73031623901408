<div class="widget form-group">
  <div class="array">
    <form-element-header [schema]="schema"></form-element-header>

    <div *ngFor="let itemProperty of $any(formProperty).properties" [class.inline]="options.remove_btn_inline" class="subproperty">
      <sf-form-element [formProperty]="itemProperty"></sf-form-element>

      <ng-container *ngIf="options?.show_remove">
        <bg2-button
          *ngIf="!(schema.hasOwnProperty('minItems') && schema.hasOwnProperty('maxItems') && schema.minItems === schema.maxItems)"
          [type]="'delete'"
          class="array-remove-button"
          [icon]="'mdi-minus-box'"
          [message]="label_remove | translate"
          [class.inline]="options.remove_btn_inline"
          [disabled]="isRemoveButtonDisabled()"
          (btn_click)="removeItem(itemProperty)"
        >
        </bg2-button>
      </ng-container>
    </div>

    <ng-container *ngIf="options?.show_add">
      <bg2-button
        *ngIf="!(schema.hasOwnProperty('minItems') && schema.hasOwnProperty('maxItems') && schema.minItems === schema.maxItems)"
        [type]="'action'"
        class="array-add-button"
        [icon]="'mdi-plus-box'"
        [disabled]="isAddButtonDisabled()"
        [message]="((is_empty) ? label_add_first : label_add) | translate"
        (btn_click)="addItem()"
      >
      </bg2-button>
    </ng-container>
  </div>
</div>
