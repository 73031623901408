import { Component, OnInit, AfterViewInit, ChangeDetectionStrategy, OnDestroy } from '@angular/core';
import { findKey, isEmpty, isEqual, isNil, isNumber, max, uniqueId, values } from 'lodash-es';

import { combineLatest, from, concat, of, merge, Subscription, BehaviorSubject, Observable } from 'rxjs';
import { debounceTime, map, switchMap } from 'rxjs';

import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';

import {
  setHours,
  getHours,
  getMinutes,
  setMinutes,
  startOfYear,
  addSeconds,
  isAfter,
  endOfDay,
  startOfDay,
  endOfYear,
  differenceInMonths,
} from 'date-fns';

import { parseDate } from 'app/misc/tools';

import { distinctUntilRealChanged } from '@bg2app/tools/rxjs';
import { EfStringWidget as StringWidget, StringWidgetOptions } from '../string/string.widget';
import { replay } from '@bg2app/tools/rxjs';
import { Entity } from 'app/models';
import { getSeconds, setMilliseconds, setSeconds } from 'date-fns/esm';

export interface DateWidgetOptions extends StringWidgetOptions {
  pickerType: string;
  readonly?: boolean;
  output?: null | 'timestamp';

  // Show seconds in time input
  show_seconds?: boolean;
  // TODO add option to activate/unactivate highlight
}

@AutoUnsubscribe()
@Component({
  selector: 'bg2-ef-date-widget',
  templateUrl: './date.widget.html',
  styleUrls: ['./date.widget.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EfDateWidgetComponent extends StringWidget implements OnInit, OnDestroy, AfterViewInit {
  public owl_datatime_picker_id = 'owlDatatimePicler_' + uniqueId();

  private default: any;

  protected value_sub: Subscription;
  protected datetime_sub: Subscription;
  protected min_value_sub: Subscription;
  protected setup_sub: Subscription;

  public options: DateWidgetOptions = {
    pickerType: 'both',
    readonly: false,
    indent: false,
    reset_btn: false,
    output: null,

    show_seconds: false
  };

  private _date$$ = new BehaviorSubject<Date>(null);
  private _time$$ = new BehaviorSubject<Date>(null);

  private set date(date: Date) {
    this._date$$.next(date);
  }

  public setDate(val: Date): void {
    if (!isNil(val)) {
      this.is_default_value = false;
      this.defaultValueUnsubscribe();
      this.date = val;
    }
  }

  private set time(date: Date) {
    this._time$$.next(date);
  }

  public setTime(val: Date): void {
    if (!isNil(val)) {
      this.is_default_value = false;
      this.defaultValueUnsubscribe();
      this.time = val;
    }
  }

  public max: Date;
  private _all_min$$ = new BehaviorSubject<{ [key_role: string]: Date }>({});
  public all_min$$ = this._all_min$$.asObservable();
  private get _all_min(): { [key_role: string]: Date } {
    return this._all_min$$.getValue();
  }
  public min$$ = this.all_min$$.pipe(
    map(all_min => {
      const all_dates = values(all_min).filter(date => !isNil(date));
      if (all_dates.length > 0) {
        return max(all_dates);
      } else {
        return null;
      }
    }),
    distinctUntilRealChanged(),
    replay()
  );
  public min_day$$ = this.min$$.pipe(
    map(min => startOfDay(min)),
    replay()
  );

  public min_role$$: Observable<{ role: string; date: Date }> = this.all_min$$.pipe(
    map(all_minimals => {
      const dates = values(all_minimals).filter(date => !isNil(date));

      if (isEmpty(dates)) {
        return null;
      }

      const max_date = max(dates);
      return {
        role: findKey(all_minimals, date => isEqual(date, max_date)),
        date: max_date,
      };
    }),
    replay()
  );

  public datetime$$ = combineLatest([this._date$$, this._time$$, this.min$$]).pipe(
    debounceTime(30),
    map(([date, time, min]) => {
      if (!isNil(date) && !isNil(time)) {
        let datetime = setHours(date, getHours(time));
        datetime = setMinutes(datetime, getMinutes(time));
        datetime = setSeconds(datetime, getSeconds(time));
        datetime = setMilliseconds(datetime, 0);

        if (!isNil(min)) {
          // Ensure min
          datetime = max([datetime, min]);
        }
        return datetime;
      } else {
        return null;
      }
    }),
    distinctUntilRealChanged(),
    replay()
  );

  public is_in_futur$$ = this.datetime$$.pipe(map(dtime => isAfter(dtime, endOfDay(new Date()))));

  protected update_min(key: string, min: Date): void {
    const all_min = this._all_min;
    all_min[key] = parseDate(min);
    this._all_min$$.next(all_min);
  }

  ngOnInit(): void {
    super.ngOnInit();

    // Bind to value change
    this.value_sub = this.value$$.subscribe(new_date_str => {
      if (new_date_str) {
        const new_date = parseDate(new_date_str);
        this.date = new_date;
        this.time = new_date;
      }
    });
    this.datetime_sub = this.datetime$$.subscribe(ndatetime => {
      if (!isNil(ndatetime)) {
        if (this.options?.output === 'timestamp') {
          this.value = Math.round(ndatetime.getTime() / 1000);
        } else {
          this.value = ndatetime.toISOString();
        }
      }
    });

    // Manage default
    this.default = this.schema._default;

    // Check min/
    const _min = this.schema._min || {};
    if (_min) {
      // From schmela _min
      if (!isNil(_min.value) && _min.value === 'from_entity' && !isNil(_min.path) && !isNil(_min.entity_path)) {
        const entity_path = _min.entity_path;
        const property = this.robustSearchProperty(entity_path);
        if (!isNil(property)) {
          const path = _min.path;
          this.min_value_sub = concat(of(property.value), property.valueChanges).subscribe(entity_id => {
            this.updateMinFromEntity(entity_id, path);
          });
        }
      }
      if (!isNil(_min.value) && _min.value === 'from_property' && !isNil(_min.path)) {
        const property = this.robustSearchProperty(_min.path);
        if (!isNil(property)) {
          const path = _min.path;
          this.min_value_sub = concat(of(property.value), property.valueChanges).subscribe(min_value => {
            this.update_min('from_property', min_value);
          });
        }
      }
    }
    if (this.schema.min) {
      // From schmela statif min
      this.update_min('static_schema', this.schema.min);
    }
    this.updateMinFromSetupDates();
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      // console.log('debug after init', this.formProperty.value, this.value);
      if (!this.value) {
        // console.log('debug no value')
        this.computeDefault();
      } else {
        this.is_default_value = false;
      }
    }, 100);
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  protected computeDefault(): void {
    const now = new Date();
    const default_conf = this.default;
    let default_obs = this.trackValue(this.bg2Api, default_conf);
    if (!isNil(default_obs)) {
      default_obs = default_obs.pipe(
        map(value => {
          if (value) {
            let _date: Date = parseDate(value);
            if (default_conf.value === 'from_entity') {
              // This is needed to ensure we are just after 'from_entity' value
              _date = addSeconds(_date, 1);
            }
            if (
              !isNil(default_conf.options?.less_than_X_month) &&
              differenceInMonths(new Date(), _date) > default_conf.options.less_than_X_month
            ) {
              _date = now;
            }
            return _date;
          }
          return null;
        })
      );
    } else if (default_conf?.value === 'start_of_year') {
      default_obs = from([startOfYear(now)]);
    } else if (default_conf?.value === 'end_of_year') {
      default_obs = from([endOfYear(now)]);
    } else {
      // Now by default
      default_obs = from([now]);
    }

    this.defaultValueUnsubscribe();
    this.default_value_sub = default_obs.subscribe((date: Date) => {
      // console.log('debug, defaiult date?', date, this.is_default_value);
      if (this.is_default_value && !isNil(date)) {
        this.date = date;
        this.time = date;
      }
    });
  }

  protected updateMinFromSetupDates(): void {
    // NOTE: on utilisait des chemin absolu "/apply_to"
    // mais ca pose pb dans le cas des new_entity.
    // Donc chemin relatif.
    // Peut etre il faudra passer a un chemin dépendant du niveau du widget
    /// (les chemins relatif en './truc' ne marchent pas)
    const apply_to = this.robustSearchProperty('apply_to') as any;
    if (isNil(apply_to)) {
      return;
    }

    const all_roles: string[] = apply_to.propertiesId;
    if (isNil(all_roles) || !all_roles.length) {
      return;
    }

    const all_entities_values$ = all_roles.map(role => {
      const path = 'apply_to/' + role;
      const property = this.robustSearchProperty(path);
      return concat(of(property.value), property.valueChanges).pipe(
        switchMap(eid => {
          if (isNil(eid) || !isNumber(eid)) {
            return of(null as Entity);
          } else {
            return this.bg2Api.getEntityObj(eid);
          }
        }),
        map(entity => ({ entity, role }))
      );
    });
    this.setupUnsubscribe();
    this.setup_sub = merge(...all_entities_values$)
      .pipe(
        map(({ entity, role }) => ({
          setup_date: entity?.initial_setup_date,
          role,
        }))
      )
      .subscribe(res => {
        this.update_min(`setupdate_role_${res.role}`, res.setup_date);
      });
  }

  protected entity_sub: Subscription;
  protected updateMinFromEntity(entity_id: any, path: any): void {
    this.entity_sub = this.bg2Api.getEntityObj(entity_id).subscribe(entity => {
      const val = entity.get(path);
      if (val) {
        const _date = parseDate(val);
        this.update_min(`from_${entity_id}`, _date);
      }
    });
  }

  protected unsubscribe(): void {
    super.unsubscribe();
    this.minValueUnsubscribe();
    this.setupUnsubscribe();
  }

  protected setupUnsubscribe(): void {
    this.setup_sub?.unsubscribe();
  }

  private minValueUnsubscribe(): void {
    this.min_value_sub?.unsubscribe();
  }
}
