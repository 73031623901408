<div class="ng-mat-text-form-field">
  <!-- <mat-form-field appearance="outline">
    <mat-label>{{ schema?.label | translate }}</mat-label>
    
    <input
    [ngModel]="formProperty.valueChanges | async"
    (ngModelChange)="value = $event"
    />
  </mat-form-field> -->

  <mat-form-field [appearance]="'outline'">
    <mat-label>{{ schema?.label | translate }}</mat-label>

    <textarea
      matInput
      cdkTextareaAutosize
      #autosize="cdkTextareaAutosize"
      #textareaFromWidget
      [required]="schema?.isRequired || null"
      [placeholder]="schema?.label | translate"
      [ngModel]="formProperty.valueChanges | async"
      [cdkAutosizeMinRows]="options?.autosize?.min"
      [cdkAutosizeMaxRows]="options?.autosize?.max"
      [attr.maxlength]="options?.max_length ?? null"
      (ngModelChange)="value = $event"
    ></textarea>

    <mat-hint *ngIf="options?.max_length; let max_length" align="end">{{ textareaFromWidget.value.length }} / {{ max_length }}</mat-hint>
  </mat-form-field>
</div>
