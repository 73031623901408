<bg2-modal class="visite-modal" [fullscreen]="fullscreen">
  <bg2-modal-header>
    <div class="header">
      <span class="mdi mdi-code-greater-than mdi-36px"></span>
      <span class="title">
        <span class="hide-on-small-and-down">{{ 'EVENT.SUPERBOX.Visit hive by hive' | translate }}</span>
        <span class="hide-on-medium-and-up only-landscape">{{ 'EVENT.SUPERBOX.Hive by hive' | translate }}</span>
        <span class="hide-on-medium-and-up only-portrait">
          {{ 'EVENT.SUPERBOX.Hive by hive' | translate | truncate: 12:'...' }}
        </span>
      </span>
    </div>
    <div class="action-buttons">
      <ng-container *ngIf="visit_history$$ | async as visit_history">
        <span class="mdi mdi-{{ visit_history.length ? 'pause-circle' : 'close-box' }} mdi-36px" (click)="closeVisit()"></span>
      </ng-container>
    </div>
  </bg2-modal-header>

  <bg2-modal-content>
    <div class="content">
      <div class="btns-table">
        <ng-container
          *ngLet="{
            visit_idx: current_visit_idx$$ | async,
            visit_count: (visit_count$$ | async) || {},
            visit_current: (visit_current$$ | async) || {},
            visit_previous: (visit_previous$$ | async) || {}
          } as ctx"
        >
          <div
            class="btn-row"
            *ngFor="let field of fields$$ | async"
            [class.disable]="isFieldActif(field.name, ctx.visit_current) === false"
          >
            <ng-container *ngFor="let fconf of field.values">
              <div
                class="button-cell-container"
                (click)="isFieldActif(field.name, ctx.visit_current) && visitSelect(field.name, fconf.value)"
                [class.two-lines]="(nb_fields$$ | async) <= 1"
                [class.three-items-in-row]="field.size % 3 === 0 || field.size % 3 >= field.size % 4"
                *ngIf="field.name + '-' + fconf.value as field_value_key"
              >
                <bg2-button
                  class="btn-cell-content"
                  [type]="'action'"
                  [message]="buttonContent"
                  [styles]="{ 'background-color': field.color }"
                  [disabled]="ctx.visit_current[field.name] === fconf.value"
                ></bg2-button>
                <ng-template #buttonContent>
                  <div class="label">
                    <img src="assets/{{ fconf.img }}" alt="{{ fconf.label | translate }}" />
                  </div>
                </ng-template>
                <!-- <button
                  mat-flat-button
                  class="btn-cell-content"
                  [class.hasvalue]="ctx.visit_current[field.name]"
                  [class.previous]="ctx.visit_previous[field.name] == fconf.value"
                  [ngStyle]="{ 'background-color': field.color }"
                  >
                  <div class="label">
                    <img src="assets/{{ fconf.img }}" alt="{{ fconf.label | translate }}" />
                  </div>
                </button> -->

                <div
                  class="badge badge-value"
                  [class.new]="ctx.visit_current[field.name] === fconf.value"
                  *ngIf="ctx.visit_count[field_value_key] > 0"
                  [class.previous]="ctx.visit_previous[field.name] === fconf.value"
                  [class.badge-ok]="ctx.visit_idx === (nb_elements$$ | async)"
                  [class.badge-error]="ctx.visit_idx / (nb_elements$$ | async) > 1"
                >
                  <i class="mdi mdi-undo-variant mdi-18px" *ngIf="ctx.visit_previous[field.name] === fconf.value"></i>

                  <span>{{ ctx?.visit_count?.[field_value_key] }}</span>

                  <span *ngIf="ctx.visit_count[field_value_key] > 1" class="hide-on-small-and-down">
                    {{ 'ENTITY.ALL.TYPE.hives' | translate }}
                  </span>

                  <span *ngIf="ctx.visit_count[field_value_key] < 2" class="hide-on-small-and-down">
                    {{ 'ENTITY.ALL.TYPE.hive' | translate }}
                  </span>
                </div>

                <span class="btn-label">{{ fconf.label | translate }}</span>
              </div>
            </ng-container>
          </div>
        </ng-container>
      </div>
    </div>
  </bg2-modal-content>

  <bg2-modal-footer>
    <ng-container
      *ngLet="{
        nbhn: nb_elements$$ | async,
        styles: styles_class$$ | async,
        will_go_next: will_go_next$$ | async,
        visit_idx: current_visit_idx$$ | async
      } as ctx_footer"
    >
      <div id="cs-footer-container">
        <ng-container *ngIf="styles_class$$ | async as styles">
          <div [class]="'progress visit-progress ' + (styles.background || 'orange lighten-4')">
            <div
              [class]="'determinate ' + (styles.progress || 'orange')"
              [ngStyle]="{ 'width.%': (ctx_footer.visit_idx / (ctx_footer.nbhn ? ctx_footer.nbhn : 1)) * 100 }"
            ></div>
          </div>
        </ng-container>

        <!-- First block -->
        <div class="first-block">
          <bg2-button
            *ngIf="!ctx_footer?.will_go_next"
            class="w-100"
            [type]="'delete'"
            [icon]="'mdi-undo-variant'"
            [disabled]="ctx_footer.visit_idx <= 0"
            [options]="{ icon_size_class: 'mdi-18px' }"
            [message]="'VIEWS.MODALS.VISIT.Back' | translate"
            (btn_click)="backToPreviousVisit()"
          >
          </bg2-button>

          <bg2-button
            *ngIf="ctx_footer.will_go_next"
            class="w-100"
            [type]="'delete'"
            [icon]="'mdi-undo-variant'"
            [message]="'VIEWS.MODALS.VISIT.Cancel' | translate"
            [options]="{ icon_size_class: 'mdi-18px' }"
            (btn_click)="clearGoNext()"
          >
          </bg2-button>
        </div>

        <!-- Second block -->
        <div class="second-block">
          <span *ngIf="!ctx_footer.will_go_next" class="current-status">
            <span>{{ 'ENTITY.ALL.TYPE.hive' | translate }} &#35;{{ ctx_footer.visit_idx + 1 }}</span>
            <span> ({{ 'VIEWS.MODALS.VISIT.Done' | translate }} {{ ctx_footer.visit_idx }} / {{ ctx_footer.nbhn }}) </span>
          </span>

          <bg2-button
            *ngIf="!ctx_footer.will_go_next"
            [type]="'action'"
            [message]="'VIEWS.MODALS.VISIT.End' | translate"
            (btn_click)="closeVisit()"
          >
          </bg2-button>

          <bg2-button
            *ngIf="ctx_footer.will_go_next"
            class="going-next-button"
            [type]="'action'"
            [color]="'green'"
            [icon]="'mdi-arrow-right-bold'"
            [options]="{ icon_size_class: 'mdi-18px' }"
            [message]="'VIEWS.MODALS.VISIT.Going to next one !' | translate"
            (btn_click)="visitNext()"
          >
            <div role="button-loader" class="cache" [style.width.%]="100 - ((next_tick$$ | async) / NEXT_TIMEOUT) * 100"></div>
          </bg2-button>
        </div>
      </div>
    </ng-container>
  </bg2-modal-footer>
</bg2-modal>
