import { Injectable, OnDestroy } from '@angular/core';

import { flatten, isEmpty, isNil, values } from 'lodash-es';

import { map, switchMap, tap } from 'rxjs';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { distinctUntilRealChanged, replay, robustCombineLatest } from '@bg2app/tools/rxjs';

import { ConsoleLoggerService } from 'app/core/console-logger.service';
import { Dictionary } from 'app/typings/core/interfaces';

import { BaseRunner } from './models/base-runner';

type RunnerSource = Observable<BaseRunner[]>;

@Injectable({
  providedIn: 'root',
})
export class RunnersService implements OnDestroy {
  private _logger = new ConsoleLoggerService('RunnersService', true);

  private _all_runner_source: Dictionary<RunnerSource> = {};
  private _all_runner_source$$ = new BehaviorSubject<RunnerSource[]>([]);

  /**
   * Observable on current runners.
   */
  public current_runners$$ = this._all_runner_source$$.pipe(
    switchMap(runner_sources => robustCombineLatest(runner_sources)),
    map(runners => flatten<BaseRunner>(runners)),
    map(runners => runners.filter(runner => !isNil(runner))),
    // tap(runners => this._logger.runner(runners)),
    replay()
  );

  /**
   * Observable on runners emptiest state.
   */
  public has_runners$$: Observable<boolean> = this.current_runners$$.pipe(
    map(runners => !isEmpty(runners)),
    distinctUntilRealChanged(),
    replay()
  );

  // #endregion

  // #region -> (service basics)

  constructor() {}

  registerRunner(name: string, runner$$: RunnerSource) {
    this._all_runner_source[name] = runner$$;
    this._all_runner_source$$.next(values(this._all_runner_source));
  }

  ngOnDestroy(): void {}

  // #endregion

  private _used_height$$ = new BehaviorSubject<number>(null);
  public used_height$$ = combineLatest([this.has_runners$$, this._used_height$$.asObservable()]).pipe(
    map(([has_runners, used_height]) => {
      if (has_runners) {
        return used_height;
      }

      return null;
    }),
    distinctUntilRealChanged(),
    replay()
  );

  public set used_height(used_height: number) {
    this._used_height$$.next(used_height);
  }
}
