<form-element-header [schema]='schema' [showLabel]="true"></form-element-header>
<div class="color">
  <input class="browser-default selector color-h"
      *ngIf="!options.fixed_h"
      [ngStyle]="{'background': hue_bg$$ | async}"
      [ngModel]="hue$$ | async"
      (ngModelChange)="hue = $event"
      min="1"
      max="360"
      type="range">
  <input class="browser-default selector color-s"
      *ngIf="!options.fixed_s"
      [ngStyle]="{'background': saturation_bg$$ | async}"
      [ngModel]="saturation$$ | async"
      (ngModelChange)="saturation = $event"
      min="1"
      max="100"
      type="range">
  <input class="browser-default selector color-l"
      *ngIf="!options.fixed_l"
      [ngStyle]="{'background': lightness_bg$$ | async}"
      [ngModel]="lightness$$ | async"
      (ngModelChange)="lightness = $event"
      min="1"
      max="100"
      type="range">
</div>