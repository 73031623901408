import { Observable, of } from 'rxjs';
import { clone } from 'lodash-es';

import { Dictionary } from 'app/typings/core/interfaces';

import { Event } from './Event';
import { Beeguard2Api } from 'app/core';
import { Entity, Location, Exploitation } from '..';

export class SetupLocation extends Event {

  constructor(protected bg2Api: Beeguard2Api) {
    super(bg2Api);
    this.type = 'location_setup';
    this.resetLogger();
  }

  protected _applyLocally(entities: Dictionary<Entity|Entity[]>): Event {
    const exploitation = entities.exploitation as Exploitation;
    const location = entities.location as Location;
    // Update exploitation
    const exploitation_state = exploitation.getPreviousLocalState(this.date, this.id);
    const location_ids = exploitation_state.location_ids || [];
    location_ids.push(location.id);
    exploitation_state.location_ids = location_ids;
    exploitation.storeLocalState(this.date, this.id, exploitation_state);
    // Update location
    const state = location.getPreviousLocalState(this.date, this.id);
    state.exploitation_id = exploitation.id;
    state.exploitation_id_since = this.since();
    location.storeLocalState(this.date, this.id, state);
    return this;
  }


}
