import { OnDestroy, Component } from '@angular/core';

import { ISchema } from 'ngx-schema-form';
import { isEmpty, isEqual, isNil } from 'lodash-es';

import { replay } from '@bg2app/tools/rxjs';
import { BehaviorSubject, combineLatest, debounceTime, distinctUntilChanged, map, tap, filter } from 'rxjs';

import { AbstractModalComponent, ModalParams } from './abstract-modal.component';

import { SimpleSetterGetter } from 'app/models';

@Component({
  template: '',
})
export abstract class AbstractFormModalComponent<I extends ModalParams = any, O = any, FormModel = any>
  extends AbstractModalComponent<I, O>
  implements OnDestroy
{
  // #region -> (component basics)

  constructor() {
    super();
    this._logger.update_prefix('AbstractFormModalComponent');
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  // #endregion

  protected dismissible = false;
  private last_form_data: any = null; // last data from form

  // #region -> (form schema)

  /** */
  private _form_schema$$ = new BehaviorSubject<ISchema>({});

  /** */
  public form_schema$$ = this._form_schema$$.asObservable().pipe(filter(schema => !isEmpty(schema)));

  /** */
  public set form_schema(new_schema: ISchema) {
    if (!isEmpty(new_schema)) {
      this._form_schema$$.next(new_schema);
      this.initial_loading = false;
    }
  }

  /** */
  private _form_ready = false;

  /** */
  public get form_ready(): boolean {
    return this._form_ready;
  }

  /** */
  public form_ready$$ = this.form_schema$$.pipe(
    map(schema => !isNil(schema)),
    tap(ready => (this._form_ready = ready))
  );

  // #endregion

  // #region -> (form model)

  /** */
  protected form_model_sg = new SimpleSetterGetter<FormModel>(null);

  /** */
  public form_model$$ = this.form_model_sg.value$$;

  /** */
  public set form_model(form_model: FormModel) {
    this.form_model_sg.value = form_model;
  }

  /** */
  public get form_model(): FormModel {
    return this.form_model_sg.value;
  }

  public onFormChange(event: any): void {
    if (isNil(event.value)) {
      return;
    }

    // return if same as previous...
    if (isEqual(this.last_form_data, event.value)) {
      return;
    }

    this.onFormChanged(event);

    this.form_model = event.value;
    this.last_form_data = event.value;
  }

  protected abstract onFormChanged(event: any): any;

  // #endregion

  // #region -> (form validity)

  /** */
  protected form_valid_sg = new SimpleSetterGetter(false);

  /** */
  public setFormValid(val: boolean): void {
    this.form_valid_sg.value = val;
  }

  public form_valid$$ = combineLatest([this.form_valid_sg.value$$, this.form_ready$$]).pipe(
    map(([form_valid, form_ready]) => form_valid && form_ready),
    debounceTime(100),
    distinctUntilChanged(),
    replay()
  );

  // #endregion

  // #region -> (submit management)

  /** */
  protected is_submitting_sg = new SimpleSetterGetter(false);

  /** */
  protected set is_submitting(is_submitting: boolean) {
    this.is_submitting_sg.value = is_submitting;
  }

  /** */
  protected get is_submitting(): boolean {
    return this.is_submitting_sg.value;
  }

  /** */
  public abstract submit(): void;

  // #endregion
}
