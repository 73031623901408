import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { flatten, get, isEmpty, isNil } from 'lodash-es';

import { TranslateService } from '@ngx-translate/core';
import { marker as i18n } from '@biesbjerg/ngx-translate-extract-marker';

import { BehaviorSubject, combineLatest, map, of, Subscription, switchMap } from 'rxjs';

import { AppStateService } from 'app/core/app-state.service';
import { ConsoleLoggerService } from 'app/core/console-logger.service';
import { ExcelExportService } from 'app/core/export/excel-export.service';

import { DatatableColumn } from 'app/models/misc/datatable';
import { DatatableBaseRow } from 'app/typings/datatable/interfaces/DatatableBaseRow.iface';

import { TableBaseComponent } from 'app/widgets/misc-widgets/table-base/table-base.component';
import { MovementAuthorizationsByDevice } from '../device-movements-authorization-modal.component';
import { distinctUntilRealChanged, replay } from '@bg2app/tools/rxjs';
import { Dictionary } from 'app/typings/core/interfaces';
import { DRDevice } from 'app/models';
import { CompareByType } from 'app/misc/tools';
import { DialogsService } from 'app/widgets/dialogs-modals';
import { isBefore } from 'date-fns/esm';
import { UrlParamsService } from 'app/core/url-param.service';
import { DeviceAuthorizedMovementDialogComponent } from '../../../dialogs/device-authorized-movement-dialog/device-authorized-movement-dialog.component';

/** */
export interface AuthorizedMovementRow extends DatatableBaseRow {
  /** */
  device: DRDevice;

  /** */
  editable: boolean;

  /** */
  config: {
    /** */
    id: string;

    /** */
    device: {
      /** */
      imei: number;
    };

    /** */
    start: Date;

    /** */
    end: Date;

    /** */
    type: string;

    /** */
    embedded: Dictionary<any>;
  };
}

@Component({
  selector: 'bg2-device-authorized-movements-modal-table-view',
  templateUrl: './table-view.component.html',
  styleUrls: ['./table-view.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DeviceAuthorizedMovementsModalTableViewComponent extends TableBaseComponent<AuthorizedMovementRow> implements OnInit {
  /** */
  public readonly column_definitions: DatatableColumn<AuthorizedMovementRow>[] = [
    {
      property: 'config.id',
      short_property: 'cfg_id',
      label: i18n('VIEWS.DEVICES.DIALOGS_AND_MODALS.DEVICE_MOVEMENTS_AUTHORIZATION_MODAL.TABLE_VIEW.CFG_COLUMNS.Config ID'),
      displayed_by_default: false,
      not_configurable: false,
      is_sortable: false,
      is_groupable: false,
      compare_by: null,
    },
    {
      property: 'config.device.imei',
      short_property: 'dvc_nm',
      label: i18n('VIEWS.DEVICES.DIALOGS_AND_MODALS.DEVICE_MOVEMENTS_AUTHORIZATION_MODAL.TABLE_VIEW.CFG_COLUMNS.Applied to device'),
      displayed_by_default: true,
      not_configurable: false,
      is_sortable: false,
      is_groupable: true,
      compare_by: null,
    },
    {
      property: 'config.start',
      short_property: 'cfg_start',
      label: i18n('ALL.COMMON.DATE_MANAGEMENT.Start date'),
      displayed_by_default: true,
      not_configurable: false,
      is_sortable: true,
      is_groupable: false,
      compare_by: CompareByType.DATE,
    },
    {
      property: 'config.end',
      short_property: 'cfg_end',
      label: i18n('ALL.COMMON.DATE_MANAGEMENT.End date'),
      displayed_by_default: true,
      not_configurable: false,
      is_sortable: true,
      is_groupable: false,
      compare_by: CompareByType.DATE,
    },
  ];

  // #region -> (component basics)

  /** */
  private readonly LOGGER = new ConsoleLoggerService('DeviceAuthorizedMovementsModalTableViewComponent', true);

  /** */
  private _data_sub: Subscription = null;

  /** */
  constructor(
    public appState: AppStateService,
    private _translate: TranslateService,
    private readonly _dialogs_service: DialogsService,
    private readonly _url_params: UrlParamsService
  ) {
    super(_translate, _url_params);
  }

  /** */
  ngOnInit() {
    this.all_columns = this.column_definitions;
    if (this.activate_groups) {
      this.set_grouped_columns(['config.device.imei']);
    }

    this._data_sub = this.authorized_movements$$
      .pipe(switchMap(data => this.sorting_model$$.pipe(switchMap(sorting_model => super.sort_data_locally(data, sorting_model)))))
      .subscribe({
        next: authorized_movements => {
          this.error = null;

          this.paged_data_rows = authorized_movements;

          // Update loading
          this.loading = false;
        },
        error: (error: unknown) => {
          this.LOGGER.error(error);
          this.error = error;
        },
      });
  }

  // #endregion

  // #region -> (component loadings)

  /** */
  private _loading$$: BehaviorSubject<boolean> = new BehaviorSubject(true);

  /** */
  private set loading(val: boolean) {
    this._loading$$.next(val);
  }

  /** */
  public loading$$ = this._loading$$.pipe(distinctUntilRealChanged());

  // #endregion

  // #region -> (authorized movement management)

  /** */
  private _authorized_movements_by_device$$ = new BehaviorSubject<MovementAuthorizationsByDevice[]>(<any>[]);

  /** */
  private authorized_movements_by_device$$ = this._authorized_movements_by_device$$.asObservable();

  /** */
  @Input()
  public set authorized_movements_by_device(value: MovementAuthorizationsByDevice[]) {
    this._authorized_movements_by_device$$.next(value);
  }

  @Input()
  public activate_groups = false;

  /** */
  private authorized_movements$$ = this.authorized_movements_by_device$$.pipe(
    map(authorized_movements_by_device => {
      if (isNil(authorized_movements_by_device)) {
        return [];
      }

      const aoa_of_config_by_device = authorized_movements_by_device.map(authorized_movements_for_device =>
        authorized_movements_for_device.configurations.map(config => {
          const formatted_model = <AuthorizedMovementRow>{ config, device: authorized_movements_for_device.device };
          formatted_model.editable = !isBefore(formatted_model.config.end, new Date());

          return formatted_model;
        })
      );

      return aoa_of_config_by_device;
    }),
    map(aoa_of_config_by_device => flatten(aoa_of_config_by_device)),
    replay()
  );

  /** */
  public update_authorized_movement(row: AuthorizedMovementRow): void {
    if (new Date() > row.config.end) {
      return;
    }

    this._dialogs_service
      .open(DeviceAuthorizedMovementDialogComponent, {
        imeis: [],
        authorized_movement: {
          id: row.config.id,
          imei: row.config.device.imei,
        },
      })
      .subscribe({
        next: (response: boolean) => {
          if (response) {
            this.reload_data.emit(true);
          }
        },
      });
  }

  // #endregion

  @Output()
  public reload_data = new EventEmitter<boolean>();

  /** */
  public assertRowModel(row: AuthorizedMovementRow): AuthorizedMovementRow | null {
    return <AuthorizedMovementRow>row;
  }
}
