<div class="select-exploitation">
  <mat-form-field [appearance]="'outline'">
    <mat-label>{{ 'ENTITY.ALL.TYPE.exploitation' | translate }}</mat-label>
    <mtx-select
      [multiple]="true"
      [bindValue]="'id'"
      [appendTo]="'body'"
      [bindLabel]="'name'"
      [typeahead]="filter$$"
      [items]="items$$ | async"
      [ngModel]="appState.selected_exploitations_ids$$ | async"
      (ngModelChange)="appState.selected_exploitations_ids = $event"
      [notFoundText]="'VIEWS.EXPLOITATION.SHARED.SELECT_EXPLOITATION.No expoitation founded' | translate"
    >
      <ng-template ng-header-tmp>
        <div style="display: flex; flex-flow: row nowrap">
          <bg2-button
            style="flex: 1 1 50%"
            [type]="'cancel'"
            [options]="{ is_thin: true }"
            [message]="'ALL.ACTIONS.Select all' | translate"
            (btn_click)="selectAll()"
          ></bg2-button>

          <bg2-button
            style="flex: 1 1 50%"
            [type]="'cancel'"
            [options]="{ is_thin: true }"
            [message]="'ALL.ACTIONS.Unselect all' | translate"
            (btn_click)="unselectAll()"
          ></bg2-button>
        </div>
      </ng-template>

      <ng-template ng-multi-label-tmp let-exploitations="items" let-clear="clear">
        <div class="ng-value" *ngFor="let exploitation of exploitations | slice : 0 : max_displayed">
          <span class="ng-value-label">{{ $any(exploitation).name }}</span>
          <span class="ng-value-icon left" (click)="clear(exploitation)" aria-hidden="true">×</span>
        </div>
        <div class="ng-value" *ngIf="exploitations.length > max_displayed">
          <span class="ng-value-label">
            {{ 'ALL.COMMON.[number] more' | translate : { number: exploitations.length - max_displayed } }}
          </span>
        </div>
      </ng-template>

      <ng-template ng-option-tmp let-expl="item" let-index="index" let-search="searchTerm">
        <div *ngIf="assertSelectObject(expl); let model" class="item bg2-select-exploitation_option-ng-option-tmp">
          <span [ngOptionHighlight]="search">{{ model?.exploitation.name$$ | async }}</span>

          <ng-container *ngIf="model?.owner; let owner">
            <div class="exploitation-owner-container">
              <span>(</span>

              <div class="exploitation-owner">
                <span
                  *ngLet="owner?.is_superadmin$$ | async; let is_superadmin"
                  class="mdi mdi-24px mdi-crown"
                  [ngClass]="{ 'mdi-account': !is_superadmin, 'mdi-crown': is_superadmin }"
                  [ngStyle]="{ color: is_superadmin ? 'goldenrod' : 'initial' }"
                ></span>

                <span [ngOptionHighlight]="search">{{ owner?.name$$ | async }}</span>
              </div>

              <span>)</span>
            </div>
          </ng-container>
        </div>
      </ng-template>
    </mtx-select>
  </mat-form-field>
</div>
