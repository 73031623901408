import { isNil } from 'lodash-es';
import { map, Observable } from 'rxjs';

import { marker as i18n } from '@biesbjerg/ngx-translate-extract-marker';

import { EventSchema, I18NParams, IEventGlobalSchema } from '../Event';
import { NopDeviceEvent } from './NopDeviceEvent';
import { Hive } from 'app/models/entities';

export class DeviceRegister extends NopDeviceEvent {
  protected getI18nParams(translate_service: any, entities: any) {
    return super.getI18nParams(translate_service, entities).pipe(
      map(i18nParams => {
        if (!isNil(i18nParams.data.device)) {
          i18nParams.data.device = this.extandDeviceParams(i18nParams.data.device, null);
        }

        return i18nParams;
      })
    );
  }

  protected getDescKey(role: string, schema: IEventGlobalSchema, i18nData: I18NParams): Observable<string> {
    return super.getDescKey(role, schema, i18nData).pipe(
      map(desc_key => {
        const data = i18nData.data;

        if (data?.contract === 'purchase') {
          return i18n<string>(
            'EVENT.DEVICE_REGISTER.LABEL.Device [data->device->link] affected to the exploitation [entities->warehouse->link] via a purchase contract'
          );
        }

        if (data?.contract === 'rent') {
          return i18n<string>(
            'EVENT.DEVICE_REGISTER.LABEL.Device [data->device->link] affected to the exploitation [entities->warehouse->link] via a rental contract'
          );
        }

        if (data?.contract === 'loan') {
          return i18n<string>(
            'EVENT.DEVICE_REGISTER.LABEL.Device [data->device->link] affected to the exploitation [entities->warehouse->link] via a loan contract'
          );
        }

        return i18n<string>(
          'EVENT.DEVICE_REGISTER.LABEL.Device [data->device->link] affected to the exploitation [entities->warehouse->link]'
        );
      })
    );
  }
}

export class DeviceReplace extends NopDeviceEvent {
  protected getI18nParams(translate_service: any, entities: any) {
    return super.getI18nParams(translate_service, entities).pipe(
      map(i18nParams => {
        // console.log(i18nParams);
        if (!isNil(i18nParams.data.old_device)) {
          i18nParams.data.old_device = this.extandDeviceParams(i18nParams.data.old_device, null);
        }
        if (!isNil(i18nParams.data.new_device)) {
          i18nParams.data.new_device = this.extandDeviceParams(i18nParams.data.new_device, null);
        }
        return i18nParams;
      })
    );
  }

  protected getDescKey(role: string, schema: IEventGlobalSchema, i18nData: I18NParams): Observable<string> {
    return super.getDescKey(role, schema, i18nData).pipe(
      map(desc_key => {
        const reinstall_in_hive_or_apiary = i18nData.data.reinstall_in_hive_or_apiary || false;
        const hive = i18nData.entities.hive as Hive;
        if (role === 'apiary') {
          if (reinstall_in_hive_or_apiary) {
            if (hive) {
              return i18n(
                'EVENT.DEVICE_REPLACE.Replace device [data->old_device->link] by [data->new_device->link] in hive [entities->hive->link]'
              );
            } // else default value is ok
          } else {
            // Do not reinstall
            if (hive) {
              return i18n(
                'EVENT.DEVICE_REPLACE.Takeout device [data->old_device->link] from hive [entities->hive->link] (replaced by [data->new_device->link] but not re-installed in the hive)'
              );
            } else {
              return i18n(
                'EVENT.DEVICE_REPLACE.Takeout device [data->old_device->link] (replaced by [data->new_device->link] but not re-installed in the apiary)'
              );
            }
          }
        } else if (role === 'hive') {
          if (!reinstall_in_hive_or_apiary) {
            return i18n(
              'EVENT.DEVICE_REPLACE.Takeout device [data->old_device->link] (replaced by [data->new_device->link] but not re-installed in the hive)'
            );
          }
        }
        return desc_key;
      })
    );
  }
}

export class BulkDevicesRegister extends NopDeviceEvent {
  protected getI18nParams(translate_service: any, entities: any) {
    return super.getI18nParams(translate_service, entities).pipe(
      map(i18nParams => {
        if (!isNil(i18nParams.data.devices)) {
          i18nParams.data.nb_devices = i18nParams.data.devices.length;
          i18nParams.data.devices = i18nParams.data.devices.map((device: any) => this.extandDeviceParams(device));
          i18nParams.data.device_links = i18nParams.data.devices
            .map((c: { imei: string; label: string; link: string }) => c.link)
            .join(', ');
        }

        return i18nParams;
      })
    );
  }

  protected getDescKey(role: string, schema: IEventGlobalSchema, i18nData: I18NParams): Observable<string> {
    return super.getDescKey(role, schema, i18nData).pipe(
      map(() => {
        const data = i18nData.data;

        if (data?.contract === 'purchase') {
          return i18n<string>(
            'EVENT.BULK_DEVICES_REGISTER.LABEL.Devices [data->device_links] affected to the exploitation [entities->warehouse->link] via a purchase contract'
          );
        }

        if (data?.contract === 'rent') {
          return i18n<string>(
            'EVENT.BULK_DEVICES_REGISTER.LABEL.Devices [data->device_links] affected to the exploitation [entities->warehouse->link] via a rental contract'
          );
        }

        if (data?.contract === 'loan') {
          return i18n<string>(
            'EVENT.BULK_DEVICES_REGISTER.LABEL.Devices [data->device_links] affected to the exploitation [entities->warehouse->link] via a loan contract'
          );
        }

        return i18n<string>(
          'EVENT.BULK_DEVICES_REGISTER.LABEL.Devices [data->device_links] affected to the exploitation [entities->warehouse->link]'
        );
      })
    );
  }
}

export class DeviceReasign extends NopDeviceEvent {
  protected getI18nParams(translate_service: any, entities: any) {
    return super.getI18nParams(translate_service, entities).pipe(
      map(i18nParams => {
        if (!isNil(i18nParams.data.device)) {
          i18nParams.data.device = this.extandDeviceParams(i18nParams.data.device, null);
        }
        return i18nParams;
      })
    );
  }
}
