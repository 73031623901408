<div class="bg2-apiary-sensor-data-container-component">
  <div class="sensor-data-container-main">
    <bg2-apiary-hives-weight-diff-d3-chart
      *ngIf="!apiary_is_compact"
      [is_loading]="loadings.weight_data$$ | async"
      [geoposition]="location_geoposition$$ | async"
      [weight_diff_data]="weight_diff_data$$ | async"
      [date_range]="date_range_manager.range$$ | async"
    ></bg2-apiary-hives-weight-diff-d3-chart>

    <bg2-apiary-weight-d3-chart-component
      [weight_data]="weight_data$$ | async"
      [is_loading]="loadings.weight_data$$ | async"
      [geoposition]="location_geoposition$$ | async"
      [date_range]="date_range_manager.range$$ | async"
    ></bg2-apiary-weight-d3-chart-component>

    <bg2-apiary-hives-temperature-d3-chart-component
      *ngIf="!apiary_is_compact"
      [geoposition]="location_geoposition$$ | async"
      [temperature_data]="temperature_data$$ | async"
      [is_loading]="loadings.temperature_data$$ | async"
      [date_range]="date_range_manager.range$$ | async"
    ></bg2-apiary-hives-temperature-d3-chart-component>

    <bg2-apiary-hives-beecount-d3-chart-component
      *ngIf="!apiary_is_compact && has_devices_for_beecounting$$ | async"
      [data]="counter_data$$ | async"
      [geoposition]="location_geoposition$$ | async"
      [is_loading]="loadings.beecount_data$$ | async"
      [date_range]="date_range_manager.range$$ | async"
    ></bg2-apiary-hives-beecount-d3-chart-component>
  </div>

  <bg2-apiary-charts-footer
    [is_compact]="false"
    [location_id]="location_id$$ | async"
    [date_range_manager]="date_range_manager"
    [config]="{ show_total_WG_sensors_changed: show_total_WG_sensors_changed$$ | async }"
  ></bg2-apiary-charts-footer>

  <bg2-error-helper *ngIf="error$$ | async; let error" [error]="error"></bg2-error-helper>
</div>
