<div
  *ngLet="apiary$$ | async; let apiary"
  class="bg2-apiary-details bg2-apiary-details-component"
  [ngClass]="{ 'bg2-apiary-details--ghost': apiary?.is_ghost$$ | async }"
>
  <div class="actual-hives-nucs-data">
    <bg2-apiary-hives-nucs-summary [apiary]="apiary"></bg2-apiary-hives-nucs-summary>
  </div>

  <ng-container *ngIf="!is_compact_view">
    <div *ngIf="apiary_comment$$ | async; let notes" class="apiary-comment-container">
      <bg2-title-separator
        [icon]="'mdi-comment'"
        [title]="'VIEWS.APIARY.SHARED.VIEWS.APIARY_DETAILS.Notes' | translate"
      ></bg2-title-separator>

      <div class="apiary-comment-container__inner">
        <div>{{ notes }}</div>
      </div>
    </div>
  </ng-container>

  <ng-container *ngLet="apiary_total_equipped_hives$$ | async; let apiary_total_equipped_hives">
    <bg2-title-separator
      [icon]="'mdi-beehive-outline'"
      [goto_link]="is_compact_view ? { routerLink: ['/locations', location_id$$ | async] } : null"
      [title]="
        (apiary_total_equipped_hives >= 1
          ? 'VIEWS.APIARY.SHARED.APIARY_DETAILS.[total] equipped hives'
          : 'VIEWS.APIARY.SHARED.APIARY_DETAILS.No equipped hive'
        ) | translate: { total: apiary_total_equipped_hives }
      "
    ></bg2-title-separator>
  </ng-container>

  <bg2-apiary-last-data [apiary]="apiary"></bg2-apiary-last-data>

  <bg2-title-separator
    [icon]="'mdi-chart-multiple'"
    [title]="'VIEWS.APIARY.SHARED.VIEWS.APIARY_DETAILS.Devices datas' | translate"
  ></bg2-title-separator>

  <bg2-apiary-sensor-data-container [apiary]="apiary" [apiary_is_compact]="is_compact_view"></bg2-apiary-sensor-data-container>

  <ng-container *ngIf="!is_compact_view">
    <bg2-title-separator
      [icon]="'mdi-sun-wireless'"
      [title]="'VIEWS.APIARY.SHARED.VIEWS.APIARY_DETAILS.Weather datas' | translate"
    ></bg2-title-separator>

    <bg2-apiary-weather-data-container [apiary]="apiary"></bg2-apiary-weather-data-container>
  </ng-container>

  <bg2-title-separator [icon]="'mdi-presentation'" [title]="'ALL.BREEDING_REGISTER.Breeding register' | translate"></bg2-title-separator>

  <div class="evaluation-history-container">
    <bg2-apiary-br-evaluation-carousel [apiary]="apiary"></bg2-apiary-br-evaluation-carousel>
  </div>

  <div *ngIf="!is_compact_view" class="harvest-history-container">
    <bg2-apiary-carousel-superbox-harvest [apiary]="apiary"></bg2-apiary-carousel-superbox-harvest>
  </div>

  <div *ngIf="!is_compact_view" class="another-intervention" >
    <bg2-button
      [type]="'action'"
      [options]="{ is_thin: true }"
      [disabled]="apiary?.user_acl?.cannot$$('write_all_events') | async"
      [message]="'EVENT.OTHER_INTERVENTION.Other intervention' | translate"
      [icon]="(apiary?.user_acl?.can$$('write_all_events') | async) ? 'mdi-pencil-box' : 'mdi-lock'"
      (btn_click)="runOtherIntervention()"
    >
    </bg2-button>
  </div>

  <bg2-title-separator
    *ngIf="!is_compact_view"
    [icon]="'mdi-calendar-month'"
    [title]="'VIEWS.EVENTS.SHARED.ENTITY_EVENTS_HISTORY.History of apiary' | translate"
  ></bg2-title-separator>

  <div *ngIf="!is_compact_view" class="apiary-history-container">
    <bg2-entity-events-history [entity]="apiary"></bg2-entity-events-history>
  </div>
</div>
