<div *ngLet="is_favorite$$ | async; let is_favorite" class="bg2-favorite-component" [ngClass]="{ '--favorited': is_favorite }">
  <!-- Only addable -->
  <span
    *ngIf="is_addable && !is_removable"
    class="mdi mdi-star mdi-24px"
    [popper]="!is_favorite ? (labels?.add | translate) : (labels?.cannot_add | translate)"
    (click)="is_favorite ? null : favorite_changed.next(true)"
  ></span>

  <!-- Only removable -->
  <span
    *ngIf="!is_addable && is_removable"
    class="mdi mdi-star mdi-24px"
    [popper]="!is_favorite ? (labels?.remove | translate) : (labels?.cannot_remove | translate)"
    (click)="!is_favorite ? null : favorite_changed.next(false)"
  ></span>

  <!-- Addable and removable -->
  <span
    *ngIf="is_addable && is_removable"
    [popper]="favoriteTemplateModifiable"
    class="mdi mdi-star mdi-24px"
    (click)="update_favorite()"
  ></span>

  <!-- Not Addable and removable -->
  <span *ngIf="!is_addable && !is_removable" class="mdi mdi-star mdi-24px"></span>
</div>

<popper-content #favoriteTemplateModifiable>
  <ng-container *ngIf="is_favorite$$ | async; let is_favorite">
    <span *ngIf="is_favorite">{{ labels?.add | translate }}</span>
    <span *ngIf="!is_favorite">{{ labels?.remove | translate }}</span>
  </ng-container>
</popper-content>
