<ng-container *ngIf="visible$$ | async">
  <form-element-header [schema]="schema" [title_style]="options.title_style"></form-element-header>
  <div class="rating-container">
    <div class="rating">
      <ng-container *ngFor="let number of (maximum | range)">
        <span
          class="item"
          [class.exact]="(note$$ |async) == number + 1"
          [class.rated]="(note$$ |async) >= (number + 1)"
          [attr.data-value]="number + 1"
          (click)="note = (number + 1)"
        >
          <img [src]="schema.options.image" alt="" />
        </span>
      </ng-container>
    </div>
    <bg2-button *ngIf="options.reset_btn && !__isNil(formProperty.value)" [type]="'action'" [message]="'WIDGETS.EVENT_FORM.CONTROL.Don\'t specify' | translate" (btn_click)="reset()">
    </bg2-button>
  </div>
  <ng-container *ngIf="(previous$$ | async) as previous">
    <form-element-previous
      [previous]="previous"
      [from_template]="true"
      [previous_btn]="formProperty.value!==previous.value"
      (reset_previous)="resetPrevious()"
    >
      <div class="rating-container">
        <div class="rating previous">
          <ng-container *ngFor="let number of ($any(previous).value | range)">
            <span class="item">
              <img [src]="schema.options.image" alt="" />
            </span>
          </ng-container>
        </div>
      </div>
    </form-element-previous>
  </ng-container>
</ng-container>
