import { AfterViewInit, ChangeDetectionStrategy, Component, HostListener, NgZone, OnDestroy, OnInit } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';
import { marker as i18n } from '@biesbjerg/ngx-translate-extract-marker';

import { isEmpty, isNil, uniqBy } from 'lodash-es';

import {
  BehaviorSubject,
  combineLatest,
  concat,
  debounceTime,
  forkJoin,
  map,
  Observable,
  of,
  Subscription,
  switchMap,
  take,
  tap,
} from 'rxjs';
import { distinctUntilRealChanged, replay } from '@bg2app/tools/rxjs';

import { Beeguard2Api } from 'app/core';
import { DialogsService } from 'app/widgets/dialogs-modals';
import { AppStateService } from 'app/core/app-state.service';
import { ZohoCRMApiService } from 'app/core/services/zoho/zoho-crm-api.service';
import { ZohoAuthService } from 'app/core/services/zoho/zoho-auth.service';

import { build_zoho_search_criteria, IZohoCRMAccount, IZohoCRMContact, ZohoSearchCriteriaTemplate } from 'app/models/zoho';

import { EfSelectOptions, EfSelectWidgetComponent } from '../select/select.widget';
import { FullscreenSelectHelper } from '@bg2app/tools/misc';

export interface ZohoSearchSelectOptions extends EfSelectOptions {
  /** */
  zoho_search_config: {
    /** */
    search_in: 'Accounts' | 'Contacts';

    /** */
    criteria_template: ZohoSearchCriteriaTemplate;
  };
}

@Component({
  selector: 'bg2-zoho-search-crm-widget',
  templateUrl: './zoho-search-crm-widget.component.html',
  styleUrls: ['./zoho-search-crm-widget.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ZohoSearchCRMWidgetComponent extends EfSelectWidgetComponent implements OnInit, AfterViewInit, OnDestroy {
  // #region -> (component basics)

  public options: ZohoSearchSelectOptions = {
    img: false,
    img_prefix: 'select/',
    items: {},
    readonly: false,
    indent: false,
    clearable: true,
    reset_btn: false,
    auto_scroll: true,

    zoho_search_config: {
      search_in: null,
      criteria_template: null,
    },
  };

  /** */
  private _widget_value_sub: Subscription = null;

  constructor(
    public appState: AppStateService,

    protected bg2Api: Beeguard2Api,
    protected translate: TranslateService,
    protected dialogs: DialogsService,
    protected readonly _ng_zone: NgZone,

    private readonly _zohoAuthService: ZohoAuthService,
    private readonly _zohoCRMApiService: ZohoCRMApiService
  ) {
    super(bg2Api, translate, appState, dialogs, _ng_zone);
  }

  ngOnInit(): void {
    super.ngOnInit();

    /** */
    this._widget_value_sub = concat(of(this.formProperty?.value), this.formProperty.valueChanges)
      .pipe(distinctUntilRealChanged())
      .subscribe({
        next: current_value => {
          this._selected_value$$.next(current_value);
        },
      });
  }

  ngAfterViewInit(): void {
    super.ngAfterViewInit();
  }

  ngOnDestroy(): void {
    this._widget_value_sub?.unsubscribe();
  }

  // #endregion

  // #region -> (zoho auth state)

  /** */
  public is_authenticated$$ = this._zohoAuthService.is_authenticated$$;

  /** */
  public login(): void {
    this._zohoAuthService.login();
  }

  // #endregion

  // #region -> (fullscreen management)

  /** */
  public fullscreen_select_helper = new FullscreenSelectHelper(this._ng_zone);

  // #endregion

  // #region -> (loading)

  /** */
  private _is_loading$$ = new BehaviorSubject<boolean>(false);

  /** */
  public is_loading$$ = this._is_loading$$.asObservable().pipe(distinctUntilRealChanged(), replay());

  /** */
  private set is_loading(is_loading: boolean) {
    this._is_loading$$.next(is_loading);
  }

  // #endregion

  // #region -> (search)

  /** */
  public raw_filter$$ = new BehaviorSubject<string>('');

  /** */
  public criteria$$: Observable<string> = this.raw_filter$$.pipe(
    distinctUntilRealChanged(),
    debounceTime(400),
    map(raw_filter => {
      if (isNil(raw_filter) || isEmpty(raw_filter)) {
        return null;
      }

      return build_zoho_search_criteria(this?.options?.zoho_search_config?.criteria_template, { SEARCH_TERM: raw_filter });
    }),
    distinctUntilRealChanged(),
    replay()
  );

  // #endregion

  // #region -> (requesting result)

  /** */
  private _selected_value$$ = new BehaviorSubject<string>(null);

  /** */
  public selected_value_id$$ = this._selected_value$$.asObservable().pipe(
    map(selected_value => {
      if (isNil(selected_value) || isEmpty(selected_value)) {
        return null;
      }

      return selected_value;
    }),
    replay()
  );

  /** */
  private selected_value_object$$ = this.selected_value_id$$.pipe(
    switchMap(selected_value_id => {
      if (isNil(selected_value_id) || isEmpty(selected_value_id)) {
        return of(null);
      }

      const fields_to_search = [];

      if (this?.options?.zoho_search_config?.search_in === 'Contacts') {
        fields_to_search.push('First_Name', 'Last_Name');
      }

      if (this?.options?.zoho_search_config?.search_in === 'Accounts') {
        fields_to_search.push('Account_Name');
      }

      return this._zohoCRMApiService.fetch_record$(<any>this?.options?.zoho_search_config?.search_in, selected_value_id, fields_to_search);
    }),
    replay()
  );

  /** */
  private request_users$$ = combineLatest({
    criteria: this.criteria$$,
  }).pipe(
    distinctUntilRealChanged(),
    tap(() => (this.is_loading = true)),
    switchMap(({ criteria }) => {
      const request$$ = this._zohoCRMApiService
        .search_in_records(<any>this?.options?.zoho_search_config?.search_in, criteria, {})
        .pipe(take(1));

      return forkJoin({ request: request$$, selected_value: this.selected_value_object$$.pipe(take(1)) }).pipe(
        map(({ request, selected_value }) => {
          this._total$$.next(request?.info?.count ?? 0);

          const search_data = request?.data ?? [];
          const response = uniqBy(
            [selected_value, ...search_data].filter(value => !isNil(value)),
            'id'
          );

          return response;
        })
      );
    }),
    tap(() => (this.is_loading = false)),
    replay()
  );

  /** */
  public users$$: Observable<any[]> = this.request_users$$.pipe(replay());

  // #endregion

  // #region -> (total loaded items)

  /** */
  private _total$$ = new BehaviorSubject(0);

  /** */
  public current_loaded_sentence$$ = combineLatest([this.users$$, this._total$$.asObservable()]).pipe(
    switchMap(([users, total]) =>
      this.translate.stream(i18n<string>('WIDGETS.EVENT_FORM.ZOHO_SEARCH.[loaded] out of [total] items loaded'), {
        loaded: users?.length,
        total,
      })
    ),
    distinctUntilRealChanged(),
    replay()
  );

  // #endregion

  public asType(value: any, type: 'Accounts'): IZohoCRMAccount;
  public asType(value: any, type: 'Contacts'): IZohoCRMContact;
  public asType(value: any, type: 'Contacts' | 'Accounts'): any {
    return value;
  }
}
