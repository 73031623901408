import { Injectable, OnDestroy } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { isNil } from 'lodash-es';
import { Subscription } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PageTitleService implements OnDestroy {

  constructor(
    private titleService: Title,
    private translateService: TranslateService
  ) { }

  private title_sub: Subscription = null;

  setKey(key: string, param?: any): void {
    if (!isNil(this.title_sub)) {
      this.title_sub.unsubscribe();
    }
    this.title_sub = this.translateService.stream(key, param).subscribe(title => {
      this.titleService.setTitle('BeeGuard - ' + title);
    });
  }

  ngOnDestroy(): void {
    this.title_sub?.unsubscribe();
  }
}
