<ng-container *ngIf="visible$$ | async">
  <form-element-header [schema]="schema" [showLabel]="true"></form-element-header>

  <div id="quantity_object_{{id}}" class="quantity">
    <div class="picto" *ngIf="schema.options?.img">
      <img *ngIf="!schema.options.img.includes('super')" [src]="'assets/' + schema.options?.img" width="90px" />

      <!-- SUPER specific variant -->
      <img
        *ngIf="(schema.options.img.includes('super')) && ((value$$ | async) <= 4)"
        [src]="'assets/' + schema.options.img.replace('[value]', value$$ | async | fixed:nb_dec)"
        alt=""
        width="90px"
      />

      <img
        *ngIf="(schema.options.img.includes('super')) && ((value$$ | async) > 4)"
        [src]="'assets/' + schema.options.img.replace('[value]', '4+')"
        alt=""
        width="90px"
      />
    </div>

    <div class="quantity-button quantity-down waves-effect" [class.disabled]="(value$$ | async) <= schema.minimum" (click)="dec()">
      <span class="mdi mdi-minus mdi-24px"></span>
    </div>

    <div class="input-container">
      <span class="null-value" *ngIf="(not_nil_value$$ | async) === false">~</span>

      <mat-form-field class="no-padding" appearance="outline">
        <input
          matInput
          type="number"
          id="input_number_{{id}}"
          class="quantity-value browser-default"
          [name]="name"
          [step]="step"
          [ngModel]="(value$$ | async | fixed:nb_dec)"
          [min]="__isNil(schema.minimum) ? null : schema.minimum"
          [max]="__isNil(schema.maximum) ? null : schema.maximum"
          (ngModelChange)="onChange($event)"
        />
      </mat-form-field>
    </div>

    <div class="quantity-button quantity-up waves-effect" [class.disabled]="value >= schema.maximum" (click)="inc()">
      <span class="mdi mdi-plus mdi-24px"></span>
    </div>
  </div>
  <bg2-button *ngIf="not_nil_value$$ | async" style="margin: 0 0 0 auto" [type]="'action'" [message]="'WIDGETS.EVENT_FORM.CONTROL.Don\'t specify' | translate"  (btn_click)="reset()">
  </bg2-button>

  <form-element-previous
    *ngIf="previous$$ | async; let previous"
    [previous]="previous"
    [previous_btn]="(value$$ | async) !== previous?.value"
    (reset_previous)="resetPrevious()"
  ></form-element-previous>
</ng-container>
