import { ChangeDetectionStrategy, Component, NgZone, OnInit } from '@angular/core';

import { Observable } from 'rxjs';
import { switchMap } from 'rxjs';

import { UsersApiService, Beeguard2Api } from 'app/core';
import { User } from 'app/models';

import { EfSelectWidgetComponent } from '../select/select.widget';
import { TranslateService } from '@ngx-translate/core';
import { AppStateService } from 'app/core/app-state.service';
import { DialogsService } from 'app/widgets/dialogs-modals/dialogs.service';
import { replay } from '@bg2app/tools/rxjs';


@Component({
  selector: 'bg2-user-widget',
  templateUrl: './user.widget.html',
  styleUrls: ['./user.widget.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class EfBg2UserWidget extends EfSelectWidgetComponent implements OnInit {

  selected_user$$: Observable<User>;

  constructor(
    bg2Api: Beeguard2Api,
    public userApi: UsersApiService,
    protected translate: TranslateService,
    public appState: AppStateService,
    protected dialogs: DialogsService,
    protected readonly _ng_zone: NgZone) {
    super(bg2Api, translate, appState, dialogs, _ng_zone);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.selected_user$$ = this.value$$.pipe(
      switchMap(user_id => this.userApi.getUserObj(user_id)),
      replay()
    );
  }

}
