import { Location } from './entities';
import { DRDevice } from './devices';

import { ConfigExportDatatableColumn } from 'app/typings/export';

export interface ExportHeader {
  name: string;
  label: string;
  sub_headers: ExportHeader[];
  export?: ConfigExportDatatableColumn;
}

export interface ExportedRow {
  [header: string]: {
    [subHeader: string]: any;
  };
}

/**
 * Object that represents an export-ready data page.
 * In the end, it must be an array of this type.
 */
export interface ExportReadyDataPage {
  location?: Location;
  device?: DRDevice;
  ready_data: any[][];
}

export enum ExportFileExtension {
  XLSX = 'xlsx',
  CSV = 'csv',
}
