import {
  of,
  map,
  tap,
  take,
  filter,
  concat,
  Subject,
  forkJoin,
  switchMap,
  takeUntil,
  catchError,
  Observable,
  throwError,
  combineLatest,
  withLatestFrom,
  BehaviorSubject,
  distinctUntilChanged,
  debounceTime,
} from 'rxjs';

import { ISchema } from 'ngx-schema-form';

import {
  get,
  set,
  keys,
  pick,
  clone,
  isNil,
  union,
  values,
  toPairs,
  flatten,
  isArray,
  isEmpty,
  isEqual,
  cloneDeep,
  isUndefined,
} from 'lodash-es';

import { TranslateService } from '@ngx-translate/core';

import { parseDate, forceArray } from 'app/misc/tools';
import { distinctUntilRealChanged, replay, robustCombineLatest, waitForNotNilValue } from '@bg2app/tools/rxjs';

import { Beeguard2Api } from 'app/core/api/main/beeguard2-api-service';
import { ConsoleLoggerService } from 'app/core/console-logger.service';

import { User } from '..';
import { Entity, isOlder } from '..';
import { ExportHeader, ExportedRow } from '../export';
import { Dictionary } from 'app/typings/core/interfaces';
import { Event as EventInterface } from 'app/core/api-swagger/beeguard2';

/** */
interface IEventGlobalSchemaNeededEntity {
  /** */
  type: string;

  /** */
  label?: string;

  /** */
  event_description: string;

  /** */
  readonly?: boolean;

  /** */
  multiple?: boolean;

  /** */
  nullable?: boolean;

  /** */
  dependencies?: string[];

  /** */
  dynamic?: {
    /** */
    from: string;

    /** */
    path: string;
  };

  /** */
  with_in?: {
    /** */
    from: string;

    /** */
    path: string;
  };

  /** */
  [key: string]: any;
}

/** */
export interface IEventGlobalSchema {
  /** */
  beta?: boolean;

  /** */
  type: string;

  /** */
  doc?: string;

  /** */
  event_description: string;

  /** */
  needed_entities?: {
    /** */
    warehouse?: IEventGlobalSchemaNeededEntity;

    /** */
    exploitation?: IEventGlobalSchemaNeededEntity;

    /** */
    location?: IEventGlobalSchemaNeededEntity;

    /** */
    apiary?: IEventGlobalSchemaNeededEntity;

    /** */
    hives?: IEventGlobalSchemaNeededEntity;

    /**
     * Global definition if key type is not defined.
     */
    [key: string]: IEventGlobalSchemaNeededEntity;
  };

  /** */
  date_schema: {
    /** */
    title?: string;

    /** */
    options?: {
      pickerType?: 'calendar' | 'both' | 'date';
    };
  };

  /** */
  data_schema: ISchema;
}

/** */
export interface EventSchema {
  data_schema: ISchema;
  date_schema: ISchema;
  beta?: boolean;
  description?: string;
  doc?: string;
  event_description?: string;
  type?: string;
  needed_entities: { [role: string]: any };
}

/** */
export interface I18NParams {
  /** */
  data: any;

  /** */
  computed: any;

  /** */
  entities: Dictionary<Dictionary<any>>;
}

/** */
export interface ApplyToEntity {
  /** */
  ro?: boolean;

  /** */
  type?: string;

  /** */
  role?: string;

  /** */
  entity_id: number;

  /** */
  previous_event_id?: number;
}

/** */
export interface ApplyTo extends Dictionary<any> {
  /** */
  [role: string]: ApplyToEntity | ApplyToEntity[];
}

/** */
export interface NeededEntity {
  /** */
  type: string;

  /** */
  title?: string;

  /** */
  description?: string;

  /** */
  [key: string]: any;
}

/** */
export interface NeededEntities {
  /** */
  [role: string]: NeededEntity;
}

/** */
export enum RunState {
  /** */
  new = 'new',

  /** */
  running = 'running',

  /** */
  error = 'error',

  /** */
  aborting = 'aborting',

  /** */
  stopped = 'stopped',

  /** */
  done = 'done',
}

/** */
export class Run {
  /** */
  id: string;

  /** */
  state: RunState;

  /** */
  events: number[];

  /** */
  error?: {
    /** */
    date: Date;

    /** */
    description: string;

    /** */
    type: string;

    /** */
    source: {
      /** */
      event_id: number;

      /** */
      event_type: string;

      /** */
      event_date: Date;

      /** */
      error: {
        /** */
        type: string;

        /** */
        description: string;
      };
    };
  };
}

/** */
export class EventDict {
  /** */
  data: any;

  /** */
  author?: number;

  /** */
  date: string; // ISO Date

  /** */
  apply_to: { [role: string]: number | number[] };
}

/** */
export type IEventData = Dictionary<any>;

/**
 * @template TApplyTo Generic type for applyto entity
 */
export class Event<TApplyTo extends ApplyTo = any, TData extends IEventData = any> implements EventInterface {
  // #region -> (model basics)

  /** */
  protected _logger: ConsoleLoggerService;

  /** */
  private _update$ = new BehaviorSubject<EventDict>(null);

  /** */
  public update$$ = this._update$.asObservable().pipe(waitForNotNilValue(), replay());

  /** */
  constructor(protected bg2Api: Beeguard2Api) {
    this.resetLogger();

    // Auto-self subscription
    this.apply_to$$.subscribe(() => this.update_changed());
    this.data$$.subscribe(() => this.update_changed());
    this.date$$.subscribe(() => this.update_changed());
    this.type$$.subscribe();
    this.user_id$$.subscribe();
    this.update$$.subscribe();
  }

  // #endregion

  // #region -> (event ID management)

  /** */
  private _id$ = new BehaviorSubject<number>(null);

  /** */
  public id$$ = this._id$.asObservable().pipe(distinctUntilRealChanged(), replay());

  /** */
  public get id(): number {
    return this._id$.getValue();
  }

  /** */
  public set id(_id: number) {
    this._id$.next(_id);
  }

  // #endregion

  // #region -> (event type management)

  /** */
  private _type$: BehaviorSubject<string> = new BehaviorSubject<string>(null);

  /** */
  public type$$: Observable<string> = this._type$.asObservable().pipe(distinctUntilChanged(), replay());

  /** */
  public get type(): string {
    return this._type$.getValue();
  }

  /** */
  public set type(type: string) {
    this._type$.next(type);
  }

  // #endregion

  // #region -> (event date management)

  /** */
  private _date: Date;

  /** */
  private _tmp_date: Date;

  /** */
  private has_date_changed$ = new BehaviorSubject<boolean>(false);

  /** */
  public date_changed$$ = this.has_date_changed$.asObservable().pipe(distinctUntilRealChanged(), replay());

  /** */
  public get date_changed() {
    return this.has_date_changed$.getValue();
  }

  /** */
  protected _date$ = new Subject<Date>();

  /** */
  public date$$ = this._date$.asObservable().pipe(distinctUntilRealChanged(), replay());

  /** */
  public get date(): Date {
    if (isNil(this._tmp_date)) {
      this._tmp_date = clone(this._date);
    }

    return this._tmp_date;
  }

  /** */
  public set date(val: Date) {
    if (!isNil(val) && !val.getMonth) {
      val = parseDate(val);
    }

    if (!isEqual(val, this.date)) {
      this._tmp_date = val;
      this._date$.next(val);
    }
  }

  // #endregion

  // #region -> (event author management)

  /** */
  protected _user_id: number;

  /** */
  protected _user_id$ = new Subject<number>();

  /** */
  public user_id$$ = this._user_id$.asObservable().pipe(replay());

  /** */
  public set user_id(val: number) {
    this._user_id = val;
    this._user_id$.next(val);
  }

  /** */
  public get user_id(): number {
    return this._user_id;
  }

  /** */
  public author$$: Observable<User> = this.user_id$$.pipe(
    switchMap(user_id => this.bg2Api.userApi.fetch_user$(user_id)),
    replay()
  );

  // #endregion

  // #region -> (event apply_to management)

  /** */
  private _has_apply_to_changed$ = new BehaviorSubject<boolean>(false);

  /** */
  public apply_to_changed$$ = this._has_apply_to_changed$.asObservable().pipe(distinctUntilRealChanged(), replay());

  /** */
  public get apply_to_changed() {
    return this._has_apply_to_changed$.getValue();
  }

  /** */
  protected _apply_to: TApplyTo = {} as TApplyTo;

  /** */
  protected _tmp_apply_to: TApplyTo = {} as TApplyTo;

  /** */
  protected _apply_to$ = new BehaviorSubject<TApplyTo>({} as TApplyTo);

  /** */
  public apply_to$$ = this._apply_to$.asObservable();

  /** */
  public get apply_to(): TApplyTo {
    if (isNil(this._tmp_apply_to)) {
      this._tmp_apply_to = clone(this._apply_to);
    }

    return this._tmp_apply_to;
  }

  /** */
  public set apply_to(apply_to: TApplyTo) {
    const _clean = (val: ApplyToEntity | ApplyToEntity[]) => pick(val, 'entity_id');

    keys(apply_to).map(role => {
      const operand = apply_to[role];

      if (isArray(operand)) {
        set(
          apply_to,
          role,
          operand.map(val => _clean(val))
        );
      } else {
        set(apply_to, role, _clean(operand));
      }
    });

    if (!isEqual(apply_to, this.apply_to)) {
      this._tmp_apply_to = apply_to;
      this._apply_to$.next(apply_to);
    }
  }

  // #endregion

  // #region -> (event data management)

  /** */
  private _has_data_changed$ = new BehaviorSubject<boolean>(false);

  /** */
  public data_changed$$ = this._has_data_changed$.asObservable().pipe(distinctUntilRealChanged(), replay());

  /** */
  public get data_changed() {
    return this._has_data_changed$.getValue();
  }

  /** */
  protected default_data: TData = null;

  /** */
  protected _data: TData = null;

  /** */
  protected _tmp_data: TData;

  /** */
  private _data$: BehaviorSubject<TData> = new BehaviorSubject<TData>(null);

  /** */
  public data$$: Observable<TData> = this._data$.asObservable();

  /** */
  public get data(): TData {
    if (!this._tmp_data) {
      this._tmp_data = cloneDeep(this._data);
    }

    return this._tmp_data;
  }

  /** */
  public set data(data: TData) {
    if (!isEqual(data, this.data)) {
      this._tmp_data = data;
      this._data$.next(data);
    }
  }

  /** */
  public is_older_than(other: Event): boolean {
    return isOlder(this.date, this.id, other.date, other.id);
  }

  // #endregion

  // #region -> (event ghost management)

  /** */
  public is_ghost$$ = this.id$$.pipe(
    map(id => id < 0),
    replay()
  );

  /** */
  public get is_ghost(): boolean {
    return this.id < 0;
  }

  // #endregion

  // todo cleaning

  /**
   * Default modal view configuration.
   */
  public vconf = {
    update_modal: 'update_event',
  };

  // -- New Entities --

  private new_entities: [Entity, string][] = [];
  public addNewEntity(entity: Entity, role: string) {
    this.setOperand(role, entity.id);
    this.new_entities.push([entity, role]);
  }

  public get has_changed(): boolean {
    return this.date_changed || this.apply_to_changed || this.data_changed;
  }

  public has_changed$$ = combineLatest([this.date_changed$$, this.apply_to_changed$$, this.data_changed$$]).pipe(
    map(([date_changed, apply_to_changed, data_changed]) => date_changed || apply_to_changed || data_changed),
    distinctUntilChanged(),
    replay()
  );

  public schema: IEventGlobalSchema;

  public schema$$: Observable<IEventGlobalSchema> = this.type$$.pipe(
    filter(type => !isNil(type)),
    switchMap(type => this.bg2Api.getEventSchema(type)),
    tap(schema => this.schema = schema),
    // finalize(() => console.log(this.desc, 'fin schema$$')),
    replay()
  );

  public data_schema$$ = this.schema$$.pipe(map(schema => schema.data_schema));

  public run: Run = null;

  public entities$: Observable<{ [role: string]: Entity[] | Entity }> = this.trackEntities();
  public entities$$ = this.entities$.pipe(replay());

  public setup_entities$$: Observable<{ [role: string]: Entity[] }> = this.schema$$.pipe(
    map(schema => schema.needed_entities),
    map(needed_entities => keys(needed_entities).filter(role => needed_entities[role].setup_event)),
    switchMap(roles => this.trackEntities(roles, true)),
    map(entities => {
      const filtered_entities: { [role: string]: Entity[] } = {};
      keys(entities).map(role => {
        const filtered = entities[role].filter(entity => entity.initial_setup_event_id === this.id);
        if (filtered.length) {
          filtered_entities[role] = filtered;
        }
      });
      return filtered_entities;
    })
  );

  public getExportRowBuilder(headers: ExportHeader[], translateService: TranslateService): Observable<ExportedRow> {
    let row_builder = of({} as ExportedRow);
    headers
      .filter(header => header.name === 'base_informations')
      .forEach((header: ExportHeader) => {
        // Build only "base_informations" cols
        row_builder = row_builder.pipe(
          map((row: ExportedRow) => {
            const base_informations = row.base_informations || {};
            header.sub_headers.forEach((subHeader: ExportHeader) => {
              if (subHeader.name === 'date') {
                // Build col base_informations / date
                base_informations.date = this.date || '';
              } else if (subHeader.name === 'event_type') {
                // Build col base_informations / event type
                base_informations.event_type = translateService.instant(this.type_i18n) || '';
              }
            });
            row.base_informations = base_informations;
            return row;
          })
        );
      });
    return row_builder;
  }

  public static type_i18n(type: string) {
    return `EVENT.TYPE.${type}`;
  }

  public resetLogger() {
    this._logger = new ConsoleLoggerService(this.desc, true);
  }

  get type_i18n() {
    return Event.type_i18n(this.type);
  }

  get desc() {
    return `${this.type}#${this.id ? this.id : '?'}`;
  }

  /** Get enities ids (number of list of numbers if multiple)
   *
   * Used to build form
   */
  get apply_to_ids(): { [role: string]: number | number[] } {
    const _apply_to_ids: Dictionary<any> = {};
    keys(this.apply_to).map(role => {
      const ato = this.apply_to[role];
      if (isArray(ato)) {
        _apply_to_ids[role] = ato.map(conf => conf.entity_id);
      } else {
        _apply_to_ids[role] = ato.entity_id;
      }
    });
    return _apply_to_ids;
  }

  /** Get enities role from entities ids
   *
   */
  get apply_to_role_by_id(): { [id: number]: string } {
    const ato = this.apply_to_ids;
    const by_id: { [id: number]: string } = {};
    keys(ato).map(role => {
      const ids = ato[role];
      if (isArray(ids)) {
        ids.forEach(id => (by_id[id] = role));
      } else {
        by_id[ids] = role;
      }
    });
    return by_id;
  }

  public unsubscribe() {}

  private saved() {
    this._data = cloneDeep(this.data);
    this._apply_to = cloneDeep(this.apply_to);
    this._date = cloneDeep(this.date);
    // console.log(this.desc, 'saved', this._data);
    this.update_changed();
  }

  private update_changed() {
    this.has_date_changed$.next(!isEqual(this._date, this._tmp_date));
    this._has_data_changed$.next(!isEqual(this._data, this._tmp_data));
    this._has_apply_to_changed$.next(!isEqual(this._tmp_apply_to, this._apply_to));
    // console.log(this.desc, this.date_changed, this.data_changed, this.apply_to_changed);
  }

  protected _applyLocally(entities: Dictionary<Entity | Entity[]>): Event {
    this._logger.error('Abstract event local apply');
    return this;
  }

  public applyLocally(): Observable<Event> {
    return this.getEntities().pipe(map(entities => this._applyLocally(entities)));
  }

  protected since() {
    return {
      date: this.date.toISOString(),
      event_id: this.id,
    };
  }

  protected _unApplyLocally(entities: Dictionary<Entity | Entity[]>): Event {
    values(entities).map(entity => (isArray(entity) ? entity.map(_e => _e.rmLocalState(this.id)) : entity.rmLocalState(this.id)));
    return this;
  }

  public unApplyLocally(): Observable<Event> {
    return this.getEntities().pipe(map(entities => this._unApplyLocally(entities)));
  }

  private _undo_save: Observable<any>[] = [];

  private _handle_error_in_save() {
    console.log(`Error in event save for #${this.id}`);
    let _undo_save$$ = of(null);
    this._undo_save.reverse().forEach(action => {
      _undo_save$$ = _undo_save$$.pipe(switchMap(() => action));
    });
    // We delete the entity that has been created (as no setup event is possible)
    return _undo_save$$;
  }

  // BG2 API calls
  public save(): Observable<Event> {
    // create each new entities
    this.new_entities.forEach(([entity, role]) => {
      this.setOperand(role, entity.id);
    });
    const entities_to_create = this.new_entities.filter(([entity, role]) => entity.id < 0);
    const save_enitites$$ = robustCombineLatest(
      entities_to_create.map(([entity, role]) => {
        const prev_entity_id = entity.id;
        return entity.save().pipe(
          // Improtant: as entity are created, we modify this event and all ghost event to the newly created entity id.
          //   - we exlpicitely do setOperand on this event. This may also be done by changeEntityIdInGhostEvents but only if this is a ghost event it is not always the case.
          //   - _undo_save is here to ensure that if pipeline fail elsewhere the entity is deleted, and old id back in use
          tap(() => this.setOperand(role, entity.id)),
          tap(() => this.bg2Api.changeEntityIdInGhostEvents(prev_entity_id, entity.id)),
          tap(() => {
            this._undo_save.push(
              entity.delete().pipe(
                tap(() => (entity.id = prev_entity_id)),
                tap(() => this.setOperand(role, prev_entity_id)),
                tap(() => this.bg2Api.changeEntityIdInGhostEvents(entity.id, prev_entity_id))
              )
            );
          }),
          map(res => ({ entity, role }))
        );
      })
    );

    const save_query = save_enitites$$.pipe(
      switchMap(() => {
        if (this.id && this.id > 0) {
          return this.bg2Api.update_event$(this.id, this);
        } else {
          return this.bg2Api.create_event$(this);
        }
      }),
      catchError((error: unknown) => this._handle_error_in_save().pipe(switchMap(() => throwError(() => error)))),
      tap(() => this.saved()),
      tap((event_ret: any) => {
        const event = event_ret.event;
        this.id = event.id;
      }),
      map(() => this)
    );

    return save_query;
  }

  public delete() {
    let _setup_entities: Entity[];
    return this.setup_entities$$.pipe(
      // manage deletion of setup entities
      map(entities_by_role => flatten(values(entities_by_role))),
      tap(setup_entities => (_setup_entities = setup_entities)),
      switchMap(() => this.bg2Api.delete_event$(this.id)),
      switchMap(() => {
        if (_setup_entities.length === 0) {
          return of(null);
        }
        return forkJoin(_setup_entities.map(entity => entity.delete(true)));
      }),
      // TODO recreate event if fail ?
      tap(() => this.saved())
    );
  }

  public getEntities(roles: string[], force_array: true): Observable<{ [role: string]: Entity[] }>;
  public getEntities(roles?: string[], force_array?: boolean): Observable<{ [role: string]: Entity[] | Entity }>;
  public getEntities(roles?: string[], force_array = false): Observable<any> {
    return this.trackEntities(roles, force_array).pipe(take(1));
  }

  public trackEntities(roles: string[], force_array: true): Observable<{ [role: string]: Entity[] }>;
  public trackEntities(roles?: string[], force_array?: boolean): Observable<{ [role: string]: Entity[] | Entity }>;
  public trackEntities(roles?: string[], force_array = false): Observable<any> {
    return this.apply_to$$.pipe(
      switchMap(apply_to => {
        // console.log(this.desc, apply_to);
        const entity_ids: any[] = [];
        const id2role: { [entity_id: number]: string[] } = {};
        keys(apply_to).map((role: string) => {
          this.getApplyToConf(role).map(conf => {
            if ((isNil(roles) || roles.includes(role)) && !isNil(conf.entity_id)) {
              entity_ids.push(conf.entity_id);
              if (!id2role[conf.entity_id]) {
                id2role[conf.entity_id] = [role];
              } else {
                id2role[conf.entity_id].push(role);
              }
            }
          });
        });
        return this.bg2Api
          .getEntitiesObj(
            entity_ids,
            undefined, // type
            true // show_archived
          )
          .pipe(
            map(entity_list => {
              const entities: { [role: string]: Entity[] | Entity } = {};
              if (isNil(entity_list)) {
                return {};
              }
              entity_list.map(entity => {
                const _roles = id2role[entity.id] || [];
                // Note this may be null if entity change id during save
                _roles.map(role => {
                  if (force_array || isArray(this.apply_to[role])) {
                    if (isArray(entities[role])) {
                      (entities[role] as Entity[]).push(entity);
                    } else {
                      entities[role] = [entity];
                    }
                  } else {
                    entities[role] = entity;
                  }
                });
              });
              return entities;
            })
          );
      })
    );
  }

  protected whenLoaded() {}

  public get(path: string) {
    if (path === 'date') {
      return this.date;
    } else if (path.startsWith('apply_to.')) {
      const role = path.slice('apply_to.'.length);
      const ato = this.apply_to[role];
      if (isArray(ato)) {
        return ato.map(_ato => _ato.entity_id);
      } else {
        return ato.entity_id;
      }
    } else if (path.startsWith('data.')) {
      return get(this.data, path.slice('data.'.length));
    }
    return;
  }

  public setOperand(role: string, entity_id: null | number | number[]) {
    if (!isEqual(entity_id, this.getOperands(role))) {
      if (isNil(entity_id)) {
        delete this.apply_to[role];
      } else if (isArray(entity_id)) {
        // Manage multiple entity
        set(
          this.apply_to,
          role,
          entity_id.map(eid => ({
            entity_id: eid,
          }))
        );
      } else {
        set(this.apply_to, role, { entity_id });
      }
      this._apply_to$.next(this.apply_to);
    }
  }

  public getApplyToConf(role: string): ApplyToEntity[] {
    const ato = this.apply_to[role];
    return forceArray<ApplyToEntity>(ato);
  }

  /**
   * Returns list of entity id for a given role.
   *
   * Warning: it always returns a list even if it is not a "multiple"
   *
   * Note: this is private as to access event's entity one should better use observables
   */
  private getOperands(role: string): number[] {
    return this.getApplyToConf(role).map(conf => conf.entity_id);
  }

  public createOperand(role: string) {
    set(this.apply_to, role, {
      entity_id: -1,
    });
  }

  public update(date: Date, apply_to: { [role: string]: number | number[] }, data: any) {
    // console.warn(this.desc, 'vvv Event.update(date, apply_to, data)', this.type);
    // console.log({date, apply_to, data});

    if (!isUndefined(date)) {
      const new_date = !isNil(date) ? parseDate(date) : null;
      const new_date_ts = !isNil(new_date) ? new_date.getTime() : null;
      const e_date_ts = !isNil(this.date) ? this.date.getTime() : null;
      if (new_date_ts !== e_date_ts) {
        this.date = new_date;
        // console.log(this.desc, '-> change date', new_date_ts, e_date_ts);
      }
    }

    // Update event operands
    // Apply modification on apply_to
    // NOTE: we RAZ all entities excepts the "setup" ones
    let roles_from_schema: any[] = [];
    if (this.schema && this.schema.needed_entities) {
      roles_from_schema = keys(this.schema.needed_entities).filter(role => {
        const conf = this.schema.needed_entities[role];
        return !conf.setup_event;
      });
    }
    const all_roles = union(roles_from_schema, keys(apply_to));
    this.updateOperands(all_roles, apply_to);

    // Update event data
    if (!isNil(data) && !isEmpty(data)) {
      // console.log(this.desc, `-> change data`);
      this.data = data;
    } else {
      // console.log(this.desc, `-> raz data`);
      // IMPORTANT: raz data of null/undefined
      // this.data = {};
      this.data = clone(this.default_data);
    }
    // Ask to udpate model directly (from event values)
    this._update$.next(this.export());
  }

  /**
   * Internal method to update event operands
   */
  protected updateOperands(all_roles: any, apply_to: { [role: string]: number | number[] }) {
    // console.log(this.desc, 'updateOperands(apply_to)');
    // console.log(apply_to);
    const changed_operand: { [role: string]: boolean } = {};
    if (!apply_to) {
      return {};
    }
    all_roles.map((role: any) => {
      const ato = apply_to[role];
      const entity_ids: number[] = forceArray(ato);
      const actual_value = this.getOperands(role);

      // console.log({role,  entity_ids, actual_value});
      if (!isEqual(entity_ids, actual_value)) {
        changed_operand[role] = true;
      }
    });
    // console.log(this.changes)
    // Set the values
    keys(changed_operand)
      .filter(role => changed_operand[role])
      .map((role: string) => {
        const entity_id = apply_to[role];
        // console.log(`-> change apply_to ${role}=${entity_id}`);
        this.setOperand(role, entity_id);
      });
  }

  public getRole(entity_id: number): string {
    const roles = keys(this.apply_to).filter(role => {
      const ato = this.getApplyToConf(role);
      const _ato = ato.filter(conf => entity_id === conf.entity_id);
      return _ato.length >= 1;
    });
    return roles.length > 0 ? roles[0] : null;
  }

  private _event_desc_sub: { [key: number]: Observable<string> } = {};
  public getDesc(translate_service: TranslateService, from_entity_id?: number, desc?: string, links: boolean = true): Observable<string> {
    if (isNil(from_entity_id)) {
      from_entity_id = -1;
    }
    // Generic description
    if (isNil(this._event_desc_sub[from_entity_id])) {
      let role: string = null;
      if (from_entity_id >= 0) {
        role = this.getRole(from_entity_id);
      }
      const event_desc_obs = this.schema$$.pipe(
        switchMap(schema => this.entities$$.pipe(map(entities => ({ entities, schema })))),
        switchMap(({ entities, schema }) => this.getI18nParams(translate_service, entities).pipe(map(i18nData => ({ schema, i18nData })))),
        switchMap(({ schema, i18nData }) => {
          let desc$ = of(desc);
          if (isNil(desc)) {
            desc$ = this.getDescKey(role, schema, i18nData);
          }
          return desc$.pipe(map(desc_key => ({ i18nData, desc_key })));
        }),
        // tap(ret => console.log(this.desc, 'new i18n', ret)),
        switchMap(({ desc_key, i18nData }) => translate_service.stream(desc_key, i18nData)),
        replay()
      );
      this._event_desc_sub[from_entity_id] = concat(
        translate_service.stream('ALL.COMMON.Loading').pipe(
          withLatestFrom(translate_service.stream(this.type_i18n)),
          map(([loading, type]) => `${type} (${loading})`),
          // tap((val) => console.log(this.desc, 'LOAD', val)),
          takeUntil(event_desc_obs)
        ),
        event_desc_obs
      ).pipe(map(desc_var => (!links ? desc_var.replace(/(<([^>]+)>)/gi, '') : desc_var)));
    }
    return this._event_desc_sub[from_entity_id];
  }

  public deserialize(input: EventInterface): Event {
    Object.assign(this, input);
    this.resetLogger();
    this.whenLoaded();
    this.saved();
    // console.log(this.desc, 'deserialize');
    return this;
  }

  protected getDescKey(role: string, schema: IEventGlobalSchema, i18nData: I18NParams): Observable<string> {
    let desc_key = this.type_i18n;
    const needed_entities = schema.needed_entities;
    if (!isNil(role) && !isNil(needed_entities) && !isNil(needed_entities[role])) {
      desc_key = needed_entities[role].event_description || desc_key;
    } else if (!isNil(schema.event_description)) {
      desc_key = schema.event_description;
    }
    return of(desc_key);
  }

  /** Build params for i18n string translation
   */
  protected getI18nParams(
    translate_service: TranslateService,
    entities_by_role: { [role: string]: Entity[] | Entity }
  ): Observable<I18NParams> {
    const data = cloneDeep(this.data);
    let entities_dict$$ = of<Dictionary<Dictionary<any>>>({});

    if (!isNil(entities_by_role) && keys(entities_by_role).length > 0) {
      const dict_by_role$$ = toPairs(entities_by_role).map(([role, entity]) => {
        if (isArray(entity)) {
          return combineLatest(entity.map(_ent => _ent.asDict$$())).pipe(
            // tap(ret => console.log(this.desc, 'new edict', role, ret)),
            map(dict => ({ role, dict }))
          );
        } else {
          return entity.asDict$$().pipe(
            // tap(ret => console.log(this.desc, 'new edict', entity, role, ret)),
            map(dict => ({ role, dict }))
          );
        }
      });

      entities_dict$$ = combineLatest(dict_by_role$$).pipe(
        map(role_dicts => {
          const dicts: Dictionary<Dictionary<any> | Dictionary<any>[]> = {};

          role_dicts.forEach(value => (dicts[value.role] = value.dict));

          return dicts;
        })
      );
    }

    return entities_dict$$.pipe(
      debounceTime(50),
      map(entities_dict => ({
        data,
        computed: {},
        entities: entities_dict,
      }))
    );
  }

  public export(): EventDict {
    const model: any = {
      apply_to: cloneDeep(this.apply_to_ids),
      data: cloneDeep(this.data),
      date: undefined,
      author: this.user_id,
    };
    if (!isNil(this.date)) {
      if (this.date.getMonth) {
        model.date = this.date.toISOString();
      } else {
        model.date = this.date;
      }
    }
    return model;
  }

  public toJSON() {
    const obj: Dictionary<any> = {};
    if (this.id) {
      obj.id = this.id;
    }
    obj.type = this.type;
    obj.date = this.date;
    obj.data = this.data;
    obj.apply_to = this.apply_to;
    return obj;
  }
}
