import { Component, ChangeDetectionStrategy } from '@angular/core';

import { Observable, combineLatest, BehaviorSubject, of } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, filter, map, switchMap, take, tap } from 'rxjs/operators';

import { isNil, cloneDeep, find } from 'lodash-es';
import { ISchema } from 'ngx-schema-form';

import { marker as i18n } from '@biesbjerg/ngx-translate-extract-marker';

import { DRDevice } from 'app/models';

import { AbstractDialogComponent } from 'app/widgets/dialogs-modals/abstract-dialog.component';
import { catchErrorInDialog, distinctUntilRealChanged, replay } from '@bg2app/tools/rxjs';
import { DeviceApi } from 'app/core';
import { ConsoleLoggerService } from 'app/core/console-logger.service';
import { DialogsService } from 'app/widgets/dialogs-modals';
import { DialogElement } from 'app/widgets/dialogs-modals/dialog-parts/dialog-div/dialog-div.component';
import { GetBulkOtaResponse, PostBulkOtaResponse } from 'app/core/api-swagger/device';

import { DevicesDialogParams } from '../../devices-dialog-params';



const SEPARATOR = '@-§-@';

@Component({
  selector: 'bg2-devices-ota-dialog',
  templateUrl: './devices-ota.dialog.html',
  styleUrls: ['./devices-ota.dialog.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DevicesOtaDialogComponent extends AbstractDialogComponent<DevicesDialogParams, any> {

  DEFAULT_SCHEMA = {
    type: 'object',
    required: ["devices"],
    properties: {
      devices: {
        label: i18n('VIEWS.DEVICES.OTA-DIALOG.Selected devices'),
        type: 'array',
        widget: 'ng-mat-select',
        options: {
          multiple: true
        },
        minItems: 1,
        default: [] as any[],
        items: {
          type: 'number',
          oneOf: [
            // {
            //   enum: ['WG'],
            //   label: 'WG'
            // },
          ] as any[]
        }
      },
      firmware: {
        label: i18n('VIEWS.DEVICES.OTA-DIALOG.Firmware'),
        type: 'string',
        widget: 'ng-mat-select',
        options: {
          multiple: false
        },
        oneOf: [
          // {
          //   enum: ['WG'],
          //   label: 'WG'
          // },
        ] as any[]
      },
      disable_previous_version_check: {
        type: 'boolean',
        description_on: i18n('VIEWS.DEVICES.OTA-DIALOG.Unactivate version check for OTA order'),
      },
      force: {
        type: 'boolean',
        description_on: i18n('VIEWS.DEVICES.OTA-DIALOG.Force non-compatible FW'),
      }
    }
  };

  protected _logger = new ConsoleLoggerService(this.constructor.name, true);

  private _loading$$ = new BehaviorSubject<boolean>(true);
  public schema_loading$$ = this._loading$$.asObservable();

  private set loading(val: boolean) {
    this._loading$$.next(val);
  }

  public devices$$: Observable<DRDevice[]> = this.input_params$$.pipe(
    map(params => params.devices),
    tap(() => this.loading = true),
    replay()
  );

  private _form_model$$ = new BehaviorSubject<any>({});
  public form_model$$ = this._form_model$$.pipe(
    debounceTime(300),
    distinctUntilRealChanged(),
    tap(data => this._logger.debug('new data', data)),
    replay(),
  );

  public force$$ = this.form_model$$.pipe(
    map(data => data?.force || false),
    distinctUntilRealChanged(),
    debounceTime(400),
    replay()
  )

  public bulk_ota_info$$: Observable<GetBulkOtaResponse> = this.devices$$.pipe(
    switchMap(devices => this.device_api.fetch_OTA_bulk$(devices.map(device => device.imei))),
    catchErrorInDialog(this._dialogs),
    catchError(() => {
      this.close(false);
      return of(null);
    }),
    filter(data => !isNil(data)),
    replay()
  )

  public all_possible_firmwares$$ = this.bulk_ota_info$$.pipe(
    map(data => data.possible_firmwares),
    replay()
  );

  public possible_firmwares$$ = combineLatest({
    all_possible_firmwares: this.all_possible_firmwares$$,
    force: this.force$$
  }).pipe(
    map(({all_possible_firmwares, force}) => all_possible_firmwares.filter(
      firmware => force || firmware.is_compatible
    )),
    replay()
  );


  public selected_fw$$ = this.form_model$$.pipe(
    switchMap(model => {
      if (!model?.firmware) {
        return of(null);
      }
      const firmware_name = model.firmware.split(SEPARATOR)[0];
      const firmware_filename = model.firmware.split(SEPARATOR)[1];
      return this.all_possible_firmwares$$.pipe(
        map(all_fw => find(all_fw, fw => fw.filename === firmware_filename && fw.name === firmware_name))
      );
    }),
  );


  public devices_types_versions$$ = this.bulk_ota_info$$.pipe(
    map(data => data.devices_types_versions),
    replay()
  );

  public devices_ota_status$$ = this.bulk_ota_info$$.pipe(
    map(data => data.devices_ota_status),
    replay()
  );

  public schema$$ = combineLatest({
    devices_types_versions: this.devices_types_versions$$,
    possible_firmwares: this.possible_firmwares$$
  }).pipe(
    map(({devices_types_versions, possible_firmwares}) => {
      const schema = cloneDeep(this.DEFAULT_SCHEMA);
      schema.properties.devices.items.oneOf = devices_types_versions.map(device_types_versions => ({
        enum: [device_types_versions.did],
        label: `${device_types_versions.did} (SWV: ${device_types_versions.software_version})`
      }));
      schema.properties.devices.default = devices_types_versions.map(device_types_versions => device_types_versions.did);

      schema.properties.firmware.oneOf = possible_firmwares.map(firmware => ({
        enum: [`${firmware.name}${SEPARATOR}${firmware.filename}`],
        label: `[SWV ${firmware.software_version}] ${firmware.name}/${firmware.filename} ${!firmware.is_compatible ? 'NOT-COMPATIBLE' : ''}`
      }));
      schema.properties.firmware.oneOf.push({
        enum: [""],
        label: `Cancel OTA`
      })
      return schema as ISchema;
    }),
    tap(() => this.loading = false),
    replay()
  );

  private _form_valid$$ = new BehaviorSubject<boolean>(false);
  public form_valid$$ = this._form_valid$$.pipe(
    distinctUntilChanged(),
    replay()
  );

  private _submit_progress_total$$ = new BehaviorSubject<number>(null);
  public submit_progress_total$$ = this._submit_progress_total$$.pipe(
    distinctUntilChanged(),
    replay()
  );

  private _submit_progress_current$$ = new BehaviorSubject<number>(null);
  public submit_progress_current$$ = this._submit_progress_current$$.pipe(
    distinctUntilChanged(),
    replay()
  );

  public submit_progress_percent$$ = combineLatest([this.submit_progress_total$$, this.submit_progress_current$$]).pipe(
    map(([total, current]) => !total ? 0 : current / total),
    replay()
  );

  public submit_in_progress$$ = this.submit_progress_total$$.pipe(
    map(total => !isNil(total)),
    replay()
  );

  public setFormValid(val: any): void {
    this._form_valid$$.next(val);
  }

  constructor(private device_api: DeviceApi, private _dialogs: DialogsService) {
    super();
  }

  public onFormModelChanged(event: any) {
    this._logger.debug(event);
    this._form_model$$.next(event.value);
  }

  public logErrors(errors: any) {
    if (errors) {
      this._logger.log_error(errors);
    }
  }


  public submit(): void {
    this.form_model$$.pipe(
      take(1),
      switchMap(model => {
        const firmware_name = model.firmware.split(SEPARATOR)[0];
        const firmware_filename = model.firmware.split(SEPARATOR)[1];
        const ota_data = {
          dids: model.devices,
          firmware_name,
          firmware_filename,
          disable_previous_version_check: model.disable_previous_version_check || false,
          force: model.force || false
        }
        return this.device_api.create_OTA_bulk$(ota_data)
      }),
      catchErrorInDialog(this._dialogs),
      catchError(() => of(null)),
      filter(res => !isNil(res)),
      switchMap((res: PostBulkOtaResponse) => {
        let elements: DialogElement<void>[] = [
          {
            type: 'div',
            elements: {
              type: 'span',
              content: "OTA programmed:"
            }
          }
        ];
        res.actions.forEach(action => {
          elements.push({
            type: 'div',
            elements: {
              type: 'span',
              content: `- ${action}`
            }
          });
        });
        return this._dialogs.customizable({
          body: {
            type: 'div',
            elements: elements
          },
          footer: {
            buttons: {
              items: [
                {
                  type: 'button',
                  result: null,
                  content: i18n<string>('ALL.COMMON.OK'),
                },
              ],
            },
          },
        })
      })
    ).subscribe({
      next: () => {
        this.close(true);
      },
      error: () => {},
      complete: () => {},
    })
  }

  public close(value: boolean): void {
    this.complete(value);
  }

}
