<div *ngIf="content$$ | async; let content" [ngStyle]="content.styles" [ngClass]="content.class">
  <ng-container *ngFor="let element of elements$$ | async">
    <ng-container *ngIf="isSpan(element); let span_element">
      <span [ngStyle]="span_element.styles" [ngClass]="span_element.class">
        {{ span_element.content | translate: span_element.translateParams || {} }}
      </span>
    </ng-container>

    <ng-container *ngIf="isJSONDate(element); let element">
      <pre [ngStyle]="element.styles" [ngClass]="element.class">{{ element.content | json }}</pre>
    </ng-container>

    <ng-container *ngIf="isLogicalSep(element); let sep_element">
      <span class="logical-sep" [ngStyle]="sep_element.styles" [ngClass]="sep_element.class">
        <span class="text">
          <ng-container *ngIf="sep_element.messageType === 'OR'">{{ 'ALL.COMMON.Or' | translate }}</ng-container>
          <ng-container *ngIf="sep_element.messageType === 'AND'">{{ 'ALL.COMMON.and' | translate }}</ng-container>
        </span>
      </span>
    </ng-container>

    <ng-container *ngIf="isSchema(element); let schema_element">
      <explanation-schemas
        [ngStyle]="schema_element.styles"
        [ngClass]="schema_element.class"
        [schema_name]="schema_element.name"
        [data]="schema_element.data"
      ></explanation-schemas>
    </ng-container>

    <ng-container *ngIf="isTextarea(element); let textarea_element">
      <div [innerHtml]="textarea_element.content | markdown"></div>
    </ng-container>

    <ng-container *ngIf="isLink(element); let link_element">
      <a
        [ngStyle]="link_element.styles"
        [ngClass]="link_element.class"
        href="javascript:void(0)"
        (click)="link_element.action ? link_element.action() : onClickButton(link_element.result)"
      >
        {{ link_element.content | translate: link_element.translateParams || {} }}
      </a>
    </ng-container>

    <ng-container *ngIf="isForm(element); let form_element">
      <bg2-form-overlay
        [ngStyle]="form_element.styles"
        [ngClass]="form_element.class"
        [source_schema]="form_element.schema"
        [source_model]="get_form_model$$(form_element.name) | async"
        (when_form_data_update)="update_form_element(form_element.name, $event)"
      >
      </bg2-form-overlay>
    </ng-container>

    <ng-container *ngIf="isButton(element); let button_element">
      <ng-template #buttonDescriptiveTemplate>
        <ng-container *ngFor="let descriptive of button_element?.descriptive">
          <ng-container *ngIf="isSpan(descriptive); let desc_part">
            <span [ngStyle]="desc_part.styles" [ngClass]="desc_part.class">
              {{ desc_part.content | translate: desc_part.translateParams || {} }}
            </span>
          </ng-container>
        </ng-container>
      </ng-template>

      <bg2-button
        [type]="'action'"
        [color]="button_element.color"
        [styles]="button_element.styles"
        [ngClass]="button_element.class"
        [icon]="button_element?.icon ?? undefined"
        [message]="button_element.content | translate: button_element.translateParams || {}"
        [descriptive]="button_element?.descriptive?.length > 0 ? buttonDescriptiveTemplate : undefined"
        (btn_click)="onClickButton(button_element.result)"
      >
      </bg2-button>
    </ng-container>

    <ng-container *ngIf="isDiv(element); let div_element">
      <bg2-dialog-div
        [content]="div_element"
        (action)="onClickButton($event)"
        (form_values)="update_form_element(div_element.name, $event)"
      ></bg2-dialog-div>
    </ng-container>
  </ng-container>
</div>
