<div class="ng-mat-text-form-field">
  <mat-form-field appearance="outline">
    <mat-label>{{ schema?.label | translate }}</mat-label>

    <input
      #textFormWidget
      matInput
      type="text"
      [disabled]="schema?.readOnly ?? null"
      [required]="schema?.isRequired || null"
      [placeholder]="schema?.label | translate"
      [maxlength]="options?.max_length ?? null"
      [ngModel]="formProperty.valueChanges | async"
      (ngModelChange)="value = $event"
    />

    <mat-hint *ngIf="options?.max_length; let max_length" align="end">{{ textFormWidget.value.length }} / {{ max_length }}</mat-hint>
  </mat-form-field>
</div>
