import { turf_feature } from '../turf-feature/turf-feature';

export const turf_polygon = <P = any>(coordinates: any[][], properties?: P, options: { bbox?: any; id?: any } = {}): any => {
  for (const ring of coordinates) {
    if (ring.length < 4) {
      throw new Error('Each LinearRing of a Polygon must have 4 or more Positions.');
    }
    for (let j = 0; j < ring[ring.length - 1].length; j++) {
      // Check if first point of Polygon contains two numbers
      if (ring[ring.length - 1][j] !== ring[0][j]) {
        throw new Error('First and last Position are not equivalent.');
      }
    }
  }
  const geom: any = {
    type: 'Polygon',
    coordinates,
  };
  return turf_feature(geom, properties, options);
};
