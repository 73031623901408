import { Component, OnInit, OnDestroy, ChangeDetectionStrategy } from '@angular/core';

import { assign, isNil } from 'lodash-es';

import { Observable, combineLatest, Subscription, concat, of } from 'rxjs';
import { map, distinctUntilChanged } from 'rxjs';

import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';
import { FormProperty, ObjectWidget } from 'ngx-schema-form';

import { Beeguard2Api } from 'app/core';
import { robustSearchProperty, trackEventDateAndId, EventDateAndId } from '../eventforms.helpers';
import { trackValue, TrackOptions } from '../eventforms.helpers';
import { replay } from '@bg2app/tools/rxjs';


export interface ObjectWidgetOptions {
  transparent: boolean;
  indent?: boolean;
  img?: string;
  variants?: string;      // flexbox
  nb_cols?: number;
  nb_cols_m?: number;
  nb_cols_s?: number;
  title_style?: '' | 'large';
}

@AutoUnsubscribe()
@Component({
  selector: 'bg2-ef-object-widget',
  templateUrl: './object.widget.html',
  styleUrls: ['./object.widget.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EfObjectWidgetComponent extends ObjectWidget implements OnInit, OnDestroy {

  public __isNil = isNil;

  public options: ObjectWidgetOptions = {
    transparent: true,
    indent: false,
    variants: '',
    title_style: '',
  };

  public ofields_class: any[] = [];

  protected _self_valid$$: Observable<boolean>;
  protected _valid_from_parent$$: Observable<boolean>;
  protected _valid_from_root$$: Observable<boolean>;
  protected valid_sub: Subscription;
  public valid$$: Observable<boolean>;

  get transparent(): boolean {
    return this.options.transparent;
  }

  get indent(): boolean {
    return this.options.indent;
  }

  public value$$: Observable<any>;

  public set value(value: any) {
    this.formProperty.setValue(value, false);
  }

  public get value(): any {
    return this.formProperty.value;
  }

  /**
   * Constructs an object of type `EfObjectWidget`.
   *
   * @param bg2Api Reference to beeguard API.
   */
  constructor(protected bg2Api: Beeguard2Api) {
    super();
  }

  /**
   * Angular lifecycle hook: OnInit.
   */
  ngOnInit(): void {
    // Copy object options to sub properties
    const obj_options = this.formProperty.schema.options || {};
    // _.values(this.formProperty.properties).map((fp) => {
    //   const item_options = fp.schema.options || {};
    //   fp.schema.options = _.assign({}, obj_options, item_options);
    // });

    this.options = assign(this.options, obj_options);
    if (this.schema.title) {
      this.options.transparent = false;
    }
    // console.log(this.options.variants, this.options.variants.indexOf('flexbox'))
    if (this.options.variants && this.options.variants.indexOf('flexbox') >= 0) {
      if (this.options.nb_cols) {
        this.ofields_class.push('c' + this.options.nb_cols);
      }
      if (this.options.nb_cols_s) {
        this.ofields_class.push('cs' + this.options.nb_cols_s);
      }
      if (this.options.nb_cols_m) {
        this.ofields_class.push('cm' + this.options.nb_cols_m);
      }
    }

    this.value$$ = concat(of(this.formProperty.value), this.formProperty.valueChanges).pipe(
      // tap(val => console.log('debug new val', this.formProperty.path, val)),
      replay()
    );

    this._initValidObs();
  }

  ngOnDestroy(): void {
    this.unsubscribeValid();
  }

  private _initValidObs(): void {
    const path = this.formProperty.path;
    const sub_path_idx = path.lastIndexOf('/');
    const property_id = sub_path_idx !== -1 ? path.substr(sub_path_idx + 1) : path;

    this._self_valid$$ = concat(of([]), this.formProperty.errorsChanges).pipe(
      map(errors => (errors || []).length === 0),
      distinctUntilChanged(),
      replay()
    );

    if (this.formProperty.parent) {
      this._valid_from_parent$$ = concat(of([]), this.formProperty.parent.errorsChanges).pipe(
        map(errors => {
          // console.log(this.formProperty.path, 'parent', errors);
          // Get parents errors for this
          const myerrors = (errors || []).filter((error: any) => error.path.endsWith(`/${property_id}`));
          // Catch "any of" error
          const any_of_errors = (errors || []).filter((error: any) => error.code === 'ANY_OF_MISSING');
          const any_of_internals = any_of_errors.map((error: any) => error.inner)
            .map((inners: any) => inners.filter((inner: any) => inner.code === 'OBJECT_MISSING_REQUIRED_PROPERTY'))
            .map((inners: any) => inners.filter((inner: any) => inner.params.includes(property_id)))
            .filter((inners: any) => inners.length > 0);
          // console.log(this.formProperty.path, any_of_internals);
          // Update validity
          return myerrors.length === 0 && any_of_internals.length === 0;
        }),
        distinctUntilChanged(),
        replay()
      );
    } else {
      this._valid_from_parent$$ = of(false);
    }

    this._valid_from_root$$ = concat(of([]), this.formProperty.findRoot().errorsChanges).pipe(
      map(errors => {
        // console.log(this.formProperty.path, 'root', errors)
        const myerrors = (errors || []).filter((error: any) => error.path === this.formProperty.path);
        return myerrors.length === 0;
      }),
      distinctUntilChanged(),
      replay()
    );

    this.valid$$ = combineLatest([this._self_valid$$, this._valid_from_parent$$, this._valid_from_root$$]).pipe(
      map(([self_valid, valid_from_parent, valid_from_root]) => self_valid && valid_from_parent && valid_from_root),
      distinctUntilChanged(),
      replay()
    );

    // Auto subscribe valid$$ to ensure shareReplay
    this.unsubscribeValid();
    this.valid_sub = this.valid$$.subscribe();
  }

  protected unsubscribeValid(): void {
    this.valid_sub?.unsubscribe();
  }

  protected robustSearchProperty(path: string, possible_root?: FormProperty): FormProperty {
    return robustSearchProperty(this.formProperty, path, possible_root);
  }

  /**
   * Gets an observable on a event date and identifier.
   *
   * @returns Returns an `Observable` on the event data and identifier.
   */
  protected trackEventDateAndId(dpath: string): Observable<EventDateAndId> {
    const date_property = this.robustSearchProperty(dpath);
    return trackEventDateAndId(date_property);
  }

  /**
   * Get an observable on a specific value.
   *
   * @param configs Options to specify where to search the value to track.
   * @param dpath The path to the date property. The default value is `/date`.
   * @returns Returns an `Observable` on the tracked value.
   */
  protected trackValue(bg2Api: Beeguard2Api, configs: TrackOptions, dpath: string = '/date'): Observable<EventDateAndId> | null {
    const event_date_and_id = this.trackEventDateAndId(dpath);
    return trackValue(this.formProperty, bg2Api, configs, event_date_and_id);
  }
}
