import { Injectable, OnDestroy } from '@angular/core';
import { MatSnackBar, MatSnackBarRef } from '@angular/material/snack-bar';

import { isNil, isUndefined, min } from 'lodash-es';

import { BehaviorSubject, forkJoin, from, fromEvent, map, Observable, of, Subscription, switchMap, take, tap, timer } from 'rxjs';
import { defaultValue, distinctUntilRealChanged, replay, waitForNotNilValue } from '@bg2app/tools/rxjs';

import { AppStateService } from 'app/core/app-state.service';
import { ConsoleLoggerService } from 'app/core/console-logger.service';

import { SimpleSetterGetter } from 'app/models';
import { ZohoAuthRedirectCredentials } from 'app/models/zoho';

import { ZohoAuthSnackbarComponent } from 'app/widgets/widgets-reusables/snackbars/zoho-auth/zoho-auth-snackbar.component';
import { LocalStorageService } from '../storage/local-storage.service';

@Injectable({
  providedIn: 'root',
})
export class ZohoAuthService implements OnDestroy {
  // #region -> (service basics)

  private readonly LOGGER = new ConsoleLoggerService('ZohoAuthService', false);

  /** */
  private readonly PREFIX_LOCAL_STORAGE = 'zoho_';

  /** */
  private readonly LOCAL_STORAGE_CREDENTIALS = `${this.PREFIX_LOCAL_STORAGE}credentials`;

  /** */
  private readonly LOCAL_STORAGE_GRANTED_FOR_SESSION = `${this.PREFIX_LOCAL_STORAGE}granted_for_session`;

  /** */
  private _expires_at_sub: Subscription = null;

  /** */
  private _window_message_sub: Subscription = null;

  /** */
  private _has_valid_access_token_sub: Subscription = null;

  /** */
  private _not_logged_user_snackbar_ref: MatSnackBarRef<any> = null;

  /** */
  constructor(private readonly _matSnackbar: MatSnackBar, private readonly _localStorageService: LocalStorageService) {}

  ngOnDestroy(): void {
    this._expires_at_sub?.unsubscribe();
    this._window_message_sub?.unsubscribe();
    this._has_valid_access_token_sub?.unsubscribe();
  }

  // #endregion

  // #region -> (initial setup)

  /** */
  public initialize(_appStateService: AppStateService): void {
    _appStateService.is_superadmin$$.pipe(take(1)).subscribe({
      next: is_superadmin => {
        if (!is_superadmin) {
          this.LOGGER.warn('Service initialization skipped ...');

          return;
        }

        this.listen_for_incoming_window_message();

        this.watch_for_valid_access_token();
        this.watch_for_automatic_refresh();

        this.LOGGER.info('Service is ready to use !');
      },
    });
  }

  /** */
  private listen_for_incoming_window_message(): void {
    this._window_message_sub = fromEvent(window, 'message').subscribe({
      next: (event: MessageEvent) => {
        if (event.origin !== window.origin) {
          return;
        }

        const data: { type: 'redirect' | 'error'; data: any } = event?.data ?? null;
        if (isNil(data)) {
          console.error('Empty data received in message !');
          return;
        }

        if (data.type === 'redirect') {
          this.on_receive_message_redirect(data?.data);
        }

        if (data.type === 'error') {
          this.on_receive_message_error(data?.data);
        }
      },
    });
  }

  /** */
  private watch_for_valid_access_token(): void {
    this.has_valid_access_token$$.subscribe({
      next: has_valid_access_token => {
        if (has_valid_access_token) {
          this.is_authenticated.value = true;

          if (this._not_logged_user_snackbar_ref) {
            this._not_logged_user_snackbar_ref.dismiss();
            this._not_logged_user_snackbar_ref = null;
          }
        } else {
          this.logout();
        }
      },
    });
  }

  /** */
  private watch_for_automatic_refresh(): void {
    this._expires_at_sub = this.expires_at$$
      .pipe(
        switchMap(expires_at => {
          if (isNil(expires_at)) {
            return of({ check: null, granted_for_session: null });
          }

          const due_at = new Date(expires_at * 1000);
          this.LOGGER.info('Token refresh or logout expected at: ', due_at);

          return timer(due_at).pipe(
            switchMap(() => {
              const granted_for_session = this._granted_for_session$$.getValue();

              if (granted_for_session) {
                this.refresh_token();
                return of(null);
              }

              return this.save_zoho_auth_credentials$(null, null, null);
            })
          );
        })
      )
      .subscribe();
  }

  public warn_not_logged_user(): void {
    let never_remind_zoho_auth = this._localStorageService.get<boolean>('zoho_ignore_auth');
    never_remind_zoho_auth = isNil(never_remind_zoho_auth) ? false : never_remind_zoho_auth;

    if (never_remind_zoho_auth) {
      return;
    }

    if (!isNil(this._not_logged_user_snackbar_ref)) {
      return;
    }

    this._not_logged_user_snackbar_ref = this._matSnackbar.openFromComponent(ZohoAuthSnackbarComponent, {
      duration: 0,
      panelClass: 'app-version-snackbar',
      data: {},
    });
  }

  // #endregion

  // #region -> (message handler)

  /** */
  private on_receive_message_redirect(data: string): void {
    let key_values: string[] = [];

    if (data.startsWith('?')) {
      data = data.slice(1);
    }

    key_values = data.split('&');

    const oauth_params: ZohoAuthRedirectCredentials = key_values.reduce((final: ZohoAuthRedirectCredentials, current) => {
      const [key, value]: [keyof ZohoAuthRedirectCredentials, string] = <any>current.split('=');
      final[key] = value;

      return final;
    }, <ZohoAuthRedirectCredentials>{});

    const expires_in = parseInt(oauth_params?.expires_in, 10);
    const expires_in_80 = (expires_in / 100) * 90;
    const expires_at_80 = Date.now() / 1000 + expires_in_80;

    const has_param_granted_for_session = !isNil(oauth_params?.granted_for_session ?? null);

    setTimeout(() => {
      this.save_zoho_auth_credentials$(
        oauth_params?.access_token,
        expires_at_80,
        has_param_granted_for_session ? true : undefined
      ).subscribe();
    }, 500);
  }

  /** */
  private on_receive_message_error(data: string): void {
    setTimeout(() => {
      this.logout();
      this.authentication_error.value = data;
    }, 500);
  }

  // #endregion

  // #region -> (zoho credentials)

  /** */
  public credentials$$: Observable<{ access_token: string; expires_at: number }> = this._localStorageService.credentials$$.pipe(
    map(credentials => credentials?.zoho_credentials),
    distinctUntilRealChanged(),
    replay()
  );

  /** */
  public access_token$$ = this.credentials$$.pipe(
    map(access_token_and_expires_at => access_token_and_expires_at?.access_token),
    replay()
  );

  /** */
  public expires_at$$ = this.credentials$$.pipe(
    map(access_token_and_expires_at => access_token_and_expires_at?.expires_at),
    replay()
  );

  /** */
  public has_valid_access_token$$ = this.credentials$$.pipe(
    map(credentials => {
      if (isNil(credentials?.access_token) || isNil(credentials?.expires_at)) {
        return false;
      }

      const current_time = Date.now() / 1000;
      if (current_time >= credentials?.expires_at) {
        return false;
      }

      return true;
    }),
    distinctUntilRealChanged(),
    replay()
  );

  /** */
  private save_zoho_auth_credentials$(access_token: string, expires_at: number, granted_for_session: boolean = undefined) {
    const save_credentials$$ = this._localStorageService.put(this.LOCAL_STORAGE_CREDENTIALS, JSON.stringify({ access_token, expires_at }));
    const clear_credentials$$ = this._localStorageService.put(
      this.LOCAL_STORAGE_CREDENTIALS,
      JSON.stringify({ access_token: null, expires_at: null })
    );

    return forkJoin({
      save_credentials: isNil(access_token) || isNil(expires_at) ? clear_credentials$$ : save_credentials$$,
      save_granted_for_session: !isUndefined(granted_for_session) ? this.save_granted_for_session$(granted_for_session) : of(null),
    }).pipe(map(({ save_granted_for_session }) => ({ access_token, expires_at, granted_for_session: save_granted_for_session })));
  }

  // #endregion

  // #region -> (granted for session management)

  /** */
  private _granted_for_session$$ = new BehaviorSubject<boolean>(null);

  /** */
  public granted_for_session$$ = this._granted_for_session$$
    .asObservable()
    .pipe(
      waitForNotNilValue(),
      defaultValue(
        of(sessionStorage.getItem(this.LOCAL_STORAGE_GRANTED_FOR_SESSION)).pipe(
          map(granted_for_session => {
            if (isNil(granted_for_session)) {
              return false;
            }

            return JSON.parse(granted_for_session);
          })
        ),
        100
      )
    )
    .pipe(distinctUntilRealChanged(), replay());

  /** */
  private save_granted_for_session$(granted_for_session: boolean): Observable<boolean> {
    if (isNil(granted_for_session) || granted_for_session === false) {
      this._granted_for_session$$.next(null);
      sessionStorage.removeItem(this.LOCAL_STORAGE_GRANTED_FOR_SESSION);

      return of(null).pipe(take(1));
    }

    this._granted_for_session$$.next(true);
    sessionStorage.setItem(this.LOCAL_STORAGE_GRANTED_FOR_SESSION, `${true}`);

    return of(true).pipe(take(1));
  }

  // #endregion

  // #region -> (authentication state)

  public is_authenticated = new SimpleSetterGetter(false);

  /** */
  public is_authenticated$$ = this.is_authenticated.value$$;

  // #endregion

  // #region -> (log in/out management)

  private readonly ZOHO_AUTH_CONFIG = {
    clientId: '1000.MLKHCXWMX583CAE2958FR7QVSY1X7J',
    scope: [
      'openid',
      'email',
      'AaaServer.profile.Read',

      'ZohoSearch.securesearch.READ',

      'Desk.tickets.READ',
      'Desk.tickets.CREATE',
      'Desk.tickets.UPDATE',
      'Desk.contacts.READ',
      'Desk.settings.READ',

      'ZohoCRM.users.READ',
      'ZohoCRM.modules.deals.READ',
      'ZohoCRM.modules.notes.READ',
      'ZohoCRM.modules.tasks.READ',
      'ZohoCRM.modules.accounts.READ',
      'ZohoCRM.modules.accounts.UPDATE',
      'ZohoCRM.modules.contacts.READ',
      'ZohoCRM.modules.contacts.UPDATE',

      'ZohoBooks.contacts.READ',
      'ZohoBooks.invoices.READ',
    ],

    loginUrl: 'https://accounts.zoho.eu/oauth/v2/auth',
    refreshUrl: 'https://accounts.zoho.eu/oauth/v2/auth/refresh',
    redirectUri: window.location.origin + '/zoho/redirect',
  };

  /** */
  public login(): void {
    const zoho_auth_url = `${this.ZOHO_AUTH_CONFIG.loginUrl}?client_id=${this.ZOHO_AUTH_CONFIG.clientId}&response_type=token&scope=${this.ZOHO_AUTH_CONFIG.scope}&redirect_uri=${this.ZOHO_AUTH_CONFIG.redirectUri}`;

    const width = min([window.outerWidth, 500]);
    const height = min([window.outerHeight, 800]);

    const window_ref = window.open(
      zoho_auth_url,
      'AuthorizeZoho',
      `popup=1,innerWidth=${width},innerHeight=${height},top=${screen.height / 2 - height / 2},left=${screen.width / 2 - width / 2}`
    );
  }

  /** */
  public logout(): void {
    this.save_zoho_auth_credentials$(null, null, null).subscribe({
      next: response => {
        this.is_authenticated.value = false;

        if (isNil(response?.granted_for_session) || !response?.granted_for_session) {
          this.warn_not_logged_user();
        }
      },
      complete: () => this.LOGGER.info('Disconnected ...'),
    });
  }

  // #endregion

  // #region -> (refresh token management)

  /** */
  private refresh_token(): void {
    const zoho_auth_url = `${this.ZOHO_AUTH_CONFIG.refreshUrl}?client_id=${this.ZOHO_AUTH_CONFIG.clientId}&response_type=token&scope=${this.ZOHO_AUTH_CONFIG.scope}&redirect_uri=${this.ZOHO_AUTH_CONFIG.redirectUri}`;

    const width = min([window.outerWidth, 500]);
    const height = min([window.outerHeight, 800]);

    const window_ref = window.open(
      zoho_auth_url,
      'RefreshZohoAuth',
      `popup=1,innerWidth=${width},innerHeight=${height},top=${screen.height / 2 - height / 2},left=${screen.width / 2 - width / 2}`
    );
  }

  // #endregion

  // #region -> (state messages)

  public authentication_error = new SimpleSetterGetter<string>(null);

  /** */
  public authentication_error$$ = this.authentication_error.value$$;

  /** */
  private _succeed_to_login$$ = new BehaviorSubject<boolean>(false);

  /** */
  public succeed_to_login$$ = this._succeed_to_login$$.asObservable();

  /** */
  public set succeed_to_login(succeed_to_login: boolean) {
    this._succeed_to_login$$.next(succeed_to_login);
  }

  // #endregion
}
