import { BehaviorSubject, Observable, of, combineLatest } from 'rxjs';
import { finalize, map, switchMap, take, tap } from 'rxjs';

import { concat, isNil, flatten, reverse } from 'lodash-es';

import { Beeguard2Api } from 'app/core';
import { ConsoleLoggerService } from 'app/core/console-logger.service';
import { distinctUntilRealChanged, replay } from '@bg2app/tools/rxjs';
import { Event, Entity, Exploitation, Location, Apiary } from 'app/models';
import { DeviceAffectation, DRDevice } from 'app/models/devices';


export interface DevicesAtOneLocation {
  location: Location;
  devices: DRDevice[];
  alt_locations: Location[];
}


export interface DevicesFromSameLocation {
  apiary: Apiary;
  location: Location;
  exploitation: Exploitation;
  total: number;
  devices_names: string[];
  devices: DRDevice[];
  affectations: DeviceAffectation[];
  setup_dates: Date[];
}


export enum GhostSolutionLevel {
  LVL_LOCATION = 0,
  LVL_APIARY = 1,
  LVL_HIVE = 2,
}


export class GhostSolutionAlternatives {
  protected _logger = new ConsoleLoggerService(this.constructor.name, true);

  public level: GhostSolutionLevel;
  public _parent_soluton: GhostSolutionAlternatives;

  public get has_parent() {
    return !isNil(this._parent_soluton);
  }
  
  private _selected_solution_idx$$: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  public selected_solution_idx$$ = this._selected_solution_idx$$.pipe(
    map(idx => idx >= this.solutions.length ? null : idx),
    distinctUntilRealChanged(),
    replay()
  );

  public solutions: GhostSolution[] = [];

  private _devices: DRDevice[] = [];

  public setDevices(devices: DRDevice[]): void {
    this._devices = devices;
  }

  public getDevices(): DRDevice[] {
    return this._devices;
  }

  private _source_location: Location;

  public setSourceLocation(location: Location) {
    this._source_location = location;
  }

  public getSourceLocation() {
    return this._source_location;
  }

  public selected_solution$$ = this._selected_solution_idx$$.pipe(
    map(idx => !isNil(idx) ? this.solutions[idx] : null)
  );

  public remaining_devices_by_previous_location$$: Observable<DevicesFromSameLocation[]> = this.selected_solution$$.pipe(
    switchMap(solution => solution.remaining_devices_by_previous_location$$),
  );
  
  get is_applied_locally() {
    return this.getSelectedSolution()?.is_applied_locally;
  }

  get desc_array() {
    const desc: string[] = [];
    this.solutions.forEach((solution, idx) => {
      if (idx === this._selected_solution_idx$$.getValue()) {
        desc.push('*' + solution.desc);
        (this._sub_solutions || []).map(sol => sol.desc_array.forEach(line => desc.push('\t' + line)));
      } else {
        desc.push(' ' + solution.desc)
      }
    });
    return desc;
  }

  get desc(): string {
    return this.desc_array.join('\n');
  }

  constructor(level: GhostSolutionLevel) {
    this.level = level;
    this._logger.prefix = `${this.constructor.name} (${this.level})`;
  }

  public setSelectedIdx(idx: number) {
    this._selected_solution_idx$$.next(idx)
  }

  public applyLocally(bg2Api: Beeguard2Api): Observable<GhostSolutionAlternatives> {
    return this.selected_solution$$.pipe(
      switchMap(solution => {
        if (isNil(solution)) {
          return of(this);
        } else {
          return solution.applyLocally(bg2Api).pipe(
            map(() => this)
          )
        }
      }),
      // finalize(() => this._logger.info('CLEAN applyLocally'))
    );
  }

  public getFlattenSolutionsAlt(): GhostSolutionAlternatives[] {
    const _sub_solutions = this.getSubSolutions();
    return concat(
      this,
      flatten(_sub_solutions.map(solution_alt => solution_alt.getFlattenSolutionsAlt()))
    );
  }

  public validate(): Observable<any> {
    return this.selected_solution$$.pipe(
      switchMap(solution => solution.validate())
    );
  }

  /**
   * This should be call from GhostService only
   */
  public _validateAll(): Observable<any> {
    const flatten_solutions_alt = this.getFlattenSolutionsAlt();
    let solutions_validate$$ = of(null);
    flatten_solutions_alt.forEach(solution_alt => solutions_validate$$ = solutions_validate$$.pipe(
      switchMap(() => solution_alt.validate())
    ));
    return solutions_validate$$;
  }

  public add(solution: GhostSolution) {
    const idx = this.solutions.length;
    this.solutions.push(solution);
    solution.set_alternatives(this, idx);
  }

  public hasSolution(): boolean {
    return this.solutions.length > 0;
  }

  public getSelectedSolution(): GhostSolution {
    return this.solutions[this._selected_solution_idx$$.getValue()];
  }

  private _sub_solutions: GhostSolutionAlternatives[] = [];
  public registerSubSolutions(sub_solutions: GhostSolutionAlternatives[]) {
    this._sub_solutions = sub_solutions || [];
    this._sub_solutions.forEach(sub_sol => sub_sol.setParent(this))
  }

  public setParent(parent: GhostSolutionAlternatives) {
    this._parent_soluton = parent;
  }

  public getSubSolutions() {
    return this._sub_solutions;
  }

  public streamAllEvents(): Observable<Event[]> {
    return this.selected_solution$$.pipe(
      map(solution => solution.getAllEvents())
    );
  }

}


export type GhostOperandConfig = [string, number];


export class GhostSolution {
  protected _logger = new ConsoleLoggerService(this.constructor.name, true);

  public name: string;

  public alternatives: GhostSolutionAlternatives;
  private alt_idx: number;

  public set_alternatives(alternatives: GhostSolutionAlternatives, idx: number) {
    this.alternatives = alternatives;
    this.alt_idx = idx;
  }

  public _is_applied_locally$$ = new BehaviorSubject<boolean>(false);
  public is_applied_locally$$: Observable<boolean> = this._is_applied_locally$$.asObservable();

  get is_applied_locally() {
    return this._is_applied_locally$$.getValue();
  }

  private _remaining_devices_by_previous_location$$ = new BehaviorSubject<DevicesFromSameLocation[]>([]);

  public setRemainingDevices(remaining_devices: DevicesFromSameLocation[]) {
    this._remaining_devices_by_previous_location$$.next(remaining_devices);
  }

  public remaining_devices_by_previous_location$$: Observable<DevicesFromSameLocation[]> = combineLatest([
    this._remaining_devices_by_previous_location$$,
    this.is_applied_locally$$
  ]).pipe(
    map(([remaining_devices, is_applied_locally]) => is_applied_locally ? remaining_devices : []),
    replay()
  );

  private _events: [Event, GhostOperandConfig[]][] = [];

  constructor(name: string) {
    this.name = name;
    this._logger.prefix = `${this.constructor.name} (${this.desc})`;
  }

  get desc(): string {
    return `${this.level}: ${this.name}`;
  }

  get level() {
    return this.alternatives?.level;
  }

  private _ghost_entities_specs: [string, any][] = [];
  private _ghost_entities: Entity[] = [];

  private _devices: DRDevice[] = [];

  public select() {
    this.alternatives.setSelectedIdx(this.alt_idx);
  }

  public setDevices(devices: DRDevice[]): void {
    this._devices = devices;
  }

  public getDevices(): DRDevice[] {
    return this._devices;
  }

  private _source_location: Location;

  public setSourceLocation(location: Location) {
    this._source_location = location;
  }

  public getSourceLocation() {
    return this._source_location;
  }

  private _description: string;
  private _description_params: any;

  public get description() {
    return this._description || this.name;
  }

  public get description_params() {
    return this._description_params || {};
  }

  public setDescription(description: string, description_params: any = {}) {
    this._description = description;
    this._description_params = description_params;
  }

  public newGhostEntity(type: string, static_state: any): number {
    const ref = this._ghost_entities_specs.length;
    this._ghost_entities_specs.push([type, static_state]);
    return ref;
  }

  public getGhostEntity(idx: number) {
    return this._ghost_entities[idx];
  }

  private _create_ghosts_entities(bg2Api: Beeguard2Api) {
    this._ghost_entities = this._ghost_entities_specs.map(([type, static_state]) => {
      const schema: any = {
        type,
        static_state,
        last_state: {
          state: {},
          error: null,
          event_id: -1,
          event_date: new Date(),
          dirty: false,
          is_last: true,
          previous_event_id: null,
          next_event_id: null,
        },
        _user_acl: [
          'read',
          'read_aproximate_position',
          'read_precisse_position',
          'read_measurements_data',
          'read_all_events',
          'read_devices',
          'read_devices_last_position',
          'read_devices_routes',
        ],
      };
      const entity = bg2Api.createGhostEntity(schema);
      return entity;
    });
  }

  private _rm_ghosts_entities(bg2Api: Beeguard2Api) {
    this._ghost_entities.forEach(entity => bg2Api.deleteGhostEntity(entity.id));
  }

  public addEvent(event: Event, ...ghost_operand_config: GhostOperandConfig[]) {
    this._events.push([event, ghost_operand_config]);
  }

  public getSubSolutions(): GhostSolutionAlternatives[] {
    return this.alternatives.getSubSolutions();
  }

  public getSubSolutionEvents(): Event[] {
    const sub_sols = this.alternatives.getSubSolutions()
    if (!this.is_applied_locally || sub_sols.length === 0) {
      return []
    }
    const selecetd_sub_sol_events = flatten(sub_sols.map(
      sub_sol => sub_sol.getSelectedSolution().getAllEvents()
    ));
    return selecetd_sub_sol_events;
  }

  public getEvents(): Event[] {
    return this._events.map(([event, ]) => event);
  }

  /**
   * Notes: events are returned the more recent first
   * 
   * @returns list of all events of this solution and all selected sub-solutions
   */
  public getAllEvents(): Event[] {
    return concat(this.getEvents(), this.getSubSolutionEvents());
  }

  private _applyOneEvent(bg2Api: Beeguard2Api, event: Event, ghost_operand_config: GhostOperandConfig[]): (() => Observable<Event>) {
    return () => {
      ghost_operand_config.forEach(([role, ghost_idx]) => {
        event.addNewEntity(this._ghost_entities[ghost_idx], role);
      });
      return bg2Api.addGhostEvent(event);
    };
  }

  public applyLocally(bg2Api: Beeguard2Api): Observable<any> {
    this._logger.ghost('applyLocally');

    let apply$$: Observable<any> = of(null).pipe(
      tap(() => this._is_applied_locally$$.next(true)),
      tap(() => this._create_ghosts_entities(bg2Api))
    );
    this._events.forEach(([event, ghost_operand_config]) => {
      const _apply_the_event = this._applyOneEvent(bg2Api, event, ghost_operand_config);
      apply$$ = apply$$.pipe(switchMap(_apply_the_event));
    });

    const clean = async() => {
      await this.unApplyLocally(bg2Api).toPromise()
    };
    
    return apply$$.pipe(
      switchMap(res => new BehaviorSubject(res)), // prevent obs to complete
      finalize(() => clean())
    );
  }

  public unApplyLocally(bg2Api: Beeguard2Api): Observable<any> {
    this._logger.ghost('unApplyLocally');
    let apply$$: Observable<any> = of(null);
    reverse(this._events).forEach(([event, ghost_operand_config]) => {
      apply$$ = apply$$.pipe(
        switchMap(() => bg2Api.deleteGhostEvent(event))
      );
    });
    apply$$ = apply$$.pipe(
      tap(() => this._rm_ghosts_entities(bg2Api))
    );
    return apply$$.pipe(tap(() => this._is_applied_locally$$.next(false)));
  }

  public validate() {
    const events = this.getEvents()
    let event_save$$: Observable<any> = of(null);
    events.forEach(event => event_save$$ = event_save$$.pipe(
      switchMap(() => event.save())
    ))
    return event_save$$;
  }
}
