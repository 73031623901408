import { map, of, combineLatest, Observable } from 'rxjs';
import { replay } from '@bg2app/tools/rxjs';

import { cloneDeep, isNil } from 'lodash-es';
import { marker as i18n } from '@biesbjerg/ngx-translate-extract-marker';
import { ISchema } from 'ngx-schema-form';

import { DeviceInterface, DeviceLocation } from './DRDevice';
import { GPSDevice, IFx_SCHEMA } from './GPSDevice';
import { DeviceApi } from 'app/core';
import { DeviceQueryParams } from 'app/core/api/device/device-api-service';
import { DeviceCommunicationTechnology } from './enumerators/device-communication-technology.enum';
import { DEVICE_BATTERY_TYPE, DEVICE_SIMPLIFIED_BATTERY_STATE, DEVICE_SIMPLIFIED_BATTERY_STATE_REASON, DeviceSimplifiedBatteryState } from './enumerators';

function _build_cptmc_ifx_schema() {
  const schema: ISchema = cloneDeep(IFx_SCHEMA);
  delete schema.properties.embedded.properties.sim;
  delete schema.properties.embedded.properties.time_zone;
  delete schema.properties.embedded.properties.other_features;
  //schema.properties.embedded.properties.btn_actif;
  delete schema.properties.embedded.properties.sms_number;
  delete schema.properties.embedded.properties.gprs_login;
  delete schema.properties.embedded.properties.gprs_password;
  delete schema.properties.embedded.properties.dns_server;

  schema.properties.embedded.properties.rfx_1_com_configuration.label = 'Configuration reveil (rfx_1_com_configuration)';
  schema.properties.embedded.properties.rfx_1_com_configuration.widget = 'select';
  schema.properties.embedded.properties.rfx_1_com_configuration.oneOf = [
    {
      enum: [1],
      label: 'Actif entre lever/coucher du soleil, pos FIX ou Labège (Ephemeris = 1)',
    },
    {
      enum: [129],
      label: 'Actif entre lever/coucher du soleil, pos FIX ou conf (Ephemeris = 129)',
    },
    {
      enum: [33],
      label: 'Toujours actif (AlwaysOn = 33)',
    },
    {
      enum: [65],
      label: 'Actif entre la première et la dernière com (ComConfig = 65)',
    },
  ];

  delete schema.properties.embedded.properties.rfx_1_intermediate_measure_sec;

  schema.properties.embedded.properties.nb_threshold_exceeded.label = 'Offset reveil ephéméride en degrés (nb_threshold_exceeded)';
  schema.properties.embedded.properties.nb_threshold_exceeded.visibleIf = {
    rfx_1_com_configuration: [1, 129],
  };
  schema.properties.embedded.properties.nb_threshold_exceeded.widget = 'slider';
  schema.properties.embedded.properties.nb_threshold_exceeded.minimum = 0;
  schema.properties.embedded.properties.nb_threshold_exceeded.maximum = 80;
  schema.properties.embedded.properties.nb_threshold_exceeded.step = 1;

  schema.properties.embedded.properties.alr_windows_sec.label = 'Offset reveil ephéméride en secondes (alr_windows_sec)';
  schema.properties.embedded.properties.alr_windows_sec.visibleIf = {
    rfx_1_com_configuration: [1, 129],
  };
  schema.properties.embedded.properties.alr_windows_sec.minimum = 0;
  schema.properties.embedded.properties.alr_windows_sec.maximum = 43200;

  schema.properties.embedded.properties.rfx_1_measure_period_sec.label =
    'Periode de measure VBAT en secondes (rfx_1_measure_period_sec)';

  schema.properties.embedded.properties.rfx_1_communication_start_hour.label = 'Heure de reveil CPT (rfx_1_communication_start_hour)';
  schema.properties.embedded.properties.rfx_1_communication_start_hour.visibleIf = {
    rfx_1_com_configuration: 65,
  };

  schema.properties.embedded.properties.rfx_1_communication_period_minute.label =
    'Periode de remonté VBAT en minutes (rfx_1_communication_period_minute)';

  schema.properties.embedded.properties.rfx_1_communication_number.label =
    'Nombre de periodes remonté VBAT avant switch-off CPT (rfx_1_communication_number)';
  schema.properties.embedded.properties.rfx_1_communication_number.visibleIf = {
    rfx_1_com_configuration: 65,
  };

  //TODO: widget custom pour avoir une map de select
  schema.properties.embedded.properties.audio_listening_sec.label = 'Latitude si pas de fix (audio_listening_sec)';
  schema.properties.embedded.properties.audio_listening_sec.visibleIf = {
    rfx_1_com_configuration: [129],
  };
  schema.properties.embedded.properties.audio_off_sec.label = 'Longitude si pas de fix (audio_off_sec)';
  schema.properties.embedded.properties.audio_off_sec.visibleIf = {
    rfx_1_com_configuration: [129],
  };

  schema.properties.embedded.properties.temperature_internal_low.label =
    'Seuil vbat minimal pdt journée en mV (temperature_internal_low)';
  schema.properties.embedded.properties.temperature_internal_low.widget = 'slider';
  schema.properties.embedded.properties.temperature_internal_low.minimum = 10800;
  schema.properties.embedded.properties.temperature_internal_low.maximum = 16000;
  schema.properties.embedded.properties.temperature_internal_low.step = 100;

  schema.properties.embedded.properties.temperature_internal_high.label =
    'Seuil vbat minimal au démarage en mV (temperature_internal_high)';
  schema.properties.embedded.properties.temperature_internal_high.widget = 'slider';
  schema.properties.embedded.properties.temperature_internal_high.minimum = 10800;
  schema.properties.embedded.properties.temperature_internal_high.maximum = 16000;
  schema.properties.embedded.properties.temperature_internal_high.step = 100;

  delete schema.properties.embedded.properties.temperature_external_high;
  delete schema.properties.embedded.properties.temperature_external_low;
  delete schema.properties.embedded.properties.rain_gauge_threshold;
  delete schema.properties.embedded.properties.rain_gauge_threshold_window;
  delete schema.properties.embedded.properties.weight_delta_dec;
  delete schema.properties.embedded.properties.weight_delta_inc;
  delete schema.properties.embedded.properties.audio_threshold_mv;
  delete schema.properties.embedded.properties.hysteresis_frequency_hz;
  delete schema.properties.embedded.properties.central_frequency_hz;
  delete schema.properties.embedded.properties.choc_counter_1;
  delete schema.properties.embedded.properties.choc_counter_2;
  delete schema.properties.embedded.properties.choc_counter_3;
  delete schema.properties.embedded.properties.choc_counter_4;
  delete schema.properties.embedded.properties.choc_threshold_1_mg;
  delete schema.properties.embedded.properties.choc_threshold_2_mg;
  delete schema.properties.embedded.properties.choc_threshold_3_mg;
  delete schema.properties.embedded.properties.choc_threshold_4_mg;
  delete schema.properties.embedded.properties.choc_mask;
  delete schema.properties.embedded.properties.nb_stream_acc;
  delete schema.properties.embedded.properties.angle;
  delete schema.properties.embedded.properties.etor_mask_high;
  delete schema.properties.embedded.properties.etor_mask_low;
  delete schema.properties.embedded.properties.acc_delay;
  delete schema.properties.embedded.properties.acc_delay_off;
  delete schema.properties.embedded.properties.acc_nb;
  delete schema.properties.embedded.properties.mvt_sensor;

  delete schema.properties.embedded.properties.events_0;
  delete schema.properties.embedded.properties.events_1;
  delete schema.properties.embedded.properties.events_2;
  delete schema.properties.embedded.properties.events_3;
  delete schema.properties.embedded.properties.events_4;
  delete schema.properties.embedded.properties.events_5;
  delete schema.properties.embedded.properties.events_6;
  delete schema.properties.embedded.properties.events_7;
  delete schema.properties.embedded.properties.events_8;
  delete schema.properties.embedded.properties.events_9;

  delete schema.properties.embedded.properties.critical_alarm_filter_min;
  delete schema.properties.embedded.properties.critical_alarm_mask;
  delete schema.properties.embedded.properties.critical_alarm_release;

  delete schema.properties.embedded.properties.rfx_2_com_configuration;
  delete schema.properties.embedded.properties.rfx_2_communication_number;
  delete schema.properties.embedded.properties.rfx_2_communication_start_hour;
  delete schema.properties.embedded.properties.rfx_2_communication_period_minute;
  delete schema.properties.embedded.properties.rfx_2_configuration;
  delete schema.properties.embedded.properties.rfx_2_external_sensor_strategy;
  delete schema.properties.embedded.properties.rfx_2_gps_flash_configuration;
  delete schema.properties.embedded.properties.rfx_2_max_measurements;
  delete schema.properties.embedded.properties.rfx_2_measure_period_sec;
  delete schema.properties.embedded.properties.rfx_2_presence;

  delete schema.properties.embedded.properties.rfx_3_com_configuration;
  delete schema.properties.embedded.properties.rfx_3_communication_number;
  delete schema.properties.embedded.properties.rfx_3_communication_start_hour;
  delete schema.properties.embedded.properties.rfx_3_communication_period_minute;
  delete schema.properties.embedded.properties.rfx_3_configuration;
  delete schema.properties.embedded.properties.rfx_3_external_sensor_strategy;
  delete schema.properties.embedded.properties.rfx_3_gps_flash_configuration;
  delete schema.properties.embedded.properties.rfx_3_max_measurements;
  delete schema.properties.embedded.properties.rfx_3_measure_period_sec;
  delete schema.properties.embedded.properties.rfx_3_presence;


  schema.properties.embedded.order = [
    // Conf du revail
    'rfx_1_measure_period_sec',
    'rfx_1_communication_period_minute',
    'rfx_1_com_configuration',
    'rfx_1_communication_start_hour',
    'rfx_1_communication_number',
    'nb_threshold_exceeded',
    'alr_windows_sec',
    'audio_listening_sec',
    'audio_off_sec',

    // Seuil temp
    'temperature_internal_high',
    'temperature_internal_low',

    // Config tracking
    'gps_timeout',
    'tracking_mode',
    'tracking_period',
    'tracking_stop_duration',
    'stop_threshold',
    'move_threshold',

    'rfx_1_external_sensor_strategy',
    'rfx_1_max_measurements',

    'rfx_1_configuration',
    'rfx_1_presence',
    'rfx_1_gps_flash_configuration',

    '_cls',
    'server_ota_port',
    'server_port',
    'server_ip',
    'gprs_apn',

    // Log 
    'presence_log',
    'start_ts_h_log',
    'stop_ts_h_log'
  ];

  return schema;
}

export const CPTMC_IFX_SCHEMA = _build_cptmc_ifx_schema();

export class CPTMCDevice extends GPSDevice {
  // #region -> (model basics)

  constructor(protected deviceApi: DeviceApi, params?: DeviceQueryParams) {
    super(deviceApi, params);

    this.is_gateway = true;
    this.type = DeviceInterface.TypeEnum.CPTMC;
  }

  // #endregion

  // #region -> (device generation & versions)

  /**
   * Gets generation of the device.
   *
   * @returns Device generation
   */
  protected getGeneration(swv: number, hwv: number, gateway_type: string): number {
    return 1;
  }

  public use_ifx$$ = of(true).pipe(replay());

  // #endregion

  // #region -> battery state

  /** */
  protected compute_battery_type$$(): Observable<DEVICE_BATTERY_TYPE> {
    return of(null);
  }

  protected get_battery_critical_vbat$$(): Observable<number> {
    return of(null);
  }

  /** */
  public get_battery_simplified_state$$(): Observable<DeviceSimplifiedBatteryState> {
    return this._get_default_battery_simplified_state$$.pipe(
      map(state => {
        if (!isNil(state)) {
          return state;
        }

        return {
          state: DEVICE_SIMPLIFIED_BATTERY_STATE.UNKNOWN,
          reason: DEVICE_SIMPLIFIED_BATTERY_STATE_REASON.UNKNOWN,
        };
      })
    );
  }

  // #endregion

  // #region -> (communication technology)

  /** */
  protected get_com_technology$$(): Observable<DeviceCommunicationTechnology[]> {
    return of(null);
  }

  // #endregion

  /**
   * Use GPS location except if Cell ID is more recent, and away from last gps id
   *
   * @returns Returns the last location of the device.
   */
  get location(): DeviceLocation {
    if (isNil(this._location)) {
      if (this.location_cellids && !this.location_gps) {
        // On na que du cellid...
        this._location = this.location_cellids;
      } else if (
        this.location_cellids &&
        this.location_cellids.timestamp &&
        this.location_gps.timestamp &&
        this.location_cellids.timestamp >= this.location_gps.timestamp &&
        this.distance_gps_cellid > 2 * this.location_cellids.accuracy
      ) {
        // Cas cell id plus récent et loin de la dernière pos GPS
        this._location = this.location_cellids;
      } else {
        // sinon on prefaire le point GPS
        this._location = this.location_gps;
      }
    }
    return this._location;
  }

  get config_modal(): [string, any] {
    return ['device_config', { imei: this.imei }];
  }

  public fetchConfigurationSchema$(): Observable<any> {
    return of(CPTMC_IFX_SCHEMA);
  }

  public requestSimplifiedConfigurationSchema(): Observable<any> {
    return super.requestSimplifiedConfigurationSchema().pipe(
      map(schema => {
        schema.properties.sconf.properties.mode.oneOf = [
          {
            enum: ['measures'],
            label: i18n('DEVICE.ALL.CONFIG.Internal measurements'),
          },
          {
            enum: ['measures_sensors'],
            label: i18n('DEVICE.ALL.CONFIG.Internal measurements and WGuard'),
          },
        ];
        schema.properties.sconf.properties.mode.default = 'measures';
        return schema;
      })
    );
  }
}
