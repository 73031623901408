import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { combineLatest, Observable } from 'rxjs';

import { EfObjectWidgetComponent as ObjectWidget, ObjectWidgetOptions } from '../object/object.widget';
import { map } from 'rxjs';

export interface ColorHSLWidgetOptions extends ObjectWidgetOptions {
  fixed_h?: boolean;
  fixed_s?: boolean;
  fixed_l?: boolean;
}

@Component({
  selector: 'ef-color-hsl-selector-widget',
  templateUrl: './color-hsl-selector.widget.html',
  styleUrls: ['./color-hsl-selector.widget.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EfColorHSLSelectorWidget extends ObjectWidget implements OnInit {

  public options: ColorHSLWidgetOptions = {
    transparent: true,
    variants: '',
    title_style: '',
    fixed_h: false,
    fixed_s: false,
    fixed_l: false,
  };

  set hue(value: number) {
    (this.formProperty.properties as any).h.setValue(value, false);
  }
  public hue$$: Observable<number>;
  public hue_bg$$: Observable<string>;

  set saturation(value: number) {
    (this.formProperty.properties as any).s.setValue(value, false);
  }
  public saturation$$: Observable<number>;
  public saturation_bg$$: Observable<string>;

  set lightness(value: number) {
    (this.formProperty.properties as any).l.setValue(value, false);
  }
  public lightness$$: Observable<number>;
  public lightness_bg$$: Observable<string>;

  public ngOnInit(): void {
    super.ngOnInit();

    this.hue$$ = this.value$$.pipe(
      map(value => value?.h)
    );

    this.lightness$$ = this.value$$.pipe(
      map(value => value?.l)
    );

    this.saturation$$ = this.value$$.pipe(
      map(value => value?.s)
    );

    this.hue_bg$$ = combineLatest([this.saturation$$, this.lightness$$]).pipe(
      map(([saturation, lightness]) => {
        return `linear-gradient( to right,
          hsl(0, ${saturation}%, ${lightness}%) 0%,
          hsl(36, ${saturation}%, ${lightness}%) 10%,
          hsl(72, ${saturation}%, ${lightness}%) 20%,
          hsl(108, ${saturation}%, ${lightness}%) 30%,
          hsl(144, ${saturation}%, ${lightness}%) 40%,
          hsl(180, ${saturation}%, ${lightness}%) 50%,
          hsl(216, ${saturation}%, ${lightness}%) 60%,
          hsl(252, ${saturation}%, ${lightness}%) 70%,
          hsl(288, ${saturation}%, ${lightness}%) 80%,
          hsl(324, ${saturation}%, ${lightness}%) 90%,
          hsl(360, ${saturation}%, ${lightness}%) 100%)`;
      })
    );

    this.saturation_bg$$ = combineLatest([this.hue$$, this.lightness$$]).pipe(
      map(([hue, lightness]) => {
        return `linear-gradient( to right,
          hsl(${hue}, 0%, ${lightness}%) 0%,
          hsl(${hue}, 100%, ${lightness}%) 100%)`;
      })
    );

    this.lightness_bg$$ = combineLatest([this.hue$$, this.saturation$$]).pipe(
      map(([hue, saturation]) => {
        return`linear-gradient( to right,
          hsl(${hue}, ${saturation}%, 0%) 0%,
          hsl(${hue}, ${saturation}%, 10%) 10%,
          hsl(${hue}, ${saturation}%, 20%) 20%,
          hsl(${hue}, ${saturation}%, 30%) 30%,
          hsl(${hue}, ${saturation}%, 40%) 40%,
          hsl(${hue}, ${saturation}%, 50%) 50%,
          hsl(${hue}, ${saturation}%, 60%) 60%,
          hsl(${hue}, ${saturation}%, 70%) 70%,
          hsl(${hue}, ${saturation}%, 80%) 80%,
          hsl(${hue}, ${saturation}%, 90%) 90%,
          hsl(${hue}, ${saturation}%, 100%) 100%)`;
      })
    );
  }
}
