import packageJson from '../../../../package.json';

/** */
export interface IEnvironmentConfig {
  /** */
  disable_navigation: boolean;

  /** */
  language: {
    /** */
    default: string;

    /** */
    available: string[];
  };

  /** */
  beta_version: {
    /** */
    is_beta_env: boolean;

    /** */
    should_check_for_beta: boolean;

    /** */
    should_check_for_classy: boolean;
  };

  /** */
  ghost: {
    /** */
    default_merge_range_meter: number;

    /** */
    last_com_max_days_threshold: number;
  };

  /** */
  events: {
    /** */
    activate_migratory: boolean;
  };

  /** */
  contact_form: {
    /** */
    in_menu: boolean;
  };

  /** */
  data: {
    /** */
    amemo: {
      /**
       * Minimal wind speed (km/h) for valid wind heading.
       */
      min_speed_for_valid_heading: number;
    };
  };
}

/** */
export interface IEnvironment {
  /** */
  production: boolean;

  /** */
  version: string;

  /** */
  package_version: string;

  /** */
  env: 'dev' | 'apismart' | 'beta' | 'preprod' | 'prod' | 'staging';

  /** */
  config: IEnvironmentConfig;

  /** */
  wa_secret_key: string;

  /** */
  activateAsyncStream: boolean;

  /** */
  activateDailyRAZWarning: boolean;

  /** */
  Beeguard1Url: string;

  /** */
  Beeguard2ApiUrl: string;
  /** */
  Beeguard2StreamUrl: string;

  /** */
  DeviceApiUrl: string;

  /** */
  UserApiUrl: string;

  /** */
  userManualUrl: string;

  /** */
  anfrlUrl: string;

  /** */
  grafana: {
    /** */
    url: string;

    /** */
    orgId: string;
  };

  /** */
  sentry: {
    /** */
    environment: 'dev' | 'prod' | 'prod-apismart' | 'prod-beta' | 'prod-preprod';

    /** */
    release: string;

    /** */
    url: string;

    /** */
    traces_sample_rate: number;
  };

  /** */
  show_welcome: boolean;

  /** */
  salesiq_code: string;

  /** */
  google_api_key: string;

  /** */
  analytics: {
    /** */
    clarity: string;

    /** */
    google_analytics: string;
  };
}

export const environment: Partial<IEnvironment> = {
  production: false,
  version: 'release-bg2024-04-r47-0d1c48e1',
  package_version: packageJson.version,

  sentry: {
    url: null,
    release: '2024.4.47-dev-0d1c48e1',
    environment: null,
    traces_sample_rate: 1.0,
  },

  config: {
    disable_navigation: false,
    language: {
      default: 'en',
      available: ['fr', 'es', 'it', 'en', 'pt', 'ja'],
    },
    beta_version: {
      is_beta_env: false,
      should_check_for_beta: false,
      should_check_for_classy: false,
    },
    ghost: {
      default_merge_range_meter: 500,
      last_com_max_days_threshold: 4,
    },
    events: {
      activate_migratory: true,
    },
    contact_form: {
      in_menu: true,
    },
    data: {
      amemo: {
        min_speed_for_valid_heading: 5,
      },
    },
  },
};
