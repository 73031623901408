import { DateTime } from 'luxon';

/**
 *
 * @param timezone
 * @returns
 */
export function startOfTomorrow(timezone: string): Date {
  const now_tz = DateTime.now().setZone(timezone);
  const start_of_next_day = now_tz.plus({ day: 1 }).startOf('day');

  return start_of_next_day.toJSDate();
}

/**
 *
 * @param timezone
 * @returns
 */
export function startOfTomorrowLuxon(timezone: string): DateTime {
  const now_tz = DateTime.now().setZone(timezone);
  const start_of_next_day = now_tz.plus({ day: 1 }).startOf('day');

  return start_of_next_day;
}
