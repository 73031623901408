import { ChangeDetectionStrategy, Component } from '@angular/core';

import { merge } from 'lodash-es';

import { Beeguard2Api } from 'app/core';
import { AppStateService } from 'app/core/app-state.service';
import { DialogsService } from 'app/widgets/dialogs-modals/dialogs.service';

import { BgControlWidgetComponent, WidgetOptions } from '../control/control.widget';

/** */
interface NgMatTextWidgetOptions extends WidgetOptions {
  /** */
  max_length: number;
}

@Component({
  selector: 'bg2-ng-mat-text',
  templateUrl: './ng-mat-text.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NgMatTextComponent extends BgControlWidgetComponent {
  // #region -> (component basics)

  constructor(private _bg2Api: Beeguard2Api, private _dialogs: DialogsService, private _appState: AppStateService) {
    super(_bg2Api, _appState, _dialogs);
  }

  // #endregion

  // #region -> (widget options)

  /**
   * Re-define options for this widget component
   */
  public options: NgMatTextWidgetOptions = merge(
    {},
    <NgMatTextWidgetOptions>{
      max_length: null,
    },
    super.options
  );

  // #endregion
}
