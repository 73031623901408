<div *ngLet="device$$ | async; let device" class="bg2-device-modal-configuration-component">
  <div *ngIf="is_either_view" class="device-card-template">
    <div class="device-card-template__normal-container">
      <div class="device-detail__title-container">
        <div class="title-container__icon --color-green">
          <span
            class="mdi mdi-cog mdi-36px"
            [ngClass]="{ 'mdi-spin': device_next_conf_is_pending$$ | async }"
            [ngStyle]="{ color: (device_next_conf_is_pending$$ | async) ? 'darkorange' : null }"
          ></span>
        </div>

        <div class="title-container__title">{{ 'VIEWS.MODALS.DEVICE.Configuration' | translate }}</div>

        <ng-container *ngIf="(device_acl_error$$ | async) === null">
          <div class="title-container__sep">|</div>

          <div *ngLet="device_simplified_conf$$ | async; let sconf" class="title-container__important-container">
            <bg2-loading-skeleton *ngIf="is_loading_sconf.value" [string_model]="'active_since_date'"></bg2-loading-skeleton>

            <ng-container *ngIf="!is_loading_sconf.value">
              <span *ngIf="sconf?.ack && !sconf?.next?.estimated_ack_time">
                {{ 'DEVICE.ALL.CONFIG.Acknoledged on [date]' | translate : { date: sconf.ack.date | formatDate : 'full' } }}
              </span>

              <span *ngIf="sconf?.next?.estimated_ack_time; let estimated_ack_time">
                {{ 'DEVICE.ALL.CONFIG.Will be taken over on [date]' | translate : { date: estimated_ack_time | formatDate : 'short' } }}
              </span>
            </ng-container>
          </div>
        </ng-container>
      </div>

      <div class="device-modal-card-container__body-container">
        <ng-container *ngLet="device_acl_error$$ | async; let device_acl_error">
          <bg2-error-helper *ngIf="device_acl_error !== null" [error]="device_acl_error"></bg2-error-helper>

          <ng-container *ngIf="device_acl_error === null">
            <!-- Device configuration -->
            <div class="device-ack-next-conf">
              <bg2-device-ack-next-conf
                *ngIf="device?.can_have_sconf$$ | async; else deviceCannotHaveSconf"
                [device]="device"
                [display_type]="'device_modal_summary'"
              ></bg2-device-ack-next-conf>

              <ng-template #deviceCannotHaveSconf>
                <bg2-ui-message
                  *ngIf="(is_device_cpt$$ | async) === false"
                  [type]="'info'"
                  [should_take_full_height]="true"
                  [title]="'DEVICE.ALL.CONFIG.No configuration' | translate"
                >
                  <div message>
                    <span>{{ "DEVICE.ALL.CONFIG.This device's configuration depends on the gateway" | translate }}</span>
                    <span *ngIf="is_superadmin$$ | async">
                      {{ 'En tant que administrateur, il reste possible de modifier sa configuration complete' }}
                    </span>
                  </div>
                </bg2-ui-message>

                <bg2-ui-message
                  *ngIf="is_device_cpt$$ | async"
                  [type]="'info'"
                  [should_take_full_height]="true"
                  [title]="'DEVICE.ALL.CONFIG.Special configuration' | translate"
                >
                  <div message>
                    <span>
                      {{ "DEVICE.BEELIVE.CONFIG.This device's configuration can only be modified by an administrator" | translate }}&nbsp;
                    </span>

                    <span>
                      {{ 'DEVICE.BEELIVE.CONFIG.If you want to change the configuration of this device, you can' | translate }}
                    </span>

                    <a [routerLink]="['', { outlets: { modal: ['new_issue'] } }]" [queryParamsHandling]="'preserve'">
                      {{ 'VIEWS.AUTH.LOGIN.NO_ACCOUNT.contact us' | translate }}
                    </a>
                  </div>
                </bg2-ui-message>
              </ng-template>
            </div>
          </ng-container>

          <!-- Device configuration button -->
          <ng-container *ngIf="(is_device_rg_or_gps$$ | async) || (device?.can_have_sconf$$ | async)">
            <bg2-button
              *ngLet="device?.user_acl?.can$$('write_devices_configuration') | async; let can_write_devices_configuration"
              [type]="'action'"
              [color]="'device'"
              [disabled]="!can_write_devices_configuration"
              [message]="'VIEWS.MODALS.DEVICE.Configuration' | translate"
              [icon]="can_write_devices_configuration ? 'mdi-cog' : 'cs-/img/pictos/lock.svg'"
              [internal_routing]="{
                queryParamsHandling: 'preserve',
                routerLink: ['', { outlets: { modal: device?.config_modal$$ | async } }]
              }"
            ></bg2-button>
          </ng-container>

          <ng-container *ngIf="device_acl_error === null">
            <!-- Device gateways -->
            <div *ngIf="device?.last_data?.gateways" class="summary__related-devices-container">
              <span class="related-devices-container__list__spec">{{ 'VIEWS.MODALS.DEVICE.Last gateways :' | translate }}</span>

              <div class="related-devices-container__list">
                <ng-container *ngIf="device?.last_data?.gateways?.length === 0">
                  <div class="related-devices-container__list__spec">
                    {{ 'VIEWS.MODALS.DEVICE.No gateway devices' | translate }}
                  </div>
                </ng-container>

                <ng-container *ngIf="device?.last_data?.gateways?.length > 0">
                  <ng-container *ngFor="let gw of device?.last_data.gateways">
                    <ng-container *ngIf="gw.read_ace || (!gw.read_ace && gw.is_last)">
                      <div class="related-devices-container__list__spec">
                        <ng-container *ngIf="compute_gateway_state($any(gw)); let status; else: unknownRSSI">
                          <bg2-s868-level [open_in_modal]="false" [status]="status"></bg2-s868-level>
                        </ng-container>

                        <ng-template #unknownRSSI>
                          <span class="mdi mdi-24px mdi-help"></span>
                        </ng-template>

                        <a
                          [class.disabled]="!gw.read_ace"
                          [routerLink]="[
                            '',
                            {
                              outlets: {
                                modal: [
                                  'device',
                                  {
                                    imei: gw.imei
                                  }
                                ]
                              }
                            }
                          ]"
                          [queryParamsHandling]="'preserve'"
                        >
                          {{ gw.name }}
                        </a>
                        <span>,&nbsp;</span>
                        <bg2-last-com prefix="" [last_contact]="gw.timestamp_last | parseDate"></bg2-last-com>
                      </div>
                    </ng-container>
                  </ng-container>
                </ng-container>
              </div>
            </div>

            <!-- Device sensors -->
            <div *ngIf="device?.last_data?.sensors" class="summary__related-devices-container">
              <span>{{ 'VIEWS.MODALS.DEVICE.sensors :' | translate }}</span>

              <div class="related-devices-container__list">
                <ng-container *ngIf="device?.last_data?.sensors?.length === 0">
                  <div class="related-devices-container__list__spec">{{
                    'VIEWS.MODALS.DEVICE.No devices have been uploaded by this device' | translate
                  }}</div>
                </ng-container>

                <ng-container *ngIf="device?.last_data?.sensors?.length > 0">
                  <ng-container *ngFor="let sensor of device?.last_data?.sensors">
                    <ng-container *ngIf="sensor.read_ace || (!sensor.read_ace && sensor.is_last)">
                      <div class="related-devices-container__list__spec">
                        <ng-container *ngIf="compute_sensor_state($any(sensor)); let status; else: unknownRSSISensor">
                          <bg2-s868-level [open_in_modal]="false" [status]="status"></bg2-s868-level>
                        </ng-container>

                        <ng-template #unknownRSSISensor>
                          <span class="mdi mdi-24px mdi-help"></span>
                        </ng-template>

                        <a
                          [class.disabled]="!sensor.read_ace"
                          [routerLink]="[
                            '',
                            {
                              outlets: {
                                modal: [
                                  'device',
                                  {
                                    imei: sensor.imei
                                  }
                                ]
                              }
                            }
                          ]"
                          [queryParamsHandling]="'preserve'"
                        >
                          {{ sensor.name }}
                        </a>

                        <span>,&nbsp;</span>
                        <bg2-last-com prefix="" [last_contact]="sensor.timestamp_last | parseDate"></bg2-last-com>
                      </div>
                    </ng-container>
                  </ng-container>
                </ng-container>
              </div>
            </div>
          </ng-container>
        </ng-container>

        <div *ngIf="is_superadmin$$ | async" class="body-container__properties">
          <!-- Full configuration -->
          <div class="body-container__property-container --only-superadmin">
            <div class="device-details-properties__property-container__label-container">
              <span class="mdi mdi-engine mdi-24px"></span>
            </div>

            <span class="device-details-properties__property-container__value">
              <a [routerLink]="['', { outlets: { modal: device_full_config_modal$$ | async } }]" [queryParamsHandling]="'preserve'">
                {{ 'VIEWS.MODALS.DEVICE.Full configuration' | translate }}
              </a>
            </span>
          </div>

          <!-- OTA -->
          <div class="body-container__property-container --only-superadmin">
            <div class="device-details-properties__property-container__label-container">
              <span class="mdi mdi-cloud-download-outline mdi-24px"></span>
            </div>

            <span class="device-details-properties__property-container__value">
              <a class="clickable" (click)="program_ota()">
                {{ 'VIEWS.DEVICES.COMPONENTS.DEVICES_DATATABLES.SELECTION.Program an OTA' | translate }}
              </a>
            </span>
          </div>

          <!-- Send order -->
          <div class="body-container__property-container --only-superadmin">
            <div class="device-details-properties__property-container__label-container">
              <span class="mdi mdi-sync-alert mdi-24px"></span>
            </div>

            <span class="device-details-properties__property-container__value">
              <a class="clickable" (click)="send_order()">
                {{ 'VIEWS.DEVICES.COMPONENTS.DEVICES_DATATABLES.SELECTION.Send order' | translate }}
              </a>
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
