<div class="ng-mat-select-form-field">
  <mat-form-field appearance="outline">
    <mat-label>{{ schema?.label | translate }}</mat-label>

    <input
      matInput
      [placeholder]="schema?.value"
      [required]="schema?.isRequired ?? false"
      [type]="show_password ? 'text' : 'password'"
      [ngModel]="formProperty.valueChanges | async"
      (ngModelChange)="value = $event"
    />

    <mat-icon matSuffix (click)="show_password = !show_password">
      {{ show_password ? 'visibility_off' : 'visibility' }}
    </mat-icon>
  </mat-form-field>
</div>
