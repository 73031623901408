import { LatLngBounds, Marker } from 'leaflet';

import { MapMarkerType } from '../../enumerators';

export abstract class AbstractMarker extends Marker {
  public circle: L.Polygon = null;
  public type: MapMarkerType = null;

  constructor(latlng: L.LatLngExpression) {
    super(latlng);
  }

  public getBounds(): LatLngBounds {
    if (this.circle) {
      return this.circle.getBounds();
    } else {
      return new LatLngBounds(this.getLatLng(), this.getLatLng());
    }
  }

  protected abstract buildMarker(): L.DivIcon | L.Icon<L.IconOptions>;

  protected abstract buildCircle(radius: number, options: L.PolylineOptions): L.Polygon<any>;
}
