<div class="bg2-events-history-component">
  <bg2-error-helper *ngIf="error$$ | async; let error" [error]="error"></bg2-error-helper>

  <div *ngLet="events$$ | async; let events" class="events-container">
    <bg2-loading-skeleton *ngIf="loadings?.events$$ | async" [theme]="{ height: '100%' }"></bg2-loading-skeleton>

    <ng-container *ngIf="(loadings?.events$$ | async) === false">
      <ng-container *ngFor="let event of events; trackBy: track_by_event">
        <bg2-event-link [event]="event" [from]="entity$$ | async"></bg2-event-link>
      </ng-container>
    </ng-container>
  </div>

  <div class="events-footer">
    <bg2-paging
      *ngIf="has_pagination$$ | async; else noPagination"
      [limit]="10"
      [total]="total$$ | async"
      [offset]="offset$$ | async"
      (pageChanged)="this.page = $event"
    ></bg2-paging>

    <ng-template #noPagination>
      <div></div>
    </ng-template>

    <div class="actions">
      <ng-container *ngIf="entity_type$$ | async; let entity_type">
        <ng-container *ngIf="entity_type === 'location' || entity_type === 'hive'">
          <bg2-button
            [type]="'action'"
            [message]="'VIEWS.EVENTS.SHARED.ENTITY_EVENTS_HISTORY.View events in the datatable' | translate"
            [icon]="'mdi-calendar-month'"
            [options]="{ is_thin: true }"
            [internal_routing]="{
              routerLink: [{ outlets: { primary: ['events'], modal: ['raz'] } }],
              queryParams: {
                'se[]': (exploitation$$ | async)?.id,
                'entities[]': entity_id$$ | async,
                entity_type: entity_type$$ | async
              },
              queryParamsHandling: 'merge'
            }"
          ></bg2-button>
        </ng-container>
      </ng-container>
    </div>
  </div>
</div>
