import { AfterViewInit, ChangeDetectionStrategy, Component, OnDestroy, Renderer2 } from '@angular/core';

import { combineLatest, Observable, map } from 'rxjs';
import { distinctUntilRealChanged, replay } from '@bg2app/tools/rxjs';

import { ConsoleLoggerService } from 'app/core/console-logger.service';

import { AbstractCarouselSlideComponent } from '../../abstract-carousel/abstract-carousel-slide/abtract-carousel-slide.component';

@Component({
  selector: 'bg2-time-carousel-slide',
  template: '',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TimeBasedCarouselSlideComponent extends AbstractCarouselSlideComponent implements AfterViewInit, OnDestroy {
  // #region -> (static parameters)

  public start_date$$: Observable<Date> = this.static_parameters$$.pipe(
    map(static_params => static_params?.start_date || null),
    replay()
  );

  public end_date$$: Observable<Date> = this.static_parameters$$.pipe(
    map(static_params => static_params?.end_date || null),
    replay()
  );

  public date_range$$: Observable<[Date, Date]> = combineLatest([this.start_date$$, this.end_date$$]).pipe(
    distinctUntilRealChanged(),
    replay()
  );

  // #endregion

  // #region -> (component basics)

  protected _logger = new ConsoleLoggerService('TimeBasedCarouselSlideComponent', true);

  constructor(protected _renderer: Renderer2) {
    super(_renderer);
  }

  ngAfterViewInit(): void {
    super.ngAfterViewInit();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  // #endregion

  public link_more_data_start_end$$ = this.date_range$$.pipe(
    map(range => range.map(date => date.toISOString())),
    map(iso_dates => iso_dates.join(',')),
    replay()
  );
}
