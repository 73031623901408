import { map, Observable, of } from 'rxjs';

import { assign, cloneDeep, isEmpty, isNil } from 'lodash-es';

import { DeviceApi } from 'app/core';
import { DeviceQueryParams } from 'app/core/api/device/device-api-service';

import { DeviceRSSIData, GPSJobData, GPSSecondaryData } from '../data';
import { ComputeDevice } from './ComputeDevice';

import { IFx2ConfigurationSchemaEmbedded } from './configuration/IFx2';
import {
  DEVICE_BATTERY_TYPE,
  DEVICE_SIMPLIFIED_BATTERY_STATE,
  DEVICE_SIMPLIFIED_BATTERY_STATE_REASON,
  DeviceCommunicationTechnology,
  DeviceSimplifiedBatteryState,
} from './enumerators';
import { DeviceLastMeasurements, DeviceStatus, DeviceStatusGPRS } from './DRDevice';
import { DeviceLastMeasurement } from 'app/core/api-swagger/device';
import { parseDate } from 'app/misc/tools';
import { differenceInHours } from 'date-fns';
import { compute_gprs_state } from './_functions';

export class BloomLiveDevice extends ComputeDevice {
  // #region -> (model basics)

  constructor(protected deviceApi: DeviceApi, params?: DeviceQueryParams) {
    super(deviceApi, params);

    this.is_gateway = true;
    this.type = 'BloomLive';
  }

  public can_have_sconf$$ = of(true);

  public requestSimplifiedConfigurationSchema(): Observable<any> {
    const full_schema = cloneDeep(IFx2ConfigurationSchemaEmbedded);
    full_schema.properties.active_configurations.properties.gprs.widget = 'hidden';
    full_schema.properties.active_configurations.properties.sms.widget = 'hidden';
    full_schema.properties.active_configurations.properties.dns.widget = 'hidden';
    full_schema.properties.active_configurations.properties.rfx_2.widget = 'hidden';
    full_schema.properties.active_configurations.properties.rfx_3.widget = 'hidden';
    full_schema.properties.active_configurations.properties.alarms.widget = 'hidden';
    full_schema.properties.active_configurations.properties.tracking.widget = 'hidden';
    full_schema.properties.active_configurations.properties.shock.widget = 'hidden';
    full_schema.properties.active_configurations.properties.audio.widget = 'hidden';
    full_schema.properties.active_configurations.properties.alarm_event_0.widget = 'hidden';
    full_schema.properties.active_configurations.properties.alarm_event_1.widget = 'hidden';
    full_schema.properties.active_configurations.properties.alarm_event_2.widget = 'hidden';
    full_schema.properties.active_configurations.properties.alarm_event_3.widget = 'hidden';
    full_schema.properties.active_configurations.properties.alarm_event_4.widget = 'hidden';
    full_schema.properties.active_configurations.properties.alarm_event_5.widget = 'hidden';
    full_schema.properties.active_configurations.properties.alarm_event_6.widget = 'hidden';
    full_schema.properties.active_configurations.properties.alarm_event_7.widget = 'hidden';
    full_schema.properties.active_configurations.properties.alarm_event_8.widget = 'hidden';
    full_schema.properties.active_configurations.properties.alarm_event_9.widget = 'hidden';
    full_schema.properties.active_configurations.properties.log.widget = 'hidden';
    full_schema.properties.active_configurations.properties.activity.widget = 'hidden';
    full_schema.properties.active_configurations.properties.linux_misc.widget = 'hidden';
    full_schema.properties.active_configurations.properties.camera.widget = 'hidden';

    full_schema.properties.rfx_1.properties.rfx_presence.properties.rain_gauge.widget = 'hidden';
    full_schema.properties.rfx_1.properties.rfx_presence.properties.anemometer_heading.widget = 'hidden';
    full_schema.properties.rfx_1.properties.rfx_presence.properties.anemometer_speed.widget = 'hidden';
    full_schema.properties.rfx_1.properties.rfx_presence.properties.tracking.widget = 'hidden';
    full_schema.properties.rfx_1.properties.rfx_presence.properties.tracking_state.widget = 'hidden';
    full_schema.properties.rfx_1.properties.rfx_presence.properties.accelerometer_angle.widget = 'hidden';
    full_schema.properties.rfx_1.properties.rfx_presence.properties.accelerometer_orientation.widget = 'hidden';
    full_schema.properties.rfx_1.properties.rfx_presence.properties.weight_1.widget = 'hidden';
    full_schema.properties.rfx_1.properties.rfx_presence.properties.weight_2.widget = 'hidden';
    full_schema.properties.rfx_1.properties.rfx_presence.properties.weight_3.widget = 'hidden';
    full_schema.properties.rfx_1.properties.rfx_presence.properties.weight_4.widget = 'hidden';
    full_schema.properties.rfx_1.properties.rfx_presence.properties.wifi_1.widget = 'hidden';
    full_schema.properties.rfx_1.properties.rfx_presence.properties.wifi_2_3.widget = 'hidden';
    full_schema.properties.rfx_1.properties.rfx_presence.properties.cell_ids_1.widget = 'hidden';
    full_schema.properties.rfx_1.properties.rfx_presence.properties.cell_ids_2_5.widget = 'hidden';
    full_schema.properties.rfx_1.properties.rfx_presence.properties.cell_ids_1_5_extended.widget = 'hidden';
    full_schema.properties.rfx_1.properties.rfx_presence.properties.gps.widget = 'hidden';
    full_schema.properties.rfx_1.properties.rfx_presence.properties.geofencing.widget = 'hidden';
    full_schema.properties.rfx_1.properties.rfx_presence.properties.magnetometer_axes.widget = 'hidden';
    full_schema.properties.rfx_1.properties.rfx_presence.properties.counter_shock.widget = 'hidden';
    full_schema.properties.rfx_1.properties.rfx_presence.properties.anemometer_speed_min_max.widget = 'hidden';
    full_schema.properties.rfx_1.properties.rfx_presence.properties.events.widget = 'hidden';
    full_schema.properties.rfx_1.properties.rfx_presence.properties.trackagri.widget = 'hidden';
    full_schema.properties.rfx_1.properties.rfx_presence.properties.jamming_detector.widget = 'hidden';
    full_schema.properties.rfx_1.properties.rfx_presence.properties.spectrum.widget = 'hidden';
    full_schema.properties.rfx_1.properties.rfx_presence.properties.spectre.widget = 'hidden';

    full_schema.properties.rfx_1.properties.rfx_configuration.widget = 'hidden';
    full_schema.properties.rfx_1.properties.rfx_gps_flash_configuration.widget = 'hidden';

    full_schema.properties.rfx_1.properties.rfx_sensor_mode_auto_start_hour.widget = 'hidden';
    full_schema.properties.rfx_1.properties.rfx_sensor_mode_auto_stop_hour.widget = 'hidden';

    full_schema.properties.linux_specific.widget = 'hidden';

    full_schema.properties.sim.widget = 'hidden';
    full_schema.properties.time_zone.widget = 'hidden';
    full_schema.properties.server_ip.widget = 'hidden';
    full_schema.properties.server_port.widget = 'hidden';
    return of({
      type: 'object',
      properties: {
        sconf: full_schema,
      },
    });
  }

  // #region -> (gateway messages)

  private _last_gw_msg: DeviceLastMeasurement = null;

  /**
   * Gets device's last gateway message.
   *
   * @returns Returns device's last gateway message.
   */
  get last_gw_msg(): DeviceLastMeasurement {
    if (isNil(this._last_gw_msg)) {
      const last_measurements = this.last_measurements;

      if (isNil(last_measurements) || isEmpty(last_measurements)) {
        return null;
      }

      const gateway_message = last_measurements.gateway_message;

      if (isNil(gateway_message) || isEmpty(gateway_message)) {
        return null;
      } else {
        gateway_message.time = parseDate(gateway_message.time);
      }

      this._last_gw_msg = gateway_message;
    }

    return this._last_gw_msg;
  }

  // #endregion

  // #region -> GPRS status

  public get status_gprs(): DeviceStatusGPRS {
    const message = this.last_gw_msg;
    
    if (isNil(message)) {
      return null;
    }
    
    let outdated = false;
    const timestamp = message.time;
    
    if (differenceInHours(new Date(), timestamp) > 49) {
      outdated = true;
    }
    
    const prebuilt_status: DeviceStatus = { timestamp, value: message.fields.gprs_qos.last, outdated };
    return { state: compute_gprs_state(prebuilt_status), ...prebuilt_status };
  }

  // #endregion

  // #region -> battery

  /** */
  protected compute_battery_type$$(): Observable<DEVICE_BATTERY_TYPE> {
    return of(null);
  }

  /** */
  protected get_battery_noload_voltage(last_measurements: DeviceLastMeasurements): number | number[] {
    return last_measurements?.gateway_message?.fields?.valim?.last ?? null;
  }

  /** */
  protected get_battery_com_voltage(last_measurements: DeviceLastMeasurements): number | number[] {
    return last_measurements?.gateway_message?.fields?.vbat?.last ?? null;
  }

  /** */
  public get_battery_std_voltage_range$$(): Observable<[number, number]> {
    return of([8, 16]);
  }

  /** */
  protected get_battery_critical_vbat$$(): Observable<number> {
    return of(11.5);
  }

  /** */
  public get_battery_simplified_state$$(): Observable<DeviceSimplifiedBatteryState> {
    return this._get_default_battery_simplified_state$$.pipe(
      map(state => {
        if (!isNil(state)) {
          return state;
        }

        return {
          state: DEVICE_SIMPLIFIED_BATTERY_STATE.OK,
          reason: null,
        };
      })
    );
  }

  // #endregion
}
