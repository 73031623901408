import { Router } from '@angular/router';
import { ChangeDetectionStrategy, Component, ElementRef, HostListener, OnDestroy, OnInit } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';
import { marker as i18n } from '@biesbjerg/ngx-translate-extract-marker';

import { ISchema } from 'ngx-schema-form';

import { distinctUntilRealChanged, replay, waitForNotNilValue } from '@bg2app/tools/rxjs';
import { of, Subscription, Observable, BehaviorSubject, combineLatest, filter, map, switchMap, tap, take, catchError } from 'rxjs';

import { cloneDeep, isNil, isEqual } from 'lodash-es';

import { format, isAfter, endOfDay, formatDistance, differenceInDays, differenceInMonths, subMonths } from 'date-fns/esm';

import { Beeguard2Api, DeviceApi } from 'app/core';
import { AppStateService } from 'app/core/app-state.service';
import { ConsoleLoggerService } from 'app/core/console-logger.service';

import { AbstractModalComponent } from 'app/widgets/dialogs-modals';
import { DialogsService } from 'app/widgets/dialogs-modals/dialogs.service';
import { ModalArgs, ModalParams } from 'app/widgets/dialogs-modals/abstract-modal.component';
import { SchemaFormBinder } from 'app/widgets/widgets-reusables/forms/bg2-form-overlay.component';

import { Apiary, Hive, DRDevice, HiveEditingFormModel } from 'app/models';

import { DeleteEventDialogComponent } from 'app/views/events/shared/delete-event-dialog/delete-event-dialog.component';
import { DeleteOrArchiveEntityAction } from 'app/views/entities/shared/button-delete-or-archive-entity/button-delete-or-archive-entity.component';

import { catchErrorInDialog, parseDate } from 'app/misc/tools';

export interface ApiaryHiveListParams extends ModalParams {
  eid: string;
  args: ModalArgs;
}

@Component({
  selector: 'bg2-apiary-hive-list-modal',
  templateUrl: './apiary-hive-list.modal.html',
  styleUrls: ['./apiary-hive-list.modal.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class ApiaryHiveListModal extends AbstractModalComponent<ApiaryHiveListParams> implements OnInit, OnDestroy {
  private readonly LOGGER = new ConsoleLoggerService('ApiaryHiveListModal', true);

  // #region -> (component basics)

  private _entity_sub: Subscription = null;
  private _hives_and_nucs_sub: Subscription = null;
  private compute_default_name_sub: Subscription = null;

  constructor(
    private _router: Router,
    private _bg2Api: Beeguard2Api,
    private _deviceApi: DeviceApi,
    private _dialogs: DialogsService,
    private _appState: AppStateService,
    private _translate: TranslateService,
    private _element: ElementRef<HTMLElement>
  ) {
    super();
  }

  public is_superadmin$$ = this._appState.is_superadmin$$;

  ngOnInit(): void {
    this._entity_sub = this._apiary_id$$
      .pipe(
        take(1),
        switchMap(apiary_id => this._bg2Api.getEntityObj(apiary_id) as Observable<Apiary>),
        tap(entity => {
          if (!(entity instanceof Apiary)) {
            throw new Error(`Fetched entity must be an apiary`);
          }
        })
      )
      .subscribe({
        next: apiary => this._apiary$$.next(apiary),
        error: (error: unknown) => {
          this.LOGGER.error(error);
          this.close();
        },
      });

    this._hives_and_nucs_sub = this.apiary$$
      .pipe(
        filter(apiary => !isNil(apiary)),
        tap(() => this._is_hives_loading$$.next(true)),
        switchMap(apiary => apiary?.hives_nucs$$)
      )
      .subscribe({
        next: hives_nucs => {
          this._apiary_hives = hives_nucs;

          this.displayed_hives = [...this._apiary_hives];
          this._is_hives_loading$$.next(false);
        },
      });
  }

  ngOnDestroy(): void {
    this._entity_sub?.unsubscribe();
    this._hives_and_nucs_sub?.unsubscribe();

    super.ngOnDestroy();
  }

  /** */
  public trackByHive(index: number, item: Hive) {
    return item.id;
  }

  // #endregion

  // #region -> (modal basics)

  /** */
  protected handle_event_before_unload(event: BeforeUnloadEvent): void {
    const is_editing_hive = !isNil(this._edited_hive$$.getValue());
    const is_creating_hive = !isNil(this._new_hive$$.getValue());

    if (is_editing_hive || is_creating_hive) {
      event.returnValue = true;
    }
  }

  // #endregion

  // #region -> (apiary management)

  /** */
  private _apiary_id$$: Observable<number> = this.input_params$$.pipe(
    map(parameters => parseInt(parameters.eid, 10)),
    distinctUntilRealChanged(),
    tap(apiary_id => {
      if (isNil(apiary_id)) {
        throw new Error('Apiary ID must not be undefined or null');
      }
    })
  );

  /**
   * Local reference of the apiary's hives.
   */
  private _apiary_hives: Hive[] = [];

  /** */
  private _apiary$$: BehaviorSubject<Apiary> = new BehaviorSubject<Apiary>(null);

  /** */
  public apiary$$: Observable<Apiary> = this._apiary$$.asObservable();

  // #endregion

  // #region -> (warehouse)

  /** */
  private warehouse$$ = this.apiary$$.pipe(
    switchMap(apiary => apiary?.location$$),
    switchMap(location => {
      if (isNil(location)) {
        return of(null);
      }

      return location?.warehouse$$;
    }),
    replay()
  );

  /** */
  public has_beelive_devices$$ = this.warehouse$$.pipe(
    switchMap(warehouse => {
      if (isNil(warehouse)) {
        return of([]);
      }

      return warehouse.devices_beelive$$;
    }),
    map(devices => devices?.length > 0),
    replay()
  );

  /** */
  public has_cpt_devices$$ = this.warehouse$$.pipe(
    switchMap(warehouse => {
      if (isNil(warehouse)) {
        return of([]);
      }

      return warehouse.devices$$;
    }),
    map((devices: DRDevice[]) => devices.filter(device => device.type === 'CPT')),
    map(devices => devices?.length > 0),
    replay()
  );

  /** */
  public has_tg_devices$$ = this.warehouse$$.pipe(
    switchMap(warehouse => {
      if (isNil(warehouse)) {
        return of([]);
      }

      return warehouse.has_devices_tg$$;
    }),
    replay()
  );

  // #endregion

  // #region -> (hives management)

  private _is_hives_loading$$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public is_hives_loading$$: Observable<boolean> = this._is_hives_loading$$.asObservable().pipe(distinctUntilRealChanged());

  private _is_hives_dirty$$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public is_hives_dirty$$: Observable<boolean> = this._is_hives_dirty$$.asObservable().pipe(distinctUntilRealChanged());

  private _displayed_hives$$: BehaviorSubject<Hive[]> = new BehaviorSubject<Hive[]>([]);
  public displayed_hives$$: Observable<Hive[]> = this._displayed_hives$$.asObservable().pipe(
    map(hives => hives.filter(hive => !isNil(hive))),
    replay()
  );

  private get displayed_hives(): Hive[] {
    return this._displayed_hives$$.getValue();
  }

  private set displayed_hives(displayed_hives: Hive[]) {
    this._displayed_hives$$.next(displayed_hives);
  }

  /**
   * Move the hive's order.
   *
   * @param hive_index The current hive's index.
   * @param order
   */
  public moveHive(hive_index: number, order: number): void {
    const tmp = this._apiary_hives[hive_index + order];
    this._apiary_hives[hive_index + order] = this._apiary_hives[hive_index];
    this._apiary_hives[hive_index] = tmp;

    // update hives dispayed
    this.displayed_hives = [...this._apiary_hives];

    // Store this new order
    this._apiary$$.getValue().hives_order = this._apiary_hives.filter(hive => !isNil(hive)).map(hive => hive.id);
    this._apiary$$
      .getValue()
      .save()
      .subscribe({
        error: (err: unknown) => this.handleHttpError(err),
      });
  }

  // #endregion

  // #region -> (schema forms)

  private readonly HIVE_SCHEMA_FORM: ISchema = {
    type: 'object',
    properties: {
      name: {
        type: 'string',
        label: 'ENTITY.HIVE.Name of the hive',
      },
      date: {
        label: 'EVENT.HIVE_SETUP.Hive installed since',
        type: 'string',
        min: null,
        widget: {
          id: 'date-time',
        },
        options: {
          beta: true,
          event_date_path: 'date',
          readonly: false,
        },
      },
      htype: {
        label: 'EVENT.HIVE.Hive type',
        type: 'string',
        widget: 'radio',
        oneOf: [
          {
            enum: ['hive'],
            label: 'ENTITY.ALL.TYPE.hive',
            options: {
              img: 'img/hive.png',
            },
          },
          {
            enum: ['nuc'],
            label: 'ENTITY.ALL.TYPE.nuc',
            options: {
              img: 'img/nuc_hive.png',
            },
          },
        ],
      },
      color: {
        label: 'ENTITY.HIVE.Color of the hive',
        type: 'object',
        widget: 'color-hsl',
        options: {
          fixed_s: true,
          fixed_l: true,
        },
        properties: {
          h: {
            type: 'number',
          },
          l: {
            type: 'number',
          },
          s: {
            type: 'number',
          },
        },
      },
    },
  };

  // #endregion

  // #region -> (hive creation management)

  /** */
  private readonly _form_schema_for_new_hive$$ = new BehaviorSubject<ISchema>(null);

  /** */
  public form_schema_for_new_hive$$ = this._form_schema_for_new_hive$$.asObservable();

  /** */
  public new_hive__form_binder = new SchemaFormBinder();

  /** */
  private _new_hive$$ = new BehaviorSubject<Hive>(null);

  /** */
  public new_hive$$ = this._new_hive$$.asObservable();

  /** */
  public new_hive__model$$ = this.new_hive$$.pipe(
    waitForNotNilValue(),
    switchMap(hive => hive.get_editing_form_model$$),
    map(form_model => {
      if (this._hive_setup_date) {
        form_model.date = this._hive_setup_date.toISOString();
      }

      return form_model;
    }),
    distinctUntilRealChanged(),
    this.new_hive__form_binder.wait_for_form_bind(),
    replay()
  );

  /** */
  public create_new_hive(): void {
    if (!isNil(this._new_hive$$.getValue())) {
      return;
    }

    this.new_hive__form_binder.should_bind_form = false;
    this.compute_default_name_sub?.unsubscribe();

    let apiary_in_location_since = this._apiary$$.getValue().location_since_date;

    const new_hive_form_schema = cloneDeep(this.HIVE_SCHEMA_FORM);
    new_hive_form_schema.properties.date.min = apiary_in_location_since;
    this._form_schema_for_new_hive$$.next(new_hive_form_schema);

    // Builds the new hive
    const new_hive = new Hive(this._bg2Api, this._deviceApi);
    const state = new_hive.state || {};
    state.apiary_id = this._apiary$$.getValue().id;

    if (differenceInMonths(new Date(), apiary_in_location_since) > 1) {
      apiary_in_location_since = subMonths(new Date(), 1);
    }

    state.setup = { date: apiary_in_location_since };
    new_hive.forceLocalState(state);
    new_hive.hive_nb = this._apiary$$.getValue().hive_ids.length;

    this._new_hive$$.next(new_hive);

    this.compute_default_name_sub = new_hive.compute_default_name$$(this._apiary$$.getValue(), this._translate).subscribe({
      next: hive_default_name => (new_hive.name = hive_default_name),
      error: (error: unknown) => this.LOGGER.error(error),
    });
  }

  /** */
  public cancel_new_hive(): void {
    const hive = this._new_hive$$.getValue();
    hive.preDestroy();

    this._new_hive$$.next(null);
    this._hive_setup_date = null;
  }

  /** */
  public save_new_hive(): void {
    this.new_hive$$
      .pipe(
        take(1),
        switchMap(new_hive => {
          const hive_setup_date = this._hive_setup_date;

          // Agreement pipe
          let agreement_pipe: Observable<boolean> = of(true);

          // Add checking of future date
          if (isAfter(new Date(hive_setup_date), endOfDay(new Date()))) {
            agreement_pipe = agreement_pipe.pipe(
              switchMap(last_agreement =>
                last_agreement
                  ? this._dialogs.confirm(
                      i18n<string>(
                        `VIEWS.MODALS.FORM.The date you've picked is in the future. Are you sure this is the date you want to use ?`
                      ),
                      {
                        onFalseMessage: i18n<string>('VIEWS.MODALS.FORM.Cancel'),
                        onTrueMessage: i18n<string>('VIEWS.MODALS.FORM.Confirm'),
                      }
                    )
                  : of(false)
              )
            );
          }

          return agreement_pipe.pipe(
            switchMap(has_agreed => {
              if (!has_agreed) {
                return of({ agreement: false, event: null });
              }

              this._is_hives_dirty$$.next(true);

              return new_hive
                .hiveSaveCreateUpdateSetup(this._apiary$$.getValue(), hive_setup_date)
                .pipe(map(event => ({ agreement: true, event })));
            })
          );
        })
      )
      .subscribe({
        next: ({ agreement, event }) => {
          if (agreement) {
            const hive = this._new_hive$$.getValue();
            hive.preDestroy();

            this._new_hive$$.next(null);
            this._is_hives_dirty$$.next(false);
            this._hive_setup_date = null;
          }
        },
        error: (err: unknown) => {
          this._is_hives_dirty$$.next(false);
          this.handleHttpError(err);
        },
      });
  }

  /** */
  public update_model_for_new_hive(model: HiveEditingFormModel): void {
    this.new_hive$$.pipe(waitForNotNilValue(), take(1)).subscribe({
      next: new_hive => {
        // For seeing directly modifications
        new_hive.name = model.name;
        new_hive.color_hsl = model.color;

        // /!\ WARNING /!\ -> Modify state not legal but in this case it's usefull to change icon in direct
        if (model?.htype) {
          new_hive.set_tmp_htype(model.htype);
        }

        if (model?.date) {
          this._hive_setup_date = model.date;
          new_hive.checkStaticStateChanged();
        }
      },
      error: (error: unknown) => this.LOGGER.error(error),
      complete: () => {},
    });
  }

  // #endregion

  // #region -> (hive edition management)

  /** */
  private readonly _form_schema_for_edited_hive$$ = new BehaviorSubject<ISchema>(null);

  /** */
  public form_schema_for_edited_hive$$ = this._form_schema_for_edited_hive$$.asObservable();

  /** */
  public edited_hive__form_binder = new SchemaFormBinder();

  /** */
  private _edited_hive$$ = new BehaviorSubject<Hive>(null);

  /** */
  public edited_hive$$ = this._edited_hive$$.asObservable();

  /** */
  private edited_hive_id$$ = this.edited_hive$$.pipe(
    switchMap(edited_hive => edited_hive?.id$$ ?? of<number>(null)),
    replay()
  );

  /** */
  public edited_hive__model$$ = this.edited_hive$$.pipe(
    waitForNotNilValue(),
    switchMap(hive => hive.get_editing_form_model$$),
    map(form_model => {
      if (this._hive_setup_date) {
        form_model.date = this._hive_setup_date.toISOString();
      }

      return form_model;
    }),
    distinctUntilRealChanged(),
    this.edited_hive__form_binder.wait_for_form_bind(),
    replay()
  );

  /** */
  private _editing_hive_setup_date$$ = new BehaviorSubject<Date>(null);

  /** */
  private set _hive_setup_date(date_str: Date | string) {
    this._editing_hive_setup_date$$.next(parseDate(date_str));
  }

  /** */
  private get _hive_setup_date(): Date {
    return this._editing_hive_setup_date$$.getValue();
  }

  /** */
  public editing_hive_has_changed$$ = combineLatest([this.edited_hive$$, this._editing_hive_setup_date$$]).pipe(
    switchMap(([hive, setup_date]) => {
      const hive_has_changed$$ = hive ? hive.has_changed$$ : of(false);
      return hive_has_changed$$.pipe(map(has_changed => ({ hive, setup_date, has_changed })));
    }),
    map(({ hive, setup_date, has_changed }) => has_changed || !isEqual(hive?.setup_date, setup_date)),
    distinctUntilRealChanged()
  );

  /** */
  public update_model_for_edited_hive(model: HiveEditingFormModel): void {
    this.edited_hive$$.pipe(waitForNotNilValue(), take(1)).subscribe({
      next: editing_hive => {
        // For seeing directly modifications
        editing_hive.name = model.name;
        editing_hive.color_hsl = model.color;

        // /!\ WARNING /!\ -> Modify state not legal but in this case it's usefull to change icon in direct
        if (model?.htype) {
          editing_hive.set_tmp_htype(model.htype);
        }

        if (model?.date) {
          this._hive_setup_date = model.date;
          editing_hive.checkStaticStateChanged();
        }
      },
      error: (error: unknown) => this.LOGGER.error(error),
      complete: () => {},
    });
  }

  /** */
  public edit_hive(index: number): void {
    this.displayed_hives$$
      .pipe(
        map(displayed_hives => displayed_hives?.[index]),
        take(1)
      )
      .subscribe({
        next: hive_to_edit => {
          this.edited_hive__form_binder.should_bind_form = false;

          const edit_hive_form_schema = cloneDeep(this.HIVE_SCHEMA_FORM);
          delete edit_hive_form_schema.properties.htype;

          if (hive_to_edit.has_no_migratory) {
            edit_hive_form_schema.properties.date.min = this._apiary$$.getValue().location_since_date;
          } else {
            delete edit_hive_form_schema.properties.date;
          }

          this._form_schema_for_edited_hive$$.next(edit_hive_form_schema);
          this._edited_hive$$.next(hive_to_edit);

          setTimeout(() => this._element?.nativeElement?.getElementsByClassName('hive-edit')?.[0]?.scrollIntoView(), 1);
        },
      });
  }

  /** */
  public cancel_edited_hive(): void {
    this.edited_hive$$.pipe(take(1)).subscribe({
      next: edited_hive => {
        edited_hive.reset_static_state();
        this._edited_hive$$.next(null);
        this._hive_setup_date = null;
      },
    });
  }

  /** */
  public save_edited_hive(): void {
    this.edited_hive$$
      .pipe(
        take(1),
        switchMap(edited_hive => {
          const hive_setup_date = this._hive_setup_date;

          // Agreement pipe
          let agreement_pipe: Observable<boolean> = of(true);

          // Add checking of future date
          if (isAfter(new Date(hive_setup_date), endOfDay(new Date()))) {
            agreement_pipe = agreement_pipe.pipe(
              switchMap(last_agreement =>
                last_agreement
                  ? this._dialogs.confirm(
                      i18n<string>(
                        `VIEWS.MODALS.FORM.The date you've picked is in the future. Are you sure this is the date you want to use ?`
                      ),
                      {
                        onFalseMessage: i18n<string>('VIEWS.MODALS.FORM.Cancel'),
                        onTrueMessage: i18n<string>('VIEWS.MODALS.FORM.Confirm'),
                      }
                    )
                  : of(false)
              )
            );
          }

          return agreement_pipe.pipe(
            switchMap(has_agreed => {
              if (!has_agreed) {
                return of({ agreement: false, event: null });
              }

              this._is_hives_dirty$$.next(true);

              return edited_hive.hiveSaveCreateUpdateSetup(this._apiary$$.getValue(), hive_setup_date).pipe(
                catchErrorInDialog(this._dialogs),
                map(event => ({ agreement: true, event }))
              );
            })
          );
        })
      )
      .subscribe({
        next: ({ agreement, event }) => {
          if (agreement) {
            this._edited_hive$$.next(null);
            this._is_hives_dirty$$.next(false);
            this._hive_setup_date = null;
          }
        },
        error: (err: unknown) => {
          this._is_hives_dirty$$.next(false);
          this.handleHttpError(err);
        },
      });
  }

  /** */
  public delete_or_archive_edited_hive(action: DeleteOrArchiveEntityAction): void {
    switch (action) {
      case DeleteOrArchiveEntityAction.DELETE:
      case DeleteOrArchiveEntityAction.ARCHIVE: {
        this._edited_hive$$.next(null);
        this._is_hives_loading$$.next(false);
        this._hive_setup_date = null;
        break;
      }

      case DeleteOrArchiveEntityAction.CANCEL:
      default: {
      }
    }
  }

  /** */
  public is_hive_is_edited$$(hide_id$$: Observable<number>): Observable<boolean> {
    return hide_id$$.pipe(
      switchMap(hive_id => this.edited_hive_id$$.pipe(map(editing_hive_id => hive_id === editing_hive_id))),
      distinctUntilRealChanged(),
      replay()
    );
  }

  // #endregion

  // #region -> (device management)

  public takeoutDevice(device: DRDevice): void {
    const takeout_device_url = () => {
      this._router.navigate(
        [
          {
            outlets: {
              modal: [
                'new_event',
                {
                  etype: device.type === 'RG' ? 'device_RG_takeout' : 'device_takeout',
                  args: JSON.stringify({
                    warehouse: device.warehouse_id,
                    data: {
                      device: {
                        imei: device.imei,
                      },
                    },
                  }),
                },
              ],
            },
          },
        ],
        {
          queryParamsHandling: 'preserve',
        }
      );
    };

    const device_installed_since = device.type === 'RG' ? (device as any).rg_config.since.date : device.hive_config.since.date;
    let days_since_installation = differenceInDays(Date.now(), new Date(device_installed_since));

    if (days_since_installation === 0) {
      days_since_installation = 1;
    }

    if (days_since_installation > 7) {
      takeout_device_url();
      return;
    }

    this._dialogs
      .customizable({
        body: {
          type: 'div',
          styles: {},
          elements: [
            {
              type: 'span',
              styles: { 'margin-bottom': '15px', 'font-size': '17px', 'font-weight': 'bold' },
              content:
                device.type === 'RG'
                  ? i18n<string>('DEVICE.ALL.ACTION.Device [device_name] was installed on this apiary since [days_number]')
                  : i18n<string>('DEVICE.ALL.ACTION.Device [device_name] was installed on this hive since [days_number]'),
              translateParams: {
                device_name: device.name,
                days_number: formatDistance(new Date(), new Date(device_installed_since), {
                  locale: this._appState.dl.dateFns,
                  addSuffix: false,
                }),
              },
            },
            {
              type: 'div',
              class: ['col-centered'],
              elements: [
                {
                  type: 'span',
                  content: i18n<string>('DEVICE.ALL.ACTION.This installation was an error or a test'),
                },
                {
                  type: 'schema',
                  styles: { width: '90%' },
                  name: device.type === 'RG' ? 'takeout_rg_drop_data' : 'takeout_device_drop_data',
                  data: {
                    since_date: format(parseDate(device_installed_since), this._appState.dl.ll, { locale: this._appState.dl.dateFns }),
                  },
                },
                {
                  type: 'button',
                  icon: 'trash-can-outline',
                  result: 'cancel_installation',
                  color: 'warn',
                  content: i18n<string>('DEVICE.ALL.ACTION.I want to delete this installation of [date]'),
                  translateParams: {
                    date: format(parseDate(device_installed_since), this._appState.dl.ll, { locale: this._appState.dl.dateFns }),
                  },
                },
              ],
            },
            {
              type: 'logical-sep',
              messageType: 'OR',
            },
            {
              type: 'div',
              class: ['col-centered'],
              elements: [
                {
                  type: 'span',
                  content:
                    device.type === 'RG'
                      ? i18n<string>('DEVICE.ALL.ACTION.The device actually stayed for a few days on this apiary')
                      : i18n<string>('DEVICE.ALL.ACTION.The device actually stayed for a few days on this hive'),
                },
                {
                  type: 'schema',
                  styles: { width: '90%' },
                  name: device.type === 'RG' ? 'takeout_rg_keep_data' : 'takeout_device_keep_data',
                  data: {
                    since_date: format(parseDate(device_installed_since), this._appState.dl.ll, { locale: this._appState.dl.dateFns }),
                    takeout_date: format(new Date(), this._appState.dl.ll, { locale: this._appState.dl.dateFns }),
                  },
                },
                {
                  type: 'button',
                  icon: 'arrow-down-box',
                  content: i18n<string>('DEVICE.ALL.ACTION.I want to declare the withdrawal of this device'),
                  result: 'remove_installation',
                },
              ],
            },
          ],
        },
        footer: {
          styles: { 'flex-direction': 'column' },
          buttons: {
            styles: { margin: '8px 0px 0px 0px' },
            items: [
              {
                type: 'button',
                content: i18n<string>('VIEWS.MODALS.FORM.Cancel'),
                result: 'cancel',
              },
            ],
          },
        },
      })
      .pipe(
        map(res => res.return_value),
        switchMap(result => {
          if (result === 'cancel_installation') {
            return DeleteEventDialogComponent.open(this._dialogs, {
              eventID: device.type === 'RG' ? (device as any).rg_config.since.event_id : device.hive_config.since.event_id,
            }).pipe(map(() => null));
          }

          if (result === 'remove_installation') {
            return of(takeout_device_url);
          }

          return of(null);
        })
      )
      .subscribe({
        next: res => {
          if (!isNil(res)) {
            res();
          }
        },
      });
  }

  // #endregion

  /** */
  public close(): void {
    const has_edited_hive_in_progress = !isNil(this._edited_hive$$.getValue());
    const has_new_hive_in_progress = !isNil(this._new_hive$$.getValue());

    if (has_edited_hive_in_progress || has_new_hive_in_progress) {
      this._dialogs
        .confirm(i18n<string>('VIEWS.MODALS.FORM.One or more changes are not saved. Do you want to close by abandoning these changes ?'), {
          onFalseMessage: i18n<string>('VIEWS.MODALS.FORM.Come back'),
          onTrueMessage: i18n<string>('VIEWS.MODALS.FORM.Close without saving'),
        })
        .subscribe(agreement => {
          if (agreement) {
            super.close();
          }
        });
    } else {
      super.close();
    }
  }
}
