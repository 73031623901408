import { isNil } from 'lodash-es';
import { Event } from '../Event';

export class NopDeviceEvent extends Event {
  protected extandDeviceParams(dev_dict: any, type?: string) {
    if (isNil(type)) {
      type = dev_dict.dtype || dev_dict.type;
    }
    const imei = `${dev_dict.imei}`;
    // Compute device label dfrom type and imei
    let dev_label = imei.slice(Math.max(imei.length - 4, 0));
    if (!isNil(type)) {
      dev_label = `${type}_${dev_label}`;
    }
    const ext_dev_dict = {
      imei,
      label: dev_label,
      link: `<a href="javascript:void(modal:device, imei=${imei})">${dev_label}</a>`,
    };
    return ext_dev_dict;
  }
}
