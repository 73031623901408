<bg2-modal [fullscreen]="fullscreen || false" #modal>
  <bg2-modal-header>
    <div class="header">
      <span class="mdi mdi-library-plus mdi-36px"></span>
      <span class="title">{{'VIEWS.MODALS.FORM.New exploitation' | translate}}</span>
    </div>

    <div class="action-buttons">
      <span class="mdi mdi-close-box mdi-36px" (click)="close()"></span>
    </div>
  </bg2-modal-header>

  <bg2-modal-content>
    <div class="error" *ngIf="error">{{ error | translate }}</div>

    <bg2-zoho-manager-container [require_attention_for]="true">
      <div style="text-align: center">
        <p>
          <b>{{ 'ROUTABLE_MODALS.NEW_EXPLOITATION.ZOHO.You are about to set up a new exploitation' | translate }}</b>
        </p>

        <p *ngIf="is_authenticated_to_zoho$$ | async">
          <span>
            {{ 'ROUTABLE_MODALS.NEW_EXPLOITATION.ZOHO.When connected to Zoho, the exploitation\'ID in the CRM account will be automatically updated upon saving' | translate }}
          </span>
        </p>

        <p *ngIf="(is_authenticated_to_zoho$$ | async) === false">
          <span>
            {{ 'ROUTABLE_MODALS.NEW_EXPLOITATION.ZOHO.To automatically update Zoho CRM with the new exploitation\'s ID, you must log in' | translate }}
          </span>
          <span>&nbsp;</span>
          <span>
            {{ 'ROUTABLE_MODALS.NEW_EXPLOITATION.ZOHO.Otherwise, you will need to manually change the exploitation\'s ID in Zoho CRM' | translate }}
          </span>
        </p>

        <p>
          {{ 'ROUTABLE_MODALS.NEW_EXPLOITATION.ZOHO.If you do not want to link this exploitation to a CRM account, ignore this message (of course, leave the CRM ID field blank)' | translate }}
        </p>
      </div>
    </bg2-zoho-manager-container>

    <bg2-form-overlay
      #eventForm
      *ngIf="form_ready$$ | async"
      [source_model]="form_model$$ | async"
      [source_schema]="form_schema$$ | async"
      (when_form_valid_update)="setFormValid($event)"
      (when_form_data_update)="onFormChange({ value: $event })"
    ></bg2-form-overlay>
  </bg2-modal-content>

  <bg2-modal-footer>
    <bg2-button
      style="flex: 1 1 0%"
      [type]="'cancel'"
      [message]="'VIEWS.MODALS.FORM.Cancel' | translate"
      (btn_click)="close()"
    ></bg2-button>

    <bg2-button
      style="flex: 1 1 0%"
      [type]="'action'"
      [icon]="'mdi-content-save'"
      [loading]="loading$$ | async"
      [message]="'VIEWS.MODALS.FORM.Save' | translate"
      [disabled]="((form_valid$$ | async) === false) || (loading$$ | async)"
      (btn_click)="submit()"
    ></bg2-button>
  </bg2-modal-footer>
</bg2-modal>
