import { Component, Input, ChangeDetectionStrategy } from '@angular/core';

type LOADING_SIZE = 'tiny' | 'small' | 'medium' | 'large';

@Component({
  selector: 'hex-loading',
  templateUrl: './hex-loading.component.html',
  styleUrls: ['./hex-loading.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HexLoadingComponent {
  @Input()
  public size: LOADING_SIZE = 'medium';

  @Input()
  public inline = false;

  @Input()
  public message: string = null;
}
