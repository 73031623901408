import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime-ex';

import { GoogleMapsLoader } from 'app/misc/services/google-maps-loader.service';
import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { MaterialFileInputModule } from 'ngx-material-file-input';

import { SchemaFormModule } from 'ngx-schema-form';
import { WidgetRegistry } from 'ngx-schema-form';

import { SharedModule } from 'app/shared-module/shared.module';
import { MiscModule } from 'app/misc/misc.module';
import { DialogsModalsModule } from 'app/widgets/dialogs-modals/dialogs-modals.module';
import { DevicesSharedModule } from 'app/views/devices/shared/devices-shared.module';
import { HivesSharedModule } from 'app/views/hives/shared/hives-shared.module';
import { MiscWidgetsModule } from 'app/widgets/misc-widgets/misc-widgets.module';

import { EfWidgetRegistry } from './eventforms.widgetregistry';
import { EfTextAreaWidget } from './textarea/textarea.widget';
import { EfStringWidget } from './string/string.widget';
import { EfNumberWidget } from './number/number.widget';
import { EfNumberLightWidget } from './number-light/number-light.widget';
import { EfSliderWidgetComponent } from './slider/slider.widget';
import { EfSelectWidgetComponent } from './select/select.widget';
import { EfGeoposWidgetComponent } from './geopos/geopos.widget';
import { EfDateWidgetComponent } from './date/date.widget';
import { EfBooleanWidget } from './boolean/boolean.widget';
import { EfObjectWidgetComponent } from './object/object.widget';
import { EfArrayWidget } from './array/array.widget';
import { EfColorHSLSelectorWidget } from './color-hsl-selector/color-hsl-selector.widget';

import { EfBg2EntityWidget } from './bg2entity/entity.widget';

import { EfBg2DeviceWidgetComponent } from './bg2device/device.widget';
import { EfBg2DeviceComWidgetComponent } from './bg2device-com/device-com.widget';

import { EfBg2UserWidget } from './bg2user/user.widget';
import { EfBg2UserSelectWidget } from './bg2user-select/bg2user-select.widget';
import { EfBg2EntityACLWidgetComponent } from './bg2entity-acl/entity-acl.widget';
import { EfBg2SuperboxHarvestSelectorWidgetComponent } from './bg2superbox-harvest-selector/bg2superbox-harvest-selector.widget';
import { EfBg2SuperboxRepartitionWidget } from './bg2superbox-repartition/bg2superbox-repartition.widget';
import { EfBg2SuperboxFillingWidgetComponent } from './bg2superbox-filling/bg2superbox-filling.widget';

import { FormElementHeaderComponent } from './element-header/element-header.component';
import { EfCheckboxWidget } from './checkbox/checkbox.widget';
import { EfChecklistWidgetComponent } from './checklist/checklist.widget';
import { EfRadioboxWidget } from './radiobox/radiobox.widget';
import { EfArrayAddOnlyWidgetComponent } from './array-add-only/array-add-only.widget';
import { EfBg2VisitConfigWidgetComponent } from './bg2visit-config/bg2visit-config.widget';
import { EfBg2FastvisitWidgetComponent } from './bg2fastvisit/bg2fastvisit.widget';
import { FastVisitDialogComponent } from './bg2fastvisit/fastvisit-dialog/fastvisit-dialog.component';
import { BgControlWidgetComponent } from './control/control.widget';
import { Bg2RatingWidget } from './bg2-rating/bg2-rating.widget';

import { FormElementPreviousComponent } from './element-previous/element-previous.component';
import { EfDateRangeWidgetComponent } from './date-range/date-range.widget';
import { NgMatSelectFormFieldComponent } from './ng-mat-select/ng-mat-select.component';
import { NgMatTextComponent } from './ng-mat-text/ng-mat-text.component';
import { NgMatNumberRangeComponent } from './ng-mat-number-range/ng-mat-number-range.component';
import { MaterialSharedModule } from 'app/shared-module/material-shared.module';
import { EventsSharedModule } from 'app/views/events/events-shared.module';
import { EntitiesSharedModule } from 'app/views/entities/entities-shared.module';
import { WidgetsReusableModule } from '../widgets-reusables/widgets-reusables.module';
import { NgMatArrayWidgetComponent } from './ng-mat-array/ng-mat-array.component';
import { EfBg2RadioboxWidget } from './bg2radiobox/bg2radiobox.widget';
import { NgMatTelComponent } from './ng-mat-tel/ng-mat-tel.component';
import { NgMatPasswordComponent } from './ng-mat-password/ng-mat-password.component';
import { NgMatTextareaComponent } from './ng-mat-textarea/ng-mat-textarea.component';
import { ZohoSearchCRMWidgetComponent } from './zoho-search-crm/zoho-search-crm-widget.component';
import { ZohoSearchDeskWidgetComponent } from './zoho-search-desk/zoho-search-desk-widget.component';
import { ZohoSharedModule } from 'app/views/zoho/shared/zoho-shared.module';
import { NgMatFileComponent } from './ng-mat-file/ng-mat-file.component';
import { Bg2EntityExploitationFormWidgetComponent } from './bg2-entity-exploitation/bg2-entity-exploitation.component';
import { ExploitationsSharedModule } from 'app/views/exploitations/shared/exploitations-shared.module';
import { EfBg2ChecklistWidget } from './bg2checklist/bg2checklist.widget';
import { TimezoneSelectFormWidgetComponent } from './bg2-timezone-select/bg2-timezone-select.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    SchemaFormModule,
    FormsModule,
    ReactiveFormsModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    NgxSliderModule,
    MaterialFileInputModule,
    SharedModule, // for i18n mainly
    MiscModule,
    DevicesSharedModule,
    HivesSharedModule,
    MiscWidgetsModule,
    MaterialSharedModule,
    DialogsModalsModule,
    EventsSharedModule,
    EntitiesSharedModule,
    ExploitationsSharedModule,
    WidgetsReusableModule,

    ZohoSharedModule,
  ],
  declarations: [
    BgControlWidgetComponent,
    EfNumberWidget,
    Bg2RatingWidget,
    EfNumberLightWidget,
    EfSliderWidgetComponent,
    EfStringWidget,
    EfTextAreaWidget,
    EfSelectWidgetComponent,
    EfGeoposWidgetComponent,
    EfDateWidgetComponent,
    EfBooleanWidget,
    EfObjectWidgetComponent,
    EfArrayWidget,
    EfArrayAddOnlyWidgetComponent,
    EfCheckboxWidget,
    EfChecklistWidgetComponent,
    EfRadioboxWidget,
    EfColorHSLSelectorWidget,

    EfBg2EntityWidget,
    EfBg2EntityACLWidgetComponent,
    TimezoneSelectFormWidgetComponent,
    Bg2EntityExploitationFormWidgetComponent,
    EfBg2DeviceWidgetComponent,
    EfBg2DeviceComWidgetComponent,
    EfBg2UserWidget,
    EfBg2UserSelectWidget,
    EfDateRangeWidgetComponent,

    EfBg2SuperboxRepartitionWidget,
    EfBg2SuperboxFillingWidgetComponent,
    EfBg2SuperboxHarvestSelectorWidgetComponent,

    EfBg2RadioboxWidget,
    EfBg2ChecklistWidget,

    EfBg2VisitConfigWidgetComponent,
    EfBg2FastvisitWidgetComponent,
    FastVisitDialogComponent,

    FormElementHeaderComponent,
    FormElementPreviousComponent,

    // Material versions of form fields
    NgMatTelComponent,
    NgMatTextComponent,
    NgMatFileComponent,
    NgMatNumberRangeComponent,
    NgMatSelectFormFieldComponent,

    // Zoho fields
    ZohoSearchCRMWidgetComponent,
    ZohoSearchDeskWidgetComponent,
    NgMatPasswordComponent,
    NgMatTextareaComponent,
    NgMatArrayWidgetComponent,
  ],
  providers: [
    {
      // Custom Widget regristry
      provide: WidgetRegistry,
      useClass: EfWidgetRegistry,
    },
    GoogleMapsLoader,
  ],
})
export class EfWidgetModule {}
