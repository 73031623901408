<ng-container *ngIf="visible$$ | async">
  <ng-container *ngIf="!hidden || this.formProperty.value">
    <form-element-header *ngIf="!hidden" [schema]="schema" [showLabel]="false" [title_style]="options.title_style"> </form-element-header>

    <div>
      <mat-form-field appearance="outline" class="ng-default" style="width: 100%">
        <mat-label>{{ schema.label | translate }}</mat-label>
        <textarea
          #textareaWidget
          [id]="uid"
          [name]="name"
          matInput
          [attr.readOnly]="schema?.readOnly ? true : null"
          [attr.disabled]="schema?.readOnly ? true : null"
          [attr.minLength]="schema?.minLength || null"
          [attr.maxLength]="schema?.maxLength || null"
          cdkTextareaAutosize
          #autosize="cdkTextareaAutosize"
          cdkAutosizeMinRows="5"
          cdkAutosizeMaxRows="10"
          [required]="schema?.isRequired"
          [ngModel]="value$$ | async"
          (ngModelChange)="when_value_changed($event)"
        ></textarea>
        <mat-hint *ngIf="(schema?.maxLength > 0)" align="end"> {{ textareaWidget.value.length }} / {{ schema?.maxLength }} </mat-hint>
      </mat-form-field>
    </div>
  </ng-container>

  <a *ngIf="hidden && !(value$$ | async)" (click)="options.optional && hidden = false" href="javascript:void(0)">
    {{'WIDGETS.EVENT_FORM.ARRAY.Add' | translate}} {{(schema.label || schema.title) | translate}}
  </a>

  <a *ngIf="options.optional && !hidden && !(value$$ | async)" (click)="hidden = true" href="javascript:void(0)">
    {{'WIDGETS.EVENT_FORM.ARRAY.Remove' | translate}} {{(schema.label || schema.title) | translate}}
  </a>
</ng-container>
