<ng-template #undefinedDescription>
  <span>{{ 'WIDGETS.WIDGETS_REUSABLES.ERRORS.ERROR_HELPER.An unknown error occured' | translate }}</span>
</ng-template>

<ng-template #undefinedIcon>
  <span class="mdi mdi-alert-decagram"></span>
</ng-template>

<ng-template #undefinedButton>
  <div></div>
</ng-template>

<div *ngIf="error" class="bg2-error-helper-component" [class.--with-background]="with_background">
  <div class="error-description">
    <ng-container *ngIf="error?.description; let descriptions; else: undefinedDescription">
      <ng-container *ngFor="let description of descriptions; let index = index">
        <ng-container *ngIf="description.type === 'span'">
          <span>{{ description?.content | translate : description?.translateParams }}</span>
        </ng-container>

        <ng-container *ngIf="description.type === 'image_svg'">
          <img [src]="description.url" alt="" [ngStyle]="description?.styles ?? {}" />
        </ng-container>

        <ng-container *ngIf="description.type === 'markdown'">
          <span [innerHtml]="description?.content | translate : description?.translateParams | markdown"></span>
        </ng-container>

        <ng-container *ngIf="description.type === 'ace'">
          <span
            [innerHtml]="
              description?.content
                | translate
                  : {
                      ace: description?.translateParams?.ace | translate,
                      what: description?.translateParams?.what | translate | lowercase
                    }
                | markdown
            "
          ></span>
        </ng-container>

        <ng-container *ngIf="description.type === 'button'">
          <bg2-button
            [icon]="description?.icon"
            [options]="{ is_thin: true }"
            [color]="description?.color ?? null"
            [type]="description?.action ?? 'action'"
            [message]="description?.message | translate"
            (btn_click)="onClickButton(index)"
          ></bg2-button>
        </ng-container>
      </ng-container>
    </ng-container>
  </div>
</div>
