import {
  ChangeDetectorRef,
  ComponentRef,
  Directive,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
  ViewContainerRef,
} from '@angular/core';

import { Modifier } from '@popperjs/core';

import { Subscription } from 'rxjs';

import { TooltipContentComponent } from './tooltip-content/tooltip-content.component';

import { TooltipPlacement } from './enums/tooltip-placement';
import { TooltipOptions } from './interfaces/tooltip-options.model';
import { TooltipTriggerMethod } from './enums/tooltip-trigger-method';
import { TOOLTIP_OPTIONS_TOKEN } from './tokens/tooltip-options-token';

/**
 * Angular directive for PopperJS, inpired from {@link https://www.npmjs.com/package/ngx-popperjs} and modified for our usage.
 */
@Directive({
  selector: '[popper]',
})
export class TooltipDirective implements OnInit, OnDestroy {
  static baseOptions: TooltipOptions = {
    showDelay: 0,
    placement: TooltipPlacement.AUTO,
    hideOnClickOutside: true,
    hideOnMouseLeave: false,
    hideOnScroll: false,
    appendTo: undefined,
    ariaRole: 'popper',
    ariaDescribe: '',
    styles: {},
    trigger: TooltipTriggerMethod.click,
  };

  static assignDefined(target: any, ...sources: any[]) {
    for (const source of sources) {
      for (const key of Object.keys(source)) {
        const val = source[key];
        if (val !== undefined) {
          target[key] = val;
        }
      }
    }

    return target;
  }

  // #region -> (directive inputs)

  @Input('popperApplyClass')
  public set applyClass(newValue: string) {
    if (newValue === this._applyClass) {
      return;
    }

    this._applyClass = newValue;
    this._checkExisting('applyClass', newValue);
  }

  public get applyClass(): string {
    return this._applyClass;
  }

  @Input()
  public popperAriaDescribeBy: string | void;

  @Input()
  public popperAriaRole: string | void;

  @Input()
  public popperBoundaries: string;

  @Input()
  public popperCloseOnClickOutside: boolean;

  @Input('popper')
  public set content(newValue: string | TooltipContentComponent) {
    if (newValue === this._content) {
      return;
    }
    this._content = newValue;
    if (this._popperContent) {
      if (typeof newValue === 'string') {
        this._popperContent.text = newValue;
      } else {
        this._popperContent = newValue;
      }
    }
  }

  public get content(): string | TooltipContentComponent {
    return this._content;
  }

  @Input('popperDisableAnimation')
  public disableAnimation: boolean;

  @Input('popperDisabled')
  public set disabled(newValue: boolean) {
    if (newValue === this._disabled) {
      return;
    }
    this._disabled = !!newValue;
    if (this._shown) {
      this.hide();
    }
  }

  public get disabled(): boolean {
    return this._disabled;
  }

  @Input('popperDisableStyle')
  public disableStyle: boolean;

  @Input('popperHideOnClickOutside')
  public hideOnClickOutside: boolean | void;

  @Input('popperHideOnMouseLeave')
  public hideOnMouseLeave: boolean | void;

  @Input('popperHideOnScroll')
  public hideOnScroll: boolean | void;

  @Input()
  public popperTimeout: number = 0;

  @Input('popperPlacement')
  set placement(newValue: TooltipPlacement) {
    this._popperPlacement = newValue;
    this._checkExisting('placement', newValue);
  }

  public get placement(): TooltipPlacement {
    return this._popperPlacement;
  }

  @Input()
  public popperAppendTo: string;

  @Input()
  public set popperApplyArrowClass(newValue: string) {
    if (newValue === this._popperApplyArrowClass) {
      return;
    }
    this._popperApplyArrowClass = newValue;
    if (this._popperContent) {
      this._popperContent.popperOptions.applyArrowClass = newValue;
      if (!this._shown) {
        return;
      }
      this._popperContent.popperInstance.setOptions(this._popperContent.popperOptions);
    }
  }

  public get popperApplyArrowClass(): string {
    return this._popperApplyArrowClass;
  }

  @Input()
  public popperModifiers: Partial<Modifier<any, any>>[];

  @Input('popperPositionFixed')
  positionFixed: boolean;

  @Input('popperPreventOverflow')
  public set preventOverflow(newValue: boolean) {
    this._popperPreventOverflow = newValue != null && `${newValue}` !== 'false';
    this._checkExisting('preventOverflow', this._popperPreventOverflow);
  }

  public get preventOverflow(): boolean {
    return this._popperPreventOverflow;
  }

  @Input()
  public popperDelay: number | undefined;

  @Input('popperShowOnStart')
  public showOnStart: boolean;

  @Input()
  public popperTrigger: TooltipTriggerMethod | undefined;

  @Input('popperStyles')
  public styles: object;

  @Input()
  public popperTarget: HTMLElement;

  @Input('popperTimeoutAfterShow')
  public timeoutAfterShow: number = 0;

  // #endregion

  // #region -> (directive outputs)

  @Output()
  public popperOnHidden: EventEmitter<TooltipDirective> = new EventEmitter<TooltipDirective>();

  @Output()
  public popperOnShown: EventEmitter<TooltipDirective> = new EventEmitter<TooltipDirective>();

  @Output()
  public popperOnUpdate: EventEmitter<any> = new EventEmitter<any>();

  // #endregion

  private _applyClass: string;
  private _content: string | TooltipContentComponent;
  private _disabled: boolean;
  private _eventListeners: (() => void)[] = [];
  private _globalEventListeners: (() => void)[] = [];
  private _popperApplyArrowClass: string;
  private _popperContent: TooltipContentComponent;
  private _popperContentClass = TooltipContentComponent;
  private _popperContentRef: ComponentRef<TooltipContentComponent>;
  private _popperPlacement: TooltipPlacement;
  private _popperPreventOverflow: boolean;
  private _scheduledHideTimeout: any;
  private _scheduledShowTimeout: any;
  private _shown: boolean = false;

  // #region -> (directive basics)

  /** */
  private _subscriptions: Subscription[] = [];

  constructor(
    private _viewContainerRef: ViewContainerRef,
    private _changeDetectorRef: ChangeDetectorRef,
    private _elementRef: ElementRef,
    private _renderer: Renderer2,
    @Inject(TOOLTIP_OPTIONS_TOKEN) private _popperDefaults: TooltipOptions = {}
  ) {
    TooltipDirective.baseOptions = { ...TooltipDirective.baseOptions, ...this._popperDefaults };
  }

  ngOnInit() {
    this.hideOnClickOutside = typeof this.hideOnClickOutside === 'undefined' ? this.popperCloseOnClickOutside : this.hideOnClickOutside;

    if (typeof this.content === 'string') {
      this._popperContent = this._constructContent();
      this._popperContent.text = this.content;
    } else if (typeof this.content === typeof void 0) {
      this._popperContent = this._constructContent();
      this._popperContent.text = '';
    } else {
      this._popperContent = this.content;
    }

    const popper_component_ref = this._popperContent;
    popper_component_ref.referenceObject = this.getRefElement();

    this._setContentProperties(popper_component_ref);
    this._setDefaults();
    this.applyTriggerListeners();

    if (this.showOnStart) {
      this.scheduledShow();
    }
  }

  ngOnDestroy() {
    this._subscriptions.forEach(sub => sub.unsubscribe && sub.unsubscribe());
    this._subscriptions.length = 0;
    this._clearEventListeners();
    this._clearGlobalEventListeners();
    this._scheduledShowTimeout && clearTimeout(this._scheduledShowTimeout);
    this._scheduledHideTimeout && clearTimeout(this._scheduledHideTimeout);
    this._popperContent && this._popperContent.clean();
  }

  // #endregion

  private applyTriggerListeners() {
    switch (this.popperTrigger) {
      case TooltipTriggerMethod.click: {
        this._pushListener('click', this.toggle.bind(this));
        break;
      }

      case TooltipTriggerMethod.mousedown: {
        this._pushListener('mousedown', this.toggle.bind(this));
        break;
      }

      case TooltipTriggerMethod.hover: {
        this._pushListener('mouseenter', this.scheduledShow.bind(this, this.popperDelay));
        ['touchend', 'touchcancel', 'mouseleave'].forEach(eventName => {
          this._pushListener(eventName, this.scheduledHide.bind(this, null, this.popperTimeout));
        });
        break;
      }
    }

    if (this.popperTrigger !== TooltipTriggerMethod.hover && this.hideOnMouseLeave) {
      ['touchend', 'touchcancel', 'mouseleave'].forEach(eventName => {
        this._pushListener(eventName, this.scheduledHide.bind(this, null, this.popperTimeout));
      });
    }
  }

  private getRefElement() {
    return this.popperTarget || this._viewContainerRef.element.nativeElement;
  }

  private hide() {
    if (this.disabled) {
      return;
    }

    if (!this._shown) {
      this._overrideShowTimeout();

      return;
    }

    this._shown = false;

    if (this._popperContentRef) {
      this._popperContentRef.instance.hide();
    } else {
      this._popperContent.hide();
    }

    this.popperOnHidden.emit(this);
    this._clearGlobalEventListeners();
  }

  private hideOnClickOutsideHandler($event: MouseEvent): void {
    if (
      this.disabled ||
      !this.hideOnClickOutside ||
      $event.target === this._popperContent.elRef.nativeElement ||
      this._popperContent.elRef.nativeElement.contains($event.target)
    ) {
      return;
    }

    this.scheduledHide($event, this.popperTimeout);
  }

  private hideOnScrollHandler($event: MouseEvent): void {
    if (this.disabled || !this.hideOnScroll) {
      return;
    }
    this.scheduledHide($event, this.popperTimeout);
  }

  private scheduledHide($event: any = null, delay: number = this.popperTimeout) {
    if (this.disabled) {
      return;
    }

    this._overrideShowTimeout();
    this._scheduledHideTimeout = setTimeout(() => {
      const to_element = $event ? $event.toElement : null;
      const popper_content_view = this._popperContent.popperViewRef ? this._popperContent.popperViewRef.nativeElement : false;

      if (
        !popper_content_view ||
        popper_content_view === to_element ||
        popper_content_view.contains(to_element) ||
        (this.content && (this.content as TooltipContentComponent).isMouseOver)
      ) {
        return;
      }

      this.hide();
      this._applyChanges();
    }, delay);
  }

  private scheduledShow(delay: number = this.popperDelay) {
    if (this.disabled) {
      return;
    }
    this._overrideHideTimeout();
    this._scheduledShowTimeout = setTimeout(() => {
      this.show();
      this._applyChanges();
    }, delay);
  }

  private show() {
    if (this._shown) {
      this._overrideHideTimeout();

      return;
    }

    this._shown = true;
    const popper_component_ref = this._popperContent;
    const element = this.getRefElement();

    if (popper_component_ref.referenceObject !== element) {
      popper_component_ref.referenceObject = element;
    }

    this._setContentProperties(popper_component_ref);

    popper_component_ref.show();
    this.popperOnShown.emit(this);

    if (this.timeoutAfterShow > 0) {
      this.scheduledHide(null, this.timeoutAfterShow);
    }

    this._globalEventListeners.push(this._renderer.listen('document', 'click', this.hideOnClickOutsideHandler.bind(this)));
    this._globalEventListeners.push(
      this._renderer.listen(this._getScrollParent(this.getRefElement()), 'scroll', this.hideOnScrollHandler.bind(this))
    );
  }

  private toggle() {
    if (this.disabled) {
      return;
    }

    this._shown ? this.scheduledHide(null, this.popperTimeout) : this.scheduledShow();
  }

  private _applyChanges() {
    this._changeDetectorRef.markForCheck();
    this._changeDetectorRef.detectChanges();
  }

  private _checkExisting(key: string, newValue: string | number | boolean | TooltipPlacement): void {
    if (this._popperContent) {
      (this._popperContent.popperOptions as any)[key] = newValue;
      if (!this._shown) {
        return;
      }
      this._popperContent.popperInstance.setOptions(this._popperContent.popperOptions);
    }
  }

  private _clearEventListeners() {
    this._eventListeners.forEach(evt => {
      evt && typeof evt === 'function' && evt();
    });
    this._eventListeners.length = 0;
  }

  private _clearGlobalEventListeners() {
    this._globalEventListeners.forEach(evt => {
      evt && typeof evt === 'function' && evt();
    });
    this._globalEventListeners.length = 0;
  }

  private _constructContent(): TooltipContentComponent {
    this._popperContentRef = this._viewContainerRef.createComponent(this._popperContentClass);

    return this._popperContentRef.instance as TooltipContentComponent;
  }

  private _getScrollParent(node: any): any {
    const is_element = node instanceof HTMLElement;
    const overflow_y = is_element && window.getComputedStyle(node).overflowY;
    const is_scrollable = overflow_y !== 'visible' && overflow_y !== 'hidden';

    if (!node) {
      return null;
    } else if (is_scrollable && node.scrollHeight >= node.clientHeight) {
      return node;
    }

    return this._getScrollParent(node.parentNode) || document;
  }

  private _onPopperUpdate(event: any) {
    this.popperOnUpdate.emit(event);
  }

  private _overrideHideTimeout() {
    if (this._scheduledHideTimeout) {
      clearTimeout(this._scheduledHideTimeout);
      this._scheduledHideTimeout = 0;
    }
  }

  private _overrideShowTimeout() {
    if (this._scheduledShowTimeout) {
      clearTimeout(this._scheduledShowTimeout);
      this._scheduledHideTimeout = 0;
    }
  }

  private _pushListener(name: string, cb: () => void): void {
    this._eventListeners.push(this._renderer.listen(this._elementRef.nativeElement, name, cb));
  }

  private _setContentProperties(popperRef: TooltipContentComponent) {
    popperRef.popperOptions = TooltipDirective.assignDefined(popperRef.popperOptions, TooltipDirective.baseOptions, {
      showDelay: this.popperDelay,
      disableAnimation: this.disableAnimation,
      disableDefaultStyling: this.disableStyle,
      placement: this.placement,
      boundariesElement: this.popperBoundaries,
      trigger: this.popperTrigger,
      positionFixed: this.positionFixed,
      popperModifiers: this.popperModifiers,
      ariaDescribe: this.popperAriaDescribeBy,
      ariaRole: this.popperAriaRole,
      applyClass: this.applyClass,
      applyArrowClass: this.popperApplyArrowClass,
      hideOnMouseLeave: this.hideOnMouseLeave,
      styles: this.styles,
      appendTo: this.popperAppendTo,
      preventOverflow: this.preventOverflow,
    });
    popperRef.onUpdate = this._onPopperUpdate.bind(this);
    this._subscriptions.push(popperRef.onHidden.subscribe(this.hide.bind(this)));
  }

  private _setDefaults() {
    ['showDelay', 'hideOnScroll', 'hideOnMouseLeave', 'hideOnClickOutside', 'ariaRole', 'ariaDescribe'].forEach(key => {
      (this as any)[key] = (this as any)[key] === void 0 ? (TooltipDirective.baseOptions as any)[key] : (this as any)[key];
    });
    this.popperTrigger = this.popperTrigger || TooltipDirective.baseOptions.trigger;
    this.styles = this.styles === void 0 ? { ...TooltipDirective.baseOptions.styles } : this.styles;
  }
}
