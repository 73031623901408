import { AfterViewInit, ChangeDetectionStrategy, Component, OnDestroy, Renderer2 } from '@angular/core';

import { sum } from 'lodash-es';

import { combineLatest, map, Observable, of, switchMap } from 'rxjs';
import { distinctUntilRealChanged, replay, robustCombineLatest, waitForNotNilValue } from '@bg2app/tools/rxjs';

import { Location } from 'app/models';
import { SuperBoxHarvest } from 'app/models/events/Superbox';

import { ConsoleLoggerService } from 'app/core/console-logger.service';

import { TimeBasedCarouselSlideComponent } from 'app/widgets/widgets-reusables/carousels/time-based-carousel/time-carousel-slide/time-carousel-slide.component';

@Component({
  selector: 'bg2-location-yearly-stats-carousel-slide',
  templateUrl: './location-yearly-stats-carousel-slide.component.html',
  styleUrls: ['./location-yearly-stats-carousel-slide.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LocationYearlyStatsCarouselSlideComponent extends TimeBasedCarouselSlideComponent implements AfterViewInit, OnDestroy {
  // #region -> (static parameters)

  /** */
  public current_year$$ = this.start_date$$.pipe(
    map(date => date.getFullYear()),
    distinctUntilRealChanged()
  );

  /** */
  public previous_year$$ = this.current_year$$.pipe(
    map(year => year - 1),
    distinctUntilRealChanged()
  );

  // #endregion

  // #region -> (location object management)

  /** */
  public location$$: Observable<Location> = this.dynamic_parameters$$.pipe(
    map(parameters => parameters?.location ?? null),
    waitForNotNilValue(),
    replay()
  );

  // #endregion

  // #region -> (component basics)

  /** */
  protected _logger: ConsoleLoggerService = new ConsoleLoggerService('LocationYearlyStatsCarouselSlideComponent', true);

  /** */
  constructor(protected _renderer: Renderer2) {
    super(_renderer);
    this.MAX_VISIBLE_CARDS = 1;
  }

  ngAfterViewInit(): void {
    super.ngAfterViewInit();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  // #endregion

  // #region -> (occupied days management)

  /** */
  private total_of_occupied_days_by_year$$ = this.location$$.pipe(switchMap(location => location.total_occupied_days_by_year$$));

  /** */
  public total_of_occupied_days_current_year$$ = combineLatest({
    year: this.start_date$$.pipe(map(date => date.getFullYear())),
    total_by_year: this.total_of_occupied_days_by_year$$,
  }).pipe(
    map(data => data.total_by_year?.[data.year] ?? 0),
    distinctUntilRealChanged()
  );

  /** */
  public total_of_occupied_days_previous_year$$ = combineLatest({
    year: this.start_date$$.pipe(map(date => date.getFullYear())),
    total_by_year: this.total_of_occupied_days_by_year$$,
  }).pipe(
    map(data => data.total_by_year?.[data.year - 1] ?? 0),
    distinctUntilRealChanged()
  );

  // #endregion

  // #region -> (superbox harvest data)

  /** */
  private superbox_harvests_by_year$$ = this.location$$.pipe(switchMap(location => location.events_superbox_harvest_by_year$$));

  /** */
  public superbox_harvests = {
    /** */
    for_current_year: {
      /** */
      total$$: combineLatest([this.current_year$$, this.superbox_harvests_by_year$$]).pipe(
        map(([year, events_by_year]) => events_by_year?.[year]?.length ?? 0)
      ),

      /** */
      mean_super_harvest$$: combineLatest([this.current_year$$, this.superbox_harvests_by_year$$]).pipe(
        map(([year, events_by_year]) => events_by_year?.[year] ?? []),
        switchMap((events: SuperBoxHarvest[]) => {
          const total_of_events = events?.length ?? 0;

          const values$$ = robustCombineLatest(events.map(event => event.nb_harvested_supers$$));

          if (total_of_events > 0) {
            return values$$.pipe(map(values => sum(values) / total_of_events));
          } else {
            return of(0);
          }
        }),
        distinctUntilRealChanged()
      ),
    },

    /** */
    for_previous_year: {
      /** */
      total$$: combineLatest([this.previous_year$$, this.superbox_harvests_by_year$$]).pipe(
        map(([year, events_by_year]) => events_by_year?.[year]?.length ?? 0)
      ),

      /** */
      mean_super_harvest$$: combineLatest([this.previous_year$$, this.superbox_harvests_by_year$$]).pipe(
        map(([year, events_by_year]) => events_by_year?.[year] ?? []),
        switchMap((events: SuperBoxHarvest[]) => {
          const total_of_events = events?.length ?? 0;

          const values$$ = robustCombineLatest(events.map(event => event.nb_harvested_supers$$));

          if (total_of_events > 0) {
            return values$$.pipe(map(values => sum(values) / total_of_events));
          } else {
            return of(0);
          }
        }),
        distinctUntilRealChanged()
      ),
    },
  };

  // #endregion

  // #region -> (helpers)

  /** */
  public calc_trend_icon$$ = (current: Observable<number>, previous: Observable<number>): Observable<`mdi-${string}`> =>
    combineLatest({ current, previous }).pipe(
      map(values => {
        if (values.current > values.previous) {
          return 'mdi-arrow-top-right' as `mdi-${string}`;
        }

        if (values.current < values.previous) {
          return 'mdi-arrow-bottom-right' as `mdi-${string}`;
        }

        return 'mdi-minus' as `mdi-${string}`;
      }),
      distinctUntilRealChanged(),
      replay()
    );

  // #endregion
}
