import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'join',
  pure: false,
})
export class JoinPipe implements PipeTransform {
  transform(value: any[], joiner: string = ','): any {
    return (value || []).join(joiner);
  }
}
