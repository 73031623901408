import { ChangeDetectionStrategy, Component } from '@angular/core';

import { AppStateService } from 'app/core/app-state.service';

import { Location } from 'app/models';

import { AbstractDialogComponent, AbstractDialogParams } from '../abstract-dialog.component';

export interface SimilarLocation {
  location: Location;
  similar_name: boolean;
  close_position: boolean;
}

export interface LocationNearPosWarnParams extends AbstractDialogParams {
  near_locations: SimilarLocation[];
}

@Component({
  selector: 'app-location-near',
  templateUrl: './location-near-pos-warn.component.html',
  styleUrls: ['./location-near-pos-warn.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LocationNearPosWarnComponent extends AbstractDialogComponent<LocationNearPosWarnParams, any> {
  constructor(public appState: AppStateService) {
    super();
  }

  public clicked(value: boolean): void {
    this.complete(value);
  }
}
