<bg2-modal [fullscreen]="fullscreen || false" #modal style="height: 100%">
  <bg2-modal-header>
    <div class="header">
      <span class="title">
        {{
          'VIEWS.DEVICES.DIALOGS_AND_MODALS.MODALS.DEVICE_SUPPORT.DEVICE_SUPPORT_UPDATE_DESK_TICKET_COMMENTS_MODAL.Update desk ticket comments'
            | translate
        }}
      </span>
    </div>

    <div class="action-buttons">
      <span class="mdi mdi-close-box mdi-36px" (click)="close()"></span>
    </div>
  </bg2-modal-header>

  <bg2-modal-content>
    <!-- Multiple tickets -->
    <bg2-ui-message *ngIf="(is_unique_ticket$$ | async) === false" [type]="'alert'" [title]="'Multiple tickets detected'">
      <div message>
        <span>{{ 'Cannot continue to edit comments' }}</span>
      </div>
    </bg2-ui-message>

    <div class="comments-container">
      <bg2-button [type]="'action'" [icon]="'mdi-comment-plus'" [message]="'add'" [options]="{ is_thin: true }"></bg2-button>

      <div *ngFor="let comment of comments$$ | async" class="comments-container__comment-container">
        <div class="comment-header">
          <ng-container *ngLet="comment.commenter$$ | async; let commenter">
            <div class="comment-header_persona-image">
              <img [src]="commenter?.photoURL" alt="" />
            </div>

            <div class="comment-header_persona-name">
              {{ commenter?.name }}
            </div>
          </ng-container>

          <div class="comment-header_date">
            {{ comment?.commentedTime$$ | async | formatDate : 'full' }}
          </div>

          <span style="flex: 1"></span>

          <div class="comment-header_actions">
            <bg2-button
              *ngIf="(is_editing_comment$$ | async) === false"
              [type]="'action'"
              [icon]="'mdi-comment-edit'"
              [message]="'edit'"
              [options]="{ is_thin: true }"
              (btn_click)="on_edit_comment(comment)"
            ></bg2-button>

            <ng-container *ngIf="is_editing_comment_id$$(comment?.id) | async">
              <bg2-button
                [type]="'cancel'"
                [icon]="'mdi-comment-remove'"
                [message]="'cancel'"
                [options]="{ is_thin: true }"
                (btn_click)="on_cancel_comment()"
              ></bg2-button>

              <bg2-button
                [type]="'action'"
                [icon]="'mdi-comment-flash'"
                [message]="'save'"
                [options]="{ is_thin: true }"
                (btn_click)="on_save_comment()"
              ></bg2-button>
            </ng-container>
          </div>
        </div>

        <div
          *ngIf="(selected_comment_to_edit?.value?.id$$ | async) !== (comment.id$$ | async)"
          class="comment-content"
          [innerHTML]="comment?.content$$ | async"
        ></div>

        <ng-container *ngIf="(selected_comment_to_edit?.value?.id$$ | async) === (comment.id$$ | async)">
          <quill-editor [format]="'html'" [ngModel]="comment.content$$ | async"></quill-editor>
        </ng-container>
      </div>
    </div>
  </bg2-modal-content>
</bg2-modal>
