import { Component, Input, ChangeDetectionStrategy } from '@angular/core';
import { marker as i18n } from '@biesbjerg/ngx-translate-extract-marker';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs';

export interface HtmlError {
  msg: string;
  title?: string;
  code?: number;
}

@Component({
  selector: 'html-error',
  styleUrls: ['./html-error.component.scss'],
  templateUrl: './html-error.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HtmlErrorComponent {

  private _error$: BehaviorSubject<HtmlError> = new BehaviorSubject({ msg: '', title: '' });
  public error$$: Observable<HtmlError> = this._error$.asObservable().pipe(
    map((error) => {
      if (error?.code) {
        switch (error?.code) {
          case 400: {
            error.title = i18n('ALL.ERROR.HTTP.TITLE.Bad Request');
            error.msg = i18n('ALL.ERROR.HTTP.MESSAGE.The sended request isn\'t valid');
            break;
          }

          case 403: {
            error.title = i18n('ALL.ERROR.HTTP.TITLE.Access Denied');
            error.msg = i18n('ALL.ERROR.HTTP.MESSAGE.You are not allowed to access these informations');
            break;
          }

          case 404: {
            error.title = i18n('ALL.ERROR.HTTP.TITLE.Not Found');
            error.msg = i18n('ALL.ERROR.HTTP.MESSAGE.These information aren\'t accessible or does not exist');
            break;
          }

          case 406: {
            error.title = i18n('ALL.ERROR.HTTP.TITLE.Information not Valid');
            break;
          }
        }
      }

      return error;
    })
  );

  @Input()
  public set error(error: HtmlError) {
    this._error$.next(error);
  }
}
