import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'bg2-navigate-not-identified-hives',
  templateUrl: './navigate-not-identified-hives.component.html',
  styleUrls: ['./navigate-not-identified-hives.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavigateNotIdentifiedHivesComponent {
  // #region -> (component basics)

  // #endregion

  /** */
  @Input()
  public total_hives: number = null;

  @Input()
  public current_index: number = 0;

  @Output()
  public current_index_change = new EventEmitter<number>();

  // #region -> (navigation management)

  /** */
  public previous_visit(): void {
    this.current_index_change.emit(this.current_index - 1);
  }

  /** */
  public next_visit(): void {
    this.current_index_change.emit(this.current_index + 1);
  }

  // #endregion
}
