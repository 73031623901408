import { NgZone } from '@angular/core';

import { BehaviorSubject } from 'rxjs';
import { distinctUntilRealChanged, waitForNotNilValue } from '@bg2app/tools/rxjs';

import * as d3 from 'd3';
import { isSameDay } from 'date-fns/esm';
import { inRange, isEmpty, isNil, max, maxBy, min, uniqueId } from 'lodash-es';
import { marker as i18n } from '@biesbjerg/ngx-translate-extract-marker';

import { AppStateService } from 'app/core/app-state.service';
import { ConsoleLoggerService } from 'app/core/console-logger.service';
import { D3SharedCursorService } from 'app/core/services/global/d3-shared-cursor.service';

import {
  AbstractD3Axes,
  AbstractD3Grid,
  AbstractD3Labels,
  AbstractD3Scales,
  D3SvgBuilderLuxon,
  AbstractUserEvents,
  AbstractDataObjects,
  AbstractEventObjects,
  ChartGeoposition,
} from '@bg2app/models/charts';

import { BatChange } from 'app/core/api-swagger/device';
import { DeviceGPRSSparklineData } from 'app/models';
import { GPRSLevelDataPoint } from 'app/models/data';

/** */
interface Axes extends AbstractD3Axes {
  /** */
  rssi_gprs: {
    /** */
    axis: d3.Axis<d3.AxisDomain>;

    /** */
    container: d3.Selection<SVGGElement, unknown, HTMLElement, any>;
  };
}

/** */
interface Scales extends AbstractD3Scales {
  /** */
  rssi_gprs: d3.ScaleLinear<number, number, never>;
}

/** */
interface DataObjects extends AbstractDataObjects {
  /** */
  rssi_gprs: {
    /** */
    // line_before_change_not_nil: {
    //   /** */
    //   shape: d3.Line<GPRSLevelDataPoint>;

    //   /** */
    //   path: d3.Selection<SVGPathElement, unknown, HTMLElement, any>;
    // };

    /** */
    // area_before_change_not_nil: {
    //   /** */
    //   shape: d3.Area<GPRSLevelDataPoint>;

    //   /** */
    //   path: d3.Selection<SVGPathElement, unknown, HTMLElement, any>;
    // };

    /** */
    // line_before_change_nil: {
    //   /** */
    //   shape: d3.Line<GPRSLevelDataPoint>;

    //   /** */
    //   path: d3.Selection<SVGPathElement, unknown, HTMLElement, any>;
    // };

    /** */
    // area_before_change_nil: {
    //   /** */
    //   shape: d3.Area<GPRSLevelDataPoint>;

    //   /** */
    //   path: d3.Selection<SVGPathElement, unknown, HTMLElement, any>;
    // };

    /** */
    line_not_nil: {
      /** */
      shape: d3.Line<GPRSLevelDataPoint>;

      /** */
      path: d3.Selection<SVGPathElement, unknown, HTMLElement, any>;
    };

    /** */
    // area_after_change_not_nil: {
    //   /** */
    //   shape: d3.Area<GPRSLevelDataPoint>;

    //   /** */
    //   path: d3.Selection<SVGPathElement, unknown, HTMLElement, any>;
    // };

    line_nil: {
      /** */
      shape: d3.Line<GPRSLevelDataPoint>;

      /** */
      path: d3.Selection<SVGPathElement, unknown, HTMLElement, any>;
    };

    // area_after_change_nil: {
    //   /** */
    //   shape: d3.Area<GPRSLevelDataPoint>;

    //   /** */
    //   path: d3.Selection<SVGPathElement, unknown, HTMLElement, any>;
    // };

    /** */
    circle: d3.Selection<SVGCircleElement, unknown, HTMLElement, any>;
  };
}

/** */
interface Events extends AbstractUserEvents {
  /** */
  rssi_gprs: {
    /** */
    text: d3.Selection<SVGGElement, unknown, HTMLElement, any>;

    /** */
    container: d3.Selection<HTMLDivElement, unknown, HTMLElement, any>;
  };
}

/** */
interface EventObjects extends AbstractEventObjects {}

interface Labels extends AbstractD3Labels {
  /** */
  labels: {};
}

/** */
interface Grid extends AbstractD3Grid {
  /** */
  rssi_gprs: {
    /** */
    excellent: d3.Selection<SVGGElement, unknown, HTMLElement, unknown>;

    /** */
    good: d3.Selection<SVGGElement, unknown, HTMLElement, unknown>;

    /** */
    low: d3.Selection<SVGGElement, unknown, HTMLElement, unknown>;

    /** */
    very_low: d3.Selection<SVGGElement, unknown, HTMLElement, unknown>;
  };
}

/** */
export class DeviceGPRSSparklineFactory extends D3SvgBuilderLuxon<DeviceGPRSSparklineData> {
  // #region -> (properties)

  public axes: Axes = {
    container: null,
    time: {
      axis: null,
      container: null,
    },
    rssi_gprs: {
      axis: null,
      container: null,
    },
  };

  public scales: Scales = {
    time: null,
    rssi_gprs: null,
  };

  public data_objects: DataObjects = {
    container: null,
    rssi_gprs: {
      // line_before_change_not_nil: {
      //   shape: d3
      //     .line<GPRSLevelDataPoint>()
      //     .defined(point => !isNil(point?.rssi_gprs))
      //     .curve(d3.curveLinear),
      //   path: null,
      // },
      // area_before_change_not_nil: {
      //   shape: d3
      //     .area<GPRSLevelDataPoint>()
      //     .defined(point => !isNil(point?.rssi_gprs))
      //     .curve(d3.curveLinear),
      //   path: null,
      // },

      // line_before_change_nil: {
      //   shape: d3
      //     .line<GPRSLevelDataPoint>()
      //     .defined(point => !isNil(point?.rssi_gprs))
      //     .curve(d3.curveLinear),
      //   path: null,
      // },
      // area_before_change_nil: {
      //   shape: d3
      //     .area<GPRSLevelDataPoint>()
      //     .defined(point => !isNil(point?.rssi_gprs))
      //     .curve(d3.curveLinear),
      //   path: null,
      // },

      line_not_nil: {
        shape: d3
          .line<GPRSLevelDataPoint>()
          .defined(point => !isNil(point?.rssi_gprs))
          .curve(d3.curveLinear),
        path: null,
      },
      // area_after_change_not_nil: {
      //   shape: d3
      //     .area<GPRSLevelDataPoint>()
      //     .defined(point => !isNil(point?.rssi_gprs))
      //     .curve(d3.curveLinear),
      //   path: null,
      // },

      line_nil: {
        shape: d3
          .line<GPRSLevelDataPoint>()
          .defined(point => !isNil(point?.rssi_gprs))
          .curve(d3.curveLinear),
        path: null,
      },
      // area_after_change_nil: {
      //   shape: d3
      //     .area<GPRSLevelDataPoint>()
      //     .defined(point => !isNil(point?.rssi_gprs))
      //     .curve(d3.curveLinear),
      //   path: null,
      // },

      circle: null,
    },
  };

  public event_objects: EventObjects = {
    container: null,
  };

  public user_events: Events = {
    container: null,
    event_bounds: null,

    focus_line_x: null,
    focus_line_y: null,

    rssi_gprs: {
      text: null,
      container: null,
    },
  };

  public grids: Grid = {
    container: null,

    time: {
      axis: null,
      container: null,
    },

    rssi_gprs: {
      excellent: null,
      good: null,
      low: null,
      very_low: null,
    },
  };

  public labels: Labels = {
    container: null,
    labels: {
      rssi_gprs: null,
    },
  };

  // #endregion

  // #region -> (component basics)

  /** */
  protected colors: {
    [key in 'rssi_gprs' | 'rssi_gprs_excellent' | 'rssi_gprs_good' | 'rssi_gprs_low' | 'rssi_gprs_very_low']: `#${string}`;
  } = {
    /** */
    rssi_gprs: `#0077ff`,

    /** */
    rssi_gprs_excellent: `#a3d0a3`,

    /** */
    rssi_gprs_good: `#bbdbbb`, // green

    /** */
    rssi_gprs_low: `#fbdebb`, // darkorange

    /** */
    rssi_gprs_very_low: `#fbbbbb`, // red
  };

  /** */
  protected LOGGER: ConsoleLoggerService = new ConsoleLoggerService('DeviceGPRSSparklineFactory', true);

  /** */
  constructor(protected _shared_cursor: D3SharedCursorService, protected _appState: AppStateService, protected _ngZone: NgZone) {
    super(_shared_cursor, _appState, _ngZone);

    this.unique_id = uniqueId('d3-js-chart-device-gprs-level-sparkline-');

    this.margins.top = 5;
    this.margins.left = 30;
    this.margins.right = 5;
    this.margins.bottom = 5;

    this.show_x_grid = false;
    this.show_day_cycle_grid = false;
  }

  /** */
  public destroy(): void {
    super.destroy();
  }

  /** */
  public get has_data() {
    const voltages = this.incoming_data?.gprs_levels ?? [];

    return !isEmpty(voltages ?? []);
  }

  // #endregion

  // #region -> (non-abstract methods)

  public resize(): void {
    // Update speed axis
    this.axes.rssi_gprs.axis.scale(this.scales.rssi_gprs);
    this.axes.rssi_gprs.container.call(this.axes.rssi_gprs.axis).attr('transform', `translate(${this.margins.left}, ${this.margins.top})`);

    super.resize();
  }

  // #endregion

  // #region -> (creation methods)

  /** */
  protected create_grid(geoposition: ChartGeoposition): void {
    super.create_grid(geoposition);

    // Add RSSI grid
    this.grids.rssi_gprs.excellent = this.grids.container
      .append('rect')
      .attr('x', 0)
      .attr('y', 0)
      .attr('fill', this.colors.rssi_gprs_excellent)
      .attr('height', 0)
      .attr('width', this.box_sizing.width - this.margins.left - this.margins.right);

    this.grids.rssi_gprs.good = this.grids.container
      .append('rect')
      .attr('x', 0)
      .attr('y', 0)
      .attr('fill', this.colors.rssi_gprs_good)
      .attr('height', 0)
      .attr('width', this.box_sizing.width - this.margins.left - this.margins.right);

    this.grids.rssi_gprs.low = this.grids.container
      .append('rect')
      .attr('x', 0)
      .attr('y', 0)
      .attr('fill', this.colors.rssi_gprs_low)
      .attr('height', 0)
      .attr('width', this.box_sizing.width - this.margins.left - this.margins.right);

    this.grids.rssi_gprs.very_low = this.grids.container
      .append('rect')
      .attr('x', 0)
      .attr('y', 0)
      .attr('fill', this.colors.rssi_gprs_very_low)
      .attr('height', 0)
      .attr('width', this.box_sizing.width - this.margins.left - this.margins.right);
  }

  public create_axes(): void {
    super.create_axes();

    this.axes.time.container.style('display', 'none');

    // Create voltage X axis
    this.axes.rssi_gprs.axis = d3.axisLeft(this.scales.rssi_gprs);
    this.axes.rssi_gprs.container = this.axes.container
      .append('g')
      .attr('class', 'd3-Y-axis')
      .attr('transform', `translate(${this.calc_size_of(0, ['+left'])}, ${this.calc_size_of(0, ['+top'])})`)
      .call(this.axes.rssi_gprs.axis);

    // Call at least one time the format
    this.apply_axes_format();
  }

  protected create_scales(): void {
    super.create_scales();

    this.scales.rssi_gprs = d3.scaleLinear().rangeRound([this.calc_size_of(this.box_sizing?.height, ['-top', '-bottom']), 0]);
  }

  protected create_labels(): void {
    super.create_labels();
  }

  // #endregion

  // #region -> (update methods)

  /** */
  protected update_axes(): void {
    super.update_axes();

    this.axes.rssi_gprs.axis.scale(this.scales.rssi_gprs).ticks(3);
    this.axes.rssi_gprs.container
      .transition()
      .duration(500)
      .call(this.axes.rssi_gprs.axis)
      .attr('transform', `translate(${this.margins.left}, ${this.margins.top})`);

    this.axes.rssi_gprs.container.selectAll('.tick').select('text').attr('fill', this.colors.rssi_gprs).attr('font-weight', 300);
  }

  /** */
  protected update_grid(): void {
    super.update_grid();

    const minimal_domain_value = this.scales.rssi_gprs.domain()[0]; // Tend to -infinite
    const maximal_domain_value = this.scales.rssi_gprs.domain()[1]; // Tend to 0

    // Update RSSI background (excellent [infinite;-70])
    if (this.has_data && maximal_domain_value > -70) {
      this.grids.rssi_gprs.excellent
        .style('display', 'initial')
        .attr('class', 'd3-grids-container__gprs-rssi-excellent')
        .attr('y', this.scales.rssi_gprs(maximal_domain_value) - this.box_sizing.height + this.margins.top + this.margins.bottom)
        .attr('fill', this.colors.rssi_gprs_excellent)
        .attr('height', Math.abs(this.scales.rssi_gprs(maximal_domain_value) - this.scales.rssi_gprs(-70)))
        .attr('width', this.box_sizing.width - this.margins.left - this.margins.right);
    } else {
      this.grids.rssi_gprs.excellent.style('display', 'none');
    }

    // Update RSSI background (good [-70;-80])
    if (
      this.has_data &&
      (inRange(minimal_domain_value, -70, 80) ||
        inRange(maximal_domain_value, -70, -80) ||
        inRange(-70, maximal_domain_value, minimal_domain_value))
    ) {
      const limit_max = minimal_domain_value <= -80 ? -80 : minimal_domain_value;
      const limit_min = maximal_domain_value <= -70 ? maximal_domain_value : -70;

      this.grids.rssi_gprs.good
        .style('display', 'initial')
        .attr('class', 'd3-grids-container__gprs-rssi-good')
        .attr('y', this.scales.rssi_gprs(limit_min) - this.box_sizing.height + this.margins.top + this.margins.bottom)
        .attr('fill', this.colors.rssi_gprs_good)
        .attr('height', Math.abs(this.scales.rssi_gprs(limit_max) - this.scales.rssi_gprs(limit_min)))
        .attr('width', this.box_sizing.width - this.margins.left - this.margins.right);
    } else {
      this.grids.rssi_gprs.good.style('display', 'none');
    }

    // Update RSSI background (low [-80;-90])
    if (
      this.has_data &&
      (inRange(minimal_domain_value, -80, -90) ||
        inRange(maximal_domain_value, -80, -90) ||
        inRange(-80, maximal_domain_value, minimal_domain_value))
    ) {
      const limit_max = minimal_domain_value <= -90 ? -90 : minimal_domain_value;
      const limit_min = maximal_domain_value <= -80 ? maximal_domain_value : -80;

      this.grids.rssi_gprs.low
        .style('display', 'initial')
        .attr('class', 'd3-grids-container__gprs-rssi-low')
        .attr('y', this.scales.rssi_gprs(limit_min) - this.box_sizing.height + this.margins.top + this.margins.bottom)
        .attr('fill', this.colors.rssi_gprs_low)
        .attr('height', this.scales.rssi_gprs(limit_max) - this.scales.rssi_gprs(limit_min))
        .attr('width', this.box_sizing.width - this.margins.left - this.margins.right);
    } else {
      this.grids.rssi_gprs.low.style('display', 'none');
    }

    // Update RSSI background (very_low [-90;-infinite])
    if (this.has_data && minimal_domain_value <= -90) {
      this.grids.rssi_gprs.very_low
        .style('display', 'initial')
        .attr('class', 'd3-grids-container__gprs-rssi-very-low')
        .attr('y', this.scales.rssi_gprs(-90) - this.box_sizing.height + this.margins.top + this.margins.bottom)
        .attr('fill', this.colors.rssi_gprs_very_low)
        .attr('height', Math.abs(this.scales.rssi_gprs(minimal_domain_value) - this.scales.rssi_gprs(-90)))
        .attr('width', this.box_sizing.width - this.margins.left - this.margins.right);
    } else {
      this.grids.rssi_gprs.very_low.style('display', 'none');
    }
  }

  // #endregion

  // #region -> (abstract methods)

  public build_event_objects(): void {
    // Build Y-focus line
    this.user_events.focus_line_y = this.create_focus_line('y', this.user_events.container);

    // Build tooltips
    this.user_events.rssi_gprs.container = this.create_html_tooltip(this.svg_parent);

    this.data_objects.rssi_gprs.line_not_nil.path = this.data_objects.container
      .append('path')
      .attr('id', 'data__line')
      .style('fill', 'none')
      .style('stroke', this.colors.rssi_gprs)
      .style('stroke-width', '2px');

    // this.data_objects.rssi_gprs.line_nil.path = this.data_objects.container
    //   .append('path')
    //   .attr('id', 'no-data__line')
    //   .style('fill', 'none')
    //   .style('stroke', this.colors.rssi_gprs + '26')
    //   .style('stroke-width', '2px');

    // Create event circles for each hive
    this.data_objects.rssi_gprs.circle = this.user_events.container
      .append('circle')
      .attr('r', 4)
      .style('display', 'none')
      .attr('pointer-events', 'none')
      .attr('class', 'd3-focus-circle')
      .attr('fill', this.colors.rssi_gprs);
  }

  /** */
  public on_mouse_enter(is_from_shared_cursor = false): void {
    super.on_mouse_enter(is_from_shared_cursor, false);

    if (!this.has_data) {
      return;
    }

    this.user_events.rssi_gprs.container.style('display', null);
    this.data_objects.rssi_gprs.circle.style('display', null);
  }

  /** */
  public on_mouse_move(event: MouseEvent | Date, data: DeviceGPRSSparklineData, is_from_shared_cursor = false): void {
    if (!this.has_data) {
      return null;
    }

    let pointed_position_x: number = null;
    let pointed_position_y: number = null;

    if (is_from_shared_cursor) {
      pointed_position_x = this.scales.time(event as Date);
    } else {
      const source_element = (event as MouseEvent).target as SVGGElement;
      const boundings = source_element.getBoundingClientRect();

      pointed_position_x = (event as MouseEvent).clientX - boundings.left;
      pointed_position_y = (event as MouseEvent).clientY - boundings.top;
    }

    const points = data?.gprs_levels;

    const index = d3.bisectCenter(
      points?.map(v => v.tz_date),
      this.scales.time.invert(pointed_position_x)
    );

    const closest_point = points?.[index] ?? null;

    // if (!is_from_shared_cursor) {
    //   this._shared_cursor.shared_cursor = {
    //     date: closest_point?.date ?? null,
    //     from: this.unique_id,
    //     event_type: 'mousemove',
    //   };
    // }

    let template = this.create_tooltip_header(closest_point.tz_date);

    // Create list item (voltage)
    template += `<div class="d3-chart-tooltip-list-item">`;

    template += '<div class="d3-chart-tooltip-series-name">';
    template += `<span class="mdi mdi-minus-thick" style="color:${this.colors.rssi_gprs}"></span>`;
    template += `${this._appState.translate.instant(i18n<string>('ALL.DATA.LABELS_FULL.Cellular network level'))}`;
    template += '</div>';

    template += '<div class="d3-chart-tooltip-value">';
    template += `${closest_point?.rssi_gprs?.toFixed(1) ?? '?'} ${this._appState.translate.instant(i18n<string>('ALL.DATA.UNITS.dB'))}`;
    template += '</div>';

    template += '</div>';

    template += '</div>';

    // Update tooltip position
    const is_in_first_half = pointed_position_x < this.user_events.event_bounds.node().width.baseVal.value / 2;
    if (is_in_first_half) {
      this.user_events.rssi_gprs.container
        .html(template)
        .style('left', `${this.calc_size_of(pointed_position_x + 10, ['+left'])}px`)
        .style('top', `${this.user_events.event_bounds.node().getBBox().height / 2}px`);
    } else {
      this.user_events.rssi_gprs.container
        .html(template)
        .style('left', `${pointed_position_x + this.margins.left - 1 - this.user_events.rssi_gprs.container.node().clientWidth - 10}px`)
        .style('top', `${this.user_events.event_bounds.node().getBBox().height / 2}px`);
    }

    // Update the focus_line_x position
    this.user_events.focus_line_y
      .attr('x1', this.scales.time(this.scales.time.invert(pointed_position_x)))
      .attr('x2', this.scales.time(this.scales.time.invert(pointed_position_x)));

    // Update circle positions (depending on date)
    if (isNil(closest_point?.tz_date)) {
      this.data_objects.rssi_gprs.circle.style('display', 'none');
    }

    // Update vbat circle
    if (isNil(closest_point?.rssi_gprs)) {
      this.data_objects.rssi_gprs.circle.style('display', 'none');
    } else {
      this.data_objects.rssi_gprs.circle.style('display', null);
      this.data_objects.rssi_gprs.circle.attr(
        'transform',
        `translate(${this.scales.time(closest_point.tz_date.getTime())},${this.scales.rssi_gprs(closest_point.rssi_gprs)})`
      );
    }
  }

  /** */
  public on_mouse_out(is_from_shared_cursor = false): void {
    super.on_mouse_out(is_from_shared_cursor);

    this.user_events.rssi_gprs.container.style('display', 'none');

    this.data_objects.rssi_gprs.circle.style('display', 'none');
  }

  /** */
  public append_data(is_after_resize: boolean): void {
    this.scales.time.domain([this.date_range.start, this.date_range.end]);

    this.data_objects.rssi_gprs.line_not_nil.path.attr('d', '');
    // this.data_objects.rssi_gprs.line_nil.path.attr('d', '');

    if (!this.has_data) {
      this.scales.rssi_gprs.domain([-90, -70]);
      super.append_data(is_after_resize);
      return;
    }

    const not_nil_points: GPRSLevelDataPoint[] = this.incoming_data?.gprs_levels?.filter(point => !isNil(point));

    const min_value = min(not_nil_points.map(point => point.rssi_gprs)) - 2.5;
    const max_value = max(not_nil_points.map(point => point.rssi_gprs)) + 2.5;

    this.scales.rssi_gprs.domain([min([min_value, -100]), max([max_value, -60])]);

    this.data_objects.rssi_gprs.line_not_nil.shape
      .x(_point => this.scales.time(_point.tz_date))
      .y(_point => this.scales.rssi_gprs(_point.rssi_gprs));
    this.data_objects.rssi_gprs.line_not_nil.path.attr('d', this.data_objects.rssi_gprs.line_not_nil.shape(not_nil_points));

    // Update line
    // this.data_objects.rssi_gprs.line_nil.shape
    //   .x(_point => this.scales.time(_point.tz_date))
    //   .y(_point => this.scales.rssi_gprs(_point.rssi_gprs));
    // this.data_objects.rssi_gprs.line_nil.path.attr(
    //   'd',
    //   this.data_objects.rssi_gprs.line_nil.shape(points_from_last_battery_change.filter(point => !isNil(point?.vbat)))
    // );

    super.append_data(is_after_resize);
  }

  // #endregion

  // #region -> (external chart events)

  protected on_language_updated(): void {
    super.on_language_updated();
  }

  // #endregion
}
