import { IOneOf } from 'ngx-schema-form';

import { isNil } from 'lodash-es';

import { ZohoDeskTicketStatusType } from './zoho-desk-ticket-status-type.enum';

/** */
export enum ZohoDeskTicketStatus {
  /** */
  OUVERT = 'Ouvert',

  /** */
  DEMANDE_SUPPORT = 'Demande support',

  /** */
  EN_COURS_ANALYSE_DISTANCE = 'En cours analyse - Distance',

  /** */
  A_RECONTACTER = 'A recontacter',

  /** */
  ATTENTE_VALIDATION_CLIENT = 'Attente validation client',

  /** */
  ATTENTE_RETOUR_USINE = 'Attente retour usine',

  /** */
  ATTENTE_RETOUR_STOCK = 'Attente retour stock(loc/remp/prêt)',

  /** */
  RETOUR_USINE_RECU = 'Retour usine reçu',

  /** */
  EN_COURS_ANALYSE_USINE = 'En cours analyse - Usine',

  /** */
  DEMANDE_VALIDATION_ANALYSE = 'Demande validation analyse',

  /** */
  EN_COURS_DE_REPARATION = 'En cours réparation',

  /** */
  DEMANDE_VALIDATION_REPARATION = 'Demande validation réparation',

  /** */
  A_ENLEVER = 'A enlever',

  /** */
  A_EXPEDIER = 'À expédier',

  /** */
  EN_SURVEILLANCE = 'En surveillance',

  /** */
  CLOTURE = 'Cloturé',

  // /** */
  // A_RECONTACTER_SUPPORT = 'A recontacter - Support',

  // /** */
  // A_RECONTACTER_POUR_RU = 'À recontacter pour RU',

  // /** */
  // A_RECONTACTER_DEVIS = 'A recontacter - Devis',

  // /** */
  // PRET_EXPEDITION = 'Prêt Expédition',

  // /** */
  // EXPEDIER_ATTENTE_VERIFICATION_CLIENT = 'Expédié - Attente vérification Client',

  // /** */
  // DOUBLON_SUIVI_SUR_AUTRE_TICKET = 'Doublon/suivit sur autre ticket',
}

export const zoho_desk_ticket_status_oneof = (exclusion_list: ZohoDeskTicketStatus[]): IOneOf[] =>
  Object.values(ZohoDeskTicketStatus)
    .map(ticket_status => {
      const oneof: IOneOf = <IOneOf>{};

      // Exclude some properties
      if (exclusion_list.includes(ticket_status)) {
        return null;
      }

      oneof.enum = [ticket_status];
      oneof.label = ticket_status;
      oneof['status_type'] = ZohoDeskTicketStatusType.ON_HOLD.toLowerCase().replace(' ', '-');

      switch (ticket_status) {
        case ZohoDeskTicketStatus.OUVERT:
        case ZohoDeskTicketStatus.A_RECONTACTER:
        case ZohoDeskTicketStatus.DEMANDE_SUPPORT:
        case ZohoDeskTicketStatus.RETOUR_USINE_RECU:
        case ZohoDeskTicketStatus.EN_COURS_ANALYSE_DISTANCE:

        // case ZohoDeskTicketStatus.A_RECONTACTER_SUPPORT:
        // case ZohoDeskTicketStatus.A_RECONTACTER_DEVIS:
        case ZohoDeskTicketStatus.EN_SURVEILLANCE: {
          oneof['group_name'] = ZohoDeskTicketStatusType.OPEN;
          oneof.status_type = ZohoDeskTicketStatusType.OPEN.toLowerCase().replace(' ', '-');
          break;
        }

        case ZohoDeskTicketStatus.A_ENLEVER:
        case ZohoDeskTicketStatus.A_EXPEDIER:
        // case ZohoDeskTicketStatus.PRET_EXPEDITION:
        case ZohoDeskTicketStatus.ATTENTE_RETOUR_STOCK:
        case ZohoDeskTicketStatus.ATTENTE_RETOUR_USINE:
        // case ZohoDeskTicketStatus.A_RECONTACTER_POUR_RU:
        case ZohoDeskTicketStatus.EN_COURS_ANALYSE_USINE:
        case ZohoDeskTicketStatus.EN_COURS_DE_REPARATION:
        case ZohoDeskTicketStatus.DEMANDE_VALIDATION_ANALYSE:
        case ZohoDeskTicketStatus.DEMANDE_VALIDATION_REPARATION:
        // case ZohoDeskTicketStatus.EXPEDIER_ATTENTE_VERIFICATION_CLIENT:
        case ZohoDeskTicketStatus.ATTENTE_VALIDATION_CLIENT: {
          oneof['group_name'] = ZohoDeskTicketStatusType.ON_HOLD;
          oneof.status_type = ZohoDeskTicketStatusType.ON_HOLD.toLowerCase().replace(' ', '-');
          break;
        }

        // case ZohoDeskTicketStatus.DOUBLON_SUIVI_SUR_AUTRE_TICKET:
        case ZohoDeskTicketStatus.CLOTURE: {
          oneof['group_name'] = ZohoDeskTicketStatusType.CLOSED;
          oneof.status_type = ZohoDeskTicketStatusType.CLOSED.toLowerCase().replace(' ', '-');
          break;
        }

        default:
          break;
      }

      return oneof;
    })
    .filter(oneof => !isNil(oneof));
