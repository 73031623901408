import { AfterViewInit, ChangeDetectionStrategy, Component, Input, NgZone, OnDestroy } from '@angular/core';

import { AppStateService } from 'app/core/app-state.service';
import { ConsoleLoggerService } from 'app/core/console-logger.service';
import { D3SharedCursorService } from 'app/core/services/global/d3-shared-cursor.service';

import { AnyOfDeviceJobData, RGJobData, WeatherData, WindDataPoint } from 'app/models/data';
import { ResizedEvent } from 'app/misc/directives/resized/resized.directive';

import { RGJobDataWindD3ChartFactory } from './rg-job-data-wind-d3-chart-factory';
import { RGJobDataIconsD3ChartFactory } from './rg-job-data-icons-d3-chart-factory';
import { RGJobDataWeatherD3ChartFactory } from './rg-job-data-weather-d3-chart-factory';
import { ChartGeoposition } from '@bg2app/models/charts';
import { DateTime } from 'luxon';

@Component({
  selector: 'bg2-rg-job-data-d3-chart',
  templateUrl: './rg-job-data-d3-chart.component.html',
  styleUrls: ['./rg-job-data-d3-chart.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RGJobDataD3ChartComponent implements AfterViewInit, OnDestroy {
  // #region -> (component basics)

  /** */
  private readonly _logger = new ConsoleLoggerService('RGJobDataD3ChartComponent', true);

  constructor(private _d3_shared_cursor: D3SharedCursorService, private _appState: AppStateService, private _ngZone: NgZone) {
    this.wind_chart_factory = new RGJobDataWindD3ChartFactory(this._d3_shared_cursor, this._appState, this._ngZone);
    this.icons_chart_factory = new RGJobDataIconsD3ChartFactory(this._d3_shared_cursor, this._appState, this._ngZone);
    this.weather_chart_factory = new RGJobDataWeatherD3ChartFactory(this._d3_shared_cursor, this._appState, this._ngZone);
  }

  ngAfterViewInit(): void {
    this.icons_chart_factory?.create_chart();
    this.wind_chart_factory?.create_chart();
    this.weather_chart_factory?.create_chart();
  }

  ngOnDestroy(): void {
    this.icons_chart_factory?.destroy();
    this.wind_chart_factory?.destroy();
    this.weather_chart_factory?.destroy();
  }

  // #endregion

  /** */
  @Input()
  public set is_loading(is_loading: { icons: boolean; wind: boolean; weather: boolean }) {
    this.wind_chart_factory.is_loading = is_loading?.wind ?? false;
    this.icons_chart_factory.is_loading = is_loading?.icons ?? false;
    this.weather_chart_factory.is_loading = is_loading?.weather ?? false;
  }

  /** */
  @Input()
  public set date_range(date_range: { start: DateTime; end: DateTime }) {
    this.wind_chart_factory.date_range = date_range;
    this.icons_chart_factory.date_range = date_range;
    this.weather_chart_factory.date_range = date_range;
  }

  /** */
  @Input()
  public set geoposition(geoposition: ChartGeoposition) {
    this.wind_chart_factory.geoposition.value = geoposition;
    this.icons_chart_factory.geoposition.value = geoposition;
    this.weather_chart_factory.geoposition.value = geoposition;
  }

  /** */
  public onChartContainerResized(resized_event: ResizedEvent, origin: 'icons' | 'wind' | 'weather'): void {
    if (origin === 'icons') {
      this.icons_chart_factory.box_sizing = { width: resized_event?.new_width, height: resized_event?.new_height };
    }

    if (origin === 'wind') {
      this.wind_chart_factory.box_sizing = { width: resized_event?.new_width, height: resized_event?.new_height };
    }

    if (origin === 'weather') {
      this.weather_chart_factory.box_sizing = { width: resized_event?.new_width, height: resized_event?.new_height };
    }
  }

  // #region -> (icons chart)

  /** */
  public icons_chart_factory: RGJobDataIconsD3ChartFactory = null;

  /** */
  @Input()
  public set icons_data(data: AnyOfDeviceJobData) {
    this.icons_chart_factory.incoming_data = <RGJobData>data;
  }

  // #endregion

  // #region -> (wind chart)

  /** */
  public wind_chart_factory: RGJobDataWindD3ChartFactory = null;

  /** */
  @Input()
  public set wind_data(data: WindDataPoint[]) {
    this.wind_chart_factory.incoming_data = data;
  }

  // #endregion

  // #region -> (weather chart)

  /** */
  public weather_chart_factory: RGJobDataWeatherD3ChartFactory = null;

  /** */
  @Input()
  public set weather_data(data: AnyOfDeviceJobData) {
    this.weather_chart_factory.incoming_data = <RGJobData>data;
  }

  // #endregion
}
