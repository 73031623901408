<div class="carousel">
  <div class="carousel-viewport" (swipeleft)="loadNext()" (swiperight)="loadPrevious()">
    <div
      class="carousel-slider"
      [style.transform]="carousel_translate_x$$ | async"
      [style.transition]="(transition$$ | async) ? 'all 1s' : 'none'"
      #slider
    >
      <ng-container #container></ng-container>
    </div>
  </div>

  <div class="carousel-navigation">
    <ng-container *ngIf="previous_slide_dates$$ | async; let dates; else: emptyDate">
      <div class="previous" (click)="loadPrevious()">
        <span class="mdi mdi-arrow-left"></span>
        <span>
          {{
            formatLabel$$(
              dates?.start_date | formatDate: (formatters$$ | async)?.date,
              dates?.end_date | formatDate: (formatters$$ | async)?.date
            ) | async
          }}
        </span>
      </div>
    </ng-container>

    <ng-container *ngIf="next_slide_dates$$ | async; let dates; else: emptyDate">
      <div class="next" (click)="loadNext()">
        <span>
          {{
            formatLabel$$(
              dates?.start_date | formatDate: (formatters$$ | async)?.date,
              dates?.end_date | formatDate: (formatters$$ | async)?.date
            ) | async
          }}
        </span>
        <span class="mdi mdi-arrow-right"></span>
      </div>
    </ng-container>
  </div>
</div>

<ng-template #emptyDate>
  <div></div>
</ng-template>
