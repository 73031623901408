/**
 * Abbreviate a number.
 * 
 * @param number_value The number value to abbreviate.
 * @param digits Show X digits (modifies value rounding). It's very important on large numbers.
 * 
 * @returns Returns the abbreviated number
 */
export const abbreviate_number = (number_value: number, digits: number = 0) => {
  const lookup = [
    {
      value: 1,
      symbol: '',
    },
    {
      value: 1e3,
      symbol: 'k', // Thousand
    },
    {
      value: 1e6,
      symbol: 'M', // Million
    },
    {
      value: 1e9,
      symbol: 'G', // Billion
    },
    {
      value: 1e12,
      symbol: 'T', // Trillion
    },
    {
      value: 1e15,
      symbol: 'P', // Quadrillion
    },
    {
      value: 1e18,
      symbol: 'E', // Quintillion
    },
    {
      value: 1e21,
      symbol: 'Z', // Sextillion
    },
    {
      value: 1e24,
      symbol: 'Y', // Septillion
    },
  ];

  var corresponding_lookup = lookup
    .slice()
    .reverse()
    .find(item => number_value >= item.value);

  return corresponding_lookup
    ? (number_value / corresponding_lookup.value).toFixed(digits).replace(/\.0+$|(\.[0-9]*[1-9])0+$/, '$1') + corresponding_lookup.symbol
    : '0';
};