import { findIndex } from 'lodash-es';
import { isEqual, startOfDay } from 'date-fns/esm';

import { DataPoint } from '@bg2app/models/data';
import { utcToZonedTime } from 'date-fns-tz';

/** */
export const group_data_by_day = <T extends DataPoint>(objects: T[]) =>
  objects.reduce((reduced: { date_of_day: Date; values: T[] }[], current) => {
    let start_of_day = startOfDay(utcToZonedTime(current.date, current.timezone));

    const found_index = findIndex(reduced, item => isEqual(item.date_of_day, start_of_day));

    if (found_index >= 0) {
      reduced[found_index].values.push(current);
    } else {
      reduced.push({ date_of_day: start_of_day, values: [current] });
    }

    return reduced;
  }, []);
