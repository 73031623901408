export const turf_feature = <G = any, P = any>(geom: G, properties?: P, options: { bbox?: any; id?: any } = {}): any => {
  const feat: any = { type: 'Feature' };
  if (options.id === 0 || options.id) {
    feat.id = options.id;
  }
  if (options.bbox) {
    feat.bbox = options.bbox;
  }
  feat.properties = properties || {};
  feat.geometry = geom;
  return feat;
};