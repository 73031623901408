import { marker as i18n } from '@biesbjerg/ngx-translate-extract-marker';

export enum DATA_VIEW {
  COUNTER = 'counter',
  AUTOMATIC = 'automatic',
  WEIGHT_LINEAR = 'weight_linear',
  WEIGHT_DIFFERENTIAL = 'weight_diff',
  TEMPERATURE_INTERNAL_LINEAR = 'temp_internal_linear',
}
/**
 * Constant of internship translations.
 */
export const data_view_i18n: { [key in DATA_VIEW]: string } = {
  counter: i18n<string>('ALL.DATA.LABELS_FULL.BeeLive data'),
  weight_linear: i18n<string>('ALL.DATA.LABELS_FULL.Weight (linear)'),
  weight_diff: i18n<string>('ALL.DATA.LABELS_FULL.Weight (differential)'),
  temp_internal_linear: i18n<string>('ALL.DATA.LABELS_FULL.Internal temperature'),
  automatic: i18n<string>('MODELS.CONFIGURATION.ENUMERATORS.APIARY_COMPACT_CHART_VIEW_TYPE.Automatic'),
};
