import { DOCUMENT } from '@angular/common';
import { Input, Inject, OnInit, Component, OnDestroy, Renderer2, AfterViewInit, ChangeDetectionStrategy } from '@angular/core';

import { merge } from 'lodash-es';

import { endOfToday, endOfYear, startOfDay, startOfYear, subSeconds } from 'date-fns/esm';

import { Apiary, CPTDevice, Exploitation } from 'app/models';

import { BeelivePhotosCarouselGroupComponent } from './beelive-photos-carousel-group.component';
import { TimeBasedCarouselContainerComponent } from 'app/widgets/widgets-reusables/carousels/time-based-carousel/time-carousel-container/time-carousel-container.component';

@Component({
  selector: 'bg2-beelive-photos-carousel',
  templateUrl:
    '../../../../../widgets/widgets-reusables/carousels/time-based-carousel/time-carousel-container/time-carousel-container.component.html',
  styleUrls: [
    '../../../../../widgets/widgets-reusables/carousels/time-based-carousel/time-carousel-container/time-carousel-container.component.scss',
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BeeLivePhotosCarouselComponent extends TimeBasedCarouselContainerComponent implements OnInit, AfterViewInit, OnDestroy {
  // #region -> (component dynamic data)

  @Input()
  public set device(device: CPTDevice) {
    this.dynamic_data = merge({}, this.dynamic_data, { device });
  }

  // #endregion

  // #region -> (component basics)

  constructor(protected _renderer: Renderer2, @Inject(DOCUMENT) protected document: Document) {
    super(_renderer, document);
    this._logger.prefix = 'BeeLivePhotosCarouselComponent';
    this.type_of_card_group_component = BeelivePhotosCarouselGroupComponent;
  }

  ngOnInit(): void {
    this.formatters = { date: 'dd LLL yyyy', label: '${dateStart}' };

    this.date_computing.compute_start_date = (end_date: Date): Date => startOfDay(end_date);
    this.date_computing.compute_previous_range = () => {
      this._end_date = subSeconds(this._start_date, 1);
      this._start_date = this.date_computing.compute_start_date(this._end_date);

      this.new_card_group_input_data = {
        start_date: this._start_date,
        end_date: this._end_date,
      };
    };

    this._end_date = endOfToday();
    this._start_date = this.date_computing.compute_start_date(this._end_date);

    super.ngOnInit();
  }

  ngAfterViewInit(): void {
    super.ngAfterViewInit();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  // #endregion
}
