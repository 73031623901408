<div [id]="widget_id" class="bg2-zoho-search-desk-widget-component" *ngIf="visible$$ | async">
  <form-element-header [schema]="schema" [showLabel]="false" [title_style]="options.title_style"></form-element-header>

  <div
    *ngIf="fullscreen_select_helper?.is_open_in_fullscreen.value$$ | async"
    id="fulscreen-select-blocker"
    (click)="fullscreen_select_helper.close(true)"
  ></div>

  <div>
    <img src="/assets/picons/zoho/zoho-desk-logo.svg" alt="" />

    <mat-form-field [appearance]="'outline'" [class.fullscreen]="fullscreen_select_helper?.is_open_in_fullscreen.value$$ | async">
      <mat-label>{{ schema.label | translate }}</mat-label>

      <ng-template #offlineField>
        <input matInput type="text" [value]="value$$ | async" [disabled]="true" [required]="schema?.isRequired" />
      </ng-template>

      <ng-container *ngIf="is_authenticated$$ | async; else offlineField">
        <mtx-select
          #select
          class="bg2-zoho-search-desk-widget-component__select"
          [bindValue]="'id'"
          [searchable]="true"
          [items]="users$$ | async"
          [typeahead]="raw_filter$$"
          [disabled]="schema?.readOnly"
          [dropdownPosition]="'bottom'"
          [required]="schema?.isRequired"
          [loading]="is_loading$$ | async"
          [ngModel]="selected_value_id$$ | async"
          [placeholder]="placeholder | translate"
          [appendTo]="'div#' + widget_id + ' mat-form-field .mat-form-field-wrapper'"
          (ngModelChange)="value = $event"
          (open)="fullscreen_select_helper.open()"
          (close)="fullscreen_select_helper.close()"
        >
          <!-- Template for header -->
          <ng-template ng-header-tmp>
            <div class="ng-option disabled">
              <span>{{ current_loaded_sentence$$ | async }}</span>
            </div>
          </ng-template>

          <!-- Template for loading items  -->
          <ng-template ng-loadingtext-tmp let-searchTerm="searchTerm">
            <div class="ng-option disabled">
              <ng-container *ngIf="searchTerm; else searchWithoutTerm">
                <span class="mdi mdi-loading mdi-spin" style="margin-right: 5px"></span>

                <span *ngIf="options?.zoho_search_config?.search_in === null">
                  {{
                    'WIDGETS.EVENT_FORM.ZOHO_SEARCH.SEARCH.Searching objects with "[search_term]"' | translate : { search_term: searchTerm }
                  }}
                </span>

                <span *ngIf="options?.zoho_search_config?.search_in === 'tickets'">
                  {{
                    'WIDGETS.EVENT_FORM.ZOHO_SEARCH.SEARCH.Searching tickets with "[search_term]"' | translate : { search_term: searchTerm }
                  }}
                </span>
              </ng-container>

              <ng-template #searchWithoutTerm>
                <span class="mdi mdi-loading mdi-spin" style="margin-right: 5px"></span>
                <span>{{ 'ALL.COMMON.Searching items' | translate }}...</span>
              </ng-template>
            </div>
          </ng-template>

          <!-- Template for not found item -->
          <ng-template ng-notfound-tmp let-searchTerm="searchTerm">
            <div class="ng-option disabled">
              <span *ngIf="options?.zoho_search_config?.search_in === null">
                {{ 'WIDGETS.EVENT_FORM.ZOHO_SEARCH.NOT_FOUND.No data found' | translate }}
              </span>

              <span *ngIf="options?.zoho_search_config?.search_in === 'tickets'">
                {{ 'WIDGETS.EVENT_FORM.ZOHO_SEARCH.NOT_FOUND.No tickets found' | translate }}
              </span>

              <span *ngIf="options?.zoho_search_config?.search_in === 'contacts'">
                {{ 'WIDGETS.EVENT_FORM.ZOHO_SEARCH.NOT_FOUND.No contacts found' | translate }}
              </span>
            </div>
          </ng-template>

          <!-- Template for label item -->
          <ng-template ng-label-tmp let-object="item">
            <ng-container *ngIf="object">
              <span *ngIf="options?.zoho_search_config?.search_in === 'tickets'">Ticket #{{ object?.ticketNumber }}</span>

              <span *ngIf="options?.zoho_search_config?.search_in === 'contacts'">
                {{ object?.firstName }} {{ object?.lastName }} [#{{ object?.id }}]
              </span>

              <span *ngIf="options?.zoho_search_config?.search_in === 'agents'"> {{ object?.name }} [#{{ object?.id }}] </span>
            </ng-container>
          </ng-template>

          <!-- Template for each item inside the dropdown -->
          <ng-template ng-option-tmp let-object="item" let-index="index" let-search="searchTerm">
            <div *ngIf="object" class="ng-option-template template-for-{{ options?.zoho_search_config?.search_in ?? 'default' }}">
              <bg2-zoho-ticket-summary
                *ngIf="options?.zoho_search_config?.search_in === 'tickets'"
                [ticket]="object"
              ></bg2-zoho-ticket-summary>

              <ng-container *ngIf="options?.zoho_search_config?.search_in === 'contacts'">
                <ng-container *ngLet="asType(object, 'contacts'); let contact">
                  <div class="contact-base-infos-container">
                    <!-- <img src="" alt="" /> -->

                    <div>
                      <span class="contact-name" [ngOptionHighlight]="search">{{ contact.full_name$$ | async }}</span>
                      <span class="contact-imei">[#{{ contact.id$$ | async }}]</span>
                    </div>
                  </div>

                  <div class="contact-mail">
                    <span class="mdi mdi-email"></span>
                    <ng-container *ngLet="contact.email$$ | async; let email">
                      <span *ngIf="email" [ngOptionHighlight]="search">{{ email }}</span>
                      <span *ngIf="!email">--</span>
                    </ng-container>
                  </div>

                  <div class="contact-account">
                    <span class="mdi mdi-factory"></span>
                    <ng-container *ngLet="contact.account$$ | async; let account">
                      <span *ngIf="account?.accountName" [ngOptionHighlight]="search">{{ account.accountName }}</span>
                      <span *ngIf="!account?.accountName">--</span>
                    </ng-container>
                  </div>

                  <div *ngIf="(contact.has_related_zoho_crm_contact$$ | async) === false" class="crm-link-state">
                    <span class="mdi mdi-alert"></span>
                    <span>
                      {{
                        'WIDGETS.EVENT_FORM.ZOHO_SEARCH.ERROR.Selection not recommanded, because no CRM contact is related to this DESK contact'
                          | translate
                      }}
                    </span>
                  </div>
                </ng-container>
              </ng-container>

              <ng-container *ngIf="options?.zoho_search_config?.search_in === 'agents'">
                <ng-container *ngLet="asType(object, 'agents'); let agent">
                  <img [src]="agent?.photoURL ?? 'assets/img/misc/persona/user-normal.png'" />
                  <span>{{ object?.name }}</span>
                </ng-container>
              </ng-container>
            </div>
          </ng-template>
        </mtx-select>
      </ng-container>
    </mat-form-field>

    <ng-container *ngLet="is_authenticated$$ | async; let is_authenticated">
      <bg2-button
        [type]="'action'"
        [options]="{ is_thin: true }"
        [color]="is_authenticated ? 'green' : 'warn'"
        [icon]="is_authenticated ? 'cs-/picons/api-link-on.svg' : 'cs-/picons/api-link-off.svg'"
        [popper]="(is_authenticated ? 'MODELS.ZOHO.AUTH.Connected' : 'MODELS.ZOHO.AUTH.Not connected') | translate"
        (btn_click)="is_authenticated ? null : login()"
      ></bg2-button>
    </ng-container>

    <ng-container *ngIf="(has_search_config$$ | async) && !schema?.readOnly">
      <bg2-button [type]="'action'" [icon]="'mdi-cog'" [options]="{ is_thin: true }" [matMenuTriggerFor]="configureSearch"></bg2-button>
    </ng-container>

    <mat-menu #configureSearch class="zoho-search-desk-widget__config-menu">
      <div mat-menu-item [disabled]="true">
        <span class="mat-menu-item__content --title">
          <span class="mdi mdi-cog mdi-24px"></span>
          <span>
            <ng-container *ngIf="options?.zoho_search_config?.search_in === 'contacts'">
              {{ 'WIDGETS.EVENT_FORM.ZOHO_SEARCH.CONFIG.Contacts search options' | translate }}
            </ng-container>

            <ng-container *ngIf="options?.zoho_search_config?.search_in === 'tickets'">
              {{ 'WIDGETS.EVENT_FORM.ZOHO_SEARCH.CONFIG.Tickets search options' | translate }}
            </ng-container>

            <ng-container *ngIf="options?.zoho_search_config?.search_in === 'agents'">
              {{ 'WIDGETS.EVENT_FORM.ZOHO_SEARCH.CONFIG.Agents search options' | translate }}
            </ng-container>
          </span>
        </span>
      </div>

      <ng-container *ngIf="has_search_contact_config$$ | async">
        <div mat-menu-item *ngLet="search_contact_config$$ | async; let search_contact_config">
          <span class="mat-menu-item__content" (click)="update_search_contact_config('limit_to_account_name')">
            <span *ngIf="search_contact_config?.limit_to_account_name" class="mdi mdi-check-bold mdi-18px"></span>
            <span *ngIf="!search_contact_config?.limit_to_account_name" class="mdi mdi-close-thick mdi-18px"></span>

            <span>{{ 'WIDGETS.EVENT_FORM.ZOHO_SEARCH.CONFIG.Limited to account name' | translate }}</span>
          </span>
        </div>
      </ng-container>

      <ng-container *ngIf="has_search_ticket_config$$ | async">
        <div mat-menu-item *ngLet="search_ticket_config$$ | async; let search_ticket_config">
          <span class="mat-menu-item__content" (click)="update_search_ticket_config('limit_to_account_id')">
            <span *ngIf="search_ticket_config?.limit_to_account_id" class="mdi mdi-check-bold mdi-18px"></span>
            <span *ngIf="!search_ticket_config?.limit_to_account_id" class="mdi mdi-close-thick mdi-18px"></span>

            <span>{{ 'WIDGETS.EVENT_FORM.ZOHO_SEARCH.CONFIG.Limited to account ID' | translate }}</span>
          </span>
        </div>
      </ng-container>
    </mat-menu>
  </div>

  <div class="bg2-zoho-search-desk-widget-component__infos">
    <ng-container *ngIf="(is_authenticated$$ | async) && options.zoho_search_config.search_in === 'tickets'">
      <ng-container *ngIf="asType(selected_value_object$$ | async, 'tickets'); let ticket">
        <span *ngIf="ticket?.web_url$$ | async; let url" class="infos__ticket-link">
          <span>{{ 'MODELS.ZOHO.DESK.TICKET.Ticket link' | translate | colon }}</span>
          <a [href]="url" target="_blank">#{{ ticket.ticket_number$$ | async }}</a>
        </span>
      </ng-container>
    </ng-container>
  </div>
</div>
