import { HttpClient } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { MatSnackBar, MatSnackBarRef } from '@angular/material/snack-bar';

import { interval, Subscription } from 'rxjs';

import { isEqual, isNil } from 'lodash-es';

import { environment } from 'environments/environment';

import { RunnersService } from 'app/core/runners/runners.service';
import { ConsoleLoggerService } from 'app/core/console-logger.service';

import { SnackbarListComponent } from './components/snackbar-list/snackbar-list.component';
import { AppUpdateSnackComponent } from '../misc-widgets/app_update_snack/app-update-snack.component';
import { switchMap } from 'rxjs';
import { Dictionary } from 'app/typings/core/interfaces';

@Injectable({ providedIn: 'root' })
export class SnackbarManagerService implements OnDestroy {
  private _logger = new ConsoleLoggerService('SnackbarManagerService', false);

  // #region -> (snackbar references)

  private _runners_snackbar_ref: MatSnackBarRef<SnackbarListComponent> = null;
  private _update_snackbar_ref: MatSnackBarRef<AppUpdateSnackComponent> = null;

  // #endregion

  // #region -> (service basics)

  private _runs_sub: Subscription = null;
  private _version_check_sub: Subscription = null;

  constructor(private _matSnackbar: MatSnackBar, private _runnersService: RunnersService, private _http: HttpClient) {
    this._runs_sub = this._runnersService.has_runners$$.subscribe({
      next: has_runners => {
        if (has_runners && isNil(this._runners_snackbar_ref)) {
          this._runners_snackbar_ref = this._matSnackbar.openFromComponent(SnackbarListComponent, {
            horizontalPosition: 'right',
            verticalPosition: 'bottom',
            panelClass: 'snackbar-runners-manager',
          });
        }

        if (!has_runners && !isNil(this._runners_snackbar_ref)) {
          this._runners_snackbar_ref.dismiss();
          this._runners_snackbar_ref = null;
        }
      },
    });
  }

  ngOnDestroy(): void {
    this._runners_snackbar_ref?.dismiss();
    this._runners_snackbar_ref = null;
    this?._runs_sub?.unsubscribe();

    this._update_snackbar_ref?.dismiss();
    this._update_snackbar_ref = null;
  }

  // #endregion
}
