import { Component, OnInit, OnDestroy, AfterViewInit, ChangeDetectionStrategy, HostListener, ViewChild, NgZone } from '@angular/core';
import { Observable, of, concat, OperatorFunction, Subscription, combineLatest, BehaviorSubject, merge, fromEvent } from 'rxjs';
import { map, filter, distinctUntilChanged, switchMap, tap, finalize, take, delay, debounce, debounceTime } from 'rxjs';

import { SchemaValidatorFactory } from 'ngx-schema-form';

import { TranslateService } from '@ngx-translate/core';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';

import {
  cloneDeep,
  includes,
  isArray,
  isNil,
  isUndefined,
  sortBy,
  uniq,
  uniqueId,
  concat as _concat,
  intersection,
  without,
  clone,
} from 'lodash-es';
import { subMinutes } from 'date-fns';

import { marker as i18n } from '@biesbjerg/ngx-translate-extract-marker';

import { AppStateService } from 'app/core/app-state.service';
import { Beeguard2Api } from 'app/core/api/main/beeguard2-api-service';

import { Entity, EntityDict } from 'app/models';
import { Hive, Apiary, Warehouse } from 'app/models';
import { pipeFromArray, replay, robustCombineLatest } from '@bg2app/tools/rxjs';

// TODO move that helper somewhere else ?
import { parseDate } from 'app/misc/tools';
import { Dictionary } from 'app/typings/core/interfaces';
import { distinctUntilRealChanged } from '@bg2app/tools/rxjs';

import { DialogsService } from 'app/widgets/dialogs-modals/dialogs.service';
import { DeviceApi } from 'app/core';

import { NewEntityModal, NewEntityModalParams } from 'app/routable-modals/new-entity/new-entity.modal';

import { buildEntityValidator, EventDateAndId } from '../eventforms.helpers';
import { EfSelectWidgetComponent, EfSelectOptions } from '../select/select.widget';
import { ConsoleLoggerService } from 'app/core/console-logger.service';
import { DeleteOrArchiveEntityAction } from 'app/views/entities/shared/button-delete-or-archive-entity/button-delete-or-archive-entity.component';
import { MtxSelectComponent } from '@ng-matero/extensions/select';
import { FullscreenSelectHelper } from '@bg2app/tools/misc';

interface SelectEntityDict extends EntityDict {
  id: number; // id of the entity
  type: string;
  static_state: any;
  state: Dictionary<any>;
  _entity: Entity; // Source entity

  // Select custom attributes
  label: string;
  select_id: number; // id for the select value
  other_data: any;
  group_by: any;
}

interface EfEntityWidgetOptions extends EfSelectOptions {
  createnew?: boolean;
  nullable?: boolean;
  hidden?: boolean;
  multiple?: boolean;
  _default?: string;
  hide_if_null?: boolean;
  readonly_if?: any;
  dynamic?: any;
  entity_validator?: any;
  with_in?: any;
  group_by?: any;
  event_date_path?: string;
  do_not_track_date_and_id?: boolean;
}

@AutoUnsubscribe()
@Component({
  selector: 'bg2-entity-widget',
  templateUrl: './entity.widget.html',
  styleUrls: ['./entity.widget.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class EfBg2EntityWidget extends EfSelectWidgetComponent implements OnDestroy, OnInit, AfterViewInit {
  private readonly _logger = new ConsoleLoggerService(this.constructor.name, false);
  public id = uniqueId();

  private _loading_state$$ = new BehaviorSubject<string[]>([]);
  public loading_state$$ = this._loading_state$$.asObservable();

  private _loading$$ = new BehaviorSubject<boolean>(true);
  public loading$$ = this._loading$$.asObservable().pipe(distinctUntilChanged(), replay());

  public any_loading$$ = combineLatest([this.loading$$, this._loading_state$$]).pipe(
    map(([loading, loading_states]) => loading && loading_states.length === 0),
    distinctUntilChanged(),
    replay()
  );

  private set loading(loading: boolean) {
    this._loading$$.next(loading);
  }

  private loading_state_push(val: string): void {
    const nloadings = _concat(this._loading_state$$.getValue(), [val]);
    this._loading_state$$.next(nloadings);
  }

  private loading_state_pop(): void {
    if (this._loading_state$$.closed) {
      return;
    }
    const nloadings = this._loading_state$$.getValue().slice(0, -1);
    this._loading_state$$.next(nloadings);
  }

  // public loading = true;
  // public loading_state: string[] = [];

  public etype: any = null;
  public new_args = {}; // Argument for link to create a new entity

  public args: Dictionary<any> = {};
  public entity_validation_schema: any = null;

  private _readonly = true;
  private _previous_readonly: any = null;
  private _previous_readonly_setted = false;

  protected readonly_change_sub: Subscription;
  private _readonly$$ = new BehaviorSubject<boolean>(true);
  public readonly$$ = this._readonly$$.asObservable().pipe(
    distinctUntilChanged(),
    tap(ro => {
      if (ro) {
        // !readonly =to=> readonly
        this._previous_readonly = cloneDeep(this.value);
        this._previous_readonly_setted = true;
      } else {
        // readonly =to=> !readonly
        if (this._previous_readonly_setted) {
          this.value = this._previous_readonly;
        }
      }
    }),
    replay()
  );

  set readonly(ro: boolean) {
    this._readonly = ro;
    this._readonly$$.next(this._readonly);
  }

  get readonly(): boolean {
    return this._readonly$$.getValue();
  }

  // TODO remove arg copy of this options

  public options: EfEntityWidgetOptions = {
    img: false,
    img_prefix: 'select/',
    items: {},
    indent: false,
    clearable: true,
    readonly: false,
    createnew: false,
    hidden: false,
    dynamic: null,
    readonly_if: null,
    nullable: false,
    hide_if_null: false,
    entity_validator: null,
    with_in: null,
    multiple: false,
    reset_btn: false,
    group_by: null,
    event_date_path: null,
    do_not_track_date_and_id: false,
  };

  private selected_may_be_missing = true;
  // ^ if true the selected value may be missing
  // i.e. it could be explicitly loaded

  private entities_dict: SelectEntityDict[] = [];
  public entities_dict$$: Observable<SelectEntityDict[]>;

  public selected_edict$$: Observable<SelectEntityDict[]>;

  protected entities_options_sub: Subscription;
  protected value_change_sub: Subscription;

  protected dynamic_value_sub: Subscription;

  protected event_date_and_id$$: Observable<EventDateAndId>;

  get placeholder(): string {
    // console.log(this.options.length);
    let _placeholder = this.schema.label;
    if (this.entities_dict.length === 0) {
      switch (this.etype) {
        case 'hive':
          // code...
          _placeholder = i18n('ENTITY.HIVE.ERRORS.No named hive');
          if (this.args.in_apiary) {
            _placeholder = i18n('ENTITY.HIVE.ERRORS.No named hive (in this apiary)');
          }
          break;

        default:
          _placeholder = i18n('ENTITY.ALL.ERROR.No valid entity founded');
          break;
      }
    }
    return _placeholder;
  }

  public show_data$$: Observable<boolean>;

  // #region -> (fullscreen widget)

  /** */
  public fullscreen_select_helper = new FullscreenSelectHelper(this._ng_zone);

  // #endregion

  /** */
  public readonly widget_id = uniqueId('widget-event-form-bg2entity-');

  /** */
  @ViewChild('select', { static: false })
  public select_component: MtxSelectComponent;

  constructor(
    protected dialogs: DialogsService,
    protected schemaValidatorFactory: SchemaValidatorFactory,
    protected bg2Api: Beeguard2Api,
    protected deviceApi: DeviceApi,
    public appState: AppStateService,
    protected translate: TranslateService,
    protected readonly _ng_zone: NgZone
  ) {
    super(bg2Api, translate, appState, dialogs, _ng_zone);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.etype = this.schema.etype;
    this.args = this.schema.args || {};

    this.entity_validation_schema = this.options.entity_validator;

    this.show_data$$ = this.value$$.pipe(
      map(value => {
        if (!this.options.nullable && !this.options.hide_if_null) {
          return true;
        } else if (isArray(value) && value.length === 0) {
          return false;
        } else if (isNil(value)) {
          return false;
        }
        return true;
      })
    );

    // Manage readonly_if
    const readonly_if = this.options.readonly_if;
    if (isNil(readonly_if)) {
      this.readonly = this.options.readonly || false;
    } else if (readonly_if.path && readonly_if.value) {
      // get property
      const property = this.robustSearchProperty(readonly_if.path);
      const needed_values = readonly_if.value;
      if (!property) {
        throw new Error(`Propety "${readonly_if.path}" not found`);
      }
      // bind
      this.readonly_change_sub?.unsubscribe();
      this.readonly_change_sub = property.valueChanges
        .pipe(
          map(value => {
            if (needed_values.indexOf('$ANY$') >= 0) {
              return value.length > 0;
            } else {
              return needed_values.indexOf(value) >= 0;
            }
          })
        )
        .subscribe((_readonly: boolean) => {
          // console.log(this.formProperty.path, ' _readonly', _readonly)
          this.readonly = _readonly;
          // Note: this store current selection and should raz to default value (if any)
          // Note: this reset previously selected selection
        });
    } else {
      this.readonly = false;
    }
  }

  ngOnDestroy(): void {
    this.fullscreen_select_helper.destroy();
    super.ngOnDestroy();
    // Note we use autounsubscribe to clear subscriptions
  }

  public groupBy(item: SelectEntityDict): string {
    return item.group_by;
  }

  public groupValue(group: string, children: any[]) {
    return group;
  }

  public clear(item: any): void {
    const values = this.formProperty.value;
    this.formProperty.setValue(without(values, item), false);
  }

  public selectAll(): void {
    const all = this.entities_dict.map(edict => edict.select_id);
    this.formProperty.setValue(all, false);
  }

  public unselectAll(): void {
    this.formProperty.setValue([], false);
  }

  private unsubscribe_entities_options(): void {
    this.entities_options_sub?.unsubscribe();
  }

  ngAfterViewInit(): void {
    super.ngAfterViewInit();
    let event_date_path = null;
    if (this.options.event_date_path) {
      event_date_path = this.options.event_date_path;
    }
    if (!this.options.do_not_track_date_and_id) {
      if (!isNil(event_date_path)) {
        this.event_date_and_id$$ = this.trackEventDateAndId(event_date_path);
      }
      if (isNil(this.event_date_and_id$$)) {
        const event_root_property = this.formProperty.parent.parent;
        // ^ XXX: this implies that all entities widget are in "event/apply_to/operand"
        if (event_root_property && event_root_property.schema && event_root_property.schema.options) {
          event_date_path = '../../' + event_root_property.schema.options.event_date_path;
          this.event_date_and_id$$ = this.trackEventDateAndId(event_date_path);
        }
      }
    }
    if (isNil(this.event_date_and_id$$)) {
      // console.log(this.formProperty.root.schema);
      if (!this.options.do_not_track_date_and_id) {
        this._logger.error(this.formProperty.path, 'Imposible to find date property...', event_date_path);
      }
      // Fail back
      this.event_date_and_id$$ = of({
        date: new Date(),
        event_id: null,
      });
    }

    // Bind to other properties if dynamic entity
    this.bindDynamicEntity();

    // Get initial entities list observable and pipe processing
    this.entities_dict$$ = this.requestEntities().pipe(
      map((entities: Entity[]) => {
        this._logger.debug(this.formProperty.path, 'get entities', entities);
        if (isNil(entities)) {
          return [] as SelectEntityDict[];
        } else {
          return entities.map(entity => {
            const edict = entity.asDict() as SelectEntityDict;
            edict._entity = entity;
            return edict;
          });
        }
      }),
      this.getEntitiesProcessing(),
      replay()
    );

    this.unsubscribe_entities_options();
    this.entities_options_sub = this.entities_dict$$.subscribe(entities_dict => {
      // console.log(this.formProperty.path, 'new entities', entities_dict);
      this.entities_dict = entities_dict;
      // Store the available entities in schema, this is a usefull hack to get it from other widgets
      // Note: this cause infinit loop in schema verification
      // this.formProperty.schema.entities_dict = entities_dict;
      // console.log('[debug] -> dict: ', entities_dict);
      this.loading = false;
    });

    this.selected_edict$$ = combineLatest([this.value$$, this.entities_dict$$]).pipe(
      map(([values, entities_dict]) => {
        if (isNil(values)) {
          values = [];
        } else if (!isArray(values)) {
          values = [values];
        }
        // Set selected_edict when readonly
        return entities_dict.filter(edict => includes(values, edict.select_id));
      })
    );
  }

  private bindDynamicEntity(): void {
    const dyn_conf = this.options.dynamic;
    // console.log(this.formProperty.path, dyn_conf);
    if (!dyn_conf) {
      return;
    }

    const source_property = this.robustSearchProperty(dyn_conf.from);

    if (!source_property) {
      this._logger.error(this.formProperty.path, `Source property not found for dynamic entity (source: ${dyn_conf.from}))`);
      return;
    }

    const dynamic_value$ = concat(of(source_property.value), source_property.valueChanges)
      .pipe(
        filter(eid => !isNil(eid)),
        distinctUntilChanged(),
        // tap(value => console.log(this.formProperty.path, '[DYN] new syn source value:', value)),
        tap(() => this.loading_state_push('dynamic')),
        switchMap(eid => this.bg2Api.getEntityObj(eid)),
        tap(() => {
          this.loading_state_pop();
          this.loading_state_push('dynamic_state');
        }),
        switchMap(entity =>
          this.event_date_and_id$$.pipe(map(event_date_id => ({ entity, date: event_date_id.date, event_id: event_date_id.event_id })))
        ),
        switchMap(res => {
          const entity: Entity = res?.entity;

          if (isNil(entity)) {
            return of(undefined);
          }

          return entity?.getAtDate(dyn_conf.path, res.date, res.event_id);
        })
      )
      .pipe(
        tap(() => this.loading_state_pop()),
        // tap(value => console.log(this.formProperty.path, '[DYN] new value:', value)),
        map(val => (isUndefined(val) ? null : val)),
        distinctUntilChanged()
      );

    this.dynamic_value_sub = dynamic_value$.subscribe(value => {
      // console.log(this.formProperty.path, `[DYN] update value: ${value}`);
      this.formProperty.setValue(value, false);
    });
  }

  protected getEntitiesProcessing(): OperatorFunction<SelectEntityDict[], SelectEntityDict[]> {
    // Create the pipeline
    const entity_dict_transform: OperatorFunction<SelectEntityDict[], SelectEntityDict[]>[] = [];

    // Get entities at event date
    entity_dict_transform.push(
      switchMap((edicts: EntityDict[]) => {
        // Store entities dict
        const edict_idx: Dictionary<any> = {};
        edicts.map(edict => {
          edict_idx[edict.id] = edict;
        });
        // Get entities state at a given date
        // console.log(`[${this.name}][test] -> stacking for `, edicts);
        this.loading_state_push('get_state');
        // console.log(`[${this.name}][test] -> status `, _.cloneDeep(this.loading_state));

        return this.event_date_and_id$$.pipe(
          // note: event_date_and_id$ only publish new different values
          // FIXME: retravailler loading_state
          finalize(() => {
            // console.log(`[${this.name}][test] -> test finalize `, this);
            this.loading_state_pop();
          }),
          switchMap(event_date_id => {
            // console.time('Load entities states.')
            if (edicts.length === 0 || this.etype === 'warehouse' || this.etype === 'exploitation') {
              // Warehouse and exploitation dynamic state is useless here !
              return of(null);
            }
            // else we load states
            const eids = edicts.map(edict => edict.id);
            return this.bg2Api.fetch_entities_states$(eids, event_date_id.date, event_date_id.event_id);
          }),
          map((_res_entities_states: any) => {
            // console.timeEnd('Load entities states.')
            if (isNil(_res_entities_states)) {
              return edicts;
            } else {
              const n_edicts: any[] = _res_entities_states.states.map((state_res: any): any => {
                const eid = state_res.id;
                const entity_dict = edict_idx[eid];
                // console.log(this.formProperty.path, entity_dict['id']);
                // console.log(this.formProperty.path, entity_dict['state'], state_res);
                if (!isNil(state_res.state)) {
                  entity_dict.state = state_res.state.state || {};
                } else {
                  entity_dict.state = {};
                }
                return entity_dict;
              });
              return n_edicts;
            }
          })
        );
      })
    );
    entity_dict_transform.push(
      tap(() => {
        // console.log(`[${this.name}][test] -> unstacking`);
        this.loading_state_pop();
        // console.log(`[${this.name}][test] -> status`, _.cloneDeep(this.loading_state));
      })
    );

    // Add select_id
    entity_dict_transform.push(
      map((edicts: EntityDict[]) => {
        const _edicts = edicts.map(edict => {
          const _edict = edict as SelectEntityDict;
          _edict.select_id = edict.id;
          if (this.etype === 'warehouse' && edict.type === 'exploitation') {
            _edict.select_id = edict.static_state.warehouse_id;
          }
          return _edict;
        });
        return _edicts;
      })
    );

    //
    // Specific transformation
    if (this.etype === 'warehouse') {
      entity_dict_transform.push(
        switchMap((edicts: SelectEntityDict[]) =>
          this.appState.usernames_by_ids$$.pipe(
            map((usernames_by_id: string[]) => {
              edicts.map(edict => {
                edict.other_data = edict.other_data || {};
                edict.other_data.owner_name = usernames_by_id[edict.user_id];
                return edict;
              });
              // console.log(edicts);
              return edicts;
            })
          )
        )
      );
    } else if (this.etype === 'apiary') {
      entity_dict_transform.push(
        switchMap((edicts: SelectEntityDict[]) => {
          const location_ids: number[] = uniq(edicts.map(edict => edict.state.location_id).filter(lid => !isNil(lid)));
          this._logger.debug(this.formProperty.path, 'location_ids', location_ids);
          return this.bg2Api.getEntitiesObj(location_ids, 'location').pipe(
            map(locations => {
              const locations_idx: Dictionary<any> = {};
              locations.map(location => {
                locations_idx[location.id] = location;
              });
              return locations_idx;
            }),
            map(locations_idx =>
              // console.log(locations_idx)
              edicts.map(edict => {
                const lid = (edict.state as any).location_id;
                const location = locations_idx[lid];
                edict.other_data = edict.other_data || {};
                if (!isNil(location)) {
                  edict.other_data.location_name = locations_idx[lid].name;
                } else {
                  edict.other_data.location_name = '?';
                }
                return edict;
              })
            )
          );
        })
        // tap(edict => this._logger.debug(this.formProperty.path, 'apiary edict', edict))
      );
    } else if (this.etype === 'location') {
      entity_dict_transform.push(
        switchMap((edicts: SelectEntityDict[]) => {
          const apiary_ids: number[] = uniq(edicts.map(edict => (edict.state as any).apiary_id).filter(lid => !isNil(lid)));
          this._logger.debug(this.formProperty.path, 'apiary_ids', apiary_ids);
          return this.bg2Api.getEntitiesObj(apiary_ids, 'apiary').pipe(
            map(apiaries => {
              const apiary_idx: Dictionary<any> = {};
              apiaries.map(apiary => {
                apiary_idx[apiary.id] = apiary;
              });
              return apiary_idx;
            }),
            map(apiary_idx =>
              // console.log(apiary_idx)
              edicts.map(edict => {
                const lid = (edict.state as any).apiary_id;
                const apiary = apiary_idx[lid];
                edict.other_data = edict.other_data || {};
                if (!isNil(apiary)) {
                  edict.other_data.apiary_name = apiary_idx[lid].name;
                } else {
                  edict.other_data.apiary_name = null;
                }
                return edict;
              })
            )
          );
        })
      );
    }

    // Store edicts
    entity_dict_transform.push(
      map(
        (entities_dict: SelectEntityDict[]) =>
          // console.log(entities_dict);
          entities_dict
      )
    );

    // Validate entity dict
    entity_dict_transform.push(
      map((entities_dict: SelectEntityDict[]) => {
        this._logger.debug(this.formProperty.path, 'filter', this.entity_validation_schema);
        const validator = buildEntityValidator(this.schemaValidatorFactory, this.entity_validation_schema);
        return entities_dict.filter(edict => !validator(edict));
      })
    );

    // Manage _default values
    entity_dict_transform.push(
      switchMap((entities_dict: SelectEntityDict[]) =>
        // console.log(entities_dict);
        // This retriger next (ie default computation when readonly changed !)
        concat(of(null), this.readonly$$).pipe(map(() => entities_dict))
      ),
      map((entities_dict: SelectEntityDict[]) => {
        // Entity type specific operations
        const etype = this.etype;
        let can_update_with_default = this.readonly;
        can_update_with_default = can_update_with_default || isNil(this.formProperty.value);
        // can_update_with_default = can_update_with_default || (this.options.multiple && this.formProperty.value.length === 0);
        if (!can_update_with_default) {
          // console.log(this.formProperty.path, 'One can not update value from default change');
          // console.log(this.formProperty.path, this.readonly, this.formProperty.value);
        } else if (this.options._default === '_first') {
          entities_dict.map((edict, e_num) => {
            // Specific treatment on some enitty type
            if (etype === 'exploitation') {
              //  *** EXPLOITATION ***
              const expl_id = this.appState.selected_exploitations.length > 0 ? this.appState.selected_exploitations[0].id : null;
              if (isNil(expl_id) && e_num === 0) {
                this.formProperty.setValue(edict.select_id, false);
              } else if (expl_id === edict.select_id) {
                this.formProperty.setValue(edict.select_id, false);
              }
            } else if (etype === 'warehouse') {
              //  *** WAREHOUSE ***
              const wh_id = this.appState.selected_exploitations.length > 0 ? this.appState.selected_exploitations[0].warehouse_id : null;
              if (isNil(wh_id) && e_num === 0) {
                this.formProperty.setValue(edict.select_id, false);
              } else if (wh_id === edict.select_id) {
                this.formProperty.setValue(edict.select_id, false);
              }
            }
          });
        } else if (this.options._default === '_all' && this.options.multiple) {
          // console.log(this.formProperty.path, '_default', entities_dict.map(edict => edict.select_id));
          this.formProperty.setValue(
            entities_dict.map(edict => edict.select_id),
            false
          );
        }
        return entities_dict;
      })
    );

    // manage group by
    if (this.options.group_by) {
      const group_by_type = this.options.group_by.type;
      const group_by_groups = this.options.group_by.groups;
      const add_group_by_operations: OperatorFunction<any, any>[] = [];
      if (group_by_type === 'intersection_size') {
        const group_by_self = this.options.group_by.self;
        const group_by_other = this.options.group_by.other;
        this._logger.debug(this.formProperty.path, 'group X - other_prop', group_by_other);
        const other_prop = this.robustSearchProperty(group_by_other.from);
        if (other_prop) {
          // console.log(this.formProperty.path, 'group V other_prop', other_prop)
          const other_value$ = concat(of(other_prop.value), other_prop.valueChanges).pipe(
            distinctUntilChanged(),
            switchMap((eid: number) => (!isNil(eid) ? this.bg2Api.getEntityObj(eid) : of({}))),
            switchMap((other_entity: Entity) => {
              const source = (other_entity as any)[group_by_other.path];
              this._logger.debug(this.formProperty.path, 'group other_entity', other_entity.id, group_by_other.path, source);
              if (source instanceof Observable) {
                return source.pipe(tap(val => this._logger.debug(this.formProperty.path, 'group other_entity', val)));
              } else {
                return of(source);
              }
            }),
            replay()
          );
          add_group_by_operations.push(
            switchMap((entities_dict: SelectEntityDict[]) => other_value$.pipe(map(other_value => [entities_dict, other_value]))),
            switchMap(([entities_dict, other_value]: [SelectEntityDict[], any]) => {
              // this._logger.debug(this.formProperty.path, 'grouping', entities_dict, other_value);
              const entities_with_groups = entities_dict.map(edict => {
                this._logger.debug(this.formProperty.path, 'group request val for', edict.id, group_by_self.path);
                let self_value$ = (edict._entity as any)[group_by_self.path] || null;
                if (!(self_value$ instanceof Observable)) {
                  self_value$ = of(self_value$);
                }
                return self_value$.pipe(
                  map((value: any) => {
                    this._logger.debug(this.formProperty.path, 'group get val for', edict.id, value, other_value);
                    if (isArray(value) && isArray(other_value)) {
                      const inter = intersection(value, other_value);
                      if (inter.length === 0) {
                        return group_by_groups.empty;
                      } else {
                        return group_by_groups.not_empty;
                      }
                    }
                    return null;
                  }),
                  map(group => {
                    this._logger.debug(this.formProperty.path, 'group itself', group);
                    edict.group_by = group;
                    return edict;
                  })
                );
              });
              this._logger.debug(this.formProperty.path, 'entities_with_groups.length', entities_with_groups.length);
              return robustCombineLatest(entities_with_groups);
            })
          );
        }
      }
      if (add_group_by_operations.length > 0) {
        entity_dict_transform.push(...add_group_by_operations);
      }
    }

    // Sort options list
    entity_dict_transform.push(
      map((entities_dict: SelectEntityDict[]) => {
        this._logger.debug(this.formProperty.path, 'sort', entities_dict);
        entities_dict = sortBy(entities_dict, edict => [edict.group_by?.order || 'ZZZZ', edict.static_state.name, edict.select_id]);
        return entities_dict;
      })
    );

    // Set default label
    entity_dict_transform.push(
      map((entities_dict: SelectEntityDict[]) => {
        // console.log(entities_dict);
        entities_dict.map(edict => {
          edict.label = edict.static_state.name;
          const data = [edict.label];
          if (this.etype === 'location') {
            if (!isNil(edict.other_data.apiary_name)) {
              data.push(edict.other_data.apiary_name);
            }
          } else if (this.etype === 'apiary') {
            if (!isNil(edict.other_data.location_name)) {
              data.push(edict.other_data.location_name);
            }
          } else if (this.etype === 'warehouse' || this.etype === 'exploitation') {
            if (!isNil(edict?.other_data?.owner_name)) {
              data.push(edict.other_data.owner_name);
            }
          }

          (edict as any)['data-search'] = data.join(' ');
        });
        return entities_dict;
      })
    );

    // Update readonly if needed (only one element)
    /*
    // NOTE: on desactive ca pour le moment, ca pose pb si on a que un element est qu'il n'est pas selectioné
    // typiquement sur une transhumance si on a que un emplacement source possibe... cf #147
    entity_dict_transform.push(
      tap((entities_dict: SelectEntityDict[]) => {
        const etype = this.etype;
        // FIXME: this should be true if value seted.... (but it may not be setted yet)
        if (entities_dict.length === 1 && !this.options.nullable) {
          /this.readonly = true;
        }
      })
    );
    */

    return pipeFromArray(entity_dict_transform);
  }

  protected get_in_apiary_id$$(): Observable<number> {
    if (this.args.in_apiary) {
      return of(this.args.in_apiary);
    }
    const apiary_property = this.robustSearchProperty('apiary');
    if (apiary_property) {
      return concat(of(apiary_property.value), apiary_property.valueChanges).pipe(
        filter(apiary_id => !isNil(apiary_id)),
        debounceTime(100),
        distinctUntilRealChanged()
      );
    }
    return of(null);
  }

  protected requestEntities(): Observable<Entity[]> {
    const etype = this.etype;
    let p_entities$: Observable<Entity[]>;
    // console.log(this.formProperty.path, this.options);

    if (this.readonly && isNil(this.options._default)) {
      // console.log(this.formProperty.path, 'Readonly with no _default "patern"');
      // Note: if with have
      // -- LOAD nothing or defaults --
      // Get "imposed" entity
      if (!isNil(this.schema.default)) {
        p_entities$ = this.bg2Api.getEntitiesObj([this.schema.default]);
      } else {
        p_entities$ = of([]);
      }
      this.selected_may_be_missing = true;
    } else if (this.options.with_in) {
      // console.log(this.formProperty.path, this.options.with_in);
      // -- LOAD from other entity (widget) attribute --
      // i.e. fet entities from an other (with_in)
      const efrom = this.options.with_in.from;
      const entity_id = this.options.with_in.entity_id;
      const path = this.options.with_in.path;
      let load_entity_id$: Observable<number>;
      if (efrom) {
        const entity_from_property = this.formProperty.searchProperty(efrom);
        // On recherche la formProperty source
        load_entity_id$ = concat(of(entity_from_property.value), entity_from_property.valueChanges).pipe(
          distinctUntilChanged(),
          // on get l'entity corespondate,
          filter((eid: number) => !isNil(eid))
        );
      } else if (entity_id) {
        load_entity_id$ = of(entity_id);
      } else {
        throw new Error("Invalid 'with_in' format");
      }
      p_entities$ = load_entity_id$.pipe(
        switchMap((eid: number) => this.bg2Api.getEntityObj(eid)),
        // on recupere la valeur
        switchMap((entity: Entity) =>
          this.event_date_and_id$$.pipe(
            map(event_date_and_id => ({
              entity,
              event_date_and_id,
            }))
          )
        ),
        switchMap(res => {
          const entity = res.entity;
          const event_date_and_id = res.event_date_and_id;
          return entity.getAtDate(path, event_date_and_id.date, event_date_and_id.event_id, false);
        }),
        map((eids: number[] | null) => eids || []),
        // on charge les entity
        switchMap((eids: number[]) => {
          this.selected_may_be_missing = false;
          return this.bg2Api.getEntitiesObj(eids);
        })
      );
    } else if (etype === 'exploitation' || etype === 'warehouse') {
      // -- LOAD exploitations or warehouses --
      p_entities$ = this.appState.all_exploitations$$;
      // .pipe(tap(expls => console.log(expls)));
    } else if (etype === 'location') {
      // -- LOAD locations  --
      p_entities$ = this.appState.all_named_locations$$;
      // p_entities$ = this.bg2Api.getEntitiesObjByType('location');
    } else if (etype === 'apiary') {
      // -- LOAD apiary --
      // p_entities$ = this.bg2Api.getEntitiesObjByType('apiary');
      p_entities$ = this.appState.all_named_locations$$.pipe(
        switchMap(locations => {
          if (locations.length) {
            return combineLatest(locations.map(location => location.apiary_id$$));
          } else {
            return of([]);
          }
        }),
        map(apiary_ids => apiary_ids.filter(apiary_id => !isNil(apiary_id))),
        map(apiary_ids => apiary_ids.filter(apiary_id => apiary_id > 0)), // remove ghost
        switchMap(apiary_ids => this.bg2Api.getEntitiesObj(apiary_ids) as Observable<Apiary[]>)
      );
    } else if (etype === 'hive') {
      this.selected_may_be_missing = true;
      // -- LOAD hives --
      const _in_apiary$ = this.get_in_apiary_id$$().pipe(
        tap(apiary_id => {
          this.new_args = { apiary: apiary_id };
        }),
        switchMap(apiary_id => (apiary_id ? this.bg2Api.getEntityObj(apiary_id) : null)),
        map(apiary => apiary as Apiary)
      );

      p_entities$ = combineLatest([_in_apiary$, this.event_date_and_id$$]).pipe(
        debounceTime(10),
        switchMap(([apiary, event_date_id]) => {
          if (apiary) {
            return apiary.requestHivesAtDate(event_date_id.date, event_date_id.event_id, true);
          } else {
            return this.bg2Api.getEntitiesObjByType('hive', undefined, undefined, undefined, undefined, undefined, undefined, 0, 20);
          }
        })
      );
    } else {
      console.error(this.formProperty.path, 'Unimplemented type selector:', etype);
      p_entities$ = of(null);
    }

    // Force the load of the "selected" entity$ OR unselect it
    p_entities$ = p_entities$.pipe(
      switchMap((entities: Entity[]) => {
        if (entities.length > 0) {
          const get_entities$ = entities.map(entity => {
            if (this.etype === 'warehouse' && entity.type === 'warehouse') {
              return (entity as Warehouse).exploitation$$;
            } else {
              return of(entity);
            }
          });
          return combineLatest(get_entities$);
        } else {
          return of(entities);
        }
      }),
      switchMap((entities: Entity[]) => {
        const entities_idx: Dictionary<any> = {};
        entities.map(entity => {
          let eid = entity.id;
          // Special case of warehouses
          if (this.etype === 'warehouse' && entity.type === 'exploitation') {
            eid = entity.static_state.warehouse_id;
          }
          entities_idx[eid] = entity;
        });
        // console.log(this.formProperty.path, 'new entities: ', {entities});
        return this.value$$.pipe(
          distinctUntilRealChanged(),
          switchMap(entity_id => {
            this.loading_state_push('new_entity');
            let entity_ids = [];
            const is_array = isArray(entity_id);
            if (is_array) {
              entity_ids = entity_id;
            } else if (!isNil(entity_id)) {
              entity_ids = [entity_id];
            }
            entity_ids = uniq(entity_ids);
            const missing_ids: any = entity_ids.filter((eid: any) => isNil(entities_idx[eid]));
            const present_ids = entity_ids.filter((eid: any) => !isNil(entities_idx[eid]));
            // console.log(this.formProperty.path, { entity_ids, missing_ids, entities });
            if (missing_ids.length > 0 && this.selected_may_be_missing) {
              // console.log(this.formProperty.path, 'load selected entity (missing)', { missing_ids });
              return this.bg2Api.getEntitiesObj(missing_ids, undefined, /*show_archived:*/ true);
            } else if (missing_ids.length > 0 && !this.selected_may_be_missing) {
              // console.log(this.formProperty.path, 'Unselect no more existing entity', entity_id);
              if (is_array) {
                this.formProperty.setValue(present_ids, false);
              } else {
                this.formProperty.setValue(null, false);
              }
            } else {
              // console.log(this.formProperty.path, 'nothing new to load');
            }
            return of([]);
          }),
          map(new_selected_entities => {
            this.loading_state_pop();
            if (!isNil(new_selected_entities)) {
              entities = _concat(entities, new_selected_entities);
              entities = uniq(entities);
            }
            return entities;
          }),
          distinctUntilRealChanged()
          // tap(entities => console.log(this.formProperty.path, 'new entities', entities)),
        );
      })
    );

    p_entities$ = p_entities$.pipe(e => e);

    return p_entities$;
  }

  // #region -> (entity creation management)

  private _has_been_created$$ = new BehaviorSubject<boolean>(false);
  public has_been_created$$ = this._has_been_created$$.asObservable().pipe(distinctUntilRealChanged(), replay());

  public getCreatorI18n$$ = of({
    apiary: i18n<string>('ENTITY.APIARY.Create apiary'),
    exploitation: i18n<string>('ENTITY.EXPLOITATION.Create exploitation'),
    hive: i18n<string>('ENTITY.HIVE.Create hive'),
    location: i18n<string>('ENTITY.LOCATION.Create location'),
    warehouse: i18n<string>('ENTITY.WAREHOUSE.Create warehouse'),
  }).pipe(
    map((translations: any) => translations[this.etype]),
    replay()
  );

  public createNewEntity(): void {
    this.event_date_and_id$$
      .pipe(
        take(1),
        switchMap(event_x_date => this._buildNewEntityModalParams(event_x_date)),
        switchMap(modal_params => this.dialogs.open<NewEntityModalParams, Entity>(NewEntityModal, modal_params, 'modal-overlay')),
        delay(300) // FIXME: this is needed to ensure entity sate will be loaded
      )
      .subscribe({
        next: created_entity => {
          if (!isNil(created_entity) && created_entity instanceof Entity) {
            this.value = created_entity?.id?.toString();
            this.readonly = true;
            this._has_been_created$$.next(true);
          }
        },
        error: (error: unknown) => console.error(error),
      });
  }

  public deleteCreatedEntity(event: DeleteOrArchiveEntityAction): void {
    if (event === DeleteOrArchiveEntityAction.DELETE || event === DeleteOrArchiveEntityAction.ARCHIVE) {
      this._has_been_created$$.next(false);
      this.readonly = false;

      setTimeout(() => {
        this.formProperty.setValue(null, false);
      }, 1);
    }
  }

  private _buildNewHiveModalParams(event_x_date: EventDateAndId): Observable<NewEntityModalParams> {
    // Return modal parameters
    return this.get_in_apiary_id$$().pipe(
      map(apiary_id => {
        // Check required parameters
        if (isNil(apiary_id)) {
          throw new Error(`Missing 'apiary_id' to create new hive within entity widget.`);
        }
        return {
          etype: 'hive',
          args: {
            setup: {
              date: subMinutes(parseDate(event_x_date.date), 60),
              apiary: apiary_id,
            },
          },
        };
      })
    );
  }

  private _buildNewLocationModalParams(event_x_date: EventDateAndId): Observable<NewEntityModalParams> {
    return of({
      etype: 'location',
      args: {
        no_redirect: true,
        setup: {
          // exploitation: (device_initial_exploitation_id$$ | async),
          date: subMinutes(parseDate(event_x_date.date), 60),
        },
      },
    });
  }

  private _buildNewEntityModalParams(event_x_date: EventDateAndId): Observable<NewEntityModalParams> {
    switch (this.etype) {
      case 'hive': {
        return this._buildNewHiveModalParams(event_x_date);
      }

      case 'location': {
        return this._buildNewLocationModalParams(event_x_date);
      }

      default:
        throw new Error(`Unhandled case to create new entity of type "${this.etype}" within entity widget.`);
    }
  }

  // #endregion

  // #region -> (template typings)

  public asSelectEntityDict(entity_dict: SelectEntityDict): SelectEntityDict {
    return entity_dict as SelectEntityDict;
  }

  public asHive(entity: Entity): Hive {
    return entity instanceof Hive ? entity : null;
  }

  // #endregion
}
