<div class="bg2-intl-tel-input-container">
  <button type="button" mat-button [matMenuTriggerFor]="menu" class="country-selector" [disabled]="disabled" [disableRipple]="true">
    <img
      class="country-selector-flag"
      [alt]="selectedCountry.name"
      [src]="'https://flagcdn.com/' + (selectedCountry.iso2 | lowercase) + '.svg'"
    />

    <span class="country-selector-code" *ngIf="selectedCountry?.dialCode">+{{ selectedCountry.dialCode }}</span>
  </button>

  <mat-menu
    #menu="matMenu"
    class="ngx-mat-tel-input-mat-menu-panel"
    backdropClass="ngx-mat-tel-input-overlay-backdrop"
    overlayPanelClass="ngx-mat-tel-input-overlay-pane"
  >
    <input
      *ngIf="enableSearch"
      class="country-search"
      [(ngModel)]="searchCriteria"
      type="text"
      [placeholder]="searchPlaceholder"
      (click)="$event.stopPropagation()"
    />

    <button
      type="button"
      mat-menu-item
      class="country-list-button"
      *ngFor="let country of preferredCountriesInDropDown"
      (click)="onCountrySelect(country, focusable)"
    >
      <img class="flag" [alt]="country.name" [src]="'https://flagcdn.com/' + (country.iso2 | lowercase) + '.svg'" />

      <div class="label-wrapper">
        {{ country.name }}
        <ng-container *ngIf="country?.dialCode">+{{ country.dialCode }}</ng-container>
      </div>
    </button>

    <mat-divider *ngIf="preferredCountriesInDropDown?.length"></mat-divider>

    <ng-container *ngFor="let country of allCountries">
      <button
        type="button"
        mat-menu-item
        class="country-list-button"
        *ngIf="country | search : searchCriteria"
        (click)="onCountrySelect(country, focusable)"
      >
        <img class="flag" [alt]="country.name" [src]="'https://flagcdn.com/' + (country.iso2 | lowercase) + '.svg'" />

        <div class="label-wrapper">{{ country.name }} +{{ country.dialCode }}</div>
      </button>
    </ng-container>
  </mat-menu>

  <span class="separator mdi mdi-chevron-right"></span>

  <input
    matInput
    type="tel"
    autocomplete="off"
    [ngClass]="cssClass"
    (blur)="onTouched()"
    (keypress)="onInputKeyPress($event)"
    [(ngModel)]="phoneNumber"
    (ngModelChange)="onPhoneNumberChange()"
    [errorStateMatcher]="errorStateMatcher"
    [placeholder]="inputPlaceholder"
    [disabled]="disabled"
    [aria-describedby]="describedBy"
    #focusable
  />
</div>
