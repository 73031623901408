import { Component, Input, ChangeDetectionStrategy } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { SafeHtml } from '@angular/platform-browser';

import { Observable, BehaviorSubject, combineLatest, timer, of } from 'rxjs';
import { filter, map, switchMap, distinctUntilChanged } from 'rxjs';

import { isNil, isString } from 'lodash-es';
import {
  differenceInHours,
  format,
  formatDistanceToNow
} from 'date-fns';

import { parseDate } from 'app/misc/tools';
import { AppStateService } from 'app/core/app-state.service';
import { DRDevice } from 'app/models';
import { distinctUntilRealChanged } from '@bg2app/tools/rxjs';

import { replay } from '@bg2app/tools/rxjs';


@Component({
  selector: 'bg2-last-com',
  templateUrl: './last-com.component.html',
  styleUrls: ['./last-com.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LastComComponent {

  /** */
  public DATE_NOW = new Date();

  private _input_last_contact$$ = new BehaviorSubject<Date>(null);
  @Input()
  public set last_contact(last_contact: Date) {
    if (!isNil(last_contact) && !(last_contact instanceof Date)) {
      throw Error('last_contact should be a Date');
    }
    this._input_last_contact$$.next(parseDate(last_contact));
  }

  private _device$$ = new BehaviorSubject<DRDevice>(null);
  @Input()
  public set device(device: DRDevice) {
    this._device$$.next(device);
  }

  private _prefix$: BehaviorSubject<SafeHtml> = new BehaviorSubject(null);
  public prefix$$ = this._prefix$.asObservable().pipe(
    distinctUntilRealChanged(),
    map((prefix: SafeHtml) => ({
      is_nil: isNil(prefix),
      is_string: isString(prefix),
      prefix: isString(prefix) ? this._sanitizer.bypassSecurityTrustHtml(prefix) : prefix
    })),
    replay()
  );

  @Input()
  public set prefix(prefix: SafeHtml) {
    this._prefix$.next(prefix);
  }

  @Input()
  public loading = false;

  @Input()
  public fromnow = true;

  @Input()
  public hive_fromnow_if_old = false;

  @Input()
  public fulldate = true;

  public now$$ = timer(0, 60 * 1000).pipe(
    map(() => new Date()),
    replay()
  );

  private _device_last_contact$$ = this._device$$.pipe(
    switchMap(device => device ? device.last_contact$$ : of(null))
  );

  private _last_contact$$ = combineLatest([this._input_last_contact$$, this._device_last_contact$$]).pipe(
    map(([input_last_contact, device_last_contact]) => input_last_contact ? input_last_contact : device_last_contact),
    switchMap((last_contact): Observable<Date> => this.now$$.pipe(
      map(now => {
        if (isNil(last_contact)) {
          return null;
        } else {
          return (last_contact > now) ? now : last_contact;
        }
      })
    )),
    distinctUntilChanged(), // a simple distinctUntilChanged with ref comparaison is enought here
    replay()
  );

  /** */
  public last_contact$$ = this._last_contact$$.pipe(
    filter(last_contact => !isNil(last_contact) && last_contact instanceof Date && !isNaN(last_contact.getTime())),
  );

  public flags$$ = combineLatest([this._last_contact$$, this.now$$, this._appState.lang$$]).pipe(
    map(([last_contact, now, lang]) => {
      if (isNil(last_contact)) {
        return {
          never: true,
          too_old: false
        };
      } else {
        return {
          never: false,
          too_old: differenceInHours(now, last_contact) > 24 * 3
        };
      }
    }),
    replay()
  );

  // #region -> (component basics)

  constructor(
    private _sanitizer: DomSanitizer,
    private _appState: AppStateService,
  ) { }

  // #endregion
}
