<bg2-modal [fullscreen]="fullscreen || false" #modal>
  <bg2-modal-header>
    <div class="header">
      <span class="mdi mdi-cog mdi-36px"></span>
      <span class="title">{{ ('ALL.MENU.User settings' | translate)}}</span>
    </div>
    <div class="action-buttons">
      <span class="mdi mdi-close-box mdi-36px" (click)="close()"></span>
    </div>
  </bg2-modal-header>

  <bg2-modal-content>
    <div  class="settings-container" >
      <div class="navigation-container"  *ngIf="(is_menu_hidden$$ | async) === false">
        <mat-button-toggle-group vertical [value]="current_category$$ | async" (valueChange)="current_category = $event">
          <mat-button-toggle [value]="SETTINGS_CATEGORY.APP_SETTINGS">
            <span>{{ 'ROUTABLE_MODALS.USER_SETTINGS.CATEGORY.APP_SETTINGS.Title' | translate }}</span>
            <span class="mdi mdi-24px mdi-chevron-right"></span>
          </mat-button-toggle>

          <mat-button-toggle [value]="SETTINGS_CATEGORY.NOTIFICATIONS">
            <span>{{ 'ROUTABLE_MODALS.USER_SETTINGS.CATEGORY.NOTIFICATIONS.Title' | translate }}</span>
            <span class="mdi mdi-24px mdi-chevron-right"></span>
          </mat-button-toggle>

          <!-- <mat-button-toggle [value]="SETTINGS_CATEGORY.WAREHOUSE">
            <span>{{ 'ROUTABLE_MODALS.USER_SETTINGS.CATEGORY.WAREHOUSE.Title' | translate }}</span>
            <span class="mdi mdi-24px mdi-chevron-right"></span>
          </mat-button-toggle> -->

          <mat-button-toggle *ngIf="appState.is_superadmin$$ | async" [value]="SETTINGS_CATEGORY.DEBUG">
            <span>{{ 'ROUTABLE_MODALS.USER_SETTINGS.CATEGORY.DEBUG.Title' | translate }}</span>
            <span class="mdi mdi-24px mdi-chevron-right"></span>
          </mat-button-toggle>

          <mat-button-toggle [value]="SETTINGS_CATEGORY.ABOUT">
            <span>{{ 'ROUTABLE_MODALS.USER_SETTINGS.CATEGORY.ABOUT.Title' | translate }}</span>
            <span class="mdi mdi-24px mdi-chevron-right"></span>
          </mat-button-toggle>
        </mat-button-toggle-group>
      </div>

      <div
        
        class="category-container"
        *ngIf="(is_settings_hidden$$ | async) === false"
        [ngSwitch]="current_category$$ | async"
      >
        <!-- Button to go back to menu (only mobile) -->
        <bg2-button
          *ngIf="is_mobile_mode$$ | async"
          [type]="'navigation'"
          [icon]="'mdi-arrow-left'"
          [message]="'ROUTABLE_MODALS.USER_SETTINGS.Back to the menu' | translate"
          [options]="{ is_thin: true }"
          (btn_click)="current_category = SETTINGS_CATEGORY.UNSET"
        ></bg2-button>

        <!-- App settings -->
        <ng-container *ngSwitchCase="SETTINGS_CATEGORY.APP_SETTINGS">
          <bg2-global-settings [user]="user$$ | async"></bg2-global-settings>
        </ng-container>

        <!-- Notifications settings -->
        <ng-container *ngSwitchCase="SETTINGS_CATEGORY.NOTIFICATIONS">
          <bg2-notifications-settings [user]="user$$ | async"></bg2-notifications-settings>
        </ng-container>

        <!-- Warehouse settings -->
        <!-- <ng-container *ngSwitchCase="SETTINGS_CATEGORY.WAREHOUSE">
          <bg2-warehouse-view-settings></bg2-warehouse-view-settings>
        </ng-container> -->

        <!-- Debug settings -->
        <ng-container *ngSwitchCase="SETTINGS_CATEGORY.DEBUG">
          <bg2-debugging></bg2-debugging>
        </ng-container>

        <!-- Active system -->
        <ng-container *ngSwitchCase="SETTINGS_CATEGORY.ABOUT">
          <bg2-about></bg2-about>
        </ng-container>
      </div>
    </div>
  </bg2-modal-content>
</bg2-modal>
