import { max } from 'lodash-es';
import { isValid } from 'date-fns/esm';
import { tzLookup } from './tzLookup';

// shortcuts for easier to read formulas
const PI = Math.PI;
const sin = Math.sin;
const cos = Math.cos;
const tan = Math.tan;
const asin = Math.asin;
const atan = Math.atan2;
const acos = Math.acos;
const rad = PI / 180;

const dayMs = 1000 * 60 * 60 * 24;
const J1970 = 2440588;
const J2000 = 2451545;

function toJulian(date: any) {
  return date.valueOf() / dayMs - 0.5 + J1970;
}

function fromJulian(j: any) {
  return new Date((j + 0.5 - J1970) * dayMs);
}

function toDays(date: any) {
  return toJulian(date) - J2000;
}

// general calculations for position

const e = rad * 23.4397; // obliquity of the Earth

function rightAscension(l: any, b: any) {
  return atan(sin(l) * cos(e) - tan(b) * sin(e), cos(l));
}
function declination(l: any, b: any) {
  return asin(sin(b) * cos(e) + cos(b) * sin(e) * sin(l));
}

function azimuth(H: any, phi: any, dec: any) {
  return atan(sin(H), cos(H) * sin(phi) - tan(dec) * cos(phi));
}
function altitude(H: any, phi: any, dec: any) {
  return asin(sin(phi) * sin(dec) + cos(phi) * cos(dec) * cos(H));
}

function siderealTime(d: any, lw: any) {
  return rad * (280.16 + 360.9856235 * d) - lw;
}

function solarMeanAnomaly(d: any) {
  return rad * (357.5291 + 0.98560028 * d);
}

function eclipticLongitude(M: any) {
  const C = rad * (1.9148 * sin(M) + 0.02 * sin(2 * M) + 0.0003 * sin(3 * M)), // equation of center
    P = rad * 102.9372; // perihelion of the Earth

  return M + C + P + PI;
}

function sunCoords(d: any) {
  const M = solarMeanAnomaly(d),
    L = eclipticLongitude(M);
  return {
    dec: declination(L, 0),
    ra: rightAscension(L, 0),
  };
}

// calculates sun position for a given date and latitude/longitude

const getPosition = function (date: any, lat: any, lng: any) {
  const lw = rad * -lng,
    phi = rad * lat,
    d = toDays(date),
    c = sunCoords(d),
    H = siderealTime(d, lw) - c.ra;
  return {
    azimuth: azimuth(H, phi, c.dec),
    altitude: altitude(H, phi, c.dec),
  };
};

// sun times configuration (angle, morning name, evening name)

const times: [number, string, string][] = [
  [-0.833, 'sunrise', 'sunset'],
  // [-0.3, 'sunriseEnd', 'sunsetStart'],
  // [-6, 'dawn', 'dusk'],
  // [-12, 'nauticalDawn', 'nauticalDusk'],
  // [-18, 'nightEnd', 'night'],
  // [6, 'goldenHourEnd', 'goldenHour'],
];

const J0 = 0.0009;
function julianCycle(d: any, lw: any) {
  return Math.round(d - J0 - lw / (2 * PI));
}

function approxTransit(Ht: any, lw: any, n: any) {
  return J0 + (Ht + lw) / (2 * PI) + n;
}
function solarTransitJ(ds: any, M: any, L: any) {
  return J2000 + ds + 0.0053 * sin(M) - 0.0069 * sin(2 * L);
}

function hourAngle(h: any, phi: any, d: any) {
  return acos((sin(h) - sin(phi) * sin(d)) / (cos(phi) * cos(d)));
}
function observerAngle(height: any) {
  return (-2.076 * Math.sqrt(height)) / 60;
}

function getSetJ(h: any, lw: any, phi: any, dec: any, n: any, M: any, L: any) {
  const w = hourAngle(h, phi, dec);
  const a = approxTransit(w, lw, n);

  return solarTransitJ(a, M, L);
}

/**
 * Calculates sun times for a given date
 *
 * @param date
 * @param latitude
 * @param longitude
 * @param elevation Altitude (in meters) relative to the horizon
 *
 * @returns
 */
export const getTimes = (date: Date, latitude: number, longitude: number, elevation: number = 0) => {
  const tz = tzLookup(latitude, longitude);

  elevation = max([elevation ?? 0, 0]);

  const lw = rad * -longitude; // Geodetic longitude
  const phi = rad * latitude; // Geodetic latitude
  const dh = observerAngle(elevation); // Ellipsoidal height
  const d = toDays(date);
  const n = julianCycle(d, lw);
  const ds = approxTransit(0, lw, n);
  const M = solarMeanAnomaly(ds);
  const L = eclipticLongitude(M);
  const dec = declination(L, 0);
  const Jnoon = solarTransitJ(ds, M, L);

  let i, len, time, h0, Jset, Jrise;

  const result: any = {
    // solarNoon: fromJulian(Jnoon),
    // nadir: fromJulian(Jnoon - 0.5),
  };

  for (i = 0, len = times.length; i < len; i += 1) {
    time = times[i];
    h0 = (time[0] + dh) * rad;
    Jset = getSetJ(h0, lw, phi, dec, n, M, L);
    Jrise = Jnoon - (Jset - Jnoon);

    result[time[1]] = fromJulian(Jrise);
    result[time[2]] = fromJulian(Jset);
  }

  // Convert dates to the time at lat/lng
  for (let time_v in result) {
    const options: Intl.DateTimeFormatOptions = {
      timeZone: tz,
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
    };

    try {
      const newTime = new Date(Date.parse(new Intl.DateTimeFormat('en', options).format(result[time_v])));

      if (!isValid(newTime)) {
        throw new Error(`New time "${newTime}" is invalid. Old time: "${time}". DateTimeFormat options: ${JSON.stringify(options)}`);
      } else {
        result[time_v] = newTime;
      }
    } catch (error: any) {
      console.error(`ERROR: Failed to convert time "${time}" at timezone "${tz}"`, error);
    }
  }

  return result;
};
