import { ChangeDetectionStrategy, Component, Inject, Input } from '@angular/core';

import { isNil } from 'lodash-es';

import { map, switchMap, BehaviorSubject, Observable, of, filter } from 'rxjs';
import { distinctUntilRealChanged, waitForNotNilValue } from '@bg2app/tools/rxjs';

import { Apiary, Exploitation, Location } from 'app/models';
import { ENV } from 'app/core/providers/environment.provider';
import { IEnvironment } from 'environments/common';

@Component({
  selector: 'bg2-apiary-hives-nucs-summary',
  templateUrl: './apiary-hives-nucs-summary.component.html',
  styleUrls: ['./apiary-hives-nucs-summary.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ApiaryHivesNucsSummaryComponent {
  // #region -> (component basics)

  constructor(@Inject(ENV) public env: IEnvironment) {}

  // #endregion

  // #region -> (apiary object)

  private _apiary$$: BehaviorSubject<Apiary> = new BehaviorSubject<Apiary>(null);

  public apiary$$: Observable<Apiary> = this._apiary$$.asObservable().pipe(waitForNotNilValue());

  @Input()
  public set apiary(apiary: Apiary) {
    this._apiary$$.next(apiary);
  }

  // #endregion

  // #region -> (location object)

  /**
   * Observes the apiary's location.
   */
  private location$$: Observable<Location> = this.apiary$$.pipe(
    switchMap(apiary => apiary?.location$$),
    waitForNotNilValue()
  );

  /**
   * Observes the location's id.
   */
  public location_id$$ = this.location$$.pipe(
    filter(location => !isNil(location)),
    switchMap(location => location.id$$)
  );

  /**
   * Observes the apiary's id.
   */
  public apiary_id$$: Observable<number> = this.apiary$$.pipe(switchMap(apiary => apiary.id$$));

  // #endregion

  // #region -> (exploitation object)

  /**
   * Observes the location's exploitation.
   */
  private exploitation$$: Observable<Exploitation> = this.location$$.pipe(switchMap(location => location?.exploitation$$));

  /**
   * Observes the apiary's exploitation's id.
   */
  public exploitation_id$$: Observable<number> = this.exploitation$$.pipe(
    waitForNotNilValue(),
    switchMap(exploitation => exploitation?.id$$)
  );

  // #endregion

  // #region -> (named hives and nucs management)

  /** */
  public total_declared_hives$$ = this.apiary$$.pipe(
    switchMap(apiary => apiary.nb_declared_hives$$),
    map(total => total ?? 0)
  );

  /** */
  public total_declared_nucs$$ = this.apiary$$.pipe(
    switchMap(apiary => apiary.nb_declared_nuc$$),
    map(total => total ?? 0)
  );

  // #endregion

  // #region -> (non-named hives and nucs management)

  /** */
  public total_named_hives$$ = this.apiary$$.pipe(switchMap(apiary => apiary.nb_named_hives$$));

  /** */
  public total_named_nucs$$ = this.apiary$$.pipe(switchMap(apiary => apiary.nb_named_nuc$$));

  // #endregion

  // #region -> (supers management)

  /** */
  public total_supers$$ = this.apiary$$.pipe(
    switchMap(apiary => apiary?.supers$$),
    map(supers => supers?.nbs ?? 0)
  );

  /** */
  public mean_supers_by_hive$$ = this.total_declared_hives$$.pipe(
    switchMap(nb_declared_hives =>
      this.total_supers$$.pipe(
        map(supers => {
          if (supers <= 0 || nb_declared_hives <= 0) {
            return 0;
          }

          return Math.round(supers / nb_declared_hives);
        })
      )
    )
  );

  // #endregion

  public is_loading = {
    nucs: {
      // min_max$$: this.nucs_min_max$$.pipe(
      //   map(min_max => isNil(min_max?.min || null) && isNil(min_max?.max || null)),
      //   distinctUntilRealChanged()
      // ),
      total_named$$: this.total_named_nucs$$.pipe(
        map(final_value => isNil(final_value)),
        distinctUntilRealChanged()
      ),
      total_declared_nucs$$: this.total_declared_nucs$$.pipe(
        map(final_value => isNil(final_value)),
        distinctUntilRealChanged()
      ),
    },

    hives: {
      // min_max$$: this.hives_min_max$$.pipe(
      //   map(min_max => isNil(min_max?.min || null) && isNil(min_max?.max || null)),
      //   distinctUntilRealChanged()
      // ),
      total_named$$: this.total_named_hives$$.pipe(
        map(final_value => isNil(final_value)),
        distinctUntilRealChanged()
      ),
      total_declared_hives$$: this.total_declared_hives$$.pipe(
        map(final_value => isNil(final_value)),
        distinctUntilRealChanged()
      ),
    },
  };

  // #region -> number of devices

  public gateway_devices_number$$ = this.apiary$$.pipe(
    switchMap(apiary => apiary.gateway_devices$$),
    map(gateway_devices => gateway_devices.length)
  );

  public sensor_devices_number$$ = this.apiary$$.pipe(
    switchMap(apiary => apiary.sensor_devices$$),
    map(sensor_devices => sensor_devices.length)
  );

  // #endregion
}
