import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, OnDestroy, ViewChild } from '@angular/core';

import { convertFileToBase64Obs, replay, waitForNotNilValue, switchTap } from '@bg2app/tools/rxjs';
import { BehaviorSubject, debounceTime, from, map, Observable, switchMap, take, fromEvent, tap, Subscription } from 'rxjs';

import * as Cropper from 'cropperjs';
// import { CropperCanvas, CropperImage, CropperSelection, getAdjustedSizes, isFunction, isPlainObject, isPositiveNumber } from 'cropperjs';

import { AppStateService } from 'app/core/app-state.service';

import { AbstractDialogComponent, AbstractDialogParams } from '../abstract-dialog.component';
import { SimpleSetterGetter } from 'app/models';
import { NgMatFileWidgetOptions } from 'app/widgets/event-form/ng-mat-file/ng-mat-file.component';
import { isNil } from 'lodash-es';

/** */
export interface ImageCropperParams extends AbstractDialogParams {
  /** */
  file: File;

  /** */
  options: NgMatFileWidgetOptions;
}

/** */
export interface ImageCropperResponse extends AbstractDialogParams {
  /** */
  base_64: string;

  /** */
  size: number;

  /** */
  type: string;
}

@Component({
  selector: 'bg2-image-cropper-dialog',
  templateUrl: './image-cropper-dialog.component.html',
  styleUrls: ['./image-cropper-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ImageCropperDialogComponent
  extends AbstractDialogComponent<ImageCropperParams, ImageCropperResponse | null>
  implements AfterViewInit, OnDestroy
{
  // #region -> (component basics)

  /** */
  private _crop_watcher_sub: Subscription = null;

  /** */
  constructor(public appState: AppStateService) {
    super();
  }

  /** */
  ngAfterViewInit(): void {
    this.cropperImage$$
      .pipe(
        waitForNotNilValue(),
        take(1),
        map(image => image.nativeElement)
      )
      .subscribe({
        next: image_element => {
          this._cropper = new Cropper.default(image_element, {
            guides: false,
            scalable: false,
            dragMode: 'move',
            rotatable: false,
            responsive: true,
            cropBoxMovable: false,
            cropBoxResizable: false,
            minCropBoxWidth: this.input_params.options.cropper_options.width,
            minCropBoxHeight: this.input_params.options.cropper_options.height,
            aspectRatio: this.input_params?.options?.cropper_options?.aspectRatio,
            ready: () => {
              this.is_loading_image.value = false;
            },
            crop: event => {
              this._cropper_event$$.next(event);
            },
          });
        },
      });

    this._crop_watcher_sub = this.cropper_configuration$$
      .pipe(
        take(1),
        switchTap(() => this.cropper_event$$)
      )
      .subscribe({
        next: config => {
          const canvas = this._cropper.getCroppedCanvas({
            width: config.width,
            height: config.height,
          });

          const base64 = canvas.toDataURL('image/jpeg', 0.8);

          this._cropped_file$$.next(base64);
        },
      });
  }

  ngOnDestroy(): void {
    this._cropper.destroy();
    this._crop_watcher_sub?.unsubscribe();
  }

  // #endregion

  // #region -> (loadings)

  /** */
  public is_loading_image = new SimpleSetterGetter(true);

  // #endregion

  // #region -> (incoming data)

  /** */
  public input_file$$ = this.input_params$$.pipe(
    tap(() => (this.is_loading_image.value = true)),
    map(parameters => parameters?.file),
    replay()
  );

  /** */
  public input_file_base64$$ = this.input_file$$.pipe(
    waitForNotNilValue(),
    switchMap(file => convertFileToBase64Obs(file)),
    replay()
  );

  /** */
  private input_options$$ = this.input_params$$.pipe(
    map(parameters => parameters.options),
    replay()
  );

  /** */
  public cropper_configuration$$: Observable<ImageCropperParams['options']['cropper_options']> = this.input_options$$.pipe(
    map(options => options?.cropper_options),
    replay()
  );

  // #endregion

  // #region -> (cropper mgmt)

  /** */
  private _cropper: Cropper = null;

  /** */
  private _cropper_event$$ = new BehaviorSubject<any>(null);

  /** */
  public cropper_event$$ = this._cropper_event$$.asObservable().pipe(waitForNotNilValue(), debounceTime(200), replay());

  /** */
  @ViewChild('cropperImage')
  public set cropperImage(image: ElementRef<HTMLImageElement>) {
    this._cropperImage$$.next(image);
  }

  /** */
  public get cropperImage(): ElementRef<HTMLImageElement> {
    return this._cropperImage$$.getValue();
  }

  /** */
  private _cropperImage$$ = new BehaviorSubject<ElementRef<HTMLImageElement>>(null);

  /** */
  public cropperImage$$ = this._cropperImage$$.asObservable();

  // #endregion

  // #region -> (cropped file)

  /** */
  private _cropped_file$$ = new BehaviorSubject<string>(null);

  /** */
  public cropped_file$$ = this._cropped_file$$.asObservable();

  /** */
  public has_cropped_file$$ = this.cropped_file$$.pipe(
    map(cropped_file => !isNil(cropped_file)),
    replay()
  );

  // #endregion

  // #region -> (validation)

  /** */
  public max_authorized_size$$ = this.input_params$$.pipe(
    map(options => options?.options?.max_size ?? null),
    replay()
  );

  /** */
  // public is_final_file_have_correct_size$$ = combineLatest({ file: this.cropped_file_data$$, max_size: this.max_authorized_size$$ }).pipe(
  //   map(({ file, max_size }) => {
  //     const final_file_size = file?.after_compression?.size ?? null;

  //     if (isNil(max_size) || isNil(final_file_size)) {
  //       return null;
  //     }

  //     if (final_file_size >= max_size) {
  //       return false;
  //     }

  //     return true;
  //   }),
  //   replay()
  // );

  // #endregion

  /** */
  public save(): void {
    this.cropped_file$$
      .pipe(
        take(1),
        map(cropped_file => {
          const mime_type = cropped_file.split(';')[0].split(':')[1];

          const base64_data = cropped_file.substring(cropped_file.indexOf(',') + 1);
          const bits = base64_data.length * 6;
          const bytes = bits / 8;

          return <ImageCropperResponse>{ size: bytes, base_64: cropped_file, type: mime_type };
        })
      )
      .subscribe({
        next: image_cropper_response => this.complete(image_cropper_response),
      });
  }

  /** */
  public cancel(): void {
    this.complete(null);
  }
}
