import { Component, Input, ChangeDetectionStrategy } from '@angular/core';

import { switchMap, tap, filter, map, debounceTime, distinctUntilChanged } from 'rxjs';
import { BehaviorSubject, combineLatest } from 'rxjs';

import { isNil } from 'lodash-es';

import { replay } from '@bg2app/tools/rxjs';

import { Entity } from 'app/models';

@Component({
  selector: 'bg2-entity-history',
  templateUrl: './event-history.component.html',
  styleUrls: ['./event-history.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EntityHistoryComponent {
  private _entity$$: BehaviorSubject<Entity> = new BehaviorSubject(null);
  public entity$$ = this._entity$$.asObservable().pipe(
    filter(entity => !isNil(entity)),
    replay()
  );

  @Input()
  public set entity(entity: Entity) {
    this._entity$$.next(entity);
  }

  @Input()
  public title: string;

  @Input()
  public show_title: boolean = true;

  private _page$$ = new BehaviorSubject<number>(0);
  public page$$ = this._page$$.pipe(distinctUntilChanged());

  set page(page: number) {
    this._page$$.next(page);
  }

  private _limit$$ = new BehaviorSubject<number>(10);
  public limit$$ = this._limit$$.pipe(distinctUntilChanged());

  public offset$$ = combineLatest([this.limit$$, this.page$$]).pipe(
    map(([limit, page]) => limit * page),
    distinctUntilChanged(),
    replay()
  );

  private _events_loading$$ = new BehaviorSubject<boolean>(true);
  public events_loading$$ = this._events_loading$$.pipe(distinctUntilChanged(), replay());

  private _events_res$$ = combineLatest({
    entity: this.entity$$,
    offset: this.offset$$,
    limit: this.limit$$
  }).pipe(
    debounceTime(50),
    tap(() => this._events_loading$$.next(true)),
    filter(({entity, offset, limit}) => !isNil(entity)),
    switchMap(({entity, offset, limit}) => entity.stream_events$$(offset, limit)),
    tap(() => this._events_loading$$.next(false)),
    replay()
  );

  public total$$ = this._events_res$$.pipe(
    map(event_res => event_res.paging.total),
    replay()
  );

  public events$$ = this._events_res$$.pipe(
    map(event_res => event_res.events),
    replay()
  );

  public has_pagging$$ = combineLatest([this.total$$, this.limit$$]).pipe(
    map(([total, limit]) => limit < total),
    replay()
  );
}
