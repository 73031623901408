import { marker as i18n } from '@biesbjerg/ngx-translate-extract-marker';

import { of } from 'rxjs';
import { replay } from '@bg2app/tools/rxjs';

import { DatatableColumn } from 'app/models/misc/datatable';

export const get_device_bat_changes_columns$$ = (is_superadmin: boolean = false) => {
  const columns = <DatatableColumn<any>[]>[
    // {
    //   property: 'select',
    //   short_property: 'slct',
    //   label: i18n<string>('VIEWS.DEVICES.COMPONENTS.DEVICES_DATATABLE.Select'),
    //   displayed_by_default: true,
    //   not_configurable: true,
    //   is_sortable: false,
    //   is_groupable: false,
    //   compare_by: CompareByType.NULL,
    // },
    {
      property: 'bat_change.type',
      short_property: 'typ',
      label: i18n<string>('VIEWS.DEVICES.SHARED.DEVICE_BAT_CHANGES.Type'),
      displayed_by_default: true,
      not_configurable: true,
      is_sortable: false,
      is_groupable: false,
    },
    {
      property: 'bat_change.date',
      short_property: 'dt',
      label: i18n<string>('ALL.COMMON.Date'),
      displayed_by_default: true,
      not_configurable: true,
      is_sortable: false,
      is_groupable: false,
    },
    {
      property: 'bat_change.actions',
      short_property: 'act',
      label: i18n<string>('ALL.COMMON.Actions'),
      displayed_by_default: true,
      not_configurable: true,
      is_sortable: false,
      is_groupable: false,
    },
  ];

  return of(columns).pipe(replay());
};
