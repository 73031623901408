/**
 * bg2
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: dev
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { AclAndOwner } from '../model/aclAndOwner';
import { CreateEntityResponse } from '../model/createEntityResponse';
import { DeleteEntityResponse } from '../model/deleteEntityResponse';
import { DeleteEventResponse } from '../model/deleteEventResponse';
import { DeskRoot } from '../model/deskRoot';
import { EntityClearCacheResponse } from '../model/entityClearCacheResponse';
import { EntitySchema } from '../model/entitySchema';
import { EventSchema } from '../model/eventSchema';
import { GetEntitiesByTypeResponse } from '../model/getEntitiesByTypeResponse';
import { GetEntitiesResponse } from '../model/getEntitiesResponse';
import { GetEntityACLResponse } from '../model/getEntityACLResponse';
import { GetEntityEventsResponse } from '../model/getEntityEventsResponse';
import { GetEntityStateResponse } from '../model/getEntityStateResponse';
import { GetEntityStatesResponse } from '../model/getEntityStatesResponse';
import { GetEntityStaticStateResponse } from '../model/getEntityStaticStateResponse';
import { GetEntityTimeseriesResponse } from '../model/getEntityTimeseriesResponse';
import { GetEventIdsList } from '../model/getEventIdsList';
import { GetEventSchemaResponse } from '../model/getEventSchemaResponse';
import { GetEventsResponse } from '../model/getEventsResponse';
import { GetOneEntity } from '../model/getOneEntity';
import { GetOneEvent } from '../model/getOneEvent';
import { GetReRunEventsCreateHookReturn } from '../model/getReRunEventsCreateHookReturn';
import { GetRunsList } from '../model/getRunsList';
import { InputCreateEntity } from '../model/inputCreateEntity';
import { InputCreateEvent } from '../model/inputCreateEvent';
import { OneSchemaEntityResponse } from '../model/oneSchemaEntityResponse';
import { OrderedError } from '../model/orderedError';
import { PostDesk } from '../model/postDesk';
import { PostEventResponse } from '../model/postEventResponse';
import { PutEntityAclResponse } from '../model/putEntityAclResponse';
import { PutEventResponse } from '../model/putEventResponse';
import { UpdateEntityResponse } from '../model/updateEntityResponse';
import { UpdateEventParams } from '../model/updateEventParams';
import { UpdateOneEntity } from '../model/updateOneEntity';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class Beeguard2Service {

    protected basePath = '/';
    protected defaultHeaders = new HttpHeaders();
    protected configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * 
     * Create a new entity
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    protected createEntity(body: InputCreateEntity, observe?: 'body', reportProgress?: boolean): Observable<CreateEntityResponse>;
    protected createEntity(body: InputCreateEntity, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CreateEntityResponse>>;
    protected createEntity(body: InputCreateEntity, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CreateEntityResponse>>;
    protected createEntity(body: InputCreateEntity, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling createEntity.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<CreateEntityResponse>('post',`${this.basePath}/entities/`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Create a new event
     * Create a new event
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    protected createEvent(body: InputCreateEvent, observe?: 'body', reportProgress?: boolean): Observable<PostEventResponse>;
    protected createEvent(body: InputCreateEvent, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PostEventResponse>>;
    protected createEvent(body: InputCreateEvent, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PostEventResponse>>;
    protected createEvent(body: InputCreateEvent, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling createEvent.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<PostEventResponse>('post',`${this.basePath}/events/`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Add, update or remove a device bat change with a Zoho desk ticket
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    protected createTicketDesk(observe?: 'body', reportProgress?: boolean): Observable<PostDesk>;
    protected createTicketDesk(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PostDesk>>;
    protected createTicketDesk(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PostDesk>>;
    protected createTicketDesk(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<PostDesk>('post',`${this.basePath}/desk/ticket`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Delete an entity, only entity without events can be deleted
     * @param id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    protected deleteEntity(id: number, observe?: 'body', reportProgress?: boolean): Observable<DeleteEntityResponse>;
    protected deleteEntity(id: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<DeleteEntityResponse>>;
    protected deleteEntity(id: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<DeleteEntityResponse>>;
    protected deleteEntity(id: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling deleteEntity.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<DeleteEntityResponse>('delete',`${this.basePath}/entities/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Delete an event.
     * @param id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    protected deleteEvent(id: number, observe?: 'body', reportProgress?: boolean): Observable<DeleteEventResponse>;
    protected deleteEvent(id: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<DeleteEventResponse>>;
    protected deleteEvent(id: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<DeleteEventResponse>>;
    protected deleteEvent(id: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling deleteEvent.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<DeleteEventResponse>('delete',`${this.basePath}/events/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Clear cache of entities
     * @param entities_id List of Entities ID
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    protected entityClearCache(entities_id: Array<number>, observe?: 'body', reportProgress?: boolean): Observable<EntityClearCacheResponse>;
    protected entityClearCache(entities_id: Array<number>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<EntityClearCacheResponse>>;
    protected entityClearCache(entities_id: Array<number>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<EntityClearCacheResponse>>;
    protected entityClearCache(entities_id: Array<number>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (entities_id === null || entities_id === undefined) {
            throw new Error('Required parameter entities_id was null or undefined when calling entityClearCache.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (entities_id) {
            entities_id.forEach((element) => {
                queryParameters = queryParameters.append('entities_id', <any>element);
            })
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<EntityClearCacheResponse>('delete',`${this.basePath}/entities/_clear_cache`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Clear cache of entities (deprecated prefer to use delete)
     * @param entities_id List of Entities ID
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    protected entityClearCacheDeprecated(entities_id: Array<number>, observe?: 'body', reportProgress?: boolean): Observable<EntityClearCacheResponse>;
    protected entityClearCacheDeprecated(entities_id: Array<number>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<EntityClearCacheResponse>>;
    protected entityClearCacheDeprecated(entities_id: Array<number>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<EntityClearCacheResponse>>;
    protected entityClearCacheDeprecated(entities_id: Array<number>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (entities_id === null || entities_id === undefined) {
            throw new Error('Required parameter entities_id was null or undefined when calling entityClearCacheDeprecated.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (entities_id) {
            entities_id.forEach((element) => {
                queryParameters = queryParameters.append('entities_id', <any>element);
            })
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<EntityClearCacheResponse>('get',`${this.basePath}/entities/_clear_cache`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Flush the current runs
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    protected flushRuns(observe?: 'body', reportProgress?: boolean): Observable<OrderedError>;
    protected flushRuns(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<OrderedError>>;
    protected flushRuns(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<OrderedError>>;
    protected flushRuns(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<OrderedError>('get',`${this.basePath}/events/runs/_flush`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Get state of Desk
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    protected getDesk(observe?: 'body', reportProgress?: boolean): Observable<DeskRoot>;
    protected getDesk(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<DeskRoot>>;
    protected getDesk(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<DeskRoot>>;
    protected getDesk(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<DeskRoot>('get',`${this.basePath}/desk/`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Gets list of user entities.
     * @param id Entity ids
     * @param history Get historical values of given fields
     * @param history_from Get historical values from the given date
     * @param with_states Get last states (last know and last clean and first dirty if needed)
     * @param state_before Get state before a given date
     * @param show_archived If &#x27;true&#x27; returns archived entities
     * @param offset Pagging offset
     * @param limit Max number of entity to return
     * @param static_state_filter Json filter expression to apply on entity static states
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    protected getEntities(id?: Array<number>, history?: Array<string>, history_from?: Date, with_states?: boolean, state_before?: Date, show_archived?: boolean, offset?: number, limit?: number, static_state_filter?: string, observe?: 'body', reportProgress?: boolean): Observable<GetEntitiesResponse>;
    protected getEntities(id?: Array<number>, history?: Array<string>, history_from?: Date, with_states?: boolean, state_before?: Date, show_archived?: boolean, offset?: number, limit?: number, static_state_filter?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GetEntitiesResponse>>;
    protected getEntities(id?: Array<number>, history?: Array<string>, history_from?: Date, with_states?: boolean, state_before?: Date, show_archived?: boolean, offset?: number, limit?: number, static_state_filter?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GetEntitiesResponse>>;
    protected getEntities(id?: Array<number>, history?: Array<string>, history_from?: Date, with_states?: boolean, state_before?: Date, show_archived?: boolean, offset?: number, limit?: number, static_state_filter?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {










        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (id) {
            queryParameters = queryParameters.set('id', id.join(COLLECTION_FORMATS['csv']));
        }
        if (history) {
            queryParameters = queryParameters.set('history', history.join(COLLECTION_FORMATS['csv']));
        }
        if (history_from !== undefined && history_from !== null) {
            queryParameters = queryParameters.set('history_from', <any>history_from.toISOString());
        }
        if (with_states !== undefined && with_states !== null) {
            queryParameters = queryParameters.set('with_states', <any>with_states);
        }
        if (state_before !== undefined && state_before !== null) {
            queryParameters = queryParameters.set('state_before', <any>state_before.toISOString());
        }
        if (show_archived !== undefined && show_archived !== null) {
            queryParameters = queryParameters.set('show_archived', <any>show_archived);
        }
        if (offset !== undefined && offset !== null) {
            queryParameters = queryParameters.set('offset', <any>offset);
        }
        if (limit !== undefined && limit !== null) {
            queryParameters = queryParameters.set('limit', <any>limit);
        }
        if (static_state_filter !== undefined && static_state_filter !== null) {
            queryParameters = queryParameters.set('static_state_filter', <any>static_state_filter);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<GetEntitiesResponse>('get',`${this.basePath}/entities/`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Gets all user entities of a given type.
     * @param type 
     * @param id Entity ids
     * @param history Get historical values of given fields
     * @param history_from Get historical values from the given date
     * @param with_states Get last states (last know and last clean and first dirty if needed)
     * @param state_before Get state before a given date
     * @param show_archived If &#x27;true&#x27; returns archived entities
     * @param offset Pagging offset
     * @param limit Max number of entity to return
     * @param static_state_filter Json filter expression to apply on entity static states
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    protected getEntitiesByType(type: string, id?: Array<number>, history?: Array<string>, history_from?: Date, with_states?: boolean, state_before?: Date, show_archived?: boolean, offset?: number, limit?: number, static_state_filter?: string, observe?: 'body', reportProgress?: boolean): Observable<GetEntitiesByTypeResponse>;
    protected getEntitiesByType(type: string, id?: Array<number>, history?: Array<string>, history_from?: Date, with_states?: boolean, state_before?: Date, show_archived?: boolean, offset?: number, limit?: number, static_state_filter?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GetEntitiesByTypeResponse>>;
    protected getEntitiesByType(type: string, id?: Array<number>, history?: Array<string>, history_from?: Date, with_states?: boolean, state_before?: Date, show_archived?: boolean, offset?: number, limit?: number, static_state_filter?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GetEntitiesByTypeResponse>>;
    protected getEntitiesByType(type: string, id?: Array<number>, history?: Array<string>, history_from?: Date, with_states?: boolean, state_before?: Date, show_archived?: boolean, offset?: number, limit?: number, static_state_filter?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (type === null || type === undefined) {
            throw new Error('Required parameter type was null or undefined when calling getEntitiesByType.');
        }










        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (id) {
            queryParameters = queryParameters.set('id', id.join(COLLECTION_FORMATS['csv']));
        }
        if (history) {
            queryParameters = queryParameters.set('history', history.join(COLLECTION_FORMATS['csv']));
        }
        if (history_from !== undefined && history_from !== null) {
            queryParameters = queryParameters.set('history_from', <any>history_from.toISOString());
        }
        if (with_states !== undefined && with_states !== null) {
            queryParameters = queryParameters.set('with_states', <any>with_states);
        }
        if (state_before !== undefined && state_before !== null) {
            queryParameters = queryParameters.set('state_before', <any>state_before.toISOString());
        }
        if (show_archived !== undefined && show_archived !== null) {
            queryParameters = queryParameters.set('show_archived', <any>show_archived);
        }
        if (offset !== undefined && offset !== null) {
            queryParameters = queryParameters.set('offset', <any>offset);
        }
        if (limit !== undefined && limit !== null) {
            queryParameters = queryParameters.set('limit', <any>limit);
        }
        if (static_state_filter !== undefined && static_state_filter !== null) {
            queryParameters = queryParameters.set('static_state_filter', <any>static_state_filter);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<GetEntitiesByTypeResponse>('get',`${this.basePath}/entities/t/${encodeURIComponent(String(type))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Gets list of entities states.
     * @param id Entities id
     * @param date Get states at this date
     * @param before_event_id get states before this event (if more than one event at this date)
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    protected getEntitiesStates(id?: Array<number>, date?: Date, before_event_id?: number, observe?: 'body', reportProgress?: boolean): Observable<GetEntityStatesResponse>;
    protected getEntitiesStates(id?: Array<number>, date?: Date, before_event_id?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GetEntityStatesResponse>>;
    protected getEntitiesStates(id?: Array<number>, date?: Date, before_event_id?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GetEntityStatesResponse>>;
    protected getEntitiesStates(id?: Array<number>, date?: Date, before_event_id?: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (id) {
            queryParameters = queryParameters.set('id', id.join(COLLECTION_FORMATS['csv']));
        }
        if (date !== undefined && date !== null) {
            queryParameters = queryParameters.set('date', <any>date.toISOString());
        }
        if (before_event_id !== undefined && before_event_id !== null) {
            queryParameters = queryParameters.set('before_event_id', <any>before_event_id);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<GetEntityStatesResponse>('get',`${this.basePath}/entities/state`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Get entities&#x27;s tree
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    protected getEntitiesTree(observe?: 'body', reportProgress?: boolean): Observable<EntitySchema>;
    protected getEntitiesTree(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<EntitySchema>>;
    protected getEntitiesTree(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<EntitySchema>>;
    protected getEntitiesTree(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<EntitySchema>('get',`${this.basePath}/entities/schemas`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Gets an entity object.
     * @param id 
     * @param history Get history of given fields
     * @param history_from Get history data from the given date
     * @param state_before Get state before a given date
     * @param with_states Get last states (last know and last clean and first dirty if needed)
     * @param with_links Show entity links
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    protected getEntity(id: number, history?: Array<string>, history_from?: Date, state_before?: Date, with_states?: boolean, with_links?: boolean, observe?: 'body', reportProgress?: boolean): Observable<GetOneEntity>;
    protected getEntity(id: number, history?: Array<string>, history_from?: Date, state_before?: Date, with_states?: boolean, with_links?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GetOneEntity>>;
    protected getEntity(id: number, history?: Array<string>, history_from?: Date, state_before?: Date, with_states?: boolean, with_links?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GetOneEntity>>;
    protected getEntity(id: number, history?: Array<string>, history_from?: Date, state_before?: Date, with_states?: boolean, with_links?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling getEntity.');
        }






        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (history) {
            queryParameters = queryParameters.set('history', history.join(COLLECTION_FORMATS['csv']));
        }
        if (history_from !== undefined && history_from !== null) {
            queryParameters = queryParameters.set('history_from', <any>history_from.toISOString());
        }
        if (state_before !== undefined && state_before !== null) {
            queryParameters = queryParameters.set('state_before', <any>state_before.toISOString());
        }
        if (with_states !== undefined && with_states !== null) {
            queryParameters = queryParameters.set('with_states', <any>with_states);
        }
        if (with_links !== undefined && with_links !== null) {
            queryParameters = queryParameters.set('with_links', <any>with_links);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<GetOneEntity>('get',`${this.basePath}/entities/${encodeURIComponent(String(id))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Gets entity&#x27;s access control list (ACL).
     * @param id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    protected getEntityACL(id: number, observe?: 'body', reportProgress?: boolean): Observable<GetEntityACLResponse>;
    protected getEntityACL(id: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GetEntityACLResponse>>;
    protected getEntityACL(id: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GetEntityACLResponse>>;
    protected getEntityACL(id: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling getEntityACL.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<GetEntityACLResponse>('get',`${this.basePath}/entities/${encodeURIComponent(String(id))}/acl`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Gets entity&#x27;s events.
     * @param id 
     * @param types Filter events to the given types
     * @param start Get events from this date
     * @param end Get events until this date
     * @param offset Pagination offset
     * @param limit Number of event by page
     * @param asc By default events are returned from the newest to the oldest, if &#x60;asc&#x60; is true oldest are returned first.
     * @param run_info If true, event&#x27;s current run information are returned
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    protected getEntityEvents(id: number, types?: Array<string>, start?: Date, end?: Date, offset?: number, limit?: number, asc?: boolean, run_info?: boolean, observe?: 'body', reportProgress?: boolean): Observable<GetEntityEventsResponse>;
    protected getEntityEvents(id: number, types?: Array<string>, start?: Date, end?: Date, offset?: number, limit?: number, asc?: boolean, run_info?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GetEntityEventsResponse>>;
    protected getEntityEvents(id: number, types?: Array<string>, start?: Date, end?: Date, offset?: number, limit?: number, asc?: boolean, run_info?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GetEntityEventsResponse>>;
    protected getEntityEvents(id: number, types?: Array<string>, start?: Date, end?: Date, offset?: number, limit?: number, asc?: boolean, run_info?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling getEntityEvents.');
        }








        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (types) {
            queryParameters = queryParameters.set('types', types.join(COLLECTION_FORMATS['csv']));
        }
        if (start !== undefined && start !== null) {
            queryParameters = queryParameters.set('start', <any>start.toISOString());
        }
        if (end !== undefined && end !== null) {
            queryParameters = queryParameters.set('end', <any>end.toISOString());
        }
        if (offset !== undefined && offset !== null) {
            queryParameters = queryParameters.set('offset', <any>offset);
        }
        if (limit !== undefined && limit !== null) {
            queryParameters = queryParameters.set('limit', <any>limit);
        }
        if (asc !== undefined && asc !== null) {
            queryParameters = queryParameters.set('asc', <any>asc);
        }
        if (run_info !== undefined && run_info !== null) {
            queryParameters = queryParameters.set('run_info', <any>run_info);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<GetEntityEventsResponse>('get',`${this.basePath}/entities/${encodeURIComponent(String(id))}/events`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Get the schema of an enitity type
     * @param type 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    protected getEntitySchema(type: string, observe?: 'body', reportProgress?: boolean): Observable<OneSchemaEntityResponse>;
    protected getEntitySchema(type: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<OneSchemaEntityResponse>>;
    protected getEntitySchema(type: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<OneSchemaEntityResponse>>;
    protected getEntitySchema(type: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (type === null || type === undefined) {
            throw new Error('Required parameter type was null or undefined when calling getEntitySchema.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<OneSchemaEntityResponse>('get',`${this.basePath}/entities/schemas/${encodeURIComponent(String(type))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Gets entity&#x27;s state (at a given date).
     * @param id 
     * @param date Get states at this date
     * @param before_event_id get states before this event (if more than one event at this date)
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    protected getEntityState(id: number, date?: Date, before_event_id?: number, observe?: 'body', reportProgress?: boolean): Observable<GetEntityStateResponse>;
    protected getEntityState(id: number, date?: Date, before_event_id?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GetEntityStateResponse>>;
    protected getEntityState(id: number, date?: Date, before_event_id?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GetEntityStateResponse>>;
    protected getEntityState(id: number, date?: Date, before_event_id?: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling getEntityState.');
        }



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (date !== undefined && date !== null) {
            queryParameters = queryParameters.set('date', <any>date.toISOString());
        }
        if (before_event_id !== undefined && before_event_id !== null) {
            queryParameters = queryParameters.set('before_event_id', <any>before_event_id);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<GetEntityStateResponse>('get',`${this.basePath}/entities/${encodeURIComponent(String(id))}/state`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Gets entity&#x27;s static state.
     * @param id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    protected getEntityStaticState(id: number, observe?: 'body', reportProgress?: boolean): Observable<GetEntityStaticStateResponse>;
    protected getEntityStaticState(id: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GetEntityStaticStateResponse>>;
    protected getEntityStaticState(id: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GetEntityStaticStateResponse>>;
    protected getEntityStaticState(id: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling getEntityStaticState.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<GetEntityStaticStateResponse>('get',`${this.basePath}/entities/${encodeURIComponent(String(id))}/static_state`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Get timeseries data for an entity (from associated devices).
     * @param id 
     * @param measurements Name of the data to retrieve
     * @param start Get data from this date
     * @param end Get data until this date
     * @param step Resolution step (1h, 2h, 1d, ...)
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    protected getEntityTimeseries(id: number, measurements?: Array<string>, start?: Date, end?: Date, step?: string, observe?: 'body', reportProgress?: boolean): Observable<GetEntityTimeseriesResponse>;
    protected getEntityTimeseries(id: number, measurements?: Array<string>, start?: Date, end?: Date, step?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GetEntityTimeseriesResponse>>;
    protected getEntityTimeseries(id: number, measurements?: Array<string>, start?: Date, end?: Date, step?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GetEntityTimeseriesResponse>>;
    protected getEntityTimeseries(id: number, measurements?: Array<string>, start?: Date, end?: Date, step?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling getEntityTimeseries.');
        }





        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (measurements) {
            queryParameters = queryParameters.set('measurements', measurements.join(COLLECTION_FORMATS['csv']));
        }
        if (start !== undefined && start !== null) {
            queryParameters = queryParameters.set('start', <any>start.toISOString());
        }
        if (end !== undefined && end !== null) {
            queryParameters = queryParameters.set('end', <any>end.toISOString());
        }
        if (step !== undefined && step !== null) {
            queryParameters = queryParameters.set('step', <any>step);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<GetEntityTimeseriesResponse>('get',`${this.basePath}/entities/${encodeURIComponent(String(id))}/timeseries`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Gets an event.
     * @param id 
     * @param include Include
     * @param run_info If true, event&#x27;s current run information are returned
     * @param previous_event_id Have ID of previous event
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    protected getEvent(id: number, include?: string, run_info?: boolean, previous_event_id?: boolean, observe?: 'body', reportProgress?: boolean): Observable<GetOneEvent>;
    protected getEvent(id: number, include?: string, run_info?: boolean, previous_event_id?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GetOneEvent>>;
    protected getEvent(id: number, include?: string, run_info?: boolean, previous_event_id?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GetOneEvent>>;
    protected getEvent(id: number, include?: string, run_info?: boolean, previous_event_id?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling getEvent.');
        }




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (include !== undefined && include !== null) {
            queryParameters = queryParameters.set('include', <any>include);
        }
        if (run_info !== undefined && run_info !== null) {
            queryParameters = queryParameters.set('run_info', <any>run_info);
        }
        if (previous_event_id !== undefined && previous_event_id !== null) {
            queryParameters = queryParameters.set('previous_event_id', <any>previous_event_id);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<GetOneEvent>('get',`${this.basePath}/events/${encodeURIComponent(String(id))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Get ids of events
     * @param offset Pagging offset
     * @param limit Max number of events to return
     * @param run_info Run information
     * @param desc Not ordered events ids
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    protected getEventIds(offset?: number, limit?: number, run_info?: boolean, desc?: boolean, observe?: 'body', reportProgress?: boolean): Observable<GetEventIdsList>;
    protected getEventIds(offset?: number, limit?: number, run_info?: boolean, desc?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GetEventIdsList>>;
    protected getEventIds(offset?: number, limit?: number, run_info?: boolean, desc?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GetEventIdsList>>;
    protected getEventIds(offset?: number, limit?: number, run_info?: boolean, desc?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {





        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (offset !== undefined && offset !== null) {
            queryParameters = queryParameters.set('offset', <any>offset);
        }
        if (limit !== undefined && limit !== null) {
            queryParameters = queryParameters.set('limit', <any>limit);
        }
        if (run_info !== undefined && run_info !== null) {
            queryParameters = queryParameters.set('run_info', <any>run_info);
        }
        if (desc !== undefined && desc !== null) {
            queryParameters = queryParameters.set('desc', <any>desc);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<GetEventIdsList>('get',`${this.basePath}/events/ids`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Recompute a event
     * @param id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    protected getEventRecompute(id: number, observe?: 'body', reportProgress?: boolean): Observable<OrderedError>;
    protected getEventRecompute(id: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<OrderedError>>;
    protected getEventRecompute(id: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<OrderedError>>;
    protected getEventRecompute(id: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling getEventRecompute.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<OrderedError>('get',`${this.basePath}/events/${encodeURIComponent(String(id))}/_recompute`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Get the schema of an event type
     * @param type 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    protected getEventSchema(type: string, observe?: 'body', reportProgress?: boolean): Observable<EventSchema>;
    protected getEventSchema(type: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<EventSchema>>;
    protected getEventSchema(type: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<EventSchema>>;
    protected getEventSchema(type: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (type === null || type === undefined) {
            throw new Error('Required parameter type was null or undefined when calling getEventSchema.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<EventSchema>('get',`${this.basePath}/events/schemas/${encodeURIComponent(String(type))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * List all user events
     * Gets all user events.
     * @param entity_ids Filter events to the given entities
     * @param types Filter events to the given types
     * @param date_start Get events from this date
     * @param date_end Get events until this date
     * @param offset pagination offset
     * @param limit number of element by page
     * @param run_info If true, event&#x27;s current run information are returned
     * @param order_asc Order events by ASC
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    protected getEvents(entity_ids?: Array<number>, types?: Array<string>, date_start?: Date, date_end?: Date, offset?: number, limit?: number, run_info?: boolean, order_asc?: boolean, observe?: 'body', reportProgress?: boolean): Observable<GetEventsResponse>;
    protected getEvents(entity_ids?: Array<number>, types?: Array<string>, date_start?: Date, date_end?: Date, offset?: number, limit?: number, run_info?: boolean, order_asc?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GetEventsResponse>>;
    protected getEvents(entity_ids?: Array<number>, types?: Array<string>, date_start?: Date, date_end?: Date, offset?: number, limit?: number, run_info?: boolean, order_asc?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GetEventsResponse>>;
    protected getEvents(entity_ids?: Array<number>, types?: Array<string>, date_start?: Date, date_end?: Date, offset?: number, limit?: number, run_info?: boolean, order_asc?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {









        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (entity_ids) {
            queryParameters = queryParameters.set('entity_ids', entity_ids.join(COLLECTION_FORMATS['csv']));
        }
        if (types) {
            queryParameters = queryParameters.set('types', types.join(COLLECTION_FORMATS['csv']));
        }
        if (date_start !== undefined && date_start !== null) {
            queryParameters = queryParameters.set('date_start', <any>date_start.toISOString());
        }
        if (date_end !== undefined && date_end !== null) {
            queryParameters = queryParameters.set('date_end', <any>date_end.toISOString());
        }
        if (offset !== undefined && offset !== null) {
            queryParameters = queryParameters.set('offset', <any>offset);
        }
        if (limit !== undefined && limit !== null) {
            queryParameters = queryParameters.set('limit', <any>limit);
        }
        if (run_info !== undefined && run_info !== null) {
            queryParameters = queryParameters.set('run_info', <any>run_info);
        }
        if (order_asc !== undefined && order_asc !== null) {
            queryParameters = queryParameters.set('order_asc', <any>order_asc);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<GetEventsResponse>('get',`${this.basePath}/events/`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Get event&#x27;s tree
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    protected getEventsTree(observe?: 'body', reportProgress?: boolean): Observable<GetEventSchemaResponse>;
    protected getEventsTree(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GetEventSchemaResponse>>;
    protected getEventsTree(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GetEventSchemaResponse>>;
    protected getEventsTree(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<GetEventSchemaResponse>('get',`${this.basePath}/events/schemas`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Get the current runs
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    protected getRuns(observe?: 'body', reportProgress?: boolean): Observable<GetRunsList>;
    protected getRuns(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GetRunsList>>;
    protected getRuns(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GetRunsList>>;
    protected getRuns(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<GetRunsList>('get',`${this.basePath}/events/runs`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Clear cache of one entity
     * @param id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    protected oneEntityClearCache(id: number, observe?: 'body', reportProgress?: boolean): Observable<EntityClearCacheResponse>;
    protected oneEntityClearCache(id: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<EntityClearCacheResponse>>;
    protected oneEntityClearCache(id: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<EntityClearCacheResponse>>;
    protected oneEntityClearCache(id: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling oneEntityClearCache.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<EntityClearCacheResponse>('delete',`${this.basePath}/entities/${encodeURIComponent(String(id))}/_clear_cache`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Clear cache of one entity (deprecated prefer to use delete)
     * @param id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    protected oneEntityClearCacheDeprecated(id: number, observe?: 'body', reportProgress?: boolean): Observable<EntityClearCacheResponse>;
    protected oneEntityClearCacheDeprecated(id: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<EntityClearCacheResponse>>;
    protected oneEntityClearCacheDeprecated(id: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<EntityClearCacheResponse>>;
    protected oneEntityClearCacheDeprecated(id: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling oneEntityClearCacheDeprecated.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<EntityClearCacheResponse>('get',`${this.basePath}/entities/${encodeURIComponent(String(id))}/_clear_cache`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param event_type 
     * @param offset Pagging offset
     * @param limit Max number of events to return
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    protected reRunEventsCreateHook(event_type: string, offset?: number, limit?: number, observe?: 'body', reportProgress?: boolean): Observable<GetReRunEventsCreateHookReturn>;
    protected reRunEventsCreateHook(event_type: string, offset?: number, limit?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GetReRunEventsCreateHookReturn>>;
    protected reRunEventsCreateHook(event_type: string, offset?: number, limit?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GetReRunEventsCreateHookReturn>>;
    protected reRunEventsCreateHook(event_type: string, offset?: number, limit?: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (event_type === null || event_type === undefined) {
            throw new Error('Required parameter event_type was null or undefined when calling reRunEventsCreateHook.');
        }



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (offset !== undefined && offset !== null) {
            queryParameters = queryParameters.set('offset', <any>offset);
        }
        if (limit !== undefined && limit !== null) {
            queryParameters = queryParameters.set('limit', <any>limit);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<GetReRunEventsCreateHookReturn>('get',`${this.basePath}/events/t/${encodeURIComponent(String(event_type))}/_create_hook`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Rerun the current runs
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    protected rerunRuns(observe?: 'body', reportProgress?: boolean): Observable<OrderedError>;
    protected rerunRuns(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<OrderedError>>;
    protected rerunRuns(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<OrderedError>>;
    protected rerunRuns(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<OrderedError>('get',`${this.basePath}/events/runs/_rerun_error`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Update an entity (static state).
     * @param body 
     * @param id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    protected updateEntity(body: UpdateOneEntity, id: number, observe?: 'body', reportProgress?: boolean): Observable<UpdateEntityResponse>;
    protected updateEntity(body: UpdateOneEntity, id: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<UpdateEntityResponse>>;
    protected updateEntity(body: UpdateOneEntity, id: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<UpdateEntityResponse>>;
    protected updateEntity(body: UpdateOneEntity, id: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling updateEntity.');
        }

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling updateEntity.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<UpdateEntityResponse>('put',`${this.basePath}/entities/${encodeURIComponent(String(id))}`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Update entity&#x27;s ACL and owner.
     * @param body 
     * @param id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    protected updateEntityACL(body: AclAndOwner, id: number, observe?: 'body', reportProgress?: boolean): Observable<PutEntityAclResponse>;
    protected updateEntityACL(body: AclAndOwner, id: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PutEntityAclResponse>>;
    protected updateEntityACL(body: AclAndOwner, id: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PutEntityAclResponse>>;
    protected updateEntityACL(body: AclAndOwner, id: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling updateEntityACL.');
        }

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling updateEntityACL.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<PutEntityAclResponse>('put',`${this.basePath}/entities/${encodeURIComponent(String(id))}/acl`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Update an event.
     * @param body 
     * @param id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    protected updateEvent(body: UpdateEventParams, id: number, observe?: 'body', reportProgress?: boolean): Observable<PutEventResponse>;
    protected updateEvent(body: UpdateEventParams, id: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PutEventResponse>>;
    protected updateEvent(body: UpdateEventParams, id: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PutEventResponse>>;
    protected updateEvent(body: UpdateEventParams, id: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling updateEvent.');
        }

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling updateEvent.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<PutEventResponse>('put',`${this.basePath}/events/${encodeURIComponent(String(id))}`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
