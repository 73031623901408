<div class="bg2-device-calibration-temperature-selected-chart-component">
  <div [id]="unique_name" (resized)="onChartContainerResized($event)"></div>

  <div class="legend">
    <div [ngClass]="{ 'not-displayed': (what_is_displayed.value$$ | async) !== 'temperature' }" (click)="on_change_display('temperature')">
      <div style="background-color: red"></div>
      <span>
        {{ 'Temperature' }}
      </span>
    </div>

    <div [ngClass]="{ 'not-displayed': (what_is_displayed.value$$ | async) !== 'weight_1' }" (click)="on_change_display('weight_1')">
      <div style="background-color: green"></div>
      <span>
        {{ 'Weight 1' }}
      </span>
    </div>

    <div [ngClass]="{ 'not-displayed': (what_is_displayed.value$$ | async) !== 'weight_2' }" (click)="on_change_display('weight_2')">
      <div style="background-color: blue"></div>
      <span>
        {{ 'Weight 2' }}
      </span>
    </div>
  </div>
</div>
