import { get, isEqual, isNil } from 'lodash-es';

import { BehaviorSubject, map } from 'rxjs';

import { ConsoleLoggerService } from 'app/core/console-logger.service';

/** */
export interface StateMachineSchema<State extends string, Event extends string> {
  /** */
  id: string;

  /** */
  states: {
    /** */
    [s in State]: {
      on: {
        [e in Event]?: State;
      };
    };
  };
}

/** */
export class StateMachine<State extends string, Event extends string> {
  // #region -> (class basics)

  /** */
  private _logger: ConsoleLoggerService = null;

  /** */
  constructor() {}

  // #endregion

  // #region -> (machine properties)

  /** */
  private _schema: StateMachineSchema<State, Event> = null;

  /** */
  private _state$: BehaviorSubject<State> = new BehaviorSubject<State>(null);

  /** */
  public state$$ = this._state$.asObservable();

  // #endregion

  // #region -> (configuration methods)

  /** */
  public start_with_initial_state(initial_state: State): StateMachine<State, Event> {
    this._state$.next(initial_state);

    return this;
  }

  /** */
  public using_schema(schema: StateMachineSchema<State, Event>): StateMachine<State, Event> {
    this._schema = schema;
    this._logger = new ConsoleLoggerService(`StateMachine#${this._schema.id}`, true);

    return this;
  }

  // #endregion

  /** */
  public is_current_state_is = (state: State) => isEqual(this._state$.getValue(), state);

  /** */
  public is_current_state_one_of = (...states: State[]) => states.includes(this._state$.getValue());

  /** */
  public is_current_state_one_of$$ = (...states: State[]) => this.state$$.pipe(map(state => states.includes(state)));

  /** */
  public transition = (event: Event): State => {
    const next_state: State = get(this._schema, `states.${this._state$.getValue()}.on.${event}`, null);

    if (isNil(next_state)) {
      this._logger.warn(
        `Next state for "state:${this._state$.getValue()}" when triggering "event:${event}" does not exist => transition is ignored !`
      );

      return next_state;
    }

    this._state$.next(next_state);
    return next_state;
  };
}
