import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { SharedModule } from 'app/shared-module/shared.module';
import { MaterialSharedModule } from 'app/shared-module/material-shared.module';

import { MiscModule } from 'app/misc/misc.module';

import { EventsSharedModule } from 'app/views/events/events-shared.module';
import { HivesSharedModule } from 'app/views/hives/shared/hives-shared.module';
import { DevicesSharedModule } from 'app/views/devices/shared/devices-shared.module';

import { DialogsModalsModule } from 'app/widgets/dialogs-modals/dialogs-modals.module';
import { WidgetsReusableModule } from 'app/widgets/widgets-reusables/widgets-reusables.module';

import { GhostSolutionsComponent } from './ghost-solutions/ghost-solutions.component';
import { Bg2GhostConfigModalComponent } from './ghost-config-modal/ghost-config-modal.component';
import { GhostConfirmDialogComponent } from './ghost-confirm-dialog/ghost-confirm-dialog.component';
import { LocationsGhostWizardComponent } from './locations-ghost-wizard/locations-ghost-wizard.component';

const module_components = [GhostSolutionsComponent, LocationsGhostWizardComponent];

@NgModule({
  imports: [
    FormsModule,
    CommonModule,
    SharedModule,
    RouterModule,
    MiscModule,
    HivesSharedModule,
    EventsSharedModule,
    DevicesSharedModule,
    DialogsModalsModule,
    MaterialSharedModule,
    WidgetsReusableModule,
  ],
  declarations: [...module_components, Bg2GhostConfigModalComponent, GhostConfirmDialogComponent],
  exports: [...module_components],
})
export class GhostSharedModule {}
