<div *ngLet="location$$ | async; let location" class="location-map-component">
  <hex-loading [size]="'small'" *ngIf="loading$$ | async"></hex-loading>

  <div *ngIf="(user_can_read_position$$ | async) === false" class="no-ace-message">
    <bg2-acl-not-accessible
      [message]="'ALL.ERROR.ACE.READ_POSITION.ACCESSIBILITY.Position not displayed' | translate"
      [popper_config]="{
        entity_type: 'location',
        ace: (location?.user_acl?.cannot$$('read_aproximate_position') | async) ? 'read_aproximate_position' : 'read_precisse_position',
        what:
          (location?.user_acl?.cannot$$('read_aproximate_position') | async)
            ? ('ALL.ERROR.ACE.READ_APPROXIMATE_POSITION.WHAT.the location\'s approximate position' | translate)
            : ('ALL.ERROR.ACE.READ_PRECISE_POSITION.WHAT.the location\'s precise position' | translate)
      }"
    ></bg2-acl-not-accessible>
  </div>

  <bg2-leaflet-map
    (mapReady)="onLeafletMapReady($event)"
    (mapMoveEnd)="onLeafletMapMoveEnd()"
    [height]="'250px'"
    [layers]="map_layers$$ | async"
  ></bg2-leaflet-map>
</div>
