import { AfterViewInit, ChangeDetectionStrategy, Component, Input, NgZone, OnDestroy } from '@angular/core';

import { marker as i18n } from '@biesbjerg/ngx-translate-extract-marker';

import { replay, switchTap, waitForNotNilValue } from '@bg2app/tools/rxjs';
import { BehaviorSubject, catchError, combineLatest, map, of, Subscription, switchMap, tap } from 'rxjs';

import { AppStateService } from 'app/core/app-state.service';
import { ConsoleLoggerService } from 'app/core/console-logger.service';
import { D3SharedCursorService } from 'app/core/services/global/d3-shared-cursor.service';

import { ResizedEvent } from 'app/misc/directives/resized/resized.directive';

import { DRDevice } from 'app/models';

import { DeviceBatterySparklineFactory } from './device-battery-sparkline.factory';
import { isNil } from 'lodash-es';

@Component({
  selector: 'bg2-device-battery-sparkline',
  templateUrl: './device-battery-sparkline.component.html',
  styleUrls: ['./device-battery-sparkline.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DeviceBatterySparklineComponent implements AfterViewInit, OnDestroy {
  // #region -> (component basics)

  /** */
  private data_sub: Subscription;

  /** */
  private readonly LOGGER = new ConsoleLoggerService('DeviceBatterySparklineComponent', true);

  /** */
  constructor(private _d3_shared_cursor: D3SharedCursorService, private _appState: AppStateService, private _ngZone: NgZone) {
    this.chart_factory = new DeviceBatterySparklineFactory(this._d3_shared_cursor, this._appState, this._ngZone);
    this.data_sub = combineLatest([
      this.battery_sparkline_data$$.pipe(tap(data => (this.chart_factory.incoming_data = data ?? null))),
      this.date_range$$.pipe(tap(range => (this.chart_factory.date_range = range))),
      this.vbat_range$$.pipe(tap(range => (this.chart_factory.vbat_range = range))),
      this.vbat_minimal$$.pipe(tap(vbat_minimal => (this.chart_factory.vbat_critical = vbat_minimal))),
      this.geoposition_robust$$.pipe(tap(geoposition => (this.chart_factory.geoposition.value = geoposition))),
    ]).subscribe();
  }

  ngAfterViewInit(): void {
    this.chart_factory.create_chart();
  }

  ngOnDestroy(): void {
    this.data_sub?.unsubscribe();
    this.chart_factory.destroy();
  }

  // #endregion

  @Input()
  public show_legend = true;

  /** */
  public chart_factory: DeviceBatterySparklineFactory = null;

  /** */
  public onChartContainerResized(resized_event: ResizedEvent): void {
    this.chart_factory.box_sizing = { width: resized_event?.new_width, height: resized_event?.new_height };
  }

  /** */
  @Input()
  public height: string = '60px';

  /** */
  @Input()
  public show_title: boolean = false;

  // #region -> (device)

  /** */
  @Input()
  public set device(device: DRDevice) {
    this._device$$.next(device);
  }

  /** */
  private _device$$ = new BehaviorSubject<DRDevice>(null);

  /** */
  public device$$ = this._device$$.pipe(waitForNotNilValue());

  /** */
  public is_device_is_beelive$$ = this.device$$.pipe(
    switchMap(device => combineLatest({ type: device?.type$$, hwv: device.hwv$$ })),
    map(({ type, hwv }) => {
      if (type === 'CPT' && (isNil(hwv) || hwv <= 2)) {
        return true;
      }

      return false;
    }),
    replay()
  );

  // #endregion

  // #region -> (data management)

  /** */
  public battery_sparkline_data$$ = this.device$$.pipe(
    tap(() => (this.chart_factory.is_loading = true)),
    switchMap(device => device.battery_sparkline_last_two_years$$),
    tap(() => (this.chart_factory.is_loading = false))
  );

  /** */
  public vbat_range$$ = this.device$$.pipe(
    switchMap(device => device.get_battery_std_voltage_range$$()),
    replay()
  );

  /** */
  public vbat_minimal$$ = this.device$$.pipe(
    switchMap(device => device.battery_critical_vbat$$),
    replay()
  );

  /** */
  public date_range$$ = this.battery_sparkline_data$$.pipe(
    map(data => ({ start: data.start_date, end: data.end_date })),
    replay()
  );

  /** */
  private geoposition_robust$$ = this.device$$.pipe(
    switchMap(device => device.geoposition_robust$$),
    replay()
  );

  // #endregion

  // #region -> (acl management)

  /** */
  public acl_error$$ = this.device$$.pipe(
    switchTap(device =>
      // Check if the user can "read_devices"
      device.user_acl.throw__if_cannot$$('read_devices', i18n<string>('ALL.ACE.READ_DEVICES.WHAT.view the device'), 'exploitation')
    ),
    switchTap(device =>
      // Check if the user can "read_devices_data"
      device.user_acl.throw__if_cannot$$(
        'read_devices_data',
        i18n<string>('ALL.ACE.READ_DEVICES_DATA.WHAT.view the battery voltage of the device'),
        'exploitation'
      )
    ),
    map(() => null),
    catchError((error: unknown) => of(error))
  );

  // #endregion
}
