import { ChangeDetectionStrategy, Component, Inject, Input } from '@angular/core';

import { isNil } from 'lodash-es';

import { BehaviorSubject, concat, map, Observable, of, switchMap } from 'rxjs';
import { distinctUntilRealChanged, replay, waitForNotNilValue } from '@bg2app/tools/rxjs';

import { Beeguard2Api } from 'app/core';
import { ConsoleLoggerService } from 'app/core/console-logger.service';

import { Apiary, Location } from 'app/models';

import { parseDate } from 'app/misc/tools';
import { ENV } from 'app/core/providers/environment.provider';
import { IEnvironment } from 'environments/common';

@Component({
  selector: 'bg2-apiary-undefined',
  templateUrl: './apiary-undefined.component.html',
  styleUrls: ['./apiary-undefined.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ApiaryUndefinedComponent {
  // #region -> (component basics)

  private _logger = new ConsoleLoggerService('ApiaryUndefinedComponent', true);

  constructor(@Inject(ENV) public env: IEnvironment, private _bg2Api: Beeguard2Api) {}

  // #endregion

  // #region -> (location entity management)

  /**
   * @description
   *
   *
   */
  private _location$$: BehaviorSubject<Location> = new BehaviorSubject<Location>(null);

  /**
   * @description
   *
   * Observes the location entity itself.
   */
  private location$$: Observable<Location> = this._location$$.asObservable().pipe(waitForNotNilValue());

  /** */
  public location_id$$ = this._location$$.pipe(switchMap(location => location.id$$));

  /**
   * @description
   *
   *
   */
  @Input()
  public set location(location: Location) {
    this._location$$.next(location);
  }

  /**
   * @description
   *
   *
   */
  private empty_since$$: Observable<{ date: string; event_id: number }> = this.location$$.pipe(
    switchMap(location => location.state$$),
    map(state => state?.empty_since ?? null),
    distinctUntilRealChanged()
  );

  // #endregion

  // #region -> (previous apiary)

  /**
   * @description
   *
   *
   */
  public apiary_remove_at$$: Observable<Date> = this.empty_since$$.pipe(map(empty_since => parseDate(empty_since?.date)));

  /**
   * @description
   *
   *
   */
  private previous_apiary$$ = this.empty_since$$.pipe(
    switchMap(empty_since => {
      if (isNil(empty_since?.event_id)) {
        return of<Apiary>(null);
      }

      return this._bg2Api.getEventObj(empty_since.event_id).pipe(
        switchMap(event => event.entities$$),
        map(entities => entities?.apiary as Apiary)
      );
    }),
    replay()
  );

  /**
   * @description
   *
   *
   */
  public has_previous_apiary$$ = this.previous_apiary$$.pipe(
    map(previous_apiary => !isNil(previous_apiary)),
    distinctUntilRealChanged()
  );

  /**
   * @description
   *
   *
   */
  public previous_apiary_name$$ = this.previous_apiary$$.pipe(
    waitForNotNilValue(),
    switchMap(apiary => apiary.name$$)
  );

  // #endregion

  // #region -> (loadings)

  public is_loading = {
    previous_apiary$$: concat(of(true), this.previous_apiary$$.pipe(map(() => false))),
  };

  // #endregion
}
