import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';

import { isNil, uniqueId } from 'lodash-es';

import { EfNumberWidget } from '../number/number.widget';

@Component({
  selector: 'ef-number-light-widget',
  templateUrl: './number-light.widget.html',
  styleUrls: ['./number-light.widget.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EfNumberLightWidget extends EfNumberWidget implements OnInit {

  public __isNil = isNil;

  public id: string = uniqueId();

  public step = 0.01;
  public nb_dec = 1;

  ngOnInit(): void {
    super.ngOnInit();
    // TODO: integer with step or multiple > 1 do not work yet
    this.step = this.schema.type === 'integer' ? 1 : (this.schema.step || this.schema.multipleOf || 0.01);
    this.nb_dec = this._countDecimals(this.step);
  }

  private _countDecimals(_val: number): number {
    if (Math.floor(_val) === _val) { // Is integer ?
      return 0;
    }
    return _val.toString().split('.')[1].length || 0;
  }

  public dec(): void {
    const value = this.value || 0;
    if (!isNil(this.schema.minimum) && value <= this.schema.minimum) {
      return;
    }
    this.value = value - this.step;
  }

  public inc(): void {
    const value = this.value || 0;
    if (!isNil(this.schema.maximum) && value >= this.schema.maximum) {
      return;
    }
    this.value = value + this.step;
  }
}
