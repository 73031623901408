<div [id]="widget_id">
  <form-element-header [schema]="schema" [showLabel]="false"></form-element-header>

  <div
    *ngIf="fullscreen_select_helper?.is_open_in_fullscreen.value$$ | async"
    id="fulscreen-select-blocker"
    (click)="fullscreen_select_helper?.close(true)"
  ></div>

  <div *ngIf="(readonly$$ | async) === false && !options.hidden" class="entity-widget">
    <div class="entity-select">
      <mat-form-field [appearance]="'outline'" [class.fullscreen]="fullscreen_select_helper?.is_open_in_fullscreen.value$$ | async">
        <mat-label>{{ schema.label | translate }}</mat-label>

        <mtx-select
          #select
          [bindValue]="'select_id'"
          [bindLabel]="'data-search'"
          [groupBy]="$any(groupBy)"
          [groupValue]="groupValue"
          [items]="entities_dict$$ | async"
          [ngModel]="value$$ | async"
          (ngModelChange)="value = $event;"
          [loading]="loading$$ | async"
          [multiple]="options.multiple"
          [clearable]="options.nullable"
          [closeOnSelect]="!options.multiple"
          [class.valid]="(valid$$ | async)"
          [class.invalid]="(valid$$ | async) === false"
          [loadingText]="'ALL.COMMON.Loading' | translate"
          [dropdownPosition]="'bottom'"
          [appendTo]="'div#' + widget_id + ' mat-form-field .mat-form-field-wrapper'"
          (open)="fullscreen_select_helper.open();"
          (close)="fullscreen_select_helper.close();"
        >
          <!-- Template: select header -->
          <ng-template ng-header-tmp *ngIf="options.multiple && (entities_dict$$ | async)?.length > 2">
            <div style="display: flex; flex-flow: row nowrap">
              <bg2-button
                style="flex: 1 1 50%"
                [type]="'cancel'"
                [message]="'ALL.ACTIONS.Select all' | translate"
                [options]="{ is_thin: true }"
                (btn_click)="selectAll()"
              >
              </bg2-button>

              <bg2-button
                style="flex: 1 1 50%"
                [type]="'cancel'"
                [message]="'ALL.ACTIONS.Unselect all' | translate"
                [options]="{ is_thin: true }"
                (btn_click)="unselectAll()"
              >
              </bg2-button>
            </div>
          </ng-template>

          <!-- Template: selected option(s) -->
          <ng-template ng-label-tmp let-edict="item">
            <span *ngIf="edict?.label">
              <span *ngIf="options.multiple" class="ng-value-icon left" (click)="clear(edict.select_id)" aria-hidden="true"> × </span>
              <span class="ng-value-label">
                <ng-container *ngIf="etype === 'hive' && edict['_entity']">
                  <bg2-picto-hive [hive]="edict['_entity']"></bg2-picto-hive>
                </ng-container>

                {{ edict.label }}

                <ng-container *ngIf="etype === 'apiary'">
                  <ng-container *ngIf="!edict?.state?.setup">
                    <span class="mdi mdi-alert-outline alert"> {{ 'ENTITY.APIARY.ERRORS.Apiary non-existent at this date' | translate }} </span>
                  </ng-container>
                </ng-container>

                <ng-container *ngIf="etype === 'location'">
                  <ng-container *ngIf="!edict?.state?.setup">
                    <span class="mdi mdi-alert-outline alert"> {{ 'ENTITY.LOCATION.ERRORS.Location non-existent at this date' | translate }} </span>
                  </ng-container>
                </ng-container>
              </span>
            </span>

            <span *ngIf="!edict?.label">#{{edict.select_id}}</span>
          </ng-template>

          <!-- Template: not found option -->
          <ng-template ng-notfound-tmp>
            <div class="ng-option disabled">
              <span>{{ 'ALL.ERROR.HTTP.TITLE.Not Found' | translate }}</span>
            </div>
          </ng-template>

          <!-- Template: footer -->
          <ng-container *ngIf="options.createnew">
            <ng-template ng-footer-tmp>
              <div style="display: flex; flex-flow: row nowrap">
                <bg2-button
                  class="w-100"
                  [type]="'action'"
                  [icon]="'mdi-plus'"
                  [message]="getCreatorI18n$$ | async | translate"
                  [options]="{ is_thin: true }"
                  (btn_click)="createNewEntity()"
                >
                </bg2-button>
              </div>
            </ng-template>
          </ng-container>

          <!-- Template: options groups list -->
          <ng-template ng-optgroup-tmp let-group="item" let-index="index">&#9658;&nbsp;{{ group.label | translate }}</ng-template>

          <!-- Template: options list -->
          <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
            <ng-container *ngIf="asSelectEntityDict(item); let entity_dict;">
              <div class="item entity-item entity-item-{{ etype }}">
                <ng-container [ngSwitch]="etype">
                  <!-- Hive entity template definition -->
                  <ng-container *ngSwitchCase="'hive'">
                    <ng-container *ngIf="asHive(entity_dict?._entity); let hive;">
                      <div class="entity-desc-block">
                        <bg2-picto-hive [hive]="hive"></bg2-picto-hive>

                        <span [ngOptionHighlight]="search">{{ entity_dict?.label }}</span>

                        <span class="additional-info" *ngIf="hive?.location$$ | async; let location;">
                          &nbsp;- {{ location?.name$$ | async }}
                        </span>
                        <span class="additional-info" *ngIf="hive?.apiary$$ | async; let apiary;">
                          &nbsp;({{ apiary?.name$$ | async }})
                        </span>
                      </div>

                      <div class="entity-desc-block entity-hive-devices">
                        <ng-container *ngIf="(hive.devices$$ | async)?.length > 0; else emptyHiveDevicesList">
                          <b>{{ 'DEVICE.ALL.COMMON.Associated devices :' | translate }}</b>
                          <ul>
                            <li *ngFor="let device of hive.devices$$ | async">
                              <bg2-device-inline
                                [device]="device"
                                [link_to_device]="false"
                                [show_status]="false"
                                [show_last_com]="true"
                                [show_action]="false"
                                [open_in_modal]="false"
                              ></bg2-device-inline>
                            </li>
                          </ul>
                        </ng-container>

                        <ng-template #emptyHiveDevicesList>
                          <b>{{ 'DEVICE.ALL.ERROR.No associated devices' | translate }}</b>
                        </ng-template>
                      </div>
                    </ng-container>
                  </ng-container>

                  <!-- Location entity template definition -->
                  <ng-container *ngSwitchCase="'location'">
                    <div class="entity-desc-block">
                      <span [ngOptionHighlight]="search">{{ entity_dict?.label }}</span>

                      <span *ngIf="entity_dict?.other_data?.apiary_name; let apiary_name;" class="additional-info">
                        <span>&nbsp;(</span>
                        <span>{{ "ENTITY.APIARY.Apiary:" | translate }}</span>
                        <span [ngOptionHighlight]="search">{{ apiary_name }}</span>
                        <span>)</span>
                      </span>

                      <span *ngIf="!entity_dict?.other_data?.apiary_name" class="additional-info no-apiary">
                        <span>&nbsp;(</span>
                        <span>{{"ENTITY.APIARY.ERRORS.No apiary" | translate}}</span>
                        <span>)</span>
                      </span>
                    </div>

                    <div class="entity-desc-block">
                      <ng-container *ngIf="!entity_dict?.state?.setup">
                        <span class="mdi mdi-alert-outline alert">
                          {{ 'ENTITY.LOCATION.ERRORS.Location non-existent at this date' | translate }}
                        </span>
                      </ng-container>
                    </div>
                  </ng-container>

                  <!-- Apiary entity template definition -->
                  <ng-container *ngSwitchCase="'apiary'">
                    <div class="entity-desc-block">
                      <span [ngOptionHighlight]="search">{{ entity_dict.label }}</span>

                      <span *ngIf="entity_dict?.other_data?.location_name; let location_name">
                        <span>&nbsp;(</span>
                        <span>{{"ENTITY.LOCATION.Location:" | translate}}</span>
                        <span [ngOptionHighlight]="search"> {{ location_name }}</span>
                        <span>)</span>
                      </span>

                      <div class="entity-desc-block">
                        <ng-container *ngIf="!entity_dict?.state?.setup">
                          <span class="mdi mdi-alert-outline alert">
                            {{ 'ENTITY.APIARY.ERRORS.Apiary non-existent at this date' | translate }}
                          </span>
                        </ng-container>
                      </div>
                    </div>
                  </ng-container>

                  <!-- Warehouse entity template definition -->
                  <ng-container *ngSwitchCase="'warehouse'">
                    <div class="entity-desc-block">
                      <span [ngOptionHighlight]="search">{{ entity_dict.label }}</span>
                      <span
                        *ngIf="entity_dict?.other_data?.owner_name && (entity_dict?.label !== entity_dict?.other_data?.owner_name)"
                        class="label-secondary"
                      >
                        <span>&nbsp;[</span>
                        <span class="mdi mdi-account"></span>
                        <span [ngOptionHighlight]="search">{{ entity_dict.other_data.owner_name }}</span>
                        <span>]</span>
                      </span>
                    </div>
                  </ng-container>

                  <!-- Exploitation entity template definition -->
                  <ng-container *ngSwitchCase="'exploitation'">
                    <div class="entity-desc-block">
                      <span [ngOptionHighlight]="search">{{ entity_dict.label }}</span>
                      <span
                        *ngIf="entity_dict?.other_data?.owner_name && (entity_dict?.label !== entity_dict?.other_data?.owner_name)"
                        class="label-secondary"
                      >
                        <span>&nbsp;[</span>
                        <span class="mdi mdi-account"></span>
                        <span [ngOptionHighlight]="search">{{ entity_dict.other_data.owner_name }}</span>
                        <span>]</span>
                      </span>
                    </div>
                  </ng-container>

                  <!-- Default template definition -->
                  <ng-container *ngSwitchDefault>
                    <span [ngOptionHighlight]="search">{{ entity_dict.label }}</span>
                  </ng-container>
                </ng-container>
              </div>
            </ng-container>
          </ng-template>
        </mtx-select>
      </mat-form-field>
    </div>
  </div>

  <!-- Preselected entity (readonly entity) -->
  <div *ngIf="(readonly$$ | async) && (show_data$$ | async) && !options.hidden" class="entity-widget readonly">
    <label>{{schema.label | translate}}:</label>
    <span *ngIf="any_loading$$ | async">...</span>
    <span *ngIf="(loading$$ | async) === false">
      <ng-container *ngFor="let edict of (selected_edict$$ | async)">
        <span class="item">
          {{edict?.label}}
          <ng-container *ngIf="edict.type === 'hive'">
            <span class="additional-info" *ngIf="$any($any(edict?._entity)?.location$$ | async); let location;">
              - {{ location?.name }}
            </span>
            <span class="additional-info" *ngIf="$any($any(edict?._entity)?.apiary$$ | async); let apiary;"> ({{ apiary?.name }}) </span>
          </ng-container>
          <ng-container *ngIf="(has_been_created$$ | async) && (value$$ | async) >= 0 && (value$$ | async) !== null">
            <bg2-button-delete-or-archive
              [is_icon]="true"
              [entity_id]="value$$ | async"
              [popper]="'WIDGETS.EVENT_FORM.BG2ENTITY.Delete created entity' | translate"
              (taken_action)="deleteCreatedEntity($event)"
            ></bg2-button-delete-or-archive>
          </ng-container>
        </span>
      </ng-container>
    </span>

    <div class="progress" style="background-color: transparent" *ngIf="any_loading$$ | async">
      <div class="indeterminate" style="background-color: #f5b54a"></div>
    </div>
  </div>
</div>
