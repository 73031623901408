import { Observable } from 'rxjs';
import { map } from 'rxjs';

import { marker as i18n } from '@biesbjerg/ngx-translate-extract-marker';
import { clone, isArray } from 'lodash-es';

import { Dictionary } from 'app/typings/core/interfaces';

import { Beeguard2Api } from 'app/core';
import { Event, I18NParams, IEventGlobalSchema } from './Event';
import { Apiary, Entity, Hive } from '..';

export class HiveSetup extends Event {

  constructor(protected bg2Api: Beeguard2Api) {
    super(bg2Api);
    this.type = 'hive_setup';
    this.resetLogger();
  }

  protected _applyLocally(entities: Dictionary<Entity|Entity[]>): Event {
    const apiary = entities.apiary as Apiary;
    const hive = entities.hive as Hive;
    // Update hive
    const hive_state = hive.getPreviousLocalState(this.date, this.id);
    hive_state.apiary_id = apiary.id;
    hive_state.setup = this.since();
    // update apiary
    const apiary_state = apiary.getPreviousLocalState(this.date, this.id);
    const hive_ids = clone(apiary_state.hive_ids) || [];
    hive_ids.push(hive.id)
    apiary_state.hive_ids = hive_ids
    // Store it
    hive.storeLocalState(this.date, this.id, hive_state);
    apiary.storeLocalState(this.date, this.id, apiary_state);
    return this;
  }

  protected getDescKey(role: string, schema: IEventGlobalSchema, i18nData: I18NParams): Observable<string> {
    return super.getDescKey(role, schema, i18nData).pipe(
      map(desc_key => {
        const htype = i18nData.data.htype || 'hive';
        if (role === 'hive') {
          return i18n('EVENT.HIVE_SETUP.Added to apiary \'[entities->apiary->name]\'');
        } else if ( role === 'warehouse') {
          if (htype === 'nuc') {
            return i18n('EVENT.HIVE_SETUP.Add nuc [entities->hive->name] on apiary [entities->apiary->name]');
          } else {
            return i18n('EVENT.HIVE_SETUP.Add hive [entities->hive->name] on apiary [entities->apiary->name]');
          }
        } else {
          if (htype === 'nuc') {
            return i18n('EVENT.HIVE_SETUP.Adding nuc hive \'[entities->hive->name]\'');
          } else {
            return i18n('EVENT.HIVE_SETUP.Adding hive \'[entities->hive->name]\'');
          }
        }
      })
    );
  }
}


export class HivesSetup extends Event {
  protected getDescKey(role: string, schema: IEventGlobalSchema, i18nData: I18NParams): Observable<string> {
    return super.getDescKey(role, schema, i18nData).pipe(
      map(desc_key => {
        const htype = i18nData.data.htype || 'hive';
        if ( role === 'hive') {
          return i18n('EVENT.HIVE_SETUP.Added to apiary \'[entities->apiary->name]\'');
        } else if ( role === 'warehouse') {
          if (htype === 'nuc') {
            return i18n('EVENT.HIVE_SETUP.Adding [computed->nb_hives] nuc hives on apiary [entities->apiary->name]');
          } else {
            return i18n('EVENT.HIVE_SETUP.Adding [computed->nb_hives] hives on apiary [entities->apiary->name]');
          }
        } else {
          if (htype === 'nuc') {
            return i18n('EVENT.HIVE_SETUP.Adding [computed->nb_hives] nuc hives');
          } else {
            return i18n('EVENT.HIVE_SETUP.Adding [computed->nb_hives] hives');
          }
        }
      })
    );
  }

  protected getI18nParams(translate_service: any, entities: any) {
    return super.getI18nParams(translate_service, entities).pipe(
      map(i18nParams => {
        i18nParams.computed = {
          nb_hives: isArray(i18nParams.entities.hives) ? (i18nParams.entities.hives.length) : (1)
        };
        return i18nParams;
      })
    );
  }
}
