import { Pipe, PipeTransform } from '@angular/core';

import { format, isSameYear } from 'date-fns';
import { isNil } from 'lodash-es';

import { AppStateService } from 'app/core/app-state.service';
import { parseDate } from '../tools';
import { isToday } from 'date-fns/esm';

enum TmplDateFormat {
  Full = 'full',
  Short = 'short',
  Week_day = 'week_day',
  Month_day = 'month_day',
  Month_day_year = 'month_day_year_if_needed',
  Hour_short = 'hour_short', // 06:35 PM
  Month = 'month',
  Time_else_day_month_year = 'today_time_else_day_month_year',
}

// TODO: add a cache of previously computed value,
// should be invalidate if lang changed or value changed
@Pipe({
  name: 'formatDate',
  pure: false,
})
export class FormatDatePipe implements PipeTransform {
  constructor(protected appState: AppStateService) {}

  transform(value: Date | string, fm: string = null): string {
    if (isNil(value)) {
      return null;
    }

    const vdate = parseDate(value); // ensure this is a Date
    if (!(vdate instanceof Date) || isNaN(vdate as any)) {
      return null;
    }

    switch (fm) {
      case TmplDateFormat.Full: {
        // Day of the month with time
        fm = this.appState.dl.lll;
        break;
      }

      case TmplDateFormat.Short: {
        // Day of the month with the year (no time)
        fm = this.appState.dl.ll;
        break;
      }

      case TmplDateFormat.Week_day: {
        // Day in the "same week" ('mardi 2',  'mercredi 4')
        fm = this.appState.dl.ll_dsw;
        break;
      }

      case TmplDateFormat.Month_day_year: {
        // Day of the month, year if needed
        fm = this.appState.dl.ll;
        if (isSameYear(vdate, new Date())) {
          fm = this.appState.dl.ll_ny;
        }
        break;
      }

      case TmplDateFormat.Month_day: {
        // Day of the month without the year
        fm = this.appState.dl.ll_ny;
        break;
      }

      case TmplDateFormat.Month: {
        fm = 'LLLL';
        break;
      }

      case TmplDateFormat.Hour_short: {
        fm = this.appState.dl.HH_mm;
        break;
      }

      case TmplDateFormat.Time_else_day_month_year: {
        if (isToday(vdate)) {
          fm = this.appState.dl.HH_mm;
          break;
        }

        fm = this.appState.dl.ll;

        break;
      }

      default: {
        fm = fm;
      }
    }

    return format(vdate, fm, { locale: this.appState.dl.dateFns });
  }
}
