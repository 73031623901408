import { NgModule } from '@angular/core';

import { PipesModule } from './pipes/pipes.module';
import { DirectivesModule } from './directives/directives.module';
import { TooltipModule } from './libraries/bg2-tooltip/tooltip.module';

import { TooltipTriggerMethod } from './libraries/bg2-tooltip/enums/tooltip-trigger-method';
import { TooltipPlacement } from './libraries/bg2-tooltip/enums/tooltip-placement';

@NgModule({
  declarations: [],
  imports: [
    PipesModule,
    DirectivesModule,
    TooltipModule.forRoot({
      appendTo: 'body',
      hideOnScroll: true,
      trigger: TooltipTriggerMethod.hover,
      placement: TooltipPlacement.BOTTOM,
    }),
  ],
  exports: [PipesModule, DirectivesModule, TooltipModule],
})
export class MiscModule {}
