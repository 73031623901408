/** */
export enum LocationEntityUserAce {
  /** */
  'write_all',

  /** */
  'read',

  /** */
  'read_aproximate_position',

  /** */
  'read_precisse_position',

  /** */
  'read_measurements_data',

  /** */
  'read_all_events',

  /** */
  'write_all_events',

  /** */
  'read_devices',

  /**
   * Can read all measurements and last measurements
   */
  'read_devices_data',

  /**
   * Can only read specific measurements and last measurements :
   * - (location)
   * - (location_AGG_GPS)
   */
  'read_devices_routes',

  /**
   * Can only read specific last measurements :
   * - (location)
   * - (location_AGG_GPS)
   */
  'read_devices_last_position',

  /** */
  'write_move_authorization',

  /** */
  'write_devices_notifications',

  /** */
  'write_devices_configuration',
}

/** */
export const location_user_ace_to_i18n: Map<keyof typeof LocationEntityUserAce, string> = new Map([
  ['read', 'ENTITY.ALL.SCOPE.Minimal access to the location'],
  ['read_all_events', 'ENTITY.ALL.SCOPE.Access all events'],
  ['read_aproximate_position', 'ENTITY.ALL.SCOPE.Read an approximate position'],
  ['read_devices', 'ENTITY.ALL.SCOPE.Access to the associated devices'],
  ['read_devices_last_position', 'ENTITY.ALL.SCOPE.Read last precise GPS position of associated devices'],
  ['read_devices_routes', 'ENTITY.ALL.SCOPE.Access trips of associated devices'],
  ['read_measurements_data', 'ENTITY.ALL.SCOPE.Access measurements data on the location'],
  ['read_precisse_position', 'ENTITY.ALL.SCOPE.Read the precisse position'],

  ['write_all', 'ENTITY.ALL.SCOPE.Full read/write acces to the location and associated apiary, hives and devices'],
  ['write_all_events', 'ENTITY.ALL.SCOPE.Create and modify all events'],
  ['write_devices_configuration', 'ENTITY.ALL.SCOPE.Configure devices associated to the location'],
  ['write_devices_notifications', 'ENTITY.ALL.SCOPE.Configure custom notifications on associated devices'],
  ['write_move_authorization', 'ENTITY.ALL.SCOPE.Configure movement authorizations for associated devices'],
]);
