import { map, of, combineLatest, Observable, switchMap, forkJoin, concatMap } from 'rxjs';
import { replay, waitForNotNilValue } from '@bg2app/tools/rxjs';

import { isNil, has, isEmpty, minBy, maxBy, mean, sum } from 'lodash-es';

import { marker as i18n } from '@biesbjerg/ngx-translate-extract-marker';

import { DRDevice, DeviceInterface, DeviceLastMeasurements, DeviceLocation } from './DRDevice';
import { GPSDevice, IFx_SCHEMA } from './GPSDevice';
import { DeviceApi } from 'app/core';
import { DeviceQueryParams } from 'app/core/api/device/device-api-service';
import { DeviceCommunicationTechnology } from './enumerators/device-communication-technology.enum';
import { DeviceRSSIData, LastWeatherData, RawWeatherDataPoint, RGJobData, RGSecondaryData, WeatherDataPoint } from '../data';
import {
  computes_barometric_pressure_from_sea_pressure,
  compute_pressure_at_sea_level,
  parseDate,
  startOfTomorrowLuxon,
} from 'app/misc/tools';
import { addHours, isSameDay, subHours } from 'date-fns/esm';
import { MeasurementDescription } from 'app/core/api-swagger/device';
import { MinMax } from 'app/typings/core/interfaces';
import { percentile } from 'app/misc/tools/maths';
import {
  DEVICE_BATTERY_TYPE,
  DEVICE_SIMPLIFIED_BATTERY_STATE,
  DEVICE_SIMPLIFIED_BATTERY_STATE_REASON,
  DeviceSimplifiedBatteryState,
} from './enumerators';
import { differenceInYears } from 'date-fns';
import { BatterySparklineData } from './interfaces';

export class RGDevice extends GPSDevice {
  // #region -> (model basics)

  constructor(protected deviceApi: DeviceApi, params?: DeviceQueryParams) {
    super(deviceApi, params);

    this.is_gateway = true;
    this.type = DeviceInterface.TypeEnum.RG;
  }

  // #endregion

  // #region -> last data

  /** */
  protected fix_last_battery_voltage(last_temperature: number): number {
    if (!isNil(last_temperature)) {
      if (last_temperature < -15 || last_temperature > 60) {
        last_temperature = DRDevice.DEFAULT_TEMPERATURE_IF_INVALID;
      }
    }

    return last_temperature;
  }

  // #endregion

  // #region -> battery state

  /** */
  protected fix_sparkline_timeseries(timeseries: any[]): any[] {
    return timeseries.map((d: any) => {
      if (!isNil(d.temperature)) {
        if (d.temperature < -15 || d.temperature > 60) {
          d.temperature = DRDevice.DEFAULT_TEMPERATURE_IF_INVALID;
        }
      }

      return d;
    });
  }

  /** */
  protected compute_battery_type$$(): Observable<DEVICE_BATTERY_TYPE> {
    return this.gateway_type$$.pipe(
      map(gateway_type => {
        if (gateway_type === 'RG2') {
          return DEVICE_BATTERY_TYPE['3P1'];
        }

        return DEVICE_BATTERY_TYPE.M1;
      })
    );
  }

  /** */
  protected get_battery_com_voltage(last_measurements: DeviceLastMeasurements): number | number[] {
    const fields = last_measurements?.gateway_message?.fields;
    if (has(fields, 'vbat_1')) {
      return [fields?.vbat?.last ?? null, fields?.vbat_1?.last ?? null, fields?.vbat_2?.last ?? null];
    }

    return fields?.vbat?.last ?? null;
  }

  /** */
  protected get_battery_noload_voltage(last_measurements: DeviceLastMeasurements): number | number[] {
    const fields = last_measurements?.gateway_message?.fields;

    if (has(fields, 'valim_1')) {
      return [fields?.valim?.last ?? null, fields?.valim_1?.last ?? null, fields?.valim_2?.last ?? null];
    }

    return fields?.valim?.last ?? null;
  }

  /**
   * Device typical voltage range
   */
  public get_battery_std_voltage_range$$(): Observable<[number, number]> {
    return combineLatest({
      gen: this.generation$$,
      swv: this.swv$$,
    }).pipe(
      map<{ gen: number; swv: number }, [number, number]>(({ gen, swv }) => {
        if (gen <= 1) {
          // M1 bat
          if (swv < 17) {
            return [3.2, 3.8];
          }
          return [2.8, 3.65];
        }
        // gen 2
        return [2.1, 2.8]; // P1 bat
      }),
      replay()
    );
  }

  protected get_battery_critical_vbat$$(): Observable<number> {
    return combineLatest({
      gen: this.generation$$,
      swv: this.swv$$,
    }).pipe(
      map<{ gen: number; swv: number }, number>(({ gen, swv }) => {
        if (gen <= 1) {
          if (swv < 17) {
            return 3.2;
          }
          return 2.8;
        }
        // gen 2
        return 2.1;
      }),
      replay()
    );
  }

  /** */
  public get_battery_simplified_state$$(): Observable<DeviceSimplifiedBatteryState> {
    return this._get_default_battery_simplified_state$$.pipe(
      switchMap(state => {
        if (!isNil(state)) {
          return of(state);
        }

        return combineLatest({
          last_power_on: this.last_power_on$$,
          last_battery_change: this.last_battery_change$$,
        }).pipe(
          map(({ last_battery_change, last_power_on }) => {
            const last_battery_change_years = Math.abs(differenceInYears(last_battery_change?.time ?? last_power_on, new Date()));

            if (last_battery_change_years >= 3) {
              return {
                state: DEVICE_SIMPLIFIED_BATTERY_STATE.NOT_OK,
                reason: DEVICE_SIMPLIFIED_BATTERY_STATE_REASON.LAST_BATTERY_OLDER_THAN_3_YEARS,
              };
            }

            return {
              state: DEVICE_SIMPLIFIED_BATTERY_STATE.OK,
              reason: null,
            };
          })
        );
      })
    );
  }

  // #endregion

  // #region -> (device generation & versions)

  /**
   * Gets generation of the device.
   *
   * @returns Device generation
   */
  protected getGeneration(swv: number, hwv: number, gateway_type: string): number {
    if (gateway_type === 'RG2') {
      return 2;
    }
    return 1;
  }

  public use_ifx$$ = of(true).pipe(replay());

  // #endregion

  // #region -> (communication technology)

  /** */
  protected get_com_technology$$(): Observable<DeviceCommunicationTechnology[]> {
    return of(null);
  }

  // #endregion

  // #region -> (device timeseries)

  /** */
  private last_weather_date$$: Observable<Date> = this.update$$.pipe(
    map(device => {
      const last_env_time = device?.last_env?.time ?? null;

      if (isNil(last_env_time)) {
        return new Date();
      }

      return new Date(last_env_time);
    }),
    replay()
  );

  /** */
  private last_weather_data$$: Observable<LastWeatherData> = this.last_weather_date$$.pipe(
    waitForNotNilValue(),
    switchMap((last_date: Date) =>
      this.requestTimeseries(
        ['temperature', 'humidity', 'pressure', 'rain', 'anemo_speed', 'anemo_heading'],
        subHours(last_date, 24),
        addHours(last_date, 1),
        'auto'
      )
    ),
    map(timeseries => {
      const raw_data = ((timeseries?.timeseries?.data as any) ?? []) as RawWeatherDataPoint[];
      const last_weather_data = {} as LastWeatherData;

      if (isEmpty(raw_data)) {
        return last_weather_data;
      }

      last_weather_data.end = parseDate(timeseries.timeseries.end);

      // Calculate last data for temperature
      const temperatures = raw_data.map(datum => datum?.temperature).filter(temperature => !isNil(temperature));
      if (!isEmpty(temperatures)) {
        const minimal_temperature = minBy(raw_data, 'temperature');
        const maximal_temperature = maxBy(raw_data, 'temperature');

        last_weather_data.temperature = {
          min: minimal_temperature?.temperature,
          timestamp_for_min: parseDate(minimal_temperature.date),

          max: maximal_temperature?.temperature,
          timestamp_for_max: parseDate(maximal_temperature.date),

          last: temperatures?.[temperatures.length - 1],
        };
      }

      // Calculate last data for humidity
      const humidities = raw_data.map(datum => datum?.humidity).filter(humidity => !isNil(humidity));
      if (!isEmpty(humidities)) {
        last_weather_data.humidity = {
          mean: mean(humidities),
          last: humidities?.[humidities.length - 1],
        };
      }

      // Calculate last data for pressure
      const pressure_values = raw_data.map(datum => datum.pressure).filter(pressure => !isNil(pressure));
      if (!isEmpty(pressure_values)) {
        last_weather_data.pressure = {
          mean: mean(pressure_values),
          last: pressure_values?.[pressure_values.length - 1],
        };
      }

      // Calculate last data for rain
      const rain_values = raw_data.map(datum => datum.rain).filter(rain => !isNil(rain));
      if (!isEmpty(rain_values)) {
        last_weather_data.rain = {
          last: rain_values?.[rain_values.length - 1],
          total: sum(rain_values),
        };
      }

      // Calculate last data for anemo_speed and anemo_heading
      const anemo_speed_values = raw_data.map(datum => datum.anemo_speed);
      const anemo_heading_values = raw_data.map(datum => datum.anemo_heading);
      const [anemo_speed_percentile_90, anemo_speed_percentile_idx] = percentile(90, anemo_speed_values) as [number, number];

      if (!isEmpty(anemo_speed_values)) {
        last_weather_data.anemo_speed = {
          last: anemo_speed_values[anemo_speed_values.length - 1],
          percentile_90: anemo_speed_percentile_90,
        };
      }

      if (!isEmpty(anemo_heading_values)) {
        last_weather_data.anemo_heading = {
          last: anemo_heading_values[anemo_heading_values.length - 1],
          percentile_90: anemo_heading_values[anemo_speed_percentile_idx],
        };
      }

      return last_weather_data;
    }),
    replay()
  );

  /**
   * Observes the apiary atmospheric pressure range.
   */
  private last_weather_pressure_range$$: Observable<MinMax> = combineLatest({
    latitude: this.geoposition_latitude$$,
    elevation: this.geoposition_elevation$$,
  }).pipe(
    switchMap(({ elevation, latitude }) =>
      this.last_weather_data$$.pipe(
        map(last_weather_data => ({
          latitude,
          elevation,
          temperature: last_weather_data?.temperature?.last,
        }))
      )
    ),
    map(({ temperature, latitude, elevation }) => {
      if (isNil(temperature) || isNil(latitude) || isNil(elevation)) {
        return null;
      }

      return {
        min: computes_barometric_pressure_from_sea_pressure(1010, temperature, elevation, latitude),
        max: computes_barometric_pressure_from_sea_pressure(1015, temperature, elevation, latitude),
      };
    }),
    replay()
  );

  /** */
  public fetch_job_data$(start: Date = undefined, end: Date = undefined, step: string = undefined): Observable<RGJobData> {
    return super.requestTimeseries(['temperature', 'humidity', 'pressure', 'rain', 'anemo_speed', 'anemo_heading'], start, end, step).pipe(
      map(response => {
        const ats = response.available || [];

        return {
          has_rain_data: ats.filter((md: MeasurementDescription) => md.type === 'rain').length > 0,
          has_anemo_speed_data: ats.filter(md => md.type === 'anemo_speed')?.length > 0,
          has_temperature_data: ats.filter((md: MeasurementDescription) => md.type === 'temperature').length > 0,
          has_anemo_heading_data: ats.filter(md => md.type === 'anemo_heading')?.length > 0,

          pressure_range: null,

          points: response?.timeseries?.data?.map((point: WeatherDataPoint) => {
            point.pressure = {
              value: point.pressure as any,
              value_sea_level: null,

              zone: null,
              range_up: null,
              range_down: null,
            };

            return point;
          }),
        } as RGJobData;
      }),
      switchMap(weather_data =>
        this.last_weather_pressure_range$$.pipe(
          map(last_weather_pressure_range => {
            weather_data.pressure_range = last_weather_pressure_range;

            return weather_data;
          })
        )
      ),
      switchMap(weather_data =>
        combineLatest({ elevation: this.geoposition_elevation$$, latitude: this.geoposition_latitude$$ }).pipe(
          map(({ elevation, latitude }) => this.apply_data_transformations(weather_data, elevation, latitude))
        )
      )
    );
  }

  /** */
  public fetch_secondary_data$(start?: Date, end?: Date, step?: string): Observable<RGSecondaryData> {
    return of(<RGSecondaryData>{
      points: [],
    });
  }

  /** */
  public fetch_rssi$(start?: Date, end?: Date, step?: string): Observable<DeviceRSSIData> {
    return super.requestTimeseries(['rssi_gprs'], start, end, step).pipe(
      map(
        response =>
          <DeviceRSSIData>{
            points: response?.timeseries?.data ?? [],
          }
      )
    );
  }

  /** */
  private apply_data_transformations = (weather_data: RGJobData, elevation: number, latitude: number): RGJobData => {
    if (isEmpty(weather_data.points)) {
      return weather_data;
    }

    weather_data.points = weather_data?.points.map((point, index, self) => {
      const is_first_index = index === 0;

      // Compute pressure data
      if (!isNil(elevation) && !isNil(latitude)) {
        point.pressure.value_sea_level = !isNil(point?.pressure?.value)
          ? compute_pressure_at_sea_level(point.pressure?.value, point.temperature, elevation, latitude)
          : null;
        point.pressure.range_up = computes_barometric_pressure_from_sea_pressure(1015, point.temperature, elevation, latitude);
        point.pressure.range_down = computes_barometric_pressure_from_sea_pressure(1010, point.temperature, elevation, latitude);
        point.pressure.zone = !isNil(point?.pressure?.value)
          ? point?.pressure?.value >= point?.pressure?.range_up
            ? 'high'
            : point?.pressure?.value <= point?.pressure?.range_down
            ? 'low'
            : 'normal'
          : null;
      } else {
        point.pressure.value_sea_level = null;
        point.pressure.range_up = null;
        point.pressure.range_down = null;
        point.pressure.zone = null;
      }

      // Compute cumulative rain
      if (weather_data?.has_rain_data) {
        point.rain_cumul_day = point?.rain ?? null;

        if (!is_first_index) {
          const current_date = point.tz_date;
          const previous_date = self[index - 1].tz_date;

          if (isSameDay(current_date, previous_date)) {
            if (!isNil(point?.rain_cumul_day)) {
              point.rain_cumul_day = self[index - 1].rain_cumul_day + point.rain_cumul_day;
            }
          }
        }
      }

      return point;
    });

    return weather_data;
  };

  // #endregion

  /**
   * Use GPS location except if Cell ID is more recent, and away from last gps id
   *
   * @returns Returns the last location of the device.
   */
  get location(): DeviceLocation {
    if (isNil(this._location)) {
      if (this.location_cellids && !this.location_gps) {
        // On na que du cellid...
        this._location = this.location_cellids;
      } else if (
        this.location_cellids &&
        this.location_cellids.timestamp &&
        this.location_gps.timestamp &&
        this.location_cellids.timestamp >= this.location_gps.timestamp &&
        this.distance_gps_cellid > 2 * this.location_cellids.accuracy
      ) {
        // Cas cell id plus récent et loin de la dernière pos GPS
        this._location = this.location_cellids;
      } else {
        // sinon on prefaire le point GPS
        this._location = this.location_gps;
      }
    }
    return this._location;
  }

  get config_modal(): [string, any] {
    return ['device_config', { imei: this.imei }];
  }

  public fetchConfigurationSchema$(): Observable<any> {
    return of(IFx_SCHEMA);
  }

  public requestSimplifiedConfigurationSchema(): Observable<any> {
    return super.requestSimplifiedConfigurationSchema().pipe(
      map(schema => {
        schema.properties.sconf.properties.mode.oneOf = [
          {
            enum: ['measures'],
            label: i18n('DEVICE.ALL.CONFIG.Internal measurements'),
          },
          {
            enum: ['measures_sensors'],
            label: i18n('DEVICE.ALL.CONFIG.Internal measurements and WGuard'),
          },
        ];
        schema.properties.sconf.properties.mode.default = 'measures';
        return schema;
      })
    );
  }
}
