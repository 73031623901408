<div
  *ngLet="hive$$ | async; let hive"
  class="bg2-apiary-last-data-card-hive-component"
  [ngClass]="{ 'bg2-apiary-last-data-card-hive-component--ghost': is_hive_is_ghost$$ | async }"
>
  <div class="last-data-card-header" [ngStyle]="{ backgroundColor: (hive?.coloration$$ | async)?.tonal }">
    <div class="last-data-card-header__info">
      <div class="last-data-card-header__info__name">
        <bg2-picto-hive [hive]="hive" [size]="'55px'"></bg2-picto-hive>
        <bg2-adaptive-text-to-container
          [text_to_adapt]="hive?.name$$ | async"
          [options]="{ color: 'rgba(0, 0, 0, 0.87)' }"
        ></bg2-adaptive-text-to-container>
      </div>

      <div class="last-data-card-header__info__edit">
        <div
          *ngLet="hive.is_visible_on_chart$$ | async; let is_hive_visible_on_chart"
          class="last-data-card-header__info__edit__chart"
          (click)="hive.is_visible_on_chart = !is_hive_visible_on_chart"
          [popper]="
            is_hive_visible_on_chart
              ? ('VIEWS.APIARY.SHARED.WIDGETS.APIARY_LAST_DATA.APIARY_LAST_DATA_CARD_HIVE.Hide hive\'s data' | translate)
              : ('VIEWS.APIARY.SHARED.WIDGETS.APIARY_LAST_DATA.APIARY_LAST_DATA_CARD_HIVE.Show hive\'s data' | translate)
          "
        >
          <span class="mdi mdi-chart-multiple mdi-24px"></span>
          <span *ngIf="!is_hive_visible_on_chart" class="mdi mdi-eye-off" [style.color]="'red'"></span>
          <span *ngIf="is_hive_visible_on_chart" class="mdi mdi-eye" [style.color]="'green'"></span>
        </div>

        <span
          *ngIf="user_can_x_hive$$('write_all') | async"
          class="mdi mdi-pencil mdi-24px"
          [queryParamsHandling]="'preserve'"
          [routerLink]="['', { outlets: { modal: ['update_entity', { eid: hive?.id }] } }]"
          [popper]="'VIEWS.APIARY.SHARED.WIDGETS.APIARY_LAST_DATA.APIARY_LAST_DATA_CARD_HIVE.Edit the hive' | translate"
        ></span>
      </div>
    </div>
  </div>

  <div class="last-data-card-body" [ngStyle]="{ backgroundColor: (hive?.coloration$$ | async)?.tonal_max }">
    <div class="last-data-card-body__inner" [ngStyle]="{ transform: translate_x$$ | async }">
      <div class="last-data-card-body__lastdata-container">
        <!-- Last hive's internal temperature -->
        <div class="last-data-info last-temperature">
          <span
            class="icon mdi mdi-24px mdi-thermometer"
            [popper]="
              'VIEWS.APIARY.SHARED.APIARY_LAST_DATA.APIARY_LAST_DATA_CARD.APIARY_LAST_DATA_CARD_HIVE.Hive internal temperature' | translate
            "
          ></span>

          <span
            *ngLet="hive?.last_internal_temperature$$ | async; let last_internal_temp"
            class="value"
            [ngClass]="{ 'outdated-value': hive?.is_internal_temperature_outdated_by_48h$$ | async }"
          >
            <ng-container *ngIf="(last_internal_temp ?? null) === null; else tmpLastInternalTemperatureExists">
              <span>--</span>
            </ng-container>

            <ng-template #tmpLastInternalTemperatureExists>
              <ng-container *ngIf="(last_internal_temp?.value ?? null) === null">
                <span class="minimal-value num-value" [popper]="lastInternalTempMin">{{ last_internal_temp?.min | fixed : 1 }}</span>
                <span>/</span>
                <span class="maximal-value num-value" [popper]="lastInternalTempMax">{{ last_internal_temp?.max | fixed : 1 }}</span>
                <span>&nbsp;°C</span>

                <popper-content #lastInternalTempMin>
                  <span>
                    {{ 'ALL.DATA.SPEC.TEMPERATURE.Minimale temperature over 24 hours' | translate }}
                    ({{ 'ALL.COMMON.Taken on [date]' | translate : { date: last_internal_temp?.timestamp_for_min | formatDate : 'full' } }})
                  </span>
                </popper-content>

                <popper-content #lastInternalTempMax>
                  <span>
                    {{ 'ALL.DATA.SPEC.TEMPERATURE.Maximale temperature over 24 hours' | translate }}
                    ({{ 'ALL.COMMON.Taken on [date]' | translate : { date: last_internal_temp?.timestamp_for_max | formatDate : 'full' } }})
                  </span>
                </popper-content>
              </ng-container>

              <ng-container *ngIf="(last_internal_temp?.value ?? null) !== null">
                <span class="num-value">{{ last_internal_temp?.value | fixed : 1 }}</span>

                <span>&nbsp;°C</span>
              </ng-container>

              <span
                *ngIf="hive?.is_internal_temperature_outdated_by_48h$$ | async"
                class="mdi mdi-clock value-outdated-icon"
                [popper]="'VIEWS.APIARY.SHARED.APIARY_LAST_DATA.Value has not been updated since 48 hours' | translate"
              ></span>
            </ng-template>
          </span>
        </div>

        <!-- Last hive's internal humidity -->
        <div class="last-data-info last-humidity">
          <span
            class="icon mdi mdi-24px mdi-water-percent"
            [popper]="
              'VIEWS.APIARY.SHARED.APIARY_LAST_DATA.APIARY_LAST_DATA_CARD.APIARY_LAST_DATA_CARD_HIVE.Hive internal humidity' | translate
            "
          ></span>

          <span
            *ngLet="hive?.last_internal_humidity$$ | async; let last_internal_humidity"
            class="value"
            [ngClass]="{ 'outdated-value': hive?.is_internal_humidity_outdated_by_48h$$ | async }"
          >
            <ng-container *ngIf="(last_internal_humidity ?? null) === null; else tmpLastInternalHumidityExists">
              <span>--</span>
            </ng-container>

            <ng-template #tmpLastInternalHumidityExists>
              <ng-container *ngIf="(last_internal_humidity?.value ?? null) === null">
                <span class="minimal-value num-value" [popper]="lastInternalHumMin">{{ last_internal_humidity?.min | fixed : 1 }}</span>
                <span>/</span>
                <span class="maximal-value num-value" [popper]="lastInternalHumMax">{{ last_internal_humidity?.max | fixed : 1 }}</span>
                <span>&nbsp;%</span>

                <popper-content #lastInternalHumMin>
                  <span>
                    {{ 'ALL.DATA.SPEC.HUMIDITY.Minimale humidity over 24 hours' | translate }}
                    ({{
                      'ALL.COMMON.Taken on [date]' | translate : { date: last_internal_humidity?.timestamp_for_min | formatDate : 'full' }
                    }})
                  </span>
                </popper-content>

                <popper-content #lastInternalHumMax>
                  <span>
                    {{ 'ALL.DATA.SPEC.HUMIDITY.Maximale humidity over 24 hours' | translate }}
                    ({{
                      'ALL.COMMON.Taken on [date]' | translate : { date: last_internal_humidity?.timestamp_for_max | formatDate : 'full' }
                    }})
                  </span>
                </popper-content>
              </ng-container>

              <ng-container *ngIf="(last_internal_humidity?.value ?? null) !== null">
                <span class="num-value">{{ last_internal_humidity?.value | fixed : 1 }}</span>

                <span>&nbsp;%</span>
              </ng-container>

              <span
                *ngIf="hive?.is_internal_humidity_outdated_by_48h$$ | async"
                class="mdi mdi-clock value-outdated-icon"
                [popper]="'VIEWS.APIARY.SHARED.APIARY_LAST_DATA.Value has not been updated since 48 hours' | translate"
              ></span>
            </ng-template>
          </span>
        </div>

        <!-- Last hive's measured weight -->
        <div class="last-data-info last-weight">
          <span
            class="icon mdi mdi-24px mdi-weight"
            [popper]="'VIEWS.APIARY.SHARED.APIARY_LAST_DATA.APIARY_LAST_DATA_CARD.APIARY_LAST_DATA_CARD_HIVE.Hive weight' | translate"
          ></span>

          <span
            *ngLet="hive?.last_weight$$ | async; let last_weight"
            class="value"
            [ngClass]="{ 'outdated-value': hive?.is_weight_outdated_by_48h$$ | async }"
          >
            <ng-container *ngIf="(last_weight ?? null) === null; else tmpLastWeightExists">
              <span>--</span>
            </ng-container>

            <ng-template #tmpLastWeightExists>
              <span class="num-value" [popper]="'ALL.DATA.SPEC.WEIGHT.Last measured weight' | translate">{{
                last_weight?.value | fixed : 1
              }}</span>

              <span>&nbsp;kg</span>

              <span *ngIf="last_weight?.value <= 10" [popper]="tplLowWeightData" [popperTimeout]="100" class="mdi mdi-help-circle"></span>

              <popper-content #tplLowWeightData>
                <span>{{ 'VIEWS.LASTDATA.Below 10kg, measure may be inaccurate' | translate }}.&ensp;</span>
                <span
                  *ngIf="(language$$ | async) === 'fr'"
                  class="simili-link"
                  [routerLink]="[
                    '',
                    {
                      outlets: {
                        modal: [
                          'user_manual',
                          { help: 'faq.html#il-ny-a-aucune-ruche-sur-la-balance-et-elle-maffiche-un-poids-positif-est-ce-normal-' }
                        ]
                      }
                    }
                  ]"
                  [queryParamsHandling]="'preserve'"
                >
                  {{ 'ALL.COMMON.Learn more' | translate }}
                </span>
              </popper-content>

              <span
                *ngIf="hive?.is_weight_outdated_by_48h$$ | async"
                class="mdi mdi-clock value-outdated-icon"
                [popper]="'VIEWS.APIARY.SHARED.APIARY_LAST_DATA.Value has not been updated since 48 hours' | translate"
              ></span>
            </ng-template>
          </span>
        </div>

        <!-- Last hive's measured beecount -->
        <div *ngIf="(hive?.has_device_CPT$$ | async) || (hive?.has_device_beelive$$ | async)" class="last-data-info last-beecount">
          <span
            class="icon mdi mdi-24px mdi-counter"
            [popper]="
              'VIEWS.APIARY.SHARED.WIDGETS.APIARY_LAST_DATA.APIARY_LAST_DATA_CARD.APIARY_LAST_DATA_CARD_HIVE.Bees count' | translate
            "
          ></span>

          <span
            *ngLet="hive?.last_beecount$$ | async; let last_beecount"
            class="value"
            [popper]="outcomeBees"
            [popperApplyClass]="'apiary-last-data-card-hive__beecount__popper'"
          >
            <img src="assets/img/pictos/beehive-in-out.svg" alt="" />

            <span class="value-content">
              <span class="current-day-value">
                <ng-container
                  *ngIf="last_beecount?.day_m1?.count_in !== null && last_beecount?.day_m1?.count_out !== null; else emptyValue"
                >
                  <span>
                    {{ 'J-1' | colon }}<b>{{ last_beecount?.day_m1?.count_in + last_beecount?.day_m1?.count_out | abbreviateNumber }}</b>
                  </span>
                  <span class="mdi mdi-bee"></span>
                </ng-container>
              </span>

              <span class="previous-day-value">
                <ng-container
                  *ngIf="last_beecount?.day_m2?.count_in !== null && last_beecount?.day_m2?.count_out !== null; else emptyValue"
                >
                  <span>
                    {{ 'J-2' | colon }}<b>{{ last_beecount?.day_m2?.count_in + last_beecount?.day_m2?.count_out | abbreviateNumber }}</b>
                  </span>
                  <span class="mdi mdi-bee"></span>
                </ng-container>
              </span>
            </span>

            <!-- <span>/</span> -->

            <!-- <span class="num-value">
              <img src="assets/img/pictos/beehive-outcome.svg" alt="" />

              <span class="value-content">
                <span class="current-day-value">
                  <ng-container *ngIf="last_beecount?.day_m1?.count_out !== null; else emptyValue">
                    -{{ last_beecount?.day_m1?.count_out | abbreviateNumber }}
                  </ng-container>
                </span>

                <span class="previous-day-value">
                  <ng-container *ngIf="last_beecount?.day_m2?.count_out !== null; else emptyValue">
                    (J-2)(-{{ last_beecount?.day_m2?.count_out | abbreviateNumber }})
                  </ng-container>
                </span>
              </span>
            </span> -->

            <popper-content #outcomeBees>
              <span>
                {{
                  'VIEWS.APIARY.SHARED.WIDGETS.APIARY_LAST_DATA.APIARY_LAST_DATA_CARD.APIARY_LAST_DATA_CARD_HIVE.BEECOUNT.Total entries/exits over the day'
                    | translate
                }}
              </span>

              <span>
                {{
                  'VIEWS.APIARY.SHARED.WIDGETS.APIARY_LAST_DATA.APIARY_LAST_DATA_CARD.APIARY_LAST_DATA_CARD_HIVE.BEECOUNT.[x] bees entered yesterday ([y] 2 days ago)'
                    | translate
                      : {
                          x: last_beecount?.day_m1?.count_in ?? '--',
                          y: last_beecount?.day_m2?.count_in ?? '--'
                        }
                }}
              </span>

              <span>
                {{
                  'VIEWS.APIARY.SHARED.WIDGETS.APIARY_LAST_DATA.APIARY_LAST_DATA_CARD.APIARY_LAST_DATA_CARD_HIVE.BEECOUNT.[x] bees exited yesterday ([y] 2 days ago)'
                    | translate
                      : {
                          x: last_beecount?.day_m1?.count_out ?? '--',
                          y: last_beecount?.day_m2?.count_out ?? '--'
                        }
                }}
              </span>
            </popper-content>
          </span>
        </div>
      </div>

      <div *ngLet="hive?.devices$$ | async; let devices" class="last-data-card-body__devices-container">
        <ng-container *ngFor="let device of devices; let index = index; trackBy: track_by_device">
          <div class="device-details">
            <bg2-device-inline [device]="device" [show_status]="true" [show_action]="true" [show_last_com]="false"></bg2-device-inline>
          </div>

          <mat-divider *ngIf="index + 1 < devices?.length"></mat-divider>
        </ng-container>
      </div>
    </div>
  </div>

  <div class="last-data-card-footer" [ngStyle]="{ backgroundColor: (hive?.coloration$$ | async)?.tonal }">
    <div *ngLet="hive?.devices_last_com$$ | async; let hive_last_com_date" class="last-data-card-footer__lastcom">
      <span [popper]="hiveLastComTooltip">
        <span>{{ 'VIEWS.APIARY.SHARED.WIDGETS.APIARY_LAST_DATA.APIARY_LAST_DATA_CARD.Last com' | translate | colon }}</span>
        <span *ngIf="hive_last_com_date; else loadingHiveLastCom">{{ hive_last_com_date | distanceToNow }}</span>
      </span>

      <ng-template #loadingHiveLastCom>
        <bg2-loading-skeleton [string_model]="'today'" [theme]="{ height: '18px', width: '100%' }"></bg2-loading-skeleton>
      </ng-template>

      <popper-content #hiveLastComTooltip>
        <span>{{ 'VIEWS.APIARY.SHARED.WIDGETS.APIARY_LAST_DATA.APIARY_LAST_DATA_CARD.Last com' | translate | colon }}</span>
        <span *ngIf="hive_last_com_date">{{ hive_last_com_date | formatDate : 'full' }}</span>
      </popper-content>
    </div>

    <div class="navigation_and_states">
      <div class="switch-lastdata-devices">
        <span *ngIf="current_index === 0" (click)="current_index = -1">
          <ng-container *ngLet="(hive?.devices$$ | async)?.length; let total_hive_devices">
            {{
              (total_hive_devices > 1
                ? 'VIEWS.APIARY.SHARED.WIDGETS.APIARY_LAST_DATA.APIARY_LAST_DATA_CARD.See [x] device(s)'
                : 'VIEWS.APIARY.SHARED.WIDGETS.APIARY_LAST_DATA.APIARY_LAST_DATA_CARD.See the device'
              ) | translate : { x: total_hive_devices }
            }}
          </ng-container>
        </span>
        <span *ngIf="current_index === -1" (click)="current_index = 0">
          {{ 'VIEWS.APIARY.SHARED.WIDGETS.APIARY_LAST_DATA.APIARY_LAST_DATA_CARD.Last data' | translate }}
        </span>
      </div>

      <div class="worst-states">
        <!-- Battery level -->
        <ng-container *ngIf="hive">
          <!-- <bg2-battery-level [status]="hive?.devices_worst_state_battery$$ | async"></bg2-battery-level> -->
          <bg2-battery-level-simplified
            [open_in_modal]="false"
            [simple_state]="hive?.devices_worst_state_battery_simplified$$ | async"
          ></bg2-battery-level-simplified>
        </ng-container>

        <!-- GPS level -->
        <ng-container *ngIf="hive">
          <bg2-gps-level [open_in_modal]="false" [status]="hive?.devices_worst_state_gps$$ | async"></bg2-gps-level>
        </ng-container>

        <!-- GPRS level -->
        <ng-container *ngIf="hive">
          <bg2-gprs-level [open_in_modal]="false" [status]="hive?.devices_worst_state_gprs$$ | async"></bg2-gprs-level>
        </ng-container>

        <!-- 868 level -->
        <ng-container *ngIf="hive">
          <bg2-s868-level [open_in_modal]="false" [status]="hive?.devices_worst_state_868$$ | async"></bg2-s868-level>
        </ng-container>
      </div>
    </div>
  </div>
</div>

<ng-template #emptyValue>--</ng-template>
