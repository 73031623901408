import {
  Component,
  ChangeDetectionStrategy,
  Output,
  Input,
  EventEmitter,
  OnDestroy,
  HostBinding,
  Optional,
  Self,
  ElementRef,
  Inject,
  ViewChild,
} from '@angular/core';
import { ControlValueAccessor, UntypedFormBuilder, NgControl } from '@angular/forms';
import { MatFormField, MatFormFieldControl, MAT_FORM_FIELD } from '@angular/material/form-field';
import { FocusMonitor } from '@angular/cdk/a11y';
import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';

import { Observable, BehaviorSubject, of, Subscription, combineLatest, Subject, forkJoin, take } from 'rxjs';
import { map, switchMap, distinctUntilChanged, debounceTime, tap } from 'rxjs';

import { assign, concat, flatten, isEmpty, isNil } from 'lodash-es';

import { TranslateService } from '@ngx-translate/core';
import { marker as i18n } from '@biesbjerg/ngx-translate-extract-marker';

import { UsersApiService } from 'app/core';
import { replay } from '@bg2app/tools/rxjs';

import { User } from 'app/models';
import { distinctUntilRealChanged } from '@bg2app/tools/rxjs';
import { Dictionary } from 'app/typings/core/interfaces';
import { ConsoleLoggerService } from 'app/core/console-logger.service';
import { sum } from 'd3';
import { MtxSelectComponent } from '@ng-matero/extensions/select';

/**
 * Interface of device options for ng-select
 */
export interface UserSelectOption {
  no_impersonate?: boolean;
  readonly?: boolean;
  exclude_ids?: number[];
  label?: string;
}

export class _UserSelectOption implements UserSelectOption {
  readonly: boolean = false;
  exclude_ids: number[] = [];
  no_impersonate: boolean = false;
  label: string = i18n('WIDGETS.MISC_WIDGETS.USER_SELECT.Select one user');

  public update(other: UserSelectOption): UserSelectOption {
    Object.assign(this, other);
    return this;
  }
}

@Component({
  selector: 'bg2-user-select',
  templateUrl: './user-select.component.html',
  styleUrls: ['./user-select.component.scss'],
  providers: [{ provide: MatFormFieldControl, useExisting: Bg2UserSelectComponent }],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class Bg2UserSelectComponent implements ControlValueAccessor, MatFormFieldControl<number>, OnDestroy {
  private readonly _logger = new ConsoleLoggerService('Bg2SUserSelect', false);
  private readonly LOAD_ONLY = ['username', 'first_name', 'last_name', 'user_id'];

  private _user_id_sub: Subscription;

  constructor(
    private _formBuilder: UntypedFormBuilder,
    private _focusMonitor: FocusMonitor,
    private _elementRef: ElementRef<HTMLElement>,
    @Optional() @Inject(MAT_FORM_FIELD) public _formField: MatFormField,
    @Optional() @Self() public ngControl: NgControl,
    private userApi: UsersApiService,
    private _translate: TranslateService
  ) {
    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }
    this._user_id_sub = this.user_id$$.subscribe(user_id => {
      this.valueChange.emit(user_id);
      this.onChange(user_id);
    });
  }

  // #region -> (selector reference)

  @Output()
  public selector_ref = new EventEmitter<MtxSelectComponent>();

  @ViewChild('userSelect', {})
  public set select_ref(select: MtxSelectComponent) {
    this.selector_ref.emit(select);
  }

  // #endregion

  /** */
  onChange = (_: any) => {};

  /** */
  onTouched = () => {};

  writeValue(user_id: number): void {
    this._logger.debug('writeValue', user_id);
    this.value = user_id;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  stateChanges = new Subject<void>();

  static nextId = 0;

  @HostBinding() id = `bg2-user-select-${Bg2UserSelectComponent.nextId++}`;

  @Input()
  get placeholder() {
    return this._placeholder;
  }
  set placeholder(plh) {
    this._placeholder = plh;
    this.stateChanges.next();
  }
  private _placeholder: string;

  private touched = false;
  public focused = false;

  onFocusIn(event: FocusEvent) {
    if (!this.focused) {
      this.focused = true;
      this.stateChanges.next();
    }
  }

  onFocusOut(event: FocusEvent) {
    if (!this._elementRef.nativeElement.contains(event.relatedTarget as Element)) {
      this.touched = true;
      this.focused = false;
      this.onTouched();
      this.stateChanges.next();
    }
  }

  get empty() {
    return isNil(this.value);
  }

  @HostBinding('class.floating')
  get shouldLabelFloat() {
    return this.focused || !this.empty;
  }

  private _required = false;

  /** */
  @Input()
  public get required(): boolean {
    return this._required;
  }

  /** */
  public set required(value: BooleanInput) {
    this._required = coerceBooleanProperty(value);
    this.stateChanges.next();
  }

  /** */
  private _disabled = false;

  /** */
  @Input()
  public get disabled(): boolean {
    return this._disabled;
  }

  /** */
  public set disabled(value: BooleanInput) {
    this._disabled = coerceBooleanProperty(value);
    this.stateChanges.next();
  }

  get errorState(): boolean {
    return false && this.touched;
  }

  controlType = 'bg2-user-select';

  autofilled?: boolean;

  @Input('aria-describedby') userAriaDescribedBy: string;

  setDescribedByIds(ids: string[]) {
    const control_element = this._elementRef.nativeElement.querySelector('.bg2-user-select-container')!;
    control_element.setAttribute('aria-describedby', ids.join(' '));
  }

  onContainerClick(event: MouseEvent): void {
    //throw new Error('Method not implemented.');
  }

  ngOnDestroy() {
    this.stateChanges.complete();
    this._user_id_sub?.unsubscribe();
  }

  private _user_id$$ = new BehaviorSubject<number>(null);
  public user_id$$ = this._user_id$$.pipe(distinctUntilRealChanged(), replay());

  public get value(): number | null {
    return this._user_id$$.getValue();
  }

  @Input()
  public set value(_user_id: number | null) {
    this._logger.debug('set value', _user_id);
    this._user_id$$.next(_user_id);
    this.stateChanges.next();
  }

  @Output()
  public valueChange = new EventEmitter<number>();

  private _options$$ = new BehaviorSubject<_UserSelectOption>(new _UserSelectOption());

  @Input()
  public set options(_options: UserSelectOption) {
    const options = this._options$$.getValue();
    _options = options.update(_options);
    this._options$$.next(options);
  }

  public get options(): UserSelectOption {
    return this._options$$.getValue();
  }

  public label$$ = this._options$$.pipe(
    map(options => options.label),
    replay()
  );

  public raw_filter$$ = new BehaviorSubject<string>('');

  /**
   * Observable on the device query.
   */
  public query$$ = this.raw_filter$$.pipe(
    distinctUntilRealChanged(),
    map(raw_filter => {
      const query: Dictionary<any> = {};
      if (!isNil(raw_filter)) {
        query._additional_filters_or = this.userApi.getNameFiltersOr(raw_filter);
      }
      return query;
    }),
    replay()
  );

  /**
   * Observable on the max displayed devices.
   */
  public limit$$ = of(10).pipe(replay());

  public selected_users$$ = combineLatest({
    user_id: this.user_id$$,
    options: this._options$$,
  }).pipe(
    switchMap(({ user_id, options }) => {
      if (isNil(user_id)) {
        return of(null);
      }
      return this.userApi.fetch_users$({ user_id }, undefined, undefined, options.no_impersonate, this.LOAD_ONLY);
    }),
    replay()
  );

  public selected_user_name$$ = this.selected_users$$.pipe(switchMap(res => res?.users[0]?.name$$ || of(null)));

  private request_users$$ = combineLatest({
    user_id: this.user_id$$,
    query: this.query$$,
    limit: this.limit$$,
    options: this._options$$,
  }).pipe(
    // TODO: Make it into a device API method
    distinctUntilRealChanged(),
    debounceTime(400),
    // tap(query => console.log(query)),
    tap(() => (this.loading = true)),
    switchMap(({ user_id, query, limit, options }) => {
      // this._logger.debug(`query: ${query} imeis: ${imeis} limit: ${limit}`);
      const _paging = {
        offset: 0,
        limit: limit,
      };

      const sort = ['first_name', 'last_name', 'username'];
      const params = {
        query,
        limit,
        user_id,
      };

      // Get options
      const no_impersonate = options.no_impersonate || false;
      const exclude_ids = concat(options.exclude_ids || []).filter(value => !isNil(value) || value !== '');

      if (exclude_ids?.length > 0) {
        query = assign(query, { user_id__not__in: exclude_ids });
      }

      const requests = [this.userApi.fetch_users$(query, _paging, sort, no_impersonate, this.LOAD_ONLY).pipe(take(1))];
      if (user_id) {
        requests.unshift(this.selected_users$$.pipe(take(1)));
      }

      return forkJoin(requests).pipe(
        tap(() => (this.loading = false)),
        // tap(res => this._logger.info(res)),
        map(responses => {
          const users = flatten(responses.filter(res => !isNil(res)).map(res => res.users));
          const paging = {
            total: sum(responses.map(res => res.paging.total)),
            offset: responses[responses.length - 1].paging.offset,
            limit: responses[responses.length - 1].paging.limit,
          };
          return { users, paging };
        }),
        map(res => ({
          users: res.users,
          paging: res.paging,
          params,
        }))
      );
    }),
    replay()
  );

  /**
   * Observable on the total of devices fetched by the request.
   */
  public total$$ = this.request_users$$.pipe(
    map(({ paging }) => paging.total),
    distinctUntilRealChanged(),
    replay()
  );

  public items$$: Observable<{ user: User; user_id: number; name: string }[]> = this.request_users$$.pipe(
    map(({ users }) =>
      users.map(user => ({
        user: user,
        name: user.name,
        user_id: user.user_id,
      }))
    ),
    replay()
  );

  public user_index$$ = this.items$$.pipe(
    map(items => {
      const _users: Dictionary<any> = {};
      items.map(item => {
        _users[item.user.user_id] = item.user;
      });
      return _users;
    }),
    replay()
  );

  private _is_loading$$ = new BehaviorSubject<boolean>(false);
  public is_loading$$ = this._is_loading$$.asObservable().pipe(debounceTime(100), distinctUntilChanged(), replay());

  set loading(is_loading: boolean) {
    this._is_loading$$.next(is_loading);
  }

  private _is_searchable$$ = new BehaviorSubject(true);
  public is_searchable$$ = this._is_searchable$$.asObservable().pipe(distinctUntilRealChanged(), replay());

  public select_loading_sentence$$ = combineLatest({
    users: this.items$$,
    total: this.total$$,
  }).pipe(
    switchMap(({ users, total }) =>
      this._translate.stream(i18n('WIDGETS.MISC_WIDGETS.USER_SELECT.[loaded] out of [total] users'), {
        loaded: users?.length,
        total: total,
      })
    ),
    distinctUntilRealChanged(),
    replay()
  );

  // #region -> (helpers)

  /** */
  public assert_user(value: any): User {
    if (value instanceof User) {
      return value;
    }

    return null;
  }

  // #endregion
}
