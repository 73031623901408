<div class="bg-dialog">
  <dialog-header>
    <span translate>VIEWS.DEVICES.OTA-DIALOG.Program bulk OTA</span>
  </dialog-header>

  <dialog-body>
    <hex-loading *ngIf="schema_loading$$ | async"></hex-loading>
    <!-- <div>
      <ul>
        <li *ngFor="let status of (devices_ota_status$$ | async)">
          <pre>{{status | json}}</pre>
        </li>
      </ul>
    </div> -->

    <div *ngIf="schema$$ | async; let supports_schema">
      <sf-form
        [schema]="supports_schema"
        (isValid)="setFormValid($event)"
        [model]="form_model$$ | async"
        (onErrorChange)="logErrors($event.value)" 
        (onChange)="onFormModelChanged($event)">
      </sf-form>
    </div>
    <div *ngIf="selected_fw$$ | async; let fw" class="selected-fw-details">
      <h3>{{fw.filename}}</h3>
      <p>
        Hardware Version: {{fw.hardware_version}} <br />
        Software Version: {{fw.software_version}} <br />
        Size: {{fw.size}} bytes<br />
        Date: {{fw.created_at | formatDate:'full' }} <br />
        See  <a href="{{fw.tag_url}}" target="_blank">release notes</a>
      </p>
      <hr>
      <p>
        is_compatible: {{fw.is_compatible}} <br />
        on_ota_srv: {{fw.on_ota_srv}} <br />
      </p>
    </div>
  </dialog-body>

  <div class="dialog-footer">
    <bg2-button
      [type]="'cancel'"
      [message]="'VIEWS.MODALS.FORM.Cancel' | translate"
      (btn_click)="close(false)"
    >
    </bg2-button>
    <bg2-button
      [type]="'action'"
      [icon]="'mdi-content-save'"
      [message]="'VIEWS.MODALS.FORM.Save' | translate"
      [disabled]="((form_valid$$ | async) === false) || (submit_in_progress$$ | async)"
      [loading]="submit_in_progress$$ | async"
      (btn_click)="submit()"
    >
    </bg2-button>
    <mat-progress-bar [value]="submit_progress_percent$$ | async" *ngIf="submit_progress_total$$ | async"></mat-progress-bar>
  </div>
</div>
