import { isEmpty, isNil } from 'lodash-es';

import { BehaviorSubject, map } from 'rxjs';
import { replay } from '@bg2app/tools/rxjs';

import { IZohoCRMAccount } from '../interfaces';
import { ZohoBaseModel } from '../../../../abstract';

/** */
export class ZohoCRMAccount extends ZohoBaseModel<IZohoCRMAccount> {
  // #region -> (model basics)

  constructor() {
    super();
  }

  // #endregion

  // #region -> (account infos)

  /** */
  protected _account_name$$ = new BehaviorSubject<string>(null);

  /** */
  public account_name$$ = this._account_name$$.asObservable();

  /** */
  public set Account_Name(account_name: string) {
    this._account_name$$.next(account_name);
  }

  /** */
  public get Account_Name(): string {
    return this._account_name$$.getValue();
  }

  // #endregion

  // #region -> (account additional infos)

  /** */
  protected _exploitation_id$$ = new BehaviorSubject<string>(null);

  /** */
  public exploitation_id$$ = this._exploitation_id$$.asObservable();

  /** */
  public set exploitation_id(exploitation_id: string) {
    this._exploitation_id$$.next(exploitation_id);
  }

  /** */
  public get exploitation_id(): string {
    return this._exploitation_id$$.getValue();
  }

  /** */
  protected _description$$ = new BehaviorSubject<string>(null);

  /** */
  public description$$ = this._description$$.asObservable();

  /** */
  public set Description(Description: string) {
    this._description$$.next(Description);
  }

  /** */
  public get Description(): string {
    return this._description$$.getValue();
  }

  // #endregion

  // #region -> (links)

  /** */
  public url$$ = this.id$$.pipe(
    map(id => {
      if (isNil(id) || isEmpty(id)) {
        return null;
      }

      return `https://crm.zoho.eu/crm/org20067795631/tab/Accounts/${id}`;
    }),
    replay()
  );

  /** */
  public url_notes$$ = this.url$$.pipe(
    map(url => {
      if (isNil(url) || isEmpty(url)) {
        return null;
      }

      return `${url}#noteDetails`;
    }),
    replay()
  );

  /** */
  public url_potentials$$ = this.url$$.pipe(
    map(url => {
      if (isNil(url) || isEmpty(url)) {
        return null;
      }

      return `${url}#potentialspersonality_219345000000013165`;
    }),
    replay()
  );

  // #endregion
}
