import { AbstractMarker, MapMarkerType } from 'app/typings/mapping';
import { featureGroup } from 'leaflet';
import { groupBy, isNil, property } from 'lodash-es';

/**
 * This method is used to group markers by internal type.
 *
 * @param markers The list of markers to group by.
 * ```typescript
 * // The returned result is a dictionnary with keys of `MapMarkerType`:
 * { location: [...], location_archived: [...], device_rg: [...], ... }
 * ```
 */
export const groupMarkersByType = (markers: L.Marker[], sub_map?: 'circle') => {
  const initial: { [key in MapMarkerType]: AbstractMarker[] } = groupBy(markers, property('type')) as any;

  if (!isNil(sub_map)) {
    Object.keys(initial).forEach(
      key => ((initial as any)[key] = featureGroup((initial as any)[key].map((m: AbstractMarker) => m[sub_map])))
    );
  }

  return initial;
};
