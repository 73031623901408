<div *ngLet="device$$ | async; let device" class="bg2-device-modal-geoposition-component">
  <!-- CARD: Geoposition -->
  <div
    *ngLet=""
    class="device-card-template"
    [ngStyle]="{
      'padding-bottom': ((has_movement$$ | async) ? (device_movement_div_size?.value$$ | async)?.new_height + 20 ?? 0 : 0) + 'px'
    }"
  >
    <div class="device-card-template__special-container">
      <bg2-device-position
        [device]="device"
        [height]="'100%'"
        [delay]="5000"
        [prefer_position_type]="selected_view_type$$ | async"
      ></bg2-device-position>
    </div>

    <div class="device-card-template__normal-container">
      <div class="device-detail__title-container">
        <div class="title-container__icon --color-blue">
          <span class="mdi mdi-map-marker mdi-36px"></span>
        </div>

        <div class="title-container__title">
          {{ 'VIEWS.DEVICES.DIALOGS_AND_MODALS.DEVICE_MODAL.DETAILS.GEOLOCATION.Geolocation & routes' | translate }}
        </div>
      </div>

      <div *ngLet="device; let device" class="device-modal-card-container__body-container">
        <ng-container *ngLet="device_has_oudated_com$$ | async; let device_has_oudated_com">
          <bg2-ui-message
            *ngIf="device_has_oudated_com?.outdated"
            [type]="'warning'"
            [title]="'DEVICE.ALL.GEOPOSITION.OUTDATED.Outdated position' | translate"
          >
            <div message>
              <span>
                {{
                  'DEVICE.ALL.GEOPOSITION.OUTDATED.No communication since [date]'
                    | translate : { date: device_has_oudated_com?.since | formatDate : 'full' }
                }}
              </span>

              <span>{{ 'DEVICE.ALL.GEOPOSITION.OUTDATED.Since its last known position, the device might have moved' | translate }}</span>
            </div>
          </bg2-ui-message>
        </ng-container>

        <div *ngLet="device?.has_geoposition$$ | async; let has_geoposition" class="body-container__properties">
          <ng-container *ngIf="has_geoposition">
            <!-- Geoposition type -->
            <div *ngLet="selected_view_type$$ | async; let selected_view_type_value" class="body-container__property-container">
              <div class="device-details-properties__property-container__label-container">
                <span class="mdi mdi-map-marker-question-outline mdi-24px"></span>
                <b>{{ 'WIDGETS.DIALOGS_MODALS.DIALOGS.ERROR.Type' | translate | colon }}</b>
              </div>

              <span class="device-details-properties__property-container__value">
                <span
                  class="mdi mdi-24px"
                  [ngClass]="{
                    'mdi-satellite-variant': selected_view_type_value === 'GPS',
                    'mdi-transmission-tower': selected_view_type_value === 'CELLIDS'
                  }"
                ></span>
                <span>{{ selected_view_type_value }}</span>
              </span>

              <span
                *ngIf="selected_view_type_value === 'CELLIDS'"
                class="device-details-properties__property-container__value"
                [popper]="'VIEWS_WINDOWED.MODALS.DEVICE_MODAL.Expect poor accuracy because of the cellular network' | translate"
              >
                <span class="mdi mdi-24px mdi-alert-outline red-text"></span>
              </span>

              <span
                *ngIf="(has_geoposition_gps$$ | async) && has_geoposition_cellids$$ | async"
                class="device-details-properties__property-container__value"
              >
                <span>(</span>
                <span class="clickable simili-link" (click)="selected_view_type = selected_view_type_value === 'GPS' ? 'CELLIDS' : 'GPS'">
                  <span class="mdi mdi-swap-horizontal"></span>
                  <span *ngIf="selected_view_type_value === 'CELLIDS'">GPS</span>
                  <span *ngIf="selected_view_type_value === 'GPS'">CELLIDS</span>
                </span>
                <span>)</span>
              </span>
            </div>

            <!-- Geoposition date -->
            <div *ngLet="device_position_from_type$$ | async; let device_position_from_type" class="body-container__property-container">
              <div class="device-details-properties__property-container__label-container">
                <span class="mdi mdi-clock mdi-24px"></span>
                <b>{{ 'DEVICE.ALL.GEOPOSITION.Position taken on' | translate | colon }}</b>
              </div>

              <span class="device-details-properties__property-container__value">
                <span>{{ device_position_from_type?.timestamp | formatDate : 'full' }}</span>
              </span>
            </div>

            <!-- Geoposition accuracy -->
            <div *ngLet="device_position_from_type$$ | async; let device_position_from_type" class="body-container__property-container">
              <div class="device-details-properties__property-container__label-container">
                <span class="mdi mdi-target mdi-24px"></span>
                <b>{{ 'DEVICE.ALL.GEOPOSITION.Accuracy' | translate | colon }}</b>
              </div>

              <span class="device-details-properties__property-container__value">
                <span>{{ device_position_from_type?.accuracy | fixed : 1 }} m</span>
              </span>
            </div>
          </ng-container>

          <ng-container *ngIf="has_geoposition === false">
            <bg2-ui-message [type]="'alert'" [title]="'DEVICE.ALL.GEOPOSITION.ERROR.No geo-position data' | translate">
              <div message>
                <span>{{ 'DEVICE.ALL.GEOPOSITION.ERROR.There is no geo-position data available for this device' | translate }}</span>
              </div>
            </bg2-ui-message>
          </ng-container>
        </div>

        <!-- Position actions -->
        <div>
          <ng-container *ngIf="device?.type === 'GPS'">
            <bg2-button
              *ngLet="device?.user_acl?.can$$('read_devices_routes') | async; let can_read_devices_routes"
              [type]="'action'"
              [color]="'device'"
              [options]="{ is_thin: true }"
              [disabled]="!can_read_devices_routes"
              [message]="'VIEWS.MODALS.DEVICE.Routes' | translate"
              [icon]="can_read_devices_routes ? 'mdi-map-marker-path' : 'cs-/img/pictos/lock.svg'"
              [internal_routing]="{
                queryParamsHandling: 'preserve',
                routerLink: ['', { outlets: { modal: ['route_tracer', { device_imei: device_imei$$ | async }] } }]
              }"
            ></bg2-button>

            <bg2-button
              *ngLet="device?.user_acl?.can$$('write_move_authorization') | async; let can_write_move_authorization"
              [type]="'action'"
              [color]="'device'"
              [options]="{ is_thin: true }"
              [disabled]="!can_write_move_authorization"
              [message]="'VIEWS.MODALS.DEVICE.Movements authorization' | translate"
              [icon]="can_write_move_authorization ? 'mdi-cursor-move' : 'cs-/img/pictos/lock.svg'"
              [internal_routing]="{
                queryParamsHandling: 'preserve',
                routerLink: ['', { outlets: { modal: ['device_move_auth', { imeis: ([device_imei$$ | async] | json) }] } }]
              }"
            ></bg2-button>
          </ng-container>
        </div>
      </div>

      <!-- <span class="spacer"></span> -->

      <!-- <div class="device-detail__open-detail-container">
          <bg2-button
            [type]="'navigation'"
            [icon]="'mdi-magnify'"
            [options]="{ is_thin: true }"
            [styles]="{ padding: '2px 5px', margin: '0px' }"
            [message]="'VIEWS.DEVICES.DIALOGS_AND_MODALS.DEVICE_MODAL.DETAILS.MISC.More details' | translate"
            (btn_click)="open_detail.next(true)"
          ></bg2-button>
        </div> -->
    </div>

    <!-- Device movement alarm -->
    <div
      *ngIf="(has_movement$$ | async) && is_device_is_gps$$ | async"
      class="device-movement-alarm"
      (resized)="device_movement_div_size.value = $event"
    >
      <ng-container *ngLet="is_recent_movement$$ | async; let is_recent_movement">
        <span *ngIf="is_recent_movement">
          <span>
            {{ 'VIEWS.DEVICES.DIALOGS_AND_MODALS.MODALS.DEVICE_MODAL.CATEGORIES.GEOPOSITION.MOVEMENT.Movement in progress' | translate }}
          </span>
        </span>

        <span *ngIf="!is_recent_movement">
          <span>
            {{
              'VIEWS.DEVICES.DIALOGS_AND_MODALS.MODALS.DEVICE_MODAL.CATEGORIES.GEOPOSITION.MOVEMENT.Position taken during the movement !'
                | translate
            }}
          </span>

          <span>
            {{
              'VIEWS.DEVICES.DIALOGS_AND_MODALS.MODALS.DEVICE_MODAL.CATEGORIES.GEOPOSITION.MOVEMENT.The GPS has not communicated a STOP for more than 3 hours'
                | translate
            }}
          </span>

          <span class="reason">
            {{
              'VIEWS.DEVICES.DIALOGS_AND_MODALS.MODALS.DEVICE_MODAL.CATEGORIES.GEOPOSITION.MOVEMENT.Either the device has no battery or there is no network coverage at the current position of the device'
                | translate
            }}
          </span>

          <a class="link" [routerLink]="['', { outlets: { modal: ['new_issue'] } }]">
            {{
              'VIEWS.DEVICES.DIALOGS_AND_MODALS.MODALS.DEVICE_MODAL.CATEGORIES.GEOPOSITION.MOVEMENT.Contact-us if you need help' | translate
            }}
          </a>
        </span>
      </ng-container>
    </div>
  </div>
</div>
