import { clone } from 'lodash-es';

// #region -> (gradient color generator from https://github.com/Adrinlol/javascript-color-gradient)

export class Gradient {
  public intervals: {
    upper: number;
    lower: number;
  }[] = [];
  public _colors: string[] = [];

  /**
   * Array of gradient colors
   */
  private gradients: GradientColor[] = [];

  /**
   * The number of colors to generate.
   */
  private _number_of_colors = 0;

  // #region -> (class basics)

  constructor() {}

  // #endregion

  // #region -> (gradient configuration & compute)

  /**
   * Setup the gradient steps.
   *
   * @param gradient_steps An array of gradient steps.
   */
  public setGradientSteps = (...gradient_steps: string[]): void => {
    this._setColors(gradient_steps);
  };

  /**
   * Setup the number of colors to generate.
   *
   * @param number_of_colors The number of colors to generates
   */
  public setMidpoint = (number_of_colors: number) => {
    if (number_of_colors <= 0) {
      throw new RangeError(`midPoint should be greater than ${number_of_colors}`);
    }

    this._number_of_colors = number_of_colors;
    this._setColors(this._colors);
  };

  private _setColors = (gradient_steps: string[]) => {
    if (gradient_steps.length < 2) {
      throw new Error(`setGradient should have more than ${gradient_steps.length} color`);
    }

    const increment = this._number_of_colors / (gradient_steps.length - 1);
    const first_gradient_color = new GradientColor();
    const first_gradient_color_lower = 0;
    const first_gradient_color_upper = 0 + increment;

    first_gradient_color.set_gradient_range(gradient_steps[0], gradient_steps[1]);
    first_gradient_color.set_midpoint(first_gradient_color_lower, first_gradient_color_upper);
    this.gradients = [first_gradient_color];
    this.intervals = [
      {
        lower: first_gradient_color_lower,
        upper: first_gradient_color_upper,
      },
    ];

    for (let i = 1; i < gradient_steps.length - 1; i++) {
      const gradient_color = new GradientColor();
      const gradient_color_lower = 0 + increment * i;
      const gradient_color_upper = 0 + increment * (i + 1);
      gradient_color.set_gradient_range(gradient_steps[i], gradient_steps[i + 1]);
      gradient_color.set_midpoint(gradient_color_lower, gradient_color_upper);
      this.gradients[i] = gradient_color;
      this.intervals[i] = {
        lower: gradient_color_lower,
        upper: gradient_color_upper,
      };
    }

    this._colors = gradient_steps;
  };

  // #endregion

  // #region -> (result access)

  // #endregion

  public getArray = () => {
    const gradients = [];
    for (let j = 0; j < this.intervals.length; j++) {
      const interval = this.intervals[j];
      const start = interval.lower === 0 ? 1 : Math.ceil(interval.lower);
      const end = interval.upper === this._number_of_colors ? interval.upper + 1 : Math.ceil(interval.upper);

      for (let i = start; i < end; i++) {
        gradients.push(this.gradients[j].getColor(i));
      }
    }
    return gradients;
  };

  public getColor = (props: number) => {
    if (isNaN(props)) {
      throw new TypeError(`getColor should be a number`);
    } else if (props <= 0) {
      throw new TypeError(`getColor should be greater than ${props}`);
    } else {
      const segment = (this._number_of_colors - 0) / this.gradients.length;
      const index = Math.min(Math.floor((Math.max(props, 0) - 0) / segment), this.gradients.length - 1);
      return this.gradients[index].getColor(props);
    }
  };
}

export class GradientColor {
  private _min_num = 0;
  private _max_num = 0;

  private _start_color: string = null;
  private _end_color: string = null;

  // #region -> (class basics)

  constructor() {}

  // #endregion

  // #region -> (gradient color setup & compute)

  public set_gradient_range = (colorStart: string, colorEnd: string): void => {
    this._start_color = this._get_hexa_color(colorStart);
    this._end_color = this._get_hexa_color(colorEnd);
  };

  public set_midpoint = (minNumber: number, maxNumber: number): void => {
    this._min_num = minNumber;
    this._max_num = maxNumber;
  };

  private _generate_hexa_color = (num: number, start: string, end: string): string => {
    if (num < this._min_num) {
      num = this._min_num;
    } else if (num > this._max_num) {
      num = this._max_num;
    }

    const mid_point = this._max_num - this._min_num;
    const start_base = parseInt(start, 16);
    const end_base = parseInt(end, 16);
    const average = (end_base - start_base) / mid_point;
    const final_base = Math.round(average * (num - this._min_num) + start_base);
    const balanced_final_base = final_base < 16 ? '0' + final_base.toString(16) : final_base.toString(16);
    return balanced_final_base;
  };

  // #endregion

  public getColor = (numero: number) => {
    if (numero) {
      return (
        '#' +
        this._generate_hexa_color(numero, this._start_color.substring(0, 2), this._end_color.substring(0, 2)) +
        this._generate_hexa_color(numero, this._start_color.substring(2, 4), this._end_color.substring(2, 4)) +
        this._generate_hexa_color(numero, this._start_color.substring(4, 6), this._end_color.substring(4, 6))
      );
    }
  };

  private _get_hexa_color = (props: string): string => props.substring(props.length - 6, props.length);
}

// #endregion

/** */
export const coloration_helper = {
  /** */
  modifiers: {
    /** */
    lighten_or_darken_color: (base_color: string, amount: number): `#${string}` => {
      if (base_color.includes('rgb') || base_color.includes('hsl')) {
        throw new Error('The base color must be in hexa format !');
      }

      var usePound = false;

      if (base_color[0] === '#') {
        base_color = base_color.slice(1);
        usePound = true;
      }

      var num = parseInt(base_color, 16);

      var r = (num >> 16) + amount;

      if (r > 255) r = 255;
      else if (r < 0) r = 0;

      var b = ((num >> 8) & 0x00ff) + amount;

      if (b > 255) b = 255;
      else if (b < 0) b = 0;

      var g = (num & 0x0000ff) + amount;

      if (g > 255) g = 255;
      else if (g < 0) g = 0;

      return ((usePound ? '#' : '') + (g | (b << 8) | (r << 16)).toString(16)) as `#${string}`;
    },

    /** */
    change_saturation_and_luminosity: (hsl: { h: number; s: number; l: number }, new_saturation?: number, new_luminosity?: number) => {
      let new_hsl = clone(hsl);

      if (new_saturation !== undefined) {
        new_hsl.s = new_saturation;
      }

      if (new_luminosity !== undefined) {
        new_hsl.l = new_luminosity;
      }

      return new_hsl;
    },
  },

  /** */
  formatters: {
    /** */
    format_hsl_string_to_number: (base_color: string): number[] => {
      if (base_color.includes('rgb')) {
        throw new Error('The base color must be in hsl format !');
      }

      let sep = base_color.indexOf(',') > -1 ? ',' : ' ';
      let hsl = base_color.substring(4).split(')')[0].split(sep);

      let h = parseInt(hsl[0]),
        s = parseInt(hsl[1].substring(0, hsl[1].length - 1)) / 100,
        l = parseInt(hsl[2].substring(0, hsl[2].length - 1)) / 100;

      return [h, s, l];
    },

    /** */
    format_hsl_number_to_string: (hsl: { h: number; s: number; l: number }): string => `hsl(${hsl.h}, ${hsl.s}%, ${hsl.l}%)`,
  },

  /** */
  converters: {
    /** */
    from_hsl: {
      /** */
      to_hex: (hsl: { h: number; s: number; l: number }) => {
        if (hsl.h == null) {
          hsl.h = 0;
        }

        const h: number = hsl.h;
        let s = hsl.s;
        let l = hsl.l;

        s /= 100;
        l /= 100;

        const c = (1 - Math.abs(2 * l - 1)) * s;
        const x = c * (1 - Math.abs(((h / 60) % 2) - 1));
        const m = l - c / 2;
        let r = 0;
        let g = 0;
        let b = 0;
        let r_hex = '';
        let g_hex = '';
        let b_hex = '';

        if (0 <= h && h < 60) {
          r = c;
          g = x;
          b = 0;
        } else if (60 <= h && h < 120) {
          r = x;
          g = c;
          b = 0;
        } else if (120 <= h && h < 180) {
          r = 0;
          g = c;
          b = x;
        } else if (180 <= h && h < 240) {
          r = 0;
          g = x;
          b = c;
        } else if (240 <= h && h < 300) {
          r = x;
          g = 0;
          b = c;
        } else if (300 <= h && h < 360) {
          r = c;
          g = 0;
          b = x;
        }

        // Having obtained RGB, convert channels to hex
        r_hex = Math.round((r + m) * 255).toString(16);
        g_hex = Math.round((g + m) * 255).toString(16);
        b_hex = Math.round((b + m) * 255).toString(16);

        // Prepend 0s, if necessary
        if (r_hex.length === 1) {
          r_hex = '0' + r_hex;
        }
        if (g_hex.length === 1) {
          g_hex = '0' + g_hex;
        }
        if (b_hex.length === 1) {
          b_hex = '0' + b_hex;
        }

        return '#' + r_hex + g_hex + b_hex;
      },
    },

    /** */
    from_hex: {
      /** */
      to_hsl: (hexa_color: string) => {
        if (hexa_color.startsWith('#')) {
          hexa_color = hexa_color.slice(1);
        }
        // Convert hex to RGB first
        let r: any;
        let g: any;
        let b: any;
        if (hexa_color.length === 3) {
          r = '0x' + hexa_color[0] + hexa_color[0];
          g = '0x' + hexa_color[1] + hexa_color[1];
          b = '0x' + hexa_color[2] + hexa_color[2];
        } else if (hexa_color.length === 6) {
          r = '0x' + hexa_color[0] + hexa_color[1];
          g = '0x' + hexa_color[2] + hexa_color[3];
          b = '0x' + hexa_color[4] + hexa_color[5];
        } else {
          return;
        }

        // Then to HSL
        r /= 255;
        g /= 255;
        b /= 255;
        const cmin = Math.min(r, g, b);
        const cmax = Math.max(r, g, b);
        const delta = cmax - cmin;
        let h = 0;
        let s = 0;
        let l = 0;

        if (delta === 0) {
          h = 0;
        } else if (cmax === r) {
          h = ((g - b) / delta) % 6;
        } else if (cmax === g) {
          h = (b - r) / delta + 2;
        } else {
          h = (r - g) / delta + 4;
        }

        h = Math.round(h * 60);

        if (h < 0) {
          h += 360;
        }

        l = (cmax + cmin) / 2;
        s = delta === 0 ? 0 : delta / (1 - Math.abs(2 * l - 1));
        s = +(s * 100).toFixed(1);
        l = +(l * 100).toFixed(1);

        return {
          h,
          s,
          l,
        };
      },
    },
  },
};
