import { Router } from '@angular/router';
import { Component, Input, ChangeDetectionStrategy, Output, EventEmitter } from '@angular/core';

import { BehaviorSubject, combineLatest, Observable, of } from 'rxjs';
import { map, switchMap, debounceTime, tap } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

import { replay } from '@bg2app/tools/rxjs';
import { Dictionary } from 'app/typings/core/interfaces';
import { Entity, Event } from 'app/models';
import { isNil } from 'lodash-es';

@Component({
  selector: 'bg2-event-span',
  templateUrl: './event-span.component.html',
  styleUrls: ['./event-span.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EventSpanComponent {
  // #region -> (component basics)

  constructor(public translate: TranslateService, private _router: Router) {}

  @Output() link_clicked = new EventEmitter<boolean>();

  // #endregion

  // #region -> (override decription)

  /** */
  private _desc$$: BehaviorSubject<string> = new BehaviorSubject(null);

  /** */
  public desc$$: Observable<string> = this._desc$$.asObservable();

  /** */
  @Input()
  public set desc(desc: string) {
    this._desc$$.next(desc);
  }

  // #endregion

  // #region -> (related entity)

  /** */
  private _from$$: BehaviorSubject<Entity> = new BehaviorSubject(null);

  /** */
  private from$$: Observable<Entity> = this._from$$.asObservable();

  /** */
  @Input()
  public set from(from: Entity) {
    this._from$$.next(from);
  }

  /** */
  public can_write_all_events$$ = this.from$$.pipe(
    switchMap(entity => {
      if (isNil(entity)) {
        return of(false);
      }

      return entity.user_acl.can$$('write_all_events');
    }),
    replay()
  );

  /** */
  public from_entity_id$$ = this.from$$.pipe(map(from => from?.id));

  // #endregion

  // #region -> (related event)

  /** */
  private _event$$: BehaviorSubject<Event> = new BehaviorSubject(null);

  /** */
  public event$$: Observable<Event> = this._event$$.asObservable();

  /** */
  @Input()
  public set event(event: Event) {
    this._event$$.next(event);
  }

  /** */
  public event_desc$$: Observable<string> = combineLatest([this.event$$, this.from_entity_id$$, this.desc$$]).pipe(
    debounceTime(40),
    switchMap(([event, from_entity_id, desc]) => event.getDesc(this.translate, from_entity_id, desc)),
    replay()
  );

  /** */
  public is_ghost$$ = this.event$$.pipe(
    switchMap(event => event.is_ghost$$),
    replay()
  );

  // #endregion

  public getRoute(event: any): void {
    event.stopPropagation();
    event.preventDefault();
    event.stopImmediatePropagation();

    // Get url
    let url = event.target.getAttribute('href');
    url = /void\((.*)\)/.exec(url);
    if (url && url.length === 2) {
      url = url[1].replace('/', '');
      const urls = url.split(', ');
      const outlets = {
        primary: urls,
        modal: ['raz'],
      };
      if (urls[0].startsWith('modal:')) {
        delete outlets.primary;
        const modal_params: Dictionary<any> = {};
        if (urls[1]) {
          urls[1].split(';').map((param_str: any) => {
            const key_value = param_str.split('=');
            modal_params[key_value[0]] = key_value[1];
          });
        }
        outlets.modal = [urls[0].slice('modal:'.length), modal_params];
      }
      const path = [{ outlets }];
      //TODO add eventemmiter
      this._router.navigate(path, { queryParamsHandling: 'preserve' });
      this.link_clicked.emit(true);
    }
  }
}
