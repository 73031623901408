import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';

import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { MatPaginatorModule } from '@angular/material/paginator';

import { SchemaFormModule } from 'ngx-schema-form';

import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime-ex';

import { SharedModule } from 'app/shared-module/shared.module';
import { MaterialSharedModule } from 'app/shared-module/material-shared.module';
import { MiscModule } from 'app/misc/misc.module';
import { HivesSharedModule } from 'app/views/hives/shared/hives-shared.module';
import { MiscWidgetsModule } from 'app/widgets/misc-widgets/misc-widgets.module';
import { DialogsModalsModule } from 'app/widgets/dialogs-modals/dialogs-modals.module';
import { WidgetsReusableModule } from 'app/widgets/widgets-reusables/widgets-reusables.module';

import { AlarmMvntRowComponent } from './alarms/alarm-mvnt-row/alarm-mvnt-row.component';
import { DeviceAlarmsStatusComponent } from './alarms/alarms-status/alarms-status.component';

// From @pwd/device-level/*
import { BatteryLevelComponent } from './device-level/battery-level/battery-level.component';
import { GPRSLevelComponent } from './device-level/gprs-level/gprs-level.component';
import { GPSLevelComponent } from './device-level/gps-level/gps-level.component';
import { S868LevelComponent } from './device-level/s868-level/s868-level.component';

import { LastComComponent } from './last-com/last-com.component';
import { DeviceAckNextConfComponent } from './device-ack-next-conf/device-ack-next-conf.component';
import { DeviceBatChangesComponent } from './device-bat-changes/device-bat-changes.component';
import { DeviceLastMovementsComponent } from './device-last-movements/device-last-movements.component';
import { DeviceLastMsgsComponent } from './device-last-msgs/device-last-msgs.component';
import { DeviceMsgsTableComponent } from './device-msgs-table/device-msgs-table.component';

import { DevicePositionComponent } from './device-position/device-position.component';
import { DeviceInlineComponent } from './device-inline/device-inline.component';
import { DeviceSpanLastPositionAndComComponent } from './device-span-last-position-and-com/device-span-last-position-and-com.component';
import { DeviceLinkComponent } from './device-link/device-link.component';
import { NotifiedDevicesDatatableComponent } from './notified-devices-datatable/notified-devices-datatable.component';
import { DeviceBatterySparklineComponent } from './charts/all/device-battery-sparkline/device-battery-sparkline.component';
import { GPSJobDataD3ChartComponent } from './charts/gps/gps-job-data-d3-chart/gps-job-data-d3-chart.component';
import { WGJobDataD3ChartComponent } from './charts/wg/wg-job-data-d3-chart/wg-job-data-d3-chart.component';
import { CPTJobDataD3ChartComponent } from './charts/cpt/cpt-job-data-d3-chart/cpt-job-data-d3-chart.component';
import { RGJobDataD3ChartComponent } from './charts/rg/rg-job-data-d3-chart/rg-job-data-d3-chart.component';
import { DeviceHistoryComponent } from './device-history/device-history.component';
import { EventsSharedModule } from 'app/views/events/events-shared.module';
import { BeeLivePhotosCarouselComponent } from './carousels/beelive-photos-carousel/beelive-photos-carousel.component';
import { BeelivePhotosCarouselGroupComponent } from './carousels/beelive-photos-carousel/beelive-photos-carousel-group.component';
import { DeviceGPRSSparklineComponent } from './charts/all/device-gprs-sparkline/device-gprs-sparkline.component';
import { Device868SparklineComponent } from './charts/all/device-868-sparkline/device-868-sparkline.component';
import { TGJobDataD3ChartComponent } from './charts/tg/tg-job-data-d3-chart/tg-job-data-d3-chart.component';
import { BatteryLevelSimplifiedComponent } from './device-level/battery-level-simplified/battery-level-simplified.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,

    MatTableModule,
    MatSortModule,
    MatPaginatorModule,

    MiscWidgetsModule,
    MiscModule,
    FormsModule,
    SchemaFormModule,

    OwlDateTimeModule,
    OwlNativeDateTimeModule,

    SharedModule,
    HivesSharedModule,
    EventsSharedModule,
    MaterialSharedModule,
    DialogsModalsModule,
    WidgetsReusableModule,
  ],
  declarations: [
    DeviceAlarmsStatusComponent,
    AlarmMvntRowComponent,

    // Device charts
    TGJobDataD3ChartComponent,
    RGJobDataD3ChartComponent,
    WGJobDataD3ChartComponent,
    CPTJobDataD3ChartComponent,
    GPSJobDataD3ChartComponent,
    Device868SparklineComponent,
    DeviceGPRSSparklineComponent,
    DeviceBatterySparklineComponent,

    // Device carousels
    BeeLivePhotosCarouselComponent,
    BeelivePhotosCarouselGroupComponent,

    BatteryLevelComponent,
    BatteryLevelSimplifiedComponent,
    GPRSLevelComponent,
    GPSLevelComponent,
    S868LevelComponent,
    DeviceAckNextConfComponent,
    DeviceBatChangesComponent,
    DeviceLastMovementsComponent,
    DeviceLastMsgsComponent,
    DeviceMsgsTableComponent,
    NotifiedDevicesDatatableComponent,

    LastComComponent,
    DeviceSpanLastPositionAndComComponent,

    DeviceHistoryComponent,
    DeviceInlineComponent,
    DeviceLinkComponent,
    DevicePositionComponent,
  ],
  providers: [],
  exports: [
    DeviceAlarmsStatusComponent,
    AlarmMvntRowComponent,

    // Device charts
    TGJobDataD3ChartComponent,
    RGJobDataD3ChartComponent,
    WGJobDataD3ChartComponent,
    CPTJobDataD3ChartComponent,
    GPSJobDataD3ChartComponent,
    Device868SparklineComponent,
    DeviceGPRSSparklineComponent,
    DeviceBatterySparklineComponent,

    // Device carousels
    BeeLivePhotosCarouselComponent,

    BatteryLevelComponent,
    BatteryLevelSimplifiedComponent,
    GPRSLevelComponent,
    GPSLevelComponent,
    S868LevelComponent,
    DeviceAckNextConfComponent,
    DeviceBatChangesComponent,
    DeviceLastMovementsComponent,
    DeviceLastMsgsComponent,
    DeviceMsgsTableComponent,
    NotifiedDevicesDatatableComponent,

    LastComComponent,
    DeviceSpanLastPositionAndComComponent,

    DeviceHistoryComponent,
    DeviceInlineComponent,
    DeviceLinkComponent,
    DevicePositionComponent,
  ],
})
export class DevicesSharedModule {}
