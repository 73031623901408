<bg2-modal [fullscreen]="fullscreen ?? false">
  <bg2-modal-header>
    <div class="header">
      <span
        *ngLet="is_modal_user_superadmin$$ | async; let is_superadmin"
        class="mdi mdi-36px"
        [ngClass]="{ 'mdi-account': !is_superadmin, 'mdi-crown': is_superadmin }"
      ></span>

      <span class="title">
        <ng-container *ngIf="is_modal_user_is_current_user$$ | async; else altModalTitle">
          {{ 'ALL.MENU.My account' | translate }}
        </ng-container>

        <ng-template #altModalTitle>{{ user_name$$ | async }}</ng-template>
      </span>
    </div>

    <div class="action-buttons">
      <ng-container *ngIf="(is_current_user_superadmin$$ | async) && (is_modal_user_is_current_user$$ | async) === false">
        <span
          *ngIf="user_id$$ | async; let user_id"
          class="mdi mdi-incognito mdi-36px"
          (click)="this.oauthService.set_impersonated_user_id(user_id)"
        ></span>
      </ng-container>

      <span class="mdi mdi-close-box mdi-36px" (click)="close()"></span>
    </div>
  </bg2-modal-header>

  <bg2-modal-content>
    <bg2-uam-user-details [user]="user$$ | async" (close_modal)="close()"></bg2-uam-user-details>
  </bg2-modal-content>
</bg2-modal>
