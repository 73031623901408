import { AfterViewInit, ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { filter, switchMap, take } from 'rxjs';

import { isNil, uniqueId } from 'lodash-es';
import { select, scaleLinear, axisBottom, axisLeft, line, min, max, Selection, ScaleLinear, ScaleTime, scaleTime } from 'd3';

import { AppStateService } from 'app/core/app-state.service';

import { DRDevice, SimpleSetterGetter } from 'app/models';

import { ResizedEvent } from 'app/misc/directives/resized/resized.directive';
import { format } from 'date-fns';

/** */
interface ChartData {
  /** */
  device: DRDevice;

  /** */
  start_time: Date;

  /** */
  end_time: Date;

  /** */
  data: {
    date: Date;
    weight_1: number;
    weight_2: number;
    temperature: number;
  }[];
}

@Component({
  selector: 'bg2-device-calibration-temperature-selected-chart',
  templateUrl: './device-calibration-temperature-selected-chart.component.html',
  styleUrls: ['./device-calibration-temperature-selected-chart.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DeviceCalibrationTemperatureSelectedChartComponent implements OnInit, AfterViewInit {
  // #region -> (component basics)

  constructor(private readonly appState: AppStateService) {}

  ngOnInit(): void {
    this._is_chart_base_ready.value$$
      .pipe(
        filter(Boolean),
        take(1),
        switchMap(() => this._data.value$$)
      )
      .subscribe({
        next: data => this.draw_data(data),
      });
  }

  ngAfterViewInit(): void {
    this._chart_refs.svg = select('#' + this.unique_name)
      .append('svg')
      .attr('width', '100%')
      .attr('height', this._chart_size.height)
      .append('g')
      .attr('transform', `translate(${this._chart_size.margins.left}, ${this._chart_size.margins.top})`);

    // Build X-axis
    this._chart_refs.xAxis = scaleTime().range([0, this.chart_real_width]);

    this._chart_refs.xAxisContainer = this._chart_refs.svg
      .append('g')
      .attr('transform', `translate(0, ${this.chart_real_height})`)
      .call(
        axisBottom(this._chart_refs.xAxis).tickFormat(
          (date: Date) => format(date, this.appState.dl.HH_mm, { locale: this.appState.dl.dateFns }) as any
        )
      );

    // Build Y-axis
    this._chart_refs.yAxis = scaleLinear().range([this.chart_real_height, 0]);
    this._chart_refs.yAxisContainer = this._chart_refs.svg.append('g').call(axisLeft(this._chart_refs.yAxis));

    // Create data containers
    this._chart_refs.lines_container = this._chart_refs.svg.append('g').attr('class', 'lines-container');
    this._chart_refs.limits_container = this._chart_refs.svg.append('g').attr('class', 'limits-container');

    this._is_chart_base_ready.value = true;
  }

  // #endregion

  // #region -> (chart management)

  /** */
  private readonly _chart_size = {
    margins: { top: 10, right: 20, bottom: 20, left: 20 },
    width: 300,
    height: 200,
  };

  /** */
  private get chart_real_width(): number {
    return this._chart_size.width - this._chart_size.margins.left - this._chart_size.margins.right;
  }

  /** */
  private get chart_real_height(): number {
    return this._chart_size.height - this._chart_size.margins.top - this._chart_size.margins.bottom;
  }

  /** */
  public onChartContainerResized(resized_event: ResizedEvent): void {
    this._is_chart_base_ready.value$$.pipe(filter(Boolean), take(1)).subscribe({
      next: () => {
        this._chart_refs.svg.attr('width', resized_event.new_width).attr('height', this._chart_size.height);
        this._chart_refs.xAxis.range([0, resized_event?.new_width - this._chart_size.margins.left - this._chart_size.margins.right]);

        this.draw_data(this._data.value);
      },
    });
  }

  /** */
  public unique_name = uniqueId('device-calibration-chart-');

  /** */
  private _chart_refs: {
    svg: Selection<SVGGElement, unknown, HTMLElement, any>;

    xAxis: ScaleTime<number, number, never>;
    xAxisContainer: Selection<SVGGElement, unknown, HTMLElement, any>;

    yAxis: ScaleLinear<number, number, never>;
    yAxisContainer: Selection<SVGGElement, unknown, HTMLElement, any>;

    lines_container: Selection<SVGGElement, unknown, HTMLElement, any>;
    limits_container: Selection<SVGGElement, unknown, HTMLElement, any>;
  } = {
    svg: null,
    xAxis: null,
    xAxisContainer: null,
    yAxis: null,
    yAxisContainer: null,
    lines_container: null,
    limits_container: null,
  };

  /** */
  private _is_chart_base_ready = new SimpleSetterGetter(false);

  /** */
  private draw_data(data: ChartData[]): void {
    this._chart_refs.lines_container.selectAll('path').remove();
    this._chart_refs.limits_container.selectAll('line').remove();

    if (isNil(data)) {
      return;
    }

    const usable_data = data.map(datum => {
      const filtered_data = datum.data.filter(d => !isNil(d[this.what_is_displayed.value]));
      datum.data = filtered_data;

      return datum;
    });

    const min_value_x = min(usable_data.map(d => min(d.data.map(a => a.date))));
    const max_value_x = max(usable_data.map(d => max(d.data.map(a => a.date))));

    const min_value_y = min(usable_data.map(d => min(d.data.map(a => a[this.what_is_displayed.value]))));
    const max_value_y = max(usable_data.map(d => max(d.data.map(a => a[this.what_is_displayed.value]))));

    // Update domains
    this._chart_refs.xAxis.domain([min_value_x, max_value_x]);
    this._chart_refs.yAxis.domain([min_value_y, max_value_y]);

    // Update axes
    this._chart_refs.xAxisContainer.call(
      axisBottom(this._chart_refs.xAxis).tickFormat(
        (date: Date) => format(date, this.appState.dl.HH_mm, { locale: this.appState.dl.dateFns }) as any
      )
    );
    this._chart_refs.yAxisContainer.call(axisLeft(this._chart_refs.yAxis));

    // Draw selected date range
    const min_start_time = min(usable_data.map(datum => datum.start_time));
    const max_start_time = max(usable_data.map(datum => datum.end_time));

    this._chart_refs.limits_container
      .append('line')
      .attr('x1', this._chart_refs.xAxis(min_start_time))
      .attr('x2', this._chart_refs.xAxis(min_start_time))
      .attr('y1', 0)
      .attr('y2', this.chart_real_height)
      .attr('stroke', 'black');
    this._chart_refs.limits_container
      .append('line')
      .attr('x1', this._chart_refs.xAxis(max_start_time))
      .attr('x2', this._chart_refs.xAxis(max_start_time))
      .attr('y1', 0)
      .attr('y2', this.chart_real_height)
      .attr('stroke', 'black');

    // Draw data lines
    usable_data.forEach(datum => {
      this._chart_refs.lines_container
        .append('path')
        .datum(datum.data)
        .attr('fill', 'none')
        .attr('stroke', this.data_colors[this.what_is_displayed.value])
        .attr('stroke-width', 1.5)
        .attr(
          'd',
          <any>line()
            .x((d: any) => this._chart_refs.xAxis(d.date))
            .y((d: any) => this._chart_refs.yAxis(d[this.what_is_displayed.value]))
        );
      // .on('mouseover', (event: MouseEvent, line_datum) => {
      //   const element = event.target as SVGPathElement;

      //   var siblings: SVGPathElement[] = [];
      //   var sibling = element.parentNode.firstChild;

      //   // Loop through each sibling and push to the array
      //   while (sibling) {
      //     if (sibling.nodeType === 1 && sibling !== element) {
      //       siblings.push(sibling as SVGPathElement);
      //     }

      //     sibling = sibling.nextSibling;
      //   }

      //   siblings.forEach(s => (s.style.stroke = 'lightgray'));
      // })
      // .on('mouseleave', (event: MouseEvent, line_datum) => {
      //   const element = event.target as SVGPathElement;

      //   var siblings: SVGPathElement[] = [];
      //   var sibling = element.parentNode.firstChild;

      //   // Loop through each sibling and push to the array
      //   while (sibling) {
      //     if (sibling.nodeType === 1 && sibling !== element) {
      //       siblings.push(sibling as SVGPathElement);
      //     }

      //     sibling = sibling.nextSibling;
      //   }

      //   siblings.forEach(s => (s.style.stroke = this.data_colors[this.what_is_displayed.value]));
      //   element.style.stroke = this.data_colors[this.what_is_displayed.value];
      // })
      // .on('click', (event: MouseEvent, line_datum) => this.exclude_device_from_selection.next(datum.device.imei));
    });
  }

  @Output()
  public exclude_device_from_selection = new EventEmitter<number>();

  // #endregion

  // #region -> (displayed data management)

  public readonly data_colors: { [key in 'temperature' | 'weight_1' | 'weight_2']: string } = {
    temperature: 'red',
    weight_1: 'green',
    weight_2: 'blue',
  };

  /** */
  public what_is_displayed = new SimpleSetterGetter<'temperature' | 'weight_1' | 'weight_2'>('temperature');

  /** */
  public on_change_display(type: 'temperature' | 'weight_1' | 'weight_2') {
    this.what_is_displayed.value = type;
    this.draw_data(this._data.value);
  }

  // #endregion

  @Input()
  public set data(data: ChartData[]) {
    this._data.value = data;
  }

  /** */
  private _data = new SimpleSetterGetter<ChartData[]>(null);
}
