import { marker as i18n } from '@biesbjerg/ngx-translate-extract-marker';

import { strEnum } from 'app/misc/tools';

const FilterEmptyLocations = strEnum(['all', 'occupied', 'empty']);
type FilterEmptyLocations = keyof typeof FilterEmptyLocations;

const FilterEmptyLocations_i18n_chip: { [key in FilterEmptyLocations]: string } = {
  all: i18n<string>('ENTITY.LOCATION.FILTERS.All'),
  occupied: i18n<string>('ENTITY.LOCATION.FILTERS.Only occupied'),
  empty: i18n<string>('ENTITY.LOCATION.FILTERS.Only not occupied'),
};


export { FilterEmptyLocations, FilterEmptyLocations_i18n_chip };
