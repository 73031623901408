import { Pipe, PipeTransform } from '@angular/core';

import { get, isEmpty, isNil } from 'lodash-es';

import { Dictionary } from 'app/typings/core/interfaces';

@Pipe({
  name: 'filter',
})
export class FilterPipe implements PipeTransform {
  public transform(items: Dictionary<any>[], field: string, value: string | boolean): any[] {
    if (isNil(items) || isEmpty(items)) {
      return [];
    }

    if (isNil(value)) {
      return items.filter(it => get(it, field));
    }

    return items.filter(it => get(it, field) === value);
  }
}
