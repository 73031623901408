import { ChangeDetectionStrategy, Component, Inject, Input } from '@angular/core';

import { marker as i18n } from '@biesbjerg/ngx-translate-extract-marker';

import { map, switchMap, BehaviorSubject, Observable, of, catchError, tap } from 'rxjs';

import { distinctUntilRealChanged, replay, switchTap, waitForNotNilValue, keepSourceIfNoError } from '@bg2app/tools/rxjs';
import { ConsoleLoggerService } from 'app/core/console-logger.service';

import { Apiary, DRDevice, Hive, RGDevice } from 'app/models';
import { ErrorHelperData } from 'app/widgets/widgets-reusables/errors/error-helper/error-helper.component';
import { ENV } from 'app/core/providers/environment.provider';
import { IEnvironment } from 'environments/common';

@Component({
  selector: 'bg2-apiary-last-data',
  templateUrl: './apiary-last-data.component.html',
  styleUrls: ['./apiary-last-data.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ApiaryLastDataComponent {
  // #region -> (component basics)

  /** */
  protected readonly LOGGER = new ConsoleLoggerService(this.constructor.name, false);

  /** */
  constructor(@Inject(ENV) public readonly env: IEnvironment) {}

  @Input()
  public show_navigation = true;

  /** */
  public track_by_hive_id(index: number, hive: Hive) {
    return hive.id;
  }

  // #endregion

  // #region -> (apiary entity)

  /** */
  private _apiary$$: BehaviorSubject<Apiary> = new BehaviorSubject<Apiary>(null);

  /** */
  public apiary$$: Observable<Apiary> = this._apiary$$.asObservable();

  /** */
  @Input()
  public set apiary(apiary: Apiary) {
    this._apiary$$.next(apiary);
  }

  public get apiary(): Apiary {
    return this._apiary$$.getValue();
  }

  /** */
  public apiary_can_have_weather_data$$ = this.apiary$$.pipe(
    waitForNotNilValue(),
    switchMap(apiary => apiary.can_have_weather_data$$),
    map(can_have_weather_data => can_have_weather_data?.pressure || can_have_weather_data?.temperature || can_have_weather_data?.wind)
  );

  // #endregion

  // #region -> (exploitation entity)

  /** */
  private exploitation$$ = this.apiary$$.pipe(
    waitForNotNilValue(),
    switchMap(apiary => apiary.exploitation$$),
    waitForNotNilValue()
  );

  // #endregion

  // #region -> (switch between hives and devices)

  private _show_equipped_hives = true;

  public get show_equipped_hives(): boolean {
    return this._show_equipped_hives;
  }

  public set show_equipped_hives(show_equipped_hives: boolean) {
    this._show_equipped_hives = show_equipped_hives;
  }

  // #endregion

  // #region -> (hives objects & data)

  /**
   * Observes the apiary's hives which have at least one device.
   */
  public hives_with_device$$ = this.apiary$$.pipe(switchMap(apiary => apiary?.connected_hives$$ ?? of<Hive[]>([])));

  /** */
  private has_hives_with_devices$$ = this.hives_with_device$$.pipe(
    map(hives_with_devices => hives_with_devices?.length > 0),
    distinctUntilRealChanged()
  );

  // #endregion

  // #region -> (devices objects & data)

  /**
   * Observes the apiary's devices.
   */
  private devices$$: Observable<DRDevice[]> = this.apiary$$.pipe(
    switchMap(apiary => apiary?.devices$$ ?? of([])),
    replay()
  );

  /** */
  public track_by_devices(index: number, device: DRDevice) {
    return device.imei;
  }

  /**
   * Observes the apiary's weather stations.
   */
  public weather_station$$ = this.apiary$$.pipe(switchMap(apiary => apiary?.devices_rg$$ ?? of<RGDevice[]>([])));

  /** */
  private has_weather_stations$$ = this.weather_station$$.pipe(
    map(weather_stations => weather_stations?.length > 0),
    distinctUntilRealChanged()
  );

  // #endregion

  // #region -> (error management)

  private _check_can_read_devices_from_exploitation$$ = this.exploitation$$.pipe(
    // Check if user can "read devices" of exploitation
    keepSourceIfNoError(exploitation =>
      exploitation.user_acl.check_can_read_devices$$({
        what: i18n<string>('ALL.ACE.READ_DEVICES.WHAT.view the devices of this exploitation'),
      })
    ),
    switchMap(exploitation_or_error => {
      if (exploitation_or_error instanceof ErrorHelperData) {
        return of(exploitation_or_error);
      }

      return this._check_can_add_devices_from_exploitation$$;
    })
  );

  private _check_can_add_devices_from_exploitation$$ = this.exploitation$$.pipe(
    switchMap(exploitation =>
      exploitation.has_devices$$.pipe(
        switchMap(exploitation__has_devices =>
          exploitation.user_acl
            .check_can_write_all$$({
              what: i18n<string>('ALL.ACE.WRITE_ALL.WHAT.add devices to this apiary'),
            })
            .pipe(
              switchMap(() => {
                if (exploitation__has_devices) {
                  return this.apiary$$.pipe(
                    switchMap(apiary =>
                      apiary.prebuilt_error_models.get_error_manage_hive_and_devices$$(
                        i18n<string>('ENTITY.APIARY.ERRORS.No equipped devices on this apiary')
                      )
                    )
                  );
                }

                return exploitation.prebuilt_error_models.get_error_ask_quote$$();
              })
            )
        )
      )
    )
  );

  /** */
  public error$$: Observable<ErrorHelperData | null> = this.apiary$$.pipe(
    waitForNotNilValue(),
    keepSourceIfNoError(apiary =>
      apiary.user_acl.check_read_devices$$({
        what: i18n<string>('ALL.ACE.READ_DEVICES.WHAT.see the equipped hives'),
      })
    ),
    switchMap(apiary_or_error => {
      if (apiary_or_error instanceof ErrorHelperData) {
        return of(apiary_or_error);
      }

      return apiary_or_error?.has_devices$$;
    }),
    switchMap(apiary__has_devices_or_error => {
      if (apiary__has_devices_or_error instanceof ErrorHelperData) {
        return of(apiary__has_devices_or_error);
      }

      const apiary_has_devices = apiary__has_devices_or_error;

      if (!apiary_has_devices) {
        return this._check_can_read_devices_from_exploitation$$;
      }

      return of(null);
    })
  );

  // #endregion

  // #region -> (helpers)

  /** */
  public isObjectHive(object: any): Hive | null {
    return object instanceof Hive ? object : null;
  }

  /** */
  public isObjectRGDevice(object: any): RGDevice | null {
    return object instanceof RGDevice ? object : null;
  }

  // #endregion
}
