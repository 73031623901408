<bg2-modal [fullscreen]="fullscreen || false" #modal>
  <bg2-modal-header>
    <div class="header">
      <span class="mdi mdi-ticket mdi-36px"></span>
      <span class="title">{{ modal_title$$ | async | translate }}</span>
    </div>

    <div class="action-buttons">
      <span class="mdi mdi-close-box mdi-36px" (click)="close()"></span>
    </div>
  </bg2-modal-header>

  <bg2-modal-content>
    <bg2-ui-message
      *ngIf="(issue_type$$ | async) === 'generic'"
      [type]="'info'"
      [title]="'VIEWS.ZOHO.DIALOGS_AND_MODALS.ZOHO_CREATE_ISSUE_MODAL.TICKET_TYPE_INFO.Creation of generic DESK ticket' | translate"
    >
      <div style="display: flex; flex-flow: column nowrap" message>
        <span>
          {{
            'VIEWS.ZOHO.DIALOGS_AND_MODALS.ZOHO_CREATE_ISSUE_MODAL.TICKET_TYPE_INFO.This modal is intended to create an issue on desk which is not related to devices'
              | translate
          }}
        </span>
        <span>
          {{
            'VIEWS.ZOHO.DIALOGS_AND_MODALS.ZOHO_CREATE_ISSUE_MODAL.TICKET_TYPE_INFO.Go to the devices datatable to create a ticket for devices'
              | translate
          }}
        </span>
      </div>
    </bg2-ui-message>

    <bg2-ui-message
      *ngIf="(issue_type$$ | async) === 'device_issue'"
      [type]="'info'"
      [title]="'VIEWS.ZOHO.DIALOGS_AND_MODALS.ZOHO_CREATE_ISSUE_MODAL.TICKET_TYPE_INFO.Creation DESK ticket for devices' | translate"
    >
      <div style="display: flex; flex-flow: column nowrap" message>
        <span>
          {{
            'VIEWS.ZOHO.DIALOGS_AND_MODALS.ZOHO_CREATE_ISSUE_MODAL.TICKET_TYPE_INFO.This modal is intended to create an issue on desk related to devices'
              | translate
          }}
        </span>
        <span>
          {{
            'VIEWS.ZOHO.DIALOGS_AND_MODALS.ZOHO_CREATE_ISSUE_MODAL.TICKET_TYPE_INFO.Use the superadmin shortcut to create an issue not related to the devices'
              | translate
          }}
        </span>
      </div>
    </bg2-ui-message>

    <bg2-form-overlay
      *ngIf="(loading_form_data$$ | async) === false; else loadingFormData"
      [source_schema]="schema$$ | async"
      [source_model]="form_model$$ | async"
      (when_form_data_update)="form_model = $event"
      (when_form_valid_update)="is_form_valid = $event"
    ></bg2-form-overlay>

    <ng-template #loadingFormData>
      <hex-loading [size]="'large'"></hex-loading>
    </ng-template>

    <ng-container [ngSwitch]="issue_type$$ | async">
      <ng-container *ngSwitchCase="'device_issue'">
        <div class="infos-to-check-container">
          <span>Informations a verifier manuellement !</span>

          <div class="container__info-to-check-container">
            <span>Garantie des appareils</span>

            <div class="container__devices-guarantee">
              <div *ngFor="let device of devices$$ | async">
                <span>{{ device?.name | colon }}</span>

                <div>
                  <span>
                    {{ 'EVENT.DEVICE.Contract type' | translate | colon }}{{ 'EVENT.DEVICE.' + (device.contract$$ | async) | translate }}
                  </span>

                  <span>
                    {{
                      'DEVICE.AFFECTATION.WAREHOUSE.Affected to warehouse since the [date]'
                        | translate : { date: (device.warehouse_config$$ | async)?.timestamp | formatDate : 'full' }
                    }}
                  </span>

                  <span>
                    {{ 'DEVICE.ALL.GUARANTEE.Is under guarantee ?' | translate }}
                    {{ ((device?.guarantee$$ | async)?.under_guarantee ? 'ALL.COMMON.Yes' : 'ALL.COMMON.No') | translate }}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
    </ng-container>
  </bg2-modal-content>

  <bg2-modal-footer>
    <bg2-button style="flex: 1 1 0%" [type]="'cancel'" [message]="'ALL.ACTIONS.Cancel' | translate" (btn_click)="close()"></bg2-button>

    <bg2-button
      style="flex: 1 1 0%"
      [type]="'action'"
      [icon]="'mdi-content-save'"
      [message]="'ALL.ACTIONS.Create' | translate"
      [disabled]="(is_form_valid$$ | async) === false"
      (btn_click)="submit()"
    ></bg2-button>
  </bg2-modal-footer>
</bg2-modal>
