import { ChangeDetectionStrategy, Component } from '@angular/core';

import { isNil } from 'lodash-es';

import { BehaviorSubject } from 'rxjs';
import { distinctUntilRealChanged, replay } from '@bg2app/tools/rxjs';

@Component({
  selector: 'bg2-debugging',
  templateUrl: './debugging.component.html',
  styleUrls: ['./debugging.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DebuggingSettingsComponent {
  // #region -> (component basics)

  constructor() {
    if (!isNil(localStorage.getItem('config.debug.analytics.google'))) {
      this.debug_analytics_google = (JSON.parse(localStorage.getItem('config.debug.analytics.google')) as boolean) || false;
    }
  }

  // #endregion

  private _debug_analytics_google$$ = new BehaviorSubject<boolean>(false);
  public apiary_data_default_view_type$$ = this._debug_analytics_google$$.asObservable().pipe(distinctUntilRealChanged(), replay());

  public get debug_analytics_google(): boolean {
    return this._debug_analytics_google$$.getValue();
  }

  public set debug_analytics_google(datum: boolean) {
    this._debug_analytics_google$$.next(datum);
    localStorage.setItem('config.debug.analytics.google', `${datum}`);
  }
}
