import { Injectable, Type } from '@angular/core';

import { map, Observable, of, switchMap } from 'rxjs';
import { tap } from 'rxjs';

import { TranslateService } from '@ngx-translate/core';
import { marker as i18n } from '@biesbjerg/ngx-translate-extract-marker';

import { DomInjectionService } from 'app/core/dom-injection.service';

// Components (dialogs)
import { AbstractDialogComponent, AbstractDialogParams } from './abstract-dialog.component';
import { CustomazibleDialogComponent, CustomazibleDialogParams, CustomazibleReturn } from './customizable/customazible.dialog';
import { ErrorDialogComponent, ErrorDialogParams } from './dialog-error/error.dialog';
import { Dictionary } from 'app/typings/core/interfaces';

/**
 * Dialog system service.
 *
 * @notes Dialogs service can handle one dialog at a time, it's like the default JS `confirm`.
 */
@Injectable({
  providedIn: 'root',
})
export class DialogsService {
  //#region Dialog component

  public dialog_opened = 0;

  //#endregion

  /**
   * DialogsService class constructor.
   *
   * @param injectorService
   * @param translate
   */
  constructor(private injectionService: DomInjectionService, protected translate: TranslateService) {}

  /**
   * Render a dialog component.
   *
   * @param dialog_type The type of the dialog.
   * @param dialog_params Properties to add to the dialog.
   * @example
   * this.dialogs.open(YesNoComponent, { body: 'issue ?' }).subscribe(
   *  value => handleResult(value),
   *  error => handleError(value),
   *  () => handleComplete()
   * );
   */
  public open<I extends AbstractDialogParams, O = any>(
    dialog_type: Type<AbstractDialogComponent<I, O>>,
    dialog_params: I,
    overlay_class: string = 'dialog-overlay'
  ): Observable<O> {
    const dialog_ref = this.injectionService.appendComponent(dialog_type, { input_params: dialog_params });
    this.injectionService.addOverlay(overlay_class);
    this.injectionService.preventBodyScroll();

    // Save builded component.
    this.dialog_opened += 1;

    // Get result from dialog.
    return dialog_ref.instance.results$$.pipe(
      tap(() => {
        this.dialog_opened -= 1;
        this.injectionService.destroyComponent(dialog_ref);
        this.injectionService.removeOverlay(overlay_class);
        this.injectionService.releaseBodyScroll();
      })
    );
  }

  /** */
  public error(configuration: ErrorDialogParams) {
    return this.open(ErrorDialogComponent, configuration);
  }

  /**
   * Opens a customazible dialog.
   *
   * @param dialogProperties The properties of the dialog.
   */
  public customizable<ReturnValue>(dialogProperties: CustomazibleDialogParams<ReturnValue>): Observable<CustomazibleReturn<ReturnValue>> {
    return this.open(CustomazibleDialogComponent, dialogProperties);
  }

  /**
   * Render a confirm dialog (yes/no).
   *
   * @param message The message to display.
   * @param override Override default buttons (item 1 should be `Yes`, item 2 should be `No`)
   * @example
   * this.dialogs.confirm('issue ?').subscribe(
   *  value => handleResult(value),
   *  error => handleError(value),
   *  () => handleComplete()
   * );
   */
  public confirm(
    message: string = '',
    buttonsMessages: { onTrueMessage: string; onFalseMessage: string } = null,
    delete_confirm: boolean = false
  ) {
    // Translation of message & buttons text
    message = this.translate.instant(message);
    buttonsMessages.onTrueMessage = this.translate.instant(buttonsMessages.onTrueMessage);
    buttonsMessages.onFalseMessage = this.translate.instant(buttonsMessages.onFalseMessage);

    const params: CustomazibleDialogParams<boolean> = {
      body: {
        type: 'span',
        content: message,
      },
      footer: {
        buttons: {
          items: [
            {
              type: 'button',
              result: false,
              color: 'transparent',
              content: buttonsMessages.onFalseMessage,
            },
            {
              type: 'button',
              result: true,
              color: delete_confirm ? 'warn' : 'primary',
              content: buttonsMessages.onTrueMessage,
            },
          ],
        },
      },
    };
    return this.customizable(params).pipe(
      map(result => result.return_value)
    );
  }

  /**
   * Render a delete confirm dialog (yes/no with code to enter).
   *
   */
   public deleteConfirmWithInput(
    initial_message: string,
    type_message: string,
    incorect_type_message: string,
    options: {
      on_true_message: string;
      on_false_message: string;
      waited_input: string
    },
    i18n_params: any = null
  ) {
    // Translation of message & buttons text
    initial_message = this.translate.instant(initial_message, i18n_params);
    type_message = this.translate.instant(type_message, i18n_params);
    incorect_type_message = this.translate.instant(incorect_type_message, i18n_params);
    options.on_true_message = this.translate.instant(options.on_true_message, i18n_params);
    options.on_false_message = this.translate.instant(options.on_false_message, i18n_params);

    const params: CustomazibleDialogParams<boolean> = {
      body: {
        type: 'div',
        elements: [
          {
            type: 'span',
            content: initial_message,
            styles: {
              'font-size': '1.2em',
              'padding-bottom': '5px'
            }
          },
          {
            type: 'span',
            content: type_message,
          },
          {
            type: 'form',
            name: 'confirm_value',
            schema: {
              type: "string"
            },
          },
          
        ],
      },
      footer: {
        buttons: {
          items: [
            {
              type: 'button',
              result: false,
              color: 'transparent',
              content: options.on_false_message,
            },
            {
              type: 'button',
              result: true,
              color: 'warn',
              content: options.on_true_message,
            },
          ],
        },
      },
    };
    return this.customizable(params).pipe(
      switchMap(ret => {
        if(ret.return_value && ret.form_values.confirm_value !== options.waited_input) {
          return this.alert(incorect_type_message).pipe(
            map(() => false)
          );
        } else {
          return of(ret.return_value)
        }
      })
    );
  }

  /**
   * Render a `AlertComponent`.
   *
   * @param message The message to display.
   * @example
   * this.dialogs.alert('warns user').subscribe(
   *  value => handleResult(value),
   *  error => handleError(value),
   *  () => handleComplete()
   * );
   */
  public alert(message: string = '', params: Dictionary<any> = {}) {
    const properties: CustomazibleDialogParams<void> = {
      body: {
        type: 'span',
        content: message,
        translateParams: params
      },
      footer: {
        buttons: {
          items: [
            {
              type: 'button',
              result: null,
              content: i18n<string>('ALL.COMMON.OK'),
            },
          ],
        },
      },
    };
    return this.customizable(properties).pipe(
      map(data => data.return_value)
    );
  }

}
