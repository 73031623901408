<div
  *ngIf="top_layer_announcement_config?.enabled"
  class="top-layer-announcement"
  [ngStyle]="{
    color: top_layer_announcement_config?.styles?.font_color ?? 'inherit',
    'background-color': top_layer_announcement_config?.styles?.background_color ?? 'orange'
  }"
>
  <div class="description">
    <span class="fake-div"></span>

    <div class="message-container">
      <span class="message" [innerHTML]="top_layer_announcement_message$$ | async"></span>

      <ng-container *ngIf="top_layer_announcement_config?.read_more?.enabled">
        <span>&nbsp;</span>
        <span class="find-out-more" (click)="find_out_more_top_layer_announcement()">{{ 'ALL.COMMON.Find out more' | translate }}</span>
      </ng-container>
    </div>

    <span
      class="mdi mdi-close-circle mdi-24px"
      (click)="mode === 'live' ? appBackofficeService.hide_top_layer_announcement() : null"
    ></span>
  </div>
</div>
