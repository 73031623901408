import { AfterViewInit, ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';

import { BgControlWidgetComponent as ControlWidget, WidgetOptions } from '../control/control.widget';

/** */
interface BooleanWidgetOptions extends WidgetOptions {
  /** */
  type: 'classic' | 'on-off';
}

@Component({
  selector: 'bg2-ef-boolean-widget',
  templateUrl: './boolean.widget.html',
  styleUrls: ['./boolean.widget.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EfBooleanWidget extends ControlWidget implements OnInit, AfterViewInit, OnDestroy {
  // #region -> (component basics)

  ngOnInit(): void {
    super.ngOnInit();
  }

  ngAfterViewInit(): void {
    super.ngAfterViewInit();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  // #endregion

  public options = <BooleanWidgetOptions>{
    type: 'on-off',
  };
}
