import { CurrencyPipe } from '@angular/common';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Component, OnInit, HostListener, ChangeDetectionStrategy, OnDestroy } from '@angular/core';

import { ISchema } from 'ngx-schema-form';

import { cloneDeep, groupBy, isNil, mapValues, sortBy, sum } from 'lodash-es';

import { BehaviorSubject, combineLatest, from, map, of, switchMap, take, tap } from 'rxjs';
import { catchErrorInDialog, replay, waitForNotNilProperties, waitForNotNilValue } from '@bg2app/tools/rxjs';

import { formatISO } from 'date-fns';

import { TranslateService } from '@ngx-translate/core';
import { marker as i18n } from '@biesbjerg/ngx-translate-extract-marker';

import { environment } from 'environments/environment';

import { BeeguardAuthService } from 'app/core';
import { DeviceBatInfo } from 'app/core/api-swagger/device';
import { AppStateService } from 'app/core/app-state.service';
import { DialogsService } from 'app/widgets/dialogs-modals/dialogs.service';

import { AbstractFormModalComponent } from 'app/widgets/dialogs-modals';

import { decodeJson, strEnum } from 'app/misc/tools';
import { Dictionary } from 'app/typings/core/interfaces';
import { DEVICE_BATTERY_TYPE } from 'app/models/devices';
import { BatteryPrice } from 'app/models/devices/DRDevice';
import { ModalParams, ModalArgs } from 'app/widgets/dialogs-modals/abstract-modal.component';

interface NewIssueModalParams extends ModalParams {
  type: 'order_battery' | 'contact_support' | 'device_support';
  data: any;
  args: ModalArgs;
}

/** */
interface ContactIssueModel {
  /** */
  user_name: string;

  user_email: string;

  /** */
  subject: string;

  /** */
  description: string;
}

@Component({
  selector: 'bg2-new-issue-modal',
  templateUrl: './new-issue.modal.html',
  styleUrls: ['./new-issue.modal.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NewIssueModalComponent extends AbstractFormModalComponent<NewIssueModalParams> implements OnInit, OnDestroy {
  // #region -> (component basics)

  constructor(
    private _http: HttpClient,
    private _dialogs: DialogsService,
    private _appState: AppStateService,
    private _translate: TranslateService,
    private _oAuthService: BeeguardAuthService
  ) {
    super();
  }

  ngOnInit(): void {
    this.compute_form_schema();

    setTimeout(() => {
      this.compute_form_model();
    }, 2);
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  /** */
  public language$$ = this._appState.lang$$;

  // #endregion

  // #region -> (closing management)

  /** */
  protected handle_event_before_unload(event: BeforeUnloadEvent): void {
    if (this.hasChanged) {
      event.returnValue = true;
    }
  }

  /** */
  public close(): void {
    if (this.hasChanged) {
      this._dialogs
        .confirm(
          i18n<string>('ROUTABLE_MODALS.NEW_ISSUE.QUIT.You have written a message. Are you sure you want to quit without sending it ?'),
          {
            onFalseMessage: i18n<string>('ALL.COMMON.No'),
            onTrueMessage: i18n<string>('ALL.COMMON.Yes'),
          }
        )
        .subscribe({
          next: (want_to_quit: boolean) => {
            if (!want_to_quit) {
              return;
            }
            super.close();
          },
        });
    } else {
      super.close();
    }
  }

  private hasChanged(): boolean {
    const current_model: ContactIssueModel = cloneDeep(this.form_model);

    const has_subject_changed = (current_model?.subject || '') !== '';
    const has_description_changed = (current_model?.description || '') !== '';

    return has_description_changed || has_subject_changed;
  }

  // #endregion

  /** */
  private get data() {
    return this.input_params.data;
  }

  // #region -> (inherited form management)

  protected onFormChanged(event: { value: ContactIssueModel }) {
    this.form_model = event.value;
  }

  public submit(): void {
    this.loading = true;

    const current_data: ContactIssueModel = cloneDeep(this.form_model);

    this.user$$
      .pipe(
        take(1),
        map(user => {
          const context = `
          USER: ${user.username}
          email: ${user.email}
          id: ${user.id}
          CRM ID: ${user.CRM_id}
          lang: ${this._appState.lang}
          userAgent: ${navigator.userAgent}
          from:
            url: ${window.location.href}
            referrer: ${document.referrer}
        `;

          const post_body: Dictionary<any> = {
            contexte: context,
          };

          return post_body;
        }),
        map(post_body => {
          mapValues(current_data, (value, key, self) => {
            post_body[key] = value;
          });

          return post_body;
        }),
        switchMap(post_body => {
          let headers = new HttpHeaders();

          return combineLatest({
            impersonate: this._oAuthService.impersonate$$.pipe(take(1)),
            access_token: this._oAuthService.access_token$$.pipe(take(1)),
          }).pipe(
            switchMap(({ impersonate, access_token }) => {
              headers = headers.set('Authorization', 'Bearer ' + access_token);

              if (impersonate?.user_id) {
                headers = headers.set('ImpersonateId', impersonate.user_id.toString());
                headers = headers.set('ImpersonateScope', impersonate.scopes);
              } else {
                headers = headers.delete('ImpersonateId');
                headers = headers.delete('ImpersonateScope');
              }

              return this._http.post(`${environment.Beeguard2ApiUrl}/desk/ticket`, post_body, {
                headers,
                observe: 'body',
                responseType: 'text',
              });
            }),
            catchErrorInDialog(this._dialogs),
            switchMap(() => this._dialogs.alert(this._translate.instant('VIEWS.MODALS.NEW_ISSUE.Your request has been sent')))
          );
        })
      )
      .subscribe({
        next: () => {
          this.loading = false;
          super.close(); // Do not ask for any confirm
        },
        error: (err: unknown) => {
          this.loading = false;
        },
      });
  }

  // #endregion

  // #region-> (form schema & model builders)

  /** */
  private compute_form_schema(): void {
    const schema: ISchema = {
      type: 'object',
      properties: {
        user_name: {
          type: 'string',
          widget: 'hidden',

          label: i18n<string>('ROUTABLE_MODALS.USER_ACCOUNT.UAM_USER_DETAILS.PROFILE.Username'),
          readOnly: true,
        },
        user_email: {
          type: 'string',
          widget: 'ng-mat-text',

          label: i18n<string>('VIEWS.MODALS.NEW_ISSUE.Mail address'),
          readOnly: true,
          options: {
            max_length: 300,
          },
        },
        subject: {
          type: 'string',
          widget: 'ng-mat-text',

          label: i18n<string>('VIEWS.MODALS.NEW_ISSUE.Subject'),
          options: {
            max_length: 300,
          },
        },
        description: {
          type: 'string',
          widget: 'ng-mat-textarea',

          label: i18n<string>('VIEWS.MODALS.NEW_ISSUE.Issue description'),
          options: {
            max_length: 2000,
          },
        },
      },
      required: ['user_name', 'user_email', 'subject', 'description'],
    };

    this.form_schema = schema;
  }

  /** */
  private compute_form_model(): void {
    combineLatest({
      user_email: this.user_email$$,
      username: this.user_username$$,
      initial_content: this.input_params$$.pipe(
        map(input_params => input_params?.type),
        map(type => {
          if (isNil(type)) {
            return { message: '', subject: '' };
          }

          switch (type) {
            case 'contact_support': {
              return this.build_contact_support_form();
            }

            case 'order_battery': {
              return this.build_battery_order_form();
            }

            case 'device_support': {
              return this.build_device_support();
            }

            default:
              throw new Error(`Type ${type} is not handled for the new issue !`);
          }
        })
      ),
    })
      .pipe(waitForNotNilProperties(), take(1))
      .subscribe({
        next: ({ user_email, username, initial_content }) => {
          this.form_model = <ContactIssueModel>{
            user_email,
            user_name: username,
            subject: initial_content.subject,
            description: initial_content.message,
          };
        },
      });
  }

  // #endregion

  // #region -> (validity management)

  /** */
  private _is_valid$$ = new BehaviorSubject(true);

  /** */
  public is_valid$$ = this._is_valid$$.asObservable();

  /** */
  public set is_valid(is_valid: boolean) {
    this._is_valid$$.next(is_valid);
  }

  // #endregion

  public user$$ = this._appState.user$$.pipe(
    waitForNotNilValue(),
    tap(() => {
      this.loading = false;
      this.initial_loading = false;
    }),
    replay()
  );

  /** */
  public user_email$$ = this.user$$.pipe(switchMap(user => user?.email$$));

  /** */
  public user_username$$ = this.user$$.pipe(switchMap(user => user?.username$$));

  // #region -> (prefilled form builders)

  /** */
  private build_battery_order_form() {
    const decoded_json = decodeJson(this.data);
    const devices: { name: string; bat: DeviceBatInfo; contract: 'rent' | 'purchase' | 'loan'; battery_price: BatteryPrice; is_battery_computed_in_local: boolean }[] =
      JSON.parse(decoded_json);

    let message = `${this._translate.instant(i18n<string>('ALL.COMMON.Hello'))},`;
    message += `\r\r`;
    message += this._translate.instant(
      i18n<string>(
        'VIEWS_WINDOWED.MODALS.NEW_ISSUE.ORDER_BATTERY.I want to order batteries for the following devices (devices marked with * are rented or loaned):'
      )
    );

    // Build the devices list
    sortBy(devices, item => item?.bat?.model).forEach(device => {
      let sentence = `\r   => ${device.name}${device?.contract === 'rent' || device?.contract === 'loan' ? '*' : ''}`;

      if (!isNil(device?.bat?.model)) {
        if (device.is_battery_computed_in_local) {
          sentence += ` (${device.bat.model} (from-app))`;
        } else {
          sentence += ` (${device.bat.model})`;
        }
      }

      if (!isNil(device?.battery_price?.price)) {
        // sentence += ` <--> ${this._currencyPipe.transform(device.battery_price.price, 'EUR')}`;
        sentence += ` <--> ${device.battery_price.price} €`;

        if (device.battery_price.price === 0) {
          sentence += ` (${this._translate.instant('DEVICE.ALL.CONTRACT.Included', 'EUR')})`;
        }
      }

      message += sentence;
    });

    const devices_by_contract_type = groupBy(devices, device => device?.contract);

    if (devices_by_contract_type?.['rent']?.length > 0) {
      message += `\r\r`;
      message += this._translate.instant(i18n<string>('ROUTABLE_MODALS.NEW_ISSUE.TYPES.ORDER_BATTERY.Summary of leased devices:'));

      const devices_by_battery_type = groupBy(devices_by_contract_type?.['rent'], device => device?.bat?.model);
      message += this._map_battery_types(devices_by_battery_type as any);
    }

    if (devices_by_contract_type?.['loan']?.length > 0) {
      message += `\r\r`;
      message += this._translate.instant(i18n<string>('ROUTABLE_MODALS.NEW_ISSUE.TYPES.ORDER_BATTERY.Summary of loaned devices:'));

      const devices_by_battery_type = groupBy(devices_by_contract_type?.['loan'], device => device?.bat?.model);
      message += this._map_battery_types(devices_by_battery_type as any);
    }

    if (devices_by_contract_type?.['purchase']?.length > 0) {
      message += `\r\r`;
      message += this._translate.instant(i18n<string>('ROUTABLE_MODALS.NEW_ISSUE.TYPES.ORDER_BATTERY.Summary of purchased devices:'));

      const devices_by_battery_type = groupBy(devices_by_contract_type?.['purchase'], device => device?.bat?.model);
      message += this._map_battery_types(devices_by_battery_type as any);
    }

    const estimated_totals = devices.map(d => d?.battery_price?.price || 0);
    message += `\r\r`;
    // message += `${this._translate.instant(
    //   i18n<string>('ROUTABLE_MODALS.NEW_ISSUE.TYPES.ORDER_BATTERY.Estimated total:')
    // )}${this._currencyPipe.transform(sum(estimated_totals), 'EUR')}`;
    message += `${this._translate.instant(
      i18n<string>('ROUTABLE_MODALS.NEW_ISSUE.TYPES.ORDER_BATTERY.Estimated total:')
    )}${estimated_totals} €`;
    message += `\r${this._translate.instant(
      i18n<string>(
        'ROUTABLE_MODALS.NEW_ISSUE.TYPES.ORDER_BATTERY.The estimated price is only an indication and can be different on the quote'
      )
    )}`;
    message += `\r${this._translate.instant(
      i18n<string>('ROUTABLE_MODALS.NEW_ISSUE.TYPES.ORDER_BATTERY.Also, it does not include the shipping fees')
    )}`;

    message += `\r\r ${this._translate.instant(i18n<string>('ALL.COMMON.Thanks'))}`;

    // Load issue content
    return { message, subject: this._translate.instant(i18n<string>('VIEWS_WINDOWED.MODALS.NEW_ISSUE.ORDER_BATTERY.Object')) };
  }

  /** */
  private _map_battery_types(array_to_map: {
    [key in DEVICE_BATTERY_TYPE | 'undefined']: {
      name: string;
      bat: DeviceBatInfo;
      contract: 'rent' | 'purchase' | 'loan';
      battery_price: BatteryPrice;
    }[];
  }): string {
    let message = '';

    mapValues(array_to_map, (_devices, battery_type: DEVICE_BATTERY_TYPE | 'undefined') => {
      if (battery_type !== 'undefined') {
        const totals = _devices.map(d => d?.battery_price?.price || 0);
        const total = sum(totals);

        if (_devices.length > 1) {
          message += `\r   => ${this._translate.instant(
            i18n<string>('VIEWS_WINDOWED.MODALS.NEW_ISSUE.ORDER_BATTERY.[number] batteries of type [type]'),
            { number: _devices.length, type: battery_type }
          )}`;
        } else {
          message += `\r   => ${this._translate.instant(
            i18n<string>('VIEWS_WINDOWED.MODALS.NEW_ISSUE.ORDER_BATTERY.[number] battery of type [type]'),
            { number: _devices.length, type: battery_type }
          )}`;
        }

        if (total === 0) {
          message += ` (${this._translate.instant('DEVICE.ALL.CONTRACT.Included', 'EUR')})`;
        }
      }
    });

    return message;
  }

  /** */
  private build_contact_support_form() {
    const decoded_json = decodeJson(this.data);
    const decoded_error = JSON.parse(decoded_json);

    // Build message
    let message = `${this._translate.instant(i18n<string>('ALL.COMMON.Hello'))},`;
    message += `\r\r ${this._translate.instant(i18n<string>('VIEWS.MODALS.NEW_ISSUE.Error details'))}`;
    message += `\r\r Description: ${decoded_error.description}`;
    message += `\r Type: ${decoded_error.type}`;
    message += `\r Date: ${formatISO(decoded_error.date)}`;
    message += `\r`;
    message += `\r Event id: ${decoded_error.source.event_id}`;
    message += `\r Event type: ${decoded_error.source.event_type}`;
    message += `\r Event date: ${formatISO(decoded_error.source.event_date)}`;
    message += `\r\r ${this._translate.instant(i18n<string>('ALL.COMMON.Thanks'))}`;

    return { message, subject: this._translate.instant(i18n<string>('VIEWS.MODALS.NEW_ISSUE.Object')) };
  }

  /** */
  private build_device_support() {
    const decoded_json = decodeJson(this.data);
    const data: { name: string } = JSON.parse(decoded_json);

    // Build message
    let message = `${this._translate.instant(i18n<string>('ALL.COMMON.Hello'))},`;

    message += `\r\r${this._translate.instant(
      i18n<string>('VIEWS.MODALS.NEW_ISSUE.DEVICE_SUPPORT.I need support for the device "[name]"'),
      {
        name: data?.name,
      }
    )}.`;

    message += `\r\r${this._translate.instant(i18n<string>('VIEWS.MODALS.NEW_ISSUE.DEVICE_SUPPORT.My message:'), {})}`;
    message += `\r${this._translate.instant(i18n<string>('VIEWS.MODALS.NEW_ISSUE.DEVICE_SUPPORT.[Write your message here]'), {})}`;

    message += `\r\r ${this._translate.instant(i18n<string>('ALL.COMMON.Thanks'))}`;

    return {
      message,
      subject: this._translate.instant(i18n<string>('VIEWS.MODALS.NEW_ISSUE.Support for device [name]'), { name: data?.name }),
    };
  }

  // #endregion
}
