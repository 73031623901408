<div
  class="bg2-location-details-header"
  [ngClass]="{
    '--ghost': is_location_is_ghost$$ | async,
    '--compact': location_header_type === 'compact'
  }"
  [routerLink]="
    location_header_type === 'compact' ? ['', { outlets: { modal: ['location_details', { location_id: location_id$$ | async }] } }] : null
  "
  [queryParamsHandling]="'preserve'"
>
  <div class="location-header-details-container">
    <div class="location-name-and-exploitation">
      <div class="location-name">
        <bg2-adaptive-text-to-container
          [options]="{ color: '#494752' }"
          [text_to_adapt]="location_name$$ | async"
        ></bg2-adaptive-text-to-container>
      </div>

      <span
        class="location-exploitation"
        [class.navigate-to-exploitation]="location_header_type !== 'compact' && !env.config.disable_navigation"
        [routerLink]="
          !env.config.disable_navigation
            ? location_header_type !== 'compact'
              ? [{ outlets: { primary: ['exploitations', exploitation_id$$ | async], modal: ['raz'] } }]
              : null
            : null
        "
      >
        {{ exploitation_name$$ | async }}
      </span>
    </div>
  </div>

  <div class="location-header-image-container">
    <div
      *ngIf="location_banner_image$$ | async; let banner_image"
      class="location-header-image-container__image"
      [ngStyle]="{ 'background-image': 'url(\'' + banner_image + '\')' }"
    ></div>

    <ng-container *ngIf="location_has_apiary$$ | async">
      <bg2-button
        class="location-header-image-container__alarm-status"
        [ngStyle]="{ display: (alarms ?? [])?.length === 0 ? 'none' : 'initial' }"
        *ngLet="movement_alarms$$ | async; let alarms"
        [type]="'navigation'"
        [options]="{ is_thin: true, icon_size_class: 'mdi-24px' }"
        [color]="(alarms ?? [])?.length > 0 ? 'red' : (apiary_has_devices_for_movement_detection$$ | async) ? 'green' : 'orange'"
        [icon]="
          (alarms ?? [])?.length > 0
            ? 'mdi-shield-alert'
            : (apiary_has_devices_for_movement_detection$$ | async)
            ? 'mdi-shield-check'
            : 'mdi-shield-off'
        "
        (btn_click)="location.update_is_alarms_displayed($event)"
      ></bg2-button>
    </ng-container>

    <bg2-button
      *ngIf="location_header_type !== 'compact' && (location_has_ace$$('write_all') | async)"
      class="location-header-image-container__location-settings"
      [icon]="'mdi-pencil'"
      [type]="'navigation'"
      [options]="{ is_thin: true }"
      [matMenuTriggerFor]="locationConfigMenu"
      [disabled]="(location_has_ace$$('write_all') | async) === false"
      [popper]="'VIEWS.LOCATIONS.SHARED.LOCATION_DETAILS.LOCATION_DETAILS_HEADER.Settings' | translate"
    ></bg2-button>

    <mat-menu #locationConfigMenu="matMenu" [class]="'location-settings-menu'">
      <button
        mat-menu-item
        [queryParamsHandling]="'preserve'"
        [routerLink]="[{ outlets: { modal: ['update_entity', { eid: (location_id$$ | async) }] } }]"
      >
        <span class="mdi mdi-pencil mdi-24px"></span>
        <span>{{ 'VIEWS.LOCATION_CARD.Edit the location' | translate }}</span>
      </button>

      <button
        mat-menu-item
        [queryParamsHandling]="'preserve'"
        [routerLink]="[{ outlets: { modal: ['update_entity_acl', { eid: (location_id$$ | async) }] } }]"
      >
        <span class="mdi mdi-account-key mdi-24px"></span>
        <span>{{ 'VIEWS.LOCATION_CARD.Configure access control' | translate }}</span>
      </button>
    </mat-menu>

    <ng-container *ngIf="location_header_type === 'modal'">
      <bg2-button
        class="location-header-image-container__close-location"
        [type]="'navigation'"
        [icon]="'mdi-close-box'"
        [options]="{ is_thin: true }"
        [popper]="'VIEWS.LOCATION_CARD.Close location details' | translate"
        (btn_click)="when_quit.emit()"
      ></bg2-button>
    </ng-container>
  </div>
</div>
