import { isEmpty, isNil, template } from 'lodash-es';

/** */
export const build_template_ticket_comment_header = (title?: string): string => {
  const template_builder: string[] = [];

  template_builder.push(
    '<div>',

    '<span style="font-size: 0.8em; color: gray">',
    "<i>[Message automatique de l'application]</i>",
    '</span>',

    '</div>'
  );

  if (!isNil(title) || !isEmpty(title)) {
    template_builder.push(`<h1>${title}</h1>`);
  }

  return template_builder.join('');
};
