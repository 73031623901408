import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'form-element-header',
  templateUrl: './element-header.component.html',
  styleUrls: ['./element-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FormElementHeaderComponent {
  @Input() schema: any;
  @Input() hide_title = false;  // TODO: rename it hideTitle
  @Input() hide_description = false;
  @Input() showLabel = true;
  @Input() title_style: '' | 'large' = '';
  @Input() additionnal_text = '';
}
