<div class="bg-dialog" *ngIf="input_params">
  <!-- Dialog header -->
  <dialog-header *ngIf="input_params.header" [header]="input_params.header"></dialog-header>

  <!-- Dialog body -->
  <dialog-body *ngIf="input_params.body" [content]="input_params.body" (action)="clicked($event)" (form_values)="onFormValues($event)">
  </dialog-body>

  <!-- Dialog footer -->
  <div class="dialog-footer" [ngStyle]="input_params.footer.styles">
    <bg2-button
      *ngFor="let button of input_params.footer.buttons.items"
      [type]="'action'"
      [color]="button.color"
      [icon]="button?.icon ?? undefined"
      [ngStyle]="{ flex: style_flex$$ | async }"
      [message]="button?.content | translate"
      [styles]="input_params.footer.buttons.styles"
      (btn_click)="clicked(button.result)"
    >
    </bg2-button>
  </div>
</div>
