<bg2-modal class="eval-modal"
  [fullscreen]="fullscreen || false" #modal>

  <bg2-modal-header>
    <div class="header">
      <span class="title">{{ 'VIEWS.MODALS.EVAL.Apiary Visit' | translate }}</span>
    </div>
    <div class="action-buttons">
      <doc-helper page="Visit_and_evaluation" iconSize="36px" ></doc-helper>
      <span class="mdi mdi-close-box mdi-36px" (click)="close()"></span>
    </div>
  </bg2-modal-header>

  <!-- ----------------------------- CONTENTS ----------------------------- -->
  <bg2-modal-content>
    <div class="loading" [class.initial]="initial_loading$$ | async" *ngIf="(loading$$ | async) || !isConfigFormReady()">
      <hex-loading [size]="(initial_loading$$ | async) ? 'medium' : 'small'"></hex-loading>
    </div>

    <!-- config content -->
    <ng-container *ngIf="state === ActiveView.CONFIG && isConfigFormReady()">
      <sf-form [schema]="config_schema" [(model)]="form_model" (onChange)="onFormChange($event)"
        (onErrorChange)="logErrors($event.value)" (isValid)="setFormValid($event)" #configForm>
      </sf-form>
    </ng-container>
    <!-- End config content -->

    <!-- Apiary form content -->
    <ng-container *ngIf="state === ActiveView.FORM_A && isEventFormReady()">
      <!-- Show apiary eval form -->
      <sf-form [schema]="apiary_evaluation_schema" [(model)]="form_model" (onChange)="onFormChange($event)"
        (onErrorChange)="logErrors($event.value)" (isValid)="setFormValid($event)" #apiaryEvalForm>
      </sf-form>
    </ng-container>

    <!-- Hive fast form content -->
    <ng-container *ngIf="state === ActiveView.FORM_HF && isEventFormReady()">
      <sf-form [schema]="hf_form_schema" [(model)]="form_model" (onChange)="onFormChange($event)"
        (onErrorChange)="logErrors($event.value)" (isValid)="setFormValid($event)" #hiveFastEvalForm>
      </sf-form>
    </ng-container>

    <!-- Hive precise form content -->
    <ng-container *ngIf="state === ActiveView.FORM_HP && isEventFormReady()">
      <div class="hive-selector">
        <ul class="hive-list" #hivesList>
          <li *ngFor="let hive of all_hives; let hive_num = index;" [class.selected]="hive_num === selected_hive_num">
            <a href="javascript:void(0)" (click)="selectHive(hive_num)">
              <bg2-picto-hive [hive]="hive" [title]="false"
                [htype]="hp_form_model[hive_num]?.data?.evaluation_hive?.hive?.htype"
                [size]="hive_num === selected_hive_num ? '100px' : '45px'" [GPS]="false" [WGuard]="['']"
                [status]="hive_states[hive_num]">
              </bg2-picto-hive>
              <span>{{ (hp_form_model[hive_num]?.data?.evaluation_hive?.hive?.name || '') | truncate:13:'...' }}</span>
            </a>
          </li>

          <!-- Special to add more hives -->
          <li class="add-hive" style="text-align: center;" (click)="addHiveIntoHivePreciseEval()">
            <span class="mdi mdi-36px mdi-plus-circle-outline"></span>
          </li>
        </ul>

        <div class="progress progress">
          <span class="status-text">{{ progress }} / {{ all_hives.length }}</span>
          <div class="status-color" [style.width.%]="(progress / all_hives.length) * 100"></div>
        </div>
      </div>

      <!-- Show hive eval form -->
      <sf-form *ngIf="!selected_hive_changed" [schema]="hp_form_schema[0]" [(model)]="hp_form_model[selected_hive_num]"
        (onChange)="hivePreciseEvaluationFormChange(selected_hive_num, $event)"
        (onErrorChange)="logErrors($event.value)" (isValid)="setFormValid($event)" #hiveEvalForm>
      </sf-form>

      <div class="hive-loading" *ngIf="selected_hive_changed">
        <hex-loading [size]="'medium'"></hex-loading>
      </div>
    </ng-container>

    <!-- Summary content -->
    <ng-container *ngIf="state === ActiveView.SUMMARY && isEventFormReady() && (loading$$ | async) === false">
      <div class="summary" *ngIf="event">
        <!-- HTTP error -->
        <ng-container *ngIf="HTTPError.display">
          <html-error [error]="HTTPError"></html-error>
        </ng-container>

        <!-- Informations about the event -->
        <div>
          <h5 class="h5-modified">
            {{'VIEWS.MODALS.EVAL.[date_str] visit' | translate:{date_str: (event.date | formatDate:'short') } }}
          </h5>
          
          <ul class="event-global">
            <li *ngIf="(event.apiary$$ | async) as apiary;">
              <b>{{ 'ENTITY.ALL.TYPE.apiary' | translate | colon }}</b>
              {{ apiary.name }}
            </li>

            <li *ngIf="(event.location$$ | async) as location;">
              <b>{{ 'ENTITY.ALL.TYPE.location' | translate | colon }}</b>
              {{location.name }}
            </li>

            <li *ngIf="(event.author$$ | async) as author;">
              <b>{{ 'VIEWS.MODALS.EVAL.Visit done by' | translate | colon }}</b>
              {{author.username}}
            </li>
            
            <li *ngIf="(event.visit_type$$ | async) as visit_type;">
              <b>{{ 'VIEWS.MODALS.EVAL.Visit type:' | translate }}</b>
              <ng-container *ngIf="visit_type?.length <= 0; else showTypes;">
                {{ 'VIEWS.MODALS.EVAL.Not precised' | translate }}
              </ng-container>

              <ng-template #showTypes>
                <b *ngFor="let i18n of visit_type; let i = index;">
                  {{ i18n | translate }}
                  <span *ngIf="(visit_type.length > 1) && (i !== visit_type.length - 1)">, </span>
                </b>
              </ng-template>
            </li>

            <ng-container *ngIf="(event.visit_type$$ | async)?.includes('EVENT.VISIT.BY_HIVE.fast')">
              <li *ngIf="event?.hf_comment$$ | async; let hf_comment">
                <b>{{ 'ALL.COMMON.Comment' | translate }} ({{ 'EVENT.VISIT.BY_HIVE.fast' | translate }}) : </b>
                {{ hf_comment }}
              </li>
            </ng-container>

          </ul>
        </div>

        <!-- Summary informations -->
        <div class="global-apiary-summary">
          <!-- Global visit summary -->
          <div class="global-informations">
            <!-- Hardware stocks -->
            <div *ngIf="event.hardware_stocks$$ | async; let hardware_stocks;">
              <h6 class="h6-modified" translate>EVENT.VISIT.Hardware stock</h6>
              <ul class="browser-default">
                <ng-container *ngFor="let hw_stock_key of (hardware_stocks | keys)">
                  <li *ngIf="hw_stock_key !== 'comment'">{{ hardware_stocks[hw_stock_key].label | translate }} :
                    {{ hardware_stocks[hw_stock_key].value }}</li>
                </ng-container>
              </ul>
              <span *ngIf="hardware_stocks['comment']?.value">{{ 'VIEWS.MODALS.EVAL.Comment :' | translate }}
                {{ hardware_stocks['comment'].value }}</span>
            </div>

            <!-- Sanitary observations -->
            <div *ngIf="event.sanitary_observations$$ | async; let observations;">
              <h6 class="h6-modified" translate>EVENT.VISIT.Sanitary observations</h6>
              <ul class="browser-default">
                <li *ngFor="let observation of observations">
                  <span>{{observation.label | translate}}</span>
                  <ul class="browser-default">
                    <li *ngIf="observation.other">{{'VIEWS.MODALS.EVAL.Other :' | translate}}
                      {{observation.other | translate}}</li>
                    <li *ngIf="observation.comment">{{'VIEWS.MODALS.EVAL.Comment :' | translate}}
                      {{observation.comment}}</li>
                  </ul>
                </li>
              </ul>
            </div>

            <!-- Sanitary tests -->
            <div *ngIf="event.sanitary_tests$$ | async; let sanitary_tests;">
              <h6 class="h6-modified" translate>EVENT.VISIT.Sanitary tests</h6>
              <ul class="browser-default">
                <li *ngFor="let sanitary_test of sanitary_tests">
                  <span *ngIf="sanitary_test.label">{{sanitary_test.label | translate}}</span>
                  <ul class="browser-default">
                    <ng-container *ngIf="sanitary_test.type === 'VP100'">
                      <li *ngIf="sanitary_test.VP100.label">{{'VIEWS.MODALS.EVAL.VP100.Variant :' | translate}}
                        {{sanitary_test.VP100.label | translate}}</li>
                      <li *ngIf="sanitary_test.VP100.result">{{'VIEWS.MODALS.EVAL.VP100.Results :' | translate}}
                        {{sanitary_test.VP100.result}}</li>
                    </ng-container>
                    <ng-container *ngIf="sanitary_test.type === 'foulbrood'">
                      <li *ngIf="sanitary_test.foulbrood.result" translate>VIEWS.MODALS.EVAL.Positif test result</li>
                      <li *ngIf="!sanitary_test.foulbrood.result" translate>VIEWS.MODALS.EVAL.Negatif test result</li>
                    </ng-container>
                    <li *ngIf="sanitary_test.comment">{{'VIEWS.MODALS.EVAL.Comment :' | translate}}
                      {{sanitary_test.comment}}</li>
                  </ul>
                </li>
              </ul>
            </div>

            <!-- Sanitary treatments -->
            <div *ngIf="event.sanitary_treatments$$ | async; let sanitary_treatments;">
              <h6 class="h6-modified" translate>EVENT.VISIT.Sanitary treatments</h6>
              <ul class="browser-default">
                <li *ngFor="let sanitary_treatment of sanitary_treatments">
                  <span *ngIf="sanitary_treatment.label">{{sanitary_treatment.label | translate}}</span>
                  <ul class="browser-default">
                    <li *ngIf="sanitary_treatment.test_type_b">{{'EVENT.VISIT.Name of the treatment' | translate}}:
                      {{sanitary_treatment.test_type_b}}</li>
                    <li *ngIf="sanitary_treatment.test_type_c">{{'EVENT.VISIT.Name of the treatment' | translate}}:
                      {{sanitary_treatment.test_type_c}}</li>
                    <li *ngIf="sanitary_treatment.comment">{{'VIEWS.MODALS.EVAL.Comment :' | translate}}
                      {{sanitary_treatment.comment}}</li>
                  </ul>
                </li>
              </ul>
            </div>

            <!-- Feeding -->
            <div *ngIf="event.feeding$$ | async; let feeding;">
              <h6 class="h6-modified" translate>EVENT.VISIT.Feeding</h6>
              <ul class="browser-default">
                <li *ngIf="feeding?.nb_feeded_hive">{{ feeding.nb_feeded_hive.label | translate }} :
                  {{ feeding.nb_feeded_hive.value }}</li>
                <li *ngIf="feeding?.nb_feeded_nuc">{{ feeding.nb_feeded_nuc.label | translate }} :
                  {{ feeding.nb_feeded_nuc.value }}</li>
                <li>
                  <span *ngIf="feeding.feeding_type.value">{{feeding.feeding_type.value | translate}}</span>
                  <ul class="browser-default">
                    <li *ngIf="feeding?.quantity_kg">{{ feeding.quantity_kg.label | translate }} :
                      {{ feeding.quantity_kg.value }}</li>
                    <li *ngIf="feeding?.quantity_L">{{ feeding.quantity_L.label | translate }} :
                      {{ feeding.quantity_L.value }}</li>
                    <li *ngIf="feeding?.comment">{{ 'VIEWS.MODALS.EVAL.Comment :' | translate }} {{ feeding.comment }}
                    </li>
                  </ul>
                </li>
              </ul>
            </div>

            <!-- Next actions -->
            <div *ngIf="event.next_actions$$ | async; let next_actions;">
              <h6 class="h6-modified" translate>EVENT.VISIT.Next actions</h6>
              <ul class="browser-default">
                <li *ngFor="let next_action of next_actions">
                  <span *ngIf="next_action.value !== 'other'">{{next_action.label | translate}}</span>
                  <span *ngIf="next_action.value === 'other'">{{next_action.other | translate}}</span>
                  <ul class="browser-default">
                    <li *ngIf="next_action.comment">{{'VIEWS.MODALS.EVAL.Comment :' | translate}}
                      {{next_action.comment}}</li>
                  </ul>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <!-- Hives informations repartition -->
        <div class="hives-summary">
          <h6 *ngIf="event.has_hive_data$$ | async" class="h6-modified" translate>EVENT.VISIT.Hives status</h6>

          <!-- Statuses repartition -->
          <div class="repartitions-resume">
            <div class="repartition-chart" *ngIf="event.hive_statuses$$ | async; let repartition;">
              <h6 translate>EVENT.VISIT.Hive status</h6>
              <bg2-histogram [histogram_type]="'statuses'" [histogram_height]="'120px'" [data]="repartition">
              </bg2-histogram>
            </div>

            <!-- Brood_frames repartition -->
            <div class="repartition-chart" *ngIf="event.broodframes$$ | async; let repartition;">
              <h6 translate>EVENT.VISIT.Brood frames</h6>
              <bg2-histogram [histogram_type]="'broodframes'" [histogram_height]="'120px'" [data]="repartition">
              </bg2-histogram>
            </div>

            <!-- Queen colors repartition -->
            <div class="repartition-chart" *ngIf="event.queen_colors$$ | async; let repartition;">
              <h6 translate>EVENT.VISIT.Queen colors</h6>
              <bg2-histogram [histogram_type]="'queens'" [histogram_height]="'120px'" [data]="repartition">
              </bg2-histogram>
            </div>

            <!-- Supers repartition -->
            <div class="repartition-chart" *ngIf="event.supers_and_stocks$$ | async; let repartition;">
              <h6 translate>EVENT.VISIT.Supers</h6>
              <bg2-histogram [histogram_type]="'supers'" [histogram_height]="'120px'" [data]="repartition">
              </bg2-histogram>
            </div>
          </div>
        </div>

        <!-- Hives detail -->
        <div class="hives-detail" *ngIf="event.data.config.by_hives === 'advanced'">
          <ng-container *ngIf="(event.hp_visit_results_with_hive$$ | async); let hp_visit_results;">
            <h6 class="h6-modified" translate>EVENT.VISIT.Summary by hive</h6>
            <div *ngFor="let hive_eval of hp_visit_results">
              <details>
                <summary><b>{{ hive_eval.hive?.static_state?.name }}</b> : {{ hive_eval['state_label'] | translate }}
                  <span *ngIf="hive_eval.hive_status">({{ hive_eval['hive_status_label'] | translate }})</span>
                </summary>

                <!-- Change displaying depending on hive state -->
                <div [ngSwitch]="hive_eval.state">

                  <!-- Mortality state of the hive -->
                  <div *ngSwitchCase="'D'">
                    <ul class="browser-default">
                      <li>{{ 'EVENT.VISIT.Observation' | translate }}:
                        {{ hive_eval.mortality['observation_label'] | translate }}</li>
                      <li>{{ 'EVENT.VISIT.DEAD.Supposed cause' | translate }}:
                        {{ hive_eval.mortality['cause_supposed_label'] | translate }}</li>
                      <li>{{ 'EVENT.VISIT.DEAD.Next action' | translate }}:
                        {{ hive_eval.mortality['next_action_label'] | translate }}</li>
                      <li *ngIf="hive_eval.mortality.comment">
                        {{ 'VIEWS.MODALS.EVAL.Comment on the mortality :' | translate }} {{hive_eval.mortality.comment}}
                      </li>
                    </ul>
                    <p *ngIf="hive_eval.comment">{{ 'VIEWS.MODALS.EVAL.Comment :' | translate }} {{hive_eval.comment}}
                    </p>
                  </div>

                  <!-- Thiefted state of the hive -->
                  <div *ngSwitchCase="'T'">
                    <b *ngIf="!hive_eval.comment">{{ 'ALL.COMMON.No informations to display !' | translate }}</b>
                    <span *ngIf="hive_eval.comment">{{ 'VIEWS.MODALS.EVAL.Comment :' | translate }}
                      {{hive_eval.comment}}</span>
                  </div>

                  <!-- OK state of the hive -->
                  <div *ngSwitchCase="'OK'">

                    <!-- Dynamism -->
                    <ul *ngIf="hive_eval?.global_state?.dynamism as dynamism;" class="browser-default">
                      <b>{{ 'EVENT.VISIT.Hive dynamic scoring' | translate }}</b>
                      <li>{{ 'ALL.MENU.Dynamism' | translate }}: {{ dynamism }}</li>
                    </ul>

                    <!-- Queen status -->
                    <ng-container *ngIf="event?.data?.config?.hives_precise.includes('queen')">
                      <ul *ngIf="hive_eval.queen; let queen;" class="browser-default">
                        <b>{{ 'EVENT.VISIT.Queen state' | translate }}</b>
                        <li>{{ 'EVENT.VISIT.Status:' | translate }} {{ queen['status_label'] | translate }}</li>
                        <li *ngIf="queen.color">{{ 'EVENT.VISIT.Color:' | translate }}
                          {{ queen['color_label'] | translate }}</li>
                        <li>{{ 'EVENT.VISIT.Queen cells' | translate }}
                          {{ queen.queen_cell['status_label'] | translate }}<span *ngIf="queen.queen_cell.action">
                            ({{ 'EVENT.VISIT.Action' | translate | lowercase }}:
                            {{ queen.queen_cell['action_label'] | translate }})</span></li>
                      </ul>
                    </ng-container>

                    <!-- Supers -->
                    <ng-container *ngIf="event?.data?.config?.hives_precise.includes('supers')">
                      <ul *ngIf="hive_eval.supers; let supers;" class="browser-default">
                        <b>{{ 'EVENT.VISIT.Supers' | translate }}</b>
                        <li>{{ 'EVENT.VISIT.Number of supers on the hive' | translate }}: {{supers.nb_supers}}</li>
                      </ul>
                    </ng-container>

                    <!-- Feeding -->
                    <ng-container *ngIf="event?.data?.config?.hives_precise.includes('feeding')">
                      <ul *ngIf="hive_eval.feeding; let feeding;" class="browser-default">
                        <b>{{ 'EVENT.VISIT.Feeding' | translate }}</b>
                        <li>{{ 'EVENT.FEEDING.Type of feeding' | translate }}:
                          {{ feeding.feeding_type_other || (feeding.feeding_type | translate)}}</li>
                        <li *ngIf="feeding.quantity_kg">{{ 'EVENT.VISIT.Quantity:' | translate }}
                          {{feeding.quantity_kg}} kg</li>
                        <li *ngIf="feeding.comment">{{ 'VIEWS.MODALS.EVAL.Comment :' | translate }} {{feeding.comment}}
                        </li>
                      </ul>
                    </ng-container>

                    <!-- Brood_frames -->
                    <ng-container *ngIf="event?.data?.config?.hives_precise.includes('brood_frame')">
                      <ul *ngIf="hive_eval.brood_box; let brood_box;" class="browser-default">
                        <b>{{ 'EVENT.VISIT.Brood box' | translate }}</b>
                        <li *ngIf="!__isNil(brood_box.brood_frame?.size)">
                          {{ 'EVENT.VISIT.Broodframes number:' | translate }} {{brood_box.brood_frame.size}}</li>
                        <li *ngIf="!__isNil(brood_box.brood_frame?.added)">
                          {{ 'EVENT.VISIT.Added broodframes number:' | translate }} {{brood_box.brood_frame.added}}</li>
                        <li *ngIf="!__isNil(brood_box.brood_frame?.homogeneous)">
                          {{ 'EVENT.VISIT.Homogeneous brood' | translate }}:
                          {{ brood_box.brood_frame.homogeneous ? ('ALL.COMMON.Yes' | translate) : ('ALL.COMMON.No' | translate) }}
                        </li>
                        <li *ngIf="!__isNil(brood_box.brood_frame?.eggs)">
                          {{ 'EVENT.VISIT.Presence of eggs' | translate }}:
                          {{brood_box.brood_frame.eggs ? ('ALL.COMMON.Yes' | translate) : ('ALL.COMMON.No' | translate) }}
                        </li>
                        <li *ngIf="!__isNil(brood_box.empty_frames?.added)">
                          {{ 'EVENT.VISIT.Empty frames number added:' | translate }} {{brood_box.empty_frames.added}}
                        </li>
                        <li *ngIf="!__isNil(brood_box.stores_frames?.size)">
                          {{ 'EVENT.VISIT.Frames number in stock:' | translate }} {{brood_box.stores_frames.size}}</li>
                        <li *ngIf="!__isNil(brood_box.stores_frames?.added)">
                          {{ 'EVENT.VISIT.Frames number added in stock:' | translate }}
                          {{brood_box.stores_frames.added}}
                        </li>
                        <li *ngIf="brood_box.comment">{{ 'VIEWS.MODALS.EVAL.Comment :' | translate }}
                          {{brood_box.comment}}
                        </li>
                      </ul>
                    </ng-container>

                    <!-- Others -->
                    <p *ngIf="hive_eval.comment">{{ 'VIEWS.MODALS.EVAL.Comment :' | translate }} {{hive_eval.comment}}
                    </p>
                  </div>

                </div>
              </details>
            </div>
          </ng-container>
        </div>
      </div>
    </ng-container>
  </bg2-modal-content>

  <!-- ----------------------------- FOOTERS ----------------------------- -->
  <bg2-modal-footer>
    <div class="sub-footer" *ngIf="(loading$$ | async) === false">
      <bg2-button
        *ngIf="event?.id && (state === ActiveView.SUMMARY)"
        [type]="'delete'"
        [message]="'VIEWS.MODALS.EVAL.Delete this visit' | translate"
        [icon]="'mdi-delete'"
        [disabled]="apiary?.hasACE('write_all_events') === false"
        [options]="{ is_thin: true }"
        (btn_click)="deleteApiaryEvaluation(event.id)">
      </bg2-button>
      <span *ngIf="!event || !event.id || state !== ActiveView.SUMMARY">&nbsp;</span>

      <!-- Steps switcher -->
      <bg2-button
        *ngIf="state !== ActiveView.SUMMARY && state !== ActiveView.FORM_HP"
        [type]="'navigation'"
        [message]="btnStepSwitch"
        (btn_click)="event.is_valid ? goNextState() : false">
      </bg2-button>
      <ng-template #btnStepSwitch>
        {{ 'VIEWS.MODALS.EVAL.Go next step :' | translate }}
        <ng-container [ngSwitch]="next_state">
          <ng-container *ngSwitchCase="ActiveView.FORM_A">{{ 'VIEWS.MODALS.EVAL.Apiary' | translate }}</ng-container>
          <ng-container *ngSwitchCase="ActiveView.FORM_HP">{{ 'VIEWS.MODALS.EVAL.Hive precise' | translate }}</ng-container>
          <ng-container *ngSwitchCase="ActiveView.FORM_HF">{{ 'VIEWS.MODALS.EVAL.Hive fast' | translate }}</ng-container>
          <ng-container *ngSwitchCase="ActiveView.SUMMARY">{{ 'VIEWS.MODALS.EVAL.Save' | translate }}</ng-container>
        </ng-container>
      </ng-template>

      <!-- Precise visit switcher -->
      <ng-container *ngIf="state === ActiveView.FORM_HP">
        <bg2-button
          *ngIf="selected_hive_num < all_hives.length - 1"
          [type]="'navigation'"
          (btn_click)="selectHive(selected_hive_num + 1)"
          [message]="'VIEWS.MODALS.EVAL.Edit next hive' | translate">
        </bg2-button>
        <bg2-button
          *ngIf="selected_hive_num === all_hives.length - 1"
          [type]="'navigation'" (btn_click)="event.is_valid ? goNextState() : false"
          [message]="btnLastPreciseVisitSwitcher">
        </bg2-button>
        <ng-template #btnLastPreciseVisitSwitcher>
          {{ 'VIEWS.MODALS.EVAL.Go next step :' | translate }}
          <ng-container *ngIf="(next_state === ActiveView.SUMMARY)">{{ 'VIEWS.MODALS.EVAL.Save' | translate }}</ng-container>
        </ng-template>
      </ng-container>
    </div>

    <ul *ngIf="(loading$$ | async) === false" class="eval-breadcrumb">

      <!-- #region .config-breadcrumb -->
      <li class="breadcrumb-item" [ngClass]="{
          'active': state === ActiveView.CONFIG,
          'disabled': !apiary?.hasACE('write_all_events') || (event?.is_valid$$ | async) === false || ((apiary.dirty$$ | async)) || (loading$$ | async)
        }"
        (click)="state === ActiveView.CONFIG ? false : (event.is_valid ? (apiary?.hasACE('write_all_events') ?  changeState(ActiveView.CONFIG) : false) : false)">
        <div>
          <span class="mdi mdi-cog mdi-24px"></span>
          <span class="description">&nbsp;{{ 'VIEWS.MODALS.EVAL.Configuration' | translate }}</span>
        </div>
      </li>
      <!-- #endregion -->

      <!-- #region .apiary-form-breadcrumb -->
      <li *ngIf="eval_apiary === true" class="breadcrumb-item" [ngClass]="{
          'active': state === ActiveView.FORM_A,
          'disabled': !apiary?.hasACE('write_all_events') || (event?.is_valid$$ | async) === false || ((apiary.dirty$$ | async)) || (loading$$ | async)
        }"
        (click)="state === ActiveView.FORM_A ? false : (event.is_valid ? (apiary?.hasACE('write_all_events') ?  changeState(ActiveView.FORM_A) : false) : false)">
        <div>
          <img src="/assets/img/eval/apiary.png" alt="{{ 'VIEWS.MODALS.EVAL.Apiary' | translate }}">
          <span class="description">&nbsp;{{ 'VIEWS.MODALS.EVAL.Apiary' | translate }}</span>
        </div>
      </li>
      <!-- #endregion -->

      <!-- #region .hive-fast-form-breadcrumb -->
      <li *ngIf="eval_hives_fast === true" class="breadcrumb-item" [ngClass]="{
          'active': state === ActiveView.FORM_HF,
          'disabled': !apiary?.hasACE('write_all_events') || (event?.is_valid$$ | async) === false || ((apiary.dirty$$ | async)) || (loading$$ | async)
        }"
        (click)="state === ActiveView.FORM_HF ? false : (event.is_valid ? (apiary?.hasACE('write_all_events') ?  changeState(ActiveView.FORM_HF) : false) : false)">
        <div>
          <img src="/assets/img/eval/hive_fast.png" alt="{{ 'VIEWS.MODALS.EVAL.Hive fast' | translate }}">
          <span class="description">&nbsp;{{ 'VIEWS.MODALS.EVAL.Hive fast' | translate }}</span>
        </div>
      </li>
      <!-- #endregion -->

      <!-- #region .hive-precise-form-breadcrumb -->
      <li *ngIf="eval_hives_precise === true" class="breadcrumb-item" [ngClass]="{
          'active': state === ActiveView.FORM_HP,
          'disabled': !apiary?.hasACE('write_all_events') || (event?.is_valid$$ | async) === false || ((apiary.dirty$$ | async)) || (loading$$ | async)
        }"
        (click)="state === ActiveView.FORM_HP ? false : (event.is_valid ? (apiary?.hasACE('write_all_events') ?  changeState(ActiveView.FORM_HP) : false) : false)">
        <div>
          <img src="/assets/img/eval/hive_step.png" alt="{{ 'VIEWS.MODALS.EVAL.Hive precise' | translate }}">
          <span class="description">&nbsp;{{ 'VIEWS.MODALS.EVAL.Hive precise' | translate }}</span>
        </div>
      </li>
      <!-- #endregion -->

      <!-- #region .save-breadcrumb -->
      <li *ngIf="state !== ActiveView.SUMMARY" class="breadcrumb-item"
        [class.disabled]="!apiary?.hasACE('write_all_events') || (event?.is_valid$$ | async) === false"
        (click)="event.is_valid ? (apiary?.hasACE('write_all_events') ?  changeState(ActiveView.SUMMARY) : false) : false">
        <div>
          <span class="icon mdi mdi-content-save mdi-24px"></span>
          <span class="description">&nbsp;{{ 'VIEWS.MODALS.EVAL.Save' | translate }}</span>
        </div>
      </li>
      <!-- #endregion -->

      <!-- #region .close-breadcrumb -->
      <li *ngIf="state === ActiveView.SUMMARY" class="breadcrumb-item close"
        [class.active]="state === ActiveView.SUMMARY" (click)="close()">
        <div>
          <span class="icon mdi mdi-close-box mdi-24px"></span>
          <span class="description">&nbsp;{{ 'VIEWS.MODALS.FORM.Close' | translate }}</span>
        </div>
      </li>
      <!-- #endregion -->
    </ul>
  </bg2-modal-footer>
</bg2-modal>