import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';

import { BehaviorSubject, combineLatest, Observable, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, take, tap } from 'rxjs';
import { distinctUntilRealChanged, replay } from '@bg2app/tools/rxjs';

import {
  clone,
  every,
  has,
  isNil,
  keys,
  some,
} from 'lodash-es';

import { Dictionary } from 'app/typings/core/interfaces';

import { AbstractDialogComponent, AbstractDialogParams } from 'app/widgets/dialogs-modals/abstract-dialog.component';

import { FieldConfig, GlobalVisitResult, VisitValues } from '../models';
import { FastVisitOutput } from '../models';

export interface FastVisitDialogParams extends AbstractDialogParams {
  initial_value: FastVisitOutput;
  fields: {
    config: any;
    order: any;
  };
  status: {
    nb_elements: number;
  };
}

export function isFieldActif(field: FieldConfig, visit: VisitValues): boolean {
  let actif = true;
  if (!field) {
    return false;
  }
  if (!field.actif_if || keys(field.actif_if).length === 0) {
    actif = true;
  } else {
    const actifs = keys(field.actif_if).map(ofield => {
      const values = field.actif_if[ofield];
      return !visit[ofield] || values.includes(visit[ofield]);
    });
    actif = some(actifs);
  }
  return actif;
}


@Component({
  selector: 'bg2-fastvisit-dialog',
  templateUrl: './fastvisit-dialog.component.html',
  styleUrls: ['./fastvisit-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class FastVisitDialogComponent extends AbstractDialogComponent<FastVisitDialogParams, FastVisitOutput> implements OnInit, OnDestroy {
  // #region -> (input parameters)

  public fields_order$$: Observable<string[]> = this.input_params$$.pipe(
    map(params => params?.fields?.order || [])
  );

  public fields_config$$: Observable<Dictionary<FieldConfig>> = this.input_params$$.pipe(
    map(params => params?.fields?.config || {})
  );

  public nb_elements$$: Observable<number> = this.input_params$$.pipe(
    map(params => params?.status?.nb_elements || 0),
    distinctUntilRealChanged(),
    replay()
  );

  public fields$$: Observable<FieldConfig[]> = combineLatest([this.fields_config$$, this.fields_order$$]).pipe(
    map(([configs, order]) => order.map(field_name => configs[field_name])),
    replay()
  );

  public nb_fields$$ = this.fields_order$$.pipe(
    map(order => order.length),
    replay()
  );

  // #endregion

  // #region -> (component basics)

  /** */
  private _incoming_params_sub: Subscription = null

  ngOnInit(): void {
    this._incoming_params_sub = this.input_params$$.subscribe();
    
    const input_history = this.input_params?.initial_value.visit_history || [];
    const input_visit_idx = this.input_params?.initial_value.visit_idx || 0;
    this._visit_history$$.next(input_history);
    this._current_visit_idx$$.next(input_visit_idx);
    if (input_history.length === input_visit_idx) {
      if(input_visit_idx === 0) {
        this._current_visit_idx$$.next(-1);
      }
      this.visitNext();
    }
  }

  ngOnDestroy(): void {
    this._incoming_params_sub?.unsubscribe();
  }

  // #endregion

  // #region -> (timers management)

  public readonly NEXT_TIMEOUT = 500;

  private _next_to: NodeJS.Timeout;
  private _next_timer: NodeJS.Timeout;

  private _next_tick$: BehaviorSubject<number> = new BehaviorSubject(this.NEXT_TIMEOUT);
  public next_tick$$: Observable<number> = this._next_tick$.asObservable().pipe(replay());

  private startGoNextTimer(): void {
    this._will_go_next$.next(true);
    clearInterval(this._next_timer);
    this._next_tick$.next(this.NEXT_TIMEOUT);
    this._next_timer = setInterval(() => {
      this._next_tick$.next(this._next_tick$.getValue() - 100);
    }, 100);
    clearTimeout(this._next_to);
    this._next_to = setTimeout(() => {
      this.clearGoNext();
      this.visitNext();
      this._vibrate(500);
    }, this.NEXT_TIMEOUT);
  }

  /**
   * Clears the timer to the next hive visit.
   */
  public clearGoNext(): void {
    if (this._next_to) {
      this._will_go_next$.next(false);
      clearTimeout(this._next_to);
      clearInterval(this._next_timer);
    }
  }

  private _vibrate(time = 200): void {
    if (typeof window.navigator.vibrate === 'function') {
      window.navigator.vibrate(time);
    }
  }

  // #endregion

  // #region ->  (visit management)

  private _current_visit: VisitValues = {};

  public visit_default$$ = this.fields$$.pipe(
    map(fields => {
      const visit_default: Dictionary<any> = {};
      fields.forEach(field => {
        if (field.default) {
          visit_default[field.name] = field.default;
        }
      });
      return visit_default;
    }),
    replay()
  );

  /**
   * Subject of current visit idx.
   */
  private _current_visit_idx$$: BehaviorSubject<number> = new BehaviorSubject(0);

  /**
   * Observable of current visit idx.
   */
  public current_visit_idx$$: Observable<number> = this._current_visit_idx$$.asObservable().pipe(replay());

  /**
   * Internal accessor of current visit idx.
   */
  private get current_visit_idx(): number {
    return this._current_visit_idx$$.getValue();
  }

  private _visit_history$$: BehaviorSubject<VisitValues[]> = new BehaviorSubject([]);
  public visit_history$$: Observable<VisitValues[]> = this._visit_history$$.asObservable().pipe(distinctUntilChanged(), replay());

  private set visit_history(visit_history: VisitValues[]) {
    this._visit_history$$.next(visit_history);
  }
  private get visit_history(): VisitValues[] {
    return this._visit_history$$.getValue();
  }

  private history_and_idx$$ = combineLatest([
    this.current_visit_idx$$,
    this.visit_history$$
  ]).pipe(
    debounceTime(10),
    // tap(history_and_idx => console.log('history_and_idx', history_and_idx)),
    replay()
  );

  public visit_current$$: Observable<VisitValues> = this.history_and_idx$$.pipe(
    map(([idx, history]) => {
      if (idx < 0) {
        return {};
      } else if (isNil(history[idx])) {
        return {};
      }
      return history[idx];
    }),
    tap(visit_last => (this._current_visit = visit_last))
  );

  private set visit_current(visit: VisitValues) {
    const new_history = clone(this.visit_history);
    new_history[this.current_visit_idx] = visit;
    this.visit_history = new_history;
  }

  private get visit_current(): VisitValues {
    return this._current_visit;
  }

  /**
   * Back to the previous hive visit.
   */
  public backToPreviousVisit(): void {
    this.clearGoNext();
    if (this.current_visit_idx !== 0) {
      this._current_visit_idx$$.next(this.current_visit_idx - 1);
    }
  }

  /**
   * Handle click event on button of the fast visit
   *
   * @param field
   * @param value
   */
  public visitSelect(field: string, value: any): void {
    const click_already_selected = this.visit_current[field] === value;
    if (this._isLastValid() && this.goNextInProgress() && click_already_selected) {
      this.visitNext();
    } else {
      this.clearGoNext();
      const current = clone(this.visit_current);
      current[field] = value;
      this.visit_current = current;
      this._vibrate(100);
      if (this._isValid(current)) {
        this.startGoNextTimer();
      } else {
        this._vibrate(200);
      }
    }
  }

  /**
   * Checks if the last visit id valid.
   */
  private _isLastValid(): boolean {
    return this._isValid(this.visit_current);
  }

  public visitNext(): void {
    this.clearGoNext();
    this.visit_default$$.pipe(
      take(1)
    ).subscribe(visit_default => {
      const actual_idx = this.current_visit_idx;
      const actual_history = this.visit_history;
      const next = actual_history[actual_idx + 1];
      if (isNil(next)) {
        // If next visit doesn't exists initialize it !
        const new_history = clone(actual_history);
        new_history[actual_idx + 1] = visit_default;
        this.visit_history = new_history;
      }
      this._current_visit_idx$$.next(actual_idx + 1);
    });
  }

  private _will_go_next$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public will_go_next$$: Observable<boolean> = this._will_go_next$.asObservable().pipe(distinctUntilChanged(), replay());

  protected goNextInProgress(): boolean {
    return this._will_go_next$.getValue();
  }

  public visit_count$$: Observable<Dictionary<number>> = this.history_and_idx$$.pipe(
    map(([idx, history]) => history.slice(0, idx + 1)),
    map(history => {
      const counts: Dictionary<number> = {};
      history.forEach(visit => {
        keys(visit).map((field_name: string) => {
          const value = visit[field_name];
          const actif = this.isFieldActif(field_name, visit);
          if (actif && value) {
            const key = `${field_name}-${value}`;
            counts[key] = (counts[key] || 0) + 1;
          }
        });
      });
      return counts;
    }),
    replay()
  );

  public visit_previous$$ = this.history_and_idx$$.pipe(
    map(([idx, history]) => {
      if (idx <= 0 || isNil(history[idx - 1])) {
        return {};
      }
      return history[idx - 1];
    })
  );

  private _visit_result$$ = this.history_and_idx$$.pipe(
    map(([idx, history]) => {
      const visit_result: GlobalVisitResult = {};
      for (const visit of history.slice(0, idx)) {
        if (this._isValid(visit)) {
          keys(visit)
            .filter(field_name => this.isFieldActif(field_name, visit))
            .map(field_name => {
              const value = visit[field_name];
              if (!has(visit_result, field_name)) {
                visit_result[field_name] = {};
              }
              if (!has(visit_result[field_name], value)) {
                visit_result[field_name][value] = 0;
              }
              visit_result[field_name][value]++;
            });
        }
      }
      return visit_result;
    })
  );

  // #endregion

  // #region -> (styles computers)

  public styles_class$$ = combineLatest([this.current_visit_idx$$, this.nb_elements$$]).pipe(
    map(([idx, nb_hives_nuc]) => ({
      background: this.computeVisitBackgroundClass(idx, nb_hives_nuc),
      progress: this.computeVisitProgressClass(idx, nb_hives_nuc),
    }))
  );

  private computeVisitProgressClass(idx: number, nb_hives_nuc: number): string {
    if (idx / (nb_hives_nuc ? nb_hives_nuc : 1) < 1) {
      return 'blue determinate';
    } else if (idx === (nb_hives_nuc ? nb_hives_nuc : 1)) {
      return 'green determinate';
    } else {
      return 'red determinate';
    }
  }

  private computeVisitBackgroundClass(idx: number, nb_hives_nuc: number): string {
    if (idx / (nb_hives_nuc ? nb_hives_nuc : 1) < 1) {
      return 'blue lighten-4';
    } else if (idx === (nb_hives_nuc ? nb_hives_nuc : 1)) {
      return 'green lighten-4';
    } else {
      return 'red lighten-4';
    }
  }

  // #endregion

  // #region -> (checkers)

  private _isValid(visit: VisitValues): boolean {
    if (isNil(visit)) {
      return false;
    }
    return every(this.input_params?.fields?.order.map((field_name: string) => !this.isFieldActif(field_name, visit) || !isNil(visit[field_name])));
  }

  public isFieldActif(field_name: string, visit: VisitValues) {
    const field = this.input_params.fields.config[field_name];
    return isFieldActif(field, visit);
  }

  // #endregion

  public closeVisit(): void {
    combineLatest([this.history_and_idx$$, this._visit_result$$])
      .pipe(debounceTime(200), take(1))
      .subscribe(([[idx, history], results]) => {
        this.complete({
          visit_history: history,
          visit_idx: idx,
          result: results,
        });
      });
  }
}
