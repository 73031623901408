import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { clone, isNil, some, uniqueId } from 'lodash-es';

import { concat, Observable, of } from 'rxjs';
import { distinctUntilChanged, map, shareReplay } from 'rxjs';

import { FormProperty } from 'ngx-schema-form';

import { EfObjectWidgetComponent } from '../object/object.widget';
import { replay } from '@bg2app/tools/rxjs';
import { Dictionary } from 'app/typings/core/interfaces';

export interface ScopeDefinition {
  name: string;
  description: string;
  imply: string[];
  implicit_with: string[];
}

@Component({
  selector: 'bg2-entityacl-widget',
  templateUrl: './entity-acl.widget.html',
  styleUrls: ['./entity-acl.widget.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EfBg2EntityACLWidgetComponent extends EfObjectWidgetComponent implements OnInit {

  public __isNil = isNil;

  public id = uniqueId();

  public scopes_property: FormProperty;

  public possible_scopes$$: Observable<ScopeDefinition[]>;
  public all_scopes: Dictionary<ScopeDefinition> = {};

  public scopes$$: Dictionary<Observable<boolean>> = {};

  public scopes_icons: Dictionary<string> = {
    write_all: 'mdi-lock-open-variant',
    read: 'mdi-eye',
    read_aproximate_position: 'mdi-map-marker-circle',
    read_precisse_position: 'mdi-map-marker',
    read_measurements_data: 'mdi-chart-bar',
    read_all_events: 'mdi-format-list-checks',
    write_all_events: 'mdi-lead-pencil',
    read_devices: 'mdi-format-list-numbered',
    read_devices_last_position: 'mdi-satellite-variant',
    read_devices_routes: 'mdi-map-marker-path',
    write_move_authorization: 'mdi-cursor-move',
    write_devices_notifications: 'mdi-chat-alert',
    write_devices_configuration: 'mdi-cog',
    read_minimal: 'mdi-lock',
    read_devices_data: 'mdi-database-search',
  };

  ngOnInit(): void {
    super.ngOnInit();
    this.scopes_property = (this.formProperty.properties as any).scopes;
    const selected_scopes$$ = concat(of(this.scopes_property.value), this.scopes_property.valueChanges).pipe(
      map((scopes: { scope: string }[]) => {
        const selected_scope: Dictionary<boolean> = {};
        this.schema.possible_scopes.map((scope: ScopeDefinition) => {
          selected_scope[scope.name] = false;
        });
        scopes.forEach(scope => {
          selected_scope[scope.scope] = true;
        });
        return selected_scope;
      }),
    );

    // this.possible_scopes = this.schema.properties.scopes.items.properties.scope.oneOf;
    this.schema.possible_scopes.map((scope: ScopeDefinition) => {
      this.all_scopes[scope.name] = scope;
      this.scopes$$[scope.name] = selected_scopes$$.pipe(
        map(selected_scope => selected_scope[scope.name] || false),
        distinctUntilChanged(),
        replay()
      );
    });

    this.possible_scopes$$ = selected_scopes$$.pipe(
      map(selected_scope =>
        // console.log(selected_scope)
         this.schema.possible_scopes.filter((scope: ScopeDefinition) => !this._isSubSelected(scope.name, selected_scope))
      )
    );
  }

  get transparent(): boolean {
    if (!isNil(this.schema.transparent)) {
      return this.schema.transparent;
    }
    return isNil(this.schema.title);
  }

  private _isSubSelected(scope_name: string, selected_scope: Dictionary<boolean>): boolean {
    const implicit_with = this.all_scopes[scope_name].implicit_with || [];
    return some(implicit_with.map((_scope: any) => selected_scope[_scope]));
  }

  public onScopeChange(scope: string, value: boolean): void {
    let new_selection: { scope: string }[] = this.scopes_property.value;
    if (value) {
      new_selection = clone(new_selection);
      new_selection.push({ scope });
    } else {
      new_selection = new_selection.filter(val => val.scope !== scope);
    }
    this.scopes_property.setValue(new_selection, false);
  }

}
