<div class="us-global-settings-component">
  <mat-card>
    <mat-card-header>
      <mat-card-title>{{ 'ROUTABLE_MODALS.USER_SETTINGS.CATEGORY.APP_SETTINGS.GLOBAL.Title' | translate }}</mat-card-title>
    </mat-card-header>

    <mat-card-content>
      <div class="config">
        <span class="label">
          {{ 'ROUTABLE_MODALS.USER_ACCOUNT.UAM_USER_DETAILS.PROFILE.Language' | translate }}
        </span>

        <bg2-lang-select></bg2-lang-select>
      </div>
    </mat-card-content>
  </mat-card>

  <mat-card>
    <mat-card-header>
      <mat-card-title>{{ 'ROUTABLE_MODALS.USER_SETTINGS.CATEGORY.GLOBAL.IA_HELPERS.Title' | translate }}</mat-card-title>
    </mat-card-header>

    <mat-card-content>
      <div class="config">
        <span class="label">
          {{ 'ROUTABLE_MODALS.USER_SETTINGS.CATEGORY.GLOBAL.IA_HELPERS.Display the configuration wizard' | translate }}

          <span
            class="mdi mdi-alert-outline"
            [popper]="'ALL.ERROR.MISC.This config has effect only on your current browser' | translate"
          ></span>
        </span>

        <mat-slide-toggle
          [color]="'primary'"
          (change)="toggle_ghost_entities()"
          [ngModel]="load_ghost_entities$$ | async"
          [disabled]="(load_ghost_entities$$ | async) === false"
        ></mat-slide-toggle>
      </div>
    </mat-card-content>
  </mat-card>

  <mat-card>
    <mat-card-header>
      <mat-card-title>{{ 'ROUTABLE_MODALS.USER_SETTINGS.CATEGORY.APP_SETTINGS.APIARY_SETTINGS.Title' | translate }}</mat-card-title>
    </mat-card-header>

    <mat-card-content>
      <!-- Configure default chart type for compact view -->
      <div class="config">
        <span class="label">
          {{ 'ROUTABLE_MODALS.USER_SETTINGS.CATEGORY.APP_SETTINGS.APIARY_SETTINGS.Default chart type (compact view)' | translate }}
        </span>

        <div style="font-size: 12px">
          <mat-form-field [appearance]="'outline'">
            <mat-select
              [value]="default_weight_chart_type$$ | async"
              (valueChange)="update_user_param_setting($event, 'app_settings.apiary.list.default_chart_type')"
            >
              <mat-option *ngFor="let view_type of available_apiary_data_view_type__values" [value]="view_type.value">
                {{ view_type.label | translate }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>

      <!-- Configure default date range for compact view -->
      <div class="config">
        <span class="label">
          {{ 'ROUTABLE_MODALS.USER_SETTINGS.CATEGORY.APP_SETTINGS.APIARY_SETTINGS.Default date range (compact view)' | translate }}
        </span>

        <div style="font-size: 12px">
          <mat-form-field [appearance]="'outline'">
            <mat-select
              [value]="default_date_range__compact_apiary$$ | async"
              (valueChange)="update_user_param_setting($event, 'app_settings.apiary.list.default_date_range')"
            >
              <mat-option *ngFor="let view_type of default_date_range__compact_apiary__values" [value]="view_type.value">
                {{ view_type.label | translate }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
    </mat-card-content>
  </mat-card>
</div>
