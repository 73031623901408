<div class="new-beta-version-snackbar-component">
  <ng-container *ngIf="data?.show_new_beta; else newClassyVersion">
    <div
      class="desc"
      [innerHtml]="
        'WIDGETS.WIDGETS_REUSABLES.SNACKBARS.A new beta version is available. You can switch to the beta version or ignore the message'
          | translate
          | markdown
      "
    >
    </div>
  </ng-container>

  <ng-template #newClassyVersion>
    <div
      class="desc"
      [innerHtml]="
        'WIDGETS.WIDGETS_REUSABLES.SNACKBARS.The beta version has been released as production version ! You can go back to the normal application until a new beta comes out'
          | translate
          | markdown
      "
    >
    </div>
  </ng-template>

  <div class="actions">
    <ng-container *ngIf="data?.show_new_beta; else goToClassyVersion">
      <bg2-button [type]="'cancel'" [message]="'ALL.BETA.Stay on this version' | translate" (btn_click)="ignore()"></bg2-button>
      <bg2-button [type]="'action'" [message]="'ALL.MENU.Try BETA version' | translate" (btn_click)="use_beta_version()"></bg2-button>
    </ng-container>
    
    <ng-template #goToClassyVersion>
      <bg2-button [type]="'action'" [message]="'ALL.MENU.Go to normal version' | translate" (btn_click)="quit_beta_version()"></bg2-button>
    </ng-template>
  </div>
</div>
