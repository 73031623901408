import { Component, AfterViewInit, OnDestroy, OnInit, ChangeDetectionStrategy } from '@angular/core';

import { map, filter, tap } from 'rxjs';
import { BehaviorSubject, Subscription } from 'rxjs';

import { cloneDeep, forIn, isNil, sum } from 'lodash-es';
import { marker as i18n } from '@biesbjerg/ngx-translate-extract-marker';
import { ISchema } from 'ngx-schema-form';
import { FormProperty } from 'ngx-schema-form/lib/model/formproperty';


import { Beeguard2Api } from 'app/core';
import { Dictionary } from 'app/typings/core/interfaces';
import { distinctUntilRealChanged, replay } from '@bg2app/tools/rxjs';

import { trackEntityStateBeforeEvent } from '../eventforms.helpers';
import { EfObjectWidgetComponent, ObjectWidgetOptions } from '../object/object.widget';

function totalHives(repartition: number[]): number {
  return sum(repartition);
}

function totalSuperbox(repartition: number[]): number {
  return sum(repartition.map((nb, idx) => idx * nb));
}

function fixRepartitionFormat(repartition: Dictionary<number>): number[] {
  const array_repartition: number[] = [];
  forIn(repartition, (nbs, idx) => {
    array_repartition[+idx] = nbs;
  });
  return array_repartition;
}

function modifyRepartitionForceNbs(repartition: number[], nbs_target: any) {
  let has_changed = true;
  while (has_changed && totalSuperbox(repartition) > nbs_target) {
    has_changed = rmSuperInRepartition(repartition);
  }
  while (has_changed && totalSuperbox(repartition) < nbs_target) {
    has_changed = addSuperInRepartition(repartition);
  }
  return repartition;
}

function addSuperInRepartition(repartition: number[]) {
  let smaller_nn = 0;
  while (repartition[smaller_nn] === 0 && smaller_nn < repartition.length) {
    smaller_nn++;
  }
  if (smaller_nn >= repartition.length - 1) {
    return false;
  }
  repartition[smaller_nn]--;
  repartition[smaller_nn + 1]++;
  return true;
}

function rmSuperInRepartition(repartition: number[]) {
  let larger_nn = repartition.length - 1;
  while (repartition[larger_nn] === 0 && larger_nn >= 0) {
    larger_nn--;
  }
  if (larger_nn < 0) {
    return false;
  }
  repartition[larger_nn]--;
  repartition[larger_nn - 1]++;
  return true;
}

function fixNbHivesWithNoSupers(repartition: number[], nbs: number): number[] {
  const actual_nbs = totalHives(repartition);
  if (actual_nbs < nbs) {
    repartition[0] = nbs - actual_nbs + (repartition[0] || 0);
  }
  return repartition;
}

export interface SuperboxRepartitionWidgetOptions extends ObjectWidgetOptions {
  transparent: boolean;
  add_supers: boolean;
  apiary_path: string;
}

@Component({
  selector: 'bg2-bg2superbox-repartition',
  templateUrl: './bg2superbox-repartition.widget.html',
  styleUrls: ['./bg2superbox-repartition.widget.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class EfBg2SuperboxRepartitionWidget extends EfObjectWidgetComponent implements OnInit, AfterViewInit, OnDestroy {
  public options: SuperboxRepartitionWidgetOptions = {
    title_style: '',
    add_supers: true,
    transparent: false,
    apiary_path: 'apply_to/apiary',
  };

  private default: any;
  private initial_is_default = false; // If true the value is setted from apiary

  private rep_property: FormProperty; // formProperty for hives/superbox repartition array
  private nbs_diff_property: FormProperty;
  private nbs_final_property: FormProperty;
  private nbs_stock_property: FormProperty; // formProperty for nb superbox in stock

  private _schema_nbs_diff$$ = new BehaviorSubject<ISchema>(null);
  public schema_nbs_diff$$ = this._schema_nbs_diff$$.asObservable().pipe(distinctUntilRealChanged(), replay());

  private _is_updating$$ = new BehaviorSubject(false);
  public is_updating$$ = this._is_updating$$.asObservable().pipe(distinctUntilRealChanged(), replay());

  private _is_loading$$ = new BehaviorSubject(true);
  public is_loading$$ = this._is_loading$$.asObservable().pipe(distinctUntilRealChanged(), replay());

  private _error$$ = new BehaviorSubject(null);
  public error$$ = this._error$$.asObservable().pipe(distinctUntilRealChanged(), replay());

  private _is_rep_is_defined$$ = new BehaviorSubject(false);
  public is_rep_is_defined$$ = this._is_rep_is_defined$$.asObservable().pipe(distinctUntilRealChanged(), replay());

  private _nbh_adiff$$ = new BehaviorSubject(0);
  public nbh_adiff$$ = this._nbh_adiff$$.asObservable().pipe(distinctUntilRealChanged(), replay());

  private _repartition_initial$$ = new BehaviorSubject<number[]>(null);
  public repartition_initial$$ = this._repartition_initial$$.asObservable().pipe(distinctUntilRealChanged(), replay());

  private _nbs_stock_initial$$ = new BehaviorSubject(0);
  public nbs_stock_initial$$ = this._nbs_stock_initial$$.asObservable().pipe(distinctUntilRealChanged(), replay());

  private _nbh_diff = 0;
  private _nbs_adiff = 0;
  private _nbh_actual = 0; // actual nb of hives computed from super repartition
  private _nbs_initial: any; // Initial number of supers on the apiary
  private _nbh: number = null; // number of hives on the apiary
  private nbs_mean_by_hive = 0;
  private nbh_initial: number = null; // number of hives before intervention
  private rep_initial_defined = false; // True if the supers repartition on hives was defined

  public get repartition() {
    return this.rep_property.value;
  }

  public set repartition(values) {
    this.rep_property.setValue(values, false);
  }

  public get nbs_diff() {
    return this.nbs_diff_property ? this.nbs_diff_property.value : null;
  }

  public set nbs_diff(value) {
    this.nbs_diff_property.setValue(value, false);
  }

  public set nbh(value) {
    this._nbh = value;
    this.nbh_diff = this.nbh_actual - value;
  }

  public get nbh() {
    return this._nbh;
  }

  public set nbh_diff(value) {
    this._nbh_diff = value;
    this._nbh_adiff$$.next(Math.abs(this._nbh_diff));
  }

  public get nbh_diff() {
    return this._nbh_diff;
  }

  public set nbh_actual(value) {
    this._nbh_actual = value;
    this.nbh_diff = value - this.nbh;
  }

  public get nbh_actual() {
    return this._nbh_actual;
  }

  public get nbs_initial() {
    return this._nbs_initial;
  }

  public set nbs_initial(value) {
    const first_setup = isNil(this.nbs_initial);
    this._nbs_initial = value;

    if (!first_setup) {
      this.nbs_diff = this._nbs_initial - this.nbs_final;
    }
  }

  public get nbs_final() {
    return this.nbs_final_property ? this.nbs_final_property.value : null;
  }

  public set nbs_final(value) {
    if (value < 0) {
      value = 0;
    }
    this.nbs_final_property.setValue(value, false);
  }

  public get nbs_adiff() {
    return this._nbs_adiff;
  }

  public set nbs_adiff(value) {
    if (this.options.add_supers) {
      // Note: Imposible to remove more hive than there is on the apiary
      // this is possible in "harverst" not in "add"
      this.nbs_diff = Math.max(-this.nbs_initial, value);
    } else {
      this.nbs_diff = -value;
    }
  }

  public get nbh_added() {
    let value = this.nbh - this.nbh_actual;

    if (value * -1 > this.rep_property.value[0]) {
      value = this.rep_property.value[0] * -1;
    }

    return value;
  }

  public get nbs_stock() {
    return this.nbs_stock_property ? this.nbs_stock_property.value : null;
  }

  public set nbs_stock(value) {
    this.nbs_stock_property.setValue(value, false);
  }

  // #region -> (component basics)

  protected initial_value_sub: Subscription = null;
  protected nbs_diff_value_sub: Subscription = null;
  protected nbs_final_value_sub: Subscription = null;
  protected rep_property_value_sub: Subscription = null;

  constructor(private _bg2Api: Beeguard2Api) {
    super(_bg2Api);
  }

  ngOnInit(): void {
    super.ngOnInit();

    // TODO: Check that schema is compatible

    // Get default config
    this.default = this.schema._default;

    // Get subelements schemas
    this._schema_nbs_diff$$.next(this.schema.properties.nbs_diff);

    // Get subelements properties
    this.nbs_stock_property = this.formProperty.getProperty('stock');
    this.rep_property = this.formProperty.getProperty('repartition');
    this.nbs_diff_property = this.formProperty.getProperty('nbs_diff');
    this.nbs_final_property = this.formProperty.getProperty('nbs_final');

    if (isNil(this.nbs_diff_property)) {
      throw Error('nbs_diff_property not found !');
    }

    if (isNil(this.nbs_final_property)) {
      throw Error('nbs_final_property not found !');
    }

    // Subscribe to repartition property changed
    this.rep_property_value_sub = this.rep_property.valueChanges.pipe(distinctUntilRealChanged()).subscribe({
      next: value => this.repartitionChanged(value),
    });

    // Subscribe to nbs_diff changed
    this.nbs_diff_value_sub = this.nbs_diff_property.valueChanges
      .pipe(distinctUntilRealChanged())
      .subscribe({ next: value => this.nbsDiffChanged(value) });

    // Subscribe to nbs_final changed
    this.nbs_final_value_sub = this.nbs_final_property.valueChanges
      .pipe(distinctUntilRealChanged())
      .subscribe({ next: value => this.nbsFinalChanged(value) });
  }

  ngAfterViewInit(): void {
    this._is_loading$$.next(true);

    this._is_rep_is_defined$$.next(!(isNil(this.repartition) || this.repartition.length === 0));

    if (!this._is_rep_is_defined$$.getValue() && isNil(this.nbs_diff)) {
      this.initial_is_default = true;
    } else {
      this.initial_is_default = false;
    }

    setTimeout(() => {
      this._is_updating$$.next(true);
      const dpath = this.formProperty.root.schema.options.event_date_path;
      const event_date_and_id = this.trackEventDateAndId(dpath);
      const apiary_path = this.options.apiary_path;
      const apiary_property = this.robustSearchProperty(apiary_path);

      const apiary_state_obs = trackEntityStateBeforeEvent(apiary_property, this.bg2Api, event_date_and_id);
      const from_apiary = apiary_state_obs.pipe(
        tap(() => this._is_updating$$.next(true)),
        map(state => state.state),
        filter(astate => !isNil(astate)),
        map(astate => {
          const superbox: Dictionary<any> = {
            nbs: 0,
            nbh: 0,
            stock: 0,
            repartition: null,
          };

          // Get initial repartition
          if (!isNil(astate.superbox) && !isNil(astate.superbox.repartition)) {
            superbox.repartition = astate.superbox.repartition;
          }

          // Get initial nbh{
          if (!isNil(astate.nb_hives)) {
            superbox.nbh = astate.nb_hives;
          } else if (!isNil(astate.hive_ids)) {
            superbox.nbh = astate.hive_ids.length;
          }
          // Get initial nbs number
          if (!isNil(astate.superbox) && !isNil(astate.superbox.nbs)) {
            superbox.nbs = astate.superbox.nbs;
          } else if (superbox.repartition) {
            superbox.nbs = totalSuperbox(superbox.repartition);
          }

          // get number supers in stock
          if (!isNil(astate.superbox) && !isNil(astate.superbox.stock)) {
            superbox.stock = astate.superbox.stock;
          }

          return superbox;
        }),
        replay()
      );

      this.initial_value_sub?.unsubscribe();
      this.initial_value_sub = null;

      this.initial_value_sub = from_apiary.subscribe(superbox => {
        if (this.initial_value_sub && !isNil(superbox)) {
          this._is_updating$$.next(false);
          this.nbh = superbox.nbh;
          this.nbh_actual = this.nbh;
          this.nbh_initial = this.nbh;
          this.nbs_initial = superbox.nbs;
          this.rep_initial_defined = !isNil(superbox.repartition);

          // Compute initial repartition *EVEN* if not given
          let repartition_initial;
          if (this.rep_initial_defined) {
            repartition_initial = fixRepartitionFormat(superbox.repartition);
            repartition_initial = fixNbHivesWithNoSupers(repartition_initial, this.nbh);
          } else {
            repartition_initial = [this.nbh, 0, 0, 0, 0];
            repartition_initial = modifyRepartitionForceNbs(repartition_initial, this.nbs_initial);
          }

          this._repartition_initial$$.next(repartition_initial);
          this._nbs_stock_initial$$.next(superbox.stock);

          if (this.initial_is_default) {
            this.nbs_stock_property.setValue(superbox.stock, false);

            if (this.rep_initial_defined) {
              this.repartition = cloneDeep(repartition_initial);
              this._is_rep_is_defined$$.next(!(isNil(this.repartition) || this.repartition.length === 0));
            }

            if (this.options.add_supers) {
              this.nbs_final = superbox.nbs;
            } else {
              this.nbs_final = 0;
            }
          }
        }
      });

      this._is_loading$$.next(false);
    }, 100);
  }

  ngOnDestroy(): void {
    this.initial_value_sub?.unsubscribe();
    this.nbs_diff_value_sub?.unsubscribe();
    this.nbs_final_value_sub?.unsubscribe();
    this.rep_property_value_sub?.unsubscribe();
  }

  // #endregion

  // #region -> (base properties management)

  private nbsDiffChanged(nbs_diff: any) {
    if (this.options.add_supers) {
      this._nbs_adiff = nbs_diff;
    } else {
      this._nbs_adiff = -nbs_diff;
    }

    // Update nbs_final (this will change repartition)
    // if nbs_initial is "None" the initial value is not yet know...
    if (!isNil(this.nbs_initial)) {
      const new_nbs_final = this.nbs_initial + this.nbs_diff;

      if (this.nbs_final !== new_nbs_final) {
        this.nbs_final = new_nbs_final;
      }
    }
  }

  private nbsFinalChanged(nbs_final: any) {
    // Update diff
    // if nbs_initial is "None" the initial value is not yet know...
    if (!isNil(this.nbs_initial)) {
      const new_nbs_diff = this.nbs_final - this.nbs_initial;

      if (this.nbs_diff !== new_nbs_diff) {
        this.nbs_diff = new_nbs_diff;
      }
    }
    // Change repartition
    if (this._is_rep_is_defined$$.getValue()) {
      this.repartition = modifyRepartitionForceNbs(cloneDeep(this.repartition), this.nbs_final);
    }

    this.nbs_mean_by_hive = Math.ceil(this.nbs_final / this.nbh_actual);
  }

  private repartitionChanged(new_rep?: any) {
    if (isNil(new_rep)) {
      new_rep = this.repartition;
    }

    // Update sueprs and hives number
    if (!isNil(new_rep) && new_rep.length > 0) {
      this.nbs_final = totalSuperbox(new_rep);
      this.nbh_actual = totalHives(new_rep);
    } else {
      this.nbh_actual = this.nbh_initial;
    }

    // Check error
    if (!isNil(this.nbh) && this.nbh_actual !== this.nbh) {
      if (this.nbh_actual > this.nbh) {
        this._error$$.next(
          i18n<string>('EVENT.SUPERBOX.[nbh_adiff] more hives than initially declared on this apiary ([nbh_actual] versus [nbh])')
        );

        if (this._nbh_adiff$$.getValue() === 1) {
          this._error$$.next(
            i18n<string>('EVENT.SUPERBOX.One more hive than initially declared on this apiary ([nbh_actual] versus [nbh])')
          );
        }
      } else {
        this._error$$.next(
          i18n<string>('EVENT.SUPERBOX.[nbh_adiff] less hives than initially declared on this apiary ([nbh_actual] versus [nbh])')
        );

        if (this._nbh_adiff$$.getValue() === 1) {
          this._error$$.next(i18n<string>('EVENT.SUPERBOX.A hive less than initially declared on this apiary ([nbh_actual] versus [nbh])'));
        }
      }

      // Note: settimeout needed to add error after formProperty validation
      setTimeout(() => {
        this.formProperty.findRoot().extendErrors([
          {
            hives_number_issue: this._error$$.getValue(),
            path: this.formProperty.path,
          },
        ]);
      }, 300);
    } else {
      this._error$$.next(null);
    }
  }

  // #endregion

  // #region -> (data management)

  public changeTotalHiveNumber() {
    this.nbh = this.nbh_actual;
    this.repartitionChanged(); // usefull to recompute error
  }

  public autoComplete() {
    if (this.nbh - this.nbh_actual > 0) {
      const add = this.nbh - this.nbh_actual;
      const value = this.repartition[0] + add;

      this.setRepartitionValue(0, value);
    } else if (this.nbh - this.nbh_actual < 0) {
      const sub = this.nbh - this.nbh_actual;
      const value = this.repartition[0] + sub;

      if (value > 0) {
        this.setRepartitionValue(0, value);
      } else {
        this.setRepartitionValue(0, 0);
      }
    }
  }

  private setRepartitionValue(idx: number, val: number) {
    const value = this.repartition;

    if (value[idx] !== val) {
      value[idx] = val;
      this.repartition = value;
    }
  }

  public resetOldOrInitial() {
    this.nbh = this.nbh_initial;
    this.repartition = this._repartition_initial$$.getValue();
  }

  // #endregion

  // public __isNil = isNil;

  // // Input states

  // // Supers repartition

  // public repartition_tmp: number[] = [0, 0, 0, 0, 0];
  // public nbs_stock_tmp = 0;

  // protected repartition_before_visit: number[] = [0, 0, 0, 0, 0];

  // get nbh_tmp() {
  //   return _sum(this.repartition_tmp);
  // }

  // // Number of supers;

  // // Number of hives added or removed

  // protected nbStockChanged() {

  // }

  // ngOnDestroy() {
  //   this.unsubscribe();
  // }

  // protected unsubscribe() {
  //   this.unsubscribeValues();
  //   this.unsubscribeDefaultValue();
  // }

  // private unsubscribeValues() {
  //   if (this.rep_property_value_sub) {
  //     this.rep_property_value_sub.unsubscribe();
  //   }
  //   if (this.nbs_diff_value_sub) {
  //     this.nbs_diff_value_sub.unsubscribe();
  //   }
  // }

  // // --------------------------------------------------------------------------
  // // Manipulate Data

  // protected setRepartitionValueTmp(idx: number, val: number) {
  //   if (this.repartition_tmp[idx] !== val) {
  //     this.repartition_tmp[idx] = val;
  //   }
  // }

  // public toNextValue(prev: number, next: number) {
  //   const prevValue = this.repartition_tmp[prev];
  //   const nextValue = this.repartition_tmp[next];
  //   this.repartition_tmp[prev] = 0;
  //   this.repartition_tmp[next] = prevValue + nextValue;
  // }

  // public razRepartition() {
  //   this.rep_is_defined = false;
  //   this.repartition = null;
  // }

  // // --------------------------------------------------------------------------
  // // Sub-modals Option
  // // @ViewChild('visiteMode', { read: ElementRef, static: true }) visiteMode: ElementRef;
  // // @ViewChild('visiteMode', { static: true }) visiteModeModal: Bg2ModalComponent;
  // public visit_modal_opened = false;

  // // @ViewChild('inputMode', { read: ElementRef, static: true }) inputMode: ElementRef;
  // // @ViewChild('inputMode', { static: true }) inputModeModal: Bg2ModalComponent;
  // public input_modal_opened = false;
  // // Note: ^ this is necesarry to have same element with two types...

  // public fullscreen = (window.innerWidth <= 1200 || window.innerHeight <= 800);

  // protected setupInitialTmpRepartition(raz = false) {
  //   if (!this.rep_is_defined) {
  //     let repartition = null;
  //     repartition = _cloneDeep(this.repartition_initial);
  //     this.repartition_tmp = modifyRepartitionForceNbs(repartition, this.nbs_final);
  //   } else {
  //     this.repartition_tmp = _clone(this.repartition);
  //   }
  //   this.repartition_before_visit = _clone(this.repartition_tmp);
  //   this.nbs_stock_tmp = this.nbs_stock_property.value;
  // }

  // protected validateTmpRepartition() {
  //   this.repartition = this.repartition_tmp;
  //   this.rep_is_defined = true;
  //   this.nbs_stock_property.setValue(this.nbs_stock_tmp, false);
  // }

  // public modalClosed(event: any) {
  //   switch (event) {
  //     case 'input-mode': {
  //       this.input_modal_opened = false;
  //       break;
  //     }

  //     case 'visite-mode': {
  //       this.visit_modal_opened = false;
  //       break;
  //     }
  //   }
  // }

  // // --------------------------------------------------------------------------
  // // Manual edition mode
  // public startManualEdition() {
  //   this.setupInitialTmpRepartition();
  //   this.modalsMngt.setHasSubModals(true);
  //   this.input_modal_opened = true;
  //   // this.inputModeModal.openModal();
  // }

  // public validateEdition() {
  //   this.validateTmpRepartition();
  //   // this.inputModeModal.closeModal();
  // }

  // public cancelEdition() {
  //   this.modalsMngt.setHasSubModals(false);
  //   // this.inputModeModal.closeModal();
  // }

  // public activeFocus(event: any) {
  //   const input = event.querySelector('input') as HTMLElement;
  //   input.focus();
  // }

  // // --------------------------------------------------------------------------
  // // Visit mode hives by hives

  // public startVisit() {
  //   this.repartition_tmp = [0, 0, 0, 0, 0];
  //   this.nbs_stock_tmp = this.nbs_stock_property.value;
  //   this.modalsMngt.setHasSubModals(true);
  //   this.visit_modal_opened = true;
  //   // this.visiteModeModal.openModal();
  // }

  // public continueVisit() {
  //   this.modalsMngt.setHasSubModals(true);
  //   this.visit_modal_opened = true;
  //   // this.visiteModeModal.openModal();
  // }

  // // Manipulate Data in Visit Mode (hive by hive)
  // public visitHistory: number[] = [];

  // public addToValue(idx: number) {
  //   const value = this.repartition_tmp[idx] + 1;
  //   this.visitHistory.push(idx);
  //   this.setRepartitionValueTmp(idx, value);
  //   if (typeof window.navigator.vibrate === 'function') {
  //     window.navigator.vibrate(200);
  //   }
  // }

  // public cancelAdd(): any {
  //   if (!this.visitHistory.length) { return; }
  //   const last = this.visitHistory.pop();
  //   const value = this.repartition_tmp[last] - 1;
  //   if (value < 0) {
  //     // "shouldn't arrive" but it could when :
  //     // - pause of visit
  //     // - manual modification of the value
  //     // - back to the visit and then cancel
  //     return this.cancelAdd();
  //   }
  //   this.setRepartitionValueTmp(last, value);
  //   if (typeof window.navigator.vibrate === 'function') {
  //     window.navigator.vibrate(200);
  //   }
  // }

  // public cancelVisit() {
  //   this.visitHistory = [];
  //   this.modalsMngt.setHasSubModals(false);
  //   // this.visiteModeModal.closeModal();
  // }

  // public pauseVisit() {
  //   if (this.visitHistory.length) {
  //     this.validateTmpRepartition();
  //   }
  //   /*else {
  //     this.stock_property.setValue(this.nbs_stock_tmp, false);
  //     this.repartition = this.repartition_before_visit;
  //   }*/
  //   this.modalsMngt.setHasSubModals(false);
  //   // this.visiteModeModal.closeModal();
  // }

  // public validateVisit() {
  //   this.pauseVisit();
  //   this.visitHistory = [];
  // }

  // // Visit Modal Progress Options
  // get visitBackgroundClass() {
  //   if (this.nbh_tmp / this.nbh < 1) {
  //     return 'blue lighten-4';
  //   } else if (this.nbh_tmp === this.nbh) {
  //     return 'green lighten-4';
  //   } else {
  //     return 'red lighten-4';
  //   }
  // }

  // get visitProgressClass() {
  //   if (this.nbh_tmp / this.nbh < 1) {
  //     return 'blue determinate';
  //   } else if (this.nbh_tmp === this.nbh) {
  //     return 'green determinate';
  //   } else {
  //     return 'red determinate';
  //   }
  // }
}
