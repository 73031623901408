import { Observable, combineLatest, map, take, of, switchMap, concatMap, forkJoin } from 'rxjs';
import { isEmpty, isNil } from 'lodash-es';
import { marker as i18n } from '@biesbjerg/ngx-translate-extract-marker';
import { differenceInMonths, differenceInDays, differenceInHours, differenceInMinutes } from 'date-fns/esm';
import { ISchema } from 'ngx-schema-form';

import { parseDate, startOfTomorrowLuxon } from 'app/misc/tools';
import { replay } from '@bg2app/tools/rxjs';
import { getDistance } from 'app/misc/tools/geomap';

import { DeviceApi } from 'app/core';
import { DataPoint, DeviceLastMeasurement } from 'app/core/api-swagger/device';
import { DeviceQueryParams } from 'app/core/api/device/device-api-service';

import { compute_gprs_state, compute_gps_state } from './_functions';
import {
  DRDevice,
  DeviceStatus,
  DeviceLocation,
  DeviceInterface,
  DeviceStatusGPS,
  DeviceStatusGPRS,
  DeviceLastMeasurements,
} from './DRDevice';
import { DeviceCommunicationTechnology } from './enumerators/device-communication-technology.enum';
import { DeviceRSSIData, GPSJobData, GPSSecondaryData } from '../data';
import {
  DEVICE_BATTERY_TYPE,
  DEVICE_SIMPLIFIED_BATTERY_STATE,
  DEVICE_SIMPLIFIED_BATTERY_STATE_REASON,
  DeviceSimplifiedBatteryState,
} from './enumerators';
import { differenceInYears } from 'date-fns';
import { BatterySparklineData } from './interfaces';

export const IF_SCHEMA: ISchema = {
  type: 'object',
  title: 'Full GPS configuration (IF)',
  properties: {
    embedded: {
      type: 'object',
      properties: {
        type: {
          type: 'integer',
          label: 'type',
          // format: 'int32',
        },
        sta: {
          type: 'integer',
          label: 'sta',
          // format: 'int32',
        },
        sim: {
          type: 'integer',
          label: 'sim',
          // format: 'int32',
        },
        per1_en: {
          type: 'integer',
          label: 'per1_en',
          // format: 'int32',
        },
        per2_en: {
          type: 'integer',
          label: 'per2_en',
          // format: 'int32',
        },
        per1_fast: {
          type: 'integer',
          label: 'per1_fast',
          // format: 'int32',
        },
        per2_slow: {
          type: 'integer',
          label: 'per2_slow',
          // format: 'int32',
        },
        per1_start: {
          type: 'integer',
          label: 'per1_start',
          // format: 'int32',
        },
        per1_stop: {
          type: 'integer',
          label: 'per1_stop',
          // format: 'int32',
        },
        per2_start: {
          type: 'integer',
          label: 'per2_start',
          // format: 'int32',
        },
        per2_stop: {
          type: 'integer',
          label: 'per2_stop',
          // format: 'int32',
        },
        server_port: {
          type: 'integer',
          label: 'server_port',
          // format: 'int32',
        },
        server_ip: {
          type: 'string',
          label: 'server_ip',
        },
        acc_nb: {
          type: 'integer',
          label: 'acc_nb',
          // format: 'int32',
        },
        acc_delay_off: {
          type: 'integer',
          label: 'acc_delay_off',
          // format: 'int32',
        },
        acc_delay: {
          type: 'integer',
          label: 'acc_delay',
          // format: 'int32',
        },
        gprs_apn: {
          type: 'string',
          label: 'gprs_apn',
        },
        gprs_login: {
          type: 'string',
          label: 'gprs_login',
        },
        gprs_password: {
          type: 'string',
          label: 'gprs_password',
        },
        tracking_period: {
          type: 'integer',
          label: 'Periode fix GPS durant tracking en minutes (tracking_period)',
          // format: 'int32',
        },
        tracking_stop_duration: {
          type: 'integer',
          label: 'Temps sans mouvement avant STOP (tracking_stop_duration)',
          // format: 'int32',
        },
        gps_timeout: {
          type: 'integer',
          label: 'gps_timeout',
          // format: 'int32',
        },
        etor_state: {
          type: 'integer',
          label: 'etor_state',
          // format: 'int32',
        },
        etor_timeout: {
          type: 'integer',
          label: 'etor_timeout',
          // format: 'int32',
        },
        stop_threshold: {
          type: 'integer',
          label: 'stop_threshold',
          // format: 'int32',
        },
        move_threshold: {
          type: 'integer',
          label: 'move_threshold',
          // format: 'int32',
        },
      },
    },
  },
};

export const IFx_SCHEMA: ISchema = {
  type: 'object',
  title: 'Full GPS Dynamic configuration (IFx)',
  properties: {
    embedded: {
      title: 'Embedded configuration',
      type: 'object',
      properties: {
        sim: {
          type: 'integer',
          label: 'sim',
          // format: 'int32',
        },
        time_zone: {
          type: 'integer',
          label: 'time_zone',
          // format: 'int32',
        },
        other_features: {
          type: 'object',
          label: 'other_features',
          properties: {
            spare: {
              type: 'integer',
              label: 'spare',
              // format: 'int32',
            },
            btn_actif: {
              type: 'boolean',
              label: 'btn_actif',
              widget: 'checkbox',
            },
          },
        },
        server_ota_port: {
          type: 'integer',
          label: 'server_ota_port',
          // format: 'int32',
        },
        server_port: {
          type: 'integer',
          label: 'server_port',
          // format: 'int32',
        },
        server_ip: {
          type: 'string',
          label: 'server_ip',
        },
        gprs_apn: {
          type: 'string',
          label: 'gprs_apn',
        },
        gprs_login: {
          type: 'string',
          label: 'gprs_login',
        },
        gprs_password: {
          type: 'string',
          label: 'gprs_password',
        },
        sms_number: {
          type: 'string',
          label: 'sms_number',
        },
        dns_server: {
          type: 'string',
          label: 'dns_server',
        },
        rfx_1_presence: {
          type: 'integer',
          label: 'rfx_1_presence',
          // format: 'int32',
        },
        rfx_1_measure_period_sec: {
          type: 'integer',
          label: 'Période de mesures en secondes (rfx_1_measure_period_sec)',
          // format: 'int32',
        },
        rfx_1_intermediate_measure_sec: {
          type: 'integer',
          label: 'rfx_1_intermediate_measure_sec',
          // format: 'int32',
        },
        rfx_1_communication_period_minute: {
          type: 'integer',
          label: 'Période de com. des measures en minutes (rfx_1_communication_period_minute)',
          // format: 'int32',
        },
        rfx_1_communication_start_hour: {
          widget: 'slider',
          type: 'integer',
          label: 'rfx_1_communication_start_hour',
          minimum: 0,
          maximum: 24,
          step: 1,
          // format: 'int32',
        },
        rfx_1_communication_number: {
          type: 'integer',
          label: 'rfx_1_communication_number',
          // format: 'int32',
        },
        rfx_1_external_sensor_strategy: {
          type: 'integer',
          label: 'rfx_1_external_sensor_strategy',
          widget: 'checklist',
          options: {
            display: 'button',
            null_value: 0,
          },
          oneOf: [
            {
              enum: [0],
              label: 'No external sensors (= 0x00)',
            },
            {
              enum: [255],
              label: 'Load externa sensors (= 0xFF)',
            },
          ],
          // format: 'int32',
        },
        rfx_1_max_measurements: {
          type: 'integer',
          label: 'rfx_1_max_measurements',
          // format: 'int32',
        },
        rfx_1_com_configuration: {
          type: 'integer',
          label: 'rfx_1_com_configuration',
          // format: 'int32',
        },
        rfx_1_gps_flash_configuration: {
          type: 'integer',
          label: 'rfx_1_gps_flash_configuration',
          // format: 'int32',
        },
        rfx_1_configuration: {
          type: 'integer',
          label: 'rfx_1_configuration',
          // format: 'int32',
        },
        rfx_2_presence: {
          type: 'integer',
          label: 'rfx_2_presence',
          // format: 'int32',
        },
        rfx_2_measure_period_sec: {
          type: 'integer',
          label: 'rfx_2_measure_period_sec',
          // format: 'int32',
        },
        rfx_2_communication_period_minute: {
          type: 'integer',
          label: 'rfx_2_communication_period_minute',
          // format: 'int32',
        },
        rfx_2_communication_start_hour: {
          type: 'integer',
          label: 'rfx_2_communication_start_hour',
          // format: 'int32',
        },
        rfx_2_communication_number: {
          type: 'integer',
          label: 'rfx_2_communication_number',
          // format: 'int32',
        },
        rfx_2_external_sensor_strategy: {
          type: 'integer',
          label: 'rfx_2_external_sensor_strategy',
          widget: 'checklist',
          options: {
            display: 'button',
            null_value: 0,
          },
          oneOf: [
            {
              enum: [0],
              label: 'No external sensors (= 0x00)',
            },
            {
              enum: [255],
              label: 'Load externa sensors (= 0xFF)',
            },
          ],
          // format: 'int32',
        },
        rfx_2_max_measurements: {
          type: 'integer',
          label: 'rfx_2_max_measurements',
          // format: 'int32',
        },
        rfx_2_com_configuration: {
          type: 'integer',
          label: 'rfx_2_com_configuration',
          // format: 'int32',
        },
        rfx_2_gps_flash_configuration: {
          type: 'integer',
          label: 'rfx_2_gps_flash_configuration',
          // format: 'int32',
        },
        rfx_2_configuration: {
          type: 'integer',
          label: 'rfx_2_configuration',
          // format: 'int32',
        },
        rfx_3_presence: {
          type: 'integer',
          label: 'rfx_3_presence',
          // format: 'int32',
        },
        rfx_3_measure_period_sec: {
          type: 'integer',
          label: 'rfx_3_measure_period_sec',
          // format: 'int32',
        },
        rfx_3_communication_period_minute: {
          type: 'integer',
          label: 'rfx_3_communication_period_minute',
          // format: 'int32',
        },
        rfx_3_communication_start_hour: {
          type: 'integer',
          label: 'rfx_3_communication_start_hour',
          // format: 'int32',
        },
        rfx_3_communication_number: {
          type: 'integer',
          label: 'rfx_3_communication_number',
          // format: 'int32',
        },
        rfx_3_external_sensor_strategy: {
          type: 'integer',
          label: 'rfx_3_external_sensor_strategy',
          widget: 'checklist',
          options: {
            display: 'button',
            null_value: 0,
          },
          oneOf: [
            {
              enum: [0],
              label: 'No external sensors (= 0x00)',
            },
            {
              enum: [255],
              label: 'Load externa sensors (= 0xFF)',
            },
          ],
          // format: 'int32',
        },
        rfx_3_max_measurements: {
          type: 'integer',
          label: 'rfx_3_max_measurements',
          // format: 'int32',
        },
        rfx_3_com_configuration: {
          type: 'integer',
          label: 'rfx_3_com_configuration',
          // format: 'int32',
        },
        rfx_3_gps_flash_configuration: {
          type: 'integer',
          label: 'rfx_3_gps_flash_configuration',
          // format: 'int32',
        },
        rfx_3_configuration: {
          type: 'integer',
          label: 'rfx_3_configuration',
          // format: 'int32',
        },
        critical_alarm_mask: {
          type: 'integer',
          label: 'critical_alarm_mask',
          // format: 'int32',
        },
        critical_alarm_release: {
          type: 'integer',
          label: 'critical_alarm_release',
          // format: 'int32',
        },
        critical_alarm_filter_min: {
          type: 'integer',
          label: 'critical_alarm_filter_min',
          // format: 'int32',
        },

        gps_timeout: {
          type: 'integer',
          label: 'Timeout GPS fix en minutes (gps_timeout)',
          // format: 'int32',
        },
        tracking_mode: {
          type: 'integer',
          label: 'Activation du tracking GPS (tracking_mode)',
          // format: 'int32',
        },
        tracking_period: {
          type: 'integer',
          label: 'Periode fix GPS durant tracking en minutes (tracking_period)',
          // format: 'int32',
        },
        tracking_stop_duration: {
          type: 'integer',
          label: 'Temps sans mouvement avant STOP (tracking_stop_duration)',
          // format: 'int32',
        },

        stop_threshold: {
          type: 'integer',
          label: 'Seuil non-detection de mouvement pour STOP (stop_threshold)',
          // format: 'int32',
        },
        move_threshold: {
          type: 'integer',
          label: 'Seuil detection mouvement pour START (move_threshold)',
          // format: 'int32',
        },

        acc_nb: {
          type: 'integer',
          label: 'acc_nb',
          // format: 'int32',
        },
        acc_delay_off: {
          type: 'integer',
          label: 'acc_delay_off',
          // format: 'int32',
        },
        acc_delay: {
          type: 'integer',
          label: 'acc_delay',
          // format: 'int32',
        },
        etor_mask_high: {
          type: 'integer',
          label: 'etor_mask_high',
          // format: 'int32',
        },
        etor_mask_low: {
          type: 'integer',
          label: 'etor_mask_low',
          // format: 'int32',
        },
        angle: {
          type: 'integer',
          label: 'angle',
          // format: 'int32',
        },
        choc_mask: {
          type: 'integer',
          label: 'choc_mask',
          // format: 'int32',
        },
        nb_stream_acc: {
          type: 'integer',
          label: 'nb_stream_acc',
          // format: 'int32',
        },
        choc_threshold_1_mg: {
          type: 'integer',
          label: 'choc_threshold_1_mg',
          // format: 'int32',
        },
        choc_counter_1: {
          type: 'integer',
          label: 'choc_counter_1',
          // format: 'int32',
        },
        choc_threshold_2_mg: {
          type: 'integer',
          label: 'choc_threshold_2_mg',
          // format: 'int32',
        },
        choc_counter_2: {
          type: 'integer',
          label: 'choc_counter_2',
          // format: 'int32',
        },
        choc_threshold_3_mg: {
          type: 'integer',
          label: 'choc_threshold_3_mg',
          // format: 'int32',
        },
        choc_counter_3: {
          type: 'integer',
          label: 'choc_counter_3',
          // format: 'int32',
        },
        choc_threshold_4_mg: {
          type: 'integer',
          label: 'choc_threshold_4_mg',
          // format: 'int32',
        },
        choc_counter_4: {
          type: 'integer',
          label: 'choc_counter_4',
          // format: 'int32',
        },
        audio_listening_sec: {
          type: 'integer',
          label: 'audio_listening_sec',
          // format: 'int32',
        },
        audio_off_sec: {
          type: 'integer',
          label: 'audio_off_sec',
          // format: 'int32',
        },
        central_frequency_hz: {
          type: 'integer',
          label: 'central_frequency_hz',
          // format: 'int32',
        },
        hysteresis_frequency_hz: {
          type: 'integer',
          label: 'hysteresis_frequency_hz',
          // format: 'int32',
        },
        audio_threshold_mv: {
          type: 'integer',
          label: 'audio_threshold_mv',
          // format: 'int32',
        },
        alr_windows_sec: {
          type: 'integer',
          label: 'alr_windows_sec',
          // format: 'int32',
        },
        nb_threshold_exceeded: {
          type: 'integer',
          label: '',
          // format: 'int32',
        },
        rain_gauge_threshold: {
          type: 'integer',
          label: 'rain_gauge_threshold',
          // format: 'int32',
        },
        rain_gauge_threshold_window: {
          type: 'integer',
          label: 'rain_gauge_threshold_window',
          // format: 'int32',
        },
        temperature_internal_high: {
          type: 'integer',
          label: 'temperature_internal_high',
          // format: 'int32',
        },
        temperature_internal_low: {
          type: 'integer',
          label: 'temperature_internal_low',
          // format: 'int32',
        },
        temperature_external_high: {
          type: 'integer',
          label: 'temperature_external_high',
          // format: 'int32',
        },
        temperature_external_low: {
          type: 'integer',
          label: 'temperature_external_low',
          // format: 'int32',
        },
        weight_delta_inc: {
          type: 'integer',
          label: 'weight_delta_inc',
          // format: 'int32',
        },
        weight_delta_dec: {
          type: 'integer',
          label: 'weight_delta_dec',
          // format: 'int32',
        },
        mvt_sensor: {
          type: 'integer',
          label: 'mvt_sensor',
          // format: 'int32',
        },
        events_0: {
          type: 'object',
          label: 'Alarm Event 0',
          options: {
            indent: true,
          },
          properties: {
            measure_id: {
              type: 'integer',
              label: 'measure_id',
            },
            event_configuration: {
              type: 'integer',
              label: 'event_configuration',
            },
            threshold: {
              type: 'integer',
              label: 'threshold',
            },
            hysteresis: {
              type: 'integer',
              label: 'hysteresis',
            },
            time_on: {
              type: 'integer',
              label: 'time_on_min',
            },
            time_pause: {
              type: 'integer',
              label: 'time_pause_min',
            },
          },
        },
        events_1: {
          type: 'object',
          label: 'Alarm Event 1',
          options: {
            indent: true,
          },
          properties: {
            measure_id: {
              type: 'integer',
              label: 'measure_id',
            },
            event_configuration: {
              type: 'integer',
              label: 'event_configuration',
            },
            threshold: {
              type: 'integer',
              label: 'threshold',
            },
            hysteresis: {
              type: 'integer',
              label: 'hysteresis',
            },
            time_on: {
              type: 'integer',
              label: 'time_on_min',
            },
            time_pause: {
              type: 'integer',
              label: 'time_pause_min',
            },
          },
        },
        events_2: {
          type: 'object',
          label: 'Alarm Event 2',
          options: {
            indent: true,
          },
          properties: {
            measure_id: {
              type: 'integer',
              label: 'measure_id',
            },
            event_configuration: {
              type: 'integer',
              label: 'event_configuration',
            },
            threshold: {
              type: 'integer',
              label: 'threshold',
            },
            hysteresis: {
              type: 'integer',
              label: 'hysteresis',
            },
            time_on: {
              type: 'integer',
              label: 'time_on_min',
            },
            time_pause: {
              type: 'integer',
              label: 'time_pause_min',
            },
          },
        },
        presence_log: {
          // Vecteur de "présence" pour le log
          type: 'integer',
          label: 'presence_log',
          // format: 'int32',
        },
        start_ts_h_log: {
          type: 'integer',
          widget: 'date-time',
          options: {
            output: 'timestamp',
          },
          label: 'start_ts_h_log',
          // format: 'int32',
        },
        stop_ts_h_log: {
          type: 'integer',
          widget: 'date-time',
          options: {
            output: 'timestamp',
          },
          label: 'stop_ts_h_log',
          // format: 'int32',
        },
        _cls: {
          type: 'string',
          widget: 'hidden',
        },
      },
    },
  },
};

export class GPSDevice extends DRDevice {
  // #region -> (model basics)

  constructor(protected deviceApi: DeviceApi, params?: DeviceQueryParams) {
    super(deviceApi, params);

    this.is_gateway = true;
    this.type = DeviceInterface.TypeEnum.GPS;
  }

  // #endregion

  // #region -> (device timeseries)

  /** */
  public fetch_job_data$(start: Date = undefined, end: Date = undefined, step: string = undefined): Observable<GPSJobData> {
    return super
      .requestTimeseries(['temperature', 'humidity'], start, end, step)
      .pipe(map(response => <GPSJobData>{ points: response?.timeseries?.data ?? [] }));
  }

  /** */
  public fetch_secondary_data$(start?: Date, end?: Date, step?: string): Observable<GPSSecondaryData> {
    return of(<GPSSecondaryData>{
      points: [],
    });
  }

  /** */
  public fetch_rssi$(start?: Date, end?: Date, step?: string): Observable<DeviceRSSIData> {
    return super.requestTimeseries(['rssi_gprs'], start, end, step).pipe(
      map(
        response =>
          <DeviceRSSIData>{
            points: response?.timeseries?.data ?? [],
          }
      )
    );
  }

  // #endregion

  // #region -> (device location management)

  /**
   * Gets device's current location.
   *
   * @returns Returns device's current location.
   */
  public get location(): DeviceLocation {
    if (isNil(this._location)) {
      const location_cellid = super.location_cellids;
      const location_gps = this.location_gps;

      if (location_gps && location_cellid) {
        const diff_h = differenceInMinutes(location_cellid.timestamp, location_gps.timestamp);
        // ^ posifif if cellid is more recent
        const dist = this.distance_gps_cellid;
        if (diff_h > 0 && dist > this.location_cellids.accuracy) {
          // si cellid est plus récente (au moins une min) et gps tres distant du cellid
          this._location = location_cellid;
        } else if (diff_h >= 3 * 60 && dist > this.location_cellids.accuracy / 2) {
          // Si cellid est plus rencent de au moins 3 heures et pos gps un peu éloigné
          this._location = location_cellid;
        } else if (diff_h >= 10 * 60) {
          // Si cellid est plus rencent de au moins 10 heures
          this._location = location_cellid;
        } else {
          this._location = location_gps;
        }
      } else if (!location_gps && location_cellid) {
        this._location = location_cellid;
      } else {
        this._location = location_gps;
      }
    }

    return this._location;
  }

  // #endregion

  // #region -> (communication technology)

  /** */
  protected get_com_technology$$(): Observable<DeviceCommunicationTechnology[]> {
    return this.hwv$$.pipe(
      map(hardware_version => {
        if (hardware_version < 12) {
          return [DeviceCommunicationTechnology['GPRS (2G)']];
        }

        return [DeviceCommunicationTechnology['GPRS (2G)'], DeviceCommunicationTechnology['LTE-M (4G)']];
      })
    );
  }

  // #endregion

  // #region -> battery state

  /** */
  public battery_sparkline_last_two_years$$ = this.timezone$$.pipe(
    concatMap(timezone => {
      let end = startOfTomorrowLuxon(timezone);
      let start = end.minus({ years: 2 });

      const first_part = this.requestTimeseries(
        ['vbat', 'temperature', 'temperature_com'],
        start.toJSDate(),
        end.minus({ months: 1 }).toJSDate(),
        '7d'
      );
      const second_part = this.requestTimeseries(
        ['vbat', 'temperature', 'temperature_com'],
        end.minus({ months: 1 }).plus({ seconds: 1 }).toJSDate(),
        end.plus({ minutes: 30 }).toJSDate(),
        '1d'
      );

      return forkJoin({
        first_part,
        second_part,
      }).pipe(
        map(response => {
          // console.log({ response });

          const final_timeseries: DataPoint[] = [];

          final_timeseries.push(...(response?.first_part?.timeseries?.data ?? []));
          final_timeseries.push(...(response?.second_part?.timeseries?.data ?? []));

          return final_timeseries;
        }),
        map(timeseries_data => this.fix_sparkline_timeseries(timeseries_data)),
        switchMap(timeseries_data =>
          this.bat_changes$$.pipe(
            map(battery_changes => {
              const start_date = start;
              const end_date = end;

              const battery_changes_to_keep = battery_changes.filter(
                battery_changed =>
                  battery_changed?.time.getTime() >= start_date.toMillis() && battery_changed?.time.getTime() <= end_date.toMillis()
              );

              return <BatterySparklineData>{
                end_date: end,
                start_date: start,
                battery_changes: battery_changes_to_keep,
                battery_voltages: timeseries_data ?? [],
              };
            })
          )
        )
      );
    }),
    map((data: BatterySparklineData) => {
      // NB: if temperature is not defined, we use temperature_com
      data.battery_voltages = data.battery_voltages.map(datum => {
        if (isNil(datum?.temperature)) {
          datum.temperature = datum.temperature_com;
        }

        return datum;
      });

      return data;
    }),
    replay(),
  );

  /** */
  protected compute_battery_type$$(): Observable<DEVICE_BATTERY_TYPE> {
    return this.generation$$.pipe(
      map(generation => {
        if (generation <= 2) {
          return DEVICE_BATTERY_TYPE.G3;
        }

        return DEVICE_BATTERY_TYPE.P1;
      })
    );
  }

  /** */
  protected get_battery_noload_voltage(last_measurements: DeviceLastMeasurements): number | number[] {
    return last_measurements?.gateway_message?.fields?.valim?.last ?? null;
  }

  /** */
  protected get_battery_com_voltage(last_measurements: DeviceLastMeasurements): number | number[] {
    return last_measurements?.gateway_message?.fields?.vbat?.last ?? null;
  }

  /**
   * Device typical voltage range
   */
  public get_battery_std_voltage_range$$(): Observable<[number, number]> {
    return this.generation$$.pipe(
      map<number, [number, number]>(gen => {
        if (gen <= 2) {
          return [2.3, 3.4];
        }
        return [2.5, 3.2];
      }),
      replay()
    );
  }

  protected get_battery_critical_vbat$$(): Observable<number> {
    return this.generation$$.pipe(
      map(gen => {
        if (gen <= 2) {
          return 2.4;
        }

        return 2.5;
      }),
      replay()
    );
  }

  /** */
  public get_battery_simplified_state$$(): Observable<DeviceSimplifiedBatteryState> {
    return this._get_default_battery_simplified_state$$.pipe(
      switchMap(state => {
        if (!isNil(state)) {
          return of(state);
        }

        return combineLatest({
          hwv: this.hwv$$,
          last_power_on: this.last_power_on$$,
          last_battery_change: this.last_battery_change$$,
        }).pipe(
          map(({ hwv, last_battery_change, last_power_on }) => {
            const last_battery_change_years = Math.abs(differenceInYears(last_battery_change?.time ?? last_power_on, new Date()));

            if (hwv < 10 && last_battery_change_years >= 1) {
              return {
                state: DEVICE_SIMPLIFIED_BATTERY_STATE.NOT_OK,
                reason: DEVICE_SIMPLIFIED_BATTERY_STATE_REASON.LAST_BATTERY_OLDER_THAN_1_YEAR,
              };
            }

            if (hwv >= 10 && last_battery_change_years >= 2) {
              return {
                state: DEVICE_SIMPLIFIED_BATTERY_STATE.NOT_OK,
                reason: DEVICE_SIMPLIFIED_BATTERY_STATE_REASON.LAST_BATTERY_OLDER_THAN_2_YEARS,
              };
            }

            return {
              state: DEVICE_SIMPLIFIED_BATTERY_STATE.OK,
              reason: null,
            };
          })
        );
      })
    );
  }

  // #endregion

  // #region -> (gateway messages)

  private _last_gw_msg: DeviceLastMeasurement = null;

  /**
   * Gets device's last gateway message.
   *
   * @returns Returns device's last gateway message.
   */
  get last_gw_msg(): DeviceLastMeasurement {
    if (isNil(this._last_gw_msg)) {
      const last_measurements = this.last_measurements;

      if (isNil(last_measurements) || isEmpty(last_measurements)) {
        return null;
      }

      const gateway_message = last_measurements.gateway_message;

      if (isNil(gateway_message) || isEmpty(gateway_message)) {
        return null;
      } else {
        gateway_message.time = parseDate(gateway_message.time);
      }

      this._last_gw_msg = gateway_message;
    }

    return this._last_gw_msg;
  }

  // #endregion

  // #region -> (GPRS status)

  public get status_gprs(): DeviceStatusGPRS {
    const message = this.last_gw_msg;

    if (isNil(message)) {
      return null;
    }

    let outdated = false;
    const timestamp = message.time;

    if (differenceInHours(new Date(), timestamp) > 49) {
      outdated = true;
    }

    const prebuilt_status: DeviceStatus = { timestamp, value: message.fields.gprs_qos.last, outdated };
    return { state: compute_gprs_state(prebuilt_status), ...prebuilt_status };
  }

  // #endregion

  // #region -> (GPS status)

  public get status_gps(): DeviceStatusGPS {
    const location = this.location;

    if (isNil(location)) {
      return null;
    }

    const outdated = false;
    const value = location.fix;
    const timestamp = location.timestamp;

    const prebuilt_status: DeviceStatus = { timestamp, value, outdated };
    return { state: compute_gps_state(prebuilt_status), ...prebuilt_status };
  }

  /**
   * Retrieves GPS accuracy of the device.
   *
   * @returns Returns the GPS accuracy of the device as a `number`, or `null` if the device has no GPS position.
   */
  public get gps_accuracy(): number {
    const location = this.location;

    if (isNil(location)) {
      return null;
    }

    if (isNil(location.accuracy)) {
      return null;
    }

    return Math.ceil(location.accuracy);
  }

  // #endregion

  //#region Basic information about the device

  /**
   * Retrieves the generation of the device.
   */
  protected getGeneration(swv: number, hwv: number, gateway_type: string): number {
    if (hwv < 10) {
      return 2;
    }
    return 3;
    // Note: gen 1 devices where serverless devices
  }

  //#endregion

  get temperature_micro(): number {
    const message = this._last_gw_msg;
    if (isNil(message)) {
      return null;
    }
    return message.fields.temperature_micro.last;
  }

  //TODO: use device full_type (GPS vs GPSd to make the choice)
  public use_ifx$$ = combineLatest([this.hwv$$, this.swv$$, this.gateway_type$$]).pipe(
    take(1),
    map(([hwv, swv, gateway_type]) => {
      if (gateway_type === 'BLiveMC') {
        return true;
      }

      return isNil(hwv) || hwv >= 10 || (hwv < 10 && swv >= 42);
    }),
    replay()
  );

  public fetchConfigurationSchema$(): Observable<any> {
    return this.use_ifx$$.pipe(
      take(1),
      map(use_ifx => {
        if (use_ifx) {
          return IFx_SCHEMA;
        } else {
          return IF_SCHEMA;
        }
      })
    );
  }

  public requestSimplifiedConfigurationSchema(): Observable<any> {
    let conf_schema = combineLatest([super.requestSimplifiedConfigurationSchema(), this.use_ifx$$]).pipe(
      take(1),
      map(([schema, use_ifx]) => {
        schema.properties.sconf.properties.mode.oneOf = [
          {
            enum: ['tracking'],
            label: i18n('DEVICE.ALL.CONFIG.Tracking only'),
          },
          {
            enum: ['tracking_measures'],
            label: i18n('DEVICE.ALL.CONFIG.Tracking and internal measurements'),
          },
          {
            enum: ['tracking_measures_sensors'],
            label: i18n('DEVICE.ALL.CONFIG.Tracking, internal measurements and WGuard'),
          },
        ];
        schema.properties.sconf.properties.mode.default = 'tracking';

        if (!use_ifx) {
          schema.properties.sconf.properties.com.visibleIf = {
            mode: ['tracking_measures', 'tracking_measures_sensors'],
          };
          schema.properties.sconf.properties.com.options = {
            maxRange: 18,
          };
          schema.properties.sconf.properties.com.properties.conf.oneOf = [
            {
              enum: ['one_by_day'],
              label: i18n('DEVICE.ALL.CONFIG.One communication by day'),
            },
            {
              enum: ['two_by_day'],
              label: i18n('DEVICE.ALL.CONFIG.Two communications by day'),
            },
          ];
        }

        return schema;
      })
    );
    return conf_schema;
  }

  public get has_warning(): boolean {
    const gps_pos = this.location_gps;
    const gprs_pos = this.location_cellids;

    if (!isNil(gps_pos) && !isNil(gprs_pos)) {
      const is_gps_precise = gps_pos.accuracy <= 1000;
      const is_gps_com_old = Math.abs(differenceInMonths(gps_pos?.timestamp, new Date())) >= 4;
      const is_gprs_com_recent = !isNil(gprs_pos) ? Math.abs(differenceInDays(gprs_pos?.timestamp, new Date())) <= 2 : false;

      if (is_gps_precise && is_gps_com_old && is_gprs_com_recent) {
        const gps_latlng = { lat: gps_pos?.latitude, lng: gps_pos?.longitude };
        const gprs_latlng = { lat: gprs_pos?.latitude, lng: gprs_pos?.longitude };

        return (
          getDistance({ latitude: gps_latlng.lat, longitude: gps_latlng.lng }, { latitude: gprs_latlng.lat, longitude: gprs_latlng.lng }) >
          gps_pos.accuracy + gprs_pos.accuracy
        );
      }
    }

    return false;
  }
}
