import { AfterViewInit, ChangeDetectionStrategy, Component, OnDestroy, Renderer2 } from '@angular/core';

import { map, of, switchMap } from 'rxjs';

import { Beeguard2Api } from 'app/core';

import { SuperBoxHarvest } from 'app/models/events/Superbox';

import { EventBasedCarouselSlideComponent } from 'app/widgets/widgets-reusables/carousels/event-based-carousel/event-carousel-slide/event-carousel-slide.component';
import { isNil } from 'lodash-es';

@Component({
  selector: 'bg2-apiary-carousel-group-sh',
  templateUrl: './apiary-carousel-group-sh.component.html',
  styleUrls: ['./apiary-carousel-group-sh.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ApiaryCarouselGroupsuperboxHarvestComponent
  extends EventBasedCarouselSlideComponent<SuperBoxHarvest>
  implements AfterViewInit, OnDestroy
{
  // #region -> (component basics)

  constructor(protected _renderer: Renderer2, protected _bg2Api: Beeguard2Api) {
    super(_renderer, _bg2Api);
    this.MAX_VISIBLE_CARDS = 1;

    this.event_date$$;
  }

  ngAfterViewInit(): void {
    super.ngAfterViewInit();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  // #endregion

  // #region -> (data management)

  /** */
  public harvested_honey_type$$ = this.event$$.pipe(
    switchMap(event => {
      if (isNil(event)) {
        return of(null);
      }

      return event.honey_type$$;
    })
  );

  /** */
  public harvested_honey_type__image$$ = this.event$$.pipe(
    switchMap(event => {
      if (isNil(event)) {
        return of(null);
      }

      return event?.honey_type__image_css$$;
    })
  );

  /** */
  public harvested_supers__total$$ = this.event$$.pipe(
    switchMap(event => {
      if (isNil(event)) {
        return of(null);
      }

      return event?.total_harvested_honey_type$$;
    }),
    map(total_harvested => total_harvested?.nb_harvested_supers)
  );

  // #endregion
}
