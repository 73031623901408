import { ChangeDetectionStrategy, Component, Input, OnDestroy } from '@angular/core';

import { marker as i18n } from '@biesbjerg/ngx-translate-extract-marker';

import { isEqual, startOfDay, startOfTomorrow, subDays, addMinutes } from 'date-fns/esm';

import { findIndex, flatten, isNil, some, sumBy } from 'lodash-es';

import { BehaviorSubject, catchError, combineLatest, concat, map, Observable, of, Subscription, switchMap, tap } from 'rxjs';
import { distinctUntilRealChanged, replay, waitForNotNilValue, keepSourceIfNoError } from '@bg2app/tools/rxjs';

import { ConsoleLoggerService } from 'app/core/console-logger.service';

import { Apiary, ApiaryWeightsData } from 'app/models';
import { WeightDataPoint } from 'app/models/data';
import { DateRangeManager } from 'app/misc/tools/dates/date-range-manager';

import { ErrorHelperData } from 'app/widgets/widgets-reusables/errors/error-helper/error-helper.component';
import { HiveDiffWeightPoint, HiveWeightData } from 'app/models/data/data-classic/interfaces/hive-data.interface';
import { group_data_by_day, startOfTomorrowLuxon } from '@bg2app/tools/dates';
import { utcToZonedTime } from 'date-fns-tz';

/** */
@Component({
  selector: 'bg2-apiary-sensor-data-container',
  templateUrl: './apiary-sensor-data-container.component.html',
  styleUrls: ['./apiary-sensor-data-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ApiarySensorDataContainerComponent implements OnDestroy {
  // #region -> (component basics)

  /** */
  private readonly LOGGER = new ConsoleLoggerService('ApiarySensorDataContainerComponent', false);

  /** */
  private _timezone_sub: Subscription = null;

  /** */
  constructor() {
    this._timezone_sub = this.location_timezone$$.subscribe({
      next: timezone => {
        const end_date = startOfTomorrowLuxon(timezone);
        const start = end_date.minus({ days: 7 });

        this.date_range_manager.initialize([start, end_date], timezone);
      },
    });
  }

  ngOnDestroy(): void {
    this._timezone_sub?.unsubscribe();
  }

  // #endregion

  /** */
  @Input()
  public apiary_is_compact: boolean = false;

  /** */
  public date_range_manager = new DateRangeManager();

  // #region -> (apiary entity object)

  /** */
  private _apiary$$: BehaviorSubject<Apiary> = new BehaviorSubject<Apiary>(null);

  /** */
  private apiary$$: Observable<Apiary> = this._apiary$$.asObservable().pipe(waitForNotNilValue());

  /** */
  @Input()
  public set apiary(apiary: Apiary) {
    this._apiary$$.next(apiary);
  }

  // #endregion

  // #region -> (location entity)

  /** */
  private location$$ = this.apiary$$.pipe(switchMap(apiary => apiary?.location$$));

  /** */
  public location_id$$ = this.location$$.pipe(switchMap(location => location?.id$$ ?? of(null)));

  /** */
  public location_geoposition$$ = this.location$$.pipe(
    switchMap(location => {
      if (isNil(location)) {
        return of(null);
      }

      return location.position_robust$$;
    })
  );

  /** */
  private location_timezone$$ = this.location$$.pipe(switchMap(location => location.location_timezone$$));

  // #endregion

  /** */
  public has_devices_for_beecounting$$ = this.apiary$$.pipe(switchMap(apiary => apiary.has_devices_for_beecounting$$));

  // #region -> (data management)

  public temperature_data$$ = this.date_range_manager.range$$.pipe(
    tap(() => this._is_loading_temperature_data$$.next(true)),
    switchMap(date_range =>
      this.apiary$$.pipe(
        switchMap(apiary => {
          const start_date = date_range?.start;
          const end_date = date_range?.end.plus({ minutes: 30 });

          return apiary?.stream_temperature_data$$(start_date.toJSDate(), end_date.toJSDate());
        })
      )
    ),
    tap(() => this._is_loading_temperature_data$$.next(false)),
    replay()
  );

  /** */
  public counter_data$$ = this.date_range_manager.range$$.pipe(
    tap(() => this._is_loading_beecount_data$$.next(true)),
    switchMap(date_range =>
      this.apiary$$.pipe(
        switchMap(apiary => {
          const start_date = date_range?.start;
          const end_date = date_range?.end.plus({ minutes: 30 });

          return apiary.stream_beecounter_data$$(start_date.toJSDate(), end_date.toJSDate());
        })
      )
    ),
    tap(() => this._is_loading_beecount_data$$.next(false)),
    replay()
  );

  /** */
  public weight_data$$: Observable<ApiaryWeightsData> = this.date_range_manager.range$$.pipe(
    tap(() => this._is_loading_weight_data$$.next(true)),
    switchMap(date_range =>
      this.apiary$$.pipe(
        switchMap(apiary => {
          const start_date = date_range?.start;
          const end_date = date_range?.end.plus({ minutes: 30 });

          return apiary.stream_weight_data$$(start_date.toJSDate(), end_date.toJSDate());
        })
      )
    ),

    tap(() => this._is_loading_weight_data$$.next(false)),
    replay()
  );

  /** */
  public weight_diff_data$$: Observable<{ date: Date; values: HiveDiffWeightPoint[] }[]> = this.weight_data$$.pipe(
    map(apiary_weights_data => {
      const hives_weight: HiveWeightData[] = apiary_weights_data?.hives_weight;

      const final = hives_weight?.map(data => {
        const hive_weights_by_day = group_data_by_day(data.values);

        const hive_weights_by_day_summarized = hive_weights_by_day.map((current: { date_of_day: Date; values: WeightDataPoint[] }) => {
          const have_at_least_one_not_nil_value = some(current.values, point => !isNil(point.weight_diff));

          const summary_weight_data_point: HiveDiffWeightPoint = {
            hive_id: data.hive_id,
            hive_num: data.hive_num,
            hive_name: data.hive_name,
            hive_color: data.hive_color,

            nb_meas: current.values?.length,

            weight: current.values.slice(-1)[0]?.weight,
            weight_diff: have_at_least_one_not_nil_value ? sumBy(current.values, d => d.weight_diff) : null,
          };

          return { date: current.date_of_day, point: summary_weight_data_point };
        });

        return hive_weights_by_day_summarized;
      });

      return flatten(final).reduce((build: { date: Date; tz_date: Date; timezone: string; values: HiveDiffWeightPoint[] }[], current) => {
        const index = findIndex(build, value => isEqual(value.date, current.date));

        if (index === -1) {
          build.push({
            date: current?.date,
            timezone: apiary_weights_data?.timezone,
            tz_date: utcToZonedTime(current?.date, apiary_weights_data.timezone),
            values: [current?.point],
          });
        } else {
          build[index].values = (build[index].values ?? []).concat(current?.point);
        }

        return build;
      }, []);
    })
  );

  // #endregion

  // #region -> (chart legend management)

  /** */
  public show_total_WG_sensors_changed$$ = this.weight_data$$.pipe(
    map(apiary_weights_data => {
      const losts_and_restablished_sensors = apiary_weights_data?.hives_weight?.reduce((next: boolean[], current) => {
        const hive_weight_data_values = current?.values ?? [];
        const assoc_restablished_lost_sensors = flatten(
          hive_weight_data_values.map(hive_weight_data_value => [
            hive_weight_data_value?.has_lost_sensors ?? false,
            hive_weight_data_value?.has_restablished_sensors ?? false,
          ])
        );

        return next.concat(assoc_restablished_lost_sensors);
      }, []);

      return some(losts_and_restablished_sensors);
    }),
    distinctUntilRealChanged(),
    replay()
  );

  // #endregion

  // #region -> (errors management)

  /** */
  private _check_devices_for_weight_or_temp$$ = this.apiary$$.pipe(
    switchMap(apiary =>
      combineLatest({
        apiary_has_devices_for_weight_measure: apiary.has_devices_for_weight_measure$$,
        apiary_has_devices_for_temperature_measure: apiary.has_devices_for_temperature_measure$$,
        apiary_has_devices_for_beecount_measure: apiary?.has_devices_for_beecounting$$,
      }).pipe(
        switchMap(({ apiary_has_devices_for_temperature_measure, apiary_has_devices_for_weight_measure, apiary_has_devices_for_beecount_measure }) => {
          if (apiary_has_devices_for_weight_measure || apiary_has_devices_for_temperature_measure || apiary_has_devices_for_beecount_measure) {
            return of(null);
          }

          return apiary.prebuilt_error_models.get_error_manage_hive_and_devices$$(
            i18n<string>(
              'ENTITY.APIARY.ERRORS.Within the devices equipping the apiary, no one can be used to display either weight, internal temperature or bee counting data'
            )
          );
        })
      )
    )
  );

  private _check_can_add_devices_from_expl$$ = combineLatest({
    apiary: this.apiary$$,
    exploitation: this.apiary$$.pipe(
      switchMap(apiary => apiary.exploitation$$),
      waitForNotNilValue()
    ),
  }).pipe(
    keepSourceIfNoError(({ apiary, exploitation }) =>
      exploitation.user_acl.check_can_read_devices$$({
        what: i18n<string>('ALL.ACE.READ_DEVICES.WHAT.assign devices to this apiary'),
      })
    ),
    keepSourceIfNoError(data_or_error => {
      if (data_or_error instanceof ErrorHelperData) {
        return of(data_or_error);
      }

      const apiary = data_or_error?.apiary;
      const exploitation = data_or_error?.exploitation;

      return exploitation.has_devices$$.pipe(
        switchMap(exploitation__has_devices => {
          // Check if the exploitation has devices
          if (exploitation__has_devices) {
            return apiary.prebuilt_error_models.get_error_manage_hive_and_devices$$(
              i18n<string>('ENTITY.APIARY.ERRORS.No equipped devices on this apiary')
            );
          }

          return exploitation.user_acl.check_can_write_all$$({
            what: i18n<string>('ALL.ACE.WRITE_ALL.WHAT.ask for a quote'),
          });
        })
      );
    }),

    // If no error here => ask a quote !
    switchMap(data_or_error => {
      if (data_or_error instanceof ErrorHelperData) {
        return of(data_or_error);
      }

      const apiary = data_or_error?.apiary;
      const exploitation = data_or_error?.exploitation;

      return exploitation.prebuilt_error_models.get_error_ask_quote$$();
    })
  );

  /** */
  public error$$: Observable<ErrorHelperData | null> = this.apiary$$.pipe(
    // Check if the user can "read_measurements_data"
    keepSourceIfNoError(apiary =>
      apiary.user_acl.check_read_measurements_data$$({
        what: i18n<string>('ALL.ACE.READ_MEASUREMENTS_DATA.WHAT.view the data'),
      })
    ),

    // Check if current user can "read_devices"
    switchMap(apiary_or_error => {
      if (apiary_or_error instanceof ErrorHelperData) {
        return of(apiary_or_error);
      }

      return apiary_or_error.user_acl.can$$('read_devices').pipe(
        switchMap(can_read_devices => {
          // If cannot read devices, then continue
          if (!can_read_devices) {
            return of(null);
          }

          // If can read devices, then do classic checks
          return apiary_or_error.has_devices$$.pipe(
            switchMap(apiary__has_devices => {
              if (apiary__has_devices) {
                return this._check_devices_for_weight_or_temp$$;
              }

              return this._check_can_add_devices_from_expl$$;
            })
          );
        })
      );
    })
  );

  // #endregion

  // #region -> (loadings management)

  /** */
  private _is_loading_weight_data$$ = new BehaviorSubject(true);

  /** */
  private _is_loading_beecount_data$$ = new BehaviorSubject(true);

  /** */
  private _is_loading_temperature_data$$ = new BehaviorSubject(true);

  /** */
  public loadings = {
    /** */
    weight_data$$: this._is_loading_weight_data$$.asObservable(),

    /** */
    beecount_data$$: this._is_loading_weight_data$$.asObservable(),

    /** */
    temperature_data$$: this._is_loading_temperature_data$$.asObservable(),
  };

  // #endregion
}
