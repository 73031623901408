import { Component, ChangeDetectorRef, OnInit, ChangeDetectionStrategy, OnDestroy } from '@angular/core';

import { endOfDay, isAfter } from 'date-fns/esm';

import { Observable, of, Subscription } from 'rxjs';
import { map, switchMap, take } from 'rxjs';
import { distinctUntilRealChanged, replay } from '@bg2app/tools/rxjs';

import { TranslateService } from '@ngx-translate/core';
import { marker as i18n } from '@biesbjerg/ngx-translate-extract-marker';

import { Beeguard2Api } from 'app/core';
import { AppStateService } from 'app/core/app-state.service';
import { DialogsService } from 'app/widgets/dialogs-modals/dialogs.service';

import { NewEventModalComponent, NewEventModalParams } from '../new-event/new-event.modal';
import {
  DeleteEventDialogComponent,
  DeleteEventDialogOutput,
} from 'app/views/events/shared/delete-event-dialog/delete-event-dialog.component';

export interface UpdateEventModalParams extends NewEventModalParams {
  eid: number;
}

@Component({
  selector: 'bg2-update-event-modal',
  templateUrl: './update-event.modal.html',
  styleUrls: ['./update-event.modal.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UpdateEventModalComponent extends NewEventModalComponent<UpdateEventModalParams> implements OnInit, OnDestroy {
  public ro: string;
  public has_changed = true; // TODO: realy implement it, always true for now

  // #region -> (incoming parameters)

  public input_event_id$$ = this.input_params$$.pipe(
    map(input_params => +input_params?.eid),
    distinctUntilRealChanged(),
    replay()
  );

  // #endregion

  // #region -> (component basics)

  private _event_load_sub: Subscription = null;

  constructor(
    protected _bg2Api: Beeguard2Api,
    protected _dialogs: DialogsService,
    protected _cdRef: ChangeDetectorRef,
    protected _appState: AppStateService,
    protected _translate: TranslateService
  ) {
    super(_bg2Api, _dialogs, _cdRef, _appState, _translate);
    this._logger.update_prefix('UpdateEventModal');
  }

  ngOnInit(): void {
    this.loading = true;

    this._event_load_sub = this.input_event_id$$
      .pipe(
        switchMap(event_id => this._bg2Api.getEventObj(event_id)),
        take(1)
      )
      .subscribe({
        next: event => {
          this.loading = false;
          this.initial_loading = false;

          this.event = event;
        },
        error: (error: unknown) => {
          this.loading = false;
          this.initial_loading = false;

          this._logger.error(error);
        },
      });
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this._event_load_sub?.unsubscribe();
  }

  // #endregion

  // #region -> (form management)

  public submit(): void {
    if (this.loading) {
      return;
    }

    let agreement_pipe: Observable<boolean> = of(true);

    if (isAfter(new Date(this.event.date), endOfDay(new Date()))) {
      agreement_pipe = agreement_pipe.pipe(
        switchMap(last_agreement =>
          last_agreement
            ? this._dialogs.confirm(
                i18n<string>(`VIEWS.MODALS.FORM.The date you've picked is in the future. Are you sure this is the date you want to use ?`),
                {
                  onFalseMessage: i18n<string>('VIEWS.MODALS.FORM.Cancel'),
                  onTrueMessage: i18n<string>('VIEWS.MODALS.FORM.Confirm'),
                }
              )
            : of(false)
        )
      );
    }

    agreement_pipe.pipe(take(1)).subscribe({
      next: has_agreed => {
        if (has_agreed) {
          this.error = null;
          this.loading = true;
          super._submit();
        }
      },
    });
  }

  public deleteEvent(): void {
    this.is_trying_to_close_modal = true;

    this.event$$
      .pipe(
        take(1),
        map(event => event.id),
        switchMap(eventID => DeleteEventDialogComponent.open(this._dialogs, { eventID }))
      )
      .subscribe({
        next: dialog_result => {
          if (dialog_result === DeleteEventDialogOutput.DELETED) {
            super.force_close();
          } else {
            this.is_trying_to_close_modal = false;
          }
        },
      });
  }

  // #endregion

  // #region -> (modal management)

  public close(): void {
    if (this.is_trying_to_close_modal) {
      return;
    }

    if (this.event && this.event.has_changed) {
      this.is_trying_to_close_modal = true;

      this._dialogs
        .confirm(i18n<string>('VIEWS.MODALS.FORM.One or more changes are not saved. Do you want to close by abandoning these changes ?'), {
          onFalseMessage: i18n<string>('VIEWS.MODALS.FORM.Come back'),
          onTrueMessage: i18n<string>('VIEWS.MODALS.FORM.Close without saving'),
        })
        .subscribe(agreement => {
          this.is_trying_to_close_modal = false;

          if (agreement) {
            super.force_close();
          }
        });
    } else {
      super.force_close();
    }
  }

  // #endregion
}
