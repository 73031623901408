import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

import { marker as i18n } from '@biesbjerg/ngx-translate-extract-marker';
import { merge } from 'lodash-es';

import { BehaviorSubject, take } from 'rxjs';

/** */
interface Labels {
  /** */
  add: string;

  /** */
  cannot_add: string;

  /** */
  remove: string;

  /** */
  cannot_remove: string;
}

@Component({
  selector: 'bg2-favorite',
  templateUrl: './favorite.component.html',
  styleUrls: ['./favorite.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FavoriteComponent {
  // #region -> (component basics)

  constructor() {}

  // #endregion

  // #region -> (config)

  /** */
  private readonly DEFAULT_LABELS: Labels = {
    add: i18n<string>('WIDGETS.WIDGETS_REUSABLES.UI_COMPONENTS.FAVORITE.Add to favorites'),
    cannot_add: i18n<string>('WIDGETS.WIDGETS_REUSABLES.UI_COMPONENTS.FAVORITE.Already favorites'),

    remove: i18n<string>('WIDGETS.WIDGETS_REUSABLES.UI_COMPONENTS.FAVORITE.Remove from favorites'),
    cannot_remove: i18n<string>('WIDGETS.WIDGETS_REUSABLES.UI_COMPONENTS.FAVORITE.Already not in favorites'),
  };

  /** */
  private _labels: Labels = this.DEFAULT_LABELS;

  /** */
  @Input()
  public set labels(labels: Partial<Labels>) {
    this._labels = merge({}, this.DEFAULT_LABELS, labels);
  }

  /** */
  public get labels(): Labels {
    return this._labels;
  }

  @Input()
  public is_removable = true;

  @Input()
  public is_addable = true;

  // #endregion

  // #region -> (state handler)

  /** */
  @Input()
  public set is_favorite(is_favorite: boolean) {
    this._is_favorite$$.next(is_favorite);
  }

  /** */
  private _is_favorite$$ = new BehaviorSubject(null);

  /** */
  public is_favorite$$ = this._is_favorite$$.asObservable();

  /** */
  @Output()
  public favorite_changed = new EventEmitter<boolean>();

  /** */
  public update_favorite() {
    this.is_favorite$$.pipe(take(1)).subscribe({
      next: is_favorite => this.favorite_changed.next(!is_favorite),
    });
  }

  // #endregion
}
