<div class="bg2-map-popup-movement-marker-component">
  <ng-container *ngIf="marker$$ | async; let marker">
    <ng-container *ngIf="marker?.current_position; let current_position">
      <div style="display: flex; flex-flow: column nowrap;">
        <i>
          <b>{{ 'VIEWS_WINDOWED.MODALS.ROUTE_TRACER.Step [step]' | translate: { step: 1 } }}</b>
          <span>- {{ current_position.point.tracking_state }}</span>
        </i>

        <ng-container *ngIf="!current_position?.point?.gps_lng && !current_position?.point?.gps_lng">
          <span class="red-text">
            <span class="mdi mdi-alert-outline"></span>
            <span>&nbsp;{{ 'VIEWS_WINDOWED.MODALS.ROUTE_TRACER.Approximative position' | translate }}</span>
          </span>
        </ng-container>

        <ng-container *ngIf="current_position?.point?.date; let date">
          <span style="font-weight: 800">
            {{ 'VIEWS_WINDOWED.MODALS.ROUTE_TRACER.Date: [date_formated]' | translate: { date_formated: date | formatDate:'full' } }}
          </span>
        </ng-container>

        <ng-container *ngIf="current_position?.point?.gps_lat; else noGPSLatitude">
          <span>
            {{
              'VIEWS_WINDOWED.MODALS.ROUTE_TRACER.Position: [lat], [lon] ([accuracy] m)'
                | translate
                  : {
                      lat: current_position.point.gps_lat,
                      lon: current_position.point.gps_lng,
                      accuracy: current_position.point.gps_accuracy
                    }
            }}
          </span>
        </ng-container>

        <ng-template #noGPSLatitude>
          <span>
            {{
              'VIEWS_WINDOWED.MODALS.ROUTE_TRACER.Position: [lat], [lon] ([accuracy] m)'
                | translate
                  : {
                      lat: current_position.point.cell_ids_lat,
                      lon: current_position.point.cell_ids_lng,
                      accuracy: current_position.point.cell_ids_accuracy
                    }
            }}
          </span>
        </ng-template>

        <ng-container *ngIf="current_position?.point?.gps_speed; let gps_speed">
          <span>
            {{ 'VIEWS_WINDOWED.MODALS.ROUTE_TRACER.Speed: [speed] km/h' | translate: { speed: gps_speed } }}
          </span>
        </ng-container>

        <ng-container *ngIf="current_position?.point?.tracking_delta_last_move; let delta_last_move">
          <span>
            {{
              'VIEWS_WINDOWED.MODALS.ROUTE_TRACER.Time since last movement: [duration]'
                | translate: { duration: delta_last_move | formatDuration }
            }}
          </span>
        </ng-container>
      </div>
    </ng-container>
  </ng-container>
</div>
