import { Component, ChangeDetectionStrategy, Input } from '@angular/core';

import { isEmpty, isNil, uniqBy } from 'lodash-es';
import { BehaviorSubject, combineLatest, debounceTime, forkJoin, map, of, switchMap, take, tap } from 'rxjs';

import { UsersApiService } from 'app/core';
import { AppStateService } from 'app/core/app-state.service';

import { User } from '@bg2app/models/user';
import { Exploitation } from '@bg2app/models/entities';

import { replay } from '@bg2app/tools/rxjs';
import { UserQuery } from 'app/views/user/components/users-datatable/users-datatable.filters';

interface ExploitationXOwner {
  exploitation: Exploitation;
  id: number;
  name: string;

  owner: User;
}

@Component({
  selector: 'bg2-select-exploitation',
  templateUrl: './select-exploitation.component.html',
  styleUrls: ['./select-exploitation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectExploitationComponent {
  // #region -> (component basics)

  /** */
  constructor(public appState: AppStateService, private readonly _usersApi: UsersApiService) {}

  // #endregion

  // #region -> (manual filtering)

  /** */
  public filter$$ = new BehaviorSubject<string>('');

  /** */
  private _filtered_exploitations$$ = combineLatest({
    filter: this.filter$$,
    all_exploitations: this.appState.all_exploitations$$,
  }).pipe(
    debounceTime(100),
    switchMap(({ filter, all_exploitations }) => {
      const fixed_filter = filter ?? '';

      // Filter exploitations
      const filter_on_name = fixed_filter?.toLowerCase() ?? '';
      const filtered_exploitations = all_exploitations.filter(exploitation => exploitation.name?.toLowerCase()?.includes(filter_on_name));

      // Filter users
      const user_query: UserQuery = {
        _additional_filters_or: [
          {
            first_name__icontains: fixed_filter,
          },
          {
            last_name__icontains: fixed_filter,
          },
          {
            username__icontains: fixed_filter,
          },
          {
            phone_number__contains: fixed_filter,
          },
          {
            email__icontains: fixed_filter,
          },
        ],
      };

      const query_users$ = this._usersApi.fetch_users$(user_query).pipe(map(users_response => users_response?.users));
      return forkJoin({ all_exploitations: of(all_exploitations), exploitations: of(filtered_exploitations), users: query_users$ });
    }),
    map(({ all_exploitations, exploitations, users }) => {
      const user_ids = users?.map(user => {
        this._known_users.set(user.user_id, user);
        return user?.user_id;
      });
      const exploitations_match_user = all_exploitations.filter(exploitation => user_ids.includes(exploitation.user_id));

      const all_possible_exploitations = exploitations.concat(exploitations_match_user);
      const unique_exploitations = uniqBy(all_possible_exploitations, exploitation => exploitation.id);

      return unique_exploitations;
    })
  );

  /** */
  private filtered_exploitations$$ = combineLatest([
    this.appState.selected_exploitations$$,
    this._filtered_exploitations$$,
    this.filter$$,
  ]).pipe(
    debounceTime(5),
    map(([selected_exploitations, filtered_exploitations, filter]) => {
      const visible_exploitations = new Set(selected_exploitations);

      const max_displayed_option =
        isNil(filter) || isEmpty(filter) ? this.MAX_SHOW_OTIONS : this.MAX_SHOW_OTIONS * 2 + selected_exploitations.length;

      const nb_max = Math.min(filtered_exploitations.length + selected_exploitations.length, max_displayed_option);

      for (let new_idx = 0; new_idx < filtered_exploitations.length && visible_exploitations.size < nb_max; new_idx++) {
        visible_exploitations.add(filtered_exploitations[new_idx]);
      }

      return visible_exploitations;
    }),
    map(exploitations => [...exploitations]),
    tap(exploitations => (this.visible_exploitations = exploitations)),
    replay()
  );

  // #endregion

  /** */
  private _known_users = new Map<number, User>();

  /** */
  public items$$ = this.filtered_exploitations$$.pipe(
    map(exploitations =>
      exploitations.reduce((result: ExploitationXOwner[], exploitation, index) => {
        const known_user = this._known_users.get(exploitation?.user_id);

        const option: ExploitationXOwner = {
          exploitation,
          id: exploitation?.id,
          name: exploitation?.name,

          owner: known_user,
        };

        result.push(option);

        return result;
      }, [])
    ),
    replay()
  );

  /** */
  private MAX_SHOW_OTIONS = 20;

  /** */
  @Input()
  public max_displayed = 3;

  /** */
  @Input()
  public no_padding = false;

  /** */
  private visible_exploitations: Exploitation[] = [];

  public selectAll(): void {
    this._filtered_exploitations$$.pipe(take(1)).subscribe({
      next: exploitations => {
        const exploitation_ids = exploitations?.map(exploitation => exploitation?.id);
        this.appState.selectExploitations(exploitation_ids);
      },
    });
  }

  public unselectAll(): void {
    this.appState.selectExploitations([]);
  }

  public assertSelectObject(model: any): ExploitationXOwner {
    return model;
  }
}
