<div class="bg-dialog">
  <dialog-header>
    <span>{{ 'VIEWS.DEVICES.DIALOGS_AND_MODALS.DEVICE_SUPPORT_DIALOGS.TITLE.New support' | translate }}</span>
  </dialog-header>

  <dialog-body>
    <ng-container *ngIf="(is_submitted_declaration$$ | async) === false">
      <div class="message message-info">
        <span class="mdi mdi-24px mdi-information message-icon"></span>
        <div class="message-content">
          <div class="message-content__title">
            <span>{{ 'ALL.COMMON.Informations' | translate }}</span>
          </div>

          <div class="message-content__text">
            <div>
              {{ 'VIEWS.DEVICES.DIALOGS_AND_MODALS.DEVICE_SUPPORT_DIALOGS.MESSAGE.INFO.You are about to create a new RU' | translate }}
            </div>
            <div>
              {{
                'VIEWS.DEVICES.DIALOGS_AND_MODALS.DEVICE_SUPPORT_DIALOGS.MESSAGE.INFO.Please verify that all devices linked to the ticket are selected before continuing'
                  | translate
              }}
            </div>
          </div>
        </div>
      </div>

      <div *ngIf="(devices_supports$$ | async)?.length > 0" class="message message-alert">
        <span class="mdi mdi-24px mdi-alert message-icon"></span>
        <div class="message-content">
          <div class="message-content__title">
            <span>{{ 'VIEWS.DEVICES.DIALOGS_AND_MODALS.DEVICE_SUPPORT_DIALOGS.MESSAGE.ERROR.Already created issue' | translate }}</span>
          </div>

          <div class="message-content__text">
            <div *ngLet="support_type$$ | async; let support_type">
              {{
                'VIEWS.DEVICES.DIALOGS_AND_MODALS.DEVICE_SUPPORT_DIALOGS.MESSAGE.ERROR.There is already an open issue for some devices'
                  | translate
              }}
            </div>
            <div>
              <span>{{ 'VIEWS.DEVICES.DIALOGS_AND_MODALS.DEVICE_SUPPORT_DIALOGS.MESSAGE.Concerned devices' | translate | colon }}</span>
              <span style="text-decoration: underline">
                {{ devices_supports$$ | async | mapArray : 'device' | mapArray : 'name' | join : ', ' }}
              </span>
            </div>
          </div>
        </div>
      </div>

      <div *ngIf="(missing_devices_of_ticket$$ | async)?.length > 0" class="message message-info">
        <span class="mdi mdi-24px mdi-information message-icon"></span>
        <div class="message-content">
          <div class="message-content__title">
            <span>
              {{ 'VIEWS.DEVICES.DIALOGS_AND_MODALS.DEVICE_SUPPORT_DIALOGS.MESSAGE.INFO.Several devices has been added' | translate }}
            </span>
          </div>

          <div class="message-content__text">
            <div>
              {{
                'VIEWS.DEVICES.DIALOGS_AND_MODALS.DEVICE_SUPPORT_DIALOGS.MESSAGE.INFO.The related DESK ticket refers to these devices : [device_name_list]'
                  | translate
                    : {
                        device_name_list: missing_devices_of_ticket$$ | async | mapArray : 'name' | join : ', '
                      }
              }}.
            </div>
          </div>
        </div>
      </div>

      <mat-form-field [appearance]="'outline'">
        <mat-label>{{ 'VIEWS.DEVICES.SUPPORTS-DIALOG.Which device to declare as "RU" ?' | translate }}</mat-label>

        <mtx-select
          [multiple]="true"
          [appendTo]="null"
          [bindLabel]="'name'"
          [bindValue]="'imei'"
          [ngModel]="selected_devices_for_ru$$ | async"
          [items]="available_devices_for_support$$ | async"
          (ngModelChange)="selected_devices_for_ru = $event"
        >
          <!-- Template for each item inside the dropdown -->
          <ng-template ng-option-tmp let-object="item" let-index="index" let-search="searchTerm">
            <div *ngIf="object" class="ng-option-template">
              <ng-container *ngIf="asType(object?.device, 'device'); let device">
                <span>{{ device?.name }}</span>

                <span *ngIf="object?.disabled" class="already-closed-support">
                  {{ 'DEVICE.SUPPORTS.ERROR.Support already opened' | translate }}
                </span>
              </ng-container>
            </div>
          </ng-template>
        </mtx-select>
      </mat-form-field>

      <div *ngIf="can_show_form$$ | async">
        <bg2-form-overlay
          [source_schema]="schema$$ | async"
          [source_model]="form_model$$ | async"
          (when_form_valid_update)="setFormValid($event)"
          (when_form_data_update)="onFormModelChanged($event)"
        ></bg2-form-overlay>
      </div>
    </ng-container>

    <ng-container *ngIf="is_submitted_declaration$$ | async">
      <div class="prepare-supports-creation">
        <!-- Failed requests -->
        <div *ngIf="have_failed_requests$$ | async" class="message message-alert">
          <span class="mdi mdi-24px mdi-alert message-icon"></span>
          <div class="message-content">
            <div class="message-content__title">
              <span>{{
                'VIEWS.DEVICES.DIALOGS_AND_MODALS.DEVICE_SUPPORT_CREATE_DIALOG.ERROR.Failed to create some supports' | translate
              }}</span>
            </div>

            <div class="message-content__text">
              <div>
                {{
                  'VIEWS.DEVICES.DIALOGS_AND_MODALS.DEVICE_SUPPORT_CREATE_DIALOG.MESSAGE.ERROR.Some supports could not be created for some reason'
                    | translate
                }}
              </div>
              <div>
                {{
                  'VIEWS.DEVICES.DIALOGS_AND_MODALS.DEVICE_SUPPORT_DIALOGS.MESSAGE.ERROR.Please retry the failed requests before continuing'
                    | translate
                }}
              </div>

              <bg2-button
                style="width: 100%"
                [type]="'action'"
                [icon]="'mdi-reload'"
                [message]="'retry failed requests'"
                (btn_click)="retry_failed_requests()"
              ></bg2-button>
            </div>
          </div>
        </div>

        <div class="prepare-supports-creation__title">
          {{ 'VIEWS.DEVICES.DIALOGS_AND_MODALS.DEVICE_SUPPORT_DIALOGS.State of creation of the RU supports' | translate }}
        </div>

        <ul class="prepare-supports-creation__requests">
          <li *ngFor="let request of request_states$$ | async | keyvalue; let index = index">
            <div class="request-state">
              <span>
                <b>{{ request.value.device.name }}</b>
              </span>

              <mat-divider></mat-divider>

              <span class="state --{{ request.value.state }}">
                <span *ngIf="request.value.state === 'failed'" class="mdi mdi-close-thick"></span>
                <span *ngIf="request.value.state === 'success'" class="mdi mdi-check-bold"></span>
                <span *ngIf="request.value.state === 'pending'" class="mdi mdi-loading mdi-spin"></span>
                <span>
                  <ng-container *ngIf="request.value.state === 'failed'">{{ 'ALL.COMMON.Failed' | translate }}</ng-container>
                  <ng-container *ngIf="request.value.state === 'success'">{{ 'ALL.COMMON.Created' | translate }}</ng-container>
                  <ng-container *ngIf="request.value.state === 'pending'">{{ 'ALL.COMMON.Pending' | translate }}</ng-container>
                </span>
              </span>
            </div>

            <div *ngIf="request.value.state === 'failed'" class="request-fail-reason">
              <b>{{ 'ALL.COMMON.Reason' | translate | colon }}</b>
              <i>{{ request?.value?.reason ?? 'ALL.COMMON.Unknown' | translate }}</i>
            </div>
          </li>
        </ul>
      </div>
    </ng-container>
  </dialog-body>

  <div class="dialog-footer">
    <ng-container *ngIf="(is_submitted_declaration$$ | async) === false">
      <bg2-button
        [type]="'cancel'"
        [message]="'VIEWS.MODALS.FORM.Cancel' | translate"
        (btn_click)="close(false)"
        [disabled]="is_submitted_declaration$$ | async"
      >
      </bg2-button>

      <bg2-button
        [type]="'action'"
        [icon]="'mdi-content-save'"
        [loading]="is_submitted_declaration$$ | async"
        [message]="'VIEWS.MODALS.FORM.Save' | translate"
        [disabled]="(form_valid$$ | async) === false || (is_submitted_declaration$$ | async)"
        (btn_click)="submit_declaration()"
      >
      </bg2-button>
    </ng-container>

    <ng-container *ngIf="is_submitted_declaration$$ | async">
      <bg2-button
        [type]="'action'"
        [icon]="'mdi-content-save'"
        [loading]="is_updating_desk_ticket$$ | async"
        [message]="'VIEWS.DEVICES.DIALOGS_AND_MODALS.DEVICE_SUPPORT_CREATE_DIALOG.ACTION.Apply to DESK ticket and quit' | translate"
        [disabled]="(have_pending_requests$$ | async) || (have_failed_requests$$ | async) || (is_updating_desk_ticket$$ | async)"
        (btn_click)="submit_to_desk_and_quit()"
      >
      </bg2-button>
    </ng-container>
  </div>
</div>
