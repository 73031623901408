import { AfterViewInit, ChangeDetectionStrategy, Component, OnDestroy, Renderer2 } from '@angular/core';

import { get, isEmpty, isNil, omit, sum, values } from 'lodash-es';

import { Observable, of, map, switchMap, combineLatest } from 'rxjs';
import { nullValueToEmptyDict } from '@bg2app/tools/rxjs';

import { Beeguard2Api } from 'app/core';

import { EvaluationEvent } from 'app/models/events/Evaluation';

import { HiveSupersData } from 'app/views/events/shared/events/evaluation/evaluation-histogram-hive-supers/evaluation-histogram-hive-supers.component';
import { HiveQueensData } from 'app/views/events/shared/events/evaluation/evaluation-histogram-hive-queens/evaluation-histogram-hive-queens.component';
import { HiveStatusesData } from 'app/views/events/shared/events/evaluation/evaluation-histogram-hive-statuses/evaluation-histogram-hive-statuses.component';
import { HiveBroodframesData } from 'app/views/events/shared/events/evaluation/evaluation-histogram-hive-broodframes/evaluation-histogram-hive-broodframes.component';
import { EventBasedCarouselSlideComponent } from 'app/widgets/widgets-reusables/carousels/event-based-carousel/event-carousel-slide/event-carousel-slide.component';
import { VisitSummaryData } from 'app/views/events/shared/events/evaluation/evaluation-resume/evaluation-resume.component';

@Component({
  selector: 'bg2-apiary-br-evaluation-carousel-group',
  templateUrl: './apiary-br-evaluation-carousel-group.component.html',
  styleUrls: [
    '../../../../../../widgets/widgets-reusables/carousels/abstract-carousel/abstract-carousel-slide/abstract-carousel-slide.component.scss',
    './apiary-br-evaluation-carousel-group.component.scss',
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ApiaryBREvaluationCarouselGroupComponent
  extends EventBasedCarouselSlideComponent<EvaluationEvent>
  implements AfterViewInit, OnDestroy
{
  // #region -> (component basics)

  constructor(protected _renderer: Renderer2, protected _bg2Api: Beeguard2Api) {
    super(_renderer, _bg2Api);

    // Map specific name to card position id defined by the template.
    this.slide_card_mapping = new Map([
      ['summary', 0],
      ['hive_statuses', 1],
      ['queen_colors', 2],
      ['supers', 3],
      ['broodframes', 4],
    ]);
  }

  ngAfterViewInit(): void {
    super.ngAfterViewInit();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  // #endregion

  // #region -> (hive statuses management)

  /** */
  public hive_statuses_data$$ = this.event$$.pipe(
    switchMap(event => (event?.hive_statuses$$ ?? of(<HiveStatusesData>{})) as Observable<HiveStatusesData>),
    nullValueToEmptyDict()
  );

  // #endregion

  // #region -> (queen colors management)

  /** */
  public queen_colors_data$$ = this.event$$.pipe(
    switchMap(event => (event?.queen_colors$$ ?? of(<HiveQueensData>{})) as Observable<HiveQueensData>),
    nullValueToEmptyDict()
  );

  // #endregion

  // #region -> (supers management)

  /** */
  public supers_data$$ = this.event$$.pipe(
    switchMap(event => (event?.supers$$ ?? of(<HiveSupersData>{})) as Observable<HiveSupersData>),
    nullValueToEmptyDict()
  );

  public nb_supers$$ = this.event$$.pipe(
    switchMap(event => (event?.nb_supers$$ ?? of(null) as Observable<number>))
  );

  // #endregion

  // #region -> (broodframes management)

  /** */
  public broodframes_data$$ = this.event$$.pipe(
    switchMap(event => (event?.broodframes$$ ?? of(<HiveBroodframesData>{})) as Observable<HiveBroodframesData>),
    nullValueToEmptyDict()
  );

  // #endregion

  public summary_data$$: Observable<VisitSummaryData> = combineLatest({
    supers: this.nb_supers$$.pipe(
      map(nb_supers => ({ has_data: !isNil(nb_supers), total: nb_supers }))
    ),
    queens: this.queen_colors_data$$.pipe(
      map(data => {
        const has_data = !isNil(data) && !isEmpty(data);

        if (!has_data) {
          return { has_data, total: null };
        }

        const partial_of_queens: Partial<HiveQueensData> = omit(data, ['unknown', 'not_found']);
        const total = sum(values(partial_of_queens));

        return { has_data, total };
      })
    ),
    statuses: this.hive_statuses_data$$.pipe(
      map(data => {
        const has_data = !isNil(data) && !isEmpty(data);

        if (!has_data) {
          return { has_data, total: null };
        }

        const total = get(data, 'OK', 0) as number;

        return { has_data, total };
      })
    ),
    broodframes: this.broodframes_data$$.pipe(
      map(data => {
        const has_data = !isNil(data) && !isEmpty(data);

        if (!has_data) {
          return { has_data, total: null };
        }

        return { has_data, total: sum(values(data)) };
      })
    ),
    nb_hives_and_nuc: this.event$$.pipe(
      switchMap(event =>
        combineLatest({
          nb_hives: event?.nb_hives$$ ?? of(<number>null),
          nb_nuc: event?.nb_nuc$$ ?? of(<number>null),
        })
      )
    ),
  }).pipe(
    map(({ supers, queens, statuses, broodframes, nb_hives_and_nuc }) => {
      const model: VisitSummaryData = {
        total_nucs: nb_hives_and_nuc?.nb_nuc ?? 0,
        total_hives: nb_hives_and_nuc?.nb_hives ?? 0,

        hive_statuses: {
          total: statuses?.total,
          is_evaluated: statuses?.has_data,
        },

        queen_colors: {
          total: queens?.total,
          is_evaluated: queens?.has_data,
        },

        supers: {
          total: supers?.total,
          is_evaluated: supers?.has_data,
        },

        broodframes: {
          total: broodframes?.total,
          is_evaluated: broodframes?.has_data,
        },
      };

      return model;
    })
  );
}
