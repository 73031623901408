import { Observable, of, combineLatest, concat } from 'rxjs';
import { filter, map, distinctUntilChanged, switchMap } from 'rxjs';

import {
  assign, cloneDeep, isArray, isEmpty, isEqual,
  isFunction, isNil, isNumber, keys, union
} from 'lodash-es';

import { parseDate } from 'app/misc/tools';

import { FormProperty } from 'ngx-schema-form/lib/model/formproperty';

import { marker as i18n } from '@biesbjerg/ngx-translate-extract-marker';

import { Beeguard2Api } from '../../core';

import { EntityState } from 'app/models/entities/misc';
import { Event, Entity } from '../../models';
import { replay } from '@bg2app/tools/rxjs';
import { Dictionary } from 'app/typings/core/interfaces';


export interface TrackOption {
  value: 'from_entity' | 'from_property' | 'from_entity_in_schema';
  entity_path?: string;
  path?: string;
  date_path?: string;
}

export type TrackOptions = TrackOption | TrackOption[];


export interface EventDateAndId {
  date: Date;
  event_id: number | null;
}


function _robustSearchProperty(property: FormProperty, path: string): FormProperty | null {
  while (path.startsWith('../')) {
    path = path.slice(3);
    property = property.parent;
    // console.log({path, property})
  }
  let sproperty = property.searchProperty(path);
  if (isNil(sproperty) && isFunction((property as any).getProperty)) {
    sproperty = (property as any).getProperty(path);
  }
  // console.log({path, property, sproperty})
  if (isNil(sproperty)) {
    sproperty = property.root.getProperty(path);
  }
  return sproperty;
}

/**
 * Robust search of a form property
 *
 * @param property
 * @param path
 * @param possible_root
 */
export function robustSearchProperty(property: FormProperty, path: string, possible_root?: FormProperty): FormProperty | null {
  let sproperty = _robustSearchProperty(property, path);
    if (isNil(sproperty) && !isNil(possible_root)) {
      sproperty = _robustSearchProperty(possible_root, path);
    }
    return sproperty;
}

/**
 * Build an observable to monitor form's event date (and eventual id)
 */
export const trackEventDateAndId = (date_property: FormProperty): Observable<EventDateAndId> => {
  if (isNil(date_property)) {
    console.error('Date property not found');
    return null;
  }
  const date_observable = concat(of(date_property.value), date_property.valueChanges).pipe(
    filter(date => date.trim()),
    map(date => parseDate(date)),
    filter(date => !isNil(date)),
    map(date => {
      const event_id = date_property.schema.event_id || null;
      return { date, event_id };
    }),
    distinctUntilChanged((previous, current) => isEqual(previous, current)),
    replay()
  );
  return date_observable;
};

export const trackEntityStateBeforeEvent = (
  entity_property: FormProperty,
  bg2Api: Beeguard2Api,
  event_date_and_id: Observable<EventDateAndId>
): Observable<EntityState> => {
  const obs = concat(of(entity_property.value), entity_property.valueChanges).pipe(
    filter((entity_id: number) => !isNil(entity_id) && entity_id > 0),
    distinctUntilChanged(),
    map(entity_id =>
      // console.log({entity_id})
      entity_id
    ),
    switchMap((entity_id: number) => bg2Api.getEntityObj(entity_id)),
    switchMap(entity =>
      // Reload when app current state changed
      concat(of(entity), entity.updates$)
    ),
    switchMap(entity => {
      if (isNil(event_date_and_id)) {
        event_date_and_id = of({ entity, date: new Date(), event_id: null });
      }
      return event_date_and_id.pipe(
        map(edate =>
          // console.log({edate});
          edate
        ),
        map(res => {
          const rres = {
            entity,
            date: res.date,
            event_id: res.event_id
          };
          // console.log(rres);
          return rres;
        })
      );
    }),
    switchMap((res: { entity: Entity; date: Date; event_id: number }) => {
      const entity = res.entity;
      const date = res.date;
      const event_id = res.event_id;
      return entity.getStateAtDate(date, event_id);
    })
  );
  return obs;
};

export const trackEntityValueBeforeEvent = (
  entity_src: FormProperty | Entity | number,
  value_path: string,
  bg2Api: Beeguard2Api,
  event_date_and_id: Observable<EventDateAndId>
): Observable<any> => {
  // console.log('trackEntityValueBeforeEvent');
  let entity_obs;
  if (entity_src instanceof Entity) {
    entity_obs = of(entity_src);
  } else {
    if (isNumber(entity_src)) {
      entity_obs = of(entity_src);
    } else {
      entity_obs = concat(of(entity_src.value), entity_src.valueChanges);
    }
    entity_obs = entity_obs.pipe(
      filter((entity_id: number) => !isNil(entity_id) && entity_id >= 0),
      switchMap((entity_id: number) => bg2Api.getEntityObj(entity_id)),
    );
  }

  const obs = entity_obs.pipe(
    switchMap((entity: Entity) =>
      // Reload when app current state changed
      concat(of(entity), entity.updates$)
    ),
    switchMap(entity => {
      if (isNil(event_date_and_id)) {
        event_date_and_id = of({ entity, date: new Date(), event_id: null });
      }
      return event_date_and_id.pipe(
        map(res => {
          const rres = {
            entity,
            date: res.date,
            event_id: res.event_id
          };
          return rres;
        })
      );
    }),
    switchMap((res: { entity: Entity; date: Date; event_id: number }) => {
      const entity = res.entity;
      const date = res.date;
      const event_id = res.event_id;
      // console.log(entity, date);
      return entity.getAtDate(value_path, date, event_id);
    })
  );
  return obs;
};

const _trackValue = (
  property: FormProperty,
  bg2Api: Beeguard2Api,
  track_conf: TrackOption,
  event_date_and_id: Observable<EventDateAndId>
): Observable<any> | null => {
  let default_obs = null;
  if (track_conf && track_conf.value === 'from_entity') {
    const entity_property = robustSearchProperty(property, track_conf.entity_path);
    if (!isNil(entity_property)) {
      default_obs = trackEntityValueBeforeEvent(entity_property, track_conf.path, bg2Api, event_date_and_id);
    } else {
      console.warn(`Source entiry property not found (${track_conf.entity_path})`);
    }
  } else if (track_conf && track_conf.value === 'from_entity_in_schema') {
    const entity_id = (property.root.schema.entity_ids || {})[track_conf.entity_path];
    // console.log(entity)
    if (!isNil(entity_id)) {
      default_obs = trackEntityValueBeforeEvent(entity_id, track_conf.path, bg2Api, event_date_and_id);
    }
  } else if (track_conf && track_conf.value === 'from_property') {
    const oproperty = robustSearchProperty(property, track_conf.path);
    if (!isNil(oproperty)) {
      default_obs = concat(of(oproperty.value), oproperty.valueChanges);
    }
  }
  return default_obs;
};

/**
 * Create an observable over a value, in case :
 *  - cames from an entity
 *  - cames from an other property
 *  - or an array of such
 *
 * @param property
 * @param bg2Api
 * @param track_conf
 * @param event_date_and_id
 */
export const trackValue = (
  property: FormProperty,
  bg2Api: Beeguard2Api,
  track_confs: TrackOptions,
  event_date_and_id: Observable<EventDateAndId>
): Observable<any> | null => {
  // console.log(property.path, 'track value', track_confs);
  if (isArray(track_confs)) {
    const val = track_confs.map(config => _trackValue(property, bg2Api, config, event_date_and_id))
      .filter(obs => !isNil(obs));
    if (val.length === 0) {
      return null;
    }
    return combineLatest(val).pipe(
      // tap(values => console.log(values)),
      map(values => values.filter(local_value => !isNil(local_value))),
      filter(values => values.length > 0),
      map(values => values[0]),
    );
  } else {
    return _trackValue(property, bg2Api, track_confs, event_date_and_id);
  }
};


/**
 * Build a json schema validator for 'entity_dict'
 *
 * @param schemaValidatorFactory
 * @param schema
 */
export const buildEntityValidator = (schemaValidatorFactory: any, schema: any) => {
  let validator = (entity_dict: any): any => null;
  if (schema) {
    const _validator = schemaValidatorFactory.createValidatorFn(schema);
    validator = entity_dict => {
      const errors = _validator(entity_dict);
      return errors;
    };
  }
  return validator;
};


/* Helper class that generate and keep up to date an event form schema
 *
 * Note: It's quite useless for now to have such a specific class to build the schema.
 * The interest was to manage continous update of the schema.
 */
export class EventForm {
  // JSON Schema for the event
  public static schema_base: any = {
    type: 'object',
    options: {
      beta: false,
      event_date_path: 'date', // default path to event date
    },
    properties: {
      date: {
        label: i18n('EVENT.ALL.COMMON.Date of the intervention'),
        type: 'string',
        widget: 'date-time',
        event_id: undefined,
      },
      apply_to: {
        type: 'object',
        properties: {}
      }
    },
    required: ['date', 'apply_to']
  };

  public static buildSchema(event?: Event, args?: any, remove_setup = true): Observable<any> {
    return event.schema$$.pipe(
      map(event_schema => {
        const schema = EventForm.computeSchema(event_schema, args, remove_setup);
        // Add event id in date schema, this is usefull to get entities states at exact position
        if (!isNil(event.id)) {
          schema.properties.date.event_id = event.id;
        }
        return schema;
      })
    );
  }

  public static updateSchemaDefault(defaults: any, schema: any) {
    // console.log('updateSchemaDefault', defaults, schema);
    if (schema.type == 'array') {
      schema.default = defaults;
    } else {
      for (const property in defaults) {
        if (defaults.hasOwnProperty(property)) {
          if (schema.properties && schema.properties[property]) {
            if (typeof defaults[property] === 'object') {
              EventForm.updateSchemaDefault(defaults[property], schema.properties[property]);
            } else {
              schema.properties[property].default = defaults[property];
            }
          } else {
            // console.log(schema.properties);
            console.error(`Unknow property '${property}'`);
          }
        }
      }
    }
    
  }

  /**
   * Compote form schema from an event schema (return by bg2-api)
   *
   * @param event_schema
   * @param args
   */
  protected static computeSchema(event_schema: any, args: any, remove_setup = true) {
    const date_schema = cloneDeep(event_schema.date_schema);
    const needed_entities = cloneDeep(event_schema.needed_entities);
    const data_schema = cloneDeep(event_schema.data_schema);
    const schema: Dictionary<any> = cloneDeep(EventForm.schema_base);
    // Update date schema
    schema.properties.date = assign(schema.properties.date, date_schema);
    schema.options.beta = event_schema.beta;  // is event beta
    schema.options.event_date_path = 'date';  // reset default path
    // Update data list
    if (data_schema && data_schema.properties && !isEmpty(data_schema.properties)) {
      if (data_schema.required && !isEmpty(data_schema.required)) {
        schema.required.push('data');
      }
      if (args && args.data) {
        // console.log(args['data']);
        // console.log(data_schema);
        EventForm.updateSchemaDefault(args.data, data_schema);
        // console.log(data_schema);
      }
      schema.properties.data = data_schema;
    }
    let apply_to_required: any[] = [];
    keys(needed_entities).map((role: string) => {
      const entity_conf = needed_entities[role];
      if (!remove_setup || !entity_conf.setup_event) {
        const operand_schema = EventForm.getOperandSchema(role, entity_conf, args);
        schema.properties.apply_to.properties[role] = operand_schema;
        if (!operand_schema.options.nullable) {
          apply_to_required = union(apply_to_required, [role]);
        }
      }
    });
    schema.properties.apply_to.required = apply_to_required;
    // console.log(schema);
    return schema;
  }

  /** Convert entity_conf in event description to JSON schema
   *
   */
  private static getOperandSchema(role: any, entity_conf: any, args?: any): any {
    // Basic schema
    const entity_schema: Dictionary<any> = {
      type: 'number',
      label: entity_conf.label || `ENTITY.ALL.TYPE.${entity_conf.type}`,
    };
    // Entity type specific operations
    const etype = entity_conf.type;
    entity_schema.widget = 'bg2entity';
    if (entity_conf.widget) {
      entity_schema.widget = entity_conf.widget;
    }
    entity_schema.etype = etype;
    if (entity_conf.visibleIf) {
      entity_schema.visibleIf = entity_conf.visibleIf;
    }
    // Configure widget options
    entity_schema.options = entity_conf.options || {};
    // get options back from uper level for compat
    const schema_keys = [ //NOTE: should correspond to bg2entity options
      'dynamic',
      'readonly',
      'readonly_if',
      'nullable',
      'hide_if_null',
      'createnew',
      'entity_validator',
      'with_in',
      'multiple',
      '_default'
    ];
    schema_keys.map(key => {
      if (entity_conf[key]) {
        entity_schema.options[key] = entity_conf[key];
      }
    });

    // Manage static default value (rarely used)
    if (entity_conf['default']) {
      entity_schema.default = entity_conf['default'];
    }

    // Manage multiple
    if (entity_schema.options.multiple) {
      entity_schema.type = 'array';
      entity_schema.items = {
        type: 'number',
      };
    }
    // Copy specfic args to the schema
    const role_args = `${role}_args`;
    if (args && !isNil(args[role_args])) {
      entity_schema.args = args[role_args];
    }
    // XXX TODO
    // Default value in args => so fixed value
    // console.log(this.args);
    if (args && !isNil(args[role])) {
      entity_schema.default = args[role];
      entity_schema.options.readonly = true;
    } else if (role === 'exploitation' && isNil(entity_conf.dynamic)) {
      entity_schema.options._default = '_first';
    } else if (role === 'warehouse' && isNil(entity_conf.dynamic)) {
      entity_schema.options._default = '_first';
    }
    // Check if dynamic from an other entity with fixed value
    if (entity_conf.dynamic && entity_conf.dynamic.from) {
      const from = entity_conf.dynamic.from;
      if (args && !isNil(args[from])) {
        entity_schema.options.readonly = true;
      }
    }
    // console.log(entity_schema);
    return entity_schema;
  }

}
