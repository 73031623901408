import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

import { marker as i18n } from '@biesbjerg/ngx-translate-extract-marker';
import { ConsoleLoggerService } from 'app/core/console-logger.service';

import { strEnum } from 'app/misc/tools';

import {
  Bg2SimpleHistogramBaseComponent,
  SimpleHistogramConfig,
} from 'app/widgets/widgets-reusables/charts/histograms/simple-histogram-base/simple-histogram-base.component';
import { ErrorHelperData } from 'app/widgets/widgets-reusables/errors/error-helper/error-helper.component';

const hive_queen = strEnum(['b', 'w', 'y', 'r', 'g', 'unknow', 'not_found', 'all']);
export type HiveQueen = keyof typeof hive_queen;

export type HiveQueensData = { [key in HiveQueen]: number };

@Component({
  selector: 'bg2-evaluation-histogram-hive-queens',
  templateUrl: '../../../../../../widgets/widgets-reusables/charts/histograms/simple-histogram-base/simple-histogram-base.component.html',
  styleUrls: ['../../../../../../widgets/widgets-reusables/charts/histograms/simple-histogram-base/simple-histogram-base.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class Bg2EvaluationHistogramHiveQueensComponent extends Bg2SimpleHistogramBaseComponent implements OnInit {
  @Input()
  public set queens(queens: HiveQueensData) {
    this.histogram_data = queens;
  }

  // #region -> (component basics)

  protected _logger = new ConsoleLoggerService('Bg2EvaluationHistogramHiveQueensComponent', true);

  constructor() {
    super();
    this.histogram_type = 'queen-color';
  }

  ngOnInit(): void {
    super.ngOnInit();

    this.histogram_configuration = new Map([
      ['b', { key: 'b', label: 'EVENT.VISIT.QUEEN.blue', img: 'assets/img/queen/b.png' }],
      ['w', { key: 'w', label: 'EVENT.VISIT.QUEEN.white', img: 'assets/img/queen/w.png' }],
      ['y', { key: 'y', label: 'EVENT.VISIT.QUEEN.yellow', img: 'assets/img/queen/y.png' }],
      ['r', { key: 'r', label: 'EVENT.VISIT.QUEEN.red', img: 'assets/img/queen/r.png' }],
      ['g', { key: 'g', label: 'EVENT.VISIT.QUEEN.green', img: 'assets/img/queen/g.png' }],
      ['unknow', { key: 'unknow', label: 'EVENT.VISIT.QUEEN.Unknow', img: 'assets/img/queen/unknow.png' }],
      ['not_found', { key: 'not_found', label: 'EVENT.VISIT.QUEEN.Not found', img: 'assets/img/queen/not_found.png' }],
    ]) as SimpleHistogramConfig;

    this.has_data$$.subscribe({
      next: has_data => {
        if (!has_data) {
          this.error = new ErrorHelperData([{ type: 'span', content: i18n<string>('ALL.DATA.MISC.No data') }]);
        } else {
          this.error = null;
        }
      },
    });
  }

  // #endregion
}
