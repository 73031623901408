<span class="mdi mdi-auto-fix is-ghost-icon" *ngIf="is_ghost$$ | async"></span>

<ng-container *ngIf="event_desc$$ | async; let event_desk; else: loading">
  <span class="event-span" [innerHTML]="event_desk | safe : 'html'" (click)="getRoute($event)"></span>

  <ng-container *ngIf="event$$ | async; let event">
    <span *ngIf="can_write_all_events$$ | async; let can_write_all_events" class="event-link">
      <a
        [routerLink]="[
          '',
          {
            outlets: {
              modal: [event?.vconf?.update_modal, { eid: event?.id, ro: !can_write_all_events ? 1 : 0 }]
            }
          }
        ]"
        [queryParamsHandling]="'preserve'"
      >
        <span class="mdi mdi-pencil"></span>
      </a>
    </span>

    <span *ngIf="(can_write_all_events$$ | async) === false; let can_write_all_events" class="event-link">
      <a
        [routerLink]="[
          '',
          {
            outlets: {
              modal: [event?.vconf?.update_modal, { eid: event?.id, ro: !can_write_all_events ? 1 : 0 }]
            }
          }
        ]"
        [queryParamsHandling]="'preserve'"
      >
        <span class="mdi mdi-eye"></span>
      </a>
    </span>
  </ng-container>
</ng-container>

<ng-template #loading>
  <span translate>ALL.COMMON.Loading</span>
</ng-template>
