import { Injectable } from '@angular/core';
import { ZohoBooksApiService } from './zoho-books-api.service';

import { ZohoCRMApiService } from './zoho-crm-api.service';
import { ZohoDeskApiService } from './zoho-desk-api.service';

@Injectable({
  providedIn: 'root',
})
export class ZohoApisService {
  // #region -> (service basics)

  constructor(
    private readonly _zohoCrmApiService: ZohoCRMApiService,
    private readonly _zohoDeskApiService: ZohoDeskApiService,
    private readonly _zohoBooksApiService: ZohoBooksApiService
  ) {}

  // #endregion

  /** */
  public get crm_api(): ZohoCRMApiService {
    return this._zohoCrmApiService;
  }

  /** */
  public get desk_api(): ZohoDeskApiService {
    return this._zohoDeskApiService;
  }

  /** */
  public get books_api(): ZohoBooksApiService {
    return this._zohoBooksApiService;
  }
}
