import { NgZone } from '@angular/core';

import * as d3 from 'd3';

import { get, isArray, isEmpty, isNil } from 'lodash-es';

import { AppStateService } from 'app/core/app-state.service';
import { ConsoleLoggerService } from 'app/core/console-logger.service';
import { D3SharedCursorService } from 'app/core/services/global/d3-shared-cursor.service';

import {
  AbstractUserEvents,
  AbstractD3Grid,
  AbstractD3Axes,
  AbstractD3Scales,
  AbstractDataObjects,
  AbstractD3Labels,
  AbstractEventObjects,
  D3SvgBuilderLuxon,
} from '@bg2app/models/charts';
import { D3GridDayCycle } from '@bg2app/models/charts';
import { Dictionary } from 'app/typings/core/interfaces';
import { DataPoint } from 'app/models/data';

// #region -> (interfaces)

/** */
interface Axes extends AbstractD3Axes {
  /**
   * Defines a dictionnary to hold the informations about time axis.
   */
  time: {
    /**
     * Reference to the d3-axis generator for the time values.
     */
    axis: d3.Axis<Date>;

    /**
     * Reference to the container for the time axis.
     */
    container: d3.Selection<SVGGElement, unknown, HTMLElement, any>;
  };

  /**
   * Defines a dictionnary to hold the informations about value axis.
   */
  value: {
    /**
     * Reference to the d3-axis generator for the value values.
     */
    axis: d3.Axis<d3.AxisDomain>;

    /**
     * Reference to the container for the value axis.
     */
    container: d3.Selection<SVGGElement, unknown, HTMLElement, any>;
  };
}

/** */
interface Scales extends AbstractD3Scales {
  /**
   * Reference to the time scale for the temporal value.
   */
  time: d3.ScaleTime<number, number, never>;

  /**
   * Reference to linear scale for the value value.
   */
  value: d3.ScaleLinear<number, number, never>;
}

/** */
interface DataObjects extends AbstractDataObjects {
  /** */
  value: {
    /** */
    line: {
      /** */
      shape: d3.Line<Dictionary<any>>;

      /** */
      path: d3.Selection<SVGPathElement, unknown, HTMLElement, any>;
    };

    /** */
    circle: d3.Selection<SVGCircleElement, unknown, HTMLElement, any>;
  };
}

/** */
interface Events extends AbstractUserEvents {
  /** */
  weather: {
    /** */
    container: d3.Selection<HTMLDivElement, unknown, HTMLElement, any>;
  };
}

/** */
interface Labels extends AbstractD3Labels {
  /** */
  labels: {
    /** */
    value: d3.Selection<SVGTextElement, unknown, HTMLElement, unknown>;
  };
}

// #endregion

// #region -> (classes)

/** */
export class SingleLineD3ChartFactory extends D3SvgBuilderLuxon<{ points: DataPoint[] }> {
  // #region -> (properties)

  public axes: Axes = {
    container: null,
    time: {
      axis: null,
      container: null,
    },
    value: {
      axis: null,
      container: null,
    },
  };

  public scales: Scales = {
    time: null,
    value: null,
  };

  public data_objects: DataObjects = {
    container: null,
    value: {
      line: {
        shape: d3
          .line<Dictionary<any>>()
          .defined(point => !isNil(this.get_value_from_source(point)))
          .curve(d3.curveLinear),
        path: null,
      },

      circle: null,
    },
  };

  public event_objects: AbstractEventObjects = {
    container: null,
  };

  public user_events: Events = {
    container: null,
    event_bounds: null,

    focus_line_x: null,
    focus_line_y: null,

    weather: {
      container: null,
    },
  };

  public grids: AbstractD3Grid = {
    container: null,

    time: {
      axis: null,
      container: null,
    },

    day_cycle: new D3GridDayCycle(),
  };

  public labels: Labels = {
    container: null,

    labels: {
      value: null,
    },
  };

  //#endregion

  // #region -> (component basics)

  /** */
  protected colors: { [key in 'main']: `#${string}` } = {
    /** */
    main: this.override_config?.color ?? '#000000',
  };

  /** */
  protected LOGGER: ConsoleLoggerService = new ConsoleLoggerService('D3SvgWGJobDataFactory', true);

  /** */
  constructor(
    protected _shared_cursor: D3SharedCursorService,
    protected _appState: AppStateService,
    protected _ngZone: NgZone,
    private override_config: {
      /** */
      path: string;

      /** */
      labels: {
        /** */
        unit: string;

        /** */
        label_short_with_unit: string;

        /** */
        label_full_without_unit: string;
      };

      /** */
      color?: `#${string}`;
    }
  ) {
    super(_shared_cursor, _appState, _ngZone);

    this.margins.top = 5;
    this.margins.right = 15;

    // Apply override config
  }

  /** */
  public destroy(): void {
    super.destroy();
  }

  // #endregion

  // #region -> (override config)

  private get_value_from_source(obj: Dictionary<any>): any {
    return get(obj, this.override_config.path);
  }

  // #endregion

  // #region -> (incoming data)

  /** */
  public get has_data() {
    const data = this.incoming_data?.points;

    if (isArray(data)) {
      return !isEmpty(data ?? []);
    }

    return !isNil(data);
  }

  // #endregion

  // #region -> (update methods)

  /** */
  protected update_axes(): void {
    super.update_axes();

    this.axes.value.axis.scale(this.scales.value).ticks(5);
    this.axes.value.container
      .transition()
      .duration(500)
      .call(this.axes.value.axis)
      .attr('transform', `translate(${this.margins.left}, ${this.margins.top})`);

    this.axes.value.container.selectAll('.tick').select('text').attr('fill', this.colors.main).attr('font-weight', 600);
  }

  // #endregion

  // #region -> (non-abstract methods)

  public resize(): void {
    this.axes.value.axis.scale(this.scales.value);

    this.labels.labels.value.attr('x', -(this.calc_size_of(this.box_sizing.height, ['-top', '-bottom']) / 2));

    super.resize();
  }

  // #endregion

  // #region -> (abstract methods)

  protected create_chart_defs(): void {
    super.create_chart_defs();
  }

  protected create_scales(): void {
    super.create_scales();

    this.scales.value = d3.scaleLinear().rangeRound([this.calc_size_of(this.box_sizing?.height, ['-top', '-bottom']), 0]);
  }

  public create_axes(): void {
    super.create_axes();

    this.axes.value.axis = d3.axisLeft(this.scales.value);
    this.axes.value.container = this.axes.container
      .append('g')
      .attr('class', 'd3-Y-axis d3-Y-axis-value')
      .attr('transform', `translate(${this.calc_size_of(0, ['+left'])}, ${this.calc_size_of(0, ['+top'])})`)
      .call(this.axes.value.axis);

    this.apply_axes_format();
  }

  protected create_labels(): void {
    super.create_labels();

    this.labels.labels.value = this.labels.container
      .append('text')
      .text(this._appState.translate.instant(this.override_config.labels.label_short_with_unit))
      .attr('transform', 'rotate(-90)')
      .attr('font-size', '10px')
      .attr('font-weight', 'bold')
      .attr('fill', this.colors.main)
      .attr('text-anchor', 'middle')
      .attr('x', -(this.calc_size_of(this.box_sizing.height, ['-top', '-bottom']) / 2))
      .attr('y', -25);
  }

  public build_event_objects(): void {
    this.user_events.focus_line_y = this.create_focus_line('y', this.user_events.container);

    this.user_events.weather.container = this.create_html_tooltip(this.svg_parent);

    this.data_objects.value.line.path = this.data_objects.container
      .append('path')
      .style('fill', 'none')
      .style('stroke', this.colors.main)
      .style('stroke-width', '2px');
    this.data_objects.value.circle = this.user_events.container
      .append('circle')
      .attr('r', 4)
      .style('display', 'none')
      .attr('pointer-events', 'none')
      .attr('class', 'd3-focus-circle')
      .attr('fill', this.colors.main);
  }

  /**
   * @public
   * @method
   * @override
   * @description
   *
   *
   * @param is_from_shared_cursor
   */
  public on_mouse_enter(is_from_shared_cursor = false): void {
    super.on_mouse_enter(is_from_shared_cursor);

    if (!this.has_data) {
      return;
    }

    this.user_events.weather.container.style('display', null);
    this.data_objects.value.circle.style('display', null);
  }

  /**
   * @public
   * @method
   * @override
   * @description
   *
   *
   * @param event
   * @param data
   * @param is_from_shared_cursor
   */
  public on_mouse_move(event: MouseEvent | Date, data: { points: DataPoint[] }, is_from_shared_cursor = false): void {
    if (!this.has_data) {
      return null;
    }

    let pointed_position_x: number = null;
    let pointed_position_y: number = null;

    if (is_from_shared_cursor) {
      pointed_position_x = this.scales.time(event as Date);
    } else {
      const source_element = (event as MouseEvent).target as SVGGElement;
      const boundings = source_element.getBoundingClientRect();

      pointed_position_x = (event as MouseEvent).clientX - boundings.left;
      pointed_position_y = (event as MouseEvent).clientY - boundings.top;
    }

    const weather_points = data.points;

    const index = d3.bisectCenter(
      weather_points?.map((v: DataPoint) => v.tz_date),
      this.scales.time.invert(pointed_position_x)
    );

    const closest_point = weather_points?.[index] ?? null;

    if (!is_from_shared_cursor) {
      this._shared_cursor.shared_cursor = {
        date: closest_point?.tz_date ?? null,
        from: this.unique_id,
        event_type: 'mousemove',
      };
    }

    let template = this.create_tooltip_header(closest_point?.date);

    template += `<div class="d3-chart-tooltip-list-item">`;

    template += '<div class="d3-chart-tooltip-series-name">';
    template += `<span class="mdi mdi-minus-thick" style="color: ${this.colors.main}"></span>`;
    template += `${this._appState.translate.instant(this.override_config.labels.label_full_without_unit)}`;
    template += '</div>';

    template += '<div class="d3-chart-tooltip-value">';
    template += `${this.get_value_from_source(closest_point)?.toFixed(1) ?? '?'} ${this._appState.translate.instant(
      this.override_config.labels.unit
    )}`;
    template += '</div>';

    template += '</div>';

    template += '</div>';

    // Update tooltip position
    const is_in_first_half = pointed_position_x < this.user_events.event_bounds.node().width.baseVal.value / 2;
    if (is_in_first_half) {
      this.user_events.weather.container
        .html(template)
        .style('left', `${this.calc_size_of(pointed_position_x + 10, ['+left'])}px`)
        .style('top', `${this.user_events.event_bounds.node().getBBox().height / 2}px`);
    } else {
      this.user_events.weather.container
        .html(template)
        .style('left', `${pointed_position_x + this.margins.left - 1 - this.user_events.weather.container.node().clientWidth - 10}px`)
        .style('top', `${this.user_events.event_bounds.node().getBBox().height / 2}px`);
    }

    // Update the focus_line_x position
    this.user_events.focus_line_y
      .attr('x1', this.scales.time(this.scales.time.invert(pointed_position_x)))
      .attr('x2', this.scales.time(this.scales.time.invert(pointed_position_x)));

    // Update circle positions (depending on date)
    if (isNil(closest_point?.tz_date)) {
      this.data_objects.value.circle.style('display', 'none');
    }

    // Update value circle
    if (isNil(this.get_value_from_source(closest_point))) {
      this.data_objects.value.circle.style('display', 'none');
    } else {
      this.data_objects.value.circle.style('display', null);
      this.data_objects.value.circle.attr(
        'transform',
        `translate(${this.scales.time(closest_point.tz_date.getTime())},${this.scales.value(this.get_value_from_source(closest_point))})`
      );
    }
  }

  public on_mouse_out(is_from_shared_cursor = false): void {
    super.on_mouse_out(is_from_shared_cursor);

    this.user_events.weather.container.style('display', 'none');

    this.data_objects.value.circle.style('display', 'none');
  }

  public append_data(is_after_resize: boolean): void {
    this.scales.time.domain([this.date_range.start, this.date_range.end]);

    if (!this.has_data) {
      this.scales.value.domain([0, 25]);

      this.data_objects.value.line.path.attr('d', '');

      super.append_data(is_after_resize);
      return;
    }

    const points: Dictionary<any>[] = this.incoming_data?.points;

    // Search min value for weight
    const min_weight_value = d3.min(
      points.filter(data_point => !isNil(this.get_value_from_source(data_point))),
      (c: Dictionary<any>) => this.get_value_from_source(c) - 2
    );

    // Search max value for weight
    const max_weight_value = d3.max(
      points.filter(data_point => !isNil(this.get_value_from_source(data_point))),
      (c: Dictionary<any>) => this.get_value_from_source(c) + 2
    );

    // Update Y-Axes ranges
    this.scales.value.domain([min_weight_value, max_weight_value]);

    // Update data of path
    this.data_objects.value.line.shape
      .x(_point => this.scales.time(_point.tz_date))
      .y(_point => this.scales.value(this.get_value_from_source(_point)));
    this.data_objects.value.line.path.attr('d', this.data_objects.value.line.shape(points));

    super.append_data(is_after_resize);
  }

  // #endregion

  // #region -> (external chart events)

  protected on_language_updated(): void {
    super.on_language_updated();

    this.labels?.labels?.value
      ?.text(this._appState.translate.instant(this.override_config.labels.label_short_with_unit))
      ?.attr('x', -(this.calc_size_of(this.box_sizing.height, ['-top', '-bottom']) / 2));
  }

  // #endregion
}

// #endregion
