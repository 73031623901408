<bg2-modal [fullscreen]="fullscreen || false" #modal>
  <bg2-modal-header>
    <div class="header">
      <span class="mdi mdi-pencil mdi-36px"></span>
      <span class="title">
        <ng-container *ngIf="(event$$ | async); let event; defaultTitle">{{ event.type_i18n | translate }}</ng-container>
        <ng-template #defaultTitle>{{ 'VIEWS.MODALS.FORM.Update' | translate }}&nbsp;{{ '#' + (input_event_id$$ | async) }}</ng-template>
      </span>
    </div>

    <div class="action-buttons">
      <span class="mdi mdi-close-box mdi-36px" (click)="close()"></span>
    </div>
  </bg2-modal-header>

  <bg2-modal-content>
    <div class="error" *ngIf="error">{{error | translate}}</div>
    <bg2-event-form-inline
      [disabled]="(ro === '1')"
      [args]="args"
      [event]="event$$ | async"
      (isValid)="setFormValid($event)"
      (isSchema)="setSchema($any($event))"
    ></bg2-event-form-inline>
  </bg2-modal-content>

  <bg2-modal-footer *ngIf="event$$ | async">
    <bg2-button
      *ngIf="(event$$ | async)?.id"
      [type]="'delete'"
      [disabled]="ro === '1'"
      [icon]="'mdi-delete'"
      [message]="'VIEWS.MODALS.FORM.Delete' | translate"
      (btn_click)="deleteEvent()"
    ></bg2-button>

    <bg2-button
      *ngIf="(event.has_changed$$ | async) === false"
      [type]="'cancel'"
      [message]="'VIEWS.MODALS.FORM.Close' | translate"
      (btn_click)="close()"
    ></bg2-button>

    <bg2-button
      *ngIf="(event.has_changed$$ | async)"
      [type]="'cancel'"
      [message]="'VIEWS.MODALS.FORM.Cancel' | translate"
      (btn_click)="close()"
    ></bg2-button>

    <bg2-button
      [type]="'action'"
      [message]="'VIEWS.MODALS.FORM.Save' | translate"
      [disabled]="((form_valid$$ | async) === false) || (loading$$ | async) || ((event?.has_changed$$ | async) === false)"
      [loading]="loading$$ | async"
      (btn_click)="submit()"
    ></bg2-button>
  </bg2-modal-footer>
</bg2-modal>
