<div class="widget form-group">
  <label> {{ schema.label | translate }} </label>

  <div *ngIf="(events_grouped_by_location$$ | async)?.length <= 0">
    {{ 'WIDGETS.EVENT_FORM.SUPERBOX_HARVEST_SELECTOR.No super harvest this year' | translate }}
  </div>

  <div *ngFor="let egroup of events_grouped_by_location$$ | async" class="egroup">
    <ng-container *ngIf="(egroup.entity$$ | async); let entity">
      <div class="group-header">{{ 'ENTITY.ALL.TYPE.location' | translate }}: {{ entity.name }}</div>

      <ul class="collection">
        <li class="collection-item" *ngFor="let event of egroup.events">
          <mat-checkbox id="event_{{ event.event.id }}" [(ngModel)]="event.selected" [disabled]="event.disabled">
            <bg2-event-link [event]="event.event" [from]="entity"></bg2-event-link>
          </mat-checkbox>
        </li>
      </ul>
    </ng-container>
  </div>

  <a
    *ngIf="source_entity$$ | async; let source_entity"
    class="new-event-link"
    [routerLink]="['', { outlets: { 'modal': [ 'new_event', { 'etype': 'superbox_harvest', 'args': ({ 'warehouse': source_entity.id } | json) }] } }]"
    [queryParamsHandling]="'preserve'"
  >
    <span>{{ 'WIDGETS.EVENT_FORM.SUPERBOX_HARVEST_SELECTOR.Declare a new harvest of supers' | translate }}</span>
  </a>
</div>
