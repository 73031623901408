<ng-template #emptyData>
  <bg2-error-helper [error]="error$$ | async" [with_background]="false"></bg2-error-helper>
</ng-template>

<ng-template #loadingData>
  <div *ngFor="let index of (histogram_configuration$$ | async)?.size | range">
    <div class="data-container">
      <bg2-loading-skeleton [theme]="{ height: '100%' }"></bg2-loading-skeleton>
    </div>

    <div class="legend">
      <bg2-loading-skeleton [theme]="{ 'min-height': '20px', 'margin-top': '5px', height: '100%' }"></bg2-loading-skeleton>
    </div>
  </div>
</ng-template>

<div class="bg2-simple-histogram-base">
  <div *ngLet="histogram_data$$ | async as histogram_data" class="values">
    <ng-container *ngIf="(is_loading.data$$ | async) === false; else loadingData">
      <ng-container *ngIf="has_data$$ | async; else emptyData">
        <ng-container *ngIf="histogram_configuration$$ | async; let histogram_configuration">
          <ng-container *ngFor="let data_configuration of histogram_configuration | keyvalue: keepOriginalOrder">
            <div *ngIf="shouldDisplayValue$$(histogram_data?.[data_configuration?.key] ?? 0) | async">
              <div class="data-container">
                <div
                  class="histo-background"
                  [ngStyle]="{ height: ((histogram_data?.[data_configuration?.key] ?? 0) / (max$$ | async)) * 100 + '%' }"
                ></div>
                <div class="histo-value">{{ histogram_data?.[data_configuration?.key] ?? 0 }}</div>
              </div>

              <div class="legend">
                <ng-container [ngSwitch]="histogram_type">
                  <ng-container *ngSwitchCase="'queen-color'">
                    <bg2-picon-queen-color [size_w]="'20px'" [queen_color]="data_configuration?.key"></bg2-picon-queen-color>
                  </ng-container>

                  <ng-container *ngSwitchCase="'hive-status'">
                    <bg2-picon-hive-status [size_w]="'20px'" [hive_status]="data_configuration?.key"></bg2-picon-hive-status>
                  </ng-container>

                  <ng-container *ngSwitchDefault>
                    <img
                      *ngIf="data_configuration?.value?.img; else defaultLegend"
                      [src]="data_configuration?.value?.img"
                      [alt]="data_configuration?.value?.label | translate"
                    />

                    <ng-template #defaultLegend>
                      <span>{{ data_configuration?.value?.label | translate }}</span>
                    </ng-template>
                  </ng-container>
                </ng-container>
              </div>
            </div>
          </ng-container>
        </ng-container>
      </ng-container>
    </ng-container>
  </div>
</div>
