import { ChangeDetectionStrategy, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { ISchema } from 'ngx-schema-form';

import { assign, cloneDeep, isEqual, isNil, merge } from 'lodash-es';

import { marker as i18n } from '@biesbjerg/ngx-translate-extract-marker';

import { BehaviorSubject, map, of, take, switchMap } from 'rxjs';

import { AppStateService } from 'app/core/app-state.service';
import { ENV } from 'app/core/providers/environment.provider';
import { InputCreateUser } from 'app/core/api-swagger/user-v2';
import { ConsoleLoggerService } from 'app/core/console-logger.service';

import { AbstractModalComponent, ModalParams } from 'app/widgets/dialogs-modals/abstract-modal.component';

import { timezone } from 'app/models/misc/timezones';
import { language_to_i18n } from 'app/models/misc/language';

import { IEnvironment } from 'environments/common';
import { catchErrorInDialog, distinctUntilRealChanged, replay } from '@bg2app/tools/rxjs';
import { DialogsService } from 'app/widgets/dialogs-modals';
import { UsersApiService } from 'app/core/api/user/users-api.service';
import { SETINGS_NOTIFICATION_SCHEMA } from '../user-settings/notifications-settings/notifications-settings.component';
import { ZohoSearchSelectOptions } from 'app/widgets/event-form/zoho-search-crm/zoho-search-crm-widget.component';

/** */
interface CreateUserModalParams extends ModalParams {}

/** */
interface CreateUserFormData {
  /** */
  user_profile: {
    /** */
    first_name: string;

    /** */
    last_name: string;

    /** */
    username: string;

    /** */
    email: string;

    /** */
    CRM_id: string;

    /** */
    phone_number: string;

    /** */
    timezone: string;

    /** */
    lang: string;
  };

  /** */
  user_security: {
    /** */
    create_with_password: boolean;

    /** */
    password: string;

    /** */
    password_check: string;
  };

  /** */
  scopes: string[];

  params: {
    notifications: any;
  };
}

@Component({
  selector: 'bg2-new-user-modal',
  templateUrl: './new-user.modal.html',
  styleUrls: ['./new-user.modal.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NewUserModalComponent extends AbstractModalComponent<CreateUserModalParams> implements OnInit, OnDestroy {
  // #region -> (component basics)

  /** */
  private readonly LOGGER = new ConsoleLoggerService('NewUserModalComponent', true);

  /** */
  constructor(
    @Inject(ENV) public readonly env: IEnvironment,
    public appState: AppStateService,
    private readonly _router: Router,
    private readonly apiUser: UsersApiService,
    private readonly _dialogs: DialogsService,
    private readonly _dialogs_service: DialogsService
  ) {
    super();
  }

  // myValidators: {
  //   [path: string]: Validator;
  // } = {
  //   '/user_security/password': function (
  //     value: any,
  //     property: FormProperty,
  //     form: PropertyGroup
  //   ): [
  //     {
  //       [key: string]: any;
  //     }
  //   ] {
  //     const passwordProperty = property.parent.getProperty('password_check');
  //     console.log(passwordProperty);
  //     if (passwordProperty.value !== undefined && property.valid && value !== passwordProperty.value) {
  //       return [{ passwordCheck: { expectedValue: "same as 'password'" } }];
  //     }
  //     // return null;
  //     // console.log("Value :", value, property, form);

  //     return [{}];
  //   },
  // };

  ngOnInit(): void {
    this.input_params$$
      .pipe(
        take(1),
        map(params => params?.args ?? null),
        map(args => {
          if (isNil(args)) {
            return null;
          }

          const parsed_data = JSON.parse(args as any);

          const form_data = merge({}, this.default_form_value, <CreateUserFormData>{
            user_profile: {
              email: parsed_data?.email ?? null,
              CRM_id: parsed_data?.crm_id ?? null,
              username: parsed_data?.username ?? null,
              first_name: parsed_data?.first_name ?? null,
              last_name: parsed_data?.last_name ?? null,
              phone_number: parsed_data?.telephone ?? null,
            },
          });

          return form_data;
        })
      )
      .subscribe({
        next: value => {
          if (isNil(value)) {
            return;
          }

          this._form_model$$.next(value);
        },
      });
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  /** */
  public close(raz = false) {
    if (!isEqual(this._form_model$$.getValue(), this.default_form_value)) {
      this._dialogs
        .confirm(i18n<string>('VIEWS.MODALS.FORM.Abandon the user creation ?'), {
          onTrueMessage: i18n<string>('VIEWS.MODALS.FORM.Abandon'),
          onFalseMessage: i18n<string>('VIEWS.MODALS.FORM.Continue'),
        })
        .subscribe(agreement => {
          if (agreement) {
            super.close(raz);
          }
        });
    } else {
      super.close(raz);
    }
  }

  private force_close() {
    super.close();
  }

  // #endregion

  /** */
  protected handle_event_before_unload(event: BeforeUnloadEvent): void {
    return null;
  }

  // #region -> (schema management)

  /** */
  private readonly _default_schema: ISchema = {
    type: 'object',
    required: ['user_profile', 'user_security'],
    properties: {
      user_profile: {
        type: 'object',
        required: ['first_name', 'last_name', 'username', 'email'],
        title: i18n('ROUTABLE_MODALS.USER_ACCOUNT.UAM_USER_DETAILS.PROFILE.User data'),
        properties: {
          first_name: {
            type: 'string',
            label: i18n('ROUTABLE_MODALS.USER_ACCOUNT.UAM_USER_DETAILS.PROFILE.Given name'),
          },
          last_name: {
            type: 'string',
            label: i18n('ROUTABLE_MODALS.USER_ACCOUNT.UAM_USER_DETAILS.PROFILE.Family name'),
          },
          username: {
            type: 'string',
            label: i18n('ROUTABLE_MODALS.USER_ACCOUNT.UAM_USER_DETAILS.PROFILE.Username'),
          },
          email: {
            type: 'string',
            label: i18n('ROUTABLE_MODALS.USER_ACCOUNT.UAM_USER_DETAILS.PROFILE.Mail'),
            format: 'email',
          },
          CRM_id: {
            type: 'string',
            widget: 'zoho-search-crm',
            label: i18n('WIDGETS.EVENT_FORM.ZOHO_SEARCH.Zoho-Contact CRM ID'),
            options: <ZohoSearchSelectOptions>{
              visible_only_for: 'superadmin',
              zoho_search_config: {
                search_in: 'Contacts',
                criteria_template: [
                  { prop: 'id', condition: 'equals', replace_value: 'SEARCH_TERM' },
                  'or',
                  [
                    { prop: 'First_Name', condition: 'in', replace_value: 'SEARCH_TERM' },
                    'or',
                    { prop: 'First_Name', condition: 'starts_with', replace_value: 'SEARCH_TERM' },
                  ],
                  'or',
                  [
                    { prop: 'Last_Name', condition: 'in', replace_value: 'SEARCH_TERM' },
                    'or',
                    { prop: 'Last_Name', condition: 'starts_with', replace_value: 'SEARCH_TERM' },
                  ],
                ],
              },
            },
          },
          phone_number: {
            type: 'string',
            description: 'Phone number',
            widget: 'ng-mat-tel',
            label: i18n('ROUTABLE_MODALS.USER_ACCOUNT.UAM_USER_DETAILS.PROFILE.Mobile phone'),
            value: '+33 612 345 678',
          },
          timezone: {
            type: 'string',
            label: i18n('ROUTABLE_MODALS.USER_ACCOUNT.UAM_USER_DETAILS.PROFILE.Timezone'),
            widget: 'select',
            oneOf: timezone.map(tz => ({ enum: [tz], label: tz })),
          },
          lang: {
            type: 'string',
            label: i18n('ROUTABLE_MODALS.USER_ACCOUNT.UAM_USER_DETAILS.PROFILE.Language'),
            widget: 'select',
            default: null,
            oneOf: this.env.config.language.available.map(av_lang => ({ enum: [av_lang], label: language_to_i18n[av_lang] ?? av_lang })),
          },
          referent: {
            type: 'object',
            default: null,
            properties: {
              user_id: {
                label: i18n('ROUTABLE_MODALS.USER_ACCOUNT.UAM_USER_DETAILS.PROFILE.Referent'),
                type: 'number',
                widget: 'bg2user-select',
              },
            },
          },
        },
      },
      user_security: {
        type: 'object',
        title: i18n('ROUTABLE_MODALS.USER_ACCOUNT.UAM_USER_DETAILS.SECURITY.Title'),
        required: ['create_with_password', 'password', 'password_check'],
        properties: {
          create_with_password: {
            type: 'boolean',
            default: true,
            widget: 'checkbox',
            readOnly: true,
            label: i18n<string>(
              'ROUTABLE_MODALS.USER_ACCOUNT.UAM_USER_DETAILS.SECURITY.Create with password (if not an email will be sent to the specified mail)'
            ),
          },
          password: {
            type: 'string',
            description: '**********',
            widget: 'ng-mat-password',
            label: i18n('ROUTABLE_MODALS.USER_ACCOUNT.UAM_USER_DETAILS.SECURITY.Password'),
            visibleIf: {
              create_with_password: [true],
            },
          },
          password_check: {
            type: 'string',
            description: '**********',
            widget: 'ng-mat-password',
            label: i18n('ROUTABLE_MODALS.USER_ACCOUNT.UAM_USER_DETAILS.SECURITY.Confirm password'),
            visibleIf: {
              create_with_password: [true],
            },
          },
        },
      },
      scopes: {
        type: 'array',
        title: i18n('ROUTABLE_MODALS.USER_ACCOUNT.UAM_USER_DETAILS.PROFILE.User rights'),
        widget: 'checklist',
        options: {
          visible_only_for: 'superadmin',
          display: 'button',
          null_value: 'no',
        },
        items: {
          type: 'string',
          oneOf: [
            {
              // enum: ['user:write_subusers'],
              enum: ['can_create_users'],
              label: i18n('ROUTABLE_MODALS.USER_ACCOUNT.UAM_USER_DETAILS.PROFILE.Can create users'),
            },
            {
              // enum: ['user:write_self'],
              enum: ['is_public_account'],
              label: i18n('ROUTABLE_MODALS.USER_ACCOUNT.UAM_USER_DETAILS.SECURITY.Public account'),
            },
          ],
        },
      },
      params: {
        type: 'object',
        title: 'VIEWS.USER.COMMON.Parameters',
        properties: {
          notifications: assign({}, SETINGS_NOTIFICATION_SCHEMA, {
            label: '',
          }),
        },
      },
    },
  };

  /** */
  public form_schema$$ = of(this._default_schema).pipe(
    switchMap(schema_form =>
      this.appState.lang$$.pipe(
        map(lang => {
          const schema = cloneDeep(schema_form);

          schema.properties.user_profile.properties.lang.default = lang;

          return schema;
        })
      )
    ),
    switchMap(schema_form =>
      this.form_model$$.pipe(
        map(model => model?.user_security?.create_with_password),
        distinctUntilRealChanged(),
        map(create_with_password => {
          const schema = cloneDeep(schema_form);

          if (!create_with_password) {
            schema.properties.user_security.required = ['create_with_password'];
          } else {
            schema.properties.user_security.required = ['create_with_password', 'password', 'password_check'];
          }

          return schema;
        })
      )
    ),
    replay()
  );

  // #region -> (model management)

  /** */
  private readonly default_form_value: CreateUserFormData = {
    user_profile: {
      first_name: null,
      last_name: null,
      username: null,
      email: null,
      CRM_id: null,
      lang: null,
      phone_number: null,
      timezone: 'Europe/Paris',
    },
    user_security: {
      password: null,
      password_check: null,
      create_with_password: true,
    },
    scopes: [],
    params: {
      notifications: {},
    },
  };

  /** */
  private _form_model$$ = new BehaviorSubject<CreateUserFormData>(this.default_form_value);

  /** */
  public form_model$$ = this._form_model$$.asObservable().pipe();

  /** */
  public onFormChanged(fdata: CreateUserFormData): void {
    this._form_model$$.next(fdata);
  }

  // #endregion

  // #region -> (form validity)

  /** */
  private _form_valid$$ = new BehaviorSubject(false);

  /** */
  public form_valid$$ = this._form_valid$$.asObservable();

  /** */
  public update_form_validity(is_valid: boolean): void {
    this._form_valid$$.next(is_valid);
  }

  // #endregion

  /** */
  public submit() {
    this.form_model$$
      .pipe(
        map(form_data => {
          delete form_data.user_security.password_check;
          delete form_data.user_security.create_with_password;

          let user_data = <InputCreateUser>{};

          // User data
          user_data = assign(user_data, form_data.user_profile);

          // Security
          user_data = assign(user_data, form_data.user_security);

          // Scopes
          const scopes_to_save = [];
          if (!form_data.scopes.includes('is_public_account')) {
            scopes_to_save.push('user:write_self');
          }

          if (form_data.scopes.includes('can_create_users')) {
            scopes_to_save.push('user:write_subusers');
          }

          user_data.scopes = scopes_to_save;

          // Params
          user_data.params = form_data.params;

          return user_data;
        }),
        switchMap(user_data => this.apiUser.create_user$(user_data)),
        catchErrorInDialog(this._dialogs_service),
        take(1)
      )
      .subscribe({
        next: result => {
          if (result.action === 'created') {
            this.force_close();
            this._router.navigate(['', { outlets: { modal: ['user_account', { user_id: result.user.user_id }] } }]);
          }
        },
        error: (error: unknown) => {},
      });
  }
}
