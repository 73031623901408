import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { BehaviorSubject, map, Observable, of, switchMap, take } from 'rxjs';
import { catchErrorInDialog, replay, waitForNotNilValue } from '@bg2app/tools/rxjs';

import { marker as i18n } from '@biesbjerg/ngx-translate-extract-marker';

import { keys, set } from 'lodash-es';

import { GhostService } from 'app/core/ghost';
import { DialogsService } from 'app/widgets/dialogs-modals';

import { User } from 'app/models';
import { DATA_VIEW, data_view_i18n } from 'app/models/config/data-view.enum';
import { Dictionary } from 'app/typings/core/interfaces';

@Component({
  selector: 'bg2-global-settings',
  templateUrl: './global-settings.component.html',
  styleUrls: ['./global-settings.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GlobalSettingsComponent {
  // #region -> (component basics)

  /** */
  constructor(private readonly _ghost_service: GhostService, private readonly _dialogs_service: DialogsService) {}

  // #endregion

  // #region -> (related user)

  /** */
  private _user$$ = new BehaviorSubject<User>(null);

  /** */
  public user$$ = this._user$$.asObservable().pipe(waitForNotNilValue());

  /** */
  @Input()
  public set user(user: User) {
    this._user$$.next(user);
  }

  // #endregion

  // #region -> (ghost management)

  /** */
  public toggle_ghost_entities(): void {
    this._ghost_service.toggleGhost();
  }

  /** */
  public load_ghost_entities$$ = this._ghost_service.load_ghost$$;

  // #endregion

  // #region -> (apiary settings)

  // #region -> (global apiary settings)

  /** */
  public default_weight_chart_type$$ = this.user$$.pipe(
    switchMap(user => user.params__apiary_list_default_chart_type$$),
    replay()
  );

  /** */
  public readonly available_apiary_data_view_type__values = [
    {
      value: DATA_VIEW.AUTOMATIC,
      label: data_view_i18n[DATA_VIEW.AUTOMATIC],
    },
    {
      value: DATA_VIEW.WEIGHT_LINEAR,
      label: data_view_i18n[DATA_VIEW.WEIGHT_LINEAR],
    },
    {
      value: DATA_VIEW.WEIGHT_DIFFERENTIAL,
      label: data_view_i18n[DATA_VIEW.WEIGHT_DIFFERENTIAL],
    },
    {
      value: DATA_VIEW.COUNTER,
      label: data_view_i18n[DATA_VIEW.COUNTER],
    },
    {
      value: DATA_VIEW.TEMPERATURE_INTERNAL_LINEAR,
      label: data_view_i18n[DATA_VIEW.TEMPERATURE_INTERNAL_LINEAR],
    },
  ];

  /** */
  public default_date_range__compact_apiary$$ = this.user$$.pipe(
    switchMap(user => user.params__apiary_list_default_date_range$$),
    replay()
  );

  /** */
  public readonly default_date_range__compact_apiary__values = [
    {
      value: 'weekly',
      label: i18n<string>('ALL.DATE.RANGES.7 days'),
    },
    {
      value: 'monthly',
      label: i18n<string>('ALL.DATE.RANGES.30 days'),
    },
  ];

  /** */
  public update_user_param_setting(value: any, path: string) {
    this.user$$
      .pipe(
        take(1),
        map(user => {
          let partial_parameters: Dictionary<any> = {};
          partial_parameters = set(partial_parameters, path, value);

          user.setPartialParams(partial_parameters);

          return user;
        }),
        switchMap(user => user.save(['params'])),
        catchErrorInDialog(this._dialogs_service)
      )
      .subscribe({
        next: user => {},
        error: (error: unknown) => {},
        complete: () => {},
      });
  }

  // #endregion
}
