import { Component, Input, ChangeDetectionStrategy } from '@angular/core';
import { Dictionary } from 'app/typings/core/interfaces';
import { isNil } from 'lodash-es';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs';

export interface HistogramItemConfig {
  key: string;    // key in input data
  label?: string;  // i18n label
  img?: string;
}

@Component({
  selector: 'simple-histogram',
  templateUrl: './simple-histogram.component.html',
  styleUrls: ['./simple-histogram.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SimpleHistogramComponent {

  @Input()
  public items_config: HistogramItemConfig[];

  private _data$: BehaviorSubject<Dictionary<number>> = new BehaviorSubject(null);
  public data$$: Observable<{ data: Dictionary<number>; maximum: number }> = this._data$.asObservable().pipe(
    map((data: Dictionary<number>) => {
      let maximum = 0;

      if (!isNil(data)) {
        this.items_config.forEach(
          (histogram_config: HistogramItemConfig) => {
            if (data[histogram_config.key]) {
              maximum = Math.max(maximum, data[histogram_config.key]);
            }
          }
        );
      }

      return { data, maximum };
    })
  );

  @Input()
  public set data(data: Dictionary<number>) {
    this._data$.next(data);
  }

  @Input()
  public histogram_height: string = null; // If no specified height, is compact version

  @Input()
  public histogram_color = 'lighten(bg2-colors-light.$color-fg-primary, 25%)';

}
