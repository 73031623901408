import { Component, ChangeDetectionStrategy } from '@angular/core';

import { ISchema } from 'ngx-schema-form';
import { marker as i18n } from '@biesbjerg/ngx-translate-extract-marker';

import { distinctUntilRealChanged, replay } from '@bg2app/tools/rxjs';
import { Observable, forkJoin, map, switchMap, take, tap, BehaviorSubject, of } from 'rxjs';

import { ConsoleLoggerService } from 'app/core/console-logger.service';

import { AbstractDialogComponent } from 'app/widgets/dialogs-modals/abstract-dialog.component';

import { DRDevice, SimpleSetterGetter } from 'app/models';
import { DEVICE_BATTERY_TYPE } from 'app/models/devices';
import { DevicesDialogParams } from '../../../devices-dialog-params';

interface DeviceBatteryChangeTypeDialogParams extends DevicesDialogParams {}

/** */
interface FormModel {
  /** */
  date: string;

  /** */
  battery_type: string;

  /** */
  comment: string;
}

@Component({
  selector: 'bg2-device-battery-change-type-dialog',
  templateUrl: './device-battery-change-type-dialog.component.html',
  styleUrls: ['./device-battery-change-type-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DeviceBatteryChangeTypeDialogComponent extends AbstractDialogComponent<DeviceBatteryChangeTypeDialogParams, any> {
  // #region -> (dialog basics)

  /** */
  private LOGGER = new ConsoleLoggerService('DevicesBulkBatteryChangeDialogComponent', false);

  /** */
  constructor() {
    super();
  }

  public close(value: boolean): void {
    this.complete(value);
  }

  // #endregion

  // #region -> (loadings)

  /** */
  public is_saving = new SimpleSetterGetter(false);

  // #endregion

  public devices$$: Observable<DRDevice[]> = this.input_params$$.pipe(
    map(params => params.devices),
    replay()
  );

  // #region -> (override selected devices)

  /** */
  private _selected_device_imeis$$ = new BehaviorSubject<number[]>([]);

  /** */
  public selected_device_imeis$$ = this.devices$$.pipe(
    map(devices => devices.map(device => device.imei)),
    take(1),
    tap(selected_imeis => (this.selected_device_imeis = selected_imeis)),
    switchMap(() => this._selected_device_imeis$$),
    distinctUntilRealChanged(),
    replay()
  );

  /** */
  public set selected_device_imeis(selected_device_imeis: number[]) {
    this._selected_device_imeis$$.next(selected_device_imeis);
  }

  // #endregion

  // #region -> (schema management)

  /** */
  public readonly schema: ISchema = {
    type: 'object',
    required: ['date', 'battery_type'],
    properties: {
      date: {
        type: 'string',
        widget: 'date-time',
        default: new Date().toISOString(),
        label: i18n<string>('VIEWS.DEVICES.DIALOGS_AND_MODALS.DIALOGS.DEVICE_BULK_BATTERY_CHANGE.Date of battery change'),
        options: {
          pickerType: 'both',
        },
      },
      battery_type: {
        type: 'string',
        default: null,
        widget: 'ng-mat-select',
        label: i18n<string>('DEVICE.ALL.BATTERY.Battery type'),
        options: {
          with_reset: false,
        },
        oneOf: Object.keys(DEVICE_BATTERY_TYPE).map((key: keyof typeof DEVICE_BATTERY_TYPE) => ({
          enum: [key],
          label: DEVICE_BATTERY_TYPE[key],
        })),
      },
      comment: {
        type: 'string',
        widget: 'ng-mat-textarea',
        label: i18n<string>('ALL.COMMON.Comment'),
      },
    },
  };

  /** */
  public form_model = new SimpleSetterGetter<FormModel>({ date: null, battery_type: null, comment: null });

  /** */
  public is_valid = new SimpleSetterGetter(false);

  public submit(): void {
    this.is_saving.value = true;

    this.form_model.value$$
      .pipe(
        take(1),
        switchMap(form_model =>
          this.devices$$.pipe(
            take(1),
            switchMap(devices => {
              console.log(devices);

              return of(null);

              // const create_battery_change_for_devices$$ = devices?.map(device =>
              //   device.addBatCh(new Date(form_model.date), form_model?.comment ?? undefined)
              // );

              // return forkJoin(create_battery_change_for_devices$$);
            })
          )
        )
      )
      .subscribe({
        next: () => {},
        complete: () => {
          this.is_saving.value = false;
          this.close(true);
        },
      });
  }

  // #endregion

  public asType(value: any, type: 'device'): DRDevice;
  public asType(value: any, type: 'device'): any {
    if (value instanceof DRDevice) {
      return value;
    }

    return null;
  }
}
