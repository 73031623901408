import { combineLatest, concatMap, forkJoin, map, Observable, of, switchMap } from 'rxjs';

import { cloneDeep, isNil } from 'lodash-es';

import { differenceInHours, differenceInYears } from 'date-fns';

import { DeviceApi } from 'app/core';
import { DeviceQueryParams } from 'app/core/api/device/device-api-service';

import { parseDate, startOfTomorrowLuxon } from 'app/misc/tools';
import { compute_868_state, compute_bat_state } from './_functions';
import { DeviceInterface, DRDevice, DeviceStatus, DeviceStatus868, DeviceStatusBat, DeviceLastMeasurements } from './DRDevice';
import { replay } from '@bg2app/tools/rxjs';
import { DeviceCommunicationTechnology } from './enumerators/device-communication-technology.enum';
import { AnyOfDeviceJobData, DeviceRSSIData, WGSecondaryData } from '../data';
import { BatterySparklineData, DeviceFullConfiguration } from './interfaces';
import {
  DEVICE_BATTERY_TYPE,
  DEVICE_SIMPLIFIED_BATTERY_STATE,
  DEVICE_SIMPLIFIED_BATTERY_STATE_REASON,
  DeviceSimplifiedBatteryState,
} from './enumerators';

export class WGDevice extends DRDevice {
  // #region -> (model basics)

  constructor(protected deviceApi: DeviceApi, params?: DeviceQueryParams) {
    super(deviceApi, params);
  }

  // #endregion

  // #region -> last data

  /** */
  public last_battery_voltage_calibrated$$ = this.last_battery_voltage$$.pipe(
    switchMap(last_battery_voltage => {
      if (isNil(last_battery_voltage)) {
        return of(null);
      }

      return this.last_temperature$$.pipe(
        map(last_temperature => {
          let temperature__last = last_temperature?.value?.last;

          if (!isNil(temperature__last)) {
            if (temperature__last < -15 || temperature__last > 60) {
              temperature__last = DRDevice.DEFAULT_TEMPERATURE_IF_INVALID;
            }
          }

          return last_battery_voltage - 0.01 * ((temperature__last ?? 20) - 20);
        })
      );
    }),
    replay()
  );

  // #endregion

  // #endregion

  // #region -> battery state

  /** */
  protected fix_last_battery_voltage(last_temperature: number): number {
    if (!isNil(last_temperature)) {
      if (last_temperature < -15 || last_temperature > 60) {
        last_temperature = DRDevice.DEFAULT_TEMPERATURE_IF_INVALID;
      }
    }

    return last_temperature;
  }

  /** */
  protected fix_sparkline_timeseries(timeseries: any[]): any[] {
    return timeseries.map((d: any) => {
      if (!isNil(d.temperature)) {
        if (d.temperature < -15 || d.temperature > 60) {
          d.temperature = DRDevice.DEFAULT_TEMPERATURE_IF_INVALID;
        }
      }

      return d;
    });
  }

  /** */
  protected compute_battery_type$$(): Observable<DEVICE_BATTERY_TYPE> {
    return this.hwv$$.pipe(
      map(hardware_version => {
        if (hardware_version < 60) {
          return DEVICE_BATTERY_TYPE.W1;
        }

        return DEVICE_BATTERY_TYPE.P1;
      })
    );
  }

  /** */
  protected get_battery_noload_voltage(last_measurements: DeviceLastMeasurements): number {
    return last_measurements?.sensor_message?.fields?.sensor_vbat?.last;
  }

  /** */
  protected get_battery_com_voltage(last_measurements: DeviceLastMeasurements): number {
    return null;
  }

  // vbatrect = vbatraw - 7 * (tmpT - 20)

  /**
   * Device typical voltage range
   */
  public get_battery_std_voltage_range$$(): Observable<[number, number]> {
    return this.generation$$.pipe(
      map<number, [number, number]>(gen => {
        if (gen <= 1) {
          return [2.7, 3.7];
        }
        return [2.7, 3.1];
      }),
      replay()
    );
  }

  protected get_battery_critical_vbat$$(): Observable<number> {
    return this.generation$$.pipe(
      map(gen => {
        if (gen <= 1) {
          return 2.8;
        }

        return 2.7;
      }),
      replay()
    );
  }

  /** */
  public get_battery_simplified_state$$(): Observable<DeviceSimplifiedBatteryState> {
    return this._get_default_battery_simplified_state$$.pipe(
      switchMap(state => {
        if (!isNil(state)) {
          return of(state);
        }

        return combineLatest({
          hwv: this.hwv$$,
          last_power_on: this.last_power_on$$,
          last_battery_change: this.last_battery_change$$,
        }).pipe(
          map(({ hwv, last_battery_change, last_power_on }) => {
            const last_battery_change_years = Math.abs(differenceInYears(last_battery_change?.time ?? last_power_on, new Date()));

            if (hwv < 60 && last_battery_change_years >= 1) {
              return {
                state: DEVICE_SIMPLIFIED_BATTERY_STATE.NOT_OK,
                reason: DEVICE_SIMPLIFIED_BATTERY_STATE_REASON.LAST_BATTERY_OLDER_THAN_1_YEAR,
              };
            }

            if (hwv >= 60 && last_battery_change_years >= 2) {
              return {
                state: DEVICE_SIMPLIFIED_BATTERY_STATE.NOT_OK,
                reason: DEVICE_SIMPLIFIED_BATTERY_STATE_REASON.LAST_BATTERY_OLDER_THAN_2_YEARS,
              };
            }

            return {
              state: DEVICE_SIMPLIFIED_BATTERY_STATE.OK,
              reason: null,
            };
          })
        );
      })
    );
  }

  // #endregion

  // #region -> (communication technology)

  /** */
  protected get_com_technology$$(): Observable<DeviceCommunicationTechnology[]> {
    return of(null);
  }

  // #endregion

  // #region -> (device timeseries)

  /** */
  public fetch_job_data$(start: Date = undefined, end: Date = undefined, step: string = undefined) {
    return super
      .requestTimeseries(['weight_total', 'weight_1', 'weight_2'], start, end, step)
      .pipe(map(response => <AnyOfDeviceJobData>{ points: response?.timeseries?.data ?? [] }));
  }

  /** */
  public fetch_secondary_data$(start: Date = undefined, end: Date = undefined, step: string = undefined): Observable<WGSecondaryData> {
    return super.requestTimeseries(['temperature', 'humidity', 'pressure'], start, end, step).pipe(
      map(response => {
        // TODO: Override default return type of `requestTimeseries`
        const data: WGSecondaryData = {
          points: <any>response?.timeseries?.data ?? [],
        };

        return data;
      })
    );
  }

  /** */
  public fetch_rssi$(start?: Date, end?: Date, step?: string): Observable<DeviceRSSIData> {
    return super.requestTimeseries(['rssi_868'], start, end, step).pipe(
      map(
        response =>
          <DeviceRSSIData>{
            points: response?.timeseries?.data ?? [],
          }
      )
    );
  }

  // #endregion

  is_gateway = false;
  type = DeviceInterface.TypeEnum.WG;

  get last_sensor_message() {
    const msg = this.last_measurements?.sensor_message;
    if (msg) {
      msg.time = parseDate(msg.time);
    }
    return msg;
  }

  /**
   * Gets generation of the device.
   */
  public get generation(): number {
    if (this.hwv < 60) {
      return 1;
    }

    if (this.hwv >= 60) {
      return 2;
    }
  }

  /**
   * Gets generation of the device.
   *
   * @returns Device generation
   */
  protected getGeneration(swv: number, hwv: number, gateway_type: string): number {
    if (hwv < 60) {
      return 1;
    }
    return 2;
  }

  //  Status
  get last_weight() {
    const meas = this.last_measurements?.weight;
    if (meas) {
      meas.time = parseDate(meas.time);
    }
    return meas;
  }

  public get setup_event_name(): string {
    return `devices_${this.type}_install`;
  }

  // #region -> (battery status)

  public get status_bat(): DeviceStatusBat {
    const status = super.status_bat;

    if (isNil(status)) {
      return status;
    }

    // 0 si gen 1 et pas de msg depuis 6 mois
    if (this.generation === 1 && !isNil(this.last_contact_in_days) && this.last_contact_in_days >= 6 * 31) {
      status.value = 0;
    }

    status.state = compute_bat_state(status);
    return status;
  }

  /**
   * Get the status of the battery for this device (estimated level, outdated?).
   *
   * @returns Returns current battery status.
   */
  // public status_bat$$: Observable<DeviceStatusBat> = this.bat$$.pipe(
  //   map(() => this.status_bat),
  //   distinctUntilRealChanged(),
  //   replay()
  // );

  // public status_bat_observation_state$$: Observable<DeviceObservationState> = this.status_bat$$.pipe(
  //   map(status_bat => {
  //     if (isNil(status_bat)) {
  //       return null;
  //     }

  //     return status_bat.state;
  //   }),
  //   map(state_of_battery => {
  //     let observation_state: DeviceObservationState = 'ok';

  //     if (state_of_battery === 'bat_full' || state_of_battery === 'bat_half_full' || state_of_battery === 'bat_half') {
  //       observation_state = 'ok';
  //     }

  //     if (state_of_battery === 'bat_half_empty') {
  //       observation_state = 'need_check';
  //     }

  //     if (state_of_battery === 'bat_empty' || state_of_battery === 'bat_unknown') {
  //       observation_state = 'have_issue';
  //     }

  //     return observation_state;
  //   }),
  //   distinctUntilRealChanged()
  // );

  // public observation_state$$: Observable<DeviceObservationState> = robustCombineLatest([
  //   this.status_868_observation_state$$,
  //   this.status_bat_observation_state$$,
  //   this.status_gps_observation_state$$,
  //   this.status_gprs_observation_state$$,
  // ]).pipe(
  //   map(states => {
  //     const array_of_statuses = values(states);
  //     const ordered_statuses = orderBy(array_of_statuses, ['have_issue', 'need_check', 'ok', undefined, null] as DeviceObservationState[]);

  //     return ordered_statuses?.[0];
  //   })
  // );

  // public observation_state_i18n$$ = this.observation_state$$.pipe(
  //   map(state => device_global_status_i18n[state]),
  //   distinctUntilRealChanged()
  // );

  // #endregion

  // #region -> (s868 status)

  public get status_868(): DeviceStatus868 {
    const message = this.last_sensor_message;

    if (isNil(message)) {
      return null;
    }

    let outdated = false;
    const timestamp = message.time;
    const value = message.fields.rssi.last;

    if (differenceInHours(new Date(), timestamp) > 49) {
      outdated = true;
    }

    const prebuilt_status: DeviceStatus = { timestamp, value, outdated };
    return { state: compute_868_state(prebuilt_status), ...prebuilt_status };
  }

  // #endregion

  // Configuration

  public fetchConfigurationSchema$(): Observable<any> {
    const conf_schema = of({
      type: 'object',
      title: 'Full WG configuration',
      properties: {
        server: {
          type: 'object',
          properties: {
            weight_a_factor: {
              type: 'number',
              label: 'weight_a_factor',
              format: 'float',
              description: 'Coefficient a (both sensors, or sensor 1)',
            },
            weight_b_offset: {
              type: 'number',
              label: 'weight_b_offset',
              format: 'float',
              description: 'Offset b (both sensors, or sensor 1)',
            },
            activate_sensor_2_calibration: {
              type: 'boolean',
              description_on: 'Different calibration for sensor 2',
            },
            weight_2_a_factor: {
              type: 'number',
              label: 'weight_2_a_factor',
              format: 'float',
              description: 'Coefficient a_2 (sensor 2)',
              visibleIf: {
                activate_sensor_2_calibration: [true],
              },
            },
            weight_2_b_offset: {
              type: 'number',
              label: 'weight_2_b_offset',
              format: 'float',
              description: 'Offset b_2 (sensor 2)',
              visibleIf: {
                activate_sensor_2_calibration: [true],
              },
            },
            activate_temp_calibration: {
              type: 'boolean',
              description_on: 'Add temperature calibration',
            },
            weight_calib_temp: {
              type: 'number',
              label: 'weight_1_calib_temp',
              format: 'float',
              description: 'Temperature during measure used to compute a and b (°C)',
              visibleIf: {
                activate_temp_calibration: [true],
              },
            },
            weight_1_alpha_temp_factor: {
              type: 'number',
              label: 'weight_1_alpha_temp_factor',
              format: 'float',
              description: 'α1: temperature correction factor for sensor 1',
              visibleIf: {
                activate_temp_calibration: [true],
              },
            },
            weight_2_alpha_temp_factor: {
              type: 'number',
              label: 'weight_2_alpha_temp_factor',
              format: 'float',
              description: 'α2: temperature correction factor for sensor 2',
              visibleIf: {
                activate_temp_calibration: [true],
              },
            },
          },
        },
      },
    });
    return conf_schema;
  }

  public requestConfiguration(): Observable<DeviceFullConfiguration> {
    return this.deviceApi.fetch_device_full_conf$(this.imei).pipe(
      map((conf: any) => {
        conf = cloneDeep(conf);
        if (conf.server) {
          conf.server.weight_a_factor = conf.server.weight_1_a_factor;
          conf.server.weight_b_offset = conf.server.weight_1_b_offset;
          if (
            conf.server.weight_1_a_factor !== conf.server.weight_2_a_factor ||
            conf.server.weight_1_b_offset !== conf.server.weight_2_b_offset
          ) {
            conf.server.activate_sensor_2_calibration = true;
          }
          delete conf.server.weight_1_a_factor;
          delete conf.server.weight_1_b_offset;
          // temp conf
          conf.server.weight_calib_temp = conf.server.weight_1_calib_temp;
          if (conf.server.weight_1_alpha_temp_factor || conf.server.weight_2_alpha_temp_factor) {
            conf.server.activate_temp_calibration = true;
          }
        }
        return conf;
      })
    );
  }

  public setConfiguration(conf: DeviceFullConfiguration): Observable<any> {
    conf = cloneDeep(conf);
    if (conf.server) {
      conf.server.weight_1_a_factor = conf.server.weight_a_factor;
      conf.server.weight_1_b_offset = conf.server.weight_b_offset;
      if (!conf.server.activate_sensor_2_calibration) {
        conf.server.weight_2_a_factor = conf.server.weight_a_factor;
        conf.server.weight_2_b_offset = conf.server.weight_b_offset;
      }
      delete conf.server.weight_a_factor;
      delete conf.server.weight_b_offset;
      // Temp calib
      conf.server.weight_1_calib_temp = conf.server.weight_calib_temp;
      conf.server.weight_2_calib_temp = conf.server.weight_calib_temp;
      delete conf.server.weight_calib_temp;
      delete conf.server.activate_sensor_2_calibration;
      delete conf.server.activate_temp_calibration;
    }
    return this.deviceApi.update_device_full_conf$(this.imei, conf);
  }

  /** Request simplified configuration
   */
  public requestSimplifiedConfigurationSchema(): Observable<any> {
    return of(null);
  }
}
