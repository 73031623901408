<div class="bg2-ng-mat-file-component">
  <div class="ng-mat-file-form-field">
    <mat-form-field [appearance]="'outline'" [formGroup]="form_group">
      <mat-label>
        {{ (schema?.label | translate) ?? ('WIDGETS.EVENT_FORM.NG_MAT_FILE.Upload file' | translate) }}
      </mat-label>

      <ngx-mat-file-input
        #matFileInput
        [multiple]="false"
        [accept]="options?.accept"
        [formControlName]="'file_input'"
        [required]="schema?.isRequired || schema?.required"
        (change)="when_image_changed($event)"
      ></ngx-mat-file-input>

      <mat-icon matSuffix>folder</mat-icon>

      <mat-hint>{{ 'WIDGETS.EVENT_FORM.NG_MAT_FILE.Beta feature; some devices may not be compatible' | translate }}</mat-hint>

      <mat-error *ngIf="form_group.get('file').hasError('required')">
        {{ 'WIDGETS.EVENT_FORM.NG_MAT_FILE.ERROR.You must select a file' | translate }}
      </mat-error>

      <mat-error *ngIf="form_group.get('file').hasError('maxContentSize')">
        {{
          'WIDGETS.EVENT_FORM.NG_MAT_FILE.ERROR.The total size must not exceed [limit] (current)'
            | translate
              : {
                  limit: form_group.get('file')?.getError('maxContentSize').maxSize | byteFormat,
                  current: form_group.get('file')?.getError('maxContentSize').actualSize | byteFormat
                }
        }}
      </mat-error>
    </mat-form-field>

    <bg2-button
      *ngIf="has_value$$ | async"
      [type]="'delete'"
      [icon]="'mdi-delete'"
      [options]="{ is_thin: true }"
      (btn_click)="delete_file()"
    ></bg2-button>
  </div>

  <ng-container *ngIf="has_cropper_limits">
    <bg2-ui-message [type]="'info'" [title]="'WIDGETS.EVENT_FORM.NG_MAT_FILE.File recommandations' | translate">
      <div message>
        <div *ngIf="has_width_limit || has_height_limit">
          <span>
            {{
              'WIDGETS.EVENT_FORM.NG_MAT_FILE.The recommanded size is [[width] x [height]] (pixels)'
                | translate
                  : {
                      width: options?.cropper_options?.width,
                      height: options?.cropper_options?.height
                    }
            }}
          </span>
        </div>

        <div *ngIf="has_aspect_ratio_limit">
          <span>
            {{
              'WIDGETS.EVENT_FORM.NG_MAT_FILE.The required aspect-ratio is [aspectRatio] (orientation : landscape)'
                | translate
                  : {
                      aspectRatio: '24:5'
                    }
            }}
          </span>
        </div>
      </div>
    </bg2-ui-message>
  </ng-container>
</div>
