import { ChangeDetectionStrategy, Component } from '@angular/core';

import { isNil } from 'lodash-es';
import { marker as i18n } from '@biesbjerg/ngx-translate-extract-marker';

import { catchError, map, Observable, of, switchMap, tap } from 'rxjs';

import { DeviceApi } from 'app/core';
import { DialogsService } from 'app/widgets/dialogs-modals';
import { AppStateService } from 'app/core/app-state.service';

import { DeviceModalAbstractCategoryComponent } from '../device-modal-abstract-details-or-summary.component';

import { SimpleSetterGetter } from 'app/models';
import { AnyOfDeviceJobData, AnyOfDeviceSecondaryData, WindDataPoint } from 'app/models/data';

import { DateRangeManager } from 'app/misc/tools/dates/date-range-manager';
import { distinctUntilRealChanged, replay, switchTap, waitForNotNilValue } from '@bg2app/tools/rxjs';
import { startOfTomorrowLuxon } from 'app/misc/tools/dates';

@Component({
  selector: 'bg2-device-modal-data',
  templateUrl: './device-modal-data.component.html',
  styleUrls: ['./device-modal-data.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DeviceModalDataComponent extends DeviceModalAbstractCategoryComponent {
  // #region -> (component basics)

  /** */
  public date_range_manager: DateRangeManager = new DateRangeManager();

  constructor(
    protected readonly _deviceApi: DeviceApi,
    protected readonly _dialogs: DialogsService,
    protected readonly _appStateService: AppStateService
  ) {
    super(_appStateService, _dialogs, _deviceApi);

    this.device_geoposition$$
      .pipe(
        map(geoposition => geoposition?.timezone),
        distinctUntilRealChanged()
      )
      .subscribe({
        next: timezone => {
          let end_date = startOfTomorrowLuxon(timezone);
          let start_date = end_date.minus({ day: 7 });

          this.date_range_manager.initialize([start_date, end_date], timezone);
        },
      });
  }

  // #endregion

  // #region -> (loadings)

  /** */
  protected is_loading_job_data = new SimpleSetterGetter(true);

  /** */
  protected is_loading_secondary_data = new SimpleSetterGetter(true);

  // #endregion

  // #region -> (device basics)

  /** */
  public device_acl$$ = this.device$$.pipe(
    waitForNotNilValue(),
    switchTap(device =>
      device.user_acl.throw__if_cannot$$('read_devices', i18n<string>('ALL.ACE.READ_DEVICES.WHAT.view the device'), 'exploitation')
    ),
    switchTap(device =>
      device.user_acl.throw__if_cannot$$(
        'read_devices_data',
        i18n<string>('ALL.ACE.READ_DEVICES_DATA.WHAT.view the data history of the device'),
        'exploitation'
      )
    ),
    map(() => null),
    catchError((error: unknown) => of(error)),
    replay()
  );

  /** */
  public device_geoposition$$ = this.device$$.pipe(switchMap(device => device.geoposition_robust$$));

  // #endregion

  // #region -> (device data)

  /** */
  public device_job_data$$ = this.date_range_manager.range$$.pipe(
    tap(() => (this.is_loading_job_data.value = true)),
    switchMap(date_range =>
      this.device$$.pipe(
        switchMap(device => {
          if (isNil(device)) {
            return of<AnyOfDeviceJobData>({ points: [] });
          }

          const start = date_range.start;
          const end = date_range.end.plus({ minutes: 30 });

          return device.fetch_job_data$(start.toJSDate(), end.toJSDate());
        })
      )
    ),
    tap(() => (this.is_loading_job_data.value = false)),
    replay()
  );

  /** */
  public wind_data$$: Observable<WindDataPoint[]> = this.device_job_data$$.pipe(
    map(weather_data =>
      (weather_data?.points || [])?.map(datum => {
        const wind_data: WindDataPoint = {} as any;

        wind_data.date = datum?.date;
        wind_data.tz_date = datum?.tz_date;
        wind_data.anemo_speed = datum?.anemo_speed;
        wind_data.anemo_heading = datum?.anemo_heading;

        return wind_data;
      })
    ),
    replay()
  );

  /** */
  public secondary_data$$: Observable<AnyOfDeviceSecondaryData> = this.date_range_manager.range$$.pipe(
    tap(() => (this.is_loading_secondary_data.value = true)),
    switchMap(date_range =>
      this.device$$.pipe(
        switchMap(device => {
          if (isNil(device)) {
            return of<AnyOfDeviceJobData>({ points: [] });
          }

          const start = date_range.start;
          const end = date_range.end.plus({ minutes: 30 });

          return device.fetch_secondary_data$(start.toJSDate(), end.toJSDate());
        })
      )
    ),
    tap(() => (this.is_loading_secondary_data.value = false)),
    replay()
  );

  // #endregion
}
