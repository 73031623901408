import { ChangeDetectionStrategy, Component } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';

import { distinctUntilRealChanged, replay, waitForNotNilValue } from '@bg2app/tools/rxjs';
import { BehaviorSubject, combineLatest, distinctUntilChanged, map, switchMap, withLatestFrom } from 'rxjs';

import { AppStateService } from 'app/core/app-state.service';
import { UsersApiService } from 'app/core/api/user/users-api.service';

import { AbstractModalComponent } from 'app/widgets/dialogs-modals';
import { ModalParams } from 'app/widgets/dialogs-modals/abstract-modal.component';

export interface UserSettingsParams extends ModalParams {
  user_id: string;
}

enum SettingsCategory {
  UNSET,
  DEBUG,
  APP_SETTINGS,
  NOTIFICATIONS,
  WAREHOUSE,
  ABOUT,
}

@Component({
  selector: 'bg2-user-settings',
  templateUrl: './user-settings.modal.html',
  styleUrls: ['./user-settings.modal.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class UserSettingsModal extends AbstractModalComponent<UserSettingsParams> {
  public SETTINGS_CATEGORY = SettingsCategory;

  public is_mobile_mode$$ = this.breakpointObserver.observe([Breakpoints.Small, Breakpoints.XSmall]).pipe(
    map(results => results.matches),
    replay()
  );

  private _current_category$$ = new BehaviorSubject<SettingsCategory>(null);
  public current_category$$ = this._current_category$$.asObservable().pipe(
    withLatestFrom(this.is_mobile_mode$$),
    map(([category, is_mobile]) => (is_mobile ? category ?? SettingsCategory.UNSET : category ?? SettingsCategory.APP_SETTINGS)),
    distinctUntilChanged(),
    replay()
  );

  public set current_category(category: SettingsCategory) {
    this._current_category$$.next(category);
  }

  /**
   * Hiddens the menu only on mobile and if the user has selected a category.
   */
  public is_menu_hidden$$ = combineLatest([this.is_mobile_mode$$, this.current_category$$]).pipe(
    map(([is_mobile, category]) => {
      if (is_mobile) {
        return category !== SettingsCategory.UNSET;
      }

      return false;
    }),
    replay()
  );

  /**
   * Hiddens the settings only on mobile and if the user has not selected a category.
   */
  public is_settings_hidden$$ = combineLatest([this.is_mobile_mode$$, this.current_category$$]).pipe(
    map(([is_mobile, category]) => {
      if (is_mobile) {
        return category === SettingsCategory.UNSET;
      }

      return false;
    }),
    replay()
  );

  // #region -> (component basics)

  constructor(
    public readonly appState: AppStateService,
    private readonly _users_api: UsersApiService,
    private breakpointObserver: BreakpointObserver
  ) {
    super();
  }

  /** */
  protected handle_event_before_unload(event: BeforeUnloadEvent): void {
    return null;
  }

  // #endregion

  // #region -> (current user)

  /** */
  private user_id$$ = this.input_params$$.pipe(
    map(input_params => input_params.user_id),
    map(user_id => parseInt(user_id)),
    distinctUntilRealChanged()
  );

  /** */
  public user$$ = this.user_id$$.pipe(
    waitForNotNilValue(),
    switchMap(user_id => this._users_api.fetch_user$(user_id)),
    replay()
  );

  // #endregion
}
