import { map, Observable } from 'rxjs';

import { marker as i18n } from '@biesbjerg/ngx-translate-extract-marker';

import { ErrorHelperData } from 'app/widgets/widgets-reusables/errors/error-helper/error-helper.component';

import { EntityUserAclContext } from '../../00_abstract';
import { LocationEntityUserACL, location_user_ace_to_i18n } from '../../03_location';

/** */
export class ApiaryEntityUserACL extends LocationEntityUserACL {
  /**
   * Check if the current user have the minimal read access.
   *
   * @returns An observable with the error model else null.
   *
   * @public
   * @observable
   */
  public check_only__read_minimal$$ = (context: EntityUserAclContext): Observable<ErrorHelperData | null> =>
    this.only$$('read').pipe(
      map(can_only_read_minimal => {
        if (can_only_read_minimal) {
          return new ErrorHelperData([
            {
              type: 'image_svg',
              url: '/assets/img/pictos/lock.svg',
              styles: {
                width: '40px',
              },
            },
            {
              type: 'ace',
              content: i18n<string>("ALL.ACE.COMMON.You can't **[what]**"),
              translateParams: {
                what: context.what,
              },
            },
            {
              type: 'ace',
              content: i18n<string>('ALL.ACE.COMMON.Ask **the location\'s manager** to grant you the following permission: "**[ace]**"'),
              translateParams: {
                ace: location_user_ace_to_i18n.get('read'),
              },
            },
          ]);
        }

        return null;
      })
    );

  /**
   * Check if the current user can read devices.
   *
   * @returns An observable with the error model else null.
   *
   * @public
   * @observable
   */
  public check_read_devices$$ = (context: EntityUserAclContext): Observable<ErrorHelperData | null> =>
    this.can$$('read_devices').pipe(
      map(can_read_devices => {
        if (!can_read_devices) {
          return new ErrorHelperData([
            {
              type: 'image_svg',
              url: '/assets/img/pictos/lock.svg',
              styles: {
                width: '40px',
              },
            },
            {
              type: 'ace',
              content: i18n<string>("ALL.ACE.COMMON.You can't **[what]**"),
              translateParams: {
                what: context.what,
              },
            },
            {
              type: 'ace',
              content: i18n<string>('ALL.ACE.COMMON.Ask **the location\'s manager** to grant you the following permission: "**[ace]**"'),
              translateParams: {
                ace: location_user_ace_to_i18n.get('read_devices'),
              },
            },
          ]);
        }

        return null;
      })
    );

  /**
   * Check if the current user can read the measurements.
   *
   * @returns An observable with the error model else null.
   *
   * @public
   * @observable
   */
  public check_read_measurements_data$$ = (context: EntityUserAclContext): Observable<ErrorHelperData | null> =>
    this.can$$('read_measurements_data').pipe(
      map(can_read_measurements_data => {
        if (!can_read_measurements_data) {
          return new ErrorHelperData([
            {
              type: 'image_svg',
              url: '/assets/img/pictos/lock.svg',
              styles: {
                width: '40px',
              },
            },
            {
              type: 'ace',
              content: i18n<string>("ALL.ACE.COMMON.You can't **[what]**"),
              translateParams: {
                what: context.what,
              },
            },
            {
              type: 'ace',
              content: i18n<string>('ALL.ACE.COMMON.Ask **the location\'s manager** to grant you the following permission: "**[ace]**"'),
              translateParams: {
                ace: location_user_ace_to_i18n.get('read_measurements_data'),
              },
            },
          ]);
        }

        return null;
      })
    );
}
