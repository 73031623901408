<div [attr.aria-labelledby]="_formField?.getLabelId()" class="bg2-user-select-container">
  <input matInput *ngIf="options?.readonly" type="text" [readonly]="true" [value]="selected_user_name$$ | async" />

  <mtx-select
    #userSelect
    *ngIf="options?.readonly === false"
    class="bg2-user-select-container"
    [appendTo]="'body'"
    [bindLabel]="'name'"
    [bindValue]="'user_id'"
    [items]="items$$ | async"
    [typeahead]="raw_filter$$"
    [ngModel]="user_id$$ | async"
    [dropdownPosition]="'bottom'"
    [loading]="is_loading$$ | async"
    [searchable]="is_searchable$$ | async"
    [placeholder]="placeholder | translate"
    [attr.aria-labelledby]="_formField?.getLabelId()"
    (focusin)="onFocusIn($event)"
    (focusout)="onFocusOut($event)"
    (ngModelChange)="value = $event"
  >
    <!-- Template for header -->
    <ng-template ng-header-tmp>
      <div class="ng-option disabled">
        <span>{{ select_loading_sentence$$ | async }}</span>
      </div>
    </ng-template>

    <!-- Template for loading items  -->
    <ng-template ng-loadingtext-tmp let-searchTerm="searchTerm">
      <div class="ng-option disabled">
        <span *ngIf="searchTerm">"{{ searchTerm }}" </span>
        <span>{{ 'ALL.COMMON.Loading' | translate }}</span>
      </div>
    </ng-template>

    <!-- Template for not found item -->
    <ng-template ng-notfound-tmp let-searchTerm="searchTerm">
      <div class="ng-option disabled">{{ 'WIDGETS.MISC_WIDGETS.USER_SELECT.No user found' | translate }}</div>
    </ng-template>

    <!-- Template for label item -->
    <ng-template ng-label-tmp let-item="item">
      <ng-container *ngIf="assert_user(item?.user); let user">
        <span>{{ user?.name$$ | async }}</span>
      </ng-container>
    </ng-template>

    <!-- Template for group? item inside the dropdown -->
    <ng-template ng-optgroup-tmp let-item="item" let-index="index">
      {{ item.user?.name$$ | async }}
    </ng-template>

    <!-- Template for each item inside the dropdown -->
    <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
      <div *ngIf="assert_user(item?.user); let user" style="display: flex; flex-flow: row nowrap; align-items: center; gap: 5px">
        <span
          *ngLet="user?.is_superadmin$$ | async; let is_superadmin"
          class="mdi mdi-24px"
          [ngStyle]="{ color: is_superadmin ? 'goldenrod' : 'inherit' }"
          [ngClass]="{ 'mdi-account': !is_superadmin, 'mdi-crown': is_superadmin }"
        ></span>
        <span [ngOptionHighlight]="search">{{ user?.name$$ | async }}</span>
      </div>
    </ng-template>
  </mtx-select>
</div>
