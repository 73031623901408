/* eslint-disable rxjs/no-internal */
import { findIndex, isArray, isEqual, isNil } from 'lodash-es';

import { asyncScheduler, BehaviorSubject, filter, from, map, mergeMap, Observable, switchMap, take, tap, timeout } from 'rxjs';
import { timer, buffer } from 'rxjs';

import { replay, waitForNotNilValue } from '@bg2app/tools/rxjs';

import { ConsoleLoggerService } from 'app/core/console-logger.service';

import { Dictionary } from 'app/typings/core/interfaces';
import { RequestInQueue } from './interfaces/request-in-queue';
import { HttpBatchConfig } from './interfaces/http-batch-config';
import { RequestsToBatchByQueryParameters } from './interfaces/request-to-batch-by-qparams';

/**
 * Generic class to help batching HTTP requests.
 *
 * @template ResponseType Type of the response (could be an entity, a user or anything else).
 * @template RequestParametersType Definition of the possible query parameters.
 *
 * @class HttpBatch
 */
export class HttpBatch<ResponseType, RequestParametersType extends Dictionary<any>> {
  // #region (model basics)

  /** */
  private _config: HttpBatchConfig<ResponseType, RequestParametersType> = null;

  /** */
  private LOGGER: ConsoleLoggerService = null;

  /** */
  constructor(config: HttpBatchConfig<ResponseType, RequestParametersType>) {
    this.LOGGER = new ConsoleLoggerService(config.logger.name, config.logger.active);
    this._config = config;
  }

  // #endregion

  /**
   * Request ID (incrementally assigned to each new request to batch).
   *
   * @private
   */
  private _request_id: number = 0;

  /**
   * New request to batch entry queue.
   *
   * @private
   */
  private _request_queue_entry$$ = new BehaviorSubject<RequestInQueue<ResponseType, RequestParametersType>>(null);

  // private _pending_requests_ticks$$ = new Subject<boolean>();
  // private pending_requests_ticks$$ = this._pending_requests_ticks$$.pipe(
  //   debounceTime(50)
  // )
  /** */
  private pending_requests_ticks$$ = timer(50, 50);

  /**
   * Observe a list of pending requests.
   *
   * @public
   * @replay
   */
  public pending_requests$$: Observable<RequestInQueue<ResponseType, RequestParametersType>[]> = this._request_queue_entry$$
    .pipe(
      waitForNotNilValue(),
      tap(data => {
        if (data.multiple) {
          this.LOGGER.debug(`New request #${data.request_id}, object_ids:${data.object_ids}`);
        } else {
          this.LOGGER.debug(`New request #${data.request_id}, object_id:${data.object_ids[0]}`);
        }
      }),
      buffer(this.pending_requests_ticks$$),
      filter(requests => requests?.length > 0),
      tap(data => this.LOGGER.debug(`Batched requests: `, data)),
      map(requests => this.group_by_parameters_config(requests)),
      tap(data => this.LOGGER.debug(`Batched requests by parameters: `, data))
    )
    .pipe(
      mergeMap(requests_to_batch => this._config.batch_requests(requests_to_batch)),
      tap(requests => {
        const log_request_ids = requests?.map(request => `#${request.request_id}`).join(', ');
        this.LOGGER.debug(`Got response for requests ${log_request_ids}`);
      }),
      replay()
    );

  /**
   * Add a new request to batch, then wait for it.
   *
   * @param object_ids IDs of the objects to identify.
   * @param parameters Query parameters assigned to the object request.
   *
   * @returns Retunrs an observable on the result of the request.
   *
   * @public
   */
  public add_and_wait_request(object_ids: number, parameters?: RequestParametersType): Observable<ResponseType>;
  public add_and_wait_request(object_ids: number[], parameters?: RequestParametersType): Observable<ResponseType[]>;
  public add_and_wait_request(
    object_ids: number | number[],
    parameters?: RequestParametersType
  ): Observable<ResponseType | ResponseType[]> {
    return from(this.add_request_to_queue$$(object_ids, parameters)).pipe(
      switchMap(request_id =>
        this.pending_requests$$.pipe(
          map(requests => requests.find(request => request.request_id === request_id)),
          filter(request => !isNil(request)),
          timeout(60000),
          // catchError((error: unknown) => {
          //   return throwError(() => error);
          // }),
          map(request => request?.response),
          take(1)
        )
      )
    );
  }

  /**
   * Add a new request to the queue.
   *
   * @param object_ids IDs the objects (for unbatch).
   *
   * @returns Returns the ID of the request.
   *
   * @private
   */
  private add_request_to_queue$$(object_ids: number | number[], parameters?: RequestParametersType): Observable<number> {
    return new Observable<number>(observer => {
      asyncScheduler.schedule(() => {
        const request_id = this._request_id++;

        this._request_queue_entry$$.next({
          object_ids: isArray(object_ids) ? object_ids : [object_ids],
          request_id,
          response: null,
          multiple: isArray(object_ids) ? object_ids?.length > 1 : false,
          request_parameters: parameters ?? undefined,
        });
        observer.next(request_id);
        observer.complete();
        // this._pending_requests_ticks$$.next(true);
      }, 0);
    });
  }

  /**
   * Group a list of requests by query parameters config.
   *
   * @param requests_to_group List of requests to group by query parameters.
   *
   * @returns Returns grouped requests by query parameters.
   *
   * @private
   */
  private group_by_parameters_config(
    requests_to_group: RequestInQueue<ResponseType, RequestParametersType>[]
  ): RequestsToBatchByQueryParameters<ResponseType, RequestParametersType>[] {
    const grouped_requests_by_params = requests_to_group.reduce(
      (result: { params: RequestParametersType; requests: RequestInQueue<ResponseType, RequestParametersType>[] }[], current) => {
        const find_same = findIndex(result, r => isEqual(r.params, current?.request_parameters ?? {}));

        if (find_same === -1) {
          result.push({ params: <any>current?.request_parameters ?? {}, requests: [current] });
        } else {
          result[find_same].requests.push(current);
        }

        return result;
      },
      []
    );

    return grouped_requests_by_params;
  }
}
