import { DateAdapter } from '@angular/material/core';
import { Router, RoutesRecognized } from '@angular/router';
import { Inject, Injectable, OnDestroy, VERSION } from '@angular/core';

import { configureScope } from '@sentry/angular-ivy';

import {
  of,
  map,
  tap,
  take,
  timer,
  delay,
  concat,
  filter,
  Subject,
  pairwise,
  switchMap,
  skipUntil,
  Observable,
  Subscription,
  debounceTime,
  combineLatest,
  BehaviorSubject,
  distinctUntilChanged,
} from 'rxjs';
import { replay, anyTrue, waitForNotNilValue, robustCombineLatest, distinctUntilRealChanged } from '@bg2app/tools/rxjs';

import { DateTimeAdapter } from 'ng-pick-datetime-ex';

import { isArray, isEqual, flatten, isNil } from 'lodash-es';

import { Locale } from 'date-fns';
import { fr, es, enUS, it, pt, ja } from 'date-fns/locale';

import { timeFormatDefaultLocale } from 'd3-time-format';

import { TranslateService } from '@ngx-translate/core';

import { UAParser } from 'ua-parser-js';

import { BeeguardAuthService } from '.';
import { UsersApiService } from './api/user/users-api.service';
import { UrlParamsService } from './url-param.service';
import { DeviceApi } from './api/device/device-api-service';
import { ConsoleLoggerService } from './console-logger.service';
import { Beeguard2Api } from './api/main/beeguard2-api-service';

import { ENV } from './providers/environment.provider';

import { IEnvironment } from 'environments/common';
import { Exploitation, Location, User } from '../models';

import { NumericDictionary } from 'app/typings/core/interfaces';
import { LocationFilters } from '../views/locations/configurations/filters.config';
import { FilterEmptyLocations } from 'app/typings/entities/location';
import { HttpErrorResponse } from '@angular/common/http';

export interface LocaleDateFormat {
  d: string;

  /**
   * @property
   * @description
   *
   *
   */
  ll_dsw: string; // Day in the "same week" ('mardi 2',  'mercredi 4')

  /**
   * @property
   * @description
   *
   *
   */
  ll_ny: string; // Day of the month without the year ('21 avr.')

  /**
   * @property
   * @description
   *
   *
   */
  ll: string; // Day of the month with the year (no time)

  /**
   * @property
   * @description
   *
   *
   */
  lll: string; // Day of the month with time

  /**
   * @property
   * @description
   *
   *
   */
  lll_xls: string;

  /**
   * @property
   * @description
   *
   *
   */
  d_MMM_yy: string;

  /**
   * @property
   * @description
   *
   *
   */
  dateFns: Locale;

  /**
   * @property
   * @description
   *
   *
   */
  d3Locale: any;

  /**
   * @property
   * @description
   *
   *
   */
  HH_mm: string;
}

/*
Note : d3locale cames from https://github.com/d3/d3-time-format/tree/master/locale
*/
const date_locale_formats: { [lang: string]: LocaleDateFormat } = {
  fr: {
    d: 'd',
    d_MMM_yy: 'd MMM yy',
    ll_dsw: 'eeee dd',
    ll_ny: 'd MMM',
    ll: 'd MMMM yyyy',
    lll: 'd MMMM yyyy HH:mm',
    lll_xls: 'd MMMM yyyy HH:mm',
    HH_mm: 'HH:mm',
    dateFns: fr,
    d3Locale: {
      dateTime: '%a %e %b %Y %X',
      date: '%Y-%m-%d',
      time: '%H:%M:%S',
      periods: ['', ''],
      days: ['dimanche', 'lundi', 'mardi', 'mercredi', 'jeudi', 'vendredi', 'samedi'],
      shortDays: ['dim', 'lun', 'mar', 'mer', 'jeu', 'ven', 'sam'],
      months: ['janvier', 'février', 'mars', 'avril', 'mai', 'juin', 'juillet', 'août', 'septembre', 'octobre', 'novembre', 'décembre'],
      shortMonths: ['jan', 'fév', 'mar', 'avr', 'mai', 'jui', 'jul', 'aoû', 'sep', 'oct', 'nov', 'déc'],
    },
  },
  en: {
    d: 'd',
    d_MMM_yy: 'd-MMM-yy',
    ll_dsw: 'dd eeee',
    ll_ny: 'MMM d',
    ll: 'MMMM do yyyy',
    lll: 'MMMM do yyyy h:mm a',
    lll_xls: 'm/d/yy h:mm AM/PM',
    HH_mm: 'h:mm (a)',
    dateFns: enUS,
    d3Locale: {
      dateTime: '%x, %X',
      date: '%-m/%-d/%Y',
      time: '%-I:%M:%S %p',
      periods: ['AM', 'PM'],
      days: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
      shortDays: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
      months: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
      shortMonths: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
    },
  },
  es: {
    d: 'd',
    d_MMM_yy: 'd MMM yy',
    ll_dsw: 'eeee dd',
    ll_ny: 'd MMM',
    ll: 'd MMMM yyyy',
    lll: 'd MMMM yyyy HH:mm',
    lll_xls: 'd MMMM yyyy HH:mm',
    HH_mm: 'HH:mm',
    dateFns: es,
    d3Locale: {
      dateTime: '%A, %e de %B de %Y, %X',
      date: '%d/%m/%Y',
      time: '%H:%M:%S',
      periods: ['AM', 'PM'],
      days: ['domingo', 'lunes', 'martes', 'miércoles', 'jueves', 'viernes', 'sábado'],
      shortDays: ['dom', 'lun', 'mar', 'mié', 'jue', 'vie', 'sáb'],
      months: ['enero', 'febrero', 'marzo', 'abril', 'mayo', 'junio', 'julio', 'agosto', 'septiembre', 'octubre', 'noviembre', 'diciembre'],
      shortMonths: ['ene', 'feb', 'mar', 'abr', 'may', 'jun', 'jul', 'ago', 'sep', 'oct', 'nov', 'dic'],
    },
  },
  it: {
    d: 'd',
    d_MMM_yy: 'd MMM yy',
    ll_dsw: 'eeee dd',
    ll_ny: 'd MMM',
    ll: 'd MMMM yyyy',
    lll: 'd MMMM yyyy HH:mm',
    lll_xls: 'd MMMM yyyy HH:mm',
    HH_mm: 'HH:mm',
    dateFns: it,
    d3Locale: {
      dateTime: '%A %e %B %Y, %X',
      date: '%d/%m/%Y',
      time: '%H:%M:%S',
      periods: ['AM', 'PM'],
      days: ['Domenica', 'Lunedì', 'Martedì', 'Mercoledì', 'Giovedì', 'Venerdì', 'Sabato'],
      shortDays: ['Dom', 'Lun', 'Mar', 'Mer', 'Gio', 'Ven', 'Sab'],
      months: [
        'Gennaio',
        'Febbraio',
        'Marzo',
        'Aprile',
        'Maggio',
        'Giugno',
        'Luglio',
        'Agosto',
        'Settembre',
        'Ottobre',
        'Novembre',
        'Dicembre',
      ],
      shortMonths: ['Gen', 'Feb', 'Mar', 'Apr', 'Mag', 'Giu', 'Lug', 'Ago', 'Set', 'Ott', 'Nov', 'Dic'],
    },
  },
  pt: {
    d: 'd',
    d_MMM_yy: 'd MMM yy',
    ll_dsw: 'eeee dd',
    ll_ny: 'd MMM',
    ll: 'd MMMM yyyy',
    lll: 'd MMMM yyyy HH:mm',
    lll_xls: 'd MMMM yyyy HH:mm',
    HH_mm: 'HH:mm',
    dateFns: pt,
    d3Locale: {
      dateTime: '%A, %e de %B de %Y. %X',
      date: '%d/%m/%Y',
      time: '%H:%M:%S',
      periods: ['AM', 'PM'],
      days: ['Domingo', 'Segunda', 'Terça', 'Quarta', 'Quinta', 'Sexta', 'Sábado'],
      shortDays: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sáb'],
      months: ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'],
      shortMonths: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],
    },
  },
  ja: {
    d: 'd日',
    d_MMM_yy: 'yyyy年MMMMd日',
    ll_dsw: 'eeee dd',
    ll_ny: 'd MMM',
    ll: 'yyyy年MMMMd日',
    lll: 'yyyy年MMMMd日 HH:mm',
    lll_xls: 'yyyy年MMMMd日 HH:mm',
    HH_mm: 'HH:mm',
    dateFns: ja,
    d3Locale: {
      dateTime: '%A, %e de %B de %Y. %X',
      date: '%d/%m/%Y',
      time: '%H:%M:%S',
      periods: ['AM', 'PM'],
      days: ['月曜日', '火曜日', '水曜日', '木曜日', '金曜日', '土曜日', '日曜日'],
      shortDays: ['月曜日', '火曜日', '水曜日', '木曜日', '金曜日', '土曜日', '日曜日'],
      months: ['一月', '二月', '三月', '四月', '五月', '六月', '七月', '八月', '九月', '十月', '十一月', '十二月'],
      shortMonths: ['一月', '二月', '三月', '四月', '五月', '六月', '七月', '八月', '九月', '十月', '十一月', '十二月'],
    },
  },
};

@Injectable()
export class AppStateService implements OnDestroy {
  protected _logger = new ConsoleLoggerService('AppStateService', true);

  public get config() {
    return this.env.config;
  }

  // #region -> (application constants)

  public readonly MAX_SELECT_EXPLOITATION = 5;
  public dl: LocaleDateFormat = date_locale_formats.en;

  // #endregion

  // #region -> (about current running app)

  /** */
  public running_app_info = {
    /** */
    parsed_user_agent$$: of(navigator.userAgent).pipe(
      map(user_agent => new UAParser(user_agent).getResult()),
      replay()
    ),

    /** */
    app_version$$: of(this.env?.version).pipe(replay()),

    /** */
    angular_version$$: of(VERSION.full).pipe(replay()),
  };

  /** */
  private _timezone_offset: number = null;

  /** */
  public get timezone_offset() {
    if (!isNil(this._timezone_offset)) {
      return this._timezone_offset;
    }

    const now = new Date();
    this._timezone_offset = now.getTimezoneOffset() * -1;

    return this._timezone_offset;
  }

  // #endregion

  // #region -> (exploitations)

  // Subscriptions
  private _exploitations_sub: Subscription;
  private _init_lang_sub: Subscription;
  private _lang_sub: Subscription;
  private _all_expl_sub: Subscription;
  private _all_locations_sub: Subscription;
  private _locations_selected_sub: Subscription;
  private _location_filter_sub: Subscription;
  private _user_sub: Subscription;
  private _url_params_sub: Subscription;

  // All exploitations loading status
  private _exploitations_loading$$: BehaviorSubject<boolean> = new BehaviorSubject(true);
  public exploitations_loading$$ = this._exploitations_loading$$.asObservable().pipe(distinctUntilChanged(), replay());

  /**
   * Observes if the exploitations are not currently in loading.
   */
  public is_not_loading_exploitations$$ = this.exploitations_loading$$.pipe(
    map(is_loading_exploitations => !is_loading_exploitations),
    distinctUntilRealChanged()
  );

  private set exploitations_loading(val: boolean) {
    this._exploitations_loading$$.next(val);
  }

  // All exploitations data
  private _all_exploitations$: BehaviorSubject<Exploitation[]> = new BehaviorSubject([]);
  public get all_exploitations(): Exploitation[] {
    return this._all_exploitations$.getValue();
  }
  public all_exploitations$$: Observable<Exploitation[]> = this._all_exploitations$.asObservable().pipe(replay());

  public more_than_one_expl$$ = this.all_exploitations$$.pipe(map(expls => expls.length > 1));

  // All exploitations data (by identifiers)
  private _all_exploitations_by_id: NumericDictionary<Exploitation>;
  private all_exploitations_by_id$$ = this.all_exploitations$$.pipe(
    map(exploitations =>
      exploitations.reduce(
        (last: NumericDictionary<Exploitation>, exploitation: Exploitation) => ({
          ...last,
          [exploitation.id]: exploitation,
        }),
        {}
      )
    ),
    tap(all_exploitations_by_id => (this._all_exploitations_by_id = all_exploitations_by_id))
  );
  private get all_exploitations_by_id(): NumericDictionary<Exploitation> {
    return this._all_exploitations_by_id;
  }

  // Selected exploitations loading status
  private _selected_exploitations_loading$$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public selected_exploitations_loading$$ = combineLatest([
    this.exploitations_loading$$,
    this._selected_exploitations_loading$$.asObservable().pipe(distinctUntilChanged()),
  ]).pipe(
    map(([expl_loading, select_loading]) => expl_loading || select_loading),
    debounceTime(40),
    distinctUntilChanged(),
    replay()
  );
  private set selected_exploitations_loading(val: boolean) {
    this._selected_exploitations_loading$$.next(val);
  }

  // Selected exploitations (identifiers)
  private _selected_exploitations_ids$: BehaviorSubject<number[]> = new BehaviorSubject(null);
  public selected_exploitations_ids$$: Observable<number[]> = this._selected_exploitations_ids$
    .asObservable()
    .pipe(distinctUntilRealChanged());
  public get selected_exploitations_ids(): number[] {
    return this._selected_exploitations_ids$.getValue();
  }
  public set selected_exploitations_ids(selected_exploitations_ids: number[]) {
    this._selected_exploitations_ids$.next(selected_exploitations_ids);
  }

  // Selected exploitations (itself)
  private _selected_exploitations: Exploitation[];
  public get selected_exploitations(): Exploitation[] {
    return this._selected_exploitations;
  }

  private defaultExploitationsSelect(exploitations: Exploitation[]): boolean {
    if (isNil(exploitations) || exploitations.length === 0) {
      // Do not select default exloitation if no exploiation available
      // This allows to have a default selection next when an exploitation arrive
      return;
    }

    if (this.user?.is_superadmin()) {
      return false;
    }

    // this._logger.debug('Compute default exploitations', exploitations);
    const user_exploitations = exploitations.filter(_expl => this.user && _expl.user_id === this.user.id);

    if (user_exploitations.length >= 1 && user_exploitations.length <= this.MAX_SELECT_EXPLOITATION) {
      this.selected_exploitations_ids = user_exploitations.map(expl => expl.id);
      return true;
    } else if (exploitations.length <= this.MAX_SELECT_EXPLOITATION) {
      this.selected_exploitations_ids = exploitations.map(expl => expl.id);
      return true;
    }
    return false; // No default selection done
  }

  public selected_exploitations$$: Observable<Exploitation[]> = combineLatest([
    this.all_exploitations$$,
    this.selected_exploitations_ids$$,
  ]).pipe(
    tap(() => (this.selected_exploitations_loading = true)),
    map(([exploitations, selected_exploitations_ids]: [Exploitation[], number[]]) => {
      if (!isNil(selected_exploitations_ids)) {
        const selected_exploitations = exploitations.filter(expl => selected_exploitations_ids.indexOf(expl.id) >= 0);

        if (selected_exploitations.length > 0) {
          return selected_exploitations;
        } // else recompute default expl selection
      }

      if (this.defaultExploitationsSelect(exploitations)) {
        return null; // If a default selection is done, this pipeline will start again
      } else {
        return []; // no defaut selection possible, so we have no exploitations selected...
      }
    }),
    filter(exploitations_selected => !isNil(exploitations_selected)),
    switchMap((exploitations_selected: Exploitation[]) => {
      if (exploitations_selected.length === 0) {
        return of([]);
      }
      return combineLatest(exploitations_selected.map(expl => expl.loadState()));
    }),
    tap(selected_exploitations => {
      const sids = new Set(selected_exploitations.map(exploitation => exploitation.id));
      this.all_exploitations.map(expl => {
        if (sids.has(expl.id)) {
          expl.bind('APPSTATE');
        } else {
          expl.unbind('APPSTATE');
        }
      });
    }),
    tap(selected_exploitations => (this._selected_exploitations = selected_exploitations)),
    tap(() => (this.selected_exploitations_loading = false)),
    replay()
  );

  public selected_warehouse_ids$$: Observable<number[]> = this.selected_exploitations$$.pipe(
    switchMap((exploitations: Exploitation[]) => {
      if (exploitations.length > 0) {
        return combineLatest(exploitations.map(expl => expl.warehouse_id$$));
      } else {
        return of<number[]>([]);
      }
    }),
    replay()
  );

  /**
   * Loads exploitations without their state. This method is called once
   * user changed.
   *
   * @warning This methods loads exploitations without the state, this
   * should be done on selection.
   */
  public loadExploitations(): void {
    // this._logger.debug(`Loading exploitations ...`);
    this.exploitations_loading = true;

    this.exploitationsUnsubscribe();
    this.bg2Api.razEntitiesCache();

    this._exploitations_sub = this.bg2Api.getEntitiesObjByType('exploitation', undefined, undefined, undefined, false).subscribe(
      (entities: Exploitation[]) => {
        this._logger.info(`${entities?.length} loaded exploitations.`);
        this._all_exploitations$.next(entities);
        this.exploitations_loading = false;
      },
      (error: unknown) => {
        if (error instanceof Error) {
          this._logger.error(`[${error?.name}] ${error?.message}`);
        } else if (error instanceof HttpErrorResponse) {
          this._logger.error(`[${error?.status}] ${error?.statusText}`);
        } else {
          this._logger.error(error);
        }
      }
    );
  }

  /**
   * Selects a list of exploitations.
   *
   * @param expl The list of exploitations to select. Can be an array of
   * `Exploitation` or `number` or `string`.
   */
  public selectExploitations(expl: (Exploitation | number | string)[]): void {
    const expl_ids = this.getExploitationsIds(expl);
    if (!isEqual(this.selected_exploitations_ids, expl_ids)) {
      this._selected_exploitations_ids$.next(expl_ids);
    }
  }

  /**
   * Fetch specific exploitation from a source.
   *
   * @param expl_or_eid The exploitation data source.
   * @returns Returns the fetched exploitation.
   */
  public getExploitationFromId(expl_or_eid: Exploitation | number | string): Exploitation {
    if (expl_or_eid instanceof Exploitation) {
      return expl_or_eid as Exploitation;
    } else if (typeof expl_or_eid === 'string') {
      expl_or_eid = parseInt(expl_or_eid, 10);
    }
    return this.all_exploitations_by_id[expl_or_eid];
  }

  /**
   * Fetch the specific exploitation id from a source.
   *
   * @param expl_or_eid The exploitation data source.
   * @returns Returns the fetched exploitation id.
   */
  public getExploitationId(expl_or_eid: Exploitation | number | string): number {
    if (expl_or_eid instanceof Exploitation) {
      return (expl_or_eid as Exploitation).id;
    } else if (typeof expl_or_eid === 'string') {
      return parseInt(expl_or_eid, 10);
    }
    return expl_or_eid as number;
  }

  /**
   * Fetch all exploitations for all exploitation sources.
   *
   * @param expls_or_eids The exploitation data source.
   * @returns Returns the fetched exploitations.
   */
  public getExploitationsFromIds(expls_or_eids: (Exploitation | number | string)[]): Exploitation[] {
    return expls_or_eids.map(expl_or_eid => this.getExploitationFromId(expl_or_eid)).filter(expl => !isNil(expl));
  }

  /**
   * Fetch all exploitations identifiers for all exploitation sources.
   *
   * @param expls_or_eids The exploitation data source.
   * @returns Returns the fetched exploitations identifiers.
   */
  public getExploitationsIds(expls_or_eids: (Exploitation | number | string)[]): number[] {
    return expls_or_eids.map(expl_or_eid => this.getExploitationId(expl_or_eid)).filter(expl => !isNil(expl));
  }

  /**
   * Adds a new exploitation to exploitations.
   *
   * @param new_exploitation The new exploitation to add.
   */
  public addExploitations(new_exploitation: Exploitation): void {
    const current_exploitations = this.all_exploitations;
    current_exploitations.push(new_exploitation);
    this._all_exploitations$.next(current_exploitations);
  }

  /**
   * Unsubscribes the exploitations.
   */
  private exploitationsUnsubscribe(): void {
    this._exploitations_sub?.unsubscribe();
  }

  // #region -> (user management)

  /**
   *
   * @private
   * @deprecated
   */
  private _real_user: User = null;

  /**
   * Observes the real authenticated user.
   *
   * @public
   * @replay
   */
  public real_user$$: Observable<User> = this.oAuthService.real_user_id$$.pipe(
    // tap(user => this._logger.debug("New real user", user)),
    switchMap(real_user_id => this.userApi.fetch_user$(real_user_id, true)),
    tap(real_user => (this._real_user = real_user)),
    replay()
  );

  public is_impersonate_actif$$ = this.oAuthService.is_impersonate_actif$$;

  /**
   * Observes the impersonated user.
   *
   * @private
   * @replay
   */
  private impersonated_user$$ = this.oAuthService.impersonate_id$$.pipe(
    // tap(user => this._logger.debug("New impersonate user", user)),
    switchMap(impersonated_user_id => {
      if (isNil(impersonated_user_id)) {
        return of<User>(null);
      }
      return this.userApi.fetch_user$(impersonated_user_id);
    }),
    replay()
  );

  /**
   *
   * @private
   * @deprecated
   */
  private _user: User = null;

  /**
   * Observes the current user. It could be the impersonated user, elsewhere the authenticated one.
   *
   * @public
   * @replay
   */
  public user$$ = this.oAuthService.is_authorized$$.pipe(
    switchMap(authorized => {
      if (!authorized) {
        return of(null);
      }

      return combineLatest({ real_user: this.real_user$$, impersonated_user: this.impersonated_user$$ }).pipe(
        map(({ real_user, impersonated_user }) => {
          if (isNil(impersonated_user)) {
            return real_user;
          }

          return impersonated_user;
        })
      );
    }),
    tap(user => (this._user = user)),
    replay()
  );

  /**
   *
   * @public
   * @deprecated
   */
  public get user(): User {
    return this._user;
  }

  /**
   * Observes if the user is an administrator.
   *
   * @note Using this observable includes the impersonate.
   *
   * @public
   * @observable
   */
  public is_superadmin$$ = this.user$$.pipe(
    waitForNotNilValue(),
    switchMap(user => user.is_superadmin$$),
    replay()
  );

  /**
   * Observes if the real user is an administrator.
   *
   * @note Using this observable ignores the impersonate.
   *
   * @public
   * @observable
   */
  public is_real_user_superadmin$$ = this.real_user$$.pipe(
    waitForNotNilValue(),
    switchMap(real_user => real_user.is_superadmin$$),
    replay()
  );

  /** */
  public all_users$$: Observable<User[]> = this.userApi.fetch_users$().pipe(
    map(users_response => users_response.users),
    replay()
  );

  /** */
  public usernames_by_ids$$: Observable<NumericDictionary<string>> = this.all_users$$.pipe(
    map((users: User[]) => {
      const res: NumericDictionary<string> = {};
      users.map(suser => (res[suser.user_id] = suser.name));
      return res;
    }),
    replay()
  );

  public isAccountIsDeactivated(): boolean {
    if (isNil(this.user)) {
      return false;
    }

    return !(this.user.user_id === 1) && this.user.unpaid_block;
  }

  // #endregion

  // #region -> (language)
  private _lang: string = null;
  private _lang$$: BehaviorSubject<string> = new BehaviorSubject(this._lang);
  public _null_lang$$ = this._lang$$.asObservable().pipe(
    switchMap(language =>
      this.user$$.pipe(
        switchMap(user => {
          if (isNil(user)) {
            return of(language);
          }

          return user.lang$$;
        })
      )
    ),
    debounceTime(100),
    distinctUntilRealChanged(),
    tap(lang => {
      if (!isNil(lang)) {
        // console.log('Lang change: ', lang);
        this.dateTimeAdapter.setLocale(lang);
        this.dateAdapter.setLocale(date_locale_formats[lang].dateFns);
        this.translate.use(lang);
        this.dl = date_locale_formats[lang] || date_locale_formats.en;
        timeFormatDefaultLocale(this.dl.d3Locale);
      }
    }),
    delay(100), // we add this delay to ensure lang change is realy done
    tap(lang => {
      if (!isNil(lang)) {
        // Store it !
        this._lang = lang;
        localStorage.setItem('lang', lang);
      }
    }),
    replay()
  );

  public lang$$ = this._null_lang$$.pipe(
    filter(lang => !isNil(lang)),
    distinctUntilRealChanged(),
    replay()
  );

  public dl$$ = this.lang$$.pipe(map(() => this.dl));

  public set_language(language: string): void {
    if (!this.islangAvailable(language)) {
      console.error(`Unsuported langage: ${language}`);
      return;
    }
    this._lang$$.next(language);
  }

  public get lang(): string {
    return this._lang;
  }

  /**
   * Checks if the language is available.
   *
   * @param lang The language to test.
   * @returns Returns `true` if the language is available.
   */
  public islangAvailable(lang: string): boolean {
    return this.env.config.language.available.includes(lang);
  }

  /**
   * Initializes application language.
   */
  private initLang(): void {
    // this language will be used as a fallback when a translation isn't found in the current language
    this.translate.setDefaultLang(this.env.config.language.default);
    const stored_lang = localStorage.getItem('lang');
    const nav_lang = (navigator.language || this.env.config.language.default).slice(0, 2);

    this._init_lang_sub?.unsubscribe();
    this._init_lang_sub = concat(of(null), this.urlParams.on_change('lang'))
      .pipe(take(2), debounceTime(140))
      .subscribe(
        // ^ NOTE: take two to get just the params at load time
        // See: https://github.com/angular/angular/issues/12157
        value => {
          let lang = this.env.config.language.default;
          if (!isNil(value) && this.islangAvailable(value)) {
            lang = value;
          } else if (!isNil(stored_lang) && this.islangAvailable(stored_lang)) {
            lang = stored_lang;
          } else if (!isNil(nav_lang) && this.islangAvailable(nav_lang)) {
            lang = nav_lang;
          }
          this.set_language(lang);
          this._logger.info(`Language loaded is ${lang}.`);
        }
      );
  }

  // #endregion

  // #region (service basics)

  ngOnDestroy(): void {
    this.exploitationsUnsubscribe();
    this._lang_sub?.unsubscribe();
    this._init_lang_sub?.unsubscribe();
    this._lang_sub?.unsubscribe();
    this._all_expl_sub?.unsubscribe();
    this._all_locations_sub?.unsubscribe();
    this._locations_selected_sub?.unsubscribe();
    this._location_filter_sub?.unsubscribe();
    this._user_sub?.unsubscribe();
    this._url_params_sub?.unsubscribe();
  }

  // #endregion

  // #region ↛ (locations)

  public location_filters = new LocationFilters();

  // locations loading status
  private _locations_loading$$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private set locations_loading(val: boolean) {
    this._locations_loading$$.next(val);
  }
  public locations_loading$$ = anyTrue(this._locations_loading$$.pipe(distinctUntilChanged()), this.selected_exploitations_loading$$).pipe(
    distinctUntilChanged(),
    debounceTime(50),
    replay()
  );

  /**
   * Observes if the locations are not currently loading.
   */
  public is_not_loading_locations$$ = this.locations_loading$$.pipe(
    map(is_loading_locations => !is_loading_locations),
    distinctUntilRealChanged()
  );

  protected _all_locations: any = [];
  public all_locations$$ = this.selected_exploitations$$.pipe(
    tap(() => (this.locations_loading = true)),
    switchMap((exploitations: Exploitation[]) => robustCombineLatest(exploitations.map(expl => expl.locations_archived_or_not$$))),
    map((locations: Location[][]) => flatten(locations)),
    tap(all_locations => (this._all_locations = all_locations)),
    tap(() => (this.locations_loading = false)),
    replay()
  );

  public all_named_locations$$ = this.selected_exploitations$$.pipe(
    switchMap((exploitations: Exploitation[]) => robustCombineLatest(exploitations.map(expl => expl.named_locations_archived_or_not$$))),
    map((locations: Location[][]) => flatten(locations)),
    // tap(locations => this._logger.debug('all named locations', locations)),
    replay()
  );

  // Filtres
  protected _locations_selected: any[] = [];
  public locations_selected$$ = this.all_locations$$.pipe(
    switchMap(locations =>
      this.location_filters.apply(locations).pipe(
        switchMap(filtered_locations => {
          if (locations.length > 0 && filtered_locations.length === 0) {
            this._logger.info('No selected location, but there is some, one may release some filters');
            return this.location_filters.active_filters$$.pipe(
              tap(actif_filters => {
                const empty_filter = actif_filters.find(_filter => _filter.name === 'empty');
                if (empty_filter && actif_filters.length === 1) {
                  this._logger.info('Just empty filter actif, unactivate that filter');
                  this.location_filters.get_by_name('empty').set([FilterEmptyLocations.occupied, FilterEmptyLocations.empty]);
                }
              }),
              map(() => filtered_locations)
            );
          }
          return of(filtered_locations);
        })
      )
    ),
    tap(locations => (this._locations_selected = locations)),
    // tap(locations => this._logger.debug('New selected locations', locations)),
    replay()
  );

  // #endregion

  public previous_url$$: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  public get previous_url(): string {
    return this.previous_url$$.getValue();
  }

  public current_url$$: BehaviorSubject<string> = new BehaviorSubject<string>(null);

  /**
   * Constructs an object of type `AppStateService`.
   */
  constructor(
    @Inject(ENV) public env: IEnvironment,
    public bg2Api: Beeguard2Api,
    public deviceApi: DeviceApi,
    public userApi: UsersApiService,
    public oAuthService: BeeguardAuthService,
    private router: Router,
    public translate: TranslateService,
    private urlParams: UrlParamsService,
    private dateTimeAdapter: DateTimeAdapter<any>,
    private dateAdapter: DateAdapter<Locale>
  ) {
    this.urlParams.castAsIntArray('se');

    this.router.events
      .pipe(
        filter((evt: any) => evt instanceof RoutesRecognized),
        map(evt => evt.urlAfterRedirects),
        map(url => url.split('(')[0]),
        map(url => url.split('?')[0]),
        distinctUntilRealChanged(),
        tap(url => this.current_url$$.next(url)),
        pairwise()
      )
      .subscribe(([previous_url, current_url]: [string, string]) => {
        this.previous_url$$.next(previous_url);
      });

    // Subscribe to internal "replay" observable to ensure to always have a data to replay
    this._all_expl_sub = combineLatest([this.all_exploitations$$, this.all_exploitations_by_id$$]).subscribe();
    this._all_locations_sub = this.all_locations$$.subscribe();
    this._locations_selected_sub = this.locations_selected$$.subscribe();

    this._lang_sub = this.lang$$.subscribe();
    this._user_sub = this.user$$.subscribe();

    const se_fitter_from_url$ = this.urlParams.on_change('se').pipe(
      tap((value: number[]) => {
        let selected_exploitation_ids = value.filter(id => id >= 0);

        if (selected_exploitation_ids && selected_exploitation_ids.length > 0) {
          if (!isArray(selected_exploitation_ids)) {
            selected_exploitation_ids = [selected_exploitation_ids];
          }
          this.selectExploitations(selected_exploitation_ids);
        }
      })
    );

    const se_fitter_to_url$ = this.selected_exploitations_ids$$.pipe(
      skipUntil(timer(500)), // ignore default value ?
      debounceTime(200),
      distinctUntilRealChanged(),
      filter(se => !isNil(se)),
      tap(se => {
        if (se.length === 0) {
          this.urlParams.set('se', ['-1']);
        } else {
          this.urlParams.set('se', se);
        }
      })
    );

    this._url_params_sub = combineLatest([
      se_fitter_from_url$,
      se_fitter_to_url$,
      this.location_filters.sync_url_params(this.urlParams),
    ]).subscribe();

    // Bind user to scripts
    this.real_user$$.pipe(waitForNotNilValue()).subscribe({
      next: real_user => {
        // Update Sentry configuration
        if (this.env?.sentry?.url) {
          configureScope(scope => {
            scope.setUser({
              id: real_user.user_id.toString(),
              email: real_user.email,
              username: real_user.username,
            });
          });
        }

        // Update Zoho sales iq
        if (this.env?.salesiq_code) {
          setTimeout(() => {
            const zoho_conf = (window as any).$zoho || {};
            (window as any).$zoho = zoho_conf;

            zoho_conf.salesiq = {
              widgetcode: this.env.salesiq_code,
              values: {},
              ready: () => {
                const $zoho = (window as any).$zoho;
                $zoho.salesiq.chat.department(['SAV']);
                $zoho.salesiq.chat.defaultdepartment('SAV');
                $zoho.salesiq.chatbutton.texts([
                  ['A feedback ?', 'Online'],
                  ['A feedback ?', 'Offline'],
                ]);

                $zoho.salesiq.visitor.id('' + real_user.user_id);
                $zoho.salesiq.visitor.name(real_user.username);
                $zoho.salesiq.visitor.email(real_user.email);
              },
            };

            const body = document.getElementsByTagName('body')[0];
            const zoho = document.createElement('script');

            zoho.setAttribute('type', 'text/javascript');
            zoho.setAttribute('id', 'zsiqscript');
            zoho.setAttribute('defer', 'true');
            zoho.setAttribute('src', 'https://salesiq.zoho.com/widget');

            const first_s = document.getElementsByTagName('script')[0];
            first_s.parentNode.insertBefore(zoho, first_s);

            const zoho_widget = document.createElement('div');
            zoho_widget.setAttribute('id', 'zsiqwidget');
            body.appendChild(zoho_widget);
          }, 400);
        }
      },
    });
  }

  /**
   * Called once `AppComponent` has triggered `OnInit`.
   */
  public ngAppOnInit(): void {
    this.initLang();
  }

  /*===============                 Locations                 ===============*/
  /* Manage location list */

  get all_locations(): Location[] {
    return this._all_locations;
  }

  get locations_selected(): Location[] {
    return this._locations_selected;
  }
}
