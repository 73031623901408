import { marker as i18n } from '@biesbjerg/ngx-translate-extract-marker';

import { includes, intersection, isEmpty, isEqual, isNil, isNull } from 'lodash-es';

import { isSameYear } from 'date-fns';

import { combineLatest, of, map, switchMap } from 'rxjs';

import { Location } from 'app/models';
import { ActiveFilter } from 'app/models/filters';
import { AbstractFilterMngt, StringArrayFilter, StringFilter } from 'app/models/filters';
import { NumberArrayFilter, SelectedExploitationsFilter } from 'app/models/filters/filter-elements';

import { FilterEmptyLocations, FilterEmptyLocations_i18n_chip } from 'app/typings/entities/location';

import { parseDate } from 'app/misc/tools';

const EXTRACT_LOCATION_RAW_FILTER = new RegExp('\\!("([^"]*)|([^ ]*))');
const EXTRACT_APIARY_RAW_FILTER = new RegExp('\\?("([^"]*)|([^ ]*))');

export class LocationFilters extends AbstractFilterMngt<Location[]> {
  constructor() {
    super(['selected_exploitation_ids', 'raw', 'empty', 'location_devices', 'location_paiement', 'show_ghost_location', 'show_archived']);
  }

  // Filter on selected exploitation
  public selected_exploitation_ids = new SelectedExploitationsFilter<Location[]>();

  /** */
  raw = new StringFilter<Location[]>(
    'raw',
    {
      default: '',
      label: i18n<string>('VIEWS.PAGE.LOCATION.Search by location and apiary name'),
    },
    {
      url_param_name: 'fraw',
      chip_i18n: 'VIEWS.LOCATIONS.COMPONENTS.PAGE_LOCATIONS.Name: "[data->value]"',
      apply: (filter, locations) => {
        if (filter.value) {
          const raw = filter.value.toLowerCase();
          const reres_loc = EXTRACT_LOCATION_RAW_FILTER.exec(raw);
          const raw_location = reres_loc ? reres_loc[2] || reres_loc[1] : null;
          const reres_api = EXTRACT_APIARY_RAW_FILTER.exec(raw);
          const raw_apiary = reres_api ? reres_api[2] || reres_api[1] : null;
          locations = locations.filter(location => {
            let keep_it = location.name.toLowerCase().indexOf(raw) >= 0;
            keep_it = keep_it || (location.apiary_name && location.apiary_name.toLowerCase().indexOf(raw) >= 0);
            if (raw_apiary) {
              keep_it = keep_it || (location.apiary_name && location.apiary_name.toLowerCase().indexOf(raw_apiary) >= 0);
            }
            if (raw_location) {
              keep_it = keep_it || location.name.toLowerCase().indexOf(raw_location) >= 0;
            }
            return keep_it;
          });
        }
        return of(locations);
      },
    }
  );

  public empty = new StringArrayFilter<Location[]>(
    'empty',
    {
      type: 'array',
      label: i18n<string>('ALL.FILTERS.LABELS.Filter locations with apiary'),
      default: [FilterEmptyLocations.occupied],
      widget: 'checklist',
      options: {
        display: 'button',
      },
      minItems: 1,
      items: {
        type: 'string',
        oneOf: [
          {
            enum: [FilterEmptyLocations.occupied],
            label: i18n('VIEWS.PAGE.LOCATION.Hide empty locations'),
            image: 'img/views/common/maps/location_marker.svg',
            image_styles: {
              'max-width': '40px',
            },
          },
          {
            enum: [FilterEmptyLocations.empty],
            label: i18n('VIEWS.PAGE.LOCATION.Only empty locations'),
            image: 'img/views/common/maps/location_archived_marker.svg',
            image_styles: {
              'max-width': '40px',
            },
          },
        ],
      },
    },
    {
      url_param_name: 'fem',
      reset_value: [FilterEmptyLocations.occupied, FilterEmptyLocations.empty],
      only_url_param_if_active: false,
      is_active: filter => isEqual(filter?.value, [FilterEmptyLocations.empty]) || isEqual(filter?.value, [FilterEmptyLocations.occupied]),
      compute_chip: filter => {
        const active_filter: ActiveFilter = {
          label: FilterEmptyLocations_i18n_chip.occupied,
          data: {},
        };

        if (filter?.value?.length > 1) {
          active_filter.label = FilterEmptyLocations_i18n_chip.all;
        } else {
          if (includes(filter?.value, FilterEmptyLocations.empty)) {
            active_filter.label = FilterEmptyLocations_i18n_chip.empty;
          }
        }

        return of(active_filter);
      },
      apply: (filter, locations) =>
        of(locations).pipe(
          switchMap(_locations => {
            if (_locations.length > 0) {
              return combineLatest(
                _locations.map(location => location.has_apiary$$.pipe(map(has_apiary => [location, has_apiary] as [Location, boolean])))
              );
            } else {
              return of([]);
            }
          }),
          map(locations__has_apiary => {
            if (filter?.value) {
              if (isEqual(filter?.value, [FilterEmptyLocations.occupied])) {
                locations__has_apiary = locations__has_apiary.filter(([location, has_apiary]) => has_apiary);
              } else if (isEqual(filter?.value, [FilterEmptyLocations.empty])) {
                locations__has_apiary = locations__has_apiary.filter(([location, has_apiary]) => !has_apiary);
              }
            }

            return locations__has_apiary.map(([location]) => location);
          })
        ),
    }
  );

  show_ghost_location = new StringArrayFilter<Location[]>(
    'show_ghost_location',
    {
      type: 'array',
      label: i18n<string>('ALL.FILTERS.LABELS.Filter automatically created locations'),
      default: ['ghost', 'named'],
      widget: 'checklist',
      options: {
        display: 'button',
      },
      minItems: 1,
      items: {
        type: 'string',
        oneOf: [
          {
            enum: ['ghost'],
            label: i18n('ENTITY.LOCATION.FILTERS.Automaticaly created locations'),
            image: 'img/views/common/filters/ghost-barred.svg',
            image_styles: {
              'max-width': '40px',
            },
          },
          {
            enum: ['named'],
            label: i18n('ENTITY.LOCATION.FILTERS.Manualy created locations'),
            image: 'img/views/common/filters/ghost.svg',
            image_styles: {
              'max-width': '40px',
            },
          },
        ],
      },
    },
    {
      url_param_name: 'flgh',
      is_active: filter => filter.value?.length !== 2,
      compute_chip: filter => {
        const active_filter: ActiveFilter = {
          label: i18n('ENTITY.LOCATION.FILTERS.All locations'),
          data: {},
        };
        if (filter.value.length === 1) {
          if (includes(filter.value, 'ghost')) {
            active_filter.label = i18n('ENTITY.LOCATION.FILTERS.Only automaticaly created');
          } else {
            active_filter.label = i18n('ENTITY.LOCATION.FILTERS.Only manualy created');
          }
        }
        return of(active_filter);
      },
      apply: (filter, locations) =>
        of(locations).pipe(
          switchMap(_locations => {
            if (_locations.length > 0) {
              return combineLatest(
                _locations.map(location => location.is_ghost$$.pipe(map(is_ghost => [location, is_ghost] as [Location, boolean])))
              );
            } else {
              return of([] as [Location, boolean][]);
            }
          }),
          map(locations__is_ghost => {
            if (isEqual(filter.value, ['ghost'])) {
              locations__is_ghost = locations__is_ghost.filter(([location, is_ghost]) => is_ghost);
            } else if (isEqual(filter.value, ['named'])) {
              locations__is_ghost = locations__is_ghost.filter(([location, is_ghost]) => !is_ghost);
            }
            return locations__is_ghost.map(([location, is_ghost]) => location);
          })
        ),
    }
  );

  show_archived = new StringArrayFilter<Location[]>(
    'show_archived',
    {
      type: 'array',
      label: i18n<string>('ALL.FILTERS.LABELS.Filter archived locations'),
      default: ['un_archived'],
      widget: 'checklist',
      options: {
        display: 'button',
      },
      minItems: 1,
      items: {
        type: 'string',
        oneOf: [
          {
            enum: ['archived'],
            label: i18n('VIEWS.PAGE.LOCATION.Archived'),
            image: 'img/views/common/filters/archive-box.svg',
            image_styles: {
              'max-width': '40px',
            },
          },
          {
            enum: ['un_archived'],
            label: i18n('VIEWS.PAGE.LOCATION.Unarchived'),
            image: 'img/views/common/filters/non-archive-box.svg',
            image_styles: {
              'max-width': '40px',
            },
          },
        ],
      },
    },
    {
      url_param_name: 'farch',
      is_active: filter => filter.value?.length > 1 || includes(filter.value, 'archived'),
      compute_chip: filter => {
        const active_filter: ActiveFilter = {
          label: i18n<string>('ENTITY.LOCATION.FILTERS.Unarchived'),
          data: {},
        };

        if (filter.value.length > 1) {
          active_filter.label = i18n<string>('ENTITY.LOCATION.FILTERS.Archived & unarchived');
        } else {
          if (includes(filter.value, 'archived')) {
            active_filter.label = i18n<string>('ENTITY.LOCATION.FILTERS.Archived');
          }
        }

        return of(active_filter);
      },
      apply: (filter, locations) => {
        if (filter.value) {
          if (isEqual(filter.value, ['un_archived'])) {
            locations = locations.filter(location => !location.isArchived());
          } else if (isEqual(filter.value, ['archived'])) {
            locations = locations.filter(location => location.isArchived());
          }
        }
        return of(locations);
      },
    }
  );

  public location_paiement = new StringFilter<Location[]>(
    'location_paiement',
    {
      type: 'string',
      label: i18n<string>('ALL.FILTERS.LABELS.Filter paid locations'),
      widget: 'checklist',
      default: 'any',
      oneOf: [
        {
          enum: ['paid'],
          label: i18n('ENTITY.LOCATION.FILTERS.Paid this year'),
          image: 'img/views/common/filters/euro.svg',
          image_styles: {
            'max-width': '40px',
          },
        },
        {
          enum: ['unpaid'],
          label: i18n('ENTITY.LOCATION.FILTERS.Unaid this year'),
          image: 'img/views/common/filters/euro-barred.svg',
          image_styles: {
            'max-width': '40px',
          },
        },
        {
          enum: ['any'],
          hidden: true,
        },
      ],
      options: {
        display: 'button',
        null_value: 'any',
      },
    },
    {
      url_param_name: 'fpay',
      is_active: filter => (filter.value || 'any') !== 'any',
      compute_chip: filter => {
        const active_filter: ActiveFilter = {
          label: 'DEFAULT',
          data: {},
        };

        if (filter.value === 'paid') {
          active_filter.label = i18n<string>('ENTITY.LOCATION.FILTERS.Paid this year');
        } else {
          active_filter.label = i18n<string>('ENTITY.LOCATION.FILTERS.Unaid this year');
        }
        return of(active_filter);
      },
      apply: (filter, locations) => {
        if (filter?.value === 'paid') {
          locations = locations.filter(
            location =>
              location.hasLastPayments() &&
              location.last_payments.filter(lp => !isNil(lp.at) && isSameYear(parseDate(lp.at.date), Date.now())).length > 0
          );
        }

        if (filter?.value === 'unpaid') {
          locations = locations.filter(
            location =>
              !location.hasLastPayments() ||
              isNull(location.last_payments.filter(lp => !isNil(lp.at) && isSameYear(parseDate(lp.at.date), Date.now())).length)
          );
        }
        return of(locations);
      },
    }
  );

  public location_devices = new StringArrayFilter<Location[]>(
    'location_devices',
    {
      label: i18n<string>('ALL.FILTERS.LABELS.Filter locations with at least one device'),
      type: 'array',
      default: [],
      widget: 'checklist',
      options: {
        display: 'button',
      },
      minItems: 0,
      items: {
        type: 'string',
        oneOf: [
          {
            enum: ['WG'],
            label: i18n<string>('DEVICE.WG.Full name with short name'),
            image: 'img/views/common/maps/device_wg_marker.svg',
            image_styles: {
              'max-width': '40px',
            },
          },
          {
            enum: ['GPS'],
            label: i18n<string>('DEVICE.GPS.Full name with short name'),
            image: 'img/views/common/maps/device_gps_marker.svg',
            image_styles: {
              'max-width': '40px',
            },
          },
          {
            enum: ['RG'],
            label: i18n<string>('DEVICE.RG.Full name with short name'),
            image: 'img/views/common/maps/device_rg_marker.svg',
            image_styles: {
              'max-width': '40px',
            },
          },
          {
            enum: ['CPT'],
            label: i18n<string>('DEVICE.CPT.Full name with short name'),
            image: 'img/views/common/maps/device_cpt_marker.svg',
            image_styles: {
              'max-width': '40px',
            },
          },
          {
            enum: ['BeeLive'],
            label: i18n<string>('DEVICE.BEELIVE.Full name with short name'),
            image: 'img/views/common/maps/device_cpt_marker.svg',
            image_styles: {
              'max-width': '40px',
            },
          },
        ],
      },
    },
    {
      url_param_name: 'fdev',
      reset_value: [],
      is_active: filter => filter.value?.length >= 1,
      compute_chip: filter => {
        const total_devices = filter.value.length;

        if (total_devices > 1) {
          return of({
            label: i18n('ENTITY.LOCATION.FILTERS.With [data->devices_list] or [data->last_type]'),
            data: {
              devices_list: filter.value.slice(0, -1).join(', '),
              last_type: filter.value[filter.value.length - 1],
            },
          });
        } else {
          return of({
            label: i18n('ENTITY.LOCATION.FILTERS.With [data->devices_list]'),
            data: {
              devices_list: filter.value.join(', '),
            },
          });
        }
      },
      apply: (filter, locations) => {
        if (!filter.value || filter.value.length === 0 || locations.length === 0) {
          return of(locations);
        } else {
          const _dev_filtered_loc$$ = combineLatest(
            locations.map(location =>
              location.associated_devices_types$$.pipe(
                map(dev_type => intersection(dev_type, filter.value).length > 0),
                map(keep_it => ({ keep_it, location }))
              )
            )
          );
          return _dev_filtered_loc$$.pipe(map(data => data.filter(loc_row => loc_row.keep_it).map(loc_row => loc_row.location)));
        }
      },
    }
  );

  public selected_loc_ids = new NumberArrayFilter<Location[]>(
    'selected_loc_ids',
    {},
    {
      url_param_name: 'lid',
      is_active: filter => (filter.value || []).length >= 1,
      compute_chip: filter => {
        const active_filter: ActiveFilter = {
          label: 'DEFAULT',
          data: {
            nb: filter.value.length,
          },
        };
        if (filter.value.length === 1) {
          active_filter.label = i18n<string>('ENTITY.LOCATION.FILTERS.One location selected');
        }
        if (filter.value.length > 1) {
          active_filter.label = i18n<string>('ENTITY.LOCATION.FILTERS.[data->nb] locations selected');
        }
        return of(active_filter);
      },
      apply: (filter, locations) => {
        if (!isEmpty(filter.value)) {
          locations = locations.filter(location => includes(filter.value, location.id));
        }
        return of(locations);
      },
    }
  );

  public all_filters = [
    this.raw,
    this.empty,
    this.show_ghost_location,
    this.show_archived,
    this.location_paiement,
    this.location_devices,
    this.selected_loc_ids,
    this.selected_exploitation_ids,
  ];
}
