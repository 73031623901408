<div *ngLet="modal_user$$ | async; let user" class="bg2-uam-user-details-component">
  <div class="category-container">
    <span class="category-container__title">
      <span class="category-container__title__text">
        {{ 'ROUTABLE_MODALS.USER_ACCOUNT.UAM_USER_DETAILS.PROFILE.Title' | translate }}
      </span>

      <div *ngIf="is_profile_editable$$ | async" class="category-container__title__actions">
        <ng-container *ngIf="(is_editing_profile$$ | async) === false">
          <bg2-button
            [type]="'action'"
            [icon]="'mdi-pencil'"
            [options]="{ is_thin: true }"
            [message]="'ALL.ACTIONS.Edit' | translate"
            [disabled]="(security_manager.is_editing$$ | async) || (is_editing_password$$ | async) || (is_editing_crm$$ | async)"
            (btn_click)="edit_user_profile()"
          ></bg2-button>

          <bg2-button
            *ngIf="(is_auth_user$$ | async) === false"
            [type]="'delete'"
            [icon]="'mdi-delete'"
            [options]="{ is_thin: true }"
            [message]="'ALL.ACTIONS.Delete' | translate"
            [disabled]="(security_manager.is_editing$$ | async) || (is_editing_password$$ | async) || (is_editing_crm$$ | async)"
            (btn_click)="delete_user_profile()"
          ></bg2-button>
        </ng-container>

        <ng-container *ngIf="(is_editing_profile$$ | async) === true">
          <bg2-button
            [type]="'cancel'"
            [icon]="'mdi-cancel'"
            [message]="'ALL.ACTIONS.Cancel' | translate"
            [options]="{ is_thin: true }"
            (btn_click)="cancel_user_profile()"
          ></bg2-button>

          <bg2-button
            [type]="'action'"
            [icon]="'mdi-content-save'"
            [message]="'ALL.ACTIONS.Save' | translate"
            [options]="{ is_thin: true }"
            [disabled]="!profile_form.valid"
            (btn_click)="save_user_profile()"
          ></bg2-button>
        </ng-container>
      </div>
    </span>

    <div class="category-container__properties" [ngClass]="{ '--is-editing': (is_editing_profile$$ | async) }" [formGroup]="profile_form">
      <div
        *ngIf="(is_editing_crm$$ | async) || (security_manager.is_editing$$ | async) || (is_editing_password$$ | async)"
        class="category-container__properties__disabled"
      ></div>

      <!-- User ID -->
      <div *ngIf="is_viewer_superadmin$$ | async" class="category-container__properties__prop">
        <div class="category-container__properties__prop__name">
          {{ 'ROUTABLE_MODALS.USER_ACCOUNT.UAM_USER_DETAILS.PROFILE.User ID' | translate }}
        </div>
        <div class="category-container__properties__prop__value">{{ user?.user_id$$ | async }}</div>
      </div>

      <!-- User's username -->
      <div class="category-container__properties__prop">
        <div class="category-container__properties__prop__name">
          {{ 'ROUTABLE_MODALS.USER_ACCOUNT.UAM_USER_DETAILS.PROFILE.Username' | translate }}
        </div>
        <div class="category-container__properties__prop__value">{{ user?.username$$ | async }}</div>
      </div>

      <!-- User's full name -->
      <div class="category-container__properties__prop">
        <div class="category-container__properties__prop__name">
          {{ 'ROUTABLE_MODALS.USER_ACCOUNT.UAM_USER_DETAILS.PROFILE.Full name' | translate }}
        </div>
        <div *ngIf="(is_editing_profile$$ | async) === false; else userNameForm" class="category-container__properties__prop__value">
          {{ (user?.name$$ | async) ?? '--' }}
        </div>

        <ng-template #userNameForm>
          <div class="category-container__properties__prop__value">
            <div class="category-container__properties__prop__form">
              <mat-form-field [appearance]="'outline'">
                <mat-label>{{ 'ROUTABLE_MODALS.USER_ACCOUNT.UAM_USER_DETAILS.PROFILE.Given name' | translate }}</mat-label>
                <input matInput type="text" [formControlName]="'first_name'" />
              </mat-form-field>

              <mat-form-field [appearance]="'outline'">
                <mat-label>{{ 'ROUTABLE_MODALS.USER_ACCOUNT.UAM_USER_DETAILS.PROFILE.Family name' | translate }}</mat-label>
                <input matInput type="text" [formControlName]="'last_name'" />
              </mat-form-field>
            </div>
          </div>
        </ng-template>
      </div>

      <!-- User's email -->
      <ng-container *ngIf="is_profile_readable$$ | async">
        <div class="category-container__properties__prop">
          <div class="category-container__properties__prop__name">
            {{ 'ROUTABLE_MODALS.USER_ACCOUNT.UAM_USER_DETAILS.PROFILE.Mail' | translate }}
          </div>
          <div
            *ngIf="(is_editing_profile$$ | async) === false; else userProfileFormMail"
            class="category-container__properties__prop__value"
          >
            {{ (user?.email$$ | async) ?? '--' }}
          </div>

          <ng-template #userProfileFormMail>
            <div class="category-container__properties__prop__value">
              <div class="category-container__properties__prop__form">
                <mat-form-field [appearance]="'outline'">
                  <mat-label>{{ 'ROUTABLE_MODALS.USER_ACCOUNT.UAM_USER_DETAILS.PROFILE.Mail' | translate }}</mat-label>
                  <input matInput type="text" [formControlName]="'email'" />
                </mat-form-field>
              </div>
            </div>
          </ng-template>
        </div>
      </ng-container>

      <!-- User's phone number -->
      <ng-container *ngIf="is_profile_readable$$ | async">
        <div class="category-container__properties__prop">
          <div class="category-container__properties__prop__name">
            {{ 'ROUTABLE_MODALS.USER_ACCOUNT.UAM_USER_DETAILS.PROFILE.Mobile phone' | translate }}
          </div>
          <div
            *ngIf="(is_editing_profile$$ | async) === false; else userProfileFormPhone"
            class="category-container__properties__prop__value"
          >
            <div class="phone-with-country">
              <img
                *ngIf="user?.phone_number_country$$ | async; let country"
                [src]="'https://flagcdn.com/' + (country | lowercase) + '.svg'"
                [alt]="country"
              />
              {{ (user?.phone_number$$ | async) ?? '--' }}
            </div>
          </div>

          <ng-template #userProfileFormPhone>
            <div class="category-container__properties__prop__value">
              <div class="category-container__properties__prop__form">
                <mat-form-field [appearance]="'outline'">
                  <mat-label>{{ 'ROUTABLE_MODALS.USER_ACCOUNT.UAM_USER_DETAILS.PROFILE.Mobile phone' | translate }}</mat-label>
                  <bg2-intl-phone-input
                    [enableSearch]="true"
                    [enablePlaceholder]="true"
                    [preferredCountries]="['fr']"
                    [formControlName]="'phone_number'"
                  ></bg2-intl-phone-input>
                </mat-form-field>
              </div>
            </div>
          </ng-template>
        </div>
      </ng-container>

      <!-- User's timezone -->
      <ng-container *ngIf="is_profile_readable$$ | async">
        <div class="category-container__properties__prop">
          <div class="category-container__properties__prop__name">
            {{ 'ROUTABLE_MODALS.USER_ACCOUNT.UAM_USER_DETAILS.PROFILE.Timezone' | translate }}
          </div>
          <div
            *ngIf="(is_editing_profile$$ | async) === false; else userProfileFormTimezone"
            class="category-container__properties__prop__value"
          >
            {{ (user?.timezone$$ | async) ?? '--' }}
          </div>

          <ng-template #userProfileFormTimezone>
            <div class="category-container__properties__prop__value">
              <div class="category-container__properties__prop__form">
                <mat-form-field [appearance]="'outline'">
                  <mat-label>{{ 'ROUTABLE_MODALS.USER_ACCOUNT.UAM_USER_DETAILS.PROFILE.Timezone' | translate }}</mat-label>
                  <mtx-select
                    [appendTo]="null"
                    [items]="timezones$$ | async"
                    [formControlName]="'timezone'"
                    [clearable]="false"
                    [searchable]="true"
                  ></mtx-select>
                </mat-form-field>
              </div>
            </div>
          </ng-template>
        </div>
      </ng-container>

      <!-- User's referent -->
      <ng-container *ngIf="is_profile_readable$$ | async">
        <div class="category-container__properties__prop">
          <div class="category-container__properties__prop__name">
            {{ 'ROUTABLE_MODALS.USER_ACCOUNT.UAM_USER_DETAILS.PROFILE.Referent' | translate }}
          </div>

          <div class="category-container__properties__prop__value">
            <ng-container *ngIf="(is_referent_editable$$ | async) ? (is_editing_profile$$ | async) === false : true">
              <ng-container *ngIf="user?.created_by_user$$ | async; let created_by; else: emptyValue">
                <a
                  [routerLink]="['', { outlets: { modal: ['user_account', { user_id: created_by.user_id$$ | async }] } }]"
                  [queryParamsHandling]="'preserve'"
                  (click)="$event.stopPropagation()"
                >
                  {{ (created_by?.name$$ | async) ?? '--' }}
                </a>
              </ng-container>
            </ng-container>

            <ng-container *ngIf="(is_referent_editable$$ | async) && (is_editing_profile$$ | async)">
              <div class="category-container__properties__prop__form">
                <mat-form-field [appearance]="'outline'">
                  <mat-label>{{ 'ROUTABLE_MODALS.USER_ACCOUNT.UAM_USER_DETAILS.PROFILE.Referent' | translate }}</mat-label>
                  <bg2-user-select
                    [formControlName]="'referent_id'"
                    [options]="{
                      exclude_ids: [user?.user_id$$ | async],
                      no_impersonate: false
                    }"
                  >
                  </bg2-user-select>
                </mat-form-field>
              </div>
            </ng-container>
          </div>
        </div>
      </ng-container>

      <!-- User's parameters -->
      <ng-container *ngIf="is_profile_readable$$ | async">
        <div class="category-container__properties__prop --only-button">
          <div class="category-container__properties__prop__name">
            {{ 'ROUTABLE_MODALS.USER_ACCOUNT.UAM_USER_DETAILS.PROFILE.Parameters' | translate }}
          </div>

          <div class="category-container__properties__prop__value">
            <bg2-button
              [type]="'navigation'"
              [icon]="'mdi-cog'"
              [options]="{ is_thin: true }"
              [message]="'ROUTABLE_MODALS.USER_ACCOUNT.UAM_USER_DETAILS.PROFILE.Parameters' | translate"
              [disabled]="
                (is_editing_profile$$ | async) ||
                (security_manager.is_editing$$ | async) ||
                (is_editing_password$$ | async) ||
                (is_editing_crm$$ | async)
              "
              [internal_routing]="{
                routerLink: ['', { outlets: { modal: ['user_settings', { user_id: user?.user_id$$ | async }] } }],
                queryParamsHandling: 'preserve'
              }"
            ></bg2-button>
          </div>
        </div>
      </ng-container>
    </div>
  </div>

  <!-- Security container -->
  <ng-container *ngIf="is_profile_readable$$ | async">
    <div class="category-container">
      <span class="category-container__title">
        <span class="category-container__title__text">
          {{ 'ROUTABLE_MODALS.USER_ACCOUNT.UAM_USER_DETAILS.SECURITY.Title' | translate }}
        </span>

        <div *ngIf="is_security_editable$$ | async" class="category-container__title__actions">
          <bg2-button
            *ngIf="(security_manager.is_editing$$ | async) === false"
            [type]="'action'"
            [icon]="'mdi-pencil'"
            [options]="{ is_thin: true }"
            [message]="'ALL.ACTIONS.Edit' | translate"
            [disabled]="(is_editing_profile$$ | async) || (is_editing_password$$ | async) || (is_editing_crm$$ | async)"
            (btn_click)="security_manager.edit()"
          ></bg2-button>

          <ng-container *ngIf="(security_manager.is_editing$$ | async) === true">
            <bg2-button
              [type]="'cancel'"
              [icon]="'mdi-cancel'"
              [options]="{ is_thin: true }"
              [message]="'ALL.ACTIONS.Cancel' | translate"
              (btn_click)="security_manager.cancel()"
            ></bg2-button>

            <bg2-button
              [type]="'action'"
              [icon]="'mdi-content-save'"
              [message]="'ALL.ACTIONS.Save' | translate"
              [options]="{ is_thin: true }"
              (btn_click)="save_security_data()"
            ></bg2-button>
          </ng-container>
        </div>
      </span>

      <div
        class="category-container__properties"
        [ngClass]="{ '--is-editing': (security_manager.is_editing$$ | async) }"
        [formGroup]="security_manager.form"
      >
        <div *ngIf="(is_editing_profile$$ | async) || (is_editing_crm$$ | async)" class="category-container__properties__disabled"></div>

        <!-- User creation time -->
        <div class="category-container__properties__prop">
          <div class="category-container__properties__prop__name">
            {{ 'ROUTABLE_MODALS.USER_ACCOUNT.UAM_USER_DETAILS.SECURITY.Created on' | translate }}
          </div>

          <div *ngLet="" class="category-container__properties__prop__value">
            <ng-container *ngIf="user?.creation_time$$ | async; let creation_time; else: emptyValue">
              {{ creation_time | formatDate : 'full' }}
            </ng-container>
          </div>
        </div>

        <!-- User last update time -->
        <div class="category-container__properties__prop">
          <div class="category-container__properties__prop__name">
            {{ 'ROUTABLE_MODALS.USER_ACCOUNT.UAM_USER_DETAILS.SECURITY.Last update' | translate }}
          </div>

          <div class="category-container__properties__prop__value">
            <ng-container *ngIf="user?.update_time$$ | async; let update_time; else: emptyValue">
              {{ update_time | formatDate : 'full' }}
            </ng-container>
          </div>
        </div>

        <!-- User last login -->
        <div class="category-container__properties__prop">
          <div class="category-container__properties__prop__name">
            {{ 'ROUTABLE_MODALS.USER_ACCOUNT.UAM_USER_DETAILS.SECURITY.Last login' | translate }}
          </div>

          <div class="category-container__properties__prop__value">
            <ng-container *ngIf="user?.last_login$$ | async; let last_login; else: emptyValue">
              {{ last_login | formatDate : 'full' }}
            </ng-container>
          </div>
        </div>

        <!-- User disabled -->
        <div *ngIf="is_disabled_account_editable$$ | async" class="category-container__properties__prop">
          <div class="category-container__properties__prop__name">
            {{ 'ROUTABLE_MODALS.USER_ACCOUNT.UAM_USER_DETAILS.SECURITY.Disabled account' | translate }}
          </div>

          <div class="category-container__properties__prop__value">
            <ng-container *ngIf="(security_manager.is_editing$$ | async) === false; else userDisabledForm">
              {{ (user?.disabled$$ | async) ? ('ALL.COMMON.Yes' | translate) : ('ALL.COMMON.No' | translate) }}
            </ng-container>

            <ng-template #userDisabledForm>
              <mat-slide-toggle [color]="'primary'" [formControlName]="'disabled'"></mat-slide-toggle>
            </ng-template>
          </div>
        </div>

        <!-- User tags -->
        <div *ngIf="is_viewer_superadmin$$ | async" class="category-container__properties__prop">
          <div class="category-container__properties__prop__name">
            {{ 'ROUTABLE_MODALS.USER_ACCOUNT.UAM_USER_DETAILS.SECURITY.Tags' | translate }}
          </div>

          <div class="category-container__properties__prop__value">
            <ng-container *ngIf="(security_manager.is_editing$$ | async) === false; else userTagsForm">
              <span *ngIf="(user?.tags$$ | async)?.length === 0">--</span>

              <span *ngFor="let tag of user?.tags$$ | async">
                {{ get_i18n_for_tag(tag.name) | translate }}
              </span>
            </ng-container>

            <ng-template #userTagsForm>
              <mat-form-field [appearance]="'outline'">
                <mtx-select
                  [appendTo]="null"
                  [multiple]="true"
                  [clearable]="false"
                  [searchable]="true"
                  [bindValue]="'value'"
                  [bindLabel]="'label'"
                  [items]="user_tags_list"
                  [formControlName]="'tags'"
                >
                  <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                    <div class="ng-value" *ngFor="let item of items | slice : 0 : 2">
                      <span class="ng-value-label">{{ $any(item).label | translate }}</span>
                      <span class="ng-value-icon right" (click)="clear(item)" aria-hidden="true">×</span>
                    </div>

                    <div class="ng-value" *ngIf="items.length > 2">
                      <span class="ng-value-label">{{ items.length - 2 }} more...</span>
                    </div>
                  </ng-template>

                  <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
                    <span>{{ item?.label | translate }}</span>
                  </ng-template>
                </mtx-select>
              </mat-form-field>
            </ng-template>
          </div>
        </div>

        <!-- Superadmin account -->
        <div class="category-container__properties__prop">
          <div class="category-container__properties__prop__name">
            {{ 'ROUTABLE_MODALS.USER_ACCOUNT.UAM_USER_DETAILS.SECURITY.Administrator' | translate }}
          </div>

          <div class="category-container__properties__prop__value">
            <div class="category-container__properties__prop__value__with-comment">
              <div
                *ngIf="
                  (security_manager.is_editing$$ | async) === false || (is_viewer_superadmin$$ | async) === false;
                  else userAdministratorAccountUpdate
                "
              >
                {{ (user?.is_superadmin$$ | async) ? ('ALL.COMMON.Yes' | translate) : ('ALL.COMMON.No' | translate) }}
              </div>

              <ng-template #userAdministratorAccountUpdate>
                <mat-slide-toggle [color]="'primary'" [formControlName]="'is_administrator_account'"></mat-slide-toggle>
              </ng-template>
            </div>
          </div>
        </div>

        <!-- Public account -->
        <div class="category-container__properties__prop">
          <div class="category-container__properties__prop__name">
            {{ 'ROUTABLE_MODALS.USER_ACCOUNT.UAM_USER_DETAILS.SECURITY.Public account' | translate }}
          </div>

          <div class="category-container__properties__prop__value">
            <div class="category-container__properties__prop__value__with-comment">
              <div *ngIf="(security_manager.is_editing$$ | async) === false; else userPublicAccountUpdate">
                {{ (user?.is_public_account$$ | async) ? ('ALL.COMMON.Yes' | translate) : ('ALL.COMMON.No' | translate) }}
              </div>

              <ng-template #userPublicAccountUpdate>
                <mat-slide-toggle [color]="'primary'" [formControlName]="'is_public_account'"></mat-slide-toggle>
              </ng-template>

              <div class="category-container__properties__prop__value__with-comment__comment">
                {{ 'ROUTABLE_MODALS.USER_ACCOUNT.UAM_USER_DETAILS.SECURITY.A public account can not modify it self' | translate }}
              </div>
            </div>
          </div>
        </div>

        <!-- User rights -->
        <div class="category-container__properties__prop">
          <div class="category-container__properties__prop__name">
            {{ 'ROUTABLE_MODALS.USER_ACCOUNT.UAM_USER_DETAILS.SECURITY.Rights' | translate }}
          </div>

          <div class="category-container__properties__prop__value">
            <ng-container *ngIf="(security_manager.is_editing$$ | async) === false; else userScopesUpdate">
              <div class="category-container__properties__prop__value__scopes-container">
                <div *ngLet="user?.can_create_subusers$$ | async; let can_create_subusers">
                  <span
                    class="mdi"
                    [ngClass]="{
                      'mdi-check-bold': can_create_subusers,
                      'mdi-close-thick': !can_create_subusers
                    }"
                  ></span>

                  <span>{{ 'MODELS.USER.SCOPES.user:write_subusers' | translate }}</span>
                </div>
              </div>
            </ng-container>
          </div>

          <ng-template #userScopesUpdate>
            <div class="category-container__properties__prop__form">
              <mat-slide-toggle [color]="'primary'" [formControlName]="'can_create_users'">
                {{ 'ROUTABLE_MODALS.USER_ACCOUNT.UAM_USER_DETAILS.PROFILE.Can create users' | translate }}
              </mat-slide-toggle>
            </div>
          </ng-template>
        </div>

        <!-- User password -->
        <div
          class="category-container__properties__prop"
          [ngClass]="{ '--only-button': (is_editing_password$$ | async) === false }"
          [ngStyle]="{ height: (is_editing_password$$ | async) ? 'auto' : '60px' }"
        >
          <div class="category-container__properties__prop__name">
            {{ 'ROUTABLE_MODALS.USER_ACCOUNT.UAM_USER_DETAILS.SECURITY.Password' | translate }}
          </div>

          <div
            *ngIf="(is_editing_password$$ | async) === false; else userPasswordUpdate"
            class="category-container__properties__prop__value"
          >
            <bg2-button
              [type]="'action'"
              [icon]="(is_password_editable$$ | async) ? 'mdi-pencil' : 'mdi-lock'"
              [options]="{ is_thin: true }"
              [message]="'ALL.ACTIONS.Change password' | translate"
              [disabled]="
                (is_password_editable$$ | async) === false ||
                (is_editing_profile$$ | async) ||
                (security_manager.is_editing$$ | async) ||
                (is_editing_crm$$ | async)
              "
              (btn_click)="edit_user_password()"
            ></bg2-button>
          </div>

          <ng-template #userPasswordUpdate>
            <div class="category-container__properties__prop__value">
              <form class="category-container__properties__prop__form" [formGroup]="password_form">
                <mat-form-field [appearance]="'outline'" hidden>
                  <mat-label>{{ 'ROUTABLE_MODALS.USER_ACCOUNT.UAM_USER_DETAILS.SECURITY.Current password' | translate }}</mat-label>
                  <input matInput type="email" autocomplete="email" [formControlName]="'email'" />
                </mat-form-field>

                <mat-form-field [appearance]="'outline'" [attr.hidden]="(is_viewer_superadmin$$ | async) ? '' : null">
                  <mat-label>{{ 'ROUTABLE_MODALS.USER_ACCOUNT.UAM_USER_DETAILS.SECURITY.Current password' | translate }}</mat-label>
                  <input
                    matInput
                    [type]="show_password.current ? 'text' : 'password'"
                    autocomplete="current-password"
                    [formControlName]="'current_password'"
                  />
                  <mat-icon matSuffix (click)="show_password.current = !show_password.current">
                    {{ show_password.current ? 'visibility_off' : 'visibility' }}
                  </mat-icon>
                </mat-form-field>

                <mat-form-field [appearance]="'outline'">
                  <mat-label>{{ 'ROUTABLE_MODALS.USER_ACCOUNT.UAM_USER_DETAILS.SECURITY.New password' | translate }}</mat-label>
                  <input
                    matInput
                    autocomplete="new-password"
                    [formControlName]="'new_password'"
                    [type]="show_password.new ? 'text' : 'password'"
                  />
                  <mat-icon matSuffix (click)="show_password.new = !show_password.new">
                    {{ show_password.new ? 'visibility_off' : 'visibility' }}
                  </mat-icon>
                </mat-form-field>

                <mat-form-field [appearance]="'outline'">
                  <mat-label>{{ 'ROUTABLE_MODALS.USER_ACCOUNT.UAM_USER_DETAILS.SECURITY.Confirm new password' | translate }}</mat-label>
                  <input
                    matInput
                    autocomplete="new-password"
                    [formControlName]="'confirm_password'"
                    [type]="show_password.confirm ? 'text' : 'password'"
                  />
                  <mat-icon matSuffix (click)="show_password.confirm = !show_password.confirm">
                    {{ show_password.confirm ? 'visibility_off' : 'visibility' }}
                  </mat-icon>

                  <ng-container *ngIf="password_form.get('confirm_password').errors; let errors">
                    <mat-error *ngIf="errors?.matchedValue">
                      {{ 'ALL.ERROR.FORM.The password does not match the new password' | translate }}.
                    </mat-error>
                  </ng-container>
                </mat-form-field>

                <div class="category-container__properties__prop__form__action">
                  <bg2-button
                    [type]="'cancel'"
                    [icon]="'mdi-cancel'"
                    [options]="{ is_thin: true }"
                    [message]="'ALL.ACTIONS.Cancel' | translate"
                    (btn_click)="cancel_user_password()"
                  ></bg2-button>

                  <bg2-button
                    [type]="'action'"
                    [btn_type]="'submit'"
                    [icon]="'mdi-content-save'"
                    [message]="'ALL.ACTIONS.Save' | translate"
                    [disabled]="password_form?.valid === false"
                    [options]="{ is_thin: true }"
                    (btn_click)="save_user_password()"
                  ></bg2-button>
                </div>
              </form>
            </div>
          </ng-template>
        </div>
      </div>
    </div>
  </ng-container>

  <!-- CRM container -->
  <ng-container *ngIf="is_viewer_superadmin$$ | async">
    <div class="category-container">
      <span class="category-container__title">
        <span class="category-container__title__text">
          {{ 'ROUTABLE_MODALS.USER_ACCOUNT.UAM_USER_DETAILS.CRM_PROFILE.Title' | translate }}
        </span>

        <div class="category-container__title__actions">
          <bg2-button
            *ngIf="(is_editing_crm$$ | async) === false"
            [type]="'action'"
            [icon]="'mdi-pencil'"
            [options]="{ is_thin: true }"
            [message]="'ALL.ACTIONS.Edit' | translate"
            [disabled]="(is_editing_profile$$ | async) || (security_manager.is_editing$$ | async) || (is_editing_password$$ | async)"
            (btn_click)="edit_crm_profile()"
          ></bg2-button>

          <ng-container *ngIf="is_editing_crm$$ | async">
            <bg2-button
              [type]="'cancel'"
              [icon]="'mdi-cancel'"
              [message]="'ALL.ACTIONS.Cancel' | translate"
              [options]="{ is_thin: true }"
              (btn_click)="cancel_crm_profile()"
            ></bg2-button>

            <bg2-button
              [type]="'action'"
              [icon]="'mdi-content-save'"
              [options]="{ is_thin: true }"
              [message]="'ALL.ACTIONS.Save' | translate"
              [disabled]="(crm_form_valid$$ | async) === false"
              (btn_click)="save_crm_profile()"
            ></bg2-button>
          </ng-container>
        </div>
      </span>

      <div class="category-container__properties" [ngClass]="{ '--is-editing': (is_editing_crm$$ | async) }">
        <div
          *ngIf="(is_editing_profile$$ | async) || (security_manager.is_editing$$ | async) || (is_editing_password$$ | async)"
          class="category-container__properties__disabled"
        ></div>

        <!-- User's CRM ID -->
        <div class="category-container__properties__prop">
          <div class="category-container__properties__prop__name">
            {{ 'ROUTABLE_MODALS.USER_ACCOUNT.UAM_USER_DETAILS.CRM_PROFILE.Zoho CRM' | translate }}
          </div>

          <div *ngIf="(is_editing_crm$$ | async) === false; else userCRMFormID" class="category-container__properties__prop__value">
            <a
              *ngIf="user?.crm_link$$ | async; let crm_link; else: emptyValue"
              [href]="crm_link"
              target="_blank"
              (click)="$event.stopPropagation()"
            >
              {{ 'VIEWS.USER.COMMON.CRM account' | translate }}
            </a>
          </div>

          <ng-template #userCRMFormID>
            <div class="category-container__properties__prop__value">
              <div class="category-container__properties__prop__form">
                <bg2-form-overlay
                  [source_schema]="crm_form_schema"
                  [source_model]="crm_form_model$$ | async"
                  (when_form_valid_update)="is_crm_form_valid = $event"
                  (when_form_data_update)="when_crm_form_data_update($event)"
                ></bg2-form-overlay>
              </div>
            </div>
          </ng-template>
        </div>
      </div>
    </div>
  </ng-container>

  <!-- User tools -->
  <ng-container *ngIf="is_viewer_superadmin$$ | async">
    <div class="category-container">
      <span class="category-container__title">
        <span class="category-container__title__text">
          {{ 'ROUTABLE_MODALS.USER_ACCOUNT.UAM_USER_DETAILS.USER_TOOLS.Title' | translate }}
        </span>
      </span>

      <div class="category-container__properties">
        <!-- User's last used hardware/software -->
        <div class="category-container__properties__prop">
          <div class="category-container__properties__prop__name">
            {{ 'ROUTABLE_MODALS.USER_ACCOUNT.UAM_USER_DETAILS.USER_TOOLS.Last used Hw/Sw' | translate }}
          </div>

          <div class="category-container__properties__prop__value">
            <a
              [href]="
                'https://datastudio.google.com/u/1/reporting/05fcb171-0d2f-4e85-81de-b1e337258588/page/co7sC?params=%7B%22dp12%22:%22a120239518w297389434%22,%22df9%22:%22include%25EE%2580%25800%25EE%2580%2580EQ%25EE%2580%2580' +
                (user?.user_id$$ | async) +
                '%22%7D'
              "
              target="_blank"
              (click)="$event.stopPropagation()"
            >
              {{ 'ALL.COMMON.Link to Looker Studio' | translate }}
            </a>
          </div>
        </div>

        <!-- User's clarity -->
        <div *ngIf="env?.analytics?.clarity !== null" class="category-container__properties__prop">
          <div class="category-container__properties__prop__name">
            {{ 'ROUTABLE_MODALS.USER_ACCOUNT.UAM_USER_DETAILS.USER_TOOLS.Microsoft Clarity' | translate }}
          </div>

          <div class="category-container__properties__prop__value">
            <a
              [href]="
                'https://clarity.microsoft.com/projects/view/' +
                env.analytics.clarity +
                '/dashboard?Variables=app_user_id%3A' +
                (user?.user_id$$ | async) +
                '%2Capp_version%3A' +
                env.version +
                '&date=Last%207%20days'
              "
              target="_blank"
              (click)="$event.stopPropagation()"
            >
              {{ 'ALL.COMMON.Go to website' | translate }}
            </a>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</div>

<ng-template #emptyValue>--</ng-template>
