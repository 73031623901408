<div class="carousel event-based-carousel">
  <div
    class="carousel-viewport"
    [class.no-data]="(has_carousel_slides$$ | async) === false"
    (swipeleft)="loadNext()"
    (swiperight)="loadPrevious()"
  >
    <div
      class="carousel-slider"
      [style.transform]="carousel_translate_x$$ | async"
      [style.transition]="(transition$$ | async) ? 'all 1s' : 'none'"
      #slider
    >
      <ng-container #container></ng-container>
    </div>
  </div>

  <ng-container *ngIf="loadings.events$$ | async">
    <bg2-loading-skeleton [theme]="{ height: '100%' }"></bg2-loading-skeleton>
  </ng-container>

  <div
    [ngStyle]="{ display: (has_factice_slide$$ | async) && (loadings.events$$ | async) === false ? 'block' : 'none' }"
    class="carousel-empty-container"
  >
    <bg2-error-helper [error]="error$$ | async"></bg2-error-helper>
    <pre>{{ error$$ | async | json }}</pre>
  </div>

  <div class="carousel-navigation">
    <div class="previous" [class.disabled]="(has_previous_slide$$ | async) === false" (click)="loadPrevious()">
      <span class="mdi mdi-arrow-left"></span>
      <span>{{ label_previous$$ | async | translate }}</span>
    </div>

    <ng-container *ngIf="has_next_slide$$ | async; else createEventTag">
      <div class="next" (click)="loadNext()">
        <span>{{ label_next$$ | async | translate }}</span>
        <span class="mdi mdi-arrow-right"></span>
      </div>
    </ng-container>
  </div>
</div>

<ng-template #fakeDivTag>
  <div></div>
</ng-template>

<ng-template #createEventTag>
  <ng-container [ngSwitch]="event_name$$ | async">
    <ng-container *ngIf="can_create_event$$ | async">
      <bg2-button
        *ngSwitchCase="'evaluation'"
        [type]="'action'"
        [color]="'breeding-register'"
        [icon]="'mdi-plus-circle-outline'"
        [options]="{ is_thin: true, is_label_hidden_on_small: true }"
        [message]="'EVENT.VISIT.Add visit' | translate"
        [disabled]="(has_factice_slide$$ | async) || (loadings.events$$ | async)"
        [internal_routing]="{
          queryParamsHandling: 'preserve',
          routerLink: ['', { outlets: { modal: ['apiary_evaluation', { aid: entity_id$$ | async }] } }]
        }"
      ></bg2-button>
      
      <bg2-button
        *ngSwitchCase="'superbox_harvest'"
        [type]="'action'"
        [icon]="'mdi-plus-circle-outline'"
        [options]="{ is_thin: true, is_label_hidden_on_small: true }"
        [message]="'EVENT.SUPERBOX_HARVEST.Harvest supers' | translate"
        [disabled]="(has_factice_slide$$ | async) || (loadings.events$$ | async)"
        [internal_routing]="{
          queryParamsHandling: 'preserve',
          routerLink: [
            '',
            { outlets: { modal: ['new_event', { etype: 'superbox_harvest', args: ({ apiary: entity_id$$ | async } | json) }] } }
          ]
        }"
      ></bg2-button>
    </ng-container>

  </ng-container>
</ng-template>
