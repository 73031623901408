// From ~/node_modules/@angular/*
import { Injectable } from '@angular/core';

// From ~/node_modules/rxjs/*
import { Observable, of, Subscriber, combineLatest, concat, from } from 'rxjs';
import { tap, map, switchMap, delay } from 'rxjs';

// From ~/node_modules/lodash/*
import { isNil } from 'lodash-es';

// From ~/app/core/*
import { GoogleMapsLoader } from 'app/misc/services/google-maps-loader.service';

// From ~/app/misc/*
import { ConsoleLoggerService } from 'app/core/console-logger.service';
import { ApiCache } from 'app/core/api/misc/api-cache';
import { replay } from '../tools';

declare const google: any;

const filteredGeocodingLevelTypes = {
  street_address: '',
  route: '',
  locality: '',
  country: '',
};
// We do ot keep all google level, see:
// https://developers.google.com/maps/documentation/javascript/geocoding#GeocodingAddressTypes
export type GeocodingLevelTypes = keyof typeof filteredGeocodingLevelTypes;
// ^ trick to get union type with runtime type checking see https://stackoverflow.com/a/43621735

export interface GeocodingOptions {
  start_with_null?: boolean;
}

export interface GeocodingReverseOptions extends GeocodingOptions {
  lang: string;
  level?: GeocodingLevelTypes[];
}

export type GeocodingElevationOptions = GeocodingOptions;

export type GeocodingRawResults = google.maps.GeocoderResult[];
export type GeocodigReverseResults = {
  order: GeocodingLevelTypes[];
  values: {
    [key in GeocodingLevelTypes]?: google.maps.GeocoderResult;
  };
};

interface LatLng {
  lat: number;
  lng: number;
}

/**
 * Constant which refers to the name of the cache in local storage.
 */
const GEOCODING_STORAGE_NAME = 'geocoding_cache_v002';
const ELEVATION_STORAGE_NAME = 'elevation_cache_v002';

/**
 * Encodes latitude & longitude as key to saves into cache.
 *
 * @param latlng The latitude and longitude to encode.
 * @returns Returns a string from the encoded latitude and longitude.
 */
export const encodeLatLng = (latlng: LatLng): string => `${latlng.lat.toFixed(4)}_${latlng.lng.toFixed(4)}`;

/**
 * Service class to access google geocoding API.
 */
@Injectable({
  providedIn: 'root',
})
export class GeocodingService {
  private readonly _logger = new ConsoleLoggerService(this.constructor.name, false);

  /**
   * Refers to max number of call by second.
   */
  private MAX_CALL_FREQ = 0.5;

  /**
   * Refers to the geocoder instance of Google.
   */
  private geocoder: google.maps.Geocoder = null;

  /**
   * Refers to the geocoder instance of Google.
   */
  private elevator: google.maps.ElevationService = null;

  /**
   * Stores the local cache.
   *
   * @notice Don't forget to load it and save it.
   */
  private cache = new ApiCache<GeocodingRawResults, LatLng>(0, GEOCODING_STORAGE_NAME, encodeLatLng);
  private elevation_cache = new ApiCache<number, LatLng>(0, ELEVATION_STORAGE_NAME, encodeLatLng);

  private service_init$$ = combineLatest([
    this.waitForMapsToLoad(),
    this.cache.loadFromLocalStorage(),
    this.elevation_cache.loadFromLocalStorage(),
  ]).pipe(
    map(() => true),
    replay()
  );

  /**
   * `GeocodingService` class constructor.
   */
  constructor() {}

  /**
   * Waits for google maps to loaded since we need it's API.
   *
   * @returns Returns an observable on succes load as `boolean`.
   */
  private waitForMapsToLoad(): Observable<boolean> {
    if (!this.geocoder) {
      return from(GoogleMapsLoader.load()).pipe(
        tap(() => {
          // console.log('[debug] gmaps loaded !');
          if (isNil(this.geocoder)) {
            this.geocoder = new google.maps.Geocoder();
            this.elevator = new google.maps.ElevationService();
          }
        }),
        map(() => true)
      );
    }
    return of(true);
  }

  public geocode(address: string): any {}

  /**
   * Compute the physical addresses from latitudes and longitudse.
   *
   * @param latlngs The latitudes and longitudes to use to find the addresses.
   * @param options The options to compute the physical address like language.
   * @returns Returns an observable on physical addresses.
   */
  public reverse(latlngs: LatLng[], options: GeocodingReverseOptions = { lang: 'fr' }): Observable<GeocodigReverseResults[]> {
    return this.service_init$$.pipe(
      switchMap(() => {
        const all_reverse = latlngs.map(latlng => this.reverseOne(latlng, options));
        return combineLatest(all_reverse);
      }),
      tap(() => this.cache.saveToLocalStorage())
    );
  }

  private last_call_time = 0;

  /**
   * Keep trace of last API call and wait to ensure a certain max api call frequency.
   *
   * @param args Any arguments to pass to the handler.
   * @returns Returns an observable on the specified `T`.
   */
  private waitForApiCall<T>(args: T): Observable<T> {
    const time_now = Date.now();
    const tdelta = time_now - this.last_call_time;
    const min_period = 1000 / this.MAX_CALL_FREQ;
    // console.log('[debug] Wait !!' + tdelta);
    if (tdelta < min_period) {
      const wait_time = min_period - tdelta + Math.random() * 100;
      // console.log('[debug] wait_time:' + wait_time);
      return of(args).pipe(
        delay(wait_time),
        // tap(() => console.log('[debug] Wait again')),
        switchMap(_args => this.waitForApiCall(_args))
      );
    } else {
      // console.log('[debug] query !!');
      this.last_call_time = time_now;
      return of(args);
    }
  }

  private reverseOneRaw(latlng: LatLng, options: GeocodingReverseOptions): Observable<GeocodingRawResults> {
    const start_with_null = options?.start_with_null || false;
    if (this.cache.has(latlng)) {
      // console.log(`[debug] -> used cache for "${latlng}"`);
      return of(this.cache.get(latlng));
    }
    let delayed_api_call = this.waitForApiCall({ latlng, options }).pipe(
      switchMap(args => this._reverseApiCall(args.latlng, args.options))
    );
    if (start_with_null) {
      delayed_api_call = concat(of(null), delayed_api_call);
    }
    return delayed_api_call;
  }

  /**
   * Make an API call (or uses cache) to fetch a physical address.
   *
   * @param latlng The latitude and longitude to use to find the physical address.
   * @param options The options for the API request.
   * @returns Returns an observable on the physical address.
   */
  private reverseOne(latlng: LatLng, options: GeocodingReverseOptions): Observable<GeocodigReverseResults> {
    return this.reverseOneRaw(latlng, options).pipe(
      map(results => {
        this._logger.debug('Get geocode reverse addresses results:', results);
        const res: GeocodigReverseResults = {
          order: [],
          values: {},
        };
        // Filter and format results
        if (results && results.length !== 0) {
          for (const one_res of results) {
            if (one_res.types) {
              for (const level of one_res.types) {
                if (
                  filteredGeocodingLevelTypes.hasOwnProperty(level) &&
                  (isNil(options.level) || options.level.includes(level as GeocodingLevelTypes))
                ) {
                  res.order.push(level as GeocodingLevelTypes);
                  res.values[level as GeocodingLevelTypes] = one_res;
                }
              }
            }
          }
        }
        return res;
      })
    );
  }

  /**
   * Actual call to google Api with no cache nor sequencing.
   *
   * @param latlngs The latitudes and longitudes to use to find the addresses.
   * @param options The options to compute the physical address like language.
   * @returns Returns the physical address belonging to latitude and longitude.
   */
  private _reverseApiCall(latlng: LatLng, options: { lang: string }): Observable<GeocodingRawResults> {
    return new Observable<GeocodingRawResults>((subscriber: Subscriber<GeocodingRawResults>) => {
      this.geocoder.geocode(
        { location: latlng, region: options.lang },
        (results: google.maps.GeocoderResult[], status: google.maps.GeocoderStatus) => {
          switch (status) {
            case google.maps.GeocoderStatus.ERROR:
            case google.maps.GeocoderStatus.INVALID_REQUEST:
            case google.maps.GeocoderStatus.UNKNOWN_ERROR:
            case google.maps.GeocoderStatus.REQUEST_DENIED: {
              subscriber.error(`An error occured in geocoding, reason is: ${status}`);
              break;
            }

            case google.maps.GeocoderStatus.OVER_QUERY_LIMIT: {
              subscriber.error(`An error occured in geocoding, there was too many calls to the API !`);
              break;
            }

            case google.maps.GeocoderStatus.OK: {
              this._logger.debug('got: ', results);
              this.cache.add(latlng, results);
              subscriber.next(results);
              subscriber.complete();
              break;
            }

            default:
            case google.maps.GeocoderStatus.ZERO_RESULTS: {
              subscriber.next([]);
              subscriber.complete();
              break;
            }
          }
        }
      );
    });
  }

  public elevation(latlng: LatLng, options: GeocodingElevationOptions): Observable<number> {
    return this.service_init$$.pipe(
      switchMap(() => this._elevation(latlng, options)),
      tap(() => this.elevation_cache.saveToLocalStorage())
    );
  }

  private _elevation(latlng: LatLng, options: GeocodingElevationOptions): Observable<number> {
    const start_with_null = options?.start_with_null || false;
    if (this.elevation_cache.has(latlng)) {
      // console.log(`[debug] -> used cache for "${latlng}"`);
      return of(this.elevation_cache.get(latlng));
    }
    let delayed_api_call = this.waitForApiCall({ latlng, options }).pipe(switchMap(args => this._oneElevationApiCall(args.latlng)));
    if (start_with_null) {
      delayed_api_call = concat(of(null), delayed_api_call);
    }
    return delayed_api_call;
  }

  private _oneElevationApiCall(latlng: LatLng): Observable<number> {
    const req = new Observable<number>(subscriber => {
      this.elevator.getElevationForLocations(
        {
          locations: [latlng],
        },
        (results: any, status: any) => {
          if (status === 'OK') {
            // Retrieve the first result
            if (results[0]) {
              // Open the infowindow indicating the elevation at the clicked position.
              const val = Math.round(results[0].elevation);
              this.elevation_cache.add(latlng, val);
              subscriber.next(val);
              subscriber.complete();
            } else {
              console.log('No elevation found');
              subscriber.error('No elevation found');
            }
          } else {
            console.log('Elevation service failed due to: ' + status);
            subscriber.error('Elevation service failed due to: ' + status);
          }
        }
      );
    });
    return req;
  }
}
