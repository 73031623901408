<ng-container *ngIf="visible$$ | async">
  <div class="checklist-widget" [class.indent]="options.indent">
    <form-element-header [schema]="schema" [title_style]="options.title_style"></form-element-header>

    <ul
      *ngIf="items && items.length"
      class="checklist-container"
      [class.buttons]="options.display === 'button'"
      [ngStyle]="container_style"
    >
      <li
        class="checkbox-container"
        *ngFor="let item of items"
        [class.checked]="item.value"
        [class.disabled]="item.disabled"
        [ngStyle]="options?.for_each_item?.styles"
      >
        <mat-checkbox
          id="checkbox-{{id}}-{{item.label}}"
          color="primary"
          [ngModel]="item.value$$ | async"
          [disabled]="item.disabled || (item.value && (nb_true$$ | async) <= minItems)"
          (ngModelChange)="onChange(item, $event)"
        >
          <img *ngIf="item.image" src="assets/{{item.image}}" [ngStyle]="item.image_styles" />

          <div>{{item.label | translate}}</div>
        </mat-checkbox>
      </li>
    </ul>
  </div>
</ng-container>
