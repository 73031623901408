import { HttpStatusCode } from '@angular/common/http';

import { marker as i18n } from '@biesbjerg/ngx-translate-extract-marker';

type HttpErrorStatusCode = Exclude<
  HttpStatusCode,
  | HttpStatusCode.Continue
  | HttpStatusCode.SwitchingProtocols
  | HttpStatusCode.Processing
  | HttpStatusCode.EarlyHints
  | HttpStatusCode.Ok
  | HttpStatusCode.Created
  | HttpStatusCode.Accepted
  | HttpStatusCode.NonAuthoritativeInformation
  | HttpStatusCode.NoContent
  | HttpStatusCode.ResetContent
  | HttpStatusCode.PartialContent
  | HttpStatusCode.MultiStatus
  | HttpStatusCode.AlreadyReported
  | HttpStatusCode.ImUsed
  | HttpStatusCode.MultipleChoices
  | HttpStatusCode.MovedPermanently
  | HttpStatusCode.Found
  | HttpStatusCode.SeeOther
  | HttpStatusCode.NotModified
  | HttpStatusCode.UseProxy
  | HttpStatusCode.Unused
  | HttpStatusCode.TemporaryRedirect
  | HttpStatusCode.PermanentRedirect
>;

const http_status_code_to_i18n: { [key in HttpErrorStatusCode]: string } = {
  '400': i18n<string>('ALL.ERROR.HTTP.TYPE.BAD_REQUEST'),
  '401': i18n<string>('ALL.ERROR.HTTP.TYPE.UNAUTHORIZED'),
  '402': i18n<string>('ALL.ERROR.HTTP.TYPE.PAYMENT_REQUIRED'),
  '403': i18n<string>('ALL.ERROR.HTTP.TYPE.FORBIDDEN'),
  '404': i18n<string>('ALL.ERROR.HTTP.TYPE.NOT_FOUND'),
  '405': i18n<string>('ALL.ERROR.HTTP.TYPE.METHOD_NOT_ALLOWED'),
  '406': i18n<string>('ALL.ERROR.HTTP.TYPE.NOT_ACCEPTABLE'),
  '407': i18n<string>('ALL.ERROR.HTTP.TYPE.PROXY_AUTHENTICATION_REQUIRED'),
  '408': i18n<string>('ALL.ERROR.HTTP.TYPE.REQUEST_TIMEOUT'),
  '409': i18n<string>('ALL.ERROR.HTTP.TYPE.CONFLICT'),
  '410': i18n<string>('ALL.ERROR.HTTP.TYPE.GONE'),
  '411': i18n<string>('ALL.ERROR.HTTP.TYPE.LENGTH_REQUIRED'),
  '412': i18n<string>('ALL.ERROR.HTTP.TYPE.PRECONDITION_FAILED'),
  '413': i18n<string>('ALL.ERROR.HTTP.TYPE.PAYLOAD_TOO_LARGE'),
  '414': i18n<string>('ALL.ERROR.HTTP.TYPE.URI_TOO_LONG'),
  '415': i18n<string>('ALL.ERROR.HTTP.TYPE.UNSUPPORTED_MEDIA_TYPE'),
  '416': i18n<string>('ALL.ERROR.HTTP.TYPE.RANGE_NOT_SATISFIABLE'),
  '417': i18n<string>('ALL.ERROR.HTTP.TYPE.EXPECTATION_FAILED'),
  '418': i18n<string>('ALL.ERROR.HTTP.TYPE.IM_A_TEAPOT'),
  '421': i18n<string>('ALL.ERROR.HTTP.TYPE.MISDIRECTED_REQUEST'),
  '422': i18n<string>('ALL.ERROR.HTTP.TYPE.UNPROCESSABLE_ENTITY'),
  '423': i18n<string>('ALL.ERROR.HTTP.TYPE.LOCKED'),
  '424': i18n<string>('ALL.ERROR.HTTP.TYPE.FAILED_DEPENDENCY'),
  '425': i18n<string>('ALL.ERROR.HTTP.TYPE.TOO_EARLY'),
  '426': i18n<string>('ALL.ERROR.HTTP.TYPE.UPGRADE_REQUIRED'),
  '428': i18n<string>('ALL.ERROR.HTTP.TYPE.PRECONDITION_REQUIRED'),
  '429': i18n<string>('ALL.ERROR.HTTP.TYPE.TOO_MANY_REQUESTS'),
  '431': i18n<string>('ALL.ERROR.HTTP.TYPE.REQUEST_HEADER_FIELDS_TOO_LARGE'),
  '451': i18n<string>('ALL.ERROR.HTTP.TYPE.UNAVAILABLE_FOR_LEGAL_REASONS'),
  '500': i18n<string>('ALL.ERROR.HTTP.TYPE.INTERNAL_SERVER_ERROR'),
  '501': i18n<string>('ALL.ERROR.HTTP.TYPE.NOT_IMPLEMENTED'),
  '502': i18n<string>('ALL.ERROR.HTTP.TYPE.BAD_GATEWAY'),
  '503': i18n<string>('ALL.ERROR.HTTP.TYPE.SERVICE_UNAVAILABLE'),
  '504': i18n<string>('ALL.ERROR.HTTP.TYPE.GATEWAY_TIMEOUT'),
  '505': i18n<string>('ALL.ERROR.HTTP.TYPE.HTTP_VERSION_NOT_SUPPORTED'),
  '506': i18n<string>('ALL.ERROR.HTTP.TYPE.VARAIANT_ALSO_NEGOTIATES'),
  '507': i18n<string>('ALL.ERROR.HTTP.TYPE.INSUFFICIENT_STORAGE'),
  '508': i18n<string>('ALL.ERROR.HTTP.TYPE.LOOP_DETECTED'),
  '510': i18n<string>('ALL.ERROR.HTTP.TYPE.NOT_EXTENDED'),
  '511': i18n<string>('ALL.ERROR.HTTP.TYPE.NETWORK_AUTHENTICATION_REQUIRED'),
};

/** */
export const get_status_code_i18n = (code: HttpErrorStatusCode | number) => http_status_code_to_i18n[code as HttpErrorStatusCode];
