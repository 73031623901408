import { ChangeDetectionStrategy, Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { BehaviorSubject, combineLatest, Observable, of, Subscription, throwError } from 'rxjs';
import { catchError, filter, map, switchMap, take, tap } from 'rxjs';

import { isNil } from 'lodash-es';

import { TranslateService } from '@ngx-translate/core';
import { marker as i18n } from '@biesbjerg/ngx-translate-extract-marker';

import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';
import { ISchema, SchemaValidatorFactory } from 'ngx-schema-form';

import { AppStateService } from 'app/core/app-state.service';
import { Beeguard2Api } from 'app/core';
import { DeviceApi } from 'app/core';
import { Event as ModelEvent, Entity, Location, entityDeserialize } from 'app/models';

import { EventForm } from 'app/widgets/event-form/eventforms.helpers';
import { parseDate, strEnum } from 'app/misc/tools';
import { getDistance } from 'app/misc/tools/geomap';
import { levenshtein_distance } from 'app/misc/tools/maths';
import { distinctUntilRealChanged, replay, robustCombineLatest } from '@bg2app/tools/rxjs';

import { DialogsService } from 'app/widgets/dialogs-modals/dialogs.service';

import {
  LocationNearPosWarnComponent,
  LocationNearPosWarnParams,
} from 'app/widgets/dialogs-modals/location-near-pos-warn/location-near-pos-warn.component';

import { AbstractFormModalComponent } from 'app/widgets/dialogs-modals';
import { ModalArgs, ModalParams } from 'app/widgets/dialogs-modals/abstract-modal.component';
import { endOfDay, isAfter } from 'date-fns/esm';
import { EntitySchema } from 'app/core/api-swagger/beeguard2';

const new_entity_type = strEnum(['location', 'apiary', 'hive']);
type NewEntityType = keyof typeof new_entity_type;

export interface NewEntitytModalArgs extends ModalArgs {
  no_redirect?: any;
  static?: any;
  setup?: any;
}

export interface NewEntityModalParams extends ModalParams {
  etype: NewEntityType;
  args: NewEntitytModalArgs;
}

@AutoUnsubscribe()
@Component({
  selector: 'bg2-new-entity-modal',
  templateUrl: './new-entity.modal.html',
  styleUrls: ['./new-entity.modal.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class NewEntityModal<I extends NewEntityModalParams = NewEntityModalParams>
  extends AbstractFormModalComponent<I>
  implements OnInit, OnDestroy
{
  // #region -> (component basics)

  private data_schema_sub: Subscription = null;

  constructor(
    private _router: Router,
    private _bg2Api: Beeguard2Api,
    private _deviceApi: DeviceApi,
    private _dialogs: DialogsService,
    private _appState: AppStateService,
    private _translate: TranslateService
  ) {
    super();
  }

  ngOnInit(): void {
    // Create a new entity
    // TODO rework this init.
    // one may have a private "_initWithType()" that does all the job (create entity and event) when type is here
    this.entity_type$$
      .pipe(
        map(entity_type => entityDeserialize(this._bg2Api, this._deviceApi, { type: entity_type, static_state: {} })),
        tap(entity => (entity.id = -10000)), // tmp  negatif ID is needed for entity creation, one may have it in the model
        take(1)
      )
      .subscribe({
        next: entity => this._entity$$.next(entity),
      });

    // Create a new setup event
    this.setup_event = new ModelEvent(this._bg2Api);
    this.setup_event.type = `${this.input_params?.etype}_setup`;
    this.setup_event.createOperand(this.input_params?.etype);

    if (this.args?.setup?.date) {
      this.setup_event.date = parseDate(this.args?.setup?.date);
    }

    this.loading = true;

    this.data_schema_sub = this.entity$$
      .pipe(
        filter(entity => !isNil(entity)),
        switchMap(entity => entity?.schema$$),
        switchMap(entity_schema => {
          this.entity_schema = entity_schema;
          EventForm.updateSchemaDefault(this.args.static, this.entity_schema.static_state_schema);
          return EventForm.buildSchema(this.setup_event, this.args.setup);
        })
      )
      .subscribe({
        next: event_schema => {
          this.loading = false;
          this.initial_loading = false;

          this.event_schema = event_schema;
          this.computeFormSchema(this.entity_schema, this.event_schema);
        },
        error: (error: unknown) => {
          this._logger.error(error);

          this.event_schema = null;
          this.entity_schema = null;
        },
      });
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this.data_schema_sub?.unsubscribe();

    if (this.setup_event) {
      this.setup_event.unsubscribe();
      delete this.setup_event;
    }

    if (this._entity$$.getValue()) {
      this._entity$$.getValue().preDestroy();
      this._entity$$.next(null);
    }
  }

  // #endregion

  // #region -> (closing management)

  /** */
  protected handle_event_before_unload(event: BeforeUnloadEvent): void {
    if (this._entity$$.getValue()?.has_changed || this.setup_event?.has_changed) {
      event.returnValue = true;
    }
  }

  public close(raz = false) {
    if (this.is_trying_to_close_modal) {
      return;
    }

    if (this.is_submitting) {
      return;
    }

    if (this._entity$$.getValue()?.has_changed || this.setup_event?.has_changed) {
      this.is_trying_to_close_modal = true;
      this._dialogs
        .confirm(i18n<string>('VIEWS.MODALS.FORM.Abandon the entity creation ?'), {
          onTrueMessage: i18n<string>('VIEWS.MODALS.FORM.Abandon'),
          onFalseMessage: i18n<string>('VIEWS.MODALS.FORM.Continue'),
        })
        .subscribe(agreement => {
          this.is_trying_to_close_modal = false;

          if (agreement) {
            super.close(raz);
          }
        });
    } else {
      super.close(raz);
    }
  }

  public forceClose(results: any = null, raz = false): any {
    super.close(results, raz);
  }

  // #endregion

  // #region -> (incoming parameters)

  public entity_type$$ = this.input_params$$.pipe(
    map(input_params => input_params.etype),
    tap(entity_type => {
      if (!Object.values(new_entity_type).includes(entity_type)) {
        throw new TypeError(`Cannot create a entity of type: ${entity_type}`);
      }
    }),
    distinctUntilRealChanged(),
    replay()
  );

  // #endregion

  // #region -> (entity management)

  private entity_schema: EntitySchema = null;

  private _entity$$ = new BehaviorSubject<Entity>(null);
  public entity$$ = this._entity$$.asObservable().pipe(replay());

  public entity_definition$$ = this.entity_type$$.pipe(
    map(entity_type => {
      switch (entity_type) {
        case 'location':
          return i18n<string>(
            `VIEWS.MODALS.FORM.Location: A Location is a fixed place on the map; Once created you can install or migrate an apiary; You can create a Location in advance, even if it doesn't host a hive today;`
          );

        case 'apiary':
          return i18n<string>(
            `VIEWS.MODALS.FORM.Apiary: An apiary is a group of hives installed on a location; It can be migrate from one location to another;`
          );

        default:
          return null;
      }
    }),
    distinctUntilRealChanged(),
    replay()
  );

  public entityEventCreated(): any {
    const entity_id = this._entity$$.getValue().id;
    // TODO: Manager better redirect with creation and edition

    if (!(this.input_params?.args?.no_redirect || false)) {
      if (this.input_params.etype === 'location') {
        setTimeout(() => {
          this._router.navigate([{ outlets: { primary: ['locations', 'all'], modal: ['location_details', { location_id: entity_id }] } }], {
            queryParamsHandling: 'preserve',
          });
        }, 40);
      }
    }

    this.forceClose(this._entity$$.getValue());
  }

  // #endregion

  // #region -> (setup event management)

  private event_schema: any = null;
  private setup_event: ModelEvent = null;

  // #endregion

  // #region -> (form management)

  protected computeFormSchema(entity_schema: EntitySchema, event_schema: any): any {
    if (isNil(this._entity$$.getValue()) || isNil(this.setup_event)) {
      return null;
    }

    const schema: ISchema = {
      type: 'object',
      properties: {
        entity: {
          type: 'object',
          properties: {
            static_state: entity_schema.static_state_schema,
          },
          required: ['static_state'],
        },
        event: event_schema,
      },
      required: ['entity', 'event'],
    };

    this.form_schema = null;

    setTimeout(() => {
      this.form_schema = schema;
      this.computeFormModel();
    }, 2);
  }

  private computeFormModel() {
    if (isNil(this._entity$$.getValue())) {
      return;
    }

    if (isNil(this.setup_event)) {
      return;
    }

    const data = {
      entity: this._entity$$.getValue().toJSON(),
      event: this.setup_event.export(),
    };

    this.form_model = data;
  }

  protected onFormChanged(fdata: any) {
    if (!this.form_ready) {
      return;
    }

    if (isNil(this._entity$$.getValue())) {
      return;
    }

    if (isNil(this.setup_event)) {
      return;
    }

    fdata = fdata.value;

    if (fdata.entity) {
      this._entity$$.getValue().static_state = fdata.entity.static_state;
    } else {
      this._entity$$.getValue().static_state = {};
    }

    if (fdata.event) {
      this.setup_event.update(fdata.event.date, fdata.event.apply_to, fdata.event.data);
      this.computeFormModel();
    }
  }

  public _submit() {
    this.loading = true;

    this.entity$$
      .pipe(
        take(1),
        switchMap(entity => {
          const role = this.input_params.etype;
          this.setup_event.addNewEntity(entity, role);
          return this.setup_event.save();
        }),
        catchError((error: unknown) => {
          this.handleHttpError(error);
          return this._dialogs.alert(this._translate.instant(<any>error)).pipe(
            switchMap(() => throwError(error)) // resend the error when dialog close
            // note: futur treatment could be make here to handle a user choice
          );
        })
      )
      .subscribe(
        ret => {
          this.loading = false;
          this.initial_loading = false;
          this.entityEventCreated();
        },
        (error: unknown) => {
          console.log('Error during process...');
          console.error(error);
          this.error = <any>error;
          this.loading = false;
          this.initial_loading = false;
        }
      );
  }

  public submit() {
    this.error = null;
    this.loading = true;
    this.is_submitting = true;

    let agreement_pipe: Observable<boolean> = of<boolean>(true);

    // Adding first level agreement
    if (isAfter(new Date(this.setup_event.date), endOfDay(new Date()))) {
      this.is_trying_to_close_modal = true;

      agreement_pipe = agreement_pipe.pipe(
        switchMap(() =>
          this._dialogs.confirm(
            i18n<string>(`VIEWS.MODALS.FORM.The date you've picked is in the future. Are you sure this is the date you want to use ?`),
            {
              onFalseMessage: i18n<string>('VIEWS.MODALS.FORM.Cancel'),
              onTrueMessage: i18n<string>('VIEWS.MODALS.FORM.Confirm'),
            }
          )
        )
      );
    }

    if (this._entity$$.getValue().type === 'location') {
      // For location we check if there is alreday some similar locations
      agreement_pipe = agreement_pipe.pipe(
        switchMap(future_agreement => {
          if (!future_agreement) {
            return of(false);
          }

          return combineLatest([
            of(this._entity$$.getValue() as Location),
            this._appState.all_locations$$.pipe(
              take(1), // To take only the current state,
              switchMap((all_locations: Location[]) =>
                robustCombineLatest(
                  all_locations.map((location: Location) =>
                    location.is_ghost$$.pipe(
                      map((is_ghost: boolean) => ({ location, is_ghost })),
                      take(1)
                    )
                  )
                )
              )
            ),
          ]).pipe(
            map(([location_to_create, all_locations]) =>
              all_locations
                .map(location_data => ({
                  location: location_data.location,
                  is_ghost: location_data.is_ghost,
                  close_position: getDistance(location_to_create.position, location_data.location.position) < 200,
                  similar_name: levenshtein_distance(location_to_create.name, location_data.location.name) < 3,
                }))
                .filter(value => (value.similar_name || value.close_position) && !value.is_ghost)
            ),
            switchMap(similar_locations => {
              if (similar_locations?.length >= 1) {
                return this._dialogs.open<LocationNearPosWarnParams, any>(LocationNearPosWarnComponent, {
                  near_locations: similar_locations,
                });
              } else {
                return of(true);
              }
            })
          );
        })
      );
    }

    agreement_pipe.pipe(take(1)).subscribe({
      next: has_agreed => {
        if (has_agreed) {
          this.is_trying_to_close_modal = true;
          this._submit();
        } else {
          this.loading = false;
        }

        this.is_submitting = false;
      },
      error: (error: unknown) => {
        this._logger.error(error);
        this.is_submitting = false;
      },
    });
  }

  // #endregion
  // #region -> (documentation link)

  public doc_page$$ = this.entity_type$$.pipe(
    map(entity_type => {
      switch (entity_type) {
        case 'location':
          return 'Create_location';

        case 'apiary':
          return 'Create_apiary';

        case 'hive':
          return 'Create_hives';

        default:
          return null;
      }
    })
  );

  // #endregion
}
