<bg2-modal [fullscreen]="fullscreen || false" #modal>
  <bg2-modal-header>
    <div class="header">
      <span class="mdi mdi-library-plus mdi-36px"></span>
      <span class="title">
        <ng-container *ngIf="entity_type$$ | async; let entity_type">
          <ng-container [ngSwitch]="entity_type">
            <ng-container *ngSwitchCase="'location'">{{'VIEWS.MODALS.FORM.New location' | translate}}</ng-container>
            <ng-container *ngSwitchCase="'apiary'">{{'VIEWS.MODALS.FORM.New apiary' | translate}}</ng-container>
            <ng-container *ngSwitchCase="'hive'">{{'VIEWS.MODALS.FORM.New hive' | translate}}</ng-container>
            <ng-container *ngSwitchDefault>
              {{'VIEWS.MODALS.FORM.New' | translate}}
              <ng-container *ngIf="entity$$ | async; let entity; else defaultDisplayedTitle">
                {{ entity.type_i18n | translate }}
              </ng-container>

              <ng-template #defaultDisplayedTitle>{{ entity_type }}</ng-template>
            </ng-container>
          </ng-container>
        </ng-container>
      </span>
    </div>

    <div class="action-buttons">
      <doc-helper *ngIf="doc_page$$ | async, let page" [page]="page" iconSize="36px"></doc-helper>
      <span class="mdi mdi-close-box mdi-36px" (click)="close()"></span>
    </div>
  </bg2-modal-header>

  <bg2-modal-content>
    <div *ngIf="entity_definition$$ | async; let entity_definition" class="entity-definition hide-on-small-and-down">
      <p>
        <span class="mdi mdi-help-rhombus mdi-36px"></span>
        <span>{{ entity_definition | translate }}</span>
      </p>
    </div>

    <sf-form
      *ngIf="form_ready$$ | async"
      [schema]="form_schema$$ | async"
      [model]="form_model"
      (isValid)="setFormValid($event)"
      (onChange)="onFormChange($event)"
      #eventForm
    >
    </sf-form>
  </bg2-modal-content>

  <bg2-modal-footer>
    <bg2-button [type]="'cancel'" [message]="'VIEWS.MODALS.FORM.Close' | translate" (btn_click)="close()"></bg2-button>

    <bg2-button
      [type]="'action'"
      [icon]="'mdi-content-save'"
      [message]="'VIEWS.MODALS.FORM.Save' | translate"
      [disabled]="(loading$$ | async) || (form_valid$$ | async) === false"
      [loading]="loading$$ | async"
      (btn_click)="submit()"
    ></bg2-button>
  </bg2-modal-footer>
</bg2-modal>
