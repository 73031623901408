import { Router } from '@angular/router';
import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';
import { marker as i18n } from '@biesbjerg/ngx-translate-extract-marker';

import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';

import { isNil } from 'lodash-es';

import { filter, map, switchMap, tap } from 'rxjs';
import { distinctUntilRealChanged, replay, waitForNotNilValue } from '@bg2app/tools/rxjs';
import { Subscription, Observable, BehaviorSubject, of } from 'rxjs';

import { DeviceApi, UsersApiService, Beeguard2Api } from 'app/core';
import { AppStateService } from 'app/core/app-state.service';
import { GetDeviceResponse } from 'app/core/api-swagger/device';
import { DeviceQueryParams } from 'app/core/api/device/device-api-service';

import { DRDevice as Device, RGDevice, GPSDevice, Warehouse, Exploitation, Hive, Apiary, Location, DRDevice } from 'app/models';

import { Dictionary } from 'app/typings/core/interfaces';

import { AbstractModalComponent, ModalArgs, ModalParams } from 'app/widgets/dialogs-modals/abstract-modal.component';
import { compute_868_state } from 'app/models/devices/_functions';
import { parseDate } from 'app/misc/tools';
import { DeviceStatus, DeviceStatus868, DeviceStatus868Str } from 'app/models/devices/DRDevice';

export interface ILocationDetailsModalParams extends ModalParams {
  location_id: string;
}

@AutoUnsubscribe()
@Component({
  selector: 'bg2-location-details-modal',
  templateUrl: './location-details-modal.component.html',
  styleUrls: ['./location-details-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LocationDetailsModalComponent extends AbstractModalComponent<ILocationDetailsModalParams> implements OnInit, OnDestroy {
  // #region -> (component basics)

  /** */
  private _location_sub: Subscription = null;

  /** */
  private _location_deleted_sub: Subscription = null;

  constructor(private readonly _bg2Api: Beeguard2Api) {
    super();
    this._logger.update_prefix('LocationDetailsModalComponent');
  }

  ngOnInit(): void {
    this._location_sub = this.input_params$$
      .pipe(
        map(input_params => input_params?.location_id ?? null),
        waitForNotNilValue(),
        map(location_id => parseInt(location_id, 10)),
        switchMap(location_id => this._bg2Api.getEntityObj<Location>(location_id))
      )
      .subscribe({
        next: location => this._location$$.next(location),
      });

    this._location_deleted_sub = this.location$$
      .pipe(
        switchMap(location => location.notifications.has_been_deleted$$),
        filter(Boolean)
      )
      .subscribe({
        next: () =>
          setTimeout(() => {
            this.close();
          }, 10),
      });
  }

  ngOnDestroy() {
    this._location_sub?.unsubscribe();
    this._location_deleted_sub?.unsubscribe();

    this.unsubscribe();
  }

  protected unsubscribe() {
    super.unsubscribe();
  }

  // #endregion

  /** */
  protected handle_event_before_unload(event: BeforeUnloadEvent): void {
    return null;
  }

  // #region -> (location entity)

  /** */
  private _location$$ = new BehaviorSubject<Location>(null);

  /** */
  public location$$ = this._location$$.asObservable().pipe(waitForNotNilValue());

  // #endregion
}
