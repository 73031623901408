import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';

import { map } from 'rxjs';

import { EfNumberWidget } from '../number/number.widget';

import { isNil } from 'lodash-es';

@Component({
  selector: 'bg2-rating-widget',
  templateUrl: './bg2-rating.widget.html',
  styleUrls: ['./bg2-rating.widget.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class Bg2RatingWidget extends EfNumberWidget implements OnInit {

  public __isNil = isNil;

  public get maximum(): number {
    return this.schema.maximum || 5;
  }

  public note$$: Observable<number>;

  ngOnInit(): void {
    super.ngOnInit();
    this.note$$ = this.formProperty.valueChanges.pipe(
      // warning: we use 0 as default value, but actually it means "no value"
      map(val => val || 0)
    );
  }

  public set note(note: number) {
    this.formProperty.setValue(note, false);
  }

}
