<div class="bg2-apiary-last-data-component">
  <div *ngIf="show_navigation" class="switch-equipped-hives-to-devices">
    <ng-container *ngLet="apiary?.user_acl?.can$$('read_devices') | async; let can_read_devices">
      <span
        class="switch-equipped-hives-to-devices__item"
        [popper]="popperNavToDevices"
        [popperDisabled]="can_read_devices"
        [ngClass]="{ active: show_equipped_hives === false, 'locked-due-to-rights': !can_read_devices }"
        (click)="can_read_devices ? (show_equipped_hives = false) : null"
      >
        <span class="mdi" [ngClass]="{ 'mdi-shape-plus': can_read_devices, 'mdi-lock': !can_read_devices }"></span>

        {{ 'VIEWS.APIARY.SHARED.APIARY_DETAILS_COMPACT.VIEW_SELECT.Devices' | translate }}
      </span>

      <popper-content #popperNavToDevices>
        <bg2-acl-tooltip-content
          [ace]="'read_devices'"
          [entity_type]="'location'"
          [what]="'ALL.ERROR.ACE.READ_ALL_EVENTS.WHAT.the devices' | translate | lowercase"
        ></bg2-acl-tooltip-content>
      </popper-content>
    </ng-container>

    <span>➔</span>

    <ng-container *ngLet="apiary?.user_acl?.can$$('read_devices') | async; let can_read_devices">
      <span
        class="switch-equipped-hives-to-devices__item"
        [popper]="popperNavToEqHives"
        [popperDisabled]="can_read_devices"
        [ngClass]="{ active: show_equipped_hives, 'locked-due-to-rights': !can_read_devices }"
        (click)="can_read_devices ? (show_equipped_hives = true) : null"
      >
        <span *ngIf="!can_read_devices" class="mdi mdi-lock"></span>
        <bg2-picto-hive *ngIf="can_read_devices" [size]="'20px'"></bg2-picto-hive>

        {{ 'VIEWS.APIARY.SHARED.APIARY_DETAILS_COMPACT.VIEW_SELECT.Eq. hives' | translate }}
      </span>

      <popper-content #popperNavToEqHives>
        <bg2-acl-tooltip-content
          [ace]="'read_devices'"
          [entity_type]="'location'"
          [what]="'ALL.ERROR.ACE.READ_ALL_EVENTS.WHAT.the equipped hives' | translate | lowercase"
        ></bg2-acl-tooltip-content>
      </popper-content>
    </ng-container>
  </div>

  <div *ngIf="show_equipped_hives" class="last-data-container">
    <bg2-error-helper *ngIf="error$$ | async; let error" [error]="error"></bg2-error-helper>

    <!-- Display apiray last data -->
    <div class="last-data-container__apiary">
      <ng-container *ngIf="apiary_can_have_weather_data$$ | async">
        <bg2-apiary-last-data-card-weather [apiary]="apiary$$ | async"></bg2-apiary-last-data-card-weather>
        <mat-divider [vertical]="true"></mat-divider>
      </ng-container>
    </div>

    <!-- Display hives with devices -->
    <div class="last-data-container__hives">
      <ng-container *ngFor="let hive of hives_with_device$$ | async; let index = index; trackBy: track_by_hive_id">
        <bg2-apiary-last-data-card-hive [hive]="hive"></bg2-apiary-last-data-card-hive>
      </ng-container>
    </div>
  </div>

  <div *ngIf="show_equipped_hives === false" class="apiary-devices-list">
    <ng-container *ngLet="apiary?.devices$$ | async; let devices">
      <ng-container *ngFor="let device of devices; trackBy: track_by_devices; let index=index">
        <bg2-device-inline [device]="device" [show_action]="true" [show_last_com]="true" [hive_id]="device.hive_config?.hive_id ?? null">
        </bg2-device-inline>

        <mat-divider *ngIf="index !== devices?.length - 1"></mat-divider>
      </ng-container>
    </ng-container>
  </div>

  <ng-container *ngIf="!env?.config?.disable_navigation">
    <div *ngLet="apiary$$ | async; let apiary" class="manage-my-devices-container">
      <bg2-button
        [type]="'action'"
        [color]="'device'"
        [options]="{ is_thin: true }"
        [icon]="'mdi-shape-polygon-plus'"
        [queryParamsHandling]="'preserve'"
        [message]="'VIEWS.APIARY.SHARED.WIDGETS.APIARY_LAST_DATA.Manage Hives & Devices' | translate"
        [routerLink]="['', { outlets: { modal: ['apiary_hive_list', { eid: (apiary?.id$$ | async) }] } }]"
      ></bg2-button>
    </div>
  </ng-container>
</div>
