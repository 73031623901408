import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'bg2-ui-message',
  templateUrl: './message.component.html',
  styleUrls: ['./message.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UIMessageComponent {
  // #region -> (component basics)

  constructor() {}

  // #endregion

  // #region -> (message properties)

  @Input()
  public type: 'alert' | 'warning' | 'info' | 'success' | 'solution';

  @Input()
  public title: string = 'DEFAULT_TITLE_PROPERTY';

  // #endregion

  // #region -> (message styles)

  @Input()
  public should_take_full_height = false;
  
  @Input()
  public show_borders = true;

  @Input()
  public fill_background = true;

  @Input()
  public invert_text_color = false;

  // #endregion
}
