export enum MapMarkerType {
  DEFAULT = 'default',

  LOCATION = 'location',
  LOCATION_ARCHIVED = 'location_archived',

  DEVICE_RG = 'device_rg',
  DEVICE_GPS = 'device_gps',
  DEVICE_GPRS = 'device_gprs',
  DEVICE_WG = 'device_wg',
  DEVICE_CPT = 'device_cpt',
  DEVICE_TG = 'device_tg',

  POS_TYPE_MOVEMENT = 'pos_type_movement',
  POS_TYPE_PERIODIC_LOCATION = 'pos_type_periodic_location',
  POS_TYPE_STOP = 'pos_type_stop',

  MOBILE_NETWORK_ANTENNA_SITE = 'mobile_nw_antenna_site',
}
