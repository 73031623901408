<div class="ef-element-header">
  <ng-container *ngIf="schema && (schema.title)">
    <div *ngIf="schema.type !== 'number' && schema.options?.step" class="step-indicator">{{ schema.options.step }}</div>

    <div *ngIf="schema.icon" class="step-indicator">
      <i class="mdi mdi-{{ schema.icon }} mdi-24px"></i>
    </div>

    <div *ngIf="schema.title && !hide_title" class="legend {{ title_style }}" [class.step]="schema.step">
      <div>
        {{ schema.title | translate }}
        <span *ngIf="additionnal_text.length > 0"> ({{ additionnal_text }})</span>
      </div>
    </div>

    <span *ngIf="schema.description && !hide_description" class="formHelp">{{ schema.description | translate }}</span>
  </ng-container>

  <div *ngIf="showLabel && schema?.label" class="label">
    <span>{{ schema.label | translate }}</span>
  </div>
</div>
