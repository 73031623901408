import { ActivatedRoute, Router } from '@angular/router';
import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { LocalStorage } from '@ngx-pwa/local-storage';

import { isEmpty, isNil } from 'lodash-es';

import { allTrue, distinctUntilRealChanged, replay, switchTap } from '@bg2app/tools/rxjs';
import { map, switchMap, tap, combineLatest, filter, Observable, take, of, catchError, Subject } from 'rxjs';

import { ENV } from '../providers/environment.provider';
import { ConsoleLoggerService } from '../console-logger.service';
import { ResponseLogin, User } from '../api-swagger/user-v2';

import { IEnvironment } from 'environments/common';
import { SidenavService } from '../sidenav.service';
import { GenericAuthService } from './abstract-auth.service';

@Injectable({
  providedIn: 'root',
})
export class BeeguardAuthService extends GenericAuthService {
  // #region -> (service basics)

  /** */
  protected readonly LOGGER = new ConsoleLoggerService('BeeguardAuthService', false);

  constructor(
    @Inject(ENV) public env: IEnvironment,

    protected readonly httpClient: HttpClient,
    protected readonly _storage: LocalStorage,

    private readonly _router: Router,
    private readonly _sidenav: SidenavService,
    private readonly _activated_route: ActivatedRoute,
  ) {
    super(env, httpClient);
    this._candidate_impersonate$$.subscribe(impersonate => (this.impersonate = impersonate));

    this._activated_route.queryParamMap.subscribe({
      next: parameters => {
        const impersonate_id = parameters.get('impersonate_id') ?? null;

        if (!isNil(impersonate_id)) {
          this.set_impersonated_user_id(parseInt(impersonate_id));
        }
      },
    });
  }

  // #endregion

  // #region -> (token management)

  protected getAccessToken() {
    return this._storage.getItem<string>('access_token', { type: 'string' });
  }

  protected storeAccessToken(access_token: string, expires_at_epoch_ms: number) {
    const access_token_saved$$ = this._storage.setItem('access_token', access_token, { type: 'string' });
    const expires_at_saved$$ = this._storage.setItem('expires_at', expires_at_epoch_ms, { type: 'number' });

    return allTrue(access_token_saved$$, expires_at_saved$$).pipe(filter(Boolean));
  }

  protected removeAccessToken() {
    const access_token_cleared$$ = this._storage.removeItem('access_token');
    const expires_at_cleared$$ = this._storage.removeItem('expires_at');

    return allTrue(access_token_cleared$$, expires_at_cleared$$).pipe(
      filter(Boolean),
      tap(() => {
        this._access_token = null;
        this._update_token$$.next(true);
      }),
    );
  }

  /**
   * Observes the expires date of token.
   *
   * @public
   */
  public expires_at$$: Observable<number> = this._update_token$$.pipe(
    switchMap(() => this._storage.getItem<number>('expires_at', { type: 'number' })),
    distinctUntilRealChanged(),
    replay(),
  );

  /** */
  public hasValidAccessToken$(): Observable<{ valid: boolean; reason?: 'expired' | 'empty' }> {
    return this.access_token$$.pipe(
      take(1),
      switchMap(token => {
        if (isNil(token) || isEmpty(token)) {
          return of<any>({ valid: false, reason: 'empty' });
        }

        return this.httpClient
          .get(this.env.UserApiUrl, {
            headers: new HttpHeaders({
              Accept: 'text/json',
              Authorization: `Bearer ${token}`,
            }),
          })
          .pipe(
            map(() => ({ valid: true })),
            catchError(() => of<any>({ valid: false, reason: 'expired' })),
          );
      }),
    );
  }

  // #endregion

  // #region -> (password management)

  /** */
  public ask_reset_password_token(mail: string) {
    return super.requestResetPasswordToken({ email: mail });
  }

  /** */
  public check_reset_password_token(token: string) {
    return super.checkResetPasswordToken({ token });
  }

  /** */
  public update_reset_password(password: string, token?: string) {
    return super.resetPassword({ new_password: password, token }).pipe();
  }

  // #endregion

  /**
   * Dictionnary of authentication data.
   *
   * @public
   * @replay
   */
  public authentication_data$$ = combineLatest({
    expires_at: this.expires_at$$,
    access_token: this.access_token$$,
    impersonate: this.impersonate$$,
  });

  /**
   * @inheritdoc
   */
  public login(email: string, password: string) {
    let stripped_mail = email.split(' ').join();

    return super.authenticate({ email: stripped_mail, password }).pipe(
      switchMap(login_response => {
        const access_token = login_response.access_token;
        const expires_at_epoch_ms = new Date().getTime() + login_response.expires_in * 1000;

        return this.set_access_token(access_token, expires_at_epoch_ms).pipe(map(() => login_response));
      }),
      map(login_response => {
        this._real_user_id$$.next(login_response?.user?.user_id);
        this.is_authorized = true;
        return login_response;
      }),
    );
  }

  /** */
  public login_after_reset_password(login_response: ResponseLogin) {
    const access_token = login_response.access_token;
    const expires_at_epoch_ms = new Date().getTime() + login_response.expires_in * 1000;

    this.set_access_token(access_token, expires_at_epoch_ms)
      .pipe(
        map(() => login_response),
        map(response => {
          this._real_user_id$$.next(login_response?.user?.user_id);
          this.is_authorized = true;

          return response;
        }),
        take(1),
      )
      .subscribe({
        next: () => {
          this._router.navigate(['/']);
        },
      });
  }

  /**
   * @inheritdoc
   */
  public logout(reload_navigator = true): void {
    this._sidenav.close();
    this.is_authorized = false;

    this.removeAccessToken()
      .pipe(take(1))
      .subscribe({
        complete: () => {
          if (reload_navigator) {
            location.reload();
          }
        },
      });
  }

  /** */

  private fetch_user_scope(_user_id: number) {
    return this.access_token$$.pipe(
      take(1),
      switchMap(access_token =>
        this.httpClient.get(`${this.env.UserApiUrl}/users/${_user_id}`, {
          params: {
            only: ['scopes'],
          },
          headers: new HttpHeaders({
            Accept: 'text/json',
            Authorization: `Bearer ${access_token}`,
          }),
        }),
      ),
      map(({ user }: { user: Partial<User> }) => ({ user_id: user.user_id, scopes: user.scopes })),
    );
  }

  private _candidate_impersonate_id$$ = new Subject<number | null>();
  private _candidate_impersonate$$ = this._candidate_impersonate_id$$.pipe(
    switchTap(candidate =>
      this.impersonate_id$$.pipe(
        take(1),
        tap(actual => this.LOGGER.debug(candidate, actual)),
        filter(actual => candidate !== actual),
      ),
    ),
    distinctUntilRealChanged(),
    tap(actual => this.LOGGER.debug('New valid candidate', actual)),
    switchMap(_user_id => {
      if (isNil(_user_id)) {
        return of(null);
      } else {
        return this.fetch_user_scope(_user_id);
      }
    }),
  );

  public set_impersonated_user_id(impersonate_user_id: number): void {
    this.LOGGER.debug('set candidate impersonate_id', impersonate_user_id);
    this._candidate_impersonate_id$$.next(impersonate_user_id);
  }
}
