import { distinctUntilRealChanged, replay } from '@bg2app/tools/rxjs';
import { BehaviorSubject } from 'rxjs';

/** */
export abstract class ZohoBaseModel<BaseModelDef> {
  // #region -> (model basics)

  constructor() {}

  /** */
  public deserialize(model_data: Partial<BaseModelDef>): void {
    Object.keys(model_data).map(key => {
      const value: any = (<any>model_data)[key];
      (<any>this)[key] = value;
    });
  }

  // #endregion

  // #region -> (base identification)

  /** */
  protected _id$$ = new BehaviorSubject<string>(null);

  /** */
  public id$$ = this._id$$.asObservable().pipe(distinctUntilRealChanged(), replay());

  /** */
  public set id(id: string) {
    this._id$$.next(id);
  }

  /** */
  public get id(): string {
    return this._id$$.getValue();
  }

  // #endregion
}

/** */
export class TestZohoBaseModel extends ZohoBaseModel<{ id: string }> {}
