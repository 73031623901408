<div *ngIf="(link$$ | async) as link" class="doc-helper-component">
  <ng-container *ngIf="(icon$$ | async) as icon">
    <ng-container *ngIf="!tooltip">
      <span class="doc-helper mdi mdi-{{icon}} without-tooltip mdi-{{iconSize}}"
        [routerLink]="['', { outlets: { 'modal': ['user_manual', { 'help': link }] }}]"
        [queryParamsHandling]="'preserve'">
      </span>
    </ng-container>
    <ng-container *ngIf="tooltip">
      <span class="doc-helper mdi mdi-{{icon}} mdi-{{iconSize}}" 
        [popper]="tooltip" [popperTimeout]="timeout ? 100 : 0"
        [routerLink]="['', { outlets: { 'modal': ['user_manual', { 'help': link }] }}]"
        [queryParamsHandling]="'preserve'">
      </span>
    </ng-container>
  </ng-container>

  <span class="simili-link"
      [routerLink]="['', { outlets: { 'modal': ['user_manual', { 'help': link }] }}]"
      [queryParamsHandling]="'preserve'">
    <ng-content></ng-content>
  </span>
</div>
