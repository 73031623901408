import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Dictionary } from 'app/typings/core/interfaces';

@Component({
  selector: 'bg2-loading-skeleton',
  templateUrl: './loading-skeleton.component.html',
  styleUrls: ['./loading-skeleton.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SkeletonLoadingComponent {
  @Input()
  public appearance: 'circle' | 'rectangle' = 'rectangle';

  @Input()
  public count: number = 1;

  @Input()
  public theme: Dictionary<string> = {};

  @Input()
  public string_model: string = '';

  @Input()
  public visible_message: boolean = false;

  @Input()
  public has_loading_effect: boolean = true;
}
