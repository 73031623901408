import { LatLng, polygon } from 'leaflet';

/**
 * Draws a polygon which looks like a circle.
 *
 * @param latlng The center position of the circle.
 * @param radius The radius of the circle in meters.
 * @param options Additionnal options to styling the circle.
 * @param points Number of points to draw the circle, default is 100.
 * @returns Returns a proper polygon wich looks like a classic circle.
 */
export const drawPolygonCircle = (
  latlng: L.LatLng,
  radius: number,
  options: L.PolylineOptions = {},
  points: number = 100
): L.Polygon<any> => {
  // Pre-define required constants
  const EARTH_RADIUS = 6371000.0; // The approximate earth radius in meters
  const DEG_TO_RAD = Math.PI / 180;
  const RAD_TO_DEG = 180 / Math.PI;

  // Transform radius in lat & lng values
  const rlat = (radius / EARTH_RADIUS) * RAD_TO_DEG;
  const rlng = rlat / Math.cos(latlng.lat * DEG_TO_RAD);

  const extp = [];
  for (let i = 0; i < points + 1; i++) {
    const theta = Math.PI * (i / (points / 2));
    const ex = latlng.lng + rlng * Math.cos(theta); // center a + radius x * cos(theta)
    const ey = latlng.lat + rlat * Math.sin(theta); // center b + radius y * sin(theta)
    extp.push(new LatLng(ey, ex));
  }

  return polygon(extp, options);
};
