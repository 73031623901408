import { AfterViewInit, Component, NgZone, OnDestroy, OnInit, ViewChild } from '@angular/core';

import { of } from 'rxjs';

import { Beeguard2Api } from 'app/core';
import { DialogsService } from 'app/widgets/dialogs-modals';
import { AppStateService } from 'app/core/app-state.service';

import { BgControlWidgetComponent } from '../control/control.widget';
import { TimezoneObject, timezones_data } from 'app/models/misc/timezones';
import { FullscreenSelectHelper } from '@bg2app/tools/misc';
import { MtxSelectComponent } from '@ng-matero/extensions/select';
import { isNil } from 'lodash-es';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'bg2-timezone-select-form-widget',
  templateUrl: './bg2-timezone-select.component.html',
  styleUrls: ['./bg2-timezone-select.component.scss'],
})
export class TimezoneSelectFormWidgetComponent extends BgControlWidgetComponent implements OnInit, AfterViewInit, OnDestroy {
  // #region -> (component basics)

  constructor(
    protected bg2Api: Beeguard2Api,
    public appState: AppStateService,
    protected dialogs: DialogsService,
    private readonly _ng_zone: NgZone,
    private readonly _translate: TranslateService
  ) {
    super(bg2Api, appState, dialogs);
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  ngAfterViewInit(): void {
    super.ngAfterViewInit();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  // #endregion

  // #region -> (fullscreen management)

  /** */
  public fullscreen_select_helper = new FullscreenSelectHelper(this._ng_zone);

  /** */
  private _material_selector_ref: MtxSelectComponent;

  /** */
  @ViewChild('select', {})
  protected set select_ref(select: MtxSelectComponent) {
    if (isNil(select)) {
      return;
    }

    this._material_selector_ref = select;
    this.fullscreen_select_helper.select_ref.value = select;
  }

  /** */
  protected get select_ref() {
    return this._material_selector_ref;
  }

  // #endregion

  // #region -> (items management)

  /** */
  public items$$ = of(timezones_data);

  /** */
  public ngSelectCustomSearch = (term: string, item: TimezoneObject) => {
    term = term.toLocaleLowerCase();

    const has_tz_abbr = item.tz_abbr.toLocaleLowerCase().indexOf(term) > -1;
    const has_tz_offset = item.tz_offset.toLocaleLowerCase().indexOf(term) > -1;

    const has_tz_identifier = item.tz_identifier.toLocaleLowerCase().indexOf(term) > -1;
    const has_country_name = this._translate.instant(item.country_name).toLocaleLowerCase().indexOf(term) > -1;

    if (item?.summer_time) {
      const has_tz_st_abbr = item.summer_time.tz_abbr.toLocaleLowerCase().indexOf(term) > -1;
      const has_tz_st_offset = item.summer_time.tz_offset.toLocaleLowerCase().indexOf(term) > -1;

      return has_tz_abbr || has_tz_offset || has_country_name || has_tz_identifier || has_tz_st_abbr || has_tz_st_offset;
    }

    return has_tz_abbr || has_tz_offset || has_country_name || has_tz_identifier;
  };

  // #endregion
}
