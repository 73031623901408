import { AfterViewInit, ChangeDetectionStrategy, Component, Input, NgZone, OnDestroy } from '@angular/core';

import { isNil, merge } from 'lodash-es';
import { DateTime } from 'luxon';

import { ConsoleLoggerService } from 'app/core/console-logger.service';

import { ApiaryWeightsData } from 'app/models';
import { ResizedEvent } from 'app/misc/directives/resized/resized.directive';

import { D3SvgApiaryWeightFactory } from './_chart_factory';
import { D3SharedCursorService } from 'app/core/services/global/d3-shared-cursor.service';
import { AppStateService } from 'app/core/app-state.service';
import { ChartGeoposition, D3ChartMargins } from '@bg2app/models/charts';

/**
 * @ngModule DevicesSharedModule
 *
 * @description
 *
 * Creates a D3.js chart to display the weight of the hives with WGuard devices.
 *
 * The `ApiaryWeightD3ChartComponent` component creates a D3.js chart to display
 * the weight of each hive which have WGuard devices installed on it.
 */
@Component({
  selector: 'bg2-apiary-weight-d3-chart-component',
  template: `<div class="bg2-apiary-weight-d3-chart-component" (resized)="onChartContainerResized($event)">
    <svg [id]="chart_factory.unique_id"></svg>
    <div class="loading"></div>
  </div>`,
  styleUrls: ['./apiary-weight-d3-chart.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ApiaryWeightD3ChartComponent implements AfterViewInit, OnDestroy {
  // #region -> (component basics)

  private readonly _logger = new ConsoleLoggerService('ApiaryWeightD3ChartComponent', true);

  constructor(private _d3_shared_cursor: D3SharedCursorService, private _appState: AppStateService, private _ngZone: NgZone) {
    this.chart_factory = new D3SvgApiaryWeightFactory(this._d3_shared_cursor, this._appState, this._ngZone);
  }

  ngAfterViewInit(): void {
    this.chart_factory.create_chart();
  }

  ngOnDestroy(): void {
    this.chart_factory.destroy();
  }

  // #endregion

  // #region -> (container size management)

  public onChartContainerResized(resized_event: ResizedEvent): void {
    this.chart_factory.box_sizing = { width: resized_event?.new_width, height: resized_event?.new_height };
  }

  // #endregion

  // #region -> (data management)

  /** */
  @Input()
  public set weight_data(weight_data: ApiaryWeightsData) {
    this.chart_factory.incoming_data = weight_data?.hives_weight || null;
  }

  @Input()
  public set margins(margins: Partial<D3ChartMargins>) {
    this.chart_factory.margins = merge(this.chart_factory.margins, margins);
  }

  /** */
  @Input()
  public set date_range(date_range: { start: DateTime; end: DateTime }) {
    if (isNil(date_range)) {
      return;
    }

    this.chart_factory.date_range = date_range;
  }

  /** */
  @Input()
  public set is_loading(is_loading: boolean) {
    this.chart_factory.is_loading = is_loading;
  }

  /** */
  @Input()
  public set geoposition(geoposition: ChartGeoposition) {
    this.chart_factory.geoposition.value = geoposition;
  }

  // #endregion

  // #region -> (chart management)

  /** */
  public chart_factory: D3SvgApiaryWeightFactory = null;

  // #endregion
}
