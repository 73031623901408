import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatSnackBar, MatSnackBarRef } from '@angular/material/snack-bar';

import { cloneDeep, isEqual, isNil } from 'lodash-es';

import { replay } from '@bg2app/tools/rxjs';
import { BehaviorSubject, of, Subscription, timer } from 'rxjs';
import { filter, map, mergeMap, switchMap, take } from 'rxjs/operators';

import { marker as i18n } from '@biesbjerg/ngx-translate-extract-marker';

import { ConsoleLoggerService } from 'app/core/console-logger.service';

import { DialogsService } from 'app/widgets/dialogs-modals';

import { NewBetaVersionSnackbarComponent } from 'app/widgets/widgets-reusables/snackbars/new-beta-version-snackbar.component';
import { app_version_parser, parseDate } from 'app/misc/tools';
import { IEnvironment } from 'environments/common';
import { ENV } from 'app/core/providers/environment.provider';

type BetaVersionFormat = `beta-bg${string}-${string}-${string}-b${string}`;
type ClassicVersionFormat = `release-bg${string}-${string}-${string}-r${string}`;

@Injectable({
  providedIn: 'root',
})
export class BetaAppService implements OnDestroy {
  // #region -> (service basics)

  /** */
  private readonly BETA_FIXED_NAMES = {
    local_storage: {
      is_beta_enabled: 'beta.is_beta_enabled',
      last_viewed_version: 'beta.last_consulted_version',
    },
    url_param: {
      quit_beta: 'quit_beta',
    },
  };

  /** */
  private LOGGER = new ConsoleLoggerService('BetaAppService', true);

  /** */
  private _beta_snackbar_ref: MatSnackBarRef<any> = null;

  /** */
  private _quit_beta_url_parameter_sub: Subscription = null;

  /** */
  private _beta_version_watcher_sub: Subscription = null;

  /** */
  private _classy_version_watcher_sub: Subscription = null;

  constructor(
    private _router: Router,
    private _http: HttpClient,
    private _route: ActivatedRoute,
    private _dialogs: DialogsService,
    private _matSnackbar: MatSnackBar,
    @Inject(ENV) public env: IEnvironment
  ) {}

  ngOnDestroy(): void {
    this._beta_snackbar_ref?.dismiss();
    this._beta_version_watcher_sub?.unsubscribe();
    this._classy_version_watcher_sub?.unsubscribe();
    this._quit_beta_url_parameter_sub?.unsubscribe();
  }

  /** */
  public initialize(): void {
    if (this.env.config.beta_version.is_beta_env) {
      // Watch for possible new app version.
      this.watch_new_classy_version();
    } else {
      // Watch for possible new beta version.

      if (!this.env.config.beta_version.should_check_for_beta) {
        return;
      }

      this.watch_new_beta_version();

      this._quit_beta_url_parameter_sub = this._route.queryParamMap.pipe(take(1)).subscribe(parameters => {
        const quit_beta = parameters.get(this.BETA_FIXED_NAMES.url_param.quit_beta) as null | '1';

        if (quit_beta === '1') {
          localStorage.setItem(this.BETA_FIXED_NAMES.local_storage.is_beta_enabled, 'false');
          this._should_watch_beta_version$$.next(true);

          let copy_parameters = cloneDeep((parameters as any).params);
          delete copy_parameters[this.BETA_FIXED_NAMES.url_param.quit_beta];

          this._router.navigate([], { queryParams: copy_parameters });
          this._should_watch_beta_version$$.next(true);

          return;
        }

        if (this.get_is_beta_enabled_by_user_from_storage()) {
          this.check_redirection_to_beta();

          return;
        }

        this._should_watch_beta_version$$.next(true);
      });
    }
  }

  // #endregion

  // #region -> (misc helpers)

  /** */
  private get_is_beta_enabled_by_user_from_storage = (): boolean =>
    JSON.parse(localStorage.getItem(this.BETA_FIXED_NAMES.local_storage.is_beta_enabled) ?? 'false');

  // #endregion

  // #region -> (beta-version watcher)

  /** */
  private _should_watch_beta_version$$ = new BehaviorSubject(false);

  /** */
  private _current_beta_version$$ = this._http.get('https://beta.beeguard.net/version.json?t=' + new Date().getTime()).pipe(
    map((app_version: { version: BetaVersionFormat; hash: string }) => app_version?.version),
    map(version => app_version_parser(version)),
    replay()
  );

  /** */
  private fetch_current_beta_version$$() {
    return this._http.get('https://beta.beeguard.net/version.json?t=' + new Date().getTime()).pipe(
      map((app_version: { version: BetaVersionFormat; hash: string }) => app_version?.version),
      map(version => app_version_parser(version))
    );
  }

  /** */
  private fetch_current_classy_version$$() {
    return this._http.get('https://app.beeguard.net/version.json?t=' + new Date().getTime()).pipe(
      map((app_version: { version: ClassicVersionFormat; hash: string }) => app_version?.version),
      map(version => app_version_parser(version))
    );
  }

  /** */
  private watch_new_classy_version(): void {
    if (isNil(this._classy_version_watcher_sub) === false) {
      return;
    }

    if (!this.env.config.beta_version.should_check_for_classy) {
      return;
    }

    this._classy_version_watcher_sub = timer(0, 1000 * 60 * 10)
      .pipe(switchMap(() => this.fetch_current_classy_version$$()))
      .subscribe({
        next: current_classy_version => {
          const current_version_string = app_version_parser(<any>this.env.version);
          const current_version_date = new Date(current_version_string);

          const current_classy_date = new Date(current_classy_version);

          if (current_classy_date > current_version_date) {
            this.display_new_beta_version_snackbar(false);
          }
        },
      });
  }

  /**
   * Watches for possible new beta-version every 30 minutes.
   */
  private watch_new_beta_version(): void {
    if (!isNil(this._beta_version_watcher_sub)) return;

    if (this.env.config.beta_version.is_beta_env) return;
    if (!this.env.config.beta_version.should_check_for_beta) return;

    this._beta_version_watcher_sub = timer(0, 1000 * 60 * 10)
      .pipe(
        switchMap(() => this._should_watch_beta_version$$),
        filter(Boolean),
        mergeMap(() => this.fetch_current_beta_version$$())
      )
      .subscribe({
        next: current_beta_version => {
          const last_consulted_version = (localStorage.getItem(this.BETA_FIXED_NAMES.local_storage.last_viewed_version) ??
            null) as BetaVersionFormat;

          if (isEqual(last_consulted_version, current_beta_version)) return;

          const current_version_string = app_version_parser(this.env.version);
          const current_classy_date = new Date(current_version_string);

          const current_beta_date = new Date(current_beta_version);

          if (current_classy_date < current_beta_date) {
            this.display_new_beta_version_snackbar(true);
          }
        },
      });
  }

  /** */
  private display_new_beta_version_snackbar(show_new_beta: boolean): void {
    if (!isNil(this._beta_snackbar_ref)) {
      return;
    }

    // Displays the beta update snackbar
    this._beta_snackbar_ref = this._matSnackbar.openFromComponent(NewBetaVersionSnackbarComponent, {
      duration: 0,
      panelClass: 'app-version-snackbar',
      data: {
        show_new_beta,
      },
    });

    // Handle user action
    this._current_beta_version$$
      .pipe(
        take(1),
        switchMap(current_beta_version =>
          this._beta_snackbar_ref.afterDismissed().pipe(map(dismissable => ({ dismissable, current_beta_version })))
        )
      )
      .subscribe({
        next: ({ dismissable, current_beta_version }) => {
          this._beta_snackbar_ref = null;

          localStorage.setItem(this.BETA_FIXED_NAMES.local_storage.is_beta_enabled, 'false');
          localStorage.setItem(this.BETA_FIXED_NAMES.local_storage.last_viewed_version, current_beta_version);

          if (!dismissable.dismissedByAction) return;

          if (this.env.config.beta_version.is_beta_env) {
            this.use_beta_application();
          } else {
            this.quit_beta_application();
          }
        },
      });
  }

  // #endregion

  /** */
  public is_current_build_is_beta$$ = of<boolean>(this.env.config.beta_version.is_beta_env);

  /** */
  private _redirection_to_beta_sub: Subscription = null;

  /** */
  public check_redirection_to_beta(): void {
    if (!isNil(this._redirection_to_beta_sub)) return;

    if (this.env.config.beta_version.is_beta_env) return;
    if (!this.env.config.beta_version.should_check_for_beta) return;

    this._redirection_to_beta_sub = this._current_beta_version$$
      .pipe(
        switchMap(version =>
          this._dialogs
            .confirm(
              i18n<string>(
                'ALL.BETA.You have enabled the BETA version but logged in the non-BETA application. Would you like to stay on the normal version ?'
              ),
              {
                onTrueMessage: i18n<string>('ALL.COMMON.Yes'),
                onFalseMessage: i18n<string>('ALL.COMMON.No'),
              }
            )
            .pipe(map(has_accepted => ({ version, has_accepted })))
        )
      )
      .subscribe({
        next: ({ version, has_accepted }) => {
          if (has_accepted) {
            localStorage.setItem(this.BETA_FIXED_NAMES.local_storage.is_beta_enabled, 'false');
            this._should_watch_beta_version$$.next(true);

            return;
          }

          localStorage.setItem(this.BETA_FIXED_NAMES.local_storage.is_beta_enabled, 'true');
          localStorage.setItem(this.BETA_FIXED_NAMES.local_storage.last_viewed_version, version);

          window.location.href = 'https://beta.beeguard.net';
        },
        complete: () => (this._redirection_to_beta_sub = null),
      });
  }

  // #region -> (navigation management)

  /** */
  public use_beta_application(): void {
    this._current_beta_version$$
      .pipe(
        take(1),
        switchMap(version =>
          this._dialogs
            .confirm(
              i18n<string>(
                'ALL.BETA.You are about to join the beta version of the application. Are you sure you want to continue ? You can come back any time on the non-beta version'
              ),
              {
                onTrueMessage: i18n<string>('ALL.COMMON.Yes'),
                onFalseMessage: i18n<string>('ALL.COMMON.No'),
              }
            )
            .pipe(map(has_accepted => ({ has_accepted, version })))
        )
      )
      .subscribe({
        next: ({ version, has_accepted }) => {
          console.log(version);

          if (!has_accepted) {
            localStorage.setItem(this.BETA_FIXED_NAMES.local_storage.is_beta_enabled, 'false');
            localStorage.setItem(this.BETA_FIXED_NAMES.local_storage.last_viewed_version, version);

            return;
          }

          localStorage.setItem(this.BETA_FIXED_NAMES.local_storage.is_beta_enabled, 'true');
          window.location.href = 'https://beta.beeguard.net';
        },
      });
  }

  /** */
  public quit_beta_application(): void {
    this._dialogs
      .confirm(i18n<string>('ALL.BETA.You are about to quit the beta version of the application. Are you sure you want to continue ?'), {
        onTrueMessage: i18n<string>('ALL.COMMON.Yes'),
        onFalseMessage: i18n<string>('ALL.COMMON.No'),
      })
      .subscribe({
        next: has_accepted => {
          if (!has_accepted) return;

          window.location.href = 'https://app.beeguard.net?quit_beta=1';
        },
      });
  }

  // #endregion
}
