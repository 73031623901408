import { Component, OnInit, Input, ChangeDetectionStrategy } from '@angular/core';
import { HistogramItemConfig } from '../simple-histogram/simple-histogram.component';
import { marker as i18n } from '@biesbjerg/ngx-translate-extract-marker';
import { Dictionary } from 'app/typings/core/interfaces';

@Component({
  selector: 'bg2-histogram',
  templateUrl: './bg2-histogram.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class Bg2HistogramComponent {

  @Input()
  public histogram_height: string = null; // Height of histogram, if null show compact version

  @Input()
  public data: Dictionary<number>; // Data

  @Input()
  public histogram_type: 'supers' | 'broodframes' | 'statuses' | 'queens' = 'statuses';

  public readonly SUPERS_CONFIG: HistogramItemConfig[] = [
    {
      key: '0',
      label: 'EVENT.VISIT.SUPERS.No supers',
      img: 'img/super/super_0.svg'
    }, {
      key: '1',
      label: 'EVENT.VISIT.SUPERS.1 super',
      img: 'img/super/super_1.svg'
    }, {
      key: '2',
      label: 'EVENT.VISIT.SUPERS.2 supers',
      img: 'img/super/super_2.svg'
    }, {
      key: '3',
      label: 'EVENT.VISIT.SUPERS.3 supers',
      img: 'img/super/super_3.svg'
    }, {
      key: '4',
      label: 'EVENT.VISIT.SUPERS.4 supers',
      img: 'img/super/super_4.svg'
    }, {
      key: '4+',
      label: 'EVENT.VISIT.SUPERS.4+ supers',
      img: 'img/super/super_4+.svg'
    }, {
      key: '-1',
      label: 'EVENT.TYPE.superbox_stock',
      img: 'img/super/super_stock.svg'
    }
  ];

  public readonly BROODFRAME_CONFIG: HistogramItemConfig[] = [
    {
      key: 'C0',
      label: 'EVENT.VISIT.BF.No BF',
      img: 'img/hive_frame/c0.png'
    }, {
      key: 'C2',
      label: 'EVENT.VISIT.BF.2 BF',
      img: 'img/hive_frame/c2.png'
    }, {
      key: 'C3',
      label: 'EVENT.VISIT.BF.3 BF',
      img: 'img/hive_frame/c3.png'
    }, {
      key: 'C4',
      label: 'EVENT.VISIT.BF.4 BF',
      img: 'img/hive_frame/c4.png'
    }, {
      key: 'C5',
      label: 'EVENT.VISIT.BF.5 BF',
      img: 'img/hive_frame/c5.png'
    }, {
      key: 'C6',
      label: 'EVENT.VISIT.BF.6 BF',
      img: 'img/hive_frame/c6.png'
    }, {
      key: 'C7',
      label: i18n('EVENT.VISIT.BF.7 BF'),
      img: 'img/hive_frame/c7.png'
    }, {
      key: 'C7+',
      label: 'EVENT.VISIT.BF.+ 7 BF',
      img: 'img/hive_frame/c7+.png'
    }
  ];

  public readonly STATUS_CONFIG: HistogramItemConfig[] = [
    {
      key: 'OK',
      label: 'EVENT.VISIT.STATUS.OK',
      img: 'img/hive_status/ok.png'
    }, {
      key: 'RHS',
      label: 'EVENT.VISIT.STATUS.Dead queen',
      img: 'img/hive_status/rhs.png'
    }, {
      key: 'E',
      label: 'EVENT.VISIT.STATUS.Swarming',
      img: 'img/hive_status/e.png'
    }, {
      key: 'NV',
      label: 'EVENT.VISIT.STATUS.NV',
      img: 'img/hive_status/nv.png'
    }, {
      key: 'B',
      label: 'EVENT.VISIT.STATUS.Drone laying queen',
      img: 'img/hive_status/b.png'
    }, {
      key: 'D',
      label: 'EVENT.VISIT.STATUS.Dead',
      img: 'img/hive_status/d.png'
    }, {
      key: 'T',
      label: 'EVENT.VISIT.STATUS.Stolen',
      img: 'img/hive_status/t.png'
    }, {
      key: 'S',
      label: 'EVENT.VISIT.STATUS.Sanitary suspicion',
      img: 'img/hive_status/s.png'
    }
  ];

  public readonly QUEEN_CONFIG: HistogramItemConfig[] = [
    {
      key: 'b',
      label: 'EVENT.VISIT.QUEEN.blue',
      img: 'img/queen/b.png'
    }, {
      key: 'w',
      label: 'EVENT.VISIT.QUEEN.white',
      img: 'img/queen/w.png'
    }, {
      key: 'y',
      label: 'EVENT.VISIT.QUEEN.yellow',
      img: 'img/queen/y.png'
    }, {
      key: 'r',
      label: 'EVENT.VISIT.QUEEN.red',
      img: 'img/queen/r.png'
    }, {
      key: 'g',
      label: 'EVENT.VISIT.QUEEN.green',
      img: 'img/queen/g.png'
    }, {
      key: 'unknow',
      label: 'EVENT.VISIT.QUEEN.Unknow',
      img: 'img/queen/unknow.png'
    }, {
      key: 'not_found',
      label: 'EVENT.VISIT.QUEEN.Not found',
      img: 'img/queen/not_found.png'
    }
  ];
}
