import { AfterViewInit, ChangeDetectionStrategy, Component, Input, NgZone, OnDestroy, OnInit } from '@angular/core';

import { marker as i18n } from '@biesbjerg/ngx-translate-extract-marker';

import { AppStateService } from 'app/core/app-state.service';
import { ConsoleLoggerService } from 'app/core/console-logger.service';
import { D3SharedCursorService } from 'app/core/services/global/d3-shared-cursor.service';

import { ResizedEvent } from 'app/misc/directives/resized/resized.directive';
import { waitForNotNilProperties, waitForNotNilValue } from '@bg2app/tools/rxjs';

import { Dictionary } from 'app/typings/core/interfaces';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { SingleLineD3ChartFactory } from './single-line-chart-factory';
import { ChartGeoposition } from '@bg2app/models/charts';
import { DataPoint } from 'app/models/data';
import { DateTime } from 'luxon';

@Component({
  selector: 'bg2-single-line-chart',
  templateUrl: './single-line-chart.component.html',
  styleUrls: ['./single-line-chart.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SingleLineD3ChartComponent implements OnInit, AfterViewInit, OnDestroy {
  // #region -> (component basics)

  /** */
  private readonly _logger = new ConsoleLoggerService('SingleLineD3ChartComponent', true);

  /** */
  public chart_factory: SingleLineD3ChartFactory = null;

  constructor(private _d3_shared_cursor: D3SharedCursorService, private _appState: AppStateService, private _ngZone: NgZone) {}

  ngOnInit(): void {
    this.chart_factory = new SingleLineD3ChartFactory(this._d3_shared_cursor, this._appState, this._ngZone, this.options);
  }

  ngAfterViewInit(): void {
    this.chart_factory.create_chart();

    this.data$$.pipe(waitForNotNilValue()).subscribe({
      next: data => (this.chart_factory.incoming_data = data),
    });

    combineLatest({ date_range: this.date_range$$, is_loading: this.is_loading$$, geoposition: this.geoposition$$ })
      .pipe(waitForNotNilProperties())
      .subscribe({
        next: ({ date_range, is_loading, geoposition }) => {
          this.chart_factory.date_range = date_range;
          this.chart_factory.is_loading = is_loading;
          this.chart_factory.geoposition.value = geoposition;
        },
      });
  }

  ngOnDestroy(): void {
    this.chart_factory.destroy();
  }

  // #endregion

  /** */
  private _options = {};

  @Input()
  public set options(options: any) {
    this._options = options;
  }

  public get options(): any {
    return this._options;
  }

  /** */
  private _geoposition$$ = new BehaviorSubject(null);

  /** */
  private geoposition$$ = this._geoposition$$.asObservable();

  /** */
  @Input()
  public set geoposition(geoposition: ChartGeoposition) {
    this._geoposition$$.next(geoposition);
  }

  // #region -> (container size management)

  public onChartContainerResized(resized_event: ResizedEvent): void {
    this.chart_factory.box_sizing = { width: resized_event?.new_width, height: resized_event?.new_height };
  }

  // #endregion

  // #region -> (data management)

  /** */
  private _data$$ = new BehaviorSubject<{ points: DataPoint[] }>(null);

  /** */
  private data$$ = this._data$$.asObservable();

  /** */
  @Input()
  public set data(data: { points: DataPoint[] }) {
    this._data$$.next(data);
  }

  /** */
  private _date_range$$ = new BehaviorSubject(null);

  /** */
  private date_range$$ = this._date_range$$.asObservable();

  /** */
  @Input()
  public set date_range(date_range: { start: DateTime; end: DateTime }) {
    this._date_range$$.next(date_range);
  }

  /** */
  private _is_loading$$ = new BehaviorSubject(null);

  /** */
  private is_loading$$ = this._is_loading$$.asObservable();

  /** */
  @Input()
  public set is_loading(is_loading: boolean) {
    this._is_loading$$.next(is_loading);
  }

  // #endregion
}
