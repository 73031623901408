import {
  Input,
  NgZone,
  OnInit,
  Output,
  OnDestroy,
  Component,
  EventEmitter,
  AfterViewInit,
  IterableDiffer,
  IterableDiffers,
  ChangeDetectionStrategy,
} from '@angular/core';

import { isNil, uniqueId } from 'lodash-es';

import { TranslateService } from '@ngx-translate/core';
import { marker as i18n } from '@biesbjerg/ngx-translate-extract-marker';

import { combineLatest, filter, map, switchMap, take, tap, withLatestFrom } from 'rxjs';
import { BehaviorSubject, Subscription } from 'rxjs';
import { distinctUntilRealChanged, replay, waitForNotNilValue } from '@bg2app/tools/rxjs';

import { control, Control, Layer, LeafletEvent, Map, TileLayer, tileLayer } from 'leaflet';

import { ConsoleLoggerService } from 'app/core/console-logger.service';

import { Dictionary } from 'app/typings/core/interfaces';
import { AppStateService } from 'app/core/app-state.service';

@Component({
  selector: 'bg2-leaflet-map',
  templateUrl: './leaflet-map.component.html',
  styleUrls: ['./leaflet-map.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class Bg2LeafletMapComponent implements OnInit, AfterViewInit, OnDestroy {
  public readonly UNIQUE_MAP_ID = uniqueId('bg2-leaflet-map-');

  private readonly _logger = new ConsoleLoggerService('Bg2LeafletMapComponent', true);

  // #region -> (layer definitions)

  /** */
  private _layer_gmaps_hybrid$$ = new BehaviorSubject<TileLayer>(null);

  /** */
  private layer_osm_mapnik: TileLayer = tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
    maxZoom: 19,
    attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
  });

  /** */
  private layer_opentopo: TileLayer = tileLayer('https://{s}.tile.opentopomap.org/{z}/{x}/{y}.png', {
    maxZoom: 17,
    attribution:
      'Map data: &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors, <a href="http://viewfinderpanoramas.org">SRTM</a> | Map style: &copy; <a href="https://opentopomap.org">OpenTopoMap</a> (<a href="https://creativecommons.org/licenses/by-sa/3.0/">CC-BY-SA</a>)',
  });

  private layer_orange_lte_m_coverage: TileLayer = tileLayer(
    'https://couverture-mobile.orange.fr/arcgis/rest/services/extern/geomap_LTE/MapServer/tile/{z}/{y}/{x}',
    {
      maxZoom: 16,
      opacity: 0.7,
      attribution:
        'Map data: &copy; <a href="https://www.orange-business.com/fr/reseau-LTE-M">Orange Business</a> contributors, <a href="https://www.orange-business.com/fr/informations-legales">Mentions legales</a>',
    }
  );

  private layer_orange_4G_coverage: TileLayer = tileLayer(
    'https://couverture-mobile.orange.fr/arcgis/rest/services/extern/geomap_wgs84_4G/MapServer/tile/{z}/{y}/{x}',
    {
      maxZoom: 16,
      opacity: 0.7,
      attribution:
        'Map data: &copy; <a href="https://www.orange-business.com/fr/reseau-LTE-M">Orange Business</a> contributors, <a href="https://www.orange-business.com/fr/informations-legales">Mentions legales</a>',
    }
  );

  private layer_orange_2G_coverage: TileLayer = tileLayer(
    'https://couverture-mobile.orange.fr/arcgis/rest/services/extern/geomap_arcep_voix_2G/MapServer/tile/{z}/{y}/{x}',
    {
      maxZoom: 16,
      opacity: 0.7,
      attribution:
        'Map data: &copy; <a href="https://www.orange-business.com/fr/reseau-LTE-M">Orange Business</a> contributors, <a href="https://www.orange-business.com/fr/informations-legales">Mentions legales</a>',
    }
  );

  // #endregion

  // #region -> (component inputs)

  @Input()
  public height = '150px';

  @Input()
  public selectable_base_layer = true;

  // #endregion

  // #region -> (componenent basics)

  private _layers_control_i18_sub: Subscription = null;

  constructor(
    private _ngZone: NgZone,
    private _appState: AppStateService,
    private _translate: TranslateService,
    private _iterable_differs: IterableDiffers
  ) {
    this._layers_differ = this._iterable_differs.find([]).create<Layer>();
  }

  ngOnInit(): void {
    this._layers_control_i18_sub = this._appState.lang$$
      .pipe(
        take(1),
        tap(language =>
          this._layer_gmaps_hybrid$$.next(
            tileLayer(`https://{s}.google.com/vt/lyrs=s,h&x={x}&y={y}&z={z}&hl=${language}`, {
              maxZoom: 19,
              subdomains: ['mt0', 'mt1', 'mt2', 'mt3'],
            })
          )
        ),
        switchMap(() => this._leaflet_map$$),
        waitForNotNilValue(),
        switchMap(() =>
          combineLatest({
            translations: this._translate.stream([
              i18n<string>('VIEWS.MAP.COMPONENTS.PAGE_MAP.LAYERS_NAME.Google Maps - Hybrid'),
              i18n<string>('VIEWS.MAP.COMPONENTS.PAGE_MAP.LAYERS_NAME.OpenStreetMap - Mapnik'),
              i18n<string>('VIEWS.MAP.COMPONENTS.PAGE_MAP.LAYERS_NAME.OpenToPo map'),
              i18n<string>('VIEWS.MAP.COMPONENTS.PAGE_MAP.OVERLAYS_NAME.LTE-M coverage'),
              i18n<string>('VIEWS.MAP.COMPONENTS.PAGE_MAP.OVERLAYS_NAME.Orange 4G coverage'),
              i18n<string>('VIEWS.MAP.COMPONENTS.PAGE_MAP.OVERLAYS_NAME.Orange 2G coverage'),
            ]),
            is_superadmin: this._appState.is_superadmin$$,
          })
        )
      )
      .subscribe({
        next: ({ translations, is_superadmin }) => {
          if (this.selectable_base_layer) {
            const base_layers: Dictionary<TileLayer> = {};
            base_layers[translations['VIEWS.MAP.COMPONENTS.PAGE_MAP.LAYERS_NAME.Google Maps - Hybrid']] =
              this._layer_gmaps_hybrid$$.getValue();
            base_layers[translations['VIEWS.MAP.COMPONENTS.PAGE_MAP.LAYERS_NAME.OpenStreetMap - Mapnik']] = this.layer_osm_mapnik;
            base_layers[translations['VIEWS.MAP.COMPONENTS.PAGE_MAP.LAYERS_NAME.OpenToPo map']] = this.layer_opentopo;

            const overlays: Dictionary<TileLayer> = {};

            if (is_superadmin) {
              overlays[translations['VIEWS.MAP.COMPONENTS.PAGE_MAP.OVERLAYS_NAME.LTE-M coverage']] = this.layer_orange_lte_m_coverage;
              overlays[translations['VIEWS.MAP.COMPONENTS.PAGE_MAP.OVERLAYS_NAME.Orange 4G coverage']] = this.layer_orange_4G_coverage;
              overlays[translations['VIEWS.MAP.COMPONENTS.PAGE_MAP.OVERLAYS_NAME.Orange 2G coverage']] = this.layer_orange_2G_coverage;
            }

            this._control_layers?.remove();
            this._control_layers = control.layers(base_layers, overlays, { position: 'bottomleft' }).addTo(this.leaflet_map);
          }
        },
      });
  }

  ngAfterViewInit(): void {
    // Creates the map
    this._layer_gmaps_hybrid$$.pipe(waitForNotNilValue(), take(1)).subscribe({
      next: gmaps_layer => {
        this.leaflet_map = new Map(this.UNIQUE_MAP_ID, {
          zoom: 5,
          tap: false, // Needed see https://gitlab.dev.siconsult.fr:9090/beeguard_v2/beeguard2-ng-app/-/issues/546
          zoomControl: false,
          layers: [gmaps_layer],
          center: [43.547055, 1.50297],
        });

        control.scale({ maxWidth: 200, position: 'bottomleft', imperial: false }).addTo(this.leaflet_map);

        this.setupMapEventListeners();
        this.mapReady.next(this.leaflet_map);
      },
    });
  }

  ngOnDestroy(): void {
    this._layers_control_i18_sub?.unsubscribe();

    this.leaflet_map?.off();
    this.leaflet_map?.remove();
  }

  // #endregion

  // #region -> (map basics)

  @Output()
  public mapReady = new EventEmitter<Map>();

  private _leaflet_map$$ = new BehaviorSubject<Map>(null);

  private set leaflet_map(leaflet_map: Map) {
    this._leaflet_map$$.next(leaflet_map);
  }

  private get leaflet_map(): Map {
    return this._leaflet_map$$.getValue();
  }

  /**
   * Resizes the map to fit it's new bounds.
   */
  public onContainerResized() {
    this._ngZone.runOutsideAngular(() => {
      this.leaflet_map?.invalidateSize({});
    });
  }

  // #endregion

  // #region -> (map events)

  @Output()
  public mapMoveEnd = new EventEmitter<LeafletEvent>();

  private mapEventHandlers: any = {};

  private setupMapEventListeners(): void {
    const register_event_handler = (eventName: string, handler: (e: LeafletEvent) => any) => {
      this.mapEventHandlers[eventName] = handler;
      this.leaflet_map.on(eventName, handler);
    };

    register_event_handler('moveend', (event: LeafletEvent) => this._ngZone.runOutsideAngular(() => this.mapMoveEnd.next(event)));
  }

  // #endregion

  // #region -> (layers control)

  private _control_layers: Control.Layers;

  @Input()
  public layersOptions: Control.LayersOptions;

  @Output()
  public layersControlReady = new EventEmitter<Control.Layers>();

  // #endregion

  // #region -> (map layers management)

  private _layers: Layer[];
  private _layers_differ: IterableDiffer<Layer>;

  @Input()
  public set layers(layers: Layer[]) {
    this._layers = layers;
    this.updateMapLayers();
  }

  private updateMapLayers() {
    this._leaflet_map$$
      .pipe(
        filter(leaflet_map => !isNil(leaflet_map)),
        take(1),
        map(leaflet_map => {
          if (!isNil(this._layers_differ)) {
            const changes = this._layers_differ.diff(this._layers);

            if (!isNil(changes)) {
              this._ngZone.run(() => {
                changes.forEachRemovedItem(c => {
                  leaflet_map.removeLayer(c.item);
                });

                changes.forEachAddedItem(c => {
                  leaflet_map.addLayer(c.item);
                });
              });
            }
          }
        })
      )
      .subscribe();
  }

  // #endregion
}
