<div *ngLet="apiary$$ | async; let apiary" class="bg2-apiary-hives-nucs-summary">
  <bg2-summary-with-cta>
    <div information-row>
      <div class="pictogram">
        <bg2-picto-hive [htype]="'hive'" [size]="'50px'" [supers]="mean_supers_by_hive$$ | async"></bg2-picto-hive>
      </div>

      <div class="inner-informations">
        <div *ngLet="total_declared_hives$$ | async as total" class="type-value-and-text">
          <ng-container *ngIf="(is_loading?.hives?.total_declared_hives$$ | async) === false; else loadingTotalHives">
            <span class="value">{{ total }}</span>
            <span class="description">
              <ng-container *ngIf="total <= 1">{{ 'ENTITY.ALL.TYPE.hive' | translate }}</ng-container>
              <ng-container *ngIf="total > 1">{{ 'ENTITY.ALL.TYPE.hives' | translate }}</ng-container>

              <ng-container *ngLet="total_supers$$ | async; let total_supers">
                <ng-container *ngIf="total_supers > 0">
                  <span
                    >&nbsp;({{ total_supers }}
                    {{ (total_supers > 1 ? 'ENTITY.ALL.TYPE.supers' : 'ENTITY.ALL.TYPE.super') | translate }})</span
                  >
                </ng-container>
              </ng-container>

              <ng-container *ngLet="total_named_hives$$ | async; let total_named_hives">
                <ng-container *ngIf="(is_loading?.hives?.total_named$$ | async) === false; else loadingNamedHives">
                  <span *ngIf="total_named_hives > 0 && total_named_hives !== total">
                    / {{ 'VIEWS.APIARY.SHARED.WIDGET.APIARY_HIVES_NUCS_SUMMARY.[x] named' | translate: { x: total_named_hives } }}</span
                  >
                </ng-container>

                <ng-template #loadingNamedHives>
                  <bg2-loading-skeleton
                    [theme]="{ height: '24px', width: '100%' }"
                    [string_model]="'VIEWS.APIARY.SHARED.WIDGET.APIARY_HIVES_NUCS_SUMMARY.[x] named' | translate: { x: 0 }"
                  ></bg2-loading-skeleton>
                </ng-template>
              </ng-container>
            </span>
          </ng-container>
        </div>

        <ng-template #loadingTotalHives>
          <bg2-loading-skeleton
            [theme]="{ height: '24px', width: '100%' }"
            [string_model]="'00 '.concat('ENTITY.ALL.TYPE.hives' | translate)"
          ></bg2-loading-skeleton>
        </ng-template>

        <!-- <div *ngLet="hives_min_max$$ | async as min_max" class="type-min-max">
          <ng-container *ngIf="(is_loading?.hives?.min_max$$ | async) === false; else loadingMinMaxHives">
            <span
              class="value-min"
              [popper]="'VIEWS.EXPLOITATIONS.COMPONENTS.WIDGETS_APIARY.EXPLOITATION_SUMMARY_HIVES.Min hives over current year'"
            >
              <span class="mdi mdi-arrow-down-bold"></span>
              <span>{{ min_max?.min }}&nbsp;{{ 'ALL.COMMON.Min' | translate | lowercase }}</span>
            </span>

            <span
              class="value-max"
              [popper]="'VIEWS.EXPLOITATIONS.COMPONENTS.WIDGETS_APIARY.EXPLOITATION_SUMMARY_HIVES.Max hives over current year'"
            >
              <span class="mdi mdi-arrow-up-bold"></span>
              <span>{{ min_max?.max }}&nbsp;{{ 'ALL.COMMON.Max' | translate | lowercase }}</span>
            </span>
          </ng-container>
        </div> -->

        <ng-template #loadingMinMaxHives>
          <bg2-loading-skeleton [theme]="{ height: '16px', width: '100%' }" [string_model]="'i 00 aaa. i 00 aaa.'"></bg2-loading-skeleton>
        </ng-template>
      </div>
    </div>

    <div information-row>
      <div class="pictogram">
        <bg2-picto-hive [htype]="'nuc'" [size]="'50px'"></bg2-picto-hive>
      </div>

      <div class="inner-informations">
        <div *ngLet="total_declared_nucs$$ | async as total" class="type-value-and-text">
          <ng-container *ngIf="(is_loading?.nucs?.total_declared_nucs$$ | async) === false; else loadingTotalNucs">
            <span class="value">{{ total }}</span>
            <span class="description">
              <ng-container *ngIf="total <= 1">{{ 'ENTITY.ALL.TYPE.nuc' | translate }}</ng-container>
              <ng-container *ngIf="total > 1">{{ 'ENTITY.ALL.TYPE.nucs' | translate }}</ng-container>

              <ng-container *ngLet="total_named_nucs$$ | async; let total_named_nucs">
                <ng-container *ngIf="(is_loading?.nucs?.total_named$$ | async) === false; else loadingNamedNucs">
                  <span *ngIf="total_named_nucs > 0 && total_named_nucs !== total">
                    {{ 'VIEWS.APIARY.SHARED.WIDGET.APIARY_HIVES_NUCS_SUMMARY.[x] named' | translate: { x: total_named_nucs } }}</span
                  >
                </ng-container>

                <ng-template #loadingNamedNucs>
                  <bg2-loading-skeleton
                    [theme]="{ height: '24px', width: '100%' }"
                    [string_model]="'VIEWS.APIARY.SHARED.WIDGET.APIARY_HIVES_NUCS_SUMMARY.[x] named' | translate: { x: 0 }"
                  ></bg2-loading-skeleton>
                </ng-template>
              </ng-container>
            </span>
          </ng-container>
        </div>

        <ng-template #loadingTotalNucs>
          <bg2-loading-skeleton
            [theme]="{ height: '24px', width: '100%' }"
            [string_model]="'00 '.concat('ENTITY.ALL.TYPE.nucs' | translate)"
          ></bg2-loading-skeleton>
        </ng-template>

        <!-- <div *ngLet="nucs_min_max$$ | async as min_max" class="type-min-max">
          <ng-container *ngIf="(is_loading?.nucs?.min_max$$ | async) === false; else loadingMinMaxNucs">
            <span
              class="value-min"
              [popper]="'VIEWS.EXPLOITATIONS.COMPONENTS.WIDGETS_APIARY.EXPLOITATION_SUMMARY_HIVES.Min nucs over current year'"
            >
              <span class="mdi mdi-arrow-down-bold"></span>
              <span>{{ min_max?.min }}&nbsp;{{ 'ALL.COMMON.Min' | translate | lowercase }}</span>
            </span>

            <span
              class="value-max"
              [popper]="'VIEWS.EXPLOITATIONS.COMPONENTS.WIDGETS_APIARY.EXPLOITATION_SUMMARY_HIVES.Max nucs over current year'"
            >
              <span class="mdi mdi-arrow-up-bold"></span>
              <span>{{ min_max?.max }}&nbsp;{{ 'ALL.COMMON.Max' | translate | lowercase }}</span>
            </span>
          </ng-container>
        </div> -->

        <ng-template #loadingMinMaxNucs>
          <bg2-loading-skeleton [theme]="{ height: '16px', width: '100%' }" [string_model]="'i 00 aaa. i 00 aaa.'"></bg2-loading-skeleton>
        </ng-template>
      </div>
    </div>

    <div *ngIf="!env?.config?.disable_navigation" call-to-action class="cta-container">
      <bg2-button
        [icon]="'mdi-beehive-outline'"
        [type]="'navigation'"
        [routerLink]="[{ outlets: { primary: ['hives'], modal: ['raz'] } }]"
        [queryParams]="{ 'se[]': (exploitation_id$$ | async), 'hlids[]': (location_id$$ | async)?.toString() }"
        [options]="{ is_thin: true, is_label_hidden_on_small: true }"
        [message]="
          (total_named_hives$$ | async) > 0
            ? ('VIEWS.EXPLOITATIONS.COMPONENTS.WIDGETS_APIARY.EXPLOITATION_SUMMARY_HIVES.View named hives' | translate)
            : ('VIEWS.EXPLOITATIONS.COMPONENTS.WIDGETS_APIARY.EXPLOITATION_SUMMARY_HIVES.View hives' | translate)
        "
      ></bg2-button>

      <bg2-button
        *ngIf="env.config.events.activate_migratory"
        [type]="'action'"
        [options]="{ is_thin: true, is_label_hidden_on_small: true }"
        [disabled]="apiary?.user_acl?.cannot$$('write_all_events') | async"
        [message]="'VIEWS.APIARY.SHARED.WIDGET.APIARY_HIVES_NUCS_SUMMARY.Migrate apiary' | translate"
        [icon]="(apiary?.user_acl?.cannot$$('write_all_events') | async) ? 'mdi-lock' : 'mdi-truck-delivery'"
        [internal_routing]="{
          queryParamsHandling: 'preserve',
          routerLink: ['', { outlets: { modal: ['migratory', { args: ({ apiary: apiary_id$$ | async } | json) }] } }]
        }"
      ></bg2-button>
    </div>
  </bg2-summary-with-cta>
</div>
