import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';

import { marker as i18n } from '@biesbjerg/ngx-translate-extract-marker';

import { cloneDeep, isNil } from 'lodash-es';

import { IOneOf, ISchema } from 'ngx-schema-form';

import { BehaviorSubject, filter, map, of, switchMap, take } from 'rxjs';
import { distinctUntilRealChanged, replay } from '@bg2app/tools/rxjs';

import { DeviceApi } from 'app/core';
import { AppStateService } from 'app/core/app-state.service';

import { AbstractDialogComponent, AbstractDialogParams, DialogsService } from 'app/widgets/dialogs-modals';
import { addHours } from 'date-fns/esm';

/** */
export interface NewDeviceMoveAuthDialogParams extends AbstractDialogParams {
  /** */
  authorized_movement?: {
    /**
     * ID of the authorized movement.
     */
    id: string;

    /** */
    imei: number;
  };

  /** */
  imeis?: number[];
}

/** */
interface AuthorizedMovementData {
  /** */
  id: string;

  /** */
  imei: number;

  /** */
  start_date: Date;

  /** */
  end_date: Date;
}

@Component({
  selector: 'bg2-new-device-move-auth-dialog',
  templateUrl: './device-authorized-movement-dialog.component.html',
  styleUrls: ['./device-authorized-movement-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DeviceAuthorizedMovementDialogComponent extends AbstractDialogComponent<NewDeviceMoveAuthDialogParams, any> implements OnInit {
  // #region -> (dialog basics)

  /** */
  constructor(
    public appState: AppStateService,
    private readonly _device_api: DeviceApi,
    private dialogs: DialogsService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.input_params$$
      .pipe(
        map(parameters => parameters.authorized_movement),
        filter(authorized_movement => !isNil(authorized_movement)),
        switchMap(authorized_movement => this._device_api.fetch_device_tmp_conf$(authorized_movement.imei, authorized_movement.id)),
        take(1)
      )
      .subscribe({
        next: authorized_movement => {
          this._is_create_form$$.next(false);
          const configuration = authorized_movement.tmp_configuration as any;

          this.update_value({
            id: configuration.id,
            start_date: configuration.start,
            end_date: configuration.end,
            imei: configuration.device.imei,
          });
        },
      });
  }

  /** */
  public clicked(value: boolean): void {
    this.complete(value);
  }

  // #endregion

  // #region -> (devices management)

  /** */
  private imeis$$ = this.input_params$$.pipe(
    map(paramaters => paramaters.imeis),
    map(imeis => imeis),
    replay()
  );

  // #endregion

  // #region -> (form management)

  /** */
  private _is_create_form$$ = new BehaviorSubject(true);

  /** */
  public is_create_form$$ = this._is_create_form$$.asObservable().pipe(distinctUntilRealChanged());

  /** */
  private readonly create_form_schema: ISchema = {
    type: 'object',
    properties: {
      id: {
        type: 'string',
        widget: 'hidden',
      },
      imei: {
        label: i18n<string>('VIEWS.DEVICES.DIALOGS_AND_MODALS.NEW_DEVICE_MOVE_AUTH_DIALOG.IMEI of the device'),
        type: 'string',
        widget: 'ng-mat-select',
        options: {
          multiple: false,
        },
        oneOf: <IOneOf[]>[],
      },
      start_date: {
        type: 'string',
        widget: 'date-time',
        label: i18n<string>('ALL.COMMON.DATE_MANAGEMENT.Start date'),
      },
      end_date: {
        type: 'string',
        widget: 'date-time',
        label: i18n<string>('ALL.COMMON.DATE_MANAGEMENT.End date'),
      },
    },
  };

  /** */
  private readonly edit_form_schema: ISchema = {
    type: 'object',
    properties: {
      id: {
        type: 'string',
        widget: 'hidden',
      },
      imei: {
        label: i18n<string>('VIEWS.DEVICES.DIALOGS_AND_MODALS.NEW_DEVICE_MOVE_AUTH_DIALOG.IMEI of the device'),
        type: 'string',
        widget: 'string',
        readOnly: true,
        options: {
          readonly: true,
        },
      },
      start_date: {
        type: 'string',
        widget: 'date-time',
        label: i18n<string>('ALL.COMMON.DATE_MANAGEMENT.Start date'),
      },
      end_date: {
        type: 'string',
        widget: 'date-time',
        _min: {
          value: "from_property",
          path: "start_date"
        },
        label: i18n<string>('ALL.COMMON.DATE_MANAGEMENT.End date'),
      },
    },
  };

  /** */
  public form_schema$$ = this._is_create_form$$.pipe(
    switchMap(is_create_from => {
      if (is_create_from) {
        return this.imeis$$.pipe(
          map(imeis => {
            const schema = cloneDeep(this.create_form_schema);

            schema.properties.imei.oneOf = imeis.map(imei => ({
              enum: [imei],
              label: `${imei}`,
            }));

            if (imeis.length === 1) {
              schema.properties.imei.default = imeis[0];
            }

            return schema;
          })
        );
      }

      return of(cloneDeep(this.edit_form_schema));
    }),
    replay()
  );

  /** */
  private _form_value$$ = new BehaviorSubject<AuthorizedMovementData>({
    id: null,
    imei: null,
    start_date: new Date(),
    end_date: addHours(new Date(), 2),
  });

  /** */
  public form_value$$ = this._form_value$$.asObservable();

  /** */
  public is_form_value_valid$$ = this.form_value$$.pipe(
    map(value => !isNil(value.imei) && !isNil(value.start_date) && !isNil(value.end_date)),
    replay()
  );

  /** */
  public update_value(value: AuthorizedMovementData): void {
    this._form_value$$.next(value);
  }

  // #endregion

  // #region -> (movement auth)

  /** */
  public create_movement(): void {
    const value = this._form_value$$.getValue();

    this._device_api
      .create_device_tmp_conf$(value.imei, <any>{
        start: value.start_date,
        end: value.end_date,
        type: 'move_auth',
        embedded: {
          tracking_period: 120,
          tracking_stop_duration: 20,
        },
      })
      .subscribe({
        next: response => {
          this.complete(true);
        },
        error: (error: unknown) => console.error(error),
      });
  }

  /** */
  public update_movement(): void {
    const value = this._form_value$$.getValue();

    this._device_api
      .update_device_tmp_conf$(value.imei, value.id, <any>{
        start: value.start_date,
        end: value.end_date,
      })
      .subscribe({
        next: () => {
          this.complete(true);
        },
        error: (error: unknown) => console.error(error),
      });
  }

  /** */
  public delete_movement(): void {
    const value = this._form_value$$.getValue();

    this.dialogs.confirm(
      i18n('VIEWS.DEVICES.DIALOGS_AND_MODALS.NEW_DEVICE_MOVE_AUTH_DIALOG.Are you sure to delete the move auth ?'),
      {
        onFalseMessage: i18n('VIEWS.MODALS.FORM.Come back'),
        onTrueMessage: i18n('ALL.ACTIONS.Delete'),
      },
      true
    ).pipe(
      switchMap(agreement => {
        if (agreement) {
          return this._device_api.delete_device_tmp_conf$(value.imei, value.id).pipe(
            map(data => true)
          )
        } else {
          return of(false)
        }
      })
    ).subscribe({
      next: agreement => {
        if(agreement) this.complete(true);
      },
      error: (error: unknown) => console.error(error),
    });
  }

  // #endregion
}
