import { Observable, concat, isObservable, of, timeout } from 'rxjs';

/**
 * Returns a default value if none after the ellipsed time.
 *
 * @param default_value Default value to use if empty.
 * @param timeout_ms Time to wait for value.
 *
 * @returns
 */
export const defaultValue =
  <X>(default_value: Observable<X> | X, timeout_ms: number) =>
  (source: Observable<X>): Observable<X> =>
    source.pipe(
      timeout({
        first: timeout_ms,
        with: (): Observable<X> => {
          const initial_value$$: Observable<X> = isObservable(default_value) ? default_value : of<X>(default_value);

          return concat(initial_value$$, source);
        },
      })
    );
