/**
 * identity-server
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: dev
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { CreateGroup } from '../model/createGroup';
import { Group } from '../model/group';
import { IdentitySrvError } from '../model/identitySrvError';
import { InputCreateGroup } from '../model/inputCreateGroup';
import { InputUpdateGroup } from '../model/inputUpdateGroup';
import { ListGroups } from '../model/listGroups';
import { MembershipList } from '../model/membershipList';
import { NewMembership } from '../model/newMembership';
import { UpdateGroup } from '../model/updateGroup';
import { UpdateMembership } from '../model/updateMembership';
import { UpdateMembershipReturn } from '../model/updateMembershipReturn';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class GroupsService {

    protected basePath = '/';
    protected defaultHeaders = new HttpHeaders();
    protected configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Retrieve list of group
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    protected groupsGet(observe?: 'body', reportProgress?: boolean): Observable<ListGroups>;
    protected groupsGet(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ListGroups>>;
    protected groupsGet(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ListGroups>>;
    protected groupsGet(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (OAuth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<ListGroups>('get',`${this.basePath}/groups/`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Delete an existing group identified by its ID &#x60;group_id&#x60;
     * 
     * @param group_id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    protected groupsGroupIdDelete(group_id: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    protected groupsGroupIdDelete(group_id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    protected groupsGroupIdDelete(group_id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    protected groupsGroupIdDelete(group_id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (group_id === null || group_id === undefined) {
            throw new Error('Required parameter group_id was null or undefined when calling groupsGroupIdDelete.');
        }

        let headers = this.defaultHeaders;

        // authentication (OAuth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('delete',`${this.basePath}/groups/${encodeURIComponent(String(group_id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get info about an existing group identified by its ID &#x60;group_id&#x60;
     * 
     * @param group_id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    protected groupsGroupIdGet(group_id: string, observe?: 'body', reportProgress?: boolean): Observable<Group>;
    protected groupsGroupIdGet(group_id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Group>>;
    protected groupsGroupIdGet(group_id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Group>>;
    protected groupsGroupIdGet(group_id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (group_id === null || group_id === undefined) {
            throw new Error('Required parameter group_id was null or undefined when calling groupsGroupIdGet.');
        }

        let headers = this.defaultHeaders;

        // authentication (OAuth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Group>('get',`${this.basePath}/groups/${encodeURIComponent(String(group_id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Remove a user from the group
     * 
     * @param group_id 
     * @param user_id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    protected groupsGroupIdMemberUserIdDelete(group_id: string, user_id: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    protected groupsGroupIdMemberUserIdDelete(group_id: string, user_id: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    protected groupsGroupIdMemberUserIdDelete(group_id: string, user_id: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    protected groupsGroupIdMemberUserIdDelete(group_id: string, user_id: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (group_id === null || group_id === undefined) {
            throw new Error('Required parameter group_id was null or undefined when calling groupsGroupIdMemberUserIdDelete.');
        }

        if (user_id === null || user_id === undefined) {
            throw new Error('Required parameter user_id was null or undefined when calling groupsGroupIdMemberUserIdDelete.');
        }

        let headers = this.defaultHeaders;

        // authentication (OAuth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('delete',`${this.basePath}/groups/${encodeURIComponent(String(group_id))}/member/${encodeURIComponent(String(user_id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get a list of user who belong to this group
     * 
     * @param group_id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    protected groupsGroupIdMembersGet(group_id: string, observe?: 'body', reportProgress?: boolean): Observable<MembershipList>;
    protected groupsGroupIdMembersGet(group_id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<MembershipList>>;
    protected groupsGroupIdMembersGet(group_id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<MembershipList>>;
    protected groupsGroupIdMembersGet(group_id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (group_id === null || group_id === undefined) {
            throw new Error('Required parameter group_id was null or undefined when calling groupsGroupIdMembersGet.');
        }

        let headers = this.defaultHeaders;

        // authentication (OAuth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<MembershipList>('get',`${this.basePath}/groups/${encodeURIComponent(String(group_id))}/members`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Create a new membership between a user and a group
     * 
     * @param body 
     * @param group_id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    protected groupsGroupIdMembersPost(body: NewMembership, group_id: string, observe?: 'body', reportProgress?: boolean): Observable<UpdateMembershipReturn>;
    protected groupsGroupIdMembersPost(body: NewMembership, group_id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<UpdateMembershipReturn>>;
    protected groupsGroupIdMembersPost(body: NewMembership, group_id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<UpdateMembershipReturn>>;
    protected groupsGroupIdMembersPost(body: NewMembership, group_id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling groupsGroupIdMembersPost.');
        }

        if (group_id === null || group_id === undefined) {
            throw new Error('Required parameter group_id was null or undefined when calling groupsGroupIdMembersPost.');
        }

        let headers = this.defaultHeaders;

        // authentication (OAuth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<UpdateMembershipReturn>('post',`${this.basePath}/groups/${encodeURIComponent(String(group_id))}/members`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update the role of a member in the group
     * 
     * @param body 
     * @param group_id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    protected groupsGroupIdMembersPut(body: UpdateMembership, group_id: string, observe?: 'body', reportProgress?: boolean): Observable<UpdateMembershipReturn>;
    protected groupsGroupIdMembersPut(body: UpdateMembership, group_id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<UpdateMembershipReturn>>;
    protected groupsGroupIdMembersPut(body: UpdateMembership, group_id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<UpdateMembershipReturn>>;
    protected groupsGroupIdMembersPut(body: UpdateMembership, group_id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling groupsGroupIdMembersPut.');
        }

        if (group_id === null || group_id === undefined) {
            throw new Error('Required parameter group_id was null or undefined when calling groupsGroupIdMembersPut.');
        }

        let headers = this.defaultHeaders;

        // authentication (OAuth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<UpdateMembershipReturn>('put',`${this.basePath}/groups/${encodeURIComponent(String(group_id))}/members`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update an existing group identified by its ID &#x60;group_id&#x60;
     * 
     * @param body 
     * @param group_id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    protected groupsGroupIdPut(body: InputUpdateGroup, group_id: string, observe?: 'body', reportProgress?: boolean): Observable<UpdateGroup>;
    protected groupsGroupIdPut(body: InputUpdateGroup, group_id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<UpdateGroup>>;
    protected groupsGroupIdPut(body: InputUpdateGroup, group_id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<UpdateGroup>>;
    protected groupsGroupIdPut(body: InputUpdateGroup, group_id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling groupsGroupIdPut.');
        }

        if (group_id === null || group_id === undefined) {
            throw new Error('Required parameter group_id was null or undefined when calling groupsGroupIdPut.');
        }

        let headers = this.defaultHeaders;

        // authentication (OAuth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<UpdateGroup>('put',`${this.basePath}/groups/${encodeURIComponent(String(group_id))}`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Create a new group
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    protected groupsPost(body: InputCreateGroup, observe?: 'body', reportProgress?: boolean): Observable<CreateGroup>;
    protected groupsPost(body: InputCreateGroup, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CreateGroup>>;
    protected groupsPost(body: InputCreateGroup, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CreateGroup>>;
    protected groupsPost(body: InputCreateGroup, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling groupsPost.');
        }

        let headers = this.defaultHeaders;

        // authentication (OAuth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<CreateGroup>('post',`${this.basePath}/groups/`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
