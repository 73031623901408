<div class="device-inline" *ngIf="device$$ | async; let device">
  <div class="hive-and-actions">
    <div *ngIf="show_base" class="picto-hive">
      <bg2-picto-hive *ngIf="hive_id" [hive_id]="hive_id"></bg2-picto-hive>
      <device-link *ngIf="link_to_device" [device]="device"></device-link>
      <span *ngIf="!link_to_device">{{ device.name }}</span>
      &nbsp;
      <bg2-last-com *ngIf="show_last_com" [device]="device" [prefix]="false" [hive_fromnow_if_old]="true"></bg2-last-com>
    </div>

    <div>
      <!--separator-->
    </div>

    <ul class="action-btn" *ngIf="show_action">
      <bg2-button
        *ngIf="device.type === 'GPS' && device.hasACE('read_devices_routes')"
        [type]="(device?.has_currently_alarm$$ | async) ? 'delete' : 'action'"
        [color]="(device?.has_currently_alarm$$ | async) ? undefined : 'device'"
        [icon]="'mdi-map-marker-path'"
        [options]="{ is_thin: true, icon_size_class: 'mdi-18px' }"
        [popper]="'VIEWS.MODALS.DEVICE.Routes' | translate"
        [internal_routing]="{
          routerLink: ['', { outlets: { modal: ['route_tracer', { device_imei: device.imei }] } }],
          queryParamsHandling: 'preserve'
        }"
        (btn_click)="$event.stopImmediatePropagation()"
      ></bg2-button>

      <bg2-button
        *ngIf="device.type === 'GPS' && device.hasACE('write_move_authorization')"
        [type]="'action'"
        [color]="'device'"
        [icon]="'mdi-cursor-move'"
        [options]="{ is_thin: true, icon_size_class: 'mdi-18px' }"
        [popper]="'VIEWS.MODALS.DEVICE.Movements authorization' | translate"
        [internal_routing]="{
          routerLink: ['', { outlets: { modal: ['device_move_auth', { imeis: ([device.imei] | json) }] } }],
          queryParamsHandling: 'preserve'
        }"
        (btn_click)="$event.stopImmediatePropagation()"
      ></bg2-button>

      <bg2-button
        *ngIf="['GPS', 'RG'].includes(device.type) && device.hasACE('write_devices_configuration')"
        [type]="'action'"
        [color]="'device'"
        [icon]="'mdi-cog'"
        [options]="{ is_thin: true, icon_size_class: 'mdi-18px' }"
        [popper]="'VIEWS.MODALS.DEVICE.Configuration' | translate"
        [internal_routing]="{
          routerLink: ['', { outlets: { modal: device.config_modal$$ | async } }],
          queryParamsHandling: 'preserve'
        }"
        (btn_click)="$event.stopImmediatePropagation()"
      ></bg2-button>

      <bg2-button
        *ngIf="['GPS', 'RG', 'WG', 'TG'].includes(device.type)"
        [type]="'action'"
        [color]="'device'"
        [icon]="'mdi-battery-sync'"
        [options]="{ is_thin: true, icon_size_class: 'mdi-18px' }"
        [popper]="'VIEWS.MODALS.DEVICE.I have changed the battery' | translate"
        (btn_click)="$event.stopImmediatePropagation(); replace_battery(device)"
      ></bg2-button>
    </ul>
  </div>

  <div class="status" *ngIf="show_status">
    <bg2-device-alarms-status [device]="device"></bg2-device-alarms-status>
    <bg2-s868-level [open_in_modal]="open_in_modal" [device]="device"></bg2-s868-level>
    <bg2-gps-level [open_in_modal]="open_in_modal" [device]="device"></bg2-gps-level>
    <bg2-gprs-level [open_in_modal]="open_in_modal" [device]="device"></bg2-gprs-level>

    <!-- <bg2-battery-level [open_in_modal]="open_in_modal" [device]="device"></bg2-battery-level> -->
    <bg2-battery-level-simplified [open_in_modal]="open_in_modal" [device]="device"></bg2-battery-level-simplified>
  </div>
</div>
