import { environment } from 'environments/environment';

import { get_opposite_angle } from '../get-opposite-angle/get-opposite-angle.tool';

/** */
export const compute_wind_heading = (wind_speed?: number, wind_heading?: number) => {
  const is_reliable = wind_heading <= 360 && (wind_speed ?? 0) > environment.config.data.amemo.min_speed_for_valid_heading;

  if (!is_reliable) {
    return null;
  }

  const fixed_angle = wind_heading > 360 ? wind_heading % 360 : wind_heading;
  const opposite_angle = get_opposite_angle(wind_heading);

  // Compute direction name
  const headings = ['N', 'NNE', 'NE', 'ENE', 'E', 'ESE', 'SE', 'SSE', 'S', 'SSW', 'SW', 'WSW', 'W', 'WNW', 'NW', 'NNW', 'N'];
  const zone_size = 360 / (headings.length - 1); // 22.5

  const index = Math.round(fixed_angle / zone_size);

  return {
    name: headings?.[index] ?? null,
    rotation: opposite_angle ?? null,
    transform: `rotate(${opposite_angle}deg)`,
  };
};
