import { distinctUntilRealChanged, replay, robustCombineLatest } from '@bg2app/tools/rxjs';
import {
  Observable,
  of,
  combineLatest,
  switchMap,
  map,
  tap,
  filter,
  distinctUntilChanged,
  take,
  debounceTime,
  BehaviorSubject,
} from 'rxjs';

import { DateTime } from 'luxon';
import { includes, isNil, uniq, min, keys, values, flatten, clone, range, groupBy, isEmpty } from 'lodash-es';

import { addYears, differenceInCalendarYears, endOfYear, isSameYear, startOfYear, subHours } from 'date-fns/esm';

import { marker as i18n } from '@biesbjerg/ngx-translate-extract-marker';

import { environment } from 'environments/environment';

import { Beeguard2Api, DeviceApi } from 'app/core';
import { GetEntityTimeseriesResponse, MeasurementDescription } from 'app/core/api-swagger/beeguard2';

import { parseDate, strEnum } from 'app/misc/tools';
import { getDistance } from 'app/misc/tools/geomap';
import { mergeDeviceByPreviousLocation } from 'app/misc/tools/devices.helpers';

import { DRDevice } from '../../../devices';
import { Exploitation } from '../../02_exploitation/classes/Exploitation';
import { Entity, IEntityStaticState } from '../../00_abstract/classes/Entity';
import { Apiary, ApiaryDeviceConfig } from '../../04_apiary/classes/Apiary';

import { BlacklistReason } from 'app/typings/entities/warehouse';

import {
  GhostSolution,
  GhostSolutionLevel,
  DevicesFromSameLocation,
  GhostSolutionAlternatives,
} from 'app/core/ghost/models/ghost-solution';

import { Migratory } from '../../../events/Migratory';
import { SetupApiary } from '../../../events/ApiaryBasic';

import { compute_difference_in_days } from '@bg2app/tools/dates';
import { SuperBoxHarvest } from '../../../events/Superbox';
import { Dictionary } from 'app/typings/core/interfaces';
import { Hive } from '../../05_hive/classes/Hive';
import { BeeCountDataPoint, DataPoint } from '../../../data';
import { DeviceAlarm } from '../../../devices/DRDevice';
import { LocationEntityUserACL } from './location-entity-user-acl.class';
import { HiveBeeCountData } from '../../../data/data-classic/interfaces/hive-data.interface';
import { utcToZonedTime } from 'date-fns-tz';
import { tzLookup } from 'app/misc/tools/misc/suncalc/tzLookup';
import { timezones_data } from '../../../misc/timezones';

const LocationStatus = strEnum(['normal', 'ghost', 'movement']);
export type LocationStatus = keyof typeof LocationStatus;

export const LocationStatusI18n: { [key in LocationStatus]: string } = {
  normal: i18n<string>('ENTITY.LOCATION.STATUS.normal'),
  ghost: i18n<string>('ENTITY.LOCATION.STATUS.ghost'),
  movement: i18n<string>('ENTITY.LOCATION.STATUS.movement'),
};

/** */
interface LocationGeoposition {
  /** */
  latitude: number;

  /** */
  longitude: number;

  /** */
  elevation?: number;

  /** */
  address?: string;

  /** */
  approximate?: {
    /** */
    latitude: number;

    /** */
    longitude: number;
  };

  /** */
  timezone: string;
}

export interface LocationStaticState extends IEntityStaticState {
  name: string;
  id_v1: number;
  banner_image: {
    complete_path: string;
    lastModified: string;
    name: string;
    size: number;
    type: string;
    url: string;
  };
  position: LocationGeoposition;
  _apiary?: {
    name: string;
  };
}

/** Location Entity
 *
 * Do (lazy-) load apiary (if any)
 */
export class Location extends Entity<LocationStaticState> {
  // #region -> (model basics)

  constructor(bg2Api: Beeguard2Api, deviceApi: DeviceApi) {
    super(bg2Api, deviceApi);
    this.type = 'location';
  }

  public preDestroy() {
    super.preDestroy();
  }

  // #endregion

  // #region -> (acl management)

  /**
   * @inheritdoc
   */
  public user_acl = new LocationEntityUserACL();

  // #endregion

  // #region -> (location image)

  /** */
  public banner_image$$ = this.static_state$$.pipe(
    map(static_state => static_state?.banner_image?.url ?? 'assets/img/apiary_default_00.jpg'),
    distinctUntilRealChanged(),
    replay()
  );

  // #endregion

  // #region -> (geo-position management)

  /**
   * Location's position observable.
   *
   * @remarks
   * Use this observable only to display position-related data of the location. To display data in charts, prefer {@link position_robust$$}.
   */
  public position$$ = this.static_state$$.pipe(
    map(static_state => static_state?.position ?? null),
    distinctUntilRealChanged(),
    map(position => {
      if (isNil(position)) {
        return null;
      }

      // Override with approximate position
      if (!isNil(position)) {
        if (isNil(position.latitude) && !isNil(position?.approximate?.latitude)) {
          position.latitude = position.approximate.latitude;
          position.longitude = position.approximate.longitude;
        }
      }

      // Fix position (latitude & longitude) if needed
      // @see https://gis.stackexchange.com/questions/303300/calculating-correct-longitude-when-its-over-180
      if (!isNil(position?.longitude)) {
        let lng = position.longitude;
        while (Math.abs(lng) > 180) {
          lng -= Math.sign(lng) * 360;
        }

        position.longitude = lng;
      }

      // Override timezone (if not existing)
      if (!isEmpty(position) && !isNil(position)) {
        if (isNil(position?.timezone) || isEmpty(position?.timezone)) {
          if (!isNil(position?.longitude) && !isNil(position?.latitude)) {
            position.timezone = tzLookup(position.latitude, position.longitude);
          }
        }
      }

      return position;
    }),
    replay()
  );

  /**
   * Location's position with defaults.
   *
   * @remarks
   * Use this observable only to display location data in charts. For another use, prefer {@link geoposition$$}.
   */
  public position_robust$$ = this.position$$.pipe(
    map(geoposition => {
      if (isNil(geoposition) || isEmpty(geoposition) || isNil(geoposition?.latitude) || isNil(geoposition?.longitude)) {
        const local_timezone = geoposition?.timezone ?? DateTime.now()?.zoneName;
        const estimated_timezone_data = timezones_data.find(tz_data => tz_data.tz_identifier === local_timezone);

        let robust_position: LocationGeoposition = {
          timezone: local_timezone,
          latitude: geoposition?.latitude ?? estimated_timezone_data?.latitude ?? null,
          longitude: geoposition?.latitude ?? estimated_timezone_data?.longitude ?? null,
        };

        this._logger.warn(
          `location#${this.id} : using local timezone "${local_timezone}" at position [lat:${robust_position?.latitude}, lng:${robust_position?.longitude}]`
        );

        return robust_position;
      }

      return geoposition;
    }),
    replay()
  );

  /** */
  public location_latitute$$ = this.position$$.pipe(map(position => position?.latitude));

  /** */
  public location_longitude$$ = this.position$$.pipe(map(position => position?.longitude));

  /** */
  public location_elevation$$ = this.position$$.pipe(map(position => position?.elevation));

  /**
   * Timezone name of the location.
   */
  public location_timezone$$ = this.position_robust$$.pipe(
    map(position => position?.timezone),
    distinctUntilRealChanged(),
    replay()
  );

  /** */
  public location_has_elevation$$ = this.location_elevation$$.pipe(map(elevation => !isNil(elevation)));

  /** */
  public location_has_latlng$$ = combineLatest([this.location_latitute$$, this.location_longitude$$]).pipe(
    map(([latitude, longitude]: [number, number]) => !isNil(latitude) || !isNil(longitude)),
    replay()
  );

  // #endregion

  public apiary_id$$: Observable<number> = this.state$$.pipe(
    map(state => state.apiary_id),
    distinctUntilRealChanged(),
    replay()
  );

  public named_apiary_id$$: Observable<number> = this.initial_empty_named_state$$.pipe(
    map(state => state.apiary_id),
    distinctUntilRealChanged(),
    replay()
  );

  /**
   * Observes if the location has either ghost or named apiary.
   *
   * @public
   * @replay
   */
  public has_apiary$$ = this.apiary_id$$.pipe(
    map(apiary_id => !isNil(apiary_id)),
    distinctUntilRealChanged(),
    replay()
  );

  public has_named_apiary$$ = this.named_apiary_id$$.pipe(
    map(apiary_id => !isNil(apiary_id)),
    // tap(has_apiary => this._logger.debug('has_apiary', has_apiary)),
    distinctUntilChanged(),
    replay()
  );

  public archivable$$ = combineLatest([this.is_archived$$, this.has_named_apiary$$]).pipe(
    map(([is_archived, has_apiary]) => !is_archived && !has_apiary),
    replay()
  );

  // NOTE: allays prefers to use apiary$$
  public apiary: Apiary = null;

  public apiary$$: Observable<Apiary> = this.apiary_id$$.pipe(
    switchMap(apiary_id => this.bg2Api.getEntityObj(apiary_id) as Observable<Apiary>),
    tap(apiary => (this.apiary = apiary)),
    replay()
  );

  public named_apiary$$: Observable<Apiary> = this.named_apiary_id$$.pipe(
    switchMap(apiary_id => this.bg2Api.getEntityObj(apiary_id) as Observable<Apiary>),
    replay()
  );

  public ghost_apiary$$ = this.apiary$$.pipe(
    map(apiary => (apiary && apiary.id < 0 ? apiary : null)),
    replay()
  );

  public exploitation_id$$ = this.state$$.pipe(
    map(state => state.exploitation_id),
    distinctUntilRealChanged(),
    replay()
  );

  public exploitation$$ = this.exploitation_id$$.pipe(
    filter(id => !isNil(id)),
    switchMap(id => this.bg2Api.getEntityObj(id) as Observable<Exploitation>),
    replay()
  );

  public warehouse$$ = this.exploitation$$.pipe(switchMap(exploitation => exploitation.warehouse$$));

  public hives_nbr$$ = this.apiary$$.pipe(
    switchMap(apiary => (isNil(apiary) ? of([]) : apiary.hives_nucs$$)),
    map(hives_nbr => hives_nbr.length),
    distinctUntilRealChanged(),
    replay()
  );

  // #region -> (location devices)

  /**
   * Observes the devices on the location.
   */
  public devices$$: Observable<DRDevice[]> = this.named_apiary$$.pipe(switchMap(apiary => (!isNil(apiary) ? apiary.devices$$ : of([]))));

  // #endregion

  // #region -> (active alarms management)

  /** */
  private _is_alarms_displayed$$ = new BehaviorSubject(false);

  /** */
  public is_alarms_displayed$$ = this._is_alarms_displayed$$.asObservable();

  /** */
  public movement_alarms$$: Observable<DeviceAlarm[]> = this.apiary$$.pipe(
    switchMap(apiary => apiary?.devices_alarms_movement$$ ?? of([])),
    tap(alarms => {
      this._is_alarms_displayed$$.next(alarms?.length > 0);
    }),
    replay()
  );

  /** */
  public update_is_alarms_displayed(event: MouseEvent): void {
    if (event && event instanceof MouseEvent) {
      event.stopPropagation();
    }

    const actual_value = this._is_alarms_displayed$$.getValue();
    this._is_alarms_displayed$$.next(!actual_value);
  }

  // #endregion

  //#region location devices

  /** Devices config ignoring ghost entities */
  public named_devices_config$$ = this.named_apiary$$.pipe(
    switchMap(apiary => (!isNil(apiary) ? apiary.named_devices_config$$ : of<ApiaryDeviceConfig[]>([]))),
    replay()
  );

  /** Devices with ghost entities */
  public devices_config$$ = this.apiary$$.pipe(
    switchMap(apiary => (!isNil(apiary) ? apiary.devices_config$$ : of<ApiaryDeviceConfig[]>([]))),
    replay()
  );

  public associated_devices_types$$: Observable<string[]> = this.devices_config$$.pipe(
    map(dconfs => uniq(values(dconfs).map(dconf => dconf.type)))
  );

  public exploitation_badly_associated_devices$$ = this.exploitation$$.pipe(
    switchMap(exploitation => exploitation.badly_associated_devices$$)
  );

  /**
   * Returns devices associated to this location that are not in this location
   */
  public badly_associated_devices$$ = combineLatest([this.named_devices_config$$, this.exploitation_badly_associated_devices$$]).pipe(
    map(([devices_config, devices]: [ApiaryDeviceConfig[], DRDevice[]]) => {
      // Prepare some variables
      const associated_imeis = Object.keys(devices_config).map((key: any) => devices_config[key].imei);

      // Filter devices
      devices = devices
        .filter(device => includes(associated_imeis, device.imei)) // Include only current location devices
        .filter(device => !isNil(device.location?.longitude) && !isNil(device.location?.latitude)); // Keep devices with location
      return devices;
    }),
    replay()
  );

  public blacklisted_device_imeis$$: Observable<number[]> = this.warehouse$$.pipe(
    switchMap(wh => wh.blacklisted_devices_by_location$$),
    map(bl => {
      if (bl.data.length <= 0) {
        return [];
      }
      const results = bl.data.find(bl_point_lat_lnt =>
        this.isLocationPositionWithinRange(bl_point_lat_lnt, environment.config.ghost.default_merge_range_meter)
      );
      return results?.imeis || [];
    })
  );

  public all_compatible_devices$$ = this.exploitation$$.pipe(
    switchMap(expl => expl.badly_associated_devices_by_location$$),
    map(badly_associated_devices => badly_associated_devices[this.id]?.devices || []),
    replay()
  );

  public compatible_devices$$ = combineLatest([this.all_compatible_devices$$, this.blacklisted_device_imeis$$]).pipe(
    map(([devices, blacklisted_imeis]: [DRDevice[], number[]]) => {
      devices = devices.filter(device => !blacklisted_imeis.includes(device.imei));
      return devices;
    }),
    // tap(devices => this._logger.ghost('Comp dev', devices)),
    replay()
  );

  public compatible_gateway_devices$$ = this.compatible_devices$$.pipe(map(devices => devices.filter(device => device.is_gateway)));

  public compatible_devices_by_previous_location$$ = this.compatible_devices$$.pipe(
    switchMap(devices => mergeDeviceByPreviousLocation(devices, this.bg2Api)),
    tap(devices => this._logger.ghost('Comp dev by ploc', devices)),
    replay()
  );

  public buildSolutionsForMissingApiary$$(
    compatible_devices_by_previous_location$$: Observable<DevicesFromSameLocation[]>
  ): Observable<GhostSolutionAlternatives> {
    return combineLatest([compatible_devices_by_previous_location$$, this.named_apiary$$]).pipe(
      debounceTime(200), // important to avoid to build ghost while real apiary is still loading
      // tap(data => this._logger.ghost('devices & named apiary ?', data, `for location ${this.name}`)),
      distinctUntilRealChanged(),
      switchMap(([devices_by_previous_location, apiary]) => {
        // this._logger.debug('[GHOST] devices ? ', devices, `for location ${this.name}`);
        if (keys(devices_by_previous_location).length === 0 || !isNil(apiary)) {
          return of(null);
        } else {
          return this.buildSolutionForMissingApiary(devices_by_previous_location);
        }
      })
    );
  }

  public has_ghost_apiary$$ = combineLatest([this.named_apiary$$, this.ghost_apiary$$]).pipe(
    // tap((data) => this._logger.debug(data)),
    map(([named_apiary, ghost_apiary]) => !isNil(ghost_apiary) && isNil(named_apiary))
  );

  /**
   * Checks if the location has ghost
   */
  public has_ghost_hives$$ = this.apiary$$.pipe(switchMap(apiary => apiary?.has_ghost_hives$$ ?? of(false)));

  /**
   * Returns an object on current location ghost status
   */
  public ghost_status$$ = combineLatest({
    is_ghost: this.is_ghost$$,
    has_ghost_apiary: this.has_ghost_apiary$$,
    has_ghost_hives: this.has_ghost_hives$$,
  }).pipe(replay());

  /** */
  public has_ghost_depedency$$ = this.ghost_status$$.pipe(
    map(({ is_ghost, has_ghost_apiary, has_ghost_hives }) => is_ghost || has_ghost_apiary || has_ghost_hives),
    distinctUntilRealChanged(),
    replay()
  );

  /**
   * Fetch all misplaced devices on current location.
   */
  public misplaced_devices$$ = this.devices$$.pipe(
    map(devices => devices.filter(device => !device.isDeviceInLocationRange(this, environment.config.ghost.default_merge_range_meter)))
  );

  //#endregion

  public status$$: Observable<LocationStatus> = combineLatest([this.movement_alarms$$, this.has_ghost_depedency$$]).pipe(
    map(([alarms, has_ghost_depedency]) => {
      if (alarms?.length > 0) {
        return LocationStatus.movement;
      }

      if (has_ghost_depedency) {
        return LocationStatus.ghost;
      }

      return LocationStatus.normal;
    }),
    distinctUntilRealChanged(),
    replay()
  );

  public isLocationPositionWithinRange(position: { lat: number; lng: number }, max_range: number): boolean {
    return getDistance(this.position, { latitude: position.lat, longitude: position.lng }, 10) < max_range;
  }

  get url_v1() {
    if (this.static_state.id_v1) {
      return 'https://beeguard.fr/index.php/units/' + this.static_state.id_v1;
    }
    return null;
  }

  /** */
  public elevation$$ = this.static_state$$.pipe(map(static_state => static_state?.position?.elevation ?? 0));

  get elevation(): number {
    if (isNil(this.static_state) || isNil(this.static_state.position)) {
      return null;
    }
    return this.static_state.position.elevation;
  }

  public address$$ = this.static_state$$.pipe(map(static_state => static_state?.position?.address));

  /**
   * @deprecated
   */
  get address(): string {
    return this.static_state.position.address;
  }

  get position() {
    if (isNil(this.static_state) || isNil(this.static_state.position)) {
      return null;
    }

    const pos = this.static_state.position;

    if (!isNil(pos)) {
      // Get approximate pos
      if (isNil(pos.latitude) && !isNil(pos.approximate) && !isNil(pos.approximate.latitude)) {
        pos.latitude = pos.approximate.latitude;
        pos.longitude = pos.approximate.longitude;
      }
    }

    // Fix position (latitude & longitude) if needed
    // @see https://gis.stackexchange.com/questions/303300/calculating-correct-longitude-when-its-over-180
    if (!isNil(pos?.longitude)) {
      let lng = pos.longitude;
      while (Math.abs(lng) > 180) {
        lng -= Math.sign(lng) * 360;
      }

      pos.longitude = lng;
    }

    return pos;
  }

  public link$$ = combineLatest({ name: this.name$$, id: this.id$$ }).pipe(
    map(({ name, id }) => `<a href="javascript:void(modal:location_details, location_id=${this.id})">${name}</a>`),
    replay()
  );

  get description() {
    return i18n(`ENTITY.LOCATION.Location "[name]"`);
  }

  get exploitation_id() {
    if (!this.state) {
      return null;
    }
    return this.state.exploitation_id;
  }

  get apiary_name(): string {
    if (this.apiary) {
      return this.apiary.name;
    }
    if (this.static_state._apiary && this.static_state._apiary.name) {
      return this.static_state._apiary.name;
    }
    return null;
  }

  get last_payments(): any[] {
    return this.state.last_payments || [];
  }

  public hasApiary() {
    return this.state.apiary_id != null;
  }

  public isArchived() {
    return !isNil(this.state.archived) && this.state.archived;
  }

  public hasLastPayments() {
    return this.last_payments.length > 0;
  }

  public getAtDate(path: string, date?: Date, event_id?: number): Observable<any> {
    if (path === 'state.warehouse_id') {
      return this.bg2Api.fetch_entity_state$(this.id, date, event_id).pipe(
        switchMap((res: any) => {
          if (!isNil(res.state)) {
            const state = res.state.state;
            if (!isNil(state) && !isNil(state.warehouse_id)) {
              return of(state.warehouse_id);
            } else if (!isNil(state) && !isNil(state.exploitation_id)) {
              // Fail back to get warehouse id from exploiation
              console.warn(this.desc, 'warehouse_id not in state, get ot from exploitation');
              return this.bg2Api.getEntityObj(state.exploitation_id).pipe(
                map((entity: Entity) => entity as Exploitation),
                switchMap(exploitation => {
                  if (!isNil(exploitation)) {
                    return exploitation.getAtDate('static_state.warehouse_id', date, event_id);
                  } else {
                    return null;
                  }
                })
              );
            } else {
              return of(null);
            }
          } else {
            return of(null);
          }
        })
      );
    } else {
      return super.getAtDate(path, date, event_id);
    }
  }

  public toJSON() {
    const obj = super.toJSON();
    delete obj.static_state._apiary;
    return obj;
  }

  public static hasLatitudeLongitude(location: Location): boolean {
    return !isNil(location?.position?.latitude) && !isNil(location?.position?.longitude);
  }

  // #region -> (timeseries management)

  /** */
  private _available_ts_caches$$: Dictionary<Observable<MeasurementDescription[]>> = {};

  /** */
  protected stream_available_timeseries$$(start: Date, end: Date): Observable<MeasurementDescription[]> {
    const key = `${start.toISOString()}__${end.toISOString()}`;

    if (!this._available_ts_caches$$[key]) {
      this._available_ts_caches$$[key] = this.devices_config$$.pipe(
        switchMap(() => this.requestTimeseries([], start, end, 'auto')),
        map(ts => ts.available),
        distinctUntilRealChanged(),
        replay()
      );
    }

    return this._available_ts_caches$$[key];
  }

  // #endregion

  // #region -> (data management)

  /** */
  public stream_beecounter_data$$ = (start: Date, end: Date): Observable<HiveBeeCountData[]> =>
    this.location_timezone$$.pipe(
      switchMap(location_timezone =>
        this.stream_available_timeseries$$(start, end).pipe(
          map(available => available.filter(ts => ts.type === 'count_bm')), //TODO: load only "_bm" (by minutes) data
          // map(available => available.filter(ts => ts.type === 'count' || ts.type === 'count_bm')),
          switchMap(measurement_descriptions => {
            if (isEmpty(measurement_descriptions ?? [])) {
              return of([]);
            }

            const timeseries_names = measurement_descriptions.map(measurement_description => measurement_description.name);
            const related_hive_ids = uniq(timeseries_names.map(ts_name => parseInt(ts_name.split('__')[1], 10)));

            if (isEmpty(related_hive_ids ?? [])) {
              return of([]);
            }

            return this.requestTimeseries(timeseries_names, start, end, '15m').pipe(
              switchMap(timeseries_response => {
                const data_builder$$ = related_hive_ids.map(hive_id => {
                  const data_of_hive = timeseries_response.timeseries.data.map(
                    (point: DataPoint) =>
                      ({
                        date: parseDate(point.date),
                        timezone: location_timezone,
                        tz_date: utcToZonedTime(point.date, location_timezone),

                        // count_in: point[`count_in__${hive_id}`],
                        count_in_bm: point[`count_in_bm__${hive_id}`],
                        // count_in_nb_sensors: point[`count_in_nb_sensors__${hive_id}`],
                        // count_in_bm_nb_sensors: point[`count_in_bm_nb_sensors__${hive_id}`],

                        // count_out: point[`count_out__${hive_id}`],
                        count_out_bm: point[`count_out_bm__${hive_id}`],
                        // count_out_nb_sensors: point[`count_out_nb_sensors__${hive_id}`],
                        // count_out_bm_nb_sensors: point[`count_out_bm_nb_sensors__${hive_id}`],

                        // count_outside_bee_byday: point[`count_outside_bee_byday__${hive_id}`],
                        // count_outside_bee_byday_nb_sensors: point[`count_outside_bee_byday_nb_sensors__${hive_id}`],

                        // mortality_byday: point[`mortality_byday__${hive_id}`],
                        // mortality_byday_nb_sensors: point[`mortality_byday_nb_sensors__${hive_id}`],
                      } as BeeCountDataPoint)
                  );

                  return this.bg2Api.getEntityObj<Hive>(hive_id).pipe(
                    switchMap(hive => {
                      if (isNil(hive)) {
                        return of<HiveBeeCountData>(<HiveBeeCountData>{ hive_id: null, values: [] });
                      }

                      return hive.is_visible_on_chart$$.pipe(
                        map(
                          is_hive_visible_on_chart =>
                            ({
                              hive,
                              hive_id,
                              hive_name: hive.name,
                              hive_color: hive.color,
                              hive_visible_on_chart: is_hive_visible_on_chart,

                              values: data_of_hive,
                            } as HiveBeeCountData)
                        )
                      );
                    })
                  );
                });

                return robustCombineLatest(data_builder$$);
              })
            );
          })
        )
      ),
      take(1)
    );

  // #endregion

  public streamGhostAvailableTimeseries(start: Date, end: Date): Observable<MeasurementDescription[]> {
    return this.apiary$$.pipe(switchMap(apiary => (apiary ? apiary.streamGhostAvailableTimeseries(start, end) : of([]))));
  }

  public requestGhostTimeseries(
    measurements?: Array<string>,
    start?: Date,
    end?: Date,
    step?: string
  ): Observable<GetEntityTimeseriesResponse> {
    return this.apiary$$.pipe(switchMap(apiary => apiary.requestGhostTimeseries(measurements, start, end, step)));
  }

  public blacklist_ghost(reason: BlacklistReason) {
    // TODO filter if current location is not a ghost
    return this.warehouse$$.pipe(
      take(1),
      switchMap(warehouse => warehouse.blacklist_ghost_location(this.position, reason))
    );
  }

  public buildSolutionForMissingApiary(devices_by_previous_location: DevicesFromSameLocation[]): Observable<GhostSolutionAlternatives> {
    const all_devices = flatten(values(devices_by_previous_location).map(device_group => device_group.devices));
    const devices_with_no_previous_location = devices_by_previous_location[-1]?.devices;

    return combineLatest([this.exploitation$$]).pipe(
      take(1),
      map(([exploitation]) => {
        // Build new alternatives holder
        const alternatives = new GhostSolutionAlternatives(GhostSolutionLevel.LVL_APIARY);
        alternatives.setDevices(all_devices);

        // Build basic solution (apiary creation)
        const new_apiary_solution = new GhostSolution('create_apiary');
        const new_apiary_devices = devices_with_no_previous_location || all_devices;
        new_apiary_solution.setDevices(new_apiary_devices);
        const remaining_devices = clone(devices_by_previous_location);
        new_apiary_solution.setRemainingDevices(remaining_devices);

        const ghost_apiary_ref = new_apiary_solution.newGhostEntity('apiary', {});
        const setup_location = new SetupApiary(this.bg2Api);
        const here_since = min(all_devices.map(device => device.at_this_position_since));
        setup_location.date = here_since;
        setup_location.setOperand('location', this.id);
        new_apiary_solution.addEvent(setup_location, ['apiary', ghost_apiary_ref]);

        new_apiary_solution.setDescription(i18n('GHOST.SOLUTION.Create a new apiary'), {});

        alternatives.add(new_apiary_solution);

        devices_by_previous_location.map(device_group => {
          if (device_group.apiary?.id) {
            const migratory_solution = new GhostSolution('migratory');
            const m_remaining_devices = devices_by_previous_location.filter(_dg => _dg.location?.id !== device_group.location.id);
            migratory_solution.setRemainingDevices(m_remaining_devices);

            const migratory = new Migratory(this.bg2Api);
            const migratory_since = min(device_group.devices.map(device => device.at_this_position_since));
            migratory.date = migratory_since;
            migratory.data = {
              partial: false,
            };
            migratory.setOperand('apiary', device_group.apiary.id);
            migratory.setOperand('location_source', device_group.location.id);
            migratory.setOperand('location_dest', this.id);
            migratory.setOperand('warehouse_source', device_group?.affectations?.[0]?.warehouse?.entity?.id);
            migratory.setOperand('warehouse_dest', device_group?.affectations?.[0]?.warehouse?.entity?.id);

            migratory_solution.addEvent(migratory);
            migratory_solution.setDevices(device_group.devices);
            migratory_solution.setSourceLocation(device_group.location);
            migratory_solution.setDescription(i18n('GHOST.SOLUTION.Migratory from [location->name]'), {
              location: device_group.location,
            });
            alternatives.add(migratory_solution);
          }
        });

        return alternatives;
      })
    );
  }

  // #region -> (entity events -> superbox_harvest)

  /**
   * @description
   *
   * Observes harvest events of the current location.
   */
  private events_superbox_harvest$$ = this.stream_last_events$$(0, -1, ['superbox_harvest']).pipe(
    map(events => events as SuperBoxHarvest[]),
    replay()
  );

  /**
   * @description
   *
   * Observes the list of superbox harvest events by year.
   */
  public events_superbox_harvest_by_year$$ = this.events_superbox_harvest$$.pipe(
    map(events => groupBy(events, event => event.date.getFullYear()))
  );

  // #endregion

  // #region -> (entity history management)

  /**
   * @description
   *
   * Observes the history of apiary ids.
   */
  public history_of_apiary_id$$ = of(null).pipe(
    switchMap(() => this.requestHistory(['apiary_id'])),
    map(history => {
      const apiary_ids: any[] = history?.apiary_id ?? [];
      return apiary_ids?.map(apiary_id_history => ({
        apiary_id: apiary_id_history?.value ?? null,
        end: apiary_id_history?.end ? parseDate(apiary_id_history?.end) : null,
        start: apiary_id_history?.start ? parseDate(apiary_id_history?.start) : null,
      }));
    }),
    replay()
  );

  // #endregion

  // #region -> (entity history computed data)

  /**
   * @description
   *
   *
   */
  public total_occupied_days_by_year$$ = this.history_of_apiary_id$$.pipe(
    map(history => history.filter(h => !isNil(h.apiary_id))),
    map(history =>
      history.reduce((occupied_days: { [key: string]: number }, current) => {
        if (!isNil(current.end)) {
          if (isSameYear(current.start, current.end)) {
            occupied_days[current.start.getFullYear()] =
              (occupied_days?.[current.start.getFullYear()] ?? 0) + compute_difference_in_days([current.end, current.start]);
            return occupied_days;
          } else {
            const years = differenceInCalendarYears(current.end, current.start);

            range(years + 1).forEach((year, index, self) => {
              const true_year = current.start.getFullYear() + year;

              if (index === 0) {
                occupied_days[true_year] =
                  (occupied_days?.[true_year] ?? 0) + compute_difference_in_days([endOfYear(current.start), current.start]);
                return occupied_days;
              }

              if (index > 0 && index < self.length - 1) {
                occupied_days[true_year] =
                  (occupied_days?.[true_year] ?? 0) +
                  compute_difference_in_days([endOfYear(addYears(current.start, year)), startOfYear(addYears(current.start, year))]);
                return occupied_days;
              }

              if (index >= self.length - 1) {
                occupied_days[true_year] =
                  (occupied_days?.[true_year] ?? 0) + compute_difference_in_days([current.end, startOfYear(addYears(current.start, year))]);
                return occupied_days;
              }
            });
          }
        }

        // If there is no end (until today)
        if (isNil(current.end)) {
          const today = new Date();

          if (isSameYear(current.start, today)) {
            occupied_days[current.start.getFullYear()] =
              (occupied_days?.[current.start.getFullYear()] ?? 0) + compute_difference_in_days([today, current.start]);
            return occupied_days;
          } else {
            const years = differenceInCalendarYears(today, current.start);

            range(years + 1).forEach((year, index, self) => {
              const true_year = current.start.getFullYear() + year;

              if (index === 0) {
                occupied_days[true_year] =
                  (occupied_days?.[true_year] ?? 0) + compute_difference_in_days([endOfYear(current.start), current.start]);
                return occupied_days;
              }

              if (index > 0 && index < self.length - 1) {
                occupied_days[true_year] =
                  (occupied_days?.[true_year] ?? 0) +
                  compute_difference_in_days([endOfYear(addYears(current.start, year)), startOfYear(addYears(current.start, year))]);
                return occupied_days;
              }

              if (index >= self.length - 1) {
                occupied_days[true_year] =
                  (occupied_days?.[true_year] ?? 0) + compute_difference_in_days([today, startOfYear(addYears(current.start, year))]);
                return occupied_days;
              }
            });
          }

          return occupied_days;
        }

        return occupied_days;
      }, {})
    ),
    distinctUntilRealChanged(),
    replay()
  );

  // #endregion
}
