<div *ngLet="device$$ | async; let device" class="bg2-device-modal-diagnostic-component">
  <!-- CARD: Device summary status -->
  <div class="device-card-template">
    <div class="device-card-template__normal-container">
      <div class="device-detail__title-container">
        <div class="title-container__icon --color-blue">
          <span class="mdi mdi-list-status mdi-36px"></span>
        </div>

        <div class="title-container__title">
          {{ 'VIEWS.DEVICES.DIALOGS_AND_MODALS.DEVICE_MODAL.DETAILS.STATUS_AND_BATTERY.Diagnostic' | translate }}
        </div>

        <div class="title-container__sep">|</div>

        <div class="title-container__important-container">
          <bg2-last-com
            [device]="device"
            [prefix]="'VIEWS.APIARY.SHARED.WIDGETS.APIARY_LAST_DATA.APIARY_LAST_DATA_CARD.Last com' | translate | colon"
          ></bg2-last-com>
        </div>
      </div>

      <div class="device-modal-card-container__body-container">
        <div class="body-container__properties">
          <!-- Origin -->
          <div *ngIf="is_superadmin$$ | async" class="body-container__property-container --only-superadmin">
            <div class="device-details-properties__property-container__label-container">
              <span class="mdi mdi-source-branch mdi-24px"></span>
              <b>{{ 'DEVICE.ALL.METADATA.Origin' | translate | colon }}</b>
            </div>

            <span class="device-details-properties__property-container__value">
              {{ device?.last_origin$$ | async }}

              <ng-container *ngIf="device?.last_network_activity_time$$ | async; let last_network_activity_time">
                ({{ last_network_activity_time | formatDate : 'full' }})
              </ng-container>
            </span>
          </div>

          <!-- GPRS status -->
          <div *ngIf="device_status_gprs$$ | async; let status" class="body-container__property-container">
            <div class="device-details-properties__property-container__label-container">
              <bg2-gprs-level [open_in_modal]="false" [device]="device"></bg2-gprs-level>
              <b>{{ 'VIEWS.MODALS.DEVICE.GPRS level' | translate | colon }}</b>
            </div>

            <span class="device-details-properties__property-container__value">
              <span>{{ status.value | localeNumber : '0.0-0' }} dB</span>
            </span>
          </div>

          <!-- GPS status -->
          <div *ngIf="device_location_gps$$ | async; let geolocation" class="body-container__property-container">
            <div class="device-details-properties__property-container__label-container">
              <bg2-gps-level [open_in_modal]="false" [device]="device"></bg2-gps-level>
            </div>

            <div class="device-details-properties__property-container__values">
              <span class="device-details-properties__property-container__value">
                <b>{{ 'VIEWS.MODALS.DEVICE.Last device GPS position' | translate | colon }}</b>
                <a
                  target="_blank"
                  [popper]="'VIEWS.PAGE.MAP.Open google maps' | translate"
                  href="https://www.google.fr/maps?q=loc:{{ geolocation.latitude }},{{ geolocation.longitude }}"
                >
                  {{ geolocation.latitude | localeNumber : '0.5-5' }},{{ geolocation.longitude | localeNumber : '0.5-5' }}
                </a>
                <span *ngIf="geolocation?.accuracy >= 0">({{ geolocation?.accuracy | localeNumber : '0.0-0' }} m)</span>
                <a
                  target="_blank"
                  [popper]="'VIEWS.PAGE.MAP.Open direction' | translate"
                  href="https://www.google.com/maps/dir/?api=1&destination={{ geolocation.latitude }},{{ geolocation.longitude }}"
                >
                  <span class="mdi mdi-directions"></span>
                </a>
              </span>

              <span class="device-details-properties__property-container__value">
                <b>{{ 'VIEWS.MAP.SHARED.HTML_POPUP_MARKER.Position taken on:' | translate }}</b>
                <span>{{ geolocation.timestamp | formatDate : 'full' }}</span>
              </span>
            </div>
          </div>

          <!-- Cell IDs status -->
          <ng-container *ngIf="device_location_cellids$$ | async; let location_cellids">
            <div *ngIf="location_cellids?.latitude" class="body-container__property-container">
              <div class="device-details-properties__property-container__label-container">
                <span class="mdi mdi-transmission-tower mdi-24px"></span>
              </div>

              <div class="device-details-properties__property-container__values">
                <span class="device-details-properties__property-container__value">
                  <b>{{ 'VIEWS.MODALS.DEVICE.Last device Cell ID position' | translate }}</b>
                  <a
                    href="https://www.google.fr/maps?q=loc:{{ location_cellids.latitude }},{{ location_cellids.longitude }}"
                    target="_blank"
                    [popper]="'VIEWS.PAGE.MAP.Open google maps' | translate"
                  >
                    {{ location_cellids.latitude | localeNumber : '0.5-5' }},{{ location_cellids.longitude | localeNumber : '0.5-5' }}
                  </a>
                  <span *ngIf="location_cellids.accuracy">({{ location_cellids.accuracy | localeNumber : '0.0-0' }} m)</span>
                  <a
                    target="_blank"
                    [popper]="'VIEWS.PAGE.MAP.Open direction' | translate"
                    href="https://www.google.com/maps/dir/?api=1&destination={{ location_cellids.latitude }},{{
                      location_cellids.longitude
                    }}"
                  >
                    <span class="mdi mdi-directions"></span>
                  </a>
                </span>

                <span class="device-details-properties__property-container__value">
                  <b>{{ 'VIEWS.MAP.SHARED.HTML_POPUP_MARKER.Position taken on:' | translate }}</b>
                  <span>{{ location_cellids?.timestamp | formatDate : 'full' }}</span>
                </span>
              </div>
            </div>
          </ng-container>

          <!-- 868 status -->
          <div *ngIf="device_status_868$$ | async; let status" class="body-container__property-container">
            <div class="device-details-properties__property-container__label-container">
              <bg2-s868-level [open_in_modal]="false" [device]="device"></bg2-s868-level>
              <b>{{ 'VIEWS.MODALS.DEVICE.Local communication level' | translate | colon }}</b>
            </div>

            <span class="device-details-properties__property-container__value">
              <span>{{ status.value | localeNumber : '0.0-0' }} dB</span>
            </span>
          </div>

          <!-- Telit -->
          <ng-container *ngIf="is_superadmin$$ | async">
            <div *ngIf="is_device_rg_or_gps$$ | async" class="body-container__property-container --only-superadmin">
              <div class="device-details-properties__property-container__label-container">
                <span class="mdi mdi-access-point mdi-24px"></span>
              </div>

              <span class="device-details-properties__property-container__value">
                <a href="https://portal-de.telit.com/connections/index/query:{{ device_imei$$ | async }}#connection-table" target="_blank">
                  {{ 'VIEWS_WINDOWED.MODALS.DEVICE_MODAL.Open telit portal' | translate }}
                </a>
              </span>
            </div>
          </ng-container>
        </div>
      </div>

      <ng-container *ngIf="view === 'details'">
        <bg2-device-last-msgs [device]="device"></bg2-device-last-msgs>
      </ng-container>

      <ng-container *ngIf="view === 'summary'">
        <mat-divider></mat-divider>

        <div class="device-detail__title-container">
          <div class="title-container__icon --color-green">
            <span class="mdi mdi-battery-40 mdi-36px"></span>
          </div>

          <div class="title-container__title">
            {{ 'VIEWS.DEVICES.DIALOGS_AND_MODALS.DEVICE_MODAL.DETAILS.STATUS_AND_BATTERY.Battery' | translate }}
          </div>

          <div class="title-container__sep">|</div>

          <div *ngLet="device_status_bat$$ | async; let status_bat" class="title-container__important-container">
            <!-- <bg2-battery-level [open_in_modal]="false" [device]="device"></bg2-battery-level> -->
            <bg2-battery-level-simplified [open_in_modal]="false" [device]="device"></bg2-battery-level-simplified>
            <!-- <span *ngIf="status_bat?.value >= 0; else unknownBatLevel">{{ status_bat?.value | localeNumber : '0.0-0' }} %</span> -->

            <ng-template #unknownBatLevel>
              <span>{{ 'VIEWS.MODALS.DEVICE.Unknow' | translate }}</span>
            </ng-template>
          </div>
        </div>

        <div class="device-modal-card-container__body-container">
          <div *ngLet="device; let device" class="body-container__properties">
            <!-- Battery type -->
            <div class="body-container__property-container">
              <div class="device-details-properties__property-container__label-container">
                <span class="mdi mdi-battery-unknown mdi-24px"></span>
                <b>{{ 'DEVICE.ALL.BATTERY.Battery type' | translate | colon }}</b>
              </div>

              <span *ngLet="device?.battery_type$$ | async; let battery_type" class="device-details-properties__property-container__value">
                <ng-container *ngIf="battery_type; else unknownValue">
                  <span *ngIf="device?.is_battery_computed_in_local">({{ device?.battery_type$$ | async }})</span>
                  <span *ngIf="!device?.is_battery_computed_in_local">{{ device?.battery_type$$ | async }}</span>
                </ng-container>
              </span>
            </div>

            <!-- Last voltage -->
            <div class="body-container__property-container">
              <div class="device-details-properties__property-container__label-container">
                <span class="mdi mdi-battery-charging mdi-24px"></span>
                <b>{{ 'DEVICE.ALL.BATTERY.Last voltage' | translate | colon }}</b>
              </div>

              <span class="device-details-properties__property-container__value">
                <span>{{ (device?.last_battery_voltage_calibrated$$ | async | fixed : 2) ?? '-' }} V</span>
              </span>
            </div>

            <!-- Last battery change date -->
            <ng-container *ngIf="is_not_beelive_nor_cpt$$ | async">
              <div *ngIf="device_status_bat$$ | async; let status_bat" class="body-container__property-container">
                <div class="device-details-properties__property-container__label-container">
                  <span class="mdi mdi-clock mdi-24px"></span>
                  <b>{{ 'VIEWS.MODALS.DEVICE.Last bat change :' | translate }}</b>
                </div>

                <span *ngIf="device.bat?.state?.time_new_bat; let time" class="device-details-properties__property-container__value">
                  <span>{{ time | formatDate : 'short' }}</span>
                  <span>({{ time | distanceToNow : false : true }})</span>
                </span>

                <bg2-button
                  *ngIf="['GPS', 'RG', 'WG', 'TG'].includes(device.type)"
                  [type]="'action'"
                  [color]="'device'"
                  [icon]="'mdi-battery-sync'"
                  [options]="{ is_thin: true, icon_size_class: 'mdi-18px' }"
                  [popper]="'VIEWS.MODALS.DEVICE.I have changed the battery' | translate"
                  (btn_click)="$event.stopImmediatePropagation(); replace_battery(device)"
                ></bg2-button>
              </div>
            </ng-container>
          </div>

          <!-- Device battery sparkline -->
          <bg2-device-battery-sparkline
            style="width: 100%"
            [height]="'100px'"
            [show_title]="true"
            [device]="device"
          ></bg2-device-battery-sparkline>
        </div>

        <div class="device-detail__open-detail-container">
          <bg2-button
            [type]="'navigation'"
            [icon]="'mdi-magnify'"
            [options]="{ is_thin: true }"
            [styles]="{ padding: '2px 5px', margin: '0px' }"
            [message]="'VIEWS.DEVICES.DIALOGS_AND_MODALS.DEVICE_MODAL.DETAILS.MISC.More details' | translate"
            (btn_click)="open_detail.next(true)"
          ></bg2-button>
        </div>
      </ng-container>
    </div>
  </div>

  <ng-container *ngIf="view === 'details'">
    <!-- CARD: Device GPRS status -->
    <div *ngIf="can_show_gprs_card$$ | async" class="device-card-template">
      <div class="device-card-template__normal-container">
        <div class="device-detail__title-container">
          <div class="title-container__icon --color-green">
            <span class="mdi mdi-signal-cellular-2 mdi-36px"></span>
          </div>

          <div class="title-container__title">
            {{ 'VIEWS.DEVICES.DIALOGS_AND_MODALS.DEVICE_MODAL.DETAILS.STATUS_AND_BATTERY.GPRS.Cellular network status' | translate }}
          </div>
        </div>

        <div class="device-modal-card-container__body-container">
          <!-- What is GPRS -->
          <bg2-ui-message
            [type]="'info'"
            [title]="
              'VIEWS.DEVICES.DIALOGS_AND_MODALS.DEVICE_MODAL.DETAILS.STATUS_AND_BATTERY.GPRS.USAGE.What is the purpose of the mobile network on this device ?'
                | translate
            "
          >
            <div message>
              <ng-container *ngIf="is_device_cpt$$ | async">
                <span>
                  {{
                    'VIEWS.DEVICES.DIALOGS_AND_MODALS.DEVICE_MODAL.DETAILS.STATUS_AND_BATTERY.GPRS.USAGE.This device uses the mobile network to send its own data to the BeeGuard server'
                      | translate
                  }}
                </span>
              </ng-container>

              <ng-container *ngIf="is_device_is_gps$$ | async">
                <span>
                  {{
                    'VIEWS.DEVICES.DIALOGS_AND_MODALS.DEVICE_MODAL.DETAILS.STATUS_AND_BATTERY.GPRS.USAGE.This device uses the mobile network to send its own data and that of nearby WGuard scales to the BeeGuard server'
                      | translate
                  }}
                </span>
              </ng-container>

              <ng-container *ngIf="is_device_is_rg$$ | async">
                <span>
                  {{
                    'VIEWS.DEVICES.DIALOGS_AND_MODALS.DEVICE_MODAL.DETAILS.STATUS_AND_BATTERY.GPRS.USAGE.This device uses the mobile network to send its own data and that of nearby WGuard scales to the BeeGuard server'
                      | translate
                  }}
                </span>
              </ng-container>
            </div>
          </bg2-ui-message>

          <div class="body-container__properties">
            <!-- GPRS status -->
            <div *ngIf="device_status_gprs$$ | async; let status" class="body-container__property-container">
              <div class="device-details-properties__property-container__label-container">
                <bg2-gprs-level [open_in_modal]="false" [device]="device"></bg2-gprs-level>
                <b>{{ 'VIEWS.MODALS.DEVICE.GPRS level' | translate | colon }}</b>
              </div>

              <span class="device-details-properties__property-container__value">
                <span>{{ status.value | localeNumber : '0.0-0' }} dB</span>
              </span>
            </div>

            <!-- Communication technology -->
            <div *ngIf="device?.com_technology$$ | async; let com_technologies" class="body-container__property-container">
              <div class="device-details-properties__property-container__label-container">
                <span class="mdi mdi-satellite-uplink mdi-24px"></span>
                <b>{{ 'VIEWS.MODALS.EXPORT_DEVICE.Communication technology' | translate | colon }}</b>
              </div>

              <span class="device-details-properties__property-container__value">
                <span>{{ com_technologies | join : ', ' }}</span>
              </span>

              <span class="device-details-properties__property-container__value">
                <doc-helper [page]="'appareils/technologie_communication'" section=""></doc-helper>
              </span>
            </div>
          </div>

          <bg2-device-gprs-sparkline
            style="width: 100%"
            [device]="device"
            [height]="'100px'"
            [show_title]="true"
          ></bg2-device-gprs-sparkline>
        </div>
      </div>
    </div>

    <!-- CARD: Device 868 status -->
    <div *ngIf="can_show_868_card$$ | async" class="device-card-template">
      <div class="device-card-template__normal-container">
        <div class="device-detail__title-container">
          <div class="title-container__icon --color-green">
            <span class="mdi mdi-wifi mdi-36px"></span>
          </div>

          <div class="title-container__title">
            {{
              'VIEWS.DEVICES.DIALOGS_AND_MODALS.DEVICE_MODAL.DETAILS.STATUS_AND_BATTERY.GPRS.Inter-sensor communication status' | translate
            }}
          </div>
        </div>

        <div class="device-modal-card-container__body-container">
          <!-- What is GPRS -->
          <bg2-ui-message
            [type]="'info'"
            [title]="
              'VIEWS.DEVICES.DIALOGS_AND_MODALS.DEVICE_MODAL.DETAILS.STATUS_AND_BATTERY.868.USAGE.What is the purpose of the inter-sensor network on this device ?'
                | translate
            "
          >
            <div message>
              <span>
                {{
                  'VIEWS.DEVICES.DIALOGS_AND_MODALS.DEVICE_MODAL.DETAILS.STATUS_AND_BATTERY.868.USAGE.This device uses the inter-sensor network to send collected data to the nearest gateway (it can be a GPS or a weather station)'
                    | translate
                }}
              </span>
            </div>
          </bg2-ui-message>

          <div class="body-container__properties">
            <!-- 868 status -->
            <div *ngIf="device_status_868$$ | async; let status" class="body-container__property-container">
              <div class="device-details-properties__property-container__label-container">
                <bg2-s868-level [open_in_modal]="false" [device]="device"></bg2-s868-level>
                <b>{{ 'VIEWS.MODALS.DEVICE.Local communication level' | translate | colon }}</b>
              </div>

              <span class="device-details-properties__property-container__value">
                <span>{{ status.value | localeNumber : '0.0-0' }} dB</span>
              </span>
            </div>
          </div>

          <bg2-device-868-sparkline style="width: 100%" [device]="device" [height]="'100px'" [show_title]="true"></bg2-device-868-sparkline>
        </div>
      </div>
    </div>

    <!-- CARD: Device battery -->
    <div class="device-card-template">
      <div class="device-card-template__normal-container">
        <div class="device-detail__title-container">
          <div class="title-container__icon --color-green">
            <span class="mdi mdi-battery-40 mdi-36px"></span>
          </div>

          <div class="title-container__title">
            {{ 'VIEWS.DEVICES.DIALOGS_AND_MODALS.DEVICE_MODAL.DETAILS.STATUS_AND_BATTERY.Battery' | translate }}
          </div>

          <div class="title-container__sep">|</div>

          <div *ngLet="device_status_bat$$ | async; let status_bat" class="title-container__important-container">
            <!-- <bg2-battery-level [open_in_modal]="false" [device]="device"></bg2-battery-level> -->
            <bg2-battery-level-simplified [open_in_modal]="false" [device]="device"></bg2-battery-level-simplified>
            <!-- <span *ngIf="status_bat?.value >= 0; else unknownBatLevel">{{ status_bat?.value | localeNumber : '0.0-0' }} %</span> -->

            <ng-template #unknownBatLevel>
              <span>{{ 'VIEWS.MODALS.DEVICE.Unknow' | translate }}</span>
            </ng-template>
          </div>
        </div>

        <div *ngLet="device_status_bat$$ | async; let status_bat" class="device-modal-card-container__body-container">
          <div class="body-container__properties">
            <!-- Battery type -->
            <div class="body-container__property-container">
              <div class="device-details-properties__property-container__label-container">
                <span class="mdi mdi-battery-unknown mdi-24px"></span>
                <b>{{ 'DEVICE.ALL.BATTERY.Battery type' | translate | colon }}</b>
              </div>

              <span *ngLet="device?.battery_type$$ | async; let battery_type" class="device-details-properties__property-container__value">
                <ng-container *ngIf="battery_type; else unknownValue">
                  <span *ngIf="device?.is_battery_computed_in_local">({{ device?.battery_type$$ | async }})</span>
                  <span *ngIf="!device?.is_battery_computed_in_local">{{ device?.battery_type$$ | async }}</span>
                </ng-container>
              </span>
            </div>

            <!-- Last voltage -->
            <div class="body-container__property-container">
              <div class="device-details-properties__property-container__label-container">
                <span class="mdi mdi-battery-charging mdi-24px"></span>
                <b>{{ 'DEVICE.ALL.BATTERY.Last voltage' | translate | colon }}</b>
              </div>

              <span class="device-details-properties__property-container__value">
                <span>{{ (device?.last_battery_voltage_calibrated$$ | async | fixed : 2) ?? '-' }} V</span>
              </span>
            </div>

            <!-- Last battery change date -->
            <ng-container *ngIf="is_not_beelive_nor_cpt$$ | async">
              <div *ngIf="device_status_bat$$ | async; let status_bat" class="body-container__property-container">
                <div class="device-details-properties__property-container__label-container">
                  <span class="mdi mdi-clock mdi-24px"></span>
                  <b>{{ 'VIEWS.MODALS.DEVICE.Last bat change :' | translate }}</b>
                </div>

                <span *ngIf="device.bat?.state?.time_new_bat; let time" class="device-details-properties__property-container__value">
                  <span>{{ time | formatDate : 'short' }}</span>
                  <span>({{ time | distanceToNow : false : true }})</span>
                </span>

                <bg2-button
                  *ngIf="['GPS', 'RG', 'WG', 'TG'].includes(device.type)"
                  [type]="'action'"
                  [color]="'device'"
                  [icon]="'mdi-battery-sync'"
                  [options]="{ is_thin: true, icon_size_class: 'mdi-18px' }"
                  [popper]="'VIEWS.MODALS.DEVICE.I have changed the battery' | translate"
                  (btn_click)="$event.stopImmediatePropagation(); replace_battery(device)"
                ></bg2-button>
              </div>
            </ng-container>
          </div>

          <ng-container *ngLet="device?.battery_simplified_state$$ | async; let status">
            <bg2-ui-message
              *ngIf="status?.reason !== null"
              [type]="'warning'"
              [title]="''"
              [invert_text_color]="false"
              [fill_background]="true"
              [show_borders]="true"
            >
              <div message>
                <ng-container [ngSwitch]="status.reason">
                  <span *ngSwitchCase="BATTERY_REASON.BATTERY_VOLTAGE_UNDER_CRITICAL_LIMIT">
                    {{ 'VIEWS.DEVICES.SHARED.DEVICE_LEVEL.BATTERY_LEVEL_SIMPLIFIED.BATTERY_VOLTAGE_UNDER_CRITICAL_LIMIT' | translate }}
                  </span>

                  <span *ngSwitchCase="BATTERY_REASON.LAST_BATTERY_OLDER_THAN_1_YEAR">
                    {{ 'VIEWS.DEVICES.SHARED.DEVICE_LEVEL.BATTERY_LEVEL_SIMPLIFIED.LAST_BATTERY_OLDER_THAN_1_YEAR' | translate }}
                  </span>

                  <span *ngSwitchCase="BATTERY_REASON.LAST_BATTERY_OLDER_THAN_2_YEARS">
                    {{ 'VIEWS.DEVICES.SHARED.DEVICE_LEVEL.BATTERY_LEVEL_SIMPLIFIED.LAST_BATTERY_OLDER_THAN_2_YEARS' | translate }}
                  </span>

                  <span *ngSwitchCase="BATTERY_REASON.LAST_BATTERY_OLDER_THAN_3_YEARS">
                    {{ 'VIEWS.DEVICES.SHARED.DEVICE_LEVEL.BATTERY_LEVEL_SIMPLIFIED.LAST_BATTERY_OLDER_THAN_3_YEARS' | translate }}
                  </span>

                  <span *ngSwitchCase="BATTERY_REASON.NO_CONTACT_SINCE_7_DAYS">
                    {{ 'VIEWS.DEVICES.SHARED.DEVICE_LEVEL.BATTERY_LEVEL_SIMPLIFIED.NO_CONTACT_SINCE_7_DAYS' | translate }}
                  </span>

                  <span *ngSwitchCase="BATTERY_REASON.UNKNOWN">
                    {{ 'VIEWS.DEVICES.SHARED.DEVICE_LEVEL.BATTERY_LEVEL_SIMPLIFIED.UNKNOWN' | translate }}
                  </span>
                </ng-container>
              </div>
            </bg2-ui-message>
          </ng-container>

          <!-- Device battery sparkline -->
          <bg2-device-battery-sparkline
            style="width: 100%"
            [height]="'100px'"
            [show_title]="true"
            [device]="device"
          ></bg2-device-battery-sparkline>

          <!-- Device battery changes -->
          <div *ngIf="device?.user_acl?.can$$('write_devices_configuration') | async" class="device-bat-change">
            <bg2-device-bat-changes
              *ngIf="device.bat_changes$$ | async as bat_changes"
              [can_add]="true"
              [device]="device"
            ></bg2-device-bat-changes>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</div>

<ng-template #unknownValue>
  <span>N/A</span>
</ng-template>
