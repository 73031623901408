import { Pipe, PipeTransform } from '@angular/core';

import { parseDate } from '../tools';

@Pipe({
  name: 'parseDate',
  pure: true,
})
export class ParseDatePipe implements PipeTransform {
  public transform(value: Date | string | number): Date {
    return parseDate(value);
  }
}
