<!-- Statuses -->
<simple-histogram *ngIf="histogram_type === 'statuses' && data"
    [data]="data"
    [items_config]="STATUS_CONFIG"
    [histogram_height]="histogram_height"
    [histogram_color]="'#F6C674'">
</simple-histogram>

<!-- Queens -->
<simple-histogram *ngIf="histogram_type === 'queens' && data"
    [data]="data"
    [items_config]="QUEEN_CONFIG"
    [histogram_height]="histogram_height"
    [histogram_color]="'#cdcdcd'">
</simple-histogram>

<!-- Brood_frames -->
<simple-histogram *ngIf="histogram_type === 'broodframes' && data"
    [data]="data"
    [items_config]="BROODFRAME_CONFIG"
    [histogram_height]="histogram_height"
    [histogram_color]="'#55AA55'">
</simple-histogram>

<!-- Supers -->
<simple-histogram *ngIf="histogram_type === 'supers' && data"
    [data]="data"
    [items_config]="SUPERS_CONFIG"
    [histogram_height]="histogram_height"
    [histogram_color]="'#8BB1D0'">
</simple-histogram>
