import { clone } from 'lodash-es';

import * as L from 'leaflet';

import { AbstractMarker } from '..';
import { drawPolygonCircle } from 'app/misc/tools/geomap';
import { MapMarkerType } from '../../enumerators/MapMarkerType.enum';
import { PositionHelper } from 'app/views/devices/dialogs-and-modals/modals/route-tracer/route-tracer.modal';
import { ConsoleLoggerService } from 'app/core/console-logger.service';

export interface RoutePositionMarkerData {
  tracking_state: MapMarkerType;
  previous_position: PositionHelper;
  current_position: PositionHelper;
  next_position: PositionHelper;
}

export class RoutePositionMarker extends AbstractMarker {
  private _position_marker_data: RoutePositionMarkerData = null;

  // #region -> (class model data)

  public get previous_position(): PositionHelper {
    return this._position_marker_data.previous_position;
  }

  public get current_position(): PositionHelper {
    return this._position_marker_data.current_position;
  }

  public get tracking_state(): MapMarkerType {
    return this._position_marker_data.tracking_state;
  }

  public get line_coloration(): string {
    return this._position_marker_data?.current_position?.color;
  }

  public get hasPrecisePosition(): boolean {
    if (!this.current_position?.accuracy) {
      return;
    }
    if (!this.current_position?.accuracy) {
      return;
    }
    return this.current_position?.accuracy <= 1000;
  }

  // #endregion

  private get svg_position(): L.DivIconOptions {
    return {
      iconSize: this.hasPrecisePosition ? [50, 50] : [40, 40], // size of the icon
      // shadowSize: [50, 64], // size of the shadow
      iconAnchor: this.hasPrecisePosition ? [25, 46] : [20, 20], // point of the icon which will correspond to marker's location
      // shadowAnchor: [4, 62],  // the same for the shadow
      popupAnchor: this.hasPrecisePosition ? [0, -25]: [0, -10], // point from which the popup should open relative to the iconAnchor
    };
  }

  // #region -> (class model basics)

  constructor(latlng: L.LatLngExpression, data: RoutePositionMarkerData) {
    super(latlng);

    // Update custom marker properties
    this._position_marker_data = clone(data);

    // Update leaflet marker properties
    this.options.icon = this.buildMarker();
    if (this._position_marker_data?.current_position?.accuracy >= 0) {
      this.circle = this.buildCircle(this._position_marker_data.current_position.accuracy, {});
    }
  }

  // #endregion

  protected buildMarker(): L.DivIcon | L.Icon<L.IconOptions> {
    switch (this._position_marker_data?.tracking_state) {
      case MapMarkerType.POS_TYPE_MOVEMENT: {
        const html_approximative = `<svg width="40" height="40" version="1.1" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
          <g transform="translate(-7.4479 -16.632)">
            <circle cx="19.448" cy="28.632" r="11.671" fill="none" stroke="${this.line_coloration}" stroke-linecap="round" stroke-linejoin="round" stroke-width=".62769" style="paint-order:stroke fill markers"/>
            <ellipse cx="19.448" cy="28.632" rx="10.522" ry="10.829" fill="${this.line_coloration}" style="paint-order:stroke fill markers"/>
            <path d="m21.879 32.887h-4.8626v-8.5098h4.8626m0.30392-1.2157h-5.4705a0.91175 0.91175 0 0 0-0.91175 0.91175v9.1175a0.91175 0.91175 0 0 0 0.91175 0.91175h5.4705a0.91175 0.91175 0 0 0 0.91175-0.91175v-9.1175a0.91175 0.91175 0 0 0-0.91175-0.91175m1.5196 8.5098h1.2157v-6.0784h-1.2157m1.8236 1.2157v3.647h1.2157v-3.647m-12.765 4.8626h1.2157v-6.0784h-1.2157m-1.8236 4.8626h1.2157v-3.647h-1.2157z" fill="#008000" stroke-width=".60784"/>
          </g>
        </svg>`;

        const html_not_approximative = `<svg width="50" height="50" viewBox="0 0 24 24">
          <path fill="${this.line_coloration}" d="M12,11.5A2.5,2.5 0 0,1 9.5,9A2.5,2.5 0 0,1 12,6.5A2.5,2.5 0 0,1 14.5,9A2.5,2.5 0 0,1 12,11.5M12,2A7,7 0 0,0 5,9C5,14.25 12,22 12,22C12,22 19,14.25 19,9A7,7 0 0,0 12,2Z" />
          <rect x="9.0508" y="5.8983" width="6.7119" height="6.3051" fill="${this.line_coloration}"/>
          <path d="m13.571 11.799h-3.1412v-5.4972h3.1412m0.19633-0.78531h-3.5339a0.58898 0.58898 0 0 0-0.58898 0.58898v5.8898a0.58898 0.58898 0 0 0 0.58898 0.58898h3.5339a0.58898 0.58898 0 0 0 0.58898-0.58898v-5.8898a0.58898 0.58898 0 0 0-0.58898-0.58898m0.98164 5.4972h0.78531v-3.9266h-0.78531m1.178 0.78531v2.3559h0.78531v-2.3559m-8.2458 3.1412h0.78531v-3.9266h-0.78531m-1.178 3.1412h0.78531v-2.3559h-0.78531z" fill="green" stroke-width=".39266"/>
        </svg>`;
        return L.divIcon({
          className: 'marker-pos-movement',
          html: L.Util.template(this.hasPrecisePosition ? html_not_approximative : html_approximative, {}),
          ...this.svg_position,
        });
      }

      case MapMarkerType.POS_TYPE_PERIODIC_LOCATION: {
        const html_approximative = `<svg width="40" height="40" version="1.1" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
          <circle cx="12" cy="12" r="11.671" fill="none" stroke="${this.line_coloration}" stroke-linecap="round" stroke-linejoin="round" stroke-width=".62769" style="paint-order:stroke fill markers"/>
          <ellipse cx="12" cy="12" rx="10.522" ry="10.829" fill="${this.line_coloration}" style="paint-order:stroke fill markers"/>
          <g transform="matrix(1.6201 0 0 1.6201 -43.638 -15.432)">
            <path d="m37.478 13.796c-0.80487-0.80487-1.9095-1.3044-3.1362-1.3044a4.4407 4.4407 0 0 0-4.4407 4.4407 4.4407 4.4407 0 0 0 4.4407 4.4407c2.0705 0 3.7968-1.4155 4.2908-3.3305h-1.1546c-0.45517 1.2933-1.6875 2.2203-3.1362 2.2203a3.3305 3.3305 0 0 1-3.3305-3.3305 3.3305 3.3305 0 0 1 3.3305-3.3305c0.92144 0 1.743 0.38301 2.3425 0.98805l-1.7874 1.7874h3.8856v-3.8856z" fill="#ffa500" stroke-width=".55508"/>
          </g>
        </svg>`;

        const html_not_approximative = `<svg width="50" height="50" viewBox="0 0 24 24">
          <path fill="${this.line_coloration}" d="M12,11.5A2.5,2.5 0 0,1 9.5,9A2.5,2.5 0 0,1 12,6.5A2.5,2.5 0 0,1 14.5,9A2.5,2.5 0 0,1 12,11.5M12,2A7,7 0 0,0 5,9C5,14.25 12,22 12,22C12,22 19,14.25 19,9A7,7 0 0,0 12,2Z" />
          <rect fill="${this.line_coloration}" x="8.6441" y="5.7966" width="7.1186" height="6.6102"/>
          <path d="m15.136 5.7966c-0.80487-0.80487-1.9095-1.3044-3.1362-1.3044a4.4407 4.4407 0 0 0-4.4407 4.4407 4.4407 4.4407 0 0 0 4.4407 4.4407c2.0705 0 3.7968-1.4155 4.2908-3.3305h-1.1546c-0.45517 1.2933-1.6875 2.2203-3.1362 2.2203a3.3305 3.3305 0 0 1-3.3305-3.3305 3.3305 3.3305 0 0 1 3.3305-3.3305c0.92144 0 1.743 0.38301 2.3425 0.98805l-1.7874 1.7874h3.8856v-3.8856z" fill="orange" stroke-width=".55508"/>
        </svg>`;

        return L.divIcon({
          className: 'marker-pos-periodic-location',
          html: L.Util.template(this.hasPrecisePosition ? html_not_approximative : html_approximative, {}),
          ...this.svg_position,
        });
      }

      case MapMarkerType.POS_TYPE_STOP: {
        const html_approximative = `<svg width="40" height="40" version="1.1" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
          <ellipse cx="12" cy="12" rx="10.522" ry="10.829" fill="${this.line_coloration}" style="paint-order:stroke fill markers"/>
          <g transform="matrix(1.4938 0 0 1.4938 -12.816 -26.536)">
            <rect x="12.598" y="21.782" width="8.0298" height="8.0298" fill="#f00"/>
          </g>
          <circle cx="12" cy="12" r="11.671" fill="none" stroke="${this.line_coloration}" stroke-linecap="round" stroke-linejoin="round" stroke-width=".62769" style="paint-order:stroke fill markers"/>
        </svg>`;

        const html_not_approximative = `<svg width="50" height="50" viewBox="0 0 24 24">
          <path fill="${this.line_coloration}" d="M12,11.5A2.5,2.5 0 0,1 9.5,9A2.5,2.5 0 0,1 12,6.5A2.5,2.5 0 0,1 14.5,9A2.5,2.5 0 0,1 12,11.5M12,2A7,7 0 0,0 5,9C5,14.25 12,22 12,22C12,22 19,14.25 19,9A7,7 0 0,0 12,2Z" />
          <rect x="7.9851" y="4.6821" width="8.0298" height="8.0298" fill="red"/>
        </svg>`;

        return L.divIcon({
          className: 'marker-pos-stop',
          html: L.Util.template(this.hasPrecisePosition ? html_not_approximative : html_approximative, {}),
          ...this.svg_position,
        });
      }

      default: {
        return L.divIcon({
          className: 'leaflet-data-marker',
          html: L.Util.template(
            `
          <svg width="50" height="50" viewBox="0 0 24 24">
            <path fill="red" d="M12,11.5A2.5,2.5 0 0,1 9.5,9A2.5,2.5 0 0,1 12,6.5A2.5,2.5 0 0,1 14.5,9A2.5,2.5 0 0,1 12,11.5M12,2A7,7 0 0,0 5,9C5,14.25 12,22 12,22C12,22 19,14.25 19,9A7,7 0 0,0 12,2Z" />
          </svg>`,
            {}
          ),
          iconSize: [50, 50], // size of the icon
          // shadowSize: [50, 64], // size of the shadow
          iconAnchor: [25, 46], // point of the icon which will correspond to marker's location
          // shadowAnchor: [4, 62],  // the same for the shadow
          popupAnchor: [0, -36], // point from which the popup should open relative to the iconAnchor
        });
      }
    }
  }

  protected buildCircle(radius: number, options: L.PolylineOptions): L.Polygon<any> {
    return drawPolygonCircle(this.getLatLng(), radius, options);
  }
}
