import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

import { marker as i18n } from '@biesbjerg/ngx-translate-extract-marker';

import { strEnum } from 'app/misc/tools';

import {
  Bg2SimpleHistogramBaseComponent,
  SimpleHistogramConfig,
} from 'app/widgets/widgets-reusables/charts/histograms/simple-histogram-base/simple-histogram-base.component';
import { ErrorHelperData } from 'app/widgets/widgets-reusables/errors/error-helper/error-helper.component';

const hive_broodframe = strEnum(['C0', 'C2', 'C3', 'C4', 'C5', 'C6', 'C7', 'C7+']);
export type HiveBroodframe = keyof typeof hive_broodframe;

export type HiveBroodframesData = { [key in HiveBroodframe]: number };

@Component({
  selector: 'bg2-evaluation-histogram-hive-broodframes',
  templateUrl: '../../../../../../widgets/widgets-reusables/charts/histograms/simple-histogram-base/simple-histogram-base.component.html',
  styleUrls: ['../../../../../../widgets/widgets-reusables/charts/histograms/simple-histogram-base/simple-histogram-base.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class Bg2EvaluationHistogramHiveBroodframesComponent extends Bg2SimpleHistogramBaseComponent implements OnInit {
  @Input()
  public set broodframes(broodframes: HiveBroodframesData) {
    this.histogram_data = broodframes;
  }

  // #region -> (component basics)

  constructor() {
    super();
    this.histogram_type = 'hive-broodframe';
  }

  ngOnInit(): void {
    super.ngOnInit();

    this.histogram_configuration = new Map([
      ['C0', { key: 'C0', label: 'EVENT.VISIT.BF.No BF', img: 'assets/img/hive_frame/c0.png' }],
      ['C2', { key: 'C2', label: 'EVENT.VISIT.BF.2 BF', img: 'assets/img/hive_frame/c2.png' }],
      ['C3', { key: 'C3', label: 'EVENT.VISIT.BF.3 BF', img: 'assets/img/hive_frame/c3.png' }],
      ['C4', { key: 'C4', label: 'EVENT.VISIT.BF.4 BF', img: 'assets/img/hive_frame/c4.png' }],
      ['C5', { key: 'C5', label: 'EVENT.VISIT.BF.5 BF', img: 'assets/img/hive_frame/c5.png' }],
      ['C6', { key: 'C6', label: 'EVENT.VISIT.BF.6 BF', img: 'assets/img/hive_frame/c6.png' }],
      ['C7', { key: 'C7', label: 'EVENT.VISIT.BF.7 BF', img: 'assets/img/hive_frame/c7.png' }],
      ['C7+', { key: 'C7+', label: 'EVENT.VISIT.BF.+ 7 BF', img: 'assets/img/hive_frame/c7+.png' }],
    ]) as SimpleHistogramConfig;

    this.has_data$$.subscribe({
      next: has_data => {
        if (!has_data) {
          this.error = new ErrorHelperData([{ type: 'span', content: i18n<string>('ALL.DATA.MISC.No data') }]);
        } else {
          this.error = null;
        }
      },
    });
  }

  // #endregion
}
