import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

import { BehaviorSubject, combineLatest, Subscription } from 'rxjs';
import { filter, map, switchMap, take, tap } from 'rxjs';
import { concat, isEqual, some } from 'lodash-es';

import { ConsoleLoggerService } from 'app/core/console-logger.service';
import { distinctUntilRealChanged, replay } from '@bg2app/tools/rxjs';

import { GhostSolutionAlternatives } from 'app/core/ghost/models/ghost-solution';

import { Location } from 'app/models';
import { Router } from '@angular/router';
import { GhostService } from 'app/core/ghost';

@Component({
  selector: 'bg2-ghost-solutions',
  templateUrl: './ghost-solutions.component.html',
  styleUrls: ['./ghost-solutions.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GhostSolutionsComponent {
  @Input()
  public set solutions(solutions: GhostSolutionAlternatives[]) {
    this._solutions$$.next(solutions);
  }

  private _solutions$$ = new BehaviorSubject<GhostSolutionAlternatives[]>(null);
  public solutions$$ = this._solutions$$.asObservable();

  private _location$$ = new BehaviorSubject<Location>(null);
  public location$$ = this._location$$.asObservable();

  @Input()
  public set location(location: Location) {
    this._location$$.next(location);
  }

  @Output()
  validate: EventEmitter<boolean> = new EventEmitter<false>();

  private _logger = new ConsoleLoggerService('GhostSolutionsComponent');

  constructor(private _router: Router, private ghostService: GhostService) {}

  public is_root_solution$$ = this.solutions$$.pipe(
    map(solutions_alt => solutions_alt.map(solution_alt => !solution_alt.has_parent)),
    map(root_solutions => some(root_solutions)),
    replay()
  );

  public validateOne(solution_alt: GhostSolutionAlternatives) {
    solution_alt.validate().subscribe(() => {
      this.validate.emit(true);
    });
  }

  public validateAll() {
    this.solutions$$
      .pipe(
        take(1),
        switchMap(solutions => this.ghostService.validate(solutions))
      )
      .subscribe();
  }
}
