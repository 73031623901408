import * as d3 from 'd3';

import { isArray, isEmpty, isNil, max, mean, min, some } from 'lodash-es';

import { addDays, format, isSameDay, setHours } from 'date-fns/esm';

import { AppStateService } from 'app/core/app-state.service';
import { ConsoleLoggerService } from 'app/core/console-logger.service';
import { D3SharedCursorService } from 'app/core/services/global/d3-shared-cursor.service';

import {
  AbstractD3Axes,
  AbstractD3Grid,
  AbstractD3Labels,
  AbstractD3Scales,
  AbstractDataObjects,
  AbstractEventObjects,
  AbstractUserEvents,
  D3SvgBuilderLuxon,
} from '@bg2app/models/charts';
import { compute_weather_icon } from 'app/misc/tools';
import { group_data_every_x_day, group_data_every_x_day_luxon } from '@bg2app/tools/dates';
import { percentile } from 'app/misc/tools/maths';
import { NgZone } from '@angular/core';
import { RGJobData } from 'app/models/data';
import { getTimezoneOffset, utcToZonedTime } from 'date-fns-tz';

export class RGJobDataIconsD3ChartFactory extends D3SvgBuilderLuxon<RGJobData> {
  // #region -> (properties)

  public axes: AbstractD3Axes = {
    container: null,
    time: {
      axis: null,
      container: null,
    },
  };

  public scales: AbstractD3Scales = {
    time: null,
  };

  public data_objects: AbstractDataObjects = {
    container: null,
  };

  public event_objects: AbstractEventObjects = {
    container: null,
  };

  public user_events: AbstractUserEvents = {
    container: null,
    event_bounds: null,
    focus_line_x: null,
    focus_line_y: null,
  };

  public grids: AbstractD3Grid = {
    container: null,
    time: {
      axis: null,
      container: null,
    },
  };

  public labels: AbstractD3Labels = {
    container: null,
    labels: {},
  };

  protected colors: { [key: string]: `#${string}` } = {};

  // #endregion

  // #region -> (factory basics)

  /** */
  protected LOGGER: ConsoleLoggerService = new ConsoleLoggerService('RGJobDataIconsD3ChartFactory');

  /** */
  constructor(protected _shared_cursor: D3SharedCursorService, protected _appState: AppStateService, protected _ngZone: NgZone) {
    super(_shared_cursor, _appState, _ngZone);

    this.margins.top = 5;
    this.margins.right = 50;
    this.margins.bottom = 5;

    this.show_day_cycle_grid = false;
  }

  /** */
  public destroy(): void {
    super.destroy();
  }

  // #endregion

  // #region -> (incoming data)

  /** */
  public get has_data() {
    const data = this.incoming_data?.points;

    if (isArray(data)) {
      return !isEmpty(data ?? []);
    }

    return !isNil(data);
  }

  // #endregion

  // #region -> (creation methods)

  /** */
  protected create_axes(): void {
    super.create_axes();

    this.axes.time.container.style('display', 'none');
  }

  /** */
  public build_event_objects(): void {}

  // #endregion

  // #region -> (updating methods)

  public resize(): void {
    super.resize();
  }

  // #endregion

  // #region -> (user event management)

  /** */
  public on_mouse_enter(is_from_shared_cursor?: boolean): void {
    super.on_mouse_enter(is_from_shared_cursor, false);
  }

  // #endregion

  public append_data(is_after_resize: boolean): void {
    this.scales.time.domain([this.date_range.start, this.date_range.end]);

    // Cleanup previous objects
    this.data_objects.container.selectAll('.day-data-container').remove();

    if (!this.has_data) {
      super.append_data(is_after_resize);
      return;
    }

    group_data_every_x_day_luxon(this.incoming_data.points, this.display_every_x_day).map(datum => {
      if (datum.date_of_day.startOf('day').equals(this.date_range.end.startOf('day'))) {
        return;
      }

      const minimal_temperature = min(datum.values.map(value => value.temperature));
      const maximal_temperature = max(datum.values.map(value => value.temperature));

      // Calculation of the picto for this day
      const has_data = some(
        datum.values.map(
          datum_in_day =>
            datum_in_day?.rain_nb_sensors > 0 ||
            datum_in_day?.humidity_nb_sensors > 0 ||
            datum_in_day?.pressure_nb_sensors > 0 ||
            datum_in_day?.anemo_speed_nb_sensors > 0 ||
            datum_in_day?.temperature_nb_sensors > 0 ||
            datum_in_day?.anemo_heading_nb_sensors > 0
        )
      );

      const icon = has_data
        ? compute_weather_icon(
            datum?.values?.[datum?.values.length - 1]?.rain_cumul_day ?? 0,
            {
              mean: mean(datum?.values?.map(p => p.pressure?.value)),
              limit_up: mean(datum?.values?.map(p => p.pressure?.range_up)),
              limit_down: mean(datum?.values?.map(p => p.pressure?.range_down)),
            },
            percentile(
              90,
              datum.values.map(p => p?.anemo_speed)
            )?.[0] as number
          )
        : 'unknown';

      const timezone_offset = getTimezoneOffset(this.geoposition.value.timezone);

      const end_day_value = this.scales.time(
        utcToZonedTime(
          datum.date_of_day
            .plus({ days: this.display_every_x_day })
            .set({ hour: timezone_offset > 0 ? 0 : 24 })
            .toJSDate(),
          this.geoposition.value.timezone
        )
      );
      const start_day_value = this.scales.time(
        utcToZonedTime(datum.date_of_day.set({ hour: timezone_offset > 0 ? 0 : 24 }).toJSDate(), this.geoposition.value.timezone)
      );

      const max_width = end_day_value - start_day_value;

      // Build day container
      const day_container = this.data_objects.container
        .append('g')
        .attr('class', 'day-data-container')
        .attr('transform', `translate(${start_day_value},${this.calc_size_of(0, ['+top', '+bottom'])})`);

      // Append the current date day
      const day_text = day_container
        .append('text')
        .attr('x', max_width / 2)
        .attr('y', 0)
        .style('font-size', '12px')
        .style('text-anchor', 'middle');

      if (this.days_to_display <= 7) {
        day_text.text(
          format(utcToZonedTime(datum.date_of_day.toJSDate(), this.geoposition.value.timezone), this._appState.dl.ll_ny, {
            locale: this._appState.dl.dateFns,
          })
        );
      } else {
        day_text.text(
          utcToZonedTime(datum.date_of_day.toJSDate(), this.geoposition.value.timezone).getDate() +
            '-' +
            utcToZonedTime(datum.date_of_day.plus({ days: 3 }).toJSDate(), this.geoposition.value.timezone).getDate()
        );
      }

      // Append the weather picon
      const g = day_container
        .append('g')
        .attr('class', 'weather-icon-parts')
        .style('transform-box', 'fill-box')
        .style('transform', `translate(${max_width / 2}px, 5px)`)
        .append('g')
        .style('transform-box', 'fill-box')
        .style('transform', `translate(-50%, 0px)`);

      // Build min/max temperatures
      const min_max_temp_container = day_container
        .append('g')
        .attr('class', 'min-max-temperature')
        .style('transform-box', 'fill-box')
        .style('font-size', '12px');
      const miminal_temp_text = min_max_temp_container
        .append('text')
        .attr('x', 5)
        .attr('y', 40 + 12)
        .attr('fill', 'blue')
        .attr('text-anchor', 'start');
      const maximal_temp_text = min_max_temp_container
        .append('text')
        .attr('x', max_width - 5)
        .attr('y', 40)
        .attr('fill', 'red')
        .attr('text-anchor', 'end');

      if (this.days_to_display <= 7) {
        miminal_temp_text.text((isNil(minimal_temperature) ? '-' : minimal_temperature?.toFixed(1)) + '°C');
        maximal_temp_text.text((isNil(maximal_temperature) ? '-' : maximal_temperature?.toFixed(1)) + '°C');
      } else {
        miminal_temp_text.text((isNil(minimal_temperature) ? '-' : minimal_temperature?.toFixed(0)) + '°C');
        maximal_temp_text.text((isNil(maximal_temperature) ? '-' : maximal_temperature?.toFixed(0)) + '°C');
      }

      this.append_icon_to_container(g, icon);
    });

    super.append_data(is_after_resize);
  }

  /** */
  private weather_icon_paths = {
    /** */
    append: {
      /** */
      unknown: (container: d3.Selection<SVGGElement, unknown, HTMLElement, null>) =>
        container
          .append('path')
          .attr(
            'd',
            'm 4.782623,20.369591 h 3.5869613 v 3.586966 H 4.782623 V 20.369591 M 7.1739293,0.04344295 C 13.570685,0.3064872 16.356564,6.7630286 12.554379,11.605434 c -0.992394,1.195656 -2.5945737,1.984788 -3.3837057,2.98914 -0.801089,0.992394 -0.801089,2.18805 -0.801089,3.383706 H 4.782623 c 0,-1.996746 0,-3.682621 0.8010862,-4.878276 C 6.3728383,11.904348 7.9750173,11.198912 8.9674123,10.409778 11.860898,7.7315096 11.143505,3.9412809 7.1739293,3.6304104 A 3.5869672,3.5869672 0 0 0 3.5869673,7.2173776 H 0 A 7.1739345,7.1739345 0 0 1 7.1739293,0.04344295 Z'
          ),

      /**
       * Appends a cloud path object.
       */
      cloud: {
        /**
         * Appends a cloud with no cuts in the border line.
         */
        'type-1': (container: d3.Selection<SVGGElement, unknown, HTMLElement, null>) =>
          container
            .append('path')
            .attr(
              'd',
              'M 20.238395,11.438145 C 19.615006,8.7643881 17.100566,6.7433267 14.049497,6.6144173 c -0.102024,-0.00445 -0.203244,-0.012594 -0.306874,-0.012594 -2.780345,0 -5.1590219,1.5758056 -6.1511414,3.8102327 C 6.890973,10.051997 6.0852273,9.8445571 5.2264615,9.8445571 c -1.795455,0 -3.3595494,0.8994029 -4.2030519,2.2336899 -0.42014452,0.663808 -0.66435861,1.432079 -0.66435861,2.255171 0,2.478913 2.17864631,4.488121 4.86660721,4.488121 l 14.4343348,-0.0074 c 2.209173,-0.01482 3.995791,-1.669895 3.995791,-3.710219 0,-1.859554 -1.483761,-3.394613 -3.417389,-3.665766 z'
            )
            .attr('fill', 'white')
            .attr('stroke', '#424242')
            .attr('stroke-width', 0.718102)
            .attr('stroke-linecap', 'round')
            .attr('stroke-linejoin', 'round')
            .attr('stroke-miterlimit', 4),

        /**
         * Appends a cloud with the bottom partially cutted.
         */
        'type-2': (container: d3.Selection<SVGGElement, unknown, HTMLElement, null>) =>
          container
            .append('path')
            .attr(
              'd',
              'm 17.271519,18.999249 c 1.124348,-0.0076 2.989837,-0.03041 4.32221,-0.752925 1.286133,-0.697443 2.059617,-2.076704 2.059617,-3.093911 0,-1.883396 -1.486507,-3.436635 -3.414951,-3.714268 -0.642814,-2.7988308 -3.310494,-4.8998305 -6.50046,-4.8998305 -2.780173,0 -5.1585848,1.5982607 -6.154947,3.8568355 C 6.8839274,10.027475 6.0723742,9.8173753 5.2206452,9.8173753 c -2.691785,0.0075 -4.8693187,2.0409707 -4.8693187,4.5546667 0,1.247883 0.9222151,2.734082 2.3269776,3.562426 1.4249458,0.840245 3.0538559,1.054904 4.4093481,1.054904'
            )
            .attr('fill', 'white')
            .attr('stroke', '#424242')
            .attr('stroke-width', '0.697981')
            .attr('stroke-linecap', 'round')
            .attr('stroke-linejoin', 'round')
            .attr('stroke-miterlimit', 4),
      },

      /**
       * Appends a sun path object.
       */
      sun: {
        /**
         * Appends a small sun.
         */
        small: (container: d3.Selection<SVGGElement, unknown, HTMLElement, null>) =>
          container
            .append('path')
            .attr(
              'd',
              'M 7.731505,0 V 3.485752 Z M 13.23034,2.2655418 10.754417,4.72963 Z M 2.21426,2.3220788 4.690183,4.787482 Z M 7.731505,4.4600763 A 3.3124397,3.2989192 0 0 0 4.419317,7.759116 3.3124397,3.2989192 0 0 0 7.731505,11.058155 3.3124397,3.2989192 0 0 0 11.04369,7.759116 3.3124397,3.2989192 0 0 0 7.731505,4.4600763 Z M 0,7.759116 h 3.500215 z m 11.973312,0 h 3.500216 z m -7.340984,2.867757 -2.475923,2.465404 z m 6.151016,0.05786 2.475923,2.464088 z m -3.051839,1.303048 v 3.485752 z'
            )
            .attr('fill', '#fbe000')
            .attr('stroke', '#666666')
            .attr('stroke-linecap', 'butt')
            .attr('stroke-miterlimit', '4')
            .attr('stroke-linejoin', 'bevel')
            .attr('stroke-width', '0.437264'),

        /**
         * Appends a large sun.
         */
        large: (container: d3.Selection<SVGGElement, unknown, HTMLElement, null>) =>
          container
            .append('path')
            .attr(
              'd',
              'm 11.991843,-2e-6 v 5.4065271 z m 8.528891,3.5139371 -3.840245,3.8218895 z m -17.0863357,0.087691 3.8402448,3.823929 z m 8.5574447,3.3161112 a 5.1377134,5.1167427 0 0 0 -5.1373227,5.1169287 5.1377134,5.1167427 0 0 0 5.1373227,5.11693 5.1377134,5.1167427 0 0 0 5.137319,-5.11693 5.1377134,5.1167427 0 0 0 -5.137319,-5.1169287 z M 0,12.034666 h 5.4289597 z m 18.571039,0 H 24 Z m -11.386131,4.447996 -3.8402447,3.82393 z m 9.540448,0.08974 3.840245,3.821889 z m -4.733513,2.021073 v 5.406527 z'
            )
            .attr('fill', '#fbe000')
            .attr('stroke', '#666666')
            .attr('stroke-linecap', 'butt')
            .attr('stroke-miterlimit', '4')
            .attr('stroke-linejoin', 'bevel')
            .attr('stroke-width', '0.678212'),
      },

      /**
       * Appends a wind path object.
       */
      wind: {
        /**
         * Appends a cloud placed on bottom-right.
         */
        'type-1': (container: d3.Selection<SVGGElement, unknown, HTMLElement, null>) =>
          container
            .append('path')
            .attr(
              'd',
              'm 13.465529,16.877016 a 0.56858178,0.56858178 0 0 1 -0.568582,-0.568581 0.56858178,0.56858178 0 0 1 0.568582,-0.568582 h 4.548653 a 1.1371636,1.1371636 0 0 0 1.137164,-1.137163 1.1371636,1.1371636 0 0 0 -1.137164,-1.137164 c -0.31272,0 -0.597011,0.125088 -0.8017,0.335463 -0.221747,0.233119 -0.585639,0.233119 -0.807385,0 -0.221747,-0.221747 -0.221747,-0.585639 0,-0.807386 0.415063,-0.409379 0.983645,-0.665241 1.609085,-0.665241 a 2.2743271,2.2743271 0 0 1 2.274327,2.274328 2.2743271,2.2743271 0 0 1 -2.274327,2.274326 h -4.548653 m 8.528726,1.137164 a 0.56858178,0.56858178 0 0 0 0.568581,-0.568581 0.56858178,0.56858178 0 0 0 -0.568581,-0.568583 c -0.159203,0 -0.301349,0.06254 -0.403693,0.164889 -0.221747,0.221748 -0.579954,0.221748 -0.801701,0 -0.216061,-0.221747 -0.216061,-0.579953 0,-0.8017 0.307034,-0.307034 0.733471,-0.500352 1.205394,-0.500352 A 1.7057453,1.7057453 0 0 1 23.7,17.445599 1.7057453,1.7057453 0 0 1 21.994255,19.151344 H 14.03411 A 0.56858178,0.56858178 0 0 1 13.465529,18.582762 0.56858178,0.56858178 0 0 1 14.03411,18.01418 h 7.960145 m -0.568582,3.411491 h -7.960144 a 0.56858178,0.56858178 0 0 1 -0.568582,-0.568581 0.56858178,0.56858178 0 0 1 0.568582,-0.568582 h 7.960144 A 1.7057453,1.7057453 0 0 1 23.131418,21.994253 1.7057453,1.7057453 0 0 1 21.425673,23.7 c -0.471923,0 -0.898359,-0.193318 -1.205394,-0.500352 -0.216061,-0.221747 -0.216061,-0.579954 0,-0.801702 0.221747,-0.221747 0.579954,-0.221747 0.801701,0 0.102344,0.102345 0.24449,0.16489 0.403693,0.16489 a 0.56858178,0.56858178 0 0 0 0.568582,-0.568583 0.56858178,0.56858178 0 0 0 -0.568582,-0.568582 z'
            )
            .attr('fill', '#424242')
            .attr('stroke', '#ffffff')
            .attr('stroke-width', 0.5)
            .attr('stroke-miterlimit', 4),

        /**
         * Appends a cloud placed on middle-right.
         */
        'type-2': (container: d3.Selection<SVGGElement, unknown, HTMLElement, null>) =>
          container
            .append('path')
            .attr(
              'd',
              'M 13.481238,9.404357 A 0.56858178,0.56858178 0 0 1 12.912656,8.835776 0.56858178,0.56858178 0 0 1 13.481238,8.267194 h 4.548653 A 1.1371636,1.1371636 0 0 0 19.167055,7.130031 1.1371636,1.1371636 0 0 0 18.029891,5.992867 c -0.31272,0 -0.597011,0.125088 -0.8017,0.335463 -0.221747,0.233119 -0.585639,0.233119 -0.807385,0 -0.221747,-0.221747 -0.221747,-0.585639 0,-0.807386 0.415063,-0.4093788 0.983645,-0.6652408 1.609085,-0.6652408 a 2.2743271,2.2743271 0 0 1 2.274327,2.2743278 2.2743271,2.2743271 0 0 1 -2.274327,2.274326 h -4.548653 m 8.528726,1.137164 A 0.56858178,0.56858178 0 0 0 22.578545,9.97294 0.56858178,0.56858178 0 0 0 22.009964,9.404357 c -0.159203,0 -0.301349,0.06254 -0.403693,0.164889 -0.221747,0.221748 -0.579954,0.221748 -0.801701,0 -0.216061,-0.221747 -0.216061,-0.579953 0,-0.8017 0.307034,-0.307034 0.733471,-0.500352 1.205394,-0.500352 a 1.7057453,1.7057453 0 0 1 1.705745,1.705746 1.7057453,1.7057453 0 0 1 -1.705745,1.705745 h -7.960145 a 0.56858178,0.56858178 0 0 1 -0.568581,-0.568582 0.56858178,0.56858178 0 0 1 0.568581,-0.568582 h 7.960145 m -0.568582,3.411491 h -7.960144 a 0.56858178,0.56858178 0 0 1 -0.568582,-0.568581 0.56858178,0.56858178 0 0 1 0.568582,-0.568582 h 7.960144 a 1.7057453,1.7057453 0 0 1 1.705745,1.705745 1.7057453,1.7057453 0 0 1 -1.705745,1.705747 c -0.471923,0 -0.898359,-0.193318 -1.205394,-0.500352 -0.216061,-0.221747 -0.216061,-0.579954 0,-0.801702 0.221747,-0.221747 0.579954,-0.221747 0.801701,0 0.102344,0.102345 0.24449,0.16489 0.403693,0.16489 a 0.56858178,0.56858178 0 0 0 0.568582,-0.568583 0.56858178,0.56858178 0 0 0 -0.568582,-0.568582 z'
            )
            .attr('fill', '#424242')
            .attr('stroke', '#ffffff')
            .attr('stroke-width', 0.5)
            .attr('stroke-miterlimit', 4),
      },

      /**
       * Appends a rain path object.
       */
      rain: {
        /**
         * Appends a rainy weather.
         */
        rainy: (container: d3.Selection<SVGGElement, unknown, HTMLElement, null>) =>
          container
            .append('path')
            .attr(
              'd',
              'm 14.321828,20.182673 -2.019532,-4.009363 -2.01953,4.009363 c 0,0 -0.207894,0.386086 -0.207894,0.940468 0,1.227558 0.999866,2.227424 2.227424,2.227424 1.227558,0 2.227424,-0.999866 2.227424,-2.227424 0,-0.554382 -0.207892,-0.940468 -0.207892,-0.940468 z'
            )
            .attr('fill', '#0079ff'),

        /**
         * Appends a pouring weather.
         */
        pouring: (container: d3.Selection<SVGGElement, unknown, HTMLElement, null>) =>
          container
            .append('path')
            .attr(
              'd',
              'M 10.213284,17.434303 7.4714981,22.18192 Z m 6.055977,0 -2.741784,4.747617 z m -3.165078,0.134684 -3.5330544,6.12332 z'
            )
            .attr('fill', 'none')
            .attr('stroke', '#0079ff')
            .attr('stroke-width', 1.23095)
            .attr('stroke-linecap', 'butt')
            .attr('stroke-linejoin', 'bevel')
            .attr('stroke-miterlimit', 4),
      },
    },
  };

  /** */
  private append_icon_to_container(
    container: d3.Selection<SVGGElement, unknown, HTMLElement, null>,
    icon_name:
      | 'cloudy-windy'
      | 'partly-windy'
      | 'sunny-windy'
      | 'cloudy-rainy-windy'
      | 'partly-rainy-windy'
      | 'cloudy-pouring-windy'
      | 'partly-pouring-windy'
      | 'cloudy'
      | 'partly'
      | 'sunny'
      | 'cloudy-rainy'
      | 'partly-rainy'
      | 'cloudy-pouring'
      | 'partly-pouring'
      | 'unknown'
  ): void {
    switch (icon_name) {
      case 'cloudy-windy': {
        this.weather_icon_paths.append.cloud['type-1'](container);
        this.weather_icon_paths.append.wind['type-2'](container);

        break;
      }

      case 'partly-windy': {
        this.weather_icon_paths.append.sun.small(container);
        this.weather_icon_paths.append.cloud['type-1'](container);
        this.weather_icon_paths.append.wind['type-2'](container);

        break;
      }

      case 'sunny-windy': {
        this.weather_icon_paths.append.sun.small(container);
        this.weather_icon_paths.append.wind['type-1'](container);

        break;
      }

      case 'cloudy-rainy-windy': {
        this.weather_icon_paths.append.cloud['type-2'](container);
        this.weather_icon_paths.append.rain.rainy(container);
        this.weather_icon_paths.append.wind['type-2'](container);

        break;
      }

      case 'partly-rainy-windy': {
        this.weather_icon_paths.append.sun.small(container);
        this.weather_icon_paths.append.cloud['type-2'](container);
        this.weather_icon_paths.append.rain.rainy(container);
        this.weather_icon_paths.append.wind['type-2'](container);

        break;
      }

      case 'cloudy-pouring-windy': {
        this.weather_icon_paths.append.cloud['type-2'](container);
        this.weather_icon_paths.append.rain.pouring(container);
        this.weather_icon_paths.append.wind['type-2'](container);

        break;
      }

      case 'partly-pouring-windy': {
        this.weather_icon_paths.append.sun.small(container);
        this.weather_icon_paths.append.cloud['type-2'](container);
        this.weather_icon_paths.append.rain.pouring(container);
        this.weather_icon_paths.append.wind['type-2'](container);

        break;
      }

      case 'cloudy': {
        this.weather_icon_paths.append.cloud['type-1'](container);

        break;
      }

      case 'partly': {
        this.weather_icon_paths.append.sun.small(container);
        this.weather_icon_paths.append.cloud['type-1'](container);

        break;
      }

      case 'sunny': {
        this.weather_icon_paths.append.sun.large(container);

        break;
      }

      case 'cloudy-rainy': {
        this.weather_icon_paths.append.cloud['type-2'](container);
        this.weather_icon_paths.append.rain.rainy(container);

        break;
      }

      case 'partly-rainy': {
        this.weather_icon_paths.append.sun.small(container);
        this.weather_icon_paths.append.cloud['type-2'](container);
        this.weather_icon_paths.append.rain.rainy(container);

        break;
      }

      case 'cloudy-pouring': {
        this.weather_icon_paths.append.cloud['type-2'](container);
        this.weather_icon_paths.append.rain.pouring(container);

        break;
      }

      case 'partly-pouring': {
        this.weather_icon_paths.append.sun.small(container);
        this.weather_icon_paths.append.cloud['type-2'](container);
        this.weather_icon_paths.append.rain.pouring(container);

        break;
      }

      default: {
        this.weather_icon_paths.append.unknown(container);
      }
    }
  }
}
