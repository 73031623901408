import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { get, isEmpty, isNil } from 'lodash-es';

import { TranslateService } from '@ngx-translate/core';
import { marker as i18n } from '@biesbjerg/ngx-translate-extract-marker';

import { combineLatest } from 'rxjs';
import { take } from 'rxjs';

import { formatDuration, intervalToDuration } from 'date-fns';

import { AppStateService } from 'app/core/app-state.service';
import { ConsoleLoggerService } from 'app/core/console-logger.service';
import { ExcelExportService } from 'app/core/export/excel-export.service';

import { CompareByType, parseDateForXls } from 'app/misc/tools';
import { PositionDataPoint } from 'app/models/data';
import { DatatableColumn } from 'app/models/misc/datatable';
import { CONFIG_EXPORT_FORMAT_TYPE } from 'app/typings/export';
import { DatatableBaseRow } from 'app/typings/datatable/interfaces/DatatableBaseRow.iface';

import { DeviceMovement } from 'app/views/devices/dialogs-and-modals/modals/route-tracer/route-tracer.modal';
import { TableBaseComponent } from 'app/widgets/misc-widgets/table-base/table-base.component';
import { UrlParamsService } from 'app/core/url-param.service';

export interface PositionRow extends DatatableBaseRow {
  point: PositionDataPoint;
  position: {
    latitude: number;
    longitude: number;
  };
  accuracy: number;
  position_type: 'GPS' | 'CELLIDS';
  gps_fix: boolean;
  movement_num: number;
  movement: DeviceMovement;
}

@Component({
  selector: 'bg2-device-movements-table',
  templateUrl: 'device-movements-table.component.html',
  styleUrls: ['device-movements-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DeviceMovementsTableComponent extends TableBaseComponent<PositionRow> implements OnInit {
  private _logger = new ConsoleLoggerService(this.constructor.name, true);

  public readonly column_definitions: DatatableColumn<PositionRow>[] = [
    {
      property: 'movement_num',
      short_property: 'mvmt_id',
      label: i18n('VIEWS.DEVICES.MOVEMENTS_TBL.Movement num'),
      displayed_by_default: false,
      not_configurable: false,
      is_sortable: true,
      is_groupable: true,
      compare_by: CompareByType.NUMBER,
    },
    {
      property: 'point.date',
      short_property: 'mvmt_ts',
      label: i18n('VIEWS.DEVICES.MSG_TABLE.date'),
      displayed_by_default: true,
      not_configurable: false,
      is_sortable: true,
      is_groupable: false,
      compare_by: CompareByType.DATE,
      export: {
        format: CONFIG_EXPORT_FORMAT_TYPE.DATE_FORMAT_LLL,
        tranform: date => parseDateForXls(date)
      },
    },
    {
      property: 'point.tracking_state',
      short_property: 'mvmt_trck_ste',
      label: i18n('VIEWS.DEVICES.MOVEMENTS_TBL.Tracking state'),
      displayed_by_default: true,
      not_configurable: false,
      is_sortable: true,
      is_groupable: false,
      compare_by: CompareByType.STRING,
    },
    {
      property: 'position',
      short_property: 'mvmt_pos',
      label: i18n('VIEWS.DEVICES.MOVEMENTS_TBL.Position'),
      displayed_by_default: true,
      not_configurable: false,
      is_sortable: false,
      is_groupable: false,
      compare_by: CompareByType.STRING,
      export: {
        format: CONFIG_EXPORT_FORMAT_TYPE.TEXT,
        tranform: (value: { latitude: number; longitude: number }) => `${value?.latitude?.toFixed(5)}, ${value?.longitude?.toFixed(5)}`,
      },
    },
    {
      property: 'accuracy',
      short_property: 'mvmt_acc',
      label: i18n('VIEWS.DEVICES.MOVEMENTS_TBL.Accuracy'),
      displayed_by_default: true,
      not_configurable: false,
      is_sortable: true,
      is_groupable: false,
      compare_by: CompareByType.NUMBER,
      export: {
        format: CONFIG_EXPORT_FORMAT_TYPE.UNIT_METERS_2,
      },
    },
    {
      property: 'position_type',
      short_property: 'mvmt_type',
      label: i18n('VIEWS.DEVICES.MOVEMENTS_TBL.Postion type'),
      displayed_by_default: true,
      not_configurable: false,
      is_sortable: true,
      is_groupable: true,
      compare_by: CompareByType.STRING,
      export: {
        format: CONFIG_EXPORT_FORMAT_TYPE.TEXT,
        tranform: (value, { _translate }) => {
          if (value === 'CELLIDS') {
            return _translate.instant(i18n<string>('VIEWS_WINDOWED.MODALS.ROUTE_TRACER.Approximative position'));
          }
          
          return value;
        },
      },
    },
    {
      property: 'point.gps_speed',
      short_property: 'mvmt_gps_spd',
      label: i18n('VIEWS.DEVICES.MOVEMENTS_TBL.GPS Speed'),
      displayed_by_default: true,
      not_configurable: false,
      is_sortable: true,
      is_groupable: false,
      compare_by: CompareByType.NUMBER,
      export: {
        format: CONFIG_EXPORT_FORMAT_TYPE.UNIT_KILOMETER_PER_HOUR_0,
      },
    },
    {
      property: 'point.tracking_delta_last_move',
      short_property: 'mvmt_trck_dt_ls_mvmt',
      label: i18n('VIEWS.DEVICES.MOVEMENTS_TBL.Tracking delta last move'),
      displayed_by_default: true,
      not_configurable: false,
      is_sortable: true,
      is_groupable: false,
      compare_by: CompareByType.NUMBER,
      export: {
        format: CONFIG_EXPORT_FORMAT_TYPE.TEXT,
        tranform: value => {
          const duration_str = formatDuration(
            intervalToDuration({
              start: 0,
              end: value * 1000,
            }),
            {
              locale: this.appState.dl.dateFns,
            }
            );
            
            return duration_str;
          },
        },
      },
    ];
    
    @Input()
    set routes(routes: PositionRow[]) {
      this.paged_data_rows = routes;
      this.filtered_data_rows = routes;
    }
    
    @Input()
    public set export_as(export_as: 'xlsx' | 'csv') {
    if (!isNil(export_as)) {
      this._exportService.setExportStep('export_triggered');
      this._exportService.setExportStep('ask_authorization');
      this._exportService.setExportStep('querying_data');
      this._exportService.setExportStep('building_data');
      this._exportService.setExportStep('preparing_export');

      combineLatest([this.export_headers$$, this.export_data$$, this.splitted_by$$])
        .pipe(take(1))
        .subscribe({
          next: ([headers, data, split]) => {
            if (!isEmpty(data) || !isNil(data)) {
              this._exportService.setHeaders(headers);
              this._exportService.setData(data, split);
              this._exportService.generateExcel(
                { title: i18n<string>('CORE.SERVICES.EXPORT.Export of routes') },
                export_as as any
              );
            } else {
              this._exportService.setExportStep('abort');
            }

            this._is_exporting$$.next(false);
            this.exported.next(true);
            this.error = null;
          },
          error: (error: unknown) => {
            (this.error = error);
            this._exportService.setExportStep('abort');
          },
        });
    }
  }

  @Output()
  public exported = new EventEmitter();

  // #region -> (component basics)

  public isNil = isNil;

  constructor(
    public appState: AppStateService,
    private _translate: TranslateService,
    private _exportService: ExcelExportService,
    private readonly _ur_params: UrlParamsService
  ) {
    super(_translate, _ur_params);
  }

  ngOnInit() {
    this.all_columns = this.column_definitions;
    this.set_grouped_columns(['movement_num']);
  }

  // #endregion
}
