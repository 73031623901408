<div class="bg2-button-condition-component">
  <bg2-button
    [type]="types[0]"
    [icon]="icons[0]"
    [color]="colors[0]"
    [message]="messages[0]"
    [loading]="loadings[0]"
    [disabled]="disables[0]"
    [routerLink]="internal_routing_0?.routerLink"
    [queryParams]="internal_routing_0?.queryParams || {}"
    [queryParamsHandling]="internal_routing_0?.queryParamsHandling || 'preserve'"
    (btn_click)="btn_0_click.next($event)"
  ></bg2-button>

  <span class="button-condition">{{ 'ALL.COMMON.Or' | translate }}</span>

  <bg2-button
    [type]="types[1]"
    [icon]="icons[1]"
    [color]="colors[1]"
    [message]="messages[1]"
    [loading]="loadings[1]"
    [disabled]="disables[1]"
    [routerLink]="internal_routing_1?.routerLink"
    [queryParams]="internal_routing_1?.queryParams || {}"
    [queryParamsHandling]="internal_routing_1?.queryParamsHandling || 'preserve'"
    (btn_click)="btn_1_click.next($event)"
  ></bg2-button>
</div>
