import { Component, ChangeDetectionStrategy, Input } from '@angular/core';

import { isNil } from 'lodash-es';

import { BehaviorSubject, combineLatest, Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs';
import { distinctUntilRealChanged, replay, robustCombineLatest } from '@bg2app/tools/rxjs';

import { DRDevice } from 'app/models';
import { DeviceStatusGPSStr, DeviceStatus, DeviceStatusGPS } from 'app/models/devices/DRDevice';
import { compute_gps_state } from 'app/models/devices/_functions';

@Component({
  selector: 'bg2-gps-level',
  templateUrl: 'gps-level.component.html',
  styleUrls: ['gps-level.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GPSLevelComponent {
  /** */
  @Input()
  public open_in_modal = true;

  // #region -> (device management)

  @Input()
  public set device(device: DRDevice) {
    this._device$$.next(device);
  }

  private _device$$: BehaviorSubject<DRDevice> = new BehaviorSubject(null);
  public device$$: Observable<DRDevice> = this._device$$.asObservable();

  /** */
  public device_imei$$: Observable<number> = this.device$$.pipe(
    switchMap(device => {
      if (isNil(device)) {
        return of(null);
      }

      return device?.imei$$;
    }),
    replay()
  );

  private _device_status$$: Observable<DeviceStatusGPS> = this.device$$.pipe(
    switchMap(device => {
      if (isNil(device)) {
        return of(null);
      }

      return device.status_gps$$;
    })
  );

  // #endregion

  // #region -> (status management)

  @Input()
  public set status(status: DeviceStatus) {
    this._status$$.next(status);
  }

  private _status$$: BehaviorSubject<DeviceStatus> = new BehaviorSubject<DeviceStatus>(null);
  public status$$ = robustCombineLatest([
    this._status$$.asObservable().pipe(
      map((status: DeviceStatus) => {
        if (isNil(status)) {
          return null;
        }

        if (isNil((status as DeviceStatusGPS).state)) {
          const state = compute_gps_state(status);
          return { ...status, state } as DeviceStatusGPS;
        }

        return status as DeviceStatusGPS;
      })
    ),
    this._device_status$$,
  ]).pipe(
    map(([status, device_status]) => {
      if (isNil(status)) {
        return device_status ?? null;
      }

      return status;
    })
  );

  // #endregion

  // #region -> (gps accuracy management)

  gps_accuracy$$: Observable<number> = this.device$$.pipe(
    map(device => {
      if (isNil(device)) {
        return null;
      }

      return device.gps_accuracy;
    })
  );

  // #endregion

  // #region -> (gps state management)

  private state$$: Observable<DeviceStatusGPSStr> = this.status$$.pipe(
    map(status => status?.state),
    distinctUntilRealChanged()
  );

  public is_gps_ok$$: Observable<boolean> = this.state$$.pipe(
    map(state => state === 'gps_ok'),
    distinctUntilRealChanged(),
    replay()
  );

  public is_gps_ko$$: Observable<boolean> = this.state$$.pipe(
    map(state => state === 'gps_ko'),
    distinctUntilRealChanged(),
    replay()
  );

  // #endregion
}
