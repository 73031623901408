// From @node_modules/@angular/*
import { Component, Input, ChangeDetectionStrategy, Inject } from '@angular/core';
import { AppStateService } from 'app/core/app-state.service';
import { ENV } from 'app/core/providers/environment.provider';

// From @src/app/models/*
import { DRDevice } from 'app/models';
import { openGrafanaForSingleDevice } from 'app/models/devices/DRDevice';
import { IEnvironment } from 'environments/common';

@Component({
  selector: 'device-link',
  templateUrl: './device-link.component.html',
  styleUrls: ['./device-link.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DeviceLinkComponent {

  public readonly openGrafanaForSingleDevice = openGrafanaForSingleDevice;

  @Input()
  public device: DRDevice;

  constructor(public appState: AppStateService, @Inject(ENV) public readonly env: IEnvironment) { }
}
