import { Injectable, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';

import { Observable, Subscription } from 'rxjs';
import { filter, map as map_op, tap, debounceTime } from 'rxjs';
import { distinctUntilRealChanged, replay } from '@bg2app/tools/rxjs';

import { assign, cloneDeep, forEach, has, identity, isArray, isEqual, isNil, map, mapValues, pickBy, some } from 'lodash-es';

import * as queryString from 'query-string';

import { ConsoleLoggerService } from './console-logger.service';

import { Dictionary } from 'app/typings/core/interfaces';

@Injectable({
  providedIn: 'root',
})
export class UrlParamsService implements OnDestroy {
  // #region -> (service basics)

  private readonly _logger = new ConsoleLoggerService('UrlParamsService', false);

  constructor(private _router: Router, private _activated_route: ActivatedRoute) {
    this._query_parameters_changed_sub = this._all_changes$$.subscribe();
  }

  ngOnDestroy(): void {
    this._query_parameters_changed_sub?.unsubscribe();
  }

  // #endregion

  // #region -> (internal parameters management)

  private _query_parameters_changed_sub: Subscription = null;

  private _route_updated = 0;
  private _route_internal_change = false;

  private _internal_changes: Dictionary<boolean> = {};
  private _query_parameters_obs: Dictionary<Observable<{ value: any; init: boolean }>> = {};

  private _query_parameters: Dictionary<any> = {};
  private get query_parameters(): Dictionary<any> {
    return this._query_parameters;
  }
  private set query_parameters(query_parameters: Dictionary<any>) {
    this._query_parameters = cloneDeep(query_parameters);
  }

  private _is_first_init = true;
  private _previous_query_params: Params & {
    se: string[];
  } = null;

  /**
   * Observes any changes on the activated route query parameters.
   */
  private _all_changes$$ = this._activated_route.queryParams.pipe(
    distinctUntilRealChanged(),
    // tap(query_params => this._logger.debug('got params: ', query_params)),
    map_op(query_params => {
      let updated_query_params = cloneDeep(query_params);

      if (!this._is_first_init) {
        if (!has(updated_query_params, 'se[]')) {
          updated_query_params['se[]'] = this._previous_query_params?.se?.join(',') ?? '-1';
        }
      }

      this._is_first_init = false;

      const { has_been_formatted, formatted_params } = this._formatQueryParamsToNewFormat(cloneDeep(updated_query_params));
      const parameters = this._transformURLDictToClassicDict(has_been_formatted ? formatted_params : updated_query_params);

      this._previous_query_params = <any>parameters;
      return parameters;
    }),
    map_op(params => this.castParams(params)),
    tap(new_params => {
      // this._logger.debug(new_params, casted_params);
      this._route_updated += 1;
      // this._logger.debug(`NEW queryParams
      //   route_updated:${this._route_updated}
      //   route_internal_change:${this._route_internal_change}
      //   same: ${isEqual(this._query_parameters, casted_params)}`);
      if (!isEqual(this._query_parameters, new_params)) {
        // this._logger.debug(`old:`, this._query_parameters);
        // this._logger.debug(`new:`, casted_params);
        this._query_parameters = new_params;
        this.changeUrl();
      }
      this._route_internal_change = false;
    }),
    map_op(params => ({ params, init: this._route_updated < 1 })),
    replay()
  );

  private changeUrl(replace = true): void {
    if (this._route_updated < 1) {
      return;
    }

    this._route_internal_change = true;
    this._router.navigate([], {
      queryParams: this._transformClassicDictToURLDict(this._query_parameters),
      relativeTo: this._activated_route,
      replaceUrl: replace,
    });
  }

  // #endregion

  // #region -> (parameters management by user)

  public on_change(parameter_name: string | string[], sub_key: string = null): Observable<any | any[]> {
    if (isNil(sub_key)) {
      if (isArray(parameter_name)) {
        sub_key = parameter_name.join('&');
      } else {
        sub_key = parameter_name;
      }
    }

    if (isNil(this._query_parameters_obs[sub_key])) {
      this._query_parameters_obs[sub_key] = this._all_changes$$.pipe(
        filter(value => {
          if (!isNil(this._internal_changes[sub_key])) {
            this._internal_changes[sub_key] = false;
            return false;
          }
          return true;
        }),
        debounceTime(100),
        filter(query_parameters => {
          // this._logger.debug(`[${parameter_name}] data:`, query_parameters);
          if (isArray(parameter_name)) {
            return some(parameter_name, pa => has(query_parameters.params, pa));
          } else {
            return has(query_parameters.params, parameter_name);
          }
        }),
        map_op(query_parameters => {
          let value;

          if (isArray(parameter_name)) {
            value = parameter_name.map(key => query_parameters.params[key]);
          } else {
            value = query_parameters.params[parameter_name];
          }

          return value;
        }),
        distinctUntilRealChanged()
        // tap(value => this._logger.debug(`[${parameter_name}] changed value:`, value))
      );
    }

    return this._query_parameters_obs[sub_key];
  }

  public update(params: Dictionary<any>, replace = true, sub_key: string = null): void {
    // \/ remove null, this is important to avoid overide existing nt null values
    // params = _.pickBy(params, _.identity);
    const old_params = cloneDeep(this._query_parameters);
    assign(this._query_parameters, params);
    // \/ remove null, this is important to avoid update that are not handle by router
    this._query_parameters = pickBy(this._query_parameters, identity);
    // TODO: ^^ A voir si il ne faut pas utilisé, (x) => !_.isNil(x)
    // this._logger.debug(`update replace:${replace} same:${_isEqual(this._query_parameters, old_params)}`);
    if (!isEqual(this._query_parameters, old_params)) {
      // this._logger.debug(`new elements:`, params);
      // this._logger.debug(`update old:`, old_params);
      if (!isNil(sub_key)) {
        this._internal_changes[sub_key] = true;
      }
      this.changeUrl(replace);
    }
  }

  /**
   * Changes the value of an existing query parameter.
   *
   * @param parameter_name The name of the parameter to set.
   * @param parameter_value The value of the parameter to set.
   * @param should_replace Put `true` to replace the url history state.
   */
  public set(parameter_name: string, parameter_value: any, should_replace = true): void {
    if (isNil(parameter_value) && isNil(this._query_parameters[parameter_name])) {
      return;
    }

    const is_same_value = isEqual(parameter_value, this._query_parameters[parameter_name]);
    // this.logger.debug(`[${parameter_name}] set replace:${replace} same_value:${is_same_value}`);

    if (!is_same_value) {
      // this._logger.debug(`[${parameter_name}] set val:`, parameter_value, this._query_parameters[parameter_name]);
      this._query_parameters[parameter_name] = parameter_value;
      this.changeUrl(should_replace);
    }
  }

  public del(parameter_name: string): void {
    if (!parameter_name) {
      return;
    }

    delete this._query_parameters[parameter_name];
    this.changeUrl(false);
  }

  // #endregion

  // #region -> (casters)

  private _cast: Dictionary<(val: any) => any> = {};

  public setCast(key: string, cast: (val: any) => any): void {
    this._cast[key] = cast;
    if (has(this._query_parameters, key)) {
      // This is important to ensure that if a cast is add
      // after initial params values where reed
      // we know the "actual" casted value
      this._query_parameters[key] = this.castParam(key, this._query_parameters[key]);
    }
  }

  private castParam(key: string, value: any): any {
    // this._logger.debug(has(this._cast, key), ' for: ', key);
    if (has(this._cast, key)) {
      return this._cast[key](value);
    }
    return value;
  }

  private castParams(params: Dictionary<any>): Dictionary<any> {
    // this._logger.debug('initial: ', params);
    const new_params = mapValues(params, (value, key) => this.castParam(key, value));
    // this._logger.debug('modified: ', new_params);
    return new_params;
  }

  public castAsInt(key: string): void {
    this.setCast(key, value => +value);
  }

  public castAsIntArray(key: string): void {
    this.setCast(key, value => {
      if (isArray(value)) {
        return value.map(val => +val);
      } else {
        return [+value];
      }
    });
  }

  public castAsArray(key: string): void {
    this.setCast(key, value => {
      if (isArray(value)) {
        return value;
      } else {
        return [value];
      }
    });
  }

  // #endregion

  // #region -> (parameters helpers)

  private _transformClassicDictToURLDict(query_params: Dictionary<any>): Dictionary<any> {
    const stringified_dict = queryString.default.stringify(query_params, {
      arrayFormat: 'bracket-separator',
      arrayFormatSeparator: ',',
    });

    return this._transformQueryStringToDict(stringified_dict);
  }

  private _transformURLDictToClassicDict(query_params: Dictionary<any>): Dictionary<any> {
    const query_string = map(query_params, (value, key) => `${key}=${value}`).join('&');
    return queryString.default.parse(query_string, {
      arrayFormat: 'bracket-separator',
      arrayFormatSeparator: ',',
    });
  }

  private _formatQueryParamsToNewFormat(query_params: Dictionary<any>): { has_been_formatted: boolean; formatted_params: Dictionary<any> } {
    let has_been_formatted = false;

    forEach(query_params, (value, key) => {
      if (isArray(value)) {
        delete query_params[key];
        query_params[`${key}[]`] = `${value}`;
        has_been_formatted = true;
      }
    });

    return { has_been_formatted, formatted_params: query_params };
  }

  /**
   * Stringify query parameters.
   *
   * @param query_params The query parameters to stringify.
   *
   * @returns Returns the stringified query parameters.
   */
  private _stringifyQueryParams(query_params: Dictionary<any>): string {
    return queryString.default.stringify(query_params, {
      arrayFormat: 'bracket-separator',
      arrayFormatSeparator: ',',
    });
  }

  /**
   * Transforms a query string to a dictionnary.
   *
   * @param query_string The query string to transform.
   *
   * @returns Returns a dictionnary of query params.
   */
  private _transformQueryStringToDict(query_string: string) {
    const param_dict: Dictionary<any> = {};

    if (query_string) {
      const splitted_parameters = query_string.split('&');
      splitted_parameters.forEach(parameter => {
        const [key, value] = parameter.split('=');
        param_dict[key] = value;
      });
    }

    return param_dict;
  }

  // #endregion
}
