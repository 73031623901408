import { Component, Input, ChangeDetectionStrategy } from '@angular/core';

import { flatten, isEmpty, isNil } from 'lodash-es';

import { waitForNotNilValue } from '@bg2app/tools/rxjs';
import { BehaviorSubject, combineLatest, filter, map, of, switchMap, take } from 'rxjs';

import { DialogsService } from 'app/widgets/dialogs-modals/dialogs.service';

import { OtherInterventionDialogComponent, OtherInterventionDialogParams } from '../other-intervention-dialog/other-intervention.dialog';

import { Apiary } from 'app/models';

@Component({
  selector: 'bg2-apiary-details',
  templateUrl: './apiary-details.component.html',
  styleUrls: ['./apiary-details.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ApiaryDetailsComponent {
  // #region -> (component basics)

  /** */
  constructor(private dialogs: DialogsService) {}

  // #endregion

  /** */
  @Input()
  public is_compact_view = false;

  // #region -> (apiary entity)

  /** */
  private _apiary$$ = new BehaviorSubject<Apiary>(null);

  /** */
  public apiary$$ = this._apiary$$.asObservable().pipe(waitForNotNilValue());

  /** */
  @Input()
  public set apiary(apiary: Apiary) {
    this._apiary$$.next(apiary);
  }

  /**
   * Observes the apiary's ID.
   */
  public apiary_id$$ = this.apiary$$.pipe(switchMap(apiary => apiary.id$$));

  /**
   * Observes the comments of the current apiary.
   */
  public apiary_comment$$ = this.apiary$$.pipe(switchMap(apiary => apiary.comment$$));

  /**
   * Observes the presence of installed WGs on the apiary.
   */
  public apiary_has_WG$$ = this.apiary$$.pipe(
    switchMap(apiary => apiary?.connected_hives$$),
    switchMap(hives => combineLatest(hives.map(hive => hive.devices_wg$$))),
    map(devices_WG_by_hive => flatten(devices_WG_by_hive)),
    map(devices_WG => !isEmpty(devices_WG))
  );

  /** */
  public apiary_total_equipped_hives$$ = this.apiary$$.pipe(
    switchMap(apiary => apiary.connected_hives$$),
    map(connected_hives => (connected_hives ?? []).length)
  );

  // #endregion

  // #region -> (related location entity)

  /** */
  private location$$ = this.apiary$$.pipe(
    switchMap(apiary => apiary.location$$),
    waitForNotNilValue()
  );

  /** */
  public location_id$$ = this.location$$.pipe(
    filter(location => !isNil(location)),
    switchMap(location => location.id$$)
  );

  // #endregion

  /** */
  public runOtherIntervention(): void {
    this.apiary$$
      .pipe(
        take(1),
        switchMap(apiary => this.dialogs.open<OtherInterventionDialogParams, any>(OtherInterventionDialogComponent, { apiary }))
      )
      .subscribe();
  }
}
