import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Beeguard2Api } from 'app/core';

import { isNil, isNumber, parseInt } from 'lodash-es';

import { Observable } from 'rxjs';
import { filter, map, switchMap, tap } from 'rxjs';
import { distinctUntilRealChanged, replay } from '@bg2app/tools/rxjs';

import { GhostService } from 'app/core/ghost';

import { Location } from 'app/models';
import { AbstractModalComponent } from 'app/widgets/dialogs-modals';
import { GhostSolutionAlternatives } from 'app/core/ghost/models/ghost-solution';
import { ModalParams } from 'app/widgets/dialogs-modals/abstract-modal.component';

interface GhostConfigModalInput extends ModalParams {
  location_id: string;
}

@Component({
  selector: 'bg2-ghost-config-modal',
  templateUrl: './ghost-config-modal.component.html',
  styleUrls: ['./ghost-config-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class Bg2GhostConfigModalComponent extends AbstractModalComponent<GhostConfigModalInput, null> {
  // #region -> (incoming parameters)

  private location_id$$: Observable<number> = this.input_params$$.pipe(
    map(parameters => parameters?.location_id),
    map((location_id: string) => parseInt(location_id, 10)),
    distinctUntilRealChanged(),
    replay()
  );

  // #endregion

  // #region -> (component basics)

  constructor(private _bg2Api: Beeguard2Api, private _ghostService: GhostService) {
    super();
  }

  // #endregion

  /** */
  protected handle_event_before_unload(event: BeforeUnloadEvent): void {
    return null;
  }

  // #region -> (location management)

  public location$$: Observable<Location> = this.location_id$$.pipe(
    filter(location_id => !isNil(location_id) && isNumber(location_id)),
    switchMap(location_id => this._bg2Api.getEntityObj(location_id) as Observable<Location>),
    tap(entity => {
      if (!(entity instanceof Location)) {
        throw new Error('Wrong type exception');
      }
    }),
    replay()
  );

  // #endregion

  // #region -> (solution management)

  public all_solutions$$: Observable<GhostSolutionAlternatives[]> = this.location_id$$.pipe(
    switchMap(location_id => this._ghostService.streamSolutionsFromLocation(location_id)),
    replay()
  );

  // #endregion
}
