import { ChangeDetectionStrategy, Component, Input, OnDestroy } from '@angular/core';

import { marker as i18n } from '@biesbjerg/ngx-translate-extract-marker';

import { keepSourceIfNoError, replay, waitForNotNilValue } from '@bg2app/tools/rxjs';
import { BehaviorSubject, Observable, map, switchMap, of, tap, combineLatest, catchError, filter, Subscription } from 'rxjs';

import { isEmpty, isNil } from 'lodash-es';

import { addMinutes, isSameDay, startOfTomorrow, subDays } from 'date-fns/esm';

import { ConsoleLoggerService } from 'app/core/console-logger.service';

import { Apiary } from 'app/models';
import { WindDataPoint } from 'app/models/data';
import { DateRangeManager } from 'app/misc/tools/dates/date-range-manager';

import { computes_barometric_pressure_from_sea_pressure, compute_pressure_at_sea_level } from 'app/misc/tools';

import { WeatherData } from 'app/models/data/data-classic';
import { ErrorHelperData } from 'app/widgets/widgets-reusables/errors/error-helper/error-helper.component';
import { startOfTomorrowLuxon } from 'app/misc/tools/dates';

@Component({
  selector: 'bg2-apiary-weather-data-container',
  templateUrl: './apiary-weather-data-container.component.html',
  styleUrls: ['./apiary-weather-data-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ApiaryChartWeatherContainerComponent implements OnDestroy {
  // #region -> (component basics)

  /** */
  private readonly LOGGER = new ConsoleLoggerService('ApiaryChartWeatherComponent', true);

  /** */
  public date_range_manager = new DateRangeManager();

  /** */
  private _timezone_sub: Subscription = null;

  /** */
  constructor() {
    this._timezone_sub = this.location_timezone$$.subscribe({
      next: timezone => {
        const end_date = startOfTomorrowLuxon(timezone);
        const start = end_date.minus({ days: 7 });

        this.date_range_manager.initialize([start, end_date], timezone);
      },
    });
  }

  ngOnDestroy(): void {
    this._timezone_sub?.unsubscribe();
  }

  // #endregion

  // #region -> (apiary entity)

  /** */
  private _apiary$$: BehaviorSubject<Apiary> = new BehaviorSubject<Apiary>(null);

  /** */
  public apiary$$ = this._apiary$$.asObservable().pipe(waitForNotNilValue());

  /** */
  @Input()
  public set apiary(apiary: Apiary) {
    this._apiary$$.next(apiary);
  }

  // #endregion

  // #region -> (location entity)

  /** */
  private location$$ = this.apiary$$.pipe(
    switchMap(apiary => apiary.location$$),
    waitForNotNilValue(),
    replay()
  );

  /** */
  public location_id$$ = this.location$$.pipe(switchMap(location => location?.id$$ ?? of(null)));

  /** */
  private location_elevation$$ = this.location$$.pipe(switchMap(location => location.elevation$$));

  /** */
  private location_latitude$$ = this.location$$.pipe(
    switchMap(location => location.position$$),
    map(position => position?.latitude)
  );

  /** */
  private location_timezone$$ = this.location$$.pipe(switchMap(location => location.location_timezone$$));

  /** */
  public location_geoposition$$ = this.location$$.pipe(
    switchMap(location => {
      if (isNil(location)) {
        return of(null);
      }

      return location.position_robust$$;
    })
  );

  // #endregion

  // #region -> (data management)

  /**
   * Observes the apiary weather raw data.
   */
  public weather_data$$: Observable<WeatherData> = this.date_range_manager.range$$.pipe(
    tap(() => this._is_loading_weather_data$$.next(true)),
    switchMap(range =>
      this.apiary$$.pipe(
        switchMap(apiary => {
          const start_date = range.start;
          const end_date = range.end.plus({ minutes: 30 });

          return apiary.stream_weather_data$$(start_date.toJSDate(), end_date.toJSDate());
        })
      )
    ),
    switchMap(weather_data =>
      combineLatest({ elevation: this.location_elevation$$, latitude: this.location_latitude$$ }).pipe(
        map(({ elevation, latitude }) => this.apply_data_transformations(weather_data, elevation, latitude))
      )
    ),
    tap(() => this._is_loading_weather_data$$.next(false)),
    replay()
  );

  /** */
  private apply_data_transformations = (weather_data: WeatherData, elevation: number, latitude: number): WeatherData => {
    if (isEmpty(weather_data.points)) {
      return weather_data;
    }

    weather_data.points = weather_data?.points.map((point, index, self) => {
      const is_first_index = index === 0;

      // Compute pressure data
      if (!isNil(elevation) && !isNil(latitude)) {
        point.pressure.value_sea_level = !isNil(point?.pressure?.value)
          ? compute_pressure_at_sea_level(point.pressure?.value, point.temperature, elevation, latitude)
          : null;
        point.pressure.range_up = computes_barometric_pressure_from_sea_pressure(1015, point.temperature, elevation, latitude);
        point.pressure.range_down = computes_barometric_pressure_from_sea_pressure(1010, point.temperature, elevation, latitude);
        point.pressure.zone = !isNil(point?.pressure?.value)
          ? point?.pressure?.value >= point?.pressure?.range_up
            ? 'high'
            : point?.pressure?.value <= point?.pressure?.range_down
            ? 'low'
            : 'normal'
          : null;
      } else {
        point.pressure.value_sea_level = null;
        point.pressure.range_up = null;
        point.pressure.range_down = null;
        point.pressure.zone = null;
      }

      // Compute cumulative rain
      if (weather_data?.has_rain_data) {
        point.rain_cumul_day = point?.rain ?? null;

        if (!is_first_index) {
          const current_date = point.tz_date;
          const previous_date = self[index - 1].tz_date;

          if (isSameDay(current_date, previous_date)) {
            if (!isNil(point?.rain_cumul_day)) {
              point.rain_cumul_day = self[index - 1].rain_cumul_day + point.rain_cumul_day;
            }
          }
        }
      }

      return point;
    });

    return weather_data;
  };

  /**
   * @public
   * @description
   *
   *
   */
  public wind_data$$: Observable<WindDataPoint[]> = this.weather_data$$.pipe(
    map(weather_data =>
      (weather_data?.points || [])?.map(datum => {
        const wind_data: WindDataPoint = {} as any;

        wind_data.date = datum?.date;
        wind_data.tz_date = datum?.tz_date;
        wind_data.anemo_speed = datum?.anemo_speed;
        wind_data.anemo_heading = datum?.anemo_heading;

        return wind_data;
      })
    ),
    replay()
  );

  // #endregion

  // #region -> (error management)

  /** */
  private _check_has_devices_for_weather_data$$: Observable<ErrorHelperData | null> = this.apiary$$.pipe(
    switchMap(apiary =>
      apiary?.can_have_weather_data$$.pipe(
        switchMap(apiary_can_have_weather_data => {
          if (apiary_can_have_weather_data.wind || apiary_can_have_weather_data.pressure || apiary_can_have_weather_data.temperature) {
            return of(null);
          }

          return apiary.prebuilt_error_models.get_error_manage_hive_and_devices$$(
            i18n<string>('ENTITY.APIARY.ERRORS.Within the devices equipping the apiary, no one can be used to display weather data')
          );
        })
      )
    ),
    replay()
  );

  /** */
  private _check_can_add_device_from_expl$$: Observable<ErrorHelperData | null> = combineLatest({
    apiary: this.apiary$$,
    exploitation: this.apiary$$.pipe(
      switchMap(apiary => apiary.exploitation$$),
      waitForNotNilValue()
    ),
  }).pipe(
    // Check if can "read_devices"
    keepSourceIfNoError(({ apiary, exploitation }) =>
      exploitation.user_acl.check_can_read_devices$$({
        what: i18n<string>('ALL.ACE.READ_DEVICES.WHAT.assign devices to this apiary'),
      })
    ),

    //
    switchMap(data_or_error => {
      if (data_or_error instanceof ErrorHelperData) {
        return of(data_or_error);
      }

      const apiary = data_or_error?.apiary;
      const exploitation = data_or_error?.exploitation;

      return exploitation.has_devices$$.pipe(
        switchMap(exploitation__has_devices => {
          // Check if the exploitation has devices
          if (exploitation__has_devices) {
            return apiary.prebuilt_error_models.get_error_manage_hive_and_devices$$(
              i18n<string>('ENTITY.APIARY.ERRORS.No equipped devices on this apiary')
            );
          }

          return exploitation.user_acl.check_can_write_all$$({
            what: i18n<string>('ALL.ACE.WRITE_ALL.WHAT.ask for a quote'),
          });
        }),
        map(error => {
          if (!isNil(error)) {
            return error;
          }

          return exploitation;
        })
      );
    }),

    //
    switchMap(exploitation_or_error => {
      if (exploitation_or_error instanceof ErrorHelperData) {
        return of(exploitation_or_error);
      }

      return exploitation_or_error.prebuilt_error_models.get_error_ask_quote$$();
    }),
    replay()
  );

  /** */
  public error$$: Observable<ErrorHelperData | null> = this.apiary$$.pipe(
    // Check if the user can "read_measurements_data"
    keepSourceIfNoError(apiary =>
      apiary.user_acl.check_read_measurements_data$$({
        what: i18n<string>('ALL.ACE.READ_MEASUREMENTS_DATA.WHAT.view the data'),
      })
    ),

    // Check if current user can "read_devices"
    keepSourceIfNoError(apiary_or_error => {
      if (apiary_or_error instanceof ErrorHelperData) {
        return of(apiary_or_error);
      }

      return apiary_or_error.user_acl.check_read_devices$$({ what: 'ALL.ACE.READ_DEVICES.WHAT.see the equipped hives' });
    }),

    switchMap(apiary_or_error => {
      if (apiary_or_error instanceof ErrorHelperData) {
        return of(apiary_or_error);
      }

      return apiary_or_error.has_devices$$;
    }),

    switchMap(apiary__has_devices_or_error => {
      if (apiary__has_devices_or_error instanceof ErrorHelperData) {
        return of(apiary__has_devices_or_error);
      }

      if (apiary__has_devices_or_error) {
        return this._check_has_devices_for_weather_data$$;
      } else {
        return this._check_can_add_device_from_expl$$;
      }
    }),
    replay()
  );

  // #endregion

  // #region -> (loadings management)

  /** */
  private _is_loading_weather_data$$ = new BehaviorSubject(true);

  /** */
  public loadings = {
    /** */
    weather_data$$: this._is_loading_weather_data$$.asObservable(),
  };

  // #endregion
}
