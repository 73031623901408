<bg2-modal [fullscreen]="fullscreen || false" #modal>
  <bg2-modal-header>
    <div class="header">
      <span class="mdi mdi-check-all mdi-36px"></span>
      <span class="title">{{ 'VIEWS.ZOHO.DIALOGS_AND_MODALS.ZOHO_CHECK_MODAL.title' | translate }}</span>
    </div>

    <div class="action-buttons">
      <span class="mdi mdi-close-box mdi-36px" (click)="close()"></span>
    </div>
  </bg2-modal-header>

  <bg2-modal-content>
    <bg2-zoho-manager-container (refetch_zoho_data)="refetch_zoho_data.value = $event">
      <ng-container *ngIf="(check_name$$ | async) === 'zoho_account_exploitation_id'">
        <bg2-zoho-check-bind-exploitation-id
          *ngFor="let exploitation of data__check_zoho_account_exploitation_id$$ | async"
          [exploitation]="exploitation"
          [should_refetch_zoho_data]="refetch_zoho_data.value$$ | async"
        ></bg2-zoho-check-bind-exploitation-id>
      </ng-container>

      <ng-container *ngIf="(check_name$$ | async) === 'zoho_contact_user_id'">
        <bg2-zoho-check-contact-user-id
          *ngFor="let user of data__check_zoho_contact_user_id$$ | async"
          [user]="user"
          [should_refetch_zoho_data]="refetch_zoho_data.value$$ | async"
        ></bg2-zoho-check-contact-user-id>
      </ng-container>
    </bg2-zoho-manager-container>
  </bg2-modal-content>
</bg2-modal>
