import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';

import { ISchema } from 'ngx-schema-form';

import { marker as i18n } from '@biesbjerg/ngx-translate-extract-marker';

import { cloneDeep, difference, isEmpty, isNil, some } from 'lodash-es';

import {
  BehaviorSubject,
  catchError,
  combineLatest,
  distinctUntilChanged,
  finalize,
  forkJoin,
  map,
  merge,
  Observable,
  of,
  Subject,
  switchMap,
  take,
  tap,
  timeout,
  withLatestFrom,
} from 'rxjs';
import {
  anyTrue,
  catchErrorInDialog,
  defaultValue,
  distinctUntilRealChanged,
  replay,
  waitForNotNilValue,
} from '@bg2app/tools/rxjs';

import { Beeguard2Api, DeviceApi } from 'app/core';
import { DeviceSupportIssue, ListSupportResponse, SupportResponse } from 'app/core/api-swagger/device';
import { ConsoleLoggerService } from 'app/core/console-logger.service';
import { ZohoDeskApiService } from 'app/core/services/zoho/zoho-desk-api.service';

import { DRDevice } from 'app/models';

import { AbstractDialogComponent, AbstractDialogParams, DialogsService } from 'app/widgets/dialogs-modals';
import { ZohoSearchDeskSelectOptions } from 'app/widgets/event-form/zoho-search-desk/zoho-search-desk-widget.component';
import { Dictionary } from 'app/typings/core/interfaces';

export interface DeviceSupportWithDevice extends DeviceSupportIssue {
  device?: DRDevice;
}

/** */
interface SelectableDevice {
  /** */
  name: string;

  /** */
  imei: number;

  /** */
  device: DRDevice;

  /** */
  disabled: boolean;
}

/** */
interface CreateSupportRequest {
  /** */
  support: Dictionary<any>;

  /** */
  device: DRDevice;

  /** */
  reason: string;

  /** */
  state: 'pending' | 'success' | 'failed';
}

/** */
export interface DevicesSupportCreateDialogParams extends AbstractDialogParams {
  /** */
  devices: DRDevice[];

  /** */
  support_type: string;
}

@Component({
  selector: 'bg2-device-support-create-dialog',
  templateUrl: './device-support-create-dialog.component.html',
  styleUrls: ['device-support-create-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DevicesSupportsCreateDialogComponent extends AbstractDialogComponent<DevicesSupportCreateDialogParams, any> implements OnInit {
  // #region -> (dialog basics)

  private readonly LOGGER = new ConsoleLoggerService('DevicesSupportsCreateDialogComponent', false);

  constructor(
    private readonly _bg2Api: Beeguard2Api,
    private readonly _device_api: DeviceApi,
    private readonly _dialogs: DialogsService,
    private readonly _deskApi: ZohoDeskApiService
  ) {
    super();
  }

  ngOnInit(): void {
    this.devices$$
      .pipe(
        map(devices => devices.map(device => device.imei)),
        switchMap(imeis => {
          const joined_imeis = imeis.join(' ');
          const tickets_request$$ = this._deskApi.search_tickets(
            { customField1: 'cf_imeis:' + joined_imeis },
            { statusType: ['Open', 'On Hold'] }
          );

          return tickets_request$$;
        }),
        take(1)
      )
      .subscribe({
        next: possible_tickets => {
          let form_model: DeviceSupportWithDevice = {};

          if (possible_tickets?.data?.length > 0) {
            form_model.issue_id = possible_tickets?.data[0]?.id;
          }

          this._form_model$$.next(form_model);
        },
      });
  }

  /** */
  public close(value: boolean): void {
    this.complete(value);
  }

  // #endregion

  // #region -> (dialog params)

  /** */
  public support_type$$: Observable<string> = this.input_params$$.pipe(
    map(params => params.support_type),
    distinctUntilRealChanged(),
    replay()
  );

  /**
   * List of devices defined by dialog params.
   *
   * @note Do not use for operations.
   */
  public devices$$: Observable<DRDevice[]> = this.input_params$$.pipe(
    map(params => params.devices),
    replay()
  );

  // #endregion

  // #region -> (devices and supports management)

  /**
   * List of devices (with support) defined by dialog params.
   *
   * @note Do not use for operations.
   */
  public devices_supports$$ = this.devices$$.pipe(
    tap(data => this.LOGGER.debug('devices_supports$$ (0) : ', data)),
    switchMap(devices => {
      if (devices.length === 0) {
        return of([]);
      }

      const request_devices_supports$$ = devices.map(device =>
        this._device_api.fetch_device_supports$(device.imei, undefined, undefined, {
          is_open: true,
          type: this.input_params.support_type,
        })
      );

      return forkJoin(request_devices_supports$$);
    }),
    tap(data => this.LOGGER.debug('devices_supports$$ (1) : ', data)),
    map((supports_by_devices: ListSupportResponse[]) =>
      supports_by_devices.map(results => (results?.supports ?? [null])[0]).filter(support => support)
    ),
    withLatestFrom(this.devices$$),
    tap(data => this.LOGGER.debug('devices_supports$$ (2) : ', data)),
    map(([devices_supports, devices]) => {
      const dev_by_imei: { [imei: number]: DRDevice } = {};

      devices.forEach(dev => (dev_by_imei[dev.imei] = dev));

      return devices_supports.map(support => {
        const support_with_device: DeviceSupportWithDevice = cloneDeep(support) as any;

        support_with_device.device = dev_by_imei[support.device.imei];

        return support_with_device;
      });
    }),
    replay()
  );

  // #endregion

  // #region -> (form schema management)

  /** */
  private readonly DEFAULT_SCHEMA: ISchema = {
    type: 'object',
    properties: {
      issue_id: {
        type: 'string',
        widget: 'zoho-search-desk',
        label: i18n('VIEWS.DEVICES.SUPPORTS-DIALOG.Desk ticket ID'),
        options: <ZohoSearchDeskSelectOptions>{
          visible_only_for: 'superadmin',
          zoho_search_config: {
            search_in: 'tickets',
          },
        },
      },
      start_time: {
        label: i18n('VIEWS.DEVICES.SUPPORTS-DIALOG.Support start time'),
        type: 'string',
        widget: 'date-time',
        default: new Date().toISOString(),
        options: {
          event_date_path: 'date',
          pickerType: 'calendar',
        },
      },
      comment: {
        label: i18n('ALL.COMMON.Comment (visible on Zoho)'),
        type: 'string',
        widget: 'textarea',
      },
    },
    required: ['issue_id', 'start_time'],
  };

  /** */
  public schema$$ = this.devices$$.pipe(
    tap(() => this.LOGGER.debug('schema$$ (0) : ', this.DEFAULT_SCHEMA)),
    switchMap(devices => {
      const schema = cloneDeep(this.DEFAULT_SCHEMA);

      const devices_with_exploitation$$ = devices.map(device =>
        device.exploitation$$(this._bg2Api).pipe(map(exploitation => ({ device, exploitation })))
      );

      return combineLatest(devices_with_exploitation$$).pipe(
        switchMap(devices_with_exploitation => {
          const values$$ = devices_with_exploitation.map(_composite =>
            _composite.exploitation.zoho_desk_account$$.pipe(
              map(zoho_desk_account => {
                if (zoho_desk_account instanceof Error) {
                  return { device: _composite.device, exploitation: _composite.exploitation, desk_account_id: <string>null };
                }

                return { device: _composite.device, exploitation: _composite.exploitation, desk_account_id: zoho_desk_account?.id };
              })
            )
          );

          return combineLatest(values$$);
        }),
        map(devices_with_exploitation => {
          // Update issue_id schema
          (<ZohoSearchDeskSelectOptions>schema.properties.issue_id.options).zoho_search_config.tickets_config = {
            accountId: devices_with_exploitation[0].desk_account_id,
          };

          return schema;
        })
      );
    }),
    tap(schema => this.LOGGER.debug('schema$$ (1) : ', schema)),
    replay()
  );

  // #endregion

  // #region -> (form management)

  /** */
  public can_show_form$$ = this.devices_supports$$.pipe(
    map(devices_supports => devices_supports?.length === 0),
    replay()
  );

  /** */
  private _form_valid$$ = new BehaviorSubject<boolean>(false);

  /** */
  public form_valid$$ = this._form_valid$$.pipe(distinctUntilChanged(), replay());

  /** */
  public setFormValid(val: any): void {
    this._form_valid$$.next(val);
  }

  /** */
  private _form_model$$ = new BehaviorSubject<any>({});

  /** */
  public form_model$$ = this._form_model$$.asObservable();

  /** */
  public onFormModelChanged(event: any) {
    this._form_model$$.next(event);
  }

  // #endregion

  // #region -> (ticket management)

  /** */
  private selected_ticket$$ = this.form_model$$.pipe(
    map(form_model => form_model?.issue_id ?? null),
    distinctUntilRealChanged(),
    switchMap((issue_id: string) => {
      if (isNil(issue_id)) {
        return of(null);
      }

      return this._deskApi.fetch_module$('tickets', issue_id).pipe(catchError(() => of(null)));
    }),
    replay()
  );

  /** */
  public missing_devices_of_ticket$$ = this.selected_ticket$$.pipe(
    map(ticket => ticket?.cf?.cf_imeis ?? null),
    map(related_device_imeis => {
      if (isNil(related_device_imeis) || isEmpty(related_device_imeis)) {
        return [];
      }

      const imeis = related_device_imeis.split(' ');
      return imeis.map(imei => parseInt(imei, 10));
    }),
    switchMap(related_device_imeis =>
      this.devices$$.pipe(
        map(devices => devices.map(device => device.imei)),
        map(selected_device_imeis => difference(related_device_imeis, selected_device_imeis)),
        take(1)
      )
    ),
    switchMap(missing_imeis => this._device_api.requestDevices(missing_imeis)),
    replay()
  );

  // #endregion

  /**
   * Observes all devices (selected + ticket).
   */
  private _all_devices$$ = combineLatest({
    initial_devices: this.devices$$,
    missing_devices: this.missing_devices_of_ticket$$,
  }).pipe(
    map(({ initial_devices, missing_devices }) => [...(initial_devices ?? []), ...(missing_devices ?? [])]),
    replay()
  );

  // #region -> (partial RU management)

  /** */
  private _create_selectable_devices(devices: DRDevice[]): SelectableDevice[] {
    return devices.map(device => {
      const item: SelectableDevice = {
        device,
        name: device.name,
        imei: device.imei,
        disabled: (device?.supports?.open ?? [])?.filter(support => support.type === this.input_params.support_type)?.length > 0,
      };

      return item;
    });
  }

  /** */
  public readonly available_devices_for_support$$ = this._all_devices$$.pipe(
    map(devices => this._create_selectable_devices(devices)),
    replay()
  );

  /** */
  private _selected_devices_for_ru$$ = new BehaviorSubject<number[]>([]);

  /**
   * Observes list of selected devies for the new support.
   */
  public selected_devices_for_ru$$ = this.devices$$.pipe(
    map(devices => {
      const items = this._create_selectable_devices(devices);
      return items.filter(item => !item.disabled).map(item => item.imei);
    }),
    take(1),
    tap(selected_imeis => (this.selected_devices_for_ru = selected_imeis)),
    switchMap(() => this._selected_devices_for_ru$$),
    distinctUntilRealChanged(),
    replay()
  );

  /** */
  public set selected_devices_for_ru(selected_devices_to_close_ru: number[]) {
    this._selected_devices_for_ru$$.next(selected_devices_to_close_ru);
  }

  // #endregion

  /** */
  private _is_submitted_declaration$$ = new BehaviorSubject(false);

  /** */
  public is_submitted_declaration$$ = this._is_submitted_declaration$$.asObservable();

  /** */
  private _create_support_request$(device: DRDevice, support: any) {
    return this._device_api.create_device_supports$(device?.imei, support).pipe(
      map(
        () =>
          <CreateSupportRequest>{
            support,
            device: device,
            state: 'success',
            reason: null,
          }
      ),
      timeout(5000),
      catchError((error: unknown) =>
        of(<CreateSupportRequest>{
          support,
          device: device,
          state: 'failed',
          reason: (<Error>error)?.message ?? 'ALL.COMMON.Unknown',
        })
      ),
      take(1)
    );
  }

  /** */
  public submit_declaration(): void {
    this._is_submitted_declaration$$.next(true);

    combineLatest([this.form_model$$.pipe(take(1)), this.selected_devices_for_ru$$.pipe(take(1))])
      .pipe(
        take(1),
        map(([form_model, selected_imeis]) => {
          if (selected_imeis?.length === 0) {
            throw new Error('No devices selected');
          }

          form_model.devices = selected_imeis;

          // this._submit_progress_current$$.next(0);
          // this._submit_progress_total$$.next(form_model.devices.length);

          return form_model;
        }),
        switchMap(form_model =>
          this._all_devices$$.pipe(
            take(1),
            map(all_devices => all_devices.filter(device => (<number[]>form_model.devices).includes(device.imei))),
            switchMap(devices => {
              const support = {
                type: 'ru_no_sms',
                tags: ['no_sms', 'ru'],
                start_time: form_model.start_time,
                issue_id: form_model.issue_id,
              };

              this.request_states = devices.reduce((build: { [key: string]: CreateSupportRequest }, device) => {
                build[device.imei] = {
                  device,
                  support,
                  state: 'pending',
                  reason: null,
                };

                return build;
              }, {});

              const request_create_supports$$ = devices.map(device => this._create_support_request$(device, support));

              return forkJoin(request_create_supports$$).pipe(
                tap(responses => {
                  const current_states = this.request_states;

                  responses.forEach(response => {
                    current_states[response.device.imei].state = response.state;
                    current_states[response.device.imei].reason = response.reason;
                  });

                  this.request_states = current_states;
                }),
                take(1)
              );
            })
          )
        )
      )
      .subscribe({
        next: () => {},
        error: () => {},
        complete: () => {},
      });
  }

  // #region -> (summary mgmt)

  /** */
  private _request_states$$ = new BehaviorSubject<{ [key: string]: CreateSupportRequest }>(<any>{});

  /** */
  public request_states$$ = this._request_states$$.asObservable();

  /** */
  public get request_states(): { [key: string]: CreateSupportRequest } {
    return this._request_states$$.getValue();
  }

  /** */
  public set request_states(value: { [key: string]: CreateSupportRequest }) {
    this._request_states$$.next(value);
  }

  /** */
  public have_failed_requests$$ = this.request_states$$.pipe(
    map(request_states => some(request_states, request_state => request_state.state === 'failed')),
    replay()
  );

  /** */
  public have_pending_requests$$ = this.request_states$$.pipe(
    map(request_states => some(request_states, request_state => request_state.state === 'pending')),
    replay()
  );

  /** */
  public retry_failed_requests(): void {
    this.request_states$$
      .pipe(
        take(1),
        map(all_requests => {
          const failed_requests = Object.keys(all_requests).reduce((filtered: { [key: string]: CreateSupportRequest }, key) => {
            if (all_requests?.[key]?.state === 'failed') {
              filtered[key] = all_requests[key];
            }

            return filtered;
          }, {});

          return failed_requests;
        }),
        switchMap(failed_requests => {
          const obs$$ = Object.values(failed_requests).map(fr => this._create_support_request$(fr.device, fr.support));
          return forkJoin(obs$$);
        })
      )
      .subscribe({
        next: responses => {
          const current_states = this.request_states;

          responses.forEach(response => {
            current_states[response.device.imei].state = response.state;
            current_states[response.device.imei].reason = response.reason;
          });

          this.request_states = current_states;
        },
        complete: () => {},
      });
  }

  /** */
  private _is_updating_desk_ticket$$ = new BehaviorSubject(false);

  /** */
  public is_updating_desk_ticket$$ = this._is_updating_desk_ticket$$.asObservable();

  /** */
  public submit_to_desk_and_quit(): void {
    this._is_updating_desk_ticket$$.next(true);

    this.form_model$$
      .pipe(
        take(1),
        switchMap(form_model =>
          this._all_devices$$.pipe(
            take(1),
            map(all_devices => all_devices.filter(device => form_model.devices.includes(device.imei))),
            switchMap(devices =>
              this._bg2Api.zohoApis.desk_api.update_ticket_with_support$(form_model.issue_id, {
                type: 'new',
                selected_devices: devices,
                ru_in_date: form_model.start_time,
                comment: form_model?.comment,
              })
            )
          )
        ),
        catchErrorInDialog(this._dialogs)
      )
      .subscribe({
        next: () => {
          this._is_updating_desk_ticket$$.next(false);
        },
        complete: () => this.close(true),
      });
  }

  // #endregion

  public asType(value: any, type: 'device'): DRDevice;
  public asType(value: any, type: 'device'): any {
    if (value instanceof DRDevice) {
      return value;
    }

    return null;
  }
}
