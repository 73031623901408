// From @node_modules/@angular/*
import { Injectable, OnDestroy } from '@angular/core';

// From @node_modules/rxjs/*
import { Subscription, Observable, combineLatest, of, BehaviorSubject, concat, merge, interval, Subject } from 'rxjs';
import { map, distinctUntilChanged, tap, switchMap, debounce, debounceTime } from 'rxjs';

// From @node_modules/lodash/*
import { concat as _concat, sum, flatten, isNil, keys } from 'lodash-es';

// From @app/core/api/*
import { Beeguard2Api } from '../api/main/beeguard2-api-service';

// From @app/models/*
import { Apiary, Location, Exploitation } from 'app/models';

import { AppStateService } from '../app-state.service';
import { ConsoleLoggerService } from '../console-logger.service';
import { GeocodingService } from 'app/misc/services/geocoding.service';
import { distinctUntilRealChanged, replay, robustCombineLatest } from '@bg2app/tools/rxjs';

import { Dictionary } from 'app/typings/core/interfaces';
import { GhostRunState } from './models/ghost-runner';
import { DevicesFromSameLocation, GhostSolutionAlternatives } from './models/ghost-solution';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable()
export class GhostService implements OnDestroy {
  protected _logger = new ConsoleLoggerService(this.constructor.name, true);

  // Subscriptions
  private _build_ghost_sub: Subscription;

  private _solutions_by_entity_id: { [entity_: number]: GhostSolutionAlternatives[] } = {};
  public _solutions_by_locations: { [location_id: number]: BehaviorSubject<GhostSolutionAlternatives[]> } = {};

  // All exploitations loading status
  private _load_ghost$$: BehaviorSubject<boolean> = new BehaviorSubject(JSON.parse(localStorage.getItem('config.load_ghosts') || 'false'));
  public load_ghost$$ = this._load_ghost$$.asObservable().pipe(distinctUntilChanged(), replay());

  public toggleGhost() {
    this._load_ghost$$.next(!this._load_ghost$$.getValue());
    localStorage.setItem('config.load_ghosts', JSON.stringify(this._load_ghost$$.getValue()));
  }

  private _changes_lock = false;
  private _debounce_changes: Subject<number> = new Subject();

  private getStateDebounceAndLock(): Observable<number> {
    // this._logger.debug('getStateDebounceAndLock()', this._changes_lock)
    if (!this._changes_lock) {
      return interval(50);
    } else {
      return this._debounce_changes.asObservable().pipe(
        debounceTime(400) // wait after unlock to real unlock
      );
    }
  }

  private lockGhostChanges() {
    this._changes_lock = true;
  }

  private unlockGhostChanges() {
    this._changes_lock = false;
    this._debounce_changes.next(1);
  }

  private _registerSolutions(entity_id: number, solutions: GhostSolutionAlternatives[]) {
    this._solutions_by_entity_id[entity_id] = solutions;
  }

  public validate(solutions: GhostSolutionAlternatives[]): Observable<any> {
    return of(solutions).pipe(
      tap(() => this.lockGhostChanges()),
      switchMap(_solutions => {
        let validate_all_solutions$$ = of(null);
        _solutions.forEach(solution_alt => {
          validate_all_solutions$$ = validate_all_solutions$$.pipe(switchMap(() => solution_alt._validateAll()));
        });
        return validate_all_solutions$$;
      }),
      tap(() => this.unlockGhostChanges())
    );
  }

  private _popSolutions(entity_id: number): GhostSolutionAlternatives[] {
    const solutions = this._solutions_by_entity_id[entity_id];
    this._solutions_by_entity_id[entity_id] = null;
    return solutions || [];
  }

  public getSolutions(entity_id: number): GhostSolutionAlternatives[] {
    return this._solutions_by_entity_id[entity_id] || [];
  }

  private _getSolutionsFromOneLocation$$(location_id: number) {
    if (isNil(this._solutions_by_locations[location_id])) {
      this._solutions_by_locations[location_id] = new BehaviorSubject([]);
    }
    return this._solutions_by_locations[location_id];
  }

  private newSolutionsForOneLocation(location_id: number, solutions: GhostSolutionAlternatives[]) {
    const sol$$ = this._getSolutionsFromOneLocation$$(location_id);
    sol$$.next(solutions);
  }

  public streamSolutionsFromLocation(location_id: number): Observable<GhostSolutionAlternatives[]> {
    return this._getSolutionsFromOneLocation$$(location_id).asObservable();
  }

  /**
   * Build ghost hives of an apiary
   *
   * @returns obs of apiary's ghost hives
   */
  private _resolveApiaryCompatibleDevices(
    apiary: Apiary,
    apiary_lvl_solution: GhostSolutionAlternatives
  ): Observable<GhostSolutionAlternatives[]> {
    if (apiary) {
      // let apiary_previous_solutions: GhostSolutionAlternatives[] = [];
      this._logger.debug('Resolve missing hives for apiary', apiary?.id);
      const remaining_devices$$ = apiary_lvl_solution
        ? apiary_lvl_solution.remaining_devices_by_previous_location$$
        : apiary.compatible_devices_by_previous_location$$;
      return remaining_devices$$.pipe(
        tap(devices_by_previous_location => this._logger.debug('devices_by_previous_location', apiary?.id, devices_by_previous_location)),
        switchMap(devices_by_previous_location => apiary.buildSolutionForPossibleDevices(devices_by_previous_location)),
        debounce(() => this.getStateDebounceAndLock()),
        switchMap(solutions_for_compatible_devices => {
          if (solutions_for_compatible_devices.length > 0) {
            this._logger.ghost('Apply solutions for apiary', apiary.desc);
          }
          if (this._changes_lock) {
            throw Error('One should not apply locally when _changes_lock is active');
          }
          this._registerSolutions(apiary.id, solutions_for_compatible_devices);
          return robustCombineLatest(solutions_for_compatible_devices.map(solution => solution.applyLocally(this.bg2Api)));
        })
      );
    } else {
      return of(null);
    }
  }

  private _resolveMissingApiary(
    location: Location,
    remaining_devices$$: Observable<DevicesFromSameLocation[]>
  ): Observable<GhostSolutionAlternatives> {
    return location.buildSolutionsForMissingApiary$$(remaining_devices$$).pipe(
      debounce(() => this.getStateDebounceAndLock()),
      switchMap(solutions_for_missing_apiary => {
        if (solutions_for_missing_apiary) {
          this._logger.ghost('Apply missing apiary solutions for location', location.desc);
          if (this._changes_lock) {
            throw Error('One should not apply locally when _changes_lock is active');
          }
          this._registerSolutions(location.id, [solutions_for_missing_apiary]);
          return solutions_for_missing_apiary.applyLocally(this.bg2Api);
        } else {
          this._logger.ghost('No more missing apiary solutions for location', location.desc);
          this._registerSolutions(location.id, []);
          return of(solutions_for_missing_apiary);
        }
      })
    );
  }

  /**
   * Build potential ghost apiary and hives for given location
   *
   * @returns Obseravle of built ghost entities
   */
  private _resolveAllForOneLocation(
    location: Location,
    location_creation_solution: GhostSolutionAlternatives
  ): Observable<GhostSolutionAlternatives[]> {
    this._logger.ghost('Resolve all for location', location?.desc);
    const remaining_devices$$ = location_creation_solution
      ? location_creation_solution.remaining_devices_by_previous_location$$
      : location.compatible_devices_by_previous_location$$;
    return this._resolveMissingApiary(location, remaining_devices$$).pipe(
      tap(apiary_lvl_solution =>
        this._logger.debug(`Got ${apiary_lvl_solution?.desc} solution for compatible dev location`, location?.desc)
      ),
      switchMap(apiary_lvl_solution =>
        location.apiary$$.pipe(map(apiary => [apiary_lvl_solution, apiary] as [GhostSolutionAlternatives, Apiary]))
      ),
      //distinctUntilChanged(([apiary_lvl_solution, apiary], [prev_apiary_lvl_solution, prev_apiary]) => prev_apiary?.id === apiary?.id),
      tap(([apiary_lvl_solution, apiary]) =>
        this._logger.debug(`new apiary ${apiary?.desc}, new solution ${apiary_lvl_solution?.desc}`, location?.desc)
      ),
      // ^ not this is important to avoid recompute solution for the same apiary
      switchMap(([apiary_lvl_solution, apiary]) =>
        this._resolveApiaryCompatibleDevices(apiary, apiary_lvl_solution).pipe(
          tap(hive_lvl_solutions =>
            this._logger.debug('Got missing hives solutions for apiary', apiary?.desc, location?.desc, hive_lvl_solutions)
          ),
          map(hive_lvl_solutions => {
            if (apiary_lvl_solution && apiary_lvl_solution.hasSolution()) {
              apiary_lvl_solution.registerSubSolutions(hive_lvl_solutions);
              return [apiary_lvl_solution];
            } else {
              return hive_lvl_solutions;
            }
          })
        )
      ),
      map(location_solutions => {
        if (!isNil(location_creation_solution)) {
          location_creation_solution.registerSubSolutions(location_solutions);
          return [location_creation_solution];
        } else {
          return location_solutions;
        }
      }),
      tap(solution => this.newSolutionsForOneLocation(location.id, solution))
    );
  }

  private _resolveMissingLocations(exploitation: Exploitation): Observable<GhostSolutionAlternatives[]> {
    this._logger.ghost('_resolveMissingLocations(', exploitation.id);
    return exploitation.buildSolutionForNewPossibleLocations$$(this.appState.lang, this.geocoding).pipe(
      debounce(() => this.getStateDebounceAndLock()),
      switchMap(solutions_for_new_locations => {
        if (this._changes_lock) {
          throw Error('One should not apply locally when _changes_lock is active');
        }
        this._logger.ghost('Apply solutions for missing location for expl', exploitation.desc);
        this._registerSolutions(exploitation.id, solutions_for_new_locations);
        return robustCombineLatest(solutions_for_new_locations.map(solutions => solutions.applyLocally(this.bg2Api)));
      })
    );
  }

  private _resolveAllForOneExploitation(exploitation: Exploitation): Observable<GhostRunState> {
    // Build ghost locations
    // For each location (ghost or not) build ghosts sub-entities
    this._logger.ghost('Build solution for expl', exploitation.id);

    let seen_existing_locations: Dictionary<boolean> = {};
    let seen_new_locations: Dictionary<boolean> = {};
    let total_existing = 0;
    let total_new = 0;
    const solutions_existing_locations$$ = exploitation.named_locations$$.pipe(
      switchMap(locations => {
        seen_existing_locations = {};
        total_existing = locations.length;
        const build_ghost_for_locations$$ = locations.map(loc =>
          this._resolveAllForOneLocation(loc, null).pipe(
            tap(solutions => this._logger.ghost('Got solutions for loc', loc.desc, solutions)),
            tap(() => (seen_existing_locations[loc.id] = true))
          )
        );
        if (build_ghost_for_locations$$.length) {
          return merge(...build_ghost_for_locations$$);
        } else {
          return of([]);
        }
      })
    );
    const solutions_new_locations$$ = this._resolveMissingLocations(exploitation).pipe(
      switchMap(location_creation_solutions => {
        seen_new_locations = {};
        total_new = location_creation_solutions.length;
        const build_ghost_for_locations$$ = location_creation_solutions.map(sol_alt => {
          const loc = sol_alt.getSelectedSolution().getGhostEntity(0) as Location;
          return this._resolveAllForOneLocation(loc, sol_alt).pipe(
            tap(solutions => this._logger.ghost('Got solutions for loc', loc.desc, solutions)),
            tap(() => (seen_new_locations[loc.id] = true))
          );
        });
        if (build_ghost_for_locations$$.length) {
          return merge(...build_ghost_for_locations$$);
        } else {
          return of([]);
        }
      })
    );

    return merge(solutions_existing_locations$$, solutions_new_locations$$).pipe(
      map(solutions => {
        const total = total_existing + total_new;
        const seen = keys(seen_existing_locations).length + keys(seen_new_locations).length;
        const remains = total - seen;
        return new GhostRunState(total, remains, solutions);
      })
    );
  }

  private _resolveAllForOneExploitationWithInitialProgress(exploitation: Exploitation): Observable<GhostRunState> {
    return concat(of(new GhostRunState(1, null, [])), this._resolveAllForOneExploitation(exploitation));
  }

  private resolveAllForExploitations(exploitations: Exploitation[]): Observable<GhostRunState> {
    return robustCombineLatest(exploitations.map(expl => this._resolveAllForOneExploitationWithInitialProgress(expl))).pipe(
      map(all_progress => {
        const global_total = sum(all_progress.map(progress => progress.nb_total));
        const global_remains = sum(all_progress.map(progress => progress.nb_remains));
        const _solutions = all_progress.map(progress => progress.solutions);
        const solutions = flatten(_solutions);
        // this._logger.ghost(solutions);
        return new GhostRunState(global_total, global_remains, solutions);
      })
    );
  }
  // #endregion

  ngOnDestroy(): void {
    this._build_ghost_sub?.unsubscribe();
  }

  private _ghost_runs$$ = new BehaviorSubject<GhostRunState>(null);
  public ghost_run_state$$: Observable<GhostRunState[]> = this._ghost_runs$$.asObservable().pipe(
    map(run_state => {
      if (isNil(run_state) || run_state?.nb_remains === 0) {
        return null;
      } else {
        return run_state;
      }
    }),
    map(run_state => (!isNil(run_state) ? [run_state] : null)),
    replay()
  );

  public is_computing$$ = this.ghost_run_state$$.pipe(
    map(states => (states || [])?.length > 0),
    distinctUntilRealChanged(),
    replay()
  );

  constructor(public appState: AppStateService, public bg2Api: Beeguard2Api, private geocoding: GeocodingService) {
    // this._build_ghost_sub = combineLatest([this.load_ghost$$, this.appState.selected_exploitations$$])
    //   .pipe(
    //     switchMap(([load_ghost, selected_exploitations]) => {
    //       this._logger.ghost(`Loads ghost ? ${load_ghost} for [${selected_exploitations.map(e => '#' + e.id).join(', ')}]`);

    //       return of(null);

    //       // if (load_ghost) {
    //       //   return this.resolveAllForExploitations(selected_exploitations);
    //       // } else {
    //       //   return of(null);
    //       // }
    //     })
    //     // tap(val => this._logger.ghost('Updated', val)),
    //   )
    //   .subscribe({
    //     next: val => this._ghost_runs$$.next(val),
    //     error: (error: unknown) => {
    //       if (error instanceof Error) {
    //         this._logger.error(`[${error?.name}] ${error?.message}`);
    //       } else if (error instanceof HttpErrorResponse) {
    //         this._logger.error(`[${error?.status}] ${error?.statusText}`);
    //       } else {
    //         this._logger.error(error);
    //       }
    //     },
    //     complete: () => {},
    //   });
  }
}
