import { isEmpty, isNil, orderBy, sortBy } from 'lodash-es';

import { isObservable, Observable, switchMap, throwError } from 'rxjs';
import { robustCombineLatest } from '@bg2app/tools/rxjs';

import { DEVICE_SIMPLIFIED_BATTERY_STATE, DeviceSimplifiedBatteryState, DRDevice } from 'app/models';
import { DeviceStatus } from '../DRDevice';

/**
 * Creates an observable on devices statuses.
 *
 * The `extract_device_statuses$$` method allows to create an observable of device's statuses for
 * a specific list of devices.
 *
 * @param devices
 * @param device_status_obs
 *
 * @returns
 */
export const extract_device_statuses$$ = <T extends DeviceStatus>(
  devices: DRDevice[],
  device_status_obs: keyof DRDevice
): Observable<T[]> => {
  const array_of_statuses$$ = devices.map(device => {
    const value$$ = device.last_contact$$.pipe(
      switchMap(() => {
        const prop_value: Observable<T> = device[device_status_obs];

        if (!isObservable(prop_value)) {
          return throwError(() => new Error(`device[${device_status_obs}] must be an observable`));
        }

        return prop_value;
      })
    );

    return value$$;
  });

  return robustCombineLatest(array_of_statuses$$);
};

/**
 * Search the worst device's status in the list.
 *
 * The `find_worst_status_in` method searches the worst device's status in the list. It takes
 * an argument named `spec` to use custom sorting in case of GPS.
 *
 * @param statuses
 * @param spec
 *
 * @returns
 */
export const find_worst_device_status_in = <T extends DeviceStatus>(statuses: T[], spec: 'gps' = null): T => {
  const filtered_statuses = statuses?.filter(status => !isNil(status));

  switch (spec) {
    case 'gps': {
      return orderBy<T>(filtered_statuses, ['value'], ['desc'])[0];
    }

    default: {
      return orderBy<T>(filtered_statuses, ['outdated', 'value'], ['desc', 'asc'])[0];
    }
  }
};

export const find_worst_device_status_simplified_in = (statuses: DeviceSimplifiedBatteryState[]): DeviceSimplifiedBatteryState['state'] => {
  const status_order: DEVICE_SIMPLIFIED_BATTERY_STATE[] = [
    DEVICE_SIMPLIFIED_BATTERY_STATE.NOT_OK,
    DEVICE_SIMPLIFIED_BATTERY_STATE.OUTDATED,
    DEVICE_SIMPLIFIED_BATTERY_STATE.OK,
    DEVICE_SIMPLIFIED_BATTERY_STATE.UNKNOWN,
  ];

  if (isEmpty(statuses ?? [])) {
    return DEVICE_SIMPLIFIED_BATTERY_STATE.UNKNOWN;
  }

  const sorted_statuses = statuses.sort((a: DeviceSimplifiedBatteryState, b: DeviceSimplifiedBatteryState) => {
    const statusA = status_order.indexOf(a.state);
    const statusB = status_order.indexOf(b.state);

    return statusA - statusB;
  });

  const worst_state = sorted_statuses?.[0]?.state;
  return worst_state;
};
