<div *ngIf="is_active$$ | async" class="locations-wizard-ghost-component" [class.beta]="true">
  <bg2-ia-single-dialog>
    <ng-container *ngIf="(config_has_been_modified$$ | async) === false">
      <p [innerHtml]="'VIEWS.GHOST.SHARED.LOCATIONS_GHOST_WIZARD.Paragragh 1' | translate | markdown"></p>

      <br />

      <p [innerHtml]="'VIEWS.GHOST.SHARED.LOCATIONS_GHOST_WIZARD.Paragragh 2' | translate | markdown"></p>
    </ng-container>

    <ng-container *ngIf="(config_has_been_modified$$ | async) === true">
      <p>{{ 'VIEWS.GHOST.SHARED.LOCATIONS_GHOST_WIZARD.Paragragh 3' | translate }}</p>
      <p [innerHtml]="'VIEWS.GHOST.SHARED.LOCATIONS_GHOST_WIZARD.Paragragh 4' | translate | markdown"></p>

      <br />

      <p [innerHtml]="'VIEWS.GHOST.SHARED.LOCATIONS_GHOST_WIZARD.Paragragh 5' | translate | markdown"></p>
    </ng-container>
  </bg2-ia-single-dialog>

  <div class="compatible-devices">
    <ng-container *ngFor="let device of devices$$ | async">
      <div class="device" [popper]="deviceTooltip" [popperTimeout]="100">
        <span>
          {{ device.name }}
        </span>
      </div>

      <popper-content #deviceTooltip>
        <div style="display: flex; flex-flow: column nowrap;">
          <bg2-last-com [device]="device"></bg2-last-com>
          <span>
            <span class="mdi mdi-eye"></span>
            <a [routerLink]="['', { outlets: { modal: ['device', { imei: device.imei }] } }]" [queryParamsHandling]="'preserve'">
              {{ 'DEVICE.ALL.ACTION.See detail of this device' | translate }}
            </a>
          </span>
        </div>
      </popper-content>
    </ng-container>
  </div>

  <div class="actions">
    <div>
      <bg2-button
        style="flex: 1 1 0%"
        [type]="'action'"
        [color]="'tonal'"
        [icon]="'mdi-pencil'"
        [message]="'VIEWS.GHOST.SHARED.LOCATIONS_GHOST_WIZARD.I want to personnalize this installation' | translate"
        (btn_click)="modifyInstallation()"
      ></bg2-button>

      <bg2-button
        style="flex: 1 1 0%"
        [type]="'action'"
        [icon]="'mdi-content-save'"
        [message]="'VIEWS.GHOST.SHARED.LOCATIONS_GHOST_WIZARD.I confirm this installation' | translate"
        (btn_click)="confirmInstallation()"
      ></bg2-button>
    </div>
  </div>
</div>
