import { AfterViewInit, ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';

import { merge } from 'lodash-es';

import { Beeguard2Api } from 'app/core';
import { AppStateService } from 'app/core/app-state.service';
import { DialogsService } from 'app/widgets/dialogs-modals/dialogs.service';

import { BgControlWidgetComponent, WidgetOptions } from '../control/control.widget';
import { Subscription } from 'rxjs';

/** */
interface Bg2EntityExploitationFormWidgetOptions extends WidgetOptions {}

@Component({
  selector: 'bg2-entity-exploitation-form-widget',
  templateUrl: './bg2-entity-exploitation.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class Bg2EntityExploitationFormWidgetComponent extends BgControlWidgetComponent implements OnInit, AfterViewInit, OnDestroy {
  // #region -> (component basics)

  /** */
  private _internal_form_update_sub: Subscription = null;

  constructor(private _bg2Api: Beeguard2Api, private _dialogs: DialogsService, private _appState: AppStateService) {
    super(_bg2Api, _appState, _dialogs);
  }

  ngOnInit(): void {
    super.ngOnInit();

    this._internal_form_update_sub = this._appState.selected_exploitations_ids$$.subscribe({
      next: selected_exploitation_ids => {
        this.formProperty.setValue(selected_exploitation_ids, false);
      },
    });
  }

  ngAfterViewInit(): void {
    super.ngAfterViewInit();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();

    this._internal_form_update_sub?.unsubscribe();
  }

  // #endregion

  // #region -> (widget options)

  /**
   * Re-define options for this widget component
   */
  public options: Bg2EntityExploitationFormWidgetOptions = merge({}, <Bg2EntityExploitationFormWidgetOptions>{}, super.options);

  // #endregion
}
