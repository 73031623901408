<div *ngLet="which_event_is_displayed.value$$ | async; let displayed_events" class="bg2-device-history-component">
  <div class="events-container--title">{{ 'VIEWS.DEVICES.SHARED.DEVICE_HISTORY.Device history' | translate }}</div>

  <div class="device-history-filters">
    <mat-checkbox
      *ngIf="displayed_events?.includes('*') || displayed_events?.includes('tickets')"
      [color]="'primary'"
      [ngModel]="show_off_topics_tickets$$ | async"
      (ngModelChange)="show_off_topics_tickets = $event"
    >
      <span>
        {{ 'MODELS.ZOHO.SEARCH.Include global search' | translate }}
        <span style="color: red">({{ 'MODELS.ZOHO.DESK.TICKET.Risk of displaying off-topic tickets' | translate }})</span>
      </span>
    </mat-checkbox>

    <mat-checkbox
      *ngIf="displayed_events?.includes('*') || displayed_events?.includes('affectations')"
      [color]="'primary'"
      [ngModel]="show_only_affectated_exploitations.value$$ | async"
      (ngModelChange)="show_only_affectated_exploitations.value = $event"
    >
      <span>
        {{ 'VIEWS.DEVICES.SHARED.DEVICE_HISTORY.FILTERS.Show only affections to an exploitation' | translate }}
      </span>
    </mat-checkbox>
  </div>

  <div #eventsContainer *ngLet="device_history$$ | async; let device_history" class="events-container">
    <bg2-loading-skeleton *ngIf="is_loading$$ | async" [theme]="{ height: '100%' }"></bg2-loading-skeleton>

    <ng-container *ngIf="displayed_events?.includes('tickets')">
      <bg2-ui-message
        *ngIf="(appState.is_superadmin$$ | async) && (is_connected_to_zoho$$ | async) === false"
        [type]="'warning'"
        [title]="'MODELS.ZOHO.AUTH.Not connected' | translate"
      >
        <div message>
          <span>{{ 'MODELS.ZOHO.DESK.TICKET.To view DESK ticket history, you must be logged to Zoho' | translate }}</span>
        </div>
      </bg2-ui-message>
    </ng-container>

    <bg2-error-helper
      *ngIf="(has_events$$ | async) === false"
      [error]="{
        name: null,
        message: null,
        description: [
          {
            type: 'span',
            content:
              'VIEWS.DEVICES.SHARED.DEVICE_HISTORY.ERROR.There have been no events for [total]'
              | translate : { total: loaded_events_until_date$$ | async | distanceToNow }
          }
        ]
      }"
    ></bg2-error-helper>

    <ng-container *ngFor="let year_or_month_or_event of device_history; let index = index">
      <!-- Display year -->
      <ng-container *ngIf="year_or_month_or_event.type === 'year'">
        <span class="date-split --year">{{ year_or_month_or_event?.value }}</span>
      </ng-container>

      <!-- Display month -->
      <ng-container *ngIf="year_or_month_or_event.type === 'month'">
        <span *ngLet="as_type(device_history?.[index + 1]?.value, 'abstract'); let ticket_event" class="date-split --month">
          <span>{{ ticket_event?.date$$ | async | formatDate : 'MMMM yyyy' }}</span>
        </span>
      </ng-container>

      <!-- Display event -->
      <ng-container *ngIf="year_or_month_or_event.type === 'event'">
        <ng-container *ngIf="year_or_month_or_event?.value; let event">
          <div class="event-container">
            <span class="event-container__date">{{ event.date$$ | async | formatDate : 'full' }}</span>

            <ng-container *ngIf="event?.use_event_span; else defaultEventSpan">
              <bg2-event-link [show_date]="false" [event]="$any(event).event$$ | async"></bg2-event-link>
            </ng-container>

            <ng-template #defaultEventSpan>
              <span
                class="event-container__label"
                [innerHTML]="event.get_event_label$$ | async | translate : (event.get_event_label_params$$ | async)"
              >
              </span>
            </ng-template>

            <!-- Render ack config data -->
            <ng-container *ngIf="as_type(event, 'ack-conf'); let ack_conf_event">
              <ng-container *ngIf="ack_conf_event?.ack_config$$ | async; let ack_config">
                <span>
                  <span>(</span>
                  <span class="simili-link" (click)="show_ack_conf_in_dialog(ack_config)">
                    {{ 'DEVICE.ALL.CONFIGURATION.View configuration' | translate }}
                  </span>
                  <span>)</span>
                </span>
              </ng-container>
            </ng-container>

            <!-- Render ticket data -->
            <ng-container *ngIf="as_type(event, 'ticket'); let ticket_event">
              <ng-container *ngIf="ticket_event?.ticket$$ | async; let ticket">
                <span *ngIf="ticket?.maybe_off_topic$$ | async">
                  <span
                    class="mdi mdi-file-question"
                    style="color: red"
                    [popper]="'MODELS.ZOHO.DESK.TICKET.May be off-topic' | translate"
                  ></span>
                </span>
              </ng-container>
            </ng-container>
          </div>
        </ng-container>
      </ng-container>
    </ng-container>
  </div>

  <div class="end-of-events-container">
    <div class="end-of-events">
      <span>{{ '---' }}&nbsp;</span>

      <ng-container *ngIf="loaded_events_until_date$$ | async; let date">
        <span>
          {{ 'VIEWS.DEVICES.SHARED.DEVICE_HISTORY.Events loaded until [date]' | translate : { date: date | formatDate : 'full' } }}
        </span>

        <span>({{ date | distanceToNow : false : true }})</span>
      </ng-container>

      <span>&nbsp;{{ '---' }}</span>
    </div>

    <bg2-button
      [type]="'action'"
      [icon]="'mdi-calendar-search'"
      [disabled]="is_loading$$ | async"
      [message]="('VIEWS.DEVICES.SHARED.DEVICE_HISTORY.Load more events' | translate) + '...'"
      (btn_click)="load_new_event_range()"
    ></bg2-button>
  </div>
</div>
