<bg2-modal [fullscreen]="fullscreen || false" #modal style="height: 100%">
  <bg2-modal-header class="--device-type-{{ device_type$$ | async }}">
    <div class="header">
      <span class="title">
        <ng-container *ngIf="(is_loading_device?.value$$ | async) === false; else loadingModalTitleName">
          {{ 'DEVICE.ALL.COMMON.Device "[name]"' | translate:{ name: device_name$$ | async } }}
        </ng-container>

        <ng-template #loadingModalTitleName>
          <bg2-loading-skeleton [string_model]="'device \'dvc_name\''" [theme]="{ height: '46px' }"></bg2-loading-skeleton>
        </ng-template>
      </span>
    </div>

    <div class="action-buttons">
      <span class="mdi mdi-close-box mdi-36px" (click)="close()"></span>
    </div>
  </bg2-modal-header>

  <bg2-modal-content>
    <!-- <pre>actual : {{ (device$$ | async)?.user_acl?.acl$$ | async | json }}</pre>
    <pre>missing : {{ (device$$ | async)?.user_acl?.missing_acl$$ | async | json }}</pre> -->

    <div class="device-modal-inner-navigation">
      <div class="device-modal-inner-navigation__level" (click)="full_url = DEVICE_DETAIL_NAME.HOME">
        <span>{{ device_name$$ | async }}</span>
      </div>

      <span class="mdi mdi-chevron-right"></span>

      <div
        *ngLet="detail_url$$ | async; let detail_url"
        class="device-modal-inner-navigation__level"
        [mat-menu-trigger-for]="secondLevelMenu"
      >
        <span *ngIf="!(detail_url?.[0])">.........</span>
        <span *ngIf="detail_url?.[0]">{{ NAVIGATION_MENUS[detail_url?.[0]].label | translate }}</span>

        <span class="mdi mdi-menu-down"></span>
      </div>

      <mat-menu #secondLevelMenu="matMenu" class="device-modal__inner-navigation__menu">
        <ng-container *ngFor="let menu of NAVIGATION_MENUS | keyvalue : originalOrder">
          <button mat-menu-item (click)="full_url = menu.value.url">
            <div class="mat-menu-item__container">
              <span class="mdi {{ menu.value.icon }} mdi-24px"></span>

              <div class="mat-menu-item__container__content">
                <span>{{ menu.value.label | translate }}</span>
              </div>
            </div>
          </button>
        </ng-container>
      </mat-menu>

      <!-- <span class="mdi mdi-chevron-right"></span>

      <div *ngLet="detail_url$$ | async; let detail_url" class="device-modal-inner-navigation__level">
        <span *ngIf="!(detail_url?.[1])">select category</span>
        <span *ngIf="detail_url?.[1]">{{ detail_url?.[1] }}</span>
        <span class="mdi mdi-menu-down"></span>
      </div> -->
    </div>

    <div *ngIf="(is_summary_view$$ | async) === false" class="device-selected-detail__back">
      <bg2-button
        [type]="'navigation'"
        [icon]="'mdi-arrow-left'"
        [message]="'VIEWS.DEVICES.DIALOGS_AND_MODALS.DEVICE_MODAL.DETAILS.MISC.Back to summary' | translate"
        [options]="{ is_thin: true }"
        (btn_click)="full_url = DEVICE_DETAIL_NAME.HOME"
      ></bg2-button>
    </div>

    <div *ngLet="(context$$ | async); let context" class="device-categories" [ngClass]="{ '--is-details': !context?.is_summary_view }">
      <!-- Device identification -->
      <bg2-device-modal-identification
        *ngIf="context?.is_summary_view || (!context?.is_summary_view && context?.detail_url?.[0] === DEVICE_DETAIL_NAME.IDENTIFICATION)"
        class="device-details__device-property-summary"
        [device]="device$$ | async"
        [view]="context?.is_summary_view ? 'summary' : 'details'"
        (after_delete)="close()"
        (open_detail)="full_url = DEVICE_DETAIL_NAME.IDENTIFICATION"
      ></bg2-device-modal-identification>

      <!-- Location -->
      <bg2-device-modal-geoposition
        *ngIf="context?.is_summary_view || (!context?.is_summary_view && context?.detail_url?.[0] === DEVICE_DETAIL_NAME.GEOLOCATION)"
        class="device-details__device-property-summary"
        [device]="device$$ | async"
        [view]="context?.is_summary_view ? 'summary' : 'details'"
        (open_detail)="full_url = DEVICE_DETAIL_NAME.GEOLOCATION"
      ></bg2-device-modal-geoposition>

      <!-- Status and battery -->
      <bg2-device-modal-diagnostic
        *ngIf="context?.is_summary_view || (!context?.is_summary_view && context?.detail_url?.[0] === DEVICE_DETAIL_NAME.STATUS_AND_BATTERY)"
        class="device-details__device-property-summary"
        [device]="device$$ | async"
        [view]="context?.is_summary_view ? 'summary' : 'details'"
        (open_detail)="full_url = DEVICE_DETAIL_NAME.STATUS_AND_BATTERY"
      ></bg2-device-modal-diagnostic>

      <!-- Data -->
      <bg2-device-modal-data
        *ngIf="context?.is_summary_view || (!context?.is_summary_view && context?.detail_url?.[0] === DEVICE_DETAIL_NAME.DATA)"
        class="device-details__device-property-summary"
        [device]="device$$ | async"
        [view]="context?.is_summary_view ? 'summary' : 'details'"
        (open_detail)="full_url = DEVICE_DETAIL_NAME.DATA"
      ></bg2-device-modal-data>

      <!-- Configuration -->
      <bg2-device-modal-configuration
        *ngIf="context?.is_summary_view || (!context?.is_summary_view && context?.detail_url?.[0] === DEVICE_DETAIL_NAME.CONFIGURATION)"
        class="device-details__device-property-summary"
        [device]="device$$ | async"
        [view]="context?.is_summary_view ? 'summary' : 'details'"
        (open_detail)="full_url = DEVICE_DETAIL_NAME.CONFIGURATION"
      ></bg2-device-modal-configuration>

      <!-- Affectation -->
      <bg2-device-modal-affectation
        *ngIf="context?.is_summary_view || (!context?.is_summary_view && context?.detail_url?.[0] === DEVICE_DETAIL_NAME.AFFECTATION)"
        class="device-details__device-property-summary"
        [device]="device$$ | async"
        [view]="context?.is_summary_view ? 'summary' : 'details'"
        (open_detail)="full_url = DEVICE_DETAIL_NAME.AFFECTATION"
      ></bg2-device-modal-affectation>
    </div>
  </bg2-modal-content>
</bg2-modal>
