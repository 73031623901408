import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { SchemaFormModule } from 'ngx-schema-form';

import { MiscModule } from 'app/misc/misc.module';
import { SharedModule } from 'app/shared-module/shared.module';
import { MaterialSharedModule } from 'app/shared-module/material-shared.module';
import { MiscWidgetsModule } from 'app/widgets/misc-widgets/misc-widgets.module';
import { DevicesSharedModule } from 'app/views/devices/shared/devices-shared.module';
import { DialogsModalsModule } from 'app/widgets/dialogs-modals/dialogs-modals.module';
import { WidgetsReusableModule } from 'app/widgets/widgets-reusables/widgets-reusables.module';

import { UserSettingsModal } from './user-settings/user-settings.modal';
import { USAboutComponent } from './user-settings/about/about.component';
import { DebuggingSettingsComponent } from './user-settings/debugging/debugging.component';
import { GlobalSettingsComponent } from './user-settings/global-settings/global-settings.component';
import { WarehouseViewSettingsComponent } from './user-settings/warehouse-view-settings/warehouse-view-settings.component';
import { NotificationsSettingsComponent } from './user-settings/notifications-settings/notifications-settings.component';

import { UserAccountModalComponent } from './user-account/user-account-modal.component';
import { UAMUserDetailsComponent } from './user-account/uam-user-details/uam-user-details.component';

import { NewUserModalComponent } from './new-user/new-user.modal';

const internal_components = [
  GlobalSettingsComponent,
  WarehouseViewSettingsComponent,
  GlobalSettingsComponent,
  NotificationsSettingsComponent,
  DebuggingSettingsComponent,
  USAboutComponent,

  UAMUserDetailsComponent,
];

@NgModule({
  declarations: [UserSettingsModal, UserAccountModalComponent, NewUserModalComponent, ...internal_components],
  imports: [
    MiscModule,
    FormsModule,
    SharedModule,
    CommonModule,
    RouterModule,
    SchemaFormModule,
    MiscWidgetsModule,
    DevicesSharedModule,
    DialogsModalsModule,
    ReactiveFormsModule,
    MaterialSharedModule,
    WidgetsReusableModule,
  ],
})
export class UserDialogsAndModalsModule {}
