import { Component, Input, ChangeDetectionStrategy } from '@angular/core';
import { Output, EventEmitter } from '@angular/core';

import { BehaviorSubject, Observable, combineLatest, distinctUntilChanged, map, switchMap, debounceTime } from 'rxjs';

import { get, isNil } from 'lodash-es';
import { Entity } from 'app/models';
import { replay } from '@bg2app/tools/rxjs';
import { Dictionary } from 'app/typings/core/interfaces';

interface IPagingData {
  nb_pages: number;
  active_page: number;
  pages: any;
  labels: any;
}

interface PageLink {
  num: number;
  label: string;
}

@Component({
  selector: 'bg2-paging',
  templateUrl: 'paging.component.html',
  styleUrls: ['./paging.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PagingComponent {

  // #region -> (component inputs)

  private _entities$$ = new BehaviorSubject<Entity[]>([]);
  public entities$$ = this._entities$$.asObservable();
  @Input() set entities(val: Entity[]) {
    this._entities$$.next(val);
  }
  get entities(): Entity[] {
    return this._entities$$.getValue();
  }

  private _title$$ = new BehaviorSubject<string>(null);
  public title$$ = this._title$$.asObservable();
  @Input()
  public set title(val: string) {
    this._title$$.next(val);
  }
  public get title(): string {
    return this._title$$.getValue();
  }

  private _limit$$ = new BehaviorSubject<number>(10);
  public limit$$ = this._limit$$.asObservable();
  @Input()
  set limit(limit: number) {
    this._limit$$.next(limit);
  }
  get limit(): number {
    return this._limit$$.getValue();
  }

  private _total$$ = new BehaviorSubject<number>(0);
  @Input()
  set total(total: number) {
    this._total$$.next(total);
  }
  get total(): number {
    return this._total$$.getValue();
  }


  private _offset$$ = new BehaviorSubject<number>(0);
  public offset$$ = this._offset$$.asObservable().pipe(
    distinctUntilChanged(),
    map(offset => Math.max(0, offset)),
    switchMap(offset => this.total$$.pipe(
      map(total => Math.min(total - 1, offset)),
    )),
    debounceTime(100),
    replay(1)
  );
  @Input()
  set offset(offset: number) {
    this._offset$$.next(offset);
  }
  get offset(): number {
    return this._offset$$.getValue();
  }


  private _size_max$$ = new BehaviorSubject<number>(10);
  public size_max$$ = this._size_max$$.asObservable();
  @Input()
  set size_max(size_max: number) {
    this._size_max$$.next(size_max);
  }

  // #endregion

  // #endregion
  public page$$ = combineLatest([this.offset$$, this.limit$$]).pipe(
    map(([offset, limit]) => Math.ceil(offset / limit)),
    map(page => Math.max(0, page)),
    switchMap(page => this.nb_pages$$.pipe(
      map(nb_pages => Math.min(nb_pages - 1, page)),
    )),
    distinctUntilChanged(),
    replay()
  );

  @Output() pageChanged = new EventEmitter();

  // #region -> (component basics)

  private nb_entities$$ = this.entities$$.pipe(
    map(entities => entities?.length || null)
  );

  public total$$ = combineLatest([
    this._total$$,
    this.nb_entities$$
  ]).pipe(
    map(([total, nb_entities]) => Math.min(total, nb_entities ? nb_entities : total)),
    debounceTime(100),
    distinctUntilChanged(),
    replay()
  );

  public nb_pages$$ = combineLatest([
    this.total$$,
    this.limit$$
  ]).pipe(
    map(([total, limit]) => Math.ceil(total / limit))
  );

  public has_pagination$$ = this.nb_pages$$.pipe(
    map(nb_pages => nb_pages > 1)
  );

  public pages$$: Observable<PageLink[]> = combineLatest([
    this.page$$,
    this.nb_pages$$,
    this.size_max$$,
    this.limit$$,
    this.entities$$,
    this.title$$
  ]).pipe(
    map(([page, nb_pages, size_max, limit, entities, title]) => {
      // console.log('debug', page, nb_pages, size_max, limit, entities, title);
      let page_min = Math.max(0, page - size_max / 2);
      const page_max = Math.min(nb_pages, page_min + size_max);
      page_min = Math.max(0, page_max - size_max);

      const pages = [];
      const labels: Dictionary<PageLink> = {};
      for (let num = page_min; num < page_max; num++) {
        pages.push({
          num,
          label: this.getLabel(num, limit, entities, title)
        });
      }
      return pages;
    })
  );

  constructor() {
    this.page$$.subscribe(page => {
      this.pageChanged.emit(page);
    });
  }

  // #endregion


  private getLabel(page: number, limit: number, entities: Entity[], title: string): string {
    const nbchar_max = 2;
    if (isNil(entities) || isNil(title)) {
      return `${page + 1}`;
    } else {
      const offset: number = page * limit;
      const mtitle: string = (get(entities[offset], title) || '').toUpperCase();
      // console.log(page, i, mtitle);
      // Detemine how many chars are needed to distinct current page from previous
      let nbchar = 1;
      if (offset - limit >= 0) {
        const previous = (get(entities[offset - limit], title) || '').toUpperCase();
        // console.log("previous", previous)
        while (mtitle.slice(0, nbchar) === previous.slice(0, nbchar) && nbchar <= nbchar_max) { nbchar++; }
      }
      // Detemine how many chars are needed to distinct current page from next
      if (offset + limit < entities.length) {
        const next = (get(entities[offset + limit], title) || '').toUpperCase();
        // console.log("next", next)
        while (mtitle.slice(0, nbchar) === next.slice(0, nbchar) && nbchar <= nbchar_max) { nbchar++; }
      }
      let suffix = '...';
      if (nbchar > nbchar_max || mtitle.length === 0) {
        nbchar = nbchar_max;
        suffix = `#${page + 1}...`;
      }
      return mtitle.slice(0, nbchar) + suffix;
    }
  }

  public goto(page: number): void {
    this._offset$$.next(page * this.limit);
  }

  public goto_next(): void {
    this._offset$$.next(this.offset + this.limit);
  }
  public goto_previous(): void {
    this._offset$$.next(this.offset - this.limit);
  }

}
