import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';

import { MatSnackBarRef, MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';

@Component({
  selector: 'bg2-new-beta-version-snackbar',
  templateUrl: 'new-beta-version-snackbar.component.html',
  styleUrls: ['new-beta-version-snackbar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NewBetaVersionSnackbarComponent {
  // #region -> (component basics)

  constructor(
    @Inject(MAT_SNACK_BAR_DATA) public data: { show_new_beta: boolean },
    private snackBarRef: MatSnackBarRef<NewBetaVersionSnackbarComponent>
  ) {}

  // #endregion

  public use_beta_version(): void {
    this.snackBarRef.dismissWithAction();
  }

  public quit_beta_version(): void {
    this.snackBarRef.dismissWithAction();
  }

  public ignore(): void {
    this.snackBarRef.dismiss();
  }
}
