import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

import { BehaviorSubject } from 'rxjs';

import { VISIT_ASPECT } from 'app/models/events/VisitEvent';

@Component({
  selector: 'bg2-navigate-visit-aspects',
  templateUrl: './navigate-visit-aspects.component.html',
  styleUrls: ['./navigate-visit-aspects.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavigateVisitAspectsComponent {
  // #region -> (component basics)

  // #endregion

  // #region -> (aspects management)

  /** */
  private _aspects$$ = new BehaviorSubject<VISIT_ASPECT[]>([]);

  /** */
  public aspects$$ = this._aspects$$.asObservable();

  /** */
  @Input()
  public set aspects(aspects: VISIT_ASPECT[]) {
    this._aspects$$.next(aspects);
  }

  // #endregion

  /** */
  private _current_aspect$$ = new BehaviorSubject<VISIT_ASPECT>(null);

  /** */
  public current_aspect$$ = this._current_aspect$$.asObservable();

  /** */
  @Input()
  public set current_aspect(current_aspect: VISIT_ASPECT) {
    this._current_aspect$$.next(current_aspect);
  }

  /** */
  @Output()
  public when_aspect_changed = new EventEmitter<VISIT_ASPECT>();
}
