import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

import { marker as i18n } from '@biesbjerg/ngx-translate-extract-marker';

import { strEnum } from 'app/misc/tools';

import {
  Bg2SimpleHistogramBaseComponent,
  SimpleHistogramConfig,
} from 'app/widgets/widgets-reusables/charts/histograms/simple-histogram-base/simple-histogram-base.component';
import { ErrorHelperData } from 'app/widgets/widgets-reusables/errors/error-helper/error-helper.component';

const hive_status = strEnum(['OK', 'RHS', 'E', 'NV', 'B', 'D', 'T', 'S']);
export type HiveStatus = keyof typeof hive_status;

export type HiveStatusesData = { [key in HiveStatus]: number };

@Component({
  selector: 'bg2-evaluation-histogram-hive-statuses',
  templateUrl: '../../../../../../widgets/widgets-reusables/charts/histograms/simple-histogram-base/simple-histogram-base.component.html',
  styleUrls: ['../../../../../../widgets/widgets-reusables/charts/histograms/simple-histogram-base/simple-histogram-base.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class Bg2EvaluationHistogramHiveStatusesComponent extends Bg2SimpleHistogramBaseComponent implements OnInit {
  @Input()
  public set statuses(statuses: HiveStatusesData) {
    this.histogram_data = statuses;
  }

  // #region -> (component basics)

  constructor() {
    super();
    this.histogram_type = 'hive-status';
  }

  ngOnInit(): void {
    super.ngOnInit();

    this.histogram_configuration = new Map([
      ['OK', { key: 'OK', label: 'EVENT.VISIT.STATUS.OK', img: 'assets/img/hive_status/ok.png' }],
      ['RHS', { key: 'RHS', label: 'EVENT.VISIT.STATUS.Dead queen', img: 'assets/img/hive_status/rhs.png' }],
      ['E', { key: 'E', label: 'EVENT.VISIT.STATUS.Swarming', img: 'assets/img/hive_status/e.png' }],
      ['NV', { key: 'NV', label: 'EVENT.VISIT.STATUS.NV', img: 'assets/img/hive_status/nv.png' }],
      ['B', { key: 'B', label: 'EVENT.VISIT.STATUS.Drone laying queen', img: 'assets/img/hive_status/b.png' }],
      ['D', { key: 'D', label: 'EVENT.VISIT.STATUS.Dead', img: 'assets/img/hive_status/d.png' }],
      ['T', { key: 'T', label: 'EVENT.VISIT.STATUS.Stolen', img: 'assets/img/hive_status/t.png' }],
      ['S', { key: 'S', label: 'EVENT.VISIT.STATUS.Sanitary suspicion', img: 'assets/img/hive_status/s.png' }],
    ]) as SimpleHistogramConfig;

    this.has_data$$.subscribe({
      next: has_data => {
        if (!has_data) {
          this.error = new ErrorHelperData([{ type: 'span', content: i18n<string>('ALL.DATA.MISC.No data') }]);
        } else {
          this.error = null;
        }
      },
    });
  }

  // #endregion
}
