import { catchError, combineLatest, map, switchMap, take, tap, timeout } from "rxjs"

import { marker as i18n } from '@biesbjerg/ngx-translate-extract-marker';

import { Clipboard } from '@angular/cdk/clipboard';

import { Exploitation } from "app/models"
import { DialogsService } from "app/widgets/dialogs-modals"
import { CustomazibleDialogParams } from "app/widgets/dialogs-modals/customizable/customazible.dialog"
import { DialogDiv, DialogElement } from "app/widgets/dialogs-modals/dialog-parts/dialog-div/dialog-div.component";

export function openExploitationCRMUpdateModal(
  dialogs_service: DialogsService,
  clipboard: Clipboard,
  exploitation: Exploitation,
  intro_message: string = null,
) {
  return combineLatest({
    expl_crm_id: exploitation.crm_id$$,
    owner: exploitation.owner$$,
    owner_crm_id: exploitation.owner$$.pipe(
      switchMap(owner => owner.CRM_id$$)
    ),
  }).pipe(
    timeout(500),
    take(1),
    tap(({expl_crm_id, owner_crm_id}) => {
      if(!expl_crm_id) {
        throw Error('No expl crm_id');
      }
      if(!owner_crm_id) {
        throw Error('No owner crm_id');
      }
    }),
    map(({owner, expl_crm_id, owner_crm_id}) => {
      const dialog_config: CustomazibleDialogParams<string> = {
        body: {
          type: 'div',
          styles: {},
          elements: [
            {
              // Update owner
              type: 'div',
              elements: [
                {
                  type: 'span',
                  content: i18n<string>('VIEWS.EXPLOITATIONS.HELPERS."[exploitation_name]" exploitation_id is: [exploitation_id]'),
                  translateParams: {
                    exploitation_name: exploitation.name,
                    exploitation_id: exploitation.id
                  }
                },
                {
                  type: 'link',
                  content: i18n('VIEWS.EXPLOITATIONS.HELPERS.Copy exploitation_id and edit [exploitation_name] CRM entry (in a new tab)'),
                  result: 'open_crm_expl',
                  action: () => {
                    clipboard?.copy(`${exploitation.id}`);
                    window.open(`https://crm.zoho.eu/crm/org20067795631/tab/Accounts/${expl_crm_id}/edit?layoutId=219345000000032035`, "_blank");
                  },
                  translateParams: {
                    exploitation_name: exploitation.name,
                  }
                },
              ]
            },
            {
              type: 'logical-sep',
              messageType: 'AND'
            },
            {
              // Update owner
              type: 'div',
              elements: [
                {
                  type: 'span',
                  content: i18n<string>('VIEWS.EXPLOITATIONS.HELPERS.Exploitation owner "[owner_name]" user_id is: [owner_id]'),
                  translateParams: {
                    owner_name: owner.name,
                    owner_id: exploitation.user_id
                  }
                },
                {
                  type: 'link',
                  content: i18n('VIEWS.EXPLOITATIONS.HELPERS.Copy user_id and edit [owner_name] CRM contact (in a new tab)'),
                  result: 'open_crm_owner',
                  action: () => {
                    clipboard?.copy(`${exploitation.user_id}`);
                    window.open(`https://crm.zoho.eu/crm/org20067795631/tab/Contacts/${owner_crm_id}/edit?layoutId=219345000000032039`, "_blank");
                  },
                  translateParams: {
                    owner_name: owner.name
                  }
                },
              ]
            },
          ],
        },
        footer: {
          styles: {},
          buttons: {
            styles: {},
            items: [
              {
                type: 'button',
                result: 'done',
                color: 'primary',
                content: i18n<string>("ROUTABLE_MODALS.NEW_EXPLOITATION.CREATED.I've updated zoho CRM"),
              },
            ],
          },
        },
      }
      if (intro_message) { // Add intro msg if needed
        ((dialog_config.body as DialogDiv<string>).elements as DialogElement<string>[]).unshift({
          type: 'span',
          content: intro_message,
          styles: {
            'font-size': '1.2em',
            'margin-bottom': '10px'
          },
          
        });
      }
      return dialog_config
    }),
    switchMap(dialog_config => dialogs_service.customizable(dialog_config)),
    catchError(() => dialogs_service.alert(i18n<string>('VIEWS.EXPLOITATIONS.HELPERS.CRM id is not set in exploitation or owner, one can not help you to update zoho CRM'))),
  )
}