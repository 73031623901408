import { Pipe, PipeTransform } from '@angular/core';

import { abbreviate_number } from '../tools/maths/abbreviate-number/abbreviate-number.tool';

@Pipe({
  name: 'abbreviateNumber',
  pure: true,
})
export class AbbreviateNumberPipe implements PipeTransform {
  public transform(value: number, digits: number = 0): string {
    return abbreviate_number(value, digits);
  }
}
