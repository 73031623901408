import { Pipe, PipeTransform } from '@angular/core';

import { range } from 'lodash-es';

@Pipe({
  name: 'range',
  pure: true,
})
export class RangePipe implements PipeTransform {
  public transform(value: number): any {
    return range(value);
  }
}
