import { Component, OnInit, OnDestroy, ChangeDetectionStrategy } from '@angular/core';

import { Subscription, combineLatest, BehaviorSubject, concat, of, timer, Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map, skipUntil, tap } from 'rxjs';

import { startOfToday, subMonths, subDays, subYears, endOfToday } from 'date-fns';

import { assign, isNil } from 'lodash-es';

import { parseDate } from 'app/misc/tools';

import { UrlParamsService } from 'app/core/url-param.service';

import { EfObjectWidgetComponent, ObjectWidgetOptions } from '../object/object.widget';
import { Beeguard2Api } from 'app/core';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';
import { distinctUntilRealChanged, replay } from '@bg2app/tools/rxjs';
import { Dictionary } from 'app/typings/core/interfaces';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';

export enum DateRangeInterval {
  OTHER = 'OTHER',
  LAST_DAY = 'LAST_DAY',
  LAST_WEEK = 'LAST_WEEK',
  LAST_MONTH = 'LAST_MONTH',
  LAST_YEAR = 'LAST_YEAR',
}

export interface DateRangeOptions extends ObjectWidgetOptions {
  default_interval: DateRangeInterval;
  url_param_binding: string;
}

@AutoUnsubscribe()
@Component({
  selector: 'bg2-ef-date-range-widget',
  templateUrl: './date-range.widget.html',
  styleUrls: ['./date-range.widget.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EfDateRangeWidgetComponent extends EfObjectWidgetComponent implements OnInit, OnDestroy {
  // #region -> (date range manager)

  public date_range_form = new UntypedFormGroup({
    start: new UntypedFormControl(),
    end: new UntypedFormControl(),
  });

  public options: DateRangeOptions = assign(super.options, {
    default_interval: DateRangeInterval.LAST_DAY,
    url_param_binding: null,
  });

  // #endregion

  // #region -> (widget basics)

  private _range_sub: Subscription = null;
  private _value_sub: Subscription = null;
  private _interval_sub: Subscription = null;
  private _url_params_sub: Subscription = null;

  constructor(protected bg2Api: Beeguard2Api, private urlParams: UrlParamsService) {
    super(bg2Api);
  }

  ngOnInit(): void {
    super.ngOnInit();

    this._range_sub = this.date_range_form.valueChanges.pipe(debounceTime(500), distinctUntilRealChanged()).subscribe({
      next: ({ start, end }) => {
        let fns_start = start ? parseDate(start.toISOString()) : null;
        let fns_end = end ? parseDate(end.toISOString()) : null;
        if (fns_start && fns_end && fns_start > fns_end) {
          [fns_start, fns_end] = [fns_end, fns_start];
        }
        // console.log('New datas', fns_start?.toISOString(), fns_end?.toISOString());
        this.formProperty.setValue([fns_start?.toISOString() ?? null, fns_end?.toISOString() ?? null], false);
      },
    });

    this._value_sub = this.value$$.pipe(distinctUntilRealChanged()).subscribe({
      next: ([start, end]: [string, string]) => {
        this.date_range_form.patchValue(
          {
            start: !isNil(start) ? parseDate(start) : null,
            end: !isNil(end) ? parseDate(end) : null,
          },
          { onlySelf: false }
        );
      },
    });

    this._interval_sub = this.selected_interval$$.subscribe(interval => {
      const now = endOfToday();

      switch (interval) {
        case DateRangeInterval.LAST_DAY: {
          this.formProperty.setValue([subDays(startOfToday(), 1).toISOString(), now.toISOString()], false);
          break;
        }

        case DateRangeInterval.LAST_WEEK: {
          this.formProperty.setValue([subDays(startOfToday(), 7).toISOString(), now.toISOString()], false);
          break;
        }

        case DateRangeInterval.LAST_MONTH: {
          this.formProperty.setValue([subMonths(startOfToday(), 1).toISOString(), now.toISOString()], false);
          break;
        }

        case DateRangeInterval.LAST_YEAR: {
          this.formProperty.setValue([subYears(startOfToday(), 1).toISOString(), now.toISOString()], false);
          break;
        }

        case DateRangeInterval.OTHER: {
          break;
        }

        default: {
          throw new Error(`The specified date range doesn\'t exist ! (${interval})`);
        }
      }
    });

    // Subscribe on url parameters
    if (this.options.url_param_binding) {
      this._url_params_sub = this.sync_url_params().subscribe();
    } else {
      this._setDefaultInterval();
    }
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();

    this._range_sub?.unsubscribe();
    this._value_sub?.unsubscribe();
    this._interval_sub?.unsubscribe();
    this._url_params_sub?.unsubscribe();

    if (this.options.url_param_binding) {
      const nparams: Dictionary<string> = {};
      nparams[`${this.options.url_param_binding}start`] = null;
      nparams[`${this.options.url_param_binding}end`] = null;
      this.urlParams.update(nparams);
    }
  }

  // #endregion

  private ingnore_next_url_param_update = false;

  public sync_url_params(): Observable<number> {
    const prefix = this.options.url_param_binding;
    const start_key = `${prefix}start`;
    const end_key = `${prefix}end`;

    const on_url_param_changed$$ = combineLatest([
      concat(of(null), this.urlParams.on_change(start_key)),
      concat(of(null), this.urlParams.on_change(end_key)),
    ]).pipe(
      debounceTime(20),
      distinctUntilRealChanged(),
      tap(([date_start, date_end]) => {
        if (!this.ingnore_next_url_param_update) {
          if (!isNil(date_start) && !isNil(date_end)) {
            this.formProperty.setValue([date_start, date_end], false);
          } else {
            this._setDefaultInterval();
          }
        }
        this.ingnore_next_url_param_update = false;
      })
    );

    const on_value_changed$$ = this.value$$.pipe(
      skipUntil(timer(500)), // ignore default value ?
      debounceTime(200),
      distinctUntilRealChanged(),
      tap(() => (this.ingnore_next_url_param_update = true)),
      tap(([start, end]) => {
        const nparams: Dictionary<string> = {};
        nparams[`${this.options.url_param_binding}start`] = start;
        nparams[`${this.options.url_param_binding}end`] = end;
        this.urlParams.update(nparams);
      })
    );

    return combineLatest([on_value_changed$$, on_url_param_changed$$]).pipe(map(() => 0));
  }

  // #region (dates interval)

  public INTERVAL = DateRangeInterval;

  private _selected_interval$$ = new BehaviorSubject<DateRangeInterval>(DateRangeInterval.OTHER);
  public selected_interval$$ = this._selected_interval$$.asObservable().pipe(
    filter(_interval => !isNil(_interval)),
    distinctUntilChanged(),
    replay()
  );

  public setInterval(interval: DateRangeInterval | keyof typeof DateRangeInterval): void {
    const _interval = DateRangeInterval[interval];
    this._selected_interval$$.next(_interval);
  }

  private _setDefaultInterval(): void {
    if (this.options.default_interval && (isNil(this.value) || (this.value as any[]).length === 0)) {
      this.setInterval(this.options.default_interval);
    }
  }

  // #endregion
}
