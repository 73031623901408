<div class="bg2-zoho-invoice-summary-component">
  <div *ngLet="invoice$$ | async; let invoice" class="invoice-container">
    <div class="invoice-container__header-container">
      <span class="invoice-container__description-container__subject" [popper]="invoice?.customer_name$$ | async">
        {{ invoice?.customer_name$$ | async }}
      </span>

      <div class="invoice-amount">
        <span class="value">{{ (invoice?.total$$ | async) ?? '-' }} €</span>
      </div>
    </div>

    <div class="invoice-container__description-container">
      <div class="invoice-details">
        <span class="invoice-number">
          {{ invoice?.invoice_number$$ | async }} ({{ invoice?.created_time$$ | async | formatDate : 'short' }})
        </span>

        <span class="invoice-due-date">
          {{
            'VIEWS.ZOHO.SHARED.COOKS.INVOICE_SUMMARY.Due in [due_date]'
              | translate : { due_date: invoice?.due_date$$ | async | distanceToNow : true : false }
          }}
        </span>
      </div>

      <div class="reference">
        {{ invoice?.reference_number$$ | async }}
      </div>

      <div class="link-to-web-page">
        <span class="mdi mdi-web"></span>

        <a [href]="invoice?.url$$ | async" target="_blank" (click)="$event.stopImmediatePropagation()">
          {{ 'VIEWS.ZOHO.SHARED.BOOKS.Click to view/edit on BOOKS' | translate }}
        </a>
      </div>
    </div>
  </div>
</div>
