export * from './Event';

import { Beeguard2Api, DeviceApi } from 'app/core';
import { Event as EventInterface } from 'app/core/api-swagger/beeguard2';

import { Event } from './Event';
import { SetupApiary, OtherIntervention } from './ApiaryBasic';
import { HoneyHarvest, HoneyExtraction } from './Harvest';
import { Feeding } from './Feeding';
import { SuperBoxAdd, SuperBoxHarvest } from './Superbox';
import {
  DeviceRegister,
  DeviceReplace,
  BulkDevicesRegister,
  DeviceReasign,
  DevicesWGInstall,
  DeviceGPSInstall,
  DeviceRGInstall,
  DeviceCPTInstall,
  DeviceBeeLiveInstall,
  DeviceTakeout,
  DeviceRGTakeout,
  DeviceTGInstall,
} from './device_setup_events';
import { Migratory } from './Migratory';
import { EvaluationEvent } from './Evaluation';
import { HiveSetup, HivesSetup } from './HiveBasic';
import { LocationPayment } from './LocationPayment';
import { SetupLocation } from './LocationBasic';
import { DataSharing } from './DataSharing';

import { strEnum } from 'app/misc/tools/misc.helpers';
import { VisitEvent } from './VisitEvent';

export function isOlder(event_date: Date, event_id: number, other_date: Date, other_id: number) {
  if (event_date > other_date) {
    return true;
  } else if (event_date.getTime() === other_date.getTime()) {
    if (event_id < 0 && other_id < 0) {
      // NOTE ghost events have dicreasing id (-1, -2, -3)
      // => (2022-01-01, -2) more recent than (2022-01-01, -1)
      return event_id < other_id;
    } else {
      return event_id > other_id;
    }
  }
  return false;
}

export function areEquals(event_date: Date, event_id: number, other_date: Date, other_id: number) {
  return event_date.getTime() === other_date.getTime() && event_id === other_id;
}

export function eventDeserialize(bg2Api: Beeguard2Api, deviceApi: DeviceApi, input: EventInterface) {
  let object: Event;
  switch (input.type) {
    case 'location_setup': {
      object = new SetupLocation(bg2Api);
      break;
    }
    case 'location_payment': {
      object = new LocationPayment(bg2Api);
      break;
    }
    case 'apiary_setup': {
      object = new SetupApiary(bg2Api);
      break;
    }
    case 'hive_setup': {
      object = new HiveSetup(bg2Api);
      break;
    }
    case 'hives_setup': {
      object = new HivesSetup(bg2Api);
      break;
    }
    case 'device_register': {
      object = new DeviceRegister(bg2Api);
      break;
    }
    case 'device_replace': {
      object = new DeviceReplace(bg2Api);
      break;
    }
    case 'bulk_devices_register': {
      object = new BulkDevicesRegister(bg2Api);
      break;
    }
    case 'device_reasign': {
      object = new DeviceReasign(bg2Api);
      break;
    }
    case 'device_takeout': {
      object = new DeviceTakeout(bg2Api);
      break;
    }
    case 'device_RG_takeout': {
      object = new DeviceRGTakeout(bg2Api);
      break;
    }
    case 'devices_WG_install': {
      object = new DevicesWGInstall(bg2Api);
      break;
    }
    case 'device_GPS_install': {
      object = new DeviceGPSInstall(bg2Api);
      break;
    }

    case 'device_TG_install': {
      object = new DeviceTGInstall(bg2Api);
      break;
    }
    case 'device_CPT_install': {
      object = new DeviceCPTInstall(bg2Api);
      break;
    }
    case 'device_BeeLive_install': {
      object = new DeviceBeeLiveInstall(bg2Api);
      break;
    }
    case 'device_RG_install': {
      object = new DeviceRGInstall(bg2Api);
      break;
    }
    case 'other_intervention': {
      object = new OtherIntervention(bg2Api);
      break;
    }
    case 'honey_harvest': {
      object = new HoneyHarvest(bg2Api);
      break;
    }
    case 'honey_extraction': {
      object = new HoneyExtraction(bg2Api);
      break;
    }
    case 'feeding': {
      object = new Feeding(bg2Api);
      break;
    }
    case 'superbox_add': {
      object = new SuperBoxAdd(bg2Api);
      break;
    }
    case 'superbox_harvest': {
      object = new SuperBoxHarvest(bg2Api);
      break;
    }
    case 'evaluation': {
      // object = new VisitEvent(bg2Api);
      object = new EvaluationEvent(bg2Api);
      break;
    }
    case 'migratory': {
      object = new Migratory(bg2Api);
      break;
    }
    case 'data_sharing': {
      object = new DataSharing(bg2Api);
      break;
    }
    default: {
      object = new Event(bg2Api);
    }
  }
  object.deserialize(input);
  return object;
}

export const EventType = strEnum([
  'apiary_setup',
  'apiary_hives_setup',
  'apiary_archive',
  'other_intervention',
  '_device_wharehouse_assign',
  'device_register',
  'devices_WG_install',
  'device_GPS_install',
  'device_RG_install',
  'device_takeout',
  'device_RG_takeout',
  'evaluation',
  'feeding',
  'honey_harvest',
  'honey_extraction',
  'health_care',
  'hive_setup',
  'hives_setup',
  'location_payment',
  'location_setup',
  'location_archive',
  'migratory',
  'data_sharing',
  'apismart_data_sharing',
  'superbox_add',
  'superbox_harvest',
  'superbox_stock',
]);

export type EventType = keyof typeof EventType;

export const ExportableEventType = Object.values(EventType).filter(
  event_type =>
    event_type === 'evaluation' || event_type === 'migratory' || event_type === 'superbox_harvest' || event_type === 'other_intervention'
);
