import { DOCUMENT } from '@angular/common';
import { AfterViewInit, ChangeDetectionStrategy, Component, Inject, Input, OnDestroy, OnInit, Renderer2 } from '@angular/core';

import { isNil } from 'lodash-es';

import { marker as i18n } from '@biesbjerg/ngx-translate-extract-marker';

import { Beeguard2Api } from 'app/core';
import { ConsoleLoggerService } from 'app/core/console-logger.service';

import { Apiary } from 'app/models';
import { SuperBoxHarvest } from 'app/models/events/Superbox';

import { ApiaryCarouselGroupsuperboxHarvestComponent } from './apiary-carousel-group-sh.component';
import { ErrorButtonElement } from 'app/widgets/widgets-reusables/errors/error-helper/error-helper.component';
import { EventBasedCarouselContainerComponent } from 'app/widgets/widgets-reusables/carousels/event-based-carousel/event-carousel-container/event-carousel-container.component';

@Component({
  selector: 'bg2-apiary-carousel-superbox-harvest',
  templateUrl:
    '../../../../../../widgets/widgets-reusables/carousels/event-based-carousel/event-carousel-container/event-carousel-container.component.html',
  styleUrls: [
    '../../../../../../widgets/widgets-reusables/carousels/event-based-carousel/event-carousel-container/event-carousel-container.component.scss',
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ApiaryCarouselSuperboxHarvestComponent
  extends EventBasedCarouselContainerComponent<SuperBoxHarvest>
  implements OnInit, AfterViewInit, OnDestroy
{
  @Input()
  public set apiary(apiary: Apiary) {
    if (isNil(apiary)) {
      return;
    }

    this.carousel_config = {
      entity: apiary,
    };
  }

  // #region -> (component basics)

  protected _logger = new ConsoleLoggerService('ApiaryCarouselSuperboxHarvestComponent', false);

  constructor(protected _renderer: Renderer2, protected _bg2Api: Beeguard2Api, @Inject(DOCUMENT) protected document: Document) {
    super(_renderer, _bg2Api, document);
    this.type_of_card_group_component = ApiaryCarouselGroupsuperboxHarvestComponent;
  }

  ngOnInit(): void {
    this.carousel_config = {
      event_name: 'superbox_harvest',

      legend: {
        previous: i18n<string>('EVENT.SUPERBOX_HARVEST.CAROUSEL.NAVIGATION.Previous supers harvest'),
        next: i18n<string>('EVENT.SUPERBOX_HARVEST.CAROUSEL.NAVIGATION.Next supers harvest'),
      },

      error: {
        read_what: i18n<string>('ALL.ACE.READ_ALL_EVENTS.WHAT.see the superbox harvests'),
        write_what: i18n<string>('ALL.ACE.WRITE_ALL_EVENTS.WHAT.create a superbox harvest event'),
        create: {
          message: i18n<string>('VIEWS.APIARY.COMMON.Declare your most recent harvest on this apiary so that it can be easily found here'),
        },
      },
    };

    super.ngOnInit();
  }

  ngAfterViewInit(): void {
    super.ngAfterViewInit();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  // #endregion

  /** */
  protected build_button_to_new_event(entity_id: number) {
    return {
      type: 'button',
      action: 'action',
      icon: 'mdi-plus-circle-outline',
      message: i18n<string>('EVENT.SUPERBOX_HARVEST.New supers harvest'),
      navigate: {
        extras: {
          queryParamsHandling: 'preserve',
        },
        commands: [{ outlets: { modal: ['new_event', { etype: 'superbox_harvest', args: JSON.stringify({ apiary: entity_id }) }] } }],
      },
    } as ErrorButtonElement;
  }
}
