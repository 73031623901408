import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';

import {
  of,
  tap,
  map,
  take,
  filter,
  forkJoin,
  switchMap,
  Observable,
  Subscription,
  combineLatest,
  withLatestFrom,
  BehaviorSubject,
} from 'rxjs';
import { distinctUntilRealChanged, replay, waitForNotNilValue } from '@bg2app/tools/rxjs';

import {
  assign,
  flattenDeep,
  has,
  isEmpty,
  isEqual,
  isNil,
  isNumber,
  isUndefined,
  mapValues,
  remove,
  sortBy,
  update,
  values,
} from 'lodash-es';

import { TranslateService } from '@ngx-translate/core';
import { marker as i18n } from '@biesbjerg/ngx-translate-extract-marker';

import { ISchema } from 'ngx-schema-form';

import { DialogsService } from 'app/widgets/dialogs-modals';
import { Beeguard2Api } from 'app/core/api/main/beeguard2-api-service';

import { Dictionary } from 'app/typings/core/interfaces';

import { ACE, Exploitation, Location } from 'app/models';
import { UsersApiService } from 'app/core/api/user/users-api.service';

import { ModalParams, ModalArgs, AbstractModalComponent } from 'app/widgets/dialogs-modals/abstract-modal.component';

interface UserAcl {
  scopes: ACE[];
  user_id: number;
}

export interface UpdateEntityACLModalParams extends ModalParams {
  eid: number;
  args: ModalArgs;
}

@Component({
  selector: 'bg2-update-entity-acl-modal',
  templateUrl: './update-entity-acl.modal.html',
  styleUrls: ['./update-entity-acl.modal.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class UpdateEntityACLModal extends AbstractModalComponent<UpdateEntityACLModalParams> implements OnInit, OnDestroy {
  // #region -> (component basics)

  private _users_and_acls_sub: Subscription = null;

  constructor(
    private _userApi: UsersApiService,
    private _bg2Api: Beeguard2Api,
    private _dialogs: DialogsService,
    private _translate: TranslateService,
    private _breakpoints: BreakpointObserver
  ) {
    super();
  }

  ngOnInit(): void {
    this._users_and_acls_sub = this.initial_entity_acl$$.subscribe({
      next: (acls: any) => {
        this.acl_by_user = this.compute_acl_by_user(acls.acl);
      },
    });
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this._users_and_acls_sub?.unsubscribe();
  }

  /** */
  protected handle_event_before_unload(event: BeforeUnloadEvent): void {
    return null;
  }

  // #endregion

  // #region -> (submission management)

  private _is_saving$$ = new BehaviorSubject(false);
  public is_saving$$ = this._is_saving$$.asObservable().pipe(distinctUntilRealChanged(), replay());

  public submit() {
    combineLatest({ acl_models_by_user: this.acl_models_by_user$$, entity: this.entity$$ })
      .pipe(
        take(1),
        tap(() => this._is_saving$$.next(true)),
        map(({ acl_models_by_user, entity }) => ({
          entity,
          scopes: values(acl_models_by_user).map(user_acl =>
            user_acl.scopes.map(
              scope =>
                // TODO: manage scope.*
                ({
                  user_id: user_acl.user_id,
                  scope: scope.scope,
                } as ACE)
            )
          ),
        })),
        tap(({ scopes }) => this._logger.debug('Save the scopes:', scopes)),
        switchMap(({ entity, scopes }) =>
          entity.updateACL({
            acl: flattenDeep(scopes),
            owner_id: this._entity.user_id,
          })
        )
      )
      .subscribe({
        next: () => {
          this._is_saving$$.next(false);
          this.close();
        },
        error: (error: unknown) => {
          this._is_saving$$.next(false);
          //       this.loading = false;
          //       this.initial_loading = false;
          //       this.handleHttpError(err);
        },
      });
  }

  // #endregion

  // #region -> (entity management)

  /**
   * Internal reference to the entity.
   */
  private _entity: Exploitation | Location = null;

  /**
   * Observable on the entity identifier.
   */
  public entity_id$$ = this.input_params$$.pipe(
    map(input_params => input_params?.eid),
    map(entity_id => {
      if (isNil(entity_id)) {
        return null;
      }

      if (!isNumber(entity_id)) {
        return parseInt(entity_id, 10);
      }

      return entity_id;
    }),
    distinctUntilRealChanged(),
    replay()
  );

  /**
   * Observable of entity loaded from it's ID.
   */
  public entity$$: Observable<Exploitation | Location> = this.entity_id$$.pipe(
    switchMap(entity_id => {
      if (!isNil(entity_id)) {
        return this._bg2Api.getEntityObj(entity_id);
      }
      return of<Exploitation | Location>(null);
    }),
    tap(entity => (this._entity = entity)),
    replay()
  );

  public initial_entity_acl$$ = this.entity$$.pipe(
    waitForNotNilValue(),
    switchMap(entity => entity.entity_acl$$ as any), //TODO fix entity acl typing
    replay()
  );

  public entity_type$$ = this.entity$$.pipe(
    waitForNotNilValue(),
    switchMap(entity => entity.type$$),
    distinctUntilRealChanged(),
    replay()
  );
  // #endregion

  // #region -> (loadings management)

  private _loading_users_with_acl$$ = new BehaviorSubject(true);
  public loading_users_with_acl$$ = this._loading_users_with_acl$$.pipe(distinctUntilRealChanged(), replay());

  // #endregion
  private _acl_by_user$$: BehaviorSubject<UserAcl[]> = new BehaviorSubject(null);
  public acl_by_user$$: Observable<UserAcl[]> = this._acl_by_user$$.asObservable();

  public acl_user_ids$$: Observable<number[]> = this.acl_by_user$$.pipe(
    // tap(() => this._loading_users_with_acl$$.next(true)),
    map(acl_by_user => {
      if (isNil(acl_by_user) || isEmpty(acl_by_user)) {
        return [];
      }
      return acl_by_user.map(_acl_by_user => _acl_by_user.user_id);
    }),
    // tap(() => this._loading_users_with_acl$$.next(false)),
    replay()
  );

  public has_users_with_acl$$ = this.acl_user_ids$$.pipe(
    map(users => !isEmpty(users)),
    replay()
  );

  public acl_users$$ = this.acl_user_ids$$.pipe(
    switchMap(user_ids => {
      const query = { user_id__in: user_ids };
      const only = ['username', 'first_name', 'last_name'];
      return this._userApi.fetch_users$(query, { limit: -1, offset: 0 }, ['first_name', 'last_name'], false, only);
    }),
    map(res => res.users || []),
    replay()
  );

  // #region -> (owner form management)

  /**
   * Observable on the owner schema form.
   */
  public owner_schema$$: Observable<ISchema> = combineLatest({ is_saving: this.is_saving$$ }).pipe(
    map(({ is_saving }) => {
      const schema: ISchema = {
        type: 'object',
        required: ['owner'],
        properties: {
          owner: {
            label: i18n('VIEWS.MODALS.UPDATE_ENTITY_ACL.Owner'),
            description: i18n('VIEWS.MODALS.UPDATE_ENTITY_ACL.Note: owner has all read/write authorization on the entity'),
            type: 'number',
            widget: 'bg2user-select',
            readOnly: is_saving,
            options: {
              clearable: false,
            },
          },
        },
      };
      return schema;
    }),
    replay()
  );

  /**
   * Observable on the owner form model.
   */
  public owner_model$$: Observable<{ owner: number }> = this.entity$$.pipe(
    switchMap(entity => entity.user_id$$),
    distinctUntilRealChanged(),
    map(user_id => ({ owner: user_id })),
    replay()
  );

  /**
   * Handle owner form changed value.
   *
   * @param event The event of the form which contains the data.
   */
  public onOwnerFormChanged(fvalue: { owner: number }): void {
    if (isNil(this._entity)) return;
    if (fvalue.owner !== this._entity.user_id) {
      this._entity.user_id = fvalue.owner;
    }
  }

  /** */
  private _is_owner_schema_valid$$ = new BehaviorSubject(false);

  /** */
  public is_owner_schema_valid$$ = this._is_owner_schema_valid$$.asObservable();

  /** */
  public set is_owner_schema_valid(is_owner_schema_valid: boolean) {
    this._is_owner_schema_valid$$.next(is_owner_schema_valid);
  }

  // #endregion

  // #region -> (ACL creation management)

  public nb_authorized_users$$ = this.acl_user_ids$$.pipe(
    map(user_ids => user_ids.length),
    replay()
  );

  public nb_possible_users$$ = combineLatest({
    nb_auth: this.nb_authorized_users$$,
    nb_total: this._userApi.fetch_users$({}, { limit: 0, offset: 0 }).pipe(map(res => res.paging.total)),
  }).pipe(
    map(({ nb_auth, nb_total }) => Math.max(nb_total - nb_auth, 0)),
    replay()
  );

  public can_add_more_authorized_users$$ = this.nb_possible_users$$.pipe(
    map(total => total > 0),
    replay()
  );

  public add_authorized_info$$ = combineLatest({
    acl_user_ids: this.acl_user_ids$$,
    nb_possible: this.nb_possible_users$$,
  }).pipe(
    map(({ acl_user_ids, nb_possible }) => {
      const has_users_with_acl = !isEmpty(acl_user_ids);
      const can_add_more_authorized_users = nb_possible > 0;

      if (!has_users_with_acl && can_add_more_authorized_users) {
        return {
          key: i18n<string>('ROUTABLE_MODALS.UPDATE_ENTITY_ACL.There are no authorized users, you can add a new one using the list below:'),
        };
      } else if (!has_users_with_acl && !can_add_more_authorized_users) {
        return {
          key: i18n<string>('ROUTABLE_MODALS.UPDATE_ENTITY_ACL.There are no authorized users, but you cannot add more'),
        };
      } else if (has_users_with_acl && can_add_more_authorized_users) {
        return {
          key: i18n<string>(
            'ROUTABLE_MODALS.UPDATE_ENTITY_ACL.There are [total_auth] authorized users and you can add [total_left] more using the list below:'
          ),
          data: {
            total_auth: acl_user_ids.length,
            total_left: nb_possible,
          },
        };
      } else {
        return {
          key: i18n<string>('ROUTABLE_MODALS.UPDATE_ENTITY_ACL.There are [total_auth] authorized users, but you cannot add more'),
          data: {
            total_auth: acl_user_ids.length,
          },
        };
      }
    }),
    switchMap(({ key, data }) => this._translate.stream(key, data)),
    replay()
  );

  public onSelectNewAuthorizedUser(user_id: number): void {
    if (!isNil(user_id)) {
      const already_here = this.acl_by_user.find(acl => acl.user_id === user_id);
      if (isNil(already_here)) {
        const tmp_acl_by_user = this.acl_by_user;
        tmp_acl_by_user.push({ user_id: user_id, scopes: [] });
        this.acl_by_user = tmp_acl_by_user;
      }
      this.selected_user_id = user_id;
    }
  }

  // #endregion

  // #region -> (ACL deletion management)

  public onDeleteUserAcl(event: MouseEvent): void {
    event.stopPropagation();
    this.selected_user_id$$
      .pipe(
        withLatestFrom(this.acl_by_user$$),
        take(1),
        map(([selected_user_id, acl_by_user]) => {
          const index = acl_by_user.findIndex(user => user.user_id === selected_user_id);
          return index === -1 ? null : acl_by_user[index].user_id;
        }),
        switchMap((user_id: number) =>
          forkJoin({
            user_id: of(user_id),
            dialog: this._dialogs.confirm(
              i18n<string>(`ROUTABLE_MODALS.UPDATE_ENTITY_ACL.Are you sure you want to delete this user rights ?`),
              {
                onFalseMessage: i18n<string>('VIEWS.MODALS.FORM.Cancel'),
                onTrueMessage: i18n<string>('VIEWS.MODALS.FORM.Confirm'),
              }
            ),
          })
        )
      )
      .subscribe({
        next: ({ dialog, user_id }) => {
          if (dialog) {
            const tmp_acl_by_user = this.acl_by_user;
            remove(tmp_acl_by_user, user_acl => user_acl?.user_id === user_id);
            this.acl_by_user = tmp_acl_by_user;
            this.selected_user_id = null;
          }
        },
      });
  }

  // #endregion

  // #region -> (users management)

  private get acl_by_user(): UserAcl[] {
    return this._acl_by_user$$.getValue();
  }

  private set acl_by_user(acl_by_user: UserAcl[]) {
    this._acl_by_user$$.next(acl_by_user);
  }

  private _selected_user_id$$: BehaviorSubject<number> = new BehaviorSubject(null);
  public selected_user_id$$: Observable<number> = this._selected_user_id$$.asObservable().pipe(
    map(selected_user_id => (!isNil(selected_user_id) && selected_user_id >= 0 ? selected_user_id : null)),
    distinctUntilRealChanged(),
    replay()
  );

  public set selected_user_id(selected_user_id: number) {
    if (isUndefined(selected_user_id)) {
      // NOTE: this is important as mat-button-toggle-group set it to undefined when number of users change
      return;
    }
    this._selected_user_id$$.next(selected_user_id);
  }

  private compute_acl_by_user(acls: ACE[]): UserAcl[] {
    this._loading_users_with_acl$$.next(true);
    const _acls_by_user: Dictionary<UserAcl> = {};

    acls.forEach(ace => {
      if (!has(_acls_by_user, ace.user_id)) {
        _acls_by_user[ace.user_id] = {
          scopes: [],
          user_id: ace.user_id,
          // user: this._possible_users$$.getValue().find(user => userid === ace.user_id),
        };
      }
      _acls_by_user[ace.user_id].scopes.push(ace);
    });
    this._loading_users_with_acl$$.next(false);
    return values(_acls_by_user);
  }

  // #endregion

  // #region -> (ACL edition management)

  public acl_schema$$: Observable<ISchema> = this.initial_entity_acl$$.pipe(
    map((entity_acls: any) => {
      const possible_scopes: any[] = [];
      const oneof_possible_scopes: {
        description: any;
        enum: string[];
      }[] = [];

      mapValues(entity_acls?.scopes_schema, (value, key) => {
        const assigned = assign({ name: key }, entity_acls.scopes_schema[key]);
        possible_scopes.push(assigned);

        oneof_possible_scopes.push({
          description: value.description,
          enum: [key],
        });
      });

      const scopes_schema: ISchema = {
        type: 'object',
        widget: 'bg2entity-acl',
        possible_scopes,
        required: ['user_id', 'scopes'],
        properties: {
          user_id: {
            label: i18n('VIEWS.MODALS.UPDATE_ENTITY_ACL.User'),
            type: 'number',
            widget: 'bg2user-select',
            options: {
              readonly: true,
            },
          },
          scopes: {
            type: 'array',
            minItems: 1,
            maxItems: possible_scopes.length,
            config: {
              remove_btn_inline: true,
            },
            items: {
              type: 'object',
              required: ['scope'],
              properties: {
                scope: {
                  type: 'string',
                  placeholder: i18n('VIEWS.MODALS.UPDATE_ENTITY_ACL.Select an authorization'),
                  widget: 'select',
                  oneOf: oneof_possible_scopes,
                },
              },
            },
            default: [{ scope: null }],
          },
        },
      };
      return scopes_schema;
    }),
    replay()
  );

  /**
   * Observable on acl models for each user.
   */
  public acl_models_by_user$$ = this.acl_by_user$$.pipe(
    map((acl_by_user: UserAcl[]) => {
      const acl_models_by_user: Dictionary<{ user_id: number; scopes: ACE[] }> = {};
      acl_by_user.forEach(value => {
        acl_models_by_user[value?.user_id] = {
          user_id: value?.user_id,
          scopes: value?.scopes,
        };
      });

      return acl_models_by_user;
    }),
    replay()
  );

  public getModelForUser$$: Observable<any> = this.selected_user_id$$.pipe(
    filter(user_id => !isNil(user_id)),
    withLatestFrom(this.acl_models_by_user$$),
    map(([user_id, acl_models_by_user]) => acl_models_by_user[user_id]),
    replay()
  );

  public onAclFormChanged(event: { value: { user_id: number; scopes: ACE[] } }): void {
    const fvalue = event.value;
    const tmp_acl_by_user = this.acl_by_user;

    const index = tmp_acl_by_user.findIndex(value => value.user_id === fvalue?.user_id);

    if (index >= 0) {
      tmp_acl_by_user[index].scopes = fvalue.scopes;
      this.acl_by_user = tmp_acl_by_user;
    }
  }

  // #endregion

  // #region -> (minimal acl check)

  private _is_checking_minimum_rights$$ = new BehaviorSubject<boolean>(false);
  public is_checking_minimum_rights$$ = this._is_checking_minimum_rights$$.pipe(distinctUntilRealChanged(), replay());

  private _is_updating_entity_acl$$ = new BehaviorSubject<boolean>(false);
  public is_updating_entity_acl$$ = this._is_updating_entity_acl$$.pipe(distinctUntilRealChanged(), replay());

  /**
   * Observable of the related exploitation to the current entity.
   */
  private exploitation$$: Observable<Exploitation> = this.entity$$.pipe(
    switchMap(entity => {
      if (entity instanceof Location) {
        return entity.exploitation$$;
      }
      if (entity instanceof Exploitation) {
        return of<Exploitation>(entity);
      }
      return of<Exploitation>(null);
    }),
    replay()
  );

  /**
   * Observable on the related exploitation ACL.
   */
  private exploitation_acl$$: Observable<ACE[]> = this.exploitation$$.pipe(
    switchMap(exploitation => {
      if (isNil(exploitation)) {
        return of(null);
      }
      return exploitation.entity_acl$$;
    }),
    map(response => response?.acl || []),
    replay()
  );

  /**
   * Observable on the ID of the related exploitation.
   */
  private exploitation_owner_id$$: Observable<number> = this.exploitation$$.pipe(
    map(exploitation => exploitation?.user_id),
    distinctUntilRealChanged(),
    replay()
  );

  public no_minimal_access$$ = combineLatest({
    current_entity_type: this.entity_type$$,
    loading: this.is_updating_entity_acl$$.pipe(filter(loading => !loading)),
  }).pipe(
    tap(() => this._is_checking_minimum_rights$$.next(false)),
    switchMap(({ current_entity_type }) => {
      if (current_entity_type !== 'location') {
        return of(false);
      }

      this._is_checking_minimum_rights$$.next(true);
      return combineLatest({
        expl_acl: this.exploitation_acl$$,
        expl_owner_id: this.exploitation_owner_id$$,
        user_id: this.selected_user_id$$,
      }).pipe(
        map(({ expl_acl, expl_owner_id, user_id }) => {
          if (isEqual(expl_owner_id, user_id)) {
            return false;
          }
          // FIXME: Needs to load all of specific user's ACE
          const filtered_acl = (expl_acl || []).filter(ace => isEqual(ace.user_id, user_id));
          return filtered_acl.length === 0;
        })
      );
    }),
    tap(() => this._is_checking_minimum_rights$$.next(false)),
    distinctUntilRealChanged(),
    replay()
  );

  public giveMinimalAccessForCurrentUser(): void {
    if (this._is_updating_entity_acl$$.getValue()) {
      return;
    }

    this._is_updating_entity_acl$$.next(true);

    combineLatest({
      user_id: this.selected_user_id$$,
      expl: this.exploitation$$,
      expl_acl: this.exploitation_acl$$,
    })
      .pipe(
        switchMap(({ user_id, expl, expl_acl }) => {
          const minimal_ace: ACE = {
            cdate: new Date(),
            user_id: user_id,
            scope: 'read_minimal',
          };

          expl_acl.push(minimal_ace);
          return expl.updateACL({ acl: expl_acl });
        }),
        take(1)
      )
      .subscribe({
        next: () => this._is_updating_entity_acl$$.next(false),
        error: (error: unknown) => this._logger.error(error),
      });
  }

  // #endregion

  // #region -> (responsive management)

  public is_mobile$$ = this._breakpoints.observe([Breakpoints.Small, Breakpoints.XSmall]).pipe(
    map(results => results.matches),
    replay()
  );

  // #endregion
}
