import { isNil, find } from 'lodash-es';

import { ISchema } from 'ngx-schema-form';

import { replay, robustCombineLatest } from '@bg2app/tools/rxjs';
import { debounceTime, map, switchMap } from 'rxjs';
import { Observable, combineLatest, of } from 'rxjs';

import { UrlParamsService } from 'app/core/url-param.service';

import { GenericFilter } from './filter-elements';
import { ActiveFilterView } from './filter-elements';
import { Dictionary } from 'app/typings/core/interfaces';

export class AbstractFilterMngt<ApplyOnType> {
  // #region -> (model basics)

  constructor(public only_for: string[]) {}

  // #endregion

  /** */
  public all_filters: GenericFilter<any, ApplyOnType>[] = [];

  /** */
  public get_by_name(fname: string): GenericFilter<any, ApplyOnType> {
    return find(this.all_filters, filter => filter.name === fname);
  }

  public get_schema_for(filter_names: string[]): Observable<ISchema> {
    const filters_schemas$$ = filter_names
      .map(fname => this.get_by_name(fname))
      .filter(filter => !isNil(filter))
      .map(filter => filter.schema$$.pipe(map(schema => [filter.name, schema] as [string, ISchema])));
    return combineLatest(filters_schemas$$).pipe(
      map(schemas => {
        const schema: ISchema = {
          type: 'object',
          properties: {},
        };
        schemas.forEach(([fname, fschema]) => {
          schema.properties[fname] = fschema;
        });
        return schema;
      })
    );
  }

  public get_values_for(filter_names: string[]): Observable<ISchema> {
    const filters_values$$ = filter_names
      .map(fname => this.get_by_name(fname))
      .filter(filter => !isNil(filter))
      .map(filter => filter.value$$.pipe(map(value => [filter.name, value] as [string, ISchema])));
    return combineLatest(filters_values$$).pipe(
      map(values => {
        const value: any = {};
        values.forEach(([fname, fschema]) => {
          value[fname] = fschema;
        });
        return value;
      }),
      debounceTime(30),
      replay()
    );
  }

  public get_all_values$$() {
    const filters_values$$ = this.all_filters
      .filter(filter => !isNil(filter))
      .map(filter => filter.value$$.pipe(map(value => ({ value, name: filter.name }))));

    return robustCombineLatest(filters_values$$).pipe(
      map(filters =>
        filters.reduce((build: Dictionary<any>, filter) => {
          build[filter.name] = filter.value;
          return build;
        }, {})
      ),
      replay()
    );
  }

  // #region -> (active filters management)

  /**
   * Observes active filters.
   *
   * @public
   * @replay
   */
  public active_filters$$: Observable<ActiveFilterView[]> = of(null).pipe(
    switchMap(() => {
      const all_chips$$ = this.all_filters.map(filter => filter.chip$$);

      if (all_chips$$.length === 0) {
        return of([]);
      }

      return combineLatest(all_chips$$);
    }),
    map(chips => chips.filter(chip => !isNil(chip))),
    replay()
  );

  /**
   * Observes number of the active filters.
   *
   * @public
   * @replay
   */
  public active_filters__total$$ = this.active_filters$$.pipe(
    map(active_filters => active_filters?.length ?? 0),
    replay()
  );

  // #endregiom

  public update(_values: Dictionary<any>, only_for: string[] = null) {
    const filters = isNil(only_for) ? this.all_filters : only_for.map(fname => this.get_by_name(fname));
    filters.forEach(filter => {
      // console.log(filter.name, _values[filter.name]);
      const _value = _values[filter.name];
      filter.value = _value;
    });
  }

  public clear(fname: string) {
    const filter = this.get_by_name(fname);
    filter?.clear();
  }

  protected _url_params: UrlParamsService;

  public sync_url_params(url_params: UrlParamsService) {
    this._url_params = url_params;
    const sync_url_params$$ = this.all_filters.map(filter => filter.sync_url_params(this._url_params));
    return combineLatest(sync_url_params$$);
  }

  public apply(input: ApplyOnType): Observable<ApplyOnType> {
    let apply_pipeline$$ = of(input);
    this.all_filters.forEach(filter => {
      apply_pipeline$$ = apply_pipeline$$.pipe(switchMap(_input => filter.apply(_input)));
    });
    return apply_pipeline$$;
  }
}
