import { DeviceApi } from 'app/core';

import { map, Observable, of } from 'rxjs';

import { DeviceQueryParams } from 'app/core/api/device/device-api-service';

import { DRDevice } from './DRDevice';
import { DeviceCommunicationTechnology } from './enumerators/device-communication-technology.enum';
import { AnyOfDeviceJobData, AnyOfDeviceSecondaryData, DeviceRSSIData } from '../data';
import {
  DEVICE_BATTERY_TYPE,
  DEVICE_SIMPLIFIED_BATTERY_STATE,
  DEVICE_SIMPLIFIED_BATTERY_STATE_REASON,
  DeviceSimplifiedBatteryState,
} from './enumerators';
import { isNil } from 'lodash-es';

/** */
export class NADevice extends DRDevice {
  // #region -> (class basics)

  /** */
  constructor(protected deviceApi: DeviceApi, params?: DeviceQueryParams) {
    super(deviceApi, params);

    this.type = 'NA' as any;
  }

  // #endregion

  // #region battery state

  /** */
  /** */
  protected compute_battery_type$$(): Observable<DEVICE_BATTERY_TYPE> {
    return of(null);
  }

  protected get_battery_critical_vbat$$(): Observable<number> {
    return of(null);
  }

  /** */
  public get_battery_simplified_state$$(): Observable<DeviceSimplifiedBatteryState> {
    return this._get_default_battery_simplified_state$$.pipe(
      map(state => {
        if (!isNil(state)) {
          return state;
        }

        return {
          state: DEVICE_SIMPLIFIED_BATTERY_STATE.UNKNOWN,
          reason: DEVICE_SIMPLIFIED_BATTERY_STATE_REASON.UNKNOWN,
        };
      })
    );
  }

  // #endregion

  // #region -> (communication technology)

  /** */
  protected get_com_technology$$(): Observable<DeviceCommunicationTechnology[]> {
    return of(null);
  }

  // #endregion

  // #region -> (device timeseries)

  /** */
  public fetch_job_data$(start: Date = undefined, end: Date = undefined, step: string = undefined) {
    return super
      .requestTimeseries([], start, end, step)
      .pipe(map(response => <AnyOfDeviceJobData>{ points: response?.timeseries?.data ?? [] }));
  }

  /** */
  public fetch_secondary_data$(start?: Date, end?: Date, step?: string): Observable<AnyOfDeviceSecondaryData> {
    return of({
      points: [],
    });
  }

  /** */
  public fetch_rssi$(start?: Date, end?: Date, step?: string): Observable<DeviceRSSIData> {
    return of({
      points: [],
    });
  }

  // #endregion
}
