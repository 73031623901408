import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { BehaviorSubject, of, map, switchMap } from 'rxjs';

import { Location } from 'app/models';

@Component({
  selector: 'bg2-picon-location-entity',
  templateUrl: './picon-location-entity.component.html',
  styleUrls: ['./picon-location-entity.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PiconLocationEntityComponent {
  private _location$$ = new BehaviorSubject<Location>(null);
  @Input()
  set location(location: Location) {
    this._location$$.next(location);
  }

  public location$$ = this._location$$.asObservable();

  public has_apiary$$ = this._location$$.pipe(switchMap(location => (location ? location.has_apiary$$ : of(false))));

  public img_src$$ = this.has_apiary$$.pipe(
    map(has_apiary => `/assets/img/views/common/maps/${has_apiary ? 'location_marker.svg' : 'location_archived_marker.svg'}`)
  );
}
