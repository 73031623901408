<bg2-modal [fullscreen]="fullscreen || false" #modal>
  <bg2-modal-header [isBeta]="is_beta$$ | async">
    <div class="header">
      <span class="mdi mdi-library-plus mdi-36px"></span>
      <span class="title">
        <ng-container *ngIf="(event$$ | async); let event; defaultTitle">{{ event.type_i18n | translate }}</ng-container>
        <ng-template #defaultTitle>{{ 'VIEWS.MODALS.FORM.New' | translate }}&nbsp;{{ '#' + (input_event_type$$ | async) }}</ng-template>
      </span>
    </div>
    <div class="action-buttons">
      <doc-helper [page]="help_page$$ | async" [section]="help_link_section$$ | async" iconSize="36px"></doc-helper>
      <span class="mdi mdi-close-box mdi-36px" (click)="close()"></span>
    </div>
  </bg2-modal-header>

  <bg2-modal-content>
    <div class="error" *ngIf="error">{{error | translate}}</div>

    <bg2-event-form-inline
      [args]="args"
      [event]="event$$ | async"
      (isBeta)="is_beta = $event"
      (isValid)="setFormValid($event)"
      (isSchema)="setSchema($any($event))"
    >
    </bg2-event-form-inline>

    <div *ngIf="activateDailyRAZWarning && (form_valid$$ | async)" class="warning">
      {{ 'ALL.BETA.Warning all the modifications will be erased next night' | translate }}.
    </div>
  </bg2-modal-content>

  <bg2-modal-footer>
    <bg2-button [type]="'cancel'" [message]="'VIEWS.MODALS.FORM.Cancel' | translate" (btn_click)="close()"></bg2-button>

    <bg2-button
      [type]="'action'"
      [icon]="'mdi-content-save'"
      [message]="'VIEWS.MODALS.FORM.Save' | translate"
      [disabled]="(loading$$ | async) || (form_valid$$ | async) === false"
      [loading]="loading$$ | async"
      (btn_click)="submit()"
    ></bg2-button>
  </bg2-modal-footer>
</bg2-modal>
