<div class="bg2-map-popup-device-marker-component">
  <ng-container *ngIf="device$$ | async; let device">
    <div style="display: flex; flex-direction: column">
      <span>{{ device?.name }}</span>

      <ng-container *ngIf="device?.has_outdated_com?.outdated">
        <span>
          <span class="mdi mdi-clock-alert" style="color: red"></span>
          <b>
            {{ 'DEVICE.ALL.LEVEL.No com since [since_days]' | translate : { since_days: device?.has_outdated_com?.since | distanceToNow } }}
          </b>
        </span>
      </ng-container>

      <ng-container *ngIf="device?.last_contact">
        <span>
          <b>{{ 'VIEWS.MAP.SHARED.HTML_POPUP_MARKER.Last contact :' | translate }}</b>
          {{ device.last_contact | formatDate : 'short' }}
        </span>
      </ng-container>
      <ng-container *ngIf="device?.location?.timestamp">
        <span>
          <b>{{ 'VIEWS.MAP.SHARED.HTML_POPUP_MARKER.Position taken on:' | translate }}</b>
          {{ device.location.timestamp | formatDate : 'short' }}
        </span>
      </ng-container>
      <ng-container *ngIf="$any(device)['affectation']?.location; let location; else: nolocation">
        <span>
          <b>{{ 'VIEWS.MAP.SHARED.HTML_POPUP_MARKER.Current location:' | translate }}</b>
          <a
            [routerLink]="['', { outlets: { modal: ['location_details', { location_id: location.id }] } }]"
            [queryParamsHandling]="'preserve'"
          >
            {{ location.name }}
          </a>
        </span>
        <span *ngIf="!$any(device)['is_compatible'] && can_create_location$$ | async">
          <a
            href="javascript:void(0)"
            [routerLink]="[
              '',
              {
                outlets: {
                  modal: [
                    'new_entity',
                    {
                      etype: 'location',
                      args:
                        ({
                          static: {
                            position: {
                              latitude: device.location.latitude,
                              longitude: device.location.longitude
                            }
                          }
                        } | json)
                    }
                  ]
                }
              }
            ]"
            [queryParamsHandling]="'preserve'"
          >
            {{ 'VIEWS.MAP.SHARED.HTML_POPUP_MARKER.Create a location here' | translate }}
          </a>
        </span>
      </ng-container>
      <ng-template #nolocation>
        <a
          href="javascript:void(0)"
          *ngIf="can_create_location$$ | async"
          [routerLink]="[
            '',
            {
              outlets: {
                modal: [
                  'new_entity',
                  {
                    etype: 'location',
                    args:
                      ({
                        static: {
                          position: {
                            latitude: device.location.latitude,
                            longitude: device.location.longitude
                          }
                        }
                      } | json)
                  }
                ]
              }
            }
          ]"
          [queryParamsHandling]="'preserve'"
        >
          {{ 'VIEWS.MAP.SHARED.HTML_POPUP_MARKER.Create a location here' | translate }}
        </a>
      </ng-template>
    </div>
  </ng-container>
</div>
