import { isNil, isString } from 'lodash-es';

import { Dictionary } from 'app/typings/core/interfaces';

import { ZohoSearchCriteriaTemplate, ZohoSearchTermCriteria } from '../abstract';

/** */
const convert_search_term_criteria_to_string = (criteria: ZohoSearchTermCriteria, values: Dictionary<string>) => {
  const value = values?.[criteria?.replace_value] ?? null;

  if (isNil(value)) {
    throw new Error(`Nil value for criteria "${criteria}" is forbidden`);
  }

  let criteria_string = `(${criteria.prop}:${criteria.condition}:${value})`;

  return criteria_string;
};

/** */
const build_criteria_template = (criteria_template: ZohoSearchCriteriaTemplate, values: Dictionary<string>): string => {
  let criteria_string: string = '(';

  criteria_template.forEach(criteria => {
    if (isString(criteria)) {
      criteria_string += criteria;
    } else if (Array.isArray(criteria)) {
      criteria_string += build_criteria_template(criteria, values);
    } else {
      criteria_string += convert_search_term_criteria_to_string(criteria, values);
    }
  });

  criteria_string += ')';
  return criteria_string;
};

/** */
export const build_zoho_search_criteria = (criteria_template: ZohoSearchCriteriaTemplate, values: Dictionary<string>) => build_criteria_template(criteria_template, values);
