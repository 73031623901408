<div class="bg2-device-gprs-sparkline-component">
  <span *ngIf="show_title">
    {{ 'VIEWS.DEVICES.SHARED.CHARTS.ALL.DEVICE_GPRS_SPARKLINE.Cellular network level (over two years)' | translate }}
  </span>

  <div (resized)="onChartContainerResized($event)" [ngStyle]="{ height: height, width: '100%' }">
    <svg [id]="chart_factory.unique_id" [ngStyle]="{ height: height }"></svg>

    <ng-container *ngIf="is_device_is_beelive$$ | async">
      <bg2-loading-skeleton
        [theme]="{ height: '100%' }"
        [visible_message]="true"
        [has_loading_effect]="false"
        [string_model]="'DEVICE.ALL.DATA.Unavailable data' | translate"
      ></bg2-loading-skeleton>
    </ng-container>
  </div>

  <bg2-error-helper *ngIf="acl_error$$ | async; let error" [error]="error"></bg2-error-helper>

  <!-- <div *ngIf="(acl_error$$ | async) === null && (is_device_is_beelive$$ | async) === false && show_legend" class="chart-legends">
    <div class="legend legend-battery-change">
      <span class="legend__coloration"></span>

      <span class="legend__text">
        {{ 'VIEWS.DEVICES.SHARED.CHARTS.ALL.DEVICE_BATTERY_SPARKLINE.LEGEND.Battery change' | translate }}
      </span>
    </div>

    <div class="legend legend-minimal-working-voltage">
      <span class="legend__coloration"></span>

      <span class="legend__text">
        {{ 'VIEWS.DEVICES.SHARED.CHARTS.ALL.DEVICE_BATTERY_SPARKLINE.LEGEND.Minimal working voltage' | translate }}
      </span>
    </div>
  </div> -->
</div>
