<bg2-modal [fullscreen]="fullscreen || false" #modal>
  <bg2-modal-header>
    <div class="header">
      <span class="title">
        {{
          'VIEWS.DEVICES.DIALOGS_AND_MODALS.MODALS.DEVICE_CALIBRATION.DEVICE_CALIBRATION_TEMPERATURE_MODAL.Calibrate devices temperature'
            | translate
        }}
      </span>
    </div>

    <div class="action-buttons">
      <span class="mdi mdi-close-box mdi-36px" (click)="close()"></span>
    </div>
  </bg2-modal-header>

  <bg2-modal-content>
    <mat-stepper [orientation]="'vertical'" [linear]="true" #stepper>
      <!-- STEP : Check selected devices -->
      <mat-step [editable]="(is_saving_calibrations.value$$ | async) === false" [completed]="is_preliminary_check_valid$$ | async">
        <ng-template matStepLabel>
          {{
            'VIEWS.DEVICES.DIALOGS_AND_MODALS.MODALS.DEVICE_CALIBRATION.DEVICE_CALIBRATION_TEMPERATURE_MODAL.PRELIMINARY_CHECK.Preliminary checks'
              | translate
          }}
        </ng-template>

        <ng-template matStepContent>
          <div class="mat-step-container --preliminary-check">
            <div class="devices-list">
              <bg2-loading-skeleton
                *ngIf="is_running_preliminary_check.value$$ | async"
                [theme]="{ width: '100%', height: '100%' }"
              ></bg2-loading-skeleton>

              <table>
                <thead>
                  <th>{{ 'DEVICE.ALL.COMMON.Device' | translate }}</th>
                  <th>{{ 'VIEWS.APIARY.SHARED.WIDGETS.APIARY_LAST_DATA.APIARY_LAST_DATA_CARD.Last com' | translate }}</th>
                  <th>{{ 'ALL.COMMON.Is valid ?' | translate }}</th>
                </thead>

                <tbody>
                  <ng-container *ngFor="let device_x_validity of check_devices_validity$$ | async">
                    <tr>
                      <!-- Device identity -->
                      <td *ngLet="device_x_validity?.device; let device" rowspan="1" class="device-identity">
                        {{ device?.name }} ({{ device?.imei$$ | async }})
                      </td>

                      <!-- Device last communication -->
                      <td>
                        <bg2-last-com [device]="device_x_validity?.device" [prefix]="''"></bg2-last-com>
                      </td>

                      <!-- Validation -->
                      <td class="validity-check">
                        <div
                          *ngLet="device_x_validity?.validity; let validity"
                          class=""
                          [ngClass]="{
                            '--success': validity?.is_valid === 'success',
                            '--warning': validity?.is_valid === 'warning',
                            '--error': validity?.is_valid === 'error'
                          }"
                        >
                          <span
                            class="mdi mdi-24px"
                            [ngClass]="{
                              'mdi-alert': validity?.is_valid === 'warning',
                              'mdi-close-thick': validity?.is_valid === 'error',
                              'mdi-check-bold': validity?.is_valid === 'success'
                            }"
                          ></span>

                          <span *ngIf="validity?.is_valid === 'success'">
                            {{
                              'VIEWS.DEVICES.DIALOGS_AND_MODALS.MODALS.DEVICE_CALIBRATION.DEVICE_CALIBRATION_TEMPERATURE_MODAL.No error detected'
                                | translate
                            }}
                          </span>
                          <span *ngIf="validity?.is_valid === 'warning'">{{ validity?.reason | translate }}</span>
                          <span *ngIf="validity?.is_valid === 'error'">{{ validity?.reason | translate }}</span>
                        </div>
                      </td>
                    </tr>
                  </ng-container>
                </tbody>
              </table>
            </div>

            <div class="step-actions">
              <bg2-button
                [type]="'action'"
                [icon]="'mdi-pan-right'"
                [message]="'ALL.COMMON.Next step' | translate"
                [disabled]="(is_preliminary_check_valid$$ | async) === false"
                (btn_click)="next_step(stepper)"
              ></bg2-button>
            </div>
          </div>
        </ng-template>
      </mat-step>

      <!-- STEP : Select datetime and check data -->
      <mat-step [editable]="(is_saving_calibrations.value$$ | async) === false" [completed]="step_datetime__is_valid$$ | async">
        <ng-template matStepLabel>
          {{
            'VIEWS.DEVICES.DIALOGS_AND_MODALS.MODALS.DEVICE_CALIBRATION.DEVICE_CALIBRATION_TEMPERATURE_MODAL.DATA_SELECTION.Data selection'
              | translate
          }}
        </ng-template>

        <ng-template matStepContent>
          <div class="mat-step-container --datetime-selection">
            <bg2-form-overlay
              [source_schema]="datetime_select_schema"
              [source_model]="step_datetime__form_model.value$$ | async"
              (when_form_data_update)="step_datetime__form_model.value = $event"
              (when_form_valid_update)="step_datetime__form_validity.value = $event"
            ></bg2-form-overlay>

            <div class="preview-selected-data">
              <bg2-loading-skeleton
                *ngIf="is_running_data_validation.value$$ | async"
                [theme]="{ width: '100%', height: '100%' }"
                [string_model]="
                  'VIEWS.DEVICES.DIALOGS_AND_MODALS.MODALS.DEVICE_CALIBRATION.DEVICE_CALIBRATION_TEMPERATURE_MODAL.DATA_SELECTION.Loading chart data'
                    | translate
                "
                [visible_message]="true"
                [has_loading_effect]="true"
              ></bg2-loading-skeleton>

              <bg2-device-calibration-temperature-selected-chart
                [data]="selected_devices_temperature$$ | async"
                (exclude_device_from_selection)="select_device_for_calibration(false, $event)"
              ></bg2-device-calibration-temperature-selected-chart>
            </div>

            <div class="devices-list">
              <bg2-loading-skeleton
                *ngIf="is_running_data_validation.value$$ | async"
                [theme]="{ width: '100%', height: '100%' }"
              ></bg2-loading-skeleton>

              <table>
                <thead>
                  <th>{{ 'DEVICE.ALL.COMMON.Device' | translate }}</th>

                  <th>
                    {{
                      'VIEWS.DEVICES.DIALOGS_AND_MODALS.MODALS.DEVICE_CALIBRATION.DEVICE_CALIBRATION_TEMPERATURE_MODAL.DATA_SELECTION.Measure A'
                        | translate
                    }}
                  </th>

                  <th>
                    {{
                      'VIEWS.DEVICES.DIALOGS_AND_MODALS.MODALS.DEVICE_CALIBRATION.DEVICE_CALIBRATION_TEMPERATURE_MODAL.DATA_SELECTION.Measure B'
                        | translate
                    }}
                  </th>

                  <th>{{ 'ALL.COMMON.Is valid ?' | translate }}</th>

                  <th>{{
                    'VIEWS.DEVICES.DIALOGS_AND_MODALS.MODALS.DEVICE_CALIBRATION.DEVICE_CALIBRATION_TEMPERATURE_MODAL.DATA_SELECTION.Calibrate this device ?'
                      | translate
                  }}</th>
                </thead>

                <tbody>
                  <ng-container *ngFor="let devices_x_weight_at_time of devices_x_weight_at_time$$ | async">
                    <tr>
                      <!-- Device identity -->
                      <td *ngLet="devices_x_weight_at_time?.device?.device; let device" class="device-identity" rowspan="2">
                        {{ device?.name }} ({{ device?.imei$$ | async }})
                      </td>

                      <!-- Measure A -->
                      <td class="measurement">
                        <div>
                          <!-- Measurement date -->
                          <div class="measurement-date">
                            <span>{{ (devices_x_weight_at_time?.search_method_time_a.date | formatDate : 'full') ?? '-' }}</span>
                          </div>

                          <!-- Weight sensor 1 -->
                          <div class="measurement-sensor">
                            <div
                              class="measurement-sensor-desc"
                              [popper]="
                                'VIEWS.DEVICES.DIALOGS_AND_MODALS.MODALS.DEVICE_CALIBRATION.DEVICE_CALIBRATION_TEMPERATURE_MODAL.TOOLTIP.Raw weight of sensor 1 at measure A'
                                  | translate
                              "
                            >
                              <img src="assets/picons/devices/weight-w1.svg" alt="" />
                              <span>RAW</span>
                            </div>

                            <mat-divider></mat-divider>
                            <!-- <span class="mdi mdi-call-split mdi-24px mdi-rotate-90"></span> -->

                            <div class="measurement-sensor-value">
                              <!-- <span>
                                <span>(RAW)</span>
                                <span style="flex: 1"></span>
                                <b>{{ (devices_x_weight_at_time?.weight_1_raw?.time_a | fixed : 10) ?? '-' }}</b>
                                <span>kg</span>
                              </span> -->

                              <span>
                                <!-- <span>(CALIB)</span>
                                <span style="flex: 1"></span> -->
                                <b>{{ (devices_x_weight_at_time?.weight_1_raw?.time_a | fixed : 2) ?? '-' }}</b>
                                <span>kg</span>
                              </span>
                            </div>
                          </div>

                          <!-- Weight sensor 2 -->
                          <div class="measurement-sensor">
                            <div
                              class="measurement-sensor-desc"
                              [popper]="
                                'VIEWS.DEVICES.DIALOGS_AND_MODALS.MODALS.DEVICE_CALIBRATION.DEVICE_CALIBRATION_TEMPERATURE_MODAL.TOOLTIP.Raw weight of sensor 2 at measure A'
                                  | translate
                              "
                            >
                              <img src="assets/picons/devices/weight-w2.svg" alt="" />
                              <span>RAW</span>
                            </div>

                            <mat-divider></mat-divider>
                            <!-- <span class="mdi mdi-call-split mdi-24px mdi-rotate-90"></span> -->

                            <div class="measurement-sensor-value">
                              <!-- <span>
                                <span>(RAW)</span>
                                <span style="flex: 1"></span>
                                <b>{{ (devices_x_weight_at_time?.weight_2_raw?.time_a | fixed : 10) ?? '-' }}</b>
                                <span>kg</span>
                              </span> -->

                              <span>
                                <!-- <span>(CALIB)</span>
                                <span style="flex: 1"></span> -->
                                <b>{{ (devices_x_weight_at_time?.weight_2_raw?.time_a | fixed : 2) ?? '-' }}</b>
                                <span>kg</span>
                              </span>
                            </div>
                          </div>

                          <!-- Temperature -->
                          <div class="measurement-sensor">
                            <div
                              class="measurement-sensor-desc"
                              [popper]="
                                'VIEWS.DEVICES.DIALOGS_AND_MODALS.MODALS.DEVICE_CALIBRATION.DEVICE_CALIBRATION_TEMPERATURE_MODAL.TOOLTIP.Temperature of sensors at measure A'
                                  | translate
                              "
                            >
                              <span class="mdi mdi-thermometer mdi-24px"></span>
                            </div>

                            <mat-divider></mat-divider>

                            <div class="measurement-sensor-value">
                              <span>
                                <b>{{ (devices_x_weight_at_time?.temperature?.time_a | fixed : 2) ?? '-' }}</b>
                                <span>°C</span>
                              </span>
                            </div>
                          </div>
                        </div>
                      </td>

                      <!-- Measure B -->
                      <td class="measurement">
                        <div>
                          <!-- Measurement date -->
                          <div class="measurement-date">
                            <span>{{ (devices_x_weight_at_time?.search_method_time_b.date | formatDate : 'full') ?? '-' }}</span>
                          </div>

                          <!-- Weight sensor 1 -->
                          <div class="measurement-sensor">
                            <div
                              class="measurement-sensor-desc"
                              [popper]="
                                'VIEWS.DEVICES.DIALOGS_AND_MODALS.MODALS.DEVICE_CALIBRATION.DEVICE_CALIBRATION_TEMPERATURE_MODAL.TOOLTIP.Raw weight of sensor 1 at measure B'
                                  | translate
                              "
                            >
                              <img src="assets/picons/devices/weight-w1.svg" alt="" />
                              <span>RAW</span>
                            </div>

                            <mat-divider></mat-divider>
                            <!-- <span class="mdi mdi-call-split mdi-24px mdi-rotate-90"></span> -->

                            <div class="measurement-sensor-value">
                              <!-- <span>
                                <span>(RAW)</span>
                                <span style="flex: 1"></span>
                                <b>{{ (devices_x_weight_at_time?.weight_1_raw?.time_b | fixed : 10) ?? '-' }}</b>
                                <span>kg</span>
                              </span> -->

                              <span>
                                <!-- <span>(CALIB)</span>
                                <span style="flex: 1"></span> -->
                                <b>{{ (devices_x_weight_at_time?.weight_1_raw?.time_b | fixed : 2) ?? '-' }}</b>
                                <span>kg</span>
                              </span>
                            </div>
                          </div>

                          <!-- Weight sensor 2 -->
                          <div class="measurement-sensor">
                            <div
                              class="measurement-sensor-desc"
                              [popper]="
                                'VIEWS.DEVICES.DIALOGS_AND_MODALS.MODALS.DEVICE_CALIBRATION.DEVICE_CALIBRATION_TEMPERATURE_MODAL.TOOLTIP.Raw weight of sensor 2 at measure B'
                                  | translate
                              "
                            >
                              <img src="assets/picons/devices/weight-w2.svg" alt="" />
                              <span>RAW</span>
                            </div>

                            <mat-divider></mat-divider>
                            <!-- <span class="mdi mdi-call-split mdi-24px mdi-rotate-90"></span> -->

                            <div class="measurement-sensor-value">
                              <!-- <span>
                                <span>(RAW)</span>
                                <span style="flex: 1"></span>
                                <b>{{ (devices_x_weight_at_time?.weight_2_raw?.time_b | fixed : 10) ?? '-' }}</b>
                                <span>kg</span>
                              </span> -->

                              <span>
                                <!-- <span>(CALIB)</span>
                                <span style="flex: 1"></span> -->
                                <b>{{ (devices_x_weight_at_time?.weight_2_raw?.time_b | fixed : 2) ?? '-' }}</b>
                                <span>kg</span>
                              </span>
                            </div>
                          </div>

                          <!-- Temperature -->
                          <div class="measurement-sensor">
                            <div
                              class="measurement-sensor-desc"
                              [popper]="
                                'VIEWS.DEVICES.DIALOGS_AND_MODALS.MODALS.DEVICE_CALIBRATION.DEVICE_CALIBRATION_TEMPERATURE_MODAL.TOOLTIP.Temperature of sensors at measure B'
                                  | translate
                              "
                            >
                              <span class="mdi mdi-thermometer mdi-24px"></span>
                            </div>

                            <mat-divider></mat-divider>

                            <div class="measurement-sensor-value">
                              <span>
                                <b>{{ (devices_x_weight_at_time?.temperature?.time_b | fixed : 2) ?? '-' }}</b>
                                <span>°C</span>
                              </span>
                            </div>
                          </div>
                        </div>
                      </td>

                      <!-- Validation -->
                      <td rowspan="2" class="validity-check">
                        <div
                          *ngLet="devices_x_weight_at_time?.validity; let validity"
                          class=""
                          [ngClass]="{
                            '--success': validity?.is_valid === 'success',
                            '--warning': validity?.is_valid === 'warning',
                            '--error': validity?.is_valid === 'error'
                          }"
                        >
                          <span
                            class="mdi mdi-24px"
                            [ngClass]="{
                              'mdi-alert': validity?.is_valid === 'warning',
                              'mdi-close-thick': validity?.is_valid === 'error',
                              'mdi-check-bold': validity?.is_valid === 'success'
                            }"
                          ></span>

                          <span *ngIf="validity?.is_valid === 'success'">
                            {{
                              'VIEWS.DEVICES.DIALOGS_AND_MODALS.MODALS.DEVICE_CALIBRATION.DEVICE_CALIBRATION_TEMPERATURE_MODAL.No error detected'
                                | translate
                            }}
                          </span>
                          <span *ngIf="validity?.is_valid === 'warning'">{{ validity?.reason | translate }}</span>
                          <span *ngIf="validity?.is_valid === 'error'">{{ validity?.reason | translate }}</span>
                        </div>
                      </td>

                      <td rowspan="2" style="width: 0">
                        <mat-checkbox
                          [color]="'primary'"
                          [disabled]="devices_x_weight_at_time?.validity?.is_valid === 'error'"
                          [checked]="
                            devices_x_weight_at_time?.validity?.is_valid === 'error'
                              ? false
                              : (is_device_selected_for_calibration$$(devices_x_weight_at_time?.device?.device?.imei) | async)
                          "
                          (change)="select_device_for_calibration($event.checked, devices_x_weight_at_time?.device?.device?.imei)"
                        ></mat-checkbox>
                      </td>
                    </tr>

                    <tr>
                      <!-- Delta -->
                      <td colspan="2" class="measurement">
                        <div>
                          <!-- Calibration temperature -->
                          <div class="measurement-sensor">
                            <div
                              class="measurement-sensor-desc"
                              [popper]="
                                'VIEWS.DEVICES.DIALOGS_AND_MODALS.MODALS.DEVICE_CALIBRATION.DEVICE_CALIBRATION_TEMPERATURE_MODAL.TOOLTIP.Calibration temperature of device (extracted from configuration)'
                                  | translate
                              "
                            >
                              <span class="mdi mdi-thermometer mdi-24px"></span>
                              <span>T<span style="vertical-align: sub">c</span></span>
                            </div>

                            <mat-divider></mat-divider>

                            <div
                              class="measurement-sensor-value clickable"
                              [popperTrigger]="$any('click')"
                              [popperTimeoutAfterShow]="1000"
                              [popper]="'ALL.ACTIONS.Copied !' | translate"
                              (click)="clipboard.copy(devices_x_weight_at_time?.configuration?.server?.weight_1_calib_temp?.toString())"
                            >
                              <span>
                                <b>{{ (devices_x_weight_at_time?.configuration?.server?.weight_1_calib_temp | fixed : 2) ?? '-' }}</b>
                                <span>°C</span>
                                <span class="mdi mdi-content-copy mdi-24px"></span>
                              </span>
                            </div>
                          </div>

                          <!-- Delta weight 1 -->
                          <div class="measurement-sensor">
                            <div
                              class="measurement-sensor-desc"
                              [popper]="
                                'VIEWS.DEVICES.DIALOGS_AND_MODALS.MODALS.DEVICE_CALIBRATION.DEVICE_CALIBRATION_TEMPERATURE_MODAL.TOOLTIP.Weight difference of sensor 1 (after calibration in weight of each raw weight)'
                                  | translate
                              "
                            >
                              <span class="mdi mdi-delta mdi-24px"></span>
                              <img src="assets/picons/devices/weight-w1.svg" alt="" />
                            </div>

                            <mat-divider></mat-divider>

                            <div
                              class="measurement-sensor-value clickable"
                              [popperTrigger]="$any('click')"
                              [popperTimeoutAfterShow]="1000"
                              [popper]="'ALL.ACTIONS.Copied !' | translate"
                              (click)="clipboard.copy(devices_x_weight_at_time?.weight_1_raw?.delta?.toString())"
                            >
                              <span>
                                <b>{{ (devices_x_weight_at_time?.weight_1_raw?.delta | fixed : 2) ?? '-' }}</b>
                                <span>g</span>
                                <span class="mdi mdi-content-copy mdi-24px"></span>
                              </span>
                            </div>
                          </div>

                          <!-- Delta weight 2 -->
                          <div class="measurement-sensor">
                            <div
                              class="measurement-sensor-desc"
                              [popper]="
                                'VIEWS.DEVICES.DIALOGS_AND_MODALS.MODALS.DEVICE_CALIBRATION.DEVICE_CALIBRATION_TEMPERATURE_MODAL.TOOLTIP.Weight difference of sensor 2 (after calibration in weight of each raw weight)'
                                  | translate
                              "
                            >
                              <span class="mdi mdi-delta mdi-24px"></span>
                              <img src="assets/picons/devices/weight-w2.svg" alt="" />
                            </div>

                            <mat-divider></mat-divider>

                            <div
                              class="measurement-sensor-value clickable"
                              [popperTrigger]="$any('click')"
                              [popperTimeoutAfterShow]="1000"
                              [popper]="'ALL.ACTIONS.Copied !' | translate"
                              (click)="clipboard.copy(devices_x_weight_at_time?.weight_2_raw?.delta?.toString())"
                            >
                              <span>
                                <b>{{ (devices_x_weight_at_time?.weight_2_raw?.delta | fixed : 2) ?? '-' }}</b>
                                <span>g</span>
                                <span class="mdi mdi-content-copy mdi-24px"></span>
                              </span>
                            </div>
                          </div>

                          <!-- Delta temperature -->
                          <div class="measurement-sensor">
                            <div
                              class="measurement-sensor-desc"
                              [popper]="
                                'VIEWS.DEVICES.DIALOGS_AND_MODALS.MODALS.DEVICE_CALIBRATION.DEVICE_CALIBRATION_TEMPERATURE_MODAL.TOOLTIP.Temperature difference between measure B and measure A'
                                  | translate
                              "
                            >
                              <span class="mdi mdi-delta mdi-24px"></span>
                              <span class="mdi mdi-thermometer mdi-24px"></span>
                            </div>

                            <mat-divider></mat-divider>

                            <div
                              class="measurement-sensor-value clickable"
                              [popperTrigger]="$any('click')"
                              [popperTimeoutAfterShow]="1000"
                              [popper]="'ALL.ACTIONS.Copied !' | translate"
                              (click)="clipboard.copy(devices_x_weight_at_time?.temperature?.delta?.toString())"
                            >
                              <span>
                                <b>{{ (devices_x_weight_at_time?.temperature?.delta | fixed : 2) ?? '-' }}</b>
                                <span>°C</span>
                                <span class="mdi mdi-content-copy mdi-24px"></span>
                              </span>
                            </div>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </ng-container>
                </tbody>
              </table>
            </div>

            <div class="step-actions">
              <bg2-button
                [type]="'action'"
                [icon]="'mdi-pan-left'"
                [message]="'ALL.COMMON.Previous step' | translate"
                (btn_click)="previous_step(stepper)"
              ></bg2-button>

              <bg2-button
                [type]="'action'"
                [icon]="'mdi-pan-right'"
                [message]="'ALL.COMMON.Next step' | translate"
                [disabled]="(step_datetime__is_valid$$ | async) === false"
                (btn_click)="next_step(stepper)"
              ></bg2-button>
            </div>
          </div>
        </ng-template>
      </mat-step>

      <!-- STEP : Compute calibrations -->
      <mat-step [editable]="(is_saving_calibrations.value$$ | async) === false" [completed]="step_computation__is_valid$$ | async">
        <ng-template matStepLabel>
          {{
            'VIEWS.DEVICES.DIALOGS_AND_MODALS.MODALS.DEVICE_CALIBRATION.DEVICE_CALIBRATION_TEMPERATURE_MODAL.DATA_COMPUTE.Data compute'
              | translate
          }}
        </ng-template>

        <ng-template matStepContent>
          <div class="mat-step-container --calibration-computation">
            <div *ngLet="calibration_results$$ | async; let calibration_results" class="devices-list">
              <table>
                <thead>
                  <th>{{ 'DEVICE.ALL.COMMON.Device' | translate }}</th>
                  <th>W1</th>
                  <th>W2</th>
                  <th>
                    {{
                      'VIEWS.DEVICES.DIALOGS_AND_MODALS.MODALS.DEVICE_CALIBRATION.DEVICE_CALIBRATION_TEMPERATURE_MODAL.Apply calibration ?'
                        | translate
                    }}
                  </th>
                </thead>

                <tbody>
                  <ng-container *ngFor="let result of calibration_results">
                    <tr>
                      <!-- Device identity -->
                      <td *ngLet="result?.device; let device" rowspan="3" class="device-identity">
                        {{ device?.name }} ({{ device?.imei$$ | async }})
                      </td>

                      <td colspan="2" class="measurement">
                        <div>
                          <!-- Temperature calibration -->
                          <div class="measurement-sensor">
                            <div class="measurement-sensor-desc">
                              <span class="mdi mdi-thermometer mdi-24px"></span>
                              <span>T<span style="vertical-align: sub">c</span></span>
                            </div>

                            <mat-divider></mat-divider>

                            <div
                              class="measurement-sensor-value clickable"
                              [popperTrigger]="$any('click')"
                              [popperTimeoutAfterShow]="1000"
                              [popper]="'ALL.ACTIONS.Copied !' | translate"
                              (click)="clipboard.copy(result?.coef_alpha_1?.formula?.temperature_calibration?.toString())"
                            >
                              <span>
                                <b>{{ (result?.coef_alpha_1?.formula?.temperature_calibration | fixed : 2) ?? '-' }}</b>
                                <span>°C</span>
                                <span class="mdi mdi-content-copy mdi-24px"></span>
                              </span>
                            </div>
                          </div>
                        </div>
                      </td>

                      <td rowspan="3" style="width: 0">
                        <mat-checkbox
                          [color]="'primary'"
                          [checked]="apply_calibration_to_device$$(result?.device?.imei) | async"
                          (change)="apply_calibration_to_device($event.checked, result?.device?.imei)"
                        ></mat-checkbox>
                      </td>
                    </tr>

                    <tr>
                      <td class="measurement">
                        <div>
                          <!-- Linear function -->
                          <!-- <div class="measurement-sensor">
                            <div class="measurement-sensor-desc">
                              <span class="mdi mdi-function-variant mdi-24px"></span>
                              <span>P<span style="vertical-align: sub">w1</span>(T)</span>
                            </div>

                            <mat-divider></mat-divider>

                            <div
                              class="measurement-sensor-value clickable"
                              [popperTrigger]="$any('click')"
                              [popperTimeoutAfterShow]="1000"
                              [popper]="'ALL.ACTIONS.Copied !' | translate"
                              (click)="
                                clipboard.copy(
                                  result?.coef_alpha_1?.formula?.constant_a?.toString() +
                                    ' * T + ' +
                                    result?.coef_alpha_1?.formula?.constant_b?.toString()
                                )
                              "
                            >
                              <span>
                                <b>
                                  {{ (result?.coef_alpha_1?.formula?.constant_a | fixed : 5) ?? '-' }} * T +
                                  {{ (result?.coef_alpha_1?.formula?.constant_b | fixed : 5) ?? '-' }}
                                </b>
                                <span class="mdi mdi-content-copy mdi-24px"></span>
                              </span>
                            </div>
                          </div> -->

                          <!-- Weight calibration -->
                          <div class="measurement-sensor">
                            <div
                              class="measurement-sensor-desc"
                              [popper]="
                                'VIEWS.DEVICES.DIALOGS_AND_MODALS.MODALS.DEVICE_CALIBRATION.DEVICE_CALIBRATION_TEMPERATURE_MODAL.TOOLTIP.Calibration weight of sensor 1'
                                  | translate
                              "
                            >
                              <img src="assets/picons/devices/weight-w1.svg" alt="" />
                              <span>P<span style="vertical-align: sub">C</span></span>
                            </div>

                            <mat-divider></mat-divider>

                            <div
                              class="measurement-sensor-value clickable"
                              [popperTrigger]="$any('click')"
                              [popperTimeoutAfterShow]="1000"
                              [popper]="'ALL.ACTIONS.Copied !' | translate"
                              (click)="clipboard.copy(result?.coef_alpha_1?.formula?.weight_calibration_kg?.toString())"
                            >
                              <span>
                                <b>{{ (result?.coef_alpha_1?.formula?.weight_calibration_kg | fixed : 4) ?? '-' }}</b>
                                <span>kg</span>
                                <span class="mdi mdi-content-copy mdi-24px"></span>
                              </span>
                            </div>
                          </div>

                          <!-- Weight variation -->
                          <!-- <div class="measurement-sensor">
                            <div class="measurement-sensor-desc">
                              <span class="mdi mdi-swap-horizontal mdi-24px"></span>
                              <span>W1<span style="vertical-align: sub">var</span></span>
                            </div>

                            <mat-divider></mat-divider>

                            <div
                              class="measurement-sensor-value clickable"
                              [popperTrigger]="$any('click')"
                              [popperTimeoutAfterShow]="1000"
                              [popper]="'ALL.ACTIONS.Copied !' | translate"
                              (click)="clipboard.copy(result?.coef_alpha_1?.formula?.weight_variation?.toString())"
                            >
                              <span>
                                <b>{{ (result?.coef_alpha_1?.formula?.weight_variation | fixed : 5) ?? '-' }}</b>
                                <span>g/°C</span>
                                <span class="mdi mdi-content-copy mdi-24px"></span>
                              </span>
                            </div>
                          </div> -->

                          <!-- Coef alpha -->
                          <div class="measurement-sensor">
                            <div
                              class="measurement-sensor-desc"
                              [popper]="
                                'VIEWS.DEVICES.DIALOGS_AND_MODALS.MODALS.DEVICE_CALIBRATION.DEVICE_CALIBRATION_TEMPERATURE_MODAL.TOOLTIP.Correction coefficient of sensor 1'
                                  | translate
                              "
                            >
                              <span class="mdi mdi-alpha mdi-24px"></span>
                              <span>a<span style="vertical-align: sub">w1</span></span>
                            </div>

                            <mat-divider></mat-divider>

                            <div
                              class="measurement-sensor-value clickable"
                              [popperTrigger]="$any('click')"
                              [popperTimeoutAfterShow]="1000"
                              [popper]="'ALL.ACTIONS.Copied !' | translate"
                              (click)="clipboard.copy(result?.coef_alpha_1?.result?.toString())"
                            >
                              <span>
                                <b>
                                  {{ (result?.coef_alpha_1?.result * 1000 | fixed : 4) ?? '-' }}
                                </b>
                                <span>g/°C/kg</span>
                                <span class="mdi mdi-content-copy mdi-24px"></span>
                              </span>
                            </div>
                          </div>

                          <!-- Weight calibrated in temperature -->
                          <div class="measurement-sensor">
                            <div
                              class="measurement-sensor-desc"
                              [popper]="
                                'VIEWS.DEVICES.DIALOGS_AND_MODALS.MODALS.DEVICE_CALIBRATION.DEVICE_CALIBRATION_TEMPERATURE_MODAL.TOOLTIP.Corrected weight after calibration in temperature (applied to raw weight after calibration in weight)'
                                  | translate
                              "
                            >
                              <img src="assets/picons/devices/weight-w1.svg" alt="" />
                              <span>P<span style="vertical-align: sub">R</span></span>
                            </div>

                            <mat-divider></mat-divider>

                            <div
                              class="measurement-sensor-value clickable"
                              [popperTrigger]="$any('click')"
                              [popperTimeoutAfterShow]="1000"
                              [popper]="'ALL.ACTIONS.Copied !' | translate"
                              (click)="clipboard.copy(result?.coef_alpha_1?.corrected?.corrected_measure_A?.toString())"
                            >
                              <span>
                                <b>{{ (result?.coef_alpha_1?.corrected?.corrected_measure_A | fixed : 4) ?? '-' }}</b>
                                <span>kg</span>
                                <span class="mdi mdi-content-copy mdi-24px"></span>
                              </span>
                            </div>
                          </div>
                        </div>
                      </td>

                      <td class="measurement">
                        <div>
                          <!-- Linear function -->
                          <!-- <div class="measurement-sensor">
                            <div class="measurement-sensor-desc">
                              <span class="mdi mdi-function-variant mdi-24px"></span>
                              <span>P<span style="vertical-align: sub">w2</span>(T)</span>
                            </div>

                            <mat-divider></mat-divider>

                            <div
                              class="measurement-sensor-value clickable"
                              [popperTrigger]="$any('click')"
                              [popperTimeoutAfterShow]="1000"
                              [popper]="'ALL.ACTIONS.Copied !' | translate"
                              (click)="
                                clipboard.copy(
                                  result?.coef_alpha_2?.formula?.constant_a?.toString() +
                                    ' * T + ' +
                                    result?.coef_alpha_2?.formula?.constant_b?.toString()
                                )
                              "
                            >
                              <span>
                                <b>
                                  {{ (result?.coef_alpha_2?.formula?.constant_a | fixed : 5) ?? '-' }} * T +
                                  {{ (result?.coef_alpha_2?.formula?.constant_b | fixed : 5) ?? '-' }}
                                </b>
                                <span class="mdi mdi-content-copy mdi-24px"></span>
                              </span>
                            </div>
                          </div> -->

                          <!-- Weight calibration -->
                          <div class="measurement-sensor">
                            <div
                              class="measurement-sensor-desc"
                              [popper]="
                                'VIEWS.DEVICES.DIALOGS_AND_MODALS.MODALS.DEVICE_CALIBRATION.DEVICE_CALIBRATION_TEMPERATURE_MODAL.TOOLTIP.Calibration weight of sensor 2'
                                  | translate
                              "
                            >
                              <img src="assets/picons/devices/weight-w2.svg" alt="" />
                              <span>P<span style="vertical-align: sub">C</span></span>
                            </div>

                            <mat-divider></mat-divider>

                            <div
                              class="measurement-sensor-value clickable"
                              [popperTrigger]="$any('click')"
                              [popperTimeoutAfterShow]="1000"
                              [popper]="'ALL.ACTIONS.Copied !' | translate"
                              (click)="clipboard.copy(result?.coef_alpha_2?.formula?.weight_calibration_kg?.toString())"
                            >
                              <span>
                                <b>{{ (result?.coef_alpha_2?.formula?.weight_calibration_kg | fixed : 4) ?? '-' }}</b>
                                <span>kg</span>
                                <span class="mdi mdi-content-copy mdi-24px"></span>
                              </span>
                            </div>
                          </div>

                          <!-- Weight variation -->
                          <!-- <div class="measurement-sensor">
                            <div class="measurement-sensor-desc">
                              <span class="mdi mdi-swap-horizontal mdi-24px"></span>
                              <span>W2<span style="vertical-align: sub">var</span></span>
                            </div>

                            <mat-divider></mat-divider>

                            <div
                              class="measurement-sensor-value clickable"
                              [popperTrigger]="$any('click')"
                              [popperTimeoutAfterShow]="1000"
                              [popper]="'ALL.ACTIONS.Copied !' | translate"
                              (click)="clipboard.copy(result?.coef_alpha_2?.formula?.weight_variation?.toString())"
                            >
                              <span>
                                <b>{{ (result?.coef_alpha_2?.formula?.weight_variation | fixed : 5) ?? '-' }}</b>
                                <span>g/°C</span>
                                <span class="mdi mdi-content-copy mdi-24px"></span>
                              </span>
                            </div>
                          </div> -->

                          <!-- Coef alpha -->
                          <div class="measurement-sensor">
                            <div
                              class="measurement-sensor-desc"
                              [popper]="
                                'VIEWS.DEVICES.DIALOGS_AND_MODALS.MODALS.DEVICE_CALIBRATION.DEVICE_CALIBRATION_TEMPERATURE_MODAL.TOOLTIP.Correction coefficient of sensor 2'
                                  | translate
                              "
                            >
                              <span class="mdi mdi-alpha mdi-24px"></span>
                              <span>a<span style="vertical-align: sub">w2</span></span>
                            </div>

                            <mat-divider></mat-divider>

                            <div
                              class="measurement-sensor-value clickable"
                              [popperTrigger]="$any('click')"
                              [popperTimeoutAfterShow]="1000"
                              [popper]="'ALL.ACTIONS.Copied !' | translate"
                              (click)="clipboard.copy(result?.coef_alpha_2?.result?.toString())"
                            >
                              <span>
                                <b>
                                  {{ (result?.coef_alpha_2?.result * 1000 | fixed : 4) ?? '-' }}
                                </b>
                                <span>g/°C/kg</span>
                                <span class="mdi mdi-content-copy mdi-24px"></span>
                              </span>
                            </div>
                          </div>

                          <!-- Weight calibrated in temperature -->
                          <div class="measurement-sensor">
                            <div
                              class="measurement-sensor-desc"
                              [popper]="
                                'VIEWS.DEVICES.DIALOGS_AND_MODALS.MODALS.DEVICE_CALIBRATION.DEVICE_CALIBRATION_TEMPERATURE_MODAL.TOOLTIP.Corrected weight after calibration in temperature (applied to raw weight after calibration in weight)'
                                  | translate
                              "
                            >
                              <img src="assets/picons/devices/weight-w2.svg" alt="" />
                              <span>P<span style="vertical-align: sub">R</span></span>
                            </div>

                            <mat-divider></mat-divider>

                            <div
                              class="measurement-sensor-value clickable"
                              [popperTrigger]="$any('click')"
                              [popperTimeoutAfterShow]="1000"
                              [popper]="'ALL.ACTIONS.Copied !' | translate"
                              (click)="clipboard.copy(result?.coef_alpha_2?.corrected?.corrected_measure_A?.toString())"
                            >
                              <span>
                                <b>{{ (result?.coef_alpha_2?.corrected?.corrected_measure_A | fixed : 4) ?? '-' }}</b>
                                <span>kg</span>
                                <span class="mdi mdi-content-copy mdi-24px"></span>
                              </span>
                            </div>
                          </div>
                        </div>
                      </td>
                    </tr>

                    <tr>
                      <td class="preview-calibration">
                        <div>
                          <bg2-device-calibration-temperature-chart
                            [property]="'weight_1'"
                            [data]="{ raw: result.weight_x_temperature_raw_chart, calib: result.weight_x_temperature_calib_chart }"
                          ></bg2-device-calibration-temperature-chart>
                        </div>
                      </td>

                      <td class="preview-calibration">
                        <div>
                          <bg2-device-calibration-temperature-chart
                            [property]="'weight_2'"
                            [data]="{ raw: result.weight_x_temperature_raw_chart, calib: result.weight_x_temperature_calib_chart }"
                          ></bg2-device-calibration-temperature-chart>
                        </div>
                      </td>
                    </tr>
                  </ng-container>
                </tbody>
              </table>
            </div>

            <div class="step-actions">
              <bg2-button
                [type]="'action'"
                [icon]="'mdi-pan-left'"
                [message]="'ALL.COMMON.Previous step' | translate"
                (btn_click)="previous_step(stepper)"
              ></bg2-button>

              <bg2-button
                [type]="'action'"
                [icon]="'mdi-pan-right'"
                [message]="'ALL.COMMON.Next step' | translate"
                [disabled]="(step_computation__is_valid$$ | async) === false"
                (btn_click)="next_step(stepper)"
              ></bg2-button>
            </div>
          </div>
        </ng-template>
      </mat-step>

      <!-- STEP : Save calibrations   -->
      <mat-step [editable]="(is_saving_calibrations.value$$ | async) === false" [completed]="false">
        <ng-template matStepLabel>
          {{
            'VIEWS.DEVICES.DIALOGS_AND_MODALS.MODALS.DEVICE_CALIBRATION.DEVICE_CALIBRATION_TEMPERATURE_MODAL.SUBMIT.Save calibrations'
              | translate
          }}
        </ng-template>

        <ng-template matStepContent>
          <div class="mat-step-container --save">
            <div *ngLet="data_for_step_4$$ | async; let apply_calibration_to_devices" class="devices-list">
              <table>
                <thead>
                  <th>{{ 'DEVICE.ALL.COMMON.Device' | translate }}</th>
                  <th>Alpha 1</th>
                  <th>Alpha 2</th>
                </thead>

                <tbody>
                  <ng-container *ngFor="let result of apply_calibration_to_devices">
                    <tr>
                      <!-- Device identity -->
                      <td *ngLet="result?.device; let device" rowspan="1" class="device-identity">
                        {{ device?.name }} ({{ device?.imei$$ | async }})
                      </td>

                      <td class="measurement">
                        <div>
                          <!-- Coef alpha -->
                          <div class="measurement-sensor --coefficient-alpha">
                            <div class="measurement-sensor-desc">
                              <span class="mdi mdi-alpha mdi-24px"></span>
                              <span>a<span style="vertical-align: sub">w1</span></span>
                            </div>

                            <mat-divider></mat-divider>

                            <div
                              class="measurement-sensor-value clickable"
                              [popperTrigger]="$any('click')"
                              [popperTimeoutAfterShow]="1000"
                              [popper]="'ALL.ACTIONS.Copied !' | translate"
                              (click)="clipboard.copy(result?.coef_alpha_1?.result?.toString())"
                            >
                              <span>
                                <b>
                                  {{ (result?.coef_alpha_1?.result * 1000 | fixed : 4) ?? '-' }}
                                </b>
                                <span>g/°C/kg</span>
                                <span class="mdi mdi-content-copy mdi-24px"></span>
                              </span>
                            </div>
                          </div>
                        </div>
                      </td>

                      <td class="measurement">
                        <div>
                          <!-- Coef alpha -->
                          <div class="measurement-sensor --coefficient-alpha">
                            <div class="measurement-sensor-desc">
                              <span class="mdi mdi-alpha mdi-24px"></span>
                              <span>a<span style="vertical-align: sub">w2</span></span>
                            </div>

                            <mat-divider></mat-divider>

                            <div
                              class="measurement-sensor-value clickable"
                              [popperTrigger]="$any('click')"
                              [popperTimeoutAfterShow]="1000"
                              [popper]="'ALL.ACTIONS.Copied !' | translate"
                              (click)="clipboard.copy(result?.coef_alpha_2?.result?.toString())"
                            >
                              <span>
                                <b>
                                  {{ (result?.coef_alpha_2?.result * 1000 | fixed : 4) ?? '-' }}
                                </b>
                                <span>g/°C/kg</span>
                                <span class="mdi mdi-content-copy mdi-24px"></span>
                              </span>
                            </div>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </ng-container>
                </tbody>
              </table>
            </div>

            <div class="step-actions">
              <bg2-button
                [type]="'action'"
                [icon]="'mdi-pan-left'"
                [message]="'ALL.COMMON.Previous step' | translate"
                [disabled]="is_saving_calibrations.value$$ | async"
                (btn_click)="previous_step(stepper)"
              ></bg2-button>

              <bg2-button
                [type]="'action'"
                [icon]="'mdi-content-save'"
                [message]="'ALL.ACTIONS.Save' | translate"
                [loading]="is_saving_calibrations.value$$ | async"
                [disabled]="is_saving_calibrations.value$$ | async"
                (btn_click)="save_calibrations()"
              ></bg2-button>
            </div>
          </div>
        </ng-template>
      </mat-step>
    </mat-stepper>
  </bg2-modal-content>
</bg2-modal>
