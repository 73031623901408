<fieldset [disabled]="disabled">
  <ng-container *ngIf="form_schema$$ | async as form_schema;">
    <sf-form #eventForm
      [schema]="form_schema"
      [model]="form_model$$ | async" 
      (isValid)="setFormValid($event)"
      (onChange)="onFormChange($event)"
      (onErrorChange)="logErrors($event.value)">
    </sf-form>
  </ng-container>
</fieldset>