import { groupBy } from 'lodash';

import { DeviceInterface, DRDevice } from 'app/models/devices';
import { map, Observable, of, switchMap, take } from 'rxjs';

/** */
export const build_template_device_list$ = (devices: DRDevice[], exceptional_imeis?: string[]): Observable<string> => {
  const non_excluded_devices = devices.filter(
    device => device?.type !== DeviceInterface.TypeEnum.CPT && device?.type !== DeviceInterface.TypeEnum.CPTMC
  );

  // Styles
  const table_th_td_styles = 'border: 1px solid gray;text-align: center;padding: 5px;';
  const table_th_styles = 'background-color: black; color: white;';

  return of(['<h3>Liste des appareils</h3>']).pipe(
    switchMap(template_builder => {
      if (non_excluded_devices?.length === 0) {
        template_builder.push('<div><b>Aucun appareil lié au ticket</b></div>');
        return of(template_builder);
      }

      return of(template_builder).pipe(
        map(_template_builder => {
          _template_builder.push(
            '<table style="border-collapse: collapse;width: 100%;table-layout:fixed;">',

            `<thead><tr>`,
            `<th style="${table_th_td_styles}${table_th_styles}background-color: lightgray;"></th>`,
            `<th style="${table_th_td_styles}${table_th_styles}">Appareils</th>`,
            `<th style="${table_th_td_styles}${table_th_styles}">Total</th>`,
            `</tr></thead>`,

            '<tbody>'
          );

          return _template_builder;
        }),
        map(_template_builder => {
          const build_device_row = (_devices: DRDevice[]): string => {
            let device_row_template = '';

            device_row_template += '<tr>';
            device_row_template += `<th style="${table_th_td_styles}${table_th_styles}">${_devices[0].type}</th>`;

            device_row_template += `<td style="${table_th_td_styles}">`;
            device_row_template += '<div style="display: flex; flex-flow: column nowrap;">';
            device_row_template += `${_devices
              .map(device => {
                const is_exceptional = exceptional_imeis?.includes(device.imei.toString());
                const template =
                  `<span style="${is_exceptional ? 'color: red;' : ''}">` +
                  `${device.name}${is_exceptional ? '<b style="vertical-align: super;">*</b>' : ''}` +
                  '</span>';

                return template;
              })
              .join('')}`;
            device_row_template += '</div>';
            device_row_template += '</td>';

            device_row_template += `<td style="${table_th_td_styles}">${_devices.length}</td>`;
            device_row_template += '</tr>';

            return device_row_template;
          };

          const devices_by_type: { [key in DeviceInterface.TypeEnum]: DRDevice[] } = <any>(
            groupBy(non_excluded_devices, device => device.type)
          );

          if ((devices_by_type?.GPS ?? []).length > 0) {
            _template_builder.push(build_device_row(devices_by_type.GPS));
          }

          if ((devices_by_type?.WG ?? []).length > 0) {
            _template_builder.push(build_device_row(devices_by_type.WG));
          }

          if ((devices_by_type?.RG ?? []).length > 0) {
            _template_builder.push(build_device_row(devices_by_type.RG));
          }

          return _template_builder;
        }),
        map(_template_builder => {
          const total_of_devices = non_excluded_devices?.length;

          _template_builder.push(
            '</tbody>',

            '<tfoot><tr>',
            `<th style="${table_th_td_styles}${table_th_styles}">Total :</th><th style="${table_th_td_styles}" colspan="2">${total_of_devices} appareil(s)</th>`,
            '</tr></tfoot>',

            '</table>'
          );

          if (exceptional_imeis?.length > 0) {
            template_builder.push(
              '<br>',
              '<div style="font-size: 0.9em;">',

              '<b style="color: red; font-weight: bold;">*</b>',
              ' ',
              '<i>Appareil initialement non inclus dans le ticket, ajouté via RU.</i>',

              '</div>'
            );
          }

          return _template_builder;
        })
      );
    }),
    map(template_builder => template_builder.join('')),
    take(1)
  );
};
