<div class="bg-dialog">
  <dialog-header>
    <span>{{ 'VIEWS.DEVICES.COMPONENTS.DEVICES_DATATABLES.SELECTION.Enable/Disable devices' | translate }}</span>
  </dialog-header>

  <!-- VIEWS.DEVICES.DIALOGS_AND_MODALS.DEVICE_ENABLE_DISABLE_DIALOG.Enable/Disable devices -->

  <dialog-body>
    <div class="devices-list-container">
      <ng-container *ngFor="let device of devices$$ | async; let index = index">
        <div class="devices-list-container__device-container">
          <div class="devices-list-container__device-container__header">
            <div class="devices-list-container__device-container__header__name-and-status">
              <span>{{ device?.name }}</span>
              <span>|</span>
              <bg2-gps-level [open_in_modal]="false" [device]="device"></bg2-gps-level>
              <bg2-gprs-level [open_in_modal]="false" [device]="device"></bg2-gprs-level>
              <bg2-s868-level [open_in_modal]="false" [device]="device"></bg2-s868-level>
              <!-- <bg2-battery-level [open_in_modal]="false" [device]="device"></bg2-battery-level> -->
              <bg2-battery-level-simplified [open_in_modal]="false" [device]="device"></bg2-battery-level-simplified>
            </div>

            <div class="devices-list-container__device-container__header__last-com">
              <bg2-last-com
                [device]="device"
                [prefix]="'VIEWS.APIARY.SHARED.WIDGETS.APIARY_LAST_DATA.APIARY_LAST_DATA_CARD.Last com' | translate | colon"
              ></bg2-last-com>
            </div>
          </div>

          <mat-slide-toggle
            [color]="'primary'"
            [checked]="device?.is_activated$$ | async"
            [disabled]="is_updating_devices$$ | async"
            (change)="set_device_active_state($event?.checked, device)"
          >
            {{ 'DEVICE.ALL.METADATA.UNACTIVATED.Is active ?' | translate }}
          </mat-slide-toggle>
        </div>

        <mat-divider *ngIf="index !== (devices$$ | async)?.length - 1"></mat-divider>
      </ng-container>
    </div>
  </dialog-body>

  <div class="dialog-footer">
    <bg2-button [type]="'cancel'" [message]="'VIEWS.MODALS.FORM.Cancel' | translate" (btn_click)="close(false)"></bg2-button>

    <bg2-button
      [type]="'action'"
      [icon]="'mdi-content-save'"
      [loading]="is_updating_devices$$ | async"
      [disabled]="(is_valid$$ | async) === false"
      [message]="'VIEWS.MODALS.FORM.Save' | translate"
      (btn_click)="submit()"
    >
    </bg2-button>
  </div>
</div>
