import { BehaviorSubject, combineLatest, map, switchMap } from 'rxjs';
import { distinctUntilRealChanged, replay, waitForNotNilValue } from '@bg2app/tools/rxjs';

import { StateMachine } from 'app/models/misc/state-machine';
import { VISIT_ASPECT } from 'app/models/events/VisitEvent';

import { strEnum } from 'app/misc/tools';

// #region -> (enumerators)

/** */
export const apiary_visit_state = strEnum([
  'event_config',

  'overall_visit_aspects_config',
  'overall_visit',

  'hive_by_hive_config',
  'hive_by_hive_aspects_config',
  'hive_by_hive_identified_visit',
  'hive_by_hive_not_identified_visit',
]);

/** */
export type APIARY_VISIT_STATE = keyof typeof apiary_visit_state;

/** */
const apiary_visit_state_event = strEnum([
  'go_back',
  'save_visit',

  'configure_overall_visit_aspects',
  'do_overall_visit',

  'configure_hive_by_hive_visit',
  'configure_hive_by_hive_visit_aspects',
  'do_hive_by_hive_identified_visit',
  'do_hive_by_hive_not_identified_visit',
]);

/** */
type APIARY_VISIT_STATE_EVENT = keyof typeof apiary_visit_state_event;

// #endregion

/** */
export class ApiaryEvaluationManager {
  // #region -> (class basics)

  /** */
  constructor() {
    this._visit_state_machine$.next(
      new StateMachine<APIARY_VISIT_STATE, APIARY_VISIT_STATE_EVENT>().start_with_initial_state('event_config').using_schema({
        id: 'apiary_evaluation_flow',
        states: {
          event_config: {
            on: {
              configure_hive_by_hive_visit: 'hive_by_hive_config',
              configure_overall_visit_aspects: 'overall_visit_aspects_config',
            },
          },

          overall_visit_aspects_config: {
            on: {
              go_back: 'event_config',
              do_overall_visit: 'overall_visit',
            },
          },
          overall_visit: {
            on: {
              go_back: 'overall_visit_aspects_config',
              save_visit: 'event_config',
            },
          },

          hive_by_hive_config: {
            on: {
              go_back: 'event_config',
              configure_hive_by_hive_visit_aspects: 'hive_by_hive_aspects_config',
            },
          },
          hive_by_hive_aspects_config: {
            on: {
              go_back: 'hive_by_hive_config',
              do_hive_by_hive_identified_visit: 'hive_by_hive_identified_visit',
              do_hive_by_hive_not_identified_visit: 'hive_by_hive_not_identified_visit',
            },
          },
          hive_by_hive_identified_visit: {
            on: {
              go_back: 'hive_by_hive_aspects_config',
              save_visit: 'event_config',
            },
          },
          hive_by_hive_not_identified_visit: {
            on: {
              go_back: 'hive_by_hive_aspects_config',
              save_visit: 'event_config',
            },
          },
        },
      })
    );
  }

  // #endregion

  // #region -> (global visit management)

  /** */
  private _visit_state_machine$ = new BehaviorSubject<StateMachine<APIARY_VISIT_STATE, APIARY_VISIT_STATE_EVENT>>(null);

  /** */
  public visit_state_machine$$ = this._visit_state_machine$.asObservable().pipe(waitForNotNilValue());

  /** */
  public get visit_state_machine(): StateMachine<APIARY_VISIT_STATE, APIARY_VISIT_STATE_EVENT> {
    return this._visit_state_machine$.getValue();
  }

  /** */
  public visit_state$$ = this.visit_state_machine$$.pipe(switchMap(state_machine => state_machine.state$$));

  /** */
  private _visit_aspect$ = new BehaviorSubject<VISIT_ASPECT>(null);

  /** */
  public visit_aspect$$ = this._visit_aspect$.asObservable().pipe(distinctUntilRealChanged());

  /** */
  public set visit_aspect(visit_aspect: VISIT_ASPECT) {
    this._visit_aspect$.next(visit_aspect);
  }

  /**
   * Observes if the user can go back in the flow.
   */
  public can_go_back$$ = this.visit_state$$.pipe(
    map(visit_state => visit_state !== 'event_config'),
    distinctUntilRealChanged(),
    replay()
  );

  /**
   * Observes if the user is visiting the apiary or the hives.
   */
  public is_visiting$$ = this.visit_state_machine$$.pipe(
    switchMap(machine =>
      machine.is_current_state_one_of$$('hive_by_hive_identified_visit', 'hive_by_hive_not_identified_visit', 'overall_visit')
    ),
    distinctUntilRealChanged()
  );

  /**
   * Observes if the user is configuring the visit.
   */
  public is_configuring$$ = this.visit_state_machine$$.pipe(
    map(machine =>
      machine.is_current_state_one_of$$(
        'event_config',
        'hive_by_hive_aspects_config',
        'hive_by_hive_config',
        'overall_visit_aspects_config'
      )
    ),
    distinctUntilRealChanged()
  );

  // #endregion

  // #region -> (apiary_visit-level management)

  // #endregion

  // #region -> (identified hives visit management)

  // #endregion

  // #region -> (not identified hives visit management)

  // #endregion
}
