import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';

import { BehaviorSubject, Subscription } from 'rxjs';
import { distinctUntilRealChanged } from '@bg2app/tools/rxjs';

import { isEmpty, isNil, isUndefined } from 'lodash-es';
import { marker as i18n } from '@biesbjerg/ngx-translate-extract-marker';

import { BgControlWidgetComponent } from '../control/control.widget';

import { Country } from 'app/widgets/misc-widgets/custom-form-field/intl-phone-input/model/country.model';
import { Beeguard2Api } from 'app/core';
import { AppStateService } from 'app/core/app-state.service';
import { DialogsService } from 'app/widgets/dialogs-modals';

/**
 * Component to manage phone number depending on country.
 *
 *
 * Test for auto-fill for phone number: {@link http://app.beeguard.test:4200/users(modal:new_user;args=%7B%22telephone%22:%22%2B33758025626%22%7D)?cols%5B%5D=usr_usrnm,usr_email,usr_phone,usr_lstlog,usr_refer,usr_prms&offset=-8}
 */
@Component({
  selector: 'bg2-ng-mat-tel',
  templateUrl: './ng-mat-tel.component.html',
  styleUrls: ['./ng-mat-tel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NgMatTelComponent extends BgControlWidgetComponent implements OnInit, OnDestroy {
  // #region -> (widget basics)

  /** */
  private _error_timeout: NodeJS.Timeout = null;

  /** */
  private _phone_number_sub: Subscription = null;

  /** */
  private _value_changes_sub: Subscription = null;

  /** */
  private _phone_number_error_sub: Subscription = null;

  constructor(protected bg2Api: Beeguard2Api, public appState: AppStateService, protected dialogs: DialogsService) {
    super(bg2Api, appState, dialogs);
  }
  ngOnInit(): void {
    // this.formProperty.valueChanges.pipe(distinctUntilRealChanged()).subscribe({
    //   next: value => {
    //     if (value === ' ' || isEmpty(value) || isUndefined(value)) {
    //       if (this._error_timeout) {
    //         clearTimeout(this._error_timeout);
    //         this._error_timeout = null;
    //       }
    //       this._error_timeout = setTimeout(() => {
    //         const error = {
    //           code: 'INVALID_PHONE_NUMBER',
    //           message: i18n<string>('ALL.ERROR.FORM.PHONE_NUMBER.INVALID_PHONE_NUMBER'),
    //           path: this.formProperty._canonicalPath,
    //         };
    //         this._error$$.next(error);
    //         this.formProperty.root.extendErrors(error);
    //       }, 2000);
    //     } else {
    //       this._error$$.next(null);
    //     }
    //   },
    // });
  }

  ngOnDestroy(): void {
    this._phone_number_sub?.unsubscribe();
    this._value_changes_sub?.unsubscribe();
    this._phone_number_error_sub?.unsubscribe();
  }

  // #endregion

  // #region -> (selected country)

  /** */
  private _country$$ = new BehaviorSubject<Country>(null);

  /** */
  public country$$ = this._country$$.asObservable();

  /** */
  public set when_country_changed(country: Country) {
    this._country$$.next(country);
  }

  // #endregion

  // #region -> (value management)

  /** */
  public set when_value_changed(value: string) {
    if (isNil(value)) {
      this.formProperty.setValue('', false);
    } else {
      this.formProperty.setValue(value, false);
    }
  }

  // #endregion

  // #region -> (errors)

  /** */
  private _error$$ = new BehaviorSubject<{ code: string; message: string; path: string }>(null);

  /** */
  public error$$ = this._error$$.asObservable();

  // #endregion
}
