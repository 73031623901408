<div class="zoho-manager-container-component">
  <div class="zoho-manager-container__wrap">
    <div class="zoho-manager-container__header">
      <img src="assets/img/pictos/zoho_logo.svg" alt="" />

      <ng-container>
        <span>|</span>
        <span *ngIf="!require_attention_for">{{ title }}</span>
        <span *ngIf="require_attention_for">{{ 'VIEWS.ZOHO.SHARED.ZOHO_MANAGER_CONTAINER.Your attention is required' | translate }}</span>
      </ng-container>

      <ng-container *ngIf="!require_attention_for">
        <span class="spacer"></span>

        <bg2-button
          [type]="'action'"
          [icon]="'mdi-check-all'"
          [options]="{ is_thin: true }"
          [disabled]="(is_authenticated$$ | async) === false"
          [message]="'VIEWS.ZOHO.SHARED.ZOHO_MANAGER_CONTAINER.Re-check' | translate"
          (btn_click)="run_zoho_fetch()"
        ></bg2-button>
      </ng-container>

      <ng-container *ngIf="require_attention_for && (is_authenticated$$ | async) === false">
        <span class="spacer"></span>

        <bg2-button [type]="'action'" [message]="'VIEWS.AUTH.LOGIN.Log in' | translate" (btn_click)="login()"></bg2-button>
      </ng-container>
    </div>

    <div class="zoho-manager-container__body">
      <ng-container *ngIf="!require_attention_for; else ngContent">
        <ng-container *ngIf="(is_authenticated$$ | async) === false; else ngContent">
          <div class="body__not-authenticated-to-zoho">
            <span>{{ 'VIEWS.ZOHO.SHARED.ZOHO_MANAGER_CONTAINER.You must be logged to use this zoho integration' | translate }}</span>
            <bg2-button [type]="'action'" [message]="'VIEWS.AUTH.LOGIN.Log in' | translate" (btn_click)="login()"></bg2-button>
          </div>
        </ng-container>
      </ng-container>

      <ng-template #ngContent>
        <ng-content></ng-content>
      </ng-template>
    </div>
  </div>
</div>
