<div class="bg2-zoho-ticket-summary-component">
  <div
    class="ticket-container"
    [ngClass]="{
      '--is-opened': ticket?.statusType === 'Open',
      '--is-onhold': ticket?.statusType === 'On Hold',
      '--is-closed': ticket?.statusType === 'Closed'
    }"
  >
    <div class="ticket-container__description-container">
      <span class="ticket-container__description-container__subject" [popper]="ticket?.subject ?? ''">
        {{ ticket?.subject | truncate : 35 : '...' }}
      </span>

      <div class="ticket-container__description-container__subinfo-container">
        <span class="subinfo-container__ticket-contact">{{ ticket?.contact?.firstName }} {{ ticket?.contact?.lastName }}</span>
        <span class="subinfo-container__ticket-account" [popper]="ticket?.contact?.account?.accountName ?? ''">
          [{{ ticket?.contact?.account?.accountName | truncate : 15 : '...' }}]
        </span>
      </div>

      <div class="ticket-container__description-container__subinfo-container">
        <span class="subinfo-container__ticket-number">
          <a [href]="ticket?.webUrl" target="_blank" (click)="$event.stopImmediatePropagation()">#{{ ticket?.ticketNumber }}</a>
        </span>
        <span>-</span>
        <span class="subinfo-container__ticket-time">
          <span class="mdi mdi-clock"></span>

          <ng-container *ngIf="ticket?.statusType === 'Open'">
            <span
              [popper]="
                'WIDGETS.EVENT_FORM.ZOHO_SEARCH_DESK_WIDGET.TICKETS.Created on [date]'
                  | translate : { date: ticket?.modifiedTime | formatDate : 'today_time_else_day_month_year' }
              "
            >
              {{ ticket?.modifiedTime | formatDate : 'today_time_else_day_month_year' }}
            </span>
          </ng-container>

          <ng-container *ngIf="ticket?.statusType === 'On Hold'">
            <span
              [popper]="
                'WIDGETS.EVENT_FORM.ZOHO_SEARCH_DESK_WIDGET.TICKETS.On hold since [date]'
                  | translate : { date: ticket?.onholdTime | formatDate : 'today_time_else_day_month_year' }
              "
            >
              {{ ticket?.onholdTime | formatDate : 'today_time_else_day_month_year' }}
            </span>
          </ng-container>

          <ng-container *ngIf="ticket?.statusType === 'Closed'">
            <span
              [popper]="
                'WIDGETS.EVENT_FORM.ZOHO_SEARCH_DESK_WIDGET.TICKETS.Closed since [date]'
                  | translate : { date: ticket?.modifiedTime | formatDate : 'today_time_else_day_month_year' }
              "
            >
              {{ ticket?.modifiedTime | formatDate : 'today_time_else_day_month_year' }}
            </span>
          </ng-container>
        </span>
      </div>
    </div>

    <div class="ticket-container__state" [popper]="ticket?.status ?? ''">
      {{ ticket?.status | truncate : 20 : '...' }}
    </div>
  </div>
</div>
