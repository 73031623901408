import { Clipboard } from '@angular/cdk/clipboard';
import { MatStepper } from '@angular/material/stepper';
import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';

import { ISchema } from 'ngx-schema-form';
import { clone, every, isEmpty, isNil, uniq } from 'lodash-es';
import { marker as i18n } from '@biesbjerg/ngx-translate-extract-marker';
import {
  addHours,
  addMinutes,
  closestTo,
  isAfter,
  isBefore,
  isEqual,
  setHours,
  setSeconds,
  startOfYesterday,
  subHours,
} from 'date-fns/esm';

import { Observable, combineLatest, debounceTime, forkJoin, map, of, switchMap, tap, filter, merge, Subscription } from 'rxjs';
import { allTrue, catchErrorInDialog, distinctUntilRealChanged, replay } from '@bg2app/tools/rxjs';

import { DeviceApi } from 'app/core';
import { AbstractModalComponent, DialogsService } from 'app/widgets/dialogs-modals';

import { parseDate } from 'app/misc/tools';
import { DeviceModalParams } from '../../device-modal';
import { DRDevice, DeviceFullConfiguration, SimpleSetterGetter } from 'app/models';

/** */
interface DeviceCalibrationTemperatureModalParams extends DeviceModalParams {
  /**
   * List of device imeis separated by a comma.
   */
  imeis: string;
}

/** */
interface DeviceCalibrationDataPoint {
  /** */
  date: Date;

  /** */
  weight_1: number;

  /** */
  weight_1_raw: number;

  /** */
  weight_2: number;

  /** */
  weight_2_raw: number;

  /** */
  temperature: number;
}

export function compute_alpha_calibration(
  weight: {
    factor: number;
    offset: number;
    measure_A: number;
    measure_B: number;
  },
  temperature: {
    measure_A: number;
    measure_B: number;
    calibration: number;
  }
): {
  formula: {
    /**
     * Calibration temperature used from devicec config
     */
    temperature_calibration: number;

    /**
     * Differerence of temperature between measurement A and B in °C.
     */
    delta_temperature: number;

    /**
     * Differerence of weight between measurement A and B in g.
     */
    delta_weight_g: number;

    /**
     * Constant a for linear function Pw(T) = aT + b.
     */
    constant_a: number;

    /**
     * Constant b for linear function Pw(T) = aT + b.
     */
    constant_b: number;

    /**
     * Computed weight calibration for temperature calibration in kg.
     */
    weight_calibration_kg: number;

    /**
     * Computed weight calibration for temperature calibration in g.
     */
    weight_calibration_g: number;

    /**
     * Weight variation in g/°C.
     */
    weight_variation: number;
  };
  /** */
  corrected: {
    /** */
    corrected_measure_A: number;

    /** */
    corrected_measure_B: number;

    /** */
    corrected_delta_weight_g: number;

    /** */
    corrected_weight_variation: number;
  };

  /** */
  result: number;
} {
  // const wgt_a = wgt_calib_b_offset + wgt_calib_a_factor * wgt_raw_time_a;
  // const wgt_b = wgt_calib_b_offset + wgt_calib_a_factor * wgt_raw_time_b;

  // const a = (wgt_a - wgt_b) / (temp_time_a - temp_time_b);
  // const b = wgt_a - a * temp_time_a;

  // const wgt_at_cal_temp = a * calib_temp + b;
  // const alpha = (wgt_a - wgt_b) / ((temp_time_a - temp_time_b) * wgt_at_cal_temp);

  // -----------------

  const weight_measure_a = weight.factor * weight.measure_A + weight.offset;
  const weight_measure_b = weight.factor * weight.measure_B + weight.offset;

  const delta_temperature = temperature?.measure_B - temperature?.measure_A;

  const delta_weight_kg = (weight_measure_b - weight_measure_a) * -1;
  // const delta_weight_g = delta_weight_kg * 1000;

  // Calculate linear function
  const constant_a = (weight_measure_b - weight_measure_a) / delta_temperature;
  const constant_b = (temperature?.measure_B * weight_measure_a - temperature?.measure_A * weight_measure_b) / delta_temperature;
  const linear_fn = (_temperature: number) => constant_a * _temperature + constant_b;

  // Calculations
  const weight_calibration_kg = linear_fn(temperature?.calibration);
  const weight_calibration_g = weight_calibration_kg * 1000;
  const weight_variation = delta_weight_kg / delta_temperature;

  const alpha = delta_weight_kg / (delta_temperature * weight_calibration_kg);

  // Calculations after calibration
  const corrected_measure_a = apply_calibration(
    { weight: weight_measure_a, temperature: temperature?.measure_A },
    temperature?.calibration,
    alpha
  );

  const corrected_measure_b = apply_calibration(
    { weight: weight_measure_b, temperature: temperature?.measure_B },
    temperature?.calibration,
    alpha
  );

  const corrected_delta_weight_g = (corrected_measure_b - corrected_measure_a) * 1000;
  const corrected_weight_variation = corrected_delta_weight_g / delta_temperature; // (g/°C)

  return {
    formula: {
      temperature_calibration: temperature?.calibration,
      delta_temperature,
      delta_weight_g: null,
      constant_a,
      constant_b,
      weight_calibration_kg,
      weight_calibration_g,
      weight_variation,
    },
    corrected: {
      corrected_delta_weight_g,
      corrected_weight_variation,
      corrected_measure_A: corrected_measure_a,
      corrected_measure_B: corrected_measure_b,
    },
    result: alpha,
  };
}

/** */
function apply_calibration(measure: { weight: number; temperature: number }, temperature_calibration: number, coef: number): number {
  const calc_coef = 1 + coef * (temperature_calibration - measure.temperature);
  return measure?.weight / calc_coef;
}

@Component({
  selector: 'bg2-device-calibration-temperature-modal',
  templateUrl: './device-calibration-temperature-modal.component.html',
  styleUrls: ['./device-calibration-temperature-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DeviceCalibrationTemperatureModalComponent
  extends AbstractModalComponent<DeviceCalibrationTemperatureModalParams>
  implements OnInit, OnDestroy
{
  // #region -> (component basics)

  /** */
  private readonly _now = new Date();

  /** */
  private get now(): Date {
    let now = this._now;

    now.setMinutes(0);
    now.setSeconds(0);
    now.setMilliseconds(0);

    return now;
  }

  /** */
  private selected_devices_marked_for_calibration_sub: Subscription = null;

  /** */
  private update_apply_calib_to_devices_sub: Subscription = null;

  /** */
  constructor(
    private readonly _deviceApi: DeviceApi,
    private readonly _dialogsService: DialogsService,
    public readonly clipboard: Clipboard
  ) {
    super();
  }

  /** */
  ngOnInit(): void {
    this.selected_devices_marked_for_calibration_sub = this.devices_x_weight_at_time$$
      .pipe(
        map(devices_x_weight_at_time => devices_x_weight_at_time.filter(d => d.validity.is_valid !== 'error')),
        map(devices_x_weight_at_time => devices_x_weight_at_time.map(a => a.device.device.imei))
      )
      .subscribe({
        next: imeis => {
          this.selected_devices_for_calibration.value = imeis;
        },
      });

    this.update_apply_calib_to_devices_sub = this.calibration_results$$
      .pipe(map(calibration_results => calibration_results.map(r => r.device.imei)))
      .subscribe({
        next: imeis => (this.apply_calibration_to_devices.value = imeis),
      });
  }

  /** */
  ngOnDestroy(): void {
    super.ngOnDestroy();

    this.update_apply_calib_to_devices_sub?.unsubscribe();
    this.selected_devices_marked_for_calibration_sub?.unsubscribe();
  }

  /** */
  protected handle_event_before_unload(event: BeforeUnloadEvent): void {
    return null;
  }

  // #endregion

  // #region -> (related devices)

  /** */
  private modal_device_imeis$$ = this.input_params$$.pipe(
    map(parameters => parameters?.imeis),
    map(imeis => {
      const trimmed_imeis = imeis.trim();
      const imeis_list = trimmed_imeis.split(',');

      return imeis_list.map(imei => parseInt(imei, 10));
    }),
    distinctUntilRealChanged(),
    replay()
  );

  /** */
  public devices$$ = this.modal_device_imeis$$.pipe(
    switchMap(imeis => this._deviceApi.requestDevices(imeis)),
    replay()
  );

  // #endregion

  // #region -> (stepper management)

  /** */
  public previous_step(stepper: MatStepper): void {
    stepper.previous();
  }

  /** */
  public next_step(stepper: MatStepper): void {
    stepper.next();
  }

  // #endregion

  // #region -> (step 1 / preliminary checks)

  /** */
  public is_running_preliminary_check = new SimpleSetterGetter(true);

  /** */
  private _is_device_valid$$(device: DRDevice): Observable<{ device: DRDevice; validity: { is_valid: string; reason?: string } }> {
    return of(device).pipe(
      map(_device => {
        if (_device.type !== 'WG') {
          return {
            device: _device,
            validity: {
              is_valid: 'error',
              reason: i18n<string>(
                'VIEWS.DEVICES.DIALOGS_AND_MODALS.MODALS.DEVICE_CALIBRATION.DEVICE_CALIBRATION_TEMPERATURE_MODAL.INVALID.This device is invalid'
              ),
            },
          };
        }

        return { device: _device, validity: { is_valid: 'success' } };
      })
    );
  }

  /** */
  public check_devices_validity$$ = this.devices$$.pipe(
    tap(() => (this.is_running_preliminary_check.value = true)),
    switchMap(devices => {
      const check_devices_validaty$$ = devices.map(device => this._is_device_valid$$(device));

      return combineLatest(check_devices_validaty$$);
    }),
    tap(() => (this.is_running_preliminary_check.value = false)),
    replay()
  );

  /** */
  public is_preliminary_check_valid$$ = merge(
    of(false),
    this.check_devices_validity$$.pipe(
      map(check_devices_validity => {
        if (check_devices_validity.length === 0) {
          return false;
        }

        const validity_status = check_devices_validity.map(device_x_validity => device_x_validity?.validity?.is_valid ?? false);
        return every(validity_status, value => value === 'success');
      })
    )
  ).pipe(distinctUntilRealChanged(), replay());

  // #endregion

  // #region -> (step 2 / data selection)

  /** */
  public readonly datetime_select_schema: ISchema = {
    type: 'object',
    properties: {
      date_measurement_a: {
        type: 'string',
        widget: 'date-time',
        label: i18n(
          'VIEWS.DEVICES.DIALOGS_AND_MODALS.MODALS.DEVICE_CALIBRATION.DEVICE_CALIBRATION_TEMPERATURE_MODAL.DATA_SELECTION.Date of measure A'
        ),
        default: setHours(startOfYesterday(), 8).toISOString(),
        // default: new Date('2023-04-21T11:14:00.000Z'),
        options: {
          pickerType: 'both',
          show_seconds: false,
        },
      },
      date_measurement_b: {
        type: 'string',
        widget: 'date-time',
        label: i18n(
          'VIEWS.DEVICES.DIALOGS_AND_MODALS.MODALS.DEVICE_CALIBRATION.DEVICE_CALIBRATION_TEMPERATURE_MODAL.DATA_SELECTION.Date of measure B'
        ),
        default: this.now.toISOString(),
        // default: new Date('2023-04-21T16:37:00.000Z'),
        options: {
          pickerType: 'both',
          show_seconds: false,
        },
      },
    },
    required: ['date_measurement_a', 'date_measurement_b'],
  };

  /** */
  public step_datetime__form_model = new SimpleSetterGetter<{ date_measurement_a: Date; date_measurement_b: Date }>(null);

  /** */
  public step_datetime__form_validity = new SimpleSetterGetter(false);

  /** */
  public is_running_data_validation = new SimpleSetterGetter(true);

  /** */
  private _devices_configuration$$: Observable<{ device: DRDevice; configuration: DeviceFullConfiguration }[]> = this.devices$$.pipe(
    tap(() => (this.is_running_data_validation.value = true)),
    switchMap(devices => {
      if (isEmpty(devices ?? [])) {
        return of([]);
      }

      const device_x_config$$ = devices.map(device =>
        device?.requestConfiguration().pipe(map(configuration => ({ device, configuration })))
      );

      return forkJoin(device_x_config$$);
    }),
    replay()
  );

  /** */
  public devices_x_weight_at_time$$ = combineLatest({
    devices: this._devices_configuration$$,
    datetime: this.step_datetime__form_model.value$$,
  }).pipe(
    debounceTime(100),
    tap(() => (this.is_running_data_validation.value = true)),
    filter(({ datetime }) => !isNil(datetime?.date_measurement_a) && !isNil(datetime?.date_measurement_b)),
    switchMap(({ devices, datetime }) => {
      let start_time = parseDate(datetime?.date_measurement_a);
      let end_time = parseDate(datetime?.date_measurement_b);

      // Force seconds to 0
      start_time = setSeconds(start_time, 0);
      end_time = setSeconds(end_time, 0);

      const are_date_inversed = isBefore(end_time, start_time);

      if (are_date_inversed) {
        const tmp_date = start_time;

        start_time = end_time;
        end_time = tmp_date;
      }

      const timeseries$$ = devices.map(device => {
        const request$$ = device.device
          .requestTimeseries(
            ['weight_1', 'weight_2', 'weight_raw_1', 'weight_raw_2', 'temperature'],
            subHours(start_time, 2),
            addHours(end_time, 2),
            '1m'
          )
          .pipe(
            map(timeseries => {
              const data: DeviceCalibrationDataPoint[] = <any>timeseries?.timeseries?.data ?? [];

              const point_at_time_a = this._search_valid_point_for_date(start_time, data);
              const point_at_time_b = this._search_valid_point_for_date(end_time, data);

              return {
                data,
                device,
                end_time,
                start_time,
                configuration: device.configuration,
                search_method_time_a: {
                  date: point_at_time_a?.point?.date,
                  direct: point_at_time_a?.is_direct_find,
                },
                search_method_time_b: {
                  date: point_at_time_b?.point?.date,
                  direct: point_at_time_b?.is_direct_find,
                },
                // weight_1: {
                //   time_a: point_at_time_a?.point?.weight_1 as number,
                //   time_b: point_at_time_b?.point?.weight_1 as number,
                //   delta:
                //     isNil(point_at_time_a?.point?.weight_1) || isNil(point_at_time_b?.point?.weight_1)
                //       ? null
                //       : -1000 * ((point_at_time_b?.point?.weight_1 ?? 0) - (point_at_time_a?.point?.weight_1 ?? 0)),
                // },
                weight_1_raw: {
                  time_a: point_at_time_a?.point?.weight_1_raw as number,
                  time_b: point_at_time_b?.point?.weight_1_raw as number,
                  delta:
                    isNil(point_at_time_a?.point?.weight_1_raw) || isNil(point_at_time_b?.point?.weight_1_raw)
                      ? null
                      : -1000 * ((point_at_time_b?.point?.weight_1_raw ?? 0) - (point_at_time_a?.point?.weight_1_raw ?? 0)),
                },
                // weight_2: {
                //   time_a: point_at_time_a?.point?.weight_2 as number,
                //   time_b: point_at_time_b?.point?.weight_2 as number,
                //   delta:
                //     isNil(point_at_time_a?.point?.weight_2) || isNil(point_at_time_b?.point?.weight_2)
                //       ? null
                //       : -1000 * ((point_at_time_b?.point?.weight_2 ?? 0) - (point_at_time_a?.point?.weight_2 ?? 0)),
                // },
                weight_2_raw: {
                  time_a: point_at_time_a?.point?.weight_2_raw as number,
                  time_b: point_at_time_b?.point?.weight_2_raw as number,
                  delta:
                    isNil(point_at_time_a?.point?.weight_2_raw) || isNil(point_at_time_b?.point?.weight_2_raw)
                      ? null
                      : -1000 * ((point_at_time_b?.point?.weight_2_raw ?? 0) - (point_at_time_a?.point?.weight_2_raw ?? 0)),
                },
                temperature: {
                  time_a: point_at_time_a?.point?.temperature as number,
                  time_b: point_at_time_b?.point?.temperature as number,
                  delta:
                    isNil(point_at_time_b?.point?.temperature) || isNil(point_at_time_a?.point?.temperature)
                      ? null
                      : (point_at_time_b?.point?.temperature ?? 0) - (point_at_time_a?.point?.temperature ?? 0),
                },
              };
            })
          );

        return request$$;
      });

      return forkJoin(timeseries$$);
    }),
    map(devices_x_weight_at_time =>
      devices_x_weight_at_time.map(device_x_weight_at_time => {
        const device_config = device_x_weight_at_time?.configuration?.server;

        // Check if device raw weight for measure A is valid
        if (isNil(device_x_weight_at_time?.weight_1_raw?.time_a) || isNil(device_x_weight_at_time?.weight_2_raw?.time_a)) {
          return {
            ...device_x_weight_at_time,
            validity: {
              is_valid: 'error',
              reason: i18n<string>(
                'VIEWS.DEVICES.DIALOGS_AND_MODALS.MODALS.DEVICE_CALIBRATION.DEVICE_CALIBRATION_TEMPERATURE_MODAL.INVALID.Raw weight of measure A is missing'
              ),
            },
          };
        }

        if (device_x_weight_at_time?.weight_1_raw?.time_a <= 5 || device_x_weight_at_time?.weight_1_raw?.time_b <= 5) {
          return {
            ...device_x_weight_at_time,
            validity: {
              is_valid: 'error',
              reason: i18n<string>(
                'VIEWS.DEVICES.DIALOGS_AND_MODALS.MODALS.DEVICE_CALIBRATION.DEVICE_CALIBRATION_TEMPERATURE_MODAL.INVALID.Raw weight of measure A cannot be under 5 kg'
              ),
            },
          };
        }

        // Check if device raw weight for measure B is valid
        if (isNil(device_x_weight_at_time?.weight_1_raw?.time_b) || isNil(device_x_weight_at_time?.weight_2_raw?.time_b)) {
          return {
            ...device_x_weight_at_time,
            validity: {
              is_valid: 'error',
              reason: i18n<string>(
                'VIEWS.DEVICES.DIALOGS_AND_MODALS.MODALS.DEVICE_CALIBRATION.DEVICE_CALIBRATION_TEMPERATURE_MODAL.INVALID.Raw weight of measure B is missing'
              ),
            },
          };
        }

        if (device_x_weight_at_time?.weight_2_raw?.time_a <= 5 || device_x_weight_at_time?.weight_2_raw?.time_b <= 5) {
          return {
            ...device_x_weight_at_time,
            validity: {
              is_valid: 'error',
              reason: i18n<string>(
                'VIEWS.DEVICES.DIALOGS_AND_MODALS.MODALS.DEVICE_CALIBRATION.DEVICE_CALIBRATION_TEMPERATURE_MODAL.INVALID.Raw weight of measure B cannot be under 5 kg'
              ),
            },
          };
        }

        // Check if temperature of measure A is valid
        if (isNil(device_x_weight_at_time?.temperature?.time_a)) {
          return {
            ...device_x_weight_at_time,
            validity: {
              is_valid: 'error',
              reason: i18n<string>(
                'VIEWS.DEVICES.DIALOGS_AND_MODALS.MODALS.DEVICE_CALIBRATION.DEVICE_CALIBRATION_TEMPERATURE_MODAL.INVALID.Temperature of measure A is missing'
              ),
            },
          };
        }

        // Check if temperature of measure B is valid
        if (isNil(device_x_weight_at_time?.temperature?.time_b)) {
          return {
            ...device_x_weight_at_time,
            validity: {
              is_valid: 'error',
              reason: i18n<string>(
                'VIEWS.DEVICES.DIALOGS_AND_MODALS.MODALS.DEVICE_CALIBRATION.DEVICE_CALIBRATION_TEMPERATURE_MODAL.INVALID.Temperature of measure B is missing'
              ),
            },
          };
        }

        // Check if calibration weight is valid for sensor 1
        if (
          isNil(device_config?.weight_a_factor) ||
          device_config?.weight_a_factor === 1 ||
          isNil(device_config?.weight_b_offset) ||
          device_config?.weight_b_offset === 0
        ) {
          return {
            ...device_x_weight_at_time,
            validity: {
              is_valid: 'error',
              reason: i18n<string>(
                'VIEWS.DEVICES.DIALOGS_AND_MODALS.MODALS.DEVICE_CALIBRATION.DEVICE_CALIBRATION_TEMPERATURE_MODAL.INVALID.Device not calibrated in weight (invalid factor or offset for sensor 1)'
              ),
            },
          };
        }

        // Check if calibration weight is valid for sensor 2
        if (
          isNil(device_config?.weight_2_a_factor) ||
          device_config?.weight_2_a_factor === 1 ||
          isNil(device_config?.weight_2_b_offset) ||
          device_config?.weight_2_b_offset === 0
        ) {
          return {
            ...device_x_weight_at_time,
            validity: {
              is_valid: 'error',
              reason: i18n<string>(
                'VIEWS.DEVICES.DIALOGS_AND_MODALS.MODALS.DEVICE_CALIBRATION.DEVICE_CALIBRATION_TEMPERATURE_MODAL.INVALID.Device not calibrated in weight (invalid factor or offset for sensor 2)'
              ),
            },
          };
        }

        // Check if temperature calibration is not missing
        if (isNil(device_x_weight_at_time?.configuration?.server?.weight_1_calib_temp)) {
          return {
            ...device_x_weight_at_time,
            validity: {
              is_valid: 'error',
              reason: i18n<string>(
                'VIEWS.DEVICES.DIALOGS_AND_MODALS.MODALS.DEVICE_CALIBRATION.DEVICE_CALIBRATION_TEMPERATURE_MODAL.INVALID.Missing calibration temperature from device configuration'
              ),
            },
          };
        }

        const temperature_delta = Math.abs(device_x_weight_at_time?.temperature?.time_a - device_x_weight_at_time?.temperature?.time_b);
        if (temperature_delta < 10) {
          return {
            ...device_x_weight_at_time,
            validity: {
              is_valid: 'warning',
              reason: i18n<string>(
                'VIEWS.DEVICES.DIALOGS_AND_MODALS.MODALS.DEVICE_CALIBRATION.DEVICE_CALIBRATION_TEMPERATURE_MODAL.INVALID.Temperature delta is inferior to 10 °C'
              ),
            },
          };
        }

        if (
          device_x_weight_at_time?.search_method_time_a?.direct === false ||
          device_x_weight_at_time?.search_method_time_b?.direct === false
        ) {
          return {
            ...device_x_weight_at_time,
            validity: {
              is_valid: 'warning',
              reason: i18n<string>(
                'VIEWS.DEVICES.DIALOGS_AND_MODALS.MODALS.DEVICE_CALIBRATION.DEVICE_CALIBRATION_TEMPERATURE_MODAL.INVALID.Measure date is different from the original'
              ),
            },
          };
        }

        return {
          ...device_x_weight_at_time,
          validity: { is_valid: 'success', reason: null },
        };
      })
    ),
    tap(() => (this.is_running_data_validation.value = false)),
    replay()
  );

  /** */
  public select_device_for_calibration(should_add: boolean, device_imei: number): void {
    const actual_application = clone(this.selected_devices_for_calibration.value);

    if (should_add) {
      actual_application.push(device_imei);
      this.selected_devices_for_calibration.value = uniq(actual_application);

      return;
    }

    if (!should_add) {
      const updated = actual_application.filter(imei => imei !== device_imei);
      this.selected_devices_for_calibration.value = updated;

      return;
    }
  }

  /** */
  protected selected_devices_for_calibration = new SimpleSetterGetter<number[]>([]);

  /** */
  public selected_devices_for_calibration$$ = this.devices_x_weight_at_time$$.pipe(
    map(step_datetime__checklist => step_datetime__checklist.filter(d => d.validity.is_valid !== 'error').map(d => d.device.device.imei)),
    distinctUntilRealChanged(),
    tap(imeis => (this.selected_devices_for_calibration.value = imeis)),
    replay()
  );

  /** */
  private _is_device_selected_for_calibration: { [key: number]: Observable<boolean> } = {};

  /** */
  public is_device_selected_for_calibration$$ = (imei: number) => {
    if (!isNil(this._is_device_selected_for_calibration[imei])) {
      return this._is_device_selected_for_calibration[imei];
    }

    this._is_device_selected_for_calibration[imei] = this.selected_devices_for_calibration.value$$.pipe(
      map(selected_devices_for_calibration => selected_devices_for_calibration.includes(imei)),
      distinctUntilRealChanged(),
      replay()
    );

    return this._is_device_selected_for_calibration[imei];
  };

  /** */
  private data_for_step_3$$ = this.selected_devices_for_calibration.value$$.pipe(
    switchMap(selected_imeis =>
      this.devices_x_weight_at_time$$.pipe(
        map(devices_x_weight_at_time => devices_x_weight_at_time.filter(datum => selected_imeis.includes(datum.device.device.imei)))
      )
    ),
    replay()
  );

  /** */
  public selected_devices_temperature$$ = this.data_for_step_3$$.pipe(
    map(data =>
      data.map(datum => ({ start_time: datum.start_time, end_time: datum.end_time, device: datum.device.device, data: datum.data }))
    ),
    replay()
  );

  /** */
  public step_datetime__is_valid$$ = allTrue(
    this.step_datetime__form_validity.value$$,
    this.data_for_step_3$$.pipe(
      map(values => {
        const has_selected_values = values.length > 0;
        const has_only_valid_values = every(
          values.map(v => v.validity.is_valid),
          value => value === 'success' || value === 'warning'
        );

        return has_selected_values && has_only_valid_values;
      })
    )
  );

  // #endregion

  // #region -> (step 3 / calibration computation)

  /** */
  public step_calibration = {
    /** */
    is_loading_devices_configuration: new SimpleSetterGetter(true),

    /** */
    is_preparing_data: new SimpleSetterGetter(true),

    /** */
    is_computing_calibration: new SimpleSetterGetter(true),
  };

  /** */
  private _data_to_use_for_compute$$ = this.data_for_step_3$$.pipe(
    map(devices_x_weight_at_time => {
      const devices = devices_x_weight_at_time.map(d => d.device);

      return devices.map(device => {
        const { weight_1_raw, weight_2_raw, temperature, data, configuration, search_method_time_a, search_method_time_b } =
          devices_x_weight_at_time.find(x => x.device.device.imei === device.device.imei);

        return {
          device,
          data,
          start_time: search_method_time_a.date,
          end_time: search_method_time_b.date,
          weight_1_raw,
          weight_2_raw,
          temperature,
          configuration,
        };
      });
    }),
    replay()
  );

  /** */
  private calibration_compute$$ = this._data_to_use_for_compute$$.pipe(
    tap(() => (this.step_calibration.is_computing_calibration.value = true)),
    map(data =>
      data.map(device_data => ({
        device_data,
        alpha_1: compute_alpha_calibration(
          {
            factor: device_data?.configuration?.server?.weight_a_factor,
            offset: device_data?.configuration?.server?.weight_b_offset,
            measure_A: device_data.weight_1_raw.time_a,
            measure_B: device_data.weight_1_raw.time_b,
          },
          {
            measure_A: device_data.temperature.time_a,
            measure_B: device_data.temperature.time_b,
            calibration: device_data.configuration.server.weight_1_calib_temp,
          }
        ),
        alpha_2: compute_alpha_calibration(
          {
            factor: device_data?.configuration?.server?.weight_2_a_factor,
            offset: device_data?.configuration?.server?.weight_2_b_offset,
            measure_A: device_data.weight_2_raw.time_a,
            measure_B: device_data.weight_2_raw.time_b,
          },
          {
            measure_A: device_data.temperature.time_a,
            measure_B: device_data.temperature.time_b,
            calibration: device_data.configuration.server.weight_1_calib_temp,
          }
        ),
      }))
    ),
    tap(() => (this.step_calibration.is_computing_calibration.value = false))
  );

  public calibration_results$$ = this.calibration_compute$$.pipe(
    map(calibration_compute_by_device =>
      calibration_compute_by_device.map(calibration_compute_for_device => {
        const device = calibration_compute_for_device.device_data.device.device;
        const configuration = calibration_compute_for_device.device_data.device.configuration;

        const coef_alpha_1 = calibration_compute_for_device.alpha_1;
        const coef_alpha_2 = calibration_compute_for_device.alpha_2;

        const existant_data = calibration_compute_for_device?.device_data?.data
          ?.filter(datum => !isNil(datum?.temperature) && !isNil(datum?.weight_1) && !isNil(datum?.weight_2))
          .filter(
            datum =>
              datum.date.getTime() >= calibration_compute_for_device.device_data.start_time.getTime() &&
              datum.date.getTime() <= calibration_compute_for_device.device_data.end_time.getTime()
          );

        const weight_x_temperature_raw = existant_data.map(datum => ({
          date: datum?.date,
          weight_1: datum?.weight_1,
          weight_2: datum?.weight_2,
          temperature: datum?.temperature,
        }));

        const weight_x_temperature_raw_chart = {
          points_noise: weight_x_temperature_raw.filter((p, index, self) => index >= 1 && index <= self?.length - 1),
          calibrated_points: [weight_x_temperature_raw[0], weight_x_temperature_raw[weight_x_temperature_raw?.length - 1]],
        };

        const recalibrated_data = existant_data.map(datum => {
          const recalc_weight_1 = apply_calibration(
            { weight: datum?.weight_1, temperature: datum?.temperature },
            configuration.server.weight_1_calib_temp,
            coef_alpha_1.result
          );

          const recalc_weight_2 = apply_calibration(
            { weight: datum?.weight_2, temperature: datum?.temperature },
            configuration.server.weight_1_calib_temp,
            coef_alpha_2.result
          );

          return {
            weight_1: recalc_weight_1,
            weight_2: recalc_weight_2,
            temperature: datum?.temperature,
          };
        });

        const weight_x_temperature_calib_chart = {
          points_noise: recalibrated_data.filter((p, index, self) => index >= 1 && index <= self?.length - 1),
          calibrated_points: [recalibrated_data[0], recalibrated_data[recalibrated_data?.length - 1]],
        };

        return { device, coef_alpha_1, coef_alpha_2, weight_x_temperature_raw_chart, weight_x_temperature_calib_chart };
      })
    ),
    replay()
  );

  /** */
  public apply_calibration_to_device(should_add: boolean, device_imei: number): void {
    const actual_application = clone(this.apply_calibration_to_devices.value);

    if (should_add) {
      actual_application.push(device_imei);
      this.apply_calibration_to_devices.value = uniq(actual_application);

      return;
    }

    if (!should_add) {
      const updated = actual_application.filter(imei => imei !== device_imei);
      this.apply_calibration_to_devices.value = updated;

      return;
    }
  }

  /** */
  protected apply_calibration_to_devices = new SimpleSetterGetter<number[]>([]);

  /** */
  public apply_calibration_to_device$$ = (imei: number) =>
    this.apply_calibration_to_devices.value$$.pipe(
      map(apply_calibration_to_devices => apply_calibration_to_devices.includes(imei)),
      distinctUntilRealChanged(),
      replay()
    );

  /** */
  public step_computation__is_valid$$ = allTrue(
    this.calibration_compute$$.pipe(
      map(
        result =>
          // Check is result is minimal valid (must be positive ? must be at least x value)

          true
      ),
      distinctUntilRealChanged(),
      replay()
    ),
    this.apply_calibration_to_devices.value$$.pipe(
      map(values => values.length >= 1),
      distinctUntilRealChanged(),
      replay()
    )
  );

  /** */
  public data_for_step_4$$ = this.apply_calibration_to_devices.value$$.pipe(
    switchMap(selected_imeis =>
      this.calibration_results$$.pipe(
        map(calibration_results => calibration_results.filter(result => selected_imeis.includes(result.device.imei)))
      )
    ),
    replay()
  );

  // #endregion

  // #region -> (save)

  /** */
  public save_calibrations(): void {
    this.is_saving_calibrations.value = true;

    this._dialogsService
      .customizable({
        body: {
          type: 'div',
          styles: {},
          elements: [
            {
              type: 'span',
              content: i18n<string>(
                'VIEWS.DEVICES.DIALOGS_AND_MODALS.MODALS.DEVICE_CALIBRATION.DEVICE_CALIBRATION_TEMPERATURE_MODAL.You are about to apply calibration coefficients to devices'
              ),
            },
            {
              type: 'span',
              content: i18n<string>(
                'VIEWS.DEVICES.DIALOGS_AND_MODALS.MODALS.DEVICE_CALIBRATION.DEVICE_CALIBRATION_TEMPERATURE_MODAL.Are you sure you want to continue ?'
              ),
            },
          ],
        },
        footer: {
          styles: {},
          buttons: {
            styles: {},
            items: [
              {
                type: 'button',
                content: i18n<string>('ALL.ACTIONS.Cancel'),
                color: 'transparent',
                result: false,
              },
              {
                type: 'button',
                content: i18n<string>('ALL.ACTIONS.Save'),
                color: 'primary',
                result: true,
                icon: 'mdi-content-save',
              },
            ],
          },
        },
      })
      .pipe(
        switchMap(should_continue => {
          if (!should_continue.return_value) {
            return of(null);
          }

          return this.data_for_step_4$$.pipe(
            switchMap(apply_calibration_to_devices => {
              const current_fconf$ = apply_calibration_to_devices.map(actd =>
                this._deviceApi
                  .update_device_full_conf$(actd.device.imei, {
                    server: {
                      weight_1_alpha_temp_factor: actd.coef_alpha_1.result,
                      weight_2_alpha_temp_factor: actd.coef_alpha_2.result,
                    },
                  })
                  .pipe(catchErrorInDialog(this._dialogsService))
              );

              return forkJoin(current_fconf$);
            })
          );
        })
      )
      .subscribe({
        next: result => {
          this.is_saving_calibrations.value = false;

          if (isNil(result)) {
            return;
          } else {
            this.close();
          }
        },
      });
  }

  /** */
  public is_saving_calibrations = new SimpleSetterGetter(false);

  // #endregion

  /** */
  private _search_valid_point_for_date(requested_date: Date, timeseries: DeviceCalibrationDataPoint[]) {
    if (isNil(requested_date) || isEmpty(timeseries ?? [])) {
      return { is_direct_find: true, point: null };
    }

    const timeseries_with_valid_data = timeseries.filter(calibration_data_point => {
      const temperature = calibration_data_point?.temperature;
      // const weight_1_raw = calibration_data_point?.weight_1_raw;
      const weight_1_raw = calibration_data_point?.weight_1;
      const weight_2_raw = calibration_data_point?.weight_2;
      // const weight_2_raw = calibration_data_point?.weight_2_raw;

      return !isNil(temperature) && !isNil(weight_1_raw) && !isNil(weight_2_raw);
    });

    const point_at_requested_time = timeseries_with_valid_data.find(calibration_data_point =>
      isEqual(calibration_data_point?.date, requested_date)
    );

    if (!isNil(point_at_requested_time)) {
      return { is_direct_find: true, point: point_at_requested_time };
    }

    // Fallback to range search (for the closest one)
    const range_start = requested_date;
    const range_end = addMinutes(requested_date, 16); // 15 min (+1 for "strict equal")

    const timeseries_range = timeseries_with_valid_data.filter(t => isAfter(t.date, range_start) && isBefore(t.date, range_end));
    const timeseries_date = timeseries_range.map(t => new Date(t.date));
    const closest_point_to_request = timeseries_range.find(t => isEqual(t.date, closestTo(requested_date, timeseries_date)));

    return { is_direct_find: false, point: closest_point_to_request };
  }
}
