import { ChangeDetectionStrategy, Component, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';

import { Observable, of } from 'rxjs';
import { map } from 'rxjs';

import { merge as _merge } from 'lodash-es';

import { Beeguard2Api } from 'app/core';
import { AppStateService } from 'app/core/app-state.service';
import { ConsoleLoggerService } from 'app/core/console-logger.service';

import { replay } from '@bg2app/tools/rxjs';

import { DialogsService } from 'app/widgets/dialogs-modals/dialogs.service';

import { ISchema } from 'ngx-schema-form';

import { BgControlWidgetComponent, WidgetOptions } from '../control/control.widget';

import { marker as i18n } from '@biesbjerg/ngx-translate-extract-marker';
import { MatSelect } from '@angular/material/select';

interface INgMatSelectFormFieldOption extends WidgetOptions {
  readonly: boolean;
  multiple: boolean;
  with_reset: boolean;
  reset_label: string;
}

interface SelectOption {
  value: any;
  label: string;
}

@Component({
  selector: 'bg2-ng-mat-select',
  templateUrl: './ng-mat-select.component.html',
  styleUrls: ['./ng-mat-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NgMatSelectFormFieldComponent extends BgControlWidgetComponent implements OnInit, OnDestroy {
  /**
   * Re-define options for this widget component
   */
  public options: INgMatSelectFormFieldOption = _merge(
    {},
    {
      multiple: false,
      with_reset: false,
      reset_label: i18n<string>('ALL.COMMON.All'),
    } as INgMatSelectFormFieldOption,
    super.options
  );

  private readonly _logger = new ConsoleLoggerService(this.constructor.name, true);

  public select_options$$: Observable<any[]> = null;

  // #region -> (component basics)

  @ViewChild('select')
  public select: MatSelect;

  constructor(private _bg2Api: Beeguard2Api, private _dialogs: DialogsService, private _appState: AppStateService) {
    super(_bg2Api, _appState, _dialogs);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.select_options$$ = of<ISchema>(this.schema).pipe(
      map((schema: ISchema) => {
        let _select_options: SelectOption[] = [];

        if (this.options.multiple) {
          schema = schema?.items;
        }

        if (schema?.oneOf) {
          _select_options = schema.oneOf?.map((oneOf: { enum: string[]; label: string; description: string }) => ({
            label: oneOf?.label || oneOf?.description || 'DEFAULT',
            value: oneOf?.enum[0],
          }));
        } else if (schema?.enum) {
          _select_options = schema.enum?.map((enum_value: string) => ({
            label: enum_value,
            value: enum_value,
          }));
        }

        return _select_options;
      }),
      replay()
    );
  }

  ngOnDestroy(): void {}

  // #endregion

  // #region -> (value management)

  set value(value: string) {
    this.formProperty.setValue(value, false);
  }

  // #endregion
}
