import { Injectable } from '@angular/core';

import { GhostService } from 'app/core/ghost';
import { RunnersService } from 'app/core/runners';
import { AppStateService } from 'app/core/app-state.service';
import { Beeguard2Api } from 'app/core/api/main/beeguard2-api-service';
import { ConsoleLoggerService } from 'app/core/console-logger.service';
import { BeeguardAuthService } from 'app/core/auth/beeguard-auth.service';
import { SnackbarManagerService } from 'app/widgets/widgets-snackbar/snackbar-manager.service';
import { ApplicationVersionService } from './app-version.service';
import { environment } from 'environments/environment';
import { GoogleAnalyticsService } from '../seo-tools/google-analytics.service';
import { ZohoAuthService } from 'app/core/services/zoho/zoho-auth.service';
import { LocalStorageService } from '../storage/local-storage.service';

/** */
export function initialize_app(appInitService: AppInitService) {
  return (): Promise<any> => appInitService.init();
}

/** */
@Injectable({
  providedIn: 'root',
})
export class AppInitService {
  // #region -> (service basics)

  /** */
  private readonly LOGGER = new ConsoleLoggerService('AppInitService', false);

  /** */
  constructor(
    private readonly _bg2Api: Beeguard2Api,
    private readonly _runners: RunnersService,
    private readonly _appState: AppStateService,
    private readonly _ghostService: GhostService,
    private readonly _zohoAuthService: ZohoAuthService,
    private readonly _oAuthService: BeeguardAuthService,
    private readonly _snackbarService: SnackbarManagerService,
    private readonly _localStorageService: LocalStorageService,
    private readonly _appVersionService: ApplicationVersionService,
    private readonly _googleAnalyticsService: GoogleAnalyticsService
  ) {}

  /** */
  public init() {
    return new Promise<void>((resolve, reject) => {
      this._appState.ngAppOnInit();
      this._zohoAuthService.initialize(this._appState);

      this._googleAnalyticsService.initialize();

      this._appVersionService.initialize();

      this._runners.registerRunner('ghost', this._ghostService.ghost_run_state$$);
      this._runners.registerRunner('bg2run', this._bg2Api.runs.entity_runners$$);

      this.LOGGER.info('App is ready to be used !');
      this.LOGGER.info(`Application running on version ${environment?.version}`);

      resolve();
    });
  }

  // #endregion
}
