import { some } from 'lodash-es';

import { BehaviorSubject, map, Observable } from 'rxjs';
import { distinctUntilRealChanged, waitForNotNilValue } from '@bg2app/tools/rxjs';

import { ConsoleLoggerService } from 'app/core/console-logger.service';

import { ErrorHelperData } from 'app/widgets/widgets-reusables/errors/error-helper/error-helper.component';

import { EntityUserAclContext } from '../interfaces/entity-acl-context.interface';

/** */
export abstract class AbstractEntityUserACL<TUserAce extends string = any> {
  // #region -> (ace list management)
  protected readonly LOGGER = new ConsoleLoggerService(this.constructor.name, false);

  /** */
  private _acl$$: BehaviorSubject<TUserAce[]> = new BehaviorSubject<TUserAce[]>(null);

  /** */
  public acl$$: Observable<TUserAce[]> = this._acl$$.asObservable().pipe(waitForNotNilValue());

  /** */
  public update_acl(acl: TUserAce[]) {
    this._acl$$.next(acl);
  }

  // #endregion

  // #region -> (acl checks)

  /** */
  public can$$ = (ace: TUserAce) =>
    this.acl$$.pipe(
      map(acl => acl.includes(ace)),
      distinctUntilRealChanged()
    );

  /** */
  public cannot$$ = (ace: TUserAce) =>
    this.can$$(ace).pipe(
      map(can_ace => !can_ace),
      distinctUntilRealChanged()
    );

  /** */
  public cannot_or$$ = (...aces: TUserAce[]) =>
    this.acl$$.pipe(
      map(acl => aces.map(ace => !acl.includes(ace))),
      map(are_aces_in_acl => some(are_aces_in_acl)),
      distinctUntilRealChanged()
    );

  /** */
  public only$$ = (ace: TUserAce) =>
    this.acl$$.pipe(
      map(acl => acl.length === 1 && acl.includes(ace)),
      distinctUntilRealChanged()
    );

  // #endregion

  // #region -> (specific ACL checks)

  /** */
  public abstract check_read_all_events$$: (context: EntityUserAclContext) => Observable<ErrorHelperData | null>;

  /** */
  public abstract check_write_all_events$$: (context: EntityUserAclContext) => Observable<ErrorHelperData | null>;

  // #endregion

  // #region -> (error-scoped ACE check)

  /** */
  public abstract throw__if_cannot$$: (ace: TUserAce, context: string) => Observable<ErrorHelperData | null>;

  // #endregion
}

/** */
export class TestAbstractEntityUserACL extends AbstractEntityUserACL<'ace_A' | 'ace_B' | 'ace_C'> {
  // #region -> (specific ACL checks)

  /** */
  public check_read_all_events$$: (context: EntityUserAclContext) => Observable<ErrorHelperData | null>;

  /** */
  public check_write_all_events$$: (context: EntityUserAclContext) => Observable<ErrorHelperData | null>;

  // #endregion

  // #region -> (error-scoped ACE check)

  /** */
  public throw__if_cannot$$: (ace: 'ace_A' | 'ace_B' | 'ace_C', context: string) => Observable<ErrorHelperData | null>;

  // #endregion
}
