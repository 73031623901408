import { HttpClient } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';

import { difference, groupBy, isEmpty, isNil, uniq } from 'lodash-es';

import { catchError, forkJoin, map, Observable, of, switchMap, take } from 'rxjs';
import { replay, waitForNotNilValue } from '@bg2app/tools/rxjs';

import { ApiCache } from 'app/core/api/misc/api-cache';
import { ZohoAuthService } from 'app/core/services/zoho/zoho-auth.service';

import {
  ZohoDeskAgent,
  IZohoDeskAgent,
  ZohoDeskTicket,
  ZohoDeskContact,
  ZohoDeskAccount,
  IZohoDeskTicket,
  IZohoDeskAccount,
  IZohoDeskContact,
  UpdateTicketSupport,
  ZohoDeskTicketStatus,
  IZohoCreateDeskTicket,
  SearchContactQueryParams,
  SearchTicketQueryParams,
  CloseTicketRUSupport,
  NewTicketSupport,
  AnyOfTicketRUSupportEdit,
  ZohoDeskTicketComment,
} from 'app/models/zoho/desk';
import { DeviceInterface, DeviceSupportCreationType, DeviceSupportNextUsage, DRDevice } from 'app/models';
import { build_template_support_close_ru$, build_template_support_new_ru$ } from 'app/models/zoho/tools/template-builder';
import { parseDate } from 'app/misc/tools';
import { TranslateService } from '@ngx-translate/core';

/** */
enum ZohoDeskModuleName {
  /** */
  MODULE_AGENTS = 'agents',

  /** */
  MODULE_TICKETS = 'tickets',

  /** */
  MODULE_TICKET_COMMENT = 'comments',

  /** */
  MODULE_ACCOUNTS = 'accounts',

  /** */
  MODULE_CONTACTS = 'contacts',
}

/**
 * Zoho Desk API service.
 *
 * Use this API to manage modules, organization, roles, profiles, territories, records, etc.
 *
 * @url https://desk.zoho.com/DeskAPIDocument#Introduction
 */
@Injectable({
  providedIn: 'root',
})
export class ZohoDeskApiService implements OnDestroy {
  // #region -> (service basics)

  /** */
  public static readonly API_BASE: string = 'https://desk.zoho.eu/api/v1';

  /** */
  private _cache = new ApiCache<ZohoDeskAccount | ZohoDeskContact | ZohoDeskTicket | ZohoDeskAgent | ZohoDeskTicketComment, string>();

  /** */
  constructor(
    private readonly _httpClient: HttpClient,
    private readonly _auth: ZohoAuthService,
    private readonly _translateService: TranslateService
  ) {}

  /** */
  ngOnDestroy(): void {}

  // #endregion

  // #region -> (auth token)

  /** */
  private _access_token$$: Observable<string> = this._auth.is_authenticated$$.pipe(
    switchMap(is_authenticated => {
      if (!is_authenticated) {
        return of(null);
      }

      return this._auth.access_token$$;
    }),
    replay()
  );

  /** */
  private _wait_for_access_token$$ = this._access_token$$.pipe(waitForNotNilValue(), take(1));

  // #endregion

  /** */
  public deserialize(model_data: IZohoDeskAgent, type: ZohoDeskModuleName.MODULE_AGENTS): ZohoDeskAgent;
  public deserialize(model_data: IZohoDeskTicket, type: ZohoDeskModuleName.MODULE_TICKETS): ZohoDeskTicket;
  public deserialize(model_data: IZohoDeskAccount, type: ZohoDeskModuleName.MODULE_ACCOUNTS): ZohoDeskAccount;
  public deserialize(model_data: IZohoDeskContact, type: ZohoDeskModuleName.MODULE_CONTACTS): ZohoDeskContact;
  public deserialize(model_data: IZohoDeskAgent, type: ZohoDeskModuleName.MODULE_TICKET_COMMENT): ZohoDeskTicketComment;
  public deserialize(model_data: IZohoDeskAccount | IZohoDeskContact | IZohoDeskAgent | IZohoDeskTicket, type: ZohoDeskModuleName): any {
    let object = null;

    if (this._cache.has(model_data?.id)) {
      object = this._cache.get(model_data?.id);
      object.deserialize(<any>model_data);

      return object;
    }

    switch (type) {
      case ZohoDeskModuleName.MODULE_ACCOUNTS: {
        object = new ZohoDeskAccount();
        break;
      }

      case ZohoDeskModuleName.MODULE_CONTACTS: {
        object = new ZohoDeskContact();
        break;
      }

      case ZohoDeskModuleName.MODULE_AGENTS: {
        object = new ZohoDeskAgent();
        break;
      }

      case ZohoDeskModuleName.MODULE_TICKETS: {
        object = new ZohoDeskTicket();
        break;
      }

      case ZohoDeskModuleName.MODULE_TICKET_COMMENT: {
        object = new ZohoDeskTicketComment();
        break;
      }
    }

    object.deserialize(<any>model_data);
    this._cache.add(object.id, object);

    return object;
  }

  /** */
  public connected_agent$$: Observable<ZohoDeskAgent> = this._httpClient.get<any>(`${ZohoDeskApiService.API_BASE}/myinfo`).pipe(
    map(response => this.deserialize(response, ZohoDeskModuleName.MODULE_AGENTS)),
    catchError(() => of<ZohoDeskAgent>(null))
  );

  // #region -> (create methods)

  /** */
  public create_ticket$(ticket: IZohoCreateDeskTicket): Observable<ZohoDeskTicket> {
    return this._httpClient.post<any>(`${ZohoDeskApiService.API_BASE}/tickets`, ticket).pipe(
      map(response => this.deserialize(response, ZohoDeskModuleName.MODULE_TICKETS)),
      catchError(() => of<ZohoDeskTicket>(null))
    );
  }

  /**
   * Create a new ticket comment
   *
   * @param ticket_id ID of the ticket.
   * @param content Content of the comment.
   *
   * @returns
   */
  public create_ticket_comment$(ticket_id: string, content: string) {
    return this._httpClient
      .post<any>(`${ZohoDeskApiService.API_BASE}/tickets/${ticket_id}/comments`, {
        content: content,
        isPublic: 'false',
        attachmentIds: [],
        contentType: 'html',
      })
      .pipe(
        catchError(() => null),
        take(1)
      );
  }

  // #endregion

  // #region -> (read methods)

  /** */
  public fetch_module$(module_name: 'agents', object_id: string): Observable<ZohoDeskAgent>;
  public fetch_module$(module_name: 'tickets', object_id: string): Observable<ZohoDeskTicket>;
  public fetch_module$(module_name: 'accounts', object_id: string): Observable<ZohoDeskAccount>;
  public fetch_module$(module_name: 'tickets' | 'accounts' | 'agents', object_id: string): Observable<any> {
    return this._httpClient.get<any>(`${ZohoDeskApiService.API_BASE}/${module_name}/${object_id}`).pipe(
      map(response => this.deserialize(response, <any>module_name)),
      catchError(() => of(null))
    );
  }

  /**
   * Fetch list of contacts for specfic account.
   *
   * @param account_id
   *
   * @returns
   */
  public fetch_account_contacts$(account_id: string): Observable<{ data: ZohoDeskContact[] }> {
    return this._httpClient.get<{ data: any[] }>(`${ZohoDeskApiService.API_BASE}/accounts/${account_id}/contacts?limit=100`).pipe(
      map(response => {
        if (isNil(response)) {
          return { data: [] };
        }

        const deserialized_data = response?.data?.map(datum => this.deserialize(datum, ZohoDeskModuleName.MODULE_CONTACTS));
        response.data = deserialized_data;

        return <any>response;
      }),
      catchError(() => of<any>(null))
    );
  }

  /** */
  public fetch_ticket_comments$(ticket_id: string): Observable<{ data: ZohoDeskTicketComment[] }> {
    return this._httpClient
      .get<{ data: any[] }>(`${ZohoDeskApiService.API_BASE}/tickets/${ticket_id}/comments`, {
        params: {
          sortBy: '-commentedTime',
        },
      })
      .pipe(
        map(response => {
          if (isNil(response)) {
            return { data: [] };
          }

          const deserialized_data = response?.data?.map(datum => this.deserialize(datum, ZohoDeskModuleName.MODULE_TICKET_COMMENT));
          response.data = deserialized_data;

          return <any>response;
        }),
        catchError(() => of(null))
      );
  }

  // #endregion

  // #region -> (update methods)

  /** */
  public update_ticket_with_support$(ticket_id: string, support: NewTicketSupport): Observable<ZohoDeskTicket>;
  public update_ticket_with_support$(ticket_id: string, support: UpdateTicketSupport): Observable<ZohoDeskTicket>;
  public update_ticket_with_support$(ticket_id: string, support: CloseTicketRUSupport): Observable<ZohoDeskTicket>;
  public update_ticket_with_support$(ticket_id: string, support: AnyOfTicketRUSupportEdit): Observable<ZohoDeskTicket> {
    return this.fetch_module$('tickets', ticket_id).pipe(
      switchMap(selected_ticket => {
        // Ticket related data
        const ticket_related_imeis = (selected_ticket?.cf_imeis ?? '').trim().split(' ');
        const ticket_ru_imeis = (selected_ticket?.cf_imeis_recu_en_ru ?? '').trim().split(' ');

        // Selected devices data
        const selected_devices_by_type: { [key in DeviceInterface.TypeEnum]: DRDevice[] } = <any>(
          groupBy(support?.selected_devices, device => device.type)
        );
        const selected_devices_imeis = support?.selected_devices?.map(device => device.imei.toString());

        // Compute updated values
        const updated_ticket_imeis = uniq([...ticket_related_imeis, ...selected_devices_imeis]);
        const updated_ticket_imeis_ru = uniq([...ticket_ru_imeis, ...selected_devices_imeis]);

        // Prepare update requests
        let update_ticket$: Observable<any> = of(null);
        let new_comment_template$: Observable<string> = of(null);

        // Prepare ticket update for RU
        const update_ticket: Partial<IZohoCreateDeskTicket> = {
          cf: {
            cf_retour_usine: 'true',

            cf_imeis: updated_ticket_imeis.join(' '),
            cf_imeis_recu_en_ru: updated_ticket_imeis_ru.join(' ').trim(),
          },
        };

        switch (support.type) {
          case 'new': {
            // Compute exceptional imeis
            const exceptional_imeis = difference(selected_devices_imeis, ticket_related_imeis);
            const exceptional_devices_type: { [key in DeviceInterface.TypeEnum]: DRDevice[] } = <any>groupBy(
              (support?.selected_devices ?? []).filter(device => (exceptional_imeis ?? []).includes(device.imei.toString())),
              device => device.type
            );

            // Compute device not yet in ru
            const not_yet_ru_imeis = difference(ticket_related_imeis, ticket_ru_imeis);
            const not_yet_ru_devices: { [key in DeviceInterface.TypeEnum]: DRDevice[] } = <any>groupBy(
              (support?.selected_devices ?? []).filter(device => (not_yet_ru_imeis ?? []).includes(device.imei.toString())),
              device => device.type
            );

            // Compute special creation conditions
            const is_completing = difference(updated_ticket_imeis, updated_ticket_imeis_ru).length === 0;
            const is_partial = difference(ticket_related_imeis, selected_devices_imeis).length > 0;

            if (!is_partial) {
              // CASE : [full_new_ru]
              // - Set ticket status to "ZohoDeskTicketStatus.RETOUR_USINE_RECU"
              // - Set 'cf_retour_usine' to "true"
              // - Set total of each devices in RU
              // - Set RU date
              // - Set 'cf_imeis_recu_en_ru' to devices in RU

              // Update ticket status
              update_ticket.status = ZohoDeskTicketStatus.RETOUR_USINE_RECU;
              update_ticket.cf.cf_date_arrivee_usine = `${support?.ru_in_date.split('T')[0]}`;

              update_ticket$ = this._httpClient.patch<any>(`${ZohoDeskApiService.API_BASE}/tickets/${ticket_id}`, update_ticket, {});

              // Add comment to ticket
              new_comment_template$ = build_template_support_new_ru$(
                update_ticket,
                parseDate(support.ru_in_date),
                {
                  selected: support?.selected_devices,
                  exceptional_imeis: exceptional_imeis,
                },
                {
                  is_partial: is_partial,
                  is_completing_ru: is_completing,
                  is_already_ru: (selected_ticket?.cf?.cf_retour_usine ?? 'false') === 'true',
                },
                {
                  comment: support?.comment,
                }
              );

              break;
            }

            if ((selected_ticket?.cf?.cf_retour_usine ?? 'false') === 'false') {
              // CASE : [partial, not_yet_ru]
              update_ticket.status = ZohoDeskTicketStatus.ATTENTE_RETOUR_USINE;
              update_ticket.cf.cf_date_arrivee_usine = `${support?.ru_in_date.split('T')[0]}`;

              update_ticket$ = this._httpClient.patch<any>(`${ZohoDeskApiService.API_BASE}/tickets/${ticket_id}`, update_ticket, {});

              // Add comment to ticket
              new_comment_template$ = build_template_support_new_ru$(
                update_ticket,
                parseDate(support.ru_in_date),
                {
                  selected: support?.selected_devices,
                  exceptional_imeis: exceptional_imeis,
                },
                {
                  is_partial: is_partial,
                  is_already_ru: false,
                  is_completing_ru: is_completing,
                },
                {
                  comment: support?.comment,
                }
              );

              break;
            }

            // CASE : [partial, ticket_with_ru]
            update_ticket.status = is_completing ? ZohoDeskTicketStatus.RETOUR_USINE_RECU : ZohoDeskTicketStatus.ATTENTE_RETOUR_USINE;

            // Update ticket status
            update_ticket$ = this._httpClient.patch<any>(`${ZohoDeskApiService.API_BASE}/tickets/${ticket_id}`, update_ticket, {});

            // Add comment to ticket
            new_comment_template$ = build_template_support_new_ru$(
              update_ticket,
              parseDate(support.ru_in_date),
              {
                selected: support?.selected_devices,
                exceptional_imeis: exceptional_imeis,
              },
              {
                is_already_ru: true,
                is_partial: is_partial,
                is_completing_ru: is_completing,
              },
              {
                comment: support?.comment,
              }
            );

            break;
          }

          case 'update': {
            // update_ticket$ = this._httpClient.patch<any>(
            //   `${this.API_BASE}/tickets/${ticket_id}`,
            //   <Partial<IZohoCreateDeskTicket>>{
            //     cf: {
            //       cf_date_arrivee_usine: `${config?.date_in_ru.split('T')[0]}`,
            //       cf_imeis_recu_en_ru: uniq([...ticket_ru_imeis, config?.selected_devices?.map(device => device.imei)]).join(' '),
            //     },
            //   },
            //   {
            //     headers: {
            //       Authorization: `Zoho-oauthtoken ${access_token}`,
            //     },
            //   }
            // );

            // new_comment_template$ = this.create_ticket_comment$(
            //   ticket_id,
            //   build_ticket_comment_for_update_ru({
            //     concerned_devices: config?.selected_devices,
            //     date_in_ru: config?.date_in_ru.split('T')[0],
            //   })
            // );

            break;
          }

          case 'close': {
            if (!support.review.ticket.is_partial) {
              // CASE : [full]

              update_ticket.status = support?.review?.ticket?.status;
              update_ticket$ = this._httpClient.patch<any>(`${ZohoDeskApiService.API_BASE}/tickets/${ticket_id}`, update_ticket, {});
            } else {
              if (!support.review.ticket.is_completing) {
                // CASE : [partial, with_remaining]
              } else {
                // CASE : [partial, completing_ru]
              }
            }

            // Add comment to ticket
            new_comment_template$ = build_template_support_close_ru$(update_ticket, support, this._translateService);

            break;
          }
        }

        return forkJoin([
          update_ticket$.pipe(take(1)),
          new_comment_template$.pipe(
            take(1),
            switchMap(template => this.create_ticket_comment$(ticket_id, template))
          ),
        ]);
      }),
      map(
        ([response_update, response_comment]) =>
          // console.log({ response_update, response_comment });
          response_update
      ),
      map(response => this.deserialize(response, ZohoDeskModuleName.MODULE_TICKETS)),
      catchError(() => of(null))
    );
  }

  // #endregion

  // #region -> (delete methods)

  // #endregion

  // #region -> (search methods)

  /** */
  private readonly DEFAULT_SEARCH_OPTIONAL_PARAMS = { per_page: 10, fields: <string[]>[] };

  /** */
  public search_objects_globally(module_name: ZohoDeskModuleName.MODULE_AGENTS, search_str: string): Observable<{ data: ZohoDeskAgent[] }>;
  public search_objects_globally(
    module_name: ZohoDeskModuleName.MODULE_TICKETS,
    search_str: string
  ): Observable<{ data: ZohoDeskTicket[]; count: number }>;
  public search_objects_globally(
    module_name: ZohoDeskModuleName.MODULE_CONTACTS,
    search_str: string
  ): Observable<{ data: ZohoDeskContact[]; count: number }>;
  public search_objects_globally(module_name: ZohoDeskModuleName, search_str: string): Observable<any> {
    return this._wait_for_access_token$$.pipe(
      switchMap(access_token => {
        if (isNil(search_str) || isEmpty(search_str)) {
          return of([]);
        }

        if (module_name === 'agents') {
          return this._httpClient
            .get<{ data: IZohoDeskAgent[]; count: number }>(`${ZohoDeskApiService.API_BASE}/agents`, {
              headers: {
                Authorization: `Zoho-oauthtoken ${access_token}`,
              },
            })
            .pipe(
              map(response => {
                if (isNil(response)) {
                  return { data: [] };
                }

                const filtered_agents = response?.data?.filter(
                  agent =>
                    agent.status === 'ACTIVE' &&
                    ((agent?.lastName ?? '')?.toLowerCase().includes(search_str?.toLowerCase()) ||
                      (agent?.firstName ?? '')?.toLowerCase().includes(search_str?.toLowerCase()) ||
                      (agent?.id ?? '')?.toLowerCase().includes(search_str?.toLowerCase()))
                );

                response.data = filtered_agents;
                return response;
              })
            );
        }

        return this._httpClient.get<any>(
          `${ZohoDeskApiService.API_BASE}/search?module=${module_name}&searchStr=${search_str}&sortBy=modifiedTime`,
          {
            headers: {
              Authorization: `Zoho-oauthtoken ${access_token}`,
            },
          }
        );
      }),
      map((response: { data: any[] }) => {
        if (isNil(response)) {
          return { data: [] };
        }

        const deserialized_data = response?.data?.map((datum: any) => this.deserialize(datum, <any>module_name));
        response.data = deserialized_data;

        return response;
      })
    );
  }

  /** */
  public search_in_contacts$(queryparams: SearchContactQueryParams): Observable<{ data: ZohoDeskContact[]; count: number }> {
    if (isNil(queryparams) || isEmpty(queryparams)) {
      return of({ data: [], count: 0 });
    }

    // Build query params
    const array_params = Object.keys(queryparams).map((key, index, self) => {
      const value = (<any>queryparams)[key];
      return `${key}=${encodeURIComponent(value)}`;
    });

    return this._httpClient.get<any>(`${ZohoDeskApiService.API_BASE}/contacts/search?${array_params.join('&')}`).pipe(
      map((response: { data: any[]; count: number }) => {
        if (isNil(response)) {
          return <any>{ data: [] };
        }

        const deserialized_data = response?.data?.map((datum: any) => this.deserialize(datum, ZohoDeskModuleName.MODULE_CONTACTS));
        response.data = deserialized_data;

        return response;
      })
    );
  }

  /** */
  public search_accounts$(queryparams: { accountName?: string }): Observable<{ data: ZohoDeskAccount[]; count: number }> {
    if (isNil(queryparams) || isEmpty(queryparams)) {
      return of({ data: [], count: 0 });
    }

    // Build query params
    const array_params = Object.keys(queryparams).map((key, index, self) => {
      let value = (<any>queryparams)[key];

      return `${key}=${encodeURIComponent(value)}`;
    });

    return this._httpClient.get<any>(`${ZohoDeskApiService.API_BASE}/accounts/search?limit=100&${array_params.join('&')}`).pipe(
      map((response: { data: any[]; count: number }) => {
        if (isNil(response)) {
          return <any>{ data: [] };
        }

        const deserialized_data = response?.data?.map((datum: any) => this.deserialize(datum, ZohoDeskModuleName.MODULE_ACCOUNTS));
        response.data = deserialized_data;

        return response;
      })
    );
  }

  /** */
  public search_tickets(
    queryparams: SearchTicketQueryParams,
    local_filter?: { statusType?: IZohoDeskTicket['statusType'][]; include_with_undefined_status_type?: boolean }
  ): Observable<{ data: ZohoDeskTicket[]; count: number }> {
    if (isNil(queryparams) || isEmpty(queryparams)) {
      return of({ data: [], count: 0 });
    }

    // Build query params
    const array_params = Object.keys(queryparams).map((key, index, self) => {
      const value = (<any>queryparams)[key];
      return `${key}=${encodeURIComponent(value)}`;
    });

    return this._httpClient
      .get<any>(`${ZohoDeskApiService.API_BASE}/tickets/search?${array_params.join('&')}&limit=100&sortBy=modifiedTime`)
      .pipe(
        map((response: { data: ZohoDeskTicket[]; count: number }) => {
          if (isNil(response?.data) || isEmpty(response?.data)) {
            return response;
          }

          let final_data = response?.data?.filter(ticket => {
            let keep_for_status_type = false;
            let keep_for_undefined_status_type = false;

            if (isNil(local_filter) || isEmpty(local_filter)) {
              return true;
            }

            if (local_filter?.statusType) {
              keep_for_status_type = local_filter?.statusType.includes(ticket.statusType);
            }

            if (local_filter?.include_with_undefined_status_type) {
              keep_for_undefined_status_type = isNil(ticket?.statusType) || isEmpty(ticket?.statusType);
            }

            return keep_for_status_type || keep_for_undefined_status_type;
          });

          response.data = final_data;
          response.count = final_data.length;
          return response;
        }),
        map((response: { data: any[]; count: number }) => {
          if (isNil(response?.data)) {
            return <any>{ data: <ZohoDeskTicket[]>[] };
          }

          const deserialized_data = response?.data?.map((datum: IZohoDeskTicket) =>
            this.deserialize(datum, ZohoDeskModuleName.MODULE_TICKETS)
          );
          response.data = deserialized_data;

          return response;
        })
      );
  }

  // #endregion
}
