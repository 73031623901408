import { OnDestroy, Component, HostListener } from '@angular/core';

import { BehaviorSubject, Subscription } from 'rxjs';

import { isNil, isString as _isString, clone } from 'lodash-es';
import { captureMessage } from '@sentry/angular-ivy';

import { extractErrorMsg } from 'app/misc/tools';

import { debounceTime, distinctUntilChanged, map, tap } from 'rxjs';
import { replay } from '@bg2app/tools/rxjs';

import { ConsoleLoggerService } from 'app/core/console-logger.service';
import { ModalsService } from 'app/widgets/dialogs-modals/modals.service';
import { AbstractDialogComponent, AbstractDialogParams } from './abstract-dialog.component';

export interface ModalArgs {
  [key: string]: any;
}

export interface ModalParams extends AbstractDialogParams {
  args: ModalArgs;
}

@Component({
  template: '',
})
export abstract class AbstractModalComponent<I extends ModalParams = any, O = any>
  extends AbstractDialogComponent<I, O>
  implements OnDestroy
{
  // #region -> (component basics)

  /** */
  private _args_sub: Subscription;

  /** */
  private _runs_sub: Subscription;

  /** */
  protected readonly _logger = new ConsoleLoggerService('AbstractModalComponent', false);

  /** */
  constructor() {
    super();

    this._args_sub = this.args$$.subscribe();
  }

  /** */
  ngOnDestroy(): void {
    this._runs_sub?.unsubscribe();
    this._args_sub?.unsubscribe();

    this.unsubscribe();
  }

  /** */
  protected unsubscribe(): void {}

  // #endregion

  // #region -> (closing management)

  /** */
  protected is_trying_to_close_modal = false;

  /** */
  protected override_close_on_escape = false;

  /** */
  @HostListener('document:keydown.escape', ['$event'])
  public onEscapeKeyDown(event: KeyboardEvent) {
    setTimeout(() => {
      const is_the_last_modal = clone(this.modals_service.isLast(this));

      if (!is_the_last_modal || event?.defaultPrevented || this.override_close_on_escape || this.is_trying_to_close_modal) {
        this._logger.info(`Ignored "${event?.key}" keyboard event for reason : `, {
          is_not_last_opened_modal: !is_the_last_modal,
          managed_by_another_than_modal: event?.defaultPrevented,
          is_trying_to_close_modal: this.is_trying_to_close_modal,
          overrided_in_another_modal: this.override_close_on_escape,
        });

        return;
      }

      this._logger.info(`Manage "${event?.key}" keyboard event`);

      event.preventDefault();
      event.stopPropagation();
      event.stopImmediatePropagation();

      this.modals_service.tryCloseLastModal();

      return false;
    }, 10);
  }

  @HostListener('window:beforeunload', ['$event'])
  public onBeforeUnload(event: BeforeUnloadEvent) {
    this.handle_event_before_unload(event);
  }

  /** */
  protected abstract handle_event_before_unload(event: BeforeUnloadEvent): void;

  // #endregion

  public error = '';

  protected dismissible = true;

  protected _args: I['args'] = {};
  public args$$ = this.input_params$$.pipe(
    map(params => params?.args || {}),
    map(args => {
      if (_isString(args)) {
        args = JSON.parse(args);
      }
      return args;
    }),
    tap(args => (this._args = args)),
    replay()
  );

  get args(): I['args'] {
    return this._args;
  }

  private _loading$$ = new BehaviorSubject<boolean>(false);
  public loading$$ = this._loading$$.asObservable().pipe(debounceTime(100), distinctUntilChanged(), replay());

  public get loading(): boolean {
    return this._loading$$.getValue();
  }

  public set loading(val: boolean) {
    this._loading$$.next(val);
  }

  private _initial_loading$$ = new BehaviorSubject<boolean>(false);
  public initial_loading$$ = this._initial_loading$$.asObservable().pipe(debounceTime(100), distinctUntilChanged(), replay());

  public set initial_loading(val: boolean) {
    this._initial_loading$$.next(val);
  }

  protected modals_service: ModalsService = null;

  public setModalsService(modals_service: ModalsService) {
    this.modals_service = modals_service;
  }

  public submit(): void {}

  public canBeClose(): boolean {
    return true;
  }

  public close(results: O = null, raz: boolean = false): void {
    this.complete(results);
    if (this.modals_service && this.modals_service.isLast(this)) {
      this.modals_service.tryDestroyLastModal(raz);
    }
  }

  protected handleHttpError(err: any): void {
    this.error = extractErrorMsg(err);
    if (err.status !== 403) {
      console.error(err);
      captureMessage(this.error, 'error');
    }
  }
}
